import { sortNumberArray } from '../../../../utils/collections';
import { getDiceArrangementArray } from '../../../../utils/dice';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  seededRandom
} from '../../../../utils/random';

const twoSimpleArrangements = [
  getDiceArrangementArray(2),
  [[true], [false], [true]],
  [[true, false, true]]
] as const;

const threeSimpleArrangements = [
  getDiceArrangementArray(3),
  [
    [true, false, true],
    [false, true, false]
  ],
  [[true], [true], [true]]
] as const;

const fourSimpleArrangements = [
  getDiceArrangementArray(4),
  [
    [false, true, false],
    [true, false, true],
    [false, true, false]
  ],
  [
    [false, true, false],
    [true, true, true]
  ]
] as const;

const fiveSimpleArrangements = [
  getDiceArrangementArray(5),
  [
    [true, true],
    [true, true, true]
  ],
  [
    [false, true, false],
    [true, true, true],
    [false, true, false]
  ]
] as const;

const sixSimpleArrangements = [getDiceArrangementArray(6)] as const;

const twoArrangements = [
  ...twoSimpleArrangements,
  [
    [false, false, true],
    [false, false, false],
    [true, false, false]
  ]
] as const;

const threeArrangements = [
  ...threeSimpleArrangements,
  [[true, true, true]],
  [
    [true, false, false],
    [false, true, false],
    [false, false, true]
  ]
] as const;

const fourArrangements = [...fourSimpleArrangements, [[true, true, true, true]]] as const;

const fiveArrangements = [
  ...fiveSimpleArrangements,
  [[true], [true, true], [true, true]],
  [
    [true, true, false],
    [true, true, true]
  ]
] as const;

const sixArrangements = [
  ...sixSimpleArrangements,
  [
    [true, true, true, false],
    [false, true, true, true]
  ],
  [[true], [true, true], [true, true, true]],
  [
    [true, false, false],
    [true, true, false],
    [true, true, true]
  ]
] as const;

export const getArrangement = (
  num: number,
  isSimpleArrangements = false,
  seed?: string | number | object
) => {
  if (num < 0 || num > 6) {
    throw new Error('Number must be between 1 and 6.');
  }
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;

  switch (num) {
    case 1:
      return getDiceArrangementArray(1);
    case 2:
      return getRandomFromArray(
        isSimpleArrangements ? twoSimpleArrangements : twoArrangements,
        seeded
      );
    case 3:
      return getRandomFromArray(
        isSimpleArrangements ? threeSimpleArrangements : threeArrangements,
        seeded
      );
    case 4:
      return getRandomFromArray(
        isSimpleArrangements ? fourSimpleArrangements : fourArrangements,
        seeded
      );
    case 5:
      return getRandomFromArray(
        isSimpleArrangements ? fiveSimpleArrangements : fiveArrangements,
        seeded
      );
    case 6:
      return getRandomFromArray(
        isSimpleArrangements ? sixSimpleArrangements : sixArrangements,
        seeded
      );
    default:
      return [];
  }
};

const getMaxRows = (array: unknown[][][]) =>
  sortNumberArray(
    array.map(val => val.length),
    'descending'
  )[0];

const getMaxCols = (array: unknown[][][]) =>
  Math.max(...array.flat().map(subArray => subArray.length));

export const getCounterArrangementScale = (num: number, isSimpleArrangements?: boolean) => {
  if (num < 0 || num > 6) {
    throw new Error('Number must be between 1 and 6.');
  }

  switch (num) {
    case 2: {
      const array = isSimpleArrangements ? twoSimpleArrangements : twoArrangements;
      return (
        Math.max(
          getMaxCols(array as unknown as boolean[][][]),
          getMaxRows(array as unknown as boolean[][][])
        ) + 1
      );
    }
    case 3: {
      const array = isSimpleArrangements ? threeSimpleArrangements : threeArrangements;
      return (
        Math.max(
          getMaxCols(array as unknown as boolean[][][]),
          getMaxRows(array as unknown as boolean[][][])
        ) + 1
      );
    }
    case 4: {
      const array = isSimpleArrangements ? fourSimpleArrangements : fourArrangements;
      return (
        Math.max(
          getMaxCols(array as unknown as boolean[][][]),
          getMaxRows(array as unknown as boolean[][][])
        ) + 1
      );
    }
    case 5: {
      const array = isSimpleArrangements ? fiveSimpleArrangements : fiveArrangements;
      return (
        Math.max(
          getMaxCols(array as unknown as boolean[][][]),
          getMaxRows(array as unknown as boolean[][][])
        ) + 1
      );
    }
    case 6: {
      const array = isSimpleArrangements ? sixSimpleArrangements : sixArrangements;
      return (
        Math.max(
          getMaxCols(array as unknown as boolean[][][]),
          getMaxRows(array as unknown as boolean[][][])
        ) + 1
      );
    }

    default:
      return 1;
  }
};

export const getUniqueArrangements = (
  counters: number,
  arrangements: number,
  isSimpleArrangements?: boolean
) => {
  if (counters < 1 || counters > 6) {
    throw new Error('Number must be between 2 and 6.');
  }

  switch (counters) {
    case 2:
      return getRandomSubArrayFromArray(
        isSimpleArrangements ? twoSimpleArrangements : twoArrangements,
        arrangements
      );
    case 3:
      return getRandomSubArrayFromArray(
        isSimpleArrangements ? threeSimpleArrangements : threeArrangements,
        arrangements
      );
    case 4:
      return getRandomSubArrayFromArray(
        isSimpleArrangements ? fourSimpleArrangements : fourArrangements,
        arrangements
      );
    case 5:
      return getRandomSubArrayFromArray(
        isSimpleArrangements ? fiveSimpleArrangements : fiveArrangements,
        arrangements
      );
    case 6:
      return getRandomSubArrayFromArray(
        isSimpleArrangements ? sixSimpleArrangements : sixArrangements,
        arrangements
      );
    default:
      return [];
  }
};
