import { useMemo } from 'react';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { binOpEquationToSentenceString, getBinOpEquation } from 'common/src/utils/fourOperations';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ADD, SUB } from 'common/src/constants';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import { numberToBase10Object, roundToTheNearest } from 'common/src/utils/math';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import NumberLine from 'common/src/components/question/representations/Number Line/NumberLine';
import { View } from 'react-native';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { getRandomName, nameSchema } from '../../../../utils/names';
import Text from 'common/src/components/typography/Text';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afM',
  description: 'afM',
  keywords: ['Addition', '1s', 'Base 10'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(101)
      .max(595)
      .refine(val => val % 10 < 6, 'number1 must have less than 6 ones.'),
    number2: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 595, {
      constraint: x => x % 10 < 6
    });

    const number2 = randomIntegerInclusive(1, 4);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans /> ${ADD} <ans /> = <ans />`}
        title={translate.instructions.completeNumberSentenceRepresentedByBase10()}
        inputMaxCharacters={3}
        testCorrect={answer =>
          (answer[0] === number1.toString() &&
            answer[1] === number2.toString() &&
            answer[2] === (number1 + number2).toString()) ||
          (answer[0] === number2.toString() &&
            answer[1] === number1.toString() &&
            answer[2] === (number1 + number2).toString())
        }
        {...props}
        Content={({ dimens }) => {
          const width = dimens.width / 2.25;
          const height = dimens.height;
          const scales = [number1, number2].map(
            number =>
              BaseTenRepCalcGridsAndScale(width, height, numberToBase10Object(number), 'Cubes')
                .scale
          );
          const scale = Math.min(...scales);

          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: dimens.width,
                justifyContent: 'space-evenly'
              }}
            >
              <BaseTenRepresentation
                b10Rep={{
                  variant: 'Cubes',
                  numbers: numberToBase10Object(number1),
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width / 2.25}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
              <Text style={{ fontSize: 40 }}>{ADD}</Text>
              <BaseTenRepresentation
                b10Rep={{
                  variant: 'Cubes',
                  numbers: numberToBase10Object(number2),
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width / 2.25}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number1.toLocaleString(),
            number2.toLocaleString(),
            (number1 + number2).toLocaleString()
          ],
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'afN',
  description: 'afN',
  keywords: ['Subtraction', '1s', 'Base 10'],
  schema: z
    .object({
      name: nameSchema,
      number1: z
        .number()
        .int()
        .min(102)
        .max(999)
        .refine(val => val % 10 !== 0, 'number1 must not be a multiple of 10'),
      number2: z.number().int().min(2).max(9)
    })
    .refine(
      val =>
        numberToBase10Object(val.number1).ones &&
        numberToBase10Object(val.number1).ones! >= val.number2,
      'number1 - number2 must not cross a tens boundary.'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const name = getRandomName();

    const number2 = randomIntegerInclusive(2, 9);

    const number1 = randomIntegerInclusive(102, 999, {
      constraint: x => x % 10 !== 0 && numberToBase10Object(x).ones! >= number2
    });

    return { name, number1, number2 };
  },
  Component: props => {
    const {
      question: { name, number1, number2 },
      translate
    } = props;
    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.characterSubtractsXOnesFromNumber(name, number2),
          `<ans /> ${SUB} <ans /> = <ans />`
        ]}
        title={translate.instructions.characterHasMadeTheNumberX(name, number1)}
        testCorrect={[[], [number1.toString(), number2.toString(), (number1 - number2).toString()]]}
        {...props}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: numberToBase10Object(number1),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              containerStyle={{ alignItems: 'center' }}
            />
          );
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afO',
  description: 'afO',
  keywords: ['Addition', '1s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2: z.number().int().min(1).max(3)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(1, 3);
    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const rangeA = roundToTheNearest(number3, 10, 'down');
    const rangeB = rangeA + 10;

    // Array of tick values for number line
    const tickValues = range(rangeA, rangeB);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[number3.toString()]}
        {...props}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'afP',
  description: 'afP',
  keywords: ['Subtraction', '1s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2: z.number().int().min(1).max(9)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(1, 9);
    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const total = number3 - number2;

    const rangeA = roundToTheNearest(number3, 10, 'down');
    const rangeB = rangeA + 10;

    // Array of tick values for number line
    const tickValues = range(rangeA, rangeB);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[total.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'afQ',
  description: 'afQ',
  keywords: ['Addition', 'Subtraction', '1s'],
  schema: z
    .object({
      numberA1: z.number().int().min(101).max(998),
      numberA2: z.number().int().min(1).max(9),
      answerBoxA: z.enum(['left', 'right', 'result']),
      numberB1: z.number().int().min(101).max(998),
      numberB2: z.number().int().min(1).max(9),
      answerBoxB: z.enum(['left', 'right', 'result'])
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 + numberA2 must not exchange'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 + numberB2 must not exchange'
    ),
  questionHeight: 500,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusive(1, 9);
        const numberA1 = randomIntegerInclusive(101, 998, {
          constraint: x => numbersDoNotExchange(x, numberA2)
        });

        const numberB2 = randomIntegerInclusive(1, 9);
        const numberB1 = randomIntegerInclusive(101, 998, {
          constraint: x => numbersDoNotExchange(x, numberB2)
        });

        const answerBoxA = getRandomFromArray(['left', 'right', 'result'] as const);
        const answerBoxB = getRandomFromArray(['left', 'right', 'result'] as const);

        return { numberA1, numberA2, numberB1, numberB2, answerBoxA, answerBoxB };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, answerBoxA, answerBoxB },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'add',
      answer: answerBoxA
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'add',
      answer: answerBoxB
    });

    // Remove = as QF2AlignedEquations includes its own
    const eqAContents = binOpEquationToSentenceString(eqA).split('=');
    const eqBContents = binOpEquationToSentenceString(eqB).split('=');

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeNumberSentences()}
        leftSide={[eqAContents[0], eqBContents[0]]}
        rightSide={[eqAContents[1], eqBContents[1]]}
        inputMaxCharacters={2}
        sentenceStyle={{ height: 150 }}
        testCorrect={userAnswer => {
          const answers = [...userAnswer.left.flat(), ...userAnswer.right.flat()];
          // If eqB is not equal to result and eqA ans is result
          // We need to check eqB ans first
          // Because furthest left ans gets put into array as first index
          if (eqB.answer !== 'result' && eqA.answer === 'result') {
            if (
              eqB[eqB.answer].toString() === answers[0].toString() &&
              eqA[eqA.answer].toString() === answers[1].toString()
            ) {
              return true;
            }
          } else {
            // Else check eqA answers first
            if (
              eqA[eqA.answer].toString() === answers[0].toString() &&
              eqB[eqB.answer].toString() === answers[1].toString()
            ) {
              return true;
            }
          }

          // Incorrect
          return false;
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'afR',
  description: 'afR',
  keywords: ['Addition', 'Subtraction', '1s'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2: z.number().int().min(1).max(9),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(1, 9);
    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, number2)
    });
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const number3 = number1 + number2;

    const splitNumbers = (numbers: number[]) => {
      return Array.from([numbers].join(''), Number);
    };

    const splitNumber1 = splitNumbers([number1]);
    const splitNumber3 = splitNumbers([number3]);

    const answerOptions = useMemo(() => {
      const draggables =
        addOrSubtract === ADD ? [...splitNumber1, number2] : [...splitNumber3, number2];
      return shuffle(draggables, { random: seededRandom(props.question) });
    }, [number2, splitNumber1, splitNumber3, addOrSubtract, props.question]);

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentence()}
        items={answerOptions}
        sentence={
          addOrSubtract === ADD
            ? `<ans/> <ans/> <ans/> ${ADD} <ans/> = ${number3.toLocaleString()}`
            : `<ans/> <ans/> <ans/> ${SUB} <ans/> = ${number1.toLocaleString()}`
        }
        testCorrect={userAnswer => {
          const [ans1Hundreds, ans1Tens, ans1Ones, ans2] = userAnswer;

          const lhs = Number([ans1Hundreds, ans1Tens, ans1Ones].join(''));
          const rhs = ans2 ?? 0;

          return addOrSubtract === ADD ? lhs + rhs === number3 : lhs - rhs === number1;
        }}
        moveOrCopy="move"
        customMarkSchemeAnswer={{
          answerText:
            addOrSubtract === ADD
              ? translate.markScheme.anyValidAdditionUsingAvailCards()
              : translate.markScheme.anyValidSubtractionUsingAvailCards()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtract1s',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
