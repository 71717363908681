import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import {
  arraysHaveSameContents,
  countRange,
  filledArray,
  range
} from 'common/src/utils/collections';
import { CreateShapeFromSquaresWithState } from '../representations/CreateShapeFromSquares';
import { DisplayShapeOnGrid } from '../representations/DisplayShapeOnGrid';
import { View, Platform, StyleSheet } from 'react-native';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { Line, Svg } from 'react-native-svg';
import { colors } from '../../../theme/colors';
import { Dimens, ScaleFactorContext } from '../../../theme/scaling';
import {
  givenShapeReflectedInX,
  givenShapeReflectedInXEqualsY,
  givenShapeReflectedInY
} from '../../../utils/shapes';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * Given shape's dimensions, as an array of array of boolean values.
   */
  givenShape: boolean[][];
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: boolean[][]; answerText?: string };
  symmetryLine: 'X' | 'Y' | 'XandY' | 'Y=X';
  color?: string;
};

export default function QF24bCreateSymmetricalShape({
  givenShape,
  title,
  pdfTitle,
  questionHeight,
  customMarkSchemeAnswer,
  symmetryLine,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  let gridLineWidth = isPdf ? 1.2 * 0.616 : 0.616;
  // Avoid making gridLineWidth any smaller than the hairlineWidth (i.e. one screen pixel) on native
  // This is a bit of a hack, and seems to only be required because we put our SVG (and entire question) in a big
  // transform: scale.
  gridLineWidth =
    Platform.OS === 'web'
      ? gridLineWidth
      : Math.max(gridLineWidth, StyleSheet.hairlineWidth / scaleFactor);

  const filterSelectedCols = (array: boolean[][]) => {
    return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
  };

  const isInX = symmetryLine === 'X';
  const isInY = symmetryLine === 'Y';
  const isBoth = symmetryLine === 'XandY';

  const numberOfCols = givenShape[0].length;
  const numberOfRows = givenShape.length;

  const answer: boolean[][][] = [];
  if (isInX) {
    answer.push(givenShapeReflectedInX(givenShape));
  } else if (isInY) {
    answer.push(givenShapeReflectedInY(givenShape));
  } else if (isBoth) {
    const ans1 = givenShapeReflectedInY(givenShape);
    const ans2 = givenShapeReflectedInX(givenShape);
    const ans3 = givenShapeReflectedInX(ans1);
    answer.push(ans1, ans2, ans3);
  } else {
    answer.push(givenShapeReflectedInXEqualsY(givenShape));
  }

  let array: (null | 'fixed' | undefined)[][];

  switch (symmetryLine) {
    case 'X':
      array = countRange(numberOfRows * 2).map(i =>
        countRange(numberOfCols).map(() => (i >= numberOfRows - 1 ? null : undefined))
      );
      givenShape.forEach((row, i) =>
        row.forEach((col, j) => (col ? (array[i][j] = 'fixed') : (array[i][j] = undefined)))
      );
      break;
    case 'Y':
      array = countRange(numberOfRows).map(() =>
        countRange(numberOfCols * 2).map(j => (j >= numberOfCols - 1 ? null : undefined))
      );
      givenShape.forEach((row, i) =>
        row.forEach((col, j) => (col ? (array[i][j] = 'fixed') : (array[i][j] = undefined)))
      );
      break;
    case 'Y=X':
      array = range(0, numberOfRows - 1).map(() => range(0, numberOfCols - 1).map(() => null));
      givenShape.forEach((row, i) =>
        row.forEach((col, j) =>
          col
            ? (array[i][j] = 'fixed')
            : givenShape.length - 1 - j < i
            ? (array[i][j] = null)
            : (array[i][j] = undefined)
        )
      );
      break;
  }

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => {
                const maxWidth = isInX ? dimens.width * 0.9 : ((dimens.width * 0.9) / 8) * 3.5;
                const maxHeight = isInY ? dimens.height * 0.9 : ((dimens.height * 0.9) / 8) * 3.5;

                const displayShapeSquareSize = Math.min(
                  maxHeight / givenShape.length,
                  maxWidth / givenShape[0].length
                );
                const createShapeSquareSize = Math.min(
                  maxHeight / numberOfRows,
                  maxWidth / numberOfCols
                );

                // Need to have a minimum of 96x96 for touchable area.
                const squareSize = Math.max(
                  Math.min(displayShapeSquareSize, createShapeSquareSize),
                  96
                );

                const xAxisWidth = (isBoth ? 2 * squareSize : squareSize) * numberOfCols;
                const yAxisWidth = (isBoth ? 2 * squareSize : squareSize) * numberOfRows;

                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: dimens.width,
                      height: dimens.height,
                      justifyContent: 'center'
                    }}
                  >
                    {symmetryLine === 'XandY' ? (
                      <View
                        style={{
                          height: dimens.height,
                          width: dimens.width,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <View
                          style={{
                            height: dimens.height * 0.5,
                            flexDirection: 'row',
                            alignItems: 'flex-end'
                          }}
                        >
                          <DisplayShapeOnGrid
                            givenShape={givenShape}
                            dimens={{
                              width: (dimens.width / 16) * 3.5,
                              height: dimens.height * 0.4
                            }}
                            gridCellSize={squareSize}
                            borderWidthAmount={gridLineWidth}
                          />

                          <View style={{ left: -2 }}>
                            <CreateShapeFromSquaresWithState
                              id="shape0"
                              defaultState={displayMode === 'markscheme' ? answer[0] : undefined}
                              numberOfRows={numberOfRows}
                              numberOfCols={numberOfCols}
                              dimens={{
                                width: (dimens.width / 16) * 3.5,
                                height: dimens.height * 0.4
                              }}
                              squareSize={squareSize}
                              borderWidthAmount={gridLineWidth}
                            />
                          </View>
                        </View>
                        <View
                          style={{ height: dimens.height * 0.5, flexDirection: 'row', top: -2 }}
                        >
                          <CreateShapeFromSquaresWithState
                            id="shape1"
                            defaultState={displayMode === 'markscheme' ? answer[1] : undefined}
                            numberOfRows={numberOfRows}
                            numberOfCols={numberOfCols}
                            dimens={{
                              width: (dimens.width / 16) * 3.5,
                              height: dimens.height * 0.4
                            }}
                            squareSize={squareSize}
                            borderWidthAmount={gridLineWidth}
                          />
                          <View style={{ left: -2 }}>
                            <CreateShapeFromSquaresWithState
                              id="shape2"
                              defaultState={displayMode === 'markscheme' ? answer[2] : undefined}
                              numberOfRows={numberOfRows}
                              numberOfCols={numberOfCols}
                              dimens={{
                                width: (dimens.width / 16) * 3.5,
                                height: dimens.height * 0.4
                              }}
                              squareSize={squareSize}
                              borderWidthAmount={gridLineWidth}
                            />
                          </View>
                        </View>
                        {svgLines(dimens, 'X', xAxisWidth)}
                        {svgLines(dimens, 'Y', yAxisWidth)}
                      </View>
                    ) : (
                      <>
                        {svgLines(dimens, symmetryLine, isInX ? xAxisWidth : yAxisWidth)}
                        <CreateShapeFromSquaresWithState
                          id="shape0"
                          defaultState={displayMode === 'markscheme' ? answer[0] : undefined}
                          numberOfRows={array.length}
                          numberOfCols={array[0].length}
                          dimens={{
                            width: (dimens.width / 8) * 3.5,
                            height: dimens.height * 0.8
                          }}
                          squareSize={squareSize}
                          borderWidthAmount={gridLineWidth}
                          array={array}
                          // When we reflect over the X/Y axis we want to ignore the static rows above/to the left of the symmetry line.
                          // This will push the userAnswer to start from the first array after the symmetry line
                          ansRowOffset={isInX ? array.length / 2 : 0}
                          ansColumnOffset={isInY ? array[0].length / 2 : 0}
                        />
                      </>
                    )}
                  </View>
                );
              }}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => {
            const maxWidth = isInX ? dimens.width * 0.9 : ((dimens.width * 0.9) / 8) * 3.5;
            const maxHeight = isInY ? dimens.height * 0.9 : ((dimens.height * 0.9) / 8) * 3.5;

            const displayShapeSquareSize = Math.min(
              maxHeight / givenShape.length,
              maxWidth / givenShape[0].length
            );
            const createShapeSquareSize = Math.min(
              maxHeight / numberOfRows,
              maxWidth / numberOfCols
            );

            // Need to have a minimum of 96x96 for touchable area.
            const squareSize = Math.max(
              Math.min(displayShapeSquareSize, createShapeSquareSize),
              96
            );

            const xAxisWidth = (isBoth ? 2 * squareSize : squareSize) * numberOfCols;
            const yAxisWidth = (isBoth ? 2 * squareSize : squareSize) * numberOfRows;

            return (
              <View
                style={{
                  flexDirection: isInX ? 'column' : 'row',
                  alignItems: 'center',
                  width: dimens.width,
                  height: dimens.height,
                  justifyContent: 'center'
                }}
              >
                {symmetryLine === 'XandY' ? (
                  <View
                    style={{
                      height: dimens.height,
                      width: dimens.width,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <View
                      style={{
                        height: dimens.height * 0.5,
                        flexDirection: 'row',
                        alignItems: 'flex-end'
                      }}
                    >
                      <DisplayShapeOnGrid
                        givenShape={givenShape}
                        dimens={{ width: (dimens.width / 16) * 3.5, height: dimens.height * 0.4 }}
                        gridCellSize={squareSize}
                        borderWidthAmount={gridLineWidth}
                      />

                      <View style={{ left: -2 }}>
                        <CreateShapeFromSquaresWithState
                          id={`shape-${0}`}
                          defaultState={filledArray(filledArray(false, numberOfCols), numberOfRows)}
                          testComplete={userAnswer => filterSelectedCols(userAnswer).length > 0}
                          testCorrect={userAnswer =>
                            userAnswer.every((row, i) => arraysHaveSameContents(row, answer[0][i]))
                          }
                          numberOfRows={numberOfRows}
                          numberOfCols={numberOfCols}
                          dimens={{ width: (dimens.width / 16) * 3.5, height: dimens.height * 0.4 }}
                          squareSize={squareSize}
                          borderWidthAmount={gridLineWidth}
                        />
                      </View>
                    </View>
                    <View style={{ height: dimens.height * 0.5, flexDirection: 'row', top: -2 }}>
                      <CreateShapeFromSquaresWithState
                        id={`shape-${1}`}
                        defaultState={filledArray(filledArray(false, numberOfCols), numberOfRows)}
                        testComplete={userAnswer => filterSelectedCols(userAnswer).length > 0}
                        testCorrect={userAnswer =>
                          userAnswer.every((row, i) => arraysHaveSameContents(row, answer[1][i]))
                        }
                        numberOfRows={numberOfRows}
                        numberOfCols={numberOfCols}
                        dimens={{ width: (dimens.width / 16) * 3.5, height: dimens.height * 0.4 }}
                        squareSize={squareSize}
                        borderWidthAmount={gridLineWidth}
                      />
                      <View style={{ left: -2 }}>
                        <CreateShapeFromSquaresWithState
                          id={`shape-${2}`}
                          defaultState={filledArray(filledArray(false, numberOfCols), numberOfRows)}
                          testComplete={userAnswer => filterSelectedCols(userAnswer).length > 0}
                          testCorrect={userAnswer =>
                            userAnswer.every((row, i) => arraysHaveSameContents(row, answer[2][i]))
                          }
                          numberOfRows={numberOfRows}
                          numberOfCols={numberOfCols}
                          dimens={{ width: (dimens.width / 16) * 3.5, height: dimens.height * 0.4 }}
                          squareSize={squareSize}
                          borderWidthAmount={gridLineWidth}
                        />
                      </View>
                    </View>
                    {svgLines(dimens, 'X', xAxisWidth)}
                    {svgLines(dimens, 'Y', yAxisWidth)}
                  </View>
                ) : (
                  <>
                    {svgLines(dimens, symmetryLine, isInX ? xAxisWidth : yAxisWidth)}
                    <CreateShapeFromSquaresWithState
                      id={`shape-${0}`}
                      defaultState={filledArray(filledArray(false, numberOfCols), numberOfRows)}
                      testComplete={userAnswer => filterSelectedCols(userAnswer).length > 0}
                      testCorrect={userAnswer =>
                        userAnswer.every((row, i) => arraysHaveSameContents(row, answer[0][i]))
                      }
                      numberOfRows={array.length}
                      numberOfCols={array[0].length}
                      dimens={{ width: (dimens.width / 8) * 3.5, height: dimens.height * 0.8 }}
                      squareSize={squareSize}
                      borderWidthAmount={gridLineWidth}
                      array={array}
                      ansRowOffset={isInX ? array.length / 2 : 0}
                      ansColumnOffset={isInY ? array[0].length / 2 : 0}
                    />
                  </>
                )}
              </View>
            );
          }}
        </MeasureView>
      }
      {...props}
    />
  );
}

const svgLines = (dimens: Dimens, symmetryLines: 'X' | 'Y' | 'Y=X', lineLength: number) => {
  switch (symmetryLines) {
    case 'Y':
      return (
        <View style={{ position: 'absolute', left: dimens.width / 2 - 3, zIndex: 2 }}>
          <Svg width={6} height={lineLength}>
            <Line
              x1={3}
              y1={0}
              x2={3}
              y2={lineLength}
              stroke={colors.red}
              strokeWidth={6}
              strokeDasharray={[18, 19]}
            />
          </Svg>
        </View>
      );
    case 'X':
      return (
        <View style={{ position: 'absolute', top: dimens.height / 2 - 4, zIndex: 2 }}>
          <Svg width={lineLength} height={6}>
            <Line
              x1={3}
              y1={3}
              x2={lineLength}
              y2={3}
              stroke={colors.red}
              strokeWidth={6}
              strokeDasharray={[18, 19]}
            />
          </Svg>
        </View>
      );
    case 'Y=X':
      return (
        <View style={{ position: 'absolute' }}>
          <Svg width={lineLength} height={lineLength}>
            <Line
              x1={lineLength}
              y1={0}
              x2={0}
              y2={lineLength}
              stroke={colors.red}
              strokeWidth={6}
              strokeDasharray={[18, 18]}
            />
          </Svg>
        </View>
      );
  }
};
