import { View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { AssetSvg } from '../../../assets/svg';
import Text from '../../typography/Text';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  bottleSize: 'extraLarge' | 'large' | 'medium' | 'small';
  label: string;
  dimens: Dimens;
};

export const LabelledJuiceBottle = ({ dimens, bottleSize, label }: Props) => {
  const displayMode = useContext(DisplayMode);
  const isDigital = displayMode === 'digital';
  const bottleSvg = {
    extraLarge: 'Capacity_images/Extra_large_juice_bottle',
    large: 'Capacity_images/Large_juice_bottle',
    medium: 'Capacity_images/Medium_juice_bottle',
    small: 'Capacity_images/Small_juice_bottle'
  } as const;

  return (
    <View style={{ ...dimens, alignItems: 'center' }}>
      <AssetSvg height={dimens.height} width={dimens.width} name={bottleSvg[bottleSize]} />
      <Text
        variant="WRN700"
        style={{
          fontSize: isDigital ? 35 : 60,
          position: 'absolute',
          top: isDigital ? dimens.height * 0.58 : dimens.height * 0.6
        }}
      >
        {label}
      </Text>
    </View>
  );
};
