import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { isPrime } from 'mathjs';
import { z } from 'zod';
import { DisplayShapeOnGrid } from 'common/src/components/question/representations/DisplayShapeOnGrid';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { createHundredSquareShape, reflectShapeOnX, rotateShape270 } from 'common/src/utils/shapes';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { ADD } from 'common/src/constants';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { numberEnum } from 'common/src/utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aN8',
  description: 'aN8',
  keywords: ['Hundredths', 'Fractions'],
  schema: z.object({
    numerator: z
      .number()
      .int()
      .min(5)
      .max(97)
      .refine(val => isPrime(val), 'numerator should be a prime')
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(5, 97, {
      constraint: x => isPrime(x)
    });

    return { numerator };
  },
  Component: props => {
    const {
      question: { numerator },
      translate
    } = props;

    const shape = createHundredSquareShape(numerator);

    return (
      <QF1ContentAndSentence
        sentence={`<frac nAns='' d='100' />`}
        mainPanelStyle={{ flexDirection: 'row' }}
        title={translate.instructions.eachPartOfHundredSquareIsWorthHundredthWhatFractionOfHundredSquareShaded()}
        testCorrect={[numerator.toString()]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aN9',
  description: 'aN9',
  keywords: ['Hundredths', 'Fractions'],
  schema: z
    .object({
      numeratorA: z
        .number()
        .int()
        .min(5)
        .max(99)
        .refine(val => val % 10 !== 0, 'numeratorA should not be a multiple of 10'),
      numeratorB: z.number().int().min(5).max(99),
      leftToRightOrientationA: z.boolean(),
      leftToRightOrientationB: z.boolean()
    })
    .refine(
      val => val.numeratorB !== val.numeratorA,
      'numeratorB should not be equal to numeratorA'
    ),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(5, 99, {
      constraint: x => x % 10 !== 0
    });
    const numeratorB = randomIntegerInclusive(5, 99, {
      constraint: x => x !== numeratorA
    });

    const leftToRightOrientationA = getRandomBoolean();
    const leftToRightOrientationB = getRandomBoolean();

    return { numeratorA, numeratorB, leftToRightOrientationA, leftToRightOrientationB };
  },

  Component: props => {
    const {
      question: { numeratorA, numeratorB, leftToRightOrientationA, leftToRightOrientationB },
      translate
    } = props;

    // Randomly order these shapes
    const shapes = useMemo(() => {
      const shapeA = {
        shape: leftToRightOrientationA
          ? createHundredSquareShape(numeratorA)
          : reflectShapeOnX(rotateShape270(createHundredSquareShape(numeratorA))),
        isCorrect: true
      };

      const shapeB = {
        shape: leftToRightOrientationB
          ? createHundredSquareShape(numeratorB)
          : reflectShapeOnX(rotateShape270(createHundredSquareShape(numeratorB))),
        isCorrect: false
      };

      return shuffle([shapeA, shapeB], { random: seededRandom(props.question) });
    }, [leftToRightOrientationA, leftToRightOrientationB, numeratorA, numeratorB, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.eachPartOfHundredSquareIsWorthHundredthSelectHundredSquareShowsFractionShaded(
          numeratorA
        )}
        pdfTitle={translate.instructions.eachPartOfHundredSquareIsWorthHundredthCircleHundredSquareShowsFractionShaded(
          numeratorA
        )}
        testCorrect={shapes.filter(shape => shape.isCorrect).map(shape => shape.shape)}
        numItems={2}
        titleFractionDividerStyle={{ marginVertical: 2 }}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: <DisplayShapeOnGrid givenShape={shape.shape} dimens={dimens} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aOa',
  description: 'aOa',
  keywords: ['Hundredths', 'Fractions'],
  schema: z.object({
    numerator: z
      .number()
      .int()
      .min(5)
      .max(97)
      .refine(val => isPrime(val), 'numerator should be a prime')
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(5, 97, {
      constraint: x => isPrime(x)
    });

    return { numerator };
  },
  Component: props => {
    const {
      question: { numerator },
      translate
    } = props;

    const ans = 100 - numerator;
    const shape = createHundredSquareShape(numerator);

    return (
      <QF1ContentAndSentence
        sentence={`<frac nAns='' d='100' />`}
        mainPanelStyle={{ flexDirection: 'row' }}
        title={translate.instructions.eachPartOfHundredSquareIsWorthHundredthWhatFractionOfHundredSquareIsNotShaded()}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aOb',
  description: 'aOb',
  keywords: ['Hundredths', 'Tenths', 'Fractions'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(9),
    numeratorB: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const [numeratorA, numeratorBFactor] = randomUniqueIntegersInclusive(1, 9, 3);

    const numeratorB = numeratorBFactor * 10;

    return { numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB },
      translate
    } = props;

    // Answers
    const ans1 = numeratorA * 10;
    const ans2 = numeratorB / 10;

    const sentences = [
      `<frac n='${numeratorA}' d='10'/> = <frac nAns='' d='100' />`,
      `<frac n='${numeratorB}' d='100'/> = <frac nAns='' d='10' />`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumeratorsMakeStatementsCorrect()}
        testCorrect={[[ans1.toString()], [ans2.toString()]]}
        sentences={sentences}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOc',
  description: 'aOc',
  keywords: ['Hundredths', 'Tenths', 'Fractions'],
  schema: z.object({
    numeratorA: z
      .number()
      .int()
      .min(11)
      .max(97)
      .refine(val => isPrime(val), 'numeratorA should be a prime'),
    numeratorB: z
      .number()
      .int()
      .min(11)
      .max(97)
      .refine(val => isPrime(val), 'numeratorB should be a prime')
  }),
  simpleGenerator: () => {
    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(11, 97, 2, {
      constraint: x => isPrime(x)
    });

    return { numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB },
      translate
    } = props;

    // Tens/Ones digits of numeratorA
    const tensA = Math.floor(numeratorA / 10) % 10;
    const onesA = numeratorA % 10;

    // Tens/Ones digits of numeratorB
    const tensB = Math.floor(numeratorB / 10) % 10;
    const onesB = numeratorB % 10;

    const sentences = [
      `<frac n='${numeratorA}' d='100'/> = <frac nAns='' d='10' /> ${ADD} <frac nAns='' d='100' />`,
      `<frac n='${numeratorB}' d='100'/> = <frac nAns='' d='10' /> ${ADD} <frac nAns='' d='100' />`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumeratorsMakeStatementsCorrect()}
        testCorrect={[
          [tensA.toString(), onesA.toString()],
          [tensB.toString(), onesB.toString()]
        ]}
        sentences={sentences}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aOc2',
  description: 'aOc',
  keywords: ['Hundredths', 'Tenths', 'Fractions'],
  schema: z.object({
    numeratorA: z
      .number()
      .int()
      .min(11)
      .max(97)
      .refine(val => isPrime(val), 'numeratorA should be a prime')
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(11, 97, {
      constraint: x => isPrime(x)
    });

    return { numeratorA };
  },
  Component: props => {
    const {
      question: { numeratorA },
      translate
    } = props;

    // Tens/Ones digits of numeratorA
    const tensA = Math.floor(numeratorA / 10) % 10;
    const onesA = numeratorA % 10;

    const sentence = `<frac n='${numeratorA}' d='100'/> = <frac nAns='' d='10' /> ${ADD} <frac nAns='' d='100' />`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumeratorsMakeStatementCorrect()}
        testCorrect={userAnswer =>
          parseInt(userAnswer[0]) * 10 + parseInt(userAnswer[1]) === numeratorA &&
          userAnswer.every(val => parseInt(val) !== 0)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [tensA.toString(), onesA.toString()],
          answerText: translate.markScheme.anyValidPartition()
        }}
        inputMaxCharacters={2}
        sentence={sentence}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOd',
  description: 'aOd',
  keywords: ['Hundredths', 'Tenths', 'Fractions', 'Partitioning'],
  schema: z
    .object({
      numeratorA: z.number().int().min(3).max(9),
      numeratorB: z.number().int().min(1).max(9),
      numeratorC: z.number().int().min(1).max(8),
      numeratorD: z.number().int().min(1).max(8),
      correctAnswersTotal: numberEnum([2, 3]),
      name: nameSchema
    })
    .refine(
      val => (val.numeratorC && val.numeratorD) < val.numeratorA,
      'numeratorC and numeratorD must be less than numeratorA'
    ),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(3, 9);
    const numeratorB = randomIntegerInclusive(1, 9);
    const numeratorC = randomIntegerInclusive(1, 8, {
      constraint: x => x < numeratorA
    });
    const numeratorD = randomIntegerInclusive(1, 8, {
      constraint: x => x < numeratorA
    });

    // Use either 2 or 3 correct selectable answers
    const correctAnswersTotal = getRandomFromArray([2, 3] as const);

    const name = getRandomName();

    return { numeratorA, numeratorB, numeratorC, numeratorD, correctAnswersTotal, name };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC, numeratorD, correctAnswersTotal, name },
      translate
    } = props;

    const random = seededRandom(props.question);

    const numeratorE = numeratorA * 10 + numeratorB;
    const numeratorF = numeratorE - numeratorC * 10;
    const numeratorG = numeratorE - numeratorD * 10;
    const numeratorH = numeratorA * 10;
    const numeratorI = numeratorE - numeratorA;

    // Randomly order these equations
    const correctAnswers = getRandomSubArrayFromArray(
      [
        {
          fraction: `<frac n='${numeratorA}' d='10'/> ${ADD} <frac n='${numeratorB}' d='100'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorB}' d='100'/> ${ADD} <frac n='${numeratorA}' d='10'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorC}' d='10'/> ${ADD} <frac n='${numeratorF}' d='100'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorF}' d='100'/> ${ADD} <frac n='${numeratorC}' d='10'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorD}' d='10'/> ${ADD} <frac n='${numeratorG}' d='100'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorG}' d='100'/> ${ADD} <frac n='${numeratorD}' d='10'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorH}' d='100'/> ${ADD} <frac n='${numeratorB}' d='100'/>`,
          isCorrect: true
        },
        {
          fraction: `<frac n='${numeratorB}' d='100'/> ${ADD} <frac n='${numeratorH}' d='100'/>`,
          isCorrect: true
        }
      ],
      correctAnswersTotal,
      { random }
    );

    // Randomly order these equations
    const incorrectAnswers = getRandomSubArrayFromArray(
      [
        {
          fraction: `<frac n='${numeratorA}' d='10'/> ${ADD} <frac n='${numeratorI}' d='100'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorI}' d='100'/> ${ADD} <frac n='${numeratorA}' d='10'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorA}' d='100'/> ${ADD} <frac n='${numeratorB}' d='100'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorC}' d='100'/> ${ADD} <frac n='${numeratorF}' d='100'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorE}' d='100'/> ${ADD} <frac n='${numeratorD}' d='100'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorA}' d='100'/> ${ADD} <frac n='${numeratorB}' d='10'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorC}' d='100'/> ${ADD} <frac n='${numeratorF}' d='10'/>`,
          isCorrect: false
        },
        {
          fraction: `<frac n='${numeratorD}' d='100'/> ${ADD} <frac n='${numeratorG}' d='100'/>`,
          isCorrect: false
        }
      ],
      4 - correctAnswersTotal,
      { random }
    );

    const selectables = shuffle([...correctAnswers, ...incorrectAnswers], { random });

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterHasPartitionedFracInDifferentWaysSelectAdditionsEqualToX(
          name,
          numeratorE
        )}
        pdfTitle={translate.instructions.characterHasPartitionedFracInDifferentWaysCircleAdditionsEqualToX(
          name,
          numeratorE
        )}
        // Use `flatMap` like a `filter` and a `map` combined.
        testCorrect={selectables.flatMap((selectable, idx) => (selectable.isCorrect ? [idx] : []))}
        multiSelect
        titleFractionDividerStyle={{ marginVertical: 2 }}
        items={selectables.map((selectable, idx) => ({
          value: idx,
          component: (
            <TextStructure
              fractionTextStyle={{ fontSize: 30, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
              sentence={selectable.fraction}
            />
          )
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HundredthsAsFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
