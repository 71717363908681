import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { all, create, number } from 'mathjs';
import {
  arrayHasNoDuplicates,
  countRange,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { SequenceBoxesWithState } from '../../../../components/question/representations/SequenceBoxes';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { numberEnum } from '../../../../utils/zod';
import {
  compareFractions,
  fractionToDecimal,
  formatFractionToMarkup
} from '../../../../utils/fractions';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { View } from 'react-native';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const DECIMAL = 'decimal';
const PERCENTAGE = 'percentage';
const FRACTION = 'fraction';

const Question1 = newQuestionContent({
  uid: 'aV4',
  description: 'aV4',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Greater than',
    'Less than',
    'Comparison'
  ],
  schema: z.object({
    number1: numberEnum([5, 10, 20, 25, 50]),
    number2: z.number().int().min(1).max(49),
    number3: numberEnum([5, 10, 20, 25, 50]),
    number4: z.number().int().min(1).max(24),
    number5: numberEnum([5, 10, 20, 25, 50]),
    number6: z.number().int().min(3).max(49)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([5, 10, 20, 25, 50] as const);
    const number2 = randomIntegerInclusive(1, number1 - 1, { constraint: x => x !== number1 / 2 });

    const number3 = getRandomFromArray([5, 10, 20, 25, 50] as const);
    const number4 = randomIntegerInclusive(1, Math.floor(number3 / 2) - 1);

    const number5 = getRandomFromArray([5, 10, 20, 25, 50] as const);
    const number6 = randomIntegerInclusive(Math.floor(number5 / 2) + 1, number5 - 1, {
      constraint: x => !compareFractions([number4, number3], [x, number5])
    });

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate,
      displayMode
    } = props;

    const decimalFracPerc = shuffle([DECIMAL, FRACTION, PERCENTAGE] as const, {
      random: seededRandom(props.question)
    });

    const topSentenceValues = shuffle(
      [
        {
          display: formatFractionToMarkup(number2, number1, decimalFracPerc[0]),
          value: number(math.evaluate(`${number2}/${number1}`))
        },
        { display: formatFractionToMarkup(1, 2, decimalFracPerc[1]), value: 0.5 }
      ],
      { random: seededRandom(props.question) }
    );

    const displayValues = [
      ...topSentenceValues,
      {
        display: formatFractionToMarkup(number4, number3, decimalFracPerc[2]),
        value: number(math.evaluate(`${number4}/${number3}`))
      },
      {
        display: formatFractionToMarkup(number6, number5, decimalFracPerc[0]),
        value: number(math.evaluate(`${number6}/${number5}`))
      }
    ];

    const sentences = [
      {
        sentence: `${translate.answerSentences.xIsAnsThanY(
          displayValues[0].display,
          displayValues[1].display
        )}`,
        answer:
          displayValues[0].value > displayValues[1].value
            ? translate.misc.greater()
            : translate.misc.less()
      },
      {
        sentence: `${translate.answerSentences.xIsAnsThanY(
          displayValues[2].display,
          displayValues[3].display
        )}`,
        answer:
          displayValues[2].value > displayValues[3].value
            ? translate.misc.greater()
            : translate.misc.less()
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfLayout="itemsRight"
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        items={[translate.misc.greater(), translate.misc.less()]}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        moveOrCopy="copy"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aV5',
  description: 'aV5',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Greater than',
    'Less than',
    'Equal to',
    'Comparison'
  ],
  schema: z.object({
    denominatorA: numberEnum([5, 10, 20, 25, 50, 100]),
    numeratorA: z.number().min(2).max(99),
    denominatorB: numberEnum([5, 10, 20, 25, 50, 100]),
    numeratorB: z.number().min(2).max(99),
    greaterLessOrEqual: z.array(z.enum(['<', '=', '>'])).length(2)
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = getRandomSubArrayFromArray(
      [5, 10, 20, 25, 50, 100] as const,
      2
    );

    const numeratorA = randomIntegerInclusive(2, denominatorA - 1);
    const numeratorB = randomIntegerInclusive(2, denominatorB - 1);

    const greaterLessOrEqual = countRange(2).map(() =>
      getRandomFromArray(['<', '=', '>'] as const)
    );

    return { denominatorA, denominatorB, numeratorA, numeratorB, greaterLessOrEqual };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, numeratorA, numeratorB, greaterLessOrEqual },
      translate,
      displayMode
    } = props;

    const decimalFracPerc = shuffle([DECIMAL, FRACTION, PERCENTAGE] as const, {
      random: seededRandom(props.question)
    });

    const numeratorA2 =
      greaterLessOrEqual[0] === '<'
        ? numeratorA + 1
        : greaterLessOrEqual[0] === '>'
        ? numeratorA - 1
        : numeratorA;

    const numeratorB2 =
      greaterLessOrEqual[1] === '<'
        ? numeratorB + 1
        : greaterLessOrEqual[1] === '>'
        ? numeratorB - 1
        : numeratorB;

    const displayValues = [
      formatFractionToMarkup(numeratorA, denominatorA, decimalFracPerc[0]),
      formatFractionToMarkup(numeratorA2, denominatorA, decimalFracPerc[1]),
      formatFractionToMarkup(numeratorB, denominatorB, decimalFracPerc[2]),
      formatFractionToMarkup(numeratorB2, denominatorB, decimalFracPerc[0])
    ];

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: 200, alignItems: 'flex-end' }}>
            {' '}
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={displayValues[0]}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: 200, alignItems: 'flex-start' }}>
            {' '}
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={displayValues[1]}
            />
          </View>
        ),
        correctAnswer: greaterLessOrEqual[0]
      },
      {
        lhsComponent: (
          <View style={{ width: 200, alignItems: 'flex-end' }}>
            {' '}
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={displayValues[2]}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: 200, alignItems: 'flex-start' }}>
            {' '}
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={displayValues[3]}
            />
          </View>
        ),
        correctAnswer: greaterLessOrEqual[1]
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aV6',
  description: 'aV6',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Number line',
    'Estimate'
  ],
  schema: z.object({
    denominator: numberEnum([5, 10, 20, 25, 50, 100]),
    numerator: z.number().int().min(1).max(99),
    decimalFracPerc: z.array(z.enum([DECIMAL, FRACTION, PERCENTAGE])).length(2)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([5, 10, 20, 25, 50, 100] as const);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const decimalFracPerc = getRandomSubArrayFromArray([DECIMAL, FRACTION, PERCENTAGE] as const, 2);

    return { denominator, numerator, decimalFracPerc };
  },
  Component: props => {
    const {
      question: { denominator, numerator, decimalFracPerc },
      translate
    } = props;

    const startNumber = 0;
    const endNumber = 1;
    let tickValues;
    switch (decimalFracPerc[1]) {
      case DECIMAL: {
        tickValues = range(0, 10).map(i => number(math.evaluate(`${i} / 10`)).toLocaleString());
        break;
      }
      case PERCENTAGE: {
        tickValues = range(0, 100, 10).map(i => `${i.toLocaleString()}%`);
        break;
      }
      case FRACTION: {
        tickValues = range(0, 5).map(i =>
          i === 0 || i === 5
            ? (i / 5).toLocaleString()
            : `<frac n='${i.toLocaleString()}' d='${(5).toLocaleString()}' />`
        );
        break;
      }
    }

    const displayValue = formatFractionToMarkup(numerator, denominator, decimalFracPerc[0]);
    const correctAnswer = number(math.evaluate(`${numerator}/${denominator}`));

    const [acceptableRangeLower, acceptableRangeUpper] = (() => {
      switch (decimalFracPerc[1]) {
        case 'decimal':
        case 'percentage':
          return [correctAnswer - 0.01, correctAnswer + 0.01];
        case 'fraction':
          return [correctAnswer - 0.02, correctAnswer + 0.02];
      }
    })();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(displayValue)}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(displayValue)}
        testCorrect={[acceptableRangeLower, acceptableRangeUpper]}
        min={startNumber}
        max={endNumber}
        sliderStep={0.01}
        tickValues={tickValues}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aV7',
  description: 'aV7',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Greatest',
    'Smallest',
    'Comparison',
    'Ascending',
    'Descending',
    'Order'
  ],
  schema: z.object({
    denominatorA: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorB: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorC: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorD: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    numeratorA: z.number().int().min(1).max(99),
    numeratorB: z.number().int().min(1).max(99),
    numeratorC: z.number().int().min(1).max(99),
    numeratorD: z.number().int().min(1).max(99),
    ordering: z.enum(['ascending', 'descending'])
  }),
  questionHeight: 800,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominatorA = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorB = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorC = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorD = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);

        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
        const numeratorD = randomIntegerInclusive(1, denominatorD - 1);

        const ordering = getRandomFromArray(['ascending', 'descending'] as const);

        return {
          denominatorA,
          denominatorB,
          denominatorC,
          denominatorD,
          numeratorA,
          numeratorB,
          numeratorC,
          numeratorD,
          ordering
        };
      },
      val =>
        arrayHasNoDuplicates([
          fractionToDecimal(val.numeratorA, val.denominatorA),
          fractionToDecimal(val.numeratorB, val.denominatorB),
          fractionToDecimal(val.numeratorC, val.denominatorC),
          fractionToDecimal(val.numeratorD, val.denominatorD)
        ])
    ),
  Component: props => {
    const {
      question: {
        denominatorA,
        denominatorB,
        denominatorC,
        denominatorD,
        numeratorA,
        numeratorB,
        numeratorC,
        numeratorD,
        ordering
      },
      translate,
      displayMode
    } = props;
    const labels: ('Smallest' | 'Greatest')[] =
      ordering === 'ascending' ? ['Smallest', 'Greatest'] : ['Greatest', 'Smallest'];

    const decimalFracPerc = shuffle([DECIMAL, FRACTION, PERCENTAGE] as const, {
      random: seededRandom(props.question)
    });

    const items = shuffle(
      [
        {
          string: formatFractionToMarkup(numeratorA, denominatorA, decimalFracPerc[0]),
          value: number(math.evaluate(`${numeratorA}/${denominatorA}`))
        },
        {
          string: formatFractionToMarkup(numeratorB, denominatorB, decimalFracPerc[1]),
          value: number(math.evaluate(`${numeratorB}/${denominatorB}`))
        },
        {
          string: formatFractionToMarkup(numeratorC, denominatorC, decimalFracPerc[2]),
          value: number(math.evaluate(`${numeratorC}/${denominatorC}`))
        },
        {
          string: formatFractionToMarkup(numeratorD, denominatorD, decimalFracPerc[0]),
          value: number(math.evaluate(`${numeratorD}/${denominatorD}`))
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragCardsToPutFracDecPercInOrder(
          translate.keywords[labels[0]]()
        )}
        pdfTitle={translate.instructions.useCardsToPutFracDecPercInOrder(
          translate.keywords[labels[0]]()
        )}
        topLabel={translate.keywords[labels[0]]()}
        bottomLabel={translate.keywords[labels[1]]()}
        items={items.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              textVariant="WRN700"
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          )
        }))}
        draggableVariant="rectangle"
        testCorrect={sortNumberArray(
          items.map(({ value }) => value),
          ordering
        )}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aV8',
  description: 'aV8',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Greatest',
    'Smallest',
    'Comparison',
    'Order'
  ],
  schema: z.object({
    denominatorA: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorB: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorC: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorD: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    denominatorE: numberEnum([4, 5, 10, 20, 25, 50, 100]),
    numeratorA: z.number().int().min(1).max(99),
    numeratorB: z.number().int().min(1).max(99),
    numeratorC: z.number().int().min(1).max(99),
    numeratorD: z.number().int().min(1).max(99),
    numeratorE: z.number().int().min(1).max(99),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominatorA = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorB = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorC = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorD = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);
        const denominatorE = getRandomFromArray([4, 5, 10, 20, 25, 50, 100] as const);

        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
        const numeratorD = randomIntegerInclusive(1, denominatorD - 1);
        const numeratorE = randomIntegerInclusive(1, denominatorE - 1);

        const ordering = getRandomFromArray(['ascending', 'descending'] as const);

        return {
          denominatorA,
          denominatorB,
          denominatorC,
          denominatorD,
          denominatorE,
          numeratorA,
          numeratorB,
          numeratorC,
          numeratorD,
          numeratorE,
          ordering
        };
      },
      val =>
        arrayHasNoDuplicates([
          fractionToDecimal(val.numeratorA, val.denominatorA),
          fractionToDecimal(val.numeratorB, val.denominatorB),
          fractionToDecimal(val.numeratorC, val.denominatorC),
          fractionToDecimal(val.numeratorD, val.denominatorD),
          fractionToDecimal(val.numeratorE, val.denominatorE)
        ])
    ),
  Component: props => {
    const {
      question: {
        denominatorA,
        denominatorB,
        denominatorC,
        denominatorD,
        denominatorE,
        numeratorA,
        numeratorB,
        numeratorC,
        numeratorD,
        numeratorE,
        ordering
      },
      translate,
      displayMode
    } = props;
    const labels: ('Smallest' | 'Greatest')[] =
      ordering === 'ascending' ? ['Smallest', 'Greatest'] : ['Greatest', 'Smallest'];

    const decimalFracPerc = shuffle([DECIMAL, FRACTION, PERCENTAGE] as const, {
      random: seededRandom(props.question)
    });

    const items = shuffle(
      [
        {
          string: formatFractionToMarkup(numeratorA, denominatorA, decimalFracPerc[0]),
          value: number(math.evaluate(`${numeratorA}/${denominatorA}`))
        },
        {
          string: formatFractionToMarkup(numeratorB, denominatorB, decimalFracPerc[1]),
          value: number(math.evaluate(`${numeratorB}/${denominatorB}`))
        },
        {
          string: formatFractionToMarkup(numeratorC, denominatorC, decimalFracPerc[2]),
          value: number(math.evaluate(`${numeratorC}/${denominatorC}`))
        },
        {
          string: formatFractionToMarkup(numeratorD, denominatorD, decimalFracPerc[0]),
          value: number(math.evaluate(`${numeratorD}/${denominatorD}`))
        },
        {
          string: formatFractionToMarkup(numeratorE, denominatorE, decimalFracPerc[1]),
          value: number(math.evaluate(`${numeratorE}/${denominatorE}`))
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions.dragCardsToPutFracDecPercInOrder(
          translate.keywords[labels[0]]()
        )}
        pdfTitle={translate.instructions.useCardsToPutFracDecPercInOrder(
          translate.keywords[labels[0]]()
        )}
        leftLabel={translate.keywords[labels[0]]()}
        rightLabel={translate.keywords[labels[1]]()}
        items={items.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              textVariant="WRN700"
              textStyle={
                displayMode === 'digital' && {
                  fontSize: 26
                }
              }
              fractionTextStyle={[
                displayMode === 'digital' && { fontSize: 26 },
                { fontWeight: '700' }
              ]}
              fractionDividerStyle={{
                marginVertical: 2
              }}
            />
          )
        }))}
        testCorrect={sortNumberArray(
          items.map(({ value }) => value),
          ordering
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aV9',
  description: 'aV9',
  keywords: [
    'Fraction',
    'Decimal',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Greatest',
    'Smallest',
    'Comparison',
    'Order'
  ],
  schema: z
    .object({
      number1: z.number().int().min(1).max(3),
      number2: z.number().int().min(1).max(4),
      number3: z.number().int().min(1).max(9),
      number4: z.number().int().min(1).max(9),
      number5: z.number().int().min(1).max(19),
      number6: z.number().int().min(1).max(24),
      number7: z.number().int().min(1).max(49),
      number8: z.number().int().min(1).max(99),
      ordering: z.enum(['ascending', 'descending']),
      fracDecPerc: z.array(z.enum([FRACTION, DECIMAL, PERCENTAGE])).length(9)
    })
    .refine(val =>
      arrayHasNoDuplicates([
        val.number1 / 4,
        val.number2 / 5,
        val.number3 / 10,
        val.number4 / 10,
        val.number5 / 20,
        val.number6 / 25,
        val.number7 / 50,
        val.number8 / 100
      ])
    ),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([1, 3] as const);
    const number2 = randomIntegerInclusive(1, 4);
    const [number3, number4] = randomUniqueIntegersInclusive(1, 9, 2, {
      constraint: x => x !== 5 && x / 10 !== number1 / 4 && x / 10 !== number2 / 5
    });
    const number5 = randomIntegerInclusive(1, 19, {
      constraint: x =>
        x !== 10 &&
        x / 20 !== number1 / 4 &&
        x / 20 !== number2 / 5 &&
        x / 20 !== number3 / 10 &&
        x / 20 !== number4 / 10
    });
    const number6 = randomIntegerInclusive(1, 24, {
      constraint: x =>
        x / 25 !== number2 / 5 &&
        x / 25 !== number3 / 10 &&
        x / 25 !== number4 / 10 &&
        x / 25 !== number5 / 20
    });
    const number7 = randomIntegerInclusive(1, 49, {
      constraint: x =>
        x !== 25 &&
        x / 50 !== number2 / 5 &&
        x / 50 !== number3 / 10 &&
        x / 50 !== number4 / 10 &&
        x / 50 !== number5 / 20 &&
        x / 50 !== number6 / 25
    });
    const number8 = randomIntegerInclusive(1, 99, {
      constraint: x =>
        x !== 50 &&
        x / 100 !== number1 / 4 &&
        x / 100 !== number2 / 5 &&
        x / 100 !== number3 / 10 &&
        x / 100 !== number4 / 10 &&
        x / 100 !== number5 / 20 &&
        x / 100 !== number6 / 25 &&
        x / 100 !== number7 / 50
    });
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const fracDecPerc = shuffle([
      FRACTION,
      DECIMAL,
      PERCENTAGE,
      FRACTION,
      DECIMAL,
      PERCENTAGE,
      FRACTION,
      DECIMAL,
      PERCENTAGE
    ] as const);

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      ordering,
      fracDecPerc
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        ordering,
        fracDecPerc
      },
      translate,
      displayMode
    } = props;

    const fullList = [
      [number1, 4],
      [number2, 5],
      [number3, 10],
      [number4, 10],
      [number5, 20],
      [number6, 25],
      [number7, 50],
      [number8, 100],
      [1, 2]
    ];

    const orderedList = sortNumberArray(
      fullList.map(i => number(math.evaluate(`${i[0]}/${i[1]}`))),
      ordering
    );

    const orderedFullList = orderedList.map(
      i => fullList.filter(j => number(math.evaluate(`${j[0]}/${j[1]}`)) === i)[0]
    );

    const statements = shuffle(
      [
        {
          sentence: formatFractionToMarkup(
            orderedFullList[4][0],
            orderedFullList[4][1],
            fracDecPerc[4]
          ),
          value: 'A',
          correct: true
        },
        {
          sentence: formatFractionToMarkup(
            orderedFullList[5][0],
            orderedFullList[5][1],
            fracDecPerc[5]
          ),
          value: 'B',
          correct: true
        },
        {
          sentence: formatFractionToMarkup(
            orderedFullList[7][0],
            orderedFullList[7][1],
            fracDecPerc[7]
          ),
          value: 'C',
          correct: false
        },
        {
          sentence: formatFractionToMarkup(
            orderedFullList[1][0],
            orderedFullList[1][1],
            fracDecPerc[1]
          ),
          value: 'D',
          correct: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct);
    const title =
      ordering === 'ascending'
        ? translate.instructions.theFractionsAreInAscendingOrderSelectGap()
        : translate.instructions.theFractionsAreInDescendingOrderSelectGap();
    const pdfTitle =
      ordering === 'descending'
        ? translate.instructions.theFractionsAreInAscendingOrderCircleGap()
        : translate.instructions.theFractionsAreInDescendingOrderCircleGap();

    return (
      <QF11SelectImagesUpTo4WithContent
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={correctAnswers.map(it => it.value)}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <SequenceBoxesWithState
            id="seq1"
            boxes={[
              [
                formatFractionToMarkup(
                  orderedFullList[0][0],
                  orderedFullList[0][1],
                  fracDecPerc[0]
                ),
                formatFractionToMarkup(
                  orderedFullList[2][0],
                  orderedFullList[2][1],
                  fracDecPerc[2]
                ),
                formatFractionToMarkup(
                  orderedFullList[3][0],
                  orderedFullList[3][1],
                  fracDecPerc[3]
                ),
                '',
                formatFractionToMarkup(
                  orderedFullList[6][0],
                  orderedFullList[6][1],
                  fracDecPerc[6]
                ),
                formatFractionToMarkup(orderedFullList[8][0], orderedFullList[8][1], fracDecPerc[8])
              ]
            ]}
            dimens={dimens}
          />
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: (
            <TextStructure
              sentence={sentence}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              textVariant="WRN700"
            />
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderFractionsDecimalsAndPercentages',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
