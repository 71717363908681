import { newBlockContent } from '../../../Block';

import BeforeAndAfter from './1BeforeAndAfter';
import DaysOfTheWeek from './2DaysOfTheWeek';
import MonthsOfTheYear from './3MonthsOfTheYear';
import HoursMinutesAndSeconds from './4HoursMinutesAndSeconds';
import TellTheTimeToTheHour from './5TellTheTimeToTheHour';
import TellTheTimeToTheHalfHour from './6TellTheTimeToTheHalfHour';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [10, 11],
  smallSteps: [
    BeforeAndAfter, // Step 1
    DaysOfTheWeek, // Step 2
    MonthsOfTheYear, // Step 3
    HoursMinutesAndSeconds, // Step 4
    TellTheTimeToTheHour, // Step 5
    TellTheTimeToTheHalfHour // Step 6
  ]
});
export default Block;
