import Svg, { SvgProps, Circle, Path } from 'react-native-svg';
import { memo } from 'react';
const SvgComponent = (props: SvgProps) => (
  <Svg fill="none" color="#6c767c" viewBox="0 0 300 300" {...props}>
    <Circle cx={150} cy={150} r={150} fill="currentColor" stroke="#000" />
    <Path
      fill="#000"
      fillRule="evenodd"
      d="M300 150C300 67.157 232.843 0 150 0S0 67.157 0 150s67.157 150 150 150 150-67.157 150-150zM5 150C5 69.919 69.919 5 150 5s145 64.919 145 145-64.919 145-145 145S5 230.081 5 150z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
