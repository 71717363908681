import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats, integerToWord } from '../../../../utils/math';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import { getRandomUniqueNames } from '../../../../utils/names';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';

const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const findMedian = (arr: number[]) => {
  if (arr.length === 0) return 0;

  const sortedArr = [...arr].sort((a, b) => a - b);

  const middleIndex = Math.floor(sortedArr.length / 2);

  if (sortedArr.length % 2 === 0) {
    return (sortedArr[middleIndex - 1] + sortedArr[middleIndex]) / 2;
  } else {
    return sortedArr[middleIndex];
  }
};

const findMode = (arr: number[]) => {
  if (arr.length === 0) return 0;

  const occurrences: { [key: string]: number } = arr.reduce(
    (acc: { [key: string]: number }, curr: number) => {
      acc[curr] ? acc[curr]++ : (acc[curr] = 1);
      return acc;
    },
    {}
  );

  const sortedOccurrences = Object.entries(occurrences).sort(
    ([_keyA, countA], [_keyB, countB]) => countB - countA
  );

  return parseInt(sortedOccurrences[0][0]);
};

const findRange = (arr: number[]) => {
  if (arr.length === 0) return 0;

  const sortedArr = [...arr].sort((a, b) => a - b);
  return sortedArr[sortedArr.length - 1] - sortedArr[0];
};

const Question1 = newQuestionContent({
  uid: 'aXI',
  description: 'aXI',
  keywords: ['Mean', 'Calculate', 'Set', 'Addition', 'Total', 'Division'],
  schema: z.object({
    numberOfCards: z.number().int().min(4).max(10),
    numbers: z.number().int().min(1).max(10).array(),
    answer: z.number().int().min(1).max(10),
    incorrectAnswerA: z.number().int().min(1).max(10),
    incorrectAnswerB: z.number().int().min(1).max(10),
    incorrectAnswerC: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const numberOfCards = randomIntegerInclusive(4, 10);
    const { numbers, answer } = rejectionSample(
      () => {
        const numbers = countRange(numberOfCards).map(() => randomIntegerInclusive(1, 10));
        const answer = numbers.reduce((prev, current) => prev + current) / numberOfCards;

        return { numbers, answer };
      },
      ({ answer }) => answer % 1 === 0
    );

    const median = Math.floor(findMedian(numbers));
    const incorrectAnswerA =
      median !== answer ? median : randomIntegerInclusive(1, 10, { constraint: x => x !== answer });

    const mode = Math.floor(findMode(numbers));
    const incorrectAnswerB = ![answer, incorrectAnswerA].includes(mode)
      ? mode
      : randomIntegerInclusive(1, 10, {
          constraint: x => ![answer, incorrectAnswerA].includes(x)
        });

    const range = Math.floor(findRange(numbers));
    const incorrectAnswerC =
      ![answer, incorrectAnswerA, incorrectAnswerB].includes(range) && range > 0
        ? range
        : randomIntegerInclusive(1, 10, {
            constraint: x => ![answer, incorrectAnswerA, incorrectAnswerB].includes(x)
          });

    return {
      numberOfCards,
      numbers,
      answer,
      incorrectAnswerA,
      incorrectAnswerB,
      incorrectAnswerC
    };
  },

  Component: props => {
    const {
      question: { numbers, answer, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC },
      translate,
      displayMode
    } = props;

    // Items
    const statements = shuffle(
      [
        {
          component: answer.toLocaleString(),
          value: answer
        },
        {
          component: incorrectAnswerA.toLocaleString(),
          value: incorrectAnswerA
        },
        {
          component: incorrectAnswerB.toLocaleString(),
          value: incorrectAnswerB
        },
        {
          component: incorrectAnswerC.toLocaleString(),
          value: incorrectAnswerC
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheMeanOfTheSetOfNumbers()}
        pdfTitle={translate.instructions.selectTheMeanOfTheSetOfNumbersPdf()}
        testCorrect={[answer]}
        numItems={4}
        contentContainerStyle={{
          flexDirection: 'row',
          columnGap: 8,
          justifyContent: 'center',
          flex: 1
        }}
        Content={() =>
          numbers.map<JSX.Element>((number, index) => (
            <ContentBox
              key={index}
              containerStyle={{
                alignSelf: 'center',
                height: displayMode === 'digital' ? 100 : null,
                width: 75
              }}
            >
              <Text variant="WRN400">{number.toLocaleString()}</Text>
            </ContentBox>
          ))
        }
        renderItems={statements.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component}</Text>
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aXJ',
  description: 'aXJ',
  keywords: ['Mean', 'Calculate', 'Set', 'Addition', 'Total', 'Division'],
  schema: z.object({
    firstYear: z.number().int().min(1990).max(2020),
    numberOfCards: z.number().int().min(1).max(200).array(),
    mean: z.number().int().min(1).max(200)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const firstYear = randomIntegerInclusive(1990, 2020);

    const { numberOfCards, mean } = rejectionSample(
      () => {
        const numberOfCards = countRange(4).map(() => randomIntegerInclusive(1, 200));
        const sum = numberOfCards.reduce((prev, current) =>
          number(math.evaluate(`${prev} + ${current}`))
        );

        const mean = number(math.evaluate(`${sum} / ${4}`));

        return { numberOfCards, mean };
      },
      ({ mean }) => mean % 1 === 0
    );

    return { firstYear, numberOfCards, mean };
  },
  Component: props => {
    const {
      question: { firstYear, numberOfCards, mean },
      translate
    } = props;

    const tableData = numberOfCards.map((value, index) => [
      (firstYear + index).toString(), // this is a year, we don't want local string because we don't want a comma
      value.toLocaleString()
    ]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMeanNumberOfCardsCollectedPerYear()}
        testCorrect={[mean.toString()]}
        questionHeight={800}
        Content={() => (
          <CustomizableTable
            cellHeaders={[translate.tableHeaders.Year(), translate.tableHeaders.numberOfCards()]}
            tableData={tableData}
          />
        )}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXK',
  description: 'aXK',
  keywords: ['Mean', 'Calculate', 'Set', 'Addition', 'Total', 'Division'],
  schema: z.object({
    numberOfRows: z.number().int().min(3).max(4),
    numbers: z.number().int().min(0).max(8).array(),
    characters: z.string().array(),
    mean: z.number().int().min(0).max(8),
    answerRowIndex: z.number().int().min(0).max(3),
    collection: z.enum(['pets', 'siblings'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const numberOfRows = randomIntegerInclusive(3, 4);
    const answerRowIndex = randomIntegerInclusive(1, numberOfRows - 1);
    const collection = getRandomFromArray(['pets', 'siblings'] as const);
    const characters = getRandomUniqueNames(numberOfRows);

    const { numbers, mean } = rejectionSample(
      () => {
        const numbers = countRange(numberOfRows).map(() => randomIntegerInclusive(0, 8));
        const sum = numbers.reduce((prev, current) =>
          number(math.evaluate(`${prev} + ${current}`))
        );

        const mean = sum > 0 ? number(math.evaluate(`${sum} / ${numberOfRows}`)) : 0;

        return { numbers, mean };
      },
      ({ mean }) => mean > 0 && mean % 1 === 0
    );

    return { numberOfRows, numbers, collection, mean, characters, answerRowIndex };
  },
  Component: props => {
    const {
      question: { numbers, collection, mean, characters, answerRowIndex },
      translate
    } = props;

    const tableData = numbers.map((value, index) => [
      `${characters[index]}`,
      index === answerRowIndex ? '<ans/>' : value.toLocaleString()
    ]);

    return (
      <QF7InteractiveTable
        title={translate.instructions.meanNumberOfXisYWorkOutMissingValue(
          translate.misc[collection](),
          mean.toLocaleString()
        )}
        cellHeaders={[
          translate.misc.Name(),
          collection === 'pets'
            ? translate.tableHeaders.numberOfPets()
            : translate.tableHeaders.numberOfSiblings()
        ]}
        tableData={tableData}
        testCorrect={[numbers[answerRowIndex].toString()]}
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aXL',
  description: 'aXL',
  keywords: [
    'Mean',
    'Calculate',
    'Set',
    'Addition',
    'Total',
    'Division',
    'Decimals',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    numberOfCards: z.number().int().min(3).max(6),
    numbers: z.number().min(0.001).max(0.999).array(),
    answer: z.number().min(0.001).max(0.999)
  }),
  simpleGenerator: () => {
    const numberOfCards = randomIntegerInclusive(3, 6);

    const { numbers, answer } = rejectionSample(
      () => {
        const numbers = countRange(numberOfCards).map(() => randomIntegerInclusive(1, 999) / 1000);
        const sum = numbers.reduce((prev, current) =>
          number(math.evaluate(`${prev} + ${current}`))
        );

        const answer = number(math.evaluate(`${sum} / ${numberOfCards}`));

        return { numbers, answer };
      },
      ({ answer }) => (answer * 1000) % 1 === 0
    );

    return {
      numberOfCards,
      numbers,
      answer
    };
  },

  Component: props => {
    const {
      question: { numbers, answer },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcMeanOfNumbers()}
        testCorrect={userAnswer => compareFloats(userAnswer.toString(), answer.toString())}
        Content={() => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            {numbers.map<JSX.Element>((number, index) => (
              <ContentBox
                key={index}
                containerStyle={{
                  alignSelf: 'center',
                  height: displayMode === 'digital' ? 100 : null,
                  margin: 8,
                  width: displayMode === 'digital' ? 125 : null
                }}
              >
                <Text variant="WRN400">{number}</Text>
              </ContentBox>
            ))}
          </View>
        )}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXM',
  description: 'aXM',
  keywords: ['Mean', 'Calculate', 'Set', 'Addition', 'Total', 'Division'],
  schema: z.object({
    numberOfCards: z.number().int().min(4).max(6),
    numbers: z.number().int().min(1).max(20).array(),
    incorrectAnswers: z.number().int().min(1).max(20).array(),
    missingIndices: z.number().min(0).max(5).array(),
    mean: z.number().min(1).max(20),
    sum: z.number().min(4).max(120)
  }),
  simpleGenerator: () => {
    const numberOfCards = randomIntegerInclusive(4, 6);
    const missingIndices = randomUniqueIntegersInclusive(
      0,
      numberOfCards - 1,
      randomIntegerInclusive(1, Math.floor(numberOfCards / 2))
    );

    const { numbers, mean, sum } = rejectionSample(
      () => {
        const numbers = countRange(numberOfCards).map(() => randomIntegerInclusive(1, 20));
        const sum = numbers.reduce((prev, current) =>
          number(math.evaluate(`${prev} + ${current}`))
        );

        const mean = number(math.evaluate(`${sum} / ${numberOfCards}`));

        return { numbers, mean, sum };
      },
      ({ mean }) => mean % 1 === 0
    );

    const incorrectAnswers = randomUniqueIntegersInclusive(1, 20, 6 - missingIndices.length, {
      constraint: x => !numbers.filter((_val, i) => missingIndices.includes(i)).includes(x)
    });

    return { numberOfCards, numbers, missingIndices, mean, incorrectAnswers, sum };
  },
  Component: props => {
    const {
      question: { numbers, missingIndices, mean, incorrectAnswers, sum },
      translate
    } = props;

    const answers = missingIndices.map(x => numbers[x]);
    const sentence = numbers
      .map((x, index) => (missingIndices.includes(index) ? `<ans/>` : x.toLocaleString()))
      .join(',  ');

    const displayedNumbers = numbers.filter((_x, index) => !missingIndices.includes(index));

    const title =
      missingIndices.length === 1
        ? translate.instructions.theMeanOfTheNumbersIsYDragCardsToFillMissingNumber
        : translate.instructions.theMeanOfTheNumbersIsYDragCardsToFillMissingNumbers;

    const pdfTitle =
      missingIndices.length === 1
        ? translate.instructions.theMeanOfTheNumbersIsYDragCardsToFillMissingNumberPdf
        : translate.instructions.theMeanOfTheNumbersIsYDragCardsToFillMissingNumbersPdf;

    const items = shuffle([...answers, ...incorrectAnswers], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentenceDrag
        title={title(mean.toLocaleString())}
        pdfTitle={pdfTitle(mean.toLocaleString())}
        sentence={sentence}
        items={items}
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={userAnswer => {
          return (
            [...userAnswer, ...displayedNumbers].reduce((prev, current) =>
              number(math.evaluate(`${prev} + ${current}`))
            ) === sum
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [answers],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aXN',
  description: 'aXN',
  keywords: ['Mean', 'Calculate', 'Set', 'Addition', 'Total', 'Division', 'Multiplication'],
  schema: z.object({
    games: z.number().int().min(3).max(10),
    numbers: z.number().int().min(1).max(8).array(),
    sum: z.number().min(3).max(80),
    mean: z.number().min(1).max(8)
  }),
  simpleGenerator: () => {
    const games = randomIntegerInclusive(3, 6);

    const { numbers, sum, mean } = rejectionSample(
      () => {
        const numbers = countRange(games).map(() => randomIntegerInclusive(1, 8));
        const sum = numbers.reduce((prev, current) =>
          number(math.evaluate(`${prev} + ${current}`))
        );

        const mean = number(math.evaluate(`${sum} / ${games}`));

        return { numbers, sum, mean };
      },
      ({ mean }) => (mean * 100) % 1 === 0
    );

    return { games, numbers, sum, mean };
  },
  Component: props => {
    const {
      question: { games, sum, mean },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        sentence={'<ans/>'}
        title={translate.instructions.hockeyTeamPlayXGamesTheMeanWasYHowManyGoalsInTotalXGames(
          integerToWord(games),
          mean.toLocaleString()
        )}
        testCorrect={[sum.toString()]}
        inputMaxCharacters={2}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheMean',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [Question5]
});
export default SmallStep;
