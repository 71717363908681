import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

const placeNames = ['Garden', 'Park', 'Playground'] as const;

export const placeSchema = z.enum(placeNames);

export function getRandomPlace() {
  return getRandomFromArray(placeNames);
}

export type PlaceName = (typeof placeNames)[number];

export const isPlace = (x: string): x is PlaceName => placeNames.includes(x as PlaceName);

export const placeAsWord = (object: PlaceName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Garden':
      return translate.places.Garden();
    case 'Park':
      return translate.places.Park();
    case 'Playground':
      return translate.places.Playground();
  }
};

// More places
export const morePlacesNames = ['Museum', 'Theme Park', 'Zoo', 'Farm'] as const;

export const morePlacesSchema = z.enum(morePlacesNames);

export function getRandomMorePlacesItem() {
  return getRandomFromArray(morePlacesNames);
}

export type MorePlacesName = (typeof morePlacesNames)[number];

export const morePlacesAsWord = (object: MorePlacesName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Museum':
      return translate.places.Museum();
    case 'Theme Park':
      return translate.places.ThemePark();
    case 'Zoo':
      return translate.places.Zoo();
    case 'Farm':
      return translate.places.Farm();
  }
};

export const busRoutePlaceNames = [
  'High street',
  'Library',
  'School',
  'Bank',
  'Park',
  'Café',
  'Post office',
  'Hospital',
  'Police station',
  'Pharmacy',
  'Florist',
  'Market',
  'College'
] as const;

export const busRoutePlaceNamesSchema = z.enum(busRoutePlaceNames);

export function getRandomBusRoutePlaceItem() {
  return getRandomFromArray(busRoutePlaceNames);
}

export function getRandomArrayOfBusRoutePlaces(number: number) {
  return getRandomSubArrayFromArray(busRoutePlaceNames, number);
}

export type BusRoutePlaceName = (typeof busRoutePlaceNames)[number];

export const busRouteNamesplaceNames = (
  object: BusRoutePlaceName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'High street':
      return translate.places.HighStreet();
    case 'Library':
      return translate.places.Library();
    case 'School':
      return translate.places.School();
    case 'Bank':
      return translate.places.Bank();
    case 'Park':
      return translate.places.Park();
    case 'Café':
      return translate.places.Cafe();
    case 'Post office':
      return translate.places.PostOffice();
    case 'Hospital':
      return translate.places.Hospital();
    case 'Police station':
      return translate.places.PoliceStation();
    case 'Pharmacy':
      return translate.places.Pharmacy();
    case 'Florist':
      return translate.places.Florist();
    case 'Market':
      return translate.places.Market();
    case 'College':
      return translate.places.College();
  }
};

export const streetNames = [
  'Green Park Road',
  'Forest Drive',
  'Summerville Street',
  'Penny Bridge',
  'Abbey Road',
  'Oxford Street',
  'Brick Lane',
  'King Street',
  'Baker Street',
  'Bond Street'
] as const;

export const streetNamesSchema = z.enum(streetNames);

export function getRandomStreetNames() {
  return getRandomFromArray(streetNames);
}

export function getRandomArrayOfStreetNames(number: number) {
  return getRandomSubArrayFromArray(streetNames, number);
}

export type StreetNames = (typeof streetNames)[number];

export const streetNamesAsWord = (street: StreetNames, translate: TranslationFunctions) => {
  return translate.streetNames[street]();
};
