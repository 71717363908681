import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import { FunctionMachinesInputWithState } from '../representations/FunctionMachines';
import { useCallback, useContext } from 'react';
import { arraysHaveSameContents } from '../../../utils/collections';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type UserAnswer = string[];

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * An array of arrays representing each function machine.
   * Every array within this rowsOfBoxes array represents a separate function machine.
   * Within each inner array, each string will correspond to the contents displayed in each text box in the same order.
   * If '<ans/>' is passed, the box will become an answer input box in this place.
   */
  rowsOfBoxes: string[][];
  /** By default, this is an array of array of empty strings '' matching the number of <ans/> in the rows of function machines. */
  initialState?: UserAnswer[];
  /** Defaults to checking that all user answer strings are non-empty. */
  testComplete?: (userAnswer: UserAnswer[]) => boolean;
  /** Either an array of arrays of the correct answers for each sentence, or a function for more complex use cases. */
  testCorrect: UserAnswer[] | ((userAnswer: UserAnswer[]) => boolean);
  /** Height of the boxes in the function machine. Optional, defaults to 150. */
  boxHeight?: number;
  /** Width of the boxes in the function machine. Optional, defaults to 150. */
  boxWidth?: number;
  /**
   * Indexes of columns that are to be shared across the entire function machine.
   * Box will show the contents of this index from the last row - entries in this index in other rows will be ignored.
   */
  sharedColumns?: number[];
  actionPanelVariant?: 'endWide' | 'bottomTall';
  extraSymbol?: ExtraSymbols;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerToDisplay?: UserAnswer[];
    answerText?: string;
  };
};

/**
 * Question Format 28: Function Machines.
 *
 * Title at the top. Displays function machines as a series of boxes, any of which could contain an answer box.
 * Multiple function machines can be passed as separate arrays within the rowsOfBoxes array.
 * The first box of each function machine is labelled 'Input', and the last box is labelled 'Output', with right-facing arrows
 * in between each box in a function machine.
 */
export default function QF28FunctionMachines({
  title,
  pdfTitle,
  rowsOfBoxes,
  initialState,
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  sharedColumns = [],
  boxWidth,
  boxHeight,
  extraSymbol,
  actionPanelVariant = 'bottomTall',
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  // Handle testCorrect
  const testCorrect = useCallback(
    (userAnswer: UserAnswer[]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp, arraysHaveSameContents);
      }
    },
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answerToDisplay
        : testCorrectProp.map(line =>
            line.map(ans => {
              // Temporary variable to convert simple string to localized string
              const temp = Number(parseSymbolsToString(ans));
              return temp.toLocaleString();
            })
          );

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <FunctionMachinesInputWithState
                  rowsOfBoxes={rowsOfBoxes}
                  sharedColumns={sharedColumns}
                  boxWidth={boxWidth}
                  boxHeight={boxHeight}
                  dimens={dimens}
                  id={'functionMachine'}
                  defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput
          inputType="numpad"
          variant={actionPanelVariant === 'bottomTall' ? 'wide' : 'tall'}
          extraSymbol={extraSymbol}
        />
      }
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <FunctionMachinesInputWithState
              testCorrect={testCorrect}
              rowsOfBoxes={rowsOfBoxes}
              sharedColumns={sharedColumns}
              boxWidth={boxWidth}
              boxHeight={boxHeight}
              dimens={dimens}
              id={'functionMachine'}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
