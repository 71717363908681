import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import { hundredSquareColors } from '../../../../theme/colors';
import { colors } from 'common/src/theme/colors';
import { countRange, filledArray, range } from '../../../../utils/collections';
import Table from '../../../../components/molecules/Table';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { compareFloats } from '../../../../utils/math';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFractions } from '../../../../utils/fractions';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQs',
  description: 'aQs',
  keywords: ['Hundredths', 'Fractions', 'Decimals'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusive(1, 9);

    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create table of colors
    const fullRowsShaded = filledArray(filledArray(shadingColor, 10), 0);

    const crossoverShaded = filledArray(shadingColor, numberOfShadedSquares);
    const crossoverNotShaded = filledArray('white', 10 - numberOfShadedSquares);
    const crossover = [...crossoverShaded, ...crossoverNotShaded];

    const notShaded = filledArray(filledArray('white', 10), 10 - 1);

    fullRowsShaded.push(crossover);
    const colors = fullRowsShaded.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 40,
            height: 40
          }}
        />
      ))
    );

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theHundredSquareRepresentsOneWholeWhatFractionAndDecimalIsRepresented()}
        sentences={["<frac nAns='' dAns=''/>", '<ans/>']}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numberOfShadedSquares, 100]) &&
          compareFloats(userAnswer[1][0], numberOfShadedSquares / 100)
        }
        Content={({ dimens }) => (
          <View style={dimens}>
            <Table items={table} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numberOfShadedSquares.toString(), (100).toLocaleString()],
            [(numberOfShadedSquares / 100).toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQt',
  description: 'aQt',
  keywords: ['Hundredths', 'Fractions', 'Decimals'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusive(11, 99);

    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create table of colors
    const numOfRows = Math.floor(numberOfShadedSquares / 10);
    const fullRowsShaded = filledArray(filledArray(shadingColor, 10), numOfRows);

    const leftover = numberOfShadedSquares - numOfRows * 10;
    const crossoverShaded = filledArray(shadingColor, leftover);
    const crossoverNotShaded = filledArray('white', 10 - leftover);
    const crossover = [...crossoverShaded, ...crossoverNotShaded];

    const notShaded = filledArray(filledArray('white', 10), 10 - numOfRows - 1);

    fullRowsShaded.push(crossover);
    const colors = fullRowsShaded.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 40,
            height: 40
          }}
        />
      ))
    );

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theHundredSquareRepresentsOneWholeWhatFractionAndDecimalIsRepresented()}
        sentences={["<frac nAns='' dAns='' />", '<ans/>']}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numberOfShadedSquares, 100]) &&
          compareFloats(userAnswer[1][0], numberOfShadedSquares / 100)
        }
        Content={({ dimens }) => (
          <View style={dimens}>
            <Table items={table} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numberOfShadedSquares.toString(), (100).toLocaleString()],
            [(numberOfShadedSquares / 100).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQu',
  description: 'aQu',
  keywords: ['Fraction', 'Decimal', 'Hundredths', 'Place value counters'],
  schema: z.object({
    number: z.number().int().min(1).max(40)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 40);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatFractionAndDecimalDoTheCountersRepresent()}
        Content={
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              columnGap: 8,
              bottom: 10
            }}
          >
            {countRange(number).map((_number, i) => (
              <AssetSvg key={i} name={'Place_value/1-100'} width={70} height={70} />
            ))}
          </View>
        }
        inputMaxCharacters={4}
        style={{ flexDirection: 'row' }}
        sentences={[`<frac nAns="" d="${(100).toLocaleString()}" />`, '<ans/>']}
        testCorrect={userAnswer =>
          userAnswer[0][0] === number.toString() && compareFloats(userAnswer[1][0], number / 100)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[number.toLocaleString()], [(number / 100).toLocaleString()]]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQv',
  description: 'aQv',
  keywords: ['Hundredths', 'Fractions', 'Decimals'],
  schema: z.object({
    numberOfShadedRows: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numberOfShadedRows = randomIntegerInclusive(1, 9);

    return { numberOfShadedRows };
  },
  Component: props => {
    const {
      question: { numberOfShadedRows },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create 10 x 10 table of colors for shaded parts
    const shadedPart = filledArray(filledArray(shadingColor, 10), numberOfShadedRows);
    const notShaded = filledArray(filledArray('white', 10), 10 - numberOfShadedRows);
    const colors = shadedPart.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 40,
            height: 40
          }}
        />
      ))
    );

    const answer = numberOfShadedRows * 10;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theHundredSquareRepresentsOneWholeWhatFractionsAndDecimalAreRepresented()}
        sentences={[
          `<frac nAns='' d='${(100).toLocaleString()}' />`,
          `<frac nAns='' d='${(10).toLocaleString()}' />`,
          '<ans/>'
        ]}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          userAnswer[0][0] === answer.toString() &&
          userAnswer[1][0] === numberOfShadedRows.toString() &&
          compareFloats(userAnswer[2][0], answer / 100)
        }
        Content={({ dimens }) => (
          <View style={dimens}>
            <Table items={table} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer.toString()],
            [numberOfShadedRows.toLocaleString()],
            [(answer / 100).toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aQv2',
  description: 'aQv',
  keywords: ['Hundredths', 'Fractions', 'Decimals'],
  schema: z.object({
    numberOfShadedRows: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numberOfShadedRows = randomIntegerInclusive(1, 9);

    return { numberOfShadedRows };
  },
  Component: props => {
    const {
      question: { numberOfShadedRows },
      translate,
      displayMode
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create 10 x 10 table of colors for shaded parts
    const shadedPart = filledArray(filledArray(shadingColor, 10), numberOfShadedRows);
    const notShaded = filledArray(filledArray('white', 10), 10 - numberOfShadedRows);
    const colors = shadedPart.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: displayMode === 'digital' ? 22 : 40,
            height: displayMode === 'digital' ? 22 : 40
          }}
        />
      ))
    );

    const answer = numberOfShadedRows * 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theHundredSquareRepresentsOneWholeWhatFractionsAndDecimalAreRepresented()}
        sentence={`<frac nAns='' d='${(100).toLocaleString()}' /> = <frac nAns='' d='${(10).toLocaleString()}' /> = <ans/>`}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          userAnswer[0] === answer.toString() &&
          userAnswer[1] === numberOfShadedRows.toString() &&
          compareFloats(userAnswer[2], answer / 100)
        }
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={dimens}>
            <Table items={table} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer.toLocaleString(),
            numberOfShadedRows.toLocaleString(),
            (answer / 100).toLocaleString()
          ]
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aQw',
  description: 'aQw',
  keywords: ['Hundredths', 'Fractions', 'Decimals'],
  schema: z.object({
    numberOfShadedSquaresA: z.number().int().min(1).max(99).step(10),
    numberOfShadedSquaresB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numberOfShadedSquaresA = randomIntegerInclusiveStep(10, 90, 10, {
      constraint: x => x % 100 !== 0
    });
    const numberOfShadedSquaresB = randomIntegerInclusive(1, 9);

    return { numberOfShadedSquaresA, numberOfShadedSquaresB };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { numberOfShadedSquaresA, numberOfShadedSquaresB },
      translate
    } = props;

    const shadingColorA = colors.pacificBlue as string;
    const shadingColorB = colors.fieryRose as string;

    // Create table of colors
    const numOfRows = numberOfShadedSquaresA / 10;
    const fullRowsShaded = filledArray(filledArray(shadingColorA, 10), numOfRows);

    const leftover = numberOfShadedSquaresA + numberOfShadedSquaresB - numOfRows * 10;

    const crossoverShaded = filledArray(shadingColorB, leftover);
    const crossoverNotShaded = filledArray('white', 10 - leftover);
    const crossover = [...crossoverShaded, ...crossoverNotShaded];

    const notShaded = filledArray(filledArray('white', 10), 10 - numOfRows - 1);

    fullRowsShaded.push(crossover);
    const colorsForTables = fullRowsShaded.concat(notShaded);

    // Create each square of the 100 square
    const table = colorsForTables.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 18,
            height: 18
          }}
        />
      ))
    );

    const answerA = numberOfShadedSquaresA / 10;
    const answerB = numberOfShadedSquaresA + numberOfShadedSquaresB;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatFractionAndDecimalIsRepresent()}
        sentences={[
          translate.answerSentences.thereAreFracAndFracShaded({
            frac1: `<frac nAns='' d="${(10).toLocaleString()}" />`,
            frac2: `<frac nAns='' d="${(100).toLocaleString()}"/>`
          }),
          translate.answerSentences.thisShowsTheDecimalAns()
        ]}
        questionHeight={800}
        pdfDirection="column"
        sentenceStyle={{ alignSelf: 'flex-start', top: 20 }}
        inputMaxCharacters={4}
        textStyle={{ fontSize: 40 }}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          userAnswer[0][0] === answerA.toString() &&
          userAnswer[0][1] === numberOfShadedSquaresB.toString() &&
          compareFloats(userAnswer[1][0], answerB / 100)
        }
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, width: dimens.width }}>
            <Table style={{ alignSelf: 'center' }} items={table} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answerA.toString(), numberOfShadedSquaresB.toLocaleString()],
            [(answerB / 100).toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQx',
  description: 'aQx',
  keywords: ['Hundredths', 'Fractions', 'Decimals', 'Mixed number'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(101).max(499)
  }),
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusive(101, 499);

    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    const hundreds = Math.floor(numberOfShadedSquares / 100);
    const remaining = numberOfShadedSquares - hundreds * 100;

    // Create table of colors
    const numOfRows = Math.floor(remaining / 10);
    const fullRowsShadedRemaining = filledArray(
      filledArray(shadingColor, 10),
      Math.floor(remaining / 10)
    );

    const leftover = remaining - numOfRows * 10;
    const crossoverShaded = filledArray(shadingColor, leftover);
    const crossoverNotShaded = filledArray('white', 10 - leftover);
    const crossover = [...crossoverShaded, ...crossoverNotShaded];

    const notShaded = filledArray(filledArray('white', 10), 10 - numOfRows - 1);

    fullRowsShadedRemaining.push(crossover);
    const remainingRowscolors = fullRowsShadedRemaining.concat(notShaded);

    const fullRowsShaded = filledArray(filledArray(shadingColor, 10), 10);

    // Create each square of the 100 square
    const generateTables = (colorsArray: string[][]) => {
      return colorsArray.map(rowColors =>
        rowColors.map((color, columnIndex) => (
          <View
            key={columnIndex}
            style={{
              backgroundColor: color,
              width: 12,
              height: 12
            }}
          />
        ))
      );
    };

    const fullTables = generateTables(fullRowsShaded);

    const remainingTable = generateTables(remainingRowscolors);

    const arrayOfFullTables = range(0, hundreds - 1).map(() => fullTables);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionAndDecimalIsRepresent()}
        sentence={"<ans/> <frac nAns='' dAns=''/> = <ans/>"}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0], userAnswer[1], userAnswer[2]],
            [hundreds, remaining, 100]
          ) && compareFloats(userAnswer[3], numberOfShadedSquares / 100)
        }
        Content={({ dimens }) => (
          <View style={{ width: dimens.width, flexDirection: 'row', columnGap: 10 }}>
            {arrayOfFullTables.map((table, i) => {
              return <Table key={i} items={table} />;
            })}
            <Table items={remainingTable} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            hundreds.toLocaleString(),
            remaining.toLocaleString(),
            (100).toLocaleString(),
            (numberOfShadedSquares / 100).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAndDecimalsHundredths',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
