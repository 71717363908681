import { TranslationFunctions } from 'common/src/i18n/i18n-types';
import { sortNumberArray } from './collections';
import isEqual from 'react-fast-compare';
import { z } from 'zod';
import { getRandomFromArray } from './random';

/**
 * Converts a 24h time (in hours and minutes) to a 12h time (in hours and minutes).
 * Note that if the hours are 0 or 12, this produces 12:xx am or 12:xx pm respectively.
 *
 * @param hours - the number of hours, an integer from 0 to 23
 * @param minutes - the number of minutes, an integer from 0 to 59. Default: 0
 * @returns 12h time in [hours, minutes]
 */
export function convert24hTo12h(
  hours: number,
  minutes = 0
): [hours: number, minutes: number, amOrPm: 'am' | 'pm'] {
  if (hours < 0 || hours > 23) {
    throw new Error('hours must be between 0 and 23');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('minutes must be between 0 and 59');
  }

  if (hours < 12) {
    const hour = hours === 0 ? 12 : hours;
    return [hour, minutes, 'am'];
  } else {
    const hour = hours === 12 ? 12 : hours - 12;
    return [hour, minutes, 'pm'];
  }
}

/**
 * Converts a 24h time (in hours and minutes) to a localized 12h time string.
 * Note that if the hours are 0 or 12, this produces 12:xx am or 12:xx pm respectively.
 *
 * Note on 12 am and 12 pm: Some sources say to avoid writing 12 am or 12 pm (without minutes), as these have
 * historically been ambiguous, see https://en.wikipedia.org/wiki/12-hour_clock#Confusion_at_noon_and_midnight.
 * For this function, we ignore these sources: we write 12 am for noon and 12 pm for midnight.
 *
 * @param translate - the localized strings object
 * @param hours - the number of hours, an integer from 0 to 23
 * @param minutes - the number of minutes, an integer from 0 to 59. Default: 0
 * @param zeroMinutesShown - by default, when minutes are 0 they are simply not shown, e.g. "8 am". Set this to true
 * to change that behaviour
 * @returns localized time string, e.g. "8 am" or "9:24 pm"
 */
export function convert24hTo12hString(
  translate: TranslationFunctions,
  hours: number,
  minutes = 0,
  zeroMinutesShown = false
) {
  const [hours12h, minutes12h, amOrPm] = convert24hTo12h(hours, minutes);

  switch (amOrPm) {
    case 'am':
      return minutes === 0 && !zeroMinutesShown
        ? translate.time.numAm(hours12h)
        : translate.time.hoursMinutesAm(
            hours12h,
            minutes12h.toLocaleString(undefined, { minimumIntegerDigits: 2 })
          );

    case 'pm':
      return minutes === 0 && !zeroMinutesShown
        ? translate.time.numPm(hours12h)
        : translate.time.hoursMinutesPm(
            hours12h,
            minutes12h.toLocaleString(undefined, { minimumIntegerDigits: 2 })
          );
  }
}

/**
 * Get the spoken version of a 12h time in hours and minutes. Uses past/to appropriately, and also half/quarter.
 *
 * @param translate - the localized strings object
 * @param hours - the number of hours, an integer from 0 to 23. Can be null, which means to just show the minutes,
 * e.g. "o'clock" or "13 past".
 * @param minutes - the number of minutes, an integer from 0 to 59.
 */
export function convert12hToSpokenString(
  translate: TranslationFunctions,
  hours: number | null,
  minutes: number
) {
  if (typeof hours === 'number' && (hours < 0 || hours > 11)) {
    throw new Error('hours must be between 0 and 11');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('hours must be between 0 and 59');
  }

  let hoursString: string;
  if (hours === null) {
    hoursString = '';
  } else {
    let closestHour = minutes > 30 ? hours + 1 : hours;
    if (closestHour === 0) {
      closestHour += 12;
    }
    hoursString = closestHour.toLocaleString();
  }

  switch (minutes) {
    case 0:
      return translate.time.XTimeOClock(hoursString);
    case 15:
      return translate.time.quarterPast(hoursString);
    case 30:
      return translate.time.halfPast(hoursString);
    case 45:
      return translate.time.quarterTo(hoursString);
    default: {
      if (minutes < 30) {
        return translate.time.minutesPast(minutes, hoursString);
      } else {
        return translate.time.minutesTo(60 - minutes, hoursString);
      }
    }
  }
}

/**
 * Adds a duration (in minutes) to a 24h time (in hours and minutes).
 *
 * @param hours - the number of hours, an integer from 0 to 23
 * @param minutes - the number of minutes, an integer from 0 to 59
 * @param duration - the duration in minutes to add, must be non-negative
 * @returns the new digital time as an array in [newHours, newMinutes].
 */
export function addDurationTo24hTime(
  hours: number,
  minutes: number,
  duration: number
): [hours: number, minutes: number] {
  if (hours < 0 || hours > 23) {
    throw new Error('hours must be between 0 and 23');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('minutes must be between 0 and 59');
  }

  const totalMinutes = minutes + duration;

  const hoursToAdd = Math.floor(totalMinutes / 60);

  let newHours = hours + hoursToAdd;

  // Check if newHours is greater than or equal to 24 and adjust it, e.g. if newHours = 25, return 1, not 25
  if (newHours >= 24) {
    newHours = newHours % 24;
  }
  const newMinutes = totalMinutes - 60 * hoursToAdd;

  return [newHours, newMinutes];
}

/**
 * Subtracts a duration (in minutes) from a 24h time (in hours and minutes).
 *
 * @param hours - the number of hours, an integer from 0 to 23
 * @param minutes - the number of minutes, an integer from 0 to 59
 * @param duration - the duration in minutes to subtract, must be non-negative
 * @returns the new digital time as an array in [newHours, newMinutes].
 */
export function subtractDurationTo24hTime(
  hours: number,
  minutes: number,
  duration: number
): [hours: number, minutes: number] {
  if (hours < 0 || hours > 23) {
    throw new Error('hours must be between 0 and 23');
  }
  if (minutes < 0 || minutes > 59) {
    throw new Error('minutes must be between 0 and 59');
  }

  const totalMinutes = hours * 60 + minutes - duration;

  let newHours = Math.floor(((totalMinutes + 1440) % 1440) / 60);
  const newMinutes = ((totalMinutes % 60) + 60) % 60;

  // Adjust the hours if the minute subtraction causes the hour to decrement
  if (totalMinutes < 0 && newMinutes !== 0) {
    newHours = (newHours - 1 + 24) % 24;
  }

  return [newHours, newMinutes];
}

/**
 * Returns an array of numbers in ascending order in either am or pm
 * @param timeType Pass in a string of am or pm
 * @param times Pass in an array of objects including the timeType (am or pm), hours and minutes properties
 * @param order Either ascending or descending order
 */
export const sortNumbersByAmOrPm = (
  timeType: 'am' | 'pm',
  times: {
    timeType: 'am' | 'pm';
    hours: number;
    minutes: number;
  }[],
  order: 'ascending' | 'descending' = 'ascending'
) => {
  /* Sort the the times in either ascending or descending order */
  const sortedTimes = sortNumberArray(
    times
      .filter(time => time.timeType === timeType)
      .map(x => {
        return x.hours * 3600 + x.minutes * 60;
      }),
    order
  );

  return sortedTimes;
};

/**
 * Displays digital time in format 12:38
 *
 * @param hours - the number of hours, an integer from 0 to 23
 * @param minutes - the number of minutes, an integer from 0 to 59. Default: 0
 * @param padHoursWithZeros - by default, when hours are <10 the leading zero is shown, e.g. "08:28". Set this to true
 * to change that behaviour
 * @param twelveOrTwentyFour - hours format either '12' or '24'. Default: 24hr
 * @returns digital time as a string '12:38'
 */
export function displayDigitalTime(
  hours: number,
  minutes = 0,
  padHoursWithZeros = true,
  twelveOrTwentyFour: '24' | '12' = '24'
): string {
  if (twelveOrTwentyFour === '24') {
    const displayHours = padHoursWithZeros ? hours.toString().padStart(2, '0') : hours.toString();
    return `${displayHours}:${minutes.toString().padStart(2, '0')}`;
  } else {
    const [tweleveHours, twelveMinutes, amPm] = convert24hTo12h(hours, minutes);
    const displayHours = padHoursWithZeros
      ? tweleveHours.toString().padStart(2, '0')
      : tweleveHours.toString();
    return `${displayHours}:${twelveMinutes.toString().padStart(2, '0')} ${amPm}`;
  }
}

/**
 * Checks if the two times passed are equal. Both times need to either be am or pm.
 * @param time - time as [hour, minutes]
 * @param correctTime - time as [hour, minutes]
 */
export function compareTime(time: string[], correctTime: string[]): boolean {
  const [hours, minutes] = time;
  const [corrHour, corrMin] = correctTime;

  const checkDigits = (corrNum: string, num: string) =>
    ['0', '00'].includes(corrNum)
      ? ['0', '00'].includes(num)
      : corrNum[0] === '0'
      ? [corrNum, corrNum[1]].includes(num)
      : isEqual(num, corrNum);

  const checkHours = checkDigits(corrHour, hours);

  const checkMinutes = checkDigits(corrMin, minutes);

  return checkHours && checkMinutes;
}

export type clockColourVariantType = 'yellow' | 'blue' | 'orange';
export const clockColours = ['yellow', 'blue', 'orange'] as const;
export const clockColourVariantSchema = z.enum(clockColours);
export const getRandomClockColourVariant = () => {
  return getRandomFromArray(clockColours);
};
