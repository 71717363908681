import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';

const METRE_STICK_HEIGHT = 290;
const METRE_STICK_WIDTH = 670;

type Props = {
  rulerLength: number;
};

/**
 * An SVG of some metre sticks stacked side by side.
 * If you need to know this SVG's natural dimensions and other sizing info, use {@link MetreSticks.getRulerSizingInfo}.
 */
export default function MetreSticks({ rulerLength, scaleFactor }: Props & { scaleFactor: number }) {
  const { naturalWidth, naturalHeight, pixelsPerUnit, majorTicks } = MetreSticks.getRulerSizingInfo(
    {
      rulerLength
    }
  );

  return (
    <View
      style={{
        width: naturalWidth * scaleFactor,
        height: naturalHeight * scaleFactor,
        flexDirection: 'row'
      }}
    >
      {countRange(majorTicks).map(i => (
        <View
          key={i}
          style={{
            width: pixelsPerUnit * scaleFactor,
            height: naturalHeight * scaleFactor,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {/* The metre-stick SVG we have is upright, but our rulers must be measuring horizontal distance, so rotate.
              Note that whoever uses this ruler might flip it back again the right way */}
          <View
            style={{
              height: pixelsPerUnit * scaleFactor,
              width: naturalHeight * scaleFactor,
              transform: 'rotate(90deg)'
            }}
          >
            <AssetSvg
              name="Metre_stick"
              height={pixelsPerUnit * scaleFactor}
              width={naturalHeight * scaleFactor}
            />
          </View>
        </View>
      ))}
    </View>
  );
}

MetreSticks.getRulerSizingInfo = ({ rulerLength }: Props) => {
  // A major tick is an entire metre-stick. We always show whole sticks.
  const majorTicks = Math.ceil(rulerLength);
  const height = METRE_STICK_HEIGHT;
  // The rule is made up of metre-sticks (670 each) joined together.
  const width = majorTicks * METRE_STICK_WIDTH;
  // A metre-stick is 670 pixels.
  const pixelsPerUnit = METRE_STICK_WIDTH;
  // Pixel 0 is worth 0m, and pixel 670 is worth 1m, so no offset needed.
  const startOffset = 0;
  return {
    naturalWidth: width,
    naturalHeight: height,
    pixelsPerUnit,
    startOffset,
    majorTicks
  };
};
