import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { findFactorPairs, findFactors } from 'common/src/utils/factors';
import {
  arraysHaveSameContentsUnordered,
  arrayIntersection,
  nestedArraysHaveSameContentsUnordered,
  arrayHasNoDuplicates,
  arraysHaveSameContents
} from 'common/src/utils/collections';
import { isPrime } from 'common/src/utils/primes';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { MULT } from 'common/src/constants';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { ArrayOfObjectsColors } from 'common/src/theme/colors';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import UserInput from 'common/src/components/molecules/UserInput';
import { isEqualUnordered } from 'common/src/utils/matchers';
import BaseLayoutPDF from 'common/src/components/molecules/BaseLayoutPDF';
import { CompleteTheSentencesWithState } from 'common/src/components/molecules/CompleteTheSentences';
import { CompleteTheSentenceWithState } from 'common/src/components/molecules/CompleteTheSentence';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import Text from '../../../../components/typography/Text';
import { renderMarkSchemeProp } from '../../../../components/question/questionFormats/utils/markSchemeRender';

////
// Questions
////

// Question1 exported to anj
const Question1 = newQuestionContent({
  uid: 'amD',
  description: 'amD',
  keywords: ['Factor', 'Array', 'Common'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(10),
      columnsA: z.number().int().min(1).max(8),
      columnsB: z.number().int().min(1).max(8)
    })
    .refine(
      val => (val.rows > 6 ? val.columnsA + val.columnsB < 6 : val.columnsA + val.columnsB < 10),
      'If rows is more than 6, columnsA + columnsB must be less than 6, otherwise columnsA + columnsB must be less than 10'
    )
    .refine(val => val.columnsA !== val.columnsB, 'columnsA and columnsB must be different.'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 10);

    const columnsA = rows > 6 ? randomIntegerInclusive(1, 4) : randomIntegerInclusive(1, 8);

    const columnsB =
      rows > 6
        ? randomIntegerInclusive(1, 5 - columnsA, {
            constraint: x => x !== columnsA
          })
        : randomIntegerInclusive(1, 9 - columnsA, {
            constraint: x => x !== columnsA
          });

    return { rows, columnsA, columnsB };
  },
  Component: props => {
    const {
      question: { rows, columnsA, columnsB },
      translate
    } = props;

    const productA = rows * columnsA;
    const productB = rows * columnsB;

    const arrayColors = getRandomSubArrayFromArray(Object.values(ArrayOfObjectsColors), 2, {
      random: seededRandom(props.question)
    });

    const factorsA = findFactors(productA);
    const factorsB = findFactors(productB);
    const commonFactors = factorsA.filter(value => factorsB.includes(value)).map(i => i.toString());

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1100}
        sentence={translate.answerSentences.ansIsACommonFactorOfNumAndNum(productA, productB)}
        title={translate.instructions.useTheArraysToFindCommonFactorOfNumAndNum(productA, productB)}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [rows.toLocaleString()],
          answerText: translate.markScheme.acceptAnyCommonFactor()
        }}
        testCorrect={userAnswer => commonFactors.includes(userAnswer[0])}
        Content={({ dimens }) => {
          const widthPerCounterA = Math.min(dimens.width / 3 / columnsA, dimens.height / rows);

          const widthPerCounterB = Math.min(dimens.width / 3 / columnsB, dimens.height / rows);

          const widthPerCounter = Math.min(widthPerCounterA, widthPerCounterB);

          return (
            <View style={{ flexDirection: 'row', columnGap: 50 }}>
              <ArrayOfObjects
                rows={rows}
                columns={columnsA}
                color={arrayColors[0]}
                dimens={{
                  height: dimens.height,
                  width: dimens.width / 3
                }}
                counterSize={widthPerCounter}
              />
              <ArrayOfObjects
                rows={rows}
                columns={columnsB}
                color={arrayColors[1]}
                dimens={{
                  height: dimens.height,
                  width: dimens.width / 3
                }}
                counterSize={widthPerCounter}
              />
            </View>
          );
        }}
      />
    );
  }
});
export const amD = Question1;

// Question2 exported to Q ank
const Question2 = newQuestionContent({
  uid: 'amE',
  description: 'amE',
  keywords: ['Factor', 'Common'],
  schema: z
    .object({
      chosenFactor: z.number().int().min(2).max(10),
      numberArray: z.number().int().min(6).max(60).array().length(6)
    })
    .refine(val => {
      const numsWithChosenFactor = val.numberArray.filter(
        number => number % val.chosenFactor === 0
      );
      return numsWithChosenFactor.length > 1 && numsWithChosenFactor.length < 6;
    }, 'numberArray must contain at least 2 numbers that have chosenFactor as one of their factors, and 1 number that does not have this factor.')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    const chosenFactor = randomIntegerInclusive(2, 10);

    // Create two numbers that definitely have chosenFactor as one of their factors.
    const [number1, number2] = randomUniqueIntegersInclusive(6, 60, 2, {
      constraint: x => x % chosenFactor === 0
    });

    // Create a number that definitely does not have chosenFactor as one of its factors.
    const number3 = randomIntegerInclusive(6, 60, {
      constraint: x => x % chosenFactor !== 0
    });

    // Create five other numbers.
    const [number4, number5, number6] = randomUniqueIntegersInclusive(6, 60, 5, {
      constraint: x => x !== number1 && x !== number2 && x !== number3
    });

    const numberArray = shuffle([number1, number2, number3, number4, number5, number6]);

    return { chosenFactor, numberArray };
  },
  Component: props => {
    const {
      question: { chosenFactor, numberArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatHaveNumAsCommonFactor(chosenFactor)}
        pdfTitle={translate.instructions.circleNumbersThatHaveNumAsCommonFactor(chosenFactor)}
        testCorrect={numberArray.filter(it => it % chosenFactor === 0)}
        items={shuffle(
          numberArray.map(number => ({
            value: number,
            component: number.toLocaleString()
          })),
          { random: seededRandom(props.question) }
        )}
        multiSelect
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
export const amE = Question2;

// Question3 exported to Q anl
const Question3 = newQuestionContent({
  uid: 'amF',
  description: 'amF',
  keywords: ['Factor', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(60),
      number2: z.number().int().min(2).max(60)
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must not be the same.')
    .refine(
      val => arrayIntersection(findFactors(val.number1), findFactors(val.number2)).length > 1,
      'number1 and number2 must have at least two factors in common.'
    ),
  simpleGenerator: () => {
    // If number1 is a prime, it must be less than 30, otherwise number2 will never have two common factors.
    const number1 = randomIntegerInclusive(2, 60, {
      constraint: x => !isPrime(x) || (isPrime(x) && x < 30)
    });

    const number2 = randomIntegerInclusive(2, 60, {
      constraint: x =>
        x !== number1 && arrayIntersection(findFactors(number1), findFactors(x)).length > 1
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;
    const number1Factors = findFactors(number1);
    const number2Factors = findFactors(number2);

    const commonFactors = arrayIntersection(number1Factors, number2Factors);
    const commonFactorsAsStrings = commonFactors.map(number => number.toString());

    const factorSentence = (factors: number[]): string => {
      let result = '';
      for (let i = 0; i < factors.length; i++) {
        result += factors[i].toString();
        if (i === factors.length - 2) {
          result += ` ${translate.misc.and()} `;
        } else if (i < factors.length - 1) {
          result += ', ';
        }
      }
      return result;
    };

    // Function to create dynamic answer sentence
    const sentenceMaker = (): string => {
      let finalSentence = '';
      for (let i = 0; i < commonFactors.length; i++) {
        if (i === commonFactors.length - 1) {
          finalSentence += `${translate.misc.and()} <ans/>`;
        } else if (i === commonFactors.length - 2) {
          finalSentence += '<ans/> ';
        } else {
          finalSentence += '<ans/>, ';
        }
      }
      return finalSentence;
    };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.whatAreTheCommonFactorsOfXAndY(number1, number2)}
        testCorrect={isEqualUnordered(commonFactorsAsStrings)}
        inputMaxCharacters={2}
        sentence={sentenceMaker()}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly', alignItems: 'center' }]}>
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.factorsOfNum(number1)}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {factorSentence(number1Factors)}
            </Text>
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.factorsOfNum(number2)}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {factorSentence(number2Factors)}
            </Text>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: commonFactors.map(num => num.toLocaleString()),
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
export const amF = Question3;

const Question4 = newQuestionContent({
  uid: 'amG',
  description: 'amG',
  keywords: ['Factor', 'Common'],
  schema: z
    .object({
      var1: z.number().int().min(12).max(60),
      var2: z.number().int().min(12).max(60),
      numbers: z.array(z.number().int().min(1).max(60))
    })
    .refine(val => val.var1 !== val.var2, 'var1 and var2 must be different')
    .refine(
      val => arrayIntersection(findFactors(val.var1), findFactors(val.var2)).length > 1,
      'number1 and number2 must have at least two factors in common.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const { var1, var2, var1Factors, var2Factors } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(12, 60);
        const var2 = randomIntegerInclusive(12, 60, {
          constraint: x => x !== var1
        });
        const var1Factors = findFactors(var1);
        const var2Factors = findFactors(var2);
        return { var1, var2, var1Factors, var2Factors };
      },
      //make sure they have 2 or more common factors
      ({ var1Factors, var2Factors }) => arrayIntersection(var1Factors, var2Factors).length >= 2
    );

    const numbers = shuffle([...new Set([...var1Factors, ...var2Factors])]);

    //have the draggables be a maximum of 6 (a max of 6 can fit in 1 column)
    while (numbers.length > 6) {
      numbers.pop();
    }

    return { var1, var2, numbers: numbers };
  },
  Component: ({ question: { var1, var2, numbers }, translate }) => {
    const factorsBothArray = arrayIntersection(findFactors(var1), findFactors(var2));
    const allFactors1 = findFactors(var1);

    const factors1: number[] = [];
    const factors2: number[] = [];
    const factorsBoth: number[] = [];

    //Organize the numbers into their respective Sets
    numbers.forEach(number => {
      if (factorsBothArray.includes(number)) {
        factorsBoth.push(number);
      } else if (allFactors1.includes(number)) {
        factors1.push(number);
      } else {
        factors2.push(number);
      }
    });

    const correctOrder = [factors1, factorsBoth, factors2];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortFactorsOfXAndY(var1, var2)}
        pdfTitle={translate.instructions.sortFactorsOfXAndYPDF(var1, var2)}
        zoneNames={[
          translate.instructions.factorOfXOnly(var1),
          translate.instructions.factorOfXAndY(var1, var2),
          translate.instructions.factorOfXOnly(var2)
        ]}
        items={numbers}
        testCorrect={correctOrder}
        pdfItemVariant="pdfSquare"
        questionHeight={800}
      />
    );
  }
});
export const amG = Question4;

// Question5 exported to Q ann
const Question5 = newQuestionContent({
  uid: 'amH',
  description: 'amH',
  keywords: ['Factor', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(4).max(30),
      number2: z.number().int().min(31).max(72)
    })
    .refine(
      val => arrayIntersection(findFactors(val.number1), findFactors(val.number2)).length > 2,
      'number1 and number2 must have at least three factors in common.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(4, 30);
        const number2 = randomIntegerInclusive(31, 72);
        return { number1, number2 };
      },
      // Only permit them if they each have less than seven factors, and have at three common factors for spacing.
      ({ number1, number2 }) =>
        findFactors(number1).length < 7 &&
        findFactors(number2).length < 7 &&
        arrayIntersection(findFactors(number1), findFactors(number2)).length === 3
    );

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number1Factors = findFactors(number1);
    const number2Factors = findFactors(number2);

    const number1FactorPairs = findFactorPairs(number1);
    const number1FactorPairsAsString = number1FactorPairs.map(array =>
      array.map(number => number.toString())
    );
    const number2FactorPairs = findFactorPairs(number2);
    const number2FactorPairsAsString = number2FactorPairs.map(array =>
      array.map(number => number.toString())
    );

    const commonFactors = arrayIntersection(number1Factors, number2Factors);
    const commonFactorsAsStrings = commonFactors.map(number => number.toString());

    // SENTENCES FUNCTIONS:
    // Function to create a sentence for every factor pair
    const factorPairsSentences = (number: number, factorPairs: number[][]): string[] => {
      const sentenceArray = [`${(1).toLocaleString()} ${MULT} <ans/> = ${number.toLocaleString()}`];

      // Loop for each factor pair besides 1 x number1:
      for (let i = 1; i < factorPairs.length; i++) {
        sentenceArray.push(`${factorPairs[i][0].toLocaleString()} ${MULT} <ans/> = ${number}`);
      }

      return sentenceArray;
    };

    // Function to create dynamic answer sentence for all common factors
    const finalSentence = (): string => {
      let sentence = `${translate.answerSentences.theCommonFactorsAre()}`;
      for (let i = 0; i < commonFactors.length; i++) {
        if (i === commonFactors.length - 1) {
          sentence += `${translate.misc.and()} <ans/>`;
        } else if (i === commonFactors.length - 2) {
          sentence += '<ans/> ';
        } else {
          sentence += '<ans/>, ';
        }
      }
      return sentence;
    };

    // ANSWER CHECK FUNCTION:
    // Function to check factor pairs
    const factorPairCheck = (factorPairs: string[][], answerColumn: string[][]) => {
      const answerPairs = [];

      for (let i = 0; i < factorPairs.length; i++) {
        if (i === 0) {
          answerPairs.push(['1', answerColumn[i][0]]);
        } else {
          answerPairs.push([factorPairs[i][0], answerColumn[i][1]]);
        }
      }

      return nestedArraysHaveSameContentsUnordered(factorPairs, answerPairs);
    };

    return displayMode === 'pdf' || displayMode === 'markscheme' ? (
      <BaseLayoutPDF
        title={translate.instructions.findTheCommonFactorsOfXAndY(number1, number2)}
        mainPanelContents={
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              rowGap: 32
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                columnGap: 128,
                alignItems: 'center',
                flexDirection: 'row',
                alignSelf: 'stretch'
              }}
            >
              <CompleteTheSentencesWithState
                id="sentences_1"
                sentences={factorPairsSentences(number1, number1FactorPairs)}
                testCorrect={answer => {
                  return factorPairCheck(number1FactorPairsAsString, answer);
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  rowGap: commonFactorsAsStrings.length > 3 ? 8 : 32
                }}
                defaultState={
                  displayMode === 'markscheme'
                    ? number1FactorPairsAsString.map(it => [it[1]])
                    : undefined
                }
              />
              <CompleteTheSentencesWithState
                id="sentences_2"
                sentences={factorPairsSentences(number2, number2FactorPairs)}
                testCorrect={answer => {
                  return factorPairCheck(number2FactorPairsAsString, answer);
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  rowGap: commonFactorsAsStrings.length > 3 ? 8 : 32
                }}
                defaultState={
                  displayMode === 'markscheme'
                    ? number2FactorPairsAsString.map(it => [it[1]])
                    : undefined
                }
              />
            </View>
            <View style={{ width: '100%' }}>
              <CompleteTheSentenceWithState
                id="sentence_3"
                sentence={finalSentence()}
                testCorrect={isEqualUnordered(commonFactorsAsStrings)}
                style={{ justifyContent: 'flex-start' }}
                defaultState={displayMode === 'markscheme' ? commonFactorsAsStrings : undefined}
              />
            </View>
            {displayMode === 'markscheme' &&
              renderMarkSchemeProp(translate.markScheme.factorsInAnyOrder())}
          </View>
        }
        questionHeight={1100}
      />
    ) : (
      <BaseLayout
        title={translate.instructions.findTheCommonFactorsOfXAndY(number1, number2)}
        actionPanelVariant="endWide"
        actionPanelContents={<UserInput inputType="numpad" />}
        mainPanelContents={
          <View
            style={{
              flex: 1,
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              alignSelf: 'stretch',
              rowGap: 32
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: 'space-evenly',
                alignItems: 'center',
                flexDirection: 'row',
                alignSelf: 'stretch'
              }}
            >
              <CompleteTheSentencesWithState
                id="sentences_1"
                sentences={factorPairsSentences(number1, number1FactorPairs)}
                testCorrect={answer => {
                  return arraysHaveSameContents(
                    number1FactorPairsAsString.map(val => val[1]),
                    answer.map(val => val[0])
                  );
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  rowGap: commonFactorsAsStrings.length > 3 ? 8 : 32
                }}
              />
              <CompleteTheSentencesWithState
                id="sentences_2"
                sentences={factorPairsSentences(number2, number2FactorPairs)}
                testCorrect={answer => {
                  return arraysHaveSameContents(
                    number2FactorPairsAsString.map(val => val[1]),
                    answer.map(val => val[0])
                  );
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  rowGap: commonFactorsAsStrings.length > 3 ? 8 : 32
                }}
              />
            </View>
            <CompleteTheSentenceWithState
              id="sentence_3"
              sentence={finalSentence()}
              testCorrect={isEqualUnordered(commonFactorsAsStrings)}
              textStyle={{ fontSize: commonFactorsAsStrings.length > 3 ? 26 : 32 }}
            />
          </View>
        }
      />
    );
  },
  questionHeight: 1100
});
export const amH = Question5;

// Question6 exported to Q ano
const Question6 = newQuestionContent({
  uid: 'amI',
  description: 'amI',
  keywords: ['Factor', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(60),
      number2: z.number().int().min(2).max(60)
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must not be the same.')
    .refine(
      val => arrayIntersection(findFactors(val.number1), findFactors(val.number2)).length > 1,
      'number1 and number2 must have at least two factors in common.'
    ),
  simpleGenerator: () => {
    // If number1 is a prime, it must be less than 30, otherwise number2 will never have two common factors.
    const number1 = randomIntegerInclusive(2, 60, {
      constraint: x => !isPrime(x) || (isPrime(x) && x < 30)
    });

    const number2 = randomIntegerInclusive(2, 60, {
      constraint: x =>
        x !== number1 && arrayIntersection(findFactors(number1), findFactors(x)).length > 1
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const commonFactors = arrayIntersection(findFactors(number1), findFactors(number2));
    const commonFactorsAsStrings = commonFactors.map(number => number.toString());

    // Function to create dynamic answer sentence
    const sentenceMaker = (): string => {
      let finalSentence = '';
      for (let i = 0; i < commonFactors.length; i++) {
        if (i === commonFactors.length - 1) {
          finalSentence += `${translate.misc.and()} <ans/>`;
        } else if (i === commonFactors.length - 2) {
          finalSentence += '<ans/> ';
        } else {
          finalSentence += '<ans/>, ';
        }
      }
      return finalSentence;
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.listCommonFactorsOfXAndY(number1, number2)}
        testCorrect={answer => arraysHaveSameContentsUnordered(answer, commonFactorsAsStrings)}
        inputMaxCharacters={2}
        sentence={sentenceMaker()}
        customMarkSchemeAnswer={{
          answersToDisplay: commonFactors.map(num => num.toLocaleString()),
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
      />
    );
  }
});
export const amI = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CommonFactors',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
