import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { Path, Svg } from 'react-native-svg';
import { colors } from '../../../theme/colors';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  places: string[];
  dimens: Dimens;
  centerLabel?: string;
};

/**
 * This component renders a Intersection manipulative.
 */
export function Intersection({ places, dimens, centerLabel }: Props) {
  const displayMode = useContext(DisplayMode);
  const { width, height } = dimens;

  const styles = getStyles(width, height);

  const numberOfLines = places.length;

  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width * 0.35, height * 0.35);

  function getPoints(radius: number, angleInDeg: number, radialFactor: number = 1) {
    const radians = ((angleInDeg - 90) * Math.PI) / 180;
    return {
      x: centerX + radialFactor * radius * Math.cos(radians),
      y: centerY + radialFactor * radius * Math.sin(radians)
    };
  }

  const renderIntersections = (): JSX.Element[] => {
    const lines = [];
    let startAngle = 0;
    const angle = 360 / numberOfLines;
    // Loop to calculate and append segment paths to pieChart array
    for (let idx = 0; idx < numberOfLines; idx++) {
      // Angle of the slice
      const endAngle = startAngle + angle;

      // Calculate start and end coordinates of the arc
      const { x: startX, y: startY } = getPoints(radius, startAngle);
      const { x: endX, y: endY } = getPoints(radius, endAngle);

      // Create SVG path for the pie slice
      const lineSections = `M${startX},${startY} L${centerX},${centerY} L${endX},${endY}`;

      // Update startAngle for the next slice
      startAngle = endAngle;

      lines.push(
        <Path
          key={`slice-${idx}`}
          d={lineSections}
          fill={'none'}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
        />
      );
    }

    return lines;
  };

  const renderLabels = () => {
    let startAngle = 0;
    const angle = 360 / numberOfLines;
    const labelFontSize = displayMode === 'digital' ? 26 : 40;

    return places.map((val, idx) => {
      // Calculate start coordinates of the arc
      const { x: startX, y: startY } = getPoints(radius, startAngle, 1.3);
      // Update startAngle for the next slice
      const endAngle = startAngle + angle;
      startAngle = endAngle;

      return (
        <View
          key={`label-${idx}`}
          pointerEvents="none"
          style={{
            position: 'absolute',
            width: 150,
            height: 100,
            left: startX - 75,
            top: startY - 50,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text
            variant="WRN400"
            style={{
              fontSize: labelFontSize,
              textAlign: 'center'
            }}
          >
            {val}
          </Text>
        </View>
      );
    });
  };

  return (
    <View style={styles.container}>
      <Svg width={width} height={height}>
        {renderIntersections()}
      </Svg>
      {renderLabels()}
      {centerLabel && (
        <View
          style={{
            width: 50,
            height: 50,
            backgroundColor: 'black',
            position: 'absolute',
            top: height / 2 - 25,
            left: width / 2 - 25,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Text variant="WRN400" style={{ color: 'white' }}>
            {centerLabel}
          </Text>
        </View>
      )}
    </View>
  );
}

const getStyles = (width: number, height: number) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height
    }
  });
