// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import MultiplicationAndDivisionB from './MultiplicationAndDivisionB';
import FractionsB from './FractionsB';
import DecimalsAndPercentages from './DecimalsAndPercentages';
import PerimeterAndArea from './PerimeterAndArea';
import Statistics from './Statistics';

const Term = newTermContent({
  term: 'Spring',
  blocks: [
    MultiplicationAndDivisionB,
    FractionsB,
    DecimalsAndPercentages,
    PerimeterAndArea,
    Statistics
  ]
});
export default Term;
