import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, SUB } from 'common/src/constants';
import { aiD } from 'common/src/SchemeOfLearning/Year 4/Autumn/AdditionAndSubtraction/9EstimateAnswers';
import {
  aiA,
  aiB,
  aiC
} from 'common/src/SchemeOfLearning/Year 4/Autumn/AdditionAndSubtraction/9EstimateAnswers';
import { numbersOnlyExchangeAt } from 'common/src/utils/exchanges';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = { ...aiA, uid: 'ai4', description: 'ai4' as const };

const Question2 = { ...aiB, uid: 'ai5', description: 'ai5' as const };

const Question3 = { ...aiC, uid: 'ai6', description: 'ai6' as const };

const Question4 = { ...aiD, uid: 'ai7', description: 'ai7' as const };

// Question5 exported to Q ajA
const Question5 = newQuestionContent({
  uid: 'ai8',
  description: 'ai8',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().int().min(20000).max(70000).multipleOf(10000),
      number2: z.number().int().min(20000).max(70000).multipleOf(10000),
      number4Factor: z.number().int().min(-2400).max(2400),
      number5Factor: z.number().int().min(-2400).max(2400),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(
      val => val.number1 + val.number2 < 100000,
      'number1 + number2 must be less than 100,000'
    )
    .refine(val => val.number4Factor !== 0, 'number4Factor must not be 0')
    .refine(val => val.number5Factor !== 0, 'number5Factor must not be 0'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20000, 70000, 10000);

    const number2 = randomIntegerInclusiveStep(20000, 70000, 10000, {
      constraint: x => x + number1 < 100000
    });

    const number4Factor = randomIntegerInclusive(-2400, 2400, {
      constraint: x => x !== 0
    });

    const number5Factor = randomIntegerInclusive(-2400, 2400, {
      constraint: x => x !== 0
    });

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { number1, number2, number4Factor, number5Factor, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2, number4Factor, number5Factor, addOrSubtract },
      translate
    } = props;

    const number3 = number1 + number2;

    const number4 = number1 + number4Factor;

    const number5 = number2 + number5Factor;

    const number6 = number4 + number5;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestXToEstimateAnswer(10000)}
        testCorrect={
          addOrSubtract === 'add'
            ? [[], [number1.toString(), number2.toString(), number3.toString()]] // number1 + number2 = number3
            : [[], [number3.toString(), number2.toString(), number1.toString()]]
        } // number3 - number2 = number1
        sentences={
          addOrSubtract === 'add'
            ? [
                `${number4.toLocaleString()} ${ADD} ${number5.toLocaleString()}`,
                `<ans/> ${ADD} <ans/> = <ans/>`
              ]
            : [
                `${number6.toLocaleString()} ${SUB} ${number5.toLocaleString()}`,
                `<ans/> ${SUB} <ans/> = <ans/>`
              ]
        }
        containerStyle={{ alignItems: 'center' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        {...props}
      />
    );
  }
});
export const ai8 = Question5;

const Question6 = newQuestionContent({
  uid: 'ai9',
  description: 'ai9',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().min(17600).max(72400),
      number2: z.number().min(17600).max(72400)
    })
    .refine(
      val => val.number1 + val.number2 < 100000,
      'number1 + number2 must be less than 100,000'
    ),
  simpleGenerator: () => {
    const { randTotal1, randTotal2 } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const number1 = randomIntegerInclusiveStep(20000, 70000, 10000);
        const number2 = randomIntegerInclusiveStep(20000, 70000, 10000);
        const rand1 = randomIntegerInclusive(1, 2400);
        const randOp1 = getRandomFromArray(['add', 'subtract']);
        const randTotal1 = randOp1 === 'add' ? number1 + rand1 : number1 - rand1;
        const rand2 = randomIntegerInclusive(1, 2400);
        const randOp2 = getRandomFromArray(['add', 'subtract']);
        const randTotal2 = randOp2 === 'add' ? number2 + rand2 : number2 - rand2;
        return { randTotal1, randTotal2 };
      },
      // Only permit them if they have more than 1 exchange.
      ({ randTotal1, randTotal2 }) => numbersOnlyExchangeAt(randTotal1, randTotal2, 'ones')
    );

    return {
      number1: randTotal1,
      number2: randTotal2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const correctAnswer = number1 + number2;
    const answer1 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${correctAnswer.toLocaleString()}`;
    const incorrectAnswer1 =
      correctAnswer +
      getRandomFromArray([-10000, 10000] as const, { random: seededRandom(answer1) });
    const incorrectAnswer2 =
      (correctAnswer - (correctAnswer % 1000)) * 1000 - 9000 + (correctAnswer % 1000);

    const answer2 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${incorrectAnswer1.toLocaleString()}`;
    const answer3 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${incorrectAnswer2.toLocaleString()}`;

    const selectables = shuffle([answer1, answer2, answer3], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.useEstimationToWorkoutCorrectCalculationSelectCorrectAnswer()}
        pdfTitle={translate.instructions.useEstimationToWorkoutCorrectCalculationCircleCorrectAnswer()}
        testCorrect={[answer1]}
        itemLayout={'column'}
        itemStyle={{ margin: 8 }}
        numItems={3}
        renderItems={selectables.map(selectable => ({
          value: selectable,
          component: <Text variant="WRN700">{selectable}</Text>
        }))}
      />
    );
  }
});
export const ai9 = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToCheckAnswers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
