import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { filledArray } from 'common/src/utils/collections';
import { CreateShapeFromSquaresWithState } from '../representations/CreateShapeFromSquares';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * Number of rows
   */
  numberOfRows: number;
  /**
   * Number of columns - Will be the same amount per row
   */
  numberOfCols: number;
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  testCorrect: (userAnswer: boolean[][]) => boolean;
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: boolean[][]; answerText?: string };
};

export default function QF24CreateShapeFromSquares({
  numberOfRows,
  numberOfCols,
  title,
  pdfTitle,
  testCorrect,
  cellSizeLabel,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const filterSelectedCols = (array: boolean[][]) => {
    return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <CreateShapeFromSquaresWithState
                  id="shape"
                  defaultState={
                    displayMode === 'markscheme'
                      ? customMarkSchemeAnswer?.answerToDisplay
                      : undefined
                  }
                  numberOfRows={numberOfRows}
                  numberOfCols={numberOfCols}
                  cellSizeLabel={cellSizeLabel}
                  dimens={dimens}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <CreateShapeFromSquaresWithState
              id="shape"
              defaultState={filledArray(null, numberOfRows).map(() =>
                filledArray(false, numberOfCols)
              )}
              testComplete={answer => filterSelectedCols(answer).length > 0}
              testCorrect={testCorrect}
              numberOfRows={numberOfRows}
              numberOfCols={numberOfCols}
              cellSizeLabel={cellSizeLabel}
              dimens={dimens}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
