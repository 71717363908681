import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { ADD } from 'common/src/constants';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle,
  seededRandom,
  getRandomSubArrayFromArray
} from 'common/src/utils/random';
import {
  compareFractions,
  fractionArithmetic,
  fractionToDecimal,
  simplify
} from 'common/src/utils/fractions';
import PieChart from 'common/src/components/question/representations/PieChart';
import MultiPieChart, {
  calculateRadius
} from 'common/src/components/question/representations/MultiPieChart';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { PieChartColors } from 'common/src/theme/colors';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { filledArray } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ap3',
  description: 'ap3',
  keywords: ['Addition', 'Mixed numbers'],
  schema: z.object({
    number1: z.number().int().min(1).max(3),
    number2: z.number().int().min(3).max(8),
    number3: z.number().int().min(1).max(7),
    number4: z.number().int().min(1).max(3),
    number5: z.number().int().min(1).max(7),
    missingSlicesA: z.array(z.number().int().min(0).max(7)),
    missingSlicesB: z.array(z.number().int().min(0).max(7))
  }),
  questionHeight: 1440,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 3);
    const number4 = randomIntegerInclusive(1, 3);

    const number2 = randomIntegerInclusive(3, 8);

    const { number3, number5 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(1, number2 - 1);
        const number5 = randomIntegerInclusive(1, number2 - 1);

        return { number3, number5 };
      },
      ({ number3, number5 }) => number3 + number5 < number2
    );

    // Generate random indices for missing slices
    const missingSlicesA = randomUniqueIntegersInclusive(0, number2 - 1, number2 - number3);
    const missingSlicesB = randomUniqueIntegersInclusive(0, number2 - 1, number2 - number5);

    return { number1, number2, number3, number4, number5, missingSlicesA, missingSlicesB };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, missingSlicesA, missingSlicesB },
      translate
    } = props;

    // answer2 === number2
    const answer1 = number1 + number4;
    const answer3 = number3 + number5;
    const correctFrac = [answer1, answer3, number2];

    // Test correct for simplified answers
    const testCorrect = (answer: string[]): boolean => {
      return compareFractions([...answer], correctFrac);
    };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1440}
        title={translate.instructions.completeAddition()}
        testCorrect={testCorrect}
        inputMaxCharacters={2}
        sentence={`<frac w='${number1}' n='${number3}' d='${number2}'/> ${ADD} <frac w='${number4}' n='${number5}' d='${number2}'/> = <frac wAns='' nAns='' dAns=''/>`}
        Content={({ dimens }) => {
          // There will always be 2 rows
          const numberOfRows = 2;
          // Additional dimension of 50 for the plus sign in the middle
          const pieChartRadius = calculateRadius(dimens, answer1, numberOfRows, { width: 50 });

          const [colorA, colorB] = getRandomSubArrayFromArray(PieChartColors, 2, {
            random: seededRandom(props.question)
          });

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }
              ]}
            >
              <View>
                <MultiPieChart
                  numberOfPieCharts={number1}
                  slicesPerChart={number2}
                  radius={pieChartRadius}
                  dimens={dimens}
                  chartsPerRow={4}
                  color={colorA}
                />
                <PieChart
                  pieOptions={filledArray({ ratioOfSlices: 1 }, number2)}
                  missingSlices={missingSlicesA}
                  radius={pieChartRadius}
                  color={colorA}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 4 * (pieChartRadius + 10),
                  paddingHorizontal: 10
                }}
              >
                <Text variant="WRN400">{`${ADD}`}</Text>
              </View>
              <View>
                <MultiPieChart
                  numberOfPieCharts={number4}
                  slicesPerChart={number2}
                  color={colorB}
                  radius={pieChartRadius}
                  dimens={dimens}
                  chartsPerRow={4}
                />
                <PieChart
                  pieOptions={filledArray({ ratioOfSlices: 1 }, number2)}
                  missingSlices={missingSlicesB}
                  color={colorB}
                  radius={pieChartRadius}
                />
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac.map(ans => ans.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ap4',
  description: 'ap4',
  keywords: ['Addition', 'Mixed number'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(3).max(6),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(1).max(5),
    number5: z.number().int().min(6).max(12),
    number6: z.number().int().min(1).max(11)
  }),
  questionHeight: 600,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);
    const number2 = randomIntegerInclusive(3, 6);

    const number4 = randomIntegerInclusive(1, 5);
    const number5 = 2 * number2;

    const { number3, number6 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(1, number2 - 1);
        const number6 = randomIntegerInclusive(1, number5 - 1);

        return { number3, number6 };
      },
      ({ number3, number6 }) => 2 * number3 + number6 < number5
    );

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const answer1 = number1 + number4;
    const answer3 = 2 * number3 + number6;

    const correctFrac = [answer1, answer3, number5];

    // Question asks for certain fractions to be simplified
    const [simplifiedNumber3, simplifiedNumber2] = simplify(number3, number2);
    const [simplifiedNumber6, simplifiedNumber5] = simplify(number6, number5);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1], userAnswer[2]], correctFrac)
        }
        inputMaxCharacters={2}
        sentence={`<frac w='${number1}' n='${simplifiedNumber3}' d='${simplifiedNumber2}'/> ${ADD} <frac w='${number4}' n='${simplifiedNumber6}' d='${simplifiedNumber5}'/> = <frac wAns='' nAns='' dAns=''/>`}
        questionHeight={600}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ap5',
  description: 'ap5',
  keywords: ['Improper fractions', 'Mixed number', 'Convert'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z
      .number()
      .int()
      .min(4)
      .max(12)
      .refine(val => val % 2 === 0, 'number2 must be a multiple of 2'),
    number3: z.number().int().min(5).max(23),
    number4: z.number().int().min(5).max(13),
    answer1: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);

    const number2 = randomIntegerInclusiveStep(4, 12, 2);
    const number4 = number2 + 1;
    const number3 = randomIntegerInclusiveStep(number2 + 1, 2 * number2 - 1, 2, {
      constraint: x => x !== number4
    });

    const answer1 = getRandomFromArray([number1, number1 + 2] as const);

    return { number1, number2, number3, number4, answer1 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, answer1 },
      translate,
      displayMode
    } = props;

    const answer2 = number2;
    const answer3 = number3 - number2;
    const answer4 = number1 + 1;
    const [answer6, answer5] = simplify(number4 - number2, number2);

    const statements = [
      {
        lhsComponent: (
          <TextStructure sentence={`<frac w='${number1}' n='${number3}' d='${number2}'/>    =`} />
        ),
        correctAnswer: number1 + fractionToDecimal(number3, number2)
      },
      {
        lhsComponent: (
          <TextStructure sentence={`<frac w='${number1}' n='${number4}' d='${number2}'/>    =`} />
        ),
        correctAnswer: number1 + fractionToDecimal(number4, number2)
      }
    ];

    const answerOptions = [
      {
        component: (
          <TextStructure
            sentence={`<frac w='${answer1}' n='${answer3}' d='${answer2}'/>`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 30 : 50,
              fontWeight: '700'
            }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: number1 + fractionToDecimal(answer3, answer2)
      },
      {
        component: (
          <TextStructure
            sentence={`<frac w='${answer4}' n='${answer3}' d='${answer2}'/>`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 30 : 50,
              fontWeight: '700'
            }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: answer4 + fractionToDecimal(answer3, answer2)
      },
      {
        component:
          answer5 === 1 ? (
            (answer1 + answer6).toLocaleString()
          ) : (
            <TextStructure
              sentence={`<frac w='${answer1}' n='${answer6}' d='${answer5}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
        value: answer1 + fractionToDecimal(answer6, answer5)
      },
      {
        component:
          answer5 === 1 ? (
            (answer4 + answer6).toLocaleString()
          ) : (
            <TextStructure
              sentence={`<frac w='${answer4}' n='${answer6}' d='${answer5}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
        value: answer4 + fractionToDecimal(answer6, answer5)
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToShowMixedNumbersInTheirCorrectForm()}
        pdfTitle={translate.instructions.useCardsToShowMixedNumbersInTheirCorrectForm()}
        items={shuffledAnswerOptions}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
        useArrows={false}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ap6',
  description: 'ap6',
  keywords: ['Mixed number', 'Addition', 'Number line', 'Partition'],
  schema: z.object({
    xWhole: z.number().int().min(1).max(10),
    xNumerator: z.number().int().min(2).max(9),
    denominator: z.number().int().min(4).max(10),
    yWhole: z.number().int().min(1).max(10),
    yNumerator: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const xWhole = randomIntegerInclusive(1, 10);
    const denominator = randomIntegerInclusive(4, 10);

    const yWhole = randomIntegerInclusive(1, 10);

    const { xNumerator, yNumerator } = rejectionSample(
      () => {
        const xNumerator = randomIntegerInclusive(denominator / 2, denominator - 1);
        const yNumerator = randomIntegerInclusive(denominator / 2, denominator - 1);
        return { xNumerator, yNumerator };
      },
      ({ xNumerator, yNumerator }) => xNumerator + yNumerator > denominator
    );

    return { xWhole, denominator, xNumerator, yWhole, yNumerator };
  },
  Component: props => {
    const {
      question: { xWhole, denominator, xNumerator, yWhole, yNumerator },
      translate
    } = props;

    const answer1 = xWhole + yWhole;
    const answer2 = denominator;
    const answer3 = answer1 + 1;
    const answer4 = xNumerator + yNumerator - denominator;

    const startingNumber = xWhole + fractionToDecimal(xNumerator, denominator);
    const endNumber = answer3 + fractionToDecimal(answer4, answer2);

    // Space the jump arrows nicely
    const middle = (endNumber - startingNumber) / 2 + startingNumber;

    const tickArray = [
      {
        label: `<frac w='${xWhole}' n='${xNumerator}' d='${denominator}'/>`,
        position: startingNumber
      },
      {
        label: `<frac wAns='' n='${xNumerator}' d='${denominator}'/>`,
        position: middle
      },
      { label: `<frac wAns='' nAns='' dAns=''/>`, position: endNumber }
    ];

    const jumpArrowArray = [
      {
        start: startingNumber,
        end: middle,
        label: `${ADD} ${yWhole}`
      },
      {
        start: middle,
        end: endNumber,
        label: `${ADD} <frac n='${yNumerator}' d='${denominator}'/>`
      }
    ];

    return (
      <QF25JumpOnANumberLine
        title={translate.instructions.useNumberLineToWorkOutXAddY(
          `<frac w='${xWhole.toLocaleString()}' n='${xNumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`,
          `<frac w='${yWhole.toLocaleString()}' n='${yNumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
        )}
        start={startingNumber}
        end={endNumber}
        testCorrect={([userMiddleWhole, userEndWhole, userEndNumerator, userEndDenominator]) =>
          userMiddleWhole === answer1.toString() &&
          compareFractions(
            [userEndWhole, userEndNumerator, userEndDenominator],
            [answer3, answer4, answer2]
          )
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1.toLocaleString(),
            answer3.toLocaleString(),
            answer4.toLocaleString(),
            answer2.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'ap7',
  description: 'ap7',
  keywords: ['Add', 'Mixed numbers', 'Fractions'],
  schema: z
    .object({
      wholeA: z.number().int().min(1).max(10),
      numeratorA: z.number().int().min(1).max(10),
      wholeB: z.number().int().min(1).max(10),
      numeratorB: z.number().int().min(1).max(10),
      denominator: z.number().int().min(3).max(12)
    })
    .refine(val => val.numeratorA < val.denominator, 'numeratorA must be less than denominator.')
    .refine(val => val.numeratorB < val.denominator, 'numeratorB must be less than denominator.')
    .refine(
      val => val.numeratorA + val.numeratorB < val.denominator,
      'numeratorA + numeratorB must total to less than denominator.'
    ),
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(1, 10);

    const wholeB = randomIntegerInclusive(1, 10);

    const { numeratorA, numeratorB, denominator } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(3, 12);

        const numeratorA = randomIntegerInclusive(1, denominator - 2);

        const numeratorB = randomIntegerInclusive(1, denominator - 2, {
          constraint: x => x + numeratorA < denominator
        });
        return { numeratorA, numeratorB, denominator };
      },
      ({ numeratorA, numeratorB, denominator }) => {
        const simplifiedA = simplify(numeratorA, denominator);
        const simplifiedB = simplify(numeratorB, denominator);

        return (
          // Fractions generated must already be in their simplest forms:
          numeratorA === simplifiedA[0] &&
          denominator === simplifiedA[1] &&
          numeratorB === simplifiedB[0] &&
          denominator === simplifiedB[1]
        );
      }
    );

    return { wholeA, numeratorA, wholeB, numeratorB, denominator };
  },
  Component: props => {
    const {
      question: { wholeA, numeratorA, wholeB, numeratorB, denominator },
      translate
    } = props;

    const expectedFractionAnswer = fractionArithmetic(
      [numeratorA, denominator],
      [numeratorB, denominator],
      ADD
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          userAnswer[0] === (wholeA + wholeB).toString() &&
          compareFractions([userAnswer[1], userAnswer[2]], expectedFractionAnswer)
        }
        inputMaxCharacters={3}
        sentence={`<frac w='${wholeA.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac w='${wholeB.toLocaleString()}' n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions(),
          answersToDisplay: [
            (wholeA + wholeB).toLocaleString(),
            expectedFractionAnswer[0].toLocaleString(),
            expectedFractionAnswer[1].toLocaleString()
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ap8',
  description: 'ap8',
  keywords: ['Add', 'Mixed numbers', 'Fractions', 'Common denominator'],
  schema: z
    .object({
      wholeA: z.number().int().min(1).max(10),
      numeratorA: z.number().int().min(2).max(9),
      denominatorA: z.number().int().min(3).max(10),
      wholeB: z.number().int().min(1).max(10),
      numeratorB: z.number().int().min(1).max(15),
      denominatorB: z.number().int().min(6).max(20)
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorB.')
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.')
    .refine(
      val => val.denominatorB % val.denominatorA === 0 && val.denominatorB !== val.denominatorA,
      'denominatorB must be a multiple of denominatorA, other than denominatorA itself.'
    )
    .refine(val => {
      const fractionTotal = fractionArithmetic(
        [val.numeratorA, val.denominatorA],
        [val.numeratorB, val.denominatorB],
        ADD
      );

      return fractionToDecimal(fractionTotal[0], fractionTotal[1]) < 1;
    }, '(numeratorA/denominatorA) + (numeratorB / denominatorB) must total to less than a whole.'),
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(1, 10);

    const wholeB = randomIntegerInclusive(1, 10);

    const { numeratorA, denominatorA, numeratorB, denominatorB } = rejectionSample(
      () => {
        const denominatorA = randomIntegerInclusive(3, 10);

        const numeratorA = randomIntegerInclusive(2, denominatorA - 1);

        const denominatorB = randomIntegerInclusive(6, 20, {
          constraint: x => x % denominatorA === 0 && x !== denominatorA
        });

        const numeratorB = randomIntegerInclusive(1, Math.min(15, denominatorB - 1));

        return { numeratorA, denominatorA, numeratorB, denominatorB };
      },
      ({ numeratorA, denominatorA, numeratorB, denominatorB }) => {
        const simplifiedA = simplify(numeratorA, denominatorA);
        const simplifiedB = simplify(numeratorB, denominatorB);

        const fractionsTotal = fractionArithmetic(
          [numeratorA, denominatorA],
          [numeratorB, denominatorB],
          ADD
        );

        return (
          // Fractions generated must already be in their simplest forms:
          numeratorA === simplifiedA[0] &&
          denominatorA === simplifiedA[1] &&
          numeratorB === simplifiedB[0] &&
          denominatorB === simplifiedB[1] &&
          // Must total to less than one whole:
          fractionToDecimal(fractionsTotal[0], fractionsTotal[1]) < 1
        );
      }
    );

    return { wholeA, numeratorA, denominatorA, wholeB, numeratorB, denominatorB };
  },
  Component: props => {
    const {
      question: { wholeA, numeratorA, denominatorA, wholeB, numeratorB, denominatorB },
      translate
    } = props;

    const expectedFractionAnswer = fractionArithmetic(
      [numeratorA, denominatorA],
      [numeratorB, denominatorB],
      ADD
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          userAnswer[0] === (wholeA + wholeB).toString() &&
          compareFractions([userAnswer[1], userAnswer[2]], expectedFractionAnswer)
        }
        inputMaxCharacters={3}
        sentence={`<frac w='${wholeA.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${ADD} <frac w='${wholeB.toLocaleString()}' n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions(),
          answersToDisplay: [
            (wholeA + wholeB).toLocaleString(),
            expectedFractionAnswer[0].toLocaleString(),
            expectedFractionAnswer[1].toLocaleString()
          ]
        }}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwoMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
