import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusiveStep } from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Clock from '../../../../components/question/representations/Clock';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bk6',
  description: 'bk6',
  keywords: ['Quarter', 'Half', 'Three-quarters', 'Hour', 'Minutes', 'Time'],
  schema: z.object({
    startMinutes: z.number().int().min(0).max(59).multipleOf(5),
    durationMinutes: z.number().int().min(15).max(60).multipleOf(15)
  }),
  simpleGenerator: () => {
    const startMinutes = randomIntegerInclusiveStep(0, 59, 5);
    const durationMinutes = randomIntegerInclusiveStep(15, 60, 15);

    return { startMinutes, durationMinutes };
  },
  Component: props => {
    const {
      question: { startMinutes, durationMinutes },
      translate
    } = props;

    const timeToSpokenString = (() => {
      switch (durationMinutes) {
        case 15:
          return translate.time.aQuarterOfAnHour();
        case 30:
          return translate.time.halfAnHour();
        case 45:
          return translate.time.threeQuartersOfAnHour();
        case 60:
          return translate.time.anHour();
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyMinutesAreThereInX(timeToSpokenString)}
        sentence={translate.ks1AnswerSentences.ansMinutes()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[durationMinutes.toString()]}
        Content={({ dimens }) => (
          <Clock
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            showHands={false}
            showAngle={{
              startMinutes: startMinutes,
              endMinutes: startMinutes + durationMinutes,
              variant: 'sector'
            }}
            independentHands
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bk7',
  description: 'bk7',
  keywords: ['Hour', 'Minutes', 'Time'],
  schema: z.object({
    totalMinutes: z.number().int().min(65).max(100).multipleOf(5),
    hoursGiven: z.boolean()
  }),
  simpleGenerator: () => {
    const totalMinutes = randomIntegerInclusiveStep(65, 100, 5);

    const hoursGiven = getRandomBoolean();

    return { totalMinutes, hoursGiven };
  },
  Component: props => {
    const {
      question: { totalMinutes, hoursGiven },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          hoursGiven
            ? translate.ks1AnswerSentences.numberHoursAndNumberMinutesEqualsAnsMinutes(
                1,
                totalMinutes - 60
              )
            : translate.ks1AnswerSentences.numberMinutesEqualsAnsHourAndAnsMinutes(totalMinutes)
        }
        testCorrect={
          hoursGiven ? [totalMinutes.toString()] : [(1).toString(), (totalMinutes - 60).toString()]
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bk8',
  description: 'bk8',
  keywords: ['Quarter', 'Half', 'Three-quarters', 'Hour', 'Minutes', 'Time'],
  schema: z.object({
    timeAMinutes: z.number().int().min(5).max(100).multipleOf(5),
    timeAInAlternatePhrasingIfPossible: z.boolean(),
    timeBMinutes: z.number().int().min(65).max(100).multipleOf(5),
    timeBInAlternatePhrasingIfPossible: z.boolean(),
    timeAFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const timeAMinutes = randomIntegerInclusiveStep(5, 100, 5);

    const timeAInAlternatePhrasingIfPossible = getRandomBoolean();

    const timeBMinutes = randomIntegerInclusiveStep(65, 100, 5);

    const timeBInAlternatePhrasingIfPossible = getRandomBoolean();

    const timeAFirst = getRandomBoolean();

    return {
      timeAMinutes,
      timeAInAlternatePhrasingIfPossible,
      timeBMinutes,
      timeBInAlternatePhrasingIfPossible,
      timeAFirst
    };
  },

  Component: ({
    question: {
      timeAMinutes,
      timeAInAlternatePhrasingIfPossible,
      timeBMinutes,
      timeBInAlternatePhrasingIfPossible,
      timeAFirst
    },
    translate
  }) => {
    const timeToSpokenString = (
      minutes: number,
      alternatePhrasing: boolean,
      includeHours: boolean
    ) => {
      if (alternatePhrasing) {
        switch (minutes) {
          case 15:
            return translate.time.aQuarterOfAnHour();
          case 30:
            return translate.time.halfAnHour();
          case 45:
            return translate.time.threeQuartersOfAnHour();
          case 60:
            return translate.time.anHour();
          case 90:
            return translate.time.oneAndAHalfHours();
          default:
            return includeHours
              ? translate.time.numHoursAndNumMinutes(1, minutes - 60)
              : translate.time.numMinutes(minutes);
        }
      } else {
        return includeHours
          ? translate.time.numHoursAndNumMinutes(1, minutes - 60)
          : translate.time.numMinutes(minutes);
      }
    };

    const timeAString = timeToSpokenString(timeAMinutes, timeAInAlternatePhrasingIfPossible, false);

    const timeBString = timeToSpokenString(timeBMinutes, timeBInAlternatePhrasingIfPossible, true);

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompare()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompare()}
        items={['<', '>', '=']}
        pdfLayout="itemsHidden"
        sentence={
          timeAFirst
            ? `${timeAString} <ans/> ${timeBString}`
            : `${timeBString} <ans/> ${timeAString}`
        }
        testCorrect={
          timeAFirst
            ? [lessThanGreaterThanOrEqualTo(timeAMinutes, timeBMinutes)]
            : [lessThanGreaterThanOrEqualTo(timeBMinutes, timeAMinutes)]
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MinutesInAnHour',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
