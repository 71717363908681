import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  displayMoney,
  isValidMoneyAnswer,
  moneyToHighestDenominations,
  numToCurrency,
  totalPenceToPoundsAndPence
} from 'common/src/utils/money';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { isValidDecimalString, lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import Text from 'common/src/components/typography/Text';
import { View } from 'react-native';
import { numberEnum } from 'common/src/utils/zod';
import { getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import { isInRangeExclusive } from 'common/src/utils/matchers';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { arrayHasNoDuplicates, sortNumberArray, swapElements } from 'common/src/utils/collections';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axi',
  description: 'axi',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence'],
  schema: z.object({
    moneyInPence: z
      .number()
      .int()
      .min(1023)
      .max(5987)
      .refine(money => money % 100 !== 0, 'money should not be a multiple of 100'),
    uniqueDigit: z.number().int().min(1).max(9),
    inPoundsOrPence: z.enum(['£', 'p'])
  }),
  simpleGenerator: () => {
    const moneyInPence = randomIntegerInclusive(1001, 5999, {
      constraint: x => x % 100 !== 0 && arrayHasNoDuplicates([...x.toString()])
    });

    const nonZeroDigits = [...moneyInPence.toString()].filter(digit => digit !== '0');
    const uniqueDigit = Number(getRandomFromArray(nonZeroDigits));

    const inPoundsOrPence = getRandomFromArray(['£', 'p'] as const);

    return { moneyInPence, uniqueDigit, inPoundsOrPence };
  },
  Component: ({ question, translate }) => {
    const { moneyInPence, uniqueDigit, inPoundsOrPence } = question;
    const money =
      inPoundsOrPence === 'p'
        ? `${moneyInPence.toLocaleString()}p`
        : `£${(moneyInPence / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;

    const uniqueDigitPower = [...moneyInPence.toString()]
      .reverse()
      .findIndex(x => x === uniqueDigit.toString());

    const items = shuffle(
      [
        { component: `£${(uniqueDigit * 10).toLocaleString()}`, value: uniqueDigit * 10 * 100 },
        { component: `£${uniqueDigit.toLocaleString()}`, value: uniqueDigit * 100 },
        { component: `${(uniqueDigit * 10).toLocaleString()}p`, value: uniqueDigit * 10 },
        { component: `${uniqueDigit.toLocaleString()}p`, value: uniqueDigit }
      ],
      { random: seededRandom(question) }
    ); // All values are in terms of "pence"

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectValueOfDigitXinY(uniqueDigit, money)}
        items={items.map(item => item)}
        testCorrect={[uniqueDigit * 10 ** uniqueDigitPower]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axj',
  description: 'axj',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Compare'],
  schema: z.object({
    moneyInPenceA: z
      .number()
      .int()
      .min(401)
      .max(2099)
      .refine(money => money % 100 !== 0, 'money should have pence value'),
    moneyInPenceB: z
      .number()
      .int()
      .min(401)
      .max(2099)
      .refine(money => money % 100 !== 0, 'money should have pence value')
  }),
  simpleGenerator: () => {
    const moneyInPenceA = randomIntegerInclusive(401, 2099, { constraint: x => x % 100 !== 0 });
    const moneyInPenceB = randomIntegerInclusive(401, 2099, { constraint: x => x % 100 !== 0 });

    return { moneyInPenceA, moneyInPenceB };
  },
  Component: ({ question: { moneyInPenceA, moneyInPenceB }, translate, displayMode }) => {
    const moneyA = totalPenceToPoundsAndPence(moneyInPenceA);
    const moneyB = totalPenceToPoundsAndPence(moneyInPenceB);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareAmounts()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareAmounts()}
        itemVariant="square"
        statements={[
          {
            lhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                {displayMoney(
                  moneyA,
                  displayMode === 'digital' ? 100 : 150,
                  displayMode === 'digital' ? 100 : 150,
                  true
                )}
              </View>
            ),
            rhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                {displayMoney(
                  moneyB,
                  displayMode === 'digital' ? 100 : 150,
                  displayMode === 'digital' ? 100 : 150,
                  true
                )}
              </View>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(moneyInPenceA, moneyInPenceB)
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axk',
  description: 'axk',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Compare'],
  schema: z.object({
    pence1a: z
      .number()
      .int()
      .min(401)
      .max(999)
      .refine(money => money % 100 !== 0, 'pence1a should not be a multiple of 100'),
    pence2a: z
      .number()
      .int()
      .min(111)
      .max(4099)
      .refine(money => money % 100 !== 0, 'pence1a should not be a multiple of 100'),
    pence3: z
      .number()
      .int()
      .min(1001)
      .max(4999)
      .refine(money => money % 100 !== 0, 'pence3 should not be a multiple of 100'),
    pence4: z
      .number()
      .int()
      .min(101)
      .max(999)
      .refine(money => money % 100 !== 0, 'pence4 should not be a multiple of 100'),
    pound4a: z.number().int().min(2).max(9),
    pence4a: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const pence1a = randomIntegerInclusive(401, 999, { constraint: x => x % 100 !== 0 });

    const pence2a = randomIntegerInclusive(111, 4099, { constraint: x => x % 100 !== 0 });

    const pence3 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 100 !== 0 });

    const pence4 = randomIntegerInclusive(101, 999, { constraint: x => x % 100 !== 0 });
    const pound4a = randomIntegerInclusive(2, 9);
    const pence4a = randomIntegerInclusive(1, 99);

    return { pence1a, pence2a, pence3, pence4, pound4a, pence4a };
  },
  Component: ({ question, translate, displayMode }) => {
    const { pence1a, pence2a, pence3, pence4, pound4a, pence4a } = question;

    const pence1b = Number(swapElements([...pence1a.toString()], 1, 2).join('')); // reverse last two digits of pence1a

    const pence2b = Number(swapElements([...pence2a.toString()], 1, 2).join('')); // reverse last two digits of pence2a

    const statements = shuffle(
      [
        {
          lhs: `${pence1a}p`,
          rhs: `${pence1b}p`,
          answer: lessThanGreaterThanOrEqualTo(pence1a, pence1b)
        },
        {
          lhs: numToCurrency({ amount: pence2a / 100 }),
          rhs: numToCurrency({ amount: pence2b / 100 }),
          answer: lessThanGreaterThanOrEqualTo(pence2a, pence2b)
        },
        {
          lhs: `${pence3}p`,
          rhs: numToCurrency({ amount: pence3 / 100 }),
          answer: '='
        },
        {
          lhs: `${pence4}p`,
          rhs: numToCurrency({ amount: pound4a + pence4a / 100 }),
          answer: lessThanGreaterThanOrEqualTo(pence4, pound4a * 100 + pence4a)
        }
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareAmounts()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={statements.map(({ lhs, rhs, answer }) => ({
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 200, textAlign: 'right' }}
            >
              {lhs}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 200, textAlign: 'left' }}
            >
              {rhs}
            </Text>
          ),
          correctAnswer: answer
        }))}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'axl',
  description: 'axl',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Order'],
  schema: z
    .object({
      penceA: z
        .number()
        .int()
        .min(110)
        .max(980)
        .refine(
          x => x % 10 === 0 && x % 100 !== 0,
          'penceA should be a multiple of 10 and not 100'
        ),
      penceB: z
        .number()
        .int()
        .min(210)
        .max(990)
        .refine(
          x => x % 10 === 0 && x % 100 !== 0,
          'penceB should be a multiple of 10 and not 100'
        ),
      penceC: z.number().int().min(110).max(980),
      penceD: z.number().int().min(201).max(909),
      ascOrDesc: z.enum(['ascending', 'descending'])
    })
    .refine(
      ({ penceA, penceB, penceC, penceD }) =>
        arrayHasNoDuplicates([penceA, penceB, penceC, penceD]),
      'All values should be different'
    ),
  simpleGenerator: () => {
    const { penceA, penceB, penceC, penceD } = rejectionSample(
      () => {
        const penceA = randomIntegerInclusive(120, 980, {
          constraint: x => {
            const asArray = [...x.toString()];
            return asArray[0] !== asArray[1] && x % 10 === 0 && x % 100 !== 0;
          }
        });
        const penceC = Number(swapElements([...penceA.toString()]).join(''));

        const penceB = randomIntegerInclusive(210, 980, {
          constraint: x => {
            const asArray = [...x.toString()];
            return asArray[1] !== asArray[2] && x % 10 === 0 && x % 100 !== 0;
          }
        });
        const penceD = Number(swapElements([...penceB.toString()], 1, 2).join(''));

        return { penceA, penceB, penceC, penceD };
      },
      ({ penceA, penceB, penceC, penceD }) => arrayHasNoDuplicates([penceA, penceB, penceC, penceD])
    );

    const ascOrDesc = getRandomFromArray(['ascending', 'descending'] as const);
    return { penceA, penceB, penceC, penceD, ascOrDesc };
  },
  Component: ({ question, translate }) => {
    const { penceA, penceB, penceC, penceD, ascOrDesc } = question;
    const title =
      ascOrDesc === 'ascending'
        ? translate.instructions.putTheseAmountsInAscendingOrder()
        : translate.instructions.putTheseAmountsInDescendingOrder();

    const items = shuffle(
      [
        { component: `${penceA}p`, value: penceA },
        { component: `${penceB}p`, value: penceB },
        { component: numToCurrency({ amount: penceC / 100 }), value: penceC },
        { component: numToCurrency({ amount: penceD / 100 }), value: penceD }
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF4DragOrderVertical
        title={title}
        pdfTitle={
          ascOrDesc === 'ascending'
            ? translate.instructions.useCardsToPutTheseAmountsInAscendingOrder()
            : translate.instructions.useCardsToPutTheseAmountsInDescendingOrder()
        }
        topLabel={
          ascOrDesc === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          ascOrDesc === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        items={items.map(item => item)}
        testCorrect={sortNumberArray([penceA, penceB, penceC, penceD], ascOrDesc)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axm',
  description: 'axm',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Compare'],
  schema: z
    .object({
      pCoins: numberEnum([50, 20, 10]),
      poundsLHS: z.number().int().min(21).max(29),
      penceLHS: z.number().int().min(10).max(90),
      poundsRHS: z.number().int().min(16).max(27)
    })
    .refine(
      ({ penceLHS, pCoins }) => penceLHS % pCoins === 0,
      'penceLHS should be a multiple of pCoins'
    ),
  simpleGenerator: () => {
    const pCoins = getRandomFromArray([50, 20, 10] as const);

    const poundsLHS = randomIntegerInclusive(21, 29);
    const penceLHS = randomIntegerInclusive(10, 90, { constraint: x => x % pCoins === 0 });

    const poundsRHS = poundsLHS - getRandomFromArray([2, 3, 4, 5]);

    return { pCoins, poundsLHS, penceLHS, poundsRHS };
  },
  Component: ({ question: { pCoins, poundsLHS, penceLHS, poundsRHS }, translate, displayMode }) => {
    const difference = (poundsLHS * 100 + penceLHS - poundsRHS * 100) / pCoins;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXCoinsWouldYouNeedToMakeX(
          pCoins,
          numToCurrency({ amount: poundsLHS + penceLHS / 100 })
        )}
        Content={
          <View style={{ flexDirection: 'row', columnGap: 12, alignItems: 'flex-end' }}>
            {displayMoney(
              moneyToHighestDenominations(poundsRHS, 'pounds'),
              displayMode === 'digital' ? 100 : 150,
              displayMode === 'digital' ? 100 : 150,
              true
            )}
          </View>
        }
        sentence={'<ans/>'}
        inputMaxCharacters={2}
        testCorrect={[difference.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axn',
  description: 'axn',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Compare'],
  schema: z.object({
    nameA: nameSchema,
    nameB: nameSchema,
    nameC: nameSchema,
    poundsA: z.number().int().min(5).max(9),
    penceA: z.number().int().min(42).max(59),
    penceB: z.number().int().min(548).max(977)
  }),
  simpleGenerator: () => {
    const [nameA, nameB, nameC] = getRandomUniqueNames(3);

    const poundsA = randomIntegerInclusive(5, 9);
    const penceA = randomIntegerInclusive(42, 59, { constraint: x => x % 10 !== 0 });

    const penceB = poundsA * 100 + penceA + getRandomFromArray([6, 8, 12, 14, 16, 18]);

    return { nameA, nameB, nameC, poundsA, penceA, penceB };
  },
  Component: ({ question: { nameA, nameB, nameC, poundsA, penceA, penceB }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.characterAhasXAndCharacterBHasYCharacterCHasMore({
          nameA,
          nameB,
          nameC,
          nameAMoney: `${poundsA}.${penceA}`,
          nameBMoney: `${penceB}`
        })}<br/><br/>${translate.instructions.howMuchMoneyCouldCharacterHave(nameC)}`}
        sentence={'£<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={4}
        testCorrect={answer =>
          isValidDecimalString(answer[0]) &&
          isValidMoneyAnswer(answer[0]) &&
          isInRangeExclusive(poundsA * 100 + penceA, penceB)(Number(answer) * 100)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            `£${poundsA}.${penceA + 1}`,
            `${Math.floor(penceB / 100)}.${(penceB % 100) + 1}`
          )
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAmountsOfMoney',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
