import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { compareFractions } from '../../../../utils/fractions';
import { compareFloats } from '../../../../utils/math';
import { filledArray, range } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { View } from 'react-native';
import {
  barModelColors,
  barModelColorsArray,
  barModelColorsSchema
} from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg } from '../../../../assets/svg';
import TenFrames from '../../../../components/question/representations/TenFrame/TenFrameLegacy';
import { shadedSectionedShapes } from '../../../../utils/shadedSectionedShapes';
import QF20aBarModelInteractive from '../../../../components/question/questionFormats/QF20aBarModelInteractive';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aQm',
  description: 'aQm',
  keywords: ['Fraction', 'Decimals', 'Tenths', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(1).max(10),
    barModelColor: barModelColorsSchema
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);
    const barModelColor = getRandomFromArray(barModelColorsArray)!;

    return { number, barModelColor };
  },
  Component: ({ question: { number, barModelColor }, translate }) => {
    const coloredSections = [
      ...filledArray(barModelColors[barModelColor], number),
      ...filledArray('white', 10 - number)
    ];
    const numbers = filledArray(1, 10);

    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.useBarModelsToCompleteSentences()}`}
        Content={({ dimens }) => (
          <View style={{ rowGap: 10 }}>
            <BarModel
              total={10}
              dimens={{ width: dimens.width, height: dimens.height }}
              numbers={[numbers]}
              strings={[filledArray(`<frac n="1" d="10" />`, 10)]}
              cellColors={[coloredSections]}
            />
            <BarModel
              total={10}
              dimens={{ width: dimens.width, height: dimens.height }}
              numbers={[numbers]}
              strings={[filledArray(`${(0.1).toLocaleString()}`, 10)]}
              cellColors={[coloredSections]}
              rowHeight={50}
            />
          </View>
        )}
        inputMaxCharacters={3}
        extraSymbol={'decimalPoint'}
        sentences={[
          translate.answerSentences.theFractionShadedIs(),
          translate.answerSentences.theDecimalShadedIsAns()
        ]}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [number, 10]) &&
          compareFloats(userAnswer[1][0], number / 10)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[number.toLocaleString(), '10'], [(number / 10).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractionsDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQn',
  description: 'aQn',
  keywords: ['Fraction', 'Decimals', 'Tenths', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);

    return { number };
  },

  Component: ({ question: { number }, translate }) => {
    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.howMuchOfTheBarModelIsShaded()}<br/>${translate.instructions.giveAnswerAsFractionAndDecimal()}`}
        Content={({ dimens }) => shadedSectionedShapes('shortRect', number, 10, dimens, 'any')}
        inputMaxCharacters={3}
        extraSymbol={'decimalPoint'}
        sentences={['<frac nAns="" dAns="" />', '<ans/>']}
        style={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [number, 10]) &&
          compareFloats(userAnswer[1][0], number / 10)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[number.toLocaleString(), '10'], [(number / 10).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractionsDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQo',
  description: 'aQo',
  keywords: ['Tenths', 'Decimals', 'Bar model'],
  schema: z.object({
    parts: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(1, 9);
    return { parts };
  },
  Component: ({ question: { parts }, translate }) => {
    return (
      <QF20aBarModelInteractive
        numberOfRows={1}
        numberOfCols={10}
        title={translate.instructions.shadeBarModelToShowX((parts / 10).toLocaleString())}
        testCorrect={parts}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQp',
  description: 'aQp',
  keywords: ['Fraction', 'Decimals', 'Tenths', 'Place value counters'],
  schema: z.object({
    number: z.number().min(1).max(9),
    useDecimalCounters: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const useDecimalCounters = getRandomBoolean();

    return { number, useDecimalCounters };
  },
  Component: ({ question: { number, useDecimalCounters }, translate }) => {
    const svgName = useDecimalCounters ? 'Place_value/0.1' : 'Place_value/1-10';
    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.whatFractionAndDecimalDoTheCountersRepresent()}`}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={1}
            columns={number}
            customImage={<AssetSvg name={svgName} width={96} />}
            dimens={dimens}
            rowStyle={{ columnGap: 8 }}
          />
        )}
        inputMaxCharacters={3}
        extraSymbol={'decimalPoint'}
        sentences={['<frac nAns="" dAns="" />', '<ans/>']}
        style={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [number, 10]) &&
          compareFloats(userAnswer[1][0], number / 10)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[number.toLocaleString(), '10'], [(number / 10).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractionsDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aQq',
  description: 'aQq',
  keywords: ['Fifths', 'Decimals', 'Number line'],
  schema: z.object({
    missingFractions: z.boolean(),
    variablesShown: z.array(z.number().int().min(3).max(9)).length(2)
  }),
  simpleGenerator: () => {
    const missingFractions = getRandomBoolean();
    const variablesShown = randomUniqueIntegersInclusive(3, 9, 2);

    return { missingFractions, variablesShown };
  },

  Component: props => {
    const {
      question: { missingFractions, variablesShown },
      translate,
      displayMode
    } = props;

    const topTickValues = range(0, 10, 1).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (i === 1 || i === 2) return `<frac n='${i}' d='10' />`;
      if (variablesShown.includes(i))
        return missingFractions
          ? '<ans/>'
          : `<frac n='${i.toLocaleString()}' d='${(10).toLocaleString()}' />`;
      return ' ';
    });

    const bottomTickValues = range(0, 10, 1).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (i === 1 || i === 2) return `${(i / 10).toLocaleString()}`;
      if (variablesShown.includes(i)) {
        return !missingFractions ? '<ans/>' : `${(i / 10).toLocaleString()}`;
      }
      return ' ';
    });

    const answers = [...variablesShown].sort().map(x => `${x / 10}`);

    const [num1, num2] = variablesShown;

    let options;
    if (missingFractions) {
      const wrongFractionOptions = getRandomSubArrayFromArray(
        [
          [num1 / 10, 10],
          [num2 / 10, 10],
          [num1 / 10, num1],
          [num2 / 10, num2],
          [num1, 1],
          [num2, 1],
          [num1, 100],
          [num2, 100]
        ],
        6,
        { random: seededRandom(props.question) }
      );

      options = [[num1, 10], [num2, 10], ...wrongFractionOptions].map(([n, d], i) => {
        return {
          component: (
            <TextStructure
              key={i}
              sentence={`<frac n='${n}' d='${d}' />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textVariant="WRN700"
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value: `${n / d}`
        };
      });
    } else {
      const dec1 = num1 / 10;
      const dec2 = num2 / 10;
      const wrongDec1 = dec1 + 0.1 === dec2 ? dec1 - 0.1 : dec1 + 0.1;
      const wrongDec2 = dec2 + 0.1 === dec1 ? dec2 - 0.1 : dec2 + 0.1;

      options = [
        dec1,
        dec2,
        wrongDec1,
        wrongDec2,
        dec1 / 10,
        dec2 / 10,
        wrongDec1 * 10,
        wrongDec2 + 0.01
      ].map(i => i.toLocaleString());
    }
    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={answers}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aQr',
  description: 'aQr',
  keywords: ['Ten frame', 'Decimals', 'Tenths', 'Place value counters'],
  schema: z.object({
    number: z.number().min(11).max(19),
    useDecimalCounters: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 19);
    const useDecimalCounters = getRandomBoolean();

    return { number, useDecimalCounters };
  },
  Component: ({ question: { number, useDecimalCounters }, translate }) => {
    const pvcounter = useDecimalCounters ? 'tenths' : 'tenths_frac';
    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.whatFractionAndDecimalDoTheTenFramesRepresent()}`}
        Content={
          <TenFrames flexDirection={'row'} numberOfCounters={number} placeValue={pvcounter} />
        }
        inputMaxCharacters={3}
        extraSymbol={'decimalPoint'}
        sentences={['<frac  nAns="" dAns="" />', '<ans/>']}
        style={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [number, 10]) &&
          compareFloats(userAnswer[1][0], number / 10)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[number.toLocaleString(), '10'], [(number / 10).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractionsDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAndDecimalsTenths',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
