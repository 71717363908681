import { SvgName } from '../assets/svg';
import { zodEnumFromObjKeys } from './zod';

type shapes3DIsoInfo = {
  svg: SvgName;
  numberOfCubes: number;
  /* All sides are atleast 2 cubes */
  hasDepth: boolean;
};

export const shapes3DIso: Record<string, shapes3DIsoInfo> = {
  Shape_3D_Iso_1: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape1_cubes_isometric_paper',
    numberOfCubes: 4,
    hasDepth: false
  },
  Shape_3D_Iso_2: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape2_cubes_isometric_paper',
    numberOfCubes: 8,
    hasDepth: true
  },
  Shape_3D_Iso_3: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape3_cubes_isometric_paper',
    numberOfCubes: 12,
    hasDepth: true
  },
  Shape_3D_Iso_4: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape4_cubes_isometric_paper',
    numberOfCubes: 6,
    hasDepth: false
  },
  Shape_3D_Iso_5: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape5_cubes_isometric_paper',
    numberOfCubes: 12,
    hasDepth: true
  },
  Shape_3D_Iso_6: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape6_cubes_isometric_paper',
    numberOfCubes: 18,
    hasDepth: true
  },
  Shape_3D_Iso_7: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape7_cubes_isometric_paper',
    numberOfCubes: 12,
    hasDepth: false
  },
  Shape_3D_Iso_8: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape8_cubes_isometric_paper',
    numberOfCubes: 9,
    hasDepth: false
  },
  Shape_3D_Iso_9: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape9_cubes_isometric_paper',
    numberOfCubes: 18,
    hasDepth: true
  },
  Shape_3D_Iso_10: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape10_cubes_isometric_paper',
    numberOfCubes: 27,
    hasDepth: true
  },
  Shape_3D_Iso_11: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape11_cubes_isometric_paper',
    numberOfCubes: 12,
    hasDepth: false
  },
  Shape_3D_Iso_12: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape12_cubes_isometric_paper',
    numberOfCubes: 24,
    hasDepth: true
  },
  Shape_3D_Iso_13: {
    svg: '3D_Shapes_Cubes_on_Iso_Grid/3D_shape13_cubes_isometric_paper',
    numberOfCubes: 36,
    hasDepth: true
  }
};
export type Shapes3DIsoNames = keyof typeof shapes3DIso;
export const Shapes3DIsoSchema = zodEnumFromObjKeys(shapes3DIso);
export const shapes3DIsoNames = Object.keys(shapes3DIso) as Shapes3DIsoNames[];

export function filterShapes3DIso({
  numberOfCubes,
  hasDepth
}: {
  /** numberOfCubes filters by range [lowest, highest] the range is [4,36] */
  numberOfCubes?: [number, number];
  hasDepth?: boolean;
}): Shapes3DIsoNames[] {
  return Object.entries(shapes3DIso)
    .filter(([_, info]) => {
      const cubes =
        numberOfCubes === undefined
          ? true
          : info.numberOfCubes >= numberOfCubes[0] && info.numberOfCubes <= numberOfCubes[1];
      const nonFlat = hasDepth === undefined ? true : info.hasDepth === hasDepth;
      return cubes && nonFlat;
    })
    .map(([name]) => name);
}
