import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from 'common/src/utils/random';
import { DIV, MULT } from 'common/src/constants';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray, range } from 'common/src/utils/collections';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectPrepositionWord,
  objectSchema
} from 'common/src/utils/objects';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getObjectSvgName } from 'common/src/utils/objectsImages';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aln',
  description: 'aln',
  keywords: ['Multiply', 'Divide', '9', 'Object'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(objectPlural)}
        testCorrect={[(numberPerGroup * 9).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        {...props}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }
            ]}
          >
            {range(1, 9).map(index => (
              <View key={index}>
                <AssetSvg
                  name={getObjectSvgName(object, numberPerGroup)}
                  height={dimens.height / 3.2}
                  width={dimens.width / 3.2}
                />
              </View>
            ))}
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'alo',
  description: 'alo',
  keywords: ['Times-table', '9', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columns: z.number().int().min(2).max(8)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 8);

    return { columns };
  },
  Component: props => {
    const {
      question: { columns },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        // Four sentences with this mainPanelStyle make it clear that the sentences and content aren't aligned - sentences need nudging up slightly:
        sentenceStyle={{ bottom: displayMode === 'digital' ? 24 : undefined }}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        testCorrect={answer =>
          ((answer[0][0] === columns.toString() &&
            answer[0][1] === '9' &&
            answer[1][0] === '9' &&
            answer[1][1] === columns.toString()) ||
            (answer[0][0] === '9' &&
              answer[0][1] === columns.toString() &&
              answer[1][0] === columns.toString() &&
              answer[1][1] === '9')) &&
          answer[0][2] === (9 * columns).toString() &&
          answer[1][2] === (9 * columns).toString() &&
          ((answer[2][1] === columns.toString() &&
            answer[2][2] === '9' &&
            answer[3][1] === '9' &&
            answer[3][2] === columns.toString()) ||
            (answer[2][1] === '9' &&
              answer[2][2] === columns.toString() &&
              answer[3][1] === columns.toString() &&
              answer[3][2] === '9')) &&
          answer[2][0] === (9 * columns).toString() &&
          answer[3][0] === (9 * columns).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <ArrayOfObjects rows={9} columns={columns} dimens={dimens} />}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [columns.toLocaleString(), (9).toLocaleString(), (9 * columns).toLocaleString()],
            [(9).toLocaleString(), columns.toLocaleString(), (9 * columns).toLocaleString()],
            [(9 * columns).toLocaleString(), columns.toLocaleString(), (9).toLocaleString()],
            [(9 * columns).toLocaleString(), (9).toLocaleString(), columns.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alp',
  description: 'alp',
  keywords: ['Times-table', '9', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy9', 'fillBottomRowWith9'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy9',
      'fillBottomRowWith9'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, displayMode }) => {
    const multiplier = 9;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith9':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy9':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        maxFontSize={displayMode === 'digital' ? 40 : 50}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alq',
  description: 'alq',
  keywords: ['Multiple', '9'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(90)
      .array()
      .length(8)
      .refine(numbers => numbers.filter(x => x % 9 === 0).length >= 3, 'At least 3 multiples of 9')
  }),
  simpleGenerator: () => {
    // 1 to 45 range
    const multiple1 = randomUniqueIntegersInclusive(1, 45, 2, {
      constraint: x => x % 9 === 0
    });
    const random1 = randomUniqueIntegersInclusive(1, 45, 3, {
      constraint: x => !multiple1.includes(x)
    });

    // 46 to 90 range
    const multiple2 = randomIntegerInclusive(46, 90, {
      constraint: x => x % 9 === 0
    });
    const notMultiple = randomIntegerInclusive(47, 90, {
      constraint: x => x % 9 !== 0
    });
    const random2 = randomIntegerInclusive(46, 90, {
      constraint: x => x !== multiple2 && x !== notMultiple
    });

    const numbers = shuffle([...multiple1, ...random1, multiple2, notMultiple, random2]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(9)}
        pdfTitle={translate.instructions.circleTheMultiplesOfX(9)}
        testCorrect={numbers.filter(it => it % 9 === 0)}
        multiSelect
        items={() =>
          numbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'alr',
  description: 'alr',
  keywords: ['Multiply', '9', 'Problem'],
  schema: z.object({
    objectsPerGroup: z.number().int().min(2).max(6),
    object: objectSchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const objectsPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    const name = getRandomName();

    return { objectsPerGroup, object, name };
  },
  Component: props => {
    const {
      question: { objectsPerGroup, object, name },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.characterHasNumGroupsCharPutsNumObjects({
          name,
          num1: 9,
          containerPlural,
          num2: objectsPerGroup,
          objectPlural,
          preposition,
          containerSingular
        })}
        testCorrect={answer =>
          ((answer[0] === objectsPerGroup.toString() && answer[1] === '9') ||
            (answer[0] === '9' && answer[1] === objectsPerGroup.toString())) &&
          answer[2] === (objectsPerGroup * 9).toString()
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-evenly' }]}>
            <AssetSvg
              name={getCharacterHeadSvgName(name)}
              height={dimens.height * 0.8}
              width={dimens.width * 0.4}
            />
            <AssetSvg
              name={getObjectSvgName(object, objectsPerGroup)}
              height={dimens.height * 0.8}
              width={dimens.width * 0.4}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            objectsPerGroup.toLocaleString(),
            (9).toLocaleString(),
            (objectsPerGroup * 9).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'als',
  description: 'als',
  keywords: ['Divide', '9', 'Problem'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    object: objectSchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    const name = getRandomName();

    return { groups, object, name };
  },
  Component: props => {
    const {
      question: { groups, object, name },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansObject(containerPlural)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.characterHasXObjectsPutsInYContainersHowManyContainersNeeded(
          name,
          groups * 9,
          objectPlural,
          9,
          preposition,
          containerSingular,
          containerPlural
        )}
        testCorrect={[groups.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-evenly' }]}>
            <AssetSvg
              name={getCharacterHeadSvgName(name)}
              height={dimens.height * 0.8}
              width={dimens.width * 0.4}
            />
            <AssetSvg
              name={getObjectSvgName(object, 0)}
              height={dimens.height * 0.8}
              width={dimens.width * 0.4}
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAndDivideBy9',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
