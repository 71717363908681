import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from 'common/src/utils/random';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { roundToTheNearest } from 'common/src/utils/math';
import { ac3 } from './16RoundToTheNearest1000';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ac4',
  description: 'ac4',
  keywords: ['Place value', 'Round', 'Number line', '10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(10)
      .max(9990)
      .refine(x => x % 10 !== 0)
  }),
  example: {
    number: 8355
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 9989, { constraint: x => x % 10 !== 0 });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 10) * 10;
    const endNumber = Math.ceil(number / 10) * 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest10(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number}
            scaleFactor={3.5}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ac5',
  description: 'ac5',
  keywords: ['Place value', 'Round', 'Number line', '100', 'Hundred'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(110)
      .max(9990)
      .refine(x => x % 100 !== 0)
  }),
  example: {
    number: 4320
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(110, 9990, { constraint: x => x % 100 !== 0 });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;
    const startingNumber = Math.floor(number / 100) * 100;
    const endNumber = Math.ceil(number / 100) * 100;
    const tickInterval = 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest100(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 100).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number}
            customFontSize={displayMode === 'digital' ? 22 : undefined}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ac6',
  description: 'ac6',
  keywords: ['Place value', 'Round', 'Number line', '100', 'Hundred'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(1100)
      .max(9900)
      .multipleOf(100)
      .refine(x => x % 1000 !== 0)
  }),
  example: {
    number: 5700
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(1100, 9900, 100, { constraint: x => x % 1000 !== 0 });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 1000) * 1000;
    const endNumber = Math.ceil(number / 1000) * 1000;
    const tickInterval = 100;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest1000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 1000).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number}
            scaleFactor={3.5}
          />
        )}
      />
    );
  }
});

const Question4: typeof ac3 = {
  ...ac3,
  uid: 'ac8',
  description: 'ac8',
  keywords: ['Place value', 'Round', '10', '100', '1,000']
};

// Copied by aeh
const Question5 = newQuestionContent({
  uid: 'ac7',
  description: 'ac7',
  keywords: ['Place value', 'Round', '1,000', '100', '10'],
  schema: z.object({
    numbers: z.array(z.number().int().min(101).max(9999)).length(3).refine(arrayHasNoDuplicates)
  }),
  example: {
    numbers: [7254, 4211, 8777]
  },
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(101, 9999, 3);
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate,
      displayMode
    } = props;
    const nearest10 = roundToTheNearest(numbers[0], 10);
    const nearest100 = roundToTheNearest(numbers[1], 100);
    const nearest1000 = roundToTheNearest(numbers[2], 1000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[nearest10.toString()], [nearest100.toString()], [nearest1000.toString()]]}
        textStyle={displayMode === 'digital' ? { fontSize: 32 } : undefined}
        sentences={[
          translate.answerSentences.roundToNearest10(numbers[0]),
          translate.answerSentences.roundToNearest100(numbers[1]),
          translate.answerSentences.roundToNearest1000(numbers[2])
        ]}
      />
    );
  }
});
export const ac7 = Question5;

const Question6 = newQuestionContent({
  uid: 'ac9',
  description: 'ac9',
  keywords: ['Place value', 'Round', 'Thousand', '1,000'],
  schema: z
    .object({
      comparitor: z.number().int().min(1010).max(9000),
      numbers: z.number().int().min(1).max(9999).array().length(6),
      round: z.union([z.literal(10), z.literal(100), z.literal(1000)]) as z.Schema<10 | 100 | 1000>
    })
    .refine(val => val.comparitor % val.round === 0),
  example: {
    round: 10,
    comparitor: 1040,
    numbers: [1033, 1035, 1038, 1041, 1045, 1048]
  },
  simpleGenerator: () => {
    const round = getRandomFromArray([10, 100, 1000] as const);
    const comparitor = randomIntegerInclusiveStep(1000, 9000, round, { constraint: x => x > 1000 });

    const num1 = randomIntegerInclusive(comparitor - 1000, comparitor - 51);
    const num2 = randomIntegerInclusive(comparitor - 50, comparitor - 6);
    const num3 = randomIntegerInclusive(comparitor - 5, comparitor - 1);
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 4);
    const num5 = randomIntegerInclusive(comparitor + 5, comparitor + 49);
    const num6 = randomIntegerInclusive(comparitor + 50, comparitor + 999);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]), round };
  },
  Component: props => {
    const {
      question: { comparitor, numbers, round },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectWhichRoundToXToNearestY(comparitor, round)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, round) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearest10100Or1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
