import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from '../../../../theme/colors';
import { SetState } from '../../../../utils/react';
import { GridContext } from './Grid';
import { countRange } from '../../../../utils/collections';
import { useCallback, useContext, useMemo } from 'react';
import { withStateHOC } from '../../../../stateTree';
import { AssetSvg } from '../../../../assets/svg';

const DEFAULT_DOT_RADIUS = 12.24;
const DEFAULT_TOUCHABLE_RADIUS = 40;

type Props = {
  state: { x: number; y: number }[];
  setState?: SetState<{ x: number; y: number }[]>;

  fixedSelected?: { x: number; y: number }[];

  /** Default: false */
  multiSelect?: boolean;

  /** Default: false */
  noDeselect?: boolean;
  /** optional override for the fill of the circle when not selected */
  unselectedFill?: string;
  yMin?: number;

  /** Optional dots array to override default array of xMin to xMax
   * null: dot that can be selected
   * selected: selected dot
   * fixed: static point
   * undefined: return nothing
   */
  dotsArray?: (null | 'selected' | 'fixed' | undefined)[][];
};

/** Selectable dots. Should be a child of `SquareDottedPaper`. */
export default function SelectableSquareDots({
  state,
  setState,
  fixedSelected = [],
  multiSelect = false,
  noDeselect = false,
  unselectedFill,
  yMin = 0,
  dotsArray
}: Props) {
  const onDotClicked = useCallback(
    (x: number, y: number) => {
      setState &&
        setState((old): { x: number; y: number }[] => {
          const existingIndex = state.findIndex(dot => dot.x === x && dot.y === y);
          if (existingIndex !== -1) {
            // Deselect
            return noDeselect
              ? old
              : [...old.slice(0, existingIndex), ...old.slice(existingIndex + 1)];
          } else {
            // Select
            if (multiSelect) {
              return [...old, { x, y }];
            } else {
              return [{ x, y }];
            }
          }
        });
    },
    [multiSelect, setState, state, noDeselect]
  );

  const { xMax, yMax } = useContext(GridContext);

  const dots = useMemo(() => {
    // ignore any selected cells as these will be put back in later
    // need to take it back to the original array
    const nonSelectedDotsArray = dotsArray?.map(row =>
      row.map(val => (val === 'selected' ? null : val))
    );
    // 2D array, one for each dot, with null in each cell
    const dots: (null | 'selected' | 'fixed' | undefined)[][] =
      nonSelectedDotsArray ??
      countRange(xMax + 1).map(() => countRange(yMax + 1 - yMin, yMin + 1).map(() => null));

    // Set the selected and fixed dots as required
    for (const selectedDot of state) {
      const { x, y } = selectedDot;
      dots[x][y] = 'selected';
    }

    for (const fixedDot of fixedSelected) {
      const { x, y } = fixedDot;
      dots[x][y] = 'fixed';
    }
    return dots;
  }, [dotsArray, fixedSelected, state, xMax, yMax, yMin]);

  return (
    <View style={StyleSheet.absoluteFill}>
      {dots.map((row, x) =>
        row.map((kind, y) => (
          <SelectableDot
            key={`${x},${y}`}
            x={x}
            y={y}
            kind={kind}
            onDotClicked={setState !== undefined ? onDotClicked : undefined}
            unselectedFill={unselectedFill}
          />
        ))
      )}
    </View>
  );
}

function SelectableDot({
  x,
  y,
  kind,
  onDotClicked,
  unselectedFill
}: {
  x: number;
  y: number;
  kind: null | 'selected' | 'fixed' | undefined;
  onDotClicked?: (x: number, y: number) => void;
  /** optional override for the fill of the circle when not selected */
  unselectedFill?: string;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  const onPress = useCallback(() => onDotClicked && onDotClicked(x, y), [onDotClicked, x, y]);
  const touchableRadius = Math.min(DEFAULT_TOUCHABLE_RADIUS, (mathToSvgX(1) - mathToSvgX(0)) / 2);
  const style = useMemo(
    () => ({
      left: mathToSvgX(x),
      top: mathToSvgY(y),
      width: touchableRadius * 2,
      height: touchableRadius * 2,
      marginLeft: -touchableRadius,
      marginTop: -touchableRadius
    }),
    [mathToSvgX, mathToSvgY, touchableRadius, x, y]
  );

  return (
    <TouchableOpacity
      key={`${x},${y}`}
      onPress={onPress}
      style={[styles.touchableOpacity, style]}
      disabled={!onDotClicked || kind === 'fixed' || kind === undefined}
    >
      <AssetSvg
        name="Coordinates/CirclePointCustomizable"
        width={DEFAULT_DOT_RADIUS * 2}
        height={DEFAULT_DOT_RADIUS * 2}
        svgProps={{
          fill:
            kind === 'selected'
              ? colors.burntSienna
              : kind === 'fixed'
              ? colors.prussianBlue
              : unselectedFill ?? colors.pdfShading
        }}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  touchableOpacity: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dot: {
    width: DEFAULT_DOT_RADIUS * 2,
    height: DEFAULT_DOT_RADIUS * 2,
    borderRadius: 999,
    backgroundColor: colors.pdfShading
  },
  selectedDot: {
    backgroundColor: colors.burntSienna
  }
});

export const SelectableSquareDotsWithState = withStateHOC(SelectableSquareDots, {
  defaults: { defaultState: [], testComplete: state => state.length > 0 }
});
