import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { sortNumberArray } from '../../../../utils/collections';
import { ALGEBRAIC_X } from '../../../../constants';
import { LabelledRATriangle } from '../../../../components/question/representations/LabelledRATriangle';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const triangleConstraintsTop = {
  isoceles_obtuse_triangle1: [98, 120],
  isoceles_acute_triangle1: [24, 50],
  isoceles_acute_triangle2: [40, 60],
  scalene_triangle1: [91, 110],
  scalene_triangle2: [95, 115],
  scalene_triangle3: [45, 65]
};

const triangleConstraintsBottom = {
  scalene_triangle1: [40, 60],
  scalene_triangle2: [20, 40],
  scalene_triangle3: [30, 50]
};

const Question1 = newQuestionContent({
  uid: 'aEg',
  description: 'aEg',
  keywords: ['Triangles', 'Acute', 'Angles'],
  schema: z.object({
    acuteAngles: numberEnum([2, 3]),
    raTriangle: z.enum(['E', 'F']),
    scaleneObtuseTriangle: z.enum(['scalene_triangle1', 'scalene_triangle2']),
    isocelesTriangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    isocelesAngles: z.array(z.number()).length(2),
    scaleneAcuteAngles: z.array(z.number()).length(2),
    scaleneObtuseAngles: z.array(z.number()).length(2)
  }),

  simpleGenerator: () => {
    const acuteAngles = getRandomFromArray([2, 3] as const);

    const raTriangle = getRandomFromArray(['E', 'F'] as const);
    const scaleneObtuseTriangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2'
    ] as const);

    const scaleneAcuteAngles = [randomIntegerInclusive(40, 54), randomIntegerInclusive(55, 89)];
    const scaleneObtuseAngles = [randomIntegerInclusive(15, 60), randomIntegerInclusive(91, 130)];

    const isocelesTriangle:
      | 'isoceles_acute_triangle1'
      | 'isoceles_acute_triangle2'
      | 'isoceles_obtuse_triangle1' = getRandomBoolean()
      ? getRandomFromArray(['isoceles_acute_triangle1', 'isoceles_acute_triangle2'] as const)
      : 'isoceles_obtuse_triangle1';

    let isocelesAngles = [];
    if (isocelesTriangle === 'isoceles_obtuse_triangle1') {
      const topIsoAngle = randomIntegerInclusiveStep(92, 130, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      isocelesAngles = [baseIsoAngle, topIsoAngle];
    } else {
      const topIsoAngle = randomIntegerInclusiveStep(14, 58, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      isocelesAngles = [topIsoAngle, baseIsoAngle];
    }

    return {
      raTriangle,
      acuteAngles,
      scaleneObtuseTriangle,
      scaleneAcuteAngles,
      scaleneObtuseAngles,
      isocelesTriangle,
      isocelesAngles
    };
  },
  Component: props => {
    const {
      question: {
        raTriangle,
        acuteAngles,
        scaleneObtuseTriangle,
        scaleneAcuteAngles,
        scaleneObtuseAngles,
        isocelesTriangle,
        isocelesAngles
      },
      translate
    } = props;

    const isocelesLabels = isocelesAngles.map(angle => translate.units.numberOfDegrees(angle));

    // Randomly order these options
    const options = shuffle(
      [
        {
          triangle: 'raTriangle',
          shape: raTriangle,
          acuteNum: 2,
          value: 'A'
        },
        {
          triangle: 'scaleneAcute',
          angleLabels: scaleneAcuteAngles.map(angle => translate.units.numberOfDegrees(angle)),
          shape: 'scalene_triangle3',
          acuteNum: 3,
          value: 'B'
        },
        {
          triangle: 'scaleneObtuse',
          shape: scaleneObtuseTriangle,
          angleLabels: [
            '',
            ...scaleneObtuseAngles.map(angle => translate.units.numberOfDegrees(angle))
          ],
          acuteNum: 2,
          value: 'C'
        },
        {
          triangle: 'isoceles',
          shape: isocelesTriangle,
          angleLabels:
            isocelesTriangle === 'isoceles_obtuse_triangle1'
              ? ['', ...isocelesLabels]
              : isocelesLabels,
          acuteNum: isocelesTriangle === 'isoceles_obtuse_triangle1' ? 2 : 3,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTrianglesThatHaveXAcuteAngles(acuteAngles)}
        pdfTitle={translate.instructions.circleTrianglesThatHaveXAcuteAngles(acuteAngles)}
        testCorrect={options
          .filter(({ acuteNum }) => acuteNum === acuteAngles)
          .map(({ value }) => value)}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(({ triangle, shape, angleLabels, value }) => {
            return {
              component:
                triangle === 'raTriangle' ? (
                  <LabelledRATriangle dimens={dimens} variation={shape as 'E' | 'F'} />
                ) : (
                  <LabelledShape
                    dimens={dimens}
                    shapeName={
                      shape as
                        | 'isoceles_acute_triangle1'
                        | 'isoceles_acute_triangle2'
                        | 'isoceles_obtuse_triangle1'
                        | 'scalene_triangle1'
                        | 'scalene_triangle2'
                        | 'scalene_triangle3'
                    }
                    angleLabels={angleLabels}
                  />
                ),
              value
            };
          })
        }
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aEg2',
  description: 'aEg',
  keywords: ['Triangles', 'Acute', 'Angles'],
  schema: z.object({
    acuteAngles: numberEnum([2, 3]),
    raTriangle: z.enum(['E', 'F']),
    scaleneObtuseTriangle: z.enum(['scalene_triangle1', 'scalene_triangle2']),
    isocelesTriangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    isocelesAngles: z.array(z.number()).length(2),
    scaleneAcuteAngles: z.array(z.number()).length(2),
    scaleneObtuseAngles: z.array(z.number()).length(2)
  }),

  simpleGenerator: () => {
    const acuteAngles = getRandomFromArray([2, 3] as const);

    const raTriangle = getRandomFromArray(['E', 'F'] as const);
    const scaleneObtuseTriangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2'
    ] as const);

    const acuteSvg = 'scalene_triangle3';
    const angle1 = randomIntegerInclusive(
      triangleConstraintsBottom[acuteSvg][0],
      triangleConstraintsBottom[acuteSvg][1]
    );

    const scaleneAcuteAngles = [
      angle1,
      randomIntegerInclusive(
        Math.max(triangleConstraintsTop[acuteSvg][0], 91 - angle1),
        triangleConstraintsTop[acuteSvg][1]
      )
    ];

    const scaleneObtuseAngles = [
      randomIntegerInclusive(
        triangleConstraintsBottom[scaleneObtuseTriangle][0],
        triangleConstraintsBottom[scaleneObtuseTriangle][1]
      ),
      randomIntegerInclusive(
        triangleConstraintsTop[scaleneObtuseTriangle][0],
        triangleConstraintsTop[scaleneObtuseTriangle][1]
      )
    ];

    const isocelesTriangle:
      | 'isoceles_acute_triangle1'
      | 'isoceles_acute_triangle2'
      | 'isoceles_obtuse_triangle1' = getRandomBoolean()
      ? getRandomFromArray(['isoceles_acute_triangle1', 'isoceles_acute_triangle2'] as const)
      : 'isoceles_obtuse_triangle1';

    const topIsoAngle = randomIntegerInclusiveStep(
      triangleConstraintsTop[isocelesTriangle][0],
      triangleConstraintsTop[isocelesTriangle][1],
      2
    );
    const baseIsoAngle = (180 - topIsoAngle) / 2;
    let isocelesAngles = [];
    if (isocelesTriangle === 'isoceles_obtuse_triangle1') {
      isocelesAngles = [baseIsoAngle, topIsoAngle];
    } else {
      isocelesAngles = [topIsoAngle, baseIsoAngle];
    }

    return {
      raTriangle,
      acuteAngles,
      scaleneObtuseTriangle,
      scaleneAcuteAngles,
      scaleneObtuseAngles,
      isocelesTriangle,
      isocelesAngles
    };
  },
  Component: props => {
    const {
      question: {
        raTriangle,
        acuteAngles,
        scaleneObtuseTriangle,
        scaleneAcuteAngles,
        scaleneObtuseAngles,
        isocelesTriangle,
        isocelesAngles
      },
      translate
    } = props;

    const isocelesLabels = isocelesAngles.map(angle => translate.units.numberOfDegrees(angle));

    // Randomly order these options
    const options = shuffle(
      [
        {
          triangle: 'raTriangle',
          shape: raTriangle,
          acuteNum: 2,
          value: 'A'
        },
        {
          triangle: 'scaleneAcute',
          angleLabels: scaleneAcuteAngles.map(angle => translate.units.numberOfDegrees(angle)),
          shape: 'scalene_triangle3',
          acuteNum: 3,
          value: 'B'
        },
        {
          triangle: 'scaleneObtuse',
          shape: scaleneObtuseTriangle,
          angleLabels: [
            '',
            ...scaleneObtuseAngles.map(angle => translate.units.numberOfDegrees(angle))
          ],
          acuteNum: 2,
          value: 'C'
        },
        {
          triangle: 'isoceles',
          shape: isocelesTriangle,
          angleLabels:
            isocelesTriangle === 'isoceles_obtuse_triangle1'
              ? ['', ...isocelesLabels]
              : isocelesLabels,
          acuteNum: isocelesTriangle === 'isoceles_obtuse_triangle1' ? 2 : 3,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTrianglesThatHaveXAcuteAngles(acuteAngles)}
        pdfTitle={translate.instructions.circleTrianglesThatHaveXAcuteAngles(acuteAngles)}
        testCorrect={options
          .filter(({ acuteNum }) => acuteNum === acuteAngles)
          .map(({ value }) => value)}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(({ triangle, shape, angleLabels, value }) => {
            return {
              component:
                triangle === 'raTriangle' ? (
                  <LabelledRATriangle dimens={dimens} variation={shape as 'E' | 'F'} />
                ) : (
                  <LabelledShape
                    dimens={dimens}
                    shapeName={
                      shape as
                        | 'isoceles_acute_triangle1'
                        | 'isoceles_acute_triangle2'
                        | 'isoceles_obtuse_triangle1'
                        | 'scalene_triangle1'
                        | 'scalene_triangle2'
                        | 'scalene_triangle3'
                    }
                    angleLabels={angleLabels}
                  />
                ),
              value
            };
          })
        }
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aEh',
  description: 'aEh',
  keywords: ['Triangles', 'Obtuse', 'Angles'],
  schema: z.object({
    obtuseAngles: numberEnum([0, 1]),
    raTriangle: z.enum(['E', 'F']),
    scaleneObtuseTriangle: z.enum(['scalene_triangle1', 'scalene_triangle2']),
    isocelesTriangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    isocelesAngles: z.array(z.number()).length(2),
    scaleneAcuteAngles: z.array(z.number()).length(2),
    scaleneObtuseAngles: z.array(z.number()).length(2)
  }),

  simpleGenerator: () => {
    const obtuseAngles = getRandomFromArray([0, 1] as const);

    const raTriangle = getRandomFromArray(['E', 'F'] as const);
    const scaleneObtuseTriangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2'
    ] as const);

    const scaleneAcuteAngles = [randomIntegerInclusive(40, 54), randomIntegerInclusive(55, 89)];
    const scaleneObtuseAngles = [randomIntegerInclusive(15, 60), randomIntegerInclusive(91, 130)];

    const isocelesTriangle:
      | 'isoceles_acute_triangle1'
      | 'isoceles_acute_triangle2'
      | 'isoceles_obtuse_triangle1' = getRandomBoolean()
      ? getRandomFromArray(['isoceles_acute_triangle1', 'isoceles_acute_triangle2'] as const)
      : 'isoceles_obtuse_triangle1';

    let isocelesAngles = [];
    if (isocelesTriangle === 'isoceles_obtuse_triangle1') {
      const topIsoAngle = randomIntegerInclusiveStep(92, 130, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      isocelesAngles = [baseIsoAngle, topIsoAngle];
    } else {
      const topIsoAngle = randomIntegerInclusiveStep(14, 84, 2);
      const baseIsoAngle = (180 - topIsoAngle) / 2;
      isocelesAngles = [topIsoAngle, baseIsoAngle];
    }

    return {
      raTriangle,
      obtuseAngles,
      scaleneObtuseTriangle,
      scaleneAcuteAngles,
      scaleneObtuseAngles,
      isocelesTriangle,
      isocelesAngles
    };
  },
  Component: props => {
    const {
      question: {
        raTriangle,
        obtuseAngles,
        scaleneObtuseTriangle,
        scaleneAcuteAngles,
        scaleneObtuseAngles,
        isocelesTriangle,
        isocelesAngles
      },
      translate
    } = props;

    const isocelesLabels = isocelesAngles.map(angle => translate.units.numberOfDegrees(angle));

    // Randomly order these options
    const options = shuffle(
      [
        {
          triangle: 'raTriangle',
          shape: raTriangle,
          obtuseNum: 0,
          value: 'A'
        },
        {
          triangle: 'scaleneAcute',
          angleLabels: scaleneAcuteAngles.map(angle => translate.units.numberOfDegrees(angle)),
          shape: 'scalene_triangle3',
          obtuseNum: 0,
          value: 'B'
        },
        {
          triangle: 'scaleneObtuse',
          shape: scaleneObtuseTriangle,
          angleLabels: [
            '',
            ...scaleneObtuseAngles.map(angle => translate.units.numberOfDegrees(angle))
          ],
          obtuseNum: 1,
          value: 'C'
        },
        {
          triangle: 'isoceles',
          shape: isocelesTriangle,
          angleLabels:
            isocelesTriangle === 'isoceles_obtuse_triangle1'
              ? ['', ...isocelesLabels]
              : isocelesLabels,
          obtuseNum: isocelesTriangle === 'isoceles_obtuse_triangle1' ? 1 : 0,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTrianglesThatHaveXObtuseAngles(obtuseAngles)}
        pdfTitle={translate.instructions.circleTrianglesThatHaveXObtuseAngles(obtuseAngles)}
        testCorrect={options
          .filter(({ obtuseNum }) => obtuseNum === obtuseAngles)
          .map(({ value }) => value)}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(({ triangle, shape, angleLabels, value }) => {
            return {
              component:
                triangle === 'raTriangle' ? (
                  <LabelledRATriangle dimens={dimens} variation={shape as 'E' | 'F'} />
                ) : (
                  <LabelledShape
                    dimens={dimens}
                    shapeName={
                      shape as
                        | 'isoceles_acute_triangle1'
                        | 'isoceles_acute_triangle2'
                        | 'isoceles_obtuse_triangle1'
                        | 'scalene_triangle1'
                        | 'scalene_triangle2'
                        | 'scalene_triangle3'
                    }
                    angleLabels={angleLabels}
                  />
                ),
              value
            };
          })
        }
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aEh2',
  description: 'aEh',
  keywords: ['Triangles', 'Obtuse', 'Angles'],
  schema: z.object({
    obtuseAngles: numberEnum([0, 1]),
    raTriangle: z.enum(['E', 'F']),
    scaleneObtuseTriangle: z.enum(['scalene_triangle1', 'scalene_triangle2']),
    isocelesTriangle: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'isoceles_obtuse_triangle1'
    ]),
    isocelesAngles: z.array(z.number()).length(2),
    scaleneAcuteAngles: z.array(z.number()).length(2),
    scaleneObtuseAngles: z.array(z.number()).length(2)
  }),

  simpleGenerator: () => {
    const obtuseAngles = getRandomFromArray([0, 1] as const);

    const raTriangle = getRandomFromArray(['E', 'F'] as const);
    const scaleneObtuseTriangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2'
    ] as const);

    const acuteSvg = 'scalene_triangle3';
    const angle1 = randomIntegerInclusive(
      triangleConstraintsBottom[acuteSvg][0],
      triangleConstraintsBottom[acuteSvg][1]
    );

    const scaleneAcuteAngles = [
      angle1,
      randomIntegerInclusive(
        Math.max(triangleConstraintsTop[acuteSvg][0], 91 - angle1),
        triangleConstraintsTop[acuteSvg][1]
      )
    ];

    const scaleneObtuseAngles = [
      randomIntegerInclusive(
        triangleConstraintsBottom[scaleneObtuseTriangle][0],
        triangleConstraintsBottom[scaleneObtuseTriangle][1]
      ),
      randomIntegerInclusive(
        triangleConstraintsTop[scaleneObtuseTriangle][0],
        triangleConstraintsTop[scaleneObtuseTriangle][1]
      )
    ];

    const isocelesTriangle:
      | 'isoceles_acute_triangle1'
      | 'isoceles_acute_triangle2'
      | 'isoceles_obtuse_triangle1' = getRandomBoolean()
      ? getRandomFromArray(['isoceles_acute_triangle1', 'isoceles_acute_triangle2'] as const)
      : 'isoceles_obtuse_triangle1';

    const topIsoAngle = randomIntegerInclusiveStep(
      triangleConstraintsTop[isocelesTriangle][0],
      triangleConstraintsTop[isocelesTriangle][1],
      2
    );
    const baseIsoAngle = (180 - topIsoAngle) / 2;
    let isocelesAngles = [];
    if (isocelesTriangle === 'isoceles_obtuse_triangle1') {
      isocelesAngles = [baseIsoAngle, topIsoAngle];
    } else {
      isocelesAngles = [topIsoAngle, baseIsoAngle];
    }

    return {
      raTriangle,
      obtuseAngles,
      scaleneObtuseTriangle,
      scaleneAcuteAngles,
      scaleneObtuseAngles,
      isocelesTriangle,
      isocelesAngles
    };
  },
  Component: props => {
    const {
      question: {
        raTriangle,
        obtuseAngles,
        scaleneObtuseTriangle,
        scaleneAcuteAngles,
        scaleneObtuseAngles,
        isocelesTriangle,
        isocelesAngles
      },
      translate
    } = props;

    const isocelesLabels = isocelesAngles.map(angle => translate.units.numberOfDegrees(angle));

    // Randomly order these options
    const options = shuffle(
      [
        {
          triangle: 'raTriangle',
          shape: raTriangle,
          obtuseNum: 0,
          value: 'A'
        },
        {
          triangle: 'scaleneAcute',
          angleLabels: scaleneAcuteAngles.map(angle => translate.units.numberOfDegrees(angle)),
          shape: 'scalene_triangle3',
          obtuseNum: 0,
          value: 'B'
        },
        {
          triangle: 'scaleneObtuse',
          shape: scaleneObtuseTriangle,
          angleLabels: [
            '',
            ...scaleneObtuseAngles.map(angle => translate.units.numberOfDegrees(angle))
          ],
          obtuseNum: 1,
          value: 'C'
        },
        {
          triangle: 'isoceles',
          shape: isocelesTriangle,
          angleLabels:
            isocelesTriangle === 'isoceles_obtuse_triangle1'
              ? ['', ...isocelesLabels]
              : isocelesLabels,
          obtuseNum: isocelesTriangle === 'isoceles_obtuse_triangle1' ? 1 : 0,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTrianglesThatHaveXObtuseAngles(obtuseAngles)}
        pdfTitle={translate.instructions.circleTrianglesThatHaveXObtuseAngles(obtuseAngles)}
        testCorrect={options
          .filter(({ obtuseNum }) => obtuseNum === obtuseAngles)
          .map(({ value }) => value)}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(({ triangle, shape, angleLabels, value }) => {
            return {
              component:
                triangle === 'raTriangle' ? (
                  <LabelledRATriangle dimens={dimens} variation={shape as 'E' | 'F'} />
                ) : (
                  <LabelledShape
                    dimens={dimens}
                    shapeName={
                      shape as
                        | 'isoceles_acute_triangle1'
                        | 'isoceles_acute_triangle2'
                        | 'isoceles_obtuse_triangle1'
                        | 'scalene_triangle1'
                        | 'scalene_triangle2'
                        | 'scalene_triangle3'
                    }
                    angleLabels={angleLabels}
                  />
                ),
              value
            };
          })
        }
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aEi',
  description: 'aEi',
  keywords: ['Triangles', 'Angles', 'Calculate'],
  schema: z.object({
    angle1: z.number().int().min(31).max(99),
    angle2: z.number().int().min(11).max(49),
    triangle: z.enum(['scalene_triangle1', 'scalene_triangle2', 'scalene_triangle3', 'E', 'F'])
  }),
  simpleGenerator: () => {
    const isRA = getRandomBoolean();
    const triangle = isRA
      ? // RA options
        getRandomFromArray(['E', 'F'] as const)
      : getRandomFromArray([
          'scalene_triangle1',
          'scalene_triangle2',
          'scalene_triangle3'
        ] as const);

    const { angle1, angle2 } = rejectionSample(
      () => {
        const angle1 = isRA ? 90 : randomIntegerInclusive(31, 99, { constraint: x => x !== 90 });
        const angle2 = randomIntegerInclusive(11, 49, { constraint: x => x + angle1 !== 90 });
        return { angle1, angle2 };
      },
      val => {
        const angle3 = 180 - val.angle1 - val.angle2;
        return triangle === 'scalene_triangle1' || triangle === 'scalene_triangle2'
          ? val.angle1 > 90 || val.angle2 > 90 || angle3 > 90
          : triangle === 'scalene_triangle3'
          ? val.angle1 < 90 && val.angle2 < 90 && angle3 < 90
          : true;
      }
    );

    return { angle1, angle2, triangle };
  },
  Component: props => {
    const {
      question: { angle1, angle2, triangle },
      translate
    } = props;

    const angle3 = 180 - angle1 - angle2;

    const labels = sortNumberArray([angle1, angle2, angle3], 'ascending').map(deg =>
      deg === angle3 ? ALGEBRAIC_X : deg === 90 ? '' : translate.units.numberOfDegrees(deg)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        Content={({ dimens }) =>
          triangle === 'E' || triangle === 'F' ? (
            <LabelledRATriangle dimens={dimens} angleLabels={labels} variation={triangle} />
          ) : (
            <LabelledShape dimens={dimens} shapeName={triangle} angleLabels={labels} />
          )
        }
        inputMaxCharacters={3}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansDeg()}`}
        testCorrect={[angle3.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aEj',
  description: 'aEj',
  keywords: ['Triangles', 'Angles', 'Calculate', 'Decimals'],
  schema: z.object({
    angle1: z
      .number()
      .min(31.1)
      .max(99.9)
      .refine(x => Math.floor(x) % 10 !== 0, 'floor of angle should not be a multiple of 10'),
    angle2: z
      .number()
      .min(11.1)
      .max(49.9)
      .refine(x => Math.floor(x) % 5 !== 0, 'floor of angle should not be a multiple of 5'),
    angle3: z.number().min(30.2).max(137.8),
    triangle: z.enum(['scalene_triangle1', 'scalene_triangle2', 'scalene_triangle3'])
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2',
      'scalene_triangle3'
    ] as const);

    const { angle1, angle2, angle3 } = rejectionSample(
      () => {
        const angle1Whole = randomIntegerInclusive(31, 99, { constraint: x => x % 10 !== 0 });
        const angle1Decimal = randomIntegerInclusive(1, 9);

        const angle2Whole = randomIntegerInclusive(11, 49, { constraint: x => x % 5 !== 0 });
        const angle2Decimal = randomIntegerInclusive(1, 9);

        const angle1 = angle1Whole + angle1Decimal / 10;
        const angle2 = angle2Whole + angle2Decimal / 10;
        const angle3 = 180 - angle1 - angle2;
        return { angle1, angle2, angle3 };
      },
      val =>
        triangle === 'scalene_triangle1' || triangle === 'scalene_triangle2'
          ? val.angle1 > 90 || val.angle2 > 90 || val.angle3 > 90
          : triangle === 'scalene_triangle3'
          ? val.angle1 < 90 && val.angle2 < 90 && val.angle3 < 90
          : true
    );

    return { angle1, angle2, angle3, triangle };
  },
  Component: props => {
    const {
      question: { angle1, angle2, angle3, triangle },
      translate
    } = props;

    const labels = sortNumberArray([angle1, angle2, angle3], 'ascending').map(deg =>
      deg === angle3 ? ALGEBRAIC_X : translate.units.numberOfDegrees(deg)
    );

    return (
      <QF1ContentAndSentence
        extraSymbol={'decimalPoint'}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={triangle} angleLabels={labels} />
        )}
        inputMaxCharacters={5}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansDeg()}`}
        testCorrect={([userAnswer]) => compareFloats(userAnswer, angle3)}
        customMarkSchemeAnswer={{
          answersToDisplay: [angle3.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aEk',
  description: 'aEk',
  keywords: ['Triangles', 'Angles', 'Calculate', 'Decimals'],
  schema: z.object({
    angle1: z
      .number()
      .int()
      .min(11)
      .max(49)
      .refine(x => x % 5 !== 0, 'angle should not be a multiple of 5'),
    angle2Whole: z
      .number()
      .int()
      .min(21)
      .max(69)
      .refine(x => x % 5 !== 0, 'angle should not be a multiple of 5'),
    angle2Decimal: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusive(11, 49, { constraint: x => x % 5 !== 0 });
    const angle2Whole = randomIntegerInclusive(21, 69, { constraint: x => x % 5 !== 0 });
    const angle2Decimal = randomIntegerInclusive(1, 9);

    return { angle1, angle2Whole, angle2Decimal };
  },
  Component: props => {
    const {
      question: { angle1, angle2Whole, angle2Decimal },
      translate
    } = props;
    const angle2 = angle2Whole + angle2Decimal / 10;
    const angle3 = 180 - (angle1 + angle2);
    return (
      <QF2AnswerBoxOneSentence
        extraSymbol={'decimalPoint'}
        title={translate.instructions.twoAnglesInTriangleAreXandYWhatIsSizeOfThird(
          angle1.toLocaleString(),
          angle2.toLocaleString()
        )}
        inputMaxCharacters={5}
        sentence={translate.answerSentences.ansDeg()}
        testCorrect={([userAnswer]) => compareFloats(userAnswer, angle3)}
        customMarkSchemeAnswer={{
          answersToDisplay: [angle3.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aEl',
  description: 'aEl',
  keywords: ['Triangles', 'Angles', 'Calculate', 'Multi-step'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(11)
      .max(34)
      .refine(x => x % 5 !== 0, 'angle should not be a multiple of 5'),
    multiple: numberEnum([2, 3, 4])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(11, 34, { constraint: x => x % 5 !== 0 });
    const multiple = getRandomFromArray([2, 3, 4] as const);

    return { angle, multiple };
  },
  Component: props => {
    const {
      question: { angle, multiple },
      translate
    } = props;

    const sizeOf2ndAngle = {
      2: translate.misc.twice(),
      3: translate.misc.XTimes(translate.numbersAsWords[3]()),
      4: translate.misc.XTimes(translate.numbersAsWords[4]())
    };
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.oneAngleInTriangleIsXAnotherIsYSizeOfFirstWhatIsSizeOfThirdAngle(
          angle,
          sizeOf2ndAngle[multiple]
        )}
        sentence={translate.answerSentences.ansDeg()}
        testCorrect={[(180 - (angle + multiple * angle)).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AnglesInATriangle',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
