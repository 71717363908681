import IconButton from 'common/src/components/atoms/IconButton';
import Text from 'common/src/components/typography/Text';
import { colors } from 'common/src/theme/colors';
import SvgImage from 'common/src/utils/svgImage';
import { useCallback, useState } from 'react';
import { View } from 'react-native';
import Animated, {
  cancelAnimation,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming
} from 'react-native-reanimated';

type Props = {
  /** The text to copy to clipboard */
  text: string;
  /** The width of the button. Default: 64. The icon width is 3/4 of that. */
  width?: number;
};

/**
 * Icon for copying text to clipboard.
 *
 * When clicked it makes an absolutely positioned text element appear in the foreground, to the right. That element can
 * be dismissed by clicking on it, and will fade over time.
 */
export default function CopyToClipboardIcon({ text, width = 64 }: Props) {
  const [isCopyMessageShowing, setCopyMessageShowing] = useState(false);

  const copyMessageOpacity = useSharedValue(0);
  const copyMessageOpacityStyle = useAnimatedStyle(
    () => ({ opacity: copyMessageOpacity.value }),
    [copyMessageOpacity]
  );

  const showCopyMessage = useCallback(() => {
    setCopyMessageShowing(true);
    cancelAnimation(copyMessageOpacity);
    copyMessageOpacity.value = 1;
    copyMessageOpacity.value = withDelay(
      3000,
      withTiming(0, { duration: 2000 }, success => {
        if (success) {
          setCopyMessageShowing(false);
        }
      })
    );
  }, [copyMessageOpacity]);

  return (
    <View
      style={{ flexDirection: 'row', alignItems: 'center', gap: 10, height: width, width: width }}
    >
      <IconButton
        width={width}
        iconWidth={(width * 3) / 4}
        baseColor={colors.greys700}
        onPressInColor={colors.greys500}
        onPress={async () => {
          await navigator.clipboard.writeText(text);
          showCopyMessage();
        }}
      >
        <SvgImage fill source={require('pupil-app/assets/svg/zest/copy.svg')} />
      </IconButton>

      {isCopyMessageShowing && (
        <Animated.View
          style={[
            {
              position: 'absolute',
              top: -30,
              left: 54,
              width: 220,
              backgroundColor: colors.greys300,
              borderRadius: 10,
              padding: 10,
              zIndex: 2
            },
            copyMessageOpacityStyle
          ]}
          onClick={() => setCopyMessageShowing(false)}
        >
          <Text
            variant="WRN400"
            style={{
              fontSize: 20,
              lineHeight: 30
            }}
          >
            Copied to clipboard!
          </Text>
        </Animated.View>
      )}
    </View>
  );
}
