import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { MarkupAssets } from '../../../../markup';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { barModelColors } from '../../../../theme/colors';
import { countRange, nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import { compareFractions } from '../../../../utils/fractions';
import { Rect, Svg } from 'react-native-svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ADD } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  EqualShapePart,
  equalShapePartsSchema,
  getEqualShapesSVGPaths,
  getRandomUniqueEqualShapeParts,
  getRandomUniqueUnequalShapeParts,
  shapePartsSchema
} from '../../../../utils/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aL6',
  description: 'aL6',
  keywords: ['Whole', 'Unequal parts', 'Equal parts'],
  schema: z.object({
    shapes: z
      .object({
        svgName: shapePartsSchema,
        isEqual: z.boolean()
      })
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    const answerCount = randomIntegerInclusive(1, 3);
    const correctShapes = getRandomUniqueEqualShapeParts(answerCount).map(val => ({
      svgName: val,
      isEqual: true
    }));

    const incorrectShapes = getRandomUniqueUnequalShapeParts(4 - answerCount).map(val => ({
      svgName: val,
      isEqual: false
    }));

    return { shapes: shuffle([...correctShapes, ...incorrectShapes]) };
  },
  Component: props => {
    const {
      question: { shapes },
      translate
    } = props;

    const isEqual = shapes.filter(shape => shape.isEqual);

    return (
      <QF11SelectImagesUpTo4
        title={
          isEqual.length > 1
            ? translate.instructions.selectShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.selectShapeThatHasBeenSplitIntoEqualParts()
        }
        pdfTitle={
          isEqual.length > 1
            ? translate.instructions.circleShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.circleShapeThatHasBeenSplitIntoEqualParts()
        }
        testCorrect={isEqual.map(val => val.svgName)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            return {
              value: shape.svgName,
              component: (
                <AssetSvg
                  name={shape.svgName as SvgName}
                  height={dimens.height * 0.8}
                  width={dimens.width * 0.8}
                />
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aL7',
  description: 'aL7',
  keywords: ['Whole', 'Equal parts', 'Unit fraction'],
  schema: z.object({
    shape: z.object({
      svgName: equalShapePartsSchema,
      parts: z.number()
    })
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(2, 6);
    const equalShapePaths = getEqualShapesSVGPaths(parts);

    const shapeSvg = getRandomFromArray(equalShapePaths) as EqualShapePart;

    return { shape: { svgName: shapeSvg, parts } };
  },

  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.eachEqualPartIsWorth()}
        title={translate.instructions.completeSentenceForShape()}
        testCorrect={[shape.parts.toString()]}
        Content={({ dimens }) => (
          <AssetSvg name={shape.svgName} height={dimens.height * 0.8} width={dimens.width * 0.8} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aL8',
  description: 'aL8',
  questionHeight: 900,
  keywords: [
    'Bar model',
    'One whole',
    'Equal parts',
    'Shaded',
    'Not shaded',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(10),
    shaded: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const shaded = randomIntegerInclusive(2, denominator);

    return { denominator, shaded };
  },

  Component: props => {
    const {
      question: { denominator, shaded },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barArray = shuffle(
      countRange(denominator).map(i => (i < shaded ? numeratorColor : 'white')),
      { random: seededRandom(props.question) }
    );

    return (
      <MarkupAssets
        elements={{
          barModelShaded: (
            <Svg width={40} height={60}>
              <Rect width={40} height={60} fill={numeratorColor} strokeWidth={2} stroke="black" />
            </Svg>
          ),
          barModelUnshaded: (
            <Svg width={40} height={60}>
              <Rect width={40} height={60} fill="white" strokeWidth={2} stroke="black" />
            </Svg>
          )
        }}
      >
        <QF1ContentAndSentences
          sentences={[
            `<asset name='barModelShaded'/> = <frac nAns='' dAns='' />`,
            `<asset name='barModelUnshaded'/> = <frac nAns='' dAns='' />`
          ]}
          questionHeight={900}
          title={`${translate.instructions.whatFractionOfTheShapeIsShaded()}<br/>${translate.instructions.whatFractionIsNotShaded()}`}
          testCorrect={answer =>
            compareFractions(answer[0], [shaded, denominator]) &&
            compareFractions(answer[1], [denominator - shaded, denominator])
          }
          Content={({ dimens }) => (
            <ShadedFractionBarModel
              totalSubSections={denominator}
              customColorMap={barArray}
              width={dimens.width}
            />
          )}
          pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
          style={{ flexDirection: 'row' }}
          inputMaxCharacters={2}
          customMarkSchemeAnswer={{
            answersToDisplay: [
              [shaded.toLocaleString(), denominator.toLocaleString()],
              [(denominator - shaded).toLocaleString(), denominator.toLocaleString()]
            ],
            answerText: translate.markScheme.acceptEquivalentFractions()
          }}
          pdfDirection="column"
        />
      </MarkupAssets>
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aL82',
  description: 'aL8',
  questionHeight: 900,
  keywords: [
    'Bar model',
    'One whole',
    'Equal parts',
    'Shaded',
    'Not shaded',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(10),
      shaded: z.number().int().min(1).max(9)
    })
    .refine(val => val.shaded < val.denominator, 'shaded must be less than denominator'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const shaded = randomIntegerInclusive(1, denominator - 1);

    return { denominator, shaded };
  },

  Component: props => {
    const {
      question: { denominator, shaded },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barArray = shuffle(
      countRange(denominator).map(i => (i < shaded ? numeratorColor : 'white')),
      { random: seededRandom(props.question) }
    );

    return (
      <MarkupAssets
        elements={{
          barModelShaded: (
            <Svg width={40} height={60}>
              <Rect width={40} height={60} fill={numeratorColor} strokeWidth={2} stroke="black" />
            </Svg>
          ),
          barModelUnshaded: (
            <Svg width={40} height={60}>
              <Rect width={40} height={60} fill="white" strokeWidth={2} stroke="black" />
            </Svg>
          )
        }}
      >
        <QF1ContentAndSentences
          sentences={[
            `<asset name='barModelShaded'/> = <frac nAns='' dAns='' />`,
            `<asset name='barModelUnshaded'/> = <frac nAns='' dAns='' />`
          ]}
          questionHeight={900}
          title={`${translate.instructions.whatFractionOfTheShapeIsShaded()}<br/>${translate.instructions.whatFractionIsNotShaded()}`}
          testCorrect={answer =>
            compareFractions(answer[0], [shaded, denominator]) &&
            compareFractions(answer[1], [denominator - shaded, denominator])
          }
          Content={({ dimens }) => (
            <ShadedFractionBarModel
              totalSubSections={denominator}
              customColorMap={barArray}
              width={dimens.width}
            />
          )}
          pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
          style={{ flexDirection: 'row' }}
          inputMaxCharacters={2}
          customMarkSchemeAnswer={{
            answersToDisplay: [
              [shaded.toLocaleString(), denominator.toLocaleString()],
              [(denominator - shaded).toLocaleString(), denominator.toLocaleString()]
            ],
            answerText: translate.markScheme.acceptEquivalentFractions()
          }}
          pdfDirection="column"
        />
      </MarkupAssets>
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aL9',
  description: 'aL9',
  keywords: [
    'Bar model',
    'One whole',
    'Equal parts',
    'Shaded',
    'Not shaded',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    shaded: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const shaded = randomIntegerInclusive(2, denominator - 1);

    return { denominator, shaded };
  },

  Component: props => {
    const {
      question: { denominator, shaded },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barArray = shuffle(
      countRange(denominator).map(i => (i < shaded ? numeratorColor : 'white')),
      { random: seededRandom(props.question) }
    );

    return (
      <QF1ContentAndSentence
        sentence={`<frac n='${shaded.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${ADD} <frac nAns='' dAns='' /> = ${(1).toLocaleString()}`}
        title={translate.instructions.useBarModelToCompleteAddition()}
        testCorrect={answer => compareFractions(answer, [denominator - shaded, denominator])}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            customColorMap={barArray}
            width={dimens.width}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [(denominator - shaded).toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={2}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMa',
  description: 'aMa',
  keywords: ['Addition', 'Numerator', 'Denominator', 'Fraction', 'One whole'],
  questionHeight: 1000,
  schema: z.object({
    denominator1: z.number().int().min(3).max(11),
    numerator1: z.number().int().min(1).max(11),
    denominator2: z.number().int().min(3).max(12),
    numerator2: z.number().int().min(1).max(11),
    isLeft: z.array(z.boolean()).length(2)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 11);
    const denominator2 = randomIntegerInclusive(denominator1 + 1, 12);

    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const numerator2 = randomIntegerInclusive(
      1,
      denominator2 - 1,
      denominator1 === denominator2
        ? {
            constraint: x => x !== numerator1
          }
        : undefined
    );

    const isLeft = countRange(2).map(getRandomBoolean);

    return { denominator1, denominator2, numerator1, numerator2, isLeft };
  },

  Component: props => {
    const {
      question: { denominator1, denominator2, numerator1, numerator2, isLeft },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        testCorrect={answer =>
          compareFractions(answer[0], [denominator1 - numerator1, denominator1]) &&
          compareFractions(answer[1], [denominator2 - numerator2, denominator2])
        }
        questionHeight={1000}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(denominator1 - numerator1).toLocaleString(), denominator1.toLocaleString()],
            [(denominator2 - numerator2).toLocaleString(), denominator2.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentences={[
          isLeft[0]
            ? `<frac n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> ${ADD} <frac nAns='' dAns='' /> = ${(1).toLocaleString()}`
            : `${(1).toLocaleString()} = <frac n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> ${ADD} <frac nAns='' dAns='' />`,
          isLeft[1]
            ? `<frac nAns='' dAns='' /> ${ADD} <frac n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' /> = ${(1).toLocaleString()}`
            : `${(1).toLocaleString()} = <frac n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' /> ${ADD} <frac nAns='' dAns='' />`
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aMb',
  description: 'aMb',
  keywords: ['Table', 'Fraction', 'Numerator', 'Denominator', 'Part', 'Whole'],
  schema: z.object({
    denominator1: z.number().int().min(3).max(9),
    denominator2: z.number().int().min(3).max(9),
    denominator3: z.number().int().min(10).max(20),
    denominator4: z.number().int().min(10).max(20),
    denominator5: z.number().int().min(20).max(100).step(10),
    denominator6: z.number().int().min(20).max(100).step(10),
    numerator1: z.number().int().min(1).max(8),
    numerator2: z.number().int().min(1).max(8),
    numerator3: z.number().int().min(1).max(19),
    numerator4: z.number().int().min(1).max(19),
    numerator5: z.number().int().min(1).max(99),
    numerator6: z.number().int().min(1).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominator1 = randomIntegerInclusive(3, 9);
        const denominator2 = randomIntegerInclusive(3, 9);
        const denominator3 = randomIntegerInclusive(10, 20);
        const denominator4 = randomIntegerInclusive(10, 20);
        const denominator5 = randomIntegerInclusiveStep(20, 100, 10);
        const denominator6 = randomIntegerInclusiveStep(20, 100, 10);

        const numerator1 = randomIntegerInclusive(1, denominator1 - 1, {
          constraint: x => x !== denominator1 / 2
        });
        const numerator2 = randomIntegerInclusive(1, denominator2 - 1, {
          constraint: x => x !== denominator2 / 2
        });
        const numerator3 = randomIntegerInclusive(1, denominator3 - 1, {
          constraint: x => x !== denominator3 / 2
        });
        const numerator4 = randomIntegerInclusive(1, denominator4 - 1, {
          constraint: x => x !== denominator4 / 2
        });
        const numerator5 = randomIntegerInclusive(1, denominator5 - 1, {
          constraint: x => x !== denominator5 / 2
        });
        const values = [
          [numerator1, denominator1],
          [numerator2, denominator2],
          [numerator3, denominator3],
          [numerator4, denominator4],
          [numerator5, denominator5]
        ];

        // ensure we have at least one of each
        const smallPart = values.filter(x => x[0] < x[1] / 2).length < 1;
        const max = smallPart ? denominator6 / 2 - 1 : denominator6 - 1;
        const min = smallPart ? 1 : denominator6 / 2 + 1;
        const numerator6 = randomIntegerInclusive(min, max);

        return {
          denominator1,
          denominator2,
          denominator3,
          denominator4,
          denominator5,
          denominator6,
          numerator1,
          numerator2,
          numerator3,
          numerator4,
          numerator5,
          numerator6
        };
      },
      val => {
        const values = [
          [val.numerator1, val.denominator1],
          [val.numerator2, val.denominator2],
          [val.numerator3, val.denominator3],
          [val.numerator4, val.denominator4],
          [val.numerator5, val.denominator5],
          [val.numerator6, val.denominator6]
        ];
        return nestedArrayHasNoDuplicates(values);
      }
    ),
  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        denominator3,
        denominator4,
        denominator5,
        denominator6,
        numerator1,
        numerator2,
        numerator3,
        numerator4,
        numerator5,
        numerator6
      },
      translate,
      displayMode
    } = props;

    const values = [
      [numerator1, denominator1],
      [numerator2, denominator2],
      [numerator3, denominator3],
      [numerator4, denominator4],
      [numerator5, denominator5],
      [numerator6, denominator6]
    ];

    const items = shuffle(
      values.map(val => ({
        string: `<frac n='${val[0].toLocaleString()}' d='${val[1].toLocaleString()}' />`,
        smallPart: val[0] < val[1] / 2
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortFractionsIntoTheTable()}
        testCorrect={[
          items.filter(i => i.smallPart).map(i => i.string),
          items.filter(i => !i.smallPart).map(i => i.string)
        ]}
        headerHeight={100}
        headerStyle={{ paddingHorizontal: 10 }}
        headerTextStyle={{ textAlign: 'center' }}
        zoneNames={[
          translate.tableHeaders.smallPartOfWhole(),
          translate.tableHeaders.largePartOfWhole()
        ]}
        items={items.map(val => ({
          component: (
            <TextStructure
              sentence={val.string}
              fractionDividerStyle={{ marginVertical: 1 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
            />
          ),
          value: val.string
        }))}
        pdfItemVariant="pdfSquare"
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandTheWhole',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
