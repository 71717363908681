import { MutableRefObject, ReactNode, useRef } from 'react';

/** Not exported, so there's no way it can be part of T. */
const UNINITIALIZED = Symbol('uninitialized');

/**
 * Like {@link useRef}, but takes a function to initialize it.
 */
export function useRefWithInitializer<T>(initializer: () => T): MutableRefObject<T> {
  const ref = useRef<T | typeof UNINITIALIZED>(UNINITIALIZED);
  if (ref.current === UNINITIALIZED) {
    ref.current = initializer();
  }
  return ref as MutableRefObject<T>;
}

/**
 * Like useMemo, but guaranteed to be stable.
 *
 * React's useMemo may forget your previous value and compute a new one even if dependencies haven't changed, if the
 * system is running low on memory. This is an implementation of useMemo from scratch which doesn't do that.
 */
export function useMemoStable<T>(factory: () => T, dependencies: readonly unknown[]): T {
  const prevVal = useRefWithInitializer(factory);
  const prevDeps = useRef(dependencies);
  if (prevDeps.current.some((dep, i) => dep !== dependencies[i])) {
    prevVal.current = factory();
  }
  return prevVal.current;
}

/**
 * Like useCallback, but guaranteed to be stable. See {@link useMemoStable}.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function useCallbackStable<T extends Function>(callback: T, deps: readonly unknown[]): T {
  return useMemoStable(() => callback, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Either a thing, or a function to produce that thing.
 *
 * The second generic parameter is the list of arguments that the function expects.
 *
 * ```
 * function example(stuffInput: ThingOrFunction<Foo, [number, string]>) {
 *   const stuff = resolveThingOrFunction(stuffInput, 7, 'hi);
 * }
 * ```
 */
export type ThingOrFunction<Thing, Args extends unknown[] = []> =
  | Thing
  | ((...args: Args) => Thing);

/**
 * Helper function to resolve a {@link ThingOrFunction} to the thing itself.
 *
 * Simply checks whether the thing was a function or not, and runs the function with the arguments given if so.
 */
export function resolveThingOrFunction<Thing, Args extends unknown[] = []>(
  thing: ThingOrFunction<Thing, Args>,
  ...args: Args
): Thing {
  return typeof thing === 'function' ? (thing as (...args: Args) => Thing)(...args) : thing;
}

/**
 * Either a react element, or a render function for a react element.
 *
 * The generic parameter is extra props, but this can be omitted for elements with no props.
 *
 * ```
 * function example(fooInput: ElementOrRenderFunction, barInput; ElementOrRenderFunction<{width: number}>) {
 *   const fooInput = resolveElementOrRenderFunction(fooInput);
 *   const barInput = resolveElementOrRenderFunction(barInput, {width: 100});
 * }
 * ```
 */
export type ElementOrRenderFunction<Props extends Record<string, unknown> | null = null> =
  ThingOrFunction<ReactNode, Props extends null ? [] : [props: Props]>;

/**
 * Turn {@link ElementOrRenderFunction} into a react node, by providing props (if required).
 */
export function resolveElementOrRenderFunction<Props extends Record<string, unknown> | null>(
  e: ElementOrRenderFunction<Props> | undefined,
  ...args: Props extends null ? [] : [props: Props]
): ReactNode {
  return resolveThingOrFunction(e, ...args);
}

/** Type of the argument to a {@link SetState} function, the state updater returned by react's useState. */
type NewStateOrAction<T> = ThingOrFunction<T, [prevState: T]>;

/**
 * Custom type. Equivalent to, but quicker to write than, `React.Dispatch<React.SetStateAction<T>>`.
 *
 * This is the type of the second return value of `useState`. More complicated than just
 * `(newState: T) => void` because you can provide an update action, which takes the latest value of the
 * state. This is useful to avoid bugs due to simultaneous updates, where some updates are lost.
 */
export type SetState<T> = (newStateOrAction: NewStateOrAction<T>) => void;

/**
 * Function for mapping a react set-state (i.e. a function which either takes the new state or it takes a function
 * mapping the old state to the new state).
 *
 * This is only required if you have a single `useState` with all the state, and need to split apart or otherwise
 * transform that state. In our code, that is the case as `BaseLayout` does all the state management, and chunks
 * of that state are passed onto different children.
 *
 * Working with `SetState<T>` (AKA `React.Dispatch<React.SetStateAction<T>>` AKA `(action: T | ((t: T) => T)) => void`)
 * manually is difficult, as you need to keep checking whether the SetStateAction is a value or a function. That's why
 * this utility function is useful.
 *
 * Note that it might be easier in general to simply use `(value: T) => void` instead of
 * `SetState<T>` for as long as possible - until the former causes a problem where
 * simultaneous updates are lost.
 *
 * Warning: if your state itself contains functions, you may get bad results! In particular, if the generic type B is
 * a function, you cannot use this utility.
 *
 * For example, I might have a managed component that looks like:
 *
 * ```
 * const ToggleButton = ({label, activated, setActivated}: {
 *   label: string,
 *   activated: boolean,
 *   setActivated: SetState<boolean>
 * }) => {
 *   return <TouchableOpacity onPress={() => setActivated(old => !old)} style={{backgroundColor: activated ? 'green' : 'red' }}>
 *     <Text>{label}</Text>
 *   </TouchableOpacity>
 * }
 * ```
 *
 * A parent might want to manage two of these from a single `useState`.
 *
 * ```
 * const ConfigMenu = () => {
 *   const [options, setOptions] = useState({sound: false, animations: false});
 *
 *   return <View>
 *     <ToggleButton
 *       label='Sound'
 *       activated={options.sound}
 *       setActivated={transformSetState(
 *         setOptions,
 *         state => state.sound,
 *         (activated, old) => {
 *           const newState = {...old};
 *           newState.sound = activated;
 *           return newState;
 *         }
 *       )}
 *     />
 *     <ToggleButton
 *       label='Animations'
 *       activated={options.animations}
 *       setActivated={transformSetState(
 *         setOptions,
 *         state => state.animations,
 *         (activated, old) => {
 *           const newState = {...old};
 *           newState.animations = activated;
 *           return newState;
 *         }
 *       )}
 *     />
 *   </View>
 * }
 * ```
 *
 * @param setState the `SetState` function that you want to transform
 * @param transformation the transformation to apply
 * @param applyUpdate the reverse of the transformation. Because the transformation might be lossy, `applyUpdate` is
 * also provided with the old pre-transformation value, in case you need to merge in the new transformed state. See
 * the example above where this is done.
 * @returns the `SetState` function for the transformed state
 */
export function transformSetState<A, B>(
  setState: SetState<A>,
  transformation: (oldA: A) => B,
  applyUpdate: (newB: B, oldA: A) => A
): SetState<B> {
  return (bAction: NewStateOrAction<B>) => {
    let aAction: NewStateOrAction<A>;
    if (typeof bAction === 'function') {
      aAction = (old: A) => applyUpdate((bAction as (prevState: B) => B)(transformation(old)), old);
    } else {
      aAction = (old: A) => applyUpdate(bAction, old);
    }
    return setState(aAction);
  };
}

/**
 * Transforms a {@link SetState} from a state which is an object, to a state which is one of the properties of that
 * object. This is like {@link transformSetState} but only works for the case where the transformation is just
 * extractng a property from an object (i.e. a projection).
 *
 * For example,
 *
 * ```
 * const [foo, setFoo] = useState<{x: number, y: string}>({});
 * const x: number = foo.x;
 * const setX: SetState<number> = projectSetState(setFoo, 'x');
 * const incrementX = () => setBar(oldX => oldX + 1);
 * ```
 *
 * The generic type `A` may be either an object or an array. (It is cloned with ... syntax, so don't expect
 * clever stuff like prototype chains or self-referencial loops to be preserved.)
 */
export function projectSetState<
  A extends Record<string | number | symbol, unknown> | unknown[],
  L extends keyof A
>(setState: SetState<A>, propertyLabel: L): SetState<A[L]> {
  return transformSetState(
    setState,
    oldA => oldA[propertyLabel],
    (newB, oldA) => {
      const newA = Array.isArray(oldA) ? ([...oldA] as unknown as A) : { ...oldA };
      newA[propertyLabel] = newB;
      return newA;
    }
  );
}
