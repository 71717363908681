import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import {
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_C,
  ALGEBRAIC_P,
  ALGEBRAIC_X,
  ALGEBRAIC_Y
} from '../../../../constants';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  getQuadrilateralSvgName,
  quadrilateralColorsSchema
} from '../../../../utils/quadrilateralImages';
import { AssetSvg } from '../../../../assets/svg';
import TextStructure from '../../../../components/molecules/TextStructure';
import { countRange } from '../../../../utils/collections';
import { Dimens } from '../../../../theme/scaling';
import { getActualDimens } from '../../../../utils/getActualDimens';
import { getGenderFromName, getRandomName, nameSchema } from '../../../../utils/names';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aAu',
  description: 'aAu',
  keywords: ['Angles', 'Right angles', 'Calculate'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(6)
      .max(79)
      .refine(x => x % 5 !== 0),
    shape: z.enum([
      'Rectangle1_interior_lines_and_angles',
      'Rectangle2_interior_lines_and_angles',
      'Rectangle3_interior_lines_and_angles',
      'Rectangle4_interior_lines_and_angles'
    ])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(6, 79, { constraint: x => x % 5 !== 0 });
    const shape = getRandomFromArray([
      'Rectangle1_interior_lines_and_angles',
      'Rectangle2_interior_lines_and_angles',
      'Rectangle3_interior_lines_and_angles',
      'Rectangle4_interior_lines_and_angles'
    ] as const);

    return { angle, shape };
  },
  Component: props => {
    const {
      question: { angle, shape },
      translate
    } = props;

    const angleLabels =
      angle >= 45
        ? [ALGEBRAIC_P, translate.units.numberOfDegrees(angle)]
        : [translate.units.numberOfDegrees(angle), ALGEBRAIC_P];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsRectangleWhatIsSizeOfAngleMarkedP()}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={angleLabels} />
        )}
        sentence={`${ALGEBRAIC_P} = ${translate.answerSentences.ansDeg()}`}
        testCorrect={[(90 - angle).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAv',
  description: 'aAv',
  keywords: ['Length', 'Perimeter', 'Calculate'],
  schema: z.object({
    width: z.number().int().min(4).max(10),
    length: z.number().int().min(3).max(9),
    numberOfShapes: z.number().int().min(2).max(5),
    color: quadrilateralColorsSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 10);
    const length = randomIntegerInclusive(3, width - 1);
    const color = getRandomFromArray(['Purple', 'Blue', 'Green'] as const);
    const numberOfShapes = randomIntegerInclusive(2, 5);

    return { width, length, color, numberOfShapes };
  },
  Component: props => {
    const {
      question: { width, length, color, numberOfShapes },
      translate
    } = props;

    const perimeter = 2 * length + 2 * (width * numberOfShapes);
    const coloredShape = getQuadrilateralSvgName('Rectangle', color);
    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.instructions.rectangleMeasuresXcmByYcm(
          width.toLocaleString(),
          length.toLocaleString()
        )}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-between' }}>
            <View style={{ marginTop: 20, alignSelf: 'center' }}>
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.3, width: dimens.width * 0.5 }}
                shape={'Rectangle2Arrows'}
                color={color}
                labels={[
                  '',
                  '',
                  translate.units.numberOfCm(width),
                  translate.units.numberOfCm(length)
                ]}
              />
            </View>
            <TextStructure
              sentence={translate.instructions.thisCompoundShapeIsMadeWorkOutPerimeterOfThisShape()}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {countRange(numberOfShapes).map(x => (
                <AssetSvg
                  key={x}
                  name={coloredShape}
                  height={dimens.height * 0.3}
                  width={numberOfShapes > 3 ? (dimens.width - 100) / numberOfShapes : undefined}
                />
              ))}
            </View>
          </View>
        )}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        testCorrect={[perimeter.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAw',
  description: 'aAw',
  keywords: ['Length', 'Perimeter', 'Calculate'],
  schema: z.object({
    width: z.number().int().min(4).max(10),
    length: z.number().int().min(3).max(9),
    numberOfShapes: z.number().int().min(2).max(5),
    color: quadrilateralColorsSchema,
    rotations: z.array(z.enum(['0', '90']))
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 10);
    const length = randomIntegerInclusive(3, width - 1);
    const color = getRandomFromArray(['Purple', 'Blue', 'Green'] as const);
    const numberOfShapes = randomIntegerInclusive(2, 5);

    const rotations: ('0' | '90')[] = shuffle([
      '0',
      '90',
      ...countRange(numberOfShapes - 2).map(_ => (getRandomBoolean() ? '90' : '0'))
    ]);

    return { width, length, color, numberOfShapes, rotations };
  },
  Component: props => {
    const {
      question: { width, length, color, numberOfShapes, rotations },
      translate
    } = props;

    const heightDiff = width - length;

    const perimeter = countRange(numberOfShapes).reduce((acc, curr) => {
      let additions = 0;

      // Get the perimeter for the 3 sides of the first and last rect
      if (curr === 0 || curr === numberOfShapes - 1) {
        additions += rotations[curr] === '0' ? 2 * width + length : 2 * length + width;
      } else {
        // Get the perimeter for the 2 sides of the middle rects
        additions += rotations[curr] === '0' ? 2 * width : 2 * length;
      }
      // If the next rect is a different rotation, add the difference of height
      if (rotations[curr + 1] && rotations[curr] !== rotations[curr + 1]) additions += heightDiff;
      return acc + additions;
    }, 0);

    const coloredShape = getQuadrilateralSvgName('Rectangle', color);

    const getCompoundShape = (dimens: Dimens) => {
      const { actualHeight, actualWidth } = getActualDimens(coloredShape, {
        width: dimens.height * 0.3,
        height: dimens.height * 0.3
      });

      return countRange(numberOfShapes).map(x => (
        <View
          key={x}
          style={{
            height: rotations[x] === '0' ? actualHeight : actualWidth,
            width: rotations[x] === '0' ? actualWidth : actualHeight
          }}
        >
          <View style={{ transform: [{ rotate: `${rotations[x]}deg` }] }}>
            <AssetSvg
              key={x}
              name={coloredShape}
              width={actualWidth}
              // Override tailwind default style on web.
              // See https://white-rose-education.monday.com/boards/1527384024/pulses/1530379277
              style={{ maxWidth: actualWidth }}
            />
          </View>
        </View>
      ));
    };

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.instructions.rectangleMeasuresXcmByYcm(
          width.toLocaleString(),
          length.toLocaleString()
        )}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-between' }}>
            <View style={{ marginTop: 20, alignSelf: 'center' }}>
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.3, width: dimens.width * 0.5 }}
                shape={'Rectangle2Arrows'}
                color={color}
                labels={[
                  '',
                  '',
                  translate.units.numberOfCm(width),
                  translate.units.numberOfCm(length)
                ]}
              />
            </View>
            <TextStructure
              sentence={translate.instructions.thisCompoundShapeIsMadeWorkOutPerimeterOfThisShape()}
            />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              {getCompoundShape(dimens)}
            </View>
          </View>
        )}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        testCorrect={[perimeter.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aAw2',
  description: 'aAw',
  keywords: ['Length', 'Perimeter', 'Calculate'],
  schema: z.object({
    width: z.number().int().min(4).max(10),
    length: z.number().int().min(3).max(9),
    numberOfShapes: z.number().int().min(2).max(5),
    color: quadrilateralColorsSchema,
    rotations: z.array(z.enum(['0', '90'])),
    character: nameSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 10);
    const length = randomIntegerInclusive(3, width - 1);
    const color = getRandomFromArray(['Purple', 'Blue', 'Green'] as const);
    const numberOfShapes = randomIntegerInclusive(2, 5);

    const rotations: ('0' | '90')[] = shuffle([
      '0',
      '90',
      ...countRange(numberOfShapes - 2).map(_ => (getRandomBoolean() ? '90' : '0'))
    ]);

    const character = getRandomName();

    return { width, length, color, numberOfShapes, rotations, character };
  },
  Component: props => {
    const {
      question: { width, length, color, numberOfShapes, rotations, character },
      translate
    } = props;

    const heightDiff = width - length;

    const perimeter = countRange(numberOfShapes).reduce((acc, curr) => {
      let additions = 0;

      // Get the perimeter for the 3 sides of the first and last rect
      if (curr === 0 || curr === numberOfShapes - 1) {
        additions += rotations[curr] === '0' ? 2 * width + length : 2 * length + width;
      } else {
        // Get the perimeter for the 2 sides of the middle rects
        additions += rotations[curr] === '0' ? 2 * width : 2 * length;
      }
      // If the next rect is a different rotation, add the difference of height
      if (rotations[curr + 1] && rotations[curr] !== rotations[curr + 1]) additions += heightDiff;
      return acc + additions;
    }, 0);

    const getCompoundShape = (dimens: Dimens) => {
      const coloredShape = getQuadrilateralSvgName('Rectangle', color);

      const { actualHeight, actualWidth } = getActualDimens(coloredShape, {
        width: dimens.height * 0.3,
        height: dimens.height * 0.3
      });

      const fill = (() => {
        switch (color) {
          case 'Blue':
            return '#01abe6';
          case 'Green':
            return '#72bf44';
          default:
            return '#6d4099';
        }
      })();

      return (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: 20
          }}
        >
          {countRange(numberOfShapes).map(x => (
            <View
              key={x}
              style={{
                width: rotations[x] === '0' ? actualWidth : actualHeight,
                height: rotations[x] === '0' ? actualHeight : actualWidth,
                backgroundColor: fill,
                borderWidth: 1
              }}
            />
          ))}
        </View>
      );
    };

    const pronoun =
      getGenderFromName(character) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    return (
      <QF1ContentAndSentence
        questionHeight={1200}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.charHasSeveralIdenticalRectangles(character)}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-between' }}>
            <View style={{ marginBottom: 28, alignSelf: 'center' }}>
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.3, width: dimens.width * 0.5 }}
                shape={'Rectangle2Arrows'}
                color={color}
                labels={[
                  '',
                  '',
                  translate.units.numberOfCm(width),
                  translate.units.numberOfCm(length)
                ]}
              />
            </View>
            <TextStructure
              sentence={translate.instructions.xJoinsSeveralOfThemToMakeThisCompoundShape(pronoun)}
            />
            {getCompoundShape(dimens)}
            <TextStructure sentence={translate.instructions.workOutThePerimeterOfThisShape()} />
          </View>
        )}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        testCorrect={[perimeter.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAx',
  description: 'aAx',
  keywords: ['Length', 'Area', 'Calculate'],
  schema: z.object({
    width: z.number().int().min(4).max(10),
    length: z.number().int().min(3).max(9),
    numberOfShapes: z.number().int().min(2).max(5),
    color: quadrilateralColorsSchema,
    rotations: z.array(z.enum(['0', '90']))
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 10);
    const length = randomIntegerInclusive(3, width - 1);
    const color = getRandomFromArray(['Purple', 'Blue', 'Green'] as const);
    const numberOfShapes = randomIntegerInclusive(2, 5);

    const rotations: ('0' | '90')[] = shuffle([
      '0',
      '90',
      ...countRange(numberOfShapes - 2).map(_ => (getRandomBoolean() ? '90' : '0'))
    ]);

    return { width, length, color, numberOfShapes, rotations };
  },
  Component: props => {
    const {
      question: { width, length, color, numberOfShapes, rotations },
      translate
    } = props;

    const area = length * width * numberOfShapes;

    const coloredShape = getQuadrilateralSvgName('Rectangle', color);

    const getCompoundShape = (dimens: Dimens) => {
      const { actualHeight, actualWidth } = getActualDimens(coloredShape, {
        width: dimens.height * 0.3,
        height: dimens.height * 0.3
      });

      return countRange(numberOfShapes).map(x => (
        <View
          key={x}
          style={{
            height: rotations[x] === '0' ? actualHeight : actualWidth,
            width: rotations[x] === '0' ? actualWidth : actualHeight
          }}
        >
          <View style={{ transform: [{ rotate: `${rotations[x]}deg` }] }}>
            <AssetSvg
              key={x}
              name={coloredShape}
              width={actualWidth}
              // Override tailwind default style on web.
              // See https://white-rose-education.monday.com/boards/1527384024/pulses/1530379277
              style={{ maxWidth: actualWidth }}
            />
          </View>
        </View>
      ));
    };

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.instructions.rectangleMeasuresXcmByYcm(
          width.toLocaleString(),
          length.toLocaleString()
        )}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-between' }}>
            <View style={{ marginTop: 20, alignSelf: 'center' }}>
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.3, width: dimens.width * 0.5 }}
                shape={'Rectangle2Arrows'}
                color={color}
                labels={[
                  '',
                  '',
                  translate.units.numberOfCm(width),
                  translate.units.numberOfCm(length)
                ]}
              />
            </View>
            <TextStructure
              sentence={translate.instructions.thisCompoundShapeIsMadeWorkOutArearOfThisShape()}
            />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              {getCompoundShape(dimens)}
            </View>
          </View>
        )}
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        testCorrect={[area.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aAx2',
  description: 'aAx',
  keywords: ['Length', 'Area', 'Calculate'],
  schema: z.object({
    width: z.number().int().min(4).max(10),
    length: z.number().int().min(3).max(9),
    numberOfShapes: z.number().int().min(2).max(5),
    color: quadrilateralColorsSchema,
    rotations: z.array(z.enum(['0', '90'])),
    character: nameSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 10);
    const length = randomIntegerInclusive(3, width - 1);
    const color = getRandomFromArray(['Purple', 'Blue', 'Green'] as const);
    const numberOfShapes = randomIntegerInclusive(2, 5);

    const rotations: ('0' | '90')[] = shuffle([
      '0',
      '90',
      ...countRange(numberOfShapes - 2).map(_ => (getRandomBoolean() ? '90' : '0'))
    ]);

    const character = getRandomName();

    return { width, length, color, numberOfShapes, rotations, character };
  },
  Component: props => {
    const {
      question: { width, length, color, numberOfShapes, rotations, character },
      translate
    } = props;

    const area = length * width * numberOfShapes;

    const getCompoundShape = (dimens: Dimens) => {
      const coloredShape = getQuadrilateralSvgName('Rectangle', color);

      const { actualHeight, actualWidth } = getActualDimens(coloredShape, {
        width: dimens.height * 0.3,
        height: dimens.height * 0.3
      });

      const fill = (() => {
        switch (color) {
          case 'Blue':
            return '#01abe6';
          case 'Green':
            return '#72bf44';
          default:
            return '#6d4099';
        }
      })();

      return (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: 20
          }}
        >
          {countRange(numberOfShapes).map(x => (
            <View
              key={x}
              style={{
                width: rotations[x] === '0' ? actualWidth : actualHeight,
                height: rotations[x] === '0' ? actualHeight : actualWidth,
                backgroundColor: fill,
                borderWidth: 1
              }}
            />
          ))}
        </View>
      );
    };

    const pronoun =
      getGenderFromName(character) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    return (
      <QF1ContentAndSentence
        questionHeight={1200}
        title={translate.instructions.charHasSeveralIdenticalRectangles(character)}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-between' }}>
            <View style={{ marginBottom: 28, alignSelf: 'center' }}>
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.3, width: dimens.width * 0.5 }}
                shape={'Rectangle2Arrows'}
                color={color}
                labels={[
                  '',
                  '',
                  translate.units.numberOfCm(width),
                  translate.units.numberOfCm(length)
                ]}
              />
            </View>
            <TextStructure
              sentence={translate.instructions.xJoinsSeveralOfThemToMakeThisCompoundShape(pronoun)}
            />
            {getCompoundShape(dimens)}
            <TextStructure sentence={translate.instructions.workOutTheAreaOfThisShape()} />
          </View>
        )}
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        testCorrect={[area.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAy',
  description: 'aAy',
  keywords: ['Angles', 'Triangles', 'Calculate'],
  schema: z.object({
    outerAngleA: z.number().int().min(91).max(149),
    outerAngleB: z.number().int().min(91).max(149)
  }),
  simpleGenerator: () => {
    const outerAngleA = randomIntegerInclusive(91, 149);
    const outerAngleB = randomIntegerInclusive(91, 149, { constraint: x => x !== outerAngleA });

    return { outerAngleA, outerAngleB };
  },
  Component: props => {
    const {
      question: { outerAngleA, outerAngleB },
      translate
    } = props;
    const outerAngleC = 360 - outerAngleA - outerAngleB;
    const angleLabels = [
      ALGEBRAIC_A,
      translate.units.numberOfDegrees(outerAngleA),
      ALGEBRAIC_B,
      translate.units.numberOfDegrees(outerAngleB),
      ALGEBRAIC_C,
      translate.units.numberOfDegrees(outerAngleC)
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutAnglesInTriangle()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={'Triangle1_line_extension_3_angles'}
            angleLabels={angleLabels}
          />
        )}
        sentences={[
          `${ALGEBRAIC_A} = ${translate.answerSentences.ansDeg()}`,
          `${ALGEBRAIC_B} = ${translate.answerSentences.ansDeg()}`,
          `${ALGEBRAIC_C} = ${translate.answerSentences.ansDeg()}`
        ]}
        testCorrect={[
          [(180 - outerAngleA).toString()],
          [(180 - outerAngleB).toString()],
          [(180 - outerAngleC).toString()]
        ]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        style={{ flexDirection: 'row' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAz',
  description: 'aAz',
  keywords: ['Perimeter', 'Length', 'Calculate'],
  schema: z.object({
    sideLength: z.number().int().min(6).max(12),
    xLength: z.number().int().min(3).max(10)
  }),
  simpleGenerator: () => {
    const sideLength = randomIntegerInclusive(6, 12);
    const xLength = randomIntegerInclusive(3, Math.min(10, sideLength - 1), {
      constraint: x => x * 2 > sideLength
    });

    return { sideLength, xLength };
  },
  Component: props => {
    const {
      question: { sideLength, xLength },
      translate
    } = props;
    const perimeter = sideLength * 2 + xLength * 3;
    const labels = [
      `${ALGEBRAIC_X}`,
      translate.units.numberOfCm(sideLength),
      ALGEBRAIC_Y,
      translate.units.numberOfCm(sideLength)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.perimeterOfTrapeziumIsXcmSideIsTwiceLengthOfSideCalculateTheLengthOfSide(
          perimeter.toLocaleString()
        )}
        Content={({ dimens }) => (
          <LabelledQuadrilateral dimens={dimens} shape={'Trapezium4Arrows'} labels={labels} />
        )}
        sentence={`${ALGEBRAIC_Y} = ${translate.answerSentences.ansCm()}`}
        testCorrect={[(xLength * 2).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LengthsAndAnglesInShapes',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question3, Question4],
  unpublishedQuestionTypes: []
});
export default SmallStep;
