import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { View } from 'react-native';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baH',
  description: 'baH',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numA: z.number().int().min(1).max(8),
    numB: z.number().int().min(1).max(8),
    colour: z.enum(['blue', 'green', 'orange', 'purple', 'red'])
  }),
  simpleGenerator: () => {
    let numA = 5;
    let numB = 5;
    const lessMoreSame = getRandomFromArray(['more', 'less', 'same'] as const);
    switch (lessMoreSame) {
      case 'less':
        numA = randomIntegerInclusive(1, 7);
        numB = randomIntegerInclusive(numA + 1, 8);
        break;
      case 'more':
        numA = randomIntegerInclusive(2, 8);
        numB = randomIntegerInclusive(1, numA - 1);
        break;
      case 'same':
        numA = randomIntegerInclusive(1, 8);
        numB = numA;
        break;
    }

    const colour = getRandomFromArray(['blue', 'green', 'orange', 'purple', 'red'] as const);

    return { numA, numB, colour };
  },

  Component: ({ question: { numA, numB, colour }, translate }) => {
    const correctInequality = lessThanGreaterThanOrEqualTo(numA, numB);

    const multiLinkShapes = [
      'Multi_link_cubes/column_1' as const,
      'Multi_link_cubes/column_2' as const,
      'Multi_link_cubes/column_3' as const,
      'Multi_link_cubes/column_4' as const,
      'Multi_link_cubes/column_5' as const,
      'Multi_link_cubes/column_6' as const,
      'Multi_link_cubes/column_7' as const,
      'Multi_link_cubes/column_8' as const
    ];

    const shapeA = `${multiLinkShapes[numA - 1]}_${colour}` as const;
    const shapeB = `${multiLinkShapes[numB - 1]}_${colour}` as const;

    return (
      <QF36ContentAndSentenceDrag<'<' | '>' | '='>
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheWordsToCompleteTheSentence()}
        itemVariant="rectangle"
        items={[
          { value: '>', component: translate.ks1MiscStrings.greaterThan() },
          { value: '=', component: translate.ks1MiscStrings.equalTo() },
          { value: '<', component: translate.ks1MiscStrings.lessThan() }
        ]}
        moveOrCopy="move"
        actionPanelVariant="endWide"
        pdfLayout="itemsBottom"
        pdfItemVariant="rectangle"
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'flex-end',
              width: dimens.width
            }}
          >
            <AssetSvg
              name={shapeA}
              width={Math.max(numA, numB) >= 6 ? dimens.width / 14 : dimens.width / 10}
            />

            <AssetSvg
              name={shapeB}
              width={Math.max(numA, numB) >= 6 ? dimens.width / 14 : dimens.width / 10}
            />
          </View>
        )}
        sentence={translate.answerSentences.aIsAnswerThanB(numA, numB)}
        testCorrect={[correctInequality]}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question2 = newQuestionContent({
  uid: 'baI',
  description: 'baH',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numA: z.number().int().min(1).max(8),
    numB: z.number().int().min(1).max(8),
    colour: z.enum(['blue', 'green', 'orange', 'purple', 'red'])
  }),
  simpleGenerator: () => {
    let numA = 5;
    let numB = 5;
    const lessMoreSame = getRandomFromArray(['>', '<', '='] as const);
    switch (lessMoreSame) {
      case '>':
        numA = randomIntegerInclusive(1, 7);
        numB = randomIntegerInclusive(numA + 1, 8);
        break;
      case '<':
        numA = randomIntegerInclusive(2, 8);
        numB = randomIntegerInclusive(1, numA - 1);
        break;
      case '=':
        numA = randomIntegerInclusive(1, 8);
        numB = numA;
        break;
    }

    const colour = getRandomFromArray(['blue', 'green', 'orange', 'purple', 'red'] as const);

    return { numA, numB, colour };
  },

  Component: ({ question: { numA, numB, colour }, translate }) => {
    const multiLinkShapes = [
      'Multi_link_cubes/column_1' as const,
      'Multi_link_cubes/column_2' as const,
      'Multi_link_cubes/column_3' as const,
      'Multi_link_cubes/column_4' as const,
      'Multi_link_cubes/column_5' as const,
      'Multi_link_cubes/column_6' as const,
      'Multi_link_cubes/column_7' as const,
      'Multi_link_cubes/column_8' as const
    ];

    const shapeA = `${multiLinkShapes[numA - 1]}_${colour}` as const;
    const shapeB = `${multiLinkShapes[numB - 1]}_${colour}` as const;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        itemVariant="square"
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        sentenceStyle={{ width: '100%', justifyContent: 'space-evenly' }}
        pdfLayout="itemsHidden"
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'flex-end',
              width: dimens.width,
              height: dimens.height - 1000
            }}
          >
            <AssetSvg
              name={shapeA}
              width={Math.max(numA, numB) >= 6 ? dimens.width / 14 : dimens.width / 10}
            />
            <View
              style={{
                width: '10%'
              }}
            />
            <AssetSvg
              name={shapeB}
              width={Math.max(numA, numB) >= 6 ? dimens.width / 14 : dimens.width / 10}
            />
          </View>
        )}
        sentence={`${numA} <ans/> ${numB}`}
        testCorrect={[lessThanGreaterThanOrEqualTo(numA, numB)]}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LessThanGreaterThanEqualTo',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
