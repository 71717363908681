import { StyleSheet, TouchableOpacity } from 'react-native';
import TenFrameLayout, { renderTenFrameCells } from './TenFrameLayout';
import { type SetState, projectSetState } from '../../../../utils/react';
import { withStateHOC } from '../../../../stateTree';
import { type ComponentProps } from 'react';

type Props = {
  /** Default: []. Any elements left undefined or absent are treated as false. */
  cellsCrossed?: boolean[];
  setCellsCrossed: SetState<boolean[]>;
} & Pick<
  ComponentProps<typeof TenFrameLayout>,
  'size' | 'items' | 'itemOrdering' | 'orientation' | 'colorBlindMode'
>;

/** Ten frame, but tapping on cell toggles it being crossed out or not. */
export default function TenFrameTapToCrossOut({
  cellsCrossed = [],
  setCellsCrossed,
  items: itemsProp,
  ...props
}: Props) {
  const items = renderTenFrameCells({
    size: props.size,
    items: itemsProp,
    crossedOutCells: (index: number) => cellsCrossed[index]
  });

  return (
    <TenFrameLayout
      items={index =>
        items[index] === undefined ? undefined : (
          <TouchableOpacity
            style={StyleSheet.absoluteFill}
            onPress={() => projectSetState(setCellsCrossed, index)(old => !old)}
          >
            {items[index]}
          </TouchableOpacity>
        )
      }
      {...props}
    />
  );
}

export const TenFrameTapToCrossOutWithState = withStateHOC(TenFrameTapToCrossOut, {
  stateProp: 'cellsCrossed',
  setStateProp: 'setCellsCrossed',
  defaults: { defaultState: [] }
});
