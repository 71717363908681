import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import { displayMoney, totalPenceToPoundsAndPence } from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfr',
  description: 'bfr',
  keywords: ['Count', 'Multiplication', 'Coins'],
  schema: z.object({
    coinValue: numberEnum([1, 2, 5, 10]),
    totalNumberOfCoins: z.number().int().min(2).max(12),
    randomA: z.number().int().min(1).max(1000),
    randomB: z.number().int().min(1).max(1000),
    showRandomArrangement: z.boolean()
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const values = [1, 2, 5, 10] as const;

    const coinValue = getRandomFromArray(values);

    const totalNumberOfCoins = randomIntegerInclusive(2, coinValue === 10 ? 9 : 12);

    const showRandomArrangement = getRandomFromArrayWithWeights([false, true], [3, 1]);

    const randomA = randomIntegerInclusive(1, 1000);
    const randomB = randomIntegerInclusive(1, 1000);

    return {
      coinValue,
      totalNumberOfCoins,
      showRandomArrangement,
      randomA,
      randomB
    };
  },
  Component: props => {
    const {
      question: { coinValue, totalNumberOfCoins, showRandomArrangement, randomA, randomB },
      translate,
      displayMode
    } = props;

    const money = countRange(totalNumberOfCoins).map(() => coinValue);

    const denominations = shuffle(
      money.map(number => totalPenceToPoundsAndPence(number)[0]),
      { random: seededRandom(props.question) }
    );

    const coinSvgs = displayMoney(
      denominations,
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    const arrangement = getRandomBooleanArray(
      4,
      4,
      denominations.length,
      seededRandom({ randomA, randomB })
    );

    let denominationIndex = 0;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoneyIsThere()}
        questionHeight={1200}
        Content={({ dimens }) =>
          showRandomArrangement ? (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              {arrangement.map((row, rowIndex) => (
                <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                  {row.map((cell, colIndex) => (
                    <View
                      key={`cell-${rowIndex}-${colIndex}`}
                      style={{
                        width: dimens.width / 4,
                        height: dimens.height / 4,
                        padding: 8
                      }}
                    >
                      {cell ? coinSvgs[denominationIndex++] : null}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ) : (
            <View
              style={{
                flexDirection: 'row',
                gap: 30,
                flexWrap: 'wrap',
                alignItems: 'center'
              }}
            >
              {displayMoney(
                denominations,
                displayMode === 'digital' ? 90 : 120,
                displayMode === 'digital' ? 90 : 120,
                true
              )}
            </View>
          )
        }
        sentence={translate.ks1AnswerSentences.ansPence()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(coinValue * totalNumberOfCoins).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bfs',
  description: 'bfs',
  keywords: ['Count', 'Multiplication', 'Coins'],
  schema: z.object({
    combinationOfCoins: z.array(z.number())
  }),
  simpleGenerator: () => {
    const combination = getRandomFromArray([0, 1, 2, 3, 4, 5]);

    const numberOfTens = randomIntegerInclusive(1, combination === 0 ? 4 : 5);
    const numberOfFives = randomIntegerInclusive(1, 5);
    const numberOfTwos = randomIntegerInclusive(1, 4);
    const numberOfOnes = randomIntegerInclusive(1, 9);

    const combinationA = [
      50,
      ...getRandomFromArray([
        countRange(numberOfTens).map(() => 10),
        countRange(numberOfFives).map(() => 5),
        countRange(numberOfTwos).map(() => 2),
        countRange(numberOfOnes).map(() => 1)
      ])
    ];

    const combinationB = [
      20,
      ...getRandomFromArray([
        countRange(numberOfTens).map(() => 10),
        countRange(numberOfFives).map(() => 5),
        countRange(numberOfTwos).map(() => 2),
        countRange(numberOfOnes).map(() => 1)
      ])
    ];

    const combinationC = [
      ...countRange(numberOfTens).map(() => 10),
      ...getRandomFromArray([
        countRange(numberOfFives).map(() => 5),
        countRange(numberOfTwos).map(() => 2),
        countRange(numberOfOnes).map(() => 1)
      ])
    ];
    const combinationD = [
      ...countRange(randomIntegerInclusive(2, 6, { constraint: x => x % 2 === 0 })).map(() => 5),
      ...getRandomFromArray([
        countRange(numberOfTwos).map(() => 2),
        countRange(numberOfOnes).map(() => 1)
      ])
    ];

    const combinationE = [
      ...countRange(randomIntegerInclusive(1, 5, { constraint: x => x % 2 !== 0 })).map(() => 2),
      ...countRange(numberOfTwos).map(() => 1)
    ];

    const combinationF = [
      ...countRange(randomIntegerInclusive(1, 6)).map(() => 2),
      ...countRange(numberOfOnes).map(() => 1)
    ];

    const combinationOfCoins = [
      combinationA,
      combinationB,
      combinationC,
      combinationD,
      combinationE,
      combinationF
    ][combination];

    return {
      combinationOfCoins
    };
  },
  Component: props => {
    const {
      question: { combinationOfCoins },
      translate,
      displayMode
    } = props;

    const denominations = combinationOfCoins.map(number => totalPenceToPoundsAndPence(number)[0]);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoneyIsThere()}
        Content={
          <View
            style={{
              flexDirection: 'row',
              gap: 30,
              flexWrap: 'wrap',
              alignItems: 'center'
            }}
          >
            {displayMoney(
              denominations,
              displayMode === 'digital' ? 90 : 120,
              displayMode === 'digital' ? 90 : 120,
              true
            )}
          </View>
        }
        sentence={translate.ks1AnswerSentences.ansPence()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[sumNumberArray(combinationOfCoins).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bft',
  description: 'bft',
  keywords: ['Count', 'Coins', 'Greater than', 'Less than', 'Equal to', 'Compare'],
  schema: z.object({
    optionAValue: numberEnum([1, 2, 5, 10, 20, 50]),
    optionBValue: numberEnum([1, 2, 5, 10, 20, 50]),
    optionAAmount: z.number().int().min(1).max(12),
    optionBAmount: z.number().int().min(1).max(12)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [optionAValue, optionBValue] = getRandomSubArrayFromArray(
      [1, 2, 5, 10, 20, 50] as const,
      2
    );

    const getOptionAmount = (optionValue: 1 | 2 | 50 | 10 | 5 | 20) => {
      switch (optionValue) {
        case 1:
          return randomIntegerInclusive(1, 12);
        case 2:
          return randomIntegerInclusive(1, 12);
        case 5:
          return randomIntegerInclusive(1, 10);
        case 10:
          return randomIntegerInclusive(1, 8);
        default:
          return 1;
      }
    };

    const optionAAmount = getOptionAmount(optionAValue);
    const optionBAmount = getOptionAmount(optionBValue);

    return { optionAAmount, optionBAmount, optionAValue, optionBValue };
  },
  Component: ({
    question: { optionAAmount, optionBAmount, optionAValue, optionBValue },
    translate,
    displayMode
  }) => {
    const coinSvgs = displayMoney(
      [
        ...countRange(optionAAmount).map(() => totalPenceToPoundsAndPence(optionAValue)[0]),
        ...countRange(optionBAmount).map(() => totalPenceToPoundsAndPence(optionBValue)[0])
      ],
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    const lhsSvgs = coinSvgs.filter((_, index) => index <= optionAAmount - 1);
    const rhsSvgs = coinSvgs.filter((_, index) => index > optionAAmount - 1);

    const lhsComponent = (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: displayMode === 'digital' ? 300 : 600,
          gap: 10
        }}
      >
        {lhsSvgs}
      </View>
    );

    const rhsComponent = (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: displayMode === 'digital' ? 300 : 600,
          gap: 10
        }}
      >
        {rhsSvgs}
      </View>
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        questionHeight={1000}
        statements={[
          {
            lhsComponent,
            rhsComponent,
            correctAnswer: lessThanGreaterThanOrEqualTo(
              optionAValue * optionAAmount,
              optionBValue * optionBAmount
            )
          }
        ]}
        statementStyle={{ justifyContent: 'center', gap: 16 }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountInCoins',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
