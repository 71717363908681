import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  BarModelColorsKey,
  barModelColors,
  barModelColorsArray,
  colors
} from '../../../../theme/colors';
import { BarModel } from '../../../../components/question/representations/BarModel';
import {
  arrayHasNoDuplicates,
  filledArray,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import TextStructure from '../../../../components/molecules/TextStructure';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import Scales, {
  defaultWeightImages
} from '../../../../components/question/representations/Scales';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIe',
  description: 'aIe',
  keywords: [
    'Unit fraction',
    'Non-unit fraction',
    'Scales',
    'Number line',
    'Equal parts',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(5),
      denominator: z.number().int().min(3).max(6)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 6);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { numerator, denominator };
  },
  Component: props => {
    const {
      question: { numerator, denominator },
      translate,
      displayMode
    } = props;

    const numbers = [filledArray(1, denominator)];

    const strings = [filledArray('', denominator)];

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const customColorMap = [
      ...filledArray(barModelColors[numeratorColor as BarModelColorsKey], numerator),
      ...filledArray('white', denominator - numerator)
    ];

    const tickValues = [0, ...range(1, denominator - 1).map(() => null), 1];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionOfTheShapeIsShaded()}
        sentence={`<frac nAns='' dAns=''/>`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        fractionContainerStyle={{ height: 96 }}
        testCorrect={userAnswer => compareFractions(userAnswer, [numerator, denominator])}
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <View style={{ right: displayMode === 'digital' ? undefined : 50 }}>
              <BarModel
                total={denominator}
                dimens={{
                  // NumberLines don't fully use up the entire width available, so BarModel needs less width to align correctly:
                  width: dimens.width - (displayMode === 'digital' ? 36 : 136),
                  height: dimens.height / 2
                }}
                numbers={numbers}
                strings={strings}
                cellColors={[customColorMap]}
                pdfShadingOverride
              />
            </View>
            <NumberLine
              tickValues={tickValues}
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
            />
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aIf',
  description: 'aIf',
  keywords: [
    'Unit fraction',
    'Non-unit fraction',
    'Numerator',
    'Denominator',
    'Equal parts',
    'Length',
    'Metre stick',
    'Metre'
  ],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(4),
      denominator: numberEnum([2, 4, 5]),
      ribbonLeftOrRight: z.enum(['left', 'right'])
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 4, 5] as const);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    const ribbonLeftOrRight = getRandomFromArray(['left', 'right'] as const);

    return { numerator, denominator, ribbonLeftOrRight };
  },
  Component: props => {
    const {
      question: { numerator, denominator, ribbonLeftOrRight },
      translate
    } = props;

    const ribbonPath = getRandomFromArray(['RibbonGreenStriped', 'RibbonRedStriped'], {
      random: seededRandom(props.question)
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheRibbonAsAFractionOfAMetre()}
        pdfDirection="column"
        sentence={`<frac nAns='' dAns=''/> ${translate.units.m()}`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        fractionContainerStyle={{ height: 96 }}
        testCorrect={userAnswer => compareFractions(userAnswer, [numerator, denominator])}
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center' }]}>
            <View
              style={{
                alignSelf: ribbonLeftOrRight === 'left' ? 'flex-start' : 'flex-end',
                marginBottom: 16
              }}
            >
              <AssetSvg
                name={ribbonPath as SvgName}
                width={(dimens.width / denominator) * numerator}
              />
            </View>
            <View style={{ flexDirection: 'row' }}>
              {range(1, denominator).map(num => (
                <View
                  key={num}
                  style={{
                    width: dimens.width / denominator,
                    height: dimens.height / 3,
                    backgroundColor: num % 2 === 0 ? '#e30613' : '#ffed00',
                    borderWidth: 4,
                    borderEndWidth: num === denominator ? 4 : 0,
                    borderColor: 'black',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                  }}
                >
                  <Text
                    variant={'WRN700'}
                    style={{ color: num % 2 === 0 ? colors.white : colors.black, marginRight: 16 }}
                  >
                    {num === denominator ? translate.units.numberOfM(1) : ''}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aIg',
  description: 'aIg',
  keywords: ['Capacity', 'Unit fraction', 'Non-unit fraction', 'Litre', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(2).max(4),
    numerator: z.number().int().min(1).max(3),
    incorrectDenominator: z.number().int().min(2).max(4),
    incorrectNumerator: z.number().int().min(1).max(3),
    offset: numberEnum([-1, 1])
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const [denominator, incorrectDenominator] = randomUniqueIntegersInclusive(2, 4, 2);
        const numerator = randomIntegerInclusive(1, denominator - 1);
        const incorrectNumerator = randomIntegerInclusive(1, incorrectDenominator - 1);
        const offset =
          numerator === 1
            ? 1
            : numerator === denominator - 1
            ? -1
            : getRandomFromArray([-1, 1] as const);

        return {
          numerator,
          incorrectNumerator,
          incorrectDenominator,
          denominator,
          offset
        };
      },
      val =>
        arrayHasNoDuplicates([
          fractionToDecimal(val.numerator, val.denominator),
          fractionToDecimal(val.numerator + val.offset, val.denominator),
          fractionToDecimal(val.incorrectNumerator, val.incorrectDenominator)
        ])
    ),
  Component: props => {
    const {
      question: { numerator, incorrectNumerator, incorrectDenominator, denominator, offset },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [
        {
          numerator,
          denominator,
          isCorrect: true,
          value: fractionToDecimal(numerator, denominator)
        },
        {
          numerator: numerator + offset,
          denominator,
          isCorrect: false,
          value: fractionToDecimal(numerator + offset, denominator)
        },
        {
          numerator: incorrectNumerator,
          denominator: incorrectDenominator,
          isCorrect: false,
          value: fractionToDecimal(incorrectNumerator, incorrectDenominator)
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectJugShowsXOfWater(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        pdfTitle={translate.instructions.circleJugShowsXOfWater(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        testCorrect={items.filter(item => item.isCorrect).map(item => item.value)}
        numItems={3}
        itemLayout="row"
        renderItems={items.map(({ value, numerator, denominator }) => ({
          value,
          component: (
            <JugWithLiquid
              dimens={
                displayMode === 'digital'
                  ? {
                      width: 300,
                      height: 300
                    }
                  : {
                      width: 400,
                      height: 400
                    }
              }
              jugCapacity={1000}
              tickValue={1000 / denominator}
              liquidAmount={(1000 / denominator) * numerator}
              displayMajorLabels="top"
            />
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aIh',
  description: 'aIh',
  keywords: [
    'Mass',
    'Scale',
    'Unit fraction',
    'Non-unit fraction',
    'Numerator',
    'Denominator',
    'Kilogram'
  ],
  schema: z
    .object({
      scaleIntervals: z.number().int().min(2).max(4),
      image: z.enum(defaultWeightImages),
      weightInterval: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.weightInterval < val.scaleIntervals,
      'weightInterval must be less than scaleIntervals'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleIntervals = randomIntegerInclusive(2, 4);
    const weightInterval = randomIntegerInclusive(1, scaleIntervals - 1);
    const image = getRandomFromArray(defaultWeightImages);
    return { weightInterval, image, scaleIntervals };
  },
  Component: ({ question: { weightInterval, image, scaleIntervals }, translate }) => {
    const scaleImage: SvgName =
      scaleIntervals === 2
        ? 'Scales/kg_scale_2'
        : scaleIntervals === 3
        ? 'Scales/kg_scale_3'
        : 'Scales/kg_scale_4';
    return (
      <QF1ContentAndSentence
        sentence={`<frac nAns='' dAns='' /> ${translate.units.kg()}`}
        title={translate.instructions.theScaleMesauresUpTo1kgWhatIsFractionShown()}
        testCorrect={userAnswer => compareFractions(userAnswer, [weightInterval, scaleIntervals])}
        inputMaxCharacters={1}
        sentenceStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [weightInterval.toLocaleString(), scaleIntervals.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={(1000 / scaleIntervals) * weightInterval}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName={scaleImage}
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aIh2',
  description: 'aIh',
  keywords: [
    'Mass',
    'Scale',
    'Unit fraction',
    'Non-unit fraction',
    'Numerator',
    'Denominator',
    'Kilogram'
  ],
  schema: z
    .object({
      scaleIntervals: z.number().int().min(2).max(4),
      image: z.enum(defaultWeightImages),
      weightInterval: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.weightInterval < val.scaleIntervals,
      'weightInterval must be less than scaleIntervals'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleIntervals = randomIntegerInclusive(2, 4);
    const weightInterval = randomIntegerInclusive(1, scaleIntervals - 1);
    const image = getRandomFromArray(defaultWeightImages);
    return { weightInterval, image, scaleIntervals };
  },
  Component: props => {
    const {
      question: { weightInterval, image, scaleIntervals },
      translate,
      displayMode
    } = props;

    const scaleImage: SvgName =
      scaleIntervals === 2
        ? 'Scales/kg_scale_2'
        : scaleIntervals === 3
        ? 'Scales/kg_scale_3'
        : 'Scales/kg_scale_4';

    const incorrectOptions = [
      [weightInterval, scaleIntervals + 1],
      [weightInterval + 1, scaleIntervals],
      [weightInterval + 1, scaleIntervals + 1]
    ];

    const [incorrectFracA, incorrectFracB, incorrectFracC] =
      scaleIntervals === 2
        ? [...incorrectOptions]
        : shuffle([...incorrectOptions, [weightInterval, scaleIntervals - 1]], {
            random: seededRandom(props.question)
          });

    const statements = shuffle(
      [
        {
          value: fractionToDecimal(weightInterval, scaleIntervals),
          text: `<frac n='${weightInterval}' d='${scaleIntervals}' />`
        },
        {
          value: fractionToDecimal(incorrectFracA[0], incorrectFracA[1]),
          text: `<frac n='${incorrectFracA[0]}' d='${incorrectFracA[1]}' />`
        },
        {
          value: fractionToDecimal(incorrectFracB[0], incorrectFracB[1]),
          text: `<frac n='${incorrectFracB[0]}' d='${incorrectFracB[1]}' />`
        },
        {
          value: fractionToDecimal(incorrectFracC[0], incorrectFracC[1]),
          text: `<frac n='${incorrectFracC[0]}' d='${incorrectFracC[1]}' />`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theScaleMesauresUpTo1kgSelectTheFractionShown()}
        pdfTitle={translate.instructions.theScaleMesauresUpTo1kgCircleTheFractionShown()}
        testCorrect={[fractionToDecimal(weightInterval, scaleIntervals)]}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <View style={{ paddingBottom: displayMode === 'digital' ? 16 : 32 }}>
            <Scales
              weightG={(1000 / scaleIntervals) * weightInterval}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName={scaleImage}
              weightImage={image}
            />
          </View>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: (
            <TextStructure
              sentence={statement.text}
              fractionTextStyle={{ fontSize: 36, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          )
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aIi',
  description: 'aIi',
  keywords: [
    'Capacity',
    'Volume',
    'Unit fraction',
    'Scale',
    'Non-unit fraction',
    'Numerator',
    'Denominator',
    'Litre'
  ],
  schema: z
    .object({
      jugIntervals: z.number().int().min(2).max(4),
      volumeInterval: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.volumeInterval < val.jugIntervals,
      'volumeInterval must be less than jugIntervals'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const jugIntervals = randomIntegerInclusive(2, 4);
    const volumeInterval = randomIntegerInclusive(1, jugIntervals - 1);
    return { volumeInterval, jugIntervals };
  },
  Component: ({ question: { volumeInterval, jugIntervals }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={`<frac nAns='' dAns='' /> ${translate.units.l()}`}
        title={translate.instructions.whatFractionOfLitreOfWaterIsInTheJug()}
        testCorrect={userAnswer => compareFractions(userAnswer, [volumeInterval, jugIntervals])}
        inputMaxCharacters={1}
        sentenceStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [volumeInterval.toLocaleString(), jugIntervals.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens }) => {
          return (
            <JugWithLiquid
              dimens={dimens}
              jugCapacity={1000}
              tickValue={1000 / jugIntervals}
              liquidAmount={(1000 / jugIntervals) * volumeInterval}
              displayMajorLabels="top"
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aIj',
  description: 'aIj',
  keywords: [
    'Mass',
    'Kilogram',
    'Length',
    'Metre',
    'Capacity',
    'Litre',
    'Order',
    'Greatest',
    'Smallest',
    'Unit of measurement',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      numeratorA: z.number().int().min(1).max(3),
      denominatorA: z.number().int().min(2).max(4),
      numeratorB: z.number().int().min(1).max(3),
      denominatorB: z.number().int().min(2).max(4),
      numeratorC: z.number().int().min(1).max(3),
      denominatorC: z.number().int().min(2).max(4),
      numeratorD: z.number().int().min(1).max(3),
      denominatorD: z.number().int().min(2).max(4),
      measurement: z.enum(['kg', 'm', 'l']),
      orderBy: z.enum(['ascending', 'descending'])
    })
    .refine(
      val =>
        val.numeratorA < val.denominatorA &&
        val.numeratorB < val.denominatorB &&
        val.numeratorC < val.denominatorC &&
        val.numeratorD < val.denominatorD,
      'All numerators must be less than their matching denominator.'
    )
    .refine(
      val =>
        arrayHasNoDuplicates([
          fractionToDecimal(val.numeratorA, val.denominatorA),
          fractionToDecimal(val.numeratorB, val.denominatorB),
          fractionToDecimal(val.numeratorC, val.denominatorC),
          fractionToDecimal(val.numeratorD, val.denominatorD)
        ]),
      'No fractions must be equivalent to each other.'
    ),
  simpleGenerator: () => {
    // Only one of these sets can be selected - set A has 1/2, set B has 2/4 - these are the only two possible equivalent fractions
    // that we need to avoid:
    const setAFractions = [
      [1, 4],
      [3, 4],
      [1, 3],
      [2, 3],
      [1, 2]
    ];
    const setBFractions = [
      [1, 4],
      [2, 4],
      [3, 4],
      [1, 3],
      [2, 3]
    ];

    const fractionsToChooseFrom = getRandomFromArray([setAFractions, setBFractions]);

    const [
      [numeratorA, denominatorA],
      [numeratorB, denominatorB],
      [numeratorC, denominatorC],
      [numeratorD, denominatorD]
    ] = getRandomSubArrayFromArray(fractionsToChooseFrom, 4);

    const measurement = getRandomFromArray(['kg', 'm', 'l'] as const);
    const orderBy = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      numeratorA,
      denominatorA,
      numeratorB,
      denominatorB,
      numeratorC,
      denominatorC,
      numeratorD,
      denominatorD,
      measurement,
      orderBy
    };
  },
  Component: props => {
    const {
      question: {
        numeratorA,
        denominatorA,
        numeratorB,
        denominatorB,
        numeratorC,
        denominatorC,
        numeratorD,
        denominatorD,
        measurement,
        orderBy
      },
      translate,
      displayMode
    } = props;

    const fractionMeasurement = (fraction: string) => {
      switch (measurement) {
        case 'kg':
          return translate.units.stringKg(fraction);
        case 'l':
          return translate.units.stringL(fraction);
        case 'm':
          return translate.units.stringM(fraction);
      }
    };

    const decimalA = fractionToDecimal(numeratorA, denominatorA);
    const decimalB = fractionToDecimal(numeratorB, denominatorB);
    const decimalC = fractionToDecimal(numeratorC, denominatorC);
    const decimalD = fractionToDecimal(numeratorD, denominatorD);

    const items = shuffle(
      [
        {
          sentence: fractionMeasurement(`<frac n='${numeratorA}' d='${denominatorA}' />`),
          value: decimalA
        },
        {
          sentence: fractionMeasurement(`<frac n='${numeratorB}' d='${denominatorB}' />`),
          value: decimalB
        },
        {
          sentence: fractionMeasurement(`<frac n='${numeratorC}' d='${denominatorC}' />`),
          value: decimalC
        },
        {
          sentence: fractionMeasurement(`<frac n='${numeratorD}' d='${denominatorD}' />`),
          value: decimalD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const correctOrder = sortNumberArray([decimalA, decimalB, decimalC, decimalD], orderBy);

    const measurementCategoryString =
      measurement === 'kg'
        ? translate.keywords.Masses()
        : measurement === 'l'
        ? translate.keywords.Capacities()
        : translate.keywords.Lengths();

    const leftLabel =
      orderBy === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest();
    const rightLabel =
      orderBy === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest();

    return (
      <QF5DragOrderHorizontal
        title={
          orderBy === 'ascending'
            ? translate.instructions.dragCardsToOrderMeasurementsStartWithSmallest(
                measurementCategoryString
              )
            : translate.instructions.dragCardsToOrderMeasurementsStartWithGreatest(
                measurementCategoryString
              )
        }
        pdfTitle={
          orderBy === 'ascending'
            ? translate.instructions.useCardsToOrderMeasurementsStartWithSmallest(
                measurementCategoryString
              )
            : translate.instructions.useCardsToOrderMeasurementsStartWithGreatest(
                measurementCategoryString
              )
        }
        testCorrect={correctOrder}
        items={items.map(({ sentence, value }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                fractionDividerStyle={{ margin: 4 }}
              />
            ),
            value
          };
        })}
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        labelsPosition="bottom"
        itemVariant={'shortRectangle'}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionsAndScales',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
