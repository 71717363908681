import { SvgName } from '../assets/svg';
import { countRange } from './collections';

type Image = {
  /** The image component, with input dimensions. */
  image: (props: { width: number; height: number; key?: string | number }) => JSX.Element;
  /** "Natural" starting dimensions - width. */
  width: number;
  /** "Natural" starting dimensions - height. */
  height: number;
};

export const getImagesByAmount = (image: SvgName | Image, amount: number) => {
  return countRange(amount).map(() => image);
};
