import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray, shuffle } from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';

////
// Questions
////

const q1Shapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q1Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const Question1 = newQuestionContent({
  uid: 'bbO',
  description: 'bbO',
  keywords: ['2-D shape', 'Circle', 'Triangle', 'Rectangle', 'Square'],
  schema: z.object({
    correctShape: z.enum(q1Shapes),
    items: z
      .array(
        z.object({
          shape: z.enum(q1Shapes),
          color: z.enum(q1Colors)
        })
      )
      .length(4)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must have no duplicates')
  }),
  simpleGenerator: () => {
    const correctShape = getRandomFromArray(q1Shapes);

    const [shapeA, shapeB, shapeC, shapeD] = getRandomSubArrayFromArray(q1Shapes, 4);

    const [colorA, colorB, colorC, colorD] = getRandomSubArrayFromArray(q1Colors, 4);

    const items = shuffle([
      { shape: shapeA, color: colorA },
      { shape: shapeB, color: colorB },
      { shape: shapeC, color: colorC },
      { shape: shapeD, color: colorD }
    ]);

    return { correctShape, items };
  },
  Component: ({ question: { correctShape, items }, translate }) => {
    const shapeString = (() => {
      switch (correctShape) {
        case 'circle':
          return translate.shapes.circles(1);
        case 'rectangle':
          return translate.shapes.rectangles(1);
        case 'square':
          return translate.shapes.squares(1);
        case 'triangle':
          return translate.shapes.triangles(1);
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheX(shapeString)}
        pdfTitle={translate.ks1PDFInstructions.tickTheX(shapeString)}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={getShapeSvgByShapeAndColor(item.shape, item.color)}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => item.shape === correctShape)}
        pdfShowBorder
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2CorrectShapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q2IncorrectCircleShapes = [
  'ellipse',
  'wavy circle',
  'semi-circle',
  'rounded rectangle'
] as const;
const q2IncorrectRectangleShapes = [
  'rounded rectangle',
  'cuboid',
  'L-shape',
  'parallelogram'
] as const;
const q2IncorrectSquareShapes = ['rounded square', 'cube', 'rhombus', 'rectangle'] as const;
const q2IncorrectTriangleShapes = ['triangle-based pyramid', 'square-based pyramid'] as const;
const q2Shapes = [
  ...q2CorrectShapes,
  ...q2IncorrectCircleShapes,
  ...q2IncorrectRectangleShapes,
  ...q2IncorrectSquareShapes,
  ...q2IncorrectTriangleShapes
] as const;
const q2Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

type Q2Shape = (typeof q2Shapes)[number];

const Question2 = newQuestionContent({
  uid: 'bbP',
  description: 'bbP',
  keywords: ['2-D shape', 'Circle', 'Triangle', 'Rectangle', 'Square'],
  schema: z
    .object({
      correctShape: z.enum(q2CorrectShapes),
      items: z
        .array(z.object({ shape: z.enum(q2Shapes), color: z.enum(q2Colors) }))
        .length(4)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => {
      const correctItems = val.items.filter(item => item.shape === val.correctShape);

      return correctItems.length >= 1 && correctItems.length <= 3;
    }, 'Number of correct items must be between 1 and 3'),
  simpleGenerator: () => {
    const correctShape = getRandomFromArray(q2CorrectShapes);

    const incorrectShapeC = (() => {
      switch (correctShape) {
        case 'circle':
          return getRandomFromArray(q2IncorrectCircleShapes);
        case 'rectangle':
          return getRandomFromArray(q2IncorrectRectangleShapes);
        case 'square':
          return getRandomFromArray(q2IncorrectSquareShapes);
        case 'triangle':
          return getRandomFromArray(q2IncorrectTriangleShapes);
      }
    })();

    const otherShapeD = (() => {
      switch (correctShape) {
        case 'circle':
          return getRandomFromArray(
            ['circle', ...q2IncorrectCircleShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'rectangle':
          return getRandomFromArray(
            ['rectangle', ...q2IncorrectRectangleShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'square':
          return getRandomFromArray(
            ['square', ...q2IncorrectSquareShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'triangle':
          return getRandomFromArray(
            ['triangle', ...q2IncorrectTriangleShapes].filter(shape => shape !== incorrectShapeC)
          );
      }
    })() as Q2Shape;

    const [shapeAColor, shapeBColor, shapeCColor, shapeDColor] = getRandomSubArrayFromArray(
      q2Colors,
      4
    );

    const items = shuffle([
      { shape: correctShape, color: shapeAColor },
      { shape: correctShape, color: shapeBColor },
      { shape: incorrectShapeC, color: shapeCColor },
      { shape: otherShapeD, color: shapeDColor }
    ]);

    return {
      correctShape,
      items
    };
  },
  Component: ({ question, translate }) => {
    const { correctShape, items } = question;

    const shapeString = (() => {
      switch (correctShape) {
        case 'circle':
          return translate.shapes.circles(2);
        case 'rectangle':
          return translate.shapes.rectangles(2);
        case 'square':
          return translate.shapes.squares(2);
        case 'triangle':
          return translate.shapes.triangles(2);
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheX(shapeString)}
        pdfTitle={translate.ks1PDFInstructions.tickTheX(shapeString)}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={getShapeSvgByShapeAndColor(item.shape, item.color)}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => item.shape === correctShape)}
        multiSelect
        questionHeight={1000}
        pdfShowBorder
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndName2DShapes',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
