import { newBlockContent } from '../../../Block';

import CompareLengthsAndHeights from './1CompareLengthsAndHeights';
import MeasureLengthUsingObjects from './2MeasureLengthUsingObjects';
import MeasureLengthInCentimetres from './3MeasureLengthInCentimetres';

const Block = newBlockContent({
  block: 'LengthAndHeight',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    CompareLengthsAndHeights, // Step 1
    MeasureLengthUsingObjects, // Step 2
    MeasureLengthInCentimetres // Step 3
  ]
});
export default Block;
