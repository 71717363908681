import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import { GridLine, GridPolygon } from 'common/src/utils/gridUtils';
import { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { Point2d } from '../../../../utils/vectors';
import { createShapeWithSquares } from '../../../../utils/shapes';
import QF24bCreateSymmetricalShape from '../../../../components/question/questionFormats/QF24bCreateSymmetricalShape';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import Svg, { TSpan, Text as SvgText } from 'react-native-svg';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const shapesArray = [
  'rectangleHorizontal',
  'rectangleVertical',
  'triangleHorizontal',
  'triangleVertical',
  'lShapeHorizontal',
  'lShapeVertical',
  'rhombusHorizontal',
  'rhombusVertical',
  'crossHorizontal',
  'crossVertical'
] as const;
export type Shapes = (typeof shapesArray)[number];

function generateShape(length: number, shape: Shapes) {
  const shapePoints = [];
  switch (shape) {
    case 'rectangleHorizontal':
      shapePoints.push({
        coords: [
          [-2, length === 2 ? 4 : 5],
          [0, length === 2 ? 4 : 5],
          [0, length === 2 ? 2 : 5 - length],
          [-2, length === 2 ? 2 : 5 - length]
        ],
        isHorizontal: true
      });
      break;
    case 'rectangleVertical':
      shapePoints.push({
        coords: [
          [length === 2 ? 2 : 1, 0],
          [length === 2 ? 2 : 1, 2],
          [length === 2 ? 4 : 1 + length, 2],
          [length === 2 ? 4 : 1 + length, 0]
        ],
        isHorizontal: false
      });
      break;
    case 'triangleHorizontal':
      shapePoints.push({
        coords: [
          [-2, randomIntegerInclusive(1, length + 1)],
          [0, 1],
          [0, length + 1]
        ],
        isHorizontal: true
      });
      break;
    case 'triangleVertical':
      shapePoints.push({
        coords: [
          [randomIntegerInclusive(1, length + 1), 2],
          [length + 1, 0],
          [1, 0]
        ],
        isHorizontal: false
      });
      break;
    case 'lShapeHorizontal':
      shapePoints.push({
        coords: [
          [-2, length === 2 ? 4 : 5],
          [-1, length === 2 ? 4 : 5],
          [-1, length === 2 ? 3 : 6 - length],
          [0, length === 2 ? 3 : 6 - length],
          [0, length === 2 ? 2 : 5 - length],
          [-2, length === 2 ? 2 : 5 - length]
        ],
        isHorizontal: true
      });
      break;
    case 'lShapeVertical':
      shapePoints.push({
        coords: [
          [length === 2 ? 2 : 1, 0],
          [length === 2 ? 2 : 1, 2],
          [length === 2 ? 4 : 1 + length, 2],
          [length === 2 ? 4 : 1 + length, 1],
          [length === 2 ? 3 : length, 1],
          [length === 2 ? 3 : length, 0]
        ],
        isHorizontal: false
      });
      break;
    case 'rhombusHorizontal':
      shapePoints.push({
        coords: [
          [-2, length === 2 ? 4 : 5],
          [0, length === 2 ? 3 : 4],
          [0, length === 2 ? 2 : 5 - length + 1],
          [-2, length === 2 ? 2 : 5 - length]
        ],
        isHorizontal: true
      });
      break;
    case 'rhombusVertical':
      shapePoints.push({
        coords: [
          [length === 2 ? 3 : 2, 0],
          [length === 2 ? 2 : 1, 2],
          [length === 2 ? 4 : 1 + length, 2],
          [length === 2 ? 3 : length, 0]
        ],
        isHorizontal: false
      });
      break;
    case 'crossHorizontal':
      shapePoints.push({
        coords: [
          [-3, 4],
          [-2, 4],
          [-2, 5],
          [-1, 5],
          [-1, 4],
          [0, 4],
          [0, 3],
          [-1, 3],
          [-1, 1],
          [-2, 1],
          [-2, 3],
          [-3, 3]
        ],
        isHorizontal: true
      });
      break;
    case 'crossVertical':
      shapePoints.push({
        coords: [
          [1, 2],
          [3, 2],
          [3, 3],
          [4, 3],
          [4, 2],
          [5, 2],
          [5, 1],
          [4, 1],
          [4, 0],
          [3, 0],
          [3, 1],
          [1, 1]
        ],
        isHorizontal: false
      });
      break;
  }
  return shapePoints;
}

const Question1 = newQuestionContent({
  uid: 'aBg',
  description: 'aBg',
  keywords: ['Reflection', 'Mirror line'],
  schema: z
    .object({
      shapePoints: z
        .array(
          z.object({
            coords: z.array(z.number().int().min(-2).max(5).array().length(2)),
            isHorizontal: z.boolean()
          })
        )
        .length(2),
      incorrectAnswer: z.enum(['translate', 'reflectTranslateX', 'reflectTranslateY'])
    })
    .refine(
      val =>
        val.shapePoints.every(point =>
          point.isHorizontal
            ? point.coords.some(array => array[0] === 0)
            : point.coords.some(array => array[1] === 0)
        ),
      'one coordinate must be on the reflection axis'
    ),
  simpleGenerator: () => {
    const shapes: Shapes[] = getRandomSubArrayFromArray(
      [
        'rectangleHorizontal',
        'rectangleVertical',
        'triangleHorizontal',
        'triangleVertical',
        'lShapeHorizontal',
        'lShapeVertical',
        'rhombusHorizontal',
        'rhombusVertical'
      ],
      2
    );

    const length = randomIntegerInclusive(2, 4);
    const incorrectAnswer =
      shapes[0] === 'rectangleHorizontal' || shapes[0] === 'rectangleVertical'
        ? getRandomFromArray(['reflectTranslateX', 'reflectTranslateY'] as const)
        : getRandomFromArray(['translate', 'reflectTranslateX', 'reflectTranslateY'] as const);
    const shapePoints = shapes.map(shape => generateShape(length, shape)).flat();

    return { shapePoints, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { shapePoints, incorrectAnswer },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      shapePoints.map((val, i) => ({
        value: ['A', 'B'][i],
        points1: val.coords,
        isHorizontal: val.isHorizontal,
        isCorrect: i >= 1
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectDiagramShowsCorrectReflectionInGivenMirrorLine()}
        pdfTitle={translate.instructions.circleDiagramShowsCorrectReflectionInGivenMirrorLine()}
        testCorrect={items.filter(val => val.isCorrect).map(x => x.value)}
        numItems={2}
        questionHeight={900}
        renderItems={({ dimens }) =>
          items.map(val => {
            let points2: number[][] = [];
            const correctAnswer = (points2 = val.points1.map(coord =>
              val.isHorizontal ? [-coord[0], coord[1]] : [coord[0], -coord[1]]
            ));
            if (val.isCorrect) {
              points2 = correctAnswer;
            } else {
              points2 =
                incorrectAnswer === 'translate'
                  ? val.points1
                      .map(point => {
                        const start = new Point2d(point[0], point[1]);
                        const addPoint = val.isHorizontal ? new Point2d(2, 0) : new Point2d(0, -2);
                        return start.add(addPoint);
                      })
                      .map(p => [p.x, p.y])
                  : correctAnswer
                      .map(val => {
                        const start = new Point2d(val[0], val[1]);
                        const addPoint =
                          incorrectAnswer === 'reflectTranslateX'
                            ? new Point2d(1, 0)
                            : new Point2d(0, -1);
                        return start.add(addPoint);
                      })
                      .map(p => [p.x, p.y]);
            }
            return {
              value: val.value,
              component: (
                <Grid
                  width={dimens.width * 0.8}
                  height={dimens.height * 0.8}
                  yMin={val.isHorizontal ? 0 : -3}
                  yMax={val.isHorizontal ? 6 : 3}
                  xMin={val.isHorizontal ? -3 : 0}
                  xMax={val.isHorizontal ? 3 : 6}
                  hideContinuationLines
                  xLabels={null}
                  yLabels={null}
                  xAxis={null}
                  yAxis={null}
                  squareGrid
                  children={({ mathToSvgX, mathToSvgY }) => (
                    <GridSvgChildren>
                      <GridPolygon
                        points={val.points1.map(array => array as [number, number])}
                        showBorder
                        borderWidth={displayMode === 'digital' ? 3 : 5}
                        color="none"
                      />
                      <GridPolygon
                        points={points2.map(array => array as [number, number])}
                        showBorder
                        borderWidth={displayMode === 'digital' ? 3 : 5}
                        color="none"
                      />
                      <Line
                        x1={mathToSvgX(0)}
                        y1={mathToSvgY(val.isHorizontal ? 6 : 0)}
                        x2={mathToSvgX(val.isHorizontal ? 0 : 6)}
                        y2={mathToSvgY(0)}
                        stroke={colors.red}
                        strokeDasharray={[8, 8]}
                        strokeWidth={displayMode === 'digital' ? 4 : 6}
                      />
                    </GridSvgChildren>
                  )}
                />
              )
            };
          })
        }
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aBh',
  description: 'aBh',
  keywords: ['Reflection', 'Mirror line'],
  questionHeight: 900,
  schema: z
    .object({
      shapePoints: z
        .array(
          z.object({
            coords: z.array(z.number().int().min(-4).max(6).array().length(2)),
            isHorizontal: z.boolean()
          })
        )
        .length(2),
      incorrectAnswer: z.enum(['translate', 'reflectTranslateX', 'reflectTranslateY'])
    })
    .refine(
      val =>
        val.shapePoints.every(point =>
          point.isHorizontal
            ? point.coords.every(array => array[0] !== 0)
            : point.coords.every(array => array[1] !== 0)
        ),
      'no coordinates must be on the reflection axis'
    ),
  simpleGenerator: () => {
    const shapes: Shapes[] = getRandomSubArrayFromArray(
      [
        'rectangleHorizontal',
        'rectangleVertical',
        'lShapeHorizontal',
        'lShapeVertical',
        'crossHorizontal',
        'crossVertical'
      ],
      2
    );

    const length = randomIntegerInclusive(2, 4);
    const incorrectAnswer =
      shapes[0] === 'lShapeHorizontal' || shapes[0] === 'lShapeVertical'
        ? getRandomFromArray(['translate', 'reflectTranslateX', 'reflectTranslateY'] as const)
        : getRandomFromArray(['reflectTranslateX', 'reflectTranslateY'] as const);
    const shapePoints = shapes
      .map(shape =>
        generateShape(length, shape).map(val => ({
          coords: val.coords.map(coord => {
            const start = new Point2d(coord[0], coord[1]);
            const addPoint = val.isHorizontal ? new Point2d(-1, 0) : new Point2d(0, 1);
            const endPoint = start.add(addPoint);
            return [endPoint.x, endPoint.y];
          }),
          isHorizontal: val.isHorizontal
        }))
      )
      .flat();

    return { shapePoints, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { shapePoints, incorrectAnswer },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      shapePoints.map((val, i) => ({
        value: ['A', 'B'][i],
        points1: val.coords,
        isHorizontal: val.isHorizontal,
        isCorrect: i >= 1
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectDiagramShowsCorrectReflectionInGivenMirrorLine()}
        pdfTitle={translate.instructions.circleDiagramShowsCorrectReflectionInGivenMirrorLine()}
        testCorrect={items.filter(val => val.isCorrect).map(x => x.value)}
        numItems={2}
        questionHeight={900}
        renderItems={({ dimens }) =>
          items.map(val => {
            let points2: number[][] = [];
            const correctAnswer = (points2 = val.points1.map(coord =>
              val.isHorizontal ? [-coord[0], coord[1]] : [coord[0], -coord[1]]
            ));
            if (val.isCorrect) {
              points2 = correctAnswer;
            } else {
              points2 =
                incorrectAnswer === 'translate'
                  ? val.points1
                      .map(point => {
                        const start = new Point2d(point[0], point[1]);
                        const addPoint = val.isHorizontal ? new Point2d(4, 0) : new Point2d(0, -4);
                        return start.add(addPoint);
                      })
                      .map(p => [p.x, p.y])
                  : correctAnswer
                      .map(val => {
                        const start = new Point2d(val[0], val[1]);
                        const addPoint =
                          incorrectAnswer === 'reflectTranslateX'
                            ? new Point2d(1, 0)
                            : new Point2d(0, 1);
                        return start.add(addPoint);
                      })
                      .map(p => [p.x, p.y]);
            }

            const yMin = 0;
            const yMax = val.isHorizontal ? 7 : 10;
            const xMin = 0;
            const xMax = val.isHorizontal ? 12 : 7;

            const translateX = val.isHorizontal ? xMax / 2 : 0;
            const translateY = val.isHorizontal ? 0 : yMax / 2;

            return {
              value: val.value,
              component: (
                <Grid
                  width={dimens.width * 0.9}
                  height={dimens.height * 0.9}
                  yMin={yMin}
                  yMax={yMax}
                  xMin={xMin}
                  xMax={xMax}
                  hideContinuationLines
                  xLabels={null}
                  yLabels={null}
                  squareGrid
                  xAxis={null}
                  yAxis={null}
                  children={({ mathToSvgX, mathToSvgY }) => (
                    <GridSvgChildren>
                      <GridPolygon
                        // because we want to also show the axis, we need to translate out of the negative side
                        points={val.points1.map(array => {
                          const start = new Point2d(array[0], array[1]);
                          const addPoint = new Point2d(translateX, translateY);
                          const endPoint = start.add(addPoint);
                          return [endPoint.x, endPoint.y] as [number, number];
                        })}
                        showBorder
                        borderWidth={displayMode === 'digital' ? 3 : 5}
                        color="none"
                      />
                      <GridPolygon
                        // because we want to also show the axis, we need to translate out of the negative side
                        points={points2.map(array => {
                          const start = new Point2d(array[0], array[1]);
                          const addPoint = new Point2d(translateX, translateY);
                          const endPoint = start.add(addPoint);
                          return [endPoint.x, endPoint.y] as [number, number];
                        })}
                        showBorder
                        borderWidth={displayMode === 'digital' ? 3 : 5}
                        color="none"
                      />
                      <Line
                        x1={mathToSvgX(val.isHorizontal ? xMax / 2 : 0)}
                        y1={mathToSvgY(val.isHorizontal ? 7 : yMax / 2)}
                        x2={mathToSvgX(val.isHorizontal ? xMax / 2 : 7)}
                        y2={mathToSvgY(val.isHorizontal ? 0 : yMax / 2)}
                        stroke={colors.red}
                        strokeDasharray={'10,10'}
                        strokeWidth={displayMode === 'digital' ? 4 : 6}
                      />
                    </GridSvgChildren>
                  )}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aBi',
  description: 'aBi',
  keywords: ['Reflect', 'Area', 'Mirror line'],
  schema: z
    .object({
      shape: z.array(z.array(z.boolean())),
      isInY: z.boolean()
    })
    .refine(
      val =>
        val.isInY
          ? val.shape.some(row => row[row.length - 1] === true)
          : val.shape[val.shape.length - 1].some(col => col === true),
      'the shape must touch the mirror line'
    ),
  simpleGenerator: () => {
    const squaresInShape = randomIntegerInclusive(4, 12);
    const isInY = getRandomBoolean();
    const rows = isInY ? 4 : 2;
    const cols = isInY ? 4 : 8;
    const shape = rejectionSample(
      () => createShapeWithSquares(rows, cols, squaresInShape, true),
      val =>
        isInY
          ? val.some(row => row[row.length - 1] === true)
          : val[val.length - 1].some(col => col === true)
    );

    return { shape, isInY };
  },
  Component: props => {
    const {
      question: { shape, isInY },
      translate
    } = props;

    return (
      <QF24bCreateSymmetricalShape
        title={translate.instructions.reflectShapeInMirrorLineBySelecting()}
        pdfTitle={translate.instructions.reflectShapeInMirrorLineByShading()}
        givenShape={shape}
        symmetryLine={isInY ? 'Y' : 'X'}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aBj',
  description: 'aBj',
  keywords: ['Reflect', 'Area', 'Mirror line'],
  schema: z
    .object({
      shape: z.array(z.array(z.boolean()))
    })
    .refine(
      val => val.shape.every(row => row[row.length - 1] !== true),
      'the shape must touch not the mirror line'
    ),
  simpleGenerator: () => {
    const squaresInShape = randomIntegerInclusive(4, 8);
    // has to be in y axis as we can't fit x axis on
    const rows = 4;
    const cols = 4;
    const shape = rejectionSample(
      () => createShapeWithSquares(rows, cols, squaresInShape, true),
      val => val.every(row => row[row.length - 1] !== true)
    );

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    return (
      <QF24bCreateSymmetricalShape
        title={translate.instructions.reflectShapeInMirrorLineBySelecting()}
        pdfTitle={translate.instructions.reflectShapeInMirrorLineByShading()}
        givenShape={shape}
        symmetryLine={'Y'}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aBk',
  description: 'aBk',
  keywords: ['Reflect', 'Area', 'Mirror line'],
  schema: z
    .object({
      shapePoints: z.object({
        coords: z.array(z.number().int().min(-2).max(5).array().length(2)),
        isHorizontal: z.boolean()
      })
    })
    .refine(
      val =>
        val.shapePoints.coords.some(point =>
          val.shapePoints.isHorizontal ? point[0] === 0 : point[1] === 0
        ),
      'one coordinate must be on the reflection axis'
    ),
  simpleGenerator: () => {
    const shape: Shapes = getRandomFromArray([
      'rectangleHorizontal',
      'rectangleVertical',
      'lShapeHorizontal',
      'lShapeVertical'
    ] as const);

    const length = randomIntegerInclusive(2, 4);
    const shapePoints = generateShape(length, shape)[0];

    return { shapePoints };
  },
  Component: props => {
    const {
      question: { shapePoints },
      translate,
      displayMode
    } = props;

    const startPoints = shapePoints.coords.map(val => new Point2d(val[0], val[1]));
    const endPoints = startPoints.map(coord =>
      shapePoints.isHorizontal ? [-coord.x, coord.y] : [coord.x, -coord.y]
    );

    return (
      <QF48TranslateSvg
        title={translate.instructions.dragPointsToReflectInMirrorLine()}
        pdfTitle={translate.instructions.drawPointsToReflectInMirrorLine()}
        start={shapePoints.coords.map(val => new Point2d(val[0], val[1]))}
        end={endPoints.map(val => new Point2d(val[0], val[1]))}
        questionHeight={900}
        svg="custom"
        gridChildren={
          <GridSvgChildren>
            <GridPolygon
              points={shapePoints.coords.map(val => val as [number, number])}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
            <GridLine
              points={[
                [0, shapePoints.isHorizontal ? 6 : 0],
                [shapePoints.isHorizontal ? 0 : 6, 0]
              ]}
              color={colors.red}
              strokeDasharray={[8, 8]}
              strokeWidth={displayMode === 'digital' ? 4 : 6}
            />
          </GridSvgChildren>
        }
        customDraggable={(color: string, opacity?: number, index = 0) => ({
          component: (
            <Svg width={36} height={36}>
              <AssetSvg
                name="Coordinates/CirclePointCustomizable"
                width={36}
                svgProps={{ fill: opacity && opacity < 1 ? `${color}${opacity * 100}` : color }}
              />
              <SvgText y={26} x={18} textAnchor="middle" fontFamily="White_Rose_Noto-Regular">
                <TSpan
                  fontSize={displayMode === 'digital' ? 22 : 28}
                  fill={displayMode === 'digital' ? 'black' : 'white'}
                >
                  {['A', 'B', 'C', 'D', 'E', 'F'][index]}
                </TSpan>
              </SvgText>
            </Svg>
          ),
          width: 36,
          height: 36
        })}
        yMin={shapePoints.isHorizontal ? 0 : -3}
        yMax={shapePoints.isHorizontal ? 6 : 3}
        xMin={shapePoints.isHorizontal ? -3 : 0}
        xMax={shapePoints.isHorizontal ? 3 : 6}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aBl',
  description: 'aBl',
  keywords: ['Reflect', 'Area', 'Mirror line'],
  questionHeight: 900,
  schema: z
    .object({
      shapePoints: z.object({
        coords: z.array(z.number().int().min(-6).max(6).array().length(2)),
        isHorizontal: z.boolean()
      })
    })
    .refine(
      val =>
        val.shapePoints.coords.every(point =>
          val.shapePoints.isHorizontal ? point[0] !== 0 : point[1] !== 0
        ),
      'no coordinates must be on the reflection axis'
    ),
  simpleGenerator: () => {
    const shape: Shapes = getRandomFromArray([
      'rectangleHorizontal',
      'rectangleVertical',
      'lShapeHorizontal',
      'lShapeVertical'
    ] as const);

    const moveByValue = randomIntegerInclusive(1, 2);

    const length = randomIntegerInclusive(2, 4);
    const shapePoints = generateShape(length, shape).map(val => ({
      coords: val.coords.map(coord => {
        const moveBy = val.isHorizontal ? moveByValue : 1;
        const start = new Point2d(coord[0], coord[1]);
        const addPoint = val.isHorizontal ? new Point2d(-moveBy, 0) : new Point2d(0, moveBy);
        const endPoint = start.add(addPoint);
        return [endPoint.x, endPoint.y];
      }),
      isHorizontal: val.isHorizontal
    }))[0];

    return { shapePoints };
  },
  Component: props => {
    const {
      question: { shapePoints },
      translate,
      displayMode
    } = props;

    const yMin = shapePoints.isHorizontal ? 0 : -4;
    const yMax = shapePoints.isHorizontal ? 6 : 4;
    const xMin = shapePoints.isHorizontal ? -6 : 0;
    const xMax = 6;

    const startPoints = shapePoints.coords.map(val => new Point2d(val[0], val[1]));
    const endPoints = startPoints.map(coord =>
      shapePoints.isHorizontal ? [-coord.x, coord.y] : [coord.x, -coord.y]
    );

    return (
      <QF48TranslateSvg
        title={translate.instructions.dragPointsToReflectInMirrorLine()}
        pdfTitle={translate.instructions.drawPointsToReflectInMirrorLine()}
        start={startPoints}
        end={endPoints.map(val => new Point2d(val[0], val[1]))}
        questionHeight={900}
        svg="custom"
        gridChildren={
          <GridSvgChildren>
            <GridPolygon
              points={startPoints.map(val => [val.x, val.y])}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
            <GridLine
              points={[
                [shapePoints.isHorizontal ? 0 : 0, shapePoints.isHorizontal ? 7 : 0],
                [shapePoints.isHorizontal ? 0 : 7, shapePoints.isHorizontal ? 0 : 0]
              ]}
              color={colors.red}
              strokeDasharray={[8, 8]}
              strokeWidth={displayMode === 'digital' ? 4 : 6}
            />
          </GridSvgChildren>
        }
        customDraggable={(color: string, opacity?: number, index = 0) => ({
          component: (
            <Svg width={36} height={36}>
              <AssetSvg
                name="Coordinates/CirclePointCustomizable"
                width={36}
                svgProps={{ fill: opacity && opacity < 1 ? `${color}${opacity * 100}` : color }}
              />
              <SvgText y={26} x={18} textAnchor="middle" fontFamily="White_Rose_Noto-Regular">
                <TSpan
                  fontSize={displayMode === 'digital' ? 22 : 28}
                  fill={displayMode === 'digital' ? 'black' : 'white'}
                >
                  {['A', 'B', 'C', 'D', 'E', 'F'][index]}
                </TSpan>
              </SvgText>
            </Svg>
          ),
          width: 36,
          height: 36
        })}
        yMin={yMin}
        yMax={yMax}
        xMin={xMin}
        xMax={xMax}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReflectionInHorizontalAndVerticalLines',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
