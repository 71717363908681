import { throwError } from './flowControl';

export class Point2d {
  readonly x: number;
  readonly y: number;

  /** Various convenient constructors, using typescript's function signature overload feature. */
  constructor(x: number, y: number);
  constructor(vector: [number, number]);
  constructor(vector: { x: number; y: number });
  constructor(xOrVector: number | [number, number] | { x: number; y: number }, yOptional?: number) {
    let x, y: number;

    if (typeof xOrVector === 'number') {
      x = xOrVector;
      y = yOptional ?? throwError('if the first argument is a number, two arguments are required');
    } else if (Array.isArray(xOrVector)) {
      [x, y] = xOrVector;
    } else {
      x = xOrVector.x;
      y = xOrVector.y;
    }

    this.x = x;
    this.y = y;
  }

  copy({ x = this.x, y = this.y }): Point2d {
    return new Point2d({ x, y });
  }

  toArray(): [number, number] {
    return [this.x, this.y];
  }

  toObject(): { x: number; y: number } {
    return { x: this.x, y: this.y };
  }

  add(...others: Point2d[]): Point2d {
    return new Point2d(
      others.reduce((sum, v) => sum + v.x, this.x),
      others.reduce((sum, v) => sum + v.y, this.y)
    );
  }

  multiply(scalar: number): Point2d {
    return new Point2d(this.x * scalar, this.y * scalar);
  }
}

export const DIRECTIONS = ['up', 'down', 'left', 'right'] as const;
export type Direction = (typeof DIRECTIONS)[number];

/**
 * Get a vector indicating movement in the given direction.
 *  This uses math coordinates not graphics coordinates, so +y points up.
 */
export function directionToVector(direction: Direction) {
  switch (direction) {
    case 'up':
      return new Point2d(0, 1);
    case 'down':
      return new Point2d(0, -1);
    case 'left':
      return new Point2d(-1, 0);
    case 'right':
      return new Point2d(1, 0);
  }
}
