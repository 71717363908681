import {
  filledArray,
  range,
  rangeAsString,
  sortNumberArray,
  arrayHasNoDuplicates,
  arraysHaveSameContents
} from 'common/src/utils/collections';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from 'common/src/utils/random';
import { digitAtPowerIsNumber, isWithinRange } from 'common/src/utils/math';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aam',
  description: 'aam',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10).max(90)).length(3).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 100;
    const interval = 10;

    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const numbers = rejectionSample(
      () => getRandomSubArrayFromArray(choices, 3),
      x => {
        const [i, j, k] = x.map(it => choices.indexOf(it)).sort();
        return (j === i + 1 && k !== j + 1) || (k === j + 1 && j !== i + 1);
      }
    );

    // Sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers: sortNumberArray(Array.from(numbers)) };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 100;
    const interval = 10;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aan',
  description: 'aan',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z.object({
    number: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const labelInterval = 50;
    const number = randomIntegerInclusive(1, 9, { constraint: x => x !== labelInterval / 10 }) * 10;

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 100;
    const tickInterval = 10;
    const labelInterval = 50;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        tickValues={tickValues}
        freeNumberLineAnswer={[number]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aao',
  description: 'aao',
  keywords: ['Number line', 'Place value', '100', 'Hundred', 'Estimate'],
  schema: z.object({
    number: z.number().int().min(2).max(98)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 100;
    const interval = 10;

    const tensChoices = range(startingNumber + interval, endNumber - interval - interval, interval);
    const tensChoice = getRandomFromArray(tensChoices);
    const onesChoice = getRandomFromArray([2, 3, 4, 5, 6, 7, 8] as const);
    const number = tensChoice + onesChoice;

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 100;
    const interval = 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, interval);

    const testCorrect = (userAnswer: string[]) => {
      if (number % 5 === 0) {
        return arraysHaveSameContents(userAnswer, [number.toString()]);
      } else if (number % 5 === 1) {
        return [number.toString(), (number + 1).toString()].includes(userAnswer[0]);
      } else if (number % 5 === 4) {
        return [number.toString(), (number - 1).toString()].includes(userAnswer[0]);
      } else {
        return isWithinRange(parseInt(userAnswer[0]), number, 1);
      }
    };

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateNumberArrowIsPointingTo()}
        testCorrect={testCorrect}
        inputMaxCharacters={3}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        customMarkSchemeAnswer={{ answersToDisplay: [number.toLocaleString()] }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aap',
  description: 'aap',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z
    .object({
      number: z.number().int().min(10).max(100),
      startingNumber: z.number().int().min(10).max(90),
      endNumber: z.number().int().min(20).max(100)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number < val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 9) * 10;
    const endNumber = startingNumber + 10;
    const answerInterval = 1;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowWhereXIsOnTheNumberLine(number)}
        pdfTitle={translate.instructions.whereIsNumPdf(number)}
        testCorrect={[number - 0.1, number + 0.1]}
        min={startingNumber}
        max={endNumber}
        sliderStep={0.01}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aaq',
  description: 'aaq',
  keywords: ['Number line', 'Place value', '100', 'Hundred', 'Estimate'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(1)
      .max(999)
      .refine(number => digitAtPowerIsNumber(number, 'ones', [2, 3, 4, 5, 6, 7, 8]))
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 100;
    const answerInterval = 1;

    const number = randomIntegerInclusiveStep(startingNumber, endNumber, answerInterval, {
      constraint: x => digitAtPowerIsNumber(x, 'ones', [2, 3, 4, 5, 6, 7, 8])
    });
    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 10;
    const labelInterval = 50;
    const startingNumber = 0;
    const endNumber = 100;

    // Create array of empty strings
    const numberArray = range(startingNumber, endNumber, tickInterval);
    const tickArray = numberArray.map(number => {
      return number % labelInterval ? '' : number.toLocaleString();
    });

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowToEstimateWhereXIsOnNumberLine(number)}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={[number - 1, number + 1]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickArray}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aar',
  description: 'aar',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z
    .object({
      number: z.number().int().min(12).max(98),
      startingNumber: z.number().int().min(20).max(80),
      endNumber: z.number().int().min(40).max(100),
      interval: z.union([z.literal(2), z.literal(5)])
    })
    .refine(
      val => val.number >= val.startingNumber + val.interval,
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.number <= val.endNumber - val.interval,
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([2, 5] as const);
    const startingNumber = getRandomFromArray(
      range(Math.max(interval * 10), 100 - 10 * interval, interval * 10)
    );
    const endNumber = startingNumber + 10 * interval;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        tickValues={tickValues}
        freeNumberLineAnswer={[number]}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLineTo100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
