import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom
} from 'common/src/utils/random';
import { z } from 'zod';
import { filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors } from '../../../../theme/colors';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD } from '../../../../constants';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLk',
  description: 'aLk',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Equivalent'],
  schema: z.object({
    number: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 8);

    return { number };
  },

  Component: ({ question: { number }, translate }) => {
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ number })
    }) as string;
    const numbers = [filledArray(1, number), filledArray(1, number)];
    const strings = [filledArray(translate.units.numberOfKm(1), number), filledArray('', number)];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreThousandMInOneKmUseBarModel()}
        testCorrect={[(number * 1000).toString()]}
        sentence={translate.answerSentences.thereAreAnsMinXKm(number.toLocaleString())}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number}
            dimens={dimens}
            rowColors={[color, 'white']}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLl',
  description: 'aLl',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Equivalent'],
  schema: z.object({
    number: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 9);

    return { number };
  },

  Component: ({ question: { number }, translate }) => {
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ number })
    }) as string;
    const numbers = [filledArray(1, number), filledArray(1, number)];
    const strings = [filledArray('', number), filledArray(translate.units.numberOfM(1000), number)];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreThousandMInOneKmUseBarModel()}
        testCorrect={[number.toString()]}
        pdfDirection="column"
        sentence={translate.answerSentences.thereAreXMInAnsKm((number * 1000).toLocaleString())}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number}
            dimens={dimens}
            rowColors={['white', color]}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aLm',
  description: 'aLm',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Equivalent'],
  schema: z.object({
    km1: z.number().int().min(1).max(9),
    km2: z.number().int().min(1).max(9),
    km3: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [km1, km2, km3] = randomUniqueIntegersInclusive(1, 9, 3);

    return { km1, km2, km3 };
  },

  Component: ({ question: { km1, km2, km3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        testCorrect={[[km1.toString()], [km2.toString()], [(km3 * 1000).toString()]]}
        sentences={[
          `${translate.units.numberOfM(km1 * 1000)} = ${translate.answerSentences.ansKm()}`,
          `${translate.units.numberOfM(km2 * 1000)} = ${translate.answerSentences.ansKm()}`,
          `${translate.answerSentences.ansM()} = ${translate.units.numberOfKm(km3)}`
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLn',
  description: 'aLn',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Equivalent'],
  schema: z.object({
    km1: z.number().int().min(1).max(9),
    m1: z.number().int().min(100).max(900).step(100),
    km2: z.number().int().min(1).max(9),
    m2: z.number().int().min(100).max(900).step(100),
    km3: z.number().int().min(1).max(9),
    m3: z.number().int().min(100).max(900).step(100)
  }),
  simpleGenerator: () => {
    const [km1, km2, km3] = randomUniqueIntegersInclusive(1, 9, 3);
    const [m1, m2, m3] = randomUniqueIntegersInclusiveStep(100, 900, 100, 3);

    return { km1, km2, km3, m1, m2, m3 };
  },

  Component: ({ question: { km1, km2, km3, m1, m2, m3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        testCorrect={[
          [km1.toString(), m1.toString()],
          [km2.toString(), m2.toString()],
          [(km3 * 1000 + m3).toString()]
        ]}
        sentences={[
          `${translate.units.numberOfM(
            km1 * 1000 + m1
          )} = ${translate.answerSentences.ansKm()} ${translate.answerSentences.ansM()}`,
          `${translate.units.numberOfM(
            km2 * 1000 + m2
          )} = ${translate.answerSentences.ansKm()} ${translate.answerSentences.ansM()}`,
          `${translate.answerSentences.ansM()} = ${translate.units.numberOfKm(
            km3
          )} ${translate.units.numberOfM(m3)}`
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLo',
  description: 'aLo',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Bar model', 'Equivalent'],
  schema: z.object({
    m1: z.number().int().min(100).max(900).step(100),
    m2: z.number().int().min(100).max(2900).step(100),
    km: z.number().int().min(1).max(4),
    answerPosition: z.enum(['bottom middle', 'top', 'bottom left', 'bottom right'])
  }),
  simpleGenerator: () => {
    const km = randomIntegerInclusive(1, 4);
    const m1 = randomIntegerInclusiveStep(100, 900, 100);
    // make sure that the meters make up a good proportion of the total for bar model spacing
    const mTotal =
      km === 1
        ? 1000
        : km === 2
        ? getRandomFromArray([1000, 2000])
        : getRandomFromArray([1000, 2000, 3000]);
    const m2 = mTotal - m1;

    const answerPosition = getRandomFromArray([
      'bottom middle',
      'top',
      'bottom left',
      'bottom right'
    ] as const);

    return { km, m1, m2, answerPosition };
  },

  Component: ({ question: { km, m1, m2, answerPosition }, translate }) => {
    // due to small numbers we want to artificially weight the bar models. Ensure that min box is 10%
    const totalM = km * 1000 + m1 + m2;
    const totalKm = totalM / 1000;

    let convertedLeft = m1;
    let convertedMiddle = m2;
    let convertedRight = km;
    let convertedTop = totalM;
    const leftPerc = (m1 / totalM) * 100;
    const middlePerc = (m2 / totalM) * 100;
    const rightPerc = (km / totalKm) * 100;

    switch (answerPosition) {
      case 'bottom left':
        convertedLeft = m1;
        if (leftPerc < 15) {
          const newTotal = (m1 / 15) * 100;
          convertedRight = (newTotal / 100) * rightPerc;
          convertedMiddle = newTotal - convertedRight - m1;
          convertedTop = newTotal;
        } else if (middlePerc < 10) {
          convertedMiddle = totalM / 10;
          const difference = convertedMiddle - m2;
          convertedRight = km * 1000 - difference;
        } else {
          convertedRight = km * 1000;
        }
        break;
      case 'bottom middle':
        convertedMiddle = m2;
        if (leftPerc < 10) {
          convertedLeft = totalM / 10;
          const difference = convertedLeft - m1;
          convertedRight = km * 1000 - difference;
        } else if (middlePerc < 15) {
          const newTotal = (m2 / 15) * 100;
          convertedRight = (newTotal / 100) * rightPerc;
          convertedLeft = newTotal - convertedRight - m2;
          convertedTop = newTotal;
        } else {
          convertedRight = km * 1000;
        }
        break;
      case 'bottom right':
        convertedRight = km;
        if (leftPerc < 10) {
          convertedLeft = totalKm / 10;
          const difference = convertedLeft - m1 / 1000;
          convertedMiddle = m2 / 1000 - difference;
        } else if (middlePerc < 10) {
          convertedMiddle = totalKm / 10;
          const difference = convertedMiddle - m2 / 1000;
          convertedLeft = m1 / 1000 - difference;
        } else {
          convertedLeft = m1 / 1000;
          convertedMiddle = m2 / 1000;
        }
        convertedTop = totalKm;
        break;
      case 'top':
        convertedTop = totalKm;
        if (leftPerc < 10) {
          convertedLeft = totalKm / 10;
          const difference = convertedLeft - m1 / 1000;
          convertedMiddle = m2 / 1000 - difference;
        } else if (middlePerc < 10) {
          convertedMiddle = totalKm / 10;
          const difference = convertedMiddle - m2 / 1000;
          convertedLeft = m1 / 1000 - difference;
        } else {
          convertedLeft = m1 / 1000;
          convertedMiddle = m2 / 1000;
        }
        break;
    }

    const numbers = [[convertedTop], [convertedLeft, convertedMiddle, convertedRight]];

    const strings = [
      [translate.units.numberOfKm(totalKm)],
      [translate.units.numberOfM(m1), translate.units.numberOfM(m2), translate.units.numberOfKm(km)]
    ];

    const answerIndices = (() => {
      switch (answerPosition) {
        case 'top':
          return [[0], []];
        case 'bottom left':
          return [[], [0]];
        case 'bottom middle':
          return [[], [1]];
        case 'bottom right':
          return [[], [2]];
      }
    })();

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        strings={strings}
        answerIndices={answerIndices}
        total={convertedTop}
        postAnswerString={
          answerPosition === 'bottom right' || answerPosition === 'top'
            ? translate.units.km()
            : translate.units.m()
        }
        oneFontSize
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aLo2',
  description: 'aLo',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Bar model', 'Equivalent'],
  schema: z.object({
    total: z.number().int().min(1000).max(6000).step(1000),
    part1: z.number().int().min(200).max(3600).step(100),
    part2: z.number().int().min(200).max(3600).step(100),
    part3: z.number().int().min(200).max(3600).step(100),
    answerPosition: z.enum(['bottom middle', 'top', 'bottom left', 'bottom right'])
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusiveStep(1000, 6000, 1000);

    const twentyPercent = total * 0.2;
    const sixtyPercent = total * 0.6;

    const part1 = randomIntegerInclusiveStep(twentyPercent, sixtyPercent, 100); //We need to maintain a minimum of 20% for each of the other 2 parts so the text box fits

    const part2 = randomIntegerInclusiveStep(twentyPercent, total - (part1 + twentyPercent), 100); // We add 20% to the first part to ensure the max still allows for part 3 to be at least 20% too

    const part3 = total - part1 - part2;

    const answerPosition = getRandomFromArray([
      'bottom middle',
      'top',
      'bottom left',
      'bottom right'
    ] as const);

    return { total, part1, part2, part3, answerPosition };
  },

  Component: ({ question: { total, part1, part2, part3, answerPosition }, translate }) => {
    //If the answer is in KM we need to scale the values down so they are all in KM or else the bar model won't render correctly
    const { convertedTop, convertedLeft, convertedMiddle, convertedRight } = (() => {
      switch (answerPosition) {
        case 'top':
          return {
            convertedTop: total / 1000,
            convertedLeft: part1 / 1000,
            convertedMiddle: part2 / 1000,
            convertedRight: part3 / 1000
          };
        case 'bottom left':
          return part1 % 1000 > 0
            ? {
                convertedTop: total,
                convertedLeft: part1,
                convertedMiddle: part2,
                convertedRight: part3
              }
            : {
                convertedTop: total / 1000,
                convertedLeft: part1 / 1000,
                convertedMiddle: part2 / 1000,
                convertedRight: part3 / 1000
              };
        case 'bottom middle':
          return part2 % 1000 > 0
            ? {
                convertedTop: total,
                convertedLeft: part1,
                convertedMiddle: part2,
                convertedRight: part3
              }
            : {
                convertedTop: total / 1000,
                convertedLeft: part1 / 1000,
                convertedMiddle: part2 / 1000,
                convertedRight: part3 / 1000
              };
        case 'bottom right':
          return part3 % 1000 > 0
            ? {
                convertedTop: total,
                convertedLeft: part1,
                convertedMiddle: part2,
                convertedRight: part3
              }
            : {
                convertedTop: total / 1000,
                convertedLeft: part1 / 1000,
                convertedMiddle: part2 / 1000,
                convertedRight: part3 / 1000
              };
      }
    })();

    const numbers = [[convertedTop], [convertedLeft, convertedMiddle, convertedRight]];

    const strings = [
      [translate.units.numberOfKm(total / 1000)],
      [
        part1 % 1000 > 0
          ? translate.units.numberOfM(part1)
          : translate.units.numberOfKm(part1 / 1000),
        part2 % 1000 > 0
          ? translate.units.numberOfM(part2)
          : translate.units.numberOfKm(part2 / 1000),
        part3 % 1000 > 0
          ? translate.units.numberOfM(part3)
          : translate.units.numberOfKm(part3 / 1000)
      ]
    ];

    const { answerIndices, inputMaxCharacters, postAnswerString } = (() => {
      switch (answerPosition) {
        case 'top':
          return {
            answerIndices: [[0], []],
            inputMaxCharacters: convertedTop.toString().length,
            postAnswerString: translate.units.km()
          };
        case 'bottom left':
          return {
            answerIndices: [[], [0]],
            inputMaxCharacters: convertedLeft.toString().length,
            postAnswerString: convertedLeft % 1000 > 0 ? translate.units.m() : translate.units.km()
          };
        case 'bottom middle':
          return {
            answerIndices: [[], [1]],
            inputMaxCharacters: convertedMiddle.toString().length,
            postAnswerString:
              convertedMiddle % 1000 > 0 ? translate.units.m() : translate.units.km()
          };
        case 'bottom right':
          return {
            answerIndices: [[], [2]],
            inputMaxCharacters: convertedRight.toString().length,
            postAnswerString: convertedRight % 1000 > 0 ? translate.units.m() : translate.units.km()
          };
      }
    })();

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        strings={strings}
        answerIndices={answerIndices}
        total={convertedTop}
        postAnswerString={postAnswerString}
        oneFontSize
        inputMaxCharacters={inputMaxCharacters}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLp',
  description: 'aLp',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Equivalent'],
  schema: z
    .object({
      m1: z.number().int().min(200).max(900).step(100),
      m2: z.number().int().min(200).max(900).step(100),
      m3: z.number().int().min(500).max(1900).step(100),
      m4: z.number().int().min(1100).max(4900).step(100)
    })
    .refine(val => val.m1 + val.m2 > 1000, 'm1 + m2 > 1000'),
  simpleGenerator: () => {
    const m1 = randomIntegerInclusiveStep(200, 900, 100);
    const m2 = randomIntegerInclusiveStep(1100 - m1, 900, 100);
    const m3 = randomIntegerInclusiveStep(500, 1900, 100);
    const m4 = randomIntegerInclusiveStep(1100, 4900, 100);

    return { m1, m2, m3, m4 };
  },

  Component: ({ question: { m1, m2, m3, m4 }, translate }) => {
    const ansKm1 = Math.floor((m1 + m2) / 1000);
    const ansM1 = (m1 + m2) % 1000;

    const ansKm2 = Math.floor((m3 + m4) / 1000);
    const ansM2 = (m3 + m4) % 1000;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        testCorrect={[
          [ansKm1.toString(), ansM1.toString()],
          [ansKm2.toString(), ansM2.toString()]
        ]}
        actionPanelVariant="bottomTall"
        sentences={[
          `${translate.units.numberOfM(m1)} ${ADD} ${translate.units.numberOfM(
            m2
          )} = ${translate.units.numberOfM(
            m1 + m2
          )} = ${translate.answerSentences.ansKm()} ${translate.answerSentences.ansM()}`,
          `${translate.units.numberOfM(m3)} ${ADD} ${translate.units.numberOfM(
            m4
          )} = ${translate.units.numberOfM(
            m3 + m4
          )} = ${translate.answerSentences.ansKm()} ${translate.answerSentences.ansM()}`
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentLengthsKilometresAndMetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
