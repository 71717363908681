import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { useContext, useMemo } from 'react';
import { MarkupAssetsContext, parseMarkup, renderMarkup } from '../../markup';
import { Theme } from '../../theme';
import { DisplayMode } from '../../contexts/displayMode';

type Props = {
  /**
   * The sentence, using our markup language where <ans/> indicates an answer box.
   *
   * See {@link parseMarkup} for more information on how the markup language works.
   */
  sentence: string;
  /** What to display when encountering an <ans i='{index}'/>. */
  inputBox?: (props: { index: number }) => JSX.Element;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textVariant?: keyof Theme['fonts'];
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
};

/**
 * Helper component for parsing a sentence of our markup language, and producing the layout.
 *
 * See {@link parseMarkup} for more information on how the markup language works.
 */
export default function TextStructure({
  sentence,
  inputBox,
  style,
  textStyle,
  textVariant,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle
}: Props) {
  const displayMode = useContext(DisplayMode);
  const markupAssets = useContext(MarkupAssetsContext);
  return useMemo(
    () =>
      renderMarkup(
        parseMarkup(sentence).tokens,
        {
          inputBox,
          style,
          textStyle,
          textVariant,
          fractionContainerStyle,
          fractionDividerStyle,
          fractionTextStyle,
          displayMode
        },
        markupAssets
      ),
    [
      fractionContainerStyle,
      fractionDividerStyle,
      fractionTextStyle,
      inputBox,
      markupAssets,
      sentence,
      style,
      textStyle,
      textVariant,
      displayMode
    ]
  );
}
