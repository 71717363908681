import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { filledArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bak',
  description: 'bak',
  keywords: ['Counters', 'Ten frame'],
  schema: z
    .object({
      animal: z.enum(['Sheep', 'Horse', 'Cow']),
      answer: z.number().int().min(3).max(10),
      incorrectA: z.number().int().min(2).max(10),
      arrangementSeed: z.number().int().min(1).max(1000)
    })
    .refine(
      ({ answer, incorrectA }) => answer !== incorrectA,
      'Answer and incorrectA cannot be the same'
    ),
  simpleGenerator: () => {
    const animal = getRandomFromArray(['Sheep', 'Horse', 'Cow'] as const);
    const arrangementSeed = randomIntegerInclusive(1, 1000);
    const answer = randomIntegerInclusive(3, 10);
    const incorrectA = answer === 10 ? 9 : getRandomFromArray([answer - 1, answer + 1]);
    return { animal, answer, incorrectA, arrangementSeed };
  },
  Component: ({
    question: { animal, answer, incorrectA, arrangementSeed },
    translate,
    displayMode
  }) => {
    // TODO add pigs, goats, cats, and dogs when we have the artwork
    const animalSvgPaths = {
      Sheep: 'Sheep' as const,
      Horse: 'Horse' as const,
      Cow: 'Cow' as const
    };

    const rows = 2;
    const cols = 6;

    const random = seededRandom({ arrangementSeed });
    const arrangementA = getRandomBooleanArray(rows, cols, answer, random);

    const items = shuffle(
      [
        {
          value: answer,
          component: (
            <TenFrameLayout
              items={filledArray('red', answer)}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          )
        },
        {
          value: incorrectA,
          component: (
            <TenFrameLayout
              items={filledArray('red', incorrectA)}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          )
        }
      ],
      { random }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTenFrameThatMatchesThePicture()}
        pdfTitle={translate.ks1PDFInstructions.whichTenFrameMatchesThePictureSelectYourAnswer()}
        testCorrect={[answer]}
        numItems={2}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            {arrangementA.map((row, rowIndex) => (
              <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                {row.map((cell, colIndex) => (
                  <View
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      width: dimens.width / cols,
                      height: dimens.height / rows,
                      padding: 8
                    }}
                  >
                    {cell ? (
                      <AssetSvg
                        name={animalSvgPaths[animal]}
                        height={dimens.height / rows - 16}
                        width={dimens.width / cols - 16}
                      />
                    ) : (
                      <></>
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
        renderItems={items}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentObjects',
  questionTypes: [Question2],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
