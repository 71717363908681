import { View, type ViewStyle, type StyleProp, Pressable, StyleSheet } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange
} from 'common/src/utils/collections';
import { useTheme } from 'common/src/theme';
import { resolveThingOrFunction, type SetState, type ThingOrFunction } from '../../../utils/react';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import React, { type ComponentProps, useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { AssetSvg } from '../../../assets/svg';
import { getPlayer } from 'common/src/utils/Audio';
import { withStateHOC } from '../../../stateTree';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import deepEqual from 'react-fast-compare';

type ItemInfo<T> = {
  component: JSX.Element;
  /** Must be unique amongst the items. */
  value: T;
};

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * If false, selecting one item deselects all others, so the user answer is a set of at most 1 value.
   * Default: false.
   */
  multiSelect?: boolean;

  /**
   * If true, selecting 0 items counts as a complete answer.
   * Default: false.
   */
  emptyIsValid?: boolean;

  /** Show the border on PDF */
  pdfShowBorder?: boolean;

  /**
   * Initial selection state.
   * Note: order is irrelevant. This must not contain duplicates (using `isEqual` to compare).
   * Default: none selected.
   */
  initialState?: T[];

  outerContainerStyle?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  /** Layout for the selectable items. Optional prop, defaults to 'grid'. */
  itemLayout?: 'grid' | 'column' | 'row';

  /** The number of items to show. */
  numItems: 2 | 3 | 4 | 6 | 8;

  /**
   * The items to show. Each item gets the same dimensions. The items will be rendered in order top-left, top-right,
   * bottom-left, bottom-right - with the bottom row reduced to one item in the case of 3 total items, or
   * nonexistent in the case of 2 total items.
   *
   * Length of this array must match `numItems`.
   */
  renderItems: ThingOrFunction<ItemInfo<T>[], [{ dimens: Dimens }]>;

  /** How to compare two items for equality. Defaults to deep equality AKA structural equality. */
  isEqual?: (x: T, y: T) => boolean;

  /** Either an array showing the correct answer, or a function if there are multiple correct answers. */
  testCorrect: T[] | ((ans: T[]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: T[]; answerText?: string };
};

/**
 * Question Format 11: Select Images (2, 3, 4, 6 or 8).
 *
 * Title at the top.
 */
export default function QF11SelectImagesUpTo4<T>({
  title,
  pdfTitle,
  testCorrect,
  numItems,
  renderItems,
  isEqual = deepEqual,
  multiSelect = false,
  emptyIsValid = false,
  initialState = [],
  outerContainerStyle,
  innerContainerStyle,
  itemStyle,
  itemLayout = 'grid',
  questionHeight,
  customMarkSchemeAnswer,
  pdfShowBorder = false,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  // Throw error if initial state has duplicates
  if (!arrayHasNoDuplicates(initialState, isEqual)) {
    throw Error(`Initial state has duplicates: ${JSON.stringify(initialState)}`);
  }

  const SelectableContainerWithState = getSelectableContainerWithState<T>();

  const selectableSpacing = displayMode === 'digital' ? 24 : 64;

  const itemsPerRow =
    itemLayout === 'column'
      ? 1
      : itemLayout === 'grid' && numItems > 2
      ? Math.ceil(numItems / 2)
      : numItems;
  const itemsPerCol =
    itemLayout === 'column' ? numItems : itemLayout === 'grid' && numItems > 2 ? 2 : 1;

  const itemContainerDimens = (dimens: Dimens) => {
    // Give a gap of 24 and 2 selectables can fit in a row
    const width = (dimens.width - (selectableSpacing * itemsPerRow - 1)) / itemsPerRow;
    const height = (dimens.height - (selectableSpacing * itemsPerCol - 1)) / itemsPerCol;
    return { width, height } as const;
  };

  const selectableContainerProps = {
    multiSelect,
    itemLayout,
    itemStyle,
    outerContainerStyle,
    innerContainerStyle,
    renderItems,
    isEqual,
    itemContainerDimens,
    numItems,
    itemsPerRow
  };

  if (displayMode !== 'digital') {
    const markSchemeAnswer =
      typeof testCorrect !== 'function' && displayMode === 'markscheme'
        ? testCorrect
        : customMarkSchemeAnswer?.answerToDisplay;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={{ paddingLeft: 10 }}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <SelectableContainer
                  userAnswer={markSchemeAnswer ?? []}
                  dimens={dimens}
                  {...selectableContainerProps}
                  pdfShowBorder={pdfShowBorder}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <SelectableContainerWithState
              id="selection"
              defaultState={initialState}
              testCorrect={answer =>
                typeof testCorrect === 'function'
                  ? testCorrect(answer)
                  : arraysHaveSameContentsUnordered(answer, testCorrect, isEqual)
              }
              testComplete={answer => emptyIsValid || answer.length > 0}
              dimens={dimens}
              {...selectableContainerProps}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}

function SelectableContainer<T>({
  userAnswer,
  setUserAnswer,
  multiSelect,
  itemStyle,
  itemLayout,
  outerContainerStyle,
  innerContainerStyle,
  renderItems,
  isEqual,
  itemContainerDimens,
  numItems,
  itemsPerRow,
  pdfShowBorder,
  dimens
}: {
  userAnswer: T[];
  setUserAnswer?: SetState<T[]>;
  itemStyle?: StyleProp<ViewStyle>;
  pdfShowBorder?: boolean;
  itemLayout: 'grid' | 'column' | 'row';
  outerContainerStyle?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  multiSelect: boolean;
  renderItems: ThingOrFunction<ItemInfo<T>[], [{ dimens: Dimens }]>;
  isEqual: (x: T, y: T) => boolean;
  itemContainerDimens: (dimens: Dimens) => Dimens;
  numItems: 2 | 3 | 4 | 6 | 8;
  itemsPerRow: number;
  dimens: Dimens;
}) {
  const displayMode = useContext(DisplayMode);
  const player = getPlayer();

  const rowWidth = dimens.width;
  const { width: selectableWidth, height: selectableHeight } = itemContainerDimens(dimens);

  const styles = useStyles(rowWidth, selectableWidth, selectableHeight, numItems, pdfShowBorder);

  const items = resolveThingOrFunction(renderItems, {
    dimens: { width: selectableWidth, height: selectableHeight }
  });

  const selectableItem = (item: ItemInfo<T> | undefined) => {
    const selected = item && userAnswer.some(answerValue => isEqual(answerValue, item.value));

    return (
      item && (
        <Pressable
          style={[styles.selectable, selected && styles.selected, itemStyle, {}]}
          onPress={() => {
            if (userAnswer && setUserAnswer) {
              if (userAnswer.some(answerValue => isEqual(answerValue, item.value))) {
                // Unselect this item
                player.playSound('unselect');
                setUserAnswer(userAnswer.filter(value => !isEqual(value, item.value)));
              } else {
                // Select this item
                player.playSound('select');
                multiSelect
                  ? setUserAnswer([...userAnswer, item.value])
                  : setUserAnswer([item.value]);
              }
            }
          }}
        >
          {displayMode === 'markscheme' && userAnswer?.includes(item.value) && (
            <AssetSvg
              name={'True'}
              width={50}
              style={{ zIndex: 999, position: 'absolute', top: 15, right: 30 }}
            />
          )}
          {item.component}
        </Pressable>
      )
    );
  };

  const secondRowGrid = itemLayout === 'grid' && numItems > 2 ? true : false;
  return (
    <View style={[styles.container, outerContainerStyle]}>
      <View style={[itemLayout === 'column' ? styles.column : styles.row, innerContainerStyle]}>
        {countRange(itemLayout === 'grid' ? itemsPerRow : numItems).map(i => (
          <React.Fragment key={i}>{selectableItem(items[i])}</React.Fragment>
        ))}
      </View>
      {secondRowGrid && (
        <View style={[styles.row, innerContainerStyle]}>
          {countRange(itemsPerRow, itemsPerRow).map(i => (
            <React.Fragment key={i}>{selectableItem(items[i])}</React.Fragment>
          ))}
        </View>
      )}
    </View>
  );
}

type SelectableContainerWithState<T> = ReturnType<
  typeof withStateHOC<ComponentProps<typeof SelectableContainer<T>>, 'userAnswer', 'setUserAnswer'>
>;

const SelectableContainerWithStateNonGeneric = withStateHOC(SelectableContainer, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer'
});

/** Get the StateTree version of SelectableContainer. There's no need to use useMemo with this. */
function getSelectableContainerWithState<T>() {
  // This cast simply changes the generic parameter from unknown to T, which is safe.
  return SelectableContainerWithStateNonGeneric as SelectableContainerWithState<T>;
}

const useStyles = (
  rowWidth: number,
  width: number,
  height: number,
  numItems: number,
  pdfShowBorder?: boolean
) => {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();

  return StyleSheet.create({
    container: {
      rowGap: displayMode === 'digital' ? 24 : 64
    },
    column: {
      width: rowWidth,
      height: height * numItems,
      display: 'flex',
      alignItems: 'center'
    },
    row: {
      width: rowWidth,
      height: height,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      columnGap: displayMode === 'digital' ? 24 : 64
    },
    selectable: {
      width,
      height,
      borderRadius: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: theme.buttonBorderWidth,
      borderColor:
        displayMode === 'digital'
          ? theme.colors.tertiary
          : pdfShowBorder
          ? theme.colors.tertiary
          : 'transparent',
      backgroundColor: theme.colors.background
    },
    selected: {
      backgroundColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.background,
      borderColor:
        displayMode === 'digital'
          ? theme.colors.tertiary
          : displayMode === 'markscheme'
          ? theme.colors.pdfPrimary
          : 'transparent'
    }
  });
};
