import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { ADD } from 'common/src/constants';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  fractionArithmetic,
  fractionToDecimal,
  simplify
} from 'common/src/utils/fractions';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { filledArray } from '../../../../utils/collections';
import { BarModelInteractiveWithFixedCellsWithState } from '../../../../components/question/representations/BarModelInteractiveWithFixedCells';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apX',
  description: 'apX',
  keywords: ['Part-whole', 'Addition', 'Fractions'],
  schema: z.object({
    answerWhole: z.number().int().min(1).max(12),
    answerDenom: z.number().int().min(2).max(9),
    answerNum: z.number().int().min(1).max(8),
    isAFrac: z.boolean()
  }),
  simpleGenerator: () => {
    const answerWhole = randomIntegerInclusive(1, 12);
    const answerDenom = randomIntegerInclusive(2, 9);
    const answerNum = randomIntegerInclusive(1, answerDenom - 1);

    // Used to randomise which position the integer & fraction are as answers
    const isAFrac = getRandomBoolean();

    return { answerWhole, answerDenom, answerNum, isAFrac };
  },
  Component: props => {
    const {
      question: { answerWhole, answerDenom, answerNum, isAFrac },
      translate
    } = props;

    let sentences: string[] = [];

    if (isAFrac) {
      sentences = [`A = <frac nAns='' dAns=''/>`, 'B = <ans/>'];
    } else {
      sentences = ['A = <ans/>', `B = <frac nAns='' dAns=''/>`];
    }

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completePartWholeModel()}
        sentences={sentences}
        testCorrect={userAnswer => {
          return isAFrac
            ? compareFractions([...userAnswer[0]], [answerNum, answerDenom]) &&
                userAnswer[1][0] === answerWhole.toString()
            : userAnswer[0][0] === answerWhole.toString() &&
                compareFractions([...userAnswer[1]], [answerNum, answerDenom]);
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <PartWholeModel
            top={`<frac w='${answerWhole}' n='${answerNum}' d='${answerDenom}'/>`}
            partition={['B', 'A']}
            representation="text"
            dimens={dimens}
            fractionTextStyle={{ fontSize: 28 }}
          />
        )}
        style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}
        customMarkSchemeAnswer={{
          answersToDisplay: isAFrac
            ? [
                [answerNum.toLocaleString(), answerDenom.toLocaleString()],
                [answerWhole.toLocaleString()]
              ]
            : [
                [answerWhole.toLocaleString()],
                [answerNum.toLocaleString(), answerDenom.toLocaleString()]
              ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'apY',
  description: 'apY',
  keywords: ['Addition', 'Mixed number', 'Whole number'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(5),
    number3: z.number().int().min(2).max(10),
    number4: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);
    const number2 = randomIntegerInclusive(1, 5);
    const number3 = randomIntegerInclusive(2, 10);
    const number4 = randomIntegerInclusive(1, number3 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answer1 = number1 + number2;

    // Question asks for fraction to be in simplest form
    const [simplifiedNumber4, simplifiedNumber3] = simplify(number4, number3);

    const correctFrac1 = [answer1, simplifiedNumber4, simplifiedNumber3];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.useWorkingToAdd(
          number1,
          `<frac w='${number2.toLocaleString()}' n='${simplifiedNumber4.toLocaleString()}' d='${simplifiedNumber3.toLocaleString()}'/>`
        )}
        testCorrect={userAnswer => compareFractions(userAnswer, correctFrac1)}
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${ADD} <frac n='${simplifiedNumber4.toLocaleString()}' d='${simplifiedNumber3.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        textStyle={{ fontSize: 40 }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedNumber4.toLocaleString(),
            simplifiedNumber3.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apZ',
  description: 'apZ',
  keywords: ['Integer', 'Mixed number', 'Addition'],
  schema: z.object({
    number1: z.number().int().min(3).max(9),
    number2: z.number().int().min(3).max(9),
    number3: z.number().int().min(2).max(10),
    number4: z.number().int().min(1).max(9),
    showWholeNumberFirst: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 9);
    const number2 = randomIntegerInclusive(3, 9);
    const number3 = randomIntegerInclusive(2, 10);
    const number4 = randomIntegerInclusive(1, number3 - 1);

    const showWholeNumberFirst = getRandomBoolean();

    return {
      number1,
      number2,
      number3,
      number4,
      showWholeNumberFirst
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, showWholeNumberFirst },
      translate
    } = props;

    const answer1 = number1 + number2;

    const correctFrac1 = [answer1, number4, number3];

    // Question asks for certain fractions to be simplified
    const [simplifiedNumber4, simplifiedNumber3] = simplify(number4, number3);

    const sentence = showWholeNumberFirst
      ? `${number1.toLocaleString()} ${ADD} <frac w='${number2.toLocaleString()}' n='${simplifiedNumber4.toLocaleString()}' d='${simplifiedNumber3.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`
      : `<frac w='${number1.toLocaleString()}' n='${simplifiedNumber4.toLocaleString()}' d='${simplifiedNumber3.toLocaleString()}'/> ${ADD} ${number2.toLocaleString()} = <frac wAns='' nAns='' dAns=''/>`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctFrac1)}
        inputMaxCharacters={2}
        sentence={sentence}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac1.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ap0',
  description: 'ap0',
  keywords: ['Mixed number', 'Addition', 'Fraction'],
  schema: z.object({
    number2: z.number().int().min(3).max(7),
    number3: z.number().int().min(1).max(6),
    number4: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const { number2, number3, number4 } = rejectionSample(
      () => {
        const number2 = getRandomFromArray([3, 5, 7]);
        const number3 = randomIntegerInclusive(1, number2 - 1);
        const number4 = randomIntegerInclusive(1, number2 - 1);

        return { number2, number3, number4 };
      },
      ({ number2, number3, number4 }) => number3 + number4 < number2
    );

    return { number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number2, number3, number4 },
      translate
    } = props;

    const number1 = 1;

    // Answers
    const answer1 = number1;
    const answer2 = number2;
    const answer3 = number3 + number4;

    const bars = [
      { rows: number1, cols: number2, fixedCells: { min: 0, max: number2 } },
      { rows: number1, cols: number2, fixedCells: { min: 0, max: number3 - 1 } }
    ];

    const sentence = `<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${ADD} <frac n='${number4.toLocaleString()}' d='${number2.toLocaleString()}'/> = <ans /> <frac nAns='' dAns=''/>`;

    const fixedCells = [
      filledArray(true, number2),
      [...filledArray(true, number3), ...filledArray(false, number2 - number3)]
    ];

    const correctFrac1 = [answer1, answer3, answer2];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpCompleteTheAddition()}
        testCorrect={answer => compareFractions(answer, correctFrac1)}
        sentence={sentence}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac1.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens: { width } }) => (
          <View style={{ rowGap: 32 }}>
            {bars.map((bar, idx) => (
              <BarModelInteractiveWithFixedCellsWithState
                id={`barmodel-${idx}`}
                key={`barmodel-${idx}`}
                numberOfRows={bar.rows}
                numberOfCols={bar.cols}
                fixedCells={[fixedCells[idx]]}
                tableHeight={100}
                tableWidth={width}
                fixedCellsColor="barYellow3"
                interactiveCellsColor="barGreen1"
              />
            ))}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'ap1',
  description: 'ap1',
  keywords: ['Addition', 'Mixed number', 'Fraction'],
  schema: z.object({
    fractionNumbers: z.array(z.number().int().min(1).max(20)).length(4),
    isMixedFractionFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const useSmallerNumbers = getRandomBoolean();
    const number1 = useSmallerNumbers
      ? randomIntegerInclusive(1, 9)
      : randomIntegerInclusive(10, 20);
    const number2 = useSmallerNumbers
      ? getRandomFromArray([3, 5, 7, 11] as const)
      : getRandomFromArray([11, 13, 17, 19] as const);
    const isMixedFractionFirst = getRandomBoolean();

    const { number3, number4 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(1, number2 - 1);
        const number4 = randomIntegerInclusive(1, number2 - 1);

        return { number3, number4 };
      },
      ({ number3, number4 }) => number3 + number4 < number2
    );

    const fractionNumbers = [number1, number2, number3, number4];

    return {
      fractionNumbers,
      isMixedFractionFirst
    };
  },
  Component: props => {
    const {
      question: { fractionNumbers, isMixedFractionFirst },
      translate
    } = props;

    const [wholeNumber, denominator, numeratorA, numeratorB] = fractionNumbers;

    const answerNumerator = numeratorA + numeratorB;

    const correctFrac1 = [wholeNumber, answerNumerator, denominator];

    const sentence = isMixedFractionFirst
      ? `<frac w='${wholeNumber}' n='${numeratorA}' d='${denominator}'/> ${ADD} <frac n='${numeratorB}' d='${denominator}'/> = <frac wAns='' nAns='' dAns=''/>`
      : `<frac n='${numeratorA}' d='${denominator}'/> ${ADD} <frac w='${wholeNumber}' n='${numeratorB}' d='${denominator}'/> = <frac wAns='' nAns='' dAns=''/>`;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer => compareFractions(userAnswer[0], correctFrac1)}
        inputMaxCharacters={2}
        sentences={[sentence]}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctFrac1.map(num => num.toLocaleString())],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ap2',
  description: 'ap2',
  keywords: ['Mixed number', 'Fractions', 'Addition'],
  schema: z
    .object({
      whole: z.number().int().min(1).max(9),
      numeratorA: z.number().int().min(1).max(3),
      denominatorA: z.number().int().min(2).max(8),
      numeratorB: z.number().int().min(1).max(5),
      denominatorB: z.number().int().min(4).max(20)
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.')
    .refine(
      val => val.denominatorB % val.denominatorA === 0 && val.denominatorB !== val.denominatorA,
      'denominatorB must be a multiple of denominatorA, and not be equal to denominatorA.'
    )
    .refine(val => {
      const fractionTotal = fractionArithmetic(
        [val.numeratorA, val.denominatorA],
        [val.numeratorB, val.denominatorB],
        ADD
      );

      return fractionToDecimal(fractionTotal[0], fractionTotal[1]) < 1;
    }, '(numeratorA/denominatorA) + (numeratorB / denominatorB) must total to less than a whole.'),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 9);

    const { numeratorA, denominatorA, numeratorB, denominatorB } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusive(1, 3);

        const denominatorA = randomIntegerInclusive(numeratorA + 1, 8);

        const numeratorB = randomIntegerInclusive(1, 5);

        const denominatorB = randomIntegerInclusive(Math.max(numeratorB + 1, 4), 20, {
          constraint: x => x % denominatorA === 0 && x !== denominatorA
        });

        return { numeratorA, denominatorA, numeratorB, denominatorB };
      },
      ({ numeratorA, denominatorA, numeratorB, denominatorB }) => {
        const fractionTotal = fractionArithmetic(
          [numeratorA, denominatorA],
          [numeratorB, denominatorB],
          ADD
        );

        return fractionToDecimal(fractionTotal[0], fractionTotal[1]) < 1;
      }
    );

    return { whole, numeratorA, denominatorA, numeratorB, denominatorB };
  },
  Component: props => {
    const {
      question: { whole, numeratorA, denominatorA, numeratorB, denominatorB },
      translate
    } = props;

    const expectedFractionAnswer = fractionArithmetic(
      [numeratorA, denominatorA],
      [numeratorB, denominatorB],
      ADD
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          userAnswer[0] === whole.toString() &&
          compareFractions([userAnswer[1], userAnswer[2]], expectedFractionAnswer)
        }
        inputMaxCharacters={3}
        sentence={`<frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${ADD} <frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions(),
          answersToDisplay: [
            whole.toLocaleString(),
            expectedFractionAnswer[0].toLocaleString(),
            expectedFractionAnswer[1].toLocaleString()
          ]
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddToAMixedNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
