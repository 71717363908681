// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import RomanNumeralsTo1000 from './1RomanNumeralsTo1000';
import NumbersTo10000 from './2NumbersTo10000';
import NumbersTo100000 from './3NumbersTo100000';
import NumbersTo1000000 from './4NumbersTo1000000';
import ReadAndWriteNumbersTo1000000 from './5ReadAndWriteNumbersTo1000000';
import PowersOf10 from './6PowersOf10';
import To100000MoreOrLess from './7To100000MoreOrLess';
import PartitionNumbersTo1000000 from './8PartitionNumbersTo1000000';
import NumberLineTo1000000 from './9NumberLineTo1000000';
import CompareAndOrderNumbersTo100000 from './10CompareAndOrderNumbersTo100000';
import CompareAndOrderNumbersTo1000000 from './11CompareAndOrderNumbersTo1000000';
import RoundToTheNearest10100Or1000 from './12RoundToTheNearest10100Or1000';
import RoundWithin100000 from './13RoundWithin100000';
import RoundWithin1000000 from './14RoundWithin1000000';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    RomanNumeralsTo1000, // Step 1
    NumbersTo10000, // Step 2
    NumbersTo100000, // Step 3
    NumbersTo1000000, // Step 4
    ReadAndWriteNumbersTo1000000, // Step 5
    PowersOf10, // Step 6
    To100000MoreOrLess, // Step 7
    PartitionNumbersTo1000000, // Step 8
    NumberLineTo1000000, // Step 9
    CompareAndOrderNumbersTo100000, // Step 10
    CompareAndOrderNumbersTo1000000, // Step 11
    RoundToTheNearest10100Or1000, // Step 12
    RoundWithin100000, // Step 13
    RoundWithin1000000 // Step 14
  ]
});
export default Block;
