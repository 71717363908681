import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  rejectionSample,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { numberEnum } from 'common/src/utils/zod';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { findFactors } from 'common/src/utils/factors';
import { getRandomSport, sportSchema, sportAsWord } from 'common/src/utils/sports';
import { objectSchema, objectAsWord, getRandomObject } from 'common/src/utils/objects';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { ADD, DIV } from '../../../../constants';
import QF9DragIntoTableOfGroups from '../../../../components/question/questionFormats/QF9DragIntoTableOfGroups';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import { arraysHaveSameContents } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arS',
  description: 'arS',
  keywords: ['Partitioning', 'Division', 'Factor'],
  schema: z.object({
    number1: z.number().int().min(3).max(19),
    number2: z.number().int().min(2).max(90),
    number4: z.number().int().min(10).max(900)
  }),
  simpleGenerator: () => {
    const variant = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    let number1, number2, number4;

    switch (variant) {
      case 'A':
        number1 = randomIntegerInclusive(3, 9);
        number2 = randomIntegerInclusive(2, 12);
        number4 = randomIntegerInclusiveStep(100, 900, 100);
        break;
      case 'B':
        number1 = randomIntegerInclusive(3, 9);
        number2 = randomIntegerInclusiveStep(20, 90, 10);
        number4 = randomIntegerInclusiveStep(100, 900, 100);
        break;
      case 'C':
        number1 = randomIntegerInclusive(11, 12);
        number2 = randomIntegerInclusive(2, 9);
        number4 = randomIntegerInclusiveStep(10, 120, 10);
        break;
      case 'D':
        number1 = randomIntegerInclusive(13, 19);
        number2 = randomIntegerInclusive(2, 5);
        number4 = randomIntegerInclusiveStep(10, 50, 10);
        break;
    }

    return { number1, number2, number4 };
  },
  Component: ({ question: { number1, number2, number4 }, translate }) => {
    const number3 = number1 * number2;
    const number5 = number4 * number1;
    const number6 = number5 + number3;
    const number7 = number6 / number1;

    const lhs = [
      `${number3.toLocaleString()} ${DIV} ${number1.toLocaleString()}`,
      `${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()}`,
      `${number6.toLocaleString()} ${DIV} ${number1.toLocaleString()}`
    ];
    const rhs = ['<ans/>', '<ans/>', `<ans/> ${ADD} <ans/> = <ans/>`];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeWorkingsToCalculateX(
          `${number6.toLocaleString()} ${DIV} ${number1.toLocaleString()}`
        )}
        leftSide={lhs}
        rightSide={rhs}
        inputMaxCharacters={2}
        testCorrect={({ right }) =>
          arraysHaveSameContents(right[0], [number2.toString()]) &&
          arraysHaveSameContents(right[1], [number4.toString()]) &&
          (arraysHaveSameContents(right[2], [
            number2.toString(),
            number4.toString(),
            number7.toString()
          ]) ||
            arraysHaveSameContents(right[2], [
              number4.toString(),
              number2.toString(),
              number7.toString()
            ]))
        }
        customMarkSchemeAnswer={{
          answersToDisplay: {
            right: [
              [number2.toLocaleString()],
              [number4.toLocaleString()],
              [number2.toLocaleString(), number4.toLocaleString(), number7.toLocaleString()]
            ]
          }
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arT',
  description: 'arT',
  keywords: ['Divide', 'Money'],
  schema: z.object({
    number1: z.number().int().min(3).max(9),
    number2: numberEnum([10, 20, 50]),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 9);
    const number2 = getRandomFromArray([10, 20, 50] as const);
    const name = getRandomName();

    return { number1, number2, name };
  },
  Component: props => {
    const {
      question: { number1, number2, name },
      translate
    } = props;

    // Answer
    const answer = ((number1 * 100) / number2).toString();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasSavedXinYPenceCoinsHowManyYPenceCoinsDoesCharacterHave(
          {
            character: name,
            number1,
            number2
          }
        )}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        testCorrect={[answer]}
        sentence={'<ans/>'}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arU',
  description: 'arU',
  keywords: ['Divide', 'Multi-step'],
  schema: z
    .object({
      number1: z.number().int().min(4).max(12),
      number2: z.number().int().min(4).max(12),
      number3: z.number().int().min(101).max(999),
      object: objectSchema
    })
    .refine(val => val.number2 !== val.number1, 'number2 must be different than number1')
    .refine(
      val => val.number3 % val.number1 === 0 && val.number3 % val.number2 === 0,
      'number3 must be a multiple of number1 and number2'
    ),
  simpleGenerator: () => {
    const object = getRandomObject();

    const { number3, factors } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(101, 999);

        // Find factors of number3 based on number1/number2 ranges
        const factors = findFactors(number3).filter(factor => factor >= 4 && factor <= 12);

        return { number3, factors };
      },
      val => val.factors.length >= 2
    );

    const [number1, number2] = getRandomSubArrayFromArray(factors, 2);

    return { number1, number2, number3, object };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, object },
      translate
    } = props;

    const objectPlural = objectAsWord(object, translate, true);

    // Answers
    const answer1 = (number3 / number1).toString();
    const answer2 = (number3 / number2).toString();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.itemsArePackedInBoxesOfEitherXorYHowManyOfEachTypeOfBoxAreNeeded(
          {
            objects: objectPlural,
            number1,
            number2,
            number3
          }
        )}
        testCorrect={[[answer1], [answer2]]}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        pdfMainPanelContainerStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        sentences={[
          translate.answerSentences.ansBoxesOfNumber(number1),
          translate.answerSentences.ansBoxesOfNumber(number2)
        ]}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arV',
  description: 'arV',
  keywords: ['Divide', 'Remainders'],
  schema: z.object({
    number1: z.number().int().min(20).max(60),
    number2: z.number().int().min(101).max(999),
    sport: sportSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(20, 60);
    const number2 = randomIntegerInclusive(101, 999, {
      constraint: x => x % number1 !== 0
    });
    const sport = getRandomSport();

    return { number1, number2, sport };
  },
  Component: props => {
    const {
      question: { number1, number2, sport },
      translate
    } = props;

    // Answer
    const answer = Math.ceil(number2 / number1).toString();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aCoachCanSeatXPeopleHowManyCoachesAreNeeded({
          number1,
          number2,
          sport: sportAsWord(sport, translate)
        })}
        testCorrect={[answer]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arW',
  description: 'arW',
  keywords: ['Divide', 'Remainders'],
  schema: z.object({
    sums: z
      .array(
        z
          .array(z.number().int().min(13).max(999))
          .length(2)
          .refine(
            ([dividend, divisor]) => dividend >= divisor,
            'First number (dividend) must be greater than the second number (divisor).'
          )
      )
      .length(4),
    remainderThreshold: numberEnum([6, 7, 8])
  }),
  simpleGenerator: () => {
    const remainderThreshold = getRandomFromArray([6, 7, 8] as const);

    const [number1, number4] = randomUniqueIntegersInclusive(101, 999, 2);

    const [number2, number3] = randomUniqueIntegersInclusive(13, 19, 2, {
      constraint: x => number1 % x > remainderThreshold || number1 % x < remainderThreshold
    });
    const [number5, number6] = randomUniqueIntegersInclusive(13, 19, 2, {
      constraint: x => number4 % x > remainderThreshold || number4 % x < remainderThreshold
    });

    const sums = [
      [number1, number2],
      [number1, number3],
      [number4, number5],
      [number4, number6]
    ];

    return { sums, remainderThreshold };
  },
  Component: ({ question: { sums, remainderThreshold }, translate }) => {
    const correctAnswer = [
      [[], []],
      [[], []]
    ] as [
      [lessThanOdd: number[], lessThanEven: number[]],
      [greaterThanOdd: number[], greaterThanEven: number[]]
    ];

    sums.forEach(([dividend, divisor], sumIndex) => {
      const remainder = dividend % divisor;

      // Determine which row and column the answer will be in
      if (remainder < remainderThreshold) {
        const rowIndex = 0;
        const columnIndex = remainder % 2 === 0 ? 1 : 0;
        correctAnswer[rowIndex][columnIndex].push(sumIndex);
      } else {
        const rowIndex = 1;
        const columnIndex = remainder % 2 === 0 ? 1 : 0;
        correctAnswer[rowIndex][columnIndex].push(sumIndex);
      }
    });

    return (
      <QF9DragIntoTableOfGroups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        rowNames={[
          translate.tableHeaders.remainderIsLessThanX(remainderThreshold),
          translate.tableHeaders.remainderIsGreaterThanX(remainderThreshold)
        ]}
        columnNames={[
          translate.tableHeaders.remainderIsOdd(),
          translate.tableHeaders.remainderIsEven()
        ]}
        tableHeaderMaxLines={2}
        tableHeaderStyle={{ fontSize: 20, lineHeight: 25 }}
        testCorrect={correctAnswer}
        items={sums.map(([dividend, divisor], sumIndex) => ({
          value: sumIndex,
          component: `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={1400}
        pdfTableHeaderStyle={{ fontSize: 34, lineHeight: 34 }}
      />
    );
  },
  questionHeight: 1400
});

const Question6 = newQuestionContent({
  uid: 'arX',
  description: 'arX',
  keywords: ['Complement', '100', 'Hundred', 'Match'],
  schema: z.object({
    sentenceBase: z.enum(['less than', 'greater than']),
    sentenceNum: numberEnum([10, 15, 25])
  }),
  simpleGenerator: () => {
    const sentenceBase = getRandomFromArray(['less than', 'greater than'] as const);
    let sentenceNum: 10 | 15 | 25;

    if (sentenceBase === 'greater than') {
      sentenceNum = getRandomFromArray([10, 15, 25] as const);
    } else {
      sentenceNum = 10;
    }

    return { sentenceBase, sentenceNum };
  },
  Component: props => {
    const {
      question: { sentenceBase, sentenceNum },
      translate
    } = props;

    const sentence = `<ans/> <ans/> <ans/> ${DIV} <ans/> <ans/>`;

    const answerOptions = [1, 2, 3, 4, 5];

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCreateDivisionWithRemainderX(
          sentenceBase,
          sentenceNum
        )}
        pdfTitle={translate.instructions.useCardsCreateDivisionWithRemainderXPDF(
          sentenceBase,
          sentenceNum
        )}
        items={answerOptions}
        sentence={sentence}
        testCorrect={userAnswer => {
          const firstNum = parseInt(userAnswer.slice(0, 3).join(''));
          const secondNum = parseInt(userAnswer.slice(3).join(''));

          const remainder = firstNum % secondNum;

          return sentenceBase === 'less than' ? remainder < sentenceNum : remainder > sentenceNum;
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerText:
            sentenceBase === 'less than'
              ? translate.markScheme.validDivisionWithRemainderLessThanX(sentenceNum)
              : translate.markScheme.validDivisionWithRemainderGreaterThanX(sentenceNum)
        }}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
