import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';
import { getIrregularShapeSvgName } from '../../../../utils/shapeImages/irregular';
import {
  compoundShapes,
  markedOneAngle,
  shapeInteriorAngles
} from '../../../../utils/shapeImages/angles';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DEGREES, MULT } from '../../../../constants';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import { LabelledOneAngle } from '../../../../components/question/representations/LabelledMarkedOneAngle';
import { compareFloats } from '../../../../utils/math';
import { aOrAnShapeAsWords } from '../../../../utils/shapes';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { sortNumberArray, sumNumberArray } from '../../../../utils/collections';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';

////
// Questions
////

const shapeOptions = [
  'pentagons',
  'hexagons',
  'heptagons',
  'octagons',
  'nonagons',
  'decagons',
  'squares',
  'rectangles'
] as const;
type ShapeOptions = (typeof shapeOptions)[number];

const Question1 = newQuestionContent({
  uid: 'aEE',
  description: 'aEE',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shape: z.enum([
      'squares',
      'rectangles',
      'pentagons',
      'hexagons',
      'heptagons',
      'octagons',
      'nonagons',
      'decagons'
    ]),
    flipX: z.boolean(),
    flipY: z.boolean()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'squares',
      'rectangles',
      'pentagons',
      'hexagons',
      'heptagons',
      'octagons',
      'nonagons',
      'decagons'
    ] as const);

    const flipX = getRandomBoolean();

    const flipY = getRandomBoolean();

    return { shape, flipX, flipY };
  },
  Component: props => {
    const {
      question: { shape, flipX, flipY },
      translate
    } = props;

    const shapeString =
      shape === 'squares' || shape === 'rectangles'
        ? translate.shapes.quadrilaterals(1)
        : translate.shapes[shape](1);

    const [shapeSvgPath, totalTriangles] = (() => {
      switch (shape) {
        case 'rectangles':
        case 'squares':
          return ['Shapes_with_interior_angles/Rectangle_with_interior_triangles', 2];
        case 'pentagons':
          return ['Shapes_with_interior_angles/Regular_pentagon_with_interior_triangles', 3];
        case 'hexagons':
          return ['Shapes_with_interior_angles/Regular_hexagon_with_interior_triangles', 4];
        case 'heptagons':
          return ['Shapes_with_interior_angles/Regular_heptagon_with_interior_triangles', 5];
        case 'octagons':
          return ['Shapes_with_interior_angles/Regular_octagon_with_interior_triangles', 6];
        case 'nonagons':
          return ['Shapes_with_interior_angles/Regular_nonagon_with_interior_triangles', 7];
        case 'decagons':
          return ['Shapes_with_interior_angles/Regular_decagon_with_interior_triangles', 8];
      }
    })();

    const totalDegrees = totalTriangles * 180;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theShapeHasBeenSplitIntoNumTrianglesCompleteSentences(
          shapeString,
          totalTriangles
        )}
        sentences={[
          `<ans/> ${MULT} ${(180).toLocaleString()} = <ans/>`,
          translate.answerSentences.theSumOfInteriorAnglesIs()
        ]}
        pdfDirection="column"
        testCorrect={[
          [totalTriangles.toString(), totalDegrees.toString()],
          [totalDegrees.toString()]
        ]}
        Content={({ dimens }) => {
          return (
            <View style={{ transform: [{ scaleX: flipX ? -1 : 1 }, { scaleY: flipY ? -1 : 1 }] }}>
              <AssetSvg
                name={shapeSvgPath as SvgName}
                height={dimens.height}
                width={dimens.width}
              />
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aEF',
  description: 'aEF',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shapeName: z.enum(shapeOptions)
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray(shapeOptions);

    return { shapeName };
  },
  Component: props => {
    const {
      question: { shapeName },
      translate
    } = props;

    const shapeObject = shapeInteriorAngles.filter(val => val.shape === shapeName)[0] as {
      shape: ShapeOptions;
      sumOfInteriorAngles: number;
    };
    const titleShape = aOrAnShapeAsWords(shapeObject.shape, translate);
    return (
      <QF2AnswerBoxOneSentence
        sentence={translate.answerSentences.theSumOfInteriorAnglesInXIs(titleShape)}
        title={translate.instructions.completeSentence()}
        testCorrect={[shapeObject.sumOfInteriorAngles.toString()]}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        actionPanelVariant="bottomTall"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aEG',
  description: 'aEG',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shapeName: z.enum(['pentagons', 'hexagons', 'heptagons', 'octagons', 'nonagons', 'decagons'])
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray([
      'pentagons',
      'hexagons',
      'heptagons',
      'octagons',
      'nonagons',
      'decagons'
    ] as const);

    return { shapeName };
  },
  Component: props => {
    const {
      question: { shapeName },
      translate
    } = props;

    const random = props.question;
    const regularShape = getShapeSvgName(shapeName, random);
    const irregularShape = getIrregularShapeSvgName(shapeName, random);
    const sumOfInteriorAngles = shapeInteriorAngles.filter(val => val.shape === shapeName)[0]
      .sumOfInteriorAngles;

    return (
      <QF1ContentAndSentences
        sentences={[
          `${translate.answerSentences.shapeA()} = <ans/> ${DEGREES}`,
          `${translate.answerSentences.shapeB()} = <ans/> ${DEGREES}`
        ]}
        title={translate.instructions.shapeAIsRegularBIrregularWhatIsInteriorAngles(
          translate.shapes[shapeName as ShapeOptions](1)
        )}
        style={{ flexDirection: 'row' }}
        testCorrect={[[sumOfInteriorAngles.toString()], [sumOfInteriorAngles.toString()]]}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: dimens.width * 0.4 }}>
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {translate.letters.A()}
                </Text>
                <AssetSvg
                  name={regularShape as SvgName}
                  width={dimens.width * 0.4}
                  height={dimens.height * 0.6}
                />
              </View>
              <View style={{ width: dimens.width * 0.4 }}>
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {translate.letters.B()}
                </Text>
                <AssetSvg
                  name={irregularShape as SvgName}
                  width={dimens.width * 0.4}
                  height={dimens.height * 0.6}
                />
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aEH',
  description: 'aEH',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shape: z.enum(['decagons', 'heptagons', 'hexagons', 'nonagons', 'octagons', 'pentagons']),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'decagons',
      'heptagons',
      'hexagons',
      'nonagons',
      'octagons',
      'pentagons'
    ] as const);
    const letter = getAlgebraicSymbol();
    return { shape, letter };
  },
  Component: props => {
    const {
      question: { shape, letter },
      translate
    } = props;

    const shapeObject = markedOneAngle.filter(val => val.shape === shape)[0];

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        title={
          shapeObject.angleSize % 1 !== 0
            ? translate.instructions.calculateAngleOfX2dp(letter)
            : translate.instructions.calculateAngleOfX(letter)
        }
        testCorrect={answer => compareFloats(answer[0], shapeObject.angleSize.toString())}
        customMarkSchemeAnswer={{ answersToDisplay: [shapeObject.angleSize.toLocaleString()] }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={6}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => {
          return (
            <LabelledOneAngle
              dimens={dimens}
              label={letter}
              svgName={shapeObject.svgName as SvgName}
            />
          );
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aEH2',
  description: 'aEH',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shape: z.enum(['decagons', 'hexagons', 'nonagons', 'octagons', 'pentagons']),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'decagons',
      'hexagons',
      'nonagons',
      'octagons',
      'pentagons'
    ] as const);
    const letter = getAlgebraicSymbol();
    return { shape, letter };
  },
  Component: props => {
    const {
      question: { shape, letter },
      translate
    } = props;

    const shapeObject = markedOneAngle.filter(val => val.shape === shape)[0];
    const sumOfInterior = shapeInteriorAngles.filter(val => val.shape === shape)[0]
      .sumOfInteriorAngles;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        title={translate.instructions.theSumOfInteriorAnglesAreXCalculateSizeOfAngleY(
          sumOfInterior.toLocaleString(),
          letter
        )}
        testCorrect={[shapeObject.angleSize.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <LabelledOneAngle
              dimens={dimens}
              label={letter}
              svgName={shapeObject.svgName as SvgName}
            />
          );
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aEI',
  description: 'aEI',
  keywords: ['Polygons', 'Angles'],
  schema: z.object({
    shape: z.enum([
      'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles',
      'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles'
    ]),
    angles: z
      .number()
      .int()
      .array()
      .refine(arr => arr.every(angle => angle !== 90, 'No angle should be equal to 90')),
    missingAngleIndex: z.number().int().min(0).max(7),
    missingAngleLabel: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const { shape, angles } = rejectionSample(
      () => {
        const shape = getRandomFromArray([
          'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles',
          'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles'
        ] as const);

        let angles: number[] = [];

        switch (shape) {
          case 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles':
            angles = [
              randomIntegerInclusive(122, 132),
              randomIntegerInclusive(61, 71),
              randomIntegerInclusive(128, 138),
              randomIntegerInclusive(135, 145)
            ];
            angles.push(540 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles':
            angles = [
              randomIntegerInclusive(91, 100), //exclude 90
              randomIntegerInclusive(99, 109),
              randomIntegerInclusive(113, 123),
              randomIntegerInclusive(139, 149)
            ];
            angles.push(540 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles':
            angles = [
              randomIntegerInclusive(129, 139),
              randomIntegerInclusive(43, 53),
              randomIntegerInclusive(149, 159),
              randomIntegerInclusive(126, 136)
            ];
            angles.push(540 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles':
            angles = [
              randomIntegerInclusive(148, 158),
              randomIntegerInclusive(58, 68),
              randomIntegerInclusive(139, 149),
              randomIntegerInclusive(50, 60),
              randomIntegerInclusive(245, 255)
            ];
            angles.push(720 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles':
            angles = [
              randomIntegerInclusive(99, 109),
              randomIntegerInclusive(143, 153),
              randomIntegerInclusive(110, 120),
              randomIntegerInclusive(107, 117),
              randomIntegerInclusive(115, 125)
            ];
            angles.push(720 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles':
            angles = [
              randomIntegerInclusive(109, 119),
              randomIntegerInclusive(81, 91), //90 will get caught in rejection sample
              randomIntegerInclusive(135, 145),
              randomIntegerInclusive(125, 135),
              randomIntegerInclusive(106, 116)
            ];
            angles.push(720 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles':
            angles = [
              randomIntegerInclusive(231, 241),
              randomIntegerInclusive(100, 110),
              randomIntegerInclusive(58, 68),
              randomIntegerInclusive(223, 233),
              randomIntegerInclusive(67, 77),
              randomIntegerInclusive(144, 154)
            ];
            angles.push(900 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles':
            angles = [
              randomIntegerInclusive(89, 99), //90 will get caught in rejection sample
              randomIntegerInclusive(254, 264),
              randomIntegerInclusive(41, 51),
              randomIntegerInclusive(128, 138),
              randomIntegerInclusive(117, 127),
              randomIntegerInclusive(113, 123)
            ];
            angles.push(900 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles':
            angles = [
              randomIntegerInclusive(220, 230),
              randomIntegerInclusive(33, 43),
              randomIntegerInclusive(219, 229),
              randomIntegerInclusive(31, 41),
              randomIntegerInclusive(214, 224),
              randomIntegerInclusive(91, 101)
            ];
            angles.push(900 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles':
            angles = [
              randomIntegerInclusive(88, 98), //90 will get caught in rejection sample
              randomIntegerInclusive(231, 241),
              randomIntegerInclusive(97, 107),
              randomIntegerInclusive(102, 112),
              randomIntegerInclusive(132, 142),
              randomIntegerInclusive(156, 166),
              randomIntegerInclusive(104, 114)
            ];
            angles.push(1080 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles':
            angles = [
              randomIntegerInclusive(75, 85),
              randomIntegerInclusive(109, 119),
              randomIntegerInclusive(72, 82),
              randomIntegerInclusive(256, 266),
              randomIntegerInclusive(35, 45),
              randomIntegerInclusive(212, 222),
              randomIntegerInclusive(28, 38)
            ];
            angles.push(1080 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
          case 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles':
            angles = [
              randomIntegerInclusive(210, 220),
              randomIntegerInclusive(27, 37),
              randomIntegerInclusive(265, 275),
              randomIntegerInclusive(26, 36),
              randomIntegerInclusive(237, 247),
              randomIntegerInclusive(82, 92), //90 will get caught in rejection sample
              randomIntegerInclusive(144, 154)
            ];
            angles.push(1080 - sumNumberArray(angles)); // last angle is generated from what is remaining
            break;
        }
        return { shape, angles };
      },
      ({ angles }) => angles.length > 0 && angles.every(angle => angle !== 90) // none of the angles are right-angled
    );

    const missingAngleIndex = randomIntegerInclusive(0, angles.length - 1);

    const missingAngleLabel = getAlgebraicSymbol();

    return {
      shape,
      angles,
      missingAngleIndex,
      missingAngleLabel
    };
  },
  Component: ({ question, translate }) => {
    const { shape, angles, missingAngleIndex, missingAngleLabel } = question;

    const answer = angles[missingAngleIndex];
    const labels = angles.map((angle, index) =>
      index === missingAngleIndex ? missingAngleLabel : translate.units.numberOfDegrees(angle)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutSizeOfAngleX(missingAngleLabel)}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={labels} />
        )}
        // This shape actually grows significantly with flexDirection: 'row', while not encroaching on the answer sentence.
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.xEqualsAnsDegrees(missingAngleLabel)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aEJ',
  description: 'aEJ',
  keywords: ['Polygons', 'Angles', 'Compound'],
  schema: z.object({
    svgName: z.enum([
      'Layered_shapes/Layered_compound_shapes1',
      'Layered_shapes/Layered_compound_shapes2',
      'Layered_shapes/Layered_compound_shapes3',
      'Layered_shapes/Layered_compound_shapes4',
      'Layered_shapes/Layered_compound_shapes5'
    ]),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const svgName = getRandomFromArray([
      'Layered_shapes/Layered_compound_shapes1',
      'Layered_shapes/Layered_compound_shapes2',
      'Layered_shapes/Layered_compound_shapes3',
      'Layered_shapes/Layered_compound_shapes4',
      'Layered_shapes/Layered_compound_shapes5'
    ] as const);

    const letter = getAlgebraicSymbol();
    return { svgName, letter };
  },
  Component: props => {
    const {
      question: { svgName, letter },
      translate
    } = props;

    const shapeObject = compoundShapes.filter(val => val.svgName === svgName)[0];

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        title={
          shapeObject.angleSize % 1 !== 0
            ? translate.instructions.compoundShapeMadeOfRegularPolygonsWorkOutSizeOfX2dp(letter)
            : translate.instructions.compoundShapeMadeOfRegularPolygonsWorkOutSizeOfX(letter)
        }
        testCorrect={answer => compareFloats(answer[0], shapeObject.angleSize.toString())}
        customMarkSchemeAnswer={{ answersToDisplay: [shapeObject.angleSize.toLocaleString()] }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={6}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => {
          return (
            <LabelledOneAngle
              dimens={dimens}
              label={letter}
              svgName={shapeObject.svgName as SvgName}
            />
          );
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aEJ2',
  description: 'aEJ',
  keywords: ['Polygons', 'Angles', 'Compound'],
  schema: z
    .object({
      shapeNames: z
        .enum([
          'triangles',
          'squares',
          'rectangles',
          'pentagons',
          'hexagons',
          'heptagons',
          'octagons',
          'nonagons',
          'decagons'
        ])
        .array()
        .length(4),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => !(val.shapeNames.includes('rectangles') && val.shapeNames.includes('squares')),
      'must not have square and rectangle as options'
    ),
  simpleGenerator: () => {
    const shapeNames = rejectionSample(
      () =>
        getRandomSubArrayFromArray(
          [
            'triangles',
            'squares',
            'rectangles',
            'pentagons',
            'hexagons',
            'heptagons',
            'octagons',
            'nonagons',
            'decagons'
          ] as const,
          4
        ),
      val => !(val.includes('rectangles') && val.includes('squares'))
    );

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { shapeNames, ordering };
  },
  Component: props => {
    const {
      question: { shapeNames, ordering },
      translate
    } = props;

    const title =
      ordering === 'ascending'
        ? translate.instructions.dragCardsToOrderInteriorAnglesSmallestGreatest()
        : translate.instructions.dragCardsToOrderInteriorAnglesGreatestSmallest();

    const items = shapeNames.map(name => ({
      component: translate.shapes[name](1),
      value: shapeInteriorAngles.filter(val => val.shape === name)[0].sumOfInteriorAngles
    }));

    const testCorrect = sortNumberArray(
      items.map(item => item.value),
      ordering
    );

    return (
      <QF4DragOrderVertical
        title={title}
        testCorrect={testCorrect}
        items={items}
        topLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AnglesInPolygons',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4, Question6]
});
export default SmallStep;
