import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { fractionSchema, numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  compareFractions,
  decimalToFraction,
  fractionToDecimal
} from '../../../../utils/fractions';
import QF9DragIntoTableOfGroups from '../../../../components/question/questionFormats/QF9DragIntoTableOfGroups';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { hundredSquareColors } from '../../../../theme/colors';
import {
  HundredSquareShapeName,
  getPremadeHundredSquareShape
} from '../../../../utils/hundredSquareShapes';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { useMemo } from 'react';
import { range } from '../../../../utils/collections';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aw0',
  description: 'aw0',
  keywords: ['Halves', 'Quarters'],
  schema: z.object({
    shaded: z.enum(['Half rows', 'Quarter rows', 'Quarter square']),
    rotation: numberEnum([0, 90, 180, 270])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const shaded = getRandomFromArray(['Half rows', 'Quarter rows', 'Quarter square'] as const);

    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { shaded, rotation };
  },
  Component: props => {
    const {
      question: { shaded, rotation },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    const sentences =
      shaded === 'Half rows'
        ? [
            translate.answerSentences.howManyHundredthsAreShaded(),
            translate.answerSentences.howManyTenthsAreShaded()
          ]
        : [translate.answerSentences.howManyHundredthsAreShaded()];

    const shapeData = getPremadeHundredSquareShape(shaded, shadingColor, rotation);

    const shape = shapeData.shape;

    const cellColorMap = shapeData.cellColorMap;

    return (
      <QF1ContentAndSentences
        sentences={sentences}
        title={
          shaded === 'Half rows'
            ? translate.instructions.halfOfHundredSquareIsShaded()
            : translate.instructions.aQuarterOfHundredSquareIsShaded()
        }
        testCorrect={shaded === 'Half rows' ? [['50'], ['5']] : [['25']]}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} cellColorMap={cellColorMap} dimens={dimens} />
        )}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aw1',
  description: 'aw1',
  keywords: ['Halves', 'Quarters'],
  schema: z.object({
    shaded: z.enum([
      'Half rows',
      'Quarter rows',
      'Three quarters rows',
      'Half squares',
      'Quarter square',
      'Three quarters squares'
    ]),
    rotation: numberEnum([0, 90, 180, 270])
  }),
  simpleGenerator: () => {
    const shaded = getRandomFromArray([
      'Half rows',
      'Quarter rows',
      'Three quarters rows',
      'Half squares',
      'Quarter square',
      'Three quarters squares'
    ] as const);

    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { shaded, rotation };
  },
  Component: props => {
    const {
      question: { shaded, rotation },
      translate
    } = props;

    const numerator =
      shaded === 'Three quarters rows' || shaded === 'Three quarters squares' ? 3 : 1;

    const denominator = shaded === 'Half rows' || shaded === 'Half squares' ? 2 : 4;

    const shadingColor = getRandomFromArray(Object.values(hundredSquareColors), {
      random: seededRandom(props.question)
    }) as string;

    const shapeData = getPremadeHundredSquareShape(shaded, shadingColor, rotation);

    const shape = shapeData.shape;

    const cellColorMap = shapeData.cellColorMap;

    return (
      <QF1ContentAndSentence
        sentence={`<frac n='${numerator}' d='${denominator}' /> = <frac nAns='' d='${100}' />`}
        title={translate.instructions.completeEquivalentFractions()}
        testCorrect={[((100 / denominator) * numerator).toString()]}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} cellColorMap={cellColorMap} dimens={dimens} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aw2',
  description: 'aw2',
  keywords: ['Halves', 'Quarters'],
  schema: z.object({
    fraction: z.enum(['Half', 'Quarter', 'Three quarters']),
    halfShapeShading: z.enum([
      'Half rows',
      'Half alternating rows',
      'Half split rows',
      'Half squares'
    ]),
    halfShapeRotation: numberEnum([0, 90, 180, 270]),
    quarterShapeShading: z.enum([
      'Quarter rows',
      'Quarter rows one side',
      'Quarter alternating rows',
      'Quarter square'
    ]),
    quarterShapeRotation: numberEnum([0, 90, 180, 270]),
    threeQuartersShapeShading: z.enum([
      'Three quarters rows',
      'Three quarters rows one side',
      'Three quarters alternating rows',
      'Three quarters squares'
    ]),
    threeQuartersShapeRotation: numberEnum([0, 90, 180, 270]),
    otherShapeShading: z.enum([
      'Half rows',
      'Half alternating rows',
      'Half split rows',
      'Half squares',
      'Quarter rows',
      'Quarter rows one side',
      'Quarter alternating rows',
      'Quarter square',
      'Three quarters rows',
      'Three quarters rows one side',
      'Three quarters alternating rows',
      'Three quarters squares'
    ]),
    otherShapeRotation: numberEnum([0, 90, 180, 270])
  }),
  simpleGenerator: () => {
    const fraction = getRandomFromArray(['Half', 'Quarter', 'Three quarters'] as const);

    const halfShapeShading = getRandomFromArray([
      'Half rows',
      'Half alternating rows',
      'Half split rows',
      'Half squares'
    ] as const);

    const halfShapeRotation = getRandomFromArray([0, 90, 180, 270] as const);

    const quarterShapeShading = getRandomFromArray([
      'Quarter rows',
      'Quarter rows one side',
      'Quarter alternating rows',
      'Quarter square'
    ] as const);

    const quarterShapeRotation = getRandomFromArray([0, 90, 180, 270] as const);

    const threeQuartersShapeShading = getRandomFromArray([
      'Three quarters rows',
      'Three quarters rows one side',
      'Three quarters alternating rows',
      'Three quarters squares'
    ] as const);

    const threeQuartersShapeRotation = getRandomFromArray([0, 90, 180, 270] as const);

    const otherShapeShadingOptions = [
      'Half rows',
      'Half alternating rows',
      'Half split rows',
      'Half squares',
      'Quarter rows',
      'Quarter rows one side',
      'Quarter alternating rows',
      'Quarter square',
      'Three quarters rows',
      'Three quarters rows one side',
      'Three quarters alternating rows',
      'Three quarters squares'
    ].filter(
      shape =>
        shape !== halfShapeShading &&
        shape !== quarterShapeShading &&
        shape !== threeQuartersShapeShading
    );

    const otherShapeShading = getRandomFromArray(
      otherShapeShadingOptions
    ) as HundredSquareShapeName;

    const otherShapeRotation = getRandomFromArray([0, 90, 180, 270] as const);

    return {
      fraction,
      halfShapeShading,
      halfShapeRotation,
      quarterShapeShading,
      quarterShapeRotation,
      threeQuartersShapeShading,
      threeQuartersShapeRotation,
      otherShapeShading,
      otherShapeRotation
    };
  },
  Component: props => {
    const {
      question: {
        fraction,
        halfShapeShading,
        halfShapeRotation,
        quarterShapeShading,
        quarterShapeRotation,
        threeQuartersShapeShading,
        threeQuartersShapeRotation,
        otherShapeShading,
        otherShapeRotation
      },
      translate
    } = props;

    const numerator = fraction === 'Three quarters' ? 3 : 1;

    const denominator = fraction === 'Half' ? 2 : 4;

    const statements = useMemo(() => {
      const random = seededRandom(props.question);

      const [shadingColorHalf, shadingColorQuarter, shadingColorThreeQuarters, shadingColorOther] =
        getRandomSubArrayFromArray(Object.values(hundredSquareColors), 4, {
          random: random
        });

      const shapeDataHalf = getPremadeHundredSquareShape(
        halfShapeShading,
        shadingColorHalf,
        halfShapeRotation
      );

      const shapeDataQuarter = getPremadeHundredSquareShape(
        quarterShapeShading,
        shadingColorQuarter,
        quarterShapeRotation
      );

      const shapeDataThreeQuarters = getPremadeHundredSquareShape(
        threeQuartersShapeShading,
        shadingColorThreeQuarters,
        threeQuartersShapeRotation
      );

      const shapeDataOther = getPremadeHundredSquareShape(
        otherShapeShading,
        shadingColorOther,
        otherShapeRotation
      );

      return shuffle(
        [
          {
            shape: shapeDataHalf.shape,
            colorMap: shapeDataHalf.cellColorMap,
            isCorrect: fraction === 'Half'
          },
          {
            shape: shapeDataQuarter.shape,
            colorMap: shapeDataQuarter.cellColorMap,
            isCorrect: fraction === 'Quarter'
          },
          {
            shape: shapeDataThreeQuarters.shape,
            colorMap: shapeDataThreeQuarters.cellColorMap,
            isCorrect: fraction === 'Three quarters'
          },
          {
            shape: shapeDataOther.shape,
            colorMap: shapeDataOther.cellColorMap,
            isCorrect: (() => {
              switch (fraction) {
                case 'Half':
                  return (
                    otherShapeShading === 'Half rows' ||
                    otherShapeShading === 'Half split rows' ||
                    otherShapeShading === 'Half alternating rows' ||
                    otherShapeShading === 'Half squares'
                  );
                case 'Quarter':
                  return (
                    otherShapeShading === 'Quarter rows' ||
                    otherShapeShading === 'Quarter alternating rows' ||
                    otherShapeShading === 'Quarter rows one side' ||
                    otherShapeShading === 'Quarter square'
                  );
                case 'Three quarters':
                  return (
                    otherShapeShading === 'Three quarters rows' ||
                    otherShapeShading === 'Three quarters alternating rows' ||
                    otherShapeShading === 'Three quarters rows one side' ||
                    otherShapeShading === 'Three quarters squares'
                  );
              }
            })()
          }
        ],
        {
          random: random
        }
      );
    }, [
      fraction,
      halfShapeRotation,
      halfShapeShading,
      otherShapeRotation,
      otherShapeShading,
      props.question,
      quarterShapeRotation,
      quarterShapeShading,
      threeQuartersShapeRotation,
      threeQuartersShapeShading
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheHundredSquareThatRepresentsFrac(
          numerator,
          denominator
        )}
        pdfTitle={translate.instructions.circleTheHundredSquareThatRepresentsFrac(
          numerator,
          denominator
        )}
        testCorrect={statements.filter(shape => shape.isCorrect)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          statements.map(shape => ({
            value: shape,
            component: (
              <DisplayShapeOnGrid
                givenShape={shape.shape}
                cellColorMap={shape.colorMap}
                dimens={dimens}
              />
            )
          }))
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aw3',
  description: 'aw3',
  keywords: ['Halves', 'Quarters'],
  schema: z.object({
    statementFracs: z.array(fractionSchema(z.number().int().min(1).max(4))).length(3),
    answerFracs: z.array(fractionSchema(z.number().min(1).max(100))).length(4)
  }),
  simpleGenerator: () => {
    const statementFracs: [number, number][] = getRandomSubArrayFromArray(
      [
        [1, 2],
        [1, 4],
        [3, 4],
        [1, 2]
      ],
      3
    );
    const answerFracs: [number, number][] = shuffle([
      [50, 100],
      [25, 100],
      [75, 100],
      [5, 10]
    ]);

    return { statementFracs, answerFracs };
  },
  Component: props => {
    const {
      question: { statementFracs, answerFracs },
      translate,
      displayMode
    } = props;

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        actionPanelVariant="end"
        testCorrect={userAnswer =>
          userAnswer.every((ans, idx) => compareFractions(ans[0] as number[], statementFracs[idx]))
        }
        items={answerFracs.map(opt => ({
          component: (
            <TextStructure
              sentence={`<frac n='${opt[0].toLocaleString()}' d='${opt[1].toLocaleString()}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          ),
          value: opt
        }))}
        sentences={statementFracs.map(
          frac =>
            `<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/>  =  <ans/>`
        )}
        pdfLayout="itemsRight"
        sentencesStyle={{ rowGap: 8 }}
        customMarkSchemeAnswer={{
          answersToDisplay: statementFracs.map(frac =>
            answerFracs.filter(ansFrac => compareFractions(frac, ansFrac))
          ),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aw4',
  description: 'aw4',
  keywords: ['Halves', 'Quarters'],
  schema: z.object({
    fractionTopOrBottom: z.enum(['top', 'bottom']),
    fractions: z.array(fractionSchema(z.number().int().min(5).max(100))).length(4)
  }),
  simpleGenerator: () => {
    const fractions: [number, number][] = shuffle([
      [50, 100],
      [5, 10],
      [25, 100],
      [75, 100]
    ]);

    const fractionTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { fractionTopOrBottom, fractions };
  },
  Component: ({ question: { fractionTopOrBottom, fractions }, translate, displayMode }) => {
    const correctAnswer = [
      [[], [], []],
      [[], [], []]
    ] as [
      [topRowA: number[], topRowB: number[], topRowC: number[]],
      [bottomRowA: number[], bottomRowB: number[], bottomRowC: number[]]
    ];

    const fractionRowIdx = fractionTopOrBottom === 'top' ? 0 : 1;
    const decimalRowIdx = fractionTopOrBottom === 'top' ? 1 : 0;

    const columnHeaderValues = [
      ...new Set(fractions.map(frac => fractionToDecimal(frac[0], frac[1])))
    ];

    const rowHeaders =
      fractionTopOrBottom === 'top'
        ? [translate.tableHeaders.Fraction(), translate.tableHeaders.Decimal()]
        : [translate.tableHeaders.Decimal(), translate.tableHeaders.Fraction()];

    // Collect all possible answer options
    const answerOptions = [...fractions, ...columnHeaderValues];

    answerOptions.forEach((frac, fracIdx) => {
      // Determine which row and column the answer will be in
      if (typeof frac === 'number') {
        // Answer option is a decimal
        const columnIndex = columnHeaderValues.findIndex(it => it === frac);
        correctAnswer[decimalRowIdx][columnIndex].push(fracIdx);
      } else {
        // Answer option is a fraction
        const columnIndex = columnHeaderValues.findIndex(
          it => it === fractionToDecimal(frac[0], frac[1])
        );
        correctAnswer[fractionRowIdx][columnIndex].push(fracIdx);
      }
    });

    return (
      <QF9DragIntoTableOfGroups
        title={translate.instructions.dragCardsToMatchEquivalentFracsAndDecimals()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFracsAndDecimals()}
        rowNames={rowHeaders}
        columnNames={columnHeaderValues.map(header =>
          header === 0.5
            ? translate.keywords.Half()
            : header === 0.25
            ? translate.tableHeaders.quater()
            : translate.tableHeaders.threeQuarters()
        )}
        tableHeaderMaxLines={1}
        tableHeaderStyle={displayMode === 'digital' && { fontSize: 28 }}
        testCorrect={correctAnswer}
        items={answerOptions.map((opt, sumIndex) => ({
          value: sumIndex,
          component: (
            <TextStructure
              sentence={
                typeof opt === 'number'
                  ? `${opt.toLocaleString()}`
                  : `<frac n='${opt[0].toLocaleString()}' d='${opt[1].toLocaleString()}' />`
              }
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
            />
          )
        }))}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aw5',
  description: 'aw5',
  keywords: ['Halves', 'Quarters', 'Decimals', 'Fractions'],
  schema: z.object({
    answerBoxesTop: z.array(z.number().int().min(1).max(3)).length(2),
    answerBoxesBottom: z.array(z.number().int().min(1).max(3)).length(2)
  }),
  simpleGenerator: () => {
    const answerBoxesTop = randomUniqueIntegersInclusive(1, 3, 2);
    const answerBoxBottom = randomIntegerInclusive(1, 3);

    const missingValue = range(1, 3).filter(i => !answerBoxesTop.includes(i));

    const answerBoxesBottom =
      answerBoxBottom === missingValue[0]
        ? [
            answerBoxBottom,
            randomIntegerInclusive(1, 3, { constraint: x => x !== answerBoxBottom })
          ]
        : [answerBoxBottom, missingValue[0]];

    return { answerBoxesTop, answerBoxesBottom };
  },

  Component: props => {
    const {
      question: { answerBoxesTop, answerBoxesBottom },
      translate,
      displayMode
    } = props;

    const commonAnswerIndex = answerBoxesTop.find(num => answerBoxesBottom.includes(num));

    const commonAnswerValue = range(0, 1, 0.25).find((_, i) => i === commonAnswerIndex);

    const topAnswers = range(0, 1, 0.25)
      .filter((_val, i) => answerBoxesTop.includes(i))
      .map(i => {
        return {
          component: (
            <TextStructure
              key={i}
              sentence={`<frac n='${decimalToFraction(i)[0]}' d='${decimalToFraction(i)[1]}' />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textVariant="WRN700"
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value:
            commonAnswerValue === i
              ? commonAnswerValue.toString()
              : `${decimalToFraction(i)[0]}/${decimalToFraction(i)[1]}`
        };
      });

    const bottomAnswers = range(0, 1, 0.25)
      .filter((_val, i) => answerBoxesBottom.includes(i))
      .map(i => i.toLocaleString());

    const items = shuffle([...topAnswers, ...bottomAnswers], {
      random: seededRandom(props.question)
    });

    // Create array to pass to Number Line
    const bottomTickValues: string[] = range(0, 1, 0.25).map((i, index) =>
      answerBoxesBottom.includes(index) ? '<ans/>' : i.toLocaleString()
    );
    const topTickValues = range(0, 1, 0.25).map((i, index) =>
      answerBoxesTop.includes(index)
        ? `<ans/>`
        : i === 0 || i === 1
        ? i.toLocaleString()
        : `<frac n='${decimalToFraction(i)[0]}' d='${decimalToFraction(i)[1]}' />`
    );

    answerBoxesTop.forEach(number => {
      topTickValues[number] = '<ans/>';
    });

    const topCorrect = topAnswers.map(i => i.value);

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[...topCorrect, ...bottomAnswers]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HalvesAndQuartersAsDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
