import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusive, shuffle } from '../../../../utils/random';
import { range, rangeAsString } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { SUB } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbC',
  description: 'bbC',
  keywords: ['Subtract', 'Number line'],
  questionHeight: 900,
  schema: z
    .object({
      minuend: z.number().int().min(1).max(10),
      subtrahend: z.number().int().min(1).max(10)
    })
    .refine(
      ({ minuend, subtrahend }) => minuend >= subtrahend,
      'minuend should be greater or equal to subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(1, 10);
    const subtrahend = randomIntegerInclusive(1, minuend);

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const tickValues = range(0, 10).map(num => ({
      position: num,
      label: num.toLocaleString()
    }));

    const jumpArrowArray = range(minuend, minuend - subtrahend + 1).map(num => ({
      start: num,
      end: num - 1,
      label: ''
    }));

    const eq = getBinOpEquation({ left: minuend, right: subtrahend, sign: 'subtract' });

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.ks1Instructions.completeTheSubtraction()}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            dimens={dimens}
            tickValues={tickValues}
            start={0}
            end={10}
            jumpArrowArray={jumpArrowArray}
            subtraction={true}
          />
        )}
        sentence={binOpEquationToSentenceString(eq)}
        testCorrect={binOpEquationsToTestCorrect([eq])[0]}
        pdfDirection="column"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbD',
  description: 'bbD',
  keywords: ['Subtract', 'Number line'],
  questionHeight: 1200,
  schema: z
    .object({
      minuend: z.number().int().min(1).max(10),
      subtrahend: z.number().int().min(1).max(10),
      items: z.array(z.number().int().min(0).max(10)).length(4)
    })
    .refine(
      ({ minuend, subtrahend }) => minuend >= subtrahend,
      'minuend should be greater or equal to subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(1, 10);
    const subtrahend = randomIntegerInclusive(1, minuend);
    const difference = minuend - subtrahend;
    const wrongOption =
      difference === 0 ? difference + 1 : getRandomBoolean() ? difference + 1 : difference - 1;

    const items = shuffle([subtrahend, minuend, difference, wrongOption]);
    return { minuend, subtrahend, items };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend, items },
      translate
    } = props;
    const difference = minuend - subtrahend;

    const tickValues = range(0, 10).map(num => ({
      position: num,
      label: num.toLocaleString()
    }));

    const jumpArrowArray = range(minuend, minuend - subtrahend + 1).map(num => ({
      start: num,
      end: num - 1,
      label: ''
    }));

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1200}
        title={translate.ks1Instructions.whatSubtractionIsShownOnTheNumberLineDragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.whatSubtractionIsShownOnTheNumberLineUseTheCardsToCompleteTheNumberSentence()}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            dimens={dimens}
            tickValues={tickValues}
            start={0}
            end={10}
            jumpArrowArray={jumpArrowArray}
            subtraction={true}
          />
        )}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        items={items.map(i => i.toLocaleString())}
        sentence={`<ans/> ${SUB} <ans/> = <ans/>`}
        testCorrect={[
          minuend.toLocaleString(),
          subtrahend.toLocaleString(),
          difference.toLocaleString()
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbE',
  description: 'bbE',
  keywords: ['Subtract', 'Number line'],
  schema: z
    .object({
      minuend: z.number().int().min(1).max(10),
      subtrahend: z.number().int().min(1).max(10)
    })
    .refine(
      ({ minuend, subtrahend }) => minuend >= subtrahend,
      'minuend should be greater or equal to subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(1, 10);
    const subtrahend = randomIntegerInclusive(1, minuend);

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const tickValues = rangeAsString(0, 10, 1, true);

    const eq = getBinOpEquation({ left: minuend, right: subtrahend, sign: 'subtract' });

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        Content={({ dimens }) => <NumberLine dimens={dimens} tickValues={tickValues} />}
        sentence={binOpEquationToSentenceString(eq)}
        testCorrect={binOpEquationsToTestCorrect([eq])[0]}
        pdfDirection="column"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionOnANumberLine',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
