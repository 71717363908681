// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import TheFirstQuadrant from './1TheFirstQuadrant';
import ReadAndPlotPointsInFourQuadrants from './2ReadAndPlotPointsInFourQuadrants';
import SolveProblemsWithCoordinates from './3SolveProblemsWithCoordinates';
import Translations from './4Translations';
import Reflections from './5Reflections';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [4, 4],
  smallSteps: [
    TheFirstQuadrant, // Step 1
    ReadAndPlotPointsInFourQuadrants, // Step 2
    SolveProblemsWithCoordinates, // Step 3
    Translations, // Step 4
    Reflections // Step 5
  ]
});
export default Block;
