import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { filledArray } from 'common/src/utils/collections';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import AutoScaleText from '../../typography/AutoScaleText';
import { Theme } from '../../../theme';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext, useRef, useState } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import ContentBox from '../../molecules/ContentBox';
import { DraggableVariant } from '../../draganddrop/utils';
import Svg, { Line } from 'react-native-svg';
import { MINIMUM_QUESTION_HEIGHT, PDF_WIDTH } from '../../../theme/scaling';
import { AssetSvg } from '../../../assets/svg';

type Measurement = {
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
};

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type StatementInfo<T> = {
  lhsComponent: string | JSX.Element;
  rhsComponent?: string | JSX.Element;
  correctAnswer: T | ((answer: T) => boolean);
};

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * Prop to decide how the PDF version will be displayed, based on your choice of if and where items are placed.
   * 'itemsHidden' - removes the items entirely from the PDF.
   * 'itemsRight' - items display in a column list on the right.
   * 'itemsTop' - items display in a row between the instruction and sentence(s).
   * Optional prop, defaults to 'itemsRight'.
   */
  pdfLayout?: 'itemsHidden' | 'itemsRight' | 'itemsBottom' | 'itemsTop';
  /** The items to show as draggables. Each item can be passed as a simple string or number. */
  items: ((T & (string | number)) | ItemInfo<T>)[];
  // used if items contains strings
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;

  statements: StatementInfo<T>[];
  // used if statements contains strings
  statementsTextVariant?: keyof Theme['fonts'];
  statementsLetterEmWidth?: number;
  statementMaxLines?: number;

  /** Default: 'move' */
  moveOrCopy?: 'move' | 'copy';
  /** Default: 'rectangle' */
  itemVariant?: DraggableVariant;
  /** Default: 'tallRectangle' */
  pdfItemVariant?: DraggableVariant;
  /** Default: 'endWide */
  actionPanelVariant?: 'endWide' | 'endMid' | 'end' | 'bottom';
  mainPanelStyle?: StyleProp<ViewStyle>;
  statementStyle?: StyleProp<ViewStyle>;
  /** Optional contentBox, if used there is a limit to 3 statements */
  contentBox?: string;
  /** Optional custom mark scheme answer */
  markSchemeAnswer?: string;
  questionHeight?: number;
  useArrows?: boolean;
};

/** Util function simplify use of numbers as draggable items */
type NumberWithString = { value: number; string: string };
export function toNumberWithString(x: number | NumberWithString) {
  return typeof x === 'number' ? { value: x, string: x.toLocaleString() } : x;
}

/**
 * Question Format 6: Match statements (drag and drop)
 *
 * Title at the top, draggables on the right, and positions to drag them into on the left, listed vertically.
 *
 * All statements should have only lhs components, or both lhs and rhs components.
 *
 * This is one-to-one by default, but can be made many-to-one with moveOrCopy='copy'.
 *
 * At most 4 draggables.
 */
export default function QF6DragMatchStatements<T extends string | number | boolean>({
  title,
  pdfTitle,
  items: itemsProp,
  pdfLayout = 'itemsRight',
  itemsTextVariant = 'WRN700',
  itemsLetterEmWidth = 0.7,
  itemsMaxLines = 1,
  statements,
  statementsTextVariant,
  statementsLetterEmWidth = 0.7,
  statementMaxLines = 1,
  moveOrCopy,
  mainPanelStyle,
  statementStyle,
  itemVariant = 'rectangle',
  pdfItemVariant = 'tallRectangle',
  actionPanelVariant = 'endWide',
  markSchemeAnswer,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  contentBox,
  useArrows = true,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const dashRefs = useRef<{
    left: (View | null)[];
    right: (View | null)[];
  }>({ left: [], right: [] });
  const svgRef = useRef<View | null>(null);

  const workingMeasurements = useRef<{
    svg?: Measurement;
    left: Measurement[];
    right: Measurement[];
  }>({
    left: [],
    right: []
  });
  const [measurements, setMeasurements] = useState<{
    svg?: Measurement;
    left: Measurement[];
    right: Measurement[];
  } | null>(null);

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const numAnswerBoxes = statements.length;

  const haveBothComponents = statements.every(statement => statement.rhsComponent !== undefined);
  // Typescript ensures all statements have a lhs. Check here if there is a mix of sides
  if (!haveBothComponents) {
    const mixed = !statements.every(statement => statement.rhsComponent === undefined);
    if (mixed) {
      throw Error(`There should not be a mix of lhs and rhs components`);
    }
  }

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );
  const longestStatementTextLength = statements.reduce(
    (max, statement) =>
      Math.max(
        max,
        typeof statement.lhsComponent === 'string' ? statement.lhsComponent.length : 0,
        typeof statement.rhsComponent === 'string' ? statement.rhsComponent.length : 0
      ),
    0
  );

  // Markscheme Matching lines
  const matchLines = () => {
    const setMeasurementsIfWeHaveAllMeasurements = () => {
      if (
        workingMeasurements.current.svg !== undefined &&
        Object.keys(workingMeasurements.current.left).length === items.length &&
        Object.keys(workingMeasurements.current.right).length === items.length
      ) {
        setMeasurements(workingMeasurements.current);
      }
    };

    const measureEverythingIfWeHaveAllRefs = () => {
      if (
        svgRef.current !== null &&
        Object.keys(dashRefs.current.left).length === items.length &&
        dashRefs.current.left.every((it): it is View => it !== null) &&
        Object.keys(dashRefs.current.right).length === items.length &&
        dashRefs.current.right.every((it): it is View => it !== null)
      ) {
        // Got all the refs - measure everything
        // SVG
        svgRef.current.measure((x, y, width, height, pageX, pageY) => {
          workingMeasurements.current.svg = {
            x,
            y,
            width,
            height,
            pageX,
            pageY
          };
          setMeasurementsIfWeHaveAllMeasurements();
        });

        // Left dashes
        dashRefs.current.left.forEach((ref, i) =>
          ref.measure((x, y, width, height, pageX, pageY) => {
            workingMeasurements.current.left[i] = {
              x,
              y,
              width,
              height,
              pageX,
              pageY
            };
            setMeasurementsIfWeHaveAllMeasurements();
          })
        );

        // Right dashes
        dashRefs.current.right.forEach((ref, i) =>
          ref.measure((x, y, width, height, pageX, pageY) => {
            workingMeasurements.current.right[i] = {
              x,
              y,
              width,
              height,
              pageX,
              pageY
            };
            setMeasurementsIfWeHaveAllMeasurements();
          })
        );
      }
    };

    return { measurements, measureEverythingIfWeHaveAllRefs };
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctItemOrder = statements
      .map(statement => items.filter(item => item.value === statement.correctAnswer)[0])
      .map(item => item.value);

    const correctMatch = statements.map(statement =>
      items.findIndex(item => item.value === statement.correctAnswer)
    );

    const markSchemeLines = matchLines();

    const displayCards = (cards: ((T & (string | number)) | ItemInfo<T>)[]) => (
      <View
        style={{
          flex: pdfLayout === 'itemsRight' ? 0.5 : 0.2
        }}
      >
        <DragAndDropSectionPDF
          itemsStyle={{
            rowGap: pdfLayout === 'itemsRight' ? 60 : undefined,
            columnGap: pdfLayout === 'itemsBottom' || pdfLayout === 'itemsTop' ? 60 : undefined,
            flexDirection: pdfLayout === 'itemsRight' ? 'column' : 'row'
          }}
        >
          {pdfLayout !== 'itemsHidden' &&
            cards.map((_item, index) => (
              <View
                key={index}
                ref={ref => {
                  dashRefs.current.right[index] = ref;
                  markSchemeLines.measureEverythingIfWeHaveAllRefs();
                }}
              >
                <EasyDragAndDropWithSingleZones.Source id={index} />
              </View>
            ))}
        </DragAndDropSectionPDF>
      </View>
    );

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        variant={pdfItemVariant}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
        defaultState={
          displayMode === 'markscheme' && (!useArrows || pdfLayout !== 'itemsRight')
            ? correctItemOrder
            : undefined
        }
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          questionHeight={questionHeight}
          mainPanelContents={
            <View
              style={{
                flex: 1
              }}
            >
              {/* Content Box */}
              <View style={{ alignSelf: 'center' }}>
                {contentBox && (
                  <ContentBox containerStyle={styles.contentBoxPDF}>
                    <Text variant="WRN400">{contentBox}</Text>
                  </ContentBox>
                )}
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: pdfLayout === 'itemsRight' ? 'row' : 'column'
                }}
              >
                <View
                  style={{
                    flex: 1
                  }}
                >
                  {pdfLayout === 'itemsTop' && displayCards(items)}
                  <View style={{ flex: 1 }}>
                    {statements.map((statement, index) => (
                      <View
                        key={index}
                        style={[
                          statements.length === 1 ? styles.singleStatementPDF : styles.statementPDF,
                          { columnGap: 64 },
                          statementStyle
                        ]}
                      >
                        {typeof statement.lhsComponent !== 'string' ? (
                          statement.lhsComponent
                        ) : (
                          <AutoScaleText
                            containerStyle={styles.statementTextPDF}
                            variant={statementsTextVariant}
                            longestInGroup={longestStatementTextLength}
                            letterEmWidth={statementsLetterEmWidth}
                            maxLines={statementMaxLines}
                            minFontSize={28}
                            maxFontSize={50}
                          >
                            {statement.lhsComponent}
                          </AutoScaleText>
                        )}

                        <View
                          ref={ref => {
                            dashRefs.current.left[index] = ref;
                            markSchemeLines.measureEverythingIfWeHaveAllRefs();
                          }}
                        >
                          {(!useArrows || pdfLayout !== 'itemsRight') &&
                            displayMode === 'markscheme' && (
                              <AssetSvg
                                name="True"
                                width={50}
                                style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                              />
                            )}
                          <EasyDragAndDropWithSingleZones.ZoneSingle id={index} />
                        </View>

                        {typeof statement.rhsComponent !== 'string' ? (
                          statement.rhsComponent
                        ) : (
                          <AutoScaleText
                            containerStyle={styles.statementTextPDF}
                            variant={statementsTextVariant}
                            longestInGroup={longestStatementTextLength}
                            letterEmWidth={statementsLetterEmWidth}
                            maxLines={statementMaxLines}
                            minFontSize={28}
                            maxFontSize={50}
                          >
                            {statement.rhsComponent}
                          </AutoScaleText>
                        )}
                      </View>
                    ))}
                  </View>
                </View>

                {pdfLayout !== 'itemsTop' && displayCards(items)}
              </View>
              {/* The overlay with all the arrows on */}
              {pdfLayout === 'itemsRight' && useArrows && displayMode === 'markscheme' && (
                <View style={StyleSheet.absoluteFill}>
                  <View
                    ref={ref => {
                      svgRef.current = ref;
                      markSchemeLines.measureEverythingIfWeHaveAllRefs();
                    }}
                    style={StyleSheet.absoluteFill}
                  >
                    <Svg width={PDF_WIDTH} height={questionHeight}>
                      {measurements &&
                        correctMatch.map((to, from) => (
                          <Line
                            key={`${to}-${from}`}
                            x1={
                              measurements.left[from].pageX +
                              measurements.left[from].width -
                              measurements.svg!.pageX
                            }
                            y1={
                              measurements.left[from].pageY +
                              measurements.left[from].height / 2 -
                              measurements.svg!.pageY
                            }
                            x2={measurements.right[to].pageX - measurements.svg!.pageX}
                            y2={
                              measurements.right[to].pageY +
                              measurements.right[to].height / 2 -
                              measurements.svg!.pageY
                            }
                            stroke="red"
                            strokeWidth="2"
                          />
                        ))}
                    </Svg>
                  </View>
                </View>
              )}
            </View>
          }
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={filledArray(undefined, numAnswerBoxes)}
      testCorrect={answer =>
        statements.every((statement, index) => {
          const statementAnswer = answer[index];
          return statementAnswer !== undefined && typeof statement.correctAnswer === 'function'
            ? statement.correctAnswer(statementAnswer)
            : statement.correctAnswer === statementAnswer;
        })
      }
      testComplete={answer => answer.every(it => it !== undefined)}
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
      textStyle={styles.text}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection
            itemsStyle={itemVariant === 'shortRectangle' && { justifyContent: 'center' }}
          >
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={[styles.mainPanel, mainPanelStyle]}>
            {contentBox && (
              <ContentBox containerStyle={styles.contentBox}>
                <Text style={{ fontSize: 32 }}>{contentBox}</Text>
              </ContentBox>
            )}
            {statements.map((statement, index) => (
              <View
                key={index}
                style={[
                  statements.length === 1 ? styles.singleStatement : styles.statement,
                  statementStyle
                ]}
              >
                {typeof statement.lhsComponent !== 'string' ? (
                  statement.lhsComponent
                ) : (
                  <AutoScaleText
                    containerStyle={
                      statements.length === 1
                        ? styles.lhsContainerSingleStatement
                        : styles.lhsContainer
                    }
                    variant={statementsTextVariant}
                    longestInGroup={longestStatementTextLength}
                    letterEmWidth={statementsLetterEmWidth}
                    maxLines={statementMaxLines}
                    minFontSize={22}
                    maxFontSize={40}
                  >
                    {statement.lhsComponent}
                  </AutoScaleText>
                )}
                <EasyDragAndDropWithSingleZones.ZoneSingle
                  id={index}
                  style={{ marginHorizontal: 16 }}
                />
                {typeof statement.rhsComponent !== 'string' ? (
                  statement.rhsComponent
                ) : (
                  <AutoScaleText
                    containerStyle={
                      statements.length === 1
                        ? styles.rhsContainerSingleStatement
                        : styles.rhsContainer
                    }
                    variant={statementsTextVariant}
                    longestInGroup={longestStatementTextLength}
                    letterEmWidth={statementsLetterEmWidth}
                    maxLines={statementMaxLines}
                    minFontSize={22}
                    maxFontSize={40}
                  >
                    {statement.rhsComponent}
                  </AutoScaleText>
                )}
              </View>
            ))}
          </View>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row'
  },

  mainPanel: {
    flex: 1,
    alignSelf: 'stretch',
    rowGap: 32,
    justifyContent: 'center'
  },

  statementColumnPDF: {
    flex: 1,
    rowGap: 32,
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  },

  statement: {
    columnGap: 32,
    flexDirection: 'row',
    alignItems: 'center'
  },
  lhsContainer: {
    flex: 1,
    height: 96,
    alignItems: 'flex-end'
  },
  containerPDF: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row'
  },
  rhsContainer: {
    flex: 1,
    height: 96,
    alignItems: 'flex-start'
  },
  ansBoxPDF: {
    justifyContent: 'center',
    alignItems: 'center'
  },

  singleStatement: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  lhsContainerSingleStatement: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  containerSingleStatementPDF: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rhsContainerSingleStatement: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  statementPDF: {
    flex: 1,
    columnGap: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  },
  singleStatementPDF: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'row'
  },
  statementTextPDF: {
    height: 150,
    flex: 1
  },
  text: {
    fontWeight: '700'
  },
  markSchemeContainer: {
    paddingLeft: 50,
    paddingVertical: 10
  },
  markSchemeText: {
    fontSize: 40
  },
  contentBox: {
    minHeight: 75,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  contentBoxPDF: {
    height: 75,
    marginBottom: 50,
    justifyContent: 'center',
    alignSelf: 'flex-start'
  }
});
