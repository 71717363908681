// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import InterpretPictograms from './1InterpretPictograms';
import DrawPictograms from './2DrawPictograms';
import InterpretBarCharts from './3InterpretBarCharts';
import DrawBarCharts from './4DrawBarCharts';
import CollectAndRepresentData from './5CollectAndRepresentData';
import TwoWayTables from './6TwoWayTables';

const Block = newBlockContent({
  block: 'Statistics',

  weeks: [10, 11],
  smallSteps: [
    InterpretPictograms, // Step 1
    DrawPictograms, // Step 2
    InterpretBarCharts, // Step 3
    DrawBarCharts, // Step 4
    CollectAndRepresentData, // Step 5
    TwoWayTables // Step 6
  ]
});
export default Block;
