import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 36 36" {...props}>
    <Path
      fillRule="evenodd"
      d="M34.942 1.253c.566.566.879 1.31.879 2.1s-.313 1.549-.88 2.1L22.298 18.097l12.645 12.645c.566.566.879 1.31.879 2.1s-.313 1.549-.88 2.1a2.953 2.953 0 0 1-2.099.879c-.79 0-1.549-.313-2.1-.88L18.098 22.298 5.453 34.942a2.953 2.953 0 0 1-2.1.879c-.79 0-1.549-.313-2.1-.88a2.954 2.954 0 0 1-.878-2.1c0-.789.312-1.548.878-2.1l12.645-12.644L1.253 5.453a2.928 2.928 0 0 1-.878-2.1c0-.79.312-1.549.878-2.1a2.954 2.954 0 0 1 2.1-.879c.79 0 1.55.313 2.1.88l12.645 12.644 12.63-12.645a2.954 2.954 0 0 1 2.1-.879c.788 0 1.548.313 2.1.88z"
      clipRule="evenodd"
      strokeWidth={1.117}
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
