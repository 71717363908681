export type gridSwitch = {
  grid: number[][];
  dimens: { width: number; height: number };
};

export const gridSwitchRowsOf1 = (set: number): gridSwitch => {
  switch (set) {
    case 0: {
      const zeroGrid = [[]];

      const zeroDimens = { width: 0, height: 0 };

      return { grid: zeroGrid, dimens: zeroDimens };
    }

    case 1: {
      const oneGrid = [[1]];

      const oneDimens = { width: 1, height: 1 };

      return { grid: oneGrid, dimens: oneDimens };
    }

    default:
      throw Error('Received a number not 0 or 1.');
  }
};

export const gridSwitchRowsOf2 = (set: number): gridSwitch => {
  switch (set) {
    case 0: {
      const zeroGrid = [[]];

      const zeroDimens = { width: 0, height: 0 };

      return { grid: zeroGrid, dimens: zeroDimens };
    }

    case 1: {
      const oneGrid = [[1]];

      const oneDimens = { width: 1, height: 1 };

      return { grid: oneGrid, dimens: oneDimens };
    }

    case 2: {
      const twoGrid = [[1, 1]];

      const twoDimens = { width: 2, height: 1 };

      return { grid: twoGrid, dimens: twoDimens };
    }

    default:
      throw Error('Received a number not between 0 and 2.');
  }
};

export const gridSwitchRowsOf3 = (set: number, isRemainder: boolean): gridSwitch => {
  switch (set) {
    case 0: {
      const zeroGrid = [[]];

      const zeroDimens = { width: 0, height: 0 };

      return { grid: zeroGrid, dimens: zeroDimens };
    }

    case 1: {
      const oneGrid = [[1]];

      const oneDimens = { width: 1, height: 1 };

      return { grid: oneGrid, dimens: oneDimens };
    }

    case 2: {
      const twoGrid = [[1], [1]];

      const twoDimens = { width: 1, height: 2 };
      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderTwoGrid = [[1, 1]];

      const remainderTwoDimens = { width: 2, height: 1 };

      return isRemainder
        ? { grid: remainderTwoGrid, dimens: remainderTwoDimens }
        : { grid: twoGrid, dimens: twoDimens };
    }

    case 3: {
      const threeGrid = [[1, 1], [1]];

      const threeDimens = { width: 2, height: 2 };
      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderThreeGrid = [[1, 1, 1]];

      const remainderThreeDimens = { width: 3, height: 1 };

      return isRemainder
        ? { grid: remainderThreeGrid, dimens: remainderThreeDimens }
        : { grid: threeGrid, dimens: threeDimens };
    }

    case 4: {
      const fourGrid = [
        [1, 1],
        [1, 1]
      ];

      const fourDimens = { width: 2, height: 2 };
      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderFourGrid = [[1, 1, 1], [1]];

      const remainderFourDimens = { width: 3, height: 2 };

      return isRemainder
        ? { grid: remainderFourGrid, dimens: remainderFourDimens }
        : { grid: fourGrid, dimens: fourDimens };
    }

    case 5: {
      const fiveGrid = [[1, 1], [1, 1], [1]];

      const fiveDimens = { width: 2, height: 3 };
      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderFiveGrid = [
        [1, 1, 1],
        [1, 1]
      ];

      const remainderFiveDimens = { width: 3, height: 2 };

      return isRemainder
        ? { grid: remainderFiveGrid, dimens: remainderFiveDimens }
        : { grid: fiveGrid, dimens: fiveDimens };
    }

    case 6: {
      const sixGrid = [
        [1, 1],
        [1, 1],
        [1, 1]
      ];

      const sixDimens = { width: 2, height: 3 };

      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderSixGrid = [
        [1, 1, 1],
        [1, 1, 1]
      ];

      const remainderSixDimens = { width: 3, height: 2 };

      return isRemainder
        ? { grid: remainderSixGrid, dimens: remainderSixDimens }
        : { grid: sixGrid, dimens: sixDimens };
    }

    case 7: {
      const sevenGrid = [
        [1, 1, 1],
        [1, 1],
        [1, 1]
      ];

      const sevenDimens = { width: 3, height: 3 };

      // If this is part of a larger number, it needs a different layout as everything above it will be 3 images wide
      const remainderSevenGrid = [[1, 1, 1], [1, 1, 1], [1]];

      return isRemainder
        ? { grid: remainderSevenGrid, dimens: sevenDimens }
        : { grid: sevenGrid, dimens: sevenDimens };
    }

    case 8: {
      const eightGrid = [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1]
      ];

      const eightDimens = { width: 3, height: 3 };

      return { grid: eightGrid, dimens: eightDimens };
    }

    case 9: {
      const nineGrid = [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1]
      ];

      const nineDimens = { width: 3, height: 3 };

      return { grid: nineGrid, dimens: nineDimens };
    }

    default:
      throw Error('Received a digit not between 0 and 9.');
  }
};

export const gridSwitchRowsOf5 = (set: number): gridSwitch => {
  switch (set) {
    case 0: {
      const zeroGrid = [[]];

      const zeroDimens = { width: 0, height: 0 };

      return { grid: zeroGrid, dimens: zeroDimens };
    }

    case 1: {
      const oneGrid = [[1]];

      const oneDimens = { width: 1, height: 1 };

      return { grid: oneGrid, dimens: oneDimens };
    }

    case 2: {
      const twoGrid = [[1, 1]];

      const twoDimens = { width: 2, height: 1 };

      return { grid: twoGrid, dimens: twoDimens };
    }

    case 3: {
      const threeGrid = [[1, 1, 1]];

      const threeDimens = { width: 3, height: 1 };

      return { grid: threeGrid, dimens: threeDimens };
    }

    case 4: {
      const fourGrid = [[1, 1, 1, 1]];

      const fourDimens = { width: 4, height: 1 };

      return { grid: fourGrid, dimens: fourDimens };
    }

    case 5: {
      const fiveGrid = [[1, 1, 1, 1, 1]];

      const fiveDimens = { width: 5, height: 1 };

      return { grid: fiveGrid, dimens: fiveDimens };
    }

    case 6: {
      const sixGrid = [[1, 1, 1, 1, 1], [1]];

      const sixDimens = { width: 5, height: 2 };

      return { grid: sixGrid, dimens: sixDimens };
    }

    case 7: {
      const sevenGrid = [
        [1, 1, 1, 1, 1],
        [1, 1]
      ];

      const sevenDimens = { width: 5, height: 2 };

      return { grid: sevenGrid, dimens: sevenDimens };
    }

    case 8: {
      const eightGrid = [
        [1, 1, 1, 1, 1],
        [1, 1, 1]
      ];

      const eightDimens = { width: 5, height: 2 };

      return { grid: eightGrid, dimens: eightDimens };
    }

    case 9: {
      const nineGrid = [
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1]
      ];

      const nineDimens = { width: 5, height: 2 };

      return { grid: nineGrid, dimens: nineDimens };
    }

    case 10: {
      const tenGrid = [
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1]
      ];

      const tenDimens = { width: 5, height: 2 };

      return { grid: tenGrid, dimens: tenDimens };
    }

    default:
      throw Error('Received a number not between 0 and 10.');
  }
};
