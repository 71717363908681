import { useCallback, useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import Text from '../../../typography/Text';
import { parseMarkup } from '../../../../markup';
import { DisplayMode } from '../../../../contexts/displayMode';
import { DRAGGABLE_DIMENS, DraggableVariant } from '../../../draganddrop/utils';
import { withStateHOC } from '../../../../stateTree';
import { SetState } from '../../../../utils/react';
import TextStructure from '../../../molecules/TextStructure';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { filledArray } from '../../../../utils/collections';

export type CompleteDoubleNumberLine = (number | string)[];

type Props = {
  /**
   * Input box for answer box on the number track, must be specified to know what input
   * is available to the user and how to handle the answer
   */
  inputBox?: (ansIndex: number, boxWidth: number, boxHeight: number) => JSX.Element;
  /**
   * @param tickValues - Array representing the number line tick values/labels.
   */
  topTickValues: CompleteDoubleNumberLine;
  bottomTickValues: CompleteDoubleNumberLine;
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  customBoxWidth?: number;
  customBoxHeight?: number;
  /**
   * Optional symbol shown at left of the input box. Takes either a string to be rendered
   * or a JSX.Element
   */
  inputSymbol?: string | JSX.Element;
  pdfItemVariant?: DraggableVariant;
  onTextInput?: (answer: string, index: number) => void;
  inputMaxCharacters?: number;
  /**
   * @param userAnswer - State to hold the user input answer.
   */
  userAnswer?: string[];
  allowEmptyTicks?: boolean;
  inputTrailingSymbol?: string;
};

/**
 * Representation showing two number lines stacked on top with tick values that cross both horizontal lines
 */
export default function DoubleNumberLine({
  topTickValues,
  inputBox,
  bottomTickValues,
  dimens: { width, height },
  customBoxWidth,
  customBoxHeight,
  pdfItemVariant,
  inputMaxCharacters,
  allowEmptyTicks,
  inputTrailingSymbol,
  userAnswer = [''],
  onTextInput = () => {
    /* do nothing */
  }
}: Props) {
  const displayMode = useContext(DisplayMode);
  // Parse all the tick values. Each tick can have 0, 1 or more input boxes.
  const topTickValuesParsed = topTickValues.map(tick => parseMarkup(String(tick)));
  const bottomTickValuesParsed = bottomTickValues.map(tick => parseMarkup(String(tick)));

  const horizontalPad = 40;
  const pdfHorizontalPad = 140;
  const lineGap = 40;
  const lineWidth =
    width - (displayMode !== 'digital' ? horizontalPad + pdfHorizontalPad : 2 * horizontalPad);

  const tickOverhang = 20;
  const answerTickOverhang = 30;
  const topLineY = height / 2 - lineGap / 2;
  const bottomLineY = height / 2 + lineGap / 2;

  const topTickSpacing = lineWidth / (topTickValues.length - 1);
  const bottomTickSpacing = lineWidth / (bottomTickValues.length - 1);
  const startingTickX = horizontalPad;

  const longestTicks =
    topTickValues.length > bottomTickValues.length ? topTickValues : bottomTickValues;

  const styles = useStyles(width, height);
  const fontSize = scaleNumberLineFontSize(
    longestTicks.map(tick => {
      return tick?.toLocaleString() ?? '';
    }),
    lineWidth,
    displayMode
  );

  // Top line
  const topLine = (
    <Line
      x1={horizontalPad}
      y1={topLineY}
      x2={width - (displayMode !== 'digital' ? pdfHorizontalPad : horizontalPad)}
      y2={topLineY}
      stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
      strokeWidth={displayMode === 'digital' ? 2 : 4}
      key={'TopLine'}
    />
  );

  // Bottom line
  const bottomLine = (
    <Line
      x1={horizontalPad}
      y1={bottomLineY}
      x2={width - (displayMode !== 'digital' ? pdfHorizontalPad : horizontalPad)}
      y2={bottomLineY}
      stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
      strokeWidth={displayMode === 'digital' ? 2 : 4}
      key={'BottomLine'}
    />
  );

  const ticks = (line: 'top' | 'bottom') => {
    const tickValues = line === 'top' ? topTickValues : bottomTickValues;
    const tickValuesParsed = line === 'top' ? topTickValuesParsed : bottomTickValuesParsed;
    const tickSpacing = line === 'top' ? topTickSpacing : topTickSpacing;
    const answerY1 = topLineY - answerTickOverhang;
    const answerY2 = bottomLineY + answerTickOverhang;
    const y1 = line === 'top' ? topLineY - tickOverhang : bottomLineY + tickOverhang;
    const y2 = line === 'top' ? topLineY + lineGap / 2 : bottomLineY - lineGap / 2;

    return tickValues.map((tick, index) => {
      const tickX = startingTickX + index * tickSpacing;
      // Render different style of tick if answer box present
      const answer = tickValuesParsed[index].numberOfAns > 0;
      // the answer ticks will go from top to bottom so we only need to draw this once
      const noTick =
        topTickSpacing === bottomTickSpacing &&
        !answer &&
        (topTickValuesParsed[index].numberOfAns > 0 ||
          bottomTickValuesParsed[index].numberOfAns > 0);
      if (noTick) return;
      if (tickValuesParsed[index].tokens.length > 0 || (tick === '' && allowEmptyTicks))
        return (
          <Line
            x1={tickX}
            y1={answer ? answerY1 : y1}
            x2={tickX}
            y2={answer ? answerY2 : y2}
            stroke={answer ? colors.burntSienna : colors.prussianBlue}
            strokeWidth={
              answer ? (displayMode === 'digital' ? 4 : 8) : displayMode === 'digital' ? 2 : 4
            }
            key={'tick' + index}
          />
        );
    });
  };

  let ansIndex = -1;
  const pdfAnswerBoxWidth = pdfItemVariant ? DRAGGABLE_DIMENS[pdfItemVariant].width : 150;
  const pdfAnswerBoxHeight = pdfItemVariant ? DRAGGABLE_DIMENS[pdfItemVariant].height : 150;
  const textVerticalPad = displayMode === 'digital' ? 64 : 80;
  const answerBoxHeight = displayMode === 'digital' ? 88 : pdfAnswerBoxHeight;
  const answerBoxWidth = displayMode === 'digital' ? 88 : pdfAnswerBoxWidth;

  const boxWidth = customBoxWidth ?? answerBoxWidth;
  const boxHeight = customBoxHeight ?? answerBoxHeight;

  const tickLabels = (line: 'top' | 'bottom') => {
    const tickValues = line === 'top' ? topTickValues : bottomTickValues;
    const tickValuesParsed = line === 'top' ? topTickValuesParsed : bottomTickValuesParsed;
    const tickSpacing = line === 'top' ? topTickSpacing : topTickSpacing;
    const inputTop =
      line === 'top' ? topLineY - answerTickOverhang - boxHeight : bottomLineY + answerTickOverhang;
    const valueTop =
      line === 'top'
        ? topLineY - answerTickOverhang - textVerticalPad
        : bottomLineY + answerTickOverhang;
    return tickValues.map((tick, index) => {
      const tickX = startingTickX + index * tickSpacing;
      if (tickValuesParsed[index].numberOfAns > 0) {
        const ansCount = tickValuesParsed[index].numberOfAns;
        // Add number of answers for this tick to index
        ansIndex += ansCount;

        // Create scoped copy, otherwise all answer indices will equal final index
        const i = ansIndex;
        return (
          <View
            key={'tickNum' + line + index}
            style={[
              styles.completeNumberLineLabel,
              {
                left: tickX - 60,
                top: inputTop
              }
            ]}
          >
            {inputBox ? (
              inputBox(ansIndex, boxWidth, boxHeight)
            ) : (
              <>
                <TextStructure
                  sentence={tick.toString()}
                  inputBox={({ index }) => {
                    // Adjust the answer index to be correct for the number of answers
                    // This is done by the following calculation: i-count+(index+1)
                    // i = the total number of answers to date including the answers at this tick
                    // count = number of answers at this tick
                    // index = the index of the answer at this tick (0,1,2)
                    return (
                      <NoKeyboardTextInput
                        key={i - ansCount + (index + 1)}
                        value={userAnswer[i - ansCount + (index + 1)]}
                        onChangeText={text => {
                          onTextInput(text, i - ansCount + (index + 1));
                        }}
                        maxCharacters={inputMaxCharacters}
                        style={{ fontSize, bottom: line === 'top' ? 8 : 16 }}
                      />
                    );
                  }}
                  textStyle={[styles.text, { fontSize }]}
                  fractionTextStyle={{ fontSize }}
                  fractionContainerStyle={{ height: ansCount > 0 ? 96 : 48 }}
                />
                <Text
                  variant="WRN400"
                  style={{
                    fontSize: fontSize,
                    position: 'absolute',
                    left: displayMode === 'digital' ? 110 : 170,
                    height: displayMode === 'digital' ? 96 : 150
                  }}
                >
                  {inputTrailingSymbol}
                </Text>
              </>
            )}
          </View>
        );
      } else {
        return (
          <View
            key={'tickNum' + line + index}
            style={[styles.completeNumberLineLabel, { left: tickX - 60, top: valueTop }]}
          >
            <Text numberOfLines={1} style={[styles.text, { fontSize }]}>
              {tick !== null ? tick.toLocaleString() : undefined}
            </Text>
          </View>
        );
      }
    });
  };

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={400}
        viewBox={`0 0 ${width} ${400}`}
        style={styles.svg}
        pointerEvents={'none'}
      >
        {topLine}
        {bottomLine}
        {ticks('top')}
        {ticks('bottom')}
      </Svg>
      {tickLabels('top')}
      {tickLabels('bottom')}
    </View>
  );
}

/**
 * Simple wrapper around {@link NumberLine} which makes it easier to use with {@link withStateHOC}.
 * Replaces `onTextInput` prop (which works on one text input at a time) with `setUserAnswer` prop
 * (which works on all of the text inputs simultaneously).
 */
function DoubleNumberLineThatsEasierToUseWithWithState({
  setUserAnswer,
  ...props
}: Omit<Props, 'onTextInput'> & { setUserAnswer: SetState<string[]> }) {
  const onTextInput = useCallback(
    (answer: string, index: number) => {
      setUserAnswer(old => {
        const newArr = [...old];
        newArr[index] = answer;
        return newArr;
      });
    },
    [setUserAnswer]
  );
  return DoubleNumberLine({ onTextInput, ...props });
}

export const DoubleNumberLineWithState = withStateHOC(
  DoubleNumberLineThatsEasierToUseWithWithState,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer',
    defaults: props => ({
      testComplete: state => state !== undefined && state.every(it => it !== ''),
      defaultState: filledArray('', getAnswerCount(props))
    })
  }
);

const getAnswerCount = (props: Omit<Props, 'inputBox'>) => {
  const topTickValuesParsed = props.topTickValues
    .map(i => i.toString())
    .map(ticks => parseMarkup(ticks))
    .filter(i => i.numberOfAns > 0);
  const bottomTickValuesParsed = props.bottomTickValues
    .map(i => i.toString())
    .map(ticks => parseMarkup(ticks))
    .filter(i => i.numberOfAns > 0);
  return topTickValuesParsed.length + bottomTickValuesParsed.length;
};

function useStyles(width: number, height: number) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height
        },
        svg: {
          position: 'absolute',
          top: 0,
          left: 0
        },
        text: {
          width: 120,
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          overflow: 'visible'
        },
        completeNumberLineLabel: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120
        }
      }),
    [width, height]
  );
}
