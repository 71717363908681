import {
  filledArray,
  range,
  rangeAsString,
  arrayHasNoDuplicates,
  NonEmptyArray
} from 'common/src/utils/collections';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  getTwoConsecutiveAndOneNot
} from 'common/src/utils/random';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { isWithinRange } from '../../../../utils/math';

////
// Questions
////

// Exports to question abI
const Question1 = newQuestionContent({
  uid: 'aa2',
  description: 'aa2',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    numbers: z.array(z.number().int().min(100).max(900)).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 1000;
    const interval = 100;

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and a third that isn’t */
    const numbers = getTwoConsecutiveAndOneNot(
      startingNumber + interval,
      endNumber - interval,
      interval
    );

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1000;
    const interval = 100;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'aa3',
  description: 'aa3',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(50).max(950)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 100;
    const middle = (startingNumber + endNumber) / 2;

    // Make this miss the first, last and middle number
    const choices = range(
      startingNumber + tickInterval / 2,
      endNumber - tickInterval / 2,
      tickInterval / 2
    ).filter(i => i !== middle) as NonEmptyArray<number>;

    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 100;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;
    const middle = Math.floor((tickValues.length - 1) / 2);
    tickValues[middle] = middle * tickInterval;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={answer => isWithinRange(parseInt(answer[0]), number, 10)}
        inputMaxCharacters={4}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answersToDisplay: [number.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(10)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aa4',
  description: 'aa4',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(0).max(1000)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 100;
    const answerInterval = 50;

    // Make this miss the first and last number
    const choices = range(startingNumber + tickInterval, endNumber - tickInterval, answerInterval);
    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 100;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 20, number + 20]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'aa5',
  description: 'aa5',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(100).max(1000),
      startingNumber: z.number().int().min(100).max(900),
      endNumber: z.number().int().min(110).max(1000)
    })
    .refine(
      val => val.number > val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number < val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 9) * 100;
    const endNumber = startingNumber + 100;
    const answerInterval = 10;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 10;
    const sliderInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 2, number + 2]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question5 = newQuestionContent({
  uid: 'aa6',
  description: 'aa6',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(110).max(998)).length(3).refine(arrayHasNoDuplicates),
      startingNumber: z.number().int().min(0).max(990).multipleOf(10),
      endNumber: z.number().int().min(120).max(1000).multipleOf(10),
      interval: z.union([z.literal(2), z.literal(20), z.literal(200)])
    })
    .refine(
      val => val.numbers.every(number => number >= val.startingNumber + val.interval),
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.numbers.every(number => number <= val.endNumber - val.interval),
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([2, 20, 200] as const);

    // StartingNumber: Multiple of 5*interval, >100 and <1000-5*interval
    // except if interval=200, then start=0
    let startingNumber = 0;
    switch (interval) {
      case 2:
        startingNumber = getRandomFromArray(range(110, 990, 10));
        break;
      case 20:
        startingNumber = getRandomFromArray(range(200, 900, 100));
        break;
    }
    const endNumber = startingNumber + 5 * interval;

    const choices = range(startingNumber + interval, endNumber - interval, interval);

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and and third that isn’t?
     * Since this only leaves 4 numbers that are selectable here there are only
     * 2 choices variants we can chose here. */
    const numbers =
      randomIntegerInclusive(1, 2) === 1
        ? [choices[0], choices[1], choices[3]]
        : [choices[0], choices[2], choices[3]];

    return { numbers, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { numbers, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'aa7',
  description: 'aa7',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(110).max(1000),
      startingNumber: z.number().int().min(110).max(990),
      endNumber: z.number().int().min(120).max(1000),
      interval: z.union([z.literal(1), z.literal(2), z.literal(5), z.literal(10), z.literal(20)])
    })
    .refine(
      val => val.number >= val.startingNumber + val.interval,
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.number <= val.endNumber - val.interval,
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([1, 2, 5, 10, 20] as const);
    // multiple of interval less than 1000
    const startingNumber = getRandomFromArray(
      range(100 + 10 * interval, 1000 - 10 * interval, interval)
    );
    const endNumber = startingNumber + interval * 10;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start, middle and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLineTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
export const aa2 = Question1;
export const aa4 = Question3;
