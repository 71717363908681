import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats } from '../../../../utils/math';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ShapeNames } from '../../../../utils/labelPositions';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEK',
  description: 'aEK',
  keywords: ['Circles', 'Radius', 'Circumference', 'Diameter', 'Centre'],
  schema: z.object({
    partOfCircle: z.enum(['centre', 'circumference', 'diameter', 'radius']),
    flipX: z.boolean(),
    flipY: z.boolean()
  }),
  simpleGenerator: () => {
    const partOfCircle = getRandomFromArray([
      'centre',
      'circumference',
      'diameter',
      'radius'
    ] as const);

    const flipX = getRandomBoolean();

    const flipY = getRandomBoolean();

    return { partOfCircle, flipX, flipY };
  },
  Component: props => {
    const {
      question: { partOfCircle, flipX, flipY },
      translate,
      displayMode
    } = props;

    const random = seededRandom(props.question);

    const statements = shuffle(
      [
        translate.shapes.centre(),
        translate.shapes.circumference(),
        translate.shapes.diameter(),
        translate.shapes.radius()
      ],
      {
        random
      }
    );

    const [svgPath, answer] = (() => {
      switch (partOfCircle) {
        case 'centre':
          return ['Circles/Parts_of_a_circle/Circle_parts_centre_arrow', translate.shapes.centre()];
        case 'circumference':
          return [
            'Circles/Parts_of_a_circle/Circle_parts_circumference_arrow',
            translate.shapes.circumference()
          ];
        case 'diameter':
          return [
            'Circles/Parts_of_a_circle/Circle_parts_diameter_arrow',
            translate.shapes.diameter()
          ];
        case 'radius':
          return ['Circles/Parts_of_a_circle/Circle_parts_radius_arrow', translate.shapes.radius()];
      }
    })() as [SvgName, string];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectLabelForPartOfCircleArrowIsPointingTo()}
        pdfTitle={translate.instructions.circleLabelForPartOfCircleArrowIsPointingTo()}
        Content={({ dimens }) => (
          <View style={{ transform: [{ scaleX: flipX ? -1 : 1 }, { scaleY: flipY ? -1 : 1 }] }}>
            <AssetSvg name={svgPath} height={dimens.height * 0.9} />
          </View>
        )}
        numItems={4}
        itemLayout="row"
        renderItems={statements.map(label => ({
          component: (
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 30 : 50 }}>
              {label}
            </Text>
          ),
          value: label
        }))}
        innerContainerStyle={{ gap: 32 }}
        testCorrect={[answer]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aEL',
  description: 'aEL',
  keywords: ['Circles', 'Radius'],
  schema: z
    .object({
      incorrectRadius1: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      incorrectRadius2: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      incorrectRadius3: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      correctRadiusRotation: z.number().int().min(0).max(359),
      incorrectRadius1Rotation: z.number().int().min(0).max(359),
      incorrectRadius2Rotation: z.number().int().min(0).max(359),
      incorrectRadius3Rotation: z.number().int().min(0).max(359)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([val.incorrectRadius1, val.incorrectRadius2, val.incorrectRadius3]),
      'All incorrect radius types must be different.'
    ),
  simpleGenerator: () => {
    const [incorrectRadius1, incorrectRadius2, incorrectRadius3] = getRandomSubArrayFromArray(
      ['A', 'B', 'C', 'D', 'E', 'F'] as const,
      3
    );

    const [
      correctRadiusRotation,
      incorrectRadius1Rotation,
      incorrectRadius2Rotation,
      incorrectRadius3Rotation
    ] = randomUniqueIntegersInclusive(0, 359, 4);

    return {
      incorrectRadius1,
      incorrectRadius2,
      incorrectRadius3,
      correctRadiusRotation,
      incorrectRadius1Rotation,
      incorrectRadius2Rotation,
      incorrectRadius3Rotation
    };
  },
  Component: props => {
    const {
      question: {
        incorrectRadius1,
        incorrectRadius2,
        incorrectRadius3,
        correctRadiusRotation,
        incorrectRadius1Rotation,
        incorrectRadius2Rotation,
        incorrectRadius3Rotation
      },
      translate
    } = props;

    const getIncorrectRadiusPath = (form: 'A' | 'B' | 'C' | 'D' | 'E' | 'F') => {
      switch (form) {
        case 'A':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_A';
        case 'B':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_B';
        case 'C':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_C';
        case 'D':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_D';
        case 'E':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_E';
        case 'F':
          return 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_F';
      }
    };

    const statements = [
      {
        svgPath: 'Circles/Parts_of_a_circle/Circle_parts_radius_correct',
        rotation: correctRadiusRotation,
        isCorrect: true
      },
      {
        svgPath: getIncorrectRadiusPath(incorrectRadius1),
        rotation: incorrectRadius1Rotation,
        isCorrect: false
      },
      {
        svgPath: getIncorrectRadiusPath(incorrectRadius2),
        rotation: incorrectRadius2Rotation,
        isCorrect: false
      },
      {
        svgPath: getIncorrectRadiusPath(incorrectRadius3),
        rotation: incorrectRadius3Rotation,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectCircleThatHasRadiusMarkedCorrectly()}
        pdfTitle={translate.instructions.drawARingAroundCircleThatHasRadiusMarkedCorrectly()}
        testCorrect={statements
          .filter(radius => radius.isCorrect)
          .map(statement => statement.svgPath)}
        numItems={4}
        renderItems={({ dimens }) => {
          return shuffledStatements.map(({ svgPath, rotation }) => ({
            value: svgPath,
            component: (
              <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
                <AssetSvg name={svgPath as SvgName} height={dimens.height * 0.9} />
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aEM',
  description: 'aEM',
  keywords: ['Circles', 'Diameter'],
  schema: z
    .object({
      incorrectDiameter1: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      incorrectDiameter2: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      incorrectDiameter3: z.enum(['A', 'B', 'C', 'D', 'E', 'F']),
      correctDiameterRotation: z.number().int().min(0).max(359),
      incorrectDiameter1Rotation: z.number().int().min(0).max(359),
      incorrectDiameter2Rotation: z.number().int().min(0).max(359),
      incorrectDiameter3Rotation: z.number().int().min(0).max(359)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.incorrectDiameter1,
          val.incorrectDiameter2,
          val.incorrectDiameter3
        ]),
      'All incorrect diameter types must be different.'
    ),
  simpleGenerator: () => {
    const [incorrectDiameter1, incorrectDiameter2, incorrectDiameter3] = getRandomSubArrayFromArray(
      ['A', 'B', 'C', 'D', 'E', 'F'] as const,
      3
    );

    const [
      correctDiameterRotation,
      incorrectDiameter1Rotation,
      incorrectDiameter2Rotation,
      incorrectDiameter3Rotation
    ] = randomUniqueIntegersInclusive(0, 359, 4);

    return {
      incorrectDiameter1,
      incorrectDiameter2,
      incorrectDiameter3,
      correctDiameterRotation,
      incorrectDiameter1Rotation,
      incorrectDiameter2Rotation,
      incorrectDiameter3Rotation
    };
  },
  Component: props => {
    const {
      question: {
        incorrectDiameter1,
        incorrectDiameter2,
        incorrectDiameter3,
        correctDiameterRotation,
        incorrectDiameter1Rotation,
        incorrectDiameter2Rotation,
        incorrectDiameter3Rotation
      },
      translate
    } = props;

    const getIncorrectDiameterPath = (form: 'A' | 'B' | 'C' | 'D' | 'E' | 'F') => {
      switch (form) {
        case 'A':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_A';
        case 'B':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_B';
        case 'C':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_C';
        case 'D':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_D';
        case 'E':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_E';
        case 'F':
          return 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_F';
      }
    };

    const statements = [
      {
        svgPath: 'Circles/Parts_of_a_circle/Circle_parts_diameter_correct',
        rotation: correctDiameterRotation,
        isCorrect: true
      },
      {
        svgPath: getIncorrectDiameterPath(incorrectDiameter1),
        rotation: incorrectDiameter1Rotation,
        isCorrect: false
      },
      {
        svgPath: getIncorrectDiameterPath(incorrectDiameter2),
        rotation: incorrectDiameter2Rotation,
        isCorrect: false
      },
      {
        svgPath: getIncorrectDiameterPath(incorrectDiameter3),
        rotation: incorrectDiameter3Rotation,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectCircleThatHasDiameterMarkedCorrectly()}
        pdfTitle={translate.instructions.drawARingAroundCircleThatHasDiameterMarkedCorrectly()}
        testCorrect={statements
          .filter(diameter => diameter.isCorrect)
          .map(statement => statement.svgPath)}
        numItems={4}
        renderItems={({ dimens }) => {
          return shuffledStatements.map(({ svgPath, rotation }) => ({
            value: svgPath,
            component: (
              <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
                <AssetSvg name={svgPath as SvgName} height={dimens.height * 0.9} />
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aEN',
  description: 'aEN',
  keywords: ['Circles', 'Diameter', 'Radius'],
  schema: z.object({
    radius: z.number().min(2).max(16.9),
    unit: z.enum(['mm', 'cm', 'm', 'km'])
  }),
  simpleGenerator: () => {
    const radius = randomIntegerInclusive(20, 169) / 10;
    const unit = getRandomFromArray(['mm', 'cm', 'm', 'km'] as const);

    return { radius, unit };
  },
  Component: props => {
    const {
      question: { radius, unit },
      translate
    } = props;

    const sentence = (() => {
      switch (unit) {
        case 'mm':
          return translate.answerSentences.ansMm();
        case 'cm':
          return translate.answerSentences.ansCm();
        case 'm':
          return translate.answerSentences.ansM();
        default:
          return translate.answerSentences.ansKm();
      }
    })();

    const translatedMeasurement = (() => {
      switch (unit) {
        case 'mm':
          return translate.units.mm();
        case 'cm':
          return translate.units.cm();
        case 'm':
          return translate.units.m();
        default:
          return translate.units.km();
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        sentence={sentence}
        extraSymbol="decimalPoint"
        title={translate.instructions.radiusIsXWhatIsDiameter(radius, translatedMeasurement)}
        testCorrect={userAnswer => compareFloats((radius * 2).toString(), userAnswer[0])}
        customMarkSchemeAnswer={{ answersToDisplay: [(radius * 2).toLocaleString()] }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={4}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aEO',
  description: 'aEO',
  keywords: ['Circles', 'Diameter', 'Radius'],
  schema: z.object({
    diameter: z.number().min(2).max(32.9),
    unit: z.enum(['mm', 'cm', 'm', 'km'])
  }),
  simpleGenerator: () => {
    const diameter = randomIntegerInclusive(20, 329) / 10;
    const unit = getRandomFromArray(['mm', 'cm', 'm', 'km'] as const);

    return { diameter, unit };
  },
  Component: props => {
    const {
      question: { diameter, unit },
      translate
    } = props;

    const sentence = (() => {
      switch (unit) {
        case 'mm':
          return translate.answerSentences.ansMm();
        case 'cm':
          return translate.answerSentences.ansCm();
        case 'm':
          return translate.answerSentences.ansM();
        default:
          return translate.answerSentences.ansKm();
      }
    })();

    const translatedMeasurement = (() => {
      switch (unit) {
        case 'mm':
          return translate.units.mm();
        case 'cm':
          return translate.units.cm();
        case 'm':
          return translate.units.m();
        default:
          return translate.units.km();
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        sentence={sentence}
        extraSymbol="decimalPoint"
        title={translate.instructions.diameterIsXWhatIsRadius(diameter, translatedMeasurement)}
        testCorrect={userAnswer => compareFloats((diameter / 2).toString(), userAnswer[0])}
        customMarkSchemeAnswer={{ answersToDisplay: [(diameter / 2).toLocaleString()] }}
        inputMaxCharacters={4}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aEP',
  description: 'aEP',
  keywords: ['Circles', 'Diameter', 'Radius'],
  schema: z.object({
    innerCircleRadius: z.number().int().min(5).max(12),
    remainingRadius: z.number().int().min(1).max(4),
    radiusOrDiameter: z.enum(['radius', 'diameter'])
  }),
  simpleGenerator: () => {
    const innerCircleRadius = randomIntegerInclusive(5, 12);

    const remainingRadius = randomIntegerInclusive(1, 4);

    const radiusOrDiameter = getRandomFromArray(['radius', 'diameter'] as const);

    return { innerCircleRadius, remainingRadius, radiusOrDiameter };
  },
  Component: props => {
    const {
      question: { innerCircleRadius, remainingRadius, radiusOrDiameter },
      translate
    } = props;

    const outerCircleRadius = innerCircleRadius + remainingRadius;

    const innerRadiusString = translate.units.numberOfCm(innerCircleRadius);

    const remainingRadiusString = translate.units.numberOfCm(remainingRadius);

    const random = seededRandom(props.question);

    // Want to use medium circle SVGs if the difference in the radii is close, otherwise use the large circle SVGs.
    const possibleSvgPaths =
      Math.abs(innerCircleRadius - remainingRadius) < 3
        ? [
            'Circles/Inner_outer_circles/Inner_outer_circle_medium_A',
            'Circles/Inner_outer_circles/Inner_outer_circle_medium_B',
            'Circles/Inner_outer_circles/Inner_outer_circle_medium_C'
          ]
        : [
            'Circles/Inner_outer_circles/Inner_outer_circle_large_A',
            'Circles/Inner_outer_circles/Inner_outer_circle_large_B',
            'Circles/Inner_outer_circles/Inner_outer_circle_large_C'
          ];

    const selectedSvgPath = getRandomFromArray(possibleSvgPaths, {
      random
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.twoCirclesHaveSameCentreCompleteSentence()}
        sentence={
          radiusOrDiameter === 'radius'
            ? translate.answerSentences.theRadiusOfOuterCircleIsAnsCm()
            : translate.answerSentences.theDiameterOfOuterCircleIsAnsCm()
        }
        pdfDirection="column"
        testCorrect={[
          radiusOrDiameter === 'radius'
            ? outerCircleRadius.toString()
            : (outerCircleRadius * 2).toString()
        ]}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={selectedSvgPath as ShapeNames}
            labels={[innerRadiusString, remainingRadiusString]}
            shapeWidthReduction={0}
            shapeHeightReduction={0}
          />
        )}
        questionHeight={1000}
        pdfSentenceStyle={{ padding: 0 }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Circles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
