import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import {
  arrayHasNoDuplicates,
  arrayIntersection,
  sortNumberArray
} from 'common/src/utils/collections';
import { getRandomObject, objectAsWord, objectSchema } from 'common/src/utils/objects';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import { ScientificNotation, findZeroesInt, numberWithEnforcedDigit } from 'common/src/utils/math';
import { useMemo } from 'react';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from 'common/src/components/question/representations/Place Value Chart/PlaceValueChart';
import { ADD, SUB } from 'common/src/constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

const constants = { SUB, ADD };

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aiM',
  description: 'aiM',
  keywords: ['Addition', 'Subtraction', 'Pattern'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(9),
      number2: z.number().int().min(2).max(9),
      multiple: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      object: objectSchema
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 cannot be the same.'),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(2, 9, 2);

    const multiple = getRandomFromArray([10, 100, 1000] as const);

    const object = getRandomObject();

    return { number1, number2, multiple, object };
  },

  Component: props => {
    const {
      question: { number1, number2, multiple, object },
      translate,
      displayMode
    } = props;

    const total = number1 + number2;

    const objectToWord = objectAsWord(object, translate, true);

    const multipleAsWord = () => {
      switch (multiple) {
        case 10:
          return translate.keywords.Tens().toLowerCase();
        case 100:
          return translate.keywords.Hundreds().toLowerCase();
        case 1000:
          return translate.keywords.Thousands().toLowerCase();
      }
    };

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [total.toString()],
          [total.toString()],
          [total.toString()],
          [(total * multiple).toString()]
        ]}
        sentences={[
          `${number1.toLocaleString()} + ${number2.toLocaleString()} = <ans/>`,
          translate.answerSentences.numObjAddNumObjEqualsNumObj(number1, objectToWord, number2),
          `${number1.toLocaleString()} ${multipleAsWord()} + ${number2.toLocaleString()} ${multipleAsWord()} = <ans/> ${multipleAsWord()}`,
          `${(number1 * multiple).toLocaleString()} + ${(
            number2 * multiple
          ).toLocaleString()} = <ans/>`
        ]}
        textStyle={{ fontSize: displayMode !== 'digital' ? 50 : 28 }}
        containerStyle={{ alignItems: 'flex-start', alignContent: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aiN',
  description: 'aiN',
  keywords: ['Addition', 'Subtraction', 'Pattern'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(9),
      number2: z.number().int().min(2).max(9),
      multA: z.union([z.literal(10), z.literal(100), z.literal(1000), z.literal(10000)]),
      multB: z.union([z.literal(10), z.literal(100), z.literal(1000), z.literal(10000)]),
      multC: z.union([z.literal(10), z.literal(100), z.literal(1000), z.literal(10000)]),
      multD: z.union([z.literal(10), z.literal(100), z.literal(1000), z.literal(10000)])
    })
    .refine(val => val.number1 + val.number2 > 10)
    .refine(val => arrayHasNoDuplicates([val.multA, val.multB, val.multC, val.multD]))
    .refine(val => val.multA < val.multB)
    .refine(val => val.multC < val.multD),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    const number2 = randomIntegerInclusive(2, 9, {
      constraint: x => x + number1 > 10
    });

    const [aOrB1, aOrB2, cOrD1, cOrD2] = shuffle([10, 100, 1000, 10000] as const);
    const [multA, multB] = sortNumberArray([aOrB1, aOrB2]);
    const [multC, multD] = sortNumberArray([cOrD1, cOrD2]);

    return { number1, number2, multA, multB, multC, multD };
  },

  Component: props => {
    const {
      question: { number1, number2, multA, multB, multC, multD },
      translate,
      displayMode
    } = props;

    const eqA = getBinOpEquation({
      left: number1 * multA,
      right: number2 * multA,
      sign: 'add',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: number1 * multB,
      right: number2 * multB,
      sign: 'add',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: number1 * multC,
      right: number2 * multC,
      sign: 'add',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      left: number1 * multD,
      right: number2 * multD,
      sign: 'add',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatXPlusYEqualsZ(
          number1,
          number2,
          number1 + number2
        )}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        textStyle={{ fontSize: displayMode !== 'digital' ? 50 : 32 }}
        containerStyle={{ rowGap: 16 }}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aiO',
  description: 'aiO',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s', 'Base 10'],
  schema: z
    .object({
      var1: z.number().int().min(10_000).max(100_000),
      var2: z.number().int(),
      operation: z.enum(['ADD', 'SUB'])
    })
    .refine(
      val => val.var2 % 100 === 0 || val.var2 % 1000 === 0 || val.var2 % 10_000 === 0,
      'var2 should be a multiple of 100, 1000 or 10,000'
    )
    .refine(
      val =>
        val.operation === 'ADD'
          ? numbersDoNotExchange(val.var1, val.var2)
          : numbersDoNotExchange(val.var1, -val.var2),
      'numbers should not exchange'
    ),
  simpleGenerator: () => {
    const operation = getRandomFromArray(['ADD', 'SUB'] as const);
    const PVFactor = getRandomFromArray([100, 1000, 10_000]);

    //The constraint on var1 depends on which place value number is being changed and operation
    const var1 = randomIntegerInclusive(10_000, 99_000, {
      constraint: x =>
        operation === 'ADD'
          ? x % (PVFactor * 10) < 5 * PVFactor
          : x % (PVFactor * 10) > 5 * PVFactor
    });

    const var2 = randomIntegerInclusiveStep(1 * PVFactor, 5 * PVFactor, PVFactor);

    return {
      var1,
      var2,
      operation
    };
  },
  Component: ({ question: { var1, var2, operation }, translate }) => {
    const ans = operation === 'ADD' ? var1 + var2 : var1 - var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useThePVCToHelpYouWorkOutTheMissingNumber()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(var1)}
            columnsToShow={[4, 3, 2, 1, 0]}
            counterVariant={'greyCounter'}
            headerVariant={'shortName'}
            dimens={dimens}
          />
        )}
        sentence={`${var1.toLocaleString()} ${
          constants[operation]
        } ${var2.toLocaleString()} = <ans/>`}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aiP',
  description: 'aiP',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(89999),
      numberA2: z.number().int().min(1000).max(89999),
      numberB1: z.number().int().min(1000).max(89999),
      numberB2: z.number().int().min(1000).max(89999)
    })
    .refine(
      val => val.numberA1 + val.numberA2 < 100000,
      'numberA1 + numberA2 must be less than 100,000'
    )
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 + numberA2 must not exchange'
    )
    .refine(
      val => val.numberB1 + val.numberB2 < 100000,
      'numberB1 + numberB2 must be less than 100,000'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 + numberB2 must not exchange'
    )
    .refine(
      ({ numberA1, numberA2 }) =>
        arrayIntersection(findZeroesInt(numberA1), findZeroesInt(numberA2)).length >= 2,
      'numberA1 and numberA2 must have at least 2 zeroes in common'
    )
    .refine(
      ({ numberB1, numberB2 }) =>
        arrayIntersection(findZeroesInt(numberB1), findZeroesInt(numberB2)).length >= 2,
      'numberB1 and numberB2 must have at least 2 zeroes in common'
    ),
  simpleGenerator: () => {
    const doubleEnforcedZeroes = (
      number: number,
      firstEnforced: number,
      secondEnforced: number
    ): number => {
      const firstZeroEnforcedNum = numberWithEnforcedDigit(number, firstEnforced, 0);
      return numberWithEnforcedDigit(firstZeroEnforcedNum, secondEnforced, 0);
    };

    // Question A:
    const thousandsOrTenThousandsA = getRandomFromArray(['thousands', 'tenThousands'] as const);

    // Do not allow the highest digit in a number to be a zero.
    const enforcedZeroPowerA1 =
      thousandsOrTenThousandsA === 'thousands'
        ? randomIntegerInclusive(0, 2)
        : randomIntegerInclusive(0, 3);

    const enforcedZeroPowerA2 =
      thousandsOrTenThousandsA === 'thousands'
        ? randomIntegerInclusive(0, 2, {
            constraint: x => x !== enforcedZeroPowerA1
          })
        : randomIntegerInclusive(0, 3, {
            constraint: x => x !== enforcedZeroPowerA1
          });

    // numberA1 + numberA2 must not exchange, therefore can only be from 1000-8999
    const numberA1Generated =
      thousandsOrTenThousandsA === 'thousands'
        ? randomIntegerInclusive(1000, 8999)
        : randomIntegerInclusive(10000, 89999);

    const numberA2Generated =
      thousandsOrTenThousandsA === 'thousands'
        ? randomIntegerInclusive(1000, 8999, {
            constraint: x => numbersDoNotExchange(numberA1Generated, x)
          })
        : randomIntegerInclusive(10000, 89999, {
            constraint: x => numbersDoNotExchange(numberA1Generated, x)
          });

    const numberA1 = doubleEnforcedZeroes(
      numberA1Generated,
      enforcedZeroPowerA1,
      enforcedZeroPowerA2
    );
    const numberA2 = doubleEnforcedZeroes(
      numberA2Generated,
      enforcedZeroPowerA1,
      enforcedZeroPowerA2
    );

    // Question B:
    const thousandsOrTenThousandsB = getRandomFromArray(['thousands', 'tenThousands'] as const);

    // Do not allow the highest digit in a number to be a zero.
    const enforcedZeroPowerB1 =
      thousandsOrTenThousandsB === 'thousands'
        ? randomIntegerInclusive(0, 2)
        : randomIntegerInclusive(0, 3);

    const enforcedZeroPowerB2 =
      thousandsOrTenThousandsB === 'thousands'
        ? randomIntegerInclusive(0, 2, {
            constraint: x => x !== enforcedZeroPowerB1
          })
        : randomIntegerInclusive(0, 3, {
            constraint: x => x !== enforcedZeroPowerB1
          });

    // numberB1 + numberB2 must not exchange, therefore can only be from 1000-8999
    const numberB1Generated =
      thousandsOrTenThousandsB === 'thousands'
        ? randomIntegerInclusive(1000, 8999)
        : randomIntegerInclusive(10000, 89999);

    const numberB2Generated =
      thousandsOrTenThousandsB === 'thousands'
        ? randomIntegerInclusive(1000, 8999, {
            constraint: x => numbersDoNotExchange(numberB1Generated, x)
          })
        : randomIntegerInclusive(10000, 89999, {
            constraint: x => numbersDoNotExchange(numberB1Generated, x)
          });

    const numberB1 = doubleEnforcedZeroes(
      numberB1Generated,
      enforcedZeroPowerB1,
      enforcedZeroPowerB2
    );
    const numberB2 = doubleEnforcedZeroes(
      numberB2Generated,
      enforcedZeroPowerB1,
      enforcedZeroPowerB2
    );

    return { numberA1, numberA2, numberB1, numberB2 };
  },

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'add',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aiQ',
  description: 'aiQ',
  keywords: ['Subtraction', 'Efficient'],
  schema: z.object({
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    // Needed in order to get variation each time it's generated
    const seed = randomIntegerInclusive(1, 999);
    return { seed };
  },
  Component: props => {
    const { translate } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${translate.answerSentences.addXAndSubtractY((100).toLocaleString(), 1)}`,
        value: 99
      };
      const statement2 = {
        statement: `${translate.answerSentences.subtractXAndAddY((100).toLocaleString(), 1)}`,
        value: -99
      };
      const statement3 = {
        statement: `${translate.answerSentences.addXAndSubtractY((1000).toLocaleString(), 1)}`,
        value: 999
      };
      const statement4 = {
        statement: `${translate.answerSentences.subtractXAndAddY((1000).toLocaleString(), 1)}`,
        value: -999
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [props.question, translate.answerSentences]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchEquivalentCalcs()}
        pdfTitle={translate.instructions.matchEquivalentCalcs()}
        items={shuffle(
          [
            {
              component: `${translate.operations.Add()} 99`,
              value: 99
            },
            {
              component: `${translate.operations.Subtract()} 99`,
              value: -99
            },
            {
              component: `${translate.operations.Add()} ${(999).toLocaleString()}`,
              value: 999
            },
            {
              component: `${translate.operations.Subtract()} ${(999).toLocaleString()}`,
              value: -999
            }
          ],
          { random: seededRandom(props.question) }
        )}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aiR',
  description: 'aiR',
  keywords: ['Subtraction', 'Efficient'],
  schema: z.object({
    numberA2: z.union([z.literal(99), z.literal(999)]),
    numberA3: z.number().int().min(1000).max(9999),
    numberB3: z.number().int().min(1000).max(9999),
    numberC3: z.number().int().min(1000).max(9999),
    addOrSubtractC: z.enum(['add', 'subtract'])
  }),
  simpleGenerator: () => {
    const numberA2 = getRandomFromArray([99, 999] as const);
    const numberA3 = randomIntegerInclusive(1000, 9999);
    const numberB3 = randomIntegerInclusive(1000, 9999);
    const numberC3 = randomIntegerInclusive(1000, 9999);
    const addOrSubtractC = getRandomFromArray(['add', 'subtract'] as const);
    return { numberA2, numberA3, numberB3, numberC3, addOrSubtractC };
  },

  Component: props => {
    const {
      question: { numberA2, numberA3, numberB3, numberC3, addOrSubtractC },
      translate
    } = props;

    const numberB2 = numberA2 === 99 ? 999 : 99;

    const numberC2 = 990;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA3,
      sign: 'add',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB3,
      right: numberB2,
      sign: 'subtract',
      answer: 'result'
    });

    const eqC =
      addOrSubtractC === 'add'
        ? getBinOpEquation({ right: numberC2, result: numberC3, sign: 'add', answer: 'result' })
        : getBinOpEquation({ left: numberC3, right: numberC2, sign: 'subtract', answer: 'result' });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MentalStrategies',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
