import { useMemo, useRef } from 'react';
import { type SharedValue, useSharedValue } from 'react-native-reanimated';

/**
 * Get several {@link SharedValue}s in an array.
 *
 * The `quantity` argument must _never_ change throughout the lifecycle of this component, otherwise we break the
 * rules of hooks. If it changes, this hook throws an error.
 */
export function useSharedValues<T extends number | string>(
  quantity: number,
  initialStates: T[]
): SharedValue<T>[] {
  const initialQuantity = useRef<number>(quantity).current;

  if (quantity !== initialQuantity) {
    throw new Error('useSharedValue: length of quantity changed - this is not supported');
  }

  let animatedPoints: SharedValue<T>[] = [];
  for (let i = 0; i < quantity; i++) {
    // This is safe because the we always call this hook `quantity` times, and `quantity` doesn't change
    // eslint-disable-next-line react-hooks/rules-of-hooks
    animatedPoints.push(useSharedValue(initialStates[i]));
  }

  // Use existing array, if nothing's actually changed. This is a small optimization.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  animatedPoints = useMemo(() => animatedPoints, animatedPoints);

  return animatedPoints;
}
