import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { addDurationTo24hTime, convert24hTo12h, displayDigitalTime } from '../../../../utils/time';
import { numberEnum } from 'common/src/utils/zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ax4',
  description: 'ax4',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    time1: z.number().int().min(13).max(23),
    time3: z.number().int().min(1).max(12),
    time4: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const time1 = randomIntegerInclusive(13, 23);
    const [time3, time4] = randomUniqueIntegersInclusive(1, 12, 2);

    return { time1, time3, time4 };
  },
  Component: props => {
    const {
      question: { time1, time3, time4 },
      translate
    } = props;

    const time2 = 0;
    const displayTime1 = displayDigitalTime(time1, time2);
    const displayTime2 = displayDigitalTime(time3, time2);
    const displayTime3 = displayDigitalTime(time1, time1);
    const displayTime4 = displayDigitalTime(time4, time2);

    const answer1 = displayDigitalTime(time1, time2, false, '12');
    const answer2 = displayDigitalTime(time3, time2, false, '12');
    const answer3 = displayDigitalTime(time1, time1, false, '12');
    const answer4 = displayDigitalTime(time4, time2, false, '12');

    const statements = [
      {
        statement: displayTime1,
        correctAnswer: answer1
      },
      {
        statement: displayTime2,
        correctAnswer: answer2
      },
      {
        statement: displayTime3,
        correctAnswer: answer3
      },
      {
        statement: displayTime4,
        correctAnswer: answer4
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchTimes()}
        pdfTitle={translate.instructions.useCardsMatchTimes()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={shuffledStatements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={shuffledStatements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ax5',
  description: 'ax5',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours1: z.number().int().min(13).max(23),
    minutes: z.number().int().min(1).max(59)
  }),
  simpleGenerator: () => {
    const hours1 = randomIntegerInclusive(13, 23);
    const minutes = randomIntegerInclusive(1, 59);

    return { hours1, minutes };
  },
  Component: props => {
    const {
      question: { hours1, minutes },
      translate
    } = props;

    const hours2 = hours1 - 12;
    const hours3 = hours1 - 10;
    const hours4 = hours1 - 6;

    const item1 = displayDigitalTime(hours1, minutes, false, '12');
    const item2 = displayDigitalTime(hours2, minutes, false, '12');
    const item3 = displayDigitalTime(hours3, minutes, false, '12');
    const item4 = displayDigitalTime(hours4, minutes, false, '12');

    const shuffledItems = shuffle([item1, item2, item3, item4], {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTwelveHoursClockTime(
          displayDigitalTime(hours1, minutes, true)
        )}
        pdfTitle={translate.instructions.circleTwelveHoursClockTime(
          displayDigitalTime(hours1, minutes, true)
        )}
        testCorrect={[item1]}
        items={shuffledItems}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ax6',
  description: 'ax6',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours: z.number().int().min(1).max(23),
    minutes: z.number().int().min(1).max(59)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 23, { constraint: x => x !== 12 });
    const minutes = randomIntegerInclusive(1, 59);

    return { hours, minutes };
  },
  Component: ({ question: { hours, minutes }, translate, displayMode }) => {
    const correctAnswer = convert24hTo12h(hours, minutes);

    return (
      <QF3InteractiveContent
        title={translate.instructions.giveTheTimeOn24HClockAs12HClockTime()}
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? [
                correctAnswer[0].toLocaleString(),
                correctAnswer[1].toLocaleString().padStart(2, '0')
              ]
            : ['', '']
        }
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer =>
          answer[0] === correctAnswer[0].toString() &&
          answer[1] === correctAnswer[1].toString().padStart(2, '0')
        }
        Content={({ dimens, userAnswer, setUserAnswer }) => (
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              gap: 30
            }}
          >
            <DigitalClock
              hours={hours.toString().padStart(2, '0')}
              minutes={minutes.toString().padStart(2, '0')}
              dimens={{
                height: dimens.height / 2,
                width: dimens.width
              }}
            />
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height / 2,
                width: dimens.width
              }}
              amOrPm={hours > 12 ? 'pm' : 'am'}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ax7',
  description: 'ax7',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours: z.number().int().min(1).max(17),
    minutes: z.number().int().min(1).max(59),
    mornOrAft: z.enum(['morning', 'afternoon'])
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusive(1, 59, { constraint: x => x % 15 !== 0 });
    const mornOrAft = getRandomFromArray(['morning', 'afternoon'] as const);
    const hours =
      mornOrAft === 'morning' ? randomIntegerInclusive(1, 5) : randomIntegerInclusive(13, 17);

    return { hours, minutes, mornOrAft };
  },
  Component: props => {
    const {
      question: { hours, minutes, mornOrAft },
      translate
    } = props;

    const pastOrTo = minutes > 30 ? 'to' : 'past';
    const answerMinutes = minutes > 30 ? 60 - minutes : minutes;
    // hours is never 11 or 12 so we dont need to worry about answer being 0.
    const answerHours = (minutes > 30 ? hours + 1 : hours) % 12;

    const sentence =
      mornOrAft === 'morning'
        ? pastOrTo === 'past'
          ? translate.answerSentences.ansMinutesPastYInTheMorning(minutes)
          : translate.answerSentences.ansMinutesToYInTheMorning(minutes)
        : pastOrTo === 'past'
        ? translate.answerSentences.ansMinutesPastYInTheAfternoon(minutes)
        : translate.answerSentences.ansMinutesToYInTheAfternoon(minutes);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentenceForTheTimeShownOnThe24HClock()}
        sentence={sentence}
        testCorrect={[answerMinutes.toString(), answerHours.toString()]}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        {...props}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <DigitalClock
                hours={hours.toString().padStart(2, '0')}
                minutes={minutes.toString().padStart(2, '0')}
                dimens={{
                  height: dimens.height,
                  width: dimens.width * 0.8
                }}
              />
            </View>
          );
        }}
        pdfDirection="column"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ax8',
  description: 'ax8',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours1: z.number().int().min(13).max(18),
    minutes1: z.number().int().min(0).max(55),
    increment: numberEnum([15, 30, 60, 120])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const hours1 = randomIntegerInclusive(13, 18);
    const increment = getRandomFromArray([15, 30, 60, 120] as const);
    const minutes1 =
      increment % 60 === 0
        ? randomIntegerInclusiveStep(5, 55, 5)
        : increment === 15
        ? randomIntegerInclusiveStep(15, 45, 15)
        : 30;

    return { hours1, minutes1, increment };
  },
  Component: ({ question: { hours1, minutes1, increment }, translate, displayMode }) => {
    const [hours2, minutes2] = addDurationTo24hTime(hours1, minutes1, increment);
    const [hours3, minutes3] = addDurationTo24hTime(hours2, minutes2, increment);
    const [hours4, minutes4] = addDurationTo24hTime(hours3, minutes3, increment);

    const [answerHours, answerMinutes] = convert24hTo12h(hours4, minutes4);

    return (
      <QF3InteractiveContent
        title={translate.instructions.completeSequenceByWritingTime()}
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? [answerHours.toLocaleString(), answerMinutes.toLocaleString().padStart(2, '0')]
            : ['', '']
        }
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer =>
          answer[0] === answerHours.toString() &&
          answer[1] === answerMinutes.toString().padStart(2, '0')
        }
        Content={({ dimens, userAnswer, setUserAnswer }) => (
          <View style={{ gap: 30 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: 30
              }}
            >
              <DigitalClock
                hours={hours1.toString().padStart(2, '0')}
                minutes={minutes1.toString().padStart(2, '0')}
                dimens={{
                  height: dimens.height,
                  width: dimens.width * 0.3
                }}
              />
              <DigitalClock
                hours={hours2.toString().padStart(2, '0')}
                minutes={minutes2.toString().padStart(2, '0')}
                dimens={{
                  height: dimens.height,
                  width: dimens.width * 0.3
                }}
              />
              <DigitalClock
                hours={hours3.toString().padStart(2, '0')}
                minutes={minutes3.toString().padStart(2, '0')}
                dimens={{
                  height: dimens.height,
                  width: dimens.width * 0.3
                }}
              />
            </View>
            <View style={{ alignItems: 'center' }}>
              <DigitalClock
                hours={'<ans/>'}
                minutes={'<ans/>'}
                dimens={{
                  height: dimens.height,
                  width: dimens.width * 0.5
                }}
                amOrPm={hours4 > 12 ? 'pm' : 'am'}
                userAnswer={userAnswer}
                setUserAnswer={setUserAnswer}
              />
            </View>
          </View>
        )}
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ax9',
  description: 'ax9',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    duration: z.number().int().min(35).max(55).multipleOf(5),
    hours: z.number().int().min(13).max(20),
    minutes: z.number().int().min(5).max(25).multipleOf(5),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const duration = randomIntegerInclusiveStep(35, 55, 5);
    const hours = randomIntegerInclusive(13, 20);
    const minutes = randomIntegerInclusiveStep(5, 25, 5);
    const name = getRandomName();

    return { hours, minutes, duration, name };
  },

  Component: props => {
    const {
      question: { hours, minutes, duration, name },
      displayMode,
      translate
    } = props;

    const [startHour, startMinutes] = addDurationTo24hTime(hours, minutes, -(duration + 60));
    // Convert 24 hour time to 12 hour time
    // Destruct hours and minutes from displayDigitalTime
    const [ansHours, ansMinutes] = displayDigitalTime(startHour, startMinutes, false, '12')
      .split(' ')[0]
      .split(':');

    return (
      <QF3InteractiveContent
        title={translate.instructions.whatTimeDidCharacterLeave(
          name,
          duration,
          displayDigitalTime(hours, minutes)
        )}
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? [ansHours.toLocaleString(), ansMinutes.toLocaleString().padStart(2, '0')]
            : ['', '']
        }
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer =>
          answer[0] === ansHours.toString() && answer[1] === ansMinutes.toString().padStart(2, '0')
        }
        Content={({ dimens, userAnswer, setUserAnswer }) => (
          <View style={{ alignItems: 'center' }}>
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height,
                width: dimens.width
              }}
              amOrPm={startHour > 12 ? 'pm' : 'am'}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertFromThe24HourClock',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
