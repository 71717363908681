import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';
import { SvgName } from '../assets/svg';

// Animals
export const animalNames = ['Sheep', 'Horses', 'Pigs', 'Goats', 'Cows'] as const;

export const animalSchema = z.enum(animalNames);

export type AnimalName = (typeof animalNames)[number];

export const animalAsWord = (object: AnimalName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Sheep':
      return translate.animals.Sheep();
    case 'Horses':
      return translate.animals.Horses();
    case 'Pigs':
      return translate.animals.Pigs();
    case 'Goats':
      return translate.animals.Goats();
    case 'Cows':
      return translate.animals.Cows();
  }
};

export function getRandomAnimal() {
  return getRandomFromArray(animalNames);
}
export function getRandomUniqueAnimals(numberOfAnimals: number) {
  return getRandomSubArrayFromArray([...animalNames] as const, numberOfAnimals);
}

// KS1 Animals
export const ks1AnimalNames = [
  'sheep',
  'horse',
  'dog',
  'cat',
  'frog',
  'bird',
  'bee',
  'owl',
  'lion'
] as const;

export const ks1AnimalNamesSchema = z.enum(ks1AnimalNames);

export type Ks1AnimalName = (typeof ks1AnimalNames)[number];

export const ks1AnimalAsWord = (
  object: Ks1AnimalName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'sheep':
      return translate.animals.Sheep();
    case 'horse':
      return translate.animals.Horse(amount);
    case 'dog':
      return translate.animals.dogs(amount);
    case 'cat':
      return translate.animals.cats(amount);
    case 'frog':
      return translate.animals.frogs(amount);
    case 'bird':
      return translate.animals.birds(amount);
    case 'bee':
      return translate.animals.bees(amount);
    case 'owl':
      return translate.animals.owls(amount);
    case 'lion':
      return translate.animals.lions(amount);
  }
};

export function getRandomKs1Animal() {
  return getRandomFromArray(ks1AnimalNames);
}
export function getRandomUniqueKs1Animals(numberOfAnimals: number) {
  return getRandomSubArrayFromArray([...ks1AnimalNames] as const, numberOfAnimals);
}

export const getAnimalSvgs = (object: Ks1AnimalName): SvgName => {
  switch (object) {
    case 'sheep':
      return 'Sheep';
    case 'horse':
      return 'Horse';
    case 'dog':
      return 'Dogs/dog1';
    case 'cat':
      return 'Cat';
    case 'frog':
      return 'Frog';
    case 'bird':
      return 'bird_blue';
    case 'bee':
      return 'Bee';
    case 'owl':
      return 'Owl_bird';
    case 'lion':
      return 'Lion';
  }
};
