import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { BarModelVerticalBraceWithState } from '../../../../components/question/representations/BarModelVerticalCurlyBrace';
import isEqual from 'react-fast-compare';
import { getGenderFromKs1Name, getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, sumNumberArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isInRange } from '../../../../utils/matchers';
import { View } from 'react-native';
import TextStructure from '../../../../components/molecules/TextStructure';
import { numToCurrency, penceToPoundsAndPence } from '../../../../utils/money';
import { AssetSvg } from '../../../../assets/svg';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biA',
  description: 'biA',
  keywords: ['Multi-step', 'Add', 'Subtract', 'Money'],
  schema: z
    .object({
      isPounds: z.boolean(),
      characterName: ks1NameSchema,
      otherCharacter: z.enum(['mum', 'dad', 'friend']),
      item: z.enum([
        'cinema',
        'bag',
        'comic',
        'game',
        'tShirt',
        'jumper',
        'apple',
        'iceCream',
        'sweets',
        'crisps',
        'drink',
        'cookie'
      ]),
      startingAmount: z.number().int().min(3).max(95),
      extraMoney: z.number().int().min(3).max(95),
      amountSpent: z.number().int().min(2).max(90),
      items: z
        .object({
          value: z.enum(['A', 'B', 'C', 'D']),
          moneyValue: z.number()
        })
        .array()
        .length(4)
    })
    .refine(val => val.startingAmount + val.extraMoney > val.amountSpent),
  questionHeight: 1000,
  simpleGenerator: () => {
    const characterName = getRandomKs1Name();
    const otherCharacter = getRandomFromArray(['mum', 'dad', 'friend'] as const);

    const isPounds = getRandomBoolean();

    const { startingAmount, amountSpent, extraMoney, incorrectMoneyValues, item } = rejectionSample(
      () => {
        const itemPounds = getRandomFromArray([
          'cinema',
          'bag',
          'comic',
          'game',
          'tShirt',
          'jumper'
        ] as const);

        const itemPence = getRandomFromArray([
          'apple',
          'iceCream',
          'sweets',
          'crisps',
          'drink',
          'cookie'
        ] as const);

        const item = isPounds ? itemPounds : itemPence;

        const startingAmount = isPounds
          ? randomIntegerInclusive(4, 20)
          : randomIntegerInclusiveStep(5, 90, 5);

        const extraMoney = isPounds
          ? randomIntegerInclusive(3, 15)
          : randomIntegerInclusiveStep(5, 95, 5, { constraint: x => x + startingAmount < 100 });

        const amountSpentPounds = (() => {
          switch (item) {
            case 'cinema':
              return randomIntegerInclusive(5, 9);
            case 'bag':
              return randomIntegerInclusive(7, 14);
            case 'comic':
              return randomIntegerInclusive(2, 6);
            case 'game':
              return randomIntegerInclusive(3, 16);
            case 'tShirt':
              return randomIntegerInclusive(8, 16);
            case 'jumper':
              return randomIntegerInclusive(8, 22);
          }
        })();

        const amountSpentPence = (() => {
          switch (item) {
            case 'apple':
              return randomIntegerInclusiveStep(20, 60, 5);
            case 'sweets':
              return randomIntegerInclusiveStep(5, 45, 5);
            case 'crisps':
              return randomIntegerInclusiveStep(30, 80, 5);
            case 'iceCream':
              return randomIntegerInclusiveStep(70, 80, 5);
            case 'drink':
              return randomIntegerInclusiveStep(50, 90, 5);
            case 'cookie':
              return randomIntegerInclusiveStep(40, 90, 5);
          }
        })();

        const amountSpent = isPounds ? (amountSpentPounds as number) : (amountSpentPence as number);

        const incorrectMoneyValues = [
          startingAmount + extraMoney,
          startingAmount + extraMoney + amountSpent,
          startingAmount - amountSpent,
          startingAmount - extraMoney,
          isPounds
            ? startingAmount + extraMoney - amountSpent + 1
            : startingAmount + extraMoney - amountSpent + 5,
          isPounds
            ? startingAmount + extraMoney - amountSpent - 1
            : startingAmount + extraMoney - amountSpent - 5
        ].filter(num => num !== startingAmount + extraMoney - amountSpent);

        return {
          startingAmount,
          amountSpent,
          extraMoney,
          incorrectMoneyValues,
          isPounds,
          item
        };
      },
      ({ startingAmount, amountSpent, extraMoney, incorrectMoneyValues }) =>
        startingAmount + extraMoney > amountSpent &&
        incorrectMoneyValues.every(num => num > 0 && num < 100) &&
        arrayHasNoDuplicates(incorrectMoneyValues)
    );

    const incorrectValues = ['B', 'C', 'D'] as const;

    const correctItem = {
      value: 'A' as const,
      moneyValue: startingAmount + extraMoney - amountSpent
    };

    const incorrectItems = getRandomSubArrayFromArray(incorrectMoneyValues, 3).map(
      (moneyValue, index) => {
        return { value: incorrectValues[index], moneyValue };
      }
    );

    const items = shuffle([...incorrectItems, correctItem]);

    return {
      characterName,
      otherCharacter,
      item,
      startingAmount,
      amountSpent,
      extraMoney,
      items,
      isPounds
    };
  },

  Component: props => {
    const {
      question: {
        characterName,
        otherCharacter,
        item,
        startingAmount,
        amountSpent,
        extraMoney,
        items,
        isPounds
      },
      translate,
      displayMode
    } = props;

    const herOrHis =
      getGenderFromKs1Name(characterName) === 'female'
        ? translate.ks1MiscStrings.Her()
        : translate.ks1MiscStrings.His();

    const herOrHim =
      getGenderFromKs1Name(characterName) === 'female'
        ? translate.ks1MiscStrings.Her()
        : translate.ks1MiscStrings.Him();

    const otherCharacterString =
      otherCharacter === 'dad'
        ? translate.ks1MiscStrings.dad()
        : otherCharacter === 'mum'
        ? translate.ks1MiscStrings.mum()
        : translate.ks1MiscStrings.friend();

    const itemToItemString = (() => {
      switch (item) {
        case 'bag':
          return translate.objects.Bag();
        case 'cinema':
          return translate.objects.CinemaTicket();
        case 'comic':
          return translate.objects.Comic();
        case 'game':
          return translate.objects.Game();
        case 'tShirt':
          return translate.objects['T-shirt']();
        case 'jumper':
          return translate.objects.Jumper();
        case 'apple':
          return translate.fruits.anApple();
        case 'iceCream':
          return translate.objects.anIceCream();
        case 'sweets':
          return translate.objects.someSweets();
        case 'crisps':
          return translate.objects.aBagOfCrisps();
        case 'drink':
          return translate.objects.aDrink();
        case 'cookie':
          return translate.objects.aCookie();
      }
    })();

    const title = isPounds
      ? item === 'tShirt'
        ? `${translate.ks1Instructions.charHasPoundsXHeSheOtherCharacterGivesPronounAnotherPoundsYCharSpendsPoundsZOnATShirt(
            characterName,
            startingAmount,
            herOrHis,
            otherCharacterString,
            herOrHim,
            extraMoney,
            amountSpent
          )}<br/>${
            displayMode === 'digital'
              ? translate.ks1Instructions.howMuchDoesCharHaveLeftSelectYourAnswer(characterName)
              : translate.ks1PDFInstructions.howMuchDoesCharHaveLeftTickYourAnswer(characterName)
          }`
        : `${translate.ks1Instructions.charHasPoundsXHeSheOtherCharacterGivesPronounAnotherPoundsYCharSpendsPoundsZOnItem(
            characterName,
            startingAmount,
            herOrHis,
            otherCharacterString,
            herOrHim,
            extraMoney,
            amountSpent,
            itemToItemString
          )}<br/>${
            displayMode === 'digital'
              ? translate.ks1Instructions.howMuchDoesCharHaveLeftSelectYourAnswer(characterName)
              : translate.ks1PDFInstructions.howMuchDoesCharHaveLeftTickYourAnswer(characterName)
          }`
      : item === 'tShirt'
      ? `${translate.ks1Instructions.charHasXPHeSheOtherCharacterGivesPronounAnotherYPCharSpendsZPOnATShirt(
          characterName,
          startingAmount,
          herOrHis,
          otherCharacterString,
          herOrHim,
          extraMoney,
          amountSpent
        )}<br/>${
          displayMode === 'digital'
            ? translate.ks1Instructions.howMuchDoesCharHaveLeftSelectYourAnswer(characterName)
            : translate.ks1PDFInstructions.howMuchDoesCharHaveLeftTickYourAnswer(characterName)
        }`
      : `${translate.ks1Instructions.charHasXPHeSheOtherCharacterGivesPronounAnotherYPCharSpendsZPOnItem(
          characterName,
          startingAmount,
          herOrHis,
          otherCharacterString,
          herOrHim,
          extraMoney,
          amountSpent,
          itemToItemString
        )}<br/>${
          displayMode === 'digital'
            ? translate.ks1Instructions.howMuchDoesCharHaveLeftSelectYourAnswer(characterName)
            : translate.ks1PDFInstructions.howMuchDoesCharHaveLeftTickYourAnswer(characterName)
        }`;

    return (
      <QF11SelectImagesUpTo4
        title={title}
        questionHeight={1000}
        testCorrect={['A']}
        pdfShowBorder
        renderItems={items.map(({ value, moneyValue }) => {
          return {
            value,
            component: (
              <Text variant="WRN700">{isPounds ? `£${moneyValue}` : `${moneyValue}p`}</Text>
            )
          };
        })}
        numItems={4}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'biB',
  description: 'biB',
  keywords: ['Multi-step', 'Add', 'Subtract', 'Money'],
  schema: z.object({
    characterName: ks1NameSchema,
    startingPounds: z.number().int().min(5).max(20),
    itemObjects: z.array(
      z.object({
        item: z.enum([
          'pen',
          'pencil',
          'lollipop',
          'apple',
          'orange',
          'fizzyDrink',
          'barOfChocolate',
          'keyChain',
          'iceCream',
          'cardPack',
          'marbleBag',
          'tubeCrisps',
          'magazine',
          'stickers',
          'hulahoop',
          'packOfPen',
          'book',
          'football',
          'candle',
          'stickerBook',
          'jumper',
          'rucksack',
          'scarf',
          'hat'
        ]),
        priceInPence: z.number().int().min(10).max(1000).step(5)
      })
    )
  }),
  simpleGenerator: () => {
    const characterName = getRandomKs1Name();

    const startingPounds = randomIntegerInclusive(5, 20);

    const { itemObjects } = rejectionSample(
      () => {
        const valueA = randomIntegerInclusiveStep(20, 1000, 5);

        const valueB = randomIntegerInclusiveStep(20, 1000, 5);

        const getItem = (value: number) => {
          if (isInRange(20, 60)(value)) {
            return getRandomFromArray(['pen', 'pencil', 'lollipop', 'apple', 'orange'] as const);
          } else if (isInRange(61, 120)(value)) {
            return getRandomFromArray(['fizzyDrink', 'barOfChocolate', 'keyChain'] as const);
          } else if (isInRange(121, 250)(value)) {
            return getRandomFromArray([
              'iceCream',
              'cardPack',
              'marbleBag',
              'tubeCrisps',
              'magazine',
              'stickers',
              'hulahoop',
              'packOfPen'
            ] as const);
          } else if (isInRange(251, 500)(value)) {
            return getRandomFromArray(['book', 'football', 'candle', 'stickerBook'] as const);
          } else return getRandomFromArray(['jumper', 'rucksack', 'scarf', 'hat'] as const);
        };

        const itemA = getItem(valueA);
        const itemB = getItem(valueB);

        const itemObjects = shuffle([
          { priceInPence: valueA, item: itemA },
          { priceInPence: valueB, item: itemB }
        ]);

        return { valueA, valueB, itemObjects };
      },
      ({ valueA, valueB, itemObjects }) =>
        valueA + valueB < startingPounds * 100 &&
        (valueA % 100) + (valueB % 100) > 100 &&
        arrayHasNoDuplicates(itemObjects.map(({ item }) => item))
    );

    return { characterName, startingPounds, itemObjects };
  },
  Component: ({ question, translate, displayMode }) => {
    const { characterName, startingPounds, itemObjects } = question;

    const svgNameAndStringMap = {
      pencil: { svgName: 'Pencils/Pencil_green' as const, string: translate.objects.aPencil() },
      pen: { svgName: 'Base_Ten/1RedPen' as const, string: translate.objects.aPen() },
      lollipop: { svgName: 'Base_Ten/Lollipops1' as const, string: translate.objects.aLollipop() },
      apple: { svgName: 'Array_objects/AppleRed' as const, string: translate.fruits.anApple() },
      orange: { svgName: 'Array_objects/Orange' as const, string: translate.fruits.anOrange() },
      fizzyDrink: {
        svgName: 'Can_of_fizzy_drink' as const,
        string: translate.objects.aFizzyDrink()
      },
      barOfChocolate: {
        svgName: 'Bar_of_chocolate' as const,
        string: translate.objects.aBarOfChocolate()
      },
      keyChain: { svgName: 'Keychain1' as const, string: translate.objects.aKeyChain() },
      iceCream: { svgName: 'icecream' as const, string: translate.objects.anIceCream() },
      cardPack: { svgName: 'Card_pack' as const, string: translate.objects.aPackOfCards() },
      marbleBag: { svgName: 'Marble_bag' as const, string: translate.objects.aBagOfMarbles() },
      tubeCrisps: { svgName: 'Tube_of_crisps' as const, string: translate.objects.aTubeOfCrisps() },
      magazine: { svgName: 'Magazine' as const, string: translate.objects.aMagazine() },
      stickers: { svgName: 'Sticker_sheet' as const, string: translate.objects.aSheetOfStickers() },
      hulahoop: { svgName: 'Hulahoop' as const, string: translate.objects.aHulahoop() },
      packOfPen: {
        svgName: 'Base_Ten/10BluePens' as const,
        string: translate.objects.aPackOfPens()
      },
      book: { svgName: 'BookRed' as const, string: translate.objects.aBook() },
      football: { svgName: 'Sports_ball_football' as const, string: translate.objects.aFootball() },
      candle: { svgName: 'Candle' as const, string: translate.objects.aCandle() },
      stickerBook: {
        svgName: 'Football_sticker_book' as const,
        string: translate.objects.aStickerBook()
      },
      jumper: { svgName: 'Clothes/Jumper_blue' as const, string: translate.objects.aJumper() },
      rucksack: { svgName: 'Rucksack' as const, string: translate.objects.aRucksack() },
      scarf: { svgName: 'Clothes/Scarf_purple' as const, string: translate.objects.aScarf() },
      hat: { svgName: 'Clothes/Hat_cap' as const, string: translate.objects.aHat() }
    };

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const sentence = translate.ks1AnswerSentences.poundAnsAndAnsP();

    const answerPoundsAndPence = sumNumberArray(
      itemObjects.map(({ priceInPence }) => priceInPence)
    );

    const answer = penceToPoundsAndPence(startingPounds * 100 - answerPoundsAndPence);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.charHasPoundsXPronounBuysXAndY(
          characterName,
          startingPounds,
          heOrShe,
          svgNameAndStringMap[itemObjects[0].item].string,
          svgNameAndStringMap[itemObjects[1].item].string
        )}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.pounds.toString(), answer.pence.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignSelf: 'center'
              }}
            >
              {itemObjects.map(({ priceInPence, item }, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      justifyContent: 'flex-end',
                      height: dimens.height * 0.8
                    }}
                  >
                    <AssetSvg
                      width={dimens.width * 0.4}
                      height={
                        ['hat', 'barOfChocolate'].includes(item)
                          ? dimens.height * 0.4
                          : dimens.height * 0.6
                      }
                      name={svgNameAndStringMap[item].svgName}
                    />
                    <View style={{ width: dimens.width * 0.14, alignSelf: 'center' }}>
                      <Text
                        style={{
                          fontSize: displayMode === 'digital' ? 32 : 50,
                          padding: 8,
                          marginTop: 6,
                          backgroundColor: colors.prussianBlue,
                          color: colors.white,
                          textAlign: 'center'
                        }}
                      >
                        {numToCurrency({ amount: priceInPence / 100 })}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <TextStructure
              sentence={translate.ks1AnswerSentences.howMuchMoneyDoesCharHaveLeft(characterName)}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'biC',
  description: 'biC',
  keywords: ['Multi-step', 'Add', 'Subtract', 'Money'],
  schema: z.object({
    objectA: z.enum(['Coat', 'Jumper', 'Bag', 'Game']),
    objectB: z.enum(['T-shirt', 'Jumper', 'Book', 'Hat']),
    priceA: z.number().int().min(5).max(20),
    priceB: z.number().int().min(1).max(10),
    lessOrMore: z.enum(['less', 'more'])
  }),
  simpleGenerator: () => {
    const { objectA, objectB } = rejectionSample(
      () => {
        const objectA = getRandomFromArray(['Coat', 'Jumper', 'Bag', 'Game'] as const);
        const objectB = getRandomFromArray(['T-shirt', 'Jumper', 'Book', 'Hat'] as const);
        return { objectA, objectB };
      },
      ({ objectA, objectB }) => objectA !== objectB
    );

    const priceA = randomIntegerInclusive(5, 20);
    const priceB = randomIntegerInclusive(1, 10, { constraint: x => x < priceA });

    const lessOrMore = getRandomFromArray(['less', 'more'] as const);

    return { objectA, objectB, priceA, priceB, lessOrMore };
  },
  Component: ({
    question: { objectA, objectB, priceA, priceB, lessOrMore },
    translate,
    displayMode
  }) => {
    const difference = priceA - priceB;

    const instruction =
      lessOrMore === 'less'
        ? translate.ks1Instructions.xObjectCostsLessThanYObjectWhatIsTotalCostCombinedCompleteBarModel(
            {
              objectA,
              objectB,
              difference
            }
          )
        : translate.ks1Instructions.xObjectCostsMoreThanYObjectWhatIsTotalCostCombinedCompleteBarModel(
            {
              objectA,
              objectB,
              difference
            }
          );

    const strings =
      lessOrMore === 'less'
        ? [[`£${priceA.toLocaleString()}`], ['', `£${difference.toLocaleString()}`]]
        : [[''], [`£${priceB.toLocaleString()}`, `£${difference.toLocaleString()}`]];

    return (
      <QF3Content
        title={instruction}
        inputType="numpad"
        Content={({ dimens }) => (
          <BarModelVerticalBraceWithState
            id="barmodel"
            numbers={[[priceA], [priceB, difference]]}
            strings={strings}
            total={Math.max(priceA, priceB)}
            arrowIndices={[[], [1]]}
            dimens={dimens}
            testCorrect={userAnswer => isEqual(userAnswer, [(priceA + priceB).toString()])}
            preBarText={[objectA, objectB]}
            braceText="£ <ans/>"
            inputMaxCharacters={2}
            defaultState={displayMode !== 'digital' ? [(priceA + priceB).toString()] : undefined}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwoStepProblems',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
