import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { filledArray, range } from '../../../../utils/collections';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { all, create, number } from 'mathjs';
import {
  ScientificNotation,
  compareFloats,
  digitAtPowerIsNumber,
  roundToSignificantFigures,
  roundToTheNearest
} from '../../../../utils/math';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const INTEGER = 'integer';
const TENTHS = 'tenths';
const HUNDREDTHS = 'hundredths';

const Question1 = newQuestionContent({
  uid: 'aUU',
  description: 'aUU',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Hundredths', 'Thousandths', 'Integer', 'Number line'],
  schema: z.object({
    step: z.enum([INTEGER, TENTHS, HUNDREDTHS]),
    number1: z.number().min(0.01).max(9.98),
    number3: z.number().min(0.001).max(0.9)
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([INTEGER, TENTHS, HUNDREDTHS] as const);
    let number1, number3;
    switch (step) {
      case INTEGER: {
        number1 = randomIntegerInclusive(1, 8);
        number3 = randomIntegerInclusive(1, 9) / 10;
        break;
      }
      case TENTHS: {
        number1 = randomIntegerInclusive(1, 98) / 10;
        number3 = randomIntegerInclusive(1, 9) / 100;
        break;
      }
      default: {
        number1 = randomIntegerInclusive(1, 998) / 100;
        number3 = randomIntegerInclusive(1, 9) / 1000;
        break;
      }
    }

    return { step, number1, number3 };
  },
  Component: props => {
    const {
      question: { step, number1, number3 },
      translate
    } = props;
    const tickInterval = step === INTEGER ? 0.1 : step === TENTHS ? 0.01 : 0.001;

    const number2 =
      step === INTEGER
        ? number1 + 1
        : step === TENTHS
        ? number(math.evaluate(`${number1} + 0.1`))
        : number(math.evaluate(`${number1} + 0.01`));

    const label = number(math.evaluate(`${number1} + ${number3}`));

    // Create array to pass to Number Line
    const tickValues = range(number1, number2 - tickInterval, tickInterval).map(number => {
      return number === label ? number.toLocaleString() : '';
    });

    // Set where the answers should go
    tickValues[0] = '<ans/>';
    tickValues[10] = '<ans/>';

    const displayStep =
      step === INTEGER
        ? translate.keywords.Integers()
        : step === TENTHS
        ? translate.keywords.Tenths()
        : translate.keywords.Hundredths();

    const fixed = step === INTEGER ? 0 : step === TENTHS ? 1 : 2;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whichXIsDecimalBetween(displayStep)}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], number1.toFixed()) &&
          compareFloats(userAnswer[1], number2.toFixed())
        }
        firstNumber={number1}
        lastNumber={number2}
        inputMaxCharacters={4}
        tickValues={tickValues}
        extraSymbol="decimalPoint"
        answerPositions="bottom"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number1.toFixed(fixed).toLocaleString(),
            number2.toFixed(fixed).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aUV',
  description: 'aUV',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Number line',
    'Round',
    'Nearest integer',
    'Closer to'
  ],
  questionHeight: 800,
  schema: z.object({
    number1: z.number().min(0.01).max(9.98),
    number3: z.number().min(0.001).max(0.9),
    step: z.enum([INTEGER, TENTHS, HUNDREDTHS])
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([INTEGER, TENTHS, HUNDREDTHS] as const);
    const number1 =
      step === INTEGER
        ? randomIntegerInclusive(1, 8)
        : step === TENTHS
        ? randomIntegerInclusive(1, 98) / 10
        : randomIntegerInclusive(1, 998) / 100;

    const divisor = step === INTEGER ? 10 : step === TENTHS ? 100 : 1000;
    const number3 = randomIntegerInclusive(1, 9, { constraint: x => x !== 5 }) / divisor;

    return { number1, number3, step };
  },
  Component: props => {
    const {
      question: { number1, number3, step },
      translate
    } = props;

    const number2 =
      step === INTEGER ? number1 + 1 : step === TENTHS ? number1 + 0.1 : number1 + 0.01;

    // Array of tick values for number line
    const tickValues = filledArray('', 11);
    tickValues[0] = number1.toLocaleString();
    tickValues[10] = number2.toLocaleString();

    const label = number(math.evaluate(`${number1} + ${number3}`));

    const displayStep =
      step === INTEGER
        ? translate.keywords.Integer()
        : step === TENTHS
        ? translate.fractions.tenths(1)
        : translate.fractions.hundredths(1);

    const sigFig = step === INTEGER ? 1 : step === TENTHS ? 2 : 3;
    const answer1 = roundToSignificantFigures(label, sigFig);
    const answer2 = [number1, number2].filter(i => i !== answer1)[0];

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.xIsCloserToAnsThanAns(label.toLocaleString()),
          translate.answerSentences.numRoundedToTheNearestStepIs(
            label.toLocaleString(),
            displayStep
          )
        ]}
        pdfDirection="column"
        questionHeight={800}
        title={translate.instructions.useXandYToCompleteTheSentence(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) &&
          compareFloats(userAnswer[0][1], answer2) &&
          compareFloats(userAnswer[1][0], answer1)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString(), answer2.toLocaleString()],
            [answer1.toLocaleString()]
          ]
        }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <NumberLine focusNumber={label} showFocusNumber tickValues={tickValues} dimens={dimens} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUW',
  description: 'aUW',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Round',
    'Nearest integer',
    'Closer to'
  ],
  schema: z.object({
    step: z.enum([INTEGER, TENTHS, HUNDREDTHS]),
    number1: z.number().min(1.011).max(9.989),
    number2: z.number().min(1.001).max(9.999),
    number3: z.number().min(1.015).max(9.995)
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([INTEGER, TENTHS, HUNDREDTHS] as const);
    let number1, number2, number3;
    switch (step) {
      case INTEGER: {
        number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 5 !== 0 }) / 10;
        number2 = randomIntegerInclusive(11, 99, { constraint: x => x % 5 !== 0 }) / 10;
        number3 = randomIntegerInclusiveStep(15, 95, 10) / 10;
        break;
      }
      case TENTHS: {
        number1 =
          randomIntegerInclusive(111, 998, {
            constraint: x => x % 5 !== 0 && !digitAtPowerIsNumber(x, 'tens', [9, 0])
          }) / 100;
        number2 = randomIntegerInclusive(101, 999, { constraint: x => x % 5 !== 0 }) / 100;
        number3 = randomIntegerInclusive(115, 995) / 100;
        break;
      }
      default: {
        number1 =
          randomIntegerInclusive(1011, 9989, {
            constraint: x => x % 5 !== 0 && !digitAtPowerIsNumber(x, 'tens', [9, 0])
          }) / 1000;
        number2 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 5 !== 0 }) / 1000;
        number3 = randomIntegerInclusiveStep(1015, 9995, 10) / 1000;
        break;
      }
    }

    return { step, number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { step, number1, number2, number3 },
      translate
    } = props;

    const displayStep =
      step === INTEGER
        ? translate.keywords.Integer()
        : step === TENTHS
        ? translate.fractions.tenths(1)
        : translate.fractions.hundredths(1);

    // In the powerpoint it specifies that we want the pupil to have correct precision too
    const sigFig = step === INTEGER ? 1 : step === TENTHS ? 2 : 3;
    const answers = [
      [roundToSignificantFigures(number1, sigFig).toFixed(sigFig - 1)],
      [roundToSignificantFigures(number2, sigFig).toFixed(sigFig - 1)],
      [roundToSignificantFigures(number3, sigFig).toFixed(sigFig - 1)]
    ];
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestStep(displayStep)}
        testCorrect={answers}
        extraSymbol="decimalPoint"
        sentences={[
          `${number1.toFixed(sigFig).toLocaleString()}   <ans/>`,
          `${number2.toFixed(sigFig).toLocaleString()}   <ans/>`,
          `${number3.toFixed(sigFig).toLocaleString()}   <ans/>`
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUX',
  description: 'aUX',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Round',
    'Nearest',
    'Closer to'
  ],
  schema: z.object({
    step: z.enum(['integer', 'tenths', 'hundredths']),
    number1: z.number().min(0.001).max(10.999)
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray(['integer', 'tenths', 'hundredths'] as const);
    const number1 =
      step === 'integer'
        ? randomIntegerInclusive(1, 109, { constraint: x => x % 10 !== 0 }) / 10
        : step === 'tenths'
        ? randomIntegerInclusive(1, 1099, { constraint: x => x % 10 !== 0 }) / 100
        : randomIntegerInclusive(1, 10999, { constraint: x => x % 10 !== 0 }) / 1000;

    return { step, number1 };
  },
  Component: props => {
    const {
      question: { step, number1 },
      translate
    } = props;

    const displayStep =
      step === 'integer'
        ? translate.keywords.Integer()
        : step === 'tenths'
        ? translate.fractions.tenths(1)
        : translate.fractions.hundredths(1);

    const nearest = step === 'integer' ? 1 : step === 'tenths' ? 0.1 : 0.01;
    const fixed = step === 'integer' ? 0 : step === 'tenths' ? 1 : 2;
    const items = [
      roundToTheNearest(number1, nearest, 'down').toFixed(fixed),
      roundToTheNearest(number1, nearest, 'up').toFixed(fixed)
    ];

    const correctAnswer =
      Number(ScientificNotation.fromNumber(number1).digits.slice(-1)) > 4
        ? roundToTheNearest(number1, nearest, 'up').toFixed(fixed)
        : roundToTheNearest(number1, nearest, 'down').toFixed(fixed);

    return (
      <QF10SelectNumbers
        title={translate.instructions.roundXToNearestStep(number1.toLocaleString(), displayStep)}
        pdfTitle={`${translate.instructions.roundXToNearestStep(
          number1.toLocaleString(),
          displayStep
        )}<br/>${translate.instructions.circleCorrectAnswer()}`}
        items={items}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUY',
  description: 'aUY',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Round',
    'Nearest',
    'Closer to'
  ],
  schema: z.object({
    number1: z.number().int().min(0).max(9),
    number2: z.number().int().min(0).max(9),
    number3: z.number().int().min(0).max(9),
    number4: z.number().int().min(0).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(0, 9);
    const number2 = randomIntegerInclusive(0, 9);
    const number3 = randomIntegerInclusive(0, 9);
    const number4 = randomIntegerInclusive(0, 9);

    return { number1, number2, number3, number4 };
  },

  Component: ({ question: { number1, number2, number3, number4 }, translate }) => {
    const number5 = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100 + ${number4} / 1000`)
    );
    const number6 = number1 + 1;
    const number7 = number(math.evaluate(`${number1} + ${number2} / 10`));
    const number8 = number(math.evaluate(`${number1} + ${number2} / 10 + 0.1`));
    const number9 = number(math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100`));
    const number10 = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100 + 0.01`)
    );

    const sentences = [
      {
        sentence: `${translate.answerSentences.nearestStep(translate.keywords.Integer())} <ans />`,
        correctAnswer: number2 > 4 ? number6.toLocaleString() : number1.toLocaleString()
      },
      {
        sentence: `${translate.answerSentences.nearestStep(translate.fractions.tenths(1))} <ans />`,
        correctAnswer:
          number3 > 4 ? number8.toFixed(1).toLocaleString() : number7.toFixed(1).toLocaleString()
      },
      {
        sentence: `${translate.answerSentences.nearestStep(
          translate.fractions.hundredths(1)
        )} <ans />`,
        correctAnswer:
          number4 > 4 ? number10.toFixed(2).toLocaleString() : number9.toFixed(2).toLocaleString()
      }
    ];

    const items = shuffle(
      [
        number1.toLocaleString(),
        number6.toLocaleString(),
        number7.toFixed(1).toLocaleString(),
        number8.toFixed(1).toLocaleString(),
        number9.toFixed(2).toLocaleString(),
        number10.toFixed(2).toLocaleString()
      ],
      { random: seededRandom({ number1, number2, number3, number4 }) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToRoundX(number5.toFixed(3).toLocaleString())}
        pdfTitle={translate.instructions.useCardsToRoundX(number5.toFixed(3).toLocaleString())}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ correctAnswer }) => [correctAnswer])}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        actionPanelVariant="endWide"
        items={items}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aUZ',
  description: 'aUZ',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Round',
    'Nearest',
    'Closer to'
  ],
  schema: z.object({
    number1: z.number().int().min(0).max(9),
    number2: z.number().int().min(0).max(9),
    number3: z.number().int().min(0).max(9),
    number4: z.number().int().min(1).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(0, 9);
    const number2 = randomIntegerInclusive(0, 9);
    const number3 = randomIntegerInclusive(0, 9);
    const number4 = randomIntegerInclusive(1, 9);

    return { number1, number2, number3, number4 };
  },

  Component: ({ question: { number1, number2, number3, number4 }, translate }) => {
    const number5 = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100 + ${number4} / 1000`)
    );

    const tenthRoundUp = number(math.evaluate(`${number1} + ${number2} / 10 + 0.1`));
    const hundredthRoundUp = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100 + 0.01`)
    );
    const tenthRoundDown = number(math.evaluate(`${number1} + ${number2} / 10`));
    const hundredthRoundDown = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100`)
    );

    const sentences = [
      {
        sentence: `${translate.answerSentences.xRoundedToTheNearestXIs(
          number5.toLocaleString(),
          translate.keywords.Integer()
        )}`,
        correctAnswer: number2 > 4 ? number1 + 1 : number1
      },
      {
        sentence: `${translate.answerSentences.xRoundedToTheNearestXIs(
          number5.toLocaleString(),
          translate.fractions.tenths(1)
        )}`,
        correctAnswer: number3 > 4 ? tenthRoundUp : tenthRoundDown
      },
      {
        sentence: `${translate.answerSentences.xRoundedToTheNearestXIs(
          number5.toLocaleString(),
          translate.fractions.hundredths(1)
        )}`,
        correctAnswer: number4 > 4 ? hundredthRoundUp : hundredthRoundDown
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        extraSymbol="decimalPoint"
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={userAnswer =>
          userAnswer.every((sentence, idx) =>
            sentence.every(ans => compareFloats(ans, sentences[idx].correctAnswer))
          )
        }
        inputMaxCharacters={4}
        textStyle={{ fontSize: 32 }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [sentences[0].correctAnswer.toLocaleString()],
            [sentences[1].correctAnswer.toLocaleString()],
            [sentences[2].correctAnswer.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
