import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import QF65SetTheScales from '../../../../components/question/questionFormats/QF65SetTheScales';
import Scales, {
  defaultWeightImages
} from '../../../../components/question/representations/Scales';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';
import { SvgName } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIU',
  description: 'aIU',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    weight: z.number().int().min(50).max(1000).step(50),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(100, 1000, 100);
    const image = getRandomFromArray(defaultWeightImages);
    return { weight, image };
  },
  Component: ({ question: { weight, image }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[weight.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName="Scales/1_kg_scale_50g"
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIV',
  description: 'aIV',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    kilograms: numberEnum([1, 2]),
    grams: z.number().int().min(100).max(900).step(100),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const kilograms = getRandomFromArray([1, 2] as const);
    const grams = randomIntegerInclusiveStep(100, 900, 100);
    const image = getRandomFromArray(defaultWeightImages);
    return { kilograms, grams, image };
  },
  Component: ({ question: { kilograms, grams, image }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKgAndG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[kilograms.toString(), grams.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={number(math.evaluate(`(${kilograms} * 1000) + ${grams}`))}
              scaleWidth={dimens.width * 0.3}
              svgName="Scales/3_kg_scale_100_g"
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIW',
  description: 'aIW',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    weight: z.number().int().min(100).max(5900).step(100),
    maxKg: z.number().int().min(4).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const maxKg = randomIntegerInclusive(4, 5);
    const weight = randomIntegerInclusiveStep(100, maxKg * 1000 - 100, 100);

    return { weight, maxKg };
  },
  Component: ({ question: { weight, maxKg }, translate }) => {
    const svgImage = maxKg === 4 ? 'Scales/4_kg_scale_100_g' : 'Scales/5_kg_scale_100_g';

    return (
      <QF65SetTheScales
        title={translate.instructions.dragTheArrowToShowMassOnScale(weight)}
        pdfTitle={translate.instructions.drawArrowOnScalesToShowXG(weight)}
        svgName={svgImage}
        answerGrams={weight}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aIX',
  description: 'aIX',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    scaleMaxKilograms: z.number().int().min(3).max(6),
    kilograms: z.number().int().min(1).max(5),
    isScaleFourIntervals: z.boolean(),
    grams: z.number().int().min(200).max(800),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const isScaleFourIntervals = getRandomBoolean();
    const scaleMaxKilograms = randomIntegerInclusive(3, 6, {
      // Don't have SVG for 4 kg scale 200 gram intervals
      constraint: x => (!isScaleFourIntervals ? x !== 4 : true)
    });
    const kilograms = randomIntegerInclusive(1, scaleMaxKilograms - 1);
    const grams = isScaleFourIntervals
      ? randomIntegerInclusiveStep(250, 750, 250)
      : randomIntegerInclusiveStep(200, 800, 200);
    const image = getRandomFromArray(defaultWeightImages);
    return { scaleMaxKilograms, isScaleFourIntervals, kilograms, grams, image };
  },
  Component: ({
    question: { kilograms, scaleMaxKilograms, isScaleFourIntervals, grams, image },
    translate
  }) => {
    const weight = number(math.evaluate(`(${kilograms} * 1000) + ${grams}`));

    const svgName = isScaleFourIntervals
      ? `Scales/${scaleMaxKilograms}_kg_scale_250_g`
      : `Scales/${scaleMaxKilograms}_kg_scale_200_g`;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKgAndG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[kilograms.toString(), grams.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={dimens.width * 0.3}
              svgName={svgName as SvgName}
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aIY',
  description: 'aIY',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    scaleBMaxKilograms: z.number().int().min(4).max(10),
    scaleAIntervals: numberEnum([4, 5, 10]),
    scaleAGrams: z.number().int().min(100).max(900),
    scaleBKilograms: z.number().int().min(1).max(9),
    imageA: z.enum(defaultWeightImages),
    imageB: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleAIntervals = getRandomFromArray([4, 5, 10] as const);
    const scaleBMaxKilograms = randomIntegerInclusive(4, 10);

    const [imageA, imageB] = getRandomSubArrayFromArray(defaultWeightImages, 2);

    const scaleAGrams =
      scaleAIntervals === 4
        ? randomIntegerInclusiveStep(250, 750, 250)
        : scaleAIntervals === 5
        ? randomIntegerInclusiveStep(200, 800, 200)
        : randomIntegerInclusiveStep(100, 900, 100);

    const scaleBKilograms = randomIntegerInclusive(1, scaleBMaxKilograms - 1);

    return { scaleAIntervals, scaleBMaxKilograms, scaleAGrams, scaleBKilograms, imageA, imageB };
  },
  Component: ({
    question: { scaleAIntervals, scaleBMaxKilograms, scaleAGrams, scaleBKilograms, imageA, imageB },
    translate
  }) => {
    const svgNameA =
      scaleAIntervals === 4
        ? `Scales/1000g_scale_250g`
        : scaleAIntervals === 5
        ? `Scales/1000g_scale_200g`
        : `Scales/1000g_scale_100g`;

    const svgNameB = `Scales/Various_kg_scale_${scaleBMaxKilograms}kg`;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKgAndG()}
        title={translate.instructions.whatIsMassOfObjects()}
        testCorrect={[scaleBKilograms.toString(), scaleAGrams.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', gap: 100, alignItems: 'flex-end' }}>
              <Scales
                weightG={scaleAGrams}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName={svgNameA as SvgName}
                weightImage={imageA}
              />
              <Scales
                weightG={scaleBKilograms * 1000}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName={svgNameB as SvgName}
                weightImage={imageB}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aIZ',
  description: 'aIZ',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms', 'Compare'],
  schema: z
    .object({
      scaleAMaxKilograms: z.number().int().min(1).max(6),
      scaleBMaxKilograms: z.number().int().min(1).max(6),
      scaleAIntervals: numberEnum([2, 4, 5]),
      scaleBIntervals: numberEnum([2, 4, 5]),
      scaleAGrams: z.number().int().min(200).max(5800),
      scaleBGrams: z.number().int().min(200).max(5800),
      imageA: z.enum(defaultWeightImages),
      imageB: z.enum(defaultWeightImages),
      isGreatest: z.boolean()
    })
    .refine(
      val => val.scaleAMaxKilograms !== val.scaleBMaxKilograms,
      'scaleAMaxKilograms cannot equal scaleBMaxKilograms'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const {
      scaleAIntervals,
      scaleBIntervals,
      scaleAMaxKilograms,
      scaleBMaxKilograms,
      scaleAGrams,
      scaleBGrams
    } = rejectionSample(
      () => {
        const scaleAIntervals = getRandomFromArray([2, 4, 5] as const);
        const scaleBIntervals = getRandomFromArray([2, 4, 5] as const);

        const [scaleAMaxKilograms, scaleBMaxKilograms] = getRandomSubArrayFromArray(
          [1, 3, 4, 5, 6],
          2 as const
        );

        const maxGramsA = scaleAMaxKilograms * 1000;
        const maxGramsB = scaleBMaxKilograms * 1000;

        const scaleAGrams =
          scaleAIntervals === 4
            ? randomIntegerInclusiveStep(250, maxGramsA - 250, 250)
            : scaleAIntervals === 5
            ? randomIntegerInclusiveStep(200, maxGramsA - 200, 200)
            : randomIntegerInclusiveStep(500, maxGramsA - 500, 500);

        const scaleBGrams =
          scaleBIntervals === 4
            ? randomIntegerInclusiveStep(250, maxGramsB - 250, 250)
            : scaleBIntervals === 5
            ? randomIntegerInclusiveStep(200, maxGramsB - 200, 200)
            : randomIntegerInclusiveStep(500, maxGramsB - 500, 500);

        return {
          scaleAIntervals,
          scaleBIntervals,
          scaleAMaxKilograms,
          scaleBMaxKilograms,
          scaleAGrams,
          scaleBGrams
        };
      },
      ({ scaleAGrams, scaleBGrams }) => scaleAGrams !== scaleBGrams
    );

    const [imageA, imageB] = getRandomSubArrayFromArray(defaultWeightImages, 2);

    const isGreatest = getRandomBoolean();

    return {
      scaleAMaxKilograms,
      scaleAIntervals,
      scaleAGrams,
      scaleBMaxKilograms,
      scaleBIntervals,
      scaleBGrams,
      imageA,
      imageB,
      isGreatest
    };
  },
  Component: ({
    question: {
      scaleAMaxKilograms,
      scaleAIntervals,
      scaleAGrams,
      scaleBMaxKilograms,
      scaleBIntervals,
      scaleBGrams,
      imageA,
      imageB,
      isGreatest
    },
    translate
  }) => {
    const getSvgName = (scaleIntervals: 2 | 4 | 5, maxKilograms: number) => {
      switch (scaleIntervals) {
        case 2:
          return `Scales/${maxKilograms}_kg_scale_500_g`;
        case 4:
          return `Scales/${maxKilograms}_kg_scale_250_g`;
        default:
          return `Scales/${maxKilograms}_kg_scale_200_g`;
      }
    };

    const svgNameA = getSvgName(scaleAIntervals, scaleAMaxKilograms);
    const svgNameB = getSvgName(scaleBIntervals, scaleBMaxKilograms);

    const options = [
      {
        weight: scaleAGrams,
        svgName: svgNameA,
        weightImage: imageA
      },
      {
        weight: scaleBGrams,
        svgName: svgNameB,
        weightImage: imageB
      }
    ];

    const answer = isGreatest
      ? [Math.max(scaleAGrams, scaleBGrams)]
      : [Math.min(scaleAGrams, scaleBGrams)];

    return (
      <QF11SelectImagesUpTo4
        title={
          isGreatest
            ? translate.instructions.selectObjectWithTheGreatestMass()
            : translate.instructions.selectObjectWithTheSmallestMass()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.circleObjectWithTheGreatestMass()
            : translate.instructions.circleObjectWithTheSmallestMass()
        }
        numItems={2}
        itemStyle={{ justifyContent: 'flex-end' }}
        renderItems={({ dimens }) =>
          options.map(({ weight, svgName, weightImage }) => {
            return {
              value: weight,
              component: (
                <Scales
                  weightG={weight}
                  scaleWidth={dimens.width * 0.5}
                  svgName={svgName as SvgName}
                  weightImage={weightImage}
                />
              )
            };
          })
        }
        testCorrect={answer}
        questionHeight={1200}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aIZ2',
  description: 'aIZ',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms'],
  schema: z.object({
    weight: z.number().int().min(100).max(5900),
    maxKg: numberEnum([4, 5]),
    division: numberEnum([200, 250])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const maxKg = getRandomFromArray([4, 5] as const);
    const division = getRandomFromArray([200, 250] as const);
    const weight = randomIntegerInclusiveStep(division, maxKg * 1000 - division, division);

    return { weight, maxKg, division };
  },
  Component: ({ question: { weight, maxKg, division }, translate }) => {
    const svgImage = `Scales/${maxKg}_kg_scale_${division}_g`;

    return (
      <QF65SetTheScales
        title={translate.instructions.dragTheArrowToShowMassOnScale(weight)}
        pdfTitle={translate.instructions.drawArrowOnScalesToShowXG(weight)}
        svgName={svgImage as SvgName}
        answerGrams={weight}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureMassInKilogramsAndGrams',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
