import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getItemArrangement,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  coinScaledSizes,
  currencyToWords,
  displayMoney,
  moneyFromXDenominations,
  moneyToHighestDenominations,
  numToCurrency
} from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { integerToWord } from '../../../../utils/math';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import {
  arrayHasNoDuplicates,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'as8',
  description: 'as8',
  keywords: ['Money', 'Pounds', 'Pence'],
  schema: z
    .object({
      penceA: z.object({
        sum: z.number().int().min(3).max(99),
        denominations: z.string().array().length(3)
      }),
      penceB: z.object({
        sum: z.number().int().min(2).max(99),
        denominations: z.string().array().length(2)
      }),
      poundsA: z.object({
        sum: z.number().int().min(10).max(70),
        denominations: z.string().array().length(2)
      }),
      poundsB: z.object({
        sum: z.number().int().min(5).max(50),
        denominations: z.string().array().length(1)
      })
    })
    .refine(
      val => val.penceA.sum !== val.penceB.sum && val.poundsA.sum !== val.poundsB.sum,
      'amounts must all be different'
    ),
  simpleGenerator: () => {
    const penceA = rejectionSample(
      () => moneyFromXDenominations(3, 'pence'),
      x => x.sum > 2 && x.sum < 100
    );
    const penceB = rejectionSample(
      () => moneyFromXDenominations(2, 'pence'),
      x => x.sum > 2 && x.sum < 100 && x.sum !== penceA.sum
    );

    const poundsA = rejectionSample(
      () => moneyFromXDenominations(2, 'pounds', [5, 10, 20, 50]),
      x => x.sum > 10 && x.sum < 71
    );
    const poundsB = rejectionSample(
      () => moneyFromXDenominations(1, 'pounds', [5, 10, 20, 50]),
      x => x.sum > 5 && x.sum < 51 && x.sum !== poundsA.sum
    );

    return { penceA, penceB, poundsA, poundsB };
  },

  Component: props => {
    const {
      question: { penceA, penceB, poundsA, poundsB },
      translate
    } = props;

    const penceBAsWords = currencyToWords(penceB.sum);
    const poundsBAsWords = currencyToWords(poundsB.sum * 100);

    const coinScales = coinScaledSizes([
      ...penceA.denominations,
      ...penceB.denominations,
      ...poundsA.denominations,
      ...poundsB.denominations
    ]);

    const statements = [
      {
        statement: `${penceA.sum.toLocaleString()}p`,
        correctAnswer: 'A'
      },
      {
        statement: `${penceBAsWords.charAt(0).toUpperCase()}${penceBAsWords.slice(1)}`, // capitalise the first letter
        correctAnswer: `B`
      },
      {
        statement: `£${poundsA.sum.toLocaleString()}`,
        correctAnswer: `C`
      },
      {
        statement: `${poundsBAsWords.charAt(0).toUpperCase()}${poundsBAsWords.slice(1)}`, // capitalise the first letter
        correctAnswer: 'D'
      }
    ];

    const items = [
      {
        component: (
          <View style={{ flexDirection: 'row', columnGap: 4, alignItems: 'center' }}>
            {penceA.denominations.map(money =>
              displayMoney(
                [money],
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1),
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1)
              )
            )}
          </View>
        ),
        value: 'A'
      },
      {
        component: (
          <View style={{ flexDirection: 'row', columnGap: 4, alignItems: 'center' }}>
            {penceB.denominations.map(money =>
              displayMoney(
                [money],
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1),
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1)
              )
            )}
          </View>
        ),
        value: 'B'
      },
      {
        component: (
          <View style={{ flexDirection: 'row', columnGap: 4, alignItems: 'center' }}>
            {poundsA.denominations.map(money =>
              displayMoney(
                [money],
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1),
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1)
              )
            )}
          </View>
        ),
        value: 'C'
      },
      {
        component: (
          <View style={{ flexDirection: 'row', columnGap: 4, alignItems: 'center' }}>
            {poundsB.denominations.map(money =>
              displayMoney(
                [money],
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1),
                80 *
                  // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                  (money in coinScales ? coinScales[money] : 1)
              )
            )}
          </View>
        ),
        value: 'D'
      }
    ];

    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchAmounts()}
        pdfTitle={translate.instructions.useCardsToMatchAmounts()}
        items={shuffledItems}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'as9',
  description: 'as9',
  keywords: ['Money', 'Pounds', 'Pence'],
  schema: z.object({
    var1: z.number().int().min(7).max(90),
    unit: z.enum(['pounds', 'pence'])
  }),
  simpleGenerator: () => {
    const unit = getRandomFromArray(['pounds', 'pence'] as const);
    const var1 = unit === 'pounds' ? randomIntegerInclusive(7, 38) : randomIntegerInclusive(19, 90);

    return {
      var1,
      unit
    };
  },
  Component: ({ question: { var1, unit }, translate, displayMode }) => {
    const denominations = moneyToHighestDenominations(var1, unit);
    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchMoney()}
        Content={
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {displayMoney(
              denominations,
              displayMode === 'digital' ? 120 : 150,
              displayMode === 'digital' ? 120 : 150,
              true
            )}
          </View>
        }
        sentence={
          unit === 'pounds'
            ? translate.answerSentences.poundAns()
            : translate.answerSentences.ansP()
        }
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[var1.toString()]}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ata',
  description: 'ata',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pounds: z.number().int().min(11).max(40),
    pence: z.number().int().min(21).max(49)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(11, 40, { constraint: x => x !== 20 });
    const pence = randomIntegerInclusive(21, 49);

    return {
      pounds,
      pence
    };
  },
  Component: ({ question: { pounds, pence }, translate, displayMode }) => {
    const denominations = [
      ...moneyToHighestDenominations(pounds, 'pounds'),
      ...moneyToHighestDenominations(pence, 'pence')
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchMoney()}
        Content={
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {displayMoney(
              denominations,
              displayMode === 'digital' ? 120 : 150,
              displayMode === 'digital' ? 120 : 150,
              true
            )}
          </View>
        }
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[pounds.toString(), pence.toString()]}
        pdfDirection="column"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'atb',
  description: 'atb',
  keywords: ['Money', 'Pounds', 'Pence'],
  schema: z.object({
    var1: z.object({
      sum: z.number().int().min(3).max(15),
      denominations: z.string().array()
    }),
    var2: numberEnum([50, 20, 10, 5, 2, 1]),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const var1 = rejectionSample(
      () => {
        const amount = moneyFromXDenominations(3, 'pounds', [1, 2, 5]);

        //There always has to be a note so we modify the result of amount to add £5
        return amount.denominations.includes('£5')
          ? amount
          : {
              sum: amount.sum + 5,
              denominations: ['£5', ...amount.denominations]
            };
      },
      x => x.sum <= 15
    );
    const var2 = getRandomFromArray([50, 20, 10, 5, 2, 1] as const);
    const name = getRandomName();

    return { var1, var2, name };
  },
  Component: props => {
    const {
      question: { var1, var2, name },
      translate,
      displayMode
    } = props;

    const { sum, denominations: optionA } = var1;
    // Amounts
    const optionB = [...optionA, `${var2}p`];
    const optionC = moneyToHighestDenominations(sum - 1, 'pounds', [2, 1]);
    const optionD = [...optionC, `${var2}p`];

    // Selectables
    const selectables = shuffle(
      [
        {
          value: 'A',
          amount: optionA
        },
        {
          value: 'B',
          amount: optionB
        },
        {
          value: 'C',
          amount: optionC
        },
        {
          value: 'D',
          amount: optionD
        }
      ],
      { random: seededRandom(props.question) }
    );

    const coinScales = coinScaledSizes([...optionA, ...optionB, ...optionC, ...optionD]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterEarnsXWalkingDogSelectMoneyGiven({
          character: name,
          amount: `£${var1.sum}`
        })}
        pdfTitle={translate.instructions.characterEarnsXWalkingDogCircleMoneyGiven({
          character: name,
          amount: `£${var1.sum}`
        })}
        testCorrect={['A']}
        numItems={4}
        renderItems={() =>
          selectables.map(selectable => {
            return {
              value: selectable.value,
              component: (
                <View
                  style={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 12
                  }}
                >
                  {selectable.amount.map(money =>
                    displayMoney(
                      [money],
                      (displayMode === 'digital' ? 90 : 150) *
                        // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                        (money in coinScales ? coinScales[money] : 1),
                      (displayMode === 'digital' ? 90 : 150) *
                        // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                        (money in coinScales ? coinScales[money] : 1)
                    )
                  )}
                </View>
              )
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'atc',
  description: 'atc',
  keywords: ['Money', 'Pounds', 'Pence'],
  schema: z.object({
    pounds: z.number().int().min(11).max(80),
    pence: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const { pounds, pence } = rejectionSample(
      () => {
        const pounds = randomIntegerInclusive(11, 80, { constraint: x => x % 10 < 6 });

        const pence = randomIntegerInclusive(1, 99);
        return { pounds, pence };
      },

      ({ pounds, pence }) =>
        arrayHasNoDuplicates(moneyToHighestDenominations(pounds, 'pounds')) &&
        arrayHasNoDuplicates(moneyToHighestDenominations(pence, 'pence'))
    );

    return {
      pounds,
      pence
    };
  },

  Component: props => {
    const {
      question: { pounds, pence },
      translate,
      locale
    } = props;

    const moneyObjects = [
      { currency: '1p', value: 1 },
      { currency: '10p', value: 10 },
      { currency: '£1', value: 100 },
      { currency: '£10', value: 1000 },
      { currency: '2p', value: 2 },
      { currency: '20p', value: 20 },
      { currency: '£2', value: 200 },
      { currency: '£20', value: 2000 },
      { currency: '5p', value: 5 },
      { currency: '50p', value: 50 },
      { currency: '£5', value: 500 },
      { currency: '£50', value: 5000 }
    ];

    const items = shuffle(
      moneyObjects.map(({ value, currency }) => {
        return {
          value: value,
          component: displayMoney([currency], value > 200 ? 170 : 80, 80)[0],
          currency
        };
      }),
      { random: seededRandom(props.question) }
    );

    const moneyString = numToCurrency({ amount: pounds + pence / 100, locale });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNotesAndCoinsToMakeXPoundsAndYPence(pounds, pence)}
        pdfTitle={translate.instructions.circleNotesAndCoinsToMakeXPoundsAndYPence(pounds, pence)}
        testCorrect={userAnswer =>
          moneyString ===
          numToCurrency({
            amount: sumNumberArray(userAnswer.map(num => Number(num))) / 100,
            locale
          })
        }
        multiSelect
        items={items.map(selectable => ({
          value: selectable.value,
          component: selectable.component as JSX.Element
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            ...moneyToHighestDenominations(pence, 'pence'),
            ...moneyToHighestDenominations(pounds, 'pounds')
          ].flatMap(currency => {
            const moneyObject = moneyObjects.find(obj => obj.currency === currency);
            if (moneyObject === undefined) {
              console.error("moneyToHighestDenominations produced a value that wasn't present");
              return [];
            } else {
              return [moneyObject.value];
            }
          }),
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6v2 = newQuestionContent({
  uid: 'atd2',
  description: 'atd',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    coins: z.array(z.enum(['1p', '2p', '5p', '10p', '20p', '50p', '£1', '£2'])).length(8),
    sum: z.number().min(3).max(699),
    minmax: z.enum(['greatest', 'smallest']),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const minmax = getRandomFromArray(['greatest', 'smallest'] as const);
    const numValidOptions = randomIntegerInclusive(3, 6);

    const coins = rejectionSample(
      () => {
        if (minmax === 'smallest') {
          const amount = moneyFromXDenominations(numValidOptions, 'pence');

          return { ...amount, doesNotExchange: amount.sum < 100 };
        }

        const pounds = moneyFromXDenominations(Math.ceil(numValidOptions / 2), 'pounds', [2, 1]);
        const pence = moneyFromXDenominations(
          Math.floor(numValidOptions / 2),
          'pence',
          [50, 20, 10, 5]
        );

        return {
          denominations: [...pounds.denominations, ...pence.denominations],
          valuesInP: [...pounds.valuesInP, ...pence.valuesInP],
          doesNotExchange: pence.sum < 100
        };
      },
      x => x.doesNotExchange
    );

    // If minmax is 'smallest', we must enforce at least 5 coins on screen to be pence coins, no matter what numValidOptions is:
    const extraPenceCoinsNeeded =
      minmax === 'smallest' ? Math.max(5 - coins.denominations.length, 0) : 0;

    const extraCoinsResult =
      extraPenceCoinsNeeded > 0
        ? [
            moneyFromXDenominations(extraPenceCoinsNeeded, 'pence', [50]),
            moneyFromXDenominations(
              8 - coins.denominations.length - extraPenceCoinsNeeded,
              minmax === 'smallest' ? 'pounds' : 'pence',
              [2, 1]
            )
          ]
        : [
            moneyFromXDenominations(
              8 - coins.denominations.length,
              minmax === 'smallest' ? 'pounds' : 'pence',
              [2, 1]
            )
          ];

    const spareCoins = extraCoinsResult.reduce((acc, cur) => ({
      sum: acc.sum + cur.sum,
      denominations: [...acc.denominations, ...cur.denominations],
      valuesInP: [...acc.valuesInP, ...cur.valuesInP]
    }));

    // Needed to combine all coins
    const allCoins = [...coins.denominations, ...spareCoins.denominations] as (
      | '1p'
      | '2p'
      | '5p'
      | '10p'
      | '20p'
      | '50p'
      | '£1'
      | '£2'
    )[];
    const allCoinsValue = sortNumberArray(
      [...coins.valuesInP, ...spareCoins.valuesInP],
      minmax === 'smallest' ? 'ascending' : 'descending'
    );
    // Total amount of the smallest or greatest 4 coins.
    const sum = sumNumberArray(allCoinsValue.slice(0, 4));

    return {
      coins: allCoins,
      sum,
      minmax,
      name
    };
  },
  Component: ({ question: { coins, sum, minmax, name }, translate, displayMode }) => {
    const poundsAnswer = Math.trunc(sum / 100);
    const penceAnswer = sum - poundsAnswer * 100;

    const sentence =
      minmax === 'smallest'
        ? translate.answerSentences.ansP()
        : translate.answerSentences.poundAnsAndAnsPence();

    const minMaxString =
      minmax === 'smallest' ? translate.keywords.Smallest() : translate.keywords.Greatest();

    const testCorrect =
      minmax === 'smallest'
        ? [penceAnswer.toString()]
        : [poundsAnswer.toString(), penceAnswer.toString()];

    const arrangement = getItemArrangement(coins, seededRandom(coins.map(coin => coin)), 2, 6);

    const coinScales = coinScaledSizes(coins);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.coinsAreOnATable()}
        Content={({ dimens }) => (
          <>
            {arrangement.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  height: dimens.height / 3,
                  width: dimens.width,
                  alignItems: 'center'
                }}
              >
                {row.map(({ marginLeft, item }, itemIndex) => (
                  <View key={itemIndex} style={{ marginLeft, gap: 16 }}>
                    {displayMoney(
                      [item],
                      (displayMode === 'digital' ? 90 : 150) * coinScales[item],
                      (displayMode === 'digital' ? 90 : 150) * coinScales[item]
                    )}
                  </View>
                ))}
              </View>
            ))}
            <TextStructure
              sentence={`${translate.answerSentences.characterChoosesFourOfTheCoins(
                name
              )}<br/>${translate.instructions.whatIsGreatestSmallestAmountOfMoneyCharCouldHave(
                minMaxString,
                name
              )}`}
              style={{ alignSelf: 'flex-start' }}
            />
          </>
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={testCorrect}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'atd',
  description: 'atd',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    coins: z.string().array().length(8),
    sum: z.number().min(3).max(699),
    numValidOptions: z.number().min(3).max(6),
    minmax: z.enum(['greatest', 'smallest']),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const minmax = getRandomFromArray(['greatest', 'smallest'] as const);
    const numValidOptions = randomIntegerInclusive(3, 6);

    const coins = rejectionSample(
      () => {
        if (minmax === 'smallest') {
          const amount = moneyFromXDenominations(numValidOptions, 'pence');

          return { ...amount, doesNotExchange: amount.sum < 100 };
        }

        const pounds = moneyFromXDenominations(Math.ceil(numValidOptions / 2), 'pounds', [2, 1]);
        const pence = moneyFromXDenominations(
          Math.floor(numValidOptions / 2),
          'pence',
          [50, 20, 10, 5]
        );

        return {
          sum: pounds.sum * 100 + pence.sum,
          denominations: [...pounds.denominations, ...pence.denominations],
          doesNotExchange: pence.sum < 100
        };
      },
      x => x.doesNotExchange
    );

    // If minmax is 'smallest', we must enforce at least 5 coins on screen to be pence coins, no matter what numValidOptions is:
    const sparePenceCoinsNeeded =
      minmax === 'smallest' ? Math.max(5 - coins.denominations.length, 0) : 0;

    const spareCoinsResult =
      sparePenceCoinsNeeded > 0
        ? [
            moneyFromXDenominations(sparePenceCoinsNeeded, 'pence', [50]),
            moneyFromXDenominations(
              8 - coins.denominations.length - sparePenceCoinsNeeded,
              minmax === 'smallest' ? 'pounds' : 'pence',
              [2, 1]
            )
          ]
        : [
            moneyFromXDenominations(
              8 - coins.denominations.length,
              minmax === 'smallest' ? 'pounds' : 'pence',
              [2, 1]
            )
          ];

    // Needed to combine the objects of sparePenceNeeded's denominations, and the remaining denominations from spareCoinsResult, if any:
    const spareCoins = spareCoinsResult.reduce((acc, cur) => ({
      sum: acc.sum + cur.sum,
      denominations: [...acc.denominations, ...cur.denominations],
      valuesInP: [...acc.valuesInP, ...cur.valuesInP]
    }));

    const name = getRandomName();

    return {
      coins: [...coins.denominations, ...spareCoins.denominations],
      sum: coins.sum,
      minmax,
      numValidOptions,
      name
    };
  },
  Component: ({
    question: { coins, sum, minmax, numValidOptions, name },
    translate,
    displayMode
  }) => {
    const poundsAnswer = Math.trunc(sum / 100);
    const penceAnswer = sum - poundsAnswer * 100;

    const sentence =
      minmax === 'smallest'
        ? translate.answerSentences.ansP()
        : translate.answerSentences.poundAnsAndAnsPence();

    const minMaxString =
      minmax === 'smallest' ? translate.keywords.Smallest() : translate.keywords.Greatest();

    const testCorrect =
      minmax === 'smallest'
        ? [penceAnswer.toString()]
        : [poundsAnswer.toString(), penceAnswer.toString()];

    const arrangement = getItemArrangement(coins, seededRandom(coins.map(coin => coin)), 3, 6);

    const coinScales = coinScaledSizes(coins);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterChoosesNumCoinsWhatGreatestSmallestMoney({
          character: name,
          num: integerToWord(numValidOptions),
          minmax: minMaxString
        })}
        Content={({ dimens }) => {
          return arrangement.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                height: dimens.height / 3,
                width: dimens.width,
                alignItems: 'center'
              }}
            >
              {row.map(({ marginLeft, item }, itemIndex) => (
                <View key={itemIndex} style={{ marginLeft, gap: 16 }}>
                  {displayMoney(
                    [item],
                    (displayMode === 'digital' ? 90 : 150) * coinScales[item],
                    (displayMode === 'digital' ? 90 : 150) * coinScales[item]
                  )}
                </View>
              ))}
            </View>
          ));
        }}
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        testCorrect={testCorrect}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PoundsAndPence',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});

export default SmallStep;
