import { PartWholeModel } from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  aaJ,
  Question1 as Y3Question1,
  Question3 as Y3Question3,
  Question4 as Y3Question4,
  Question5 as Y3Question5
} from 'common/src/SchemeOfLearning/Year 3/Autumn/PlaceValue/6PartitionNumbersTo1000';
import { base10ObjectToNumber } from 'common/src/utils/math';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from 'common/src/utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const Question1 = { ...Y3Question1, uid: 'abC', description: 'abC' as const };

const Question2 = { ...Y3Question3, uid: 'abD', description: 'abD' as const };

const Question3 = { ...Y3Question4, uid: 'abE', description: 'abE' as const };

const Question4 = newQuestionContent({
  uid: 'abF',
  description: 'abF',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(101).max(989),
    numberPartition: z.number().int().array().length(2),
    wrongNumberPartitions: z.number().int().array().length(2).array().length(3),
    correctPosition: z.number().int().min(0).max(3)
  }),
  example: {
    number: 348,
    numberPartition: [8, 340],
    wrongNumberPartitions: [
      [8, 34],
      [8, 304],
      [40, 380]
    ],
    correctPosition: 3
  },
  simpleGenerator: () => {
    // Limit ones to 1-9 and tens to 1-9 (if ones are not zero from above, tens are zero instead) and hundreds to 1-9
    const tens = randomIntegerInclusive(0, 9);

    // Ensure ones are never the same as tens
    const ones = rejectionSample(
      () => randomIntegerInclusive(0, 9),
      x => x !== tens
    );

    // Ensure hundreds are never the same as tens
    const hundreds = rejectionSample(
      () => randomIntegerInclusive(1, 9),
      x => x !== tens
    );

    const number = base10ObjectToNumber({ ones, tens, hundreds });

    // Ensure correct part-whole model doesn't show one circle as just 0
    const numberPartition =
      ones === 0 ? [tens * 10, ones + hundreds * 100] : [ones, tens * 10 + hundreds * 100];

    const wrongNumberPartitions = [
      [ones, (tens * 10 + hundreds * 100) / 10],
      [ones * 10, tens + hundreds * 100],
      [tens, ones * 10 + hundreds * 100]
    ];

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, numberPartition, wrongNumberPartitions, correctPosition };
  },
  Component: props => {
    const {
      question: { number, numberPartition, wrongNumberPartitions, correctPosition },
      translate
    } = props;
    const allPartitions = [
      ...wrongNumberPartitions.slice(0, correctPosition),
      numberPartition,
      ...wrongNumberPartitions.slice(correctPosition)
    ];

    return (
      <QF11SelectImagesUpTo4<number>
        title={`${translate.instructions.selectTheCorrectPartWholeModelForNum(
          number.toLocaleString()
        )}`}
        pdfTitle={`${translate.instructions.circleTheCorrectPartWholeModelForNum(
          number.toLocaleString()
        )}`}
        testCorrect={[correctPosition]}
        numItems={4}
        renderItems={({ dimens }) => {
          return allPartitions.map((partition, index) => ({
            value: index,
            component: (
              <PartWholeModel
                top={number}
                partition={partition}
                dimens={dimens}
                representation="text"
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = { ...Y3Question5, uid: 'abG', description: 'abG' as const };

const Question6 = { ...aaJ, uid: 'abH', description: 'abH' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
