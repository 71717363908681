import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import Text from '../../../../components/typography/Text';
import { ALGEBRAIC_B, ALGEBRAIC_H } from '../../../../constants';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import { compareFloats } from '../../../../utils/math';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWQ',
  description: 'aWQ',
  keywords: ['Area', 'Triangle'],
  schema: z
    .object({
      base: z.number().int().min(4).max(8),
      height: z.number().int().min(5).max(12),
      triangle: z.enum([
        'Shapes_with_arrows/Triangle2_2_arrows',
        'Shapes_with_arrows/Triangle3_2_arrows',
        'Shapes_with_arrows/Triangle4_2_arrows',
        'Shapes_with_arrows/Triangle5_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
      ])
    })
    .refine(val => val.base < val.height, 'base must be less than height.')
    .refine(val => (val.base * val.height) % 2 === 0, 'base x height must be a multiple of 2'),
  simpleGenerator: () => {
    const triangleType = getRandomFromArray(['acute', 'RA']);

    const base = randomIntegerInclusive(4, triangleType === 'acute' ? 7 : 8);

    const height = randomIntegerInclusive(base + 1, 12, {
      constraint: x => (x * base) % 2 === 0
    });

    // TODO: Obtuse triangle SVGs with 2 arrows need adding when these become available.
    const triangle = getRandomFromArray(
      triangleType === 'acute'
        ? ([
            'Shapes_with_arrows/Triangle2_2_arrows',
            'Shapes_with_arrows/Triangle3_2_arrows',
            'Shapes_with_arrows/Triangle4_2_arrows',
            'Shapes_with_arrows/Triangle5_2_arrows'
          ] as const)
        : ([
            'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
          ] as const)
    );

    return { base, height, triangle };
  },
  Component: props => {
    const {
      question: { base, height, triangle },
      translate
    } = props;

    const area = (base * height) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfTriangle()}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            labels={[translate.units.numberOfCm(height), translate.units.numberOfCm(base)]}
          />
        )}
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        testCorrect={[area.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aWQ2',
  description: 'aWQ2',
  keywords: ['Triangle', 'Estimate', 'Area'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8),
    point2X: z.number().int().min(1).max(7),
    isFlatTop: z.boolean()
  }),
  simpleGenerator: () => {
    const isFlatTop = getRandomBoolean();
    const width = randomIntegerInclusive(2, 8);
    // need to make sure its not a right-angled triangle
    const height = randomIntegerInclusive(2, 5, {
      constraint: x => x / 2 !== width && x * 2 !== width
    });
    const point2X = randomIntegerInclusive(1, width - 1);

    return { isFlatTop, width, height, point2X };
  },
  Component: props => {
    const {
      question: { isFlatTop, width, height, point2X },
      translate
    } = props;

    const point1 = [0, isFlatTop ? height : 0];
    const point2 = [point2X, isFlatTop ? 0 : height];
    const point3 = [width, isFlatTop ? height : 0];

    const coordinates = [point1, point2, point3];

    const area = (height * width) / 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCmSquared()}
        title={translate.instructions.whatIsAreaOfTriangle()}
        testCorrect={[area.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        extraSymbol="decimalPoint"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={coordinates.map(val => val as [number, number])}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aWR',
  description: 'aWR',
  keywords: ['Area', 'Triangle'],
  schema: z.object({
    edge: z.number().int().min(3).max(28),
    base: z.number().int().min(3).max(28),
    height: z.number().int().min(3).max(12),
    triangle: z.enum([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ]),
    isCorrect: z.boolean()
  }),
  simpleGenerator: () => {
    // TODO: Obtuse and RA triangle SVGs with 3 arrows need adding when these become available.
    const triangle = getRandomFromArray([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ] as const);

    const { var1, var3, var4, var5 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(4, 7);
        const var4 = randomIntegerInclusive(var1 + 1, 12);
        const var5 = randomIntegerInclusive(Math.round(var1 / Math.SQRT2), var1 - 1);
        const var3 = Math.round((var4 * var1) / var5);
        return { var1, var3, var4, var5 };
      },
      ({ var3, var4 }) =>
        triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle'
          ? // need to make sure there are valid values for var2
            var3 - var4 > 1
          : true
    );

    let edge;
    let height;
    let base;
    if (
      [
        'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle6_3_arrows_right_angle'
      ].includes(triangle)
    ) {
      base = var1;
      height = var4;
      edge = var3;
    } else if (triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle') {
      const var2 = randomIntegerInclusive(var4 + 1, var3 - 1);
      base = var1;
      height = var4;
      edge = var2;
    } else {
      base = var3;
      height = var5;
      edge = var1;
    }

    const isCorrect = getRandomBoolean();

    return { edge, base, height, triangle, isCorrect };
  },
  Component: props => {
    const {
      question: { edge, base, height, triangle, isCorrect },
      translate
    } = props;

    const number1 = isCorrect ? height : edge;
    const answer = (number1 * base) / 2;

    const selectables = [
      ['True', translate.keywords.True()],
      ['False', translate.keywords.False()]
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.isTheAreaOfTheTriangleXMultYDiv2SelectTrueOrFalse(
          number1,
          base,
          answer
        )}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        pdfTitle={translate.instructions.isTheAreaOfTheTriangleXMultYDiv2CircleTrueOrFalse(
          number1,
          base,
          answer
        )}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <LabelledShape
                dimens={dimens}
                shapeName={triangle}
                labels={[
                  translate.units.numberOfCm(height),
                  translate.units.numberOfCm(base),
                  translate.units.numberOfCm(edge)
                ]}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[isCorrect ? 'True' : 'False']}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aWR2',
  description: 'aWR2',
  keywords: ['Area', 'Triangle'],
  schema: z
    .object({
      base: z.number().int().min(4).max(8),
      height: z.number().int().min(5).max(12),
      triangle: z.enum([
        'Shapes_with_arrows/Triangle2_2_arrows',
        'Shapes_with_arrows/Triangle3_2_arrows',
        'Shapes_with_arrows/Triangle4_2_arrows',
        'Shapes_with_arrows/Triangle5_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
      ])
    })
    .refine(val => val.base < val.height, 'base must be less than height.')
    .refine(val => (val.base * val.height) % 2 === 0, 'base x height must be a multiple of 2'),
  simpleGenerator: () => {
    const triangleType = getRandomFromArray(['acute', 'RA']);

    const base = randomIntegerInclusive(4, triangleType === 'acute' ? 7 : 8);

    const height = randomIntegerInclusive(base + 1, 12, {
      constraint: x => (x * base) % 2 === 0
    });

    // TODO: Obtuse triangle SVGs with 2 arrows need adding when these become available.
    const triangle = getRandomFromArray(
      triangleType === 'acute'
        ? ([
            'Shapes_with_arrows/Triangle2_2_arrows',
            'Shapes_with_arrows/Triangle3_2_arrows',
            'Shapes_with_arrows/Triangle4_2_arrows',
            'Shapes_with_arrows/Triangle5_2_arrows'
          ] as const)
        : ([
            'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
          ] as const)
    );

    return { base, height, triangle };
  },
  Component: props => {
    const {
      question: { base, height, triangle },
      translate
    } = props;

    const area = (base * height) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfTriangle()}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            labels={[translate.units.numberOfCm(height), translate.units.numberOfCm(base)]}
          />
        )}
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        testCorrect={[area.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aWS',
  description: 'aWS',
  keywords: ['Area', 'Triangle'],
  schema: z.object({
    edge: z.number().int().min(3).max(28),
    base: z.number().int().min(3).max(28),
    height: z.number().int().min(3).max(12),
    triangle: z.enum([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ])
  }),
  simpleGenerator: () => {
    // TODO: Obtuse and RA triangle SVGs with 3 arrows need adding when these become available.
    const triangle = getRandomFromArray([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ] as const);

    const { var1, var3, var4, var5 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(4, 7);
        const var4 = randomIntegerInclusive(var1 + 1, 12);
        const var5 = randomIntegerInclusive(Math.round(var1 / Math.SQRT2), var1 - 1);
        const var3 = Math.round((var4 * var1) / var5);
        return { var1, var3, var4, var5 };
      },
      ({ var3, var4 }) =>
        triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle'
          ? // need to make sure there are valid values for var2
            var3 - var4 > 1
          : true
    );

    let edge;
    let height;
    let base;
    if (
      [
        'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle6_3_arrows_right_angle'
      ].includes(triangle)
    ) {
      base = var1;
      height = var4;
      edge = var3;
    } else if (triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle') {
      const var2 = randomIntegerInclusive(var4 + 1, var3 - 1);
      base = var1;
      height = var4;
      edge = var2;
    } else {
      base = var3;
      height = var5;
      edge = var1;
    }

    return { edge, base, height, triangle };
  },
  Component: props => {
    const {
      question: { edge, base, height, triangle },
      translate
    } = props;

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.dragTheCardsToMatchTheBaseAndHeightOfTriangle()}
        pdfTitle={translate.instructions.useTheCardsToMatchTheBaseAndHeightOfTriangle()}
        items={shuffle(
          [edge, base, height].map(value => ({
            value,
            component: <Text variant="WRN700">{value.toLocaleString()}</Text>
          })),
          { random: seededRandom(props.question) }
        )}
        Content={
          <MeasureView>
            {dimens => (
              <LabelledShape
                dimens={dimens}
                shapeName={triangle}
                labels={[
                  translate.units.numberOfCm(height),
                  translate.units.numberOfCm(base),
                  translate.units.numberOfCm(edge)
                ]}
              />
            )}
          </MeasureView>
        }
        testCorrect={[[base], [height]]}
        sentences={[
          `${ALGEBRAIC_B} = ${translate.answerSentences.ansCm()}`,
          `${ALGEBRAIC_H} = ${translate.answerSentences.ansCm()}`
        ]}
        actionPanelVariant="end"
        sentencesStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        questionHeight={1250}
      />
    );
  },
  questionHeight: 1250
});

const Question3v2 = newQuestionContent({
  uid: 'aWS2',
  description: 'aWS',
  keywords: ['Area', 'Triangle'],
  schema: z.object({
    edge: z.number().int().min(3).max(28),
    base: z.number().int().min(3).max(28),
    height: z.number().int().min(3).max(12),
    triangle: z.enum([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ])
  }),
  simpleGenerator: () => {
    // TODO: Obtuse and RA triangle SVGs with 3 arrows need adding when these become available.
    const triangle = getRandomFromArray([
      'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_3_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
    ] as const);

    const { var1, var3, var4, var5 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(4, 7);
        const var4 = randomIntegerInclusive(var1 + 1, 12);
        const var5 = randomIntegerInclusive(Math.round(var1 / Math.SQRT2), var1 - 1);
        const var3 = Math.round((var4 * var1) / var5);
        return { var1, var3, var4, var5 };
      },
      ({ var3, var4 }) =>
        triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle'
          ? // need to make sure there are valid values for var2
            var3 - var4 > 1
          : true
    );

    let edge;
    let height;
    let base;
    if (
      [
        'Shapes_with_arrows/Triangle1_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle3_3_arrows_right_angle',
        'Shapes_with_arrows/Triangle6_3_arrows_right_angle'
      ].includes(triangle)
    ) {
      base = var1;
      height = var4;
      edge = var3;
    } else if (triangle === 'Shapes_with_arrows/Triangle7_3_arrows_right_angle') {
      const var2 = randomIntegerInclusive(var4 + 1, var3 - 1);
      base = var1;
      height = var4;
      edge = var2;
    } else {
      base = var3;
      height = var5;
      edge = var1;
    }

    return { edge, base, height, triangle };
  },
  Component: props => {
    const {
      question: { edge, base, height, triangle },
      translate,
      displayMode
    } = props;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteCalculationForArea()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculationForArea()}
        items={shuffle(
          [edge, base, height].map(value => ({
            value,
            component: <Text variant="WRN700">{value.toLocaleString()}</Text>
          })),
          { random: seededRandom(props.question) }
        )}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        Content={
          <MeasureView>
            {dimens => (
              <LabelledShape
                dimens={dimens}
                shapeName={triangle}
                labels={[
                  translate.units.numberOfCm(height),
                  translate.units.numberOfCm(base),
                  translate.units.numberOfCm(edge)
                ]}
              />
            )}
          </MeasureView>
        }
        testCorrect={userAnswer => arraysHaveSameContentsUnordered([base, height], userAnswer)}
        customMarkSchemeAnswer={{ answersToDisplay: [[base, height]] }}
        sentence={translate.answerSentences.areaEqualsHalfAnsMultAns()}
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-start' }}
        questionHeight={1250}
      />
    );
  },
  questionHeight: 1250
});

const Question4 = newQuestionContent({
  uid: 'aWT',
  description: 'aWT',
  keywords: ['Area', 'Triangle'],
  schema: z.object({
    edge1: z.number().int().min(3).max(28),
    edge2: z.number().int().min(3).max(28),
    base: z.number().int().min(3).max(28),
    height: z.number().int().min(3).max(12),
    triangle: z.enum([
      'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
    ])
  }),
  simpleGenerator: () => {
    // TODO: Obtuse and RA triangle SVGs with 3 arrows need adding when these become available.
    const triangle = getRandomFromArray([
      'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
    ] as const);

    const { var1, var3, var4, var5 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(4, 7);
        const var4 = randomIntegerInclusive(var1 + 1, 12);
        const var5 = randomIntegerInclusive(Math.round(var1 / Math.SQRT2), var1 - 1);
        const var3 = Math.round((var4 * var1) / var5);
        return { var1, var3, var4, var5 };
      },
      ({ var3, var4 }) => var3 - var4 > 1
    );
    const var2 = randomIntegerInclusive(var4 + 1, var3 - 1);

    let edge1;
    let edge2;
    let height;
    let base;
    if (
      [
        'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
        'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
        'Shapes_with_arrows/Triangle6_4_arrows_right_angle'
      ].includes(triangle)
    ) {
      base = var1;
      height = var4;
      edge1 = var3;
      edge2 = var2;
    } else if (triangle === 'Shapes_with_arrows/Triangle7_4_arrows_right_angle') {
      base = var1;
      height = var4;
      edge1 = var2;
      edge2 = var3;
    } else {
      base = var3;
      height = var5;
      edge1 = var1;
      edge2 = var2;
    }

    return { edge1, edge2, base, height, triangle };
  },
  Component: props => {
    const {
      question: { edge1, edge2, base, height, triangle },
      translate
    } = props;

    const area = (base * height) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateAreaOfShape(translate.shapes.triangles(1))}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            labels={[
              translate.units.numberOfCm(height),
              translate.units.numberOfCm(base),
              translate.units.numberOfCm(edge1),
              translate.units.numberOfCm(edge2)
            ]}
          />
        )}
        customMarkSchemeAnswer={{ answersToDisplay: [area.toLocaleString()] }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], area)}
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aWU',
  description: 'aWU',
  keywords: ['Area', 'Triangle'],
  schema: z.object({
    edge1: z.number().int().min(3).max(28),
    edge2: z.number().int().min(3).max(28),
    base: z.number().int().min(3).max(28),
    height: z.number().int().min(3).max(12),
    triangle: z.enum([
      'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
    ])
  }),
  simpleGenerator: () => {
    // TODO: Obtuse triangle SVGs with 3 arrows need adding when these become available.
    const triangle = getRandomFromArray([
      'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle2_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle4_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle6_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle7_4_arrows_right_angle',
      'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
    ] as const);

    const { var1, var2, var3, var4, var5 } = rejectionSample(
      () => {
        const { var1, var3, var4, var5 } = rejectionSample(
          () => {
            const var1 = randomIntegerInclusive(4, 7);
            const var4 = randomIntegerInclusive(var1 + 1, 12);
            const var5 = randomIntegerInclusive(Math.round(var1 / Math.SQRT2), var1 - 1);
            const var3 = Math.round((var4 * var1) / var5);
            return { var1, var3, var4, var5 };
          },
          ({ var3, var4 }) => var3 - var4 > 1
        );
        const var2 = randomIntegerInclusive(var4 + 1, var3 - 1);
        return { var1, var2, var3, var4, var5 };
      },
      ({ var1, var2, var3, var4, var5 }) => arrayHasNoDuplicates([var1, var2, var3, var4, var5])
    );

    let edge1;
    let edge2;
    let height;
    let base;
    if (
      [
        'Shapes_with_arrows/Triangle1_4_arrows_right_angle',
        'Shapes_with_arrows/Triangle3_4_arrows_right_angle',
        'Shapes_with_arrows/Triangle6_4_arrows_right_angle'
      ].includes(triangle)
    ) {
      base = var1;
      height = var4;
      edge1 = var3;
      edge2 = var2;
    } else if (triangle === 'Shapes_with_arrows/Triangle7_4_arrows_right_angle') {
      base = var1;
      height = var4;
      edge1 = var2;
      edge2 = var3;
    } else {
      base = var3;
      height = var5;
      edge1 = var1;
      edge2 = var2;
    }

    return { edge1, edge2, base, height, triangle };
  },
  Component: props => {
    const {
      question: { edge1, edge2, base, height, triangle },
      translate
    } = props;

    const area = (base * height) / 2;

    const options = shuffle(
      [
        ['A', translate.units.numberOfCm2(area)],
        ['B', translate.units.numberOfCm2((edge1 * base) / 2)],
        ['C', translate.units.numberOfCm2((edge2 * base) / 2)],
        ['D', translate.units.numberOfCm2((height * edge1) / 2)]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectAreaOfShape(translate.shapes.triangles(1))}
        pdfTitle={translate.instructions.circleAreaOfShape(translate.shapes.triangles(1))}
        selectables={Object.fromEntries(options)}
        leftContent={
          <MeasureView>
            {dimens => (
              <LabelledShape
                dimens={dimens}
                shapeName={triangle}
                labels={[
                  translate.units.numberOfCm(height),
                  translate.units.numberOfCm(base),
                  translate.units.numberOfCm(edge1),
                  translate.units.numberOfCm(edge2)
                ]}
              />
            )}
          </MeasureView>
        }
        correctAnswer={['A']}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aWV',
  description: 'aWV',
  keywords: ['Area', 'Triangle'],
  schema: z
    .object({
      base: z.number().int().min(4).max(8),
      height: z.number().int().min(5).max(12),
      baseOrHeightHidden: z.enum(['base', 'height']),
      algebraicSymbol: algebraicSymbolSchema,
      triangle: z.enum([
        'Shapes_with_arrows/Triangle2_2_arrows',
        'Shapes_with_arrows/Triangle3_2_arrows',
        'Shapes_with_arrows/Triangle4_2_arrows',
        'Shapes_with_arrows/Triangle5_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
        'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
      ])
    })
    .refine(val => val.base < val.height, 'base must be less than height.')
    .refine(val => (val.base * val.height) % 2 === 0, 'base x height must be a multiple of 2'),
  simpleGenerator: () => {
    const triangleType = getRandomFromArray(['acute', 'RA']);

    const base = randomIntegerInclusive(4, triangleType === 'acute' ? 7 : 8);

    const height = randomIntegerInclusive(base + 1, 12, {
      constraint: x => (x * base) % 2 === 0
    });

    const baseOrHeightHidden = getRandomFromArray(['base', 'height'] as const);

    const algebraicSymbol = getAlgebraicSymbol();

    // TODO: Obtuse triangle SVGs with 2 arrows need adding when these become available.
    const triangle = getRandomFromArray(
      triangleType === 'acute'
        ? ([
            'Shapes_with_arrows/Triangle2_2_arrows',
            'Shapes_with_arrows/Triangle3_2_arrows',
            'Shapes_with_arrows/Triangle4_2_arrows',
            'Shapes_with_arrows/Triangle5_2_arrows'
          ] as const)
        : ([
            'Shapes_with_arrows/Right_angle_triangle1_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle2_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle3_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle4_2_arrows',
            'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
          ] as const)
    );

    return { base, height, baseOrHeightHidden, algebraicSymbol, triangle };
  },
  Component: props => {
    const {
      question: { base, height, baseOrHeightHidden, algebraicSymbol, triangle },
      translate
    } = props;

    const area = (base * height) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theAreaOfTheTriangleIsXCm2WorkOutLengthMarkedY(
          area,
          algebraicSymbol
        )}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            labels={
              baseOrHeightHidden === 'base'
                ? [translate.units.numberOfCm(height), algebraicSymbol]
                : [algebraicSymbol, translate.units.numberOfCm(base)]
            }
          />
        )}
        sentence={translate.answerSentences.xEqualsAnsCm(algebraicSymbol)}
        testCorrect={[baseOrHeightHidden === 'base' ? base.toString() : height.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfAnyTriangle',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
