import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, range } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { integerToWord } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'baq',
  description: 'baq',
  keywords: ['Number track', 'Count'],
  schema: z.object({
    startingNumber: z.number().min(0).max(4),
    answerIndexes: z.array(z.number().min(2).max(7)).min(1).refine(arrayHasNoDuplicates),
    numCells: z.number().min(6).max(8)
  }),
  simpleGenerator: () => {
    const numCells = randomIntegerInclusive(6, 8);
    const startingNumber = randomIntegerInclusive(0, 10 - numCells);
    const numberOfAns = numCells - 3;
    const answerIndexes = randomUniqueIntegersInclusive(2, numCells - 1, numberOfAns);
    return { startingNumber, answerIndexes, numCells };
  },
  Component: ({ question: { startingNumber, answerIndexes, numCells }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = countRange(numCells).map(i => startingNumber + i);

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bar',
  description: 'bar',
  keywords: ['Words', 'Numeral'],
  schema: z.object({
    startingNumber: z.number().min(1).max(6),
    wordIndexes: z.array(z.number().min(0).max(4)),
    itemOrder: z.number().min(1).max(4).array().length(4).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 6);
    const numWords = randomIntegerInclusive(0, 2);
    const wordIndexes = randomUniqueIntegersInclusive(0, 4, numWords);
    const itemOrder = shuffle(range(1, 4));
    return { startingNumber, wordIndexes, itemOrder };
  },
  Component: ({ question: { startingNumber, wordIndexes, itemOrder }, translate, displayMode }) => {
    const numberArray = range(startingNumber, startingNumber + 4);
    const stringArray = numberArray.map((val, id) =>
      wordIndexes.includes(id) ? integerToWord(val) : val.toLocaleString()
    );
    const sentence = stringArray
      .map((string, index) => (index === 0 ? string : '<ans/>'))
      .join('   ');
    const correctAnswer = numberArray.slice(1);
    const items = itemOrder.map(index => ({
      value: numberArray[index], // use number in user answer's data
      component: <TextStructure sentence={stringArray[index]} /> // use string for showing to user
    }));
    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToPutThemInOrder()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToPutThemInOrder()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={items}
        moveOrCopy="move"
        itemVariant="shortRectangle"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="shortRectangle"
        testCorrect={correctAnswer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountOnFromAnyNumber',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
