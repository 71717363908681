import { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import NoKeyboardTextInput from 'common/src/components/atoms/NoKeyboardTextInput';
import { SetState } from 'common/src/utils/react';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { withStateHOC } from '../../../stateTree';
import { filledArray } from '../../../utils/collections';
import { DisplayMode } from '../../../contexts/displayMode';
import { horizontalDoubleEndedArrow } from './LineSvgs';
import { all, create, number } from 'mathjs';
import { Rect, Svg } from 'react-native-svg';
import { parseMarkup } from '../../../markup';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

type Props = {
  /**
   * The number representing the total width of the line
   * Used to determine the width proportions of each line.
   */
  total: number;
  /**
   * The labels to show on the lines. They should be given in the form [topRow, bottomRow]
   * where the bottom row is the total
   *
   * These strings should be written using the markup language used by {@link TextStructure}.
   */
  labels: string[][];
  /**
   * number array to represent how the line should be split up
   */
  partition: number[];
  /**
   * Colors to assign to each partition.
   */
  rowColors: string[][];
  /**
   * Usable dimensions for the question
   */
  dimens: Dimens;
  /**
   * State to hold the users input answers
   */
  userAnswer?: string[];
  setUserAnswer?: SetState<string[]>;
  inputMaxCharacters?: number;
};

/**
 * This component renders a bar model, with multiple rows.
 * The bars go horizontally across the screen.
 */
export const LinesWithAnswers = ({
  total,
  partition,
  labels,
  rowColors,
  dimens,
  userAnswer = [],
  setUserAnswer = () => {
    /* do nothing */
  },
  inputMaxCharacters
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const totalWidth = dimens.width * 0.9;
  const lineHeight = displayMode === 'digital' ? 15 : 30;
  const answerBoxHeight = displayMode === 'digital' ? 96 : 150;

  const styles = getStyles();

  const lineValues = [
    partition.map((val, i) => ({ number: val, label: labels[0][i] })),
    [{ number: total, label: labels[1][0] }]
  ];

  const line = (color: string, lineLength: number) => (
    <Svg
      viewBox={`0 0 ${lineLength} ${lineHeight}`}
      fill="none"
      width={lineLength}
      height={lineHeight}
    >
      <Rect width={lineLength} height={lineHeight} rx="2" fill={color} />
    </Svg>
  );

  let ansIndex = -1;

  const displayLabels = (label: string, lineLength: number, row: 'top' | 'bottom') => {
    const ansCount = parseMarkup(label).numberOfAns;
    ansIndex += ansCount;
    // Create scoped copy, otherwise all answer indices will equal final index
    const i = ansIndex;

    return (
      <View
        style={{
          width: lineLength,
          alignItems: 'center',
          height: answerBoxHeight,
          justifyContent: row === 'top' ? 'flex-end' : 'flex-start'
        }}
      >
        <TextStructure
          textVariant="WRN400"
          sentence={label}
          inputBox={({ index }: { index: number }) => (
            <NoKeyboardTextInput
              value={userAnswer[i - ansCount + (index + 1)]}
              onChangeText={text => {
                const newState = [...userAnswer];
                newState[i - ansCount + (index + 1)] = text;
                setUserAnswer(newState);
              }}
              maxCharacters={inputMaxCharacters}
            />
          )}
        />
      </View>
    );
  };

  return (
    <View style={{ width: dimens.width, gap: 40 }}>
      {/* top row */}
      <View style={styles.row} key="topRow">
        {lineValues[0].map((val, paritionIndex) => {
          const lineLength = number(math.evaluate(`(${val.number} / ${total}) * ${totalWidth}`));
          return (
            <View style={styles.innerRow} key={`topRow${paritionIndex}`}>
              {displayLabels(val.label, lineLength, 'top')}
              {horizontalDoubleEndedArrow(lineLength, displayMode !== 'digital' ? 8 : undefined)}
              {line(rowColors[0][paritionIndex], lineLength)}
            </View>
          );
        })}
      </View>
      {/* bottom row */}
      <View style={styles.row} key="bottomRow">
        {lineValues[1].map((val, paritionIndex) => {
          const lineLength = number(math.evaluate(`(${val.number} / ${total}) * ${totalWidth}`));
          return (
            <View style={styles.innerRow} key={`bottomRow${paritionIndex}`}>
              {line(rowColors[1][paritionIndex], lineLength)}
              {horizontalDoubleEndedArrow(lineLength, displayMode !== 'digital' ? 8 : undefined)}
              {displayLabels(val.label, lineLength, 'bottom')}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export const LinesWithAnswersWithState = withStateHOC(LinesWithAnswers, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(
      '',
      props.labels
        .map(row => row.map(val => parseMarkup(val).numberOfAns > 0))
        .flat()
        .filter(val => val).length
    )
  })
});

const getStyles = () =>
  StyleSheet.create({
    row: { flexDirection: 'row', justifyContent: 'center' },
    innerRow: { flexDirection: 'column', gap: 5 }
  });
