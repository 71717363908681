import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomIntegerInclusive
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import { MULT } from 'common/src/constants';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import { numberEnum } from 'common/src/utils/zod';
import { multiplesNumberTrackArray } from 'common/src/utils/multiples';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { akl } from 'common/src/SchemeOfLearning/Year 3/Autumn/MultiplicationAndDivisionA/8The3TimesTable';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ak5',
  description: 'ak5',
  keywords: ['Multiple', '3', 'Track'],
  schema: z.object({
    interval: numberEnum([-3, 3]),
    startingNumber: z.number().int().min(3).max(36).multipleOf(3),
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-3, 3] as const);
    const startingNumber =
      interval === 3 ? randomIntegerInclusiveStep(3, 9, 3) : randomIntegerInclusiveStep(30, 36, 3);
    const tileToShow = randomIntegerInclusive(2, 9);
    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      8,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = { ...akl, uid: 'ak6', description: 'ak6' as const };

const Question3 = newQuestionContent({
  uid: 'ak7',
  description: 'ak7',
  keywords: ['Multiple', 'Multiply', '3'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = `${numberA1} ${MULT} 3 = <ans/>`;

    const eqB = `3 ${MULT} ${numberB1} = <ans/>`;

    const eqC = `<ans/> = ${numberC1} ${MULT} 3`;

    const eqD = `<ans/> = 3 ${MULT} ${numberD1}`;

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [(numberA1 * 3).toString()],
          [(numberB1 * 3).toString()],
          [(numberC1 * 3).toString()],
          [(numberD1 * 3).toString()]
        ]}
        sentences={eqs}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ak8',
  description: 'ak8',
  keywords: ['Multiple', 'Divisibility', '3'],
  schema: z
    .object({
      numberArray: z.number().int().min(1).max(9999).array().length(8)
    })
    .refine(val => {
      const multsOf3 = val.numberArray.filter(number => number % 3 === 0);
      return multsOf3.length >= 3 && multsOf3.length < 8;
    }, 'numberArray must contain at least three numbers that are multiples of 3, and one that is not a multiple of 3')
    .refine(val => {
      const lessThan100 = val.numberArray.filter(number => number < 100);
      const lessThan1000MoreThan99 = val.numberArray.filter(
        number => number >= 100 && number < 1000
      );
      const moreThan999 = val.numberArray.filter(number => number >= 1000);
      return (
        lessThan100.length === 3 && lessThan1000MoreThan99.length === 3 && moreThan999.length === 2
      );
    }, 'numberArray must contain: three nums from 1-99, three nums from 100-999 and two nums from 1,000-9,999')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    // Decide where the enforced multiples of 3 should go:
    const [enforcedMult1, enforcedMult2, enforcedMult3] = randomUniqueIntegersInclusive(1, 8, 3);

    // Decide where the enforced non-multiple of 3 should go:
    const enforcedNonMult = randomIntegerInclusive(1, 8, {
      constraint: x => x !== enforcedMult1 && x !== enforcedMult2 && x !== enforcedMult3
    });

    const numberGenerator = (min: number, max: number, numToCheck: number, otherNums: number[]) => {
      return randomIntegerInclusive(min, max, {
        constraint: x => {
          // Ensure numbers that are enforced to be a multiple of 3 become so, and are unique.
          if (
            enforcedMult1 === numToCheck ||
            enforcedMult2 === numToCheck ||
            enforcedMult3 === numToCheck
          ) {
            return x % 3 === 0 && arrayHasNoDuplicates([x, ...otherNums]);
            // Ensure numbers that are enforced to not be a multiple of 3 become so, and are unique.
          } else if (enforcedNonMult === numToCheck) {
            return x % 3 !== 0 && arrayHasNoDuplicates([x, ...otherNums]);
          } else {
            // Ensure non-enforced numbers are unique from all other numbers.
            return arrayHasNoDuplicates([x, ...otherNums]);
          }
        }
      });
    };

    const number1 = numberGenerator(1, 99, 1, []);
    const number2 = numberGenerator(1, 99, 2, [number1]);
    const number3 = numberGenerator(1, 99, 3, [number1, number2]);
    const number4 = numberGenerator(100, 999, 4, [number1, number2, number3]);
    const number5 = numberGenerator(100, 999, 5, [number1, number2, number3, number4]);
    const number6 = numberGenerator(100, 999, 6, [number1, number2, number3, number4, number5]);
    const number7 = numberGenerator(1000, 9999, 7, [
      number1,
      number2,
      number3,
      number4,
      number5,
      number6
    ]);
    const number8 = numberGenerator(1000, 9999, 8, [
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7
    ]);

    const numberArray = [number1, number2, number3, number4, number5, number6, number7, number8];

    return { numberArray };
  },
  Component: props => {
    const {
      question: { numberArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(3)}
        pdfTitle={translate.instructions.circleTheMultiplesOfX(3)}
        testCorrect={numberArray.filter(it => it % 3 === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'ak9',
  description: 'ak9',
  keywords: ['3', 'Multiple', 'Divisibility'],
  schema: z.object({
    digits: z.array(z.number().int().min(0).max(9)).length(5)
  }),
  simpleGenerator: () => {
    const threes = getRandomSubArrayFromArray([3, 6, 9], 2);
    const others = getRandomSubArrayFromArray([1, 2, 4, 5, 7, 8], 3);

    const digits = [...threes, ...others];

    return { digits };
  },
  Component: props => {
    const {
      question: { digits },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      // Mark as wrong if first digit of created number is 0.
      if (ans[0] === '0') return false;

      const userNumber = parseInt(ans.join(''));
      return userNumber % 3 === 0;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCreateXDigitMultOfY(3, 3)}
        pdfTitle={translate.instructions.useCardsToCreateXDigitMultOfY(3, 3)}
        sentence={'<ans/> '.repeat(3)}
        items={digits.map(num => num.toLocaleString())}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyMultipleOfXUsingAvailCards(3)
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ala',
  description: 'ala',
  keywords: ['Multiple', '3', 'Given one find other'],
  schema: z.object({
    numberA1: z.number().int().min(10).max(100).step(10),
    numberB1Relative: z.number().int().min(9).max(101)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusiveStep(10, 100, 10);

    const numberB1Relative = getRandomFromArray([numberA1 + 1, numberA1 - 1]);

    return { numberA1, numberB1Relative };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1Relative },
      translate
    } = props;

    const numberC1 = numberA1 + 5;

    const eqB = getBinOpEquation({
      left: numberB1Relative,
      right: 3,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({ left: numberC1, right: 3, sign: 'multiply', answer: 'result' });

    const eqs = [eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatToWorkOut(numberA1, 3, numberA1 * 3)}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplesOf3',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
