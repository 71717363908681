import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { DIV } from '../../../../constants';
import { countRange, range } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import RowOfImages from '../../../../components/molecules/RowOfImages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGi',
  description: 'aGi',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    numberOfCompleteSVG: z.number().int().min(2).max(5),
    remainder: z.number().int().min(1).max(4),
    svgToShow: z.enum(['square', 'pentagon'])
  }),
  simpleGenerator: () => {
    const svgToShow = getRandomFromArray(['square', 'pentagon'] as const);
    const numberOfCompleteSVG = randomIntegerInclusive(2, 5);
    const remainder = randomIntegerInclusive(1, svgToShow === 'square' ? 3 : 4);

    return { numberOfCompleteSVG, remainder, svgToShow };
  },
  Component: props => {
    const {
      question: { numberOfCompleteSVG, remainder, svgToShow },
      translate
    } = props;

    const stickValueCompleteSVG = svgToShow === 'square' ? 4 : 5;
    const totalSticks = numberOfCompleteSVG * stickValueCompleteSVG + remainder;

    const sentences = [
      {
        sentence: `${translate.answerSentences.thereAreAnsXAltogether(
          translate.objects.LollySticks()
        )}`,
        answer: [totalSticks.toString()]
      },
      {
        sentence: `${translate.answerSentences.thereAreAnsGroupsOf(
          stickValueCompleteSVG.toLocaleString()
        )}`,
        answer: [numberOfCompleteSVG.toString()]
      },
      {
        sentence: `${translate.answerSentences.thereAreAnsXRemaining(
          translate.objects.LollySticks()
        )}`,
        answer: [remainder.toString()]
      }
    ];

    const wholeAssetName =
      svgToShow === 'square'
        ? 'Lolly_sticks/FourLollyStickSquare'
        : 'Lolly_sticks/FiveLollyStickPentagon';

    const getRemainderSvgName = () => {
      switch (svgToShow) {
        case 'square':
          switch (remainder) {
            case 1:
              return 'Lolly_sticks/SingleLollyStickSquare';
            case 2:
              return 'Lolly_sticks/TwoLollyStickSquare';
            case 3:
              return 'Lolly_sticks/ThreeLollyStickSquare';
          }
        case 'pentagon':
          switch (remainder) {
            case 1:
              return 'Lolly_sticks/SingleLollyStickPentagon';
            case 2:
              return 'Lolly_sticks/TwoLollyStickPentagon';
            case 3:
              return 'Lolly_sticks/ThreeLollyStickPentagon';
            case 4:
              return 'Lolly_sticks/FourLollyStickPentagon';
          }
        default:
          return 'Lolly_sticks/SingleLollyStickSquare';
      }
    };

    const images: SvgName[] = [];

    countRange(numberOfCompleteSVG).forEach(() => {
      images.push(wholeAssetName);
    });

    images.push(getRemainderSvgName());

    return (
      <QF1ContentAndSentences
        sentences={sentences.map(sentence => sentence.sentence)}
        title={translate.instructions.completeSentences()}
        testCorrect={sentences.map(sentence => sentence.answer)}
        Content={({ dimens }) => (
          <View style={{ ...dimens }}>
            <RowOfImages style={{ justifyContent: 'flex-start', gap: 16 }} images={images} />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGj',
  description: 'aGj',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    numberOfCompleteBoxes: z.number().int().min(2).max(5),
    remainder: z.number().int().min(1).max(9),
    object: z.enum(['Crayons', 'Red pens', 'Blue pens'])
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Crayons', 'Red pens', 'Blue pens'] as const);

    const numberOfCompleteBoxes = randomIntegerInclusive(2, 5);

    const remainder = randomIntegerInclusive(1, object === 'Crayons' ? 7 : 9);

    return { numberOfCompleteBoxes, remainder, object };
  },
  Component: props => {
    const {
      question: { numberOfCompleteBoxes, remainder, object },
      translate
    } = props;

    const boxValue = object === 'Crayons' ? 8 : 10;

    const total = numberOfCompleteBoxes * boxValue + remainder;

    const sentences = [
      {
        sentence: translate.answerSentences.thereAreAnsXAltogether(translate.objects[object]()),
        answer: [total.toString()]
      },
      {
        sentence: translate.answerSentences.thereAreAnsGroupsOfXObject(
          boxValue.toLocaleString(),
          translate.objects[object]()
        ),
        answer: [numberOfCompleteBoxes.toString()]
      },
      {
        sentence: translate.answerSentences.thereAreAnsXRemaining(translate.objects[object]()),
        answer: [remainder.toString()]
      }
    ];

    const boxSvgName =
      object === 'Crayons'
        ? 'Crayons_box_pack_of_8'
        : object === 'Blue pens'
        ? 'Base_Ten/10BluePens'
        : 'Base_Ten/10RedPens';

    // remainderSvgName not needed if object is 'Crayons' - alternative shuffling of crayon SVGs below will be used in that case.
    const remainderSvgName = object === 'Blue pens' ? 'Base_Ten/1BluePen' : 'Base_Ten/1RedPen';

    const shuffledCrayonSvgs: SvgName[] = shuffle(
      [
        'Base_Ten/Crayons1_blue',
        'Base_Ten/Crayons1_green',
        'Base_Ten/Crayons1_lightblue',
        'Base_Ten/Crayons1_lime',
        'Base_Ten/Crayons1_orange',
        'Base_Ten/Crayons1_pink',
        'Base_Ten/Crayons1_purple',
        'Base_Ten/Crayons1_red',
        'Base_Ten/Crayons1_white',
        'Base_Ten/Crayons1_yellow'
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF1ContentAndSentences
        sentences={sentences.map(sentence => sentence.sentence)}
        title={translate.instructions.completeSentences()}
        testCorrect={sentences.map(sentence => sentence.answer)}
        pdfSentenceStyle={{ alignItems: 'flex-start' }}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }
            ]}
          >
            {range(1, numberOfCompleteBoxes).map(num => (
              <AssetSvg
                key={num}
                name={boxSvgName}
                height={dimens.height * 0.9}
                width={dimens.width / (numberOfCompleteBoxes + remainder + 2)}
              />
            ))}
            {range(1, remainder).map(num => (
              <AssetSvg
                key={num}
                name={object === 'Crayons' ? shuffledCrayonSvgs[num] : remainderSvgName}
                height={object === 'Crayons' ? dimens.height / 4 : dimens.height}
                width={dimens.width / (numberOfCompleteBoxes + remainder + 2)}
              />
            ))}
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aGk',
  description: 'aGk',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    numberPairs: z
      .object({
        left: z.number().int().min(1).max(99),
        right: z.number().int().min(1).max(99)
      })
      .array()
      .length(9)
  }),
  simpleGenerator: () => {
    const numberPairs = [];

    const var1a = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2a = randomIntegerInclusive(1, 12, {
      constraint: x => x * var1a < 100
    });
    const var3a = var1a * var2a;
    const var4a = randomIntegerInclusive(1, 8, { constraint: x => x < var1a && x + var3a < 100 });
    const var5a = var3a + var4a;

    const var1b = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2b = randomIntegerInclusive(1, 12, {
      constraint: x => x !== var2a && x * var1b < 100
    });
    const var3b = var1b * var2b;
    const var4b = randomIntegerInclusive(1, 8, { constraint: x => x < var1b && x + var3b < 100 });
    const var5b = var3b + var4b;

    const var1c = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2c = randomIntegerInclusive(1, 12, {
      constraint: x => x !== var2a && x !== var2b && x * var1c < 100
    });
    const var3c = var1c * var2c;
    const var4c = randomIntegerInclusive(1, 8, { constraint: x => x < var1c && x + var3c < 100 });
    const var5c = var3c + var4c;

    numberPairs.push(
      { left: var3a, right: var1a },
      { left: var3b, right: var1b },
      { left: var3c, right: var1c },
      { left: var5a, right: var1a },
      { left: var5b, right: var1b },
      { left: var5c, right: var1c }
    );

    // Randomise the distribution of randoms
    const [randomVar3, randomVar5] = shuffle([1, 2]);

    countRange(randomVar3).forEach(_ => {
      const var1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
      const var2 = randomIntegerInclusive(1, 12, {
        constraint: x => var1 * x <= 99 && ![var2a, var2b, var2c].includes(x)
      });
      const var3 = var1 * var2;
      numberPairs.push({ left: var3, right: var1 });
    });

    countRange(randomVar5).forEach(_ => {
      const var1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
      const var2 = randomIntegerInclusive(1, 12, {
        constraint: x => x * var1 <= 99 && ![var2a, var2b, var2c].includes(x)
      });
      const var3 = var1 * var2;
      const var4 = randomIntegerInclusive(1, var1 - 1, { constraint: x => x + var3 < 100 });
      const var5 = var3 + var4;

      numberPairs.push({ left: var5, right: var1 });
    });

    return {
      numberPairs: shuffle(numberPairs)
    };
  },

  Component: props => {
    const {
      question: { numberPairs },
      translate
    } = props;

    const statements = numberPairs.map(({ left, right }, index) => ({
      value: index,
      division: left / right,
      text: `${left.toLocaleString()} ${DIV} ${right.toLocaleString()}`
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichCalcsHaveRemainder()}
        testCorrect={statements
          .filter(statement => !Number.isInteger(statement.division))
          .map(statement => statement.value)}
        multiSelect
        items={statements.map(statement => ({
          value: statement.value,
          component: statement.text
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aGl',
  description: 'aGl',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    numberPairs: z
      .object({
        left: z.number().int().min(1).max(99),
        right: z.number().int().min(1).max(99)
      })
      .array()
      .length(4)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    let numberPairs = [];

    const var1a = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2a = randomIntegerInclusive(1, 12, {
      constraint: x => x * var1a < 100
    });
    const var3a = var1a * var2a;
    const var4a = randomIntegerInclusive(1, 8, { constraint: x => x < var1a && x + var3a < 100 });
    const var5a = var3a + var4a;

    const var1b = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2b = randomIntegerInclusive(1, 12, {
      constraint: x => x !== var2a && x * var1b < 100
    });
    const var3b = var1b * var2b;
    const var4b = randomIntegerInclusive(1, 8, { constraint: x => x < var1b && x + var3b < 100 });
    const var5b = var3b + var4b;

    const var1c = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2c = randomIntegerInclusive(1, 12, {
      constraint: x => x !== var2a && x !== var2b && x * var1c < 100
    });
    const var3c = var1c * var2c;
    const var4c = randomIntegerInclusive(1, 8, { constraint: x => x < var1c && x + var3c < 100 });
    const var5c = var3c + var4c;

    numberPairs.push(
      { left: var3a, right: var1a },
      { left: var3b, right: var1b },
      { left: var3c, right: var1c },
      { left: var5a, right: var1a },
      { left: var5b, right: var1b },
      { left: var5c, right: var1c }
    );

    const getRandomVar3 = () => {
      const var1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
      const var2 = randomIntegerInclusive(1, 12, {
        constraint: x => ![var2a, var2b, var2c].includes(x) && x * var1 < 100
      });
      const var3 = var1 * var2;
      return { left: var3, right: var1 };
    };

    const getRandomVar5 = () => {
      const var1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
      const var2 = randomIntegerInclusive(1, 12, {
        constraint: x => ![var2a, var2b, var2c].includes(x) && x * var1 < 100
      });
      const var3 = var1 * var2;
      const var4 = randomIntegerInclusive(1, var1 - 1, { constraint: x => x + var3 < 100 });
      const var5 = var3 + var4;

      return { left: var5, right: var1 };
    };

    numberPairs.push(getRandomFromArray([getRandomVar3(), getRandomVar5()]));

    numberPairs = getRandomSubArrayFromArray(numberPairs, 4);

    return {
      numberPairs
    };
  },
  Component: ({ question: { numberPairs }, translate }) => {
    const numberPairsObjects = numberPairs.map(({ left, right }, index) => ({
      value: index,
      division: left % right,
      text: `${left.toLocaleString()} ${DIV} ${right.toLocaleString()}`
    }));

    const withRemainder: string[] = [];
    const withoutRemainder: string[] = [];

    numberPairsObjects.forEach(number => {
      if (number.division === 0) {
        withoutRemainder.push(number.text);
      } else {
        withRemainder.push(number.text);
      }
    });

    const correctOrder = [withRemainder, withoutRemainder];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        zoneNames={[
          translate.tableHeaders.DivisionWithRemainders(),
          translate.tableHeaders.DivisionWithoutRemainders()
        ]}
        items={numberPairsObjects.map(item => item.text)}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={correctOrder}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGm',
  description: 'aGm',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    var1a: numberEnum([2, 3, 4, 5, 8, 10]),
    var2a: z.number().int().min(1).max(12),
    var4a: z.number().int().min(1).max(9),
    var1b: numberEnum([2, 3, 4, 5, 8, 10]),
    var2b: z.number().int().min(1).max(12),
    var4b: z.number().int().min(1).max(9),
    var1c: numberEnum([2, 3, 4, 5, 8, 10]),
    var2c: z.number().int().min(1).max(12),
    var4c: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const var1a = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2a = randomIntegerInclusive(1, 12, { constraint: x => x * var1a <= 50 });
    const var4a = randomIntegerInclusive(1, var1a - 1);

    const var1b = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2b = randomIntegerInclusive(1, 12, { constraint: x => x * var1b <= 50 });
    const var4b = randomIntegerInclusive(1, var1b - 1);

    const var1c = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2c = randomIntegerInclusive(1, 12, { constraint: x => x * var1c <= 50 });
    const var4c = randomIntegerInclusive(1, var1c - 1);

    return { var1a, var2a, var4a, var1b, var2b, var4b, var1c, var2c, var4c };
  },

  Component: props => {
    const {
      question: { var1a, var2a, var4a, var1b, var2b, var4b, var1c, var2c, var4c },
      translate
    } = props;

    const var3a = var1a * var2a;
    const var5a = var3a + var4a;

    const var3b = var1b * var2b;
    const var5b = var3b + var4b;

    const var3c = var1c * var2c;
    const var5c = var3c + var4c;

    const sentences = [
      {
        sentence: `${var5a.toLocaleString()} ${DIV} ${var1a.toLocaleString()} = <ans/> ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} <ans/>`,
        answer: [var2a.toString(), var4a.toString()]
      },
      {
        sentence: `${var5b.toLocaleString()} ${DIV} ${var1b.toLocaleString()} = <ans/> ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} <ans/>`,
        answer: [var2b.toString(), var4b.toString()]
      },
      {
        sentence: `${var5c.toLocaleString()} ${DIV} ${var1c.toLocaleString()} = <ans/> ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} <ans/>`,
        answer: [var2c.toString(), var4c.toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={sentences.map(sentence => sentence.answer)}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGn',
  description: 'aGn',
  keywords: ['Remainder', 'Division', '2-digit'],
  schema: z.object({
    var1a: numberEnum([2, 3, 4, 5, 8, 10]),
    var2a: z.number().int().min(1).max(12),
    var4a: z.number().int().min(1).max(9),
    var1b: numberEnum([2, 3, 4, 5, 8, 10]),
    var2b: z.number().int().min(1).max(12),
    var4b: z.number().int().min(1).max(9),
    var1c: numberEnum([2, 3, 4, 5, 8, 10]),
    var2c: z.number().int().min(1).max(12),
    var4c: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const var1a = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2a = randomIntegerInclusive(1, 12, { constraint: x => x * var1a <= 50 });
    const var4a = randomIntegerInclusive(1, var1a - 1);

    const var1b = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2b = randomIntegerInclusive(1, 12, { constraint: x => x * var1b <= 50 });
    const var4b = randomIntegerInclusive(1, var1b - 1);

    const var1c = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const var2c = randomIntegerInclusive(1, 12, { constraint: x => x * var1c <= 50 });
    const var4c = randomIntegerInclusive(1, var1c - 1);

    return { var1a, var2a, var4a, var1b, var2b, var4b, var1c, var2c, var4c };
  },

  Component: props => {
    const {
      question: { var1a, var2a, var4a, var1b, var2b, var4b, var1c, var2c, var4c },
      translate
    } = props;

    const var3a = var1a * var2a;
    const var5a = var3a + var4a;

    const var3b = var1b * var2b;
    const var5b = var3b + var4b;

    const var3c = var1c * var2c;
    const var5c = var3c + var4c;

    const sentences = [
      {
        sentence: `${var5a.toLocaleString()} ${DIV} <ans/> = ${var2a.toLocaleString()} ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} ${var4a.toLocaleString()}`,
        answer: [var1a.toString()]
      },
      {
        sentence: `${var5b.toLocaleString()} ${DIV} <ans/> = ${var2b.toLocaleString()} ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} ${var4b.toLocaleString()}`,
        answer: [var1b.toString()]
      },
      {
        sentence: `${var5c.toLocaleString()} ${DIV} <ans/> = ${var2c.toLocaleString()} ${translate.keywords
          .Remainder()
          .toLocaleLowerCase()} ${var4c.toLocaleString()}`,
        answer: [var1c.toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={sentences.map(sentence => sentence.answer)}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberByA1DigitNumberWithRemainders',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
