import { AssetSvg } from 'common/src/assets/svg';

import { getRandomFromArray } from '../../../../utils/random';

export const ONES = 0;
export const TENS = 1;
export const HUNDREDS = 2;
export const THOUSANDS = 3;
export const TENTHOUSANDS = 4;
export const HUNDREDTHOUSANDS = 5;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const order = [ONES, TENS, HUNDREDS, THOUSANDS];

type CrayonColors =
  | 'blue'
  | 'red'
  | 'lime'
  | 'green'
  | 'lightblue'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'white'
  | 'yellow';

const crayonColors = [
  'blue',
  'red',
  'lime',
  'green',
  'lightblue',
  'orange',
  'pink',
  'purple',
  'white',
  'yellow'
];

const crayonsOne = {
  blue: <AssetSvg name="Base_Ten/Crayons1_blue" />,
  red: <AssetSvg name="Base_Ten/Crayons1_red" />,
  lime: <AssetSvg name="Base_Ten/Crayons1_lime" />,
  green: <AssetSvg name="Base_Ten/Crayons1_green" />,
  lightblue: <AssetSvg name="Base_Ten/Crayons1_lightblue" />,
  orange: <AssetSvg name="Base_Ten/Crayons1_orange" />,
  pink: <AssetSvg name="Base_Ten/Crayons1_pink" />,
  purple: <AssetSvg name="Base_Ten/Crayons1_purple" />,
  white: <AssetSvg name="Base_Ten/Crayons1_white" />,
  yellow: <AssetSvg name="Base_Ten/Crayons1_yellow" />
};

const fetchCrayon = () => {
  const randomCrayonColor = getRandomFromArray(crayonColors) as CrayonColors;
  return crayonsOne[randomCrayonColor];
};

export const BaseTenImages = {
  Balloons: {
    ONES: {
      width: 65,
      height: 35,
      component: <AssetSvg name="Base_Ten/Balloons1" />
    },
    TENS: {
      width: 67,
      height: 110,
      component: <AssetSvg name="Base_Ten/Balloons10" />
    },
    HUNDREDS: {
      width: 116,
      height: 194,
      component: <AssetSvg name="Base_Ten/Balloons100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  'Blue pens': {
    ONES: {
      width: 36,
      height: 136,
      component: <AssetSvg name="Base_Ten/1BluePen" />
    },
    TENS: {
      width: 94,
      height: 222,
      component: <AssetSvg name="Base_Ten/10BluePens" />
    },
    HUNDREDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Bricks: {
    ONES: {
      width: 32,
      height: 22,
      component: <AssetSvg name="Base_Ten/Bricks1" />
    },
    TENS: {
      width: 76,
      height: 50,
      component: <AssetSvg name="Base_Ten/Bricks10" />
    },
    HUNDREDS: {
      width: 121,
      height: 81,
      component: <AssetSvg name="Base_Ten/Bricks100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Crayons: {
    ONES: {
      width: 100,
      height: 100,
      component: fetchCrayon()
    },
    TENS: {
      width: 120,
      height: 208,
      component: <AssetSvg name="Base_Ten/Crayons10" />
    },
    HUNDREDS: {
      width: 150,
      height: 100,
      component: <AssetSvg name="Base_Ten/Crayons100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Counters: {
    ONES: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/1" />
    },
    TENS: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/10" />
    },
    HUNDREDS: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/100" />
    },
    THOUSANDS: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/1000" />
    },
    TENTHOUSANDS: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/10000" />
    },
    HUNDREDTHOUSANDS: {
      width: 300,
      height: 300,
      component: <AssetSvg name="Place_value/100000" />
    }
  },

  Cubes: {
    ONES: {
      width: 96,
      height: 90,
      component: <AssetSvg name="Base_Ten/1cube" />
    },
    TENS: {
      width: 135,
      height: 843,
      component: <AssetSvg name="Base_Ten/10cube" />
    },
    HUNDREDS: {
      width: 847,
      height: 843,
      component: <AssetSvg name="Base_Ten/100cube" />
    },
    THOUSANDS: {
      width: 1180,
      height: 1112,
      component: <AssetSvg name="Base_Ten/1000cube" />
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Lollipops: {
    ONES: {
      width: 46,
      height: 113,
      component: <AssetSvg name="Base_Ten/Lollipops1" />
    },
    TENS: {
      width: 120,
      height: 200,
      component: <AssetSvg name="Base_Ten/Lollipops10" />
    },
    HUNDREDS: {
      width: 180,
      height: 328,
      component: <AssetSvg name="Base_Ten/Lollipops100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Marbles: {
    ONES: {
      width: 44,
      height: 44,
      component: <AssetSvg name="Base_Ten/Marbles1" />
    },
    TENS: {
      width: 202,
      height: 85,
      component: <AssetSvg name="Base_Ten/Marbles10" />
    },
    HUNDREDS: {
      width: 502,
      height: 115,
      component: <AssetSvg name="Base_Ten/Marbles100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Nails: {
    ONES: {
      width: 78,
      height: 157,
      component: <AssetSvg name="Base_Ten/Nails1" />
    },
    TENS: {
      width: 206,
      height: 274,
      component: <AssetSvg name="Base_Ten/Nails10" />
    },
    HUNDREDS: {
      width: 406,
      height: 255,
      component: <AssetSvg name="Base_Ten/Nails100" />
    },
    THOUSANDS: {
      width: 652,
      height: 387,
      component: <AssetSvg name="Base_Ten/Nails1000" />
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  'Red pens': {
    ONES: {
      width: 36,
      height: 136,
      component: <AssetSvg name="Base_Ten/1RedPen" />
    },
    TENS: {
      width: 94,
      height: 222,
      component: <AssetSvg name="Base_Ten/10RedPens" />
    },
    HUNDREDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Straws: {
    ONES: {
      width: 121,
      height: 176,
      component: <AssetSvg name="Base_Ten/Straws1" />
    },
    TENS: {
      width: 168,
      height: 208,
      component: <AssetSvg name="Base_Ten/Straws10" />
    },
    HUNDREDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  },

  Sweets: {
    ONES: {
      width: 71,
      height: 49,
      component: <AssetSvg name="Base_Ten/Sweets1" />
    },
    TENS: {
      width: 90,
      height: 118,
      component: <AssetSvg name="Base_Ten/Sweets10" />
    },
    HUNDREDS: {
      width: 132,
      height: 173,
      component: <AssetSvg name="Base_Ten/Sweets100" />
    },
    THOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    TENTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    },
    HUNDREDTHOUSANDS: {
      width: 0,
      height: 0,
      component: <></>
    }
  }
};
