import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { displayDigitalTime } from 'common/src/utils/time';
import Text from 'common/src/components/typography/Text';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { DigitalClock } from 'common/src/components/question/representations/DigitalClock';
import QF3InteractiveContent from 'common/src/components/question/questionFormats/QF3InteractiveContent';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axY',
  description: 'axY',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    times: z.array(
      z.object({
        hours: z
          .number()
          .int()
          .min(0)
          .max(23)
          .refine(x => x !== 12),
        minutes: z.number().int().min(0).max(59)
      })
    ),
    amOrPm: z.enum(['am', 'pm'])
  }),
  simpleGenerator: () => {
    // Get unique hours
    const [hoursA, hoursC, hoursD, hoursE] = randomUniqueIntegersInclusive(0, 23, 5, {
      constraint: x => x !== 12
    });

    // At least one PM hour
    const hoursF = randomIntegerInclusive(13, 23, {
      constraint: x => x !== hoursA && x !== hoursC && x !== hoursD && x !== hoursE
    });

    // Get unique minutes
    const [minutesA, minutesB, minutesC, minutesD, minutesE] = randomUniqueIntegersInclusive(
      0,
      59,
      5
    );

    const times = [
      {
        hours: hoursA,
        minutes: minutesA
      },
      {
        hours: 0,
        minutes: minutesB
      },
      {
        hours: hoursC,
        minutes: minutesC
      },
      {
        hours: hoursD,
        minutes: minutesD
      },
      {
        hours: hoursE,
        minutes: minutesE
      },
      {
        hours: hoursF,
        minutes: 0
      }
    ];

    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    return {
      times,
      amOrPm
    };
  },
  Component: props => {
    const {
      question: { times, amOrPm },
      translate
    } = props;

    const [timeA, timeB, timeC, timeD, timeE, timeF] = times;

    // Options
    const optionA = displayDigitalTime(timeA.hours, timeA.minutes);
    const optionB = displayDigitalTime(timeB.hours, timeB.minutes);
    const optionC = displayDigitalTime(timeC.hours, timeC.minutes);
    const optionD = displayDigitalTime(timeD.hours, timeD.minutes);
    const optionE = displayDigitalTime(timeE.hours, timeE.minutes);
    const optionF = displayDigitalTime(timeF.hours, timeF.minutes);

    // Put 24 hour strings into an options list
    const options = shuffle([optionA, optionB, optionC, optionD, optionE, optionF], {
      random: seededRandom(props.question)
    });

    const allTimes = [
      [timeA.hours, timeA.minutes],
      [timeB.hours, timeB.minutes],
      [timeC.hours, timeC.minutes],
      [timeD.hours, timeD.minutes],
      [timeE.hours, timeE.minutes],
      [timeF.hours, timeF.minutes]
    ];

    // Filter am times
    // Returns a string of am times using displayDigitalTime
    const amTimes = allTimes
      .filter(time => time[0] >= 0 && time[0] < 12)
      .map(time => {
        return displayDigitalTime(time[0], time[1]);
      });

    // Filter pm times
    // Returns a string of pm times using displayDigitalTime
    const pmTimes = allTimes
      .filter(time => time[0] >= 12 && time[0] <= 23)
      .map(time => {
        return displayDigitalTime(time[0], time[1]);
      });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectThe24HourClockTimesThatAreAmOrPm(
          amOrPm === 'am' ? translate.time.am() : translate.time.pm()
        )}
        pdfTitle={translate.instructions.circleThe24HourClockTimesThatAreAmOrPm(
          amOrPm === 'am' ? translate.time.am() : translate.time.pm()
        )}
        testCorrect={amOrPm === 'am' ? amTimes : pmTimes}
        multiSelect
        items={options.map(opt => ({
          value: opt,
          component: opt
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'axZ',
  description: 'axZ',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hoursA: z.number().int().min(1).max(11),
    hoursB: z.number().int().min(1).max(11)
  }),
  simpleGenerator: () => {
    const [hoursA, hoursB] = randomUniqueIntegersInclusive(1, 11, 2);

    return { hoursA, hoursB };
  },
  Component: ({ question: { hoursA, hoursB }, translate, displayMode }) => {
    // Remaining hours
    const hoursC = hoursB + 12;
    const hoursD = hoursA + 12;

    // 12 hour times
    const time12HA = displayDigitalTime(hoursA, 0, true, '12');
    const time12HB = displayDigitalTime(hoursB, 0, true, '12');

    // 24 hour selectables
    const time24HA = displayDigitalTime(hoursA, 0);
    const time24HB = displayDigitalTime(hoursB, 0);
    const time24HC = displayDigitalTime(hoursC, 0);
    const time24HD = displayDigitalTime(hoursD, 0);

    const items = [time24HA, time24HB, time24HC, time24HD];

    const statements = [
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
          >
            {time12HA}
          </Text>
        ),
        correctAnswer: time24HA
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
          >
            {displayDigitalTime(hoursB + 12, 0, false, '12')}
          </Text>
        ),
        correctAnswer: time24HC
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
          >
            {displayDigitalTime(hoursA + 12, 0, false, '12')}
          </Text>
        ),
        correctAnswer: time24HD
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
          >
            {time12HB}
          </Text>
        ),
        correctAnswer: time24HB
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchTheTimes()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items}
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'ax0',
  description: 'ax0',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: z.number().int().min(5).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const minutes = randomIntegerInclusiveStep(5, 55, 5);

    return { hours, minutes };
  },

  Component: props => {
    const {
      question: { hours, minutes },
      translate,
      displayMode
    } = props;

    // Display
    const time12H = displayDigitalTime(hours, minutes, false, '12');

    // Answers
    const [ansHours, ansMinutes] = displayDigitalTime(hours, minutes, false, '24').split(':');

    return (
      <QF3InteractiveContent
        title={translate.instructions.writeTheTimeAsA24HourClockTime()}
        initialState={
          displayMode === 'markscheme'
            ? [
                ansHours.padStart(2, '0').toLocaleString(),
                ansMinutes.toLocaleString().padStart(2, '0')
              ]
            : ['', '']
        }
        inputType="numpad"
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={userAnswer =>
          userAnswer[0] === ansHours.padStart(2, '0') && userAnswer[1] === ansMinutes
        }
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <Text variant="WRN400" style={{ paddingBottom: 32, textAlign: 'center' }}>
              {time12H}
            </Text>
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height * 0.9,
                width: dimens.width
              }}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ax1',
  description: 'ax1',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: z.number().int().min(5).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const minutes = randomIntegerInclusiveStep(5, 55, 5);

    return { hours, minutes };
  },

  Component: props => {
    const {
      question: { hours, minutes },
      translate,
      displayMode
    } = props;

    // Display
    const time12H = displayDigitalTime(hours + 12, minutes, false, '12');

    // Answers
    const [ansHours, ansMinutes] = displayDigitalTime(hours + 12, minutes).split(':');

    return (
      <QF3InteractiveContent
        title={translate.instructions.writeTheTimeAsA24HourClockTime()}
        initialState={
          displayMode === 'markscheme'
            ? [ansHours.toLocaleString(), ansMinutes.toLocaleString().padStart(2, '0')]
            : ['', '']
        }
        inputType="numpad"
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={userAnswer => userAnswer[0] === ansHours && userAnswer[1] === ansMinutes}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <Text variant="WRN400" style={{ paddingBottom: 32, textAlign: 'center' }}>
              {time12H}
            </Text>
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height * 0.9,
                width: dimens.width
              }}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ax2',
  description: 'ax2',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hours: z.number().int().min(1).max(5),
    minutes: z
      .number()
      .int()
      .min(5)
      .max(25)
      .multipleOf(5)
      .refine(x => x !== 15),
    pastOrTo: z.enum(['past', 'to']),
    morningOrAfternoon: z.enum(['morning', 'afternoon'])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 5);
    const minutes = randomIntegerInclusiveStep(5, 25, 5, {
      constraint: x => x !== 15
    });
    const pastOrTo = getRandomFromArray(['past', 'to'] as const);
    const morningOrAfternoon = getRandomFromArray(['morning', 'afternoon'] as const);

    return { hours, minutes, pastOrTo, morningOrAfternoon };
  },

  Component: props => {
    const {
      question: { hours, minutes, pastOrTo, morningOrAfternoon },
      translate,
      displayMode
    } = props;

    let answer1 = 0;
    let answer2 = 0;

    // Answers
    if (pastOrTo === 'past' && morningOrAfternoon === 'morning') {
      answer1 = hours;
      answer2 = minutes;
    } else if (pastOrTo === 'to' && morningOrAfternoon === 'morning') {
      answer1 = hours - 1;
      answer2 = 60 - minutes;
    } else if (pastOrTo === 'past' && morningOrAfternoon === 'afternoon') {
      answer1 = hours + 12;
      answer2 = minutes;
    } else if (pastOrTo === 'to' && morningOrAfternoon === 'afternoon') {
      answer1 = hours - 1 + 12;
      answer2 = 60 - minutes;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.writeTheTimeAsA24HourClockTime()}
        initialState={
          displayMode === 'markscheme'
            ? [answer1.toLocaleString(), answer2.toLocaleString().padStart(2, '0')]
            : ['', '']
        }
        inputType="numpad"
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={userAnswer =>
          (userAnswer[0] === answer1.toString() ||
            userAnswer[0] === answer1.toString().padStart(2, '0')) &&
          userAnswer[1] === answer2.toString().padStart(2, '0')
        }
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <Text variant="WRN400" style={{ paddingBottom: 32, textAlign: 'center' }}>
              {translate.answerSentences.xMinutesXPastOrToXHoursInTheXTimeOfDay({
                minutes,
                hours,
                pastOrTo,
                timeOfDay: morningOrAfternoon
              })}
            </Text>
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height * 0.9,
                width: dimens.width
              }}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ax3',
  description: 'ax3',
  keywords: ['Digital', 'Convert', '24-hour clock'],
  schema: z.object({
    hoursA: z.number().int().min(2).max(7),
    minutesA: z
      .number()
      .int()
      .min(1)
      .max(39)
      .refine(x => x % 5 !== 0),
    hoursB: z.number().int().min(1).max(3),
    minutesB: z
      .number()
      .int()
      .min(11)
      .max(19)
      .refine(x => x % 5 !== 0)
  }),
  simpleGenerator: () => {
    const hoursA = randomIntegerInclusive(2, 7);
    const minutesA = randomIntegerInclusive(1, 39, {
      constraint: x => x % 5 !== 0
    });

    const hoursB = randomIntegerInclusive(1, 3);
    const minutesB = randomIntegerInclusive(11, 19, {
      constraint: x => x % 5 !== 0
    });

    return { hoursA, minutesA, hoursB, minutesB };
  },

  Component: props => {
    const {
      question: { hoursA, minutesA, hoursB, minutesB },
      translate,
      displayMode
    } = props;

    const answer1 = hoursA + hoursB + 12; // Add 12 to convert to 24 hour time
    const answer2 = minutesA + minutesB;

    return (
      <QF3InteractiveContent
        title={translate.instructions.theTimeIsXAndYPmWhatTimeInAHoursAndBMinutesIn24HTime(
          hoursA,
          minutesA.toString().padStart(2, '0'),
          hoursB,
          minutesB
        )}
        initialState={
          displayMode === 'markscheme'
            ? [answer1.toLocaleString().padStart(2, '0'), answer2.toLocaleString().padStart(2, '0')]
            : ['', '']
        }
        inputType="numpad"
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={userAnswer =>
          userAnswer[0] === answer1.toString().padStart(2, '0') &&
          userAnswer[1] === answer2.toString().padStart(2, '0')
        }
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <DigitalClock
              hours={'<ans/>'}
              minutes={'<ans/>'}
              dimens={{
                height: dimens.height,
                width: dimens.width
              }}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertToThe24HourClock',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
