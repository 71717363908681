import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationToSentenceString,
  getBinOpEquation,
  binOpEquationsToTestCorrect
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { ahC } from './21InverseOperations';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import {
  PartWholeModel,
  TextPartition
} from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from 'common/src/components/question/questionFormats/QF3InteractiveContent';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ADD, SUB } from 'common/src/constants';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afG',
  description: 'afG',
  keywords: ['Addition', 'Bonds', 'Base 10'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(4),
    power: z.enum(['ones', 'tens', 'hundreds'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);

    const number2 = randomIntegerInclusive(1, 4);

    const power = getRandomFromArray(['ones', 'tens', 'hundreds'] as const);

    return { number1, number2, power };
  },
  Component: props => {
    const {
      question: { number1, number2, power },
      translate,
      displayMode
    } = props;

    const powerString = (number: number) => {
      switch (power) {
        case 'ones':
          return translate.misc.numberOfOnes(number);
        case 'tens':
          return translate.misc.numberOfTens(number);
        case 'hundreds':
          return translate.misc.numberOfHundreds(number);
      }
    };

    return (
      <QF1ContentAndSentence
        sentence={`${powerString(number1)} ${ADD} ${powerString(
          number2
        )} = <ans /> ${translate.powersOfTen[power](number1 + number2)}`}
        title={translate.instructions.completeSentence()}
        testCorrect={[(number1 + number2).toString()]}
        pdfDirection="column"
        Content={({ dimens }) => {
          const width = dimens.width / 2.5;
          const height = dimens.height;
          const scales = [number1, number2].map(
            number =>
              BaseTenRepCalcGridsAndScale(width, height, { [power]: number }, 'Cubes', 1).scale
          );
          // ensure that base one are has max scale 0.4
          if (power === 'ones') scales.push(0.4);
          const scale = Math.min(...scales);

          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <BaseTenRepresentation
                b10Rep={{ variant: 'Cubes', numbers: { [power]: number1 }, arrangement: 'ltr' }}
                usableWidth={displayMode === 'digital' ? dimens.width / 2.25 : dimens.width / 4.5}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
              <Text variant="WRN700">{ADD}</Text>
              <BaseTenRepresentation
                b10Rep={{ variant: 'Cubes', numbers: { [power]: number2 }, arrangement: 'ltr' }}
                usableWidth={displayMode === 'digital' ? dimens.width / 2.25 : dimens.width / 4.5}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
            </View>
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'afH',
  description: 'afH',
  keywords: ['Subtraction', 'Bonds', 'Base 10'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(1).max(8),
    power: z.enum(['ones', 'tens', 'hundreds'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    const number2 = randomIntegerInclusive(1, number1 - 1);

    const power = getRandomFromArray(['ones', 'tens', 'hundreds'] as const);

    return { number1, number2, power };
  },
  Component: props => {
    const {
      question: { number1, number2, power },
      translate
    } = props;

    const powerString = (number: number) => {
      switch (power) {
        case 'ones':
          return translate.misc.numberOfOnes(number);
        case 'tens':
          return translate.misc.numberOfTens(number);
        case 'hundreds':
          return translate.misc.numberOfHundreds(number);
      }
    };

    return (
      <QF1ContentAndSentence
        sentence={`${powerString(number1)} ${SUB} ${powerString(
          number2
        )} = <ans /> ${translate.powersOfTen[power](number1 - number2)}`}
        title={translate.instructions.completeSentence()}
        testCorrect={[(number1 - number2).toString()]}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <BaseTenRepresentation
                b10Rep={{ variant: 'Cubes', numbers: { [power]: number1 }, arrangement: 'ltr' }}
                usableWidth={dimens.width}
                usableHeight={
                  power === 'ones'
                    ? number1 > 4
                      ? dimens.height / 3
                      : dimens.height / 5
                    : dimens.height
                }
                containerStyle={{ alignItems: 'center' }}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afI',
  description: 'afI',
  keywords: ['Addition', 'Subtraction', 'Bonds'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(7),
      number2: z.number().int().min(2).max(7),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(val => val.number1 + val.number2 < 10, 'number1 + number2 must be less than 10'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 7);

    const number2 = rejectionSample(
      () => randomIntegerInclusive(2, 7),
      // Prevents total being less than 10, 100 or 1,000 depending on powerOfTen
      x => x + number1 < 10
    );

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { number1, number2, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const number1tens = number1 * 10;
    const number2tens = number2 * 10;
    const number1hundreds = number1 * 100;
    const number2hundreds = number2 * 100;

    const eqA =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: number2, sign: 'add', answer: 'result' })
        : getBinOpEquation({ right: number2, result: number1, sign: 'subtract', answer: 'result' });

    const eqB =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1tens, right: number2tens, sign: 'add', answer: 'result' })
        : getBinOpEquation({
            right: number2tens,
            result: number1tens,
            sign: 'subtract',
            answer: 'result'
          });

    const eqC =
      addOrSubtract === 'add'
        ? getBinOpEquation({
            left: number1hundreds,
            right: number2hundreds,
            sign: 'add',
            answer: 'result'
          })
        : getBinOpEquation({
            right: number2hundreds,
            result: number1hundreds,
            sign: 'subtract',
            answer: 'result'
          });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question4: typeof ahC = {
  ...ahC,
  uid: 'afJ',
  description: 'afJ',
  keywords: ['Addition', 'Subtraction', 'Bar model', 'Base 10']
};

export const Question5 = newQuestionContent({
  uid: 'afK',
  description: 'afK',
  keywords: ['Addition', 'Subtraction', 'Part-whole', 'Base 10'],
  schema: z
    .object({
      number1: z.number().int(),
      number2: z.number().int(),
      part: z.enum(['top', 'partA', 'partB'])
    })
    .refine(val => val.number1 + val.number2 < 1000, 'number1 + number2 must be < 1000')
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const part = getRandomFromArray(['top', 'partA', 'partB'] as const);
    const randomMultiple = getRandomFromArray([true, false] as const);

    let number1: number;
    let number2: number;

    // Either multiple of 10 < 100 or multiple of 100 < 1000
    if (randomMultiple) {
      number2 = randomIntegerInclusiveStep(10, 80, 10);
      number1 = randomIntegerInclusiveStep(10, 80, 10, {
        constraint: x => x + number2 < 100 && numbersDoNotExchange(x, number2)
      });
    } else {
      number2 = randomIntegerInclusiveStep(100, 800, 100);
      number1 = randomIntegerInclusiveStep(100, 800, 100, {
        constraint: x => x + number2 < 1000 && numbersDoNotExchange(x, number2)
      });
    }

    return { number1, number2, part };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, part } = question;

    const number3 = number1 + number2;

    let partition: TextPartition = [];

    if (part === 'partA') {
      partition = ['$ans', number2];
    } else if (part === 'partB') {
      partition = [number1, '$ans'];
    } else {
      partition = [number1, number2];
    }

    const correctAnswer = (() => {
      switch (part) {
        case 'top':
          return number3;
        case 'partA':
          return number1;
        case 'partB':
          return number2;
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={part === 'top' ? '$ans' : number3}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'afL',
  description: 'afL',
  keywords: ['Addition', 'Subtraction', 'Bonds'],
  schema: z
    .object({
      numberA1: z.number().int().min(1).max(8),
      numberA2: z.number().int().min(1).max(8),
      answerBoxA: z.enum(['left', 'right', 'result']),
      powerOfTenA: z.number().int().min(1).max(2),
      numberB1: z.number().int().min(1).max(8),
      numberB2: z.number().int().min(1).max(8),
      powerOfTenB: z.number().int().min(1).max(2),
      answerBoxB: z.enum(['left', 'right', 'result'])
    })
    .refine(val => val.numberA1 + val.numberA2 < 10, 'numberA1 + numberA2 must be less than 10')
    .refine(val => val.numberB1 + val.numberB2 < 10, 'numberB1 + numberB2 must be less than 10'),
  questionHeight: 500,
  simpleGenerator: () => {
    const powerOfTenA = randomIntegerInclusive(1, 2);

    const numberA1 = randomIntegerInclusive(1, 8);

    const numberA2 = rejectionSample(
      () => randomIntegerInclusive(1, 8),
      // Prevents total being less than 100 or 1,000 depending on powerOfTen
      x => x + numberA1 < 10
    );

    const [answerBoxA, answerBoxB] = getRandomSubArrayFromArray(
      ['left', 'right', 'result'] as const,
      2
    );

    const powerOfTenB = randomIntegerInclusive(1, 2);

    const numberB1 = randomIntegerInclusive(1, 8);

    const numberB2 = rejectionSample(
      () => randomIntegerInclusive(1, 8),
      // Prevents total being less than 100 or 1,000 depending on powerOfTen
      x => x + numberB1 < 10
    );

    return {
      numberA1,
      numberA2,
      powerOfTenA,
      answerBoxA,
      numberB1,
      numberB2,
      powerOfTenB,
      answerBoxB
    };
  },

  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        powerOfTenA,
        answerBoxA,
        numberB1,
        numberB2,
        powerOfTenB,
        answerBoxB
      },
      translate
    } = props;

    const powerA = Math.pow(10, powerOfTenA);
    const numberA1Powered = numberA1 * powerA;
    const numberA2Powered = numberA2 * powerA;
    const powerB = Math.pow(10, powerOfTenB);
    const numberB1Powered = numberB1 * powerB;
    const numberB2Powered = numberB2 * powerB;

    const eqA = getBinOpEquation({
      left: numberA1Powered,
      right: numberA2Powered,
      sign: 'add',
      answer: answerBoxA
    });

    const eqB = getBinOpEquation({
      left: numberB1Powered,
      right: numberB2Powered,
      sign: 'add',
      answer: answerBoxB
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ApplyNumberBondsWithin10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
