import { newBlockContent } from '../../../Block';

import MakeTallyCharts from './1MakeTallyCharts';
import Tables from './2Tables';
import BlockDiagrams from './3BlockDiagrams';
import DrawPictograms1To1 from './4DrawPictograms1To1';
import InterpretPictograms1To1 from './5InterpretPictograms1To1';
import DrawPictograms25And10 from './6DrawPictograms25And10';
import InterpretPictograms25And10 from './7InterpretPictograms25And10';

const Block = newBlockContent({
  block: 'Statistics',

  weeks: [7, 8],
  smallSteps: [
    MakeTallyCharts, // Step 1
    Tables, // Step 2
    BlockDiagrams, // Step 3
    DrawPictograms1To1, // Step 4
    InterpretPictograms1To1, // Step 5
    DrawPictograms25And10, // Step 6
    InterpretPictograms25And10 // Step 7
  ]
});
export default Block;
