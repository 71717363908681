import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContents, countRange, sortNumberArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import { colors } from '../../../../theme/colors';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { getRandomUniqueOneInteriorAngleShape } from '../../../../utils/shapeImages/angles';
import { raTrianglesLong } from '../../../../utils/shapeImages/triangles';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { AngleFromMovableLinesWithState } from '../../../../components/question/representations/AngleFromMovableLines';
import { angleBetweenTwoLines } from '../../../../utils/angles';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { verticalArrow } from '../../../../components/question/representations/LineSvgs';
import { ShapeNames } from '../../../../utils/labelPositions';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aym',
  description: 'aym',
  keywords: ['Angles', 'Compare'],
  schema: z.object({
    angles: z.number().int().min(10).max(170).array().length(4),
    isGreatest: z.boolean()
  }),
  simpleGenerator: () => {
    const angles = randomUniqueIntegersInclusiveStep(10, 170, 10, 4);
    const isGreatest = getRandomBoolean();

    return { angles, isGreatest };
  },
  Component: props => {
    const {
      question: { angles, isGreatest },
      translate,
      displayMode
    } = props;

    const orderAngles = sortNumberArray(angles, 'ascending');
    const answer = isGreatest ? orderAngles[angles.length - 1] : orderAngles[0];

    return (
      <QF11SelectImagesUpTo4
        title={
          isGreatest
            ? translate.instructions.selectGreatestAngle()
            : translate.instructions.selectSmallestAngle()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.circleGreatestAngle()
            : translate.instructions.circleSmallestAngle()
        }
        testCorrect={[answer]}
        numItems={4}
        renderItems={({ dimens }) =>
          angles.map(val => ({
            value: val,
            component: (
              <AngleFromLines
                // to optimise the space in selectable
                degrees={val <= 90 ? [90, val + 90] : [-90, val - 90]}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                strokeWidth={displayMode === 'digital' ? 4 : 6}
                strokeColor={colors.prussianBlue}
              />
            )
          }))
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayn',
  description: 'ayn',
  keywords: ['Angles', 'Compare'],
  schema: z.object({
    isGreatest: z.boolean(),
    triangleName: z.string(),
    rotation: z.number().min(0).max(360),
    flip: z.boolean()
  }),
  simpleGenerator: () => {
    const isGreatest = getRandomBoolean();
    const triangleName = getRandomFromArray(raTrianglesLong);

    const baseRotation = getRandomBoolean();
    const flip = getRandomBoolean();
    const rotation = baseRotation
      ? randomIntegerInclusiveStep(0, 10, 2) + 180
      : randomIntegerInclusiveStep(0, 10, 2);

    return { isGreatest, triangleName, rotation, flip };
  },
  Component: props => {
    const {
      question: { isGreatest, triangleName, rotation, flip },
      translate,
      displayMode
    } = props;

    const items = shuffle(['A', 'B', 'C'] as const, { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isGreatest
            ? translate.instructions.selectGreatestAngleInDiagram()
            : translate.instructions.selectSmallestAngleInDiagram()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.circleGreatestAngleInDiagram()
            : translate.instructions.circleSmallestAngleInDiagram()
        }
        testCorrect={isGreatest ? [items[1]] : [items[2]]}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => (
          <View
            style={{
              transform: [{ rotate: `${rotation}deg` }, { scaleX: flip ? -1 : 1 }],
              height: dimens.height,
              width: dimens.height * 1.91, // ratio of triangle dimens
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text
              style={{
                position: 'absolute',
                top: 55,
                left: 100,
                fontSize: displayMode === 'digital' ? 22 : 32,
                transform: [
                  { rotate: `${flip ? 1 * rotation : -1 * rotation}deg` },
                  { scaleX: flip ? -1 : 1 }
                ],
                fontWeight: '700'
              }}
            >
              {translate.letters[items[0]]()}
            </Text>
            <Text
              style={{
                position: 'absolute',
                bottom: 55,
                left: 100,
                fontSize: displayMode === 'digital' ? 22 : 32,
                transform: [
                  { rotate: `${flip ? 1 * rotation : -1 * rotation}deg` },
                  { scaleX: flip ? -1 : 1 }
                ],
                fontWeight: '700'
              }}
            >
              {translate.letters[items[1]]()}
            </Text>
            <Text
              style={{
                position: 'absolute',
                bottom: 55,
                right: 100,
                fontSize: displayMode === 'digital' ? 22 : 32,
                transform: [
                  { rotate: `${flip ? 1 * rotation : -1 * rotation}deg` },
                  { scaleX: flip ? -1 : 1 }
                ],
                fontWeight: '700'
              }}
            >
              {translate.letters[items[2]]()}
            </Text>
            <AssetSvg name={triangleName as SvgName} height={dimens.height * 0.65} />
          </View>
        )}
        renderItems={items.map(value => ({
          value,
          component: <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{value}</Text>
        }))}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'ayn2',
  description: 'ayn',
  keywords: ['Angles', 'Compare'],
  questionHeight: 900,
  schema: z.object({
    isGreatest: z.boolean(),
    triangleName: z.string()
  }),
  simpleGenerator: () => {
    const isGreatest = getRandomBoolean();
    const triangleName = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2',
      'scalene_triangle3',
      'scalene_triangle5',
      'irregular_triangle1',
      'irregular_triangle2',
      'irregular_triangle3'
    ]);

    return { isGreatest, triangleName };
  },
  Component: props => {
    const {
      question: { isGreatest, triangleName },
      translate,
      displayMode
    } = props;

    const items = shuffle(['A', 'B', 'C'] as const, { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4WithContent
        questionHeight={900}
        title={
          isGreatest
            ? translate.instructions.selectGreatestAngleInDiagram()
            : translate.instructions.selectSmallestAngleInDiagram()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.circleGreatestAngleInDiagram()
            : translate.instructions.circleSmallestAngleInDiagram()
        }
        testCorrect={isGreatest ? [items[2]] : [items[0]]}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangleName as ShapeNames}
            angleLabels={items}
          />
        )}
        renderItems={items.map(value => ({
          value,
          component: <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{value}</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayo',
  description: 'ayo',
  keywords: ['Angles', 'Compare'],
  schema: z.object({
    angle: z.number().int().min(16).max(159),
    isGreater: z.boolean()
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(16, 159, { constraint: x => x !== 90 });
    const isGreater = getRandomBoolean();

    return { angle, isGreater };
  },
  Component: props => {
    const {
      question: { angle, isGreater },
      translate,
      displayMode
    } = props;

    return (
      <QF3Content
        title={
          isGreater
            ? translate.instructions.hereIsAnAngleDragLinesToMakeGreaterAngle()
            : translate.instructions.hereIsAnAngleDragLinesToMakeSmallerAngle()
        }
        pdfTitle={
          isGreater
            ? translate.instructions.hereIsAnAngleDrawAGreaterAngle()
            : translate.instructions.hereIsAnAngleDrawASmallerAngle()
        }
        customMarkSchemeAnswer={{
          answerText: isGreater
            ? translate.markScheme.anyGreaterAngle()
            : translate.markScheme.anySmallerAngle()
        }}
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              flexDirection: 'row',
              justifyContent: 'space-around'
            }}
          >
            <AngleFromLines
              degrees={[0, angle]}
              dimens={{ height: dimens.height * 0.7, width: dimens.width * 0.4 }}
              strokeWidth={displayMode === 'digital' ? 4 : 6}
              strokeColor={colors.prussianBlue}
            />
            {displayMode === 'digital' ? (
              <AngleFromMovableLinesWithState
                id="moveAngle"
                testComplete={answer => !arraysHaveSameContents(answer, [0, 0])}
                testCorrect={([a, b]) => {
                  const answer = angleBetweenTwoLines(a, b, false);
                  return isGreater ? answer > angle : answer < angle;
                }}
                variant="halfHeight"
              />
            ) : (
              <View
                style={{ borderWidth: 2, height: dimens.height, width: dimens.width * 0.6 }}
              ></View>
            )}
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ayp',
  description: 'ayp',
  keywords: ['Order', 'Angles'],
  schema: z.object({
    angles: z.number().int().min(10).max(170).array().length(3)
  }),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusive(10, 80);
    const angle2 = randomIntegerInclusive(Math.max(90, angle1 + 15), 170);
    const angle3 = randomIntegerInclusive(10, 170, {
      // not within 15 of either of the others
      constraint: x =>
        !countRange(30, angle1 - 15).includes(x) && !countRange(30, angle2 - 15).includes(x)
    });

    const angles = shuffle([angle1, angle2, angle3]);

    return { angles };
  },
  Component: ({ question: { angles }, translate, displayMode }) => {
    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragCardsToOrderAnglesSmallestGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderAnglesSmallestGreatest()}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        items={angles.map(angle => ({
          value: angle,
          component: (
            <MeasureView>
              {dimens => (
                <AngleFromLines
                  // to optimise the space in a draggable
                  degrees={angle <= 90 ? [90, angle + 90] : [-90, angle - 90]}
                  dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                  strokeWidth={displayMode === 'digital' ? 2 : 4}
                  strokeColor={colors.prussianBlue}
                />
              )}
            </MeasureView>
          )
        }))}
        testCorrect={sortNumberArray(angles, 'ascending')}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ayq',
  description: 'ayq',
  keywords: ['Order', 'Angles'],
  schema: z.object({
    shape: z.enum([
      'Shapes_with_interior_angles/Quadrilateral6_interior_angles',
      'Shapes_with_interior_angles/Quadrilateral7_interior_angles',
      'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles'
    ]),
    isGreatest: z.boolean()
  }),
  simpleGenerator: () => {
    // TODO: We need to add more, there is not enough variation at the moment
    const shape = getRandomFromArray([
      'Shapes_with_interior_angles/Quadrilateral6_interior_angles',
      'Shapes_with_interior_angles/Quadrilateral7_interior_angles',
      'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles'
    ] as const);
    const isGreatest = getRandomBoolean();

    return { shape, isGreatest };
  },
  Component: ({ question: { shape, isGreatest }, translate, displayMode }) => {
    const labels: string[] = [
      translate.letters.J(),
      translate.letters.K(),
      translate.letters.L(),
      translate.letters.M()
    ];

    let greatestLetters: string[][];
    const correctAnswer: string[][] = (() => {
      switch (shape) {
        case 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles':
          greatestLetters = [
            [translate.letters.M()],
            [translate.letters.J()],
            [translate.letters.K()],
            [translate.letters.L()]
          ];
          return isGreatest ? greatestLetters : [...greatestLetters].reverse();
        case 'Shapes_with_interior_angles/Quadrilateral6_interior_angles':
          greatestLetters = [
            [translate.letters.M()],
            [translate.letters.K()],
            [translate.letters.L()],
            [translate.letters.J()]
          ];
          return isGreatest ? greatestLetters : [...greatestLetters].reverse();
        case 'Shapes_with_interior_angles/Quadrilateral7_interior_angles':
          greatestLetters = [
            [translate.letters.J()],
            [translate.letters.K()],
            [translate.letters.L()],
            [translate.letters.M()]
          ];
          return isGreatest ? greatestLetters : [...greatestLetters].reverse();
      }
    })();

    return (
      <QF36ContentAndSentencesDrag
        title={
          isGreatest
            ? translate.instructions.hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesGreatest()
            : translate.instructions.hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesSmallest()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesGreatestPDF()
            : translate.instructions.hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesSmallestPDF()
        }
        testCorrect={correctAnswer}
        sentences={['<ans/>', '<ans/>', '<ans/>', '<ans/>']}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: dimens.width * 0.8,
                height: dimens.height,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <LabelledShape
                shapeName={shape}
                dimens={{ width: dimens.width * 0.8, height: dimens.height }}
                angleLabels={labels}
              />
            </View>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: colors.grey, fontSize: displayMode === 'digital' ? 26 : 38 }}>
                {isGreatest ? translate.keywords.Greatest() : translate.keywords.Smallest()}
              </Text>
              {verticalArrow(dimens.height * 0.7)}
              <Text style={{ color: colors.grey, fontSize: displayMode === 'digital' ? 26 : 38 }}>
                {isGreatest ? translate.keywords.Smallest() : translate.keywords.Greatest()}
              </Text>
            </View>
          </View>
        )}
        items={labels}
        actionPanelVariant="end"
        sentencesStyle={{ marginTop: 0, rowGap: 0 }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'ayr',
  description: 'ayr',
  keywords: ['Order', 'Angles'],
  schema: z.object({
    shapes: z
      .array(z.object({ svgName: z.string(), shape: z.string(), angleSize: z.number() }))
      .length(4),
    isGreatest: z.boolean()
  }),
  simpleGenerator: () => {
    const shapes = getRandomUniqueOneInteriorAngleShape(4);
    const isGreatest = getRandomBoolean();

    return { shapes, isGreatest };
  },
  Component: ({ question: { shapes, isGreatest }, translate, displayMode }) => {
    const orderedAngles = sortNumberArray(
      shapes.map(val => val.angleSize),
      isGreatest ? 'descending' : 'ascending'
    );
    const labels = [
      translate.letters.A(),
      translate.letters.B(),
      translate.letters.C(),
      translate.letters.D()
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isGreatest
            ? translate.instructions.anInteriorAngleIsMarkedOnEachPolygonSelectGreatest()
            : translate.instructions.anInteriorAngleIsMarkedOnEachPolygonSelectSmallest()
        }
        pdfTitle={
          isGreatest
            ? translate.instructions.anInteriorAngleIsMarkedOnEachPolygonCircleGreatest()
            : translate.instructions.anInteriorAngleIsMarkedOnEachPolygonCircleSmallest()
        }
        testCorrect={labels.filter(
          (_val, i) => i === shapes.map(val => val.angleSize).indexOf(orderedAngles[0])
        )}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {shapes.map((val, i) => (
              <View
                key={`shape_${i}`}
                style={{
                  width: dimens.width * 0.2,
                  alignItems: 'center'
                }}
              >
                <Text variant="WRN700">{labels[i]}</Text>
                <AssetSvg
                  name={val.svgName as SvgName}
                  width={dimens.width * 0.2}
                  height={displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.5}
                />
              </View>
            ))}
          </View>
        )}
        renderItems={labels.map(value => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {value}
            </Text>
          )
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderAngles',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
