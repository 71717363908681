import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { integerToWord, numberOfZeroDigits, ScientificNotation } from 'common/src/utils/math';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import AutoScaleText from 'common/src/components/typography/AutoScaleText';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import PlaceValueChart from 'common/src/components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aeI',
  description: 'aeI',
  keywords: ['Place value', 'Represent', '10,000,000', 'Million', 'Order'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000)
    })
    .refine(val => numberOfZeroDigits(val.number) === 0, 'number must not contain any 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1111111, 9999999, {
      constraint: x => {
        // Reject any number that has 0 as a digit
        return numberOfZeroDigits(x) === 0;
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const correctOrder = Array.from(number.toString());
    const digits = shuffle(correctOrder, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsMakeNumber(integerToWord(number))}
        pdfTitle={translate.instructions.useCardsMakeNumber(integerToWord(number))}
        sentence={'<ans/> '.repeat(correctOrder.length)}
        items={digits}
        testCorrect={correctOrder}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aeJ',
  description: 'aeJ',
  keywords: ['Place value', '10,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000)
    })
    .refine(val => numberOfZeroDigits(val.number) === 0, 'number must not contain any 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1111111, 9999999, {
      constraint: x => {
        // Reject any number that has 0 as a digit
        return numberOfZeroDigits(x) === 0;
      }
    });
    return { number };
  },
  Component: ({ question: { number }, translate }) => {
    const numberString = integerToWord(number);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.writeNumInNumerals()}
        testCorrect={[number.toString()]}
        inputMaxCharacters={9}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AutoScaleText
            variant="WRN400"
            containerStyle={{ height: 200, width: dimens.width }}
            maxFontSize={50}
            minFontSize={32}
          >
            {numberString}
          </AutoScaleText>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aeK',
  description: 'aeK',
  keywords: ['Place value', '10,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000),
      wrongNumbers: z.array(z.number().min(1000000).max(10000000)).length(3),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1000000, 10000000, {
      constraint: x => {
        // There must be at least 2 unique numbers after the first one
        const numArray = Array.from(x.toString());
        const numUnique = new Set(numArray.slice(1)).size;
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0 && numUnique >= 2;
      }
    });

    const wrongNumbers = new Set<number>();
    const numArray = Array.from(number.toString());
    while (wrongNumbers.size < 3) {
      // Shuffle digits except the first one
      const shuffledNumberArray = [numArray[0], ...shuffle(numArray.slice(1))];
      // Reform the integer and push to wrong numbers
      wrongNumbers.add(Number(shuffledNumberArray.join('')));
    }
    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, wrongNumbers: Array.from(wrongNumbers), correctPosition };
  },
  Component: props => {
    const {
      question: { number, wrongNumbers, correctPosition },
      translate,
      displayMode
    } = props;
    const allAnswers = [
      ...wrongNumbers.slice(0, correctPosition),
      number,
      ...wrongNumbers.slice(correctPosition)
    ];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPictureShowsNumWords(integerToWord(number))}
        pdfTitle={translate.instructions.circlePictureShowsNumWords(integerToWord(number))}
        testCorrect={[number]}
        numItems={4}
        renderItems={({ dimens }) => {
          return allAnswers.map(number => ({
            value: number,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(number)}
                columnsToShow={[6, 5, 4, 3, 2, 1, 0]}
                dimens={{
                  height: dimens.height * 0.9,
                  width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.95
                }}
                headerVariant="shortName"
                counterSize={displayMode === 'digital' ? undefined : 50}
                counterVariant={'greyCounter'}
              />
            )
          }));
        }}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question4 = newQuestionContent({
  uid: 'aeL',
  description: 'aeL',
  keywords: ['Place value', '10,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000),
      wrongNumbers: z.array(z.number().min(1000000).max(10000000)).length(3),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s')
    .refine(
      val => arrayHasNoDuplicates(val.wrongNumbers),
      'wrongNumbers must contain no duplicates'
    ),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1000000, 10000000, {
      constraint: x => {
        // There must be at least 2 unique numbers after the first one
        const numArray = Array.from(x.toString());
        const numUnique = new Set(numArray.slice(1)).size;
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0 && numUnique >= 3;
      }
    });

    const wrongNumbersSet = new Set<number>();
    const numArray = Array.from(number.toString());
    while (wrongNumbersSet.size < 3) {
      // Shuffle digits except the first one
      const shuffledNumberArray = [numArray[0], ...shuffle(numArray.slice(1))];
      // Reform the integer and push to wrong numbers
      if (Number(shuffledNumberArray.join('')) !== number) {
        wrongNumbersSet.add(Number(shuffledNumberArray.join('')));
      }
    }
    const wrongNumbers = Array.from(wrongNumbersSet);

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, wrongNumbers, correctPosition };
  },
  Component: props => {
    const {
      question: { number, wrongNumbers, correctPosition },
      translate
    } = props;
    const allAnswers = [
      ...wrongNumbers.slice(0, correctPosition),
      number,
      ...wrongNumbers.slice(correctPosition)
    ];

    let longestLength = 0;
    const sentences: string[] = [];
    allAnswers.forEach((answer, index) => {
      sentences.push(integerToWord(answer));
      longestLength = Math.max(longestLength, sentences[index].length);
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNumberNum(number)}
        pdfTitle={`${translate.instructions.whichNumWordsShowsNumber(
          number
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={[number]}
        numItems={allAnswers.length as 2 | 3 | 4}
        renderItems={() => {
          return allAnswers.map((number, index) => ({
            value: number,
            component: (
              <AutoScaleText
                longestInGroup={longestLength}
                containerStyle={{ width: '100%', height: '100%' }}
              >
                {sentences[index]}
              </AutoScaleText>
            )
          }));
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aeM',
  description: 'aeM',
  keywords: ['Place value', 'Words', '1,000,000', 'Million', 'Order'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1000000, 10000000, {
      constraint: x => {
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0;
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const correctOrder = Array.from(number.toString());
    const digits = shuffle(correctOrder, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsMakeNumber(integerToWord(number))}
        pdfTitle={translate.instructions.useCardsMakeNumber(integerToWord(number))}
        sentence={'<ans/> '.repeat(correctOrder.length)}
        items={digits}
        testCorrect={correctOrder}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aeN',
  description: 'aeN',
  keywords: ['Place value', '10,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(1000000).max(10000000)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1000000, 10000000, {
      constraint: x => {
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0;
      }
    });
    return { number };
  },
  Component: ({ question: { number }, translate }) => {
    const numberString = integerToWord(number);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.writeNumInNumerals()}
        testCorrect={[number.toString()]}
        inputMaxCharacters={9}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AutoScaleText
            variant="WRN400"
            containerStyle={{ height: 200, width: dimens.width }}
            maxFontSize={50}
            minFontSize={32}
          >
            {numberString}
          </AutoScaleText>
        )}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndWriteNumbersTo10000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
