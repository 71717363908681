import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { arrayHasNoDuplicates, filledArray, range } from '../../../../utils/collections';
import { ADD, MULT } from '../../../../constants';
import TextStructure from '../../../../components/molecules/TextStructure';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPA',
  description: 'aPA',
  keywords: [
    'Multiplication',
    'Multiply',
    'Lots of',
    'Repeated addition',
    'Unit fraction',
    'Integer'
  ],
  schema: z
    .object({
      denominatorA: z.number().int().min(3).max(6),
      multiplierA: z.number().int().min(2).max(6),
      denominatorB: z.number().int().min(2).max(6),
      multiplierB: z.number().int().min(2).max(6),
      denominatorC: z.number().int().min(2).max(6),
      multiplierC: z.number().int().min(2).max(6)
    })
    .refine(
      val => arrayHasNoDuplicates([val.denominatorA, val.denominatorB, val.denominatorC]),
      'All denominators must be different.'
    ),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(3, 6);

    const multiplierA = randomIntegerInclusive(2, denominatorA - 1);

    const [denominatorB, denominatorC] = randomUniqueIntegersInclusive(2, 6, 2, {
      constraint: x => x !== denominatorA
    });

    const multiplierB = randomIntegerInclusive(2, 6);

    const multiplierC = randomIntegerInclusive(2, 6);

    return { denominatorA, multiplierA, denominatorB, multiplierB, denominatorC, multiplierC };
  },
  Component: props => {
    const {
      question: { denominatorA, multiplierA, denominatorB, multiplierB, denominatorC, multiplierC },
      translate,
      displayMode
    } = props;

    const items = [
      {
        sentence: `<frac n='1' d='${denominatorA.toLocaleString()}' /> ${MULT} ${multiplierA.toLocaleString()}`,
        value: 'A'
      },
      {
        sentence: `<frac n='1' d='${denominatorB.toLocaleString()}' /> ${MULT} ${multiplierB.toLocaleString()}`,
        value: 'B'
      },
      {
        sentence: `<frac n='1' d='${denominatorC.toLocaleString()}' /> ${MULT} ${multiplierC.toLocaleString()}`,
        value: 'C'
      }
    ];

    const statements = shuffle(
      [
        {
          sentence: `<frac n='1' d='${denominatorA.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='1' d='${denominatorA.toLocaleString()}' />`,
            multiplierA - 1
          ).join('')} = <ans/>`,
          value: 'A'
        },
        {
          sentence: `<frac n='1' d='${denominatorB.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='1' d='${denominatorB.toLocaleString()}' />`,
            multiplierB - 1
          ).join('')} = <ans/>`,
          value: 'B'
        },
        {
          sentence: `<frac n='1' d='${denominatorC.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='1' d='${denominatorC.toLocaleString()}' />`,
            multiplierC - 1
          ).join('')} = <ans/>`,
          value: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchEachAdditionToEquivMult()}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endMid"
        pdfTitle={translate.instructions.useCardsToMatchEachAdditionToEquivMult()}
        pdfLayout="itemsRight"
        items={items.map(({ sentence, value }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value
          };
        })}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(it => it.sentence)}
        testCorrect={statements.map(it => [it.value])}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aPB',
  description: 'aPB',
  keywords: [
    'Multiplication',
    'Multiply',
    'Lots of',
    'Repeated addition',
    'Unit fraction',
    'Integer',
    'Bar model'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(7),
    multiplier: z.number().int().min(2).max(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 7);

    // multiplier should be at least 1 less than denominator, but can only be up to 4:
    const multiplier = randomIntegerInclusive(2, Math.min(4, denominator - 1));

    return { denominator, multiplier };
  },
  Component: props => {
    const {
      question: { denominator, multiplier },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.shadeInTheBarModelToHelpCompleteTheCalculation()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [multiplier, denominator]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [multiplier, denominator])
        }
        inputMaxCharacters={1}
        sentences={[
          `<frac n='1' d='${denominator}' />${filledArray(
            ` ${ADD} <frac n='1' d='${denominator}' />`,
            multiplier - 1
          ).join('')} = <frac nAns='' dAns='' />`,
          `${multiplier.toLocaleString()} ${MULT} <frac n='1' d='${denominator}' /> = <frac nAns='' dAns='' />`
        ]}
        textStyle={{ fontSize: 32 }}
        fractionTextStyle={{ fontSize: 32 }}
        // Styling needed to try and keep the two sentences/equations as separate as possible on the same line:
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [multiplier.toLocaleString(), denominator.toLocaleString()],
            [multiplier.toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <BarModelInteractiveWithState
            id="barModel"
            numberOfRows={1}
            numberOfCols={denominator}
            tableWidth={dimens.width}
            tableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPC',
  description: 'aPC',
  keywords: ['Multiplication', 'Multiply', 'Unit fraction', 'Integer', 'Non-unit fraction'],
  schema: z.object({
    denominatorA: z.number().int().min(3).max(12),
    multiplierA: z.number().int().min(2).max(11),
    denominatorB: z.number().int().min(3).max(12),
    multiplierB: z.number().int().min(2).max(11),
    startWithMultiplierA: z.boolean(),
    startWithMultiplierB: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(3, 12, 2);

    const multiplierA = randomIntegerInclusive(2, denominatorA - 1);

    const multiplierB = randomIntegerInclusive(2, denominatorB - 1);

    const startWithMultiplierA = getRandomBoolean();

    const startWithMultiplierB = getRandomBoolean();

    return {
      multiplierA,
      denominatorA,
      multiplierB,
      denominatorB,
      startWithMultiplierA,
      startWithMultiplierB
    };
  },
  Component: props => {
    const {
      question: {
        multiplierA,
        denominatorA,
        multiplierB,
        denominatorB,
        startWithMultiplierA,
        startWithMultiplierB
      },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [multiplierA, denominatorA]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [multiplierB, denominatorB])
        }
        inputMaxCharacters={1}
        sentences={[
          startWithMultiplierA
            ? `${multiplierA.toLocaleString()} ${MULT} <frac n='1' d='${denominatorA}'/> = <frac nAns='' dAns=''/>`
            : `<frac n='1' d='${denominatorA}'/> ${MULT} ${multiplierA.toLocaleString()} = <frac nAns='' dAns=''/>`,
          startWithMultiplierB
            ? `${multiplierB.toLocaleString()} ${MULT} <frac n='1' d='${denominatorB}'/> = <frac nAns='' dAns=''/>`
            : `<frac n='1' d='${denominatorB}'/> ${MULT} ${multiplierB.toLocaleString()} = <frac nAns='' dAns=''/>`
        ]}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [multiplierA.toLocaleString(), denominatorA.toLocaleString()],
            [multiplierB.toLocaleString(), denominatorB.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPD',
  description: 'aPD',
  keywords: ['Multiplication', 'Multiply', 'Unit fraction', 'Integer', 'Non-unit fraction'],
  schema: z
    .object({
      denominator: z.number().int().min(6).max(20),
      correctNumerator: z.number().int().min(2).max(19),
      incorrectNumeratorA: z.number().int().min(2).max(19),
      incorrectNumeratorB: z.number().int().min(2).max(19)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.correctNumerator,
          val.incorrectNumeratorA,
          val.incorrectNumeratorB
        ]),
      'All numerators must be different.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(6, 20);

    const [correctNumerator, incorrectNumeratorA, incorrectNumeratorB] =
      randomUniqueIntegersInclusive(2, denominator - 1, 3);

    return {
      denominator,
      correctNumerator,
      incorrectNumeratorA,
      incorrectNumeratorB
    };
  },
  Component: props => {
    const {
      question: { denominator, correctNumerator, incorrectNumeratorA, incorrectNumeratorB },
      translate
    } = props;

    const eqs = shuffle(
      [
        {
          sentence: `<frac n='${correctNumerator}' d='${denominator}'/> ${translate.units.m()}`,
          isCorrect: true
        },
        {
          sentence: `<frac n='${incorrectNumeratorA}' d='${denominator}'/> ${translate.units.m()}`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${incorrectNumeratorB}' d='${denominator}'/> ${translate.units.m()}`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${
            correctNumerator * 10 + 1
          }' d='${denominator}'/> ${translate.units.m()}`,
          isCorrect: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.theLengthOfABrickIsNumMWhatIsTotalLength(
          `<frac n='1' d='${denominator}'/>`,
          correctNumerator
        )}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: (
              <TextStructure
                sentence={equation.sentence}
                style={{ justifyContent: 'center' }}
                fractionTextStyle={{ fontWeight: '700' }}
                textStyle={{ fontWeight: '700' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPE',
  description: 'aPE',
  keywords: [
    'Multiplication',
    'Multiply',
    'Unit fraction',
    'Integer',
    'Non-unit fraction',
    'Number line'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(6),
      multiplier: z.number().int().min(3).max(8)
    })
    .refine(val => val.multiplier > val.denominator, 'multiplier must be greater than denominator.')
    .refine(
      val => val.multiplier % val.denominator !== 0,
      'multiplier must not be a multiple of denominator.'
    ),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(3, 8);

    // denominator must be less than multiplier, up to a possible max of 6:
    const denominator = randomIntegerInclusive(2, Math.min(6, multiplier - 1), {
      constraint: x => multiplier % x !== 0
    });

    return { denominator, multiplier };
  },
  Component: props => {
    const {
      question: { denominator, multiplier },
      translate
    } = props;

    const mixedNumberInteger = Math.floor(multiplier / denominator);

    const mixedNumberNumerator = multiplier % denominator;

    const numberLineIntegers = range(0, mixedNumberInteger + 1);

    const fractionJumps = range(1, denominator - 1).map(num => fractionToDecimal(num, denominator));

    const allTicks: number[] = [];

    for (let i = 0; i < numberLineIntegers.length; i++) {
      // Push each integer into allTicks:
      allTicks.push(numberLineIntegers[i]);

      // Push each integer + fraction into allTicks - mathjs needed to ensure correct number of arrows created:
      fractionJumps.map(frac =>
        allTicks.push(number(math.evaluate(`${numberLineIntegers[i]} + ${frac}`)))
      );
    }

    const tickArray = allTicks.map(num => {
      return {
        label: num % 1 === 0 ? num.toLocaleString() : '',
        position: num
      };
    });

    const jumpArrowArray = allTicks
      .filter(num => num < fractionToDecimal(multiplier, denominator))
      .map(num => {
        return {
          start: num,
          end: num + fractionToDecimal(1, denominator),
          label: ''
        };
      });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToCompleteMult()}
        sentence={`${multiplier.toLocaleString()} ${MULT} <frac n='1' d='${denominator}' /> = <frac nAns='' dAns='' /> = <frac wAns='' nAns='' dAns='' />`}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [multiplier, denominator]) &&
          userAnswer[2] === mixedNumberInteger.toString() &&
          compareFractions([userAnswer[3], userAnswer[4]], [mixedNumberNumerator, denominator])
        }
        inputMaxCharacters={2}
        textStyle={{ fontSize: 40 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            multiplier.toLocaleString(),
            denominator.toLocaleString(),
            mixedNumberInteger.toLocaleString(),
            mixedNumberNumerator.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            dimens={dimens}
            tickValues={tickArray}
            start={0}
            end={mixedNumberInteger + 1}
            jumpArrowArray={jumpArrowArray}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPF',
  description: 'aPF',
  keywords: ['Multiplication', 'Multiply', 'Unit fraction', 'Integer', 'Non-unit fraction'],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(11),
      multiplierA: z.number().int().min(3).max(12),
      denominatorB: z.number().int().min(2).max(11),
      multiplierB: z.number().int().min(3).max(12),
      incorrectMultiplierA: z.number().int().min(2).max(12),
      incorrectMultiplierB: z.number().int().min(2).max(12),
      incorrectMultiplierC: z.number().int().min(2).max(12),
      incorrectMultiplierD: z.number().int().min(2).max(12)
    })
    .refine(
      val => val.multiplierA > val.denominatorA,
      'multiplierA must be greater than denominatorA.'
    )
    .refine(
      val => val.multiplierB > val.denominatorB,
      'multiplierB must be greater than denominatorB.'
    )
    .refine(
      val => val.multiplierA % val.denominatorA !== 0,
      'multiplierA must not be a multiple of denominatorA.'
    )
    .refine(
      val => val.multiplierB % val.denominatorB !== 0,
      'multiplierB must not be a multiple of denominatorB.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.denominatorA, val.denominatorB]),
      'All denominators must be different.'
    )
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.multiplierA,
          val.multiplierB,
          val.incorrectMultiplierA,
          val.incorrectMultiplierB,
          val.incorrectMultiplierC,
          val.incorrectMultiplierD
        ]),
      'All multipliers must be different.'
    ),
  simpleGenerator: () => {
    const { denominatorA, multiplierA, denominatorB, multiplierB } = rejectionSample(
      () => {
        const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 11, 2);

        const multiplierA = randomIntegerInclusive(denominatorA + 1, 12);

        const multiplierB = randomIntegerInclusive(denominatorB + 1, 12);

        return { denominatorA, multiplierA, denominatorB, multiplierB };
      },
      ({ denominatorA, multiplierA, denominatorB, multiplierB }) =>
        multiplierA !== multiplierB &&
        multiplierA % denominatorA !== 0 &&
        multiplierB % denominatorB !== 0
    );

    const [incorrectMultiplierA, incorrectMultiplierB, incorrectMultiplierC, incorrectMultiplierD] =
      randomUniqueIntegersInclusive(3, 12, 4, {
        constraint: x => x !== multiplierA && x !== multiplierB
      });

    return {
      denominatorA,
      multiplierA,
      denominatorB,
      multiplierB,
      incorrectMultiplierA,
      incorrectMultiplierB,
      incorrectMultiplierC,
      incorrectMultiplierD
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        multiplierA,
        denominatorB,
        multiplierB,
        incorrectMultiplierA,
        incorrectMultiplierB,
        incorrectMultiplierC,
        incorrectMultiplierD
      },
      translate,
      displayMode
    } = props;

    const mixedNumberIntegerA = Math.floor(multiplierA / denominatorA);

    const mixedNumberNumeratorA = multiplierA % denominatorA;

    const mixedNumberIntegerB = Math.floor(multiplierB / denominatorB);

    const mixedNumberNumeratorB = multiplierB % denominatorB;

    const items = shuffle(
      [
        multiplierA.toLocaleString(),
        multiplierB.toLocaleString(),
        incorrectMultiplierA.toLocaleString(),
        incorrectMultiplierB.toLocaleString(),
        incorrectMultiplierC.toLocaleString(),
        incorrectMultiplierD.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    const statements = shuffle(
      [
        {
          sentence: `<ans/> ${MULT} <frac n='${(1).toLocaleString()}' d='${denominatorA.toLocaleString()}' /> = <frac w='${mixedNumberIntegerA.toLocaleString()}' n='${mixedNumberNumeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' />`,
          value: multiplierA.toLocaleString()
        },
        {
          sentence: `<ans/> ${MULT} <frac n='${(1).toLocaleString()}' d='${denominatorB.toLocaleString()}' /> = <frac w='${mixedNumberIntegerB.toLocaleString()}' n='${mixedNumberNumeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`,
          value: multiplierB.toLocaleString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        items={items}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentences={statements.map(it => it.sentence)}
        testCorrect={statements.map(it => [it.value])}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAUnitFractionByAnInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
