import { ComponentProps, useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import { parseMarkup } from '../../../../markup';
import { DisplayMode } from '../../../../contexts/displayMode';
import TextStructure from '../../../molecules/TextStructure';
import { SUB } from '../../../../constants';
import { SetState, projectSetState } from '../../../../utils/react';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { DRAGGABLE_DIMENS, DraggableVariant } from '../../../draganddrop/utils';

type Props = {
  /**
   * Input box for answer box on the number line, must be specified to know what input
   * is available to the user and how to handle the answer
   */
  inputBox?: (ansIndex: number, boxWidth: number, boxHeight: number) => JSX.Element;
  /**
   * @param topTickValues, bottomTickValues - Array representing the number line tick values/labels - to allow for variable positioning passing object with label and position.
   */
  topTickValues: string[] | { label: string; position: number }[];
  bottomTickValues: string[] | { label: string; position: number }[];
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  customBoxWidth?: number;
  /**
   * Optional symbol shown at left of the input box. Takes either a string to be rendered
   * or a JSX.Element
   */
  inputSymbol?: string | JSX.Element;
  customFontSize?: number;
  pdfItemVariant?: DraggableVariant;
};

/**
 * Representation showing a number line with tick values on both the top and the bottom
 */
export default function NumberLineTicksTopBottom({
  topTickValues: topTickValuesProp,
  bottomTickValues: bottomTickValuesProp,
  inputBox,
  dimens: { width, height },
  customBoxWidth,
  customFontSize,
  pdfItemVariant
}: Props) {
  const displayMode = useContext(DisplayMode);
  // Parse all the tick values. Each tick can have 0, 1 or more input boxes.

  const topTickValues = topTickValuesProp.map(tick =>
    typeof tick === 'string' ? tick : tick.label
  );

  const bottomTickValues = bottomTickValuesProp.map(tick =>
    typeof tick === 'string' ? tick : tick.label
  );

  const topTickValuesParsed = topTickValues.map(tick => parseMarkup(String(tick)));
  const bottomTickValuesParsed = bottomTickValues.map(tick => parseMarkup(String(tick)));

  const horizontalPad = 40;
  const pdfHorizontalPad = 140;
  const lineWidth =
    width - (displayMode !== 'digital' ? horizontalPad + pdfHorizontalPad : 2 * horizontalPad);

  const tickOverhang = 20;
  const topLineY = height / 2;

  const topTickSpacing = lineWidth / (topTickValues.length - 1);
  const bottomTickSpacing = lineWidth / (bottomTickValues.length - 1);
  const startingTickX = horizontalPad;

  const longestTicks =
    topTickValues.length > bottomTickValues.length ? topTickValues : bottomTickValues;

  const styles = useStyles(width, height);
  const fontSize =
    customFontSize ??
    scaleNumberLineFontSize(
      longestTicks.map(tick => {
        return tick?.toLocaleString() ?? '';
      }),
      lineWidth,
      displayMode
    );

  // Top line
  const topLine = (
    <Line
      x1={horizontalPad}
      y1={topLineY}
      x2={width - (displayMode !== 'digital' ? pdfHorizontalPad : horizontalPad)}
      y2={topLineY}
      stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
      strokeWidth={displayMode === 'digital' ? 2 : 4}
      key={'TopLine'}
    />
  );

  const ticks = (line: 'top' | 'bottom') => {
    const tickValues = line === 'top' ? topTickValuesProp : bottomTickValuesProp;
    const tickSpacing = line === 'top' ? topTickSpacing : bottomTickSpacing;
    const y1 = topLineY;
    const y2 = line === 'bottom' ? topLineY + tickOverhang : topLineY - tickOverhang;

    const tickPositions = tickValues.map(value => {
      if (typeof value !== 'string') {
        return value.position;
      }
    });

    return tickValues.map((_tick, index) => {
      const position = tickPositions[index];

      const tickX = position
        ? startingTickX + position * Math.abs(lineWidth / 100)
        : startingTickX + index * tickSpacing;

      return (
        <Line
          x1={tickX}
          y1={y1}
          x2={tickX}
          y2={y2}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          key={'tick' + index}
        />
      );
    });
  };

  let ansIndex = -1;
  const textVerticalPad = displayMode === 'digital' ? 64 : 80;
  const textBottomPad = displayMode === 'digital' ? 10 : 26;

  const pdfAnswerBoxWidth = pdfItemVariant ? DRAGGABLE_DIMENS[pdfItemVariant].width : 150;
  const pdfAnswerBoxHeight = pdfItemVariant ? DRAGGABLE_DIMENS[pdfItemVariant].height : 150;

  const boxWidth = displayMode === 'digital' ? customBoxWidth ?? 96 : pdfAnswerBoxWidth;
  const boxHeight = displayMode === 'digital' ? 96 : pdfAnswerBoxHeight;
  const tickLabels = (line: 'top' | 'bottom') => {
    const tickValues = line === 'top' ? topTickValues : bottomTickValues;
    const tickValuesParsed = line === 'top' ? topTickValuesParsed : bottomTickValuesParsed;
    const tickSpacing = line === 'top' ? topTickSpacing : bottomTickSpacing;
    const answerPadding = 10;
    const inputTop =
      line === 'top'
        ? topLineY - tickOverhang - boxHeight - answerPadding
        : topLineY + tickOverhang + answerPadding;
    const valueTop =
      line === 'top'
        ? topLineY - tickOverhang - textVerticalPad
        : topLineY + tickOverhang + textBottomPad;

    const tickObjects = line === 'top' ? topTickValuesProp : bottomTickValuesProp;

    const tickPositions = tickObjects.map(value => {
      if (typeof value !== 'string') {
        return value.position;
      }
    });

    return tickValues.map((tick, index) => {
      const position = tickPositions[index];

      const tickX = position
        ? startingTickX + position * Math.abs(lineWidth / 100)
        : startingTickX + index * tickSpacing;

      if (tickValuesParsed[index].numberOfAns > 0) {
        const ansCount = tickValuesParsed[index].numberOfAns;
        // Add number of answers for this tick to index
        ansIndex += ansCount;
        return (
          <View
            key={'tickNum' + line + index}
            style={[
              styles.completeNumberLineLabel,
              {
                left: tickX - 60,
                top: inputTop
              }
            ]}
          >
            {inputBox ? inputBox(ansIndex, boxWidth, boxHeight) : null}
          </View>
        );
      } else {
        const negativeFrac = tick.includes(`${SUB}`) && tick.includes('<frac');
        const top = tick.includes('<frac')
          ? line === 'top'
            ? valueTop - 110
            : valueTop - 50
          : valueTop;
        return (
          <View
            key={'tickNum' + line + index}
            style={[styles.completeNumberLineLabel, { left: tickX - 60, top: top }]}
          >
            <TextStructure
              sentence={tick}
              textStyle={[styles.text, { fontSize }]}
              style={{ flexWrap: 'nowrap', width: negativeFrac ? 90 : undefined }}
              fractionTextStyle={{ fontSize: fontSize }}
              fractionContainerStyle={{ height: 96 }}
            />
          </View>
        );
      }
    });
  };

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={400}
        viewBox={`0 0 ${width} ${400}`}
        style={styles.svg}
        pointerEvents={'none'}
      >
        {topLine}
        {ticks('top')}
        {ticks('bottom')}
      </Svg>
      {tickLabels('top')}
      {tickLabels('bottom')}
    </View>
  );
}

export function NumberLineTicksTopBottomInput({
  userAnswer,
  onTextInput,
  ...props
}: Omit<ComponentProps<typeof NumberLineTicksTopBottom>, 'inputBox'> & {
  userAnswer?: string[];
  onTextInput?: SetState<string[]>;
}) {
  const keyboardInputBox = (ansIndex: number, boxWidth: number, boxHeight: number) => (
    <NoKeyboardTextInput
      key={'tickNum' + ansIndex}
      value={userAnswer ? userAnswer[ansIndex] : ''}
      onChangeText={onTextInput ? projectSetState(onTextInput, ansIndex) : undefined}
      style={[
        {
          width: boxWidth,
          minHeight: boxHeight
        }
      ]}
      selectedStyle={{ zIndex: 2, borderWidth: 3 }}
      autoFocus={ansIndex === 0}
    />
  );

  return <NumberLineTicksTopBottom inputBox={keyboardInputBox} {...props} />;
}

/** See {@link NumberLineTicksTopBottomInput}. */
export const NumberLineTicksTopBottomInputWithState = withStateHOC(NumberLineTicksTopBottomInput, {
  stateProp: 'userAnswer',
  setStateProp: 'onTextInput',
  defaults: props => ({
    // default state is an empty string array of the length of how many ans boxes there are.
    defaultState: filledArray('', getAnswerCount(props)),
    testComplete: state => (state ? state.every(it => it !== '') : true)
  })
});

const getAnswerCount = (props: Omit<Props, 'inputBox'>) => {
  const topTickValuesParsed = props.topTickValues
    .map(ticks => (typeof ticks === 'string' ? parseMarkup(ticks) : parseMarkup(ticks.label)))
    .filter(i => i.numberOfAns > 0);
  const bottomTickValuesParsed = props.bottomTickValues
    .map(ticks => (typeof ticks === 'string' ? parseMarkup(ticks) : parseMarkup(ticks.label)))
    .filter(i => i.numberOfAns > 0);
  return topTickValuesParsed.length + bottomTickValuesParsed.length;
};

function useStyles(width: number, height: number) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height
        },
        svg: {
          position: 'absolute',
          top: 0,
          left: 0
        },
        text: {
          width: 120,
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          overflow: 'visible'
        },
        completeNumberLineLabel: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120
        }
      }),
    [width, height]
  );
}
