import { type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { MeasureView } from '../atoms/MeasureView';
import { SvgName, getSvgInfo } from '../../assets/svg/_generatedSvgInfo';
import { useMemo } from 'react';
import { AssetSvg } from '../../assets/svg';

export function calcRowOfImagesScaleFactor(
  containerWidth: number,
  containerHeight: number,
  imagesProp: (SvgName | Image)[],
  rowGap = 0
): number {
  const imagesDimens: { width: number; height: number }[] = imagesProp.map(image => {
    const { width, height } = typeof image === 'string' ? getSvgInfo(image) : image;
    return { width, height };
  });

  // Figure out how much we need to scale to make the row of images' width exactly match the width of the container.
  const rowNaturalWidth =
    // Sum of all images' width
    imagesDimens.map(it => it.width).reduce((sum, width) => sum + width, 0);
  const widthScaleFactor =
    rowNaturalWidth === 0
      ? 1
      : (containerWidth - rowGap * (imagesDimens.length - 1)) / rowNaturalWidth;

  // Figure out how much we need to scale to make the row of images' height exactly match the height of the container.
  const rowNaturalHeight = Math.max(...imagesDimens.map(it => it.height), 0);
  const heightScaleFactor = rowNaturalHeight === 0 ? 1 : containerHeight / rowNaturalHeight;

  // We use fit: contain, i.e. fit both dimensions in the container using the same scaleFactor.
  return Math.min(widthScaleFactor, heightScaleFactor);
}

type Image = {
  /** The image component, with input dimensions. */
  image: (props: { width: number; height: number; key?: string | number }) => JSX.Element;
  /** "Natural" starting dimensions - width. */
  width: number;
  /** "Natural" starting dimensions - height. */
  height: number;
};

type Props = {
  /**
   * The images to render on this row.
   * SVGs from assets can be provided as a simple string name. Other images, including SVGs created manually, should
   * be provided as an {@link Image} object.
   */
  images: (SvgName | Image)[];
  /**
   * Extra styles to apply to the outer container.
   *
   * Defaults to alignSelf: stretch and flex: 1
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Extra styles to apply to the row itself. Don't use this for margins, borders or padding - use `containerStyle`.
   *
   * Defaults to flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'
   */
  style?: StyleProp<ViewStyle>;
  scaleFactor?: number;
};

/**
 * A simple collection of potentially different images, arranged in a row.
 *
 * The SVGs will all scale together (with the same scale factor) to fit in the container.
 */
export default function RowOfImages({
  images: imagesProp,
  style,
  containerStyle,
  scaleFactor: scaleFactorProp
}: Props): JSX.Element {
  // Resolve SVG names
  const images: Image[] = useMemo(
    () =>
      imagesProp.map(image => {
        if (typeof image === 'string') {
          // Svg image
          const { width, height } = getSvgInfo(image);
          return {
            image: props => <AssetSvg name={image} {...props} />,
            width,
            height
          };
        } else {
          // Other
          return image;
        }
      }),
    [imagesProp]
  );

  const renderImages = (containerWidth: number, containerHeight: number, rowGap: number) => {
    const scaleFactor =
      scaleFactorProp ??
      calcRowOfImagesScaleFactor(containerWidth, containerHeight, images, rowGap);

    return images.map(({ image, width, height }, index) => {
      return image({ width: width * scaleFactor, height: height * scaleFactor, key: index });
    });
  };

  const flatStyle = useMemo(() => StyleSheet.flatten(style), [style]);

  return (
    <>
      <MeasureView containerStyle={[styles.container, containerStyle]} style={[styles.row, style]}>
        {measuredDimens => {
          return renderImages(
            measuredDimens.width,
            measuredDimens.height,
            flatStyle.rowGap ?? flatStyle.gap ?? 0
          );
        }}
      </MeasureView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    flex: 1
  },
  row: {
    flexDirection: 'row',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }
});
