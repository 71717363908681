import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { findFactors } from 'common/src/utils/factors';
import { ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, DIV } from 'common/src/constants';
import { useMemo } from 'react';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { filledArray, range } from 'common/src/utils/collections';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF27bShortDivision from '../../../../components/question/questionFormats/QF27bShortDivision';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoG',
  description: 'aoG',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(4).max(9),
      number2: z.number().int().min(2).max(9)
    })
    .refine(
      val => findFactors(val.numberA1).includes(val.number2),
      'number2 must be a factor of numberA1 greater than 1'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(4, 9);

    const numberA1AllFactors = findFactors(numberA1);

    // Remove 1 from numberA1AllFactors
    numberA1AllFactors.shift();

    const number2 = getRandomFromArray(numberA1AllFactors) as number;

    return { numberA1, number2 };
  },

  Component: props => {
    const {
      question: { numberA1, number2 },
      translate
    } = props;

    const numberB1 = numberA1 * 10;
    const numberC1 = numberA1 * 100;
    const numberD1 = numberA1 * 1000;

    const eqs = [
      getBinOpEquation({ left: numberA1, right: number2, sign: 'divide', answer: 'result' }),
      getBinOpEquation({ left: numberB1, right: number2, sign: 'divide', answer: 'result' }),
      getBinOpEquation({ left: numberC1, right: number2, sign: 'divide', answer: 'result' }),
      getBinOpEquation({ left: numberD1, right: number2, sign: 'divide', answer: 'result' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aoH',
  description: 'aoH',
  keywords: ['Divide', 'Short division', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(3).max(9),
      dividend: z.number().int().min(1001).max(99999)
    })
    .refine(val => val.dividend % 10 !== 0, 'dividend cannot be a multiple of 10')
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(3, 9);

    const dividend = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % 10 !== 0 && x % divisor === 0
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.completeDivision()}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            quotient: Array.from(quotient.toString()).reverse()
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aoI',
  description: 'aoI',
  keywords: ['Divide', 'Remainders'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(999),
      number2: z.number().int().min(4).max(9)
    })
    .refine(val => val.number1 % val.number2 === 0, 'number1 must be a multiple of number2'),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(4, 9);

    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => x % number2 === 0
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const statements = useMemo(() => {
      const eqs = [
        {
          statement: `${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()} =`,
          answer: 0
        },
        {
          statement: `${(number1 + 1).toLocaleString()} ${DIV} ${number2.toLocaleString()} =`,
          answer: 1
        },
        {
          statement: `${(number1 + 2).toLocaleString()} ${DIV} ${number2.toLocaleString()} =`,
          answer: 2
        },
        {
          statement: `${(number1 + 3).toLocaleString()} ${DIV} ${number2.toLocaleString()} =`,
          answer: 3
        }
      ];
      return shuffle(eqs, { random: seededRandom(props.question) });
    }, [number1, number2, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchRemaindersToDivisions()}
        pdfTitle={translate.instructions.matchRemaindersToDivisions()}
        items={[
          { component: 'r0', value: 0 },
          { component: 'r1', value: 1 },
          { component: 'r2', value: 2 },
          { component: 'r3', value: 3 }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aoJ',
  description: 'aoJ',
  keywords: ['Multiply', 'Written method'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(999),
      answerNumber: z.number().int().min(3).max(9)
    })
    .refine(
      val => val.number1 % val.answerNumber === 0,
      'number1 must be a multiple of answerNumber'
    ),
  simpleGenerator: () => {
    const answerNumber = randomIntegerInclusive(3, 9);

    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => x % answerNumber === 0
    });

    return { number1, answerNumber };
  },

  Component: props => {
    const {
      question: { number1, answerNumber },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(number1 / answerNumber).toString()]}
        sentence={`${number1.toLocaleString()} ${DIV} <ans/> = ${answerNumber.toLocaleString()}`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoK',
  description: 'aoK',
  keywords: ['Divide', 'Bar model', 'Short division', 'Written method'],
  schema: z
    .object({
      number1: z.number().int().min(10001).max(99999),
      number2: z.number().int().min(3).max(9),
      variableSymbol: z.enum([ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C])
    })
    .refine(val => val.number1 % val.number2 === 0, 'number1 must be a multiple of number2'),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 9);

    const number1 = randomIntegerInclusive(10001, 99999, {
      constraint: x => x % number2 === 0
    });

    const variableSymbol = getRandomFromArray([ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C] as const);

    return { number1, number2, variableSymbol };
  },
  Component: props => {
    const {
      question: { number1, number2, variableSymbol },
      translate
    } = props;

    const number3 = number1 / number2;

    const numbers = [filledArray(number1, 1), filledArray(number3, number2)];

    const strings = [
      filledArray(number1.toLocaleString(), 1),
      filledArray(variableSymbol, number2)
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${variableSymbol} = <ans/>`}
        title={translate.instructions.workOutTheValueOf(variableSymbol)}
        testCorrect={[number3.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number1}
            dimens={dimens}
            sameRowColor
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aoL',
  description: 'aoL',
  keywords: ['Divide', 'Problem', 'Remainders'],
  schema: z
    .object({
      food: z.enum([
        'Apples',
        'Blueberries',
        'Carrots',
        'Lemons',
        'Limes',
        'Mangoes',
        'Onions',
        'Oranges',
        'Pears',
        'Potatoes',
        'Strawberries'
      ]),
      foodPerBox: z.number().int().min(3).max(9),
      boxesPerYear: z
        .number()
        .int()
        .min(1001)
        .max(4999)
        .refine(val => val % 10 !== 0, 'foodPerYear cannot be a multiple of 10'),
      foodRemainder: z.number().int().min(1).max(8)
    })
    .refine(
      val => val.foodRemainder < val.foodPerBox,
      'foodRemainder must be less than foodPerBox.'
    ),
  simpleGenerator: () => {
    const food = getRandomFromArray([
      'Apples',
      'Blueberries',
      'Carrots',
      'Lemons',
      'Limes',
      'Mangoes',
      'Onions',
      'Oranges',
      'Pears',
      'Potatoes',
      'Strawberries'
    ] as const);

    const foodPerBox = randomIntegerInclusive(3, 9);

    const boxesPerYear = randomIntegerInclusive(1001, 4999, {
      constraint: x => x % 10 !== 0
    });

    const foodRemainder = randomIntegerInclusive(1, foodPerBox - 1);

    return { food, foodPerBox, boxesPerYear, foodRemainder };
  },

  Component: props => {
    const {
      question: { food, foodPerBox, boxesPerYear, foodRemainder },
      translate
    } = props;

    const foodPerYear = foodPerBox * boxesPerYear + foodRemainder;

    const foodAsString = translate.objects[food]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.farmWorkersPickNumFoodOverAYear(
          foodPerYear,
          foodAsString,
          foodPerBox
        )}
        testCorrect={[boxesPerYear.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ShortDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
