import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm12.25-4.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM11 10a1 1 0 1 0 0 2v5a1 1 0 1 0 2 0v-6a1 1 0 0 0-1-1h-1z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
