import { getRandomFromArray } from './random';
import { SvgName } from '../assets/svg';
import { zodEnumFromObjKeys } from './zod';

export type ShapeFormulae = {
  svgName: SvgName;
  largeShape: 'circles' | 'squares' | 'stars' | 'triangles';
  smallShape: 'circles' | 'squares' | 'triangles';
  smallShapeAmount: number;
};

export const shapeFormulae = {
  circle_2_triangles: {
    svgName: 'Shape_formulae/1_circle_2_triangles',
    largeShape: 'circles',
    smallShape: 'triangles',
    smallShapeAmount: 2
  },
  square_2_circles: {
    svgName: 'Shape_formulae/1_square_2_circles',
    largeShape: 'squares',
    smallShape: 'circles',
    smallShapeAmount: 2
  },
  star_2_circles: {
    svgName: 'Shape_formulae/1_star_2_circles',
    largeShape: 'stars',
    smallShape: 'circles',
    smallShapeAmount: 2
  },
  triangle_3_circles: {
    svgName: 'Shape_formulae/1_triangle_3_circles',
    largeShape: 'triangles',
    smallShape: 'circles',
    smallShapeAmount: 3
  },
  triangle_3_squares: {
    svgName: 'Shape_formulae/1_triangle_3_squares',
    largeShape: 'triangles',
    smallShape: 'squares',
    smallShapeAmount: 3
  },
  circle_4_squares: {
    svgName: 'Shape_formulae/1_circle_4_squares',
    largeShape: 'circles',
    smallShape: 'squares',
    smallShapeAmount: 4
  },
  square_4_triangles: {
    svgName: 'Shape_formulae/1_square_4_triangles',
    largeShape: 'squares',
    smallShape: 'triangles',
    smallShapeAmount: 4
  }
};

export type ShapeFormulaNames = keyof typeof shapeFormulae;
export const shapeFormulaNames = Object.keys(shapeFormulae) as ShapeFormulaNames[];
export const shapeFormulaNameSchema = zodEnumFromObjKeys(shapeFormulae);

export const getRandomShapeFormulaeName = () => {
  return getRandomFromArray(shapeFormulaNames) as ShapeFormulaNames;
};
