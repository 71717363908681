import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { isEqual, isInRange } from '../../../../utils/matchers';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import Text from '../../../../components/typography/Text';
import { ADD } from '../../../../constants';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ba2',
  description: 'ba2',
  keywords: ['Fact families', 'Add', 'Number sentences', 'Counters', 'Bar model'],
  schema: z
    .object({
      useCounters: z.boolean(),
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10),
      colors: z.array(z.enum(['red', 'green', 'blue', 'yellow'])).length(2),
      correctAnswers: z
        .array(z.enum(['A', 'B', 'C', 'D']))
        .min(1)
        .max(4),
      wrongSelects: z
        .array(z.enum(['E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']))
        .min(0)
        .max(3)
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const useCounters = getRandomBoolean();

    const colorOptions = ['red', 'green', 'blue', 'yellow'] as const;
    const colors = getRandomSubArrayFromArray(colorOptions, 2);

    // Bar model should not have 0 parts
    const [min, max] = useCounters ? [0, 10] : [1, 9];
    const partA = randomIntegerInclusive(min, max);
    const partB = randomIntegerInclusive(min, max, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    const [maxCorrects, correctOptions, wrongOptions] =
      partA === partB
        ? [2, ['A', 'B'] as const, ['E', 'F', 'G', 'H'] as const]
        : [
            4,
            ['A', 'B', 'C', 'D'] as const,
            partA === 0
              ? (['I', 'J'] as const)
              : partB === 0
              ? (['E', 'F'] as const)
              : (['E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'] as const)
          ];

    const minCorrects = [partA, partB].includes(0) ? 2 : 1;
    const numCorrects = randomIntegerInclusive(minCorrects, maxCorrects);
    const correctAnswers = getRandomSubArrayFromArray(correctOptions, numCorrects);

    const wrongSelects = getRandomSubArrayFromArray(wrongOptions, 4 - numCorrects);

    return { useCounters, partA, partB, colors, correctAnswers, wrongSelects };
  },
  Component: ({ question, translate }) => {
    const { useCounters, partA, partB, colors, correctAnswers, wrongSelects } = question;

    const total = partA + partB;
    const partASvg = `Circles/circle_${colors[0]}` as const;
    const partBSvg = `Circles/circle_${colors[1]}` as const;
    const groupedCounters = ({ width, height }: { width: number; height: number }) => [
      ...countRange(partA).map((_, i) => (
        <AssetSvg key={`${partASvg}_${i}`} name={partASvg} height={height} width={width * 0.09} />
      )),
      ...countRange(partB).map((_, i) => (
        <AssetSvg key={`${partBSvg}_${i}`} name={partBSvg} height={height} width={width * 0.09} />
      ))
    ];

    const equation = (left: number, right: number, ans: number, flipped: boolean) =>
      flipped
        ? `${ans.toLocaleString()} = ${left.toLocaleString()} ${ADD} ${right.toLocaleString()}`
        : `${left.toLocaleString()} ${ADD} ${right.toLocaleString()} = ${ans.toLocaleString()}`;

    const equationStatemets = {
      A: equation(partA, partB, total, false),
      B: equation(partA, partB, total, true),
      C: equation(partB, partA, total, false),
      D: equation(partB, partA, total, true),
      E: equation(partA, total, partB, false),
      F: equation(partA, total, partB, true),
      G: equation(total, partA, partB, false),
      H: equation(total, partA, partB, true),
      I: equation(partB, total, partA, false),
      J: equation(partB, total, partA, true),
      K: equation(total, partB, partA, false),
      L: equation(total, partB, partA, true)
    };

    const statements = shuffle(
      [...correctAnswers, ...wrongSelects].map(x => [x, equationStatemets[x]]),
      { random: seededRandom(question) }
    );

    const rep = useCounters ? 'Counters' : 'BarModel';
    const title =
      correctAnswers.length === 1
        ? (`selectTheAdditionThatMatchThe${rep}` as const)
        : (`selectTheAdditionsThatMatchThe${rep}` as const);

    const pdfTitle =
      correctAnswers.length === 1
        ? (`tickTheAdditionThatMatchThe${rep}` as const)
        : (`tickTheAdditionsThatMatchThe${rep}` as const);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={correctAnswers}
        numItems={4}
        multiSelect={true}
        Content={({ dimens }) =>
          useCounters ? (
            <View
              style={{
                ...dimens,
                columnGap: 8,
                justifyContent: 'center',
                flexDirection: 'row'
              }}
            >
              {groupedCounters(dimens)}
            </View>
          ) : (
            <BarModel dimens={dimens} total={total} numbers={[[total], [partA, partB]]} />
          )
        }
        renderItems={statements.map(([x, statement]) => ({
          value: x,
          component: <Text variant="WRN700">{statement}</Text>
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  questionHeight: 1000,
  uid: 'ba3',
  description: 'ba3',
  keywords: ['Part-whole model', 'Fact families', 'Add'],
  schema: z
    .object({
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10),
      flipped: z.boolean(),
      reverse: z.boolean(),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
      answer: z.enum(['left', 'right', 'result'])
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 10);
    const partB = randomIntegerInclusive(0, 10, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    const flipped = getRandomBoolean();
    const reverse = getRandomBoolean();

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );
    const answer = getRandomFromArray(['left', 'right', 'result'] as const);

    return { partA, partB, flipped, reverse, variation, answer };
  },
  Component: props => {
    const {
      question: { partA, partB, flipped, reverse, variation, answer },
      translate
    } = props;
    const total = partA + partB;

    const [left, right] = reverse ? [partB, partA] : [partA, partB];

    const sentence = getBinOpEquation({
      left,
      right,
      result: total,
      sign: 'add',
      flipped,
      answer
    });
    const items = shuffle(
      [partA, partB, total].map(num => {
        return { value: num.toString(), component: num.toLocaleString() };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1100}
        actionPanelVariant="end"
        title={translate.ks1Instructions.dragACardToCompleteTheAddition()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToMakeTheAdditionCorrect()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={[partA, partB]}
            dimens={{
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9,
              height: dimens.height
            }}
          />
        )}
        items={items}
        pdfLayout="itemsAboveContent"
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  questionHeight: 900,
  uid: 'ba4',
  description: 'ba4',
  keywords: ['Fact families', 'Add'],
  schema: z
    .object({
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10)
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 10);
    const partB = randomIntegerInclusive(0, 10, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    return { partA, partB };
  },
  Component: props => {
    const {
      question: { partA, partB },
      translate
    } = props;
    const total = partA + partB;

    const ansBoxPositions = shuffle(['left', 'right', 'result', undefined] as const, {
      random: seededRandom(props.question)
    });

    const equation = (
      left: number,
      right: number,
      result: number,
      flipped: boolean,
      ansBoxPosition?: 'left' | 'right' | 'result'
    ) => {
      const resultString = ansBoxPosition === 'result' ? '<ans/>' : result.toLocaleString();
      const leftString = ansBoxPosition === 'left' ? '<ans/>' : left.toLocaleString();
      const rightString = ansBoxPosition === 'right' ? '<ans/>' : right.toLocaleString();
      const statement: string = flipped
        ? `${resultString} = ${leftString} ${ADD} ${rightString}`
        : `${leftString} ${ADD} ${rightString} = ${resultString}`;

      const answer =
        ansBoxPosition === 'result'
          ? result.toString()
          : ansBoxPosition === 'left'
          ? left.toString()
          : ansBoxPosition === 'right'
          ? right.toString()
          : '';

      return [statement, answer];
    };

    const eqA = equation(partA, partB, total, false, ansBoxPositions[0]);
    const eqB = equation(partA, partB, total, true, ansBoxPositions[1]);
    const eqC = equation(partB, partA, total, false, ansBoxPositions[2]);
    const eqD = equation(partB, partA, total, true, ansBoxPositions[3]);

    const equations = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        questionHeight={900}
        title={translate.ks1Instructions.completeTheFactFamily()}
        containerStyle={{ alignItems: 'flex-start' }}
        sentences={equations.map(([statement]) => statement)}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          equations.every(([_, ans], i) => {
            if (ans.length > 0) {
              return isEqual(userAnswer[i][0])(ans);
            } else {
              return true;
            }
          })
        }
        customMarkSchemeAnswer={{
          answersToDisplay: equations.map(([_, answer]) => [answer])
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FactFamiliesAdditionFacts',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
