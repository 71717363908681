import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { getNumberOfDaysInMonth, monthNames } from '../../../../utils/months';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { View } from 'react-native';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomShortSchoolSubject,
  shortSchoolSubjectAsWord,
  shortSchoolSubjectsSchema
} from '../../../../utils/schoolSubjects';
import { QF51CompleteTheDigitalClock } from '../../../../components/question/questionFormats/QF51CompleteTheDigitalClock';
import { addDurationTo24hTime } from '../../../../utils/time';
import { ClockWithState } from '../../../../components/question/representations/Clock';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auG',
  description: 'auG',
  keywords: ['Time', 'Start times', 'End times', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    duration: z.number().int().min(20).max(50).multipleOf(5),
    startHour: z.number().int().min(1).max(3),
    startMinute: z.number().int().min(5).max(35).multipleOf(5),
    schoolSubject: shortSchoolSubjectsSchema
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const startHour = randomIntegerInclusive(1, 3);

    const duration = randomIntegerInclusiveStep(20, 50, 5);

    const startMinute = randomIntegerInclusiveStep(5, 35, 5, {
      constraint: x => x + duration < 60
    });

    const schoolSubject = getRandomShortSchoolSubject();

    return { name, duration, startHour, startMinute, schoolSubject };
  },

  Component: props => {
    const {
      question: { name, duration, startHour, startMinute, schoolSubject },
      translate
    } = props;

    const subjectString = shortSchoolSubjectAsWord(schoolSubject, translate);

    return (
      <QF51CompleteTheDigitalClock
        title={translate.instructions.charactersSubjectLessonStartsAtTime(
          name,
          subjectString,
          startHour,
          startMinute.toString().padStart(2, '0'),
          duration
        )}
        testCorrect={[startHour.toString(), (duration + startMinute).toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm={'pm'}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question2 = newQuestionContent({
  uid: 'auH',
  description: 'auH',
  keywords: ['Time', 'Start times', 'End times', 'Analogue', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    duration: z.number().int().min(35).max(55).multipleOf(5),
    startHour: z.number().int().min(1).max(7),
    startMinute: z.number().int().min(30).max(55).multipleOf(5)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const name = getRandomName();

    const duration = randomIntegerInclusiveStep(35, 55, 5);

    const startHour = randomIntegerInclusive(1, 7);

    const startMinute = randomIntegerInclusiveStep(30, 55, 5);

    return { name, duration, startHour, startMinute };
  },
  Component: ({ question: { name, duration, startHour, startMinute }, translate, displayMode }) => {
    const [endHour, endMinute] = addDurationTo24hTime(startHour, startMinute, duration);

    const interactive = displayMode === 'digital' ? true : false;
    const showHands = displayMode === 'pdf' ? false : true;
    const initialState =
      displayMode === 'markscheme' ? { hours: endHour, minutes: endMinute } : undefined;

    return (
      <QF3Content
        title={translate.instructions.characterIsBakingACake(name, duration)}
        pdfTitle={translate.instructions.characterIsBakingACakePDF(name, duration)}
        Content={({ dimens }) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-around'
              }}
            >
              <Text variant="WRN700">{translate.misc.startTime()}</Text>
              <Text variant="WRN700">{translate.misc.finishTime()}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-around'
              }}
            >
              <DigitalClock
                hours={startHour}
                minutes={startMinute}
                dimens={{
                  width: dimens.width * 0.4,
                  height: dimens.height
                }}
                amOrPm="pm"
              />
              <ClockWithState
                id="clock"
                width={dimens.width * 0.4}
                testCorrect={answer => answer.hours === endHour && answer.minutes === endMinute}
                interactive={interactive}
                showHands={showHands}
                defaultState={initialState}
              />
            </View>
          </>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'auI',
  description: 'auI',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds', 'Duration'],
  schema: z
    .object({
      hours: z.number().int().min(1).max(4),
      minutes: z.number().int().min(1).max(4),
      seconds: z.number().int().min(40).max(300).multipleOf(5),
      quartersOfAnHour: numberEnum([15, 30, 45])
    })
    .refine(
      val => val.minutes * 60 !== val.seconds,
      'minutes and seconds must not be equivalent to each other.'
    ),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 4);

    const minutes = randomIntegerInclusive(1, 4);

    const seconds = randomIntegerInclusiveStep(40, 300, 5, {
      constraint: x => minutes * 60 !== x
    });

    const quartersOfAnHour = getRandomFromArray([15, 30, 45] as const);

    return { hours, minutes, seconds, quartersOfAnHour };
  },
  Component: props => {
    const {
      question: { hours, minutes, seconds, quartersOfAnHour },
      translate,
      displayMode
    } = props;

    // Hours item's number to be written as a word:
    const hoursAsString = translate.numbersAsWords[hours as 1 | 2 | 3 | 4]();

    const items = shuffle(
      [
        {
          string:
            hours === 1
              ? translate.time.wordHour(hoursAsString)
              : translate.time.wordHours(hoursAsString),
          value: 3600 * hours
        },
        {
          string: translate.time.numMinutes(minutes),
          value: 60 * minutes
        },
        {
          string: translate.time.numSeconds(seconds),
          value: seconds
        },
        {
          string: (() => {
            switch (quartersOfAnHour) {
              case 15:
                return translate.time.quarterOfAnHour();
              case 30:
                return translate.time.halfAnHour();
              case 45:
                return translate.time.threeQuartersOfAnHour();
            }
          })(),
          value: 60 * quartersOfAnHour
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragCardsToOrderTheDurationsOfTime()}
        pdfTitle={translate.instructions.useCardsToOrderTheDurationsOfTime()}
        testCorrect={sortNumberArray(
          items.map(x => x.value),
          'ascending'
        )}
        draggableVariant={displayMode !== 'digital' ? 'tallRectangle' : 'rectangle'}
        items={items.map(({ value, string }) => ({
          value,
          component: (
            <Text
              variant="WRN700"
              style={{
                textAlign: 'center',
                lineHeight: displayMode === 'digital' ? 48 : 50,
                fontSize: displayMode === 'digital' ? 32 : 40
              }}
            >
              {string}
            </Text>
          )
        }))}
        topLabel={translate.keywords.Shortest()}
        bottomLabel={translate.keywords.Longest()}
      />
    );
  }
});

// Deliberately missing November and December, as requested by the question specification.
const Question4Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October'
] as const;

const Question4 = newQuestionContent({
  uid: 'auJ',
  description: 'auJ',
  keywords: ['Time', 'Weeks', 'Months', 'Duration'],
  schema: z.object({
    numberOfWeeks: z.number().int().min(2).max(10),
    startMonth: z.enum(Question4Months),
    numberOfMonthsFromStart: z.number().int().min(1).max(2),
    dateInEndMonth: z.number().int().min(10).max(25).multipleOf(5)
  }),
  simpleGenerator: () => {
    const startMonth = getRandomFromArray(Question4Months);

    const numberOfMonthsFromStart = randomIntegerInclusive(1, 2);

    const dateInEndMonth = randomIntegerInclusiveStep(10, 25, 5);

    const currentMonthIndex = monthNames.indexOf(startMonth);

    const monthsInDays =
      getNumberOfDaysInMonth(startMonth) +
      dateInEndMonth +
      // Calculates extra days to add from the second month of three if numberOfMonths is 2, otherwise don't add anything else.
      (numberOfMonthsFromStart === 2
        ? getNumberOfDaysInMonth(monthNames[currentMonthIndex + 1])
        : 0);

    const numberOfWeeks = randomIntegerInclusive(2, 10, {
      // Ensure that the number of days between weeks and months do not match,
      // e.g. if 1 March to 15 April, do not let numberOfWeeks be 5
      constraint: x => x * 7 !== monthsInDays
    });

    return { numberOfWeeks, startMonth, numberOfMonthsFromStart, dateInEndMonth };
  },
  Component: props => {
    const {
      question: { numberOfWeeks, startMonth, numberOfMonthsFromStart, dateInEndMonth },
      translate
    } = props;

    const weeksInDays = numberOfWeeks * 7;

    const currentMonthIndex = monthNames.indexOf(startMonth);

    const monthsInDays =
      getNumberOfDaysInMonth(startMonth) +
      dateInEndMonth +
      // Calculates extra days to add from the second month of three if numberOfMonths is 2, otherwise don't add anything else.
      (numberOfMonthsFromStart === 2
        ? getNumberOfDaysInMonth(monthNames[currentMonthIndex + 1])
        : 0);

    const [startMonthString, endMonthString] = [
      translate.time[startMonth](),
      translate.time[monthNames[currentMonthIndex + numberOfMonthsFromStart]]()
    ];

    const shuffledStatements = shuffle(
      [
        {
          sentence: translate.time.numWeeks(numberOfWeeks),
          value: weeksInDays
        },
        {
          sentence: translate.answerSentences.xDateToYDate(
            1,
            startMonthString,
            dateInEndMonth,
            endMonthString
          ),
          value: monthsInDays
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectLongerDuration()}
        pdfTitle={translate.instructions.circleLongerDuration()}
        testCorrect={[Math.max(weeksInDays, monthsInDays)]}
        numItems={2}
        renderItems={() => {
          return shuffledStatements.map(({ sentence, value }) => ({
            value,
            component: (
              <Text style={{ textAlign: 'center' }} variant="WRN700">
                {sentence}
              </Text>
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'auK',
  description: 'auK',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z
    .object({
      startHour: z.number().int().min(1).max(8),
      startMinute: z.number().int().min(1).max(58),
      hoursAfterStartHour: z.number().int().min(2).max(3),
      endMinute: z.number().int().min(2).max(59)
    })
    .refine(val => val.startMinute < val.endMinute, 'startMinute must be less than endMinute.'),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(1, 8);

    const startMinute = randomIntegerInclusive(1, 58);

    const hoursAfterStartHour = randomIntegerInclusive(2, 3);

    const endMinute = randomIntegerInclusive(startMinute + 1, 59);

    return { startHour, startMinute, hoursAfterStartHour, endMinute };
  },

  Component: props => {
    const {
      question: { startHour, startMinute, hoursAfterStartHour, endMinute },
      translate
    } = props;

    const endHour = startHour + hoursAfterStartHour;

    const duration = hoursAfterStartHour * 60 + (60 - (60 - endMinute + startMinute));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMinutesBetweenTheseTwoTimes()}
        testCorrect={[duration.toString()]}
        sentence={translate.answerSentences.ansMinutes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly', alignItems: 'center' }]}>
            <DigitalClock
              hours={startHour}
              minutes={startMinute}
              dimens={{ width: dimens.width, height: dimens.height * 0.45 }}
              amOrPm="pm"
            />
            <DigitalClock
              hours={endHour}
              minutes={endMinute}
              dimens={{ width: dimens.width, height: dimens.height * 0.45 }}
              amOrPm="pm"
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'auL',
  description: 'auL',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    filmStartHour: z.number().int().min(1).max(5),
    filmStartMinutes: z.number().int().min(5).max(30).multipleOf(5),
    filmDurationMinutes: z.number().int().min(61).max(80)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const filmStartHour = randomIntegerInclusive(1, 5);

    const filmStartMinutes = randomIntegerInclusiveStep(5, 30, 5);

    const filmDurationMinutes = randomIntegerInclusive(61, 80);

    return { name, filmStartHour, filmStartMinutes, filmDurationMinutes };
  },

  Component: props => {
    const {
      question: { name, filmStartHour, filmStartMinutes, filmDurationMinutes },
      translate
    } = props;

    const filmStartTimeInMinutes = filmStartHour * 60 + filmStartMinutes;

    const filmEndTimeInMinutes = filmStartTimeInMinutes + filmDurationMinutes;

    const homeworkEnd = filmStartHour + 2;

    const homeworkEndInMinutes = homeworkEnd * 60;

    const homeworkDuration = homeworkEndInMinutes - filmEndTimeInMinutes;

    // filmStartMinutesString needed to ensure times display with a leading 0 where needed, e.g. 2:05 pm, not 2:5 pm
    const filmStartMinutesString =
      filmStartMinutes > 9 ? filmStartMinutes.toLocaleString() : `0${filmStartMinutes}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterStartsWatchingAFilmAtTime(
          name,
          filmStartHour,
          filmStartMinutesString,
          filmDurationMinutes,
          homeworkEnd
        )}
        testCorrect={[homeworkDuration.toString()]}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        sentence={`<ans/> ${translate.timePeriod.minutes(homeworkDuration)}`}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithTime',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
