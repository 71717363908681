import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { displayMoney, moneyToHighestDenominations, numToCurrency } from '../../../../utils/money';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  filledArray,
  range,
  sumNumberArray
} from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bij',
  description: 'bij',
  keywords: ['Money', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z.object({
    correctPounds: z.number().int().min(1).max(99),
    correctPence: z.number().int().min(1).max(99),
    isAnswerShown: z.boolean(),
    items: z.array(
      z.object({
        value: z.enum(['A', 'B', 'C', 'D']),
        combinationOfMoney: z.array(z.string())
      })
    )
  }),
  simpleGenerator: () => {
    const { items, correctPounds, correctPence } = rejectionSample(
      () => {
        const correctPounds = randomIntegerInclusive(1, 99);
        const correctPence = randomIntegerInclusive(1, 99);

        const incorrectPounds = filledArray(randomIntegerInclusive(1, 99), 3);
        const incorrectPence = randomUniqueIntegersInclusive(1, 99, 3, {
          constraint: x => x !== correctPence
        });

        const correctItem = {
          value: 'A' as const,
          combinationOfMoney: [
            ...moneyToHighestDenominations(correctPounds, 'pounds'),
            ...moneyToHighestDenominations(correctPence, 'pence')
          ],
          total: correctPounds * 100 + correctPence
        };

        const incorrectItems = ['B' as const, 'C' as const, 'D' as const].map((value, index) => {
          return {
            value,
            combinationOfMoney: [
              ...moneyToHighestDenominations(incorrectPounds[index], 'pounds'),
              ...moneyToHighestDenominations(incorrectPence[index], 'pence')
            ],
            total: incorrectPounds[index] * 100 + incorrectPence[index]
          };
        });

        const items = shuffle([correctItem, ...incorrectItems]);

        return { items, correctPounds, correctPence };
      },
      ({ items }) =>
        items.every(item => item.combinationOfMoney.length <= 8) &&
        arrayHasNoDuplicates(items.map(({ total }) => total))
    );

    const isAnswerShown = getRandomBoolean();

    return {
      correctPounds,
      correctPence,
      isAnswerShown,
      items
    };
  },
  Component: props => {
    const {
      question: { correctPounds, correctPence, isAnswerShown, items },
      translate,
      displayMode
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={
          isAnswerShown
            ? translate.ks1Instructions.selectThePictureThatShowsPoundXAndYPence(
                correctPounds,
                correctPence
              )
            : translate.ks1Instructions.selectThePicturesThatDoNotShowPoundXAndYPence(
                correctPounds,
                correctPence
              )
        }
        pdfTitle={
          isAnswerShown
            ? translate.ks1PDFInstructions.tickTheBoxThatShowsPoundXAndYPence(
                correctPounds,
                correctPence
              )
            : translate.ks1PDFInstructions.tickTheBoxesThatDoNotShowPoundXAndYPence(
                correctPounds,
                correctPence
              )
        }
        testCorrect={userAnswer =>
          isAnswerShown
            ? userAnswer[0] === 'A'
            : arraysHaveSameContentsUnordered(userAnswer, ['B', 'C', 'D'])
        }
        numItems={4}
        multiSelect={isAnswerShown ? false : true}
        customMarkSchemeAnswer={{ answerToDisplay: isAnswerShown ? ['A'] : ['B', 'C', 'D'] }}
        renderItems={() => {
          // Scaling for the coins
          const combinations = items.map(el => el.combinationOfMoney).flat();
          const money = displayMoney(
            combinations,
            displayMode === 'digital' ? 65 : 120,
            displayMode === 'digital' ? 65 : 120,
            true
          );

          return items.map((item, index) => {
            const calculateStartIndex = (
              items: { value: 'A' | 'B' | 'C' | 'D'; combinationOfMoney: string[] }[],
              index: number
            ): number =>
              items
                .slice(0, index)
                .reduce((sum, { combinationOfMoney }) => sum + combinationOfMoney.length, 0);

            const indexes = range(
              calculateStartIndex(items, index),
              calculateStartIndex(items, index) + item.combinationOfMoney.length - 1
            );

            return {
              value: item.value,
              component: (
                <View
                  style={{
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 12,
                    padding: 8
                  }}
                >
                  {money.filter((_, index) => indexes.includes(index))}
                </View>
              )
            };
          });
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bik',
  description: 'bik',
  keywords: ['Notes', 'Coins', 'Pounds', 'Pence', 'Money', 'Total'],
  schema: z.object({
    pounds: z
      .number()
      .int()
      .min(5)
      .max(38)
      .refine(pounds => pounds % 10 !== 4 && pounds % 10 !== 9),
    pence: z
      .number()
      .int()
      .min(5)
      .max(85)
      .step(5)
      .refine(pounds => Math.floor(pounds / 10) !== 4),
    moneyObjects: z.array(
      z.object({
        currency: z.enum(['5p', '10p', '20p', '50p', '£1', '£2', '£5', '£10', '£20']),
        value: numberEnum([5, 10, 20, 50, 100, 200, 500, 1000, 2000])
      })
    )
  }),
  simpleGenerator: () => {
    const { pounds, pence } = rejectionSample(
      () => {
        const pounds = randomIntegerInclusive(5, 38, {
          constraint: x => x % 10 !== 4 && x % 10 !== 9
        });

        const pence = randomIntegerInclusiveStep(5, 85, 5, {
          constraint: x => Math.floor(x / 10) !== 4
        });
        return { pounds, pence };
      },

      ({ pounds, pence }) =>
        arrayHasNoDuplicates(moneyToHighestDenominations(pounds, 'pounds')) &&
        arrayHasNoDuplicates(moneyToHighestDenominations(pence, 'pence'))
    );

    const moneyObjects = shuffle([
      { currency: '10p' as const, value: 10 as const },
      { currency: '£1' as const, value: 100 as const },
      { currency: '£10' as const, value: 1000 as const },
      { currency: '20p' as const, value: 20 as const },
      { currency: '£2' as const, value: 200 as const },
      { currency: '£20' as const, value: 2000 as const },
      { currency: '5p' as const, value: 5 as const },
      { currency: '50p' as const, value: 50 as const },
      { currency: '£5' as const, value: 500 as const }
    ]);

    return {
      pounds,
      pence,
      moneyObjects
    };
  },

  Component: props => {
    const {
      question: { pounds, pence, moneyObjects },
      translate,
      locale
    } = props;

    const items = moneyObjects.map(({ value, currency }) => {
      return {
        value: value,
        component: displayMoney([currency], value > 200 ? 170 : 80, 80)[0],
        currency
      };
    });

    const moneyString = numToCurrency({ amount: pounds + pence / 100, locale });

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectNotesAndCoinsToMakeXPoundsAndYPence(pounds, pence)}
        pdfTitle={translate.ks1PDFInstructions.circleXPoundsAndYPence(pounds, pence)}
        testCorrect={userAnswer =>
          moneyString ===
          numToCurrency({
            amount: sumNumberArray(userAnswer.map(num => Number(num))) / 100,
            locale
          })
        }
        multiSelect
        items={items.map(selectable => ({
          value: selectable.value,
          component: selectable.component as JSX.Element
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            ...moneyToHighestDenominations(pence, 'pence'),
            ...moneyToHighestDenominations(pounds, 'pounds')
          ].flatMap(currency => {
            const moneyObject = moneyObjects.find(obj => obj.currency === currency);
            if (!moneyObject) {
              console.error("moneyToHighestDenominations produced a value that wasn't present");
              return [];
            }
            return [moneyObject.value];
          })
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ChooseNotesAndCoins',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
