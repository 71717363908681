import { z } from 'zod';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { DIV } from '../../../../constants';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aN2',
  description: 'aN2',
  keywords: ['Place value chart', 'Decimals', 'Divide by 10', '2-digit', 'Multiples of 10'],
  schema: z.object({
    number: z.number().min(10).max(90).step(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10, 90, 10);

    return { number };
  },
  Component: ({ question: { number }, translate, displayMode }) => {
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragTheCountersToShowAnswer(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(number / 10)}
        columnsToShow={[1, 0, -1]}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aN3',
  description: 'aN3',
  keywords: ['Place value chart', 'Decimals', 'Divide by 10', '2-digit'],
  schema: z.object({
    number: z.number().min(11).max(99)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });

    return { number };
  },
  Component: ({ question: { number }, translate, displayMode }) => {
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragTheCountersToShowTheAnswer(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToShowAnswer(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(number / 10)}
        columnsToShow={[1, 0, -1]}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aN4',
  description: 'aN4',
  keywords: ['Part-whole', 'Decimals', 'Divide by 10', '2-digit'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;
    const number = ones + tens * 10;
    return (
      <QF1ContentAndSentences
        sentences={[
          `${(tens * 10).toLocaleString()} ${DIV} 10 = <ans />`,
          `${ones.toLocaleString()} ${DIV} 10 = <ans />`,
          `${number.toLocaleString()} ${DIV} 10 = <ans />`
        ]}
        title={translate.instructions.usePartWholeModelToDivide(
          number.toLocaleString(),
          (10).toLocaleString()
        )}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], tens.toString()) &&
          compareFloats(userAnswer[1][0], (ones / 10).toString()) &&
          compareFloats(userAnswer[2][0], (number / 10).toString())
        }
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        sentenceStyle={{ minWidth: 300, justifyContent: 'flex-end' }}
        inputMaxCharacters={3}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => {
          return (
            <PartWholeModel
              top={number}
              partition={[ones, tens * 10]}
              dimens={{ width: dimens.width, height: dimens.height }}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aN5',
  description: 'aN5',
  keywords: ['Gattegno', 'Decimals', 'Divide by 2', '2-digit'],
  schema: z.object({
    number: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const sci = ScientificNotation.fromNumber(number);

    const tens = sci.digitAt('tens') * 10;
    const ones = sci.digitAt('ones');

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number / 10}
        rowsToShow={[2, 1, 0, -1]}
        preshaded={[tens, ones]}
        title={`${translate.instructions.xIsShownOnGattegnoChart(
          number.toLocaleString()
        )}<br/>${translate.instructions.useGattegnotoShowTheAnswer(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aN6',
  description: 'aN6',
  keywords: ['Decimals', 'Divide by 10', '2-digit'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number2: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99),
    number4: z.number().int().min(11).max(99)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99);
    const [number2, number3, number4] = randomUniqueIntegersInclusive(11, 99, 3, {
      constraint: x => x % 10 !== 0 && x !== number1
    });

    return { number1, number2, number3, number4 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // Sentences
    const sentences = [
      `${number1} ${DIV} 10 = <ans/>`,
      `${number2} ${DIV} 10 = <ans/>`,
      `<ans/> ${DIV} 10 = ${number3 / 10}`,
      `${number4 / 10} = <ans/> ${DIV} 10`
    ];

    // Answers
    const ans1 = number1 / 10;
    const ans2 = number2 / 10;
    const ans3 = number3;
    const ans4 = number4;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3) &&
          compareFloats(userAnswer[3][0], ans4)
        }
        inputMaxCharacters={3}
        sentences={sentences}
        extraSymbol="decimalPoint"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()],
            [ans4.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aN7',
  description: 'aN7',
  keywords: ['Decimals', 'Divide by 10', '2-digit'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number2: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99),
    number4: z.number().int().min(11).max(99),
    number5: z.number().int().min(1).max(9),
    number6: z.number().int().min(11).max(99),
    number7: z.number().int().min(1).max(8),
    number9: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const number3 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const number4 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const number5 = randomIntegerInclusive(1, 9);
    const number6 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const number7 = randomIntegerInclusive(1, 8);
    const number9 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    return { number1, number2, number3, number4, number5, number6, number7, number9 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, number7, number9 },
      translate
    } = props;

    const number8 = number7 + 1;

    const sentences = [
      {
        sentence: `${number1.toLocaleString()} ${DIV} ${(10).toLocaleString()} <ans/> ${number2.toLocaleString()} ${DIV} ${(10).toLocaleString()}`,
        answer: number1 > number2 ? '>' : number1 === number2 ? '=' : '<'
      },
      {
        sentence: `${number3.toLocaleString()} ${DIV} ${(10).toLocaleString()} <ans/> ${number4.toLocaleString()} ${DIV} ${(10).toLocaleString()}`,
        answer: number3 > number4 ? '>' : number3 === number4 ? '=' : '<'
      },
      {
        sentence: `${translate.misc.double()} ${(
          number5 / 10
        ).toLocaleString()} <ans/> ${number6.toLocaleString()} ${DIV} ${(10).toLocaleString()}`,
        answer: number5 * 2 > number6 ? '>' : number5 * 2 === number6 ? '=' : '<'
      },
      {
        sentence: `${translate.answerSentences.halfwayBetween(
          number7.toLocaleString(),
          number8.toLocaleString()
        )} <ans/> ${number9.toLocaleString()} ${DIV} ${(10).toLocaleString()}`,
        answer: number7 * 10 + 5 > number9 ? '>' : number7 * 10 + 5 === number9 ? '=' : '<'
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useInequalitySymbolsToMakeStatementsCorrect()}
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        moveOrCopy="copy"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberBy10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
