import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  createShapeWithSquares,
  createRectangleFromSquares,
  trueCount,
  createGridAroundShape,
  reflectShapeOnX,
  reflectShapeOnY,
  rotateShape90,
  createRectilinearShapeWithSquaresAndTriangles
} from '../../../../utils/shapes';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afo',
  description: 'afo',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shape: z.boolean().array().length(5).array().length(4)
  }),
  simpleGenerator: () => {
    const shape = createShapeWithSquares(4, 5, randomIntegerInclusive(6, 11), true);
    return { shape };
  },
  Component: ({ question: { shape }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.countSquaresToFindArea()}
        sentence={translate.answerSentences.theAreaIsAnsSquares()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} noGrid />}
        testCorrect={[trueCount(shape).toString()]}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'afp',
  description: 'afp',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shape: z.boolean().array().length(5).array().length(4)
  }),
  simpleGenerator: () => {
    const shape = createShapeWithSquares(4, 5, randomIntegerInclusive(6, 15), true);
    return { shape };
  },
  Component: ({ question: { shape }, translate, ...props }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsAreaOfShadedShape()}
        sentence={translate.answerSentences.theAreaIsAnsSquares()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        testCorrect={[trueCount(shape).toString()]}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afq',
  description: 'afq',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    row: z.number().int().min(2).max(4),
    col: z.number().int().min(2).max(6),
    gridShape: z.boolean().array().length(6).array().length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const row = randomIntegerInclusive(2, 4);
    const col = (() => {
      switch (row) {
        case 2:
          return randomIntegerInclusive(4, 5);
        case 3:
          return randomIntegerInclusive(3, 6);
        default:
          return randomIntegerInclusive(2, 6);
      }
    })();

    const shape = createRectangleFromSquares(col, row);
    const gridShape = createGridAroundShape([6, 4], shape, 'center');

    return { row, col, gridShape };
  },
  Component: ({ question: { row, col, gridShape }, translate }) => {
    const areaOfShapeAnswer =
      row === col ? 'theAreaOfTheSquareIsAns' : 'theAreaOfTheRectangleIsAns';

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        sentence={`${translate.answerSentences.thereAreAnsRowsAndAnsColumns()}<br/>${translate.answerSentences[
          areaOfShapeAnswer
        ]()} `}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={gridShape} dimens={dimens} noGrid />
        )}
        testCorrect={[row.toString(), col.toString(), (row * col).toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'afr',
  description: 'afr',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shape: z.boolean().array().length(7).array().length(6)
  }),
  simpleGenerator: () => {
    const col = randomIntegerInclusive(2, 5);
    const row = (() => {
      switch (col) {
        case 2:
          return randomIntegerInclusive(3, 4);
        case 3:
          return randomIntegerInclusive(2, 4);
        case 4:
          return randomIntegerInclusive(2, 4);
        default:
          return randomIntegerInclusive(2, 4);
      }
    })();

    const rectangle = createRectangleFromSquares(col, row);
    const shape = createGridAroundShape([7, 6], rectangle, 'random', 1, 1);

    return { shape };
  },
  Component: ({ question: { shape }, translate, ...props }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.findArea()}
        sentence={translate.answerSentences.areaIsAnsSquares()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        testCorrect={[trueCount(shape).toString()]}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'afs',
  description: 'afs',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shape: z.boolean().array().length(4).array().length(4),
    isSame: z.boolean(),
    manipulatedType: z.enum(['first', 'second', 'reflectedX', 'reflectedY', 'rotated'])
  }),
  simpleGenerator: () => {
    const shape = createShapeWithSquares(4, 4, randomIntegerInclusive(4, 10), true);
    const isSame = getRandomBoolean();

    const manipulatedType = isSame
      ? getRandomFromArray(['reflectedX', 'reflectedY', 'rotated'] as const)
      : getRandomFromArray(['first', 'second'] as const);

    return { shape, isSame, manipulatedType };
  },
  Component: ({ question, translate }) => {
    const { shape, isSame, manipulatedType } = question;

    const manipulateShape = (origShape: boolean[][]) => {
      switch (manipulatedType) {
        case 'reflectedX':
          return reflectShapeOnX(origShape);
        case 'reflectedY':
          return reflectShapeOnY(origShape);
        default: //rotated
          return rotateShape90(origShape);
      }
    };
    const scaleDownDimens = (dimens: Dimens): Dimens => {
      const scale = 0.7;
      return {
        width: dimens.width * scale,
        height: dimens.height * scale
      };
    };

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalse()}
        pdfTitle={translate.instructions.isStatementTrueOrFalsePDF()}
        content={({ dimens }) => (
          <View style={{ flexDirection: 'row', columnGap: 50 }}>
            <DisplayShapeOnGrid
              givenShape={shape}
              dimens={manipulatedType === 'first' ? scaleDownDimens(dimens) : dimens}
              noGrid
            />
            <DisplayShapeOnGrid
              givenShape={isSame ? manipulateShape(shape) : shape}
              dimens={manipulatedType === 'second' ? scaleDownDimens(dimens) : dimens}
              noGrid
            />
          </View>
        )}
        sentence={translate.answerSentences.theShapesHaveTheSameArea()}
        correctAnswer={isSame}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aft',
  description: 'aft',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    squares: z.number().int().min(5).max(12),
    triangles: z.number().int().min(2).max(6).step(2)
  }),
  simpleGenerator: () => {
    const squares = randomIntegerInclusive(5, 12);
    const triangles = randomIntegerInclusiveStep(2, 6, 2);

    return { squares, triangles };
  },
  Component: ({ question: { squares, triangles }, translate }) => {
    const random = seededRandom({ squares, triangles });
    const shape = createRectilinearShapeWithSquaresAndTriangles(4, 5, squares, triangles, {
      random
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsAreaOfShape()}
        sentence={translate.answerSentences.theAreaIsAnsSquares()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        testCorrect={[(squares + triangles / 2).toString()]}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountSquares',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
