import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { filledArray, range, sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { compareFloats, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { createHundredSquareShape } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRw',
  description: 'aRw',
  keywords: ['Percentages', 'Decimals', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numbers = [filledArray(1, 10), filledArray(1, 10)];
    const strings = [
      range(1, 10).map(() => `${(0.1).toLocaleString()}`),
      range(1, 10).map(() => `${(10).toLocaleString()}%`)
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToCompleteTheConversion()}
        testCorrect={[(number * 10).toString()]}
        sentence={`${(number / 10).toLocaleString()} = <ans/>%`}
        sentenceStyle={{ alignSelf: 'center' }}
        Content={({ dimens }) => (
          <BarModel
            total={10}
            oneFontSize
            dimens={dimens}
            numbers={numbers}
            strings={strings}
            sameRowColor
            topBraceText={`${(1).toLocaleString()}`}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRx',
  description: 'aRx',
  keywords: ['Percentages', 'Decimals', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numbers = [filledArray(1, 10), filledArray(1, 10)];
    const strings = [
      range(1, 10).map(() => `${(0.01).toLocaleString()}`),
      range(1, 10).map(() => `${(1).toLocaleString()}%`)
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToCompleteTheConversion()}
        testCorrect={[number.toString()]}
        sentence={`${(number / 100).toLocaleString()} = <ans/>%`}
        sentenceStyle={{ alignSelf: 'center' }}
        Content={({ dimens }) => (
          <BarModel
            total={10}
            oneFontSize
            dimens={dimens}
            numbers={numbers}
            strings={strings}
            sameRowColor
            topBraceText={`${(0.1).toLocaleString()}`}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRy',
  description: 'aRy',
  keywords: ['Percentages', 'Decimals', 'Hundred square'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusive(1, 99);

    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shape = createHundredSquareShape(numberOfShadedSquares);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theHundredSquareRepresents1WholeWorkOutTheDecimalAndPercentage()}
        sentences={[
          translate.answerSentences.decimalEqualsAns(),
          translate.answerSentences.percentageEqualsAnsPercent()
        ]}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], numberOfShadedSquares / 100) &&
          compareFloats(userAnswer[1][0], numberOfShadedSquares)
        }
        Content={({ dimens }) => (
          <View>
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(numberOfShadedSquares / 100).toLocaleString()],
            [numberOfShadedSquares.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRz',
  description: 'aRz',
  keywords: ['Percentages', 'Decimals', 'Equivalent'],
  schema: z.object({
    decimalA: z.number().int().min(1).max(99),
    decimalB: z.number().int().min(1).max(99),
    percentageA: z.number().int().min(1).max(99)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [decimalA, decimalB, percentageA] = randomUniqueIntegersInclusive(1, 99, 3);
    return { decimalA, decimalB, percentageA };
  },

  Component: props => {
    const {
      question: { decimalA, decimalB, percentageA },
      translate
    } = props;

    const sentences = shuffle(
      [
        { sentence: `${(decimalA / 100).toLocaleString()} = <ans/>%`, answer: decimalA },
        { sentence: `${(decimalB / 100).toLocaleString()} = <ans/>%`, answer: decimalB },
        { sentence: `<ans/> = ${percentageA.toLocaleString()} %`, answer: percentageA / 100 }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheEquivalentDecimalsAndPercentages()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer) &&
          compareFloats(userAnswer[2][0], sentences[2].answer)
        }
        sentences={sentences.map(el => el.sentence)}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: sentences.map(({ answer }) => [answer.toLocaleString()]),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRA',
  description: 'aRA',
  keywords: ['Percentages', 'Decimals', 'Compare'],
  schema: z.object({
    percentageA: z.number().int().min(11).max(100),
    decimalA: z.number().int().min(11).max(100),
    percentageB: z.number().int().min(10).max(100).step(10),
    decimalB: z.number().int().min(1).max(90),
    percentageC: z.number().int().min(1).max(9),
    decimalC: z.number().int().min(1).max(90),
    showPercentageFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const percentageA = randomIntegerInclusive(11, 100);
    const decimalA = randomIntegerInclusive(11, 100);
    const percentageB = randomIntegerInclusiveStep(10, 100, 10);
    const decimalB = getRandomFromArray([
      randomIntegerInclusive(1, 10),
      randomIntegerInclusiveStep(10, 90, 10)
    ]);
    const percentageC = randomIntegerInclusive(1, 9);
    const decimalC = getRandomFromArray([
      randomIntegerInclusive(1, 10),
      randomIntegerInclusiveStep(10, 90, 10)
    ]);

    const showPercentageFirst = getRandomBoolean();

    return {
      percentageA,
      decimalA,
      percentageB,
      decimalB,
      percentageC,
      decimalC,
      showPercentageFirst
    };
  },
  Component: props => {
    const {
      question: {
        percentageA,
        decimalA,
        percentageB,
        decimalB,
        percentageC,
        decimalC,
        showPercentageFirst
      },
      translate
    } = props;

    const statements = [
      {
        lhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'right', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${percentageA.toLocaleString()}%`
                : `${(decimalA / 100).toLocaleString()}`
            }
          />
        ),
        rhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'left', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${(decimalA / 100).toLocaleString()} `
                : `${percentageA.toLocaleString()}%`
            }
          />
        ),
        correctAnswer: showPercentageFirst
          ? lessThanGreaterThanOrEqualTo(percentageA, decimalA)
          : lessThanGreaterThanOrEqualTo(decimalA, percentageA)
      },
      {
        lhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'right', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${percentageB.toLocaleString()}%`
                : `${(decimalB / 100).toLocaleString()}`
            }
          />
        ),
        rhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'left', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${(decimalB / 100).toLocaleString()} `
                : `${percentageB.toLocaleString()}%`
            }
          />
        ),
        correctAnswer: showPercentageFirst
          ? lessThanGreaterThanOrEqualTo(percentageB, decimalB)
          : lessThanGreaterThanOrEqualTo(decimalB, percentageB)
      },
      {
        lhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'right', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${percentageC.toLocaleString()}%`
                : `${(decimalC / 100).toLocaleString()}`
            }
          />
        ),
        rhsComponent: (
          <TextStructure
            textStyle={{ textAlign: 'left', width: 100 }}
            sentence={
              showPercentageFirst
                ? `${(decimalC / 100).toLocaleString()} `
                : `${percentageC.toLocaleString()}%`
            }
          />
        ),
        correctAnswer: showPercentageFirst
          ? lessThanGreaterThanOrEqualTo(percentageC, decimalC)
          : lessThanGreaterThanOrEqualTo(decimalC, percentageC)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        itemVariant="square"
        statements={statements}
        items={['>', '<', '=']}
        statementStyle={{ justifyContent: 'center' }}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aRB',
  description: 'aRB',
  keywords: ['Order', 'Decimals', 'Percentages'],
  schema: z.object({
    percentageA: z.number().int().min(1).max(99),
    decimalA: z.number().int().min(1).max(99),
    percentageB: z.number().int().min(1).max(99),
    decimalB: z.number().int().min(1).max(99),
    order: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const [percentageA, decimalA, percentageB, decimalB] = randomUniqueIntegersInclusive(1, 99, 4);
    const order = getRandomFromArray(['ascending', 'descending'] as const);

    return { percentageA, decimalA, percentageB, decimalB, order };
  },
  Component: props => {
    const {
      question: { percentageA, decimalA, percentageB, decimalB, order },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: percentageA,
          string: `${percentageA.toLocaleString()}%`
        },
        {
          value: decimalA,
          string: `${(decimalA / 100).toLocaleString()}`
        },
        {
          value: percentageB,
          string: `${percentageB.toLocaleString()}%`
        },
        {
          value: decimalB,
          string: `${(decimalB / 100).toLocaleString()}`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          order === 'ascending'
            ? translate.instructions.dragCardsOrderValuesSmallestToGreatest()
            : translate.instructions.dragCardsOrderValuesGreatestToSmallest()
        }
        pdfTitle={
          order === 'ascending'
            ? translate.instructions.useCardsOrderValuesSmallestToGreatest()
            : translate.instructions.useCardsOrderValuesGreatestToSmallest()
        }
        testCorrect={sortNumberArray([percentageA, decimalA, percentageB, decimalB], order)}
        items={items.map(({ value, string }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        topLabel={
          order === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          order === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PercentagesAsDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
