import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { ADD, SUB } from 'common/src/constants';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom
} from 'common/src/utils/random';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { findCommonMultiples } from 'common/src/utils/multiples';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  fractionArithmetic,
  fractionToDecimal,
  improperFractionToMixedNumber,
  simplify
} from 'common/src/utils/fractions';
import TextStructure from 'common/src/components/molecules/TextStructure';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import ContentBox from 'common/src/components/molecules/ContentBox';
import { filledArray } from 'common/src/utils/collections';
import { barModelColors } from 'common/src/theme/colors';
import { lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { findFactors } from '../../../../utils/factors';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { isPrime } from '../../../../utils/primes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqD',
  description: 'aqD',
  keywords: ['Addition', 'Common denominator', 'Fractions'],
  schema: z
    .object({
      number2: z.number().int().min(2).max(5),
      number3: z.number().int().min(1).max(4),
      number4: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.number4 + val.number2 * val.number3 < val.number2 * val.number4,
      'number1/number2 and number3/number4 must sum to less than 1'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    // number1 === 1
    const { number2, number3, number4 } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(2, 5);
        const number4 = randomIntegerInclusive(2, 5, {
          // Need to make sure that the denominators are not equivalent and do not produce fractions where only one needs to be changed:
          constraint: x => x !== number2 && x % number2 !== 0 && number2 % x !== 0
        });
        const number3 = randomIntegerInclusive(1, number4 - 1);

        return { number2, number3, number4 };
      },
      ({ number2, number3, number4 }) =>
        number4 + number2 * number3 < number2 * number4 && number2 !== number4
    );

    return { number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number2, number3, number4 },
      translate,
      displayMode
    } = props;

    const answer1 = number4 + number2 * number3;
    const answer2 = number2 * number4;

    // Simplify all fractions
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);
    const [simplifiedAns1, simplifiedAns2] = simplify(answer1, answer2);

    const [color1, color2] = getRandomSubArrayFromArray(Object.values(barModelColors), 2, {
      random: seededRandom({ answer1, answer2 })
    });

    const colors1 = filledArray(color1, 4);
    const colors2 = filledArray(color2, 3);
    const remainder = filledArray('white', 5);

    const customColorMap = [...colors1, ...colors2, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.useTheMethodBelowToCompleteCalc()}
        testCorrect={userAnswer => compareFractions(userAnswer, [simplifiedAns1, simplifiedAns2])}
        inputMaxCharacters={2}
        sentence={`<frac n='1' d='${number2}'/> ${ADD} <frac n='${simplifiedNum3}' d='${simplifiedNum4}'/> = <frac nAns='' dAns=''/>`}
        Content={
          <ContentBox containerStyle={{ justifyContent: 'space-evenly' }}>
            <ShadedFractionBarModel
              totalSubSections={12}
              customColorMap={customColorMap}
              width={displayMode === 'digital' ? 750 : 1500}
              height={displayMode === 'digital' ? undefined : 150}
            />
            <TextStructure
              sentence={`<frac n='1' d='3'/> ${ADD} <frac n='1' d='4'/> = <frac n='4' d='12'/> ${ADD} <frac n='3' d='12'/> = <frac n='7' d='12'/>`}
              fractionContainerStyle={{ height: 48 }}
            />
          </ContentBox>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [simplifiedAns1.toLocaleString(), simplifiedAns2.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqE',
  description: 'aqE',
  keywords: ['Addition', 'Subtraction', 'Common denominator', 'Fractions'],
  schema: z.object({
    addOrSubtract: z.enum([ADD, SUB]),
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(2).max(9),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const [number2, number4] = randomUniqueIntegersInclusive(2, 9, 2);

    // Ensure fractions don't sum to negative number
    let number1: number;
    let number3: number;

    if (addOrSubtract === SUB) {
      ({ number1, number3 } = rejectionSample(
        () => ({
          number1: randomIntegerInclusive(1, number2 - 1),
          number3: randomIntegerInclusive(1, number4 - 1)
        }),
        ({ number1, number3 }) => number3 / number4 < number1 / number2
      ));
    } else {
      number1 = randomIntegerInclusive(1, number2 - 1);
      number3 = randomIntegerInclusive(1, number4 - 1);
    }

    return { addOrSubtract, number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { addOrSubtract, number1, number2, number3, number4 },
      translate
    } = props;

    // Create list of first 20 common multiples between the 2 numbers
    const commonMultiples = findCommonMultiples(number2, number4, 1);

    // Pass into question sentence
    const sentence = addOrSubtract === ADD ? 'add' : 'subtract';

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatCommonDenominatorToUse(sentence)}
        testCorrect={userAnswer => parseInt(userAnswer[0]) % commonMultiples[0] === 0}
        inputMaxCharacters={2}
        sentence="<ans/>"
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={() => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TextStructure
              sentence={`<frac n='${number1}' d='${number2}'/> ${addOrSubtract} <frac n='${number3}' d='${number4}'/>`}
              style={undefined}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [commonMultiples[0].toLocaleString()],
          answerText: translate.markScheme.otherCommonDenominatorsAreAlsoAcceptable()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aqF',
  description: 'aqF',
  keywords: ['Addition', 'Subtraction', 'Common denominator', 'Fractions'],
  schema: z.object({
    addOrSubtract: z.enum([ADD, SUB]),
    number1: z.number().int().min(1).max(15),
    number2: z.number().int().min(2).max(15),
    number3: z.number().int().min(1).max(15),
    number4: z.number().int().min(2).max(15)
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const [number2, number4] = randomUniqueIntegersInclusive(2, 15, 2);

    // Ensure fractions sum < 1 and > 0
    const { number1, number3 } =
      addOrSubtract === ADD
        ? rejectionSample(
            () => {
              const number1 = randomIntegerInclusive(1, number2 - 1);
              const number3 = randomIntegerInclusive(1, number4 - 1);

              return { number1, number3 };
            },
            ({ number1, number3 }) => number3 / number4 + number1 / number2 < 1
          )
        : rejectionSample(
            () => {
              const number1 = randomIntegerInclusive(1, number2 - 1);
              const number3 = randomIntegerInclusive(1, number4 - 1);

              return { number1, number3 };
            },
            ({ number1, number3 }) => number3 / number4 < number1 / number2
          );

    return { addOrSubtract, number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { addOrSubtract, number1, number2, number3, number4 },
      translate
    } = props;

    const number5 =
      addOrSubtract === ADD
        ? number1 * number4 + number3 * number2
        : number1 * number4 - number3 * number2;
    const number6 = number2 * number4;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    const [answer1, answer2] = simplify(number5, number6);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[answer1.toString(), answer2.toString()]}
        sentence={`<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/> ${addOrSubtract} <frac n='${simplifiedNum3}' d='${simplifiedNum4}'/> = <frac nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqG',
  description: 'aqG',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(19),
    number2: z.number().int().min(14).max(20),
    number4: z.number().int().min(3).max(8)
  }),
  questionHeight: 1440,
  simpleGenerator: () => {
    // The 2 fractions being displayed must be less than 1, otherwise answer impossible
    const { number1, number2, number4 } = rejectionSample(
      () => {
        // Ensure it isn't a prime otherwise number4 is impossible
        const number2 = randomIntegerInclusive(14, 20, {
          constraint: x => !isPrime(x)
        });
        const number1 = randomIntegerInclusive(2, 19, {
          constraint: x => x < number2
        });

        const number4 = randomIntegerInclusive(3, 8, {
          constraint: x => findFactors(number2).includes(x)
        });

        return { number1, number2, number4 };
      },
      ({ number1, number2, number4 }) => number1 * number4 + number2 < number2 * number4
    );

    return { number1, number2, number4 };
  },
  Component: ({ question: { number1, number2, number4 }, translate }) => {
    const number3 = 1;

    // Simplify to display
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);

    // Calculate answer
    const [leftoverNum, leftoverDenom] = fractionArithmetic(
      [number1, number2],
      [number3, number4],
      ADD
    );
    const [simplifiedAns1, simplifiedAns2] = simplify(leftoverDenom - leftoverNum, leftoverDenom);

    const numbers = [[1], [number1 / number2, number3 / number4, simplifiedAns1 / simplifiedAns2]];
    const strings = [
      [],
      [
        `<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/>`,
        `<frac n='${number3}' d='${number4}'/>`,
        '?'
      ]
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1440}
        title={translate.instructions.workOutTheMissingFractionInTheBarModel()}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        inputMaxCharacters={2}
        sentence={`<frac nAns='' dAns=''/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        Content={({ dimens }) => (
          <BarModel total={1} numbers={numbers} strings={strings} dimens={dimens} />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.doNotAcceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqH',
  description: 'aqH',
  keywords: ['Addition', 'Improper fraction', 'Mixed number', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(8),
    number2: z.number().int().min(3).max(9),
    number3: z.number().int().min(4).max(10),
    number4: z.number().int().min(3).max(9)
  }),
  simpleGenerator: () => {
    const [number2, number4] = randomUniqueIntegersInclusive(3, 9, 2);

    const number1 = number2 - 1;
    const number3 = number4 + 1;

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const numeratorSum = number3 * number2 + number1 * number4;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    const [answer1, answer2] = simplify(numeratorSum, number2 * number4);
    const [mixedAns1, mixedAns2, mixedAns3] = improperFractionToMixedNumber(answer1, answer2);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAdditionConvertImproperAndMixedFractionsSimplest()}
        testCorrect={[
          answer1.toString(),
          answer2.toString(),
          mixedAns1.toString(),
          mixedAns2.toString(),
          mixedAns3.toString()
        ]}
        sentence={`<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/> ${ADD} <frac n='${simplifiedNum3}' d='${simplifiedNum4}'/> = <frac nAns='' dAns=''/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionsMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqI',
  description: 'aqI',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Compare'],
  schema: z
    .object({
      number1: z.number().int(),
      number2: z.number().int().min(3).max(9),
      number3: z.number().int().min(1).max(5),
      number4: z.number().int().min(4).max(10),
      number5: z.number().int().min(1).max(9),
      number6: z.number().int().min(1).max(8)
    })
    .refine(val => val.number2 < val.number4, 'number2 must be less than number4')
    .refine(val => val.number3 < val.number4, 'number3 must be less than number4')
    .refine(val => val.number5 < val.number4, 'number5 must be less than number4')
    .refine(val => val.number6 < val.number2, 'number6 must be less than number2'),
  simpleGenerator: () => {
    return rejectionSample(
      () => {
        const number1 = 1;
        const number4 = randomIntegerInclusive(4, 10);
        const number2 = randomIntegerInclusive(3, number4 - 1);
        const number3 = randomIntegerInclusive(1, 5, {
          constraint: x => x < number4
        });
        const number5 = randomIntegerInclusive(1, number4 - 1);
        const number6 = randomIntegerInclusive(1, number2 - 1);

        return { number1, number2, number3, number4, number5, number6 };
      },
      val => val.number5 / val.number4 > val.number6 / val.number2
    );
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const [fraction1ANumerator, fraction1ADenominator] = simplify(number1, number2);
    const [fraction1BNumerator, fraction1BDenominator] = simplify(number3, number4);

    const [fraction2ANumerator, fraction2ADenominator] = simplify(number5, number4);
    const [fraction2BNumerator, fraction2BDenominator] = simplify(number6, number2);

    const checkAnswer = (obj: {
      frac1: { fracA: number; fracB: number };
      frac2: { fracA: number; fracB: number };
    }): string => {
      const { frac1, frac2 } = obj;

      const frac1Sum = frac1.fracA + frac1.fracB;
      const frac2Sum = frac2.fracA + frac2.fracB;

      // Compare fractions and return correct answer (>, < or =)
      const answer = lessThanGreaterThanOrEqualTo(frac1Sum, frac2Sum);

      return answer;
    };

    const sentences = [
      {
        sentence: `<frac n='${fraction1ANumerator}' d='${fraction1ADenominator}'/> ${ADD} <frac n='${fraction1BNumerator}' d='${fraction1BDenominator}'/> <ans /> <frac n='${fraction2ANumerator}' d='${fraction2ADenominator}'/> ${ADD} <frac n='${fraction2BNumerator}' d='${fraction2BDenominator}'/>`,
        answer: checkAnswer({
          frac1: {
            fracA: fractionToDecimal(fraction1ANumerator, fraction1ADenominator),
            fracB: fractionToDecimal(fraction1BNumerator, fraction1BDenominator)
          },
          frac2: {
            fracA: fractionToDecimal(fraction2ANumerator, fraction2ADenominator),
            fracB: fractionToDecimal(fraction2BNumerator, fraction2BDenominator)
          }
        })
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMakeStatementCorrect()}
        pdfTitle={translate.instructions.useCardsMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractAnyTwoFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
