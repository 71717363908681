import Text from 'common/src/components/typography/Text';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { aOrAnShapeAsWords, moreShapesAsWord } from '../../../../utils/shapes';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';
import {
  getshapesWithPerpendicularOrParalleLinesName,
  shapesWithParallelAndPerpendicularData,
  ShapesWitherpendicularOrParalelleLinesName
} from '../../../../utils/shapeImages/perpendicularParallel';
import {
  getIrregularShapeSvgName,
  getRandomIrregularShapeSvgNames
} from '../../../../utils/shapeImages/irregular';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  getQuadrilateralSvgName,
  quadrilateralColors
} from '../../../../utils/quadrilateralImages';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avm',
  description: 'avm',
  keywords: ['2-D', 'Shape', 'Regular'],
  schema: z.object({
    shapeNames: z
      .array(
        z.enum([
          'squares',
          'rectangles',
          'triangles',
          'circles',
          'hexagons',
          'pentagons',
          'heptagons',
          'nonagons',
          'octagons',
          'decagons'
        ])
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const shapeNames = getRandomSubArrayFromArray(
      [
        'squares',
        'rectangles',
        'triangles',
        'circles',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      4
    );
    return { shapeNames };
  },
  Component: props => {
    const {
      question: { shapeNames },
      translate,
      displayMode
    } = props;

    const statements = shapeNames.map(name => {
      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: 160 }}>
            <AssetSvg
              name={getShapeSvgName(name, props.question) as SvgName}
              height={displayMode === 'digital' ? 110 : 150}
            />
          </View>
        ),
        correctAnswer: moreShapesAsWord(name, translate, 1)
      };
    });

    const items = shapeNames.map(name => moreShapesAsWord(name, translate, 1));

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'avn',
  description: 'avn',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    answerShapeName: z.enum([
      'squares',
      'rectangles',
      'triangles',
      'circles',
      'hexagons',
      'pentagons',
      'octagons'
    ]),
    incorrectShapeNames: z
      .array(
        z.enum([
          'squares',
          'rectangles',
          'triangles',
          'circles',
          'hexagons',
          'pentagons',
          'octagons'
        ])
      )
      .length(3),
    answerSvg: z.string()
  }),
  simpleGenerator: () => {
    const isIrregular = getRandomBoolean();

    const [answerShapeName, ...incorrectShapeNames] = isIrregular
      ? [
          getRandomFromArray(['hexagons', 'pentagons', 'octagons'] as const),
          ...getRandomSubArrayFromArray(
            ['squares', 'rectangles', 'triangles', 'circles'] as const,
            3
          )
        ]
      : getRandomSubArrayFromArray(
          [
            'squares',
            'rectangles',
            'triangles',
            'circles',
            'hexagons',
            'pentagons',
            'octagons'
          ] as const,
          4
        );

    const answerSvg = isIrregular
      ? getIrregularShapeSvgName(answerShapeName as 'hexagons' | 'pentagons' | 'octagons')
      : getShapeSvgName(answerShapeName);

    return { answerShapeName, incorrectShapeNames, answerSvg };
  },
  Component: props => {
    const {
      question: { answerShapeName, incorrectShapeNames, answerSvg },
      translate
    } = props;

    const items = shuffle([answerShapeName, ...incorrectShapeNames], {
      random: seededRandom(props.question)
    });
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheNameOfTheShape()}
        pdfTitle={translate.instructions.circleTheNameOfTheShape()}
        testCorrect={[answerShapeName]}
        numItems={4}
        Content={<AssetSvg name={answerSvg as SvgName} height={200} width={200} />}
        renderItems={items.map(shapeName => ({
          value: shapeName,
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {moreShapesAsWord(shapeName, translate, 1)}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avo',
  description: 'avo',
  keywords: ['Shape', 'Quadrilaterals', 'Regular', 'Irregular'],
  schema: z.object({
    correctAnswerSvgNameA: z.string(),
    correctAnswerSvgNameB: z.string(),
    incorrectAnswerSvgNameA: z.string(),
    incorrectAnswerSvgNameB: z.string()
  }),
  simpleGenerator: () => {
    const [randomQuadsA, randomQuadsB] = getRandomSubArrayFromArray(
      [
        'Parallelogram',
        'Rectangle',
        'Rhombus',
        'Square',
        'Trapezium',
        'Trapezium4Arrows',
        'Kite'
      ] as const,
      2
    );
    const [colourA, colourB] = getRandomSubArrayFromArray(quadrilateralColors, 2);

    const correctAnswerSvgNameA = getQuadrilateralSvgName(randomQuadsA, colourA);
    const correctAnswerSvgNameB = getQuadrilateralSvgName(randomQuadsB, colourB);

    const [randomNonQuadA, randomNonQuadB] = getRandomSubArrayFromArray(
      [
        'triangles',
        'circles',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      2
    );

    const incorrectAnswerARegular = getShapeSvgName(randomNonQuadA);
    const incorrectAnswerBRegular = getShapeSvgName(randomNonQuadB);

    const [incorrectAnswerAIrregular, incorrectAnswerBIrregular] =
      getRandomIrregularShapeSvgNames(2);

    const [incorrectAnswerSvgNameA, incorrectAnswerSvgNameB] = [
      getRandomFromArray([incorrectAnswerARegular, incorrectAnswerAIrregular]),
      getRandomFromArray([incorrectAnswerBRegular, incorrectAnswerBIrregular])
    ];

    return {
      correctAnswerSvgNameA,
      correctAnswerSvgNameB,
      incorrectAnswerSvgNameA,
      incorrectAnswerSvgNameB
    };
  },
  Component: props => {
    const {
      question: {
        correctAnswerSvgNameA,
        correctAnswerSvgNameB,
        incorrectAnswerSvgNameA,
        incorrectAnswerSvgNameB
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          component: <AssetSvg name={correctAnswerSvgNameA as SvgName} width={150} height={150} />
        },
        {
          value: 'B',
          component: <AssetSvg name={correctAnswerSvgNameB as SvgName} width={150} height={150} />
        },
        {
          value: 'C',
          component: <AssetSvg name={incorrectAnswerSvgNameA as SvgName} width={150} height={150} />
        },
        {
          value: 'D',
          component: <AssetSvg name={incorrectAnswerSvgNameB as SvgName} width={150} height={150} />
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllQuadrilaterals()}
        pdfTitle={translate.instructions.circleAllQuadrilaterals()}
        testCorrect={['A', 'B']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avp',
  description: 'avp',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    correctAnswerSvgName: z.string(),
    incorrectAnswerSvgNames: z.array(z.string()).length(3),
    answerShape: z.enum(['pentagons', 'hexagons', 'octagons'])
  }),
  simpleGenerator: () => {
    const answerShape = getRandomFromArray(['pentagons', 'hexagons', 'octagons'] as const);
    const incorrectShapes = getRandomSubArrayFromArray(
      ['heptagons', 'nonagons', 'decagons', 'dodecagons'] as const,
      3
    );

    const correctAnswerSvgName = getIrregularShapeSvgName(answerShape);

    const incorrectAnswerSvgNames = incorrectShapes.map(name => getIrregularShapeSvgName(name));

    return {
      correctAnswerSvgName,
      incorrectAnswerSvgNames,
      answerShape
    };
  },
  Component: props => {
    const {
      question: { correctAnswerSvgName, incorrectAnswerSvgNames, answerShape },
      translate
    } = props;

    const [incorrectAnswersSvgNameA, incorrectAnswersSvgNameB, incorrectAnswersSvgNameC] =
      incorrectAnswerSvgNames;

    const items = shuffle(
      [
        {
          value: 'A',
          component: <AssetSvg name={correctAnswerSvgName as SvgName} width={150} height={150} />
        },
        {
          value: 'B',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameA as SvgName} width={150} height={150} />
          )
        },
        {
          value: 'C',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameB as SvgName} width={150} height={150} />
          )
        },
        {
          value: 'D',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameC as SvgName} width={150} height={150} />
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    const titleShape = aOrAnShapeAsWords(answerShape, translate);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeThatIsX(titleShape)}
        pdfTitle={translate.instructions.circleTheShapeThatIsX(titleShape)}
        testCorrect={['A']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'avq',
  description: 'avq',
  keywords: ['2-D', 'Shape', 'Regular'],
  schema: z.object({
    shapeNames: z.array(z.enum(['heptagons', 'nonagons', 'octagons', 'decagons']))
  }),
  simpleGenerator: () => {
    const shapeNames = shuffle(['heptagons', 'nonagons', 'octagons', 'decagons']) as [];

    return { shapeNames };
  },
  Component: props => {
    const {
      translate,
      displayMode,
      question: { shapeNames }
    } = props;

    const statements = shapeNames.map(name => {
      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: 160 }}>
            <AssetSvg
              style={{}}
              name={getShapeSvgName(name, props.question) as SvgName}
              height={displayMode === 'digital' ? 110 : 150}
            />
          </View>
        ),
        correctAnswer: moreShapesAsWord(name, translate, 1)
      };
    });

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = shapeNames.map(name => moreShapesAsWord(name, translate, 1));

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'avr',
  description: 'avr',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular', 'Parallel', 'Perpendicular'],
  schema: z.object({
    isAnswerParallel: z.boolean(),
    allAnswers: z.array(
      z.object({
        name: z.string(),
        isPerpendicular: z.boolean(),
        isParellel: z.boolean()
      })
    ),
    correctAnswerNames: z.array(z.string()),
    answersSvgNames: z.array(z.string())
  }),
  simpleGenerator: () => {
    const isAnswerParallel = getRandomBoolean();
    const numOfCorrectAnswers = randomIntegerInclusive(1, 3);

    // All shape objects that have perpendiculars lines only
    const isPerpendicular = shapesWithParallelAndPerpendicularData.filter(
      el => el.isPerpendicular && !el.isParellel
    );

    // All shape objects that have parallel lines only
    const isParallel = shapesWithParallelAndPerpendicularData.filter(
      el => el.isParellel && !el.isPerpendicular
    );

    // All shape objects have no parallel and perpendicular line because these shapes can be used for the incorrect answers.
    const shapesWithNoPerOrPar = shapesWithParallelAndPerpendicularData.filter(
      el => !el.isPerpendicular && !el.isParellel
    );

    // All shape objects that have parallel and perpendicular lines because these shapes have both we need to make sure they're only used for correct answers
    const shapesWithBothPerOrPar = shapesWithParallelAndPerpendicularData.filter(
      el => el.isPerpendicular && el.isParellel
    );

    // If answer is parallel get the correct answers from isParallel and shapesWithBothPerOrPar else get correct answers from isPerpendicular and shapesWithBothPerOrPar
    const correctAnswers = getRandomSubArrayFromArray(
      isAnswerParallel
        ? [...isParallel, ...shapesWithBothPerOrPar]
        : [...isPerpendicular, ...shapesWithBothPerOrPar],
      numOfCorrectAnswers
    );

    // If answer is parallel get the incorrect answers from isPerpendicular and shapesWithNoPerOrPar else get correct answers from isParallel and shapesWithNoPerOrPar
    const incorrectAnswers = getRandomSubArrayFromArray(
      isAnswerParallel
        ? [...isPerpendicular, ...shapesWithNoPerOrPar]
        : [...isParallel, ...shapesWithNoPerOrPar],
      4 - numOfCorrectAnswers
    );

    const allAnswers = [...correctAnswers, ...incorrectAnswers];

    const correctAnswerNames = correctAnswers.map(ans => ans.name);

    const answersSvgNames = allAnswers.map(({ name }) =>
      getshapesWithPerpendicularOrParalleLinesName(
        name as ShapesWitherpendicularOrParalelleLinesName
      )
    );

    return { isAnswerParallel, allAnswers, answersSvgNames, correctAnswerNames };
  },
  Component: props => {
    const {
      question: { isAnswerParallel, allAnswers, answersSvgNames, correctAnswerNames },
      translate
    } = props;

    const items = allAnswers.map(({ name }, index) => {
      return {
        value: name,
        component: <AssetSvg height={150} width={150} name={answersSvgNames[index] as SvgName} />
      };
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapesThatHaveAtLeastOnePairOfX(
          isAnswerParallel ? translate.misc.parallel() : translate.misc.perpendicular()
        )}
        pdfTitle={translate.instructions.circleTheShapesThatHaveAtLeastOnePairOfX(
          isAnswerParallel ? translate.misc.parallel() : translate.misc.perpendicular()
        )}
        testCorrect={correctAnswerNames}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndDescribe2DShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
