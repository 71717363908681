import { AssetSvg, type SvgName } from '../../assets/svg';
import { type StyleProp, type ViewStyle, View, Platform } from 'react-native';
import { useContext, useMemo } from 'react';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
  type SharedValue
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { useTheme } from '../../theme';
import { ScaleFactorContext } from '../../theme/scaling';
import { withStateHOC } from '../../stateTree';
import { rotateAroundAPointTransformStyle } from '../../utils/angles';

const ROTATION_HANDLE_WIDTH = 54;
const ROTATION_HANDLE_TOUCHABLE_AREA_WIDTH = 96;

type Props = {
  /** In radians. */
  rotation: number;
  setRotation: (newRotation: number) => void;
  maxRotation?: number;
  minRotation?: number;
  image:
    | {
        element: JSX.Element;
      }
    | {
        svgName: SvgName;
      };
  width: number;
  height: number;
  /** Default: width/2 */
  anchorX?: number;
  /** Default: height/2 */
  anchorY?: number;
  /** Handles are positioned relative to the anchor point. */
  handles: { xOffset?: number; yOffset?: number }[];
  /** @deprecated Shows a small red dot at the anchor point. Useful in development to know where it is. */
  showDebugDotAtAnchorPoint?: boolean;
  style?: StyleProp<ViewStyle>;
};

/**
 * Image with a rotation handle. The handle rotates the image from the `image` prop.
 */
export default function ImageWithRotationHandle({
  rotation,
  setRotation,
  maxRotation,
  minRotation,
  image,
  width,
  height,
  anchorX: anchorXProp,
  anchorY: anchorYProp,
  handles,
  showDebugDotAtAnchorPoint,
  style
}: Props) {
  // Extract layout info and set defaults
  const anchorX = anchorXProp ?? width / 2;
  const anchorY = anchorYProp ?? height / 2;

  const animatedRotation = useSharedValue(rotation);
  const rotateStyle = useAnimatedStyle(
    () => ({
      transform: rotateAroundAPointTransformStyle(
        animatedRotation.value,
        anchorX,
        anchorY,
        width,
        height
      )
    }),
    [anchorX, anchorY, animatedRotation, height, width]
  );

  return (
    <View style={[{ width, height }, style]}>
      <Animated.View
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          width,
          height,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Animated.View style={rotateStyle}>
          {'svgName' in image ? (
            <AssetSvg name={image.svgName} width={width} height={height} />
          ) : (
            image.element
          )}
        </Animated.View>
        {showDebugDotAtAnchorPoint && (
          <View
            style={{
              position: 'absolute',
              left: anchorX - 2,
              top: anchorY - 2,
              width: 4,
              height: 4,
              borderRadius: 999,
              backgroundColor: 'red'
            }}
          />
        )}
        {handles.map((handle, index) => (
          <RotationHandle
            key={index}
            anchorX={anchorX}
            anchorY={anchorY}
            width={width}
            height={height}
            animatedRotation={animatedRotation}
            xOffset={handle.xOffset}
            yOffset={handle.yOffset}
            setRotation={setRotation}
            maxRotation={maxRotation}
            minRotation={minRotation}
          />
        ))}
      </Animated.View>
    </View>
  );
}

type RotationHandleProps = {
  anchorX: number;
  anchorY: number;
  width: number;
  height: number;
  animatedRotation: SharedValue<number>;
  xOffset?: number;
  yOffset?: number;
  setRotation: (newRotation: number) => void;
  maxRotation?: number;
  minRotation?: number;
};

/** The orange dot which can be dragged to rotate the image. Manages the gesture. */
function RotationHandle({
  anchorX,
  anchorY,
  width,
  height,
  animatedRotation,
  xOffset = 0,
  yOffset = 0,
  setRotation,
  maxRotation,
  minRotation
}: RotationHandleProps) {
  const scaleFactor = useContext(ScaleFactorContext);
  const theme = useTheme();

  const midpointX = width / 2;
  const midpointY = height / 2;
  const angleOffset = Math.atan2(xOffset, -yOffset);

  const rotateHandleStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: anchorX - midpointX },
        { translateY: anchorY - midpointY },
        { rotate: `${animatedRotation.value}rad` },
        { translateX: -(anchorX - midpointX) },
        { translateY: -(anchorY - midpointY) },
        { translateX: anchorX - midpointX + xOffset },
        {
          translateY: anchorY - midpointY + yOffset
        }
      ]
    };
  }, [anchorX, midpointX, anchorY, midpointY, animatedRotation, xOffset, yOffset]);

  // Used to calculate translationX/Y since we don't trust those values from the event.
  const beginAbsX = useSharedValue<number | null>(null);
  const beginAbsY = useSharedValue<number | null>(null);

  // Start position of gesture, relative to anchor of image
  const startPositionFromAnchorX = useSharedValue<number | null>(null);
  const startPositionFromAnchorY = useSharedValue<number | null>(null);

  const rotationGesture = useMemo(
    () =>
      Gesture.Pan()
        .onStart(event => {
          beginAbsX.value = event.absoluteX;
          beginAbsY.value = event.absoluteY;

          let { x: eventX, y: eventY } = event;
          if (Platform.OS === 'web') {
            // On web these values are post-scaling-transformation, so unscale to get back to the component's coordinate
            // system
            eventX /= scaleFactor;
            eventY /= scaleFactor;
          }

          // Work out where the start position (as given by this event) is relative to the image's anchor point, and store it off
          // in the context.
          // To do this, we use the following vectors:
          // 1) anchor point -> rotation handle center
          // 2) origin -> rotation handle center
          // 3) origin -> start position
          // where the origin is (0, 0) in the coordinate system of the event.

          // For (1), we calculate using the handle's rotation around the anchor point, and the handle's offset.
          const handleAngle = animatedRotation.value;
          const handleCenterFromAnchorX =
            Math.cos(handleAngle) * xOffset - Math.sin(handleAngle) * yOffset;
          const handleCenterFromAnchorY =
            Math.sin(handleAngle) * xOffset + Math.cos(handleAngle) * yOffset;

          // For (2), it stands to reason that the origin is the top left of the handle, which is square.
          // However, the handle may have been rotated, and in that case the coordinates actually originate from the top
          // left of the *smallest unrotated square that bounds the handle*. This can be easily calculated from the
          // handle's width and rotation, and the handle's center is in the middle of this box.
          const boundingBoxWidth =
            ROTATION_HANDLE_TOUCHABLE_AREA_WIDTH *
            (Math.abs(Math.sin(handleAngle)) + Math.abs(Math.cos(handleAngle)));
          const handleCenterFromOriginX = boundingBoxWidth / 2;
          const handleCenterFromOriginY = boundingBoxWidth / 2;

          // For (3), this is easy - it's just the event's x/y coordinates by definition!
          const startPositionFromOriginX = eventX;
          const startPositionFromOriginY = eventY;

          // Now we use vector addition/subtraction to get the vector we want.
          startPositionFromAnchorX.value =
            handleCenterFromAnchorX - handleCenterFromOriginX + startPositionFromOriginX;
          startPositionFromAnchorY.value =
            handleCenterFromAnchorY - handleCenterFromOriginY + startPositionFromOriginY;
        })
        .onUpdate(event => {
          // Calculate translationX/Y manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;
          const translationY = (event.absoluteY - beginAbsY.value!) / scaleFactor;

          const currentPositionFromAnchorX = startPositionFromAnchorX.value! + translationX;
          const currentPositionFromAnchorY = startPositionFromAnchorY.value! + translationY;

          const angleOfCurrentPosition = Math.atan2(
            currentPositionFromAnchorX,
            -currentPositionFromAnchorY // Negative because, in canvas coordinates, y points down.
          );

          animatedRotation.value = angleOfCurrentPosition - angleOffset;
          // Clamp to max/min
          if (maxRotation !== undefined)
            animatedRotation.value = Math.min(animatedRotation.value, maxRotation);
          if (minRotation !== undefined)
            animatedRotation.value = Math.max(animatedRotation.value, minRotation);
        })
        .onEnd(() => {
          runOnJS(setRotation)(animatedRotation.value);
        }),
    [
      beginAbsX,
      beginAbsY,
      animatedRotation,
      xOffset,
      yOffset,
      startPositionFromAnchorX,
      startPositionFromAnchorY,
      scaleFactor,
      angleOffset,
      maxRotation,
      minRotation,
      setRotation
    ]
  );

  return (
    <GestureDetector gesture={rotationGesture}>
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: ROTATION_HANDLE_TOUCHABLE_AREA_WIDTH,
            height: ROTATION_HANDLE_TOUCHABLE_AREA_WIDTH,
            borderRadius: 999,
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center'
          },
          rotateHandleStyle
        ]}
      >
        <View
          style={{
            width: ROTATION_HANDLE_WIDTH,
            height: ROTATION_HANDLE_WIDTH,
            borderRadius: 999,
            backgroundColor: theme.colors.tertiary,
            borderColor: 'black',
            borderWidth: 2.516
          }}
        />
      </Animated.View>
    </GestureDetector>
  );
}

export const ImageWithRotationHandleWithState = withStateHOC(ImageWithRotationHandle, {
  stateProp: 'rotation',
  setStateProp: 'setRotation',
  defaults: { defaultState: 0 }
});
