import { newBlockContent } from '../../../Block';

import IntroductionToPartsAndWhole from './1IntroductionToPartsAndWhole';
import EqualAndUnequalParts from './2EqualAndUnequalParts';
import RecogniseAHalf from './3RecogniseAHalf';
import FindAHalf from './4FindAHalf';
import RecogniseAQuarter from './5RecogniseAQuarter';
import FindAQuarter from './6FindAQuarter';
import RecogniseAThird from './7RecogniseAThird';
import FindAThird from './8FindAThird';
import FindTheWhole from './9FindTheWhole';
import UnitFractions from './10UnitFractions';
import NonUnitFractions from './11NonUnitFractions';
import RecogniseEquivalenceOfHalfAndTwoQuarters from './12RecogniseEquivalenceOfHalfAndTwoQuarters';
import RecogniseThreeQuarters from './13RecogniseThreeQuarters';
import FindThreeQuarters from './14FindThreeQuarters';
import CountInFractionsUpToAWhole from './15CountInFractionsUpToAWhole';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    IntroductionToPartsAndWhole, // Step 1
    EqualAndUnequalParts, // Step 2
    RecogniseAHalf, // Step 3
    FindAHalf, // Step 4
    RecogniseAQuarter, // Step 5
    FindAQuarter, // Step 6
    RecogniseAThird, // Step 7
    FindAThird, // Step 8
    FindTheWhole, // Step 9
    UnitFractions, // Step 10
    NonUnitFractions, // Step 11
    RecogniseEquivalenceOfHalfAndTwoQuarters, // Step 12
    RecogniseThreeQuarters, // Step 13
    FindThreeQuarters, // Step 14
    CountInFractionsUpToAWhole // Step 15
  ]
});
export default Block;
