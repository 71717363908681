import { getRandomBoolean, getRandomFromArray } from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import { randomIntegerInclusive } from 'common/src/utils/random';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { ScientificNotation } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aay',
  description: 'aay',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Object'],
  schema: z.object({
    variant: z.enum(['Balloons', 'Bricks', 'Lollipops', 'Marbles', 'Sweets', 'Crayons']),
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  example: {
    variant: 'Lollipops',
    hundreds: 1,
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    const variant = getRandomFromArray([
      'Balloons',
      'Bricks',
      'Lollipops',
      'Marbles',
      'Sweets',
      'Crayons'
    ] as const);
    // Limit tens to 1-9, ones to 1-9 and hundreds to 1-9
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    return {
      variant,
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.thereAreNumberObjects(variant)}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aaz',
  description: 'aaz',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Object'],
  schema: z.object({
    variant: z.enum(['Balloons', 'Bricks', 'Lollipops', 'Marbles', 'Sweets', 'Crayons']),
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  example: {
    variant: 'Balloons',
    hundreds: 1,
    tens: 2,
    ones: 0
  },
  simpleGenerator: () => {
    const variant = getRandomFromArray([
      'Balloons',
      'Bricks',
      'Lollipops',
      'Marbles',
      'Sweets',
      'Crayons'
    ] as const);

    // Limit tens to 1-9 and hundreds to 1-9 and ones to 1-9
    // Should either have ones or tens
    const shouldHaveOnes = getRandomBoolean();
    const ones = shouldHaveOnes ? randomIntegerInclusive(1, 9) : 0;
    const tens = shouldHaveOnes ? 0 : randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    return {
      variant,
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.thereAreNumberObjects(variant)}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aaA',
  description: 'aaA',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  questionHeight: 1440,
  example: {
    ones: 4,
    tens: 2,
    hundreds: 1
  },
  simpleGenerator: () => {
    // Limit ones to 1-9 and tens to 1-9
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={[
          hundreds.toString(),
          tens.toString(),
          ones.toString(),
          (hundreds * 100 + tens * 10 + ones).toString()
        ]}
        sentence={translate.answerSentences.hundredsTensOnesAns()}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aaB',
  description: 'aaB',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10', 'Select'],
  schema: z
    .object({
      number: z.number().int().min(101).max(499),
      numberPartition: z.object({
        hundreds: z.number().int().min(1).max(4),
        tens: z.number().int().min(0).max(9),
        ones: z.number().int().min(0).max(9)
      }),
      wrongNumberPartitions: z
        .array(
          z.object({
            hundreds: z.number().int().min(0).max(5),
            tens: z.number().int().min(0).max(9),
            ones: z.number().int().min(0).max(9)
          })
        )
        .nonempty(),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(
      val =>
        val.wrongNumberPartitions.every(
          it => it.hundreds * 100 + it.tens * 10 + it.ones !== val.number
        ),
      'Each wrong number must not sum to the number provided'
    ),
  example: {
    number: 240,
    numberPartition: { hundreds: 2, tens: 4, ones: 0 },
    wrongNumberPartitions: [
      { hundreds: 2, tens: 0, ones: 7 },
      { hundreds: 1, tens: 2, ones: 0 },
      { hundreds: 3, tens: 0, ones: 3 }
    ],
    correctPosition: 3
  },
  simpleGenerator: () => {
    // Randomly decide if the ones are zero or not.
    const areOnesZero = getRandomFromArray([true, false]);
    // Limit ones to 1-9 and tens to 1-9 (if ones are not zero from above, tens are zero instead) and hundreds to 1-9
    const ones = areOnesZero ? 0 : randomIntegerInclusive(1, 9);
    const tens = areOnesZero ? randomIntegerInclusive(1, 9) : 0;

    // Chose the number
    const hundreds = randomIntegerInclusive(1, 4);
    const number = hundreds * 100 + tens * 10 + ones;

    type Partition = { hundreds: number; tens: number; ones: number };
    const numberPartition = { hundreds: hundreds, tens: tens, ones: ones };

    const wrongNumberPartitions: [Partition, ...Partition[]] = [
      { hundreds: hundreds, tens: tens <= 5 ? tens + 2 : tens - 2, ones: 0 },
      {
        hundreds: hundreds < 2 ? hundreds + 1 : hundreds - 1,
        tens: 0,
        ones: ones <= 5 ? ones + 2 : ones - 2
      },
      {
        hundreds: hundreds < 2 ? hundreds + 1 : hundreds - 1,
        tens: tens <= 5 ? tens + 3 : tens - 3,
        ones: ones <= 5 ? ones + 3 : ones - 3
      }
    ];

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, numberPartition, wrongNumberPartitions, correctPosition };
  },
  Component: props => {
    const {
      question: { number, numberPartition, wrongNumberPartitions, correctPosition },
      translate
    } = props;
    const allPartitions = [
      ...wrongNumberPartitions.slice(0, correctPosition),
      numberPartition,
      ...wrongNumberPartitions.slice(correctPosition)
    ];

    const variant = 'Cubes';

    return (
      <QF11SelectImagesUpTo4<number>
        title={`${translate.instructions.whichPictureRepresents(
          number
        )} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.whichPictureRepresents(
          number
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={[correctPosition]}
        numItems={4}
        renderItems={({ dimens }) => {
          const scale = Math.min(
            ...allPartitions.map(
              part => BaseTenRepCalcGridsAndScale(dimens.width, dimens.height, part, variant).scale
            )
          );
          return allPartitions.map((partition, index) => ({
            value: index,
            component: (
              <BaseTenRepresentation
                b10Rep={{
                  variant: variant,
                  numbers: {
                    ones: partition.ones,
                    tens: partition.tens,
                    hundreds: partition.hundreds
                  },
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width}
                usableHeight={dimens.height - 20} // -20 to give a margin of 10
                scale={scale}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aaC',
  description: 'aaC',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(101)
      .max(999)
      .refine(
        number => sumDigits(number) <= 33,
        'number must be representable with <=33 base ten counters'
      )
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(101, 999, {
      constraint: number => sumDigits(number) <= 33
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF15CreateBaseTenNumber
        title={translate.instructions.dragTheBase10ToRepresentTheNumber(number)}
        pdfTitle={translate.instructions.useBase10ToRepresentNum(number)}
        number={ScientificNotation.fromNumber(number)}
        draggablesToShow={[2, 1, 0]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aaD',
  description: 'aaD',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  example: {
    hundreds: 1,
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    // Limit tens to 1-9, ones to 0-9 (or 1-9 if tens are 0) and hundreds to 1-9
    const tens = randomIntegerInclusive(0, 9);
    const ones = tens === 0 ? randomIntegerInclusive(1, 9) : randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentNumbersTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
