// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import RepresentNumbersTo100 from './1RepresentNumbersTo100';
import PartitionNumbersTo100 from './2PartitionNumbersTo100';
import NumberLineTo100 from './3NumberLineTo100';
import Hundreds from './4Hundreds';
import RepresentNumbersTo1000 from './5RepresentNumbersTo1000';
import PartitionNumbersTo1000 from './6PartitionNumbersTo1000';
import FlexiblePartitioningOfNumbersTo1000 from './7FlexiblePartitioningOfNumbersTo1000';
import HundredsTensAndOnes from './8HundredsTensAndOnes';
import Find1To100MoreOrLess from './9Find1To100MoreOrLess';
import NumberLineTo1000 from './10NumberLineTo1000';
import EstimateOnANumberLineTo1000 from './11EstimateOnANumberLineTo1000';
import CompareNumbersTo1000 from './12CompareNumbersTo1000';
import OrderNumbersTo1000 from './13OrderNumbersTo1000';
import CountIn50s from './14CountIn50s';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    RepresentNumbersTo100, // Step 1
    PartitionNumbersTo100, // Step 2
    NumberLineTo100, // Step 3
    Hundreds, // Step 4
    RepresentNumbersTo1000, // Step 5
    PartitionNumbersTo1000, // Step 6
    FlexiblePartitioningOfNumbersTo1000, // Step 7
    HundredsTensAndOnes, // Step 8
    Find1To100MoreOrLess, // Step 9
    NumberLineTo1000, // Step 10
    EstimateOnANumberLineTo1000, // Step 11
    CompareNumbersTo1000, // Step 12
    OrderNumbersTo1000, // Step 13
    CountIn50s // Step 14
  ]
});
export default Block;
