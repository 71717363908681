import { StyleSheet, View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { colors } from '../../../theme/colors';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * Text to be displayed on label
   */
  value: string;
  /**
   * Image to be displayed
   */
  image: JSX.Element | undefined;
  /**
   * Position of label
   */
  position: 'middle' | 'bottom' | 'top' | 'right';
  /**
   * Height of image. This is required when setting position as bottom as it is used
   * to work out top position.
   */
  imageHeight?: number;
  /**
   * Width of label
   */
  labelWidth?: number;
  /**
   * Height of label
   */
  labelHeight?: number;
  fontSize?: number;
};

/**
 * Function to display a given image with a label at the top/bottom/middle of the image.
 * Takes the value to be shown on the label, the image, the position of the label,
 * font size and dimens.
 */
export const ImageWithLabel = ({
  value,
  labelWidth,
  labelHeight,
  fontSize,
  image,
  position,
  imageHeight
}: Props) => {
  const displayMode = useContext(DisplayMode);

  const topPosition =
    position === 'bottom' && imageHeight ? imageHeight : position === 'top' ? 0 : null;
  const styles = useStyles(topPosition, labelWidth, labelHeight);

  const fontSizeToUse = fontSize ?? (displayMode === 'digital' ? 24 : 40);
  const lineHeightToUse = displayMode === 'digital' ? 48 : 60;
  return (
    <View style={styles.container}>
      <View style={styles.label}>
        <Text variant="WRN400" style={{ fontSize: fontSizeToUse, lineHeight: lineHeightToUse }}>
          {value}
        </Text>
      </View>
      {image}
    </View>
  );
};

const useStyles = (topPosition: number | null, width?: number, height?: number) => {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        label: {
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: width,
          minHeight: displayMode === 'digital' ? height : 64,
          position: 'absolute',
          top: topPosition,
          zIndex: 1,
          backgroundColor: colors.yellow,
          borderWidth: 4,
          borderColor: colors.prussianBlue
        },
        container: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        }
      }),
    [displayMode, height, topPosition, width]
  );
};
