// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import ReadAndPlotCoordinates from './1ReadAndPlotCoordinates';
import ProblemSolvingWithCoordinates from './2ProblemSolvingWithCoordinates';
import Translation from './3Translation';
import TranslationWithCoordinates from './4TranslationWithCoordinates';
import LinesOfSymmetry from './5LinesOfSymmetry';
import ReflectionInHorizontalAndVerticalLines from './6ReflectionInHorizontalAndVerticalLines';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [4, 5],
  smallSteps: [
    ReadAndPlotCoordinates, // Step 1
    ProblemSolvingWithCoordinates, // Step 2
    Translation, // Step 3
    TranslationWithCoordinates, // Step 4
    LinesOfSymmetry, // Step 5
    ReflectionInHorizontalAndVerticalLines // Step 6
  ]
});
export default Block;
