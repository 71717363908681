import { newBlockContent } from '../../../Block';

import LanguageOfPosition from './1LanguageOfPosition';
import DescribeMovement from './2DescribeMovement';
import DescribeTurns from './3DescribeTurns';
import DescribeMovementAndTurns from './4DescribeMovementAndTurns';
import ShapePatternsWithTurns from './5ShapePatternsWithTurns';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [9, 10],
  smallSteps: [
    LanguageOfPosition, // Step 1
    DescribeMovement, // Step 2
    DescribeTurns, // Step 3
    DescribeMovementAndTurns, // Step 4
    ShapePatternsWithTurns // Step 5
  ]
});
export default Block;
