import { StyleSheet, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Svg, { Line } from 'react-native-svg';
import { barModelColors, BarModelColorsKey, colors } from 'common/src/theme/colors';
import { SetState } from 'common/src/utils/react';
import { filledArray, range } from 'common/src/utils/collections';
import Text from '../../typography/Text';
import Table from 'common/src/components/molecules/Table';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { useCallback, useContext } from 'react';
import { withStateHOC } from 'common/src/stateTree';
import { noop } from 'common/src/utils/flowControl';
import { trueCount } from 'common/src/utils/shapes';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * Cells which should be always shaded and interactive.
   */
  shadedCells: boolean[][];
  /**
   * Total number of rows required
   */
  numberOfRows: number;
  /**
   * Number of columns per row
   */
  numberOfCols: number;
  /**
   * The user answer, i.e. which cells are crossed out.
   */
  userAnswer: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<boolean[][]>;
  /**
   * Shaded cells color
   */
  shadedCellsColor?: BarModelColorsKey;
  /**
   * Table width
   */
  tableWidth?: number;
  /**
   * Table height
   */
  tableHeight?: number;
  /**
   * Table styles
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Text to display before the bar model. Optional prop.
   */
  preBarText?: string;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  /**
   * Cells which should be always crossed.
   */
  crossedCells: boolean[][];
  /**
   * Whether the bar model is interactive to be able toggle crosses onClick or non-interactive to allow crosses to be displayed by default but not clickable
   */
  interactive: boolean;
  /**
   * Boolean to determine whether each row should actually display separately, as though they are separate bar models.
   * Necessary for when we need to handle the state of all these rows/bars together.
   */
  separatedRows?: boolean;
};

/**
 * Takes in shadedCells prop which is a 2D array of shaded cells which are static and never change.
 * Clicking on a cell toggles an X/cross on that specific cell, represented by the userAnswer/setUserAnswer props.
 */

export const BarModelInteractiveWithCrosses = ({
  numberOfRows,
  numberOfCols,
  userAnswer,
  setUserAnswer = noop,
  tableWidth = 800 * 0.9,
  tableHeight = 400 * 0.9,
  style,
  preBarText,
  fractionContainerStyle,
  fractionTextStyle,
  fullWidthTopBrace,
  shadedCells,
  shadedCellsColor,
  crossedCells,
  interactive,
  separatedRows = false
}: Props) => {
  const displayMode = useContext(DisplayMode);

  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      setUserAnswer(userAnswer =>
        userAnswer.map((row, rowIndex) =>
          rowIndex !== rowIdx
            ? row
            : row.map((item, columnIndex) => (columnIndex !== colIdx ? item : !item))
        )
      );
    },
    [setUserAnswer]
  );

  // Now return a table
  return (
    <View style={{ flexDirection: 'row' }}>
      {preBarText && (
        <TextStructure
          sentence={preBarText}
          fractionTextStyle={fractionTextStyle}
          fractionContainerStyle={fractionContainerStyle}
        />
      )}
      <View style={{ flexDirection: 'column' }}>
        {/* If fullWidthTopBrace prop passed render text with horizontal line above bar model */}
        {fullWidthTopBrace && (
          <View style={[styles.wholeContainer]}>
            <View style={styles.wholeHorizontalLine}>
              <Text style={styles.questionMark}>{fullWidthTopBrace}</Text>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineMiddle
                ]}
              ></View>
              <View
                style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineLeft]}
              ></View>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineRight
                ]}
              ></View>
            </View>
          </View>
        )}
        {separatedRows ? (
          <View style={{ gap: 16 }}>
            {range(0, numberOfRows - 1).map(row => (
              <Table
                key={`row-${row}`}
                style={[{ width: tableWidth, height: tableHeight }, style]}
                rowStyle={{ flex: 1 }} // Rows share the table height
                cellStyle={{ flex: 1 }} // Cells share the row width
                items={[
                  range(0, numberOfCols - 1).map(columnIndex => {
                    return (
                      <TouchableOpacity
                        key={`${row}-${columnIndex}`}
                        disabled={!shadedCells[row][columnIndex] || !interactive}
                        style={{
                          flex: 1,
                          alignSelf: 'stretch',
                          backgroundColor: shadedCells[row][columnIndex]
                            ? displayMode !== 'digital'
                              ? colors.pdfShading
                              : barModelColors[shadedCellsColor!] ?? interactive
                              ? colors.burntSienna
                              : colors.pacificBlue
                            : undefined
                        }}
                        onPress={() => {
                          handleOnPress(row, columnIndex);
                        }}
                      >
                        {userAnswer.length > 0 &&
                          (userAnswer[row][columnIndex] || crossedCells[row][columnIndex]) && (
                            <Svg
                              viewBox="0 0 100 100"
                              preserveAspectRatio="none"
                              style={{ height: '100%' }}
                            >
                              <Line
                                x1="0"
                                y1="100"
                                x2="100"
                                y2="0"
                                strokeWidth="3"
                                stroke={
                                  displayMode !== 'digital' ? colors.black : colors.prussianBlue
                                }
                              />
                              <Line
                                x1="0"
                                y1="0"
                                x2="100"
                                y2="100"
                                strokeWidth="3"
                                stroke={
                                  displayMode !== 'digital' ? colors.black : colors.prussianBlue
                                }
                              />
                            </Svg>
                          )}
                      </TouchableOpacity>
                    );
                  })
                ]}
              />
            ))}
          </View>
        ) : (
          <Table
            style={[{ width: tableWidth, height: tableHeight }, style]}
            rowStyle={{ flex: 1 }} // Rows share the table height
            cellStyle={{ flex: 1 }} // Cells share the row width
            items={range(0, numberOfRows - 1).map(rowIndex =>
              range(0, numberOfCols - 1).map(columnIndex => {
                return (
                  <TouchableOpacity
                    key={`${rowIndex}-${columnIndex}`}
                    disabled={!shadedCells[rowIndex][columnIndex] || !interactive}
                    style={{
                      flex: 1,
                      alignSelf: 'stretch',
                      backgroundColor: shadedCells[rowIndex][columnIndex]
                        ? displayMode !== 'digital'
                          ? colors.pdfShading
                          : barModelColors[shadedCellsColor!] ?? interactive
                          ? colors.burntSienna
                          : colors.pacificBlue
                        : undefined
                    }}
                    onPress={() => {
                      handleOnPress(rowIndex, columnIndex);
                    }}
                  >
                    {userAnswer.length > 0 &&
                      (userAnswer[rowIndex][columnIndex] ||
                        crossedCells[rowIndex][columnIndex]) && (
                        <Svg
                          viewBox="0 0 100 100"
                          preserveAspectRatio="none"
                          style={{ height: '100%' }}
                        >
                          <Line
                            x1="0"
                            y1="100"
                            x2="100"
                            y2="0"
                            strokeWidth="3"
                            stroke={displayMode !== 'digital' ? colors.black : colors.prussianBlue}
                          />
                          <Line
                            x1="0"
                            y1="0"
                            x2="100"
                            y2="100"
                            strokeWidth="3"
                            stroke={displayMode !== 'digital' ? colors.black : colors.prussianBlue}
                          />
                        </Svg>
                      )}
                  </TouchableOpacity>
                );
              })
            )}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wholeContainer: {
    paddingBottom: 20
  },
  wholeHorizontalLine: {
    backgroundColor: colors.prussianBlue,
    height: 2,
    position: 'relative'
  },
  wholeHorizontalVerticalLine: {
    backgroundColor: colors.prussianBlue,
    height: 10,
    position: 'absolute',
    top: 0,
    width: 2
  },
  wholeHorizontalVerticalLineLeft: {
    left: 0
  },
  wholeHorizontalVerticalLineRight: {
    right: 0
  },
  wholeHorizontalVerticalLineMiddle: {
    alignSelf: 'center',
    height: 20,
    top: -20
  },
  questionMark: {
    alignSelf: 'center',
    fontSize: 24,
    position: 'absolute',
    top: -56
  }
});

/** See {@link BarModelInteractiveWithCrosses}. */
export const BarModelInteractiveWithCrossesWithState = withStateHOC(
  BarModelInteractiveWithCrosses,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer',
    defaults: props => ({
      defaultState: filledArray(filledArray(false, props.numberOfCols), props.numberOfRows),
      testComplete: state => (props.interactive ? trueCount(state) > 0 : true)
    })
  }
);
