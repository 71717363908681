import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { isInRange } from '../../../../utils/matchers';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import { Point2d } from '../../../../utils/vectors';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

////
// Questions
////

const shapes = [
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Square',
  'Rectangle',
  'Circle',
  'Star'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Square: [
    'Square/square_pink',
    'Square/square_purple',
    'Square/square_green',
    'Square/square_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_yellow'
  ],
  Circle: [
    'Circles/circle_pink',
    'Circles/circle_purple',
    'Circles/circle_green',
    'Circles/circle_yellow'
  ],
  Star: ['Star_pink', 'Star_purple', 'Star_green', 'Star_yellow']
};

const shapeTranslation = (shape: Shape, translate: TranslationFunctions) => {
  switch (shape) {
    case 'Square':
      return translate.shapes.squares(1);
    case 'Arrow':
      return translate.shapes.arrow();
    case 'Circle':
      return translate.shapes.circles(1);
    case 'Equilateral':
    case 'Isosceles':
      return translate.shapes.triangles(1);
    case 'Rectangle':
      return translate.shapes.rectangles(1);
    case 'Star':
      return translate.shapes.stars(1);
  }
};

const Question1 = newQuestionContent({
  uid: 'beL',
  description: 'beL',
  keywords: ['Left', 'Right', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Circle'])
      .array()
      .refine(arrayHasNoDuplicates),
    shapeComparisonIndexes: z.number().int().min(0).max(3).array().length(2),
    colorIndexes: z.number().int().min(0).max(3).array().length(4),
    isVariation1: z.boolean()
  }),
  simpleGenerator: () => {
    const shapeOptions = [
      'Arrow' as const,
      'Circle' as const,
      getRandomFromArray(['Equilateral', 'Isosceles'] as const),
      getRandomFromArray(['Square', 'Rectangle'] as const)
    ];
    const numberOfShapes = randomIntegerInclusive(3, 4);
    const shapes = getRandomSubArrayFromArray([...shapeOptions] as const, numberOfShapes);
    const shapeComparisonIndexes = randomUniqueIntegersInclusive(0, numberOfShapes - 1, 2);

    const isVariation1 = getRandomBoolean();

    return {
      shapes: shuffle(shapes),
      shapeComparisonIndexes,
      colorIndexes: shuffle(countRange(4)),
      isVariation1
    };
  },
  Component: props => {
    const {
      question: { shapes, shapeComparisonIndexes, colorIndexes, isVariation1 },
      translate
    } = props;

    const isLeft = shapeComparisonIndexes[0] < shapeComparisonIndexes[1];

    const items = isVariation1
      ? (['left', 'right'] as const).map(val => ({
          value: val,
          component: <Text variant="WRN700">{translate.ks1MiscStrings.directions[val]()}</Text>
        }))
      : shapes
          .filter((_, i) => i !== shapeComparisonIndexes[0])
          .map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val, translate)}</Text>
          }));

    const testCorrect = (userAnswer: string | undefined): boolean => {
      if (!userAnswer) return false;
      if (isVariation1) {
        return (
          userAnswer === (shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'left' : 'right')
        );
      } else {
        const answerIndex = shapes.indexOf(
          userAnswer as 'Arrow' | 'Equilateral' | 'Isosceles' | 'Square' | 'Rectangle' | 'Circle'
        );
        return (
          (isLeft && answerIndex > shapeComparisonIndexes[0]) ||
          (!isLeft && answerIndex < shapeComparisonIndexes[0])
        );
      }
    };

    const shape1 = shapeTranslation(shapes[shapeComparisonIndexes[0]], translate);
    const shape2 = shapeTranslation(shapes[shapeComparisonIndexes[1]], translate);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={items}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {shapes.map((shape, i) => (
              <View
                key={i}
                style={{
                  transform: `rotate(${shape === 'Arrow' ? 270 : 0}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg name={svgNames[shape][colorIndexes[i]]} height={dimens.height * 0.4} />
              </View>
            ))}
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        sentence={
          isVariation1
            ? translate.ks1AnswerSentences.theShapeIsToTheAnsOfTheShape(shape1, shape2)
            : isLeft
            ? translate.ks1AnswerSentences.theShapeIsToTheLeftOfAns(shape1)
            : translate.ks1AnswerSentences.theShapeIsToTheRightOfAns(shape1)
        }
        testCorrect={userAnswer => testCorrect(userAnswer[0])}
        customMarkSchemeAnswer={{
          answersToDisplay: isVariation1
            ? [[shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'left' : 'right']]
            : [[shapes[shapeComparisonIndexes[1]]]],
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'beN',
  description: 'beN',
  keywords: ['Left', 'Right', '2-D shapes'],
  schema: z
    .object({
      trackSize: z.number().int().min(5).max(8),
      startSpace: z.number().int().min(0).max(7),
      spaces: z
        .number()
        .int()
        .min(-7)
        .max(7)
        .refine(val => val !== 0),
      shape: z.enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Star']),
      colorIndex: z.number().int().min(0).max(3)
    })
    .refine(val => {
      const after = val.startSpace + val.spaces;
      return isInRange(0, val.trackSize - 1)(after);
    }, 'item must stay inside track'),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Square',
      'Rectangle',
      'Star'
    ] as const);

    const trackSize = randomIntegerInclusive(5, 8);
    const startSpace = randomIntegerInclusive(0, trackSize - 1);

    const colorIndex = randomIntegerInclusive(0, 3);

    const spaces = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => x !== 0 && isInRange(0, trackSize - 1)(startSpace + x)
    });

    return { shape, trackSize, startSpace, spaces, colorIndex };
  },
  Component: props => {
    const {
      question: { shape, trackSize, startSpace, spaces, colorIndex },
      translate
    } = props;

    const shapeTranslated = shapeTranslation(shape, translate);

    const start = new Point2d(startSpace, 0);
    const end = start.add(new Point2d(spaces, 0));

    const [xDirection, xDistance] = ((): ['left' | 'right', number] => {
      if (spaces > 0) return ['right', spaces];
      else return ['left', -spaces];
    })();

    return (
      <QF48TranslateSvg
        title={translate.ks1Instructions.dragTheItemXSpacesToTheDx(
          shapeTranslated,
          xDistance,
          translate.directions[xDirection]()
        )}
        pdfTitle={translate.ks1PDFInstructions.theItemMovesXSpacesToTheDxDrawItOnTheTrack(
          shapeTranslated,
          xDistance,
          translate.directions[xDirection]()
        )}
        start={[start]}
        end={[end]}
        questionHeight={900}
        svg="custom"
        customDraggable={(_color: string, opacity?: number, _index = 0, width = 200) => ({
          component: (
            <View
              style={{
                opacity: opacity,
                width: width,
                height: width,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                width={width * 0.9}
                height={width * 0.9}
              />
            </View>
          ),
          width: width,
          height: width
        })}
        anchorDX={0}
        anchorDY="height"
        yMax={1}
        xMax={trackSize}
        gridLineWidth={2}
        markSchemeIsGhost
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribePositionLeftAndRight',
  questionTypes: [Question1, Question3],
  unpublishedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
