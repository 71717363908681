import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { View } from 'react-native';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  moneyToHighestDenominations,
  displayMoney,
  numToCurrency,
  isValidMoneyAnswer
} from 'common/src/utils/money';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import Text from 'common/src/components/typography/Text';
import ContentBox from 'common/src/components/molecules/ContentBox';
import { ADD, SUB } from 'common/src/constants';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from 'common/src/utils/zod';
import { colors } from 'common/src/theme/colors';
import { compareFloats } from 'common/src/utils/math';
import {
  clothingItemSVG,
  clothingItemsSchema,
  getRandomUniqueClothingNames
} from 'common/src/utils/items';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axu',
  description: 'axu',
  keywords: ['Money', 'Decimals', 'Pence', 'Add'],
  schema: z.object({
    penceA: z
      .number()
      .int()
      .min(11)
      .max(49)
      .refine(val => val % 5 !== 0, 'penceA must not be a multiple of 5'),
    penceB: z
      .number()
      .int()
      .min(11)
      .max(49)
      .refine(val => val % 5 !== 0, 'penceB must not be a multiple of 5'),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const [penceA, penceB] = randomUniqueIntegersInclusive(11, 49, 2, {
      constraint: x => x % 5 !== 0
    });

    const name = getRandomName();

    return { penceA, penceB, name };
  },
  Component: props => {
    const {
      question: { penceA, penceB, name },
      translate,
      displayMode
    } = props;

    const penceAInCoins = moneyToHighestDenominations(penceA, 'pence');

    // Answer
    const answer = penceA + penceB;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansP()}
        title={translate.instructions.characterHasThisMoney({
          name
        })}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 24,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {displayMoney(
                penceAInCoins,
                displayMode === 'digital' ? 100 : 150,
                displayMode === 'digital' ? 100 : 150,
                true
              )}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.characterIsGivenAnotherXPence({
                name,
                pence: penceB
              })}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.howMuchMoneyDoesCharacterHaveNow({
                name
              })}
            </Text>
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axv',
  description: 'axv',
  keywords: ['Money', 'Decimals', 'Pence', 'Subtract'],
  schema: z
    .object({
      penceA: z.number().int().min(51).max(99),
      penceB: z.number().int().min(11).max(49),
      name: nameSchema
    })
    .refine(val => val.penceA % 10 !== 0, 'penceA must not be a multiple of 10')
    .refine(val => val.penceB % 5 !== 0, 'penceB must not be a multiple of 5'),
  simpleGenerator: () => {
    const penceA = randomIntegerInclusive(51, 99, {
      constraint: x => x % 10 !== 0
    });
    const penceB = randomIntegerInclusive(11, 49, {
      constraint: x => x % 5 !== 0
    });

    const name = getRandomName();

    return { penceA, penceB, name };
  },
  Component: props => {
    const {
      question: { penceA, penceB, name },
      translate,
      displayMode
    } = props;

    const penceAInCoins = moneyToHighestDenominations(penceA, 'pence');

    // Answer
    const answer = penceA - penceB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterHasThisMoney({
          name
        })}
        sentence={translate.answerSentences.ansP()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                rowGap: 12,
                columnGap: 24,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {displayMoney(
                penceAInCoins,
                displayMode === 'digital' ? 100 : 150,
                displayMode === 'digital' ? 100 : 150,
                true
              )}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.characterSpendsXPence({
                name,
                pence: penceB
              })}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.howMuchMoneyDoesCharacterHaveLeft({
                name
              })}
            </Text>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axw',
  description: 'axw',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Partition'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(12),
      penceA: z.number().int().min(1).max(49),
      poundsB: z.number().int().min(1).max(6),
      penceB: z.number().int().min(1).max(49),
      name: nameSchema
    })
    .refine(val => val.penceA % 10 !== 0, 'penceA must not be a multiple of 10')
    .refine(val => val.penceB % 5 !== 0, 'penceB must not be a multiple of 5'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 12);
    const penceA = randomIntegerInclusive(1, 49, {
      constraint: x => x % 10 !== 0
    });
    const poundsB = randomIntegerInclusive(1, 6);
    const penceB = randomIntegerInclusive(1, 49, {
      constraint: x => x % 5 !== 0
    });

    const name = getRandomName();

    return { poundsA, penceA, poundsB, penceB, name };
  },
  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB, name },
      translate,
      displayMode
    } = props;

    // Pounds and pence sentences display
    const poundsADisplay = numToCurrency({ amount: (poundsA * 100 + penceA) / 100 });
    const poundsBDisplay = numToCurrency({ amount: (poundsB * 100 + penceB) / 100 });

    // Convert pounds to pence
    const poundsAInPence = poundsA * 100 + penceA;
    const poundsBInPence = poundsB * 100 + penceB;

    // Answer
    const ans = (poundsAInPence + poundsBInPence) / 100;

    return (
      <QF1ContentAndSentence
        sentence={`${poundsADisplay} ${ADD} ${poundsBDisplay} = £<ans/>`}
        title={translate.instructions.characterIsPartitioningAmountsToWorkOutTheTotalOf5Pound42And7Pound39(
          name
        )}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <ContentBox containerStyle={{ width: dimens.width / 2, alignSelf: 'center' }}>
              <View
                style={{
                  rowGap: 12
                }}
              >
                <Text
                  style={{
                    fontSize: displayMode === 'digital' ? 32 : 50,
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {`£${(5).toLocaleString()} ${ADD} £${(7).toLocaleString()} = £${(12).toLocaleString()}`}
                </Text>
                <Text
                  style={{
                    fontSize: displayMode === 'digital' ? 32 : 50,
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {`${(42).toLocaleString()}p ${ADD} ${(39).toLocaleString()}p = ${(81).toLocaleString()}p`}
                </Text>
                <Text
                  style={{
                    fontSize: displayMode === 'digital' ? 32 : 50,
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {`£${(12).toLocaleString()} ${ADD} ${(81).toLocaleString()}p = £${(12.81).toLocaleString()}`}
                </Text>
              </View>
            </ContentBox>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.useCharactersMethodToWorkOutX(
                name,
                `${poundsADisplay} ${ADD} ${poundsBDisplay}`
              )}
            </Text>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4v2 = newQuestionContent({
  uid: 'axx2',
  description: 'axx',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Subtract', 'Partition'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(10),
      poundsB: z.number().int().min(1).max(7),
      pence: z.number().int().min(1).max(89)
    })
    .refine(val => val.poundsB < val.poundsA - 1, 'poundsB must be less than poundsA - 1')
    .refine(val => val.pence % 10 !== 0, 'pence must not be a multiple of 10'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 10);
    const poundsB = randomIntegerInclusive(1, 7, {
      // Must be less than poundsA - 1 so that a valid partition can be made in the question sentences.
      constraint: x => x < poundsA - 1
    });
    const pence = randomIntegerInclusive(1, 89, {
      constraint: x => x % 10 !== 0
    });

    return { poundsA, poundsB, pence };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB, pence },
      translate,
      displayMode
    } = props;

    // Pounds and pence sentences display
    const poundsAToDisplay = numToCurrency({ amount: poundsA });
    const poundsBToDisplay = numToCurrency({ amount: (poundsB * 100 + pence) / 100 });

    // Answers
    const answer = (poundsA * 100 - (poundsB * 100 + pence)) / 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        inputMaxCharacters={4}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answer) && isValidMoneyAnswer(userAnswer[0])
        }
        extraSymbol="decimalPoint"
        sentence={`${poundsAToDisplay} ${SUB} ${poundsBToDisplay} = £<ans/>`}
        sentenceStyle={{ justifyContent: 'center' }}
        customMarkSchemeAnswer={{ answersToDisplay: [answer.toFixed(2)] }}
      />
    );
  }
});

/** Archived */
const Question4 = newQuestionContent({
  uid: 'axx',
  description: 'axx',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Subtract', 'Partition'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(10),
      poundsB: z.number().int().min(1).max(7),
      pence: z.number().int().min(1).max(89)
    })
    .refine(val => val.poundsB < val.poundsA - 1, 'poundsB must be less than poundsA - 1')
    .refine(val => val.pence % 10 !== 0, 'pence must not be a multiple of 10'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 10);
    const poundsB = randomIntegerInclusive(1, 7, {
      // Must be less than poundsA - 1 so that a valid partition can be made in the question sentences.
      constraint: x => x < poundsA - 1
    });
    const pence = randomIntegerInclusive(1, 89, {
      constraint: x => x % 10 !== 0
    });

    return { poundsA, poundsB, pence };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB, pence },
      translate,
      displayMode
    } = props;

    // Pounds and pence sentences display
    const poundsASubtractOne = poundsA - 1;
    const poundsAToDisplay = numToCurrency({ amount: poundsA });
    const poundsBToDisplay = numToCurrency({ amount: (poundsB * 100 + pence) / 100 });

    // Answers
    const answer1 = poundsA - 1 - poundsB;
    const answer2 = 100 - pence;
    const answer3 = (poundsA * 100 - (poundsB * 100 + pence)) / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.usePartitioningToWorkOutXPoundsSubtractYPounds({
          poundsA: poundsAToDisplay,
          poundsB: poundsBToDisplay
        })}
        inputMaxCharacters={4}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={answer => {
          return (
            compareFloats(answer[0][0], answer1) &&
            isValidMoneyAnswer(answer[0][0]) &&
            answer[1][0] === answer2.toString() &&
            compareFloats(answer[2][0], answer3) &&
            isValidMoneyAnswer(answer[2][0])
          );
        }}
        extraSymbol="decimalPoint"
        sentences={[
          `£${poundsASubtractOne} ${SUB} £${poundsB} = £<ans/>`,
          `100p ${SUB} ${pence}p = <ans/>p`,
          `£${poundsA} ${SUB} ${poundsBToDisplay} = £<ans/>`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString()],
            [answer3.toFixed(2)]
          ]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axy',
  description: 'axy',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Subtract'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(20),
      penceA: z.number().int().min(12).max(99),
      poundsB: z.number().int().min(2).max(19),
      penceB: z.number().int().min(11).max(98)
    })
    .refine(val => val.penceA % 10 !== 0, 'penceA must not be a multiple of 10')
    .refine(val => val.poundsB < val.poundsA, 'poundsB must be less than poundsA')
    .refine(
      val => val.penceB % 10 !== 0 && val.penceB < val.penceA,
      'penceB must not be a multiple of 10 and must be less then penceA'
    ),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 20);
    const penceA = randomIntegerInclusive(12, 99, {
      constraint: x => x % 10 !== 0
    });
    const poundsB = randomIntegerInclusive(2, 19, {
      constraint: x => x < poundsA
    });
    const penceB = randomIntegerInclusive(11, 98, {
      constraint: x => x % 10 !== 0 && x < penceA
    });

    return { poundsA, penceA, poundsB, penceB };
  },
  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB },
      translate
    } = props;

    // Pounds and pence sentences display
    const poundsAToDisplay = numToCurrency({ amount: (poundsA * 100 + penceA) / 100 });
    const poundsBToDisplay = numToCurrency({ amount: (poundsB * 100 + penceB) / 100 });

    // Answer
    const ans = (poundsA * 100 + penceA - (poundsB * 100 + penceB)) / 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        extraSymbol="decimalPoint"
        sentence={`${poundsAToDisplay} ${SUB} ${poundsBToDisplay} = £<ans/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ans.toLocaleString(undefined, {
              minimumFractionDigits: 2
            })
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axz',
  description: 'axz',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z
    .object({
      poundsNote: numberEnum([20, 50]),
      poundsA: z.number().int().min(5).max(9),
      penceA: z.number().int().min(1).max(49),
      poundsB: z.number().int().min(1).max(4),
      penceB: z.number().int().min(11).max(49),
      name: nameSchema,
      clothingItemA: clothingItemsSchema,
      clothingItemB: clothingItemsSchema
    })
    .refine(val => val.penceA % 10 !== 0, 'penceA must not be a multiple of 10')
    .refine(val => val.penceB % 5 !== 0, 'penceB must not be a multiple of 5'),
  simpleGenerator: () => {
    const poundsNote = getRandomFromArray([20, 50] as const);
    const poundsA = randomIntegerInclusive(5, 9);
    const penceA = randomIntegerInclusive(1, 49, {
      constraint: x => x % 10 !== 0
    });
    const poundsB = randomIntegerInclusive(1, 4);
    const penceB = randomIntegerInclusive(11, 49, {
      constraint: x => x % 5 !== 0
    });

    const name = getRandomName();

    const [clothingItemA, clothingItemB] = getRandomUniqueClothingNames(2);

    return { poundsNote, poundsA, penceA, poundsB, penceB, name, clothingItemA, clothingItemB };
  },
  Component: props => {
    const {
      question: {
        poundsNote,
        poundsA,
        penceA,
        poundsB,
        penceB,
        name,
        clothingItemA,
        clothingItemB
      },
      translate,
      displayMode
    } = props;

    // Pounds and pence sentences display
    const poundsADisplay = numToCurrency({ amount: (poundsA * 100 + penceA) / 100 });
    const poundsBDisplay = numToCurrency({ amount: (poundsB * 100 + penceB) / 100 });

    // Answer
    const ans = (poundsNote * 100 - (poundsA * 100 + penceA + (poundsB * 100 + penceB))) / 100;

    return (
      <QF1ContentAndSentence
        sentence={'£<ans />'}
        title={translate.instructions.characterBuysAItemXAndAItemYCharacterPaysWithZPoundsNote({
          name,
          item1: clothingItemA.toLowerCase(),
          item2: clothingItemB.toLowerCase(),
          poundsNote
        })}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            ]}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  alignItems: 'center',
                  width: dimens.width / 2
                }}
              >
                {clothingItemSVG(clothingItemA, dimens.height / 2, dimens.height / 2)}
                <Text
                  style={{
                    fontSize: displayMode === 'digital' ? 32 : 50,
                    padding: 8,
                    marginTop: 6,
                    backgroundColor: colors.prussianBlue,
                    color: colors.white
                  }}
                >
                  {poundsADisplay}
                </Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  width: dimens.width / 2
                }}
              >
                {clothingItemSVG(clothingItemB, dimens.height / 2, dimens.height / 2)}
                <Text
                  style={{
                    fontSize: displayMode === 'digital' ? 32 : 50,
                    padding: 8,
                    marginTop: 6,
                    backgroundColor: colors.prussianBlue,
                    color: colors.white
                  }}
                >
                  {poundsBDisplay}
                </Text>
              </View>
            </View>
            <Text
              variant="WRN400"
              style={{ fontSize: displayMode === 'digital' ? 32 : 50, alignSelf: 'flex-start' }}
            >
              {translate.answerSentences.workOutHowMuchChangeCharacterWillGet({
                name
              })}
            </Text>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateWithMoney',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
