import { Camera } from 'expo-camera';
import { Platform } from 'react-native';
import showAlert from './showAlert';
import { noop } from 'common/src/utils/flowControl';

const isWeb = Platform.OS === 'web';

/** Check camera permissions, and runs the appropriate callback. */
export const checkCameraPermissions = async function (
  successCallback: () => void,
  failureCallback: () => void = noop
) {
  try {
    const webHasCamera = isWeb && (await Camera.isAvailableAsync());
    if (isWeb && !webHasCamera) {
      showAlert(
        'Your device does not have a useable camera. You can enter the code manually.',
        'Scanning unavailable'
      );
    }

    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      successCallback();
    } else if (status === 'denied') {
      showAlert('This app does not have permission to access the camera.', 'Scanning unavailable');
    }
  } catch {
    showAlert(
      'Your device does not have a useable camera. You can enter the code manually.',
      'Scanning unavailable',
      [
        {
          text: 'Ok',
          onPress: failureCallback
        }
      ]
    );
  }
};
