import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  islandAOnGridShapeSchema,
  islandBOnGridShapeNames,
  islandBOnGridShapeSchema,
  islandAOnGridShapeNames,
  leavesOnGridShapeNames,
  leavesOnGridShapeSchema,
  shapesOnGridProperties,
  curvedShapeOnGridShapeSchema,
  curvedShapeOnGridShapeNames
} from '../../../../utils/shapesOnGrid';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { isWithinRange } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isValidTriangle, triangleArea } from '../../../../utils/shapes';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import { isInRange } from '../../../../utils/matchers';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSc',
  description: 'aSc',
  keywords: ['Estimate', 'Area', 'Centimetres squared', 'cm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    points: z.array(
      z.object({ x: z.number().int().min(0).max(5), y: z.number().int().min(0).max(5) })
    ),
    area: z.number()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['parallelograms', 'trapeziums', 'triangles'] as const);

    const { points, area } = rejectionSample(
      () => {
        const x1 = 0;
        const y1 = 0;
        const width = randomIntegerInclusive(3, 4);
        const xOffset = randomIntegerInclusive(1, 2);
        const height = randomIntegerInclusive(1, 4 - y1, {
          constraint: x => (shape === 'parallelograms' && x !== width) || shape !== 'parallelograms'
        });

        let points = [];
        let area = 0;
        switch (shape) {
          case 'trapeziums': {
            const width = randomIntegerInclusive(3, 4);
            const xOffset = 1;
            points = [
              { x: x1, y: y1 },
              { x: x1 + width, y: y1 },
              { x: x1 + width - xOffset, y: y1 + height },
              { x: x1 + xOffset, y: y1 + height }
            ];
            area = 0.5 * (width + width - 2 * xOffset) * height;
            break;
          }
          case 'parallelograms':
            points = [
              { x: x1, y: y1 },
              { x: x1 + width, y: y1 },
              { x: x1 + width + xOffset, y: y1 + height },
              { x: x1 + xOffset, y: y1 + height }
            ];
            area = width * height;
            break;
          case 'triangles': {
            const [x2, x3] = randomUniqueIntegersInclusive(1, 4, 2);
            const [y2, y3] = randomUniqueIntegersInclusive(1, 4, 2);
            points = [
              { x: x1, y: y1 },
              { x: x2, y: y2 },
              { x: x3, y: y3 }
            ];
            area = triangleArea(
              [points[0].x, points[0].y],
              [points[1].x, points[1].y],
              [points[2].x, points[2].y]
            );
            break;
          }
        }
        return { points, area };
      },
      val =>
        val.points.every(point => point.x >= 0 && point.x <= 5 && point.y >= 0 && point.y <= 5) &&
        ((shape === 'triangles' &&
          isValidTriangle(
            [val.points[0].x, val.points[0].y],
            [val.points[1].x, val.points[1].y],
            [val.points[2].x, val.points[2].y]
          )) ||
          shape !== 'triangles') &&
        val.area >= 5
    );

    return { points, area };
  },
  Component: ({ question, translate }) => {
    const { points, area } = question;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.onTheGridTheAreaOfEachSquareIsX(
          translate.units.numberOfCm2(1)
        )}<br/>${translate.instructions.estimateAreaOfShape()}`}
        sentence={translate.answerSentences.ansCmSquared()}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            dimens={dimens}
            points={points.map(val => [val.x, val.y])}
            cellSizeLabel={translate.units.numberOfCm(1)}
            yMax={5}
            xMax={5}
          />
        )}
        inputMaxCharacters={4}
        testCorrect={ans => isInRange(area - 1, area + 1)(parseFloat(ans[0]))}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [area.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aSd',
  description: 'aSd',
  keywords: ['Estimate', 'Area', 'Centimetres squared', 'cm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    shape: curvedShapeOnGridShapeSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(curvedShapeOnGridShapeNames);

    return { shape };
  },
  Component: ({ question, translate }) => {
    const { shape } = question;

    const { area, svgName } = shapesOnGridProperties[shape];

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.onTheGridTheAreaOfEachSquareIsX(
          translate.units.numberOfCm2(1)
        )}<br/>${translate.instructions.estimateAreaOfShape()}`}
        sentence={translate.answerSentences.ansCmSquared()}
        inputMaxCharacters={2}
        testCorrect={ans => isInRange(area - 1, area + 1)(parseFloat(ans[0]))}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [area.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
        Content={({ dimens }) => (
          <AssetSvg name={svgName as SvgName} width={dimens.width} height={dimens.height} />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'aSe',
  description: 'aSe',
  keywords: ['Estimate', 'Area', 'Metres squared', 'm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    shape: curvedShapeOnGridShapeSchema,
    incorrectAnswers: z.number().int().min(8).max(20).array().length(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(curvedShapeOnGridShapeNames);

    const { area } = shapesOnGridProperties[shape];
    const incorrectAnswers = randomUniqueIntegersInclusive(8, 20, 3, {
      constraint: x => !isInRange(area - 2, area + 2)(x)
    });

    return { shape, incorrectAnswers };
  },
  Component: ({ question, translate }) => {
    const { shape, incorrectAnswers } = question;

    const { area, svgName } = shapesOnGridProperties[shape];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToEstimateArea()}
        pdfTitle={translate.instructions.useCardsToEstimateArea()}
        sentence={translate.answerSentences.ansMSquared()}
        sentencesStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svgName as SvgName} width={dimens.width} height={dimens.height} />
        )}
        itemVariant="square"
        actionPanelVariant="end"
        mainPanelStyle={{ flexDirection: 'row' }}
        questionHeight={1000}
        items={shuffle([area, ...incorrectAnswers], { random: seededRandom(question) })}
        testCorrect={[area]}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aSf',
  description: 'aSf',
  keywords: ['Estimate', 'Area', 'Centimetres squared', 'cm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    shape: curvedShapeOnGridShapeSchema,
    incorrectAnswers: z.number().int().min(8).max(20).array().length(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(curvedShapeOnGridShapeNames);

    const { area } = shapesOnGridProperties[shape];
    const incorrectAnswers = randomUniqueIntegersInclusive(8, 20, 3, {
      constraint: x => !isInRange(area - 2, area + 2)(x)
    });

    return { shape, incorrectAnswers };
  },
  Component: ({ question, translate }) => {
    const { shape, incorrectAnswers } = question;

    const { area, svgName } = shapesOnGridProperties[shape];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAShapeOnCmSquaredGridSelectArea()}
        pdfTitle={translate.instructions.hereIsAShapeOnCmSquaredGridCircleArea()}
        Content={({ dimens }) => (
          <AssetSvg
            name={svgName as SvgName}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        questionHeight={900}
        renderItems={shuffle([area, ...incorrectAnswers], { random: seededRandom(question) }).map(
          value => ({
            value,
            component: (
              <Text variant="WRN700" style={{ textAlign: 'center' }}>
                {translate.units.numberOfCm2(value)}
              </Text>
            )
          })
        )}
        itemLayout="row"
        testCorrect={[area]}
        numItems={4}
      />
    );
  },
  questionHeight: 900
});

const Question4v2 = newQuestionContent({
  uid: 'aSf2',
  description: 'aSf',
  keywords: ['Estimate', 'Area', 'Centimetres squared', 'cm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    shape: curvedShapeOnGridShapeSchema,
    incorrectAnswers: z.number().int().min(8).max(20).array().length(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(curvedShapeOnGridShapeNames);

    const { area } = shapesOnGridProperties[shape];
    const incorrectAnswers = randomUniqueIntegersInclusive(8, 20, 3, {
      constraint: x => !isInRange(area - 2, area + 2)(x)
    });

    return { shape, incorrectAnswers };
  },
  Component: ({ question, translate }) => {
    const { shape, incorrectAnswers } = question;

    const { area, svgName } = shapesOnGridProperties[shape];

    const selectables = shuffle(
      [area, ...incorrectAnswers].map((val, i) => [
        ['A', 'B', 'C', 'D'][i],
        translate.units.numberOfCm2(val)
      ]),
      { random: seededRandom(question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.hereIsAShapeOnCmSquaredGridSelectArea()}
        pdfTitle={translate.instructions.hereIsAShapeOnCmSquaredGridCircleArea()}
        questionHeight={900}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        leftContent={
          <MeasureView>
            {dimens => (
              <AssetSvg name={svgName as SvgName} width={dimens.width} height={dimens.height} />
            )}
          </MeasureView>
        }
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aSg',
  description: 'aSg',
  keywords: ['Estimate', 'Area', 'Centimetres squared', 'cm2', 'Shape', 'Grid', 'Squares'],
  schema: z.object({
    shapeA: leavesOnGridShapeSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(leavesOnGridShapeNames);

    return { shapeA };
  },
  Component: ({ question, translate }) => {
    const { shapeA } = question;

    const { area: shapeAArea, svgName: shapeASvgName } = shapesOnGridProperties[shapeA];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.estimateAreaOfLeaf()}
        sentence={translate.answerSentences.ansCmSquared()}
        testCorrect={answer => isWithinRange(parseInt(answer[0]), shapeAArea, 2)}
        pdfDirection="column"
        questionHeight={900}
        inputMaxCharacters={2}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={shapeASvgName as SvgName} height={dimens.height} width={dimens.width} />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSh',
  description: 'aSh',
  keywords: [
    'Estimate',
    'Area',
    'Kilometres squared',
    'km2',
    'Shape',
    'Grid',
    'Squares',
    'Greater',
    'Smaller'
  ],
  schema: z.object({
    shapeA: islandAOnGridShapeSchema,
    shapeB: islandBOnGridShapeSchema,
    isGreater: z.boolean()
  }),
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(islandAOnGridShapeNames);
    const { area } = shapesOnGridProperties[shapeA];

    const shapeBIdx = randomIntegerInclusive(0, 5, {
      constraint: x =>
        !isWithinRange(shapesOnGridProperties[islandBOnGridShapeNames[x]].area, area, 3)
    });
    const shapeB = islandBOnGridShapeNames[shapeBIdx];
    const isGreater = getRandomBoolean();

    return { shapeA, shapeB, isGreater };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB, isGreater } = question;

    const { area: shapeAArea, svgName: shapeASvgName } = shapesOnGridProperties[shapeA];
    const { area: shapeBArea, svgName: shapeBSvgName } = shapesOnGridProperties[shapeB];

    const title = isGreater
      ? 'selectIslandThatHasGreaterEstimatedArea'
      : 'selectIslandThatHasSmallerEstimatedArea';
    const pdfTitle = isGreater
      ? 'circleIslandThatHasGreaterEstimatedArea'
      : 'circleIslandThatHasSmallerEstimatedArea';

    const [greaterArea, smallerArea] =
      shapeAArea > shapeBArea ? [shapeAArea, shapeBArea] : [shapeBArea, shapeAArea];

    const answer = isGreater ? greaterArea : smallerArea;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions[title]()}
        pdfTitle={translate.instructions[pdfTitle]()}
        testCorrect={[answer]}
        numItems={2}
        renderItems={({ dimens }) => [
          {
            value: shapeAArea,
            component: (
              <AssetSvg
                name={shapeASvgName as SvgName}
                height={dimens.height}
                width={dimens.width * 0.9}
              />
            )
          },
          {
            value: shapeBArea,
            component: (
              <AssetSvg
                name={shapeBSvgName as SvgName}
                height={dimens.height}
                width={dimens.width * 0.9}
              />
            )
          }
        ]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateArea',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4],
  unpublishedQuestionTypes: []
});
export default SmallStep;
