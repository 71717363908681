import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext, useMemo } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import SelectableNumberLine, {
  SelectableNumberLineWithState
} from '../representations/Number Line/SelectableNumberLine';
import { filledArray } from '../../../utils/collections';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  tickValues: string[][];
  selectableIndexes?: number[][];
  testCorrect: number[][] | ((userAnswer: number[][]) => boolean);
  /**
   * Custom vertical padding for text under ticks. Optional prop, defaults to 8.
   */
  textVerticalPadding?: number;
  tickOffset?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: number[][]; answerText?: string };
  multiSelect?: boolean;
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
};

export default function QF17dSelectTheNumberLine({
  title,
  pdfTitle,
  tickValues,
  testCorrect: testCorrectProp,
  textVerticalPadding,
  tickOffset,
  questionHeight,
  customMarkSchemeAnswer,
  selectableIndexes,
  multiSelect,
  customFontSize,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  if (isPdf) {
    const emptyState: number[][] = filledArray([], tickValues.length);

    const markSchemeAnswer =
      typeof testCorrectProp !== 'function' && displayMode === 'markscheme'
        ? testCorrectProp
        : customMarkSchemeAnswer?.answersToDisplay ?? emptyState;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <SelectableNumberLine
                  tickValues={tickValues}
                  textVerticalPad={textVerticalPadding}
                  dimens={dimens}
                  selectableIndexes={selectableIndexes}
                  multiSelect={multiSelect}
                  userAnswer={displayMode === 'pdf' ? emptyState : markSchemeAnswer}
                  customFontSize={customFontSize}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <SelectableNumberLineWithState
              id="numberline"
              testCorrect={testCorrect}
              tickValues={tickValues}
              textVerticalPad={textVerticalPadding}
              dimens={dimens}
              selectableIndexes={selectableIndexes}
              multiSelect={multiSelect}
              customFontSize={customFontSize}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
