import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getGenderFromKs1Name, getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  displayMoney,
  penceToPoundsAndPence,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { sumNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bix',
  description: 'bix',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money', 'Subtract', 'Change'],
  schema: z.object({
    moneyObject: z
      .object({
        value: z.number().min(5).max(6000),
        denominations: z.string().array(),
        amountSpent: z.number().int().min(1).max(99)
      })
      .refine(val => val.amountSpent < val.value),
    characterName: ks1NameSchema,
    isPence: z.boolean()
  }),
  simpleGenerator: () => {
    const isPence = getRandomBoolean();

    const { penceMoneyObject } = rejectionSample(
      () => {
        const value = randomIntegerInclusive(7, 99);

        const amountSpent = randomIntegerInclusive(5, value - 1);

        const denominations = totalPenceToPoundsAndPence(value);

        const values = denominations.map(str => parseInt(str.replace('p', '')));

        const denominationUsedCount: number[] = [];
        let i = 0;

        while (sumNumberArray(denominationUsedCount) < amountSpent) {
          if (i >= values.length) {
            break; // Avoid infinite loop if values are exhausted
          }
          denominationUsedCount.push(values[i]);
          i++;
        }

        const penceMoneyObject = {
          value,
          denominations: denominations,
          amountSpent
        };

        return {
          penceMoneyObject,
          denominationUsedCount
        };
      },
      ({ penceMoneyObject, denominationUsedCount }) =>
        penceMoneyObject.denominations.length <= 8 &&
        // Checks that every coin is used
        denominationUsedCount.length === penceMoneyObject.denominations.length
    );

    const { poundsMoneyObject } = rejectionSample(
      () => {
        const value = randomIntegerInclusiveStep(200, 6000, 100);
        const amountSpent = randomIntegerInclusiveStep(100, value - 100, 100) / 100;

        const poundsMoneyObject = {
          value,
          denominations: totalPenceToPoundsAndPence(value),
          amountSpent
        };

        return { poundsMoneyObject };
      },
      ({ poundsMoneyObject }) =>
        poundsMoneyObject.denominations.length <= 8 &&
        !poundsMoneyObject.denominations.includes('£50')
    );

    const moneyObject = isPence ? penceMoneyObject : poundsMoneyObject;

    const characterName = getRandomKs1Name();
    return { moneyObject, characterName, isPence };
  },
  Component: ({ question, translate, displayMode }) => {
    const { moneyObject, characterName, isPence } = question;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const answer = isPence
      ? (moneyObject.value - moneyObject.amountSpent).toString()
      : (moneyObject.value / 100 - moneyObject.amountSpent).toString();

    const sentence = isPence
      ? translate.ks1AnswerSentences.ansP()
      : translate.ks1AnswerSentences.poundAns();

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.charHasThisMoney(characterName)}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center'
              }}
            >
              {displayMoney(
                moneyObject.denominations,
                displayMode === 'digital' ? 80 : 130,
                displayMode === 'digital' ? 80 : 130,
                true
              )}
            </View>
            <TextStructure
              sentence={
                isPence
                  ? translate.ks1Instructions.xSpendsYP(heOrShe, moneyObject.amountSpent)
                  : translate.ks1Instructions.xSpendsPoundsY(heOrShe, moneyObject.amountSpent)
              }
            />
            <TextStructure sentence={translate.ks1AnswerSentences.howMuchChangeDoesXGet(heOrShe)} />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biy',
  description: 'biy',
  keywords: ['Pounds', 'Pence', 'Money', 'Subtract', 'Change', 'Notes', 'Coins'],
  schema: z
    .object({
      characterName: ks1NameSchema,
      startingAmount: numberEnum([200, 500, 1000, 2000]),
      amountSpent: z.number().int().min(5).max(1995).step(5),
      items: z
        .object({
          value: z.enum(['A', 'B', 'C', 'D']),
          moneyValue: z.number(),
          denominations: z.string().array()
        })
        .array()
    })
    .refine(val => val.startingAmount > val.amountSpent),
  questionHeight: 1000,
  simpleGenerator: () => {
    const characterName = getRandomKs1Name();

    const startingAmount = getRandomFromArray([200, 500, 1000, 2000] as const);

    const amountSpent = randomIntegerInclusiveStep(5, startingAmount - 5, 5);

    const incorrectValues = ['B', 'C', 'D'] as const;

    const correctItem = {
      value: 'A' as const,
      moneyValue: startingAmount - amountSpent,
      denominations: totalPenceToPoundsAndPence(startingAmount - amountSpent)
    };

    const incorrectMoneyValues = [
      startingAmount - amountSpent + 5,
      startingAmount - amountSpent - 5,
      startingAmount - amountSpent + 10,
      startingAmount - amountSpent - 10,
      startingAmount - amountSpent + 100,
      amountSpent
    ].filter(num => num > 0);

    const incorrectItems = getRandomSubArrayFromArray(incorrectMoneyValues, 3).map(
      (moneyValue, index) => {
        return {
          value: incorrectValues[index],
          moneyValue,
          denominations: totalPenceToPoundsAndPence(moneyValue)
        };
      }
    );

    const items = shuffle([...incorrectItems, correctItem]);

    return {
      characterName,
      startingAmount,
      amountSpent,
      items
    };
  },

  Component: props => {
    const {
      question: { characterName, startingAmount, amountSpent, items },
      translate,
      displayMode
    } = props;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const poundsAndPenceSpent = penceToPoundsAndPence(amountSpent);

    const poundsAndPenceSpentString =
      poundsAndPenceSpent.pence && poundsAndPenceSpent.pounds
        ? `£${poundsAndPenceSpent.pounds} ${translate.ks1MiscStrings.and()} ${
            poundsAndPenceSpent.pence
          }p`
        : !poundsAndPenceSpent.pounds
        ? `${poundsAndPenceSpent.pence}p`
        : `£${poundsAndPenceSpent.pounds}`;

    const selectableHasTwoNotes =
      items.filter(
        ({ denominations }) =>
          denominations.filter(denomination => denomination === '£10' || denomination === '£5')
            .length === 2
      ).length > 0;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.charHasPoundsXHeSheBuysAComicForYMoneySelectTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        pdfTitle={translate.ks1PDFInstructions.charHasPoundsXHeSheBuysAComicForYMoneyTickTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        testCorrect={['A']}
        questionHeight={1000}
        pdfShowBorder
        renderItems={items.map(({ value, denominations }) => {
          return {
            value,
            component: (
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: 4 }}>
                {displayMoney(
                  denominations,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  true
                )}
              </View>
            )
          };
        })}
        numItems={4}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'biz',
  description: 'biz',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money', 'Subtract', 'Change'],
  schema: z
    .object({
      moneyObject: z.object({
        value: numberEnum([200, 500, 1000, 2000]),
        denominations: z.string().array()
      }),
      characterName: ks1NameSchema,
      amountSpent: z.number().int().min(10).max(1890)
    })
    .refine(val => val.amountSpent < val.moneyObject.value)
    .refine(val => val.moneyObject.value - val.amountSpent >= 110),
  simpleGenerator: () => {
    const value = getRandomFromArray([200, 500, 1000, 2000] as const);
    const moneyObject = { value, denominations: totalPenceToPoundsAndPence(value) };

    const amountSpent = randomIntegerInclusiveStep(10, moneyObject.value - 110, 10, {
      constraint: x => x % 100 !== 0
    });

    const characterName = getRandomKs1Name();
    return { moneyObject, characterName, amountSpent };
  },
  Component: ({ question, translate, displayMode }) => {
    const { moneyObject, characterName, amountSpent } = question;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const answerPoundsAndPence = penceToPoundsAndPence(moneyObject.value - amountSpent);

    const spentPoundsAndPence = penceToPoundsAndPence(amountSpent);

    const sentence = translate.ks1AnswerSentences.poundAnsAndAnsP();

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.charHasThisMoney(characterName)}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          answerPoundsAndPence.pounds.toString(),
          answerPoundsAndPence.pence.toString()
        ]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center'
              }}
            >
              {displayMoney(
                moneyObject.denominations,
                displayMode === 'digital' ? 100 : 130,
                displayMode === 'digital' ? 100 : 130
              )}
            </View>
            <TextStructure
              sentence={
                spentPoundsAndPence.pounds === 0
                  ? translate.ks1Instructions.xSpendsYP(heOrShe, spentPoundsAndPence.pence)
                  : translate.ks1Instructions.xSpendsPoundsYAndZP(
                      heOrShe,
                      spentPoundsAndPence.pounds,
                      spentPoundsAndPence.pence
                    )
              }
            />
            <TextStructure sentence={translate.ks1AnswerSentences.howMuchChangeDoesXGet(heOrShe)} />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindChange',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
