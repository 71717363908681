import { createContext, useContext, type PropsWithChildren } from 'react';

export type MarkupAssetsType = { elements?: Record<string, JSX.Element> };

export const MarkupAssetsContext = createContext<MarkupAssetsType>({});

export default function MarkupAssets({ elements, children }: PropsWithChildren<MarkupAssetsType>) {
  const parentAssets = useContext(MarkupAssetsContext);
  return (
    <MarkupAssetsContext.Provider value={{ elements: { ...parentAssets.elements, ...elements } }}>
      {children}
    </MarkupAssetsContext.Provider>
  );
}
