import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomNumber,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { arraysHaveSameContents, countRange, range } from '../../../../utils/collections';
import QF59DrawLineGraph from '../../../../components/question/questionFormats/QF59DrawLineGraph';
import { numberEnum } from '../../../../utils/zod';
import { roundToTheNearest } from '../../../../utils/math';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import TableWithLeftHeaders from '../../../../components/question/representations/TableWithLeftHeaders';
import { colors } from '../../../../theme/colors';
import { getDayTemperaturesGenerator } from '../../../../utils/graphs';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import LineGraph from '../../../../components/question/representations/Coordinates/LineGraph';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { displayDigitalTime } from '../../../../utils/time';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aze',
  description: 'aze',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  questionHeight: 1000,
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      timeStep: numberEnum([1, 2]),
      temperatures: z.number().int().array()
    })
    .refine(
      ({ tempMin, temperatures }) => temperatures.every(it => it >= tempMin && it <= tempMin + 6),
      'All temperatures must be between tempMin and tempMin+6 inclusive.'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusive(0, 6);
    const tempMax = tempMin + 6;
    const timeMin = randomIntegerInclusive(6, 11);
    const timeStep = getRandomFromArray([1, 2] as const);

    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      randomNumber(tempMax - 2, tempMax),
      timeMin,
      timeMin + timeStep * 6,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 6 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(Math.round);

    return { tempMin, timeMin, timeStep, temperatures };
  },
  Component: ({ question: { tempMin, timeMin, timeStep, temperatures }, translate }) => {
    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.dragPointsToCompleteTheLineGraphPdf()}
        correctAnswer={temperatures}
        gridProps={{
          xAxis: tempMin,
          xMin: timeMin,
          xMax: timeMin + (temperatures.length - 1) * timeStep,
          xStepSize: timeStep,
          yAxis: timeMin,
          yMin: tempMin,
          yMax: tempMin + 6,
          yStepSize: 1,
          yAxisLabel: translate.misc.temperatureC(),
          xAxisLabel: translate.misc.time(),
          xLabels: range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
            displayDigitalTime(x, 0, true, '24')
          ),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={1}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azf',
  description: 'azf',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      temperatures: z.number().int().array()
    })
    .refine(
      ({ tempMin, temperatures }) => temperatures.every(it => it >= tempMin && it <= tempMin + 12),
      'All temperatures must be between tempMin and tempMin+12 inclusive.'
    )
    .refine(
      ({ temperatures }) => temperatures.every(it => it % 2 === 0),
      'All temperatures must be even.'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusiveStep(0, 10, 2);
    const tempMax = tempMin + 12;
    const timeMin = randomIntegerInclusive(6, 11);
    const pointTempMax = getRandomFromArray([tempMax - 2, tempMax]);

    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      pointTempMax,
      timeMin,
      timeMin + 12,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 12, 2)
      .map(dayTemperatures)
      .map(i => (Math.round(i) % 2 === 0 ? Math.round(i) : Math.round(i) + 1));

    return { tempMin, timeMin, temperatures };
  },
  Component: ({ question: { tempMin, timeMin, temperatures }, translate }) => {
    const timeStep = 2;
    const tempStep = 2;
    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.dragPointsToCompleteTheLineGraphPdf()}
        correctAnswer={temperatures}
        gridProps={{
          xAxis: tempMin,
          xMin: timeMin,
          xMax: timeMin + (temperatures.length - 1) * timeStep,
          xStepSize: timeStep,
          yAxis: timeMin,
          yMin: tempMin,
          yMax: tempMin + 12,
          yStepSize: tempStep,
          yAxisLabel: translate.misc.temperatureC(),
          xAxisLabel: translate.misc.time(),
          xLabels: range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
            displayDigitalTime(x, 0, true, '24')
          ),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={2}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'azg',
  description: 'azg',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      temperatures: z.number().int().array()
    })
    .refine(
      ({ tempMin, temperatures }) => temperatures.every(it => it >= tempMin && it <= tempMin + 12),
      'All temperatures must be between tempMin and tempMin+12 inclusive.'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusiveStep(0, 10, 2);
    const tempMax = tempMin + 12;
    const timeMin = randomIntegerInclusive(6, 11);
    const pointTempMax = getRandomFromArray([tempMax - 2, tempMax]);

    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      pointTempMax,
      timeMin,
      timeMin + 12,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 12, 2)
      .map(dayTemperatures)
      .map(Math.round);

    return { tempMin, timeMin, temperatures };
  },
  Component: ({ question: { tempMin, timeMin, temperatures }, translate }) => {
    const timeStep = 2;
    const tempStep = 2;
    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.dragPointsToCompleteTheLineGraphPdf()}
        correctAnswer={temperatures}
        gridProps={{
          xAxis: tempMin,
          xMin: timeMin,
          xMax: timeMin + (temperatures.length - 1) * timeStep,
          xStepSize: timeStep,
          yAxis: timeMin,
          yMin: tempMin,
          yMax: tempMin + 12,
          yStepSize: tempStep,
          yAxisLabel: translate.misc.temperatureC(),
          xAxisLabel: translate.misc.time(),
          xLabels: range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
            displayDigitalTime(x, 0, true, '24')
          ),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={1}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'azh',
  description: 'azh',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      timeStep: numberEnum([1, 2]),
      temperatures: z.number().int().array(),
      incorrectTime: z.number().int(),
      incorrectTemp: z.number().int()
    })
    .refine(
      ({ tempMin, temperatures, incorrectTemp }) =>
        [...temperatures, incorrectTemp].every(it => it >= tempMin && it <= tempMin + 6),
      'All temperatures must be between tempMin and tempMin+6 inclusive.'
    )
    .refine(
      ({ timeMin, timeStep, temperatures, incorrectTime }) =>
        (incorrectTime - timeMin) % timeStep === 0 &&
        (incorrectTime - timeMin) / timeStep <= temperatures.length - 1,
      'incorrectTime must be a valid time'
    )
    .refine(
      ({ timeMin, timeStep, temperatures, incorrectTime, incorrectTemp }) =>
        temperatures[(incorrectTime - timeMin) / timeStep] !== incorrectTemp,
      'incorrectTemp must differ from the correct answer!'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusive(0, 6);
    const tempMax = tempMin + 6;
    const timeMin = randomIntegerInclusive(6, 11);
    const timeStep = getRandomFromArray([1, 2] as const);

    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      randomNumber(tempMax - 2, tempMax),
      timeMin,
      timeMin + timeStep * 6,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 6 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(Math.round);

    const incorrectTimeIndex = randomIntegerInclusive(0, 6);
    const incorrectTime = timeMin + timeStep * incorrectTimeIndex;
    const incorrectTemp = randomIntegerInclusive(tempMin, tempMax, {
      // Mustn't be correct!
      constraint: x => x !== temperatures[incorrectTimeIndex]
    });

    return {
      tempMin,
      timeMin,
      timeStep,
      temperatures,
      incorrectTime,
      incorrectTemp
    };
  },
  Component: ({
    question: { tempMin, timeMin, timeStep, temperatures, incorrectTime, incorrectTemp },
    translate
  }) => {
    const incorrectTimeIndex = (incorrectTime - timeMin) / timeStep;
    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragIncorrectPointsToCorrectLineGraph(1)}
        pdfTitle={translate.instructions.dragIncorrectPointsToCorrectLineGraphPdf(1)}
        defaultState={temperatures.map((x, i) => (i === incorrectTimeIndex ? incorrectTemp : x))}
        correctAnswer={temperatures}
        gridProps={{
          xAxis: tempMin,
          xMin: timeMin,
          xMax: timeMin + (temperatures.length - 1) * timeStep,
          xStepSize: timeStep,
          yAxis: timeMin,
          yMin: tempMin,
          yMax: tempMin + 6,
          yStepSize: 1,
          yAxisLabel: translate.misc.temperatureC(),
          xAxisLabel: translate.misc.time(),
          xLabels: range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
            displayDigitalTime(x, 0, true, '24')
          ),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={1}
        questionHeight={1000}
        pdfVariant="fixIncorrectPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'azi',
  description: 'azi',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      temperatures: z.number().int().array(),
      incorrectTimes: z.array(z.number().int()),
      incorrectTemps: z.array(z.number().int())
    })
    .refine(
      ({ tempMin, temperatures, incorrectTemps }) =>
        [...temperatures, ...incorrectTemps].every(it => it >= tempMin && it <= tempMin + 12),
      'All temperatures must be between tempMin and tempMin+12 inclusive.'
    )
    .refine(
      ({ timeMin, temperatures, incorrectTimes }) =>
        incorrectTimes.map(
          time => (time - timeMin) % 2 === 0 && (time - timeMin) / 2 <= temperatures.length - 1
        ),
      'incorrectTime must be a valid time'
    )
    .refine(
      ({ timeMin, temperatures, incorrectTimes, incorrectTemps }) =>
        incorrectTimes.map(time => temperatures[(time - timeMin) / 2] !== incorrectTemps[time]),
      'incorrectTemp must differ from the correct answer!'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusive(0, 10);
    const tempMax = tempMin + 12;
    const timeMin = randomIntegerInclusive(6, 11);

    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      randomNumber(tempMax - 2, tempMax),
      timeMin,
      timeMin + 12,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 12, 2)
      .map(dayTemperatures)
      .map(Math.round);

    const numberOfIncorrect = randomIntegerInclusive(2, 4);
    const incorrectTimeIndex = randomUniqueIntegersInclusive(0, 6, numberOfIncorrect);
    const incorrectTimes = incorrectTimeIndex.map(idx => timeMin + 2 * idx);
    const incorrectTemps = incorrectTimes.map(incorrectTime =>
      randomIntegerInclusive(tempMin, tempMax, {
        // Mustn't be correct!
        constraint: x => x !== temperatures[incorrectTime]
      })
    );

    return {
      tempMin,
      timeMin,
      temperatures,
      incorrectTimes,
      incorrectTemps
    };
  },
  Component: ({
    question: { tempMin, timeMin, temperatures, incorrectTimes, incorrectTemps },
    translate
  }) => {
    const tempStep = 2;
    const timeStep = 2;
    const incorrectTimeIndexes = incorrectTimes.map(time => (time - timeMin) / timeStep);

    let incorrectTempIndex = -1;
    const defaultState = temperatures.map((x, i) => {
      if (incorrectTimeIndexes.includes(i)) {
        incorrectTempIndex++;
        return incorrectTemps[incorrectTempIndex];
      }
      return x;
    });

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragIncorrectPointsToCorrectLineGraph(2)}
        pdfTitle={translate.instructions.dragIncorrectPointsToCorrectLineGraphPdf(2)}
        defaultState={defaultState}
        correctAnswer={temperatures}
        gridProps={{
          xAxis: tempMin,
          xMin: timeMin,
          xMax: timeMin + (temperatures.length - 1) * timeStep,
          xStepSize: timeStep,
          yAxis: timeMin,
          yMin: tempMin,
          yMax: tempMin + 12,
          yStepSize: tempStep,
          yAxisLabel: 'temperature (°C)',
          xAxisLabel: 'time',
          xLabels: range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
            displayDigitalTime(x, 0, true, '24')
          ),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={1}
        questionHeight={1000}
        pdfVariant="fixIncorrectPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'azj',
  description: 'azj',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      tempMin: z.number().int().min(0),
      timeMin: z.number().int().min(0),
      timeStep: numberEnum([1, 2]),
      correctTemperatures: z.number().array().length(4),
      plottedTemperatures: z.number().array().length(4)
    })
    .refine(
      ({ tempMin, correctTemperatures, plottedTemperatures }) =>
        [...correctTemperatures, ...plottedTemperatures].every(
          it => it >= tempMin && it <= tempMin + 12
        ),
      'All temperatures must be between tempMin and tempMin+12 inclusive.'
    ),
  simpleGenerator: () => {
    const tempMin = randomIntegerInclusiveStep(0, 10, 2);
    const timeMin = randomIntegerInclusive(6, 11);
    const timeStep = getRandomFromArray([1, 2] as const);

    const startTemp = getRandomFromArray([tempMin + 1, tempMin + 3, tempMin + 5]);
    const correctTemperatures = [startTemp];
    countRange(3).forEach(() =>
      correctTemperatures.push(
        correctTemperatures[correctTemperatures.length - 1] + randomIntegerInclusive(1, 2)
      )
    );
    // Misconception that we're testing. There's no misconception a third of the time
    const failureVariant =
      Math.random() < 0.33
        ? 'no failure'
        : getRandomFromArray([
            'round up',
            'round down',
            'one wrong',
            'all wrong',
            'reverse'
          ] as const);

    let plottedTemperatures: number[] = (() => {
      switch (failureVariant) {
        case 'no failure':
          return correctTemperatures;
        case 'round up':
          return correctTemperatures.map(x => roundToTheNearest(x, 2, 'up'));
        case 'round down':
          return correctTemperatures.map(x => roundToTheNearest(x, 2, 'down'));
        case 'one wrong': {
          const wrongIndex = randomIntegerInclusive(0, 3);
          return correctTemperatures.map((x, i) =>
            i === wrongIndex ? x + randomIntegerInclusive(-2, 2, { constraint: x => x !== 0 }) : x
          );
        }
        case 'all wrong': {
          return correctTemperatures.map(
            x => x + randomIntegerInclusive(-1, 1, { constraint: x => x !== 0 })
          );
        }
        case 'reverse':
          return [...correctTemperatures].reverse();
      }
    })();

    // Make sure we don't violate the schema
    plottedTemperatures = plottedTemperatures.map(x =>
      Math.max(tempMin, Math.min(tempMin + 12, x))
    );

    return {
      tempMin,
      timeMin,
      timeStep,
      correctTemperatures,
      plottedTemperatures
    };
  },
  Component: ({
    question: { tempMin, timeMin, timeStep, correctTemperatures, plottedTemperatures },
    translate,
    displayMode
  }) => {
    const timeLabels = range(
      timeMin,
      timeMin + (correctTemperatures.length - 1) * timeStep,
      timeStep
    ).map(x => displayDigitalTime(x, 0, true, '24'));
    const drawnAccurately = arraysHaveSameContents(correctTemperatures, plottedTemperatures);

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.hasTheLineGraphBeenDrawnAccurately()}
        pdfTitle={translate.instructions.hasTheLineGraphBeenDrawnAccurately()}
        selectables={{ yes: translate.misc.Yes(), no: translate.misc.No() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        correctAnswer={[drawnAccurately ? 'yes' : 'no']}
        topContent={
          <TableWithLeftHeaders
            headers={['time', 'temperature  (°C)']}
            items={[timeLabels, correctTemperatures.map(it => it.toLocaleString())]}
            style={{ width: '80%', alignSelf: 'center', marginBottom: 20 }}
            headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
            textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
          />
        }
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xAxis={tempMin}
                xMin={timeMin}
                xMax={timeMin + (plottedTemperatures.length - 1) * timeStep}
                xStepSize={timeStep}
                yAxis={timeMin}
                yMin={tempMin}
                yMax={tempMin + 12}
                yStepSize={2}
                yAxisLabel={'temperature (°C)'}
                xAxisLabel={'time'}
                xLabels={timeLabels}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={plottedTemperatures} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawLineGraphs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
