import Toast from 'react-native-root-toast';
import { Dimensions, Platform, View } from 'react-native';
import { colors } from 'common/src/theme/colors';
import Text from 'common/src/components/typography/Text';
import CircledInfo from 'pupil-app/assets/svg/zest/circled-info';
import CircledExclamation from 'pupil-app/assets/svg/zest/circled-exclamation';
import useBreakpoints from '../hooks/useBreakpoints';
import { AssetSvg } from 'common/src/assets/svg';

/**
 * Component that represents the content present within the Toast.
 * Called within other Toast functions and components.
 */
const ToastContent = ({
  title,
  message,
  type = 'error'
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Type of toast to show - currently can either be:
   * - 'error', red toast with an exclamation mark icon;
   * - 'info', blue toast with an information icon.
   */
  type: 'error' | 'info';
}) => {
  const borderColor = (() => {
    switch (type) {
      case 'error':
        return colors.fieryRose;
      case 'info':
        return colors.infoBorder;
    }
  })();

  const { resize } = useBreakpoints();

  return (
    <View
      style={{
        flexDirection: 'row',
        // For some reason, we can't just set `maxWidth: '100%'` to keep the <ToastContent> in the bounds of the the
        // <ToastContainer> defined in react-native-root-toast. So, we've had to have a look at their code and set the
        // maxWidth manually to the same value they come up with.
        // Note that we don't use a hook to keep this value up to date as the screen changes size. This is because
        // react-native-root-toast doesn't either, and we want to match its behavior.
        maxWidth: Dimensions.get('window').width * 0.8
      }}
    >
      <View
        style={{
          width: 52 * resize,
          alignSelf: 'center',
          // iOS devices seem to nudge this View 3 pixels higher than it should be.
          // This is the only solution I can think of to fix this specifically for iOS:
          top: Platform.OS === 'ios' ? 3 : undefined
        }}
      >
        {type === 'error' && (
          <CircledExclamation
            width={32 * resize}
            height={32 * resize}
            fill={colors.white}
            style={{ alignSelf: 'center', margin: 10 * resize }}
          />
        )}
        {type === 'info' && (
          <CircledInfo
            width={32 * resize}
            height={32 * resize}
            fill={colors.white}
            style={{ alignSelf: 'center', margin: 10 * resize }}
          />
        )}
      </View>
      <View
        style={{
          borderLeftColor: borderColor,
          borderLeftWidth: 1,
          // iOS devices seem to nudge this View 3 pixels higher than it should be.
          // This is the only solution I can think of to fix this specifically for iOS:
          top: Platform.OS === 'ios' ? 3 : undefined,
          padding: 12 * resize,
          // The texts inside this view don't seem to take into account the width reserved for the icon to the left,
          // and are overflowing on the right. This padding visually stops the text overflowing.
          paddingRight: 62 * resize,
          maxWidth: '100%'
        }}
      >
        <Text
          variant="WRN700"
          style={{
            textAlign: 'left',
            fontSize: 22 * resize,
            lineHeight: 33 * resize,
            color: colors.white
          }}
        >
          {title}
        </Text>
        <Text
          variant="WRN400"
          style={{
            textAlign: 'left',
            fontSize: 22 * resize,
            lineHeight: 33 * resize,
            color: colors.white
          }}
        >
          {message}
        </Text>
      </View>
      <View
        style={{
          position: 'absolute',
          right: 0,
          // iOS devices seem to nudge this View 3 pixels higher than it should be.
          // This is the only solution I can think of to fix this specifically for iOS:
          top: Platform.OS === 'ios' ? 3 * resize : 0,
          margin: 10 * resize,
          width: 22 * resize
        }}
      >
        <AssetSvg
          name={'CloseCustomizable'}
          svgProps={{ fill: colors.white }}
          height={22 * resize}
          width={22 * resize}
        />
      </View>
    </View>
  );
};

/**
 * Util function to show a Toast popup.
 * Needs to be a util rather than a straight-forward React component, as this returns a Toast class from 'react-native-root-toast'.
 * */
const showToast = ({
  title,
  message,
  type = 'error',
  extraDuration = 0,
  delay = 0,
  resize = 1,
  persist = false
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Type of toast to show - currently can either be:
   * - 'error', red toast with an exclamation mark icon;
   * - 'info', blue toast with an information icon.
   */
  type: 'error' | 'info';
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Scale to resize elements of the Toast for different devices. Optional prop, defaults to 1.
   */
  resize?: number;
  /**
   * Boolean to determine if the Toast should persist indefinitely.
   * Optional prop, defaults to false.
   */
  persist?: boolean;
}) => {
  const [backgroundColor, borderColor] = (() => {
    switch (type) {
      case 'error':
        return [colors.danger, colors.fieryRose];
      case 'info':
        return [colors.infoToast, colors.infoBorder];
    }
  })();

  return Toast.show(<ToastContent message={message} title={title} type={type} />, {
    duration: persist ? 0 : Toast.durations.LONG + extraDuration,
    position: Toast.positions.TOP * resize,
    visible: true,
    containerStyle: {
      backgroundColor: backgroundColor,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: borderColor,
      padding: 0,
      alignItems: 'flex-start'
    },

    shadow: false,
    opacity: 1,
    animation: true,
    hideOnPress: true,
    delay
  });
};

/**
 * Util function to show an error Toast popup.
 * */
export const showErrorToast = ({
  title,
  message,
  extraDuration = 0,
  delay = 0,
  resize = 1,
  persist = false
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Scale to resize elements of the Toast for different devices. Optional prop, defaults to 1.
   */
  resize?: number;
  /**
   * Boolean to determine if the Toast should persist indefinitely.
   * Optional prop, defaults to false.
   */
  persist?: boolean;
}) => {
  return showToast({
    title,
    message,
    type: 'error',
    extraDuration,
    delay,
    resize,
    persist
  });
};

/**
 * Util function to show an info Toast popup.
 * */
export const showInfoToast = ({
  title,
  message,
  extraDuration = 0,
  delay = 0,
  resize = 1
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Scale to resize elements of the Toast for different devices. Optional prop, defaults to 1.
   */
  resize?: number;
}) => {
  return showToast({
    title,
    message,
    type: 'info',
    extraDuration,
    delay,
    resize
  });
};

/**
 * Functional component version of our Toast - needed for when we need to conditionally render a toast.
 * Particularly important for when we need to persist a Toast until a condition has been met.
 */
const BaseToast = ({
  title,
  message,
  type = 'error',
  extraDuration = 0,
  delay = 0,
  persist
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Type of toast to show - currently can either be:
   * - 'error', red toast with an exclamation mark icon;
   * - 'info', blue toast with an information icon.
   */
  type: 'error' | 'info';
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Boolean to determine if the Toast should persist indefinitely.
   * Optional prop, defaults to undefined.
   */
  persist?: boolean;
}) => {
  const [backgroundColor, borderColor] = (() => {
    switch (type) {
      case 'error':
        return [colors.danger, colors.fieryRose];
      case 'info':
        return [colors.infoToast, colors.infoBorder];
    }
  })();

  const { resize } = useBreakpoints();

  return (
    <Toast
      duration={persist ? 0 : Toast.durations.LONG + extraDuration}
      position={Toast.positions.TOP * resize}
      visible
      containerStyle={{
        backgroundColor: backgroundColor,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: borderColor,
        padding: 0,
        alignItems: 'flex-start'
      }}
      shadow={false}
      opacity={1}
      animation={true}
      hideOnPress={true}
      delay={delay}
    >
      <ToastContent message={message} title={title} type={type} />
    </Toast>
  );
};

/**
 * Functional component error Toast.
 * */
export const ErrorToast = ({
  title,
  message,
  extraDuration = 0,
  delay = 0,
  persist = false
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Boolean to determine if the Toast should persist indefinitely.
   * Optional prop, defaults to false.
   */
  persist?: boolean;
}) => {
  return (
    <BaseToast
      title={title}
      message={message}
      type="error"
      extraDuration={extraDuration}
      delay={delay}
      persist={persist}
    />
  );
};

/**
 * Functional component info Toast.
 * */
export const InfoToast = ({
  title,
  message,
  extraDuration = 0,
  delay = 0,
  persist
}: {
  /**
   * String to display in bold at the top of the toast.
   */
  title: string;
  /**
   * String to display at the bottom of the toast.
   */
  message: string;
  /**
   * Milliseconds to add to the default duration of the toast. Optional prop, defaults to 0.
   */
  extraDuration?: number;
  /**
   * Milliseconds to delay showing the toast. Optional prop, defaults to 0.
   */
  delay?: number;
  /**
   * Boolean to determine if the Toast should persist indefinitely.
   * Optional prop, defaults to undefined.
   */
  persist?: boolean;
}) => {
  return (
    <BaseToast
      title={title}
      message={message}
      type="info"
      extraDuration={extraDuration}
      delay={delay}
      persist={persist}
    />
  );
};
