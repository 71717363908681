import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { ADD, SUB } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcV',
  description: 'bcV',
  keywords: ['Part-whole model', 'Fact families', 'Add', 'Subtract'],
  questionHeight: 1200,
  schema: z
    .object({
      numberA: z.number().int().min(0).max(20),
      numberB: z.number().int().min(0).max(20),
      additionOrSubtraction: z.enum([
        'Addition normal',
        'Addition flipped',
        'Subtraction normal',
        'Subtraction flipped'
      ]),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      val => val.numberA + val.numberB <= 20,
      'numberA + numberB must be less than or equal to 20'
    ),
  simpleGenerator: () => {
    // Both numberA and numberB need to be a min of 2 to prevent possible duplicate answers.
    const numberA = randomIntegerInclusive(0, 20);

    const numberB = randomIntegerInclusive(0, 20 - numberA);

    const additionOrSubtraction = getRandomFromArray([
      'Addition normal',
      'Addition flipped',
      'Subtraction normal',
      'Subtraction flipped'
    ] as const);

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { numberA, numberB, additionOrSubtraction, variation };
  },
  Component: props => {
    const {
      question: { numberA, numberB, additionOrSubtraction, variation },
      translate,
      displayMode
    } = props;

    const random = seededRandom(props.question);

    const total = numberA + numberB;

    const items = shuffle(
      [numberA.toLocaleString(), numberB.toLocaleString(), total.toLocaleString()],
      { random }
    );

    const sentence = (() => {
      switch (additionOrSubtraction) {
        case 'Addition normal':
          return '<ans/> + <ans/> = <ans/>';
        case 'Addition flipped':
          return '<ans/> = <ans/> + <ans/>';
        case 'Subtraction normal':
          return `<ans/> ${SUB} <ans/> = <ans/>`;
        case 'Subtraction flipped':
          return `<ans/> = <ans/> ${SUB} <ans/>`;
      }
    })();

    const testCorrect = (userAnswer: readonly (string | undefined)[]) => {
      switch (additionOrSubtraction) {
        case 'Addition normal':
          return (
            userAnswer[2] === total.toLocaleString() &&
            ((userAnswer[0] === numberA.toString() && userAnswer[1] === numberB.toString()) ||
              (userAnswer[0] === numberB.toString() && userAnswer[1] === numberA.toString()))
          );
        case 'Addition flipped':
          return (
            userAnswer[0] === total.toLocaleString() &&
            ((userAnswer[1] === numberA.toString() && userAnswer[2] === numberB.toString()) ||
              (userAnswer[1] === numberB.toString() && userAnswer[2] === numberA.toString()))
          );
        case 'Subtraction normal':
          return (
            userAnswer[0] === total.toLocaleString() &&
            ((userAnswer[1] === numberA.toString() && userAnswer[2] === numberB.toString()) ||
              (userAnswer[1] === numberB.toString() && userAnswer[2] === numberA.toString()))
          );
        case 'Subtraction flipped':
          return (
            userAnswer[1] === total.toLocaleString() &&
            ((userAnswer[0] === numberA.toString() && userAnswer[2] === numberB.toString()) ||
              (userAnswer[0] === numberB.toString() && userAnswer[2] === numberA.toString()))
          );
      }
    };

    const [markSchemeExample, answerTextExample] = (() => {
      switch (additionOrSubtraction) {
        case 'Addition normal':
          return [
            [numberA.toLocaleString(), numberB.toLocaleString(), total.toLocaleString()],
            `${numberB.toLocaleString()} + ${numberA.toLocaleString()} = ${total.toLocaleString()}`
          ];
        case 'Addition flipped':
          return [
            [total.toLocaleString(), numberA.toLocaleString(), numberB.toLocaleString()],
            `${total.toLocaleString()} = ${numberB.toLocaleString()} + ${numberA.toLocaleString()}`
          ];
        case 'Subtraction normal':
          return [
            [total.toLocaleString(), numberA.toLocaleString(), numberB.toLocaleString()],
            `${total.toLocaleString()} ${SUB} ${numberB.toLocaleString()} = ${numberA.toLocaleString()}`
          ];
        case 'Subtraction flipped':
          return [
            [numberB.toLocaleString(), total.toLocaleString(), numberA.toLocaleString()],
            `${numberA.toLocaleString()} = ${total.toLocaleString()} ${SUB} ${numberB.toLocaleString()}`
          ];
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberSentence()}
        pdfLayout="itemsAboveContent"
        testCorrect={userAnswer => testCorrect(userAnswer)}
        actionPanelVariant="end"
        mainPanelStyle={{ flexDirection: 'row' }}
        questionHeight={1200}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={[numberB, numberA]}
            isInteractive
            // Reduce width slightly to add more space between part-whole model and sentence.
            dimens={{
              height: dimens.height,
              width: displayMode === 'digital' ? dimens.width * 0.75 : dimens.width * 0.8
            }}
          />
        )}
        items={items}
        sentence={sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [markSchemeExample],
          answerText: translate.markScheme.alsoAcceptX(answerTextExample)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcW',
  description: 'bcW',
  keywords: ['Bar model', 'Add', 'Subtract', 'Number sentence'],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(19),
      numberB: z.number().int().min(1).max(19),
      totalOnTop: z.boolean()
    })
    .refine(
      val => val.numberA + val.numberB >= 3 && val.numberA + val.numberB <= 20,
      'numberA + numberB must be greater than or equal to 3, and less than or equal to 20'
    ),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 19);

    const numberB = randomIntegerInclusive(1, 20 - numberA, {
      constraint: x => x + numberA >= 3
    });

    const totalOnTop = getRandomBoolean();

    return { numberA, numberB, totalOnTop };
  },
  Component: props => {
    const {
      question: { numberA, numberB, totalOnTop },
      translate,
      displayMode
    } = props;

    const total = numberA + numberB;

    const numbers = totalOnTop ? [[total], [numberA, numberB]] : [[numberA, numberB], [total]];

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheNumberSentencesToMatchTheBarModel()}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            total={total}
            dimens={dimens}
            oneFontSize
            maxFontSize={displayMode === 'digital' ? 32 : 50}
          />
        )}
        sentences={[`<ans/> ${ADD} <ans/> = <ans/>`, `<ans/> ${SUB} <ans/> = <ans/>`]}
        sentenceStyle={{ alignSelf: 'center' }}
        testCorrect={userAnswer =>
          // First two numbers of addition can be numberA + numberB, or numberB + numberA:
          ((userAnswer[0][0] === numberA.toString() && userAnswer[0][1] === numberB.toString()) ||
            (userAnswer[0][0] === numberB.toString() && userAnswer[0][1] === numberA.toString())) &&
          userAnswer[0][2] === total.toString() &&
          userAnswer[1][0] === total.toString() &&
          // Last two numbers of subtraction can be (total -) numberA = numberB, or (total -) numberB = numberA
          ((userAnswer[1][1] === numberA.toString() && userAnswer[1][2] === numberB.toString()) ||
            (userAnswer[1][1] === numberB.toString() && userAnswer[1][2] === numberA.toString()))
        }
        inputMaxCharacters={2}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent(),
          answersToDisplay: [
            [numberA.toLocaleString(), numberB.toLocaleString(), total.toLocaleString()],
            [total.toLocaleString(), numberA.toLocaleString(), numberB.toLocaleString()]
          ]
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bcX',
  description: 'bcX',
  keywords: ['Fact families', 'Add', 'Subtract'],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(19),
      numberB: z.number().int().min(1).max(19),
      totalCorrectAnswers: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.numberA + val.numberB >= 3 && val.numberA + val.numberB <= 20,
      'numberA + numberB must be greater than or equal to 3, and less than or equal to 20'
    )
    .refine(val => val.numberA !== val.numberB, 'numberA and numberB must be different.'),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 19);

    const numberB = randomIntegerInclusive(1, 20 - numberA, {
      constraint: x =>
        x + numberA >= 3 &&
        // numberA and numberB must be different to prevent duplicate answers being generated.
        x !== numberA
    });

    const totalCorrectAnswers = randomIntegerInclusive(1, 6);

    return { numberA, numberB, totalCorrectAnswers };
  },
  Component: props => {
    const {
      question: { numberA, numberB, totalCorrectAnswers },
      translate
    } = props;

    const random = seededRandom(props.question);

    const total = numberA + numberB;

    const correctAnswers = [
      // Normal additions:
      `${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()} = ${total.toLocaleString()}`,
      `${numberB.toLocaleString()} ${ADD} ${numberA.toLocaleString()} = ${total.toLocaleString()}`,
      // Flipped additions:
      `${total.toLocaleString()} = ${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()}`,
      `${total.toLocaleString()} = ${numberB.toLocaleString()} ${ADD} ${numberA.toLocaleString()}`,
      // Normal subtractions:
      `${total.toLocaleString()} ${SUB} ${numberA.toLocaleString()} = ${numberB.toLocaleString()}`,
      `${total.toLocaleString()} ${SUB} ${numberB.toLocaleString()} = ${numberA.toLocaleString()}`,
      // Flipped subtractions:
      `${numberA.toLocaleString()} = ${total.toLocaleString()} ${SUB} ${numberB.toLocaleString()}`,
      `${numberB.toLocaleString()} = ${total.toLocaleString()} ${SUB} ${numberA.toLocaleString()}`
    ];

    const selectedCorrectAnswerItems = getRandomSubArrayFromArray(
      correctAnswers,
      totalCorrectAnswers,
      {
        random
      }
    );

    const incorrectAnswers = [
      // Incorrect additions - numberA result:
      `${total.toLocaleString()} ${ADD} ${numberB.toLocaleString()} = ${numberA.toLocaleString()}`,
      `${numberB.toLocaleString()} ${ADD} ${total.toLocaleString()} = ${numberA.toLocaleString()}`,
      // Incorrect additions - numberB result:
      `${total.toLocaleString()} ${ADD} ${numberA.toLocaleString()} = ${numberB.toLocaleString()}`,
      `${numberA.toLocaleString()} ${ADD} ${total.toLocaleString()} = ${numberB.toLocaleString()}`,
      // Incorrect flipped additions - numberA result:
      `${numberA.toLocaleString()} = ${total.toLocaleString()} ${ADD} ${numberB.toLocaleString()}`,
      `${numberA.toLocaleString()} = ${numberB.toLocaleString()} ${ADD} ${total.toLocaleString()}`,
      // Incorrect flipped additions - numberB result:
      `${numberB.toLocaleString()} = ${total.toLocaleString()} ${ADD} ${numberA.toLocaleString()}`,
      `${numberB.toLocaleString()} = ${numberA.toLocaleString()} ${ADD} ${total.toLocaleString()}`,
      // Incorrect subtractions - total is difference:
      `${numberA.toLocaleString()} ${SUB} ${numberB.toLocaleString()} = ${total.toLocaleString()}`,
      `${numberB.toLocaleString()} ${SUB} ${numberA.toLocaleString()} = ${total.toLocaleString()}`,
      // Incorrect subtractions - total is subtrahend:
      `${numberA.toLocaleString()} ${SUB} ${total.toLocaleString()} = ${numberB.toLocaleString()}`,
      `${numberB.toLocaleString()} ${SUB} ${total.toLocaleString()} = ${numberA.toLocaleString()}`,
      // Incorrect flipped subtractions - total is difference:
      `${total.toLocaleString()} = ${numberA.toLocaleString()} ${SUB} ${numberB.toLocaleString()}`,
      `${total.toLocaleString()} = ${numberB.toLocaleString()} ${SUB} ${numberA.toLocaleString()}`,
      // Incorrect flipped subtractions - total is subtrahend:
      `${numberB.toLocaleString()} = ${numberA.toLocaleString()} ${SUB} ${total.toLocaleString()}`,
      `${numberA.toLocaleString()} = ${numberB.toLocaleString()} ${SUB} ${total.toLocaleString()}`
    ];

    // Set used to ensure no duplicate answers are produced, just to be safe:
    const selectedIncorrectAnswerItems = getRandomSubArrayFromArray(
      [...new Set(incorrectAnswers)],
      6 - totalCorrectAnswers,
      {
        random
      }
    );

    const items = shuffle([...selectedCorrectAnswerItems, ...selectedIncorrectAnswerItems], {
      random
    });

    return (
      <QF10SelectNumbers
        title={
          totalCorrectAnswers === 1
            ? translate.ks1Instructions.selectTheCorrectNumberSentence()
            : translate.ks1Instructions.selectTheCorrectNumberSentences()
        }
        pdfTitle={
          totalCorrectAnswers === 1
            ? translate.ks1PDFInstructions.tickTheCorrectNumberSentence()
            : translate.ks1PDFInstructions.tickTheCorrectNumberSentences()
        }
        testCorrect={selectedCorrectAnswerItems}
        items={items}
        multiSelect
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RelatedFacts',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
