import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { getRandomFromArray, randomIntegerInclusive } from 'common/src/utils/random';
import { z } from 'zod';
import { ADD } from 'common/src/constants';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Clock from '../../../../components/question/representations/Clock';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { convert12hToSpokenString } from '../../../../utils/time';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atO',
  description: 'atO',
  keywords: ['Roman numerals', 'Time', 'To the minute', 'Minutes past', 'Analogue'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutesPast: z
      .number()
      .int()
      .min(1)
      .max(29)
      .refine(val => val % 5 !== 0, 'must not be multiple of 5'),
    normalOrRoman: z.enum(['normal', 'roman'])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);
    const minutesPast = randomIntegerInclusive(1, 29, { constraint: x => x % 5 !== 0 });

    const normalOrRoman = getRandomFromArray(['normal', 'roman'] as const);

    return { hours, minutesPast, normalOrRoman };
  },
  Component: props => {
    const {
      question: { hours, minutesPast, normalOrRoman },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatTimeIsShownOnClock()}
        testCorrect={[minutesPast.toString(), hours.toString()]}
        sentence={translate.time.minutesPast('<ans/>', '<ans/>')}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes: minutesPast }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman={normalOrRoman === 'roman'}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'atP',
  description: 'atP',
  keywords: ['Time', 'Addition', 'To the minute', 'Minutes to'],
  schema: z.object({
    number: z.number().int().min(1).max(59)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 59);

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={[(60 - number).toString()]}
        sentence={`${number.toLocaleString()} ${ADD} <ans/> = ${(60).toLocaleString()}`}
        textStyle={{ fontSize: 40 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'atQ',
  description: 'atQ',
  keywords: ['Time', 'Addition', 'To the minute', 'Minutes to', 'Number line'],
  schema: z.object({
    hoursA: z.number().int().min(1).max(10),
    minutesA: z
      .number()
      .int()
      .min(11)
      .max(54)
      .refine(val => val % 5 !== 0, 'minutesA must not be a multiple of 5')
  }),
  simpleGenerator: () => {
    const hoursA = randomIntegerInclusive(1, 10);
    const minutesA = randomIntegerInclusive(10, 54, { constraint: x => x % 5 !== 0 });

    return { hoursA, minutesA };
  },
  Component: props => {
    const {
      question: { hoursA, minutesA },
      translate
    } = props;

    const hoursB = hoursA + 1;
    const answerMinutes = 60 - minutesA;

    // Ensure the final jump has enough space to fit answer box with "minutes"
    const middleTick = Math.min(minutesA / 60, 0.7) + hoursA;

    const startingNumber = hoursA;
    const endNumber = hoursB;

    const tickArray = [
      {
        label: `${translate.time.XTimeOClock(startingNumber.toLocaleString())}`,
        position: startingNumber
      },
      { label: '', position: middleTick },
      { label: `${translate.time.XTimeOClock(endNumber.toLocaleString())}`, position: endNumber }
    ];

    const jumpArrowArray = [
      {
        start: startingNumber,
        end: middleTick,
        label: `${minutesA.toLocaleString()} ${translate.timePeriod.minutes(minutesA)}`
      },
      {
        start: middleTick,
        end: endNumber,
        label: `<ans/> ${translate.timePeriod.minutes(answerMinutes)}`
      }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.fillInMissingMinutesOnNumberLine()}
        testCorrect={[answerMinutes.toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'atR',
  description: 'atR',
  keywords: ['Time', 'Addition', 'To the minute', 'Minutes to'],
  schema: z.object({
    hour: z.number().int().min(1).max(11),
    minutes: z.number().int().min(31).max(59)
  }),
  simpleGenerator: () => {
    const hour = randomIntegerInclusive(1, 11);

    const minutes = randomIntegerInclusive(31, 59);

    return { hour, minutes };
  },

  Component: props => {
    const {
      question: { hour, minutes },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeTimeToMakeStatementCorrect()}
        testCorrect={[(60 - minutes).toString(), (hour + 1).toString()]}
        sentence={translate.answerSentences.xMinutesPastYEqualsAnsMinutesToMinutes(minutes, hour)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'atS',
  description: 'atS',
  keywords: ['Roman numerals', 'Time', 'Minutes to', 'Analogue'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutesTo: z
      .number()
      .int()
      .min(1)
      .max(29)
      .refine(val => val % 5 !== 0, 'must not be multiple of 5'),
    normalOrRoman: z.enum(['normal', 'roman'])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);
    const minutesTo = randomIntegerInclusive(1, 29, { constraint: x => x % 5 !== 0 });

    const normalOrRoman = getRandomFromArray(['normal', 'roman'] as const);

    return { hours, minutesTo, normalOrRoman };
  },
  Component: props => {
    const {
      question: { hours, minutesTo, normalOrRoman },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatTimeIsShownOnClock()}
        testCorrect={[minutesTo.toString(), hours.toString()]}
        sentence={translate.time.minutesTo('<ans/>', '<ans/>')}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours - 1, minutes: 60 - minutesTo }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman={normalOrRoman === 'roman'}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atT',
  description: 'atT',
  keywords: ['Time', 'To the minute', 'Minutes to', 'Analogue'],
  schema: z.object({
    minutes: z
      .number()
      .int()
      .min(1)
      .max(59)
      .refine(val => val % 5 !== 0, 'minutes value must not be multiple of 5'),
    hours: z.number().int().min(0).max(11)
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusive(1, 59, {
      constraint: x => x % 5 !== 0
    });
    const hours = randomIntegerInclusive(0, 11);

    return { minutes, hours };
  },
  Component: ({ question: { hours, minutes }, translate }) => {
    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.instructions.moveTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.instructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTheTimeToTheMinute',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
