import { newTermContent } from '../../Term';

import Money from './Money';
import MultiplicationAndDivision from './MultiplicationAndDivision';
import LengthAndHeight from './LengthAndHeight';
import MassCapacityAndTemperature from './MassCapacityAndTemperature';

const Term = newTermContent({
  term: 'Spring',
  blocks: [Money, MultiplicationAndDivision, LengthAndHeight, MassCapacityAndTemperature]
});
export default Term;
