import { View, StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { countRange, filledArray } from '../../../utils/collections';
import CompleteTheSentence from '../../molecules/CompleteTheSentence';
import { MeasureView } from '../../atoms/MeasureView';
import Text from '../../typography/Text';
import { SetState, projectSetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import { parseMarkup } from '../../../markup';
import { noop } from '../../../utils/flowControl';

type Props = {
  userAnswer: { left: string[][]; right: string[][] };
  setUserAnswer?: SetState<{ left: string[][]; right: string[][] }>;
  /** Left and ride side sentences, must both me equal lengths */
  leftSide: string[];
  rightSide: string[];
  inputMaxCharacters?: number;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  noEquals?: boolean;
  leftColumnStyle?: StyleProp<ViewStyle>;
  rightColumnStyle?: StyleProp<ViewStyle>;
};

export const AlignedEquations = ({
  userAnswer,
  setUserAnswer = noop,
  leftSide,
  rightSide,
  inputMaxCharacters,
  textStyle,
  fractionTextStyle,
  sentenceStyle,
  noEquals = false,
  leftColumnStyle,
  rightColumnStyle
}: Props) => {
  if (leftSide.length !== rightSide.length) {
    throw new Error(
      'Both sides of the aligned equations must be of the same length. Even if they are empty strings.'
    );
  }
  const numberOfLines = Math.max(leftSide.length, rightSide.length);

  return (
    <MeasureView>
      {dimens => (
        <View style={[styles.container, dimens]}>
          <View style={[styles.leftHandColumn, leftColumnStyle]}>
            {leftSide.map((line, index) => (
              <View
                key={index}
                style={[
                  styles.sentenceLine,
                  { height: dimens.height / numberOfLines },
                  sentenceStyle
                ]}
              >
                <CompleteTheSentence
                  key={`left-${index}`}
                  value={userAnswer.left[index]}
                  onValueChanged={projectSetState(projectSetState(setUserAnswer, 'left'), index)}
                  sentence={line}
                  inputMaxCharacters={inputMaxCharacters}
                  textStyle={textStyle}
                  fractionTextStyle={fractionTextStyle}
                />
              </View>
            ))}
          </View>

          <View style={[styles.equalsColumn, noEquals && { marginHorizontal: 80 }]}>
            {countRange(numberOfLines).map((_, index) => (
              <View
                key={index}
                style={[
                  styles.sentenceLine,
                  { height: dimens.height / numberOfLines },
                  sentenceStyle
                ]}
              >
                {!noEquals && <Text variant="WRN400">=</Text>}
              </View>
            ))}
          </View>

          <View style={[styles.rightHandColumn, rightColumnStyle]}>
            {rightSide.map((line, index) => (
              <View
                key={index}
                style={[
                  styles.sentenceLine,
                  { height: dimens.height / numberOfLines },
                  sentenceStyle
                ]}
              >
                <CompleteTheSentence
                  key={`right-${index}`}
                  value={userAnswer.right[index]}
                  onValueChanged={projectSetState(projectSetState(setUserAnswer, 'right'), index)}
                  sentence={line}
                  inputMaxCharacters={inputMaxCharacters}
                  textStyle={textStyle}
                  fractionTextStyle={fractionTextStyle}
                />
              </View>
            ))}
          </View>
        </View>
      )}
    </MeasureView>
  );
};

/** Utility function when not using below HOC {@link AlignedEquationsWithState} */
AlignedEquations.defaultUserAnswer = ({
  leftSide,
  rightSide
}: {
  leftSide: string[];
  rightSide: string[];
}) => ({
  left: leftSide
    .map(parseMarkup)
    .map(it => it.numberOfAns)
    .map(numberOfAns => filledArray('', numberOfAns)),
  right: rightSide
    .map(parseMarkup)
    .map(it => it.numberOfAns)
    .map(numberOfAns => filledArray('', numberOfAns))
});

/** See {@link AlignedEquations} */
export const AlignedEquationsWithState = withStateHOC(AlignedEquations, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    testComplete: state =>
      state.left.every(sentence => sentence.every(it => it !== '')) &&
      state.right.every(sentence => sentence.every(it => it !== '')),
    defaultState: AlignedEquations.defaultUserAnswer(props)
  })
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftHandColumn: {
    alignItems: 'flex-end'
  },
  rightHandColumn: {
    alignItems: 'flex-start'
  },
  equalsColumn: {
    marginHorizontal: 24
  },
  sentenceLine: {
    justifyContent: 'center'
  }
});
