import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { useMemo } from 'react';
import { MULT } from 'common/src/constants';
import {
  amS2,
  amS,
  amU
} from 'common/src/SchemeOfLearning/Year 5/Autumn/MultiplicationAndDivisionA/6SquareNumbers';
import {
  amY2,
  amY,
  am0
} from 'common/src/SchemeOfLearning/Year 5/Autumn/MultiplicationAndDivisionA/7CubeNumbers';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { nestedArrayHasNoDuplicates } from 'common/src/utils/collections';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjectsColors } from 'common/src/theme/colors';
import Text from '../../../../components/typography/Text';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { cubedCubesSVG } from '../../../../utils/cubes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'anH',
  description: 'anH',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      rowsAndColsA: z.number().int().min(1).max(5),
      rowsB: z.number().int().min(1).max(7),
      colsB: z.number().int().min(1).max(5),
      rowsC: z.number().int().min(1).max(7),
      colsC: z.number().int().min(1).max(5),
      rowsD: z.number().int().min(1).max(7),
      colsD: z.number().int().min(1).max(5)
    })
    .refine(
      val => val.rowsB !== val.colsB && val.rowsC !== val.colsC && val.rowsD !== val.colsD,
      'rowsB must not equal colsB, rowsC must not equal colsC, and rowsD must not equal colsD'
    )
    .refine(
      val =>
        nestedArrayHasNoDuplicates(
          [
            [val.rowsB, val.colsB],
            [val.rowsC, val.colsC],
            [val.rowsD, val.colsD]
          ],
          true
        ),
      'The pairs [rowsB, colsB], [rowsC, colsC], [rowsD, colsD] must all be different.'
    )
    .refine(
      val => Math.sqrt(val.rowsB * val.colsB) !== Math.floor(Math.sqrt(val.rowsB * val.colsB)),
      'The product of rowsB and colsB must not be a square number.'
    )
    .refine(
      val => Math.sqrt(val.rowsC * val.colsC) !== Math.floor(Math.sqrt(val.rowsC * val.colsC)),
      'The product of rowsC and colsC must not be a square number.'
    )
    .refine(
      val => Math.sqrt(val.rowsD * val.colsD) !== Math.floor(Math.sqrt(val.rowsD * val.colsD)),
      'The product of rowsD and colsD must not be a square number.'
    ),
  simpleGenerator: () => {
    const { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD } = rejectionSample(
      () => {
        const rowsAndColsA = randomIntegerInclusive(1, 5);

        const rowsB = randomIntegerInclusive(1, 7);
        const colsB = randomIntegerInclusive(1, 5, {
          constraint: x => x !== rowsB
        });

        const rowsC = randomIntegerInclusive(1, 7);
        const colsC = randomIntegerInclusive(1, 5, {
          constraint: x => x !== rowsC
        });

        const rowsD = randomIntegerInclusive(1, 7);
        const colsD = randomIntegerInclusive(1, 5, {
          constraint: x => x !== rowsD
        });

        return { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD };
      },
      // Only permit them if [rowsB, colsB], [rowsC, colsC], [rowsD, colsD] are not identical,
      // and that they do not produce a square number.
      ({ rowsB, colsB, rowsC, colsC, rowsD, colsD }) =>
        nestedArrayHasNoDuplicates(
          [
            [rowsB, colsB],
            [rowsC, colsC],
            [rowsD, colsD]
          ],
          true
        ) &&
        Math.sqrt(rowsB * colsB) !== Math.floor(Math.sqrt(rowsB * colsB)) &&
        Math.sqrt(rowsC * colsC) !== Math.floor(Math.sqrt(rowsC * colsC)) &&
        Math.sqrt(rowsD * colsD) !== Math.floor(Math.sqrt(rowsD * colsD))
    );

    return { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD };
  },
  Component: props => {
    const {
      question: { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const colors = shuffle(Object.values(ArrayOfObjectsColors));

      const eqA = {
        rows: rowsAndColsA,
        columns: rowsAndColsA,
        product: rowsAndColsA * rowsAndColsA,
        isCorrect: true,
        color: colors[0]
      };
      const eqB = {
        rows: rowsB,
        columns: colsB,
        product: rowsB * colsB,
        isCorrect: false,
        color: colors[1]
      };
      const eqC = {
        rows: rowsC,
        columns: colsC,
        product: rowsC * colsC,
        isCorrect: false,
        color: colors[2]
      };
      const eqD = {
        rows: rowsD,
        columns: colsD,
        product: rowsD * colsD,
        isCorrect: false,
        color: colors[3]
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [colsB, colsC, colsD, props.question, rowsAndColsA, rowsB, rowsC, rowsD]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSquareNumber()}
        pdfTitle={translate.instructions.circleTheSquareNumber()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          // Subract 20 to give a margin of 10
          const availableHeight = dimens.height - 20;
          // Arrays to take up five-sixths of their available height.
          // All four arrays' counters to be the same size, based on the tallest array's number of rows.
          const counterSize = ((availableHeight / 6) * 5) / Math.max(...eqs.map(eq => eq.rows));

          return eqs.map(equation => ({
            value: equation,
            component: (
              <View
                style={{
                  height: availableHeight,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Text
                  variant="WRN400"
                  style={{ textAlign: 'center', fontSize: 28, lineHeight: 30 }}
                >
                  {equation.product.toLocaleString()}
                </Text>
                <ArrayOfObjects
                  dimens={dimens}
                  rows={equation.rows}
                  columns={equation.columns}
                  color={equation.color}
                  counterSize={counterSize}
                  containerStyle={{ justifyContent: 'flex-start' }}
                />
              </View>
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'anI',
  description: 'anI',
  keywords: ['Cube', 'Square'],
  schema: z.object({
    cubeRoot: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const cubeRoot = randomIntegerInclusive(2, 5);

    return { cubeRoot };
  },
  Component: props => {
    const {
      question: { cubeRoot },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        sentence={translate.answerSentences.numCubedEqualsAnsMultAnsMultAns(
          cubeRoot.toLocaleString()
        )}
        testCorrect={[
          cubeRoot.toString(),
          cubeRoot.toString(),
          cubeRoot.toString(),
          (cubeRoot ** 3).toString()
        ]}
        Content={({ dimens }) => (
          <>{cubedCubesSVG(cubeRoot as 2 | 3 | 4 | 5, dimens.height, dimens.width)}</>
        )}
        pdfDirection="column"
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'anJ2',
  description: 'anJ',
  keywords: ['Cube', 'Square', 'Notation'],
  schema: z.union([amS2.schema, amY2.schema]),
  simpleGenerator: () => {
    return getRandomFromArray([amS2, amY2] as const).generator();
  },
  Component: ({ question, ...props }) => {
    if ('squareVar' in question) {
      return amS.Component({ question, ...props });
    } else {
      return amY.Component({ question, ...props });
    }
  }
});

const Question3 = newQuestionContent({
  uid: 'anJ',
  description: 'anJ',
  keywords: ['Cube', 'Square', 'Notation'],
  schema: z.union([amS.schema, amY.schema]),
  simpleGenerator: () => {
    return getRandomFromArray([amS, amY] as const).generator();
  },
  Component: ({ question, ...props }) => {
    if ('squareVar' in question) {
      return amS.Component({ question, ...props });
    } else {
      return amY.Component({ question, ...props });
    }
  }
});

const Question4 = newQuestionContent({
  uid: 'anK',
  description: 'anK',
  keywords: ['Cube', 'Square'],
  schema: z.object({
    numbers: z.array(z.number().int().min(2).max(5)).length(2).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(2, 5, 2);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [number1, number2] = numbers;

    const statements = useMemo(() => {
      const statements = [
        { statement: `${number1.toLocaleString()}² = `, answer: number1 ** 2 },
        { statement: `${number1.toLocaleString()}³ = `, answer: number1 ** 3 },
        { statement: `${number2.toLocaleString()}² = `, answer: number2 ** 2 },
        { statement: `${number2.toLocaleString()}³ = `, answer: number2 ** 3 }
      ];

      return shuffle(statements, { random: seededRandom(props.question) });
    }, [number1, number2, props.question]);

    const answerOptions = [
      { component: (number1 ** 2).toLocaleString(), value: number1 ** 2 },
      {
        component: `${number1.toLocaleString()} ${MULT} ${number1.toLocaleString()} ${MULT} ${number1.toLocaleString()}`,
        value: number1 ** 3
      },
      {
        component: `${number2.toLocaleString()} ${MULT} ${number2.toLocaleString()}`,
        value: number2 ** 2
      },
      { component: (number2 ** 3).toLocaleString(), value: number2 ** 3 }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchTheStatements()}
        pdfTitle={translate.instructions.useCardsToMatchTheStatements()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'anL',
  description: 'anL',
  keywords: ['Cube', 'Square'],
  schema: z.object({
    number: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 12);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[[Math.pow(number, 2).toString()], [Math.pow(number, 3).toString()]]}
        sentences={[
          translate.answerSentences.numSquaredEqualsAns(number),
          translate.answerSentences.numCubedEqualsAns(number)
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'anM',
  description: 'anM',
  keywords: ['Cube', 'Square'],
  schema: z.union([amU.schema, am0.schema]),
  simpleGenerator: () => {
    return getRandomFromArray([true, false]) ? amU.generator() : am0.generator();
  },
  Component: ({ question, ...props }) => {
    return question.numbers.length === 8
      ? amU.Component({ question, ...props })
      : am0.Component({ question, ...props });
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SquareAndCubeNumbers',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
