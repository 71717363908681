import { z } from 'zod';
import { getRandomFromArray } from './random';
import { SvgName } from '../assets/svg';

export const leavesOnGridShapeNames = [
  'Leaf1_ongrid',
  'Leaf2_ongrid',
  'Leaf3_ongrid',
  'Leaf4_ongrid',
  'Leaf5_ongrid',
  'Leaf6_ongrid',
  'Leaf7_ongrid',
  'Leaf8_ongrid'
] as const;

export const leavesOnGridShapeSchema = z.enum(leavesOnGridShapeNames);

export const islandAOnGridShapeNames = [
  'Island_shape_a1',
  'Island_shape_a2',
  'Island_shape_a3',
  'Island_shape_a4',
  'Island_shape_a5',
  'Island_shape_a6'
] as const;

export const islandAOnGridShapeSchema = z.enum(islandAOnGridShapeNames);

export const islandBOnGridShapeNames = [
  'Island_shape_b1',
  'Island_shape_b2',
  'Island_shape_b3',
  'Island_shape_b4',
  'Island_shape_b5',
  'Island_shape_b6'
] as const;

export const islandBOnGridShapeSchema = z.enum(islandBOnGridShapeNames);

export const curvedShapeOnGridShapeNames = [
  'Curved_shape1',
  'Curved_shape2',
  'Curved_shape3',
  'Curved_shape4',
  'Curved_shape5',
  'Curved_shape6',
  'Curved_shape7',
  'Curved_shape8'
] as const;

export const curvedShapeOnGridShapeSchema = z.enum(curvedShapeOnGridShapeNames);

export const shapesOnGridProperties: Record<string, { svgName: SvgName; area: number }> = {
  Island_shape_a1: {
    svgName: 'Shapes_on_grids/Island_shape_a1',
    area: 37
  },
  Island_shape_a2: {
    svgName: 'Shapes_on_grids/Island_shape_a2',
    area: 34
  },
  Island_shape_a3: {
    svgName: 'Shapes_on_grids/Island_shape_a3',
    area: 22
  },
  Island_shape_a4: {
    svgName: 'Shapes_on_grids/Island_shape_a4',
    area: 23
  },
  Island_shape_a5: {
    svgName: 'Shapes_on_grids/Island_shape_a5',
    area: 27
  },
  Island_shape_a6: {
    svgName: 'Shapes_on_grids/Island_shape_a6',
    area: 26
  },
  Island_shape_b1: {
    svgName: 'Shapes_on_grids/Island_shape_b1',
    area: 33
  },
  Island_shape_b2: {
    svgName: 'Shapes_on_grids/Island_shape_b2',
    area: 28
  },
  Island_shape_b3: {
    svgName: 'Shapes_on_grids/Island_shape_b3',
    area: 30
  },
  Island_shape_b4: {
    svgName: 'Shapes_on_grids/Island_shape_b4',
    area: 26
  },
  Island_shape_b5: {
    svgName: 'Shapes_on_grids/Island_shape_b5',
    area: 35
  },
  Island_shape_b6: {
    svgName: 'Shapes_on_grids/Island_shape_b6',
    area: 25
  },
  Leaf1_ongrid: {
    svgName: 'Shapes_on_grids/Leaf1_ongrid',
    area: 28
  },
  Leaf2_ongrid: {
    svgName: 'Shapes_on_grids/Leaf2_ongrid',
    area: 26
  },
  Leaf3_ongrid: {
    svgName: 'Shapes_on_grids/Leaf3_ongrid',
    area: 18
  },
  Leaf4_ongrid: {
    svgName: 'Shapes_on_grids/Leaf4_ongrid',
    area: 24
  },
  Leaf5_ongrid: {
    svgName: 'Shapes_on_grids/Leaf5_ongrid',
    area: 34
  },
  Leaf6_ongrid: {
    svgName: 'Shapes_on_grids/Leaf6_ongrid',
    area: 25
  },
  Leaf7_ongrid: {
    svgName: 'Shapes_on_grids/Leaf7_ongrid',
    area: 27
  },
  Leaf8_ongrid: {
    svgName: 'Shapes_on_grids/Leaf8_ongrid',
    area: 29
  },
  Curved_shape1: {
    svgName: 'Shapes_on_grids/Curved_shape1',
    area: 16
  },
  Curved_shape2: {
    svgName: 'Shapes_on_grids/Curved_shape2',
    area: 14
  },
  Curved_shape3: {
    svgName: 'Shapes_on_grids/Curved_shape3',
    area: 13
  },
  Curved_shape4: {
    svgName: 'Shapes_on_grids/Curved_shape4',
    area: 12
  },
  Curved_shape5: {
    svgName: 'Shapes_on_grids/Curved_shape5',
    area: 11
  },
  Curved_shape6: {
    svgName: 'Shapes_on_grids/Curved_shape6',
    area: 8
  },
  Curved_shape7: {
    svgName: 'Shapes_on_grids/Curved_shape7',
    area: 12
  },
  Curved_shape8: {
    svgName: 'Shapes_on_grids/Curved_shape8',
    area: 17
  }
};

export const shapesOnGridWithNumberOfSides = (shapeSides: 6 | 8) => {
  switch (shapeSides) {
    case 6:
      return getRandomFromArray([
        'Shapes_on_grids/Translucent_rectiliear_shape1_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape2_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape3_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape4_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape5_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape6_grid'
      ] as const);
    case 8:
      return getRandomFromArray([
        'Shapes_on_grids/Translucent_rectiliear_shape7_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape8_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape9_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape10_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape11_grid',
        'Shapes_on_grids/Translucent_rectiliear_shape12_grid'
      ] as const);
  }
};

export const shapesOnGridWithNumberOfSidesNoLabels = (shapeSides: 6 | 8) => {
  switch (shapeSides) {
    case 6:
      return getRandomFromArray([
        'Shapes_on_grids/Translucent_rectiliear_shape1_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape2_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape3_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape4_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape5_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape6_grid_nolabels'
      ] as const);
    case 8:
      return getRandomFromArray([
        'Shapes_on_grids/Translucent_rectiliear_shape7_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape8_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape9_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape10_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape11_grid_nolabels',
        'Shapes_on_grids/Translucent_rectiliear_shape12_grid_nolabels'
      ] as const);
  }
};

export const shapeOnGridProperties = [
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid', perimeter: 24, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid', perimeter: 22, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid', perimeter: 24, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid', perimeter: 26, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid', perimeter: 16, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid', perimeter: 28, sides: 6 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid', perimeter: 28, sides: 8 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid', perimeter: 26, sides: 8 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid', perimeter: 26, sides: 8 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid', perimeter: 28, sides: 8 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid', perimeter: 26, sides: 8 },
  { name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid', perimeter: 18, sides: 8 }
];

export const shapeOnGridMissingLabelProperties = [
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelA',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelB',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelC',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelD',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelE',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelF',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelA',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelB',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelC',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelD',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelE',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelF',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelA',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelB',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelC',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelD',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelE',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelF',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelA',
    missingLabelValue: 8
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelB',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelC',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelD',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelE',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelF',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelA',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelB',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelC',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelD',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelE',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelF',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelA',
    missingLabelValue: 7
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelB',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelC',
    missingLabelValue: 8
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelD',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelE',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelF',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelA',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelB',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelC',
    missingLabelValue: 7
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelD',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelE',
    missingLabelValue: 7
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelF',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelG',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelH',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelA',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelB',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelC',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelD',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelE',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelF',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelG',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelH',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelA',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelB',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelC',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelD',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelE',
    missingLabelValue: 7
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelF',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelG',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelH',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelA',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelB',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelC',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelD',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelE',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelF',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelG',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelH',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelA',
    missingLabelValue: 4
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelB',
    missingLabelValue: 6
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelC',
    missingLabelValue: 5
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelD',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelE',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelF',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelG',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelH',
    missingLabelValue: 2
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelA',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelB',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelC',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelD',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelE',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelF',
    missingLabelValue: 1
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelG',
    missingLabelValue: 3
  },
  {
    name: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelH',
    missingLabelValue: 3
  }
];
