import { useContext, useEffect, useId, useRef } from 'react';
import { InternalNodeContext } from './helpers';

/**
 * Observer component, which does nothing except contribute to its parent node's correctness.
 */
export function TestCorrect<State>({ testCorrect }: { testCorrect: (values: State) => boolean }) {
  const id = useId();
  const parent = useContext(InternalNodeContext);
  const correct = testCorrect(parent.state as State);

  // If this leaf node's correctness has changed, inform the callback.
  const previousCorrect = useRef<boolean | null>(null);
  useEffect(() => {
    if (correct !== previousCorrect.current) {
      previousCorrect.current = correct;
      parent.reportChildCorrect(id)(correct);
    }
  }, [correct, parent, id]);

  return null;
}
