import { useMemo } from 'react';
import { InheritProps } from '../../../utils/types';
import QF37SentencesDrag from './QF37SentencesDrag';

/** Base props off of {@link QF37SentencesDrag} */
type ChangedAndNewProps<T> = {
  /** Sentence to complete. This uses the markup language defined at {@link parseMarkup}. */
  sentence: string;
  /** Generally speaking you don't need to provide this. Defaults all drop zones empty. */
  initialState?: (T | undefined)[];

  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: readonly (T | undefined)[]) => boolean;

  /** Either an array of correct answers, or a function for more advanced cases. */
  testCorrect: (T | undefined)[] | ((userAnswer: readonly (T | undefined)[]) => boolean);
};
type RemovedProps = 'sentences';
type Props<T> = InheritProps<typeof QF37SentencesDrag<T>, ChangedAndNewProps<T>, RemovedProps>;

/**
 * Layout containing a title above, drag and drop zone in the action panel, and a single sentence in the main panel.
 *
 * This variant has one sentence. If you need many sentences, choose another QF36 variant.
 *
 * ### Testing functions (testCorrect/testComplete)
 *
 * The easiest way to use these is to omit testComplete, and provide an array for testCorrect, e.g.
 *
 * ```
 *   testCorrect={[1, 2]}
 * ```
 *
 * See {@link QF37SentencesDrag}, which this is based on, for more information.
 */
export default function QF37SentenceDrag<T>({
  sentence,
  initialState,
  testComplete,
  testCorrect,
  ...props
}: Props<T>) {
  // Just call into the more general version
  return (
    <QF37SentencesDrag<T>
      sentences={useMemo(() => [sentence], [sentence])}
      initialState={useMemo(
        () => (initialState === undefined ? undefined : [initialState]),
        [initialState]
      )}
      testComplete={useMemo(() => {
        return testComplete === undefined
          ? undefined
          : sentencesUserAnswer => testComplete(sentencesUserAnswer[0]);
      }, [testComplete])}
      testCorrect={useMemo(() => {
        return typeof testCorrect === 'function'
          ? sentencesUserAnswer => testCorrect(sentencesUserAnswer[0])
          : [testCorrect];
      }, [testCorrect])}
      {...props}
    />
  );
}
