import { newBlockContent } from '../../../Block';

import CompareMass from './1CompareMass';
import MeasureInGrams from './2MeasureInGrams';
import MeasureInKilograms from './3MeasureInKilograms';
import FourOperationsWithMass from './4FourOperationsWithMass';
import CompareVolumeAndCapacity from './5CompareVolumeAndCapacity';
import MeasureInMillilitres from './6MeasureInMillilitres';
import MeasureInLitres from './7MeasureInLitres';
import FourOperationsWithVolumeAndCapacity from './8FourOperationsWithVolumeAndCapacity';
import Temperature from './9Temperature';

const Block = newBlockContent({
  block: 'MassCapacityAndTemperature',
  field: 'Measurement',
  weeks: [10, 12],
  smallSteps: [
    CompareMass, // Step 1
    MeasureInGrams, // Step 2
    MeasureInKilograms, // Step 3
    FourOperationsWithMass, // Step 4
    CompareVolumeAndCapacity, // Step 5
    MeasureInMillilitres, // Step 6
    MeasureInLitres, // Step 7
    FourOperationsWithVolumeAndCapacity, // Step 8
    Temperature // Step 9
  ]
});
export default Block;
