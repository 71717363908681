export default {
  aClockShowsTheTimeNumOClockAnsTimesEachDay:
    "A clock shows the time {0} o'clock <ans/> times a day.",
  ansCm: '<ans/> cm',
  ansG: '<ans/> g',
  ansKg: '<ans/> kg',
  ansM: '<ans/> m',
  ansMl: '<ans/> ml',
  aNewDayBeginsAtAns: 'A new day begins at <ans/>',
  ansIsAPart: '<ans/> is a part.',
  aQuarterOfXIsAns: 'A quarter of {0:number|numberFormat} is <ans/>',
  ansCanBePartitionedIntoAnsAndNum:
    '<ans/> can be partitioned into <ans/> and {0:number|numberFormat}',
  ansCanBePartitionedIntoNumAndAns:
    '<ans/> can be partitioned into {0:number|numberFormat} and <ans/>',
  ansGroupsOfAnsEqualsX: '<ans/> groups of <ans/> = {0:number|numberFormat}',
  ansHasNumTensAndNumOnes:
    '<ans/> has {0:number|numberFormat} tens and {1:number|numberFormat} one{{s}}.',
  ansHasNumOnesAndNumTens:
    '<ans/> has {0:number|numberFormat} one{{s}} and {1:number|numberFormat} tens.',
  ansHour: '<ans/> hour',
  ansHours: '<ans/> hours',
  ansIs12OClockAtNight: "<ans/> is 12 o'clock at night.",
  ansIs12OClockInTheMiddleOfTheDay: "<ans/> is 12 o'clock in the middle of the day.",
  ansIsTheBeginningOfANewDay: '<ans/> is the beginning of a new day.',
  ansLotsOfAnsEqualsX: '<ans/> lots of <ans/> = {0:number|numberFormat}',
  ansMinutes: '<ans/> minutes',
  ansMinutesPastAns: '<ans/> minutes past <ans/>',
  ansMinutesToAns: '<ans/> minutes to <ans/>',
  ansOClock: "<ans/> o'clock",
  ansP: '<ans/>p',
  ansPence: '<ans/> pence',
  ansPounds: '<ans/> pounds',
  anyArrangementOfMoreThanNumCounters:
    'Any arrangement of more than {0:number|numberFormat} counter{{s}}.',
  anyArrangementOfFewerThanNumCounters:
    'Any arrangement of fewer than {0:number|numberFormat} counter{{s}}.',
  doubleX: 'Double {0:number|numberFormat}',
  doubleXEqualsY: 'Double {0} = {1}',
  doubleAnsIsAns: 'Double <ans/> is <ans/>',
  doubleAnsIsNum: 'Double <ans/> is {0:number|numberFormat}',
  doubleNumIsAns: 'Double {0:number|numberFormat} is <ans/>',
  doubleXIsEqualTo: 'Double {0:number|numberFormat} is equal to',
  dragACardToCompleteTheSentence: 'Drag a card to complete the sentence.',
  eachGroupHasAnsX: 'Each group has <ans/> {0:string|lower}.',
  firstThereWereAnsBirdsInTree: 'First there were <ans /> birds in a tree.',
  firstThereWereAnsCarsInCarPark: 'First there were <ans /> cars in a car park.',
  firstThereWereAnsCookies: 'First there were <ans /> cookies.',
  firstThereWereXBirdsInTree: 'First there were {0:number|numberFormat} birds in a tree.',
  firstThereWereXCarsInCarPark: 'First there were {0:number|numberFormat} cars in a car park.',
  firstThereWereXCookies: 'First there were {0:number|numberFormat} cookies.',
  flowerXIsTheAnsFlower: 'Flower {0} is the <ans/> flower.',
  fracOfXIsAns: '{0} of {1:number|numberFormat} is <ans/>',
  goForwardsAnsSpaces: 'Go forwards <ans/> space{{0:s}}.',
  goForwardsXSpaces: 'Go forwards {0:number|numberFormat} space{{s}}.',
  groupAnsIsTheGreatest: 'Group <ans/> is the greatest.',
  groupAnsIsTheSmallest: 'Group <ans/> is the smallest.',
  halfPastAns: 'half past <ans/>',
  halfOfXIsAns: 'Half of {0:number|numberFormat} is <ans/>',
  halfOfXIsEqualTo: 'Half of {0:number|numberFormat} is equal to',
  halfXEqualsY: 'Half of {0} = {1}',
  howManyGlassesFillTwoJugsAns: 'How many glasses fill 2 jugs? <ans/>',
  howMuchChangeDoesXGet: 'How much change does {0:string|lower} get?',
  howMuchMoneyDoesCharHaveLeft: 'How much money does {0} have left?',
  howMuchMoreMoneyDoesCharAHaveThanCharB: 'How much <b>more</b> money does {0} have then {1}?',
  jugXHasAnsWaterThanJugY:
    'Jug {0:string|capitalize} has <ans/> water than jug {1:string|capitalize}.',
  name1HasAnsCubesThanName2: '{name1} has <ans/> cubes than {name2}.',
  nameMovesAnsSpacesX: '{0} moves <ans/> space{{1:s}} {2:string|lower}.',
  nameMovesXSpacesAns: '{0} moves {1:number|numberFormat} space{{s}} <ans/>.',
  nowThereAreAnsBirdsLeft: 'Now there are <ans/> birds left.',
  nowThereAreAnsCookiesLeft: 'Now there are <ans/> cookies left.',
  nowThereAreAnsCarsLeft: 'Now there are <ans/> cars left.',
  nowThereIsAnsBirdLeft: 'Now there is <ans/> bird left.',
  nowThereIsAnsCookieLeft: 'Now there is <ans/> cookie left.',
  nowThereIsAnsCarLeft: 'Now there is <ans/> car left.',
  nowThereIsAnsCookie: 'Now there is <ans/> cookie.',
  numCanBePartitionedIntoAnsAndAns:
    '{0:number|numberFormat} can be partitioned into <ans/> and <ans/>',
  numCanBePartitionedIntoNumAndAns:
    '{0:number|numberFormat} can be partitioned into {1:number|numberFormat} and <ans/>',
  nowThereAreAnsCookies: 'Now there are <ans/> cookies.',
  numEqualsAnsTensAnsOnes: '{0:number|numberFormat} = <ans/> tens and <ans/> ones.',
  numHasAnsTensAndAnsOnes: '{0:number|numberFormat} has <ans/> tens and <ans/> one{{s}}.',
  numHasAnsOnesAndAnsTens: '{0:number|numberFormat} has <ans/> one{{s}} and <ans/> tens.',
  num12OClockAtNightIsCalledAns: "12 o'clock at night is called <ans/>.",
  num12OClockInTheMiddleOfTheDayIsCalledAns:
    "12 o'clock in the middle of the day is called <ans/>.",
  numberAAnsNumberB: '{numberA:number|numberFormat} <ans/> {numberB:number|numberFormat}',
  numberAIsAnsNumberB: '{numberA:number|numberFormat} is <ans/> {numberB:number|numberFormat}',
  numberHoursAndNumberMinutesEqualsAnsMinutes:
    '{0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}} = <ans/> minutes',
  numberMinutesEqualsAnsHourAndAnsMinutes:
    '{0:number|numberFormat} minute{{s}} = <ans/> hour and <ans/> minutes',
  onAnsXNameWasYActivity: 'On <ans/> {0:string} was {1:string|lower}.',
  onAnsXNameWentYActivity: 'On <ans/> {0:string} went {1:string|lower}.',
  onAnsXNameWentToYActivity: 'On <ans/> {0:string} went to {1:string|lower}.',
  oneLessThanXIsAns: '1 less than {0:number|numberFormat} is <ans/>',
  oneLessThanAnsIsX: '1 less than <ans/> is {0:number|numberFormat}',
  oneMoreThanXIsAns: '1 more than {0:number|numberFormat} is <ans/>',
  oneMoreThanAnsIsX: '1 more than <ans/> is {0:number|numberFormat}',
  poundAns: '£<ans/>',
  poundAnsAndAnsPence: '£<ans/> and <ans/>p',
  pencilXIsTheAnsPencil: 'Pencil {0} is the <ans/> pencil.',
  poundsXAndYP: '£{0} and {1}p',
  poundAnsAndAnsP: '£<ans/> and <ans/>p',
  ribbonXIsTheAnsRibbon: 'Ribbon {0} is the <ans/> ribbon.',
  quarterPast: 'quarter past <ans/>',
  quarterTo: 'quarter to <ans/>',
  shapeHasAnsSides: '{0} has <ans/> sides.',
  shapeHasAnsVertices: '{0} has <ans/> vertices.',
  soDoubleXEqualsY: 'So double {0} = {1}',
  soHalfXEqualsY: 'So half of {0} = {1}',
  stringAIsAnsStringB: '{numberA:string|lower} is <ans/> {numberB:string|lower}',
  theAnsIsBetweenTheShapeAndTheShape:
    'The <ans/> is between the {0:string|lower} and the {1:string|lower}.',
  theAnsShapeIsAShape: 'The <ans/> shape is a {0:string|lower}.',
  theHourHandMovesRoundTheClockAnsTimesInADay:
    'The hour hand moves round the clock <ans/> times in a day.',
  theCapacityOfTheBottleIsAnsXGlasses:
    'The capacity of the bottle is <ans/> {0:number|numberFormat} glasses.',
  theDayBeforeOrAfterXDayIsAns: 'The day {0:string|lower} {1} is <ans />',
  theElephantIsXCmTall: 'The elephant is <ans/> cm tall.',
  theFlowerIsXCmTall: 'The flower is <ans/> cm tall.',
  theGiraffeIsXCmTall: 'The giraffe is <ans/> cm tall.',
  theMassOfXIsAnsMassOfY:
    'The mass of {0:string|lower} is <ans/> <g>the mass of {1:string|lower}.</g>',
  theMassOfTheXMassObjectIsAnsCubes: 'The mass of the {0:string|lower} is <ans/> cubes.',
  theMonthBeforeOrAfterXMonthIsAns: 'The month {0:string|lower} {1} is <ans/>',
  theOrdinalShapeIsAAns: 'The {0:string|lower} shape is a <ans/>.',
  theObjectIsMovingAnsSpacesX:
    'The {0:string|lower} is moving <ans/> space{{1:s}} {2:string|lower}.',
  theObjectIsMovingXSpacesAns:
    'The {0:string|lower} is moving {1:number|numberFormat} space{{s}} <ans/>.',
  thePositionAfterAnsIsOrdinal: 'The position after <ans/> is {0:string|lower}.',
  thePositionBeforeAnsIsOrdinal: 'The position before <ans/> is {0:string|lower}.',
  thePositionAfterOrdinalIsAns: 'The position after {0:string|lower} is <ans/>.',
  thePositionBeforeOrdinalIsAns: 'The position before {0:string|lower} is <ans/>.',
  theShapeHasTurnedAAnsTurn: 'The shape has turned a <ans/> turn.',
  theShapeHasTurnedAAnsTurnAntiClockwise: 'The shape has turned a <ans/> turn anti-clockwise.',
  theShapeHasTurnedAAnsTurnClockwise: 'The shape has turned a <ans/> turn clockwise.',
  thenAnsOfTheBirdsFlewAway: 'Then <ans/> of the birds flew away.',
  thenAnsOfTheCarsDriveAway: 'Then <ans/> of the cars drives away.',
  thenAnsOfTheCarsDrivesAway: 'Then <ans/> of the cars drives away.',
  thenAnsOfTheCookiesWasEaten: 'Then <ans/> of the cookies was eaten.',
  thenAnsOfTheCookiesWereEaten: 'Then <ans/> of the cookies were eaten.',
  thenXOfTheBirdsFlewAway: 'Then {0} of the birds flew away.',
  thenXOfTheCarsDriveAway: 'Then {0} of the cars drive away.',
  thenXCookiesWereEaten: 'Then {0} cookies were eaten.',
  thenXCookieWasEaten: 'Then {0} cookie was eaten.',
  thePencilIsXCmLong: 'The pencil is <ans/> cm long.',
  theShapeIsAboveTheAns: 'The {0:string|lower} is above the <ans/>.',
  theShapeIsAnsTheShape: 'The {0:string|lower} is <ans/> the {1:string|lower}.',
  theShapeIsBelowTheAns: 'The {0:string|lower} is below the <ans/>.',
  theShapeIsBetweenTheAnsAndTheAns: 'The {0:string|lower} is between the <ans/> and the <ans/>.',
  theShapeIsToTheAnsOfTheShape: 'The {0:string|lower} is to the <ans/> of the {1:string|lower}.',
  theShapeIsToTheLeftOfAns: 'The {0:string|lower} is to the left of the <ans/>.',
  theShapeIsToTheRightOfAns: 'The {0:string|lower} is to the right of the <ans/>.',
  theShapeMakesAAnsTurnAnsEachTime: 'The shape makes a <ans/> turn <ans/> each time.',
  theShapeMakesAAnsTurnEachTime: 'The shape makes a <ans/> turn each time.',
  theTopShapeIsAAns: 'The top shape is a <ans/>.',
  theTopShapeIsAnAns: 'The top shape is an <ans/>.',
  theTrainHasAnsX: 'The train has <ans/> {0}.',
  theTrainHasNumAns: 'The train has {0:number|numberFormat} <ans/>',
  thereAreAnsAnimalsAltogether: 'There are <ans/> animals altogether.',
  thereAreAnsApplesAltogether: 'There are <ans/> apples altogether.',
  thereAreAnsBalloonsAltogether: 'There are <ans/> balloons altogether.',
  thereAreAnsBananasAltogether: 'There are <ans/> bananas altogether.',
  thereAreAnsColumns: 'There are <ans/> columns.',
  thereAreAnsColumnsOfAns: 'There are <ans/> columns of <ans/>',
  thereAreAnsCookiesAltogether: 'There are <ans/> cookies altogether.',
  thereAreAnsCountersAltogether: 'There are <ans/> counters altogether.',
  thereAreAnsCountersInEachGroup: 'There are <ans/> counters in each group.',
  thereAreAnsEqualGroups: 'There are <ans/> equal groups.',
  thereAreAnsEqualGroupsOfAns: 'There are <ans/> equal groups of <ans/>',
  thereAreAnsFlowersAltogether: 'There are <ans/> flowers altogether.',
  thereAreAnsGroups: 'There are <ans/> groups.',
  thereAreAnsGroupsWithAnsCountersInEachGroup:
    'There are <ans/> groups with <ans/> counters in each group.',
  thereAreAnsGroupsOfTenXAndAnsMoreY: 'There are <ans/> groups of ten {0} and <ans/> more {0}.',
  thereAreAnsHoursInADay: 'There are <ans/> hours in a day.',
  thereAreAnsHoursShownOnAClockFace: 'There are <ans/> hours shown on a clock face.',
  thereAreAnsMarblesAltogether: 'There are <ans/> marbles altogether.',
  thereAreAnsMuffinsAltogether: 'There are <ans/> muffins altogether.',
  thereAreAnsObjectInEachBox: 'There are <ans/> {0:string|lower} in each box.',
  thereAreAnsObjectInTotal: 'There are <ans/> {0:string|lower} in total.',
  thereAreAnsOnesInNum: 'There are <ans/> ones in {0:number|numberFormat}',
  thereAreAnsPearsAltogether: 'There are <ans/> pears altogether.',
  thereAreAnsPencilsAltogether: 'There are <ans/> pencils altogether.',
  thereAreAnsPiecesOfFruitAltogether: 'There are <ans/> pieces of fruit altogether.',
  thereAreAnsRows: 'There are <ans/> rows.',
  thereAreAnsRowsOfAns: 'There are <ans/> rows of <ans/>',
  thereAreAnsSocksAltogether: 'There are <ans/> socks altogether.',
  thereAreAnsSweetsAltogether: 'There are <ans/> sweets altogether.',
  thereAreAnsTensAndAnsOnesTheNumberIsAns:
    'There are <ans/> tens and <ans/> ones.<br/>The number is <ans/>',
  thereAreAnsTensInNum: 'There are <ans/> tens in {0:number|numberFormat}',
  thereAreAnsX: 'There are <ans/> {0:string|lower}.',
  thereAreAnsXCoins: 'There are <ans/> {0:string|lower} coins.',
  thereAreAnsXNotes: 'There are <ans/> {0:string|lower} notes.',
  thereIsAnsObjectInEachBox: 'There is <ans/> {0:string|lower} in each box.',
  thereIsAnsX: 'There is <ans/> {0:string|lower}.',
  theRibbonIsXCmLong: 'The giraffe is <ans/> cm long.',
  theTeddyBearIsXCmTall: 'The teddy bear is <ans/> cm tall.',
  theTrainCarriageIsXCmLong: 'The train carriage is <ans/> cm long.',
  theTreeIsXCmTall: 'The tree is <ans/> cm tall.',
  theXContainerIsAns: 'The {0:string|lower} is <ans/>',
  theXShapeIsAnsTheYShape: 'The {0:string|lower} is <ans/> the {1:string|lower}.',
  towerOfCubesXIsTheAnsTowerOfCubes: 'Tower of cubes {0} is the <ans/> tower of cubes.',
  trainXIsTheAnsTrain: 'Train {0} is the <ans/> train.',
  treeXIsTheAnsTree: 'Tree {0} is the <ans/> tree.',
  turnAns: 'Turn <ans/>.',
  turnLeft: 'Turn left.',
  turnRight: 'Turn right.',
  useTheCardsToCompleteTheSentence: 'Use the cards to complete the sentence.',
  wholeIsAns: 'The whole is <ans/>',
  xEqualGroupsOfY: '{0:number|numberFormat} equal groups of {1:number|numberFormat}',
  xGroupsOfYEquals: '{0:number|numberFormat} groups of {1:number|numberFormat} = <ans/>',
  xIsAnsThanY: '{0} is <ans/> than {1:string|lower}.',
  xIsAnsY: '{0:string|capitalize} is <ans/> {1:string|lower}.',
  xLessThanYisAns: '{0:number|numberFormat} less than {1:number|numberFormat} is <ans/>',
  xLessThanAnsIsY: '{0:number|numberFormat} less than <ans/> is {1:number|numberFormat}',
  xLotsOfYEquals: '{0:number|numberFormat} lots of {1:number|numberFormat} = <ans/>',
  xMoreThanAnsIsY: '{0:number|numberFormat} more than <ans/> is {1:number|numberFormat}',
  xMoreThanYisAns: '{0:number|numberFormat} more than {1:number|numberFormat} is <ans/>',
  xContainerHasAnsJuiceThanContainerY:
    '{0:string} {1:string} has <ans/> juice than {0:string|lower} {2:string}.',
  xObjectsAreSharedEquallyBetweenAnsBoxes:
    '{0:number|numberFormat} {1:string|lower} are shared equally between <g><ans/> boxes</g>.',
  xIsAnsOfY: '{0:string|capitalize} is <ans/> <g>{1:string|lower}.</g>'
};
