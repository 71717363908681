/*
 * This file contains commonly-used function which test whether a value meets some condition.
 *
 * They are called _matchers_, inspired by Jest/Jasmine or Mocha/Chai or Hamcrest testing libraries.
 *
 * Each of these is a function returning a function. Specifically they all basically have signature (with some extra
 * readonly schenanigans):
 *
 * `(...args: Args) => ((value: T) => boolean)`
 *
 * The are useful when writing testCorrect or testComplete functions.
 *
 * Not all matchers have to live here. Matchers that only make sense for a particular component can be defined in
 * that component's file. These are just the matchers that are very general.
 */

import deepEqual from 'react-fast-compare';
import { arraysHaveSameContentsUnordered } from './collections';

// Workaround for Readonly<unknown> not extending null: https://github.com/microsoft/TypeScript/issues/50774#issuecomment-1249605264
type Test<T> = (value: unknown extends T ? unknown : Readonly<T>) => boolean;

export function not<T>(test: Test<T>): Test<T> {
  return value => !test(value);
}

export function every<T>(...tests: readonly Test<T>[]): Test<T> {
  return value => tests.every(test => test(value));
}

export function some<T>(...tests: readonly Test<T>[]): Test<T> {
  return value => tests.some(test => test(value));
}

/** Uses referential equality. (i.e. `Object.is`) */
export function is<T>(target: T): Test<unknown> {
  return value => Object.is(target, value);
}
export function isNot<T>(target: T): Test<unknown> {
  return not(is(target));
}
export function isOneOf<T>(targets: readonly T[]): Test<unknown> {
  return some(...targets.map(target => is(target)));
}

/** Uses deep structural equality. */
export function isEqual<T>(target: T): Test<unknown> {
  return value => deepEqual(value, target);
}
export function isNotEqual<T>(target: T): Test<unknown> {
  return not(isEqual(target));
}
export function isEqualToOneOf<T>(targets: readonly T[]): Test<unknown> {
  return some(...targets.map(target => isEqual(target)));
}

////
// Collections
////

export function isEqualUnordered<T>(target: readonly T[]): Test<unknown[]> {
  return value => arraysHaveSameContentsUnordered<unknown>(value, target);
}

////
// Numbers
////

export function isGreaterThanOrEqual(target: number): Test<number> {
  return value => value >= target;
}
export function isGreaterThan(target: number): Test<number> {
  return value => value > target;
}
export function isLessThanOrEqual(target: number): Test<number> {
  return value => value <= target;
}
export function isLessThan(target: number): Test<number> {
  return value => value < target;
}
export function isInRange(lower: number, upper: number): Test<number> {
  return every(isGreaterThanOrEqual(lower), isLessThanOrEqual(upper));
}
export function isInRangeExclusive(lower: number, upper: number): Test<number> {
  return every(isGreaterThan(lower), isLessThan(upper));
}
export function isCloseTo(target: number, distance: number): Test<number> {
  return value => Math.abs(value - target) < distance;
}

////
// 2D coordinates
////
/** Works with coordinates in both types: [x, y] or {x, y} */
export function isCloseTo2D(
  target: [number, number] | { x: number; y: number },
  distance: number
): Test<[number, number] | { x: number; y: number }> {
  const targetX = 'x' in target ? target.x : target[0];
  const targetY = 'y' in target ? target.x : target[1];
  return value => {
    const valueX = 'x' in value ? value.x : value[0];
    const valueY = 'y' in value ? value.y : value[1];
    return Math.sqrt((valueX - targetX) ** 2 + (valueY - targetY) ** 2) < distance;
  };
}
