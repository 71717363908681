import {
  CapacitySuffix,
  LengthSuffix,
  MassSuffix,
  Measurement,
  VolumeSuffix,
  createMeasurement
} from './unitConversion';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { range } from './collections';
import { DIV, MULT } from '../constants';
import { AssetSvg } from 'common/src/assets/svg';
import { z } from 'zod';
import { zodEnumFromObjKeys } from './zod';
import { TranslationFunctions } from '../i18n/i18n-types';

/**
 * Object containing the image components for each object.
 * These are all SVGs which scale to meet their parent container's bounds.
 */
export const itemImages = {
  bike: <AssetSvg name="Bike" />,
  pound_coin: <AssetSvg name="Money/Pounds1" height={300} />,
  plane: <AssetSvg name="Plane" />,
  bee: <AssetSvg name="Bee" />,
  elephant: <AssetSvg name="Elephant" />,
  balloon: <AssetSvg name="BalloonBlue" />,
  dice: <AssetSvg name="Dice" />,
  fish_tank: <AssetSvg name="Goldfish_in_a_tank/Goldfish_in_a_tank_1" />,
  juice_bottle: <AssetSvg name="Juice" />,
  kettle: <AssetSvg name="Kettle" />,
  mug: <AssetSvg name="Mug" />,
  cookie: <AssetSvg name="Cookie_biscuit/Cookie_biscuit_5" />,
  ant: <AssetSvg name="Ant" />,
  giraffe: <AssetSvg name="Giraffe" />,
  apple: <AssetSvg name="Array_objects/AppleRed" />
};

export type ItemName = keyof typeof itemImages;

export const itemWithTheArticle = (item: ItemName, translate: TranslationFunctions) => {
  switch (item) {
    case 'bike':
      return translate.objects.theBike();
    case 'pound_coin':
      return translate.objects.theCoin();
    case 'plane':
      return translate.objects.thePlane();
    case 'bee':
      return translate.objects.theBee();
    case 'elephant':
      return translate.objects.theElephant();
    case 'dice':
      return translate.objects.theDice();
    case 'fish_tank':
      return translate.objects.theFishTank();
    case 'kettle':
      return translate.objects.theKettle();
    case 'juice_bottle':
      return translate.objects.theJuiceBottle();
    case 'mug':
      return translate.objects.theMug();
    case 'cookie':
      return translate.objects.theCookie();
    case 'ant':
      return translate.objects.theAnt();
    case 'giraffe':
      return translate.objects.theGiraffe();
    case 'apple':
      return translate.objects.theApple();
    case 'balloon':
      return translate.objects.theBalloon();
  }
};

/**
 * Zod schema for a single item.
 * This includes an item name (from which its image can be obtained with {@link itemGetImage}), a choice of
 * unit that describes that item, and a plausible correct value.
 * This also includes some similar but wrong answers.
 */
export const itemSchema = z.object({
  name: zodEnumFromObjKeys(itemImages),
  value: z.number(),
  units: z.enum([...CapacitySuffix, ...LengthSuffix, ...MassSuffix, ...VolumeSuffix, 'tonnes']), // 'tonnes' is added as WRE workbooks don't use 't' as the suffix for tonnes
  wrongAnswers: z.string().array().length(3)
});

/**
 * A single item. This is serializable, and can appear in a zod schema. See {@link itemSchema}.
 */
export type Item = z.infer<typeof itemSchema>;

type ItemUnits = Item['units'];

/**
 * Generate a random item, with the given units and bounds.
 */
const generateRandomItem = (
  name: ItemName,
  units: ItemUnits,
  usableWrongUnits: string[],
  min: number,
  max: number,
  step: number
): Item => {
  const value = getRandomFromArray(range(min, max, step));

  const wrongUnitsToUse = getRandomSubArrayFromArray(usableWrongUnits, 2);
  const wrongAnswers = [];
  wrongAnswers.push(`${value} ${wrongUnitsToUse[0]}`);

  // Determine whether to divide or multiply by 10
  // If number is not an integer, then always multiply, to avoid multiple decimal places
  const multiplyOrDivide = Number.isInteger(value) ? getRandomFromArray([MULT, DIV]) : MULT;

  const wrongValue = multiplyOrDivide === MULT ? value * 10 : value / 10;

  wrongAnswers.push(
    `${wrongValue.toLocaleString()} ${wrongUnitsToUse[0]}`,
    `${wrongValue.toLocaleString()} ${wrongUnitsToUse[1]}`
  );

  return {
    name,
    value,
    units,
    wrongAnswers
  };
};

/**
 * Convenience function to get an image component from an {@link Item} object.
 */
export const itemGetImage = (item: Item): JSX.Element => {
  return itemImages[item.name];
};

/**
 * Convenience function to get a measurement object from an {@link Item} object.
 * This is useful when needing to perform unit conversions.
 */
export const itemGetMeasurement = (item: Item): Measurement => {
  return createMeasurement(item.value, item.units);
};

/**
 * Helper function to produce an object holding a single generator function. Simply relays its arguments to
 * {@link generateRandomItem}.
 */
function g(
  name: ItemName,
  units: ItemUnits,
  usableWrongUnits: string[],
  min: number,
  max: number,
  step = 1
) {
  return {
    generate: () => generateRandomItem(name, units, usableWrongUnits, min, max, step)
  };
}

/** Generators for all capacity items. */
export const capacityItems = {
  fish_tank: g('fish_tank', 'l', ['ml', 'cl'], 5, 8),
  juice_bottle: g('juice_bottle', 'ml', ['cl', 'l'], 300, 350, 10),
  kettle: g('kettle', 'l', ['ml', 'cl'], 1.2, 2.1, 0.3),
  mug: g('mug', 'ml', ['cl', 'l'], 250, 400, 50)
};

/** Generators for all length items. */
export const lengthItems = {
  bike: g('bike', 'm', ['mm', 'cm', 'km'], 1, 2, 0.1),
  plane: g('plane', 'm', ['mm', 'cm', 'km'], 40, 65, 5),
  bee: g('bee', 'mm', ['cm', 'm', 'km'], 10, 25, 5),
  giraffe: g('giraffe', 'm', ['mm', 'cm', 'km'], 4, 6),
  elephant: g('elephant', 'm', ['mm', 'cm', 'km'], 1.8, 3, 0.2),
  ant: g('ant', 'mm', ['cm', 'm', 'km'], 4, 6)
};

/** Generators for all mass items. */
export const massItems = {
  bike: g('bike', 'kg', ['mg', 'g', 'tonnes'], 10, 15),
  plane: g('plane', 'tonnes', ['mg', 'g', 'kg'], 35, 50, 5),
  bee: g('bee', 'mg', ['mg', 'g', 'kg'], 90, 120, 10),
  elephant: g('elephant', 'tonnes', ['mg', 'g', 'kg'], 3, 5, 0.5),
  dice: g('dice', 'g', ['mg', 'kg', 'tonnes'], 5, 7),
  pound_coin: g('pound_coin', 'g', ['mg', 'kg', 'tonnes'], 8.5, 8.9, 0.1),
  cookie: g('cookie', 'g', ['mg', 'kg', 'tonnes'], 40, 70, 10),
  ant: g('ant', 'mg', ['g', 'kg', 'tonnes'], 5, 12),
  giraffe: g('giraffe', 'tonnes', ['mg', 'g', 'kg'], 1, 1.4, 0.1),
  apple: g('apple', 'g', ['mg', 'kg', 'tonnes'], 80, 120, 10)
};

/** Generators for all volume items. */
export const volumeItems = {
  balloon: g('balloon', 'cm³', ['mm³', 'm³', 'km³'], 24, 36, 3),
  dice: g('dice', 'cm³', ['mm³', 'm³', 'km³'], 1.5, 2, 0.1),
  pound_coin: g('pound_coin', 'cm³', ['mm³', 'm³', 'km³'], 1.5, 2, 0.1),
  fish_tank: g('fish_tank', 'cm³', ['mm³', 'm³', 'km³'], 5000, 8000, 1000),
  juice_bottle: g('juice_bottle', 'cm³', ['mm³', 'm³', 'km³'], 300, 350, 10),
  kettle: g('kettle', 'cm³', ['mm³', 'm³', 'km³'], 1200, 2100, 300),
  mug: g('mug', 'cm³', ['mm³', 'm³', 'km³'], 250, 400, 50)
};

export const singleItemNames = ['book', 'crayonbox', 'football', 'magazine'] as const;

export const singleItemSchema = z.enum(singleItemNames);

export function getRandomItem() {
  return getRandomFromArray(singleItemNames);
}

export type SingleItemName = (typeof singleItemNames)[number];

/** Function to get single item image path. **/
export const getSingleItemImagePath = (item: SingleItemName) => {
  switch (item) {
    case 'book':
      return 'BookRed';
    case 'crayonbox':
      return 'CrayonBox';
    case 'football':
      return 'Sports_ball_football';
    case 'magazine':
      return 'Magazine';
  }
};

/* Shop items */
export const shopItemNames = [
  'a book',
  'a cap',
  'headphones',
  'a box of marbles',
  'a pack of crayons',
  'a magazine'
] as const;

export const shopItemSchema = z.enum(shopItemNames);

export function getRandomShopItem() {
  return getRandomFromArray(shopItemNames);
}
export function getRandomUniqueShopItems(numberOfShopItems: number) {
  return getRandomSubArrayFromArray([...shopItemNames] as const, numberOfShopItems);
}

export type ShopItemName = (typeof shopItemNames)[number];

/** Function to get shop item image. **/
export const getShopItemImage = (
  shopItem: ShopItemName,
  height: number,
  width: number
): JSX.Element => {
  switch (shopItem) {
    case 'a book':
      return <AssetSvg name="BookRed" width={width} height={height} />;
    case 'a pack of crayons':
      return <AssetSvg name="CrayonBox" width={width} height={height} />;
    case 'a cap':
      return <AssetSvg name="Clothes/Hat_cap" width={width} height={height} />;
    case 'headphones':
      return <AssetSvg name="Headphones" width={width} height={height} />;
    case 'a magazine':
      return <AssetSvg name="Magazine" width={width} height={height} />;
    case 'a box of marbles':
      return <AssetSvg name="Pack_of_marbles_blank" width={width} height={height} />;
  }
};

// Clothing items
// TODO: Add in 'Pair of trousers' if this SVG become available.
export const clothingItemsNames = [
  'Cap',
  'Jumper',
  'Pair of gloves',
  'Pair of shorts',
  'Scarf',
  'T-shirt',
  'Woolly hat'
] as const;

export type clothingItemsName = (typeof clothingItemsNames)[number];

export const clothingItemsSchema = z.enum(clothingItemsNames);

export function getRandomClothingItem() {
  return getRandomFromArray(clothingItemsNames);
}

export function getRandomUniqueClothingNames(numberOfClothingNames: number) {
  return getRandomSubArrayFromArray([...clothingItemsNames] as const, numberOfClothingNames);
}

export const clothingItemSVG = (object: clothingItemsName, height?: number, width?: number) => {
  switch (object) {
    case 'Cap':
      return <AssetSvg height={height} width={width} name="Clothes/Hat_cap" />;
    case 'Jumper':
      return <AssetSvg height={height} width={width} name="Clothes/Jumper_blue" />;
    case 'Pair of gloves':
      return <AssetSvg height={height} width={width} name="Clothes/Gloves_mittens_green" />;
    case 'Pair of shorts':
      return <AssetSvg height={height} width={width} name="Clothes/Shorts_grey" />;
    case 'Scarf':
      return <AssetSvg height={height} width={width} name="Clothes/Scarf_purple" />;
    case 'T-shirt':
      return <AssetSvg height={height} width={width} name="Clothes/T-shirt_blue" />;
    case 'Woolly hat':
      return <AssetSvg height={height} width={width} name="Clothes/Hat_woolly" />;
  }
};

// Length items
export const lengthItemNames = [
  'Table',
  'Piece of ribbon',
  'Rod',
  'Bench',
  'Bookshelf',
  'Guitar',
  'Rug'
] as const;

export type lengthItemName = (typeof lengthItemNames)[number];

export const lengthItemSchema = z.enum(lengthItemNames);

export const lengthItemAsWord = (
  object: lengthItemName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Table':
      return translate.items.table(amount);
    case 'Piece of ribbon':
      return translate.items.pieceOfRibbon(amount);
    case 'Rod':
      return translate.items.rod(amount);
    case 'Bench':
      return translate.items.bench(amount);
    case 'Bookshelf':
      return translate.items.bookshelf(amount);
    case 'Guitar':
      return translate.items.guitar(amount);
    case 'Rug':
      return translate.items.rug(amount);
  }
};

export function getRandomLengthItem() {
  return getRandomFromArray(lengthItemNames);
}

export function getRandomUniqueLengthItemNames(numberOfLengthItemNames: number) {
  return getRandomSubArrayFromArray([...lengthItemNames] as const, numberOfLengthItemNames);
}

// Child items
export const childItemNames = [
  'Bottle of juice',
  'Small toy',
  'Toy car',
  'Toy train',
  'Teddy',
  'Book',
  'Book of sticker',
  'Colouring book'
] as const;

export type childItemName = (typeof childItemNames)[number];

export const childItemSchema = z.enum(childItemNames);

export const childItemAsWord = (
  object: childItemName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Bottle of juice':
      return translate.items.bottleOfJuice(amount);
    case 'Small toy':
      return translate.items.smallToy(amount);
    case 'Toy car':
      return translate.items.toyCar(amount);
    case 'Toy train':
      return translate.items.toyTrain(amount);
    case 'Teddy':
      return translate.items.teddy(amount);
    case 'Book':
      return translate.items.book(amount);
    case 'Book of sticker':
      return translate.items.bookOfSticker(amount);
    case 'Colouring book':
      return translate.items.colouringBook(amount);
  }
};

export function getRandomChildItem() {
  return getRandomFromArray(childItemNames);
}

export function getRandomUniqueChildItemNames(numberOfChildItemNames: number) {
  return getRandomSubArrayFromArray([...childItemNames] as const, numberOfChildItemNames);
}

// Packaged items
export const packagedItemNames = ['Chocolates', 'Sweets', 'Pens', 'Rubbers', 'Buttons'] as const;

export type packagedItemName = (typeof packagedItemNames)[number];

export const packagedItemSchema = z.enum(packagedItemNames);

export const packagedItemAsWord = (
  object: packagedItemName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Chocolates':
      return translate.items.chocolates(amount);
    case 'Sweets':
      return translate.items.sweets(amount);
    case 'Pens':
      return translate.items.pens(amount);
    case 'Rubbers':
      return translate.items.rubbers(amount);
    case 'Buttons':
      return translate.items.buttons(amount);
  }
};

export function getRandomPackagedItem() {
  return getRandomFromArray(packagedItemNames);
}

export function getRandomUniquePackagedItemNames(numberOfPackagedItemNames: number) {
  return getRandomSubArrayFromArray([...packagedItemNames] as const, numberOfPackagedItemNames);
}

// Small items

export const smallItemsNames = [
  'Book',
  'Magazine',
  'Yo-Yo',
  'Football',
  'Rugby-ball',
  'Checkers board',
  'Red socks',
  'Blue socks',
  'Green socks',
  'Orange socks',
  'Purple socks',
  'Yellow socks'
] as const;

export type smallItemsName = (typeof smallItemsNames)[number];

export const smallItemsSchema = z.enum(smallItemsNames);

export const smallItemAsWord = (
  object: smallItemsName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Book':
      return translate.items.book(amount);
    case 'Magazine':
      return translate.items.magazine(amount);
    case 'Yo-Yo':
      return translate.items.yoyo(amount);
    case 'Football':
      return translate.items.football(amount);
    case 'Checkers board':
      return translate.items.checkersBoard(amount);
    case 'Rugby-ball':
      return translate.items.rugbyBall(amount);
    case 'Red socks':
    case 'Blue socks':
    case 'Green socks':
    case 'Orange socks':
    case 'Purple socks':
    case 'Yellow socks':
      return translate.items.pairOfSocks(amount);
  }
};

export const aSmallItemAsWords = (object: smallItemsName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Book':
      return translate.items['a book']();
    case 'Magazine':
      return translate.items['a magazine']();
    case 'Yo-Yo':
      return translate.items['a yo-yo']();
    case 'Football':
      return translate.items['a football']();
    case 'Checkers board':
      return translate.items['a checkers board']();
    case 'Rugby-ball':
      return translate.items['a rugby ball']();
    case 'Red socks':
    case 'Blue socks':
    case 'Green socks':
    case 'Orange socks':
    case 'Purple socks':
    case 'Yellow socks':
      return translate.items['a pair of socks']();
  }
};

export const smallItemSVG = (object: smallItemsName, height: number = 100, width: number = 100) => {
  switch (object) {
    case 'Book':
      return <AssetSvg height={height} width={width} name="Closed_book" />;
    case 'Magazine':
      return <AssetSvg height={height} width={width} name="Magazine" />;
    case 'Yo-Yo':
      return <AssetSvg height={height} width={width} name="Yoyo" />;
    case 'Football':
      return <AssetSvg height={height} width={width} name="Sports_ball_football" />;
    case 'Checkers board':
      return <AssetSvg height={height} width={width} name="CheckersBoard" />;
    case 'Rugby-ball':
      return <AssetSvg height={height} width={width} name="Sports_ball_rugby_ball" />;
    case 'Red socks':
      return <AssetSvg height={height} width={width} name="PairedsocksRed" />;
    case 'Blue socks':
      return <AssetSvg height={height} width={width} name="PairedsocksBlue" />;
    case 'Green socks':
      return <AssetSvg height={height} width={width} name="PairedsocksGreen" />;
    case 'Orange socks':
      return <AssetSvg height={height} width={width} name="PairedsocksOrange" />;
    case 'Purple socks':
      return <AssetSvg height={height} width={width} name="PairedsocksPurple" />;
    case 'Yellow socks':
      return <AssetSvg height={height} width={width} name="PairedsocksYellow" />;
  }
};

export function getRandomSmallItem() {
  return getRandomFromArray(smallItemsNames);
}

export function getRandomUniqueSmallItemNames(numberOfSmallItemNames: number) {
  return getRandomSubArrayFromArray([...smallItemsNames] as const, numberOfSmallItemNames);
}
