import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, DIV } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPo',
  description: 'aPo',
  keywords: ['Division', 'Related facts', 'Factors'],
  schema: z.object({
    number1: z.number().int().min(2).max(4),
    number2: z.number().int().min(3).max(5),
    number3: z.number().int().min(2).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 5);

    const number1 = randomIntegerInclusive(2, number2 - 1, {
      constraint: x => x * number2 < 15
    });

    const number3 = randomIntegerInclusive(2, 5, {
      constraint: x => x * number1 * number2 > 10
    });

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number1 * number2;

    const number5 = number3 * number4;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [(number2 * number3).toString()],
          [(number1 * number3).toString()],
          [number3.toString()],
          [number3.toString()]
        ]}
        sentences={[
          `${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${DIV} ${number4.toLocaleString()} = <ans/>`
        ]}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aPp',
  description: 'aPp',
  keywords: ['Division', 'Related facts', 'Factors'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(6),
    numberA2: z.number().int().min(2).max(6),
    numberA3: z.number().int().min(2).max(6),
    numberB1: z.number().int().min(2).max(6),
    numberB2: z.number().int().min(2).max(6),
    numberB3: z.number().int().min(2).max(6),
    numberC1: z.number().int().min(2).max(6),
    numberC2: z.number().int().min(2).max(6),
    numberC3: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const [numberA2, numberB2, numberC2] = randomUniqueIntegersInclusive(2, 6, 3);

    // Equation A:
    const numberA1 = randomIntegerInclusive(2, numberA2, {
      constraint: x => x * numberA2 < 20
    });

    const numberA3 = randomIntegerInclusive(2, 6, {
      constraint: x => x * numberA1 * numberA2 > 10
    });

    // Equation B:
    const numberB1 = randomIntegerInclusive(2, numberB2, {
      constraint: x => x * numberB2 < 20
    });

    const numberB3 = randomIntegerInclusive(2, 6, {
      constraint: x => x * numberB1 * numberB2 > 10
    });

    // Equation C:
    const numberC1 = randomIntegerInclusive(2, numberC2, {
      constraint: x => x * numberC2 < 20
    });

    const numberC3 = randomIntegerInclusive(2, 6, {
      constraint: x => x * numberC1 * numberC2 > 10
    });

    return {
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA3,
        numberB1,
        numberB2,
        numberB3,
        numberC1,
        numberC2,
        numberC3
      },
      translate
    } = props;

    const numberA4 = numberA1 * numberA2;
    const numberA5 = numberA3 * numberA4;

    const numberB4 = numberB1 * numberB2;
    const numberB5 = numberB3 * numberB4;

    const numberC4 = numberC1 * numberC2;
    const numberC5 = numberC3 * numberC4;

    const sentences = shuffle(
      [
        {
          sentence: `${numberA5.toLocaleString()} ${DIV} ${numberA4.toLocaleString()} = ${numberA5.toLocaleString()} ${DIV} ${numberA1.toLocaleString()} ${DIV} <ans/>`,
          answer: numberA2
        },
        {
          sentence: `${numberB5.toLocaleString()} ${DIV} ${numberB4.toLocaleString()} = ${numberB5.toLocaleString()} ${DIV} <ans/> ${DIV} ${numberB2.toLocaleString()}`,
          answer: numberB1
        },
        {
          sentence: `${numberC5.toLocaleString()} ${DIV} <ans/> = ${numberC5.toLocaleString()} ${DIV} ${numberC1.toLocaleString()} ${DIV} ${numberC2.toLocaleString()}`,
          answer: numberC4
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={sentences.map(sentence => [sentence.answer.toString()])}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPq',
  description: 'aPq',
  keywords: ['Division', 'Related facts', 'Partition'],
  schema: z
    .object({
      number1: numberEnum([2, 3, 4, 5, 6, 8, 10]),
      number2: z.number().int().min(100).max(500).multipleOf(100),
      number3: z.number().int().min(3).max(12),
      secondLineAnswerBoxPosition: z.enum(['left', 'right'])
    })
    .refine(val => val.number2 % val.number1 === 0, 'number2 must be a multiple of number1'),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 6, 8, 10] as const);

    const number2 = randomIntegerInclusiveStep(100, 500, 100, {
      constraint: x => x % number1 === 0
    });

    const number3 = randomIntegerInclusive(3, 12);

    const secondLineAnswerBoxPosition = getRandomFromArray(['left', 'right'] as const);

    return {
      number1,
      number2,
      number3,
      secondLineAnswerBoxPosition
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, secondLineAnswerBoxPosition },
      translate
    } = props;

    const number4 = number1 * number3;

    const number5 = number2 + number4;

    const number6 = number2 / number1;

    const number7 = number4 / number1;

    const number8 = number5 / number1;

    const LHS = [`${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()}`, '', ''];
    const RHS = [
      `${number2.toLocaleString()} ${DIV} ${number1.toLocaleString()} ${ADD} <ans/> ${DIV} ${number1.toLocaleString()}`,
      secondLineAnswerBoxPosition === 'left'
        ? `<ans/> ${ADD} ${number7.toLocaleString()}`
        : `${number6.toLocaleString()} ${ADD} <ans/>`,
      '<ans/>'
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.useFactorPairsToWorkOutDivision()}
        leftSide={LHS}
        rightSide={RHS}
        testCorrect={{
          right: [
            [number4.toString()],
            [secondLineAnswerBoxPosition === 'left' ? number6.toString() : number7.toString()],
            [number8.toString()]
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPr',
  description: 'aPr',
  keywords: ['Division', 'Related facts', 'Factors'],
  schema: z.object({
    name: nameSchema,
    dividend: z.number().int().min(104).max(996).multipleOf(4)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const name = getRandomName();

    const dividend = randomIntegerInclusiveStep(104, 996, 4);

    return {
      name,
      dividend
    };
  },
  Component: props => {
    const {
      question: { name, dividend },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.characterIsDividingNumBy4ByHalving(name, dividend)}
        testCorrect={[
          [(dividend / 2).toString()],
          [(dividend / 2).toString(), (dividend / 4).toString()],
          [(dividend / 4).toString()]
        ]}
        sentences={[
          `${dividend.toLocaleString()} ${DIV} ${(2).toLocaleString()} = <ans/>`,
          `<ans/> ${DIV} ${(2).toLocaleString()} = <ans/>`,
          translate.answerSentences.soEquationEqualsAns(
            `${dividend.toLocaleString()} ${DIV} ${(4).toLocaleString()}`
          )
        ]}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPs',
  description: 'aPs',
  keywords: ['Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(6),
      dividends: z
        .number()
        .int()
        .min(100)
        .max(999)
        .array()
        .length(8)
        .refine(val => arrayHasNoDuplicates(val), 'All dividends must be different.')
    })
    .refine(
      val => val.dividends.filter(num => num % val.divisor === 0).length >= 2,
      'At least two dividends must be divisible by the divisor.'
    ),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 6);

    const [correctDividendA, correctDividendB] = randomUniqueIntegersInclusive(100, 999, 2, {
      constraint: x => x % divisor === 0
    });

    const [incorrectDividendA, incorrectDividendB, incorrectDividendC] =
      randomUniqueIntegersInclusive(100, 999, 3, {
        constraint: x => x % divisor !== 0
      });

    const [otherDividendA, otherDividendB, otherDividendC] = randomUniqueIntegersInclusive(
      100,
      999,
      3,
      {
        constraint: x =>
          arrayHasNoDuplicates([
            correctDividendA,
            correctDividendB,
            incorrectDividendA,
            incorrectDividendB,
            incorrectDividendC,
            x
          ])
      }
    );

    const dividends = shuffle([
      correctDividendA,
      correctDividendB,
      incorrectDividendA,
      incorrectDividendB,
      incorrectDividendC,
      otherDividendA,
      otherDividendB,
      otherDividendC
    ]);

    return { divisor, dividends };
  },
  Component: props => {
    const {
      question: { divisor, dividends },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatAreDivisibleByNum(divisor)}
        pdfTitle={translate.instructions.circleNumbersThatAreDivisibleByNum(divisor)}
        testCorrect={dividends.filter(dividend => dividend % divisor === 0)}
        items={dividends}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aPt',
  description: 'aPt',
  keywords: ['Division', 'Fact family'],
  schema: z.object({
    number1: z.number().int().min(3).max(9),
    number2: z.number().int().min(3).max(4)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 4);

    const number1 = randomIntegerInclusive(3, 9, {
      constraint: x => x * number2 <= 30
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;
    const number4 = number3 * 100;
    const number5 = number3 * 10;
    const number6 = number2 * 10;
    const number7 = number1 * 10;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `${number4.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
          `${number4.toLocaleString()} ${DIV} ${number6.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${DIV} ${number7.toLocaleString()} = <ans/>`
        ]}
        title={translate.instructions.useFactToWorkOutDivisions()}
        testCorrect={[
          [(number4 / number2).toString()],
          [(number4 / number6).toString()],
          [(number5 / number1).toString()],
          [(number5 / number7).toString()]
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={
          <ContentBox>
            <Text style={{ fontSize: 28 }}>
              {translate.instructions.xDividedByYIsEqualToZ(number3, number2, number1)}
            </Text>
          </ContentBox>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EfficientDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
