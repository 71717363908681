import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  Base10RepTo100sVariant,
  Base10RepTo100sVariantArray,
  Base10RepTo100sVariantSchema
} from 'common/src/components/question/representations/types';
import { range, rangeAsString, sortNumberArray } from 'common/src/utils/collections';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from 'common/src/utils/random';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aas',
  description: 'aas',
  keywords: ['Place value', 'Hundreds', '100', 'Objects'],
  schema: z.object({
    variant: Base10RepTo100sVariantSchema,
    hundreds: z.number().int().min(1).max(9)
  }),
  questionHeight: 900,
  example: {
    variant: 'Sweets',
    hundreds: 4
  },
  simpleGenerator: () => {
    // Need to remove counters and cubes from this selection because they
    // can lead to a confusing question for the user.
    const variant = getRandomFromArray(
      Base10RepTo100sVariantArray.filter(x => x !== 'Counters' && x !== 'Cubes')
    ) as Base10RepTo100sVariant;
    // Limit hundreds to 1-9
    const hundreds = randomIntegerInclusive(1, 9);
    return { variant, hundreds };
  },
  Component: props => {
    const {
      question: { hundreds, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(hundreds * 100).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { hundreds }, arrangement: 'ltr' }}
            usableWidth={hundreds === 1 ? dimens.width / 2 : dimens.width}
            usableHeight={hundreds === 1 ? dimens.height / 2 : dimens.height}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aat',
  description: 'aat',
  keywords: ['Place value', 'Hundreds', '100', 'Base 10'],
  schema: z.object({
    variant: Base10RepTo100sVariantSchema,
    hundreds: z.number().int().min(1).max(9)
  }),
  questionHeight: 900,
  example: {
    variant: 'Cubes',
    hundreds: 4
  },
  simpleGenerator: () => {
    const variant = 'Cubes' as Base10RepTo100sVariant;
    // Limit hundreds to 1-9
    const hundreds = randomIntegerInclusive(1, 9);
    return { variant, hundreds };
  },
  Component: props => {
    const {
      question: { hundreds, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(hundreds * 100).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

export const Question3 = newQuestionContent({
  uid: 'aau',
  description: 'aau',
  keywords: ['Place value', '100', 'Hundreds', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(900)).min(3).max(3),
      startingNumber: z.number().int().min(0).max(400),
      endNumber: z.number().int().min(500).max(900),
      interval: z.number().int().min(100).max(100)
    })
    .refine(
      val => val.numbers.every(number => number >= val.startingNumber),
      val => ({ message: `All answers must be greater than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number <= val.endNumber),
      val => ({ message: `All answers must less than or equal to ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const interval = 100;
    const startingNumber = getRandomFromArray(range(0, 400, interval));
    const endNumber = startingNumber + 500;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    // Add the third number in the track
    numbers.add(choices[2]);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices.slice(2))!);
    }

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers: sortNumberArray(Array.from(numbers)), startingNumber, endNumber, interval };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber, interval } = question;

    // Create array to pass to Number Line
    const numberArray = rangeAsString(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aav',
  description: 'aav',
  keywords: ['Place value', 'Hundreds', '100', 'Multiples'],
  schema: z.object({
    numbers: z.number().int().min(0).max(1000).array().length(8),
    name: nameSchema
  }),
  example: {
    numbers: [200, 50, 80, 430, 600, 750, 120, 860],
    name: 'Alex'
  },
  simpleGenerator: () => {
    const pickedNumbers: number[] = [];
    const pickNumber = (sample: () => number) =>
      pickedNumbers.push(rejectionSample(sample, x => !pickedNumbers.includes(x)));

    // Multiples of 100 up to 1000
    pickNumber(() => randomIntegerInclusive(1, 10) * 100);
    pickNumber(() => randomIntegerInclusive(1, 10) * 100);

    // Two-digit multiples of 10
    pickNumber(() => randomIntegerInclusive(1, 9) * 10);
    pickNumber(() => randomIntegerInclusive(1, 9) * 10);

    // Three-digit multiples of 10
    pickNumber(() => randomIntegerInclusive(10, 99) * 10);
    pickNumber(() => randomIntegerInclusive(10, 99) * 10);

    // Any two other numbers from the set
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);

    const name = getRandomName();

    return { numbers: shuffle(pickedNumbers), name };
  },
  Component: props => {
    const {
      question: { numbers, name },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterCountsUpIn100sFromZero(name)}
        testCorrect={numbers.filter(it => it % 100 === 0)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

export const Question5 = newQuestionContent({
  uid: 'aaw',
  description: 'aaw',
  keywords: ['Place value', '100', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(900)).min(3).max(3),
      endNumber: z.number().int().min(0).max(400),
      startingNumber: z.number().int().min(500).max(900)
    })
    .refine(
      val => val.numbers.every(number => number <= val.startingNumber),
      val => ({ message: `All answers must be less than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number >= val.endNumber),
      val => ({ message: `All answers must more than or equal to ${val.endNumber}` })
    )
    .refine(val => val.endNumber === val.startingNumber - 500, {
      message: 'Track end number should be starting number - 500'
    }),
  simpleGenerator: () => {
    const interval = 100; // A negative interval throws and error from the range function.
    const endNumber = getRandomFromArray(range(400, 0, interval));
    const startingNumber = endNumber + 500;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices));
    }

    // Convert the set in to an array and sort the numbers from highest to lowest
    // This means the answers index matches with the user answers index.
    return {
      numbers: sortNumberArray(Array.from(numbers), 'descending'),
      startingNumber,
      endNumber
    };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber } = question;
    const interval = 100;

    // Create array to pass to Number Track
    const numberArray = rangeAsString(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aax',
  description: 'aax',
  keywords: ['Place value', 'Hundreds', '100', 'Tens'],
  schema: z.object({
    hundreds: z.number().int().min(1).max(9)
  }),
  example: {
    hundreds: 4
  },
  simpleGenerator: () => ({
    hundreds: randomIntegerInclusive(1, 9)
  }),
  Component: props => {
    const {
      question: { hundreds },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.thereAreAns10sInX100((hundreds * 100).toLocaleString())}
        testCorrect={[(hundreds * 10).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Hundreds',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
