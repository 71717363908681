import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { filledArray, rangeAsString } from '../../../../utils/collections';
import { integerToWord } from '../../../../utils/math';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcl',
  description: 'bcl',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    number: z.number().int().min(1).max(19)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);
    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;

    // Create array of empty strings
    const tickValues: (number | null)[] = filledArray(null, 11);
    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = startingNumber + 10;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcm',
  description: 'bcm',
  keywords: ['Number line', 'More', 'Less'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    correctNumber: z.number().int().min(1).max(19),
    direction: z.enum(['higher', 'lower']),
    useNumbers: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);
    const direction = getRandomFromArray(['higher', 'lower'] as const);
    const correctNumber = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);
    const useNumbers = getRandomBoolean();

    return { startingNumber, correctNumber, direction, useNumbers };
  },
  Component: props => {
    const {
      question: { startingNumber, correctNumber, direction, useNumbers },
      translate
    } = props;

    // Create array of empty strings
    const tickValues = rangeAsString(startingNumber, startingNumber + 10, 1, true);
    const baseNumber = direction === 'higher' ? correctNumber - 1 : correctNumber + 1;

    const { title, pdfTitle } = (() => {
      if (direction === 'higher') {
        return {
          title: useNumbers
            ? translate.ks1Instructions.dragTheArrowTo1MoreThanNumberAsNumber(baseNumber)
            : translate.ks1Instructions.dragTheArrowToOneMoreThanNumberAsWord(
                integerToWord(baseNumber)
              ),
          pdfTitle: useNumbers
            ? translate.ks1PDFInstructions.circle1MoreThanNumberAsNumber(baseNumber)
            : translate.ks1PDFInstructions.circleOneMoreThanNumberAsWord(integerToWord(baseNumber))
        };
      }

      return {
        title: useNumbers
          ? translate.ks1Instructions.dragTheArrowTo1LessThanNumberAsNumber(baseNumber)
          : translate.ks1Instructions.dragTheArrowToOneLessThanNumberAsWord(
              integerToWord(baseNumber)
            ),
        pdfTitle: useNumbers
          ? translate.ks1PDFInstructions.circle1LessThanNumberAsNumber(baseNumber)
          : translate.ks1PDFInstructions.circleOneLessThanNumberAsWord(integerToWord(baseNumber))
      };
    })();

    return (
      <QF19NumberLineDragArrow
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[correctNumber, correctNumber]}
        min={startingNumber}
        max={startingNumber + 10}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcn',
  description: 'bcn',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    number: z.number().int().min(1).max(19),
    direction: z.enum(['higher', 'lower']),
    useNumbers: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);
    const direction = getRandomFromArray(['higher', 'lower'] as const);
    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);
    const useNumbers = getRandomBoolean();

    return { startingNumber, number, direction, useNumbers };
  },
  Component: props => {
    const {
      question: { startingNumber, number, direction, useNumbers },
      translate
    } = props;

    // Create array of empty strings
    const tickValues = rangeAsString(startingNumber, startingNumber + 10, 1, true);
    const lowAnswer = direction === 'higher' ? number : startingNumber;
    const highAnswer = direction === 'higher' ? startingNumber + 10 : number;

    const { title, pdfTitle } = (() => {
      if (direction === 'higher') {
        return {
          title: useNumbers
            ? translate.ks1Instructions.dragTheArrowGreaterThanNumberAsNumber(lowAnswer - 1)
            : translate.ks1Instructions.dragTheArrowGreaterThanNumberAsWord(
                integerToWord(lowAnswer - 1)
              ),
          pdfTitle: useNumbers
            ? translate.ks1PDFInstructions.circleGreaterThanNumberAsNumber(lowAnswer - 1)
            : translate.ks1PDFInstructions.circleGreaterThanNumberAsWord(
                integerToWord(lowAnswer - 1)
              )
        };
      }

      return {
        title: useNumbers
          ? translate.ks1Instructions.dragTheArrowLessThanNumberAsNumber(highAnswer + 1)
          : translate.ks1Instructions.dragTheArrowLessThanNumberAsWord(
              integerToWord(highAnswer + 1)
            ),
        pdfTitle: useNumbers
          ? translate.ks1PDFInstructions.circleLessThanNumberAsNumber(highAnswer + 1)
          : translate.ks1PDFInstructions.circleLessThanNumberAsWord(integerToWord(highAnswer + 1))
      };
    })();

    return (
      <QF19NumberLineDragArrow
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[lowAnswer, highAnswer]}
        min={startingNumber}
        max={startingNumber + 10}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseANumberLineTo20',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
