import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import {
  getCharacterStandingOffsets,
  getCharacterStandingSvgName
} from '../../../../utils/characters';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { LineGraphColors } from '../../../../theme/colors';
import { measureObjectWithArticle } from '../../../../utils/objects';
import { SvgName } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGA',
  description: 'aGA',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(15)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 15)
  }),
  Component: ({ question, translate }) => {
    const { length } = question;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        sentence={`<ans/> ${translate.units.cm()}`}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={15}
            items={[{ length, lineColor: color }]}
          />
        )}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGB',
  description: 'aGB',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 8)
  }),
  Component: ({ question, translate }) => {
    const { length } = question;
    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        sentence={`<ans/> ${translate.units.cm()}`}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            vertical
            rulerKind="cm"
            rulerLength={8}
            items={[{ length, lineColor: color }]}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGC',
  description: 'aGC',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(15),
    angle: z.number().int().min(10).max(35)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 15),
    /** anticlockwise */
    angle: randomIntegerInclusiveStep(10, 35, 5)
  }),
  Component: ({ question, translate }) => {
    const { length, angle } = question;
    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        sentence={`<ans/> ${translate.units.cm()}`}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            // Negate to make it clockwise
            rotation={-angle}
            rulerKind="cm"
            rulerLength={15}
            items={[{ length, lineColor: color }]}
          />
        )}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aGD',
  description: 'aGD',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(5).max(15),
    object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(5, 15);
    const object = getRandomFromArray(['lollipop', 'pencil', 'stick', 'drinking_straw'] as const);

    return { length, object };
  },
  Component: ({ question, translate }) => {
    const { length, object } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';

    const theObject = measureObjectWithArticle(object, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsLengthOfX(theObject)}
        sentence={translate.answerSentences.xIsAnsCmLong(theObject)}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={15}
            items={[{ length, svgInfo: { name: objectSvgName }, guidelines: true }]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGE',
  description: 'aGE',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z
    .object({
      start: z.number().int().min(1).max(5),
      length: z.number().int().min(5).max(15),
      object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw', 'toy_car'])
    })
    .refine(({ start, length }) => start + length <= 15),
  simpleGenerator: () => {
    const start = randomIntegerInclusive(1, 5);
    const length = randomIntegerInclusive(5, 15, { constraint: x => x + start <= 15 });

    const object = getRandomFromArray([
      'lollipop',
      'pencil',
      'stick',
      'drinking_straw',
      'toy_car'
    ] as const);

    return { length, object, start };
  },
  Component: ({ question, translate }) => {
    const { length, object, start } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';
    if (object === 'toy_car') objectSvgName = 'Car';

    const theObject = measureObjectWithArticle(object, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsLengthOfX(theObject)}
        sentence={translate.answerSentences.xIsAnsCmLong(theObject)}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={15}
            items={[{ start, length, svgInfo: { name: objectSvgName }, guidelines: true }]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aGF2',
  description: 'aGF',
  keywords: ['Measure', 'Length', 'Centimetres', 'Metres'],
  schema: z.object({
    height: z.number().int().min(110).max(190).step(10)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusiveStep(110, 190, 10);

    return { height };
  },
  Component: ({ question: { height }, translate }) => {
    const metres = 1;
    const centimetres = height - 100;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howTallIsObject(translate.objects['theGiraffe']())}
        sentence={translate.answerSentences.ansMAndAnsCm()}
        testCorrect={[metres.toString(), centimetres.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            style={{ alignSelf: 'flex-end' }}
            width={dimens.width}
            height={dimens.height}
            vertical
            rulerKind="m"
            rulerLength={2}
            items={[
              {
                length: height / 100,
                svgInfo: {
                  name: 'Giraffe'
                },
                guidelines: true
              }
            ]}
            maxScaleFactor={null}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row-reverse' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{
          justifyContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGF',
  description: 'aGF',
  keywords: ['Measure', 'Length', 'Centimetres', 'Metres'],
  schema: z.object({
    height: z.number().int().min(110).max(190).step(10),
    character: nameSchema
  }),
  simpleGenerator: () => ({
    height: randomIntegerInclusiveStep(110, 190, 10),
    character: rejectionSample(
      getRandomName,
      // Dexter intentionally not used in this question
      name => name !== 'Dexter'
    )
  }),
  Component: ({ question: { height, character }, translate }) => {
    const metres = 1;
    const centimetres = height - 100;

    const characterStandingOffsets = getCharacterStandingOffsets(character);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howTallIsCharacter(character)}
        sentence={translate.answerSentences.ansMAndAnsCm()}
        testCorrect={[metres.toString(), centimetres.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            style={{ alignSelf: 'flex-end' }}
            width={dimens.width}
            height={dimens.height}
            vertical
            rulerKind="m"
            rulerLength={2}
            items={[
              {
                length: height / 100,
                svgInfo: {
                  name: getCharacterStandingSvgName(character),
                  startOffset: characterStandingOffsets?.characterBottomOffset,
                  endOffset: characterStandingOffsets?.characterTopOffset
                },
                guidelines: true
              }
            ]}
            maxScaleFactor={null}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row-reverse' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInMetresAndCentimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
