// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import CubicCentimetres from './1CubicCentimetres';
import CompareVolume from './2CompareVolume';
import EstimateVolume from './3EstimateVolume';
import EstimateCapacity from './4EstimateCapacity';

const Block = newBlockContent({
  block: 'Volume',
  field: 'Measurement',
  weeks: [12, 12],
  smallSteps: [
    CubicCentimetres, // Step 1
    CompareVolume, // Step 2
    EstimateVolume, // Step 3
    EstimateCapacity // Step 4
  ]
});
export default Block;
