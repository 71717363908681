import { StyleSheet, View } from 'react-native';
import { useContext } from 'react';
import { PlaceValueColumn } from './PlaceValueCounters';
import { Dimens } from 'common/src/theme/scaling';
import { countRange } from 'common/src/utils/collections';
import { CounterVariant } from '../types';
import { HeaderVariant, TableHeaderRow } from './TableHeaderRow';
import { ScientificNotation } from 'common/src/utils/math';
import { colors } from 'common/src/theme/colors';
import { DisplayMode } from '../../../../contexts/displayMode';
import EasyDragAndDropWithGrid from '../../../draganddrop/EasyDragAndDropWithGrid';

type Props = {
  numberPerRow: ScientificNotation;
  numOfRows: number;
  /**
   * Which columns to show. Avoid `numberPerRow` having a non-zero digit on a column that is not shown.
   * Changing this prop is not well tested.
   */
  columnsToShow: PlaceValueColumn[];
  counterVariant: CounterVariant;
  dimens: Dimens;
  /**
   * Whether to show a decimal point left of the tenths column (once in the header and once in the main table body).
   * Default: true.
   */
  showDecimalPoint?: boolean;
  /**
   * What kind of headers to use.
   * - name: e.g. Hundred Thousands, Hundredths
   * - shortName: e.g. HTh, Hth
   * - number: e.g. 100,000, 0.01
   * Default: name
   */
  headerVariant?: HeaderVariant;
  counterPerRow?: number;
  /**
   * Maximum capacity per drop zone.
   * Default: 9
   */
  maxCapacity?: number;
};

/**
 *
 * MultiRowPlaceValueChartInteractive representation
 * It is always 'interactive' so needs to be within an EasyDragAndDropWithGrid.Provider or EasyDragAndDropWithGrid.ProviderWithState.
 *
 */
export default function MultiRowPlaceValueChartInteractive({
  numOfRows,
  columnsToShow,
  counterVariant,
  dimens,
  showDecimalPoint = true,
  headerVariant = 'name',
  counterPerRow,
  maxCapacity = 9
}: Props) {
  // Everything is indexed by the indices of columnsToShow.
  const totalCols = columnsToShow.length;

  // Compute styles
  const { width, height } = dimens;
  const displayMode = useContext(DisplayMode);
  const styles = getStyles(width, height, totalCols, counterVariant, displayMode, counterPerRow);

  const dragZones = (
    <>
      {countRange(numOfRows).map(rowIndex => (
        <View key={rowIndex} style={{ flex: 1, flexDirection: 'row' }}>
          {columnsToShow.map((_, columnIndex) => {
            return (
              <View
                key={`${rowIndex}_${columnIndex}`}
                style={[styles.col, columnIndex === columnsToShow.length - 1 && styles.endCol]}
              >
                <EasyDragAndDropWithGrid.ZoneMultiple
                  row={rowIndex}
                  column={columnIndex}
                  capacity={maxCapacity}
                  style={styles.dropZone}
                  droppedStyle={styles.counter}
                  wrapperStyle={styles.counterContainer}
                />
              </View>
            );
          })}
        </View>
      ))}
    </>
  );

  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableHeader}>
        <TableHeaderRow
          columnsToShow={columnsToShow}
          headerVariant={headerVariant}
          showDecimalPoint={showDecimalPoint}
        />
      </View>
      <View style={styles.tableContent}>
        <View style={styles.row}>{dragZones}</View>
      </View>
    </View>
  );
}

const getStyles = (
  width: number,
  height: number,
  totalCols: number,
  counterVariant: CounterVariant,
  displayMode: 'digital' | 'pdf' | 'markscheme',
  counterPerRow: number | undefined
) => {
  const containerWidth = width;
  const containerHeight = height;

  const headerHeight = displayMode === 'digital' ? 56 : 86;
  const contentHeight = containerHeight - headerHeight;

  // greyCounters have an "end" actionPanelVariant so the rows fit 2x5, the others use "bottom" can fit 3x3
  const countersPerRow = 4;
  const countersPerCol = 1;

  // scale down the counter size have some space between them, slightly smaller if second row and slightly smaller
  // again if we are forcing onto one row.
  const scale = 0.7;
  const counterSizeByHeight = (containerHeight / countersPerCol) * scale;
  const counterSizeByWidth = (containerWidth / (totalCols * countersPerRow)) * scale;
  const counterSize = Math.min(counterSizeByHeight, counterSizeByWidth);

  return StyleSheet.create({
    // Contains the header and content
    tableContainer: {
      width: containerWidth,
      height: containerHeight,
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },

    // The header
    tableHeader: {
      width: containerWidth,
      height: headerHeight,
      flexDirection: 'row'
    },

    // Rows and columns made of cells.
    tableContent: {
      width: containerWidth
    },
    row: {
      height: contentHeight
    },

    dropZone: {
      flex: 1,
      borderColor: 'transparent',
      alignContent: 'center',
      justifyContent: 'center'
    },

    // A column
    col: {
      flexDirection: 'row',
      width: containerWidth / totalCols,
      flex: 1,
      borderStartWidth: 2,
      borderBottomWidth: 2,
      display: 'flex',
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      justifyContent: 'flex-start'
    },
    endCol: {
      borderRightWidth: 2,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
    },

    // Decimal between columns
    decimal: {
      height: displayMode === 'digital' ? 12 : 20,
      width: displayMode === 'digital' ? 12 : 20,
      borderRadius: 50,
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      position: 'absolute',
      left: displayMode === 'digital' ? -7 : -11,
      // Need to calculate how far from the top the decimal point should be, taking into account the decimal point's height:
      top: (contentHeight - (displayMode === 'digital' ? 12 : 20)) / 2,
      zIndex: 999
    },

    // A cell
    cell: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: containerWidth
    },

    counterContainer: {
      width: counterPerRow
        ? `${90 / counterPerRow}%`
        : counterVariant === 'greyCounter'
        ? '50%'
        : '33.3%',
      alignItems: 'center'
    },

    // A single counter.
    counter: {
      width: counterSize,
      height: counterSize,
      textAlign: 'center',
      textAlignVertical: 'center'
    },

    // A number counter.
    numberCounter: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center'
    }
  });
};
