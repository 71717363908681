import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import deepEqual from 'react-fast-compare';

////
// Questions
////

const q2And3Shapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q2And3Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const Question2 = newQuestionContent({
  uid: 'bbS',
  description: 'bbS',
  keywords: ['Sort', '2-D shapes'],
  schema: z.object({
    /* List of objects to show on the right. This must consist of 2 distinct shapes and 2 distinct colors. */
    items: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.55).max(1).optional(),
          scaleY: z.number().min(0.55).max(1).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
      .refine(
        items => new Set<string>(items.map(item => item.shape)).size === 2,
        'items must consist of exactly two shapes'
      )
      .refine(
        items => new Set<string>(items.map(item => item.color)).size === 2,
        'items must consist of exactly two colors'
      )
  }),
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(q2And3Shapes);

    const shapeB = (() => {
      switch (shapeA) {
        case 'circle':
        case 'triangle':
          return getRandomFromArray(q2And3Shapes.filter(shape => shape !== shapeA));
        case 'square':
          getRandomFromArray(
            // We need to make sure rectangles are not a choice when square is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'rectangle')
          );
        case 'rectangle':
          return getRandomFromArray(
            // We need to make sure squares are not a choice when rectangle is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'square')
          );
      }
    })()!;

    const colorA = getRandomFromArray(q2And3Colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q, so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'pink':
          return ['blue', 'green', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const scalingChoices = [
      {},
      { scaleX: 0.55, scaleY: 0.55 },
      { scaleX: 0.6, scaleY: 0.6 },
      { scaleX: 0.65, scaleY: 0.65 },
      { scaleX: 0.7, scaleY: 0.7 },
      { scaleX: 0.75, scaleY: 0.75 },
      { scaleX: 0.8, scaleY: 0.8 },
      { scaleX: 0.85, scaleY: 0.85 },
      { scaleX: 0.9, scaleY: 0.9 },
      { scaleX: 0.95, scaleY: 0.95 }
    ] as const;

    const rectangleRotationChoices = [{}, { rotation: 90 }] as const;

    const scalingAndRotationChoicesRectangle = scalingChoices.flatMap(scale =>
      rectangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const triangleScalingChoices = [
      ...scalingChoices,
      { scaleX: 0.55 },
      { scaleY: 0.55 },
      { scaleX: 0.6 },
      { scaleY: 0.6 },
      { scaleX: 0.65 },
      { scaleY: 0.65 },
      { scaleX: 0.7 },
      { scaleY: 0.7 },
      { scaleX: 0.75 },
      { scaleY: 0.75 },
      { scaleX: 0.8 },
      { scaleY: 0.8 },
      { scaleX: 0.85 },
      { scaleY: 0.85 },
      { scaleX: 0.9 },
      { scaleY: 0.9 },
      { scaleX: 0.95 },
      { scaleY: 0.95 }
    ] as const;

    const triangleRotationChoices = [
      ...rectangleRotationChoices,
      { rotation: 180 },
      { rotation: 270 }
    ] as const;

    const scalingAndRotationChoicesTriangle = triangleScalingChoices.flatMap(scale =>
      triangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const shapeAAmount = randomIntegerInclusive(2, 7);

    const shapeAColorAAmount = randomIntegerInclusive(1, Math.min(4, shapeAAmount - 1));
    const shapeAColorBAmount = shapeAAmount - shapeAColorAAmount;

    const shapeBAmount = 9 - shapeAAmount;

    const shapeBColorAAmount = randomIntegerInclusive(1, shapeBAmount - 1);
    const shapeBColorBAmount = shapeBAmount - shapeBColorAAmount;

    const shapeAColorAChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorAAmount
    );

    const shapeAColorBChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorBAmount
    );

    const shapeBColorAChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorAAmount
    );

    const shapeBColorBChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorBAmount
    );

    const items = shuffle([
      ...shapeAColorAChoices.map(choice => ({ ...choice, shape: shapeA, color: colorA })),
      ...shapeAColorBChoices.map(choice => ({ ...choice, shape: shapeA, color: colorB })),
      ...shapeBColorAChoices.map(choice => ({ ...choice, shape: shapeB, color: colorA })),
      ...shapeBColorBChoices.map(choice => ({ ...choice, shape: shapeB, color: colorB }))
    ]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    // The schema's refine checks that these sets are of size 2
    const [shapeA, shapeB] = [...new Set(items.map(item => item.shape))];

    const shapeAString = (() => {
      switch (shapeA) {
        case 'circle':
          return translate.shapes.Circles(2);
        case 'rectangle':
          return translate.shapes.Rectangles(2);
        case 'square':
          return translate.shapes.Squares(2);
        case 'triangle':
          return translate.shapes.Triangles(2);
      }
    })();

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[shapeAString, translate.tableHeaders.notX(shapeAString)]}
        testCorrect={[
          items.filter(({ shape }) => shape === shapeA),
          items.filter(({ shape }) => shape === shapeB)
        ]}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bbT',
  description: 'bbT',
  keywords: ['Sort', '2-D shapes'],
  schema: z.object({
    /* List of objects to show on the right. This must consist of 2 distinct shapes and 2 distinct colors. */
    items: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.55).max(1).optional(),
          scaleY: z.number().min(0.55).max(1).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
      .refine(
        items => new Set<string>(items.map(item => item.shape)).size === 2,
        'items must consist of exactly two shapes'
      )
      .refine(
        items => new Set<string>(items.map(item => item.color)).size === 2,
        'items must consist of exactly two colors'
      )
  }),
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(q2And3Shapes);

    const shapeB = (() => {
      switch (shapeA) {
        case 'circle':
        case 'triangle':
          return getRandomFromArray(q2And3Shapes.filter(shape => shape !== shapeA));
        case 'square':
          return getRandomFromArray(
            // We need to make sure rectangles are not a choice when square is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'rectangle')
          );
        case 'rectangle':
          return getRandomFromArray(
            // We need to make sure squares are not a choice when rectangle is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'square')
          );
      }
    })()!;

    const colorA = getRandomFromArray(q2And3Colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q, so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'pink':
          return ['blue', 'green', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const scalingChoices = [
      {},
      { scaleX: 0.55, scaleY: 0.55 },
      { scaleX: 0.6, scaleY: 0.6 },
      { scaleX: 0.65, scaleY: 0.65 },
      { scaleX: 0.7, scaleY: 0.7 },
      { scaleX: 0.75, scaleY: 0.75 },
      { scaleX: 0.8, scaleY: 0.8 },
      { scaleX: 0.85, scaleY: 0.85 },
      { scaleX: 0.9, scaleY: 0.9 },
      { scaleX: 0.95, scaleY: 0.95 }
    ] as const;

    const rectangleRotationChoices = [{}, { rotation: 90 }] as const;

    const scalingAndRotationChoicesRectangle = scalingChoices.flatMap(scale =>
      rectangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const triangleScalingChoices = [
      ...scalingChoices,
      { scaleX: 0.55 },
      { scaleY: 0.55 },
      { scaleX: 0.6 },
      { scaleY: 0.6 },
      { scaleX: 0.65 },
      { scaleY: 0.65 },
      { scaleX: 0.7 },
      { scaleY: 0.7 },
      { scaleX: 0.75 },
      { scaleY: 0.75 },
      { scaleX: 0.8 },
      { scaleY: 0.8 },
      { scaleX: 0.85 },
      { scaleY: 0.85 },
      { scaleX: 0.9 },
      { scaleY: 0.9 },
      { scaleX: 0.95 },
      { scaleY: 0.95 }
    ] as const;

    const triangleRotationChoices = [
      ...rectangleRotationChoices,
      { rotation: 180 },
      { rotation: 270 }
    ] as const;

    const scalingAndRotationChoicesTriangle = triangleScalingChoices.flatMap(scale =>
      triangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const shapeAAmount = randomIntegerInclusive(2, 7);

    const shapeAColorAAmount = randomIntegerInclusive(1, Math.min(4, shapeAAmount - 1));
    const shapeAColorBAmount = shapeAAmount - shapeAColorAAmount;

    const shapeBAmount = 9 - shapeAAmount;

    const shapeBColorAAmount = randomIntegerInclusive(1, shapeBAmount - 1);
    const shapeBColorBAmount = shapeBAmount - shapeBColorAAmount;

    const shapeAColorAChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorAAmount
    );

    const shapeAColorBChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorBAmount
    );

    const shapeBColorAChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorAAmount
    );

    const shapeBColorBChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorBAmount
    );

    const items = shuffle([
      ...shapeAColorAChoices.map(choice => ({ ...choice, shape: shapeA, color: colorA })),
      ...shapeAColorBChoices.map(choice => ({ ...choice, shape: shapeA, color: colorB })),
      ...shapeBColorAChoices.map(choice => ({ ...choice, shape: shapeB, color: colorA })),
      ...shapeBColorBChoices.map(choice => ({ ...choice, shape: shapeB, color: colorB }))
    ]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    // The schema's refine checks that these sets are of size 2
    const [shapeA, shapeB] = [...new Set(items.map(item => item.shape))];
    const [colorA, colorB] = [...new Set(items.map(item => item.color))];

    const correctAnswer = (userAnswer: (typeof items)[number][][]) => {
      return (
        // Sort by shapes:
        (userAnswer[0].every(item => item.shape === shapeA) &&
          userAnswer[1].every(item => item.shape === shapeB)) ||
        (userAnswer[0].every(item => item.shape === shapeB) &&
          userAnswer[1].every(item => item.shape === shapeA)) ||
        // Sort by colors:
        (userAnswer[0].every(item => item.color === colorA) &&
          userAnswer[1].every(item => item.color === colorB)) ||
        (userAnswer[0].every(item => item.color === colorB) &&
          userAnswer[1].every(item => item.color === colorA))
      );
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[translate.tableHeaders.groupNum(1), translate.tableHeaders.groupNum(2)]}
        testCorrect={userAnswer => correctAnswer(userAnswer)}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            items.filter(({ shape }) => shape === shapeA),
            items.filter(({ shape }) => shape === shapeB)
          ],
          answerText: translate.markScheme.shapesCanBeSortedByEitherShapeOrColour()
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Sort2DShapes',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
