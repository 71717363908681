import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { View } from 'react-native';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import QF45CompletePolygonOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45CompletePolygonOnSquareDottedPaper';
import { isValidScaleneTriangle } from '../../../../utils/shapes';
import {
  brokenTriangles,
  getRandomUniqueTriangleNames,
  isoscelesTrianglesNarrow,
  markedEquilateralTriangle,
  markedEquilateralTriangleNamesSchema,
  markedIsoscelesTriangle,
  markedIsoscelesTriangleNamesSchema,
  scaleneTriangleNamesSchema,
  scaleneTriangles,
  triangleNamesSchema
} from '../../../../utils/shapeImages/triangles';
import { pentagonHouses } from '../../../../utils/shapeImages/polygons';

////
// Questions
////

const nonTriangleNamesSchema = z.enum([...brokenTriangles, ...pentagonHouses]);
const scaleneEqualIsosSchema = z.enum([
  ...markedIsoscelesTriangle,
  ...scaleneTriangles,
  ...markedEquilateralTriangle
]);

const Question1 = newQuestionContent({
  uid: 'ays',
  description: 'ays',
  keywords: ['Triangles'],
  schema: z.object({
    triangleNames: triangleNamesSchema.array().length(2),
    nonTriangleNames: nonTriangleNamesSchema.array().length(2)
  }),
  simpleGenerator: () => {
    const nonTriangleNames = getRandomSubArrayFromArray([...brokenTriangles, ...pentagonHouses], 2);
    const triangleNames = getRandomUniqueTriangleNames(2);

    return { nonTriangleNames, triangleNames };
  },
  Component: ({ question: { nonTriangleNames, triangleNames }, translate }) => {
    const correctOrder = [triangleNames, nonTriangleNames];
    const items = shuffle([...nonTriangleNames, ...triangleNames], {
      random: seededRandom({ nonTriangleNames, triangleNames })
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortTheShapes()}
        pdfTitle={translate.instructions.useCardsToSortTheShapes()}
        zoneNames={[translate.keywords.Triangle(), translate.tableHeaders.notATriangle()]}
        testCorrect={correctOrder}
        items={items.map(val => ({
          value: val,
          component: <AssetSvg name={val as SvgName} height={80} />
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'ayt',
  description: 'ayt',
  keywords: ['Triangles', 'Scalene', 'Equilateral', 'Isosceles'],
  schema: z.object({
    statementsType: z.enum(['description', 'type']).array().length(3)
  }),
  simpleGenerator: () => {
    const statementsType = countRange(3).map(() =>
      getRandomFromArray(['description', 'type'] as const)
    );
    return { statementsType };
  },
  Component: ({ question: { statementsType }, translate, displayMode }) => {
    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 150, width: 400 } : { height: 200, width: 800 };
    const isosceles = translate.keywords.Isosceles();
    const equilateral = translate.keywords.Equilateral();
    const scalene = translate.keywords.Scalene();

    const types = [scalene, equilateral, isosceles];

    const descriptions = [
      translate.answerSentences.noSidesOrAnglesEqual(),
      translate.answerSentences.allSidesAndAnglesEqual(),
      translate.answerSentences.twoSidesAndAnglesEqual()
    ];

    const shuffledStatements = shuffle(
      statementsType.map((val, i) => ({
        lhsComponent: (
          <View style={{ width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ fontSize: displayMode === 'digital' ? 32 : 50, textAlign: 'center' }}
            >
              {val === 'description' ? descriptions[i] : types[i]}
            </Text>
          </View>
        ),
        correctAnswer: val === 'description' ? types[i] : descriptions[i]
      })),
      { random: seededRandom({ statementsType }) }
    );

    const answers = statementsType.map((val, i) =>
      val === 'description' ? types[i] : descriptions[i]
    );

    return (
      <QF6DragMatchStatements
        pdfTitle={translate.instructions.useCardsToMatchTypeOfTriangleToDefinition()}
        title={translate.instructions.dragCardsToMatchTypeOfTriangleToDefinition()}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        items={answers}
        actionPanelVariant="endWide"
        itemsMaxLines={2}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'ayu',
  description: 'ayu',
  keywords: ['Triangles', 'Scalene', 'Equilateral', 'Isosceles'],
  schema: z.object({
    isoscelesTriangle: markedIsoscelesTriangleNamesSchema,
    equilateralTriangle: markedEquilateralTriangleNamesSchema,
    scaleneTriangle: scaleneTriangleNamesSchema,
    isoscelesRotation: z.number().int().min(0).max(200),
    equilateralRotation: z.number().int().min(0).max(119),
    scaleneRotation: z.number().int().min(120).max(359)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const isoscelesTriangle = getRandomFromArray(markedIsoscelesTriangle);
    const equilateralTriangle = getRandomFromArray(markedEquilateralTriangle);
    const scaleneTriangle = getRandomFromArray(scaleneTriangles);

    const isoscelesRotation = getRandomBoolean()
      ? randomIntegerInclusive(0, 20)
      : randomIntegerInclusive(160, 200);
    const equilateralRotation = randomIntegerInclusive(0, 119);
    const scaleneRotation = getRandomBoolean()
      ? randomIntegerInclusive(300, 359)
      : randomIntegerInclusive(120, 180);

    return {
      isoscelesTriangle,
      equilateralTriangle,
      scaleneTriangle,
      isoscelesRotation,
      equilateralRotation,
      scaleneRotation
    };
  },
  Component: ({
    question: {
      isoscelesTriangle,
      equilateralTriangle,
      scaleneTriangle,
      isoscelesRotation,
      equilateralRotation,
      scaleneRotation
    },
    translate,
    displayMode
  }) => {
    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 130, width: 180 } : { height: 200, width: 300 };
    const isosceles = translate.keywords.Isosceles();
    const equilateral = translate.keywords.Equilateral();
    const scalene = translate.keywords.Scalene();

    const rotations = [isoscelesRotation, equilateralRotation, scaleneRotation];
    const triangles = [isoscelesTriangle, equilateralTriangle, scaleneTriangle];
    const answers = [isosceles, equilateral, scalene];

    const statements = shuffle(
      triangles.map((val, i) => ({
        lhsComponent: (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              justifyContent: 'center',
              top: i !== 2 ? undefined : rotations[i] >= 300 ? -20 : 20
            }}
          >
            <AssetSvg
              name={val as SvgName}
              height={dimens.height * 0.9}
              width={dimens.width}
              style={{ transform: `rotate(${rotations[i]}deg)` }}
            />
          </View>
        ),
        correctAnswer: answers[i]
      })),
      { random: seededRandom({ isoscelesTriangle, equilateralTriangle, scaleneTriangle }) }
    );

    return (
      <QF6DragMatchStatements
        pdfTitle={translate.instructions.useCardsToMatchTypeOfTriangleToShape()}
        title={translate.instructions.dragCardsToMatchTypeOfTriangleToShape()}
        statements={statements}
        questionHeight={1000}
        statementStyle={{ justifyContent: 'center' }}
        items={[isosceles, equilateral, scalene]}
        actionPanelVariant="endWide"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ayv',
  description: 'ayv',
  keywords: ['Triangles', 'Scalene', 'Equilateral', 'Isosceles'],
  schema: z.object({
    triangleType: z.enum(['Scalene', 'Equilateral', 'Isosceles']),
    svgName: scaleneEqualIsosSchema,
    rotation: z.number().int().min(0).max(89)
  }),
  simpleGenerator: () => {
    const triangleType = getRandomFromArray(['Scalene', 'Equilateral', 'Isosceles'] as const);
    let svgName;
    switch (triangleType) {
      case 'Scalene':
        svgName = getRandomFromArray(scaleneTriangles);
        break;
      case 'Equilateral':
        svgName = getRandomFromArray(markedEquilateralTriangle);
        break;
      case 'Isosceles':
        svgName = getRandomFromArray(markedIsoscelesTriangle);
        break;
    }

    const rotation = randomIntegerInclusive(0, 89);
    return { svgName, triangleType, rotation };
  },
  Component: props => {
    const {
      question: { svgName, triangleType, rotation },
      translate
    } = props;
    const options = ['Scalene', 'Equilateral', 'Isosceles'];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTypeOfTriangle()}
        pdfTitle={translate.instructions.circleTypeOfTriangle()}
        testCorrect={[triangleType]}
        numItems={3}
        Content={({ dimens }) => (
          <AssetSvg
            name={svgName as SvgName}
            height={dimens.height * 0.75}
            width={dimens.height * 0.75}
            style={{ transform: `rotate(${rotation}deg)` }}
          />
        )}
        renderItems={options.map(value => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {translate.keywords[value as 'Scalene' | 'Equilateral' | 'Isosceles']()}
            </Text>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ayw',
  description: 'ayw',
  keywords: ['Triangles', 'Equilateral', 'Isosceles'],
  schema: z.object({
    shape: z.enum(['Isosceles', 'Equilateral']),
    lengths: z.number().int().min(3).max(10).array().length(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Isosceles', 'Equilateral'] as const);
    let lengths;
    if (shape === 'Equilateral') {
      lengths = filledArray(randomIntegerInclusive(3, 10), 3);
    } else {
      const numArray = randomUniqueIntegersInclusive(3, 10, 2);
      const [length1, length2] = sortNumberArray(numArray, 'ascending');
      lengths = [length2, length1, length2];
    }

    return {
      shape,
      lengths
    };
  },
  Component: props => {
    const {
      question: { shape, lengths },
      translate
    } = props;

    const random = seededRandom(props.question);
    const svgName =
      shape === 'Isosceles'
        ? (getRandomFromArray(isoscelesTrianglesNarrow, { random }) as SvgName)
        : getRandomFromArray(markedEquilateralTriangle, { random });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thisIsAnXTriangleWhatIsMissingLength(
          translate.keywords[shape]()
        )}
        Content={({ dimens }) => (
          <LabelledTriangle
            labels={lengths.map((val, i) => (i < 2 ? translate.units.numberOfCm(val) : ''))}
            dimens={dimens}
            assetSvgName={svgName}
            showEqualSidesLines={shape === 'Isosceles'}
            isIsosceles={shape === 'Isosceles'}
          />
        )}
        pdfDirection="column"
        questionHeight={900}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[lengths[2].toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ayx',
  description: 'ayx',
  keywords: ['Triangles', 'Scalene'],
  schema: z.object({
    coordinates: z.array(z.number().int().min(0).max(9).array().length(2)).length(3),
    missingPoint: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 9), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 9), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 9), randomIntegerInclusive(0, 5)];
        const coordinates = [coordinate1, coordinate2, coordinate3];
        const missingPoint = randomIntegerInclusive(0, 2);
        return { coordinates, missingPoint };
      },
      ({ coordinates }) => {
        return isValidScaleneTriangle(
          coordinates[0] as [number, number],
          coordinates[1] as [number, number],
          coordinates[2] as [number, number]
        );
      }
    ),
  Component: ({ question, translate }) => {
    const { coordinates, missingPoint } = question;

    const plottedPoints = coordinates.filter((_val, i) => missingPoint !== i);

    const shapeDisplayString = translate.shapes.scaleneTriangles(1);

    return (
      <QF45CompletePolygonOnSquareDottedPaper
        title={translate.instructions.completeShapeBySelectingWhereADotCouldBe(shapeDisplayString)}
        pdfTitle={translate.instructions.completeShapeBySelectingADotPdf(shapeDisplayString)}
        polygon={coordinates.map(val => ({ x: val[0], y: val[1] }))}
        missingIndex={missingPoint}
        testCorrect={userAnswer =>
          isValidScaleneTriangle(
            plottedPoints[0] as [number, number],
            plottedPoints[1] as [number, number],
            [userAnswer[0].x, userAnswer[0].y]
          )
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Triangles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
