import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { countRange, filledArray, range } from 'common/src/utils/collections';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { colorsAsWord } from 'common/src/utils/colors';
import { AssetSvg, SvgName } from 'common/src/assets/svg';
import { View } from 'react-native';
import { compareRatios } from 'common/src/utils/ratios';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import Table from 'common/src/components/molecules/Table';
import QF24CreateShapeFromSquares from 'common/src/components/question/questionFormats/QF24CreateShapeFromSquares';
import { createShapeWithSquares, trueCount } from 'common/src/utils/shapes';
import { barColorNames, barColorsNamesArray, barColorsNamesSchema } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSY',
  description: 'aSY',
  keywords: ['Ratio', 'Counters'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(5),
      number2: z.number().int().min(3).max(12),
      counterColours: z.array(z.enum(['Blue', 'Green', 'Pink', 'Purple', 'Yellow'])).length(2)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusive(number1 + 1, 12);

    const counterColours = getRandomSubArrayFromArray(
      ['Blue', 'Green', 'Pink', 'Purple', 'Yellow'] as const,
      2
    );

    return { number1, number2, counterColours };
  },
  Component: props => {
    const {
      question: { number1, number2, counterColours },
      translate
    } = props;

    const generateCounters = (counterIndex: number, number: number) => {
      return range(0, number - 1).map(i => {
        const name = `Circles/circle_${counterColours[counterIndex].toLowerCase()}` as SvgName;
        return <AssetSvg key={`${counterColours[counterIndex]}-${i}`} name={name} width={60} />;
      });
    };

    const counters1 = generateCounters(0, number1);
    const counters2 = generateCounters(1, number2);

    const nameColour1 = colorsAsWord(counterColours[0], translate);
    const nameColour2 = colorsAsWord(counterColours[1], translate);

    const counters = [...counters1, ...counters2];

    const shuffledCounters = shuffle(counters, { random: seededRandom(props.question) });

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.ratioOfXCounterstoYCountersIsAns(
          nameColour1,
          nameColour2
        )}
        title={translate.instructions.completeSentence()}
        inputMaxCharacters={2}
        testCorrect={userAnswer => {
          return compareRatios([userAnswer[0], userAnswer[1]], [number1, number2]);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [number1.toLocaleString(), number2.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        Content={
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 8
            }}
          >
            {shuffledCounters.map(counter => counter)}
          </View>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSZ',
  description: 'aSZ',
  keywords: ['Ratio', 'Bar model'],
  schema: z.object({
    shadedSingleRowA: z.number().int().min(1).max(6),
    shadedSingleRowB: z.number().int().min(1).max(6),
    unshadedSingleRowA: z.number().int().min(3).max(7),
    unshadedSingleRowB: z.number().int().min(3).max(7),
    shadedDoubleRow: z.number().int().min(1).max(6),
    unshadedDoubleRow: z.number().int().min(3).max(7),
    barModelCellColor: barColorsNamesSchema
  }),
  simpleGenerator: () => {
    const [unshadedSingleRowA, unshadedSingleRowB, unshadedDoubleRow] =
      randomUniqueIntegersInclusive(3, 7, 3);

    const shadedSingleRowA = randomIntegerInclusive(1, unshadedSingleRowA - 1, {
      constraint: x => (x + unshadedSingleRowA) % 2 === 0
    });

    const shadedSingleRowB = randomIntegerInclusive(1, unshadedSingleRowB - 1, {
      constraint: x => (x + unshadedSingleRowB) % 2 === 0
    });

    const shadedDoubleRow = randomIntegerInclusive(1, unshadedDoubleRow - 1, {
      constraint: x => (x + unshadedDoubleRow) % 2 === 0
    });

    const barModelCellColor = getRandomFromArray(barColorsNamesArray)!;

    return {
      shadedSingleRowA,
      shadedSingleRowB,
      unshadedSingleRowA,
      unshadedSingleRowB,
      shadedDoubleRow,
      unshadedDoubleRow,
      barModelCellColor
    };
  },
  Component: props => {
    const {
      question: {
        shadedSingleRowA,
        shadedSingleRowB,
        unshadedSingleRowA,
        unshadedSingleRowB,
        shadedDoubleRow,
        unshadedDoubleRow,
        barModelCellColor
      },
      translate
    } = props;

    // Shaded
    const shadedSingleRow = [shadedSingleRowA, shadedSingleRowB];
    // Unshaded
    const unshadedSingleRow = [unshadedSingleRowA, unshadedSingleRowB];

    // Items
    const items = [
      translate.answerSentences.xToY(shadedSingleRowA, unshadedSingleRowA),
      translate.answerSentences.xToY(shadedSingleRowB, unshadedSingleRowB),
      translate.answerSentences.xToY(shadedDoubleRow, unshadedDoubleRow)
    ];

    // Used to determine width of cells for multiline ratios
    let rowIdx = 0;

    // Statements
    const statements = countRange(3).map((_item, idx) => {
      rowIdx = idx;
      const barModelLength =
        idx === 2
          ? shadedDoubleRow + unshadedDoubleRow
          : shadedSingleRow[idx] + unshadedSingleRow[idx];
      let table = filledArray(
        filledArray('white', idx === 2 ? barModelLength / 2 : barModelLength),
        idx === 2 ? 2 : 1
      );

      // Shade from top-left, row by row
      let cellsShadedSofar = 0;
      table = table.map(row =>
        row.map(() => {
          if (cellsShadedSofar < (idx === 2 ? shadedDoubleRow : shadedSingleRow[idx])) {
            cellsShadedSofar++;
            return barColorNames[barModelCellColor];
          } else {
            return 'white';
          }
        })
      );

      const coloredItems = table.map(rowColors =>
        rowColors.map((color, columnIndex) => (
          <View
            key={columnIndex}
            style={{
              backgroundColor: color,
              flex: 1,
              width: rowIdx === 2 ? (400 / barModelLength) * 2 : 400 / barModelLength,
              height: 45
            }}
          />
        ))
      );

      return {
        lhsComponent: (
          <Table
            style={{ width: 400, height: 90 }}
            rowStyle={{ flex: 1 }}
            cellStyle={{ flex: 1 }}
            items={coloredItems}
          />
        ),
        correctAnswer: items[idx]
      };
    });

    // Shuffled statements
    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.theXShowShadedPartsToNonShadedPartsDragCardsMatchXToBarModels(
          translate.keywords.Statements().toLowerCase()
        )}
        pdfTitle={translate.instructions.theXShowShadedPartsToNonShadedPartsUseCardsMatchXToBarModels(
          translate.keywords.Statements().toLowerCase()
        )}
        statements={shuffledStatements}
        items={items}
        actionPanelVariant="endWide"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aS0',
  description: 'aS0',
  keywords: ['Ratio', 'Bar model'],
  schema: z.object({
    shadedSingleRowA: z.number().int().min(1).max(6),
    shadedSingleRowB: z.number().int().min(1).max(6),
    unshadedSingleRowA: z.number().int().min(3).max(7),
    unshadedSingleRowB: z.number().int().min(3).max(7),
    shadedDoubleRow: z.number().int().min(1).max(6),
    unshadedDoubleRow: z.number().int().min(3).max(7),
    barModelCellColor: barColorsNamesSchema
  }),
  simpleGenerator: () => {
    const [unshadedSingleRowA, unshadedSingleRowB, unshadedDoubleRow] =
      randomUniqueIntegersInclusive(3, 7, 3);

    const shadedSingleRowA = randomIntegerInclusive(1, unshadedSingleRowA - 1, {
      constraint: x => (x + unshadedSingleRowA) % 2 === 0
    });

    const shadedSingleRowB = randomIntegerInclusive(1, unshadedSingleRowB - 1, {
      constraint: x => (x + unshadedSingleRowB) % 2 === 0
    });

    const shadedDoubleRow = randomIntegerInclusive(1, unshadedDoubleRow - 1, {
      constraint: x => (x + unshadedDoubleRow) % 2 === 0
    });

    const barModelCellColor = getRandomFromArray(barColorsNamesArray)!;

    return {
      shadedSingleRowA,
      shadedSingleRowB,
      unshadedSingleRowA,
      unshadedSingleRowB,
      shadedDoubleRow,
      unshadedDoubleRow,
      barModelCellColor
    };
  },
  Component: props => {
    const {
      question: {
        shadedSingleRowA,
        shadedSingleRowB,
        unshadedSingleRowA,
        unshadedSingleRowB,
        shadedDoubleRow,
        unshadedDoubleRow,
        barModelCellColor
      },
      translate
    } = props;

    // Shaded
    const shadedSingleRow = [shadedSingleRowA, shadedSingleRowB];
    // Unshaded
    const unshadedSingleRow = [unshadedSingleRowA, unshadedSingleRowB];

    // Items
    const items = [
      translate.answerSentences.xColonY(shadedSingleRowA, unshadedSingleRowA),
      translate.answerSentences.xColonY(shadedSingleRowB, unshadedSingleRowB),
      translate.answerSentences.xColonY(shadedDoubleRow, unshadedDoubleRow)
    ];

    // Used to determine width of cells for multiline ratios
    let rowIdx = 0;

    // Statements
    const statements = countRange(3).map((_item, idx) => {
      rowIdx = idx;
      const barModelLength =
        idx === 2
          ? shadedDoubleRow + unshadedDoubleRow
          : shadedSingleRow[idx] + unshadedSingleRow[idx];
      let table = filledArray(
        filledArray('white', idx === 2 ? barModelLength / 2 : barModelLength),
        idx === 2 ? 2 : 1
      );

      // Shade from top-left, row by row
      let cellsShadedSofar = 0;
      table = table.map(row =>
        row.map(() => {
          if (cellsShadedSofar < (idx === 2 ? shadedDoubleRow : shadedSingleRow[idx])) {
            cellsShadedSofar++;
            return barColorNames[barModelCellColor];
          } else {
            return 'white';
          }
        })
      );

      const coloredItems = table.map(rowColors =>
        rowColors.map((color, columnIndex) => (
          <View
            key={columnIndex}
            style={{
              backgroundColor: color,
              flex: 1,
              width: rowIdx === 2 ? (400 / barModelLength) * 2 : 400 / barModelLength,
              height: 45
            }}
          />
        ))
      );

      return {
        lhsComponent: (
          <Table
            style={{ width: 400, height: 90 }}
            rowStyle={{ flex: 1 }}
            cellStyle={{ flex: 1 }}
            items={coloredItems}
          />
        ),
        correctAnswer: items[idx]
      };
    });

    // Shuffled statements
    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.theXShowShadedPartsToNonShadedPartsDragCardsMatchXToBarModels(
          translate.keywords.Ratios().toLowerCase()
        )}
        pdfTitle={translate.instructions.theXShowShadedPartsToNonShadedPartsUseCardsMatchXToBarModels(
          translate.keywords.Ratios().toLowerCase()
        )}
        statements={shuffledStatements}
        items={items}
        actionPanelVariant="endWide"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aS1',
  description: 'aS1',
  keywords: ['Ratio', 'Counters'],
  schema: z.object({
    counters: z.number().int().min(2).max(5),
    marbles: z.number().int().min(3).max(12),
    cubes: z.number().int().min(3).max(12),
    random: z.boolean()
  }),
  simpleGenerator: () => {
    const counters = randomIntegerInclusive(2, 5);
    const [marbles, cubes] = randomUniqueIntegersInclusive(3, 12, 2, {
      constraint: x => x !== counters
    });
    const random = getRandomBoolean();

    return {
      counters,
      marbles,
      cubes,
      random
    };
  },
  Component: props => {
    const {
      question: { counters, marbles, cubes },
      translate
    } = props;

    const [item1, item2] = shuffle(
      [
        {
          name: translate.keywords.Counters().toLowerCase(),
          value: counters,
          images: countRange(counters).map((_counter, idx) => (
            <AssetSvg key={`counters=${idx}`} name={'Counter'} width={60} />
          ))
        },
        {
          name: translate.keywords.Marbles().toLowerCase(),
          value: marbles,
          images: countRange(marbles).map((_marble, idx) => (
            <AssetSvg key={`marbles=${idx}`} name={'Base_Ten/Marbles1'} width={60} />
          ))
        },
        {
          name: translate.keywords.Cubes().toLowerCase(),
          value: cubes,
          images: countRange(cubes).map((_cube, idx) => (
            <AssetSvg
              key={`cubes=${idx}`}
              name={'Cubes_blank/Coloured_cube_unlabelled_yellow'}
              width={60}
            />
          ))
        }
      ],
      { random: seededRandom(props.question) }
    );

    const shuffledCounters = shuffle([...item1.images, ...item2.images], {
      random: seededRandom(props.question)
    });

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.theRatioOfXtoYIsAnsToAns(item1.name, item2.name)}
        title={translate.instructions.completeSentence()}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareRatios([userAnswer[0], userAnswer[1]], [item1.value, item2.value])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [item1.value.toLocaleString(), item2.value.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        Content={
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 16
            }}
          >
            {shuffledCounters.map(counter => counter)}
          </View>
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aS2',
  description: 'aS2',
  keywords: ['Ratio', 'Counters'],
  schema: z.object({
    counters: z.number().int().min(2).max(5),
    marbles: z.number().int().min(3).max(12),
    cubes: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const counters = randomIntegerInclusive(2, 5);
    const [marbles, cubes] = randomUniqueIntegersInclusive(3, 12, 2, {
      constraint: x => x !== counters
    });

    return {
      counters,
      marbles,
      cubes
    };
  },
  Component: props => {
    const {
      question: { counters, marbles, cubes },
      translate
    } = props;

    const object1Display = countRange(counters).map((_counter, idx) => (
      <AssetSvg key={`counter-${idx}`} name={'Counter'} width={60} />
    ));

    const object2Display = countRange(marbles).map((_marble, idx) => (
      <AssetSvg key={`marble-${idx}`} name={'Base_Ten/Marbles1'} width={60} />
    ));

    const object3Display = countRange(cubes).map((_cube, idx) => (
      <AssetSvg
        key={`cube-${idx}`}
        name={'Cubes_blank/Coloured_cube_unlabelled_yellow'}
        width={60}
      />
    ));

    const shuffledCounters = shuffle([...object1Display, ...object2Display, ...object3Display], {
      random: seededRandom(props.question)
    });

    const [item1, item2, item3] = shuffle(
      [
        {
          name: translate.keywords.Counters(),
          value: counters
        },
        {
          name: translate.keywords.Marbles(),
          value: marbles
        },
        {
          name: translate.keywords.Cubes(),
          value: cubes
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.theRatioOfXtoYToZIsAnsToAnsToAns(
          item1.name,
          item2.name,
          item3.name
        )}
        title={translate.instructions.completeSentence()}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareRatios(
            [userAnswer[0], userAnswer[1], userAnswer[2]],
            [item1.value, item2.value, item3.value]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            item1.value.toLocaleString(),
            item2.value.toLocaleString(),
            item3.value.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        Content={
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 16
            }}
          >
            {shuffledCounters.map(counter => counter)}
          </View>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aS3',
  description: 'aS3',
  keywords: ['Ratio'],
  schema: z
    .object({
      shaded: z.number().int().min(1).max(8),
      unshaded: z.number().int().min(1).max(8),
      multiple: z.number().int().min(1).max(5)
    })
    .refine(val => val.shaded < val.unshaded, 'shaded must be less than unshaded'),
  simpleGenerator: () => {
    const { unshaded, shaded, multiple } = rejectionSample(
      () => {
        const unshaded = randomIntegerInclusive(1, 8);
        const shaded = randomIntegerInclusive(1, 8, {
          constraint: x => x + unshaded <= 9
        });
        const multiple = randomIntegerInclusive(1, 5);

        return {
          unshaded,
          shaded,
          multiple
        };
      },
      ({ unshaded, shaded }) => unshaded > shaded && (unshaded + shaded) % 2 === 0
    );
    return {
      unshaded,
      shaded,
      multiple
    };
  },
  Component: props => {
    const {
      question: { shaded, unshaded, multiple },
      translate
    } = props;

    const answer = shaded * multiple;

    const random = seededRandom(props.question);

    const exampleAnswerToDisplay = createShapeWithSquares(
      multiple,
      shaded + unshaded,
      answer,
      false,
      { random },
      undefined,
      false
    );

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.shadeSquaresSoRatioOfShadedToNonShadedSquaresIsXToY(
          shaded,
          unshaded
        )}
        numberOfRows={multiple}
        numberOfCols={shaded + unshaded}
        testCorrect={userAnswer => trueCount(userAnswer) === answer}
        customMarkSchemeAnswer={{
          answerToDisplay: exampleAnswerToDisplay,
          answerText: translate.markScheme.segmentsCanBeInAnyOrder()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IntroductionToTheRatioSymbol',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
