import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { range, sortNumberArray } from 'common/src/utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from 'common/src/utils/random';
import {
  Base10RepTo1000sVariant,
  Base10RepTo1000sVariantArray,
  Base10RepTo1000sVariantSchema
} from 'common/src/components/question/representations/types';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'abO',
  description: 'abO',
  keywords: ['Place value', 'Thousands', '1,000', 'Objects'],
  schema: z.object({
    variant: Base10RepTo1000sVariantSchema,
    thousands: z.number().int().min(2).max(9)
  }),
  questionHeight: 900,
  example: {
    variant: 'Nails',
    thousands: 4
  },
  simpleGenerator: () => {
    const variant = getRandomFromArray(
      Base10RepTo1000sVariantArray.filter(it => it !== 'Counters' && it !== 'Cubes')
    ) as Base10RepTo1000sVariant;

    // Limit thousands to 2-9
    const thousands = randomIntegerInclusive(2, 9);
    return { variant, thousands };
  },
  Component: props => {
    const {
      question: { thousands, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(thousands * 1000).toString()]}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { thousands }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'abP',
  description: 'abP',
  keywords: ['Place value', 'Thousands', '1,000', 'Base 10'],
  schema: z.object({
    variant: Base10RepTo1000sVariantSchema,
    thousands: z.number().int().min(2).max(9)
  }),
  example: {
    variant: 'Cubes',
    thousands: 4
  },
  simpleGenerator: () => {
    const variant = 'Cubes' as Base10RepTo1000sVariant;
    // Limit thousands to 2-9
    const thousands = randomIntegerInclusive(2, 9);
    return { variant, thousands };
  },
  Component: props => {
    const {
      question: { thousands, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(thousands * 1000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { thousands }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

export const Question3 = newQuestionContent({
  uid: 'abQ',
  description: 'abQ',
  keywords: ['Place value', '1,000', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(9000)).min(3).max(3),
      startingNumber: z.number().int().min(0).max(4000),
      endNumber: z.number().int().min(5000).max(9000)
    })
    .refine(
      val => val.numbers.every(number => number >= val.startingNumber),
      val => ({ message: `All answers must be greater than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number <= val.endNumber),
      val => ({ message: `All answers must less than or equal to ${val.endNumber}` })
    )
    .refine(val => val.endNumber === val.startingNumber + 5000, {
      message: 'Track end number should be starting number + 5000'
    }),
  example: {
    numbers: [4000, 5000, 7000],
    startingNumber: 2000,
    endNumber: 7000
  },
  simpleGenerator: () => {
    const interval = 1000;
    const startingNumber = getRandomFromArray(range(0, 4000, interval));
    const endNumber = startingNumber + 5000;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    // Add the third number in the track
    numbers.add(choices[2]);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices.slice(2))!);
    }

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers: sortNumberArray(Array.from(numbers)), startingNumber, endNumber };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber } = question;
    const interval = 1000;

    // Create array to pass to Number Line
    const numberArray: (number | string)[] = range(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number)] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray.map(val => val.toLocaleString())}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'abR',
  description: 'abR',
  keywords: ['Place value', 'Thousands', '1,000', 'Multiples'],
  schema: z.object({
    numbers: z.number().int().min(0).max(10000).array().length(8),
    name: nameSchema
  }),
  example: {
    numbers: [2000, 50, 180, 2430, 6000, 700, 1200, 8600],
    name: 'Alex'
  },
  simpleGenerator: () => {
    const pickedNumbers: number[] = [];
    const pickNumber = (sample: () => number) =>
      pickedNumbers.push(rejectionSample(sample, x => !pickedNumbers.includes(x)));

    // Multiples of 1000 up to 10000
    pickNumber(() => randomIntegerInclusive(1, 10) * 1000);
    pickNumber(() => randomIntegerInclusive(1, 10) * 1000);

    // Two-digit multiple of 10
    pickNumber(() => randomIntegerInclusive(1, 9) * 10);

    // Three-digit multiple of 10
    pickNumber(() => randomIntegerInclusive(10, 99) * 10);

    // Four-digit multiple of 10
    pickNumber(() => randomIntegerInclusive(100, 999) * 10);

    // Three-digit multiple of 100
    pickNumber(() => randomIntegerInclusive(1, 9) * 100);

    // Four-digit multiples of 100
    pickNumber(() => randomIntegerInclusive(10, 99) * 100);
    pickNumber(() => randomIntegerInclusive(10, 99) * 100);

    const name = getRandomName();

    return { numbers: shuffle(pickedNumbers), name };
  },
  Component: props => {
    const {
      question: { numbers, name },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterCountsUpIn1000sFromZero(name)}
        testCorrect={numbers.filter(it => it % 1000 === 0)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

export const Question5 = newQuestionContent({
  uid: 'abS',
  description: 'abS',
  keywords: ['Place value', '1,000', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(9000)).min(3).max(3),
      endNumber: z.number().int().min(0).max(4000),
      startingNumber: z.number().int().min(5000).max(9000)
    })
    .refine(
      val => val.numbers.every(number => number <= val.startingNumber),
      val => ({ message: `All answers must be less than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number >= val.endNumber),
      val => ({ message: `All answers must more than or equal to ${val.endNumber}` })
    )
    .refine(val => val.endNumber === val.startingNumber - 5000, {
      message: 'Track end number should be starting number - 5000'
    }),
  example: {
    numbers: [4000, 6000, 7000],
    startingNumber: 7000,
    endNumber: 2000
  },
  simpleGenerator: () => {
    const interval = 1000; // A negative interval throws and error from the range function.
    const endNumber = getRandomFromArray(range(4000, 0, interval));
    const startingNumber = endNumber + 5000;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices));
    }

    // Convert the set in to an array and sort the numbers from highest to lowest
    // This means the answers index matches with the user answers index.
    return {
      numbers: sortNumberArray(Array.from(numbers), 'descending'),
      startingNumber,
      endNumber
    };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber } = question;
    const interval = 1000;

    // Create array to pass to Number Line
    const numberArray: (number | string)[] = range(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number)] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray.map(val => val.toLocaleString())}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'abT',
  description: 'abT',
  keywords: ['Place value', 'Thousands', '1,000', 'Hundreds'],
  schema: z.object({
    thousands: z.number().int().min(1).max(9),
    ansBoxLeft: z.boolean()
  }),
  simpleGenerator: () => ({
    thousands: randomIntegerInclusive(1, 9),
    ansBoxLeft: getRandomBoolean()
  }),
  Component: props => {
    const {
      question: { thousands, ansBoxLeft },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeStatement()}
        testCorrect={ansBoxLeft ? [(thousands * 1000).toString()] : [(thousands * 10).toString()]}
        sentence={
          ansBoxLeft
            ? translate.answerSentences.ansEqualsXHundreds((thousands * 10).toLocaleString())
            : translate.answerSentences.XEqualsAnsHundreds((thousands * 1000).toLocaleString())
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Thousands',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
