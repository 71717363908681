import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import {
  getRandomUniqueWeightObjects,
  objectsWithWeights,
  ObjectsWithWeightsName,
  objectsWithWeightsNameSchema
} from '../../../../utils/objects';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { arrayHasNoDuplicates, greatest, smallest } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjT',
  description: 'bjT',
  keywords: ['More', 'Less', 'Volume', 'Capacity', 'Compare'],
  schema: z.object({
    jugVolumes: z.array(z.number().int().min(1).max(5)).length(2).refine(arrayHasNoDuplicates),
    flipSentence: z.boolean()
  }),
  simpleGenerator: () => {
    const jugVolumes = randomUniqueIntegersInclusive(1, 5, 2);
    const flipSentence = getRandomBoolean();

    return { jugVolumes, flipSentence };
  },
  Component: props => {
    const {
      question: { jugVolumes, flipSentence },
      translate
    } = props;
    const more = translate.misc.more();
    const less = translate.misc.less();

    const A = translate.letters.A();
    const B = translate.letters.B();

    const [first, second] = flipSentence ? [B, A] : [A, B];
    const [firstIndex, secondIndex] = flipSentence ? [1, 0] : [0, 1];

    const answer = jugVolumes[firstIndex] > jugVolumes[secondIndex] ? more : less;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragCardCompleteSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        sentence={translate.ks1AnswerSentences.jugXHasAnsWaterThanJugY(first, second)}
        testCorrect={[answer]}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            {jugVolumes.map((vol, i) => (
              <View key={i}>
                <JugWithLiquid
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
                  jugCapacity={5}
                  liquidAmount={vol}
                  liquidType={'water'}
                  hideAllLabels={true}
                  tickValue={1}
                />
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {[A, B][i]}
                </Text>
              </View>
            ))}
          </View>
        )}
        itemVariant="square"
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        items={[more, less]}
        sentencesStyle={{ alignItems: 'flex-start' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bjU',
  description: 'bjU',
  keywords: ['Full', 'Empty', 'Capacity', 'Compare'],
  questionHeight: 1000,
  schema: z.object({
    smallestOrGreatest: z.enum(['smallest', 'greatest']),
    randomObjects: z.array(objectsWithWeightsNameSchema).length(4)
  }),
  simpleGenerator: () => {
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);
    const { randomObjectsWithInfo } = rejectionSample(
      () => {
        const randomObjectsWithInfo = getRandomUniqueWeightObjects(4).map(name => ({
          name,
          ...objectsWithWeights[name]
        }));
        return { randomObjectsWithInfo };
      },

      ({ randomObjectsWithInfo }) => {
        // Ensure there's at least a gap of 2 in weight between the smallest/greatest objects
        // Ensures it's not too difficult to distinguish between similar sizes
        if (smallestOrGreatest === 'smallest') {
          const [smallestObject, secondSmallestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => a.weight - b.weight
          );

          return Math.abs(smallestObject.weight - secondSmallestObject.weight) > 1;
        } else {
          const [greatestObject, secondGreatestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => b.weight - a.weight
          );

          return Math.abs(greatestObject.weight - secondGreatestObject.weight) > 1;
        }
      }
    );

    return { smallestOrGreatest, randomObjects: randomObjectsWithInfo.map(it => it.name) };
  },
  Component: props => {
    const {
      question: { smallestOrGreatest, randomObjects },
      translate
    } = props;

    const correctAnswer = (smallestOrGreatest === 'smallest' ? smallest : greatest)(
      randomObjects,
      name => objectsWithWeights[name].weight
    );

    return (
      <QF11SelectImagesUpTo4<ObjectsWithWeightsName>
        questionHeight={1000}
        title={
          smallestOrGreatest === 'greatest'
            ? translate.ks1Instructions.selectTheContainerWithTheGreatestCapacity()
            : translate.ks1Instructions.selectTheContainerWithTheSmallestCapacity()
        }
        pdfTitle={
          smallestOrGreatest === 'greatest'
            ? translate.ks1PDFInstructions.tickTheContainerWithTheGreatestCapacity()
            : translate.ks1PDFInstructions.tickTheContainerWithTheSmallestCapacity()
        }
        testCorrect={[correctAnswer]}
        numItems={4}
        renderItems={({ dimens }) =>
          randomObjects.map(name => {
            const { icon, scale } = objectsWithWeights[name];
            return {
              value: name,
              component: (
                <View
                  style={{
                    ...dimens,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingBottom: 20
                  }}
                >
                  <AssetSvg
                    name={icon}
                    width={dimens.width * 0.8 * scale}
                    height={dimens.height * 0.8}
                  />
                </View>
              )
            };
          })
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjV',
  description: 'bjV',
  keywords: ['Less than', 'Greater than', 'Equal to', 'Volume', 'Compare'],
  schema: z.object({
    container: z.enum(['jug', 'glass', 'bottle']),
    volumes: z.array(z.number().int().min(1).max(5)).length(2)
  }),
  simpleGenerator: () => {
    const container = getRandomFromArray(['jug', 'glass', 'bottle'] as const);

    const volumes = randomUniqueIntegersInclusive(1, 5, 2);

    return { container, volumes };
  },
  Component: props => {
    const {
      translate,
      question: { container, volumes }
    } = props;

    const translatedObject = (() => {
      switch (container) {
        case 'jug':
          return translate.objects.Jug();
        case 'bottle':
          return translate.objects.bottle();
        case 'glass':
          return translate.objects.cup();
      }
    })();
    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheVolumeOfWaterInEachX(
          translatedObject
        )}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheVolumes()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(volumes[0], volumes[1]),
            lhsComponent: (
              <MeasureView>
                {dimens => (
                  <JugWithLiquid
                    dimens={dimens}
                    jugCapacity={5}
                    tickValue={1}
                    liquidAmount={volumes[0]}
                    containerType={container}
                    hideAllLabels
                  />
                )}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => (
                  <JugWithLiquid
                    dimens={dimens}
                    jugCapacity={5}
                    tickValue={1}
                    liquidAmount={volumes[1]}
                    containerType={container}
                    hideAllLabels
                  />
                )}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareVolumeAndCapacity',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
