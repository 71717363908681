import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import QF52InteractivePieChartWithContent from '../../../../components/question/questionFormats/QF52InteractivePieChartWithContent';
import Table from '../../../../components/molecules/Table';
import { drawPieChartColors, tableColors } from '../../../../theme/colors';
import Text from 'common/src/components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import { generateRandomPartitions } from '../../../../utils/graphs';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import { formatFractionToMarkup } from '../../../../utils/fractions';
import { DEGREES, MULT } from '../../../../constants';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { AngleFromMovableLinesWithState } from '../../../../components/question/representations/AngleFromMovableLines';
import { AssetSvg } from '../../../../assets/svg';
import { Circle, Svg } from 'react-native-svg';
import { isInRange } from '../../../../utils/matchers';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aXC',
  description: 'aXC',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Fraction',
    'Amount',
    'Total',
    'Table',
    'Percentage',
    'Data'
  ],
  questionHeight: 900,
  schema: z.object({
    denominator: numberEnum([8, 10, 20]),
    numbers: z.number().int().min(1).max(18).array().length(3),
    fruits: z
      .array(z.enum(['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear']))
      .length(3)
      .refine(arrayHasNoDuplicates, 'fruit must be different'),
    multiplier: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([8, 10, 20] as const);
    const fruits = getRandomSubArrayFromArray(
      ['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear'] as const,
      3
    );
    const multiplier = randomIntegerInclusive(2, 6);
    const numbers = generateRandomPartitions({
      total: denominator,
      parts: 3,
      min: 1
    });

    return { denominator, fruits, multiplier, numbers };
  },
  Component: ({
    question: { denominator, fruits, multiplier, numbers },
    translate,
    displayMode
  }) => {
    const translatedFruits = fruits.map(val => translate.fruits[val]());

    let data: (string | JSX.Element)[][] = [];

    data = numbers.map((val, i) => {
      return [
        translatedFruits[i],
        `<ans/>`,
        formatFractionToMarkup(val, denominator, 'fraction'),
        formatFractionToMarkup(val, denominator, 'percentage')
      ];
    });

    countRange(1).map(i => {
      data.push([
        <Text
          variant="WRN700"
          style={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }}
          key={i}
        >
          {translate.keywords.Total()}
        </Text>,
        <Text
          variant="WRN700"
          style={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }}
          key={i}
        >
          {(denominator * multiplier).toLocaleString()}
        </Text>,
        <TextStructure
          key={i}
          sentence={`<frac n='${denominator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }}
        />,
        <Text
          variant="WRN700"
          style={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }}
          key={i}
        >
          {(100).toLocaleString()}
        </Text>
      ]);
    });

    const answers = numbers.map(val => (val * multiplier).toString());

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        cellHeaders={[
          {
            label: translate.tableHeaders.Fruit(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.NumberOfChildren(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.fractionOfTotal(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.percentageOfTotal(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          }
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
        rowStyle={displayMode === 'digital' && { maxHeight: 110 }}
        tableData={data}
        questionHeight={900}
        testCorrect={answers}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aXD',
  description: 'aXD',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Equal parts',
    'Fraction',
    'Numerator',
    'Denominator',
    'Total',
    'Draw',
    'Data'
  ],
  questionHeight: 1200,
  schema: z.object({
    parts: z.number().int().min(6).max(10),
    fruits: z
      .array(z.enum(['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear']))
      .length(4)
      .refine(arrayHasNoDuplicates, 'fruit must be different'),
    multiplier: z.number().int().min(2).max(6),
    quantity1: z.number().int().min(1).max(5),
    quantity2: z.number().int().min(1).max(5),
    quantity3: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(6, 10);
    const fruits = getRandomSubArrayFromArray(
      ['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear'] as const,
      4
    );
    const multiplier = randomIntegerInclusive(2, 6);
    const quantity1 = randomIntegerInclusive(1, parts / 2);
    const quantity2 = randomIntegerInclusive(1, (parts - quantity1) / 2);
    const quantity3 = randomIntegerInclusive(1, (parts - quantity1 - quantity2) / 2);

    return { parts, fruits, multiplier, quantity1, quantity2, quantity3 };
  },
  Component: props => {
    const {
      question: { parts, fruits, multiplier, quantity1, quantity2, quantity3 },
      translate,
      displayMode
    } = props;

    const ratios = [quantity1, quantity2, quantity3, parts - quantity1 - quantity2 - quantity3];
    const answer = ratios.map((val, idx) => filledArray(idx + 1, val)).flat(1);
    const values = ratios.map(i => i * multiplier);
    const total = parts * multiplier;

    const colorMapping = {
      Apple: fruits.includes('Cherry') ? drawPieChartColors.Green : drawPieChartColors.Red,
      Banana: drawPieChartColors.Yellow,
      Orange: drawPieChartColors.Orange,
      Cherry: drawPieChartColors.Red,
      Grape: drawPieChartColors.Purple,
      Pear: drawPieChartColors.Blue
    };

    const options = [
      { color: colorMapping[fruits[0]], label: translate.acronyms[fruits[0]]() },
      { color: colorMapping[fruits[1]], label: translate.acronyms[fruits[1]]() },
      { color: colorMapping[fruits[2]], label: translate.acronyms[fruits[2]]() },
      { color: colorMapping[fruits[3]], label: translate.acronyms[fruits[3]]() }
    ];

    const fruitRows = fruits.map((fruit, i) => [
      `${translate.fruits[fruit]()} (${translate.fruits[fruit]().charAt(0)})`,
      values[i].toLocaleString(),
      `<frac n='${ratios[i]}' d='${parts}' />`
    ]);

    const tableRows = [
      [
        translate.tableHeaders.Fruit(),
        translate.tableHeaders.NumberOfChildren(),
        translate.keywords.Fraction()
      ],
      ...fruitRows,
      [translate.keywords.Total(), total.toLocaleString(), `<frac n='${parts}' d='${parts}' />`]
    ];

    return (
      <QF52InteractivePieChartWithContent
        title={translate.instructions.shadePartsToCompletePieByTapping()}
        pdfTitle={translate.instructions.shadePartsToCompletePieMatchTable()}
        questionHeight={1200}
        Content={({ dimens }) => (
          <Table
            items={tableRows.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <View
                  key={`${rowIndex}-${cellIndex}`}
                  style={{
                    width: dimens.width * 0.3,
                    height: dimens.height / 6,
                    backgroundColor:
                      displayMode !== 'digital'
                        ? 'white'
                        : rowIndex === 0
                        ? tableColors.headerGreen
                        : cellIndex === 0 && options[rowIndex - 1]
                        ? options[rowIndex - 1].color
                        : 'white',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TextStructure
                    textVariant={rowIndex === tableRows.length - 1 ? 'WRN700' : 'WRN400'}
                    textStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      lineHeight: 30,
                      textAlign: 'center'
                    }}
                    fractionTextStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      fontWeight: rowIndex === tableRows.length - 1 ? '700' : '400'
                    }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                    sentence={cell}
                  />
                </View>
              ))
            )}
            style={dimens}
          />
        )}
        numberOfSlices={parts}
        testCorrect={answer}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.segmentsCanBeInAnyOrder() }}
        options={options}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXE',
  description: 'aXE',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Equal parts',
    'Fraction',
    'Numerator',
    'Denominator',
    'Total',
    'Draw',
    'Equivalent fractions',
    'Equivalent percentages',
    'Percentage',
    'Data'
  ],
  questionHeight: 1200,
  schema: z.object({
    fruits: z
      .array(z.enum(['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear']))
      .length(4)
      .refine(arrayHasNoDuplicates, 'fruit must be different'),
    multiplier: z.number().int().min(2).max(6),
    quantity1: z.number().int().min(1).max(5),
    quantity2: z.number().int().min(1).max(5),
    quantity3: z.number().int().min(1).max(5),
    fracOrPercent: z.enum(['fraction', 'percentage'])
  }),
  simpleGenerator: () => {
    const parts = 10;
    const fruits = getRandomSubArrayFromArray(
      ['Apple', 'Banana', 'Orange', 'Cherry', 'Grape', 'Pear'] as const,
      4
    );
    const multiplier = randomIntegerInclusive(2, 6);
    const quantity1 = randomIntegerInclusive(1, parts / 2);
    const quantity2 = randomIntegerInclusive(1, (parts - quantity1) / 2);
    const quantity3 = randomIntegerInclusive(1, (parts - quantity1 - quantity2) / 2);
    const fracOrPercent = getRandomFromArray(['fraction', 'percentage'] as const);

    return { parts, fruits, multiplier, quantity1, quantity2, quantity3, fracOrPercent };
  },
  Component: props => {
    const {
      question: { fruits, multiplier, quantity1, quantity2, quantity3, fracOrPercent },
      translate,
      displayMode
    } = props;

    const parts = 10;
    const ratios = [quantity1, quantity2, quantity3, parts - quantity1 - quantity2 - quantity3];
    const answer = ratios.map((val, idx) => filledArray(idx + 1, val)).flat(1);
    const values = ratios.map(i => i * multiplier);
    const total = parts * multiplier;

    const colorMapping = {
      Apple: fruits.includes('Cherry') ? drawPieChartColors.Green : drawPieChartColors.Red,
      Banana: drawPieChartColors.Yellow,
      Orange: drawPieChartColors.Orange,
      Cherry: drawPieChartColors.Red,
      Grape: drawPieChartColors.Purple,
      Pear: drawPieChartColors.Blue
    };

    const options = [
      { color: colorMapping[fruits[0]], label: translate.acronyms[fruits[0]]() },
      { color: colorMapping[fruits[1]], label: translate.acronyms[fruits[1]]() },
      { color: colorMapping[fruits[2]], label: translate.acronyms[fruits[2]]() },
      { color: colorMapping[fruits[3]], label: translate.acronyms[fruits[3]]() }
    ];

    const fruitRows = fruits.map((fruit, i) => [
      `${translate.fruits[fruit]()} (${translate.fruits[fruit]().charAt(0)})`,
      values[i].toLocaleString(),
      fracOrPercent === 'fraction'
        ? `<frac n='${ratios[i]}' d='${parts}' />`
        : `${number(math.evaluate(`${ratios[i]} / ${parts} * 100`)).toLocaleString()}%`
    ]);

    const tableRows = [
      [
        translate.tableHeaders.Flavours(),
        translate.tableHeaders.NumberOfPeople(),
        fracOrPercent === 'fraction'
          ? translate.keywords.Fraction()
          : translate.keywords.Percentage()
      ],
      ...fruitRows,
      [
        translate.keywords.Total(),
        total.toLocaleString(),
        fracOrPercent === 'fraction'
          ? `<frac n='${parts}' d='${parts}' />`
          : `${(100).toLocaleString()}%`
      ]
    ];

    return (
      <QF52InteractivePieChartWithContent
        title={translate.instructions.shadePartsToCompletePieByTapping()}
        pdfTitle={translate.instructions.shadePartsToCompletePieMatchTable()}
        questionHeight={1200}
        Content={({ dimens }) => (
          <Table
            items={tableRows.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <View
                  key={`${rowIndex}-${cellIndex}`}
                  style={{
                    width: dimens.width * 0.3,
                    height: dimens.height / 6,
                    backgroundColor:
                      rowIndex === 0
                        ? tableColors.headerGreen
                        : cellIndex === 0 && options[rowIndex - 1]
                        ? options[rowIndex - 1].color
                        : 'white',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TextStructure
                    textVariant={rowIndex === tableRows.length - 1 ? 'WRN700' : 'WRN400'}
                    textStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      lineHeight: 30,
                      textAlign: 'center'
                    }}
                    fractionTextStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      fontWeight: rowIndex === tableRows.length - 1 ? '700' : '400'
                    }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                    sentence={cell}
                  />
                </View>
              ))
            )}
            style={{ ...dimens, alignItems: 'center' }}
          />
        )}
        numberOfSlices={parts}
        testCorrect={answer}
        options={options}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.segmentsCanBeInAnyOrder() }}
        outerLabels={countRange(11).map(i => `${(i * 10).toLocaleString()}%`)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aXF',
  description: 'aXF',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Equal parts',
    'Draw',
    'Percentages',
    'Bar model',
    'Data'
  ],
  questionHeight: 1200,
  schema: z.object({
    chosenActivities: z
      .array(
        z.enum([
          'Swimming',
          'Cycling',
          'Rowing',
          'Archery',
          'Basketball',
          'Football',
          'Tennis',
          'Netball',
          'Hockey'
        ])
      )
      .length(4),
    activitiesPercentages: z.array(z.number().int().min(5).max(100)).length(4)
  }),
  simpleGenerator: () => {
    const chosenActivities = getRandomSubArrayFromArray(
      [
        'Swimming',
        'Cycling',
        'Rowing',
        'Archery',
        'Basketball',
        'Football',
        'Tennis',
        'Netball',
        'Hockey'
      ] as const,
      4
    );

    const activitiesPercentages = rejectionSample(
      () => {
        const activityA = randomIntegerInclusiveStep(10, 100, 10);
        const activityB = randomIntegerInclusiveStep(10, 100, 10);
        const activityC = randomIntegerInclusiveStep(10, 100, 10);
        const activityD = randomIntegerInclusiveStep(10, 100, 10);

        return [activityA, activityB, activityC, activityD];
      },
      activitiesPercentages => activitiesPercentages.reduce((sum, acc) => sum + acc, 0) === 100
    );

    return { chosenActivities, activitiesPercentages };
  },
  Component: props => {
    const {
      question: { chosenActivities, activitiesPercentages },
      translate,
      displayMode
    } = props;

    const activityColors = getRandomSubArrayFromArray(Object.values(drawPieChartColors), 4, {
      random: seededRandom(props.question)
    });

    // Use first letter of each activity as label as they are unique
    const options = [
      { color: activityColors[0], label: translate.activities[chosenActivities[3]]()[0] },
      { color: activityColors[1], label: translate.activities[chosenActivities[2]]()[0] },
      { color: activityColors[2], label: translate.activities[chosenActivities[1]]()[0] },
      { color: activityColors[3], label: translate.activities[chosenActivities[0]]()[0] }
    ];

    const barModelLabels: string[][] = [
      [
        translate.activities[chosenActivities[3]](),
        translate.activities[chosenActivities[2]](),
        translate.activities[chosenActivities[1]](),
        translate.activities[chosenActivities[0]]()
      ]
    ];

    const testCorrect = activitiesPercentages
      .map((val, idx) => filledArray(idx + 1, val / 10))
      .flat();

    return (
      <QF52InteractivePieChartWithContent
        title={translate.instructions.shadePartsToCompletePieToMatchBarModelByTapping()}
        pdfTitle={translate.instructions.shadePartsToCompletePieMatchTable()}
        questionHeight={1200}
        Content={({ dimens }) => (
          <View
            style={{
              transform: 'rotate(90deg)',
              alignItems: 'center',
              justifyContent: 'center',
              width: dimens.width
            }}
          >
            <BarModel
              numbers={[
                [
                  activitiesPercentages[3],
                  activitiesPercentages[2],
                  activitiesPercentages[1],
                  activitiesPercentages[0]
                ]
              ]}
              strings={barModelLabels}
              cellColors={[activityColors]}
              total={100}
              dimens={{
                width: displayMode === 'digital' ? dimens.height * 1.1 : dimens.height * 1.1,
                height: dimens.height
              }}
              rowHeight={displayMode === 'digital' ? 150 : 300}
              oneFontSize
              vertical
            />
            <View
              style={{
                left: displayMode === 'digital' ? 0 : 50,
                top: displayMode === 'digital' ? -15 : 0
              }}
            >
              <NumberLine
                dimens={{
                  width:
                    displayMode === 'digital'
                      ? dimens.height * 1.1 + 40
                      : dimens.height * 1.1 + 140,
                  height: dimens.width * 0.2
                }}
                tickValues={countRange(11).map(i => `${((10 - i) * 10).toLocaleString()}%`)}
                textVerticalPad={-10}
                customFontSize={displayMode === 'digital' ? 24 : 32}
                vertical
              />
            </View>
          </View>
        )}
        numberOfSlices={10}
        testCorrect={testCorrect}
        options={options}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.segmentsCanBeInAnyOrder() }}
        outerLabels={countRange(11).map(i => `${(i * 10).toLocaleString()}%`)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXG',
  description: 'aXG',
  keywords: ['Pie chart', 'Draw', 'Total', 'Frequency', 'Degrees', 'Angles', 'Data'],
  schema: z.object({
    numbers: z.number().int().min(1).max(358).array().length(3),
    activities: z
      .array(
        z.enum([
          'Running',
          'Swimming',
          'Cycling',
          'Rowing',
          'Archery',
          'Basketball',
          'Football',
          'Tennis',
          'Netball',
          'Hockey'
        ])
      )
      .length(3)
      .refine(arrayHasNoDuplicates, 'fruit must be different'),
    total: numberEnum([10, 18, 36, 45, 60, 72, 90, 180, 360]),
    isFrequency: z.boolean().array().length(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const total = getRandomFromArray([10, 18, 36, 45, 60, 72, 90, 180, 360] as const);
    const activities = getRandomSubArrayFromArray(
      [
        'Running',
        'Swimming',
        'Cycling',
        'Rowing',
        'Archery',
        'Basketball',
        'Football',
        'Tennis',
        'Netball',
        'Hockey'
      ] as const,
      3
    );

    const numbers = generateRandomPartitions({
      total: total,
      parts: 3,
      min: 1
    });

    const isFrequency = countRange(3).map(getRandomBoolean);

    return { activities, total, numbers, isFrequency };
  },
  Component: ({
    question: { activities, total, numbers, isFrequency },
    translate,
    displayMode
  }) => {
    const translatedActivities = activities.map(val => translate.activities[val]());
    const multiplier = 360 / total;

    let data: (string | JSX.Element)[][] = [];

    data = numbers.map((val, i) => {
      return [
        translatedActivities[i],
        isFrequency[i] ? `<ans/>` : val.toLocaleString(),
        isFrequency[i]
          ? `${val.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = ${(
              val * multiplier
            ).toLocaleString()}${DEGREES}`
          : `${val.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/> ${DEGREES}`
      ];
    });

    countRange(1).map(i => {
      data.push([
        <Text style={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }} key={i}>
          {translate.keywords.Total()}
        </Text>,
        <Text style={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }} key={i}>
          {total.toLocaleString()}
        </Text>,
        <TextStructure
          key={i}
          sentence={`${total.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = ${(360).toLocaleString()}${DEGREES}`}
          textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50, fontWeight: '700' }}
        />
      ]);
    });

    const answers = numbers.map((val, i) =>
      isFrequency[i] ? val.toLocaleString() : (val * multiplier).toString()
    );

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTableToShowDegrees()}
        cellHeaders={[
          {
            label: translate.tableHeaders.sport(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.totalFrequency(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.angleNumberDegrees(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          }
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
        rowStyle={displayMode === 'digital' && { maxHeight: 110 }}
        tableData={data}
        questionHeight={900}
        testCorrect={answers}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aXH',
  description: 'aXH',
  keywords: ['Pie chart', 'Draw', 'Sector', 'Degrees', 'Angles', 'Data'],
  schema: z.object({
    angle: z.number().int().min(10).max(360),
    halfOrFullProtractor: z.enum(['180', '360'])
  }),
  simpleGenerator: () => {
    const halfOrFullProtractor = getRandomFromArray(['180', '360'] as const);

    const angle = randomIntegerInclusiveStep(
      halfOrFullProtractor === '180' ? 10 : 180,
      halfOrFullProtractor === '180' ? 175 : 355,
      5
    );

    return { angle, halfOrFullProtractor };
  },
  Component: ({ question: { angle, halfOrFullProtractor }, translate, displayMode }) => {
    return (
      <QF3Content
        title={translate.instructions.dragLineToMakeAngleThatisXDegreesInPieChart(angle)}
        pdfTitle={translate.instructions.drawAngleThatIsXDegreesInPieChart(angle)}
        Content={({ dimens }) => (
          <View
            style={[
              { justifyContent: 'center' },
              displayMode !== 'digital' && { alignItems: 'center' }
            ]}
          >
            <View
              style={
                displayMode === 'digital' ? { position: 'absolute' } : { width: dimens.height }
              }
            >
              <Svg height={dimens.height * 1.1} width={dimens.width}>
                <Circle
                  cx={dimens.height * 0.6 - 7}
                  cy={dimens.height * 0.55}
                  r={dimens.height * 0.5}
                  fill="none"
                  strokeWidth={2}
                  stroke="black"
                />
              </Svg>
            </View>

            {displayMode === 'digital' && (
              <>
                <View
                  style={{
                    transform: 'rotate(90deg)',
                    position: 'absolute',
                    left: halfOrFullProtractor === '180' ? 154 : 69
                  }}
                >
                  {halfOrFullProtractor === '180' ? (
                    <AssetSvg
                      name={'Protractor180'}
                      height={dimens.height * 0.49}
                      width={dimens.height * 0.98}
                      style={{ maxWidth: dimens.height * 0.98 }}
                    />
                  ) : (
                    <AssetSvg
                      name={'Protractor360'}
                      height={dimens.height * 0.9}
                      width={dimens.height * 0.9}
                      style={{ maxHeight: dimens.height * 0.9 }}
                    />
                  )}
                </View>
                <AngleFromMovableLinesWithState
                  id="angle"
                  testCorrect={userAnswer => {
                    const normalizedUserAnswer =
                      halfOrFullProtractor === '180'
                        ? Math.round(userAnswer[0])
                        : 360 + Math.round(userAnswer[0]);
                    return isInRange(angle - 1, angle + 1)(normalizedUserAnswer);
                  }}
                  allowReflexAngles
                  smallArmFixed
                  variant="specialProtractor"
                />
              </>
            )}
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyAngleWithinPieChartXDegrees(angle)
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawPieCharts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
