import { StyleSheet, View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { colors, tenFrameCounterColors } from '../../../../theme/colors';
import { Line, Rect, Svg } from 'react-native-svg';
import Text from '../../../typography/Text';
import { z } from 'zod';
import { getRandomSubArrayFromArray } from '../../../../utils/random';

/**
 * Sizes:
 * - xsmall - e.g. for displaying 3 ten frames stacked on top of each other
 * - small - e.g. for display ten frames in selectables
 * - medium - default size
 * - large - e.g. for drag and drop questions
 * - xlarge - e.g. for draggables in action panel
 */
export type TenFrameSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export const TEN_FRAME_MEASUREMENTS: Record<
  TenFrameSize,
  { cellWidth: number; cellBorder: number; counterWidth: number; counterBorder: number }
> = {
  xsmall: {
    cellWidth: 55,
    cellBorder: 2,
    counterWidth: 37,
    counterBorder: 1.5
  },
  small: {
    cellWidth: 72,
    cellBorder: 2.5,
    counterWidth: 49,
    counterBorder: 2
  },
  medium: {
    cellWidth: 82,
    cellBorder: 3,
    counterWidth: 56,
    counterBorder: 2.33
  },
  large: {
    cellWidth: 94,
    cellBorder: 3,
    counterWidth: 64,
    counterBorder: 2.33
  },
  xlarge: {
    cellWidth: 116,
    cellBorder: 4,
    counterWidth: 96,
    counterBorder: 3
  }
};

export type CounterVariant = 'red' | 'yellow' | 'blue' | 'green' | 'grey';
export const counterVariants = ['red', 'yellow', 'blue', 'green', 'grey'] as const;
export const counterVariantSchema = z.enum(counterVariants);
export function getRandomUniqueCounters(numberOfCounters: number, random?: () => number) {
  return getRandomSubArrayFromArray(counterVariants, numberOfCounters, {
    random
  });
}

type CellContents = CounterVariant | JSX.Element | undefined;

type Props = {
  /**
   * The size to use. We only support a small number of sizes, for simplicity. See {@link TenFrameSize} and
   * {@link TEN_FRAME_MEASUREMENTS} for help with choosing a size.
   * Default: medium.
   */
  size?: TenFrameSize;
  /** Default: []. */
  items?: Array<CellContents> | ((index: number) => CellContents);
  /** Which cells (indexed by the `items` array) should be crossed out. Default: none. */
  crossedOutCells?: Array<boolean> | ((index: number) => boolean);
  /**
   * The order that the items are indexed in the `items` array. Default: 'rowFirst'
   *
   * ## Horizontal ten-frames
   *
   * rowFirst:
   *
   * ```
   *     0 1 2 3 4
   *     5 6 7 8 9
   * ```
   *
   * columnFirst:
   *
   * ```
   *     0 2 4 6 8
   *     1 3 5 7 9
   * ```
   *
   * ## Vertical ten-frames
   *
   * rowFirst:
   *
   * ```
   *     0 1
   *     2 3
   *     4 5
   *     6 7
   *     8 9
   * ```
   *
   * columnFirst:
   *
   * ```
   *     0 5
   *     1 6
   *     2 7
   *     3 8
   *     4 9
   * ```
   */
  itemOrdering?: 'rowFirst' | 'columnFirst';
  /**
   * horizontal - 2 rows of 5
   * vertical - 5 rows of 2
   *
   * Default: horizontal
   */
  orientation?: 'horizontal' | 'vertical';
  /** If true, shows the initial letter of each color, when using {@link CounterVariant}. Default: false. */
  colorBlindMode?: boolean;
};

/**
 * A ten frame layout. Always has 10 cells.
 *
 * Items are indexed from the top row to bottom row, left to right. Undefined or absent entries in the `items` prop
 * represent empty cells
 *
 * ## Basic usage - non-interactive ten frame
 *
 * ```tsx
 * <TenFrameLayout items={['red', undefined, 'red']} />
 * ```
 *
 * or with custom items
 *
 * ```tsx
 * <TenFrameLayout items={[<AssetSvg name='foo'/>, <AssetSvg name='bar'/>]} />
 * ```
 */
export default function TenFrameLayout({
  size = 'medium',
  items: itemsProp = [],
  crossedOutCells: crossedOutCellsProp,
  itemOrdering = 'rowFirst',
  orientation = 'horizontal',
  colorBlindMode = false
}: Props) {
  const crossedOutCells = Array.isArray(crossedOutCellsProp)
    ? (index: number) => Boolean(crossedOutCellsProp[index])
    : crossedOutCellsProp;
  const items = renderTenFrameCells({ size, items: itemsProp, colorBlindMode, crossedOutCells });
  const measurements = TEN_FRAME_MEASUREMENTS[size];
  const width =
    orientation === 'horizontal'
      ? measurements.cellWidth * 5 + measurements.cellBorder * 6
      : measurements.cellWidth * 2 + measurements.cellBorder * 3;
  const height =
    orientation === 'horizontal'
      ? measurements.cellWidth * 2 + measurements.cellBorder * 3
      : measurements.cellWidth * 5 + measurements.cellBorder * 6;

  return (
    <View style={{ width, height }}>
      {/* Use an SVG, because sometimes putting views with borders against each other leaves gaps, */
      /* and SVGs don't have that issue. */}
      <Svg width={width} height={height} style={StyleSheet.absoluteFill}>
        {countRange(orientation === 'horizontal' ? 2 : 5).flatMap(row =>
          countRange(orientation === 'horizontal' ? 5 : 2).map(column => (
            <Rect
              key={`${row}-${column}`}
              x={
                column * (measurements.cellWidth + measurements.cellBorder) +
                measurements.cellBorder / 2
              }
              y={
                row * (measurements.cellWidth + measurements.cellBorder) +
                measurements.cellBorder / 2
              }
              width={measurements.cellWidth + measurements.cellBorder}
              height={measurements.cellWidth + measurements.cellBorder}
              stroke={colors.prussianBlue}
              fill="white"
              strokeWidth={measurements.cellBorder}
            />
          ))
        )}
      </Svg>
      {/* Render each item */}
      {countRange(orientation === 'horizontal' ? 2 : 5).flatMap(row =>
        countRange(orientation === 'horizontal' ? 5 : 2).map(column => (
          <View
            key={`${row}-${column}`}
            style={{
              position: 'absolute',
              left:
                column * (measurements.cellWidth + measurements.cellBorder) +
                measurements.cellBorder,
              top:
                row * (measurements.cellWidth + measurements.cellBorder) + measurements.cellBorder,
              width: measurements.cellWidth,
              height: measurements.cellWidth
            }}
          >
            {
              items[
                itemOrdering === 'rowFirst'
                  ? // row first
                    orientation === 'horizontal'
                    ? // 0 1 2 3 4
                      // 5 6 7 8 9
                      row * 5 + column
                    : // 0 1
                      // 2 3
                      // 4 5
                      // 6 7
                      // 8 9
                      row * 2 + column
                  : // column first
                  orientation === 'horizontal'
                  ? // 0 2 4 6 8
                    // 1 3 5 7 9
                    column * 2 + row
                  : // 0 5
                    // 1 6
                    // 2 7
                    // 3 8
                    // 4 9
                    column * 5 + row
              ]
            }
          </View>
        ))
      )}
    </View>
  );
}

export function renderTenFrameCells({
  size = 'medium',
  items = [],
  colorBlindMode = false,
  crossedOutCells
}: {
  size?: TenFrameSize;
  items?: Array<CellContents> | ((index: number) => CellContents);
  colorBlindMode?: boolean;
  crossedOutCells?: (index: number) => boolean;
}) {
  const itemsArray = Array.isArray(items) ? items : countRange(10).map(i => items(i));
  return itemsArray.map((item, index) =>
    renderTenFrameCell({
      size,
      cellContents: item,
      colorBlindMode,
      crossedOut: crossedOutCells ? crossedOutCells(index) : false
    })
  );
}

/** Render a single ten frame cell. Never undefined. */
export function renderTenFrameCell(options: {
  size: TenFrameSize;
  cellContents: CounterVariant | JSX.Element;
  colorBlindMode?: boolean;
  crossedOut?: boolean;
}): JSX.Element;
/** Render a single ten frame cell. Is allowed it be undefined. */
export function renderTenFrameCell(options: {
  size: TenFrameSize;
  cellContents: CellContents;
  colorBlindMode?: boolean;
  crossedOut?: boolean;
}): JSX.Element | undefined;

export function renderTenFrameCell({
  size,
  cellContents,
  colorBlindMode = false,
  crossedOut = false
}: {
  size: TenFrameSize;
  cellContents: CellContents;
  colorBlindMode?: boolean;
  crossedOut?: boolean;
}): JSX.Element | undefined {
  if (cellContents === undefined) return undefined;

  const measurements = TEN_FRAME_MEASUREMENTS[size];
  return (
    <View
      style={{
        width: measurements.cellWidth,
        height: measurements.cellWidth,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {typeof cellContents === 'string'
        ? renderTenFrameCounter({ size, variant: cellContents, showShadow: true, colorBlindMode })
        : cellContents}
      {crossedOut && (
        <Svg
          width={measurements.cellWidth}
          height={measurements.cellWidth}
          style={StyleSheet.absoluteFill}
        >
          <Line
            x1={0}
            x2={measurements.cellWidth}
            y1={0}
            y2={measurements.cellWidth}
            stroke={colors.prussianBlue}
            strokeWidth={measurements.cellBorder}
          />
          <Line
            x1={0}
            x2={measurements.cellWidth}
            y1={measurements.cellWidth}
            y2={0}
            stroke={colors.prussianBlue}
            strokeWidth={measurements.cellBorder}
          />
        </Svg>
      )}
    </View>
  );
}

export function renderTenFrameCounter({
  size,
  variant,
  showShadow = true,
  colorBlindMode = false
}: {
  size: TenFrameSize;
  variant: CounterVariant;
  showShadow?: boolean;
  colorBlindMode?: boolean;
}) {
  const measurements = TEN_FRAME_MEASUREMENTS[size];
  const fill = tenFrameCounterColors[variant];
  const stroke = colors.prussianBlue;

  return (
    <View
      style={{
        width: measurements.counterWidth,
        height: measurements.counterWidth,
        borderRadius: 999,
        borderWidth: measurements.counterBorder,
        backgroundColor: fill,
        borderColor: stroke,
        justifyContent: 'center',
        alignItems: 'center',
        ...(showShadow && {
          shadowColor: 'black',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.15,
          shadowRadius: 16,
          elevation: 2
        })
      }}
    >
      {/* In colorBlindMode, add a letter to the middle of the counter */}
      {colorBlindMode && variant !== 'grey' && (
        <Text
          variant="WRN700"
          style={{
            fontSize: measurements.counterWidth / 2,
            color: variant === 'blue' ? 'white' : colors.prussianBlue
          }}
        >
          {variant.charAt(0)}
        </Text>
      )}
    </View>
  );
}
