import { useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import { CompleteTheSentenceWithState } from '../../molecules/CompleteTheSentence';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { DraggableProtractorWithState } from '../representations/DraggableProtractor';
import { StyleProp, TextStyle, View } from 'react-native';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { MeasureView } from '../../atoms/MeasureView';

type Props = {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  extraSymbol?: ExtraSymbols;
  protractorImage?: {
    /** Defaults to width/2 */
    anchorX?: number;
    /** Defaults to height/2 */
    anchorY?: number;
    /** Offset from anchorX. Default 0. */
    panHandleOffsetX?: number;
    /** Offset from anchorY. Default 0. */
    panHandleOffsetY?: number;
  };
  shapeImage: JSX.Element;
  sentence: string;
  sentenceStyle?: StyleProp<TextStyle>;
  /** Can provide an array of correct answers, or a function for finer control. */
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answersToDisplay?: string[];
    answerText?: string;
  };
  questionHeight?: number;
};

export default function QF50aDraggableProtractor({
  title,
  pdfTitle,
  extraSymbol,
  shapeImage,
  sentence,
  sentenceStyle,
  testCorrect: testCorrectProp,
  customMarkSchemeAnswer,
  questionHeight
}: Props) {
  const displayMode = useContext(DisplayMode);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            <View style={{ alignItems: 'center' }}>{shapeImage}</View>
            <CompleteTheSentenceWithState
              sentence={sentence}
              id={'sentence'}
              testCorrect={testCorrect}
              style={[sentenceStyle]}
              defaultState={
                displayMode === 'markscheme' ? customMarkSchemeAnswer?.answersToDisplay : undefined
              }
            />
            {customMarkSchemeAnswer?.answerText &&
              displayMode === 'markscheme' &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <>
          <MeasureView style={{ alignItems: 'flex-start' }}>
            {dimens => {
              return (
                <DraggableProtractorWithState
                  id={'protractor'}
                  shapeImage={shapeImage}
                  dimens={dimens}
                />
              );
            }}
          </MeasureView>
          <CompleteTheSentenceWithState
            sentence={sentence}
            id={'sentence'}
            testCorrect={testCorrect}
            style={[sentenceStyle]}
          />
        </>
      }
    />
  );
}
