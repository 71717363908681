import { StyleSheet, View } from 'react-native';
import Text from '../../typography/Text';
import { colors } from '../../../theme/colors';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { MULT } from '../../../constants';
import { countRange, filledArray, range } from '../../../utils/collections';
import { ScientificNotation } from '../../../utils/math';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { BarModelCurlyBrace } from './BarModelCurlyBrace';
import { VerticalCurlyBrace } from './VerticalCurlyBrace';
import { Dimens } from '../../../theme/scaling';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { noop } from '../../../utils/flowControl';
import { withStateHOC } from '../../../stateTree';
import { SetState } from '../../../utils/react';

type Props = {
  /**
   * Width and height for the container around the SVG.
   * SVGs will fill five sixths of the container.
   */
  dimensPerSvgContainer: number;
  topNumber: number;
  sideNumber: number;
  /**
   * Determine whether the top and side numbers are to be represented by base 10 SVGs or counter SVGs.
   * Optional prop, defaults to 'base10'.
   */
  headerVariant?: 'base10' | 'counter' | 'none';
  /**
   * Determine whether the product numbers are to be represented by base 10 SVGs, counter SVGs, or just numbers themselves.
   * Optional prop, defaults to 'base10'.
   */
  productVariant?: 'base10' | 'counter' | 'number';
  /**
   * Minimum width that the top number ones column must be.
   * Optional prop, defaults to undefined.
   */
  topNumberOnesMinWidth?: number;
};

/**
 * Area Model representation, showing a multiplication of numbers broken down into sets of tens and ones,
 * represented on a table.
 * Numbers can be represented with base 10 images or counters,
 * and products of the multiplications can be represented with base 10 images, counters or numbers.
 */
export default function AreaModel({
  dimensPerSvgContainer,
  topNumber,
  sideNumber,
  headerVariant = 'base10',
  productVariant = 'base10',
  topNumberOnesMinWidth
}: Props) {
  const styles = getStyles();

  // Get an array of digits to show for each number and its power.
  const getDigits = (number: number): { value: number; pow: number }[] => {
    const sci = ScientificNotation.fromNumber(number);
    const digits = range(0, sci.e).map(pow => ({ value: sci.unsignedDigitAt(pow), pow }));
    return digits.filter(val => val.value !== 0);
  };
  const topNumberArray = getDigits(topNumber).reverse();
  const sideNumberArray = getDigits(sideNumber).reverse();

  const dimensPerSvg = (dimensPerSvgContainer / 6) * 5;

  return (
    <View
      style={{
        alignSelf: 'center'
      }}
    >
      {/* Multiplication sign and top number row */}
      <View style={{ flexDirection: 'row' }}>
        <View
          style={[
            styles.cell,
            {
              width: dimensPerSvgContainer,
              height: dimensPerSvgContainer,
              alignItems: 'center',
              justifyContent: 'center',
              borderEndWidth: 0
            }
          ]}
        >
          <Text variant="WRN400">{MULT}</Text>
        </View>
        {topNumberArray.map(({ value, pow }, index) => (
          <View
            style={[
              styles.cell,
              {
                width: dimensPerSvgContainer * value,
                height: dimensPerSvgContainer,
                flexDirection: 'row',
                borderEndWidth: index === topNumberArray.length - 1 ? undefined : 0,
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minWidth: pow === 0 ? topNumberOnesMinWidth : undefined,
                backgroundColor: colors.pacificBlueTint15
              }
            ]}
            key={index}
          >
            {countRange(value, 1).map(num => {
              return headerVariant === 'none' ? undefined : (
                <AssetSvg
                  name={
                    headerVariant === 'base10'
                      ? (`Place_value/${Math.pow(10, pow)}cube` as SvgName)
                      : (`Place_value/${Math.pow(10, pow)}` as SvgName)
                  }
                  key={`topNumber-${pow}-${num}`}
                  height={dimensPerSvg}
                  width={dimensPerSvg}
                  style={
                    headerVariant === 'base10' && pow === 1 && { transform: [{ rotate: '90deg' }] }
                  }
                />
              );
            })}
          </View>
        ))}
      </View>
      {/* Side number and product rows */}
      {sideNumberArray.map(({ value, pow }) => (
        // Side number column
        <View style={{ flexDirection: 'row', height: dimensPerSvgContainer * value }} key={pow}>
          <View
            style={[
              styles.cell,
              {
                width: dimensPerSvgContainer,
                borderTopWidth: 0,
                borderEndWidth: 0,
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: colors.pacificBlueTint15
              }
            ]}
          >
            {countRange(value, 1).map(num => (
              <View key={num} style={{ height: dimensPerSvgContainer, justifyContent: 'center' }}>
                {headerVariant === 'none' ? undefined : (
                  <AssetSvg
                    name={
                      (headerVariant === 'base10'
                        ? `Place_value/${Math.pow(10, pow)}cube`
                        : `Place_value/${Math.pow(10, pow)}`) as SvgName
                    }
                    key={`sideNumber-tens-${num}`}
                    height={dimensPerSvg}
                    width={dimensPerSvg}
                  />
                )}
              </View>
            ))}
          </View>
          {/* Product cells */}
          {topNumberArray.map(({ value: topNumberValue, pow: topNumberPow }, index) => (
            <View
              style={[
                styles.cell,
                {
                  width: dimensPerSvgContainer * topNumberValue,
                  borderTopWidth: 0,
                  borderEndWidth: index === topNumberArray.length - 1 ? undefined : 0,
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  minWidth: topNumberPow === 0 ? topNumberOnesMinWidth : undefined
                }
              ]}
              key={topNumberPow}
            >
              {productVariant === 'number' ? (
                <Text variant="WRN700">
                  {(topNumberValue * value * Math.pow(10, pow + topNumberPow)).toLocaleString()}
                </Text>
              ) : (
                countRange(value, 1).map(num => (
                  <View
                    key={`hundreds-row-${num}`}
                    style={{
                      width: dimensPerSvgContainer * topNumberValue,
                      flexDirection: 'row',
                      flex: 1,
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    {countRange(topNumberValue, 1).map(num => (
                      <AssetSvg
                        name={
                          (productVariant === 'base10'
                            ? `Place_value/${Math.pow(10, pow + topNumberPow)}cube`
                            : `Place_value/${Math.pow(10, pow + topNumberPow)}`) as SvgName
                        }
                        key={`hundreds-${num}`}
                        height={dimensPerSvg}
                        width={dimensPerSvg}
                      />
                    ))}
                  </View>
                ))
              )}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

type AreaModelWithLabelsProps = {
  dimens: Dimens;
  /**
   * Width and height for the container around the SVG.
   * SVGs will fill five sixths of the container.
   */
  svgContainerDimens?: number;
  topNumber: number;
  sideNumber: number;
  /**
   * Determine whether the top and side numbers are to be represented by base 10 SVGs or counter SVGs.
   * Optional prop, defaults to 'base10'.
   */
  headerVariant?: 'base10' | 'counter' | 'none';
  /**
   * Determine whether the product numbers are to be represented by base 10 SVGs, counter SVGs, or just numbers themselves.
   * Optional prop, defaults to 'base10'.
   */
  productVariant?: 'base10' | 'counter' | 'number';
  /**
   * Minimum width that the top number ones column must be.
   * Optional prop, defaults to undefined.
   */
  topNumberOnesMinWidth?: number;
  /**
   * Index positions, from left-to-right, where answer boxes should replace labels for the top number.
   */
  topNumberAnswerPositions?: number[];
  /**
   * Index positions, from top-to-bottom, where answer boxes should replace labels for the side number.
   */
  sideNumberAnswerPositions?: number[];
  userAnswer?: string[];
  setUserAnswer?: SetState<string[]>;
};

/**
 * Area Model representation, showing a multiplication of numbers broken down into sets of tens and ones,
 * represented on a table.
 * Numbers can be represented with base 10 images or counters,
 * and products of the multiplications can be represented with base 10 images, counters or numbers.
 * Brackets with either labels of the values or answer boxes can be displayed.
 */
export function AreaModelWithLabels({
  dimens,
  svgContainerDimens,
  topNumber,
  sideNumber,
  headerVariant = 'base10',
  productVariant = 'base10',
  topNumberOnesMinWidth,
  topNumberAnswerPositions,
  sideNumberAnswerPositions,
  userAnswer,
  setUserAnswer = noop
}: AreaModelWithLabelsProps) {
  const displayMode = useContext(DisplayMode);

  // Get an array of digits to show for each number and its power.
  const getDigits = (number: number): { value: number; pow: number }[] => {
    const sci = ScientificNotation.fromNumber(number);
    const digits = range(0, sci.e).map(pow => ({ value: sci.unsignedDigitAt(pow), pow }));
    return digits.filter(val => val.value !== 0);
  };
  const topNumberArray = getDigits(topNumber).reverse();
  const sideNumberArray = getDigits(sideNumber).reverse();

  const totalSvgsTopNumber = topNumberArray.reduce((acc, obj) => acc + obj.value, 0);
  const totalSvgsSideNumber = sideNumberArray.reduce((acc, obj) => acc + obj.value, 0);

  const dimensPerSvgContainer =
    svgContainerDimens ?? displayMode === 'digital'
      ? 60 + (10 - Math.max(totalSvgsTopNumber, totalSvgsSideNumber)) * 4
      : 130 + (10 - Math.max(totalSvgsTopNumber, totalSvgsSideNumber)) * 4;

  let ansIndex = -1;

  const getLabel = (arrayIndex: number, value: number, position: 'side' | 'top') => {
    const numberArray = position === 'side' ? sideNumberAnswerPositions : topNumberAnswerPositions;

    if (numberArray?.includes(arrayIndex) && userAnswer) {
      ansIndex += 1;

      const i = ansIndex;

      return (
        <NoKeyboardTextInput
          value={userAnswer[i]}
          onChangeText={text => {
            const newState = [...userAnswer];
            newState[i] = text;
            setUserAnswer(newState);
          }}
        />
      );
    } else {
      return <Text variant="WRN700">{value.toLocaleString()}</Text>;
    }
  };

  return (
    <View style={[dimens, { justifyContent: 'center' }]}>
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row'
        }}
      >
        <View style={{ justifyContent: 'flex-end' }}>
          {/* Side number brackets */}
          {sideNumberArray.map(({ value, pow }, index) => (
            <View
              key={pow}
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: dimensPerSvgContainer * value,
                alignItems: 'center',
                alignSelf: 'flex-end'
              }}
            >
              {getLabel(index, value * Math.pow(10, pow), 'side')}
              <View
                style={{
                  height: dimensPerSvgContainer * value,
                  width: 60,
                  paddingLeft: 16
                }}
              >
                <VerticalCurlyBrace />
              </View>
            </View>
          ))}
        </View>
        {/* Top number brackets and area model */}
        <View
          style={{
            alignSelf: 'flex-end'
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            {topNumberArray.map(({ value, pow }, index) => (
              <View
                style={{
                  width: dimensPerSvgContainer * value,
                  alignSelf: 'flex-end',
                  minWidth: pow === 0 ? topNumberOnesMinWidth : undefined
                }}
                key={pow}
              >
                <View
                  style={{
                    alignSelf: 'center',
                    justifyContent: 'center',
                    height: topNumberAnswerPositions?.includes(index)
                      ? displayMode === 'digital'
                        ? 164
                        : 212
                      : 112
                  }}
                >
                  {getLabel(index, value * Math.pow(10, pow), 'top')}
                </View>
                <BarModelCurlyBrace braceText={''} />
              </View>
            ))}
          </View>
          <AreaModel
            dimensPerSvgContainer={dimensPerSvgContainer}
            topNumber={topNumber}
            sideNumber={sideNumber}
            topNumberOnesMinWidth={topNumberOnesMinWidth}
            headerVariant={headerVariant}
            productVariant={productVariant}
          />
        </View>
      </View>
    </View>
  );
}

const getStyles = () =>
  StyleSheet.create({
    cell: {
      borderColor: colors.prussianBlue,
      borderWidth: 2
    }
  });

export const AreaModelWithLabelsWithState = withStateHOC(AreaModelWithLabels, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(
      '',
      (props.sideNumberAnswerPositions?.length ?? 0) + (props.topNumberAnswerPositions?.length ?? 0)
    ),
    testComplete: state => (state ? state.every(it => it !== '') : false)
  })
});
