import { isEqual } from '../../../utils/matchers';
import { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { Theme } from '../../../theme';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import NumberLineTicksTopBottom from '../representations/Number Line/NumberLineTicksTopBottom';
import EasyDragAndDropWithSingleZonesWithGrid from '../../draganddrop/EasyDragAndDropWithSingleZonesWithGrid';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { DraggableVariant } from '../../draganddrop/utils';
import { AssetSvg } from '../../../assets/svg';
import { countRange } from '../../../utils/collections';
import { parseMarkup } from '../../../markup';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  bottomTickValues: string[];
  topTickValues: string[];
  /**
   * If sending in as an array the answers should be [..topRowLeftToRight, ...bottomRowLeftToRight]
   */
  testCorrect: T[] | ((ans: (T | undefined)[]) => boolean);
  initialState?: T[];
  itemsTextVariant?: keyof Theme['fonts'];
  contentTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;
  items: ((T & string) | ItemInfo<T>)[];
  pdfItemVariant?: DraggableVariant;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[]; answerText?: string };
  customFontSize?: number;
};

/**
 * QF17b is a single number line with draggable items for the top and bottom
 */
export default function QF17bCompleteNumberLineDraggable<T extends string | number>({
  title,
  pdfTitle,
  topTickValues,
  bottomTickValues,
  testCorrect: testCorrectProp,
  initialState: initialStateProp,
  itemsTextVariant = 'WRN700',
  pdfItemVariant = 'pdfSquare',
  items: itemsProp,
  contentTextVariant,
  itemsLetterEmWidth,
  itemsMaxLines,
  questionHeight,
  customMarkSchemeAnswer,
  customFontSize,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const length =
    topTickValues.reduce((sum: number, tick) => sum + parseMarkup(String(tick)).numberOfAns, 0) +
    bottomTickValues.reduce((sum: number, tick) => sum + parseMarkup(String(tick)).numberOfAns, 0);
  const initialState = countRange(length).map(i => (initialStateProp ?? [])[i] as T | undefined);

  const testCorrect =
    typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp);

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item, value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const draggableInputBox = (index: number, boxWidth: number, boxHeight: number) => (
    // Outer view is required to limit the space that the drag and drop zone will take up
    <View style={{ width: boxWidth }}>
      {displayMode === 'markscheme' && (
        <AssetSvg
          name="True"
          width={50}
          style={{
            zIndex: 999,
            position: 'absolute',
            top: -10,
            right: -10
          }}
        />
      )}
      <EasyDragAndDropWithSingleZones.ZoneSingle
        style={[
          {
            width: boxWidth,
            minHeight: boxHeight
          },
          displayMode === 'digital' && styles.draggableDimens
        ]}
        id={index}
        key={index}
      />
    </View>
  );

  const draggableSource =
    displayMode === 'digital' ? (
      <DragAndDropSection style={{ padding: 0 }}>
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSection>
    ) : (
      <DragAndDropSectionPDF
        itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
        ))}
      </DragAndDropSectionPDF>
    );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="dragandgrop"
        items={items}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        moveOrCopy="copy"
        maxLines={itemsMaxLines}
        variant={pdfItemVariant}
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          questionHeight={questionHeight}
          mainPanelContents={
            <>
              {draggableSource}
              <MeasureView>
                {dimens => (
                  <View>
                    <View style={{ flexDirection: 'row' }}>
                      <NumberLineTicksTopBottom
                        inputBox={draggableInputBox}
                        topTickValues={topTickValues}
                        bottomTickValues={bottomTickValues}
                        dimens={{ height: dimens.height, width: dimens.width }}
                        customFontSize={50}
                        pdfItemVariant={pdfItemVariant}
                      />
                    </View>
                  </View>
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="dragandgrop"
      items={items}
      testCorrect={testCorrect}
      defaultState={initialState}
      draggableStyle={styles.draggableDimens}
      textVariant={itemsTextVariant}
      textStyle={{ fontSize: 40 }}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="bottom"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View style={{ flexDirection: 'row' }}>
                <NumberLineTicksTopBottom
                  inputBox={draggableInputBox}
                  topTickValues={topTickValues}
                  bottomTickValues={bottomTickValues}
                  dimens={{
                    height: dimens.height / 3,
                    width: dimens.width
                  }}
                  customFontSize={customFontSize}
                />
              </View>
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  draggableDimens: {
    width: 96,
    height: 96
  }
});
