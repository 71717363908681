import { calculateRadius } from '../components/question/representations/MultiPieChart';
import PieChart from '../components/question/representations/PieChart';
import ShadedFractionBarModel from '../components/question/representations/ShadedFractionBarModel';
import { Dimens } from '../theme/scaling';
import { filledArray, range } from './collections';
import { getRandomFromArray, randomUniqueIntegersInclusive, seededRandom } from './random';

/**
 * Function that returns a shape that has shadedSections of its totalSections.
 * As of now the typeOfShade works for only the rectangular shapes.
 */
export function shadedSectionedShapes(
  shape: 'circle' | 'longRect' | 'rect' | 'shortRect',
  shadedSections: number,
  totalSections: number,
  dimens: Dimens,
  typeOfShade: 'leftRight' | 'rightLeft' | 'random' | 'any' = 'leftRight'
) {
  if (typeOfShade === 'any') {
    typeOfShade = getRandomFromArray(['leftRight', 'rightLeft', 'random'] as const, {
      random: seededRandom({ totalSections, shadedSections })
    });
  }
  const shadedCells = () => {
    if (shadedSections === 0) return [];
    switch (typeOfShade) {
      case 'leftRight':
        return range(0, shadedSections - 1);
      case 'rightLeft':
        return range(totalSections - shadedSections, totalSections - 1);
      case 'random':
        return randomUniqueIntegersInclusive(0, totalSections - 1, shadedSections, {
          random: seededRandom({ totalSections, shadedSections })
        });
    }
  };

  switch (shape) {
    case 'circle':
      return (
        <PieChart
          pieOptions={filledArray({ ratioOfSlices: 1 }, totalSections)}
          radius={calculateRadius(dimens, 1, 1)}
          missingSlices={
            shadedSections === totalSections ? [] : range(0, totalSections - shadedSections - 1)
          }
        />
      );
    case 'shortRect':
      return (
        <ShadedFractionBarModel
          totalSubSections={totalSections}
          width={dimens.width}
          height={dimens.height / 3}
          coloredSections={shadedCells()}
        />
      );
    case 'rect':
      return (
        <ShadedFractionBarModel
          totalSubSections={totalSections}
          width={dimens.width}
          height={dimens.height / 2}
          coloredSections={shadedCells()}
        />
      );
    case 'longRect':
      return (
        <ShadedFractionBarModel
          totalSubSections={totalSections}
          width={dimens.width}
          height={dimens.height}
          coloredSections={shadedCells()}
        />
      );
  }
}
