import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { filledArray, range } from 'common/src/utils/collections';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { MULT } from 'common/src/constants';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectNames,
  objectSchema
} from 'common/src/utils/objects';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getObjectImage } from 'common/src/utils/objectsImages';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akH',
  description: 'akH',
  keywords: ['Multiply', 'Group', 'Multiple', '8'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsX(containerPlural),
          translate.answerSentences.eachGroupHasAnsObjects(containerSingular, objectPlural),
          translate.answerSentences.thereAreAnsObjectsInTotal(objectPlural)
        ]}
        title={translate.instructions.howManyObjectsAreThere(objectPlural)}
        testCorrect={[['8'], [numberPerGroup.toString()], [(numberPerGroup * 8).toString()]]}
        {...props}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }
            ]}
          >
            {range(1, 8).map(index => (
              <View key={index}>
                {getObjectImage(object, numberPerGroup, dimens.height / 2, dimens.width / 4.2)}
              </View>
            ))}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akI',
  description: 'akI',
  keywords: ['Multiply', 'Group', 'Multiple', '8'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.completeMultiplication()}
        testCorrect={answer =>
          ((answer[0] === '8' && answer[1] === numberPerGroup.toString()) ||
            (answer[0] === numberPerGroup.toString() && answer[1] === '8')) &&
          answer[2] === (numberPerGroup * 8).toString()
        }
        inputMaxCharacters={2}
        questionHeight={900}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }
            ]}
          >
            {range(1, 8).map(index => (
              <View key={index}>
                {getObjectImage(object, numberPerGroup, dimens.height / 2, dimens.width / 4.2)}
              </View>
            ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            numberPerGroup.toLocaleString(),
            (8).toLocaleString(),
            (numberPerGroup * 8).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akJ',
  description: 'akJ',
  keywords: ['Multiply', 'Array', '8'],
  schema: z.object({
    rows: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 6);

    return { rows };
  },
  Component: props => {
    const {
      question: { rows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/> = <ans/>`, `<ans/> ${MULT} <ans/> = <ans/>`]}
        title={translate.instructions.writeTwoMultSentencesForArray()}
        testCorrect={answer =>
          ((answer[0][0] === rows.toString() &&
            answer[0][1] === '8' &&
            answer[1][0] === '8' &&
            answer[1][1] === rows.toString()) ||
            (answer[0][0] === '8' &&
              answer[0][1] === rows.toString() &&
              answer[1][0] === rows.toString() &&
              answer[1][1] === '8')) &&
          answer[0][2] === (8 * rows).toString() &&
          answer[1][2] === (8 * rows).toString()
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={8} dimens={dimens} />}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [rows.toLocaleString(), (8).toLocaleString(), (rows * 8).toLocaleString()],
            [(8).toLocaleString(), rows.toLocaleString(), (rows * 8).toLocaleString()]
          ],
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'akK',
  description: 'akK',
  keywords: ['Multiply', 'Array', '8'],
  schema: z.object({
    number1: z.number().int().min(2).max(6),
    eightIsFirstOrSecond: z.enum(['first', 'second'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);

    const eightIsFirstOrSecond = getRandomFromArray(['first', 'second'] as const);

    return { number1, eightIsFirstOrSecond };
  },
  Component: props => {
    const {
      question: { number1, eightIsFirstOrSecond },
      translate
    } = props;

    const multiplicationSentence =
      eightIsFirstOrSecond === 'first'
        ? `8 ${MULT} ${number1.toLocaleString()}`
        : `${number1.toLocaleString()} ${MULT} 8`;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.createArrayToShow(multiplicationSentence)}
        testCorrect={[8, number1]}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'akL',
  description: 'akL',
  keywords: ['Multiply', '8'],
  schema: z.object({
    groupA: z.number().int().min(1).max(4).array().length(3),
    objects: z.array(objectSchema).length(2)
  }),
  simpleGenerator: () => {
    const groupA = getRandomSubArrayFromArray([1, 2, 3, 4], 3);

    // Get 2 different objects
    const objects = getRandomSubArrayFromArray(objectNames, 2);

    return { groupA, objects };
  },
  Component: props => {
    const {
      question: { groupA, objects },
      translate,
      displayMode
    } = props;

    // All the same
    const A1 = 8;
    const B2 = 8;
    const B3 = 8;

    const [B1, A2, A3] = groupA;
    const [objectA, objectB] = objects;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 160, width: 540 } : { height: 250, width: 800 };

    // Container & Object words
    const itemImageA = getObjectImage(objectA, B1, dimens.height, dimens.width / A1);
    const itemImageB = getObjectImage(objectB, A2, dimens.height, dimens.width / B2);

    const statements = [
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A1}
              customImage={itemImageA}
              rowStyle={{ columnGap: 4 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A1} ${MULT} ${B1}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={B2}
              customImage={itemImageB}
              rowStyle={{ columnGap: 4 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A2} ${MULT} ${B2}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects dimens={dimens} rows={Math.min(A3, B3)} columns={Math.max(A3, B3)} />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A3} ${MULT} ${B3}`
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchNumberSentencesToPictures()}
        items={items}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'akM',
  description: 'akM',
  keywords: ['Multiply', '8', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, ...props }) => {
    const multiplier = 8;
    const number3 = number1 * multiplier;

    const barArray = filledArray(multiplier, number1);

    const numbers = [[number3], barArray];

    const answerIndices = [[0], []];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy8',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
