import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep
} from '../../../../utils/random';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { algebraicSymbolSchema, getAlgebraicSymbolPair } from '../../../../utils/algebraicSymbols';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { ADD, SUB } from '../../../../constants';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { portionToText } from '../../../../utils/fractions';
import {
  cheapObjectWithArticle,
  cheapObjectSchema,
  getRandomUniqueCheapObject,
  cheapObjectAsWord
} from '../../../../utils/objects';
import { getCheapObjectSvgName } from '../../../../utils/objectsImages';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUC',
  description: 'aUC',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(8).max(16),
      var2: z.number().int().min(2).max(14),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(8, 16);
    const var2 = randomIntegerInclusive(2, var1 - 2);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, var2, symb1, symb2 };
  },
  Component: ({ question: { var1, var2, symb1, symb2 }, translate }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.sumOfTwoNumbersXYisZ(symb1, symb2, total.toLocaleString())}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View style={{ justifyContent: 'space-evenly' }}>
              <BarModel
                numbers={[[var1]]}
                strings={[[symb1]]}
                total={var1}
                dimens={{ width: dimens.width * 0.95, height: dimens.height / 2 }}
                sameRowColor
              />
              <BarModel
                numbers={[[var2, diff]]}
                strings={[[symb2]]}
                arrowIndices={[[1]]}
                total={var1}
                dimens={{ width: dimens.width * 0.95, height: dimens.height / 2 }}
                sameRowColor
              />
            </View>
            <View
              style={[
                {
                  justifyContent: 'flex-end',
                  width: dimens.height,
                  height: dimens.height
                },
                { transform: [{ rotate: '90deg' }] }
              ]}
            >
              <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aUD',
  description: 'aUD',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(8).max(16),
      var2: z.number().int().min(2).max(14),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema,
      moreThan: z.boolean()
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(8, 16);
    const var2 = randomIntegerInclusive(2, var1 - 2);
    const [symb1, symb2] = getAlgebraicSymbolPair();
    const moreThan = getRandomBoolean();

    return { var1, var2, symb1, symb2, moreThan };
  },
  Component: ({ question: { var1, var2, symb1, symb2, moreThan }, translate }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    const secondInstruction = moreThan
      ? translate.instructions.xIsYMoreThanZ(symb1, diff.toLocaleString(), symb2)
      : translate.instructions.xIsYLessThanZ(symb2, diff.toLocaleString(), symb1);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${secondInstruction}`}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <BarModel
              numbers={[[var1]]}
              strings={[[symb1]]}
              total={var1}
              dimens={{ width: dimens.width, height: dimens.height / 2 }}
              sameRowColor
            />
            <BarModel
              numbers={[[var2]]}
              strings={[[symb2]]}
              total={var1}
              dimens={{ width: dimens.width, height: dimens.height / 2 }}
              sameRowColor
            />
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUE',
  description: 'aUE',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(6),
      times: z.number().int().min(2).max(5),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 6);
    const times = randomIntegerInclusive(2, 5);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, times, symb1, symb2 };
  },
  Component: ({ question: { var1, times, symb1, symb2 }, translate }) => {
    const var2 = var1 * times;
    const total = var1 + var2;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYTimesZ(symb2, times.toLocaleString(), symb1)}`}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View style={{ justifyContent: 'space-evenly' }}>
              <ShadedFractionBarModel
                preBarText={symb1}
                totalSubSections={1}
                width={(dimens.width * 0.8) / times}
                containerStyle={{ alignItems: 'flex-start' }}
              />
              <ShadedFractionBarModel
                preBarText={symb2}
                totalSubSections={times}
                width={dimens.width * 0.8}
              />
            </View>
            <View
              style={[
                {
                  justifyContent: 'flex-end',
                  width: dimens.height,
                  height: dimens.height
                },
                { transform: [{ rotate: '90deg' }] }
              ]}
            >
              <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aUE2',
  description: 'aUE',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(8),
      times: z.number().int().min(2).max(5),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2)
    .refine(({ var1, times }) => var1 * (times + 1) <= 40),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 8);
    const times = randomIntegerInclusive(2, 5, { constraint: x => var1 * (x + 1) <= 40 });
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, times, symb1, symb2 };
  },
  Component: ({ question: { var1, times, symb1, symb2 }, translate, displayMode }) => {
    const var2 = var1 * times;
    const total = var1 + var2;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYTimesZ(symb2, times.toLocaleString(), symb1)}`}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'flex-start' }}>
            <View style={[dimens, { flexDirection: 'row' }]}>
              <View style={{ justifyContent: 'space-evenly' }}>
                <ShadedFractionBarModel
                  preBarText={symb1}
                  totalSubSections={1}
                  width={(dimens.width * 0.8) / times}
                  height={dimens.height * 0.2}
                  containerStyle={{ alignItems: 'flex-start' }}
                />
                <ShadedFractionBarModel
                  preBarText={symb2}
                  totalSubSections={times}
                  width={dimens.width * 0.8}
                  height={dimens.height * 0.2}
                />
              </View>
              <View
                style={[
                  {
                    justifyContent: 'flex-end',
                    width: dimens.height * 0.7,
                    height: dimens.height
                  },
                  { transform: [{ rotate: '90deg' }, { translateY: -dimens.height * 0.3 }] }
                ]}
              >
                <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
              </View>
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.workOutTheValues()}
            </Text>
          </View>
        )}
        sentence={translate.answerSentences.XAnsYAns(symb1, symb2)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aUF',
  description: 'aUF',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(6),
      fraction: z.number().int().min(2).max(4),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 6);
    const fraction = randomIntegerInclusive(2, 4);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, fraction, symb1, symb2 };
  },
  Component: ({ question: { var1, fraction, symb1, symb2 }, translate }) => {
    const var2 = var1 * fraction;
    const total = var1 + var2;

    const getfractionWord = portionToText(fraction, translate, 1);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYOfZ(symb1, getfractionWord, symb2)}`}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View style={{ justifyContent: 'space-evenly' }}>
              <ShadedFractionBarModel
                preBarText={symb1}
                totalSubSections={1}
                width={(dimens.width * 0.8) / fraction}
                containerStyle={{ alignItems: 'flex-start' }}
              />
              <ShadedFractionBarModel
                preBarText={symb2}
                totalSubSections={fraction}
                width={dimens.width * 0.8}
              />
            </View>
            <View
              style={[
                {
                  justifyContent: 'flex-end',
                  width: dimens.height,
                  height: dimens.height
                },
                { transform: [{ rotate: '90deg' }] }
              ]}
            >
              <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUG',
  description: 'aUG',
  keywords: ['Solve', 'Equation'],
  schema: z
    .object({
      price1: z.number().int().min(15).max(95).step(5),
      price2: z.number().int().min(15).max(95).step(5),
      obj1: cheapObjectSchema,
      obj2: cheapObjectSchema
    })
    .refine(({ price1, price2 }) => price1 !== price2),

  simpleGenerator: () => {
    const [price1, price2] = randomUniqueIntegersInclusiveStep(15, 95, 5, 2);
    const [obj1, obj2] = getRandomUniqueCheapObject(2);

    return { price1, price2, obj1, obj2 };
  },
  Component: ({ question: { price1, price2, obj1, obj2 }, translate }) => {
    const total = price1 + price2;
    const diff = price1 - price2;

    const obj1Name = cheapObjectAsWord(obj1, translate);
    const obj2Name = cheapObjectAsWord(obj2, translate);
    const obj1WithArticle = cheapObjectWithArticle(obj1, translate);
    const obj2WithArticle = cheapObjectWithArticle(obj2, translate);

    const secondInstruction =
      diff < 0
        ? translate.instructions.xCostsYLessThanZ(
            obj1WithArticle,
            Math.abs(diff).toLocaleString(),
            obj2WithArticle
          )
        : translate.instructions.xCostsYMoreThanZ(
            obj1WithArticle,
            diff.toLocaleString(),
            obj2WithArticle
          );
    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.totalCostOfXandYIsZ(
          obj1Name,
          obj2Name,
          (total / 100).toFixed(2).toLocaleString()
        )}<br/>${secondInstruction}`}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', gap: 50 }}>
            <AssetSvg name={getCheapObjectSvgName(obj1)} height={dimens.height} />
            <AssetSvg name={getCheapObjectSvgName(obj2)} height={dimens.height} />
          </View>
        )}
        inputMaxCharacters={2}
        sentences={[
          translate.answerSentences.costOfXis(obj1WithArticle),
          translate.answerSentences.costOfXis(obj2WithArticle)
        ]}
        testCorrect={[[price1.toString()], [price2.toString()]]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUH',
  description: 'aUH',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(30).max(99),
      var2: z.number().int().min(10).max(98),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(30, 99);
    const var2 = randomIntegerInclusive(10, var1 - 1);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, var2, symb1, symb2 };
  },
  Component: ({ question: { var1, var2, symb1, symb2 }, translate }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheValuesOfXAndY(symb1, symb2)}
        Content={
          <View>
            <Text variant="WRN400">{`${symb1} ${ADD} ${symb2} = ${total.toLocaleString()}`}</Text>
            <Text variant="WRN400">{`${symb1} ${SUB} ${symb2} = ${diff.toLocaleString()}`}</Text>
          </View>
        }
        inputMaxCharacters={2}
        sentences={[`${symb1} = <ans/>`, `${symb2} = <ans/>`]}
        style={{ flexDirection: 'row' }}
        pdfDirection={'column'}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        testCorrect={[[var1.toString()], [var2.toString()]]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aUH2',
  description: 'aUH',
  keywords: [
    'Unknowns',
    'Constant',
    'Variable',
    'Multiplication',
    'Multiplicative',
    'Addition',
    'Total'
  ],
  schema: z
    .object({
      mass1: z.number().int().min(1).max(90),
      multiplier: z.number().int().min(2).max(12),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema,
      isGrams: z.boolean()
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const mass1 = getRandomBoolean()
      ? randomIntegerInclusive(1, 12)
      : randomIntegerInclusiveStep(20, 90, 10);
    const multiplier = randomIntegerInclusive(2, 12);
    const [symb1, symb2] = getAlgebraicSymbolPair();
    const isGrams = getRandomBoolean();

    return { mass1, multiplier, symb1, symb2, isGrams };
  },
  Component: ({ question: { mass1, multiplier, symb1, symb2, isGrams }, translate }) => {
    const mass2 = mass1 * multiplier;
    const totalMass = mass1 + mass2;
    const totalSentence = isGrams
      ? translate.units.numberOfG(totalMass)
      : translate.units.numberOfKg(totalMass);

    const answerSentence = isGrams
      ? translate.answerSentences.ansG()
      : translate.answerSentences.ansKg();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.theTotalMassOfAAndBIsXMassOfAIsYTimesWorkOutAAndB({
          symbol1: symb1,
          symbol2: symb2,
          totalMass: totalSentence,
          multiplier
        })}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%'
        }}
        pdfMainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%'
        }}
        inputMaxCharacters={3}
        sentences={[`${symb1} = ${answerSentence}`, `${symb2} = ${answerSentence}`]}
        testCorrect={[[mass2.toString()], [mass1.toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithTwoUnknowns',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question3, Question6]
});
export default SmallStep;
