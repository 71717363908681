// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplyAUnitFractionByAnInteger from './1MultiplyAUnitFractionByAnInteger';
import MultiplyANonUnitFractionByAnInteger from './2MultiplyANonUnitFractionByAnInteger';
import MultiplyAMixedNumberByAnInteger from './3MultiplyAMixedNumberByAnInteger';
import CalculateAFractionOfAQuantity from './4CalculateAFractionOfAQuantity';
import FractionOfAnAmount from './5FractionOfAnAmount';
import FindTheWhole from './6FindTheWhole';
import UseFractionsAsOperators from './7UseFractionsAsOperators';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    MultiplyAUnitFractionByAnInteger, // Step 1
    MultiplyANonUnitFractionByAnInteger, // Step 2
    MultiplyAMixedNumberByAnInteger, // Step 3
    CalculateAFractionOfAQuantity, // Step 4
    FractionOfAnAmount, // Step 5
    FindTheWhole, // Step 6
    UseFractionsAsOperators // Step 7
  ]
});
export default Block;
