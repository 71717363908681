import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Clock from '../../../../components/question/representations/Clock';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  clockColourVariantSchema,
  convert12hToSpokenString,
  getRandomClockColourVariant
} from '../../../../utils/time';
import { newQuestionContent } from '../../../Question';
import Text from 'common/src/components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfG',
  description: 'bfG',
  keywords: ["O'clock", 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.array(z.number().int().min(0).max(11)),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomUniqueIntegersInclusive(0, 11, 3);
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, clockColourVariant },
      translate
    } = props;

    const random = seededRandom(props.question);

    const values = ['A', 'B', 'C'];

    const options = shuffle(
      hours.map((hour, index) => {
        return { string: convert12hToSpokenString(translate, hour, 0), value: values[index] };
      }),
      { random }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTimeShown()}
        pdfTitle={translate.ks1PDFInstructions.tickTheTimeShown()}
        testCorrect={['A']}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours[0], minutes: 0 }}
            width={Math.min(dimens.width * 0.9, dimens.height * 0.9)}
            interactive={false}
            withoutMinuteIntervals
            clockColourVariant={clockColourVariant}
          />
        )}
        renderItems={options.map(({ string, value }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bfH',
  description: 'bfH',
  keywords: ["O'clock", 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, clockColourVariant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={`<ans/> ${translate.time.oClock()}`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[hours.toString()]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes: 0 }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            withoutMinuteIntervals
            clockColourVariant={clockColourVariant}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bfI',
  description: 'bfI',
  keywords: ["O'clock", 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, clockColourVariant },
      translate
    } = props;

    const translatedTime = convert12hToSpokenString(translate, hours, 0);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHourHandOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes: 0 })}
        exampleCorrectAnswer={{ hours, minutes: 0 }}
        staticMinuteHand
        clockColourVariant={clockColourVariant}
        withoutMinuteIntervals={true}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTheTimeToTheHour',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
