import { Svg, Path, Line, Text as SvgText, SvgProps } from 'react-native-svg';
import { countRange } from '../../../../utils/collections';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

const HEIGHT = 150;
const MAJOR_TICK_INTERVAL = 72.07;
const MAJOR_TICK_LENGTH = 54.3676;
const MINOR_TICK_LENGTH = 42.206;
const FINE_TICK_LENGTH = 27.5227;
const TICK_STROKE_WIDTH = 1.57546;
const FINE_TICK_STROKE_WIDTH = 1.1816;
// These need to be different to fit the extra 0 in the labels of the MM ruler
const ENDS_OFFSET = { cm: 25, mm: 30 } as const;
const LABEL_FONT_SIZE = { cm: 35, mm: 30 } as const;
const UNIT_LABEL_FONT_SIZE = 35;

type Props = {
  rulerKind: 'cm' | 'mm';
  rulerLength: number;
};

/**
 * An SVG of a ruler, using either cm or mm.
 * If you need to know this SVG's natural dimensions and other sizing info, use {@link Ruler.getRulerSizingInfo}.
 */
export default function Ruler({ rulerKind, rulerLength, ...svgProps }: Props & SvgProps) {
  const {
    naturalWidth: length,
    naturalHeight: height,
    startOffset,
    majorTicks
  } = Ruler.getRulerSizingInfo({ rulerKind, rulerLength });
  const displayMode = useContext(DisplayMode);

  return (
    <Svg viewBox={`0 0 ${length} ${height}`} {...svgProps}>
      <Path
        d={`M${
          length - 5.55
        } 1.63696H5.54305C2.91534 1.63696 0.785156 3.76714 0.785156 6.39485V143.602C0.785156 146.229 2.91534 148.36 5.54305 148.36H${
          length - 5.55
        }C${length - 2.92} 148.36 ${length - 0.79} 146.229 ${length - 0.79} 143.602V6.39485C${
          length - 0.79
        } 3.76714 ${length - 2.92} 1.63696 ${length - 5.55} 1.63696Z`}
        fill={displayMode === 'digital' ? '#FFFAA9' : 'white'}
        stroke="black"
        strokeWidth="1.57546"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Graduations (AKA ticks) */}
      {countRange(majorTicks * 10 + 1).map(i => {
        const x = startOffset + (MAJOR_TICK_INTERVAL / 10) * i;

        if (i % 10 === 0) {
          return (
            <Line
              key={i}
              x1={x}
              x2={x}
              y1={1.63696}
              y2={1.63696 + MAJOR_TICK_LENGTH}
              strokeWidth={TICK_STROKE_WIDTH}
              stroke="black"
            />
          );
        } else if (i % 5 === 0) {
          return (
            <Line
              key={i}
              x1={x}
              x2={x}
              y1={1.63696}
              y2={1.63696 + MINOR_TICK_LENGTH}
              strokeWidth={TICK_STROKE_WIDTH}
              stroke="black"
            />
          );
        } else {
          return (
            <Line
              key={i}
              x1={x}
              x2={x}
              y1={1.63696}
              y2={1.63696 + FINE_TICK_LENGTH}
              strokeWidth={FINE_TICK_STROKE_WIDTH}
              stroke="black"
            />
          );
        }
      })}
      {/* Number labels (major ticks only) */}
      {countRange(majorTicks + 1).map(i => {
        const x = startOffset + MAJOR_TICK_INTERVAL * i;

        return (
          <SvgText
            key={i}
            y={70}
            x={x}
            fontSize={LABEL_FONT_SIZE[rulerKind]}
            fontFamily="White_Rose_Noto-Regular"
            textAnchor="middle"
            alignmentBaseline="hanging"
          >
            {rulerKind === 'cm' ? i : i * 10}
          </SvgText>
        );
      })}
      {/* Unit label */}
      <SvgText
        y={100}
        x={ENDS_OFFSET[rulerKind]}
        fontSize={UNIT_LABEL_FONT_SIZE}
        fontFamily="White_Rose_Noto-Bold"
        textAnchor="start"
        alignmentBaseline="hanging"
      >
        {rulerKind}
      </SvgText>
    </Svg>
  );
}

Ruler.getRulerSizingInfo = ({ rulerKind, rulerLength }: Props) => {
  const unitsPerMajorTick = rulerKind === 'cm' ? 1 : 10;
  const majorTicks = Math.ceil(rulerLength / unitsPerMajorTick);
  const width = ENDS_OFFSET[rulerKind] * 2 + majorTicks * MAJOR_TICK_INTERVAL;
  const pixelsPerUnit = MAJOR_TICK_INTERVAL / unitsPerMajorTick;
  const startOffset = ENDS_OFFSET[rulerKind];
  return { naturalWidth: width, naturalHeight: HEIGHT, pixelsPerUnit, startOffset, majorTicks };
};
