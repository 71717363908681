// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import AddAndSubtract1s10s100sAnd1000s from './1AddAndSubtract1s10s100sAnd1000s';
import AddUpToTwo4DigitNumbersNoExchange from './2AddUpToTwo4DigitNumbersNoExchange';
import AddTwo4DigitNumbersOneExchange from './3AddTwo4DigitNumbersOneExchange';
import AddTwo4DigitNumbersMoreThanOneExchange from './4AddTwo4DigitNumbersMoreThanOneExchange';
import SubtractTwo4DigitNumbersNoExchange from './5SubtractTwo4DigitNumbersNoExchange';
import SubtractTwo4DigitNumbersOneExchange from './6SubtractTwo4DigitNumbersOneExchange';
import SubtractTwo4DigitNumbersMoreThanOneExchange from './7SubtractTwo4DigitNumbersMoreThanOneExchange';
import EfficientSubtraction from './8EfficientSubtraction';
import EstimateAnswers from './9EstimateAnswers';
import CheckingStrategies from './10CheckingStrategies';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [5, 7],
  smallSteps: [
    AddAndSubtract1s10s100sAnd1000s, // Step 1
    AddUpToTwo4DigitNumbersNoExchange, // Step 2
    AddTwo4DigitNumbersOneExchange, // Step 3
    AddTwo4DigitNumbersMoreThanOneExchange, // Step 4
    SubtractTwo4DigitNumbersNoExchange, // Step 5
    SubtractTwo4DigitNumbersOneExchange, // Step 6
    SubtractTwo4DigitNumbersMoreThanOneExchange, // Step 7
    EfficientSubtraction, // Step 8
    EstimateAnswers, // Step 9
    CheckingStrategies // Step 10
  ]
});
export default Block;
