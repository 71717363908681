import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  randomIntegerInclusive,
  getRandomBoolean,
  randomIntegerInclusiveStep,
  shuffle,
  getRandomFromArray,
  seededRandom
} from '../../../../utils/random';
import {
  getRandomUniqueCubeObjects,
  getRandomUniqueShapeWithCubesObjects,
  getRandomUniqueCuboidsObjects
} from '../../../../utils/cubes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import {
  ThreeDShape,
  getRandomShapeStrawMarshmallow,
  getRandomUniqueNets,
  shapeProperties,
  threeDShapesSchema
} from '../../../../utils/threeDShapes';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avE',
  description: 'avE',
  keywords: ['3-D', 'Shape', 'Make'],
  schema: z.object({
    number1: z.number().int().min(4).max(8).step(2),
    cubeShapeObjects: z.array(z.object({ svgName: z.string(), size: z.number() }))
  }),
  simpleGenerator: () => {
    // only have shapes for 4,6,8
    const number1 = randomIntegerInclusiveStep(4, 8, 2);
    const cubeShapeObjectsCorrect = getRandomUniqueShapeWithCubesObjects(2, number1);
    const cubeShapeObjectsIncorrect = getRandomUniqueShapeWithCubesObjects(2, number1, false);

    const cubeShapeObjects = shuffle([...cubeShapeObjectsCorrect, ...cubeShapeObjectsIncorrect]);

    return { number1, cubeShapeObjects };
  },

  Component: ({ question: { number1, cubeShapeObjects }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesOfXCubes(number1)}
        pdfTitle={translate.instructions.circleShapesOfXCubes(number1)}
        numItems={4}
        multiSelect
        testCorrect={cubeShapeObjects.filter(val => val.size === number1).map(val => val.svgName)}
        renderItems={({ dimens }) =>
          cubeShapeObjects.map(val => {
            return {
              value: val.svgName,
              component: (
                <AssetSvg
                  name={val.svgName as SvgName}
                  height={dimens.height * 0.9}
                  width={dimens.width * 0.9}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'avF',
  description: 'avF',
  keywords: ['3-D', 'Shape', 'Make'],
  schema: z.object({
    cubeShapeObject: z.object({ svgName: z.string(), size: z.number() })
  }),
  simpleGenerator: () => {
    const cubeShapeObject = getRandomUniqueShapeWithCubesObjects(1)[0];

    return { cubeShapeObject };
  },
  Component: props => {
    const {
      question: { cubeShapeObject },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCubes()}
        title={translate.instructions.howManyCubesNeeded()}
        testCorrect={[cubeShapeObject.size.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg
            name={cubeShapeObject.svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avG',
  description: 'avG',
  keywords: ['3-D', 'Shape', 'Make'],
  schema: z.object({
    name: nameSchema,
    isCubes: z.boolean(),
    cubeShapeObjects: z.array(z.object({ svgName: z.string(), size: z.number() })),
    cuboidShapeObjects: z.array(z.object({ svgName: z.string(), size: z.number() }))
  }),
  simpleGenerator: () => {
    const isCubes = getRandomBoolean();
    const numOfCubes = randomIntegerInclusive(1, 3);
    const cubeShapeObjects = getRandomUniqueCubeObjects(numOfCubes);
    const cuboidShapeObjects = getRandomUniqueCuboidsObjects(4 - numOfCubes);
    const name = getRandomName();

    return { isCubes, cubeShapeObjects, cuboidShapeObjects, name };
  },

  Component: ({ question: { isCubes, cubeShapeObjects, cuboidShapeObjects, name }, translate }) => {
    const shapeObjects = shuffle([...cubeShapeObjects, ...cuboidShapeObjects], {
      random: seededRandom({ isCubes, cubeShapeObjects, cuboidShapeObjects, name })
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterMakes3DShapeSelectCubesCuboids(
          name,
          isCubes ? translate.shapes.cubes(2) : translate.misc.notCubes()
        )}
        pdfTitle={translate.instructions.characterMakes3DShapeCircleCubesCuboids(
          name,
          isCubes ? translate.shapes.cubes(2) : translate.misc.notCubes()
        )}
        numItems={4}
        multiSelect
        testCorrect={
          isCubes
            ? cubeShapeObjects.map(val => val.svgName)
            : cuboidShapeObjects.map(val => val.svgName)
        }
        renderItems={({ dimens }) =>
          shapeObjects.map(val => {
            return {
              value: val.svgName,
              component: (
                <AssetSvg
                  name={val.svgName as SvgName}
                  height={dimens.height * 0.9}
                  width={dimens.width * 0.9}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avH',
  description: 'avH',
  keywords: ['3-D', 'Shape', 'Make'],
  schema: z.object({
    name: nameSchema,
    isEdges: z.boolean(),
    shapeObject: z.object({
      svgName: z.string(),
      vertices: z.number(),
      edges: z.number(),
      shape: threeDShapesSchema
    })
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const isEdges = getRandomBoolean();
    const shapeObject = getRandomShapeStrawMarshmallow();

    return { name, isEdges, shapeObject };
  },
  Component: props => {
    const {
      question: { name, isEdges, shapeObject },
      translate
    } = props;

    const translatedShape = translate.shapes[shapeObject.shape](1);

    return (
      <QF1ContentAndSentence
        sentence="<ans/>"
        title={
          isEdges
            ? translate.instructions.characterMakes3DShapeUsingStrawsAndMallowsHowManyEdges(
                name,
                translatedShape
              )
            : translate.instructions.characterMakes3DShapeUsingStrawsAndMallowsHowManyVertices(
                name,
                translatedShape
              )
        }
        testCorrect={isEdges ? [shapeObject.edges.toString()] : [shapeObject.vertices.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapeObject.svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'avI',
  description: 'avI',
  keywords: ['3-D', 'Shape', 'Make', 'Net'],
  schema: z.object({
    shape: threeDShapesSchema,
    shapeObjects: z.array(z.object({ svgName: z.string(), shape: threeDShapesSchema }))
  }),
  simpleGenerator: () => {
    const shapeObjects = getRandomUniqueNets(4);
    const shape = getRandomFromArray([
      ...shapeObjects.map(val => val.shape)
    ] as const) as ThreeDShape;

    return { shapeObjects, shape };
  },

  Component: ({ question: { shapeObjects, shape }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNetOfX(translate.shapes[shape](1))}
        pdfTitle={translate.instructions.circleNetOfX(translate.shapes[shape](1))}
        numItems={4}
        testCorrect={[shape]}
        renderItems={({ dimens }) =>
          shapeObjects.map(val => {
            return {
              value: val.shape,
              component: (
                <AssetSvg
                  name={val.svgName as SvgName}
                  height={dimens.height * 0.9}
                  width={dimens.width * 0.9}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'avJ',
  description: 'avJ',
  keywords: ['3-D', 'Shape', 'Make'],
  schema: z.object({
    name: nameSchema,
    shape: z.enum([
      'cubes',
      'cuboids',
      'triangularBasedPyramids',
      'squareBasedPyramids',
      'triangularPrisms',
      'pentagonalPrisms'
    ]),
    straws: z.number().int().min(4).max(15),
    marshmallows: z.number().int().min(3).max(10)
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const shape = getRandomFromArray([
      'cubes',
      'cuboids',
      'triangularBasedPyramids',
      'squareBasedPyramids',
      'triangularPrisms',
      'pentagonalPrisms'
    ] as const);
    const straws = randomIntegerInclusive(4, 15);
    const marshmallows = randomIntegerInclusive(3, 10);

    return { name, shape, straws, marshmallows };
  },
  Component: ({ question, translate, displayMode }) => {
    const { name, shape, straws, marshmallows } = question;

    const strawRemainder = straws >= 10 ? straws - 10 : straws;
    const strawChunks = strawRemainder >= 10 ? 2 : 1;

    const marshMallowChunks = marshmallows > 5 ? 2 : 1;

    const shapeProps = shapeProperties.filter(val => val.shape === shape);

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.characterIsMaking3DShapeUsingStrawsAndMallows(name)}
        content={
          <View style={{ flexDirection: 'row', gap: 25, alignItems: 'center' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {straws >= 10 && <AssetSvg name="Bundle_of_10_straws" />}
              <View style={{ rowGap: 40, transform: 'rotate(90deg)' }}>
                {countRange(strawChunks).map(i => {
                  const maxChunk = strawChunks > 1 ? 5 : marshmallows;
                  const count =
                    strawChunks === 1 || i === 1 ? strawRemainder : strawRemainder - maxChunk;
                  return (
                    <View key={`straw_${i}`} style={{ gap: 10 }}>
                      {countRange(count).map(j => (
                        <AssetSvg
                          key={`straw_${i}_${j}`}
                          name="drinking_straw"
                          width={displayMode === 'digital' ? 200 : 400}
                        />
                      ))}
                    </View>
                  );
                })}
              </View>
            </View>
            <View style={{ rowGap: 20, alignItems: 'center' }}>
              {countRange(marshMallowChunks).map(i => {
                const maxChunk = marshMallowChunks > 1 ? 5 : marshmallows;
                const count = i === 0 ? maxChunk : marshmallows - maxChunk;
                return (
                  <View key={`straw_${i}`} style={{ gap: 10, flexDirection: 'row' }}>
                    {countRange(count).map(j => (
                      <AssetSvg
                        key={`marsh_${i}_${j}`}
                        name="marshmallow"
                        width={displayMode === 'digital' ? 50 : 75}
                      />
                    ))}
                  </View>
                );
              })}
            </View>
          </View>
        }
        sentence={translate.answerSentences.isEnoughToMakeAX(translate.shapes[shape](1))}
        correctAnswer={shapeProps[0].edges <= straws && shapeProps[0].vertices <= marshmallows}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Make3DShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
