import { useContext, useMemo } from 'react';
import { ScientificNotation } from 'common/src/utils/math';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { filledArray } from '../../../utils/collections';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import Text from '../../typography/Text';
import { useI18nContext } from '../../../i18n/i18n-react';

export function sumDigits(integer: number) {
  if (!Number.isInteger(integer)) throw new Error(`${integer} was not an integer`);
  return integer
    .toString()
    .split('')
    .reduce((sum, d) => sum + Number(d), 0);
}

/** The base 10 SVGs to use, where the index in this array is the power of ten (e.g. 2 means 100). */
const SVGS: { name: SvgName; height: number; pdfHeight: number }[] = [
  { name: 'Place_value/1cube', height: 150, pdfHeight: 300 },
  { name: 'Place_value/10cube', height: 150, pdfHeight: 300 },
  { name: 'Place_value/100cube', height: 150, pdfHeight: 300 },
  { name: 'Place_value/1000cube', height: 190, pdfHeight: 380 }
];
const DRAGGABLE_HEIGHT = 190;
const PDF_DRAGGABLE_HEIGHT = 380;

const GAP = 10;
const PADDING = 10;

type Props = TitleStyleProps & {
  title: string;
  pdfTitle: string;
  initialState?: number[];
  number: ScientificNotation;
  draggablesToShow: number[];
  /** Default: small. Small means 11 fit on a row, large means 6 fit on a row. */
  counterSize?: 'large' | 'small';
  /** Default: 3. */
  rows?: 2 | 3;
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: string;
};

/**
 * Question format for creating a base ten number using pieces (cube of 1000, square of 100, column of ten and units)
 * by dragging them into a single drop zone.
 *
 * The maximum capacity is automatically derived from the number of rows and counter size. It will usually be either:
 * - 33 (3 rows, small counter size, i.e. 3x11)
 * - 12 (2 rows, large counter size, i.e. 2x6)
 */
export default function QF15CreateBaseTenNumber({
  title,
  pdfTitle,
  initialState = [],
  number,
  draggablesToShow,
  rows = 3,
  counterSize = 'small',
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  /** Number of images that fit on a row (found experimentally). */
  const imagesPerRow = counterSize === 'large' ? 6 : 11;
  /** Scale of an image in the draggable source area, below the drop zone. */
  const imageScaleDraggable = 1;
  /** Scale of an image in the drop zone. */
  const imageScale = counterSize === 'large' ? 0.75 : 0.4;

  const capacity = rows * imagesPerRow;

  const correctAnswer = draggablesToShow.flatMap((pow, index) =>
    filledArray(index, number.digitAt(pow))
  );

  if (capacity < correctAnswer.length) {
    console.error(
      `Capacity was not enough for the correct answer: ${capacity}<${correctAnswer.length} (${number})`
    );
  }

  const items = useMemo(
    () =>
      draggablesToShow.map(pow => {
        const svgInfo = SVGS[pow];
        // Adjust width/height for the smaller SVGs so that they all end up at the same scale
        // In particular, the 1000s SVG should be slightly larger than the others.
        const height: `${number}%` = `${(svgInfo.height / DRAGGABLE_HEIGHT) * 100}%`;
        const component: JSX.Element = (
          <View
            style={{
              height: height,
              width: height
            }}
          >
            <AssetSvg name={svgInfo.name} />
          </View>
        );
        return {
          component: component,
          value: pow
        };
      }),
    [draggablesToShow]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <EasyDragAndDrop.Provider
        items={items}
        state={[displayMode === 'pdf' ? initialState : correctAnswer]}
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent',
          width: PDF_DRAGGABLE_HEIGHT * imageScaleDraggable,
          height: PDF_DRAGGABLE_HEIGHT * imageScaleDraggable
        }}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <View style={{ gap: 20, alignItems: 'center' }}>
              <EasyDragAndDrop.ZoneMultiple
                id={0}
                style={{
                  width: '100%',
                  // Height is exactly enough to fit the number of rows, with all the gaps and paddings.
                  height:
                    rows * (PDF_DRAGGABLE_HEIGHT * imageScale) +
                    (rows - 1) * GAP +
                    2 * PADDING +
                    2 * 3,
                  gap: GAP,
                  padding: PADDING
                }}
                droppedStyle={{
                  width: PDF_DRAGGABLE_HEIGHT * imageScale,
                  height: PDF_DRAGGABLE_HEIGHT * imageScale
                }}
              />
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  gap: 10,
                  alignItems: 'center'
                }}
              >
                <Text style={{ marginEnd: 50 }} numberOfLines={1} variant="WRN400">
                  {translate.markScheme.useTheseBase10Pieces()}
                </Text>
                {items.map(item => (
                  <View
                    key={item.value}
                    style={{
                      width: PDF_DRAGGABLE_HEIGHT * 0.7,
                      height: PDF_DRAGGABLE_HEIGHT * 0.7,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {item.component}
                  </View>
                ))}
              </View>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer &&
                renderMarkSchemeProp(customMarkSchemeAnswer)}
            </View>
          }
          questionHeight={questionHeight}
          {...{ props }}
        />
      </EasyDragAndDrop.Provider>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      defaultState={[initialState]}
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent',
        width: DRAGGABLE_HEIGHT * imageScaleDraggable,
        height: DRAGGABLE_HEIGHT * imageScaleDraggable
      }}
      testComplete={state => state[0].length > 0}
      testCorrect={state => state[0].reduce((sum, pow) => sum + 10 ** pow, 0) === number.toNumber()}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        mainPanelContents={
          <View style={{ height: '100%', gap: 20, alignItems: 'center' }}>
            <EasyDragAndDrop.ZoneMultiple
              id={0}
              capacity={capacity}
              style={[
                {
                  width: '100%',
                  // Height is exactly enough to fit the number of rows, with all the gaps and paddings.
                  height:
                    rows * (DRAGGABLE_HEIGHT * imageScale) + (rows - 1) * GAP + 2 * PADDING + 2 * 3,
                  gap: GAP,
                  padding: PADDING
                }
              ]}
              droppedStyle={{
                width: DRAGGABLE_HEIGHT * imageScale,
                height: DRAGGABLE_HEIGHT * imageScale
              }}
            />
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {items.map((item, index) => (
                <EasyDragAndDrop.Source
                  key={item.value}
                  id={index}
                  style={{ shadowOpacity: 0, backgroundColor: 'transparent' }}
                />
              ))}
            </View>
          </View>
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}
