import { View, StyleProp, ViewStyle } from 'react-native';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { ScaleObjectName, scaleObjects } from '../../../utils/objects';
import PyramidOfObjects from '../../molecules/PyramidOfObjects';
import { getMinTriangularNumber } from '../../../utils/math';
import { Dimens } from '../../../theme/scaling';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { sortNumberArray } from '../../../utils/collections';

type Item = ScaleObjectName | JSX.Element;
type ScaleTypes = {
  direction?: 'equal' | 'left' | 'right';
  items: [Item, Item];
  containerStyle?: StyleProp<ViewStyle>;
  amount?: [number, number];
  iconWidth?: number;
  dimens: Dimens;
  /**
   * Defaults to false. If true use scaling in scenarios where scales are too big for its container
   */
  widthScaling?: boolean;
  /**
   * When set to true, each stacked row has an incrementally higher z-index, placing the images in front as needed (e.g. For stacked cubes).
   * In the default case the lower rows will appear in front (e.g. For for items like apples).
   */
  stackInfront?: boolean;
  /**
   * Allows hardcoded numbers to position the left and right icons respectively for scales with a direction of left or right
   * Useful for questions which don't work as well with the default positioning
   */
  customPositionValues?: [number, number];
  /**
   * For objects with amounts, does not scale the object with the same scaling as the objects in the pyramid
   */
  scaleSingleObject?: [boolean, boolean];
};

/**
 * Wooden balance scale with directions of equal, left or right.
 * @param amount - A number array with a limit of 2. The amount of icons at each side of the scale.
 * @param maxLength - The maximum amount of icons the pyramid will span wide from the bottom.
 */
export default function WoodenBalanceScale({
  direction = 'equal',
  items,
  containerStyle,
  amount,
  iconWidth,
  dimens,
  widthScaling,
  stackInfront,
  customPositionValues,
  scaleSingleObject = [true, true]
}: ScaleTypes) {
  const displayMode = useContext(DisplayMode);
  const equalScale = displayMode === 'digital' ? 0.8 : 0.6;
  const WIDTH =
    direction === 'equal' || (displayMode === 'digital' && widthScaling)
      ? dimens.width * equalScale
      : 510;
  const SCALE_NAME =
    direction === 'equal'
      ? 'Scales/wooden_balance_scale_equal'
      : direction === 'left'
      ? 'Scales/wooden_balance_scale_left'
      : 'Scales/wooden_balance_scale_right';

  if (customPositionValues && customPositionValues.length !== items.length) {
    throw Error('customPositionValues length must be the same as items length');
  }

  const scaleInfo = getSvgInfo(SCALE_NAME);
  const scaleSvgHeight = WIDTH / scaleInfo.aspectRatio;

  const maxLength = amount ? Math.max(...amount.map(val => getMinTriangularNumber(val))) : 1;
  const iconScale = (WIDTH * 0.4) / maxLength;

  return (
    <View style={[{ position: 'relative' }, containerStyle]}>
      {items.map((item, idx) => {
        let rotation = 0;

        let scaleObject;

        if (typeof item === 'string') {
          scaleObject = scaleObjects[item];
          rotation =
            direction === 'equal'
              ? 0
              : direction === 'left'
              ? -scaleObject.rotation
              : scaleObject.rotation;
        }

        // Styles
        const rotateStyles = {
          rotateZ: `${rotation}deg`
        };

        return (
          <View
            key={idx}
            style={[
              { position: 'absolute' },
              // Equal
              direction === 'equal'
                ? {
                    bottom: scaleSvgHeight,
                    left: idx === 0 ? 0 : undefined,
                    right: idx === 1 ? 0 : undefined
                  }
                : // Left
                direction === 'left'
                ? idx === 0
                  ? {
                      bottom: customPositionValues
                        ? customPositionValues[0]
                        : displayMode === 'digital' && widthScaling
                        ? 25
                        : 33,
                      left: 0,
                      transform: [rotateStyles]
                    }
                  : {
                      bottom: customPositionValues
                        ? customPositionValues[1]
                        : displayMode === 'digital' && widthScaling
                        ? 127
                        : 175,
                      right: 10,
                      transform: [rotateStyles]
                    }
                : // Right
                idx === 0
                ? {
                    bottom: customPositionValues
                      ? customPositionValues[0]
                      : displayMode === 'digital' && widthScaling
                      ? 127
                      : 175,
                    left: 10,
                    transform: [rotateStyles]
                  }
                : {
                    bottom: customPositionValues
                      ? customPositionValues[1]
                      : displayMode === 'digital' && widthScaling
                      ? 25
                      : 33,
                    right: -25,
                    transform: [rotateStyles]
                  },
              { zIndex: 10 }
            ]}
          >
            {typeof item !== 'string' ? (
              item
            ) : amount &&
              (scaleSingleObject[idx] || (!scaleSingleObject[idx] && amount[idx] !== 1)) ? (
              <PyramidOfObjects
                icon={item}
                amount={amount[idx]}
                iconWidth={iconWidth || Math.min(iconScale, scaleObjects[item].width)}
                pyramidWidth={WIDTH * 0.4}
                alignLeft={idx === 0}
                stackInfront={stackInfront}
              />
            ) : (
              <AssetSvg name={scaleObjects[item].name} width={scaleObjects[item].width} />
            )}
          </View>
        );
      })}

      <AssetSvg name={SCALE_NAME} width={WIDTH} />
    </View>
  );
}

export function weightToDenominations<T extends number>(
  totalWeight: number,
  denominationsG: T[]
): T[] {
  const sortedDenominations = sortNumberArray(denominationsG, 'descending');

  const result: T[] = [];

  for (const denomination of sortedDenominations) {
    while (totalWeight >= denomination) {
      result.push(denomination);
      totalWeight -= denomination;
    }
  }
  // If total doesn't equal 0 throw error
  // Denominations provided don't have a combination to sum upto total
  if (totalWeight !== 0)
    throw new Error(
      `Denominations[${denominationsG}] provided [${totalWeight}] don't sum upto totalWeight.`
    );

  return result;
}

type kgWeights = (50 | 20 | 5 | 2 | 1)[];
type gWeights = (500 | 200 | 100 | 50 | 25 | 20 | 10 | 5 | 2 | 1)[];

type ScaledWeights = { unit: 'kg'; weights: kgWeights } | { unit: 'g'; weights: gWeights };

export function getScaledWeights({ weights, unit }: ScaledWeights): number[] {
  const weightWidths =
    unit === 'kg'
      ? (weights as kgWeights).map(w => getSvgInfo(`Weights/Weights_${w}kg`).width)
      : (weights as gWeights).map(w => getSvgInfo(`Weights/Weights_${w}g`).width);
  const maxWidth = Math.max(...weightWidths);

  return weightWidths.map(w => w / maxWidth);
}
