import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  AlgebraicSymbols,
  algebraicSymbolSchema,
  algebraicSymbols,
  getAlgebraicSymbol
} from '../../../../utils/algebraicSymbols';
import { ALGEBRAIC_M, DEGREES } from '../../../../constants';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAo',
  description: 'aAo',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(30).max(150).step(5),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(30, 150, 5, { constraint: x => x !== 90 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },
  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(180 - angle).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - angle, innerLabel: letter },
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
              ]}
              showOuterCircle
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAp',
  description: 'aAp',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(31)
      .max(149)
      .refine(val => val % 5 !== 0, "angle shouldn't be a multiple of 5"),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(31, 149, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },
  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(180 - angle).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - angle, innerLabel: letter },
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
              ]}
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAq',
  description: 'aAq',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z
    .object({
      angle1: z.number().int().min(31).max(79),
      angle2: z.number().int().min(31).max(79),
      letter: algebraicSymbolSchema
    })
    .refine(val => val.angle1 + val.angle2 <= 150, 'all angles must be greater than 30'),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusive(31, 79, { constraint: x => x % 5 !== 0 });
    const angle2 = randomIntegerInclusive(31, 79, {
      constraint: x => x % 10 !== 0 && angle1 + x <= 150
    });
    const letter = getAlgebraicSymbol();

    return { angle1, angle2, letter };
  },
  Component: props => {
    const {
      question: { angle1, angle2, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(180 - (angle1 + angle2)).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - (angle1 + angle2), innerLabel: letter },
                { angle: angle1, innerLabel: `${angle1.toLocaleString()}${DEGREES}` },
                { angle: angle2, innerLabel: `${angle2.toLocaleString()}${DEGREES}` }
              ]}
              arcSizeGroups={[[0], [1], [2]]}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAr',
  description: 'aAr',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    splits: numberEnum([3, 4, 5, 6, 8, 10, 12]),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const splits = getRandomFromArray([3, 4, 5, 6, 8, 10, 12] as const);
    const letter = getRandomFromArray([
      ...algebraicSymbols.filter(val => val !== ALGEBRAIC_M)
    ] as const) as AlgebraicSymbols;

    return { splits, letter };
  },
  Component: props => {
    const {
      question: { splits, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngles()}
        testCorrect={[(180 / splits).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={countRange(splits).map(() => ({
                angle: 180 / splits,
                innerLabel: letter
              }))}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAs',
  description: 'aAs',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z
    .object({
      splits: numberEnum([3, 4]),
      totalAngle: z.number().int().min(90).max(150),
      letter: algebraicSymbolSchema
    })
    .refine(val => val.totalAngle % val.splits === 0, 'totalAngle should be a multiple of splits'),
  simpleGenerator: () => {
    const splits = getRandomFromArray([3, 4] as const);
    const totalAngle = randomIntegerInclusive(90, 150, { constraint: x => x % splits === 0 });
    const letter = getAlgebraicSymbol();

    return { splits, totalAngle, letter };
  },
  Component: props => {
    const {
      question: { splits, totalAngle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfAngleX(letter)}
        testCorrect={[(totalAngle / splits).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                {
                  angle: 180 - totalAngle,
                  innerLabel: `${(180 - totalAngle).toLocaleString()}${DEGREES}`
                },
                ...countRange(splits).map(() => ({
                  angle: totalAngle / splits,
                  innerLabel: letter
                }))
              ]}
              arcSizeGroups={countRange(splits + 1).map(val => [val])}
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAt',
  description: 'aAt',
  keywords: ['Angles', 'Straight line', 'Calculate'],
  schema: z.object({
    angle3: z.number().int().min(31).max(49),
    angle4: z.number().int().min(41).max(79),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle3 = randomIntegerInclusive(31, 49, { constraint: x => x % 5 !== 0 });
    const angle4 = randomIntegerInclusive(41, 79, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle3, angle4, letter };
  },
  Component: props => {
    const {
      question: { angle3, angle4, letter },
      translate
    } = props;

    const angle1 = 180 - (angle3 + (180 - (angle3 + angle4)));
    const angle2 = 180 - (angle1 + angle3);

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.theDiagramIsMadeOf3LinesWorkOutAngleX(letter)}
        testCorrect={[angle1.toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                {
                  angle: angle1,
                  innerLabel: letter
                },
                {
                  angle: angle2
                },
                {
                  angle: angle3,
                  innerLabel: `${angle3.toLocaleString()}${DEGREES}`
                },
                {
                  angle: angle4,
                  innerLabel: `${angle4.toLocaleString()}${DEGREES}`
                },
                {
                  angle: 180 - angle4
                }
              ]}
              innerArcSegments={[0, 2, 3]}
              arcSizeGroups={[[0], [2], [3]]}
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateAnglesOnAStraightLine',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
