import { resolveFont } from 'common/src/theme/fonts';
import { type SetState, projectSetState } from 'common/src/utils/react';
import { forwardRef, useRef, useState } from 'react';
import { View, type StyleProp, Text, TextInput, type ViewStyle, Platform } from 'react-native';
import useBreakpoints from '../hooks/useBreakpoints';
import { colors } from 'common/src/theme/colors';
import useLoginStore from '../storage/useLoginStore';

/** –, i.e. not a hyphen! */
const DASH = '–';

type ShapeCharacter = 'l' | 'n' | '-';

/**
 * Typescript magic to resolve a string constant type as "never" if any of the characters in it aren't in
 * {@link ShapeCharacter}.
 */
type IsShape<S> = S extends ''
  ? unknown
  : S extends `${ShapeCharacter}${infer Tail}`
  ? IsShape<Tail>
  : never;

type Props<Shape> = {
  /**
   * Combination of l (letter input), n (number input) and - (hyphen - mapped to dash when displayed).
   * If you get an error complaining about it not being assignable to "never", this means typescript thinks your
   * string could have other characters.
   */
  shape: Shape & IsShape<Shape>;
  code: string[];
  setCode: SetState<string[]>;
  onFocus?: () => void;
  onFinalEnter?: () => void;
  /** Whether to highlight all cells with an error outline. Default: false. */
  error?: boolean;
  style?: StyleProp<ViewStyle>;
  /** Whether to auto-focus the first cell. */
  autoFocus?: boolean;
  /** Number of characters in a input */
  maxInputLength?: number;
};

/**
 * Input row made of several single-character text inputs.
 *
 * FIXME: when typing quickly on native, some inputs are lost! Maybe use a different approach?
 */
export default function TextInputRow<Shape extends string>({
  shape,
  code,
  setCode,
  onFocus,
  onFinalEnter,
  error = false,
  style,
  autoFocus = false,
  maxInputLength = 1
}: Props<Shape>) {
  const { loggedInUser, setActivity } = useLoginStore();

  const resize = useBreakpoints().resize;
  function shapeIndexToCodeIndex(index: number) {
    return (
      index - Array.from(shape.substring(0, index)).filter(it => it !== 'l' && it !== 'n').length
    );
  }

  const inputRefs = useRef<(TextInput | null)[]>(code.map(() => null));

  const resetActivityTimer = () => {
    loggedInUser && setActivity();
  };

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 16 * resize,
          minHeight: 96 * resize
        },
        style
      ]}
    >
      {Array.from(shape).map((character, shapeIndex) => {
        const codeIndex = shapeIndexToCodeIndex(shapeIndex);
        switch (character as ShapeCharacter) {
          case 'l':
          case 'n':
            return (
              <RowTextInput
                key={shapeIndex}
                testID={`input_${shapeIndex}`}
                ref={ref => (inputRefs.current[codeIndex] = ref)}
                variant={character === 'l' ? 'letter' : 'number'}
                value={code[codeIndex]}
                maxInputLength={maxInputLength}
                onChangeText={text => {
                  if (text.length === maxInputLength) {
                    // User entered max amount of characters, go to next text box
                    inputRefs.current[codeIndex + 1]?.focus();
                  }

                  projectSetState(setCode, codeIndex)(text);
                }}
                onFocus={onFocus}
                onEnter={
                  codeIndex === code.length - 1
                    ? onFinalEnter
                    : () => inputRefs.current[codeIndex + 1]?.focus()
                }
                onBackspace={
                  codeIndex === 0 ? undefined : () => inputRefs.current[codeIndex - 1]?.focus()
                }
                isLast={codeIndex === code.length - 1}
                error={error}
                autoFocus={autoFocus && codeIndex === 0}
                additionalKeypressAction={resetActivityTimer}
              />
            );
          case '-':
            return (
              <Text
                key={shapeIndex}
                style={[
                  resolveFont({
                    fontFamily: 'White_Rose_Noto',
                    fontWeight: '400',
                    fontSize: 40 * resize,
                    lineHeight: 60 * resize,
                    color: colors.prussianBlue
                  }),
                  { marginHorizontal: 8 * resize }
                ]}
              >
                {DASH}
              </Text>
            );
        }
      })}
    </View>
  );
}

type TextInputRowProps = {
  variant: 'letter' | 'number';
  value: string;
  onChangeText: (text: string) => void;
  onFocus?: () => void;
  onEnter?: () => void;
  onBackspace?: () => void;
  isLast?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  maxInputLength?: number;
  testID?: string;
  additionalKeypressAction: () => void;
};

const RowTextInput = forwardRef<TextInput, TextInputRowProps>(function RowTextInput(
  {
    variant,
    value,
    onChangeText,
    onFocus,
    onEnter,
    onBackspace,
    isLast = false,
    error = false,
    autoFocus = false,
    maxInputLength,
    testID,
    additionalKeypressAction
  }: TextInputRowProps,
  forwardedRef
) {
  const [isFocused, setFocus] = useState(false);
  const resize = useBreakpoints().resize;

  return (
    <TextInput
      ref={forwardedRef}
      testID={testID}
      autoCapitalize="characters"
      autoComplete="off"
      autoCorrect={false}
      spellCheck={false}
      keyboardType={(() => {
        switch (variant) {
          case 'letter':
            return Platform.OS === 'android' ? 'visible-password' : 'default';
          case 'number':
            return Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'number-pad';
        }
      })()}
      disableFullscreenUI={true}
      value={value}
      onChangeText={onChangeText}
      selectTextOnFocus={true}
      enterKeyHint={isLast ? 'go' : 'next'}
      onSubmitEditing={e => {
        onChangeText(e.nativeEvent.text);
        onEnter && onEnter();
      }}
      blurOnSubmit={false}
      onKeyPress={e => {
        additionalKeypressAction();
        if (e.nativeEvent.key === 'Backspace') {
          onChangeText(value.substring(0, value.length - 1));
          if (value === '') {
            onBackspace && onBackspace();
          }

          e.stopPropagation();
          e.preventDefault();
        }
      }}
      maxLength={maxInputLength}
      textAlign="center"
      // - On iOS, this also does the cursor color, and the selection color is actually a translucent tint of this
      //   color. ✔️
      // - On android, this seems to make the cursor color and selection color the same, which is bad, so we don't
      //   use this prop. We instead just use the default selectionColor, and set the cursor color separately below.
      // - On web, this is ignored
      selectionColor={Platform.OS === 'ios' ? colors.prussianBlue : undefined}
      // Android only
      cursorColor={colors.prussianBlue}
      style={[
        {
          width: (maxInputLength === 1 ? 96 : 150) * resize,
          height: 96 * resize,
          borderRadius: 4 * resize,
          textAlign: 'center',
          borderWidth: 1,
          borderColor: colors.greys500,
          backgroundColor: colors.greys100
        },
        resolveFont({
          fontFamily: 'White_Rose_Noto',
          fontWeight: '700',
          fontSize: 40 * resize,
          lineHeight: 60 * resize,
          color: colors.prussianBlue
        }),
        // text align vertical
        Platform.select({
          web: { textAlignVertical: 'center' },
          android: { textAlignVertical: 'center' },
          ios: { paddingBottom: 8 * resize } // Judged by eye
        }),
        error && {
          borderWidth: 3 * resize,
          borderColor: colors.danger,
          backgroundColor: colors.dangerSurface
        },
        isFocused && {
          borderWidth: 3 * resize,
          borderColor: colors.burntSienna,
          backgroundColor: colors.burntSiennaLight
        }
      ]}
      onFocus={() => {
        onFocus && onFocus();
        setFocus(true);
      }}
      onBlur={() => setFocus(false)}
      autoFocus={autoFocus}
    />
  );
});
