import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { randomIntegerInclusive, randomIntegerInclusiveStep } from '../../../../utils/random';

import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { MULT } from '../../../../constants';
import TextStructure from '../../../../components/molecules/TextStructure';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF27aLongMultiplicationDeprecated from '../../../../components/question/questionFormats/QF27aLongMultiplicationDeprecated';
import { getMarkSchemeAnswer } from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF27aLongMultiplication from '../../../../components/question/questionFormats/QF27aLongMultiplication';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOO',
  description: 'aOO',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(12).max(19),
    number2: z.number().int().min(2).max(9),
    number4: z.number().int().min(21).max(29)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 19);
    const number2 = randomIntegerInclusive(2, 9);
    const number4 = randomIntegerInclusive(21, 29);

    return { number1, number2, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4 },
      translate
    } = props;

    const number3 = number2 * 10;

    const eqA = getBinOpEquation({
      left: number1,
      right: number2,
      result: number1 * number2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: number1,
      right: number3,
      result: number1 * number3,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: number4,
      right: number2,
      result: number4 * number2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      left: number4,
      right: number3,
      result: number4 * number3,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aOP',
  description: 'aOP',
  keywords: ['3-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(201)
      .max(699)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z.number().int().min(13).max(19)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 699, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 19);

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aOP2',
  description: 'aOP',
  keywords: ['3-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(201)
      .max(699)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z.number().int().min(13).max(19)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 699, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 19);

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aOQ',
  description: 'aOQ',
  keywords: ['3-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(201)
      .max(699)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(25)
      .max(49)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 699, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(25, 49, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aOQ2',
  description: 'aOQ',
  keywords: ['3-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(201)
      .max(699)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(25)
      .max(49)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 699, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(25, 49, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aOR',
  description: 'aOR',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(101)
      .max(499)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z.number().int().min(13).max(20),
    number3: z
      .number()
      .int()
      .min(301)
      .max(699)
      .refine(x => x % 10 !== 0, 'number3 should not be a multiple of 10'),
    number4: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'number4 should not be a multiple of 10'),
    number5: z
      .number()
      .int()
      .min(501)
      .max(949)
      .refine(x => x % 10 !== 0, 'number5 should not be a multiple of 10'),
    number6: z
      .number()
      .int()
      .min(51)
      .max(99)
      .refine(x => x % 10 !== 0, 'number6 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 499, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 20);
    const number3 = randomIntegerInclusive(301, 699, { constraint: x => x % 10 !== 0 });
    const number4 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const number5 = randomIntegerInclusive(501, 949, { constraint: x => x % 10 !== 0 });
    const number6 = randomIntegerInclusive(51, 99, { constraint: x => x % 10 !== 0 });

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: number1,
      right: number2,
      result: number1 * number2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: number3,
      right: number4,
      result: number3 * number4,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: number5,
      right: number6,
      result: number5 * number6,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOS',
  description: 'aOS',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    lengthInMeters: z
      .number()
      .int()
      .min(111)
      .max(129)
      .refine(x => x % 10 !== 0, 'lengthInMeters should not be a multiple of 10'),
    widthInMeters: z
      .number()
      .int()
      .min(41)
      .max(59)
      .refine(x => x % 10 !== 0, 'widthInMeters should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const lengthInMeters = randomIntegerInclusive(111, 129, { constraint: x => x % 10 !== 0 });
    const widthInMeters = randomIntegerInclusive(41, 59, { constraint: x => x % 10 !== 0 });

    return { widthInMeters, lengthInMeters };
  },
  Component: props => {
    const {
      question: { widthInMeters, lengthInMeters },
      translate
    } = props;

    const answer = widthInMeters * lengthInMeters;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aFieldIsYLongAndZWidth({
          length: lengthInMeters.toLocaleString(),
          width: widthInMeters.toLocaleString()
        })}
        testCorrect={[answer.toString()]}
        sentence={`<ans/> m²`}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOT',
  description: 'aOT',
  keywords: ['Compare', 'Inequalities', '3-digit', 'Multiplication'],
  schema: z.object({
    multiplicationOneNumberOne: z.number().int().min(111).max(299),
    multiplicationOneNumberTwo: z.number().int().min(21).max(69),
    multiplicationTwoNumberOne: z.number().int().min(111).max(299),
    multiplicationTwoNumberTwo: z.number().int().min(12).max(96)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusiveStep(100, 200, 100);
    const var2 = randomIntegerInclusiveStep(10, 90, 10);
    const var3 = randomIntegerInclusive(1, 9);

    const multiplicationOneNumberOne = var1 + var2 + var3;

    const var5 = randomIntegerInclusiveStep(20, 60, 10, { constraint: x => x !== var2 });
    const var6 = randomIntegerInclusive(1, 9, { constraint: x => x !== var3 });

    const multiplicationOneNumberTwo = var5 + var6;

    const var8 = var2 / 10;
    const var9 = var3 * 10;

    const multiplicationTwoNumberOne = var1 + var8 + var9;

    const var11 = var5 / 10;
    const var12 = var6 * 10;

    const multiplicationTwoNumberTwo = var11 + var12;

    return {
      multiplicationOneNumberOne,
      multiplicationOneNumberTwo,
      multiplicationTwoNumberOne,
      multiplicationTwoNumberTwo
    };
  },
  Component: props => {
    const {
      question: {
        multiplicationOneNumberOne,
        multiplicationOneNumberTwo,
        multiplicationTwoNumberOne,
        multiplicationTwoNumberTwo
      },
      translate
    } = props;

    const statements = [
      {
        lhsComponent: (
          <TextStructure
            sentence={`${multiplicationOneNumberOne.toLocaleString()} ${MULT} ${multiplicationOneNumberTwo.toLocaleString()}`}
          />
        ),
        rhsComponent: (
          <TextStructure
            sentence={`${multiplicationTwoNumberOne.toLocaleString()} ${MULT} ${multiplicationTwoNumberTwo.toLocaleString()}`}
          />
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          multiplicationOneNumberOne * multiplicationOneNumberTwo,
          multiplicationTwoNumberOne * multiplicationTwoNumberTwo
        )
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatement()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatement()}
        items={['>', '<', '=']}
        itemVariant="square"
        statements={statements}
        statementStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA3DigitNumberByA2DigitNumber',
  questionTypes: [Question1, Question2v2, Question3v2, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
