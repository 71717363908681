import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomNumberInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { convertUnitsSuffix } from 'common/src/utils/unitConversion';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { numberEnum } from '../../../../utils/zod';
import { ADD, MULT } from '../../../../constants';
import { compareFloats, roundToSignificantFigures } from '../../../../utils/math';
import { findExchanges } from '../../../../utils/exchanges';
import { filledArray } from 'common/src/utils/collections';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { simplify } from '../../../../utils/fractions';
import { LocalizedString } from 'typesafe-i18n';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoc',
  description: 'aoc',
  keywords: ['Metric', 'Convert', 'cm', 'm', 'km', 'kg', 'l'],
  schema: z.object({
    bottomRowNumber: z.number().int().min(2).max(900),
    bottomRowParts: z.number().int().min(2).max(6),
    topRowUnit: z.enum(['cm', 'm', 'km', 'kg', 'l'])
  }),
  simpleGenerator: () => {
    const topRowUnit = getRandomFromArray(['cm', 'm', 'km', 'kg', 'l'] as const);

    const bottomRowNumber = (() => {
      switch (topRowUnit) {
        case 'cm':
          return randomIntegerInclusive(2, 9);
        case 'm':
          return randomIntegerInclusiveStep(20, 90, 10);
        case 'km':
        case 'kg':
        case 'l':
          return randomIntegerInclusiveStep(200, 900, 100);
      }
    })();

    const bottomRowParts = randomIntegerInclusive(2, 6, {
      constraint: x => {
        switch (topRowUnit) {
          case 'cm':
            return x * bottomRowNumber > 10;
          case 'm':
            return x * bottomRowNumber > 100;
          case 'km':
          case 'kg':
          case 'l':
            return x * bottomRowNumber > 1000;
        }
      }
    });

    return { bottomRowNumber, bottomRowParts, topRowUnit };
  },

  Component: ({ question: { bottomRowNumber, bottomRowParts, topRowUnit }, translate }) => {
    const [bottomRowUnit, topRowUnitString, sentence] = (() => {
      switch (topRowUnit) {
        case 'cm':
          return ['mm', translate.units.stringCm('___'), translate.units.stringCm('<ans/>')];
        case 'm':
          return ['cm', translate.units.stringM('___'), translate.units.stringM('<ans/>')];
        case 'km':
          return ['m', translate.units.stringKm('___'), translate.units.stringKm('<ans/>')];
        case 'kg':
          return ['g', translate.units.stringKg('___'), translate.units.stringKg('<ans/>')];
        case 'l':
          return ['ml', translate.units.stringL('___'), translate.units.stringL('<ans/>')];
      }
    })() as ['mm' | 'cm' | 'm' | 'g' | 'ml', LocalizedString, LocalizedString];

    const answer = convertUnitsSuffix(
      bottomRowParts * bottomRowNumber,
      bottomRowUnit,
      topRowUnit
    ).value;

    const bottomBarArray = filledArray(
      convertUnitsSuffix(bottomRowNumber, bottomRowUnit, topRowUnit).value,
      bottomRowParts
    );
    const bottomBarArrayStrings = filledArray(
      `${bottomRowNumber} ${translate.units[bottomRowUnit]()}`,
      bottomRowParts
    );

    const numbers = [[answer], bottomBarArray];

    const strings = [[topRowUnitString], bottomBarArrayStrings];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutMissingValueInBarModel()}
        extraSymbol="decimalPoint"
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={5}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={answer}
            dimens={dimens}
            oneFontSize
            sameRowColor
          />
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aod',
  description: 'aod',
  keywords: ['Place value', 'Order', '10,000', 'Thousand', 'Table', 'Length'],
  schema: z.object({
    amountPerGlassInMl: numberEnum([
      100, 200, 250, 300, 400, 500, 600, 700, 750, 800, 900, 1000, 1200, 1500, 2000, 2400, 2500
    ]),
    numberOfGlasses: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const numberOfGlasses = randomIntegerInclusive(2, 12);

    const amountPerGlassInMl = getRandomFromArray([
      100, 200, 250, 300, 400, 500, 600, 700, 750, 800, 900, 1000, 1200, 1500, 2000, 2400, 2500
    ] as const);

    return { numberOfGlasses, amountPerGlassInMl };
  },

  Component: props => {
    const {
      question: { numberOfGlasses, amountPerGlassInMl },
      translate
    } = props;

    const amountPerGlassInL = convertUnitsSuffix(amountPerGlassInMl, 'ml', 'l').value;

    // Randomly decide which two of the four millilitre measures should display 'ml':
    const mlUnitItems = getRandomSubArrayFromArray([1, 2, 3, 4], 2, {
      random: seededRandom(amountPerGlassInMl)
    });

    // Randomly decide which two of the four litre measures should display 'l', with a different random seed to mlUnitItems:
    const lUnitItems = getRandomSubArrayFromArray([1, 2, 3, 4], 2, {
      random: seededRandom(numberOfGlasses)
    });

    const items = shuffle(
      [
        {
          sentence: lUnitItems.includes(1)
            ? translate.units.numberOfL(amountPerGlassInL)
            : translate.units.numberOfLitres(amountPerGlassInL),
          isCorrect: true
        },
        {
          sentence: lUnitItems.includes(2)
            ? translate.units.numberOfL(amountPerGlassInL * 10)
            : translate.units.numberOfLitres(amountPerGlassInL * 10),
          isCorrect: false
        },
        {
          sentence: lUnitItems.includes(3)
            ? translate.units.numberOfL(amountPerGlassInL * 100)
            : translate.units.numberOfLitres(amountPerGlassInL * 100),
          isCorrect: false
        },
        {
          sentence:
            amountPerGlassInMl < 1000
              ? lUnitItems.includes(4)
                ? translate.units.numberOfL(amountPerGlassInL * 1000)
                : translate.units.numberOfLitres(amountPerGlassInL * 1000)
              : lUnitItems.includes(4)
              ? translate.units.numberOfL(amountPerGlassInL / 10)
              : translate.units.numberOfLitres(amountPerGlassInL / 10),
          isCorrect: false
        },
        {
          sentence: mlUnitItems.includes(1)
            ? translate.units.numberOfMl(amountPerGlassInMl)
            : translate.units.numberOfMillilitres(amountPerGlassInMl),
          isCorrect: true
        },
        {
          sentence: mlUnitItems.includes(2)
            ? translate.units.numberOfMl(amountPerGlassInMl / 100)
            : translate.units.numberOfMillilitres(amountPerGlassInMl / 100),
          isCorrect: false
        },
        {
          sentence: mlUnitItems.includes(3)
            ? translate.units.numberOfMl(amountPerGlassInMl / 10)
            : translate.units.numberOfMillilitres(amountPerGlassInMl / 10),
          isCorrect: false
        },
        {
          sentence: mlUnitItems.includes(4)
            ? amountPerGlassInMl < 1000
              ? translate.units.numberOfMl(amountPerGlassInMl * 10)
              : translate.units.numberOfMl(amountPerGlassInMl / 1000)
            : amountPerGlassInMl < 1000
            ? translate.units.numberOfMillilitres(amountPerGlassInMl * 10)
            : translate.units.numberOfMillilitres(amountPerGlassInMl / 1000),
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.numLitresOfWaterIsSharedEqually(
          amountPerGlassInL * numberOfGlasses,
          numberOfGlasses
        )}
        pdfTitle={translate.instructions.numLitresOfWaterIsSharedEquallyPDF(
          amountPerGlassInL * numberOfGlasses,
          numberOfGlasses
        )}
        // use flat map to filter for correct answers and map to the index in one go
        testCorrect={items.flatMap((item, index) => (item.isCorrect ? [index] : []))}
        multiSelect
        items={items.map((item, index) => ({
          // value of each answer is just its index in the `items` array
          value: index,
          component: item.sentence
        }))}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'aoe',
  description: 'aoe',
  keywords: ['Metric', 'Convert', 'cm', 'm', 'km', 'kg', 'l'],
  schema: z.object({
    topAndBottomLeftUnit: z.enum(['cm', 'm', 'km', 'kg', 'l']),
    answerPosition: z.enum(['top', 'bottom left', 'bottom right']),
    topNumber: z.number().min(1.1).max(9.9).step(0.1),
    bottomRightNumber: z.number().int().min(1).max(2000)
  }),
  simpleGenerator: () => {
    const topAndBottomLeftUnit = getRandomFromArray(['cm', 'm', 'km', 'kg', 'l'] as const);

    const topNumber = randomIntegerInclusive(11, 99) / 10;

    const bottomRightNumber = (() => {
      switch (topAndBottomLeftUnit) {
        case 'kg':
        case 'km':
        case 'l':
          return randomIntegerInclusiveStep(100, 2000, 100, {
            constraint: x => x / 1000 < topNumber
          });
        case 'm':
          return randomIntegerInclusiveStep(10, 200, 10, {
            constraint: x => x / 100 < topNumber
          });
        case 'cm':
          return randomIntegerInclusive(1, 50, {
            constraint: x => x / 10 < topNumber
          });
      }
    })();

    const answerPosition = getRandomFromArray(['top', 'bottom left', 'bottom right'] as const);

    return { topAndBottomLeftUnit, answerPosition, topNumber, bottomRightNumber };
  },

  Component: ({
    question: { topAndBottomLeftUnit, answerPosition, topNumber, bottomRightNumber },
    translate
  }) => {
    const bottomRightUnit = (() => {
      switch (topAndBottomLeftUnit) {
        case 'cm':
          return 'mm';
        case 'm':
          return 'cm';
        case 'km':
          return 'm';
        case 'kg':
          return 'g';
        case 'l':
          return 'ml';
      }
    })();

    const bottomRightNumberConverted = convertUnitsSuffix(
      bottomRightNumber,
      bottomRightUnit,
      topAndBottomLeftUnit
    ).value;

    const [topUnitString, bottomLeftUnitString, bottomRightUnitString, sentence] = (() => {
      switch (topAndBottomLeftUnit) {
        case 'cm':
          return [
            answerPosition === 'top'
              ? translate.units.stringCm('___')
              : translate.units.numberOfCm(topNumber),
            answerPosition === 'bottom left'
              ? translate.units.stringCm('___')
              : translate.units.numberOfCm(topNumber - bottomRightNumberConverted),
            answerPosition === 'bottom right'
              ? translate.units.stringMm('___')
              : translate.units.numberOfMm(bottomRightNumber),
            answerPosition === 'bottom right'
              ? translate.units.stringMm('<ans/>')
              : translate.units.stringCm('<ans/>')
          ];
        case 'm':
          return [
            answerPosition === 'top'
              ? translate.units.stringM('___')
              : translate.units.numberOfM(topNumber),
            answerPosition === 'bottom left'
              ? translate.units.stringM('___')
              : translate.units.numberOfM(topNumber - bottomRightNumberConverted),
            answerPosition === 'bottom right'
              ? translate.units.stringCm('___')
              : translate.units.numberOfCm(bottomRightNumber),
            answerPosition === 'bottom right'
              ? translate.units.stringCm('<ans/>')
              : translate.units.stringM('<ans/>')
          ];
        case 'km':
          return [
            answerPosition === 'top'
              ? translate.units.stringKm('___')
              : translate.units.numberOfKm(topNumber),
            answerPosition === 'bottom left'
              ? translate.units.stringKm('___')
              : translate.units.numberOfKm(topNumber - bottomRightNumberConverted),
            answerPosition === 'bottom right'
              ? translate.units.stringM('___')
              : translate.units.numberOfM(bottomRightNumber),
            answerPosition === 'bottom right'
              ? translate.units.stringM('<ans/>')
              : translate.units.stringKm('<ans/>')
          ];
        case 'kg':
          return [
            answerPosition === 'top'
              ? translate.units.stringKg('___')
              : translate.units.numberOfKg(topNumber),
            answerPosition === 'bottom left'
              ? translate.units.stringKg('___')
              : translate.units.numberOfKg(topNumber - bottomRightNumberConverted),
            answerPosition === 'bottom right'
              ? translate.units.stringG('___')
              : translate.units.numberOfG(bottomRightNumber),
            answerPosition === 'bottom right'
              ? translate.units.stringG('<ans/>')
              : translate.units.stringKg('<ans/>')
          ];
        case 'l':
          return [
            answerPosition === 'top'
              ? translate.units.stringL('___')
              : translate.units.numberOfL(topNumber),
            answerPosition === 'bottom left'
              ? translate.units.stringL('___')
              : translate.units.numberOfL(topNumber - bottomRightNumberConverted),
            answerPosition === 'bottom right'
              ? translate.units.stringMl('___')
              : translate.units.numberOfMl(bottomRightNumber),
            answerPosition === 'bottom right'
              ? translate.units.stringMl('<ans/>')
              : translate.units.stringL('<ans/>')
          ];
      }
    })();

    const answer =
      answerPosition === 'top'
        ? topNumber
        : answerPosition === 'bottom left'
        ? topNumber - bottomRightNumberConverted
        : bottomRightNumber;

    const numbers = [
      [topNumber],
      [topNumber - bottomRightNumberConverted, bottomRightNumberConverted]
    ];

    const strings = [[topUnitString], [bottomLeftUnitString, bottomRightUnitString]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutMissingValueInBarModel()}
        extraSymbol="decimalPoint"
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={topNumber}
            dimens={dimens}
            oneFontSize
          />
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aof',
  description: 'aof',
  keywords: ['Metric', 'Convert', 'Centimetres', 'Metres', 'Kilometres', 'Kilograms', 'Litres'],
  schema: z.object({
    number1: z.number().min(1.1).max(12),
    number2: z.number().int().min(2).max(1900),
    unitToConvertFrom: z.enum(['km', 'kg', 'l', 'm', 'cm']),
    eq1OrEq2: z.enum(['eq1', 'eq2'])
  }),
  simpleGenerator: () => {
    const eq1OrEq2 = getRandomFromArray(['eq1', 'eq2'] as const);

    const unitToConvertFrom = getRandomFromArray(['km', 'kg', 'l', 'm', 'cm'] as const);

    const conversion = (() => {
      switch (unitToConvertFrom) {
        case 'km':
          return ['km', 'm', 1000];
        case 'kg':
          return ['kg', 'g', 1000];
        case 'l':
          return ['l', 'ml', 1000];
        case 'm':
          return ['m', 'cm', 100];
        case 'cm':
          return ['cm', 'mm', 10];
      }
    })() as [string, string, number];

    const { eq1A, eq1B } =
      eq1OrEq2 === 'eq1'
        ? rejectionSample(
            () => {
              const eq1A = randomNumberInclusive(1.1, 9.9, 1);
              let eq1B = 0;
              switch (conversion[2]) {
                case 1000: {
                  eq1B = roundToSignificantFigures(randomIntegerInclusive(100, 1900), 2);
                  break;
                }
                case 100: {
                  eq1B = randomIntegerInclusive(5, 199);
                  break;
                }
                default: {
                  eq1B = randomIntegerInclusive(5, 99);
                  break;
                }
              }
              return { eq1A, eq1B };
            },
            ({ eq1A, eq1B }) => findExchanges(eq1B, eq1A * conversion[2]).length === 1
          )
        : // If this equation is not selected, do not bother trying a rejectionSample, just assign these
          // - these will be discarded later:
          { eq1A: 1.1, eq1B: 5 };

    // If this equation is not selected, do not bother trying randomIntegerInclusive or rejectionSample, just assign these
    // - these will be discarded later:
    const eq2A = eq1OrEq2 === 'eq1' ? 2 : randomIntegerInclusive(2, 12);

    const eq2B =
      eq1OrEq2 === 'eq1'
        ? 2
        : rejectionSample(
            () => {
              switch (conversion[2]) {
                case 1000:
                  return roundToSignificantFigures(randomIntegerInclusive(50, 900), 1);
                case 100:
                  return roundToSignificantFigures(randomIntegerInclusive(5, 90), 1);
                default:
                  return randomIntegerInclusive(2, 9);
              }
            },
            eq2B => eq2B * eq2A > conversion[2]
          );

    const number1 = eq1OrEq2 === 'eq1' ? eq1A : eq2A;

    const number2 = eq1OrEq2 === 'eq1' ? eq1B : eq2B;

    return { number1, number2, unitToConvertFrom, eq1OrEq2 };
  },
  Component: ({
    question: { number1, number2, unitToConvertFrom, eq1OrEq2 },
    translate,
    displayMode
  }) => {
    const conversion = (() => {
      switch (unitToConvertFrom) {
        case 'km':
          return ['km', 'm', 1000];
        case 'kg':
          return ['kg', 'g', 1000];
        case 'l':
          return ['l', 'ml', 1000];
        case 'm':
          return ['m', 'cm', 100];
        case 'cm':
          return ['cm', 'mm', 10];
      }
    })() as [string, string, number];

    const ansA = eq1OrEq2 === 'eq1' ? number1 * conversion[2] + number2 : number1 * number2;

    const ansB = ansA / conversion[2];

    const stringA = (() => {
      switch (conversion[0]) {
        case 'km':
          return translate.units.numberOfKm(number1);
        case 'kg':
          return translate.units.numberOfKg(number1);
        case 'l':
          return translate.units.numberOfL(number1);
        case 'm':
          return translate.units.numberOfM(number1);
        case 'cm':
          return translate.units.numberOfCm(number1);
      }
    })();

    const stringB = (() => {
      switch (conversion[1]) {
        case 'm':
          return translate.units.numberOfM(number2);
        case 'g':
          return translate.units.numberOfG(number2);
        case 'ml':
          return translate.units.numberOfMl(number2);
        case 'cm':
          return translate.units.numberOfCm(number2);
        case 'mm':
          return translate.units.numberOfMm(number2);
      }
    })();

    const stringC = translate.units[conversion[1] as 'm' | 'g' | 'ml' | 'cm' | 'mm']();

    const stringD = translate.units[conversion[0] as 'km' | 'kg' | 'l' | 'm' | 'cm']();

    const sentence =
      eq1OrEq2 === 'eq1'
        ? `${stringA} ${ADD} ${stringB} = ${translate.answerSentences.ansUnitOrAnsUnit(
            stringC,
            stringD
          )}`
        : `${number1.toLocaleString()} ${MULT} ${stringB} = ${translate.answerSentences.ansUnitOrAnsUnit(
            stringC,
            stringD
          )}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        sentence={sentence}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], ansA) && compareFloats(userAnswer[1], ansB)
        }
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        textStyle={{ fontSize: displayMode !== 'digital' ? 50 : 32 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansA.toLocaleString(), ansB.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aog',
  description: 'aog',
  keywords: ['Metric', 'Convert', 'Millilitres', 'Litres'],
  schema: z.object({
    number: z.number().int().min(200).max(600),
    answer: z.number().int().min(10).max(100)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(200, 600, 100);
    const answer = randomIntegerInclusive(10, 100);

    return {
      number,
      answer
    };
  },
  Component: props => {
    const {
      question: { number, answer },
      translate
    } = props;

    const total = convertUnitsSuffix(answer * number, 'ml', 'l').value;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howManyUnits(total.toLocaleString(), number.toLocaleString())}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const constraints = {
  10: { sectionMin: 2, sectionMax: 9, step: 1 },
  100: { sectionMin: 15, sectionMax: 90, step: 5 },
  1000: { sectionMin: 150, sectionMax: 900, step: 50 }
};

const Question6 = newQuestionContent({
  uid: 'aoh',
  description: 'aoh',
  keywords: ['Metric', 'Convert', 'Centimetres', 'Metres', 'Kilometres', 'Kilograms', 'Litres'],
  schema: z
    .object({
      conversion: numberEnum([10, 100, 1000]),
      units: z.array(z.string()).length(2),
      whole: z.number().min(0.45).max(6),
      section: z.number().int(),
      denominator: z.number().int().min(3).max(6),
      numerator: z.number().int().min(2)
    })
    .refine(
      val =>
        val.section <= constraints[val.conversion].sectionMax &&
        val.section >= constraints[val.conversion].sectionMin &&
        val.section % constraints[val.conversion].step === 0 &&
        val.numerator < val.denominator
    ),

  simpleGenerator: () => {
    const unitConversions = [
      //[scale, [bigUnit, smallUnit]]
      [10, ['cm', 'mm']],
      [100, ['m', 'cm']],
      [1000, ['kg', 'g']],
      [1000, ['km', 'm']],
      [1000, ['g', 'mg']],
      [1000, ['l', 'ml']]
    ];
    const conversionType = getRandomFromArray(unitConversions as [[10 | 100 | 1000, string[]]]);

    const conversion = conversionType[0];
    const units = conversionType[1];

    const { denominator, numerator } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(3, 6);

        const numerator = randomIntegerInclusive(2, denominator - 1);

        return { denominator, numerator };
      },
      ({ denominator, numerator }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(numerator, denominator);

        // Only permit them if they cannot be simplified:
        return numerator === simplifiedNumerator && denominator === simplifiedDenominator;
      }
    );

    const section = randomIntegerInclusiveStep(
      constraints[conversion].sectionMin,
      constraints[conversion].sectionMax,
      constraints[conversion].step
    );

    const whole = (section * denominator) / conversion;

    return {
      conversion,
      units,
      whole,
      section,
      denominator,
      numerator
    };
  },
  Component: ({
    question: { conversion, units, whole, section, denominator, numerator },
    translate
  }) => {
    const unit1String = translate.units[units[0] as 'cm' | 'm' | 'kg' | 'km' | 'g' | 'l']();

    const unit2String = translate.units[units[1] as 'mm' | 'cm' | 'g' | 'm' | 'mg' | 'ml']();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToCompleteStatement()}
        sentence={translate.answerSentences.xOfNumYIsAnsZOrAnsY(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`,
          whole,
          unit1String,
          unit2String
        )}
        Content={({ dimens }) => (
          <BarModel
            numbers={[[whole * conversion], Array.from({ length: denominator }, () => section)]}
            strings={[
              [`${whole} ${unit1String}`],
              Array.from({ length: denominator }, () => `${section} ${unit2String}`)
            ]}
            total={whole * conversion}
            sameRowColor
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], numerator * section) &&
          compareFloats(userAnswer[1], (numerator * section) / conversion)
        }
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        fractionTextStyle={{ fontSize: 32 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (numerator * section).toLocaleString(),
            ((numerator * section) / conversion).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateWithMetricMeasures',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
