import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';

////
// Questions
////

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwentyThirtyFortyAndFifty',
  questionTypes: []
});
export default SmallStep;
