import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD } from '../../../../constants';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQQ',
  description: 'aQQ',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimals'],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val =>
        [val.tenths, val.hundredths, val.thousandths].filter(number => number === 0).length <= 1,
      "should only be one or less 0's generated"
    ),
  simpleGenerator: () => {
    const { tenths, hundredths, thousandths } = rejectionSample(
      () => {
        const tenths = randomIntegerInclusive(0, 9);
        const hundredths = randomIntegerInclusive(0, 9);
        const thousandths = randomIntegerInclusive(0, 9);

        return { tenths, hundredths, thousandths };
      },
      // Only permit if one or less 0 is generated
      ({ tenths, hundredths, thousandths }) =>
        [tenths, hundredths, thousandths].filter(number => number === 0).length <= 1
    );

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths }, translate }) => {
    const total = number(
      math.evaluate(`((${thousandths} / 1000 ) + (${hundredths} / 100) + (${tenths} / 10 ))`)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[0, -1, -2, -3]}
            headerVariant="numberTitle"
            counterVariant={'decimalCounter'}
            dimens={dimens}
          />
        )}
        inputMaxCharacters={5}
        textStyle={{ fontSize: 28 }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentence={translate.answerSentences.ansOnesAnsTenthsAnsHundredthsAnsThousandths()}
        testCorrect={userAnswer =>
          userAnswer[0] === '0' &&
          userAnswer[1] === tenths.toString() &&
          userAnswer[2] === hundredths.toString() &&
          userAnswer[3] === thousandths.toString() &&
          compareFloats(userAnswer[4], total.toString())
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (0).toLocaleString(),
            tenths.toLocaleString(),
            hundredths.toLocaleString(),
            thousandths.toLocaleString(),
            total.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1V2 = newQuestionContent({
  uid: 'aQQ2',
  description: 'aQQ',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimals'],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(6),
      hundredths: z.number().int().min(0).max(6),
      thousandths: z.number().int().min(0).max(6)
    })
    .refine(
      val =>
        [val.tenths, val.hundredths, val.thousandths].filter(number => number === 0).length <= 1,
      "should only be one or less 0's generated"
    ),
  simpleGenerator: () => {
    const { tenths, hundredths, thousandths } = rejectionSample(
      () => {
        const tenths = randomIntegerInclusive(0, 6);
        const hundredths = randomIntegerInclusive(0, 6);
        const thousandths = randomIntegerInclusive(0, 6);

        return { tenths, hundredths, thousandths };
      },
      // Only permit if one or less 0 is generated
      ({ tenths, hundredths, thousandths }) =>
        [tenths, hundredths, thousandths].filter(number => number === 0).length <= 1
    );

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths }, translate, displayMode }) => {
    const total = number(
      math.evaluate(`((${thousandths} / 1000 ) + (${hundredths} / 100) + (${tenths} / 10 ))`)
    );

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[0, -1, -2, -3]}
            headerVariant="numberTitle"
            counterVariant={'decimalCounter'}
            dimens={dimens}
            rowsToUse={2}
          />
        )}
        inputMaxCharacters={5}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        sentences={[
          translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredthsThousandthsPlurals(
            tenths,
            hundredths,
            thousandths
          ),
          translate.answerSentences.theNumberIsAns()
        ]}
        testCorrect={userAnswer =>
          userAnswer[0][0] === '0' &&
          userAnswer[0][1] === tenths.toString() &&
          userAnswer[0][2] === hundredths.toString() &&
          userAnswer[0][3] === thousandths.toString() &&
          compareFloats(userAnswer[1][0], total.toString())
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              (0).toLocaleString(),
              tenths.toLocaleString(),
              hundredths.toLocaleString(),
              thousandths.toLocaleString()
            ],
            [total.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aQR',
  description: 'aQR',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimals'],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val =>
        [val.tenths, val.hundredths, val.thousandths].filter(number => number === 0).length <= 1,
      "should only be one or less 0's generated"
    ),
  simpleGenerator: () => {
    const { tenths, hundredths, thousandths } = rejectionSample(
      () => {
        const tenths = randomIntegerInclusive(0, 9);
        const hundredths = randomIntegerInclusive(0, 9);
        const thousandths = randomIntegerInclusive(0, 9);

        return { tenths, hundredths, thousandths };
      },
      // Only permit if one or less 0 is generated
      ({ tenths, hundredths, thousandths }) =>
        [tenths, hundredths, thousandths].filter(number => number === 0).length <= 1
    );

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths }, translate }) => {
    const total = number(
      math.evaluate(`((${thousandths} / 1000 ) + (${hundredths} / 100) + (${tenths} / 10 ))`)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatNumberIsShownOnThePlaceValueChart()}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number(total))}
            columnsToShow={[0, -1, -2, -3]}
            headerVariant="numberTitle"
            counterVariant={'decimalCounter'}
            dimens={dimens}
          />
        )}
        inputMaxCharacters={5}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentence={'<ans/>'}
        testCorrect={userAnswer => compareFloats(userAnswer[0], total.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [total.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aQS',
  description: 'aQS',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimals'],
  schema: z
    .object({
      ones: z.number().int().min(0).max(9),
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val =>
        [val.ones, val.tenths, val.hundredths, val.thousandths].filter(number => number === 0)
          .length <= 1,
      "should only be one or less 0's generated"
    ),
  simpleGenerator: () => {
    const { ones, tenths, hundredths, thousandths } = rejectionSample(
      () => {
        const ones = randomIntegerInclusive(0, 9);
        const tenths = randomIntegerInclusive(0, 9);
        const hundredths = randomIntegerInclusive(0, 9);
        const thousandths = randomIntegerInclusive(0, 9);

        return { ones, tenths, hundredths, thousandths };
      },
      // Only permit if one or less 0 is generated
      ({ ones, tenths, hundredths, thousandths }) =>
        [ones, tenths, hundredths, thousandths].filter(number => number === 0).length <= 1
    );

    return {
      ones,
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths, ones }, translate }) => {
    const total = number(
      math.evaluate(
        `((${thousandths} / 1000 ) + (${hundredths} / 100) + (${tenths} / 10 )) + ${ones}`
      )
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatNumberIsShownOnThePlaceValueChart()}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[0, -1, -2, -3]}
            headerVariant="numberTitle"
            counterVariant={'decimalCounter'}
            dimens={dimens}
          />
        )}
        inputMaxCharacters={5}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentence={'<ans/>'}
        testCorrect={userAnswer => compareFloats(userAnswer[0], total.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [total.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aQT',
  description: 'aQT',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimals'],
  schema: z.object({
    ones: z.number().int().min(0).max(8),
    tenths: z.number().int().min(0).max(8),
    hundredths: z.number().int().min(0).max(8),
    thousandths: z.number().int().min(0).max(8)
  }),
  simpleGenerator: () => {
    const [ones, tenths, hundredths, thousandths] = randomUniqueIntegersInclusive(0, 8, 4);

    return {
      ones,
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: props => {
    const {
      question: { ones, tenths, hundredths, thousandths },
      translate,
      displayMode
    } = props;
    const answer = number(
      `${ones.toString()}.${tenths.toString()}${hundredths.toString()}${thousandths.toString()}`
    );

    const items = shuffle(
      [
        answer,
        number(
          `${ones.toString()}.${tenths.toString()}${thousandths.toString()}${hundredths.toString()}`
        ),
        number(
          `${ones.toString()}.${hundredths.toString()}${tenths.toString()}${thousandths.toString()}`
        ),
        number(
          `${ones.toString()}.${thousandths.toString()}${hundredths.toString()}${tenths.toString()}`
        )
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartThatRepresentsX(answer.toLocaleString())}
        pdfTitle={translate.instructions.circleWhichPlaceValueChartRepresentsX(
          answer.toLocaleString()
        )}
        numItems={4}
        testCorrect={[answer]}
        renderItems={({ dimens }) => {
          return items.map(number => ({
            value: number,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(number)}
                columnsToShow={[0, -1, -2, -3]}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                headerVariant="numberTitle"
                counterSize={displayMode === 'digital' ? undefined : 55}
                counterVariant={'greyCounter'}
              />
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'aQU',
  description: 'aQU',
  keywords: ['Thousandths', 'Hundredths', 'Tenths', 'Ones', 'Place value chart'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9),
    thousandths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(0, 9);
    const hundredths = randomIntegerInclusive(0, 9);
    const thousandths = randomIntegerInclusive(1, 9);

    return { ones, tenths, hundredths, thousandths };
  },
  Component: ({ question: { ones, tenths, hundredths, thousandths }, translate }) => {
    const total = number(
      math.evaluate(
        `((${thousandths} / 1000 ) + (${hundredths} / 100) + (${tenths} / 10 )) + ${ones}`
      )
    );

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToMakeNum(total)}
        pdfTitle={translate.instructions.drawCountersToMakeNum(total)}
        number={ScientificNotation.fromNumber(total)}
        columnsToShow={[0, -1, -2, -3]}
        counterVariant="greyCounter"
        headerVariant="numberTitle"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aQV',
  description: 'aQV',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Thousandths', 'Equivalent'],
  schema: z
    .object({
      totalA: z.number().min(1).max(9.999),
      totalB: z.number().min(1).max(9.999),
      onesA: z.number().min(1).max(9),
      onesB: z.number().min(1).max(9),
      tenthsA: z.number().min(1).max(9),
      tenthsB: z.number().min(1).max(9),
      hundredthsA: z.number().min(1).max(9),
      hundredthsB: z.number().min(1).max(9),
      thousandthsA: z.number().min(1).max(9),
      thousandthsB: z.number().min(1).max(9),
      answerBoxIndexA: z.number().min(0).max(3),
      answerBoxIndexB: z.number().min(0).max(3)
    })
    .refine(val => val.totalA !== val.totalB, 'totalA and totalB should not be the same number'),
  simpleGenerator: () => {
    const {
      totalA,
      totalB,
      onesA,
      onesB,
      tenthsA,
      tenthsB,
      hundredthsA,
      hundredthsB,
      thousandthsA,
      thousandthsB
    } = rejectionSample(
      () => {
        const onesA = randomIntegerInclusive(1, 9);
        const onesB = randomIntegerInclusive(1, 9);
        const tenthsA = randomIntegerInclusive(1, 9);
        const tenthsB = randomIntegerInclusive(1, 9);
        const hundredthsA = randomIntegerInclusive(1, 9);
        const hundredthsB = randomIntegerInclusive(1, 9);
        const thousandthsA = randomIntegerInclusive(1, 9);
        const thousandthsB = randomIntegerInclusive(1, 9);

        const totalA = number(
          math.evaluate(
            `((${thousandthsA} / 1000 ) + (${hundredthsA} / 100) + (${tenthsA} / 10 )) + ${onesA}`
          )
        );

        const totalB = number(
          math.evaluate(
            `((${thousandthsB} / 1000 ) + (${hundredthsB} / 100) + (${tenthsB} / 10 )) + ${onesB}`
          )
        );

        return {
          totalA,
          totalB,
          onesA,
          onesB,
          tenthsA,
          tenthsB,
          hundredthsA,
          hundredthsB,
          thousandthsA,
          thousandthsB
        };
      },
      // Only permit them if totalA doesnt equal totalB
      ({ totalA, totalB }) => totalA !== totalB
    );

    const answerBoxIndexA = randomIntegerInclusive(0, 3);
    const answerBoxIndexB = randomIntegerInclusive(0, 3);

    return {
      totalA,
      totalB,
      onesA,
      onesB,
      tenthsA,
      tenthsB,
      hundredthsA,
      hundredthsB,
      thousandthsA,
      thousandthsB,
      answerBoxIndexA,
      answerBoxIndexB
    };
  },

  Component: props => {
    const {
      question: {
        totalA,
        totalB,
        onesA,
        onesB,
        tenthsA,
        tenthsB,
        hundredthsA,
        hundredthsB,
        thousandthsA,
        thousandthsB,
        answerBoxIndexA,
        answerBoxIndexB
      },
      translate
    } = props;

    const shuffledValuesA = shuffle(
      [
        onesA,
        math.evaluate(`${tenthsA} / 10 `),
        math.evaluate(`${hundredthsA} / 100 `),
        math.evaluate(`${thousandthsA} / 1000 `)
      ],
      {
        random: seededRandom(props.question)
      }
    );
    const shuffledValuesB = shuffle(
      [
        onesB,
        math.evaluate(`${tenthsB} / 10 `),
        math.evaluate(`${hundredthsB} / 100 `),
        math.evaluate(`${thousandthsB} / 1000 `)
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const buildAnswers = (numbers: number[], ansIndex: number) => {
      return numbers
        .reduce((acc: string, num: number, index: number) => {
          if (index === ansIndex) {
            return acc + `<ans/> ${ADD} `;
          } else {
            return acc + `${num.toLocaleString()} ${ADD} `;
          }
        }, '')
        .slice(0, -3);
    };

    const sentenceA = `${totalA} = ${buildAnswers(shuffledValuesA, answerBoxIndexA)}`;
    const sentenceB = `${totalB} = ${buildAnswers(shuffledValuesB, answerBoxIndexB)}`;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], shuffledValuesA[answerBoxIndexA]) &&
          compareFloats(userAnswer[1][0], shuffledValuesB[answerBoxIndexB])
        }
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        containerStyle={{ alignItems: 'center' }}
        actionPanelVariant="bottomTall"
        sentences={[sentenceA, sentenceB]}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationOfNumeratorsThatSumToXAndY(
            totalA,
            totalB
          )
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ThousandthsOnAPlaceValueChart',
  questionTypes: [Question1V2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
