import { ModalCard } from 'common/src/components/modals';
import { useTheme } from 'common/src/theme';
import Text from 'common/src/components/typography/Text';
import { Linking, View } from 'react-native';
import Button from 'common/src/components/atoms/Button';

export default function ConsentModal({
  onDecline,
  onAccept
}: {
  onDecline: () => void;
  onAccept: () => void;
}) {
  const theme = useTheme();

  const cookiePolicyURL = 'https://whiteroseeducation.com/cookies-policy';

  return (
    <ModalCard
      containerStyle={{ alignItems: 'center', rowGap: 8, height: 450, width: 708 }}
      style={{ zIndex: 999 }}
    >
      <Text variant="WRN700">Cookie Consent</Text>
      <Text variant="WRN400" style={{ textAlign: 'left', fontSize: 20, lineHeight: 20 }}>
        {`We use cookies to help provide a better website experience for you, and help us to understand how people use our website. Our partners will also collect data and use cookies for ad personalisation and measurement.\n\nClicking "Accept" will allow us and our partners to use cookies, learn more in our `}

        <Text
          variant="WRN400"
          style={{
            textAlign: 'left',
            fontSize: 20,
            lineHeight: 20,
            color: theme.colors.secondaryContainer,
            textDecorationLine: 'underline'
          }}
          onPress={() => Linking.openURL(cookiePolicyURL)}
        >
          cookie policy
        </Text>
        <Text variant="WRN400" style={{ textAlign: 'left', fontSize: 20, lineHeight: 20 }}>
          .
        </Text>
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'flex-end', columnGap: 40, marginTop: 32 }}>
        {/* Accept All */}
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={onAccept}
          style={{ width: 270, height: 96 }}
        >
          Accept
        </Button>
        {/* Decline Button */}
        <Button
          baseColor={theme.colors.background}
          onPress={onDecline}
          style={{ width: 270, height: 96 }}
        >
          Decline
        </Button>
      </View>
    </ModalCard>
  );
}
