import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { numberEnum } from 'common/src/utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import { MULT, DIV } from 'common/src/constants';
import QF28FunctionMachines from 'common/src/components/question/questionFormats/QF28FunctionMachines';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'and',
  description: 'and',
  keywords: ['Multiply', 'Function machine', 'Multiples', '10'],
  schema: z.object({
    multiplier: z.number().int().min(20).max(90).step(10),
    input: z.number().int().min(3).max(99)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusiveStep(20, 90, 10);
    const input =
      multiplier === 20
        ? randomIntegerInclusive(13, 99, { constraint: x => x % 10 !== 0 })
        : multiplier <= 50
        ? randomIntegerInclusive(8, 19, { constraint: x => x % 10 !== 0 })
        : multiplier <= 80
        ? randomIntegerInclusive(3, 12, { constraint: x => x % 10 !== 0 })
        : randomIntegerInclusive(3, 9);

    return { multiplier, input };
  },
  Component: props => {
    const {
      question: { multiplier, input },
      translate
    } = props;

    const number3 = multiplier / 10;
    const number4 = input * number3;
    const output = input * multiplier;

    return (
      <QF28FunctionMachines
        title={translate.instructions.completeFunctionMachineToWorkOut(
          `${input.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        rowsOfBoxes={[
          [
            `${input.toLocaleString()}`,
            `${MULT} ${number3.toLocaleString()}`,
            `<ans/>`,
            `${MULT} ${(10).toLocaleString()}`,
            `<ans/>`
          ]
        ]}
        testCorrect={[[number4.toString(), output.toString()]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ane',
  description: 'ane',
  keywords: ['Multiply', 'Multiples', '10s', '100s', '1,000s'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: numberEnum([1, 10, 100]),
    number4: z.number().int().min(2).max(9),
    number5: numberEnum([10, 100, 1000]),
    options: z.number().int().array().length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = getRandomFromArray([1, 10, 100] as const);
    const number4 = randomIntegerInclusive(2, 9);
    const number5 = getRandomFromArray([10, 100, 1000] as const);

    const number6 = number4 * number5;

    const allOptions = [number6 + number5, number6 - number5];
    if (number5 !== 1000) {
      const array = [number6 * 10, (number5 + number6) * 10, (number6 - number5) * 10];
      allOptions.push(...array);
    }

    if (number5 !== 10) {
      const array = [number6 / 10, (number5 + number6) / 10, (number6 - number5) / 10];
      allOptions.push(...array);
    }

    if (number4 < 8) {
      allOptions.push(number6 + 2 * number5);
    }

    if (number4 > 3) {
      allOptions.push(number6 - 2 * number5);
    }

    if (!(number4 >= 8 || number5 === 1000)) {
      allOptions.push((number6 + 2 * number5) * 10);
    }

    if (!(number4 <= 3 || number5 === 1000)) {
      allOptions.push((number6 - 2 * number5) * 10);
    }

    if (!(number4 >= 8 || number5 === 10)) {
      allOptions.push((number6 + 2 * number5) / 10);
    }

    if (!(number4 <= 3 || number5 === 10)) {
      allOptions.push((number6 - 2 * number5) / 10);
    }

    const options = shuffle([number6, ...getRandomSubArrayFromArray(allOptions, 3)]);

    return { number1, number2, number4, number5, options };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5, options },
      translate
    } = props;

    const number3 = number1 * number2;
    const number6 = number4 * number5;
    const number7 = number3 * number6;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteCalculation()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculation()}
        items={options}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentence={`${number7.toLocaleString()} = ${number3.toLocaleString()} ${MULT} <ans/>`}
        testCorrect={[number6]}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'anf',
  description: 'anf',
  keywords: ['Multiply', 'Multiples', '10', '100', '1,000'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(9),
    numberA2Digit: z.number().int().min(1).max(9),
    numberA2Power: z.number().int().min(1).max(3),
    numberB1: z.number().int().min(20).max(90).multipleOf(10),
    numberB2Digit: z.number().int().min(1).max(9),
    numberB2Power: z.number().int().min(1).max(3),
    numberC1: z.number().int().min(200).max(900).multipleOf(100),
    numberC2Digit: z.number().int().min(1).max(9),
    numberC2Power: z.number().int().min(1).max(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    // Question A
    const numberA1 = randomIntegerInclusive(2, 9);

    const numberA2Digit = randomIntegerInclusive(1, 9);

    const numberA2Power = randomIntegerInclusive(1, 3);

    // Question B
    const numberB1 = randomIntegerInclusiveStep(20, 90, 10);

    const numberB2Digit = randomIntegerInclusive(1, 9);

    const numberB2Power = randomIntegerInclusive(1, 3);

    // Question C
    const numberC1 = randomIntegerInclusiveStep(200, 900, 100);

    const numberC2Digit = randomIntegerInclusive(1, 9);

    const numberC2Power = randomIntegerInclusive(1, 3);

    return {
      numberA1,
      numberA2Digit,
      numberA2Power,
      numberB1,
      numberB2Digit,
      numberB2Power,
      numberC1,
      numberC2Digit,
      numberC2Power
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2Digit,
        numberA2Power,
        numberB1,
        numberB2Digit,
        numberB2Power,
        numberC1,
        numberC2Digit,
        numberC2Power
      },
      translate
    } = props;

    const numberA2 = numberA2Digit * Math.pow(10, numberA2Power);
    const numberB2 = numberB2Digit * Math.pow(10, numberB2Power);
    const numberC2 = numberC2Digit * Math.pow(10, numberC2Power);

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: numberC1,
      right: numberC2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ang',
  description: 'ang',
  keywords: ['Divide', 'Function machine', 'Multiples', '10'],
  schema: z.object({
    divisor: z.number().int().min(20).max(90).step(10),
    output: z.number().int().min(3).max(99)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusiveStep(20, 90, 10);
    const output =
      divisor === 20
        ? randomIntegerInclusive(13, 99, { constraint: x => x % 10 !== 0 })
        : divisor <= 50
        ? randomIntegerInclusive(7, 19, { constraint: x => x % 10 !== 0 })
        : divisor <= 80
        ? randomIntegerInclusive(3, 12, { constraint: x => x % 10 !== 0 })
        : randomIntegerInclusive(3, 9);

    return { divisor, output };
  },
  Component: props => {
    const {
      question: { divisor, output },
      translate
    } = props;

    const number3 = divisor / 10;
    const number4 = output * number3;
    const input = output * divisor;

    return (
      <QF28FunctionMachines
        title={translate.instructions.completeFunctionMachineToWorkOut(
          `${input.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        rowsOfBoxes={[
          [
            `${input.toLocaleString()}`,
            `${DIV} ${(10).toLocaleString()}`,
            `<ans/>`,
            `${DIV} ${number3.toLocaleString()}`,
            `<ans/>`
          ]
        ]}
        testCorrect={[[number4.toString(), output.toString()]]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'anh',
  description: 'anh',
  keywords: ['Multiply', 'Multiples', '10s', '100s', '1,000s'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: numberEnum([1, 10, 100]),
    number4: z.number().int().min(2).max(9),
    number5: numberEnum([10, 100, 1000]),
    options: z.number().int().array().length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = getRandomFromArray([1, 10, 100] as const);
    const number4 = randomIntegerInclusive(2, 9);
    const number5 = getRandomFromArray([10, 100, 1000] as const);

    const number6 = number4 * number5;

    const allOptions = [number6 + number5, number6 - number5];
    if (number5 !== 1000) {
      const array = [number6 * 10, (number5 + number6) * 10, (number6 - number5) * 10];
      allOptions.push(...array);
    }

    if (number5 !== 10) {
      const array = [number6 / 10, (number5 + number6) / 10, (number6 - number5) / 10];
      allOptions.push(...array);
    }

    if (number4 < 8) {
      allOptions.push(number6 + 2 * number5);
    }

    if (number4 > 3) {
      allOptions.push(number6 - 2 * number5);
    }

    if (!(number4 >= 8 || number5 === 1000)) {
      allOptions.push((number6 + 2 * number5) * 10);
    }

    if (!(number4 <= 3 || number5 === 1000)) {
      allOptions.push((number6 - 2 * number5) * 10);
    }

    if (!(number4 >= 8 || number5 === 10)) {
      allOptions.push((number6 + 2 * number5) / 10);
    }

    if (!(number4 <= 3 || number5 === 10)) {
      allOptions.push((number6 - 2 * number5) / 10);
    }

    const options = shuffle([number6, ...getRandomSubArrayFromArray(allOptions, 3)]);

    return { number1, number2, number4, number5, options };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5, options },
      translate
    } = props;

    const number3 = number1 * number2;
    const number6 = number4 * number5;
    const number7 = number3 * number6;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteCalculation()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculation()}
        items={options}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentence={`${number3.toLocaleString()} = ${number7.toLocaleString()} ${DIV} <ans/>`}
        testCorrect={[number6]}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ani',
  description: 'ani',
  keywords: ['Multiply', 'Multiples', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(9),
      numberA2: z.number().int().min(1).max(9000),
      numberB1: z.number().int().min(2).max(9),
      numberB2: z.number().int().min(1).max(9000),
      numberC1: z.number().int().min(20).max(90).multipleOf(10),
      numberC2: z.number().int().min(2).max(9000),
      numberD1: z.number().int().min(200).max(900).multipleOf(100),
      numberD2: z.number().int().min(2).max(9000)
    })
    .refine(val => val.numberA1 !== val.numberB1, 'numberA1 and numberB1 must be different.')
    .refine(
      val =>
        val.numberA1 * val.numberA2 < 1000000 &&
        val.numberB1 * val.numberB2 < 1000000 &&
        val.numberC1 * val.numberC2 < 1000000 &&
        val.numberD1 * val.numberD2 < 1000000
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    // Question A
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(2, 9);
        const numberA2Digit = randomIntegerInclusive(1, 9);
        const numberA2Power = randomIntegerInclusive(1, 3);
        const numberA2 = numberA2Digit * Math.pow(10, numberA2Power);

        return { numberA1, numberA2 };
      },
      val => val.numberA1 * val.numberA2 < 1000000
    );

    // Question B
    const { numberB1, numberB2 } = rejectionSample(
      () => {
        const numberB1 = randomIntegerInclusive(2, 9, {
          constraint: x => x !== numberA1
        });
        const numberB2Digit = randomIntegerInclusive(1, 9);
        const numberB2Power = randomIntegerInclusive(1, 3);
        const numberB2 = numberB2Digit * Math.pow(10, numberB2Power);

        return { numberB1, numberB2 };
      },
      val => val.numberB1 * val.numberB2 < 1000000
    );

    // Question C
    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusiveStep(20, 90, 10);
        const numberC2Digit = randomIntegerInclusive(1, 9);
        const numberC2Power = randomIntegerInclusive(1, 3);
        const numberC2 = numberC2Digit * Math.pow(10, numberC2Power);

        return { numberC1, numberC2 };
      },
      val => val.numberC1 * val.numberC2 < 1000000
    );

    // Question D
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusiveStep(200, 900, 100);
        const numberD2Digit = randomIntegerInclusive(1, 9);
        const numberD2Power = randomIntegerInclusive(1, 3);
        const numberD2 = numberD2Digit * Math.pow(10, numberD2Power);

        return { numberD1, numberD2 };
      },
      val => val.numberD1 * val.numberD2 < 1000000
    );

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA1,
      sign: 'divide',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'divide',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      right: numberC2,
      result: numberC1,
      sign: 'divide',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      right: numberD2,
      result: numberD1,
      sign: 'divide',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplesOf10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
