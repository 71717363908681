import { useTheme } from 'common/src/theme';
import { View, StyleSheet } from 'react-native';
import { useMemo } from 'react';
import { MINIMUM_QUESTION_HEIGHT, QUESTION_WIDTH } from 'common/src/theme/scaling';

/**
 * 1280x720 view showing just the background card, which is common to all questions.
 */
export default function QuizCard() {
  const styles = useStyles();

  return <View style={styles.container} />;
}

function useStyles() {
  const theme = useTheme();
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          position: 'absolute',
          backgroundColor: theme.colors.background,
          borderColor: theme.colors.onBackground,
          borderRadius: 24,
          borderWidth: 3,
          height: MINIMUM_QUESTION_HEIGHT - 2 * 16,
          width: QUESTION_WIDTH - 2 * 12,
          marginVertical: 16,
          marginHorizontal: 12
        }
      }),
    [theme.colors.background, theme.colors.onBackground]
  );
}
