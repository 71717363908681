import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getItemArrangement,
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdW',
  description: 'bdW',
  keywords: ['Times-tables', 'Count', 'Multiplication', 'Ten frame'],
  schema: z.object({
    number: z.number().int().min(10).max(50).step(10),
    orientation: z.enum(['horizontal', 'vertical']),
    color: z.enum(['red', 'yellow', 'blue', 'green'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10, 50, 10);
    const orientation = getRandomFromArray(['horizontal', 'vertical'] as const);
    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    return { number, orientation, color };
  },
  Component: props => {
    const {
      question: { number, orientation, color },
      translate,
      displayMode
    } = props;

    const numberOfTenFrames = number / 10;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyCounters()}
        Content={
          <View
            style={{
              maxWidth: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              columnGap: 40,
              rowGap: 25
            }}
          >
            {countRange(numberOfTenFrames).map(i => (
              <TenFrameLayout
                key={i}
                items={filledArray(color, 10)}
                itemOrdering={orientation === 'horizontal' ? 'columnFirst' : 'rowFirst'}
                size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                orientation={orientation}
              />
            ))}
          </View>
        }
        pdfDirection="column"
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bdX',
  description: 'bdX',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', 'Times-tables'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    numberOfBoxes: numberEnum([5, 6]),
    answerIndexes: z.array(z.number().min(2).max(5)),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);
    const numberOfBoxes = startingNumber === 10 ? 5 : getRandomFromArray([5, 6] as const);
    const numberOfAns = randomIntegerInclusive(2, numberOfBoxes - 3);
    const answerIndexes = randomUniqueIntegersInclusive(2, numberOfBoxes - 1, numberOfAns);
    const isForward = getRandomBoolean();

    return { startingNumber, numberOfBoxes, answerIndexes, isForward };
  },
  Component: ({
    question: { startingNumber, numberOfBoxes, answerIndexes, isForward },
    translate
  }) => {
    // Create array to pass to NumberTrack
    const ascendingNumberArray = countRange(numberOfBoxes).map(i => startingNumber + i * 10);

    const numberArray = isForward ? ascendingNumberArray : ascendingNumberArray.reverse();

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bdY',
  description: 'bdY',
  keywords: ['Times-tables', 'Count', 'Multiplication'],
  schema: z.object({
    multiplier: z.number().int().min(1).max(5),
    item: z.enum(['Balloons', 'Marbles', 'Pencils', 'Sweets']),
    answers: z.array(z.number().min(1).max(50)),
    ordered: z.boolean()
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(1, 5);
    const item = getRandomFromArray(['Balloons', 'Marbles', 'Pencils', 'Sweets'] as const);
    const ordered = multiplier === 1 ? true : getRandomBoolean();

    const answers = rejectionSample(
      () => [
        multiplier,
        multiplier * 10,
        getRandomBoolean() ? (multiplier - 1) * 10 : (multiplier + 1) * 10
      ],
      val => arrayHasNoDuplicates(val) && val.every(i => i > 0 && i < 51)
    );

    return { multiplier, item, answers: shuffle(answers), ordered };
  },
  Component: props => {
    const {
      question: { multiplier, item, answers, ordered },
      translate
    } = props;

    const random = seededRandom(props.question);

    let svgName: SvgName;
    switch (item) {
      case 'Balloons':
        svgName = 'Base_Ten/Balloons10';
        break;
      case 'Pencils':
        svgName = 'pencil_packs/Pack_of_pencils_10';
        break;
      case 'Marbles':
        svgName = 'Base_Ten/Marbles10';
        break;
      case 'Sweets':
        svgName = 'Base_Ten/Sweets10';
        break;
    }

    const arrangement =
      multiplier === 1
        ? []
        : getItemArrangement(
            countRange(multiplier).map(() => svgName),
            random,
            2,
            5
          );

    const sentenceLookup: Record<
      string,
      | 'thereAreAnsBalloonsAltogether'
      | 'thereAreAnsMarblesAltogether'
      | 'thereAreAnsPencilsAltogether'
      | 'thereAreAnsSweetsAltogether'
    > = {
      Balloons: 'thereAreAnsBalloonsAltogether',
      Marbles: 'thereAreAnsMarblesAltogether',
      Pencils: 'thereAreAnsPencilsAltogether',
      Sweets: 'thereAreAnsSweetsAltogether'
    };

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        sentence={translate.ks1AnswerSentences[sentenceLookup[item]]()}
        sentencesStyle={{ alignItems: 'flex-start' }}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        testCorrect={[multiplier * 10]}
        Content={({ dimens }) =>
          ordered ? (
            <View style={{ flexDirection: 'row', gap: 25 }}>
              {countRange(multiplier).map(i => (
                <AssetSvg
                  key={i}
                  name={svgName}
                  width={dimens.width / 7}
                  height={dimens.height / 3}
                />
              ))}
            </View>
          ) : (
            <View style={{ rowGap: 20 }}>
              {arrangement.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: 'row',
                    height: dimens.height / 3,
                    width: dimens.width
                  }}
                >
                  {row.map(({ marginLeft }, itemIndex) => (
                    <View key={itemIndex} style={{ marginLeft, gap: 16, justifyContent: 'center' }}>
                      <AssetSvg
                        name={svgName}
                        width={dimens.width / 7}
                        height={dimens.height / 3}
                      />
                    </View>
                  ))}
                </View>
              ))}
            </View>
          )
        }
        items={answers.map(value => ({ value, component: <Text variant="WRN700">{value}</Text> }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountIn10s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
