import { z } from 'zod';

function stringUnionType<U extends string, T extends Readonly<[U, ...U[]]>>(values: T) {
  return [values, z.enum(values), (x: string): x is T[number] => values.includes(x as U)] as const;
}

// Base 10 representation variants which have assets up to at least 1,000
export const [
  Base10RepTo1000sVariantArray,
  Base10RepTo1000sVariantSchema,
  isBase10RepTo1000sVariant
] = stringUnionType(['Counters', 'Cubes', 'Nails']);
export type Base10RepTo1000sVariant = (typeof Base10RepTo1000sVariantArray)[number];

// Base 10 representation variants which have assets up to at least 100
export const [Base10RepTo100sVariantArray, Base10RepTo100sVariantSchema, isBase10RepTo100sVariant] =
  stringUnionType([
    ...Base10RepTo1000sVariantArray,
    'Balloons',
    'Bricks',
    'Lollipops',
    'Marbles',
    'Sweets',
    'Crayons'
  ]);
export type Base10RepTo100sVariant = (typeof Base10RepTo100sVariantArray)[number];

// Base 10 representation variants which have assets up to at least 10
export const [Base10RepTo10sVariantArray, Base10RepTo10sVariantSchema, isBase10RepTo10sVariant] =
  stringUnionType([...Base10RepTo100sVariantArray, 'Straws', 'Blue pens', 'Red pens']);
export type Base10RepTo10sVariant = (typeof Base10RepTo10sVariantArray)[number];

export const Base10RepresentationArrangementArray = [
  'ltr',
  'rtl',
  'ltrJiggled',
  'jumbled'
] as const;
export const Base10RepresentationArrangementSchema = z.enum(Base10RepresentationArrangementArray);
export type Base10RepresentationArrangement = z.infer<typeof Base10RepresentationArrangementSchema>;

/** A representation of a number using a base 10 manipulative */
export const Base10RepresentationSchema = z.object({
  /** how many of each place-value, starting with ones and ascending */
  numbers: z.object({
    ones: z.number().int().min(0).optional(),
    tens: z.number().int().min(0).optional(),
    hundreds: z.number().int().min(0).optional(),
    thousands: z.number().int().min(0).optional(),
    tenThousands: z.number().int().min(0).optional(),
    hundredThousands: z.number().int().min(0).optional()
  }),
  variant: Base10RepTo10sVariantSchema,
  arrangement: Base10RepresentationArrangementSchema,
  correct: z.boolean().optional()
});
export type Base10RepresentationInfo = z.infer<typeof Base10RepresentationSchema>;

export const BlockArray = ['Transport', 'Fruit', 'Colour'] as const;
export const BlockSchema = z.enum(BlockArray);
export type Block = z.infer<typeof BlockSchema>;

export const CounterVariantNoBlockArray = [
  'Decimal Counter',
  'Fraction Counter',
  'Grey Counter',
  'Number'
] as const;
export const CounterVariantArray = [...CounterVariantNoBlockArray, 'Base-10 Block'] as const;

export const CounterVariantNoBlockAccess = [
  'decimalCounter',
  'fractionCounter',
  'greyCounter',
  'number'
] as const;
export const CounterVariantAccess = [...CounterVariantNoBlockAccess, 'base10Block'] as const;

export const CounterVariantSchema = z.enum(CounterVariantAccess);
export const CounterVariantNoBlockSchema = z.enum(CounterVariantNoBlockAccess);
export const CounterVariantHRSchema = z.enum(CounterVariantArray);
export const CounterVariantNoBlockHRSchema = z.enum(CounterVariantNoBlockArray);
export type CounterVariant = z.infer<typeof CounterVariantSchema>;
export type CounterVariantNoBlock = z.infer<typeof CounterVariantNoBlockSchema>;

export const BarDataSchema = z.object({
  color: z.string().optional(),
  value: z.number().int().min(0),
  name: z.string()
});
export type BarData = z.infer<typeof BarDataSchema>;

export const ChartConfigSchema = z.object({
  max: z.number().int(),
  min: z.number().int(),
  step: z.number().int(),
  title: z.string(),
  block: BlockSchema,
  barDataCollection: z.array(BarDataSchema).min(2)
});
export type ChartConfig = z.infer<typeof ChartConfigSchema>;
