import { SvgName } from '../assets/svg';
import { TranslationFunctions } from '../i18n/i18n-types';
import { getRandomFromArray, seededRandom } from './random';

const amountArray = [
  100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000
] as const;
export type Amount = (typeof amountArray)[number];

export const orangeJuiceSvg = (
  amount:
    | 'eighth'
    | 'empty'
    | 'full'
    | 'half'
    | 'quarter'
    | 'sevenEighths'
    | 'third'
    | 'threeQuarters'
    | 'twoThirds',
  glassType: 1 | 2
): { svgName: SvgName; value: number } => {
  if (glassType === 1) {
    switch (amount) {
      case 'eighth':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_eighth_full', value: 0.125 };
      case 'empty':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_empty', value: 0 };
      case 'full':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_full', value: 1 };
      case 'half':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_half_full', value: 0.5 };
      case 'quarter':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_quarter_full', value: 0.25 };
      case 'sevenEighths':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_seven_eighths_full', value: 0.625 };
      case 'third':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_third_full', value: 1 / 3 };
      case 'threeQuarters':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_three_quarters_full', value: 0.75 };
      case 'twoThirds':
        return { svgName: 'Glasses_of_orange_juice/glass1_oj_two_thirds_full', value: 2 / 3 };
    }
  } else {
    switch (amount) {
      case 'eighth':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_eighth_full', value: 0.125 };
      case 'empty':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_empty', value: 0 };
      case 'full':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_full', value: 1 };
      case 'half':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_half_full', value: 0.5 };
      case 'quarter':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_quarter_full', value: 0.25 };
      case 'sevenEighths':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_seven_eighths_full', value: 0.625 };
      case 'third':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_third_full', value: 1 / 3 };
      case 'threeQuarters':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_three_quarters_full', value: 0.75 };
      case 'twoThirds':
        return { svgName: 'Glasses_of_orange_juice/glass2_oj_two_thirds_full', value: 2 / 3 };
    }
  }
};

export const highVolumeItemsArray = ['SwimmingPool', 'Pond', 'Bath', 'Barrel', 'Bucket'] as const;
export type highVolumeItem = (typeof highVolumeItemsArray)[number];

export const lowVolumeItemsArray = [
  'FishTank',
  'Tablespoon',
  'Mug',
  'Kettle',
  'Glass',
  'Jug',
  'Beaker',
  'WaterBottle',
  'MilkCarton',
  'JuiceCarton'
] as const;
export type lowVolumeItem = (typeof lowVolumeItemsArray)[number];

export const capacityObjectAsWord = (
  object: highVolumeItem | lowVolumeItem,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'SwimmingPool':
      return translate.objects.SwimmingPool();
    case 'Pond':
      return translate.objects.Pond();
    case 'Bath':
      return translate.objects.Bath();
    case 'Barrel':
      return translate.objects.Barrel();
    case 'Bucket':
      return translate.objects.Bucket();
    case 'FishTank':
      return translate.objects.FishTank();
    case 'Tablespoon':
      return translate.objects.Tablespoon();
    case 'Mug':
      return translate.objects.Mug();
    case 'Kettle':
      return translate.objects.Kettle();
    case 'Glass':
      return translate.objects.Glass();
    case 'Jug':
      return translate.objects.Jug();
    case 'Beaker':
      return translate.objects.Beaker();
    case 'WaterBottle':
      return translate.objects.WaterBottle();
    case 'MilkCarton':
      return translate.objects.MilkCarton();
    case 'JuiceCarton':
      return translate.objects.JuiceCarton();
  }
};

export const highVolumeImages: { svgName: SvgName; object: highVolumeItem }[] = [
  { object: 'SwimmingPool', svgName: 'full_swimming_pool' },
  { object: 'Pond', svgName: 'Pond' },
  { object: 'Bath', svgName: 'Bath' },
  { object: 'Barrel', svgName: 'Wooden_barrel' },
  { object: 'Bucket', svgName: 'Full_bucket' }
];

export const lowVolumeImages: { svgName: SvgName; object: lowVolumeItem }[] = [
  { object: 'FishTank', svgName: 'Goldfish_in_a_tank/Goldfish_in_a_tank_0' },
  { object: 'Tablespoon', svgName: 'tablespoon' },
  { object: 'Mug', svgName: 'Mug' },
  { object: 'Kettle', svgName: 'Kettle' },
  { object: 'Glass', svgName: 'Glasses_of_orange_juice/glass1_oj_three_quarters_full' },
  { object: 'Jug', svgName: 'Capacity_images/Mostly_full_water_pitcher' },
  { object: 'Beaker', svgName: 'Capacity_images/Full_beaker_1litrecap_100mlint' },
  { object: 'WaterBottle', svgName: 'Water_bottle' },
  { object: 'MilkCarton', svgName: 'Milk_Carton' },
  { object: 'JuiceCarton', svgName: 'Orange_juice_carton' }
];

export const jugPropertiesNonTransparent: {
  svgName: SvgName;
  capacity: number;
  intervals: number;
}[] = [
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_200mlcap_25mlint',
    capacity: 200,
    intervals: 8
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_200mlcap_50mlint',
    capacity: 200,
    intervals: 4
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_200mlcap_20mlint',
    capacity: 200,
    intervals: 10
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_200mlcap_100mlint',
    capacity: 200,
    intervals: 2
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_500mlcap_50mlint',
    capacity: 500,
    intervals: 10
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_500mlcap_100mlint',
    capacity: 500,
    intervals: 5
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_500mlcap_250mlint',
    capacity: 500,
    intervals: 2
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_1000mlcap_100mlint',
    capacity: 1000,
    intervals: 10
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_1000mlcap_200mlint',
    capacity: 1000,
    intervals: 5
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_1000mlcap_250mlint',
    capacity: 1000,
    intervals: 4
  },
  {
    svgName: 'Capacity_images/None_Transparent/Jug1_1000mlcap_500mlint',
    capacity: 1000,
    intervals: 2
  }
];

export const getNonTransparentJugByCapacity = (
  capacity: 200 | 500 | 1000,
  seed?: string | number | object
): { svgName: SvgName; capacity: number; intervals: number } => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;
  const result = jugPropertiesNonTransparent.filter(val => val.capacity === capacity);
  return getRandomFromArray([...result] as const, seeded) as {
    svgName: SvgName;
    capacity: number;
    intervals: number;
  };
};
