import { mod } from 'mathjs';
import deepEqual from 'react-fast-compare';
import { z } from 'zod';

import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from 'common/src/utils/random';
import { convert12hToSpokenString } from '../../../../utils/time';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { NonEmptyArray, arrayHasNoDuplicates, range } from '../../../../utils/collections';
import Clock, { Time12hSchema } from '../../../../components/question/representations/Clock';

import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import Text from '../../../../components/typography/Text';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atI',
  description: 'atI',
  keywords: ['Time', '5-minute intervals', 'To the hour', 'Past the hour', 'Analogue'],
  schema: z
    .object({
      minutesToShow: z.number().int().min(0).max(59),
      options: z
        .number()
        .int()
        .min(0)
        .max(59)
        .array()
        .length(3)
        .refine(arrayHasNoDuplicates, 'All minutes must be different.')
    })
    .refine(
      ({ minutesToShow, options }) => options.includes(minutesToShow),
      'options must include the correct answer'
    ),
  simpleGenerator: () => {
    const options = randomUniqueIntegersInclusiveStep(0, 55, 5, 3) as NonEmptyArray<number>;
    return { minutesToShow: getRandomFromArray(options), options };
  },
  Component: ({ question: { minutesToShow, options }, translate }) => {
    const handPointsTo = minutesToShow === 0 ? 12 : minutesToShow / 5;
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectWhatTimeWouldBeShownIfMinuteHand(handPointsTo)}
        pdfTitle={translate.instructions.circleTimeWouldBeShownIfMinuteHandWasPointingToX(
          handPointsTo
        )}
        testCorrect={[minutesToShow]}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: 1, minutes: minutesToShow }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            minuteHandVariant="hand"
            hourHandVariant="absent"
          />
        )}
        renderItems={options.map(minutes => ({
          value: minutes,
          component: (
            <Text variant="WRN700">{convert12hToSpokenString(translate, null, minutes)}</Text>
          )
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'atJ',
  description: 'atJ',
  keywords: ['Roman numerals', 'Time', 'Quarter past', 'Quarter to', 'Analogue'],
  schema: z
    .object({
      hoursToShow: z.number().int().min(0).max(11),
      options: z
        .number()
        .int()
        .min(0)
        .max(11)
        .array()
        .length(3)
        .refine(arrayHasNoDuplicates, 'All hours must be different.'),
      minutes: z.number().int().min(0).max(59),
      useRomanNumerals: z.boolean()
    })
    .refine(
      ({ hoursToShow, options }) => options.includes(hoursToShow),
      'options must include the correct answer'
    ),
  simpleGenerator: () => {
    const options = randomUniqueIntegersInclusive(0, 11, 3) as NonEmptyArray<number>;

    const minutes = getRandomFromArray([15, 45] as const);

    const useRomanNumerals = getRandomBoolean();

    return { hoursToShow: getRandomFromArray(options), options, minutes, useRomanNumerals };
  },
  Component: ({ question: { hoursToShow, options, minutes, useRomanNumerals }, translate }) => {
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTimeShownOnClock()}
        testCorrect={[hoursToShow]}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hoursToShow, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman={useRomanNumerals}
          />
        )}
        renderItems={options.map(hours => ({
          value: hours,
          component: (
            <Text variant="WRN700">{convert12hToSpokenString(translate, hours, minutes)}</Text>
          )
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'atK',
  description: 'atK',
  keywords: [
    'Roman numerals',
    'Time',
    '5-minute intervals',
    'Minutes past',
    'Minutes to',
    'Analogue'
  ],
  schema: z
    .object({
      timeToShow: Time12hSchema,
      useRomanNumerals: z.boolean(),
      options: z
        .object({
          hours: z.number().int().min(0).max(11),
          minutes: z.number().int().min(0).max(59)
        })
        .array()
        .length(3)
        .refine(
          options => arrayHasNoDuplicates(options, deepEqual),
          'Must not have duplicate options'
        )
    })
    .refine(
      ({ timeToShow, options }) =>
        options.filter(option => deepEqual(option, timeToShow)).length === 1,
      'options must contain exactly one correct answer'
    ),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const minutes = randomIntegerInclusiveStep(5, 55, 5, {
      constraint: x => x % 15 !== 0
    });
    const useRomanNumerals = getRandomBoolean();

    // For this question, there's three different ways they can be wrong, which we want to test:
    // a. mix up "to" and "from"
    //   - 5 minutes past 1 => 5 minutes to 1
    //   - 25 minutes to 11 => 25 minutes past 11
    // b. pick the wrong hour
    //   - 5 minutes past 1 => 5 minutes past 2
    //   - 25 minutes to 11 => 25 minutes to 10
    // c. a combination of the two
    //   - 5 minutes past 1 => 5 minutes to 2
    //   - 25 minutes to 11 => 25 minutes past 10
    //
    // If the correct answer is "minutes past", then we go with (a) and (c).
    // If the correct answer is "minutes to", then we go with (a) and (b).
    let options = [{ hours, minutes }];
    if (minutes <= 30) {
      // E.g. 5 minutes past 1, i.e. 1:05
      options.push({ hours: mod(hours - 1, 12), minutes: 60 - minutes }); // a, 5 minutes to 1, i.e. 12:55
      options.push({ hours: hours, minutes: 60 - minutes }); // c, 5 minutes to 2, i.e. 1:55
    } else {
      // E.g. 25 minutes to 11, i.e. 10:35
      options.push({ hours: mod(hours + 1, 12), minutes: 60 - minutes }); // a, 25 minutes past 11, i.e. 11:25
      options.push({ hours: mod(hours - 1, 12), minutes: minutes }); // b, 25 minutes to 10, i.e. 9:35
    }
    options = shuffle(options);

    return { timeToShow: { hours, minutes }, useRomanNumerals, options };
  },
  Component: ({ question: { timeToShow, useRomanNumerals, options }, translate }) => {
    const correctIndex = options.findIndex(option => deepEqual(option, timeToShow));

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTimeShownOnClock()}
        testCorrect={[correctIndex]}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={timeToShow}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman={useRomanNumerals}
          />
        )}
        renderItems={options.map(({ hours, minutes }, index) => ({
          value: index,
          component: (
            <Text variant="WRN700">{convert12hToSpokenString(translate, hours, minutes)}</Text>
          )
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'atL',
  description: 'atL',
  keywords: [
    'Roman numerals',
    'Time',
    '5-minute intervals',
    'Minutes past',
    'Minutes to',
    'Analogue'
  ],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: z.number().int().min(5).max(55).multipleOf(5),
    isRoman: z.boolean()
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const minutes = randomIntegerInclusiveStep(5, 55, 5, { constraint: x => x % 15 !== 0 });
    const isRoman = getRandomFromArray([true, false] as const);

    return {
      hours,
      minutes,
      isRoman
    };
  },
  Component: props => {
    const {
      question: { hours, minutes, isRoman },
      translate
    } = props;

    const pastOrTo = minutes > 30 ? 'to' : 'past';
    const answerMinutes = minutes > 30 ? 60 - minutes : minutes;
    const answerHours = minutes > 30 ? hours + 1 : hours === 0 ? 12 : hours;
    const sentence =
      pastOrTo === 'past'
        ? translate.answerSentences.ansMinutesPastHours()
        : translate.answerSentences.ansMinutesToHours();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatTimeIsShownOnClock()}
        sentence={sentence}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours, minutes: minutes }}
            width={Math.min(dimens.width * 0.5, dimens.height)}
            interactive={false}
            isRoman={isRoman}
          />
        )}
        testCorrect={[answerMinutes.toString(), answerHours.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'atM',
  description: 'atM',
  keywords: ['Time', '5-minute intervals', 'Minutes past', 'Minutes to', 'Analogue'],
  schema: z.object({
    minutes: z.number().int().min(5).max(55).multipleOf(5),
    hours: z.number().int().min(0).max(11)
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusiveStep(5, 55, 5, { constraint: x => x % 15 !== 0 });
    const hours = randomIntegerInclusive(0, 11);

    return { minutes, hours };
  },
  Component: ({ question: { hours, minutes }, translate }) => {
    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.instructions.moveTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.instructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atN',
  description: 'atN',
  keywords: ['Time', '5-minute intervals', 'Minutes to', 'Minutes past', 'Analogue'],
  schema: z.object({
    options: z
      .object({
        hours: z.number().int().min(0).max(11),
        minutes: z.number().int().min(0).max(59)
      })
      .array()
      .length(6)
      .refine(
        options => arrayHasNoDuplicates(options, deepEqual),
        'There must not be any duplicate times'
      )
      .refine(
        options =>
          options.filter(option => option.minutes === 0 && option.hours % 2 === 0).length > 0,
        'There must be at least one correct time'
      )
  }),
  simpleGenerator: () => {
    const [hoursC, hoursD] = randomUniqueIntegersInclusiveStep(0, 10, 2, 2);

    const [hoursA, hoursB, hoursF] = range(1, 3).map(() => randomIntegerInclusiveStep(0, 10, 2));

    const hoursE = randomIntegerInclusiveStep(1, 11, 2);

    const minutesA = randomIntegerInclusiveStep(10, 20, 10);

    const minutesF = randomIntegerInclusiveStep(5, 25, 5, {
      constraint: x => x !== 15
    });

    const options = shuffle([
      { hours: hoursA, minutes: minutesA },
      { hours: hoursB, minutes: 30 },
      { hours: hoursC, minutes: 0 },
      { hours: hoursD, minutes: 0 },
      { hours: hoursE, minutes: 15 },
      { hours: mod(hoursF + 1, 12), minutes: 60 - minutesF }
    ]);

    return { options };
  },
  Component: ({ question: { options }, translate }) => {
    return (
      <QF10SelectNumbers
        title={translate.instructions.theMinuteHandAndTheHourHandOfAClockBothPointToEven()}
        // Answer is correct if the minutes is 0 and the hours is even
        // We use flatMap as a combined filter and map
        testCorrect={options.flatMap((option, index) =>
          option.minutes === 0 && option.hours % 2 === 0 ? [index] : []
        )}
        multiSelect
        items={options.map(({ hours, minutes }, index) => ({
          value: index,
          component: convert12hToSpokenString(translate, hours, minutes)
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTheTimeTo5Minutes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
