import { useContext } from 'react';
import { colors } from '../../theme/colors';
import { DisplayMode } from '../../contexts/displayMode';
import Svg, { Line } from 'react-native-svg';
import { View } from 'react-native';

type Props = {
  height: number;
  width: number;
};

export default function Brace({ height, width }: Props) {
  const displayMode = useContext(DisplayMode);

  const edgeOffset = displayMode === 'digital' ? 1 : 2;

  return (
    <View style={{ height, width }}>
      <Svg height="100%" width="100%" viewBox={`0 0 ${width} ${height}`}>
        <Line
          x1="0"
          x2={width / 2}
          y1={edgeOffset}
          y2={edgeOffset}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          stroke={colors.prussianBlue}
          strokeLinecap="round"
        />
        <Line
          x1="0"
          x2={width / 2}
          y1={height - edgeOffset}
          y2={height - edgeOffset}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          stroke={colors.prussianBlue}
          strokeLinecap="round"
        />
        <Line
          x1={width / 2}
          x2={width / 2}
          y1={edgeOffset}
          y2={height - edgeOffset}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          stroke={colors.prussianBlue}
          strokeLinecap="round"
        />
        <Line
          x1={width / 2}
          x2={width}
          y1={height / 2}
          y2={height / 2}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          stroke={colors.prussianBlue}
          strokeLinecap="round"
        />
      </Svg>
    </View>
  );
}
