import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { countRange, filledArray, range, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF15CreateBaseTenNumber from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { integerToWord, numberToBase10Object, ScientificNotation } from '../../../../utils/math';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { chunk } from '../../../../utils/chunk';
import { CubeTower } from '../../../../components/question/representations/CubeTower';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfM',
  description: 'bfM',
  keywords: ['Ten frames', 'Counters', 'Rekenrek', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(2).max(20),
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('TenFrame'),
        itemOrdering: z.enum(['columnFirst', 'rowFirst']),
        colour: z.enum(['red', 'green', 'blue', 'yellow'])
      }),
      z.object({
        variant: z.literal('Cubes'),
        cubesRotation: numberEnum([0, 180])
      }),
      z.object({
        variant: z.literal('Blocks'),
        colour: z.enum(['red', 'green', 'blue', 'yellow', 'purple', 'orange'])
      }),
      z.object({
        variant: z.literal('Rekenrek')
      })
    ])
  }),
  simpleGenerator: () => {
    const number = getRandomFromArrayWithWeights(
      [randomIntegerInclusive(10, 20), randomIntegerInclusive(2, 9)],
      [3, 1]
    );
    const variation = getRandomFromArray(['Cubes', 'Rekenrek', 'Blocks', 'TenFrame'] as const);

    const subQuestion =
      variation === 'Rekenrek'
        ? { variant: variation }
        : variation === 'Blocks'
        ? {
            variant: variation,
            colour: getRandomFromArray([
              'red',
              'green',
              'blue',
              'yellow',
              'purple',
              'orange'
            ] as const)
          }
        : variation === 'Cubes'
        ? { variant: variation, cubesRotation: getRandomFromArray([0, 180] as const) }
        : {
            variant: variation,
            itemOrdering: getRandomFromArray(['columnFirst', 'rowFirst'] as const),
            colour: getRandomFromArray(['red', 'green', 'blue', 'yellow'] as const)
          };

    return { number, subQuestion };
  },
  Component: ({ question: { number, subQuestion }, translate }) => {
    const baseTen = numberToBase10Object(number);

    const chunks = chunk(countRange(number), 10);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatNumberIsShown()}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => {
          return subQuestion.variant === 'Rekenrek' ? (
            <Rekenrek numberShown={number} rows={2} dimens={dimens} />
          ) : subQuestion.variant === 'Blocks' ? (
            <View
              style={{
                height: dimens.height,
                width: dimens.width,
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                {chunks.map((arr, index) => (
                  <CubeTower
                    key={index}
                    color={subQuestion.colour}
                    count={arr.length}
                    dimens={{
                      height:
                        chunks.length === 1 ? dimens.height : (dimens.height / 8) * arr.length,
                      width: chunks.length === 1 ? dimens.width : dimens.width / 10
                    }}
                  />
                ))}
              </View>
            </View>
          ) : subQuestion.variant === 'TenFrame' ? (
            chunks.map((arr, index) => (
              <View key={index} style={{ marginBottom: 12 }}>
                <TenFrameLayout
                  items={filledArray(subQuestion.colour, arr.length)}
                  itemOrdering={subQuestion.itemOrdering}
                />
              </View>
            ))
          ) : (
            <View style={{ transform: [{ rotate: `${subQuestion.cubesRotation}deg` }] }}>
              <SimpleBaseTenWithCrossOut
                ones={baseTen.ones}
                tens={baseTen.tens}
                dimens={{ width: dimens.width, height: dimens.height }}
              />
            </View>
          );
        }}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bfN',
  description: 'bfN',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numberArray: z.number().int().min(0).max(20).array().length(8),
    missingIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const startingNumber = randomIntegerInclusive(0, 13);

    const numberArray = sortNumberArray(range(startingNumber, startingNumber + 7, 1), ordering);

    const missingIndices = rejectionSample(
      () => {
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(3, 5)));
      },
      // Check at least two consecutive numbers
      x => numberArray.some((_, i) => !x.includes(i) && !x.includes(i + 1))
    );

    return { numberArray, missingIndices };
  },
  Component: ({ question: { numberArray, missingIndices }, translate }) => {
    const answerArray = missingIndices.map(x => numberArray[x].toString());
    const boxValues = numberArray.map((x, i) =>
      missingIndices.indexOf(i) === -1 ? x.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeTheNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bfO',
  description: 'bfO',
  keywords: ['Base 10', 'Ten frame', 'Counters'],
  schema: z.object({
    number: z.number().int().min(2).max(20),
    numOrWord: z.enum(['numeral', 'word']),
    isBase10: z.boolean(),
    counterVariant: z.enum(['red', 'blue', 'green', 'grey', 'yellow'])
  }),
  simpleGenerator: () => {
    const number = getRandomFromArrayWithWeights(
      [randomIntegerInclusive(10, 20), randomIntegerInclusive(2, 9)] as const,
      [3, 1]
    );

    const numOrWord = getRandomFromArray(['numeral', 'word'] as const);

    const isBase10 = getRandomBoolean();

    const counterVariant = getRandomFromArray(['red', 'blue', 'green', 'grey', 'yellow'] as const);

    return { number, numOrWord, isBase10, counterVariant };
  },

  Component: props => {
    const {
      question: { number, numOrWord, isBase10, counterVariant },
      translate,
      displayMode
    } = props;

    const titleNumber = numOrWord === 'numeral' ? number : `${integerToWord(number)}.`;

    const base10 = () => (
      <QF15CreateBaseTenNumber
        title={translate.ks1Instructions.dragTheBase10ToMakeX(titleNumber)}
        pdfTitle={translate.ks1PDFInstructions.drawBase10ToShowX(titleNumber)}
        number={ScientificNotation.fromNumber(number)}
        draggablesToShow={number < 10 ? [0] : [1, 0]}
        questionHeight={900}
      />
    );

    const markSchemeAnswer = [
      filledArray(counterVariant, Math.min(number, 10)),
      filledArray(counterVariant, Math.max(number - 10, 0))
    ];

    const tenFrame = () => (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToMakeX(titleNumber.toLocaleString())}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToShowX(titleNumber.toLocaleString())}
        items={[counterVariant]}
        numberOfTenFrames={number < 10 ? 1 : 2}
        exampleCorrectAnswer={markSchemeAnswer}
        tenFrameFlexDirection={displayMode === 'digital' ? 'column' : 'row'}
        testCorrect={totalCountersIs(number)}
      />
    );

    return isBase10 ? base10() : tenFrame();
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo20',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
