import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { AssetSvg } from '../../../../assets/svg';
import {
  countRange,
  filledArray,
  sortNumberArray,
  arrayHasNoDuplicates
} from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import {
  getCounterArrangementScale,
  getUniqueArrangements
} from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import { getGroupSvgName } from '../../../../utils/objectsImages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biD',
  description: 'biD',
  keywords: ['Equal', 'Unequal', 'Groups'],
  schema: z.object({
    options: z
      .object({
        item: z.enum(['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters']),
        groups: z.number().int().min(2).max(5),
        wrongGroups: z.number().int(),
        inEachGroup: z.number().int().min(2).max(5),
        isAdd: z.boolean(),
        randomSeed: z.number(),
        isDots: z.boolean()
      })
      .array()
      .length(3),
    dotsArrangements: z.array(z.array(z.array(z.boolean()))).length(2),
    dotsArrangementsIndex: z.array(z.number()),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const inEach = randomIntegerInclusive(2, 5);
    const dotGroups = randomIntegerInclusive(2, 5);
    const dotOption = {
      item: 'Counters' as const,
      groups: dotGroups,
      wrongGroups: 0,
      inEachGroup: inEach,
      isAdd: getRandomBoolean(),
      randomSeed: randomIntegerInclusive(1, 10) * Math.random() * 10000,
      isDots: true
    };
    const dotsArrangements = getUniqueArrangements(inEach, 2) as boolean[][][];
    const numberOfArrangement1 = randomIntegerInclusive(1, dotGroups - 1);
    const dotsArrangementsIndex = randomUniqueIntegersInclusive(
      0,
      dotGroups - 1,
      numberOfArrangement1
    );
    const items = getRandomSubArrayFromArray(
      ['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters'] as const,
      2
    );
    const otherOptions = countRange(2).map(i => {
      const groups = randomIntegerInclusive(2, 5);
      const eachGroup = randomIntegerInclusive(2, 5);
      return {
        item: items[i],
        groups,
        wrongGroups: randomIntegerInclusive(0, groups - 1),
        inEachGroup: eachGroup,
        isAdd: eachGroup === 5 ? false : getRandomBoolean(),
        randomSeed: Math.random() * 10000,
        isDots: false
      };
    });
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    return {
      dotsArrangements,
      dotsArrangementsIndex,
      options: shuffle([dotOption, ...otherOptions]),
      seed
    };
  },
  Component: props => {
    const {
      question: { options, dotsArrangements, dotsArrangementsIndex, seed },
      translate,
      displayMode
    } = props;
    const random = seededRandom(seed);
    const arrays = options.map(arr => ({
      correctAnswer: arr.wrongGroups === 0 ? 'equalGroups' : 'unequalGroups',
      item: arr.item,
      array: shuffle(
        [
          ...filledArray(arr.inEachGroup + (arr.isAdd ? 1 : -1), arr.wrongGroups),
          ...filledArray(arr.inEachGroup, arr.groups - arr.wrongGroups)
        ],
        { random }
      ),
      seed: arr.isDots
        ? shuffle([arr.randomSeed, ...filledArray(1, arr.inEachGroup - 1)], {
            random
          })
        : filledArray(arr.randomSeed, arr.groups),
      isDots: arr.isDots
    }));
    const dimens = {
      width: displayMode === 'digital' ? 400 : 500,
      height: displayMode === 'digital' ? 120 : 300
    };
    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToShowIfGroupsAreEqualOrUnequal()}
        pdfTitle={translate.ks1PDFInstructions.writeWhetherTheGroupsAreEqualOrUnequal()}
        statements={arrays.map((obj, i) => {
          const sort = sortNumberArray(obj.array, 'descending');
          const scale = Math.max(
            getCounterArrangementScale(sort[0], true),
            getCounterArrangementScale(sort[obj.array.length - 1], true)
          );
          const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const, {
            random: seededRandom(obj.seed)
          });
          return {
            lhsComponent: (
              <View
                style={{
                  width: dimens.width,
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                {obj.array.map((val, idx) =>
                  obj.item === 'Counters' ? (
                    <CounterBoxArrangement
                      key={`${idx}`}
                      counters={val}
                      color={color}
                      dimens={{ height: dimens.width / 6, width: dimens.width / 6 }}
                      isCircle
                      isSimpleArrangements
                      seed={obj.seed[idx]}
                      scale={scale}
                      arrangement={
                        obj.isDots
                          ? dotsArrangementsIndex.includes(idx)
                            ? dotsArrangements[0]
                            : dotsArrangements[1]
                          : undefined
                      }
                    />
                  ) : (
                    <AssetSvg
                      key={`${i}_${idx}`}
                      name={getGroupSvgName(obj.item, val)}
                      height={dimens.height}
                      width={dimens.width / 6}
                    />
                  )
                )}
              </View>
            ),
            correctAnswer: obj.correctAnswer
          };
        })}
        questionHeight={1000}
        pdfLayout="itemsTop"
        moveOrCopy="copy"
        items={(['equalGroups', 'unequalGroups'] as const).map(value => ({
          value,
          component: (
            <Text variant="WRN700" style={{ fontSize: 38 }}>
              {translate.ks1MiscStrings[value]()}
            </Text>
          )
        }))}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biE',
  description: 'biE',
  keywords: ['Equal', 'Groups', 'Counters'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    inEachGroup: z.number().int().min(2).max(6),
    sameLayout: z.boolean(),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    rotation: numberEnum([90, 180, 270, 0]).array(),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);
    const inEachGroup = randomIntegerInclusive(2, 6);
    const sameLayout = getRandomBoolean();
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation =
      inEachGroup > 3
        ? sameLayout
          ? // if all same layout then have the same rotation
            filledArray(getRandomFromArray([90, 180, 270, 0] as const), groups)
          : countRange(groups).map(() => getRandomFromArray([90, 180, 270, 0] as const))
        : filledArray(0 as const, groups);

    return { groups, inEachGroup, sameLayout, seed, color, rotation };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, sameLayout, seed, color, rotation },
      translate
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: groups > 2 ? 'flex-start' : 'center',
                gap: 20
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: dimens.height * 0.45, width: dimens.width * 0.31 }}
                  seed={sameLayout ? seed : idx * Math.random() * 10000}
                  rotation={rotation[idx]}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        testCorrect={[groups.toString(), inEachGroup.toString()]}
        pdfDirection="column"
        questionHeight={1000}
        sentence={translate.ks1AnswerSentences.thereAreAnsEqualGroupsOfAns()}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'biF',
  description: 'biF',
  keywords: ['Equal', 'Groups', 'Counters', 'Altogether'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      inEachGroup: z.number().int().min(2).max(6),
      sameLayout: z.boolean(),
      color: z.enum(['blue', 'green', 'red', 'yellow']),
      rotation: numberEnum([90, 180, 270, 0]).array(),
      seed: z.number(),
      options: z.number().array().length(4)
    })
    .refine(val => arrayHasNoDuplicates(val.options), 'no duplicate options'),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);
    const inEachGroup = randomIntegerInclusive(2, 6);
    const sameLayout = getRandomBoolean();
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation =
      inEachGroup > 3
        ? sameLayout
          ? // if all same layout then have the same rotation
            filledArray(getRandomFromArray([90, 180, 270, 0] as const), groups)
          : countRange(groups).map(() => getRandomFromArray([90, 180, 270, 0] as const))
        : filledArray(0 as const, groups);

    const correctOptions = [groups, inEachGroup, groups * inEachGroup];
    const uniqueCorrectOptions = [...new Set(correctOptions)];

    const uniqueIncorrectOptions = [
      ...new Set([
        groups + 1,
        groups - 1,
        inEachGroup + 1,
        inEachGroup - 1,
        (groups + 1) * inEachGroup,
        (groups - 1) * inEachGroup
      ])
    ].filter(val => !uniqueCorrectOptions.includes(val));

    const incorrectOptions = getRandomSubArrayFromArray(
      uniqueIncorrectOptions,
      4 - uniqueCorrectOptions.length
    );

    return {
      groups,
      inEachGroup,
      sameLayout,
      seed,
      color,
      rotation,
      options: shuffle([...incorrectOptions, ...uniqueCorrectOptions])
    };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, sameLayout, seed, color, rotation, options },
      translate,
      displayMode
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentences()}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        moveOrCopy="copy"
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: groups > 2 ? 'flex-start' : 'center',
                gap: 20,
                width: dimens.width * (displayMode === 'digital' ? 0.37 : 0.34)
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: dimens.height * 0.45, width: dimens.height * 0.45 }}
                  seed={sameLayout ? seed : idx * Math.random() * 10000}
                  rotation={rotation[idx]}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
        items={options.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
        testCorrect={[[groups, inEachGroup], [groups * inEachGroup]]}
        questionHeight={1200}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsGroupsWithAnsCountersInEachGroup(),
          translate.ks1AnswerSentences.thereAreAnsCountersAltogether()
        ]}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseEqualGroups',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
