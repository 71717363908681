import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import QF43DraggableLineOnTopOfStaticRuler from '../../../../components/question/questionFormats/QF43DraggableLineOnTopOfStaticRuler';
import { LineGraphColors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjs',
  description: 'bjs',
  keywords: ['Length', 'Height', 'Measure', 'Centimetres', 'Ruler', 'Tall', 'Long'],
  questionHeight: 1000,
  schema: z.object({
    length: z.number().int().min(1).max(15),
    isVertical: z.boolean(),
    object: z.enum([
      'flowers',
      'trees',
      'giraffe',
      'elephant',
      'teddyBear',
      'ribbons',
      'train',
      'pencils'
    ])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 15);
    const isVertical = getRandomBoolean();

    const object = isVertical
      ? getRandomFromArray(['flowers', 'trees', 'giraffe', 'elephant', 'teddyBear'] as const)
      : getRandomFromArray(['ribbons', 'train', 'pencils'] as const);

    return { object, length, isVertical };
  },
  Component: props => {
    const {
      question: { object, length, isVertical },
      translate
    } = props;

    const random = seededRandom(props.question);

    const svgName = (() => {
      switch (object) {
        case 'flowers':
          return getRandomFromArray(
            [
              'Flowers/Flower_Blue',
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Purple'
            ] as const,
            { random }
          );
        case 'trees':
          return getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
        case 'giraffe':
          return 'Giraffe';
        case 'elephant':
          return 'Elephant';
        case 'teddyBear':
          return 'Teddy_bear_1';
        case 'ribbons':
          return getRandomFromArray(['RibbonGreen', 'RibbonRed'] as const, { random });
        case 'train':
          return 'Transport_toys/Train_carriage';
        case 'pencils':
          return getRandomFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );
      }
    })();

    const sentence = {
      flowers: 'theFlowerIsXCmTall',
      trees: 'theTreeIsXCmTall',
      giraffe: 'theGiraffeIsXCmTall',
      elephant: 'theElephantIsXCmTall',
      teddyBear: 'theTeddyBearIsXCmTall',
      ribbons: 'theRibbonIsXCmLong',
      train: 'theTrainCarriageIsXCmLong',
      pencils: 'thePencilIsXCmLong'
    } as const;

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences[sentence[object]]()}
        testCorrect={[length.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            vertical={isVertical}
            maxScaleFactor={2}
            rulerKind="cm"
            rulerLength={length > 8 ? 15 : length + 1}
            items={[
              {
                length,
                svgInfo: { name: svgName },
                guidelines: true
              }
            ]}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjt',
  description: 'bjt',
  keywords: ['Length', 'Measure', 'Draw', 'Centimetres', 'Ruler', 'Long'],
  schema: z.object({
    length: z.number().int().min(1).max(14)
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 14);

    return { length };
  },
  Component: props => {
    const {
      question: { length },
      translate
    } = props;

    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.ks1Instructions.dragThePencilToDrawLineThatIsXCmLong(length)}
        pdfTitle={translate.ks1PDFInstructions.drawLineThatIsXCmLong(length)}
        rulerKind="cm"
        rulerLength={length < 8 ? 8 : 15}
        testCorrect={length}
        snapToNearest={0.1}
        wiggleRoom={0.2}
        customMarkSchemeAnswer={{
          answerToDisplay: length,
          answerText: translate.markScheme.allowXMmMarginOfError(2)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bju',
  description: 'bju',
  keywords: ['Length', 'Height', 'Measure', 'Centimetres', 'Ruler', 'Tall', 'Long'],
  questionHeight: 900,
  schema: z.object({
    length: z.number().int().min(1).max(15),
    start: z.number().int().min(1).max(14),
    lineColor: z.enum(LineGraphColors)
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 14);
    const start = randomIntegerInclusive(1, 15 - length);

    const lineColor = getRandomFromArray(LineGraphColors);

    return { start, length, lineColor };
  },
  Component: props => {
    const {
      question: { start, length, lineColor },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.ks1Instructions.whatIsTheLengthOfTheLine()}
        sentence={translate.ks1AnswerSentences.ansCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[length.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={start + length < 8 ? 8 : 15}
            items={[
              {
                length,
                start,
                lineColor,
                guidelines: true
              }
            ]}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInCentimetres',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
