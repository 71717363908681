import { View } from 'react-native';
import { AssetSvg, SvgName } from '../assets/svg';
import Text from '../components/typography/Text';

export const weightImages = (amount: 10 | 20 | 50 | 100 | 200 | 500): SvgName => {
  switch (amount) {
    case 10:
      return 'Weights/Weights_10g';
    case 20:
      return 'Weights/Weights_20g';
    case 50:
      return 'Weights/Weights_50g';
    case 100:
      return 'Weights/Weights_100g';
    case 200:
      return 'Weights/Weights_200g';
    case 500:
      return 'Weights/Weights_500g';
  }
};

export function getImageByWeight(amount: 10 | 20 | 50 | 100 | 200 | 500) {
  return weightImages(amount);
}

export const labelWeight = (label: string, width: number, height: number): JSX.Element => {
  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <AssetSvg name="Weights/Weight" width={width} height={height} />
      <Text variant="WRN700" style={{ position: 'absolute', color: 'white', bottom: height * 0.1 }}>
        {label}
      </Text>
    </View>
  );
};
