// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import TurnsAndAngles from './1TurnsAndAngles';
import RightAngles from './2RightAngles';
import CompareAngles from './3CompareAngles';
import MeasureAndDrawAccurately from './4MeasureAndDrawAccurately';
import HorizontalAndVertical from './5HorizontalAndVertical';
import ParallelAndPerpendicular from './6ParallelAndPerpendicular';
import RecogniseAndDescribe2DShapes from './7RecogniseAndDescribe2DShapes';
import DrawPolygons from './8DrawPolygons';
import RecogniseAndDescribe3DShapes from './9RecogniseAndDescribe3DShapes';
import Make3DShapes from './10Make3DShapes';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [8, 9],
  smallSteps: [
    TurnsAndAngles, // Step 1
    RightAngles, // Step 2
    CompareAngles, // Step 3
    MeasureAndDrawAccurately, // Step 4
    HorizontalAndVertical, // Step 5
    ParallelAndPerpendicular, // Step 6
    RecogniseAndDescribe2DShapes, // Step 7
    DrawPolygons, // Step 8
    RecogniseAndDescribe3DShapes, // Step 9
    Make3DShapes // Step 10
  ]
});
export default Block;
