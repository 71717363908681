import type { BaseTranslation } from '../i18n-types';

/**
 * Strings displayed to the user, from the whole app, in English.
 *
 * See https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/runtime#syntax.
 * In particular, {0:myType|myFormatter} references a type in ../custom-types.ts and a formatter in ../formatters.ts.
 *
 * When updating this, remember to run `yarn run typesafe-i18n` to generate new types.
 */
const en: BaseTranslation = {
  // Misc
  loadingEllipsis: 'Loading…',
  change: 'Change',

  // Screen titles
  titles: {
    infinity: 'Infinity',
    enterSchoolCode: 'Enter school code',
    enterQuizPin: 'Enter quiz PIN',
    enterPupilAccessCode: 'Enter pupil access code',
    pupilHome: 'Pupil home',
    scanQrCode: 'Scan QR Code',
    loadingQuiz: 'Loading quiz',
    menu: 'Menu'
  },

  // Home screen
  home: {
    appVersion: 'App version: {0}',
    enterCode: 'Enter code',
    scanQrCode: 'Scan QR code'
  },

  // Enter School Code and Enter Quiz PIN screens
  enterCodeOrPINScreen: {
    accountIssuePleaseSpeakToYourTeacherError: 'Account issue. Please speak to your teacher.',
    internetConnectionLostError:
      'Internet connection lost. Please check your internet and try again.',
    invalidPupilAccessCode: 'Invalid pupil access code please try again.',
    invalidQuizPinError: 'Invalid quiz PIN. Please try again.',
    invalidSchoolCodeError: 'Invalid school code. Please try again',
    quizLockedError: 'This quiz is locked. Please speak to your teacher.',
    quizNotFoundError: 'Quiz not found for this quiz PIN. Please try again.',
    schoolNotFoundError: 'School not found for this school code. Please try again.',
    skipLogin: 'Skip login',
    somethingWentWrongError: 'Something went wrong. Please try again later.'
  },

  // Dashboard screen
  dashboard: {
    studentQuizzesError: 'Student quizzes not found.',
    restartOrContinueTheQuiz: 'Restart or continue the quiz?'
  },

  // Error modals
  errorModals: {
    appNeedsToBeUpdated: 'App needs to be updated',
    cannotLoadQuizQuestions: 'Cannot load quiz questions.',
    cantAccessQuiz: "Can't access the quiz",
    cantLoginPromptText:
      'You can continue not logged in, but your <b>results will not be saved</b>.',
    internetConnectionLost: 'Internet connection lost',
    invalidQrCode: 'Invalid QR code',
    pleaseTryAgainLater: 'Please try again later',
    quizBelongsToDifferentGroup: 'This quiz belongs to a different learning group.',
    quizNotFound: 'Quiz not found',
    quizNotFoundForThisQrCode: 'Quiz not found for this QR code',
    resultsFailedToSendEnsureAppIsConnectedToInternet:
      'Some results have failed to send, please ensure the app is connected to the internet',
    somethingWentWrong: 'Something went wrong',
    speakToYourTeacher: 'Speak to your teacher.',
    thereIsAnAccountIssue: 'There is an account issue',
    thisAppRequiresAnInternetConnection: 'This app requires an internet connection.',
    thisQuizIsLocked: 'This quiz is locked',
    tryAgainWithAValidQrCode: 'Try again with a valid QR code',
    xQuestionsCouldNotBeLoaded: '{0:number|numberFormat} question{{s}} could not be loaded.'
  },

  // Info modals
  infoModals: {
    notLoggedIn: 'Not logged in',
    resultsNotSaved: 'Your results will not be saved.'
  },

  misc: {
    numberOfQuestions: '{0:number|numberFormat} question{{s}}',
    close: 'Close',
    continue: 'Continue',
    restart: 'Restart',
    cancel: 'Cancel'
  }
};

export default en;
