import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import { compareFloats } from '../../../../utils/math';
import { arrayHasNoDuplicates, filledArray, range } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import {
  barModelColors,
  barModelColorsArray,
  BarModelColorsKey,
  hundredSquareColors
} from '../../../../theme/colors';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import { createHundredSquareShape, numbersToHundredSquareColors } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVA',
  description: 'aVA',
  keywords: ['Decimals', 'Fraction', 'Equivalent', 'Tenths', 'Bar model'],
  schema: z.object({
    tenths: z.number().int().min(1).max(10),
    decimalOrFractionFirst: z.enum(['decimal', 'fraction'])
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 10);

    const decimalOrFractionFirst = getRandomFromArray(['decimal', 'fraction'] as const);

    return { tenths, decimalOrFractionFirst };
  },
  Component: props => {
    const {
      question: { tenths, decimalOrFractionFirst },
      translate,
      displayMode
    } = props;

    const selectedColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    }) as BarModelColorsKey;

    const coloredSections = [
      ...filledArray(barModelColors[selectedColor], tenths),
      ...filledArray('white', 10 - tenths)
    ];

    const numbers = filledArray(1, 10);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <BarModel
            total={10}
            dimens={{ width: dimens.width, height: dimens.height }}
            numbers={[numbers]}
            strings={[filledArray(`${(0.1).toLocaleString()}`, 10)]}
            cellColors={[coloredSections]}
            rowHeight={displayMode === 'digital' ? 50 : 100}
          />
        )}
        inputMaxCharacters={3}
        fractionContainerStyle={{ height: 96 }}
        pdfDirection="column"
        extraSymbol={'decimalPoint'}
        sentence={
          decimalOrFractionFirst === 'decimal'
            ? translate.answerSentences.ansIsEquivalentToX(`<frac nAns='' d='10' />`)
            : translate.answerSentences.xIsEquivalentToAns(`<frac nAns='' d='10' />`)
        }
        testCorrect={userAnswer =>
          decimalOrFractionFirst === 'decimal'
            ? compareFloats(userAnswer[0], tenths / 10) &&
              compareFractions([userAnswer[1], 10], [tenths, 10])
            : compareFractions([userAnswer[0], 10], [tenths, 10]) &&
              compareFloats(userAnswer[1], tenths / 10)
        }
        customMarkSchemeAnswer={{
          answersToDisplay:
            decimalOrFractionFirst === 'decimal'
              ? [(tenths / 10).toLocaleString(), tenths.toLocaleString()]
              : [tenths.toLocaleString(), (tenths / 10).toLocaleString()]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVB',
  description: 'aVB',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Hundredths', 'Hundred square'],
  schema: z
    .object({
      correctHundredths: z.number().int().min(1).max(100),
      incorrectHundredths: z.number().int().min(0).max(100)
    })
    .refine(
      val => val.correctHundredths !== val.incorrectHundredths,
      'correctHundredths and incorrectHundredths cannot be the same.'
    ),
  simpleGenerator: () => {
    const correctHundredths = randomIntegerInclusive(1, 100);

    const isIncorrectHundredths100MinusCorrect =
      correctHundredths === 50
        ? // incorrectHundredths must use randomIntegerInclusive if correctHundredths is 50, to avoid this also being 50
          false
        : getRandomBoolean();

    const incorrectHundredths = isIncorrectHundredths100MinusCorrect
      ? 100 - correctHundredths
      : randomIntegerInclusive(1, 100, {
          constraint: x => x !== correctHundredths
        });

    return { correctHundredths, incorrectHundredths };
  },
  Component: props => {
    const {
      question: { correctHundredths, incorrectHundredths },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [
        {
          sentence: `${(correctHundredths / 100).toLocaleString(undefined, {
            minimumFractionDigits: 1
          })}`,
          isCorrect: true
        },
        {
          sentence: `<frac n='${correctHundredths.toLocaleString()}' d='${(100).toLocaleString()}' />`,
          isCorrect: true
        },
        {
          // Enforce decimal to show with at least 1 decimal place, even if incorrectHundredths is 0:
          sentence: `${(incorrectHundredths / 100).toLocaleString(undefined, {
            minimumFractionDigits: 1
          })}`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${incorrectHundredths.toLocaleString()}' d='${(100).toLocaleString()}' />`,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const shape = createHundredSquareShape(correctHundredths);

    const hundredSquareColor = getRandomFromArray(hundredSquareColors, {
      random: seededRandom(props.question)
    });

    const coloredSquare = numbersToHundredSquareColors(
      [correctHundredths, 100 - correctHundredths],
      [hundredSquareColor as string]
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectDecimalAndFracEquivsShownByShadedPartsOfHundredSquare()}
        testCorrect={items.filter(item => item.isCorrect).map(item => item.sentence)}
        itemLayout={'column'}
        itemsOuterContainerStyle={{ gap: 16 }}
        mainPanelFlexDirection={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} dimens={dimens} cellColorMap={coloredSquare} />
        )}
        renderItems={items.map(({ sentence }) => ({
          value: sentence,
          component: (
            <TextStructure
              sentence={sentence}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              textVariant="WRN700"
            />
          )
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aVC',
  description: 'aVC',
  keywords: ['Decimals', 'Fraction', 'Equivalent', 'Tenths', 'Double number line', 'Number line'],
  schema: z.object({
    missingFractions: z.boolean(),
    missingVariables: z.array(z.number().int().min(1).max(9)).length(2),
    wrongOptions: z.array(z.number().int().min(0).max(10)).length(2)
  }),
  simpleGenerator: () => {
    const missingFractions = getRandomBoolean();
    const missingVariables = randomUniqueIntegersInclusive(1, 9, 2);
    const wrongOptions = randomUniqueIntegersInclusive(0, 10, 2, {
      constraint: x => !missingVariables.includes(x)
    });

    return { missingFractions, missingVariables, wrongOptions };
  },

  Component: props => {
    const {
      question: { missingFractions, missingVariables, wrongOptions },
      translate,
      displayMode
    } = props;

    const topTickValues = range(0, 10, 1).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (missingVariables.includes(i) && missingFractions) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${(10).toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, 10, 1).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (missingVariables.includes(i) && !missingFractions) return '<ans/>';
      return `${(i / 10).toLocaleString()} `;
    });

    const answers = [...missingVariables].sort().map(x => `${x / 10}`);

    const [num1, num2] = missingVariables;
    const [num3, num4] = wrongOptions;

    const allOptions = [
      [num1, 10],
      [num2, 10],
      [10, num1],
      [10, num2],
      [num3, 10],
      [num4, 10]
    ];

    let options;
    if (missingFractions) {
      options = allOptions.map(([n, d], i) => {
        return {
          component: (
            <TextStructure
              key={i}
              sentence={`<frac n='${n}' d='${d}' />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value: `${n / d}`
        };
      });
    } else {
      options = allOptions.map(([n, d]) => (n / d).toFixed(1).toLocaleString());
    }
    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={answers}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aVD',
  description: 'aVD',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z.object({
    denominatorA: numberEnum([2, 4, 5, 10]),
    numeratorA: z.number().int().min(1).max(9),
    denominatorB: numberEnum([2, 4, 5, 10]),
    numeratorB: z.number().int().min(1).max(9),
    denominatorC: numberEnum([2, 4, 5, 10]),
    numeratorC: z.number().int().min(1).max(9),
    draggableDecimalsOrFractions: z.enum(['decimals', 'fractions'])
  }),
  simpleGenerator: () => {
    const { denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC } =
      rejectionSample(
        () => {
          const [denominatorA, denominatorB, denominatorC] = getRandomSubArrayFromArray(
            [2, 4, 5, 10] as const,
            3
          );

          const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

          const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

          const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
          return { denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC };
        },
        // Only permit these if each pair resolves to a different decimal:
        ({ denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC }) =>
          arrayHasNoDuplicates([
            fractionToDecimal(numeratorA, denominatorA),
            fractionToDecimal(numeratorB, denominatorB),
            fractionToDecimal(numeratorC, denominatorC)
          ])
      );

    const draggableDecimalsOrFractions = getRandomFromArray(['decimals', 'fractions'] as const);

    return {
      denominatorA,
      numeratorA,
      denominatorB,
      numeratorB,
      denominatorC,
      numeratorC,
      draggableDecimalsOrFractions
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        denominatorB,
        numeratorB,
        denominatorC,
        numeratorC,
        draggableDecimalsOrFractions
      },
      translate,
      displayMode
    } = props;

    const fractionA = `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`;
    const fractionB = `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`;
    const fractionC = `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`;

    const decimalA = fractionToDecimal(numeratorA, denominatorA).toLocaleString();
    const decimalB = fractionToDecimal(numeratorB, denominatorB).toLocaleString();
    const decimalC = fractionToDecimal(numeratorC, denominatorC).toLocaleString();

    const statements = shuffle(
      draggableDecimalsOrFractions === 'decimals'
        ? [
            {
              statement: fractionA,
              correctAnswer: decimalA
            },
            {
              statement: fractionB,
              correctAnswer: decimalB
            },
            {
              statement: fractionC,
              correctAnswer: decimalC
            }
          ]
        : [
            {
              statement: decimalA,
              correctAnswer: fractionA
            },
            {
              statement: decimalB,
              correctAnswer: fractionB
            },
            {
              statement: decimalC,
              correctAnswer: fractionC
            }
          ],
      { random: seededRandom(props.question) }
    );

    const items =
      draggableDecimalsOrFractions === 'decimals'
        ? [decimalA, decimalB, decimalC]
        : [
            {
              component: (
                <TextStructure
                  sentence={fractionA}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  textVariant="WRN700"
                />
              ),
              value: fractionA
            },
            {
              component: (
                <TextStructure
                  sentence={fractionB}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  textVariant="WRN700"
                />
              ),
              value: fractionB
            },
            {
              component: (
                <TextStructure
                  sentence={fractionC}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  textVariant="WRN700"
                />
              ),
              value: fractionC
            }
          ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchDecimalAndFracEquivs()}
        pdfTitle={translate.instructions.useCardsToMatchDecimalAndFracEquivs()}
        items={items}
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} = <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVE',
  description: 'aVE',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z.object({
    denominatorA: numberEnum([10, 20, 25, 50]),
    numeratorA: z.number().int().min(1).max(49),
    denominatorB: numberEnum([100, 200, 250, 500]),
    numeratorB: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const denominatorA = getRandomFromArray([10, 20, 25, 50] as const);

    const denominatorB = getRandomFromArray([100, 200, 250, 500] as const);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

    const numeratorB = randomIntegerInclusive(1, 99);

    return {
      denominatorA,
      numeratorA,
      denominatorB,
      numeratorB
    };
  },
  Component: props => {
    const {
      question: { denominatorA, numeratorA, denominatorB, numeratorB },
      translate
    } = props;

    const expectedAnswerNumeratorA = (100 / denominatorA) * numeratorA;
    const expectedAnswerDecimalA = expectedAnswerNumeratorA / 100;

    const expectedAnswerNumeratorB = (1000 / denominatorB) * numeratorB;
    const expectedAnswerDecimalB = expectedAnswerNumeratorB / 1000;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], 100], [expectedAnswerNumeratorA, 100]) &&
          compareFloats(userAnswer[0][1], expectedAnswerDecimalA) &&
          compareFractions([userAnswer[1][0], 1000], [expectedAnswerNumeratorB, 1000]) &&
          compareFloats(userAnswer[1][1], expectedAnswerDecimalB)
        }
        fractionContainerStyle={{ height: 96 }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        sentences={[
          `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' /> = <frac nAns='' d='${(100).toLocaleString()}' /> = <ans/>`,
          `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' /> = <frac nAns='' d='${(1000).toLocaleString()}' /> = <ans/>`
        ]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aVE2',
  description: 'aVE',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z.object({
    denominator: numberEnum([10, 20, 25, 50]),
    numerator: z.number().int().min(1).max(49)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([10, 20, 25, 50] as const);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    return {
      denominator,
      numerator
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    const expectedAnswerNumerator = (100 / denominator) * numerator;
    const expectedAnswerDecimal = expectedAnswerNumerator / 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], 100], [expectedAnswerNumerator, 100]) &&
          compareFloats(userAnswer[1], expectedAnswerDecimal)
        }
        fractionContainerStyle={{ height: 96 }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        sentence={`<frac n='${numerator}' d='${denominator}' /> = <frac nAns='' d='100' /> = <ans/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [expectedAnswerNumerator.toString(), expectedAnswerDecimal.toString()]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aVF',
  description: 'aVF',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z.object({
    denominatorA: numberEnum([5, 10, 20, 25, 50, 100, 200, 250, 500]),
    numeratorA: z.number().int().min(1).max(499),
    denominatorB: numberEnum([5, 10, 20, 25, 50, 100, 200, 250, 500]),
    numeratorB: z.number().int().min(1).max(499),
    denominatorC: numberEnum([5, 10, 20, 25, 50, 100, 200, 250, 500]),
    numeratorC: z.number().int().min(1).max(499),
    draggableDecimalsOrFractions: z.enum(['decimals', 'fractions'])
  }),
  simpleGenerator: () => {
    const { denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC } =
      rejectionSample(
        () => {
          const [denominatorA, denominatorB, denominatorC] = getRandomSubArrayFromArray(
            [5, 10, 20, 25, 50, 100, 200, 250, 500] as const,
            3
          );

          const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

          const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

          const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
          return { denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC };
        },
        // Only permit these if each pair resolves to a different decimal:
        ({ denominatorA, numeratorA, denominatorB, numeratorB, denominatorC, numeratorC }) =>
          arrayHasNoDuplicates([
            fractionToDecimal(numeratorA, denominatorA),
            fractionToDecimal(numeratorB, denominatorB),
            fractionToDecimal(numeratorC, denominatorC)
          ])
      );

    const draggableDecimalsOrFractions = getRandomFromArray(['decimals', 'fractions'] as const);

    return {
      denominatorA,
      numeratorA,
      denominatorB,
      numeratorB,
      denominatorC,
      numeratorC,
      draggableDecimalsOrFractions
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        denominatorB,
        numeratorB,
        denominatorC,
        numeratorC,
        draggableDecimalsOrFractions
      },
      translate,
      displayMode
    } = props;

    const fractionA = `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`;
    const fractionB = `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`;
    const fractionC = `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`;

    const decimalA = fractionToDecimal(numeratorA, denominatorA).toLocaleString();
    const decimalB = fractionToDecimal(numeratorB, denominatorB).toLocaleString();
    const decimalC = fractionToDecimal(numeratorC, denominatorC).toLocaleString();

    const statements = shuffle(
      draggableDecimalsOrFractions === 'decimals'
        ? [
            {
              statement: fractionA,
              correctAnswer: decimalA
            },
            {
              statement: fractionB,
              correctAnswer: decimalB
            },
            {
              statement: fractionC,
              correctAnswer: decimalC
            }
          ]
        : [
            {
              statement: decimalA,
              correctAnswer: fractionA
            },
            {
              statement: decimalB,
              correctAnswer: fractionB
            },
            {
              statement: decimalC,
              correctAnswer: fractionC
            }
          ],
      { random: seededRandom(props.question) }
    );

    const items =
      draggableDecimalsOrFractions === 'decimals'
        ? [decimalA, decimalB, decimalC]
        : [
            {
              component: (
                <TextStructure
                  sentence={fractionA}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                />
              ),
              value: fractionA
            },
            {
              component: (
                <TextStructure
                  sentence={fractionB}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                />
              ),
              value: fractionB
            },
            {
              component: (
                <TextStructure
                  sentence={fractionC}
                  fractionTextStyle={{
                    fontSize: displayMode === 'digital' ? 30 : 50,
                    fontWeight: '700'
                  }}
                  textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                />
              ),
              value: fractionC
            }
          ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchDecimalAndFracEquivs()}
        pdfTitle={translate.instructions.useCardsToMatchDecimalAndFracEquivs()}
        items={items}
        actionPanelVariant="end"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} = <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aVF2',
  description: 'aVF',
  keywords: ['Decimal', 'Fraction', 'Equivalent', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z.object({
    denominator: numberEnum([100, 200, 250, 500]),
    numerator: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([100, 200, 250, 500] as const);

    const numerator = randomIntegerInclusive(1, 99);

    return {
      denominator,
      numerator
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    const expectedAnswerNumeratorB = (1000 / denominator) * numerator;
    const expectedAnswerDecimalB = expectedAnswerNumeratorB / 1000;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], 1000], [expectedAnswerNumeratorB, 1000]) &&
          compareFloats(userAnswer[1], expectedAnswerDecimalB)
        }
        fractionContainerStyle={{ height: 96 }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        sentence={`<frac n='${numerator}' d='${denominator}' /> = <frac nAns='' d='1000' /> = <ans/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [expectedAnswerNumeratorB.toString(), expectedAnswerDecimalB.toString()]
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DecimalAndFractionEquivalents',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
