import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  sortNumberArray
} from '../../../../utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import TextStructure from '../../../../components/molecules/TextStructure';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { numberToBase10Object } from '../../../../utils/math';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { chunk } from '../../../../utils/chunk';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgp',
  description: 'bgp',
  keywords: ['Greatest', 'Smallest', 'Number line', 'Order', 'Compare'],
  schema: z.object({
    numbers: z.array(z.number().int().min(0).max(100)).length(4),
    greatestOrSmallest: z.enum(['greatest', 'smallest']),
    numberOfTens: z.number().int().min(0).max(90)
  }),
  simpleGenerator: () => {
    const numberOfTens = randomIntegerInclusiveStep(0, 90, 10);

    const numbers = randomUniqueIntegersInclusive(numberOfTens, numberOfTens + 10, 4);

    const greatestOrSmallest = getRandomFromArray(['greatest', 'smallest'] as const);

    return { numbers, greatestOrSmallest, numberOfTens };
  },
  Component: props => {
    const {
      question: { numbers, greatestOrSmallest, numberOfTens },
      translate
    } = props;

    const statements = numbers.map(number => {
      return { text: number.toLocaleString(), value: number };
    });

    const tickValues = filledArray(0, 11).map((_, i) =>
      i === 0 ? numberOfTens.toLocaleString() : i === 10 ? (numberOfTens + 10).toLocaleString() : ''
    );

    const title =
      greatestOrSmallest === 'greatest'
        ? translate.ks1Instructions.selectTheGreatestNumber()
        : translate.ks1Instructions.selectTheSmallestNumber();

    const pdfTitle =
      greatestOrSmallest === 'greatest'
        ? translate.ks1PDFInstructions.tickTheGreatestNumber()
        : translate.ks1PDFInstructions.tickTheSmallestNumber();

    const answer = greatestOrSmallest === 'greatest' ? Math.max(...numbers) : Math.min(...numbers);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[answer]}
        numItems={4}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={{ height: dimens.height, width: dimens.width }}
          />
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: <TextStructure textVariant="WRN700" sentence={statement.text} />
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgq',
  description: 'bgq',
  keywords: ['Order', 'Compare', 'Greatest', 'Smallest'],
  schema: z.object({
    numberOfAnswers: numberEnum([3, 4]),
    numbers: z.array(z.number().int().min(1).max(100)).refine(arrayHasNoDuplicates),
    smallestOrGreatest: z.enum(['smallest', 'greatest']),
    variation: z.enum(['Rekenrek', 'Straws', 'Cubes'])
  }),
  simpleGenerator: () => {
    const numberOfAnswers = getRandomFromArray([3, 4] as const);

    const numbers = randomUniqueIntegersInclusive(1, 100, numberOfAnswers);

    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    const variation = getRandomFromArray(['Rekenrek', 'Straws', 'Cubes'] as const);

    return { numberOfAnswers, numbers, smallestOrGreatest, variation };
  },
  Component: props => {
    const {
      question: { numberOfAnswers, numbers, smallestOrGreatest, variation },
      translate
    } = props;

    const answer = smallestOrGreatest === 'smallest' ? Math.min(...numbers) : Math.max(...numbers);

    const title =
      smallestOrGreatest === 'smallest'
        ? translate.ks1Instructions.selectTheSmallestNumber()
        : translate.ks1Instructions.selectTheGreatestNumber();

    const pdfTitle =
      smallestOrGreatest === 'smallest'
        ? translate.ks1PDFInstructions.tickTheSmallestNumber()
        : translate.ks1PDFInstructions.tickTheGreatestNumber();

    const scaleNumberOfOnes = Math.max(...numbers.map(number => number % 10));
    const scaleNumberOfTens = Math.max(...numbers.map(number => Math.floor(number / 10)));

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[answer]}
        innerContainerStyle={{ justifyContent: 'center' }}
        numItems={numberOfAnswers}
        renderItems={({ dimens }) => {
          const simpleBaseTenScale = Scale(dimens.width, dimens.height, {
            ones: scaleNumberOfOnes,
            tens: scaleNumberOfTens
          });

          return numbers.map(number => {
            const baseTen = number === 100 ? { tens: 10 } : numberToBase10Object(number);

            const chunksTens = baseTen.tens ? chunk(countRange(baseTen.tens), 4) : [];
            const chunksOnes = baseTen.ones ? chunk(countRange(baseTen.ones), 4) : [];

            return {
              value: number,
              component:
                variation === 'Cubes' ? (
                  <SimpleBaseTenWithCrossOut
                    ones={baseTen.ones}
                    tens={baseTen.tens}
                    dimens={{ width: dimens.width, height: dimens.height }}
                    scale={simpleBaseTenScale}
                  />
                ) : variation === 'Rekenrek' ? (
                  <Rekenrek
                    numberShown={number}
                    rows={10}
                    dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                  />
                ) : (
                  <View
                    style={{
                      flexDirection: baseTen.ones && baseTen.tens ? 'row' : undefined,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <View
                      style={{
                        width: dimens.width * 0.47,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignSelf: 'flex-start'
                      }}
                    >
                      {chunksTens.map(chunk => {
                        return chunk.map((_, index) => {
                          return (
                            <AssetSvg
                              key={index}
                              name="Bundle_of_10_straws"
                              width={(dimens.width * 0.47) / chunk.length}
                              height={dimens.height * 0.3}
                            />
                          );
                        });
                      })}
                    </View>
                    <View
                      style={{
                        width: dimens.width / 2,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignSelf: 'flex-start'
                      }}
                    >
                      {chunksOnes.map(chunk => {
                        return chunk.map((_, index) => {
                          return (
                            <AssetSvg
                              key={index}
                              name="Base_Ten/Straws1"
                              width={(dimens.width * 0.47) / chunk.length}
                              height={dimens.height * 0.3}
                            />
                          );
                        });
                      })}
                    </View>
                  </View>
                )
            };
          });
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'bgr',
  description: 'bgr',
  keywords: ['Order'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(0).max(100))
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numberOfAnswers = getRandomFromArray([3, 5] as const);

    const numberOfTens = randomIntegerInclusive(0, 9);

    const [numberA, numberB] = randomUniqueIntegersInclusive(
      numberOfTens * 10,
      numberOfTens * 10 + 9,
      2
    );

    const otherNumbers = randomUniqueIntegersInclusive(0, 100, numberOfAnswers - 2, {
      constraint: x => ![numberA, numberB].includes(x)
    });

    const numbers = shuffle([numberA, numberB, ...otherNumbers]);

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const pdfTitle =
      ordering === 'ascending'
        ? translate.ks1PDFInstructions.orderTheNumbersFromSmallestToGreatest()
        : translate.ks1PDFInstructions.orderTheNumbersFromGreatestToSmallest();

    return (
      <QF5DragOrderHorizontal
        title={translate.ks1Instructions.dragToPutTheNumbersInOrder()}
        pdfTitle={pdfTitle}
        testCorrect={correctOrder}
        items={numbers}
        labelsPosition="bottom"
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        arrowWidth={numbers.length === 5 ? 220 : 115}
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderObjectsAndNumbers',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
