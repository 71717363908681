import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIq',
  description: 'aIq',
  keywords: [
    'Number line',
    'Fraction',
    'Before',
    'After',
    'Counting forwards',
    'Counting backwards',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(5).max(10),
      numerator: z.number().int().min(3).max(8),
      answerBeforeOrAfter: z.enum(['before', 'after'])
    })
    .refine(
      val => val.denominator > val.numerator + 1,
      'denominator must be at least 2 larger than numerator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 10);

    const numerator = randomIntegerInclusive(3, denominator - 2);

    const answerBeforeOrAfter = getRandomFromArray(['before', 'after'] as const);

    return { denominator, numerator, answerBeforeOrAfter };
  },
  Component: props => {
    const {
      question: { denominator, numerator, answerBeforeOrAfter },
      translate
    } = props;

    const numberLineFractions = range(1, denominator).map(val =>
      val !== numerator && val !== denominator ? '' : `<frac n='${val}' d='${denominator}' />`
    );

    const tickValues = [(0).toLocaleString(), ...numberLineFractions];

    const answerNumerator = answerBeforeOrAfter === 'before' ? numerator - 1 : numerator + 1;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        pdfDirection="column"
        fractionTextStyle={{ fontSize: 32 }}
        sentence={
          answerBeforeOrAfter === 'before'
            ? translate.answerSentences.theFractionThatComesBeforeFractionIsAns(
                numerator,
                denominator
              )
            : translate.answerSentences.theFractionThatComesAfterFractionIsAns(
                numerator,
                denominator
              )
        }
        inputMaxCharacters={2}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1]], [answerNumerator, denominator])
        }
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIr',
  description: 'aIr',
  keywords: ['Number line', 'Fraction', 'Counting forwards', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(8),
      answerNumerator: z.number().int().min(3).max(7)
    })
    .refine(
      val => val.denominator > val.answerNumerator,
      'denominator must be larger than answerNumerator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 8);

    const answerNumerator = randomIntegerInclusive(3, denominator - 1);

    return { denominator, answerNumerator };
  },
  Component: props => {
    const {
      question: { denominator, answerNumerator },
      translate
    } = props;

    const numberLineFractions = range(1, denominator).map(val =>
      val === answerNumerator
        ? `<frac nAns='' d='${denominator}' />`
        : val < 3 || val === denominator
        ? `<frac n='${val}' d='${denominator}' />`
        : ''
    );

    const tickValues = [(0).toLocaleString(), ...numberLineFractions];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.countForwardsToWorkOutTheMissingNumerator()}
        testCorrect={[answerNumerator.toString()]}
        tickValues={tickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIs',
  description: 'aIs',
  keywords: ['Number line', 'Fraction', 'Counting backwards', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(8),
      answerNumerator: z.number().int().min(2).max(6)
    })
    .refine(
      val => val.denominator > val.answerNumerator,
      'denominator must be larger than answerNumerator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 8);

    const answerNumerator = randomIntegerInclusive(2, denominator - 2);

    return { denominator, answerNumerator };
  },
  Component: props => {
    const {
      question: { denominator, answerNumerator },
      translate
    } = props;

    const numberLineFractions = range(1, denominator).map(val =>
      val === answerNumerator
        ? `<frac nAns='' d='${denominator}' />`
        : val >= denominator - 1
        ? `<frac n='${val}' d='${denominator}' />`
        : ''
    );

    const tickValues = [(0).toLocaleString(), ...numberLineFractions];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.countBackwardsToWorkOutTheMissingNumerator()}
        testCorrect={[answerNumerator.toString()]}
        tickValues={tickValues}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aIt',
  description: 'aIt',
  keywords: [
    'Number line',
    'Fraction',
    'Counting forwards',
    'Counting backwards',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(10),
      answerNumerator: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.denominator > val.answerNumerator,
      'denominator must be larger than answerNumerator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 10);

    const answerNumerator = randomIntegerInclusive(1, denominator - 1);

    return { denominator, answerNumerator };
  },
  Component: props => {
    const {
      question: { denominator, answerNumerator },
      translate
    } = props;

    const numberLineFractions = range(1, denominator - 1).map(val =>
      val === answerNumerator
        ? `<frac nAns='' dAns='' />`
        : `<frac n='${val}' d='${denominator}' />`
    );

    const tickValues = [(0).toLocaleString(), ...numberLineFractions, (1).toLocaleString()];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1]], [answerNumerator, denominator])
        }
        tickValues={tickValues}
        textVerticalPadding={0}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aIu',
  description: 'aIu',
  keywords: ['Number line', 'Fraction', 'Numerator', 'Denominator', 'Count in fractions'],
  schema: z
    .object({
      name: nameSchema,
      isCorrect: z.boolean(),
      denominator: z.number().int().min(3).max(10),
      numerator: z.number().int().min(2).max(9)
    })
    .refine(val => val.denominator > val.numerator, 'denominator must be larger than numerator'),
  simpleGenerator: () => {
    const name = getRandomName();

    const isCorrect = getRandomBoolean();

    const denominator = randomIntegerInclusive(3, 10);

    // Needed to make condition of denominator * 0.5 < numerator work:
    const numeratorLowerBound =
      Math.ceil(denominator / 2) === denominator / 2
        ? denominator / 2 + 1
        : Math.ceil(denominator / 2);

    const numerator = randomIntegerInclusive(numeratorLowerBound, denominator - 1);

    return { name, isCorrect, denominator, numerator };
  },
  Component: ({ question: { isCorrect, denominator, numerator }, translate }) => {
    const tickValues = [
      (0).toLocaleString(),
      ...filledArray('', denominator - 1),
      (1).toLocaleString()
    ];
    const titleNumerator = isCorrect
      ? numerator.toLocaleString()
      : (numerator + 1).toLocaleString();
    const titleDenominator = isCorrect
      ? denominator.toLocaleString()
      : (denominator + 1).toLocaleString();

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.selectWhetherTheStatementIsTrueOrFalse()}<br/>${translate.instructions.theArrowIsPointingAtFrac(
          titleNumerator,
          titleDenominator
        )}`}
        pdfTitle={`${translate.instructions.circleWhetherTheStatementIsTrueOrFalse()}<br/>${translate.instructions.theArrowIsPointingAtFrac(
          titleNumerator,
          titleDenominator
        )}`}
        correctAnswer={isCorrect}
        content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={fractionToDecimal(numerator, denominator)}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aIv',
  description: 'aIv',
  keywords: [
    'Number line',
    'Fraction',
    'Numerator',
    'Denominator',
    'Count in fractions',
    'Estimate'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(10),
      numerator: z.number().int().min(1).max(9)
    })
    .refine(val => val.denominator > val.numerator, 'denominator must be larger than numerator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;
    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToEstimateWhereFracBelongsOnNumberLine(
          `<frac n='${numerator}' d='${denominator}'/>`
        )}
        pdfTitle={translate.instructions.drawArrowToEstimateWhereFracBelongsOnNumberLine(
          `<frac n='${numerator}' d='${denominator}'/>`
        )}
        testCorrect={[
          fractionToDecimal(numerator, denominator) * 0.9,
          fractionToDecimal(numerator, denominator) * 1.1
        ]}
        min={0}
        max={1}
        sliderStep={fractionToDecimal(1, denominator * 2)}
        tickValues={[(0).toLocaleString(), (1).toLocaleString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountInFractionsOnANumberLine',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
