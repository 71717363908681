import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import TenFrames, {
  PlaceValue
} from 'common/src/components/question/representations/TenFrame/TenFrameLegacy';
import { all, create, number } from 'mathjs';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from 'common/src/utils/random';
import { binOpEquationToSentenceString, getBinOpEquation } from 'common/src/utils/fourOperations';
import { range } from 'common/src/utils/collections';
import { compareFloats } from 'common/src/utils/math';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aBy',
  description: 'aBy',
  keywords: ['Ten frame', 'Addition', 'Tenths'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(9),
      var2: z.number().int().min(2).max(9),
      var3: z.number().min(0.2).max(0.9).step(0.1),
      var4: z.number().min(0.2).max(0.9).step(0.1)
    })
    .refine(val => val.var1 + val.var2 > 10, 'total of var1 and var2 should be more than 10'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 9);
    const var2 = randomIntegerInclusive(2, 9, {
      constraint: x => var1 + x > 10
    });

    const var3 = var1 / 10;
    const var4 = var2 / 10;

    return { var1, var2, var3, var4 };
  },

  Component: ({ question: { var1, var2, var3, var4 }, translate }) => {
    const equations = [
      { statement: `${var1} + ${var2}`, counters1: var1, counters2: var2, placeValue: 'ones' },
      { statement: `${var3} + ${var4}`, counters1: var1, counters2: var2, placeValue: 'tenths' }
    ];

    return (
      <QF1ContentAndSentences
        style={{ flexDirection: 'row', justifyContent: 'space-around' }}
        extraSymbol={'decimalPoint'}
        title={translate.instructions.useTheTenFramesToWorkOutTheAdditions()}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            {equations.map(({ counters1, counters2, placeValue }, i) => (
              <View
                style={{
                  width: dimens.width / 2,
                  height: dimens.height,
                  justifyContent: 'space-around'
                }}
                key={i}
              >
                <TenFrames numberOfCounters={counters1} placeValue={placeValue as PlaceValue} />
                <TenFrames numberOfCounters={counters2} placeValue={placeValue as PlaceValue} />
              </View>
            ))}
          </View>
        )}
        sentences={equations.map(({ statement }) => statement + ' = <ans/>')}
        inputMaxCharacters={3}
        pdfDirection="column"
        testCorrect={userAnswer =>
          userAnswer.every((answer, i) => {
            return compareFloats(answer[0], math.evaluate(equations[i].statement));
          })
        }
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [[(var1 + var2).toLocaleString()], [(var3 + var4).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aBz',
  description: 'aBz',
  keywords: ['Addition', 'Tenths'],
  schema: z
    .object({
      var1: z.number().min(0.1).max(0.9).step(0.1),
      var2: z.number().min(0.1).max(0.9).step(0.1)
    })
    .refine(val => val.var1 + val.var2 > 1, 'total of var1 and var2 should be more than 1'),
  questionHeight: 900,
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(1, 9) / 10;
        const var2 = randomIntegerInclusive(1, 9) / 10;

        return { var1, var2 };
      },
      ({ var1, var2 }) => var1 + var2 > 1
    );

    return { var1, var2 };
  },

  Component: ({ question: { var1, var2 }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        extraSymbol="decimalPoint"
        title={translate.instructions.workOutTheCalculation()}
        sentence={`${var1.toLocaleString()} + ${var2.toLocaleString()} = <ans/>`}
        inputMaxCharacters={3}
        testCorrect={userAnswer => compareFloats(userAnswer[0], math.evaluate(`${var1} + ${var2}`))}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [(var1 + var2).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aBA',
  description: 'aBA',
  keywords: ['Ten frame', 'Subtraction', 'Tenths'],
  schema: z.object({
    var1: z.number().int().min(11).max(18),
    var2: z.number().int().min(2).max(9),
    var3: z.number().min(1.1).max(1.8).step(0.1),
    var4: z.number().min(0.2).max(0.9).step(0.1)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(11, 18);
    const var2 = randomIntegerInclusive(var1 - 9, 9);

    const var3 = var1 / 10;
    const var4 = var2 / 10;

    return { var1, var2, var3, var4 };
  },

  Component: ({ question: { var1, var2, var3, var4 }, translate }) => {
    const equations = [
      { statement: `${var1} - ${var2}`, counters: var1, placeValue: 'ones' },
      { statement: `${var3} - ${var4}`, counters: var1, placeValue: 'tenths' }
    ];

    return (
      <QF1ContentAndSentences
        style={{ flexDirection: 'row', justifyContent: 'space-around' }}
        extraSymbol={'decimalPoint'}
        title={translate.instructions.useTheTenFramesToWorkOutTheSubtractions()}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            {equations.map(({ counters, placeValue }, i) => (
              <View
                style={{
                  width: dimens.width / 2,
                  justifyContent: 'space-between'
                }}
                key={i}
              >
                <TenFrames numberOfCounters={counters} placeValue={placeValue as PlaceValue} />
              </View>
            ))}
          </View>
        )}
        sentences={equations.map(({ statement }) => statement + ' = <ans/>')}
        inputMaxCharacters={3}
        pdfDirection="column"
        testCorrect={userAnswer =>
          userAnswer.every((answer, i) =>
            compareFloats(answer[0], math.evaluate(equations[i].statement))
          )
        }
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [[(var1 - var2).toLocaleString()], [(var3 - var4).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aBB',
  description: 'aBB',
  keywords: ['Subtraction', 'Tenths'],
  schema: z.object({
    var1: z.number().min(1.1).max(1.8).step(0.1),
    var2: z.number().min(0.2).max(0.9).step(0.01)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const var1 = getRandomFromArray(range(1.1, 1.8, 0.1));
    const var2 = randomIntegerInclusive(var1 * 10 - 9, 9) / 10;

    return { var1, var2 };
  },

  Component: ({ question: { var1, var2 }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        extraSymbol="decimalPoint"
        title={translate.instructions.workOutTheCalculation()}
        sentence={`${var1.toLocaleString()} - ${var2.toLocaleString()} = <ans/>`}
        inputMaxCharacters={3}
        testCorrect={userAnswer => compareFloats(userAnswer[0], math.evaluate(`${var1} - ${var2}`))}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [math.evaluate(`${var1} - ${var2}`).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aBC',
  description: 'aBC',
  keywords: ['Addition', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      var1: z.number().min(0.1).max(0.9).step(0.1),
      var2: z.number().min(0.11).max(0.99).step(0.01),
      var3: z.number().min(0.11).max(0.99).step(0.01)
    })
    .refine(val => val.var1 + val.var2 > 1, 'total of var1 and var2 should be greater than 1'),
  questionHeight: 600,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 9) / 10;
    const var2 =
      randomIntegerInclusive(11, 99, {
        constraint: x => var1 + x / 100 > 1
      }) / 100;

    const var3 = number(math.evaluate(`${var1} + (${randomIntegerInclusive(1, 9)} / 100)`));

    return { var1, var2, var3 };
  },

  Component: ({ question: { var1, var2, var3 }, translate }) => {
    const eqA = getBinOpEquation({
      left: var3,
      right: var2,
      sign: 'add'
    });

    const eqB = getBinOpEquation({
      left: var1,
      right: var2,
      sign: 'add'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        title={translate.instructions.workOutTheCalculations()}
        sentences={eqs.map(binOpEquationToSentenceString)}
        inputMaxCharacters={3}
        testCorrect={userAnswer => {
          return userAnswer.every((answer, index) => compareFloats(answer[0], eqs[index].result));
        }}
        questionHeight={600}
        customMarkSchemeAnswer={{
          answersToDisplay: eqs.map(eq => [eq.result.toLocaleString()]),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aBD',
  description: 'aBD',
  keywords: ['Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    var1: z.number().min(1.1).max(1.8).step(0.1),
    var2: z.number().min(0.21).max(0.99).step(0.01),
    var3: z.number().min(1.11).max(1.89).step(0.01)
  }),
  questionHeight: 600,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(11, 18) / 10;
    const var2 = randomIntegerInclusive(var1 * 100 - 89, 99) / 100;

    const var3 = number(math.evaluate(`${var1} + (${randomIntegerInclusive(1, 9)} / 100)`));

    return { var1, var2, var3 };
  },

  Component: ({ question: { var1, var2, var3 }, translate }) => {
    const eqA = getBinOpEquation({
      left: var3,
      right: var2,
      sign: 'subtract'
    });

    const eqB = getBinOpEquation({
      left: var1,
      right: var2,
      sign: 'subtract'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        title={translate.instructions.workOutTheCalculations()}
        sentences={eqs.map(binOpEquationToSentenceString)}
        inputMaxCharacters={3}
        testCorrect={userAnswer => {
          return userAnswer.every((answer, index) => compareFloats(answer[0], eqs[index].result));
        }}
        questionHeight={600}
        customMarkSchemeAnswer={{
          answersToDisplay: eqs.map(eq => [eq.result.toLocaleString()]),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractDecimalsAcross1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
