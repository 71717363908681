import { useContext, type ReactElement } from 'react';
import { View, type StyleProp, type ViewStyle, type TextStyle, StyleSheet } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { useState } from 'react';
import { colors } from '../../theme/colors';
import { DisplayMode } from '../../contexts/displayMode';
import TextStructure from './TextStructure';

const RADIANS_PER_DEGREE = Math.PI / 180;

type Props = {
  /** Either a component, or text using the markup language of {@link TextStructure}. */
  children: ReactElement | string;
  /** Default: bottom-left  */
  flickLocation?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  /** Any extra style. This is a good place to put a margin to account for the speech bubble. */
  style?: StyleProp<ViewStyle>;
  /** Only used if `children` is a string. */
  textStyle?: StyleProp<TextStyle>;
  /** Only used if `children` is a string. */
  fractionContainerStyle?: StyleProp<ViewStyle>;
  /** Only used if `children` is a string. */
  fractionDividerStyle?: StyleProp<ViewStyle>;
  /** Only used if `children` is a string. */
  fractionTextStyle?: StyleProp<TextStyle>;
};

/**
 * Speech bubble.
 *
 * This is a container view that wraps its child (which will usually be a `<Text>` or `<TextStructure>`, but could
 * be anything). If the child is a single text string, then it is placed inside a `<TextStructure>`. The default font
 * size is 22.
 *
 * A speech bubble SVG is then additionally drawn underneath the child, automatically scaling to fit the child.
 *
 * Important note: The speech bubble SVG does not interact with the layout, so it's up to the user to ensure that it
 * doesn't overlap with any other elements, for example by adding a margin. The speech bubble extends out an amount
 * that depends on the width and height, so it's not knowable in advance. It might help during development to set
 * `width` and `height` styles on the speech bubble to the maximum width and height you support for that question, and
 * then when you're happy with how the speech bubble looks in that case, rename these to `maxWidth` and `maxHeight`.
 *
 * Why don't we margin automatically? Well because we're allowing the children (usually a <Text> or similar) to set
 * its own size based on other constraints, and we measure the resulting size - we don't prescribe the size that the
 * children should be. Then, as a result of measuring the children, we must not change the size of the children by
 * adding in margins. Hence, we cannot automatically set the margins - they need to be decided up front.
 * (If you can figure out a better way to do this, please submit a PR!)
 */
export default function SpeechBubble({
  children,
  flickLocation = 'bottom-left',
  style,
  textStyle,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle
}: Props) {
  ////
  // Implementation notes:
  //
  // This speech bubble is made from an ellipse and a little flick. It is generated from the width and height of the
  // `children` (usually a <Text>) which we measure. This means that the speech bubble appears on the second render.
  //
  // Mathematically, an ellipse can be thought of as the unit circle that's been stretched horizontally by rx, and
  // vertically by ry. So:
  //
  // - equation of a unit circle, centred at (0,0): x^2 + y^2 = 1
  // - equation of an ellipse, centred at (0,0): (x/rx)^2 + (y/ry)^2 = 1
  // - equation of an ellipse, centred at (cx,cy): ((x-cx)/rx)^2 + ((y-cy)/ry)^2 = 1
  //
  // - coordinates of points on a unit circle, centred at (0,0): (cos(t), sin(t)); for some angle t
  // - coordinates of points on an ellipse, centred at (0,0): (rx * cos(t), ry * sin(t)); for some angle t
  // - coordinates of points on an ellipse, centred at (cx,cy): (cx + rx * cos(t), cy + ry * sin(t)); for some angle t
  //
  // The last fact that was used here, is when trying to inscribe a rectangle within an ellipse, its bottom right corner
  // is (cx + rx * cos(t), cy + ry * sin(t)) for some t between 0 and 90 degrees. You can reflect this around the lines
  // y=cy and x=cx to get the other 3 points. So there's an infinite family of rectangles which inscribe the ellipse,
  // one for each t.
  //
  // In reverse, when trying to circumscribe an ellipse around a rectangle with center (cx,cy), width w and height h
  // (as we want to here), then we can do the following derivation:
  // 1. The rectangle's bottom right point is at (cx + w/2, cy + h/2)
  // 2. If this is to lie on an ellipse centered at (cx, cy) then we have for t between 0 and 90:
  //     - cx + w/2 = cx + rx * cos(t)
  //     - cy + h/2 = cy + ry * sin(t)
  // 3. Re-arrange this to get rx and ry:
  //     - rx = (w/2) / cos(t)
  //     - ry = (h/2) / sin(t)
  // So, there's an infinite family of ellipses which circumscribe a rectangle, one for each t.
  ////

  const styles = useStyles();

  /**
   * The location of the flick, in degrees clockwise from the right side of the ellipse.
   * (Degrees are with respect to the circle, before stretching it into an ellipse.)
   */
  let t: number;
  switch (flickLocation) {
    case 'bottom-left':
      t = 150;
      break;
    case 'bottom-right':
      t = 30;
      break;
    case 'top-left':
      t = 210;
      break;
    case 'top-right':
      t = 330;
      break;
  }

  // Size of the view
  const [layoutInfo, setLayoutInfo] = useState<{ width: number; height: number }>();

  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width, height }
        }
      }) => {
        setLayoutInfo(old => {
          if (old && width === old.width && height === old.height) {
            return old;
          } else {
            return { width, height };
          }
        });
      }}
      style={[{ justifyContent: 'center', alignItems: 'center' }, style]}
    >
      {layoutInfo &&
        (() => {
          const { width, height } = layoutInfo;
          // Calculate the rx and ry of the bounding ellipse. There are lots of possible ellipses to choose from.
          // In fact, we can choose a theta between 0 and pi/4 and then the bounding ellipse is given by:
          // - const rx = (width/2) / Math.cos(theta);
          // - const ry = (height/2) / Math.sin(theta);

          // The most efficient choice (to minimize rx*ry) is `theta=Math.PI/4`, i.e.:
          const thetaEfficient = Math.PI / 4;
          // In this case, the calculations for rx and ry simplify to these:
          // - const rx = width * Math.SQRT1_2;
          // - const ry = height * Math.SQRT1_2;

          // A circle (rx==ry) would be `theta=arctan(height/width)`, i.e.:
          const thetaCircle = Math.atan(height / width);
          // In this case, the calculations for rx and ry simplify to these:
          // - const rx = Math.sqrt(width ** 2 + height ** 2) / 2;
          // - const ry = Math.sqrt(width ** 2 + height ** 2) / 2;

          // We usually want to pick somewhere in between, so let's try half way in between.
          const theta = (thetaEfficient + thetaCircle) / 2;
          let rx = width / 2 / Math.cos(theta);
          let ry = height / 2 / Math.sin(theta);

          // Finally, we don't allow rx/ry to be too large or small (i.e. we don't allow the speech bubble to be
          // extremely long and thin). Set the maximum ratio to 4.
          ry = Math.max(ry, rx / 4);
          rx = Math.max(rx, ry / 4);

          return <BubbleShape rx={rx} ry={ry} cx={width / 2} cy={height / 2} t={t} />;
        })()}

      {typeof children === 'string' ? (
        <TextStructure
          sentence={children}
          style={styles.textStructureStyle}
          textStyle={[styles.defaultTextStyle, textStyle]}
          fractionContainerStyle={fractionContainerStyle}
          fractionDividerStyle={[styles.defaultFractionDividerStyle, fractionDividerStyle]}
          fractionTextStyle={[styles.defaultFractionTextStyle, fractionTextStyle]}
        />
      ) : (
        children
      )}
    </View>
  );
}

/**
 * Absolutely-positioned bubble shape, based on an ellipse specified by cx, cy, rx and ry.
 */
function BubbleShape({
  cx,
  cy,
  rx,
  ry,
  strokeWidth = 3,
  stroke = colors.prussianBlue,
  fill = 'white',
  t = 140
}: {
  cx: number;
  cy: number;
  rx: number;
  ry: number;
  strokeWidth?: number;
  stroke?: string;
  fill?: string;
  /**
   * Location of the speech bubble flick, in degrees clockwise from the right.
   * (Degrees are with respect to the circle, before stretching it into an ellipse.)
   */
  t?: number;
}) {
  const { path, viewBox } = calculateSpeechBubblePath(rx, ry, rx, ry, t - 10, t + 10);
  const [pathMinX, pathMinY, pathWidth, pathHeight] = viewBox;

  return (
    <Svg
      width={pathWidth + strokeWidth}
      height={pathHeight + strokeWidth}
      viewBox={[
        pathMinX - strokeWidth / 2,
        pathMinY - strokeWidth / 2,
        pathWidth + strokeWidth,
        pathHeight + strokeWidth
      ].join(' ')}
      style={{
        position: 'absolute',
        left: cx - rx + pathMinX - strokeWidth,
        top: cy - ry + pathMinY - strokeWidth
      }}
    >
      <Path strokeWidth={strokeWidth} stroke={stroke} fill={fill} d={path} />
    </Svg>
  );
}

/**
 * Calculate an SVG path of the speech bubble.
 * This is an ellipse plus a little flick, which might escape the rectangular bounds of that ellipse.
 * Also return the smallest viewBox that fits the entire path (assuming a stroke-width of 0) including the flick.
 */
function calculateSpeechBubblePath(
  /** X-coordinate of the center of the ellipse that forms the main body of the speech bubble */
  cx: number,
  /** Y-coordinate of the center of the ellipse that forms the main body of the speech bubble */
  cy: number,
  /** Horizontal radius of the ellipse that forms the main body of the speech bubble */
  rx: number,
  /** Vertical radius of the ellipse that forms the main body of the speech bubble */
  ry: number,
  /**
   * Start position of the arc, in degrees clockwise from right.
   * (Degrees are with respect to the circle, before stretching it into an ellipse.)
   */
  startT: number,
  /**
   * End position of the arc, in degrees clockwise from right.
   * (Degrees are with respect to the circle, before stretching it into an ellipse.)
   */
  endT: number
): { path: string; viewBox: [number, number, number, number] } {
  // We will draw an arc clockwise around the ellipse from start to end.
  const start = pointOnEllipse(cx, cy, rx, ry, startT);
  const end = pointOnEllipse(cx, cy, rx, ry, endT);

  // Calculate the top of the flick
  const midT = (startT + endT) / 2;
  const flickExtension = (rx + ry) / 6;
  const flick = pointOnEllipse(cx, cy, rx + flickExtension, ry + flickExtension, midT);

  // Calculate how the curve of the flick looks. We will use two quadratic bezier curve, so we need two control points.
  // The flick either points towards the direction of the start point, or the end point, depending where in the ellipse
  // it is. This ensures that the flick always points left or right, never up or down.
  const flickDirection = midT % 180 < 90 ? 'start' : 'end';
  const flickInnerControl = pointOnEllipse(
    cx,
    cy,
    rx + flickExtension / 4,
    ry + flickExtension / 4,
    midT
  );
  const flickOuterControl = pointOnEllipse(
    cx,
    cy,
    rx + flickExtension / 2,
    ry + flickExtension / 2,
    flickDirection === 'start' ? endT : startT
  );

  return {
    path:
      `M${start.x},${start.y}` +
      `A${rx},${ry} 0 1,0 ${end.x},${end.y}` +
      (flickDirection === 'start'
        ? `Q${flickOuterControl.x},${flickOuterControl.y} ${flick.x},${flick.y}` +
          `Q${flickInnerControl.x},${flickInnerControl.y} ${start.x},${start.y}`
        : `Q${flickInnerControl.x},${flickInnerControl.y} ${flick.x},${flick.y}` +
          `Q${flickOuterControl.x},${flickOuterControl.y} ${start.x},${start.y}`),
    viewBox: [
      Math.min(flick.x, 0),
      Math.min(flick.y, 0),
      Math.max(flick.x, rx * 2) - Math.min(flick.x, 0),
      Math.max(flick.y, ry * 2) - Math.min(flick.y, 0)
    ]
  };
}

/**
 * Helper function to give a point on an ellipse.
 * cx, cy, rx and ry specify the ellipse, and r is the number of degrees around the ellipse, starting at the right.
 * (Degrees are actually with respect to the circle before it's stretched into an ellipse.)
 */
function pointOnEllipse(cx: number, cy: number, rx: number, ry: number, t: number) {
  return {
    x: cx + rx * Math.cos(t * RADIANS_PER_DEGREE),
    y: cy + ry * Math.sin(t * RADIANS_PER_DEGREE)
  };
}

function useStyles() {
  const displayMode = useContext(DisplayMode);
  return StyleSheet.create({
    textStructureStyle: {
      // In the case where the text-structure uses flexWrap, align to center
      justifyContent: 'center'
    },
    defaultTextStyle: {
      fontSize: displayMode === 'digital' ? 22 : 34.375,
      lineHeight: (displayMode === 'digital' ? 22 : 34.375) * 1.5,
      // In the case where the text-structure is just text, align to center
      textAlign: 'center'
    },
    defaultFractionTextStyle: {
      fontSize: displayMode === 'digital' ? 22 : 34.375,
      lineHeight: displayMode === 'digital' ? 22 : 34.375 * 1.5
    },
    defaultFractionDividerStyle: {
      minWidth: 22,
      marginVertical: 4
    }
  });
}
