// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValueWithin10';
import AdditionAndSubtraction from './AdditionAndSubtraction';
import Shape from './Shape';

const Term = newTermContent({
  term: 'Autumn',
  blocks: [PlaceValue, AdditionAndSubtraction, Shape]
});
export default Term;
