import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import { GattegnoChart } from '../../../../components/question/representations/Gattegno Chart/GattegnoChart';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { MULT } from '../../../../constants';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCe',
  description: 'aCe',
  keywords: ['Decimals', 'Multiplication', 'Gattegno'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(9))
      .length(2)
      .refine(arrayHasNoDuplicates, 'all numbers must be unique')
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 2);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [number1, number2] = numbers;

    const number3 = number2 / 10;
    const number4 = number1 + number3;

    // Options
    const number5 = number4 * 10;
    const number6 = number1 * 10 + number3;
    const number8 = number4 * 100;
    const number9 = number4 / 10;
    const number10 = number(math.evaluate(`${number4} + 10`));

    const random = seededRandom(props.question);

    const incorrectOptions = shuffle(
      [
        {
          value: number6,
          component: number6.toLocaleString()
        },
        {
          value: number4,
          component: number4.toLocaleString(undefined, { minimumFractionDigits: 2 })
        },
        {
          value: number8,
          component: number8.toLocaleString()
        },
        {
          value: number9,
          component: number9.toLocaleString()
        },
        {
          value: number10,
          component: number10.toLocaleString()
        }
      ],
      { random }
    );

    const shuffledItems = shuffle(
      [
        {
          value: number5,
          component: number5.toLocaleString(undefined, { minimumFractionDigits: 1 })
        },
        {
          value: number9,
          component: number9.toLocaleString()
        },
        {
          value: number10,
          component: number10.toLocaleString()
        },
        ...incorrectOptions.slice(0, 1)
      ],
      { random }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutXAndSelectYourAnswer(
          `${number4.toLocaleString()} ${MULT} ${(10).toLocaleString()}`
        )}
        testCorrect={[number5]}
        itemLayout={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <GattegnoChart
            number={number4}
            rowsToShow={[1, 0, -1, -2]}
            dimens={{ width: dimens.width, height: dimens.height - 48 }}
            arrowDirection="up"
          />
        )}
        renderItems={shuffledItems.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aCf',
  description: 'aCf',
  keywords: ['Decimals', 'Multiplication', 'Gattegno'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(9))
      .length(3)
      .refine(numArray => arrayHasNoDuplicates(numArray), 'all numbers must be unique')
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 3);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [number1, number2, number3] = numbers;

    const number4 = number2 / 10;
    const number5 = number3 / 100;
    const number6 = number(math.evaluate(`${number1} + ${number4} + ${number5}`));

    // Options
    const number7 = number6 * 10;
    const number8 = number6 * 100;
    const number10 = number(math.evaluate(`${number1} * 10 + ${number4} + ${number5}`));
    const number11 = number6 / 10;
    const number12 = number(math.evaluate(`${number6} + 10`));

    const random = seededRandom(props.question);

    const incorrectOptions = shuffle(
      [
        {
          value: number8,
          component: number8.toLocaleString(undefined, { minimumFractionDigits: 1 })
        },
        {
          value: number6,
          component: number6.toLocaleString(undefined, { minimumFractionDigits: 3 })
        },
        {
          value: number10,
          component: number10.toLocaleString()
        },
        {
          value: number11,
          component: number11.toLocaleString()
        },
        {
          value: number12,
          component: number12.toLocaleString()
        }
      ],
      { random }
    );

    const shuffledItems = shuffle(
      [
        {
          value: number7,
          component: number7.toLocaleString(undefined, { minimumFractionDigits: 2 })
        },
        ...incorrectOptions.slice(0, 3)
      ],
      { random }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutXAndSelectYourAnswer(
          `${number6.toLocaleString()} ${MULT} ${(10).toLocaleString()}`
        )}
        testCorrect={[number7]}
        itemLayout={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <GattegnoChart
            number={number6}
            rowsToShow={[1, 0, -1, -2]}
            dimens={{ width: dimens.width, height: dimens.height - 48 }}
          />
        )}
        renderItems={shuffledItems.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aCg',
  description: 'aCg',
  keywords: ['Decimal', 'Multiplication', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumber: z.number().min(1.01).max(99.99)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd']);

    const questionNumber =
      questionToShow === 'a'
        ? randomIntegerInclusive(11, 99, {
            constraint: x => x % 10 !== 0
          }) / 10
        : questionToShow === 'b'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : questionToShow === 'c'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10
        : randomIntegerInclusive(1001, 9999, {
            constraint: x => x % 10 !== 0
          }) / 100;

    return {
      questionNumber
    };
  },
  Component: props => {
    const {
      question: { questionNumber },
      translate
    } = props;

    const [sentence, answer] = [
      `${questionNumber.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${questionNumber} * 10`))
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={5}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCh',
  description: 'aCh',
  keywords: ['Decimals', 'Multiplication', 'Place value chart'],
  schema: z.object({
    givenNumber: z.number().min(1.1).max(9.9)
  }),
  simpleGenerator: () => {
    const givenNumber =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    return { givenNumber };
  },
  Component: props => {
    const {
      question: { givenNumber },
      translate
    } = props;

    const answer1 = givenNumber * 10;
    const answer2 = givenNumber * 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpCompleteMultiplications()}
        sentences={[
          `${givenNumber.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
          `${givenNumber.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(givenNumber)}
            columnsToShow={[2, 1, 0, -1, -2]}
            counterVariant="number"
            dimens={dimens}
          />
        )}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aCi',
  description: 'aCi',
  keywords: ['Decimals', 'Multiplication', 'Place value chart'],
  schema: z.object({
    givenNumber: z.number().min(1.01).max(9.99)
  }),
  simpleGenerator: () => {
    const givenNumber =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    return { givenNumber };
  },
  Component: props => {
    const {
      question: { givenNumber },
      translate
    } = props;

    const answer1 = givenNumber * 10;
    const answer2 = givenNumber * 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpCompleteMultiplications()}
        sentences={[
          `${givenNumber.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
          `${givenNumber.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <PlaceValueChart
            showZerosOnNegativeColumnPow
            number={ScientificNotation.fromNumber(givenNumber)}
            columnsToShow={[2, 1, 0, -1, -2]}
            counterVariant="number"
            dimens={dimens}
          />
        )}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aCj',
  description: 'aCj',
  keywords: ['Decimal', 'Multiplication', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(1.01).max(9.99).multipleOf(0.01)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 100 !== 0
      }) / 100;

    return {
      number1
    };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} * 10`))
    ];

    const [sentence2, answer2] = [
      `${number1.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} * 100`))
    ];

    const [sentence3, answer3] = [
      `${number1.toLocaleString()} ${MULT} ${(1000).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1}  * 1000`))
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) &&
          compareFloats(answer[1][0], answer2) &&
          compareFloats(answer[2][0], answer3)
        }
        sentences={[sentence1, sentence2, sentence3]}
        inputMaxCharacters={6}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString()],
            [answer3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
