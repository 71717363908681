import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { numberEnum } from '../../../../utils/zod';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { auN, auO, auR } from 'common/src/SchemeOfLearning/Year 3/Summer/Shape/1TurnsAndAngles';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const arrowColors = ['blue', 'green', 'pink', 'purple', 'yellow'] as const;

const Question1 = newQuestionContent({
  uid: 'aya',
  description: 'aya',
  keywords: ['Turns'],
  schema: z.object({
    turn: numberEnum([90, 180, 270, 360])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const turn = getRandomFromArray([90, 180, 270, 360] as const);

    return {
      turn
    };
  },
  Component: props => {
    const {
      translate,
      question: { turn },
      displayMode
    } = props;

    const selectables = [
      ['180', translate.directions.halfTurn()],
      ['90', translate.directions.quarterTurn()],
      ['360', translate.directions.fullTurn()],
      ['270', translate.directions.threeQuarterTurn()]
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectLabelToMatch()}
        pdfTitle={translate.instructions.selectLabelToMatch()}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[turn.toString()]}
        questionHeight={900}
        selectableTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        leftContent={
          <MeasureView>
            {dimens => (
              <AngleFromLines
                degrees={[0, turn]}
                dimens={dimens}
                includeRightAngle={false}
                showArrowHead={true}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayb',
  description: 'ayb',
  keywords: ['Turns'],
  schema: z.object({
    turn: numberEnum([90, 180, 270, 360]),
    isClockwise: z.boolean()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const turn = getRandomFromArray([90, 180, 270, 360] as const);
    const isClockwise = getRandomBoolean();

    return {
      turn,
      isClockwise
    };
  },
  Component: props => {
    const {
      translate,
      question: { turn, isClockwise }
    } = props;

    const clockwise = translate.directions.clockwise();
    const anti = translate.directions.antiClockwise();
    const degrees = (isClockwise ? [0, turn] : [turn === 360 ? turn : 360 - turn, 0]) as [
      number,
      number
    ];

    const options = [
      ['180', translate.directions.halfTurn()],
      ['90', translate.directions.quarterTurn()],
      ['360', translate.directions.fullTurn()],
      ['270', translate.directions.threeQuarterTurn()]
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragWordsToDescribeTheTurn()}
        pdfTitle={translate.instructions.useWordsToDescribeTheTurn()}
        items={[clockwise, anti]}
        questionHeight={900}
        pdfItemVariant="tallRectangle"
        itemVariant="rectangle"
        Content={({ dimens }) => (
          <AngleFromLines
            degrees={degrees}
            dimens={dimens}
            includeRightAngle={false}
            showArrowHead={true}
          />
        )}
        sentence={`${Object.fromEntries(options)[turn]} <ans/>`}
        testCorrect={[isClockwise ? clockwise : anti]}
      />
    );
  }
});

const Question3 = { ...auN, uid: 'ayc', description: 'ayc' as const };

const Question4 = newQuestionContent({
  uid: 'ayd',
  description: 'ayd',
  keywords: ['Turns'],
  schema: z.object({
    startingAngle: numberEnum([90, 180, 270, 360]),
    rotationIndex: z.number().min(0).max(3),
    color: z.enum(['blue', 'green', 'pink', 'purple', 'yellow']),
    isClockwise: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const startingAngle = getRandomFromArray([90, 180, 270, 360] as const);
    const rotationIndex = randomIntegerInclusive(0, 3);
    const color = getRandomFromArray(arrowColors);
    const isClockwise = getRandomBoolean();

    return { startingAngle, rotationIndex, color, isClockwise };
  },

  Component: props => {
    const {
      question: { startingAngle, color, rotationIndex, isClockwise },
      translate
    } = props;

    const quarter = translate.directions.quarterTurn();
    const half = translate.directions.halfTurn();
    const full = translate.directions.fullTurn();
    const threeQuarter = translate.directions.threeQuarterTurn();

    const quarterAnti = translate.directions.quarterTurnAntiClockwise();
    const halfAnti = translate.directions.halfTurnAntiClockwise();
    const fullAnti = translate.directions.fullTurnAntiClockwise();
    const threeQuarterAnti = translate.directions.threeQuarterTurnAntiClockwise();

    const quarterClockwise = translate.directions.quarterTurnClockwise();
    const halfClockwise = translate.directions.halfTurnClockwise();
    const fullClockwise = translate.directions.fullTurnClockwise();
    const threeQuarterClockwise = translate.directions.threeQuarterTurnClockwise();

    const options = isClockwise
      ? [quarter, half, threeQuarter, full]
      : [threeQuarter, half, quarter, full];

    const text = isClockwise
      ? [quarterClockwise, halfClockwise, threeQuarterClockwise, fullClockwise]
      : [threeQuarterAnti, halfAnti, quarterAnti, fullAnti];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectArrowAfterTurn()}
        pdfTitle={translate.instructions.circleArrowAfterTurn()}
        testCorrect={options.filter(val => val === options[rotationIndex])}
        numItems={4}
        questionHeight={800}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, alignItems: 'center', flexDirection: 'row', gap: 30 }}
          >
            <View style={{ transform: `rotate(${startingAngle - 90}deg)` }}>
              <AssetSvg name={`Arrows/arrow_${color}`} width={dimens.height * 0.8} />
            </View>
            <View style={{ width: 300 }}>
              <Text variant="WRN400" style={{ textAlign: 'center' }}>
                {text[rotationIndex]}
              </Text>
            </View>
          </View>
        )}
        renderItems={({ dimens }) => {
          return options.map((string, i) => ({
            value: string,
            component: (
              <View style={{ transform: `rotate(${startingAngle + 90 * i}deg)` }}>
                <AssetSvg name={`Arrows/arrow_${color}`} width={dimens.height * 0.4} />
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question5 = { ...auO, uid: 'aye', description: 'aye' as const };

const Question6 = { ...auR, uid: 'ayf', description: 'ayf' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandAnglesAsTurns',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
