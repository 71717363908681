import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  ad8,
  aeb,
  aec,
  aed
} from 'common/src/SchemeOfLearning/Year 5/Autumn/PlaceValue/11CompareAndOrderNumbersTo1000000';
import { arrayHasNoDuplicates, sortNumberArray } from 'common/src/utils/collections';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import Text from '../../../../components/typography/Text';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1: typeof ad8 = { ...ad8, uid: 'ae0', description: 'ae0' };

const Question2: typeof aeb = {
  ...aeb,
  uid: 'ae1',
  description: 'ae1',
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsDescendingOrder' : 'dragCardsAscendingOrder';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsDescendingOrder' : 'useCardsAscendingOrder';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
};

const Question3: typeof aec = { ...aec, uid: 'ae2', description: 'ae2' };

const Question4: typeof aed = {
  ...aed,
  uid: 'ae3',
  description: 'ae3',
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsDescendingOrder' : 'dragCardsAscendingOrder';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsDescendingOrder' : 'useCardsAscendingOrder';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
};

const Question5 = newQuestionContent({
  uid: 'ae4',
  description: 'ae4',
  keywords: ['Place value', 'Compare', '1,000,000', 'Million'],
  schema: z.object({
    var1Value: z.number().int().min(1).max(99),
    var3Value: z.number().int().min(1).max(99),
    var4Thousands: z.number().int().min(1).max(9),
    var5Value: z.number().int().min(1).max(9),
    var6Thousands: z.number().int().min(10).max(990),
    var7Value: z.number().int().min(1).max(99)
  }),
  questionHeight: 950,
  simpleGenerator: () => {
    // For the first pair, the second number is the string 'half a million'
    const var1Value = randomIntegerInclusive(1, 99);

    // For the second pair, one of the numbers is a multiple of 100,000 written as "$ thousand"
    const var3Value = randomIntegerInclusive(1, 99);
    const var4Thousands = randomIntegerInclusive(1, 9);

    // For the third pair, one of the numbers is <100,000, and the other is written as a multiple of 1,000 in words
    const var5Value = randomIntegerInclusive(1, 9);
    const var6Thousands = randomIntegerInclusive(1, 99) * 10;

    // For the fourth pair, one of the numbers is the other-1.
    const var7Value = randomIntegerInclusive(1, 99);

    return {
      var1Value,
      var3Value,
      var4Thousands,
      var5Value,
      var6Thousands,
      var7Value
    };
  },
  Component: ({
    question: { var1Value, var3Value, var4Thousands, var5Value, var6Thousands, var7Value },
    translate,
    displayMode
  }) => {
    // All variables are made of numbers that are multiples of 10,000.
    // All numbers are given as strings, which may differ from the result of .toLocaleString
    const numberToVar = (x: number) => ({ value: x, string: x.toLocaleString() });

    const var1 = numberToVar(var1Value * 10000);
    const var2 = { value: 500000, string: translate.misc.halfAMillion() };
    const var3 = numberToVar(var3Value * 10000);
    const var4 = {
      value: var4Thousands * 1000,
      string: `${var4Thousands.toLocaleString()} ${translate.powersOfTen.thousands(1)}`
    };
    const var5 = numberToVar(var5Value * 10000);
    const var6 = {
      value: var6Thousands * 1000,
      string: `${var6Thousands.toLocaleString()} ${translate.powersOfTen.thousands(1)}`
    };
    const var7 = numberToVar(var7Value * 10000);
    const var8 = numberToVar(var7Value * 10000 - 1);

    const random = seededRandom({
      var1Value,
      var3Value,
      var4Thousands,
      var5Value,
      var6Thousands,
      var7Value
    });

    const sentences = shuffle(
      [
        shuffle([var1, var2], { random }),
        shuffle([var3, var4], { random }),
        shuffle([var5, var6], { random }),
        shuffle([var7, var8], { random })
      ],
      { random }
    );

    const items = ['>', '<', '='] as const;

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        useArrows={false}
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        items={items.map(item => {
          return {
            value: item,
            component: item
          };
        })}
        statementStyle={{ justifyContent: 'center' }}
        statements={sentences.map(([lhs, rhs]) => {
          return {
            correctAnswer: lhs.value > rhs.value ? '>' : lhs.value < rhs.value ? '<' : '=',
            lhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
              >
                {lhs.string}
              </Text>
            ),
            rhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
              >
                {rhs.string}
              </Text>
            )
          };
        })}
        questionHeight={950}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ae5',
  description: 'ae5',
  keywords: ['Place value', 'Order', '10,000,000', 'Million'],
  schema: z.object({
    var1Millions: z.number().int().min(1).max(9),
    var3Thousands: z.number().int().min(10).max(990),
    var4Value: z.number().int().min(1).max(999),
    order: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const { var4Value, var3Thousands, var1Millions } = rejectionSample(
      () => {
        // var1 is a multiple of a million, <10,000,000
        const var1Millions = randomIntegerInclusive(1, 9);

        // var3 is a multiple of 10,000, written as '? thousand'
        const var3Thousands = randomIntegerInclusive(1, 99) * 10;

        // var4 is any multiple of 1,000, written as a decimal number
        const var4Value = randomIntegerInclusive(1, 999);

        return { var1Millions, var3Thousands, var4Value };
      },
      val =>
        arrayHasNoDuplicates([
          val.var1Millions * 1000000,
          val.var3Thousands * 1000,
          val.var4Value * 1000,
          500000
        ])
    );

    return {
      var1Millions,
      var3Thousands,
      var4Value,
      order: getRandomFromArray(['ascending', 'descending'] as const)
    };
  },
  Component: ({ question: { var1Millions, var3Thousands, var4Value, order }, translate }) => {
    const var1 = {
      value: var1Millions * 1000000,
      string: `${var1Millions.toLocaleString()} ${translate.powersOfTen.millions(1)}`
    };

    // var2 is always 'half a million'
    const var2 = { value: 500000, string: translate.misc.halfAMillion() };

    const var3 = {
      value: var3Thousands * 1000,
      string: `${var3Thousands.toLocaleString()} ${translate.powersOfTen.thousands(1)}`
    };

    const var4 = { value: var4Value * 1000, string: (var4Value * 1000).toLocaleString() };

    const numbers = shuffle([var1, var2, var3, var4], {
      random: seededRandom({ var1Millions, var3Thousands, var4Value, order })
    });

    return (
      <QF4DragOrderVertical
        title={
          order === 'ascending'
            ? translate.instructions.dragCardsToPlaceNumbersInAscendingOrder()
            : translate.instructions.dragCardsToPlaceNumbersInDescendingOrder()
        }
        pdfTitle={
          order === 'ascending'
            ? translate.instructions.useCardsToPlaceNumbersInAscendingOrder()
            : translate.instructions.useCardsToPlaceNumbersInDescendingOrder()
        }
        testCorrect={sortNumberArray(
          numbers.map(x => x.value),
          order
        )}
        items={numbers.map(({ value, string }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center', lineHeight: 50 }}>
              {string}
            </Text>
          )
        }))}
        topLabel={
          order === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          order === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderAnyIntegers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
