import { sortNumberArray } from './collections';

/**
 * This is a function to find an amount of common multiples between two numbers.
 * It takes in a firstNum, a secondNum, and an amount of common multiples you want returned.
 * firstNum and secondNum should either both be positive, or both be negative.
 */
export function findCommonMultiples(firstNum: number, secondNum: number, amount: number): number[] {
  const result = [];

  // Counter to keep track of number of iterations of the for loop.

  const maxIterations = 100000;

  // Loop until the result array has x elements.
  for (let i = 1; result.length < amount; i++) {
    // If maxIterations has been reached, prevent an infinite loop and break.
    if (i > maxIterations) {
      break;
    }

    const multiple = firstNum * i;

    // If this multiple is divisible by secondNum, it is a common multiple of firstNum and secondNum.
    if (multiple % secondNum === 0) {
      result.push(multiple);
    }
  }

  return result;
}

/**
 * Checks if there are any common multiples between firstNum and secondNum up to a certain limit of multiples.
 * This checks from firstNum * 1 to firstNum * limit, and secondNum * 1 to secondNum * limit.
 * firstNum and secondNum should either both be positive, or both be negative.
 */
export function haveCommonMultipleWithinXSteps(
  firstNum: number,
  secondNum: number,
  limit: number
): boolean {
  const [smaller, larger] = sortNumberArray([firstNum, secondNum]);

  for (let i = 1; i <= limit; i++) {
    // Check if smaller*i is a multiple of larger
    if ((smaller * i) % larger === 0) {
      return true;
    }
  }
  return false;
}

/**
 * Find the least common multiple (LCM) of two given numbers.
 * Numbers can be positive and/or negative, will always return a positive number
 * If one of the numbers is zero, function will always return zero
 */
export function leastCommonMultiple(firstNum: number, secondNum: number): number {
  if (firstNum === 0 || secondNum === 0) {
    return 0;
  }

  const absNum1 = Math.abs(firstNum);
  const absNum2 = Math.abs(secondNum);
  const gcd = greatestCommonDivisor([absNum1, absNum2]);

  return (absNum1 * absNum2) / gcd;
}

/**
 * Find the least common multiple (LCM) of three given numbers.
 * Numbers can be positive and/or negative, will always return a positive number
 * If any of the numbers are zero, function will always return zero
 */
export function threeNumberLCM(a: number, b: number, c: number): number {
  return leastCommonMultiple(leastCommonMultiple(a, b), c);
}

/**
 * Find the greatest common divisor (GCD) between 2 given numbers
 * Numbers must be either be both positive or both negative.
 * If one of the numbers is zero, then function will always return the non-zero number
 * Returns 1 if there are no common divisors (other than 1) for both numbers.
 */
export function greatestCommonDivisor(nums: number[]): number {
  if (nums.length < 2) {
    throw new Error('At least two numbers are required to find the greatest common divisor.');
  }

  return nums.reduce((gcd, num) => findGCD(gcd, num), nums[0]);
}

function findGCD(a: number, b: number): number {
  if (Number.isNaN(a) && Number.isNaN(b)) return NaN;
  return b === 0 ? a : findGCD(b, a % b);
}

/**
 * Creates filled array of successive multiples of an increment
 */
export const filledNumberTrackArray = (
  startingNumber: number,
  increment: number,
  length: number
): string[] => {
  return Array.from({ length }, (_, idx) => (startingNumber + increment * idx).toLocaleString());
};

/**
 * Create an array of successive multiples of some increment, as locale strings, with most
 * entries replaced by $ans. This is used by number track questions.
 * Will also create an array of answers to be plugged into the testCorrect.
 *
 * For example: `['8', '12', '$ans', '$ans', '$ans', '28', '$ans']`
 */
export const multiplesNumberTrackArray = (
  startingNumber: number,
  increment: number,
  extraTiles: number,
  tileToShowIndex?: number
) => {
  const numberTrackArray = [
    startingNumber.toLocaleString(),
    (startingNumber + increment).toLocaleString()
  ];
  const answerArray = [];

  for (let i = 0; i < extraTiles; i++) {
    answerArray.push((startingNumber + increment * (i + 2)).toString());
    numberTrackArray.push('<ans/>');
  }

  if (tileToShowIndex) {
    numberTrackArray[tileToShowIndex] = (
      startingNumber +
      increment * tileToShowIndex
    ).toLocaleString();

    answerArray.splice(tileToShowIndex - 2, 1);
  }

  return { numberTrackArray, answerArray };
};
