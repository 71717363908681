import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { isValidRectangle, isValidSquare } from '../../../../utils/shapes';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { randomUniqueIntegersInclusive, rejectionSample } from '../../../../utils/random';
import { sortNumberArray } from '../../../../utils/collections';
import { GridPolygon } from '../../../../utils/gridUtils';
import { colors } from '../../../../theme/colors';
import { isOnLine } from '../../../../utils/lines';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ben',
  description: 'ben',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half', 'Rectangle'],
  schema: z.object({
    x1: z.number(),
    x2: z.number(),
    y1: z.number(),
    y2: z.number()
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(1, 9, 2);
        const yCoords = randomUniqueIntegersInclusive(1, 5, 2);

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          isValidRectangle(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          !isValidSquare(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          (y2 - y1) % 2 === 0 &&
          (x2 - x1) % 2 === 0
        );
      }
    );

    return { x1, x2, y1, y2 };
  },
  Component: ({ translate, question: { x1, x2, y1, y2 }, displayMode }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapTwoDotsToSplitTheShapeInHalf()}
        pdfTitle={translate.ks1PDFInstructions.drawTwoCrossesToSplitTheShapeInHalf()}
        numPoints={2}
        gridChildren={
          <GridPolygon
            key="border"
            points={[
              [x1, y1],
              [x1, y2],
              [x2, y2],
              [x2, y1]
            ]}
            color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
            showBorder
          />
        }
        testCorrect={userAnswer => {
          const [p, q] = userAnswer;
          if (p === undefined || q === undefined) return false;

          // We want to know if it intersects the rectangle's center
          const rectangleCenter = { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
          const intersectsRectangleCenter = isOnLine({
            coordinatesA: p,
            coordinatesB: q,
            x: rectangleCenter.x,
            y: rectangleCenter.y
          });

          // Additionally we want to check that the points they selected are both either on the edges of the rectangle,
          // or outside the rectangle.
          const isOutsideRectangle = ({ x, y }: { x: number; y: number }) =>
            x <= x1 || x >= x2 || y <= y1 || y >= y2;

          return intersectsRectangleCenter && isOutsideRectangle(p) && isOutsideRectangle(q);
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidAnswer() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAHalfOfAnObjectOrAShape',
  questionTypes: [Question1],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
