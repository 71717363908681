import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Text from '../../../../components/typography/Text';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { MULT } from '../../../../constants';
import { LabelledRATriangle } from '../../../../components/question/representations/LabelledRATriangle';
import ContentBox from '../../../../components/molecules/ContentBox';
import TextStructure from '../../../../components/molecules/TextStructure';
import { numberEnum } from '../../../../utils/zod';
import { arraysHaveSameContentsUnordered } from '../../../../utils/collections';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aWK',
  description: 'aWK',
  questionHeight: 1000,
  keywords: ['Triangle', 'Area'],
  schema: z.object({
    length: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(4),
    orientation: z.enum(['A', 'B', 'C', 'D']),
    wrongDiff: numberEnum([1, -1])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(2, 5);
    const width = randomIntegerInclusive(2, 4);
    const orientation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);
    const wrongDiff = getRandomFromArray([1, -1] as const);

    return { length, width, orientation, wrongDiff };
  },
  Component: props => {
    const {
      question: { length, width, orientation, wrongDiff },
      translate,
      displayMode
    } = props;

    const points = {
      A: [
        [0, 0],
        [length, 0],
        [0, width]
      ],
      B: [
        [0, 0],
        [length, 0],
        [length, width]
      ],
      C: [
        [0, 0],
        [length, width],
        [0, width]
      ],
      D: [
        [length, 0],
        [length, width],
        [0, width]
      ]
    };

    const rectArea = length * width;

    const wrong = rectArea + wrongDiff;
    const wrong2 = (rectArea - wrongDiff) / 2;

    const items = shuffle(
      [
        rectArea.toLocaleString(),
        (rectArea / 2).toLocaleString(),
        wrong.toLocaleString(),
        wrong2.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentencesDrag
        actionPanelVariant={'end'}
        questionHeight={1000}
        title={translate.instructions.selectTheAreasOfTheRectangleAndTriangle()}
        pdfTitle={translate.instructions.useCardsToSelectTheAreasOfTheRectangleAndTriangle()}
        items={items}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: displayMode === 'digital' ? 50 : 350
            }}
          >
            <DisplayShapeOnGridWithBorder
              dimens={{ height: dimens.height * 0.75, width: dimens.width / 2 - 10 }}
              points={[
                [0, 0],
                [length, 0],
                [length, width],
                [0, width]
              ]}
            />
            <DisplayShapeOnGridWithBorder
              dimens={{ height: dimens.height * 0.75, width: dimens.width / 2 - 10 }}
              points={points[orientation] as [number, number][]}
            />
          </View>
        )}
        sentencesStyle={{
          flexDirection: 'row',
          justifyContent: 'center',
          columnGap: displayMode === 'digital' ? 100 : 350
        }}
        sentences={[
          translate.answerSentences.ansCmSquared(),
          translate.answerSentences.ansCmSquared()
        ]}
        testCorrect={[[rectArea.toLocaleString()], [(rectArea / 2).toLocaleString()]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWL',
  description: 'aWL',
  keywords: ['Triangle', 'Area'],
  schema: z.object({
    length: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(4),
    orientation: z.enum(['A', 'B', 'C', 'D']),
    rectArea: z.boolean()
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(2, 5);
    const width = randomIntegerInclusive(2, 4);
    const orientation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);
    const rectArea = getRandomBoolean();

    return { length, width, orientation, rectArea };
  },
  Component: props => {
    const {
      question: { length, width, orientation, rectArea },
      translate
    } = props;

    const points = {
      A: [
        [0, 0],
        [length, 0],
        [0, width]
      ],
      B: [
        [0, 0],
        [length, 0],
        [length, width]
      ],
      C: [
        [0, 0],
        [length, width],
        [0, width]
      ],
      D: [
        [length, 0],
        [length, width],
        [0, width]
      ]
    };

    const random = seededRandom(props.question);
    const wrongAnswers = getRandomSubArrayFromArray(
      [
        ['times3', translate.misc.XTimes(3)],
        ['thirds', translate.fractions.thirds(1)],
        ['times4', translate.misc.XTimes(4)],
        ['quarter', translate.fractions.quarters(1)]
      ],
      2,
      { random }
    );

    const selectables = shuffle(
      [
        ['double', translate.misc.double()],
        ['half', translate.fractions.halves(1)],
        ...wrongAnswers
      ],
      { random }
    );

    const sentence = rectArea
      ? 'theAreaOfTheRectangleIsXAreaOfTheTriangle'
      : 'theAreaOfTheTriangleIsXAreaOfTheRectangle';

    const answer = rectArea ? 'double' : 'half';
    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheCorrectWordToCompleteSentence()}
        pdfTitle={translate.instructions.circleTheCorrectWordToCompleteSentence()}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <View>
                <View style={{ flexDirection: 'row', columnGap: 20, paddingBottom: 30 }}>
                  <DisplayShapeOnGridWithBorder
                    dimens={{ height: dimens.height * 0.75, width: dimens.width / 2 - 20 }}
                    points={[
                      [0, 0],
                      [length, 0],
                      [length, width],
                      [0, width]
                    ]}
                  />
                  <DisplayShapeOnGridWithBorder
                    dimens={{ height: dimens.height * 0.75, width: dimens.width / 2 - 20 }}
                    points={points[orientation] as [number, number][]}
                  />
                </View>
                <Text variant="WRN400">{translate.answerSentences[sentence]()}</Text>
              </View>
            )}
          </MeasureView>
        }
        correctAnswer={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWM',
  description: 'aWM',
  keywords: ['Triangle', 'Area'],
  schema: z.object({
    length: z.number().int().min(3).max(8),
    width: z.number().int().min(2).max(4),
    orientation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(3, 8);
    const width = randomIntegerInclusive(2, 4, { constraint: x => (x * length) % 4 === 0 });
    const orientation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    return { length, width, orientation };
  },
  Component: props => {
    const {
      question: { length, width, orientation },
      translate
    } = props;

    const area = (length * width) / 2;

    const points = {
      A: [
        [0, 0],
        [length, 0],
        [0, width]
      ],
      B: [
        [0, 0],
        [length, 0],
        [length, width]
      ],
      C: [
        [0, 0],
        [length, width],
        [0, width]
      ],
      D: [
        [length, 0],
        [length, width],
        [0, width]
      ]
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfRightAngledTriangle()}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            dimens={dimens}
            cellSizeLabel={translate.units.numberOfCm(1)}
            points={points[orientation] as [number, number][]}
          />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[area.toString()]}
      />
    );
  }
});

const rALengths = [
  //base, length, hyp
  [3, 4, 5],
  [6, 8, 10],
  [9, 12, 15],
  [12, 16, 20],
  [15, 20, 25],
  [5, 12, 13],
  [10, 24, 26],
  [7, 24, 25],
  [20, 21, 29]
];

const Question4 = newQuestionContent({
  uid: 'aWN',
  description: 'aWN',
  keywords: ['Triangle', 'Area', 'Formula'],
  schema: z.object({
    lengths: z.array(z.number()),
    variation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);
    const lengths = getRandomFromArray(rALengths) as number[];

    return { lengths, variation };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { lengths, variation },
      translate,
      displayMode
    } = props;

    const [base, length, hyp] = lengths;

    const options = shuffle(
      [
        [
          'A',
          <TextStructure
            key={'A'}
            textVariant="WRN700"
            sentence={`<frac n="1" d="2"/> ${MULT} ${base.toLocaleString()} ${MULT} ${length.toLocaleString()}`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ],
        [
          'B',
          <TextStructure
            key={'B'}
            textVariant="WRN700"
            sentence={`<frac n="1" d="2"/> ${MULT} ${length.toLocaleString()} ${MULT} ${hyp.toLocaleString()}`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ],
        [
          'C',
          <TextStructure
            key={'C'}
            textVariant="WRN700"
            sentence={`<frac n="1" d="2"/> ${MULT} ${base.toLocaleString()} ${MULT} ${hyp.toLocaleString()}`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ],
        [
          'D',
          `${hyp.toLocaleString()} ${MULT} ${base.toLocaleString()} ${MULT} ${length.toLocaleString()}`
        ]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheCorrectCalculationToWorkOutTheAreaOfTheTriangle()}
        pdfTitle={translate.instructions.circleTheCorrectCalculationToWorkOutTheAreaOfTheTriangle()}
        selectableTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        selectables={Object.fromEntries(options)}
        questionHeight={1000}
        leftContent={
          <MeasureView>
            {dimens => (
              <View>
                <ContentBox containerStyle={{ padding: 0, marginBottom: 10 }}>
                  <TextStructure
                    textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
                    fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
                    sentence={translate.informationStrings.raTriangleAreaFormula()}
                  />
                </ContentBox>
                <LabelledRATriangle
                  dimens={{ ...dimens, height: dimens.height * 0.7 }}
                  labels={lengths.map(l => `${translate.units.numberOfCm(l)}`)}
                  variation={variation}
                />
              </View>
            )}
          </MeasureView>
        }
        correctAnswer={['A']}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWO',
  description: 'aWO',
  keywords: ['Triangle', 'Area'],
  schema: z.object({
    lengths: z.array(z.number()),
    variation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);
    const lengths = getRandomFromArray(rALengths) as number[];

    return { lengths, variation };
  },
  Component: props => {
    const {
      question: { lengths, variation },
      translate
    } = props;

    const area = (lengths[0] * lengths[1]) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfRightAngledTriangle()}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <LabelledRATriangle
            dimens={{ ...dimens, height: dimens.height }}
            labels={lengths.map(l => `${translate.units.numberOfCm(l)}`)}
            variation={variation}
          />
        )}
        sentence={`<frac n="1" d="2"/> ${MULT} <ans/> ${MULT} <ans/> = ${translate.answerSentences.ansCmSquared()}`}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            [userAnswer[0], userAnswer[1]],
            [lengths[0].toString(), lengths[1].toString()]
          ) && userAnswer[2] === area.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            lengths[0].toLocaleString(),
            lengths[1].toLocaleString(),
            area.toLocaleString()
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWP',
  description: 'aWP',
  keywords: ['Triangle', 'Area'],
  schema: z.object({
    lengths: z.array(z.number()),
    variation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);
    const lengths = getRandomFromArray(rALengths) as number[];

    return { lengths, variation };
  },
  Component: props => {
    const {
      question: { lengths, variation },
      translate
    } = props;

    const area = (lengths[0] * lengths[1]) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfRightAngledTriangle()}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <LabelledRATriangle
            dimens={{ ...dimens, height: dimens.height }}
            labels={lengths.map(l => `${translate.units.numberOfCm(l)}`)}
            variation={variation}
          />
        )}
        sentence={`${translate.answerSentences.areaEqualsAnsCm2()}`}
        testCorrect={[area.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfARightAngledTriangle',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
