import { useCallback, useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { Theme, useTheme } from '../../theme';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import { AnimationState, Draggable, DroppableEventData } from './draganddrop';
import AutoScaleText from '../typography/AutoScaleText';
import Text from '../typography/Text';
import { colors } from '../../theme/colors';
import { DRAGGABLE_DIMENS, DraggableVariant } from './utils';
import { DisplayMode } from '../../contexts/displayMode';

type Props<Payload> = {
  /** Default: square. */
  variant?: DraggableVariant;
  children: string | ElementOrRenderFunction;
  payload: Payload;
  id: string | number;
  moveOrCopy: 'move' | 'copy';
  channel?: string | number;
  /** Default: true. */
  draggableIsPresent?: boolean;
  /** Default: false. */
  hideBackground?: boolean;
  /** If different widths are required per draggable */
  widthOverride?: number;
  /** Default: do nothing. */
  onDraggedToNowhere?: () => void;
  style?: StyleProp<ViewStyle>;
  draggableStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textVariant?: keyof Theme['fonts'];
  /** If you want auto text scaling, provide this or textSizeAutoScaleGroup, but not both. */
  textScaleToLongestLength?: number;
  /** If you want auto text scaling, provide this or textScaleToLongestLength, but not both. */
  textSizeAutoScaleGroup?: number | string;
  textLetterEmWidth?: number;
  maxLines?: number;
};

/**
 * A variant of the general {@link Draggable} component, specialized to our UI spec.
 *
 * This component makes it easier to work with drag and drop, than using {@link Draggable} and {@link Droppable}
 * directly.
 *
 * This component shows a square or rectangular, fixed-size draggable piece. The default styling matches the UI design
 * for draggable items, and it leaves a shadowed region behind. It carries the given payload, which is usually the
 * value that your draggable represents.
 *
 * Has two modes: 'move' (i.e. one-to-one or one-to-many; each draggable is in exactly one place) or 'copy' (i.e.
 * many-to-one or many-to-many; each draggable can be in many places).
 *
 * All you need to provide is the payload and whether the draggable is present (which should be some state managed by
 * something else). By default it uses a fixed text size, but by passing in a `textSizeAutoScaleGroup` you can utilize
 * auto-scale text. Also, all of the style can be overridden.
 */
export default function DragAndDropSource<Payload>({
  variant = 'square',
  children,
  payload,
  id,
  moveOrCopy,
  channel = 0,
  draggableIsPresent = true,
  hideBackground = false,
  widthOverride,
  onDraggedToNowhere = () => {
    /* Do nothing */
  },
  style,
  draggableStyle,
  textStyle,
  textVariant,
  textScaleToLongestLength,
  textSizeAutoScaleGroup,
  textLetterEmWidth,
  maxLines = 1
}: Props<Payload>) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(variant, hideBackground, displayMode);

  const draggableStyleWorklet = useCallback(
    (state: AnimationState) => {
      'worklet';
      if (!draggableIsPresent) {
        return { opacity: 0 };
      }

      if (moveOrCopy === 'move') {
        // By default, you drag a copy around. We want to hide the original when the copy is being dragged.
        return state.home ? { opacity: 1 } : { opacity: 0 };
      } else {
        // Dragging a copy around is fine!
        return { opacity: 1 };
      }
    },
    [draggableIsPresent, moveOrCopy]
  );

  const onDragEnd = useCallback(
    (event: { droppable?: DroppableEventData }) => {
      if (event.droppable === undefined) {
        onDraggedToNowhere();
      }
    },
    [onDraggedToNowhere]
  );

  return (
    <View style={[styles.container, style]}>
      <Draggable
        style={[
          styles.draggable,
          draggableStyle,
          widthOverride !== undefined ? { width: widthOverride } : undefined
        ]}
        styleWorklet={draggableStyleWorklet}
        id={id}
        payload={payload}
        channel={channel}
        onDragEnd={onDragEnd}
        dragEnabled={draggableIsPresent}
        // TODO: Fix draggable shadows - uncommenting the following line crashes iOS and Android when draggables are shown.
        // draggingHoverStyle={styles.draggableHoverShadow}
      >
        {(() => {
          if (typeof children === 'string') {
            if (textScaleToLongestLength !== undefined || textSizeAutoScaleGroup !== undefined) {
              // Use auto-scale text. Only one of these props should be defined.
              return (
                <AutoScaleText
                  variant={textVariant}
                  longestInGroup={textScaleToLongestLength}
                  group={textSizeAutoScaleGroup}
                  maxLines={maxLines}
                  letterEmWidth={textLetterEmWidth}
                  minFontSize={displayMode === 'digital' ? 22 : 28}
                  maxFontSize={displayMode === 'digital' ? 40 : 50}
                  containerStyle={{
                    width: '90%',
                    height: '95%'
                  }}
                  textStyle={[styles.text, textStyle]}
                >
                  {children}
                </AutoScaleText>
              );
            } else {
              return (
                <Text
                  variant={textVariant}
                  numberOfLines={maxLines}
                  style={[styles.text, textStyle]}
                >
                  {children}
                </Text>
              );
            }
          } else {
            return resolveElementOrRenderFunction(children);
          }
        })()}
      </Draggable>
    </View>
  );
}

function useStyles(
  variant: DraggableVariant,
  hideBackground: boolean,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) {
  const theme = useTheme();

  return useMemo(() => {
    const { width: draggableWidth, height: draggableHeight } = DRAGGABLE_DIMENS[variant];

    return StyleSheet.create({
      container: {
        backgroundColor: hideBackground ? 'transparent' : theme.colors.absentItemBackground,
        shadowColor: hideBackground ? 'transparent' : colors.black,
        shadowOffset: hideBackground ? { width: 0, height: 0 } : { width: 0, height: 2 },
        shadowOpacity: hideBackground ? 0 : 0.075,
        shadowRadius: hideBackground ? 0 : 2,
        borderRadius: 8
      },

      draggable: {
        width: draggableWidth,
        height: draggableHeight,
        borderRadius: 8,
        borderWidth: theme.buttonBorderWidth,
        backgroundColor: theme.colors.background,
        borderColor: displayMode === 'digital' ? theme.colors.backgroundBorder : 'black',
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.075
      },

      draggableHoverShadow: {
        shadowColor: colors.black,
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.15,
        shadowRadius: 16
      },

      text: {
        fontSize: displayMode === 'digital' ? 40 : 50,
        lineHeight: displayMode === 'digital' ? 60 : 75,
        color: displayMode === 'digital' ? theme.colors.primary : 'black'
      }
    });
  }, [
    displayMode,
    hideBackground,
    theme.buttonBorderWidth,
    theme.colors.absentItemBackground,
    theme.colors.background,
    theme.colors.backgroundBorder,
    theme.colors.primary,
    variant
  ]);
}
