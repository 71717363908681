import { colors } from '../theme/colors';
import {
  createHundredSquareShape,
  numbersToHundredSquareColors,
  rotateShape180,
  rotateShape270,
  rotateShape90
} from './shapes';

export const hundredSquareShapeNames = [
  'Half rows',
  'Half alternating rows',
  'Half split rows',
  'Half squares',
  'Quarter rows',
  'Quarter rows one side',
  'Quarter alternating rows',
  'Quarter square',
  'Three quarters rows',
  'Three quarters rows one side',
  'Three quarters alternating rows',
  'Three quarters squares'
] as const;

export type HundredSquareShapeName = (typeof hundredSquareShapeNames)[number];

/**
 * Function to return the matching HundredSquareShapeName's shape, and its cellColorMap.
 * Takes in a shapeName, a color (defaults to prussianBlue), and an optional degree of rotation.
 * */

export const getPremadeHundredSquareShape = (
  shapeName: HundredSquareShapeName,
  color: string = colors.prussianBlue,
  rotation?: 0 | 90 | 180 | 270
) => {
  const rotateShape = (shape: boolean[][]) => {
    switch (rotation) {
      case 270:
        return rotateShape270(shape);
      case 180:
        return rotateShape180(shape);
      case 90:
        return rotateShape90(shape);
      case 0:
      default:
        return shape;
    }
  };

  switch (shapeName) {
    case 'Half rows':
      return {
        shape: rotateShape(createHundredSquareShape(50)),
        cellColorMap: numbersToHundredSquareColors([50], [color], rotation)
      };
    case 'Half alternating rows':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    case 'Half split rows':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          [
            color,
            'white',
            'white',
            color,
            color,
            'white',
            'white',
            color,
            color,
            'white',
            'white',
            color,
            color,
            'white',
            'white',
            color,
            color,
            'white',
            'white',
            color
          ],
          rotation
        )
      };
    case 'Half squares':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          [
            color,
            'white',
            color,
            'white',
            color,
            'white',
            color,
            'white',
            color,
            'white',
            'white',
            color,
            'white',
            color,
            'white',
            color,
            'white',
            color,
            'white',
            color
          ],
          rotation
        )
      };
    case 'Quarter rows':
      return {
        shape: rotateShape(createHundredSquareShape(25)),
        cellColorMap: numbersToHundredSquareColors([25], [color], rotation)
      };
    case 'Quarter rows one side':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [5, 15, 5, 15, 5, 15, 5, 15, 5, 15],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    case 'Quarter alternating rows':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [5, 20, 5, 10, 5, 20, 5, 10, 5, 15],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    case 'Quarter square':
      return {
        shape: rotateShape(createHundredSquareShape(50)),
        cellColorMap: numbersToHundredSquareColors(
          [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    case 'Three quarters rows':
      return {
        shape: rotateShape(createHundredSquareShape(75)),
        cellColorMap: numbersToHundredSquareColors([75], [color], rotation)
      };
    case 'Three quarters rows one side':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [10, 5, 15, 5, 15, 5, 15, 5, 15, 5, 5],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white', color],
          rotation
        )
      };
    case 'Three quarters alternating rows':
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [15, 5, 10, 5, 20, 5, 10, 5, 20, 5],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    case 'Three quarters squares': {
      return {
        shape: rotateShape(createHundredSquareShape(100)),
        cellColorMap: numbersToHundredSquareColors(
          [55, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          [color, 'white', color, 'white', color, 'white', color, 'white', color, 'white'],
          rotation
        )
      };
    }
  }
};
