import { Audio } from 'expo-av';
import { soundFiles } from '../components/molecules/AudioFiles';
import { AVPlaybackSource, AVPlaybackStatus } from 'expo-av';

let _player: AudioPlayer | null = null;

const NUM_CONCURRENT_SOUNDS = 9;

type soundChoices =
  | 'select'
  | 'unselect'
  | 'correct'
  | 'pickup'
  | 'drop'
  | 'delete'
  | 'numpad'
  | 'numpaddel'
  | 'continue'
  | 'loading'
  | 'logo'
  | 'quizstart'
  | 'hundredpercent'
  | 'welldone'
  | 'resultsscreen'
  | 'tablecelloff'
  | 'tablecellon'
  | 'modal';

class AudioPlayer {
  sounds: Record<soundChoices, AVPlaybackSource[]> | null;
  indices: Partial<Record<soundChoices, number>>;
  audioEnabled: boolean;
  player: (Audio.Sound | null)[];
  playQueue: AVPlaybackSource[][];

  constructor(audioEnabled: boolean) {
    this.sounds = null;
    this.indices = {};
    this.audioEnabled = audioEnabled;
    this.player = Array(NUM_CONCURRENT_SOUNDS).fill(null);
    this.playQueue = Array.from({ length: NUM_CONCURRENT_SOUNDS }, () => []);
  }

  async initialize() {
    this.sounds = {
      select: [soundFiles.select],
      unselect: [soundFiles.unselect],
      correct: [soundFiles.correct1, soundFiles.correct2, soundFiles.correct3],
      pickup: [soundFiles.pickup1, soundFiles.pickup2, soundFiles.pickup3],
      drop: [soundFiles.drop1, soundFiles.drop2, soundFiles.drop3],
      delete: [soundFiles.delete1, soundFiles.delete2, soundFiles.delete3],
      numpad: [
        soundFiles.numpad0,
        soundFiles.numpad1,
        soundFiles.numpad2,
        soundFiles.numpad3,
        soundFiles.numpad4,
        soundFiles.numpad5,
        soundFiles.numpad6,
        soundFiles.numpad7,
        soundFiles.numpad8,
        soundFiles.numpad9
      ],
      numpaddel: [soundFiles.numpadDelete],
      continue: [soundFiles.continue],
      loading: [soundFiles.loading],
      logo: [soundFiles.logo],
      quizstart: [soundFiles.quizStart],
      hundredpercent: [soundFiles.hundredPercent],
      welldone: [soundFiles.wellDone],
      resultsscreen: [soundFiles.resultsScreen],
      tablecelloff: [
        soundFiles.TableCellDelete1,
        soundFiles.TableCellDelete2,
        soundFiles.TableCellDelete3,
        soundFiles.TableCellDelete4,
        soundFiles.TableCellDelete5,
        soundFiles.TableCellDelete6
      ],
      tablecellon: [
        soundFiles.TableCellRow1,
        soundFiles.TableCellRow2,
        soundFiles.TableCellRow3,
        soundFiles.TableCellRow4,
        soundFiles.TableCellRow5,
        soundFiles.TableCellRow6
      ],
      modal: [soundFiles.modal]
    };

    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true
    });

    for (let i = 0; i < this.player.length; i++) {
      this.player[i] = new Audio.Sound();
      this.player[i]?.setOnPlaybackStatusUpdate(this._onPlaybackStatusUpdate(i));
    }
  }

  setSoundEnabled(enabled: boolean) {
    this.audioEnabled = enabled;
  }

  _onPlaybackStatusUpdate = (index: number) => async (playbackStatus: AVPlaybackStatus) => {
    if (playbackStatus.isLoaded && playbackStatus.didJustFinish) {
      await this.player[index]?.unloadAsync();
      this.playQueue[index].shift();
    }
  };

  async _playSoundFromQueue(queueNum: number) {
    const sound = this.playQueue[queueNum][0];
    if (this.player[queueNum]?._loaded) {
      await this.player[queueNum]?.unloadAsync();
    }
    await this.player[queueNum]?.loadAsync(sound, { shouldPlay: true });
  }

  async playSound(soundChoice: soundChoices, version?: number) {
    if (this.audioEnabled && this.sounds) {
      const index = version ?? this.indices[soundChoice] ?? 0;
      this.indices[soundChoice] = (index + 1) % this.sounds[soundChoice].length;

      const sound = this.sounds[soundChoice][index];

      const firstEmptyQueueIndex = this.playQueue.findIndex(arr => arr.length === 0);

      if (firstEmptyQueueIndex !== -1) {
        this.playQueue[firstEmptyQueueIndex].push(sound);
        if (this.playQueue[firstEmptyQueueIndex].length === 1) {
          this._playSoundFromQueue(firstEmptyQueueIndex);
        }
      }
    }
  }

  async stopSound() {
    if (this.audioEnabled) {
      for (let i = 0; i < this.player.length; i++) {
        const status = await this.player[i]?.getStatusAsync();
        if (status?.isLoaded) {
          await this.player[i]?.stopAsync();
          this.playQueue[i] = [];
        }
      }
    }
  }
}

function getPlayer(audioEnabled?: boolean) {
  if (_player) {
    if (audioEnabled !== undefined) {
      _player.setSoundEnabled(audioEnabled);
    }
    return _player;
  }
  _player = new AudioPlayer(audioEnabled ?? false);
  _player.initialize();
  return _player;
}

export { getPlayer, type soundChoices };
