import { View, StyleSheet } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import { sortNumberArray } from '../../../../utils/collections';
import { getArrangement } from './counterArrangements';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';
import { colors, tenFrameCounterColors } from '../../../../theme/colors';

export type CounterVariant = 'red' | 'yellow' | 'blue' | 'green' | 'grey';

type Props = {
  /**
   * Number of counters to show
   */
  counters: number;
  /**
   * Counter color
   */
  color: CounterVariant;
  /**
   * Wrapper dimensions
   */
  dimens: Dimens;
  /**
   * Random seed for getting the arrangements
   */
  seed?: string | number | object;
  keyString?: string;
  /**
   * When true, outer shape will be a circle
   */
  isCircle?: boolean;
  /**
   * When true, there will be no border around the arrangement
   */
  noBorder?: boolean;
  /**
   * When true, the background will be transparent.
   * Use for when this representation is selectable
   */
  noBackground?: boolean;
  /**
   * When true will use a stripped down list of arrangements. See counterArrangements file
   */
  isSimpleArrangements?: boolean;
  rotation?: 90 | 180 | 270 | 0;
  /**
   * To be used to specifically set the scale of images. Useful when rendering more than one on a page
   */
  scale?: number;
  /**
   * when present this will override the getArrangement call
   */
  arrangement?: boolean[][];
};

export function CounterBoxArrangement({
  counters,
  color,
  dimens,
  seed,
  keyString,
  isCircle,
  isSimpleArrangements,
  rotation = 0,
  scale,
  arrangement: arrangementProp,
  noBorder,
  noBackground = false
}: Props) {
  const useSeed = seed ?? {
    counters,
    color,
    dimens,
    seed,
    keyString,
    isCircle,
    isSimpleArrangements,
    rotation,
    scale
  };
  const displayMode = useContext(DisplayMode);

  const arrangement = arrangementProp ?? getArrangement(counters, isSimpleArrangements, useSeed);

  const rows = arrangement.length;
  const columns = sortNumberArray(
    arrangement.map(val => val.length),
    'descending'
  )[0];

  const rowsWithRotation = rotation === 90 || rotation === 270 ? columns : rows;
  const columnsWithRotation = rotation === 90 || rotation === 270 ? rows : columns;

  const viewWidth = dimens.width / (scale ?? columnsWithRotation + 1);
  const viewHeight = dimens.height / (scale ?? rowsWithRotation + 1);
  const counterDimens = isCircle
    ? Math.min(viewWidth, viewHeight) * 0.9
    : Math.min(viewWidth, viewHeight);
  const counterScale = 0.9;

  const borderColor = displayMode === 'digital' ? colors.prussianBlue : colors.black;

  const styles = getStyles(dimens, counterDimens, borderColor, isCircle, noBorder, noBackground);

  return (
    <View key={keyString} style={styles.mainContainer}>
      <View style={styles.wrapper}>
        <View style={{ transform: `rotate(${rotation}deg)` }}>
          {arrangement.map((row, rowIndex) => (
            <View key={`row-${rowIndex}`} style={styles.counterWrapper}>
              {row.map((cell, colIndex) => (
                <View key={`cell-${rowIndex}-${colIndex}`} style={styles.counter}>
                  {cell && (
                    <View
                      style={{
                        width: counterDimens * counterScale,
                        height: counterDimens * counterScale,
                        borderRadius: 999,
                        borderWidth: 2.33,
                        backgroundColor: tenFrameCounterColors[color],
                        borderColor: 'black',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    />
                  )}
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

const getStyles = (
  dimens: Dimens,
  counterDimens: number,
  borderColor: string,
  isCircle?: boolean,
  noBorder?: boolean,
  noBackground?: boolean
) =>
  StyleSheet.create({
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: isCircle ? 999 : 0,
      backgroundColor: noBackground ? 'transparent' : 'white',
      borderWidth: noBorder ? 0 : 2,
      borderColor: borderColor,
      ...dimens
    },
    counterWrapper: { flexDirection: 'row', justifyContent: 'center' },
    mainContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      ...dimens
    },
    counter: {
      width: counterDimens,
      height: counterDimens,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
