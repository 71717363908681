import { StyleSheet, View } from 'react-native';
import PieChart from './PieChart';
import { Dimens } from 'common/src/theme/scaling';
import { PieChartColors } from 'common/src/theme/colors';
import { getRandomFromArray, seededRandom } from 'common/src/utils/random';
import { filledArray } from '../../../utils/collections';

type AdditionalDimens = Partial<Dimens>;

type Props = {
  /** Number of pie charts to render */
  numberOfPieCharts: number;
  slicesPerChart: number;
  /** Dimensions for question */
  dimens: Dimens;
  /** Charts to show per row */
  chartsPerRow: number;
  radius?: number;
  /** Color can be set manually */
  color?: string;
  /**
   * An optional prop to determine if all pie charts should be the same color.
   * Defaults to false.
   */
  sameColor?: boolean;
  /** Number of rows (default Math.ceil(numberOfPieCharts / chartsPerRow)) */
  numRows?: number;
};

const MultiPieChart = ({
  numberOfPieCharts,
  slicesPerChart,
  dimens,
  color,
  sameColor = false,
  chartsPerRow,
  numRows = Math.ceil(numberOfPieCharts / chartsPerRow),
  radius = calculateRadius(dimens, numRows, chartsPerRow)
}: Props) => {
  const renderPieCharts = (): JSX.Element[] => {
    const pieCharts = [];

    const selectedColor = getRandomFromArray(PieChartColors, {
      random: seededRandom({ numberOfPieCharts, slicesPerChart })
    }) as string;

    // Loop for number of rows
    for (let i = 0; i < numRows; i++) {
      const pieChartsInRow = [];

      // Loop for number of pie charts in row
      for (let j = 0; j < chartsPerRow && i * chartsPerRow + j < numberOfPieCharts; j++) {
        pieChartsInRow.push(
          <PieChart
            key={`row-${i}-pie-${j}`}
            pieOptions={filledArray({ ratioOfSlices: 1 }, slicesPerChart)}
            color={
              color ? color : sameColor ? selectedColor : PieChartColors[j % PieChartColors.length]
            }
            radius={radius}
          />
        );
      }

      pieCharts.push(
        <View key={`row-${i}`} style={styles.row}>
          {pieChartsInRow}
        </View>
      );
    }

    return pieCharts;
  };

  return <View style={styles.container}>{renderPieCharts()}</View>;
};

/**
 * Calculates the maximum radius for the pie charts within given dimensions
 * @param {Dimens} dimens question dimensions
 * @param {number} perRowTotal maximum number of pie charts widthways
 * @param {number} numberOfRows total number of pie charts heightways
 * @param {AdditionalDimens} additionalDimens any extra width/height dimensions
 * @returns
 */
export const calculateRadius = (
  dimens: Dimens,
  perRowTotal: number,
  numberOfRows = 1,
  additionalDimens?: AdditionalDimens
): number => {
  const viewboxPadding = 10;

  // Tentatively calculate maximum radius for given height
  let pieChartRadius = (dimens.height - 2 * numberOfRows * viewboxPadding) / (2 * numberOfRows);

  // Check if the radius relative to the height will fit in the given width
  if (
    2 * pieChartRadius * perRowTotal +
      2 * perRowTotal * viewboxPadding +
      (additionalDimens?.width ?? 0) >
    dimens.width
  ) {
    pieChartRadius =
      (dimens.width - (additionalDimens?.width ?? 0)) / (2 * perRowTotal) - viewboxPadding;
  }

  return pieChartRadius;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column'
  },
  row: {
    flexDirection: 'row'
  }
});

export default MultiPieChart;
