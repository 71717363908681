import Animated, { runOnJS, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { withStateHOC } from '../../../stateTree';
import { View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { ScaleFactorContext } from '../../../theme/scaling';
import { useContext } from 'react';

type Props = {
  shapeIconName: SvgName;
  lengthInPixels: number;
  offset?: {
    x: number;
    y: number;
  };
  setOffset?: (offsetNumber: { x: number; y: number }) => void;
  maxOffset: number;
  heightInPixels: number;
  expandClamp?: boolean;
};

export default function DragShape({
  shapeIconName,
  lengthInPixels,
  offset = {
    x: 0,
    y: 0
  },
  setOffset,
  maxOffset,
  heightInPixels,
  expandClamp = false
}: Props) {
  const scaleFactor = useContext(ScaleFactorContext);

  const animatedOffsetX = useSharedValue(offset.x);
  const animatedOffsetY = useSharedValue(offset.y);

  const offsetStyle = useAnimatedStyle(
    () => ({
      left: animatedOffsetX.value,
      top: animatedOffsetY.value
    }),
    [animatedOffsetX, animatedOffsetY]
  );

  const beginPagePositionX = useSharedValue<number | null>(null);
  const beginPagePositionY = useSharedValue<number | null>(null);

  const beginOffsetX = useSharedValue<number | null>(null);
  const beginOffsetY = useSharedValue<number | null>(null);

  const panGesture = Gesture.Pan()
    .onBegin(e => {
      beginOffsetX.value = animatedOffsetX.value;
      beginPagePositionX.value = e.absoluteX;

      beginOffsetY.value = animatedOffsetY.value;
      beginPagePositionY.value = e.absoluteY;
    })
    .onUpdate(e => {
      const translationX = (e.absoluteX - beginPagePositionX.value!) / scaleFactor;
      const translationY = (e.absoluteY - beginPagePositionY.value!) / scaleFactor;

      const newOffsetX = beginOffsetX.value! + translationX;
      const newOffsetY = beginOffsetY.value! + translationY;

      // Min/max offset X clamps
      const minOffsetXClamp = expandClamp ? -lengthInPixels / 2 : 0;
      const maxOffsetXClamp = expandClamp ? maxOffset + lengthInPixels / 2 : maxOffset;

      // X clamp
      animatedOffsetX.value =
        newOffsetX < minOffsetXClamp
          ? minOffsetXClamp
          : newOffsetX > maxOffsetXClamp
          ? maxOffsetXClamp
          : newOffsetX;
      // Y clamp
      animatedOffsetY.value = newOffsetY < -100 ? -100 : newOffsetY > 50 ? 50 : newOffsetY;
    })
    .onFinalize(() => {
      // Gesture finished - update the offset state
      setOffset &&
        runOnJS(setOffset)({
          x: animatedOffsetX.value,
          y: animatedOffsetY.value
        });
    });

  return (
    <View style={{ height: heightInPixels }}>
      <GestureDetector gesture={panGesture}>
        <Animated.View style={[offsetStyle, { position: 'absolute' }]}>
          <AssetSvg name={shapeIconName} width={lengthInPixels} />
        </Animated.View>
      </GestureDetector>
    </View>
  );
}

export const DragShapeWithState = withStateHOC(DragShape, {
  defaults: {
    defaultState: {
      x: 0,
      y: 0
    }
  },
  stateProp: 'offset',
  setStateProp: 'setOffset'
});
