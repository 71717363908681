import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { AssetSvg } from '../../../../assets/svg';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { base10ObjectToNumber } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfP',
  description: 'bfP',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9),
    variation: z.enum(['straws', 'rekenrek', 'tenFrame'])
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 9);
    const variation =
      numberOfTens < 6
        ? getRandomFromArray(['straws', 'rekenrek', 'tenFrame'] as const)
        : getRandomFromArray(['straws', 'rekenrek'] as const);

    return {
      numberOfOnes,
      numberOfTens,
      variation
    };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, variation },
      translate
    } = props;

    const total = numberOfTens * 10 + numberOfOnes;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        Content={({ dimens }) =>
          variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={total} />
          ) : variation === 'straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Straws',
                numbers: { ones: numberOfOnes, tens: numberOfTens },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="vertical"
                  size="xsmall"
                  key={i}
                />
              ))}
            </View>
          )
        }
        sentence={translate.ks1AnswerSentences.thereAreAnsGroupsOfTenXAndAnsMoreY(
          variation === 'rekenrek'
            ? translate.items.beads(2)
            : variation === 'tenFrame'
            ? translate.items.counters(2)
            : translate.items.straws(2)
        )}
        testCorrect={[numberOfTens.toString(), numberOfOnes.toString()]}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bfQ',
  description: 'bfQ',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    variation: z.enum(['tenFrame', 'rekenrek', 'straw']),
    selectables: z.array(z.array(z.string())),
    correctNumberOfOnes: z.number().int().min(1).max(9),
    correctNumberOfTens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['tenFrame', 'rekenrek', 'straw'] as const);

    const correctNumberOfOnes = randomIntegerInclusive(1, 9);
    const correctNumberOfTens =
      variation === 'tenFrame' ? randomIntegerInclusive(2, 5) : randomIntegerInclusive(2, 9);

    const correctAnswer = base10ObjectToNumber({
      ones: correctNumberOfOnes,
      tens: correctNumberOfTens
    });

    const { incorrectOptions } = rejectionSample(
      () => {
        const optionA = parseInt(correctAnswer.toString().split('').reverse().join(''));
        const optionB = correctNumberOfOnes + correctNumberOfTens;
        const optionC = correctAnswer + 1;
        const optionD = correctAnswer - 1;
        const optionE = parseInt(
          `${(correctNumberOfTens * 10).toString()}${correctNumberOfOnes.toString()}`
        );

        const incorrectOptions = getRandomSubArrayFromArray(
          [optionA, optionB, optionC, optionD, optionE],
          3
        );

        return { incorrectOptions };
      },
      ({ incorrectOptions }) => arrayHasNoDuplicates([correctAnswer, ...incorrectOptions])
    );

    const selectables = shuffle([
      ['A', correctAnswer.toLocaleString()],
      ['B', incorrectOptions[0].toLocaleString()],
      ['C', incorrectOptions[1].toLocaleString()],
      ['D', incorrectOptions[2].toLocaleString()]
    ]);

    return { variation, selectables, correctNumberOfOnes, correctNumberOfTens };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { variation, selectables, correctNumberOfOnes, correctNumberOfTens },
      translate
    } = props;

    const total = base10ObjectToNumber({
      ones: correctNumberOfOnes,
      tens: correctNumberOfTens
    });

    const titleString =
      variation === 'tenFrame'
        ? translate.items.counters(2)
        : variation === 'rekenrek'
        ? translate.items.beads(2)
        : translate.items.straws(2);

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions.howManyXAreThereSelectYourAnswer(titleString)}
        pdfTitle={translate.ks1PDFInstructions.howManyXAreThereTickYourAnswer(titleString)}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        pdfVariation="selectablesOnBottom"
        leftContent={
          variation === 'rekenrek' ? (
            <Rekenrek scale={0.75} rows={10} numberShown={total} />
          ) : variation === 'straw' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Straws',
                numbers: { ones: correctNumberOfOnes, tens: correctNumberOfTens },
                arrangement: 'ltr'
              }}
              usableWidth={500}
              usableHeight={400}
            />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20, flexWrap: 'wrap', width: 600 }}>
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="horizontal"
                  size="xsmall"
                  key={i}
                />
              ))}
            </View>
          )
        }
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfR',
  description: 'bfR',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9),
    variation: z.enum(['straws', 'rekenrek', 'tenFrame', 'muffins', 'bread'])
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 9);

    const variation =
      numberOfTens < 6
        ? getRandomFromArray(['straws', 'rekenrek', 'tenFrame', 'muffins', 'bread'] as const)
        : getRandomFromArray(['straws', 'rekenrek', 'muffins', 'bread'] as const);

    return {
      numberOfOnes,
      numberOfTens,
      variation
    };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, variation },
      translate
    } = props;

    const total = numberOfTens * 10 + numberOfOnes;

    const titleString =
      variation === 'straws'
        ? translate.items.straws(total)
        : variation === 'rekenrek'
        ? translate.items.beads(total)
        : variation === 'tenFrame'
        ? translate.items.counters(total)
        : variation === 'bread'
        ? translate.food.bread.breadRolls()
        : translate.weightObjects.muffins();

    const onesSvgName = variation === 'bread' ? 'Bread_roll' : 'Muffins_individually/Muffin_1';
    const tensSvgName =
      variation === 'bread' ? 'Bread_rolls_10' : 'Muffins_donuts/Muffins_on_a_rack_10';

    return (
      <QF1ContentAndSentence
        title={
          variation === 'rekenrek'
            ? translate.ks1Instructions.whatNumberIsShown()
            : translate.ks1Instructions.howManyXAreThere(titleString)
        }
        Content={({ dimens }) =>
          variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={total} />
          ) : variation === 'straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Straws',
                numbers: { ones: numberOfOnes, tens: numberOfTens },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'tenFrame' ? (
            <View style={{ flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="horizontal"
                  size="xsmall"
                  key={i}
                />
              ))}
            </View>
          ) : variation === 'bread' ? (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8 }}>
              {countRange(numberOfTens).map(i => (
                <AssetSvg
                  height={dimens.height / 4}
                  width={dimens.width / 4}
                  key={`${variation}_${i}_tens`}
                  name={tensSvgName}
                />
              ))}
              {countRange(numberOfOnes).map(i => (
                <AssetSvg
                  height={dimens.height / 4}
                  width={dimens.width / 16}
                  key={`${variation}_${i}_ones`}
                  name={onesSvgName}
                />
              ))}
            </View>
          ) : (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8 }}>
              {countRange(numberOfTens).map(i => (
                <AssetSvg
                  height={dimens.height / 3}
                  width={dimens.width / 7}
                  key={`${variation}_${i}_tens`}
                  name={tensSvgName}
                />
              ))}
              {countRange(numberOfOnes).map(i => (
                <AssetSvg
                  height={dimens.height / 3}
                  width={dimens.width / 14}
                  key={`${variation}_${i}_ones`}
                  name={onesSvgName}
                />
              ))}
            </View>
          )
        }
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[total.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountObjectsTo100ByMaking10s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
