import useBreakpoints from '../hooks/useBreakpoints';
import { resolveFont } from 'common/src/theme/fonts';
import { StyleProp, ViewStyle, View, Pressable } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { useTheme } from 'common/src/theme';
import { ComponentProps } from 'react';
import { IconButton } from 'react-native-paper';
import { colors } from 'common/src/theme/colors';
import { AssetSvg } from 'common/src/assets/svg';

type Props = {
  title: string;
  style?: StyleProp<ViewStyle>;
  leftButton?: JSX.Element;
  color?: string;
  backgroundColor?: string;
  borderBottomColor?: string;
  rightButton?: JSX.Element;
};

export default function CustomHeader({
  title,
  style,
  leftButton,
  color,
  backgroundColor,
  borderBottomColor,
  rightButton,
  onPress
}: Pick<ComponentProps<typeof IconButton>, 'onPress'> & Props) {
  const { resize } = useBreakpoints();
  const theme = useTheme();
  const paddingTop = 20 * resize;

  return (
    <View
      style={[
        {
          height: 90 * resize,
          backgroundColor: backgroundColor ?? 'white',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          paddingTop: paddingTop,
          borderBottomWidth: 1,
          borderBottomColor: borderBottomColor ?? backgroundColor ?? colors.greys400,
          paddingHorizontal: 24 * resize
        },
        style
      ]}
    >
      {/* Left of title - flex 1. */}
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
        {/* Buttons... */}
        <View
          style={{
            height: 70 * resize,
            maxWidth: '100%',
            justifyContent: 'center'
          }}
        >
          {onPress &&
            (leftButton ? (
              <Pressable onPress={onPress}>{leftButton}</Pressable>
            ) : (
              <IconButton
                icon={() => (
                  <View
                    style={{
                      transform: 'rotate(180deg)'
                    }}
                  >
                    <AssetSvg
                      name="ArrowRightCustomizable"
                      svgProps={{ fill: color ?? theme.colors.primary }}
                      width={48 * resize}
                      height={48 * resize}
                    />
                  </View>
                )}
                onPress={onPress}
              />
            ))}
        </View>
      </View>

      {/* Title - takes up as much space as it needs, centered because views to the left and right have same flex */}
      <Text
        style={[
          resolveFont({
            fontFamily: 'White_Rose_Noto',
            fontWeight: '700',
            fontSize: 30 * resize,
            lineHeight: 38 * resize,
            marginHorizontal: 30 * resize
          }),
          { color: color ?? theme.colors.primary }
        ]}
      >
        {title}
      </Text>

      {/* Right of title - flex 1 */}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        {/* Buttons... */}
        <View
          style={{
            height: 70 * resize,
            maxWidth: '100%',
            justifyContent: 'center'
          }}
        >
          {rightButton}
        </View>
      </View>
    </View>
  );
}
