import { z } from 'zod';
// This is required for the jwt-decode to work in react-native.
// An issue has been fixed in version 0.74 of react-native but we are currently running 0.72
// https://github.com/auth0/jwt-decode?tab=readme-ov-file#polyfilling-atob
import 'core-js/stable/atob';
import { jwtDecode } from 'jwt-decode';

/** Schema for a Login (for validation), according to the API. */
const authApiSchema = z.object({
  token: z.string(),
  refresh_token: z.string()
});

/** A Login (for validation), according to the API. */
type AuthApiEntity = z.infer<typeof authApiSchema>;

/** Request data for logging in. */
export type PostAuthPayload = {
  studentCode: string;
  learningGroupShareCode: string;
};

/** Decoded token */
type DecodedToken = {
  classStudentId: number;
  // expiry time
  exp: number;
  // issued at time
  iat: number;
  firstName: string;
  lastName: string;
  learningGroupId: number;
  roles: string[];
  username: string;
};

export type TokenState = {
  token: string;
  refreshToken: string;
  firstName: string;
  lastName: string;
  classStudentId: number;
};

/**
 * Parse and decode the response data from the /token/refresh endpoint.
 */
export const parseAndDecodeTokens = (data: unknown): TokenState | 'invalid response' => {
  const parseResults = authApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    console.warn('(parseAndDecodeTokens) Parse error:', parseResults.error);
    return 'invalid response';
  }

  // Validation success
  const parsedData: AuthApiEntity = parseResults.data;
  const decodedAuthToken = jwtDecode<DecodedToken>(parsedData.token);

  return {
    token: parsedData.token,
    refreshToken: parsedData.refresh_token,
    firstName: decodedAuthToken.firstName,
    lastName: decodedAuthToken.lastName,
    classStudentId: decodedAuthToken.classStudentId
  };
};
