import { ComponentProps } from 'react';
import { SetState } from '../../../../utils/react';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { withStateHOC } from '../../../../stateTree';
import { ArrowAnswerBoxes } from './ArrowAnswerBoxes';
import { parseMarkup } from '../../../../markup';

export default function ArrowAnswerBoxesKeyboard({
  userAnswer = [],
  setUserAnswer = () => {},
  ...props
}: Omit<ComponentProps<typeof ArrowAnswerBoxes>, 'inputBox'> & {
  userAnswer: string[][];
  setUserAnswer: SetState<string[][]>;
}) {
  const keyboardInputBox = (
    ansIndex: number,
    rowIndex: number,
    boxWidth: number,
    boxHeight: number
  ) => (
    <NoKeyboardTextInput
      key={'tickNum' + ansIndex}
      value={userAnswer ? userAnswer[rowIndex][ansIndex] : ''}
      onChangeText={text => {
        const newState = [...userAnswer];
        newState[rowIndex][ansIndex] = text;
        setUserAnswer(newState);
      }}
      style={[
        {
          width: boxWidth,
          minHeight: boxHeight
        }
      ]}
      selectedStyle={{ zIndex: 2, borderWidth: 3 }}
      autoFocus={ansIndex === 0}
    />
  );

  return <ArrowAnswerBoxes inputBox={keyboardInputBox} {...props} />;
}

/** See {@link NumberTrackKeyboard}. */
export const ArrowAnswerBoxesKeyboardWithState = withStateHOC(ArrowAnswerBoxesKeyboard, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    // default state is an empty string array of the length of how many ans boxes there are.
    defaultState: props.boxValues.map(row =>
      row
        .map(row => parseMarkup(row))
        .filter(val => val.numberOfAns > 0)
        .map(() => '')
    ),
    testComplete: userAnswer => userAnswer.every(userAnswer => userAnswer.every(it => it !== ''))
  })
});
