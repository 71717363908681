import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, rejectionSample } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';

////
// Questions
////

const Question3 = newQuestionContent({
  uid: 'beH',
  description: 'beH',
  keywords: ['Equal groups', 'Group', 'Quarter'],
  schema: z.object({
    rows: z.number().int().min(2).max(12),
    columns: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const { rows, columns } = rejectionSample(
      () => {
        const rows = randomIntegerInclusive(2, 12);

        const columns = randomIntegerInclusive(2, 12);
        return { rows, columns };
      },
      ({ rows, columns }) =>
        columns * rows >= 8 && columns * rows <= 24 && (columns * rows) % 4 === 0
    );

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const total = rows * columns;

    const answer = total / 4;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.aQuarterOfXIsAns(rows * columns)}
        title={translate.ks1Instructions.completeTheSentenceUseTheArrayToHelpYou()}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAQuarterOfAQuantity',
  questionTypes: [Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
