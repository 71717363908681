import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { integerToWord, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcr',
  description: 'bcr',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numA: z.number().int().min(0).max(20),
    numB: z.number().int().min(0).max(20),
    wordOrNum: z.enum(['NumANumB', 'WordANumB', 'NumAWordB', 'WordAWordB'])
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(0, 20);
    const numB = randomIntegerInclusive(0, 20);
    const wordOrNum = getRandomFromArray([
      'NumANumB',
      'WordANumB',
      'NumAWordB',
      'WordAWordB'
    ] as const);
    return { numA, numB, wordOrNum };
  },
  Component: props => {
    const {
      question: { numA, numB, wordOrNum },
      translate
    } = props;

    let numberA;
    let numberB;

    switch (wordOrNum) {
      case 'NumANumB':
        numberA = numA.toLocaleString();
        numberB = numB.toLocaleString();
        break;
      case 'NumAWordB':
        numberA = numA.toLocaleString();
        numberB = `${integerToWord(numB)}.`;
        break;
      case 'WordANumB':
        numberA = integerToWord(numA);
        numberB = numB.toLocaleString();
        break;
      case 'WordAWordB':
        numberA = integerToWord(numA);
        numberB = `${integerToWord(numB)}.`;
        break;
    }

    return (
      <QF37SentenceDrag<'<' | '>' | '='>
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useACardToCompleteSentence()}
        items={[
          { value: '>', component: translate.ks1MiscStrings.greaterThan() },
          { value: '=', component: translate.ks1MiscStrings.equalTo() },
          { value: '<', component: translate.ks1MiscStrings.lessThan() }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentence={translate.ks1AnswerSentences.stringAIsAnsStringB({ numberA, numberB })}
        testCorrect={[lessThanGreaterThanOrEqualTo(numA, numB)]}
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bcs',
  description: 'bcs',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numA: z.number().int().min(0).max(20),
    numB: z.number().int().min(0).max(20),
    wordOrNum: z.enum(['NumANumB', 'WordANumB', 'NumAWordB', 'WordAWordB'])
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(0, 20);
    const numB = randomIntegerInclusive(0, 20);
    const wordOrNum = getRandomFromArray([
      'NumANumB',
      'WordANumB',
      'NumAWordB',
      'WordAWordB'
    ] as const);
    return { numA, numB, wordOrNum };
  },
  Component: props => {
    const {
      question: { numA, numB, wordOrNum },
      translate
    } = props;

    let numberA;
    let numberB;

    switch (wordOrNum) {
      case 'NumANumB':
        numberA = numA.toLocaleString();
        numberB = numB.toLocaleString();
        break;
      case 'NumAWordB':
        numberA = numA.toLocaleString();
        numberB = integerToWord(numB);
        break;
      case 'WordANumB':
        numberA = integerToWord(numA);
        numberB = numB.toLocaleString();
        break;
      case 'WordAWordB':
        numberA = integerToWord(numA);
        numberB = integerToWord(numB);
        break;
    }

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompareTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={['<', '>', '=']}
        actionPanelVariant="end"
        itemVariant="square"
        sentence={`${numberA} <ans/> ${numberB}`}
        testCorrect={[lessThanGreaterThanOrEqualTo(numA, numB)]}
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bct',
  description: 'bct',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numA: z.number().int().min(0).max(20),
    inequality: z.enum(['<', '>', '=']),
    wordOrNum: z.enum(['Number', 'Word'])
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(0, 20);
    const wordOrNum = getRandomFromArray(['Number', 'Word'] as const);

    const inequality = (() => {
      if (wordOrNum === 'Word') return getRandomFromArray(['<', '='] as const);
      else if (numA !== 0) return getRandomFromArray(['<', '>'] as const);
      else return getRandomFromArray(['<'] as const);
    })();

    return { numA, wordOrNum, inequality };
  },
  Component: props => {
    const {
      question: { numA, wordOrNum, inequality },
      translate
    } = props;

    const numberA = wordOrNum === 'Number' ? numA.toLocaleString() : integerToWord(numA);
    const symbol = inequality === '<' ? LESS_THAN : inequality === '>' ? GREATER_THAN : '=';

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheStatement()}
        pdfTitle={translate.ks1PDFInstructions.writeMissingNumber()}
        sentence={`${numberA} ${symbol} <ans/>`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          inequality === lessThanGreaterThanOrEqualTo(numA, parseInt(userAnswer[0]))
        }
        customMarkSchemeAnswer={{
          answerText:
            symbol === LESS_THAN
              ? translate.markScheme.anyNumberGreaterThanX(numberA)
              : symbol === GREATER_THAN
              ? translate.markScheme.anyNumberLessThanX(numberA)
              : translate.markScheme.anyNumberEqualToX(numberA)
        }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareNumbersTo20',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
