import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { MULT } from '../../../../constants';
import { range } from '../../../../utils/collections';
import {
  findMultiplicationExchanges,
  numbersDoNotHaveMultiplicationExchange
} from 'common/src/utils/exchanges';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOC',
  description: 'aOC',
  keywords: ['Place value chart', 'Select'],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    tens: z.number().int().min(10).max(50).multipleOf(10),
    hundreds: z.number().int().min(100).max(500).multipleOf(100),
    thousands: z.number().int().min(1000).max(5000).multipleOf(1000),
    multiplicationRHS: z.number().int().min(2).max(4),
    showCorrect: z.boolean(),
    randomPlaceValueToIncrease: z.enum(['+1', '-1', '+10', '-10', '+100', '-100', '+1000', '-1000'])
  }),
  simpleGenerator: () => {
    const randomPlaceValueToIncrease = getRandomFromArray([
      '+1',
      '-1',
      '+10',
      '-10',
      '+100',
      '-100',
      '+1000',
      '-1000'
    ] as const);

    const ones = randomIntegerInclusive(1, 5);
    const tens = randomIntegerInclusiveStep(10, 50, 10);
    const hundreds = randomIntegerInclusiveStep(100, 500, 100);
    const thousands = randomIntegerInclusiveStep(1000, 5000, 1000);
    const multiplicationRHS = randomIntegerInclusive(2, 4);
    const showCorrect = getRandomBoolean();
    return {
      ones,
      tens,
      hundreds,
      thousands,
      multiplicationRHS,
      showCorrect,
      randomPlaceValueToIncrease
    };
  },

  Component: props => {
    const {
      question: {
        ones,
        tens,
        hundreds,
        thousands,
        multiplicationRHS,
        showCorrect,
        randomPlaceValueToIncrease
      },
      translate,
      displayMode
    } = props;

    const multiplicationLHS = ones + tens + hundreds + thousands;

    const amountOfTens = tens / 10;
    const amountOfHundreds = hundreds / 100;
    const amountOfThousands = thousands / 1000;

    const [
      amountOfIncorrectOnes,
      amountOfIncorrectTens,
      amountOfIncorrectHundreds,
      amountOfIncorrectThousands
    ] = (() => {
      switch (randomPlaceValueToIncrease) {
        case '+1':
          return [ones + 1, amountOfTens, amountOfHundreds, amountOfThousands];
        case '-1':
          return [ones - 1, amountOfTens, amountOfHundreds, amountOfThousands];
        case '+10':
          return [ones, amountOfTens + 1, amountOfHundreds, amountOfThousands];
        case '-10':
          return [ones, amountOfTens - 1, amountOfHundreds, amountOfThousands];
        case '+100':
          return [ones, amountOfTens, amountOfHundreds + 1, amountOfThousands];
        case '-100':
          return [ones, amountOfTens, amountOfHundreds - 1, amountOfThousands];
        case '+1000':
          return [ones, amountOfTens, amountOfHundreds, amountOfThousands + 1];
        case '-1000':
          return [ones, amountOfTens, amountOfHundreds, amountOfThousands - 1];
      }
    })();

    const thousandsImage = getImagesByAmount(
      'Place_value/1000',
      showCorrect ? amountOfThousands : amountOfIncorrectThousands
    );
    const hundredsImage = getImagesByAmount(
      'Place_value/100',
      showCorrect ? amountOfHundreds : amountOfIncorrectHundreds
    );
    const tensImage = getImagesByAmount(
      'Place_value/10',
      showCorrect ? amountOfTens : amountOfIncorrectTens
    );
    const onesImage = getImagesByAmount(
      'Place_value/1',
      showCorrect ? ones : amountOfIncorrectOnes
    );

    const dimens =
      displayMode === 'digital'
        ? {
            width: 200,
            height: 50
          }
        : {
            width: 450,
            height: 100
          };

    const scaleFactor = Math.min(
      thousands > 0
        ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, thousandsImage, 8)
        : 1,
      tens > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, hundredsImage, 8) : 1,
      tens > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, tensImage, 8) : 1,
      ones > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, onesImage, 8) : 1
    );

    const data: (string | JSX.Element)[][] = [];
    for (let i = 0; i < multiplicationRHS; i++) {
      data.push([
        thousands > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={thousandsImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        hundreds > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={hundredsImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        tens > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={tensImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        ones > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={onesImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        )
      ]);
    }

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.doesPlaceValueChartCorrectlyRepresentX(
          `${multiplicationLHS.toLocaleString()} ${MULT} ${multiplicationRHS.toLocaleString()}`
        )}
        correctAnswer={showCorrect}
        content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Thousands(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[3].color : colors.greys100
                },
                textStyle: {
                  color: displayMode === 'digital' ? placeValueColumnInfo[3].textColor : 'black'
                }
              },
              {
                label: translate.keywords.Hundreds(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[2].color : colors.greys100
                },
                textStyle: {
                  color: displayMode === 'digital' ? placeValueColumnInfo[2].textColor : 'black'
                }
              },
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                },
                textStyle: displayMode === 'digital' && {
                  color: displayMode === 'digital' ? placeValueColumnInfo[1].textColor : 'black'
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                },
                textStyle: displayMode === 'digital' && {
                  color: displayMode === 'digital' ? placeValueColumnInfo[0].textColor : 'black'
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question1V2 = newQuestionContent({
  uid: 'aOC2',
  description: 'aOC',
  keywords: ['Place value chart', 'Select'],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    tens: z.number().int().min(10).max(50).multipleOf(10),
    hundreds: z.number().int().min(100).max(500).multipleOf(100),
    thousands: z.number().int().min(1000).max(5000).multipleOf(1000),
    multiplicationRHS: z.number().int().min(2).max(4),
    showCorrect: z.boolean(),
    randomPlaceValueToIncrease: z.enum([
      '+1',
      '-1',
      '+10',
      '-10',
      '+100',
      '-100',
      '+1000',
      '-1000',
      'row1',
      'row-1'
    ])
  }),
  simpleGenerator: () => {
    const randomPlaceValueToIncrease = getRandomFromArray([
      '+1',
      '-1',
      '+10',
      '-10',
      '+100',
      '-100',
      '+1000',
      '-1000',
      'row1',
      'row-1'
    ] as const);

    const ones = randomIntegerInclusive(1, 5);
    const tens = randomIntegerInclusiveStep(10, 50, 10);
    const hundreds = randomIntegerInclusiveStep(100, 500, 100);
    const thousands = randomIntegerInclusiveStep(1000, 5000, 1000);
    const multiplicationRHS = randomIntegerInclusive(2, 4);
    const showCorrect = getRandomBoolean();
    return {
      ones,
      tens,
      hundreds,
      thousands,
      multiplicationRHS,
      showCorrect,
      randomPlaceValueToIncrease
    };
  },

  Component: props => {
    const {
      question: {
        ones,
        tens,
        hundreds,
        thousands,
        multiplicationRHS,
        showCorrect,
        randomPlaceValueToIncrease
      },
      translate,
      displayMode
    } = props;

    const multiplicationLHS = ones + tens + hundreds + thousands;

    const amountOfTens = tens / 10;
    const amountOfHundreds = hundreds / 100;
    const amountOfThousands = thousands / 1000;

    const [
      amountOfIncorrectOnes,
      amountOfIncorrectTens,
      amountOfIncorrectHundreds,
      amountOfIncorrectThousands
    ] = (() => {
      switch (randomPlaceValueToIncrease) {
        case '+1':
          return [ones + 1, amountOfTens, amountOfHundreds, amountOfThousands];
        case '-1':
          return [ones - 1, amountOfTens, amountOfHundreds, amountOfThousands];
        case '+10':
          return [ones, amountOfTens + 1, amountOfHundreds, amountOfThousands];
        case '-10':
          return [ones, amountOfTens - 1, amountOfHundreds, amountOfThousands];
        case '+100':
          return [ones, amountOfTens, amountOfHundreds + 1, amountOfThousands];
        case '-100':
          return [ones, amountOfTens, amountOfHundreds - 1, amountOfThousands];
        case '+1000':
          return [ones, amountOfTens, amountOfHundreds, amountOfThousands + 1];
        case '-1000':
          return [ones, amountOfTens, amountOfHundreds, amountOfThousands - 1];
        case 'row1':
        case 'row-1':
          return [ones, amountOfTens, amountOfHundreds, amountOfThousands];
      }
    })();

    const thousandsImage = getImagesByAmount(
      'Place_value/1000',
      showCorrect ? amountOfThousands : amountOfIncorrectThousands
    );
    const hundredsImage = getImagesByAmount(
      'Place_value/100',
      showCorrect ? amountOfHundreds : amountOfIncorrectHundreds
    );
    const tensImage = getImagesByAmount(
      'Place_value/10',
      showCorrect ? amountOfTens : amountOfIncorrectTens
    );
    const onesImage = getImagesByAmount(
      'Place_value/1',
      showCorrect ? ones : amountOfIncorrectOnes
    );

    const dimens =
      displayMode === 'digital'
        ? {
            width: 200,
            height: 50
          }
        : {
            width: 450,
            height: 100
          };

    const scaleFactor = Math.min(
      thousands > 0
        ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, thousandsImage, 8)
        : 1,
      tens > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, hundredsImage, 8) : 1,
      tens > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, tensImage, 8) : 1,
      ones > 0 ? calcRowOfImagesScaleFactor(dimens.width, dimens.height, onesImage, 8) : 1
    );

    const multiplier =
      !showCorrect && randomPlaceValueToIncrease === 'row1'
        ? multiplicationRHS + 1
        : !showCorrect && randomPlaceValueToIncrease === 'row-1'
        ? multiplicationRHS - 1
        : multiplicationRHS;

    const data: (string | JSX.Element)[][] = [];
    for (let i = 0; i < multiplier; i++) {
      data.push([
        thousands > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={thousandsImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        hundreds > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={hundredsImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        tens > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={tensImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        ones > 0 ? (
          <RowOfImages
            containerStyle={dimens}
            style={{ gap: 8 }}
            images={onesImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        )
      ]);
    }

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.doesPlaceValueChartCorrectlyRepresentX(
          `${multiplicationLHS.toLocaleString()} ${MULT} ${multiplicationRHS.toLocaleString()}`
        )}
        correctAnswer={showCorrect}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Thousands(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[3].color
                },
                textStyle: {
                  color: placeValueColumnInfo[3].textColor
                }
              },
              {
                label: translate.keywords.Hundreds(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[2].color
                },
                textStyle: {
                  color: placeValueColumnInfo[2].textColor
                }
              },
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[1].color
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[1].textColor
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[0].textColor
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aOD',
  description: 'aOD',
  keywords: ['4-digit', 'Column multiplication'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1001)
        .max(4999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      number2: z.number().int().min(2).max(4)
    })
    .refine(val => val.number1 * val.number2 < 10_000, 'total should be less than 10,000')
    .refine(
      val => numbersDoNotHaveMultiplicationExchange(val.number1, val.number2),
      'numbers should not multiplication exchange'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 });

        const number2 = randomIntegerInclusive(2, 4, { constraint: x => x * number1 < 10000 });

        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotHaveMultiplicationExchange(number1, number2)
    );

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answerNumber = number1 * number2;
    const answerMissingDigits = range(0, answerNumber.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        bottomNumber={number2}
        operation={MULT}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answerNumber, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aOE',
  description: 'aOE',
  keywords: ['4-digit', 'Column multiplication'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1001)
        .max(4999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      number2: z.number().int().min(2).max(5)
    })
    .refine(
      val =>
        findMultiplicationExchanges(val.number1, val.number2).length > 0 &&
        findMultiplicationExchanges(val.number1, val.number2).length <= 2,
      'must have 1 or 2 multiplication exchanges '
    )
    .refine(val => val.number1 * val.number2 < 10_000, 'total should be less than 10,000'),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 });
        const number2 = randomIntegerInclusive(2, 5, { constraint: x => x * number1 < 10000 });

        return { number1, number2 };
      },
      // Only permit them if they have 1 or 2 multiplication exchanges.
      ({ number1, number2 }) =>
        findMultiplicationExchanges(number1, number2).length <= 2 &&
        findMultiplicationExchanges(number1, number2).length > 0
    );

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answerNumber = number1 * number2;
    const answerMissingDigits = range(0, answerNumber.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        bottomNumber={number2}
        operation={MULT}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answerNumber, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aOF',
  description: 'aOF',
  keywords: ['4-digit', 'Column multiplication'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1001)
        .max(4999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      number2: z.number().int().min(6).max(8)
    })
    .refine(
      val =>
        findMultiplicationExchanges(val.number1, val.number2).length > 0 &&
        findMultiplicationExchanges(val.number1, val.number2).length <= 2,
      'must have 1 or 2 multiplication exchanges '
    )
    .refine(val => val.number1 * val.number2 < 10_000, 'total should be less than 10,000'),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number2 = getRandomFromArray([6, 7, 8]);
        const number1 = randomIntegerInclusive(1001, 4999, {
          constraint: x => x % 10 !== 0 && x * number2 < 10000
        });

        return { number1, number2 };
      },
      // Only permit them if they have 1 or 2 multiplication exchanges.
      ({ number1, number2 }) =>
        findMultiplicationExchanges(number1, number2).length <= 2 &&
        findMultiplicationExchanges(number1, number2).length > 0
    );

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answerNumber = number1 * number2;
    const answerMissingDigits = range(0, answerNumber.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        bottomNumber={number2}
        operation={MULT}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answerNumber, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aOG',
  description: 'aOG',
  keywords: ['4-digit', 'Column multiplication'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1011)
        .max(2444)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      number2: z.number().int().min(2).max(4),
      answerMissingDigits: z.array(z.number().int().min(1).max(3)).length(2),
      missingTopDigit: z.number().min(1).max(3)
    })
    .refine(val => val.number1 * val.number2 < 10_000, 'total should be less than 10,000')
    .refine(
      val => numbersDoNotHaveMultiplicationExchange(val.number1, val.number2),
      'numbers should not multiplication exchange'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1011, 2444, { constraint: x => x % 10 !== 0 });

        const number2 = randomIntegerInclusive(2, 4, { constraint: x => x * number1 < 10000 });

        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotHaveMultiplicationExchange(number1, number2)
    );

    const missingTopDigit = randomIntegerInclusive(1, 3);

    const answerMissingDigits = [1, 2, 3].filter(x => x !== missingTopDigit);

    return {
      answerMissingDigits,
      number1,
      number2,
      missingTopDigit
    };
  },
  Component: ({
    question: { answerMissingDigits, number1, number2, missingTopDigit },
    translate
  }) => {
    const answerNumber = number1 * number2;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={number1}
        operation={MULT}
        topMissingDigits={[missingTopDigit]}
        bottomNumber={number2}
        bottomMissingDigits={[0]}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(number1, number1.toString().length),
            bottom: getMarkSchemeAnswer(number2, number2.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          }
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOH',
  description: 'aOH',
  keywords: ['4-digit', 'Column multiplication'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1012)
        .max(2499)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      number2: z.number().int().min(2).max(6),
      answerMissingDigits: z.array(z.number().int().min(0).max(3)).length(2),
      missingTopDigits: z.array(z.number().int().min(0).max(3)).length(2)
    })
    .refine(val => val.number1 * val.number2 < 10_000, 'total should be less than 10,000')
    .refine(
      val => findMultiplicationExchanges(val.number1, val.number2).length > 0,
      'must have atleast 1 multiplication exchange'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1011, 2444, { constraint: x => x % 10 !== 0 });

        const number2 = randomIntegerInclusive(2, 6, { constraint: x => x * number1 < 10000 });

        return { number1, number2 };
      },
      ({ number1, number2 }) => findMultiplicationExchanges(number1, number2).length > 0
    );

    const [a, b, c, d] = shuffle(randomUniqueIntegersInclusive(0, 3, 4));

    const missingTopDigits = [a, b];
    const answerMissingDigits = [c, d];

    return { number1, number2, answerMissingDigits, missingTopDigits };
  },

  Component: ({
    question: { number1, number2, answerMissingDigits, missingTopDigits },
    translate
  }) => {
    const answerNumber = number1 * number2;
    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={number1}
        bottomNumber={number2}
        operation={MULT}
        topMissingDigits={missingTopDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        showNumberExchanges
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(number1, number1.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyUpToA4DigitNumberByA1DigitNumber',
  questionTypes: [Question1V2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
