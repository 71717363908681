import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DIV } from '../../../../constants';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aII',
  description: 'aII',
  keywords: ['Scales', 'Number line'],
  schema: z.object({
    parts: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(2, 10);
    return { parts };
  },
  Component: props => {
    const {
      question: { parts },
      translate
    } = props;
    const tickValues = filledArray('', parts + 1);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howManyEqualPartsHasNumberLineBeenSplitInto()}
        testCorrect={[parts.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIJ',
  description: 'aIJ',
  keywords: ['Scales', 'Number line'],
  schema: z.object({
    parts: numberEnum([2, 4, 5, 10])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const parts = getRandomFromArray([2, 4, 5, 10] as const);
    return { parts };
  },
  Component: props => {
    const {
      question: { parts },
      translate
    } = props;

    const tickValues = [
      (0).toLocaleString(),
      ...filledArray('', parts - 1),
      (100).toLocaleString()
    ];

    const amountPerPart = 100 / parts;

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [parts.toLocaleString()],
            [parts.toLocaleString(), amountPerPart.toLocaleString()],
            [amountPerPart.toLocaleString()]
          ]
        }}
        sentences={[
          translate.answerSentences.theNumberLineIsSplitIntoAnsEqualParts(),
          `${(100).toLocaleString()} ${DIV} <ans/> = <ans/>`,
          translate.answerSentences.theNumberLineIsCountingUpInAns()
        ]}
        title={translate.instructions.completeSentencesToWorkOutWhatNumberLineIsCountingUpIn()}
        testCorrect={answer =>
          answer[0][0] === parts.toString() &&
          // Accept parts and amountPerPart in either order for the division:
          ((answer[1][0] === parts.toString() && answer[1][1] === amountPerPart.toString()) ||
            (answer[1][0] === amountPerPart.toString() && answer[1][1] === parts.toString())) &&
          answer[2][0] === amountPerPart.toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIK',
  description: 'aIK',
  keywords: ['Scales', 'Number line'],
  schema: z
    .object({
      parts: numberEnum([2, 4, 5, 10]),
      answerATick: z.number().int().min(1).max(9),
      answerBTick: z.number().int().min(1).max(9).optional()
    })
    .refine(val => val.answerATick < val.parts, 'answerATick must be less than parts.')
    .refine(
      val => val.answerATick !== val.answerBTick,
      'answerATick and answerBTick must be different.'
    )
    .refine(
      val => (val.parts > 2 ? val.answerBTick : !val.answerBTick),
      'If parts > 2, answerBTick must be defined.'
    ),
  simpleGenerator: () => {
    const parts = getRandomFromArray([2, 4, 5, 10] as const);
    const answerATick = randomIntegerInclusive(1, parts - 1);

    // answerBTick must not be used if there are only 2 parts:
    const answerBTick =
      parts === 2
        ? undefined
        : randomIntegerInclusive(1, parts - 1, {
            constraint: x => x !== answerATick
          });

    return { parts, answerATick, answerBTick };
  },
  Component: props => {
    const {
      question: { parts, answerATick, answerBTick },
      translate
    } = props;

    const tickValues = [
      (0).toLocaleString(),
      ...filledArray('', parts - 1),
      (100).toLocaleString()
    ];

    const amountPerPart = 100 / parts;

    const answerA = amountPerPart * answerATick;

    const answerB = !answerBTick ? undefined : amountPerPart * answerBTick;

    const answers = !answerBTick
      ? [answerA]
      : ([answerA, answerB!].sort((a, b) => a + b) as number[]);

    return (
      <QF17CompleteTheNumberLine
        title={
          parts === 2
            ? translate.instructions.whatNumberIsTheArrowPointingTo()
            : translate.instructions.whatNumbersAreTheArrowsPointingTo()
        }
        testCorrect={answers.map(num => num.toString())}
        tickValues={tickValues}
        freeNumberLineAnswer={answers}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aIL',
  description: 'aIL',
  keywords: ['Scales', 'Number line'],
  schema: z
    .object({
      parts: numberEnum([2, 4, 5, 10]),
      total: z.number().int().min(200).max(500).multipleOf(100)
    })
    .refine(val => val.total % val.parts === 0, 'total / parts must have no remainders.'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const parts = getRandomFromArray([2, 4, 5, 10] as const);

    const total = randomIntegerInclusiveStep(200, 500, 100, {
      constraint: x => x % parts === 0
    });

    return { parts, total };
  },
  Component: props => {
    const {
      question: { parts, total },
      translate
    } = props;
    const tickValues = [
      (0).toLocaleString(),
      ...filledArray('', parts - 1),
      total.toLocaleString()
    ];

    const amountPerPart = total / parts;

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [parts.toLocaleString()],
            [total.toLocaleString(), parts.toLocaleString(), amountPerPart.toLocaleString()],
            [amountPerPart.toLocaleString()]
          ]
        }}
        sentences={[
          translate.answerSentences.theNumberLineIsSplitIntoAnsEqualParts(),
          `<ans/> ${DIV} <ans/> = <ans/>`,
          translate.answerSentences.theNumberLineIsCountingUpInAns()
        ]}
        title={translate.instructions.completeSentencesToWorkOutWhatNumberLineIsCountingUpIn()}
        testCorrect={answer =>
          answer[0][0] === parts.toString() &&
          answer[1][0] === total.toString() &&
          // Accept parts and amountPerPart in either order for the division:
          ((answer[1][1] === parts.toString() && answer[1][2] === amountPerPart.toString()) ||
            (answer[1][1] === amountPerPart.toString() && answer[1][2] === parts.toString())) &&
          answer[2][0] === amountPerPart.toString()
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aIM',
  description: 'aIM',
  keywords: ['Scales', 'Number line'],
  schema: z
    .object({
      parts: numberEnum([2, 4, 5, 8, 10]),
      answerATick: z.number().int().min(1).max(9),
      answerBTick: z.number().int().min(1).max(9).optional()
    })
    .refine(val => val.answerATick < val.parts, 'answerATick must be less than parts.')
    .refine(
      val => val.answerATick !== val.answerBTick,
      'answerATick and answerBTick must be different.'
    )
    .refine(
      val => (val.parts > 2 ? val.answerBTick : !val.answerBTick),
      'If parts > 2, answerBTick must be defined.'
    ),
  simpleGenerator: () => {
    const parts = getRandomFromArray([2, 4, 5, 8, 10] as const);
    const answerATick = randomIntegerInclusive(1, parts - 1);

    // answerBTick must not be used if there are only 2 parts:
    const answerBTick =
      parts === 2
        ? undefined
        : randomIntegerInclusive(1, parts - 1, {
            constraint: x => x !== answerATick
          });

    return { parts, answerATick, answerBTick };
  },
  Component: props => {
    const {
      question: { parts, answerATick, answerBTick },
      translate
    } = props;

    const tickValues = [
      (0).toLocaleString(),
      ...filledArray('', parts - 1),
      (200).toLocaleString()
    ];

    const amountPerPart = 200 / parts;

    const answerA = amountPerPart * answerATick;

    const answerB = answerBTick === undefined ? undefined : amountPerPart * answerBTick;

    const answers =
      answerB === undefined ? [answerA] : ([answerA, answerB].sort((a, b) => a + b) as number[]);

    return (
      <QF17CompleteTheNumberLine
        title={
          parts === 2
            ? translate.instructions.whatNumberIsTheArrowPointingTo()
            : translate.instructions.whatNumbersAreTheArrowsPointingTo()
        }
        testCorrect={answers.map(num => num.toString())}
        tickValues={tickValues}
        freeNumberLineAnswer={answers}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aIN',
  description: 'aIN',
  keywords: ['Scales', 'Number line'],
  schema: z
    .object({
      startNumber: numberEnum([20, 50, 100, 150]),
      parts: numberEnum([2, 4, 5, 8, 10]),
      amountPerPart: numberEnum([10, 20, 25, 50]),
      answerATick: z.number().int().min(1).max(9),
      answerBTick: z.number().int().min(1).max(9).optional()
    })
    .refine(val => val.answerATick < val.parts, 'answerATick must be less than parts.')
    .refine(
      val => val.answerATick !== val.answerBTick,
      'answerATick and answerBTick must be different.'
    )
    .refine(
      val => (val.parts > 2 ? val.answerBTick : !val.answerBTick),
      'If parts > 2, answerBTick must be defined.'
    ),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([20, 50, 100, 150] as const);

    const parts = getRandomFromArray([2, 4, 5, 8, 10] as const);

    const amountPerPart = getRandomFromArray([10, 20, 25, 50] as const);

    const answerATick = randomIntegerInclusive(1, parts - 1);

    // answerBTick must not be used if there are only 2 parts:
    const answerBTick =
      parts === 2
        ? undefined
        : randomIntegerInclusive(1, parts - 1, {
            constraint: x => x !== answerATick
          });

    return { startNumber, parts, amountPerPart, answerATick, answerBTick };
  },
  Component: props => {
    const {
      question: { startNumber, parts, amountPerPart, answerATick, answerBTick },
      translate
    } = props;

    const tickValues = [
      startNumber.toLocaleString(),
      ...filledArray('', parts - 1),
      (startNumber + parts * amountPerPart).toLocaleString()
    ];

    const answerA = startNumber + amountPerPart * answerATick;

    const answerB =
      answerBTick === undefined ? undefined : startNumber + amountPerPart * answerBTick;

    const answers =
      answerB === undefined ? [answerA] : ([answerA, answerB].sort((a, b) => a + b) as number[]);

    return (
      <QF17CompleteTheNumberLine
        title={
          parts === 2
            ? translate.instructions.whatNumberIsTheArrowPointingTo()
            : translate.instructions.whatNumbersAreTheArrowsPointingTo()
        }
        testCorrect={answers.map(num => num.toString())}
        tickValues={tickValues}
        freeNumberLineAnswer={answers}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseScales',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
