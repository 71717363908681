import { filledArray, sumNumberArray } from '../../utils/collections';
import { EasyDragAndDropUserAnswer } from './EasyDragAndDrop';

export type DraggableVariant =
  | 'square'
  | 'largeSquare'
  | 'pdfSquare'
  | 'rectangle'
  | 'shortRectangle'
  | 'tallRectangle'
  | 'twoThirdsRectangle';

export const DRAGGABLE_DIMENS = {
  square: { width: 96, height: 96 },
  largeSquare: { width: 212, height: 212 },
  pdfSquare: { width: 150, height: 150 },
  rectangle: { width: 320, height: 96 },
  shortRectangle: { width: 160, height: 96 },
  tallRectangle: { width: 320, height: 150 },
  twoThirdsRectangle: { width: 214, height: 96 }
} as const;

/**
 * Converts the drag and drop state `EasyDragAndDropUserAnswer` (i.e. a list of drop zones, each with a list of ids of
 * draggables within them) to a list of _values_ (which the QF writer understands).
 */
export function getValuesTransformer<DragValue>(
  items: { component: string | JSX.Element; value: DragValue }[],
  moveOrCopy: 'move' | 'copy'
) {
  return {
    transform: (state: EasyDragAndDropUserAnswer): DragValue[][] =>
      state.map(container => (container ?? []).map(itemId => items[itemId]!.value)),
    /** Best effort to place the values using the corresponding draggable IDs. */
    untransform: (transformedState: readonly DragValue[][]): EasyDragAndDropUserAnswer => {
      const itemsCopy: ({ component: string | JSX.Element; value: DragValue } | undefined)[] = [
        ...items
      ];

      return transformedState.map(container =>
        container.flatMap(value => {
          // Try to find an item with this value
          const id = itemsCopy.findIndex(item => item?.value === value);
          if (id === -1) {
            if (moveOrCopy === 'copy') {
              console.warn(
                `No item found in \`items\` prop for value: ${value}. Removing entry from drop zone. `
              );
            } else {
              console.warn(
                `Not enough items found in \`items\` prop for value: ${value}.  Removing entry from drop zone. ` +
                  `The drop zones contain too many entries of this value for how many exist in \`items\`. ` +
                  `Did you mean to use \`moveOrCopy="copy"\`?`
              );
            }
            return [];
          }

          // If 'move', we can only have this item in one place so remove it from our list.
          if (moveOrCopy === 'move') {
            itemsCopy[id] = undefined;
          }
          return [id];
        })
      );
    },
    untransformIndex: (target: number) => target
  };
}

/**
 * In the case where all the drop zones have at most one element, this converts each drop zone's array to a single
 * values (or undefiend).
 * Here, T is a single item's value or ID.
 */
export const singleZonesTransformer = {
  transform: <T>(state: T[][]): (T | undefined)[] => state.map(it => it[0]),
  untransform: <T>(transformedState: (T | undefined)[]): T[][] =>
    transformedState.map(it => (it === undefined ? [] : [it])),
  untransformIndex: (target: number) => target
};

/**
 * Sometimes the QF would prefer to arrange the drop zones as a 2D grid. This transforms a 1D array into a 2D array,
 * using information about the numbers of columns in each row.
 *
 * Here, T is the contents of an entire drop zone.
 *
 * @param columnsPerRow The number of columns allowed in each row.
 */
export function getGridTransformer(columnsPerRow: number[]) {
  const emptyGrid: readonly undefined[][] = columnsPerRow.map(columnsThisRow =>
    filledArray(undefined, columnsThisRow)
  );

  return {
    /** Input must have length equal to sum of columnsPerRow */
    transform: <T>(state: T[]): T[][] => {
      let index = 0;
      return emptyGrid.map(row => row.map(() => state[index++]!));
    },
    untransform: <T>(transformedState: T[][]): T[] => {
      return emptyGrid.flatMap((row, rowIndex) =>
        row.map((_column, columnIndex) => transformedState[rowIndex]![columnIndex]!)
      );
    },
    untransformIndex: (targetRow: number, targetColumn: number): number => {
      const startingIndex = sumNumberArray(
        emptyGrid.filter((_, index) => index < targetRow).map(row => row.length)
      );
      return startingIndex + targetColumn;
    }
  };
}
