import { TranslationFunctions } from '../i18n/i18n-types';
import { BarColorNames } from '../theme/colors';

export const colorsAsWord = (object: BarColorNames | 'White', translate: TranslationFunctions) => {
  switch (object) {
    case 'Blue':
      return translate.colors.Blue();
    case 'Green':
      return translate.colors.Green();
    case 'Orange':
      return translate.colors.Orange();
    case 'Pink':
      return translate.colors.Pink();
    case 'Purple':
      return translate.colors.Purple();
    case 'Red':
      return translate.colors.Red();
    case 'Yellow':
      return translate.colors.Yellow();
    case 'White':
      return translate.colors.White();
    default: {
      throw new Error('Color not translated');
    }
  }
};
