import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { filledArray } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import Text from '../../../../components/typography/Text';
import { getMultiLinkCubeSvgName } from '../../../../utils/multiLinkCubesImages';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aG4',
  description: 'aG4',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    centimetres: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const centimetres = randomIntegerInclusive(2, 5);

    return { centimetres };
  },
  Component: ({ question: { centimetres }, translate }) => {
    const numbers = filledArray(1, centimetres);

    const strings = [
      filledArray(translate.units.numberOfCm(1), centimetres),
      filledArray(translate.units.numberOfMm(10), centimetres)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.thereAreAnsMmInNumCm(centimetres)}
        testCorrect={[(centimetres * 10).toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            total={centimetres}
            numbers={[numbers, numbers]}
            strings={strings}
            dimens={dimens}
            sameRowColor
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aG5',
  description: 'aG5',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    centimetres: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const centimetres = randomIntegerInclusive(2, 5);

    return { centimetres };
  },
  Component: ({ question: { centimetres }, translate }) => {
    const numbers = filledArray(1, centimetres);

    const strings = [
      filledArray(translate.units.numberOfCm(1), centimetres),
      filledArray(translate.units.numberOfMm(10), centimetres)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.thereAreNumMmInAnsCm(centimetres * 10)}
        testCorrect={[centimetres.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            total={centimetres}
            numbers={[numbers, numbers]}
            strings={strings}
            dimens={dimens}
            sameRowColor
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aG6',
  description: 'aG6',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    centimetres: z.number().int().min(1).max(4),
    millimetres: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const centimetres = randomIntegerInclusive(1, 4);

    const millimetres = randomIntegerInclusive(1, 9);

    return { centimetres, millimetres };
  },
  Component: ({ question: { centimetres, millimetres }, translate }) => {
    const total = centimetres * 10 + millimetres;

    const numbers = [[total], [centimetres * 10, millimetres]];

    const strings = [
      [translate.units.numberOfMm(total)],
      [translate.units.stringCm('___'), translate.units.stringMm('___')]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.numMmEqualsAnsCmAndAnsMm(total)}
        inputMaxCharacters={2}
        pdfDirection="column"
        testCorrect={userAnswer =>
          // Ensure equivalent answers are accepted:
          parseInt(userAnswer[0]) * 10 + parseInt(userAnswer[1]) === total
        }
        Content={({ dimens }) => (
          <BarModel total={total} numbers={numbers} strings={strings} dimens={dimens} oneFontSize />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [centimetres.toLocaleString(), millimetres.toLocaleString()],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aG7',
  description: 'aG7',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    centimetres: z.number().int().min(1).max(4),
    millimetres: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const centimetres = randomIntegerInclusive(1, 4);

    const millimetres = randomIntegerInclusive(1, 9);

    return { centimetres, millimetres };
  },
  Component: ({ question: { centimetres, millimetres }, translate }) => {
    const total = centimetres * 10 + millimetres;

    const numbers = [[total], [centimetres * 10, millimetres]];

    const strings = [
      [translate.units.stringMm('___')],
      [translate.units.numberOfCm(centimetres), translate.units.numberOfMm(millimetres)]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.ansMmEqualsNumCmAndNumMm(centimetres, millimetres)}
        pdfDirection="column"
        testCorrect={[total.toString()]}
        Content={({ dimens }) => (
          <BarModel total={total} numbers={numbers} strings={strings} dimens={dimens} oneFontSize />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aG8',
  description: 'aG8',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    blocks: z.number().int().min(3).max(8),
    colour: z.enum(['Blue', 'Green', 'Orange', 'Purple', 'Red'])
  }),
  simpleGenerator: () => {
    const blocks = randomIntegerInclusive(3, 8);

    const colour = getRandomFromArray(['Blue', 'Green', 'Orange', 'Purple', 'Red'] as const);

    return { blocks, colour };
  },
  Component: ({ question: { blocks, colour }, translate, displayMode }) => {
    const columnSvgPath = getMultiLinkCubeSvgName(colour, blocks);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howTallIsTheTower()}
        sentence={translate.answerSentences.ansMm()}
        testCorrect={[(blocks * 30).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
            <View style={{ flexDirection: 'row', bottom: displayMode !== 'digital' ? -16 : 16 }}>
              <Text
                style={{
                  fontSize: 24,
                  alignSelf: 'center',
                  bottom: displayMode !== 'digital' ? 16 : 0
                }}
              >
                {translate.units.numberOfCm(3)}
              </Text>
              <Svg width={20} height={44}>
                <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
                <Path d={`M10,5 L10,39`} stroke={colors.prussianBlue} strokeWidth={2} />
                <Path
                  d={`M10,44
                  L5,34
                  L15,34
                  Z`}
                  fill={colors.prussianBlue}
                />
              </Svg>
            </View>
            {/* 69px is the width that an 8-cube column needs to fill the whole screen 
            - so this needs to be applied to all columns to be consistent */}
            <AssetSvg name={columnSvgPath} width={69} />
          </View>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aG9',
  description: 'aG9',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z
    .object({
      number1: z.number().min(1).max(9),
      number2: z.number().min(1).max(9)
    })
    .refine(val => val.number1 !== val.number2, 'number1 cannot equal number2'),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 9, 2);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const statements = shuffle(
      [
        {
          statement: translate.units.numberOfCm(number1),
          value: 'A'
        },
        {
          statement: translate.units.numberOfMm(number1 * 11),
          value: 'B'
        },
        {
          statement: `${translate.units.numberOfCm(number1)} ${translate.units.numberOfMm(
            number2
          )}`,
          value: 'C'
        },
        {
          statement: `${translate.units.numberOfCm(number2)} ${translate.units.numberOfMm(
            number1
          )}`,
          value: 'D'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchEquivalentLengths()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentLengths()}
        items={[
          {
            component: translate.units.numberOfMm(number1 * 10),
            value: 'A'
          },
          {
            component: `${translate.units.numberOfCm(number1)} ${translate.units.numberOfMm(
              number1
            )}`,
            value: 'B'
          },
          {
            component: translate.units.numberOfMm(number1 * 10 + number2),
            value: 'C'
          },
          {
            component: translate.units.numberOfMm(number2 * 10 + number1),
            value: 'D'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentLengthsCentimetresAndMillimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
