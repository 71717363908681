import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { createShapeWithSquares, perimeterCount } from '../../../../utils/shapes';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { arrayHasNoDuplicates, sumNumberArray } from '../../../../utils/collections';
import { barModelColors } from '../../../../theme/colors';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import {
  getRandomRectilinearLShape,
  getRandomRectilinearShape,
  getRandomUniqueRectilinearShapes,
  labelledRectilinearShapesProperties,
  LShapeNameSchema,
  LShapeProperties,
  RectilinearShapeNameSchema
} from '../../../../utils/rectilinearShapes';
import { rectilinearWithSideRatios } from '../../../../utils/shapeImages/polygons';
import { ShapeNames } from '../../../../utils/labelPositions';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { isInRange } from '../../../../utils/matchers';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { compareFloats } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRO',
  description: 'aRO',
  keywords: [
    'Perimeter',
    'Centimetres',
    'Rectilinear shape',
    'Addition',
    'Side lengths',
    'Grid',
    'Squares'
  ],
  schema: z.object({
    shape: z.array(z.array(z.boolean()))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfSquares1 = randomIntegerInclusive(15, 20);
    const shape = createShapeWithSquares(
      5,
      5,
      numberOfSquares1,
      true,
      undefined,
      undefined,
      true,
      true
    );

    return { shape };
  },
  Component: ({ question: { shape }, translate }) => {
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ shape })
    }) as string;

    const answer = perimeterCount(shape);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansCm()}
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            givenShape={shape}
            cellSizeLabel={translate.units.numberOfCm(1)}
            dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
            color={`${color}70`}
          />
        )}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRP',
  description: 'aRP',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths'
  ],
  schema: z.object({
    rectilinearShapeName: RectilinearShapeNameSchema,
    unit: z.enum(['cm', 'm', 'mm']),
    sideLengths: z.array(z.number().min(1).max(10)),
    allOptions: z
      .array(z.number().min(6).max(80))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const unit = getRandomFromArray(['cm', 'm', 'mm'] as const);

    const { rectilinearShapeName, sideLengths, calcArea } = rejectionSample(
      () => {
        const rectilinearShapeName = getRandomRectilinearShape();
        const { sideLengthRatioOptions, calcArea } =
          labelledRectilinearShapesProperties[rectilinearShapeName];
        const sideLengths = getRandomFromArray(sideLengthRatioOptions) as number[];
        return { rectilinearShapeName, sideLengths, calcArea };
      },
      // Side length should be a max of 10
      ({ sideLengths }) => Math.max(...sideLengths) <= 10
    );

    const perimeter = sumNumberArray(sideLengths);
    const area = calcArea(sideLengths);
    // If area mets the constraints use as a wrong option
    const options = [...new Set(isInRange(6, 80)(area) ? [perimeter, area] : [perimeter])];
    const wrongOptions = randomUniqueIntegersInclusive(6, 80, 4 - options.length, {
      constraint: x => arrayHasNoDuplicates([...options, x])
    });
    const allOptions = shuffle([...options, ...wrongOptions]);

    return { unit, rectilinearShapeName, sideLengths, allOptions };
  },
  Component: props => {
    const {
      question: { unit, rectilinearShapeName, sideLengths, allOptions },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);

    const units =
      unit === 'cm'
        ? translate.units['numberOfCm']
        : unit === 'm'
        ? translate.units['numberOfMm']
        : translate.units['numberOfM'];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectPerimeterOfShape()}
        pdfTitle={translate.instructions.circlePerimeterOfShape()}
        selectables={Object.fromEntries(allOptions.map(key => [key.toString(), units(key)]))}
        correctAnswer={[perimeter.toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <LabelledShape
                shapeName={rectilinearShapeName}
                dimens={{ height: dimens.height, width: dimens.width * 0.9 }}
                labels={sideLengths.map(l => units(l))}
                seed={props.question}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRQ',
  description: 'aRQ',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths'
  ],
  schema: z.object({
    labelledRectilinearShapeName: RectilinearShapeNameSchema,
    isCm: z.boolean(),
    sideLengths: z.array(z.number())
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const isCm = getRandomBoolean();
    const baseValue = randomIntegerInclusive(1, 5);
    const labelledRectilinearShapeName = 'Rectilinear_shape1_all_arrows' as const;
    const sideLengths = rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
      x => x * baseValue
    );

    return { isCm, labelledRectilinearShapeName, sideLengths };
  },
  Component: props => {
    const {
      question: { isCm, labelledRectilinearShapeName, sideLengths },
      translate
    } = props;

    const answer = sumNumberArray(sideLengths);

    const sentence = isCm ? translate.answerSentences.ansCm() : translate.answerSentences.ansMm();
    const units = isCm ? 'numberOfCm' : 'numberOfMm';

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.instructions.calcPerimeterOfShape()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
              shapeName={labelledRectilinearShapeName as ShapeNames}
              labels={sideLengths.map(el => translate.units[units](el))}
              seed={props.question}
            />
          );
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aRQ2',
  description: 'aRQ',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths'
  ],
  schema: z.object({
    labelledRectilinearShapeName: RectilinearShapeNameSchema,
    isCm: z.boolean(),
    sideLengths: z.array(z.number().min(1))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const isCm = getRandomBoolean();
    const labelledRectilinearShapeName = getRandomRectilinearShape();
    const { sideLengthRatioOptions } =
      labelledRectilinearShapesProperties[labelledRectilinearShapeName];
    const sideLengths = getRandomFromArray(sideLengthRatioOptions) as number[];

    return { isCm, labelledRectilinearShapeName, sideLengths };
  },
  Component: props => {
    const {
      question: { isCm, labelledRectilinearShapeName, sideLengths },
      translate
    } = props;

    const answer = sumNumberArray(sideLengths);

    const sentence = isCm ? translate.answerSentences.ansCm() : translate.answerSentences.ansMm();
    const units = isCm ? 'numberOfCm' : 'numberOfMm';

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.instructions.calcPerimeterOfShape()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
              shapeName={labelledRectilinearShapeName}
              labels={sideLengths.map(el => translate.units[units](el))}
              seed={props.question}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRR',
  description: 'aRR',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths',
    'Unknown lengths'
  ],
  schema: z.object({
    missingIndices: z.array(z.number().int().min(0).max(5)).length(2),
    sideLengths: z.array(z.number().int().min(0).max(20)).length(6),
    shapeName: LShapeNameSchema,
    unit: z.enum(['cm', 'm', 'mm']),
    wrongLengthOptions: z.array(z.number().int().min(1).max(20)).length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const unit = getRandomFromArray(['cm', 'm', 'mm'] as const);
    const shapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];
    const sideLengthsRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
    const baseValue = Math.max(...sideLengthsRatio) <= 10 ? randomIntegerInclusive(1, 2) : 1;
    const sideLengths = sideLengthsRatio.map(x => x * baseValue);

    // Get a missing index from different sides
    const missingIdx1 = getRandomFromArray([oppLSides[0][0], ...oppLSides[0][1]]);
    const missingLength1 = sideLengths[missingIdx1];
    const missingIdx2 = rejectionSample(
      () => getRandomFromArray([oppLSides[1][0], ...oppLSides[1][1]]),
      // the two missing side lengths should be different
      x => sideLengths[x] !== missingLength1
    );
    const missingLength2 = sideLengths[missingIdx2];

    const wrongLengthOptions = randomUniqueIntegersInclusive(1, 20, 4, {
      constraint: x => arrayHasNoDuplicates([missingLength1, missingLength2, x])
    });

    return {
      missingIndices: [missingIdx1, missingIdx2],
      shapeName,
      sideLengths,
      unit,
      wrongLengthOptions
    };
  },

  Component: props => {
    const {
      question: { missingIndices, shapeName, sideLengths, unit, wrongLengthOptions },
      translate
    } = props;
    const missingLength1 = sideLengths[missingIndices[0]];
    const missingLength2 = sideLengths[missingIndices[1]];

    const [units, sentenceUnits] =
      unit === 'cm'
        ? [translate.units['numberOfCm'], translate.answerSentences['ansCm']]
        : unit === 'm'
        ? [translate.units['numberOfMm'], translate.answerSentences['ansMm']]
        : [translate.units['numberOfM'], translate.answerSentences['ansM']];

    const items = shuffle([missingLength1, missingLength2, ...wrongLengthOptions], {
      random: seededRandom(props.question)
    });

    const A = translate.letters.A();
    const B = translate.letters.B();

    return (
      <QF36ContentAndSentencesDrag
        title={`${translate.instructions.whatAreLengthsXAndY(
          A,
          B
        )}<br/>${translate.instructions.dragCardsToShowYourAnswer()}`}
        pdfTitle={`${translate.instructions.whatAreLengthsXAndY(
          A,
          B
        )}<br/>${translate.instructions.useCardsToShowYourAnswer()}`}
        items={items.map(l => ({ component: l.toLocaleString(), value: l }))}
        sentences={[`${A} = ${sentenceUnits()}`, `${B} = ${sentenceUnits()}`]}
        testCorrect={[[missingLength1], [missingLength2]]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.85 }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : units(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRS',
  description: 'aRS',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths',
    'Unknown lengths'
  ],
  schema: z.object({
    rectilinearShapeName: LShapeNameSchema,
    sideLengths: z.array(z.number().min(0.5).max(20).step(0.5)),
    missingIndices: z.array(z.number().int().min(0).max(5))
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const rectilinearShapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[rectilinearShapeName];

    const sideLengths = rejectionSample(
      () => {
        const sideLengthRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
        const baseValue =
          Math.max(...sideLengthRatio) <= 13 ? getRandomFromArray([0.5, 1.5] as const) : 0.5;
        const sideLengths = sideLengthRatio.map(x => x * baseValue);
        return sideLengths;
      },
      // Atleast 1 side length should be .5
      sideLengths => sideLengths.some(x => x % 1 === 0.5)
    );

    const missingLongSides = getRandomBoolean();
    // Get a missing index from different sides
    const missingIdx1 = missingLongSides
      ? oppLSides[0][0]
      : getRandomFromArray([...oppLSides[0][1]]);
    const missingIdx2 = missingLongSides
      ? oppLSides[1][0]
      : getRandomFromArray([...oppLSides[1][1]]);

    const missingIndices = [missingIdx1, missingIdx2] as [number, number];

    return { rectilinearShapeName, sideLengths, missingIndices };
  },
  Component: props => {
    const {
      question: { rectilinearShapeName, sideLengths, missingIndices },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);

    const [units, sentenceUnits, sentencePerimeter] = [
      translate.units['numberOfM'],
      translate.answerSentences['ansM'],
      translate.answerSentences['perimeterEqualsAnsM']
    ];

    return (
      <QF1ContentAndSentences
        extraSymbol="decimalPoint"
        title={`${translate.instructions.workOutTheMissingLengthsOnShape()}<br/>${translate.instructions.thenCalcPerimeter()}`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sideLengths[missingIndices[0]]) &&
          compareFloats(userAnswer[1][0], sideLengths[missingIndices[1]]) &&
          compareFloats(userAnswer[2][0], perimeter)
        }
        sentences={[
          `${translate.letters.A()} = ${sentenceUnits()}`,
          `${translate.letters.B()} = ${sentenceUnits()}`,
          `${sentencePerimeter()}`
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [sideLengths[missingIndices[0]].toLocaleString()],
            [sideLengths[missingIndices[1]].toLocaleString()],
            [perimeter.toLocaleString()]
          ]
        }}
        questionHeight={1100}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={rectilinearShapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : units(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aRT',
  description: 'aRT',
  keywords: [
    'Perimeter',
    'Centimetres',
    'Rectilinear shape',
    'Addition',
    'Side lengths',
    'Unknown lengths'
  ],
  schema: z
    .object({
      answerShape: z.enum(['A', 'B']),
      missingIndexA: z.number().int().min(0).max(5),
      missingIndexB: z.number().int().min(0).max(5),
      sideLengthsA: z.array(z.number().min(1).max(15)),
      sideLengthsB: z.array(z.number().min(1).max(15)),
      shapeA: RectilinearShapeNameSchema,
      shapeB: RectilinearShapeNameSchema,
      unit: z.enum(['cm', 'mm', 'm'])
    })
    .refine(
      ({ sideLengthsA, sideLengthsB }) =>
        sumNumberArray(sideLengthsA) !== sumNumberArray(sideLengthsB),
      'perimeters should be different'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const unit = getRandomFromArray(['cm', 'mm', 'm'] as const);
    const [shapeA, shapeB] = getRandomUniqueRectilinearShapes(2);

    const { sideLengthRatioOptions: sideLengthRatioOptionsA } =
      labelledRectilinearShapesProperties[shapeA];

    const { sideLengthRatioOptions: sideLengthRatioOptionsB } =
      labelledRectilinearShapesProperties[shapeB];

    const sideLengthsA = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptionsA) as number[],
      // Side length should be a max of 15
      x => Math.max(...x) <= 15
    );
    const missingIndexA = randomIntegerInclusive(0, 5);

    const sideLengthsB = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptionsB) as number[],
      // Side length should be a max of 15 and perimeters should be different
      x => Math.max(...x) <= 15 && sumNumberArray(x) !== sumNumberArray(sideLengthsA)
    );

    const missingIndexB = randomIntegerInclusive(0, 5);
    const answerShape = getRandomFromArray(['A', 'B'] as const);

    return {
      shapeA,
      shapeB,
      missingIndexA,
      missingIndexB,
      sideLengthsA,
      sideLengthsB,
      answerShape,
      unit
    };
  },

  Component: props => {
    const {
      question: {
        shapeA,
        shapeB,
        missingIndexA,
        missingIndexB,
        sideLengthsA,
        sideLengthsB,
        answerShape,
        unit
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          svgName: shapeA,
          sideLengths: sideLengthsA,
          missingIndex: missingIndexA
        },
        {
          value: 'B',
          svgName: shapeB,
          sideLengths: sideLengthsB,
          missingIndex: missingIndexB
        }
      ],
      { random: seededRandom(props.question) }
    );

    const missingPerimeter =
      answerShape === 'A' ? sumNumberArray(sideLengthsA) : sumNumberArray(sideLengthsB);

    const units =
      unit === 'cm'
        ? translate.units['numberOfCm']
        : unit === 'm'
        ? translate.units['numberOfMm']
        : translate.units['numberOfM'];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapeThatHasPerimeterOfX(units(missingPerimeter))}
        pdfTitle={translate.instructions.circleShapeThatHasPerimeterOfX(units(missingPerimeter))}
        testCorrect={[answerShape]}
        questionHeight={1000}
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName, sideLengths, missingIndex }) => ({
            value,
            component: (
              <LabelledShape
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                shapeName={svgName}
                labels={sideLengths.map((val, idx) => (idx === missingIndex ? '?' : units(val)))}
                seed={props.question}
              />
            )
          }))
        }
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aRT2',
  description: 'aRT2',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths',
    'Unknown lengths'
  ],
  schema: z.object({
    rectilinearShapeName: LShapeNameSchema,
    sideLengths: z.array(z.number().min(0.5).max(20).step(0.5)),
    missingIndices: z.array(z.number().int().min(0).max(5))
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const rectilinearShapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[rectilinearShapeName];

    const sideLengths = rejectionSample(
      () => {
        const sideLengthRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
        const baseValue =
          Math.max(...sideLengthRatio) <= 13 ? getRandomFromArray([0.5, 1.5] as const) : 0.5;
        const sideLengths = sideLengthRatio.map(x => x * baseValue);
        return sideLengths;
      },
      // Atleast 1 side length should be .5
      sideLengths => sideLengths.some(x => x % 1 === 0.5)
    );

    const mix = getRandomBoolean();
    // Get a missing index from different sides
    const missingIdx1 = mix ? oppLSides[0][0] : getRandomFromArray([...oppLSides[0][1]]);
    const missingIdx2 = mix ? getRandomFromArray([...oppLSides[1][1]]) : oppLSides[1][0];

    const missingIndices = [missingIdx1, missingIdx2] as [number, number];

    return { rectilinearShapeName, sideLengths, missingIndices };
  },
  Component: props => {
    const {
      question: { rectilinearShapeName, sideLengths, missingIndices },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);

    const [units, sentenceUnits, sentencePerimeter] = [
      translate.units['numberOfM'],
      translate.answerSentences['ansM'],
      translate.answerSentences['perimeterEqualsAnsM']
    ];

    return (
      <QF1ContentAndSentences
        extraSymbol="decimalPoint"
        title={`${translate.instructions.workOutTheMissingLengthsOnShape()}<br/>${translate.instructions.thenCalcPerimeter()}`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sideLengths[missingIndices[0]]) &&
          compareFloats(userAnswer[1][0], sideLengths[missingIndices[1]]) &&
          compareFloats(userAnswer[2][0], perimeter)
        }
        sentences={[
          `${translate.letters.A()} = ${sentenceUnits()}`,
          `${translate.letters.B()} = ${sentenceUnits()}`,
          `${sentencePerimeter()}`
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [sideLengths[missingIndices[0]].toLocaleString()],
            [sideLengths[missingIndices[1]].toLocaleString()],
            [perimeter.toLocaleString()]
          ]
        }}
        questionHeight={1100}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={rectilinearShapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : units(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfRectilinearShapes',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question3, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
