import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { lessThanGreaterThanOrEqualTo, numberToBase10Object } from '../../../../utils/math';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bff',
  description: 'bff',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numberA: z.number().int().min(10).max(100).step(10),
    numberB: z.number().int().min(10).max(100).step(10),
    variant: z.enum(['Straws', 'Cubes'])
  }),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusiveStep(10, 100, 10);
    const numberB = randomIntegerInclusiveStep(10, 100, 10);
    const variant = getRandomFromArray(['Straws', 'Cubes'] as const);
    return {
      numberA,
      numberB,
      variant
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, variant },
      translate,
      displayMode
    } = props;
    const items = ['>', '<', '='];
    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  tens: Math.floor(numberA / 10)
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  tens: Math.floor(numberB / 10)
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];
    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompare()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bfg',
  description: 'bfg',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numberA: z.number().int().min(1).max(100),
    numberB: z.number().int().min(1).max(100),
    variant: z.enum(['Straws', 'Cubes'])
  }),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 100);
    const numberB = randomIntegerInclusive(1, 100);
    const variant = getRandomFromArray(['Straws', 'Cubes'] as const);
    return {
      numberA,
      numberB,
      variant
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, variant },
      translate,
      displayMode
    } = props;
    const items = ['>', '<', '='];

    const scales = [numberA, numberB].map(
      number => BaseTenRepCalcGridsAndScale(350, 300, numberToBase10Object(number), variant).scale
    );
    const scale = Math.min(...scales);

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  ones: numberA % 10,
                  tens: Math.floor(numberA / 10)
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
              scale={scale}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  ones: numberB % 10,
                  tens: Math.floor(numberB / 10)
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
              scale={scale}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];
    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompare()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bfh',
  description: 'bfh',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(99),
      numberB: z.number().int().min(1).max(99)
    })
    .refine(({ numberA, numberB }) => numberA !== numberB, 'numberA cannot equal numberB'),
  simpleGenerator: () => {
    const [numberA, numberB] = randomUniqueIntegersInclusive(1, 99, 2);

    return { numberA, numberB };
  },
  Component: ({ question: { numberA, numberB }, translate }) => {
    const statement = [
      {
        lhsComponent: (
          <View>
            <Text variant="WRN400">{numberA.toLocaleString()}</Text>
          </View>
        ),
        rhsComponent: (
          <View>
            <Text variant="WRN400">{numberB.toLocaleString()}</Text>
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        statements={statement}
        items={['>', '<', '=']}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        mainPanelStyle={{ alignItems: 'center' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAnyTwoNumbers',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
