import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  sortNumberArray
} from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const objects = ['flowers', 'trees', 'blocks', 'ribbons', 'trains', 'pencils'] as const;

const Question1 = newQuestionContent({
  uid: 'bjB',
  description: 'bjB',
  keywords: ['Tallest', 'Shortest', 'Longest', 'Height', 'Length', 'Compare'],
  questionHeight: 1000,
  schema: z.object({
    isVertical: z.boolean(),
    answer: z.enum(['shortest', 'largest']),
    objects: z.enum(objects),
    order: z.array(z.enum(['shortest', 'medium', 'largest'])).length(3)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const answer = getRandomFromArray(['shortest', 'largest'] as const);

    const verticalObjects = ['flowers', 'trees', 'blocks'] as const;
    const horizontalObjects = ['ribbons', 'trains', 'pencils'] as const;
    const objects = getRandomFromArray(isVertical ? verticalObjects : horizontalObjects);

    const order = shuffle(['shortest', 'medium', 'largest'] as const);

    return { objects, isVertical, answer, order };
  },
  Component: props => {
    const {
      question: { objects, isVertical, answer, order },
      translate
    } = props;
    const random = seededRandom(props.question);
    const svgNames = ((): SvgName[] => {
      switch (objects) {
        case 'flowers':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            3,
            { random }
          );
        case 'trees': {
          const tree = getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
          return filledArray(tree, 3);
        }
        case 'ribbons':
          return countRange(3).map(_ =>
            getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random })
          );
        case 'trains':
          return filledArray('Transport_toys/Train_carriage' as const, 3);
        case 'pencils':
          return shuffle(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );

        case 'blocks': {
          const blockColours = getRandomSubArrayFromArray(
            ['red', 'green', 'orange', 'blue'] as const,
            3,
            { random }
          );
          return ([1, 2, 3] as const).map(
            (n, i) => `Multi_link_cubes/column_${n}_${blockColours[i]}` as const
          );
        }
      }
    })();

    const getScale = (size: 'shortest' | 'medium' | 'largest') => {
      switch (size) {
        case 'shortest':
          return 0.3;
        case 'medium':
          return objects === 'trees' ? 0.4 : 0.6;
        case 'largest':
          return objects === 'trees' ? 0.6 : 0.8;
      }
    };

    const numberOfObjects = {
      shortest: 1,
      medium: 2,
      largest: 3
    };

    const [title, pdfTitle] = (() => {
      switch (objects) {
        case 'flowers':
          return answer === 'shortest'
            ? (['selectTheShortestFlower', 'tickTheShortestFlower'] as const)
            : (['selectTheTallestFlower', 'tickTheTallestFlower'] as const);
        case 'trees':
          return answer === 'shortest'
            ? (['selectTheShortestTree', 'tickTheShortestTree'] as const)
            : (['selectTheTallestTree', 'tickTheTallestTree'] as const);
        case 'blocks':
          return answer === 'shortest'
            ? (['selectTheShortestTowerOfCubes', 'tickTheShortestTowerOfCubes'] as const)
            : (['selectTheTallestTowerOfCubes', 'tickTheTallestTowerOfCubes'] as const);
        case 'ribbons':
          return answer === 'shortest'
            ? (['selectTheShortestRibbon', 'tickTheShortestRibbon'] as const)
            : (['selectTheLongestRibbon', 'tickTheLongestRibbon'] as const);
        case 'trains':
          return answer === 'shortest'
            ? (['selectTheShortestTrain', 'tickTheShortestTrain'] as const)
            : (['selectTheLongestTrain', 'tickTheLongestTrain'] as const);
        case 'pencils':
          return answer === 'shortest'
            ? (['selectTheShortestPencil', 'tickTheShortestPencil'] as const)
            : (['selectTheLongestPencil', 'tickTheLongestPencil'] as const);
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        questionHeight={1000}
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={[answer]}
        numItems={3}
        itemLayout={isVertical ? 'row' : 'column'}
        itemStyle={{
          alignItems: isVertical ? 'center' : 'flex-start',
          justifyContent: isVertical ? 'flex-end' : 'center',
          padding: 32,
          marginVertical: 4
        }}
        renderItems={({ dimens }) => {
          return order.map((o, i) => ({
            value: o,
            component: (
              <>
                {objects === 'blocks' ? (
                  <AssetSvg name={svgNames[numberOfObjects[o] - 1]} width={dimens.width * 0.5} />
                ) : objects === 'trains' ? (
                  <View style={{ flexDirection: 'row' }}>
                    {countRange(numberOfObjects[o]).map(j => (
                      <AssetSvg key={`${0}_${j}`} name={svgNames[i]} height={dimens.height * 0.8} />
                    ))}
                  </View>
                ) : (
                  <AssetSvg
                    name={svgNames[i]}
                    height={isVertical ? dimens.height * getScale(order[i]) : undefined}
                    width={isVertical ? undefined : dimens.width * getScale(order[i])}
                  />
                )}
              </>
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjC',
  description: 'bjC',
  keywords: ['Tallest', 'Shortest', 'Longest', 'Height', 'Length', 'Compare'],
  questionHeight: 1100,
  schema: z.object({
    isVertical: z.boolean(),
    answer: z.enum(['shortest', 'largest']),
    objects: z.enum(objects),
    order: z.array(z.enum(['shortest', 'medium', 'largest'])).length(3)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const answer = getRandomFromArray(['shortest', 'largest'] as const);

    const verticalObjects = ['flowers', 'trees', 'blocks'] as const;
    const horizontalObjects = ['ribbons', 'trains', 'pencils'] as const;
    const objects = getRandomFromArray(isVertical ? verticalObjects : horizontalObjects);

    const order = shuffle(['shortest', 'medium', 'largest'] as const);

    return { objects, isVertical, answer, order };
  },
  Component: props => {
    const {
      question: { objects, isVertical, answer, order },
      translate
    } = props;

    const random = seededRandom(props.question);
    const svgNames = ((): SvgName[] => {
      switch (objects) {
        case 'flowers':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            3,
            { random }
          );
        case 'trees': {
          const tree = getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
          return filledArray(tree, 3);
        }
        case 'ribbons':
          return countRange(3).map(_ =>
            getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random })
          );
        case 'trains':
          return filledArray('Transport_toys/Train_carriage' as const, 3);
        case 'pencils':
          return shuffle(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );

        case 'blocks': {
          const blockColours = getRandomSubArrayFromArray(
            ['red', 'green', 'orange', 'blue'] as const,
            3,
            { random }
          );
          return ([1, 2, 3] as const).map(
            (n, i) => `Multi_link_cubes/column_${n}_${blockColours[i]}` as const
          );
        }
      }
    })();

    const getScale = (size: 'shortest' | 'medium' | 'largest') => {
      switch (size) {
        case 'shortest':
          return 0.3;
        case 'medium':
          return 0.6;
        case 'largest':
          return 0.8;
      }
    };

    const numberOfObjects = {
      shortest: 1,
      medium: 2,
      largest: 3
    };

    const items = [
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.Tallest()),
        value: 'tallest'
      },
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.Shortest()),
        value: 'shortest'
      },
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.Longest()),
        value: 'longest'
      }
    ];

    const letters = ['A', 'B', 'C'] as const;

    const ansOrderIndex = order.indexOf(answer);
    const answerLetter = translate.letters[letters[ansOrderIndex]]();

    const ans = answer === 'largest' ? (isVertical ? 'tallest' : 'longest') : 'shortest';

    const sentence = (() => {
      switch (objects) {
        case 'flowers':
          return 'flowerXIsTheAnsFlower';
        case 'trees':
          return 'treeXIsTheAnsTree';
        case 'blocks':
          return 'towerOfCubesXIsTheAnsTowerOfCubes';
        case 'ribbons':
          return 'ribbonXIsTheAnsRibbon';
        case 'trains':
          return 'trainXIsTheAnsTrain';
        case 'pencils':
          return 'pencilXIsTheAnsPencil';
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1100}
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: isVertical ? 'row' : 'column',
              justifyContent: 'space-evenly'
            }}
          >
            {letters.map((l, i) => (
              <View
                key={l}
                style={{
                  flexDirection: isVertical ? 'column-reverse' : 'row',
                  alignItems: 'center'
                }}
              >
                <Text variant="WRN700" style={{ paddingRight: isVertical ? 0 : 16 }}>
                  {translate.letters[l]()}
                </Text>
                {objects === 'blocks' ? (
                  <AssetSvg
                    name={svgNames[numberOfObjects[order[i]] - 1]}
                    height={dimens.height * getScale(order[i])}
                  />
                ) : objects === 'trains' ? (
                  <View style={{ flexDirection: 'row' }}>
                    {countRange(numberOfObjects[order[i]]).map(j => (
                      <AssetSvg
                        key={`${0}_${j}`}
                        name={svgNames[i]}
                        height={dimens.height * 0.28}
                      />
                    ))}
                  </View>
                ) : (
                  <AssetSvg
                    name={svgNames[i]}
                    height={isVertical ? dimens.height * getScale(order[i]) : undefined}
                    width={isVertical ? undefined : dimens.width * getScale(order[i])}
                  />
                )}
              </View>
            ))}
          </View>
        )}
        sentence={translate.ks1AnswerSentences[sentence](answerLetter)}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        testCorrect={[ans]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjD',
  description: 'bjD',
  keywords: [
    'Length',
    'Height',
    'Order',
    'Shortest',
    'Longest',
    'Tallest',
    'Centimetres',
    'Metres'
  ],
  questionHeight: 800,
  schema: z.object({
    units: z.enum(['cm', 'm']),
    values: z
      .array(z.number().int().min(1).max(100))
      .length(4)
      .refine(arrayHasNoDuplicates, 'values should be different'),
    order: z.enum(['ascending', 'descending']),
    isLength: z.boolean()
  }),
  simpleGenerator: () => {
    const units = getRandomFromArray(['cm', 'm'] as const);
    const order = getRandomFromArray(['ascending', 'descending'] as const);
    const values = randomUniqueIntegersInclusive(1, 100, 4);
    const isLength = getRandomBoolean();
    return { units, order, values, isLength };
  },
  Component: props => {
    const {
      question: { units, order, values, isLength },
      translate
    } = props;

    const biggerLabel = isLength ? 'Longest' : 'Tallest';

    const title1 = isLength ? 'dragTheCardsToOrderTheLengths' : 'dragTheCardsToOrderTheHeights';
    const pdfTitle1 = isLength ? 'useTheCardsToOrderTheLengths' : 'useTheCardsToOrderTheHeights';

    const title2 =
      order === 'ascending'
        ? 'startWithTheShortest'
        : isLength
        ? 'startWithTheLongest'
        : 'startWithTheTallest';

    return (
      <QF4DragOrderVertical
        questionHeight={800}
        title={`${translate.ks1Instructions[title1]()}<br/>${translate.ks1Instructions[title2]()}`}
        pdfTitle={`${translate.ks1PDFInstructions[pdfTitle1]()}<br/>${translate.ks1Instructions[
          title2
        ]()}`}
        testCorrect={sortNumberArray(values, order)}
        items={values.map(value => ({
          value,
          component:
            units === 'cm' ? translate.units.numberOfCm(value) : translate.units.numberOfM(value)
        }))}
        topLabel={order === 'ascending' ? translate.misc.Shortest() : translate.misc[biggerLabel]()}
        bottomLabel={
          order === 'ascending' ? translate.misc[biggerLabel]() : translate.misc.Shortest()
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderLengthsAndHeights',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
