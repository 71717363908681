// Dice Arrangements

export const getDiceArrangementArray = (num: number): boolean[][] => {
  if (num < 0 || num > 9) {
    throw new Error('Number must be between 0 and 9.');
  }

  switch (num) {
    case 0:
      return [
        [false, false, false],
        [false, false, false],
        [false, false, false]
      ];
    case 1:
      return [
        [false, false, false],
        [false, true, false],
        [false, false, false]
      ];
    case 2:
      return [
        [false, false, true],
        [false, false, false],
        [true, false, false]
      ];
    case 3:
      return [
        [false, false, true],
        [false, true, false],
        [true, false, false]
      ];
    case 4:
      return [
        [true, false, true],
        [false, false, false],
        [true, false, true]
      ];
    case 5:
      return [
        [true, false, true],
        [false, true, false],
        [true, false, true]
      ];
    case 6:
      return [
        [true, false, true],
        [true, false, true],
        [true, false, true]
      ];
    case 7:
      return [
        [true, false, false],
        [true, true, true],
        [true, true, true]
      ];
    case 8:
      return [
        [true, true, false],
        [true, true, true],
        [true, true, true]
      ];
    case 9:
      return [
        [true, true, true],
        [true, true, true],
        [true, true, true]
      ];
    default:
      return [
        [false, false, false],
        [false, false, false],
        [false, false, false]
      ];
  }
};
