import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { ADD, SUB } from 'common/src/constants';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { Fraction, fractionToDecimal, simplify } from 'common/src/utils/fractions';
import { findFactors } from 'common/src/utils/factors';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import { isPrime } from 'common/src/utils/primes';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import { View } from 'react-native';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqx',
  description: 'aqx',
  keywords: ['Add', 'Fractions'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(11),
      number2: z.number().int(),
      number3: z.number().int().min(1).max(12),
      number4: z.number().int().min(2).max(12),
      number5: z.number().int(),
      number6: z.number().int()
    })
    .refine(val => val.number4 % val.number2 === 0, 'number4 must be a multiple of number2'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { number1, number2, number3, number4, number5, number6 } = rejectionSample(
      () => {
        const number4 = randomIntegerInclusive(2, 12, {
          constraint: x => !isPrime(x)
        });
        const number4Factor = getRandomFromArray(
          findFactors(number4).filter(number => number !== 1 && number !== number4)
        ) as number;
        const number2 = number4Factor;
        // Must be less than number2
        const number1 = randomIntegerInclusive(1, number2 - 1);
        // Must be less than number4
        const number3 = randomIntegerInclusive(1, number4 - 1);
        const number5 = number1 * (number4 / number2);
        const number6 = number5 + number3;

        return { number1, number2, number3, number4, number5, number6 };
      },
      ({ number4, number6 }) => number6 / number4 < 1
    );

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const bars = [
      {
        rows: 1,
        cols: number4
      }
    ];

    const sentence = `<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> ${ADD} <frac n='${number3.toLocaleString()}' d='${number4.toLocaleString()}' /> = <frac nAns='' d='${number4.toLocaleString()}'/> ${ADD} <frac n='${number3.toLocaleString()}' d='${number4.toLocaleString()}' /> = <frac nAns='' d='${number4.toLocaleString()}' />`;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.completeAddition()}<br/>${translate.instructions.shadeBarModelToHelp()}`}
        testCorrect={{
          sentence: [number5, number6]
        }}
        sentence={sentence}
        bars={bars}
        questionHeight={1000}
        pdfTableHeight={200}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.barModelDoesNotNeedToBeShaded()
        }}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqy',
  description: 'aqy',
  keywords: ['Subtract', 'Fractions'],
  schema: z
    .object({
      number1: z.number().int().min(5).max(11),
      number2: numberEnum([6, 8, 9, 10, 12]),
      number3: z.number().int().min(1).max(4),
      number4: z.number().int()
    })
    .refine(val => val.number3 < val.number4, 'number3 must be less than number4')
    .refine(
      val => findFactors(val.number2).includes(val.number4),
      'number4 must be a factor of number2'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const number2 = getRandomFromArray([6, 8, 9, 10, 12] as const);
        const number2Factor = getRandomFromArray(
          findFactors(number2).filter(number => number !== 1 && number !== number2)
        ) as number;
        // Must be less than number2
        const number1 = randomIntegerInclusive(5, number2 - 1);
        const number4 = number2Factor;
        const number3 = randomIntegerInclusive(1, 4, {
          constraint: x => x < number4
        });

        return { number1, number2, number3, number4 };
      },
      ({ number1, number2, number3, number4 }) =>
        number1 / number2 !== 1 && number1 / number2 > number3 / number4
    );

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const number5 = number3 * (number2 / number4);
    const number6 = number1 - number5;

    const bars = [
      {
        rows: 1,
        cols: number2
      }
    ];

    const sentence = `<frac n='${number1}' d='${number2}'/> ${SUB} <frac n='${number3}' d='${number4}' /> = <frac n='${number1}' d='${number2}' /> ${SUB} <frac nAns='' d='${number2}' /> = <frac nAns='' d='${number2}' />`;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.completeSubtractions()}<br/>${translate.instructions.useTheBarModelToHelp()}`}
        testCorrect={{
          sentence: [number5, number6]
        }}
        sentence={sentence}
        bars={bars}
        questionHeight={1000}
        pdfTableHeight={200}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.barModelDoesNotNeedToBeShaded()
        }}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aqz',
  description: 'aqz',
  keywords: ['Subtraction', 'Fractions'],
  schema: z
    .object({
      name: nameSchema,
      number1: z.number().int().min(1).max(6),
      number2: z.number().int().min(2).max(6),
      number3: z.number().int().min(1).max(10),
      number4: z.number().int().min(5).max(20)
    })
    .refine(val => val.number4 % val.number2 === 0, 'number4 is a multiple of number2')
    .refine(
      val => val.number1 * val.number4 + val.number2 * val.number3 < val.number2 * val.number4,
      'Answer must be less than 1'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomName();

    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(2, 6);
        const number1 = randomIntegerInclusive(1, number2 - 1);

        const number4 = randomIntegerInclusive(5, 20, {
          constraint: x => x % number2 === 0
        });
        const number3 = randomIntegerInclusive(1, 10, {
          constraint: x => x < number4 && simplify(x, number4)[0] === x
        });

        return { number1, number2, number3, number4 };
      },
      ({ number1, number2, number3, number4 }) =>
        number1 * number4 + number3 * number2 < number2 * number4
    );

    return { name, number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { name, number1, number2, number3, number4 },
      displayMode,
      translate
    } = props;

    const ansNumerator = number2 * number4 - (number1 * number4 + number2 * number3);
    const ansDenominator = number2 * number4;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    const [simplifiedAns1, simplifiedAns2] = simplify(ansNumerator, ansDenominator);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterSpendsPocketMoney(
          name,
          `<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/>`,
          `<frac n='${simplifiedNum3}' d='${simplifiedNum4}'/>`
        )}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 36 : 64 }}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        mainPanelContainerStyle={{ alignItems: 'flex-end' }}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={`<frac nAns='' dAns=''/>`}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqA',
  description: 'aqA',
  keywords: ['Addition', 'Subtraction', 'Fractions'],
  schema: z.object({
    calcAFractions: fractionSchema().array().length(2),
    calcBFractions: fractionSchema().array().length(2),
    calcCFractions: fractionSchema().array().length(2)
  }),
  simpleGenerator: () => {
    // Equation A:
    const number2 = randomIntegerInclusive(3, 8);
    const number1 = randomIntegerInclusive(1, number2 - 1);
    const number4 = randomIntegerInclusive(6, 20, {
      constraint: x => x % number2 === 0
    });
    const number3 = randomIntegerInclusive(2, 10, {
      constraint: x =>
        x < number4 &&
        // Fractions must never total to 1 otherwise this question is impossible to answer:
        number(
          math.evaluate(`${fractionToDecimal(x, number4)} + ${fractionToDecimal(number1, number2)}`)
        ) !== 1
    });

    // Equation B:
    const { number5, number6, number7, number8 } = rejectionSample(
      () => {
        const number6 = randomIntegerInclusive(3, 10);
        const number5 = randomIntegerInclusive(1, number6 - 1);
        const number8 = randomIntegerInclusive(8, 30, {
          constraint: x => x % number6 === 0
        });
        const number7 = randomIntegerInclusive(1, number8 - 1, {
          constraint: x =>
            // Fractions must never total to 1 otherwise this question is impossible to answer:
            number(
              math.evaluate(
                `${fractionToDecimal(x, number8)} + ${fractionToDecimal(number5, number6)}`
              )
            ) !== 1
        });

        return { number5, number6, number7, number8 };
      },
      ({ number5, number6, number7, number8 }) => number5 / number6 > number7 / number8
    );

    // Equation C:
    const number10 = randomIntegerInclusive(3, 16);
    const number9 = randomIntegerInclusive(1, number10 - 1);
    const number12 = randomIntegerInclusive(20, 60, {
      constraint: x => x % number10 === 0
    });
    const number11 = randomIntegerInclusive(1, number12 - 1, {
      constraint: x =>
        // Fractions must never total to 1 otherwise this question is impossible to answer:
        number(
          math.evaluate(
            `${fractionToDecimal(x, number12)} + ${fractionToDecimal(number9, number10)}`
          )
        ) !== 1
    });

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);
    const [simplifiedNum7, simplifiedNum8] = simplify(number7, number8);

    const [simplifiedNum9, simplifiedNum10] = simplify(number9, number10);
    const [simplifiedNum11, simplifiedNum12] = simplify(number11, number12);

    const calcAFractions: Fraction[] = [
      [simplifiedNum1, simplifiedNum2],
      [simplifiedNum3, simplifiedNum4]
    ];

    const calcBFractions: Fraction[] = [
      [simplifiedNum5, simplifiedNum6],
      [simplifiedNum7, simplifiedNum8]
    ];

    const calcCFractions: Fraction[] = [
      [simplifiedNum9, simplifiedNum10],
      [simplifiedNum11, simplifiedNum12]
    ];

    return { calcAFractions, calcBFractions, calcCFractions };
  },
  Component: props => {
    const {
      question: { calcAFractions, calcBFractions, calcCFractions },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${calcAFractions[0][0]}' d='${calcAFractions[0][1]}'/> ${ADD} <frac n='${calcAFractions[1][0]}' d='${calcAFractions[1][1]}'/>`}
            />
          </View>
        ),
        correctAnswer:
          Number(fractionToDecimal(...calcAFractions[0])) +
            Number(fractionToDecimal(...calcAFractions[1])) >
          1
            ? 'greater than 1'
            : 'less than 1'
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${calcBFractions[0][0]}' d='${calcBFractions[0][1]}'/> ${SUB} <frac n='${calcBFractions[1][0]}' d='${calcBFractions[1][1]}'/>`}
            />
          </View>
        ),
        correctAnswer:
          Number(fractionToDecimal(...calcBFractions[0])) -
            Number(fractionToDecimal(...calcBFractions[1])) >
          1
            ? 'greater than 1'
            : 'less than 1'
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${calcCFractions[0][0]}' d='${calcCFractions[0][1]}'/> ${ADD} <frac n='${calcCFractions[1][0]}' d='${calcCFractions[1][1]}'/>`}
            />
          </View>
        ),
        correctAnswer:
          Number(fractionToDecimal(...calcCFractions[0])) +
            Number(fractionToDecimal(...calcCFractions[1])) >
          1
            ? 'greater than 1'
            : 'less than 1'
      }
    ];

    const items = [
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: 24 }}>
            {translate.answerSentences.answerIsGreaterThanNum(1)}
          </Text>
        ),
        value: 'greater than 1'
      },
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: 24 }}>
            {translate.answerSentences.answerIsLessThanNum(1)}
          </Text>
        ),
        value: 'less than 1'
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchStatementToCalc()}
        pdfTitle={translate.instructions.matchStatementToCalc()}
        items={items}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        moveOrCopy="copy"
        useArrows={false}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aqB',
  description: 'aqB',
  keywords: ['Addition', 'Subtraction', 'Fractions'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(50),
      number2: z.number().int().min(4).max(50),
      number3: z.number().int().min(11).max(30),
      number4: z.number().int().min(12).max(100)
    })
    .refine(val => val.number4 % val.number2 === 0, 'number2 is a factor of number4')
    .refine(
      val => val.number1 * val.number4 < val.number3 * val.number2,
      'answer must not be a negative'
    ),
  simpleGenerator: () => {
    // Constraints to fulfil:
    // - number1 < number2
    // - number2 must be factor of number4
    // - number3 < number4
    // - answer must not be negative
    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const number4 = randomIntegerInclusive(12, 100);
        const number2 = randomIntegerInclusive(4, 50);
        const number1 = randomIntegerInclusive(2, 50, {
          constraint: x => x < number2
        });
        const number3 = randomIntegerInclusive(11, 30, {
          constraint: x => x < number4
        });

        return { number1, number2, number3, number4 };
      },
      ({ number1, number2, number3, number4 }) =>
        findFactors(number4).includes(number2) &&
        number1 * number4 < number3 * number2 &&
        // Denominators for the fractions in the sentence cannot be the same:
        simplify(number1, number2)[1] !== simplify(number3, number4)[1]
    );

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answer1 = number3 * number2 - number1 * number4;
    const answer2 = number2 * number4;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    const [simplifiedAns1, simplifiedAns2] = simplify(answer1, answer2);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={`<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/> ${ADD} <frac nAns='' dAns=''/> = <frac n='${simplifiedNum3}' d='${simplifiedNum4}'/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqC',
  description: 'aqC',
  keywords: ['Subtraction', 'Fractions'],
  schema: z
    .object({
      name1: nameSchema,
      name2: nameSchema,
      number1: z.number().int().min(6).max(9),
      number2: z.number().int().min(1).max(19),
      number3: z.number().int().min(1).max(20)
    })
    .refine(
      val => val.number1 * 10 - val.number2 * 2 - val.number3 > 0,
      'answer must be greater than 0'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const name1 = getRandomName();
    const name2 = getRandomName();

    const number1 = randomIntegerInclusive(6, 9);
    const number2 = randomIntegerInclusive(1, 19);
    const number3 = randomIntegerInclusive(1, 20);

    return { name1, name2, number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { name1, name2, number1, number2, number3 },
      displayMode,
      translate
    } = props;

    const answer1 = number1 * 10 - number2 * 2 - number3;

    const [simplifiedAns1, simplifiedAns2] = simplify(answer1, 100);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howMuchJuiceLeftFraction(
          `<frac n='${number1}' d='10'/>`,
          name1,
          `<frac n='${number2}' d='50'/>`,
          name2,
          `<frac n='${number3}' d='100'/>`
        )}
        titleFractionDividerStyle={{ marginVertical: 0 }}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 32 : 64 }}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleStyle={{ rowGap: 5 }}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={`<frac nAns='' dAns=''/> litres`}
        questionHeight={1200}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractSimpleFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
