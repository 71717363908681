import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom
} from 'common/src/utils/random';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { improperFractionToMixedNumber, portionToText, simplify } from 'common/src/utils/fractions';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import { View } from 'react-native';
import MultiPieChart, {
  calculateRadius
} from 'common/src/components/question/representations/MultiPieChart';
import PieChart from 'common/src/components/question/representations/PieChart';
import { compareFractions, Fraction, fractionToDecimal } from 'common/src/utils/fractions';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { PieChartColors } from 'common/src/theme/colors';
import QF19NumberLineDragArrow from 'common/src/components/question/questionFormats/QF19NumberLineDragArrow';
import { filledArray, range } from 'common/src/utils/collections';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import RowOfImages from '../../../../components/molecules/RowOfImages';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apa',
  description: 'apa',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(8),
    answer1: z.number().int().min(1).max(2),
    answer3: z.number().int().min(1).max(7),
    missingSlices: z.array(z.number().int().min(0).max(7))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);

    const answer1 = randomIntegerInclusive(1, 2);
    const answer3 = randomIntegerInclusive(1, number1 - 1);

    // Generate random indices for missing slices
    const missingSlices = randomUniqueIntegersInclusive(0, number1 - 1, number1 - answer3);

    return { number1, answer1, answer3, missingSlices };
  },
  Component: props => {
    const {
      question: { number1, answer1, answer3, missingSlices },
      translate
    } = props;

    // answer2 === number1
    const number2 = answer1 * number1 + answer3;
    const correctFrac = [answer1, answer3, number1];

    // Test correct for simplified answers
    const testCorrect = (answer: string[]): boolean => {
      const frac = [...answer];

      return compareFractions(frac, correctFrac);
    };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.convertImproperFractionToMixedNumber()}
        testCorrect={testCorrect}
        inputMaxCharacters={2}
        sentence={`<frac n='${number2}' d='${number1}'/> = <frac wAns='' nAns='' dAns=''/>`}
        Content={({ dimens }) => {
          const pieChartRadius = calculateRadius(dimens, answer1 + 1);

          const color = getRandomFromArray(PieChartColors, {
            random: seededRandom(props.question)
          }) as string;

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                  columnGap: answer1 === 1 ? 16 : 0
                }
              ]}
            >
              <MultiPieChart
                numberOfPieCharts={answer1}
                slicesPerChart={number1}
                chartsPerRow={answer1}
                dimens={dimens}
                radius={pieChartRadius}
                color={color}
              />
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, number1)}
                missingSlices={missingSlices}
                radius={pieChartRadius}
                color={color}
              />
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac.map(ans => ans.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'apb',
  description: 'apb',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    denominator: z.number().int().min(2).max(8),
    answer1: z.number().int().min(1).max(2),
    answer3: z.number().int().min(1).max(7)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const answer1 = randomIntegerInclusive(1, 2);
    const answer3 = randomIntegerInclusive(1, denominator - 1);

    return { denominator, answer1, answer3 };
  },

  Component: props => {
    const {
      question: { denominator, answer1, answer3 },
      translate
    } = props;

    const answer2 = denominator;
    const numerator = answer1 * answer2 + answer3;

    // Number of bars === answer1 + 1
    const bars = new Array(answer1 + 1).fill({ rows: 1, cols: denominator });

    const sentence = `<frac n='${numerator}' d='${denominator}' /> = <frac wAns='' nAns='' dAns='' />`;

    // testCorrect can accept simplified answer
    const [simplifiedAnswer3, simplifiedAnswer2] = simplify(answer3, answer2);

    return (
      <QF20bInteractiveBarModelWithSentence
        title={translate.instructions.useBarModelsToConvertImproperFractionToMixedNumber()}
        testCorrect={{
          sentence: answer =>
            compareFractions(
              [answer[0], answer[1], answer[2]],
              [answer1, simplifiedAnswer3, simplifiedAnswer2]
            )
        }}
        inputMaxCharacters={2}
        sentence={sentence}
        bars={bars}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: {
            sentence: [
              answer1.toLocaleString(),
              simplifiedAnswer3.toLocaleString(),
              simplifiedAnswer2.toLocaleString()
            ]
          },
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apc',
  description: 'apc',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(1).max(4),
    denominator: z.number().int().min(2).max(4),
    number4: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 4);
    const denominator = randomIntegerInclusive(2, 4);
    const number4 = randomIntegerInclusive(1, denominator - 1);

    return { number1, denominator, number4 };
  },
  Component: props => {
    const {
      question: { number1, denominator, number4 },
      translate
    } = props;

    const numerator = number1 * denominator + number4;

    const startingNumber = 0;
    const endNumber = 5;
    const tickInterval = 1 / denominator;
    const sliderStep = 1 / (denominator * 50);

    const fractionToFind = `<frac n='${numerator}' d='${denominator}'/>`;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set each label
    const tickValues = numberArray.map((tick, index) => {
      if (index % denominator === 0) {
        return (index / denominator).toLocaleString();
      } else {
        return tick;
      }
    });

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNumOnNumberLine(fractionToFind)}
        pdfTitle={translate.instructions.drawAnArrowToShowPositionOfNumOnNumberLine(fractionToFind)}
        testCorrect={[
          fractionToDecimal(numerator, denominator) - 1 / (denominator * 10),
          fractionToDecimal(numerator, denominator) + 1 / (denominator * 10)
        ]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderStep}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'apd',
  description: 'apd',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(6),
    multipler1: numberEnum([2, 3, 4, 5]),
    answer2: z.number().int().min(1).max(5),
    answer3: numberEnum([3, 5, 7]),
    answer4: z.number().int().min(1).max(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const multipler1 = getRandomFromArray([2, 3, 4, 5] as const);

    const answer2 = randomIntegerInclusive(1, 5);
    const answer3 = getRandomFromArray([3, 5, 7] as const);
    const answer4 = randomIntegerInclusive(1, 3, {
      constraint: x => x !== answer3
    });

    return { number1, multipler1, answer2, answer3, answer4 };
  },
  Component: props => {
    const {
      question: { answer2, answer3, answer4 },
      translate
    } = props;

    const number3 = answer3;
    const number4 = answer2 * answer3 + answer4;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.convertImproperFractionToMixedNumber()}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1], answer[2]], [answer2, answer4, answer3])
        }
        inputMaxCharacters={2}
        sentence={`<frac n='${number4}' d='${number3}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer2.toLocaleString(), answer4.toString(), answer3.toString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ape',
  description: 'ape',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions', 'Whole numbers'],
  schema: z.object({
    answerOptions: fractionSchema().array().length(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);
    const number2 = number1;

    const number3 = randomIntegerInclusive(2, 12);
    const number4 = number3 * randomIntegerInclusive(2, 5);

    const number5 = randomIntegerInclusive(1, 12);
    const number6 = number5 * randomIntegerInclusive(5, 10);

    const number7 = 2;
    const number8 = randomIntegerInclusive(10, 20);

    const number9 = randomIntegerInclusive(2, 12);
    const number10 = randomIntegerInclusive(1, number9 - 1);

    const number11 = randomIntegerInclusive(2, 12);
    const number12 =
      (number11 + getRandomFromArray([-1, 1] as const)) * randomIntegerInclusive(2, 5);

    const number13 = randomIntegerInclusive(4, 12);
    const number14 =
      (number13 + getRandomFromArray([-3, -2, 2, 3] as const)) * randomIntegerInclusive(2, 10);

    const answerOptions: Fraction[] = [
      [number2, number1],
      [number4, number3],
      [number6, number5],
      [number8, number7],
      [number10, number9],
      [number12, number11],
      [number14, number1]
    ];

    const chosenOpts = getRandomSubArrayFromArray(answerOptions, 6);

    return { answerOptions: chosenOpts };
  },
  Component: props => {
    const {
      question: { answerOptions },
      translate,
      displayMode
    } = props;

    return (
      <QF10SelectNumbers<Fraction>
        title={translate.instructions.selectFractionsThatEqualInteger()}
        testCorrect={answerOptions.filter(frac => Number.isInteger(fractionToDecimal(...frac)))}
        items={answerOptions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: frac
        }))}
        multiSelect
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'apf',
  description: 'apf',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    name: nameSchema,
    drink: z.enum(['juice', 'water']),
    number1: z.number().int().min(4).max(9),
    number2: numberEnum([2, 3, 4])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomName();
    const drink = getRandomFromArray(['juice', 'water'] as const);

    const number2 = getRandomFromArray([2, 3, 4] as const);
    const number1 =
      number2 === 3
        ? getRandomFromArray([4, 5, 7, 8] as const)
        : getRandomFromArray([5, 7, 9] as const);

    return { name, drink, number1, number2 };
  },
  Component: props => {
    const {
      question: { name, drink, number1, number2 },
      translate
    } = props;

    const [answer1, answer2, answer3] = improperFractionToMixedNumber(number1, number2);

    const drinkTranslation = translate.drink[drink]();

    const svgPath = drink === 'juice' ? 'Capacity_images/Small_juice_bottle' : 'Water_bottle';

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterHasXBottlesOfYContainingZ({
          name,
          numBottles: number1,
          drink: drinkTranslation,
          portion: portionToText(number2, translate, 1)
        })}
        testCorrect={userAnswer => compareFractions(userAnswer, [answer1, answer2, answer3])}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.characterHasTotalDrink(
          name,
          `<frac wAns='' nAns='' dAns=''/>`,
          drinkTranslation
        )}
        Content={({ dimens }) => {
          return (
            <View style={dimens}>
              <RowOfImages images={range(1, number1).map(() => svgPath)} style={{ gap: 4 }} />
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1.toLocaleString(),
            answer2.toLocaleString(),
            answer3.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertImproperFractionsToMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
