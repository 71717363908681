import { newBlockContent } from '../../../Block';

import CountMoneyPence from './1CountMoneyPence';
import CountMoneyPoundsNotesAndCoins from './2CountMoneyPoundsNotesAndCoins';
import CountMoneyPoundsAndPence from './3CountMoneyPoundsAndPence';
import ChooseNotesAndCoins from './4ChooseNotesAndCoins';
import MakeTheSameAmount from './5MakeTheSameAmount';
import CompareAmountsOfMoney from './6CompareAmountsOfMoney';
import CalculateWithMoney from './7CalculateWithMoney';
import MakeAPound from './8MakeAPound';
import FindChange from './9FindChange';
import TwoStepProblems from './10TwoStepProblems';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [1, 2],
  smallSteps: [
    CountMoneyPence, // Step 1
    CountMoneyPoundsNotesAndCoins, // Step 2
    CountMoneyPoundsAndPence, // Step 3
    ChooseNotesAndCoins, // Step 4
    MakeTheSameAmount, // Step 5
    CompareAmountsOfMoney, // Step 6
    CalculateWithMoney, // Step 7
    MakeAPound, // Step 8
    FindChange, // Step 9
    TwoStepProblems // Step 10
  ]
});
export default Block;
