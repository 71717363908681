import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { AngleFromMovableLinesWithState, Variants } from '../representations/AngleFromMovableLines';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { angleBetweenTwoLines } from '../../../utils/angles';
import AngleFromLines from '../representations/AngleFromLines';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle: string;
  testCorrect: (angle: number) => boolean;
  markScheme: {
    exampleCorrectAnswer: [number, number];
    notes?: string;
  };
  startAngles?: [number, number];
  /**
   * Whether to allow reflex angles.
   * This has a few effects:
   * - If false, the handles are clamped to the upper half-plane.
   * - If true, the angle formed is always the angle clockwise from the long hand to the short hand, which may be
   *   reflex. This is used for:
   *   - Drawing the arc
   *   - testCorrect
   * Default: false
   */
  allowReflexAngles?: boolean;
  protractor?: boolean;
  /**
   * Whether to fix the small arm in initial position.
   * Default: false
   */
  smallArmFixed?: boolean;
  questionHeight?: number;
  moveableLinesVariant?: Variants;
};

export default function QF41MovableLinesForCreatingAngles({
  title,
  pdfTitle,
  testCorrect,
  markScheme,
  startAngles = [0, 0],
  allowReflexAngles = false,
  protractor,
  smallArmFixed = false,
  questionHeight,
  moveableLinesVariant
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf') {
    return <BaseLayoutPDF title={pdfTitle} questionHeight={questionHeight} />;
  } else if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle}
        containerStyle={{ alignItems: 'center' }}
        mainPanelContents={
          <>
            <AngleFromLines
              degrees={markScheme.exampleCorrectAnswer}
              dimens={{ width: 500, height: 500 }}
            />
            {markScheme.notes && renderMarkSchemeProp(markScheme.notes)}
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContainerStyle={{ alignItems: 'center' }}
      mainPanelContents={
        <AngleFromMovableLinesWithState
          id="angle"
          testCorrect={([a, b]) => testCorrect(angleBetweenTwoLines(a, b, allowReflexAngles))}
          defaultState={startAngles}
          allowReflexAngles={allowReflexAngles}
          protractor={protractor}
          smallArmFixed={smallArmFixed}
          variant={moveableLinesVariant}
        />
      }
    />
  );
}
