export default {
  wellDoneExclamation: 'Well done!',
  wellDone: 'Well done',
  youGotXStars: 'You got {stars} star{{s}}.',
  backToHome: 'Back to home',
  totalPoints: 'Total:',
  results: 'Results',
  youGotAllStars: 'You got all the stars.',
  youCompletedQuiz: 'You have completed the quiz.',

  modals: {
    allAnswerBoxesNeedToBeFilled: 'All the answer boxes need to be filled.',
    checkAllAnswerBoxesFilled: 'Check you have answered all of the boxes and try again.',
    confirmExitQuiz: 'Are you sure you want to exit?',
    endQuiz: 'End the quiz?',
    noAnswer: 'Oops, no answer',
    pleaseEnterAnswer: 'Please enter your answer for this question.',
    selectAnAnswerBox: 'Select an answer box.',
    skipTryNext: 'Try the next question',
    skipSeeResults: "Let's see how you did",
    skipPreview: 'Resetting question',
    tryAgain: 'Try again',
    thenTypeYourAnswer: 'Then type your answer.',
    xAttemptsRemaining: '{attemptsRemaining} attempt{{s}} at this question remaining.'
  },

  errors: {
    errorBoundaryTitle: 'Oh no, something went wrong!',
    errorBoundaryBody: "We'll fix it as soon as possible."
  }
};
