import { type FoodFractionObjectName } from './objects';
import { type SvgName } from '../assets/svg';
import { throwError } from './flowControl';

/**
 * Object contain all the names of SVGs related to food fractions.
 *
 * (`undefined` is used as a placeholder, to indicate that we don't have an image for numerator 0.)
 */
const foodFractionObjectNames: Record<FoodFractionObjectName, (SvgName | undefined)[][]> = {
  Cake: [
    [],
    // Denominator 1
    [undefined, 'Food_fractions/Cake/Cake_Whole/Cake_1_Whole'],
    // Denominator 2
    [
      undefined,
      'Food_fractions/Cake/Cake_Half/Cake_1_half',
      'Food_fractions/Cake/Cake_Half/Cake_2_halves'
    ],
    // Denominator 3
    [
      undefined,
      'Food_fractions/Cake/Cake_Thirds/Cake_1_thirds',
      'Food_fractions/Cake/Cake_Thirds/Cake_2_thirds',
      'Food_fractions/Cake/Cake_Thirds/Cake_3_thirds'
    ],
    // Denominator 4
    [
      undefined,
      'Food_fractions/Cake/Cake_Quarters/Cake_1_quarters',
      'Food_fractions/Cake/Cake_Quarters/Cake_2_quarters',
      'Food_fractions/Cake/Cake_Quarters/Cake_3_quarters',
      'Food_fractions/Cake/Cake_Quarters/Cake_4_quarters'
    ],
    // Denominator 5
    [
      undefined,
      'Food_fractions/Cake/Cake_Fifths/Cake_1_Fifths',
      'Food_fractions/Cake/Cake_Fifths/Cake_2_Fifths',
      'Food_fractions/Cake/Cake_Fifths/Cake_3_Fifths',
      'Food_fractions/Cake/Cake_Fifths/Cake_4_Fifths',
      'Food_fractions/Cake/Cake_Fifths/Cake_5_Fifths'
    ],
    // Denominator 6
    [
      undefined,
      'Food_fractions/Cake/Cake_Sixths/Cake_1_Sixths',
      'Food_fractions/Cake/Cake_Sixths/Cake_2_Sixths',
      'Food_fractions/Cake/Cake_Sixths/Cake_3_Sixths',
      'Food_fractions/Cake/Cake_Sixths/Cake_4_Sixths',
      'Food_fractions/Cake/Cake_Sixths/Cake_5_Sixths',
      'Food_fractions/Cake/Cake_Sixths/Cake_6_Sixths'
    ],
    // Denominator 7
    [
      undefined,
      'Food_fractions/Cake/Cake_Sevenths/Cake_1_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_2_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_3_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_4_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_5_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_6_Sevenths',
      'Food_fractions/Cake/Cake_Sevenths/Cake_7_Sevenths'
    ],
    // Denominator 8
    [
      undefined,
      'Food_fractions/Cake/Cake_Eighths/Cake_1_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_2_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_3_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_4_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_5_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_6_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_7_Eighths',
      'Food_fractions/Cake/Cake_Eighths/Cake_8_Eighths'
    ]
  ],
  Pie: [
    [],
    // Denominator 1
    [undefined, 'Food_fractions/Pie/Pie_Whole/Pie_whole'],
    // Denominator 2
    [
      undefined,
      'Food_fractions/Pie/Pie_Halves/Pie_1_half',
      'Food_fractions/Pie/Pie_Halves/Pie_2_halves'
    ],
    // Denominator 3
    [
      undefined,
      'Food_fractions/Pie/Pie_Thirds/Pie_1_Thirds',
      'Food_fractions/Pie/Pie_Thirds/Pie_2_Thirds',
      'Food_fractions/Pie/Pie_Thirds/Pie_3_Thirds'
    ],
    // Denominator 4
    [
      undefined,
      'Food_fractions/Pie/Pie_Quarters/Pie_1_Quarters',
      'Food_fractions/Pie/Pie_Quarters/Pie_2_Quarters',
      'Food_fractions/Pie/Pie_Quarters/Pie_3_Quarters',
      'Food_fractions/Pie/Pie_Quarters/Pie_4_Quarters'
    ],
    // Denominator 5
    [
      undefined,
      'Food_fractions/Pie/Pie_Fifths/Pie_1_Fifths',
      'Food_fractions/Pie/Pie_Fifths/Pie_2_Fifths',
      'Food_fractions/Pie/Pie_Fifths/Pie_3_Fifths',
      'Food_fractions/Pie/Pie_Fifths/Pie_4_Fifths',
      'Food_fractions/Pie/Pie_Fifths/Pie_5_Fifths'
    ],
    // Denominator 6
    [
      undefined,
      'Food_fractions/Pie/Pie_Sixths/Pie_1_sixths',
      'Food_fractions/Pie/Pie_Sixths/Pie_2_sixths',
      'Food_fractions/Pie/Pie_Sixths/Pie_3_sixths',
      'Food_fractions/Pie/Pie_Sixths/Pie_4_sixths',
      'Food_fractions/Pie/Pie_Sixths/Pie_5_sixths',
      'Food_fractions/Pie/Pie_Sixths/Pie_6_sixths'
    ],
    // Denominator 7
    [
      undefined,
      'Food_fractions/Pie/Pie_Sevenths/Pie_1_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_2_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_3_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_4_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_5_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_6_Sevenths',
      'Food_fractions/Pie/Pie_Sevenths/Pie_7_Sevenths'
    ],
    // Denominator 8
    [
      undefined,
      'Food_fractions/Pie/Pie_Eighths/Pie_1_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_2_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_3_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_4_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_5_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_6_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_7_Eighths',
      'Food_fractions/Pie/Pie_Eighths/Pie_8_Eighths'
    ]
  ],
  Pizza: [
    [],
    // Denominator 1
    [undefined, 'Food_fractions/Pizza/Pizza_Whole/Pizza'],
    // Denominator 2
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Halves/Pizza_1_halves',
      'Food_fractions/Pizza/Pizza_Halves/Pizza_2_halves'
    ],
    // Denominator 3
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Thirds/Pizza_1_Thirds',
      'Food_fractions/Pizza/Pizza_Thirds/Pizza_2_Thirds',
      'Food_fractions/Pizza/Pizza_Thirds/Pizza_3_Thirds'
    ],
    // Denominator 4
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Quarters/Pizza_1_Quarters',
      'Food_fractions/Pizza/Pizza_Quarters/Pizza_2_Quarters',
      'Food_fractions/Pizza/Pizza_Quarters/Pizza_3_Quarters',
      'Food_fractions/Pizza/Pizza_Quarters/Pizza_4_Quarters'
    ],
    // Denominator 5
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Fifths/Pizza_1_fifths',
      'Food_fractions/Pizza/Pizza_Fifths/Pizza_2_fifths',
      'Food_fractions/Pizza/Pizza_Fifths/Pizza_3_fifths',
      'Food_fractions/Pizza/Pizza_Fifths/Pizza_4_fifths',
      'Food_fractions/Pizza/Pizza_Fifths/Pizza_5_fifths'
    ],
    // Denominator 6
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Sixths/pizza_1_sixths',
      'Food_fractions/Pizza/Pizza_Sixths/pizza_2_sixths',
      'Food_fractions/Pizza/Pizza_Sixths/pizza_3_sixths',
      'Food_fractions/Pizza/Pizza_Sixths/pizza_4_sixths',
      'Food_fractions/Pizza/Pizza_Sixths/pizza_5_sixths',
      'Food_fractions/Pizza/Pizza_Sixths/pizza_6_sixths'
    ],
    // Denominator 7
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_1_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_2_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_3_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_4_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_5_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_6_Sevenths',
      'Food_fractions/Pizza/Pizza_Sevenths/Pizza_7_Sevenths'
    ],
    // Denominator 8
    [
      undefined,
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_1_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_2_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_3_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_4_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_5_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_6_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_7_Eighths',
      'Food_fractions/Pizza/Pizza_Eighths/Pizza_8_Eighths'
    ]
  ]
};

/**
 * Get food fraction object SVG name.
 * Requires a denominator from 1 to 8, and a numerator less than or equal to the numerator.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getFoodFractionObjectSvgName = (
  foodFractionObject: FoodFractionObjectName,
  numerator: number,
  denominator: number
): SvgName => {
  if (denominator > 8 || denominator < 1) {
    throw new Error(`${foodFractionObject} image with denominator ${denominator} does not exist.`);
  }

  if (denominator < numerator) {
    throw new Error(
      `Cannot provide an image where denominator is less than numerator. Please use separate images to represent this.`
    );
  }

  return (
    foodFractionObjectNames[foodFractionObject][denominator][numerator] ??
    throwError(
      `Couldn't find foodFractionObject image: ${foodFractionObject}, ${numerator}, ${denominator}`
    )
  );
};
