import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { filledArray, range, rangeAsString } from 'common/src/utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from 'common/src/utils/random';
import { base10ObjectToNumber, numberToBase10Object } from 'common/src/utils/math';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aco',
  description: 'aco',
  keywords: ['Number line', 'Place value', '10,000', 'Estimate'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(50)
      .max(9950)
      .refine(number => number % 100 !== 0, { message: 'Number must be odd multiple of 50' }),
    startingNumber: z.number().int().min(0).max(9000).multipleOf(1000),
    endNumber: z.number().int().min(2000).max(10000).multipleOf(1000)
  }),
  simpleGenerator: () => {
    const answerInterval = 50;
    const startingNumber = randomIntegerInclusive(1, 9) * 1000;
    const endNumber = startingNumber + 1000;

    // Make this miss the first and last number
    // Number must be odd multiple of 50
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval * 2
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 100;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateNumberArrowIsPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'acp',
  description: 'acp',
  keywords: ['Number line', 'Place value', '10,000', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(0).max(10000),
      startingNumber: z.number().int().multipleOf(1000).min(0).max(5000),
      endNumber: z.number().int().multipleOf(1000).min(5000).max(10000)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 5) * 1000;
    const endNumber = startingNumber + 5000;
    const answerInterval = 500;

    // The answer is an odd multiple of 50
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval * 2
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1000;
    const sliderInterval = 1;

    const numberArray = rangeAsString(startingNumber, endNumber, tickInterval, true);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={[number - 100, number + 100]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'acq',
  description: 'acq',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand', 'Estimate'],
  schema: z.object({
    number1: z.number().int().min(1000).max(9000).multipleOf(1000),
    number2: z.number().int().min(1000).max(9000).multipleOf(1000),
    number3: z.number().int().min(999).max(8999)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(1000, 9000, 1000, {
      // We don't want the number to be exactly on the middle of the number line
      constraint: x => x !== 5000
    });
    const number2 = randomIntegerInclusiveStep(1000, 9000, 1000, {
      constraint: x => x !== 5000 && x !== number1
    });
    const number3 = randomIntegerInclusiveStep(1000, 9000, 1000) - 1;
    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const startingNumber = 0;
    const endNumber = 10000;
    const tickInterval = 5000;
    const labelInterval = 10000;
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    const left = translate.directions.left();
    const right = translate.directions.right();

    return (
      <QF36ContentAndSentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragWordsToCompleteSentences()}
        pdfTitle={translate.instructions.useWordsToCompleteSentences()}
        items={[left, right]}
        itemVariant="rectangle"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentences={[
          `${translate.answerSentences.numIsToThe(
            number1.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`,
          `${translate.answerSentences.numIsToThe(
            number2.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`,
          `${translate.answerSentences.numIsToThe(
            number3.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`
        ]}
        testCorrect={[
          [number1 < 5000 ? left : right],
          [number2 < 5000 ? left : right],
          [number3 < 5000 ? left : right]
        ]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'acr',
  description: 'acr',
  keywords: ['Number line', 'Place value', '10,000', 'Estimate'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(100)
      .max(9900)
      .refine(number => number % 100 === 0, { message: 'Number must be multiple of 100' })
  }),
  simpleGenerator: () => {
    const hundreds = getRandomFromArray([100, 200, 300, 400, 600, 700, 800, 900] as const);
    const thousands = randomIntegerInclusive(0, 9) * 1000;
    const number = hundreds + thousands;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 1000;
    const startingNumber = 0;
    const endNumber = 10000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    // Convenience base 10 objects for answer comparisons
    // $var is a number ending between 100 to 400 or 600 to 900.
    // Allow answers within range of 50 to 450 for hundreds of 1 to 4 and 550 to 950 for 6 to 9
    const numberB10 = numberToBase10Object(number);
    const b10RepLower = numberToBase10Object(number);
    b10RepLower.hundreds = numberB10.hundreds! < 5 ? 0 : 5;
    b10RepLower.tens = 5;
    const lowerAns = base10ObjectToNumber(b10RepLower);

    const b10RepUpper = numberToBase10Object(number);
    b10RepUpper.hundreds = numberB10.hundreds! < 5 ? 4 : 9;
    b10RepUpper.tens = 5;
    const upperAns = base10ObjectToNumber(b10RepUpper);

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateNumberArrowIsPointingTo()}
        testCorrect={answer => {
          const parsedAns = parseInt(answer[0]);
          return parsedAns <= upperAns && parsedAns >= lowerAns;
        }}
        inputMaxCharacters={5}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answersToDisplay: [number.toLocaleString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acs',
  description: 'acs',
  keywords: ['Number line', 'Place value', '10,000', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(1000).max(10000),
      startingNumber: z.number().int().multipleOf(1000).min(0).max(9000),
      endNumber: z.number().int().multipleOf(1000).min(1000).max(10000)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 9) * 1000;
    const endNumber = startingNumber + 1000;
    const answerInterval = 10;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = rejectionSample(
      () => getRandomFromArray(choices),
      x => {
        // Reject any number that has 0 or 5 tens
        const choiceB10 = numberToBase10Object(x);
        return choiceB10.tens !== 5 && choiceB10.tens !== 0;
      }
    );

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 100;
    const sliderInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    // Convenience base 10 objects for answer comparisons
    // $var is a number ending between 1 to 4 or 6 to 9.
    // Allow answers within range of 0.5 to 4.5 for ones of 1 to 4 and 5.5 to 9.5 for 6 to 9
    // These 2 upper and lower values mean we can compare +- 2 of this number.
    const numberB10 = numberToBase10Object(number);
    const b10RepLower = numberToBase10Object(number);
    b10RepLower.tens = 2;
    b10RepLower.ones = 5;
    const lowerAns = base10ObjectToNumber(b10RepLower);

    const b10RepUpper = numberToBase10Object(number);
    b10RepUpper.tens = 7;
    b10RepUpper.ones = 5;
    const upperAns = base10ObjectToNumber(b10RepUpper);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={
          numberB10.tens! < 5 ? [lowerAns - 20, lowerAns + 20] : [upperAns - 20, upperAns + 20]
        }
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'act',
  description: 'act',
  keywords: ['Number line', 'Place value', '10,000', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(0).max(9000),
      startingNumber: z.number().int().multipleOf(1000).min(0).max(7000),
      endNumber: z.number().int().multipleOf(1000).min(2000).max(9000)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 7) * 1000;
    const endNumber = startingNumber + 2000;
    const answerInterval = 1;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = rejectionSample(
      () => getRandomFromArray(choices),
      x => {
        // Reject any number that is not between start+20 and start+80 or end-20 and end-80
        if (x < startingNumber + 1000) {
          return x >= startingNumber + 200 && x <= startingNumber + 800;
        } else {
          return x <= endNumber - 200 && x >= endNumber - 800;
        }
      }
    );

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1000;
    const sliderInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={[number - 150, number + 150]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateOnANumberLineTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
