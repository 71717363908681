import { ADD, DIV, MULT, SUB } from '../constants';

// Symbols for adding whitepsace around
export const SYMBOLS = [ADD, DIV, MULT, SUB, '=', '≈'];
export const UINCODE_SYMBOL_MAPPING: { [key: string]: string } = {
  '+': '+',
  '\u00F7': '/',
  '\u00D7': '*',
  '\u2212': '-'
};

/**
 * Replaces the - (HYPHEN-MINUS) sign  with the SUB constant used
 *
 * @param text string to convert the HYPHEN-MINUS sign to SUB constant
 * @returns string
 */
export const parseToSUB = (text: string): string => text.replace('-', SUB);

/**
 * Replaces the mathematical symbols that we use to display with the operators that we require for evaluating
 * the mathematical sentence.
 */
export const parseSymbolsToString = (text: string): string => {
  const regexMatch = new RegExp(
    Object.keys(UINCODE_SYMBOL_MAPPING)
      .map(symbol => `\\${symbol}`)
      .join('|'),
    'g'
  );

  return text.replace(regexMatch, match => UINCODE_SYMBOL_MAPPING[match]);
};

/**
 * Add whitespace padding around operators. DEFAULT: All symbols
 * @param {string} sentence - sentence to add whitespace padding
 * @param {number} numberOfSpaces - number of whitespaces around symbols
 * @param {Array} symbols - array of symbols to add whitespace around (defaults to '+', '÷', 'x', '-', '=', '≈')
 * @returns {string}
 */
export const addWhitespacePadding = (
  sentence: string,
  numberOfSpaces: number,
  symbols = SYMBOLS
): string => {
  symbols.forEach(symbol => {
    if (sentence.includes(symbol)) {
      const paddedSymbol = ' '.repeat(numberOfSpaces) + `${symbol}` + ' '.repeat(numberOfSpaces);
      sentence = sentence.replace(new RegExp(` \\${symbol} `, 'g'), paddedSymbol);
    }
  });

  return sentence;
};
