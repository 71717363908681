import { newBlockContent } from '../../../Block';

import HeavierAndLighter from './1HeavierAndLighter';
import MeasureMass from './2MeasureMass';
import CompareMass from './3CompareMass';
import FullAndEmpty from './4FullAndEmpty';
import CompareVolume from './5CompareVolume';
import MeasureCapacity from './6MeasureCapacity';
import CompareCapacity from './7CompareCapacity';

const Block = newBlockContent({
  block: 'MassAndVolume',
  field: 'Measurement',
  weeks: [11, 12],
  smallSteps: [
    HeavierAndLighter, // Step 1
    MeasureMass, // Step 2
    CompareMass, // Step 3
    FullAndEmpty, // Step 4
    CompareVolume, // Step 5
    MeasureCapacity, // Step 6
    CompareCapacity // Step 7
  ]
});
export default Block;
