import { View } from 'react-native';
import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { barModelColors } from '../../../../theme/colors';
import { arraysHaveSameContents, countRange, filledArray } from '../../../../utils/collections';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { ADD, SUB } from '../../../../constants';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import Text from 'common/src/components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import ContentBox from '../../../../components/molecules/ContentBox';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNm',
  description: 'aNm',
  keywords: [
    'Subtraction',
    'Fraction',
    'Whole',
    'Integer',
    'Difference',
    'Reduction',
    'Numerator',
    'Denominator',
    'Convert',
    'Mixed number'
  ],
  schema: z
    .object({
      whole: z.number().int().min(2).max(5),
      denominator: z.number().int().min(2).max(8),
      numerator: z.number().int().min(1).max(7),
      isMixed: z.boolean(),
      addOrSub: z.enum([ADD, SUB]),
      incorrectAnswerIndexes: z.array(z.number().int().min(0).max(5)).length(3)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(2, 5);
    const denominator = randomIntegerInclusive(2, 8);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const isMixed = getRandomBoolean();
    const addOrSub = getRandomFromArray([ADD, SUB] as const);
    const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 3);

    return { denominator, whole, numerator, isMixed, incorrectAnswerIndexes, addOrSub };
  },
  Component: props => {
    const {
      question: { denominator, whole, numerator, isMixed, addOrSub, incorrectAnswerIndexes },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArrays = countRange(whole).map(() => filledArray(numeratorColor, denominator));

    const denominator1 = addOrSub === ADD ? denominator + 1 : denominator - 1;
    const whole2 = addOrSub === ADD ? whole + 1 : whole - 1;
    const numerator3 = addOrSub === ADD ? denominator + 1 : denominator - 1;

    const incorrrectAnswerOptions = [
      [
        whole.toLocaleString(),
        numerator.toLocaleString(),
        (whole * denominator1 - numerator).toLocaleString(),
        denominator1.toLocaleString(),
        0
      ],
      [
        whole2.toLocaleString(),
        numerator.toLocaleString(),
        (whole2 * denominator - numerator).toLocaleString(),
        denominator.toLocaleString(),
        1
      ],
      [
        whole.toLocaleString(),
        numerator3.toLocaleString(),
        (whole * denominator - numerator3).toLocaleString(),
        denominator.toLocaleString(),
        2
      ],
      [
        whole.toLocaleString(),
        numerator.toLocaleString(),
        (whole * denominator - numerator + denominator).toLocaleString(),
        denominator.toLocaleString(),
        3
      ],
      [
        (whole + 1).toLocaleString(),
        numerator.toLocaleString(),
        (whole * denominator - numerator + denominator).toLocaleString(),
        denominator.toLocaleString(),
        4
      ],
      [
        whole.toLocaleString(),
        numerator.toLocaleString(),
        (whole * denominator - numerator + denominator).toLocaleString(),
        denominator.toLocaleString(),
        5
      ]
    ].filter((_x, i) => incorrectAnswerIndexes.includes(i));

    const ansWhole = Math.floor((whole * denominator - numerator) / denominator);
    const remainder = (whole * denominator - numerator) % denominator;
    const correctSentence =
      isMixed && ansWhole > 0
        ? `<frac w='${ansWhole.toLocaleString()}' n='${remainder.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        : `<frac n='${(
            whole * denominator -
            numerator
          ).toLocaleString()}' d='${denominator.toLocaleString()}' />`;

    const answerOptions = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`${whole.toLocaleString()} ${SUB} <frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> = ${correctSentence}`}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'A'
        },
        ...incorrrectAnswerOptions.map((x, i) => {
          return x[4] === 5
            ? {
                component: (
                  <TextStructure
                    sentence={`<frac n='${x[1]}' d='${x[3]}' /> ${SUB} ${x[0]} = <frac n='${x[2]}' d='${x[3]}' />`}
                    textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                    fractionTextStyle={{
                      fontSize: displayMode === 'digital' ? 30 : 50,
                      fontWeight: '700'
                    }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                  />
                ),
                value: `${i}`
              }
            : {
                component: (
                  <TextStructure
                    sentence={`${x[0]} ${SUB} <frac n='${x[1]}' d='${x[3]}' /> = <frac n='${x[2]}' d='${x[3]}' />`}
                    textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                    fractionTextStyle={{
                      fontSize: displayMode === 'digital' ? 30 : 50,
                      fontWeight: '700'
                    }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                  />
                ),
                value: `${i}`
              };
        })
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToMatchCalcToRep()}
        pdfTitle={translate.instructions.useCardToMatchCalcToRep()}
        items={answerOptions}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
        actionPanelVariant="endWide"
        Content={({ dimens }) => (
          <View style={{ gap: displayMode === 'digital' ? 8 : 32 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                strikeThroughIndexes={
                  idx === barModelArrays.length - 1
                    ? countRange(numerator, denominator - numerator)
                    : undefined
                }
              />
            ))}
          </View>
        )}
        sentence={`<ans/>`}
        testCorrect={['A']}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aNn',
  description: 'aNn',
  keywords: ['Subtraction', 'Reduction', 'Parts', 'Whole', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    whole: z.number().int().min(2).max(5),
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(4).max(15),
    isMixed: z.boolean()
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(2, 5);
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(denominator + 1, denominator * 2 - 1);
    const isMixed = getRandomBoolean();

    return { denominator, whole, numerator, isMixed };
  },
  Component: props => {
    const {
      question: { denominator, whole, numerator, isMixed },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArrays = countRange(whole).map(() => filledArray(numeratorColor, denominator));

    const ansNumerator = whole * denominator - numerator;

    const mixed = isMixed && ansNumerator > denominator;
    const answerSentence = mixed ? `<frac wAns='' nAns='' dAns=''/>` : `<frac nAns='' dAns=''/>`;

    const markSchemeAnswer =
      mixed && Math.floor(ansNumerator / denominator) > 0
        ? [
            Math.floor(ansNumerator / denominator).toLocaleString(),
            (ansNumerator % denominator).toLocaleString(),
            denominator.toLocaleString()
          ]
        : [ansNumerator.toLocaleString(), denominator.toLocaleString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSubtractionUseTheBarModelToHelp()}
        Content={({ dimens }) => (
          <View style={{ gap: 5 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                strikeThroughIndexes={
                  idx === barModelArrays.length - 1 && denominator < numerator
                    ? countRange(denominator)
                    : idx === barModelArrays.length - 2 && denominator < numerator
                    ? countRange(numerator - denominator, 2 * denominator - numerator)
                    : idx === barModelArrays.length - 1
                    ? countRange(numerator, denominator - numerator)
                    : undefined
                }
              />
            ))}
          </View>
        )}
        textStyle={{ fontSize: 40 }}
        sentence={`${whole.toLocaleString()} ${SUB} <frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> = ${answerSentence}`}
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={2}
        testCorrect={answer =>
          mixed && Math.floor(ansNumerator / denominator) > 0
            ? compareFractions(
                [answer[0], answer[1], answer[2]],
                [Math.floor(ansNumerator / denominator), ansNumerator % denominator, denominator]
              )
            : compareFractions([answer[0], answer[1]], [ansNumerator, denominator])
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNo',
  description: 'aNo',
  keywords: [
    'Subtraction',
    'Fraction',
    'Whole',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Convert',
    'Mixed number'
  ],
  questionHeight: 850,
  schema: z
    .object({
      denominator: z.number().int().min(3).max(12),
      numerator1: z.number().int().min(1).max(11),
      numerator2: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.numerator1 < val.denominator && val.numerator2 < val.denominator,
      'The numerators must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);
    const [numerator1, numerator2] = randomUniqueIntegersInclusive(1, denominator - 1, 2);

    return { denominator, numerator1, numerator2 };
  },

  Component: props => {
    const {
      question: { denominator, numerator1, numerator2 },
      translate
    } = props;

    // Sentences
    const sentences = [
      `<frac n='${denominator.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${SUB} <frac n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac nAns='' dAns='' />`,
      `${(1).toLocaleString()} ${SUB} <frac n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac nAns='' dAns='' />`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        questionHeight={850}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [denominator - numerator1, denominator]) &&
          compareFractions(userAnswer[1], [denominator - numerator2, denominator])
        }
        sentences={sentences}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(denominator - numerator1).toLocaleString(), denominator.toLocaleString()],
            [(denominator - numerator2).toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNp',
  description: 'aNp',
  keywords: [
    'Subtraction',
    'Fraction',
    'Whole',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Convert',
    'Mixed number',
    'Improper fraction'
  ],
  schema: z
    .object({
      whole1: z.number().int().min(1).max(5),
      whole2: z.number().int().min(1).max(5),
      denominator: z.number().int().min(3).max(12),
      numerator: z.number().int().min(1).max(11),
      addOrSub: z.array(z.enum([ADD, SUB])).length(6),
      isMixed: z.array(z.boolean()).length(6)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const [whole1, whole2] = randomUniqueIntegersInclusive(1, 5, 2);
    const denominator = randomIntegerInclusive(3, 12);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const addOrSub = countRange(6).map(() => getRandomFromArray([ADD, SUB] as const));
    const isMixed = countRange(6).map(getRandomBoolean);

    return { whole1, whole2, denominator, numerator, addOrSub, isMixed };
  },
  Component: props => {
    const {
      question: { whole1, whole2, denominator, numerator, addOrSub, isMixed },
      translate,
      displayMode
    } = props;

    const numerator1 =
      addOrSub[0] === ADD ? whole1 * denominator + numerator : whole1 * denominator - numerator;
    const numerator2 =
      addOrSub[1] === ADD ? whole2 * denominator + numerator : whole2 * denominator - numerator;
    // prevent any numerators being 0
    const numerator3 =
      addOrSub[2] === ADD || whole1 * denominator + numerator - 1 === 0
        ? whole1 * denominator + numerator + 1
        : whole1 * denominator + numerator - 1;
    const numerator4 =
      addOrSub[3] === ADD || whole1 * denominator - numerator - 1 === 0
        ? whole1 * denominator - numerator + 1
        : whole1 * denominator - numerator - 1;
    const numerator5 =
      addOrSub[4] === ADD || whole2 * denominator + numerator - 1 === 0
        ? whole2 * denominator + numerator + 1
        : whole2 * denominator + numerator - 1;
    const numerator6 =
      addOrSub[5] === ADD || whole2 * denominator - numerator - 1 === 0
        ? whole2 * denominator - numerator + 1
        : whole2 * denominator - numerator - 1;

    const numerators = [numerator1, numerator2, numerator3, numerator4, numerator5, numerator6];
    const answerOptions = shuffle(
      numerators.map((num, idx) => {
        const whole = Math.floor(num / denominator);
        const sentence =
          isMixed[idx] && whole > 0 && num % denominator > 0
            ? `<frac w='${whole.toLocaleString()}' n='${(
                num % denominator
              ).toLocaleString()}' d='${denominator.toLocaleString()}' />`
            : `<frac n='${num.toLocaleString()}' d='${denominator.toLocaleString()}' />`;
        return {
          component: (
            <TextStructure
              key={idx}
              sentence={sentence}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: fractionToDecimal(num, denominator)
        };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchNumbersWithDifferenceOf(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        pdfTitle={translate.instructions.useCardsMatchNumbersWithDifferenceOf(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        sentences={[`${whole1.toLocaleString()}   <ans/>`, `${whole2.toLocaleString()}   <ans/>`]}
        testCorrect={[
          [fractionToDecimal(numerator1, denominator)],
          [fractionToDecimal(numerator2, denominator)]
        ]}
        items={answerOptions}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4v2 = newQuestionContent({
  uid: 'aNp2',
  description: 'aNp',
  keywords: [
    'Subtraction',
    'Fraction',
    'Whole',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Convert',
    'Mixed number',
    'Improper fraction'
  ],
  schema: z
    .object({
      whole1: z.number().int().min(1).max(5),
      denominator: z.number().int().min(3).max(12),
      numerator: z.number().int().min(1).max(11),
      addOrSub: z.array(z.enum([ADD, SUB])).length(3),
      isMixed: z.array(z.boolean()).length(3)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const whole1 = randomIntegerInclusive(1, 5);
    const denominator = randomIntegerInclusive(3, 12);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const addOrSub = countRange(3).map(() => getRandomFromArray([ADD, SUB] as const));
    const isMixed = countRange(3).map(getRandomBoolean);

    return { whole1, denominator, numerator, addOrSub, isMixed };
  },
  Component: props => {
    const {
      question: { whole1, denominator, numerator, addOrSub, isMixed },
      translate,
      displayMode
    } = props;

    const numerator1 =
      addOrSub[0] === ADD ? whole1 * denominator + numerator : whole1 * denominator - numerator;
    // prevent any numerators being 0
    const numerator2 =
      addOrSub[1] === ADD || whole1 * denominator + numerator - 1 === 0
        ? whole1 * denominator + numerator + 1
        : whole1 * denominator + numerator - 1;
    const numerator3 =
      addOrSub[2] === ADD || whole1 * denominator - numerator - 1 === 0
        ? whole1 * denominator - numerator + 1
        : whole1 * denominator - numerator - 1;

    const numerators = [numerator1, numerator2, numerator3];
    const answerOptions = shuffle(
      numerators.map((num, idx) => {
        const whole = Math.floor(num / denominator);
        const sentence =
          isMixed[idx] && whole > 0 && num % denominator > 0
            ? `<frac w='${whole.toLocaleString()}' n='${(
                num % denominator
              ).toLocaleString()}' d='${denominator.toLocaleString()}' />`
            : `<frac n='${num.toLocaleString()}' d='${denominator.toLocaleString()}' />`;
        return {
          component: (
            <TextStructure
              key={idx}
              sentence={sentence}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: fractionToDecimal(num, denominator)
        };
      }),
      { random: seededRandom(props.question) }
    );

    const items = [
      {
        lhsComponent: (
          <View>
            <ContentBox
              children={<Text variant="WRN700">{whole1.toLocaleString()}</Text>}
              containerStyle={
                displayMode === 'digital'
                  ? {
                      paddingTop: 16,
                      paddingBottom: 16,
                      paddingLeft: 32,
                      paddingRight: 32
                    }
                  : {
                      paddingTop: 36,
                      paddingBottom: 36,
                      paddingLeft: 56,
                      paddingRight: 56
                    }
              }
            />
          </View>
        ),
        correctAnswer: fractionToDecimal(numerator1, denominator)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardToMakeAPairOfNumbersWithADifferenceOfX(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        pdfTitle={translate.instructions.useTheCardToMakeAPairOfNumbersWithADifferenceOfX(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        moveOrCopy="move"
        actionPanelVariant="bottom"
        statementStyle={{ justifyContent: 'center' }}
        items={answerOptions}
        itemVariant="square"
        statements={items}
        questionHeight={800}
        useArrows={false}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aNq',
  description: 'aNq',
  keywords: [
    'Subtraction',
    'Fraction',
    'Whole',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Convert',
    'Mixed number',
    'Improper fraction'
  ],
  schema: z
    .object({
      ribbonLength: z.number().int().min(1).max(10),
      numerator: z.number().int().min(1).max(11),
      denominator: z.number().int().min(3).max(12),
      names: z.array(nameSchema).length(2),
      isMixed: z.boolean()
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const ribbonLength = randomIntegerInclusive(1, 10);
    const denominator = randomIntegerInclusive(3, 12);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const names = getRandomUniqueNames(2);
    const isMixed = getRandomBoolean();

    return { ribbonLength, denominator, numerator, names, isMixed };
  },
  Component: props => {
    const {
      question: { ribbonLength, denominator, numerator, names, isMixed },
      translate
    } = props;

    const answerNumerator = ribbonLength * denominator - numerator;
    const mixed = isMixed && answerNumerator > denominator;

    const markSchemeAnswer = mixed
      ? [
          Math.floor(answerNumerator / denominator).toLocaleString(),
          (answerNumerator % denominator).toLocaleString(),
          denominator.toLocaleString()
        ]
      : [answerNumerator.toLocaleString(), denominator.toLocaleString()];

    return (
      <QF2AnswerBoxOneSentence
        sentence={
          mixed
            ? `<frac wAns='' nAns='' dAns='' /> ${translate.units.m()}`
            : `<frac nAns='' dAns='' /> ${translate.units.m()}`
        }
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={
          mixed
            ? translate.instructions.characterHasXRibbonCutsOffYHowMuchLeftMixedNumber(
                names[0],
                ribbonLength.toLocaleString(),
                `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
                names[1]
              )
            : translate.instructions.characterHasXRibbonCutsOffYHowMuchLeftImproperFraction(
                names[0],
                ribbonLength.toLocaleString(),
                `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
                names[1]
              )
        }
        inputMaxCharacters={2}
        testCorrect={answer =>
          mixed
            ? compareFractions(
                [answer[0], answer[1], answer[2]],
                [
                  Math.floor(answerNumerator / denominator),
                  answerNumerator % denominator,
                  denominator
                ]
              )
            : compareFractions([answer[0], answer[1]], [answerNumerator, denominator])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aNr',
  description: 'aNr',
  keywords: ['Subtraction', 'Difference', 'Fraction', 'Numerator', 'Denominator', 'Mixed number'],
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(12),
      numerator1: z.number().int().min(1).max(11),
      whole1: z.number().int().min(2).max(10),
      denominator2: z.number().int().min(3).max(12),
      numerator2: z.number().int().min(1).max(11),
      whole2: z.number().int().min(2).max(10)
    })
    .refine(val => val.denominator1 > val.numerator1, 'numerator1 should be less than denominator1')
    .refine(
      val => val.denominator2 > val.numerator2,
      'numerator2 should be less than denominator2'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [denominator1, denominator2] = randomUniqueIntegersInclusive(3, 12, 2);
    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const whole1 = randomIntegerInclusive(2, 10);
    const numerator2 = randomIntegerInclusive(1, denominator2 - 1);
    const whole2 = randomIntegerInclusive(2, 10);
    return {
      denominator1,
      denominator2,
      numerator1,
      numerator2,
      whole1,
      whole2
    };
  },
  Component: props => {
    const {
      question: { denominator1, denominator2, numerator1, numerator2, whole1, whole2 },
      translate
    } = props;
    const eqA = `${whole1.toLocaleString()} ${SUB} <frac nAns='' dAns='' /> = <frac w='${(
      whole1 - 1
    ).toLocaleString()}' n='${(
      denominator1 - numerator1
    ).toLocaleString()}' d='${denominator1.toLocaleString()}'/>`;

    const eqB = `<ans/> ${SUB} <frac n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' /> = <frac w='${(
      whole2 - 1
    ).toLocaleString()}' n='${(
      denominator2 - numerator2
    ).toLocaleString()}' d='${denominator2.toLocaleString()}'/>`;

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        questionHeight={1000}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numerator1, denominator1]) &&
          arraysHaveSameContents(userAnswer[1], [whole2.toString()])
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator1.toLocaleString(), denominator1.toLocaleString()],
            [whole2.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentences={eqs}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFromWholeAmounts',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
