import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { useMemo } from 'react';
import { SUB } from 'common/src/constants';
import { arraysHaveSameContents } from '../../../../utils/collections';
import { NumberLineVariableTickWithState } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberOfZeroDigits } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aiu',
  description: 'aiu',
  keywords: ['Number line', 'Efficient', 'Count on', 'Subtraction'],
  schema: z.object({
    number4: z.number().int().min(100).max(900).multipleOf(100),
    number5: z.number().int().min(1).max(10),
    number6: z.number().int().min(1).max(50)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number4 = randomIntegerInclusiveStep(100, 900, 100);

    const number6 = randomIntegerInclusive(1, 50);

    const number5 = randomIntegerInclusive(1, 10, {
      // Must ensure that this jump is never smaller than one tenth of the other jump.
      constraint: x => x >= number6 / 10
    });

    return { number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number4, number5, number6 },
      translate,
      displayMode
    } = props;

    const number3 = number4 + number6;
    const number1 = number4 - number5;
    const number2 = number3 - number1;

    const ans1 = number6;
    const ans2 = number5;
    const ans3 = number2;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray = [
      { label: startingNumber.toLocaleString(), position: startingNumber },
      { label: number4.toLocaleString(), position: number4 },
      { label: endNumber.toLocaleString(), position: endNumber }
    ];

    const jumpArrowArray = [
      { start: endNumber, end: number4, label: `${SUB}<ans/>` },
      { start: number4, end: startingNumber, label: `${SUB}<ans/>` }
    ];

    const sentence = `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()} = <ans/>`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToWorkOut(number3, number1)}
        testCorrect={[ans3.toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <NumberLineVariableTickWithState
            id="numberline"
            dimens={dimens}
            tickValues={tickArray}
            start={startingNumber}
            end={endNumber}
            jumpArrowArray={jumpArrowArray}
            subtraction
            ignoreMinSpacing
            // Need a slightly smaller font size on digital to prevent number under ticks being too close together:
            customFontSize={displayMode !== 'digital' ? undefined : 32}
            testCorrect={answer =>
              arraysHaveSameContents(answer, [ans1.toString(), ans2.toString()])
            }
            defaultState={
              displayMode === 'markscheme'
                ? [ans1.toLocaleString(), ans2.toLocaleString()]
                : undefined
            }
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aiv',
  description: 'aiv',
  keywords: ['Subtraction', 'Efficient', 'Count on'],
  schema: z.object({
    numberA4: z.number().int().min(100).max(900).multipleOf(100),
    numberA3FromA4: z.union([z.literal(1), z.literal(5)]),
    numberA1FromA4: z.union([z.literal(1), z.literal(5)]),
    numberB4: z.number().int().min(1000).max(9000).multipleOf(1000),
    numberB3FromB4: z.union([z.literal(1), z.literal(9)]),
    numberC4: z.number().int().min(100).max(9900).multipleOf(100),
    numberC3FromC4: z.union([z.literal(1), z.literal(10)]),
    numberC1FromC4: z.union([z.literal(2), z.literal(5)])
  }),
  simpleGenerator: () => {
    const numberA4 = randomIntegerInclusiveStep(100, 900, 100);
    const numberA3FromA4 = getRandomFromArray([1, 5] as const);
    const numberA1FromA4 = getRandomFromArray([1, 5] as const);

    const numberB4 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const numberB3FromB4 = getRandomFromArray([1, 9] as const);

    const numberC4 = randomIntegerInclusiveStep(100, 900, 100);
    const numberC3FromC4 = getRandomFromArray([1, 10] as const);
    const numberC1FromC4 = getRandomFromArray([2, 5] as const);

    return {
      numberA4,
      numberA3FromA4,
      numberA1FromA4,
      numberB4,
      numberB3FromB4,
      numberC4,
      numberC3FromC4,
      numberC1FromC4
    };
  },

  Component: props => {
    const {
      question: {
        numberA4,
        numberA3FromA4,
        numberA1FromA4,
        numberB4,
        numberB3FromB4,
        numberC4,
        numberC3FromC4,
        numberC1FromC4
      },
      translate
    } = props;

    const numberA1 = numberA4 - numberA1FromA4;
    const numberA3 = numberA4 + numberA3FromA4;

    const numberB1 = numberB4 - 1;
    const numberB3 = numberB4 + numberB3FromB4;

    const numberC1 = numberC4 - numberC1FromC4;
    const numberC3 = numberC4 + numberC3FromC4;

    const eqA = getBinOpEquation({
      left: numberA3,
      right: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB3,
      right: numberB1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: numberC3,
      right: numberC1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aiw',
  description: 'aiw',
  keywords: ['Subtraction', 'Efficient', 'Constant difference'],
  schema: z
    .object({
      minuend: z.number().int().min(1000).max(9000).multipleOf(1000),
      subtrahend: z
        .number()
        .int()
        .min(95)
        .max(799)
        .refine(val => numberOfZeroDigits(val) === 0, 'Subtrahend must not contain any zeroes.')
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be more than subtrahend.'),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusiveStep(1000, 9000, 1000);

    const subtrahend = randomIntegerInclusive(95, 799, {
      // Must not contain any zeroes:
      constraint: x => numberOfZeroDigits(x) === 0
    });

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const minuendB = minuend - 1;

    const subtrahendB = subtrahend - 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[subtrahendB.toString()]}
        sentence={`${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = ${minuendB.toLocaleString()} ${SUB} <ans/>`}
        questionHeight={400}
      />
    );
  },
  questionHeight: 400
});

const Question4 = newQuestionContent({
  uid: 'aix',
  description: 'aix',
  keywords: ['Subtraction', 'Efficient'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(8900),
      numberA2: z.number().int().min(100).max(8900),
      numberB1: z.number().int().min(1000).max(9000).multipleOf(1000),
      numberB2: z.number().int().min(100).max(900),
      numberC1: z.number().int().min(100).max(8900),
      numberC2: z.number().int().min(100).max(8900),
      numberD1: z.number().int().min(1000).max(9000).multipleOf(1000),
      numberD2: z.number().int().min(100).max(900),
      lineDdiff: z.number().int().min(2).max(5)
    })
    .refine(
      val => (val.numberA1 + val.numberA2) % 1000 === 0 && val.numberA1 + val.numberA2 < 10000,
      'numberA1 + numberA2 must equal a multiple of 1,000 that is less than 10,000'
    )
    .refine(
      val => (val.numberC1 + val.numberC2) % 1000 === 0 && val.numberC1 + val.numberC2 < 10000,
      'numberC1 + numberC2 must equal a multiple of 1,000 that is less than 10,000'
    )
    .refine(
      val =>
        val.numberA1 % 1000 !== 0 &&
        val.numberA2 % 1000 !== 0 &&
        val.numberC1 % 1000 !== 0 &&
        val.numberC2 % 1000 !== 0
    ),
  simpleGenerator: () => {
    // Equation A
    const numberA3 = randomIntegerInclusiveStep(1000, 9000, 1000);

    const numberA1 = randomIntegerInclusive(100, numberA3 - 100, {
      constraint: x => x % 1000 !== 0 && x !== numberA3 / 2
    });

    const numberA2 = numberA3 - numberA1;

    // Equation C
    const numberC3 = randomIntegerInclusiveStep(1000, 9000, 1000, {
      constraint: x => x !== numberA3
    });

    const numberC1 = randomIntegerInclusive(100, numberC3 - 100, {
      constraint: x => x % 1000 !== 0 && x !== numberC3 / 2
    });

    const numberC2 = numberC3 - numberC1;

    // Equations B and D
    const [numberB1, numberD1] = randomUniqueIntegersInclusiveStep(1000, 9000, 1000, 2);

    const [numberB2, numberD2] = randomUniqueIntegersInclusive(100, 900, 2, {
      constraint: x => x !== numberA1 && x !== numberA2 && x !== numberC1 && x !== numberC2
    });

    const lineDdiff = randomIntegerInclusive(2, 5);

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      lineDdiff
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        numberC1,
        numberC2,
        numberD1,
        numberD2,
        lineDdiff
      },
      translate
    } = props;

    const numberA3 = numberA1 + numberA2;
    const numberB3 = numberB1 + numberB2;
    const numberC3 = numberC1 + numberC2;
    const numberD3 = numberD1 + numberD2;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${(numberA3 - 1).toLocaleString()} ${SUB} ${(numberA1 - 1).toLocaleString()}`,
        value: 'A'
      };
      const statement2 = {
        statement: `${numberB3.toLocaleString()} ${SUB} ${(numberB1 - 1).toLocaleString()}`,
        value: 'B'
      };
      const statement3 = {
        statement: `${(numberC3 - 1).toLocaleString()} ${SUB} ${(numberC1 - 1).toLocaleString()}`,
        value: 'C'
      };
      const statement4 = {
        statement: `${numberD3.toLocaleString()} ${SUB} ${(numberD1 - lineDdiff).toLocaleString()}`,
        value: 'D'
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      lineDdiff,
      numberA1,
      numberA3,
      numberB1,
      numberB3,
      numberC1,
      numberC3,
      numberD1,
      numberD3,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchEquivalentCalcs()}
        pdfTitle={translate.instructions.useTheCardsToMatchEquivalentCalcs()}
        items={[
          {
            component: `${numberA3.toLocaleString()} ${SUB} ${numberA1.toLocaleString()}`,
            value: 'A'
          },
          {
            component: `${(numberB3 + 1).toLocaleString()} ${SUB} ${numberB1.toLocaleString()}`,
            value: 'B'
          },
          {
            component: `${numberC3.toLocaleString()} ${SUB} ${numberC1.toLocaleString()}`,
            value: 'C'
          },
          {
            component: `${(
              numberD3 + lineDdiff
            ).toLocaleString()} ${SUB} ${numberD1.toLocaleString()}`,
            value: 'D'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        itemMaxLines={1}
        pdfItemVariant="tallRectangle"
        itemsLetterEmWidth={0.5}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aiy',
  description: 'aiy',
  keywords: ['Subtraction', 'Efficient', 'Constant difference'],
  schema: z
    .object({
      totalBulbs: z.number().int().min(1000).max(9000).multipleOf(1000),
      selectedColourBulbs: z
        .number()
        .int()
        .min(611)
        .max(5111)
        .refine(val => numberOfZeroDigits(val) === 0, 'Subtrahend must not contain any zeroes.'),
      selectedColour: z.enum(['Blue', 'Green', 'Red'])
    })
    .refine(
      val => val.totalBulbs - 300 >= val.selectedColourBulbs,
      'totalBulbs must be at least 300 more than selectedColourBulbs.'
    ),
  simpleGenerator: () => {
    const totalBulbs = randomIntegerInclusiveStep(1000, 9000, 1000);

    const selectedColourBulbs = randomIntegerInclusive(611, 5111, {
      // Must not contain any zeroes and must be at least 300 less than minuend:
      constraint: x => numberOfZeroDigits(x) === 0 && totalBulbs - 300 >= x
    });

    const selectedColour = getRandomFromArray(['Blue', 'Green', 'Red'] as const);

    return { totalBulbs, selectedColourBulbs, selectedColour };
  },
  Component: props => {
    const {
      question: { totalBulbs, selectedColourBulbs, selectedColour },
      translate
    } = props;

    const difference = totalBulbs - selectedColourBulbs;

    const selectedColourString = translate.colors[selectedColour]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aThemeParkHasNumLightBulbsNumLightBulbsAreColour(
          totalBulbs,
          selectedColourBulbs,
          selectedColourString
        )}
        testCorrect={[difference.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aiz',
  description: 'aiz',
  keywords: ['Subtraction', 'Efficient'],
  schema: z
    .object({
      numberA1: z.number().int().min(999).max(8999),
      numberA3: z.number().int().min(1001).max(9999),
      numberB1: z.number().int().min(995).max(8998),
      numberB3: z.number().int().min(1001).max(9999)
    })
    .refine(val => val.numberA1 < val.numberA3, 'numberA1 must be less than numberA3.')
    .refine(val => val.numberB1 < val.numberB3, 'numberB1 must be less than numberB3.')
    .refine(val => val.numberA1 % 1000 === 999, 'numberA1 must end in 999')
    .refine(
      val => val.numberB1 % 1000 === 995 || val.numberB1 % 1000 === 998,
      'numberB1 must end in 995 or 998'
    ),
  simpleGenerator: () => {
    const numberA3 = randomIntegerInclusive(1001, 9999);

    // Number ending in 999, less than numberA3
    const numberA1 =
      randomIntegerInclusiveStep(1000, 9000, 1000, {
        constraint: x => x < numberA3
      }) - 1;

    const numberB3 = randomIntegerInclusive(1001, 9999);
    const numberB1Negate = getRandomFromArray([2, 5] as const);

    // Number ending in either 995 or 998, less than numberB3
    const numberB1 =
      randomIntegerInclusiveStep(1000, 9000, 1000, {
        constraint: x => x - numberB1Negate < numberB3
      }) - numberB1Negate;

    return { numberA1, numberA3, numberB1, numberB3 };
  },

  Component: props => {
    const {
      question: { numberA1, numberA3, numberB1, numberB3 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA3,
      right: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB3,
      right: numberB1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheSubtractions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
        {...props}
      />
    );
  },
  questionHeight: 500
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EfficientSubtraction',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
