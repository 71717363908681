import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  counterVariantSchema,
  counterVariants
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  rangeAsString
} from '../../../../utils/collections';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { SUB } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { TenFrameTapToCrossOutWithState } from '../../../../components/question/representations/TenFrame/TenFrameTapToCrossOut';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bg2',
  description: 'bg2',
  keywords: ['Subtract', 'Cross out', 'Take away', 'Counters', 'Ten frame'],
  schema: z.object({
    numA: z.number().int().min(10).max(40).multipleOf(10),
    numB: z.number().int().min(1).max(9),
    color: counterVariantSchema
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusiveStep(10, 40, 10);
    const numB = randomIntegerInclusive(1, 9);
    const color = getRandomFromArray(counterVariants);

    return { numA, numB, color };
  },
  Component: props => {
    const {
      question: { numA, numB, color },
      translate,
      displayMode
    } = props;

    const fullTenFrames = countRange(numA / 10).map(_ => filledArray(color, 10));

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA - numB], SUB, 2);

    const defaultState = filledArray(false, 10);

    const instruction = `${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.youCanTapTheCountersToCrossThemOut()}`;
    const pdfInstruction = `${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.useTheTenFramesToHelpYou()}`;

    return (
      <QF1ContentAndSentence
        title={instruction}
        pdfTitle={pdfInstruction}
        Content={
          <View style={{ flexDirection: 'column', gap: displayMode === 'digital' ? 16 : 32 }}>
            <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 16 : 32 }}>
              <TenFrameTapToCrossOutWithState
                id="ten-frame-A"
                defaultState={defaultState}
                items={fullTenFrames?.[0] ?? []}
                size={displayMode === 'digital' ? 'small' : 'large'}
              />
              <TenFrameTapToCrossOutWithState
                id="ten-frame-B"
                defaultState={defaultState}
                items={fullTenFrames?.[1] ?? []}
                size={displayMode === 'digital' ? 'small' : 'large'}
              />
            </View>
            <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 16 : 32 }}>
              <TenFrameTapToCrossOutWithState
                id="ten-frame-C"
                defaultState={defaultState}
                items={fullTenFrames?.[2] ?? []}
                size={displayMode === 'digital' ? 'small' : 'large'}
              />
              <TenFrameTapToCrossOutWithState
                id="ten-frame-D"
                defaultState={defaultState}
                items={fullTenFrames?.[3] ?? []}
                size={displayMode === 'digital' ? 'small' : 'large'}
              />
            </View>
          </View>
        }
        pdfDirection="column"
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bg3',
  description: 'bg3',
  keywords: ['Subtract', 'Take away', 'Number line', 'Tens'],
  schema: z.object({
    numA: z.number().int().min(20).max(100).multipleOf(10),
    numB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusiveStep(20, 100, 10);
    const numB = randomIntegerInclusive(1, 9);

    return { numA, numB };
  },
  Component: props => {
    const {
      question: { numA, numB },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA - numB], SUB, 2);

    const tickValues = rangeAsString(numA - 10, numA, 1, true);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        pdfDirection="column"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bg4',
  description: 'bg4',
  keywords: ['Subtract', 'Take away', 'Tens'],
  schema: z.object({
    minuend: z.number().int().min(20).max(100).multipleOf(10),
    subtrahends: z
      .array(z.number().int().min(1).max(9))
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'no duplicate subtrahends'),
    answers: z.array(z.number().int().min(1).max(100)).length(4)
  }),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusiveStep(20, 100, 10);
    const subtrahends = randomUniqueIntegersInclusive(1, 9, 4);

    const shuffledAnswers = shuffle(subtrahends.map(subtrahend => minuend - subtrahend));

    return { minuend, subtrahends, answers: shuffledAnswers };
  },
  Component: props => {
    const {
      question: { minuend, subtrahends, answers },
      translate
    } = props;

    const sentencesAndAnswers = subtrahends.map(sub =>
      buildSimpleNumberSentence([minuend, sub, minuend - sub], SUB, 2)
    );

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberSentences()}
        items={answers}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        pdfSentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        sentences={sentencesAndAnswers.map(({ sentence }) => sentence)}
        testCorrect={sentencesAndAnswers.map(({ answer }) => [answer])}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFromA10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
