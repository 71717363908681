import { newBlockContent } from '../../../Block';

import RecogniseEqualGroups from './1RecogniseEqualGroups';
import MakeEqualGroups from './2MakeEqualGroups';
import AddEqualGroups from './3AddEqualGroups';
import IntroduceMultiplicationSymbol from './4IntroduceMultiplicationSymbol';
import MultiplicationSentences from './5MultiplicationSentences';
import UseArrays from './6UseArrays';
import MakeEqualGroupsGrouping from './7MakeEqualGroupsGrouping';
import MakeEqualGroupsSharing from './8MakeEqualGroupsSharing';
import The2TimesTable from './9The2TimesTable';
import DivideBy2 from './10DivideBy2';
import DoublingAndHalving from './11DoublingAndHalving';
import OddAndEvenNumbers from './12OddAndEvenNumbers';
import The10TimesTable from './13The10TimesTable';
import DivideBy10 from './14DivideBy10';
import The5TimesTable from './15The5TimesTable';
import DivideBy5 from './16DivideBy5';
import The5And10TimesTables from './17The5And10TimesTables';

const Block = newBlockContent({
  block: 'MultiplicationAndDivision',
  field: 'Number',
  weeks: [3, 7],
  smallSteps: [
    RecogniseEqualGroups, // Step 1
    MakeEqualGroups, // Step 2
    AddEqualGroups, // Step 3
    IntroduceMultiplicationSymbol, // Step 4
    MultiplicationSentences, // Step 5
    UseArrays, // Step 6
    MakeEqualGroupsGrouping, // Step 7
    MakeEqualGroupsSharing, // Step 8
    The2TimesTable, // Step 9
    DivideBy2, // Step 10
    DoublingAndHalving, // Step 11
    OddAndEvenNumbers, // Step 12
    The10TimesTable, // Step 13
    DivideBy10, // Step 14
    The5TimesTable, // Step 15
    DivideBy5, // Step 16
    The5And10TimesTables // Step 17
  ]
});
export default Block;
