import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { fruitAsWord } from '../../../../utils/fruits';
import { colors } from '../../../../theme/colors';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import { objectAsWord } from '../../../../utils/objects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { getGenderFromKs1Name, getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const objects = [
  'AppleRed',
  'AppleGreen',
  'Banana',
  'Pear',
  'Muffin',
  'Cookie',
  'CubesBlue',
  'CubesPurple',
  'CubesOrange',
  'CubesRed',
  'CubesGreen',
  'CubesYellow'
];
export type ArrayObj = (typeof objects)[number];

const objectImages: Record<ArrayObj, SvgName> = {
  AppleRed: 'Array_objects/AppleRed',
  AppleGreen: 'Array_objects/AppleGreen',
  Banana: 'Array_objects/Banana',
  Pear: 'Array_objects/Pear',
  Muffin: 'Muffins_individually/Muffin_1',
  Cookie: 'Cookie',
  CubesBlue: 'Cubes_blank/Coloured_cube_unlabelled_blue',
  CubesPurple: 'Cubes_blank/Coloured_cube_unlabelled_purple',
  CubesOrange: 'Cubes_blank/Coloured_cube_unlabelled_orange',
  CubesRed: 'Cubes_blank/Coloured_cube_unlabelled_red',
  CubesGreen: 'Cubes_blank/Coloured_cube_unlabelled_green',
  CubesYellow: 'Cubes_blank/Coloured_cube_unlabelled_yellow',
  CounterBlue: 'Circles/circle_blue',
  CounterRed: 'Circles/circle_red',
  CounterYellow: 'Circles/circle_yellow',
  CounterGreen: 'Circles/circle_green'
};

const Question1 = newQuestionContent({
  uid: 'biY',
  description: 'biY',
  keywords: ['Equal groups', 'Sharing', 'Division'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      item: z.enum([
        'AppleRed',
        'AppleGreen',
        'Pear',
        'Banana',
        'Muffin',
        'Cookie',
        'CubesBlue',
        'CubesPurple',
        'CubesOrange',
        'CubesRed',
        'CubesGreen',
        'CubesYellow',
        'CounterBlue',
        'CounterYellow',
        'CounterRed',
        'CounterGreen'
      ]),
      inEachGroup: z.number().int().min(1).max(4)
    })
    .refine(val => val.groups * val.inEachGroup <= 25, 'must be less than 26 objects'),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);
    const item = getRandomFromArray([
      'AppleRed',
      'AppleGreen',
      'Pear',
      'Banana',
      'Muffin',
      'Cookie',
      'CubesBlue',
      'CubesPurple',
      'CubesOrange',
      'CubesRed',
      'CubesGreen',
      'CubesYellow',
      'CounterBlue',
      'CounterYellow',
      'CounterRed',
      'CounterGreen'
    ] as const);
    const inEachGroup = randomIntegerInclusive(1, 4, { constraint: x => x * groups <= 25 });

    return { groups, item, inEachGroup };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup },
      translate,
      displayMode
    } = props;

    const svgName = objectImages[item];

    const localisedPluralItem =
      item === 'AppleRed' || item === 'AppleGreen'
        ? fruitAsWord('Apple', translate, true)
        : item === 'Pear' || item === 'Banana'
        ? fruitAsWord(item, translate, true)
        : item === 'Muffin'
        ? translate.food.Muffins(2)
        : [
            'CubesBlue',
            'CubesPurple',
            'CubesOrange',
            'CubesRed',
            'CubesGreen',
            'CubesYellow'
          ].includes(item)
        ? translate.shapes.cubes(2)
        : ['CounterBlue', 'CounterYellow', 'CounterRed', 'CounterGreen'].includes(item)
        ? translate.ks1MiscStrings.counters()
        : translate.food.Cookies(2);

    const localisedSingularItem =
      item === 'AppleRed' || item === 'AppleGreen'
        ? fruitAsWord('Apple', translate, false)
        : item === 'Pear' || item === 'Banana'
        ? fruitAsWord(item, translate, false)
        : item === 'Muffin'
        ? translate.food.Muffins(2)
        : [
            'CubesBlue',
            'CubesPurple',
            'CubesOrange',
            'CubesRed',
            'CubesGreen',
            'CubesYellow'
          ].includes(item)
        ? translate.shapes.cubes(1)
        : ['CounterBlue', 'CounterYellow', 'CounterRed', 'CounterGreen'].includes(item)
        ? translate.ks1MiscStrings.counter()
        : translate.food.Cookies(2);

    const color = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const borderWidth = displayMode === 'digital' ? 2 : 4;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={[
          translate.ks1AnswerSentences.xObjectsAreSharedEquallyBetweenAnsBoxes(
            inEachGroup * groups,
            localisedPluralItem
          ),
          inEachGroup === 1
            ? translate.ks1AnswerSentences.thereIsAnsObjectInEachBox(localisedSingularItem)
            : translate.ks1AnswerSentences.thereAreAnsObjectInEachBox(localisedPluralItem)
        ]}
        testCorrect={[[groups.toString()], [inEachGroup.toString()]]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View
              style={{
                flexDirection: 'column',
                gap: 15
              }}
            >
              {countRange(groups === 6 ? 2 : 1).map(rowId => {
                const viewDimens = Math.min(
                  dimens.width / 5.5,
                  dimens.height / (groups > 5 ? 2 : 1.5)
                );
                return (
                  <View key={rowId} style={{ flexDirection: 'row', gap: 15 }}>
                    {countRange(groups === 6 ? 3 : groups).map(i => (
                      <View
                        key={i}
                        style={{
                          borderColor: color,
                          borderWidth,
                          width: viewDimens,
                          height: viewDimens,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 10,
                            justifyContent: 'center'
                          }}
                        >
                          {countRange(inEachGroup).map(i => (
                            <AssetSvg
                              key={`${i}_${i}`}
                              name={svgName}
                              width={viewDimens * 0.4}
                              height={viewDimens * 0.4}
                            />
                          ))}
                        </View>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biZ',
  description: 'biZ',
  keywords: ['Share', 'Division', 'Equal'],
  schema: z
    .object({
      groups: numberEnum([2, 3, 4, 5, 6, 10]),
      item: z.enum([
        'Apple',
        'Pear',
        'Pencil',
        'Muffin',
        'Cookie',
        'Donut',
        'Egg',
        'Fish',
        'MuffinRack',
        'Flower'
      ]),
      inEachGroup: z.number().int().min(1).max(10)
    })
    .refine(val => val.groups * val.inEachGroup <= 30, 'must be less than 31 objects'),
  simpleGenerator: () => {
    const groups = getRandomFromArray([2, 3, 4, 5, 6, 10] as const);
    const inEachGroup = randomIntegerInclusive(1, 10, { constraint: x => x * groups <= 30 });
    const item = getRandomFromArray(
      inEachGroup > 5
        ? (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : ([
            'Apple',
            'Pear',
            'Donut',
            'Egg',
            'Pencil',
            'Cookie',
            'MuffinRack',
            'Muffin',
            'Fish',
            'Flower'
          ] as const)
    );

    return { groups, item, inEachGroup };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup },
      translate
    } = props;

    const svgName = getGroupSvgName(item, inEachGroup);

    const localisedItem =
      item === 'Apple' || item === 'Egg' || item === 'Pencil' || item === 'Flower'
        ? objectAsWord(item, translate, true)
        : item === 'Pear'
        ? fruitAsWord(item, translate, true)
        : item === 'Donut'
        ? translate.food.Donuts(2)
        : item === 'Muffin' || item === 'MuffinRack'
        ? translate.food.Muffins(2)
        : item === 'Cookie'
        ? translate.food.Cookies(2)
        : translate.objects.Fish();

    const input = { total: groups * inEachGroup, object: localisedItem, groups };

    const title =
      item === 'Apple' || item === 'Pear'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYBags(input)
        : item === 'Muffin' || item === 'Cookie'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYPlates(input)
        : item === 'MuffinRack'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYTrays(input)
        : item === 'Egg' || item === 'Donut'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYBoxes(input)
        : item === 'Pencil'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYPots(input)
        : item === 'Fish'
        ? translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYTanks(input)
        : translate.ks1Instructions.xObjectsAreSharedEquallyBetweenYVases(input);

    return (
      <QF1ContentAndSentence
        title={`${title}<br/>${translate.ks1Instructions.completeTheDivision()}`}
        sentence={`<ans/> ${DIV} ${groups.toLocaleString()} = <ans/>`}
        testCorrect={[(groups * inEachGroup).toString(), inEachGroup.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View
              style={{
                flexDirection: 'column',
                gap: 15
              }}
            >
              {countRange(groups > 5 ? 2 : 1).map(rowId => {
                return (
                  <View key={rowId} style={{ flexDirection: 'row', gap: 15 }}>
                    {countRange(Math.min(groups - rowId * 5, 5)).map(i => (
                      <AssetSvg
                        key={i}
                        name={svgName}
                        width={dimens.width / 6}
                        height={dimens.height * 0.45}
                      />
                    ))}
                  </View>
                );
              })}
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bi0',
  description: 'bi0',
  keywords: ['Division', 'Grouping'],
  schema: z
    .object({
      name: ks1NameSchema,
      groupSizes: numberEnum([2, 3, 4, 5, 10]),
      total: z.number().int().min(4).max(25),
      item: z.enum(['Apple', 'Pear', 'Sweet', 'Muffin', 'Cookie', 'Pencil'])
    })
    .refine(val => val.total % val.groupSizes === 0, 'total is a multiple of group sizes'),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const groupSizes = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const total = randomIntegerInclusiveStep(2 * groupSizes, 25, groupSizes);

    const item = getRandomFromArray([
      'Apple',
      'Pear',
      'Sweet',
      'Muffin',
      'Cookie',
      'Pencil'
    ] as const);

    return {
      name,
      groupSizes,
      total,
      item
    };
  },
  Component: ({ question, translate }) => {
    const { name, groupSizes, total, item } = question;

    const pronoun =
      getGenderFromKs1Name(name) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const translations = {
      Apple: translate.fruits.Apples(),
      Pear: translate.fruits.Pears(),
      Sweet: translate.enums.Base10RepresentationVariant.Sweets(),
      Muffin: translate.food.Muffins(2),
      Cookie: translate.food.Cookies(2),
      Pencil: translate.objects.Pencils()
    };

    const title = ['Apple', 'Pear', 'Sweet'].includes(item)
      ? translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYBagsHowManyInEachBag({
          name,
          object: translations[item],
          containerCount: total / groupSizes,
          total,
          pronoun
        })
      : ['Cookie', 'Muffin'].includes(item)
      ? translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYPlatesHowManyOnEachPlate({
          name,
          object: translations[item],
          containerCount: total / groupSizes,
          total,
          pronoun
        })
      : translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYPotsHowManyInEachPot({
          name,
          object: translations[item],
          containerCount: total / groupSizes,
          total,
          pronoun
        });

    return (
      <QF2AnswerBoxOneSentence
        title={title}
        testCorrect={[groupSizes.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeEqualGroupsSharing',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
