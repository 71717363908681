import * as Sentry from '@sentry/react-native';
import { type SeverityLevel } from './types';

const ONE_SECOND_IN_MS = 1000;

/**
 * Utility class for logging events, messages and exceptions to Sentry.
 */
export default class SentryLogger {
  /**
   * Captures custom event
   * @param msg - Message to log against event
   * @param level - Severity level of event
   * @param eventData - Additional data e.g. response object
   */
  static captureEvent(msg: string, level: SeverityLevel, eventData?: Record<string, unknown>) {
    return Sentry.captureEvent({
      message: msg,
      level,
      timestamp: Date.now() / ONE_SECOND_IN_MS,
      extra: eventData
    });
  }

  /** Info Logging */
  static captureMsg(msg: string) {
    return Sentry.captureMessage(msg);
  }
}
