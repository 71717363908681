import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import { range } from 'common/src/utils/collections';
import { numberToBase10Object } from 'common/src/utils/math';
import { colors } from 'common/src/theme/colors';
import Text from '../../../typography/Text';
import { all, create, number as toNumber } from 'mathjs';
import { AssetSvg } from '../../../../assets/svg';
import { DisplayMode } from '../../../../contexts/displayMode';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export const GattegnoChart = (props: {
  number: number;
  /** Elements of this array are powers of ten that the row at that index should represent */
  rowsToShow: number[];
  differentRowColors?: boolean;
  dimens: Dimens;
  /**
   * Specify which direction an arrow will appear, only to be used for when multiplying/dividing by 10
   * This shows a jump up/down a row on the Gattegno Chart
   */
  arrowDirection?: 'up' | 'down';
}) => {
  const { number, rowsToShow, differentRowColors = false, dimens, arrowDirection } = props;

  const {
    thousandths = 0,
    hundredths = 0,
    tenths = 0,
    ones = 0,
    tens = 0,
    hundreds = 0,
    thousands = 0,
    tenThousands = 0,
    hundredThousands = 0
  } = numberToBase10Object(number);

  // Number of rows for Gattegno Chart
  const numberOfRows = rowsToShow.length;

  const sharedStyles = useSharedStyles(dimens, numberOfRows);

  const rowToArray = (row: number) => {
    switch (row) {
      case -3:
        return range(0.001, 0.009, 0.001);
      case -2:
        return range(0.01, 0.09, 0.01);
      case -1:
        return range(0.1, 0.9, 0.1);
      case 0:
        return range(1, 9);
      case 1:
        return range(10, 90, 10);
      case 2:
        return range(100, 900, 100);
      case 3:
        return range(1000, 9000, 1000);
      case 4:
        return range(10000, 90000, 10000);
      case 5:
        return range(100000, 900000, 100000);
      default:
        return [];
    }
  };

  const isSelected = (row: number, cellNumber: number) => {
    switch (row) {
      case -3:
        return cellNumber === toNumber(math.evaluate(`${thousandths} * 0.001`));
      case -2:
        return cellNumber === toNumber(math.evaluate(`${hundredths} * 0.01`));
      case -1:
        return cellNumber === toNumber(math.evaluate(`${tenths} * 0.1`));
      case 0:
        return cellNumber === ones;
      case 1:
        return cellNumber === tens * 10;
      case 2:
        return cellNumber === hundreds * 100;
      case 3:
        return cellNumber === thousands * 1000;
      case 4:
        return cellNumber === tenThousands * 10000;
      case 5:
        return cellNumber === hundredThousands * 100000;
      default:
        return false;
    }
  };

  return (
    <View style={[dimens, sharedStyles.tableContainer]}>
      {/* Map each row we want to show: */}
      {rowsToShow.map((row, rowIndex) => (
        <View style={sharedStyles.tableRow} key={row}>
          {/* Grab and map each number we want to show in a row as individual cells: */}
          {rowToArray(row).map((cellNumber, cellIndex, rowArray) => {
            return (
              <TableCell
                key={cellNumber}
                value={cellNumber}
                isSelected={isSelected(row, cellNumber)}
                row={row}
                firstRow={rowIndex === 0}
                lastCell={cellIndex === rowArray.length - 1}
                differentRowColors={differentRowColors}
                dimens={dimens}
                numberOfRows={numberOfRows}
                arrowDirection={arrowDirection}
              />
            );
          })}
        </View>
      ))}
    </View>
  );
};

const TableCell = (props: {
  isSelected: boolean;
  firstRow: boolean;
  lastCell: boolean;
  row: number;
  value: number;
  differentRowColors: boolean;
  dimens: Dimens;
  numberOfRows: number;
  arrowDirection?: 'up' | 'down';
}) => {
  const {
    isSelected,
    firstRow,
    lastCell,
    row,
    value,
    differentRowColors,
    dimens,
    numberOfRows,
    arrowDirection
  } = props;

  const displayMode = useContext(DisplayMode);
  const sharedStyles = useSharedStyles(dimens, numberOfRows, arrowDirection);

  const backgroundColorSwitch = (row: number) => {
    switch (row) {
      case -3:
        return colors.thousands;
      case -2:
        return colors.hundreds;
      case -1:
        return colors.tens;
      case 0:
        return colors.ones;
      case 1:
        return colors.tens;
      case 2:
        return colors.hundreds;
      case 3:
        return colors.thousands;
      case 4:
        return colors.tenThousands;
      case 5:
        return colors.hundredThousands;
      default:
        return 'lightgrey';
    }
  };

  return (
    <View
      style={[
        sharedStyles.cell,
        lastCell && sharedStyles.lastCellOfRow,
        firstRow && sharedStyles.firstRow,
        isSelected && {
          backgroundColor:
            displayMode === 'digital'
              ? differentRowColors
                ? backgroundColorSwitch(row)
                : colors.pacificBlue600
              : colors.pdfShading
        },
        sharedStyles.selectedNumber
      ]}
    >
      {isSelected && arrowDirection && (
        // The arrow will be rotated depending on the arrowDirection specified
        <View style={sharedStyles.arrowContainer}>
          <AssetSvg
            name="ArrowUpShort"
            height={displayMode === 'digital' ? 45 : 70}
            width={displayMode === 'digital' ? 45 : 70}
          />
        </View>
      )}
      <Text
        style={{
          textAlign: 'center',
          color: isSelected && !differentRowColors ? 'white' : colors.prussianBlue,
          fontSize: displayMode === 'digital' ? 22 : 36,
          lineHeight: undefined
        }}
      >
        {value.toLocaleString()}
      </Text>
    </View>
  );
};

const useSharedStyles = (dimens: Dimens, numberOfRows: number, arrowDirection?: 'up' | 'down') => {
  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);

  const borderWidth =
    displayMode === 'digital' ? Math.max(2, 2 / scaleFactor) : Math.max(4, 2 / scaleFactor);

  return useMemo(
    () =>
      StyleSheet.create({
        cell: {
          borderColor: colors.prussianBlue,
          borderBottomWidth: borderWidth,
          borderStartWidth: borderWidth,
          justifyContent: 'center',
          flex: 1,
          alignContent: 'center',
          alignItems: 'center'
        },
        firstRow: {
          borderTopWidth: borderWidth
        },
        lastCellOfRow: {
          borderEndWidth: borderWidth
        },
        selectedNumber: {
          justifyContent: 'center',
          alignContent: 'center'
        },
        tableContainer: {
          justifyContent: 'flex-start',
          alignSelf: 'center'
        },
        tableRow: {
          flexDirection: 'row',
          height: dimens.height / numberOfRows
        },
        arrowContainer: {
          transform: arrowDirection === 'up' ? undefined : [{ rotate: '180deg' }],
          position: 'absolute',
          top: arrowDirection === 'up' ? undefined : dimens.height / 5.5,
          bottom: arrowDirection === 'up' ? dimens.height / 5.5 : undefined
        }
      }),
    [borderWidth, dimens.height, numberOfRows, arrowDirection]
  );
};
