import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomNumberWithSpecificDigit,
  rejectionSample
} from 'common/src/utils/random';
import { Digit, ScientificNotation } from 'common/src/utils/math';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from 'common/src/utils/zod';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acc',
  description: 'acc',
  keywords: ['Place value', 'Base 10', 'More', 'Less', '1,000'],
  schema: z
    .object({
      number: z.number().int().min(1).max(9999),
      moreOrLess: z.enum(['more', 'less']),
      diff: numberEnum([1, 10, 100, 1000])
    })
    .refine(
      val =>
        val.moreOrLess === 'more'
          ? numbersDoNotExchange(val.number, val.diff)
          : numbersDoNotExchange(val.number, -val.diff),
      'number and diff must not exchange.'
    )
    .refine(
      ({ number, moreOrLess, diff }) =>
        sumDigits(moreOrLess === 'more' ? number + diff : number - diff) <= 33,
      'number must be representable with <=33 base ten counters'
    ),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const diff = getRandomFromArray([1, 10, 100, 1000] as const);
    const number =
      moreOrLess === 'more'
        ? randomIntegerInclusive(1, 9999 - diff, {
            constraint: x => numbersDoNotExchange(x, diff) && sumDigits(x + diff) <= 33
          })
        : randomIntegerInclusive(diff + 1, 9999, {
            constraint: x => numbersDoNotExchange(x, -diff) && sumDigits(x - diff) <= 33
          });

    return { number, moreOrLess, diff };
  },
  Component: props => {
    const {
      question: { number, moreOrLess, diff },
      translate
    } = props;
    const answer = moreOrLess === 'more' ? number + diff : number - diff;
    return (
      <QF15CreateBaseTenNumber
        title={
          moreOrLess === 'more'
            ? translate.instructions.dragBlocksToMakeNumMore(
                diff.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.dragBlocksToMakeNumLess(
                diff.toLocaleString(),
                number.toLocaleString()
              )
        }
        pdfTitle={
          moreOrLess === 'more'
            ? translate.instructions.useBase10ToMakeNumMore(
                diff.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.useBase10ToMakeNumLess(
                diff.toLocaleString(),
                number.toLocaleString()
              )
        }
        number={ScientificNotation.fromNumber(answer)}
        draggablesToShow={[3, 2, 1, 0]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'acd',
  description: 'acd',
  keywords: ['Place value', 'Counters', 'More', 'Less', '1,000'],
  schema: z.object({
    number: z.number().min(1).max(8888),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z.number().refine(x => [1, 10, 100, 1000].includes(x)) as z.Schema<
      1 | 10 | 100 | 1000
    >
  }),
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfTen = getRandomFromArray([1, 10, 100, 1000] as const);
    const number =
      lessOrMore === 'less'
        ? randomIntegerInclusive(1, 8888, {
            constraint: x =>
              ScientificNotation.fromNumber(x).digits.every(i => i < 9) &&
              x - powerOfTen > 0 &&
              numbersDoNotExchange(x, -powerOfTen)
          })
        : randomIntegerInclusive(1, 8888, {
            constraint: x =>
              ScientificNotation.fromNumber(x).digits.every(i => i < 9) &&
              x + powerOfTen <= 9999 &&
              numbersDoNotExchange(x, powerOfTen)
          });

    return { number, lessOrMore, powerOfTen };
  },
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <ReadPlaceValueChart
        title={translate.instructions.herePlaceValueChart()}
        number={ScientificNotation.fromNumber(number)}
        columnsToShow={[3, 2, 1, 0]}
        counterVariant="greyCounter"
        sentenceStyle={{ justifyContent: 'flex-start' }}
        pdfSentenceStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number.toString(), result.toString()]}
        sentence={
          lessOrMore === 'less'
            ? translate.answerSentences.whatNumberIsShownWhatIsLess(powerOfTen.toLocaleString())
            : translate.answerSentences.whatNumberIsShownWhatIsMore(powerOfTen.toLocaleString())
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'ace',
  description: 'ace',
  keywords: ['Place value', 'More', 'Less', '1,000'],
  schema: z.object({
    number: z.number().min(1).max(9999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z.number().refine(x => [1, 10, 100, 1000].includes(x)) as z.Schema<
      1 | 10 | 100 | 1000
    >
  }),
  example: {
    number: 2465,
    lessOrMore: 'less',
    powerOfTen: 1000
  },
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfTen = getRandomFromArray([1, 10, 100, 1000] as const);
    const number =
      lessOrMore === 'less'
        ? randomIntegerInclusive(1, 9999, {
            constraint: x => x - powerOfTen > 0 && numbersDoNotExchange(x, -powerOfTen)
          })
        : randomIntegerInclusive(1, 9999, {
            constraint: x => x + powerOfTen <= 9999 && numbersDoNotExchange(x, powerOfTen)
          });

    return { number, lessOrMore, powerOfTen };
  },
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate, ...props }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[result.toString()]}
        {...props}
        sentence={translate.instructions.oneTen100LessOrMoreThanNumberIsAns(
          powerOfTen.toLocaleString(),
          lessOrMore,
          number.toLocaleString()
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'acf',
  description: 'acf',
  keywords: ['Place value', 'More', 'Less', 'Place holder', '1,000'],
  schema: z.object({
    number: z.number().min(1).max(9999),
    powerOfUnit: z.number().int().min(0).max(3)
  }),
  example: {
    number: 465,
    powerOfUnit: 3
  },
  simpleGenerator: () => {
    const powerOfUnit = randomIntegerInclusive(0, 3) as 0 | 1 | 2 | 3;
    // Enforce the answer's digit at the selected power to be 0
    let number = randomNumberWithSpecificDigit(powerOfUnit, { lower: 1, upper: 8999 }, 0 as Digit);

    // Enforce answer to be 10 if this generator returned it as being just 0
    number === 0 ? (number = 10) : '';

    return { number, powerOfUnit };
  },
  Component: ({ question: { number, powerOfUnit }, translate, ...props }) => {
    const power = Math.pow(10, powerOfUnit);
    const numberToShow = number + power;

    const result = numberToShow - power;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[result.toString()]}
        {...props}
        sentence={translate.instructions.oneTen100LessOrMoreThanNumberIsAns(
          power.toLocaleString(),
          'less',
          numberToShow.toLocaleString()
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acg',
  description: 'acg',
  keywords: ['Place value', 'More', 'Less', '1,000'],
  schema: z.object({
    number: z.number().min(1001).max(8999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfUnit: z.number().int().min(1).max(3)
  }),
  example: {
    number: 2465,
    lessOrMore: 'less',
    powerOfUnit: 2
  },
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfUnit: number = randomIntegerInclusive(1, 3);
    const number = randomIntegerInclusive(1001, 8999);
    return { number, lessOrMore, powerOfUnit };
  },
  Component: ({ question: { number, lessOrMore, powerOfUnit }, translate }) => {
    const power = Math.pow(10, powerOfUnit);

    const numbers = [
      number + 1000,
      number - 1000,
      number + 100,
      number - 100,
      number + 10,
      number - 10
    ];

    return (
      <QF10SelectNumbers
        title={
          lessOrMore === 'less'
            ? translate.instructions.whatIsDiffLessThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.whatIsDiffMoreThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
        }
        testCorrect={numbers.filter(it =>
          lessOrMore === 'less' ? it === number - power : it === number + power
        )}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ach',
  description: 'ach',
  keywords: ['Place value', 'More', 'Less', '1,000'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9999),
      powerOfNum1Unit: z.number().int().min(0).max(3),
      number2: z.number().int().min(1).max(8999),
      powerOfNum2Unit: z.number().int().min(0).max(3)
    })
    .refine(
      val => val.powerOfNum1Unit !== val.powerOfNum2Unit,
      'powerOfNum1Unit and powerOfNum2Unit should not be the same.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999);

    const powerOfNum1Unit = randomIntegerInclusive(0, 3);

    const number2 = randomIntegerInclusive(1, 8999);

    // Ensure powerOfNum2Unit is not the same as powerOfNum1Unit
    const powerOfNum2Unit = rejectionSample(
      () => randomIntegerInclusive(0, 3),
      x => x !== powerOfNum1Unit
    );

    return { number1, powerOfNum1Unit, number2, powerOfNum2Unit };
  },
  Component: props => {
    const {
      question: { number1, powerOfNum1Unit, number2, powerOfNum2Unit },
      translate
    } = props;
    const power1 = Math.pow(10, powerOfNum1Unit);
    const power2 = Math.pow(10, powerOfNum2Unit);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentencesEachCardCanBeUsedMoreThanOnce()}
        items={[1, 10, 100, 1000]}
        sentences={[
          `${translate.answerSentences.numIs(
            number1.toLocaleString()
          )} <ans/> ${translate.answerSentences.moreThan((number1 - power1).toLocaleString())}`,
          `${translate.answerSentences.numIs(
            number2.toLocaleString()
          )} <ans/> ${translate.answerSentences.lessThan((number2 + power2).toLocaleString())}`
        ]}
        testCorrect={[[power1], [power2]]}
        moveOrCopy="copy"
        questionHeight={900}
        pdfItemVariant="tallRectangle"
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Find1To1000MoreOrLess',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
