// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import FractionsB from './FractionsB';
import Money from './Money';
import Time from './Time';
import Shape from './Shape';
import Statistics from './Statistics';

const Term = newTermContent({
  term: 'Summer',
  blocks: [FractionsB, Money, Time, Shape, Statistics]
});
export default Term;
