import { AssetSvg, SvgName } from 'common/src/assets/svg';
import { getRandomSubArrayFromArray } from './random';
import { zodEnumFromObjKeys } from './zod';

export const cubedCubesSVG = (
  object: 1 | 2 | 3 | 4 | 5,
  height: number = 100,
  width: number = 100
) => {
  switch (object) {
    case 1:
      return <AssetSvg height={height} width={width} name="3D_Shapes_Cubes/3D_shape69_cubes" />;
    case 2:
      return <AssetSvg height={height} width={width} name="3D_Shapes_Cubes/3D_shape25_cubes" />;
    case 3:
      return <AssetSvg height={height} width={width} name="3D_Shapes_Cubes/3D_shape37_cubes" />;
    case 4:
      return <AssetSvg height={height} width={width} name="3D_Shapes_Cubes/3D_shape70_cubes" />;
    case 5:
      return <AssetSvg height={height} width={width} name="3D_Shapes_Cubes/3D_shape68_cubes" />;
  }
};

const cuboidsWithArrowsSVGs: SvgName[] = [
  'Cuboids_With_3_Arrows/cuboid1_3_arrows',
  'Cuboids_With_3_Arrows/cuboid2_3_arrows',
  'Cuboids_With_3_Arrows/cuboid3_3_arrows',
  'Cuboids_With_3_Arrows/cuboid4_3_arrows',
  'Cuboids_With_3_Arrows/cuboid5_3_arrows',
  'Cuboids_With_3_Arrows/cuboid6_3_arrows'
];

const cubesWithArrow: SvgName[] = [
  'Cubes_With_1_Arrow/cube1_arrow',
  'Cubes_With_1_Arrow/cube2_arrow',
  'Cubes_With_1_Arrow/cube3_arrow'
];

const cuboids: SvgName[] = ['Cuboids/Cuboid1', 'Cuboids/Cuboid2', 'Cuboids/Cuboid3'];

export const cuboidSVGNameAndSizeObjects: {
  size: number;
  svgName: SvgName;
  dimens: [number, number, number]; // [height, width, depth]
}[] = [
  { svgName: '3D_Shapes_Cubes/3D_shape1_cubes', size: 3, dimens: [3, 1, 1] },
  { svgName: '3D_Shapes_Cubes/3D_shape2_cubes', size: 4, dimens: [4, 1, 1] },
  { svgName: '3D_Shapes_Cubes/3D_shape3_cubes', size: 8, dimens: [4, 2, 1] },
  { svgName: '3D_Shapes_Cubes/3D_shape4_cubes', size: 4, dimens: [2, 1, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape5_cubes', size: 6, dimens: [3, 1, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape6_cubes', size: 6, dimens: [2, 3, 1] },
  { svgName: '3D_Shapes_Cubes/3D_shape7_cubes', size: 9, dimens: [3, 3, 1] },
  { svgName: '3D_Shapes_Cubes/3D_shape8_cubes', size: 8, dimens: [2, 1, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape24_cubes', size: 4, dimens: [1, 2, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape26_cubes', size: 12, dimens: [3, 2, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape27_cubes', size: 16, dimens: [4, 2, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape28_cubes', size: 6, dimens: [1, 3, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape29_cubes', size: 12, dimens: [2, 3, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape30_cubes', size: 18, dimens: [3, 3, 2] },
  { svgName: '3D_Shapes_Cubes/3D_shape31_cubes', size: 12, dimens: [3, 1, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape33_cubes', size: 16, dimens: [2, 2, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape34_cubes', size: 24, dimens: [3, 2, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape35_cubes', size: 9, dimens: [1, 3, 3] },
  { svgName: '3D_Shapes_Cubes/3D_shape36_cubes', size: 18, dimens: [2, 3, 3] },
  { svgName: '3D_Shapes_Cubes/3D_shape38_cubes', size: 12, dimens: [1, 3, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape39_cubes', size: 24, dimens: [2, 3, 4] },
  { svgName: '3D_Shapes_Cubes/3D_shape40_cubes', size: 36, dimens: [3, 3, 4] }
];

const cubeSVGNameAndSizeObjects: { size: number; svgName: SvgName }[] = [
  { svgName: '3D_Shapes_Cubes/3D_shape25_cubes', size: 8 },
  { svgName: '3D_Shapes_Cubes/3D_shape37_cubes', size: 9 },
  { svgName: '3D_Shapes_Cubes/3D_shape68_cubes', size: 64 },
  { svgName: '3D_Shapes_Cubes/3D_shape70_cubes', size: 125 }
];

export function getRandomUniqueCuboidsObjects(quantity: number) {
  return getRandomSubArrayFromArray([...cuboidSVGNameAndSizeObjects] as const, quantity);
}

export function getRandomUniqueCubeObjects(quantity: number) {
  return getRandomSubArrayFromArray([...cubeSVGNameAndSizeObjects] as const, quantity);
}

export const shapesWithCubesSVGNameAndSize: {
  size: number;
  svgName: SvgName;
  shape: 'cuboid' | 'cube' | 'rectilinear';
}[] = [
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes2', size: 4, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube1', size: 4, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube2', size: 4, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube3', size: 4, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube4', size: 4, shape: 'rectilinear' },
  { svgName: '3D_Shapes_Cubes/3D_shape24_cubes', size: 4, shape: 'cube' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes4', size: 6, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube5', size: 6, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube6', size: 6, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube7', size: 6, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube8', size: 6, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes3', size: 8, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/Cube_cubes1', size: 8, shape: 'cube' },
  { svgName: 'Shapes_made_from_cubes/othershapescube10', size: 8, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube11', size: 8, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube12', size: 8, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes6', size: 8, shape: 'cuboid' },
  { svgName: '3D_Shapes_Cubes/3D_shape25_cubes', size: 8, shape: 'cube' },
  { svgName: '3D_Shapes_Cubes/3D_shape62_cubes', size: 8, shape: 'cube' },
  { svgName: 'Shapes_made_from_cubes/othershapescube13', size: 10, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube14', size: 10, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube15', size: 10, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube16', size: 10, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes5', size: 12, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes13', size: 12, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/othershapescube17', size: 12, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube18', size: 12, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube19', size: 12, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/othershapescube20', size: 12, shape: 'rectilinear' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes7', size: 16, shape: 'cuboid' },
  { svgName: '3D_Shapes_Cubes/3D_shape36_cubes', size: 18, shape: 'cube' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes9', size: 20, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes10', size: 24, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes11', size: 36, shape: 'cuboid' },
  { svgName: 'Shapes_made_from_cubes/Cuboid_cubes12', size: 36, shape: 'cuboid' },
  { svgName: '3D_Shapes_Cubes/3D_shape70_cubes', size: 64, shape: 'cube' }
];

export function getRandomUniqueShapeWithCubesObjects(
  quantity: number,
  numberOfCubes?: number,
  isNumOfCubes = true
) {
  const array = numberOfCubes
    ? shapesWithCubesSVGNameAndSize.filter(val =>
        isNumOfCubes ? val.size === numberOfCubes : val.size !== numberOfCubes
      )
    : shapesWithCubesSVGNameAndSize;
  return getRandomSubArrayFromArray([...array] as const, quantity);
}

export function getRandomUniqueCuboidsWithArrowsSvgs(numberOfCuboids: number) {
  return getRandomSubArrayFromArray([...cuboidsWithArrowsSVGs] as const, numberOfCuboids);
}

export function getRandomUniqueCubesWithArrowsSvgs(numberOfCuboids: number) {
  return getRandomSubArrayFromArray([...cubesWithArrow] as const, numberOfCuboids);
}

export function getRandomUniqueCuboidsSvgs(numberOfCuboids: number) {
  return getRandomSubArrayFromArray([...cuboids] as const, numberOfCuboids);
}

export const shapeCubes3D: Record<
  string,
  {
    size: number;
    svgName: SvgName;
    hasDepth: boolean;
    stacked: boolean;
  }
> = {
  shape9_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape9_cubes',
    size: 5,
    hasDepth: false,
    stacked: false
  },
  shape10_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape10_cubes',
    size: 5,
    hasDepth: false,
    stacked: false
  },
  shape11_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape11_cubes',
    size: 6,
    hasDepth: false,
    stacked: false
  },
  shape12_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape12_cubes',
    size: 6,
    hasDepth: false,
    stacked: false
  },
  shape13_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape13_cubes',
    size: 7,
    hasDepth: false,
    stacked: false
  },
  shape14_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape14_cubes',
    size: 7,
    hasDepth: false,
    stacked: false
  },
  shape15_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape15_cubes',
    size: 8,
    hasDepth: false,
    stacked: false
  },
  shape16_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape16_cubes',
    size: 8,
    hasDepth: false,
    stacked: false
  },
  shape17_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape17_cubes',
    size: 9,
    hasDepth: false,
    stacked: false
  },
  shape18_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape18_cubes',
    size: 9,
    hasDepth: false,
    stacked: false
  },
  shape19_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape19_cubes',
    size: 5,
    hasDepth: true,
    stacked: false
  },
  shape20_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape20_cubes',
    size: 6,
    hasDepth: true,
    stacked: false
  },
  shape21_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape21_cubes',
    size: 7,
    hasDepth: true,
    stacked: false
  },
  shape22_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape22_cubes',
    size: 8,
    hasDepth: true,
    stacked: false
  },
  shape23_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape23_cubes',
    size: 9,
    hasDepth: true,
    stacked: false
  },
  shape41_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape41_cubes',
    size: 5,
    hasDepth: true,
    stacked: true
  },
  shape42_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape42_cubes',
    size: 14,
    hasDepth: true,
    stacked: true
  },
  shape43_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape43_cubes',
    size: 30,
    hasDepth: true,
    stacked: true
  },
  shape44_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape44_cubes',
    size: 10,
    hasDepth: true,
    stacked: true
  },
  shape45_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape45_cubes',
    size: 15,
    hasDepth: true,
    stacked: true
  },
  shape46_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape46_cubes',
    size: 20,
    hasDepth: true,
    stacked: true
  },
  shape47_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape47_cubes',
    size: 25,
    hasDepth: true,
    stacked: true
  },
  shape48_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape48_cubes',
    size: 14,
    hasDepth: true,
    stacked: true
  },
  shape49_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape49_cubes',
    size: 21,
    hasDepth: true,
    stacked: true
  },
  shape50_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape50_cubes',
    size: 28,
    hasDepth: true,
    stacked: true
  },
  shape51_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape51_cubes',
    size: 18,
    hasDepth: true,
    stacked: true
  },
  shape52_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape52_cubes',
    size: 24,
    hasDepth: true,
    stacked: true
  },
  shape53_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape53_cubes',
    size: 9,
    hasDepth: true,
    stacked: true
  },
  shape54_cubes: {
    svgName: '3D_Shapes_Cubes/3D_shape54_cubes',
    size: 12,
    hasDepth: true,
    stacked: true
  }
};

export type ShapesCubes3DNames = keyof typeof shapeCubes3D;
export const ShapesCubes3DSchema = zodEnumFromObjKeys(shapeCubes3D);
export const shapesCubes3DNames = Object.keys(shapeCubes3D) as ShapesCubes3DNames[];

export function filterShapesCubes3D({
  size,
  hasDepth,
  stacked
}: {
  /**   size filters by range [lowest, highest] the range is [5,30] */
  size?: [number, number];
  hasDepth?: boolean;
  stacked?: boolean;
}): ShapesCubes3DNames[] {
  return Object.entries(shapeCubes3D)
    .filter(([_, info]) => {
      const cubes = size === undefined ? true : info.size >= size[0] && info.size <= size[1];
      const isStacked = stacked === undefined ? true : info.stacked === stacked;
      const flat = hasDepth === undefined ? true : info.hasDepth === hasDepth;

      return cubes && isStacked && flat;
    })
    .map(([name]) => name);
}
