import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { compareFractions } from 'common/src/utils/fractions';
import { z } from 'zod';
import { barModelColors } from 'common/src/theme/colors';
import { countRange, filledArray, range } from 'common/src/utils/collections';
import ShadedFractionBarModel, {
  ShadedFractionBarModelWithState
} from 'common/src/components/question/representations/ShadedFractionBarModel';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import TextStructure from 'common/src/components/molecules/TextStructure';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import NumberLineTicksTopBottom from 'common/src/components/question/representations/Number Line/NumberLineTicksTopBottom';
import { CompleteTheSentenceWithState } from 'common/src/components/molecules/CompleteTheSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMG',
  description: 'aMG',
  keywords: [
    'Bar model',
    'Parts',
    'Mixed number',
    'Improper fraction',
    'Integer',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(8),
    whole: z.number().int().min(1).max(3),
    numeratorA: z.number().int().min(1).max(8)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const whole = randomIntegerInclusive(1, 3);
    const numeratorA = randomIntegerInclusive(1, denominator);

    return { denominator, whole, numeratorA };
  },
  Component: props => {
    const {
      question: { denominator, whole, numeratorA },
      translate,
      displayMode
    } = props;

    const numeratorB = denominator * whole + numeratorA;

    const wholeAmount = Math.floor(numeratorB / denominator);

    const remainder1 = numeratorB % denominator;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, denominator);

    const numeratorColorArray2 = filledArray(numeratorColor, remainder1);
    const remainder2 = filledArray('white', denominator - remainder1);

    const customColorMap = [[...numeratorColorArray1], [...numeratorColorArray2, ...remainder2]];

    return (
      <QF3Content
        title={translate.instructions.completeTheMixedNumberAndImproperFractionShownByTheBarModels()}
        inputType="numpad"
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: dimens.width,
              alignItems: 'center'
            }}
          >
            <View style={{ display: 'flex', rowGap: 16 }}>
              {countRange(wholeAmount).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width / 2}
                  customColorMap={customColorMap[0]}
                  height={displayMode !== 'digital' ? 100 : 60}
                />
              ))}
              {remainder1 > 0 && (
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  width={dimens.width / 2}
                  customColorMap={customColorMap[1]}
                  height={displayMode !== 'digital' ? 100 : 60}
                />
              )}
            </View>
            <View style={{ width: dimens.width / 2, justifyContent: 'center' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingLeft: 32,
                  columnGap: 24
                }}
              >
                <Text variant="WRN400" style={{ fontSize: 32 }}>
                  {remainder1 > 0 ? translate.keywords.Mixed() : translate.keywords.Integer()}
                </Text>
                <CompleteTheSentenceWithState
                  id="sentence-1"
                  sentence={remainder1 > 0 ? `<frac wAns='' nAns='' dAns=''/>` : '<ans/>'}
                  style={{ paddingRight: remainder1 > 0 ? 0 : 16 }}
                  testCorrect={userAnswer => {
                    return remainder1 > 0
                      ? compareFractions(
                          [userAnswer[0], userAnswer[1], userAnswer[2]],
                          [whole, numeratorA, denominator]
                        )
                      : userAnswer[0] === (whole + 1).toString();
                  }}
                  defaultState={
                    displayMode === 'markscheme'
                      ? remainder1 > 0
                        ? [
                            whole.toLocaleString(),
                            numeratorA.toLocaleString(),
                            denominator.toLocaleString()
                          ]
                        : [(whole + 1).toLocaleString()]
                      : undefined
                  }
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingLeft: 32,
                  columnGap: 24
                }}
              >
                <Text variant="WRN400" style={{ fontSize: 32 }}>
                  {translate.keywords.Improper()}
                </Text>
                <CompleteTheSentenceWithState
                  id="sentence-2"
                  sentence={`<frac nAns='' dAns=''/>`}
                  testCorrect={userAnswer =>
                    compareFractions([userAnswer[0], userAnswer[1]], [numeratorB, denominator])
                  }
                  defaultState={
                    displayMode === 'markscheme'
                      ? [numeratorB.toLocaleString(), denominator.toLocaleString()]
                      : undefined
                  }
                />
              </View>
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aMG2',
  description: 'aMG',
  keywords: [
    'Bar model',
    'Parts',
    'Mixed number',
    'Improper fraction',
    'Integer',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(8),
    whole: z.number().int().min(1).max(3),
    numeratorA: z.number().int().min(1).max(8)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const whole = randomIntegerInclusive(1, 3);
    const numeratorA = randomIntegerInclusive(1, denominator);

    return { denominator, whole, numeratorA };
  },
  Component: props => {
    const {
      question: { denominator, whole, numeratorA },
      translate
    } = props;

    const numeratorB = denominator * whole + numeratorA;

    const wholeAmount = Math.floor(numeratorB / denominator);

    const remainder1 = numeratorB % denominator;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, denominator);

    const numeratorColorArray2 = filledArray(numeratorColor, remainder1);
    const remainder2 = filledArray('white', denominator - remainder1);

    const customColorMap = [[...numeratorColorArray1], [...numeratorColorArray2, ...remainder2]];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatFractionIsShownByBarModelGiveAnswerAsMixedAndImproper()}
        sentences={[
          remainder1 > 0
            ? translate.answerSentences.mixedFracAns()
            : translate.answerSentences.integerAns(),
          translate.answerSentences.improperFracAns()
        ]}
        style={{ flexDirection: 'row', alignItems: 'center' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        questionHeight={1100}
        testCorrect={userAnswer =>
          remainder1 > 0
            ? compareFractions(
                [userAnswer[0][0], userAnswer[0][1], userAnswer[0][2]],
                [whole, numeratorA, denominator]
              )
            : userAnswer[0][0] === (whole + 1).toString() &&
              compareFractions([userAnswer[1][0], userAnswer[1][1]], [numeratorB, denominator])
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            remainder1 > 0
              ? [whole.toLocaleString(), numeratorA.toLocaleString(), denominator.toLocaleString()]
              : [(whole + 1).toLocaleString()],
            [numeratorB.toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height }}>
            {countRange(wholeAmount).map(idx => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width * 0.9}
                customColorMap={customColorMap[0]}
                height={dimens.height * 0.22}
              />
            ))}
            {remainder1 > 0 && (
              <ShadedFractionBarModel
                totalSubSections={denominator}
                width={dimens.width * 0.9}
                customColorMap={customColorMap[1]}
                height={dimens.height * 0.22}
              />
            )}
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aMH',
  description: 'aMH',
  keywords: [
    'Integer',
    'Improper fraction',
    'Mixed numbers',
    'Convert',
    'Parts',
    'Wholes',
    'Numerators',
    'Denominators'
  ],
  questionHeight: 900,
  schema: z.object({
    denominatorA: z.number().int().min(2).max(6),
    denominatorB: z.number().int().min(2).max(6),
    wholeA: z.number().int().min(2).max(6),
    wholeB: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 6);
    const denominatorB = randomIntegerInclusive(2, 6);

    const wholeA = randomIntegerInclusive(2, 6);
    const wholeB = randomIntegerInclusive(2, 6, {
      constraint: x => x !== wholeA
    });

    return {
      denominatorA,
      denominatorB,
      wholeA,
      wholeB
    };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, wholeA, wholeB },
      translate
    } = props;

    // Answers
    const ans1 = wholeA * denominatorA;
    const ans2 = wholeB * denominatorB;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertTheIntegersToImproperFractions()}
        testCorrect={[[ans1.toString()], [ans2.toString()]]}
        inputMaxCharacters={1}
        sentences={[
          `${wholeA.toLocaleString()} = <frac nAns='' d='${denominatorA.toLocaleString()}' />`,
          `${wholeB.toLocaleString()} = <frac nAns='' d='${denominatorB.toLocaleString()}' />`
        ]}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMI',
  description: 'aMI',
  keywords: [
    'Double number line',
    'Improper fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators',
    'Integer',
    'Convert'
  ],
  schema: z.object({
    denominator: z.number().min(2).max(5),
    startingNumber: z.number().min(0).max(4),
    numeratorA: z.number().min(1).max(4),
    randomIncorrectAns: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 5);
    const startingNumber = randomIntegerInclusive(0, 4);
    const numeratorA = randomIntegerInclusive(1, denominator - 1);
    const randomIncorrectAns = getRandomBoolean();

    return {
      denominator,
      startingNumber,
      numeratorA,
      randomIncorrectAns
    };
  },
  Component: props => {
    const {
      question: { denominator, startingNumber, numeratorA, randomIncorrectAns },
      translate
    } = props;

    const numeratorB = denominator * startingNumber;
    const whole = startingNumber + 1;
    const endingNumber = whole + 1;
    const numeratorC = whole * denominator;
    const numeratorD = endingNumber * denominator;
    const ansNumerator = numeratorC + numeratorA;
    const incorrectAnsNumerator = randomIncorrectAns ? whole + numeratorA : ansNumerator + 1;

    // Statements to select
    const A = {
      sentence: `<frac n='${ansNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
      value: 'A'
    };
    const B = {
      sentence: `<frac n='${incorrectAnsNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
      value: 'B'
    };

    const statements = shuffle([A, B], { random: seededRandom(props.question) });

    const tickThreePosition = 50 + 50 * (numeratorA / denominator);

    const topTickValues = [
      { label: `${startingNumber.toLocaleString()}`, position: 0 },
      { label: `${whole.toLocaleString()}`, position: 50 },
      {
        label: `<frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
        position: tickThreePosition
      },
      { label: `${endingNumber.toLocaleString()}`, position: 100 }
    ];

    const bottomTickValues = [
      startingNumber === 0
        ? { label: '0', position: 0 }
        : {
            label: `<frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            position: 0
          },
      {
        label: `<frac n='${numeratorC.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
        position: 50
      },
      { label: '?', position: tickThreePosition },
      {
        label: `<frac n='${numeratorD.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
        position: 100
      }
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheImproperFractionIndicatedByTheQuestionMark()}
        pdfTitle={translate.instructions.circleTheImproperFractionIndicatedByTheQuestionMark()}
        testCorrect={[A.value]}
        numItems={3}
        Content={({ dimens }) => (
          <NumberLineTicksTopBottom
            dimens={dimens}
            topTickValues={topTickValues}
            bottomTickValues={bottomTickValues}
          />
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          component: (
            <TextStructure
              sentence={sentence}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: 40, fontWeight: '700' }}
            />
          ),
          value
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aMJ',
  description: 'aMJ',
  keywords: [
    'Mixed number',
    'Improper fraction',
    'Wholes',
    'Parts',
    'Integer',
    'Numerator',
    'Denominator',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(10),
      numeratorA: z.number().int().min(1).max(9),
      numeratorB: z.number().int().min(1).max(9),
      numeratorC: z.number().int().min(1).max(9),
      wholeA: z.number().int().min(1).max(9),
      wholeC: z.number().int().min(1).max(9)
    })
    .refine(val => val.numeratorA !== val.wholeA, 'numeratorA must not be equal to wholeA')
    .refine(
      val => val.wholeC !== val.wholeA && val.wholeC !== val.numeratorC,
      'wholeC must not be equal to wholeA and numeratorC'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 10);
    const [numeratorA, numeratorB, numeratorC] = randomUniqueIntegersInclusive(
      1,
      denominator - 1,
      3
    );

    const wholeA = randomIntegerInclusive(1, 9, {
      constraint: x => x !== numeratorA
    });
    const wholeC = randomIntegerInclusive(1, 9, {
      constraint: x => x !== wholeA && x !== numeratorC
    });

    return {
      denominator,
      numeratorA,
      numeratorB,
      numeratorC,
      wholeA,
      wholeC
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB, numeratorC, wholeA, wholeC },
      translate,
      displayMode
    } = props;

    const numeratorD = wholeA * denominator + numeratorA;
    const numeratorE = numeratorA * denominator + numeratorB;
    const numeratorF = wholeC * denominator + numeratorC;

    const items = [
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorD.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
          />
        ),
        value: 'A'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorE.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
          />
        ),
        value: 'B'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorF.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
          />
        ),
        value: 'C'
      }
    ];

    const sentences = shuffle(
      [
        {
          sentence: `<frac w='${wholeA.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <ans/>`,
          answer: 'A'
        },
        {
          sentence: `<frac w='${numeratorA.toLocaleString()}' n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <ans/>`,
          answer: 'B'
        },
        {
          sentence: `<frac w='${wholeC.toLocaleString()}' n='${numeratorC.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <ans/>`,
          answer: 'C'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );
    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchImproperFractionsToMixedNumber()}
        pdfTitle={translate.instructions.useTheCardsToMatchImproperFractionsToMixedNumber()}
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => [sentence.answer])}
        moveOrCopy="move"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMK',
  description: 'aMK',
  keywords: [
    'Bar model',
    'Parts',
    'Mixed number',
    'Improper fraction',
    'Wholes',
    'Numerator',
    'Denominator',
    'Convert'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(6),
    whole: z.number().int().min(1).max(4),
    numerator: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 6);
    const whole = randomIntegerInclusive(1, 4);
    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { denominator, whole, numerator };
  },
  questionHeight: 1100,
  Component: props => {
    const {
      question: { denominator, whole, numerator },
      translate,
      displayMode
    } = props;

    const numeratorB = whole * denominator + numerator;

    const remainder = numeratorB % denominator;

    const mixedNumber = `<frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}'/>`;

    return (
      <QF3Content
        title={translate.instructions.convertMixedNumberToImproperFractionTapTheBarModelToHelp(
          mixedNumber
        )}
        pdfTitle={translate.instructions.convertMixedNumberToImproperFractionShadeTheBarModelToHelp(
          mixedNumber
        )}
        questionHeight={1100}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <View
              style={{
                display: 'flex',
                rowGap: displayMode === 'digital' ? 32 : 100,
                height: remainder > 0 ? (whole + 1) * 50 : whole * 50 + 40
              }}
            >
              {countRange(whole).map(idx => (
                <ShadedFractionBarModelWithState
                  id={`barmodel-${idx}`}
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width}
                  height={displayMode !== 'digital' ? 80 : 50}
                  testCorrect={userAnswer => userAnswer.length === denominator}
                  defaultState={displayMode === 'markscheme' ? range(0, denominator - 1) : []}
                />
              ))}
              {remainder > 0 && (
                <ShadedFractionBarModelWithState
                  id={`barmodel-remainder`}
                  totalSubSections={denominator}
                  width={dimens.width}
                  height={displayMode !== 'digital' ? 80 : 50}
                  testCorrect={userAnswer => userAnswer.length === remainder}
                  defaultState={displayMode === 'markscheme' ? range(0, remainder - 1) : []}
                />
              )}
            </View>
            <TextStructure
              sentence={`${mixedNumber} = <frac n='${numeratorB}' d='${denominator}' />`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aML',
  description: 'aML',
  keywords: [
    'Improper fraction',
    'Mixed numbers',
    'Convert',
    'Parts',
    'Wholes',
    'Numerators',
    'Denominators',
    'Integer'
  ],
  questionHeight: 900,
  schema: z
    .object({
      denominator1A: z.number().int().min(2).max(5),
      denominator2A: z.number().int().min(6).max(12),
      numerator1A: z.number().int().min(1).max(4),
      numerator2A: z.number().int().min(1).max(11),
      whole1A: z.number().int().min(1).max(5),
      whole2A: z.number().int().min(1).max(5),
      improperFractionLhs: z.boolean()
    })
    .refine(val => val.whole1A !== val.numerator1A, 'whole1A must not be equal to numerator1A'),
  simpleGenerator: () => {
    const denominator1A = randomIntegerInclusive(2, 5);
    const denominator2A = randomIntegerInclusive(6, 12);

    const numerator1A = randomIntegerInclusive(1, denominator1A - 1);
    const numerator2A = randomIntegerInclusive(1, denominator2A - 1);

    const whole1A = randomIntegerInclusive(1, 5, {
      constraint: x => x !== numerator1A
    });
    const whole2A = randomIntegerInclusive(1, 5);

    const improperFractionLhs = getRandomBoolean();

    return {
      denominator1A,
      denominator2A,
      numerator1A,
      numerator2A,
      whole1A,
      whole2A,
      improperFractionLhs
    };
  },
  Component: props => {
    const {
      question: {
        denominator1A,
        denominator2A,
        numerator1A,
        numerator2A,
        whole1A,
        whole2A,
        improperFractionLhs
      },
      translate
    } = props;

    const numerator1B = whole1A * denominator1A + numerator1A;
    const numerator2B = whole2A * denominator2A + numerator2A;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertMixedNumbersToImproperFractions()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numerator1B, denominator1A]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [numerator2B, denominator2A])
        }
        inputMaxCharacters={1}
        sentences={
          improperFractionLhs
            ? [
                `<frac w='${whole1A.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1A.toLocaleString()}' /> = <frac nAns='' dAns='' />`,
                `<frac w='${whole2A.toLocaleString()}' n='${numerator2A.toLocaleString()}' d='${denominator2A.toLocaleString()}' /> = <frac nAns='' dAns='' />`
              ]
            : [
                `<frac nAns='' dAns='' /> = <frac w='${whole1A.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1A.toLocaleString()}' />`,
                `<frac nAns='' dAns='' /> = <frac w='${whole2A.toLocaleString()}' n='${numerator2A.toLocaleString()}' d='${denominator2A.toLocaleString()}' />`
              ]
        }
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator1B.toLocaleString(), denominator1A.toLocaleString()],
            [numerator2B.toLocaleString(), denominator2A.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertMixedNumbersToImproperFractions',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
