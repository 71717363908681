import { z } from 'zod';
import {
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_C,
  ALGEBRAIC_D,
  ALGEBRAIC_L,
  ALGEBRAIC_M,
  ALGEBRAIC_N,
  ALGEBRAIC_W,
  ALGEBRAIC_X,
  ALGEBRAIC_Y,
  ALGEBRAIC_Z
} from '../constants';
import { getRandomFromArray } from './random';

export const algebraicSymbols = [
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_C,
  ALGEBRAIC_D,
  ALGEBRAIC_L,
  ALGEBRAIC_M,
  ALGEBRAIC_N,
  ALGEBRAIC_W,
  ALGEBRAIC_X,
  ALGEBRAIC_Y,
  ALGEBRAIC_Z
] as const;

export const algebraicSymbolSchema = z.enum(algebraicSymbols);

export const smallAlgebraicSymbols = [ALGEBRAIC_A, ALGEBRAIC_C, ALGEBRAIC_N, ALGEBRAIC_X] as const;

export const smallAlgebraicSymbolSchema = z.enum(smallAlgebraicSymbols);

export type AlgebraicSymbols = (typeof algebraicSymbols)[number];

export function getAlgebraicSymbol() {
  return getRandomFromArray(algebraicSymbols);
}

/**
 * getSmallAlgebraicSymbol returns x-height algebraic symbols
 * i.e. no d or y
 */
export function getSmallAlgebraicSymbol() {
  return getRandomFromArray(smallAlgebraicSymbols);
}

const algebraicSymbolPairs = [
  [ALGEBRAIC_A, ALGEBRAIC_B],
  [ALGEBRAIC_C, ALGEBRAIC_D],
  [ALGEBRAIC_M, ALGEBRAIC_N],
  [ALGEBRAIC_X, ALGEBRAIC_Y]
] as const;

export function getAlgebraicSymbolPair() {
  return getRandomFromArray(algebraicSymbolPairs);
}
