import Clock, { ClockWithState } from '../representations/Clock';
import { useContext } from 'react';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { clockColourVariantType } from '../../../utils/time';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * Which variant to use.
   * - Automatic (default): the hour hand automatically moves when moving the minute hand.
   * - Manual: the hour hand may be positioned anywhere within that hour, possibly disagreeing with the minute hand
   * and producing an invalid time. There is also no snapping, so userAnswer's `minutes` and `hourHandMinutes` may be
   * non-integer values.
   */
  variant?: 'manual' | 'automatic';
  testCorrect: (userAnswer: {
    /** IMPORTANT NOTE: always an integer between 0 and 11 inclusive. Never 12!  */
    hours: number;
    /**
     * This is provided when `variant` is 'manual', and undefined otherwise.
     * This is a number >=0 and <60, not necessarily an integer.
     */
    hourHandMinutes?: number;
    /** This is a number >=0 and <60. If variant is `manual`, this can be a non-integer, since there is no snapping. */
    minutes: number;
  }) => boolean;
  exampleCorrectAnswer?: { hours: number; minutes: number };
  isRoman?: boolean;
  startTime?: {
    /** Must be an integer between 0 and 11 inclusive. */
    hours: number;
    /**
     * Allows you to override the hourHand's position within the hour to disagree with the minute hand.
     * Optional: if left blank it will agree with the minute hand.
     * You should only use this with variant: 'manual'.
     * Must be >=0 and <60.
     */
    hourHandMinutes?: number;
    /** Must be >=0 and <60, and if variant is 'automatic' then this must be an integer. */
    minutes: number;
    /*
     * Change snapping to snap to the nearest X minutes. E.g. snapMinutes={5} means that the minute hand snaps to every 5
     * minutes.
     * Optional prop, defaults to 1.
     */
  };
  /**
   * The interval to snap to in minutes.
   * If we don't show the minute intervals, snap to the nearest 5 (unless specified higher)
   */
  snapMinutes?: number;
  /** Make the minute hand undraggable */
  staticMinuteHand?: boolean;
  /** Provide a colour for the outer border of clock */
  clockColourVariant?: clockColourVariantType;
  /**
   * Boolean to remove minute intervals from the clock face. Defaults to false.
   */
  withoutMinuteIntervals?: boolean;
};

/**
 * Set the clock face to match the given time.
 *
 * Note that this has two variants: 'automatic' and 'manual'. See props for details.
 */
export default function QF40SetTheClockFace({
  title,
  pdfTitle,
  variant = 'automatic',
  testCorrect,
  exampleCorrectAnswer,
  isRoman,
  startTime,
  staticMinuteHand = false,
  clockColourVariant = 'yellow',
  withoutMinuteIntervals = false,
  snapMinutes = withoutMinuteIntervals ? 5 : 1
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle}
        mainPanelContents={
          <View style={{ alignItems: 'center' }}>
            <Clock
              time={displayMode === 'markscheme' ? exampleCorrectAnswer : undefined}
              width={500}
              interactive={false}
              showHands={displayMode === 'markscheme'}
              isRoman={isRoman}
              clockColourVariant={clockColourVariant}
              withoutMinuteIntervals={withoutMinuteIntervals}
            />
          </View>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <View style={{ alignItems: 'center' }}>
          <ClockWithState
            id="clock"
            width={500}
            independentHands={variant === 'manual'}
            snapHandsToNearest={variant !== 'manual'}
            snapMinutes={snapMinutes}
            isRoman={isRoman}
            clockColourVariant={clockColourVariant}
            testCorrect={ans => {
              return testCorrect(
                variant === 'manual'
                  ? // Include hourHandMinutes
                    {
                      hours: ans.hours,
                      minutes: ans.minutes,
                      hourHandMinutes: ans.hourHandMinutes ?? ans.minutes
                    }
                  : { hours: ans.hours, minutes: ans.minutes }
              );
            }}
            defaultState={startTime}
            staticMinuteHand={staticMinuteHand}
            withoutMinuteIntervals={withoutMinuteIntervals}
          />
        </View>
      }
    />
  );
}
