import { StyleSheet, View } from 'react-native';
import { useContext } from 'react';
import { Dimens } from 'common/src/theme/scaling';
import { countRange } from 'common/src/utils/collections';
import { HeaderVariant, TableHeaderRow } from './TableHeaderRow';
import { ScientificNotation } from 'common/src/utils/math';
import { colors } from 'common/src/theme/colors';
import { DisplayMode } from '../../../../contexts/displayMode';
import { Scale, SimpleBaseTenWithCrossOut } from '../Base Ten/SimpleBaseTenWithCrossOut';
import Text from '../../../typography/Text';

type Props = {
  /** Operator or string to put left side of the table */
  operator?: string;
  /**
   * Numbers in {@link ScientificNotation} to be represented in each row.
   * Works best for numbers with a maximum of 6 tens and up to 9 ones.
   */
  numbersPerRow: ScientificNotation[];
  numOfRows: number;
  /**
   * Which columns to show, only supports the Ones and Tens columns (due to Base Ten limitation).
   * Important: Must be given in order of the columns.
   */
  columnsToShow: (0 | 1)[];
  dimens: Dimens;
  /**
   * What kind of headers to use.
   * - name: e.g. Hundred Thousands, Hundredths
   * - shortName: e.g. HTh, Hth
   * - number: e.g. 100,000, 0.01
   * Default: name
   */
  headerVariant?: HeaderVariant;
};

/**
 * Multi Row Place Value Chart with optional Operator
 *
 * Only supports Base Ten numbers to a maximum of 99.
 */
export default function MultiRowPlaceValueChartWithOperator({
  numOfRows,
  columnsToShow,
  dimens,
  headerVariant = 'name',
  numbersPerRow,
  operator
}: Props) {
  // Length of numberPerRow must be equal to numOfRows
  if (numbersPerRow.length !== numOfRows) {
    throw new Error('numbersPerRow must have the same length as numOfRows');
  }

  const displayMode = useContext(DisplayMode);
  // Compute styles
  const { width, height } = dimens;
  const usableCellWidth = (width - 75) / columnsToShow.length; // 75 is the width of the space for the operator
  const usableCellHeight = height / numOfRows;

  const styles = getStyles(width - 75, height, displayMode);

  const scale = Scale(usableCellWidth, usableCellHeight, {
    tens: Math.max(...numbersPerRow.map(num => num.digits[0])),
    ones: Math.max(...numbersPerRow.map(num => num.digits[1]))
  });

  const contentZones = (
    <>
      {countRange(numOfRows).map(rowIndex => (
        <View key={rowIndex} style={{ flex: 1, flexDirection: 'row' }}>
          {columnsToShow.map((_, columnIndex) => {
            return (
              <View
                key={`${rowIndex}_${columnIndex}`}
                style={[styles.col, columnIndex === columnsToShow.length - 1 && styles.endCol]}
              >
                <SimpleBaseTenWithCrossOut
                  tens={columnIndex === 0 ? numbersPerRow[rowIndex].digits[0] : undefined}
                  ones={columnIndex === 1 ? numbersPerRow[rowIndex].digits[1] : undefined}
                  dimens={dimens}
                  scale={scale}
                  rotate="90deg"
                />
              </View>
            );
          })}
        </View>
      ))}
    </>
  );

  return (
    <View style={{ flexDirection: 'row' }}>
      {operator && (
        <Text variant="WRN400" style={styles.text}>
          {operator}
        </Text>
      )}
      <View style={{ flexDirection: 'column' }}>
        <View style={styles.tableHeader}>
          <TableHeaderRow
            columnsToShow={columnsToShow}
            headerVariant={headerVariant}
            showDecimalPoint={false}
          />
        </View>
        <View style={styles.row}>{contentZones}</View>
      </View>
    </View>
  );
}

const getStyles = (
  width: number,
  height: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) => {
  const containerWidth = width;
  const containerHeight = height;

  const headerHeight = displayMode === 'digital' ? 56 : 86;
  const contentHeight = containerHeight - headerHeight;

  return StyleSheet.create({
    tableHeader: {
      width: containerWidth,
      height: headerHeight,
      flexDirection: 'row'
    },
    row: {
      height: contentHeight
    },
    col: {
      flexDirection: 'row',
      flex: 1,
      borderStartWidth: 2,
      borderBottomWidth: 2,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      justifyContent: 'center',
      alignItems: 'center'
    },
    endCol: {
      borderRightWidth: 2,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
    },
    text: {
      width: 75,
      fontSize: 50,
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'flex-end',
      marginBottom: (height - headerHeight) / 6
    }
  });
};
