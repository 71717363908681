import {
  ADD,
  DIV,
  MULT,
  SUB,
  APPROX_EQUAL,
  LESS_THAN,
  GREATER_THAN,
  ALGEBRAIC_C,
  ALGEBRAIC_H,
  ALGEBRAIC_S,
  ALGEBRAIC_L,
  ALGEBRAIC_Y,
  ALGEBRAIC_X,
  ALGEBRAIC_P,
  DEGREES,
  ALGEBRAIC_B
} from '../../constants';

export default {
  aBagOfFlourHasMassXABagOfSugarHasMassYCircleGreater:
    'A bag of flour has a mass of {0:number|numberFormat} kg.<br/>A bag of sugar has a mass of {1:number|numberFormat} kg.<br/>Circle the bag that has a greater mass.',
  aBagOfFlourHasMassXABagOfSugarHasMassYCircleSmaller:
    'A bag of flour has a mass of {0:number|numberFormat} kg.<br/>A bag of sugar has a mass of {1:number|numberFormat} kg.<br/>Circle the bag that has a smaller mass.',
  aBagOfFlourHasMassXABagOfSugarHasMassYSelectGreater:
    'A bag of flour has a mass of {0:number|numberFormat} kg.<br/>A bag of sugar has a mass of {1:number|numberFormat} kg.<br/>Select the bag that has a greater mass.',
  aBagOfFlourHasMassXABagOfSugarHasMassYSelectSmaller:
    'A bag of flour has a mass of {0:number|numberFormat} kg.<br/>A bag of sugar has a mass of {1:number|numberFormat} kg.<br/>Select the bag that has a smaller mass.',
  aBallCostsXAndConesCostYEach:
    'A {0:string|lower} costs {1} and cones cost {2} each.<br/>{3} wants to buy {4:number|numberFormat} {5:string|lower} and {6:number|numberFormat} cones for the {7:string|lower} team.<br/>Select the best estimate of the total cost.',
  aBallCostsXAndConesCostYEachPDF:
    'A {0:string|lower} costs {1} and cones cost {2} each.<br/>{3} wants to buy {4:number|numberFormat} {5:string|lower} and {6:number|numberFormat} cones for the {7:string|lower} team.<br/>Circle the best estimate of the total cost.',
  aXOfItemACostsYTimesAsMuchAsBoxOrBagOfItemBTogetherTheyCostZ:
    'A {0:string|lower} of {1:string|lower} costs {2} times as much as a {0:string|lower} of {3:string|lower}.<br/>Together, they cost {4}',
  aBoxHoldsXMultipacksOfWater: 'A box holds {0:number|numberFormat} multipacks of water.',
  aCarParkChargesXPoundForEveryYMinutes:
    'A car park charges £{0:number|numberFormat} for every {1:number|numberFormat} minutes of parking.\nMr Trent parks their car in the car park from {2}:{3} pm to {4}:{5} pm.\nHow much does Mr Trent pay for parking?',
  aCarParkHasXRowsOfYSpacesZRowsAreFull:
    'A car park has {0:number|numberFormat} rows of {1:number|numberFormat} spaces.<br/>{2:number|numberFormat} rows are full and the rest are empty.',
  aCarTravelsNumMilesInNumMinutes:
    'A car travels {0:number|numberFormat} miles in {1:number|numberFormat} minutes.<br/>How far will the car travel in {2:number|numberFormat} hours if it travels at the same speed?',
  aChildsCinemaTicketCostsXPoundsAndPenceAndAnAdultsTicketCostsAPoundsAndPenceHowMuchMoreDoesTheAdultsTicketCost:
    "A child's ticket to the cinema costs {poundsA} and an adult's ticket costs {poundsB}<br/>How much more does an adult's ticket cost than a child's ticket?",
  aCircleIsDividedIntoXPartsWhatIsSizeOfAngle:
    'A circle is divided into {0:number|numberFormat} equal sections.<br/>What is the size of the angle marked {1}?',
  aCoachCanSeatXPeopleHowManyCoachesAreNeeded:
    'A coach can seat {number1:number|numberFormat} people.<br/>{number2:number|numberFormat} supporters are travelling to a {sport:string|lower} match by coach.<br/>How many coaches are needed to transport all the supporters?',
  aCompanyHasNumMembersOfStaff:
    'A company has {0:number|numberFormat} members of staff, {1:number|numberFormat} managers and {2:number|numberFormat} directors.',
  aConcertLastsNumHoursAndNumMinutes:
    'A concert lasts {0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}}.<br/>Move the hands of the analogue clock to show when it finishes.',
  aConcertLastsNumHoursAndNumMinutesDraw:
    'A concert lasts {0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}}. Draw the hands of the analogue clock to show when it finishes.',
  aDogHasAMassOfXKgDragTheApproximateMassOfTheDogInBothPoundsAndStonesAndPounds:
    'A dog has a mass of {0:number|numberFormat} kg.<br/>Drag the cards to approximate the mass of the dog in both pounds and stones and pounds.',
  aDogHasAMassOfXKgUseTheApproximateMassOfTheDogInBothPoundsAndStonesAndPounds:
    'A dog has a mass of {0:number|numberFormat} kg.<br/>Use the cards to approximate the mass of the dog in both pounds and stones and pounds.',
  aFieldIsYLongAndZWidth:
    'A field is {length} m long and {width} m wide.<br/>What is the area of the field?',
  aFactoryMakesXCarsHowManyAreElectric:
    'A factory makes {numberOfCars:number|numberFormat} cars.<br/>{frac} are electric cars.<br/>How many electric cars does the factory make?',
  aFullTenFrameRepresentsOneWholeWhatFractionIsShown:
    'A full ten frame represents 1 whole.<br/>What fraction is shown?',
  aHockeyPitchIsXMlongAndYMWide:
    'A hockey pitch is {0:number|numberFormat}m long and {1:number|numberFormat}m wide.',
  aMultipackOfWaterContainsXBottles:
    'A multipack of water contains {0:number|numberFormat} bottles of water.',
  angleXIsYTimesAngleZWhatIsSizeOfX:
    'Angle {0} is {1} times the size of angle {2}.<br/>What is the size of angle {0}?',
  angleXIsYTimesAngleZWhatIsSizeOfXAndY:
    'Angle {0} is {1} times the size of angle {2}.<br/>What is the size of angle {0} and angle {2}?',
  anInteriorAngleIsMarkedOnEachPolygonCircleGreatest:
    'An interior angle is marked in each polygon.<br/>Circle the shape with the greatest interior angle.',
  anInteriorAngleIsMarkedOnEachPolygonCircleSmallest:
    'An interior angle is marked in each polygon.<br/>Circle the shape with the smallest interior angle.',
  anInteriorAngleIsMarkedOnEachPolygonSelectGreatest:
    'An interior angle is marked in each polygon.<br/>Select the shape with the greatest interior angle.',
  anInteriorAngleIsMarkedOnEachPolygonSelectSmallest:
    'An interior angle is marked in each polygon.<br/>Select the shape with the smallest interior angle.',
  aNumberRoundedToTheNearestTenthIsNum:
    'A number rounded to the nearest tenth is {0:number|numberFormat}<br/>The number has 2 decimal places.<br/>What could the number be?<br/>Find 3 possible answers.',
  aPatternOfShapesIsMadeUsingLollySticksCompleteTheTable:
    'A pattern of shapes is made using lolly sticks.<br/>Complete the table.',
  AQuadrilateralHasVerticesAt: 'A quadrilateral has vertices at',
  aQuarterOfHundredSquareIsShaded: 'A quarter of the hundred square is shaded.',
  aRaceISXLongWithYRunnersWhatsTheTotalDistanceRun:
    'A race is {0}m long.<br/>{1} runners complete the race.<br/>What is the combined total distance run?',
  areaOfTheRectangleIsXcm2: 'The area of the rectangle is {0} cm².',
  aRectangleHasASideLengthOfNumCmAndWidthOfNumCm:
    'A rectangle has a side length of {0:number|numberFormat} cm and a width of {1:number|numberFormat} cm. Select squares on the grid to show a scale diagram of the rectangle.',
  aRectangleHasASideLengthOfNumCmAndWidthOfNumCmPDF:
    'A rectangle has a side length of {0:number|numberFormat} cm and a width of {1:number|numberFormat} cm. Shade squares on the grid to show a scale diagram of the rectangle.',
  aRectilinearShapeIsDrawnOnASquareGridHowManyLengthsDoYouNeedToAddTogetherToWorkOutThePerimeterOfTheShape:
    'A rectilinear shape is drawn on a square grid.<br/>How many side lengths do you need to add together to work out the perimeter of the shape?',
  aRectilinearShapeIsDrawnOnASquareGridWorkOutThePerimeterOfTheShape:
    'A rectilinear shape is drawn on a squared grid.<br/>Work out the perimeter of the shape.',
  aRegularPolygonHasXSidesItsPerimeterIsYmmWhatIsTheLengthOfEachSideOfThePolygon:
    'A regular polygon has {0} sides.<br/>Its perimeter is {1:number|numberFormat} mm.<br/>What is the length of each side of the polygon?',
  aShapeIsDrawnOnGridCirclePointA:
    'A {0} is drawn on a coordinate grid. Circle which coordinates could be point A.',
  aShapeIsDrawnOnGridSelectPointA:
    'A {0} is drawn on a coordinate grid. Select which coordinates could be point A.',
  aShapeIsDrawnOnGridWriteCoordinatesOfOthers:
    'A {0} is drawn on a coordinate grid. Write the coordinates of the other {1} vertices.',
  aShapeIsDrawnOnGridWriteCoordinatesOfXY:
    'A {0} is drawn on a coordinate grid.<br/>Give the coordinates of points {1} and {2}.',
  aShopOrdersXBoxesOfWaterWhatsTheTotalAmountOrdered:
    'A shop orders {0:number|numberFormat} boxes of water.<br/>How many bottles of water have they ordered?',
  aShopSellsTheseItems: 'A shop sells these items.',
  aSquareHasASideLengthOfNumCm:
    'A square has a side length of {0:number|numberFormat} cm. Select squares on the grid to show a scale diagram of the shape.',
  aSquareHasASideLengthOfNumCmPDF:
    'A square has a side length of {0:number|numberFormat} cm. Shade squares on the grid to show a scale diagram of the shape.',
  aStraightLineGoesThroughXAndWhatAreCoordsOfAnotherPoint:
    'A straight line goes through {0} and {1}.<br/>What are the coordinates of another point on the line?',
  aSurveyAsksXPeopleHowManyYSelectNumberOfPeopleWhoOwnZ:
    'A survey asked {0:number|numberFormat} people how many {1: string|lower} they own. Select the number of people who own {2:number|numberFormat} {3: string|lower}.',
  aSurveyAsksXPeopleHowManyYCircleNumberOfPeopleWhoOwnZ:
    'A survey asked {0:number|numberFormat} people how many {1: string|lower} they own. Circle the number of people who own {2:number|numberFormat} {3: string|lower}.',
  aSurveyAsksXPeopleHowManyYSelectFractionOfPeopleWhoOwnZ:
    'A survey asked {0:number|numberFormat} people how many {1: string|lower} they own. Select the fraction of people who own {2} {3: string|lower}.',
  aSurveyAsksXPeopleHowManyYCircleFractionOfPeopleWhoOwnZ:
    'A survey asked {0:number|numberFormat} people how many {1: string|lower} they own. Circle the fraction of people who own {2} {3: string|lower}.',
  atNoonItWasWarmerThan5amButColderThan6pm:
    'At 12 noon, it was warmer than 5am but colder than 6pm.',
  aTrainHasXCarriagesCarryingYPeopleZReservedSeats:
    'A train has {0:number|numberFormat} carriages.<br/>Each carriage can carry {1:number|numberFormat} people.<br/>{2:number|numberFormat} people have reserved a seat.',
  aPatternIsMadeUpOfRegularShapes:
    'A pattern is made up of regular {0} that are all the same size.',
  aPieceOfStringIsNumXMLongHowManyNumYMmPiecesCanBeCut:
    'A piece of string is {0:number|numberFormat} m long.<br/>How many {1:number|numberFormat} mm pieces can be cut from the string?',
  aSheetOfStampsHasNumRowsAndNumCols:
    'A sheet of stamps has {0:number|numberFormat} rows and {1:number|numberFormat} columns of stamps.',
  aThemeParkHasNumLightBulbsNumLightBulbsAreColour:
    'A theme park has {0:number|numberFormat} light bulbs.<br/>{1:number|numberFormat} of the light bulbs are {2:string|lower}.<br/>How many bulbs are not {2:string|lower}?<br/>Use a method where you subtract 1 from each number.',
  aXCostsNumAYCostsNumMoreThanX:
    'A {0:string|lower} costs £{1:number|numberFormat}<br/>A {2:string|lower} costs £{3:number|numberFormat} more than the {0:string|lower}.<br/>How much does the {2:string|lower} cost?',
  aXisYfeetandZinchesTall:
    'A {0} is {1} feet and {2} inches tall.\nWhat is {3} height in inches and in cm?',
  aXItemCostsYPoundsHowMuchDoZOfTheseXItemPluralCost:
    'A {0:string|lower} costs {1}<br/>How much do {2} of these {3:string|lower} cost?',
  aXItemHasAMassOfYKgWhatIsMassOfZOfTheXItemPlural:
    'A {0:string|lower} has a mass of {1} kg.<br/>What is the mass of {2} of the {3:string|lower}?',
  answerTheQuestion: 'Answer the question.',
  areTheFractionsEquivalent: 'Are the fractions equivalent?<br/>Select the correct answer.',
  areTheFractionsEquivalentCircleCorrectAnswer:
    'Are the fractions equivalent?<br/>Circle the correct answer.',
  areTheStatementsTrueOrFalse: 'Are each of the statements true or false?',
  atSummerCampThereAreASportBArtAndCraftCOutwardBoundActivitiesHowManyActivityCombinationsAreThere:
    'At Summer camp there are {0} sport activities, {1} art and craft activities and {2} outward bound activities.<br/><br/>Each child is allowed to choose three activities per day; one sport, one arts and crafts and one outward bound.<br/><br/>How many activity combinations are there?',
  atTheStartOfTheDayNumParcels:
    'At the start of the day, there are {0:number|numberFormat} parcels in a depot.<br/>{1:number|numberFormat} parcels are taken from the depot to be delivered.<br/>{2:number|numberFormat} new parcels arrive at the depot.<br/>How many parcels are in the depot at the end of the day?',
  bakerBakesCookiesAndSellsThem:
    'A baker bakes {0:number|numberFormat} cookies. They sell {1:number|numberFormat} of the cookies. Complete the bar model.',
  barModelShowsRatioCompleteFractions:
    'The bar model shows the ratio {0}<br/>Complete the fractions for the bar model.',
  barModelShowsXIsEqualToYPercent: 'The bar model shows that {0} is equal to {1}%.',
  barModelShowsXIsEqualToYUseBarModelToCompleteConversion:
    'The bar model shows that {0} is equal to {1}.<br/>Use the bar model to complete the conversion.',
  bIsAnEnlargementOfACompleteDimensions:
    'B is an enlargement of A by a scale factor of {0}<br/>Complete the dimensions of the new shape.',
  boyAIsXTimesAsOldAsGirlBoyBIsXMonthsOlderThanGirlTheGirlIsXYearsAndYMonthsOld:
    '{maleNameA} is {timesOld} times as old as {femaleName}.<br/>{maleNameB} is {monthsOldA} months older than {femaleName}.<br/>{femaleName} is {years} years and {monthsOldB} months old.',
  breakTimeStartsAtTimeAm:
    'Break time starts at {0}:{1} am.<br/>It lasts for {2:number|numberFormat} minutes.<br/>What time does break time finish?',
  busATravelsXMilesBusATravelsYMilesFurtherThanBusB:
    'Bus A travels {0:number|numberFormat} miles.<br/>Bus A travels {1:number|numberFormat} miles further than Bus B.<br/>Use the bar model to work out how far the buses travel in total.',
  cafeHasXFlavoursYToppingsWorkOutTotalNumberOfCombinations:
    'A café has {0} flavours of ice cream and {1} choices of toppings.<br/>Work out the total number of combinations.',
  cafeHasXFlavoursYToppingsWorkOutTotalNumberOfCombinationsPDF:
    'A café has {0} flavours of ice cream and {1} choices of toppings.<br/>Work out the total number of combinations. Circle the correct answer.',
  calcAreaOfCompoundShape: 'Calculate the area of the compound shape.',
  calcAreaOfRectangle: 'Calculate the area of the rectangle.',
  calcAreaOfRightAngledTriangle: 'Calculate the area of the right-angled triangle.',
  calcAreaOfShape: 'Calculate the area of the shape.',
  calcAreaOfShapeGiveAnsInSimplestForm:
    'Calculate the area of the shape.<br/>Give your answer in its simplest form.',
  calcAreaOfSquare: 'Calculate the area of the square.',
  calcAreaOfTriangle: 'Calculate the area of the triangle.',
  calcInputsForFunctionMachines: 'Calculate the inputs for the function machines.',
  calcMeanOfNumbers: 'Calculate the mean of the numbers.',
  calcOutputsForFunctionMachines: 'Calculate the outputs for the function machines.',
  calcPerimeterOfRectangle:
    'Work out the perimeter of the rectangle.<br/>Give your answer in its simplest form.',
  calcPerimeterOfShape: 'Calculate the perimeter of the shape.',
  calculateAngleOfX: 'Calculate the size of angle {0}.',
  calculateAngleOfX2dp: 'Calculate the size of angle {0} to 2 decimal places.',
  calculateAreaOfShape: 'Calculate the area of the {0}.',
  calculatePerimeterOfPolygon: 'Calculate the perimeter of the polygon.',
  calculatePlayerTotalScore:
    "{0} is playing a game.\n{0} scores {1} on the first turn.\n{0} scores {2} on the second turn.\nWork out {0}'s total score.",
  calculateValueOf: 'Calculate the value of {0}',
  calculateVolumeOfCube: 'Calculate the volume of the cube.',
  calculateVolumeOfCuboid: 'Calculate the volume of the cuboid.',
  charIsWorkingOutXDivYCompletePartWholeToShowHowCharCouldPartitionX: `{0} is working out {1:number|numberFormat} ${DIV} {2:number|numberFormat}<br/>Complete the part-whole model to show how {0} could partition {1:number|numberFormat}`,
  char1AndChar2AreEatingACake:
    '{0} and {1} are eating a cake.<br/>{0} eats {2} of the cake.<br/>{1} eats {3} of the cake.<br/>What fraction of the cake have they eaten altogether?',
  char1AndChar2ArePlayingAGameChar1EarnsNumPoints:
    '{0} and {1} are playing a game.<br/>{0} earns {2:number|numberFormat} points.<br/>{1} earns {3:number|numberFormat} points.<br/>How many points have they earned altogether?',
  char1AndChar2AreWorkingOutX: '{0} and {1} are working out {2}\nSelect who is correct.',
  char1AndChar2AreWorkingOutXPDF: '{0} and {1} are working out {2}\nCircle who is correct.',
  char1AndChar2EachHaveAPieceOfRibbon: '{0} and {1} each have a piece of ribbon.',
  char1AndChar2HeightDifference:
    '{0} is {2} m tall and {1} is {3} m tall.<br/>Work out the difference in their heights.',
  char1AndChar2ShareABoxOfStickers:
    '{0} and {1} share a box of stickers.<br/>{0} gets {2} of the stickers.<br/>What fraction of the stickers does {1} get?',
  char1CountsIn5sChar2CountsIn10s:
    '{0} counts up in 5s from zero. {1} counts up in 10s from zero. Which numbers do they both say?',
  char1HasNumStickersChar2HasNumStickersHowManyAltogether:
    '{0} has {1:number|numberFormat} stickers.<br/>{2} has {3:number|numberFormat} stickers.<br/>How many stickers do they have altogether?',
  char1IsXFootYInchesTallChar2IsZcmTallCharIsTallerOrShorterThanChar:
    '{0} is {1:number|numberFormat} feet {2:number|numberFormat} inches tall.<br/>{3} is {4:number|numberFormat}cm tall.<br/>{5} is {6:string|lower} than {7}.',
  charCyclesNumKmInMonth1AndNumKmInMonth2:
    '{0} cycles {1:number|numberFormat} km in {2} and {3:number|numberFormat} km in {4}.<br/>How much further does {0} cycle in {2}?',
  charHasNumPoundCharSpendsNumPoundsOnANewBike:
    '{0} has £{1:number|numberFormat}<br/>{0} spends £{2:number|numberFormat} on a new bike.<br/>How much money does {0} have left?',
  charHasXLYMlOfJuiceHowMuchIsInBottleNow:
    '{0} has {1} l {2} ml of juice in a bottle.<br/>{0} pours some of the juice into a jug.<br/>How much juice is in the bottle now?',
  charHasXTShirtsAndYJacketsWhoHasMoreChoicesOfCombinations:
    '{name} has {tShirts} different T-shirts and {jackets} different jackets.',
  charHasSeveralIdenticalRectangles: '{0} has several identical rectangles.',
  charMakeXTurnCircleHowManyDegreesCharHasTurnedThrough:
    '{0} makes a {1}.<br/>Circle how many degrees {0} has turned through.',
  charMakeXTurnSelectHowManyDegreesCharHasTurnedThrough:
    '{0} makes a {1}.<br/>Select how many degrees {0} has turned through.',
  charMixesFracOfALitreOfOrangeJuice:
    '{0} mixes {1} of a litre of orange juice with {2} of a litre of lemonade.<br/>{0} pours the drink into {3} glasses equally.<br/>How much is in each glass?<br/>Give your answer in its simplest form.',
  characterAAndCharacterBEachHaveAFractionOfXCounters:
    '{0} and {1} each have a fraction of {2} counters.',
  characterAGivesXStrawsToCharacterBAndYStrawsToCharacterCWhatFractionOfStrawsDoesCharacterAHaveLeft:
    '{characterA} gives {strawsX} straw{{strawsX:s}} to {characterB} and {strawsY} straw{{strawsY:s}} to {characterC}.<br/>What fraction of the straws does {characterA} have left?',
  characterAHasXCharacterBHasYCharacterCHasZPTheyPutTheirMoneyTogetherThenShare:
    '{nameA} has {poundsA}, {nameB} has {poundsB} and {nameC} has {poundsC}<br/>They put their money together, then share it out evenly.<br/>How much money do they each have now?',
  characterAHasXInBankCharacterAHasYMoreThanCharacterB:
    '{nameA} has £{nameAMoney:number|numberFormat} in their bank account.<br/>{nameA} has £{difference:number|numberFormat} more than {nameB}.<br/>Use the bar model to work out how much money they have in total.',
  characterAhasXAndCharacterBHasYCharacterCHasMore:
    '{nameA} has £{nameAMoney} and {nameB} has {nameBMoney}p. <br/>{nameC} has more money than {nameA} but less than {nameB}.',
  characterAIsXTallCharacterBIsYMShorterOrTallerThanCharAHowTallIsCharBGiveYourAnswerInMetres:
    '{0} is {1:number|numberFormat} cm tall.<br/>{2} is {3:number|numberFormat} m {4:string|lower} than {0}.<br/>How tall is {2}?<br/>Give your answers in metres.',
  characterAtCenterFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNow:
    '{0} is standing in the center. {0} is facing the {1:string|lower} and turns {2}° anti-clockwise. Select what {0} is facing now.',
  characterAtCenterFacingXTurnsYDegsClockwiseWhatAreTheyFacingNow:
    '{0} is standing in the center. {0} is facing the {1:string|lower} and turns {2}° clockwise. Select what {0} is facing now.',
  characterAtCenterFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNowPDF:
    '{0} is standing in the center. {0} is facing the {1:string|lower} and turns {2}° anti-clockwise. Circle what {0} is facing now.',
  characterAtCenterFacingXTurnsYDegsClockwiseWhatAreTheyFacingNowPDF:
    '{0} is standing in the center. {0} is facing the {1:string|lower} and turns {2}° clockwise. Circle what {0} is facing now.',
  characterAtPointFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNow:
    '{0} is standing at point {1} and is facing the {2:string|lower}. {0} turns {3} degrees anti-clockwise. Select what {0} is facing now.',
  characterAtPointFacingXTurnsYDegsClockwiseWhatAreTheyFacingNow:
    '{0} is standing at point {1} and is facing the {2:string|lower}. {0} turns {3} degrees clockwise. Select what {0} is facing now.',
  characterAtPointFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNowPDF:
    '{0} is standing at point {1} and is facing the {2:string|lower}. {0} turns {3} degrees anti-clockwise. Circle what {0} is facing now.',
  characterAtPointFacingXTurnsYDegsClockwiseWhatAreTheyFacingNowPDF:
    '{0} is standing at point {1} and is facing the {2:string|lower}. {0} turns {3} degrees clockwise. Circle what {0} is facing now.',
  characterBuysFruitXandFruitYForXAndYPenceCharacterPaysWithXPoundsHowMuchChangeDoesCharacterGet:
    '{name} buys {fruit1:string|lower} for {penceA}p and {fruit2:string|lower} for {penceB}p.<br/>{name} pays with a £{pounds} coin.<br/>How much change does {name} get?',
  characterBuysAHotChocolateForXPoundsAndYPenceHowMuchChangeDoesCharacterGet:
    '{character} buys a hot chocolate for £{pounds} and {pence}p.<br/>{character} pays with a £5 note.<br/>How much change does {character} get?',
  characterBuysAItemXAndAItemYCharacterPaysWithZPoundsNote:
    '{name} buys a {item1} and a {item2}.<br/>{name} pays with a £{poundsNote} note.',
  characterBuysAMilkshakeAndPaysWithA5PoundNoteHowMuchDoesTheMilkshakeCost:
    '{character} buys a milkshake.<br/>{character} pays with a £5 note.<br/>{character} gets £{pounds} and {pence}p change.<br/>How much does the milkshake cost?',
  characterBuysATrainTicketAndABusTicketCharacterPaysWithATenPoundNoteHowMuchChangeDoesCharacterGet:
    'A train ticket costs £{trainCostInPounds} and {trainCostInPence}p.<br/>A bus ticket costs £{busCostInPounds} and {busCostInPence}p.<br/>{character} buys a train ticket and a bus ticket.<br/>{character} pays with a £10 note.<br/>How much change does {character} get?',
  characterBuysASnackAndADrinkFromAVendingMachineWithNumSnacksAndNumDrinksCompleteCalc:
    '{0} buys a snack and a drink from a vending machine which has {1:number|numberFormat} snacks and {2:number|numberFormat} drinks.<br/>Complete the calculation to show how many possible combinations {0} has.',
  characterBuysTwoItemsCompleteSentencesToShowHowMuchMoneyCharacterSpends:
    '{0} buys two items.<br/>Complete the number sentences to show how much money {0} spends.',
  characterBuysXAndYWhatIsTheTotalCost:
    '{0} buys {1:string|lower} and {2:string|lower}.<br/>What is the total cost?',
  characterCanMakeATotalOfNumDiffOutfits:
    '{0} can make a total of {1:number|numberFormat} different outfits.',
  characterChoosesAPieceOfFruitAndADrink: '{0} chooses a piece of fruit and a drink.',
  characterCountsUpIn2sFromZero:
    '{0} counts up in 2s from zero.<br/>Select the numbers would {0} say.',
  characterCountsUpIn2sFromZeroPDF:
    '{0} counts up in 2s from zero.<br/>Circle the numbers {0} will say.',
  characterCountsUpIn50sFromZero: '{0} counts up in 50s from zero.\nWhich numbers would {0} say?',
  characterCountsUpIn100sFromZero: '{0} counts up in 100s from zero.\nWhich numbers will {0} say?',
  characterCountsUpIn1000sFromZero:
    '{0} counts up in 1,000s from zero.\nWhich numbers would {0} say?',
  characterDescribesTheirBirthday: '{0} describes their birthday.\nSelect the correct date.',
  characterDescribesTheirBirthdayPDF: '{0} describes their birthday.\nCircle the correct date.',
  characterDoesNumSitupsEveryDay:
    '{0} does {1:number|numberFormat} sit-ups every day for a year.<br/>{2} does {3:number|numberFormat} sit-ups every day in {4}.<br/>How many sit-ups do they each do?',
  characterEatsFractionOfChocolateBarWhatFractionIsLeft:
    '{character} has {fracA} of a chocolate bar.<br/>{character} eats {fracB} of the chocolate bar.<br/>What fraction of the chocolate bar does {character} have left?',
  characterEarnsXWalkingDogCircleMoneyGiven:
    '{character} earns {amount} for walking the dog.<br/>Circle the money that {character} is given.',
  characterEarnsXWalkingDogSelectMoneyGiven:
    '{character} earns {amount} for walking the dog.<br/>Select the money that {character} is given.',
  characterFacingXAndMakesFullTurnWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a full turn.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesFullTurnWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a full turn.<br/>Circle what {0} is facing now.',
  characterFacingXAndMakesQuaterTurnClockwiseWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a quarter turn clockwise.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesQuaterTurnClockwiseWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a quarter turn clockwise.<br/>Circle what {0} is facing now.',
  characterFacingXAndMakesThreeQuaterTurnClockwiseWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a three quater turn clockwise.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesThreeQuaterTurnClockwiseWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a three quarter turn clockwise.<br/>Circle what {0} is facing now.',
  characterFacingXAndMakesThreeQuaterTurnAntiClockwiseWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a three quarter turn anti-clockwise.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesThreeQuaterTurnAntiClockwiseWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a three quarter turn anti-clockwise.<br/>Circle what {0} is facing now.',
  characterFacingXAndMakesQuaterTurnAntiClockwiseWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a quarter turn anti-clockwise.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesQuaterTurnAntiClockwiseWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a quarter turn anti-clockwise.<br/>Circle what {0} is facing now.',
  characterFacingXAndMakesHalfTurnWhatAreTheyFacingNow:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a half turn.<br/>Select what {0} is facing now.',
  characterFacingXAndMakesHalfTurnWhatAreTheyFacingNowPDF:
    '{0} is facing the {1: string|lower}.<br/>{0} makes a half turn.<br/>Circle what {0} is facing now.',
  characterGetsBusFromXToYTheyArriveAtYatZSelectTheTimeTheyGotOnTheBus:
    '{character} gets a bus from the {placeA:string|lower} to the {placeB:string|lower}. They arrive at the {placeB:string|lower} at {time}. Select the time they got on the bus.',
  characterGetsMoneyForBirthday: '{character} gets money for their birthday.',
  characterGetsToTheCinemaAtQuarterPast:
    '{0} gets to the cinema at quarter past {1} in the evening. Here are the film times. Select the earliest showing that {0} can go to.',
  characterGetsToTheCinemaAtQuarterTo:
    '{0} gets to the cinema at quarter to {1} in the evening. Here are the film times. Select the earliest showing that {0} can go to.',
  characterGoesToSchoolForSixHoursADay:
    '{0} goes to school for 6 hours each day.\nThe month of {1} starts on a {2}.\nThere are no school holidays in the month of {1}.\nHow many hours could {0} spend at school in {1}?',
  characterHas1000PoundsToSpend:
    '{0} has £1,000 to spend.\n{0} buys a {1:string|lower}, a {2:string|lower} and a {3:string|lower}.',
  characterHasABagOfXSweetsHowManySweetsDoesCharacterHaveLeft:
    '{characterA} has a bag of {amountOfSweetsA} sweets.<br />{characterA} eats {fracA} of the sweets.<br />{characterA} gives {fracB} of the sweets that are left to {characterB} and {amountOfSweetsB} sweets to their mum.<br />How many sweets does {characterA} have left?',
  characterHasABundleOfTenStrawsCharacterSaysThatThisBundleRepresentsOneWhole:
    '{character} has a bundle of 10 straws.<br/>{character} says that this bundle represents 1 whole.',
  characterHasAPacketOfNumCards:
    '{0} has a packet of {1:number|numberFormat} cards.<br/>{0} gives {2:number|numberFormat} cards to {3}.',
  characterHasMadeTheNumberX: '{0} has made the number {1:number|numberFormat}',
  characterHasNumXAndNumY:
    '{0} has {1:number|numberFormat} {2:string|lower} and {3:number|numberFormat} {4:string|lower}.',
  characterChoosesNumCoinsWhatGreatestSmallestMoney:
    '{character} chooses {num} coins.<br/>What is the {minmax:string|lower} amount of money {character} could have?',
  characterHasNumObjects:
    '{name} has {num1:number|numberFormat} {objectPlural:string|lower}. {pronoun} puts them {preposition:string|lower} {num2:number|numberFormat} {containerPlural:string|lower}. How many {objectPlural:string|lower} are {preposition:string|lower} each {containerSingular:string|lower}?',
  characterHasNumGroupsCharPutsNumObjects:
    '{name} has {num1:number|numberFormat} {containerPlural:string|lower}.<br/>{name} puts {num2:number|numberFormat} {objectPlural:string|lower} {preposition:string|lower} each {containerSingular:string|lower}.<br/>How many {objectPlural:string|lower} are there?<br/>Complete the multiplication.',
  characterHasNumObjectsAndSomeGroups:
    '{name} has {num1:number|numberFormat} {objectPlural:string|lower} and some {containerPlural:string|lower}.<br/>{name} puts {num2:number|numberFormat} {objectPlural:string|lower} {preposition:string|lower} each {containerSingular:string|lower}.<br/>How many {containerPlural:string|lower} are there?',
  characterHasNumObjectsAndSomeGroupsCompleteDivision:
    '{name} has {num1:number|numberFormat} {objectPlural:string|lower} and some {containerPlural:string|lower}.<br/>{name} puts {num2:number|numberFormat} {objectPlural:string|lower} {preposition:string|lower} each {containerSingular:string|lower}.<br/>How many {containerPlural:string|lower} are there?<br/>Complete the division.',
  characterHasXObjectsAndSharesBetweenYJars:
    '{name} has {dividend:number|numberFormat} {object}.<br/>{pronoun} shares them between {divisor:number|numberFormat} jars.<br/>How many {object} are in each jar?',
  characterHasNumObjectsAndSomeGroupsHowManyDoesCharNeed:
    '{name} has {num1:number|numberFormat} {objectPlural:string|lower} and some {containerPlural:string|lower}.<br/>{name} puts {num2:number|numberFormat} {objectPlural:string|lower} {preposition:string|lower} each {containerSingular:string|lower}.<br/>How many {containerPlural:string|lower} does {name} need?',
  characterHasNumObjectsCharPutsThemIntoContainersWithNumEach:
    '{name} has {total:number|numberFormat} {objectPlural:string|lower}.<br/>{name} puts them {movementPreposition:string|lower} {containerPlural:string|lower} with {amountPerGroup:number|numberFormat} {preposition:string|lower} each.<br/>How many {containerPlural:string|lower} does {name} need?',
  characterHasNumPoundsEveryDayCharacterSpendsNumP:
    '{0} has £{1}\nEvery day {0} spends {2:number|numberFormat}p\nHow many days will it be until {0} runs out of money?',
  characterHasPartitionedFracInDifferentWaysSelectAdditionsEqualToX:
    "{0} has partitioned <frac n='{1}' d='100'/> in different ways.<br/>Select the additions that are equal to <frac n='{1}' d='100'/>",
  characterHasPartitionedFracInDifferentWaysCircleAdditionsEqualToX:
    "{0} has partitioned <frac n='{1}' d='100'/> in different ways.<br/>Circle the additions that are equal to <frac n='{1}' d='100'/>",
  characterHasPutSomeLengthsInOrderWhatCouldTheMissingMeasurementBe:
    '{character} has put some lengths in order.<br/>What could the missing measurement be?',
  characterHasThisMoney: '{name} has this money.',
  characterHasSavedXinYPenceCoinsHowManyYPenceCoinsDoesCharacterHave:
    '{character} has saved £{number1:number|numberFormat} in {number2:number|numberFormat}p coins.<br/>How many {number2:number|numberFormat}p coins does {character} have?',
  characterHasSavedXinYPenceGivenOneMoreCoinHowManyYPenceCoinsDoesCharacterHaveNow:
    '{character} has £{pounds:number|numberFormat}.{pence:number|numberFormat}<br/>{character} is given one more coin.<br/>How much money could {character} have now?',
  characterHasTwoPilesOfCoinsHowManyDifferentCombinations:
    '{0} has two piles of coins.<br/>{0} chooses one coin from each pile.<br/>How many different combinations of coins are there?',
  characterHasTwoPilesOfCoinsHowManyDifferentCombinationsPDF:
    '{0} has two piles of coins. {0} chooses one coin from each pile.<br/>How many different combinations of coins are there?<br/>Circle the correct answer.',
  characterHasWorkedOutXUsingMethod: '{0} has worked out {1} using this method.',
  characterHasXObjectsPutsInYContainersHowManyContainersNeeded:
    '{0} has {1 :number|numberFormat} {2:string|lower}.<br/>{0} puts {3 :number|numberFormat} {2:string|lower} {4:string|lower} each {5:string|lower}.<br/>How many {6:string|lower} does {0} need?',
  characterHasXFlowersAndSomeVasesAVaseCanHoldYFlowers:
    '{0} has {1:number|numberFormat} flowers and some vases.<br/>A vase can hold {2:number|numberFormat} flowers.<br/>How many vases can {0} fill?<br/>Complete the division.',
  characterWorksOutAddition: `{name} works out {leftNum} ${ADD} {rightNum} = {result}\nWhat calculation can {name} do to check it is correct?`,
  characterWorksOutSubtraction: `{name} works out {leftNum} ${SUB} {rightNum} = {result}\nWhat calculation can {name} do to check it is correct?`,
  characterHasWorkedOutConversion:
    '{name} cycles for {num1} miles on one day and then {num2} km on the following day.<br/>How many km does {name} cycle altogether?',
  characterHas2IngredientsCharacterCanMakeXFoodItem:
    '{character} has {amount1:number|numberFormat} {unit1:string|lower} of {ingredient1:string|lower} and {amount2:number|numberFormat} {unit2:string|lower} of {ingredient2:string|lower}.<br/>{character} can make {foodItemAmount:number|numberFormat} {foodItem:string|lower}.',
  characterHas3IngredientsCharacterCanMakeXFoodItem:
    '{character} has {amount1:number|numberFormat} {unit1:string|lower} of {ingredient1:string|lower}, {amount2:number|numberFormat} {unit2:string|lower} of {ingredient2:string|lower} and {amount3:number|numberFormat} {unit3:string|lower} of {ingredient3:string|lower}.<br/>{character} can make {foodItemAmount:number|numberFormat} {foodItem:string|lower}.',
  characterHasPouredNumFullBagsOfSandIntoContainerEstimateCapacityOfOneBag:
    '{0} has poured {1:number|numberFormat} full bags of sand into the container.<br/>Estimate the capacity of one of the bags.',
  characterHasTheseCoins: '{name} has these coins.',
  characterHasXBottlesOfYContainingZ:
    '{name} has {numBottles:number|numberFormat} bottles of {drink}.\nEach bottle contains a {portion} of a litre.',
  characterHasWPoundsAndXPenceTheyBuyXItemForYPoundsAndZPenceHowMuchDoesCharHaveLeft:
    '{0} has £{1:number|numberFormat} and {2:number|numberFormat}p.<br/>{0} buys a {3:string|lower} for £{4:number|numberFormat} and {5:number|numberFormat}p.<br/>How much money does {0} have left?',
  characterHasXPoundsAndYPenceCharacterPutsMoneyInMoneyBox:
    '{0} has £{1:number|numberFormat} and {2:number|numberFormat}p.<br/>{0} puts £{3:number|numberFormat} and {4:number|numberFormat}p into a money box.<br/>How much does {0} have left?',
  characterHasXPoundsToBuyBreakfastCircleTheBreakfastItemThatCharacterBuys:
    '{character} has £{poundsA:number|numberFormat} to buy breakfast.<br/>{character} gets £{poundsB:number|numberFormat} and {pence:number|numberFormat}p change.<br/>Circle the breakfast item that {character} buys.',
  characterHasXPoundsToBuyBreakfastSelectTheBreakfastItemThatCharacterBuys:
    '{character} has £{poundsA:number|numberFormat} to buy breakfast.<br/>{character} gets £{poundsB:number|numberFormat} and {pence:number|numberFormat}p change.<br/>Select the breakfast item that {character} buys.',
  characterHasXRibbonCutsOffYHowMuchLeftMixedNumber:
    '{0} has {1} m of ribbon.<br/>{0} cuts off {2} m of the ribbon and gives it to {3}.<br/>How much ribbon does {0} have left?<br/>Give your answer as a mixed number.',
  characterHasXRibbonCutsOffYHowMuchLeftImproperFraction:
    '{0} has {1} m of ribbon.<br/>{0} cuts off {2} m to give to {3}.<br/>How much ribbon does {0} have left?<br/>Give your answer as an improper fraction.',
  characterHasThisMoneySelectTheAmountsThatShowHowMuchMoneyCharacterHasGot: `{character} has this money. Select the amounts that show how much money {character} has got.`,
  characterHasThisMoneySelectTheAmountsThatShowHowMuchMoneyCharacterHasGotPdf: `{character} has this money. Circle the amounts that show how much money {character} has got.`,
  characterIsBakingACake:
    '{0} is baking a cake and it needs to be in the oven for {1:number|numberFormat} minutes. Move the hands on the analogue clock to show when {0} should take the cake out of the oven.',
  characterIsBakingACakePDF:
    '{0} is baking a cake and it needs to be in the oven for {1:number|numberFormat} minutes. Draw the hands on the analogue clock to show when {0} should take the cake out of the oven.',
  characterIsBuyingSweetsThatCostXPenceEach: '{name} is buying sweets that cost {pence}p each.',
  characterIsBuyingSweetsThatCostXPenceEachCharHasYPence:
    '{name} is buying sweets that cost {pence}p each.<br/>{name} has {totalPence}p.',
  characterIsCalculatingXCircleCharactersFirstStep:
    "{0} is calculating {1}<br/>Circle {0}'s first step.",
  characterIsCalculatingXSelectCharactersFirstStep:
    "{0} is calculating {1}<br/>Select {0}'s first step.",
  characterIsChoosingAShapeCardAndADigitCardHowManyPossCombos:
    '{0} is choosing a shape card and a digit card.<br/>How many possible combinations are there?',
  characterIsChoosingASnackAndADrinkHowManyPossCombos:
    '{0} is choosing a snack and a drink.<br/>How many possible combinations are there?',
  characterIsChoosingAnIceCreamCharacterChoosesOneFlavourAndOneSauce:
    '{0} is choosing an ice cream.<br/>{0} chooses one flavour of ice cream and one sauce.<br/>There are {1:number|numberFormat} ice cream flavours.<br/>There are {2:number|numberFormat} possible combinations of ice cream and sauce.<br/>How many sauces are there?',
  characterIsCountingForwardsInXMissingFraction:
    '{0} is counting forwards in {1}.<br/>Which fraction is missing?',
  characterIsCountingBackwardsInXMissingFraction:
    '{0} is counting backwards in {1}.<br/>Which fraction is missing?',
  characterIsCountingForwardsInXFromYCircleNumbers:
    '{0} is counting forwards in {1} from {2}<br/>Circle the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYCircleNumbers:
    '{0} is counting backwards in {1} from {2}<br/>Circle the numbers that {0} will say.',
  characterIsCountingForwardsInXFromYSelectNumbers:
    '{0} is counting forwards in {1} from {2}<br/>Select the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYSelectNumbers:
    '{0} is counting backwards in {1} from {2}<br/>Select the numbers that {0} will say.',
  characterIsDividingNumBy4ByHalving:
    '{0} is dividing {1:number|numberFormat} by 4 by halving it and halving it again.<br/>Use this method to complete the sentences.',
  characterIsFacingXTheyMakeATurnAndFaceYCircleTurns:
    '{0} is facing {1}.<br/>{0} makes a turn and is now facing {2}.<br/>Circle the turns that {0} could have made.',
  characterIsFacingXTheyMakeATurnAndFaceYSelectTurns:
    '{0} is facing {1}.<br/>{0} makes a turn and is now facing {2}.<br/>Select the turns that {0} could have made.',
  characterIsFracWayThroughRaceCharHasRanXHowLongIsTheRace:
    '{name} is {frac} of the way through a race.<br/>{name} has run {distance}m so far.<br/>How long is the race?',
  characterIsGettingTheBusIntoTown:
    '{0} is getting the bus into town.<br/>Buses start running at {1}:{2} am.<br/>They arrive every {3} minutes.<br/>{0} gets to the bus stop at {4} minutes past {5} in the morning.<br/>How long will {0} wait until the next bus comes?',
  characterIsLabellingFracOnANumberLine: '{name} is labelling fractions on a number line.',
  characterIsMakingFlags: '{0} is making flags.',
  characterIsMaking3DShapeUsingStrawsAndMallows:
    '{0} is making 3-D shapes using straws and marshmallows.',
  characterIsPartitioningAmountsToWorkOutTheTotalOf5Pound42And7Pound39:
    '{0} is partitioning amounts to work out the total of £5.42 and £7.39',
  characterIsPlayingMarblesWithFriends:
    '{0} is playing a game of marbles with {1:number|numberFormat} friends.<br/>They have {2:number|numberFormat} bags of marbles.<br/>Each bag contains {3:number|numberFormat} marbles.<br/>How many marbles do they each get if the marbles are shared equally between them?',
  characterIsReadingABook:
    '{0} is reading a book.<br/>{0} reads {1:number|numberFormat} pages.<br/>The next day {0} reads {2:number|numberFormat} pages.<br/>The book has {3:number|numberFormat} pages.',
  characterIsThinkingOfAFraction: '{0} is thinking of a fraction.',
  characterIsUsingWeightsToMakeMassEquivalentTo1Kg:
    '{0} is using the weights to make a mass equivalent to 1 kg. How many more grams does {0} need?',
  characterIsXFeetYInchesTall:
    '{0} is {1:number|numberFormat} feet {2:number|numberFormat} inches tall.',
  characterLeavesHomeAtNumMinutesPastNum:
    "{0} leaves home at {1:number|numberFormat} minutes past {2:number|numberFormat} in the afternoon.<br/>It takes {3:number|numberFormat} hours and {4:number|numberFormat} minutes to get to {0}'s friend's house. <br/>Write the time {0} arrives in 12-hour digital format.",
  characterLeavesHomeAtNumMinutesToNum:
    "{0} leaves home at {1:number|numberFormat} minutes to {2:number|numberFormat} in the afternoon.<br/>It takes {3:number|numberFormat} hours and {4:number|numberFormat} minutes to get to {0}'s friend's house. <br/>Write the time {0} arrives in 12-hour digital format.",
  characterMakes3DShapeCircleCubesCuboids:
    '{0} has been making some 3-D shapes.<br/>Circle the shapes that are {1}.',
  characterMakes3DShapeSelectCubesCuboids:
    '{0} has been making some 3-D shapes.<br/>Select the shapes that are {1}.',
  characterMakes3DShapeUsingStrawsAndMallowsHowManyEdges:
    '{0} makes a {1} using some straws and marshmallows.<br/>How many edges does the shape have?',
  characterMakes3DShapeUsingStrawsAndMallowsHowManyVertices:
    '{0} makes a {1} using some straws and marshmallows.<br/>How many vertices does the shape have?',
  characterPartitionsXintoYZFindDifferentWay:
    '{0} partitions {1} into {2} and {3}<br/>Find a different way to partition this number.',
  characterSpendsFracOfMoneyAndSavesTheRestWhatPercentageIsSaved:
    '{character} spends {frac} of the money and saves the rest.<br/>What percentage of money does {character} save?',
  characterSpendsFracOfMoneyCharacterHasTheseCoinsLeft:
    '{character} spends {frac} of their money.<br/>{character} has these coins left.',
  characterSpendsPocketMoney:
    '{0} spends {1} of their pocket money on a present for a friend. {0} gives {2} of their pocket money to charity. What fraction of their pocket money does {0} have left?<br/>Give your answer in its simplest form.',
  characterStampsTheFaceOfA3DShapeOntoASheetOfPaperCircleTheShapeItCouldHaveBeen:
    '{0} stamps the face of a 3-D shape onto a sheet of paper.<br/>Circle the {1:string|lower} it could have been.',
  characterStampsTheFaceOfA3DShapeOntoASheetOfPaperSelectTheShapeItCouldHaveBeen:
    '{0} stamps the face of a 3-D shape onto a sheet of paper.<br/>Select the {1:string|lower} it could have been.',
  characterStartsWatchingACartoonAtThisTime: '{0} starts watching a cartoon at this time.',
  characterStartsWatchingAFilmAtTime:
    '{0} starts watching a film at {1:number|numberFormat}:{2} pm.\nThe film last for {3:number|numberFormat} minutes.\nStraight after, {0} does some homework.\n{0} finishes at {4:number|numberFormat} pm.\nHow long does {0} spend doing homework?',
  characterThinksArrowIsPointingToFrac: '{0} thinks the arrow is pointing to {1}',
  characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeCircleTheEarliestTimeTheyWillArriveInPlaceX:
    '{character} is travelling to {placeA}. They arrive at {placeB} train station at {time}.<br/>Circle the earliest time that they will arrive in {placeA}.',
  characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeSelectTheEarliestTimeTheyWillArriveInPlaceX:
    '{character} is travelling to {placeA}. They arrive at {placeB} train station at {time}.<br/>Select the earliest time that they will arrive in {placeA}.',
  characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeSelectTheTimeTheyWillArriveInPlaceX:
    '{character} is travelling to {placeA}. They arrive at {placeB} train station at {time}. Select the time that they will arrive in {placeA}.',
  characterTravelsFracFromHouseToSchoolHowFarInTotal:
    '{character} travels {frac} of the way from their house to school. {character} travels {numberOfMiles} miles. How far is it in total?',
  characterUsesCubesToEstimateVolumeOf3DShapeCircle:
    '{0} uses cubes to estimate the volume of a 3-D shape.<br/>Circle the 3-D shape that {0} is estimating.',
  characterUsesCubesToEstimateVolumeOf3DShapeSelect:
    '{0} uses cubes to estimate the volume of a 3-D shape.<br/>Select the 3-D shape that {0} is estimating.',
  characterUsesCubesToEstimateVolumeOfShapeCircle:
    '{0} uses cubes to estimate the volume of a {1:string|lower}.<br/>Circle the representation that will give {0} the best estimate.',
  characterUsesCubesToEstimateVolumeOfShapeEachCubeHasVolOf1Cm3:
    '{0} uses cubes to estimate the volume of a {1:string|lower}.<br/>Each cube has a volume of 1 cm³<br/>Estimate the volume of the {1:string|lower}.',
  characterUsesCubesToEstimateVolumeOfShapeSelect:
    '{0} uses cubes to estimate the volume of a {1:string|lower}.<br/>Select the representation that will give {0} the best estimate.',
  characterUsesFactorPairToWorkOutMultiplication:
    '{0} uses a factor pair to work out a multiplication.\nWhat multiplication could {0} have been working out?',
  characterUsesFactorPairToWorkOutMultiplicationWriteTwoMultiplications:
    '{0} uses a factor pair to work out a multiplication.<br/>Write two different multiplications that {0} could have been working out.',
  characterUsesLollySticksToMakePolygonWorkOutPerim:
    '{0} uses lolly sticks to make a polygon.<br/>Each stick is {1:number|numberFormat} cm long.<br/>Work out the perimeter of the shape.',
  characterUsesXAndYToMakeANetCircle3dShape:
    '{0} uses {1} and {2} to make a net.<br/>Circle the 3-D shape that this net would make.',
  characterUsesXAndYToMakeANetSelect3dShape:
    '{0} uses {1} and {2} to make a net.<br/>Select the 3-D shape that this net would make.',
  characterWantsToWatchAFilmAtTime:
    '{0} wants to watch a film at {1}:{2} pm.<br/>The film lasts {3:number|numberFormat} hour{{s}} and {4:number|numberFormat} minutes.<br/>Work out what time the film will finish.',
  characterWentToTheParkOnSaturday: '{0} went to the park on Saturday.',
  characterXIsSellingTicketsForSchoolPlayThereAreYTickets:
    '{0} is selling tickets for the school play.<br/>There are {1} tickets.',
  charactersAreDoingAPuzzle:
    '{0}, {1} and {2} are doing a puzzle.\nSelect who did it the quickest.',
  charactersAreDoingAPuzzlePDF:
    '{0}, {1} and {2} are doing a puzzle.\nCircle who did it the quickest.',
  charactersAreTellingTheTime:
    '{0}, {1} and {2} are telling the time shown on the digital clock.\nSelect who is correct.',
  charactersAreTellingTheTimePDF:
    '{0}, {1} and {2} are telling the time shown on the digital clock.\nCircle who is correct.',
  charactersDogEatsFracTinsOfFood:
    "{0}'s dog eats {1} tins of food a week.<br/>How many tins does {0}'s dog eat in a year?",
  charactersRunARace: '{0}, {1}, {2} and {3} run a race. Select who won the race.',
  charactersRunARacePDF: '{0}, {1}, {2} and {3} run a race.<br/>Circle who won the race.',
  charactersRunARaceHowMuchFaster:
    '{0} runs a race in {1} minutes.\n{2} runs the race in {3} seconds.\nWhat is the difference between their times?',
  charactersSubjectLessonStartsAtTime:
    "{0}'s {1} lesson starts at {2}:{3} pm.<br/>The {1} lesson lasts for {4:number|numberFormat} minutes.<br/>What time does the lesson end?",
  characterReadXPagesOfBookEveryNightForYWeeks:
    '{0} reads {1:number|numberFormat} pages of a book every night for {2:number|numberFormat} weeks.<br/>How many pages does {0} read in total?',
  chooseCalcToRepresentation: 'Choose the calculation that matches the representation.',
  circle100p: 'Circle 100p',
  circle2DShapesYouWouldNeedToMakeX:
    'Circle the 2-D shape{{0:s}} that would be needed to make the net for a {1}.',
  circle3DShapeThisNetMakes: 'Circle the 3-D shape this net will make.',
  circle3DShapeXIsThinkingOf: 'Circle the 3-D shape {0} is thinking of.',
  circle3DShapeWithGreaterVolume: 'Circle the 3-D shape with the greater volume.',
  circle3DShapeWithSmallerVolume: 'Circle the 3-D shape with the smaller volume.',
  circleActivityThatApproximatelyTakesX:
    'Circle the activity that takes approximately {0} to complete.',
  circleActivityThatTakeLessThan:
    'Circle the activity that approximately take less than {0} to complete.',
  circleActivityThatTakesLessTime: 'Circle the activity that takes less time.',
  circleActivityThatTakesLonger: 'Circle the activity that takes longer.',
  circleAcuteAngle: 'Circle the acute angle.',
  circleAllAcuteAngles: 'Circle all the acute angles.',
  circleAllNumbersLessThanNum: 'Circle all the numbers that are less than {0}',
  circleAllNumbersGreaterThanNum: 'Circle all the numbers that are greater than {0}',
  circleAllQuadrilaterals: 'Circle all the quadrilaterals.',
  circleAngleThatIsApproxNumDegrees: `Circle the angle that is approximately {0:number|numberFormat}${DEGREES}.`,
  circleApproxVolumeOfObject: 'Circle the approximate volume of the object.',
  circleAreaOfShape: 'Circle the area of the {0}.',
  circleArrowAfterTurn: 'Circle what the arrow will look like after the turn.',
  circleAllObtuseAngles: 'Circle all the obtuse angles.',
  circleAllOfTheLinesThatAreParallelToA: 'Circle all of the lines that are parallel to A.',
  circleAllOfTheLinesThatArePerpendicularToA:
    'Circle all of the lines that are perpendicular to A.',
  circleAllOfTheLinesThatAreNotParallelToA: 'Circle all of the lines that are not parallel to A.',
  circleAllOfTheLinesThatAreNotPerpendicularToA:
    'Circle all of the lines that are not perpendicular to A.',
  circleAllRegularPolygons: 'Circle all the regular polygons.',
  circleAreaOfRectilinearShape: 'Circle the area of the rectilinear shape.',
  circleBarModelsWherePercentShadedIn:
    'Circle the bar model{{0:s}} where {1:number|numberFormat}% is shaded in.',
  circleBeakersThatHaveTotalOfXLitresAndYMl:
    'Circle the set of beakers that show a total of {0:number|numberFormat} l and {1:number|numberFormat} ml.',
  circleCalEquivalentToX: 'Circle the calculation equivalent to {0}',
  circleCalsEquivalentToX: 'Circle the calculations equivalent to {0}',
  circleCalcThatWillBreakWhole: 'Circle the calculation that will break the whole.',
  circleCalcWithGreaterAnswer: 'Circle the calculation with the greater answer.',
  circleCalcWithSmallerAnswer: 'Circle the calculation with the smaller answer.',
  circleCalcsThatCanBeUsedToCheckCalc:
    'Circle the calculations that can be used to check <g>{0}</g>',
  circleCalcsThatEqualX: 'Circle the calculations that are equal to {0}',
  circleCalcsThatHaveAnAnswerOfNum:
    'Circle the calculations that have an answer of {0:number|numberFormat}',
  circleCardThatShowsXWrittenInWords:
    'Circle the card that shows {0:number|numberFormat} written in words.',
  circleCalcsWithAnswerGreaterThanAnsToX: `Circle the calculations with an answer greater than the answer to <g>{0}</g>`,
  circleCalcsWithAnswerLessThanAnsToX: `Circle the calculations with an answer less than the answer to <g>{0}</g>`,
  circleCharacterWithTheMostCounters: 'Circle the character with the most counters.',
  circleClocksThatShowXMinutesPastYInTheAfternoon:
    'Circle the clocks that show {0:number|numberFormat} minutes past {1} in the afternoon.',
  circleClocksThatShowXMinutesPastYInTheEvening:
    'Circle the clocks that show {0:number|numberFormat} minutes past {1} in the evening.',
  circleClocksThatShowXMinutesPastYInTheMorning:
    'Circle the clocks that show {0:number|numberFormat} minutes past {1} in the morning.',
  circleClocksThatShowXMinutesToYInTheAfternoon:
    'Circle the clocks that show {0:number|numberFormat} minutes to {1} in the afternoon.',
  circleClocksThatShowXMinutesToYInTheEvening:
    'Circle the clocks that show {0:number|numberFormat} minutes to {1} in the evening.',
  circleClocksThatShowXMinutesToYInTheMorning:
    'Circle the clocks that show {0:number|numberFormat} minutes to {1} in the morning.',
  circleColAddsWhichAreLaidOutCorrectly:
    'Circle the column additions which are laid out correctly.',
  circleCoordOfPoint: 'Circle the coordinates of the point on the coordinate grid.',
  circleCorrectAnswerToMultuseThePVCToHelp: `Circle the correct answer to {0:number|numberFormat} ${MULT} {1:number|numberFormat}<br/>Use the place value chart to help.`,
  circleCorrectAnswerToXLookAtOnesDigitToHelpYou:
    'Circle the correct answer to {0}<br/>Look at the ones digit to help you.',
  circleCorrectPerimeterOfPolygon: 'Circle the correct perimeter of the polygon.',
  circleCorrectPerimeterOfRegularPolygon: 'Circle the correct perimeter of the regular polygon.',
  circleCubeNumber: 'Circle the cube number.',
  circleCuboidWithGreaterVolume: 'Circle the cuboid with the greater volume.',
  circleCuboidWithSmallerVolume: 'Circle the cuboid with the smaller volume.',
  circleDayOnWhichLeastXSold: 'Circle the day on which the least {0:string|lower} were sold.',
  circleDayOnWhichMostXSold: 'Circle the day on which the most {0:string|lower} were sold.',
  circleDecadeThatGreatestPopulationIncrease:
    'Circle the decade that had the greatest population increase.',
  circleDecadeThatSmallestPopulationIncrease:
    'Circle the decade that had the smallest population increase.',
  circleDescriptionsThatMatchX: 'Circle the descriptions that match {0}',
  circleDecimalEquivalentForFraction: 'Circle the decimal equivalent for the fraction.',
  circleApproxDistanceTravelledAfterXMinutes:
    'Circle the approximate distance travelled by the car after {0: number|numberFormat} minute{{s}}.',
  circleDiagonalLinesOfSymmetry: 'Circle the diagonal lines of symmetry.',
  circleDiagramShowsCorrectReflectionInGivenMirrorLine:
    'Circle the diagram that shows the correct reflection in the given mirror line.',
  circleDivisionsThatProduceARemainder: 'Circle the divisions that produce a remainder.',
  circleDriestMonthInX: 'Circle the driest month in {0}.',
  circleEqualAngles: 'Circle the equal angles.',
  circleEquationRepresentedByCubes: 'Circle the equation represented by the cubes.',
  circleFactorsOfX: 'Circle the numbers that are factors of {0}',
  circleFractionsEquivalentToX: 'Circle the fractions equivalent to {0}',
  circleGattegnotoShowNum: 'Circle the numbers on the Gattegno chart to show {0}',
  circleGreaterNumber: 'Circle the greater number.',
  circleGreaterVolume: 'Circle the greater volume.',
  circleGreatestAngle: 'Circle the greatest angle.',
  circleGreatestAngleInDiagram: 'Circle the greatest angle in the diagram.',
  circleGreatestMass: 'Circle the greatest mass.',
  circleGreatestNumber: 'Circle the greatest number.',
  circleHorizontalLineOfSymmetry: 'Circle the horizontal line of symmetry.',
  circleHowManyCarsInCarParkAreColour: 'Circle how many cars in the car park are {0:string|lower}.',
  circleHowManyCarsThereAreInTotal: 'Circle how many cars there are in total.',
  circleHowManyChildrenHaveNumPets:
    'Circle how many children have {0:number|numberFormat} pet{{s}}.',
  circleHowManyMoreCarsAreXThanY:
    'Circle how many more cars are {0:string|lower} than {1:string|lower}.',
  circleHowManyXWereSoldOnDay: 'Circle how many {0:string|lower} were sold on {1}.',
  circleIfStatementIsTrueOrFalse: 'Circle if the statement is true or false.',
  circleIfStatementIsTrueOrFalseAreaOfParallelogramIsXMultY: `Circle if the statement is true or false.<br/>The area of the parallelogram is {0:number|numberFormat} ${MULT} {1:number|numberFormat} = {2:number|numberFormat} cm²`,
  circleIslandThatHasGreaterEstimatedArea: 'Circle the island that has the greater estimated area.',
  circleIslandThatHasSmallerEstimatedArea: 'Circle the island that has the smaller estimated area.',
  circleImageThatShows: 'Circle the image that shows {0}.',
  circleImagesThatShow: 'Circle the images that show {0}.',
  circleInverseCalculation: 'Circle the inverse calculation to {0}',
  circleJugsThatWouldHaveTotalCapactityOfXMl:
    'Circle jugs that would have a total capactity of {0:number|numberFormat} ml.',
  circleJugsWithTotalCapacityOfXL:
    'Circle jugs with a total capacity of {0:number|numberFormat} l. ',
  circleJugShowsXOfWater: 'Circle the jug that shows {0} of a litre of water.',
  circleLabelForPartOfCircleArrowIsPointingTo:
    'Circle the label for the part of the circle the arrow is pointing to.',
  circleThe24HourClockTimesThatAreAmOrPm: 'Circle the 24-hour clock times that are {0}.',
  circleThe3DShapeThatHasXCubes: 'Circle the 3-D shape that has a volume of {0} cubes.',
  circleTheAdditionsWhichWillNeedMoreThanOneExchange:
    'Circle the additions which will need more than one exchange.',
  circleTheCorrectCalculationToWorkOutTheAreaOfTheTriangle:
    'Circle the correct calculation to work out the area of the triangle.',
  circleTheBoxThatShowsX: 'Circle the box that shows {0:number|numberFormat}',
  circleTheHundredSquareThatRepresentsFrac:
    "Circle the hundred square(s) that represents <frac n='{0}' d='{1}' />",
  circleTheNameOfTheShape: 'Circle the name of the shape.',
  circleThePrimeNumber: 'Circle the prime number.',
  circleThePrimeNumbers: 'Circle the prime numbers.',
  circleTheSingleStepThatGivesOutput: 'Circle the single step that would give the same output.',
  circleTheXPopularMethodOfGettingToSchoolForKeyStageY: `Circle the {0:string|lower} popular method of getting to school for key stage {1:number|numberFormat}.`,
  circleTheXPopularMethodOfGettingToSchoolForYearY: `Circle the {0:string|lower} popular method of getting to school for Year {1:number|numberFormat}`,
  circleTwoLinesThatAreParallel: 'Circle two lines that are parallel.',
  circleTwoLinesThatArePerpendicular: 'Circle two lines that are perpendicular.',
  circleLengthsThatAreGreaterThanNumUnits:
    'Circle the lengths that are greater than {0:number|numberFormat} {1}',
  circleLengthsThatAreLessThanNumUnits:
    'Circle the lengths that are less than {0:number|numberFormat} {1}',
  circleLineThatIsHorizontal: 'Circle the line that is horizontal.',
  circleLineThatIsVertical: 'Circle the line that is vertical.',
  circleLinesThatAreNotParallel: 'Circle the pair of lines that are <b>not</b> parallel.',
  circleLinesThatArePerpendicular: 'Circle the perpendicular lines.',
  circleLongerDuration: 'Circle the longer duration.',
  circleMeasuringContainerThatHasLeastLiquid:
    'Circle the measuring container that has the least liquid.',
  circleMeasuringContainerThatHasMostLiquid:
    'Circle the measuring container that has the most liquid.',
  circleMethodsThatWouldGiveCorrectAnswer: 'Circle the methods that would give the correct answer.',
  circleMissingPartCompletePartWholeModel:
    'Circle the missing part to complete the part-whole model.',
  circleMissingValue: 'Circle the missing value.',
  circleNameOfPolygon: 'Circle the name of the polygon.',
  circleNetOfX: 'Circle the net that would make a {0}.',
  circleNetThatMakesX: 'Circle the net{{0:s}} that will make a {1}.',
  circleNotesAndCoinsToMakeXPoundsAndYPence:
    'Circle the notes and coins to make £{0:number|numberFormat} and {1:number|numberFormat}p',
  circleNumberSentencesThatAreEqualToX: 'Circle the number sentences that are equal to<br/>{0}',
  circleNumberThatIsGreater: 'Circle the number that is greater.',
  circleNumberThatIsSmaller: 'Circle the number that is smaller.',
  circleNumberWhichHasXinTheHundredthsPosition:
    'Circle the number which has {0:number|numberFormat} in the hundredths position.',
  circleNumberWhichHasXinTheTenthsPosition:
    'Circle the number which has {0:number|numberFormat} in the tenths position.',
  circleNumbersGreaterOrLessThanAHalf: 'Circle the numbers that are {0} than a half.',
  circleNumbersThatAreDivisibleByNum:
    'Circle the numbers that are divisible by {0:number|numberFormat}',
  circleNumbersThatAreGreaterThanNum:
    'Circle the numbers that are greater than {0:number|numberFormat}',
  circleNumbersThatAreSmallerThanNum:
    'Circle the numbers that are smaller than {0:number|numberFormat}',
  circleNumbersThatCanBeDividedEquallyByNum:
    'Circle the numbers that can be divided equally by {0:number|numberFormat}',
  circleNumbersThatHaveNumAsCommonFactor:
    'Circle the numbers that have {0:number|numberFormat} as a common factor.',
  circleNumbersThatHaveXInTheYPlace:
    'Circle the numbers that have {0:number|numberFormat} in the {1: string|lower} place.',
  circleNumbersThatRoundToNumber:
    'Circle the numbers that round to {0:number|numberFormat} when rounded to the nearest {1:number|numberFormat}',
  circleObjectThatHoldsLeastLiquid: 'Circle the object that holds the least liquid.',
  circleObjectThatHoldsMostLiquid: 'Circle the object that holds the most liquid.',
  circleObjectWithGreaterCapacity: 'Circle the object with the greater capacity.',
  circleObjectWithTheGreatestMass: 'Circle the object with the greatest mass.',
  circleObjectWithSmallerCapacity: 'Circle the object with the smaller capacity.',
  circleObjectWithTheSmallestMass: 'Circle the object with the smallest mass.',
  circleObtuseAngle: 'Circle the obtuse angle.',
  circleOptionThatShowsAllFactorsOfX: 'Circle the option that shows all the factors of {0}',
  circlePairOfCoordsThatLieOnL:
    'Circle the pair{{0:s}} of coordinates that lie on the straight line L.',
  circlePairsOfAnglesVerticallyOpposite: 'Circle the pairs of angles that are vertically opposite.',
  circlePairsOfCorrectValuesForXAndY: 'Circle the pairs of correct values for {0} and {1}.',
  circlePercentageOfHundredSquareShaded:
    'Circle the percentage of the hundred square that is shaded.',
  circlePercentageShownByXOnHundredSquare:
    'Circle the percentage shown by {0} on the hundred square.',
  circlePerimeterOfShape: 'Circle the perimeter of the shape.',
  circlePictureShowsNumWords: 'Circle the picture that shows {0}?',
  circlePictureThatShowsXDIVYAsAGroupingProblem: `Circle the picture that shows {0:number|numberFormat} ${DIV} {1:number|numberFormat} as a <b>grouping</b> problem.`,
  circlePictureThatShowsXDIVYAsASharingProblem: `Circle the picture that shows {0:number|numberFormat} ${DIV} {1:number|numberFormat} as a <b>sharing</b> problem.`,
  circlePicturesThatShowAtLeastOneAngle: 'Circle the pictures that show at least one angle.',
  circlePicturesThatShowOnlyOneRightAngle: 'Circle the pictures that show only one right angle.',
  circlePlaceValueChartRepresentsXTimesY: `Circle the place value chart that represents {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  circleRectilinearShape: 'Circle the rectilinear shape.',
  circleRectilinearShapeWithAreaOfNumSquares:
    'Circle the rectilinear shape with an area of {0:number|numberFormat} squares.',
  circleRegularPolygon: 'Circle the regular polygon.',
  circleRepresentationThatMatchesMultiplication:
    'Circle the representation that matches the multiplication.',
  circleScaleDrawingOfObject: 'Circle the scale drawing of the {0:string|lower}.',
  circleShapeAtCoordinateXY: 'Circle the shape at the coordinates ({0}, {1})',
  circleShapeDescribed: 'Circle the shape that is being described.',
  circleShapeThatHasAnAreaOfXcm2: 'Circle the shape that has an area of {0} cm²',
  circleShapeThatHasPerimeterOfX: 'Circle the shape that has a perimeter of {0}.',
  circleShapeThatHasTheSmallerPerimeter: 'Circle the shape that has the smaller perimeter.',
  circleShapeThatHasTheGreaterPerimeter: 'Circle the shape that has the greater perimeter.',
  circleShapeWithSmallerVolume: 'Circle the shape with the smaller volume.',
  circleShapeWithSmallestPerimeter: 'Circle the shape that has the smallest perimeter.',
  circleShapeWithGreaterVolume: 'Circle the shape with the greater volume.',
  circleShapeWithGreatestPerimeter: 'Circle the shape that has the greatest perimeter.',
  circleShapeWithTheSmallerOrGreaterArea: 'Circle the shape with the {0} area.',
  circleShapesOfXCubes: 'Circle the shapes that have been made with {0:number|numberFormat} cubes.',
  circleShapesThatAreRegularPolygons: 'Circle the shapes that are regular polygons.',
  circleShapeThatHasBeenSplitIntoEqualParts:
    'Circle the shape that has been split into equal parts.',
  circleShapesThatHaveBeenSplitIntoEqualParts:
    'Circle the shapes that have been split into equal parts.',
  circleShapeThatHasBeenSplitIntoNumEqualParts:
    'Circle the shape that has been split into {0:number|numberFormat} equal parts.',
  circleShapesThatHaveBeenSplitIntoNumEqualParts:
    'Circle the shapes that have been split into {0:number|numberFormat} equal parts.',
  circleShapeThatHasXShaded: 'Circle the shape that has {0} shaded.',
  circleShapesThatHaveXShaded: 'Circle the shapes that have {0} shaded.',
  circleShapesWithAtLeastOneSymmetry: 'Circle the shapes that have at least one line of symmetry.',
  circleShapesWithBothHorizontalVeritcalLineOfSymmetry:
    'Circle the shapes that have a horizontal <b>and</b> a vertical line of symmetry.',
  circleShapesWithCorrectSymmetry: 'Circle the shapes that show a correct line of symmetry.',
  circleShapesWithDiagonalLineOfSymmetry:
    'Circle the shapes that have a diagonal line of symmetry.',
  circleShapesWithHorizontalLineOfSymmetry:
    'Circle the shapes that have a horizontal line of symmetry.',
  circleShapesWithNoSymmetry: 'Circle the shapes that do not have any lines of symmetry.',
  circleShapesWithVeritcalLineOfSymmetry:
    'Circle the shapes that have a vertical line of symmetry.',
  circleShapesWithXRightAngles:
    'Circle the shapes with only {0: number|numberFormat} right angle{{s}}.',
  circleRepresentationThatMatchesSubtraction:
    'Circle the representation that matches the subtraction.',
  circleSimilarShape: 'Circle the similar shape.',
  circleSmallerNumber: 'Circle the smaller number.',
  circleSmallerVolume: 'Circle the smaller volume.',
  circleSmallestAngle: 'Circle the smallest angle.',
  circleSmallestAngleInDiagram: 'Circle the smallest angle in the diagram.',
  circleSmallestMass: 'Circle the smallest mass.',
  circleSmallestNumber: 'Circle the smallest number.',
  circleTheAngleThatIsApproxXDeg:
    'Circle the angle that is approximately {0:number|numberFormat}°.',
  circleSumOfAngles: 'Circle the sum of the angles shown in the diagram.',
  circleTheCalculationEquivalentToXMultY: `Circle the calculation equivalent to {0: number|numberFormat} ${MULT} {1: number|numberFormat}`,
  circleTheCalcsThatGiveSameAnswer: 'Circle the calculations that give the same answer.',
  circleTheCoordinateGridWithThePointXY: 'Circle the coordinate grid with the point ({0},{1})',
  circleTheCorrectLengthOfTheLine: 'Circle the correct length of the line.',
  circleTheCorrectWordToCompleteSentence: 'Circle the correct word to complete the sentence.',
  circleTheEarliestTime: 'Circle the earliest time.',
  circleTheXAndYEquivalentToZ: `Circle the {0:string|lower} and {1:string|lower} equivalent to {2}`,
  circleTheXAndYEquivalentToZFrac: `Circle the {0:string|lower} and {1:string|lower} equivalent to <frac n='{2}' d='{3}' />`,
  circleTheFactorsOfX: 'Circle the factors of {0}',
  circleTheJugThatShowsVolumeXMl:
    'Circle the jug that shows the volume of {0: number|numberFormat} ml.',
  circleTheLatestTime: 'Circle the latest time.',
  circleTheReflexAngle: 'Circle the reflex angle.',
  circleTimeShownOnDigitalClock: 'Circle the time shown on the digital clock.',
  circleWhetherAngleIsAcuteObtuseRightAngle:
    'Circle whether the angle is acute, obtuse or a right angle.',
  circleWhetherLineIsHorizontalVerticalNeither:
    'Circle whether the line is horizontal, vertical or neither.',
  circleWhetherTheStatementIsTrueOrFalse: `Circle whether the statement is true or false.`,
  circleWhetherXStatementIsTrueOrFalse: `Circle whether {0}'s statement is true or false.`,
  circleCorrectAnswer: 'Circle the correct answer.',
  circleCorrectAnswerAddition: 'Circle the correct answer to the addition.',
  circleStatementCardsThatAreEquiv: 'Circle the statement cards that are equivalent.',
  circleStatementsThatAreTrue: 'Circle the statements that are true.',
  circleTheAdditionsThatNeedAnExchange: 'Circle the additions that need an exchange.',
  circleTheAdditionsThatWillNeedAnExchange: 'Circle the additions that will need an exchange.',
  circleTheAlgebraicExpressionThatRepresentsThePerimeterOfTheShape:
    'Circle the algebraic expression that represents the perimeter of the shape.',
  circleTheAmountsThatShowOnePound: 'Circle the amounts that show £1',
  circleTheAnglesThatAreXThanARightAngle:
    'Circle the angles that are {0:string|lower} than a right angle.',
  circleTheBarModelThatShowsAFractionEquivalentTo:
    'Circle the bar model that shows a fraction equivalent to {0}',
  circleTheColumnAdditionThatIsLaidOutCorrectly:
    'Circle the column addition that is laid out correctly.',
  circleTheColumnSubtractionThatIsLaidOutCorrectly:
    'Circle the column subtraction that is laid out correctly.',
  circleTheCoordinateGridWithThePointXYMarked:
    'Circle the coordinate grid with the point ({0},{1}) marked.',
  circleTheCorrectAnswerTo: 'Circle the correct answer to {0}',
  circleTheCorrectPartWholeModelForNum: 'Circle the correct part-whole model for the number {0}',
  circleTheDayThatShowsWhenHeightOfPlantReachedXHeightCm:
    'Circle the day that shows when the height of the plant reached {0:number|numberFormat} cm.',
  circleTheFractionEquivalentToX: 'Circle the fraction equivalent to {0}',
  circleTheFractionsThatAreGreaterThanOrEqualToPercentage:
    'Circle the fractions that are greater than or equal to {0}%.',
  circleTheImproperFractionIndicatedByTheQuestionMark:
    'Circle the improper fraction indicated by the question mark.',
  circleTheImproperFractionShownByTheDiagram: 'Circle the improper fraction shown by the diagram.',
  circleTheImproperFractionsThatAreEquivalentToAnInteger:
    'Circle the improper fractions that are equivalent to an integer.',
  circleTheMathematicalNameOfTheShape: 'Circle the mathematical name of the shape.',
  circleTheMeasuresOfX: 'Circle the measures of {0:string|lower}.',
  circleTheMissingEquivalentFraction: 'Circle the missing equivalent fraction.',
  circleTheMultiplesOfX: 'Circle the multiples of {0:number|numberFormat}',
  circleTheMixedNumberThatIsShownByTheBarModel:
    'Circle the mixed number that is shown by the bar model.',
  circleTheNumberLineThatIsCountingInFrac: 'Circle the number line that is counting in {0}s',
  circleTheShapeThatHasOrHaveXAmountYAngles:
    'Circle the shape{{0:s}} that {1:string|lower} {2:number|numberFormat} {3:string|lower}{{4:s}}.',
  circleTheTimeThatShowsWhenTemperatureIsXTemperatureCelsius:
    'Circle the time that shows when the temperature is {0:string|lower} °C.',
  circleTheTimesOnTheClockThatAreInTheAfternoon:
    'Circle the times on the clocks that are in the afternoon.',
  circleTheTimesOnTheClockThatAreInTheMorning:
    'Circle the times on the clocks that are in the morning.',
  circleTheWordToCompleteTheSentence: 'Circle the word to complete the sentence.',
  circleTrianglesThatHaveXAcuteAngles:
    'Circle the triangles that have {0:number|numberFormat} acute angle{{s}}.',
  circleTrianglesThatHaveXObtuseAngles:
    'Circle the triangles that have {0:number|numberFormat} obtuse angle{{s}}.',
  circleTwelveHoursClockTime: 'Circle the 12-hour clock time for {0}',
  circleTypeOfTriangle: 'Circle the type of triangle.',
  circleMathematicalNameQuadrilateral: 'Circle the mathematical name of the quadrilateral.',
  circleValueOfXinY: 'Circle the value of the {0} in {1}',
  circleTheXWeightObject: 'Circle the {0:string|lower} object.',
  circleTheShapeThatHasMissingLengthX:
    'Circle the shape that has the missing length of {0: number|numberFormat} cm',
  circleTheShapesThatHaveAtLeastOnePairOfX:
    'Circle the shapes that have at least one pair of {0:string|lower} lines.',
  circleTheShapeThatIsX: 'Circle the shape that is {0:string|lower}.',
  circleTheShapeThatHaveAPerimeter: 'Circle the shape that has a perimeter.',
  circleTheShapesThatHaveAPerimeter: 'Circle the shapes that have a perimeter.',
  circleTheShapeWithCorrectXLineOfSymmetry:
    'Circle the shape that has a correct {0} line of symmetry.',
  circleTheShapeWithPerimeterX: 'Circle the shape with a perimeter of {0}',
  circleTheSquareNumber: 'Circle the square number.',
  circleTheSubtractionsThatNeedAnExchange: 'Circle the subtractions that need an exchange.',
  circleTheSubtractionsThatWillNeedAnExchange:
    'Circle the subtractions that will need an exchange.',
  circleTheStationsThatTheXTrainDoesNotStopAt:
    'Circle the stations that the {0} train does not stop at.',
  circleTheSubtractionsThatWillNeedMoreThanOneExchange:
    'Circle the subtractions that will need more than one exchange.',
  circleTimeWouldBeShownIfMinuteHandWasPointingToX:
    'Circle the time if the minute hand was pointing to {0}',
  circleVerticalLineOfSymmetry: 'Circle the vertical line of symmetry.',
  circleWettestMonthInX: 'Circle the wettest month in {0}.',
  circleWhichAreMultiplesOf: 'Circle the numbers which are multiples of {0: number|numberFormat}',
  circleWhichBusStopTheBusLeavesFromAtX:
    'Where does the {0:string|lower} bus depart from?<br/>Circle your answer.',
  circleWhichCalculationToEstimateAdd: `Circle which calculation you would use to estimate {0} ${ADD} {1}`,
  circleWhichCalculationToEstimateSubtract: `Circle which calculation you would use to estimate {0} ${SUB} {1}`,
  circleWhichChildDidTheBest:
    'Two children are playing a game.<br/>Circle which child did the best.',
  circleWhichNumberIsClosestToX: 'Circle which number is closest to {0}',
  circleWhichOfTheseAreCubes: 'Circle which of these shapes are cubes.',
  circleWhichPlaceValueChartRepresentsX: 'Circle which place value chart represents {0}',
  circleWhichRoundToXToNearestY:
    'Circle the numbers which round to {0:number|numberFormat} when rounded to the nearest {1:number|numberFormat}',
  circleTriangleThatIsAReflectionOfTriangleA:
    'Circle the triangle that is a reflection of triangle A.',
  circleWhichTrianglesAreTranslationsOfEachOther:
    'Circle which triangles are translations of each other.',
  circleYourAnswer: 'Circle your answer.',
  circleYourAnswers: 'Circle your answers.',
  circleXAngle: 'Circle the {0:number|numberFormat}° angle.',
  coinsAreOnATable: 'These coins are on a table.',
  compoundShapeIsMadeOfTwoRectanglesABWhatIsAreaOfCompoundShape:
    'A compound shape is made up of two rectangles, <g>A and B.</g> What is the area of the compound shape?',
  compassXIsFacingYTheyTurnSelectWhatDirectionNow:
    '{0} is facing {1}.<br/>{0} turns a {2} {3}.<br/>Select what direction {0} is facing now.',
  compassXIsFacingYTheyTurnCircleWhatDirectionNow:
    '{0} is facing {1}.<br/>{0} turns a {2} {3}.<br/>Circle what direction {0} is facing now.',
  completeAddition: 'Complete the addition.',
  completeAdditions: 'Complete the additions.',
  completeAdditionsGiveTwoDifferentWays: 'Complete the additions. Give two different ways.',
  completeAdditionConvertImproperAndMixedFractionsSimplest:
    'Complete the addition.<br/>Give your answer as an improper fraction and a mixed number in their simplest forms.',
  completeAdditionUseBarModelToHelp: 'Complete the addition.<br/>Use the bar model to help.',
  completeBarModel: 'Complete the bar model.',
  completeBarModels: 'Complete the bar models.',
  completeAdditionGiveAnswerAsImproperAndMixedFractions:
    'Complete the addition.<br/>Give your answer as an improper fraction and a mixed number.',
  completeBarModelGiveAnswerInSimplestForm:
    'Complete the bar model.\nGive your answer in its simplest form.',
  completeCalculation: 'Complete the calculation.',
  completeCalculationAnswerInSimplestForm:
    'Complete the calculation.\nGive your answer in its simplest form.',
  completeCalculationsShownOn10Frame: 'Complete the calculations shown on the 10 frame.',
  completeCalculations: 'Complete the calculations.',
  completeCalculationsToSolveX: 'Complete the calculations to solve {0}',
  completeColumnAddition: 'Complete the column addition.',
  completeColumnSubtraction: 'Complete the column subtraction.',
  completeConversion: 'Complete the conversion.',
  completeConversions: 'Complete the conversions.',
  completeDiagram: 'Complete the diagram.',
  completeDivision: 'Complete the division.',
  completeDivisionSentences: 'Complete the division sentences.',
  completeDivisionUsingFactors: 'Complete the division using factors.',
  completeDivisions: 'Complete the divisions.',
  completeEachCalculationToWorkOutX: 'Complete each calculation to work out {0}',
  completeEachFractionToEqualXWhole: 'Complete each fraction so that it is equal to {0} whole.',
  completeEquationRepresentedByCubes: 'Complete the equation represented by the cubes.',
  completeEquivalentFractionOnNumberLine: 'Complete the equivalent fraction on the number line.',
  completeEquivalentFraction: 'Complete the equivalent fraction.',
  completeEquivalentFractions: 'Complete the equivalent fractions.',
  completeEquivalentFractionsAndDecimal: 'Complete the equivalent fractions and decimal.',
  completeFactorBug: 'Complete the factor bug.',
  completeFractionAsDivision: 'Complete the fractions as divisions.',
  completeFractionShownOnNumberLine: 'Complete the fraction shown on the number line.',
  completeFunctionMachine: 'Complete the function machine.',
  completeFunctionMachineToWorkOut: 'Complete the function machine to work out {0}',
  completeShapeBySelectingADot:
    'Complete the {0} by selecting a dot to show where the final vertex would be.',
  completeShapeBySelectingADotPdf: 'Complete the {0}.',
  completeShapeBySelectingADotRegularPdf: 'Complete the regular {0}.',
  completeShapeBySelectingWhereADotCouldBe:
    'Complete the {0} by selecting a dot to show where the final vertex could be.',
  completeLongDivision: 'Complete the long division.',
  completeMissingNumber: 'Complete the missing number.',
  completeMissingValueOnNumberLine:
    'Complete the missing value on the number line.<br/>Give your answer in its simplest form.',
  completeMultiplicationsToDescribeThePicture:
    'Complete the multiplications to describe the picture.',
  completeMultiplication: 'Complete the multiplication.',
  completeMultiplications: 'Complete the multiplications.',
  completeMultiplicationUsingTheNumbers: 'Complete the multiplication using the numbers.',
  completeMultAndDivFacts: 'Complete the multiplication and division facts.',
  completeMultiplicationUsePictureToHelp:
    'Complete the multiplication.<br/>Use the picture to help.',
  completeNumberLine: 'Complete the number line.',
  completeNumberLineToSolveX: 'Complete the number line to solve {0}',
  completeNumberLineToWorkOutX: 'Complete the number line to work out {0}',
  completeNumberSentence: 'Complete the number sentence.',
  completeNumberSentenceRepresentedByBase10: 'Complete the number sentence represented by Base 10',
  completeNumberSentenceToMatchBarModel: 'Complete the number sentence to match the bar model.',
  completeNumberSentenceToPartition: 'Complete the number sentence to partition the number.',
  completeNumberSentencesToSolveX: 'Complete the number sentences to solve {0}',
  completeNumberSentences: 'Complete the number sentences.',
  completeSentencesToSolveXDivY: `Complete the sentences to solve {0} ${DIV} {1}`,
  completeNumberTrack: 'Complete the number track.',
  completeNumberTracks: 'Complete the number tracks.',
  completePartWholeModel: 'Complete the part-whole model.',
  completePictogram: 'Complete the pictogram.',
  completeSentence: 'Complete the sentence.',
  completeSentenceForShape: 'Complete the sentence for the shape.',
  completeSentenceToDescribeTheTranslation: 'Complete the sentence to describe the translation.',
  completeSentenceToDescribeTheX: 'Complete the sentence to describe the {0:string|lower}.',
  completeSentenceYouCanDrawCountersToHelp:
    'Complete the sentence. You can draw counters to help you.',
  completeSentenceYouCanUseCountersToHelp:
    'Complete the sentence. You can use the counters to help you.',
  completeSentencesToMatchArray: 'Complete the number sentences to match the array.',
  completeSentences: 'Complete the sentences.',
  completeSentencesForShape: 'Complete the sentences for the shape.',
  completeSentenceForTheTimeShownOnThe24HClock:
    'Complete the sentence for the time shown on the 24-hour clock.',
  completeSentenceToDescribeBarModels: 'Complete the sentence to describe the bar models.',
  completeSentencesToDescribeFruit: 'Complete the sentences to describe the fruit.',
  completeSentencesToDescribeRibbons: 'Complete the sentences to describe the ribbons.',
  completeSentencesToWorkOutWhatNumberLineIsCountingUpIn:
    'Complete the sentences to work out what the number line is counting up in.',
  completeSentencesToWorkOutXDivideY: `Complete the sentences to work out {0} ${DIV} {1}`,
  completeSequenceByWritingTime:
    'Complete the sequence by writing the next time as a 12-hour clock time.',
  completeStatement: 'Complete the statement.',
  completeStatements: 'Complete the statements.',
  completeSubtraction: 'Complete the subtraction.',
  completeSubtractionUseTheBarModelToHelp:
    'Complete the subtraction.<br/>Use the bar model to help.',
  completeSubtractions: 'Complete the subtractions.',
  completeTable: 'Complete the table.',
  completeTableToShowCorrectNumberOfFacesEdgesVertices:
    'Complete the table to show the correct number of faces, edges and vertices.',
  completeTableToShowDegrees:
    'Complete the table to show the number of degrees in each sector of a pie chart.',
  completeTallyChart: 'Complete the tally chart.',
  completeTheEquivalentFractions: 'Complete the equivalent fractions.',
  completeTheMixedNumberAndImproperFractionShownByTheBarModels:
    'Complete the mixed number and improper fraction shown by the bar models.',
  completeTheSubtractionUseThePlaceValueChartToHelp:
    'Complete the subtraction.<br/>Use the place value chart to help.',
  completeTimeShownOnClock: 'Drag the cards to complete the time shown on the clock.',
  completeTimeShownOnClockPDF: 'Use the cards to complete the time shown on the clock.',
  completeTimeToMakeStatementCorrect: 'Complete the time to make the statement correct.',
  completeWorkingsToCalculateX: 'Complete the workings to calculate {0}',
  compoundShapeMadeOfRegularPolygonsWorkOutSizeOfX:
    'Each compound shape is made up of regular polygons.<br/>Work out the size of angle {0}.',
  compoundShapeMadeOfRegularPolygonsWorkOutSizeOfX2dp:
    'Each compound shape is made up of regular polygons.<br/>Work out the size of angle {0} to 2 decimal places.',
  continuePatternCompleteTable: 'Continue the pattern and complete the table.',
  convertImproperFractionsToDecimals: 'Convert the improper fractions to decimals.',
  convertImproperFractionToMixedNumber: 'Convert the improper fraction to a mixed number.',
  convertImproperFractionsToMixedNumbers: 'Convert the improper fractions to mixed numbers.',
  convertFractionToPercentage: 'Convert the fraction to a percentage.',
  convertMixedNumberToImproperFraction: 'Convert the mixed number to an improper fraction.',
  convertMixedNumbersToImproperFractions: 'Convert the mixed numbers to improper fractions.',
  convertMixedNumberToImproperFractionShadeTheBarModelToHelp:
    'Convert {0} to an improper fraction.<br/>Shade the bar model to help.',
  convertMixedNumberToImproperFractionTapTheBarModelToHelp:
    'Convert {0} to an improper fraction.<br/>Tap the bar model to help.',
  convertTheIntegersToImproperFractions: 'Convert the integers to improper fractions.',
  convertTheXToAYAndAZ:
    'Convert the {0:string|lower} {1} to a {2:string|lower} and a {3:string|lower}.',
  convertAmountsToPoundsPence: 'Convert the amounts to pounds and pence.',
  convertAnalogueIntoDigitalTime: 'Convert the analogue time into a digital time.',
  countBackwardsToWorkOutTheMissingNumerator: 'Count backwards to work out the missing numerator.',
  countForwardsToWorkOutTheMissingNumerator: 'Count forwards to work out the missing numerator.',
  countSquaresToEstimateAreaTriangle: 'Count the squares to estimate the area of the triangle.',
  countSquaresToFindArea: 'Count the squares to find the area.',
  countSquaresToWorkOutAreaOfTriangle: 'Count the squares to work out the area of the triangle.',
  countersInABagAreTwoDifferentColours:
    'There are {0:string|lower} and {1:string|lower} counters in a bag.',
  createADifferentArrayUsingNumCounters:
    'Create a different array using {0:number|numberFormat} counters.',
  createADifferentArrayUsingNumCountersPDF:
    'Create a different array by drawing {0:number|numberFormat} counters.',
  createADifferentRectangleUsingTheSameArea: 'Create a different rectangle using the same area.',
  createAnIrregularPolygonOnTheGridTapDotsToShowVertices:
    'Create an irregular polygon on the grid.<br/>Tap dots to show the position of the vertices.',
  createAnIrregularPolygonOnTheGridUseDotsToShowVertices:
    'Create an irregular polygon on the grid.<br/>Use dots to show the position of the vertices.',
  createArrayToShow: 'Create an array to show {0}',
  createArrayToShowXIsFactorOfY:
    'Create an array to show that {0:number|numberFormat} is a factor of {1:number|numberFormat}',
  createADifferentRectilinearShapeThatHasTheSameAreaAsTheRectangle:
    'Create a different rectilinear shape that has the same area as the rectangle.',
  createEquationToRepBarModel: 'Create an equation to represent the bar model.',
  createEquationToRepBarModelPDF: 'Use the cards to create an equation to represent the bar model.',
  createMultipleOfX: 'Make a multiple of {0:number|numberFormat}',
  createMultipleOfXUsingSomeDigitsBelow:
    'Make a multiple of {0:number|numberFormat} using some of the digits below.\nEach digit can only be used once.',
  createMultipleOfXUsingSomeDigitsBelowNumbersCanBeUsedMoreThanOnce:
    'Make a multiple of {0:number|numberFormat} using some of the digits below.\nEach digit can be used more than once.',
  create5DigitNumberWith: 'Drag the cards to create a 5-digit number with {0} in the {1} place.',
  create5DigitNumberWithDigitsOnlyUsedOnce:
    'Use these digits to create a 5-digit number with {0} in the {1} place.\nEach digit can only be used once.',
  create6DigitNumberWith: 'Drag the cards to create a 6-digit number with {0} in the {1} place.',
  create6DigitNumberWithDigitsOnlyUsedOnce:
    'Use the cards to create to create a 6-digit number with {0} in the {1} place.\nEach digit can only be used once.',
  create7DigitNumberWith: 'Drag the cards to create a 7-digit number with {0} in the {1} place.',
  create7DigitNumberWithDigitsOnlyUsedOnce:
    'Use the cards to create a 7-digit number with {0} in the {1} place.\nEach digit can only be used once.',
  createRectangleAreaNumSquares:
    'Create a rectangle with an area of {0:number|numberFormat} squares.',
  createRectangleGreaterArea: 'Create a rectangle with a greater area.',
  createRectangleSmallerArea: 'Create a rectangle with a smaller area.',
  createXThatHasAreaOfY: 'Create a {0:string|lower} that has an area of {1:string|lower}',
  createRectangleThatHasPerimeterOfNumCm:
    'Create a rectangle that has a perimeter of {0:number|numberFormat} cm.',
  createRectilinearShapeNumSquares:
    'Create a rectilinear shape using {0:number|numberFormat} squares.',
  createShapeAreaNumSquares: 'Create a shape with an area of {0:number|numberFormat} squares.',
  createShapeThatHasPerimeterOfNumCm:
    'Create a shape that has a perimeter of {0:number|numberFormat} cm.',
  createShapeAreaNumSquaresGreaterThan:
    'Create a shape with an area {0:number|numberFormat} squares greater than this shape.',
  createShapeAreaNumSquaresSmallerThan:
    'Create a shape with an area {0:number|numberFormat} squares smaller than this shape.',
  createSquareAreaNumSquares: 'Create a square with an area of {0:number|numberFormat} squares.',
  createSquareThatHasPerimeterOfNumCm:
    'Create a square that has a perimeter of {0:number|numberFormat} cm.',
  createXPossibleMeasureUsingTheseDigits:
    'Create the {0:string|lower} possible {1:string|lower} using these digits.',
  createXPossibleMeasureUsingTheseDigitsEachDigitOnlyUsedOnce:
    'Create the {0:string|lower} possible {1:string|lower} using these digits.\nEach digit can only be used once.',
  cuboidIsDrawnOnIsometricPaperHowManyCubesAreNeededToMakeTheCuboid:
    'A cuboid is drawn on isometric paper.<br/>How many cubes are needed to make the cuboid?',
  dashedLineCutsTheRightAnglesInHalf: 'The dashed line cuts the right angle in half.',
  doesPlaceValueChartCorrectlyRepresentX: 'Does the place value chart correctly represent {0}',
  dragACardToCompleteSentence: 'Drag a card to complete the sentence.',
  dragACardToCompleteTheTimeShownOnTheDigitalClock:
    'Drag a card to complete the time shown on the digital clock.',
  dragACardToCompareUnitFractions: 'Drag a card to compare the unit fractions.',
  dragACardToMatchTheDigitalTimeToTheAnalogueClock:
    'Drag a card to match the digital time to the analogue clock.',
  dragArrowToShowXCOnTheThermometer: 'Drag the arrow to show {0}°C on the thermometer.',
  dragArrowToFillJugWithNumMlOfJuice:
    'Drag the arrow to fill the jug with {0:number|numberFormat} ml of juice.',
  dragBase10ToMakeNumMore: 'Drag the base 10 to make the number that is {0} more than {1}',
  dragBase10ToMakeNumLess: 'Drag the base 10 to make the number that is {0} less than {1}',
  dragCardsCompareLengths: 'Drag the cards to compare the lengths.',
  dragCardCompareTimes: 'Drag a card to compare the times.',
  dragCardsCompareMasses: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = to compare the masses.`,
  dragCardsCompareMixedNumbers: 'Drag the cards to compare the mixed numbers.',
  dragCardsCompareSizeOfAngles: 'Drag the cards to compare the size of the angles.',
  dragCardsCompareStatements: 'Drag the cards to compare the statements.',
  dragCardsCompleteAddition: 'Drag the cards to complete the addition.',
  dragCardsCompleteDivisions: 'Drag the cards to complete the divisions.',
  dragCardsCompleteNumberLine: 'Drag the cards to complete the number line.',
  dragCardsCompleteNumberTrack: 'Drag the cards to complete the number track.',
  dragCardsCompleteSequence: 'Drag the cards to complete the sequence.',
  dragCardsCompleteStatement: 'Drag the cards to complete the statement.',
  dragCardsCompleteStatements: 'Drag the cards to complete the statements.',
  dragCardsCompleteStatementYouCanShadeTheBarModelsToHelp:
    'Drag the cards to complete the statement.<br/>You can shade the bar models to help.',
  dragCardsConvertXToPercentage: 'Drag the cards to convert {0} to a percentage.',
  dragCardsIntoCorrectGroup: 'Drag the cards into the correct group.',
  dragCardsSolveEquations: 'Drag the cards to solve the equations.',
  dragCardsToCompareAmounts: 'Drag the cards to compare the amounts.',
  dragCardsToCompareAreasOfShadedShapes:
    'Drag the cards to compare the areas of the shaded shapes.',
  dragCardsToCompareVolumesOfShapes: 'Drag the cards to compare the volumes of the shapes.',
  dragCardsToCreateXPossibleMeasureUsingTheseDigits:
    'Drag the cards to create the {0:string|lower} possible {1:string|lower}.',
  dragCardsToEstimateArea: 'Drag the cards to estimate the area of the shape.',
  dragCardsToMatchAbbreviations: 'Drag the cards to match the abbreviations to the words.',
  dragCardsToMatchAmounts: 'Drag the cards to match the amounts.',
  dragCardsToMatchApproxCapacityToEachContainer:
    'Drag the cards to match the approximate capacity to each container.',
  dragCardsToMatchApproxVolumeToEachObject:
    'Drag the cards to match the approximate volume to each object.',
  dragCardToMatchCalcToRep: 'Drag the card to match the calculation to the representation.',
  dragCardsToMatchCalcToRep: 'Drag the cards to match the calculation to the representation.',
  dragCardsToMatchCalcsToReps: 'Drag the cards to match the calculations to the representations.',
  dragCardsToMatchListsWithStatements: 'Drag the cards to match the lists with the statements.',
  dragCardsToMatchPercentagesToFractions:
    'Drag the cards to match the converted percentages to the fractions.',
  dragCardsToMatchRuleToDiagram: 'Drag the cards to match each rule to the correct diagram.',
  dragCardsToMatchShapeToQuadrilateral:
    'Drag the cards to match the shape to the type of quadrilateral.',
  dragCardsToMatchTypeOfAnglesToTheDescriptions:
    'Drag the cards to match the types of angles to the descriptions.',
  dragCardsToOrderAnglesSmallestGreatest:
    'Drag the cards to order the angles from smallest to greatest.',
  dragCardsToOrderCapacitiesStartWithGreatest:
    'Drag the cards to order the capacities.<br/>Start with the greatest.',
  dragCardsToOrderCapacitiesStartWithSmallest:
    'Drag the cards to order the capacities.<br/>Start with the smallest.',
  dragCardsToOrderInteriorAnglesGreatestSmallest:
    'Drag the cards to order the interior angles sum of the shapes from greatest to smallest.',
  dragCardsToOrderInteriorAnglesSmallestGreatest:
    'Drag the cards to order the interior angles sum of the shapes from smallest to greatest.',
  dragCardsToOrderOrangeJuiceFromLeast:
    'Drag the cards to put the glasses in order of the amount of orange juice they contain.<br/>Start with the least amount of orange juice.',
  dragCardsToOrderOrangeJuiceFromMost:
    'Drag the cards to put the glasses in order of the amount of orange juice they contain.<br/>Start with the most amount of orange juice.',
  dragCardsToOrderExpressions: 'Drag the cards to order the expressions.',
  dragCardsToPutFracDecPercInOrder:
    'Drag the cards to put the fractions, decimals and percentages in order.<br/>Start with the {0:string|lower} value.',
  dragCardsToOrderMixedNumbers:
    'Drag the cards to order the mixed numbers.<br/>Start with the {0:string|lower}.',
  dragCardsToPlaceCalculationsInAscendingOrder:
    'Drag the cards to place the calculations in ascending order.',
  dragCardsToPlaceCalculationsInDescendingOrder:
    'Drag the cards to place the calculations in descending order.',
  dragCardsToRoundX: 'Drag the cards to round {0} to the nearest integer, tenth and hundredth.',
  dragCardsToShowMostLeastPopularFruit:
    'Drag the cards to show the {0:string|lower} popular fruit.',
  dragCardsToSortTheShapes: 'Drag the cards to sort the shapes.',
  dragDigitCardsToCreate4DigitNumberWith:
    'Drag the digit cards to create a 4-digit number with {0:number|numberFormat} in the {1} place.',
  dragEachCardToCompleteEquivalentFractions: 'Drag each card to complete equivalent fractions.',
  dragLessThanGreaterThanOrEqualsToMakeStatementCorrect: `Drag ${LESS_THAN}, ${GREATER_THAN} or = to make the statement correct.`,
  diameterIsXWhatIsRadius:
    'The diameter of a circle is {0:number|numberFormat} {1}.<br/>What is the radius?',
  divideXCountersIntoGroupsOfYCounters: 'Divide {0} counters into groups of {1} counters.',
  divisibleByXOnly: 'Divisible by {0} only',
  divisibleByXAndY: 'Divisible by {0} and {1}',
  doYouAgreeOrDisagreeWithCharacter: 'Do you agree or disagree with {0}?',
  doYouAgreeOrDisagreeWithCharacterPDF: 'Do you agree or disagree with {0}? Circle your answer.',
  dragArrowEstimatePositionOfNumOnNumberLine:
    'Drag the arrow to estimate the position of {0} on the number line.',
  dragArrowToEstimateWhereXIsOnNumberLine:
    'Drag the arrow to estimate where {0:number|numberFormat} is on the number line.',
  dragArrowToNumberGreaterThanX: 'Drag the arrow to a number that is greater than {0}',
  dragArrowToNumberLessThanX: 'Drag the arrow to a number that is less than {0}',
  dragCardCompleteNumberSentence: 'Drag a card to complete the number sentence.',
  dragCardCompleteSentence: 'Drag a card to complete the sentence.',
  dragCardCompleteStatement: 'Drag a card to complete the statement.',
  dragTheCardToMakeAPairOfNumbersWithADifferenceOfX:
    'Drag the card to make a pair of numbers with a difference of {0}',
  dragTheCardToMatchEquivalentFractionShownInDiagram:
    'Drag the card to match the equivalent fraction shown in the diagram.',
  dragTheCardToMatchTheEquivalentDecimal: 'Drag the card to match the equivalent decimal.',
  dragCardToShowGreatestNumberFromPVC:
    'Drag the card to show the greatest number from the place value chart.',
  dragCardToShowSmallestNumberFromPVC:
    'Drag the card to show the smallest number from the place value chart.',
  dragTheCardToShowTheMissingNumberOnTheNumberLine:
    'Drag the card to show the missing number on the number line.',
  dragCardsAndUseBarModelsToMatchEquivalentFractions:
    'Drag the cards and use the bar models to match the equivalent fractions.',
  dragCardsAscendingOrder: 'Drag the cards to place them in ascending order.',
  dragCardsCompleteCalculations: 'Drag the cards to complete the calculations.',
  dragCardsCompleteFunctionMachine: 'Drag the card to complete the function machine.',
  dragCardsCompleteNumberSentence: 'Drag the cards to complete the number sentence.',
  dragCardsCompleteNumberSentences: 'Drag the cards to complete the number sentences.',
  dragCardsCompleteTallyChart: 'Drag the cards to complete the tally chart.',
  dragCardsConvertFracToDecimalAndPercentage:
    'Drag the cards to convert <frac n="{0}" d="{1}" /> to a decimal and a percentage.',
  dragCardsDescendingOrder: 'Drag the cards to place them in descending order.',
  dragCardsEstimateAnswerTo:
    'Drag the cards to estimate {0}<br/>You can use each card more than once.',
  dragCardsFindDecimalEquivalentOfFraction:
    'Drag the cards to find the decimal equivalent of the fraction.',
  dragCardsFlexiblyPartitionX: 'Drag the cards to flexibly partition {0}',
  dragCardsInAscendingsOrderOfValue: 'Drag the cards in ascending order of value.',
  dragCardMakeStatementCorrect: 'Drag a card to make the statement correct.',
  dragCardsMakeFourDifferentNumberSentences:
    'Drag the cards to make four different number sentences.',
  dragCardsMakeComplementsTo100: 'Drag the cards to make complements to 100',
  dragCardsMakeCorrectCalculationToWorkOutLengthOfObject:
    'Drag the cards to make the correct calculation to work out the length of {0:string|lower}.',
  dragCardsMakeNumber: 'Drag the cards to make {0:string|lower}',
  dragCardsMakeStatementCorrect: 'Drag the cards to make the statement correct.',
  dragCardsMakeStatementsCorrect: 'Drag the cards to make the statements correct.',
  dragCardsMatchEquivalentFractions: 'Drag the cards to match the equivalent fractions.',
  dragCardsMatchEquivalentFractionsDecimalsPercentages:
    'Drag the cards to match the equivalent fractions, decimals and percentages.',
  dragCardsMatchInverseCalcs: 'Drag the cards to match the inverse calculations.',
  dragCardsMatchDecimalEquivalents: 'Drag the cards to match the decimal equivalents.',
  dragCardsMatchFractionEquivalents: 'Drag the cards to match the fraction equivalents.',
  dragCardsMatchDecimalsToCounters:
    'Drag the cards to match the decimals to the groups of counters.',
  dragCardsMatchNumbersWithDifferenceOf:
    'Drag the cards to match the numbers with a difference of {0}',
  dragCardsOrderTheTimesFromXToY:
    'Drag the cards to order the times from {0:string|lower} to {1:string|lower}.',
  dragCardsOrderValuesGreatestToSmallest:
    'Drag the cards to order the values from greatest to smallest.',
  dragCardsOrderValuesSmallestToGreatest:
    'Drag the cards to order the values from smallest to greatest.',
  dragCardsSortAdditions: 'Drag the cards to sort the additions into the table.',
  dragCardsSortAmounts: 'Drag the cards to sort the amounts into the table.',
  dragCardsSortEquivalentFractions:
    'Drag the cards to sort the equivalent fractions into the table.',
  dragCardsMatchMeasureToMetrics: 'Drag the cards to match the measure to its metric units.',
  dragCardsSortMetricUnitsIntoCorrectCategories:
    'Drag the cards to sort the metric units into the table.',
  dragCardsSortTheMonthsIntoTheTable: 'Drag the cards to sort the months into the table.',
  dragCardsSortMultiplesOfXAndY:
    'Drag the cards to sort the multiples of {0} and {1} into the table.',
  dragCardsStartingGreatest: 'Drag the cards to place them in order.\nStart with the greatest.',
  dragCardsStartingSmallest: 'Drag the cards to place them in order.\nStart with the smallest.',
  dragCardsToCompareCalculations: 'Drag the cards to compare the calculations.',
  dragCardsToCompareDivisions: 'Drag the cards to compare the divisions.',
  dragCardsToCompareExpressions: 'Drag the cards to compare the expressions.',
  dragCardsToCompareNumbers: 'Drag the cards to compare the numbers.',
  dragCardsToCompleteCalculation: 'Drag the cards to complete the calculation.',
  dragCardsToCompleteCalculationForArea:
    'Drag the cards to complete the calculation that works out the area.',
  dragCardsToCompleteCalculations: 'Drag the cards to complete the calculations.',
  dragCardsToCompleteMultiplication: 'Drag the cards to complete the multiplication.',
  dragCardToCompleteSentence: 'Drag the card to complete the sentence.',
  dragCardsToCompleteSentence: 'Drag the cards to complete the sentence.',
  dragCardsToCompleteSentences: 'Drag the cards to complete the sentences.',
  dragCardsToCreateXDigitMultOfY:
    'Drag the cards to create a {0:number|numberFormat}-digit multiple of {1:number|numberFormat}',
  dragCardsToDescribeTranslation: 'Drag the cards to describe the translation.',
  dragCardsToFillInMissingFunctions: 'Drag the cards to fill in the missing functions.',
  dragCardsToFindTwoPossiblePairsOfValuesForXAndY:
    'Drag the cards to find two possible pairs of values for {0} and {1}.',
  dragCardsToMakeASingleStepThatWouldGiveSameOutput:
    'Drag the cards to make a single step that would give the same output.',
  dragCardsToMakeMultipleOf5And10:
    'Drag the cards to make a 3-digit number that is a multiple of 5 and a multiple of 10',
  dragCardsToMakeMultipleOf5Not10:
    'Drag the cards to make a 3-digit number that is a multiple of 5 and <b>not</b> a multiple of 10',
  dragCardsToMakeMultipleOfX: 'Drag the cards to make a multiple of {0:number|numberFormat}',
  dragCardsToMatchDecimalAndFracEquivs:
    'Drag the cards to match the decimal and fraction equivalents.',
  DragCardsMatchDivisibilityRules: 'Drag the cards to match the divisibility rules.',
  dragCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize:
    'Drag the cards to match each angle to an appropriate estimate of its size.',
  dragCardsToMatchEachCalcWithEstAnswer:
    'Drag the cards to match each calculation with an estimated answer.',
  dragCardsToMatchEachAdditionToEquivMult:
    'Drag the cards to match each addition to the equivalent multiplication.',
  dragCardsToMatchEquivalentFracsAndDecimals:
    'Drag the cards to match the equivalent fractions and decimals.',
  dragCardsToMatchEquivalentLengths: 'Drag the cards to match the equivalent lengths.',
  dragCardsToMatchImproperFractionsToBarModels:
    'Drag the cards to match the improper fractions to the bar models.',
  dragCardsToMatchNumbersToRomanNumerals:
    'Drag the cards to match the numbers to the Roman numerals.',
  dragCardsToMatchRomanNumeralsToNumbers:
    'Drag the cards to match the Roman numerals to the numbers.',
  dragCardsToMatchXToY: 'Drag the cards to match the {0:string|lower} to the {1:string|lower}.',
  dragCardsToOrderDistancesStartWithLongest:
    'Drag the cards to order the distances.<br/>Start with the longest distance.',
  dragCardsToOrderDistancesStartWithShortest:
    'Drag the cards to order the distances.<br/>Start with the shortest distance.',
  dragCardsToOrderFractionsStartingWithGreatest:
    'Drag the cards to order the fractions, starting with the greatest.',
  dragCardsToOrderFractionsStartingWithGreatestCanShadeBarModel:
    'Drag the cards to order the fractions, starting with the greatest.<br/>You can shade the bar models to help.',
  dragCardsToOrderFractionsStartingWithSmallest:
    'Drag the cards to order the fractions, starting with the smallest.',
  dragCardsToOrderFractionsStartingWithSmallestCanShadeBarModel:
    'Drag the cards to order the fractions, starting with the smallest.<br/>You can shade the bar models to help.',
  dragCardsToOrderLengthsStartWithLongest:
    'Drag the cards to order the lengths.<br/>Start with the longest length.',
  dragCardsToOrderLengthsStartWithShortest:
    'Drag the cards to order the lengths.<br/>Start with the shortest length.',
  dragCardsToOrderMeasurementsStartWithGreatest:
    'Drag the cards to order the {0:string|lower}.<br/>Start with the greatest.',
  dragCardsToOrderMeasurementsStartWithSmallest:
    'Drag the cards to order the {0:string|lower}.<br/>Start with the smallest.',
  dragCardsToOrderNumbersInAscendingOrder:
    'Drag the cards to order the numbers in ascending order.',
  dragCardsToOrderNumbersInDescendingOrder:
    'Drag the cards to order the numbers in descending order.',
  dragCardsToOrderNumbersStartWithGreatest:
    'Drag the cards to order the numbers.<br/>Start with the greatest.',
  dragCardsToOrderNumbersStartWithSmallest:
    'Drag the cards to order the numbers.<br/>Start with the smallest.',
  dragCardsToOrderRomanNumeralsFromXToY:
    'Drag the cards to order the Roman numerals from {0:string|lower} to {1:string|lower}.',
  dragCardsToOrderTheTemp: 'Drag the cards to order the temperatures.',
  dragCardsToOrderUnitFractions:
    'Drag the cards to order the fractions.<br/>Start with the {0:string|lower}.',
  dragCardsToPlaceNumbersInAscendingOrder:
    'Drag the cards to place the numbers in ascending order.',
  dragCardsToPlaceNumbersInDescendingOrder:
    'Drag the cards to place the numbers in descending order.',
  dragCardsToPutShapesInAscendingOrderOfVolume:
    'Drag the cards to put the shapes in ascending order of volume.',
  dragCardsToPutShapesInDescendingOrderOfVolume:
    'Drag the cards to put the shapes in descending order of volume.',
  dragCardsToShowYourAnswer: 'Drag the cards to show your answer.',
  dragCardsToSortAnglesIntoTheTable: 'Drag the cards to sort the angles into the table.',
  dragCardsToSortCalculationsInTable: 'Drag the cards to sort the calculations into the table.',
  dragCardsToSortEquivalentFractionsIntoTheTable:
    'Drag the cards to sort the equivalent fractions into the table.',
  dragCardsToShowMixedNumbersInTheirCorrectForm:
    'Drag the cards to show the mixed numbers in their correct form.',
  dragCardsToSortFractionsIntoTheTable: 'Drag the cards to sort the fractions into the table.',
  dragCardsToSortNonUnitFractionsIntoTheTable:
    'Drag the cards to sort the non-unit fractions into the table.',
  dragCardsToSortShapesInTable: 'Drag the cards to sort the shapes into the table.',
  dragCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen:
    'Drag the cards to sort the events into the time of day that they usually happen.',
  dragCardsToSortNumbersIntoTheTable: 'Drag the cards to sort the numbers into the table.',
  dragCardsToSortUnitFractionsIntoTheTable:
    'Drag the cards to sort the unit fractions into the table.',
  dragCardsToSortUnitsOfMeasurementIntoTable:
    'Drag the cards to sort the units of measurement into the table.',
  dragCardsToWorkOutXMultY: `Drag the cards to work out {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  dragCardToWorkOutTheArea: 'Drag the card to work out the area.',
  dragCardToWorkOutTheAreaOfRectilinearShape:
    'Drag the card to work out the area of the rectilinear shape.',
  dragCircleToCompletePictogram: 'Drag the circles to complete the pictogram.',
  dragCiclesToPlot2PointsWithAXPointOfY:
    'Drag the circles to plot 2 points with a {0} coordinate of {1}',
  dragCirclesToReflectVerticesTriangleInAxis:
    'Drag the circles to reflect the vertices of the triangle in the {0}-axis.',
  dragCorrectCard: 'Drag the correct card.',
  dragCountersToCreateEqualGroups: 'Drag in {0} counters to create {1} equal groups.',
  dragCountersToCreateEqualGroupsOfX: 'Drag in {0} counters to create equal groups of {1}',
  dragCountersToShowTheAnswerToX: 'Drag the counters to show the answer to {0}',
  useTheCountersToHelpYouFindXofY: 'Use the counters to help you find {0} of {1}',
  dragCountersToMakeNum: 'Drag the counters to make {0}',
  dragInCountersToMakeNum: 'Drag in counters to make {0}',
  dragCountersToMakeNumOnPVC: 'Drag in counters to make {0} on the place value chart.',
  dragCountersToRepresentNum: 'Drag the counters to represent {0}',
  dragCountersIntoPVCToRepresentAnswerTo: 'Drag the counters to represent the answer to {0}',
  dragCountersToRepresentNumLess:
    'Drag in counters to represent the number that is {0} less than {1}',
  dragCountersToRepresentNumMore:
    'Drag in counters to represent the number that is {0} more than {1}',
  dragTheCountersToShowAnswer: 'Drag the counters to show your answer to {0}',
  dragTheCountersToShowTheAnswer: 'Drag the counters to show the answer to {0}',
  dragCountersIntoPVCToShow: 'Drag in counters to show {0}',
  dragCountersToShowNumDivNum:
    'The place value chart shows {0}\nDrag the counters to show {0} ' + DIV + ' {1}',
  dragCountersToShowNumTimesNum:
    'The place value chart shows {0}\nDrag the counters to show {0} ' + MULT + ' {1}',
  dragInCountersToRepresentNum: 'Drag in counters to represent {0}',
  dragTheCountersToShowNumTimesNum:
    'The place value chart shows {0}\nDrag the counters to show {0} ' + MULT + ' {1}',
  dragCardsCreateDivisionWithRemainderX:
    'Drag the cards to create a calculation that gives a remainder {0} {1:number|numberFormat}',
  dragCardsCompleteANumberSentence: 'Drag the cards to complete a correct number sentence.',
  dragCardsMatchMassesOneXItemHasMassOfYKg:
    'One {0:string|lower} has a mass of {1} kg.<br/>Drag the cards to match the masses.',
  dragCardsSortFactorsOfXAndY: 'Drag the cards to sort the factors of {0} and {1} into the table.',
  dragCardsSortSubtractions: 'Drag the cards to sort the subtractions into the table.',
  dragCardsToCompleteEquivalentFractions: 'Drag the cards to complete the equivalent fractions.',
  dragShapeToTranslateXToTheDx: 'Drag the shape to translate it {x} square{{s}} to the {dx}.',
  dragShapeToTranslateXToTheDxPDF: 'Translate the shape {x} square{{s}} to the {dx}.',
  dragShapeToTranslateYDy: 'Drag the shape to translate it {y} square{{s}} {dy}.',
  dragShapeToTranslateYDyPDF: 'Translate the shape {y} square{{s}} {dy}.',
  dragShapeXSquaresDXAndYSquaresDY:
    'Drag the shape to translate it {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  dragShapeXSquaresDXAndYSquaresDYPDF:
    'Translate the shape {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  dragShapeToTranslateXDxYDy:
    'Drag the {shape} to translate it {x} square{{s}} {dx} and {y} square{{s}} {dy}.',
  dragTheCardsToCompleteTheNumberSentence: 'Drag the cards to complete the number sentence.',
  dragTheCardsToContinueTheSequence: 'Drag the cards to continue the sequence.',
  dragTheCardsToMatchEquivalentCalcs: 'Drag the cards to match the equivalent calculations.',
  dragTheCardsToMatchNamesToTheShape: 'Drag the cards to match the name to the shape.',
  dragTheCardsToMatchQuestionsToTheAnswers: 'Drag the cards to match the questions to the answers.',
  dragTheCardsToMatchStatementToBarModels:
    'Drag the cards to match the statements to the bar models.',
  dragCardsMatchTimes: 'Drag the cards to match the times.',
  dragCardsToMatchTypeOfTriangleToDefinition:
    'Drag the cards to match the type of triangle to the definition.',
  dragCardsToMatchTypeOfTriangleToShape:
    'Drag the cards to match the type of triangle to the shape.',
  dragCardsToOrderTheDurationsOfTime: 'Drag the cards to order the durations of time.',
  dragCardsToPlaceFractionsInAscendingOrder:
    'Drag the cards to place the fractions in ascending order.',
  dragCardsToPlaceFractionsInDescendingOrder:
    'Drag the cards to place the fractions in descending order.',
  dragCardsToShowAreaOfRectangleAndParallelogram:
    'Drag the cards to show the area of the rectangle and the parallelogram.',
  dragCardsToSortLengthsIntoTable: 'Drag the cards to sort the lengths into the table.',
  useCardsMakeCorrectCalculationToWorkOutLengthOfObjectPDF:
    'Use the cards to make the correct calculation to work out the length of {0:string|lower}.',
  useCardsToPlaceFractionsInAscendingOrder:
    'Use the cards to place the fractions in ascending order.',
  useCardsToPlaceFractionsInDescendingOrder:
    'Use the cards to place the fractions in descending order.',
  dragDigitCardsToWriteX: 'Drag the digit cards to write {0}.',
  dragFractionsCompleteNumberSentence: 'Drag the fractions to complete the number sentence.',
  dragFractionsCompleteNumberSentencePDF:
    'Use the fractions below to complete the number sentence.',
  dragHorizontalLineSoItIsLengthLong: `Drag the pencil to draw a horizontal line that is {length} {unit} long.`,
  dragHorizontalLineSoItIsLengthLongPdf: `Draw a straight, horizontal line above the ruler that is {length} {unit} long.`,
  dragIncorrectPointsToCorrectLineGraph:
    'Drag the incorrect point{{0:s}} to correct the line graph.',
  dragIncorrectPointsToCorrectLineGraphPdf:
    'Circle and correct the incorrect point{{0:s}} on the line graph.',
  dragInequalitiesToCompareTemperatures: `Drag ${LESS_THAN}, ${GREATER_THAN} to compare the temperatures.`,
  dragInequalities0rEqualToCompareVolumes: `Drag ${LESS_THAN}, ${GREATER_THAN} or = to compare the volumes.`,
  dragInequalities0rEqualToCompareMixedNumbers: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = to compare the mixed numbers.`,
  dragInequalities0rEqualToCompleteNumberSentence: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = symbols to complete the number sentence.`,
  dragInequalities0rEqualToCompleteNumberSentences: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = symbols to complete the number sentences.`,
  dragInequalitiesOrEqualToMakeStatementsCorrect: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = symbols to make the statements correct.`,
  dragInequalitiesOrEqualToMakeStatementsCorrectPDF: `Use the ${LESS_THAN}, ${GREATER_THAN} or = symbols to make the statements correct.`,
  dragPencilToDrawLineThatIsXCmAndYMmLong:
    'Drag the pencil to draw a line that is {0:number|numberFormat} cm and {1:number|numberFormat} mm long.',
  dragPencilToDrawLineThatIsXCmAndYMmLongPdf:
    'Draw a line that is {0:number|numberFormat} cm and {1:number|numberFormat} mm long.',
  dragPencilToDrawLineLongerThanXButShorterThanY:
    'Drag the pencil to draw a line that is longer than {0} but shorter than {1}.',
  dragPencilToDrawLineLongerThanXButShorterThanYPdf:
    'Draw a line that is longer than {0} but shorter than {1}.',
  dragLessThanGreaterThanEqualsToCompareDurations: `Drag the ${LESS_THAN}, ${GREATER_THAN} or = symbols to compare the durations.`,
  dragLessThanGreaterThanEqualsToCompareTemperatures: `Drag ${LESS_THAN}, ${GREATER_THAN} or = to compare the temperatures.`,
  dragLineSoItIsLengthLong: `Drag the pencil to draw a line that is {length} {unit} long.`,
  dragLineSoItIsLengthLongPdf: `Draw a straight line above the ruler that is {length} {unit} long.`,
  dragLineToMakeAngleThatIsXDegrees:
    'Drag the line to make an angle that is {0:number|numberFormat}°.',
  dragLineToMakeAngleThatisXDegreesInPieChart:
    'Drag the line to make an angle that is {0:number|numberFormat}° within the pie chart.',
  dragLinesToMakeAngleThatIsXDegrees:
    'Drag the lines to make an angle that is {0:number|numberFormat}°.',
  dragLinesToMakeXAngle: 'Drag the lines to make an {0: string|lower} angle.',
  dragNumberCardsToCompleteCalculations: 'Drag the number cards to complete the calculations.',
  dragNumberCardsToCompleteMutliplication: 'Drag the number cards to complete the multiplication.',
  dragNumberCardsToMatchMixedNumbersToImproperFractions:
    'Drag the number cards to match the mixed numbers to the improper fractions.',
  drawArrowOnScalesToShowXG:
    'Draw an arrow on the scales to show the mass of {0:number|numberFormat} g.',
  drawHandsOnClockToShowX: 'Draw the hands on the clock to show {0}',
  dragPointToCoordinatesXY: 'Drag the point to the coordinates ({0},{1})',
  dragPointToReflectPointInLineL: 'Drag the point to reflect it in the line marked L.',
  dragPointsToCompleteTheLineGraph: 'Drag the points to complete the line graph.',
  dragPointsToCompleteVerticesOfShape:
    'Drag the point{{0:s}} to complete the vertices of {1} on the coordinate grid.',
  dragIncorrectPointsToCorrectTheLineGraph: 'Drag the incorrect points to correct the line graph.',
  dragPointsToCompleteTheLineGraphPdf: 'Use the information in the table to draw a line graph.',
  dragPointsToPlotCoordsToCreateTriangle:
    'Drag the points to plot the coordinates {0} to create a triangle.',
  dragPointsToPlotQuadrilateralVertices:
    'Drag the points to plot the quadrilateral vertices at <g>({0}, {1})</g>, <g>({2}, {3})</g>, <g>({4}, {5})</g> and <g>({6}, {7})</g>',
  dragPointsToReflectInMirrorLine: 'Drag the points to reflect the shape in the mirror line.',
  dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDY:
    'Drag the points to translate each vertex {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDYPDF:
    'Translate the points of each vertex {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  dragPointsToTranslateTriangleXSquaresDX:
    'Drag the points to translate the triangle {x:number|numberFormat} square{{s}} to the {dx}.',
  dragPointsToTranslateTriangleXSquaresDXAndYSquaresDY:
    'Drag the points to translate the triangle {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  dragPointsToTranslateTriangleYSquaresDY:
    'Drag the points to translate the triangle {y:number|numberFormat} square{{s}} {dy}.',
  dragRomanNumeralCardsPlaceInAscendingOrder:
    'Drag the Roman numeral cards to place them in ascending order.',
  dragRomanNumeralCardsPlaceInDescendingOrder:
    'Drag the Roman numeral cards to place them in descending order.',
  dragRomanNumeralCardsToMakeNum:
    'Drag the Roman numerals to make the number {0:number|numberFormat}',
  dragTheArrowToEstimateWhereFracBelongsOnNumberLine:
    'Drag the arrow to estimate where {0} belongs on the number line.',
  dragTheArrowToShowMassOnScale: 'Drag the scale to show the mass of {0: number|numberFormat} g.',
  dragTheArrowToShowWhereXIsOnTheNumberLine:
    'Drag the arrow to show where {0:number|numberFormat} is on the number line.',
  dragTheArrowToShowPositionOfNum: 'Drag the arrow to show the position of {0}',
  dragTheArrowToShowPositionOfNumOnNumberLine:
    'Drag the arrow to show the position of {0} on the number line.',
  dragTheBarsToCompleteTheDualBarChart: 'Drag the bars to complete the dual bar chart.',
  dragTheBarsToCompleteTheDualBarChartPdf: 'Draw the bars to complete the dual bar chart.',
  dragTheBarsToCompleteTheBarChart: 'Drag the bars to complete the bar chart.',
  dragTheBarsToCompleteTheBarChartPdf: 'Draw the bars to complete the bar chart.',
  dragTheBase10ToMakeTheNumberX: 'Drag the base 10 to make the number {0:number|numberFormat}',
  dragTheBase10ToRepresentTheNumber:
    'Drag the base 10 to represent the number {0:number|numberFormat}',
  dragTheCardsToMatchPlaceValueCharts:
    'Drag the cards to match the labels to the place value charts.',
  dragACardToCompareTheFractions: 'Drag a card to compare the fractions.',
  dragTheCardsToCompareTheFractions: 'Drag the cards to compare the fractions.',
  dragTheCardsToCompareTheFractionsShadeBarModelsToHelp:
    'Drag the cards to compare the fractions.<br/>Shade the bar models to help.',
  dragTheCardsToCompareTheUnitFractions: 'Drag the cards to compare the unit fractions.',
  dragTheCardsToMatchTheFractionsWordsAndDecimals:
    'Drag the cards to match the equivalent fractions, decimals and words.',
  dragTheCardsToMatchPerimetersToTheShapes: 'Drag the cards to match the perimeters to the shapes.',
  dragTheCardsToOrderTheMassesStartWithTheXMass:
    'Drag the cards to order the masses.<br/>Start with the {0:string|lower} mass.',
  dragTheCardsToOrderXFromYToZ:
    'Drag the cards to order the {0:string|lower} from {1:string|lower} to {2:string|lower}.',
  dragTheCircleToThePointXY: 'Drag the circle to the point ({0},{1}).',
  dragCardsMakeRatioOfCirclesToTrianglesXtoY:
    'Drag the cards to make the ratio of circles to triangles {0} : {1}',
  dragTheCardsToMatchImproperFractionsToMixedNumber:
    'Drag the cards to match the improper fractions to the mixed number.',
  dragWordsToCompleteSentences: 'Drag the words to complete the sentences.',
  dragWordsToDescribeTheTurn: 'Drag the words to describe the turn.',
  drawACrossToThePointXY: 'Draw a cross to the point ({0},{1}).',
  drawARingAroundCircleThatHasDiameterMarkedCorrectly:
    'Draw a ring around the circle that has the diameter marked correctly.',
  drawARingAroundCircleThatHasRadiusMarkedCorrectly:
    'Draw a ring around the circle that has the radius marked correctly.',
  drawArrowEstimatePositionOfNumOnNumberLine:
    'Draw an arrow to estimate the position of {0} on the number line.',
  drawArrowToEstimateWhereFracBelongsOnNumberLine:
    'Draw an arrow to estimate where {0} belongs on the number line.',
  drawCrossesToPlot2PointsWithAXPointOfY:
    'Draw crosses to plot 2 points with a {0} coordinate of {1}',
  drawCrossesToPlotQuadrilateralVertices:
    'Draw crosses to plot the quadrilateral vertices at ({0}, {1}), ({2}, {3}), ({4}, {5}) and ({6}, {7})',
  drawAnArrowToShowPositionOfNumPDF:
    'Draw an arrow to show the position of {0}.\nCircle the correct answer.',
  drawAnArrowToShowPositionOfNumOnNumberLine:
    'Draw an arrow to show the position of {0} on the number line.',
  drawAnAngleLessThanARightAngle: 'Draw an angle less than a right angle.',
  drawAnAngleGreaterThanOneRightAngleButLessThanTwoRightAngles:
    'Draw an angle greater than 1 right angle but less than 2 right angles.',
  drawAngleThatIsXDegrees: 'Draw an angle that is {0:number|numberFormat}°.',
  drawAngleThatIsXDegreesInPieChart:
    'Draw an angle that is {0:number|numberFormat}° within the pie chart.',
  drawArrowToNumberGreaterThanX: 'Draw the arrow to a number that is greater than {0}',
  drawArrowToNumberLessThanX: 'Draw the arrow to a number that is less than {0}',
  drawShapeToTranslateXDxYDy:
    'Draw a {shape} to translate it {x} square{{s}} {dx} and {y} square{{s}} {dy}.',
  drawTheHandsOfClockToShowEndTime: 'Draw the hands on the analogue clock to show the end time.',
  drawAnXAngle: 'Draw an {0: string|lower} angle.',
  drawBaseTenToMakeNumberX: 'Draw base 10 to make the number {0:number|numberFormat}',
  drawTheHandsOfClockToShowTheTime: 'Draw the hands on the analogue clock to show the time.',
  drawCountersInPVCToShow: 'Draw counters in the place value chart to show {0}',
  drawCountersToRepresentNum: 'Draw counters to represent {0}',
  drawCountersIntoPVCToRepresentAnswerTo:
    'Draw counters in the place value chart to represent the answer to {0}',
  drawCountersToMakeNum: 'Draw counters to make {0}',
  drawCountersToMakeNumOnPVC: 'Draw counters to make {0} on the place value chart.',
  drawCountersToShowAnswer: 'Draw counters to show your answer to {0}',
  drawCountersToShowTheAnswerToX: 'Draw counters to show the answer to {0}',
  drawCountersToRepresentNumLess: 'Draw counters to represent the number that is {0} less than {1}',
  drawCountersToRepresentNumMore: 'Draw counters to represent the number that is {0} more than {1}',
  drawCrossToCompleteVerticesOfAShape:
    'Draw a cross to complete the vertices of {0} on the coordinate grid.',
  drawCrossToCompleteVerticesOfShape:
    'Draw a cross to complete the vertices of the {0} on the coordinate grid.',
  drawCrossesToCompleteVerticesOfAShape:
    'Draw crosses to complete the vertices of {0} on the coordinate grid.',
  drawLinesToPlaceCardsInAscendingOrderOfValue:
    'Draw lines to place the cards in ascending order of value.',
  drawPointAtCoordinatesXY: 'Draw a cross at the coordinates ({0},{1})',
  drawPointsToTranslateTriangleXSquaresDX:
    'Draw crosses to translate the triangle {x:number|numberFormat} square{{s}} to the {dx}.',
  drawPointsToTranslateTriangleXSquaresDXAndYSquaresDY:
    'Draw crosses to translate the triangle {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  drawPointsToTranslateTriangleYSquaresDY:
    'Draw crosses to translate the triangle {y:number|numberFormat} square{{s}} {dy}.',
  drawPointToReflectPointInLineL: 'Draw a cross to reflect the point in the line marked L.',
  drawPointsToCompleteTheLineGraph: 'Draw the points to complete the line graph.',
  drawPointsToReflectInMirrorLine: 'Draw points to reflect the shape in the mirror line.',
  drawPointToPlotCoordsToCreateTriangle:
    'Draw points to plot the coordinates {0} to create a triangle.',
  drawPointsToReflectVerticesTriangleInAxis:
    'Draw circles to reflect the vertices of the triangle in the {0}-axis.',
  drawLinesToOrderNumbersFromSmallestToGreatest:
    'Draw lines to order these numbers from smallest to greatest.',
  drawLinesToOrderShapesBySize:
    'Draw lines to put the shapes in order of size. Start with the shape with the smallest area.',
  drawLinesToOrderTheTimes: 'Draw lines to order the times.',
  drawShapeInDiagramAccuratelyOnGrid: 'Draw the shape in the diagram accurately on the grid.',
  drawTheHandsOnClockToShowDigitalTime:
    'Draw the hands on the analogue clock to show the digital time.',
  drawTrianglesMakeRatioOfCirclesToTrianglesXtoY:
    'Draw triangles to make the ratio of circles to triangles {0} : {1}',
  eachContainerHasNumObjects: 'Each {0:string|lower} has {1} {2:string|lower}.',
  eachCounterRepresentsOneX: 'Each counter represents one-{0:string|lower}.',
  eachCubeHasVolume1cm3: 'Each cube has a volume of 1 cm³.',
  eachCubeHasVolume1cm3WhatIsTheVolumeOfTheShape:
    'Each cube has a volume of 1 cm³.<br/>What is the volume of the shape?',
  eachDiagramHas3StraightLines: 'Each diagram has been drawn using three straight lines.',
  eachDiagramHas3StraightLinesCircleWhenYouCanGetUnknownAngle:
    'Each diagram has been drawn using three straight lines.<br/>Circle the diagrams where you can work out the unknown angle.',
  eachDiagramHas3StraightLinesSelectWhenYouCanGetUnknownAngle:
    'Each diagram has been drawn using three straight lines.<br/>Select the diagrams where you can work out the unknown angle.',
  eachGlassContainsNumMlOfJuice:
    'Each glass contains {0:number|numberFormat} ml of juice.<br/>Drag the cards to match the estimated capacity to each glass.',
  eachGlassContainsNumMlOfJuicePDF:
    'Each glass contains {0:number|numberFormat} ml of juice.<br/>Use the cards to match the estimated capacity to each glass.',
  eachGroupHasAnsObjects:
    'Each {0:string|lower} has {1} {2:string|lower}.\nWork out the number of {2:string|lower} {3:string|lower}',
  eachGroupHasNumObjectsCompleteTheTable:
    'Each {0:string|lower} has {1:number|numberFormat} {2:string|lower}.<br/>Complete the table.',
  eachHundredSquareRepresentsOnePoundWhatValueIsShown:
    'Each hundred square represents £1<br/>What value is shown?',
  eachLollyStickXLongFindPerimeter:
    'Each lolly stick is {0} cm long.<br/>Find the perimeter of the rectangle.',
  eachPartOfHundredSquareIsWorthHundredthCircleHundredSquareShowsFractionShaded: `Each part of the hundred square is worth <frac n='1' d='100'/><br/>Circle the hundred square that shows <frac n='{0}' d='100'/> shaded in.`,
  eachPartOfHundredSquareIsWorthHundredthSelectHundredSquareShowsFractionShaded: `Each part of the hundred square is worth <frac n='1' d='100'/><br/>Select the hundred square that shows <frac n='{0}' d='100'/> shaded in.`,
  eachPartOfHundredSquareIsWorthHundredthWhatFractionOfHundredSquareShaded: `Each part of the hundred square is worth <frac n='1' d='100'/><br/>What fraction of the hundred square is shaded?`,
  eachPartOfHundredSquareIsWorthHundredthWhatFractionOfHundredSquareIsNotShaded: `Each part of the hundred square is worth <frac n='1' d='100'/><br/>What fraction of the hundred square is <b>not</b> shaded?`,
  eachSquareRepresents1cm2: 'Each square represents 1 cm²',
  eachXHasMassYDragToCompleteDoubleNumberLine:
    'Each {0: string|lower} has a mass of {1}.<br/>Drag the cards to complete the double number line.',
  eachXHasMassYDragToCompleteDoubleNumberLinePDF:
    'Each {0: string|lower} has a mass of {1}.<br/>Use the cards to complete the double number line.',
  estimateAmountArrow: 'Estimate the amount that the arrow is pointing to.',
  estimateAreaOfLeaf: 'Estimate the area of the leaf.',
  estimateAreaOfShape: 'Estimate the area of the shape.',
  estimateNumberArrowIsPointingTo: 'Estimate the number the arrow is pointing to.',
  estimatePopulationInX: 'Estimate the population in {0}',
  estimatePoundPositionOnNumLine: 'Drag the arrow to estimate the position of {0}',
  estimatePoundPositionOnNumLinePdf: 'Draw an arrow to estimate the position of {0}',
  estimateTotalsAndCompareAmounts: `{0} and {1} have bought these items.\nEstimate their totals and drag the cards to compare the amounts.`,
  estimateTotalsAndCompareAmountsPDF: `{0} and {1} have bought these items.\nEstimate their totals and use ${LESS_THAN}, ${GREATER_THAN} or = to compare the amounts.`,
  evenNumbers: 'Even numbers',
  factorOfXOnly: 'Factor of {0} only',
  factorOfXAndY: 'Factor of {0} and {1}',
  false: 'False',
  farmProducesXCratesOfYFoodLorryCarriesYCrates:
    'A farm produced {0:number|numberFormat} crates of {1:string|lower}.<br/>A lorry can carry {2:number|numberFormat} crates.',
  farmWorkersPickNumFoodOverAYear:
    'Farm workers pick {0:number|numberFormat} {1:string|lower} over a year.<br/>{1} are sold in boxes of {2:number|numberFormat}<br/>How many full boxes can be made?',
  fillInBlankToCompleteAreaXMultY: `Fill in the blank to complete the area {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  fillInBlanksToCompleteAreaXMultY: `Fill in the blanks to complete the area {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  fillInDecimalOnNumberLine: 'Fill in the decimal number on the number line.',
  fillInDecimalsOnNumberLine: 'Fill in the decimal numbers on the number line.',
  fillInMissingDigitToMakeStatementCorrect:
    'Fill in the missing digit to make the statement correct.',
  fillInMissingDigitsToMakeStatementCorrect:
    'Fill in the missing digits to make the statement correct.',
  fillInMissingInfo: 'Fill in the missing information.',
  fillInMissingMinutesOnNumberLine: 'Fill in the missing minutes on the number line.',
  fillInMissingNumber: 'Fill in the missing number.',
  fillInMissingNumbers: 'Fill in the missing numbers.',
  fillInMissingNumbersUseTheDiagramToHelp:
    'Fill in the missing numbers.<br/>Use the diagram to help.',
  fillInMissingNumberOnNumberLine: 'Fill in the missing number on the number line.',
  fillInMissingNumbersToWorkOutPoundsXMinusPoundsYAndZPence: `Fill in the missing numbers to work out <g>£{0:number|numberFormat} ${SUB} £{1:number|numberFormat} and {2:number|numberFormat}p</g>.`,
  fillInMissingNumerator: 'Fill in the missing numerator.',
  fillInMissingNumerators: 'Fill in the missing numerators.',
  fillInMissingNumeratorsCompleteNumberSentences:
    'Fill in the missing numerators to complete the number sentences.',
  fillInMissingNumeratorsMakeStatementCorrect:
    'Fill in the missing numerators to make the statement correct.',
  fillInMissingNumeratorsMakeStatementsCorrect:
    'Fill in the missing numerators to make the statements correct.',
  fillInMixedNumbers: 'Fill in the missing numbers.',
  fillInNumberTracksToHelpCompleteSentence:
    'Fill in the number tracks to help you complete the sentence.',
  fillInTheBlanksToCompleteNumberSentences: 'Fill in the blanks to complete the number sentences.',
  findArea: 'Find the area.',
  findDiffBetweenNumbers: 'Find the difference between the numbers.',
  findPossibleValues:
    '{0} is a prime number. {1} is a multiple of {2:number|numberFormat}<br/>The fraction {3} can be simplified.<br/>Find a pair of possible values.',
  findTheCommonFactorsOfXAndY: 'Find the common factors of {0} and {1}',
  findTheDecimalEquivalentOfEachFrcation: 'Find the decimal equivalent of each fraction.',
  findTheDifferenceOfTheLengthsOfLineAAndLineB:
    'Find the difference of the of the lengths of line A and line B.',
  findTheEquivalentFraction: 'Find the equivalent fraction.',
  findTheEquivalentFractions: 'Find the equivalent fractions.',
  findAnEquivalentFractionTo: 'Find an equivalent fraction to {0}',
  findTheFactorsOfNum: 'Find the factors of {0}',
  findTheFractionsOfTheAmounts: 'Find the fractions of the amounts.',
  findTheSumOfTheLengthsOfLineAAndLineB: 'Find the sum of the lengths of line A and line B.',
  findThreeDifferentEquivalentFractionsToFrac: 'Find three different equivalent fractions to {0}',
  findThreeEquivalentFractions: 'Find three equivalent fractions that simplify to {0}',
  findThreePossiblePairsOfValues: 'Find three possible pairs of values.',
  findValueOfMidpoint: 'Find the value of the midpoint.',
  findValueOfX: 'Find the value of the {0}.',
  fishTankHasABWaterCapacityIsCDHowMuchMoreCanFit:
    'A fish tank has {0:number|numberFormat} litres {1:number|numberFormat} ml of water in it.<br/>The capacity of the fish tank is {2:number|numberFormat} litres {3:number|numberFormat} ml.<br/>How much more water can fit in the fish tank?',
  forEveryTwoAnimalsXBalesPutOutYBalesInAFieldWhatIsMaxInField:
    'For every two {2: string|lower}, a farmer must put out {0} bales of hay.<br/>There are {1} bales of hay in a field.<br/>What is the maximum number of {2: string|lower} that could be put in the field?',
  forEveryTwoAnimalsFarmerMustPutOutXBalesHowManyBalesAreNeededForYAnimals:
    'For every two {2: string|lower}, a farmer must put out {0} bales of hay.<br/>How many bales of hay are needed for {1} {2: string|lower}?',
  forEveryXBalloonThereAreYBalloonsHowManyZBalloons:
    'For every {amount1} {colour1:string|lower} {object1:string|lower}, there are {amount2} {colour2:string|lower} {object2:string|lower}.<br/>There are {total} balloons altogether.<br/>How many {colour3:string|lower} balloons are there?',
  forEveryXBalloonThereIsYBalloonHowManyZBalloons:
    'For every {amount1} {colour1:string|lower} {object1:string|lower}, there is {amount2} {colour2:string|lower} {object2:string|lower}.<br/>There are {total} balloons altogether.<br/>How many {colour3:string|lower} balloons are there?',
  forEveryXColourCountersThereAreYColourCountersCompleteTheTable:
    'For every {colourCountersAmount1} {colour1:string|lower} counters, there are {colourCountersAmount2} {colour2:string|lower} counters. Complete the table.',
  forEveryOneAdultOnSchoolTripThereAreXChildrenThereAreYAdultsOnTripHowManyChildrenAreThere:
    'For every 1 adult on a school trip, there are {0} children.<br/>There are {1} adults on the trip.<br/>How many children are there?',
  forEveryOneFruit1InBoxThereAreFruit2HowManyFruit2AreThere:
    'For every 1 {fruit1} in a box, there are {fruit2Amount} {fruit2}{{s}}. There are {fruit1Amount} {fruit1}{{s}} in the box. How many {fruit2}{{s}} are there?',
  fracsInAscendingOrderDragNumbersToCompleteFractions:
    'These proper fractions are in ascending order.<br/>Drag the cards to complete the fractions.',
  fracsInDescendingOrderDragNumbersToCompleteFractions:
    'These proper fractions are in descending order.<br/>Drag the cards to complete the fractions.',
  fracsInAscendingOrderDragNumbersToCompleteFractionsUseEachNumberOnce:
    'These proper fractions are in ascending order.<br/>Use the cards to complete the fractions. <br/>Use each of the numbers below once.',
  fracsInDescendingOrderDragNumbersToCompleteFractionsUseEachNumberOnce:
    'These proper fractions are in descending order.<br/>Use the cards to complete the fractions. <br/>Use each of the numbers below once.',
  fractionOfAKgOfRiceIsDivided:
    '{0} of a kilogram of rice is divided equally<br/>between two bowls.',
  fractionOfANumberIsXWhatIsTheNumber:
    '{frac} of a number is {number:number|numberFormat}<br/>What is the number?',
  ifXEqualsYZEqualsAWorkOutValues: `If {0} = {1} and {2} = {3}, work out the values of the expressions.`,
  giveAnswerAsFractionAndDecimal: 'Give your answer as a fraction and as a decimal.',
  giveCoordinatesOfPoint: 'Give the coordinates of the point.',
  giveFirst5MultiplesOfNum: 'Give the first 5 multiples of {0:number|numberFormat}',
  giveTheTimeOn24HClockAs12HClockTime:
    'Give the time on the 24-hour clock as a 12-hour clock time.',
  giveYourAnswerInCentimetres: 'Give your answer in centimetres.',
  giveYourAnswerInMillimetres: 'Give your answer in millimetres.',
  halfOfHundredSquareIsShaded: 'Half of the hundred square is shaded.',
  heightsXOrdinalTallest: 'Who is the {0} tallest person?',
  hereAreCoordsOfSquareWhatCouldOtherVerticesBe:
    'Here are coordinates for two vertices of a square.<br/>({0}, {1})      ({2}, {3})<br/>What could the coordinates of the other two vertices be?<br/>Give two possible solutions.',
  hereAreElectricianPrices: "Here are electrician's prices.",
  hereAreFlagsCircleHorizontalStripes:
    'Here are some flags.<br/>Circle the flags that have horizontal stripes.',
  hereAreFlagsCircleVerticalStripes:
    'Here are some flags.<br/>Circle the flags that have vertical stripes.',
  hereAreFlagsSelectHorizontalStripes:
    'Here are some flags.<br/>Select the flags that have horizontal stripes.',
  hereAreFlagsSelectVerticalStripes:
    'Here are some flags.<br/>Select the flags that have vertical stripes.',
  hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesGreatest:
    'Here are four angles labelled in a quadrilateral.<br/>Drag the cards to order the angles from greatest to smallest.',
  hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesGreatestPDF:
    'Here are four angles labelled in a quadrilateral.<br/>Write the angles in order from greatest to smallest.',
  hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesSmallest:
    'Here are four angles labelled in a quadrilateral.<br/>Drag the cards to order the angles from smallest to greatest.',
  hereAreFourAnglesInAQuadrilateralDragTheCorrectOrderOfAnglesSmallestPDF:
    'Here are four angles labelled in a quadrilateral.<br/>Write the angles in order from smallest to greatest.',
  hereAreIngredientsNeededToMakeXFood:
    'Here are the ingredients needed to make {0: number|numberFormat} {1: string|lower}.',
  hereArePlumberPrices: "Here are plumber's prices.",
  hereAreTheIngredientsNeededToMakeFoodACompleteTheTable:
    'Here are the ingredients needed to make {0:string|lower}. Complete the table.',
  hereAreTheMultiplesOfXandYIdentifyCommonMultiple:
    'Here are the multiples of {0:number|numberFormat} and {1:number|numberFormat}<br/>Identify the common multiple from the lists.',
  hereAreTheMultiplesOfXandYIdentifyCommonMultiples:
    'Here are the multiples of {0:number|numberFormat} and {1:number|numberFormat}<br/>Identify the common multiples from the lists.',
  hereAreTwoIdenticalTrianglesWriteCoordsOfPointA:
    'Here are two identical triangles on a horizontal line.<br/>Write the coordinates of point A.',
  hereAreTwoNumberCards: 'Here are two number cards.\nThe sum of the two cards is {0}',
  hereAreTwoPointsStraightLineWhatAreCoordsOfAnotherPoint:
    'Here are two points on a straight line.<br/>What are the coordinates of another point on the line?',
  hereAreSomeShapesCircleAll3DShapes: 'Here are some shapes.<br/>Circle all the 3-D shapes',
  hereAreSomeShapesSelectAll3DShapes: 'Here are some shapes.<br/>Select all the 3-D shapes',
  hereAreValueOfTheCubes: 'Here are the values of the cubes:',
  hereAreWindowCleanerPrices: "Here are window cleaners's prices.",
  hereIsABarModel: 'Here is a bar model.',
  hereIsABusTimetableWhatTimeDoesXArriveAtY:
    'Here is a bus timetable.<br/>What time does {0} arrive at {1}?',
  hereIsABusTimetableWhatTimeDoesXLeaveY:
    'Here is a bus timetable.<br/>What time does {0} leave {1}?',
  hereIsBusTimeTableHowLongDoesItTakeXtoGetFromStopYToStopZ:
    'Here is a bus timetable.<br/>How long does it take {0} to get from {1} to {2}?',
  hereIsARegularXWhatIsThePerimeterOfTheX:
    'Here is a regular {0:string|lower}.<br/>What is the perimeter of the {0:string|lower}?',
  hereIsAnAngleDragLinesToMakeGreaterAngle:
    'Here is an angle.<br/>Drag the lines to make an angle that is greater.',
  hereIsAnAngleDrawAGreaterAngle: 'Here is an angle.<br/>Draw an angle that is greater.',
  hereIsAnAngleDragLinesToMakeSmallerAngle:
    'Here is an angle.<br/>Drag the lines to make an angle that is smaller.',
  hereIsAnAngleDrawASmallerAngle: 'Here is an angle.<br/>Draw an angle that is smaller.',
  hereIsACalendarFromMonthYearSelectDateOfFirst:
    'Here is a calendar from {0} {1}\nSelect the date of the first {2} of {0} {1}',
  hereIsACalendarFromMonthYearSelectDateOfLast:
    'Here is a calendar from {0} {1}\nSelect the date of the last {2} of {0} {1}',
  hereIsACalendarFromMonthYearSelectDateOfFirstPDF:
    'Here is a calendar from {0} {1}\nCircle the date of the first {2} of {0} {1}',
  hereIsACalendarFromMonthYearSelectDateOfLastPDF:
    'Here is a calendar from {0} {1}\nCircle the date of the last {2} of {0} {1}',
  hereIsACalendarFromMonthYearSelectDateOfOrdinalNumber:
    'Here is a calendar from {month} {year}\nSelect the date of the {ordinalNumber} {selectedDay} of {month} {year}',
  hereIsACalendarFromMonthYearSelectDateOfOrdinalNumberPDF:
    'Here is a calendar from {month} {year}\nCircle the date of the {ordinalNumber} {selectedDay} of {month} {year}',
  hereIsACalendarFromMonthYearSelectDayOfWeek:
    'Here is a calendar from {0} {1}\nSelect the day of the week {2} {0} {1} was on.',
  hereIsACalendarFromMonthYearSelectDayOfWeekPDF:
    'Here is a calendar from {0} {1}\nCircle the day of the week {2} {0} {1} was on.',
  hereIsAGridWithMultiplesOfXShadedInCircleMultiplesOfY:
    'Here is a grid with multiples of {0:number|numberFormat} shaded in. Circle the multiples of {1:number|numberFormat}',
  hereIsAHorizontalAndVerticalLineWhatAreCoordsOfIntersection:
    'Here is a horizontal line and a vertical line.<br/>What are the coordinates of the intersection?',
  hereIsAHundredSquareEachSquareRepresents1p:
    'Here is a hundred square.<br/>Each square represents 1p.',
  hereIsAOneDigitNumberOnAPlaceValueChart: 'Here is a 1-digit number on a place value chart.',
  hereIsAPictureOfObject: 'Here is a picture of a {0:string|lower}.',
  hereIsAShapeOnCmSquaredGridCircleArea:
    'Here is a shape on a centimetre squared grid.<br/>Circle the estimated area of the shape.',
  hereIsAShapeOnCmSquaredGridSelectArea:
    'Here is a shape on a centimetre squared grid.<br/>Select the estimated area of the shape.',
  hereIsASquareWhatAreCoordsOfOtherVertices:
    'Here is a square.<br/>What are the coordinates of the other two vertices?',
  hereIsASquareWriteCoordsOfOtherVertices:
    'Here is a square.<br/>Write the two coordinates of the other vertices.',
  hereIsAnEquation: 'Here is an equation.',
  hereIsAnExtractFromATVGuideHowLongIsXOnFor: `Here is an extract from a TV guide.<br/>How long is '{0}' on for?<br/>Select your answer.`,
  hereIsAnExtractFromATVGuideHowLongIsXOnForCircleAnswer: `Here is an extract from a TV guide.<br/>How long is '{0}' on for?<br/>Circle your answer.`,
  hereIsAnExtractFromATVGuideWhatTimeDoesXFinish: `Here is an extract from a TV guide.<br/>What time does '{0}' finish?<br/>Select your answer.`,
  hereIsAnExtractFromATVGuideWhatTimeDoesXFinishCircleAnswer: `Here is an extract from a TV guide.<br/>What time does '{0}' finish?<br/>Circle the correct answer.`,
  hereIsAnExtractFromATVGuideWhatTimeDoesXStart: `Here is an extract from a TV guide.<br/>What time does '{0}' start?<br/>Select your answer.`,
  hereIsAnExtractFromATVGuideWhatTimeDoesXStartCircleAnswer: `Here is an extract from a TV guide.<br/>What time does '{0}' start?<br/>Circle the correct answer.`,
  hereIsMassOfAParcel: 'Here is the mass of a parcel.',
  hereIsAnArrayOfNumCounters: 'Here is an array of {0:number|numberFormat} counters.',
  hereIsOneWayCompleteCalcs:
    'Here is one way of working out {0} mentally.<br/>Complete the calculations.',
  hereIsOneWayDragCardsToCompleteCalcs:
    'Here is one way of working out {0} mentally.<br/>Drag the cards to complete the calculations.',
  hereIsOneWayUseCardsToCompleteCalcs:
    'Here is one way of working out {0} mentally.<br/>Use the cards to complete the calculations.',
  hereIsRectangleWhatIsSizeOfAngleMarkedP: `Here is a rectangle. What is the size of the angle marked ${ALGEBRAIC_P}?`,
  hereIsSomeFoodCutIntoSlices: 'Here is some {0} cut into {1}.',
  hereIsStrategyForMultBy5: 'Here is a strategy for multiplying numbers by 5',
  hereIsQuadrilateralHowManyEqualSidePairs:
    'Here is a {0}.<br/>How many pairs of equal sides does this shape have?',
  hereIsQuadrilateralHowManyParallelLinePairs:
    'Here is a {0}.<br/>How many pairs of parallel lines does this shape have?',
  hereIsQuadrilateralHowManyRightAngles:
    'Here is a {0}.<br/>How many right angles does this shape have?',
  hereIsStraightLineWithXAndYIsZOnTheLine:
    'Here is a straight line with two coordinates {0} and {1}.<br/>Is {2} on the same straight line?',
  herePlaceValueChart: 'Here is a place value chart.',
  hereValuesOfShapes: 'Here are the values of the shapes:',
  hasTheLineGraphBeenDrawnAccurately: 'Has the line graph been drawn accurately?',
  hasTheTriangleBeenReflectedInAxis: 'Has the triangle been reflected in the {0}-axis?',
  hockeyTeamPlayXGamesTheMeanWasYHowManyGoalsInTotalXGames:
    'A hockey team played {0} games.<br/>The mean number of goals scored was {1}<br/>How many goals did they score in total in the {0} games?',
  howFarCycledFraction:
    '{0} cycles {1}km and then has a rest. Later, {0} cycles {2}km.<br/>How far does {0} cycle in total?',
  howLongDoesTheRollercoasterRideLastInMinutesAndSeconds:
    'How long does the rollercoaster ride last in minutes and seconds?',
  howLongIsTheArrow: 'How long is the arrow?',
  howLongIsTheLine: 'How long is the line?',
  howLongIsTheLineGiveAnswerInCmAndMm:
    'How long is the line?<br/>Give your answer in both centimetres and millimetres.',
  howLongIsTheX: 'How long is the {0:string|lower}?',
  howManyAdultsVisitedTheMuseumInX: 'How many adults visited the museum in {0}?',
  howManyChildrenVisitedTheMuseumInX: 'How many children visited the museum in {0}?',
  howManyCubesNeeded: 'How many cubes do you need to make the 3-D shape?',
  HowManyDifferentCombinationsOfBreadAndFillingsCompleteTheSentence:
    'How many different combinations of bread and fillings are there? Complete the sentence.',
  howManyDegreesDidXTurnThrough: 'How many degrees did {0} turn through?',
  howManyDinnersDoesTheSchoolServeInAHalfTerm:
    'How many dinners does the school serve in a half term?',
  howManyFacesEdgesVerticesDoesTheShapeHave:
    'How many faces, edges and vertices does the shape have?',
  howManyLinesOfSymmetryDoesShapeHave: 'How many lines of symmetry does the {0} have?',
  howManyMoreAdultsThanChildrenVisitedInX: 'How many more adults than children visited in {0}?',
  howManyMoreAdultsThanChildrenVisitedInXPDF:
    'How many more adults than children visited in {0}?<br/>Circle your answer.',
  howManyMoreChildrenThanAdultsVisitedInX: 'How many more children than adults visited in {0}?',
  howManyMoreChildrenThanAdultsVisitedInXPDF:
    'How many more children than adults visited in {0}?<br/>Circle your answer.',
  howManyUnits:
    'At a sports day, the children drink {0} l of water. Each child drinks {1} ml. How many children are at the sports day?',
  howMany1000s: 'How many thousands are there?',
  howMany100s: 'How many hundreds are there?',
  howManyBoxesCanBeMade: 'How many full boxes can be made?',
  howManyChildrenAreThereInTotalInKeyStageX:
    'How many children are there in total in key stage {0:number|numberFormat}?',
  howManyChildrenAreThereInTotalInYearX:
    'How many children are there in total in Year {0:number|numberFormat}?',
  howManyChildrenInTotalTravelledToSchoolByXandByY:
    'How many children in total travelled to school by {0:string|lower} and by {1:string|lower}?',
  howManyChildrenInYearXPreferY:
    'How many children in Year {0:number|numberFormat} prefer {1:string|lower}?',
  howManyChildrenVisitedXCountry: 'How many children visited {0}?',
  howManyChildrenHadDinnerOnX: 'How many children had a school dinner on {0}?',
  howManyChildrenHadPackedLunchOnX: 'How many children had a packed lunch on {0}?',
  howManyChildrenHaveAX: 'How many children have a {0:string|lower}?',
  howManyChildrenHaveAXorAY: 'How many children have a {0:string|lower} or a {1:string|lower}?',
  howManyChildrenHaveVisitedXCountry: 'How many children have visited {0}?',
  howManyChildrenInTotalHaveAPet: 'How many children in total have a pet?',
  howManyEqualPartsHasNumberLineBeenSplitInto:
    'How many equal parts has the number line been split into?',
  howManyIceCreamsWereSoldInTotal: 'How many ice creams were sold in total?',
  howManyJumpsFromXtoZero: 'How many jumps are there from {0} to zero?',
  howManyLitresLeftFraction:
    '{0} has {1} litres of {2}.<br/>{0} pours out {3} litres of {2}.<br/>How many litres of {2} does {0} have left?',
  howManyMillimetresOfRainfallWereThereInCityInMonth:
    'How many millimetres of rainfall were there in {0} in {1}?',
  howManyMinutesBetweenTheseTwoTimes: 'How many minutes are there between these two times?',
  howManyMoreChildrenPreferXThanY:
    'How many more children prefer {0:string|lower} than {1:string|lower}?',
  howManyMoreChildrenPreferXThanYInYearZ:
    'How many more children prefer {0:string|lower} than {1:string|lower} in Year {2:number|numberFormat}?',
  howManyMoreChildrenHaveAXThanAY:
    'How many more children have a {0:string|lower} than a {1:string|lower}?',
  howManyMoreCubesAreThereInCuboidAThanCuboidB:
    'How many more cubes are there in cuboid A than cuboid B?',
  howManyMoreVisitorsWentToTheXOptionOnDayXThanOnDayY:
    'How many more visitors went to the {0:string|lower} on {1} than on {2}?',
  howManyObjectsAreThere: 'How many {0:string|lower} are there?',
  howManyPenceAreThere: 'How many pence are there?',
  howManyPointsWereScoredInTotal: 'How many points were scored in total?',
  howManyPointsDidXScoreInTotal: 'How many points did {0} score in total?',
  howManyPointsWereScoredInTotalInRoundX:
    'How many points were scored in total in round {0:number|numberFormat}?',
  howManyPointsWereScoredByCharacterInRoundN:
    'How many points were scored by {0} in round {1:number|numberFormat}?',
  howManyRightAngles: 'How many right angles does this shape have?',
  howManySmallCubesAreInThisCube: 'How many small cubes are in this cube?',
  howManySpacesAreEmpty: 'How many spaces are empty?',
  howManyUnreservedSeats: 'How many unreserved seats are there?',
  howManyXCoinsWouldYouNeedToMakeX: 'How many {0}p coins would you need to make {1:string|lower}?',
  howManyXWatchedTheFilmThatDay: 'How many {0:string|lower} watched the film that day?',
  howManyXWatchedTheYPmShowing: 'How many {0:string|lower} watched the {1} pm showing?',
  howMuchChangeWouldYouGetFromAXPoundCoin: 'How much change would you get from a £{0} coin?',
  howMuchChangeWouldYouGetFromAXPoundNote: 'How much change would you get from a £{0} note?',
  howMuchDidTheTemperatureIncreaseOrDecreaseBetweenOptionXAndOptionY:
    'How much did the temperature {0:string|lower} between {1:string|lower} and {2:string|lower}?',
  howMuchHeavierAreThePotatoesThanCarrots:
    '{0} has {1} kg of potatoes.<br/>{0} has {2} kg of carrots.<br/>How much heavier are the potatoes than the carrots?<br/>Give your answer in its simplest form.',
  howMuchJuiceLeftFraction:
    'A jug is filled with {0} of a litre of a juice.<br/>• {1} drinks {2}litres of juice.<br/>• {3} drinks {4}litres of juice.<br/>How much juice is left in the jug?<br/>Give your answer in its simplest form.',
  howMuchJuiceIsThereAltogether: 'How much juice is there altogether?',
  howMuchMoney: 'How much money is there?',
  howMuchMoneyCouldCharacterHave: 'How much money could {0} have?',
  howMuchMoneyIsThereWriteYourAnswersAsADecimal:
    'How much money is there?<br/>Write your answer as a decimal.',
  howMuchMoreJuiceToMake1Litre: 'How much more juice is needed to make 1 litre?',
  howMuchMoreOfTheBookHasCharacterAReadThanCharacterB:
    '{characterA} and {characterB} are reading the same book.<br/>{characterA} has read {fracA} of the book.<br/>{characterB} has read {fracB} of the book.<br/>How much more of the book has {characterA} read than {characterB}?',
  howMuchMoreRainfallWasThereInCity1ThanCity2InMonth:
    'How much more rainfall was there in {0} than {1} in {2}?',
  howMuchOfTheBarModelIsShaded: 'How much of the bar model is shaded?',
  howMuchPetrolLeftFraction:
    'Dr Fisher has {0} of a tank of petrol in their car. He drives to see their friend and uses {1} of a tank of petrol.<br/><br/>What fraction of the tank of petrol is left in the tank? Give your answer in its simplest form.',
  howMuchWaterIsInTheJug: 'How much water is in the jug?',
  howMuchWaterIsThereInTotal: 'How much water is there in total?',
  howMuchXHeighthWasThePlantOnDayYThanOnDayZ:
    'How much {0:string|lower} was the plant on day {1:number|numberFormat} than day {2:number|numberFormat}?',
  howManyTimesDoesXGoIntoY:
    'How many times does {0:number|numberFormat} go into {1:number|numberFormat}?',
  howManyTripsWillItTakeToTransportCrates: 'How many trips will it take to transport the crates?',
  howTallIsCharacter: 'How tall is {0}?',
  howTallIsObject: 'How tall is {0:string|lower}?',
  howTallIsTheTower: 'How tall is the tower?',
  hundredSquareRepresentsWholeHowManyXAreShaded:
    'The hundred square represents one whole.\nHow many {measure} are shaded?',
  hundredSquareRepresentsWholeHowManyXAreNotShaded:
    'The hundred square represents one whole.\nHow many {measure} are not shaded?',
  ifXEquals1WhatDecimalIsRep: 'If {0} = 1, what decimal is represented?',
  ifXEquals1WhatFracIsRepresented: 'If {0} = 1, what fraction is represented?',
  ifXEquals1RepresentFracUsingBase10: 'If {0} = 1, represent {1} using base 10',
  ifXEquals1RepresentFracByDraggingBase10:
    'If {0} = 1, represent {1} by dragging the base 10 blocks.',
  ifXPercentOfNumberIsYWhatIsTheNumber:
    'If {0:number|numberFormat}% of a number is {1:number|numberFormat}, what is the number?',
  ifYouKnow10PercentOfANumberYouCanMultiplyBy10ToFindTheWhole:
    'If you know 10% of a number, you can multiply by 10 to find the whole.',
  inAClassOfXChildrenFracOfClassHaveBrotherOrSister:
    'In a class of {numOfChildren} children, {fracOfClass} of the class have a brother or sister.',
  interpretSquareNumberNotation: 'What does {0:number|numberFormat}² mean? Select the answer.',
  interpretSquareNumberNotationPDF: 'What does {0:number|numberFormat}² mean? Circle your answer.',
  interpretCubeNumberNotation: 'Select the calculation which is equal to {0:number|numberFormat}³',
  interpretCubeNumberNotationPDF:
    'Circle the calculation which is equal to {0:number|numberFormat}³',
  isCharacterCorrect: `Is {0} correct? Select your answer.`,
  isCharacterCorrectPDF: `Is {0} correct?\nCircle your answer.`,
  selectWhetherTheAngleBetweenTheHandsOfTheClockIsAcuteOrObtuse:
    'Select whether the angle between the hands of the clock is acute or obtuse.',
  isTheAngleBetweenTheHandsOfTheClockAcuteOrObtuseCircleAnswer:
    'Is the angle between the hands of the clock acute or obtuse. Circle your answer.',
  isTheAreaOfTheShapeXMultYCircleTrueOrFalse: `Is the area of the parallelogram {0:number|numberFormat} ${MULT} {1:number|numberFormat} = {2:number|numberFormat} cm²?<br/>Circle if the statement is true or false.`,
  isTheAreaOfTheShapeXMultYSelectTrueOrFalse: `Is the area of the parallelogram {0:number|numberFormat} ${MULT} {1:number|numberFormat} = {2:number|numberFormat} cm²?<br/>Select if the statement is true or false.`,
  isTheAreaOfTheTriangleXMultYDiv2CircleTrueOrFalse: `Is the area of the triangle {0:number|numberFormat} ${MULT} {1:number|numberFormat} ${DIV} 2 = {2:number|numberFormat} cm²?<br/>Circle if the statement is true or false.`,
  isTheAreaOfTheTriangleXMultYDiv2SelectTrueOrFalse: `Is the area of the triangle {0:number|numberFormat} ${MULT} {1:number|numberFormat} ${DIV} 2 = {2:number|numberFormat} cm²?<br/>Select if the statement is true or false.`,
  isStatementTrueOrFalse: 'Is the statement true or false?',
  isStatementTrueOrFalseARectangleCanBeMadeUsingNumSquares:
    'Is the statement true or false?<br/>A rectangle can be made using {0:number|numberFormat} smaller squares on the grid.',
  isStatementTrueOrFalseARectangleCanBeMadeUsingNumSquaresPDF:
    'Is the statement true or false?<br/>A rectangle can be made using {0:number|numberFormat} smaller squares on the grid.<br/>Circle the correct answer.',
  isStatementTrueOrFalseASquareCanBeMadeUsingNumSquares:
    'Is the statement true or false?<br/>A square can be made using {0:number|numberFormat} smaller squares.',
  isStatementTrueOrFalseASquareCanBeMadeUsingNumSquaresPDF:
    'Is the statement true or false?<br/>A square can be made using {0:number|numberFormat} smaller squares.<br/>Circle the correct answer.',
  isStatementTrueOrFalsePDF: 'Is the statement true or false?\nCircle the correct answer.',
  isXSquareNumber: 'Is {0:number|numberFormat} a square number?',
  isXAFactorOfYCircleYourAnswer:
    'Is {0:number|numberFormat} a factor of {1:number|numberFormat}? Circle your answer.',
  isXAFactorOfYSelectYourAnswer:
    'Is {0:number|numberFormat} a factor of {1:number|numberFormat}? Select your answer.',
  itTakesMrSmithXHoursAndYMinsToDriveToWork:
    'It takes Mr Smith {0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minutes to drive to work.<br/>He arrives at work at {2}:{3} am.<br/>What time did he set off?',
  itemsArePackedInBoxesOfEitherXorYHowManyOfEachTypeOfBoxAreNeeded:
    '{objects} are packed in boxes of either {number1:number|numberFormat} or {number2:number|numberFormat}<br/>There are {number3:number|numberFormat} {objects:string|lower}.<br/>All the {objects:string|lower} need to be packed into the same size boxes.<br/>How many of each type of box are needed?',
  labelTheFractionOnTheNumberLine: 'Label the fraction on the number line.',
  labelThermometerCorrectly: 'Label the thermometer correctly.',
  listCommonFactorsOfXAndY:
    'List the common factors of {0:number|numberFormat} and {1:number|numberFormat}',
  listTheFactorsOfNum: 'List the factors of {0:number|numberFormat}',
  listTheFirstThreeCommonMultiplesOfXAndY: 'List the first three common multiples of {0} and {1}',
  machineMakesXObjectsInYHowManyInZ:
    'A machine can make {0} {1:string|lower} in {2} seconds.<br/>How many {1:string|lower} does the machine make in {3} seconds?',
  makeCorrectCalcToWorkOutLenOfX:
    'Make the correct calculation to work out the length of {0:string|lower}.',
  makeGreatestTotalByAddingTwoOfTheNumbers:
    'Make the greatest total you can by adding two of the numbers.',
  makeStatementsCorrect: 'Make the statements correct.',
  matchAdditionsToNumberExchanges: 'Match the additions to the number of exchanges.',
  matchAmountsThatAreEqual: 'Match the amounts that are equal.',
  matchCalcs: 'Match the calculations.',
  matchStatementsToEquivalantAlgebraicExpression:
    'Match each statement to the algebraic statement.',
  matchStatementsToAlgebraicExpression: 'Match each statement to the algebraic expression.',
  dragCardsToMatchCalcs: 'Drag the cards to match the calculations.',
  dragCardsToMatchLabelsToAngles: 'Drag the cards to match the labels to the angles.',
  matchCalcToRepresentation: 'Match the calculation to the representation.',
  matchCardsToCompleteStatements: 'Match the cards to complete the statements.',
  matchEquivalentCalcs: 'Match the equivalent calculations.',
  matchEquivalentFracs: 'Match the equivalent fractions.',
  matchEquivalentMeasurements: 'Match the equivalent measurements.',
  matchFractionToDivision: 'Match the fractions to the divisions.',
  dragCardsToMatchFractionToDivision: 'Drag the cards to match the fractions to the divisions.',
  matchHowManyDaysThereAreInEachMonth:
    'Drag the cards to match how many days there are to each month in a non-leap year.',
  matchHowManyDaysThereAreInEachMonthPDF:
    'Use the cards to match how many days there are to each month in a non-leap year.',
  matchMeasuresToMetricUnits: 'Match the measure to its metric units.',
  matchMultiplesToDescriptions: 'Match the multiples to the descriptions.',
  matchNumbers: 'Match the numbers.',
  matchNumberLinesToIntervals:
    'Drag the cards to match the number lines to the number of intervals.',
  matchNumberWithCalcs: 'Match the numbers with the calculations.',
  matchNumbersWithStatements: 'Match the numbers with the statements.',
  matchNumberSentencesToPictures: 'Match the number sentences to the pictures.',
  useCardsCompareMasses: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the masses.`,
  dragTheCardsToCompleteTheNumberSentences: 'Drag the cards to complete the number sentences.',
  useTheCardsToCompleteTheNumberSentences: 'Use the cards to complete the number sentences.',
  useTheCardsToCompleteTheNumberSentence: 'Use the cards to complete the number sentence.',
  useTheCardsToContinueTheSequence: 'Use the cards to continue the sequence.',
  matchNumbersToRomanNumerals: 'Match the numbers to the Roman numerals.',
  matchRomanNumeralsToNumbers: 'Match the Roman numerals to the Roman numbers.',
  matchSequenceWithRule: 'Match the sequence with the rule.',
  dragTheCardsToMatchSequenceWithRule: 'Drag the cards to match the sequence with the rule.',
  matchStatementToCalc: 'Match the statements to each calculation.',
  dragTheCardsToMatchStatementToCalc: 'Drag the cards to match the statements to each calculation.',
  matchStatementsToValues: 'Match the statements to the values.',
  dragTheCardsToMatchThePairsOfDecimlasThatAddTogetherToMakeOneWhole:
    'Drag the cards to match the pairs of decimals that add together to make 1 whole.',
  dragTheCardsToMatchTheBaseAndHeightOfTriangle: `Drag the cards to match the base, ${ALGEBRAIC_B}, and the perpendicular <g>height, ${ALGEBRAIC_H},</g> on the triangle.`,
  dragTheCardsToMatchTheSentences: 'Drag the cards to match the sentences.',
  dragTheCardsToMatchTheStatements: 'Drag the cards to match the statements.',
  dragTheCardsToMatchTheStatementsWithPictures:
    'Drag the cards to match the statements with the pictures.',
  matchTheDescriptionToTheCorrectNumber:
    'Drag the cards to match the numbers to the correct description.',
  matchTheDescriptionToTheCorrectNumberPDF:
    'Use the cards to match the numbers to the correct description.',
  matchTheOperations: 'Match the operations.',
  matchTheTimes: 'Match the times.',
  matchQuestionsToTheAnswers: 'Match the questions to the answers.',
  matchRemaindersToDivisions: 'Match the remainders to the divisions.',
  dragTheCardsToMatchRemaindersToDivisions:
    'Drag the cards to match the remainders to the divisions.',
  measureTheSidesOfRegularXShape: 'Measure the sides of the regular {0:string|lower}.',
  measureTheSidesOfRegularXShapeYouCanDragTheXShapeToHelp:
    'Measure the sides of the regular {0:string|lower}.<br/>You can drag the {0:string|lower} to help you.',
  measureTheSizeOfEachAngleDragTheCircleToMoveTheProtractor:
    'Measure the size of each angle.<br/>Drag the circle to move the protractor.',
  measureTheSizeOfEachAngleYouWillNeedAProtractorToHelpYou:
    'Measure the size of each angle.<br/>You will need a protractor to help you.',
  meanNumberOfXisYWorkOutMissingValue:
    'The mean number of {0} is {1}<br/>Work out the missing value.',
  measureTheSizeOfTheAngle: 'Measure the size of the angle.',
  measureTheSizeOfTheAngleXDragTheCircleToMoveTheProtractor:
    'Measure the size of the angle {0}.<br/>Drag the circle to move the protractor.',
  measureTheSizeOfTheAngleXYouWillNeedAProtractorToHelpYou:
    'Measure the size of the angle {0}.<br/>You will need a protractor to help you.',
  measureTheSizeOfTheAngleDragTheCircleToMoveTheProtractor:
    'Measure the size of the angle.<br/>Drag the circle to move the protractor.',
  measureTheSizeOfTheAngleDragToRotateProtractor:
    'Measure the size of the angle.<br/>Drag the circle to rotate the protractor.',
  measureTheSizeOfTheAngleYouWillNeedAProtractorToHelpYou:
    'Measure the size of the angle.<br/>You will need a protractor to help you.',
  mentallyWorkOutCalc: 'Mentally work out the calculation.',
  mentallyWorkOutSubtractions: 'Mentally work out the subtractions.',
  moveTheHandsOfClockToShowDigitalTime:
    'Move the hands of the analogue clock to show the digital time.',
  moveTheHandsOfClockToShowEndTime: 'Move the hands of the clock to show the end time.',
  moveTheHandsOfClockToShowTheTime: 'Drag the hands of the analogue clock to show the time.',
  moveTheHandsOfClockToShowX: 'Drag the hands of the clock to show {0}',
  moveTheHandsOfClockToShowTheNewTime:
    'The time is {0}.\nMove the clock hands to show what time it will be in {1}.',
  moveTheHandsOfClockToShowTheNewTimePDF:
    'The time is {0}.\nDraw hands on the clock to show what time it will be in {1}.',
  moveTheLinesToMakeAnAngleLessThanARightAngle:
    'Move the lines to make an angle less than a right angle.',
  moveTheLinesToMakeAnAngleThatIsGreaterThanOneRightAngleButLessThanTwoRightAngles:
    'Move the lines to make an angle that is greater than 1 right angle but less than 2 right angles.',
  mrSmithIsTilingHisKitchen:
    'Mr Smith is tiling their kitchen floor.<br/>Each tile costs {0:number|numberFormat}p.<br/>How much does it cost to tile the floor?',
  msSmithIsPrintingTheSchoolNewspaper: 'Ms Smith is printing the school newspaper.',
  multiplesOfXOnly: 'Multiple of {0} only',
  multiplesOfXAndY: 'Multiple of {0} and {1}',
  notPrime: 'Not prime',
  numberOfShapesNumberOfLollySticks: `${ALGEBRAIC_S} = number of shapes, ${ALGEBRAIC_L} = number of lolly sticks.`,
  numChildrenAndAdultsGoOnASchoolTrip:
    '{0:number|numberFormat} children and adults go on a school trip.<br/>{1} of the people are children.<br/>How many children are there on the school trip?<br/>Select the correct answer.',
  numLitresOfWaterIsSharedEqually:
    '{0:number|numberFormat} litres of water is shared equally between {1:number|numberFormat} glasses.<br/>How much water is in each glass?<br/>Select all the correct answers.',
  numLitresOfWaterIsSharedEquallyPDF:
    '{0:number|numberFormat} litres of water is shared equally between {1:number|numberFormat} glasses.<br/>How much water is in each glass?<br/>Circle all the correct answers.',
  oddNumbers: 'Odd numbers',
  oneAngleInIsoscelesTriangleIsXCircleOtherAngles: `One angle in an isosceles triangle is {0:number|numberFormat}${DEGREES}.<br/>Circle what the other angles could be.`,
  oneAngleInIsoscelesTriangleIsXSelectOtherAngles: `One angle in an isosceles triangle is {0:number|numberFormat}${DEGREES}.<br/>Select what the other angles could be.`,
  oneAngleInTriangleIsXAnotherIsYSizeOfFirstWhatIsSizeOfThirdAngle:
    'One of the angles in a triangle is {0:number|numberFormat}°.<br/>Another angle is {1} the size of the first angle.<br/>What is the size of the third angle?',
  onTheGridTheAreaOfEachSquareIsX: 'On the grid, the area of each square is {0}',
  onXDayYTicketsWereSold: 'On {0} {1} tickets were sold.',
  oneCircleIs2IceCreamsHowManyWereSold: 'How many {0:string|lower} ice creams were sold?',
  oneFootEq12Inches: '1 foot = 12 inches',
  oneInchApproxEqualCm: `1 inch ${APPROX_EQUAL} 2.5 cm`,
  oneKgIs1000g: '1 kg is 1,000 times the size of 1 gram.',
  oneMetreIs100Cm: '1 metre is 100 times the size of 1 centimetre.',
  oneObjectCostsXCompleteDoubleNumberLine:
    'One {0} costs {1}p.<br/>Complete the double number line.',
  oneOfTheTimesIsWhenCharCyclesToSchool:
    'One of the times is when {0} cycles to school. Select the time you think it is.',
  oneOfTheTimesIsWhenCharEatsBreakfast:
    'One of the times is when {0} eats breakfast. Select the time you think it is.',
  oneOfTheTimesIsWhenCharGetsReadyForBed:
    'One of the times is when {0} gets ready for bed. Select the time you think it is.',
  oneOfTheTimesIsWhenCharGoesToBed:
    'One of the times is when {0} goes to bed. Select the time you think it is.',
  oneOfTheTimesIsWhenCharReadsABedtimeStory:
    'One of the times is when {0} reads a bedtime story. Select the time you think it is.',
  oneOfTheTimesIsWhenCharStartsSchool:
    'One of the times is when {0} starts school. Select the time you think it is.',
  oneOfTheTimesIsWhenCharWalksToSchool:
    'One of the times is when {0} walks to school. Select the time you think it is.',
  oneOfTheTimesIsWhenCharCyclesToSchoolPDF:
    'One of the times is when {0} cycles to school.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharEatsBreakfastPDF:
    'One of the times is when {0} eats breakfast.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharGetsReadyForBedPDF:
    'One of the times is when {0} gets ready for bed.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharGoesToBedPDF:
    'One of the times is when {0} goes to bed.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharReadsABedtimeStoryPDF:
    'One of the times is when {0} reads a bedtime story.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharStartsSchoolPDF:
    'One of the times is when {0} starts school.<br/>Circle the time you think it is.',
  oneOfTheTimesIsWhenCharWalksToSchoolPDF:
    'One of the times is when {0} walks to school.<br/>Circle the time you think it is.',
  onePoundEq16ounces: '1 pound (lb) = 16 ounces',
  oneStoneEq14pounds: '1 stone = 14 pounds (lb)',
  oneTen100LessOrMoreThanNumberIsAns: '{0} {1} than {2} is <ans />',
  orderShapesBySize:
    'Drag the shapes in order of size.<br/>Start with the shape with the smallest area.',
  orderFractionsFromGreatestToSmallest:
    'Drag the cards to order these fractions from greatest to smallest.',
  orderFractionsFromSmallestToGreatest:
    'Drag the cards to order these fractions from smallest to greatest.',
  orderFractionsStartingWithGreatest:
    'Drag the cards to order these fractions, starting with the greatest.',
  orderFractionsStartingWithSmallest:
    'Drag the cards to order these fractions, starting with the smallest.',
  orderNumbersFromGreatestToSmallest:
    'Drag the cards to order these numbers from greatest to smallest.',
  orderNumbersFromSmallestToGreatest:
    'Drag the cards to order these numbers from smallest to greatest.',
  orderTheTimes: 'Drag the cards to order the times.',
  orderTheTimesOfDayInOrderOfWhenTheyHappen:
    'Drag the cards to order the times of day in the order of when they happen.',
  orderTheseMeasurementsFromSmallestToGreatest:
    'Drag the cards to order these measurements from smallest to greatest.',
  orderTheseTimesFromEarliestToLatest:
    'Drag the cards to order these times from earliest to latest.',
  partitionNumberIntoParts: 'Partition the number into its parts.',
  partitionNumberUsePVCCounters:
    'Partition the number.<br/>Use the place value counters to help you.',
  partitionXThreeDifferentWays: 'Partition {0} in three different ways.',
  patternIsMadeUsingXandY: 'A pattern is made using {0} and {1}.',
  pencilsArePackedInBoxesOfXThereAreYPencils:
    'Pencils are packed in boxes of {0:number|numberFormat}.<br/>There are {1:number|numberFormat} pencils.',
  pencilCaseHasFractionXWhatIsRatio: `A pencil case contains felt tips and pencils.<br/>{0} of the contents are {1:string|lower}.<br/>What is the ratio of felt tips to pencils?`,
  perimeterEqualsXCm: 'Perimeter = {0:number|numberFormat} cm',
  perimeterIs: 'The perimeter is {0}',
  perimeterOfShapeIsX: 'The perimeter of the shape is {0}',
  perimeterOfShapeIsXWorkOutUnknownLength:
    'The perimeter of the {0} is {1}<br/>Work out the unknown length.',
  perimeterOfRegularXisYMmWhatIsLengthOfEachSide:
    'The perimeter of a regular {0} is {1} mm.<br/>What is the length of each side?',
  perimeterOfTrapeziumIsXcmSideIsTwiceLengthOfSideCalculateTheLengthOfSide: `The perimeter of the trapezium is {0}cm. Side ${ALGEBRAIC_Y} is twice the length of the side ${ALGEBRAIC_X}. Calculate the length of side ${ALGEBRAIC_Y}.`,
  placeCardsAscendingOrder: 'Place these cards in ascending order.',
  placeCardsDescendingOrder: 'Place these cards in descending order.',
  plotVertexOfTriangleThatHasAreaX: 'Plot the vertex of a triangle that has an area of {0}.',
  pizzeriaOffersChoiceOfXBasesAndYToppingsHowManyCombinations:
    'A pizzeria offers a choice of {0} bases and {1} toppings.<br/>Complete the workings to work out how many different combinations of pizza there are.',
  prime: 'Prime',
  pointAHasCoordsXY1AfterTranslationXY2CompleteSentence:
    'Point {0} has the coordinates <g>({1:number|numberFormat}, {2:number|numberFormat})</g>.<br/>After being translated, it has the coordinates <g>({3:number|numberFormat}, {4:number|numberFormat})</g>.<br/>Complete the sentence to describe the translation.',
  pointAHasCoordinatesHasBeenTranslatedXDXAndYDYCircleCoordAfter:
    'Point A has coordinates {coord}<br/>Point A is translated {x} squares {dx} and {y} squares {dy}.<br/>Circle the coordinates of the point when it has been translated.',
  pointAHasCoordinatesHasBeenTranslatedXDXAndYDYSelectCoordAfter:
    'Point A has coordinates {coord}.<br/>Point A is translated {x} squares {dx} and {y} squares {dy}.<br/>Select the coordinates of the point when it has been translated.',
  pointAIsReflextedInAxesToGiveBWhatAreCoordsOfB:
    'Point A is reflected in the {0}-axis to give point B.<br/>What are the coordinates of point B?',
  putTheDurationsOfTimeInOrder: 'Drag the cards to put the durations of time in order.',
  putTheseAmountsInAscendingOrder: 'Drag the cards to put these amounts in ascending order.',
  putTheseAmountsInDescendingOrder: 'Drag the cards to put these amounts in descending order.',
  radiusIsXWhatIsDiameter:
    'The radius of a circle is {0:number|numberFormat} {1}.<br/>What is the diameter?',
  ratioOfFruitsIsxWhatIsFractionOfY:
    'The ratio of {0:string|lower} to {1:string|lower} is {2}<br/>What fraction of the fruits are {3:string|lower}?',
  rectHasSameAreaAsSquareCalculateHeight:
    'The rectangle has the same area as the square. Work out the height of the rectangle.',
  rectangleHasPerimOfXWorkOutUnknown:
    'The rectangle has a perimeter of {0:number|numberFormat} cm.<br/>Work out the unknown length.',
  recordFormulaToShowTheCostForHours: `Record a formula to show the cost (${ALGEBRAIC_C}) for (${ALGEBRAIC_H}) hours.`,
  rectangleMeasuresXcmByYcm: 'A rectangle measures {0} cm by {1} cm.',
  reflectShapeInMirrorLineBySelecting:
    'Reflect the shape in the mirror line by selecting the squares.',
  reflectShapeInMirrorLineByShading: 'Reflect the shape in the mirror line by shading the squares.',
  regularXHasPerimeterOfYZWhatIsSideLength:
    'A regular {0} has a perimeter of {1}.<br/>What is the side length of the {0}?',
  roundEachDecimalToTheNearestWholeNumber: 'Round each decimal to the nearest whole number.',
  roundEachNumToNearestTenth: 'Round each number to the nearest tenth.',
  roundNum: 'Round {0:number|numberFormat}',
  roundTheNumbersToTheNearestWholeNumber: 'Round the numbers to the nearest whole number.',
  roundToNearestX: 'Round each number to the nearest {0:number|numberFormat}',
  roundToNearestStep: 'Round each number to the nearest {0:string|lower}.',
  roundXToNearestStep: 'Round {0} to the nearest {1:string|lower}.',
  roundToNearestXDragToSort:
    'Round each number to the nearest {0:number|numberFormat}<br/>Drag the cards to sort the numbers into the table.',
  roundToNearestXDragToSortPDF:
    'Round each number to the nearest {0:number|numberFormat}<br/>Sort the numbers into the table.',
  roundToNearestXToEstimateAnswer:
    'Round each number to the nearest {0:number|numberFormat} to estimate the answer.',
  roundToNearest1000: 'Round each number to the nearest 1,000',
  roundToNearest10000: 'Round each number to the nearest 10,000',
  roundToNearest100000: 'Round each number to the nearest 100,000',
  roundsTo: 'Rounds to {0:number|numberFormat}',
  schoolServesXDinnersEveryDayThereAreYWeekHowManyInTotal:
    'A school serves {0:number|numberFormat} dinners every weekday.<br/>There are {1:number|numberFormat} weeks in a half term.<br/>How many dinners does the school serve in a half term?',
  select100p: 'Select 100p',
  select2DShapeYouWouldNeedToMakeX:
    'Select the 2-D shape{{0:s}} that would be needed to make the net for a {1}.',
  select3DShapeThisNetMakes: 'Select the 3-D shape this net will make.',
  select3DShapeXIsThinkingOf: 'Select the 3-D shape {0} is thinking of.',
  select3DShapeWithGreaterVolume: 'Select the 3-D shape with the greater volume.',
  select3DShapeWithSmallerVolume: 'Select the 3-D shape with the smaller volume.',
  selectActivitesMostAppropriatelyTimedInHours:
    'Select the activities that would most appropriately be timed in hours.',
  selectActivitesMostAppropriatelyTimedInMinutes:
    'Select the activities that would most appropriately be timed in minutes.',
  selectActivitesMostAppropriatelyTimedInSeconds:
    'Select the activities that would most appropriately be timed in seconds.',
  selectActivityThatApproximatelyTakesX:
    'Select the activity that takes approximately {0} to complete.',
  selectActivityThatTakesLessThan:
    'Select the activity that takes approximately less than {0} to complete.',
  selectActivityThatTakesLessTime: 'Select the activity that takes less time.',
  selectActivityThatTakesLonger: 'Select the activity that takes longer.',
  selectAcuteAngle: 'Select the acute angle.',
  selectAllAcuteAngles: 'Select all the acute angles.',
  selectAllNumbersDivisibleBy: 'Select all the numbers divisible by {0}',
  selectAllNumbersLessThanNum: 'Select all the numbers that are less than {0}',
  selectAllNumbersGreaterThanNum: 'Select all the numbers that are greater than {0}',
  selectAllObtuseAngles: 'Select all the obtuse angles.',
  selectAllOfTheLinesThatAreParallelToA: 'Select all of the lines that are parallel to A.',
  selectAllOfTheLinesThatArePerpendicularToA:
    'Select all of the lines that are perpendicular to A.',
  selectAllOfTheLinesThatAreNotParallelToA:
    'Select all of the lines that are <b>not</b> parallel to A.',
  selectAllOfTheLinesThatAreNotPerpendicularToA:
    'Select all of the lines that are not perpendicular to A.',
  selectAllQuadrilaterals: 'Select all the quadrilaterals.',
  selectAllRepresentationsShownByNumberLine:
    'Select all the representations shown by the number line.',
  selectAngleThatIsApproxNumDegrees: `Select the angle that is approximately {0:number|numberFormat}${DEGREES}.`,
  selectApproxVolumeOfObject: 'Select the approximate volume of the object.',
  selectAreaOfRectilinearShape: 'Select the area of the rectilinear shape.',
  selectAreaOfShape: 'Select the area of the {0}.',
  selectArrowAfterTurn: 'Select what the arrow will look like after the turn.',
  selectBarModelsWherePercentShadedIn:
    'Select the bar model{{0:s}} where {1:number|numberFormat}% is shaded in.',
  selectBeakersThatHaveTotalCapacityOfOneLitre:
    'Select the set of beakers that show a total equivalent to 1 litre.',
  selectBeakersThatHaveTotalOfXLitresAndYMl:
    'Select the set of beakers that show a total of {0:number|numberFormat} l and {1:number|numberFormat} ml.',
  selectBestEstimateForXofTheY:
    'Select the best estimate for the {0:string|lower} of {1:string|lower}.',
  selectCalEquivalentToX: 'Select the calculation equivalent to {0}',
  selectCalsEquivalentToX: 'Select the calculations equivalent to {0}',
  selectCalsThatAreEqualToX: 'Select the calculations that are equal to {0:number|numberFormat}',
  selectCalcThatWillBreakWhole: 'Select the calculation that will break the whole.',
  selectCalcWithGreaterAnswer: 'Select the calculation with the greater answer.',
  selectCalcWithSmallerAnswer: 'Select the calculation with the smaller answer.',
  selectCalcsThatCanBeUsedToCheckCalc:
    'Select the calculations that can be used to check <g>{0}</g>',
  selectCalcsThatDoNotSumToOne: 'Select the calculations that do not sum to one.',
  selectCalcsThatEqualX: 'Select the calculations that are equal to {0}',
  selectCalcsThatHaveAnAnswerOfNum:
    'Select the calculations that have an answer of {0:number|numberFormat}',
  selectCalcsThatSumToOne: 'Select the calculations that sum to one.',
  selectCalcsWithAnswerGreaterThanAnsToX: `Select the calculations with an answer greater than the answer to <g>{0}</g>`,
  selectCalcsWithAnswerLessThanAnsToX: `Select the calculations with an answer less than the answer to <g>{0}</g>`,
  selectCardThatShowsXWrittenInWords:
    'Select the card that shows {0:number|numberFormat} written in words.',
  selectCharacterWithTheMostCounters: 'Select the character with the most counters.',
  selectCircleThatHasDiameterMarkedCorrectly:
    'Select the circle that has the diameter marked correctly.',
  selectCircleThatHasRadiusMarkedCorrectly:
    'Select the circle that has the radius marked correctly.',
  selectClocksThatShowXMinutesPastYInTheAfternoon:
    'Select the clocks that show {0:number|numberFormat} minutes past {1} in the afternoon.',
  selectClocksThatShowXMinutesPastYInTheEvening:
    'Select the clocks that show {0:number|numberFormat} minutes past {1} in the evening.',
  selectClocksThatShowXMinutesPastYInTheMorning:
    'Select the clocks that show {0:number|numberFormat} minutes past {1} in the morning.',
  selectClocksThatShowXMinutesToYInTheAfternoon:
    'Select the clocks that show {0:number|numberFormat} minutes to {1} in the afternoon.',
  selectClocksThatShowXMinutesToYInTheEvening:
    'Select the clocks that show {0:number|numberFormat} minutes to {1} in the evening.',
  selectClocksThatShowXMinutesToYInTheMorning:
    'Select the clocks that show {0:number|numberFormat} minutes to {1} in the morning.',
  selectColAddsWhichAreLaidOutCorrectly:
    'Select the column additions which are laid out correctly.',
  selectCommonMultiples: 'Select all the common multiples of {0} and {1}',
  selectCoordOfPoint: 'Select the coordinates of the point on the coordinate grid.',
  selectCorrectAnswer: 'Select the correct answer.',
  selectCorrectAnswerToXLookAtOnesDigitToHelpYou:
    'Select the correct answer to {0}<br/>Look at the ones digit to help you.',
  selectCubeNumber: 'Select the cube number.',
  selectCuboidWithGreaterVolume: 'Select the cuboid with the greater volume.',
  selectCuboidWithSmallerVolume: 'Select the cuboid with the smaller volume.',
  selectDecadeThatGreatestPopulationIncrease:
    'Select the decade that had the greatest population increase.',
  selectDecadeThatSmallestPopulationIncrease:
    'Select the decade that had the smallest population increase.',
  selectCorrectAnswerAddition: 'Select the correct answer to the addition.',
  selectCorrectPerimeterOfPolygon: 'Select the correct perimeter of the polygon.',
  selectCorrectPerimeterOfRegularPolygon: 'Select the correct perimeter of the regular polygon.',
  selectDayOnWhichLeastXSold: 'Select the day on which the least {0:string|lower} were sold.',
  selectDayOnWhichMostXSold: 'Select the day on which the most {0:string|lower} were sold.',
  selectDecimalAndFracEquivsShownByShadedPartsOfHundredSquare:
    'Select the decimal and fraction equivalents shown by the shaded parts of the hundred square.',
  selectDescriptionsThatMatchX: 'Select the descriptions that match {0}',
  selectDecimalEquivalentForFraction: 'Select the decimal equivalent for the fraction.',
  selectDiagramShowsCorrectReflectionInGivenMirrorLine:
    'Select the diagram that shows the correct reflection in the given mirror line.',
  selectDigitalTimeShownOnAnalogueClock: 'Select the digital time shown on the analogue clock.',
  selectApproxDistanceTravelledAfterXMinutes:
    'Select the approximate distance travelled by the car after {0: number|numberFormat} minute{{s}}.',
  selectDivisionsThatProduceARemainder: 'Select the divisions that produce a remainder.',
  selectDriestMonthInX: 'Select the driest month in {0}.',
  selectEqualAngles: 'Select the equal angles.',
  selectEquationRepresentedByCubes: 'Select the equation represented by the cubes.',
  selectFactorsOfX: 'Select the numbers that are factors of {0}',
  selectFormulaDescribesPattern: 'Select the formula that describes the pattern.',
  selectFormulaDescribesPatternPDF: 'Circle the formula that describes the pattern.',
  selectFractionsEquivalentToX: 'Select the fractions equivalent to {0}',
  selectFractionsThatEqualInteger: 'Select the fractions that equal a whole number.',
  selectGreaterNumber: 'Select the greater number.',
  selectGreaterVolume: 'Select the greater volume.',
  selectGreatestAngle: 'Select the greatest angle.',
  selectGreatestAngleInDiagram: 'Select the greatest angle in the diagram.',
  selectGreatestMass: 'Select the greatest mass.',
  selectGreatestNumber: 'Select the greatest number.',
  selectHorizontalLineOfSymmetry: 'Select the horizontal line of symmetry.',
  selectHowManyCarsInCarParkAreColour: 'Select how many cars in the car park are {0:string|lower}.',
  selectHowManyCarsThereAreInTotal: 'Select how many cars there are in total.',
  selectHowManyChildrenHaveNumPets:
    'Select how many children have {0:number|numberFormat} pet{{s}}.',
  selectHowManyMoreCarsAreXThanY:
    'Select how many more cars are {0:string|lower} than {1:string|lower}.',
  selectHowManyXWereSoldOnDay: 'Select how many {0:string|lower} were sold on {1}.',
  selectIfStatementIsTrueOrFalse: 'Select if the statement is true or false.',
  selectIfStatementIsTrueOrFalseAreaOfParallelogramIsXMultY: `Select if the statement is true or false.<br/>The area of the parallelogram is {0:number|numberFormat} ${MULT} {1:number|numberFormat} = {2:number|numberFormat} cm²`,
  selectIslandThatHasGreaterEstimatedArea: 'Select the island that has the greater estimated area.',
  selectIslandThatHasSmallerEstimatedArea:
    'Select the island that has the smallest estimated area.',
  selectJugShowsXOfWater: 'Select the jug that shows {0} of a litre of water.',
  selectImageThatShows: 'Select the image that shows {0}.',
  selectImagesThatShow: 'Select the images that show {0}.',
  selectImageOfMixedNumberThatIsGreater: 'Select the image of the mixed number that is greater.',
  selectInverseCalculation: 'Select the inverse calculation to {0}',
  selectJugsThatWouldHaveTotalCapacitityOfXMl:
    'Select jugs that would have the total capacity of {0:number|numberFormat} ml.',
  selectJugsWithTotalCapacityOfXL:
    'Select jugs with a total capacity of {0:number|numberFormat} l. ',
  selectLabelForPartOfCircleArrowIsPointingTo:
    'Select the label for the part of the circle the arrow is pointing to.',
  selectLabelToMatch: 'Select the label to match the picture.',
  selectLengthsThatAreGreaterThanNumUnits:
    'Select the lengths that are greater than {0:number|numberFormat} {1}',
  selectLengthsThatAreLessThanNumUnits:
    'Select the lengths that are less than {0:number|numberFormat} {1}',
  selectLineThatIsHorizontal: 'Select the line that is horizontal.',
  selectLineThatIsVertical: 'Select the line that is vertical.',
  selectLinesThatAreParallel: 'Select the pair of lines that are parallel.',
  selectLinesThatAreNotParallel: 'Select the pair of lines that are <b>not</b> parallel.',
  selectLinesThatArePerpendicular: 'Select the perpendicular lines.',
  selectLongerDuration: 'Select the longer duration.',
  selectMeasuringContainerThatHasLeastLiquid:
    'Select the measuring container that has the least liquid.',
  selectMeasuringContainerThatHasMostLiquid:
    'Select the measuring container that has the most liquid.',
  selectMethodsThatWouldGiveCorrectAnswer: 'Select the methods that would give the correct answer.',
  selectMissingDurationFromTable: 'Select the duration that is missing from the table.',
  selectMissingPartCompletePartWholeModel:
    'Select the missing part to complete the part-whole model.',
  selectMissingValue: 'Select the missing value.',
  selectMostAppropriateEstimateForTheSizeOfTheAngle:
    'Select the most appropriate estimate for the size of the angle.',
  selectMostAppropriateEstimateForTheSizeOfTheAnglePDF:
    'Circle the most appropriate estimate for the size of the angle.',
  selectMultiplesOf: 'Select all the multiples of {0}',
  selectTheNumberLineThatIsCountingInFrac: 'Select the number line that is counting in {0}s',
  selectWhetherLineIsHorizontalVerticalNeither:
    'Select whether the line is horizontal, vertical or neither.',
  selectWhetherTheStatementIsTrueOrFalse: 'Select whether the statement is true or false.',
  selectWhichAreMultiplesOf: 'Select the numbers which are multiples of {0: number|numberFormat}',
  selectWhichTrianglesAreTranslationsOfEachOther:
    'Select which triangles are translations of each other.',
  selectWhichRoundToXToNearestY:
    'Select the numbers which round to {0:number|numberFormat} when rounded to the nearest {1:number|numberFormat}',
  selectTriangleThatIsAReflectionOfTriangleA:
    'Select the triangle that is a reflection of triangle A.',
  selectNameOfPolygon: 'Select the name of the polygon.',
  selectNetOfX: 'Select the net that would make a {0}.',
  selectNetThatMakesX: 'Select the net{{0:s}} that will make a {1}.',
  selectNotesAndCoinsToMakeXPoundsAndYPence:
    'Select the notes and coins to make £{0:number|numberFormat} and {1:number|numberFormat}p',
  selectNumberNum: 'Select the number {0:number|numberFormat}',
  selectNumberSentencesThatAreEqualToX: 'Select the number sentences that are equal to<br/>{0}',
  selectNumberWhichHasXinTheHundredthsPosition:
    'Select the number which has {0:number|numberFormat} in the hundredths position.',
  selectNumberWhichHasXinTheTenthsPosition:
    'Select the number which has {0:number|numberFormat} in the tenths position.',
  selectNumbersGreaterOrLessThanAHalf: 'Select the numbers that are {0} than a half.',
  selectNumbersThatAreDivisibleByNum:
    'Select the numbers that are divisible by {0:number|numberFormat}',
  selectNumbersThatAreMultiplesOfBothXAndY:
    'Select the numbers that are multiples of both {0:number|numberFormat} and {1:number|numberFormat}',
  selectNumbersThatAreGreaterThanNum:
    'Select the numbers that are greater than {0:number|numberFormat}',
  selectNumbersThatAreSmallerThanNum:
    'Select the numbers that are smaller than {0:number|numberFormat}',
  selectNumbersThatCanBeDividedEquallyByNum:
    'Select the numbers that can be divided equally by {0:number|numberFormat}',
  selectNumbersThatHaveNumAsCommonFactor:
    'Select the numbers that have {0:number|numberFormat} as a common factor.',
  selectNumbersThatHaveXInTheYPlace:
    'Select the numbers that have {0:number|numberFormat} in the {1: string|lower} place.',
  selectObjectThatHoldsLeastLiquid: 'Select the object that holds the least liquid.',
  selectObjectThatHoldsMostLiquid: 'Select the object that holds the most liquid.',
  selectObjectWithTheGreatestMass: 'Select the object with the greatest mass.',
  selectObjectWithGreaterCapacity: 'Select the object with the greater capacity.',
  selectObjectWithTheSmallestMass: 'Select the object with the smallest mass.',
  selectObjectWithSmallerCapacity: 'Select the object with the smaller capacity.',
  selectObtuseAngle: 'Select the obtuse angle.',
  selectOptionThatShowsAllFactorsOfX: 'Select the option that shows all the factors of {0}',
  selectPairOfCoordsThatLieOnL:
    'Select the pair{{0:s}} of coordinates that lie on the straight line L.',
  selectPairsOfAnglesVerticallyOpposite: 'Select the pairs of angles that are vertically opposite.',
  selectPairsOfCorrectValuesForXAndY: 'Select the pairs of correct values for {0} and {1}.',
  selectPercentageOfHundredSquareShaded:
    'Select the percentage of the hundred square that is shaded.',
  selectPercentageShownByXOnHundredSquare:
    'Select the percentage shown by {0} on the hundred square.',
  selectPerimeterOfShape: 'Select the perimeter of the shape.',
  selectPictureShowsNumWords: 'Select the picture that shows {0}?',
  selectPictureThatShowsXDIVYAsAGroupingProblem: `Select the picture that shows {0:number|numberFormat} ${DIV} {1:number|numberFormat} as a <b>grouping</b> problem.`,
  selectPictureThatShowsXDIVYAsASharingProblem: `Select the picture that shows {0:number|numberFormat} ${DIV} {1:number|numberFormat} as a <b>sharing</b> problem.`,
  selectPicturesThatShowAtLeastOneAngle: 'Select the pictures that show at least one angle.',
  selectPicturesThatShowOnlyOneRightAngle: 'Select the pictures that show only one right angle.',
  selectPlaceValueChartThatRepresentsX: 'Select which place value chart represents {0}',
  selectPlaceValueChartRepresentsXDivideY: `Select the place value chart that represents {0:number|numberFormat} ${DIV} {1:number|numberFormat}.`,
  selectPlaceValueChartRepresentsXDivideYPDF: `Circle the place value chart that represents {0:number|numberFormat} ${DIV} {1:number|numberFormat}.`,
  selectRectilinearShape: 'Select the rectilinear shape.',
  selectRectilinearShapeWithAreaOfNumSquares:
    'Select the rectilinear shape with an area of {0:number|numberFormat} squares.',
  selectRegularPolygon: 'Select the regular polygon.',
  selectRepresentationThatMatchesMultiplication:
    'Select the representation that matches the multiplication.',
  selectRepresentationThatMatchesSubtraction:
    'Select the representation that matches the subtraction.',
  selectNumberThatIsGreater: 'Select the number that is greater.',
  selectNumberThatIsSmaller: 'Select the number that is smaller.',
  selectNumbersThatRoundToNumber:
    'Select the numbers that round to {0:number|numberFormat} when rounded to the nearest {1:number|numberFormat}',
  selectScaleDrawingOfObject: 'Select the scale drawing of the {0:string|lower}.',
  selectSetsOfWeightsThatHaveTotalMassOf1Kg:
    'Select the sets of weights that have a total mass equivalent to 1 kilogram.',
  selectShapeDescribed: 'Select the shape that is being described.',
  selectShapeThatHasAnAreaOfXcm2: 'Select the shape that has an area of {0} cm²',
  selectShapeThatHasPerimeterOfX: 'Select the shape that has a perimeter of {0}.',
  selectShapeWithThePerimeterXcm: 'Select the shape with the perimeter of {0} cm.',
  selectShapeWithTheSmallerOrGreaterArea: 'Select the shape with the {0} area.',
  selectShapeThatHasTheSmallerPerimeter: 'Select the shape that has the smaller perimeter.',
  selectShapeThatHasTheGreaterPerimeter: 'Select the shape that has the greater perimeter.',
  selectShapeWithSmallerVolume: 'Select the shape with the smaller volume.',
  selectShapeWithSmallestPerimeter: 'Select the shape that has the smallest perimeter.',
  selectShapeWithGreaterVolume: 'Select the shape with the greater volume.',
  selectShapeWithGreatestPerimeter: 'Select the shape that has the greatest perimeter.',
  selectShapeAtCoordinateXY: 'Select the shape at the coordinates ({0}, {1})',
  selectShapesOfXCubes: 'Select the shapes that have been made with {0:number|numberFormat} cubes.',
  selectShapesThatAreRegularPolygons: 'Select the shapes that are regular polygons.',
  selectShapeThatHasBeenSplitIntoEqualParts:
    'Select the shape that has been split into equal parts.',
  selectShapesThatHaveBeenSplitIntoEqualParts:
    'Select the shapes that have been split into equal parts.',
  selectShapesThatHaveBeenSplitIntoNumEqualParts:
    'Select the shapes that have been split into {0:number|numberFormat} equal parts.',
  selectShapeThatHasBeenSplitIntoNumEqualParts:
    'Select the shape that has been split into {0:number|numberFormat} equal parts.',
  selectShapeThatHasXShaded: 'Select the shape that has {0} shaded.',
  selectShapesThatHaveXShaded: 'Select the shapes that have {0} shaded.',
  selectShapesWithAtLeastOneSymmetry: 'Select the shapes that have at least one line of symmetry.',
  selectShapesWithBothHorizontalVeritcalLineOfSymmetry:
    'Select the shapes that have a horizontal <b>and</b> a vertical line of symmetry.',
  selectShapesWithCorrectSymmetry: 'Select the shapes that show a correct line of symmetry.',
  selectShapesWithDiagonalLineOfSymmetry:
    'Select the shapes that have a diagonal line of symmetry.',
  selectShapesWithHorizontalLineOfSymmetry:
    'Select the shapes that have a horizontal line of symmetry.',
  selectShapesWithNoSymmetry: 'Select the shapes that do not have any lines of symmetry.',
  selectShapesWithVeritcalLineOfSymmetry:
    'Select the shapes that have a vertical line of symmetry.',
  selectShapesWithXRightAngles:
    'Select the shapes with only {0: number|numberFormat} right angle{{s}}.',
  selectSimilarShape: 'Select the similar shape.',
  selectSmallerNumber: 'Select the smaller number.',
  selectSmallerVolume: 'Select the smaller volume.',
  selectSmallestAngle: 'Select the smallest angle.',
  selectSmallestAngleInDiagram: 'Select the smallest angle in the diagram.',
  selectSmallestMass: 'Select the smallest mass.',
  selectSmallestNumber: 'Select the smallest number.',
  selectStatementCardsThatAreEquiv: 'Select the statement cards that are equivalent.',
  selectStatementsThatAreTrue: 'Select the statements that are true.',
  selectStatementThatMatchesEquation: 'Select the statement that matches the equation.',
  selectStatementThatMatchesEquationPDF: 'Circle the statement that matches the equation.',
  selectStrategiesThatGiveCorrectAnswerToXAddY: `Select the strategies that give the correct answer to {0:number|numberFormat} ${ADD} {1:number|numberFormat}`,
  selectSquaresToEnlargeShapeAByX:
    'Select the squares to create an enlargement of the given shape by a scale factor of {0}',
  selectSquaresToCreateShapeWithPerimX:
    'Select the squares to create a shape with a perimeter of {0}.',
  selectSquaresToCreateRectangleWithPerimX:
    'Select the squares to create a rectangle with a perimeter of {0}.',
  selectSumOfAngles: 'Select the sum of the angles shown in the diagram.',
  selectSquaresToMakePatternSymmetrical: 'Select squares to make the pattern symmetrical.',
  selectThe3DShapeThatHasXCubes: 'Select the 3-D shape that has a volume of {0} cubes.',
  selectTheAdditionsThatNeedAnExchange: 'Select the additions that need an exchange.',
  selectTheAdditionsThatWillNeedAnExchange: 'Select the additions that will need an exchange.',
  selectTheAdditionsWhichWillNeedMoreThanOneExchange:
    'Select the additions which will need more than one exchange.',
  selectTheAlgebraicExpressionThatRepresentsThePerimeterOfTheShape:
    'Select the algebraic expression that represents the perimeter of the shape.',
  selectTheAmountsThatShowOnePound: 'Select the amounts that show £1',
  selectTheAngleThatIsApproxXDeg:
    'Select the angle that is approximately {0:number|numberFormat}°.',
  selectTheAreasOfTheRectangleAndTriangle: 'Select the areas of the rectangle and the triangle.',
  selectTheBoxThatShowsX: 'Select the box that shows {0:number|numberFormat}',
  selectTheCorrectCalculationToWorkOutTheAreaOfTheTriangle:
    'Select the correct calculation to work out the area of the triangle.',
  selectTheDayThatShowsWhenHeightOfPlantReachedXHeightCm:
    'Select the day that shows when the height of the plant reached <g>{0:number|numberFormat} cm.</g>',
  selectTheCalculationEquivalentToXMultY: `Select the calculation equivalent to {0: number|numberFormat} ${MULT} {1: number|numberFormat}`,
  selectTheCalcsThatGiveSameAnswer: 'Select the calculations that give the same answer.',
  selectTheColumnAdditionThatIsLaidOutCorrectly:
    'Select the column addition that is laid out correctly.',
  selectTheColumnSubtractionThatIsLaidOutCorrectly:
    'Select the column subtraction that is laid out correctly.',
  selectTheCoordinateGridWithThePointXY: 'Select the coordinate grid with the point ({0},{1})',
  selectTheCoordinateGridWithThePointXYMarked:
    'Select the coordinate grid with the point ({0},{1}) marked.',
  selectTheCorrectAnswersTo: 'Select the correct answers to {0}',
  selectTheCorrectLengthOfTheLine: 'Select the correct length of the line.',
  selectTheCorrectPartWholeModelForNum: 'Select the correct part-whole model for the number {0}',
  selectTheCorrectWordToCompleteSentence: 'Select the correct word to complete the sentence.',
  selectTheDayLeastDinners: 'Select the day on which children had the fewest school dinners.',
  selectTheDayLeastDinnersPdf: 'Circle the day on which children had the fewest school dinners.',
  selectTheDayLeastPacked: 'Select the day on which children had the fewest packed lunches.',
  selectTheDayLeastPackedPdf: 'Circle the day on which children had the fewest packed lunches.',
  selectTheDayMostDinners: 'Select the day on which children had the most school dinners.',
  selectTheDayMostDinnersPdf: 'Circle the day on which children had the most school dinners.',
  selectTheDayMostPacked: 'Select the day on which children had the most packed lunches.',
  selectTheDayMostPackedPdf: 'Circle the day on which children had the most packed lunches.',
  selectTheEarliestTime: 'Select the earliest time.',
  selectTheXAndYEquivalentToZ: `Select the {0:string|lower} and {1:string|lower} equivalent to {2}`,
  selectTheXAndYEquivalentToZFrac: `Select the {0:string|lower} and {1:string|lower} equivalent to <frac n='{2}' d='{3}' />`,
  selectTheFactorsOfX: 'Select the factors of {0}',
  selectTheFractionEquivalentToX: 'Select the fraction equivalent to {0}',
  selectTheFractionsThatAreGreaterThanOrEqualToPercentage:
    'Select the fractions that are greater than or equal to {0}%.',
  selectTheReflexAngle: 'Select the reflex angle.',
  selectTheHundredSquareThatRepresentsFrac:
    "Select the hundred square(s) that represents <frac n='{0}' d='{1}' />",
  selectTheImproperFractionShownByTheDiagram: 'Select the improper fraction shown by the diagram.',
  selectTheImproperFractionsThatAreEquivalentToAnInteger:
    'Select the improper fractions that are equivalent to an integer.',
  selectTheJugThatShowsVolumeXMl:
    'Select the jug that shows the volume of {0: number|numberFormat} ml.',
  selectTheLatestTime: 'Select the latest time.',
  selectTheXWeightObject: 'Select the {0:string|lower} object.',
  selectTheLongestMeasurement: 'Select the longest measurement.',
  selectTheLongestMeasurementPdf: 'Circle the longest measurement.',
  selectTheMathematicalNameOfTheShape: 'Select the mathematical name of the shape.',
  selectTheMeasureOfX: 'Select the measures of {0:string|lower}.',
  selectTheMissingEquivalentFraction: 'Select the missing equivalent fraction.',
  selectTheMeanOfTheSetOfNumbers: 'Select the mean of the set of numbers.',
  selectTheMeanOfTheSetOfNumbersPdf: 'Circle the mean of the set of numbers.',
  selectTheMixedNumberThatIsShownByTheBarModel:
    'Select the mixed number that is shown by the bar model.',
  selectTheMultiplesOfX: 'Select the multiples of {0:number|numberFormat}',
  selectTheNameOfTheShape: 'Select the name of the shape.',
  selectTheNumbersThatRoundToXWhenRoundedToTheNearestWholeNumber:
    'Select the numbers that round to {0:number|numberFormat} when rounded to the nearest whole number.',
  selectTheNumbersYouCouldUseAsCommonDenominatorToWorkOutXYZ: `Select the numbers you could use as a common denominator to <br/>work out {0} ${ADD} {1} ${ADD} {2}`,
  selectThePictureThatMatchesX: 'Select the picture that matches {0}',
  selectThePlaceValueChartThatShowsX: 'Select the place value chart that shows {0}.',
  selectThePopularPetLeast: 'Select the least popular pet.',
  selectThePopularPetLeastPdf: 'Circle the least popular pet.',
  selectThePopularPetMost: 'Select the most popular pet.',
  selectThePopularPetMostPdf: 'Circle the most popular pet.',
  selectThePrimeNumber: 'Select the prime number.',
  selectThePrimeNumbers: 'Select the prime numbers.',
  selectTheShapeThatHasOrHaveXAmountYAngles:
    'Select the shape{{0:s}} that {1:string|lower} {2:number|numberFormat} {3:string|lower}{{4:s}}.',
  selectTheShortestMeasurement: 'Select the shortest measurement.',
  selectTheShortestMeasurementPdf: 'Circle the shortest measurement.',
  selectTheSingleStepThatGivesOutput: 'Select the single step that would give the same output.',
  selectTheStationsThatTheXTrainDoesNotStopAt:
    'Select the stations that the {0} train does not stop at.',
  selectTheSquareNumber: 'Select the square number.',
  selectTheSubtractionsThatNeedAnExchange: 'Select the subtractions that need an exchange.',
  selectTheSubtractionsThatWillNeedAnExchange:
    'Select the subtractions that will need an exchange.',
  selectTheSubtractionsThatWillNeedMoreThanOneExchange:
    'Select the subtractions that will need more than one exchange.',
  selectTheTimesOnTheClockThatAreInTheAfternoon:
    'Select the times on the clocks that are in the afternoon.',
  selectTheTimesOnTheClockThatAreInTheMorning:
    'Select the times on the clocks that are in the morning.',
  selectTimeShownOnClock: 'Select the time shown on the clock.',
  selectTimeShownOnDigitalClock: 'Select the time shown on the digital clock.',
  selectTheAnglesThatAreXThanARightAngle:
    'Select the angles that are {0:string|lower} than a right angle.',
  selectTheAnswer: 'Select the answer.',
  selectTheImproperFractionIndicatedByTheQuestionMark:
    'Select the improper fraction indicated by the question mark.',
  selectTheWordToCompleteTheSentence: 'Select the word to complete the sentence.',
  selectThe24HourClockTimesThatAreAmOrPm: 'Select the 24-hour clock times that are {0}.',
  selectTheXPopularMethodOfGettingToSchoolForKeyStageY: `Select the {0:string|lower} popular method of getting to school for key stage {1:number|numberFormat}.`,
  selectTheXPopularMethodOfGettingToSchoolForYearY: `Select the {0:string|lower} popular method of getting to school for Year {1:number|numberFormat}`,
  selectTwelveHoursClockTime: 'Select the 12-hour clock time for {0}',
  selectMathematicalNameQuadrilateral: 'Select the mathematical name of the quadrilateral.',
  selectTwoLinesThatAreParallel: 'Select two lines that are parallel.',
  selectTwoLinesThatArePerpendicular: 'Select two lines that are perpendicular.',
  selectWhetherAngleIsAcuteObtuseRightAngle:
    'Select whether the angle is acute, obtuse or a right angle.',
  selectTypeOfTriangle: 'Select the type of triangle.',
  selectWettestMonthInX: 'Select the wettest month in {0}.',
  selectWhatTimeWouldBeShownIfMinuteHand:
    'Select the time if the minute hand was pointing to {0:number|numberFormat}',
  selectWhichBusStopTheBusLeavesFromAtX:
    'Where does the {0:string|lower} bus depart from?<br/>Select your answer.',
  selectWhichCalculationToEstimateAdd: `Select which calculation you would use to estimate {0} ${ADD} {1}`,
  selectWhichCalculationToEstimateSubtract: `Select which calculation you would use to estimate {0} ${SUB} {1}`,
  selectWhichChildDidTheBest: 'Two children are playing a game. Select which child did the best.',
  selectWhichNumberIsClosestToX: 'Select which number is closest to {0}',
  selectWhichPVCRepresentsXDivY: `Select which place value chart represents {0} ${DIV} {1}`,
  selectYourAnswer: 'Select your answer.',
  selectYourAnswers: 'Select your answers.',
  selectWhereTheCrossForTheFinalVertexOfTheXWouldBe:
    'Select where the dot for the final vertex of the {0:string|lower} would be.',
  selectWhereTheCrossForTheFinalVertexOfTheRegularXWouldBe:
    'Select where the dot for the final vertex of the regular {0:string|lower} would be.',
  selectWordToCompleteSentence: 'Select the word to complete the sentence.',
  selectValueOfXinY: 'Select the value of the {0} in {1}',
  selectValueOfDigitXinY: 'Select the value of the digit {0} in {1}',
  selectValuesToMakeANumberThatIsXTimeY:
    'Select the values to make a number that is {0} times the size of {1}',
  selectValuesToMakeANumberThatIsXTimeYPDF:
    'Circle the values to make a number that is {0} times the size of {1}',
  selectTheShapesThatHaveAtLeastOnePairOfX:
    'Select the shapes that have at least one pair of {0:string|lower} lines.',
  selectTheShapeThatIsX: 'Select the shape that is {0:string|lower}.',
  selectVerticalLineOfSymmetry: 'Select the vertical line of symmetry.',
  selectTheShapeWithCorrectXLineOfSymmetry:
    'Select the shape that has a correct {0} line of symmetry.',
  selectTheShapeWithPerimeterX: 'Select the shape with a perimeter of {0}',
  selectAllMultiplesOfNum: 'Select all the multiples of {0:number|numberFormat}',
  selectAllThePrimeNumbers: 'Select all the prime numbers.',
  selectAllRegularPolygons: 'Select all the regular polygons.',
  selectAllNumbersInNumTimesTable:
    'Select all the numbers in the {0:number|numberFormat} times-table.',
  selectNonUnitFraction: 'Select the non-unit fraction.',
  selectPlaceValueChartRepresentsXTimesY: `Select the place value chart that represents {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  selectPlaceValueChartRepresentsXTimesYPDF: `Select the place value chart that represents {0:number|numberFormat} ${MULT} {1:number|numberFormat}<br/>Circle the correct answer.`,
  selectSquaresToCreateRectangleWithLengthOfXCmAndWidthOfYCm:
    'Select the squares to create a rectangle with the length of <g>{0:number|numberFormat} cm</g> and the width of <g>{1:number|numberFormat} cm.</g>',
  selectSquareToCreateSquareWithSideLengthOfXcm:
    'Select the squares to create a square with the side length of <g>{0:number|numberFormat} cm.</g>',
  selectTheBarModelThatShowsAFractionEquivalentTo:
    'Select the bar model that shows a fraction equivalent to {0}',
  selectTheShapeThatHasAPerimeter: 'Select the shape that has a perimeter.',
  selectTheShapesThatHaveAPerimeter: 'Select the shapes that have a perimeter.',
  selectTheShapeThatHasMissingLengthX:
    'Select the shape that has the missing length of {0: number|numberFormat} cm.',
  selectTheTimeThatShowsWhenTemperatureIsXTemperatureCelsius:
    'Select the time that shows when the temperature is {0:string|lower} °C.',
  selectTrianglesThatHaveXAcuteAngles:
    'Select the triangles that have {0:number|numberFormat} acute angle{{s}}.',
  selectTrianglesThatHaveXObtuseAngles:
    'Select the triangles that have {0:number|numberFormat} obtuse angle{{s}}.',
  selectWhichOfTheseAreCubes: 'Select which of these shapes are cubes.',
  selectXAngle: 'Select the {0:number|numberFormat}° angle.',
  shadeARectilinearShapeWithSameAreaGreaterPerim:
    'Shade a rectilinear shape with the same area but a greater perimeter than the shaded shape.',
  shadeARectilinearShapeWithSameAreaSmallerPerim:
    'Shade a rectilinear shape with the same area but a smaller perimeter than the shaded shape.',
  shadeARectilinearShapeWithSamePerimGreaterArea:
    'Shade a rectilinear shape with the same perimeter but a greater area than the shaded shape.',
  shadeARectilinearShapeWithSamePerimSmallerArea:
    'Shade a rectilinear shape with the same perimeter but a smaller area than the shaded shape.',
  shadeBarModelToHelp: 'Shade in the bar model to help you.',
  shadeBarModelsToHelp: 'Shade in the bar models to help you.',
  shadeBarModelToShowX: 'Shade the bar model to show {0}',
  shadeBarModelsToRepresentFracXNumeratorYDenominator:
    'Shade the bar models to represent <frac n="{0}" d="{1}" />',
  shadeBarModelsToShowEquivalentFractions: 'Shade the bar models to show the equivalent fractions.',
  shadeCircleToHelpCompleteAddition: 'Shade the circle to help you complete the addition.',
  shadePercentageOfBarModel: `Shade {0}% of the bar model.`,
  shadeFractionOfTheBarModel: 'Shade {0} of the bar model.',
  shadeFractionOfTheRectangle: 'Shade {0} of the rectangle.',
  shadeInTheBarModelToHelpCompleteTheCalculation:
    'Shade in the bar model to help you complete the calculation.',
  shadeInThermometerToShowXC: 'Shade in the thermometer to show {0}°C.',
  shadeMultiplesOfX: 'Shade in the multiples of {0:number|numberFormat}',
  shadeNumbersInXTimesTable: 'Shade in the numbers in the {0:number|numberFormat} times-table.',
  shadePrimeNumbers: 'Shade in the prime numbers.',
  shadeSquaresToEnlargeShapeAByX:
    'Shade the squares to create an enlargement of shape A by a scale factor of {0}',
  shadedSquares: '{0} squares are shaded.',
  shadeSquaresSoRatioOfShadedToNonShadedSquaresIsXToY:
    'Shade the squares so that the ratio of shaded to non-shaded squares is {0} : {1}',
  shadeSquaresToCreateRectangleWithLengthOfXCmAndWidthOfYCm:
    'Shade in the squares to create a rectangle with the length of <g>{0:number|numberFormat} cm</g> and the width of <g>{1:number|numberFormat} cm.</g>',
  shadeSquareToCreateSquareWithSideLengthOfXcm:
    'Shade in the squares to create a square with the side length of <g>{0:number|numberFormat} cm.</g>',
  shadeSquaresToMakePatternSymmetrical: 'Shade squares to make the pattern symmetrical.',
  shadeToCreateTwoRectilinearShapesThatHaveAnAreaOfXcm2:
    'Shade the squares to create two different rectilinear that both have an area of {0} cm²',
  shadePartsToCompletePieByTapping:
    'Shade the pie chart to match the table by tapping multiple times.',
  shadePartsToCompletePieMatchTable: 'Shade the pie chart to match the table.',
  shadePartsToCompletePieMatchBarModel: 'Shade the pie chart to match the bar model.',
  shadePartsToCompletePieToMatchBarModelByTapping:
    'Shade the pie chart to match the bar model by tapping multiple times.',
  shadeSquaresToCreateRectWithPerimeterXLessThanShadedSquare:
    'Shade the squares to create a rectangle with a perimeter {0} less than the shaded square.',
  shadeSquaresToCreateRectWithPerimeterXGreaterThanShadedSquare:
    'Shade the squares to create a rectangle with a perimeter {0} greater than the shaded square.',
  shadeSquaresToDrawRectangleWhereSidesAreXLong:
    'Shade the squares to draw another rectangle where each side is {0} as long.',
  shadeToFillJugWithNumMlOfJuice: 'Shade to fill the jug with {0:number|numberFormat} ml of juice.',
  shadeXOfTheBarModel: 'Shade {0} of the bar model.',
  shareXCountersEquallyIntoYGroups: 'Share {0} counters equally into {1} groups.',
  shapeAHasBeenEnlargedBy: 'Shape A has been enlarged by a scale factor of {0}',
  shapeAIsRegularBIrregularWhatIsInteriorAngles:
    'Shape A is a regular {0}. Shape B is an irregular {0}.<br/>What is the sum of the interior angles in each shape?',
  shapeHasOneLineOfSymmetryWorkOutPerim:
    'This shape has one line of symmetry.<br/>Work out the perimeter of the shape.',
  shapeHasXLinesOfSymmetryWorkOutPerim:
    'This shape has {0} lines of symmetry.<br/>Work out the perimeter of the shape.',
  shapeShouldNotBeRectangle: 'Your shape should <b>not</b> be a rectangle.',
  showNum10TimesSizeOfNumOnGattegno:
    'Show the number that is 10 times the size of {0} on the Gattegno chart.',
  showNum100TimesSizeOfNumOnGattegno:
    'Show the number that is 100 times the size of {0} on the Gattegno chart.',
  showNumOneTenthSizeOfNumOnGattegno:
    'Show the number that is one-tenth the size of {0} on the Gattegno chart.',
  showNumOneHundredthSizeOfNumOnGattegno:
    'Show the number that is one-hundredth the size of {0} on the Gattegno chart.',
  showPositionOfNumPdf: 'Draw an arrow to show the position of {0}',
  showTheTimeToTheDigitalClock: 'Show the time {0:string|lower} on the digital clock.',
  completeTheCalculationUseTheBarModelToHelpYou:
    'Complete the calculation.\nUse the bar model to help you.',
  completeShapeAccordingToLineOfSymmetry: 'Complete the shape according to the line of symmetry.',
  completeShapeAccordingToLineOfSymmetryTapToShowVertices:
    'Complete the shape according to the line of symmetry.<br/>Tap to show where the missing vertices will be.',
  completeShapeAccordingToLineOfSymmetryTapToShowVertex:
    'Complete the shape according to the line of symmetry.<br/>Tap to show where the missing vertex will be.',
  simplifyFractions: 'Simplify the fractions.',
  solveEquations: 'Solve the equations.',
  sortAdditionsPDF: 'Sort the additions into the table.',
  sortAmountsPDF: 'Sort the amounts into the table.',
  sortEquivalentFractionsPDF: 'Sort the equivalent fractions into the table.',
  sortFactorsOfXAndYPDF: 'Sort the factors of {0} and {1} into the table.',
  sortFractionsIntoTheTable: 'Sort the fractions into the table.',
  sortMetricUnitsIntoCorrectCategoriesPDF: 'Sort the metric units into the table.',
  sortMultiplesOfXAndYPDF: 'Sort the multiples of {0} and {1} into the table.',
  sortNumbersPDF: 'Sort the numbers into the table.',
  startWithSmallestValue: 'Start with the smallest value.',
  startWithTheColdestTemp: 'Start with the coldest temperature.',
  startWithTheWarmestTemp: 'Start with the warmest temperature.',
  sumOfTwoNumbersXYisZ: 'The sum of two numbers, {0} and {1}, is {2}',
  symbolRoundedToNearestWholeIsNum:
    '{0} rounded to the nearest whole number is {1:number|numberFormat}<br/>{0} is a number with 1 decimal place.<br/>What number could be represented by {0}?<br/>Find 3 possible answers.',
  tapCircleToHelpCompleteAddition: 'Tap the circle to help you complete the addition.',
  tapCountersToCreateArrayToShowXIsFactorOfY:
    'Tap the counters to create an array to show that {0:number|numberFormat} is a factor of {1:number|numberFormat}',
  tapDotsToCreateXByMarkingVertices: 'Tap dots to create {0} by marking the vertices.',
  tapDotsToCreateXByMarkingVerticesPdf: 'Use the dots to create {0} by marking the vertices.',
  tapDotsToDrawRightAngledTriangleWithBaseXAndHeightY:
    'Tap the dots to draw a right-angled triangle with a base of {0:number|numberFormat} cm and a height of {1:number|numberFormat} cm.',
  tapDotsToDrawRightAngledTriangleWithBaseXAndHeightYPDF:
    'Use the dots to draw a right-angled triangle with a base of {0:number|numberFormat} cm and a height of {1:number|numberFormat} cm.',
  tapDotsToDrawShapeInDiagramAccuratelyOnGrid:
    'Tap the dots to draw the shape in the diagram accurately on the grid.',
  tapSquaresToCreateRectilinearShapeWithSameAreaGreaterPerim:
    'Tap the squares to create a rectilinear shape with the same area but a greater perimeter than the shaded shape.',
  tapSquaresToCreateARectilinearShapeWithSameAreaSmallerPerim:
    'Tap the squares to create a rectilinear shape with the same area but a smaller perimeter than the shaded shape.',
  tapSquaresToCreateARectilinearShapeWithSamePerimGreaterArea:
    'Tap the squares to create a rectilinear shape with the same perimeter but a greater area than the shaded shape.',
  tapSquaresToCreateARectilinearShapeWithSamePerimSmallerArea:
    'Tap the squares to create a rectilinear with the same perimeter but a smaller area than the shaded shape.',
  tapSquaresToCreateRectWithPerimeterXGreaterThanShadedSquare:
    'Tap squares to create a rectangle with a perimeter {0} greater than the shaded square.',
  tapSquaresToCreateRectWithPerimeterXLessThanShadedSquare:
    'Tap squares to create a rectangle with a perimeter {0} less than the shaded square.',
  tapSquaresToDrawRectangleWhereEachSideIsXLong:
    'Tap the squares to draw another rectangle where each side is {0} as long.',
  tapToCreateTwoRectilinearShapesThatHaveAnAreaOfXcm2:
    'Tap the squares to create two different rectilinear shapes that both have an area of {0} cm²',
  tapToShadeBarModelToHelp: 'Tap to shade the bar model to help you.',
  tapToShadeBarModelsToShowEquivalentFractions:
    'Tap to shade the bar models to show the equivalent fractions.',
  tempInXisYdegrees: 'The temperature in {0} is {1} °C.',
  tempInXIsYTempInZIsWarmer:
    'The temperture in {0} is {1} °C.<br/>The temperature in {2} is warmer than in {0}, but colder than freezing point.',
  the3DShapesHaveTheSameVolume: 'The 3-D shapes have the same volume.',
  theAreaIsXWorkOutPerimeter: 'The area is {0}.<br/>Work out the perimeter.',
  theAreaOfShapeIsNumCircleStatementTrueOrFalse:
    'The area of the {0} is {1:number|numberFormat} units.<br/>Circle whether the statement is true or false.',
  theAreaOfShapeIsNumSelectStatementTrueOrFalse:
    'The area of the {0} is {1:number|numberFormat} units.<br/>Select whether the statement is true or false.',
  theAreaOfShapeIsXcmSquaredWorkOutX: `The area of the {0} is {1:number|numberFormat} cm².<br/>Work out the length marked ${ALGEBRAIC_X}.`,
  theAreaOfTheTriangleIsXCm2WorkOutLengthMarkedY:
    'The area of the triangle is {0:number|numberFormat} cm².<br/>Work out the length marked {1}.',
  theAreaOfTheXIsY: 'The area of the {0:string|lower} is {1}',
  theArrowIsPointingAtFrac: `The arrow is pointing to <frac n='{0}' d='{1}' />`,
  theArrowsAreBeingTurnedAntiClockwiseDragToMatch:
    'The arrows are being turned anti-clockwise. Drag the cards to match the turns to the pictures.',
  theArrowsAreBeingTurnedClockwiseDragToMatch:
    'The arrows are being turned clockwise. Drag the cards to match the turns to the pictures.',
  theArrowsAreBeingTurnedAntiClockwiseUseToMatch:
    'The arrows are being turned anti-clockwise. Use the cards to match the turns to the pictures.',
  theArrowsAreBeingTurnedClockwiseUseToMatch:
    'The arrows are being turned clockwise. Use the cards to match the turns to the pictures.',
  theBarModelIsSplitInto: 'The bar model is split into {0:string|lower}.',
  theBarModelShowsHowXCountersHaveBeenSharedIntoYEqaulGroups:
    'The bar model shows how {0:number|numberFormat} counters have been shared into {1:number|numberFormat} equal groups.',
  theBarModelShowsFracOfAnAmountIsNumber:
    'The bar model shows that {frac} of an amount is {number}',
  theBarModelShowsX: 'The bar model shows {0}',
  theClocksShowTheStartAndFinishOfAnActivity:
    'The clocks show the start and finish time of an activity.\nSelect the duration of the activity.',
  theClockShowsStartOfTVShowItFinishesXLaterCircleEndTime:
    'The clock shows the start time of a TV programme. It finishes {0} later. Circle the end time.',
  theClockShowsStartOfTVShowItFinishesXLaterSelectEndTime:
    'The clock shows the start time of a TV programme. It finishes {0} later. Select the end time.',
  theContainerContainsNumXCapacityOfWaterEstimateCapacityOfContainer:
    'The container contains {0:number|numberFormat} {1:string|lower} of water.<br/>Estimate the capacity of the container.',
  theCostOfNumObjectIsNum: 'The cost of {0} {1:string|lower} is £{2}',
  theDiagramIsMadeOf3LinesWorkOutAngleX:
    'This diagram is made from three straight lines.<br/>Work out the size of angle {0}.',
  theDistanceBetweenABIsSameAsBAndCWhatAreCoordsOfC:
    'The distance between point A and point B is the same as the distance between point B and point C.<br/>What are the coordinates of point C?',
  theDurationOfSchoolSubjectIsNumMinutesAm:
    'The duration of {0} lesson is {1:number|numberFormat} minutes.<br/>The {0} lesson finishes at {2}:{3} am.<br/>What time did it start?',
  theDurationOfSchoolSubjectIsNumMinutesPm:
    'The duration of {0} lesson is {1:number|numberFormat} minutes.<br/>The {0} lesson finishes at {2}:{3} pm.<br/>What time did it start?',
  theEmptyBowlWeighsXgBeforeFoodWhatIsWeightOfFood:
    'The empty bowl weighs {0:number|numberFormat} g before the food is poured into it.<br/>What is the mass of the food in the bowl?',
  theFullBottleOfLiquidIsEmptiedIntoJug:
    'The full bottle of liquid is emptied into the jug.<br/>Drag the arrow to show where the liquid will reach.',
  theFullBottleOfLiquidIsEmptiedIntoJugPDF:
    'The full bottle of liquid is emptied into the jug.<br/>Shade the jug to show where the liquid will reach.',
  theFullCartonOfJuiceIsPouredIntoAGlass:
    'The full carton of juice is poured into a glass.<br/>Select the estimated capacity of the glass.',
  theFullCartonOfJuiceIsPouredIntoAGlassPDF:
    'The full carton of juice is poured into a glass.<br/>Circle the estimated capacity of the glass.',
  theGlassContainsNumMlOfJuiceEstimateCapacityOfGlass:
    'The glass contains {0:number|numberFormat} ml of juice.<br/>Estimate the capacity of the glass.',
  theGridHasMultiplesOfXShadedShadeMultiplesOfY:
    'The grid has multiples of {0:number|numberFormat} shaded. Shade the multiples of {1:number|numberFormat}',
  theGridHasMultiplesOfXShadedShadeMultiplesOfYAndSelect:
    'The grid has multiples of {0} shaded. Shade multiples of {1} and select numbers that are multiples of both <b>{0} and {1}</b> below.',
  theHundredSquareRepresents1WholeWorkOutTheDecimalAndPercentage:
    'The hundred square represents 1 whole.<br/>Work out the decimal and percentage shown.',
  theHundredSquareRepresentsOneHundred:
    'The hundred square represents one hundred.<br/>Write the calculation shown.',
  theHundredSquareRepresentsOneWhole:
    'The hundred square represents one whole. Use the hundred square to help you complete the addition.',
  theHundredSquareRepresentsOneWholeGiveFractionDecimalAndPercentage:
    'The hundred square represents one whole.<br/>Give your answer as a fraction, decimal and a percentage.',
  theHundredSquareRepresentsOneWholeWhatFractionsAndDecimalAreRepresented:
    'The hundred square represents one whole.<br/>What fractions and decimal are represented?',
  theHundredSquareRepresentsOneWholeWhatFractionAndDecimalIsRepresented:
    'The hundred square represents one whole.<br/>What fraction and decimal is represented?',
  theHundredSquareRepresentsOneWholeWriteCalcShown:
    'The hundred square represents one whole.<br/>Write the calculation shown.',
  theLengthOfABrickIsNumMWhatIsTotalLength:
    'The length of a brick is {0} m.<br/>What is the total length of {1:number|numberFormat} bricks? Select the correct answer.',
  theLengthOfAXItemIsYLengthWhatIsTheLengthOfZOfTheseXItemPlural:
    'The length of a {0:string|lower} is {1} m.<br/>What is the length of {2} of these {3:string|lower}?',
  theMeanOfTheNumbersIsYDragCardsToFillMissingNumber:
    'The mean of the numbers is {0}<br/>What is the missing number?<br/>Drag a card to complete the list.',
  theMeanOfTheNumbersIsYDragCardsToFillMissingNumbers:
    'The mean of the numbers is {0}<br/>What are the missing numbers?<br/>Drag the cards to complete the list.',
  theMeanOfTheNumbersIsYDragCardsToFillMissingNumberPdf:
    'The mean of the numbers is {0}<br/>What is the missing number?<br/>Use a card to complete the list.',
  theMeanOfTheNumbersIsYDragCardsToFillMissingNumbersPdf:
    'The mean of the numbers is {0}<br/>What are the missing numbers?<br/>Use the cards to complete the list.',
  theMassOfOneXOfFoodIsFracKgWhatIsTheMassOfYXOfFood:
    'The mass of one {0:string|lower} of {1:string|lower} is {2} kg.<br/>What is the mass of {3} {4:string|lower} of {1:string|lower}?',
  theMinuteHandAndTheHourHandOfAClockBothPointToEven:
    'The minute hand and the hour hand of a clock are both pointing <b>exactly</b> at an even number. Select the times it could be.',
  theNumberLineShowsX: 'The number line shows {0}.',
  thenCalcPerimeter: 'Then calculate the perimeter.',
  theOrangeJuiceInJugAIsPouredIntoJugB:
    'The orange juice in jug A is poured into jug B.<br/>Drag the arrow to show where the orange juice will reach.',
  theOrangeJuiceInJugAIsPouredIntoJugBPDF:
    'The orange juice in jug A is poured into jug B.<br/>Shade jug B to show where the orange juice will reach.',
  theOutputIsEqualToInputFindInput: 'The output is equal to the input. Find the input.',
  thePerimeterIsXWorkOutArea: 'The perimeter is {0}.<br/>Work out the area.',
  thePerimeterOfRectilinearShapeIsXWorkOutUnkownLengths:
    'The perimeter of this rectilinear shape is {0:number|numberFormat} cm.<br/>Work out the unknown lengths.',
  thePerimeterOfShapeIsNumCircleStatementTrueOrFalse:
    'The perimeter of the {0} is {1:number|numberFormat} units.<br/>Circle whether the statement is true or false.',
  thePerimeterOfShapeIsNumSelectStatementTrueOrFalse:
    'The perimeter of the {0} is {1:number|numberFormat} units.<br/>Select whether the statement is true or false.',
  thePerimeterOfShapeIsNumAllSidesAreEqualLengthWhatIsLengthOfOneSide:
    'The perimeter of the {0} is {1:number|numberFormat} cm. All the sides are equal in length. What is the length of one side?',
  thePerimeterOfShapeIsNumTheLengthOfShapeIsNumWhatIsWidthOfShape:
    'The perimeter of a {0} is {1:number|numberFormat} cm.<br/>The length of the {0} is {2:number|numberFormat} cm.<br/>What is the width of the {0}?',
  thePerimeterOfXItemIsYMetresWhatIsTheLengthOfEachSide:
    'The perimeter of a {0} is {1} m.<br/>What is the length of each side?',
  thePerimeterOfXItemIsYCmWhatIsTheLengthOfEachSide:
    'The perimeter of the {0} is {1:number|numberFormat} cm.<br/>What is the length of each side?',
  thePointReflectedInLWhatAreCoordsOfReflectedPoint:
    'The point is reflected in the line marked L.<br/>What are the coordinates of the reflected point?',
  thereAreXCubesInTheCuboid: 'There are {0:number|numberFormat} cubes in the cuboid.',
  theScaleMesauresUpTo1kgCircleTheFractionShown:
    'The scale measures up to 1 kg.<br/>Circle the fraction of a kilogram shown on the scale.',
  theScaleMesauresUpTo1kgWhatIsFractionShown:
    'The scale measures up to 1 kg.<br/>What fraction of a kilogram is shown on the scale?',
  theScaleMesauresUpTo1kgSelectTheFractionShown:
    'The scale measures up to 1 kg.<br/>Select the fraction of a kilogram shown on the scale.',
  theShapeHasBeenSplitIntoNumTrianglesCompleteSentences:
    'The {0:string|lower} has been split into {1:number|numberFormat} triangles.<br/>Complete the sentences.',
  theSquareHasAPerimeterOfXCmWhatIsLengthOfOneSide:
    'The square has a perimeter of {0:number|numberFormat} cm. What is the length of one side?',
  theSquareIsReflectedInLWriteCoordsOfXY:
    'The square is reflected in the line marked L.<br/>Write the coordinates of points {0} and {1}.',
  theSumOfInteriorAnglesAreXCalculateSizeOfAngleY: `The sum of the interior angles of the polygon is {0}${DEGREES}.<br/>Calculate the size of angle {1}.`,
  theTableShowsTheFractionsOfTreesInAPark: 'The table shows the fractions of trees in a park.',
  theTempInXIsYDegreesColderThanZWhatIsTheTempInX:
    'The temperature in {0} is {1:number|numberFormat} degrees colder than {2}. What is the temperature in {0}?',
  theTotalMassOfAAndBIsXMassOfAIsYTimesWorkOutAAndB:
    'The total mass of {symbol1} and {symbol2} is {totalMass}.<br/>The mass of {symbol1} is {multiplier: number|numberFormat} times greater than {symbol2}.<br/>Work out the values of {symbol1} and {symbol2}.',
  theTranslationABIsXDxAndYDYDoYouAgree:
    'The translation from A to B is {x} squares {dx} and {y} squares {dy}.<br/>Do you agree?',
  theXFractionIsY: `The {0} fraction is {1}`,
  theXShowShadedPartsToNonShadedPartsDragCardsMatchXToBarModels:
    'The {0} show shaded parts to non-shaded parts.<br/>Drag the cards to match the {0} to the bar models.',
  theXShowShadedPartsToNonShadedPartsUseCardsMatchXToBarModels:
    'The {0} show shaded parts to non-shaded parts.<br/>Use the cards to match the {0} to the bar models.',
  thereAreFiveDaysInASchoolWeek:
    'There are 5 days in a school week.\nWhat is the difference in the number of days in {0:number|numberFormat} <b>school weeks</b> compared to {0:number|numberFormat} whole weeks?',
  thereAreNumGreenBalloons:
    'There are {0} green balloons.<br/>There are {1} times as many yellow balloons.<br/>Work out how many yellow balloons there are.',
  thereAreNumberPeopleOnATrain:
    'There are {0:number|numberFormat} people on a train.<br/>At a station, {1:number|numberFormat} people get off and {2:number|numberFormat} get on.<br/>How many people are on the train now?',
  thereAreNumSweetsInABagIfTheyEatNumBagsOfSweets:
    'There are {0:number|numberFormat} sweets in a bag.<br/>A group of friends has {1:number|numberFormat} bags of sweets.<br/>If they eat {2:number|numberFormat} bags of sweets, how many sweets will they have left?',
  thereAreNumSweetsInABagIfTheyEatNumSweets:
    'There are {0:number|numberFormat} sweets in a bag.<br/>A group of friends has {1:number|numberFormat} bags of sweets.<br/>If they eat {2:number|numberFormat} sweets, how many sweets will they have left?',
  thereAreSixHoursInASchoolDay:
    'There are 6 hours in a school day.\nHow many more hours are there in {0:number|numberFormat} full days than {0:number|numberFormat} <b>school days</b>?',
  thereAreThousandMInOneKmUseBarModel:
    'There are 1,000 meters (m) in 1 kilometer (km).<br/>Use the bar model to complete the sentence.',
  thereAreXandYWhatIsDecimal: 'There are {0} and {1}.<br/>What is the number as a decimal?',
  thereAreXColorCountersHowManyInTotal:
    'There are {0: number|numberFormat} {1: string|lower} counters in a bag. How many counters are there in total?',
  thereAreXCountersInTotalCircleDifferenceBetweenXAndY:
    'There are {0: number|numberFormat} counters in total.<br/>Circle the difference between the number of {1: string|lower} and {2: string|lower} counters.',
  thereAreXCountersInTotalSelectDifferenceBetweenXAndY:
    'There are {0: number|numberFormat} counters in total.<br/>Select the difference between the number of {1: string|lower} and {2: string|lower} counters.',
  thereAreXHoursInMonthAHowManyHoursAreThereInMonthB:
    'There are {0:number|numberFormat} hours in {1}.<br/>How many hours are there in {2}?',
  thereAreXMarblesInAJarHowManyColorY:
    'There are {0: number|numberFormat} marbles in a jar. How many marbles are {1: string|lower}?',
  thereAreXOnesAndXDragCountersToRepresentNumber:
    'There are {0} ones and {1}.<br/>Drag in counters to represent the number.',
  thereIsXOneAndXDragCountersToRepresentNumber:
    'There is {0} one and {1}.<br/>Drag in counters to represent the number.',
  thereAreXOnesAndXDrawCountersToRepresentNumber:
    'There are {0} ones and {1}.<br/>Draw counters to represent the number.',
  thereIsXOneAndXDrawCountersToRepresentNumber:
    'There is {0} one and {1}.<br/>Draw counters to represent the number.',
  thereAreXSeatsInACinemaHowManyEmptySeatsAtYShowing:
    'There are {0:number|numberFormat} seats in the cinema.<br/>How many seats were empty at the {1} pm showing?',
  thereAreXWeeksInAHalfTerm: 'There are {0:number|numberFormat} weeks in a half term.',
  thereIsNumGOfPasta: 'There is {0:number|numberFormat} g of pasta in one portion.',
  thereIsXandYWhatIsDecimal: 'There is {0} and {1}.<br/>What is the number as a decimal?',
  thereWereXTimesAsManyVisitorsOnSaturdayAsYDayCircleHowManyPeopleVisitedSaturday:
    'There were {0:number|numberFormat} times as many visitors on Saturday as there were on {1}. Circle how many people visited on Saturday.',
  thereWereXTimesAsManyVisitorsOnSaturdayAsYDaySelectHowManyPeopleVisitedSaturday:
    'There were {0:number|numberFormat} times as many visitors on Saturday as there were on {1}. Select how many people visited on Saturday.',
  thereWereXAsManyVisitorsOnSaturdayAsYDayCircleHowManyPeopleVisitedSaturday:
    'There were {0:string|lower} as many visitors on Saturday as there were on {1}. Circle how many people visited on Saturday.',
  thereWereXAsManyVisitorsOnSaturdayAsYDaySelectHowManyPeopleVisitedSaturday:
    'There were {0:string|lower} as many visitors on Saturday as there were on {1}. Select how many people visited on Saturday.',
  theseAreBothScaleDrawingsOfObject: 'These are both scale drawings of the {0:string|lower}.',
  theseAreBothScaleDrawingsOfObjectPDF:
    'These are both scale drawings of the {0:string|lower}. Circle the correct answer.',
  theFractionsAreInAscendingOrderCircleGap:
    'The fractions, decimals and percentages are in ascending order.<br/>Circle the values that could fill the gap.',
  theFractionsAreInDescendingOrderCircleGap:
    'The fractions, decimals and percentages are in descending order.<br/>Circle the values that could fill the gap.',
  theFractionsAreInAscendingOrderSelectGap:
    'The fractions, decimals and percentages are in ascending order.<br/>Select the values that could fill the gap.',
  theFractionsAreInDescendingOrderSelectGap:
    'The fractions, decimals and percentages are in descending order.<br/>Select the values that could fill the gap.',
  theTempInXIsWarmerThanYColderThanZWhatTemp:
    'The temperature in {0} is warmer than {1}, but colder than {2}.<br/>Select the temperature it could be in {0}.',
  theTempInXIsWarmerThanYColderThanZWhatTempPdf:
    'The temperature in {0} is warmer than {1}, but colder than {2}.<br/>Circle the temperature it could be in {0}.',
  theTimeIsXAndYPmWhatTimeInAHoursAndBMinutesIn24HTime:
    'The time is {0}:{1} pm.<br/>What time will it be in {2} hour{{s}} and {3} minute{{s}}?<br/>Write your answer as a 24-hour clock time.',
  theTwoShapesAreSimilar: 'The two shapes are similar.',
  thirtyDaysOrLess: '30 days or less',
  thirtyOneDays: '31 days',
  thisCompoundShapeIsMadeWorkOutArearOfThisShape:
    'This compound shape is made.<br/>Work out the area of this shape',
  thisCompoundShapeIsMadeWorkOutPerimeterOfThisShape:
    'This compound shape is made.<br/>Work out the perimeter of this shape',
  thisIsAnXTriangleWhatIsMissingLength:
    'This is an {0: string|lower} triangle.<br/>What is the missing length?',
  tickTheMultipleOfX: 'Tick the multiples of {0:number|numberFormat}',
  tickBeakersThatHaveTotalCapacityOfOneLitre:
    'Tick the set of beakers that have a total capacity equivalent to 1 litre.',
  tinySaysNumIsAPrimeNumber: 'Tiny says, "{0} is a prime number."\nShow that Tiny is not correct.',
  tinySaysNumIsPrimeNumberTrueFalse:
    'Tiny says, "The only factors of {0:number|numberFormat} are 1 and {0:number|numberFormat}". Is Tiny correct?',
  tinySaysXIsAMultOf5And10:
    'Tiny says, "{0} is a multiple of 5 and a multiple of 10"\nIs Tiny correct?',
  tinySaysXIsAMultOf5AndNot10:
    'Tiny says, "{0} is a multiple of 5 and NOT a multiple of 10"\nIs Tiny correct?',
  tinySaysXIsAMultOf10AndNot5:
    'Tiny says, "{0} is a multiple of 10 and NOT a multiple of 5"\nIs Tiny correct?',
  tinySaysXIsAMultipleOfYIsTinyCorrect: 'Tiny says, "{0} is a multiple of {1}"\nIs Tiny correct?',
  tinySaysXIsDivisibleByYIsTinyCorrect: 'Tiny says, "{0} is divisible by {1}"\nIs Tiny correct?',
  totalCostOfXandYIsZ: 'The total cost of one {0: string|lower} and one {1: string|lower} is £{2}',
  translateShapeXSquaresDX:
    'Drag the {shape} to translate it {x:number|numberFormat} square{{s}} to the {dx}.',
  translateShapeXSquaresDXPDF:
    'Translate the {shape} {x:number|numberFormat} square{{s}} to the {dx}.',
  translateShapeXSquaresDY:
    'Drag the {shape} to translate it {x:number|numberFormat} square{{s}} {dy}.',
  translateShapeXSquaresDYPDF: 'Translate the {shape} {x:number|numberFormat} square{{s}} {dy}.',
  translateShapeXSquaresDXAndYSquaresDY:
    'Drag the {shape} to translate it {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  translateShapeXSquaresDXAndYSquaresDYPDF:
    'Translate the {shape} {x:number|numberFormat} square{{s}} to the {dx} and {y:number|numberFormat} square{{s}} {dy}.',
  true: 'True',
  twoAnglesInTriangleAreXandYWhatIsSizeOfThird:
    'Two angles in a triangle are {0}° and {1}°<br/>What is the size of the third angle?',
  twoAnglesInTriangleArXYIsThisIsosceles:
    'Two angles in a triangle are {0}° and {1}°.<br/>Is this triangle an isosceles triangle?',
  twoAnglesInTriangleArXYIsThisIsoscelesPDF:
    'Two angles in a triangle are {0}° and {1}°.<br/>Is this triangle an isosceles triangle?<br/>Circle your answer.',
  twoCharsLiveDistFromParkWorkOutMissingDist:
    '{nameA} lives {distanceA} km away from the park.<br/>{nameB} lives {distanceB} km nearer to the park than {nameA} does.<br/>How far from the park does {nameB} live?',
  twoCirclesHaveSameCentreCompleteSentence:
    'The two circles have the same centre.<br/>Complete the sentence.',
  twoFractionsAreEqualToOneWhole:
    'Two fractions are equal to 1 whole altogether.<br/>Drag the cards to show what the fractions could be.',
  twoFractionsAreEqualToOneWholePdf:
    'Two fractions are equal to 1 whole altogether.<br/>Use the cards to show what the fractions could be.<br/>Each card can be used more than once.',
  twoIsoscelesTrianglesAreJoinedToFormAKiteWorkOutSizesOfUnknownAngles:
    'Two isosceles triangles are joined to form a kite.<br/>Work out the sizes of the unknown angles.',
  twoIsoscelesTrianglesAreJoinedToFormAKiteWorkOutX:
    'Two isosceles triangles are joined to form a kite.<br/>Work out {0}',
  twoNumbersHaveDiffOfXAndTotalOfY:
    'Two numbers have a difference of {0} and a total of {1}\nWhat are the two numbers?',
  twoShapesAreSimilarWorkOutLengthOfX: 'The two shapes are similar. Work out the length of {0}.',
  twoVerticesAreAtXYWhatCouldOtherCoordsBe:
    'Two vertices of a square are at ({0}, {1}) and ({2}, {3}).<br/>What could the coordinates of the other two vertices be?',
  useACardToCompleteSentence: 'Use a card to complete the sentence.',
  useAdditionToWorkOutWhetherThisCalcIsCorrect:
    'Use addition to work out whether the calculation is correct.',
  useAdditionToWorkOutWhetherThisCalcIsCorrectPDF:
    'Use addition to work out whether the calculation is correct.<br/>Circle the correct answer.',
  useAreaModelToHelp: 'Use the area model to help.',
  useArrayToHelpCompleteSentence: 'Use the array to help complete the sentence.',
  useBarModelsToConvertImproperFractionToMixedNumber:
    'Use the bar models to convert the improper fraction to a mixed number.',
  useBarModelToCompleteAddition: 'Use the bar model to complete the addition.',
  useBarModelToCompleteCalc: 'Use the bar model to complete the calculation.',
  useBarModelToCompleteDivision: 'Use the bar model to complete the division.',
  useBarModelToCompleteSentence: 'Use the bar model to complete the sentence.',
  useBarModelToCompleteStatement: 'Use the bar model to complete the statement.',
  useBarModelToFindFractionOfAnAmount: 'Use the bar model to find the fraction of an amount.',
  useBarModelToFindFractionOfAKilogram: 'Use the bar model to find the fraction of a kilogram.',
  useBarModelToFindMissingNumber: 'Use the bar model to find the missing number.',
  useBarModelToHelpCompleteAddition: 'Use the bar model to help you complete the addition.',
  useBarModelToHelpRepresentEquivalentFraction:
    'Use the bar models to help represent the equivalent fraction.',
  useBarModelToRepresentCalculation: 'Use the bar model to represent the calculation.',
  useBarModelToWorkOutMissingValue: 'Use the bar model to work out the missing value.',
  useBarModelToWorkOutSubtraction: 'Use the bar models to work out the subtraction.',
  useBarModelToWorkOutTheMult: 'Use the bar model to work out the multiplication.',
  useBarModelToWorkOutThePercentage: 'Use the bar model to work out the percentage.',
  useBarModelsToCompleteImproperFractions: 'Use the bar models to complete the improper fractions.',
  useBarModelsToCompleteSentences: 'Use the bar models to complete the sentences.',
  useCardsAndBarModelsToMatchEquivalentFractions:
    'Use the cards and the bar models to match the equivalent fractions.',
  useBarModelsToShowEquivalentFractions: 'Use the bar models to show the equivalent fractions.',
  useCardsCompareLengths: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the lengths.`,
  useCardsCompareTimes: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the times.`,
  useCardCompleteNumberSentence: 'Use a card to complete the number sentence.',
  useCardsCompleteSequence: 'Use the cards to complete the sequence.',
  useCardCompleteStatement: 'Use a card to complete the statement.',
  useTheCardToMakeAPairOfNumbersWithADifferenceOfX:
    'Use the card to make a pair of numbers with a difference of {0}',
  useTheCardToMatchEquivalentFractionShownInDiagram:
    'Use the card to match the equivalent fraction shown in the diagram.',
  useTheCardToMatchTheEquivalentDecimal: 'Use a card to match the equivalent decimal.',
  useCardsCompleteANumberSentencePDF:
    'Use the cards below to complete a correct number sentence\nEach card can only be used once.',
  useCardsCompleteAddition: 'Use the cards to complete the addition.',
  useCardsCompleteNumberLine: 'Use the cards to complete the number line.',
  useCardsCompleteNumberTrack: 'Use the cards to complete the number track.',
  useCardsCompleteStatement: 'Use the cards to complete the statement.',
  useCardsCompleteStatements: 'Use the cards complete the statements.',
  useCardsConvertFracToDecimalAndPercentage:
    'Use the cards to convert <frac n="{0}" d="{1}" /> to a decimal and a percentage.',
  useCardsCreateDivisionWithRemainderXPDF:
    'Use the cards and symbols below to create a division calculation that gives a remainder {0} {1:number|numberFormat}\nEach card can only be used once.',
  useCardsCompleteCalculationsEachCardCanBeUsedMoreThanOnce:
    'Use the cards to complete the calculations.\nEach card can be used more than once.',
  useCardsCompleteCalculationsEachCardOnlyUsedOnce:
    'Use the cards to complete the calculations.\nEach card can only be used once.',
  useCardsCompleteDivisionsEachCardOnlyUsedOnce:
    'Use the cards to complete the divisions.\nEach card can only be used once.',
  useCardsCompleteNumberSentence: 'Use the cards to complete the number sentence.',
  useCardsCompleteNumberSentences: 'Use the cards to complete the number sentences.',
  useCardsCompleteNumberSentencesEachCardOnlyUsedOnce:
    'Use the cards to complete the number sentences.\nEach card can only be used once.',
  useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce:
    'Use the cards to complete the number sentences.\nEach card can be used more than once.',
  useCardCompleteSentence: 'Use the card to complete the sentence.',
  useCardsCompleteSentence: 'Use the cards to complete the sentence.',
  useCardsCompleteSentences: 'Use the cards to complete the sentences.',
  useCardsCompleteSentencesEachCardOnlyUsedOnce:
    'Use the cards to complete the sentences.\nEach card can only be used once.',
  useCardsCompleteSentencesEachCardCanBeUsedMoreThanOnce:
    'Use the cards to complete the sentences.\nEach card can be used more than once.',
  useCardsConvertXToPercentage: 'Use the cards to convert {0} to a percentage.',
  useCardsEstimateAnswerToNum:
    'Use the cards to estimate the answer to {0}<br/>Each card can be used more than once.',
  useCardsMakeFourDifferentNumberSentences:
    'Use the cards to make four different number sentences.',
  UseCardsMatchDivisibilityRules: 'Use the cards to match the divisibility rules.',
  useCardsMatchEquivalentFractions: 'Use the cards to match the equivalent fractions.',
  useCardsMatchDecimalsToCounters: 'Use the cards to match the decimals to the groups of counters.',
  useCardsMatchNumbersWithDifferenceOf:
    'Use the cards to match the numbers with a difference of {0}',
  useCardsFlexiblyPartitionX: 'Use the cards to flexibly partition {0}',
  useCardsMakeStatementCorrect: 'Use the cards to make the statement correct.',
  useCardsToCompleteCalculationForArea:
    'Use the cards to complete the calculation that works out the area.',
  useCardsToEstimateArea: 'Use the cards to estimate the area of the shape.',
  useCardsToMatchLabelsToAngles: 'Use the cards to match the labels to the angles.',
  useCardsToMatchNamesToTheShape: 'Use the cards to match the name to the shape',
  useCardsToMatchRuleToDiagram: 'Use the cards to match each rule to the correct diagram.',
  useCardsToMatchTheStatements: 'Use the cards to match the statements.',
  useCardsMatchTimes: 'Use the cards to match the times.',
  useCardsToOrderAnglesSmallestGreatest:
    'Use the cards to order the angles from smallest to greatest.',
  useCardsToOrderCapacitiesStartWithGreatest:
    'Use the cards to order the capacities.<br/>Start with the greatest',
  useCardsToOrderCapacitiesStartWithSmallest:
    'Use the cards to order the capacities.<br/>Start with the smallest',
  useCardsToOrderOrangeJuiceFromLeast:
    'Use the cards to put the glasses in order of the amount of orange juice they contain.<br/>Start with the least amount of orange juice.',
  useCardsToOrderOrangeJuiceFromMost:
    'Use the cards to put the glasses in order of the amount of orange juice they contain.<br/>Start with the most amount of orange juice.',
  useCardsToOrderTheDurationsOfTime: 'Use the cards to order the durations of time.',
  useCardsToOrderTheTemp: 'Use the cards to order the temperatures.',
  useCardsToPutShapesInAscendingOrderOfVolume:
    'Use the cards to put the shapes in ascending order of volume.',
  useCardsToPutShapesInDescendingOrderOfVolume:
    'Use the cards to put the shapes in descending order of volume.',
  useCardsToSortAnglesIntoTheTable: 'Use the cards to sort the angles into the table.',
  useCardsToSortLengthsIntoTable: 'Use the cards to sort the lengths into the table.',
  useCardsOrderTheTimesFromXToY:
    'Use the cards to order the times from {0:string|lower} to {1:string|lower}.',
  useCardsOrderValuesGreatestToSmallest:
    'Use the cards to order the values from greatest to smallest.',
  useCardsOrderValuesSmallestToGreatest:
    'Use the cards to order the values from smallest to greatest.',
  useCardsSortSubtractions: 'Use the cards to sort the subtractions into the table.',
  useCardsSortNonUnitFractions: 'Use the cards to sort the non unit fractions into the table.',
  useCardsSortUnitFractions: 'Use the cards to sort the unit fractions into the table.',
  useCardsSortTheMonthsIntoTheTable: 'Use the cards to sort the months into the table.',
  useCardsSolveEquations: 'Use the cards to solve the equations.',
  useCardsStartingGreatest: 'Write the numbers in order.\nStart with the greatest.',
  useCardsStartingSmallest: 'Write the numbers in order.\nStart with the smallest.',
  useCardsToCompareCalculations: 'Use the cards to compare the calculations.',
  useCardsToCompareCalculationsEachCardCanBeUsedMoreThanOnce: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the calculations.<br/>Each card can be used more than once.`,
  useCardsToCompareExpressions: 'Use the cards to compare the expressions.',
  useDiagramToHelpCircleCorrectStatements:
    'Use the diagram to help you circle the correct statements.',
  useDiagramToHelpSelectCorrectStatements:
    'Use the diagram to help you select the correct statements.',
  useDiagramToWorkOutXMultY: `Use the diagram to work out {0: number|numberFormat} ${MULT} {1: number|numberFormat} `,
  useDigitCardsToCreate4DigitNumberWithDigitsOnlyUsedOnce:
    'Use the digit cards to create a 4-digit number with {0:number|numberFormat} in the {1} place.\nEach digit can only be used once.',
  useCardsToDescribeTranslation: 'Use the cards to describe the translation.',
  useCardsToFindTwoPossiblePairsOfValuesForXAndY:
    'Use the cards to find two possible pairs of values for {0} and {1}.',
  useCardsMakeNumber: 'Use the cards to make {0:string|lower}',
  useCardsToMatchAbbreviations: 'Use the cards to match the abbreviations to the words.',
  useCardsToMatchAmounts: 'Use the cards to match the amounts.',
  useCardsToMatchApproxCapacityToEachContainer:
    'Use the cards to match the approximate capacity to each container.',
  useCardsToMatchApproxVolumeToEachObject:
    'Use the cards to match the approximate volume to each object.',
  useCardsToMatchCalcs: 'Use the cards to match the calculations.',
  useCardToMatchCalcToRep: 'Use the card to match the calculation to the representation.',
  useCardsToMatchCalcToRep: 'Use the cards to match the calculation to the representation.',
  useCardsToMatchCalcsToReps: 'Use the cards to match the calculations to the representations.',
  useCardsToMatchDecimalAndFracEquivs:
    'Use the cards to match the decimal and fraction equivalents.',
  useCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize:
    'Use the cards to match each angle to an appropriate estimate of its size.',
  useCardsToMatchEachCalcWithEstAnswer:
    'Use the cards to match each calculation with an estimated answer.',
  useCardsToMatchEquivalentFracsAndDecimals:
    'Use the cards to match the equivalent fractions and decimals.',
  useCardsToMatchEquivalentLengths: 'Use the cards to match the equivalent lengths.',
  useCardsToMatchListsWithStatements: 'Use the cards to match the lists with the statements.',
  useCardsToMatchPercentagesToFractions:
    'Use the cards to match the converted percentages to the fractions.',
  useCardsToMatchPlaceValueCharts: 'Use the cards to match the labels to the place value charts.',
  useCardsToMatchEquivalentFracsWordsAndDecimals:
    'Use the cards to match the equivalent fractions, decimals and words.',
  useCardsToMatchShapeToQuadrilateral:
    'Use the cards to match the shape to the type of quadrilateral.',
  useTheCardsToMatchStatementToBarModels:
    'Use the cards to match the statements to the bar models.',
  useTheCardsToMatchTheStatementsWithPictures:
    'Use the cards to match the statements with the pictures.',
  useTheCardsToMatchPerimetersToTheShapes: 'Use the cards to match the perimeters to the shapes.',
  useTheCardsToMatchTheBaseAndHeightOfTriangle: `Use the cards to match the base, ${ALGEBRAIC_B}, and the perpendicular <g>height, ${ALGEBRAIC_H},</g> on the triangle.`,
  useCardsToMatchThePairsOfDecimlasThatAddTogetherToMakeOneWhole:
    'Use the cards to match the pairs of decimals that add together to make 1 whole.',
  useCardsToMatchTypeOfAnglesToTheDescriptions:
    'Use the cards to match the types of angles to the descriptions.',
  useCardsToMatchTypeOfTriangleToDefinition:
    'Use the cards to match the type of triangle to the definition.',
  useCardsToMatchTypeOfTriangleToShape: 'Use the cards to match the type of triangle to the shape.',
  useCardsToMatchXToY: 'Use the cards to match the {0:string|lower} to the {1:string|lower}.',
  useCardsToOrderFractions:
    'Use the cards to order the fractions.<br/>Start with the {0:string|lower}.',
  useCardsToOrderFractionsStartingWithGreatest:
    'Use the cards to order these fractions, starting with the greatest.',
  useCardsToOrderFractionsStartingWithGreatestCanShadeBarModel:
    'Use the cards to order the fractions, starting with the greatest.<br/>You can shade the bar models to help.',
  useCardsToOrderFractionsStartingWithSmallest:
    'Use the cards to order these fractions, starting with the smallest.',
  useCardsToOrderFractionsStartingWithSmallestCanShadeBarModel:
    'Use the cards to order the fractions, starting with the smallest.<br/>You can shade the bar models to help.',
  useCardsToOrderLengthsStartWithLongest:
    'Use the cards to order the lengths.<br/>Start with the longest length.',
  useCardsToOrderLengthsStartWithShortest:
    'Use the cards to order the lengths.<br/>Start with the shortest length.',
  useCardsToOrderTheMassesStartWithTheXMass:
    'Use the cards to order the masses.<br/>Start with the {0:string|lower} mass.',
  useCardsToOrderMixedNumbers:
    'Use the cards to order the mixed numbers.<br/>Start with the {0:string|lower}.',
  useCardsToOrderNumbersFromGreatestToSmallest:
    'Use the cards to order these numbers from greatest to smallest.',
  useCardsToOrderNumbersFromSmallestToGreatest:
    'Use the cards to order these numbers from smallest to greatest.',
  useCardsToOrderRomanNumeralsFromXToY:
    'Use the cards to order the Roman numerals from {0:string|lower} and {1:string|lower}.',
  useCardsToOrderTheseTimesFromEarliestToLatest:
    'Use the cards to order these times from earliest to latest.',
  useCardsToOrderTheTimesOfDayInOrderOfWhenTheyHappen:
    'Use the cards to order the times of day in the order of when they happen.',
  useCardsToOrderXFromYToZ:
    'Use the cards to order the {0:string|lower} from {1:string|lower} to {2:string|lower}.',
  useCardsToPlaceCalculationsInAscendingOrder:
    'Use the cards to place the calculations in ascending order.',
  useCardsToPlaceCalculationsInDescendingOrder:
    'Use the cards to place the calculations in descending order.',
  useCardsToPlaceNumbersInAscendingOrder: 'Use the cards to place the numbers in ascending order.',
  useCardsToPlaceNumbersInDescendingOrder:
    'Use the cards to place the numbers in descending order.',
  useCardsToPutFracDecPercInOrder:
    'Use the cards to put the fractions, decimals and percentages in order.<br/>Start with the {0:string|lower} value.',
  useCardsToPutTheseAmountsInAscendingOrder:
    'Use the cards to put these amounts in ascending order.',
  useCardsToPutTheseAmountsInDescendingOrder:
    'Use the cards to put these amounts in descending order.',
  useCardsToRoundX: 'Use the cards to round {0} to the nearest integer, tenth and hundredth.',
  useCardsToSelectTheAreasOfTheRectangleAndTriangle:
    'Use the cards to select the areas of the rectangle and the triangle.',
  useCardsToShowAreaOfRectangleAndParallelogram:
    'Use the cards to show the area of the rectangle and the parallelogram.',
  useCardsToShowMixedNumbersInTheirCorrectForm:
    'Use the cards to show the mixed numbers in their correct form.',
  useCardsToShowMostLeastPopularFruit: 'Use the cards to show the {0:string|lower} popular fruit.',
  useCardsToShowYourAnswer: 'Use the cards to show your answer.',
  useCardsToSortCalculationsInTable: 'Use the cards to sort the calculations into the table.',
  useCardsToSortTheShapes: 'Use the cards to sort the shapes.',
  useCardsToSortShapesInTable: 'Use the cards to sort the shapes into the table.',
  useCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen:
    'Use the cards to sort the events into the time of day that they usually happen.',
  useCardsToSortEquivalentFractionsIntoTable:
    'Use the cards to sort the equivalent fractions into the table.',
  useCardsToSortFractionsIntoTheTable: 'Use the cards to sort the fractions into the table.',
  useCardsToSortUnitsOfMeasurementIntoTable:
    'Use the cards to sort the units of measurement into the table.',
  useCardsToWorkOutXMultY: `Use the cards to work out {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  useACardToCompleteTheTimeShownOnTheDigitalClock:
    'Use a card to complete the time shown on the digital clock.',
  useCardToMatchTheDigitalTimeToTheAnalogueClock:
    'Use a card to match the digital time to the analogue clock.',
  useCardToWorkOutTheArea: 'Use the card to work out the area.',
  useCardToWorkOutTheAreaOfRectilinearShape:
    'Use the card to work out the area of the rectilinear shape.',
  useCharactersFactToCompleteTheMultiplications: "Use {0}'s fact to complete the multiplications.",
  useCorrectCard: 'Use the correct card.',
  useCountersToHelp: 'Use the counters to help you.',
  useCountersToHelpCompleteSentences: 'Use the counters to help you complete the sentences.',
  useEachCardToCompleteEquivalentFractions: 'Use each card to complete equivalent fractions.',
  useFactorPairsToWorkOutDivision: 'Use factor pairs to work out the division.',
  useGivenFactsToWorkOutCals: 'Use the given facts to work out the calculations.',
  useGreaterThanAndLessThanToCompareTemperatures: `Use ${LESS_THAN}, ${GREATER_THAN} to compare the temperatures.`,
  useGreaterThanAndLessThanToCompleteStatements: `Use ${LESS_THAN} and ${GREATER_THAN} to complete the statements.`,
  useGreaterThanAndLessThanToMakeStatementsCorrect: `Use ${LESS_THAN} and ${GREATER_THAN} to make the statements correct.`,
  useGreaterLessThanOrApproxEqualsToCompleteStatements: `Use ${LESS_THAN}, ${GREATER_THAN} or ${APPROX_EQUAL} to complete the statements.`,
  useGreaterLessThanOrEqualsToCompareAmounts: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the amounts.`,
  useGreaterLessThanOrEqualsToCompareCalculations: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the calculations.`,
  useGreaterLessThanOrEqualsToCompareDivisons: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the divisions.`,
  useGreaterLessThanOrEqualsToCompareExpressions: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the expressions.`,
  useGreaterLessThanOrEqualsToCompareFractions: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the fractions.`,
  useGreaterLessThanOrEqualsToCompareMixedNumbers: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the mixed numbers.`,
  useGreaterLessThanOrEqualsToCompareNumbers: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the numbers.`,
  useGreaterLessThanOrEqualsToCompareTemperatures: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare temperatures.`,
  useGreaterLessThanOrEqualsToCompareUnitFractions: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the unit fractions.`,
  useGreaterLessThanOrEqualsToCompareVolumes: `Use ${LESS_THAN}, ${GREATER_THAN} or =  to compare the volumes.`,
  useGreaterLessThanOrEqualsToCompareVolumesOfShapes: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the volumes of the shapes.`,
  useGreaterLessThanOrEqualsToCompleteStatement: `Use ${LESS_THAN}, ${GREATER_THAN} or = to complete the statement.`,
  useGreaterLessThanOrEqualsToCompleteStatements: `Use ${LESS_THAN}, ${GREATER_THAN} or = to complete the statements.`,
  useGreaterLessThanOrEqualsToMakeStatementCorrect: `Use ${LESS_THAN}, ${GREATER_THAN} or = to make the statement correct.`,
  useGreaterLessThanOrEqualsToMakeStatementsCorrect: `Use ${LESS_THAN}, ${GREATER_THAN} or = to make the statements correct.`,
  useTheCardsToMatchImproperFractionsToMixedNumber:
    'Use the cards to match the improper fractions to the mixed number.',
  usePartWholeModelToCompleteSentence: 'Use the part-whole model to complete the sentence.',
  usePartWholeModelToWorkOutMissingValue: 'Use the part-whole model to work out the missing value.',
  usePartWholeModelToDivide: 'Use the part-whole model to help you divide {0} by {1}',
  usePartWholeModelToMultiplyXByY:
    'Use the part-whole model to multiply {0:number|numberFormat} by {1:number|numberFormat}',
  usePartWholeModelToSolve: 'Use the part-whole model to solve {0}',
  usePartWholeModelToWorkOut: 'Use the part-whole model to help you work out {0}',
  usePartitionToCompleteTheNumberSentence: 'Use the partition to complete the number sentence.',
  usePartitioningToWorkOutXPoundsSubtractYPounds: `Use partitioning to work out {poundsA} ${SUB} {poundsB}`,
  usePlaceValueChartToCompleteNumberSentence:
    'Use the place value chart to complete the number sentence.',
  usePlaceValueChartToHelpCompleteAddition:
    'Use the place value chart to help you complete the addition.',
  usePlaceValueChartToHelpCompleteCalculation:
    'Use the place value chart to help you complete the calculation.',
  usePlaceValueChartToHelpCompleteCalculations:
    'Use the place value chart to complete the calculation.',
  usePlaceValueChartToHelpCompleteSubtraction:
    'Use the place value chart to help you complete the subtraction.',
  usePlaceValueChartToHelpCompleteDivision:
    'Use the place value chart to help you complete the division.',
  usePlaceValueChartToHelpCompleteDivisions:
    'Use the place value chart to help you complete the divisions.',
  usePlaceValueChartToHelpCompleteMultiplications:
    'Use the place value chart to help you complete the multiplications.',
  usePlaceValueChartAndBase10ToSolve: 'Use the place value chart and base ten to solve {0}',
  usePlaceValueChartToSelectCorrectAnswerToXAddY: `Use the place value chart to select the correct answer to {0:number|numberFormat} ${ADD} {1:number|numberFormat}`,
  usePlaceValueChartToCircleCorrectAnswerToXAddYPDF: `Use the place value chart to circle the correct answer to {0:number|numberFormat} ${ADD} {1:number|numberFormat}`,
  usePlaceValueChartToWorkOutX: 'Use the place value chart to work out {0}',
  usePlaceValueChartToWorkOutDivision: 'Use the place value chart to work out the division.',
  usePlaceValueChartToWorkOutMultiplication:
    'Use the place value chart to work out the multiplication.',
  usePlaceValueChartToHelpCompleteNumberSentence:
    'Use the place value chart to help you complete the number sentence.',
  useBarModelToCompleteTheConversion: 'Use the bar model to complete the conversion.',
  useBarModelsToCircleCorrectStatements: 'Use the bar models to circle the correct statements.',
  useBarModelsToCompareFractionsUseLessThanGreaterThanEqualsToMakeStatementCorrect: `Use the bar models to compare the fractions.<br/>Use ${LESS_THAN}, ${GREATER_THAN} and = to make the statement correct.`,
  useBarModelsToCompareFractionsDragCardsToMakeStatementCorrect:
    'Use the bar models to compare the fractions.<br/>Drag the cards to make the statement correct.',
  useBarModelsToCompareFractionsUsingGreaterLessThanOrEqualsForStatement: `Use the bar models to compare the fractions using ${LESS_THAN}, ${GREATER_THAN} or = to make the statement correct.`,
  useBarModelsToSelectCorrectStatements: 'Use the bar models to select the correct statements.',
  useBarModelsToFindTheEquivalentFraction: 'Use the bar models to find the equivalent fraction.',
  useBase10RepToCompleteCalculations:
    'Use the base 10 representation to complete the calculations.',
  useBase10RepToCompleteNumSentence:
    'Use the base 10 representation to complete the number sentence.',
  useBase10ToMakeNum: 'Use base 10 to make the number {0}',
  useBase10ToRepresentNum: 'Use base 10 to represent the number {0}',
  useBase10ToMakeNumLess: 'Use base 10 to make the number that is {0} less than {1}',
  useBase10ToMakeNumMore: 'Use base 10 to make the number that is {0} more than {1}',
  dragBlocksToMakeNumMore: 'Drag the blocks to make the number that is {0} more than {1}',
  dragBlocksToMakeNumLess: 'Drag the blocks to make the number that is {0} less than {1}',
  useCalculationToCompleteNumberSentences: 'Use the calculation to complete the number sentences.',
  useCardsAscendingOrder: 'Use the cards to place them in ascending order.',
  useCardsDescendingOrder: 'Use the cards to place them in descending order.',
  useCardsFindDecimalEquivalentOfFraction:
    'Use the cards to find the decimal equivalent of the fraction.',
  useCardsMakeComplementsTo100EachNumberOnlyUsedOnce:
    'Use the cards to make complements to 100\nEach number can only be used once.',
  useCardsMatchDecimalEquivalents: 'Use the cards to match the decimal equivalents.',
  useCardsMatchFractionEquivalents: 'Use the cards to match the fraction equivalents.',
  useCardsMatchInverseCalcs: 'Use the cards to match the inverse calculations.',
  useCardsToCompleteCalculation: 'Use the cards to complete the calculation.',
  useCardsToCompleteCalculations: 'Use the cards to complete the calculations.',
  useCardsToCompleteEquivalentFractions: 'Use the cards to complete the equivalent fractions.',
  useCardsToCompleteFunctionMachine: 'Use the card to complete the function machine.',
  useCardsToCompleteMultiplication: 'Use the cards to complete the multiplication.',
  useCardsToCreateXDigitMultOfY:
    'Use the cards to create a {0:number|numberFormat}-digit multiple of {1:number|numberFormat}',
  useCardsToFillInMissingFunctions: 'Use the cards to fill in the missing functions.',
  useCardsToMakeASingleStepThatWouldGiveSameOutput:
    'Use the cards to make a single step that would give the same output.',
  useCardsToMakeMultipleOf5And10EachNumberCanOnlyBeUsedOnce:
    'Use the cards to make a 3-digit number that is a multiple of 5 and a multiple of 10<br/>Each number can only be used once.',
  useCardsToMakeMultipleOf5Not10EachNumberCanOnlyBeUsedOnce:
    'Use the cards to make a 3-digit number that is a multiple of 5 and <b>not</b> a multiple of 10<br/>Each number can only be used once.',
  useCardsToMakeMultipleOfX: 'Use the cards to make a multiple of {0:number|numberFormat}',
  useCardsToMatchEachAdditionToEquivMult:
    'Use the cards to match each addition to the equivalent multiplication.',
  useCardsToMatchEquivalentCalcs: 'Use the cards to match the equivalent calculations.',
  useCardsToMatchEquivalentFractionsDecimalsPercentages:
    'Use the cards to match the equivalent fractions, decimals and percentages.',
  useCardsMatchMassesOneXItemHasMassOfYKg:
    'One {0:string|lower} has a mass of {1} kg.<br/>Use the cards to match the masses.',
  useCardsToMatchNumbersToRomanNumerals:
    'Use the cards to match the numbers to the Roman numerals.',
  useCardsToOrderDistancesStartWithLongest:
    'Use the cards to order the distances.<br/>Start with the longest distance.',
  useCardsToOrderDistancesStartWithShortest:
    'Use the cards to order the distances.<br/>Start with the shortest distance.',
  useCardsToOrderFractionsFromGreatestToSmallest:
    'Use the cards to order these fractions from greatest to smallest.',
  useCardsToOrderFractionsFromSmallestToGreatest:
    'Use the cards to order these fractions from smallest to greatest.',
  useCardsToOrderMeasurementsStartWithGreatest:
    'Use the cards to order the {0:string|lower}.<br/>Start with the greatest.',
  useCardsToOrderMeasurementsStartWithSmallest:
    'Use the cards to order the {0:string|lower}.<br/>Start with the smallest.',
  useCardsToOrderNumbersInAscendingOrder: 'Use the cards to order the numbers in ascending order.',
  useCardsToOrderNumbersInDescendingOrder:
    'Use the cards to order the numbers in descending order.',
  useCardsToOrderNumbersStartWithGreatest:
    'Use the cards to order the numbers.<br/>Start with the greatest.',
  useCardsToOrderNumbersStartWithSmallest:
    'Use the cards to order the numbers.<br/>Start with the smallest.',
  useCardsToOrderTheseMeasurementsFromSmallestToGreatest:
    'Use the cards to order these measurements from smallest to greatest.',
  useCardsToSortFractionsIntoTable: 'Use the cards to sort the fractions into the table.',
  useColumnMethodToWorkOut: 'Use the column method to work out {0}',
  useColumnMethodToWorkOutAddition: 'Use the column method to work out the addition.',
  useColumnMethodToWorkOutSubtraction: 'Use the column method to work out the subtraction.',
  useCubesSimplifyExpressions: 'Use the cubes to simplify the expressions.',
  useDiagramToHelpCompleteCalculation: 'Use the diagram to help you complete the calculation.',
  useDiagramToHelpCompleteDivision:
    'Use the diagram to help you complete the division.<br/>Give your answer in its simplest form.',
  useDiagramsToCompleteEquivalentFractions:
    'Use the diagrams to complete the equivalent fractions.',
  useDigitCardsToWriteX: 'Use the digit cards to write {0}.',
  useDivisRulesForXAndYToFindNum:
    'Use the divisibility rules for {0} and {1} to find the number that is divisible by {2}',
  useEfficientMethodToCompleteSubtraction: 'Use an efficient method to complete the subtraction.',
  useEstimationToWorkoutCorrectCalculationSelectCorrectAnswer:
    'Use estimation to work out which calculation is correct.<br/>Select the correct answer.',
  useEstimationToWorkoutCorrectCalculationCircleCorrectAnswer:
    'Use estimation to work out which calculation is correct.<br/>Circle the correct answer.',
  useFactToWorkOutDivisions: 'Use the fact to work out the divisions.',
  useFunctionMachineToCompleteTable: 'Use the function machine to complete the table.',
  useGattegnotoShowAnswer: 'Use the Gattegno chart to show your answer to {0}',
  useGattegnotoShowNum: 'Use the Gattegno chart to show {0}',
  useGattegnotoShowNumLessThan:
    'Use the Gattegno chart to show the number that is {0} less than {1}',
  useGattegnotoShowNumMoreThan:
    'Use the Gattegno chart to show the number that is {0} more than {1}',
  useGattegnotoShowTheAnswer: 'Use the Gattegno chart to show the answer to {0}',
  useGridToHelp: 'Use the grid to help.',
  useGivenFactToCompleteCalc: 'Use the given fact to help you complete the calculation.',
  useHundredSquareToHelpWorkOutEquivalentFractionAndPercentage:
    'Use the hundred square to help you work out the equivalent fraction and percentage.',
  useInequalitiesToCompleteNumberSentence: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the number sentence.`,
  useInequalitiesToCompleteNumberSentences: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the number sentences.`,
  useInequalitiesToCompleteNumberSentencesEachSymbolCanBeUsedMoreThanOnce: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the number sentences.<br/>Each symbol can be used more than once.`,
  useInequalitiesToCompleteSentences: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the sentences.`,
  useInequalitiesToCompleteSentencesEachSymbolCanBeUsedMoreThanOnce: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the sentences.<br/>Each symbol can be used more than once.`,
  useInequalitiesToCompleteStatement: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the statement.`,
  useInequalitiesToCompleteStatements: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the statements.`,
  useInequalitiesToCompleteStatementsEachSymbolCanBeUsedMoreThanOnce: `Use ${LESS_THAN}, ${GREATER_THAN} and = to complete the statements.<br/>Each symbol can be used more than once.`,
  useInequalitySymbolsCompleteStatementYouCanShadeTheBarModelsToHelp: `Use ${LESS_THAN}, ${GREATER_THAN} or = to complete the statement.<br/>You can shade the bar models to help.`,
  useInequalitySymbolsToCompareTheFractions: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the fractions.`,
  useInequalitySymbolsToCompareTheFractionsShadeBarModelsToHelp: `Use ${LESS_THAN}, ${GREATER_THAN} or = to compare the fractions.<br/>Shade the bar models to help.`,
  useInequalitySymbolsToCompareTheNumbers: `Use ${GREATER_THAN}, ${LESS_THAN} and = to compare the numbers.`,
  useInequalitySymbolsToCompareTheUnitFractions: `Use ${GREATER_THAN}, ${LESS_THAN} and = to compare the unit fractions.`,
  useInequalitySymbolsToMakeStatementCorrect: `Use ${LESS_THAN}, ${GREATER_THAN} and = to make the statement correct.`,
  useInequalitySymbolsToMakeStatementsCorrect: `Use ${LESS_THAN}, ${GREATER_THAN} and = to make the statements correct.`,
  useInverseOperationToWorkOutWhetherCalcIsCorrect:
    'Use an inverse operation to work out whether the calculation is correct.',
  useInverseOperationToWorkOutWhetherCalcIsCorrectPDF:
    'Use an inverse operation to work out whether the calculation is correct.<br/>Circle the correct answer.',
  dragTheCardsToMakeStatementsCorrect: 'Drag the cards to make these statements correct.',
  useInfoToCompleteTheTable: 'Use the information to complete the table.',
  useLessThanGreaterThanToCompareAngles: `Use ${LESS_THAN} and ${GREATER_THAN} to compare the sizes of angles.`,
  useLessThanGreaterThanEqualsToCompareAngles: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the sizes of angles.`,
  useLessThanGreaterThanEqualsToCompareAreasOfShadedShapes: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the areas of the shaded shapes.`,
  useLessThanGreaterThanEqualsToCompareDurations: `Use ${LESS_THAN}, ${GREATER_THAN} and = to compare the durations.`,
  useMultiplicationGivenToCompleteRelatedFacts:
    'Use the multiplication given to complete the related facts.',
  useMultsOfNumToCompleteLongDivision:
    'Use the multiples of {0:number|numberFormat} to complete the long division.',
  useNotesAndCoinsToMakeXPoundsAndYPence: 'Use notes and coins to make {0}',
  useNamesMethodToFindEquivalentFraction: "Use {0}'s method to find the equivalent fraction.",
  useNamesMethodToFindEquivalentFractions: "Use {0}'s method to find the equivalent fractions.",
  useNumberCardsToCompleteCalculationEachNumberUsedMoreThanOnce:
    'Use the number cards to complete the calculations. Each number can be used more than once.',
  useNumberCardsToCompleteMultiplication: 'Use the number cards to complete the multiplication.',
  useNumberCardsToMatchMixedNumbersToImproperFractions:
    'Use the number cards to match the mixed numbers to the improper fractions.',
  useNumberLineToCompleteAddition: 'Use the number line to complete the addition.',
  useNumberLineToCompleteEquivFraction: 'Use the number line to complete the equivalent fraction.',
  useNumberLineToCompleteEquivFractions:
    'Use the number line to complete the equivalent fractions.',
  useNumberLineToCompleteDiv: 'Use the number line to complete the division.',
  useNumberLineToCompleteMult: 'Use the number line to complete the multiplication.',
  useNumberLineToFindComplementTo100: 'Use the number line to find the complement to 100',
  useNumberLineToFindDiffBetweenXAnsY:
    'Use the number line to find the difference between {0} and {1}',
  useNumberLineToHelp: 'Use the number line to help.',
  useNumberLineToWorkOut: `Use the number line to work out {0} ${SUB} {1}`,
  useNumberLineToWorkOutXAddY: `Use the number line to work out {0} ${ADD} {1}`,
  usePlaceValueCountersToCompleteDivision: 'Use the place value counters to complete the division.',
  usePieChartsToConvertImproperFractionToMixedNumber:
    'Use the pie charts to convert the improper fraction to a mixed number.',
  usePlaceValueChartToHelpCalculateX: 'Use the place value chart to help you calculate <g>{0}</g>',
  usePlaceValueCountersToCompletePartition:
    'Use the place value counters to complete the partition.',
  useRomanNumeralsToMakeNum: 'Use the Roman numerals to make the number {0:number|numberFormat}',
  useRomanNumeralsToMakeNumEachRomanNumeralCanBeUsedMoreThanOnce:
    'Use the Roman numerals to make the number {0:number|numberFormat}\nEach Roman numeral can be used more than once.',
  useSumToCompletePartWholeModel: 'Use the fact {0} + {1} = {2} to complete the part-whole model.',
  useInequalityAndEqualSymbolsCompleteStatements: `Use the ${LESS_THAN}, ${GREATER_THAN} and = symbols to complete the number statements.\nEach symbol can be used more than once.`,
  useTenFrameToCompleteSentences: 'Use the ten frame and counters to complete the sentences.',
  useTheAnswerFromFirstCalcToWorkOutSecondCalc:
    'Use the answer from the first calculation to work out the second calculation.',
  useTheMethodBelowToCompleteCalc: 'Use the method below to complete the calculation.',
  useTheAreaModelToCompleteTheMultiplication: 'Use the area model to complete the multiplication.',
  useTheArrayToCompleteNumSentence: 'Use the array to complete the number sentence.',
  useTheArraysToFindCommonFactorOfNumAndNum:
    'Use the arrays to find a common factor\nof {0} and {1}',
  useTheBarModelToHelp: 'Use the bar model to help you.',
  useTheBarModelToCompleteCalc: 'Use the bar models to help you complete the calculation.',
  useTheBarModelToHelpYouCompleteTheNumberSentence:
    'Use the bar model to help you complete the number sentence.',
  useTheBarModelToFindFracOfTotalX: 'Use the bar model to find {0} of {1:number|numberFormat}',
  useTheBarModelToHelpCompleteTheAddition: 'Use the bar model to help you complete the addition.',
  useTheBarModelToHelpCompleteTheSubtraction:
    'Use the bar model to help you complete the subtraction.',
  useTheBarModelToHelpFindEquivalentFraction:
    'Use the bar models to help find the equivalent fraction.',
  useTheBarModelToHelpYouConvertXMinutesAndYSecondsIntoSeconds:
    'Use the bar model to help you convert {0} minute{{s}} and {1} second{{s}} into seconds.',
  useTheBarModelsToHelp: 'Use the bar models to help you.',
  useTheCardToShowTheMissingNumberOnTheNumberLine:
    'Use the card to show the missing number on the number line.',
  useTheCardsToMatchEquivalentCalcs: 'Use the cards to match the equivalent calculations.',
  useTheDiagramToCompleteEquivalence: 'Use the diagram to complete the equivalence statements.',
  useTheDiagramToCompleteMultiplication: 'Use the diagram to complete the multiplication.',
  useTheHundredSquareToHelpYouCompleteTheCalculation:
    'Use the hundred square to help you complete the calculation.',
  useTheFactThatToWorkOut: `Use the fact that {0:number|numberFormat} ${MULT} {1:number|numberFormat} = {2:number|numberFormat} to work out`,
  useTheFactThatXToCompleteCalc: 'Use the fact that {0} to complete the calculation.',
  useTheFactThatXToCompleteCalcs: 'Use the fact that {0} to complete the calculations.',
  useTheFactThatXPlusYEqualsZ: 'Use the fact that {0} + {1} = {2} to complete the calculations.',
  useTheFractionWallToCompleteEquivalentFractions:
    'Use the fraction wall to complete the equivalent fractions.',
  selectCorrectAnswerToMultuseThePVCToHelp: `Select the correct answer to {0:number|numberFormat} ${MULT} {1:number|numberFormat}<br/>Use the place value chart to help.`,
  useThePVCToHelpYouWorkOutTheMissingNumber:
    'Use the place value chart to help you work out the missing number.',
  useTheTenFramesToWorkOutTheAdditions: 'Use the 10 frames to work out the additions.',
  useTheTenFramesToWorkOutTheSubtractions: 'Use the 10 frames to work out the subtractions.',
  useTheseKeyFactsToCompleteTheConversions: 'Use these key facts to complete the conversions.',
  useTheseMultsOfNumToCompleteLongDivision:
    'Use these multiples of {0:number|numberFormat} to complete the long division.',
  useThisFactToCompleteCalcs: 'Use this fact to complete the calculations.',
  useThisFactToCompleteConversion: 'Use this fact to complete the conversion.',
  useWordsToCompleteSentences: 'Use the words to complete the sentences.',
  useWordsToDescribeTheTurn: 'Use the words to describe the turn.',
  useWorkingToAdd: 'Use the working out to add {0:number|numberFormat} and {1}',
  useYourTimesTableKnowledgeToWorkOutTheAmounts:
    'Use your times-table knowledge to work out the amounts.',
  useXandYToCompleteTheSentence: 'Use {0} and {1} to complete the sentence.',
  volumeOfCuboidIsXcm3WorkOutLengthMarkedY:
    'The volume of the cuboid is {0} cm³.<br/>Work out the length marked {1}.',
  whatAlgebraicExpressionDescribesSetOfCubes:
    'What algebraic expression describes the set of cubes?',
  whatAreCoordinatesOfAnyPointInQuadX:
    'What are the coordinates of any point in <g>quadrant {0}?</g>',
  whatAreTheCommonFactorsOfXAndY: 'What are the common factors of {0} and {1}?',
  whatAreTheCoordinatesOfVerticesXY: 'What are the coordinates of vertices {0} and {1}?',
  whatAreLengthsXAndY: 'What are the lengths {0} and {1}?',
  whatAreValuesOfAandB: 'The difference between A and B is {0}<br/>What is the value of {1}?',
  whatCalculationIsRepresentedByArray: 'What calculation is represented by the array?',
  whatCalculationIsShownByPVC: 'What calculation is shown by the place value counters?',
  whatCouldMissingDenominatorsBe:
    'What could the missing denominators be?<br/>Give two possibilities.',
  whatCouldMissingNumeratorsBe: 'What could the missing numerators be?<br/>Give two possibilites.',
  whatCouldMixedNumberBe: 'What could the missing mixed number be?',
  whatCouldNextNumberBe:
    'What could the next number in the sequence be?<br/>Give two possible answers.',
  whatCommonDenominatorToUse: 'What common denominator could you use to {0} the fractions?',
  whatCouldTheTempInXBe: 'What could the temperature in {0} be?',
  whatCouldTheValuesOfTheSquareAndTriangleBe:
    'What could the values of the square and the triangle be?',
  whatDecimalNumbers: 'What decimal numbers are the arrows pointing to?',
  whatFractionAndDecimalDoTheCountersRepresent:
    'What fraction and decimal do the counters represent?',
  whatFractionAndDecimalDoTheTenFramesRepresent:
    'What fraction and decimal do the ten frames represent?',
  whatFractionAndDecimalIsRepresent: 'What fraction and decimal is represented?',
  whatFractionDecimalIsShownOnHundredSquare:
    'What fraction and decimal is shown on the hundred square?',
  whatFractionDecimalPercentageOfGridIsShaded:
    'What fraction, decimal and percentage of the grid is shaded?',
  whatFractionIsShown: 'What fraction is shown?',
  whatFractionIsShownByBarModelGiveAnswerAsMixedAndImproper:
    'What fraction is shown by the bar model?<br/>Give your answer as a mixed number and an improper fraction.',
  whatFractionOfTheBarModelIsShaded: 'What fraction of the bar model is shaded?',
  whatFractionOfLitreOfWaterIsInTheJug: 'What fraction of a litre of water is in the jug?',
  whatFractionOfTheShapeIsShaded: 'What fraction of the shape is shaded?',
  whatFractionIsNotShaded: 'What fraction is not shaded?',
  whatIntegersDoesXLieBetween: 'What integers does {0: number|numberFormat} lie between?',
  whatIsGreatestSmallestAmountOfMoneyCharCouldHave:
    'What is the {0:string|lower} amount of money {1} could have?',
  whatIsAreaOfShadedShape: 'What is the area of the shaded shape?',
  whatIsAreaOfShape: 'What is the area of the shape?',
  whatIsAreaOfTriangle: 'What is the area of the triangle?',
  whatIsAreaOfPitch: 'What is the area of the pitch?',
  whatIsApproximateDistanceAfterXMinutes:
    'What is the <b>approximate</b> distance travelled by the car after {0: number|numberFormat} minute{{s}}?',
  whatIsCalculationShownByAreaModel: 'What is the calculation shown by the area model?',
  whatIsComplementTo100ForEachNumber: 'What is the complement to 100 for each number?',
  whatIsDifferenceBetweenFractions: 'What is the difference shown between the fractions?',
  whatIsDifferenceInAreaOfShapes: 'What is the difference in the area of the shapes?',
  whatIsDifferenceInTempOfXAndY: 'What is the difference in temperature between {0} and {1}?',
  whatIsDiffLessThanNum: 'What is {0} less than {1}?',
  whatIsDiffMoreThanNum: 'What is {0} more than {1}?',
  whatIsLengthOfX: 'What is the length of {0:string|lower}?',
  whatIsMass: 'What is the mass of the object?',
  whatIsMassOfObjects: 'What is the mass of the objects?',
  whatIsMissingPart: 'What is the missing part?',
  whatIsScaleCountingUpIn: 'What is the scale counting up in?',
  whatIsTheCapacityOfTheJug: 'What is the capacity of the jug?',
  whatIsTheDifferenceBetweenNumberOfVisitorsOnDayXandDayY:
    'What is the difference between the number of visitors on {0} and the number of visitors on {1}?',
  whatIsTheDifferenceInTheDistanceTheyTravel: 'What is the difference in the distance they travel?',
  whatIsFactorOfEnlargement: 'What is the scale factor of enlargement from A to B?',
  whatIsTempInX: 'What is the temperature in {0}?',
  whatIsTempShownOnThermometer: 'What is the temperature shown on the thermometer?',
  whatIsTheFewestNumberOfCoinsYouCanUseToMakeXPence:
    'What is the fewest number of coins you can use to make {0}p?',
  whatIsTheNumberShownByTheRomanNumerals: 'What is the number shown by the Roman numerals?',
  whatIsTheLengthOfTheLine: 'What is the length of the line?',
  whatIsTheLengthOfTheRibbonAsAFractionOfAMetre:
    'What is the length of the ribbon as a fraction of a metre?',
  whatIsTheLengthOfTheSide: 'What is the length of the side?',
  whatIsTheLengthOfXInCentimetersAndMillimeters:
    'What is the length of {0:string|lower} in centimetres and millimetres?',
  whatIsThePerimeterOfTheRectangle: 'What is the perimeter of the rectangle?',
  whatIsThePerimeterOfTheShape: 'What is the perimeter of the shape?',
  whatIsThePerimeterOfTheSquare: 'What is the perimeter of the square?',
  whatIsThePerimeterOfTheTriangle: 'What is the perimeter of the triangle?',
  whatIsThePriceOfTheItemInPence: 'What is the price of the item in pence?',
  whatIsTheSizeOfTheAngle: 'What is the size of the angle?',
  whatIsTheTemperatureAtXOption: 'What is the temperature at {0:string|lower}?',
  whatIsTheVolumeOf3DShape: 'What is the volume of the 3-D shape?',
  whatIsTheVolumeOfJuice: 'What is the volume of juice in the jug?',
  whatIsTheVolumeOfWater: 'What is the volume of water in the jug?',
  whatIsTheWhole: 'What is the whole?',
  whatIsTheWidthOfThePlace: 'What is the width of the {0:string|lower}?',
  whatIsTotalMassOfIngredients: 'What is the total mass of the ingredients?',
  whatIsTotalMassOfXAndY: 'What is the mass of {0} and {1}?',
  whatIsTotalMassOfTheXAndTheY:
    'What is the total mass of the {0: string|lower} and the {1: string|lower}?',
  whatIsUnknownLength: 'What is the unknown length?',
  whatIsValueOfDigitXInY: 'What is the value of the digit {0} in {1}?',
  whatIsValueShownOnTenFrame: 'What is the total value shown on the ten frame?',
  whatIsXAsFraction: 'What is {0} as a fraction?',
  whatMixedNumberIsShown: 'What mixed number is shown by the diagram?',
  whatMultIsShown: 'What multiplication is shown?',
  whatMultAndDivFactsDoesTheArrayShow:
    'What multiplication and division facts does the array show?',
  whatNumberIsTheArrowPointingTo: 'What number is the arrow pointing to?',
  whatNumbersAreTheArrowsPointingTo: 'What numbers are the arrows pointing to?',
  whatNumberIsShown: 'What number is shown?',
  whatNumberIsShownOnThePlaceValueChart: 'What number is shown on the place value chart?',
  whatIsMissingLength: 'What is the missing length?',
  whatIsTheAmountInPoundsAndPence: 'What is the amount in pounds and pence?',
  whatIsTheDifferenceBetweenXPointsScoredByColourYTeamAndColourZTeam:
    'What is the difference between the number of points scored by the {0:string|lower} team and the {1:string|lower} team?',
  whatIsTheLengthOfTheXShape: 'What is the length of the {0:string|lower}?',
  whatIsTheLengthOfTheXShapeYouCanDragXShapeToHelpYou:
    'What is the length of the {0:string|lower}?<br/>You can drag the {0:string|lower} to help you.',
  whatIsTheRomanNumeralAsANumber: 'What is the Roman numeral as a number?',
  whatIsTheTotalNumberOfVisitorsFromMonFri:
    'What is the total number of visitors from Monday to Friday?',
  whatIsTheValueOfXInEachOfTheNumbersDragCards:
    'What is the value of the {0:number|numberFormat} in each of the numbers?<br/>Drag the cards to match the correct numbers.',
  whatIsTheValueOfXInEachOfTheNumbersUseCards:
    'What is the value of the {0:number|numberFormat} in each of the numbers?<br/>Use the cards to match to the correct numbers.',
  whatIsVolumeOfCuboidEachCubeIs1cm3: 'What is the volume of the cuboid?<br/>Each cube is 1 cm³.',
  whatIsVolumeOfLiquidInEachJug: 'What is the volume of the liquid in each jug?',
  whatNumberHasBeenMade: 'What number has been made?',
  whatPercentageOfTheBarModelIsShaded: 'What percentage of the bar model is shaded?',
  whatPercentageOfHundredSquareIsNotShaded:
    'What percentage of the hundred square is <b>not</b> shaded?',
  whatTimeDidCharacterLeave:
    '{0} leaves their house to go to the cinema. It takes {0} 1 hour and {1} minutes to get there. When they arrive, the time is {2}\nWrite what time {0} left their house as a 12-hour clock time.',
  whatTimeDoesBusXArriveAtTheY: 'What time does bus {0} arrive at the {1:string|lower}?',
  whatTimeDoesTheXTrainFromPlaceYArriveAtPlaceZ:
    'What time does the {0} train from {1} arrive in {2}?',
  whatTimeIsShownOnClock: 'What time is shown on the clock?',
  whatTimeWasXAndYTheSame:
    'At what time was the recorded amount of water consumed on {0} and {1} the same?',
  whatWasPopulationAtEndOfYear: 'What was the population at the end of {0}?',
  whatWasTheHeightOfThePlantOnDayX:
    'What was the height of the plant on day {0:number|numberFormat}?',
  whatWasTheTemperatureAtX: 'What was the temperature at {0}?',
  whereIsNumPdf: 'Draw an arrow to show where {0:number|numberFormat} is on the number line.',
  whichAdditionsNeedAnExchange: 'Which additions need an exchange?',
  whichAdditionsWillNeedAnExchange: 'Which additions will need an exchange?',
  whichCalcFindHowManyPossCombosThereAre:
    'Which calculation finds how many possible combinations there are altogether?',
  whichCalcFindHowManyPossCombosThereArePDF:
    'Which calculation finds how many possible combinations there are altogether? Circle your answer.',
  whichCalcGivesSameAnswerAsStatement: 'Which calculation gives the same answer as the statement?',
  whichCalcGivesSameAnswerAsStatementPdf:
    'Which calculation gives the same answer as the statement? Circle your answer.',
  whichCalcHasGreatestAnswer: 'Which calculation has the greatest answer?',
  whichCalcHasSmallestAnswer: 'Which calculation has the smallest answer?',
  whichCalcsHaveAnswerOfX: 'Which calculations have an answer of {0:number|numberFormat}?',
  whichCalcsHaveRemainder: 'Select which calculations will have a remainder.',
  whichCalcsDoNotShowComplementsToXCircleAnswer:
    'Which of the calculations do NOT show complements to {0:number|numberFormat}?<br/>Circle your answers.',
  whichCalcsWillNeedAnExchange: 'Which calculations will need an exchange?',
  whichCardsMatchArray: 'Which cards match the array?',
  whichColAddIsLaidOutCorrectly: 'Which column addition is laid out correctly?',
  whichColSubIsLaidOutCorrectly: 'Which column subtraction is laid out correctly?',
  whichDiagramShowsX: 'Which diagram shows {0}?',
  whichDiagramShowsXPDF: 'Which diagram shows {0}?<br/>Circle the correct answer.',
  whichDigitChanges: '{0} works out {1}\nWhich digit will change?',
  whichFracShownOnNumberLine: 'Select the fraction that is shown on the number line.',
  whichFracShownOnNumberLinePDF: 'Circle the fraction that is shown on the number line.',
  whichNumbersDivideExactly: 'Which two numbers divide by {0:number|numberFormat} exactly?',
  whichNumberIsGreaterTickYourAnswer: 'Which number is greater? Tick your answer.',
  whichNumberIsSmallerTickYourAnswer: 'Whichh number is smaller? Tick your answer.',
  whichNumberIsGreatest: 'Which number is the greatest?',
  whichNumberIsSmallest: 'Which number is the smallest?',
  whichNumberSentencesAreEqualTo: 'Which number sentences are equal to\n{0}?',
  whichNumbersHaveVarInPlace: 'Which numbers have {0} in the {1} place?',
  whichNumsAreSquare: 'Select the square numbers.',
  whichNumsAreSquarePDF: 'Circle the square numbers.',
  whichNumsAreCube: 'Select the cube numbers.',
  whichNumsAreMultsOfXAndY:
    'Which numbers are multiples of both {0:number|numberFormat} and {1:number|numberFormat}?',
  whichNumsGreaterThanNum: 'Which numbers are greater than {0}?',
  whichNumsLessThanNum: 'Which numbers are less than {0}?',
  whichNumsRoundToNumToNearestNum:
    'Which numbers round to {0:number|numberFormat} when rounded to the nearest {1:number|numberFormat}?',
  whichNumWordsShowsNumber: 'Which number is {0:number|numberFormat}?',
  whatPercentageAndFractionOfHundredSquareShaded:
    'What percentage and fraction of the hundred square is shaded?',
  whichOfTheseIsSquareNumber: 'Which of these is a square number?',
  whichPairsDoNotComplement100: 'Select the calculations that do NOT show complements to 100',
  whatPercentageOfTicketsAreStillForSale: 'What percentage of tickets are still for sale?',
  whichPlaceValueChartRepresentsXDivY: `Which place value chart represents {0:number|numberFormat} ${DIV} {1:number|numberFormat}?`,
  whichPlaceValueChartRepresentsXDivYPDF: `Which place value chart represents {0:number|numberFormat} ${DIV} {1:number|numberFormat}? Circle the correct answer.`,
  whichPlaceValueChartRepresentsXTimesY: `Which place value chart represents {0:number|numberFormat} ${MULT} {1:number|numberFormat}?`,
  whichPlaceValueChartRepresentsXTimesYPDF: `Which place value chart represents {0:number|numberFormat} ${MULT} {1:number|numberFormat}? Circle the correct answer.`,
  whichPictureRepresents: 'Which picture represents {0}?',
  whichPictureShowsXEqualGroupsOfY:
    'Select the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  whichPictureShowsXEqualGroupsOfYPDF:
    'Circle the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  whichPlaceValueChartRepresentsX: 'Which place value chart represents {0}?',
  whichShapeHasGreaterArea: 'Which shape has the greater area?',
  whichShapeHasSmallerArea: 'Which shape has the smaller area?',
  whichShapeHasPerimeterOfXCm: 'Which shape has a perimeter of {0:number|numberFormat} cm?',
  whichShapeIsRectilinear: 'Which shape is rectilinear?',
  whichXIsDecimalBetween: 'Which {0: string|lower} does the decimal lie between?',
  whoHasLongestOrShortestJourney:
    '{0}, {1}, {2} and {3} are going on holiday.\nSelect who has the {4:string|lower} journey.',
  whoHasLongestOrShortestJourneyPDF:
    '{0}, {1}, {2} and {3} are going on holiday.\nCircle who has the {4:string|lower} journey.',
  whoHasMoreChoicesOfCombinationsForWearingOneTShirtAndOneJacket:
    'Who has more choices of combinations for wearing one T-shirt and one jacket?',
  whoTravelsXOrdinalFurthest: 'Who travels the {0} furthest?',
  whoWasFasterSelectYourAnswer: 'Who was faster? Select your answer.',
  withoutCalculatingUseSymbolsToCompareTheCalculation: `Without calculating, use the ${LESS_THAN}, ${GREATER_THAN} and = to compare the calculation.`,
  workOut: 'Work out',
  workOutAnAlgebraicExpressionToDescribeTheSetOfCubes:
    'Work out an algebraic expression to describe the set of cubes.',
  workOutAnglesInTriangle: 'Work out the angles in the triangle.',
  workOutAreaAndPerimeterOfShape: 'Work out the area and perimeter of the shape.',
  workOutSizeOfAngleX: 'Work out the size of angle {0}.',
  workOutSizeOfUnknownAngle: 'Work out the size of the unknown angle.',
  workOutSizeOfUnknownAngles: 'Work out the size of the unknown angles.',
  workOutTheAreaOfTheRectangle: 'Work out the area of the rectangle.',
  workOutEquivalentFractionsAndPercentages: 'Work out the equivalent fractions and percentages.',
  workOutMissingAngleInQuadrilateral: 'Work out the missing angle in the quadrilateral.',
  workOutMissingAngles: 'Work out the missing angles.',
  workOutMissingDenominator: 'Work out the missing denominator.',
  workOutMissingNumerator: 'Work out the missing numerator.',
  workOutMissingOutputAndInput: 'Work out the missing output and input.',
  workOutMissingValue: 'Work out the missing value.',
  workOutMissingValueInBarModel: 'Work out the missing value in the bar model.',
  workOutMissingValueInThePartWholeModel: 'Work out the missing value in the part-whole model.',
  workOutSizeOfUnknownAngleInRhombus: 'Work out the size of the unknown angle in the rhombus.',
  workOutSizeOfUnknownAngleInTrapezium: 'Work out the size of the unknown angle in the trapezium.',
  workOutSizesOfAngles: 'Work out the sizes of the angles.',
  workOutTheAddition: 'Work out the addition.',
  workOutTheAdditions: 'Work out the additions.',
  workOutTheAdditionsGiveAnsAsDecimal: 'Work out the additions, give your answer as a decimal.',
  workOutTheAreaOfThisShape: 'Work out the area of this shape.',
  workOutTheCalculation: 'Work out the calculation.',
  workOutTheCalculationGiveAnswerInSimplestForm:
    'Work out the calculation and write the answer as a mixed number in its simplest form.',
  workOutTheCalculations: 'Work out the calculations.',
  workOutTheDifference: 'Work out the difference.',
  workOutTheDurationBetweenXandY: 'Work out the duration between {0} and {1}',
  workOutTheDivisionGiveAnswerInSimplestForm:
    'Work out the division.\nGive your answer in its simplest form.',
  workOutTheDivision: 'Work out the division.',
  workOutTheDivisions: 'Work out the divisions.',
  workOutTheEquivalentDecimals: 'Work out the equivalent decimals.',
  workOutTheEquivalentDecimalsAndPercentages: 'Work out the equivalent decimals and percentages.',
  workOutTheFractionOfTheAmount: 'Work out the fraction of the amount.',
  workOutTheMissingCoordinateOfTheIsoscelesTriangle:
    'Work out the missing coordinate of the isosceles triangle.',
  workOutTheMissingFractionPartWholeModel: 'Work out the missing fraction in the part-whole model.',
  workOutTheMissingDigit: 'Work out the missing digit.',
  workOutTheMissingDigits: 'Work out the missing digits.',
  workOutTheMissingFractionInTheBarModel: 'Work out the missing fraction in the bar model.',
  workOutTheMissingFractions: 'Work out the missing fractions.',
  workOutTheMissingLength: 'Work out the missing length.',
  workOutTheMissingLengths: 'Work out the missing lengths.',
  workOutTheMissingLengthOnShape: 'Work out the missing length on the shape.',
  workOutTheMissingLengthsOnShape: 'Work out the missing lengths on the shape.',
  workOutTheMissingLengthOnTheBarModel: 'Work out the missing length on the bar model.',
  workOutTheMissingNumerator: 'Work out the missing numerator.',
  workOutTheMissingNumber: 'Work out the missing number.',
  workOutTheMissingNumbers: 'Work out the missing numbers.',
  workOutTheMissingNumbersToExpressFractionAsDivision:
    'Work out the missing numbers to express the fractions as divisions.',
  workOutTheMissingTotal: 'Work out the missing total.',
  workOutTheMissingWhole: 'Work out the missing whole.',
  workOutTheMeanNumberOfCardsCollectedPerYear:
    'Work out the mean number of cards collected per year.',
  workOutTheMultiplication: 'Work out the multiplication.',
  workOutTheMultiplications: 'Work out the multiplications.',
  workOutTheMultSentences: 'Work out the multiplication sentences.',
  workOutTheNextFourNumbersInTheSequence: 'Work out the next four numbers in the sequence.',
  workOutTheNumOfObjectPreposition: 'Work out the number of {0:string|lower} {1:string|lower}',
  workOutTheOutput: 'Work out the output.',
  workOutPerimeterOfRectangle: 'Work out the perimeter of the rectangle.',
  workOutPerimeterOfRegularPolygon: 'Work out the perimeter of the regular polygon.',
  workOutPerimeterOfShape: 'Work out the perimeter of the shape.',
  workOutPerimeterOfSquare: 'Work out the perimeter of the square.',
  workOutPerimeterOfX: 'Work out the perimeter of the {0:string}.',
  workOutThePercentage: 'Work out the percentage.',
  workOutThePerimeterOfThisShape: 'Work out the perimeter of this shape.',
  workOutTheSizesOfTheUnknownAngle: 'Work out the size of the unknown angle.',
  workOutTheSizesOfTheUnknownAngles: 'Work out the sizes of the unknown angles.',
  workOutTheSubtraction: 'Work out the subtraction.',
  workOutTheSubtractions: 'Work out the subtractions.',
  workOutTheSquaresAndAreaOfTriangle:
    'Work out the number of squares and the area of the triangle.',
  workOutTheValueOf: 'Work out the value of {0}.',
  workOutTheValuesOfXAndY: 'Work out the values of {0} and {1}',
  workOutValueOfLinkingCube: 'Work out the value of the linking cube.',
  workoutValueOfCubes: 'Work out the value of the cubes.',
  workOutX: 'Work out {0}',
  workOutXAddY: `Work out {0} ${ADD} {1}`,
  workOutXAddYUsePVCToCircleAnswer: `Work out {0} ${ADD} {1}<br/>Use the place value chart to help. Circle your answer.`,
  workOutXAddYUsePVCToSelectAnswer: `Work out {0} ${ADD} {1}<br/>Use the place value chart to help. Select your answer.`,
  workOutXAndSelectYourAnswer: 'Work out {0} and select your answer.',
  workOutXDivideY: `Work out {0:number|numberFormat} ${DIV} {1:number|numberFormat}`,
  workOutXMultipliedByY: `Work out {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  workOutXSubtractY: `Work out {0} ${SUB} {1}`,
  writeCoordsOfPointsWithSameXYCoord:
    'Write the coordinates of the two points that have the same {0:string|lower}-coordinate.',
  writeDownCoordinatesOfTriangle: 'Write down the coordinates of the vertices of the triangle.',
  writeGreatestNumberFromPVC: 'Write the greatest number from the place value chart.',
  writeFractionsInSimplestFormUseFractionWallToHelp:
    'Write each fraction in its simplest form.<br/>You can use the fraction wall to help you.',
  writeNumGreaterThanNum1AndLessThanNum2:
    'Write a number that is greater than {0} and less than {1}',
  writeNumInNumerals: 'Write the number in numerals.',
  writeNumWordsInNum: 'Write {0} in numerals.',
  writeRomanNumeralAsANumber: 'Write the Roman numeral as a number.',
  writeSmallestNumberFromPVC: 'Write the smallest number from the place value chart.',
  writeThe24HourClockTime: 'Write the 24-hour clock time.',
  writeTheCoordinatesOfThePoint: 'Write the coordinates of the point.',
  writeTheCoordinatesOfThePoints: 'Write the coordinates of the points.',
  writeTheCoordinatesOfThePointAfterTranslationDy:
    'Write the coordinates of the point after it is translated {0: number|numberFormat} {1: string|lower}.',
  writeTheCoordinatesOfThePointAfterTranslationDx:
    'Write the coordinates of the point after it is translated {0: number|numberFormat} to the {1: string|lower}.',
  writeTheCoordinatesOfThePointAfterTranslationsDxDy:
    'Write the coordinates of the point after it is translated {0: number|numberFormat} to the {1: string|lower} and {2: number|numberFormat} {3: string|lower}.',
  writeTheTimeAsA24HourClockTime: 'Write the time as a 24-hour clock time.',
  writeTwoMultAndTwoDivFactsShownByArray:
    'Write two multiplication and two division facts shown by the array.',
  writeTwoMultSentencesForArray: 'Write two multiplication sentences for the array.',
  xAndYAreBothNumsLessThanNumCompleteTableToShowPossibleValues:
    '{0} and {1} are both numbers less than {2:number|numberFormat}<br/>Complete the table to show possible values for <g>{0} and {1}</g>.',
  xAndYHaveKnownEachOtherForXDaysForHowManyWeeksAndDaysHaveTheyKnownEachOther:
    '{0} and {1} have known each other for {2:number|numberFormat} days.<br/>For how many weeks and days have they known each other?',
  xAndYRanARaceXRanTheRaceInAMinAndBSecsYRanTheRaceInCSecs:
    '{0} and {1} ran a race.<br/>{0} ran the race in {2:number|numberFormat} minutes and {3:number|numberFormat} seconds.<br/>{1} ran the race in {4:number|numberFormat} seconds.',
  xEatsYBagOfFoodADayWhatFractionOfBagDoesEatInZdays: `A {0:string|lower} eats {1} of a bag of {0:string|lower} food a day.<br/>What fraction of the bag does the {0:string|lower} eat in {2} days?`,
  xChildrenChoseActivityHowManyChoseYZ:
    '{0:number|numberFormat} children chose an after-school activity.<br/>How many children chose {1:string|lower} or {2:string|lower}?',
  xChildrenChoseActivitiesDragCardsToShowHowManyY:
    '{0: number|numberFormat} children chose an after-school activity.<br/>Drag the cards to show how many children chose {1: string|lower}.',
  xChildrenChoseActivitiesUseCardsToShowHowManyY:
    '{0: number|numberFormat} children chose an after-school activity.<br/>Use the cards to show how many children chose {1: string|lower}.',
  xCostsYCompleteDoubleNumberLine:
    'One {0: string|lower} costs {1}.<br/>Drag the cards to complete the double number line.',
  xCostsYCompleteDoubleNumberLinePDF:
    'One {0: string|lower} costs {1}.<br/>Use the cards to complete the double number line.',
  xCountersArePutIntoGroupsOfYCompleteDivision:
    '{0:number|numberFormat} counters are put into groups of {1:number|numberFormat}<br/>Complete the division.',
  xCountersArePutIntoGroupsOfYCompleteSentence:
    '{0:number|numberFormat} counters are put into groups of {1:number|numberFormat}<br/>Complete the sentence.',
  xCountersAreSharedEquallyIntoYGroupsCompleteDivision:
    '{0:number|numberFormat} counters are shared equally into {1:number|numberFormat} groups.<br/>Complete the sentence.',
  xCountersAreSharedEquallyIntoYGroupsCompleteSentence:
    '{0:number|numberFormat} counters are shared equally into {1:number|numberFormat} groups.<br/>Complete the sentence.',
  xDividedByYIsEqualToZ:
    '{0:number|numberFormat} divided by {1:number|numberFormat} is equal to {2:number|numberFormat}',
  xCostsYLessThanZ: '{0} costs {1}p less than {2: string|lower}.',
  xCostsYMoreThanZ: '{0} costs {1}p more than {2: string|lower}.',
  xDrawsACompoundShapeMadeFromOtherThreeDShapesHowManyFacesAreThereOnTheShape:
    "{0} draws a compound shape made from other 3-D shapes.<br/>How many faces are there on {0}'s shape?",
  xDrawsARegularYEachSideIsZCmWhatIsThePerimeter:
    "{0} draws a regular {1}.<br/>Each side is {2} cm.<br/>What is the perimeter of {0}'s {1}?",
  xHasCircledAnglesInIsoscelesCircleTrianglesThatAreCorrect:
    '{0} has circled the angles that they think are equal in each isosceles triangle.<br/>Circle the triangles that {0} has correctly circled.',
  xHasCircledAnglesInIsoscelesSelectTrianglesThatAreCorrect:
    '{0} has circled the angles that they think are equal in each isosceles triangle.<br/>Select the triangles that {0} has correctly circled.',
  xHasDrawnARATriangleWhatIsTheSizeOfMissingAngle:
    '{0} has drawn a right-angled triangle.<br/>What is the size of the missing angle?',
  xHasDrawnSquareWithPerimeterYcmMeasureLengthOfOneSideToFindOutIfTrueOrFalse:
    '{0} has drawn a square with the perimeter of {1:number|numberFormat} cm.<br/>Measure the length of one side and select whether the statement is true or false.',
  xHasDrawnSquareWithPerimeterYcmMeasureLengthOfOneSideToFindOutIfTrueOrFalsePDF:
    '{0} has drawn a square with the perimeter of {1:number|numberFormat} cm.<br/>Measure the length of one side and circle whether the statement is true or false.',
  xHasDrawnThreeDShapeOnIsometricPaperCircleShapeHasXDrawn:
    '{0} has drawn a 3-D shape on isometric paper.<br/>Circle the shape that has {0} drawn.',
  xHasDrawnThreeDShapeOnIsometricPaperSelectShapeHasXDrawn:
    '{0} has drawn a 3-D shape on isometric paper.<br/>Select the shape that has {0} drawn.',
  xHasDrawnThreeDShapeOnIsometricPaperWhatShapeHasXDrawn:
    '{0} has drawn a 3-D shape on isometric paper.<br/>What shape has {0} drawn?',
  xHasMadeSomeShapes: '{0} has made some shapes using lolly sticks.',
  xIsMeasuringATriangleWhatIsLengthOfSide:
    '{0} is measuring the side of a triangle.<br/>What is the length of the side?',
  xIsShownOnGattegnoChart: '{0} is shown on the Gattegno chart.',
  XIsYkgThanZ: '{0} is {1} kg {2:string|lower} than {3}.<br/>Complete the table.',
  xIsYLessThanZ: '{0} is {1} less than {2}',
  xIsYMoreThanZ: '{0} is {1} more than {2}',
  xIsYTimesZ: '{0} is {1} times the size of {2}.',
  xIsYOfZ: '{0} is a {1} of {2}',
  xJoinsSeveralOfThemToMakeThisCompoundShape:
    '{0} joins several of them to make this compound shape.',
  xTravelsYkmEachDayForZdays: '{0} travels for {1}km each day for {2} days.',
  xColorMarblesCircleHowManyInTotal:
    'There are {0: number|numberFormat} {1: string|lower} marbles in a jar. Circle how many marbles there are in total.',
  xColorMarblesSelectHowManyInTotal:
    'There are {0: number|numberFormat} {1: string|lower} marbles in a jar. Select how many marbles there are in total.',
  xNumberOfYNumberOfZNumberOfA: '{0} = number of {1}, {2} = number of {3}.',
  xObjectsCostsYHowMuchDoZObjectsCostCompleteDoubleNumberLine:
    '{0} {1: string|lower} costs {2}<br/>How much do {3} {1: string|lower} cost?<br/>Drag the cards to complete the double number line.',
  xNeedsToGetToYByZCircleTheBusXTakesFromY:
    '{0} needs to get to {1} by {2}<br/>Circle the bus {0} needs to take from {1}?',
  xNeedsToGetToYByZSelectTheBusXTakesFromY:
    '{0} needs to get to {1} by {2}<br/>Select the latest bus {0} needs to take from {1}.',
  xPeopleAreAttendingAnEventEachTableCanSeatYPeople:
    '{0:number|numberFormat} people are attending an event.<br/>Each table can seat {1:number|numberFormat} people.',
  xPeoplePickedIceCreamSelectHowManyChoseY:
    '{0:number|numberFormat} people chose their favourite ice cream flavour.<br/>Select the number of people who chose {1: string|lower}.',
  xPeoplePickedIceCreamCircleHowManyChoseY:
    '{0:number|numberFormat} people chose their favourite ice cream flavour.<br/>Circle the number of people who chose {1: string|lower}.',
  xPeoplePickedIceCreamSelectDifferenceBetweenYZ:
    '{0: number|numberFormat} people chose their favourite ice cream flavour. Select how many more people chose {1: string|lower} than {2: string|lower}.',
  xPeoplePickedIceCreamCircleDifferenceBetweenYZ:
    '{0: number|numberFormat} people chose their favourite ice cream flavour. Circle how many more people chose {1: string|lower} than {2: string|lower}.',
  xPerHourPlusYFixedCharge: '£{0} per hour<br/>Plus £{1} fixed charge',
  xLitresPetrolCostsYHowMuchZCost:
    '{0} litres of petrol cost {1}<br/>How much do {2} litres of petrol cost?',
  youCanShadeTheBarModelsToHelp: 'You can shade the bar models to help.',
  youCanUseTheBarModelToHelp: 'You can use the bar model to help.'
};
