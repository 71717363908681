import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { all, create, number } from 'mathjs';
import { ADD, SUB } from '../../../../constants';
import { compareFloats } from '../../../../utils/math';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aB2',
  description: 'aB2',
  keywords: ['Addition', 'Number line', 'Tenths', 'Hundredths'],
  schema: z.object({
    startingNumber: z.number().int().min(2).max(4),
    jumpA: z.number().int().min(1).max(4),
    jumpB: z.number().min(0.11).max(0.99).step(0.01)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(2, 4);
    const jumpA = randomIntegerInclusive(1, 4);

    const jumpB =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 100;

    return { startingNumber, jumpA, jumpB };
  },
  Component: props => {
    const {
      question: { startingNumber, jumpA, jumpB },
      translate
    } = props;

    const number4 = number(math.evaluate(`${jumpA} + ${jumpB}`));

    const answer = number(math.evaluate(`${startingNumber} + ${number4}`));

    // Minimum spacing for ticks/jump arrows doesn't account for final jump/tick so have had to add custom spacing in
    const middleTick = jumpA > 2 && jumpB < 0.5 ? answer - 1 : startingNumber + jumpA;

    const tickArray = [
      { label: startingNumber.toLocaleString(), position: startingNumber },
      { label: '', position: middleTick },
      {
        label: '<ans/>',
        position: answer
      }
    ];

    const jumpArrowArray = [
      {
        start: startingNumber,
        end: middleTick,
        label: `${ADD} ${jumpA.toLocaleString()}`
      },
      { start: middleTick, end: answer, label: `${ADD} ${jumpB.toLocaleString()}` }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={answer}
        title={translate.instructions.workOutXAddY(
          startingNumber.toLocaleString(),
          number4.toLocaleString()
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aB3',
  description: 'aB3',
  keywords: ['Decimals', 'Addition', 'Tenths', 'Partition'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(4),
    number3: z
      .number()
      .min(0.1)
      .max(0.9)
      .refine(val => val !== 0.5, 'number3 must not be 0.5')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);
    const number2 = randomIntegerInclusive(1, 4);
    const number3 =
      randomIntegerInclusive(1, 9, {
        constraint: x => x !== 5
      }) / 10;

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} + ${number3}`));
    const number5 = number2 + 1;
    const number6 = number(math.evaluate(`1 - ${number3}`));

    const options = shuffle(
      [
        `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${ADD} ${number3.toLocaleString()}`,
        `${number1.toLocaleString()} ${ADD} ${number5.toLocaleString()} ${ADD} ${number3.toLocaleString()}`,
        `${number1.toLocaleString()} ${ADD} ${number5.toLocaleString()} ${SUB} ${number6.toLocaleString()}`,
        `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${ADD} ${number6.toLocaleString()}`
      ],
      { random: seededRandom(props.question) }
    );

    const correct = [
      `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${ADD} ${number3.toLocaleString()}`,
      `${number1.toLocaleString()} ${ADD} ${number5.toLocaleString()} ${SUB} ${number6.toLocaleString()}`
    ];

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectStrategiesThatGiveCorrectAnswerToXAddY(
          number1,
          number4
        )}
        testCorrect={correct}
        multiSelect
        items={options.map(opt => ({
          value: opt,
          component: opt
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aB4',
  description: 'aB4',
  keywords: ['Addition', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumbers1: z.number().min(1).max(29.9),
    questionNumbers2: z.number().min(1.1).max(28),
    questionToShow: z.enum(['a', 'b', 'c'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    const number2 =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number3 =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number4 = randomIntegerInclusive(10, 28);

    const number5 = randomIntegerInclusive(101, 299) / 10;

    const number6 = randomIntegerInclusive(3, 9);

    const questionToShow = getRandomFromArray(['a', 'b', 'c'] as const);

    const [questionNumbers1, questionNumbers2] =
      questionToShow === 'a'
        ? [number1, number2]
        : questionToShow === 'b'
        ? [number3, number4]
        : [number5, number6];

    return {
      questionNumbers1,
      questionNumbers2,
      questionToShow
    };
  },
  Component: props => {
    const {
      question: { questionNumbers1, questionNumbers2, questionToShow },
      translate
    } = props;

    const [sentence, answer] = [
      questionToShow === 'c'
        ? `${questionNumbers1.toLocaleString()} ${SUB} ${questionNumbers2.toLocaleString()} = <ans/>`
        : `${questionNumbers1.toLocaleString()} ${ADD} ${questionNumbers2.toLocaleString()} = <ans/>`,
      questionToShow === 'c'
        ? number(math.evaluate(`${questionNumbers1} - ${questionNumbers2}`))
        : number(math.evaluate(`${questionNumbers1} + ${questionNumbers2}`))
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.mentallyWorkOutCalc()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aB5',
  description: 'aB5',
  keywords: ['Addition', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumbers1: z.number().min(1).max(29.9),
    questionNumbers2: z.number().min(1.01).max(28),
    questionToShow: z.enum(['a', 'b', 'c'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    const number2 =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const number3 =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const number4 = randomIntegerInclusive(10, 28);

    const number5 = randomIntegerInclusive(101, 299) / 10;

    const number6 = randomIntegerInclusive(3, 9);

    const questionToShow = getRandomFromArray(['a', 'b', 'c'] as const);

    const [questionNumbers1, questionNumbers2] =
      questionToShow === 'a'
        ? [number1, number2]
        : questionToShow === 'b'
        ? [number3, number4]
        : [number5, number6];

    return {
      questionNumbers1,
      questionNumbers2,
      questionToShow
    };
  },
  Component: props => {
    const {
      question: { questionNumbers1, questionNumbers2, questionToShow },
      translate
    } = props;

    const [sentence, answer] =
      questionToShow === 'c'
        ? [
            `${questionNumbers1.toLocaleString()} ${ADD} <ans/> = ${number(
              math.evaluate(`${questionNumbers1} + ${questionNumbers2}`)
            ).toLocaleString()}`,
            questionNumbers2
          ]
        : [
            `${questionNumbers1.toLocaleString()} ${ADD} ${questionNumbers2.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${questionNumbers1} + ${questionNumbers2}`))
          ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.mentallyWorkOutCalc()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aB6',
  description: 'aB6',
  keywords: ['Subtraction', 'Number line', 'Tenths', 'Hundredths'],
  schema: z.object({
    startingNumber: z.number().int().min(5).max(9),
    jumpA: z.number().int().min(1).max(4),
    jumpB: z.number().min(0.1).max(0.9).step(0.1)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(5, 9);
    const jumpA = randomIntegerInclusive(1, 4);
    const jumpB = randomIntegerInclusive(1, 9) / 10;

    return { startingNumber, jumpA, jumpB };
  },
  Component: props => {
    const {
      question: { startingNumber, jumpA, jumpB },
      translate
    } = props;

    const number4 = number(math.evaluate(`${jumpA} + ${jumpB}`));

    const answer = number(math.evaluate(`${startingNumber} - ${number4}`));

    // Minimum spacing for ticks/jump arrows doesn't account for final jump/tick so have had to add custom spacing in
    const middleTick = jumpA <= 2 ? startingNumber - jumpA : answer + 1;

    const tickArray = [
      { label: startingNumber.toLocaleString(), position: startingNumber },
      { label: '', position: middleTick },
      {
        label: '<ans/>',
        position: answer
      }
    ];

    const jumpArrowArray = [
      {
        start: startingNumber,
        end: middleTick,
        label: `${SUB} ${jumpA.toLocaleString()}`
      },
      { start: middleTick, end: answer, label: `${SUB} ${jumpB.toLocaleString()}` }
    ];

    return (
      <QF25JumpOnANumberLine
        start={answer}
        end={startingNumber}
        title={translate.instructions.workOutXSubtractY(
          startingNumber.toLocaleString(),
          number4.toLocaleString()
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        extraSymbol="decimalPoint"
        subtraction
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aB7',
  description: 'aB7',
  keywords: ['Subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(9),
      number2: z.number().min(0.1).max(0.8).multipleOf(0.1),
      number3: z.number().int().min(3).max(9),
      number4: z.number().min(1.1).max(7.9).multipleOf(0.1),
      number5: numberEnum([10, 11, 12, 13, 14, 20, 21, 22, 23, 24]),
      number6: z.number().min(5.1).max(9.9).multipleOf(0.1)
    })
    .refine(val => val.number4 < val.number3, 'number4 must be less than number3'),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    const number2 = randomIntegerInclusive(1, 8) / 10;

    const number3 = randomIntegerInclusive(3, 9);

    const number4 =
      randomIntegerInclusive(11, (number3 - 1) * 10 - 1, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number5 = getRandomFromArray([10, 11, 12, 13, 14, 20, 21, 22, 23, 24] as const);

    const number6 =
      randomIntegerInclusive(51, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
      math.evaluate(`${number1} - ${number2}`)
    ];

    const [sentence2, answer2] = [
      `${number3.toLocaleString()} ${SUB} ${number4.toLocaleString()} = <ans/>`,
      math.evaluate(`${number3} - ${number4}`)
    ];

    const [sentence3, answer3] = [
      `${number5.toLocaleString()} ${SUB} ${number6.toLocaleString()} = <ans/>`,
      math.evaluate(`${number5} - ${number6}`)
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.mentallyWorkOutSubtractions()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) &&
          compareFloats(answer[1][0], answer2) &&
          compareFloats(answer[2][0], answer3)
        }
        sentences={[sentence1, sentence2, sentence3]}
        inputMaxCharacters={6}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString()],
            [answer3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EfficientStrategiesForAddingAndSubtractingDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
