import { useEffect } from 'react';

import Animated, {
  Easing,
  cancelAnimation,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming
} from 'react-native-reanimated';
import { AssetSvg } from '../../assets/svg';

export default function Spinner({ height }: { height: number }) {
  const rotation = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotateZ: `${rotation.value}deg`
        }
      ]
    }),
    [rotation]
  );

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 1000,
        easing: Easing.linear
      }),
      -1
    );
    return () => cancelAnimation(rotation);
  }, [rotation]);

  return (
    <Animated.View style={animatedStyles}>
      <AssetSvg name={'Spinner'} height={height} />
    </Animated.View>
  );
}
