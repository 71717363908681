import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../../../../theme/colors';
import TextStructure from '../../../molecules/TextStructure';
import { Dimens } from '../../../../theme/scaling';
import { parseMarkup } from '../../../../markup';
import { Line, Polygon, Svg } from 'react-native-svg';
import { DisplayMode } from '../../../../contexts/displayMode';

type Props = {
  /**
   * Input box for answer box on in the sequence, must be specified to know what input
   * is available to the user and how to handle the answer
   */
  inputBox: (
    ansIndex: number,
    rowIndex: number,
    boxWidth: number,
    boxHeight: number
  ) => JSX.Element;
  /**
   * An array of strings to hold the values that go in the sequence
   * This uses the full markup language with <ans/> and other tags used elsewhere.
   */
  boxValues: string[][];
  arrowValues: string[][];
  /** Usable dimensions for the question */
  dimens: Dimens;
  inputMaxCharacters?: number;
};

/**
 * This component renders a Arrow Answer Boxes manipulative.
 * They display values and answer boxes with arrows between with a label showing the difference
 * These are laid out horizontally across the screen.
 */
export function ArrowAnswerBoxes({
  inputBox,
  boxValues,
  arrowValues,
  dimens: { width, height },
  inputMaxCharacters
}: Props) {
  const displayMode = useContext(DisplayMode);
  const boxHeight = displayMode === 'digital' ? 96 : 150;
  const boxValuesParsed = boxValues.map(row => row.map(box => parseMarkup(box)));

  const fontSize = displayMode === 'digital' ? 40 : 50;
  const labelFontSize = displayMode === 'digital' ? 22 : 30;
  const defaultWidth = displayMode === 'digital' ? 96 : 200;
  const characterWidth = 0.7 * fontSize;
  const answerBoxWidth = inputMaxCharacters
    ? Math.max(defaultWidth, characterWidth * inputMaxCharacters)
    : 96;

  const styles = useMemo(
    () => getStyles(width, height, fontSize, displayMode),
    [width, height, fontSize, displayMode]
  );
  const arrowWidth = displayMode === 'digital' ? 100 : 200;

  const arrow = (sentence: string) => {
    return (
      <View style={{ width: arrowWidth, alignItems: 'center' }}>
        <View style={styles.arrowContainer}>
          <TextStructure textStyle={{ fontSize: labelFontSize }} sentence={sentence} />
        </View>
        <Svg height={20} width={arrowWidth}>
          <Line
            y1="10"
            x2={arrowWidth - 10}
            y2="10"
            stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
            strokeWidth={5}
          />
          <Polygon
            points={`${arrowWidth - 10},0 ${arrowWidth - 10},20 ${arrowWidth},10`}
            fill={displayMode !== 'digital' ? colors.black : colors.burntSienna}
            stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
          />
        </Svg>
      </View>
    );
  };

  // Numbers
  const numberComponents = boxValuesParsed.map((row, rowIndex) => {
    let ansIndex = -1;
    return (
      <View key={`row_${rowIndex}`} style={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}>
        {row.map((number, index) => {
          const isLastCol = index === boxValuesParsed[rowIndex].length - 1;
          if (number.numberOfAns > 0) {
            ansIndex += 1;
            return (
              <View key={ansIndex} style={styles.stepContainer}>
                {inputBox(ansIndex, rowIndex, answerBoxWidth, boxHeight)}
                {!isLastCol && arrow(arrowValues[rowIndex][index])}
              </View>
            );
          } else {
            return (
              <View key={'value' + index}>
                {number.tokens[0].type === 'text' && (
                  <View style={styles.stepContainer}>
                    <View style={{ minWidth: 103, alignItems: 'flex-end' }}>
                      <TextStructure
                        textStyle={styles.textStyle}
                        sentence={number.tokens[0].value}
                      />
                    </View>
                    {!isLastCol && arrow(arrowValues[rowIndex][index])}
                  </View>
                )}
              </View>
            );
          }
        })}
      </View>
    );
  });

  return <View style={styles.container}>{numberComponents}</View>;
}

const getStyles = (
  width: number,
  height: number,
  fontSize: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      gap: 20
    },
    stepContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20
    },
    textStyle: {
      lineHeight: 60,
      fontSize: fontSize,
      fontWeight: '400'
    },
    arrowContainer: { position: 'absolute', top: displayMode !== 'digital' ? -60 : -50 }
  });
