import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { arraysHaveSameContentsUnordered } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { DIV, MULT } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getGenderFromKs1Name, getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjg',
  description: 'bjg',
  keywords: ['Array', 'Multiply', 'Divide'],
  schema: z.object({
    rows: z.number().int().min(1).max(5),
    color: z.enum(
      Object.values(ArrayOfObjectsColors) as [
        (typeof ArrayOfObjectsColors)[keyof typeof ArrayOfObjectsColors]
      ]
    )
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 5);
    const colorLookup = [...Object.values(ArrayOfObjectsColors)] as [
      (typeof ArrayOfObjectsColors)[keyof typeof ArrayOfObjectsColors]
    ];
    const color = getRandomFromArray(colorLookup);

    return { rows, color };
  },
  Component: props => {
    const {
      question: { rows, color },
      translate
    } = props;

    const columns = 10;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/> = <ans/>`, `<ans/> ${DIV} <ans/> = <ans/>`]}
        title={translate.ks1Instructions.completeTheCalculationsToMatchArray()}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            [userAnswer[0][0], userAnswer[0][1]],
            [rows.toString(), columns.toString()]
          ) &&
          userAnswer[0][2] === (columns * rows).toString() &&
          arraysHaveSameContentsUnordered(
            [userAnswer[1][1], userAnswer[1][2]],
            [rows.toString(), columns.toString()]
          ) &&
          userAnswer[1][0] === (columns * rows).toString()
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [rows.toLocaleString(), columns.toLocaleString(), (rows * columns).toLocaleString()],
            [(rows * columns).toLocaleString(), columns.toLocaleString(), rows.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(
              (dimens.width - 20 * columns) / columns,
              dimens.height / (rows + 1)
            )}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bjh',
  description: 'bjh',
  keywords: ['10 times-table', 'Divide'],
  schema: z.object({
    dividend: z.number().min(10).max(100).step(10),
    order: z.enum(['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'])
  }),
  simpleGenerator: () => {
    const dividend = randomIntegerInclusiveStep(10, 100, 10);

    const order = getRandomFromArrayWithWeights(
      ['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'] as const,
      [4, 2, 1, 1]
    );

    return {
      dividend,
      order
    };
  },
  Component: ({ question: { dividend, order }, translate }) => {
    const [sentence, answer] = ((): [string, number] => {
      switch (order) {
        case 'quotientLast':
          return [
            `${dividend.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
            dividend / 10
          ];
        case 'quotientFirst':
          return [
            `<ans/> = ${dividend.toLocaleString()} ${DIV} ${(10).toLocaleString()}`,
            dividend / 10
          ];
        case 'dividendFirst':
          return [
            `<ans/> ${DIV} ${(10).toLocaleString()} = ${(dividend / 10).toLocaleString()}`,
            dividend
          ];
        case 'dividendLast':
          return [
            `<ans/> ${DIV} ${(10).toLocaleString()} = ${(dividend / 10).toLocaleString()}`,
            dividend
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheDivision()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bji',
  description: 'bji',
  keywords: ['Division', 'Grouping', 'Sharing', '10 times-table'],
  schema: z.object({
    name: ks1NameSchema,
    total: z.number().int().min(10).max(100).step(10),
    item: z.enum(['Marble', 'Sweet', 'Pencil', 'pence', 'pound'])
  }),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const isMoney = getRandomBoolean();
    const total = randomIntegerInclusiveStep(isMoney ? 20 : 10, 100, 10);

    const item = isMoney
      ? total === 100
        ? getRandomFromArray(['pence', 'pound'] as const)
        : 'pence'
      : getRandomFromArray(['Marble', 'Sweet', 'Pencil'] as const);

    return {
      name,
      total,
      item
    };
  },
  Component: ({ question, translate }) => {
    const { name, total, item } = question;

    const groups = 10;
    const groupSizes = total / 10;
    const pronoun =
      getGenderFromKs1Name(name) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const translations = {
      Sweet: translate.enums.Base10RepresentationVariant.Sweets(),
      Pencil: translate.objects.Pencils(),
      Marble: translate.objects.Marbles(2)
    };

    const title = (() => {
      switch (item) {
        case 'Sweet':
          return translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYBagsHowManyInEachBag(
            {
              name,
              object: translations[item],
              containerCount: groups,
              total,
              pronoun
            }
          );
        case 'Marble':
          return translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYBoxesHowManyInEachBoxes(
            {
              name,
              object: translations[item],
              containerCount: groups,
              total,
              pronoun
            }
          );
        case 'Pencil':
          return translate.ks1Instructions.nameHasXObjectsPronounSharesBetweenYPotsHowManyInEachPot(
            {
              name,
              object: translations[item],
              containerCount: groups,
              total,
              pronoun
            }
          );
        case 'pence':
          return translate.ks1Instructions.nameHasSomeTenPCoinsPronounXPenceInTotalHowManyCoinsDoesNameHave(
            { name, pronoun, pence: total }
          );
        case 'pound':
          return translate.ks1Instructions.nameHasSomeTenPCoinsPronounXPoundInTotalHowManyCoinsDoesNameHave(
            { name, pronoun, pound: total / 100 }
          );
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={title}
        testCorrect={[groupSizes.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
