import Spinner from 'common/src/components/molecules/Spinner';
import { RootStackProps } from '../navigation/types';
import { ImageBackground, View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { useCallback, useEffect } from 'react';
import { getPlayer } from 'common/src/utils/Audio';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { showErrorToast } from '../components/Toast';
import BaseScreen from 'common/src/components/screens/BaseScreen';
import { useI18nContext } from '../i18n/i18n-react';
import { createAssignedQuizSession, createNewQuizSession } from '../network/quizSession';
import useBreakpoints from '../hooks/useBreakpoints';
import { StatusBar } from 'expo-status-bar';
import { popToTop } from '../navigation/actions';

/** Screen to show when retrying a quiz, to make sure we create a new quiz session. */
export default function QuizLoadingScreen({ navigation }: RootStackProps<'QuizLoading'>) {
  const translate = useI18nContext().LL;
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);
  const quizSession = useQuizSessionStore(state => state.quizSession);

  const { resize } = useBreakpoints();

  // Just throw if the quizSession isn't defined. This should be impossible since we only get to this screen from
  // retrying a Quiz
  if (quizSession === undefined) {
    throw new Error('Quiz Session is undefined, this should not be possible');
  }
  // Get quiz details from previous quizSession
  const { retryInfo } = quizSession;

  /** Callback for when something has gone wrong */
  const handleError = useCallback(
    (title: string, message: string) => {
      showErrorToast({ title, message, extraDuration: 1000, resize: resize });
      navigation.dispatch(popToTop());
    },
    [navigation, resize]
  );

  // Load quiz and navigate to Quiz Screen
  useEffect(() => {
    // Self-calling function, to get around the fact that useEffects don't accept async functions, but they're happy
    // with a function that just calls an async function.
    (async () => {
      // Show this screen for minimum 3s. Start that timer now.
      const minWait = new Promise(r => setTimeout(r, 3000));

      if (retryInfo.type === 'legacy') {
        // Can skip downloading a new quiz session. Just keep the current one.
        await minWait;
        navigation.navigate('Quiz');
        return;
      }

      // Start loading the quiz
      const quizSessionResult =
        retryInfo.type === 'assigned'
          ? await createAssignedQuizSession(retryInfo)
          : await createNewQuizSession(retryInfo);

      if (typeof quizSessionResult === 'string') {
        // Creating quiz session failed - show a toast and navigate home
        switch (quizSessionResult) {
          case 'invalid response':
          case 'unknown error':
          case 'http error':
          case 'logged out':
            handleError(
              translate.errorModals.somethingWentWrong(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'not found':
            handleError(
              translate.errorModals.quizNotFound(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'quiz locked':
            handleError(
              translate.errorModals.thisQuizIsLocked(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
        }
      } else {
        // Create the audio handler with the correct settings
        getPlayer(quizSessionResult.quizSounds);

        // Go to the Quiz Screen, once the minimum time has passed
        await minWait;
        setQuizSession(quizSessionResult);
        navigation.navigate('Quiz');
      }
    })();

    // Intentionally empty dependency array so that this is run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StatusBar style="light" />
      <ImageBackground
        source={require('pupil-app/assets/images/SpaceBackground.png')}
        resizeMode="cover"
        fadeDuration={0}
        style={{ flex: 1, justifyContent: 'center' }}
      >
        <BaseScreen>
          <View key="loading" style={{ gap: 64, alignItems: 'center' }}>
            <View style={{ gap: 24 }}>
              <Spinner height={156} />
              <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
                {translate.loadingEllipsis()}
              </Text>
            </View>
            <View style={{ gap: 5, alignItems: 'center' }}>
              <Text
                variant="WRN700"
                style={{
                  color: 'white',
                  fontSize: 32,
                  lineHeight: 48,
                  textAlign: 'center'
                }}
              >
                {quizSession?.name ?? ''}
              </Text>
              <Text variant="WRN400" style={{ color: 'white', fontSize: 32, lineHeight: 48 }}>
                {translate.misc.numberOfQuestions(quizSession?.questions.length ?? 0)}
              </Text>
            </View>
          </View>
        </BaseScreen>
      </ImageBackground>
    </>
  );
}
