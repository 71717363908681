import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import RowOfImages from '../../../../components/molecules/RowOfImages';
import { filledArray, range } from '../../../../utils/collections';
import { getLollySticksSvgName, getObjectSvgName } from '../../../../utils/objectsImages';
import { randomIntegerInclusive, randomUniqueIntegersInclusive } from '../../../../utils/random';
import { DIV } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { all, create, number } from 'mathjs';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKQ',
  description: 'aKQ',
  keywords: ['Multiplication', 'Division', 'Pentagons', 'Remainders'],
  schema: z.object({
    var1: z.number().int().min(1).max(4),
    var2: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 4);
    const var2 = randomIntegerInclusive(1, 4);

    return { var1, var2 };
  },
  Component: props => {
    const {
      question: { var1, var2 },
      translate
    } = props;

    const total = var1 * 5 + var2;

    const sentences = [
      {
        sentence: `${translate.answerSentences.thereAreAnsXAltogether(
          translate.objects.LollySticks()
        )}`,
        answer: [total.toString()]
      },
      {
        sentence: `${translate.answerSentences.thereAreAnsGroupsOf('5')}`,
        answer: [var1.toString()]
      },
      {
        sentence: `${translate.answerSentences.thereAreAnsXRemaining(
          translate.objects.LollySticks()
        )}`,
        answer: [var2.toString()]
      }
    ];

    const images = [
      ...filledArray(getLollySticksSvgName('pentagon', 5), var1),
      getLollySticksSvgName('pentagon', var2)
    ];

    return (
      <QF1ContentAndSentences
        sentences={sentences.map(sentence => sentence.sentence)}
        title={translate.instructions.completeSentences()}
        testCorrect={sentences.map(sentence => sentence.answer)}
        Content={({ dimens }) => (
          <RowOfImages
            style={{ justifyContent: 'center', alignItems: 'flex-end', gap: 16 }}
            images={images}
            containerStyle={dimens}
          />
        )}
        pdfSentenceStyle={{ marginTop: 32 }}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aKR',
  description: 'aKR',
  keywords: ['Multiplication', 'Division', 'Remainders'],
  schema: z.object({
    number1: z.number().int().min(1).max(4),
    number2: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 4);
    const number2 = randomIntegerInclusive(1, 5);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const images = [
      ...filledArray(getObjectSvgName('Egg', 6), number1),
      getObjectSvgName('Egg', number2)
    ];

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsXAltogether('eggs'),
          translate.answerSentences.thereAreAnsGroupsOf(6),
          translate.answerSentences.thereAreAnsXRemaining('eggs')
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [(number1 * 6 + number2).toString()],
          [number1.toString()],
          [number2.toString()]
        ]}
        Content={() => {
          return (
            <RowOfImages containerStyle={{ margin: 20 }} style={{ gap: 20 }} images={images} />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKS',
  description: 'aKS',
  keywords: ['Remainder', 'Division'],
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    number2: z.number().int().min(1).max(10),
    number4: z.number().int().min(1).max(11)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);
    const number2 = randomIntegerInclusive(1, 10);
    const number4 = randomIntegerInclusive(1, number1 - 1);
    return { number1, number2, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4 },
      translate
    } = props;

    const number3 = number1 * number2;
    const number5 = number3 + number4;

    const sentences = [
      `${number3.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
      `${number5.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>r <ans/>`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={[[number2.toString()], [number2.toString(), number4.toString()]]}
        sentences={sentences}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question4 = newQuestionContent({
  uid: 'aKT',
  description: 'aKT',
  keywords: ['Division', 'Group', 'Remainder'],
  schema: z.object({
    numbers1: z.array(z.number().int().min(2).max(10)).length(4),
    numbers2: z.array(z.number().int().min(1).max(10)).length(2),
    numbers4: z.array(z.number().int().min(1).max(9)).length(2)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const numbers1 = randomUniqueIntegersInclusive(2, 10, 4);
    const numbers2 = randomUniqueIntegersInclusive(1, 10, 2);

    const number4c = randomIntegerInclusive(1, numbers1[2] - 1);
    const number4d = randomIntegerInclusive(1, numbers1[3] - 1);

    const numbers4 = [number4c, number4d];

    return { numbers1, numbers2, numbers4 };
  },
  Component: props => {
    const {
      question: { numbers1, numbers2, numbers4 },
      translate
    } = props;

    const numbers3 = range(0, 1).map(i => number(math.evaluate(`${numbers1[i]} * ${numbers2[i]}`)));
    const numbers5 = range(0, 1).map(i => number(math.evaluate(`${numbers3[i]} + ${numbers4[i]}`)));

    const items = [
      [
        `${numbers3[0].toLocaleString()} ${DIV} ${numbers1[0].toLocaleString()}`,
        number(math.evaluate(`${numbers3[0]} / ${numbers1[0]}`))
      ],
      [
        `${numbers3[1].toLocaleString()} ${DIV} ${numbers1[1].toLocaleString()}`,
        number(math.evaluate(`${numbers3[1]} / ${numbers1[1]}`))
      ],
      [
        `${numbers5[0].toLocaleString()} ${DIV} ${numbers1[2].toLocaleString()}`,
        number(math.evaluate(`${numbers5[0]} / ${numbers1[2]}`))
      ],
      [
        `${numbers5[1].toLocaleString()} ${DIV} ${numbers1[3].toLocaleString()}`,
        number(math.evaluate(`${numbers5[1]} / ${numbers1[3]}`))
      ]
    ];

    const correctAnswer = [[], []] as [down: number[], up: number[]];
    items.forEach((item, sumIndex) => {
      const zoneIndex = Number(item[1]) % 1 === 0 ? 0 : 1;
      correctAnswer[zoneIndex].push(sumIndex);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        zoneNames={[translate.tableHeaders.noRemainder(), translate.tableHeaders.remainder()]}
        testCorrect={correctAnswer}
        items={items.map((item, sumIndex) => ({
          value: sumIndex,
          component: `${item[0]}`
        }))}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aKU',
  description: 'aKU',
  keywords: ['Division', 'Group', 'Remainder'],
  schema: z.object({
    numbers1: z.array(z.number().int().min(2).max(12)).length(3),
    numbers2: z.array(z.number().int().min(1).max(12)).length(3),
    numbers4: z.array(z.number().int().min(1).max(11)).length(3)
  }),
  simpleGenerator: () => {
    const numbers1 = randomUniqueIntegersInclusive(2, 12, 3);
    const numbers2 = randomUniqueIntegersInclusive(1, 12, 3);
    const number4a = randomIntegerInclusive(1, numbers1[0] - 1);
    const number4b = randomIntegerInclusive(1, numbers1[1] - 1);
    const number4c = randomIntegerInclusive(1, numbers1[2] - 1);
    const numbers4 = [number4a, number4b, number4c];

    return { numbers1, numbers2, numbers4 };
  },

  Component: props => {
    const {
      question: { numbers1, numbers2, numbers4 },
      translate
    } = props;

    const numbers3 = range(0, 2).map(i => number(math.evaluate(`${numbers1[i]} * ${numbers2[i]}`)));
    const numbers5 = range(0, 2).map(i => numbers3[i] + numbers4[i]);

    const sentences = range(0, 2).map(i => ({
      value: numbers5[i],
      correctAnswer: [numbers2[i].toString(), numbers4[i].toString()],
      sentence: `${numbers5[i].toLocaleString()} ${DIV} ${numbers1[
        i
      ].toLocaleString()} = <ans/>r <ans/>`
    }));

    const sentencesSorted = sentences.sort((a, b) => a.value - b.value);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={sentencesSorted.map(({ correctAnswer }) => correctAnswer)}
        sentences={sentencesSorted.map(({ sentence }) => sentence)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKV',
  description: 'aKV',
  keywords: ['Division', 'Group', 'Remainder'],
  schema: z.object({
    numbers1: z.array(z.number().int().min(2).max(12)).length(3),
    numbers2: z.array(z.number().int().min(1).max(12)).length(3),
    numbers4: z.array(z.number().int().min(1).max(11)).length(3)
  }),
  simpleGenerator: () => {
    const numbers1 = randomUniqueIntegersInclusive(2, 12, 3);
    const numbers2 = randomUniqueIntegersInclusive(1, 12, 3);
    const number4a = randomIntegerInclusive(1, numbers1[0] - 1);
    const number4b = randomIntegerInclusive(1, numbers1[1] - 1);
    const number4c = randomIntegerInclusive(1, numbers1[2] - 1);
    const numbers4 = [number4a, number4b, number4c];

    return { numbers1, numbers2, numbers4 };
  },

  Component: props => {
    const {
      question: { numbers1, numbers2, numbers4 },
      translate
    } = props;

    const numbers3 = range(0, 2).map(i => number(math.evaluate(`${numbers1[i]} * ${numbers2[i]}`)));
    const numbers5 = range(0, 2).map(i => numbers3[i] + numbers4[i]);

    const sentences = range(0, 2).map(i => ({
      value: numbers5[i],
      correctAnswer: [numbers5[i].toString()],
      sentence: `<ans/> ${DIV} ${numbers1[i].toLocaleString()} = ${numbers2[i]} r ${numbers4[i]}`
    }));

    const sentencesSorted = sentences.sort((a, b) => a.value - b.value);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={sentencesSorted.map(({ correctAnswer }) => correctAnswer)}
        sentences={sentencesSorted.map(({ sentence }) => sentence)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberByA1DigitNumber2',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
