import { arraysHaveSameContentsUnordered } from 'common/src/utils/collections';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { useCallback, useContext } from 'react';
import { View } from 'react-native';
import BaseLayout from '../../molecules/BaseLayout';
import { PieChartInteractiveWithState } from '../representations/PieChartInteractive';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../utils/react';
import { Dimens, MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = {
  title: string;
  pdfTitle?: string;
  //update this type
  options: { color: string; label: string }[];
  numberOfSlices: number;
  Content: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;
  /** Either an array of the correct values, or a function for more complex use cases. */
  testCorrect: number[] | ((userAnswer: number[]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answersToDisplay?: number[];
    answerText?: string;
  };
  outerLabels?: string[];
};

type UserAnswer = number[];

export default function QF52InteractivePieChartWithContent({
  title,
  pdfTitle,
  options,
  numberOfSlices,
  Content,
  testCorrect: testCorrectProp,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  outerLabels,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const testCorrect = useCallback(
    (userAnswer: UserAnswer) => {
      if (typeof testCorrectProp === 'function') {
        // If provided just a function, just call that
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContentsUnordered(userAnswer, testCorrectProp);
      }
    },
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <View
                  style={{
                    width: dimens.width,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}
                >
                  {resolveElementOrRenderFunction(Content, {
                    dimens: { height: dimens.height, width: dimens.width / 2 }
                  })}
                  <PieChartInteractiveWithState
                    id={'pie'}
                    numberOfSlices={numberOfSlices}
                    radius={Math.min(dimens.width / 4, dimens.height / 2)}
                    testCorrect={testCorrect}
                    options={options}
                    outerLabels={outerLabels}
                    defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                  />
                </View>
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <View
              style={{
                width: dimens.width,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly'
              }}
            >
              {resolveElementOrRenderFunction(Content, {
                dimens: { height: dimens.height, width: dimens.width / 2 }
              })}
              <PieChartInteractiveWithState
                id={'pie'}
                numberOfSlices={numberOfSlices}
                radius={Math.min(dimens.width / 4, dimens.height / 2)}
                testCorrect={testCorrect}
                options={options}
                outerLabels={outerLabels}
              />
            </View>
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
