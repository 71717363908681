import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bay',
  description: 'bay',
  keywords: ['Count', 'Ten frame', 'Less'],
  schema: z.object({
    num: z.number().int().min(2).max(10),
    colour: counterVariantSchema
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(2, 10);
    const [colour] = getRandomUniqueCounters(1);
    return { num, colour };
  },
  Component: props => {
    const {
      question: { num, colour },
      translate
    } = props;

    const correctAnswer = num - 1;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToShow1LessThanNum(num)}
        pdfTitle={translate.ks1PDFInstructions.draw1LessThanNum(num)}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={[colour]}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < correctAnswer) return 'red';
        }}
        testCorrect={totalCountersIs(correctAnswer)}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'baA',
  description: 'baA',
  keywords: ['Less'],
  schema: z.object({
    number: z.number().int().min(0).max(10),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const variant = getRandomBoolean();
    const number = randomIntegerInclusive(0, variant ? 10 : 9);
    return { number, variant };
  },

  Component: props => {
    const {
      question: { number, variant },
      translate
    } = props;

    const answer = variant ? number + 1 : number - 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          variant
            ? translate.ks1AnswerSentences.oneLessThanAnsIsX(number)
            : translate.ks1AnswerSentences.oneLessThanXIsAns(number)
        }
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneLess',
  questionTypes: [Question1, Question3],
  unpublishedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
