// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UnderstandAndUseDegrees from './1UnderstandAndUseDegrees';
import ClassifyAngles from './2ClassifyAngles';
import EstimateAngles from './3EstimateAngles';
import MeasureAnglesUpTo180 from './4MeasureAnglesUpTo180';
import DrawLinesAndAnglesAccurately from './5DrawLinesAndAnglesAccurately';
import CalculateAnglesAroundAPoint from './6CalculateAnglesAroundAPoint';
import CalculateAnglesOnAStraightLine from './7CalculateAnglesOnAStraightLine';
import LengthsAndAnglesInShapes from './8LengthsAndAnglesInShapes';
import RegularAndIrregularPolygons from './9RegularAndIrregularPolygons';
import Num3DShapes from './10Num3DShapes';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [1, 3],
  smallSteps: [
    UnderstandAndUseDegrees, // Step 1
    ClassifyAngles, // Step 2
    EstimateAngles, // Step 3
    MeasureAnglesUpTo180, // Step 4
    DrawLinesAndAnglesAccurately, // Step 5
    CalculateAnglesAroundAPoint, // Step 6
    CalculateAnglesOnAStraightLine, // Step 7
    LengthsAndAnglesInShapes, // Step 8
    RegularAndIrregularPolygons, // Step 9
    Num3DShapes // Step 10
  ]
});
export default Block;
