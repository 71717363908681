import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DIV } from '../../../../constants';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import {
  getNumberOfIntervals,
  JugWithLiquid
} from '../../../../components/question/representations/JugWithLiquid';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJi',
  description: 'aJi',
  keywords: ['Capacity', 'Measure', 'Millilitres'],
  schema: z
    .object({
      beakerCapacity: numberEnum([
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000
      ]),
      intervals: numberEnum([10, 20, 25, 50, 100, 200, 250, 500])
    })
    .refine(
      val => val.beakerCapacity % val.intervals === 0,
      'intervals must be a factor of beakerCapacity'
    ),
  simpleGenerator: () => {
    const beakerCapacity = getRandomFromArray([
      100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000
    ] as const);

    const intervals = rejectionSample(
      () => getRandomFromArray([10, 20, 25, 50, 100, 200, 250, 500] as const),
      val => beakerCapacity % val === 0 && beakerCapacity / val <= 10 && beakerCapacity / val >= 2
    );

    return { beakerCapacity, intervals };
  },
  Component: props => {
    const {
      question: { beakerCapacity, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMl()}
        title={translate.instructions.whatIsTheCapacityOfTheJug()}
        testCorrect={[beakerCapacity.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={beakerCapacity}
            tickValue={intervals}
            labelUnits="ml"
            liquidAmount={0}
            emptyJug
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aJj',
  description: 'aJj',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      beakerCapacity: numberEnum([100, 200, 300, 400, 500, 600, 700, 800, 900]),
      intervals: numberEnum([10, 20, 50, 100, 200, 500])
    })
    .refine(
      val => val.beakerCapacity % val.intervals === 0,
      'intervals must be a factor of beakerCapacity'
    ),
  simpleGenerator: () => {
    const { intervals, beakerCapacity } = rejectionSample(
      () => {
        const intervals = getRandomFromArray([10, 20, 50, 100, 200] as const);
        const beakerCapacity = getRandomFromArray([
          100, 200, 300, 400, 500, 600, 700, 800, 900
        ] as const);
        return { intervals, beakerCapacity };
      },
      val =>
        val.beakerCapacity % val.intervals === 0 &&
        val.beakerCapacity / val.intervals <= 10 &&
        val.beakerCapacity / val.intervals >= 2
    );

    return { beakerCapacity, intervals };
  },
  Component: props => {
    const {
      question: { beakerCapacity, intervals },
      translate,
      displayMode
    } = props;

    const numOfIntervals = beakerCapacity / intervals;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsEqualIntervals(),
          `${beakerCapacity.toLocaleString()} ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === numOfIntervals.toString() &&
          arraysHaveSameContentsUnordered(userAnswer[1], [
            numOfIntervals.toString(),
            intervals.toString()
          ])
        }
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [intervals.toLocaleString()],
            [intervals.toLocaleString(), (beakerCapacity / intervals).toLocaleString()]
          ]
        }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1100}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={beakerCapacity}
            tickValue={intervals}
            labelUnits="ml"
            liquidAmount={0}
            emptyJug
            fontSize={displayMode === 'digital' ? 22 : undefined}
          />
        )}
      />
    );
  },
  questionHeight: 1100
});

const Question2v2 = newQuestionContent({
  uid: 'aJj2',
  description: 'aJj',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      beakerCapacity: numberEnum([100, 200, 300, 400, 500, 600, 700, 800, 900]),
      intervals: numberEnum([10, 20, 50, 100, 200, 500])
    })
    .refine(
      val => val.beakerCapacity % val.intervals === 0,
      'intervals must be a factor of beakerCapacity'
    ),
  simpleGenerator: () => {
    const { intervals, beakerCapacity } = rejectionSample(
      () => {
        const intervals = getRandomFromArray([10, 20, 50, 100, 200] as const);
        const beakerCapacity = getRandomFromArray([
          100, 200, 300, 400, 500, 600, 700, 800, 900
        ] as const);
        return { intervals, beakerCapacity };
      },
      val =>
        val.beakerCapacity % val.intervals === 0 &&
        val.beakerCapacity / val.intervals <= 10 &&
        val.beakerCapacity / val.intervals >= 2
    );

    return { beakerCapacity, intervals };
  },
  Component: props => {
    const {
      question: { beakerCapacity, intervals },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsScaleCountingUpIn()}
        sentence={translate.answerSentences.scaleIsCountingUpInAnsMl()}
        testCorrect={[intervals.toString()]}
        pdfDirection="column"
        questionHeight={1100}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={beakerCapacity}
            tickValue={intervals}
            labelUnits="ml"
            liquidAmount={0}
            emptyJug
            fontSize={displayMode === 'digital' ? 22 : undefined}
            displayMajorLabels="top"
          />
        )}
      />
    );
  },
  questionHeight: 1100
});
const Question3 = newQuestionContent({
  uid: 'aJk',
  description: 'aJk',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z.object({
    volume: z.number().int().min(50).max(1000).step(50)
  }),
  simpleGenerator: () => {
    const volume = randomIntegerInclusiveStep(50, 1000, 50);

    return { volume };
  },
  Component: props => {
    const {
      question: { volume },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMl()}
        title={translate.instructions.whatIsTheVolumeOfJuice()}
        testCorrect={[volume.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={1000}
            tickValue={500}
            unitsPerMajorTick={4}
            labelUnits="ml"
            liquidAmount={volume}
            liquidType="orange"
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aJl',
  description: 'aJl',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      jugCapacityMl: z.number().int().min(400).max(1000).multipleOf(100),
      juiceMl: z.number().int().min(100).max(900).multipleOf(100)
    })
    .refine(val => val.juiceMl < val.jugCapacityMl, 'juiceMl must be less than jugCapacityMl.'),
  simpleGenerator: () => {
    const jugCapacityMl = randomIntegerInclusiveStep(400, 1000, 100);

    const juiceMl = randomIntegerInclusiveStep(100, jugCapacityMl - 100, 100);

    return { jugCapacityMl, juiceMl };
  },
  Component: props => {
    const {
      question: { jugCapacityMl, juiceMl },
      translate
    } = props;

    const unitsPerMajorTick = (jugCapacityMl - 100) / 100;

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.dragArrowToFillJugWithNumMlOfJuice(juiceMl)}
        pdfTitle={translate.instructions.shadeToFillJugWithNumMlOfJuice(juiceMl)}
        testCorrect={juiceMl}
        jugCapacity={jugCapacityMl}
        tickValue={jugCapacityMl}
        liquidType="orange"
        unitsPerMajorTick={unitsPerMajorTick}
        labelUnits="ml"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aJm',
  description: 'aJm',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      beakerCapacity: numberEnum([100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
      intervals: numberEnum([10, 20, 50, 100, 200, 500]),
      volume: z.number().int().min(20).max(975)
    })
    .refine(
      val => val.beakerCapacity % val.intervals === 0,
      'intervals must be a factor of beakerCapacity'
    )
    .refine(
      val => val.volume <= val.beakerCapacity,
      'volume must be less than or equal to beakerCapacity'
    )
    .refine(val => val.volume % val.intervals === 0, 'volume must be a multiple of intervals'),
  simpleGenerator: () => {
    const { beakerCapacity, intervals } = rejectionSample(
      () => {
        const beakerCapacity = getRandomFromArray([
          100, 200, 300, 400, 500, 600, 700, 800, 900, 1000
        ] as const);
        const intervals = getRandomFromArray([10, 20, 50, 100, 200, 500] as const);
        return { beakerCapacity, intervals };
      },
      val =>
        val.beakerCapacity % val.intervals === 0 &&
        val.beakerCapacity / val.intervals <= 10 &&
        val.beakerCapacity / val.intervals >= 3 &&
        (val.beakerCapacity / val.intervals) % 2 === 0
    );

    const volume = randomIntegerInclusive(20, Math.min(975, beakerCapacity - intervals), {
      constraint: x =>
        x % intervals === 0 &&
        // not the mid label
        beakerCapacity / 2 !== x
    });

    return { beakerCapacity, intervals, volume };
  },
  Component: props => {
    const {
      question: { beakerCapacity, intervals, volume },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMl()}
        title={translate.instructions.howMuchWaterIsInTheJug()}
        testCorrect={[volume.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={beakerCapacity}
            tickValue={beakerCapacity / 2}
            unitsPerMajorTick={beakerCapacity / 2 / intervals - 1}
            labelUnits="ml"
            liquidAmount={volume}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aJn',
  description: 'aJn',
  keywords: ['Measure', 'Volume', 'Millilitres'],
  questionHeight: 900,
  schema: z
    .object({
      jugCapA: z.number().int().min(100).max(1000).step(50),
      jugCapB: z.number().int().min(100).max(1000).step(50),
      interval: numberEnum([20, 25, 50, 100, 200]),
      liquidA: z.number().int().min(20).max(975),
      liquidB: z.number().int().min(20).max(975)
    })
    .refine(
      ({ jugCapA, jugCapB }) => arrayHasNoDuplicates([jugCapA, jugCapB]),
      'jugs should be different'
    )
    .refine(
      ({ liquidA, liquidB }) => arrayHasNoDuplicates([liquidA, liquidB]),
      'Liquid amounts should be different'
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([20, 25, 50, 100, 200] as const);
    const [jugCapA, jugCapB] = randomUniqueIntegersInclusiveStep(
      Math.max(100, interval),
      1000,
      interval,
      2,
      {
        constraint: x => x % 50 === 0 && x / interval <= 10 && x / interval >= 3
      }
    );

    const liquidA = randomIntegerInclusive(20, Math.min(975, jugCapA - interval), {
      constraint: x => x % interval === 0
    });
    const liquidB = randomIntegerInclusive(20, Math.min(975, jugCapB - interval), {
      constraint: x => x % interval === 0 && x !== liquidA
    });

    return { jugCapA, jugCapB, interval, liquidA, liquidB };
  },
  Component: props => {
    const {
      question: { jugCapA, jugCapB, interval, liquidA, liquidB },
      translate
    } = props;

    const items = shuffle(
      [
        {
          liquidAmount: liquidA,
          jugCap: jugCapA,
          tickValue: interval,
          value: 'A'
        },
        {
          liquidAmount: liquidB,
          jugCap: jugCapB,
          tickValue: interval,
          value: 'B'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheJugThatShowsVolumeXMl(liquidA)}
        pdfTitle={translate.instructions.circleTheJugThatShowsVolumeXMl(liquidA)}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(({ liquidAmount, jugCap, tickValue, value }, i) => ({
            component: (
              <JugWithLiquid
                key={i}
                dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                jugCapacity={jugCap}
                displayMajorLabels="top"
                tickValue={tickValue}
                liquidAmount={liquidAmount}
                labelUnits="ml"
              />
            ),
            value
          }));
        }}
        testCorrect={['A']}
        questionHeight={900}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aJn2',
  description: 'aJn',
  keywords: ['Measure', 'Volume', 'Millilitres'],
  schema: z
    .object({
      jugCapacityMl: z.number().int().min(400).max(1000).multipleOf(100),
      juiceMl: z.number().int().min(100).max(900),
      intervals: numberEnum([20, 25, 50])
    })
    .refine(val => val.juiceMl < val.jugCapacityMl, 'juiceMl must be less than jugCapacityMl.'),
  simpleGenerator: () => {
    const jugCapacityMl = randomIntegerInclusiveStep(400, 1000, 100);
    const intervals = getRandomFromArray([20, 25, 50] as const);
    const juiceMl = randomIntegerInclusiveStep(100, jugCapacityMl - 100, intervals);

    return { jugCapacityMl, juiceMl, intervals };
  },
  Component: props => {
    const {
      question: { jugCapacityMl, juiceMl, intervals },
      translate
    } = props;

    const unitsPerMajorTick = getNumberOfIntervals(intervals, 100);

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.dragArrowToFillJugWithNumMlOfJuice(juiceMl)}
        pdfTitle={translate.instructions.shadeToFillJugWithNumMlOfJuice(juiceMl)}
        testCorrect={juiceMl}
        jugCapacity={jugCapacityMl}
        tickValue={100}
        snapToNearest={intervals}
        displayMajorLabels="top"
        liquidType="orange"
        unitsPerMajorTick={unitsPerMajorTick}
        labelUnits="ml"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureCapacityAndVolumeInMillilitres',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question6]
});
export default SmallStep;
