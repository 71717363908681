import { AssetSvg } from 'common/src/assets/svg';
import { colors, placeValueChartColors } from '../../../../theme/colors';

/** Each column is represented by a power of 10. Starting with 6 (millions) down to -4 (ten-thousandths) */
export type PlaceValueColumn = (typeof PlaceValueColumnArray)[number];
export const PlaceValueColumnArray = [6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4] as const;

export const placeValueColumnInfo: {
  [column in PlaceValueColumn]: {
    name: string;
    shortName: string;
    color: string;
    textColor: string;
    counter: JSX.Element;
    decimalCounter: JSX.Element;
    fractionCounter?: JSX.Element;
    base10Block?: JSX.Element;
  };
} = {
  6: {
    name: 'Millions',
    shortName: 'M',
    color: placeValueChartColors.millions,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/1000000" />,
    decimalCounter: <AssetSvg name="Place_value/1000000" />,
    fractionCounter: <AssetSvg name="Place_value/1000000" />
  },
  5: {
    name: 'Hundred Thousands',
    shortName: 'HTh',
    color: placeValueChartColors.hundredThousands,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/100000" />,
    decimalCounter: <AssetSvg name="Place_value/100000" />,
    fractionCounter: <AssetSvg name="Place_value/100000" />
  },
  4: {
    name: 'Ten Thousands',
    shortName: 'TTh',
    color: placeValueChartColors.tenThousandsTenThousandths,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/10000" />,
    decimalCounter: <AssetSvg name="Place_value/10000" />,
    fractionCounter: <AssetSvg name="Place_value/10000" />
  },
  3: {
    name: 'Thousands',
    shortName: 'Th',
    color: placeValueChartColors.thousandsThousandths,
    textColor: colors.white,
    counter: <AssetSvg name="Place_value/1000" />,
    decimalCounter: <AssetSvg name="Place_value/1000" />,
    fractionCounter: <AssetSvg name="Place_value/1000" />,
    base10Block: <AssetSvg name="Place_value/1000cube" />
  },
  2: {
    name: 'Hundreds',
    shortName: 'H',
    color: placeValueChartColors.hundredsHundredths,
    textColor: colors.white,
    counter: <AssetSvg name="Place_value/100" />,
    decimalCounter: <AssetSvg name="Place_value/100" />,
    fractionCounter: <AssetSvg name="Place_value/100" />,
    base10Block: <AssetSvg name="Place_value/100cube" />
  },
  1: {
    name: 'Tens',
    shortName: 'T',
    color: placeValueChartColors.tensTenths,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/10" />,
    decimalCounter: <AssetSvg name="Place_value/10" />,
    fractionCounter: <AssetSvg name="Place_value/10" />,
    base10Block: <AssetSvg name="Place_value/10cube" />
  },
  0: {
    name: 'Ones',
    shortName: 'O',
    color: placeValueChartColors.ones,
    textColor: colors.white,
    counter: <AssetSvg name="Place_value/1" />,
    decimalCounter: <AssetSvg name="Place_value/1" />,
    fractionCounter: <AssetSvg name="Place_value/1" />,
    base10Block: <AssetSvg name="Place_value/1cube" />
  },
  [-1]: {
    name: 'Tenths',
    shortName: 'Tth',
    color: placeValueChartColors.tensTenths,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/0.1" />,
    decimalCounter: <AssetSvg name="Place_value/0.1" />,
    fractionCounter: <AssetSvg name="Place_value/1-10" />
  },
  [-2]: {
    name: 'Hundredths',
    shortName: 'Hth',
    color: placeValueChartColors.hundredsHundredths,
    textColor: colors.white,
    counter: <AssetSvg name="Place_value/0.01" />,
    decimalCounter: <AssetSvg name="Place_value/0.01" />,
    fractionCounter: <AssetSvg name="Place_value/1-100" />
  },
  [-3]: {
    name: 'Thousandths',
    shortName: 'Thth',
    color: placeValueChartColors.thousandsThousandths,
    textColor: colors.white,
    counter: <AssetSvg name="Place_value/0.001" />,
    decimalCounter: <AssetSvg name="Place_value/0.001" />,
    fractionCounter: <AssetSvg name="Place_value/1-1000" />
  },
  [-4]: {
    name: 'Ten Thousandths',
    shortName: 'TThth',
    color: placeValueChartColors.tenThousandsTenThousandths,
    textColor: colors.prussianBlue,
    counter: <AssetSvg name="Place_value/0.0001" />,
    decimalCounter: <AssetSvg name="Place_value/0.0001" />,
    fractionCounter: <AssetSvg name="Place_value/1-10000" />
  }
};
