import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { ADD, SUB } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { deduplicate } from '../../../../utils/collections';
import { buildSimpleNumberSentence } from '../../../../utils/strings';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgK',
  description: 'bgK',
  keywords: ['Add', 'Subtract', 'Base 10'],
  schema: z
    .object({
      numberToDisplay: z.number().int().min(11).max(99),
      lessOrMore: z.enum(['less', 'more']),
      transformer: z.number().int().min(1).max(9)
    })
    .refine(
      val =>
        numbersDoNotExchange(
          val.numberToDisplay,
          val.lessOrMore === 'more' ? val.transformer : -val.transformer
        ),
      'numbers do not exchange'
    )
    .refine(
      val =>
        val.lessOrMore === 'less'
          ? (val.numberToDisplay - val.transformer) % 10 !== 0
          : (val.numberToDisplay + val.transformer) % 10 !== 0,
      'numbers do not sum to multiple of 10'
    ),
  simpleGenerator: () => {
    const { lessOrMore, transformer, numberToDisplay } = rejectionSample(
      () => {
        const lessOrMore = getRandomFromArray(['less', 'more'] as const);

        const transformer = randomIntegerInclusive(1, 9);
        const numberToDisplay = randomIntegerInclusive(11, 99);

        return { lessOrMore, transformer, numberToDisplay };
      },
      ({ lessOrMore, transformer, numberToDisplay }) =>
        lessOrMore === 'less'
          ? (numberToDisplay - transformer) % 10 !== 0 &&
            numbersDoNotExchange(numberToDisplay, -transformer)
          : (numberToDisplay + transformer) % 10 !== 0 &&
            numbersDoNotExchange(numberToDisplay, transformer)
    );

    return { lessOrMore, transformer, numberToDisplay };
  },
  Component: props => {
    const {
      question: { lessOrMore, transformer, numberToDisplay },
      translate,
      displayMode
    } = props;

    const instruction =
      lessOrMore === 'less'
        ? translate.ks1Instructions.whatIsXLessThanTheNumberShown(transformer)
        : translate.ks1Instructions.whatIsXMoreThanTheNumberShown(transformer);
    const answer = numberToDisplay + (lessOrMore === 'less' ? -transformer : transformer);

    const numberToDisplayTens = Math.floor(numberToDisplay / 10);
    const numberToDisplayOnes = numberToDisplay % 10;

    return (
      <QF1ContentAndSentence
        title={instruction}
        sentence="<ans/>"
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={numberToDisplayOnes}
            tens={numberToDisplayTens}
            dimens={{ width: dimens.width * 0.35, height: dimens.height * 0.9 }}
            scale={displayMode === 'digital' ? 0.24 : 0.38}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bgL',
  description: 'bgL',
  keywords: ['Add', 'Subtract', 'Ones'],
  schema: z
    .object({
      numberA: z.number().int().min(11).max(98),
      numberB: z.number().int().min(1).max(9),
      addOrSubtract: z.enum([ADD, SUB]),
      selectables: z.array(z.number().int().min(1).max(99)).length(4)
    })
    .refine(
      val =>
        numbersDoNotExchange(val.numberA, val.addOrSubtract === ADD ? val.numberB : -val.numberB),
      'numbers do not exchange'
    ),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const numberB = randomIntegerInclusive(1, 9);
    // If numbers sum to 99 or less than 10, then we will have duplicates, the constraint prevents this
    const numberA = randomIntegerInclusive(11, 98, {
      constraint: x =>
        numbersDoNotExchange(x, addOrSubtract === ADD ? numberB : -numberB) &&
        x + numberB !== 99 &&
        x - numberB > 10
    });
    const answer = addOrSubtract === ADD ? numberA + numberB : numberA - numberB;

    // Incorrect answers
    const incorrectA = answer + 1;
    const incorrectB = answer - 1;
    const incorrectC = addOrSubtract === ADD ? numberA - numberB : numberA + numberB;
    const incorrectD = answer + 10;
    const incorrectE = answer - 10;

    const incorrectAnswers = getRandomSubArrayFromArray(
      deduplicate([incorrectA, incorrectB, incorrectC, incorrectD, incorrectE], x => x),
      3,
      { constraint: x => x !== answer && x < 100 }
    );

    return {
      addOrSubtract,
      numberA,
      numberB,
      selectables: shuffle([answer, ...incorrectAnswers])
    };
  },
  Component: ({ question, translate }) => {
    const { addOrSubtract, numberA, numberB, selectables } = question;

    const answer = addOrSubtract === ADD ? numberA + numberB : numberA - numberB;

    const items = selectables.map(num => ({
      component: `${num.toLocaleString()}`,
      value: num
    }));

    const equation = `${numberA.toLocaleString()} ${addOrSubtract} ${numberB.toLocaleString()}`;

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.whatIsXSelectYourAnswer(equation)}
        pdfTitle={translate.ks1PDFInstructions.whatIsXTickCorrectAnswer(equation)}
        items={items}
        testCorrect={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgM',
  description: 'bgM',
  keywords: ['Add', 'Subtract', 'Ones'],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(99),
      numberB: z.number().int().min(1).max(99),
      addOrSubtract: z.enum([ADD, SUB]),
      reversed: z.boolean()
    })
    .refine(
      val =>
        val.addOrSubtract === ADD
          ? (val.numberA + val.numberB) % 10 !== 0
          : (val.numberA - val.numberB) % 10 !== 0,
      'answer must not be a multiple of 10'
    )
    .refine(
      val =>
        numbersDoNotExchange(val.numberA, val.addOrSubtract === ADD ? val.numberB : -val.numberB),
      'numbers do not exchange'
    ),
  simpleGenerator: () => {
    const reversed = getRandomBoolean();

    const { numberA, numberB, addOrSubtract } = rejectionSample(
      () => {
        const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

        const numberB = randomIntegerInclusive(1, 9);
        const numberA = randomIntegerInclusive(11, 99);

        return { numberA, numberB, addOrSubtract };
      },
      ({ numberA, numberB, addOrSubtract }) =>
        (addOrSubtract === ADD ? numberA + numberB : numberA - numberB) % 10 !== 0 &&
        numbersDoNotExchange(numberA, addOrSubtract === ADD ? numberB : -numberB)
    );

    // If addition, shuffle numberA & numberB
    const numbers = addOrSubtract === ADD ? shuffle([numberA, numberB]) : [numberA, numberB];

    return {
      addOrSubtract,
      numberA: numbers[0],
      numberB: numbers[1],
      reversed
    };
  },
  Component: ({ question, translate }) => {
    const { addOrSubtract, numberA, numberB, reversed } = question;

    const answer = addOrSubtract === ADD ? numberA + numberB : numberA - numberB;
    const { sentence } = buildSimpleNumberSentence([numberA, numberB, answer], addOrSubtract, 2, {
      reversed
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheCalculation()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtract1s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
