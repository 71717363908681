// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import PoundsAndPence from './1PoundsAndPence';
import ConvertPoundsAndPence from './2ConvertPoundsAndPence';
import AddMoney from './3AddMoney';
import SubtractMoney from './4SubtractMoney';
import FindChange from './5FindChange';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [3, 4],
  smallSteps: [
    PoundsAndPence, // Step 1
    ConvertPoundsAndPence, // Step 2
    AddMoney, // Step 3
    SubtractMoney, // Step 4
    FindChange // Step 5
  ]
});
export default Block;
