// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValue';
import Time from './Time';
import MultiplicationAndDivision from './MultiplicationAndDivision';
import Fractions from './Fractions';
import PositionAndDirection from './PositionAndDirection';
import Money from './Money';

const Term = newTermContent({
  term: 'Summer',
  blocks: [MultiplicationAndDivision, Fractions, PositionAndDirection, PlaceValue, Time, Money]
});
export default Term;
