import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';

import { z } from 'zod';
import { all, create, number } from 'mathjs';
import { View } from 'react-native';

import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  ActivityApproximateTime,
  ActivityApproximateTimeNew,
  translateActivity
} from '../../../../utils/activities';
import {
  addDurationTo24hTime,
  convert12hToSpokenString,
  convert24hTo12h
} from '../../../../utils/time';
import { characterNameLabel, getCharacterHeadSvgName } from '../../../../utils/characters';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from 'common/src/components/typography/Text';
import { isEqual } from '../../../../utils/matchers';
import { fractionToDecimal } from '../../../../utils/fractions';
import { AssetSvg } from '../../../../assets/svg';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1Activities = [
  'blink',
  'clap',
  'countBack',
  'eatASweet',
  'getDressed',
  'listenToASong',
  'nameCountries',
  'run5km',
  'sneeze',
  'tenStarJumps',
  'tieYourShoes',
  'travelToMoon',
  'travelToSchool',
  'washDryLaundry',
  'watchATVShow',
  'writeAddress',
  'writeFirstName'
] as const;

const Question1v2Activities = [
  'blink',
  'clap',
  'countBack',
  'eatASweet',
  'listenToASong',
  'sneeze',
  'tenStarJumps',
  'travelToMoon',
  'washLaundry',
  'watchATVShow',
  'writeAddress',
  'writeFirstName'
] as const;

const Question1 = newQuestionContent({
  uid: 'axM',
  description: 'axM',
  keywords: ['Hours', 'Minutes', 'Seconds'],
  schema: z
    .object({
      lessThan: z.enum(['10 seconds', '1 minute', '5 minutes', '1 hour']),
      activities: z.enum(Question1Activities).array().length(2)
    })
    .refine(
      ({ lessThan, activities }) =>
        activities.filter(activity => ActivityApproximateTime[activity].includes(lessThan))
          .length === 1,
      'There must be only one correct activity'
    ),
  simpleGenerator: () => {
    const lessThan = getRandomFromArray(['10 seconds', '1 minute', '5 minutes', '1 hour'] as const);

    const activities = rejectionSample(
      () => getRandomSubArrayFromArray(Question1Activities, 2),
      activities =>
        activities.filter(activity => ActivityApproximateTime[activity].includes(lessThan))
          .length === 1 &&
        !(
          (activities[0].includes('1 minute') || activities[0].includes('5 minutes')) &&
          (activities[1].includes('1 minute') || activities[1].includes('5 minutes'))
        )
    );
    return { lessThan, activities };
  },
  Component: ({ question: { lessThan, activities }, translate }) => {
    const translatedLessThan =
      lessThan === '1 hour'
        ? translate.answerSentences.xHours(1)
        : lessThan === '1 minute'
        ? translate.answerSentences.xMinutes(1)
        : lessThan === '5 minutes'
        ? translate.answerSentences.xMinutes(5)
        : translate.answerSentences.xSeconds(10);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectActivityThatTakesLessThan(translatedLessThan)}
        pdfTitle={translate.instructions.circleActivityThatTakeLessThan(translatedLessThan)}
        testCorrect={activities.filter(activity =>
          ActivityApproximateTime[activity].includes(lessThan)
        )}
        numItems={2}
        renderItems={() => [
          {
            value: activities[0],
            component: (
              <Text style={{ textAlign: 'center' }} variant="WRN700">
                {translateActivity(activities[0], translate)}
              </Text>
            )
          },
          {
            value: activities[1],
            component: (
              <Text style={{ textAlign: 'center' }} variant="WRN700">
                {translateActivity(activities[1], translate)}
              </Text>
            )
          }
        ]}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'axM2',
  description: 'axM',
  keywords: ['Hours', 'Minutes', 'Seconds'],
  schema: z
    .object({
      approxTime: z.enum([
        '1 second',
        '10 seconds',
        '30 seconds',
        '1 minute',
        '5 minutes',
        '1 hour'
      ]),
      activities: z.enum(Question1v2Activities).array().length(2)
    })
    .refine(
      ({ approxTime, activities }) =>
        activities.filter(activity => ActivityApproximateTimeNew[activity].includes(approxTime))
          .length === 1,
      'There must be only one correct activity'
    ),
  simpleGenerator: () => {
    const approxTime = getRandomFromArray([
      '1 second',
      '10 seconds',
      '30 seconds',
      '1 minute',
      '5 minutes',
      '1 hour'
    ] as const);

    const activities = rejectionSample(
      () => getRandomSubArrayFromArray(Question1v2Activities, 2),
      activities =>
        activities.filter(activity => ActivityApproximateTimeNew[activity].includes(approxTime))
          .length === 1 &&
        !(
          (activities[0].includes('10 seconds') || activities[0].includes('30 seconds')) &&
          (activities[1].includes('10 seconds') || activities[1].includes('30 seconds'))
        )
    );
    return { approxTime, activities };
  },
  Component: ({ question: { approxTime, activities }, translate }) => {
    const translatedTime =
      approxTime === '1 hour'
        ? translate.answerSentences.xHours(1)
        : approxTime === '1 minute'
        ? translate.answerSentences.xMinutes(1)
        : approxTime === '5 minutes'
        ? translate.answerSentences.xMinutes(5)
        : approxTime === '10 seconds'
        ? translate.answerSentences.xSeconds(10)
        : approxTime === '30 seconds'
        ? translate.answerSentences.xSeconds(30)
        : translate.answerSentences.xSeconds(1);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectActivityThatApproximatelyTakesX(translatedTime)}
        pdfTitle={translate.instructions.circleActivityThatApproximatelyTakesX(translatedTime)}
        testCorrect={activities.filter(activity =>
          ActivityApproximateTimeNew[activity].includes(approxTime)
        )}
        numItems={2}
        renderItems={() => [
          {
            value: activities[0],
            component: (
              <Text style={{ textAlign: 'center' }} variant="WRN700">
                {translateActivity(activities[0], translate)}
              </Text>
            )
          },
          {
            value: activities[1],
            component: (
              <Text style={{ textAlign: 'center' }} variant="WRN700">
                {translateActivity(activities[1], translate)}
              </Text>
            )
          }
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axN',
  description: 'axN',
  keywords: ['Days', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(10).max(80),
    number3: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = randomIntegerInclusiveStep(10, 80, 10);
    const number3 = randomIntegerInclusive(3, 12);

    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const answer1 = number1 * 60;
    const answer2 = number2 * 60;
    const answer3 = number3 * 24;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        testCorrect={[[answer1.toString()], [answer2.toString()], [answer3.toString()]]}
        sentences={[
          translate.answerSentences.XMinutesToSeconds(number1.toLocaleString()),
          translate.answerSentences.XHoursToMinutes(number2.toLocaleString()),
          translate.answerSentences.XDaysToHours(number3.toLocaleString())
        ]}
        containerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axO',
  description: 'axO',
  keywords: ['Minutes', 'Seconds'],
  schema: z.object({
    number1: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    return {
      number1
    };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const seconds1 = number1;
    const seconds2 = number1 * 10;
    const seconds3 = number1 * 100;

    // Statements
    const statements = [
      {
        lhsComponent: translate.answerSentences.xMinutes(number1.toLocaleString()),
        rhsComponent: translate.answerSentences.xSeconds(seconds1.toLocaleString()),
        value: 'C'
      },
      {
        lhsComponent: translate.answerSentences.xMinutes(number1.toLocaleString()),
        rhsComponent: translate.answerSentences.xSeconds(seconds2.toLocaleString()),
        value: 'C'
      },
      {
        lhsComponent: translate.answerSentences.xMinutes(number1.toLocaleString()),
        rhsComponent: translate.answerSentences.xSeconds(seconds3.toLocaleString()),
        value: 'B'
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const answerOptions = [
      {
        component: '=',
        value: 'A'
      },
      {
        component: '<',
        value: 'B'
      },
      {
        component: '>',
        value: 'C'
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragInequalities0rEqualToCompleteNumberSentences()}
        pdfTitle={translate.instructions.useInequalityAndEqualSymbolsCompleteStatements()}
        items={answerOptions}
        itemVariant="square"
        pdfLayout="itemsHidden"
        moveOrCopy="copy"
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements.map(({ lhsComponent, rhsComponent, value }) => ({
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 240 : 300, textAlign: 'right' }}
            >
              {lhsComponent}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 240 : 300, textAlign: 'left' }}
            >
              {rhsComponent}
            </Text>
          ),
          correctAnswer: value
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'axP',
  description: 'axP',
  keywords: ['Minutes', 'Seconds'],
  schema: z.object({
    number1: z.number().min(3).max(5).step(0.5),
    number2: z.number().int().min(181).max(500),
    name1: nameSchema,
    name2: nameSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(30, 50, 5) / 10;

    const number2 = randomIntegerInclusive(number1 * 60 + 1, 500);

    const [name1, name2] = getRandomUniqueNames(2);

    return { number1, number2, name1, name2 };
  },
  Component: props => {
    const {
      question: { number1, number2, name1, name2 },
      translate
    } = props;

    const differenceSeconds = number(math.evaluate(`${number2} - ${number1} * 60`));
    const answer1 = Math.floor(number(math.evaluate(`${differenceSeconds} / 60`)));
    const answer2 = number(math.evaluate(`${differenceSeconds} - ${answer1} * 60`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.charactersRunARaceHowMuchFaster(
          name1,
          number1.toLocaleString(),
          name2,
          number2.toLocaleString()
        )}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansMinutesAndSeconds(answer1, answer2)}
        testCorrect={[answer1.toString(), answer2.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axQ',
  description: 'axQ',
  keywords: ['Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    minutes: z.number().int().min(5).max(25),
    hours: z.number().int().min(0).max(11),
    addMinutes: z.number().int().min(10).max(60).multipleOf(10),
    addSeconds: z.number().int().min(120).max(360).multipleOf(60),
    addHours: z.number().min(1.5).max(6.5),
    unitOfTime: z.enum(['seconds', 'minutes', 'hours'])
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusiveStep(5, 25, 5, { constraint: x => x !== 15 });
    const hours = randomIntegerInclusive(0, 11);
    const unitOfTime = getRandomFromArray(['seconds', 'minutes', 'hours'] as const);
    const addMinutes = randomIntegerInclusiveStep(10, 60, 10);
    const addSeconds = randomIntegerInclusiveStep(120, 360, 60);
    const addHours = randomIntegerInclusive(1, 6) + 0.5;

    return { minutes, hours, unitOfTime, addHours, addMinutes, addSeconds };
  },
  Component: ({
    question: { minutes, hours, unitOfTime, addHours, addMinutes, addSeconds },
    translate
  }) => {
    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    const displayAddedTime =
      unitOfTime === 'hours'
        ? translate.answerSentences.xHours(addHours.toLocaleString())
        : unitOfTime === 'seconds'
        ? translate.answerSentences.xSeconds(addSeconds.toLocaleString())
        : translate.answerSentences.xMinutes(addMinutes.toLocaleString());

    const totalMins =
      unitOfTime === 'hours'
        ? number(math.evaluate(`${addHours} * 60`))
        : unitOfTime === 'seconds'
        ? number(math.evaluate(`${addSeconds} / 60`))
        : addMinutes;

    let [newHours, newMinutes] = convert24hTo12h(
      ...addDurationTo24hTime(hours, minutes, totalMins)
    );
    // QF40 uses a time between 0 and 11 hours.
    if (newHours === 12) {
      newHours = 0;
    }
    const newTime = { hours: newHours, minutes: newMinutes };

    return (
      <QF40SetTheClockFace
        title={translate.instructions.moveTheHandsOfClockToShowTheNewTime(
          translatedTime,
          displayAddedTime
        )}
        pdfTitle={translate.instructions.moveTheHandsOfClockToShowTheNewTimePDF(
          translatedTime,
          displayAddedTime
        )}
        testCorrect={isEqual(newTime)}
        exampleCorrectAnswer={newTime}
        startTime={{ hours, minutes }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axR',
  description: 'axR',
  keywords: ['Days', 'Hours', 'Minutes'],
  schema: z.object({
    names: z.array(nameSchema).length(4),
    hoursA: z.number().int().min(6).max(12),
    hoursB: z.number().int().min(1).max(5),
    dayFrac: z.array(z.number().int()),
    showInHours: z.boolean(),
    randomCharacter: z.boolean(),
    longerOrShorter: z.enum(['Longer', 'Shorter']),
    longestOrShortestTitle: z.enum(['Longest', 'Shortest']),
    halfOrTwiceAsLong: z.enum(['Half', 'Twice']),
    value1: z.number(),
    value2: z.number(),
    value3: z.number(),
    value4: z.number()
  }),
  simpleGenerator: () => {
    const longestOrShortestTitle = getRandomFromArray(['Shortest', 'Longest'] as const);
    const showInHours = getRandomBoolean();
    const randomCharacter = getRandomBoolean();

    const {
      value1,
      value2,
      value3,
      value4,
      names,
      hoursA,
      hoursB,
      dayFrac,
      longerOrShorter,
      halfOrTwiceAsLong
    } = rejectionSample(
      () => {
        const names = getRandomUniqueNames(4);
        const hoursA = randomIntegerInclusive(6, 12);
        const hoursB = randomIntegerInclusive(1, 5);
        const dayFrac = getRandomFromArray([
          [1, 4],
          [1, 3],
          [1, 2],
          [2, 3],
          [1],
          [1, 1, 4],
          [1, 1, 3],
          [1, 1, 2]
        ]);
        const longerOrShorter = getRandomFromArray(['Shorter', 'Longer'] as const);
        const halfOrTwiceAsLong = getRandomFromArray(['Half', 'Twice'] as const);

        // Value 1
        const value1 = hoursA * 60;

        // Value 2
        const value2 =
          // If fraction returns just 1 index then it's a full day
          dayFrac.length === 1
            ? 24 * 60
            : // If fraction returns 2 indexes it's a fraction of day for e.g 3/4 of a day
            dayFrac.length === 2
            ? number(math.evaluate(`24 * (${fractionToDecimal(dayFrac[0], dayFrac[1])})`)) * 60
            : // Else 3 indexes are returned it's a day and a fraction of a day for e.g 1 day and 2/3
              number(math.evaluate(`48 * ${fractionToDecimal(dayFrac[1], dayFrac[2])}`)) * 60;

        // Value 3
        const value3 = randomCharacter
          ? // If character A
            longerOrShorter === 'Longer'
            ? value1 + hoursB * 60
            : value1 - hoursB * 60
          : // If character B
          longerOrShorter === 'Longer'
          ? value2 + hoursB * 60
          : value2 - hoursB * 60;

        // Value 4
        const value4 = randomCharacter
          ? // If character A
            halfOrTwiceAsLong === 'Half'
            ? 0.5 * value1
            : 2 * value1
          : // If character B
          halfOrTwiceAsLong === 'Half'
          ? 0.5 * value2
          : 2 * value2;

        return {
          value1,
          value2,
          value3,
          value4,
          names,
          hoursA,
          hoursB,
          dayFrac,
          longerOrShorter,
          halfOrTwiceAsLong
        };
      },
      ({ value1, value2, value3, value4 }) => {
        return arrayHasNoDuplicates([value1, value2, value3, value4]);
      }
    );

    return {
      names,
      hoursA,
      hoursB,
      dayFrac,
      showInHours,
      randomCharacter,
      longerOrShorter,
      longestOrShortestTitle,
      halfOrTwiceAsLong,
      value1,
      value2,
      value3,
      value4
    };
  },
  Component: props => {
    const {
      question: {
        names,
        hoursA,
        hoursB,
        dayFrac,
        showInHours,
        randomCharacter,
        longerOrShorter,
        longestOrShortestTitle,
        halfOrTwiceAsLong,
        value1,
        value2,
        value3,
        value4
      },
      translate
    } = props;

    // Check either longest or shortest journey
    const correctAnswer =
      longestOrShortestTitle === 'Longest'
        ? Math.max(value1, value2, value3, value4)
        : Math.min(value1, value2, value3, value4);

    // Selectables
    const selectables = [
      {
        value: value1,
        name: names[0],
        text: showInHours
          ? translate.answerSentences.myJourneyIsXHours(hoursA)
          : translate.answerSentences.myJourneyIsXMinutes(hoursA * 60)
      },
      {
        value: value2,
        name: names[1],
        text:
          dayFrac.length === 1
            ? translate.answerSentences.myJourneyIsOneDay()
            : dayFrac.length === 2
            ? translate.answerSentences.myJourneyIsFractionOfADay(
                `<frac n='${dayFrac[0]}' d='${dayFrac[1]}' />`
              )
            : translate.answerSentences.myJourneyIsFractionDays(
                `<frac w='${dayFrac[0]}' n='${dayFrac[1]}' d='${dayFrac[2]}' />`
              )
      },
      {
        value: value3,
        name: names[2],
        text: translate.answerSentences.myJourneyIsXHoursYLongerOrShorterThanCharacterZ(
          hoursB,
          longerOrShorter,
          randomCharacter ? names[0] : names[1]
        )
      },
      {
        value: value4,
        name: names[3],
        text: translate.answerSentences.myJourneyIsXAmountAsLongAsCharacterY(
          halfOrTwiceAsLong,
          randomCharacter ? names[0] : names[1]
        )
      }
    ];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whoHasLongestOrShortestJourney(
          names[0],
          names[1],
          names[2],
          names[3],
          longestOrShortestTitle
        )}
        pdfTitle={translate.instructions.whoHasLongestOrShortestJourneyPDF(
          names[0],
          names[1],
          names[2],
          names[3],
          longestOrShortestTitle
        )}
        testCorrect={[correctAnswer]}
        numItems={4}
        renderItems={({ dimens }) =>
          selectables.map(selectable => ({
            value: selectable.value,
            component: (
              <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <SpeechBubble
                    flickLocation="top-right"
                    style={{
                      maxWidth: 0.5 * dimens.width,
                      maxHeight: 0.5 * dimens.height
                    }}
                  >
                    {selectable.text}
                  </SpeechBubble>
                </View>
                <View
                  style={{ rowGap: 10, justifyContent: 'center', marginLeft: 10, marginRight: 20 }}
                >
                  <AssetSvg
                    name={getCharacterHeadSvgName(selectable.name)}
                    height={dimens.height / 2}
                    width={dimens.width / 3 - 20}
                  />
                  {characterNameLabel(selectable.name, dimens.width / 3 - 20)}
                </View>
              </View>
            )
          }))
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HoursMinutesAndSeconds',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
