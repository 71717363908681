import { Pressable, View } from 'react-native';
import { SetState } from 'common/src/utils/react';
import { filledArray, range } from 'common/src/utils/collections';
import { Dimens } from 'common/src/theme/scaling';
import Table from 'common/src/components/molecules/Table';
import { useCallback, useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import { withStateHOC } from '../../../stateTree';

type Props = {
  /**
   * Total number of rows required
   */
  numberOfRows: number;
  /**
   * Number of columns per row
   */
  numberOfCols: number;
  /**
   * The user answer, i.e. which cells are highlighted.
   */
  userAnswer?: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<boolean[][]>;
  /**
   * Dimensions
   */
  dimens: Dimens;
  /**
   * When true empty arrays will show on pdf
   */
  showArrayOnPDF?: boolean;
};

export const CreateArrayOfObjects = ({
  numberOfRows,
  numberOfCols,
  userAnswer,
  setUserAnswer,
  dimens,
  showArrayOnPDF
}: Props) => {
  const displayMode = useContext(DisplayMode);

  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      if (setUserAnswer) {
        setUserAnswer(userAnswer =>
          userAnswer.map((row, rowIndex) =>
            rowIndex !== rowIdx
              ? row
              : row.map((item, columnIndex) => (columnIndex !== colIdx ? item : !item))
          )
        );
      }
    },
    [setUserAnswer]
  );

  // Firstly, calculate various layout dimensions.
  const tableWidth = dimens.width * 0.9;
  const tableHeight = dimens.height * 0.9;
  const counterDimens = Math.min(tableHeight / numberOfRows, tableWidth / numberOfCols);

  const noBackground = showArrayOnPDF && displayMode === 'pdf';

  // Now return a table
  return (
    <Table
      style={{ width: tableWidth, height: tableHeight }}
      rowStyle={{ flex: 1 }} // Rows share the table height
      cellStyle={{ flex: 1 }} // Cells share the row width
      borderWidthAmount={0}
      items={range(0, numberOfRows - 1).map(rowIndex =>
        range(0, numberOfCols - 1).map(columnIndex => {
          return (
            <Pressable
              key={`${rowIndex}-${columnIndex}`}
              style={{
                flex: 1,
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: displayMode === 'pdf' && !showArrayOnPDF ? 0 : 1
              }}
              onPress={() => handleOnPress(rowIndex, columnIndex)}
            >
              <View
                style={{
                  backgroundColor: noBackground
                    ? 'white'
                    : userAnswer?.[rowIndex]?.[columnIndex]
                    ? colors.burntSienna
                    : 'grey',
                  borderColor: 'black',
                  borderWidth: showArrayOnPDF && displayMode === 'pdf' ? 2 : 0,
                  width: counterDimens * 0.95,
                  height: counterDimens * 0.95,
                  borderRadius: 200
                }}
              />
            </Pressable>
          );
        })
      )}
    />
  );
};

export const CreateArrayOfObjectsWithState = withStateHOC(CreateArrayOfObjects, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(null, props.numberOfRows).map(() =>
      filledArray(false, props.numberOfCols)
    )
  })
});
