import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

/**
 * Hook to get _screen_ (not window) dimensions info, since there isn't an inbuilt one like there is for window.
 *
 * From https://reactnative.dev/docs/dimensions.
 */
export default function useScreenDimensions() {
  const [screenDimensions, setScreenDimensions] = useState(() => Dimensions.get('screen'));

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ screen }) => {
      setScreenDimensions(screen);
    });
    return () => subscription?.remove();
  });

  return screenDimensions;
}
