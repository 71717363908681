import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import TextStructure from '../../../../components/molecules/TextStructure';
import { AssetSvg } from '../../../../assets/svg';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baN',
  description: 'baN',
  keywords: ['Group', 'Order', 'Compare'],
  schema: z
    .object({
      numA: z.number().int().min(1).max(6),
      numB: z.number().int().min(1).max(6),
      numC: z.number().int().min(1).max(6),
      fruitA: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      fruitB: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      fruitC: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      smallestGreatest: z.enum(['smallest', 'greatest'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.fruitA, val.fruitB, val.fruitC]),
      'All fruits must be different'
    )
    .refine(
      val => arrayHasNoDuplicates([val.numA, val.numB, val.numC]),
      'All numbers must be different'
    ),
  simpleGenerator: () => {
    const [numA, numB, numC] = randomUniqueIntegersInclusive(1, 6, 3);

    const [fruitA, fruitB, fruitC] = getRandomSubArrayFromArray(
      ['apple', 'orange', 'banana', 'strawberry', 'pear'] as const,
      3
    );

    const smallestGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { numA, numB, numC, fruitA, fruitB, fruitC, smallestGreatest };
  },

  Component: ({
    question: { numA, numB, numC, fruitA, fruitB, fruitC, smallestGreatest },
    translate
  }) => {
    const fruitSvgPaths = {
      banana: 'Array_objects/Banana' as const,
      pear: 'Array_objects/Pear' as const,
      orange: 'Array_objects/Orange' as const,
      apple: 'Array_objects/AppleGreen' as const,
      strawberry: 'Array_objects/Strawberry' as const
    };

    const fruitsA = Array.from(new Array(numA), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitA]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });

    const fruitsB = Array.from(new Array(numB), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitB]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });
    const fruitsC = Array.from(new Array(numC), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitC]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });

    const correctAnswer = (smallestGreatest === 'smallest' ? Math.min : Math.max)(numA, numB, numC);

    const sentence =
      smallestGreatest === 'smallest'
        ? translate.ks1AnswerSentences.groupAnsIsTheSmallest()
        : translate.ks1AnswerSentences.groupAnsIsTheGreatest();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1Instructions.completeTheSentence()}
        itemVariant="square"
        items={[
          { value: numA, component: 'A' },
          { value: numB, component: 'B' },
          { value: numC, component: 'C' }
        ]}
        moveOrCopy="move"
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfLayout="itemsHidden"
        Content={({ dimens }) => (
          <View style={{ width: dimens.width, height: dimens.height, flexDirection: 'column' }}>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('A')}
              />

              {fruitsA}
            </View>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('B')}
              />
              {fruitsB}
            </View>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('C')}
              />
              {fruitsC}
            </View>
          </View>
        )}
        sentence={sentence}
        testCorrect={[correctAnswer]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'baO',
  description: 'baO',
  keywords: ['Order'],
  schema: z
    .object({
      numA: z.number().min(0).max(10),
      numB: z.number().min(0).max(10),
      numC: z.number().min(0).max(10),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numA, val.numB, val.numC]),
      'All fruits must be different'
    ),
  simpleGenerator: () => {
    const [numA, numB, numC] = randomUniqueIntegersInclusive(0, 10, 3);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numA, numB, numC, ordering };
  },
  Component: props => {
    const {
      question: { numA, numB, numC, ordering },
      translate
    } = props;

    const correctOrder = sortNumberArray([numA, numB, numC], ordering);

    return (
      <QF5DragOrderHorizontal
        title={translate.ks1Instructions.dragTheCardsToOrderTheNumbers()}
        pdfTitle={
          ordering === 'ascending'
            ? translate.ks1PDFInstructions.orderTheNumbersFromSmallestToGreatest()
            : translate.ks1PDFInstructions.orderTheNumbersFromGreatestToSmallest()
        }
        labelsPosition="bottom"
        testCorrect={correctOrder}
        items={[numA, numB, numC]}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        moveOrCopy="move"
        arrowWidth={200}
        mainPanelItemStyle={{ marginHorizontal: 50 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderObjectsAndNumbers',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
