import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from 'common/src/utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  createShapeWithSquares,
  isEqualPerimeter,
  isRectangle,
  perimeterCount
} from '../../../../utils/shapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { barModelColors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLq',
  description: 'aLq',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(2).max(6),
    width: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 8);
    const height = randomIntegerInclusive(2, 6, { constraint: x => x !== width });
    return { height, width };
  },
  Component: props => {
    const {
      question: { height, width },
      translate
    } = props;

    const answer = (height + width) * 2;
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, height],
                [width, height],
                [width, 0]
              ]}
              color={`${color}70`}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLr',
  description: 'aLr',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Draw', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(2, 5);
    const width = randomIntegerInclusive(2, 8);
    return { height, width };
  },
  Component: ({ question: { height, width }, translate }) => {
    const perimeter = 2 * (height + width);

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.selectSquaresToCreateRectangleWithPerimX(
          translate.units.numberOfCm(perimeter)
        )}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aLs',
  description: 'aLs',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Irregular', 'Compare', 'Centimetres'],
  schema: z.object({
    shape1: z.array(z.array(z.boolean())),
    shape2: z.array(z.array(z.boolean())),
    smallestOrGreatest: z.enum(['smallest', 'greatest'])
  }),
  simpleGenerator: () => {
    const { shape1, shape2 } = rejectionSample(
      () => {
        const numberOfSquares1 = randomIntegerInclusive(15, 20);
        const shape1 = createShapeWithSquares(
          5,
          5,
          numberOfSquares1,
          true,
          undefined,
          undefined,
          true,
          true
        );
        const numberOfSquares2 = randomIntegerInclusive(numberOfSquares1 - 1, numberOfSquares1 + 1);

        const shape2 = createShapeWithSquares(
          5,
          5,
          numberOfSquares2,
          true,
          undefined,
          undefined,
          true,
          true
        );
        return { shape1, shape2, numberOfSquares1, numberOfSquares2 };
      },
      val => {
        const perim1 = perimeterCount(val.shape1);
        const perim2 = perimeterCount(val.shape2);
        return val.numberOfSquares1 > val.numberOfSquares2 ? perim1 < perim2 : perim2 < perim1;
      }
    );
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { shape1, shape2, smallestOrGreatest };
  },
  Component: ({ question: { shape1, shape2, smallestOrGreatest }, translate }) => {
    const colors = getRandomSubArrayFromArray(Object.values(barModelColors), 2, {
      random: seededRandom({ shape1, shape2, smallestOrGreatest })
    }) as string[];

    const shapes = [shape1, shape2];
    const answer =
      smallestOrGreatest === 'smallest'
        ? Math.min(perimeterCount(shape1), perimeterCount(shape2))
        : Math.max(perimeterCount(shape1), perimeterCount(shape2));
    return (
      <QF11SelectImagesUpTo4
        title={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.selectShapeWithSmallestPerimeter()
            : translate.instructions.selectShapeWithGreatestPerimeter()
        }
        pdfTitle={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.circleShapeWithSmallestPerimeter()
            : translate.instructions.circleShapeWithGreatestPerimeter()
        }
        numItems={2}
        testCorrect={[answer]}
        renderItems={({ dimens }) =>
          shapes.map((shape, i) => {
            return {
              value: perimeterCount(shape),
              component: (
                <DisplayShapeOnGridWithBorder
                  givenShape={shape}
                  dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                  color={`${colors[i]}70`}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLt',
  description: 'aLt',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Irregular', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(3).max(6),
    width: z.number().int().min(3).max(8),
    x1: z.number().int().min(1).max(7),
    x2: z.number().int().min(1).max(7),
    y1: z.number().int().min(1).max(5),
    y2: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 8);
    const height = randomIntegerInclusive(3, 6, {
      constraint: x => 2 * (x + width) >= 14 && 2 * (x + width) <= 40
    });
    const x1 = randomIntegerInclusive(1, width / 2);
    const x2 = randomIntegerInclusive(x1 + 1, width - 1);
    const y1 = randomIntegerInclusive(height / 2, height - 1);
    const y2 = randomIntegerInclusive(1, y1 - 1);

    return { height, width, x1, x2, y1, y2 };
  },
  Component: props => {
    const {
      question: { height, width, x1, x2, y1, y2 },
      translate
    } = props;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const answer = (height + width) * 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.workOutPerimeterOfShape()}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, height],
                [x1, height],
                [x1, y1],
                [x2, y1],
                [x2, y2],
                [width, y2],
                [width, 0]
              ]}
              color={`${color}70`}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLu',
  description: 'aLu',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Draw', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(2, 5);
    const width = randomIntegerInclusive(2, 8);
    return { height, width };
  },
  Component: ({ question: { height, width }, translate }) => {
    const perimeter = 2 * (height + width);

    return (
      <QF24CreateShapeFromSquares
        title={`${translate.instructions.selectSquaresToCreateShapeWithPerimX(
          translate.units.numberOfCm(perimeter)
        )} ${translate.instructions.shapeShouldNotBeRectangle()}`}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && !isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aLv',
  description: 'aLv',
  keywords: ['Perimeter', 'Grid', 'Shape', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(2).max(6),
    width: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 8);
    const height = randomIntegerInclusive(2, 6, { constraint: x => x !== width });
    return { height, width };
  },
  Component: props => {
    const {
      question: { height, width },
      translate
    } = props;

    const answer = (height + width) * 2;
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, height],
                [width, height],
                [width, 0]
              ]}
              color={`${color}`}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOnAGrid',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
