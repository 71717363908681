import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  aiW,
  aiX
} from 'common/src/SchemeOfLearning/Year 5/Autumn/AdditionAndSubtraction/2AddWholeNumbersWithMoreThanFourDigits';
import {
  ai1,
  ai3
} from 'common/src/SchemeOfLearning/Year 5/Autumn/AdditionAndSubtraction/3SubtractWholeNumbersWithMoreThanFourDigits';
import { ai8 } from 'common/src/SchemeOfLearning/Year 5/Autumn/AdditionAndSubtraction/4RoundToCheckAnswers';
import { aiD } from 'common/src/SchemeOfLearning/Year 4/Autumn/AdditionAndSubtraction/9EstimateAnswers';

////
// Questions
////

const Question1 = { ...aiW, uid: 'ajy', description: 'ajy' as const };

const Question2 = { ...ai1, uid: 'ajz', description: 'ajz' as const };

const Question3 = { ...ai8, uid: 'ajA', description: 'ajA' as const };

const Question4 = { ...aiX, uid: 'ajB', description: 'ajB' as const };

const Question5 = { ...ai3, uid: 'ajC', description: 'ajC' as const };

const Question6 = { ...aiD, uid: 'ajD', description: 'ajD' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractIntegers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
