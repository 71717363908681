import { View } from 'react-native';
import { z } from 'zod';

import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { getCharacterHeadImage } from '../../../../utils/characters';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Clock, { ClockWithState } from '../../../../components/question/representations/Clock';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { QF51CompleteTheDigitalClock } from '../../../../components/question/questionFormats/QF51CompleteTheDigitalClock';
import { convert12hToSpokenString } from '../../../../utils/time';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atU',
  description: 'atU',
  keywords: ['Time', `O'clock`, 'Half past', 'Analogue', 'Digital'],
  schema: z.object({
    hourA: z.number().int().min(0).max(11),
    hourB: z.number().int().min(0).max(11),
    hourC: z.number().int().min(0).max(11),
    hourD: z.number().int().min(0).max(11),
    displayHour: z.number().int().min(0).max(11),
    isRoman: z.boolean()
  }),
  simpleGenerator: () => {
    // generate in pairs so we can have same hour but never the exact same time
    const [hourA, hourC] = randomUniqueIntegersInclusive(0, 11, 2);
    const [hourB, hourD] = randomUniqueIntegersInclusive(0, 11, 2);
    const displayHour = getRandomFromArray([hourA, hourB, hourC, hourD]);
    const isRoman = getRandomFromArray([true, false] as const);

    return {
      hourA,
      hourB,
      hourC,
      hourD,
      displayHour,
      isRoman
    };
  },
  Component: props => {
    const {
      question: { hourA, hourB, hourC, hourD, displayHour, isRoman },
      translate
    } = props;

    const displayMinutes = displayHour === hourA || displayHour === hourC ? 0 : 30;
    const answerHours = displayHour === 0 ? 12 : displayHour;
    const answer = answerHours + displayMinutes / 60;

    const items = [
      { hours: hourA === 0 ? 12 : hourA, minutes: 0 },
      { hours: hourB === 0 ? 12 : hourB, minutes: 30 },
      { hours: hourC === 0 ? 12 : hourC, minutes: 0 },
      { hours: hourD === 0 ? 12 : hourD, minutes: 30 }
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToMatchTheDigitalTimeToTheAnalogueClock()}
        pdfTitle={translate.instructions.useCardToMatchTheDigitalTimeToTheAnalogueClock()}
        sentence={`<ans/>`}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: displayHour, minutes: displayMinutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman={isRoman}
          />
        )}
        items={items.map(time => {
          return {
            value: time.hours + time.minutes / 60,
            component: (
              <MeasureView>
                {dimens => (
                  <DigitalClock
                    hours={time.hours}
                    minutes={time.minutes}
                    dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        testCorrect={[answer]}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'atV',
  description: 'atV',
  keywords: ['Time', 'Minutes to', 'Minutes past', 'Digital'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: z.number().int().min(5).max(25),
    pastOrTo: z.enum(['past', 'to'])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);
    const minutes = randomIntegerInclusiveStep(5, 25, 5, { constraint: x => x !== 15 });
    const pastOrTo = getRandomFromArray(['past', 'to'] as const);

    return {
      hours,
      minutes,
      pastOrTo
    };
  },
  Component: props => {
    const {
      question: { hours, minutes, pastOrTo },
      translate,
      displayMode
    } = props;

    const items = [translate.keywords['Minutes to'](), translate.keywords['Minutes past']()];
    const displayMinutes = pastOrTo === 'past' ? minutes : 60 - minutes;
    const displayHour = pastOrTo === 'past' ? hours : hours + 1 <= 12 ? hours + 1 : hours + 1 - 12;
    const correctAnswer =
      pastOrTo === 'past'
        ? translate.keywords['Minutes past']()
        : translate.keywords['Minutes to']();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompleteTheTimeShownOnTheDigitalClock()}
        pdfTitle={translate.instructions.useACardToCompleteTheTimeShownOnTheDigitalClock()}
        sentence={translate.answerSentences.xAnsY(
          minutes.toLocaleString(),
          displayHour.toLocaleString()
        )}
        Content={({ dimens }) => (
          <DigitalClock
            hours={hours}
            minutes={displayMinutes}
            dimens={{ height: dimens.height * 0.7, width: dimens.width * 0.6 }}
          />
        )}
        items={items}
        testCorrect={[correctAnswer]}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
        textStyle={{ fontSize: displayMode === 'digital' ? 34 : 50, fontWeight: '700' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'atW',
  description: 'atW',
  keywords: ['Time', 'To the minute', 'Minutes to', 'Minutes past', 'Digital'],
  schema: z.object({
    hour: z.number().int().min(1).max(12),
    minute: z
      .number()
      .int()
      .min(1)
      .max(59)
      .refine(val => val % 15 !== 0, 'minute cannot be 15, 30 or 45')
  }),
  simpleGenerator: () => {
    const hour = randomIntegerInclusive(1, 12);

    const minute = randomIntegerInclusive(1, 59, {
      constraint: x => x % 15 !== 0
    });
    return { hour, minute };
  },
  Component: props => {
    const {
      question: { hour, minute },
      translate
    } = props;

    const correctHourString = minute > 30 ? (hour === 12 ? 1 : hour + 1) : hour;
    const incorrectHourStringA =
      minute > 30
        ? correctHourString === 1
          ? 12
          : correctHourString - 1
        : (correctHourString + 1) % 12;

    const correctMinuteString = minute > 30 ? 60 - minute : minute;

    const statements = [
      {
        sentence:
          minute > 30
            ? translate.answerSentences.xMinutesToY(correctMinuteString, correctHourString)
            : translate.answerSentences.xMinutesPastY(correctMinuteString, correctHourString),
        isCorrect: true
      },
      {
        sentence:
          minute > 30
            ? translate.answerSentences.xMinutesPastY(correctMinuteString, correctHourString)
            : translate.answerSentences.xMinutesToY(correctMinuteString, correctHourString),
        isCorrect: false
      },
      {
        sentence: translate.answerSentences.xMinutesToY(correctMinuteString, incorrectHourStringA),
        isCorrect: false
      },
      {
        sentence: translate.answerSentences.xMinutesPastY(
          correctMinuteString,
          incorrectHourStringA
        ),
        isCorrect: false
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTimeShownOnDigitalClock()}
        pdfTitle={translate.instructions.circleTimeShownOnDigitalClock()}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.sentence)}
        numItems={4}
        Content={({ dimens }) => (
          <DigitalClock
            hours={hour}
            minutes={minute}
            dimens={{ height: dimens.height * 0.7, width: dimens.width }}
          />
        )}
        renderItems={() => {
          return shuffledStatements.map(({ sentence }) => ({
            value: sentence,
            component: <Text variant={'WRN700'}>{sentence}</Text>
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'atX',
  description: 'atX',
  keywords: ['Time', 'To the minute', 'Minutes to', 'Minutes past', 'Digital'],
  schema: z.object({
    minutes: z.number().int().min(5).max(55).multipleOf(5),
    hours: z.number().int().min(0).max(11)
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusiveStep(5, 55, 5, { constraint: x => x % 15 !== 0 });
    const hours = randomIntegerInclusive(0, 11);
    return { minutes, hours };
  },
  Component: props => {
    const {
      question: { minutes, hours },
      translate
    } = props;
    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    const answerHour = hours === 0 ? 12 : hours;
    const twentyFourHourVersion = hours === 0 ? '0'.padStart(2, '0') : hours + 12;

    const answerHours = [
      answerHour.toString().padStart(2, '0'),
      answerHour.toString(),
      twentyFourHourVersion.toString()
    ];

    const ansMins = minutes.toString().padStart(2, '0');

    return (
      <QF51CompleteTheDigitalClock
        title={translate.instructions.showTheTimeToTheDigitalClock(translatedTime)}
        testCorrect={userAnswer =>
          answerHours.includes(userAnswer[0]) && userAnswer[1] === ansMins.toString()
        }
        hours={'<ans/>'}
        minutes={'<ans/>'}
        questionHeight={600}
        customMarkSchemeAnswer={{
          answerToDisplay: [answerHour.toLocaleString(), ansMins.toLocaleString()],
          answerText: translate.markScheme.acceptBothTwelveHourAndTwentyFourHourTimes()
        }}
      />
    );
  },
  questionHeight: 600
});

const Question5 = newQuestionContent({
  uid: 'atY',
  description: 'atY',
  keywords: ['Time', 'To the minute', 'Minutes to', 'Minutes past', 'Analogue', 'Digital'],
  schema: z.object({
    number1: z.number().int().min(1).max(12),
    number2: z.number().int().min(1).max(59),
    isRoman: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 12);
    const number2 = randomIntegerInclusive(1, 59, { constraint: x => x % 5 !== 0 });

    const isRoman = getRandomFromArray([true, false] as const);

    return { number1, number2, isRoman };
  },
  Component: ({ question: { number1, number2, isRoman }, translate, displayMode }) => {
    const hourAnswer = number1 === 12 ? 0 : number1;
    const interactive = displayMode === 'digital' ? true : false;
    const showHands = displayMode === 'pdf' ? false : true;
    const initialState =
      displayMode === 'markscheme' ? { hours: hourAnswer, minutes: number2 } : undefined;
    return (
      <QF3Content
        title={translate.instructions.moveTheHandsOfClockToShowTheTime()}
        pdfTitle={translate.instructions.drawTheHandsOfClockToShowTheTime()}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around'
            }}
          >
            <DigitalClock
              hours={number1}
              minutes={number2}
              dimens={{ height: dimens.height, width: Math.min(dimens.width * 0.4, dimens.height) }}
            />
            <ClockWithState
              width={Math.min(dimens.width * 0.4, dimens.height)}
              isRoman={isRoman}
              id={'clock'}
              testCorrect={answer => answer.hours === hourAnswer && answer.minutes === number2}
              interactive={interactive}
              showHands={showHands}
              defaultState={initialState}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atZ',
  description: 'atZ',
  keywords: ['Time', 'To the minute', 'Minutes to', 'Minutes past', 'Digital'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      nameC: nameSchema,
      hour: z.number().int().min(1).max(12),
      minute: z.number().int().min(1).max(29)
    })
    .refine(val => val.hour !== val.minute, 'hour and minute must be different.')
    .refine(
      val => arrayHasNoDuplicates([val.nameA, val.nameB, val.nameC]),
      'Names must all be different.'
    ),
  simpleGenerator: () => {
    const [nameA, nameB, nameC] = getRandomUniqueNames(3);

    const hour = randomIntegerInclusive(1, 12);

    const minute = randomIntegerInclusive(1, 29, {
      constraint: x => x !== hour
    });

    return { nameA, nameB, nameC, hour, minute };
  },
  Component: props => {
    const {
      question: { nameA, nameB, nameC, hour, minute },
      translate
    } = props;

    const statements = [
      {
        name: nameA,
        sentence: translate.answerSentences.theTimeIsXMinutesPastY(minute, hour),
        isCorrect: true
      },
      {
        name: nameB,
        sentence: translate.answerSentences.theTimeIsXMinutesPastY(hour, minute),
        isCorrect: false
      },
      {
        name: nameC,
        sentence: translate.answerSentences.theTimeIsXMinutesToY(minute, hour),
        isCorrect: false
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.charactersAreTellingTheTime(nameA, nameB, nameC)}
        pdfTitle={translate.instructions.charactersAreTellingTheTimePDF(nameA, nameB, nameC)}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.sentence)}
        numItems={3}
        Content={({ dimens }) => (
          <DigitalClock
            hours={hour}
            minutes={minute}
            dimens={{ height: dimens.height * 0.7, width: dimens.width }}
          />
        )}
        renderItems={({ dimens }) => {
          return shuffledStatements.map(({ name, sentence }) => ({
            value: sentence,
            component: (
              <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <SpeechBubble
                    style={{ maxWidth: 0.55 * dimens.width }}
                    // Add negative margin to make the speech bubble appear less tall
                    textStyle={{ marginVertical: -13 }}
                    flickLocation="top-right"
                  >
                    {sentence}
                  </SpeechBubble>
                </View>

                <View>
                  {getCharacterHeadImage(name, (dimens.height * 0.8) / 3, dimens.width / 4)}
                  <Text
                    variant="WRN400"
                    style={{ textAlign: 'center', fontSize: 24, lineHeight: 30 }}
                  >
                    {name}
                  </Text>
                </View>
              </View>
            )
          }));
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadTimeOnADigitalClock',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
