import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { ScientificNotation } from 'common/src/utils/math';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numbersDoNotExchange, numbersExchange } from 'common/src/utils/exchanges';
import { numberEnum } from 'common/src/utils/zod';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aaW',
  description: 'aaW',
  keywords: ['Place value', 'Base 10', 'More', 'Less', '100'],
  schema: z
    .object({
      number: z.number().int().min(1).max(999),
      moreOrLess: z.enum(['more', 'less']),
      diff: numberEnum([1, 10, 100])
    })
    .refine(
      val =>
        val.moreOrLess === 'more'
          ? numbersDoNotExchange(val.number, val.diff)
          : numbersDoNotExchange(val.number, -val.diff),
      'number and diff must not exchange.'
    )
    .refine(
      ({ number, moreOrLess, diff }) =>
        sumDigits(moreOrLess === 'more' ? number + diff : number - diff) <= 33,
      'number must be representable with <=33 base ten counters'
    ),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const diff = getRandomFromArray([1, 10, 100] as const);
    const number =
      moreOrLess === 'more'
        ? randomIntegerInclusive(1, 999 - diff, {
            constraint: x => numbersDoNotExchange(x, diff) && sumDigits(x + diff) <= 33
          })
        : randomIntegerInclusive(diff + 1, 999, {
            constraint: x => numbersDoNotExchange(x, -diff) && sumDigits(x - diff) <= 33
          });

    return { number, moreOrLess, diff };
  },

  Component: props => {
    const {
      question: { number, moreOrLess, diff },
      translate
    } = props;
    const answer = moreOrLess === 'more' ? number + diff : number - diff;
    return (
      <QF15CreateBaseTenNumber
        title={
          moreOrLess === 'more'
            ? translate.instructions.dragBase10ToMakeNumMore(
                diff.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.dragBase10ToMakeNumLess(
                diff.toLocaleString(),
                number.toLocaleString()
              )
        }
        pdfTitle={
          moreOrLess === 'more'
            ? translate.instructions.useBase10ToMakeNumMore(
                diff.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.useBase10ToMakeNumLess(
                diff.toLocaleString(),
                number.toLocaleString()
              )
        }
        number={ScientificNotation.fromNumber(answer)}
        draggablesToShow={[2, 1, 0]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aaX',
  description: 'aaX',
  keywords: ['Place value', 'Counters', 'More', 'Less', '100'],
  schema: z.object({
    number: z.number().min(1).max(888),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z.number().refine(x => x === 1 || x === 10 || x === 100) as z.Schema<1 | 10 | 100>
  }),
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfTen = getRandomFromArray([1, 10, 100] as const);
    const number =
      lessOrMore === 'less'
        ? randomIntegerInclusive(1, 888, {
            constraint: x => {
              const powerArray = ScientificNotation.fromNumber(x).digits;
              return (
                powerArray.every(i => i < 9) &&
                x - powerOfTen > 0 &&
                numbersDoNotExchange(x, -powerOfTen)
              );
            }
          })
        : randomIntegerInclusive(1, 888, {
            constraint: x => {
              const powerArray = ScientificNotation.fromNumber(x).digits;
              return (
                powerArray.every(i => i < 9) &&
                x + powerOfTen <= 888 &&
                numbersDoNotExchange(x, powerOfTen)
              );
            }
          });

    return { number, lessOrMore, powerOfTen };
  },
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <ReadPlaceValueChart
        title={translate.instructions.herePlaceValueChart()}
        number={ScientificNotation.fromNumber(number)}
        columnsToShow={[2, 1, 0]}
        counterVariant="greyCounter"
        sentenceStyle={{ justifyContent: 'flex-start' }}
        pdfSentenceStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number.toString(), result.toString()]}
        sentence={
          lessOrMore === 'less'
            ? translate.answerSentences.whatNumberIsShownWhatIsLess(powerOfTen.toLocaleString())
            : translate.answerSentences.whatNumberIsShownWhatIsMore(powerOfTen.toLocaleString())
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aaY',
  description: 'aaY',
  keywords: ['Place value', 'More', 'Less', '100'],
  schema: z.object({
    number: z.number().min(1).max(999),
    isMore: z.boolean(),
    powerOfTen: numberEnum([1, 10, 100])
  }),
  simpleGenerator: () => {
    const isMore = getRandomBoolean();
    const powerOfTen = getRandomFromArray([1, 10, 100] as const);
    const number = randomIntegerInclusive(1, 999, {
      constraint: x =>
        numbersDoNotExchange(x, powerOfTen) && isMore ? x + powerOfTen <= 999 : x - powerOfTen >= 1
    });

    return { number, powerOfTen, isMore };
  },
  Component: ({ question: { number, powerOfTen, isMore }, translate, ...props }) => {
    const result = isMore ? number + powerOfTen : number - powerOfTen;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[result.toString()]}
        sentence={translate.instructions.oneTen100LessOrMoreThanNumberIsAns(
          powerOfTen.toLocaleString(),
          isMore ? translate.misc.more() : translate.misc.less(),
          number.toLocaleString()
        )}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aaZ',
  description: 'aaZ',
  keywords: ['Place value', 'More', 'Less', 'Exchange', '100'],
  schema: z.object({
    number: z.number().min(1).max(999),
    isMore: z.boolean(),
    powerOfTen: numberEnum([1, 10])
  }),
  simpleGenerator: () => {
    const powerOfTen = getRandomFromArray([1, 10] as const);
    const isMore = getRandomBoolean();
    const number = randomIntegerInclusive(1, 999, {
      constraint: x => (isMore ? numbersExchange(x, powerOfTen) : numbersExchange(x, -powerOfTen))
    });

    return { number, powerOfTen, isMore };
  },
  Component: ({ question: { number, powerOfTen, isMore }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[isMore ? (number + powerOfTen).toString() : (number - powerOfTen).toString()]}
        sentence={translate.instructions.oneTen100LessOrMoreThanNumberIsAns(
          powerOfTen.toLocaleString(),
          isMore ? translate.misc.more() : translate.misc.less(),
          number.toLocaleString()
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aa0',
  description: 'aa0',
  keywords: ['Place value', 'More', 'Less', '100'],
  schema: z.object({
    number: z.number().min(101).max(899),
    lessOrMore: z.enum(['less', 'more']),
    powerOfUnit: z.number().int().min(0).max(2)
  }),
  example: {
    number: 465,
    lessOrMore: 'less',
    powerOfUnit: 1
  },
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfUnit: number = randomIntegerInclusive(0, 2);
    const number = randomIntegerInclusive(101, 899);
    return { number, lessOrMore, powerOfUnit };
  },
  Component: props => {
    const {
      question: { number, lessOrMore, powerOfUnit },
      translate
    } = props;

    const power = Math.pow(10, powerOfUnit);

    const numbers = shuffle(
      [number + 100, number - 100, number + 10, number - 10, number + 1, number - 1],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={
          lessOrMore === 'less'
            ? translate.instructions.whatIsDiffLessThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.whatIsDiffMoreThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
        }
        testCorrect={numbers.filter(it =>
          lessOrMore === 'less' ? it === number - power : it === number + power
        )}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aa1',
  description: 'aa1',
  keywords: ['Place value', 'More', 'Less', '100'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(999),
      powerOfNum1Unit: z.number().int().min(0).max(2),
      number2: z.number().int().min(1).max(899),
      powerOfNum2Unit: z.number().int().min(0).max(2)
    })
    .refine(
      val => val.powerOfNum1Unit !== val.powerOfNum2Unit,
      'powerOfNum1Unit and powerOfNum2Unit should not be the same.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999);

    const powerOfNum1Unit = randomIntegerInclusive(0, 2);

    const number2 = randomIntegerInclusive(1, 899);

    // Ensure powerOfNum2Unit is not the same as powerOfNum1Unit
    const powerOfNum2Unit = rejectionSample(
      () => randomIntegerInclusive(0, 2),
      x => x !== powerOfNum1Unit
    );

    return { number1, powerOfNum1Unit, number2, powerOfNum2Unit };
  },
  Component: props => {
    const {
      question: { number1, powerOfNum1Unit, number2, powerOfNum2Unit },
      translate
    } = props;
    const power1 = Math.pow(10, powerOfNum1Unit);
    const power2 = Math.pow(10, powerOfNum2Unit);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentencesEachCardCanBeUsedMoreThanOnce()}
        items={[1, 10, 100]}
        sentences={[
          `${translate.answerSentences.numIs(
            number1.toLocaleString()
          )} <ans/> ${translate.answerSentences.moreThan((number1 - power1).toLocaleString())}`,
          `${translate.answerSentences.numIs(
            number2.toLocaleString()
          )} <ans/> ${translate.answerSentences.lessThan((number2 + power2).toLocaleString())}`
        ]}
        testCorrect={[[power1], [power2]]}
        moveOrCopy="copy"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Find1To100MoreOrLess',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
