import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { arrayHasNoDuplicates, filledArray, range } from 'common/src/utils/collections';
import {
  BarModelInteractive,
  BarModelInteractiveWithState,
  shadedAtLeastOneCell
} from '../../../../components/question/representations/BarModelInteractive';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { barModelColors, barModelColorsArray, BarModelColorsKey } from '../../../../theme/colors';
import TextStructure from '../../../../components/molecules/TextStructure';
import { compareFractions, isEquivalentFraction } from '../../../../utils/fractions';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { LocalizedString } from 'typesafe-i18n';
import { trueCount } from '../../../../utils/shapes';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1v2 = newQuestionContent({
  uid: 'aIC2',
  description: 'aIC',
  keywords: ['Equivalent', 'Fractions', 'Bar model'],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(6),
      denominatorB: z.number().int().min(4).max(12)
    })
    .refine(
      val => val.denominatorB % val.denominatorA === 0,
      'denominatorB must be a multiple of denominatorA'
    ),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 6);
    const denominatorB = randomIntegerInclusive(4, 12, {
      constraint: x => x % denominatorA === 0 && x !== denominatorA
    });
    return { denominatorA, denominatorB };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate,
      displayMode
    } = props;

    const numerator = denominatorB / denominatorA;

    return (
      <QF3Content
        title={translate.instructions.tapToShadeBarModelsToShowEquivalentFractions()}
        pdfTitle={translate.instructions.useBarModelsToShowEquivalentFractions()}
        inputType="numpad"
        Content={({ dimens: { width } }) => (
          <View>
            <View style={{ paddingBottom: 32 }}>
              <BarModelInteractiveWithState
                id="barmodel1"
                numberOfRows={1}
                numberOfCols={denominatorA}
                tableHeight={100}
                tableWidth={width}
                testComplete={shadedAtLeastOneCell}
                testCorrect={userAnswer => trueCount(userAnswer) === 1}
                defaultState={displayMode === 'markscheme' ? [filledArray(true, 1)] : undefined}
              />
              <BarModelInteractiveWithState
                id="barmodel2"
                numberOfRows={1}
                numberOfCols={denominatorB}
                tableHeight={100}
                tableWidth={width}
                testComplete={shadedAtLeastOneCell}
                testCorrect={userAnswer => trueCount(userAnswer) === numerator}
                style={{ paddingTop: 12 }}
                defaultState={
                  displayMode === 'markscheme' ? [filledArray(true, numerator)] : undefined
                }
              />
            </View>
            <TextStructure
              sentence={`<frac n='1' d='${denominatorA}' /> = <frac n='${numerator}' d='${denominatorB}' />`}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          </View>
        )}
      />
    );
  }
});

const Question1 = newQuestionContent({
  uid: 'aIC',
  description: 'aIC',
  keywords: [
    'Bar model',
    'Number line',
    'Equivalent',
    'Fraction',
    'Numerator',
    'Denominator',
    'Parts',
    'Whole'
  ],
  schema: z
    .object({
      steps: z.number().int().min(2).max(5),
      multiplier: z.number().int().min(2).max(5),
      givenNumerator: z.number().int().min(1).max(4)
    })
    .refine(val => val.givenNumerator < val.steps, 'givenNumerator must be less than steps.'),
  simpleGenerator: () => {
    const steps = randomIntegerInclusive(2, 5);

    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => x * steps <= 10
    });

    const givenNumerator = randomIntegerInclusive(1, steps - 1);

    return { steps, multiplier, givenNumerator };
  },
  Component: props => {
    const {
      question: { steps, multiplier, givenNumerator },
      translate,
      displayMode
    } = props;

    const barModelNumerator = givenNumerator * multiplier;

    const barModelDenominator = steps * multiplier;

    const filterSelectedCells = (array: boolean[][]) => {
      return array[0].filter(col => col);
    };

    const numberLineFractions = range(1, steps - 1).map(val => `<frac n='${val}' d='${steps}' />`);

    const tickValues = [(0).toLocaleString(), ...numberLineFractions, (1).toLocaleString()];

    // Needed to align with NumberLine correctly in different display modes:
    const barModelWidthReduction = displayMode === 'digital' ? 78 : 178;

    return (
      <QF3InteractiveContent
        title={translate.instructions.shadeFractionOfTheBarModel(
          `<frac n='${barModelNumerator}' d='${barModelDenominator}' />`
        )}
        initialState={
          displayMode === 'markscheme'
            ? [
                filledArray(true, barModelNumerator),
                filledArray(false, barModelDenominator - barModelNumerator)
              ]
            : [filledArray(false, barModelDenominator)]
        }
        testComplete={answer => filterSelectedCells(answer).length > 0}
        testCorrect={userAnswer => {
          const selectedCells = filterSelectedCells(userAnswer);
          return selectedCells.length === barModelNumerator;
        }}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View
            style={{ alignItems: displayMode === 'digital' ? 'center' : 'flex-start', rowGap: 16 }}
          >
            <BarModelInteractive
              style={displayMode !== 'digital' && { left: 38 }}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              numberOfRows={1}
              numberOfCols={barModelDenominator}
              tableHeight={dimens.height / 3}
              tableWidth={dimens.width - barModelWidthReduction}
            />
            <NumberLine
              tickValues={tickValues}
              dimens={{ width: dimens.width, height: dimens.height / 2 }}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyNumPartsOfTheBarModelCanBeShaded(barModelNumerator)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aID',
  description: 'aID',
  keywords: ['Bar model', 'Equivalent', 'Fraction', 'Numerator', 'Denominator', 'Parts', 'Whole'],
  schema: z
    .object({
      givenDenominator: z.number().int().min(2).max(5),
      givenNumerator: z.number().int().min(1).max(4),
      multiplier: z.number().int().min(2).max(5),
      answerBox: z.enum([
        'given numerator',
        'given denominator',
        'multiplied numerator',
        'multiplied denominator'
      ])
    })
    .refine(
      val => val.givenNumerator < val.givenDenominator,
      'givenNumerator must be less than givenDenominator.'
    ),
  simpleGenerator: () => {
    const givenDenominator = randomIntegerInclusive(2, 5);

    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => x * givenDenominator <= 12
    });

    const givenNumerator = randomIntegerInclusive(1, givenDenominator - 1);

    const answerBox = getRandomFromArray([
      'given numerator',
      'given denominator',
      'multiplied numerator',
      'multiplied denominator'
    ] as const);

    return { givenDenominator, multiplier, givenNumerator, answerBox };
  },
  Component: ({
    question: { givenDenominator, multiplier, givenNumerator, answerBox },
    translate
  }) => {
    const multipliedNumerator = givenNumerator * multiplier;

    const multipliedDenominator = givenDenominator * multiplier;

    const numbers = [
      range(1, givenDenominator).map(() => multipliedNumerator),
      range(1, multipliedDenominator).map(() => givenNumerator)
    ];
    const strings = [
      range(1, givenDenominator).map(() => ''),
      range(1, multipliedDenominator).map(() => '')
    ];

    const sentence = (() => {
      switch (answerBox) {
        case 'given numerator':
          return `<frac n='${multipliedNumerator}' d='${multipliedDenominator}'/> = <frac nAns='' d='${givenDenominator}'/>`;
        case 'given denominator':
          return `<frac n='${multipliedNumerator}' d='${multipliedDenominator}'/> = <frac n='${givenNumerator}' dAns=''/>`;
        case 'multiplied numerator':
          return `<frac n='${givenNumerator}' d='${givenDenominator}'/> = <frac nAns='' d='${multipliedDenominator}'/>`;
        case 'multiplied denominator':
          return `<frac n='${givenNumerator}' d='${givenDenominator}'/> = <frac n='${multipliedNumerator}' dAns=''/>`;
      }
    })();

    const answer = (() => {
      switch (answerBox) {
        case 'given numerator':
          return givenNumerator;
        case 'given denominator':
          return givenDenominator;
        case 'multiplied numerator':
          return multipliedNumerator;
        case 'multiplied denominator':
          return multipliedDenominator;
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeEquivalentFraction()}
        testCorrect={[answer.toString()]}
        sentence={sentence}
        fractionContainerStyle={{ height: 96 }} // Required to align given fraction with answer box fraction.
        Content={({ dimens }) => (
          <BarModel
            total={givenDenominator * multipliedNumerator}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            sameRowColor
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIE',
  description: 'aIE',
  keywords: ['Bar model', 'Fraction wall', 'Equivalent', 'Fractions', 'Numerator', 'Denominator'],
  schema: z
    .object({
      givenDenominator: z.number().int().min(2).max(5),
      givenNumerator: z.number().int().min(1).max(4),
      multiplier: z.number().int().min(2).max(5),
      answerNumerator: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.givenNumerator < val.givenDenominator,
      'givenNumerator must be less than givenDenominator.'
    ),
  simpleGenerator: () => {
    const givenDenominator = randomIntegerInclusive(2, 5);

    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => x * givenDenominator <= 12
    });

    const givenNumerator = randomIntegerInclusive(1, givenDenominator - 1);

    // Weighting for answerNumerator - 4:4:1 (True:False:Random)
    const answerNumeratorWeighting = randomIntegerInclusive(1, 9);

    const answerNumerator = (() => {
      // True condition:
      if (answerNumeratorWeighting < 5) {
        return givenNumerator * multiplier;
      } else if (answerNumeratorWeighting < 9) {
        // False condition:
        return givenNumerator;
      } else {
        // Random number selected:
        return randomIntegerInclusive(1, givenDenominator * multiplier - 1);
      }
    })();

    return { givenDenominator, multiplier, givenNumerator, answerNumerator };
  },
  Component: ({
    question: { givenDenominator, multiplier, givenNumerator, answerNumerator },
    translate,
    displayMode
  }) => {
    const multipliedNumerator = givenNumerator * multiplier;

    const multipliedDenominator = givenDenominator * multiplier;

    const numbers = [
      range(1, givenDenominator).map(() => multipliedNumerator),
      range(1, multipliedDenominator).map(() => givenNumerator)
    ];
    const strings = [
      range(1, givenDenominator).map(() => `<frac n='1' d='${givenDenominator}' />`),
      range(1, multipliedDenominator).map(() => `<frac n='1' d='${multipliedDenominator}' />`)
    ];

    const answerOptions = [translate.misc.is(), translate.misc['is not']()];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardCompleteStatement()}
        pdfTitle={translate.instructions.useCardCompleteStatement()}
        pdfLayout="itemsBottom"
        actionPanelVariant="bottom"
        itemVariant="rectangle"
        items={answerOptions}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        Content={({ dimens }) => (
          <BarModel
            total={givenDenominator * multipliedNumerator}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            sameRowColor
            // Reduce margin of the dividing line so that numerators/denominators are not brushing against container edges:
            fractionDividerStyle={{ marginVertical: 4 }}
          />
        )}
        sentence={translate.answerSentences.fracAnsEquivalentToFrac(
          `<frac n='${givenNumerator}' d='${givenDenominator}' />`,
          `<frac n='${answerNumerator}' d='${multipliedDenominator}' />`
        )}
        testCorrect={
          answerNumerator === multipliedNumerator
            ? [translate.misc.is()]
            : [translate.misc['is not']()]
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aIF',
  description: 'aIF',
  keywords: ['Bar model', 'Fraction wall', 'Equivalent', 'Fractions', 'Numerator', 'Denominator'],
  schema: z
    .object({
      givenBarModelParts: z.number().int().min(2).max(5),
      givenBarModelShaded: z.number().int().min(1).max(4),
      barModelAParts: z.number().int().min(4).max(10),
      barModelAShaded: z.number().int().min(2).max(8),
      barModelBParts: z.number().int().min(2).max(10),
      barModelBShaded: z.number().int().min(1).max(10)
    })
    .refine(
      val => val.givenBarModelShaded < val.givenBarModelParts,
      'givenBarModelShaded must be less than givenBarModelParts.'
    )
    .refine(
      val => val.barModelAShaded < val.barModelAParts,
      'barModelAShaded must be less than barModelAParts.'
    )
    .refine(
      val => val.barModelBShaded <= val.barModelBParts,
      `barModelBShadeds must be less than or equal to barModelBParts.' .`
    )
    .refine(
      val =>
        compareFractions(
          [val.givenBarModelShaded, val.givenBarModelParts],
          [val.barModelAShaded, val.barModelAParts]
        ),
      'barModelA and givenBarModel should represent equivalent fractions.'
    ),
  simpleGenerator: () => {
    // givenBarModel:
    const givenBarModelParts = randomIntegerInclusive(2, 5);

    const givenBarModelShaded = randomIntegerInclusive(1, givenBarModelParts - 1);

    // barModelA - equivalent fraction:
    const barModelAParts = randomIntegerInclusive(4, 10, {
      constraint: x => x % givenBarModelParts === 0 && x !== givenBarModelParts
    });

    const barModelAShaded = givenBarModelShaded * (barModelAParts / givenBarModelParts);

    // barModelB - unequivalent fraction:
    const barModelBParts = randomIntegerInclusive(4, 10, {
      constraint: x => x % givenBarModelParts !== 0
    });

    const barModelBShaded = randomIntegerInclusive(1, barModelBParts);

    return {
      givenBarModelParts,
      givenBarModelShaded,
      barModelAParts,
      barModelAShaded,
      barModelBParts,
      barModelBShaded
    };
  },
  Component: props => {
    const {
      question: {
        givenBarModelParts,
        givenBarModelShaded,
        barModelAParts,
        barModelAShaded,
        barModelBParts,
        barModelBShaded
      },
      translate,
      displayMode
    } = props;

    const translateToParts: { [key: string]: LocalizedString } = {
      '2': translate.fractions.halves(givenBarModelParts),
      '3': translate.fractions.thirds(givenBarModelParts),
      '4': translate.fractions.quarters(givenBarModelParts),
      '5': translate.fractions.fifths(givenBarModelParts)
    };

    const answerBarModelColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    }) as BarModelColorsKey;

    const items = shuffle(
      [
        {
          value: 'A',
          parts: barModelAParts,
          shaded: barModelAShaded
        },
        {
          value: 'B',
          parts: barModelBParts,
          shaded: barModelBShaded
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theBarModelIsSplitInto(translateToParts[givenBarModelParts])}
        testCorrect={['A']}
        numItems={2}
        itemLayout="column"
        itemStyle={{ flex: 1, height: 120 }}
        Content={({ dimens }) => (
          <View style={{ display: 'flex', width: dimens.width, height: (dimens.height / 3) * 2 }}>
            <ShadedFractionBarModel
              totalSubSections={givenBarModelParts}
              // This width aligns this bar model with those in the selectables:
              width={dimens.width - 96}
              coloredSections={[]}
              height={displayMode === 'digital' ? 50 : 100}
            />
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50
              }}
              sentence={
                displayMode === 'digital'
                  ? translate.instructions.selectTheBarModelThatShowsAFractionEquivalentTo(
                      `<frac n='${givenBarModelShaded}' d='${givenBarModelParts}' />`
                    )
                  : translate.instructions.circleTheBarModelThatShowsAFractionEquivalentTo(
                      `<frac n='${givenBarModelShaded}' d='${givenBarModelParts}' />`
                    )
              }
            />
          </View>
        )}
        renderItems={({ dimens }) => {
          return items.map(({ parts, shaded, value }) => ({
            component: (
              <ShadedFractionBarModel
                totalSubSections={parts}
                width={dimens.width * 2}
                color={barModelColors[answerBarModelColor]}
                coloredSections={range(0, shaded - 1)}
                height={displayMode === 'digital' ? 50 : 100}
              />
            ),
            value
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aIG',
  description: 'aIG',
  keywords: ['Bar model', 'Fraction wall', 'Equivalent', 'Fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    givenDenominator: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const givenDenominator = randomIntegerInclusive(2, 5);
    return { givenDenominator };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { givenDenominator },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const colors = [
      barModelColors[numeratorColor as BarModelColorsKey],
      ...filledArray('white', givenDenominator - 1)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theBarModelShowsX(`<frac n='1' d='${givenDenominator}' />`)}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [1, givenDenominator]) &&
          compareFractions([userAnswer[2], userAnswer[3]], [1, givenDenominator]) &&
          compareFractions([userAnswer[4], userAnswer[5]], [1, givenDenominator]) &&
          // If any denominators are the same, either one of the fractions is wrong, or some fractions are identical.
          // Answers need parsing to integers to check equivalence to givenDenominator:
          arrayHasNoDuplicates([
            givenDenominator,
            parseInt(userAnswer[1]),
            parseInt(userAnswer[3]),
            parseInt(userAnswer[5])
          ])
        }
        pdfDirection="column"
        questionHeight={800}
        inputMaxCharacters={3}
        sentence={`<frac nAns='' dAns='' /> <frac nAns='' dAns='' /> <frac nAns='' dAns='' />`}
        sentenceStyle={{ justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'flex-start' }}>
            <ShadedFractionBarModel
              totalSubSections={givenDenominator}
              width={dimens.width}
              customColorMap={colors}
            />
            <TextStructure
              sentence={translate.answerSentences.giveXEquivalentFractionsToFraction(
                3,
                `<frac n='1' d='${givenDenominator}' />`
              )}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aIH',
  description: 'aIH',
  keywords: ['Bar model', 'Equivalent', 'Fractions', 'Numerator', 'Denominator', 'Sort'],
  schema: z
    .object({
      numeratorA: z.number().int().min(1).max(4),
      denominatorA: z.number().int().min(2).max(5),
      numeratorB: z.number().int().min(1).max(4),
      denominatorB: z.number().int().min(2).max(5),
      equivalentA1Multiplier: z.number().int().min(2).max(6),
      equivalentA2Multiplier: z.number().int().min(2).max(6),
      equivalentB1Multiplier: z.number().int().min(2).max(6),
      equivalentB2Multiplier: z.number().int().min(2).max(6),
      equivalentAOrB1Multiplier: z.number().int().min(2).max(6),
      equivalentAOrB1: z.enum(['A', 'B']),
      equivalentAOrB2Multiplier: z.number().int().min(2).max(6),
      equivalentAOrB2: z.enum(['A', 'B'])
    })
    .refine(
      val => val.denominatorA !== val.denominatorB || val.numeratorA !== val.numeratorB,
      'Fractions A and B must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const { numeratorA, denominatorA, numeratorB, denominatorB } = rejectionSample(
      () => {
        // Fraction A:
        const denominatorA = randomIntegerInclusive(2, 5);
        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

        // Fraction B:
        // denominatorA and denominatorB cannot both be 2 - not enough valid options to make these different fractions.
        const denominatorB = randomIntegerInclusive(denominatorA === 2 ? 3 : 2, 5);

        // Ensure numeratorA and numeratorB are different if denominatorA and denominatorB are the same:
        const numeratorB =
          denominatorA === denominatorB
            ? randomIntegerInclusive(1, denominatorB - 1, {
                constraint: x => {
                  return x !== numeratorA;
                }
              })
            : randomIntegerInclusive(1, denominatorB - 1);

        return {
          numeratorA,
          denominatorA,
          numeratorB,
          denominatorB
        };
      },
      ({ numeratorA, denominatorA, numeratorB, denominatorB }) =>
        !isEquivalentFraction(numeratorA, denominatorA, numeratorB, denominatorB)
    );

    // Equivalent fractions for A:
    const [equivalentA1Multiplier, equivalentA2Multiplier] = randomUniqueIntegersInclusive(2, 6, 2);

    // Equivalent fractions for B:
    const [equivalentB1Multiplier, equivalentB2Multiplier] = randomUniqueIntegersInclusive(2, 6, 2);

    // Equivalent fraction 1 for either A or B:
    const equivalentAOrB1 = getRandomFromArray(['A', 'B'] as const);
    const equivalentAOrB1Multiplier = randomIntegerInclusive(2, 6, {
      constraint: x =>
        // Ensure this multiplier is unique amongst the other multipliers it is grouped with, to avoid duplicate fractions:
        equivalentAOrB1 === 'A'
          ? arrayHasNoDuplicates([x, equivalentA1Multiplier, equivalentA2Multiplier])
          : arrayHasNoDuplicates([x, equivalentB1Multiplier, equivalentB2Multiplier])
    });

    const fractionMultipliersA =
      equivalentAOrB1 === 'A'
        ? [equivalentA1Multiplier, equivalentA2Multiplier, equivalentAOrB1Multiplier]
        : [equivalentA1Multiplier, equivalentA2Multiplier];

    const fractionMultipliersB =
      equivalentAOrB1 === 'B'
        ? [equivalentB1Multiplier, equivalentB2Multiplier, equivalentAOrB1Multiplier]
        : [equivalentB1Multiplier, equivalentB2Multiplier];

    // Equivalent fraction 2 for either A or B:
    const equivalentAOrB2 = getRandomFromArray(['A', 'B'] as const);
    const equivalentAOrB2Multiplier = randomIntegerInclusive(2, 6, {
      constraint: x =>
        // Ensure this multiplier is unique amongst the other multipliers it is grouped with, to avoid duplicate fractions:
        equivalentAOrB2 === 'A'
          ? arrayHasNoDuplicates([x, ...fractionMultipliersA])
          : arrayHasNoDuplicates([x, ...fractionMultipliersB])
    });

    return {
      numeratorA,
      denominatorA,
      numeratorB,
      denominatorB,
      equivalentA1Multiplier,
      equivalentA2Multiplier,
      equivalentB1Multiplier,
      equivalentB2Multiplier,
      equivalentAOrB1,
      equivalentAOrB1Multiplier,
      equivalentAOrB2,
      equivalentAOrB2Multiplier
    };
  },
  Component: props => {
    const {
      question: {
        numeratorA,
        denominatorA,
        numeratorB,
        denominatorB,
        equivalentA1Multiplier,
        equivalentA2Multiplier,
        equivalentB1Multiplier,
        equivalentB2Multiplier,
        equivalentAOrB1,
        equivalentAOrB1Multiplier,
        equivalentAOrB2,
        equivalentAOrB2Multiplier
      },
      translate,
      displayMode
    } = props;

    const [fractionASet, fractionBSet]: [string[], string[]] = [[], []];
    const arrayOfCorrectAnswers = [fractionASet, fractionBSet];

    const items = shuffle(
      [
        {
          string: `<frac n='${numeratorA * equivalentA1Multiplier}' d='${
            denominatorA * equivalentA1Multiplier
          }' />`,
          aOrB: 'A'
        },
        {
          string: `<frac n='${numeratorA * equivalentA2Multiplier}' d='${
            denominatorA * equivalentA2Multiplier
          }' />`,
          aOrB: 'A'
        },
        {
          string: `<frac n='${numeratorB * equivalentB1Multiplier}' d='${
            denominatorB * equivalentB1Multiplier
          }' />`,
          aOrB: 'B'
        },
        {
          string: `<frac n='${numeratorB * equivalentB2Multiplier}' d='${
            denominatorB * equivalentB2Multiplier
          }' />`,
          aOrB: 'B'
        },
        {
          string: `<frac n='${
            equivalentAOrB1 === 'A'
              ? equivalentAOrB1Multiplier * numeratorA
              : equivalentAOrB1Multiplier * numeratorB
          }' d='${
            equivalentAOrB1 === 'A'
              ? equivalentAOrB1Multiplier * denominatorA
              : equivalentAOrB1Multiplier * denominatorB
          }' />`,
          aOrB: equivalentAOrB1
        },
        {
          string: `<frac n='${
            equivalentAOrB2 === 'A'
              ? equivalentAOrB2Multiplier * numeratorA
              : equivalentAOrB2Multiplier * numeratorB
          }' d='${
            equivalentAOrB2 === 'A'
              ? equivalentAOrB2Multiplier * denominatorA
              : equivalentAOrB2Multiplier * denominatorB
          }' />`,
          aOrB: equivalentAOrB2
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Organize the equations into their respective Sets:
    items.forEach(item =>
      item.aOrB === 'A' ? fractionASet.push(item.string) : fractionBSet.push(item.string)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortEquivalentFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortEquivalentFractionsIntoTable()}
        zoneNames={[
          translate.tableHeaders.EquivalentToX(`<frac n='${numeratorA}' d='${denominatorA}' />`),
          translate.tableHeaders.EquivalentToX(`<frac n='${numeratorB}' d='${denominatorB}' />`)
        ]}
        items={items.map(({ string }) => {
          return {
            component: (
              <TextStructure
                sentence={string}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value: string
          };
        })}
        testCorrect={arrayOfCorrectAnswers}
        pdfItemVariant="pdfSquare"
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAsBarModels',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
