import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { ADD, MULT } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { colors } from '../../../../theme/colors';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGu',
  description: 'aGu',
  keywords: ['True', 'False', 'Select', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    clothingA: z.enum(['Jumpers', 'Shirts', 'T-shirts']),
    clothingAAmount: z.number().int().min(2).max(4),
    clothingB: z.enum(['Pairs of shorts', 'Pairs of trousers']),
    clothingBAmount: z.number().int().min(2).max(4),
    totalOutfits: z.enum([
      'correct',
      'correct + 1',
      'correct - 1',
      'total clothing + 1',
      'total clothing - 1'
    ])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const clothingA = getRandomFromArray(['Jumpers', 'Shirts', 'T-shirts'] as const);

    const clothingAAmount = randomIntegerInclusive(2, 4);

    const clothingB = getRandomFromArray(['Pairs of shorts', 'Pairs of trousers'] as const);

    const clothingBAmount = randomIntegerInclusive(2, 4);

    const totalOutfits = getRandomFromArrayWithWeights(
      [
        'correct',
        'correct + 1',
        'correct - 1',
        'total clothing + 1',
        'total clothing - 1'
      ] as const,
      [5, 1.25, 1.25, 1.25, 1.25]
    );

    return {
      name,
      clothingA,
      clothingAAmount,
      clothingB,
      clothingBAmount,
      totalOutfits
    };
  },
  Component: props => {
    const {
      question: { name, clothingA, clothingAAmount, clothingB, clothingBAmount, totalOutfits },
      translate
    } = props;

    const totalCombinations = clothingAAmount * clothingBAmount;

    const totalClothing = clothingAAmount + clothingBAmount;

    const totalOutfitsAmount = (() => {
      switch (totalOutfits) {
        case 'correct':
          return totalCombinations;
        case 'correct + 1':
          return totalCombinations + 1;
        case 'correct - 1':
          return totalCombinations - 1;
        case 'total clothing + 1':
          return totalClothing + 1;
        case 'total clothing - 1':
          return totalClothing - 1;
      }
    })();

    const clothingAString = translate.clothing[clothingA](clothingAAmount);

    const clothingBString = translate.clothing[clothingB](clothingBAmount);

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalse()}
        pdfTitle={translate.instructions.isStatementTrueOrFalsePDF()}
        // Some cases for totalOutfits can resolve to the correct number even if 'correct' is not selected.
        // This ensures marking is correct regardless:
        correctAnswer={totalOutfitsAmount === totalCombinations}
        content={
          <View>
            <Text variant="WRN400">
              {translate.instructions.characterHasNumXAndNumY(
                name,
                clothingAAmount,
                clothingAString,
                clothingBAmount,
                clothingBString
              )}
            </Text>
            <Text variant="WRN400">
              {translate.instructions.characterCanMakeATotalOfNumDiffOutfits(
                name,
                totalOutfitsAmount
              )}
            </Text>
          </View>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGv',
  description: 'aGv',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    fruits: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Red apple', 'Green apple', 'Strawberry']))
      .min(2)
      .max(6)
      .refine(val => arrayHasNoDuplicates(val), 'All fruits must be different.'),
    drinks: z
      .array(z.enum(['Orange juice', 'Apple juice', 'Water', 'Milk', 'Milkshake', 'Fizzy drink']))
      .min(2)
      .max(6)
      .refine(val => arrayHasNoDuplicates(val), 'All drinks must be different.')
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const fruitAmount = randomIntegerInclusive(2, 6);

    const fruits = getRandomSubArrayFromArray(
      ['Banana', 'Pear', 'Orange', 'Red apple', 'Green apple', 'Strawberry'] as const,
      fruitAmount
    );

    // fruitAmount and drinkAmount cannot both be 2:
    const drinkAmount = randomIntegerInclusive(fruitAmount === 2 ? 3 : 2, 6);

    const drinks = getRandomSubArrayFromArray(
      ['Orange juice', 'Apple juice', 'Water', 'Milk', 'Milkshake', 'Fizzy drink'] as const,
      drinkAmount
    );

    return {
      name,
      fruits,
      drinks
    };
  },
  Component: props => {
    const {
      question: { name, fruits, drinks },
      translate,
      displayMode
    } = props;

    const fruitAmount = fruits.length;

    const drinkAmount = drinks.length;

    const fruitSvgPaths = {
      Banana: 'Array_objects/Banana',
      Pear: 'Array_objects/Pear',
      Orange: 'Array_objects/Orange',
      'Red apple': 'Array_objects/AppleRed',
      'Green apple': 'Array_objects/AppleGreen',
      Strawberry: 'Array_objects/Strawberry'
    };

    const drinkSvgPaths = {
      'Orange juice': 'Orange_juice_carton',
      'Apple juice': 'Apple_juice_carton',
      Water: 'Water_bottle',
      Milk: 'Milk_Carton',
      Milkshake: 'Milkshake',
      'Fizzy drink': 'Can_of_fizzy_drink'
    };

    const items = shuffle(
      [`${fruitAmount} ${ADD} ${drinkAmount}`, `${fruitAmount} ${MULT} ${drinkAmount}`],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterChoosesAPieceOfFruitAndADrink(name)}
        testCorrect={[`${fruitAmount} ${MULT} ${drinkAmount}`]}
        numItems={2}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={[
                { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }
              ]}
            >
              {range(1, fruitAmount).map((num, index) => (
                <AssetSvg
                  key={num}
                  name={fruitSvgPaths[fruits[index]] as SvgName}
                  height={dimens.height / 4}
                />
              ))}
              {range(1, drinkAmount).map((num, index) => (
                <AssetSvg
                  key={num}
                  name={drinkSvgPaths[drinks[index]] as SvgName}
                  height={dimens.height / 4}
                />
              ))}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode !== 'digital'
                ? translate.instructions.whichCalcFindHowManyPossCombosThereArePDF()
                : translate.instructions.whichCalcFindHowManyPossCombosThereAre()}
            </Text>
          </View>
        )}
        renderItems={items.map(string => ({
          value: string,
          component: <Text variant="WRN700">{string.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGw',
  description: 'aGw',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    snacks: z
      .array(z.enum(['Apple', 'Cookie', 'Crisps', 'Donut', 'Muffin']))
      .min(2)
      .max(5)
      .refine(val => arrayHasNoDuplicates(val), 'All snacks must be different.'),
    drinks: z
      .array(z.enum(['Apple juice', 'Fizzy drink', 'Milk', 'Orange juice', 'Water']))
      .min(2)
      .max(5)
      .refine(val => arrayHasNoDuplicates(val), 'All drinks must be different.')
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const snackAmount = randomIntegerInclusive(2, 5);

    const snacks = getRandomSubArrayFromArray(
      ['Apple', 'Cookie', 'Crisps', 'Donut', 'Muffin'] as const,
      snackAmount
    );

    const drinkAmount = randomIntegerInclusive(2, 5);

    const drinks = getRandomSubArrayFromArray(
      ['Apple juice', 'Fizzy drink', 'Milk', 'Orange juice', 'Water'] as const,
      drinkAmount
    );

    return {
      name,
      snacks,
      drinks
    };
  },
  Component: props => {
    const {
      question: { name, snacks, drinks },
      translate
    } = props;

    const snackAmount = snacks.length;

    const drinkAmount = drinks.length;

    const snackSvgPaths = {
      Apple: getRandomFromArray(['Array_objects/AppleGreen', 'Array_objects/AppleRed'], {
        random: seededRandom(props.question)
      }),
      Cookie: 'Cookie_biscuit/Cookie_biscuit_5',
      Crisps: 'Tube_of_crisps',
      Donut: getRandomFromArray(
        [
          'Donuts_individual/Donut_1',
          'Donuts_individual/Donut_2',
          'Donuts_individual/Donut_3',
          'Donuts_individual/Donut_4',
          'Donuts_individual/Donut_5',
          'Donuts_individual/Donut_6'
        ],
        {
          random: seededRandom(props.question)
        }
      ),
      Muffin: getRandomFromArray(
        [
          'Muffins_individually/Muffin_1',
          'Muffins_individually/Muffin_2',
          'Muffins_individually/Muffin_3',
          'Muffins_individually/Muffin_4',
          'Muffins_individually/Muffin_5',
          'Muffins_individually/Muffin_6',
          'Muffins_individually/Muffin_7'
        ],
        {
          random: seededRandom(props.question)
        }
      )
    };

    const drinkSvgPaths = {
      'Apple juice': 'Apple_juice_carton',
      'Orange juice': 'Orange_juice_carton',
      'Fizzy drink': 'Can_of_fizzy_drink',
      Milk: 'Milk_Carton',
      Water: 'Water_bottle'
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterIsChoosingASnackAndADrinkHowManyPossCombos(name)}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(snackAmount * drinkAmount).toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-around' }]}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 28 }}>{translate.food.Snacks(snackAmount)}</Text>
              <ContentBox
                containerStyle={[
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: dimens.width / 2.5
                  }
                ]}
              >
                {range(1, snackAmount).map((num, index) => (
                  <AssetSvg
                    key={num}
                    name={snackSvgPaths[snacks[index]] as SvgName}
                    width={dimens.width / 6}
                    height={dimens.height / 5}
                  />
                ))}
              </ContentBox>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 28 }}>{translate.drink.Drinks(drinkAmount)}</Text>
              <ContentBox
                containerStyle={[
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: dimens.width / 2.5
                  }
                ]}
              >
                {range(1, drinkAmount).map((num, index) => (
                  <AssetSvg
                    key={num}
                    name={drinkSvgPaths[drinks[index]] as SvgName}
                    width={dimens.width / 6}
                    height={dimens.height / 5}
                  />
                ))}
              </ContentBox>
            </View>
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aGx',
  description: 'aGx',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    shapes: z
      .array(z.enum(['Circle', 'Triangle', 'Square', 'Pentagon', 'Hexagon']))
      .min(2)
      .max(5)
      .refine(val => arrayHasNoDuplicates(val), 'All shapes must be different.'),
    digits: z
      .array(z.number().int().min(0).max(9))
      .min(2)
      .max(5)
      .refine(val => arrayHasNoDuplicates(val), 'All digits must be different.')
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const shapeAmount = randomIntegerInclusive(2, 5);

    const shapes = getRandomSubArrayFromArray(
      ['Circle', 'Triangle', 'Square', 'Pentagon', 'Hexagon'] as const,
      shapeAmount
    );

    const digitAmount = randomIntegerInclusive(2, 5);

    const digits = getRandomSubArrayFromArray(range(0, 9), digitAmount);

    return {
      name,
      shapes,
      digits
    };
  },
  Component: props => {
    const {
      question: { name, shapes, digits },
      translate
    } = props;

    const shapeAmount = shapes.length;

    const digitAmount = digits.length;

    const shapeSvgPaths = {
      Circle: 'Circles/circle_blue',
      Triangle: 'Equilateral_triangles/triangle_equal_green',
      Square: 'Square/square_pink',
      Pentagon: 'Pentagons/pentagon_purple',
      Hexagon: 'Hexagons/hexagon_yellow'
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterIsChoosingAShapeCardAndADigitCardHowManyPossCombos(
          name
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(shapeAmount * digitAmount).toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}
            >
              {range(1, shapeAmount).map((num, index) => (
                <ContentBox
                  key={num}
                  containerStyle={{
                    backgroundColor: colors.white,
                    width: dimens.width / 6,
                    height: dimens.height / 3,
                    justifyContent: 'center'
                  }}
                >
                  <AssetSvg
                    name={shapeSvgPaths[shapes[index]] as SvgName}
                    width={dimens.width / 7}
                    height={dimens.height / 4}
                  />
                </ContentBox>
              ))}
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}
            >
              {range(1, digitAmount).map((_, index) => (
                <ContentBox
                  key={index}
                  containerStyle={{
                    backgroundColor: colors.prussianBlueLight,
                    width: dimens.width / 6,
                    height: dimens.height / 3,
                    justifyContent: 'center'
                  }}
                >
                  <Text variant="WRN700" key={index}>
                    {digits[index]}
                  </Text>
                </ContentBox>
              ))}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGy',
  description: 'aGy',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    snacks: z.number().int().min(2).max(5),
    drinks: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const snacks = randomIntegerInclusive(2, 5);

    const drinks = randomIntegerInclusive(2, 6);

    return {
      name,
      snacks,
      drinks
    };
  },
  Component: props => {
    const {
      question: { name, snacks, drinks },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterBuysASnackAndADrinkFromAVendingMachineWithNumSnacksAndNumDrinksCompleteCalc(
          name,
          snacks,
          drinks
        )}
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          ((userAnswer[0] === snacks.toString() && userAnswer[1] === drinks.toString()) ||
            (userAnswer[0] === drinks.toString() && userAnswer[1] === snacks.toString())) &&
          userAnswer[2] === (snacks * drinks).toString()
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGz',
  description: 'aGz',
  keywords: ['Multiplication', 'Division', 'Correspondence'],
  schema: z.object({
    name: nameSchema,
    iceCreams: z.number().int().min(2).max(5),
    sauces: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const iceCreams = randomIntegerInclusive(2, 5);

    const sauces = randomIntegerInclusive(2, 6);

    return {
      name,
      iceCreams,
      sauces
    };
  },
  Component: props => {
    const {
      question: { name, iceCreams, sauces },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterIsChoosingAnIceCreamCharacterChoosesOneFlavourAndOneSauce(
          name,
          iceCreams,
          iceCreams * sauces
        )}
        sentence={`<ans/>`}
        testCorrect={[sauces.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HowManyWays',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
