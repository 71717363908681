import { View } from 'react-native';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectNames,
  objectPrepositionWord,
  objectSchema,
  objectSentenceMaker
} from 'common/src/utils/objects';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import QF30GroupCountersAndSentence from 'common/src/components/question/questionFormats/QF30GroupCountersAndSentence';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { range } from 'common/src/utils/collections';
import { getObjectImage } from 'common/src/utils/objectsImages';
import { DIV, MULT } from 'common/src/constants';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { getCharacterHeadImage } from '../../../../utils/characters';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alb',
  description: 'alb',
  keywords: ['Multiply', 'Divide', '6', 'Object'],
  schema: z.object({
    groups: z.number().int().min(2).max(8),
    object: objectSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 8);

    const object = getRandomObject();

    return { groups, object };
  },
  Component: props => {
    const {
      question: { groups, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={[
          translate.answerSentences.thereAreAnsX(containerPlural),
          translate.answerSentences.eachGroupHasAnsObjects(containerSingular, objectPlural),
          translate.answerSentences.thereAreAnsObjectsInTotal(objectPlural)
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[[groups.toString()], ['6'], [(groups * 6).toString()]]}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, groups).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 6, dimens.height, (dimens.width - 8 * groups) / groups)}
                  </View>
                ))}
              </View>
            </View>
          );
        }}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'alc',
  description: 'alc',
  keywords: ['Multiply', '6'],
  schema: z.object({
    groupA: z.number().int().min(1).max(4).array().length(4),
    objects: z.array(objectSchema).length(3)
  }),
  simpleGenerator: () => {
    const groupA = shuffle([1, 2, 3, 4]);

    // Get 3 different objects
    const objects = getRandomSubArrayFromArray(objectNames, 3);

    return { groupA, objects };
  },
  Component: props => {
    const {
      question: { groupA, objects },
      translate,
      displayMode
    } = props;

    // All the same
    const A1 = 6;
    const A3 = 6;
    const B2 = 6;
    const B4 = 6;

    const [B1, A2, B3, A4] = groupA;
    const [objectA, objectB, objectC] = objects;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 325 } : { height: 150, width: 700 };

    // Container & Object words
    const itemImageA = getObjectImage(objectA, B1, dimens.height, dimens.width / A1);
    const itemImageB = getObjectImage(objectB, B2, dimens.height, dimens.width / A2);
    const itemImageC = getObjectImage(objectC, B3, dimens.height, dimens.width / A3);

    const statements = [
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A1}
              customImage={itemImageA}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A1} ${MULT} ${B1}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A2}
              customImage={itemImageB}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A2} ${MULT} ${B2}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A3}
              customImage={itemImageC}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A3} ${MULT} ${B3}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects dimens={dimens} rows={Math.min(A4, B4)} columns={Math.max(A4, B4)} />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A4} ${MULT} ${B4}`
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchNumberSentencesToPictures()}
        items={items}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'ald',
  description: 'ald',
  keywords: ['Multiply', 'Divide', '6', 'Object'],
  schema: z.object({
    countersPerGroup: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const countersPerGroup = randomIntegerInclusive(2, 4);

    return { countersPerGroup };
  },
  Component: ({ question: { countersPerGroup }, translate }) => {
    const counters = 6 * countersPerGroup;

    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.shareXCountersEquallyIntoYGroups(
          counters.toLocaleString(),
          (6).toLocaleString()
        )}
        sentence={`${translate.answerSentences.XDividedIntoYEqualGroupsIsAns(
          counters.toLocaleString(),
          6
        )} <br/> ${counters.toLocaleString()} ${DIV} ${(6).toLocaleString()} = <ans/>`}
        testCorrect={[countersPerGroup.toString(), countersPerGroup.toString()]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'ale',
  description: 'ale',
  keywords: ['Multiply', 'Divide', '6', 'Object'],
  schema: z.object({
    groups: z.number().int().min(2).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 5);

    return { groups };
  },
  Component: ({ question: { groups }, translate }) => {
    const counters = 6 * groups;
    return (
      <QF1ContentAndSentences
        title={translate.instructions.divideXCountersIntoGroupsOfYCounters(counters, 6)}
        sentences={[
          translate.answerSentences.howManyGroupsOfNumAreInArray(6),
          `${counters} ${DIV} ${6} = <ans/>`
        ]}
        testCorrect={[[groups.toString()], [groups.toString()]]}
        Content={({ dimens }) => <ArrayOfObjects rows={groups} columns={6} dimens={dimens} />}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'alf',
  description: 'alf',
  keywords: ['Multiply', '6', 'Problem'],
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    name: nameSchema,
    object: objectSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);

    const name = getRandomName();

    const object = getRandomObject();

    return { number1, name, object };
  },
  Component: props => {
    const {
      question: { number1, name, object },
      translate,
      displayMode
    } = props;
    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentence()}
        testCorrect={[[], [], [(number1 * 6).toString()]]}
        sentences={objectSentenceMaker(name, object, translate, 6, number1)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        Content={({ dimens }) => (
          <>{getCharacterHeadImage(name, dimens.height * 0.8, dimens.width * 0.4)}</>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'alg',
  description: 'alg',
  keywords: ['Divide', '6', 'Problem'],
  schema: z.object({
    number1: z.number().int().min(2).max(6),
    name: nameSchema,
    object: objectSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);

    const name = getRandomName();

    const object = getRandomObject();

    return { number1, name, object };
  },
  Component: props => {
    const {
      question: { number1, name, object },
      translate,
      displayMode
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[number1.toString()]}
        sentence={translate.answerSentences.characterHasNumObjectCharacterPutsNumObjectInEachContainer(
          {
            containerPlural,
            containerSingular,
            name,
            objectPerContainer: 6,
            objectPlural,
            preposition,
            totalObject: 6 * number1
          }
        )}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        Content={({ dimens }) => (
          <>{getCharacterHeadImage(name, dimens.height * 0.8, dimens.width * 0.4)}</>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAndDivideBy6',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
