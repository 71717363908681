import IconButton from 'common/src/components/atoms/IconButton';
import { colors } from 'common/src/theme/colors';
import SvgImage from 'common/src/utils/svgImage';

type SiteInfo = {
  type: 'link' | 'text';
  svg: unknown;
  color: string;
  pressInColor?: string;
  url: string;
};

/** Information about each site we might share to. */
const SITE_INFO = {
  facebook: {
    type: 'link',
    svg: require('pupil-app/assets/svg/socialMedia/Facebook.svg'),
    color: '#0866ff',
    pressInColor: '#70A7FF',
    url: 'https://www.facebook.com/sharer/sharer.php?u='
  },
  linkedin: {
    type: 'text',
    svg: require('pupil-app/assets/svg/socialMedia/Linkedin.svg'),
    color: '#0a66c2',
    pressInColor: '#3D99F5',
    url: 'https://www.linkedin.com/shareArticle?text='
  },
  twitter: {
    type: 'text',
    svg: require('pupil-app/assets/svg/socialMedia/Twitter.svg'),
    color: '#000000',
    pressInColor: '#333333',
    url: 'https://twitter.com/intent/tweet?text='
  },
  whatsapp: {
    type: 'text',
    svg: require('pupil-app/assets/svg/socialMedia/Whatsapp.svg'),
    color: '#0dc143',
    pressInColor: '#40F276',
    url: 'https://api.whatsapp.com/send?text='
  }
} satisfies Record<string, SiteInfo>;

type Site = keyof typeof SITE_INFO;

/** Arguments to `window.open` to get a nice sharing popup. */
const WINDOW_ARGS = [
  'popup=yes',
  'menubar=no',
  'toolbar=no',
  'resizable=yes',
  'scrollbars=yes',
  'width=600',
  'height=600'
].join(',');

type Props = {
  site: Site;
  /** Defaults to 64, and the icon width is always 3/4 of this number. */
  width?: number;
  /** Whether to use greyscale instead of brand colors. Defaults to false. */
  greyscale?: boolean;
} & ({ link: string } | { text: string });

/**
 * Button for sharing a link (and maybe text) to social media.
 *
 * You must make sure that you provide a `text` prop when using a site that expects text (such as WhatsApp) and a
 * `link` prop when using a site that expects a link. Otherwise this component will throw an error.
 */
export default function SocialMediaShareIcon({
  site,
  width = 64,
  greyscale = false,
  ...props
}: Props) {
  const siteInfo = SITE_INFO[site];

  // Check that they gave the right prop
  if (!(siteInfo.type in props)) {
    throw new Error(`For this site ${site} you must provide the ${siteInfo.type} prop`);
  }

  return (
    <IconButton
      width={width}
      iconWidth={(width * 3) / 4}
      baseColor={greyscale ? colors.greys700 : siteInfo.color}
      onPressInColor={greyscale ? colors.greys500 : siteInfo.pressInColor}
      onPress={() => {
        const target = 'text' in props ? siteInfo.url + props.text : siteInfo.url + props.link;
        window.open(target, site, WINDOW_ARGS);
      }}
    >
      <SvgImage fill source={siteInfo.svg} />
    </IconButton>
  );
}
