import { RefObject, useContext, useMemo, useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import Animated, {
  useSharedValue,
  withSpring,
  useAnimatedStyle,
  useAnimatedRef,
  SharedValue,
  measure,
  MeasuredDimensions,
  runOnJS
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { colors } from 'common/src/theme/colors';
import { ScaleFactorContext, type Dimens } from 'common/src/theme/scaling';
import { countRange } from 'common/src/utils/collections';
import { AssetSvg } from '../../assets/svg';

type Props = {
  dimens: Dimens;
};

/** Represents a single counter which can be dropped freely, and knows whether it was dropped into a drop zone or not. */
const Draggable = ({
  addCounter,
  dropZoneRef,
  sharedZIndex
}: {
  /** Callback, called when the counter is successfully dropped inside the drop zone. */
  addCounter: () => void;
  /** The reference point of the dropzone */
  dropZoneRef: RefObject<Animated.View>;
  /** Shared z index value to keep track of the top most counter */
  sharedZIndex: SharedValue<number>;
}) => {
  const styles = useStyles();
  const scaleFactor = useContext(ScaleFactorContext);

  // offset/Y are the offset between gestures. gestureOffsetX/Y is the offset for the current gesture.
  // These should be added to get the total offset. These are already scaled by the scale factor.
  // Note: In react-native-reanimated version 3, you can combine these into one object.
  const offsetX = useSharedValue(0);
  const offsetY = useSharedValue(0);
  const gestureOffsetX = useSharedValue(0);
  const gestureOffsetY = useSharedValue(0);

  /** The z-index to display. */
  const zIndex = useSharedValue(0);

  const draggableRef = useAnimatedRef<Animated.View>();
  /** Measurements of the drop zone and the draggable. These are already scaled by the scale factor. */
  const measurements = useSharedValue({
    dropZone: null as MeasuredDimensions | null,
    draggable: null as MeasuredDimensions | null
  });

  // Used to calculate translationX/Y since we don't trust those values from the event. (Scaled.)
  const beginAbsX = useSharedValue<number | null>(null);
  const beginAbsY = useSharedValue<number | null>(null);

  const pan = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginAbsX.value = event.absoluteX;
          beginAbsY.value = event.absoluteY;

          // When gesture begins, move to front
          zIndex.value = sharedZIndex.value++;

          // Take measurements of everything - used later in the gesture.
          const dropZone = measure(dropZoneRef);
          const draggable = measure(draggableRef);
          if (Platform.OS === 'web') {
            // For some reason on web we need to scale the width and height, as these measurements aren't already scaled but everything else is.
            if (draggable !== null) {
              draggable.width *= scaleFactor;
              draggable.height *= scaleFactor;
            }
            if (dropZone !== null) {
              dropZone.width *= scaleFactor;
              dropZone.height *= scaleFactor;
            }
          }
          measurements.value = { dropZone, draggable };
        })
        .onChange(event => {
          // Calculate translationX/Y manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;
          const translationY = (event.absoluteY - beginAbsY.value!) / scaleFactor;

          // Update the offset
          gestureOffsetX.value = translationX * scaleFactor;
          gestureOffsetY.value = translationY * scaleFactor;
        })
        .onFinalize(() => {
          const draggable = measurements.value.draggable!;
          const counterCurrentLeft = draggable.pageX + gestureOffsetX.value;
          const counterCurrentRight = counterCurrentLeft + draggable.width;
          const counterCurrentTop = draggable.pageY + gestureOffsetY.value;
          const counterCurrentBottom = counterCurrentTop + draggable.height;

          const dropZone = measurements.value.dropZone!;
          const boxLeft = dropZone.pageX;
          const boxRight = boxLeft + dropZone.width;
          const boxTop = dropZone.pageY;
          const boxBottom = boxTop + dropZone.height;

          const inX = counterCurrentLeft > boxLeft && counterCurrentRight < boxRight;
          const inY = counterCurrentTop > boxTop && counterCurrentBottom < boxBottom;

          // Reset the gesture offset to 0 by adding it onto the start offset. This is also known as "flattening".
          offsetX.value += gestureOffsetX.value;
          offsetY.value += gestureOffsetY.value;
          gestureOffsetX.value = 0;
          gestureOffsetY.value = 0;

          if (!inX || !inY) {
            // Ended up outside the drop zone - send back to the start
            offsetX.value = withSpring(0, { overshootClamping: true });
            offsetY.value = withSpring(0, { overshootClamping: true });
          } else {
            // Ended up inside the drop zone
            runOnJS(addCounter)();
          }
        }),
    [
      addCounter,
      beginAbsX,
      beginAbsY,
      draggableRef,
      dropZoneRef,
      gestureOffsetX,
      gestureOffsetY,
      measurements,
      offsetX,
      offsetY,
      scaleFactor,
      sharedZIndex,
      zIndex
    ]
  );

  const animatedStyles = useAnimatedStyle(
    () => ({
      transform: [
        { translateX: (offsetX.value + gestureOffsetX.value) / scaleFactor },
        { translateY: (offsetY.value + gestureOffsetY.value) / scaleFactor }
      ],
      zIndex: zIndex.value
    }),
    [gestureOffsetX, gestureOffsetY, offsetX, offsetY, scaleFactor, zIndex]
  );

  return (
    <GestureDetector gesture={pan}>
      <Animated.View style={[styles.counter, animatedStyles]} ref={draggableRef}>
        <AssetSvg name="Place_value/GreyCounter" />
      </Animated.View>
    </GestureDetector>
  );
};

/**
 * An area for users to drag counters into a dropzone.
 * This component is not testable as there is no way at the moment to check how many counters are grouped together.
 * There is currently no limit of how many counters can be placed in the dropzone.
 *
 * TODO (bonus): This component is currently not controlled. So users cannot revisit a question and see how it was answered.
 * The offset values and zIndex for each counter should be saved.
 * There are extra counters being added to state, because if old counters are picked and dropped "addCounter" is run.
 * Also if a old counter is sent back to the pile there would be an extra counter to the pile.
 */
export default function DragAndDropPlay({ dimens }: Props) {
  const styles = useStyles();
  const [counters, setCounter] = useState(1);
  const addCounter = () => setCounter(counters + 1);
  const dropZoneRef = useAnimatedRef<Animated.View>();
  /** The highest z-index used so far across all counters in this component. */
  const sharedZIndex = useSharedValue(1);

  return (
    <View style={[styles.content, { ...dimens }]}>
      <View style={{ zIndex: 100, width: 70, height: 70 }}>
        {/* Placeholder counter */}
        <AssetSvg name="Place_value/GreyCounter" />

        {/* Draggable counter */}
        {countRange(counters).map(i => (
          <Draggable
            key={i}
            addCounter={addCounter}
            sharedZIndex={sharedZIndex}
            dropZoneRef={dropZoneRef}
          />
        ))}
      </View>

      <Animated.View
        ref={dropZoneRef}
        style={[
          styles.dropzone,
          {
            width: dimens.width * 0.875, // the dropzone to use 87.5% of the width so there is space for the counter on the left
            height: dimens.height
          }
        ]}
      />
    </View>
  );
}

function useStyles() {
  return useMemo(
    () =>
      StyleSheet.create({
        content: { flexDirection: 'row', justifyContent: 'space-between' },
        counter: {
          position: 'absolute',
          width: 70,
          height: 70
        },
        dropzone: {
          borderColor: colors.burntSienna,
          borderRadius: 8,
          borderWidth: 3,
          borderStyle: 'solid'
        }
      }),
    []
  );
}
