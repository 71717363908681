import { SvgName, getSvgInfo } from '../assets/svg';
import { Dimens, containAspectRatio } from '../theme/scaling';

/**
 * Returns the actual dimensions of an svg based on the aspect ratio and new size.
 * @param svgName - The svg name
 * @param dimens - The container space
 * @returns
 */
export const getActualDimens = (
  svgName: SvgName,
  dimens: Dimens
): {
  svgName: SvgName;
  actualHeight: number;
  actualWidth: number;
} => {
  const { aspectRatio } = getSvgInfo(svgName);
  const { width: actualWidth, height: actualHeight } = containAspectRatio(dimens, aspectRatio);
  return { actualHeight, actualWidth, svgName };
};
