import {
  CheapObjectName,
  ExpensiveObjectName,
  ObjectName,
  lessThanOnePoundObjectName
} from './objects';
import { throwError } from './flowControl';
import { AssetSvg, type SvgName } from '../assets/svg';

/** Object contain all the names of SVGs related to objects. */
const objectImages: Record<ObjectName, SvgName[]> = {
  Apple: [
    'Equal_groups/Apples0',
    'Equal_groups/Apples1',
    'Equal_groups/Apples2',
    'Equal_groups/Apples3',
    'Equal_groups/Apples4',
    'Equal_groups/Apples5',
    'Equal_groups/Apples6'
  ],
  Cookie: [
    'Equal_groups/Cookies0',
    'Equal_groups/Cookies1',
    'Equal_groups/Cookies2',
    'Equal_groups/Cookies3',
    'Equal_groups/Cookies4',
    'Equal_groups/Cookies5',
    'Equal_groups/Cookies6'
  ],
  Egg: [
    'Equal_groups/Eggs0',
    'Equal_groups/Eggs1',
    'Equal_groups/Eggs2',
    'Equal_groups/Eggs3',
    'Equal_groups/Eggs4',
    'Equal_groups/Eggs5',
    'Equal_groups/Eggs6'
  ],
  Flower: [
    'Equal_groups/Flowers0',
    'Equal_groups/Flowers1',
    'Equal_groups/Flowers2',
    'Equal_groups/Flowers3',
    'Equal_groups/Flowers4',
    'Equal_groups/Flowers5',
    'Equal_groups/Flowers6'
  ],
  Pencil: [
    'Equal_groups/Pencils0',
    'Equal_groups/Pencils1',
    'Equal_groups/Pencils2',
    'Equal_groups/Pencils3',
    'Equal_groups/Pencils4',
    'Equal_groups/Pencils5',
    'Equal_groups/Pencils6'
  ]
};

const lollyStickImages: Record<'square' | 'pentagon', SvgName[]> = {
  square: [
    'Lolly_sticks/SingleLollyStickSquare',
    'Lolly_sticks/TwoLollyStickSquare',
    'Lolly_sticks/ThreeLollyStickSquare',
    'Lolly_sticks/FourLollyStickSquare'
  ],
  pentagon: [
    'Lolly_sticks/SingleLollyStickPentagon',
    'Lolly_sticks/TwoLollyStickPentagon',
    'Lolly_sticks/ThreeLollyStickPentagon',
    'Lolly_sticks/FourLollyStickPentagon',
    'Lolly_sticks/FiveLollyStickPentagon'
  ]
};

const lessThanOnePoundObjects: Record<lessThanOnePoundObjectName, SvgName> = {
  Apple: 'Array_objects/AppleGreen',
  Banana: 'Array_objects/Banana',
  Pear: 'Array_objects/Pear'
};

const cheapObjects: Record<CheapObjectName, SvgName> = {
  Book: 'BookRed',
  Balloon: 'BalloonBlue',
  BreadLoaf: 'Bread_loaf',
  CrayonBox: 'CrayonBox',
  FizzyDrink: 'Can_of_fizzy_drink',
  JuiceBottle: 'Juice',
  LemonadeBottle: 'Lemonade_bottle',
  Mug: 'Mug',
  PencilCase: 'Pencil_case',
  Kettle: 'Kettle'
};

const expensiveObjects: Record<ExpensiveObjectName, SvgName> = {
  Bike: 'Bike',
  'Games console': 'GamesConsole',
  Laptop: 'Laptop',
  Phone: 'Phone',
  Rucksack: 'Rucksack',
  Scooter: 'Scooter',
  Television: 'Television'
};

/**
 * Get object SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getObjectImage} instead.
 */
export const getObjectSvgName = (object: ObjectName, amount: number): SvgName => {
  return objectImages[object][amount] ?? throwError(`No image for ${amount} of ${object}`);
};

/**
 * Get lolly sticks SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getObjectImage} instead.
 */
export const getLollySticksSvgName = (shape: 'square' | 'pentagon', amount: number): SvgName => {
  return (
    lollyStickImages[shape][amount - 1] ?? throwError(`No image for ${amount} sticks in a ${shape}`)
  );
};

/**
 * Get object image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getObjectSvgName} directly, for better customization.
 */
export const getObjectImage = (
  object: ObjectName,
  amount: number,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getObjectSvgName(object, amount)} height={height} width={width} />;
};

/**
 * Get lessThanOnePoundObject SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getLessThanOnePoundObjectImage} instead.
 */
export const getLessThanOnePoundObjectSvgName = (object: lessThanOnePoundObjectName): SvgName => {
  return lessThanOnePoundObjects[object];
};

/**
 * Get lessThanOnePoundObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getLessThanOnePoundObjectSvgName} directly, for better customization.
 */
export const getLessThanOnePoundObjectImage = (
  object: lessThanOnePoundObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getLessThanOnePoundObjectSvgName(object)} height={height} width={width} />;
};

/**
 * Get cheapObject SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getCheapObjectImage} instead.
 */
export const getCheapObjectSvgName = (object: CheapObjectName): SvgName => {
  return cheapObjects[object];
};

/**
 * Get cheapObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getCheapObjectSvgName} directly, for better customization.
 */
export const getCheapObjectImage = (
  object: CheapObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getCheapObjectSvgName(object)} height={height} width={width} />;
};

/**
 * Get expensiveObject SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getExpensiveObjectImage} instead.
 */
export const getExpensiveObjectSvgName = (object: ExpensiveObjectName): SvgName => {
  return expensiveObjects[object];
};

/**
 * Get expensiveObject image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getExpensiveObjectSvgName} directly, for better customization.
 */
export const getExpensiveObjectImage = (
  object: ExpensiveObjectName,
  height?: number,
  width?: number
): JSX.Element => {
  return <AssetSvg name={getExpensiveObjectSvgName(object)} height={height} width={width} />;
};

export const groupNames = [
  'Apple',
  'Banana',
  'Pear',
  'Pencil',
  'Muffin',
  'Cookie',
  'Donut',
  'Egg',
  'Fish',
  'MuffinRack',
  'Flower'
] as const;

export type GroupName = (typeof groupNames)[number];

const smallGroupSvgs: Record<GroupName, SvgName[]> = {
  Apple: objectImages['Apple'],
  Flower: objectImages['Flower'],
  Pear: [
    'Pears_in_a_transparent_bag/Pears_in_a_bag_0',
    'Pears_in_a_transparent_bag/Pears_in_a_bag_1',
    'Pears_in_a_transparent_bag/Pears_in_a_bag_2',
    'Pears_in_a_transparent_bag/Pears_in_a_bag_3',
    'Pears_in_a_transparent_bag/Pears_in_a_bag_4',
    'Pears_in_a_transparent_bag/Pears_in_a_bag_5'
  ],
  Pencil: objectImages['Pencil'],
  Muffin: [
    // this is just an empty plate
    'Equal_groups/Cookies0',
    'Food_on_a_plate/Muffins_plate_1',
    'Food_on_a_plate/Muffins_plate_2',
    'Food_on_a_plate/Muffins_plate_3',
    'Food_on_a_plate/Muffins_plate_4',
    'Food_on_a_plate/Muffins_plate_5',
    'Food_on_a_plate/Muffins_plate_6'
  ],
  Cookie: objectImages['Cookie'],
  Donut: [
    'Donuts_in_a_box/Donuts_in_a_box_0',
    'Donuts_in_a_box/Donuts_in_a_box_1',
    'Donuts_in_a_box/Donuts_in_a_box_2',
    'Donuts_in_a_box/Donuts_in_a_box_3',
    'Donuts_in_a_box/Donuts_in_a_box_4',
    'Donuts_in_a_box/Donuts_in_a_box_5',
    'Donuts_in_a_box/Donuts_in_a_box_6',
    'Donuts_in_a_box/Donuts_in_a_box_7',
    'Donuts_in_a_box/Donuts_in_a_box_8',
    'Donuts_in_a_box/Donuts_in_a_box_9',
    'Donuts_in_a_box/Donuts_in_a_box_10',
    'Donuts_in_a_box/Donuts_in_a_box_11',
    'Donuts_in_a_box/Donuts_in_a_box_12'
  ],
  Egg: [
    'Carton_of_eggs_0_-12/Carton_of_eggs_0',
    'Carton_of_eggs_0_-12/Carton_of_eggs_1',
    'Carton_of_eggs_0_-12/Carton_of_eggs_2',
    'Carton_of_eggs_0_-12/Carton_of_eggs_3',
    'Carton_of_eggs_0_-12/Carton_of_eggs_4',
    'Carton_of_eggs_0_-12/Carton_of_eggs_5',
    'Carton_of_eggs_0_-12/Carton_of_eggs_6',
    'Carton_of_eggs_0_-12/Carton_of_eggs_7',
    'Carton_of_eggs_0_-12/Carton_of_eggs_8',
    'Carton_of_eggs_0_-12/Carton_of_eggs_9',
    'Carton_of_eggs_0_-12/Carton_of_eggs_10',
    'Carton_of_eggs_0_-12/Carton_of_eggs_11',
    'Carton_of_eggs_0_-12/Carton_of_eggs_12'
  ],
  Fish: [
    'Goldfish_in_a_tank/Goldfish_in_a_tank_0',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_1',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_2',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_3',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_4',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_5',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_6',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_7',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_8',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_9',
    'Goldfish_in_a_tank/Goldfish_in_a_tank_10'
  ],
  Banana: [
    'Bunches_of_bananas/Bunch_of_bananas_1',
    'Bunches_of_bananas/Bunch_of_bananas_2',
    'Bunches_of_bananas/Bunch_of_bananas_3',
    'Bunches_of_bananas/Bunch_of_bananas_4',
    'Bunches_of_bananas/Bunch_of_bananas_5'
  ],
  MuffinRack: [
    'Muffins_donuts/Muffins_on_a_rack_0',
    'Muffins_donuts/Muffins_on_a_rack_1',
    'Muffins_donuts/Muffins_on_a_rack_2',
    'Muffins_donuts/Muffins_on_a_rack_3',
    'Muffins_donuts/Muffins_on_a_rack_4',
    'Muffins_donuts/Muffins_on_a_rack_5',
    'Muffins_donuts/Muffins_on_a_rack_6',
    'Muffins_donuts/Muffins_on_a_rack_7',
    'Muffins_donuts/Muffins_on_a_rack_8',
    'Muffins_donuts/Muffins_on_a_rack_9',
    'Muffins_donuts/Muffins_on_a_rack_10',
    'Muffins_donuts/Muffins_on_a_rack_11',
    'Muffins_donuts/Muffins_on_a_rack_12'
  ]
};

export const getGroupSvgName = (object: GroupName, amount: number): SvgName => {
  return (
    smallGroupSvgs[object][object === 'Banana' ? amount - 1 : amount] ??
    throwError(`No image for ${amount} of ${object}`)
  );
};
