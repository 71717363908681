import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import Text from 'common/src/components/typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { ShapeNames, labelPositions } from '../../../utils/labelPositions';

type Props = {
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Name of the shape, check in labelPositions.ts for corresponding svgName
   */
  shapeName: ShapeNames;
  /**
   * @param labels An array of strings to hold the labels that go around the shape
   * Starts in the top left and goes anticlockwise
   */
  labels?: string[];
  /**
   * @param angleLabels An array of strings to hold the labels that go around the shape angles
   * Starts in the top left and goes anticlockwise
   */
  angleLabels?: string[];
  seed?: string | number | object;
  // optional color prop. Will only work if the color prop is used in labelPositions where we get the svg name
  color?: 'pink' | 'blue' | 'green' | 'purple' | 'yellow';
  /**
   * How much to reduce the shape width by from the dimens width.
   * Optional prop, defaults to 64.
   */
  shapeWidthReduction?: number;
  /**
   * How much to reduce the shape height by from the dimens height.
   * Optional prop, defaults to 64.
   */
  shapeHeightReduction?: number;
  /**
   * Index of unknowns that require a slightly larger font size.
   * The algebraic letters render really small compared to the numbers so we can optionally enlarge these.
   */
  unknownLargerFontIndex?: number[];
};
/**
 * This component renders a shape with labels on the sides and/or angles.
 */
export const LabelledShape = (props: Props) => {
  const {
    labels,
    angleLabels,
    dimens: { width, height },
    shapeName,
    seed,
    color,
    shapeWidthReduction = 64,
    shapeHeightReduction = 64,
    unknownLargerFontIndex = []
  } = props;
  const displayMode = useContext(DisplayMode);

  const styles = useStyles(width, height);
  const shapeWidth = width - shapeWidthReduction;
  const shapeHeight = height - shapeHeightReduction;

  const font = displayMode === 'digital' ? 22 : 32;
  const enlargedFont = displayMode === 'digital' ? 26 : 36;

  //get labels for shapes and absolute positions
  const shapeLabels = () => {
    const { svgName, angleLabelsPositions, sideLabelPositions } = labelPositions(
      shapeName,
      { width, height },
      { shapeWidth, shapeHeight },
      displayMode !== 'digital',
      seed,
      color
    );

    const labelAngles =
      angleLabels &&
      angleLabels.map((label, index) => {
        return (
          <View
            key={index}
            style={[angleLabelsPositions[index], { position: 'absolute', zIndex: 2 }]}
          >
            <Text
              variant="WRN700"
              style={{
                fontSize: unknownLargerFontIndex.includes(index) ? enlargedFont : font,
                textAlign: 'center'
              }}
            >
              {label}
            </Text>
          </View>
        );
      });

    const labelSides =
      labels &&
      labels.map((label, index) => {
        return (
          <View
            key={index}
            style={[sideLabelPositions[index], { position: 'absolute', zIndex: 2 }]}
          >
            <Text
              numberOfLines={1}
              variant="WRN700"
              style={{
                fontSize: unknownLargerFontIndex.includes(index) ? enlargedFont : font,
                textAlign: 'center'
              }}
            >
              {label}
            </Text>
          </View>
        );
      });

    return {
      svgName,
      labelSides,
      labelAngles
    };
  };
  const { svgName, labelSides, labelAngles } = shapeLabels();

  return (
    <View style={[styles.imageWrapper]}>
      {labelSides}
      {labelAngles}
      <AssetSvg name={svgName as SvgName} width={shapeWidth} height={shapeHeight} />
    </View>
  );
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          width,
          height
        }
      }),
    [width, height]
  );
};
