import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { chunk } from '../../../../utils/chunk';
import TwoWayTable from '../../../../components/question/representations/TwoWayTable';
import { getRandomUniqueSports, sportAsWord, sportSchema } from '../../../../utils/sports';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'awe',
  description: 'awe',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    characters: nameSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'The characters must all be unique'),
    scores: z.number().int().array().length(12),
    characterInQuestion: z.number().int().min(0).max(3),
    roundInQuestion: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    return {
      characters: getRandomUniqueNames(4),
      scores: countRange(12).map(() => randomIntegerInclusive(1, 10)),
      characterInQuestion: randomIntegerInclusive(0, 3),
      roundInQuestion: randomIntegerInclusive(0, 2)
    };
  },
  Component: ({
    question: { characters, scores, characterInQuestion, roundInQuestion },
    translate
  }) => {
    // Convert the scores from a flat array of 12 into scores for each character.
    const numRounds = 3;
    const characterScores = chunk(scores, 3);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsWereScoredByCharacterInRoundN(
          characters[characterInQuestion],
          roundInQuestion + 1
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[characterScores[characterInQuestion][roundInQuestion].toString()]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={countRange(numRounds).map(i =>
              translate.misc.RoundN((i + 1).toLocaleString())
            )}
            leftHeaders={characters}
            items={characterScores.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awf',
  description: 'awf',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    sports: sportSchema
      .array()
      .length(3)
      .refine(arrayHasNoDuplicates, 'The sports must all be unique'),
    tally: z.number().int().array().length(9),
    sportInQuestion: z.number().int().min(0).max(2),
    yearInQuestion: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    return {
      sports: getRandomUniqueSports(3),
      tally: [
        ...countRange(6).map(() => randomIntegerInclusive(10, 20)),
        ...countRange(3).map(() => randomIntegerInclusive(1, 10))
      ],
      sportInQuestion: randomIntegerInclusive(0, 2),
      yearInQuestion: randomIntegerInclusive(0, 2)
    };
  },
  Component: ({ question: { sports, tally, sportInQuestion, yearInQuestion }, translate }) => {
    // Convert the tally from a flat array of 12 into scores for each character.
    const yearNum = 3;
    const sportPrefTally = chunk(tally, 3);

    const sportsTranslated = sports.map(sport => sportAsWord(sport, translate));

    const translatedSportInQuestion = sportsTranslated[sportInQuestion];
    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyChildrenInYearXPreferY(
          yearInQuestion + 1,
          translatedSportInQuestion
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[sportPrefTally[yearInQuestion][sportInQuestion].toString()]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={sportsTranslated}
            leftHeaders={countRange(yearNum).map(i =>
              translate.misc.yearN((i + 1).toLocaleString())
            )}
            items={sportPrefTally.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awg',
  description: 'awg',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    characters: nameSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'The characters must all be unique'),
    scores: z.number().int().array().length(12),
    roundInQuestion: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    return {
      characters: getRandomUniqueNames(4),
      scores: countRange(12).map(() => randomIntegerInclusive(1, 10)),
      roundInQuestion: randomIntegerInclusive(0, 2)
    };
  },
  Component: ({ question: { characters, scores, roundInQuestion }, translate }) => {
    // Convert the scores from a flat array of 12 into scores for each character.
    const numRounds = 3;
    const characterScores = chunk(scores, 3);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsWereScoredInTotalInRoundX(roundInQuestion + 1)}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          characterScores.reduce((acc, curr) => acc + curr[roundInQuestion], 0).toString()
        ]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={countRange(numRounds).map(i =>
              translate.misc.RoundN((i + 1).toLocaleString())
            )}
            leftHeaders={characters}
            items={characterScores.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awh',
  description: 'awh',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    characters: nameSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'The characters must all be unique'),
    scores: z.number().int().array().length(12),
    characterInQuestion: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    return {
      characters: getRandomUniqueNames(4),
      scores: countRange(12).map(() => randomIntegerInclusive(1, 10)),
      characterInQuestion: randomIntegerInclusive(0, 3)
    };
  },
  Component: ({ question: { characters, scores, characterInQuestion }, translate }) => {
    // Convert the scores from a flat array of 12 into scores for each character.
    const numRounds = 3;
    const characterScores = chunk(scores, 3);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsDidXScoreInTotal(
          characters[characterInQuestion]
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          characterScores[characterInQuestion].reduce((acc, curr) => acc + curr, 0).toString()
        ]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={countRange(numRounds).map(i =>
              translate.misc.RoundN((i + 1).toLocaleString())
            )}
            leftHeaders={characters}
            items={characterScores.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'awi',
  description: 'awi',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    sports: sportSchema
      .array()
      .length(3)
      .refine(arrayHasNoDuplicates, 'The sports must all be unique'),
    tally: z.array(z.number().int().array().length(3)).length(3),
    sportsInQuestion: z.array(z.number().int().min(0).max(2)).length(2),
    yearInQuestion: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const sportsInQuestion = randomUniqueIntegersInclusive(0, 2, 2);
    const yearInQuestion = randomIntegerInclusive(0, 2);
    const tally = rejectionSample(
      () =>
        chunk(
          [
            ...countRange(6).map(() => randomIntegerInclusive(10, 20)),
            ...countRange(3).map(() => randomIntegerInclusive(1, 10))
          ],
          3
        ),
      tally =>
        tally[yearInQuestion][sportsInQuestion[0]] !== tally[yearInQuestion][sportsInQuestion[1]]
    );

    return {
      sports: getRandomUniqueSports(3),
      tally,
      sportsInQuestion,
      yearInQuestion
    };
  },
  Component: ({ question: { sports, tally, sportsInQuestion, yearInQuestion }, translate }) => {
    const yearNum = 3;

    const sportPrefTallyYear = tally[yearInQuestion];

    const [sport1, sport2] =
      sportPrefTallyYear[sportsInQuestion[0]] > sportPrefTallyYear[sportsInQuestion[1]]
        ? sportsInQuestion
        : [...sportsInQuestion].reverse();

    const difference = sportPrefTallyYear[sport1] - sportPrefTallyYear[sport2];

    const sportsTranslated = sports.map(sport => sportAsWord(sport, translate));

    const translatedSport1InQuestion = sportsTranslated[sport1];
    const translatedSport2InQuestion = sportsTranslated[sport2];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMoreChildrenPreferXThanYInYearZ(
          translatedSport1InQuestion,
          translatedSport2InQuestion,
          yearInQuestion + 1
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[difference.toString()]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={sportsTranslated}
            leftHeaders={countRange(yearNum).map(i =>
              translate.misc.yearN((i + 1).toLocaleString())
            )}
            items={tally.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awj',
  description: 'awj',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    sports: sportSchema
      .array()
      .length(3)
      .refine(arrayHasNoDuplicates, 'The sports must all be unique'),
    tally: z.array(z.number().int().array().length(3)).length(3),
    sportsInQuestion: z.array(z.number().int().min(0).max(2)).length(2)
  }),
  simpleGenerator: () => {
    const sportsInQuestion = randomUniqueIntegersInclusive(0, 2, 2);

    const tally = rejectionSample(
      () =>
        chunk(
          [
            ...countRange(6).map(() => randomIntegerInclusive(10, 20)),
            ...countRange(3).map(() => randomIntegerInclusive(1, 10))
          ],
          3
        ),
      tally =>
        tally.reduce((acc, curr) => acc + curr[sportsInQuestion[0]], 0) !==
        tally.reduce((acc, curr) => acc + curr[sportsInQuestion[1]], 0)
    );

    return {
      sports: getRandomUniqueSports(3),
      tally,
      sportsInQuestion
    };
  },
  Component: ({ question: { sports, tally, sportsInQuestion }, translate }) => {
    const yearNum = 3;

    const sport1Total = tally.reduce((acc, curr) => acc + curr[sportsInQuestion[0]], 0);
    const sport2Total = tally.reduce((acc, curr) => acc + curr[sportsInQuestion[1]], 0);

    const [sport1, sport2] =
      sport1Total > sport2Total ? sportsInQuestion : [...sportsInQuestion].reverse();

    const difference = Math.abs(sport1Total - sport2Total);

    const sportsTranslated = sports.map(sport => sportAsWord(sport, translate));

    const translatedSport1InQuestion = sportsTranslated[sport1];
    const translatedSport2InQuestion = sportsTranslated[sport2];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMoreChildrenPreferXThanY(
          translatedSport1InQuestion,
          translatedSport2InQuestion
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[difference.toString()]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={sportsTranslated}
            leftHeaders={countRange(yearNum).map(i =>
              translate.misc.yearN((i + 1).toLocaleString())
            )}
            items={tally.map(row => row.map(n => n.toLocaleString()))}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwoWayTables',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
