import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomNumberWithSpecificDigit,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  rejectionSample
} from 'common/src/utils/random';
import { CounterVariantNoBlockSchema } from 'common/src/components/question/representations/types';
import ReadPlaceValueChart from 'common/src/components/question/questionFormats/ReadPlaceValueChart';
import CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { PartWholeModel } from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import {
  Digit,
  numberToBase10Object,
  powersOfTenPowToWord,
  ScientificNotation
} from 'common/src/utils/math';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'adg',
  description: 'adg',
  keywords: ['Place value', 'Represent', '10,000', 'Chart'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999),
    counterVariant: CounterVariantNoBlockSchema
  }),
  example: {
    number: 1324,
    counterVariant: 'greyCounter'
  },
  simpleGenerator: () => {
    // Generate random number between 1001 and 9999
    const number = randomIntegerInclusive(1001, 9999);

    const counterVariant = 'greyCounter' as const;

    return { number, counterVariant };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[3, 2, 1, 0]}
      counterVariant={question.counterVariant}
      headerVariant="shortName"
      questionHeight={1050}
    />
  ),
  questionHeight: 1050
});

export const Question2 = newQuestionContent({
  uid: 'adh',
  description: 'adh',
  keywords: ['Place value', 'Represent', '10,000', 'Chart'],
  schema: z.object({
    number: z.number().min(1001).max(9999)
  }),
  questionHeight: 850,
  example: {
    number: 1245
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[3, 2, 1, 0]}
      counterVariant="greyCounter"
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number.toLocaleString())}
      headerVariant="shortName"
      questionHeight={850}
      {...props}
    />
  )
});

export const Question3 = newQuestionContent({
  uid: 'adi',
  description: 'adi',
  keywords: ['Place value', 'Represent', '10,000', 'Bar model'],
  schema: z.object({
    total: z.number().min(1001).max(9999),
    powerOfAnswer: z.number().int().min(1).max(3) as z.Schema<1 | 2 | 3>,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const powerOfAnswer = randomIntegerInclusive(1, 3) as 1 | 2 | 3;

    // Prevents answer being 0 and rejects multiples of 1,000
    const total = rejectionSample(
      () =>
        randomNumberWithSpecificDigit(
          powerOfAnswer,
          { lower: 1001, upper: 9999 },
          randomIntegerInclusive(1, 9) as Digit
        ),
      x => x % 1000 !== 0
    );

    const answerIndex = randomIntegerInclusive(0, 1);

    return { total, powerOfAnswer, answerIndex };
  },
  Component: ({ question: { total, powerOfAnswer, answerIndex }, translate, ...props }) => {
    const hiddenBarFactor = ScientificNotation.fromNumber(total).unsignedDigitAt(powerOfAnswer);
    const hiddenBarAmount = hiddenBarFactor ? Math.pow(10, powerOfAnswer) * hiddenBarFactor : 0;
    const givenNumber = total - hiddenBarAmount;

    const numbers = [
      [total],
      answerIndex === 0 ? [hiddenBarAmount, givenNumber] : [givenNumber, hiddenBarAmount]
    ];
    const answerIndices = [[], [answerIndex]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'adj',
  description: 'adj',
  keywords: ['Place value', 'Represent', '10,000', 'Select'],
  schema: z.object({
    numbers: z.number().int().min(1000).max(9999).array().length(8),
    digit: z.number().int().min(1).max(9),
    power: z.enum(['ones', 'tens', 'hundreds', 'thousands'])
  }),
  example: {
    numbers: [4040, 9865, 1234, 1009, 5249, 2048, 6000, 3616],
    digit: 4,
    power: 'tens'
  },
  simpleGenerator: () => {
    const power = getRandomFromArray(['ones', 'tens', 'hundreds', 'thousands'] as const);
    const digit = randomIntegerInclusive(1, 9) as Digit;
    const range = { lower: 1000, upper: 9999 };

    const { num1, num2, num3, num4, num5, num6, num7, num8 } = rejectionSample(
      () => {
        const num1 = randomNumberWithSpecificDigit(3, range, digit);
        const num2 = randomNumberWithSpecificDigit(3, range, digit);
        const num3 = randomNumberWithSpecificDigit(2, range, digit);
        const num4 = randomNumberWithSpecificDigit(2, range, digit);
        const num5 = randomNumberWithSpecificDigit(1, range, digit);
        const num6 = randomNumberWithSpecificDigit(1, range, digit);
        const num7 = randomNumberWithSpecificDigit(0, range, digit);
        const num8 = randomNumberWithSpecificDigit(0, range, digit);

        return { num1, num2, num3, num4, num5, num6, num7, num8 };
      },
      val =>
        arrayHasNoDuplicates([
          val.num1,
          val.num2,
          val.num3,
          val.num4,
          val.num5,
          val.num6,
          val.num7,
          val.num8
        ])
    );

    return { numbers: shuffle([num1, num2, num3, num4, num5, num6, num7, num8]), digit, power };
  },
  Component: props => {
    const {
      question: { numbers, digit, power },
      translate
    } = props;

    const powerAsString = translate.powersOfTen[power](0);

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        pdfTitle={translate.instructions.circleNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        testCorrect={numbers.filter(it => numberToBase10Object(it)[power] === digit)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'adk',
  description: 'adk',
  keywords: ['Place value', 'Represent', '10,000'],
  schema: z
    .object({
      digits: z.array(z.number().int().min(0).max(9)).min(4).max(4),
      powerOfTen: z.number().int().min(1).max(3) as z.Schema<1 | 2 | 3>,
      value: z.number().int().min(0).max(9)
    })
    .refine(val => val.digits.includes(val.value), 'The value must be one of the choices'),
  example: {
    value: 5,
    digits: [1, 5, 2, 7],
    powerOfTen: 1
  },
  simpleGenerator: () => {
    const numOfValues = 4;
    const value = randomIntegerInclusive(0, 9);

    // Ensure question doesn't ask for 0 in the first column - also, only allow the first three unit types to be selected
    const powerOfTen = (
      value === 0 ? randomIntegerInclusive(1, 2) : randomIntegerInclusive(1, 3)
    ) as 1 | 2 | 3;

    // Ensure each digit will be unique
    const digits = new Set<number>();

    digits.add(value);
    while (digits.size < numOfValues) {
      digits.add(randomIntegerInclusive(0, 9));
    }

    const shuffled = shuffle(Array.from(digits));

    return { digits: shuffled, value, powerOfTen };
  },
  Component: props => {
    const {
      question: { digits, value, powerOfTen },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      if (ans[0] === (0).toLocaleString()) {
        return false;
      }
      return ScientificNotation.fromFixedString(ans.join('')).digitAt(powerOfTen) === value;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragDigitCardsToCreate4DigitNumberWith(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        pdfTitle={translate.instructions.useDigitCardsToCreate4DigitNumberWithDigitsOnlyUsedOnce(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        items={digits.map(num => num.toLocaleString())}
        sentence={'<ans/> '.repeat(4)}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyNumberWithXInTheYPlace(
            value,
            translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
          )
        }}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'adl',
  description: 'adl',
  keywords: ['Place value', 'Represent', '10,000', 'Part-whole'],
  schema: z
    .object({
      number: z.number().int().min(1010).max(9990).step(10),
      tens: z.number().int().min(0).max(90),
      hundreds: z.number().int().min(0).max(900),
      thousands: z.number().int().min(1000).max(9000),
      unitOfAnswer: z.enum(['tens', 'hundreds', 'thousands'])
    })
    .refine(
      val => val.tens + val.hundreds + val.thousands === val.number,
      'Tens, hundreds and thousands must be equal to the whole'
    ),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray(['tens', 'hundreds', 'thousands'] as const);
    // Conditions to ensure the answer needed is never 0
    const tens =
      unitOfAnswer === 'tens'
        ? randomIntegerInclusive(1, 9) * 10
        : randomIntegerInclusive(0, 9) * 10;
    // Added condition of ensuring that tens and hundreds are never both 0
    const hundreds =
      tens === 0 || unitOfAnswer === 'hundreds'
        ? randomIntegerInclusive(1, 9) * 100
        : randomIntegerInclusive(0, 9) * 100;
    const thousands = randomIntegerInclusive(1, 9) * 1000;
    const number = thousands + hundreds + tens;

    return { number, tens, hundreds, thousands, unitOfAnswer };
  },
  Component: ({ question, ...props }) => {
    const { number, tens, hundreds, thousands, unitOfAnswer } = question;

    const { translate } = props;

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = ['$ans', thousands + hundreds];
      correctAnswer = tens;
    } else if (unitOfAnswer === 'hundreds') {
      partition = ['$ans', thousands + tens];
      correctAnswer = hundreds;
    } else {
      partition = [hundreds + tens, '$ans'];
      correctAnswer = thousands;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
