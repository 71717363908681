import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';

////
// Questions
////

const Question3 = newQuestionContent({
  uid: 'bev',
  description: 'bev',
  keywords: ['Equal groups', 'Group'],
  schema: z.object({
    rows: z.number().int().min(2).max(10),
    columns: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 10);

    const columns = randomIntegerInclusive(2, 10, {
      constraint: x => x * rows >= 6 && x * rows <= 20 && (x * rows) % 2 === 0
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const total = rows * columns;

    const answer = total / 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.halfOfXIsAns(rows * columns)}
        title={translate.ks1Instructions.completeTheSentenceUseTheArrayToHelpYou()}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAHalfOfAQuantity',
  questionTypes: [Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
