import { newTermContent } from '../../Term';

import Fractions from './Fractions';
import Time from './Time';
import Statistics from './Statistics';
import PositionAndDirection from './PositionAndDirection';

const Term = newTermContent({
  term: 'Summer',
  blocks: [Fractions, Time, Statistics, PositionAndDirection]
});
export default Term;
