export default {
  '0': '0',
  '1': '1',
  '1s': '1s',
  '1-digit': '1-digit',
  '10 times-table': '10 times-table',
  '2': '2',
  '2-D': '2-D',
  '2-D shape': '2-D shape',
  '2-D shapes': '2-D shapes',
  '2-digit': '2-digit',
  '2 times-table': '2 times-table',
  '3': '3',
  '3-D': '3-D',
  '3-D shape': '3-D shape',
  '3-D shapes': '3-D shapes',
  '3-digit': '3-digit',
  '4': '4',
  '4-digit': '4-digit',
  '5': '5',
  '5 times-table': '5 times-table',
  '5-minute intervals': '5-minute intervals',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '10': '10',
  '10s': '10s',
  '11': '11',
  '12': '12',
  '24-hour clock': '24-hour clock',
  '50': '50',
  '50s': '50s',
  '100': '100',
  '100s': '100s',
  '100 square': '100 square',
  '1,000': '1,000',
  '1,000s': '1,000s',
  '10,000': '10,000',
  '100,000': '100,000',
  '1,000,000': '1,000,000',
  '10,000,000': '10,000,000',
  '=': '=',
  '<': '<',
  '>': '>',
  Above: 'Above',
  'Across a ten': 'Across a ten',
  Acute: 'Acute',
  Add: 'Add',
  Addition: 'Addition',
  Adults: 'Adults',
  After: 'After',
  Afternoon: 'Afternoon',
  Algebra: 'Algebra',
  Altogether: 'Altogether',
  am: 'am',
  Amount: 'Amount',
  Analogue: 'Analogue',
  'Analogue clock': 'Analogue clock',
  Angles: 'Angles',
  'Anti-clockwise': 'Anti-clockwise',
  Area: 'Area',
  'Area model': 'Area model',
  'Around a point': 'Around a point',
  Array: 'Array',
  Axes: 'Axes',
  Ascending: 'Ascending',
  Backwards: 'Backwards',
  'Balance scales': 'Balance scales',
  'Bar chart': 'Bar chart',
  'Balance Scales': 'Balance Scales',
  'Bar model': 'Bar model',
  'Base 10': 'Base 10',
  Before: 'Before',
  Below: 'Below',
  Between: 'Between',
  Bonds: 'Bonds',
  'Bonds to 10': 'Bonds to 10',
  Calculate: 'Calculate',
  Calendar: 'Calendar',
  Capacities: 'Capacities',
  Capacity: 'Capacity',
  Celsius: '°C',
  Centimetres: 'Centimetres',
  'Centimetres squared': 'Centimetres squared',
  Centre: 'Centre',
  Change: 'Change',
  Chart: 'Chart',
  Check: 'Check',
  Children: 'Children',
  Circle: 'Circle',
  Circles: 'Circles',
  Circumference: 'Circumference',
  Clockwise: 'Clockwise',
  'Closer to': 'Closer to',
  cm: 'cm',
  cm2: 'cm2',
  Coins: 'Coins',
  Collect: 'Collect',
  Column: 'Column',
  'Column addition': 'Column addition',
  'Column calculation': 'Column calculation',
  'Column method': 'Column method',
  'Column multiplication': 'Column multiplication',
  'Column subtraction': 'Column subtraction',
  Common: 'Common',
  'Common denominator': 'Common denominator',
  'Common denominators': 'Common denominators',
  'Common numerators': 'Common numerators',
  Commutative: 'Commutative',
  Compare: 'Compare',
  Comparison: 'Comparison',
  Compound: 'Compound',
  'Compound shape': 'Compound shape',
  Complement: 'Complement',
  Compass: 'Compass',
  Connections: 'Connections',
  Constant: 'Constant',
  'Constant difference': 'Constant difference',
  Conversion: 'Conversion',
  Convert: 'Convert',
  'Converting units': 'Converting units',
  Coordinate: 'Coordinate',
  Coordinates: 'Coordinates',
  'Coordinate grid': 'Coordinate grid',
  Cost: 'Cost',
  Count: 'Count',
  'Count in fractions': 'Count in fractions',
  'Count on': 'Count on',
  Counters: 'Counters',
  'Counting backwards': 'Counting backwards',
  'Counting forwards': 'Counting forwards',
  Correspondence: 'Correspondence',
  'Cross out': 'Cross out',
  Cube: 'Cube',
  Cubes: 'Cubes',
  Cubic: 'Cubic',
  Cuboid: 'Cuboid',
  Data: 'Data',
  Day: 'Day',
  Days: 'Days',
  ['Days of the week']: 'Days of the week',
  Decagon: 'Decagon',
  Decimal: 'Decimal',
  Decimals: 'Decimals',
  Decrease: 'Decrease',
  Degrees: 'Degrees',
  Denominator: 'Denominator',
  Denominators: 'Denominators',
  Descending: 'Descending',
  Diagonal: 'Diagonal',
  Diameter: 'Diameter',
  Difference: 'Difference',
  'Difference between': 'Difference between',
  Digit: 'Digit',
  Digital: 'Digital',
  Direction: 'Direction',
  Distance: 'Distance',
  Divide: 'Divide',
  'Divide by 1': 'Divide by 1',
  'Divide by 10': 'Divide by 10',
  'Divide by 1,000': 'Divide by 1,000',
  'Divide by 2': 'Divide by 2',
  'Divide by 100': 'Divide by 100',
  'Divide by itself': 'Divide by itself',
  Divisibility: 'Divisibility',
  Division: 'Division',
  'Division facts': 'Division facts',
  Double: 'Double',
  Doubling: 'Doubling',
  'Double number line': 'Double number line',
  Down: 'Down',
  Draw: 'Draw',
  'Draw shapes': 'Draw shapes',
  'Dual bar chart': 'Dual bar chart',
  Duration: 'Duration',
  Earliest: 'Earliest',
  Edge: 'Edge',
  Efficient: 'Efficient',
  Empty: 'Empty',
  'End time': 'End time',
  'End times': 'End times',
  Equal: 'Equal',
  'Equal groups': 'Equal groups',
  'Equal sides': 'Equal sides',
  'Equal parts': 'Equal parts',
  'Equal to': 'Equal to',
  Equation: 'Equation',
  Equilateral: 'Equilateral',
  Equivalence: 'Equivalence',
  Equivalent: 'Equivalent',
  'Equivalent fractions': 'Equivalent fractions',
  'Equivalent percentages': 'Equivalent percentages',
  'Equivalent to': 'Equivalent to',
  Estimate: 'Estimate',
  Even: 'Even',
  Exchange: 'Exchange',
  Expanded: 'Expanded',
  Expression: 'Expression',
  Face: 'Face',
  'Fact families': 'Fact families',
  'Fact family': 'Fact family',
  Factor: 'Factor',
  'Factor pairs': 'Factor pairs',
  Factors: 'Factors',
  False: 'False',
  Faster: 'Faster',
  FDP: 'FDP',
  Feet: 'Feet',
  Fewer: 'Fewer',
  Fewest: 'Fewest',
  Fifths: 'Fifths',
  Fifty: 'Fifty',
  'Find the difference': 'Find the difference',
  First: 'First',
  'First quadrant': 'First quadrant',
  Flexible: 'Flexible',
  'Flexible partition': 'Flexible partition',
  Formula: 'Formula',
  Forwards: 'Forwards',
  'Four operations': 'Four operations',
  'Four quadrants': 'Four quadrants',
  Fraction: 'Fraction',
  'Fraction as operator': 'Fraction as operator',
  'Fraction counters': 'Fraction counters',
  'Fraction wall': 'Fraction wall',
  Fractions: 'Fractions',
  Frequency: 'Frequency',
  'Full turn': 'Full turn',
  Function: 'Function',
  'Function machine': 'Function machine',
  Full: 'Full',
  Fourth: 'Fourth',
  g: 'g',
  Gattegno: 'Gattegno',
  'Gattegno chart': 'Gattegno chart',
  'Given one find other': 'Given one find other',
  Grams: 'Grams',
  Greater: 'Greater',
  'Greater than': 'Greater than',
  Greatest: 'Greatest',
  'Greatest product': 'Greatest product',
  Grid: 'Grid',
  Group: 'Group',
  Grouping: 'Grouping',
  Groups: 'Groups',
  'Groups of': 'Groups of',
  Half: 'Half',
  'Half past': 'Half past',
  'Half turn': 'Half turn',
  Halve: 'Halve',
  Halves: 'Halves',
  Halving: 'Halving',
  Halfway: 'Halfway',
  Heavier: 'Heavier',
  Height: 'Height',
  Heptagon: 'Heptagon',
  Hexagon: 'Hexagon',
  Horizontal: 'Horizontal',
  Hour: 'Hour',
  Hours: 'Hours',
  'How many more': 'How many more',
  'How many ways': 'How many ways',
  Hundred: 'Hundred',
  'Hundred square': 'Hundred square',
  Hundreds: 'Hundreds',
  Hundredths: 'Hundredths',
  Inches: 'Inches',
  Increase: 'Increase',
  Indices: 'Indices',
  Inequalities: 'Inequalities',
  Inequality: 'Inequality',
  Input: 'Input',
  Integer: 'Integer',
  Integers: 'Integers',
  Interpret: 'Interpret',
  Intervals: 'Intervals',
  Inverse: 'Inverse',
  Image: 'Image',
  Imperial: 'Imperial',
  Improper: 'Improper',
  'Improper fraction': 'Improper fraction',
  'Improper fractions': 'Improper fractions',
  Isosceles: 'Isosceles',
  'Isosceles triangle': 'Isosceles triangle',
  Itself: 'Itself',
  Irregular: 'Irregular',
  'Line graphs': 'Line graphs',
  Jumps: 'Jumps',
  Key: 'Key',
  kg: 'kg',
  Kilogram: 'Kilogram',
  Kilograms: 'Kilograms',
  Kilometres: 'Kilometres',
  'Kilometres squared': 'Kilometres squared',
  'Known facts': 'Known facts',
  km: 'km',
  km2: 'km2',
  l: 'l',
  Latest: 'Latest',
  Last: 'Last',
  lb: 'lb',
  Least: 'Least',
  Left: 'Left',
  Length: 'Length',
  Lengths: 'Lengths',
  Less: 'Less',
  'Less than': 'Less than',
  Lighter: 'Lighter',
  'Line graph': 'Line graph',
  'Lines of symmetry': 'Lines of symmetry',
  Litre: 'Litre',
  Litres: 'Litres',
  'Long division': 'Long division',
  Long: 'Long',
  Longer: 'Longer',
  Longest: 'Longest',
  'Lots of': 'Lots of',
  m: 'm',
  ml: 'ml',
  mm: 'mm',
  m2: 'm2',
  mm2: 'mm2',
  Make: 'Make',
  'Make 10': 'Make 10',
  Marbles: 'Marbles',
  Mass: 'Mass',
  Masses: 'Masses',
  Match: 'Match',
  Measure: 'Measure',
  Mean: 'Mean',
  Mental: 'Mental',
  Metre: 'Metre',
  'Metre stick': 'Metre stick',
  Metres: 'Metres',
  'Metres squared': 'Metres squared',
  Metric: 'Metric',
  'Metric units': 'Metric units',
  Midnight: 'Midnight',
  Midpoint: 'Midpoint',
  Miles: 'Miles',
  Millilitres: 'Millilitres',
  Millimetres: 'Millimetres',
  Million: 'Million',
  Minutes: 'Minutes',
  'Minutes past': 'Minutes past',
  'Minutes to': 'Minutes to',
  'Mirror line': 'Mirror line',
  Missing: 'Missing',
  'Missing length': 'Missing length',
  'Missing number': 'Missing number',
  'Missing numbers': 'Missing numbers',
  'Missing value': 'Missing value',
  Mixed: 'Mixed',
  'Mixed fractions': 'Mixed fractions',
  'Mixed number': 'Mixed number',
  'Mixed numbers': 'Mixed numbers',
  Money: 'Money',
  Months: 'Months',
  'Months of the year': 'Months of the year',
  More: 'More',
  Morning: 'Morning',
  Most: 'Most',
  Move: 'Move',
  Multiple: 'Multiple',
  Multiples: 'Multiples',
  'Multiples of 10': 'Multiples of 10',
  Multiplication: 'Multiplication',
  Multiplicative: 'Multiplicative',
  Multiply: 'Multiply',
  'Multiply by 10': 'Multiply by 10',
  'Multiply by 100': 'Multiply by 100',
  'Multiply by 1,000': 'Multiply by 1,000',
  'Multiply factors': 'Multiply factors',
  'Multi-step': 'Multi-step',
  Nets: 'Nets',
  'Near doubles': 'Near doubles',
  Nearest: 'Nearest',
  'Nearest integer': 'Nearest integer',
  'Nearest tenth': 'Nearest tenth',
  'Nearly empty': 'Nearly empty',
  'Nearly full': 'Nearly full',
  Negative: 'Negative',
  'Negative numbers': 'Negative numbers',
  Net: 'Net',
  'Non-unit': 'Non-unit',
  'Non-unit fraction': 'Non-unit fraction',
  Nonagon: 'Nonagon',
  'No exchange': 'No exchange',
  Noon: 'Noon',
  Notation: 'Notation',
  Notes: 'Notes',
  'Not shaded': 'Not shaded',
  Now: 'Now',
  'Number bonds': 'Number bonds',
  'Number line': 'Number line',
  'Number sentence': 'Number sentence',
  'Number sentences': 'Number sentences',
  'Number track': 'Number track',
  Numerals: 'Numerals',
  Numeral: 'Numeral',
  Numerator: 'Numerator',
  Numerators: 'Numerators',
  "O'clock": "O'clock",
  Object: 'Object',
  Objects: 'Objects',
  Obtuse: 'Obtuse',
  Octagon: 'Octagon',
  Odd: 'Odd',
  'One digit': 'One digit',
  'One quarter': 'One quarter',
  'One whole': 'One whole',
  Ones: 'Ones',
  'Ones-digit': 'Ones-digit',
  Order: 'Order',
  Ordering: 'Ordering',
  'Order of operations': 'Order of operations',
  Ordinal: 'Ordinal',
  Output: 'Output',
  'Pairs of values': 'Pairs of values',
  Parallel: 'Parallel',
  Parallelogram: 'Parallelogram',
  Partition: 'Partition',
  Partitioning: 'Partitioning',
  'Part-whole': 'Part-whole',
  'Part-whole model': 'Part-whole model',
  Part: 'Part',
  Parts: 'Parts',
  'Past the hour': 'Past the hour',
  Pattern: 'Pattern',
  Pence: 'Pence',
  Pentagon: 'Pentagon',
  Percentage: 'Percentage',
  Percentages: 'Percentages',
  Perimeter: 'Perimeter',
  Perpendicular: 'Perpendicular',
  'Pie chart': 'Pie chart',
  Pictogram: 'Pictogram',
  Pint: 'Pint',
  'Place holder': 'Place holder',
  'Place value': 'Place value',
  'Place value chart': 'Place value chart',
  'Place value counters': 'Place value counters',
  Pentagons: 'Pentagons',
  Plot: 'Plot',
  pm: 'pm',
  Point: 'Point',
  Points: 'Points',
  Polygon: 'Polygon',
  Polygons: 'Polygons',
  Position: 'Position',
  Pound: 'Pound',
  Pounds: 'Pounds',
  'Powers of 10': 'Powers of 10',
  Prime: 'Prime',
  Problem: 'Problem',
  'Problem solving': 'Problem solving',
  Product: 'Product',
  Properties: 'Properties',
  Proportion: 'Proportion',
  Protractor: 'Protractor',
  Quadrilateral: 'Quadrilateral',
  Quadrilaterals: 'Quadrilaterals',
  Quantity: 'Quantity',
  Quarter: 'Quarter',
  'Quarter past': 'Quarter past',
  'Quarter to': 'Quarter to',
  'Quarter turn': 'Quarter turn',
  Quarters: 'Quarters',
  Quicker: 'Quicker',
  Radius: 'Radius',
  Ratio: 'Ratio',
  Ratios: 'Ratios',
  Read: 'Read',
  Reasoning: 'Reasoning',
  Recipes: 'Recipes',
  Rectangle: 'Rectangle',
  Rectilinear: 'Rectilinear',
  'Rectilinear shape': 'Rectilinear shape',
  Reduction: 'Reduction',
  Reflect: 'Reflect',
  Reflection: 'Reflection',
  Reflex: 'Reflex',
  Rekenrek: 'Rekenrek',
  Regular: 'Regular',
  'Regular polygon': 'Regular polygon',
  'Regular 2-D shape': 'Regular 2-D shape',
  'Related facts': 'Related facts',
  Remainder: 'Remainder',
  Remainders: 'Remainders',
  'Repeated addition': 'Repeated addition',
  Represent: 'Represent',
  Representations: 'Representations',
  Right: 'Right',
  'Right angle': 'Right angle',
  'Right-angled': 'Right-angled',
  'Right-angled triangle': 'Right-angled triangle',
  'Right angles': 'Right angles',
  Roll: 'Roll',
  Roman: 'Roman',
  'Roman numerals': 'Roman numerals',
  Round: 'Round',
  Rounding: 'Rounding',
  Row: 'Row',
  Rows: 'Rows',
  Ruler: 'Ruler',
  'Same denominator': 'Same denominator',
  Scale: 'Scale',
  Scalene: 'Scalene',
  Scales: 'Scales',
  Scaling: 'Scaling',
  Second: 'Second',
  Seconds: 'Seconds',
  Sector: 'Sector',
  Select: 'Select',
  Sequence: 'Sequence',
  Set: 'Set',
  Shaded: 'Shaded',
  Shape: 'Shape',
  Shapes: 'Shapes',
  Share: 'Share',
  Sharing: 'Sharing',
  'Side lengths': 'Side lengths',
  Similar: 'Similar',
  Simplify: 'Simplify',
  'Simplified fractions': 'Simplified fractions',
  'Short division': 'Short division',
  Shorter: 'Shorter',
  Shortest: 'Shortest',
  Sides: 'Sides',
  Slower: 'Slower',
  Smaller: 'Smaller',
  Smallest: 'Smallest',
  Solve: 'Solve',
  Sort: 'Sort',
  Square: 'Square',
  Squares: 'Squares',
  Squared: 'Squared',
  'Start time': 'Start time',
  'Start times': 'Start times',
  Statements: 'Statements',
  'Straight line': 'Straight line',
  'Straight lines': 'Straight lines',
  Strategy: 'Strategy',
  Substitution: 'Substitution',
  Subtract: 'Subtract',
  'Subtract fractions': 'Subtract fractions',
  Subtraction: 'Subtraction',
  Sum: 'Sum',
  Systematic: 'Systematic',
  Symmetry: 'Symmetry',
  Table: 'Table',
  'Take away': 'Take away',
  Tall: 'Tall',
  Tally: 'Tally',
  Taller: 'Taller',
  Tallest: 'Tallest',
  Temperature: 'Temperature',
  'Ten frame': 'Ten frame',
  'Ten frames': 'Ten frames',
  Ten: 'Ten',
  Tens: 'Tens',
  Tenths: 'Tenths',
  'The same as': 'The Same as',
  Then: 'Then',
  Thermometer: 'Thermometer',
  Third: 'Third',
  Thousand: 'Thousand',
  Thousands: 'Thousands',
  Thousandths: 'Thousandths',
  'Three-quarters': 'Three-quarters',
  'Three-quarter turn': 'Three-quarter turn',
  'Three digit': 'Three digit',
  Time: 'Time',
  Timetable: 'Timetable',
  'Times-table': 'Times-table',
  'Times-tables': 'Times-tables',
  'To the hour': 'To the hour',
  'To the minute': 'To the minute',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  Total: 'Total',
  Track: 'Track',
  Train: 'Train',
  Translate: 'Translate',
  Translation: 'Translation',
  Triangle: 'Triangle',
  Triangles: 'Triangles',
  Triple: 'Triple',
  True: 'True',
  Turn: 'Turn',
  Turns: 'Turns',
  'Two digit': 'Two digit',
  'Two-way tables': 'Two-way tables',
  Unequal: 'Unequal',
  'Unequal parts': 'Unequal parts',
  Unit: 'Unit',
  'Unit fraction': 'Unit fraction',
  'Unit of measurement': 'Unit of measurement',
  Units: 'Units',
  Unknowns: 'Unknowns',
  'Unknown lengths': 'Unknown lengths',
  Up: 'Up',
  Value: 'Value',
  Variable: 'Variable',
  Vertical: 'Vertical',
  'Vertically opposite': 'Vertically opposite',
  Vertex: 'Vertex',
  Vertices: 'Vertices',
  Volume: 'Volume',
  Weeks: 'Weeks',
  Weight: 'Weight',
  Whole: 'Whole',
  Wholes: 'Wholes',
  'Whole number': 'Whole number',
  'Whole numbers': 'Whole numbers',
  Width: 'Width',
  'Word problem': 'Word problem',
  Word: 'Word',
  Words: 'Words',
  'Written method': 'Written method',
  'X-axis': 'X-axis',
  'X-Coordinate': 'X-Coordinate',
  'Y-axis': 'Y-axis',
  'Y-Coordinate': 'Y-Coordinate',
  Years: 'Years',
  Yesterday: 'Yesterday'
};
