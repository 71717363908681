import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { filledArray } from '../../../utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import NumberLine, {
  CompleteNumberLine,
  FreeNumberLine,
  NumberLineWithState
} from '../representations/Number Line/NumberLine';
import { parseMarkup } from '../../../markup';
import { useContext, useMemo } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type Props = TitleStyleProps & {
  title: string;
  tickValues: CompleteNumberLine | FreeNumberLine;
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  freeNumberLineAnswer?: number[];
  /**
   * These 2 parameters are only necessary if the number line you're rendering won't have a label on the first or last
   * ticks. These are required for calculating the position to place arrows/answer boxes on the number line.
   * @param firstNumber - First number on the number line
   * @param lastNumber - Last number on the number line
   */
  firstNumber?: number;
  lastNumber?: number;
  /**
   * Optional extra symbol on keypad
   */
  extraSymbol?: ExtraSymbols;
  /**
   * Optional symbol shown at left of the input box. Takes either a string to be rendered
   * or a JSX.Element
   */
  inputLeadingSymbol?: string | JSX.Element;
  /**
   * Custom vertical padding for text under ticks. Optional prop, defaults to 8.
   */
  textVerticalPadding?: number;
  answerPositions?: 'top' | 'bottom';
  tickOffset?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

export default function QF17CompleteTheNumberLine({
  title,
  tickValues,
  inputMaxCharacters: inputMaxCharactersProp,
  testCorrect: testCorrectProp,
  freeNumberLineAnswer,
  firstNumber,
  lastNumber,
  extraSymbol,
  inputLeadingSymbol,
  textVerticalPadding,
  answerPositions,
  tickOffset,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const totalNumberOfAns = freeNumberLineAnswer
    ? freeNumberLineAnswer.length
    : tickValues
        .map(tick => parseMarkup(tick as string).numberOfAns)
        .reduce((sum, numberOfAns) => sum + numberOfAns);
  const initialState = filledArray('', totalNumberOfAns);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  // Make answer input boxes a suitable width
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (isPdf) {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <NumberLine
                  tickValues={tickValues}
                  userAnswer={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                  inputMaxCharacters={inputMaxCharacters}
                  firstNumber={firstNumber}
                  lastNumber={lastNumber}
                  freeNumberLineAnswer={freeNumberLineAnswer}
                  dimens={dimens}
                  inputLeadingSymbol={inputLeadingSymbol}
                  textVerticalPad={textVerticalPadding}
                  tickOffset={tickOffset}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="bottomTall"
      actionPanelContents={
        <UserInput inputType="numpad" variant="wide" extraSymbol={extraSymbol} />
      }
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <NumberLineWithState
              id="numberline"
              defaultState={initialState}
              testComplete={userAnswer => userAnswer.every(it => it.length !== 0)}
              testCorrect={testCorrect}
              tickValues={tickValues}
              inputMaxCharacters={inputMaxCharacters}
              firstNumber={firstNumber}
              lastNumber={lastNumber}
              freeNumberLineAnswer={freeNumberLineAnswer}
              inputLeadingSymbol={inputLeadingSymbol}
              textVerticalPad={textVerticalPadding}
              dimens={dimens}
              answerPositions={answerPositions}
              tickOffset={tickOffset}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
