import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import { MULT } from '../../../../constants';
import { countRange } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFractions, improperFractionToMixedNumber } from '../../../../utils/fractions';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { Dimens } from '../../../../theme/scaling';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { barModelColors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPM',
  description: 'aPM',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(20),
    denominatorB: z.number().int().min(2).max(20),
    denominatorC: z.number().int().min(2).max(20),
    numeratorA: z.number().int().min(3).max(100),
    numeratorB: z.number().int().min(3).max(100),
    numeratorC: z.number().int().min(3).max(100),
    wholeA: z.number().int().min(2).max(6),
    wholeB: z.number().int().min(2).max(6),
    wholeC: z.number().int().min(2).max(6),
    isIntergerFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB, denominatorC] = randomUniqueIntegersInclusive(2, 20, 3);

    const numeratorA = randomIntegerInclusive(denominatorA + 1, 100, {
      constraint: x => x % denominatorA !== 0
    });
    const numeratorB = randomIntegerInclusive(denominatorB + 1, 100, {
      constraint: x => x % denominatorB !== 0
    });
    const numeratorC = randomIntegerInclusive(denominatorC + 1, 100, {
      constraint: x => x % denominatorC !== 0
    });
    const [wholeA, wholeB, wholeC] = countRange(3).map(_ => randomIntegerInclusive(2, 6));
    const isIntergerFirst = getRandomBoolean();

    return {
      denominatorA,
      denominatorB,
      denominatorC,
      numeratorA,
      numeratorB,
      numeratorC,
      wholeA,
      wholeB,
      wholeC,
      isIntergerFirst
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        denominatorB,
        denominatorC,
        numeratorA,
        numeratorB,
        numeratorC,
        wholeA,
        wholeB,
        wholeC,
        isIntergerFirst
      },
      translate,
      displayMode
    } = props;

    const mixedFractionsA = improperFractionToMixedNumber(numeratorA, denominatorA);
    const mixedFractionsB = improperFractionToMixedNumber(numeratorB, denominatorB);
    const mixedFractionsC = improperFractionToMixedNumber(numeratorC, denominatorC);

    const statements = [
      {
        sentence: `${translate.answerSentences.repeatedAdditionOfFracNumTimes({
          frac: `<frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${mixedFractionsA[2].toLocaleString()}'/>`,
          number: translate.numbersAsWords[wholeA as 1 | 2 | 3 | 4 | 5 | 6]()
        })}`,
        correctAnswer: 'A'
      },
      {
        sentence: `${translate.answerSentences.repeatedAdditionOfFracNumTimes({
          frac: `<frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}'/>`,
          number: translate.numbersAsWords[wholeB as 1 | 2 | 3 | 4 | 5 | 6]()
        })}`,
        correctAnswer: 'B'
      },
      {
        sentence: `${translate.answerSentences.repeatedAdditionOfFracNumTimes({
          frac: `<frac w='${mixedFractionsC[0].toLocaleString()}' n='${mixedFractionsC[1].toLocaleString()}' d='${mixedFractionsC[2].toLocaleString()}'/>`,
          number: translate.numbersAsWords[wholeC as 1 | 2 | 3 | 4 | 5 | 6]()
        })}`,
        correctAnswer: 'C'
      }
    ];

    const items = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={
                isIntergerFirst
                  ? `${wholeA.toLocaleString()} ${MULT} <frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${mixedFractionsA[2].toLocaleString()}'/>`
                  : `<frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${mixedFractionsA[2].toLocaleString()}'/> ${MULT} ${wholeA.toLocaleString()}`
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
            />
          ),
          value: 'A'
        },
        {
          component: (
            <TextStructure
              sentence={
                isIntergerFirst
                  ? `${wholeB.toLocaleString()} ${MULT} <frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}'/>`
                  : `<frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}'/> ${MULT} ${wholeB.toLocaleString()}`
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
            />
          ),
          value: 'B'
        },
        {
          component: (
            <TextStructure
              sentence={
                isIntergerFirst
                  ? `${wholeC.toLocaleString()} ${MULT} <frac w='${mixedFractionsC[0].toLocaleString()}' n='${mixedFractionsC[1].toLocaleString()}' d='${mixedFractionsC[2].toLocaleString()}'/>`
                  : `<frac w='${mixedFractionsC[0].toLocaleString()}' n='${mixedFractionsC[1].toLocaleString()}' d='${mixedFractionsC[2].toLocaleString()}'/> ${MULT} ${wholeC.toLocaleString()}`
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
            />
          ),
          value: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchEachAdditionToEquivMult()}
        pdfTitle={translate.instructions.useCardsToMatchEachAdditionToEquivMult()}
        items={items}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endMid"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentences={statements.map(({ sentence }) => `${sentence} <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aPN',
  description: 'aPN',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(5),
      numeratorA: z.number().int().min(3).max(15),
      multiplier: z.number().int().min(2).max(4),
      incorrectMultipler: z.number().int().min(2).max(5),
      showWholeFirst: z.boolean()
    })
    .refine(
      val => val.multiplier !== val.incorrectMultipler,
      'multiplier and incorrectMultipler cannot be equal to each other'
    ),
  simpleGenerator: () => {
    const showWholeFirst = getRandomBoolean();

    const { denominator, numeratorA, multiplier, incorrectMultipler } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 5);
        const numeratorA = randomIntegerInclusive(denominator + 1, denominator * 3);
        const multiplier = randomIntegerInclusive(2, 4);
        const incorrectMultipler = randomIntegerInclusive(2, 5, {
          constraint: x => x !== multiplier
        });

        return {
          denominator,
          numeratorA,
          multiplier,
          incorrectMultipler
        };
      },
      // Only permit them if answer numerator and incorrect answer numerator doesn't equal 0
      ({ numeratorA, denominator, multiplier, incorrectMultipler }) =>
        improperFractionToMixedNumber(numeratorA * multiplier, denominator)[1] !== 0 &&
        improperFractionToMixedNumber(numeratorA * incorrectMultipler, denominator)[1] !== 0
    );

    return { denominator, numeratorA, multiplier, incorrectMultipler, showWholeFirst };
  },

  Component: props => {
    const {
      question: { denominator, numeratorA, multiplier, incorrectMultipler, showWholeFirst },
      translate,
      displayMode
    } = props;

    const [barModelColourA, barModelColourB] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      2,
      {
        random: seededRandom(props.question)
      }
    ) as string[];

    const numeratorB = numeratorA * multiplier;

    const mixedFractionsA = improperFractionToMixedNumber(numeratorA, denominator, false);
    const mixedFractionsB = improperFractionToMixedNumber(numeratorB, denominator, false);

    const sentence = showWholeFirst
      ? `${multiplier.toLocaleString()} ${MULT} <frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}'/>`
      : `<frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}'/> = ${multiplier.toLocaleString()} ${MULT} <frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${denominator.toLocaleString()}'/>`;

    const A = {
      value: 'A',
      wholeNumbers: mixedFractionsA[0],
      remaining: mixedFractionsA[1],
      multiplier,
      colour: barModelColourA,
      correct: true
    };

    const B = {
      value: 'B',
      wholeNumbers: mixedFractionsA[0],
      remaining: mixedFractionsA[1],
      multiplier: incorrectMultipler,
      colour: barModelColourB,
      correct: false
    };

    const statements = shuffle([A, B], { random: seededRandom(props.question) });

    const getBarModels = (
      dimens: Dimens,
      amountOfWholeBarModels: number,
      remaining: number,
      multiplier: number,
      colour: string
    ) => {
      const widthToRemove = amountOfWholeBarModels === 1 ? 250 : 100;
      const width = dimens.width / amountOfWholeBarModels + 1 - widthToRemove;
      const pdfWidthsToRemove = width / 1.5;
      return countRange(multiplier).map(num => (
        <View key={num} style={{ flexDirection: 'row', width: dimens.width, marginBottom: 3 }}>
          {countRange(amountOfWholeBarModels).map(num => (
            <ShadedFractionBarModel
              key={num}
              totalSubSections={denominator}
              width={displayMode === 'digital' ? width : pdfWidthsToRemove}
              height={multiplier === 5 ? 35 : 40}
              containerStyle={{ rowGap: 2 }}
              color={colour}
            />
          ))}
          <ShadedFractionBarModel
            coloredSections={countRange(remaining)}
            totalSubSections={denominator}
            width={displayMode === 'digital' ? width : pdfWidthsToRemove}
            height={multiplier === 5 ? 35 : 40}
            containerStyle={{ rowGap: 2 }}
            color={colour}
          />
        </View>
      ));
    };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectRepresentationThatMatchesMultiplication()}
        pdfTitle={translate.instructions.circleRepresentationThatMatchesMultiplication()}
        testCorrect={['A']}
        numItems={2}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center', alignItems: 'center' }]}>
            <TextStructure textStyle={{ fontSize: 40 }} sentence={sentence} />
          </View>
        )}
        renderItems={({ dimens }) => {
          return statements.map(({ value, wholeNumbers, remaining, multiplier, colour }) => ({
            value,
            component: (
              <View>{getBarModels(dimens, wholeNumbers, remaining, multiplier, colour)}</View>
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPO',
  description: 'aPO',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z.object({
    numeratorA: z.number().int().min(2).max(100),
    numeratorB: z.number().int().min(2).max(100),
    denominatorA: z.number().int().min(2).max(25),
    denominatorB: z.number().int().min(2).max(25),
    multiplierA: z.number().int().min(2).max(12),
    multiplierB: z.number().int().min(2).max(12),
    isAnswerFirst: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { denominatorA, denominatorB, numeratorA, numeratorB, multiplierA, multiplierB } =
      rejectionSample(
        () => {
          const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 25, 2);
          const numeratorA = randomIntegerInclusive(denominatorA + 1, 100, {
            constraint: x => x % denominatorA !== 0
          });
          const numeratorB = randomIntegerInclusive(denominatorB + 1, 100, {
            constraint: x => x % denominatorB !== 0
          });
          const multiplierA = randomIntegerInclusive(2, 12, {
            constraint: x => x * numeratorA > denominatorA
          });
          const multiplierB = randomIntegerInclusive(2, 12, {
            constraint: x => x * numeratorB > denominatorB
          });

          return { denominatorA, denominatorB, numeratorA, numeratorB, multiplierA, multiplierB };
        },
        // Only permit them if answer numerators doesn't equal 0 and numerators < denominator
        ({ denominatorA, denominatorB, numeratorA, numeratorB, multiplierA, multiplierB }) => {
          const mixedFractionsA = improperFractionToMixedNumber(numeratorA, denominatorA);
          const mixedFractionsB = improperFractionToMixedNumber(numeratorB, denominatorB);

          return (
            mixedFractionsA[1] !== 0 &&
            mixedFractionsA[1] * multiplierA < mixedFractionsA[2] &&
            mixedFractionsB[1] !== 0 &&
            mixedFractionsB[1] * multiplierB < mixedFractionsB[2]
          );
        }
      );

    const isAnswerFirst = getRandomBoolean();

    return {
      denominatorA,
      denominatorB,
      numeratorA,
      numeratorB,
      multiplierA,
      multiplierB,
      isAnswerFirst
    };
  },
  Component: props => {
    const {
      question: {
        numeratorA,
        numeratorB,
        denominatorA,
        denominatorB,
        multiplierA,
        multiplierB,
        isAnswerFirst
      },
      translate
    } = props;

    const mixedFractionsA = improperFractionToMixedNumber(numeratorA, denominatorA);
    const mixedFractionsB = improperFractionToMixedNumber(numeratorB, denominatorB);

    const sentences = [
      {
        string: isAnswerFirst
          ? `${multiplierA.toLocaleString()} ${MULT} <frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${mixedFractionsA[2].toLocaleString()}' /> = <frac wAns='' nAns='' dAns='' />`
          : ` <frac wAns='' nAns='' dAns='' /> = ${multiplierA.toLocaleString()} ${MULT} <frac w='${mixedFractionsA[0].toLocaleString()}' n='${mixedFractionsA[1].toLocaleString()}' d='${mixedFractionsA[2].toLocaleString()}' />`,
        answer: improperFractionToMixedNumber(numeratorA * multiplierA, denominatorA)
      },
      {
        string: isAnswerFirst
          ? `${multiplierB.toLocaleString()} ${MULT} <frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}' /> = <frac wAns='' nAns='' dAns='' />`
          : ` <frac wAns='' nAns='' dAns='' /> = ${multiplierB.toLocaleString()} ${MULT} <frac w='${mixedFractionsB[0].toLocaleString()}' n='${mixedFractionsB[1].toLocaleString()}' d='${mixedFractionsB[2].toLocaleString()}' />`,
        answer: improperFractionToMixedNumber(numeratorB * multiplierB, denominatorB)
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], sentences[0].answer) &&
          compareFractions(userAnswer[1], sentences[1].answer)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              sentences[0].answer[0].toLocaleString(),
              sentences[0].answer[1].toLocaleString(),
              sentences[0].answer[2].toLocaleString()
            ],
            [
              sentences[1].answer[0].toLocaleString(),
              sentences[1].answer[1].toLocaleString(),
              sentences[1].answer[2].toLocaleString()
            ]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={2}
        sentences={sentences.map(el => el.string)}
        extraSymbol="decimalPoint"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPP',
  description: 'aPP',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(12),
    numerator: z.number().int().min(3).max(143),
    incorrectNumerators: z.array(z.number().int().min(3).max(143)).length(2),
    whole: z.number().int().min(2).max(12),
    isBag: z.boolean(),
    food: z.enum([
      'Apples',
      'Blueberries',
      'Carrots',
      'Lemons',
      'Limes',
      'Mangoes',
      'Onions',
      'Oranges',
      'Pears',
      'Potatoes',
      'Strawberries'
    ])
  }),
  simpleGenerator: () => {
    const isBag = getRandomBoolean();
    const food = getRandomFromArray([
      'Apples',
      'Blueberries',
      'Carrots',
      'Lemons',
      'Limes',
      'Mangoes',
      'Onions',
      'Oranges',
      'Pears',
      'Potatoes',
      'Strawberries'
    ] as const);

    const { denominator, numerator, whole, incorrectNumerators } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 12);
        const numerator = randomIntegerInclusive(denominator + 1, 143, {
          constraint: x => x % denominator !== 0
        });
        const whole = randomIntegerInclusive(2, 12, {
          constraint: x => x * numerator > denominator
        });

        const incorrectNumerators = countRange(2).map(_ =>
          randomIntegerInclusive(3, 143, {
            constraint: x => x !== numerator && x % denominator !== 0
          })
        );

        return {
          denominator,
          numerator,
          whole,
          incorrectNumerators
        };
      },
      // Only permit them if answer numerators don't equal 0
      ({ numerator, denominator, whole, incorrectNumerators }) =>
        !improperFractionToMixedNumber(numerator * whole, denominator).includes(0) &&
        !improperFractionToMixedNumber(incorrectNumerators[0], denominator).includes(0) &&
        !improperFractionToMixedNumber(incorrectNumerators[1], denominator).includes(0)
    );

    return {
      denominator,
      numerator,
      whole,
      isBag,
      food,
      incorrectNumerators
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator, whole, isBag, food, incorrectNumerators },
      translate
    } = props;

    const foodPackaging = isBag
      ? [translate.objects.Box(), translate.objects.Boxes()]
      : [translate.objects.Crate(), translate.objects.Crates()];

    const [titleWhole, titleNumber, titleDenominator] = improperFractionToMixedNumber(
      numerator,
      denominator
    );

    const items = [
      {
        value: 'A',
        fraction: improperFractionToMixedNumber(numerator * whole, denominator)
      },
      {
        value: 'B',
        fraction: improperFractionToMixedNumber(numerator, denominator)
      },
      {
        value: 'C',
        fraction: improperFractionToMixedNumber(incorrectNumerators[0], denominator)
      },
      {
        value: 'D',
        fraction: improperFractionToMixedNumber(incorrectNumerators[1], denominator)
      }
    ];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.theMassOfOneXOfFoodIsFracKgWhatIsTheMassOfYXOfFood(
          foodPackaging[0],
          food,
          `<frac w='${titleWhole.toLocaleString()}' n='${titleNumber.toLocaleString()}' d='${titleDenominator.toLocaleString()}' />`,
          whole.toLocaleString(),
          foodPackaging[1]
        )}
        numItems={4}
        renderItems={() => {
          return shuffle(
            items.map(({ value, fraction }) => ({
              value,
              component: (
                <TextStructure
                  fractionTextStyle={{ fontSize: 32 }}
                  sentence={translate.units.stringKg(
                    `<frac w='${fraction[0].toLocaleString()}' n='${fraction[1].toLocaleString()}' d='${fraction[2].toLocaleString()}' />`
                  )}
                />
              )
            })),
            { random: seededRandom(props.question) }
          );
        }}
        testCorrect={['A']}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPQ',
  description: 'aPQ',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(12),
    whole: z.number().int().min(1).max(12),
    numerator: z.number().int().min(1).max(11),
    multiplier: z.number().int().min(1).max(12),
    answerWhole: z.number().int().min(1).max(155),
    answerNumerator: z.number().int().min(1).max(11),
    isAnswerFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const { whole, denominator, numerator, multiplier, answerWhole, answerNumerator } =
      rejectionSample(
        () => {
          const whole = randomIntegerInclusive(1, 12);
          const denominator = randomIntegerInclusive(2, 12);
          const numerator = randomIntegerInclusive(1, denominator - 1);
          const multiplier = randomIntegerInclusive(1, 12);

          const var2 = denominator * whole + numerator;
          const var6 = multiplier * var2;

          const answerWhole = Math.floor(var6 / denominator);
          const answerNumerator = var6 - denominator * answerWhole;

          return { whole, denominator, numerator, multiplier, answerWhole, answerNumerator };
        },
        // Only permit them if answer numerator don't equal 0 and doesnt equal denominator
        ({ answerNumerator, denominator }) =>
          answerNumerator !== 0 && answerNumerator !== denominator
      );

    const isAnswerFirst = getRandomBoolean();

    return {
      whole,
      denominator,
      numerator,
      multiplier,
      answerWhole,
      answerNumerator,
      isAnswerFirst
    };
  },
  Component: props => {
    const {
      question: {
        whole,
        denominator,
        numerator,
        multiplier,
        answerWhole,
        answerNumerator,
        isAnswerFirst
      },
      translate,
      displayMode
    } = props;

    const sentence = isAnswerFirst
      ? `<frac wAns='' nAns='' dAns=''/> = ${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}'  d='${denominator.toLocaleString()}' />`
      : `${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}'  d='${denominator.toLocaleString()}' /> = <frac wAns='' nAns='' dAns=''/>`;

    const answerOptions = [answerWhole, answerNumerator, denominator];

    const items = shuffle(
      answerOptions.map((x, i) => {
        return {
          component: <Text variant="WRN700">{x.toLocaleString()}</Text>,
          value: i
        };
      }),
      { random: seededRandom(props.question) }
    );
    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        items={items}
        actionPanelVariant="endWide"
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={countRange(3)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPR',
  description: 'aPR',
  keywords: [
    'Multiplication',
    'Multiply',
    'Integer',
    'Numerator',
    'Denominator',
    'Mixed number',
    'Unit fraction',
    'Non-unit fraction'
  ],
  schema: z.object({
    isAnswerFirst: z.boolean(),
    showCorrectAnswer: z.boolean(),
    denominator: z.number().int().min(2).max(12),
    numeratorA: z.number().int().min(1).max(11),
    whole: z.number().int().min(1).max(12),
    multiplier: z.number().int().min(1).max(12),
    answerWhole: z.number().int().min(1).max(155),
    answerNumerator: z.number().int().min(1).max(11),
    incorrectNumerator: z.number().int().min(1).max(12),
    incorrectWhole: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const isAnswerFirst = getRandomBoolean();
    const showCorrectAnswer = getRandomBoolean();

    const {
      answerWhole,
      answerNumerator,
      incorrectNumerator,
      incorrectWhole,
      denominator,
      numeratorA,
      whole,
      multiplier
    } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 12);
        const numeratorA = randomIntegerInclusive(1, denominator);
        const whole = randomIntegerInclusive(1, 12);
        const multiplier = randomIntegerInclusive(1, 12);
        const var2 = denominator * whole + numeratorA;
        const var6 = multiplier * var2;
        const answerWhole = Math.floor(var6 / denominator);
        const answerNumerator = var6 - denominator * answerWhole;
        const incorrectWhole = randomIntegerInclusive(1, 12, {
          constraint: x => x !== answerWhole
        });
        const incorrectNumerator = answerNumerator + 1;
        return {
          answerWhole,
          answerNumerator,
          incorrectNumerator,
          incorrectWhole,
          denominator,
          numeratorA,
          whole,
          multiplier
        };
      },
      // Only permit them if doesn't equal 0 and doesn't equal denominator
      ({ answerNumerator, denominator }) => answerNumerator !== 0 && answerNumerator !== denominator
    );

    return {
      showCorrectAnswer,
      isAnswerFirst,
      multiplier,
      whole,
      numeratorA,
      denominator,
      answerNumerator,
      answerWhole,
      incorrectNumerator,
      incorrectWhole
    };
  },
  Component: ({
    question: {
      showCorrectAnswer,
      isAnswerFirst,
      multiplier,
      whole,
      numeratorA,
      denominator,
      answerNumerator,
      answerWhole,
      incorrectNumerator,
      incorrectWhole
    },
    translate,
    displayMode
  }) => {
    const correctSentence = isAnswerFirst
      ? `<frac w='${answerWhole.toLocaleString()}' n='${answerNumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> = ${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' />`
      : `${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac w='${answerWhole.toLocaleString()}' n='${answerNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> `;

    const incorrectSentence = isAnswerFirst
      ? `<frac w='${incorrectWhole.toLocaleString()}' n='${incorrectNumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> = ${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' />`
      : `${multiplier.toLocaleString()} ${MULT} <frac w='${whole.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac w='${incorrectWhole.toLocaleString()}' n='${incorrectNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> `;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalse()}
        correctAnswer={showCorrectAnswer}
        content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              justifyContent: 'center'
            }}
          >
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'center'
              }}
            >
              <TextStructure
                textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50, padding: 4 }}
                sentence={showCorrectAnswer ? correctSentence : incorrectSentence}
              />
            </ContentBox>
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAMixedNumberByAnInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
