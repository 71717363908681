import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { isEqual, isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { ADD, SUB } from '../../../../constants';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { sortNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhw',
  description: 'bhw',
  keywords: ['Add', 'Missing number', 'Part-whole model'],
  schema: z
    .object({
      number1: z.number().int().min(10).max(90).multipleOf(10),
      number2: z.number().int().min(3).max(9),
      number3: z.number().int().min(1).max(8),
      isFlipped: z.boolean()
    })
    .refine(val => val.number2 > val.number3, 'number3 must be smaller than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 90, 10);
    const number2 = randomIntegerInclusive(3, 9);
    const number3 = randomIntegerInclusive(1, number2 - 1);

    const isFlipped = getRandomFromArrayWithWeights([true, false], [1, 3]);

    return { number1, number2, number3, isFlipped };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, isFlipped },
      translate
    } = props;

    const sentence = isFlipped
      ? `${(
          number1 + number3
        ).toLocaleString()} ${ADD} <ans/> = ${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`
      : `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${(
          number1 + number3
        ).toLocaleString()} ${ADD} <ans/>`;

    return (
      <QF1ContentAndSentence
        questionHeight={1100}
        title={translate.ks1Instructions.completeTheNumberSentence()}
        Content={({ dimens }) => (
          <PartWholeModel top={number2} partition={[number3, '']} dimens={dimens} />
        )}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={[(number2 - number3).toString()]}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'bhx',
  description: 'bhx',
  keywords: ['Subtract', 'Missing number', 'Part-whole model'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(11)
        .max(98)
        .refine(val => val % 10 !== 0 && val % 10 !== 9),
      number2: z.number().int().min(1).max(9),
      isFlipped: z.boolean()
    })
    .refine(
      val => val.number1 % 10 < val.number2,
      'number2 must be larger than the ones in number1'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 98, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });
    const number2 = randomIntegerInclusive((number1 % 10) + 1, 9);

    const isFlipped = getRandomFromArrayWithWeights([true, false], [1, 3]);

    return { number1, number2, isFlipped };
  },
  Component: props => {
    const {
      question: { number1, number2, isFlipped },
      translate
    } = props;

    const number3 = Math.floor(number1 / 10) * 10;

    const sentence = isFlipped
      ? `${number3.toLocaleString()} ${SUB} <ans/> = ${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
      : `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = ${number3.toLocaleString()} ${SUB} <ans/>`;

    return (
      <QF1ContentAndSentence
        questionHeight={1100}
        title={translate.ks1Instructions.completeTheNumberSentence()}
        Content={({ dimens }) => (
          <PartWholeModel top={number2} partition={[number1 % 10, '']} dimens={dimens} />
        )}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={[(number2 - (number1 % 10)).toString()]}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'bhy',
  description: 'bhy',
  keywords: ['Add', 'Subtract', 'Constant difference'],
  schema: z
    .object({
      isAddition: z.boolean(),
      numbers: z.number().int().min(1).max(99).array().length(4)
    })
    .refine(val => {
      const xSorted = sortNumberArray(val.numbers);
      const answersToDisplay = val.isAddition
        ? [xSorted[3], xSorted[0], xSorted[2], xSorted[1]]
        : [xSorted[3], xSorted[1], xSorted[2], xSorted[0]];
      return answersToDisplay[0] > 9 && answersToDisplay[1] < 10 && val.isAddition
        ? answersToDisplay[0] + answersToDisplay[1] === answersToDisplay[2] + answersToDisplay[3] &&
            answersToDisplay[2] + answersToDisplay[3] <= 100
        : answersToDisplay[0] - answersToDisplay[1] === answersToDisplay[2] - answersToDisplay[3] &&
            answersToDisplay[3] < 10;
    }, 'additions must be less than 100 and must be able to create an equal sum on either side with 2 digit and 1 digit number'),
  simpleGenerator: () => {
    const isAddition = getRandomBoolean();
    const number1 = randomIntegerInclusive(11, 98);
    const number2 = randomIntegerInclusive(
      1,
      9,
      isAddition ? { constraint: x => x + number1 <= 100 } : undefined
    );
    const offset = randomIntegerInclusive(-1, 5, {
      constraint: x =>
        x !== 0 &&
        number1 + x < 100 &&
        (isAddition ? number2 - x > 0 : isInRange(1, 9)(number2 + x))
    });

    return {
      numbers: shuffle([
        number1,
        number2,
        number1 + offset,
        number2 + (isAddition ? -offset : offset)
      ]),
      isAddition
    };
  },
  Component: props => {
    const {
      question: { numbers, isAddition },
      translate
    } = props;

    const symbol = isAddition ? ADD : SUB;

    const xSorted = sortNumberArray(numbers);
    const answersToDisplay = isAddition
      ? [[xSorted[3], xSorted[0], xSorted[2], xSorted[1]]]
      : [[xSorted[3], xSorted[1], xSorted[2], xSorted[0]]];

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsToCompleteNumberSentence()}
        sentence={`<ans/> ${symbol} <ans/> = <ans/> ${symbol} <ans/>`}
        testCorrect={userAnswer =>
          userAnswer[0] !== undefined &&
          userAnswer[1] !== undefined &&
          userAnswer[2] !== undefined &&
          userAnswer[3] !== undefined &&
          (isAddition
            ? isEqual(userAnswer[0] + userAnswer[1])(userAnswer[2] + userAnswer[3])
            : isEqual(userAnswer[0] - userAnswer[1])(userAnswer[2] - userAnswer[3]))
        }
        items={numbers}
        customMarkSchemeAnswer={{ answersToDisplay: answersToDisplay }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MissingNumberProblems',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
