import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { Fragment, useContext } from 'react';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import Text from '../../typography/Text';
import { ADD } from '../../../constants';
import { Dimens } from '../../../theme/scaling';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { renderCounter } from '../../../utils/counters';

type ItemInfo = { component: string | JSX.Element; value: 'yellow' | 'red' | 'blue' | 'green' };

type Props = {
  title: string;
  pdfTitle?: string;
  interactiveRowIndex: number;
  /**
   * Max of 5 rows and 7 columns
   */
  counters: ('yellow' | 'red' | 'blue' | 'green')[][];
  questionHeight?: number;
  /**
   * This must be 2 distinct colors
   * The first two colors will be used to write the equation after each row.
   * Therefore, the counters prop should only consist of these two colors
   */
  counterColors: ('yellow' | 'red' | 'blue' | 'green')[];
};

/**
 * Question format 55 - draggable counters into circles
 */
export default function QF55DraggableCounterIntoCircles({
  title,
  pdfTitle,
  interactiveRowIndex,
  counters,
  counterColors,
  questionHeight
}: Props) {
  const displayMode = useContext(DisplayMode);

  const items: ItemInfo[] = counterColors.map(val => ({
    component: renderCounter(val),
    value: val
  }));

  const draggableSource = (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((_item, index) => (
        <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const numRows = counters.length;
  const numColumns = counters[0].length;
  const emptyState = filledArray(undefined, numColumns);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        hideBackground
        defaultState={displayMode === 'markscheme' ? counters[interactiveRowIndex] : emptyState}
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent'
        }}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <MeasureView>
              {dimens => (
                <DragZones
                  dimens={dimens}
                  numRows={numRows}
                  numColumns={numColumns}
                  interactiveRowIndex={interactiveRowIndex}
                  counters={counters}
                  counterColors={counterColors}
                />
              )}
            </MeasureView>
          }
          questionHeight={questionHeight}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      defaultState={emptyState}
      hideBackground
      testComplete={answer => answer.every(val => val !== undefined)}
      testCorrect={answer => arraysHaveSameContents(counters[interactiveRowIndex], answer.flat())}
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent'
      }}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <DragZones
                dimens={dimens}
                numRows={numRows}
                numColumns={numColumns}
                interactiveRowIndex={interactiveRowIndex}
                counters={counters}
                counterColors={counterColors}
              />
            )}
          </MeasureView>
        }
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const DragZones = ({
  dimens,
  numRows,
  numColumns,
  interactiveRowIndex,
  counters,
  counterColors
}: {
  dimens: Dimens;
  numRows: number;
  numColumns: number;
  interactiveRowIndex?: number;
  counters: ('yellow' | 'red' | 'blue' | 'green')[][];
  counterColors: ('yellow' | 'red' | 'blue' | 'green')[];
}) => (
  <View style={{ width: dimens.width, flexDirection: 'column', gap: 24, alignItems: 'center' }}>
    {counters.map((row, rowId) => {
      const color1Count = row.filter(color => color === counterColors[0]).length;
      const color2Count = row.filter(color => color === counterColors[1]).length;
      return (
        <View key={rowId} style={{ flexDirection: 'row', alignItems: 'center', gap: 50 }}>
          <View
            style={{
              flexDirection: 'row',
              maxWidth: dimens.width * 0.8,
              height: dimens.height / (numRows + 1),
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20
            }}
          >
            {row.map((col, colId) =>
              interactiveRowIndex !== rowId && row[colId] ? (
                <Fragment key={`${rowId}_${colId}`}>{renderCounter(col)}</Fragment>
              ) : (
                <EasyDragAndDropWithSingleZones.ZoneSingle
                  key={`${rowId}_${colId}`}
                  id={colId}
                  style={{
                    borderRadius: 999,
                    borderColor: 'black',
                    width: 96,
                    height: 96
                  }}
                />
              )
            )}
          </View>
          <Text style={{ maxWidth: dimens.width * 0.2, textAlign: 'center' }} variant="WRN400">
            {`${color1Count.toLocaleString()} ${ADD} ${color2Count.toLocaleString()} = ${numColumns.toLocaleString()}`}
          </Text>
        </View>
      );
    })}
  </View>
);
