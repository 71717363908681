import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { PartWholeModel } from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { Base10RepTo100sVariant } from 'common/src/components/question/representations/types';
import BaseTenRepresentation from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import { randomIntegerInclusive, seededRandom, shuffle } from 'common/src/utils/random';
import {
  base10ObjectToNumber,
  digitAtPowerIsNumber,
  numberOfNonZeroDigits,
  numberToBase10Object,
  powersOfTenPowToWord,
  ScientificNotation
} from 'common/src/utils/math';
import { useMemo } from 'react';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'aaE',
  description: 'aaE',
  keywords: ['Place value', 'Partition', 'Base 10', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(2).max(9),
    tens: z.number().int().min(2).max(9),
    hundreds: z.number().int().min(2).max(9)
  }),
  questionHeight: 1440,
  example: {
    ones: 4,
    tens: 2,
    hundreds: 2
  },
  simpleGenerator: () => {
    // Limit ones, tens and hundreds to 2-9
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    const hundreds = randomIntegerInclusive(2, 9);
    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens, hundreds });
    const variant = 'Cubes' as Base10RepTo100sVariant;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={answer =>
          parseInt(answer[0]) + parseInt(answer[1]) + parseInt(answer[2]) === number &&
          // 0 is not acceptable as any of the answers
          parseInt(answer[0]) !== 0 &&
          parseInt(answer[1]) !== 0 &&
          parseInt(answer[2]) !== 0
        }
        inputMaxCharacters={3}
        sentence={translate.answerSentences.numEquals3Ans(number)}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (hundreds * 100).toLocaleString(),
            (tens * 10).toLocaleString(),
            ones.toLocaleString()
          ]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aaF',
  description: 'aaF',
  keywords: ['Place value', 'Partition', 'Part-whole', 'Base 10', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(6)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    // Limit ones, tens and hundreds to 1-9:
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    const hundreds = randomIntegerInclusive(2, 6);
    return {
      hundreds,
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;
    const number = base10ObjectToNumber({ ones, tens, hundreds });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToCompleteSentence()}
        testCorrect={[hundreds.toString(), tens.toString(), ones.toString()]}
        sentence={translate.answerSentences.numEqualsHundredsTensOnes(number)}
        textStyle={{ fontSize: 28 }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={number}
            partition={[ones, tens * 10, hundreds * 100]}
            representation="base10"
            // Height increased to increase line distance between circles of Part-Whole Model:
            dimens={{ width: dimens.width, height: dimens.height * 1.15 }}
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  }
});

export const Question3 = newQuestionContent({
  uid: 'aaG',
  description: 'aaG',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  questionHeight: 1100,
  example: {
    ones: 5,
    tens: 1,
    hundreds: 2
  },
  simpleGenerator: () => {
    // Limit hundred and tens to 1-9
    const hundreds = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(0, 9, { constraint: x => x !== 1 });
    const ones =
      tens === 0
        ? randomIntegerInclusive(2, 9)
        : randomIntegerInclusive(0, 9, { constraint: x => x !== 1 });
    return {
      hundreds,
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;
    const number = base10ObjectToNumber({ ones, tens, hundreds });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToCompleteSentence()}
        testCorrect={[hundreds.toString(), tens.toString(), ones.toString()]}
        sentence={translate.answerSentences.numEqualsHundredsTensOnes(number)}
        textStyle={{ fontSize: 28 }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={number}
            partition={[ones, tens * 10, hundreds * 100]}
            // Height increased to increase line distance between circles of Part-Whole Model:
            dimens={{ width: dimens.width, height: dimens.height * 1.15 }}
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'aaH',
  description: 'aaH',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(90).multipleOf(10),
    hundreds: z.number().int().min(100).max(900).multipleOf(100),
    powerToShow: z.union([z.literal(0), z.literal(1), z.literal(2)])
  }),
  simpleGenerator: () => {
    const powerToShow = randomIntegerInclusive(0, 2) as 0 | 1 | 2;
    const ones = powerToShow === 0 ? randomIntegerInclusive(1, 9) : randomIntegerInclusive(0, 9);
    const tens =
      ones === 0 || powerToShow === 1
        ? randomIntegerInclusive(1, 9) * 10
        : randomIntegerInclusive(0, 9) * 10;
    const hundreds = randomIntegerInclusive(1, 9) * 100;
    return { ones, tens, hundreds, powerToShow };
  },
  Component: ({ question, translate }) => {
    const { ones, tens, hundreds, powerToShow } = question;

    const number = hundreds + tens + ones;

    let partition: (number | '$ans')[];
    switch (powerToShow) {
      case 0:
        if (tens === 0) {
          partition = [ones, '$ans'];
        } else {
          partition = [ones, '$ans', '$ans'];
        }
        break;
      case 1:
        if (ones === 0) {
          partition = [tens, '$ans'];
        } else {
          partition = ['$ans', tens, '$ans'];
        }
        break;
      case 2:
        if (ones === 0) {
          partition = ['$ans', hundreds];
        } else if (tens === 0) {
          partition = ['$ans', hundreds];
        } else {
          partition = ['$ans', '$ans', hundreds];
        }
        break;
    }

    const testCorrect = (answer: string[]) => {
      switch (powerToShow) {
        case 0:
          if (tens === 0) {
            return parseInt(answer[0]) === number - ones;
          }
          return (
            parseInt(answer[0]) + parseInt(answer[1]) === number - ones &&
            parseInt(answer[0]) !== 0 &&
            parseInt(answer[1]) !== 0
          );
        case 1:
          if (ones === 0) {
            return parseInt(answer[0]) === number - tens;
          }
          return (
            parseInt(answer[0]) + parseInt(answer[1]) === number - tens &&
            parseInt(answer[0]) !== 0 &&
            parseInt(answer[1]) !== 0
          );
        case 2:
          if (ones === 0 || tens === 0) {
            return parseInt(answer[0]) === number - hundreds;
          }
          return (
            parseInt(answer[0]) + parseInt(answer[1]) === number - hundreds &&
            parseInt(answer[0]) !== 0 &&
            parseInt(answer[1]) !== 0
          );
      }
    };

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={ones === 0 || tens === 0 ? [''] : ['', '']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyValidPartition() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

export const Question5 = newQuestionContent({
  uid: 'aaI',
  description: 'aaI',
  keywords: ['Place value', 'Partition', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const onesGenerated = randomIntegerInclusive(1, 9);
    const tensGenerated = randomIntegerInclusive(1, 9, {
      constraint: x => (onesGenerated === 1 && x !== 1) || onesGenerated !== 1
    });

    // Ones and tens cannot be 1, but can be 0.
    const ones = onesGenerated === 1 ? 0 : onesGenerated;
    const tens = tensGenerated === 1 ? 0 : tensGenerated;
    const hundreds = randomIntegerInclusive(1, 9);
    return { ones, tens, hundreds };
  },

  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate,
      displayMode
    } = props;
    const number = base10ObjectToNumber({ ones, tens, hundreds });

    const nonZeroesAmount = numberOfNonZeroDigits(number);

    const powerArray = ScientificNotation.fromNumber(number);

    const firstLineOfAnswersArray: number[] = [];

    let firstLine = `${number} =`;

    // This generates the first line of answers, returning the correct answer boxes and words/punctuation
    powerArray.digits.forEach((value, index) => {
      const pow = (powerArray.e - index) as 0 | 1 | 2;

      // All of these should only perform if the digit is not 0; otherwise, we should remove an answer box
      if (value !== 0) {
        firstLine += ` <ans/> ${translate.powersOfTen[powersOfTenPowToWord[pow]](0)}`;

        const answerToPush = (() => {
          switch (translate.powersOfTen[powersOfTenPowToWord[pow]](0)) {
            case 'hundreds':
              return value * 100;
            case 'tens':
              return value * 10;
            default:
              return value;
          }
        })();

        firstLineOfAnswersArray.push(answerToPush);

        if (index < nonZeroesAmount - 2) {
          firstLine += ',';
        }
        if (index === nonZeroesAmount - 2) {
          firstLine += ' ' + translate.misc.and();
        }
      }
    });
    firstLine += '.';

    let secondLine = '';
    // This generates the second line of answers, returning the correct amount of answer boxes.
    firstLineOfAnswersArray.forEach((_value, index) => {
      if (index === 0) {
        secondLine += `${number} = <ans/>`;
      } else {
        secondLine += ' + <ans/>';
      }
    });

    const answers1 = [hundreds, tens, ones].filter(val => val !== 0);
    const answers2 = [hundreds * 100, tens * 10, ones].filter(val => val !== 0);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[firstLine, secondLine]}
        testCorrect={[answers1.map(val => val.toString()), answers2.map(val => val.toString())]}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
      />
    );
  }
});

// Question6 exported to Q abH
const Question6 = newQuestionContent({
  uid: 'aaJ',
  description: 'aaJ',
  keywords: ['Place value', 'Partition', '1,000', 'Thousand'],
  schema: z
    .object({
      digit: z.number().int().min(1).max(9),
      number1: z.number().int().min(100).max(999),
      number2: z.number().int().min(100).max(999),
      number3: z.number().int().min(100).max(999)
    })
    .refine(val => {
      const base10Num = numberToBase10Object(val.number1);
      return (
        base10Num.ones === val.digit &&
        base10Num.tens !== val.digit &&
        base10Num.hundreds !== val.digit
      );
    }, 'digit must appear only in the ones of number1')
    .refine(val => {
      const base10Num = numberToBase10Object(val.number2);
      return (
        base10Num.tens === val.digit &&
        base10Num.ones !== val.digit &&
        base10Num.hundreds !== val.digit
      );
    }, 'digit must appear only in the tens of number2')
    .refine(val => {
      const base10Num = numberToBase10Object(val.number3);
      return (
        base10Num.hundreds === val.digit &&
        base10Num.tens !== val.digit &&
        base10Num.ones !== val.digit
      );
    }, 'digit must appear only in the hundreds of number3'),
  simpleGenerator: () => {
    const digit = randomIntegerInclusive(1, 9);

    const number1 = randomIntegerInclusive(100, 999, {
      constraint: x => {
        const base10Num = numberToBase10Object(x);
        return base10Num.ones === digit && base10Num.tens !== digit && base10Num.hundreds !== digit;
      }
    });

    const number2 = randomIntegerInclusive(100, 999, {
      constraint: x => {
        const base10Num = numberToBase10Object(x);
        return base10Num.tens === digit && base10Num.ones !== digit && base10Num.hundreds !== digit;
      }
    });

    const number3 = randomIntegerInclusive(100, 999, {
      constraint: x => {
        const base10Num = numberToBase10Object(x);
        return base10Num.hundreds === digit && base10Num.tens !== digit && base10Num.ones !== digit;
      }
    });

    return { digit, number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { digit, number1, number2, number3 },
      translate
    } = props;

    const numbers = useMemo(() => {
      return shuffle([number1, number2, number3], { random: seededRandom(props.question) });
    }, [number1, number2, number3, props.question]);

    const powerOfDigit = (number: number) => {
      if (digitAtPowerIsNumber(number, 0, [digit])) {
        return 'ones';
      } else if (digitAtPowerIsNumber(number, 1, [digit])) {
        return 'tens';
      } else if (digitAtPowerIsNumber(number, 2, [digit])) {
        return 'hundreds';
      } else {
        throw Error('Digit was not present in the first 3 powers.');
      }
    };

    return (
      <QF37SentencesDrag
        title={translate.instructions.whatIsTheValueOfXInEachOfTheNumbersDragCards(digit)}
        pdfTitle={translate.instructions.whatIsTheValueOfXInEachOfTheNumbersUseCards(digit)}
        items={[
          {
            component: translate.misc.numberOfHundreds(digit),
            value: 'hundreds'
          },
          {
            component: translate.misc.numberOfTens(digit),
            value: 'tens'
          },
          {
            component: translate.misc.numberOfOnes(digit),
            value: 'ones'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={numbers.map(number => `${number.toLocaleString()} <ans/>`)}
        testCorrect={numbers.map(number => [powerOfDigit(number)])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
export const aaJ = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
