import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import { ADD } from 'common/src/constants';
import {
  getRandomSubArrayFromArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { findFactors } from 'common/src/utils/factors';
import { compareFractions, Fraction, fractionToDecimal } from 'common/src/utils/fractions';
import { filledArray, sortNumberArray } from 'common/src/utils/collections';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { greatestCommonDivisor } from 'common/src/utils/multiples';
import { BarModelInteractiveWithFixedCellsWithState } from '../../../../components/question/representations/BarModelInteractiveWithFixedCells';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apL',
  description: 'apL',
  keywords: ['Add', 'Fractions', 'Denominator'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(3).max(5),
    number3: z.number().int(),
    number4: z.number().int(),
    answer1: z.number().int(),
    answer2: z.number().int()
  }),
  simpleGenerator: () => {
    const { number1, number2, number3, number4, answer1, answer2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 5);
        const number2 = randomIntegerInclusive(3, 5);

        const number3 = number1 * number2;
        let number4 = 1;

        switch (number3) {
          case 6:
            number4 = 1;
            break;
          case 8:
            number4 = getRandomFromArray([1, 3]);
            break;
          case 9:
            number4 = getRandomFromArray([1, 2, 4]);
            break;
          case 10:
            number4 = getRandomFromArray([1, 3]);
            break;
          case 12:
            number4 = getRandomFromArray([1, 5]);
            break;
          case 15:
            number4 = getRandomFromArray([1, 2, 4, 7]);
            break;
          case 16:
            number4 = getRandomFromArray([1, 3, 5, 7]);
            break;
          case 20:
            number4 = getRandomFromArray([1, 3, 7, 9]);
          case 25:
            number4 = getRandomFromArray([1, 2, 3, 4, 6, 7, 9, 11, 12]);
            break;
        }

        // Answers
        const answer1 = number3;
        const answer2 = number2 + number4;

        return { number1, number2, number3, number4, answer1, answer2 };
      },
      ({ answer1, answer2 }) => greatestCommonDivisor([answer2, answer1]) === 1
    );

    return { number1, number2, number3, number4, answer1, answer2 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, answer1, answer2 },
      translate
    } = props;

    const sentence = `<frac n='1' d='${number1.toLocaleString()}'/> ${ADD} <frac n='${number4.toLocaleString()}' d='${number3.toLocaleString()}'/> = <frac nAns='' dAns=''/>`;

    const fixedCells = filledArray(
      [...filledArray(true, 1), ...filledArray(false, number1 - 1)],
      number2
    );

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeAddition()}<br/>${translate.instructions.tapToShadeBarModelToHelp()}`}
        pdfTitle={`${translate.instructions.completeAddition()}<br/>${translate.instructions.shadeBarModelToHelp()}`}
        testCorrect={[answer2.toString(), answer1.toString()]}
        sentence={sentence}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <BarModelInteractiveWithFixedCellsWithState
            id="barmodel"
            numberOfRows={number2}
            numberOfCols={number1}
            fixedCells={fixedCells}
            tableHeight={dimens.height}
            tableWidth={dimens.width}
            fixedCellsColor="barYellow3"
            interactiveCellsColor="barGreen1"
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'apM',
  description: 'apM',
  keywords: ['Fractions', 'Addition', 'Denominator'],
  schema: z.object({
    statementFractions: fractionSchema(
      z.number().int().min(1).max(5),
      z.number().int().min(2).max(6)
    )
      .array()
      .length(3),
    answerOptionNumbers: z.array(z.number().int().min(2).max(25)).length(3),
    number3: z.number().int().min(6).max(30)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusive(3, 6, {
      constraint: x => x !== number1
    });
    const number3 = number1 * number2;

    const number4 = randomIntegerInclusive(1, number1 - 1);
    const number5 = number4 * number2;

    const [number6, number8] = randomUniqueIntegersInclusive(1, number2 - 1, 2);
    const number7 = number6 * number1;
    const number9 = number8 * number1;

    const statementFractions: Fraction[] = [
      [number4, number1],
      [number6, number2],
      [number8, number2]
    ];

    const answerOptionNumbers = [number5, number7, number9];

    return { statementFractions, answerOptionNumbers, number3 };
  },
  Component: props => {
    const {
      question: { statementFractions, answerOptionNumbers, number3 },
      translate,
      displayMode
    } = props;

    const answerOptions = answerOptionNumbers.map(num => ({
      component: (
        <TextStructure
          sentence={`<frac n='${num}' d='${number3}'/> ${ADD} <frac n='1' d='${number3}'/>`}
          fractionDividerStyle={{ marginVertical: 2 }}
          textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
          textVariant="WRN700"
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
        />
      ),
      value: fractionToDecimal(num, number3) + fractionToDecimal(1, number3)
    }));

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={shuffledAnswerOptions}
        statements={statementFractions.map(frac => ({
          lhsComponent: (
            <TextStructure
              sentence={`<frac n='${frac[0]}' d='${frac[1]}'/> ${ADD} <frac n='1' d='${number3}'/> =`}
            />
          ),
          correctAnswer: fractionToDecimal(...frac) + fractionToDecimal(1, number3)
        }))}
        statementStyle={{ justifyContent: 'center' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apN',
  description: 'apN',
  keywords: ['Addition', 'Fractions', 'Denominator'],
  schema: z.object({
    number1: z.number().int().min(5).max(10),
    number3: z.number().int().min(3).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 10);
    const number3 = randomIntegerInclusive(3, number1 - 1);

    return { number1, number3 };
  },
  Component: props => {
    const {
      question: { number1, number3 },
      translate,
      displayMode
    } = props;

    const number2 = number1 * 2;

    const answer1 = 3;
    const answer2 = 5;
    const answer3 = number3 * 2 + 1;

    const essentialSentence = `<frac n='1' d='${number1}'/> ${ADD} <frac n='1' d='${number2}'/> = <frac nAns='' d='${number2}'/>`;

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac n='2' d='${number1}'/> ${ADD} <frac n='1' d='${number2}'/> = <frac nAns='' d='${number2}'/>`,
          answer: answer2
        },
        {
          sentence: `<frac n='${number3}' d='${number1}'/> ${ADD} <frac n='1' d='${number2}'/> = <frac nAns='' d='${number2}'/>`,
          answer: answer3
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheAdditions()}
        testCorrect={[[answer1.toString()], [sentenceTwo.answer.toString()]]}
        sentences={[essentialSentence, sentenceTwo.sentence]}
        pdfDirection="row"
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        pdfContainerStyle={{ flex: 1 }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'apO',
  description: 'apO',
  keywords: ['Addition', 'Fractions'],
  schema: z.object({
    number1: numberEnum([12, 16, 18, 20, 24, 30, 32]),
    number2: z.number().int().min(1).max(15),
    number3: z.number().int().min(2).max(16),
    number4: z.number().int().min(2).max(16),
    number5: z.number().int().min(2).max(16)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([12, 16, 18, 20, 24, 30, 32] as const);

    // number2 must meet ALL the following criteria:
    // - odd
    // - less than half of number1
    // - number1 not divisible by number2
    const number2 = randomIntegerInclusive(1, number1 / 2 - 1, {
      constraint: x => x % 2 !== 0 && number1 % x !== 0
    });

    const number1Factors = findFactors(number1);
    // Remove first and last factors (1, number1)
    // number1 was chosen as there will always be at least 3 other factors remaining
    number1Factors.pop();
    number1Factors.shift();

    const [number3, number4, number5] = sortNumberArray(
      getRandomSubArrayFromArray(number1Factors, 3)
    );

    return { number1, number2, number3, number4, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5 },
      translate,
      displayMode
    } = props;

    const answer1 = number1 / number3 + number2;
    const answer2 = number1 / number4 + number2;
    const answer3 = number1 / number5 + number2;

    const essentialSentence = `<frac n='1' d='${number3}'/> ${ADD} <frac n='${number2}' d='${number1}'/> = <frac nAns='' dAns=''/>`;

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac n='1' d='${number4}'/> ${ADD} <frac n='${number2}' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: answer2
        },
        {
          sentence: `<frac n='1' d='${number5}'/> ${ADD} <frac n='${number2}' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: answer3
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    const correctFrac1 = [answer1, number1];
    const correctFrac2 = [sentenceTwo.answer, number1];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheAdditions()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], correctFrac1) &&
          compareFractions(userAnswer[1], correctFrac2)
        }
        inputMaxCharacters={2}
        sentences={[essentialSentence, sentenceTwo.sentence]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            correctFrac1.map(num => num.toLocaleString()),
            correctFrac2.map(num => num.toLocaleString())
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        pdfDirection="row"
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        pdfContainerStyle={{ flex: 1 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'apP',
  description: 'apP',
  keywords: ['Part-whole', 'Addition', 'Fractions'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 6, 12]),
    number2: numberEnum([1, 5, 7, 11, 13])
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 6, 12] as const);
    // Prevent answer being an improper fraction
    const number2 =
      number1 === 2
        ? getRandomFromArray([1, 5, 7, 11] as const)
        : getRandomFromArray([1, 5, 7, 11, 13] as const);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const answer1 = 24 / number1 + number2;
    const answer2 = 24;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingFractionPartWholeModel()}
        sentence={`? = <frac nAns='' dAns=''/>`}
        sentenceStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        testCorrect={userAnswer => compareFractions([...userAnswer], [answer1, answer2])}
        inputMaxCharacters={2}
        mainPanelStyle={{ flexDirection: 'row' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <PartWholeModel
            top={'?'}
            partition={[`<frac n='1' d='${number1}'/>`, `<frac n='${number2}' d='24'/>`]}
            representation="text"
            dimens={dimens}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer1.toLocaleString(), answer2.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'apQ',
  description: 'apQ',
  keywords: ['Addition', 'Inverse', 'Fraction'],
  schema: z.object({
    number1: z.number().int().min(6).max(12),
    number2: z.number().int().min(12).max(24),
    number3: z.number().int().min(2).max(23),
    number4: z.number().int().min(6).max(45),
    answer1: z.number().int().min(2).max(11),
    number5: z.number().int().min(6).max(12),
    number6: z.number().int().min(12).max(24),
    number7: z.number().int().min(2).max(11),
    number8: z.number().int().min(6).max(45),
    answer2: z.number().int().min(2).max(23)
  }),
  simpleGenerator: () => {
    const [number1, number5] = randomUniqueIntegersInclusive(6, 12, 2);

    const number2 = 2 * number1;
    const { answer1, number3 } = rejectionSample(
      () => {
        const answer1 = randomIntegerInclusive(2, number1 - 1);
        const number3 = randomIntegerInclusive(2, number2 - 1);

        return { answer1, number3 };
      },
      ({ answer1, number3 }) => number3 + 2 * answer1 < number2
    );

    const number4 = number3 + 2 * answer1;

    const number6 = 2 * number5;
    const { answer2, number7 } = rejectionSample(
      () => {
        const answer2 = randomIntegerInclusive(2, number6 - 1);
        const number7 = randomIntegerInclusive(2, number5 - 1);

        return { answer2, number7 };
      },
      ({ answer2, number7 }) => answer2 + 2 * number7 < number6
    );

    const number8 = answer2 + 2 * number7;

    return {
      number1,
      number2,
      number3,
      number4,
      answer1,
      number5,
      number6,
      number7,
      number8,
      answer2
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        answer1,
        number5,
        number6,
        number7,
        number8,
        answer2
      },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbers()}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={[
          `<frac n='${number3}' d='${number2}'/> ${ADD} <frac nAns='' d='${number1}'/> = <frac n='${number4}' d='${number2}'/>`,
          `<frac nAns='' d='${number6}'/> ${ADD} <frac n='${number7}' d='${number5}'/> = <frac n='${number8}' d='${number6}'/>`
        ]}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddFractionsWithin1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
