import { ReactNode, useContext, useMemo } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import { ADD, MULT } from 'common/src/constants';
import { colors } from 'common/src/theme/colors';
import { ScientificNotation } from 'common/src/utils/math';
import { projectSetState, SetState } from 'common/src/utils/react';
import { range } from 'common/src/utils/collections';
import { all, create, number } from 'mathjs';
import { withStateHOC } from '../../../../stateTree';
import { noop } from '../../../../utils/flowControl';
import { DisplayMode } from '../../../../contexts/displayMode';
import { findExchanges } from '../../../../utils/exchanges';
import { InputCell, LabelCell } from './Cells';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../typography/Text';
import { useI18nContext } from '../../../../i18n/i18n-react';
import React from 'react';

const ROWS = ['top', 'multiplier', 'partialProduct1', 'partialProduct2', 'answer'] as const;
type Row = (typeof ROWS)[number];
type UserAnswer = {
  top: string[];
  multiplier: string[];
  partialProduct1: string[];
  partialProduct2: string[];
  answer: string[];
  exchanges: string[];
};

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export type MissingDigitsLongMultiplicationProps = {
  topNumber: number;
  /**
   * 1 digit multiplier
   */
  multiplier: number;
  partialProduct1: number;
  partialProduct2: number;
  answerNumber?: number;
  topNumberMissingDigits?: number[];
  multiplierMissingDigits?: number[];
  partialProduct1MissingDigits?: number[];
  partialProduct2MissingDigits?: number[];
  answerNumberMissingDigits?: number[];
  /**
   * The user answer.
   *
   * There are three arrays, one for each partial product and one for the answer. The indices of the array
   * correspond to the digit at that position in the number. So the element at index 2 is the user's answer for the
   * hundreds, for example.
   *
   * For quality of life, we support this array being initially empty or populated with undefined - it will be filled
   * in as the user starts giving their answers.
   */
  userAnswer?: UserAnswer;
  setUserAnswer?: SetState<UserAnswer>;
  /**
   * Styles to be applied to the container of this component.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Removes extra cells from top/bottom rows and left/right columns around the operation.
   * Defaults to false.
   */
  removeExtraCells?: boolean;
  /**
   * Usable dimensions for the question.
   */
  dimens: Dimens;
};

/**
 * This component renders a long multiplication on a grid, made to represent grid paper.
 * Due to the size of the input area, we currently only accept a 2 partial products
 * The number of columns of cells is determined by the numbers and answer.
 */
export default function MissingDigitsLongMultiplication({
  topNumber,
  multiplier,
  partialProduct1,
  partialProduct2,
  answerNumber,
  topNumberMissingDigits = [],
  multiplierMissingDigits = [],
  partialProduct1MissingDigits = [],
  partialProduct2MissingDigits = [],
  answerNumberMissingDigits = [],
  containerStyle,
  removeExtraCells = false,
  userAnswer = {
    top: [],
    multiplier: [],
    partialProduct1: [],
    partialProduct2: [],
    answer: [],
    exchanges: []
  },
  setUserAnswer = () => noop,
  dimens
}: MissingDigitsLongMultiplicationProps) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  // If the answerNumber prop was missing, don't show the answer number.
  const hideAnswerDigits = answerNumber === undefined;

  const hasExchanges = findExchanges(partialProduct1, partialProduct2).length > 0;

  answerNumber = answerNumber ?? number(math.evaluate(`${topNumber} * ${multiplier}`));
  const topNumberSci = ScientificNotation.fromNumber(topNumber);
  const multiplierSci = ScientificNotation.fromNumber(multiplier);

  // Get an array of digits to show for each number, starting with the lowest power and going up.
  const getDigits = (number: number, missingDigits: number[]): (number | '$ans')[] => {
    const sci = ScientificNotation.fromNumber(number);
    const minRange = Math.min(sci.resolution, 0);
    const maxRange = Math.max(sci.e, 0);
    const digits = range(minRange, maxRange).map(pow =>
      missingDigits.includes(pow) ? '$ans' : sci.unsignedDigitAt(pow)
    );
    return digits;
  };

  const digits: Record<Row, (number | '$ans')[]> = {
    top: getDigits(topNumber, topNumberMissingDigits),
    multiplier: getDigits(multiplier, multiplierMissingDigits),
    partialProduct1: getDigits(partialProduct1, partialProduct1MissingDigits),
    partialProduct2: getDigits(partialProduct2, partialProduct2MissingDigits),
    answer: getDigits(answerNumber, answerNumberMissingDigits)
  };

  // Determine the longest number out of the two numbers passed and the answer.
  const topNumberExp = topNumberSci.e;
  const multiplierExp = multiplierSci.e;

  const longestNumber = Math.max(
    digits.top.length,
    digits.multiplier.length,
    digits.partialProduct1.length,
    digits.partialProduct2.length,
    digits.answer.length
  );

  // If the longest number is the length of the answer, and this is longer than either numbers passed,
  // the question will need one empty column either side of the numbers;
  // otherwise, the question will need two empty columns to the left and one to the right.
  // Also take into account when the topNumber, bottomNumber and partialProducts exponents are not the same to ensure
  // the operation sign is placed in the correct cell.
  const gridColumns =
    longestNumber === digits.answer.length &&
    longestNumber !== digits.top.length &&
    longestNumber !== digits.multiplier.length &&
    longestNumber !== digits.partialProduct1.length &&
    longestNumber !== digits.partialProduct2.length
      ? topNumberExp !== multiplierExp
        ? longestNumber + 3
        : longestNumber + 2
      : longestNumber + 3;

  // Calculate the width/height of each Cell. Dimens.height is used to ensure grid does not overflow. Defaults to 96 minimum square.
  const cellDimens = Math.max(
    Math.min(
      dimens.height / 6 - (displayMode === 'digital' ? 10 : 0),
      dimens.width / Math.max(6, gridColumns)
    ),
    hasExchanges ? 74 : 96
  );

  const gridLineWidth = 1;
  const answerBoxBorderWidth = displayMode === 'digital' ? 3 : 1;
  const answerRowThickLinesWidth = 1;
  const sharedStyles = useSharedStyles(
    cellDimens,
    gridLineWidth,
    gridColumns,
    answerRowThickLinesWidth
  );
  const signLocation = gridColumns - longestNumber - 2;
  /**
   * Function to create a row of Cells.
   * At the moment we assume that the partial products and answer are answer boxes and the equation is shown as digits
   */
  const getRow = (row: Row): { gridCells: ReactNode[]; inputCells: ReactNode[] } => {
    const rowIndex = ROWS.indexOf(row);
    const answerCells: ReactNode[] = [];

    const gridCells: ReactNode[] = range(0, gridColumns - 1).map(i => {
      // Ones digit goes at i === gridColumns-2 (the second to last cell), and the next digit comes just before, etc.
      const indexOfDigit = gridColumns - 2 - i;
      const numberDigits = digits[row];

      if (i === signLocation && (row === 'multiplier' || row === 'partialProduct2')) {
        // Operation symbols
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            character={row === 'multiplier' ? MULT : ADD}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      } else if (0 <= indexOfDigit && indexOfDigit < numberDigits.length) {
        const digit = numberDigits[indexOfDigit];
        if (digit === '$ans') {
          // Figure out which input box needs focusing. It's the last $ans in the first row with an $ans.
          const rowWithFirstInput = (
            ['top', 'multiplier', 'partialProduct1', 'partialProduct2', 'answer'] as const
          ).find(type => digits[type].includes('$ans'));
          const indexOfAutoFocusedDigit =
            rowWithFirstInput === undefined
              ? undefined
              : digits[rowWithFirstInput].lastIndexOf('$ans');

          const numberUserAnswer = userAnswer[row];
          const numberSetUserAnswer = projectSetState(setUserAnswer, row);
          const autoFocus = rowWithFirstInput === row && indexOfAutoFocusedDigit === indexOfDigit;

          // Merge above or below if there's an adjacent row there with any number of answer boxes
          const above = getAdjacentRowAbove(row);
          const mergeTopBorder = above !== null && digits[above].some(it => it === '$ans');
          const below = getAdjacentRowBelow(row);
          const mergeBottomBorder = below !== null && digits[below].some(it => it === '$ans');

          // Merge left or right if there's an answer box or a label to the left or right in this or any adjacent row
          const mergeLeftBorder =
            numberDigits.some((x, j) => j > indexOfDigit && x !== undefined) ||
            (above !== null && digits[above].some((x, j) => j > indexOfDigit && x !== undefined)) ||
            (below !== null && digits[below].some((x, j) => j > indexOfDigit && x !== undefined));
          const mergeRightBorder =
            numberDigits.some((x, j) => j < indexOfDigit && x !== undefined) ||
            (above !== null && digits[above].some((x, j) => j < indexOfDigit && x !== undefined)) ||
            (below !== null && digits[below].some((x, j) => j < indexOfDigit && x !== undefined));
          // Push the answer cell to a separate array - these are positioned absolutely, all as siblings to each other
          answerCells.push(
            <InputCell
              key={`${rowIndex}-${i}`}
              rowIndex={rowIndex}
              colIndex={removeExtraCells ? i - 1 : i}
              autoFocus={autoFocus}
              numberSetUserAnswer={numberSetUserAnswer}
              numberUserAnswer={numberUserAnswer}
              indexOfDigit={indexOfDigit}
              mergeLeftBorder={mergeLeftBorder}
              mergeRightBorder={mergeRightBorder}
              mergeTopBorder={mergeTopBorder}
              mergeBottomBorder={mergeBottomBorder}
              gridLineWidth={gridLineWidth}
              cellDimens={cellDimens}
              answerBoxBorderWidth={answerBoxBorderWidth}
              showBorderAroundBoxesOnPdf={false}
            />
          );
          // Return an empty cell, just for the grid lines
          return (
            <LabelCell
              key={i}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
        } else {
          return (
            <LabelCell
              key={i}
              character={row === 'answer' && hideAnswerDigits ? undefined : digit.toLocaleString()}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
        }
      } else if (removeExtraCells && (i === 0 || i === gridColumns - 1)) {
        // No empty cells wanted on left or right-hand sides
        return;
      } else {
        // Empty cell
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      }
    });
    return { gridCells, inputCells: answerCells };
  };

  const getAnswerLines = () => {
    const right = removeExtraCells ? gridLineWidth : cellDimens + gridLineWidth;
    // always have 2 partial products
    const topRow1 = 2 * cellDimens - answerRowThickLinesWidth / 2;
    const topRow2 = 4 * cellDimens - answerRowThickLinesWidth / 2;
    const topRow3 = 5 * cellDimens - answerRowThickLinesWidth / 2;
    const offSet = 3;
    const digitCount = signLocation !== 1 ? gridColumns - offSet + 1 : gridColumns - offSet;

    const positions = [topRow1, topRow2];
    if (hasExchanges) positions.push(topRow3);

    return (
      <>
        {positions.map((val, i) => (
          <View
            key={`answerLine_${i}`}
            style={[
              sharedStyles.answerLineBorder,
              {
                width: digitCount * cellDimens - gridLineWidth,
                backgroundColor: colors.prussianBlue,
                position: 'absolute',
                top: val,
                right: right,
                zIndex: 10
              }
            ]}
          />
        ))}
      </>
    );
  };

  const { gridCells: topRow, inputCells: topInputs } = getRow('top');
  const { gridCells: multiplierRow, inputCells: multiplierInputs } = getRow('multiplier');
  const { gridCells: partialProduct1Row, inputCells: partialProduct1Inputs } =
    getRow('partialProduct1');
  const { gridCells: partialProduct2Row, inputCells: partialProduct2Inputs } =
    getRow('partialProduct2');
  const { gridCells: answerRow, inputCells: answerInputs } = getRow('answer');

  const exchangeRow = () =>
    range(0, gridColumns - 1).map(i => {
      if (removeExtraCells && (i === 0 || i === gridColumns - 1)) {
        // No empty cells wanted on left or right-hand sides
        return;
      } else if (i > signLocation && i < gridColumns - (removeExtraCells ? 0 : 1)) {
        const numberUserAnswer = userAnswer['exchanges'];
        const numberSetUserAnswer = projectSetState(setUserAnswer, 'exchanges');
        return (
          <React.Fragment key={`exchange-${i}`}>
            <LabelCell
              key={i}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
            <InputCell
              key={`exchange-${i}`}
              colIndex={removeExtraCells ? i - 1 : i}
              autoFocus={false}
              numberSetUserAnswer={numberSetUserAnswer}
              numberUserAnswer={numberUserAnswer}
              indexOfDigit={i}
              mergeLeftBorder={i - 1 - signLocation !== 0}
              mergeRightBorder={i !== gridColumns - 2}
              gridLineWidth={gridLineWidth}
              cellDimens={cellDimens}
              answerBoxBorderWidth={answerBoxBorderWidth}
              showBorderAroundBoxesOnPdf={false}
              isExchange
            />
          </React.Fragment>
        );
      } else {
        // Empty cell
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      }
    });

  return (
    <>
      <View style={[sharedStyles.tableContainer, containerStyle]}>
        <View style={sharedStyles.tableRow}>{topRow}</View>
        <View style={sharedStyles.tableRow}>{multiplierRow}</View>
        <View style={sharedStyles.tableRow}>{partialProduct1Row}</View>
        <View style={sharedStyles.tableRow}>{partialProduct2Row}</View>
        <View style={sharedStyles.tableRow}>{answerRow}</View>
        {hasExchanges && <View style={sharedStyles.tableRow}>{exchangeRow()}</View>}
        {/* Absolutely-positioned elements: */}
        {/* We do this to ensure that the answer box borders are correct when we select them */}
        {topInputs}
        {multiplierInputs}
        {partialProduct1Inputs}
        {partialProduct2Inputs}
        {answerInputs}
        {getAnswerLines()}
      </View>
      {hasExchanges && displayMode === 'digital' && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <AssetSvg name={'Info'} width={25} height={26} />
          <Text variant="WRN400" style={{ fontSize: 22 }}>
            {translate.informationStrings.useWorkingSpaceToHelp()}
          </Text>
          <AssetSvg name={'Cell'} width={34} height={34} />
        </View>
      )}
    </>
  );
}

/**
 * Get the adjacent row below the given row, or null if there isn't one.
 * Rows across a thick line do not count as adjacent.
 */
const getAdjacentRowBelow = (row: Row): Row | null =>
  row === 'top' ? 'multiplier' : row === 'partialProduct1' ? 'partialProduct2' : null;
/**
 * Get the adjacent row above the given row, or null if there isn't one.
 * Rows across a thick line do not count as adjacent.
 */
const getAdjacentRowAbove = (row: Row): Row | null =>
  row === 'multiplier' ? 'top' : row === 'partialProduct2' ? 'partialProduct1' : null;

export const MissingDigitsLongMultiplicationWithState = withStateHOC(
  MissingDigitsLongMultiplication,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer',
    defaults: ({
      topNumberMissingDigits = [],
      multiplierMissingDigits = [],
      partialProduct1MissingDigits = [],
      partialProduct2MissingDigits = [],
      answerNumberMissingDigits = []
    }) => ({
      // Default to all answer boxes empty
      defaultState: {
        top: [],
        multiplier: [],
        partialProduct1: [],
        partialProduct2: [],
        answer: [],
        exchanges: []
      },
      // Check that all answer boxes are filled
      testComplete: state =>
        // Check that each answer box's answer is truthy (i.e. not undefined and non-empty)
        topNumberMissingDigits.every(i => state?.top[i]) &&
        multiplierMissingDigits.every(i => state?.multiplier[i]) &&
        partialProduct1MissingDigits.every(i => state?.partialProduct1[i]) &&
        partialProduct2MissingDigits.every(i => state?.partialProduct2[i]) &&
        answerNumberMissingDigits.every(i => state?.answer[i])
    })
  }
);

const useSharedStyles = (
  cellDimens: number,
  gridLineWidth: number,
  gridColumns: number,
  answerRowThickLinesWidth?: number
) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const minBorderWidth = 1 / scaleFactor;
  const minAnswerBorderWidth = 1 / scaleFactor;
  return useMemo(
    () =>
      StyleSheet.create({
        tableContainer: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderColor: colors.gridBlue
        },
        tableRow: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: Math.ceil(cellDimens) * gridColumns
        },
        answerLineBorder: {
          borderWidth: Math.max(
            minAnswerBorderWidth,
            answerRowThickLinesWidth ? answerRowThickLinesWidth : 0
          )
        },
        cell: {
          width: cellDimens,
          height: cellDimens,
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderColor: colors.gridBlue,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    [
      minBorderWidth,
      gridLineWidth,
      cellDimens,
      gridColumns,
      minAnswerBorderWidth,
      answerRowThickLinesWidth
    ]
  );
};
