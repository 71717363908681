import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from 'common/src/utils/random';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const triangles = [
  'triangle',
  'right angle triangle',
  'long right angle triangle',
  'scalene triangle',
  'narrow isosceles triangle',
  'wide isosceles triangle'
] as const;

const nonTriangles = ['square', 'rectangle', 'pentagon', 'hexagon', 'heptagon', 'octagon'] as const;

const shapes2d = [...triangles, ...nonTriangles] as const;

const colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const shapeToSides = (shape: (typeof shapes2d)[number]) => {
  switch (shape) {
    case 'triangle':
    case 'right angle triangle':
    case 'long right angle triangle':
    case 'scalene triangle':
    case 'narrow isosceles triangle':
    case 'wide isosceles triangle':
      return 3;
    case 'square':
    case 'rectangle':
      return 4;
    case 'pentagon':
      return 5;
    case 'hexagon':
      return 6;
    case 'heptagon':
      return 7;
    case 'octagon':
      return 8;
  }
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhC',
  description: 'bhC',
  keywords: ['Shape', '2-D shape', 'Sides'],
  schema: z.object({
    shape: z.enum(shapes2d),
    color: z.enum(colors)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(shapes2d);

    const color = getRandomFromArray(colors);

    return { shape, color };
  },
  Component: ({ question: { shape, color }, translate }) => {
    const sides = shapeToSides(shape);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManySides()}
        Content={({ dimens }) => (
          <AssetSvg
            name={getShapeSvgByShapeAndColor(shape, color)}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        sentence="<ans/>"
        testCorrect={[sides.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhE',
  description: 'bhE',
  keywords: ['Shape', '2-D shape', 'Sides'],
  schema: z
    .object({
      shape: z.enum(['triangle', ...nonTriangles]),
      firstNumberItem: z.number().int().min(3).max(7)
    })
    .refine(
      val =>
        shapeToSides(val.shape) >= val.firstNumberItem &&
        shapeToSides(val.shape) <= val.firstNumberItem + 3,
      'Total sides of the shape must be greater than or equal to firstNumberItem, and less than or equal to firstNumberItem + 3'
    ),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['triangle', ...nonTriangles] as const);

    const firstNumberItem = randomIntegerInclusive(3, 7, {
      constraint: x => shapeToSides(shape) >= x && shapeToSides(shape) <= x + 3
    });

    return { shape, firstNumberItem };
  },
  Component: props => {
    const {
      question: { shape, firstNumberItem },
      translate
    } = props;

    const shapeToString = (() => {
      switch (shape) {
        case 'triangle':
          return translate.shapes.ATriangle();
        case 'square':
          return translate.shapes.ASquare();
        case 'rectangle':
          return translate.shapes.ARectangle();
        case 'pentagon':
          return translate.shapes.APentagon();
        case 'hexagon':
          return translate.shapes.AHexagon();
        case 'heptagon':
          return translate.shapes.AHeptagon();
        case 'octagon':
          return translate.shapes.AnOctagon();
      }
    })();

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.completeTheSentence()}
        // PPT specifically asks for these numbers to be in consecutive order, not shuffled:
        items={[firstNumberItem, firstNumberItem + 1, firstNumberItem + 2, firstNumberItem + 3]}
        actionPanelVariant="end"
        itemVariant="square"
        sentence={translate.ks1AnswerSentences.shapeHasAnsSides(shapeToString)}
        testCorrect={[shapeToSides(shape)]}
        pdfLayout="itemsHidden"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountSidesOn2DShapes',
  questionTypes: [Question1, Question3],
  unpublishedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
