import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { compareFractions, simplify } from 'common/src/utils/fractions';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { DIV } from 'common/src/constants';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { barModelColors } from 'common/src/theme/colors';
import { filledArray } from 'common/src/utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ard',
  description: 'ard',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z
    .object({
      numerator: z.number().int().min(2).max(8),
      denominator: z.number().int().min(3).max(9),
      divisor: z.number().int().min(2).max(4)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    )
    .refine(
      val => val.numerator % val.divisor === 0,
      'The numerator must be a multiple of the divisor.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const numerator = randomIntegerInclusive(2, 8, {
      // Numerator must be a multiple of divisor.
      constraint: x => x % divisor === 0
    });

    const denominator = randomIntegerInclusive(numerator + 1, 9);

    return { numerator, denominator, divisor };
  },
  Component: props => {
    const {
      question: { numerator, denominator, divisor },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ numerator, denominator, divisor })
    }) as string;

    const numeratorColorArray = filledArray(numeratorColor, numerator);

    const remainder = filledArray('white', denominator - numerator);

    const customColorMap = [...numeratorColorArray, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`<frac n='${numerator}' d='${denominator}'/> ${DIV} ${divisor} = <frac nAns='' dAns=''/>`}
        title={translate.instructions.useDiagramToHelpCompleteCalculation()}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1]], [numerator / divisor, denominator])
        }
        inputMaxCharacters={2}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={dimens.height / 2}
            customColorMap={customColorMap}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [(numerator / divisor).toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'are',
  description: 'are',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    divisor: z.number().int().min(2).max(7),
    denominatorB: z.number().int().min(2).max(7),
    numeratorA: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 7);

    const denominatorB = randomIntegerInclusive(2, 7, {
      constraint: x => x * divisor <= 30
    });

    const numeratorA = randomIntegerInclusive(1, denominatorB - 1);

    return { divisor, denominatorB, numeratorA };
  },
  Component: props => {
    const {
      question: { divisor, denominatorB, numeratorA },
      translate
    } = props;

    const denominatorA = divisor * denominatorB;

    const numeratorB = divisor * numeratorA;

    const denominatorD = denominatorA * divisor;

    const simplifiedFracD = simplify(numeratorA, denominatorA);

    const sentenceD =
      simplifiedFracD[0] === numeratorA
        ? `<frac n='${numeratorB}' d='${denominatorD}' />`
        : `<frac n='${simplifiedFracD[0]}' d='${simplifiedFracD[1]}' />`;

    const eqs = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA}' d='${denominatorA}'/>`,
          isCorrect: true
        },
        {
          sentence: `<frac n='${numeratorB}' d='${denominatorB}'/>`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${numeratorA}' d='${denominatorB}'/>`,
          isCorrect: false
        },
        {
          sentence: sentenceD,
          isCorrect: true
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheCorrectAnswersTo(
          `<frac n='${numeratorB}' d='${denominatorA}'/> ${DIV} ${divisor.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.circleTheCorrectAnswerTo(
          `<frac n='${numeratorB}' d='${denominatorA}'/> ${DIV} ${divisor.toLocaleString()}`
        )}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        multiSelect
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: (
              <TextStructure
                fractionTextStyle={{ fontWeight: '700' }}
                sentence={equation.sentence}
                style={{ justifyContent: 'center' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arf',
  description: 'arf',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z
    .object({
      numerator: z.number().int().min(2).max(74),
      denominator: z.number().int().min(10).max(75),
      divisor: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    )
    .refine(
      val => val.numerator % val.divisor === 0,
      'The numerator must be a multiple of the divisor.'
    ),
  simpleGenerator: () => {
    const { divisor, numerator, denominator } = rejectionSample(
      () => {
        const divisor = randomIntegerInclusive(2, 5);

        const denominator = randomIntegerInclusive(10, 75);

        const numerator = randomIntegerInclusive(2, denominator - 1, {
          // Numerator must be a multiple of divisor.
          constraint: x => x % divisor === 0
        });

        return { divisor, numerator, denominator };
      },
      // Only permit them if numerator / denominator produces a fraction that can be simplified.
      ({ numerator, denominator }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(numerator, denominator);

        return numerator !== simplifiedNumerator && denominator !== simplifiedDenominator;
      }
    );

    return { numerator, denominator, divisor };
  },
  Component: props => {
    const {
      question: { numerator, denominator, divisor },
      translate,
      displayMode
    } = props;

    const [simplifiedNumerator, simplifiedDenominator] = simplify(numerator, denominator * divisor);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[simplifiedNumerator.toString(), simplifiedDenominator.toString()]}
        sentence={`<frac n='${numerator}' d='${denominator}'/> ${DIV} ${divisor.toLocaleString()} = <frac nAns='' dAns=''/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arg',
  description: 'arg',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    firstFractionNumeratorBase: z.number().int().min(2).max(6),
    firstFractionNumeratorScale: z.number().int().min(2).max(4),
    resultDenominator: z.number().int().min(3).max(5)
  }),
  simpleGenerator: () => {
    const resultDenominator = randomIntegerInclusive(3, 5);

    const firstFractionNumeratorBase = randomIntegerInclusive(2, 6);

    // Needed to scale the first fraction's numerator up to be 2 to 4 times larger, to prevent the answer always being 1
    const firstFractionNumeratorScale = randomIntegerInclusive(2, resultDenominator - 1);

    return { firstFractionNumeratorBase, resultDenominator, firstFractionNumeratorScale };
  },
  Component: props => {
    const {
      question: { firstFractionNumeratorBase, resultDenominator, firstFractionNumeratorScale },
      translate
    } = props;

    const firstFractionNumerator = firstFractionNumeratorBase * firstFractionNumeratorScale;

    const firstFractionDenominator = firstFractionNumeratorBase * resultDenominator;

    const divisor = (firstFractionNumerator * resultDenominator) / firstFractionDenominator;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[divisor.toString()]}
        sentence={`<frac n='${firstFractionNumerator}' d='${firstFractionDenominator}'/> ${DIV} <ans/> = <frac n='1' d='${resultDenominator}'/>`}
        textStyle={{ fontSize: 40 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arh',
  description: 'arh',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    numerator: z.number().int().min(21).max(29),
    resultDenominator: z.number().int().min(20).max(50).multipleOf(10)
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(21, 29);

    const resultDenominator = randomIntegerInclusiveStep(20, 50, 10);

    return { numerator, resultDenominator };
  },
  Component: props => {
    const {
      question: { numerator, resultDenominator },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={answer =>
          (parseInt(answer[0]) * parseInt(answer[1])).toString() ===
          (numerator * resultDenominator).toString()
        }
        inputMaxCharacters={3}
        sentence={`<frac n='${numerator}' dAns=''/> ${DIV} <ans/> = <frac n='1' d='${resultDenominator}'/>`}
        textStyle={{ fontSize: 40 }}
        fractionContainerStyle={{ height: 96 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [resultDenominator.toLocaleString(), numerator.toLocaleString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ari',
  description: 'ari',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    firstFractionNumeratorBase: z.number().int().min(2).max(6),
    firstFractionNumeratorScale: z.number().int().min(2).max(4),
    resultDenominator: z.number().int().min(3).max(5)
  }),
  simpleGenerator: () => {
    const resultDenominator = randomIntegerInclusive(3, 5);

    const firstFractionNumeratorBase = randomIntegerInclusive(2, 6);

    // Needed to scale the first fraction's numerator up to be 2 to 4 times larger, to prevent the answer always being 1
    const firstFractionNumeratorScale = randomIntegerInclusive(2, resultDenominator - 1);

    return { firstFractionNumeratorBase, resultDenominator, firstFractionNumeratorScale };
  },
  Component: props => {
    const {
      question: { firstFractionNumeratorBase, resultDenominator, firstFractionNumeratorScale },
      translate,
      displayMode
    } = props;

    const firstFractionNumerator = firstFractionNumeratorBase * firstFractionNumeratorScale;

    const firstFractionDenominator = firstFractionNumeratorBase * resultDenominator;

    const divisor = (firstFractionNumerator * resultDenominator) / firstFractionDenominator;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[divisor.toString(), (firstFractionNumerator / divisor).toString()]}
        sentence={`<frac n='${firstFractionNumerator}' d='${firstFractionDenominator}'/> ${DIV} <ans/> = <frac nAns='' d='${firstFractionDenominator}'/> = <frac n='1' d='${resultDenominator}'/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideAFractionByAnInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
