import { StyleProp, View, ViewStyle } from 'react-native';
import { colors } from '../../../theme/colors';
import { Dimens } from '../../../theme/scaling';
import Grid from './Coordinates/Grid';
import GridImage from './Coordinates/GridImage';
import { AssetSvg, SvgName } from '../../../assets/svg';
import React, { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import Svg, { Line, Polygon } from 'react-native-svg';

type Props = {
  dimens: Dimens;
  xMax: number;
  yMax: number;
  points: { x: number; y: number }[];
  imageStyle?: StyleProp<ViewStyle>;
  svgNames: SvgName[];
  // the offset value in x-coords, default will start from center
  arrowXOffset?: number;
  // the offset value in y-coords, default will start from center
  arrowYOffset?: number;
  squareGrid?: boolean;
};

/**
 * Function to display given images at given points and draw horizontal lines between points
 */
export const GridWithArrowsBetweenChildren = ({
  dimens,
  xMax,
  yMax,
  points,
  imageStyle,
  svgNames,
  arrowXOffset = 0,
  arrowYOffset = 0,
  squareGrid
}: Props) => {
  return (
    <Grid
      yMax={yMax}
      xMax={xMax}
      width={dimens.width}
      height={dimens.height}
      hideContinuationLines
      xAxis={null}
      yAxis={null}
      squareGrid={squareGrid}
      gridLineWidth={2}
      children={({ mathToSvgX, mathToSvgY }) => {
        const cellWidth = mathToSvgX(1) - mathToSvgX(0);
        const cellHeight = mathToSvgY(0) - mathToSvgY(1);
        return points.map((point, i) => {
          let arrow;
          if (i < points.length - 1) {
            // work out X offsets
            const offsetX = svgNames[i + 1] !== undefined ? arrowXOffset : 0;
            const fromXOffset = points[i].x > points[i + 1].x ? -arrowXOffset : arrowXOffset;
            const toXOffset = points[i].x > points[i + 1].x ? offsetX : -offsetX;
            const xCoordOffset = svgNames[i + 1] !== undefined ? 0 : fromXOffset;

            // work out Y offsets
            const offsetY = svgNames[i + 1] !== undefined || i === 0 ? arrowYOffset : 0;
            const toOffsetY = svgNames[i + 1] !== undefined ? arrowYOffset : 0;
            const fromYOffset = points[i].y > points[i + 1].y ? -offsetY : offsetY;
            const toYOffset = points[i].y > points[i + 1].y ? toOffsetY : -toOffsetY;
            const yCoordOffset = svgNames[i + 1] !== undefined ? 0 : fromYOffset;

            const fromX = mathToSvgX(points[i].x + fromXOffset);
            const toX = mathToSvgX(points[i + 1].x + toXOffset);
            const fromY = mathToSvgY(points[i].y + fromYOffset);
            const toY = mathToSvgY(points[i + 1].y + toYOffset);
            const { height, width } = getArrowDimens({
              fromX,
              fromY,
              toX,
              toY
            });
            arrow = (
              <View style={{ zIndex: 2 }}>
                <GridImage
                  mathCoord={[
                    (points[i].x + points[i + 1].x + 1) / 2 + xCoordOffset / 2,
                    (points[i].y + points[i + 1].y + 1) / 2 + yCoordOffset / 2
                  ]}
                  item={{
                    component: <Arrow fromX={fromX} fromY={fromY} toX={toX} toY={toY} />,
                    height,
                    width
                  }}
                />
              </View>
            );
          }
          return (
            <React.Fragment key={i}>
              {svgNames[i] !== undefined && (
                <GridImage
                  item={{
                    component: (
                      <View
                        style={{
                          width: cellWidth,
                          height: cellHeight,
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <View style={imageStyle}>
                          <AssetSvg
                            name={svgNames[i]}
                            width={cellWidth * 0.8}
                            height={cellHeight * 0.8}
                          />
                        </View>
                      </View>
                    ),
                    width: cellWidth,
                    height: cellHeight
                  }}
                  anchorDX={0}
                  anchorDY={cellHeight}
                  mathCoord={[point.x, point.y]}
                />
              )}
              {arrow}
            </React.Fragment>
          );
        });
      }}
    />
  );
};

type ArrowProps = {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  color?: string;
  strokeWidth?: number;
};

const getArrowDimens = ({
  fromX,
  fromY,
  toX,
  toY,
  arrowSize = 20
}: ArrowProps & { arrowSize?: number }) => {
  const padding = arrowSize * 2;
  const minX = Math.min(fromX, toX) - padding;
  const minY = Math.min(fromY, toY) - padding;
  const maxX = Math.max(fromX, toX) + padding;
  const maxY = Math.max(fromY, toY) + padding;
  const width = maxX - minX;
  const height = maxY - minY;
  return { width, height, minX, minY };
};

const Arrow = ({ fromX, fromY, toX, toY, color: colorProp, strokeWidth = 4 }: ArrowProps) => {
  const displayMode = useContext(DisplayMode);
  const color = colorProp ?? (displayMode === 'digital' ? colors.prussianBlue : colors.black);
  const arrowSize = strokeWidth * 5;

  // Calculate the angle of the line
  const angle = Math.atan2(toY - fromY, toX - fromX);

  // Shorten the line by the size of the arrowhead
  const shortenedToX = toX - (arrowSize * Math.cos(angle)) / 2;
  const shortenedToY = toY - (arrowSize * Math.sin(angle)) / 2;

  // Calculate the points for the arrowhead
  const arrowX1 = toX - arrowSize * Math.cos(angle - Math.PI / 6);
  const arrowY1 = toY - arrowSize * Math.sin(angle - Math.PI / 6);
  const arrowX2 = toX - arrowSize * Math.cos(angle + Math.PI / 6);
  const arrowY2 = toY - arrowSize * Math.sin(angle + Math.PI / 6);

  const { minY, minX, width, height } = getArrowDimens({ fromX, fromY, toX, toY, arrowSize });

  return (
    <Svg viewBox={`${minX} ${minY} ${width} ${height}`} style={{ height: height, width: width }}>
      <Line
        x1={fromX}
        y1={fromY}
        x2={shortenedToX}
        y2={shortenedToY}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <Polygon points={`${toX},${toY} ${arrowX1},${arrowY1} ${arrowX2},${arrowY2}`} fill={color} />
    </Svg>
  );
};
