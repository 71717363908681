import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { randomIntegerInclusive, rejectionSample } from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersExchangeAt, numbersOnlyExchangeAt } from 'common/src/utils/exchanges';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ADD } from 'common/src/constants';
import { numberToBase10Object, roundToTheNearest } from 'common/src/utils/math';
import { View } from 'react-native';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import Text from 'common/src/components/typography/Text';
import QF25JumpOnANumberLine from 'common/src/components/question/questionFormats/QF25JumpOnANumberLine';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import NumberLine from 'common/src/components/question/representations/Number Line/NumberLine';
import QF14CompleteNumberTrack from 'common/src/components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'aga',
  description: 'aga',
  keywords: ['Addition', '1s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(105).max(986)
    })
    .refine(
      val => val.startingNumber % 10 === 5 || val.startingNumber % 10 === 6,
      'startingNumber must end in 5 or 6.'
    )
    .refine(val => val.startingNumber % 100 < 90, 'startingNumber must have less than 9 tens.'),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(105, 986, {
      constraint: x => (x % 10 === 5 || x % 10 === 6) && x % 100 < 90
    });
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber + 1).toLocaleString(),
      (startingNumber + 2).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber + 3).toString(),
      (startingNumber + 4).toString(),
      (startingNumber + 5).toString(),
      (startingNumber + 6).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  },
  questionHeight: 600
});

const Question2 = newQuestionContent({
  uid: 'agb',
  description: 'agb',
  keywords: ['Addition', '1s', 'Base 10'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(599)
        .refine(x => x % 10 !== 0, 'var1 should not be a multiple of 10'),
      var2: z.number().int().min(2).max(9)
    })
    .refine(val => val.var1 % 10 < val.var2, 'var2 must be more than the ones digit in var1')
    .refine(val => (val.var1 % 10) + val.var2 >= 10, 'The ones of var1 + var2 must be >= 10'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 599, { constraint: x => x % 10 !== 0 });

        const var1Ones = var1 % 10;

        const var2 = randomIntegerInclusive(2, 9, {
          constraint: x => x + var1Ones >= 10
        });
        return { var1, var2 };
      },
      // Only permit them if var2 is greater than the ones digit in var1
      ({ var1, var2 }) => var1 % 10 < var2
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate, ...props }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeAddition()}
        Content={({ dimens }) => {
          const width = dimens.width / 2.25;
          const height = dimens.height;
          const scales = [var1, var2].map(
            number =>
              BaseTenRepCalcGridsAndScale(width, height, numberToBase10Object(number), 'Cubes')
                .scale
          );
          const scale = Math.min(...scales);

          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: dimens.width,
                justifyContent: 'space-evenly'
              }}
            >
              <BaseTenRepresentation
                b10Rep={{
                  variant: 'Cubes',
                  numbers: numberToBase10Object(var1),
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width / 2.25}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
              <Text style={{ fontSize: 40 }}>{ADD}</Text>
              <BaseTenRepresentation
                b10Rep={{
                  variant: 'Cubes',
                  numbers: numberToBase10Object(var2),
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width / 2.25}
                usableHeight={dimens.height}
                scale={scale}
                containerStyle={{ alignItems: 'center' }}
              />
            </View>
          );
        }}
        sentence={`${var1} ${ADD} ${var2} = <ans/>`}
        testCorrect={[(var1 + var2).toString()]}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'agc',
  description: 'agc',
  keywords: ['Addition', '1s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(989),
      number2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'ones'),
      'number1 and number2 must exchange ones'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);

    const number1 = randomIntegerInclusive(101, 989, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'ones')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // Array of tick values for number line
    const tickValues = range(number1 - 1, number1 + 9);

    const number3 = number1 + number2;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.workOutTheAddition()}
        testCorrect={[number3.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question4v2 = newQuestionContent({
  uid: 'age2',
  description: 'age',
  keywords: ['Number line', 'Partition', '1s', 'Addition'],
  schema: z.object({
    number1: z.number().int().min(101).max(989),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);
    const number1 = randomIntegerInclusive(101, 989, {
      constraint: x => numbersExchangeAt(x, number2, 'ones')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number4 = roundToTheNearest(number1, 10, 'up');
    const number3 = number1 + number2;

    const number5 = number4 - number1;
    const number6 = number3 - number4;

    const ans1 = number5;
    const ans2 = number6;
    const ans3 = number3;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray =
      number4 === number3
        ? [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: '<ans/>', position: endNumber }
          ]
        : [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: number4.toLocaleString(), position: number4 },
            { label: '<ans/>', position: endNumber }
          ];

    const jumpArrowArray =
      number4 === number3
        ? [{ start: startingNumber, end: endNumber, label: `${ADD}<ans/>` }]
        : [
            { start: startingNumber, end: number4, label: `${ADD}<ans/>` },
            { start: number4, end: endNumber, label: `${ADD}<ans/>` }
          ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToWorkOutX(
          `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`
        )}
        testCorrect={
          number3 === number4
            ? [ans1.toString(), ans3.toString()]
            : [ans1.toString(), ans2.toString(), ans3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'age',
  description: 'age',
  keywords: ['Number line', 'Partition', '1s', 'Addition'],
  schema: z.object({
    number1: z.number().int().min(101).max(989),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    // 100<$var1<990. 1<$var2<10. $var3=$var1+$var2 with 1 exchange only: ones to ten. $var_m=10
    // $ans at $var5, $var6, $var3
    // Show jumping on number line with $var2, $var4

    const number2 = randomIntegerInclusive(2, 9);
    const number1 = randomIntegerInclusive(101, 989, {
      constraint: x => numbersExchangeAt(x, number2, 1)
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number4 = roundToTheNearest(number1, 10, 'up');
    const number3 = number1 + number2;

    const number5 = number4 - number1;
    const number6 = number3 - number4;

    const ans1 = number5;
    const ans2 = number6;
    const ans3 = number3;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray =
      number4 === number3
        ? [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: '<ans/>', position: number3 }
          ]
        : [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: number4.toLocaleString(), position: number4 },
            { label: '<ans/>', position: number3 }
          ];

    const jumpArrowArray =
      number3 % 100 === 0
        ? [{ start: startingNumber, end: number4, label: `${ADD}<ans/>` }]
        : [
            { start: startingNumber, end: number4, label: `${ADD}<ans/>` },
            { start: number4, end: number3, label: `${ADD}<ans/>` }
          ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.workOutXAddY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={
          number3 === number4
            ? [ans1.toString(), ans3.toString()]
            : [ans1.toString(), ans2.toString(), ans3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'agd',
  description: 'agd',
  keywords: ['Addition', '1s', 'Bonds'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(999),
      number2: z.number().int().min(1).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'ones'),
      'number1 + number2 must only exchange at ones'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(1, 9);
    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'ones')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardCompleteNumberSentence()}
        items={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
        sentence={`${number1.toLocaleString()} ${ADD} <ans/> = ${number3.toLocaleString()}`}
        testCorrect={[number2]}
        moveOrCopy="copy"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'agf',
  description: 'agf',
  keywords: ['Addition', '1s'],
  schema: z
    .object({
      numberA1: z.number().int().min(101).max(989),
      numberA2: z.number().int().min(2).max(9),
      numberB1: z.number().int().min(101).max(989),
      numberB2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, val.numberA2, 'ones'),
      'numberA1 + numberA2 must only exchange at ones'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 + numberB2 must only exchange at ones'
    ),
  questionHeight: 500,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusive(2, 9);
        const numberA1 = randomIntegerInclusive(101, 989, {
          constraint: x => numbersOnlyExchangeAt(x, numberA2, 'ones')
        });

        const numberB2 = randomIntegerInclusive(2, 9);
        const numberB1 = randomIntegerInclusive(101, 989, {
          constraint: x => numbersOnlyExchangeAt(x, numberB2, 'ones')
        });

        return { numberA1, numberA2, numberB1, numberB2 };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'add',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'add',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheAdditions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Add1sAcrossA10',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
