// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplesOf3 from './1MultiplesOf3';
import MultiplyAndDivideBy6 from './2MultiplyAndDivideBy6';
import Num6TimesTableAndDivisionFacts from './3Num6TimesTableAndDivisionFacts';
import MultiplyAndDivideBy9 from './4MultiplyAndDivideBy9';
import Num9TimesTableAndDivisionFacts from './5Num9TimesTableAndDivisionFacts';
import The36And9TimesTables from './6The36And9TimesTables';
import MultiplyAndDivideBy7 from './7MultiplyAndDivideBy7';
import Num7TimesTableAndDivisionFacts from './8Num7TimesTableAndDivisionFacts';
import Num11TimesTableAndDivisionFacts from './9Num11TimesTableAndDivisionFacts';
import Num12TimesTableAndDivisionFacts from './10Num12TimesTableAndDivisionFacts';
import MultiplyBy1And0 from './11MultiplyBy1And0';
import DivideANumberBy1AndItself from './12DivideANumberBy1AndItself';
import MultiplyThreeNumbers from './13MultiplyThreeNumbers';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [9, 11],
  smallSteps: [
    MultiplesOf3, // Step 1
    MultiplyAndDivideBy6, // Step 2
    Num6TimesTableAndDivisionFacts, // Step 3
    MultiplyAndDivideBy9, // Step 4
    Num9TimesTableAndDivisionFacts, // Step 5
    The36And9TimesTables, // Step 6
    MultiplyAndDivideBy7, // Step 7
    Num7TimesTableAndDivisionFacts, // Step 8
    Num11TimesTableAndDivisionFacts, // Step 9
    Num12TimesTableAndDivisionFacts, // Step 10
    MultiplyBy1And0, // Step 11
    DivideANumberBy1AndItself, // Step 12
    MultiplyThreeNumbers // Step 13
  ]
});
export default Block;
