import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF20cInteractiveBarModelWithCrossesAndSentence from 'common/src/components/question/questionFormats/QF20cInteractiveBarModelWithCrossesAndSentence';
import { randomIntegerInclusive } from 'common/src/utils/random';
import { ADD, SUB } from 'common/src/constants';
import { getRandomName, getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import ContentBox from 'common/src/components/molecules/ContentBox';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { compareFractions } from 'common/src/utils/fractions';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { MarkupAssets } from 'common/src/markup';
import { AssetSvg } from 'common/src/assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asE',
  description: 'asE',
  keywords: ['Fractions', 'Bar model', 'Subtract'],
  schema: z
    .object({
      numerator: z.number().int().min(2).max(5),
      denominator: z.number().int().min(3).max(8),
      random: z.number().int().min(0).max(4)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than the denominator'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(2, 5, {
      constraint: x => x < denominator
    });
    // Random cell should be crossed
    const random = randomIntegerInclusive(0, numerator - 1);

    return { numerator, denominator, random };
  },
  Component: props => {
    const {
      question: { numerator, denominator, random },
      translate
    } = props;

    const bars = [
      {
        rows: 1,
        cols: denominator,
        shadedCells: { min: 0, max: numerator - 1 },
        crossedCells: { min: random, max: random }
      }
    ];

    // Answers
    const numeratorAnswer = numerator - 1;

    const sentence = `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${SUB} <frac n='1' d='${denominator.toLocaleString()}' /> = <frac nAns='' dAns=''/>`;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={translate.instructions.useTheBarModelToHelpCompleteTheSubtraction()}
        testCorrect={answer => compareFractions(answer, [numeratorAnswer, denominator])}
        sentence={sentence}
        bars={bars}
        inputMaxCharacters={2}
        interactive={false}
        customMarkSchemeAnswer={{
          answersToDisplay: [numeratorAnswer.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'asE2',
  description: 'asE',
  keywords: ['Fractions', 'Bar model', 'Subtract'],
  schema: z
    .object({
      numerator: z.number().int().min(2).max(5),
      denominator: z.number().int().min(3).max(8)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than the denominator'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(2, 5, {
      constraint: x => x < denominator
    });

    return { numerator, denominator };
  },
  Component: props => {
    const {
      question: { numerator, denominator },
      translate
    } = props;

    const bars = [
      {
        rows: 1,
        cols: denominator,
        shadedCells: { min: 0, max: numerator - 1 },
        crossedCells: { min: numerator - 1, max: numerator - 1 }
      }
    ];

    // Answers
    const numeratorAnswer = numerator - 1;

    const sentence = `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${SUB} <frac n='1' d='${denominator.toLocaleString()}' /> = <frac nAns='' dAns=''/>`;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={translate.instructions.completeSubtraction()}
        testCorrect={answer => compareFractions(answer, [numeratorAnswer, denominator])}
        sentence={sentence}
        pdfDirection="column"
        bars={bars}
        inputMaxCharacters={2}
        interactive={false}
        customMarkSchemeAnswer={{
          answersToDisplay: [numeratorAnswer.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asF',
  description: 'asF',
  keywords: ['Fractions', 'Bar model', 'Subtract'],
  schema: z
    .object({
      fracANumerator: z.number().int().min(2).max(7),
      denominator: z.number().int().min(3).max(8),
      fracBNumerator: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.fracANumerator < val.denominator,
      'fractionANumerator must be less than the denominator'
    )
    .refine(
      val => val.fracBNumerator < val.fracANumerator,
      'fractionBNumerator must be less than the fractionANumerator'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const fracANumerator = randomIntegerInclusive(2, denominator - 1);
    const fracBNumerator = randomIntegerInclusive(1, fracANumerator - 1);

    return { fracANumerator, denominator, fracBNumerator };
  },
  Component: props => {
    const {
      question: { fracANumerator, denominator, fracBNumerator },
      translate
    } = props;

    const bars = [
      {
        rows: 1,
        cols: denominator,
        shadedCells: { min: 0, max: fracANumerator - 1 }
      }
    ];

    // Answers
    const answerNumerator = fracANumerator - fracBNumerator;

    const sentence = `<frac n='${fracANumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${SUB} <frac n='${fracBNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac nAns='' dAns=''/>`;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={translate.instructions.completeTheCalculationUseTheBarModelToHelpYou()}
        testCorrect={answer => compareFractions(answer, [answerNumerator, denominator])}
        barModelShadingTestComplete={() => true}
        sentence={sentence}
        bars={bars}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumerator.toLocaleString(), denominator.toLocaleString()]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asG',
  description: 'asG',
  keywords: ['Fractions', 'Subtract'],
  schema: z
    .object({
      fracANumerator: z.number().int().min(4).max(7),
      denominator: z.number().int().min(5).max(8),
      fracBNumerator: z.number().int().min(2).max(7),
      name: nameSchema
    })
    .refine(
      val => val.fracBNumerator < val.fracANumerator,
      'fracBNumerator must be less than fracANumerator'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 8);
    const fracANumerator = denominator - 1;
    const fracBNumerator = randomIntegerInclusive(2, 7, {
      constraint: x => x < fracANumerator
    });

    const name = getRandomName();

    return { denominator, fracANumerator, fracBNumerator, name };
  },
  Component: props => {
    const {
      question: { denominator, fracANumerator, fracBNumerator, name },
      displayMode,
      translate
    } = props;

    // Answers
    const answerNumerator = fracANumerator - fracBNumerator;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<frac nAns='' dAns='' />`}
        title={translate.instructions.characterEatsFractionOfChocolateBarWhatFractionIsLeft({
          character: name,
          fracA: `<frac n='${fracANumerator}' d='${denominator}' />`,
          fracB: `<frac n='${fracBNumerator}' d='${denominator}' />`
        })}
        testCorrect={answer => compareFractions(answer, [answerNumerator, denominator])}
        inputMaxCharacters={2}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? undefined : 64 }}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asH',
  description: 'asH',
  keywords: ['Fractions', 'Subtract', 'Difference'],
  schema: z
    .object({
      fracANumerator: z.number().int().min(7).max(19),
      denominator: z.number().int().min(8).max(20),
      fracBNumerator: z.number().int().min(5).max(18),
      nameA: nameSchema,
      nameB: nameSchema
    })
    .refine(
      val => val.fracANumerator < val.denominator,
      'fracANumerator must be less than denominator'
    )
    .refine(
      val => val.fracBNumerator < val.fracANumerator,
      'fracBNumerator must be less than fracANumerator'
    ),
  questionHeight: 1100,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(8, 20);
    const fracANumerator = randomIntegerInclusive(7, denominator - 1);
    const fracBNumerator = randomIntegerInclusive(5, fracANumerator - 1);

    const [nameA, nameB] = getRandomUniqueNames(2);

    return { fracANumerator, denominator, fracBNumerator, nameA, nameB };
  },
  Component: props => {
    const {
      question: { fracANumerator, denominator, fracBNumerator, nameA, nameB },
      displayMode,
      translate
    } = props;

    // Answers
    const answerNumerator = fracANumerator - fracBNumerator;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<frac nAns='' dAns='' />`}
        title={translate.instructions.howMuchMoreOfTheBookHasCharacterAReadThanCharacterB({
          characterA: nameA,
          characterB: nameB,
          fracA: `<frac n='${fracANumerator}' d='${denominator}' />`,
          fracB: `<frac n='${fracBNumerator}' d='${denominator}' />`
        })}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 44 : 64 }}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        testCorrect={answer => compareFractions(answer, [answerNumerator, denominator])}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        inputMaxCharacters={2}
        questionHeight={1100}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asI',
  description: 'asI',
  keywords: ['Fractions', 'Add', 'Subtract'],
  schema: z
    .object({
      fracANumerator: z.number().int().min(1).max(2),
      denominator: z.number().int().min(7).max(10),
      fracBNumerator: z.number().int().min(2).max(7),
      fracDNumerator: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.fracANumerator + val.fracBNumerator <= val.denominator,
      `fracANumerator ${ADD} fracBNumerator must be less or equal to the denominator`
    ),
  simpleGenerator: () => {
    const fracANumerator = randomIntegerInclusive(1, 2);
    const denominator = randomIntegerInclusive(7, 10);
    const fracBNumerator = randomIntegerInclusive(2, 7, {
      constraint: x => x + fracANumerator < denominator
    });

    const fracDNumerator = randomIntegerInclusive(
      1,
      denominator - (fracANumerator + fracBNumerator)
    );

    return { fracANumerator, fracBNumerator, fracDNumerator, denominator };
  },
  Component: props => {
    const {
      question: { fracANumerator, denominator, fracBNumerator, fracDNumerator },
      translate
    } = props;

    const answerNumerator = fracANumerator + fracBNumerator + fracDNumerator;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<frac n='${fracANumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${ADD} <frac n='${fracBNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <frac nAns='' d='${denominator.toLocaleString()}' /> ${SUB} <frac n='${fracDNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
        title={translate.instructions.fillInMissingNumerator()}
        testCorrect={[answerNumerator.toString()]}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asJ',
  description: 'asJ',
  keywords: ['Fractions', 'Subtract'],
  schema: z.object({
    denominator: z.number().int().min(50).max(99),
    numerator: z.number().int().min(11).max(29)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(50, 99);
    const numerator = randomIntegerInclusive(11, 29);

    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    return (
      <MarkupAssets
        elements={{
          triangle: <AssetSvg name="Triangle" width={72} height={72} />,
          square: <AssetSvg name="Square" width={72} height={72} />
        }}
      >
        <QF1ContentAndSentences
          sentences={['square = <ans />', 'triangle = <ans />']}
          title={translate.instructions.whatCouldTheValuesOfTheSquareAndTriangleBe()}
          testCorrect={ans => parseInt(ans[0][0]) - parseInt(ans[1][0]) === numerator}
          style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
          inputMaxCharacters={2}
          Content={
            <ContentBox>
              <TextStructure
                sentence={`<frac d='${denominator}'><n><asset name='square'/></n></frac> ${SUB} <frac d='${denominator}'><n><asset name='triangle'/></n></frac> = <frac n='${numerator}' d='${denominator}'/>`}
              />
            </ContentBox>
          }
          customMarkSchemeAnswer={{
            answerText: translate.markScheme.anyCorrectAnswersThatSubtractToMakeX(numerator)
          }}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFractions',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1],
  unpublishedQuestionTypes: []
});
export default SmallStep;
