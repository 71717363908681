import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { compareFractions, improperFractionToMixedNumber } from 'common/src/utils/fractions';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD, MULT } from 'common/src/constants';
import { numberEnum } from 'common/src/utils/zod';
import { findFactors } from 'common/src/utils/factors';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import { useMemo } from 'react';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { calculateRadius } from 'common/src/components/question/representations/MultiPieChart';
import PieChart from 'common/src/components/question/representations/PieChart';
import { filledArray, range } from 'common/src/utils/collections';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aq1',
  description: 'aq1',
  keywords: ['Fraction', 'Multiply', 'Integer'],
  schema: z.object({
    totalSlices: z.number().int().min(3).max(11),
    slicesInFirstColor: z.number().int().min(1).max(3)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const slicesInFirstColor = randomIntegerInclusive(1, 3);

    const totalSlices = (() => {
      switch (slicesInFirstColor) {
        case 1:
          return getRandomFromArray([3, 5, 7, 9] as const);
        case 2:
          return getRandomFromArray([5, 7, 9] as const);
        case 3:
          return getRandomFromArray([7, 11] as const);
      }
    })() as 3 | 5 | 7 | 9 | 11;

    return { totalSlices, slicesInFirstColor };
  },
  Component: props => {
    const {
      question: { totalSlices, slicesInFirstColor },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1200}
        title={translate.instructions.completeCalculation()}
        testCorrect={[(slicesInFirstColor * 2).toString(), totalSlices.toString()]}
        sentence={`<frac n='${slicesInFirstColor}' d='${totalSlices}'/> ${MULT} 2 = <frac nAns='' dAns=''/>`}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => {
          const pieChartRadius = calculateRadius(dimens, 1, 1);

          const secondColorSlices = range(slicesInFirstColor, slicesInFirstColor * 2 - 1);

          const missingSlices = range(slicesInFirstColor * 2, totalSlices - 1);

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }
              ]}
            >
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, totalSlices)}
                slicesWithSecondColor={secondColorSlices}
                missingSlices={missingSlices}
                radius={pieChartRadius}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aq2',
  description: 'aq2',
  keywords: ['Fraction', 'Multiply', 'Integer'],
  schema: z.object({
    numerator: z.number().int().min(2).max(3),
    denominator: z.number().int().min(3).max(8),
    multiplier: z.number().int().min(2).max(3)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(2, 3);

    const denominator = randomIntegerInclusive(3, 8);

    const multiplier = randomIntegerInclusive(2, 3);

    return { numerator, denominator, multiplier };
  },
  Component: props => {
    const {
      question: { numerator, denominator, multiplier },
      translate
    } = props;

    const bars = filledArray({ rows: 1, cols: denominator }, multiplier);

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.completeMultiplication()} ${translate.instructions.shadeBarModelsToHelp()}`}
        testCorrect={{
          sentence: answer =>
            compareFractions([answer[0], answer[1]], [numerator * multiplier, denominator])
        }}
        inputMaxCharacters={2}
        sentence={`<frac n='${numerator}' d='${denominator}'/> ${MULT} ${multiplier.toLocaleString()} = <frac nAns='' dAns=''/>`}
        bars={bars}
        textStyle={{ fontSize: 40 }}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: {
            sentence: [(numerator * multiplier).toLocaleString(), denominator.toLocaleString()]
          },
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aq3',
  description: 'aq3',
  keywords: ['Fraction', 'Multiply', 'Integer'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(4),
      number2: numberEnum([3, 5]),
      number3: z.number().int().min(12).max(30).multipleOf(2),
      number10: z.number().int().min(5).max(8)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be smaller than number2'),
  simpleGenerator: () => {
    const number2 = getRandomFromArray([3, 5] as const);

    const number1 = randomIntegerInclusive(1, number2 - 1);

    const number3 = randomIntegerInclusiveStep(12, 30, 2);

    const number10 = randomIntegerInclusive(5, 8);

    return { number1, number2, number3, number10 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number10 },
      translate,
      displayMode
    } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const lineA = {
        statement: `<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> ${ADD} <frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> =`,
        correctAnswer: 'A'
      };

      const lineB = {
        statement: `<frac n='1' d='4'/> ${MULT} ${number3.toLocaleString()} =`,
        correctAnswer: 'B'
      };

      const lineC = {
        statement: `<frac n='5' d='${number10 * 2}'/> ${MULT} 4 =`,
        correctAnswer: 'C'
      };

      return shuffle([lineA, lineB, lineC], { random: seededRandom(props.question) });
    }, [number1, number10, number2, number3, props.question]);

    const items = [
      {
        component: (
          <TextStructure
            sentence={`<frac n='${(1).toLocaleString()}' d='${number2.toLocaleString()}'/> ${MULT} ${(
              number1 * 2
            ).toLocaleString()}`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'A'
      },
      {
        component: (
          <TextStructure
            sentence={`${(
              number3 / 2
            ).toLocaleString()} ${MULT} <frac n='${(1).toLocaleString()}' d='${(2).toLocaleString()}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'B'
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='${(1).toLocaleString()}' d='${number10.toLocaleString()}'/> ${MULT} ${(10).toLocaleString()}`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'C'
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aq4',
  description: 'aq4',
  keywords: ['Fraction', 'Multiply', 'Integer', 'Mixed number'],
  schema: z
    .object({
      integer: z.number().int().min(1).max(3),
      numerator: z.number().int().min(1).max(2),
      denominator: z.number().int().min(2).max(6),
      multiplier: z.number().int().min(2).max(8)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    )
    .refine(val => {
      const [_mixedInteger, mixedNumerator, _mixedDenominator] = improperFractionToMixedNumber(
        (val.integer * val.denominator + val.numerator) * val.multiplier,
        val.denominator
      );
      return mixedNumerator > 0;
    }, 'The operation must not produce an answer that is just a whole number.'),
  simpleGenerator: () => {
    const { integer, numerator, denominator, multiplier } = rejectionSample(
      () => {
        // Generate 4 random numbers for this operation.
        const integer = randomIntegerInclusive(1, 3);

        const numerator = randomIntegerInclusive(1, 2);

        const denominator = randomIntegerInclusive(numerator + 1, 6);

        const multiplier = randomIntegerInclusive(2, 8);
        return { integer, numerator, denominator, multiplier };
      },
      // Only permit them if they produce a non-integer answer.
      ({ integer, numerator, denominator, multiplier }) => {
        const [_mixedInteger, mixedNumerator, _mixedDenominator] = improperFractionToMixedNumber(
          (integer * denominator + numerator) * multiplier,
          denominator
        );
        return mixedNumerator > 0;
      }
    );

    return { integer, numerator, denominator, multiplier };
  },
  Component: props => {
    const {
      question: { integer, numerator, denominator, multiplier },
      translate,
      displayMode
    } = props;

    const improperNumerator = (integer * denominator + numerator) * multiplier;

    // Fraction answer must be in simplest form.
    const [simplifiedInteger, simplifiedNumerator, simplifiedDenominator] =
      improperFractionToMixedNumber(improperNumerator, denominator);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheCalculationGiveAnswerInSimplestForm()}
        testCorrect={[
          simplifiedInteger.toString(),
          simplifiedNumerator.toString(),
          simplifiedDenominator.toString()
        ]}
        sentence={`<frac w='${integer}' n='${numerator}' d='${denominator}'/> ${MULT} ${multiplier.toLocaleString()} = <frac wAns='' nAns='' dAns=''/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aq5',
  description: 'aq5',
  keywords: ['Fraction', 'Multiply', 'Integer', 'Mixed number'],
  schema: z
    .object({
      integer: z.number().int().min(1).max(5),
      denominator: numberEnum([3, 5, 7]),
      multiplier: z.number().int().min(2).max(5),
      numeratorRight: z.number().int().min(2).max(6)
    })
    .refine(
      val => val.numeratorRight < val.denominator,
      'numeratorRight must be less than denominator.'
    )
    .refine(
      val => findFactors(val.numeratorRight).includes(val.multiplier),
      'multiplier must be a factor of numeratorRight.'
    ),
  simpleGenerator: () => {
    const integer = randomIntegerInclusive(1, 5);

    const denominator = getRandomFromArray([3, 5, 7] as const);

    const numeratorRight = randomIntegerInclusive(2, denominator - 1);

    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => findFactors(numeratorRight).includes(x)
    });

    return { integer, denominator, multiplier, numeratorRight };
  },
  Component: props => {
    const {
      question: { integer, denominator, multiplier, numeratorRight },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[(numeratorRight / multiplier).toString()]}
        sentence={`<frac w='${integer}' nAns='' d='${denominator}'/> ${MULT} ${multiplier.toLocaleString()} = <frac w='${
          integer * multiplier
        }' n='${numeratorRight}' d='${denominator}'/>`}
        textStyle={{ fontSize: 40 }}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aq6',
  description: 'aq6',
  keywords: ['Fraction', 'Multiply', 'Integer', 'Mixed number'],
  schema: z
    .object({
      name: nameSchema,
      integer: z.number().int().min(3).max(6),
      numerator: z.number().int().min(1).max(4),
      denominator: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const name = getRandomName();

    const integer = randomIntegerInclusive(3, 6);

    const denominator = randomIntegerInclusive(2, 5);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { name, integer, numerator, denominator };
  },
  Component: props => {
    const {
      question: { name, integer, numerator, denominator },
      translate
    } = props;

    const improperNumerator = integer * denominator + numerator;

    // Fraction answer must be in simplest form.
    const [simplifiedInteger, simplifiedNumerator, simplifiedDenominator] =
      improperFractionToMixedNumber(improperNumerator * 52, denominator);

    const isWhole = simplifiedNumerator === 0;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.charactersDogEatsFracTinsOfFood(
          name,
          `<frac w='${integer}' n='${numerator}' d='${denominator}'/>`
        )}
        testCorrect={
          isWhole
            ? [simplifiedInteger.toString()]
            : [
                simplifiedInteger.toString(),
                simplifiedNumerator.toString(),
                simplifiedDenominator.toString()
              ]
        }
        sentence={isWhole ? '<ans/> tins' : `<frac wAns='' nAns='' dAns=''/> tins`}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: isWhole ? undefined : translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyFractionsByIntegers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
