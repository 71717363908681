import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { shapesWithCubesSVGNameAndSize } from '../../../../utils/cubes';

const getShapeSvg = (
  shape: 'cubes' | 'cylinders' | 'hexagonalPrisms' | 'squareBasedPyramids' | 'triangularPrisms'
) => {
  switch (shape) {
    case 'cubes':
      return 'Stack_of_Cubes_in_3D_Shape/cube_cubes';
    case 'cylinders':
      return 'Stack_of_Cubes_in_3D_Shape/cylinder_cubes';
    case 'hexagonalPrisms':
      return 'Stack_of_Cubes_in_3D_Shape/hexagonal_prism_cubes';
    case 'squareBasedPyramids':
      return 'Stack_of_Cubes_in_3D_Shape/square_based_pyramid_cubes';
    case 'triangularPrisms':
      return 'Stack_of_Cubes_in_3D_Shape/triangular_prism_cubes';
  }
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDM',
  description: 'aDM',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z
    .object({
      name: nameSchema,
      correctShape: z.enum([
        'cubes',
        'cylinders',
        'hexagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms'
      ]),
      incorrectShape: z.enum([
        'cubes',
        'cylinders',
        'hexagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms'
      ])
    })
    .refine(
      val => val.correctShape !== val.incorrectShape,
      'correctShape and incorrectShape cannot be the same.'
    ),
  simpleGenerator: () => {
    const name = getRandomName();

    const [correctShape, incorrectShape] = getRandomSubArrayFromArray(
      ['cubes', 'cylinders', 'hexagonalPrisms', 'squareBasedPyramids', 'triangularPrisms'] as const,
      2
    );

    return { name, correctShape, incorrectShape };
  },
  Component: ({ question, translate }) => {
    const { name, correctShape, incorrectShape } = question;

    const correctShapeString = translate.shapes[correctShape](1);

    const correctSvg = getShapeSvg(correctShape);

    const incorrectSvg = getShapeSvg(incorrectShape);

    const items = shuffle(
      [
        {
          value: correctShape,
          svgName: correctSvg
        },
        {
          value: incorrectShape,
          svgName: incorrectSvg
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeSelect(
          name,
          correctShapeString
        )}
        pdfTitle={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeCircle(
          name,
          correctShapeString
        )}
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName }) => ({
            component: (
              <AssetSvg
                name={svgName as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value
          }))
        }
        testCorrect={[correctShape]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aDN',
  description: 'aDN',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z
    .object({
      name: nameSchema,
      correctShape: z.enum([
        'cubes',
        'cylinders',
        'hexagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms'
      ]),
      incorrectShapeA: z.enum([
        'cubes',
        'cylinders',
        'hexagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms'
      ]),
      incorrectShapeB: z.enum([
        'cubes',
        'cylinders',
        'hexagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms'
      ])
    })
    .refine(
      val => arrayHasNoDuplicates([val.correctShape, val.incorrectShapeA, val.incorrectShapeB]),
      'correctShape, incorrectShapeA and incorrectShapeB must all be different.'
    ),
  simpleGenerator: () => {
    const name = getRandomName();

    const [correctShape, incorrectShapeA, incorrectShapeB] = getRandomSubArrayFromArray(
      ['cubes', 'cylinders', 'hexagonalPrisms', 'squareBasedPyramids', 'triangularPrisms'] as const,
      3
    );

    return { name, correctShape, incorrectShapeA, incorrectShapeB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { name, correctShape, incorrectShapeA, incorrectShapeB } = question;

    const correctShapeString = translate.shapes[correctShape](1);
    const incorrectShapeAString = translate.shapes[incorrectShapeA](1);
    const incorrectShapeBString = translate.shapes[incorrectShapeB](1);

    const correctShapeSvg = getShapeSvg(correctShape);

    const items = shuffle(
      [
        {
          value: correctShape,
          string: correctShapeString
        },
        {
          value: incorrectShapeA,
          string: incorrectShapeAString
        },
        {
          value: incorrectShapeB,
          string: incorrectShapeBString
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterUsesCubesToEstimateVolumeOf3DShapeSelect(name)}
        pdfTitle={translate.instructions.characterUsesCubesToEstimateVolumeOf3DShapeCircle(name)}
        numItems={3}
        itemLayout={displayMode === 'digital' ? 'row' : 'grid'}
        renderItems={items.map(({ value, string }) => ({
          component: (
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                lineHeight: displayMode === 'digital' ? 48 : 75,
                textAlign: 'center'
              }}
              variant="WRN700"
            >
              {string}
            </Text>
          ),
          value
        }))}
        testCorrect={[correctShape]}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <AssetSvg
              name={correctShapeSvg as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aDO',
  description: 'aDO',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z.object({
    name: nameSchema,
    shape: z.enum([
      'cubes',
      'cylinders',
      'hexagonalPrisms',
      'squareBasedPyramids',
      'triangularPrisms'
    ])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const shape = getRandomFromArray([
      'cubes',
      'cylinders',
      'hexagonalPrisms',
      'squareBasedPyramids',
      'triangularPrisms'
    ] as const);

    return { name, shape };
  },
  Component: ({ question, translate }) => {
    const { name, shape } = question;

    const shapeString = translate.shapes[shape](1);

    const shapeSvg = getShapeSvg(shape);

    const answer = (() => {
      {
        switch (shape) {
          case 'cubes':
            return 64;
          case 'cylinders':
            return 25;
          case 'hexagonalPrisms':
            return 28;
          case 'squareBasedPyramids':
            return 30;
          case 'triangularPrisms':
            return 189;
        }
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeEachCubeHasVolOf1Cm3(
          name,
          shapeString
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <AssetSvg
              name={shapeSvg as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3v2 = newQuestionContent({
  uid: 'aDO2',
  description: 'aDO',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z.object({
    name: nameSchema,
    shape: z.object({
      size: z.number(),
      svgName: z.string(),
      shape: z.enum(['cuboid', 'cube'])
    })
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    type shapeWithCubes = { size: number; svgName: SvgName; shape: 'cube' | 'cuboid' };

    const filteredShapes = shapesWithCubesSVGNameAndSize.filter(
      (it): it is shapeWithCubes => it.shape !== 'rectilinear'
    );

    const shape = getRandomFromArray(filteredShapes) as shapeWithCubes;

    return { name, shape };
  },
  Component: ({ question, translate }) => {
    const { name, shape } = question;

    const shapeString = translate.shapes[shape.shape]();

    const answer = shape.size;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeEachCubeHasVolOf1Cm3(
          name,
          shapeString
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <AssetSvg
              name={shape.svgName as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

// This step required question re-shuffling
// In order to prevent having to create multiple v2 of questions
// aDR was shifted to Q4 to allow aDP/aDQ to remain untouched
// This means the UIDs in this step are not in order
const Question4 = newQuestionContent({
  uid: 'aDR',
  description: 'aDR',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  questionHeight: 1000,
  schema: z.object({
    object: z.enum([
      'Mug',
      'Book',
      'Pencil case',
      'Bread',
      'Cooking pot',
      'Microwave',
      'Toolbox',
      'Rucksack',
      'Fridge',
      'Wardrobe',
      'Wheelie bin'
    ]),
    baseVolume: numberEnum([250, 330, 500])
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'Mug',
      'Book',
      'Pencil case',
      'Bread',
      'Cooking pot',
      'Microwave',
      'Toolbox',
      'Rucksack',
      'Fridge',
      'Wardrobe',
      'Wheelie bin'
    ] as const);

    const baseVolume = getRandomFromArray([250, 330, 500] as const);

    return { object, baseVolume };
  },

  Component: props => {
    const {
      question: { object, baseVolume },
      translate
    } = props;

    const answerA = translate.units.numberOfCm3(baseVolume);
    const answerB = translate.units.numberOfCm3(baseVolume * 10);
    const answerC = translate.units.numberOfCm3(baseVolume * 100);
    const answerD = translate.units.numberOfCm3(baseVolume * 1000);

    const [objectSvg, correctAnswer] = (() => {
      switch (object) {
        case 'Mug':
          return ['Mug', answerA];
        case 'Book':
          return ['BookRed', answerA];
        case 'Pencil case':
          return ['Pencil_case', answerA];
        case 'Bread':
          return ['Bread_loaf', answerB];
        case 'Cooking pot':
          return ['Cooking_pot', answerB];
        case 'Microwave':
          return ['Microwave', answerC];
        case 'Toolbox':
          return ['Toolbox', answerC];
        case 'Rucksack':
          return ['Rucksack', answerC];
        case 'Fridge':
          return ['Refrigerator', answerD];
        case 'Wardrobe':
          return ['Wardrobe', answerD];
        case 'Wheelie bin':
          return ['Large_bin', answerD];
      }
    })();

    const selectables = [answerA, answerB, answerC, answerD];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectApproxVolumeOfObject()}
        pdfTitle={translate.instructions.circleApproxVolumeOfObject()}
        questionHeight={1000}
        leftContent={
          <MeasureView>
            {dimens => (
              <AssetSvg
                name={objectSvg as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[correctAnswer]}
        selectables={Object.fromEntries(selectables.map(selectable => [selectable, selectable]))}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aDR2',
  description: 'aDR2',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z.object({
    name: nameSchema,
    shape: z.enum(['cylinders', 'hexagonalPrisms', 'squareBasedPyramids', 'triangularPrisms'])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const shape = getRandomFromArray([
      'cylinders',
      'hexagonalPrisms',
      'squareBasedPyramids',
      'triangularPrisms'
    ] as const);

    return { name, shape };
  },
  Component: ({ question, translate }) => {
    const { name, shape } = question;

    const shapeString = translate.shapes[shape](1);

    const shapeSvg = getShapeSvg(shape);

    const answer = (() => {
      {
        switch (shape) {
          case 'cylinders':
            return 25;
          case 'hexagonalPrisms':
            return 28;
          case 'squareBasedPyramids':
            return 30;
          case 'triangularPrisms':
            return 189;
        }
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeEachCubeHasVolOf1Cm3(
          name,
          shapeString
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <AssetSvg
              name={shapeSvg as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aDP',
  description: 'aDP',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z.object({
    name: nameSchema,
    shape: z.enum([
      'Cube 8',
      'Cube 27',
      'Cylinder 10',
      'Cylinder 15',
      'Cylinder 20',
      'Cylinder 25',
      'Hexagonal prism 14',
      'Hexagonal prism 21',
      'Hexagonal prism 28',
      'Square-based pyramid 5',
      'Square-based pyramid 14',
      'Square-based pyramid 30',
      'Triangular prism 9',
      'Triangular prism 12',
      'Triangular prism 18',
      'Triangular prism 24'
    ])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const shape = getRandomFromArray([
      'Cube 8',
      'Cube 27',
      'Cylinder 10',
      'Cylinder 15',
      'Cylinder 20',
      'Cylinder 25',
      'Hexagonal prism 14',
      'Hexagonal prism 21',
      'Hexagonal prism 28',
      'Square-based pyramid 5',
      'Square-based pyramid 14',
      'Square-based pyramid 30',
      'Triangular prism 9',
      'Triangular prism 12',
      'Triangular prism 18',
      'Triangular prism 24'
    ] as const);

    return { name, shape };
  },
  Component: ({ question, translate }) => {
    const { name, shape } = question;

    const [shapeString, shapeCubeSvg, shapeSvg, answer] = (() => {
      switch (shape) {
        case 'Cube 8':
          return [
            translate.shapes.cubes(1),
            '3D_Shapes_Cubes/3D_shape25_cubes',
            'Other_shapes/Cube1',
            8
          ];
        case 'Cube 27':
          return [
            translate.shapes.cubes(1),
            '3D_Shapes_Cubes/3D_shape37_cubes',
            'Other_shapes/Cube1',
            27
          ];
        case 'Cylinder 10':
          return [
            translate.shapes.cylinders(1),
            '3D_Shapes_Cubes/3D_shape44_cubes',
            'Other_shapes/Cylinder1',
            10
          ];
        case 'Cylinder 15':
          return [
            translate.shapes.cylinders(1),
            '3D_Shapes_Cubes/3D_shape45_cubes',
            'Other_shapes/Cylinder1',
            15
          ];
        case 'Cylinder 20':
          return [
            translate.shapes.cylinders(1),
            '3D_Shapes_Cubes/3D_shape46_cubes',
            'Other_shapes/Cylinder1',
            20
          ];
        case 'Cylinder 25':
          return [
            translate.shapes.cylinders(1),
            '3D_Shapes_Cubes/3D_shape47_cubes',
            'Other_shapes/Cylinder1',
            25
          ];
        case 'Hexagonal prism 14':
          return [
            translate.shapes.hexagonalPrisms(1),
            '3D_Shapes_Cubes/3D_shape48_cubes',
            'Other_shapes/Hexagonal_prism1',
            14
          ];
        case 'Hexagonal prism 21':
          return [
            translate.shapes.hexagonalPrisms(1),
            '3D_Shapes_Cubes/3D_shape49_cubes',
            'Other_shapes/Hexagonal_prism1',
            21
          ];
        case 'Hexagonal prism 28':
          return [
            translate.shapes.hexagonalPrisms(1),
            '3D_Shapes_Cubes/3D_shape50_cubes',
            'Other_shapes/Hexagonal_prism1',
            28
          ];
        case 'Square-based pyramid 5':
          return [
            translate.shapes.squareBasedPyramids(1),
            '3D_Shapes_Cubes/3D_shape41_cubes',
            'Other_shapes/Square_based_pyramid2',
            5
          ];
        case 'Square-based pyramid 14':
          return [
            translate.shapes.squareBasedPyramids(1),
            '3D_Shapes_Cubes/3D_shape42_cubes',
            'Other_shapes/Square_based_pyramid2',
            14
          ];
        case 'Square-based pyramid 30':
          return [
            translate.shapes.squareBasedPyramids(1),
            '3D_Shapes_Cubes/3D_shape43_cubes',
            'Other_shapes/Square_based_pyramid2',
            30
          ];
        case 'Triangular prism 9':
          return [
            translate.shapes.triangularPrisms(1),
            '3D_Shapes_Cubes/3D_shape53_cubes',
            'Other_shapes/Triangular_prism2by3',
            9
          ];
        case 'Triangular prism 12':
          return [
            translate.shapes.triangularPrisms(1),
            '3D_Shapes_Cubes/3D_shape54_cubes',
            'Other_shapes/Triangular_prism2by4',
            12
          ];
        case 'Triangular prism 18':
          return [
            translate.shapes.triangularPrisms(1),
            '3D_Shapes_Cubes/3D_shape51_cubes',
            'Other_shapes/Triangular_prism3by3',
            18
          ];
        case 'Triangular prism 24':
          return [
            translate.shapes.triangularPrisms(1),
            '3D_Shapes_Cubes/3D_shape52_cubes',
            'Other_shapes/Triangular_prism3by4',
            24
          ];
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesCubesToEstimateVolumeOfShapeEachCubeHasVolOf1Cm3(
          name,
          shapeString
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }
            ]}
          >
            <AssetSvg
              name={shapeSvg as SvgName}
              width={dimens.width * 0.4}
              height={dimens.height * 0.8}
            />
            <AssetSvg
              name={shapeCubeSvg as SvgName}
              width={dimens.width * 0.4}
              height={dimens.height * 0.8}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aDQ',
  description: 'aDQ',
  keywords: ['Volume', '3-D shape', 'Estimate'],
  schema: z.object({
    objectA: z.enum(['Mug', 'Book', 'Pencil case']),
    objectB: z.enum(['Bread', 'Cooking pot']),
    objectC: z.enum(['Microwave', 'Toolbox', 'Rucksack']),
    objectD: z.enum(['Fridge', 'Wardrobe', 'Wheelie bin']),
    baseVolume: numberEnum([250, 330, 500])
  }),
  simpleGenerator: () => {
    const objectA = getRandomFromArray(['Mug', 'Book', 'Pencil case'] as const);

    const objectB = getRandomFromArray(['Bread', 'Cooking pot'] as const);

    const objectC = getRandomFromArray(['Microwave', 'Toolbox', 'Rucksack'] as const);

    const objectD = getRandomFromArray(['Fridge', 'Wardrobe', 'Wheelie bin'] as const);

    const baseVolume = getRandomFromArray([250, 330, 500] as const);

    return { objectA, objectB, objectC, objectD, baseVolume };
  },
  Component: props => {
    const {
      question: { objectA, objectB, objectC, objectD, baseVolume },
      translate
    } = props;

    const answerA = translate.units.numberOfCm3(baseVolume);
    const answerB = translate.units.numberOfCm3(baseVolume * 10);
    const answerC = translate.units.numberOfCm3(baseVolume * 100);
    const answerD = translate.units.numberOfCm3(baseVolume * 1000);

    const getObjectSvg = (object: string) => {
      switch (object) {
        case 'Mug':
          return 'Mug';
        case 'Book':
          return 'BookRed';
        case 'Pencil case':
          return 'Pencil_case';
        case 'Bread':
          return 'Bread_loaf';
        case 'Cooking pot':
          return 'Cooking_pot';
        case 'Microwave':
          return 'Microwave';
        case 'Toolbox':
          return 'Toolbox';
        case 'Rucksack':
          return 'Rucksack';
        case 'Fridge':
          return 'Refrigerator';
        case 'Wardrobe':
          return 'Wardrobe';
        case 'Wheelie bin':
          return 'Large_bin';
      }
    };

    const items = shuffle(
      [
        { svg: getObjectSvg(objectA), answer: answerA },
        { svg: getObjectSvg(objectB), answer: answerB },
        { svg: getObjectSvg(objectC), answer: answerC },
        { svg: getObjectSvg(objectD), answer: answerD }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToMatchApproxVolumeToEachObject()}
        pdfTitle={translate.instructions.useCardsToMatchApproxVolumeToEachObject()}
        items={[answerA, answerB, answerC, answerD]}
        itemVariant="shortRectangle"
        pdfItemVariant="rectangle"
        itemMaxLines={1}
        sentence={`<ans/> <ans/> <ans/> <ans/>`}
        sentenceStyle={{ columnGap: 72 }}
        testCorrect={[items[0].answer, items[1].answer, items[2].answer, items[3].answer]}
        pdfLayout="itemsBottom"
        actionPanelVariant="bottom"
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            {items.map(item => (
              <AssetSvg
                key={item.svg}
                name={item.svg as SvgName}
                width={dimens.width / 5}
                height={dimens.height}
              />
            ))}
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateVolume',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question3, Question4]
});
export default SmallStep;
