import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { compareFractions, simplify } from 'common/src/utils/fractions';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { AssetSvg } from 'common/src/assets/svg';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { DIV, MULT } from 'common/src/constants';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { filledArray } from 'common/src/utils/collections';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { barModelColors } from 'common/src/theme/colors';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arj',
  description: 'arj',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    denominator: z.number().int().min(2).max(5),
    divisor: z.number().int().min(2).max(3)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 5);

    const divisor = randomIntegerInclusive(2, 3);

    return { denominator, divisor };
  },
  Component: props => {
    const {
      question: { denominator, divisor },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ denominator, divisor })
    }) as string;

    const remainder = filledArray('white', denominator - 1);

    const customColorMap = [numeratorColor, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        sentence={`<frac n='1' d='${denominator.toLocaleString()}'/> ${DIV} ${divisor.toLocaleString()} = <frac nAns='' dAns=''/>`}
        title={translate.instructions.useDiagramToHelpCompleteDivision()}
        testCorrect={['1', (denominator * divisor).toString()]}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={displayMode === 'digital' ? dimens.height / 3 : dimens.height / 2}
            customColorMap={customColorMap}
          />
        )}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ark',
  description: 'ark',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    denominator: z.number().int().min(3).max(6)
  }),
  questionHeight: 1300,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 6);
    return { denominator };
  },
  Component: props => {
    const {
      question: { denominator },
      translate
    } = props;

    const numerator = denominator - 1;

    const correctFrac = [numerator, denominator * 2];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={[
          translate.answerSentences.howMuchRiceIsThereInEachBowl(),
          `<frac nAns='' dAns=''/> ${translate.units.kg()}`
        ]}
        title={translate.instructions.fractionOfAKgOfRiceIsDivided(
          `<frac n='${numerator}' d='${denominator}'/>`
        )}
        testCorrect={answer => compareFractions(answer[1], correctFrac)}
        inputMaxCharacters={2}
        Content={() => <AssetSvg name="RiceAndBowls" />}
        questionHeight={1300}
        customMarkSchemeAnswer={{
          answersToDisplay: [[], correctFrac.map(it => it.toLocaleString())],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arl',
  description: 'arl',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(19),
      denominator: z.number().int().min(4).max(20),
      divisor: z.number().int().min(3).max(12)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 20);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    const divisor = randomIntegerInclusive(3, 12);

    return { numerator, denominator, divisor };
  },
  Component: props => {
    const {
      question: { numerator, denominator, divisor },
      translate
    } = props;

    // Fraction answer must be in simplest form.
    const [simplifiedNumerator, simplifiedDenominator] = simplify(numerator, denominator * divisor);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheDivisionGiveAnswerInSimplestForm()}
        testCorrect={[simplifiedNumerator.toString(), simplifiedDenominator.toString()]}
        sentence={`<frac n='${numerator}' d='${denominator}'/> ${DIV} ${divisor.toLocaleString()} = <frac nAns='' dAns=''/>`}
        textStyle={{ fontSize: 40 }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arm',
  description: 'arm',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(9),
      number2: z.number().int().min(4).max(10)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be smaller than number2')
    .refine(
      val => compareFractions([val.number1, val.number2], simplify(val.number1, val.number2)),
      'number1 / number2 must make a fraction that cannot be simplified.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const number1 = randomIntegerInclusive(3, 9);
        const number2 = randomIntegerInclusive(number1 + 1, 10);
        return { number1, number2 };
      },
      // Only permit them if they create a fraction that cannot be simplified further.
      ({ number1, number2 }) => compareFractions([number1, number2], simplify(number1, number2))
    );

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const translatedOne = (1).toLocaleString();

    // Randomly order these statements
    const sentences = useMemo(() => {
      const lineA = {
        sentence: `<frac n='${translatedOne}' d='${number1.toLocaleString()}'/> ${DIV} ${number2.toLocaleString()} <ans /> <frac n='${translatedOne}' d='${number2.toLocaleString()}'/> ${DIV} ${number1.toLocaleString()}`,
        answer: '='
      };

      const lineB = {
        sentence: `<frac n='${translatedOne}' d='${number1.toLocaleString()}'/> ${DIV} ${number1.toLocaleString()} <ans /> <frac n='${translatedOne}' d='${number2.toLocaleString()}'/> ${DIV} ${number2.toLocaleString()}`,
        answer: '>'
      };

      const lineC = {
        sentence: `<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> ${DIV} ${number2.toLocaleString()} <ans /> <frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> ${DIV} ${number1.toLocaleString()}`,
        answer: '<'
      };

      const lineD = {
        sentence: `<frac n='${number1.toLocaleString()}' d='${number1.toLocaleString()}'/> ${DIV} ${number1.toLocaleString()} <ans /> <frac n='${number2.toLocaleString()}' d='${number2.toLocaleString()}'/> ${DIV} ${number2.toLocaleString()}`,
        answer: '>'
      };
      return shuffle([lineA, lineB, lineC, lineD], { random: seededRandom(props.question) });
    }, [number1, number2, props.question, translatedOne]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useInequalitySymbolsToMakeStatementsCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'arn',
  description: 'arn',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(11),
      denominator: z.number().int().min(3).max(12),
      divisor: z.number().int().min(3).max(12)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    const divisor = randomIntegerInclusive(3, 12);

    return { numerator, denominator, divisor };
  },
  Component: props => {
    const {
      question: { numerator, denominator, divisor },
      translate,
      displayMode
    } = props;

    const [simplifiedNumeratorAnswer, simplifiedDenominatorAnswer] = simplify(
      numerator,
      denominator * divisor
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheMissingFractions()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [1, divisor]) &&
          compareFractions(
            [userAnswer[2], userAnswer[3]],
            [simplifiedNumeratorAnswer, simplifiedDenominatorAnswer]
          )
        }
        inputMaxCharacters={2}
        sentence={`<frac n='${numerator}' d='${denominator}'/> ${DIV} ${divisor.toLocaleString()} = <frac n='${numerator}' d='${denominator}'/> ${MULT} <frac nAns='' dAns=''/> = <frac nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (1).toLocaleString(),
            divisor.toLocaleString(),
            simplifiedNumeratorAnswer.toLocaleString(),
            simplifiedDenominatorAnswer.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aro',
  description: 'aro',
  keywords: ['Fraction', 'Divide', 'Integer'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(2).max(4),
    number4: z.number().int().min(3).max(12),
    numberOfCorrectItems: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);

    const number2 = randomIntegerInclusive(2, 4, {
      constraint: x => x * number1 <= 10
    });

    const number3 = number1 * number2;

    const number4 = randomIntegerInclusive(number3 + 1, 12, {
      constraint: x => {
        const simplifiedFraction = simplify(number3, x);
        // number3 / number4 must produce a fraction already in its simplest form.
        return simplifiedFraction[1] === x;
      }
    });

    const numberOfCorrectItems = randomIntegerInclusive(1, 3);

    return { number1, number2, number4, numberOfCorrectItems };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, numberOfCorrectItems },
      translate,
      displayMode
    } = props;

    const number3 = number1 * number2;

    const number5 = number4 * number1;

    const number6 = number3 * number1;

    const correctItems = [
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number3}' d='${number5}'/>`,
        isCorrect: true
      },
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number2}' d='${number4}'/>`,
        isCorrect: true
      },
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number3}' d='${number4}'/> ${MULT} <frac n='1' d='${number1}'/>`,
        isCorrect: true
      }
    ];

    const incorrectItems = [
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number2}' d='${number5}'/>`,
        isCorrect: false
      },
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number6}' d='${number4}'/>`,
        isCorrect: false
      },
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number3}' d='${number4}'/> ${MULT} ${number1.toLocaleString()}`,
        isCorrect: false
      },
      {
        sentence: `<frac n='${number3}' d='${number4}'/> ${DIV} ${number1.toLocaleString()} = <frac n='${number3}' d='${number4}'/> ${DIV} <frac n='1' d='${number1}'/>`,
        isCorrect: false
      }
    ];

    const selectedCorrectItems = getRandomSubArrayFromArray(correctItems, numberOfCorrectItems, {
      random: seededRandom(props.question)
    });

    const selectedIncorrectItems = getRandomSubArrayFromArray(
      incorrectItems,
      4 - numberOfCorrectItems,
      {
        random: seededRandom(props.question)
      }
    );

    const eqs = shuffle([...selectedCorrectItems, ...selectedIncorrectItems], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectStatementsThatAreTrue()}
        pdfTitle={translate.instructions.circleStatementsThatAreTrue()}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: (
              <TextStructure
                sentence={equation.sentence}
                style={{ justifyContent: 'center' }}
                textVariant="WRN700"
                fractionTextStyle={{ fontWeight: '700' }}
                textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              />
            )
          }));
        }}
        questionHeight={900}
        multiSelect
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideAnyFractionByAnInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
