import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD } from '../../../../constants';
import { colors } from '../../../../theme/colors';
import Svg, { Path } from 'react-native-svg';
import { AssetSvg } from '../../../../assets/svg';
import { getMultiLinkCubeSvgName } from '../../../../utils/multiLinkCubesImages';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHg',
  description: 'aHg',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    var1: z.number().int().min(10).max(29),
    var2: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(10, 29);
    const var2 = randomIntegerInclusiveStep(10, 90, 10);
    return { var1, var2 };
  },

  Component: ({ question: { var1, var2 }, translate }) => {
    const var3 = var2 / 10 + var1;
    const strings = [
      [`? ${translate.units.cm()}`],
      [`${translate.units.numberOfCm(var1)}`, `${translate.units.numberOfMm(var2)}`]
    ];
    const numbers = [[var3], [var1, var2 / 10]];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeBarModel()}
        testCorrect={[var3.toString()]}
        sentence={'? = <ans/>'}
        Content={({ dimens }) => (
          <BarModel numbers={numbers} strings={strings} total={var3} dimens={dimens} oneFontSize />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHh',
  description: 'aHh',
  keywords: ['Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    var1: z.number().int().min(10).max(90).multipleOf(10),
    var3: z.number().int().min(2).max(5),
    var4: z.number().int().min(10).max(490).multipleOf(10),
    var6: z.number().int().min(10).max(100).multipleOf(10)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusiveStep(10, 90, 10);
    const var3 = randomIntegerInclusive(2, 5);
    const var4 = randomIntegerInclusiveStep(10, var3 * 100 - 10, 10);
    const var6 = randomIntegerInclusiveStep(10, 100, 10);

    return { var1, var3, var4, var6 };
  },

  Component: props => {
    const {
      question: { var1, var3, var4, var6 },
      translate
    } = props;

    const var2 = 100 - var1;
    const var5 = var3 * 100 - var4;
    const var7 = 100 - var6;

    const additions = [
      {
        sentence: `${translate.units.numberOfCm(var1)} ${ADD} ${translate.units.numberOfCm(
          var2
        )} = <ans/> ${translate.units.m()}`,
        answer: ['1']
      },
      {
        sentence: `${translate.units.numberOfCm(var4)} ${ADD} ${translate.units.numberOfCm(
          var5
        )} = <ans/> ${translate.units.m()}`,
        answer: [var3.toString()]
      },
      {
        sentence: `${translate.units.numberOfCm(
          var6
        )} ${ADD} <ans/> ${translate.units.cm()} = ${translate.units.numberOfM(1)}`,
        answer: [var7.toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        testCorrect={additions.map(el => el.answer)}
        sentences={additions.map(el => el.sentence)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHi',
  description: 'aHi',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    var1a: z.number().int().min(1).max(9),
    var2a: z.number().int().min(0).max(9),
    var3a: z.number().int().min(0).max(40).multipleOf(10),
    var4a: z.number().int().min(10).max(50).multipleOf(10),
    var1b: z.number().int().min(1).max(9),
    var2b: z.number().int().min(0).max(9),
    var3b: z.number().int().min(0).max(40).multipleOf(10),
    var4b: z.number().int().min(10).max(50).multipleOf(10),
    var1c: z.number().int().min(1).max(9),
    var2c: z.number().int().min(0).max(9),
    var3c: z.number().int().min(0).max(40).multipleOf(10),
    var4c: z.number().int().min(10).max(50).multipleOf(10)
  }),
  simpleGenerator: () => {
    const var1a = randomIntegerInclusive(1, 9);
    const var2a = randomIntegerInclusive(0, 9 - var1a);
    const var3a = randomIntegerInclusiveStep(0, 40, 10);
    const var4a = randomIntegerInclusiveStep(10, 50, 10);

    const var1b = randomIntegerInclusive(1, 9);
    const var2b = randomIntegerInclusive(0, 9 - var1b);
    const var3b = randomIntegerInclusiveStep(0, 40, 10);
    const var4b = randomIntegerInclusiveStep(10, 50, 10);

    const var1c = randomIntegerInclusive(1, 9);
    const var2c = randomIntegerInclusive(0, 9 - var1c);
    const var3c = randomIntegerInclusiveStep(0, 40, 10);
    const var4c = randomIntegerInclusiveStep(10, 50, 10);

    return { var1a, var2a, var3a, var4a, var1b, var2b, var3b, var1c, var2c, var3c, var4b, var4c };
  },

  Component: props => {
    const {
      question: {
        var1a,
        var2a,
        var3a,
        var4a,
        var1b,
        var2b,
        var3b,
        var1c,
        var2c,
        var3c,
        var4b,
        var4c
      },
      translate
    } = props;

    const var5a = var3a + var1a;
    const var6a = var4a + var2a;
    const var7a = var5a + var6a;

    const var5b = var3b + var1b;
    const var6b = var4b + var2b;
    const var7b = var5b + var6b;

    const var5c = var3c + var1c;
    const var6c = var4c + var2c;
    const var7c = var5c + var6c;

    const additions = [
      {
        sentence: `${translate.units.numberOfMm(var5a)} ${ADD} ${translate.units.numberOfMm(
          var6a
        )} = <ans/> ${translate.units.mm()}`,
        answer: [var7a.toString()]
      },
      {
        sentence: `${translate.units.numberOfCm(var5b)} ${ADD} ${translate.units.numberOfCm(
          var6b
        )} = <ans/> ${translate.units.cm()}`,
        answer: [var7b.toString()]
      },
      {
        sentence: `${translate.units.numberOfM(var5c)} ${ADD} ${translate.units.numberOfM(
          var6c
        )} = <ans/> ${translate.units.m()}`,
        answer: [var7c.toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        testCorrect={additions.map(el => el.answer)}
        sentences={additions.map(el => el.sentence)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aHj',
  description: 'aHj',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    var1: z.number().int().min(1).max(9),
    var2: z.number().int().min(10).max(90).multipleOf(10),
    var5: z.number().int().min(21).max(89),
    var6: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 9);
    const var2 = randomIntegerInclusiveStep(10, 90, 10);
    const var5 = randomIntegerInclusive(21, 89);
    const var6 = randomIntegerInclusive(1, 7);

    return { var1, var2, var5, var6 };
  },

  Component: props => {
    const {
      question: { var1, var2, var5, var6 },
      translate
    } = props;

    const var3 = var2 / 10;
    const var4 = var1 + var3;
    const var7 = var6 * 10;
    const var8 = var5 + var7;

    const additions = [
      {
        sentence: `${translate.units.numberOfCm(var1)} ${ADD} ${translate.units.numberOfMm(
          var2
        )} = ${translate.units.numberOfCm(
          var1
        )} ${ADD} <ans/> ${translate.units.cm()} = <ans/> ${translate.units.cm()}`,
        answer: [var3.toString(), var4.toString()]
      },
      {
        sentence: `${translate.units.numberOfMm(var5)} ${ADD} ${translate.units.numberOfCm(
          var6
        )} = ${translate.units.numberOfMm(
          var5
        )} + <ans/> ${translate.units.mm()} = <ans/> ${translate.units.mm()}`,
        answer: [var7.toString(), var8.toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        testCorrect={additions.map(el => el.answer)}
        sentences={additions.map(el => el.sentence)}
        actionPanelVariant="bottomTall"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHk',
  description: 'aHk',
  keywords: ['Metric', 'Addition', 'Length', 'Millimetres'],
  schema: z.object({
    blocks: z.number().int().min(3).max(8),
    mmPerBlock: z.number().int().min(20).max(30).multipleOf(10),
    colour: z.enum(['Blue', 'Green', 'Orange', 'Purple', 'Red'])
  }),
  simpleGenerator: () => {
    const blocks = randomIntegerInclusive(3, 8);

    const mmPerBlock = randomIntegerInclusiveStep(20, 30, 10);

    const colour = getRandomFromArray(['Blue', 'Green', 'Orange', 'Purple', 'Red'] as const);

    return { blocks, mmPerBlock, colour };
  },
  Component: props => {
    const {
      question: { blocks, mmPerBlock, colour },
      translate,
      displayMode
    } = props;

    const columnSvgPath = getMultiLinkCubeSvgName(colour, blocks);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howLongIsTheX(translate.objects.Tower())}
        sentence={translate.answerSentences.ansMm()}
        testCorrect={[(blocks * mmPerBlock).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              transform: [{ rotate: '90deg' }]
            }}
          >
            <View style={{ flexDirection: 'row', bottom: displayMode !== 'digital' ? -16 : 16 }}>
              <Text
                style={{
                  fontSize: 24,
                  alignSelf: 'center',
                  bottom: displayMode !== 'digital' ? 16 : 0,
                  right: -20,
                  transform: [{ rotate: '270deg' }]
                }}
              >
                {translate.units.numberOfMm(mmPerBlock)}
              </Text>
              <Svg width={20} height={44}>
                <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
                <Path d={`M10,5 L10,39`} stroke={colors.prussianBlue} strokeWidth={2} />
                <Path
                  d={`M10,44
                L5,34
                L15,34
                Z`}
                  fill={colors.prussianBlue}
                />
              </Svg>
            </View>
            {/* 69px is the width that an 8-cube column needs to fill the whole screen 
          - so this needs to be applied to all columns to be consistent */}
            <AssetSvg name={columnSvgPath} width={69} />
          </View>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aHl',
  description: 'aHl',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres'],
  schema: z.object({
    blocks: z.number().int().min(3).max(8),
    mmPerBlock: z.number().int().min(40).max(50).multipleOf(10),
    colour: z.enum(['Blue', 'Green', 'Orange', 'Purple', 'Red'])
  }),
  simpleGenerator: () => {
    const blocks = randomIntegerInclusive(3, 8);

    const mmPerBlock = randomIntegerInclusiveStep(40, 50, 10);

    const colour = getRandomFromArray(['Blue', 'Green', 'Orange', 'Purple', 'Red'] as const);

    return { blocks, mmPerBlock, colour };
  },
  Component: props => {
    const {
      question: { blocks, mmPerBlock, colour },
      translate,
      displayMode
    } = props;
    const columnSvgPath = getMultiLinkCubeSvgName(colour, blocks);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howTallIsTheTower()}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[((blocks * mmPerBlock) / 10).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}>
            <View style={{ flexDirection: 'row', bottom: displayMode !== 'digital' ? -16 : 16 }}>
              <Text
                style={{
                  fontSize: 24,
                  alignSelf: 'center',
                  bottom: displayMode !== 'digital' ? 16 : 0
                }}
              >
                {translate.units.numberOfMm(mmPerBlock)}
              </Text>
              <Svg width={20} height={44}>
                <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
                <Path d={`M10,5 L10,39`} stroke={colors.prussianBlue} strokeWidth={2} />
                <Path
                  d={`M10,44
                  L5,34
                  L15,34
                  Z`}
                  fill={colors.prussianBlue}
                />
              </Svg>
            </View>
            {/* 69px is the width that an 8-cube column needs to fill the whole screen 
            - so this needs to be applied to all columns to be consistent */}
            <AssetSvg name={columnSvgPath} width={69} />
          </View>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddLengths',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
