import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { isPrime } from '../../../../utils/primes';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { DIV } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import ContentBox from '../../../../components/molecules/ContentBox';
import { compareFractions } from '../../../../utils/fractions';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVG',
  description: 'aVG',
  keywords: ['Fraction', 'Division', 'Divide', 'Numerator', 'Denominator'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(6),
    denominatorA: z.number().int().min(2).max(7),
    numeratorB: z.number().int().min(1).max(19),
    denominatorB: z.number().int().min(2).max(20),
    showFractionFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 7, { constraint: x => isPrime(x) });

    const denominatorB = randomIntegerInclusive(2, 20, {
      constraint: x => isPrime(x) && x !== denominatorA
    });

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    const showFractionFirst = getRandomBoolean();

    return { numeratorA, numeratorB, denominatorA, denominatorB, showFractionFirst };
  },

  Component: props => {
    const {
      question: { numeratorA, numeratorB, denominatorA, denominatorB, showFractionFirst },
      translate
    } = props;

    const sentences = [
      {
        sentence: showFractionFirst
          ? `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> = <ans/> ${DIV} <ans/>`
          : `<ans/> ${DIV} <ans/> = <frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`
      },
      {
        sentence: showFractionFirst
          ? `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <ans/> ${DIV} <ans/>`
          : `<ans/> ${DIV} <ans/> = <frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeFractionAsDivision()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numeratorA, denominatorA]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [numeratorB, denominatorB])
        }
        inputMaxCharacters={2}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVH',
  description: 'aVH',
  keywords: ['Fraction', 'Division', 'Divide', 'Numerator', 'Denominator'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(6),
    denominatorA: z.number().int().min(2).max(7),
    numeratorB: z.number().int().min(1).max(19),
    denominatorB: z.number().int().min(2).max(20),
    showFractionFirst: z.boolean(),
    hideNumeratorB: z.boolean()
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 7, { constraint: x => isPrime(x) });

    const denominatorB = randomIntegerInclusive(2, 20, {
      constraint: x => isPrime(x) && x !== denominatorA
    });
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    const showFractionFirst = getRandomBoolean();

    const hideNumeratorB = getRandomBoolean();

    return {
      numeratorA,
      numeratorB,
      denominatorA,
      denominatorB,
      showFractionFirst,
      hideNumeratorB
    };
  },

  Component: props => {
    const {
      question: {
        numeratorA,
        numeratorB,
        denominatorA,
        denominatorB,
        showFractionFirst,
        hideNumeratorB
      },
      translate
    } = props;

    const sentences = [
      {
        sentence: showFractionFirst
          ? `<frac nAns='' dAns=''/> = ${numeratorA.toLocaleString()} ${DIV} ${denominatorA.toLocaleString()}`
          : `${numeratorA.toLocaleString()} ${DIV} ${denominatorA.toLocaleString()} = <frac nAns='' dAns='' />`
      },
      {
        sentence: showFractionFirst
          ? `${
              hideNumeratorB
                ? `<frac nAns='' d='${denominatorB.toLocaleString()}' /> = ${numeratorB.toLocaleString()} ${DIV} <ans/>`
                : `<frac n='${numeratorB.toLocaleString()}' dAns='' /> = <ans/> ${DIV} ${denominatorB.toLocaleString()}`
            }`
          : `${
              hideNumeratorB
                ? `${numeratorB.toLocaleString()} ${DIV} <ans/> = <frac nAns='' d='${denominatorB.toLocaleString()}' />`
                : `<ans/> ${DIV} ${denominatorB.toLocaleString()} = <frac n='${numeratorB.toLocaleString()}' dAns='' />`
            }`
      }
    ];

    const secondLineCheck = (userAnswer: string[]) => {
      // We need to accept any equivalent fractions that work that the user may construct:
      if (showFractionFirst) {
        return hideNumeratorB
          ? compareFractions([userAnswer[0], denominatorB], [numeratorB, parseInt(userAnswer[1])])
          : compareFractions([numeratorB, userAnswer[0]], [parseInt(userAnswer[1]), denominatorB]);
      } else {
        return hideNumeratorB
          ? compareFractions([numeratorB, userAnswer[0]], [parseInt(userAnswer[1]), denominatorB])
          : compareFractions([userAnswer[0], denominatorB], [numeratorB, parseInt(userAnswer[1])]);
      }
    };

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbersToExpressFractionAsDivision()}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [numeratorA, denominatorA]) &&
          secondLineCheck(userAnswer[1])
        }
        fractionContainerStyle={{ height: 96 }}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aVI',
  description: 'aVI',
  keywords: [
    'Decimal',
    'Fraction',
    'Equivalent to',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Division',
    'Divide',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    numerator: z.number().int().min(1).max(10),
    denominator: numberEnum([5, 8, 12, 15, 16, 20]),
    incorrectAnswerA: z.number().int().min(1).max(9),
    incorrectAnswerB: z.number().int().min(10).max(99),
    incorrectAnswerC: z.number().int().min(100).max(999)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([5, 8, 12, 15, 16, 20] as const);

    // numerator must be less than denominator, up to a max of 10:
    const numerator = randomIntegerInclusive(1, Math.min(10, denominator - 1), {
      // Check that the lowest power of the answer is the ten-thousandths
      constraint: x => ScientificNotation.fromNumber(x / denominator).resolution >= -4
    });

    const incorrectAnswerA = randomIntegerInclusive(1, 9, {
      constraint: x => x / 10 !== number(math.evaluate(`${numerator} / ${denominator}`))
    });

    const incorrectAnswerB = randomIntegerInclusive(10, 99, {
      constraint: x =>
        x / 100 !== number(math.evaluate(`${numerator} / ${denominator}`)) && x % 10 !== 0
    });

    const incorrectAnswerC = randomIntegerInclusive(100, 999, {
      constraint: x =>
        x / 1000 !== number(math.evaluate(`${numerator} / ${denominator}`)) && x % 10 !== 0
    });

    return {
      numerator,
      denominator,
      incorrectAnswerA,
      incorrectAnswerB,
      incorrectAnswerC
    };
  },
  Component: props => {
    const {
      question: { numerator, denominator, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC },
      translate
    } = props;

    const correctAnswer = numerator / denominator;

    const statements = shuffle(
      [
        {
          value: correctAnswer.toLocaleString()
        },
        {
          value: (incorrectAnswerA / 10).toLocaleString()
        },
        {
          value: (incorrectAnswerB / 100).toLocaleString()
        },
        {
          value: (incorrectAnswerC / 1000).toLocaleString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectDecimalEquivalentForFraction()}
        pdfTitle={translate.instructions.circleDecimalEquivalentForFraction()}
        testCorrect={[correctAnswer.toLocaleString()]}
        numItems={4}
        Content={({ dimens }) => (
          <ContentBox
            containerStyle={{
              width: dimens.width / 5,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
            />
          </ContentBox>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: <Text variant="WRN700">{statement.value}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aVJ',
  description: 'aVJ',
  keywords: [
    'Decimal',
    'Fraction',
    'Equivalent to',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Division',
    'Divide',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(7),
    denominatorA: numberEnum([5, 8]),
    numeratorB: z.number().int().min(1).max(10),
    denominatorB: numberEnum([5, 8, 12, 15, 20])
  }),
  simpleGenerator: () => {
    const denominatorB = getRandomFromArray([5, 8, 12, 15, 20] as const);

    const denominatorA =
      denominatorB === 5 ? 8 : denominatorB === 8 ? 5 : getRandomFromArray([5, 8] as const);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1, {
      constraint: x =>
        // Check that the lowest power of the answer does not go beyond ten-thousandths
        ScientificNotation.fromNumber(x / denominatorA).resolution >= -4
    });
    const numeratorB = randomIntegerInclusive(1, denominatorB - 1, {
      constraint: x =>
        x <= 10 &&
        // Check that the lowest power of the answer does not go beyond ten-thousandths
        ScientificNotation.fromNumber(x / denominatorB).resolution >= -4
    });

    return { numeratorA, numeratorB, denominatorA, denominatorB };
  },

  Component: props => {
    const {
      question: { numeratorA, numeratorB, denominatorA, denominatorB },
      translate
    } = props;

    const decimalA = numeratorA / denominatorA;
    const decimalB = numeratorB / denominatorB;

    const sentences = [
      {
        sentence: `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> = <ans/>`,
        answer: decimalA.toString()
      },
      {
        sentence: `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <ans/>`,
        answer: decimalB.toString()
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.findTheDecimalEquivalentOfEachFrcation()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer)
        }
        sentences={sentences.map(sentence => sentence.sentence)}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVK',
  description: 'aVK',
  keywords: [
    'Decimal',
    'Fraction',
    'Equivalent to',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Division',
    'Divide',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: numberEnum([4, 5, 8, 12, 15, 16, 20]),
    numerator: z.number().int().min(1).max(9),
    incorrectAnswerA: z.number().int().min(1).max(12),
    incorrectAnswerB: z.number().min(0.05).max(0.95).step(0.05),
    incorrectAnswerC: z.number().min(0.05).max(0.95).step(0.05)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const denominator = getRandomFromArray([4, 5, 8, 12, 15, 16, 20] as const);

    const numerator = randomIntegerInclusive(1, Math.min(9, denominator - 1), {
      constraint: x =>
        // Check that the lowest power of the answer does not go beyond thousandths
        ScientificNotation.fromNumber(x / denominator).resolution >= -3
    });

    const incorrectAnswerA = randomIntegerInclusive(1, 12, {
      constraint: x =>
        // Ensure this card does not allow an equivalent division to be made with the numerator:
        x !== numerator &&
        x !== denominator &&
        !compareFractions([x, numerator], [numerator, denominator])
    });

    const [incorrectAnswerB, incorrectAnswerC] = randomUniqueIntegersInclusiveStep(5, 95, 5, 2, {
      constraint: x => !compareFractions([x, 100], [numerator, denominator])
    }).map(num => num / 100);

    return { denominator, numerator, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC };
  },
  Component: props => {
    const {
      question: { denominator, numerator, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC },
      translate
    } = props;

    const decimal = numerator / denominator;

    const answerOptions = shuffle(
      [
        numerator.toLocaleString(),
        denominator.toLocaleString(),
        decimal.toLocaleString(),
        incorrectAnswerA.toLocaleString(),
        incorrectAnswerB.toLocaleString(),
        incorrectAnswerC.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsFindDecimalEquivalentOfFraction()}
        pdfTitle={translate.instructions.useCardsFindDecimalEquivalentOfFraction()}
        items={answerOptions}
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <ans/> ${DIV} <ans/> = <ans/>`}
        testCorrect={[
          numerator.toLocaleString(),
          denominator.toLocaleString(),
          decimal.toLocaleString()
        ]}
        moveOrCopy="move"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator.toLocaleString()],
            [denominator.toLocaleString()],
            [decimal.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aVL',
  description: 'aVL',
  keywords: [
    'Decimal',
    'Fraction',
    'Equivalent to',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Division',
    'Divide',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    numeratorA: z.number().min(1).max(9),
    numeratorB: z.number().min(1).max(9),
    numeratorC: z.number().min(1).max(9),
    denominatorA: numberEnum([4, 5, 8, 12, 15, 16, 20]),
    denominatorB: numberEnum([4, 5, 8, 12, 15, 16, 20]),
    denominatorC: numberEnum([4, 5, 8, 12, 15, 16, 20]),
    matchFraction: z.boolean()
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB, denominatorC] = getRandomSubArrayFromArray(
      [4, 5, 8, 12, 15, 16, 20] as const,
      3
    );

    const numeratorA = randomIntegerInclusive(1, Math.min(9, denominatorA - 1), {
      constraint: x =>
        // Check that the lowest power of the answer does not go beyond ten-thousandths
        ScientificNotation.fromNumber(x / denominatorA).resolution >= -3
    });

    const numeratorB = randomIntegerInclusive(1, Math.min(9, denominatorB - 1), {
      constraint: x =>
        // Check that the lowest power of the answer does not go beyond ten-thousandths
        ScientificNotation.fromNumber(x / denominatorB).resolution >= -3 &&
        !compareFractions([x, denominatorB], [numeratorA, denominatorA])
    });

    const numeratorC = randomIntegerInclusive(1, Math.min(9, denominatorC - 1), {
      constraint: x =>
        // Check that the lowest power of the answer does not go beyond ten-thousandths
        ScientificNotation.fromNumber(x / denominatorC).resolution >= -3 &&
        !compareFractions([x, denominatorC], [numeratorA, denominatorA]) &&
        !compareFractions([x, denominatorC], [numeratorB, denominatorB])
    });

    const matchFraction = getRandomBoolean();

    return {
      numeratorA,
      numeratorB,
      numeratorC,
      denominatorA,
      denominatorB,
      denominatorC,
      matchFraction
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        denominatorB,
        numeratorA,
        numeratorB,
        numeratorC,
        denominatorC,
        matchFraction
      },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        sentence: matchFraction
          ? `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`
          : (numeratorA / denominatorA).toLocaleString(),
        value: 'A'
      },
      {
        sentence: matchFraction
          ? `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`
          : (numeratorB / denominatorB).toLocaleString(),
        value: 'B'
      },
      {
        sentence: matchFraction
          ? `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`
          : (numeratorC / denominatorC).toLocaleString(),
        value: 'C'
      }
    ];

    const items = shuffle(
      [
        {
          sentence: matchFraction
            ? (numeratorA / denominatorA).toLocaleString()
            : `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`,
          value: 'A'
        },
        {
          sentence: matchFraction
            ? (numeratorB / denominatorB).toLocaleString()
            : `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`,
          value: 'B'
        },
        {
          sentence: matchFraction
            ? (numeratorC / denominatorC).toLocaleString()
            : `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`,
          value: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={
          matchFraction
            ? translate.instructions.dragCardsMatchFractionEquivalents()
            : translate.instructions.dragCardsMatchDecimalEquivalents()
        }
        pdfTitle={
          matchFraction
            ? translate.instructions.useCardsMatchFractionEquivalents()
            : translate.instructions.useCardsMatchDecimalEquivalents()
        }
        items={items.map(({ sentence, value }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value
          };
        })}
        actionPanelVariant="end"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(statement => `${statement.sentence} = <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionsAsDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
