import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { NumberLineWithSliderWithState } from '../representations/Number Line/NumberLineWithSlider';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { isInRange } from '../../../utils/matchers';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  min: number;
  max: number;
  sliderStep: number;
  tickValues: string[];
  testCorrect: [number, number] | ((userAnswer: number) => boolean);
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answerToDisplay?: number; answerText?: string };
};

export default function QF19NumberLineDragArrow({
  title,
  pdfTitle,
  min,
  max,
  sliderStep,
  tickValues,
  testCorrect,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <MeasureView style={{ alignItems: 'flex-start', paddingLeft: 10 }}>
            {dimens => (
              <NumberLineWithSliderWithState
                id="numberline"
                min={min}
                max={max}
                tickValues={tickValues}
                width={dimens.width - 100}
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView style={{ alignItems: 'flex-start', paddingLeft: 10 }}>
              {dimens => (
                <NumberLineWithSliderWithState
                  id="numberline"
                  defaultState={
                    typeof testCorrect === 'function'
                      ? customMarkSchemeAnswer?.answerToDisplay
                      : (testCorrect[0] + testCorrect[1]) / 2
                  }
                  min={min}
                  max={max}
                  tickValues={tickValues}
                  width={dimens.width - 100}
                />
              )}
            </MeasureView>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="bottom"
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <NumberLineWithSliderWithState
              id="numberLine"
              defaultState={min}
              testCorrect={
                typeof testCorrect === 'function'
                  ? testCorrect
                  : isInRange(testCorrect[0], testCorrect[1])
              }
              min={min}
              max={max}
              sliderStep={sliderStep}
              tickValues={tickValues}
              width={dimens.width}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
