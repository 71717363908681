import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from 'common/src/utils/random';
import { filledArray, rangeExcluding } from 'common/src/utils/collections';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { fluent } from '@codibre/fluent-iterable';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { roundToTheNearest } from 'common/src/utils/math';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aeq',
  description: 'aeq',
  keywords: ['Place value', 'Round', 'Number line', '1,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(110000).max(990000)
  }),
  example: {
    number: 730000
  },
  simpleGenerator: () => {
    const hundredThousands = randomIntegerInclusive(1, 9);
    const tenThousands = randomIntegerInclusive(1, 9);
    const number = tenThousands * 10000 + hundredThousands * 100000;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 100000) * 100000;
    const endNumber = Math.ceil(number / 100000) * 100000;
    const tickValues: (number | null)[] = filledArray(null, 11);
    tickValues[0] = startingNumber;
    tickValues[5] = startingNumber + 50000;
    tickValues[10] = endNumber;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest100000(number)}
        actionPanelVariant="bottomTall"
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 100000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aer',
  description: 'aer',
  keywords: ['Place value', 'Round', 'Number line', '1,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(111000).max(999000)
  }),
  example: {
    number: 644000
  },
  simpleGenerator: () => {
    const hundredThousands = randomIntegerInclusive(1, 9);
    const tenThousands = randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(1, 9);
    const number = tenThousands * 10000 + thousands * 1000 + hundredThousands * 100000;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 10000) * 10000;
    const endNumber = Math.ceil(number / 10000) * 10000;
    const tickValues: (number | null)[] = filledArray(null, 11);
    tickValues[0] = startingNumber;
    tickValues[5] = startingNumber + 5000;
    tickValues[10] = endNumber;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        actionPanelVariant="bottomTall"
        sentence={translate.answerSentences.roundToNearest10000(number)}
        textStyle={{ fontSize: 30 }}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aes',
  description: 'aes',
  keywords: ['Place value', 'Round', 'Million', '1,000,000', 'Sort'],
  schema: z.object({
    numbers: z
      .array(
        z
          .number()
          .int()
          .min(100001)
          .max(999999)
          .refine(x => x % 100000 !== 0)
      )
      .min(6)
      .max(6)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    //6x numbers >$var and <$var+100000.
    const numbers: number[] = [];
    const hundredThousands = randomIntegerInclusive(1, 9) * 100000;
    // 1. Include $var+50000.
    const fiftyThousand = hundredThousands + 50000;
    numbers.push(fiftyThousand);

    // 2. Include one other multiple of 10000.
    const randomMultipleOf10000 =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 10000 + hundredThousands;
    numbers.push(randomMultipleOf10000);

    // 3. Include 1 multiple of 1000.
    const randomMultipleOf1000TenThousands = randomIntegerInclusive(0, 9) * 10000;
    const randomMultipleOf1000Thousands =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 1000;
    const randomMultipleOf1000 =
      hundredThousands + randomMultipleOf1000TenThousands + randomMultipleOf1000Thousands;
    numbers.push(randomMultipleOf1000);

    // 4. Include 1 odd multiple of 5000.
    const oddMultiple5000TenThousands = randomIntegerInclusive(0, 9) * 10000;
    const oddMultiple5000 = hundredThousands + oddMultiple5000TenThousands + 5000;
    numbers.push(oddMultiple5000);

    // 5. Include 1 odd multiple of 500.
    const oddMultiple500TenThousands = randomIntegerInclusive(0, 9) * 10000;
    const oddMultiple500Thousands = randomIntegerInclusive(0, 9) * 100;
    const oddMultiple500 =
      hundredThousands + oddMultiple500TenThousands + oddMultiple500Thousands + 500;
    numbers.push(oddMultiple500);

    // 6. Random number
    const randomTenThousands = randomIntegerInclusive(0, 9) * 10000;
    const randomThousands = randomIntegerInclusive(0, 9) * 1000;
    const randomHundreds = randomIntegerInclusive(0, 9) * 100;
    const randomTens = randomIntegerInclusive(0, 9) * 10;
    const randomOnes = randomIntegerInclusive(1, 9);
    const randomNumber =
      hundredThousands +
      randomTenThousands +
      randomThousands +
      randomHundreds +
      randomTens +
      randomOnes;
    numbers.push(randomNumber);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / 100000) * 100000;
    const upper = Math.ceil(numbers[0] / 100000) * 100000;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, 100000) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(100000)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(100000)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        actionPanelVariant="bottom"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={1100}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aet',
  description: 'aet',
  keywords: ['Place value', 'Round', '1,000,000', 'Million'],
  schema: z.object({
    numbers: z.array(z.number().int().min(100000).max(999999)).length(3)
  }),
  example: {
    numbers: [217254, 444211, 928777]
  },
  simpleGenerator: () => {
    const numAnswers = 3;
    const numberSet = new Set<number>();
    while (numberSet.size < numAnswers) {
      const hundredThousands = randomIntegerInclusive(1, 9);
      const tenThousands = randomIntegerInclusive(0, 9);
      const thousands = randomIntegerInclusive(0, 9);
      const hundreds = randomIntegerInclusive(0, 9);
      const tens = randomIntegerInclusive(0, 9);
      const ones = randomIntegerInclusive(1, 9);
      numberSet.add(
        hundredThousands * 100000 +
          tenThousands * 10000 +
          thousands * 1000 +
          hundreds * 100 +
          tens * 10 +
          ones
      );
    }
    const numbers = fluent(numberSet).toArray();
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate,
      displayMode
    } = props;
    const nearest100000 = roundToTheNearest(numbers[0], 100000);
    const nearest10000 = roundToTheNearest(numbers[1], 10000);
    const nearest1000 = roundToTheNearest(numbers[2], 1000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        textStyle={displayMode === 'digital' ? { fontSize: 28 } : undefined}
        testCorrect={[
          [nearest100000.toString()],
          [nearest10000.toString()],
          [nearest1000.toString()]
        ]}
        sentences={[
          translate.answerSentences.roundToNearest100000(numbers[0]),
          translate.answerSentences.roundToNearest10000(numbers[1]),
          translate.answerSentences.roundToNearest1000(numbers[2])
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aeu',
  description: 'aeu',
  keywords: ['Place value', 'Round', '1,000,000', 'Million'],
  schema: z.object({
    comparitor: z.number().int().step(100).min(100001).max(999999),
    numbers: z.number().int().min(0).max(1000000).array().length(6)
  }),
  example: {
    comparitor: 700000,
    numbers: [633320, 650000, 683079, 723005, 750000, 780830]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = randomIntegerInclusive(2, 9) * 100000;

    // $var-10001<num1<$var-5000
    const num1 = randomIntegerInclusive(comparitor - 100000, comparitor - 50001);

    // num2=$var-5000
    const num2 = comparitor - 50000;

    // $var-5000<num3<$var
    const num3 = randomIntegerInclusive(comparitor - 49999, comparitor - 1);

    // $var+5000<num1<$var+10001
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 49999);

    // num2=$var+5000
    const num5 = comparitor + 50000;

    // $var<num3<$var+5000
    const num6 = randomIntegerInclusive(comparitor + 50001, comparitor + 100000);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumsRoundToNumToNearestNum(comparitor, 100000)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 100000) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aev',
  description: 'aev',
  keywords: ['Place value', 'Round', '1,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(10000).max(999999)
  }),
  simpleGenerator: () => {
    const hundredThousands = randomIntegerInclusive(0, 9);
    const tenThousands = randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(0, 9);
    const tens = randomIntegerInclusive(0, 9);
    const ones = randomIntegerInclusive(0, 9);
    const number =
      hundredThousands * 100000 +
      tenThousands * 10000 +
      thousands * 1000 +
      hundreds * 100 +
      tens * 10 +
      ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const nearest1000 = roundToTheNearest(number, 1000);
    const nearest10000 = roundToTheNearest(number, 10000);
    const nearest100000 = roundToTheNearest(number, 100000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundNum(number)}
        testCorrect={[
          [nearest1000.toString()],
          [nearest10000.toString()],
          [nearest100000.toString()]
        ]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        sentences={[
          translate.answerSentences.roundToNearest1000Equation(number),
          translate.answerSentences.roundToNearest10000Equation(number),
          translate.answerSentences.roundToNearest100000Equation(number)
        ]}
        textStyle={{ fontSize: 32 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundWithin1000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
