import { newBlockContent } from '../../../Block';

import MeasureInCentimetres from './1MeasureInCentimetres';
import MeasureInMetres from './2MeasureInMetres';
import CompareLengthsAndHeights from './3CompareLengthsAndHeights';
import OrderLengthsAndHeights from './4OrderLengthsAndHeights';
import FourOperationsWithLengthsAndHeights from './5FourOperationsWithLengthsAndHeights';

const Block = newBlockContent({
  block: 'LengthAndHeight',
  field: 'Measurement',
  weeks: [8, 9],
  smallSteps: [
    MeasureInCentimetres, // Step 1
    MeasureInMetres, // Step 2
    CompareLengthsAndHeights, // Step 3
    OrderLengthsAndHeights, // Step 4
    FourOperationsWithLengthsAndHeights // Step 5
  ]
});
export default Block;
