import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { Direction, Point2d } from '../../../../utils/vectors';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import { colors } from '../../../../theme/colors';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { countRange, nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ALGEBRAIC_X } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aA4',
  description: 'aA4',
  keywords: ['Translation', 'Square', 'Grid'],
  questionHeight: 1200,
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-4).max(4),
      translateY: z.number().int().min(-4).max(4)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);
    const translateX = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    return { startX, startY, translateX, translateY };
  },
  Component: ({ question: { startX, startY, translateX, translateY }, translate, displayMode }) => {
    // PointB Coordinates
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.shapeAHasBeenTranslatedAnsDxAndAnsDy(
          translate.keywords.Point(),
          distanceX,
          translate.directions[directionX](),
          distanceY,
          translate.directions[directionY]()
        )}
        questionHeight={1200}
        pdfDirection="column"
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
              label={{
                text: translate.letters.A(),
                styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
              }}
            />
            <GridImage
              mathCoord={[end.x, end.y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
              label={{
                text: translate.letters.B(),
                styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aA5',
  description: 'aA5',
  keywords: ['Translation', 'Coordinate grid'],
  questionHeight: 1200,
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-4).max(4),
      translateY: z.number().int().min(-4).max(4)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);
    const translateX = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    return { startX, startY, translateX, translateY };
  },
  Component: props => {
    const {
      question: { startX, startY, translateX, translateY },
      translate,
      displayMode
    } = props;

    // PointB Coordinates
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const items = [
      translate.directions.left(),
      translate.directions.right(),
      translate.directions.up(),
      translate.directions.down()
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToDescribeTranslation()}
        pdfTitle={translate.instructions.useCardsToDescribeTranslation()}
        actionPanelVariant="end"
        items={items}
        itemVariant="square"
        pdfLayout="itemsBottom"
        questionHeight={1200}
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                height: displayMode === 'digital' ? undefined : 60,
                width: displayMode === 'digital' ? undefined : 60
              }}
              label={translate.letters.A()}
            />
            <GridImage
              mathCoord={[end.x, end.y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                height: displayMode === 'digital' ? undefined : 60,
                width: displayMode === 'digital' ? undefined : 60
              }}
              label={translate.letters.B()}
            />
          </Grid>
        )}
        sentence={translate.answerSentences.aToBIsTranslationOfXAnsYAns(distanceX, distanceY)}
        testCorrect={[translate.directions[directionX](), translate.directions[directionY]()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aA6',
  description: 'aA6',
  keywords: ['Translation', 'Coordinate grid'],
  questionHeight: 1200,
  schema: z.object({
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2),
    coordinate4: z.number().int().min(0).max(5).array().length(2),
    translateX: z.number().int().min(-4).max(4),
    translateY: z.number().int().min(-4).max(4)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const translateX = randomIntegerInclusive(-4, 4, {
          constraint: x => x !== 0 && coordinate1[0] + x > 0 && coordinate1[0] + x < 6
        });
        const translateY = randomIntegerInclusive(-4, 4, {
          constraint: x => x !== 0 && coordinate1[1] + x > 0 && coordinate1[1] + x < 6
        });
        const coordinate4 = [coordinate1[0] + translateX, coordinate1[1] + translateY];
        return { coordinate1, coordinate2, coordinate3, coordinate4, translateX, translateY };
      },
      val =>
        nestedArrayHasNoDuplicates(
          [
            val.coordinate1,
            val.coordinate2,
            val.coordinate3,
            [val.coordinate1[0] + val.translateX, val.coordinate1[1] + val.translateY]
          ],
          true
        )
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, coordinate4, translateX, translateY },
      translate,
      displayMode
    } = props;

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];
    const items = countRange(4, 1).map(i => i.toLocaleString());
    // shuffle them so its not always translation from A to B
    const labels = shuffle(['A', 'B', 'C', 'D'], { random: seededRandom(props.question) });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToDescribeTranslation()}
        pdfTitle={translate.instructions.useCardsToDescribeTranslation()}
        actionPanelVariant="end"
        items={items}
        itemVariant="square"
        pdfLayout="itemsBottom"
        moveOrCopy="copy"
        questionHeight={1200}
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={5} yMax={5} squareGrid>
            {coordinates.map((coord, i) => (
              <GridImage
                key={i}
                mathCoord={coord as [number, number]}
                item={{
                  component: 'Coordinates/CirclePointCustomizable',
                  svgProps: {
                    fill: colors.pacificBlue
                  },
                  height: displayMode === 'digital' ? undefined : 60,
                  width: displayMode === 'digital' ? undefined : 60
                }}
                label={translate.letters[labels[i] as 'A' | 'B' | 'C' | 'D']()}
              />
            ))}
          </Grid>
        )}
        sentence={translate.answerSentences.aToBIsTranslationOfAnsXAnsY(
          translate.letters[labels[0] as 'A' | 'B' | 'C' | 'D'](),
          translate.letters[labels[3] as 'A' | 'B' | 'C' | 'D'](),
          distanceX,
          translate.directions[directionX](),
          distanceY,
          translate.directions[directionY]()
        )}
        testCorrect={[distanceX.toLocaleString(), distanceY.toLocaleString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aA7',
  description: 'aA7',
  keywords: ['Translation', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5).array().length(2),
    translateInX: z.boolean(),
    translateBy: z.number().int().min(-4).max(4)
  }),
  simpleGenerator: () => {
    const coordinate = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
    const translateInX = getRandomBoolean();
    const index = translateInX ? 0 : 1;
    const translateBy = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && coordinate[index] + x > 0 && coordinate[index] + x < 6
    });
    return { coordinate, translateInX, translateBy };
  },
  Component: ({ question: { coordinate, translateInX, translateBy }, translate }) => {
    // Direction/Distance
    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case translateBy > 0 && translateInX:
          return ['right', translateBy];
        case translateBy < 0 && translateInX:
          return ['left', -translateBy];
        case translateBy > 0 && !translateInX:
          return ['up', translateBy];
        case translateBy < 0 && !translateInX:
          return ['down', -translateBy];
        default:
          throw new Error('Unreachable');
      }
    })();

    const answerX = translateInX ? coordinate[0] + translateBy : coordinate[0];
    const answerY = translateInX ? coordinate[1] : coordinate[1] + translateBy;

    return (
      <QF1ContentAndSentence
        title={
          translateInX
            ? translate.instructions.writeTheCoordinatesOfThePointAfterTranslationDx(
                distance,
                translate.directions[direction]()
              )
            : translate.instructions.writeTheCoordinatesOfThePointAfterTranslationDy(
                distance,
                translate.directions[direction]()
              )
        }
        sentence="( <ans/> , <ans/> )"
        testCorrect={[answerX.toString(), answerY.toString()]}
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'center' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aA8',
  description: 'aA8',
  keywords: ['Translation', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5).array().length(2),
    translateX: z.number().int().min(-4).max(4),
    translateY: z.number().int().min(-4).max(4)
  }),
  simpleGenerator: () => {
    const coordinate = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
    const translateX = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && coordinate[0] + x > 0 && coordinate[0] + x < 6
    });
    const translateY = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && coordinate[1] + x > 0 && coordinate[1] + x < 6
    });
    return { coordinate, translateX, translateY };
  },
  Component: ({ question: { coordinate, translateX, translateY }, translate }) => {
    const start = new Point2d(coordinate[0], coordinate[1]);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.writeTheCoordinatesOfThePointAfterTranslationsDxDy(
          distanceX,
          translate.directions[directionX](),
          distanceY,
          translate.directions[directionY]()
        )}
        sentence="( <ans/> , <ans/> )"
        testCorrect={[end.x.toString(), end.y.toString()]}
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'center' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aA9',
  description: 'aA9',
  keywords: ['Translation', 'Coordinate grid'],
  schema: z.object({
    x1: z.number().int().min(1).max(20),
    x2: z.number().int().min(10).max(30),
    y1: z.number().int().min(1).max(20),
    y2: z.number().int().min(10).max(30)
  }),
  simpleGenerator: () => {
    const x1 = randomIntegerInclusive(1, 20);
    const y1 = randomIntegerInclusive(1, 20);
    const x2 = randomIntegerInclusive(10, 30, { constraint: x => x !== x1 });
    const y2 = randomIntegerInclusive(10, 30, { constraint: y => y !== y1 });
    return { x1, y1, x2, y2 };
  },
  Component: props => {
    const {
      question: { x1, y1, x2, y2 },
      translate,
      displayMode
    } = props;

    const translateX = x1 - x2;
    const translateY = y1 - y2;

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX < 0:
          return ['right', -translateX];
        case translateX > 0:
          return ['left', translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY < 0:
          return ['up', -translateY];
        case translateY > 0:
          return ['down', translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.pointAHasCoordsXY1AfterTranslationXY2CompleteSentence(
          ALGEBRAIC_X,
          x1,
          y1,
          x2,
          y2
        )}
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={translate.answerSentences.pointXTranslatedAnsDxAndAnsDy(
          ALGEBRAIC_X,
          translate.directions[directionX](),
          translate.directions[directionY]()
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TranslationWithCoordinates',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
