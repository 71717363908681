import { ReactNode, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type Props = {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  itemsStyle?: StyleProp<ViewStyle>;
};

export default function DragAndDropSection({ children, style, itemsStyle }: Props) {
  const styles = useStyles();

  return (
    <View style={[style]}>
      <View style={[styles.itemsContainer, itemsStyle]}>{children}</View>
    </View>
  );
}

function useStyles() {
  return useMemo(
    () =>
      StyleSheet.create({
        itemsContainer: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          alignContent: 'center',
          gap: 16
        }
      }),
    []
  );
}
