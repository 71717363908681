import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { filledArray, range, rangeAsString } from 'common/src/utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import { base10ObjectToNumber, isWithinRange, numberToBase10Object } from 'common/src/utils/math';
import QF19NumberLineDragArrow from 'common/src/components/question/questionFormats/QF19NumberLineDragArrow';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aa8',
  description: 'aa8',
  keywords: ['Number line', 'Place value', '1,000', 'Estimate'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(5)
      .max(995)
      .refine(number => number % 10 !== 0 && number % 5 === 0, {
        message: 'Number must be odd multiple of 5'
      }),
    startingNumber: z.number().int().min(0).max(900).multipleOf(100),
    endNumber: z.number().int().min(200).max(1000).multipleOf(100)
  }),
  simpleGenerator: () => {
    const answerInterval = 5;
    const startingNumber = randomIntegerInclusive(1, 9) * 100;
    const endNumber = startingNumber + 100;

    // Make this miss the first and last number
    // Number must be odd multiple of 5
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval * 2
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateNumberArrowIsPointingTo()}
        testCorrect={answer => isWithinRange(parseInt(answer[0]), number, 2)}
        inputMaxCharacters={4}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answersToDisplay: [number.toLocaleString()],
          answerText: translate.markScheme.answersThatAreXFromYAccepted(2, number)
        }}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'aa9',
  description: 'aa9',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(0).max(1000),
      startingNumber: z.number().int().multipleOf(100).min(0).max(500),
      endNumber: z.number().int().multipleOf(100).min(500).max(1000)
    })
    .refine(
      val => val.number > val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number < val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 5) * 100;
    const endNumber = startingNumber + 500;
    const answerInterval = 50;

    // The answer is an odd multiple of 50
    const number = randomIntegerInclusiveStep(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval * 2
    );

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;

    const tickInterval = 100;
    const sliderInterval = 1;

    const numberArray = rangeAsString(startingNumber, endNumber, tickInterval, true);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={[number - 10, number + 10]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aba',
  description: 'aba',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand', 'Estimate'],
  schema: z.object({
    number1: z.number().int().min(100).max(900).multipleOf(100),
    number2: z.number().int().min(100).max(900).multipleOf(100),
    number3: z.number().int().min(99).max(899)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 900, 100, {
      // We don't want the number to be exactly on the middle of the number line
      constraint: x => x !== 500
    });
    const number2 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== 500 && x !== number1
    });
    const number3 = randomIntegerInclusiveStep(100, 900, 100) - 1;
    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 500;
    const labelInterval = 1000;
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    const left = translate.directions.left();
    const right = translate.directions.right();

    return (
      <QF36ContentAndSentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        items={[left, right]}
        itemVariant="rectangle"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentences={[
          `${translate.answerSentences.numIsToThe(
            number1.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`,
          `${translate.answerSentences.numIsToThe(
            number2.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`,
          `${translate.answerSentences.numIsToThe(
            number3.toLocaleString()
          )} <ans/> ${translate.answerSentences.ofHalfway()}`
        ]}
        testCorrect={[
          [number1 < 500 ? left : right],
          [number2 < 500 ? left : right],
          [number3 < 500 ? left : right]
        ]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'abb',
  description: 'abb',
  keywords: ['Number line', 'Place value', '1,000', 'Estimate'],
  schema: z.object({
    number: z.number().int().min(10).max(990).multipleOf(10)
  }),
  simpleGenerator: () => {
    const hundreds = randomIntegerInclusiveStep(0, 900, 100);
    const tens = randomIntegerInclusiveStep(10, 90, 10);
    const number = hundreds + tens;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 100;
    const startingNumber = 0;
    const endNumber = 1000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    // Convenience base 10 objects for answer comparisons
    const numberB10 = numberToBase10Object(number);

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateNumberArrowIsPointingTo()}
        testCorrect={answer => {
          // parse the answer and find the hundreds and the total of tens plus ones
          const answerNumber = parseInt(answer[0]);
          const answerNumberHundreds = Math.floor(answerNumber / 100);
          const answerTensPlusOnes =
            Math.floor((answerNumber % 100) / 10) * 10 + (answerNumber % 10);

          // compare correct number of hundreds with the answer number hundreds
          if (numberB10.hundreds === answerNumberHundreds) {
            // if hundreds are correct check that answer tens plus ones is between the correct amount.
            // eg.  if correct answer tens is less than 5 then answer tens and ones should be between 5 and 45
            // if correct answer tens is greater than 5 the answer tens and ones should be between 55 and 95.
            if (numberB10.tens! < 5) {
              return answerTensPlusOnes >= 5 && answerTensPlusOnes <= 45;
            } else {
              return answerTensPlusOnes >= 55 && answerTensPlusOnes <= 95;
            }
          }
          return false;
        }}
        inputMaxCharacters={4}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answersToDisplay: [number.toLocaleString()],
          answerText: translate.markScheme.answersThatAreCloseToX(number)
        }}
      />
    );
  }
});

export const Question5 = newQuestionContent({
  uid: 'abc',
  description: 'abc',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(100).max(1000),
      startingNumber: z.number().int().multipleOf(100).min(0).max(900),
      endNumber: z.number().int().multipleOf(100).min(100).max(1000)
    })
    .refine(
      val => val.number > val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number < val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 9) * 100;
    const endNumber = startingNumber + 100;
    const answerInterval = 1;

    const number = randomIntegerInclusiveStep(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval,
      {
        constraint: x => {
          // Reject any number that has 0 or 5 ones
          return x % 10 !== 5 && x % 10 !== 0;
        }
      }
    );

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 10;
    const sliderInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    // Convenience base 10 objects for answer comparisons
    // $var is a number ending between 1 to 4 or 6 to 9.
    // Allow answers within range of 0.5 to 4.5 for ones of 1 to 4 and 5.5 to 9.5 for 6 to 9
    // These 2 upper and lower values mean we can compare +- 2 of this number.
    const numberB10 = numberToBase10Object(number);
    const b10RepLower = numberToBase10Object(number);
    b10RepLower.ones = 2;
    b10RepLower.tenths = 5;
    const lowerAns = base10ObjectToNumber(b10RepLower);

    const b10RepUpper = numberToBase10Object(number);
    b10RepUpper.ones = 7;
    b10RepUpper.tenths = 5;
    const upperAns = base10ObjectToNumber(b10RepUpper);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={
          numberB10.ones! < 5 ? [lowerAns - 2, lowerAns + 2] : [upperAns - 2, upperAns + 2]
        }
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'abd',
  description: 'abd',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand', 'Estimate'],
  schema: z
    .object({
      number: z.number().int().min(0).max(900),
      startingNumber: z.number().int().multipleOf(100).min(0).max(700),
      endNumber: z.number().int().multipleOf(100).min(200).max(900)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 7) * 100;
    const endNumber = startingNumber + 200;
    const answerInterval = 1;

    const number = randomIntegerInclusiveStep(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval,
      {
        constraint: x => {
          // Reject any number that is not between start+20 and start+80 or end-20 and end-80
          if (x < startingNumber + 100) {
            return x >= startingNumber + 20 && x <= startingNumber + 80;
          } else {
            return x <= endNumber - 20 && x >= endNumber - 80;
          }
        }
      }
    );

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 100;
    const sliderInterval = 1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        testCorrect={[number - 10, number + 10]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderInterval}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateOnANumberLineTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
