import { type FlexStyle, type StyleProp } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { Circle, Path, Svg } from 'react-native-svg';
import { containAspectRatio } from '../../../../theme/scaling';

type RekenrekScale = 'small' | 'medium';

/** Different sizes of the rekenrek just scales everything down. */
const REKENREK_SCALE: Record<RekenrekScale, number> = {
  small: 0.5,
  medium: 1
};

const HEIGHT_BETWEEN_ROWS = 50;
const HEIGHT_ABOVE_FIRST_ROW = 52.898845;
const HEIGHT_BELOW_LAST_ROW = 57.488985;
const WIDTH = 776;
const WIDTH_BETWEEN_COUNTERS = 35.605;
const WIDTH_LEFT_OF_FIRST_COUNTER = 34.934465;
const WIDTH_RIGHT_OF_LAST_COUNTER = 34.934465;

type Props = {
  /**
   * Overall scale.
   * You can provide a number for arbitrary scaling, but make sure the rekenrek's counters are still readable.
   * See {@link calculateRekenrekScale} if you want to scale several rekenreks to the same scale.
   * Default: 'medium'
   */
  scale?: RekenrekScale | number;
  /**
   * Usable dimensions.
   * If this is provided, the rekenrek scales to be largest that fits these dimensions, overriding `fit` and `size`.
   * Caution: if using this, ensure that the rekenrek's counters big enough to be unreadable.
   */
  dimens?: { width: number; height: number };
  /**
   * Integer between 1 and 10 inclusive
   * Counters will flip from red on the left to white on the left, every 5 rows.
   */
  rows: number;
  /** How many rows in each section of the same colors. Default: 5, i.e. flip colors for rows 5-9. */
  rowsBetweenColorFlips?: number;
  /**
   * Either:
   * - number - Must be an integer, and displayable in the rows given.
   * - array - Each value represents the counters moved to the left side on that row.
   *           Must have length equal to number of rows, and each value should be integer between 0 and 10 inclusive.
   */
  numberShown: number | number[];
  /** Layout style to pass in. Do not pass width/height here. */
  style?: StyleProp<FlexStyle>;
};

/**
 * Non-interactive rekenrek.
 *
 * ## Sizing
 *
 * Can choose the size of the rekenrek either with the `scale` property, which chooses the scale of an individual
 * counter, or with the `dimens` property, which auto-scales the rekenrek into the dimensions given.
 *
 * Recommendation: use the `scale` prop, with one of the preset scales {@link RekenrekScale}. The other sizing options
 * are an escape hatch, and shouldn't be required too much.
 */
export default function Rekenrek({
  scale: scaleProp = 'medium',
  dimens,
  rows,
  rowsBetweenColorFlips = 5,
  numberShown,
  style
}: Props) {
  const height = HEIGHT_ABOVE_FIRST_ROW + HEIGHT_BELOW_LAST_ROW + (rows - 1) * HEIGHT_BETWEEN_ROWS;

  const scale = dimens
    ? containAspectRatio(dimens, WIDTH / height).width / WIDTH // Use largest rectangle that fits in the dimens
    : typeof scaleProp === 'string' // Use the `scale` prop
    ? REKENREK_SCALE[scaleProp]
    : scaleProp;
  const scaledDimens = { width: WIDTH * scale, height: height * scale };

  const countersLeftOnEachRow: number[] =
    typeof numberShown === 'number'
      ? countRange(rows).map(row => {
          const n = numberShown - row * 10;
          return n >= 10 ? 10 : n < 0 ? 0 : n;
        })
      : numberShown;

  return (
    <Svg
      width={scaledDimens.width}
      height={scaledDimens.height}
      viewBox={`0 0 ${WIDTH} ${height}`}
      style={[{ flexShrink: 0 }, scaledDimens, style]}
    >
      {countRange(rows).map(row => wire(row))}
      {pillar(height, true)}
      {pillar(height, false)}
      {countRange(rows).flatMap(row =>
        countRange(10).map(column =>
          counter(row, countersLeftOnEachRow[row] > column, column, rowsBetweenColorFlips)
        )
      )}
    </Svg>
  );
}

function counter(row: number, inNumber: boolean, column: number, rowsBetweenColorFlips: number) {
  const flipColor = Math.floor(row / rowsBetweenColorFlips) % 2 === 1; // if using 5, true for rows 5-9, 15-19, etc.
  const isRed = column < rowsBetweenColorFlips !== flipColor;

  return (
    <Circle
      key={`${row}-${column}`}
      cx={
        inNumber
          ? WIDTH_LEFT_OF_FIRST_COUNTER + column * WIDTH_BETWEEN_COUNTERS
          : WIDTH - WIDTH_RIGHT_OF_LAST_COUNTER - (9 - column) * WIDTH_BETWEEN_COUNTERS
      }
      cy={HEIGHT_ABOVE_FIRST_ROW + row * HEIGHT_BETWEEN_ROWS}
      r={16.5}
      strokeWidth={2.93059}
      stroke="#1d1d1b"
      fill={isRed ? '#E30613' : 'white'}
    />
  );
}

function wire(row: number) {
  return (
    <Path
      key={row}
      d={`M 8.492645,${HEIGHT_ABOVE_FIRST_ROW + row * HEIGHT_BETWEEN_ROWS} H ${WIDTH - 6.16432}`}
      stroke="#1d1d1b"
      strokeWidth="2.93059"
    />
  );
}

function pillar(height: number, isLeft: boolean) {
  return (
    <Path
      d={`M ${
        11.156865 + (isLeft ? 0 : WIDTH - 17)
      },1.465295 h -5.32139 c -2.41358,0 -4.37018,1.9566 -4.37018,4.37018 V ${
        height - 5.83729
      } c 0,2.413 1.9566,4.37 4.37018,4.37 h 5.32139 c 2.4135,0 4.3701,-1.957 4.3701,-4.37 V 5.835475 c 0,-2.41358 -1.9566,-4.37018 -4.3701,-4.37018 z`}
      fill="#ae7c48"
      stroke="#1d1d1b"
      strokeWidth="2.93059"
    />
  );
}
