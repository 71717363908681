import { SUB } from '../constants';
import { Fraction, MixedFraction } from './fractions';
import { parseToSUB } from './parse';

/**
 * Converts a mixed fraction to a formatted markup string.
 * Denomatinor must always be non-negative and not zero, other numbers can be negative.
 *
 * @param mixedFraction - mixed fraction to be converted
 * @returns - formatted markup string, can be simple integer or fraction
 */
export const mixedFractionToMarkup = (mixedFraction: MixedFraction): string => {
  const [whole, num, denom] = mixedFraction;

  if (denom < 0) {
    throw new Error('Denominator must be non-negative and not equal to zero.');
  }

  // Special cases
  // Whole part is 0
  if (whole === 0) {
    return fractionToMarkup([num, denom]);
  }
  // Fraction part evaluates to 0, replace minus with SUB symbol
  if (num === 0) {
    return parseToSUB(whole.toLocaleString());
  }

  // Mixed fraction
  return `<frac w='${parseToSUB(whole.toLocaleString())}' n='${num}' d='${denom}'/>`;
};

/**
 * Converts fraction to formatted markup string.
 * Denomatinor must always be non-negative and not zero, numerator can be negative.
 *
 * @param fraction - fraction to be converted
 * @returns - formatted markup string, can be simple integer or fraction
 */
export const fractionToMarkup = (fraction: Fraction): string => {
  const [num, denom] = fraction;

  if (denom < 0) {
    throw new Error('Denominator must be non-negative and not equal to zero.');
  }

  if (num === 0) {
    return '0';
  }

  return `${num < 0 ? SUB : ''}<frac n='${Math.abs(num)}' d='${denom}'/>`;
};
