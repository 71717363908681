import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { View } from 'react-native';
import ShadedFractionBarModel, {
  ShadedFractionBarModelWithState
} from 'common/src/components/question/representations/ShadedFractionBarModel';
import {
  countRange,
  filledArray,
  nestedArrayHasNoDuplicates,
  range,
  sumNumberArray
} from 'common/src/utils/collections';
import { barModelColors, colors } from 'common/src/theme/colors';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF36ContentAndSentencesDrag from 'common/src/components/question/questionFormats/QF36ContentAndSentencesDrag';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF17bCompleteNumberLineDraggable from 'common/src/components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import { compareFractions, mixedNumberToImproperFraction } from 'common/src/utils/fractions';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMA',
  description: 'aMA',
  keywords: ['Bar model', 'Parts', 'Wholes', 'Numerator', 'Denominator', 'Improper fraction'],
  schema: z.object({
    denominator: z.number().int().min(2).max(6),
    whole: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const whole = randomIntegerInclusive(1, 5);

    return { denominator, whole };
  },
  Component: props => {
    const {
      question: { denominator, whole },
      translate
    } = props;

    const numerator = whole * denominator;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, denominator);
    const customColorMap = [[...numeratorColorArray1]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.fillInMissingNumbers()}
        sentence={`<frac nAns='' d='${denominator}' /> = <ans/> ${
          whole === 1
            ? translate.keywords.Whole().toLowerCase()
            : translate.keywords.Wholes().toLowerCase()
        }`}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: dimens.width,
              height: 60 * whole + 20,
              alignItems: 'center'
            }}
          >
            <View style={{ display: 'flex', rowGap: 16 }}>
              {countRange(whole).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width - 20}
                  customColorMap={customColorMap[0]}
                />
              ))}
            </View>
          </View>
        )}
        inputMaxCharacters={1}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], denominator], [numerator, denominator]) &&
          userAnswer[1] === whole.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), whole.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aMA2',
  description: 'aMA',
  keywords: ['Bar model', 'Parts', 'Wholes', 'Numerator', 'Denominator', 'Improper fraction'],
  schema: z.object({
    denominator: z.number().int().min(2).max(6),
    whole: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const whole = randomIntegerInclusive(1, 5);

    return { denominator, whole };
  },
  Component: props => {
    const {
      question: { denominator, whole },
      translate
    } = props;

    const numerator = whole * denominator;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, denominator);
    const customColorMap = [[...numeratorColorArray1]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.fillInMissingNumber()}
        sentence={`<frac nAns='' d='${denominator}' /> = ${whole} ${
          whole === 1
            ? translate.keywords.Whole().toLowerCase()
            : translate.keywords.Wholes().toLowerCase()
        }`}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: dimens.width,
              height: 60 * whole + 20,
              alignItems: 'center'
            }}
          >
            <View style={{ display: 'flex', rowGap: 16 }}>
              {countRange(whole).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width - 20}
                  customColorMap={customColorMap[0]}
                />
              ))}
            </View>
          </View>
        )}
        inputMaxCharacters={2}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], denominator], [numerator, denominator])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aMB',
  description: 'aMB',
  keywords: [
    'Double number line',
    'Improper fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(5),
    wholeA: z.number().int().min(0).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 5);
    const wholeA = randomIntegerInclusive(0, 8);

    return {
      denominator,
      wholeA
    };
  },
  Component: props => {
    const {
      question: { denominator, wholeA },
      translate,
      displayMode
    } = props;

    const wholeB = wholeA + 1;
    const wholeC = wholeB + 1;

    const numeratorA = denominator * wholeA;
    const numeratorB = denominator * wholeB;
    const numeratorC = denominator * wholeC;

    const items = shuffle(
      [
        {
          component: (
            <TextStructure
              fractionTextStyle={{
                fontWeight: '700',
                fontSize: displayMode === 'digital' ? 30 : 50
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              sentence={`<frac n='${numeratorB}' d='${denominator}' />`}
            />
          ),
          value: `${numeratorB}`
        },
        ...countRange(denominator - 1)
          .map(idx => {
            return {
              component: (
                <TextStructure
                  fractionTextStyle={{
                    fontWeight: '700',
                    fontSize: displayMode === 'digital' ? 30 : 50
                  }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  sentence={`<frac n='${numeratorA + (idx + 1)}' d='${denominator}' />`}
                />
              ),
              value: `${numeratorA + (idx + 1)}`
            };
          })
          .filter((_item, idx) => idx <= 2),
        ...countRange(denominator - 1)
          .map(idx => {
            return {
              component: (
                <TextStructure
                  fractionTextStyle={{
                    fontWeight: '700',
                    fontSize: displayMode === 'digital' ? 30 : 50
                  }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  sentence={`<frac n='${numeratorB + (idx + 1)}' d='${denominator}' />`}
                />
              ),
              value: `${numeratorB + (idx + 1)}`
            };
          })
          .filter((_item, idx) => idx <= 2)
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragTheCardToShowTheMissingNumberOnTheNumberLine()}
        pdfTitle={translate.instructions.useTheCardToShowTheMissingNumberOnTheNumberLine()}
        topTickValues={[
          wholeA.toLocaleString(),
          ...countRange(denominator - 1).map(() => ''),
          wholeB.toLocaleString(),
          ...countRange(denominator - 1).map(() => ''),
          wholeC.toLocaleString()
        ]}
        bottomTickValues={[
          wholeA === 0
            ? '0'
            : `<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
          ...countRange(denominator - 1).map(() => ''),
          `<ans />`,
          ...countRange(denominator - 1).map(() => ''),
          `<frac n='${numeratorC.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        ]}
        items={items}
        testCorrect={[numeratorB.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMC',
  description: 'aMC',
  keywords: ['Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(10),
      denominatorB: z.number().int().min(1).max(10),
      wholeA: z.number().int().min(1).max(6),
      wholeB: z.number().int().min(7).max(12)
    })
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA must not be equal to denominatorB'
    ),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 6);
    const denominatorB = randomIntegerInclusive(2, 6, {
      constraint: x => x !== denominatorA
    });
    const wholeA = randomIntegerInclusive(1, 6);
    const wholeB = randomIntegerInclusive(7, 12);

    return {
      denominatorA,
      denominatorB,
      wholeA,
      wholeB
    };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, wholeA, wholeB },
      translate
    } = props;

    const numeratorA = denominatorA * wholeA;
    const numeratorB = denominatorB * wholeB;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === wholeA.toString() && userAnswer[1][0] === wholeB.toString()
        }
        inputMaxCharacters={1}
        sentences={[
          `<frac n='${numeratorA}' d='${denominatorA.toLocaleString()}' /> = <ans/> ${translate.keywords
            .Wholes()
            .toLowerCase()}`,
          `<frac n='${numeratorB}' d='${denominatorB.toLocaleString()}' /> = <ans/> ${translate.keywords
            .Wholes()
            .toLowerCase()}`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [[wholeA.toString()], [wholeB.toString()]]
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aMC2',
  description: 'aMC',
  keywords: ['Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z.object({
    denominator: z.number().int().min(2).max(6),
    whole: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const randomSet = getRandomBoolean();
    const denominator = randomIntegerInclusive(2, 6);
    const whole = randomSet ? randomIntegerInclusive(1, 6) : randomIntegerInclusive(7, 12);

    return {
      denominator,
      whole,
      randomSet
    };
  },
  Component: props => {
    const {
      question: { denominator, whole },
      translate
    } = props;

    const numerator = denominator * whole;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeStatement()}
        testCorrect={userAnswer => userAnswer[0] === whole.toString()}
        inputMaxCharacters={1}
        sentence={`<frac n='${numerator}' d='${denominator}' /> = <ans/> ${translate.keywords
          .Wholes()
          .toLowerCase()}`}
        customMarkSchemeAnswer={{
          answersToDisplay: [whole.toString()]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aMD',
  description: 'aMD',
  keywords: ['Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z
    .object({
      wholeA: z.number().int().min(1).max(5),
      denominatorA: z.number().int().min(2).max(6),
      numerator: z.number().int().min(1).max(5),
      correctAnswer: z.array(z.number()),
      incorrectAnswers: z.array(z.array(z.number())).length(3)
    })
    .refine(
      val => val.numerator !== val.denominatorA,
      'numerator must not be equal to denominatorA'
    ),
  simpleGenerator: () => {
    const { wholeA, denominatorA, numerator, correctAnswer, incorrectAnswers } = rejectionSample(
      () => {
        const wholeA = randomIntegerInclusive(1, 5);
        const denominatorA = randomIntegerInclusive(2, 6);
        const numerator = randomIntegerInclusive(1, denominatorA - 1);

        const wholeB = wholeA + 1;
        const wholeC = denominatorA * (wholeA + 1);
        const numeratorC = denominatorA * wholeA + numerator;

        // Correct
        const correctAnswer = [wholeA, numerator, denominatorA];

        // Incorrect answers
        const incorrectAnswers = getRandomSubArrayFromArray(
          [
            [wholeA, denominatorA, numerator],
            [denominatorA, wholeA, numerator],
            [denominatorA, numerator, wholeA],
            [wholeB, numerator, denominatorA],
            [wholeC, numerator, denominatorA],
            [1, wholeC, numeratorC]
          ],
          3
        );

        return { wholeA, denominatorA, numerator, correctAnswer, incorrectAnswers };
      },
      ({ correctAnswer, incorrectAnswers }) => {
        return nestedArrayHasNoDuplicates([correctAnswer, ...incorrectAnswers]);
      }
    );

    return {
      wholeA,
      denominatorA,
      numerator,
      correctAnswer,
      incorrectAnswers
    };
  },
  Component: props => {
    const {
      question: { wholeA, denominatorA, numerator, incorrectAnswers },
      translate,
      displayMode
    } = props;

    const [incorrectA, incorrectB, incorrectC] = incorrectAnswers;

    const remainder1 = numerator % denominatorA;

    const numeratorColor =
      displayMode === 'digital'
        ? (getRandomFromArray(Object.values(barModelColors), {
            random: seededRandom(props.question)
          }) as string)
        : colors.pdfShading;

    const numeratorColorArray1 = filledArray(numeratorColor, denominatorA);
    const numeratorColorArray2 = filledArray(numeratorColor, remainder1);

    const remainder2 = filledArray('white', denominatorA - remainder1);
    const customColorMap = [[...numeratorColorArray1], [...numeratorColorArray2, ...remainder2]];

    // Statements
    const statements = shuffle(
      [
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac w='${wholeA.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}' />`}
            />
          ),
          value: 'A',
          correct: true
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac w='${incorrectA[0].toLocaleString()}' n='${incorrectA[1].toLocaleString()}' d='${incorrectA[2].toLocaleString()}' />`}
            />
          ),
          value: 'B',
          correct: false
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac w='${incorrectB[0].toLocaleString()}' n='${incorrectB[1].toLocaleString()}' d='${incorrectB[2].toLocaleString()}' />`}
            />
          ),
          value: 'C',
          correct: false
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac w='${incorrectC[0].toLocaleString()}' n='${incorrectC[1].toLocaleString()}' d='${incorrectC[2].toLocaleString()}' />`}
            />
          ),
          value: 'D',
          correct: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheImproperFractionShownByTheDiagram()}
        pdfTitle={translate.instructions.circleTheImproperFractionShownByTheDiagram()}
        testCorrect={correctAnswers.map(it => it.value)}
        mainPanelContainer={{ justifyContent: 'space-around' }}
        numItems={4}
        Content={({ dimens }) => (
          <View
            style={{ display: 'flex', flexDirection: 'row', columnGap: 8, width: dimens.width }}
          >
            {countRange(wholeA).map(idx => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominatorA}
                width={remainder1 > 0 ? dimens.width / (wholeA + 1) - 20 : dimens.width / wholeA}
                customColorMap={customColorMap[0]}
                height={displayMode !== 'digital' ? 100 : 80}
              />
            ))}
            {remainder1 > 0 && (
              <ShadedFractionBarModel
                totalSubSections={denominatorA}
                width={dimens.width / (wholeA + 1) - 20}
                customColorMap={customColorMap[1]}
                height={displayMode !== 'digital' ? 100 : 80}
              />
            )}
          </View>
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: sentence
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4v2 = newQuestionContent({
  uid: 'aMD2',
  description: 'aMD',
  keywords: ['Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z
    .object({
      wholeA: z.number().int().min(1).max(5),
      denominatorA: z.number().int().min(2).max(6),
      numerator: z.number().int().min(1).max(5),
      correctAnswer: z.array(z.number()),
      incorrectAnswers: z.array(z.array(z.number())).length(3)
    })
    .refine(
      val => val.numerator !== val.denominatorA,
      'numerator must not be equal to denominatorA'
    ),
  simpleGenerator: () => {
    const { wholeA, denominatorA, numerator, correctAnswer, incorrectAnswers } = rejectionSample(
      () => {
        const wholeA = randomIntegerInclusive(1, 5);
        const denominatorA = randomIntegerInclusive(2, 6);
        const numerator = randomIntegerInclusive(1, denominatorA - 1);

        const wholeB = wholeA + 1;
        const wholeC = denominatorA * (wholeA + 1);
        const numeratorC = denominatorA * wholeA + numerator;

        // Correct
        const correctAnswer = mixedNumberToImproperFraction(wholeA, numerator, denominatorA);

        // Incorrect answers
        const incorrectAnswers = getRandomSubArrayFromArray(
          [
            mixedNumberToImproperFraction(wholeA, denominatorA, numerator),
            mixedNumberToImproperFraction(denominatorA, wholeA, numerator),
            mixedNumberToImproperFraction(denominatorA, numerator, wholeA),
            mixedNumberToImproperFraction(wholeB, numerator, denominatorA),
            mixedNumberToImproperFraction(wholeC, numerator, denominatorA),
            mixedNumberToImproperFraction(1, wholeC, numeratorC)
          ],
          3
        );

        return { wholeA, denominatorA, numerator, correctAnswer, incorrectAnswers };
      },
      ({ correctAnswer, incorrectAnswers }) => {
        return nestedArrayHasNoDuplicates([correctAnswer, ...incorrectAnswers]);
      }
    );

    return {
      wholeA,
      denominatorA,
      numerator,
      correctAnswer,
      incorrectAnswers
    };
  },
  Component: props => {
    const {
      question: { wholeA, denominatorA, numerator, correctAnswer, incorrectAnswers },
      translate,
      displayMode
    } = props;

    const [incorrectA, incorrectB, incorrectC] = incorrectAnswers;

    const remainder1 = numerator % denominatorA;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, denominatorA);
    const numeratorColorArray2 = filledArray(numeratorColor, remainder1);

    const remainder2 = filledArray('white', denominatorA - remainder1);
    const customColorMap = [[...numeratorColorArray1], [...numeratorColorArray2, ...remainder2]];

    // Statements
    const statements = shuffle(
      [
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac n='${correctAnswer[0].toLocaleString()}' d='${correctAnswer[1].toLocaleString()}' />`}
            />
          ),
          value: 'A',
          correct: true
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac n='${incorrectA[0].toLocaleString()}' d='${incorrectA[1].toLocaleString()}' />`}
            />
          ),
          value: 'B',
          correct: false
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac n='${incorrectB[0].toLocaleString()}' d='${incorrectB[1].toLocaleString()}' />`}
            />
          ),
          value: 'C',
          correct: false
        },
        {
          sentence: (
            <TextStructure
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontWeight: '700' }}
              textVariant="WRN700"
              sentence={`<frac n='${incorrectC[0].toLocaleString()}' d='${incorrectC[1].toLocaleString()}' />`}
            />
          ),
          value: 'D',
          correct: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheImproperFractionShownByTheDiagram()}
        pdfTitle={translate.instructions.circleTheImproperFractionShownByTheDiagram()}
        testCorrect={correctAnswers.map(it => it.value)}
        mainPanelContainer={{ justifyContent: 'space-around' }}
        numItems={4}
        Content={({ dimens }) => (
          <View
            style={{ display: 'flex', flexDirection: 'row', columnGap: 8, width: dimens.width }}
          >
            {countRange(wholeA).map(idx => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominatorA}
                width={remainder1 > 0 ? dimens.width / (wholeA + 1) - 20 : dimens.width / wholeA}
                customColorMap={customColorMap[0]}
                height={displayMode !== 'digital' ? 100 : 80}
              />
            ))}
            {remainder1 > 0 && (
              <ShadedFractionBarModel
                totalSubSections={denominatorA}
                width={dimens.width / (wholeA + 1) - 20}
                customColorMap={customColorMap[1]}
                height={displayMode !== 'digital' ? 100 : 80}
              />
            )}
          </View>
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: sentence
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aME',
  description: 'aME',
  keywords: ['Bar model', 'Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(6),
    denominatorB: z.number().int().min(2).max(6),
    denominatorC: z.number().int().min(2).max(6),
    numeratorA: z.number().int().min(3).max(23),
    numeratorB: z.number().int().min(3).max(23),
    numeratorC: z.number().int().min(3).max(23)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 6);
    const denominatorB = randomIntegerInclusive(2, 6);
    const denominatorC = randomIntegerInclusive(2, 6);

    const numeratorA = randomIntegerInclusive(denominatorA + 1, denominatorA * 4 - 1);
    const numeratorB = randomIntegerInclusive(
      denominatorB + 1,
      denominatorB * 4 - 1,
      denominatorA === denominatorB ? { constraint: x => x !== numeratorA } : undefined
    );
    const numeratorC = randomIntegerInclusive(
      denominatorC + 1,
      denominatorC * 4 - 1,
      denominatorA === denominatorC
        ? { constraint: x => x !== numeratorA }
        : denominatorB === denominatorC
        ? { constraint: x => x !== numeratorB }
        : undefined
    );

    return {
      denominatorA,
      denominatorB,
      denominatorC,
      numeratorA,
      numeratorB,
      numeratorC
    };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC },
      translate,
      displayMode
    } = props;

    const [color1, color2, color3] = getRandomSubArrayFromArray(Object.values(barModelColors), 3, {
      random: seededRandom({ denominatorA, denominatorB, denominatorC })
    });

    /* Wholes */
    const wholeAmount1 = Math.floor(numeratorA / denominatorA);
    const wholeAmount2 = Math.floor(numeratorB / denominatorB);
    const wholeAmount3 = Math.floor(numeratorC / denominatorC);

    /* Remainders */
    const remainder1 = numeratorA % denominatorA;
    const remainder2 = numeratorB % denominatorB;
    const remainder3 = numeratorC % denominatorC;

    const customColorMaps = (numerator: number, denominator: number, baseColor: string) => {
      const remainder = numerator % denominator;
      const remainderPadding = filledArray('white', denominator - remainder);
      const numeratorColorArrayA = filledArray(baseColor, denominator);
      const numeratorColorArrayB = filledArray(baseColor, remainder);

      return [[...numeratorColorArrayA], [...numeratorColorArrayB, ...remainderPadding]];
    };

    const customColorMap1 = customColorMaps(numeratorA, denominatorA, color1);
    const customColorMap2 = customColorMaps(numeratorB, denominatorB, color2);
    const customColorMap3 = customColorMaps(numeratorC, denominatorC, color3);

    const items = shuffle(
      [
        {
          component: (
            <TextStructure
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' />`}
            />
          ),
          value: 'A'
        },
        {
          component: (
            <TextStructure
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              sentence={`<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`}
            />
          ),
          value: 'B'
        },
        {
          component: (
            <TextStructure
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              sentence={`<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' />`}
            />
          ),
          value: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.dragCardsToMatchImproperFractionsToBarModels()}
        pdfTitle={translate.instructions.useBarModelsToCompleteImproperFractions()}
        items={items}
        pdfLayout="itemsBottom"
        actionPanelVariant="end"
        mainPanelStyle={{ flexDirection: 'row' }}
        sentencesStyle={{ marginTop: 0, minWidth: displayMode === 'digital' ? 0 : 250 }}
        Content={({ dimens }) => (
          <View
            style={{ justifyContent: 'space-around', height: dimens.height, width: dimens.width }}
          >
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 8, paddingRight: 20 }}>
              {/* First row */}
              {countRange(wholeAmount1).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominatorA}
                  width={
                    remainder1 > 0
                      ? (dimens.width / (wholeAmount1 + 1)) * 0.9
                      : (dimens.width / wholeAmount1) * 0.9
                  }
                  height={dimens.height / 8}
                  customColorMap={customColorMap1[0]}
                />
              ))}
              {remainder1 > 0 && (
                <ShadedFractionBarModel
                  totalSubSections={denominatorA}
                  width={(dimens.width / (wholeAmount1 + 1)) * 0.9}
                  height={dimens.height / 8}
                  customColorMap={customColorMap1[1]}
                />
              )}
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 8, paddingRight: 20 }}>
              {/* Second row */}
              {countRange(wholeAmount2).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominatorB}
                  width={
                    remainder2 > 0
                      ? (dimens.width / (wholeAmount2 + 1)) * 0.9
                      : (dimens.width / wholeAmount2) * 0.9
                  }
                  height={dimens.height / 8}
                  customColorMap={customColorMap2[0]}
                />
              ))}
              {remainder2 > 0 && (
                <ShadedFractionBarModel
                  totalSubSections={denominatorB}
                  width={(dimens.width / (wholeAmount2 + 1)) * 0.9}
                  height={dimens.height / 8}
                  customColorMap={customColorMap2[1]}
                />
              )}
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 8, paddingRight: 20 }}>
              {/* Third row */}
              {countRange(wholeAmount3).map(idx => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominatorC}
                  width={
                    remainder3 > 0
                      ? (dimens.width / (wholeAmount3 + 1)) * 0.9
                      : (dimens.width / wholeAmount3) * 0.9
                  }
                  height={dimens.height / 8}
                  customColorMap={customColorMap3[0]}
                />
              ))}
              {remainder3 > 0 && (
                <ShadedFractionBarModel
                  totalSubSections={denominatorC}
                  width={(dimens.width / (wholeAmount3 + 1)) * 0.9}
                  height={dimens.height / 8}
                  customColorMap={customColorMap3[1]}
                />
              )}
            </View>
          </View>
        )}
        sentences={['<ans />', '<ans />', '<ans />']}
        testCorrect={[['A'], ['B'], ['C']]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aMF',
  description: 'aMF',
  keywords: ['Bar model', 'Improper fraction', 'Parts', 'Wholes', 'Numerators', 'Denominators'],
  schema: z.object({
    denominator: z.number().int().min(2).max(8),
    numeratorA: z.number().int().min(1).max(8),
    extraBarModels: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const numeratorA = randomIntegerInclusive(1, denominator);
    const extraBarModels = randomIntegerInclusive(1, 3);

    return { denominator, numeratorA, extraBarModels };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, extraBarModels },
      translate,
      displayMode
    } = props;

    const numeratorB = denominator * extraBarModels + numeratorA;

    const wholeAmount = Math.floor(numeratorB / denominator);

    const remainder = numeratorB % denominator;

    const answerA: number[] = [];
    let answerB = 0;

    return (
      <QF3Content
        title={translate.instructions.shadeBarModelsToRepresentFracXNumeratorYDenominator(
          numeratorB,
          denominator
        )}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              height: (remainder > 0 ? (wholeAmount + 1) * 80 : wholeAmount * 80) + 40
            }}
          >
            {countRange(wholeAmount).map(idx => (
              <ShadedFractionBarModelWithState
                id={`barmodel-${idx}`}
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={80}
                defaultState={displayMode === 'markscheme' ? range(0, denominator - 1) : []}
                testCorrect={userAnswer => {
                  answerA[idx] = userAnswer.length;
                  const total = sumNumberArray(answerA) + answerB;
                  return total === numeratorB;
                }}
              />
            ))}
            {remainder > 0 && (
              <ShadedFractionBarModelWithState
                id={`barmodel-remainder`}
                totalSubSections={denominator}
                width={dimens.width}
                height={80}
                defaultState={displayMode === 'markscheme' ? range(0, remainder - 1) : []}
                testCorrect={userAnswer => {
                  answerB = userAnswer.length;
                  const total = sumNumberArray(answerA) + answerB;
                  return total === numeratorB;
                }}
              />
            )}
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandImproperFractions',
  questionTypes: [Question1v2, Question2, Question3v2, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question3, Question4]
});
export default SmallStep;
