import { View, type StyleProp, type ViewStyle, type TextStyle, StyleSheet } from 'react-native';
import Text from '../../typography/Text';
import { colors } from '../../../theme/colors';
import { Fragment, useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { useMinimumSafeWidth } from '../../../theme/scaling';

type Props = {
  topHeaders: string[];
  leftHeaders: string[];
  /**
   * What to display in the non-header cells.
   * Outer array chooses a row, inner array chooses a column.
   */
  items: string[][];
  /** Whether the final row has a double border. Default: false. */
  doubleBorderOnFinalRow?: boolean;
  /** Default: 1. */
  borderWidth?: number;
  /** Additional styles for the table itself. Use this to give the table a width and height. */
  style?: StyleProp<ViewStyle>;
  /** Additional styles for all the columns. */
  columnStyle?: StyleProp<ViewStyle>;
  /** Additional styles for all the cells. You can use this to provide max cell widths/heights. */
  cellStyle?: StyleProp<ViewStyle>;
  /** Additional styles for only header cells. You can use this to change the background color. */
  headerCellStyle?: StyleProp<ViewStyle>;
  /** Additional styles for all the text. */
  textStyle?: StyleProp<TextStyle>;
  /** Additional styles for just the headers. */
  headerTextStyle?: StyleProp<TextStyle>;
};

/**
 * Simple table with headers on the left _and_ the top.
 *
 * Styling:
 * - All cells are one line of text
 * - All rows have the same height:
 *    - there is a fixed minimum height, but they share the table's height evenly if this is larger
 * - The columns may have different widths:
 *    - The left header column will fit its contents
 *    - The remaining columns will fit their contents, but also share any leftover table width.
 * - The table's width can be given via the style prop (e.g. via width or alignSelf: 'stretch')
 *
 * Limitations: not interactive, only holds strings.
 */
export default function TwoWayTable({
  topHeaders,
  leftHeaders,
  items,
  borderWidth = 1,
  doubleBorderOnFinalRow = false,
  style,
  columnStyle,
  cellStyle,
  headerCellStyle,
  textStyle,
  headerTextStyle
}: Props) {
  const styles = useStyles(borderWidth);

  return (
    <View style={[styles.table, style]}>
      {/* Left headers column */}
      <View style={[styles.column, columnStyle]}>
        {/* Empty cell - filled with one character of invisible text to give it same height as other header cells. */}
        <View style={[styles.cell, cellStyle]}>
          <Text
            style={[styles.text, styles.headerText, textStyle, headerTextStyle]}
            variant="WRN400"
            numberOfLines={1}
          >
            {'\u200b'}
          </Text>
        </View>

        {/* Left headers */}
        {leftHeaders.map((header, rowIndex) => (
          <Fragment key={rowIndex}>
            {rowIndex === leftHeaders.length - 1 && doubleBorderOnFinalRow && (
              <View style={[styles.cell, styles.border, styles.doubleBorderCell]} />
            )}
            <View
              style={[
                styles.cell,
                styles.border,
                styles.headerCell,
                styles.overlapTopBorder,
                cellStyle,
                headerCellStyle
              ]}
            >
              <Text
                style={[styles.text, styles.headerText, textStyle, headerTextStyle]}
                variant="WRN400"
                numberOfLines={1}
              >
                {header}
              </Text>
            </View>
          </Fragment>
        ))}
      </View>

      {/* Remaining columns */}
      {topHeaders.map((header, columnIndex) => (
        <View key={columnIndex} style={[styles.column, columnStyle]}>
          {/* Header cell */}
          <View
            style={[
              styles.cell,
              styles.border,
              styles.headerCell,
              styles.overlapLeftBorder,
              cellStyle,
              headerCellStyle
            ]}
          >
            <Text
              style={[styles.text, styles.headerText, textStyle, headerTextStyle]}
              variant="WRN400"
              numberOfLines={1}
            >
              {header}
            </Text>
          </View>

          {/* Items */}
          {leftHeaders.map((_, rowIndex) => (
            <Fragment key={rowIndex}>
              {rowIndex === leftHeaders.length - 1 && doubleBorderOnFinalRow && (
                <View style={[styles.cell, styles.border, styles.doubleBorderCell]} />
              )}
              <View
                style={[
                  styles.cell,
                  styles.border,
                  styles.overlapTopBorder,
                  styles.overlapLeftBorder,
                  cellStyle
                ]}
              >
                <Text style={[styles.text, textStyle]} numberOfLines={1}>
                  {items[rowIndex][columnIndex]}
                </Text>
              </View>
            </Fragment>
          ))}
        </View>
      ))}
    </View>
  );
}

function useStyles(borderWidth: number) {
  const displayMode = useContext(DisplayMode);
  // We tweak the styles slightly when displayed in a PDF
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  // Make sure the border isn't less than a pixel wide on native.
  borderWidth = useMinimumSafeWidth(borderWidth);

  return useMemo(
    () =>
      StyleSheet.create({
        table: { flexDirection: 'row', justifyContent: 'flex-start' },
        column: {
          flexGrow: 1
        },
        cell: {
          minHeight: isPdf ? 60 : 39,
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 10,
          borderWidth: isPdf ? borderWidth * 2 : borderWidth,
          borderColor: 'transparent'
        },
        border: {
          borderColor: isPdf ? colors.black : colors.prussianBlue
        },
        headerCell: {
          backgroundColor: isPdf ? colors.greys200 : colors.pacificBlue
        },
        text: {
          fontSize: 32,
          lineHeight: 48
        },
        headerText: { color: isPdf ? colors.black : 'white' },
        overlapTopBorder: { marginTop: -borderWidth },
        overlapLeftBorder: { marginLeft: -borderWidth },
        doubleBorderCell: { minHeight: 0, height: 0, flex: 0 }
      }),
    [borderWidth, isPdf]
  );
}
