import deepmerge from 'deepmerge';
import en from '../en';
import type { Translation } from '../i18n-types';

/**
 * Spanish.
 *
 * For now, we fall back to English (via deepmerge) for where there is no Spanish translation available.
 *
 * In the future we probably instead want a Spanish translation to be available for all translatable strings. We
 * should remove the deepmerge, and add translations for everything that typescript warns us are missing.
 */
const es: Translation = deepmerge(en as Translation, {
  years: {
    Year3: 'Annos 3'
  },

  smallSteps: {
    RepresentNumbersTo1000: 'Representa números hasta el 1000'
  },

  descriptions: {
    aay: 'Encuentra el número de objetos 100s 10s 1s, <1.000',
    aaA: 'Describe un número de base 10 en términos de centenas, decenas y unidades, <1.000',
    aaB: 'Identifique la representación correcta en base 10, marcador de posición, <1.000',
    aaD: 'Encuentra el número representado en base 10, <1.000'
  },

  keywords: {
    'Base 10': 'Base 10',
    Hundred: 'Ciento',
    Hundreds: 'Cientos',
    Object: 'Objeto',
    'Place value': 'Valor posicional',
    Represent: 'Representar',
    Thousand: 'Mil',
    '1,000': '1.000'
  },

  enums: {
    Base10RepresentationVariant: {
      Balloons: 'Globos',
      BluePens: 'Bolígrafos azules',
      Bricks: 'Ladrillos',
      Cubes: 'Cubos',
      Lollipops: 'Piruletas',
      Marbles: 'Canicas',
      Nails: 'Clavos',
      RedPens: 'Bolígrafos rojos',
      Sweets: 'Dulces',
      Straws: 'Pajitas'
    }
  },

  answerSentences: {
    hundredsTensOnesAns:
      'Hay <ans/> centenas, <ans/> decenas y <ans/> unas.<br/>El numero es <ans/>'
  },

  instructions: {
    completeSentence: 'Completa la oración.',
    whatNumberIsShown: '¿Qué número se muestra?',
    howManyObjectsAreThere: '¿Cuántas %{variant} hay?',

    whichBase10PicRepresentsNum: '¿Qué imagen de base 10 representa {0}?'
  }
});

export default es;
