import { type RootStackProps } from '../navigation/types';
import { useEffect } from 'react';
import useQuizSessionStore, { type QuestionInfo } from '../storage/useQuizSessionStore';
import { parseOfflineQueryParams } from '../network/qrCodes';

/**
 * This is not really a screen! It's an invisible modal, which handles importing quizzes via deep linking (e.g.)
 * scanning a QR code using the device's camera app.
 * This screen is started with the query parameters of that deep link, and this screen invisibly launches the quiz
 * after parsing that data.
 * TODO: should we show something (e.g. a loading spinner) in the case when we're waiting for a network request?
 */
export default function ImportQuizScreen({
  navigation,
  route: { params }
}: RootStackProps<'ImportQuiz'>) {
  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);

  // Put all the code inside a useEffect, which runs exactly once. (Hence the eslint disable at the end.)
  useEffect(() => {
    if (params === undefined) {
      // This shouldn't be possible, but maybe they scanned a dodgy QR code.
      // TODO: provide feedback to the user?
      navigation.goBack();
      return;
    }

    const offlineParams = parseOfflineQueryParams(params);
    if (typeof offlineParams !== 'string') {
      const questions = offlineParams.tokens.map((token, index) => {
        const questionInfo: QuestionInfo = {
          id: 'questions/1234',
          uid: typeof token === 'string' ? token : token[0],
          parameters: typeof token === 'string' ? undefined : JSON.stringify(token[1]),
          displayOrder: index + 1
        };

        return questionInfo;
      });

      // The QR code was an offline QR code. Navigate to the Quiz screen.
      setQuizSession({
        id: 'import',
        name: offlineParams.quizName,
        randomiseQuestionParameters: questions.every(
          //If imported quiz does not have parameters it will be randomised
          questionInfo => questionInfo.parameters === undefined
        ),
        questions: questions,
        quizSounds: true,
        retryInfo: { type: 'legacy' }
      });

      navigation.replace('Quiz');
      return;
    }

    // TODO: Maybe add code for the new "online" QR codes, if they support deep links.

    console.warn(`Failed to parse deep link: ${JSON.stringify(params)}, ${offlineParams}`);
    // TODO: provide feedback to the user?
    navigation.goBack();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
}
