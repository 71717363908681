import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import { arrayHasNoDuplicates, filledArray, range } from 'common/src/utils/collections';
import { numberEnum } from 'common/src/utils/zod';
import { multiplesNumberTrackArray } from 'common/src/utils/multiples';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { MULT, DIV } from 'common/src/constants';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { objectSchema, getRandomObject } from 'common/src/utils/objects';
import { getObjectImage } from 'common/src/utils/objectsImages';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alh',
  description: 'alh',
  keywords: ['Times-table', '6', 'Object', 'Multiply'],
  schema: z.object({
    number: z.number().int().min(2).max(6),
    numberIsGroups: z.boolean(),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 6);

    const numberIsGroups = getRandomBoolean();

    const object = getRandomObject();

    return { number, numberIsGroups, object };
  },
  Component: props => {
    const {
      question: { number, numberIsGroups, object },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.completeMultiplication()}
        testCorrect={answer =>
          ((answer[0] === '6' && answer[1] === number.toString()) ||
            (answer[0] === number.toString() && answer[1] === '6')) &&
          answer[2] === (number * 6).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                flexWrap: 'wrap'
              }
            ]}
          >
            {numberIsGroups
              ? range(1, 6).map(index => (
                  <View key={index}>
                    {getObjectImage(object, number, dimens.height / 2.1, dimens.width / 3.1)}
                  </View>
                ))
              : range(1, number).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 6, dimens.height / 2.1, dimens.width / 3.1)}
                  </View>
                ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number.toLocaleString(),
            (6).toLocaleString(),
            (number * 6).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ali',
  description: 'ali',
  keywords: ['Times-table', '6', 'Track'],
  schema: z.object({
    interval: numberEnum([-6, 6]),
    startingNumber: z.number().int().min(6).max(72).multipleOf(6),
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-6, 6] as const);
    const startingNumber =
      interval === 6 ? randomIntegerInclusiveStep(6, 18, 6) : randomIntegerInclusiveStep(60, 72, 6);
    const tileToShow = randomIntegerInclusive(2, 9);
    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      8,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alj',
  description: 'alj',
  keywords: ['Times-table', '6', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columns: z.number().int().min(2).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 5);

    return { columns };
  },
  Component: props => {
    const {
      question: { columns },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        testCorrect={answer =>
          ((answer[0][0] === columns.toString() &&
            answer[0][1] === '6' &&
            answer[1][0] === '6' &&
            answer[1][1] === columns.toString()) ||
            (answer[0][0] === '6' &&
              answer[0][1] === columns.toString() &&
              answer[1][0] === columns.toString() &&
              answer[1][1] === '6')) &&
          answer[0][2] === (6 * columns).toString() &&
          answer[1][2] === (6 * columns).toString() &&
          ((answer[2][1] === columns.toString() &&
            answer[2][2] === '6' &&
            answer[3][1] === '6' &&
            answer[3][2] === columns.toString()) ||
            (answer[2][1] === '6' &&
              answer[2][2] === columns.toString() &&
              answer[3][1] === columns.toString() &&
              answer[3][2] === '6')) &&
          answer[2][0] === (6 * columns).toString() &&
          answer[3][0] === (6 * columns).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <ArrayOfObjects rows={6} columns={columns} dimens={dimens} />}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [columns.toLocaleString(), (6).toLocaleString(), (6 * columns).toLocaleString()],
            [(6).toLocaleString(), columns.toLocaleString(), (6 * columns).toLocaleString()],
            [(6 * columns).toLocaleString(), columns.toLocaleString(), (6).toLocaleString()],
            [(6 * columns).toLocaleString(), (6).toLocaleString(), columns.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alk',
  description: 'alk',
  keywords: ['Times-table', '6', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy6', 'fillBottomRowWith6'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy6',
      'fillBottomRowWith6'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, displayMode }) => {
    const multiplier = 6;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith6':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy6':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        maxFontSize={displayMode === 'digital' ? 40 : 50}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'all',
  description: 'all',
  keywords: ['Times-table', '6', 'Fact family', 'Inverse', 'Multiply', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 6, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 6, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ result: numberC1, right: 6, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ result: numberD1, right: 6, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'alm',
  description: 'alm',
  keywords: ['Multiple', '6'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(72)
      .array()
      .length(8)
      .refine(numbers => numbers.filter(x => x % 6 === 0).length >= 3, 'At least 3 multiples of 6')
  }),
  simpleGenerator: () => {
    // 1 to 36 range
    const multiple1 = randomUniqueIntegersInclusive(1, 36, 2, {
      constraint: x => x % 6 === 0
    });
    const random1 = randomUniqueIntegersInclusive(1, 36, 3, {
      constraint: x => !multiple1.includes(x)
    });

    // 37 to 72 range
    const multiple2 = randomIntegerInclusive(37, 72, {
      constraint: x => x % 6 === 0
    });
    const notMultiple = randomIntegerInclusive(37, 72, {
      constraint: x => x % 6 !== 0
    });
    const random2 = randomIntegerInclusive(37, 72, {
      constraint: x => x !== multiple2 && x !== notMultiple
    });

    const numbers = [...multiple1, ...random1, multiple2, notMultiple, random2];

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const shuffledNumbers = shuffle(numbers, {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(6)}
        pdfTitle={translate.instructions.circleTheMultiplesOfX(6)}
        testCorrect={shuffledNumbers.filter(it => it % 6 === 0)}
        multiSelect
        items={() =>
          shuffledNumbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num6TimesTableAndDivisionFacts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
