import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { CompleteTheSentencesWithState } from 'common/src/components/molecules/CompleteTheSentences';
import { useCallback, useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { parseMarkup } from '../../../markup';
import { SYMBOLS, parseSymbolsToString } from '../../../utils/parse';
import { addWhitespacePadding } from '../../../utils/parse';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { DisplayMode } from '../../../contexts/displayMode';

type UserAnswer = string[];

type Props = TitleStyleProps & {
  title: string | JSX.Element;
  pdfTitle?: string | JSX.Element;
  /** Sentence to complete. */
  sentences: string[];
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  mainPanelContainerStyle?: StyleProp<ViewStyle>;
  pdfMainPanelContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  pdfContainerStyle?: StyleProp<ViewStyle>;
  /** By default, this is an array of empty strings '' matching the number of <ans/> in the sentences. */
  initialState?: UserAnswer[];
  /** Defaults to checking that all user answer strings are non-empty. */
  testComplete?: (userAnswer: UserAnswer[]) => boolean;
  /** Either an array of the correct answers for each sentence, or a function for more complex use cases. */
  testCorrect: UserAnswer[] | ((userAnswer: UserAnswer[]) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  extraSymbol?: ExtraSymbols;
  /** Props for extra content modal */
  promptButton?: string | JSX.Element;
  modalTitle?: string;
  modalContent?: JSX.Element;
  /**
   * Direction for this question's PDF content to be displayed.
   * Optional prop, defaults to row.
   */
  pdfDirection?: 'row' | 'column';
  /** Whitespace props */
  whitespacePadding?: boolean;
  numberOfSpaces?: number;
  actionPanelVariant?: 'bottomTall' | 'endWide';
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[][]; answerText?: string };
};

/**
 * Layout containing a title, numpad and sentences in the remaining space evenly spaced.
 */
export default function QF2AnswerBoxManySentences({
  title,
  pdfTitle,
  sentences,
  sentenceStyle,
  containerStyle,
  mainPanelContainerStyle,
  pdfMainPanelContainerStyle,
  textStyle,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  pdfContainerStyle,
  inputStyle,
  initialState,
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  inputMaxCharacters: inputMaxCharactersProp,
  questionHeight,
  customMarkSchemeAnswer,
  extraSymbol,
  promptButton,
  modalTitle,
  modalContent,
  actionPanelVariant = 'endWide',
  pdfDirection = 'column',
  whitespacePadding = SYMBOLS.some(symbol => sentences.some(sentence => sentence.includes(symbol))),
  numberOfSpaces = 2,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(pdfDirection, displayMode, modalContent);

  // Add whitespace around all symbols
  if (whitespacePadding) {
    sentences = sentences.map(sentence => addWhitespacePadding(sentence, numberOfSpaces));
  }

  // Default initialState
  const numberOfAnsArray = useMemo(
    () => sentences.map(parseMarkup).map(it => it.numberOfAns),
    [sentences]
  );
  initialState = initialState ?? numberOfAnsArray.map(numberOfAns => filledArray('', numberOfAns));

  // Default testComplete
  const testComplete = useCallback(
    (userAnswer: UserAnswer[]) => {
      if (testCompleteProp !== undefined) {
        return testCompleteProp(userAnswer);
      } else {
        return userAnswer.every(sentenceAnswer => sentenceAnswer.every(it => it !== ''));
      }
    },
    [testCompleteProp]
  );

  // Handle testCorrect
  const testCorrect = useCallback(
    (userAnswer: UserAnswer[]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp, arraysHaveSameContents);
      }
    },
    [testCorrectProp]
  );

  // Try to make the input boxes a suitable width.
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(
        ...testCorrectProp.map(sentence => Math.max(...sentence.map(it => it.length)))
      );
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={pdfMainPanelContainerStyle}
        mainPanelContents={
          <View style={[styles.container, pdfMainPanelContainerStyle]}>
            <CompleteTheSentencesWithState
              id="sentences"
              sentences={sentences}
              autoFocus
              inputMaxCharacters={inputMaxCharacters}
              inputStyle={inputStyle}
              sentenceStyle={sentenceStyle}
              style={[styles.sentences, pdfContainerStyle]}
              textStyle={textStyle}
              fractionContainerStyle={fractionContainerStyle}
              fractionDividerStyle={fractionDividerStyle}
              fractionTextStyle={fractionTextStyle}
            />
          </View>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={pdfMainPanelContainerStyle}
        mainPanelContents={
          <>
            <View style={[styles.container, pdfMainPanelContainerStyle]}>
              <CompleteTheSentencesWithState
                id="sentences"
                sentences={sentences}
                autoFocus
                inputMaxCharacters={inputMaxCharacters}
                inputStyle={inputStyle}
                sentenceStyle={sentenceStyle}
                style={[styles.sentences, pdfContainerStyle]}
                textStyle={textStyle}
                fractionContainerStyle={fractionContainerStyle}
                fractionDividerStyle={fractionDividerStyle}
                fractionTextStyle={fractionTextStyle}
                defaultState={
                  typeof testCorrectProp === 'function'
                    ? customMarkSchemeAnswer?.answersToDisplay
                    : testCorrectProp.map(ansLine =>
                        ansLine.map(ans => {
                          // Temporary variable to convert simple string to localized string
                          const temp = Number(parseSymbolsToString(ans));
                          return temp.toLocaleString();
                        })
                      )
                }
              />
            </View>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput
          variant={actionPanelVariant === 'bottomTall' ? 'wide' : 'tall'}
          inputType="numpad"
          extraSymbol={extraSymbol}
        />
      }
      mainPanelContainerStyle={[styles.container, mainPanelContainerStyle]}
      mainPanelContents={
        <CompleteTheSentencesWithState
          id="sentences"
          defaultState={initialState}
          testCorrect={testCorrect}
          testComplete={testComplete}
          sentences={sentences}
          autoFocus
          inputMaxCharacters={inputMaxCharacters}
          inputStyle={inputStyle}
          sentenceStyle={sentenceStyle}
          style={[styles.sentences, containerStyle]}
          textStyle={[styles.text, textStyle]}
          fractionContainerStyle={fractionContainerStyle}
          fractionDividerStyle={fractionDividerStyle}
          fractionTextStyle={fractionTextStyle}
        />
      }
      promptButton={promptButton}
      modalTitle={modalTitle}
      modalContent={modalContent}
      {...props}
    />
  );
}

const useStyles = (
  pdfDirection: 'row' | 'column',
  displayMode: 'digital' | 'pdf' | 'markscheme',
  modalContent?: JSX.Element
) => {
  return useMemo(
    () =>
      StyleSheet.create({
        sentences: {
          alignItems: modalContent ? 'center' : 'flex-end',
          alignSelf: 'center',
          rowGap: 32,
          flexDirection: displayMode !== 'digital' ? pdfDirection : 'column',
          alignContent: 'center',
          justifyContent: 'space-evenly'
        },
        container: {
          alignSelf: 'center',
          flex: 1
        },
        text: {
          fontSize: 40
        }
      }),
    [pdfDirection, displayMode, modalContent]
  );
};
