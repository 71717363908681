import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';

// Events
export const eventNames = [
  'Sunrise',
  'Wake up',
  'Walk to school',
  'Eat breakfast',
  'Breakfast club',
  'Lunch',
  'After-school club',
  'Football match',
  'Gymnastics practice',
  'Alarm goes off',
  'Go to school',
  'School play rehearsal',
  'Go home from school',
  'Walk home from school'
] as const;

export type EventNames = (typeof eventNames)[number];

export const events: Record<EventNames, { timeOfDay: ('morning' | 'afternoon')[] }> = {
  Sunrise: { timeOfDay: ['morning'] },
  'Wake up': { timeOfDay: ['morning'] },
  'Walk to school': { timeOfDay: ['morning'] },
  'Eat breakfast': { timeOfDay: ['morning'] },
  'Breakfast club': { timeOfDay: ['morning'] },
  Lunch: { timeOfDay: ['afternoon'] },
  'After-school club': { timeOfDay: ['afternoon'] },
  'Football match': { timeOfDay: ['morning', 'afternoon'] },
  'Gymnastics practice': { timeOfDay: ['morning', 'afternoon'] },
  'Alarm goes off': { timeOfDay: ['morning'] },
  'Go to school': { timeOfDay: ['morning'] },
  'School play rehearsal': { timeOfDay: ['morning', 'afternoon'] },
  'Go home from school': { timeOfDay: ['afternoon'] },
  'Walk home from school': { timeOfDay: ['afternoon'] }
};

export const eventSchema = z.enum(eventNames);

export function getRandomEvents(): EventNames {
  return getRandomFromArray(eventNames);
}

export function getRandomUniqueEvents(numberOfEvents: number): EventNames[] {
  return getRandomSubArrayFromArray(eventNames, numberOfEvents);
}

/**
 * DO NOT USE
 * @deprecated
 * These has been replaced with the code above.
 * These must remain in the codebase until we remove `at0` (archived).
 */
export const eventsNames = [
  { name: 'sunrise', timeOfDay: 'morning' },
  { name: 'wake up', timeOfDay: 'morning' },
  { name: 'walk to school', timeOfDay: 'morning' },
  { name: 'eat breakfast', timeOfDay: 'morning' },
  { name: 'breakfast club', timeOfDay: 'morning' },
  { name: 'do homework', timeOfDay: 'afternoon' },
  { name: 'lunch', timeOfDay: 'afternoon' },
  { name: 'after-school club', timeOfDay: 'afternoon' },
  { name: 'football match', timeOfDay: 'afternoon' },
  { name: 'dance practice', timeOfDay: 'afternoon' }
] as const;

/**
 * DO NOT USE
 * @deprecated
 * This has been replaced with the code above.
 * These must remain in the codebase until we remove `at0` (archived).
 */
export const eventsSchema = z.array(
  z.object({
    name: z.string(),
    timeOfDay: z.string()
  })
);

/**
 * DO NOT USE
 * @deprecated
 * These has been replaced with the code above.
 * These must remain in the codebase until we remove `at0` (archived).
 */
export function getRandomUniqueEventsOld(numberOfEvents: number) {
  return getRandomSubArrayFromArray([...eventsNames] as const, numberOfEvents);
}
