import { useContext, useMemo } from 'react';
import { type StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { CompleteTheSentenceWithState } from 'common/src/components/molecules/CompleteTheSentence';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { type TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import UserInput, { type ExtraSymbols } from 'common/src/components/molecules/UserInput';
import DragAndDropPlay from 'common/src/components/molecules/DragAndDropPlay';
import BaseLayoutPDF from 'common/src/components/molecules/BaseLayoutPDF';
import { isEqual } from 'common/src/utils/matchers';
import { colors } from 'common/src/theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /** Sentence to complete. This uses <ans/> to indicate answer boxes. See {@link CompleteTheSentence}. */
  sentence: string;
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  /** Can provide an array of correct answers, or a function for finer control. */
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  /**
   * Direction for this question's PDF content to be displayed.
   * Optional prop, defaults to row.
   */
  pdfDirection?: 'row' | 'column';
  extraSymbol?: ExtraSymbols;
  /** Props for extra content modal */
  promptButton?: string;
  modalTitle?: string;
  modalContent?: JSX.Element;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
  /** PDF Question Height */
  questionHeight?: number;
};

/**
 * Title at the top.
 * Draggable counter on the left and a dropzone on the right.
 * Question sentence at the bottom with a numpad endWide action panel.
 *
 * Note: The drag and drop has no state and is not testable.
 */
export default function QF30GroupCountersAndSentence({
  title,
  pdfTitle,
  sentence,
  inputStyle,
  sentenceStyle,
  textStyle,
  testCorrect: testCorrectProp,
  inputMaxCharacters: inputMaxCharactersProp,
  pdfDirection = 'column',
  extraSymbol,
  promptButton,
  modalTitle,
  modalContent,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(pdfDirection);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  // Try to make the input boxes a suitable width.
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={styles.pdfContainer}
        mainPanelContents={
          <>
            <View style={styles.mainPanel}>
              <View style={styles.workspacePdf}></View>
              <CompleteTheSentenceWithState
                id="sentence"
                sentence={sentence}
                inputMaxCharacters={inputMaxCharacters}
                inputStyle={inputStyle}
                style={[styles.sentence, styles.pdfSentence, sentenceStyle]}
                textStyle={textStyle}
                defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
              />
            </View>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <View style={styles.mainPanel}>
          <MeasureView>{dimens => <DragAndDropPlay dimens={dimens} />}</MeasureView>
          <CompleteTheSentenceWithState
            id="sentence"
            testCorrect={testCorrect}
            sentence={sentence}
            autoFocus
            inputMaxCharacters={inputMaxCharacters}
            inputStyle={inputStyle}
            style={[styles.sentence, sentenceStyle]}
            textStyle={textStyle}
          />
        </View>
      }
      promptButton={promptButton}
      modalTitle={modalTitle}
      modalContent={modalContent}
      {...props}
    />
  );
}

function useStyles(pdfDirection: 'row' | 'column') {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          flex: 1,
          justifyContent: 'space-evenly',
          flexDirection: displayMode !== 'digital' ? pdfDirection : 'column'
        },
        sentence: {
          marginTop: 24
        },
        pdfContainer: {
          flexDirection: 'row'
        },
        pdfSentence: {
          justifyContent: 'flex-start',
          alignContent: 'center',
          padingLeft: 16
        },
        workspacePdf: {
          flex: 1,
          borderColor: colors.black,
          borderRadius: 8,
          borderWidth: 6
        }
      }),
    [displayMode, pdfDirection]
  );
}
