import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  ADD,
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_W,
  ALGEBRAIC_X,
  ALGEBRAIC_Y,
  ALGEBRAIC_Z
} from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEy',
  description: 'aEy',
  keywords: ['Quadrilateral', 'Angles'],
  schema: z.object({
    shape: z.enum(['Trapezium3_interior_angles', 'Trapezium4_interior_angles']),
    acuteAngleSize: z
      .number()
      .int()
      .min(21)
      .max(59)
      .refine(val => val % 10 !== 0, 'acuteAngleSize cannot be a multiple of 10'),
    missingAngle: z.enum(['top left', 'bottom left', 'bottom right', 'top right']),
    missingAngleLabel: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Trapezium3_interior_angles',
      'Trapezium4_interior_angles'
    ] as const);

    const acuteAngleSize = randomIntegerInclusive(21, 59, {
      constraint: x => x % 10 !== 0
    });

    const missingAngle = getRandomFromArray([
      'top left',
      'bottom left',
      'bottom right',
      'top right'
    ] as const);

    const missingAngleLabel = getAlgebraicSymbol();

    return { shape, acuteAngleSize, missingAngle, missingAngleLabel };
  },
  Component: ({ question, translate }) => {
    const { shape, acuteAngleSize, missingAngle, missingAngleLabel } = question;

    const angles =
      shape === 'Trapezium3_interior_angles'
        ? [acuteAngleSize, 180 - acuteAngleSize, 180 - acuteAngleSize, acuteAngleSize]
        : [180 - acuteAngleSize, acuteAngleSize, acuteAngleSize, 180 - acuteAngleSize];

    const acuteLabel = translate.units.numberOfDegrees(acuteAngleSize);
    const obtuseLabel = translate.units.numberOfDegrees(180 - acuteAngleSize);

    const [labels, answer] = (() => {
      if (shape === 'Trapezium3_interior_angles') {
        switch (missingAngle) {
          case 'top left':
            return [[missingAngleLabel, obtuseLabel, obtuseLabel, acuteLabel], angles[0]];
          case 'bottom left':
            return [[acuteLabel, missingAngleLabel, obtuseLabel, acuteLabel], angles[1]];
          case 'bottom right':
            return [[acuteLabel, obtuseLabel, missingAngleLabel, acuteLabel], angles[2]];
          case 'top right':
            return [[acuteLabel, obtuseLabel, obtuseLabel, missingAngleLabel], angles[3]];
        }
      } else {
        switch (missingAngle) {
          case 'top left':
            return [[missingAngleLabel, acuteLabel, acuteLabel, obtuseLabel], angles[0]];
          case 'bottom left':
            return [[obtuseLabel, missingAngleLabel, acuteLabel, obtuseLabel], angles[1]];
          case 'bottom right':
            return [[obtuseLabel, acuteLabel, missingAngleLabel, obtuseLabel], angles[2]];
          case 'top right':
            return [[obtuseLabel, acuteLabel, acuteLabel, missingAngleLabel], angles[3]];
        }
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutSizeOfUnknownAngleInTrapezium()}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={labels} />
        )}
        sentence={translate.answerSentences.xEqualsAnsDegrees(missingAngleLabel)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aEz',
  description: 'aEz',
  keywords: ['Quadrilateral', 'Angles'],
  schema: z.object({
    shape: z.enum(['Rhombus1_interior_angles', 'Rhombus3_interior_angles']),
    acuteAngleSize: z
      .number()
      .int()
      .min(21)
      .max(59)
      .refine(val => val % 10 !== 0, 'acuteAngleSize cannot be a multiple of 10'),
    missingAngle: z.enum(['top', 'left', 'bottom', 'right']),
    missingAngleLabel: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Rhombus1_interior_angles',
      'Rhombus3_interior_angles'
    ] as const);

    const acuteAngleSize = randomIntegerInclusive(21, 59, {
      constraint: x => x % 10 !== 0
    });

    const missingAngle = getRandomFromArray(['top', 'left', 'bottom', 'right'] as const);

    const missingAngleLabel = getAlgebraicSymbol();

    return { shape, acuteAngleSize, missingAngle, missingAngleLabel };
  },
  Component: ({ question, translate }) => {
    const { shape, acuteAngleSize, missingAngle, missingAngleLabel } = question;

    const angles = [180 - acuteAngleSize, acuteAngleSize, 180 - acuteAngleSize, acuteAngleSize];

    const acuteLabel = translate.units.numberOfDegrees(acuteAngleSize);
    const obtuseLabel = translate.units.numberOfDegrees(180 - acuteAngleSize);

    const [labels, answer] = (() => {
      switch (missingAngle) {
        case 'top':
          return [[missingAngleLabel, acuteLabel, obtuseLabel, acuteLabel], angles[0]];
        case 'left':
          return [[obtuseLabel, missingAngleLabel, obtuseLabel, acuteLabel], angles[1]];
        case 'bottom':
          return [[obtuseLabel, acuteLabel, missingAngleLabel, acuteLabel], angles[2]];
        case 'right':
          return [[obtuseLabel, acuteLabel, obtuseLabel, missingAngleLabel], angles[3]];
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutSizeOfUnknownAngleInRhombus()}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={labels} />
        )}
        sentence={translate.answerSentences.xEqualsAnsDegrees(missingAngleLabel)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aEA',
  description: 'aEA',
  keywords: ['Quadrilateral', 'Irregular', 'Angles'],
  schema: z.object({
    topLeftAngleSize: z.number().int().min(10).max(135),
    bottomRightAngleSize: z.number().int().min(20).max(119),
    topRightAngleSize: z.number().int().min(70).max(149),
    missingAngle: z.enum(['topLeft', 'middle', 'bottomRight', 'topRight']),
    missingAngleLabel: algebraicSymbolSchema,
    shape: z.enum([
      'Quadrilateral1_interior_angles',
      'Quadrilateral2_interior_angles',
      'Quadrilateral3_interior_angles',
      'Quadrilateral4_interior_angles',
      'Quadrilateral5_interior_angles'
    ])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Quadrilateral1_interior_angles',
      'Quadrilateral2_interior_angles',
      'Quadrilateral3_interior_angles',
      'Quadrilateral4_interior_angles',
      'Quadrilateral5_interior_angles'
    ] as const);

    const [topLeftAngleSize, bottomRightAngleSize, topRightAngleSize] = (() => {
      switch (shape) {
        case 'Quadrilateral1_interior_angles':
          return [
            randomIntegerInclusive(30, 39, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(80, 89, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(115, 135, {
              constraint: x => x % 10 !== 0
            })
          ];
        case 'Quadrilateral2_interior_angles':
          return [
            randomIntegerInclusive(10, 19, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(20, 29, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(95, 115, {
              constraint: x => x % 10 !== 0
            })
          ];
        case 'Quadrilateral3_interior_angles':
          return [
            randomIntegerInclusive(115, 135, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(91, 99, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(70, 79, {
              constraint: x => x % 10 !== 0
            })
          ];
        case 'Quadrilateral4_interior_angles':
          return [
            randomIntegerInclusive(70, 79, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(80, 89, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(140, 149, {
              constraint: x => x % 10 !== 0
            })
          ];
        case 'Quadrilateral5_interior_angles':
          return [
            randomIntegerInclusive(60, 69, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(110, 119, {
              constraint: x => x % 10 !== 0
            }),
            randomIntegerInclusive(130, 139, {
              constraint: x => x % 10 !== 0
            })
          ];
      }
    })();

    const missingAngle = getRandomFromArray([
      'topLeft',
      'middle',
      'bottomRight',
      'topRight'
    ] as const);

    const missingAngleLabel = getAlgebraicSymbol();

    return {
      topLeftAngleSize,
      bottomRightAngleSize,
      topRightAngleSize,
      missingAngle,
      missingAngleLabel,
      shape
    };
  },
  Component: ({ question, translate }) => {
    const {
      topLeftAngleSize,
      bottomRightAngleSize,
      topRightAngleSize,
      missingAngle,
      missingAngleLabel,
      shape
    } = question;

    const middleAngleSize = 360 - (topLeftAngleSize + bottomRightAngleSize + topRightAngleSize);

    const topLeftLabel = translate.units.numberOfDegrees(topLeftAngleSize);
    const middleLabel = translate.units.numberOfDegrees(middleAngleSize);
    const bottomRightLabel = translate.units.numberOfDegrees(bottomRightAngleSize);
    const topRightLabel = translate.units.numberOfDegrees(topRightAngleSize);

    const [labels, answer] = (() => {
      switch (missingAngle) {
        case 'topLeft':
          return [
            [missingAngleLabel, middleLabel, bottomRightLabel, topRightLabel],
            topLeftAngleSize
          ];
        case 'middle':
          return [
            [topLeftLabel, missingAngleLabel, bottomRightLabel, topRightLabel],
            middleAngleSize
          ];
        case 'bottomRight':
          return [
            [topLeftLabel, middleLabel, missingAngleLabel, topRightLabel],
            bottomRightAngleSize
          ];
        case 'topRight':
          return [
            [topLeftLabel, middleLabel, bottomRightLabel, missingAngleLabel],
            topRightAngleSize
          ];
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutMissingAngleInQuadrilateral()}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={labels} />
        )}
        // This shape actually grows significantly with flexDirection: 'row', while not encroaching on the answer sentence.
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.xEqualsAnsDegrees(missingAngleLabel)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aEB',
  description: 'aEB',
  keywords: ['Quadrilateral', 'Angles', 'Isosceles', 'Triangles'],
  schema: z.object({
    leftTriangleAngle: z
      .number()
      .int()
      .min(40)
      .max(130)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'leftTriangleAngle cannot be a multiple of 10'),
    rightTriangleAngle: z
      .number()
      .int()
      .min(26)
      .max(96)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'rightTriangleAngle cannot be a multiple of 10'),
    shapeVariation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const shapeVariation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    const leftTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(40, 60, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          });

    const rightTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(36, 56, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(26, 46, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(30, 50, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          });

    return { leftTriangleAngle, rightTriangleAngle, shapeVariation };
  },
  Component: ({ question, translate, displayMode }) => {
    const { leftTriangleAngle, rightTriangleAngle, shapeVariation } = question;

    const leftTriangleAngleLabel = translate.units.numberOfDegrees(leftTriangleAngle);
    const rightTriangleAngleLabel = translate.units.numberOfDegrees(rightTriangleAngle);

    const leftTriangleOtherAngles = (180 - leftTriangleAngle) / 2;
    const rightTriangleOtherAngles = (180 - rightTriangleAngle) / 2;

    const labels = [
      ALGEBRAIC_Y,
      leftTriangleAngleLabel,
      ALGEBRAIC_W,
      ALGEBRAIC_X,
      rightTriangleAngleLabel,
      ALGEBRAIC_Z
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.twoIsoscelesTrianglesAreJoinedToFormAKiteWorkOutSizesOfUnknownAngles()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={
              shapeVariation === 'A'
                ? 'Kite_two_isosceles_triangles1'
                : shapeVariation === 'B'
                ? 'Kite_two_isosceles_triangles2'
                : shapeVariation === 'C'
                ? 'Kite_two_isosceles_triangles3'
                : 'Kite_two_isosceles_triangles4'
            }
            angleLabels={labels}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_W),
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_X),
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_Y),
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_Z)
        ]}
        sentenceStyle={{
          alignSelf: 'flex-end',
          bottom: displayMode === 'digital' ? 16 : undefined
        }}
        testCorrect={[
          [leftTriangleOtherAngles.toString()],
          [rightTriangleOtherAngles.toString()],
          [leftTriangleOtherAngles.toString()],
          [rightTriangleOtherAngles.toString()]
        ]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aEC',
  description: 'aEC',
  keywords: ['Quadrilateral', 'Angles', 'Isosceles', 'Triangles', 'Calculate'],
  schema: z.object({
    leftTriangleAngle: z
      .number()
      .int()
      .min(40)
      .max(130)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'leftTriangleAngle cannot be a multiple of 10'),
    rightTriangleAngle: z
      .number()
      .int()
      .min(26)
      .max(96)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'rightTriangleAngle cannot be a multiple of 10'),
    shapeVariation: z.enum(['A', 'B', 'C', 'D']),
    topOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const shapeVariation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    const leftTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(40, 60, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          });

    const rightTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(36, 56, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(26, 46, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(30, 50, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          });

    const topOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { leftTriangleAngle, rightTriangleAngle, shapeVariation, topOrBottom };
  },
  Component: ({ question, translate }) => {
    const { leftTriangleAngle, rightTriangleAngle, shapeVariation, topOrBottom } = question;

    const leftTriangleAngleLabel = translate.units.numberOfDegrees(leftTriangleAngle);
    const rightTriangleAngleLabel = translate.units.numberOfDegrees(rightTriangleAngle);

    const leftTriangleOtherAngles = (180 - leftTriangleAngle) / 2;
    const rightTriangleOtherAngles = (180 - rightTriangleAngle) / 2;

    const labels = [
      ALGEBRAIC_Y,
      leftTriangleAngleLabel,
      ALGEBRAIC_W,
      ALGEBRAIC_X,
      rightTriangleAngleLabel,
      ALGEBRAIC_Z
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.twoIsoscelesTrianglesAreJoinedToFormAKiteWorkOutX(
          topOrBottom === 'top'
            ? `${ALGEBRAIC_Y} ${ADD} ${ALGEBRAIC_Z}`
            : `${ALGEBRAIC_W} ${ADD} ${ALGEBRAIC_X}`
        )}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={
              shapeVariation === 'A'
                ? 'Kite_two_isosceles_triangles1'
                : shapeVariation === 'B'
                ? 'Kite_two_isosceles_triangles2'
                : shapeVariation === 'C'
                ? 'Kite_two_isosceles_triangles3'
                : 'Kite_two_isosceles_triangles4'
            }
            angleLabels={labels}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansDeg()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[(leftTriangleOtherAngles + rightTriangleOtherAngles).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aED',
  description: 'aED',
  keywords: ['Quadrilateral', 'Angles', 'Isosceles', 'Triangles'],
  schema: z.object({
    leftTriangleAngle: z
      .number()
      .int()
      .min(40)
      .max(130)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'leftTriangleAngle cannot be a multiple of 10'),
    rightTriangleAngle: z
      .number()
      .int()
      .min(26)
      .max(96)
      .multipleOf(2)
      .refine(val => val % 10 !== 0, 'rightTriangleAngle cannot be a multiple of 10'),
    shapeVariation: z.enum(['A', 'B', 'C', 'D']),
    topOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const shapeVariation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    const leftTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(40, 60, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(100, 130, 2, {
            constraint: x => x % 10 !== 0
          });

    const rightTriangleAngle =
      shapeVariation === 'A'
        ? randomIntegerInclusiveStep(36, 56, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'B'
        ? randomIntegerInclusiveStep(26, 46, 2, {
            constraint: x => x % 10 !== 0
          })
        : shapeVariation === 'C'
        ? randomIntegerInclusiveStep(30, 50, 2, {
            constraint: x => x % 10 !== 0
          })
        : randomIntegerInclusiveStep(76, 96, 2, {
            constraint: x => x % 10 !== 0
          });
    const topOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { leftTriangleAngle, rightTriangleAngle, shapeVariation, topOrBottom };
  },
  Component: ({ question, translate }) => {
    const { leftTriangleAngle, rightTriangleAngle, shapeVariation, topOrBottom } = question;

    const leftTriangleOtherAngles = (180 - leftTriangleAngle) / 2;
    const rightTriangleOtherAngles = (180 - rightTriangleAngle) / 2;

    const leftTriangleAngleLabel = translate.units.numberOfDegrees(leftTriangleOtherAngles);
    const rightTriangleAngleLabel = translate.units.numberOfDegrees(rightTriangleOtherAngles);

    const labels =
      topOrBottom === 'top'
        ? [leftTriangleAngleLabel, ALGEBRAIC_A, '', '', ALGEBRAIC_B, rightTriangleAngleLabel]
        : ['', ALGEBRAIC_A, leftTriangleAngleLabel, rightTriangleAngleLabel, ALGEBRAIC_B, ''];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.twoIsoscelesTrianglesAreJoinedToFormAKiteWorkOutSizesOfUnknownAngles()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={
              shapeVariation === 'A'
                ? 'Kite_two_isosceles_triangles1'
                : shapeVariation === 'B'
                ? 'Kite_two_isosceles_triangles2'
                : shapeVariation === 'C'
                ? 'Kite_two_isosceles_triangles3'
                : 'Kite_two_isosceles_triangles4'
            }
            angleLabels={labels}
          />
        )}
        sentences={[
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_A),
          translate.answerSentences.xEqualsAnsDegrees(ALGEBRAIC_B)
        ]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        testCorrect={[[leftTriangleAngle.toString()], [rightTriangleAngle.toString()]]}
        pdfDirection="column"
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AnglesInQuadrilaterals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
