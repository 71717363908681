import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';

export type LabelledRectilinearShapesPropertiesType = {
  sideLengthRatioOptions: number[][];
  /**
   * Indices for the Longest side (L) and the two opposite sides(Oa,Ob) that add up to make it.
   * i.e [[L1,[Oa1,Ob1], [L2,[Oa2,Ob2]], where L1 = Oa1+Ob1 etc
   * Only applicable for L shapes.
   */
  oppLSides?: [number, number[]][];
  calcArea: (lengths: number[]) => number;
};

export type LabelledLShapesPropertiesType = LabelledRectilinearShapesPropertiesType &
  Required<{ oppLSides: [number, number[]][] }>;

/**
 * Labelled Rectilinear non-L-Shapes
 */
const nonLShapeNames = [
  'Rectilinear_shape2_all_arrows',
  'Rectilinear_shape3_all_arrows',
  'Rectilinear_shape4_all_arrows',
  'Rectilinear_shape12_all_arrows',
  'Rectilinear_shape15_all_arrows',
  'Rectilinear_shape32_all_arrows'
] as const;
type NonLShapeNamesType = (typeof nonLShapeNames)[number];

export const NonLShapesNameSchema = z.enum(nonLShapeNames);

export const nonLShapeProperties: Record<
  NonLShapeNamesType,
  LabelledRectilinearShapesPropertiesType
> = {
  Rectilinear_shape2_all_arrows: {
    sideLengthRatioOptions: [
      [5, 4, 2, 1, 2, 4, 5, 4, 2, 1, 2, 4],
      [9, 6, 3, 1, 3, 6, 9, 6, 3, 1, 3, 6],
      [10, 6, 3, 1, 3, 6, 10, 6, 3, 1, 3, 6],
      [6, 4, 2, 1, 2, 4, 6, 4, 2, 1, 2, 4]
    ],
    calcArea: lengths =>
      lengths[0] * (lengths[1] + lengths[3] + lengths[5]) + lengths[3] * (lengths[2] + lengths[10])
  },
  Rectilinear_shape3_all_arrows: {
    sideLengthRatioOptions: [
      [5, 8, 4, 5, 3, 7, 12, 10],
      [3, 5, 2, 3, 2, 4, 7, 6],
      [4, 6, 2, 4, 2, 5, 8, 7],
      [4, 7, 3, 2, 3, 6, 10, 9]
    ],
    calcArea: lengths =>
      lengths[0] * lengths[7] + lengths[2] * (lengths[7] - lengths[1]) + lengths[4] * lengths[5]
  },
  Rectilinear_shape4_all_arrows: {
    sideLengthRatioOptions: [
      [7, 3, 2, 2, 2, 5, 3, 6],
      [10, 4, 3, 2, 3, 7, 4, 9],
      [13, 5, 4, 3, 4, 9, 5, 11]
    ],
    calcArea: lengths =>
      lengths[1] * lengths[2] + lengths[4] * (lengths[7] - lengths[5]) + lengths[6] * lengths[7]
  },
  Rectilinear_shape12_all_arrows: {
    sideLengthRatioOptions: [
      [8, 7, 3, 6, 3, 6, 2, 7],
      [11, 9, 4, 7, 4, 7, 3, 9]
    ],
    calcArea: lengths =>
      lengths[1] * lengths[2] + lengths[4] * (lengths[7] - lengths[5]) + lengths[6] * lengths[7]
  },
  Rectilinear_shape15_all_arrows: {
    sideLengthRatioOptions: [
      [7, 4, 2, 2, 3, 2, 2, 4],
      [10, 5, 3, 2, 4, 2, 3, 5]
    ],
    calcArea: lengths =>
      lengths[1] * lengths[2] + lengths[4] * (lengths[7] - lengths[5]) + lengths[6] * lengths[7]
  },
  Rectilinear_shape32_all_arrows: {
    sideLengthRatioOptions: [
      [7, 3, 2, 2, 3, 2, 2, 3],
      [10, 5, 3, 3, 4, 3, 3, 5]
    ],
    calcArea: lengths =>
      lengths[1] * lengths[2] + lengths[4] * (lengths[1] - lengths[3]) + lengths[6] * lengths[7]
  }
};

export function getRandomRectilinearNonLShape() {
  return getRandomFromArray(nonLShapeNames);
}

/**
 * Labelled Rectilinear L-Shapes
 */
export const LShapeNames = [
  'Rectilinear_shape1_all_arrows',
  'Rectilinear_shape5_all_arrows',
  'Rectilinear_shape6_all_arrows',
  'Rectilinear_shape7_all_arrows',
  'Rectilinear_shape8_all_arrows',
  'Rectilinear_shape9_all_arrows',
  'Rectilinear_shape10_all_arrows',
  'Rectilinear_shape11_all_arrows',
  'Rectilinear_shape13_all_arrows',
  'Rectilinear_shape14_all_arrows',
  'Rectilinear_shape16_all_arrows',
  'Rectilinear_shape17_all_arrows',
  'Rectilinear_shape18_all_arrows',
  'Rectilinear_shape19_all_arrows',
  'Rectilinear_shape20_all_arrows',
  'Rectilinear_shape21_all_arrows',
  'Rectilinear_shape22_all_arrows',
  'Rectilinear_shape23_all_arrows',
  'Rectilinear_shape24_all_arrows',
  'Rectilinear_shape25_all_arrows',
  'Rectilinear_shape26_all_arrows',
  'Rectilinear_shape27_all_arrows',
  'Rectilinear_shape28_all_arrows',
  'Rectilinear_shape29_all_arrows',
  'Rectilinear_shape30_all_arrows',
  'Rectilinear_shape31_all_arrows',
  'Rectilinear_shape33_all_arrows',
  'Rectilinear_shape34_all_arrows',
  'Rectilinear_shape35_all_arrows',
  'Rectilinear_shape36_all_arrows',
  'Rectilinear_shape37_all_arrows',
  'Rectilinear_shape38_all_arrows'
] as const;

export type LShapeNamesType = (typeof LShapeNames)[number];
export const LShapeNameSchema = z.enum(LShapeNames);

export const LShapeProperties: Record<LShapeNamesType, LabelledLShapesPropertiesType> = {
  Rectilinear_shape1_all_arrows: {
    sideLengthRatioOptions: [
      [5, 2, 4, 1, 1, 3],
      [7, 3, 5, 2, 2, 5],
      [8, 4, 6, 2, 2, 6],
      [9, 4, 7, 2, 2, 6],
      [10, 4, 8, 2, 2, 6]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape5_all_arrows: {
    sideLengthRatioOptions: [
      [5, 1, 2, 3, 7, 2],
      [6, 1, 2, 4, 8, 3],
      [7, 2, 3, 5, 10, 3]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape6_all_arrows: {
    sideLengthRatioOptions: [
      [4, 1, 3, 2, 1, 3],
      [5, 1, 4, 3, 1, 4],
      [7, 2, 5, 3, 2, 5]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape7_all_arrows: {
    sideLengthRatioOptions: [
      [3, 1, 2, 6, 5, 5],
      [4, 1, 3, 8, 7, 7],
      [7, 2, 4, 12, 11, 10]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape8_all_arrows: {
    sideLengthRatioOptions: [
      [5, 4, 2, 5, 7, 1],
      [6, 5, 2, 6, 8, 1],
      [8, 6, 3, 9, 11, 3]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape9_all_arrows: {
    sideLengthRatioOptions: [
      [1, 3, 1, 4, 2, 1],
      [2, 5, 2, 7, 4, 2],
      [3, 7, 3, 10, 6, 3]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape10_all_arrows: {
    sideLengthRatioOptions: [
      [3, 1, 2, 1, 1, 2],
      [8, 3, 6, 3, 2, 6],
      [10, 3, 7, 4, 3, 7]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape11_all_arrows: {
    sideLengthRatioOptions: [
      [3, 6, 4, 2, 7, 8],
      [4, 7, 5, 3, 9, 10],
      [5, 9, 6, 4, 11, 13]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },

  Rectilinear_shape13_all_arrows: {
    sideLengthRatioOptions: [
      [4, 2, 3, 2, 1, 4],
      [5, 2, 4, 3, 1, 5],
      [7, 3, 5, 4, 2, 7]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape14_all_arrows: {
    sideLengthRatioOptions: [
      [2, 5, 7, 3, 9, 8],
      [3, 7, 9, 4, 12, 11]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape16_all_arrows: {
    sideLengthRatioOptions: [
      [8, 1, 6, 2, 2, 3],
      [12, 2, 8, 3, 4, 5]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape17_all_arrows: {
    sideLengthRatioOptions: [
      [5, 6, 2, 5, 3, 1],
      [7, 9, 3, 7, 4, 2]
    ],
    oppLSides: [
      [0, [2, 4]],
      [1, [3, 5]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape18_all_arrows: {
    sideLengthRatioOptions: [
      [6, 1, 4, 4, 2, 5],
      [9, 2, 6, 5, 3, 7]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape19_all_arrows: {
    sideLengthRatioOptions: [
      [2, 3, 6, 3, 8, 6],
      [3, 4, 8, 4, 11, 8]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape20_all_arrows: {
    sideLengthRatioOptions: [
      [4, 2, 3, 4, 1, 6],
      [7, 3, 5, 6, 2, 9]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape21_all_arrows: {
    sideLengthRatioOptions: [
      [4, 5, 2, 8, 6, 3],
      [5, 6, 2, 10, 7, 4]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape22_all_arrows: {
    sideLengthRatioOptions: [
      [8, 2, 5, 4, 3, 6],
      [11, 3, 7, 6, 4, 9]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape23_all_arrows: {
    sideLengthRatioOptions: [
      [1, 2, 3, 1, 4, 3],
      [2, 4, 7, 2, 9, 6]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape24_all_arrows: {
    sideLengthRatioOptions: [
      [2, 5, 3, 8, 5, 3],
      [3, 7, 4, 11, 7, 4]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape25_all_arrows: {
    sideLengthRatioOptions: [
      [6, 5, 3, 2, 3, 7],
      [8, 6, 4, 3, 4, 9]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape26_all_arrows: {
    sideLengthRatioOptions: [
      [3, 5, 2, 2, 1, 3],
      [6, 8, 4, 3, 2, 5]
    ],
    oppLSides: [
      [0, [2, 4]],
      [1, [3, 5]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape27_all_arrows: {
    sideLengthRatioOptions: [
      [2, 2, 4, 5, 6, 7],
      [4, 3, 6, 8, 10, 11]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape28_all_arrows: {
    sideLengthRatioOptions: [
      [1, 1, 3, 3, 4, 2],
      [4, 3, 10, 9, 14, 6]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape29_all_arrows: {
    sideLengthRatioOptions: [
      [1, 1, 2, 1, 3, 2],
      [4, 3, 8, 5, 12, 8]
    ],
    oppLSides: [
      [4, [0, 2]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape30_all_arrows: {
    sideLengthRatioOptions: [
      [5, 9, 3, 3, 2, 6],
      [7, 12, 4, 4, 3, 8]
    ],
    oppLSides: [
      [0, [2, 4]],
      [1, [3, 5]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape31_all_arrows: {
    sideLengthRatioOptions: [
      [5, 4, 2, 1, 3, 3],
      [7, 6, 3, 2, 4, 4]
    ],
    oppLSides: [
      [0, [2, 4]],
      [1, [3, 5]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape33_all_arrows: {
    sideLengthRatioOptions: [
      [2, 1, 1, 2, 1, 3],
      [7, 3, 3, 7, 4, 10]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape34_all_arrows: {
    sideLengthRatioOptions: [
      [2, 2, 1, 1, 1, 3],
      [7, 7, 3, 3, 4, 10]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape35_all_arrows: {
    sideLengthRatioOptions: [
      [3, 2, 2, 1, 1, 3],
      [10, 6, 6, 3, 4, 9]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape36_all_arrows: {
    sideLengthRatioOptions: [
      [4, 1, 2, 5, 2, 6],
      [6, 2, 3, 8, 3, 10]
    ],
    oppLSides: [
      [0, [2, 4]],
      [5, [1, 3]]
    ],
    calcArea: lengths => lengths[0] * lengths[1] + lengths[3] * lengths[4]
  },
  Rectilinear_shape37_all_arrows: {
    sideLengthRatioOptions: [
      [3, 9, 4, 11, 7, 2],
      [4, 11, 5, 14, 9, 3]
    ],
    oppLSides: [
      [3, [1, 5]],
      [4, [0, 2]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  },
  Rectilinear_shape38_all_arrows: {
    sideLengthRatioOptions: [
      [3, 4, 1, 2, 2, 2],
      [5, 7, 2, 3, 3, 4]
    ],
    oppLSides: [
      [0, [2, 4]],
      [1, [3, 5]]
    ],
    calcArea: lengths => lengths[0] * lengths[5] + lengths[2] * lengths[3]
  }
};

export function getRandomRectilinearLShape() {
  return getRandomFromArray(LShapeNames);
}

export function getRandomUniqueRectilinearLShapes(numberOfLShapes: number) {
  return getRandomSubArrayFromArray(LShapeNames, numberOfLShapes);
}

/**
 * All Labelled Rectilinear Shapes
 */
export const labelledRectilinearShapeNames = [...nonLShapeNames, ...LShapeNames] as const;

export type RectilinearShapeNames = (typeof labelledRectilinearShapeNames)[number];
export const RectilinearShapeNameSchema = z.enum(labelledRectilinearShapeNames);

export const labelledRectilinearShapesProperties: Record<
  RectilinearShapeNames,
  LabelledRectilinearShapesPropertiesType
> = {
  ...LShapeProperties,
  ...nonLShapeProperties
};

export function getRandomRectilinearShape() {
  return getRandomFromArray(labelledRectilinearShapeNames);
}

export function getRandomUniqueRectilinearShapes(numberOfShapes: number) {
  return getRandomSubArrayFromArray(labelledRectilinearShapeNames, numberOfShapes);
}
