// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import AddAndSubtractIntegers from './1AddAndSubtractIntegers';
import CommonFactors from './2CommonFactors';
import CommonMultiples from './3CommonMultiples';
import RulesOfDivisibility from './4RulesOfDivisibility';
import PrimesTo100 from './5PrimesTo100';
import SquareAndCubeNumbers from './6SquareAndCubeNumbers';
import MultiplyUpToA4DigitNumberByA2DigitNumber from './7MultiplyUpToA4DigitNumberByA2DigitNumber';
import SolveProblemsWithMultiplication from './8SolveProblemsWithMultiplication';
import ShortDivision from './9ShortDivision';
import DivisionUsingFactors from './10DivisionUsingFactors';
import IntroductionToLongDivision from './11IntroductionToLongDivision';
import LongDivisionWithRemainders from './12LongDivisionWithRemainders';
import SolveProblemsWithDivision from './13SolveProblemsWithDivision';
import SolveMultiStepProblems from './14SolveMultiStepProblems';
import OrderOfOperations from './15OrderOfOperations';
import MentalCalculationsAndEstimation from './16MentalCalculationsAndEstimation';
import ReasonFromKnownFacts from './17ReasonFromKnownFacts';

const Block = newBlockContent({
  block: 'AdditionSubtractionMultiplicationAndDivision',
  field: 'Number',
  weeks: [3, 7],
  smallSteps: [
    AddAndSubtractIntegers, // Step 1
    CommonFactors, // Step 2
    CommonMultiples, // Step 3
    RulesOfDivisibility, // Step 4
    PrimesTo100, // Step 5
    SquareAndCubeNumbers, // Step 6
    MultiplyUpToA4DigitNumberByA2DigitNumber, // Step 7
    SolveProblemsWithMultiplication, // Step 8
    ShortDivision, // Step 9
    DivisionUsingFactors, // Step 10
    IntroductionToLongDivision, // Step 11
    LongDivisionWithRemainders, // Step 12
    SolveProblemsWithDivision, // Step 13
    SolveMultiStepProblems, // Step 14
    OrderOfOperations, // Step 15
    MentalCalculationsAndEstimation, // Step 16
    ReasonFromKnownFacts // Step 17
  ]
});
export default Block;
