import { useContext, useMemo } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet, Pressable } from 'react-native';
import Svg, { Line } from 'react-native-svg';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import { ADD, SUB, MULT } from 'common/src/constants';
import { colors } from 'common/src/theme/colors';
import { ScientificNotation } from 'common/src/utils/math';
import { projectSetState, SetState } from 'common/src/utils/react';
import { filledArray, range } from 'common/src/utils/collections';
import Text from 'common/src/components/typography/Text';
import { all, create, number } from 'mathjs';
import React from 'react';
import {
  findAddSubExchangeValues,
  findExchanges,
  findMultiplicationExchangeValues,
  multiplicationHasExchanges
} from '../../../../utils/exchanges';
import { AssetSvg } from '../../../../assets/svg';
import { withStateHOC } from '../../../../stateTree';
import { DisplayMode } from '../../../../contexts/displayMode';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { GivenExchangesCell, InputCell, LabelCell } from './Cells';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

type UserAnswer = {
  top: string[];
  middle: string[];
  bottom: string[];
  answer: string[];
  exchanges: string[];
  strikeout: (number | undefined)[];
};

export type MissingDigitColumnOperationsProps = {
  // Must all be non-negative numbers. Missing digits correspond to powers of ten (i.e. 0=ones, 1=tens, etc.).
  topNumber: number;
  topMissingDigits: number[];
  /**
   * Digits denoted by powers of ten that you want to show as a blank Cell in the top row.
   * If the same digit is in the topMissingDigits array, that will override this, and a $ans answer box will appear
   * in place of a blank Cell.
   */
  topBlankDigits?: number[];
  bottomNumber: number;
  bottomMissingDigits: number[];
  /**
   * Digits denoted by powers of ten that you want to show as a blank Cell in the bottom row.
   * If the same digit is in the bottomMissingDigits array, that will override this, and a $ans answer box will appear
   * in place of a blank Cell.
   */
  bottomBlankDigits?: number[];
  // If middle number is set, we can only have a spare bottom row due to spacing. This means no exchanges on subtractions.
  middleNumber?: number;
  middleMissingDigits?: number[];
  // Undefined indicates don't show any digits
  answerNumber?: number;
  /**
   * Digits denoted by powers of ten that you want to show as a blank Cell in the answer row.
   * If the same digit is in the answerMissingDigits array, that will override this, and a $ans answer box will appear
   * in place of a blank Cell.
   */
  answerMissingDigits: number[];
  answerBlankDigits?: number[];

  /**
   * The user answer.
   *
   * Each array corresponds to the user's answer for the missing digits of that number. The indices of the array
   * correspond to the digit at that position in the number. So the element at index 2 is the user's answer for the
   * hundreds, for example. Indices corresponding to digits that aren't missing are left as undefined, since the user
   * doesn't need to give an answer for these.
   *
   * The exchanges and strikeout parts of the userAnswer are to store values for the workout space
   *
   * For quality of life, we support this array being initially empty or populated with undefined - it will be filled
   * in as the user starts giving their answers.
   */
  userAnswer?: UserAnswer;
  setUserAnswer?: SetState<UserAnswer>;
  /**
   * The operation that this column will be. Must be add, subtract or multiply.
   */
  operation: ADD | SUB | MULT;
  /**
   * Styles to be applied to the container of this component.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Removes extra cells from top/bottom rows and left/right columns around the operation.
   * Defaults to false.
   */
  removeExtraCells?: boolean;
  /**
   * Defaulted to false. When true the exchange values will be given. When false they will be optional input boxes.
   */
  showNumberExchanges?: boolean;
  /**
   * Usable dimensions for the question.
   */
  dimens: Dimens;

  showExtraLeadingAnswerBox?: boolean;
};

/**
 * This component renders a column operation on a grid, made to represent grid paper.
 * This component is different to ColumnOperations, as that component requires number props passed to calculate
 * the width of the component and how many Cells to show, whereas here we need our props to be arrays of strings.
 * Answer boxes will be shown for the user to complete for every '$ans' string passed in the topNumber, bottomNumber, thirdNumber
 * and answer props.
 * The number of columns of Cells is determined by the numbers and answer.
 * There is a blank row and column along the top, bottom, left and right of the column operation.
 * The operator will always show on the third row, second column of the grid.
 */
export default function MissingDigitColumnOperations({
  topNumber,
  topMissingDigits,
  topBlankDigits,
  bottomNumber,
  bottomMissingDigits,
  bottomBlankDigits,
  middleNumber,
  middleMissingDigits = [],
  answerNumber,
  answerMissingDigits,
  answerBlankDigits,
  operation,
  containerStyle,
  removeExtraCells = false,
  showNumberExchanges = false,
  showExtraLeadingAnswerBox = false,
  userAnswer = { top: [], bottom: [], middle: [], answer: [], exchanges: [], strikeout: [] },
  setUserAnswer = () => {
    /* do nothing */
  },
  dimens
}: MissingDigitColumnOperationsProps) {
  const displayMode = useContext(DisplayMode);

  const translate = useI18nContext().LL;
  // If the answerNumber prop was missing, don't show the answer number.
  const hideAnswerDigits = answerNumber === undefined;
  // ... but we still need to work it out in order to know how long it should be.
  answerNumber =
    answerNumber ??
    (() => {
      switch (operation) {
        case ADD:
          return number(math.evaluate(`${topNumber} + ${bottomNumber} + ${middleNumber ?? 0}`));
        case SUB:
          return number(math.evaluate(`${topNumber} - ${bottomNumber} - ${middleNumber ?? 0}`));
        case MULT:
          return number(math.evaluate(`${topNumber} * ${bottomNumber} * ${middleNumber ?? 1}`));
      }
    })();

  let hasExchanges: boolean;
  switch (operation) {
    case ADD: {
      hasExchanges =
        findExchanges(topNumber, bottomNumber).length > 0 ||
        (middleNumber !== undefined &&
          findExchanges(topNumber + bottomNumber, middleNumber).length > 0);
      break;
    }
    case SUB: {
      // no exchange boxes when 3 numbers and subtraction as there is no room
      hasExchanges = findExchanges(topNumber, -bottomNumber).length > 0;
      break;
    }
    case MULT: {
      hasExchanges =
        multiplicationHasExchanges(topNumber, bottomNumber) ||
        (middleNumber !== undefined &&
          multiplicationHasExchanges(topNumber * bottomNumber, middleNumber));
      break;
    }
  }

  const topNumberLowestPower = Math.min(ScientificNotation.fromNumber(topNumber).resolution, 0);
  const bottomNumberLowestPower = Math.min(
    ScientificNotation.fromNumber(bottomNumber).resolution,
    0
  );
  const middleNumberLowestPower = Math.min(
    ScientificNotation.fromNumber(middleNumber ?? 0).resolution,
    0
  );
  const answerNumberLowestPower = Math.min(
    ScientificNotation.fromNumber(answerNumber).resolution,
    0
  );
  const lowestPower = Math.min(
    topNumberLowestPower,
    bottomNumberLowestPower,
    middleNumberLowestPower,
    answerNumberLowestPower
  );

  // Get an array of digits to show for each number, starting with the lowest power and going up.
  const getDigits = (
    number: number,
    missingDigits: number[],
    blankDigits: number[] = [],
    noAdjust?: boolean,
    doesAnswerNeedExtraBox: boolean = false
  ): (number | '$ans' | '')[] => {
    // we want to include trailing zeros for decimals. However when it is a multiplication we do not want this for the bottom number.
    const minRange =
      operation === MULT && noAdjust
        ? Math.min(ScientificNotation.fromNumber(number).resolution, 0)
        : Math.min(ScientificNotation.fromNumber(number).resolution, 0, lowestPower);
    const sci = ScientificNotation.fromNumber(number);

    // make sure we get all leading zeros in decimals i.e 0.0023
    const maxRange = sci.e < 0 ? 0 : sci.e;

    const digits = range(minRange, maxRange).map(pow =>
      missingDigits.includes(pow)
        ? '$ans'
        : blankDigits.includes(pow)
        ? ''
        : sci.unsignedDigitAt(pow)
    );

    doesAnswerNeedExtraBox && digits.push('$ans');

    return digits;
  };

  const digits: Record<'top' | 'bottom' | 'middle' | 'answer', (number | '$ans' | '')[]> = {
    top: getDigits(topNumber, topMissingDigits, topBlankDigits),
    bottom: getDigits(bottomNumber, bottomMissingDigits, bottomBlankDigits, true),
    middle: middleNumber ? getDigits(middleNumber, middleMissingDigits) : [''],
    answer: getDigits(
      answerNumber,
      answerMissingDigits,
      answerBlankDigits,
      false,
      showExtraLeadingAnswerBox
    )
  };

  // Determine the longest number out of the two numbers passed and the answer.
  const topNumberExp = ScientificNotation.fromNumber(topNumber).e;
  const bottomNumberExp = ScientificNotation.fromNumber(bottomNumber).e;
  const middleNumberExp = middleNumber && ScientificNotation.fromNumber(middleNumber).e;
  const answerNumberExp = ScientificNotation.fromNumber(answerNumber).e;

  const longestNumber = Math.max(
    digits.top.length,
    digits.bottom.length,
    digits.answer.length,
    digits.middle.length
  );

  // If the longest number is the length of the answer, and this is longer than either numbers passed,
  // the question will need one empty column either side of the numbers;
  // otherwise, the question will need two empty columns to the left and one to the right.
  // Also take into account when the topNumber and bottomNumber are decimals and exponents are not the same to ensure
  // the operation sign is placed in the correct cell.
  const answerIsLongest =
    longestNumber === digits.answer.length &&
    longestNumber !== digits.top.length &&
    longestNumber !== digits.bottom.length &&
    longestNumber !== digits.middle.length;

  const gridColumns = answerIsLongest
    ? topNumberExp !== bottomNumberExp && (topNumber % 1 !== 0 || bottomNumber % 1 !== 0)
      ? longestNumber + 3
      : longestNumber + 2
    : longestNumber + 3;

  // Calculate the dimensions of each cell; dimens.height is used to ensure grid does not overflow; -10 for the extra message underneath. Defaults to 96 minimum square.
  const cellDimens = Math.max(
    Math.min(
      dimens.height / (removeExtraCells ? 3 : 5) -
        (hasExchanges && !removeExtraCells && displayMode === 'digital' ? 10 : 0),
      dimens.width / Math.max(6, gridColumns)
    ),
    removeExtraCells ? 0 : 96
  );

  const gridLineWidth = 1;
  const answerBoxBorderWidth = displayMode === 'digital' ? 3 : 1;
  const answerRowThickLinesWidth = 1;
  const sharedStyles = useSharedStyles(cellDimens, gridLineWidth, answerRowThickLinesWidth);

  // If we have at least one answer box missing in the top or middle row, all answer box Cells on PDFs should have a black border.
  const showBorderAroundBoxesOnPdf =
    (topMissingDigits && topMissingDigits.length > 0) ||
    (bottomMissingDigits && bottomMissingDigits.length > 0) ||
    (middleMissingDigits && middleMissingDigits.length > 0);

  const maxPower = Math.max(topNumberExp, bottomNumberExp, middleNumberExp ?? 0);
  // if the answer number has greater power than then we need to move the opSymbol one to the left
  const moveOpSymbol =
    ((answerIsLongest && answerNumber % 1 === 0) ||
      (answerNumberExp > maxPower && lowestPower < 0)) &&
    !removeExtraCells;

  /**
   * Function to create a row of Cells.
   * For 3 of the rows, this will show a number split into digits with one Cell per digit.
   * If an operation symbol is passed, this symbol will appear in the cell of index 1.
   */
  const getRow = (
    row: 'top' | 'bottom' | 'middle' | 'answer' | 'emptyTop' | 'emptyBottom',
    operationSymbol?: ADD | SUB | MULT
  ) => {
    const isExchangesRow = hasExchanges
      ? operation === ADD || operation === MULT
        ? row === 'emptyBottom'
        : row === 'emptyTop'
      : false;
    return range(0, gridColumns - 1).map(i => {
      // First and last will be empty Cells, others will be each digit in Cells in reverse order.
      // Lowest power digit goes at i === gridColumns - 2 (the second to last cell), and the next digit comes just before, etc.
      // Default index of digit assumes the number is an integer
      const indexOfDigit = gridColumns - 2 - i;

      // Identify the tenths column for the decimal point
      // We always have one blank cell at the end and we will have Math.abs(lowestPower) cells that should be to the right of the decimal point.
      // We do not want a decimal to show when multiplier is not a decimal
      const isDecimalCell =
        i === gridColumns - 1 - Math.abs(lowestPower) &&
        !(row === 'bottom' && operation === MULT && bottomNumberLowestPower >= 0);

      const operationSymbolCell = moveOpSymbol ? 0 : 1;

      if (isExchangesRow) {
        const numberOfExchanges =
          (removeExtraCells ? gridColumns : gridColumns - 2) - operationSymbolCell;
        const indexDigit = showNumberExchanges
          ? i - operationSymbolCell
          : i - operationSymbolCell - 1;

        const exchangeNumbers = showNumberExchanges
          ? operation === ADD || operation === SUB
            ? findAddSubExchangeValues(topNumber, operation === SUB ? -bottomNumber : bottomNumber)
            : findMultiplicationExchangeValues(topNumber, bottomNumber)
          : displayMode !== 'digital'
          ? filledArray(undefined, numberOfExchanges)
          : filledArray('$ans', numberOfExchanges);

        findAddSubExchangeValues(topNumber, bottomNumber);
        const currentCell = exchangeNumbers[indexDigit];
        const numberUserAnswer = userAnswer['exchanges'];
        const numberSetUserAnswer = projectSetState(setUserAnswer, 'exchanges');

        if (showNumberExchanges) {
          const mergeLeftBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            (exchangeNumbers[indexDigit - 1] !== '' &&
              exchangeNumbers[indexDigit - 1] !== undefined);
          const mergeRightBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            (exchangeNumbers[indexDigit + 1] !== '' &&
              exchangeNumbers[indexDigit + 1] !== undefined);
          if (currentCell === '' || currentCell === undefined) {
            return (
              <LabelCell
                key={i}
                cellDimens={cellDimens}
                gridLineWidth={gridLineWidth}
                answerBoxBorderWidth={answerBoxBorderWidth}
              />
            );
          } else {
            return (
              <React.Fragment key={`${i}-exchange`}>
                <LabelCell
                  cellDimens={cellDimens}
                  gridLineWidth={gridLineWidth}
                  answerBoxBorderWidth={answerBoxBorderWidth}
                />
                <GivenExchangesCell
                  colIndex={i}
                  cellDimens={cellDimens}
                  character={currentCell}
                  mergeLeftBorder={mergeLeftBorder}
                  mergeRightBorder={mergeRightBorder}
                  gridLineWidth={gridLineWidth}
                  answerBoxBorderWidth={answerBoxBorderWidth}
                />
              </React.Fragment>
            );
          }
        }
        if (currentCell === '$ans') {
          const mergeLeftBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            exchangeNumbers[indexDigit - 1] === '$ans';
          const mergeRightBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            exchangeNumbers[indexDigit + 1] === '$ans';
          return (
            <React.Fragment key={`${i}-exchange`}>
              <LabelCell
                cellDimens={cellDimens}
                gridLineWidth={gridLineWidth}
                answerBoxBorderWidth={answerBoxBorderWidth}
              />
              <InputCell
                colIndex={i}
                autoFocus={false}
                numberSetUserAnswer={numberSetUserAnswer}
                numberUserAnswer={numberUserAnswer}
                indexOfDigit={indexDigit}
                mergeLeftBorder={mergeLeftBorder}
                mergeRightBorder={mergeRightBorder}
                gridLineWidth={gridLineWidth}
                cellDimens={cellDimens}
                answerBoxBorderWidth={answerBoxBorderWidth}
                isExchange
                showBorderAroundBoxesOnPdf={showBorderAroundBoxesOnPdf}
              />
            </React.Fragment>
          );
        }
      }

      if (row === 'emptyTop' || row === 'emptyBottom') {
        // Empty row
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      }

      const numberDigits = digits[row];

      if (i === operationSymbolCell && operationSymbol) {
        // Operation symbol
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            character={operationSymbol}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      } else if (0 <= indexOfDigit && indexOfDigit < numberDigits.length) {
        // Digit from number
        const digit = numberDigits[indexOfDigit];

        if (digit === '$ans') {
          // Figure out which input box needs focusing. It's the last $ans in the first row with an $ans.
          const rowWithFirstInput = (['top', 'bottom', 'middle', 'answer'] as const).find(type =>
            digits[type].includes('$ans')
          );
          const indexOfAutoFocusedDigit =
            rowWithFirstInput === undefined
              ? undefined
              : digits[rowWithFirstInput].lastIndexOf('$ans');

          const numberUserAnswer = userAnswer[row];
          const numberSetUserAnswer = projectSetState(setUserAnswer, row);
          const autoFocus = rowWithFirstInput === row && indexOfAutoFocusedDigit === indexOfDigit;

          const mergeLeftBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            numberDigits[indexOfDigit + 1] === '$ans';
          const mergeRightBorder =
            (displayMode !== 'digital' && !showBorderAroundBoxesOnPdf) ||
            numberDigits[indexOfDigit - 1] === '$ans';

          return (
            <React.Fragment key={i}>
              <LabelCell
                cellDimens={cellDimens}
                gridLineWidth={gridLineWidth}
                answerBoxBorderWidth={answerBoxBorderWidth}
              />
              <InputCell
                colIndex={i}
                autoFocus={autoFocus}
                numberSetUserAnswer={numberSetUserAnswer}
                numberUserAnswer={numberUserAnswer}
                indexOfDigit={indexOfDigit}
                mergeLeftBorder={mergeLeftBorder}
                mergeRightBorder={mergeRightBorder}
                gridLineWidth={gridLineWidth}
                cellDimens={cellDimens}
                answerBoxBorderWidth={answerBoxBorderWidth}
                showBorderAroundBoxesOnPdf={showBorderAroundBoxesOnPdf}
              />
              {isDecimalCell && lowestPower < 0 && (
                <View style={{ ...sharedStyles.decimal, left: cellDimens * i - 5 }} />
              )}
            </React.Fragment>
          );
        } else {
          // allow striking out of numbers by clicking
          if (
            hasExchanges &&
            row === 'top' &&
            operation === SUB &&
            !removeExtraCells &&
            !middleNumber
          ) {
            const setStrikoutState = projectSetState(setUserAnswer, 'strikeout');

            return (
              <React.Fragment key={i}>
                <Pressable
                  onPress={() => {
                    if (!userAnswer.strikeout.includes(i)) {
                      const newArray = userAnswer.strikeout;
                      newArray.push(i);
                      setStrikoutState(newArray);
                    } else {
                      const newArray =
                        userAnswer.strikeout.length === 1
                          ? []
                          : userAnswer.strikeout.filter(index => index !== i);
                      setStrikoutState(newArray);
                    }
                  }}
                >
                  <LabelCell
                    character={digit.toLocaleString()}
                    cellDimens={cellDimens}
                    gridLineWidth={gridLineWidth}
                    answerBoxBorderWidth={answerBoxBorderWidth}
                  />
                  {userAnswer.strikeout.includes(i) && (
                    <Svg
                      width="26"
                      height="33"
                      viewBox="0 0 26 33"
                      style={{
                        position: 'absolute',
                        top: (cellDimens - 33) / 2,
                        left: (cellDimens - 26) / 2
                      }}
                    >
                      <Line
                        x1="24.0482"
                        y1="0.901938"
                        x2="1.19905"
                        y2="31.265"
                        stroke={colors.fieryRose600}
                        strokeWidth="3"
                      />
                    </Svg>
                  )}
                </Pressable>
                {isDecimalCell && lowestPower < 0 && (
                  <View style={{ ...sharedStyles.decimal, left: cellDimens * i - 5 }} />
                )}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={i}>
                <LabelCell
                  character={
                    row === 'answer' && hideAnswerDigits ? undefined : digit.toLocaleString()
                  }
                  cellDimens={cellDimens}
                  gridLineWidth={gridLineWidth}
                  answerBoxBorderWidth={answerBoxBorderWidth}
                />
                {isDecimalCell && lowestPower < 0 && (
                  <View style={{ ...sharedStyles.decimal, left: cellDimens * i - 5 }} />
                )}
              </React.Fragment>
            );
          }
        }
      } else {
        if (removeExtraCells && (i === 0 || i === gridColumns - 1)) {
          // No empty cells wanted on the left-hand or right-hand sides.
          return;
        }
        // Empty cell
        return (
          <LabelCell
            key={i}
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
          />
        );
      }
    });
  };

  const getAnswerLines = () => {
    const right = removeExtraCells ? gridLineWidth : cellDimens + gridLineWidth;
    const topRow1 = removeExtraCells
      ? 2 * cellDimens - answerRowThickLinesWidth / 2
      : 3 * cellDimens - answerRowThickLinesWidth / 2;
    const topRow2 = removeExtraCells
      ? 3 * cellDimens - answerRowThickLinesWidth / 2
      : 4 * cellDimens - answerRowThickLinesWidth / 2;
    const offSet = 3;
    const digitCount = moveOpSymbol ? gridColumns - offSet + 1 : gridColumns - offSet;
    return (
      <>
        <View
          style={[
            sharedStyles.answerLineBorder,
            {
              width: digitCount * cellDimens - gridLineWidth,
              backgroundColor: colors.prussianBlue,
              position: 'absolute',
              top: topRow1,
              right: right,
              zIndex: 10
            }
          ]}
        />
        <View
          style={[
            sharedStyles.answerLineBorder,
            {
              height: answerRowThickLinesWidth,
              width: digitCount * cellDimens - gridLineWidth,
              backgroundColor: colors.prussianBlue,
              position: 'absolute',
              top: topRow2,
              right: right,
              zIndex: 10
            }
          ]}
        />
      </>
    );
  };

  return (
    <View>
      <View style={[sharedStyles.tableContainer, containerStyle]}>
        {!removeExtraCells && !middleNumber && (
          <View style={sharedStyles.tableRow}>{getRow('emptyTop')}</View>
        )}
        <View style={sharedStyles.tableRow}>{getRow('top')}</View>
        {middleNumber && <View style={sharedStyles.tableRow}>{getRow('middle')}</View>}
        <View style={sharedStyles.tableRow}>{getRow('bottom', operation)}</View>
        <View style={sharedStyles.tableRow}>{getRow('answer')}</View>
        {!removeExtraCells && <View style={sharedStyles.tableRow}>{getRow('emptyBottom')}</View>}
        {getAnswerLines()}
      </View>
      {hasExchanges &&
        displayMode === 'digital' &&
        !showNumberExchanges &&
        !removeExtraCells &&
        !middleNumber && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5
            }}
          >
            <AssetSvg name={'Info'} width={25} height={26} />
            <Text variant="WRN400" style={{ fontSize: 22 }}>
              {operation === SUB
                ? translate.informationStrings.tapTheNumbersAndUseWorkingSpace()
                : translate.informationStrings.useWorkingSpaceToHelp()}
            </Text>
            <AssetSvg name={'Cell'} width={34} height={34} />
          </View>
        )}
    </View>
  );
}

const useSharedStyles = (
  cellDimens: number,
  gridLineWidth: number,
  answerRowThickLinesWidth?: number
) => {
  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);
  const minBorderWidth = 1 / scaleFactor;
  const minAnswerBorderWidth = 1 / scaleFactor;
  return useMemo(
    () =>
      StyleSheet.create({
        tableContainer: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderColor: colors.gridBlue
        },
        tableRow: {
          flexDirection: 'row'
        },
        answerLineBorder: {
          borderWidth: Math.max(
            minAnswerBorderWidth,
            answerRowThickLinesWidth ? answerRowThickLinesWidth : 0
          )
        },
        cell: {
          width: cellDimens,
          height: cellDimens,
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderColor: colors.gridBlue,
          justifyContent: 'center',
          alignItems: 'center'
        },
        decimal: {
          height: 10,
          width: 10,
          borderRadius: 50,
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          position: 'absolute',
          left: -5,
          top: cellDimens / 2 - 5,
          zIndex: 10
        },
        exchangeCell: {
          borderColor: colors.greys500,
          backgroundColor: displayMode === 'digital' ? colors.greys100 : undefined,
          fontSize: displayMode === 'digital' ? 22 : 32,
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          lineHeight: displayMode === 'digital' ? 22.5 : 36,
          padding: 5
        }
      }),
    [
      minBorderWidth,
      gridLineWidth,
      minAnswerBorderWidth,
      answerRowThickLinesWidth,
      cellDimens,
      displayMode
    ]
  );
};

/** See {@link MissingDigitColumnOperations} */
export const MissingDigitColumnOperationsWithState = withStateHOC(MissingDigitColumnOperations, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: {
    defaultState: {
      top: [],
      bottom: [],
      answer: [],
      middle: [],
      exchanges: [],
      strikeout: []
    }
  }
});
