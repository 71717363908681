import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 36 36" {...props}>
    <Path
      fillRule="evenodd"
      d="M5 0h26s5 0 5 5v26s0 5-5 5H5s-5 0-5-5V5s0-5 5-5m8 8h10s5 0 5 5v10s0 5-5 5H13s-5 0-5-5V13s0-5 5-5"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
