import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { getRandomFromArray, randomIntegerInclusiveStep } from '../../../../utils/random';
import QF65SetTheScales from '../../../../components/question/questionFormats/QF65SetTheScales';
import Scales, {
  defaultWeightImages
} from '../../../../components/question/representations/Scales';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import { SvgName } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIO',
  description: 'aIO',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    weight: z.number().int().min(100).max(1000).step(100),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(100, 1000, 100);
    const image = getRandomFromArray(defaultWeightImages);
    return { weight, image };
  },
  Component: ({ question: { weight, image }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[weight.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName="Scales/1000g_scale_50g_100g"
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIP',
  description: 'aIP',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    weight: z
      .number()
      .int()
      .min(50)
      .max(950)
      .step(50)
      .refine(val => val % 100 !== 0, 'weight must not be divisible by 100'),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(50, 950, 100);
    const image = getRandomFromArray(defaultWeightImages);
    return { weight, image };
  },
  Component: ({ question: { weight, image }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[weight.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName="Scales/1000g_scale_50g_100g"
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIQ',
  description: 'aIQ',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    weight: z.number().int().min(50).max(950).step(50)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(50, 950, 50);
    return { weight };
  },
  Component: ({ question: { weight }, translate }) => {
    return (
      <QF65SetTheScales
        title={translate.instructions.dragTheArrowToShowMassOnScale(weight)}
        pdfTitle={translate.instructions.drawArrowOnScalesToShowXG(weight)}
        svgName="Scales/1000g_scale_50g_100g"
        answerGrams={weight}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aIR',
  description: 'aIR',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    maxScale: numberEnum([100, 200]),
    interval: numberEnum([10, 20, 100]),
    weight: z.number().int().min(5).max(195),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const maxScale = getRandomFromArray([100, 200] as const);
    const array = maxScale === 200 ? [10, 20, 100] : [10, 20];
    const interval = getRandomFromArray([...array] as const) as 100 | 10 | 20;
    const weight = randomIntegerInclusiveStep(interval / 2, maxScale - interval / 2, interval);
    const image = getRandomFromArray(defaultWeightImages);
    return { weight, image, maxScale, interval };
  },
  Component: ({ question: { weight, image, maxScale, interval }, translate }) => {
    const svgName: SvgName = (() => {
      switch (maxScale) {
        case 100: {
          if (interval === 10) {
            return 'Scales/100g_scale_10g';
          } else {
            return 'Scales/100g_scale_20g';
          }
        }
        case 200: {
          if (interval === 10) {
            return 'Scales/200g_scale_10g';
          } else if (interval === 20) {
            return 'Scales/200g_scale_20g';
          } else {
            return 'Scales/200g_scale_100g';
          }
        }
      }
    })();

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[weight.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName={svgName}
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aIS',
  description: 'aIS',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    maxScale: numberEnum([500, 1000]),
    interval: numberEnum([10, 20, 25, 50]),
    weight: z.number().int().min(5).max(980),
    image: z.enum(defaultWeightImages)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const maxScale = getRandomFromArray([500, 1000] as const);
    const array = maxScale === 500 ? [10, 20, 25] : [20, 25, 50];
    const interval = getRandomFromArray([...array] as const) as 10 | 20 | 25 | 50;
    const weight = randomIntegerInclusiveStep(interval, maxScale - interval, interval);
    const image = getRandomFromArray(defaultWeightImages);
    return { weight, image, maxScale, interval };
  },
  Component: ({ question: { weight, image, maxScale, interval }, translate }) => {
    const svgName: SvgName = (() => {
      switch (maxScale) {
        case 500: {
          if (interval === 10) {
            return 'Scales/500g_scale_10g';
          } else if (interval === 20) {
            return 'Scales/500g_scale_20g';
          } else {
            return 'Scales/500g_scale_25g';
          }
        }
        case 1000: {
          if (interval === 20) {
            return 'Scales/1000g_scale_20g_100g';
          } else if (interval === 25) {
            return 'Scales/1000g_scale_25g_100g';
          } else {
            return 'Scales/1000g_scale_50g_100g';
          }
        }
      }
    })();

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsMass()}
        testCorrect={[weight.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
              svgName={svgName}
              weightImage={image}
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aIT',
  description: 'aIT',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    maxScale: numberEnum([500, 1000]),
    interval: numberEnum([10, 20, 25, 50]),
    weight: z.number().int().min(5).max(980)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const maxScale = getRandomFromArray([500, 1000] as const);
    const array = maxScale === 500 ? [10, 20, 25] : [20, 25, 50];
    const interval = getRandomFromArray([...array] as const) as 10 | 20 | 25 | 50;
    const weight = randomIntegerInclusiveStep(interval, maxScale - interval, interval);

    return { weight, maxScale, interval };
  },
  Component: ({ question: { weight, maxScale, interval }, translate }) => {
    const svgName: SvgName = (() => {
      switch (maxScale) {
        case 500: {
          if (interval === 10) {
            return 'Scales/500g_scale_10g';
          } else if (interval === 20) {
            return 'Scales/500g_scale_20g';
          } else {
            return 'Scales/500g_scale_25g';
          }
        }
        case 1000: {
          if (interval === 20) {
            return 'Scales/1000g_scale_20g_100g';
          } else if (interval === 25) {
            return 'Scales/1000g_scale_25g_100g';
          } else {
            return 'Scales/1000g_scale_50g_100g';
          }
        }
      }
    })();

    return (
      <QF65SetTheScales
        title={translate.instructions.dragTheArrowToShowMassOnScale(weight)}
        pdfTitle={translate.instructions.drawArrowOnScalesToShowXG(weight)}
        svgName={svgName}
        answerGrams={weight}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureMassInGrams',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
