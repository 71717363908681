import { parseMarkup } from '../../markup';

/**
 * Scales the font size of labels on a number line based on tick value density and line length.
 * Tick Values  can use the markup defined in {@link parseMarkup}, but if they do then they will be excluded from the
 * calculations.
 * Tick Values should be given as localized strings so that comma separators are taken into account.
 *
 * The function uses a few arbitrary values to scale according to a few limits.
 * - `baseFontSize`: Maximum font size possible; will default to this if enough space available.
 * - `minimumFontSize`: Minimum font size possible, this ensures the font size won't be unreadable.
 * - `scaleFactor`: Arbitrarily chosen after some trial and error, this is used to scale the font down.
 * - `maxDensity`: Maximum allowable density for the tick labels, also takes into account the label length.
 *
 * @param {string[]} tickValues - The tick values for the number line.
 * @param {number} lineLength - Length of number line (used to calculate tick density).
 * @returns {number} - Scaled font size for number line.
 *
 */
export default function scaleNumberLineFontSize(
  tickValues: string[],
  lineLength: number,
  displayMode: 'digital' | 'pdf' | 'markscheme' = 'digital',
  scaleFactor = 2.5
): number {
  // Limits
  const baseFontSize = displayMode === 'digital' ? 40 : 50;
  const minimumFontSize = displayMode === 'digital' ? 22 : 28;
  const maxDensity = 0.018;

  // Exclude tick values that use the markup (i.e. they have non-text tags).
  const filteredTickValues = tickValues.filter(elem => {
    const { tokens } = parseMarkup(elem);
    return tokens.length === 1 && tokens[0].type === 'text';
  });

  // Tick Density
  const tickDensity = tickValues.filter(it => it !== '').length / lineLength;

  const maxTickLabelLength = Math.max(...filteredTickValues.map(elem => elem.length), 1);

  if (maxTickLabelLength >= 6) {
    // Minimum font size changes
    return minimumFontSize;
  } else if (tickDensity * maxTickLabelLength <= maxDensity) {
    return baseFontSize;
  }

  return baseFontSize - scaleFactor * maxTickLabelLength;
}
