import { View, StyleProp, ViewStyle } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from 'common/src/utils/random';
import { PieChartColors, colors } from 'common/src/theme/colors';
import Text from '../../typography/Text';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { format } from 'mathjs';

type Props = {
  /**
   * pieOptions:
   * Ratio of slices of the pie chart. This dictates the size of the slice. If they are all equal it will return an
   * equally sliced pie with the total of the ratio being the number of slices.
   * Color is the segement color and innerLabel is the label inside the pie.
   */
  pieOptions: { ratioOfSlices: number; color?: string; innerLabel?: string }[];
  /** Indices of slices that will be rendered with a different fill.
   * e.g. [1, 4], 2nd & 5th slices will have a different color to other colored slices.
   * Will be overridden by missingSlices if the same index is in both props. */
  slicesWithSecondColor?: number[];
  /** Radius of pie chart
   * Viewbox is relative to the radius of the pie chart
   */
  radius: number;
  /** Color can be set manually */
  color?: string;
  /** Indices of slices that will be rendered with transparent fill. e.g. [0, 7], 1st & 8th slices will be transparent.
   * Will override slicesWithSecondColor if the same index is in both props.
   */
  missingSlices?: number[];
  /** When keyValues is set, a key will be rendered */
  keyValues?: { color: string; label: string }[];
  /** When true, percentages are shown inside pie chart. Default:false */
  showPercentages?: boolean;
  pieChartContainerStyle?: StyleProp<ViewStyle>;
  labelFontSize?: number;
};

const PieChart = ({
  pieOptions,
  slicesWithSecondColor,
  radius,
  color,
  missingSlices,
  keyValues,
  showPercentages = false,
  pieChartContainerStyle,
  labelFontSize
}: Props) => {
  const displayMode = useContext(DisplayMode);

  // Calculate viewbox dimensions
  const viewboxWidth = 2 * radius + 10;
  const viewboxHeight = 2 * radius + 10;

  const numberOfSlices = pieOptions.map(i => i.ratioOfSlices).reduce((a, b) => a + b);
  const ratioOfSlices = pieOptions.map(i => i.ratioOfSlices);

  const random = seededRandom({ numberOfSlices, missingSlices });

  const pieChartColor = getRandomFromArray(PieChartColors, { random }) as string;

  const pieChartSecondColor = getRandomFromArray(
    PieChartColors.filter(color => color !== pieChartColor),
    { random }
  ) as string;
  const centerX = viewboxWidth / 2;
  const centerY = viewboxHeight / 2;

  function getPoints(radius: number, angleInDeg: number, radialFactor: number = 1) {
    const radians = ((angleInDeg - 90) * Math.PI) / 180;
    return {
      x: centerX + radialFactor * radius * Math.cos(radians),
      y: centerY + radialFactor * radius * Math.sin(radians)
    };
  }

  const renderPieSlice = (): JSX.Element[] => {
    const pieChart = [];
    let startAngle = 0;
    // Loop to calculate and append segment paths to pieChart array
    for (let idx = 0; idx < ratioOfSlices.length; idx++) {
      const segmentSize = numberOfSlices / ratioOfSlices[idx];
      // Angle of the slice
      const angle = 360 / segmentSize;

      const endAngle = startAngle + angle;
      const segmentMissingFlag = missingSlices?.includes(idx);
      const secondColorFlag = slicesWithSecondColor?.includes(idx);

      const fill: string = (() => {
        if (segmentMissingFlag) return 'white';
        const pieOptionsColor = pieOptions[idx].color;
        if (pieOptionsColor !== undefined) return pieOptionsColor;
        if (secondColorFlag) return pieChartSecondColor!;
        return color ?? pieChartColor;
      })();

      // Calculate start and end coordinates of the arc
      const { x: startX, y: startY } = getPoints(radius, startAngle);
      const { x: endX, y: endY } = getPoints(radius, endAngle);

      // Create SVG path for the pie slice
      const pieChartSection = `M${centerX},${centerY} L${startX},${startY} A${radius},${radius} 0 ${
        angle > 180 ? 1 : 0
      },1 ${endX},${endY} Z`;

      // Update startAngle for the next slice
      startAngle = endAngle;

      pieChart.push(
        <Path
          key={`slice-${idx}`}
          d={pieChartSection}
          fill={fill}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={2}
        />
      );
    }

    return pieChart;
  };

  const renderInSegmentLabels = () => {
    let startAngle = 0;
    labelFontSize =
      labelFontSize ??
      (displayMode === 'digital' ? (showPercentages ? 22 : 32) : showPercentages ? 32 : 42);

    return pieOptions.map((val, idx) => {
      const segmentSize = numberOfSlices / ratioOfSlices[idx];
      const percent = format(100 / segmentSize, { precision: 3 });

      // Angle of the slice
      const angle = 360 / segmentSize;
      const midAngle = startAngle + angle / 2;
      // get mid points
      const { x: xLabel, y: yLabel } = getPoints(radius, midAngle, 0.75);
      // Update startAngle for the next slice
      const endAngle = startAngle + angle;
      startAngle = endAngle;

      const sentence = showPercentages ? `${percent}%\n(${val.innerLabel})` : val.innerLabel ?? '';

      return (
        <View
          key={`perc-${idx}`}
          pointerEvents="none"
          style={{
            position: 'absolute',
            width: viewboxWidth,
            height: viewboxHeight,
            left: xLabel - viewboxWidth / 2,
            top: yLabel - viewboxHeight / 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              pointerEvents: 'none',
              fontSize: labelFontSize,
              lineHeight: showPercentages ? 30 : 48,
              textAlign: 'center'
            }}
          >
            {sentence}
          </Text>
        </View>
      );
    });
  };

  const key = keyValues && (
    <View style={{ gap: 10 }}>
      {keyValues.map((val, idx) => (
        <View key={`key_${idx}`} style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          <View
            style={{
              height: 40,
              width: 40,
              backgroundColor: val.color,
              borderWidth: val.color === 'white' ? 2 : 0
            }}
          />
          <Text style={{ fontSize: displayMode === 'digital' ? 22 : 40 }}>{val.label}</Text>
        </View>
      ))}
    </View>
  );

  return (
    <View style={pieChartContainerStyle}>
      <Svg width={viewboxWidth} height={viewboxHeight}>
        {renderPieSlice()}
      </Svg>
      {renderInSegmentLabels()}
      {key}
    </View>
  );
};

export default PieChart;
