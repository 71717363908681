import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { arraysHaveSameContentsUnordered, range } from '../../../../utils/collections';
import { Dimens } from '../../../../theme/scaling';
import { compareFractions, simplify } from '../../../../utils/fractions';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import TextStructure from '../../../../components/molecules/TextStructure';
import { shadedSectionedShapes } from '../../../../utils/shadedSectionedShapes';
import QF20aBarModelInteractive from '../../../../components/question/questionFormats/QF20aBarModelInteractive';
////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHW',
  description: 'aHW',
  keywords: ['Non-unit fraction', 'Bar model', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);

    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { numerator, denominator };
  },
  Component: ({ question: { numerator, denominator }, translate }) => {
    return (
      <QF20aBarModelInteractive
        title={translate.instructions.shadeXOfTheBarModel(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        numberOfRows={1}
        numberOfCols={denominator}
        testCorrect={numerator}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHX',
  description: 'aHX',
  keywords: ['Non-unit fraction', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(2).max(7),
    shape: z.enum(['circle', 'longRect', 'rect', 'shortRect'])
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(2, denominator - 1);

    const shape = getRandomFromArray(['circle', 'longRect', 'rect', 'shortRect'] as const);

    return { numerator, denominator, shape };
  },
  Component: ({ question, translate }) => {
    const { numerator, denominator, shape } = question;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionOfTheShapeIsShaded()}
        Content={({ dimens }) => {
          return shadedSectionedShapes(shape, numerator, denominator, dimens, 'any');
        }}
        sentence={`<frac nAns='' dAns=''/>`}
        inputMaxCharacters={1}
        testCorrect={answer => compareFractions(answer, [numerator, denominator])}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHY',
  description: 'aHY',
  keywords: ['Non-unit fraction', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(2).max(7),
    shape: z.enum(['circle', 'longRect', 'rect', 'shortRect'])
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(2, denominator - 1, {
      constraint: x => {
        const simplifiedFraction = simplify(x, denominator);
        return simplifiedFraction[0] !== 1; // To make sure that it does not simplify to a unit fraction
      }
    });

    const shape = getRandomFromArray(['circle', 'longRect', 'rect', 'shortRect'] as const);

    return { numerator, denominator, shape };
  },
  Component: ({ question, translate }) => {
    const { numerator, denominator, shape } = question;

    const options = (dimens: Dimens) =>
      shuffle(
        [
          { value: 1, component: shadedSectionedShapes(shape, 1, denominator, dimens, 'any') },
          {
            value: numerator,
            component: shadedSectionedShapes(shape, numerator, denominator, dimens, 'any')
          }
        ],
        {
          random: seededRandom(question)
        }
      );
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNonUnitFraction()}
        numItems={2}
        renderItems={({ dimens }) => {
          return options({ width: dimens.width - 40, height: dimens.height - 40 });
        }}
        testCorrect={[numerator]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aHZ',
  description: 'aHZ',
  keywords: ['Non-unit fraction', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator1: z.number().int().min(2).max(12),
    denominator2: z.number().int().min(3).max(12),
    denominator3: z.number().int().min(3).max(12),
    denominator4: z.number().int().min(3).max(12),
    denominator5: z.number().int().min(3).max(12),
    denominator6: z.number().int().min(3).max(12),
    numerator2: z.number().int().min(1).max(11),
    numerator3: z.number().int().min(1).max(11),
    numerator4: z.number().int().min(1).max(11),
    numerator5: z.number().int().min(1).max(11),
    numerator6: z.number().int().min(1).max(11)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 12);
    const [denominator2, denominator3, denominator4, denominator5, denominator6] =
      randomUniqueIntegersInclusive(3, 12, 5, { constraint: x => x !== denominator1 });

    const getNonUnitNumerator = (denominator: number) =>
      randomIntegerInclusive(2, denominator - 1, {
        constraint: x => {
          const simplifiedFraction = simplify(x, denominator);
          return simplifiedFraction[0] !== 1; // To make sure that it does not simplify to a unit fraction
        }
      });
    const numerator2 = getNonUnitNumerator(denominator2);

    const [numerator3, numerator4, numerator5, numerator6] = [
      denominator3,
      denominator4,
      denominator5,
      denominator6
    ].map(denom => {
      return getRandomBoolean() ? 1 : getNonUnitNumerator(denom);
    });

    return {
      denominator1,
      denominator2,
      denominator3,
      denominator4,
      denominator5,
      denominator6,
      numerator2,
      numerator3,
      numerator4,
      numerator5,
      numerator6
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const {
      denominator1,
      denominator2,
      denominator3,
      denominator4,
      denominator5,
      denominator6,
      numerator2,
      numerator3,
      numerator4,
      numerator5,
      numerator6
    } = question;
    const items = shuffle(
      [
        [1, denominator1],
        [numerator2, denominator2],
        [numerator3, denominator3],
        [numerator4, denominator4],
        [numerator5, denominator5],
        [numerator6, denominator6]
      ],
      {
        random: seededRandom(question)
      }
    );
    const unitFraction: number[] = [];
    const nonUnitFraction: number[] = [];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.sortFractionsIntoTheTable()}
        zoneNames={[
          translate.tableHeaders.unitFractions(),
          translate.tableHeaders.nonUnitFractions()
        ]}
        items={items.map(([numerator, denominator]) => {
          numerator === 1 ? unitFraction.push(denominator) : nonUnitFraction.push(denominator);
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${numerator}' d='${denominator}'/>`}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={[
                  { fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }
                ]}
              />
            ),
            value: denominator
          };
        })}
        pdfItemVariant="pdfSquare"
        testCorrect={[unitFraction, nonUnitFraction]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aH0',
  description: 'aH0',
  keywords: ['Non-unit fraction', 'Bar model', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);

    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { numerator, denominator };
  },
  Component: ({ question: { numerator, denominator }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) =>
          shadedSectionedShapes('shortRect', numerator, denominator, dimens, 'any')
        }
        sentence={translate.answerSentences.xIsEqualToYLotsOfZ(
          `<frac n='${numerator}' d='${denominator}' />`,
          '<ans/>',
          `<frac n='1' d='${denominator}' />`
        )}
        testCorrect={[numerator.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aH1',
  description: 'aH1',
  keywords: ['Non-unit fraction', 'Equal parts', 'Numerator', 'Denominator', 'Shaded'],
  schema: z.object({
    denominator: z.number().int().min(3).max(5),
    denominator2: z.number().int().min(3).max(5),
    denominator3: z.number().int().min(3).max(5),
    denominator4: z.number().int().min(3).max(5),
    numerator: z.number().int().min(2).max(4),
    numerator2: z.number().int().min(2).max(4),
    numerator3: z.number().int().min(2).max(4),
    numerator4: z.number().int().min(2).max(4),
    shapes: z.array(z.enum(['circle', 'longRect', 'rect', 'shortRect'])).length(4)
  }),
  simpleGenerator: () => {
    const {
      numerator,
      numerator2,
      numerator3,
      numerator4,
      denominator,
      denominator2,
      denominator3,
      denominator4,
      shapes
    } = rejectionSample(
      () => {
        const [denominator, denominator2, denominator3] = range(1, 3).map(_ =>
          randomIntegerInclusive(3, 5)
        );

        const denominator4 = randomIntegerInclusive(3, 5, { constraint: x => x !== denominator }); // Makes sure that there is atleast one wrong answer

        const nonSimplifiedFraction = (denom: number) =>
          randomIntegerInclusive(2, denom - 1, {
            constraint: x => {
              const simplifiedFraction = simplify(x, denom);
              return simplifiedFraction[0] === x; // To make sure that it cannot simplify
            }
          });

        const [numerator, numerator2, numerator3, numerator4] = [
          denominator,
          denominator2,
          denominator3,
          denominator4
        ].map(denom => nonSimplifiedFraction(denom));

        const shapes = range(1, 4).map(_ =>
          getRandomFromArray(['circle', 'longRect', 'rect', 'shortRect'] as const)
        );

        return {
          numerator,
          numerator2,
          numerator3,
          numerator4,
          denominator,
          denominator2,
          denominator3,
          denominator4,
          shapes
        };
      },
      ({
        numerator,
        numerator2,
        numerator3,
        numerator4,
        denominator,
        denominator2,
        denominator3,
        denominator4
      }) => {
        // Prevent duplicate fractions
        return (
          !arraysHaveSameContentsUnordered([numerator, denominator], [numerator2, denominator2]) &&
          !arraysHaveSameContentsUnordered([numerator, denominator], [numerator3, denominator3]) &&
          !arraysHaveSameContentsUnordered([numerator, denominator], [numerator4, denominator4]) &&
          !arraysHaveSameContentsUnordered(
            [numerator2, denominator2],
            [numerator3, denominator3]
          ) &&
          !arraysHaveSameContentsUnordered(
            [numerator2, denominator2],
            [numerator4, denominator4]
          ) &&
          !arraysHaveSameContentsUnordered([numerator3, denominator3], [numerator4, denominator4])
        );
      }
    );

    return {
      numerator,
      numerator2,
      numerator3,
      numerator4,
      denominator,
      denominator2,
      denominator3,
      denominator4,
      shapes
    };
  },
  Component: ({ question, translate }) => {
    const {
      numerator,
      numerator2,
      numerator3,
      numerator4,
      denominator,
      denominator2,
      denominator3,
      denominator4,
      shapes
    } = question;

    const fractions = [
      [numerator, denominator, 0],
      [numerator2, denominator2, 1],
      [numerator3, denominator3, 2],
      [numerator4, denominator4, 3]
    ];

    const correctOptions = fractions
      .filter(([num, denom]) => compareFractions([num, denom], [numerator, denominator]))
      .map(([_, __, i]) => i);

    const options = (dimens: Dimens) =>
      shuffle(
        fractions.map(([num, denom], idx) => {
          return {
            value: idx,
            component: shadedSectionedShapes(shapes[idx], num, denom, dimens, 'any')
          };
        }),
        {
          random: seededRandom(question)
        }
      );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whichDiagramShowsX(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        pdfTitle={translate.instructions.whichDiagramShowsXPDF(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        numItems={4}
        renderItems={({ dimens }) =>
          options({ width: dimens.width * 0.9, height: dimens.height * 0.8 })
        }
        multiSelect
        testCorrect={userFractions =>
          arraysHaveSameContentsUnordered(userFractions, correctOptions)
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandTheNumeratorsOfNonUnitFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
