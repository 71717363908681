import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { multiplesNumberTrackArray } from 'common/src/utils/multiples';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { ADD, MULT } from 'common/src/constants';
import { filledArray, range } from 'common/src/utils/collections';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import {
  objectSchema,
  getRandomObject,
  containerOfObject,
  objectAsWord,
  objectNames
} from 'common/src/utils/objects';
import Text from '../../../../components/typography/Text';
import { getObjectImage } from 'common/src/utils/objectsImages';
import QF29CreateArray from 'common/src/components/question/questionFormats/QF29CreateArray';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aj7',
  description: 'aj7',
  keywords: ['Multiply', 'Track', 'Multiple', '3'],
  schema: z.object({
    tileToShow: z.number().int().min(2).max(7),
    startingNumber: z.number().int().min(3).max(15).multipleOf(3)
  }),
  simpleGenerator: () => {
    const tileToShow = randomIntegerInclusive(2, 7);

    const startingNumber = randomIntegerInclusiveStep(3, 15, 3);

    return { tileToShow, startingNumber };
  },
  Component: ({ question: { tileToShow, startingNumber }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      3,
      6,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aj8',
  description: 'aj8',
  keywords: ['Multiply', 'Group', 'Multiple'],
  schema: z.object({
    numberPerGroup: z
      .number()
      .int()
      .min(2)
      .max(6)
      .refine(val => val !== 3, 'numberPerGroup cannot equal 3.'),
    object: objectSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6, {
      constraint: x => x !== 3
    });

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={[
          translate.answerSentences.thereAreAnsGroupsOfAnsObjects(containerPlural, objectPlural),
          `<ans/> ${ADD} <ans/> ${ADD} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={answer =>
          answer[0][0] === '3' &&
          answer[0][1] === numberPerGroup.toString() &&
          answer[1][0] === numberPerGroup.toString() &&
          answer[1][1] === numberPerGroup.toString() &&
          answer[1][2] === numberPerGroup.toString() &&
          answer[1][3] === (numberPerGroup * 3).toString() &&
          ((answer[2][0] === numberPerGroup.toString() && answer[2][1] === '3') ||
            (answer[2][0] === '3' && answer[2][1] === numberPerGroup.toString())) &&
          answer[2][2] === (numberPerGroup * 3).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                flexDirection: 'row',
                justifyContent: 'space-evenly'
              }
            ]}
          >
            {range(1, 3).map(index => (
              <View key={index}>{getObjectImage(object, numberPerGroup, dimens.height)}</View>
            ))}
          </View>
        )}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(3).toLocaleString(), numberPerGroup.toLocaleString()],
            [
              ...filledArray(numberPerGroup.toLocaleString(), 3),
              (numberPerGroup * 3).toLocaleString()
            ],
            [
              (3).toLocaleString(),
              numberPerGroup.toLocaleString(),
              (numberPerGroup * 3).toLocaleString()
            ]
          ]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aj9',
  description: 'aj9',
  keywords: ['Multiply', 'Array', '3'],
  schema: z
    .object({
      columnsOrRows: z.number().int().min(2).max(10)
    })
    .refine(val => val.columnsOrRows !== 3, 'columnsOrRows cannot be 3'),
  simpleGenerator: () => {
    const columnsOrRows = randomIntegerInclusive(2, 10, {
      constraint: x => x !== 3
    });

    return { columnsOrRows };
  },
  Component: props => {
    const {
      question: { columnsOrRows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/> = <ans/>`, `<ans/> ${MULT} <ans/> = <ans/>`]}
        title={translate.instructions.writeTwoMultSentencesForArray()}
        testCorrect={answer =>
          ((answer[0][0] === columnsOrRows.toString() &&
            answer[0][1] === '3' &&
            answer[1][0] === '3' &&
            answer[1][1] === columnsOrRows.toString()) ||
            (answer[0][0] === '3' &&
              answer[0][1] === columnsOrRows.toString() &&
              answer[1][0] === columnsOrRows.toString() &&
              answer[1][1] === '3')) &&
          answer[0][2] === (3 * columnsOrRows).toString() &&
          answer[1][2] === (3 * columnsOrRows).toString()
        }
        inputMaxCharacters={2}
        {...props}
        Content={({ dimens }) => (
          <ArrayOfObjects
            // Ensure there's no more than 3 rows, to save on space.
            rows={columnsOrRows < 4 ? columnsOrRows : 3}
            columns={columnsOrRows < 4 ? 3 : columnsOrRows}
            dimens={dimens}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              columnsOrRows.toLocaleString(),
              (3).toLocaleString(),
              (3 * columnsOrRows).toLocaleString()
            ],
            [
              (3).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (3 * columnsOrRows).toLocaleString()
            ]
          ],
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aka',
  description: 'aka',
  keywords: ['Multiply', 'Array', '3'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    threeIsFirstOrSecond: z.enum(['first', 'second'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const threeIsFirstOrSecond = getRandomFromArray(['first', 'second'] as const);

    return { number1, threeIsFirstOrSecond };
  },
  Component: props => {
    const {
      question: { number1, threeIsFirstOrSecond },
      translate
    } = props;

    const multiplicationSentence =
      threeIsFirstOrSecond === 'first'
        ? `3 ${MULT} ${number1.toLocaleString()}`
        : `${number1.toLocaleString()} ${MULT} 3`;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.createArrayToShow(multiplicationSentence)}
        testCorrect={[3, number1]}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'akb',
  description: 'akb',
  keywords: ['Multiply', '3'],
  schema: z.object({
    groupA: z.number().int().min(1).max(4).array().length(4),
    objects: z.array(objectSchema).length(3)
  }),
  simpleGenerator: () => {
    const groupA = shuffle([1, 2, 3, 4]);

    // Get 3 different objects
    const objects = getRandomSubArrayFromArray(objectNames, 3);

    return { groupA, objects };
  },
  Component: props => {
    const {
      question: { groupA, objects },
      translate,
      displayMode
    } = props;

    // All the same
    const A1 = 3;
    const A3 = 3;
    const B2 = 3;
    const B4 = 3;

    const [B1, A2, B3, A4] = groupA;
    const [objectA, objectB, objectC] = objects;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 325 } : { height: 150, width: 450 };

    // Container & Object words
    const itemImageA = getObjectImage(objectA, B1, dimens.height, dimens.width / A1);
    const itemImageB = getObjectImage(objectB, B2, dimens.height, dimens.width / A2);
    const itemImageC = getObjectImage(objectC, B3, dimens.height, dimens.width / A3);

    const statements = [
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A1}
              customImage={itemImageA}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A1} ${MULT} ${B1}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A2}
              customImage={itemImageB}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A2} ${MULT} ${B2}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A3}
              customImage={itemImageC}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A3} ${MULT} ${B3}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects dimens={dimens} rows={Math.min(A4, B4)} columns={Math.max(A4, B4)} />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A4} ${MULT} ${B4}`
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchNumberSentencesToPictures()}
        items={items}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'akc',
  description: 'akc',
  keywords: ['Multiply', '3', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, ...props }) => {
    const multiplier = 3;
    const number3 = number1 * multiplier;

    const barArray = filledArray(multiplier, number1);

    const numbers = [[number3], barArray];

    const strings = [['?'], []];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={[number1.toString(), number3.toString()]}
        sentence={`<ans/> ${MULT} ${multiplier.toLocaleString()} = <ans/>`}
        {...props}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number3}
            dimens={dimens}
            sameRowColor
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy3',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
