// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UnderstandTheDenominatorsOfUnitFractions from './1UnderstandTheDenominatorsOfUnitFractions';
import CompareAndOrderUnitFractions from './2CompareAndOrderUnitFractions';
import UnderstandTheNumeratorsOfNonUnitFractions from './3UnderstandTheNumeratorsOfNonUnitFractions';
import UnderstandTheWhole from './4UnderstandTheWhole';
import CompareAndOrderNonUnitFractions from './5CompareAndOrderNonUnitFractions';
import FractionsAndScales from './6FractionsAndScales';
import FractionsOnANumberLine from './7FractionsOnANumberLine';
import CountInFractionsOnANumberLine from './8CountInFractionsOnANumberLine';
import EquivalentFractionsOnANumberLine from './9EquivalentFractionsOnANumberLine';
import EquivalentFractionsAsBarModels from './10EquivalentFractionsAsBarModels';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [7, 9],
  smallSteps: [
    UnderstandTheDenominatorsOfUnitFractions, // Step 1
    CompareAndOrderUnitFractions, // Step 2
    UnderstandTheNumeratorsOfNonUnitFractions, // Step 3
    UnderstandTheWhole, // Step 4
    CompareAndOrderNonUnitFractions, // Step 5
    FractionsAndScales, // Step 6
    FractionsOnANumberLine, // Step 7
    CountInFractionsOnANumberLine, // Step 8
    EquivalentFractionsOnANumberLine, // Step 9
    EquivalentFractionsAsBarModels // Step 10
  ]
});
export default Block;
