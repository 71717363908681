import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF18DragIntoABox from '../../../../components/question/questionFormats/QF18DragIntoABox';
import BaseLayoutPDF from '../../../../components/molecules/BaseLayoutPDF';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { StyleSheet, View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import { range } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bag',
  description: 'bag',
  keywords: ['Count', 'Group'],
  schema: z.object({
    number: z.number().int().min(1).max(8),
    object: z.enum(['Ball', 'Banana', 'Car', 'Flower', 'Orange', 'Pear', 'Red apple', 'Sweet'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 8);

    const object = getRandomFromArray([
      'Ball',
      'Banana',
      'Car',
      'Flower',
      'Orange',
      'Pear',
      'Red apple',
      'Sweet'
    ] as const);

    return { number, object };
  },
  Component: ({ question: { number, object }, translate, displayMode }) => {
    const objectSvgPaths = {
      Ball: '3D_shapes_full_colors/Spheres/Sphere_red',
      Flower: 'Flowers/Flower_Purple',
      Car: 'Car',
      Banana: 'Array_objects/Banana',
      Orange: 'Array_objects/Orange',
      Pear: 'Array_objects/Pear',
      'Red apple': 'Array_objects/AppleRed',
      Sweet: 'Base_Ten/Sweets1'
    } as const;

    const objectString = (() => {
      switch (object) {
        case 'Ball':
          return number === 1 ? translate.objects.Ball() : translate.objects.Balls();
        case 'Banana':
          return number === 1 ? translate.objects.Banana() : translate.objects.Bananas();
        case 'Car':
          return number === 1 ? translate.objects.Car() : translate.objects.Cars();
        case 'Flower':
          return number === 1 ? translate.objects.Flower() : translate.objects.Flowers();
        case 'Orange':
          return number === 1 ? translate.objects.Orange() : translate.objects.Oranges();
        case 'Pear':
          return number === 1 ? translate.objects.Pear() : translate.objects.Pears();
        case 'Red apple':
          return number === 1 ? translate.objects.Apple() : translate.objects.Apples();
        case 'Sweet':
          return number === 1 ? translate.objects.Sweet() : translate.objects.Sweets();
      }
    })();

    return displayMode === 'digital' ? (
      <QF18DragIntoABox
        title={translate.ks1Instructions.dragNumObjectIntoTheBox(number, objectString)}
        testCorrect={number}
        draggablePath={objectSvgPaths[object]}
        numberOfDraggables={9}
        moveOrCopy="move"
        hideDraggableCardBackground={false}
      />
    ) : (
      // This Q requires a fairly custom PDF version below, whereby the user has to circle the correct number of items, rather than
      // drawing the correct number of items, as most other draggable-to-PDF Qs tend to be.
      <BaseLayoutPDF
        title={translate.ks1PDFInstructions.circleNumberObject(number, objectString)}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View
                style={[
                  dimens,
                  {
                    flex: 1,
                    justifyContent: 'center'
                  }
                ]}
              >
                {
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      {
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }
                    ]}
                  >
                    {range(1, 9).map(num => (
                      <View
                        key={num}
                        style={[
                          { padding: 20 },
                          displayMode === 'markscheme' &&
                            num <= number && {
                              borderColor: 'black',
                              borderWidth: 4,
                              borderRadius: 72
                            }
                        ]}
                      >
                        <AssetSvg name={objectSvgPaths[object]} width={80} height={80} />
                      </View>
                    ))}
                  </View>
                }
              </View>
            )}
          </MeasureView>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountObjectsFromALargerGroup',
  questionTypes: [Question1],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
