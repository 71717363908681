import { StyleProp, View, ViewStyle } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import { NonEmptyArray } from 'common/src/utils/collections';
import { getRandomFromArray, seededRandom } from 'common/src/utils/random';
import { ArrayOfObjectsColors } from 'common/src/theme/colors';
import { ArrayObjectName } from 'common/src/utils/arrayObjects';
import { getArrayObjectSvgName } from 'common/src/utils/arrayObjectsImages';
import { AssetSvg } from '../../../assets/svg';

type Props = {
  /**
   * Array to display, boolean array for counters (true or false representing which to display)
   */
  arrayToDisplay: boolean[][];
  /**
   * Image to be used instead of counters in the array. Must be of type ArrayObjectName.
   * This will be rendered in a square container, even if the SVG itself isn't square.
   */
  image?: ArrayObjectName;
  /**
   * Custom color to pass to the array. Optional prop, defaults to undefined and picks a random ArrayOfObjectsColor.
   */
  color?: string;
  /**
   * Size of each array object/counter.
   * Will be calculated automatically for the array to take up as much space as possible by default,
   * with object/counters size proportionally.
   */
  counterSize?: number;
  /**
   * Styling to be passed to the array's outer container. Overrides any default styling within.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Usable dimensions for the question
   */
  dimens: Dimens;
};

/**
 * This component renders an array of objects, which will be rows x columns in size.
 * By default, these objects will be colored counters.
 */
export const IncompleteArrayOfObjects = ({
  arrayToDisplay,
  image,
  color,
  counterSize,
  containerStyle,
  dimens
}: Props) => {
  const rows = arrayToDisplay.length;
  const columns = arrayToDisplay[0].length;

  const counterColor =
    color ??
    // Default to random color, using rows and columns as a random seed
    getRandomFromArray(Object.values(ArrayOfObjectsColors) as NonEmptyArray<string>, {
      random: seededRandom({ rows, columns })
    });

  const containerMargin = 10;

  const usableWidth = dimens.width - containerMargin;
  const usableHeight = dimens.height - containerMargin;

  const maxWidthOfCounter = usableWidth / columns;
  const maxHeightOfCounter = usableHeight / rows;

  const sizeOfCounter = Math.min(maxWidthOfCounter, maxHeightOfCounter);

  return (
    <View
      style={[
        {
          width: usableWidth,
          height: usableHeight,
          flexDirection: 'column',
          justifyContent: 'center'
        },
        containerStyle
      ]}
    >
      {arrayToDisplay.map((row, index) => {
        return (
          <Row
            counterSize={counterSize ?? sizeOfCounter}
            row={row}
            key={index}
            color={counterColor}
            image={image}
          />
        );
      })}
    </View>
  );
};

const Row = ({
  counterSize,
  row,
  color,
  image
}: {
  counterSize: number;
  row: boolean[];
  color: string;
  image?: ArrayObjectName;
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      {row.map((value, index) => (
        <CounterRepresentative
          key={index}
          image={image}
          counterSize={counterSize}
          color={color}
          isVisible={value}
        />
      ))}
    </View>
  );
};

const CounterRepresentative = ({
  image,
  counterSize,
  color,
  isVisible
}: {
  image?: ArrayObjectName;
  counterSize: number;
  color: string;
  isVisible: boolean;
}) => {
  return isVisible && image ? (
    <AssetSvg
      name={getArrayObjectSvgName(image)}
      width={counterSize - 2}
      height={counterSize - 2}
      style={{ margin: 1 }}
    />
  ) : (
    <AssetSvg
      name="CounterCustomizable"
      width={counterSize - 2}
      height={counterSize - 2}
      svgProps={{ color: color }}
      style={{ margin: 1, opacity: isVisible ? 1 : 0 }}
    />
  );
};
