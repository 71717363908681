import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { isEqual } from '../../../utils/matchers';
import { useContext, useMemo } from 'react';
import { TextStyle, StyleProp } from 'react-native';
import { NumberTrackKeyboardWithState } from '../representations/Number Track/NumberTrackKeyboard';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type Props = TitleStyleProps & {
  title: string;
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  boxValues: string[];
  /** Initial user answer state if it is more complicated then standard $ans's */
  initialState?: string[];
  extraSymbol?: ExtraSymbols;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerToDisplay?: string[];
    answerText?: string;
  };
  /** whether the whole number track box should be answer box. Defaults to true */
  isWholeAnswerBox?: boolean;
};

export default function QF14CompleteNumberTrack({
  title,
  testCorrect: testCorrectProp,
  initialState,
  extraSymbol,
  boxValues,
  textStyle,
  fractionTextStyle,
  questionHeight,
  customMarkSchemeAnswer,
  isWholeAnswerBox,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answerToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <NumberTrackKeyboardWithState
                  id="numberTrack"
                  testCorrect={testCorrect}
                  boxValues={boxValues}
                  dimens={dimens}
                  textStyle={textStyle}
                  fractionTextStyle={fractionTextStyle}
                  defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                  isWholeAnswerBox={isWholeAnswerBox}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="bottomTall"
      actionPanelContents={
        <UserInput inputType="numpad" variant="wide" extraSymbol={extraSymbol} />
      }
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <NumberTrackKeyboardWithState
              id="numberTrack"
              testCorrect={testCorrect}
              boxValues={boxValues}
              dimens={dimens}
              textStyle={textStyle}
              fractionTextStyle={fractionTextStyle}
              isWholeAnswerBox={isWholeAnswerBox}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
