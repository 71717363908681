import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question3 = newQuestionContent({
  uid: 'bcL',
  description: 'bcL',
  keywords: ['Add', 'Near doubles'],
  schema: z.object({
    lowerNumber: z.number().int().min(1).max(9),
    lowerNumberFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const lowerNumber = randomIntegerInclusive(1, 9);

    const lowerNumberFirst = getRandomBoolean();
    return { lowerNumber, lowerNumberFirst };
  },

  Component: props => {
    const {
      question: { lowerNumber, lowerNumberFirst },
      translate
    } = props;

    const higherNumber = lowerNumber + 1;

    const sentence = lowerNumberFirst
      ? `${lowerNumber.toLocaleString()} + ${higherNumber.toLocaleString()} = <ans/>`
      : `${higherNumber.toLocaleString()} + ${lowerNumber.toLocaleString()} = <ans/>`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[(lowerNumber + higherNumber).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NearDoubles',
  questionTypes: [Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
