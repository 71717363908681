import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { View } from 'react-native';
import {
  displayMoney,
  numberToPennyPoundOrPence,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { numberEnum } from '../../../../utils/zod';
import { countRange } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfl',
  description: 'bfl',
  keywords: ['Coins', 'Pounds', 'Pence'],
  schema: z.object({
    incorrectAnswer: numberEnum([1, 2, 5, 10, 20, 50, 100, 200]),
    correctAnswers: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200])).length(3),
    moneyIsSelectable: z.boolean()
  }),
  simpleGenerator: () => {
    const [incorrectAnswer, ...correctAnswers] = getRandomSubArrayFromArray(
      [1, 2, 5, 10, 20, 50, 100, 200] as const,
      4
    );

    const moneyIsSelectable = getRandomBoolean();

    return { correctAnswers, incorrectAnswer, moneyIsSelectable };
  },
  Component: props => {
    const {
      question: { correctAnswers, incorrectAnswer, moneyIsSelectable },
      translate,
      displayMode
    } = props;

    const strings = [
      ...correctAnswers.map(num => totalPenceToPoundsAndPence(num)[0]),
      totalPenceToPoundsAndPence(incorrectAnswer)[0]
    ];

    const coinSvgs = displayMoney(
      strings,
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    const money = [...correctAnswers, incorrectAnswer].map((money, index) => {
      return {
        value: money,
        string: numberToPennyPoundOrPence(money, translate),
        component: moneyIsSelectable ? (
          <View>{coinSvgs[index]}</View>
        ) : (
          numberToPennyPoundOrPence(money, translate)
        )
      };
    });

    const statements = [money[0], money[1], money[2]].map(({ value, string }, index) => {
      return {
        lhsComponent: (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: displayMode === 'digital' ? 1 : undefined,
              justifyContent: 'space-evenly'
            }}
          >
            {moneyIsSelectable ? (
              <Text variant="WRN400">{string}</Text>
            ) : (
              <View>{coinSvgs[index]}</View>
            )}
            {displayMode === 'digital' ? <Text variant="WRN400">=</Text> : null}
          </View>
        ),
        correctAnswer: value
      };
    });

    const items = shuffle(money, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheCorrectValueToTheCoins()}
        pdfTitle={translate.ks1PDFInstructions.matchTheCoinsToTheCorrectValues()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items.map(({ value, component }) => ({
          value: value,
          component: component
        }))}
        actionPanelVariant="endWide"
        pdfLayout="itemsRight"
        useArrows={false}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bfm',
  description: 'bfm',
  keywords: ['Coins', 'Pounds', 'Pence'],
  schema: z.object({
    titleCoinValue: numberEnum([1, 2, 5, 10, 20, 50, 100, 200]),
    totalNumberOfTitleCoins: z.number().int().min(2).max(9),
    otherCoinValues: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200])),
    randomA: z.number().int().min(1).max(1000),
    randomB: z.number().int().min(1).max(1000)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const values = [1, 2, 5, 10, 20, 50, 100, 200] as const;

    const titleCoinValue = getRandomFromArray(values);

    const remainingValues = [...values.filter(num => num !== titleCoinValue)];

    const totalNumberOfTitleCoins = randomIntegerInclusive(2, 9);

    const otherCoinValues: (1 | 2 | 5 | 10 | 20 | 50 | 100 | 200)[] = [];

    countRange(10 - totalNumberOfTitleCoins).forEach(() => {
      const num = getRandomFromArray(remainingValues);
      if (num) otherCoinValues.push(num);
    });

    const randomA = randomIntegerInclusive(1, 1000);
    const randomB = randomIntegerInclusive(1, 1000);

    return {
      titleCoinValue,
      totalNumberOfTitleCoins,
      otherCoinValues,
      randomA,
      randomB
    };
  },
  Component: props => {
    const {
      question: { titleCoinValue, totalNumberOfTitleCoins, otherCoinValues, randomA, randomB },
      translate,
      displayMode
    } = props;

    const titleCoinName = numberToPennyPoundOrPence(titleCoinValue, translate, true);

    const titleCoinValues = countRange(totalNumberOfTitleCoins).map(() => titleCoinValue);

    const denominations = shuffle(
      [...titleCoinValues, ...otherCoinValues].map(number => totalPenceToPoundsAndPence(number)[0]),
      { random: seededRandom(props.question) }
    );
    const arrangement = getRandomBooleanArray(
      4,
      4,
      denominations.length,
      seededRandom({ randomA, randomB })
    );

    const coinSvgs = displayMoney(
      denominations,
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    let denominationIndex = 0;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyXCoinsAreThere(titleCoinName)}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            {arrangement.map((row, rowIndex) => (
              <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                {row.map((cell, colIndex) => (
                  <View
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      width: dimens.width / 4,
                      height: dimens.height / 4,
                      padding: 8
                    }}
                  >
                    {cell ? coinSvgs[denominationIndex++] : null}
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
        sentence={translate.ks1AnswerSentences.thereAreAnsXCoins(titleCoinName)}
        testCorrect={[totalNumberOfTitleCoins.toString()]}
        questionHeight={1200}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfn',
  description: 'bfn',
  keywords: ['Coins', 'Pounds', 'Pence', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    isOptionAWords: z.boolean(),
    isOptionBWords: z.boolean(),
    optionAValue: numberEnum([1, 2, 5, 10, 20, 50, 100, 200]),
    optionBValue: numberEnum([1, 2, 5, 10, 20, 50, 100, 200])
  }),
  simpleGenerator: () => {
    const optionAValue = getRandomFromArray([1, 2, 5, 10, 20, 50, 100, 200] as const);
    const optionBValue = getRandomFromArray([1, 2, 5, 10, 20, 50, 100, 200] as const);

    const isOptionAWords = getRandomBoolean();
    const isOptionBWords = optionAValue === optionBValue ? !isOptionAWords : getRandomBoolean();

    return { isOptionAWords, isOptionBWords, optionAValue, optionBValue };
  },
  Component: ({
    question: { isOptionAWords, isOptionBWords, optionAValue, optionBValue },
    translate,
    displayMode
  }) => {
    const coinSvgs = displayMoney(
      [...totalPenceToPoundsAndPence(optionAValue), ...totalPenceToPoundsAndPence(optionBValue)],
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    const lhsComponent = isOptionAWords ? (
      <Text variant="WRN400">{numberToPennyPoundOrPence(optionAValue, translate)}</Text>
    ) : (
      <View>{coinSvgs[0]}</View>
    );

    const rhsComponent = isOptionBWords ? (
      <View>
        <Text variant="WRN400">{numberToPennyPoundOrPence(optionBValue, translate)}</Text>
      </View>
    ) : (
      <View>{coinSvgs[1]}</View>
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent,
            rhsComponent,
            correctAnswer: lessThanGreaterThanOrEqualTo(optionAValue, optionBValue)
          }
        ]}
        statementStyle={{ justifyContent: 'center', gap: 16 }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseCoins',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
