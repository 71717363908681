import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';

export const cities = {
  hot: ['Paris', 'Berlin', 'Brussels', 'Zurich', 'Madrid', 'Barcelona', 'Porto', 'Rome', 'Athens'],
  cold: [
    'London',
    'Manchester',
    'Liverpool',
    'Edinburgh',
    'Glasgow',
    'Stockholm',
    'Dublin',
    'Cardiff',
    'Oslo',
    'Copenhagen',
    'Moscow'
  ]
} as const;

export const citiesSchema = z.enum([...cities.hot, ...cities.cold]);

export function getRandomCity() {
  return getRandomFromArray([...cities.hot, ...cities.cold] as const);
}

export function getHotCity() {
  return getRandomFromArray([...cities.hot] as const);
}

export function getRandomUniqueHotCities(numberOfCities: number) {
  return getRandomSubArrayFromArray([...cities.hot] as const, numberOfCities);
}

export function getColdCity() {
  return getRandomFromArray([...cities.cold] as const);
}

export function getRandomUniqueColdCities(numberOfCities: number) {
  return getRandomSubArrayFromArray([...cities.cold] as const, numberOfCities);
}

export function getRandomUniqueCities(numberOfCities: number) {
  return getRandomSubArrayFromArray([...cities.hot, ...cities.cold] as const, numberOfCities);
}

export const ukCities = [
  'London',
  'Manchester',
  'Birmingham',
  'Glasgow',
  'Edinburgh',
  'Liverpool',
  'Bristol',
  'Newcastle',
  'Leeds',
  'Sheffield',
  'Cardiff',
  'Belfast',
  'Nottingham',
  'Southampton',
  'Leicester',
  'Coventry',
  'Plymouth',
  'Aberdeen',
  'Dundee',
  'Hull'
] as const;

export const ukCitiesSchema = z.enum(ukCities);

export function getRandomUkCity() {
  return getRandomFromArray(ukCities);
}

export function getRandomUniqueUkCities(numberOfCities: number) {
  return getRandomSubArrayFromArray(ukCities, numberOfCities);
}
