import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Text from '../typography/Text';
import { colors } from '../../theme/colors';
import Button from './Button';
import { AssetSvg } from '../../assets/svg';

type Props = {
  onPress?: () => void;
  onLongPress?: () => void;
  disabled?: boolean;
  variant?: 'circle' | 'oval';
  style?: StyleProp<ViewStyle>;
};

const ContinueButton = ({
  onPress,
  onLongPress,
  disabled = false,
  variant = 'oval',
  style
}: Props) => {
  return (
    <Button
      variant={variant}
      testId="CONTINUE_BUTTON"
      baseColor={colors.seaGreenCrayola}
      onPressInColor={colors.seaGreenCrayolaPressIn}
      onPress={onPress}
      onLongPress={onLongPress}
      disabled={disabled}
      style={[variant === 'oval' ? styles.continueButtonOval : styles.continueButtonCircle, style]}
    >
      {variant === 'oval' ? (
        <View style={styles.ovalLabel}>
          <View style={{ bottom: 4 }}>
            <Text variant="ButtonLarge" style={styles.text}>
              Continue
            </Text>
          </View>
          <AssetSvg name="ArrowRight" width={48} height={49} />
        </View>
      ) : (
        <View style={styles.circleLabel}>
          <AssetSvg name="ArrowRight" width={48} height={48} />
        </View>
      )}
    </Button>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 37,
    lineHeight: 56
  },
  ovalLabel: {
    height: Button.HEIGHT,
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: 36
  },
  circleLabel: {
    alignItems: 'center'
  },
  continueButtonOval: {
    paddingTop: 24,
    paddingLeft: 59,
    paddingRight: 24
  },
  continueButtonCircle: {
    padding: 24
  }
});

export default ContinueButton;
