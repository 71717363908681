import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF64CreatePictogram from '../../../../components/question/questionFormats/QF64CreatePictogram';
import { fruitsSchema, getRandomUniqueFruits, FruitNameLC } from '../../../../utils/fruits';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'blr',
  description: 'blr',
  keywords: ['Pictogram'],
  questionHeight: 850,
  schema: z.object({
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(1).max(6),
    fruit2: z.number().int().min(1).max(6),
    fruit3: z.number().int().min(1).max(6),
    missingRow: z.number().int().min(0).max(2),
    key: numberEnum([2, 5, 10])
  }),
  simpleGenerator: () => {
    const fruit1 = randomIntegerInclusive(1, 6);
    const fruit2 = randomIntegerInclusive(1, 6);
    const missingRow = randomIntegerInclusive(0, 2);
    const key = getRandomFromArray([2, 5, 10] as const);

    const fruit3 = randomIntegerInclusive(1, 6);

    const fruits = getRandomUniqueFruits(3);

    return { fruit1, fruit2, fruit3, fruits, missingRow, key };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits, missingRow } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [...numbers];
    givenCols[missingRow] = 0;
    return (
      <QF64CreatePictogram
        title={translate.ks1Instructions.dragCirclesToCompletePictogram()}
        pdfTitle={translate.ks1PDFInstructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bls',
  description: 'bls',
  keywords: ['Pictogram'],
  questionHeight: 850,
  schema: z.object({
    key: numberEnum([2, 10]),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().min(1).max(6).step(0.5),
    fruit2: z.number().min(1).max(6).step(0.5),
    fruit3: z.number().min(1).max(6).step(0.5),
    missingRow: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const key = getRandomFromArray([2, 10] as const);

    const fruit1 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit2 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit3 =
      randomIntegerInclusive(2, 12, {
        constraint: x => {
          // Make sure at least one fruit will have a half circle
          if (fruit1 % 1 === 0 && fruit2 % 1 === 0) return x % 2 !== 0;
          return true;
        }
      }) / 2;

    const fruits = getRandomUniqueFruits(3);

    const missingRow = randomIntegerInclusive(0, 2);

    return { key, fruit1, fruit2, fruit3, fruits, missingRow };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits, missingRow } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [...numbers];
    givenCols[missingRow] = 0;

    return (
      <QF64CreatePictogram
        title={translate.ks1Instructions.dragTheShapesToCompleteThePictogram()}
        pdfTitle={translate.ks1PDFInstructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={['whole', 'half']}
        filledCols={givenCols}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'blt',
  description: 'blt',
  keywords: ['Pictogram'],
  questionHeight: 850,
  schema: z.object({
    key: numberEnum([2, 5, 10]),
    fruits: z.array(fruitsSchema).length(3),
    fruitsWhole1: z.number().int().min(1).max(6),
    fruitsWhole2: z.number().int().min(0).max(6),
    fruitsWhole3: z.number().int().min(0).max(6),
    fruitsHalf1: z.number().min(1).max(6).step(0.5),
    fruitsHalf2: z.number().min(1).max(6).step(0.5),
    fruitsHalf3: z.number().min(1).max(6).step(0.5),
    missingRows: z.array(z.number().int().min(0).max(2)).length(2)
  }),
  simpleGenerator: () => {
    const key = getRandomFromArray([2, 5, 10] as const);

    const fruitsWhole1 = randomIntegerInclusive(1, 6);
    const fruitsWhole2 = randomIntegerInclusive(0, 6);
    const fruitsWhole3 = randomIntegerInclusive(0, 6, {
      constraint: x => !(fruitsWhole2 === 0 && x === 0)
    });

    const fruitsHalf1 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruitsHalf2 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruitsHalf3 =
      randomIntegerInclusive(2, 12, {
        constraint: x => {
          // Make sure at least one fruit will have a half circle
          if (fruitsHalf1 % 1 === 0 && fruitsHalf2 % 1 === 0) return x % 2 !== 0;
          return true;
        }
      }) / 2;

    const fruits = getRandomUniqueFruits(3);

    const missingRows = randomUniqueIntegersInclusive(0, 2, 2, {
      constraint: x => {
        const isWhole = key === 5;
        const fruits = isWhole
          ? [fruitsWhole1, fruitsWhole2, fruitsWhole3]
          : [fruitsHalf1, fruitsHalf2, fruitsHalf3];

        return fruits[x] !== 0;
      }
    });

    return {
      key,
      fruits,
      fruitsWhole1,
      fruitsWhole2,
      fruitsWhole3,
      fruitsHalf1,
      fruitsHalf2,
      fruitsHalf3,
      missingRows
    };
  },
  Component: ({ question, translate }) => {
    const {
      key,
      fruits,
      fruitsWhole1,
      fruitsWhole2,
      fruitsWhole3,
      fruitsHalf1,
      fruitsHalf2,
      fruitsHalf3,
      missingRows
    } = question;

    const [fruit1, fruit2, fruit3] =
      key === 5
        ? [fruitsWhole1, fruitsWhole2, fruitsWhole3]
        : [fruitsHalf1, fruitsHalf2, fruitsHalf3];

    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [...numbers];

    missingRows.forEach(row => {
      givenCols[row] = 0;
    });

    return (
      <QF64CreatePictogram
        title={translate.ks1Instructions.dragTheShapesToCompleteThePictogram()}
        pdfTitle={translate.ks1PDFInstructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={key === 5 ? ['whole'] : ['whole', 'half']}
        filledCols={givenCols}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawPictograms25And10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
