import { View, StyleSheet } from 'react-native';
import Text from '../../../typography/Text';
import { colors } from '../../../../theme/colors';
import {
  PlaceValueColumn,
  PlaceValueColumnArray,
  placeValueColumnInfo
} from './PlaceValueCounters';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

export type HeaderVariant = 'name' | 'shortName' | 'numberTitle';

type Props = {
  columnsToShow: PlaceValueColumn[];
  headerVariant: HeaderVariant;
  showDecimalPoint: boolean;
};

const getTitle = (type: HeaderVariant, pow: (typeof PlaceValueColumnArray)[number]): string => {
  if (type === 'numberTitle')
    // Get a number string for a power of 10, localized (i.e. with comma separators in the thousands)
    // E.g. 100,000 or 0.0001
    // `1e${pow}` produces a decimal number string (in exponential form). Then we convert to a number and then
    // back into a string, but this time using `.toLocaleString` to add the comma separators.
    // We use `maximumFractionDigits` as otherwise it truncates the digits after a while, e.g. 0.0001 becomes "0".
    return Number(`1e${pow}`).toLocaleString(undefined, { maximumFractionDigits: 20 });
  else return placeValueColumnInfo[pow][type];
};

export const TableHeaderRow = ({ columnsToShow, headerVariant, showDecimalPoint }: Props) => {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode);

  return (
    <>
      {columnsToShow.map((pow, index, array) => {
        const isEndCell = index === array.length - 1;
        const isFirstDecimalColumn = pow === -1;

        return (
          <View
            style={[
              styles.cell,
              styles.topCell,
              isEndCell && styles.endCell,
              {
                backgroundColor: placeValueColumnInfo[pow].color
              }
            ]}
            key={placeValueColumnInfo[pow].name}
          >
            {showDecimalPoint && isFirstDecimalColumn && <View style={styles.decimal}></View>}
            <Text
              variant="WRN400"
              style={{
                lineHeight: displayMode === 'digital' ? 37.5 : 75,
                fontSize: displayMode === 'digital' ? 25 : 50,
                color:
                  displayMode !== 'digital' &&
                  placeValueColumnInfo[pow].textColor === colors.prussianBlue
                    ? colors.black
                    : placeValueColumnInfo[pow].textColor
              }}
            >
              {getTitle(headerVariant, pow)}
            </Text>
          </View>
        );
      })}
    </>
  );
};

function useStyles(displayMode: 'digital' | 'pdf' | 'markscheme') {
  return useMemo(
    () =>
      StyleSheet.create({
        cell: {
          flex: 1,
          height: '100%',
          borderStartWidth: 2,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: colors.prussianBlue
        },

        endCell: {
          borderRightWidth: 2
        },

        topCell: {
          borderTopWidth: 2,
          borderBottomWidth: 2
        },

        decimal: {
          height: displayMode === 'digital' ? 12 : 20,
          width: displayMode === 'digital' ? 12 : 20,
          borderRadius: 50,
          backgroundColor: colors.prussianBlue,
          position: 'absolute',
          left: displayMode === 'digital' ? -7 : -11
        }
      }),
    [displayMode]
  );
}
