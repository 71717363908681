import { isHttpSuccess, postRequestQuery } from './requests';
import { parseAndDecodeTokens, TokenState } from '../utils/parseAndDecodeTokens';
import Logger from '../utils/logger';

/** Request data for logging in. */
export type PostAuthPayload = {
  studentCode: string;
  learningGroupShareCode: string;
};

/** Validate that a pupil access code exists, and get auth tokens. */
export const validatePupilAccessCode = async (
  studentCode: string,
  schoolCode: string
): Promise<
  TokenState | 'network error' | 'http error' | 'not found' | 'invalid response' | 'unknown error'
> => {
  const payload: PostAuthPayload = { studentCode, learningGroupShareCode: schoolCode };
  const result = await postRequestQuery('auth', payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', 'validatePupilAccessCode', 'NETWORK_ERROR', {
          eventData: result
        });

        return 'network error';
      case 'http':
        switch (result.response.status) {
          case 401:
            Logger.captureEvent('error', 'validatePupilAccessCode', 'PUPIL_NOT_FOUND', {
              additionalMsg: payload.studentCode,
              eventData: result
            });

            return 'not found';
          default:
            Logger.captureEvent('error', 'validatePupilAccessCode', 'HTTP_ERROR', {
              eventData: result
            });

            return 'http error';
        }
      case 'unknown':
        Logger.captureEvent('error', 'validatePupilAccessCode', 'UNKNOWN_ERROR', {
          eventData: result
        });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  return parseAndDecodeTokens(data);
};
