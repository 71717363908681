import { View } from 'react-native';
import { useTheme } from '../../theme';
import Button from '../atoms/Button';
import Text from '../typography/Text';
import ModalCard from './ModalCard';
import { useI18nContext } from 'common/src/i18n/i18n-react';

/**
 * Simple modal which simply shows a title, some text, a confirm button and a cancel button.
 */
export default function YesNoModal({
  title,
  text,
  confirmButtonText,
  onDismiss,
  onConfirm
}: {
  title: string;
  text?: string;
  confirmButtonText?: string;
  onDismiss: () => void;
  onConfirm: () => void;
}) {
  const theme = useTheme();
  const translate = useI18nContext().LL;

  confirmButtonText = confirmButtonText ?? translate.misc.OK();

  return (
    <ModalCard
      onDismiss={onDismiss}
      containerStyle={{ alignItems: 'center', rowGap: 8, height: 324, width: 708 }}
    >
      <Text variant="WRN700">{title}</Text>
      {text !== undefined && (
        <Text variant="WRN400" style={{ fontSize: 32, lineHeight: 48, textAlign: 'center' }}>
          {text}
        </Text>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'flex-end', columnGap: 40, marginTop: 32 }}>
        <Button
          baseColor={theme.colors.background}
          onPress={onDismiss}
          style={{ width: 270, height: 96 }}
        >
          {translate.misc.Cancel()}
        </Button>
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={onConfirm}
          style={{ width: 270, height: 96 }}
        >
          {confirmButtonText}
        </Button>
      </View>
    </ModalCard>
  );
}
