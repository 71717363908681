import { StyleSheet, View } from 'react-native';
import { useContext, useId } from 'react';
import { PlaceValueColumn, placeValueColumnInfo } from './PlaceValueCounters';
import { AssetSvg } from 'common/src/assets/svg';
import { Dimens } from 'common/src/theme/scaling';
import {
  arraysHaveSameContents,
  filledArray,
  range,
  sortNumberArray
} from 'common/src/utils/collections';
import { CounterVariant } from '../types';
import { HeaderVariant, TableHeaderRow } from './TableHeaderRow';
import AutoScaleText from 'common/src/components/typography/AutoScaleText';
import { ScientificNotation } from 'common/src/utils/math';
import { colors } from 'common/src/theme/colors';
import { DisplayMode } from '../../../../contexts/displayMode';
import EasyDragAndDrop from '../../../draganddrop/EasyDragAndDrop';

type Props = {
  number?: ScientificNotation;
  columnState?: number[][];
  /**
   * Which columns to show. Avoid `number` having a non-zero digit on a column that is not shown.
   * Changing this prop is not well tested.
   */
  columnsToShow: PlaceValueColumn[];
  counterVariant: CounterVariant;
  dimens: Dimens;
  interactive?: boolean;
  /** Adds a second row of pv counters, doesn't support interactive. */
  secondNumber?: ScientificNotation;
  /**
   * Whether to show a decimal point left of the tenths column (once in the header and once in the main table body).
   * Default: true.
   */
  showDecimalPoint?: boolean;
  /**
   * What kind of headers to use.
   * - name: e.g. Hundred Thousands, Hundredths
   * - shortName: e.g. HTh, Hth
   * - number: e.g. 100,000, 0.01
   * Default: name
   */
  headerVariant?: HeaderVariant;
  counterPerRow?: number;
  /**
   * Whether to show the header
   * Default: true
   */
  header?: boolean;
  /**
   * Maximum capacity per drop zone.
   * Default: 9
   */
  maxCapacity?: number;
  /**
   * Custom number of rows to use in the PVC. Needed to override the height of rows when multiple PVCs are shown together.
   * Optional prop, defaults to undefined and just calculates how many rows are actually needed.
   */
  rowsToUse?: number;
  showZerosOnNegativeColumnPow?: boolean;
  /** Optional sizes for edge cases */
  counterSize?: number;
  headerHeight?: number;
};

// Function to create columnState from number.
export const numberToColState = (
  number: ScientificNotation,
  columnsToShow: PlaceValueColumn[],
  counterVariant: CounterVariant
): number[][] =>
  columnsToShow.map(pow =>
    filledArray(
      // grey counters all have value 1 and others have their decimal value
      counterVariant === 'greyCounter' ? 1 : number.digitAt(pow) * Math.pow(10, pow),
      number.digitAt(pow)
    )
  );

export const getDraggableItems = (
  counterVariant: CounterVariant,
  columnsToShow: PlaceValueColumn[],
  counterSize?: number
) => {
  let items: { component: JSX.Element; value: number }[] = [];
  if (counterVariant === 'greyCounter') {
    items = [
      {
        component: (
          <Counter counterPow={1} counterVariant={counterVariant} counterSize={counterSize} />
        ),
        value: 1
      }
    ];
  } else {
    columnsToShow.map(counterPow => {
      items.push({
        component: (
          <Counter
            counterPow={counterPow}
            counterVariant={counterVariant}
            counterSize={counterSize}
          />
        ),
        value: counterPow
      });
    });
  }
  return items;
};

/** Helper sub-component for a single counter. */
export const Counter = ({
  counterPow,
  counterVariant,
  counterSize
}: {
  counterPow: PlaceValueColumn;
  counterVariant: CounterVariant;
  counterSize?: number;
}) => {
  const displayMode = useContext(DisplayMode);
  if (counterVariant === 'base10Block' && (counterPow < 0 || counterPow > 3)) {
    throw new Error(
      `Counter variant 'base10Block' used outside of 1s, 10s, 100s, 1000s: ${Math.pow(
        10,
        counterPow
      )}`
    );
  }

  return (() => {
    switch (counterVariant) {
      case 'decimalCounter':
      case 'fractionCounter':
      case 'base10Block':
        return placeValueColumnInfo[counterPow][counterVariant];
      case 'greyCounter':
        return (
          <AssetSvg
            name={'Place_value/GreyCounter'}
            height={counterSize ?? (displayMode === 'digital' ? undefined : 100)}
          />
        );
      case 'number':
        throw new Error(
          'Logic error: code should be unreachable. <Counter> component with counterVariant:"number"'
        );
    }
  })();
};

export type Power = 0 | 6 | 5 | 4 | 3 | 2 | 1 | -1 | -2 | -3 | -4;

/**
 *
 * PlaceValueChart representation
 * When used with interactive === true it needs to be within an EasyDragAndDrop.Provider or EasyDragAndDrop.ProviderWithState.
 *
 */
export default function PlaceValueChart({
  number,
  secondNumber,
  columnState = [],
  columnsToShow,
  counterVariant,
  dimens,
  interactive = false,
  header = true,
  showDecimalPoint = true,
  headerVariant = 'name',
  counterPerRow,
  maxCapacity = 9,
  rowsToUse,
  showZerosOnNegativeColumnPow = false,
  counterSize,
  headerHeight
}: Props) {
  const id = useId();
  if (secondNumber && interactive) throw Error('PVC with 2 rows can not be interactive');

  // Create columnState based on number if number is passed and columnState isn't passed:
  if (number && Object.keys(columnState).length !== undefined) {
    columnState = numberToColState(number, columnsToShow, counterVariant);
  }

  const secondColumnState =
    secondNumber !== undefined
      ? numberToColState(secondNumber, columnsToShow, counterVariant)
      : undefined;

  // Everything is indexed by the indices of columnsToShow.
  const totalCols = columnsToShow.length;

  // Compute styles
  const { width, height } = dimens;
  const largestColumnLength = secondColumnState
    ? Math.max(...Object.values([...columnState, ...secondColumnState]).map(array => array.length))
    : Math.max(...Object.values(columnState).map(array => array.length));
  const totalRows = interactive ? 5 : Math.max(1, Math.ceil(largestColumnLength / 2));
  const hasSecondRow = secondNumber !== undefined;
  const isOneRow = maxCapacity === counterPerRow;
  const displayMode = useContext(DisplayMode);
  const styles = getStyles(
    width,
    height,
    totalCols,
    totalRows,
    counterPerRow,
    counterVariant,
    hasSecondRow,
    header,
    isOneRow,
    displayMode,
    rowsToUse,
    headerHeight
  );

  // The largest power to have any numbers within it.
  const highestPopCol = (() => {
    // get the index of all the populated columns
    const indexesOfNonEmpties = columnState
      .filter(val => !arraysHaveSameContents(val, []))
      .map(val => columnState.indexOf(val));
    // return the highest index
    return sortNumberArray(
      columnsToShow.filter((_val, i) => indexesOfNonEmpties.includes(i)),
      'descending'
    )[0];
  })();

  const cols = (columnRowState: number[][]) =>
    columnsToShow.map((columnPow, index, array) => {
      const isEndCell = index === array.length - 1;
      const isFirstDecimalColumn = columnPow === -1;
      const rows: JSX.Element[] = [];

      // if not interactive, lets render counters etc
      if (!interactive) {
        // counters in the column
        const counters = columnRowState[index] ?? [];
        const numCounters = counters.length;

        if (counterVariant === 'number') {
          rows.push(
            <View key={columnPow} style={styles.numberCounter}>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ width: '100%', height: 60 }}
                group={'numberCounter'}
              >
                {columnPow <= highestPopCol && !showZerosOnNegativeColumnPow
                  ? // We don't want to display 0 if columnPow is negative and numCounters is 0 and showZerosOnNegativeColumnPow is false
                    numCounters === 0 && columnPow < 0 && !showZerosOnNegativeColumnPow
                    ? ''
                    : numCounters
                  : showZerosOnNegativeColumnPow && numCounters === 0 && columnPow <= 0
                  ? numCounters
                  : columnPow <= highestPopCol && showZerosOnNegativeColumnPow
                  ? numCounters
                  : ''}
              </AutoScaleText>
            </View>
          );
        } else {
          let index = 0;
          let countersRemaining = counters.length;
          // greyCounters have an "end" actionPanelVariant so the rows fit 2 counters, the others use "bottom" can fit 3 per row
          range(1, totalRows).forEach(row => {
            if (index <= counters.length - 1) {
              rows.push(
                <View style={styles.cell} key={'col-' + index + 'cell-' + row}>
                  {range(
                    1,
                    Math.min(
                      countersRemaining,
                      counterPerRow ? counterPerRow : counterVariant === 'greyCounter' ? 2 : 3
                    )
                  ).map(row => {
                    const counterPow = ScientificNotation.fromNumber(counters[index]).e as Power;
                    const counter = (
                      <View style={styles.counterContainer} key={row}>
                        <View style={styles.counter}>
                          <Counter
                            counterPow={counterPow}
                            counterVariant={counterVariant}
                            counterSize={counterSize}
                          />
                        </View>
                      </View>
                    );
                    index += 1;
                    countersRemaining -= 1;
                    return counter;
                  })}
                </View>
              );
            }
          });
        }
      }

      return (
        <View key={columnPow} style={[styles.col, isEndCell && styles.endCol]}>
          {showDecimalPoint && isFirstDecimalColumn && <View style={styles.decimal}></View>}
          {interactive ? (
            <EasyDragAndDrop.ZoneMultiple
              style={[
                StyleSheet.absoluteFill,
                {
                  borderColor: 'transparent',
                  alignContent: 'flex-start',
                  justifyContent: 'flex-start',
                  columnGap: 0
                }
              ]}
              id={index}
              key={`${id}_${columnPow}`}
              capacity={maxCapacity}
              droppedStyle={styles.counter}
              wrapperStyle={styles.counterContainer}
            />
          ) : (
            rows
          )}
        </View>
      );
    });

  return (
    <View style={styles.tableContainer}>
      {header && (
        <View style={styles.tableHeader}>
          <TableHeaderRow
            columnsToShow={columnsToShow}
            headerVariant={headerVariant}
            showDecimalPoint={showDecimalPoint}
          />
        </View>
      )}
      <View style={styles.tableContent}>
        <View style={styles.row}>{cols(columnState)}</View>
        {secondColumnState && <View style={styles.row}>{cols(secondColumnState)}</View>}
      </View>
    </View>
  );
}

const getStyles = (
  width: number,
  height: number,
  totalCols: number,
  totalRows: number,
  counterPerRow: number | undefined,
  counterVariant: CounterVariant,
  hasSecondRow: boolean,
  header: boolean,
  isOneRow: boolean,
  displayMode: 'digital' | 'pdf' | 'markscheme',
  rowsToUse: number | undefined,
  customHeaderHeight?: number
) => {
  const containerWidth = width;
  const containerHeight = height;

  const headerHeight = customHeaderHeight ?? (displayMode === 'digital' ? 56 : 86);
  const contentHeight = containerHeight - headerHeight;

  // greyCounters have an "end" actionPanelVariant so the rows fit 2x5, the others use "bottom" can fit 3x3
  const countersPerRow = counterVariant === 'greyCounter' ? 2 : 3;
  const countersPerCol = counterVariant === 'greyCounter' ? (isOneRow ? 2 : 5) : isOneRow ? 1 : 3;

  // scale down the counter size have some space between them, slightly smaller if second row and slightly smaller
  // again if we are forcing onto one row.
  const scale = hasSecondRow ? 0.6 : isOneRow ? 0.5 : 0.7;
  const counterSizeByHeight = (containerHeight / countersPerCol) * scale;
  const counterSizeByWidth = (containerWidth / (totalCols * countersPerRow)) * scale;
  const counterSize = Math.min(counterSizeByHeight, counterSizeByWidth);

  return StyleSheet.create({
    // Contains the header and content
    tableContainer: {
      width: containerWidth,
      height: header ? containerHeight : contentHeight,
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },

    // The header
    tableHeader: {
      width: containerWidth,
      height: headerHeight,
      flexDirection: 'row'
    },

    // Rows and columns made of cells.
    tableContent: {
      width: containerWidth,
      flexDirection: 'column'
    },
    row: {
      flexDirection: 'row',
      height: hasSecondRow ? contentHeight / 2 : contentHeight
    },

    // A column
    col: {
      flex: 1,
      borderStartWidth: 2,
      borderBottomWidth: 2,
      display: 'flex',
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      justifyContent: 'flex-start'
    },
    endCol: {
      borderRightWidth: 2,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
    },

    // Decimal between columns
    decimal: {
      height: displayMode === 'digital' ? 12 : 20,
      width: displayMode === 'digital' ? 12 : 20,
      borderRadius: 50,
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      position: 'absolute',
      left: displayMode === 'digital' ? -7 : -11,
      // Need to calculate how far from the top the decimal point should be, taking into account the decimal point's height:
      top: (contentHeight - (displayMode === 'digital' ? 12 : 20)) / (hasSecondRow ? 4 : 2),
      zIndex: 999
    },

    // A cell
    cell: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: rowsToUse
        ? `${100 / rowsToUse}%`
        : counterPerRow
        ? `${100 / totalRows}%`
        : counterVariant === 'greyCounter'
        ? '20%'
        : '33.3%'
    },

    counterContainer: {
      width: counterPerRow
        ? `${100 / counterPerRow}%`
        : counterVariant === 'greyCounter'
        ? '50%'
        : '33.3%',
      alignItems: 'center'
    },

    // A single counter.
    counter: {
      width: counterSize,
      height: counterSize,
      textAlign: 'center',
      textAlignVertical: 'center'
    },

    // A number counter.
    numberCounter: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center'
    }
  });
};
