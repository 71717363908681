import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { Shapes3DIsoSchema, filterShapes3DIso, shapes3DIso } from '../../../../utils/shapes3DIso';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { sortNumberArray } from '../../../../utils/collections';
import { ALGEBRAIC_X } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aW8',
  description: 'aW8',
  keywords: ['Volume', 'Cuboid'],
  schema: z.object({
    shape: Shapes3DIsoSchema
  }),
  simpleGenerator: () => {
    const shapes = filterShapes3DIso({ hasDepth: true });

    const shape = getRandomFromArray(shapes)!;
    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const { svg, numberOfCubes } = shapes3DIso[shape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsVolumeOfCuboidEachCubeIs1cm3()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width} height={dimens.height} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aW9',
  description: 'aW9',
  keywords: ['Volume', 'Cuboid'],
  schema: z.object({
    width: z.number().int().min(3).max(6),
    length: z.number().int().min(4).max(10),
    depth: z.number().int().min(1).max(5),
    cuboid: z.enum([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ])
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 6);
    const length = randomIntegerInclusive(width + 1, 10);
    const depth = randomIntegerInclusive(1, width - 1);
    const cuboid = getRandomFromArray([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ] as const);

    return { cuboid, width, depth, length };
  },
  Component: props => {
    const {
      question: { cuboid, width, depth, length },
      translate
    } = props;
    const volume = width * depth * length;

    const labels = sortNumberArray([width, depth, length]).map(l => translate.units.numberOfCm(l));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateVolumeOfCuboid()}
        testCorrect={[volume.toString()]}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={cuboid} labels={labels} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXa',
  description: 'aXa',
  keywords: ['Volume', 'Cuboid'],
  schema: z.object({
    cubeLength: z.number().int().min(2).max(6),
    cube: z.enum(['cube1_arrow', 'cube2_arrow', 'cube3_arrow']),
    width: z.number().int().min(3).max(6),
    length: z.number().int().min(4).max(10),
    depth: z.number().int().min(1).max(5),
    cuboid: z.enum([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ]),
    isGreater: z.boolean()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const cubeLength = randomIntegerInclusive(2, 6);
    const cube = getRandomFromArray(['cube1_arrow', 'cube2_arrow', 'cube3_arrow'] as const);
    const width = randomIntegerInclusive(3, 6);
    const depth = randomIntegerInclusive(1, width - 1);
    const length = randomIntegerInclusive(width + 1, 10, {
      constraint: x => x * depth * width !== Math.pow(cubeLength, 3)
    });
    const cuboid = getRandomFromArray([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ] as const);

    const isGreater = getRandomBoolean();
    return { cubeLength, cube, cuboid, width, depth, length, isGreater };
  },
  Component: props => {
    const {
      question: { cubeLength, cube, cuboid, width, depth, length, isGreater },
      translate
    } = props;

    const labels = sortNumberArray([width, depth, length]).map(l => translate.units.numberOfCm(l));

    const cubeIsGreater = Math.pow(cubeLength, 3) > width * depth * length;

    return (
      <QF11SelectImagesUpTo4
        title={
          isGreater
            ? translate.instructions.selectShapeWithGreaterVolume()
            : translate.instructions.selectShapeWithSmallerVolume()
        }
        pdfTitle={
          isGreater
            ? translate.instructions.circleShapeWithGreaterVolume()
            : translate.instructions.circleShapeWithSmallerVolume()
        }
        numItems={2}
        renderItems={({ dimens }) => [
          {
            component: (
              <LabelledShape
                dimens={{ width: dimens.width * 0.64, height: dimens.height * 0.64 }}
                shapeName={cube}
                labels={[translate.units.numberOfCm(cubeLength)]}
              />
            ),
            value: 'cube'
          },
          {
            component: (
              <LabelledShape
                dimens={{ width: dimens.width * 0.64, height: dimens.height * 0.8 }}
                // cuboid5_3_arrows_small is a bigger version of cuboid5_3_arrows
                shapeName={cuboid === 'cuboid5_3_arrows' ? 'cuboid5_3_arrows_small' : cuboid}
                labels={labels}
              />
            ),
            value: 'cuboid'
          }
        ]}
        testCorrect={[isGreater === cubeIsGreater ? 'cube' : 'cuboid']}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aXb',
  description: 'aXb',
  keywords: ['Volume', 'Cuboid', 'Length'],
  schema: z.object({
    width: z.number().int().min(3).max(6),
    length: z.number().int().min(4).max(10),
    depth: z.number().int().min(1).max(5),
    missingIndex: z.number().int().min(0).max(2),
    cuboid: z.enum([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ])
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 6);
    const length = randomIntegerInclusive(width + 1, 10);
    const depth = randomIntegerInclusive(1, width - 1);
    const cuboid = getRandomFromArray([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ] as const);
    const missingIndex = randomIntegerInclusive(0, 2);
    return { cuboid, width, depth, length, missingIndex };
  },
  Component: props => {
    const {
      question: { cuboid, width, depth, length, missingIndex },
      translate
    } = props;
    const volume = width * depth * length;

    const lengthsSorted = sortNumberArray([width, depth, length]);
    const missingLength = lengthsSorted[missingIndex];

    const labels = lengthsSorted.map((l, i) =>
      i === missingIndex ? ALGEBRAIC_X : translate.units.numberOfCm(l)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.volumeOfCuboidIsXcm3WorkOutLengthMarkedY(
          volume.toLocaleString(),
          ALGEBRAIC_X
        )}
        testCorrect={[missingLength.toString()]}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansCm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={cuboid} labels={labels} />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXc',
  description: 'aXc',
  keywords: ['Volume', 'Cuboid'],
  schema: z.object({
    width: z.number().int().min(1).max(5),
    length: z.number().int().min(10).max(40).multipleOf(10),
    cuboid: z.enum([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ])
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(1, 5);
    const length = randomIntegerInclusiveStep(10, 40, 10);

    const cuboid = getRandomFromArray([
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows',
      'cuboid5_3_arrows',
      'cuboid6_3_arrows'
    ] as const);

    return { cuboid, width, length };
  },
  Component: props => {
    const {
      question: { cuboid, width, length },
      translate
    } = props;
    const depth = 0.5;
    const volume = width * 100 * (depth * 100) * length;

    const labels = [
      translate.units.numberOfCm(length),
      translate.units.numberOfM(depth),
      translate.units.numberOfM(width)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateVolumeOfCuboid()}
        testCorrect={[volume.toString()]}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width * 0.7 }}
            shapeName={cuboid}
            labels={labels}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aXd',
  description: 'aXd',
  keywords: ['Volume', 'Cube'],
  schema: z.object({
    length: z.number().int().min(1).max(12),
    shapeName: z.enum(['cube1_arrow', 'cube2_arrow', 'cube3_arrow'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 12);
    const shapeName = getRandomFromArray(['cube1_arrow', 'cube2_arrow', 'cube3_arrow'] as const);
    return { length, shapeName };
  },
  Component: props => {
    const {
      question: { length, shapeName },
      translate
    } = props;

    const volume = Math.pow(length, 3);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateVolumeOfCube()}
        Content={({ dimens }) => (
          <LabelledShape
            shapeName={shapeName}
            dimens={dimens}
            labels={[translate.units.numberOfCm(length)]}
          />
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[volume.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'VolumeOfACuboid',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
