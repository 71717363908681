import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import Pictogram from 'common/src/components/question/representations/Pictogram/Pictogram';
import { PictogramColors, carColors, colors } from 'common/src/theme/colors';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { dayNames, daySchema, getRandomUniqueDays } from '../../../../utils/days';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avK',
  description: 'avK',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      zeroPets: z.number().min(1).max(6),
      onePet: z.number().min(1).max(6),
      twoPets: z.number().min(1).max(6),
      threePets: z.number().min(0).max(6),
      numberOfPets: z.number().int().min(0).max(3)
    })
    .refine(
      val => arrayHasNoDuplicates([val.zeroPets, val.onePet, val.twoPets, val.threePets]),
      'zeroPets, onePet, twoPets and threePets all need to be different.'
    ),
  simpleGenerator: () => {
    const threePets = randomIntegerInclusive(0, 6);

    const [zeroPets, onePet, twoPets] = randomUniqueIntegersInclusive(1, 6, 4, {
      constraint: x => x !== threePets
    });

    const numberOfPets = randomIntegerInclusive(0, 3);

    return { zeroPets, onePet, twoPets, threePets, numberOfPets };
  },
  Component: props => {
    const {
      question: { zeroPets, onePet, twoPets, threePets, numberOfPets },
      translate
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    const selectables = [zeroPets, onePet, twoPets, threePets];

    const correctAnswer = (() => {
      switch (numberOfPets) {
        case 0:
          return zeroPets;
        case 1:
          return onePet;
        case 2:
          return twoPets;
        case 3:
          return threePets;
      }
    })() as number;

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectHowManyChildrenHaveNumPets(numberOfPets)}
        pdfTitle={translate.instructions.circleHowManyChildrenHaveNumPets(numberOfPets)}
        correctAnswer={[correctAnswer.toLocaleString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[zeroPets, onePet, twoPets, threePets]}
                columnNames={[
                  translate.tableHeaders.numberOfPets(),
                  translate.tableHeaders.numberOfChildren()
                ]}
                rowData={[
                  [(0).toLocaleString()],
                  [(1).toLocaleString()],
                  [(2).toLocaleString()],
                  [(3).toLocaleString()]
                ]}
                dimens={dimens}
                color={color}
                keyValue={translate.keys.numChildren(1)}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'avL',
  description: 'avL',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      dayA: daySchema,
      dayAAmount: z.number().min(0).max(6),
      dayB: daySchema,
      dayBAmount: z.number().min(0).max(6),
      dayC: daySchema,
      dayCAmount: z.number().min(0).max(6),
      dayD: daySchema,
      dayDAmount: z.number().min(0).max(6),
      mostOrLeast: z.enum(['most', 'least']),
      item: z.enum(['cakes', 'cookies', 'drinks', 'ice creams', 'muffins'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.dayAAmount, val.dayBAmount, val.dayCAmount, val.dayDAmount]),
      'Amounts for all days must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.dayA, val.dayB, val.dayC, val.dayD]),
      'All days must be different.'
    ),
  simpleGenerator: () => {
    const [dayA, dayB, dayC, dayD] = getRandomUniqueDays(4);

    const [dayAAmount, dayBAmount, dayCAmount, dayDAmount] = randomUniqueIntegersInclusive(0, 6, 4);

    const mostOrLeast = getRandomFromArray(['most', 'least'] as const);

    const item = getRandomFromArray([
      'cakes',
      'cookies',
      'drinks',
      'ice creams',
      'muffins'
    ] as const);

    return {
      dayA,
      dayAAmount,
      dayB,
      dayBAmount,
      dayC,
      dayCAmount,
      dayD,
      dayDAmount,
      mostOrLeast,
      item
    };
  },
  Component: props => {
    const {
      question: {
        dayA,
        dayAAmount,
        dayB,
        dayBAmount,
        dayC,
        dayCAmount,
        dayD,
        dayDAmount,
        mostOrLeast,
        item
      },
      translate
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    const dayAString = translate.time[dayA]();

    const dayBString = translate.time[dayB]();

    const dayCString = translate.time[dayC]();

    const dayDString = translate.time[dayD]();

    const [itemString, tableHeader, keyString] = (() => {
      switch (item) {
        case 'cakes':
          return [
            translate.food.Cakes(0),
            translate.tableHeaders.numberOfCakesSold(),
            translate.keys.numCakes(1)
          ];
        case 'cookies':
          return [
            translate.food.Cookies(0),
            translate.tableHeaders.numberOfCookiesSold(),
            translate.keys.numCookies(1)
          ];
        case 'drinks':
          return [
            translate.drink.Drinks(0),
            translate.tableHeaders.numberOfDrinksSold(),
            translate.keys.numDrinks(1)
          ];
        case 'ice creams':
          return [
            translate.food.IceCreams(0),
            translate.tableHeaders.IceCreamsSold(),
            translate.keys.numIceCreams(1)
          ];
        case 'muffins':
          return [
            translate.food.Muffins(0),
            translate.tableHeaders.numberOfMuffinsSold(),
            translate.keys.numMuffins(1)
          ];
      }
    })();

    const data = [
      {
        day: dayA,
        string: dayAString,
        value: dayAAmount
      },
      {
        day: dayB,
        string: dayBString,
        value: dayBAmount
      },
      {
        day: dayC,
        string: dayCString,
        value: dayCAmount
      },
      {
        day: dayD,
        string: dayDString,
        value: dayDAmount
      }
    ].sort((a, b) => {
      const indexA = dayNames.indexOf(a.day);
      const indexB = dayNames.indexOf(b.day);
      return indexA - indexB;
    });

    const selectables = [data[0].string, data[1].string, data[2].string, data[3].string];

    const answer =
      mostOrLeast === 'most'
        ? data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0])
        : data.reduce((min, obj) => (obj.value < min.value ? obj : min), data[0]);

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          mostOrLeast === 'most'
            ? translate.instructions.selectDayOnWhichMostXSold(itemString)
            : translate.instructions.selectDayOnWhichLeastXSold(itemString)
        }
        pdfTitle={
          mostOrLeast === 'most'
            ? translate.instructions.circleDayOnWhichMostXSold(itemString)
            : translate.instructions.circleDayOnWhichLeastXSold(itemString)
        }
        correctAnswer={[answer.string]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[data[0].value, data[1].value, data[2].value, data[3].value]}
                columnNames={[translate.keywords.Day(), tableHeader]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                color={color}
                keyValue={keyString}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'avM',
  description: 'avM',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      selectedDay: daySchema,
      selectedDayCounters: z.number().min(1).max(6),
      otherDayA: daySchema,
      otherDayACounters: z.number().min(1).max(6),
      otherDayB: daySchema,
      otherDayBCounters: z.number().min(1).max(6),
      otherDayC: daySchema,
      otherDayCCounters: z.number().min(1).max(6),
      amountPerCounter: z.number().min(2).max(5),
      item: z.enum(['cakes', 'cookies', 'drinks', 'ice creams', 'muffins'])
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.selectedDayCounters,
          val.otherDayACounters,
          val.otherDayBCounters,
          val.otherDayCCounters
        ]),
      'Counters for all days must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.selectedDay, val.otherDayA, val.otherDayB, val.otherDayC]),
      'All days must be different.'
    ),
  simpleGenerator: () => {
    const [selectedDay, otherDayA, otherDayB, otherDayC] = getRandomUniqueDays(4);

    const [selectedDayCounters, otherDayACounters, otherDayBCounters, otherDayCCounters] =
      randomUniqueIntegersInclusive(1, 6, 4);

    const amountPerCounter = randomIntegerInclusive(2, 5);

    const item = getRandomFromArray([
      'cakes',
      'cookies',
      'drinks',
      'ice creams',
      'muffins'
    ] as const);

    return {
      selectedDay,
      selectedDayCounters,
      otherDayA,
      otherDayACounters,
      otherDayB,
      otherDayBCounters,
      otherDayC,
      otherDayCCounters,
      amountPerCounter,
      item
    };
  },
  Component: props => {
    const {
      question: {
        selectedDay,
        selectedDayCounters,
        otherDayA,
        otherDayACounters,
        otherDayB,
        otherDayBCounters,
        otherDayC,
        otherDayCCounters,
        amountPerCounter,
        item
      },
      translate
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    const selectedDayString = translate.time[selectedDay]();

    const otherDayAString = translate.time[otherDayA]();

    const otherDayBString = translate.time[otherDayB]();

    const otherDayCString = translate.time[otherDayC]();

    const [itemString, tableHeader, keyString] = (() => {
      switch (item) {
        case 'cakes':
          return [
            translate.food.Cakes(0),
            translate.tableHeaders.numberOfCakesSold(),
            translate.keys.numCakes(amountPerCounter)
          ];
        case 'cookies':
          return [
            translate.food.Cookies(0),
            translate.tableHeaders.numberOfCookiesSold(),
            translate.keys.numCookies(amountPerCounter)
          ];
        case 'drinks':
          return [
            translate.drink.Drinks(0),
            translate.tableHeaders.numberOfDrinksSold(),
            translate.keys.numDrinks(amountPerCounter)
          ];
        case 'ice creams':
          return [
            translate.food.IceCreams(0),
            translate.tableHeaders.IceCreamsSold(),
            translate.keys.numIceCreams(amountPerCounter)
          ];
        case 'muffins':
          return [
            translate.food.Muffins(0),
            translate.tableHeaders.numberOfMuffinsSold(),
            translate.keys.numMuffins(amountPerCounter)
          ];
      }
    })();

    const data = [
      {
        day: selectedDay,
        string: selectedDayString,
        counters: selectedDayCounters,
        value: selectedDayCounters * amountPerCounter
      },
      {
        day: otherDayA,
        string: otherDayAString,
        counters: otherDayACounters,
        value: otherDayACounters * amountPerCounter
      },
      {
        day: otherDayB,
        string: otherDayBString,
        counters: otherDayBCounters,
        value: otherDayBCounters * amountPerCounter
      },
      {
        day: otherDayC,
        string: otherDayCString,
        counters: otherDayCCounters,
        value: otherDayCCounters * amountPerCounter
      }
    ].sort((a, b) => {
      const indexA = dayNames.indexOf(a.day);
      const indexB = dayNames.indexOf(b.day);
      return indexA - indexB;
    });

    const selectables = [
      data[0].value.toLocaleString(),
      data[1].value.toLocaleString(),
      data[2].value.toLocaleString(),
      data[3].value.toLocaleString()
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectHowManyXWereSoldOnDay(itemString, selectedDayString)}
        pdfTitle={translate.instructions.circleHowManyXWereSoldOnDay(itemString, selectedDayString)}
        correctAnswer={[(selectedDayCounters * amountPerCounter).toLocaleString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[
                  data[0].counters,
                  data[1].counters,
                  data[2].counters,
                  data[3].counters
                ]}
                columnNames={[translate.keywords.Day(), tableHeader]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                color={color}
                keyValue={keyString}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'avN',
  description: 'avN',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      selectedColour: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      selectedColourCounters: z.number().min(1).max(6).multipleOf(0.5),
      otherColourA: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      otherColourACounters: z.number().min(1).max(6).multipleOf(0.5),
      otherColourB: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      otherColourBCounters: z.number().min(1).max(6).multipleOf(0.5),
      otherColourC: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      otherColourCCounters: z.number().min(1).max(6).multipleOf(0.5),
      amountPerCounter: numberEnum([2, 4, 10])
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.selectedColourCounters,
          val.otherColourACounters,
          val.otherColourBCounters,
          val.otherColourCCounters
        ]),
      'Counters for all colours must be different.'
    )
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.selectedColour,
          val.otherColourA,
          val.otherColourB,
          val.otherColourC
        ]),
      'All colours must be different.'
    ),
  simpleGenerator: () => {
    const [selectedColour, otherColourA, otherColourB, otherColourC] = getRandomSubArrayFromArray(
      ['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'] as const,
      4
    );

    const [selectedColourCounters, otherColourACounters, otherColourBCounters] =
      randomUniqueIntegersInclusiveStep(10, 60, 5, 3).map(num => num / 10);

    // If all other counters are whole numbers, this must be enforced to be x.5:
    const otherColourCCounters =
      selectedColourCounters % 1 === 0 &&
      otherColourACounters % 1 === 0 &&
      otherColourBCounters % 1 === 0
        ? randomIntegerInclusiveStep(15, 55, 5, {
            constraint: x =>
              x % 10 !== 0 &&
              // This array check should not be needed but including just to be safe:
              arrayHasNoDuplicates([
                x / 10,
                selectedColourCounters,
                otherColourACounters,
                otherColourBCounters
              ])
          }) / 10
        : randomIntegerInclusiveStep(10, 60, 5, {
            constraint: x =>
              arrayHasNoDuplicates([
                x / 10,
                selectedColourCounters,
                otherColourACounters,
                otherColourBCounters
              ])
          }) / 10;

    const amountPerCounter = getRandomFromArray([2, 4, 10] as const);

    return {
      selectedColour,
      selectedColourCounters,
      otherColourA,
      otherColourACounters,
      otherColourB,
      otherColourBCounters,
      otherColourC,
      otherColourCCounters,
      amountPerCounter
    };
  },
  Component: props => {
    const {
      question: {
        selectedColour,
        selectedColourCounters,
        otherColourA,
        otherColourACounters,
        otherColourB,
        otherColourBCounters,
        otherColourC,
        otherColourCCounters,
        amountPerCounter
      },
      translate
    } = props;

    const data = shuffle(
      [
        {
          string: translate.colors[selectedColour](),
          counters: selectedColourCounters
        },
        {
          string: translate.colors[otherColourA](),
          counters: otherColourACounters
        },
        {
          string: translate.colors[otherColourB](),
          counters: otherColourBCounters
        },
        {
          string: translate.colors[otherColourC](),
          counters: otherColourCCounters
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const valueA = selectedColourCounters * amountPerCounter;
    const valueB = Math.round(selectedColourCounters);
    const valueC = number(math.evaluate(`(${selectedColourCounters} + 0.5) * ${amountPerCounter}`));

    const incorrectColour = getRandomFromArray(
      // Filters need to be applied to avoid duplicate selectables:
      [otherColourACounters, otherColourBCounters, otherColourBCounters].filter(
        val =>
          val * amountPerCounter !== valueA &&
          val * amountPerCounter !== valueB &&
          val * amountPerCounter !== valueC
      ),
      {
        random: seededRandom(props.question)
      }
    ) as number;

    const selectables = shuffle(
      [
        ['A', valueA.toLocaleString()],
        ['B', valueB.toLocaleString()],
        ['C', valueC.toLocaleString()],
        ['D', `${(incorrectColour * amountPerCounter).toLocaleString()}`]
      ],
      // Needs to be a different seed to the others to prevent the correct selectable and its matching data always aligning:
      { random: seededRandom(selectedColour) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectHowManyCarsInCarParkAreColour(
          translate.colors[selectedColour]()
        )}
        pdfTitle={translate.instructions.circleHowManyCarsInCarParkAreColour(
          translate.colors[selectedColour]()
        )}
        correctAnswer={['A']}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[
                  data[0].counters,
                  data[1].counters,
                  data[2].counters,
                  data[3].counters
                ]}
                columnNames={[
                  translate.tableHeaders.Colour(),
                  translate.tableHeaders.numberOfCarsInCarPark()
                ]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                rowColors={[
                  carColors[selectedColour],
                  carColors[selectedColour],
                  carColors[selectedColour],
                  carColors[selectedColour]
                ]}
                keyValue={translate.keys.numCars(amountPerCounter)}
                keyColor={carColors[selectedColour]}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'avO',
  description: 'avO',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      colourA: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      colourACounters: z.number().min(1).max(6).multipleOf(0.5),
      colourB: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      colourBCounters: z.number().min(1).max(6).multipleOf(0.5),
      colourC: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      colourCCounters: z.number().min(1).max(6).multipleOf(0.5),
      colourD: z.enum([
        'Black',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Purple',
        'Red',
        'Silver',
        'Yellow'
      ]),
      colourDCounters: z.number().min(1).max(6).multipleOf(0.5),
      amountPerCounter: numberEnum([2, 4, 10]),
      incorrectAmountPerCounter: z.number().min(2).max(10).multipleOf(2)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.colourACounters % 1 !== 0 &&
            val.colourBCounters % 1 !== 0 &&
            val.colourCCounters % 1 !== 0 &&
            val.colourDCounters % 1 !== 0
        ]),
      'Counters for all colours cannot all be whole numbers.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.colourA, val.colourB, val.colourC, val.colourD]),
      'All colours must be different.'
    )
    .refine(
      val => val.amountPerCounter !== val.incorrectAmountPerCounter,
      'amountPerCounter and incorrectAmountPerCounter cannot be the same.'
    )
    .refine(
      val =>
        val.colourACounters + val.colourBCounters + val.colourCCounters + val.colourDCounters <= 12,
      'Total of all counters should be less than or equal to 12'
    ),
  simpleGenerator: () => {
    const [colourA, colourB, colourC, colourD] = getRandomSubArrayFromArray(
      ['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'] as const,
      4
    );

    const { colourACounters, colourBCounters, colourCCounters, colourDCounters, amountPerCounter } =
      rejectionSample(
        () => {
          const colourACounters = randomIntegerInclusiveStep(10, 60, 5) / 10;

          const colourBCounters = randomIntegerInclusiveStep(10, 60, 5) / 10;

          const colourCCounters = randomIntegerInclusiveStep(10, 60, 5) / 10;

          // If all other consts are whole numbers, this must be a half:
          const colourDCounters =
            colourACounters % 1 === 0 && colourBCounters % 1 === 0 && colourCCounters % 1 === 0
              ? randomIntegerInclusiveStep(15, 55, 5, {
                  constraint: x => x % 10 !== 0
                }) / 10
              : randomIntegerInclusiveStep(10, 60, 5) / 10;

          const amountPerCounter = getRandomFromArray([2, 4, 10] as const);

          return {
            colourACounters,
            colourBCounters,
            colourCCounters,
            colourDCounters,
            amountPerCounter
          };
        },
        ({
          colourACounters,
          colourBCounters,
          colourCCounters,
          colourDCounters,
          amountPerCounter
        }) =>
          (colourACounters + colourBCounters + colourCCounters + colourDCounters) *
            amountPerCounter <=
          amountPerCounter * 12
      );

    const incorrectAmountPerCounter = randomIntegerInclusiveStep(2, 10, 2, {
      constraint: x => x !== amountPerCounter
    });

    return {
      colourA,
      colourACounters,
      colourB,
      colourBCounters,
      colourC,
      colourCCounters,
      colourD,
      colourDCounters,
      amountPerCounter,
      incorrectAmountPerCounter
    };
  },
  Component: props => {
    const {
      question: {
        colourA,
        colourACounters,
        colourB,
        colourBCounters,
        colourC,
        colourCCounters,
        colourD,
        colourDCounters,
        amountPerCounter,
        incorrectAmountPerCounter
      },
      translate
    } = props;

    const data = shuffle(
      [
        {
          colour: colourA,
          string: translate.colors[colourA](),
          counters: colourACounters
        },
        {
          colour: colourB,
          string: translate.colors[colourB](),
          counters: colourBCounters
        },
        {
          colour: colourC,
          string: translate.colors[colourC](),
          counters: colourCCounters
        },
        {
          colour: colourD,
          string: translate.colors[colourD](),
          counters: colourDCounters
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const valueA = number(
      math.evaluate(
        `(${colourACounters} + ${colourBCounters} + ${colourCCounters} + ${colourDCounters}) * ${amountPerCounter}`
      )
    );

    const valueB = Math.round(
      colourACounters + colourBCounters + colourCCounters + colourDCounters
    );

    const valueC =
      (Math.round(colourACounters) +
        Math.round(colourBCounters) +
        Math.round(colourCCounters) +
        Math.round(colourDCounters)) *
      amountPerCounter;

    const valueD = number(
      math.evaluate(
        `(${colourACounters} + ${colourBCounters} + ${colourCCounters} + ${colourDCounters}) * ${incorrectAmountPerCounter}`
      )
    );

    const selectables = shuffle(
      [
        ['A', valueA.toLocaleString()],
        ['B', valueB.toLocaleString()],
        ['C', valueC.toLocaleString()],
        ['D', valueD.toLocaleString()]
      ],
      // Needs to be a different seed to the others to prevent the correct selectable and its matching data always aligning:
      { random: seededRandom({ amountPerCounter, incorrectAmountPerCounter }) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectHowManyCarsThereAreInTotal()}
        pdfTitle={translate.instructions.circleHowManyCarsThereAreInTotal()}
        correctAnswer={['A']}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[
                  data[0].counters,
                  data[1].counters,
                  data[2].counters,
                  data[3].counters
                ]}
                columnNames={[
                  translate.tableHeaders.Colour(),
                  translate.tableHeaders.numberOfCarsInCarPark()
                ]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                rowColors={[
                  carColors[colourA],
                  carColors[colourA],
                  carColors[colourA],
                  carColors[colourA]
                ]}
                keyValue={translate.keys.numCars(amountPerCounter)}
                keyColor={carColors[colourA]}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'avP',
  description: 'avP',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z
    .object({
      countersA: z.number().min(2).max(6),
      countersB: z.number().min(1).max(5),
      countersC: z.number().min(1).max(6),
      countersD: z.number().min(1).max(6),
      colours: z
        .array(
          z.enum(['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'])
        )
        .length(4),
      amountPerCounter: numberEnum([2, 4, 10])
    })
    .refine(val => val.countersA > val.countersB, 'countersA must be greater than countersB.'),
  simpleGenerator: () => {
    const countersA = randomIntegerInclusive(2, 6);

    // Must be less than countersA:
    const countersB = randomIntegerInclusive(1, 5, {
      constraint: x => x < countersA
    });

    const countersC = randomIntegerInclusive(1, 6);

    const countersD = randomIntegerInclusive(1, 6);

    const colours = getRandomSubArrayFromArray(
      ['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'] as const,
      4
    );

    const amountPerCounter = getRandomFromArray([2, 4, 10] as const);

    return {
      countersA,
      countersB,
      countersC,
      countersD,
      colours,
      amountPerCounter
    };
  },
  Component: props => {
    const {
      question: { countersA, countersB, countersC, countersD, colours, amountPerCounter },
      translate
    } = props;

    const [colourA, colourB, colourC, colourD] = colours;

    const colourAString = translate.colors[colourA]();

    const colourBString = translate.colors[colourB]();

    const colourCString = translate.colors[colourC]();

    const colourDString = translate.colors[colourD]();

    const data = shuffle(
      [
        {
          colour: colors.pacificBlue,
          string: colourAString,
          counters: countersA
        },
        {
          colour: colors.pacificBlue,
          string: colourBString,
          counters: countersB
        },
        {
          colour: colors.pacificBlue,
          string: colourCString,
          counters: countersC
        },
        {
          colour: colors.pacificBlue,
          string: colourDString,
          counters: countersD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const valueA = number(
      math.evaluate(`(${countersA} * ${amountPerCounter}) - (${countersB} * ${amountPerCounter})`)
    );

    const valueB = number(math.evaluate(`${countersA} + ${countersB}`));

    const valueC = number(
      math.evaluate(`(${countersA} * ${amountPerCounter}) + (${countersB} * ${amountPerCounter})`)
    );

    const valueD = number(math.evaluate(`${countersA} - ${countersB}`));

    const selectables = shuffle(
      [
        ['A', valueA.toLocaleString()],
        ['B', valueB.toLocaleString()],
        ['C', valueC.toLocaleString()],
        ['D', valueD.toLocaleString()]
      ],
      // Needs to be a different seed to the others to prevent the correct selectable and its matching data always aligning:
      { random: seededRandom({ amountPerCounter, countersA, countersB }) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectHowManyMoreCarsAreXThanY(colourAString, colourBString)}
        pdfTitle={translate.instructions.circleHowManyMoreCarsAreXThanY(
          colourAString,
          colourBString
        )}
        correctAnswer={['A']}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[
                  data[0].counters,
                  data[1].counters,
                  data[2].counters,
                  data[3].counters
                ]}
                columnNames={[
                  translate.tableHeaders.Colour(),
                  translate.tableHeaders.numberOfCarsInCarPark()
                ]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                rowColors={[
                  colors.pacificBlue,
                  colors.pacificBlue,
                  colors.pacificBlue,
                  colors.pacificBlue
                ]}
                keyValue={translate.keys.numCars(amountPerCounter)}
                keyColor={colors.pacificBlue}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretPictograms',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
