import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath } from '../../../../utils/threeDShapes';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import deepEqual from 'react-fast-compare';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

////
// Questions
////

const triangles = [
  'triangle',
  'right angle triangle',
  'long right angle triangle',
  'scalene triangle',
  'narrow isosceles triangle',
  'wide isosceles triangle'
] as const;

const nonTriangles = ['square', 'rectangle', 'pentagon', 'hexagon', 'heptagon', 'octagon'] as const;

const shapes2d = [...triangles, ...nonTriangles] as const;

const pyramids = ['Square_pyramid', 'Triangle_pyramid'] as const;

const nonPyramids = ['Cube', 'Cuboid', 'Cylinder', 'Sphere'] as const;

const shapes3d = [...pyramids, ...nonPyramids] as const;

const allShapes = [...shapes2d, ...shapes3d] as const;

const colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const shapeToSvg = (shape: (typeof allShapes)[number], color: (typeof colors)[number]) => {
  switch (shape) {
    case 'triangle':
    case 'right angle triangle':
    case 'long right angle triangle':
    case 'scalene triangle':
    case 'narrow isosceles triangle':
    case 'wide isosceles triangle':
    case 'square':
    case 'rectangle':
    case 'pentagon':
    case 'hexagon':
    case 'heptagon':
    case 'octagon':
      return getShapeSvgByShapeAndColor(shape, color);
    case 'Cube':
    case 'Cuboid':
    case 'Cylinder':
    case 'Sphere':
    case 'Square_pyramid':
    case 'Triangle_pyramid':
      return get3DShapeFullColorsSVGPath(color, shape);
  }
};

const shapeToString = (
  translate: TranslationFunctions,
  shape: (typeof allShapes)[number],
  number: number
) => {
  switch (shape) {
    case 'triangle':
    case 'right angle triangle':
    case 'long right angle triangle':
    case 'scalene triangle':
    case 'narrow isosceles triangle':
    case 'wide isosceles triangle':
      return translate.shapes.triangles(number);
    case 'square':
      return translate.shapes.squares(number);
    case 'rectangle':
      return translate.shapes.rectangles(number);
    case 'pentagon':
      return translate.shapes.pentagons(number);
    case 'hexagon':
      return translate.shapes.hexagons(number);
    case 'heptagon':
      return translate.shapes.heptagons(number);
    case 'octagon':
      return translate.shapes.octagons(number);
    case 'Cube':
      return translate.shapes.cubes(number);
    case 'Cuboid':
      return translate.shapes.cuboids(number);
    case 'Cylinder':
      return translate.shapes.cylinders(number);
    case 'Sphere':
      return translate.shapes.spheres(number);
    case 'Square_pyramid':
    case 'Triangle_pyramid':
      return translate.shapes.pyramids(number);
  }
};

const Question1 = newQuestionContent({
  uid: 'bhz',
  description: 'bhz',
  keywords: ['Sort', '3-D shapes', '2-D shapes'],
  schema: z.object({
    items: z
      .array(
        z.object({
          shape: z.enum(allShapes),
          color: z.enum(colors)
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
  }),
  simpleGenerator: () => {
    const total3DShapes = randomIntegerInclusive(3, 6);

    const total2DShapes = 9 - total3DShapes;

    // We only want a maximum of two types of the same shape - triangles are the only shape this could be an issue for,
    // so we need to select two ones that could be generated and discard the others.
    const possibleTriangles = getRandomSubArrayFromArray(triangles, 2);

    const selected3DShapes = getRandomSubArrayFromArray(shapes3d, total3DShapes);

    const selected2DShapes = getRandomSubArrayFromArray(
      [...possibleTriangles, ...nonTriangles],
      total2DShapes
    );

    const selectedShapes = [...selected2DShapes, ...selected3DShapes];

    const selectedColors =
      // Some colors will have to repeat - by using the colors array twice, these can only repeat once.
      getRandomSubArrayFromArray([...colors, ...colors], 9);

    const items = shuffle(
      countRange(9).map(id => ({ shape: selectedShapes[id], color: selectedColors[id] }))
    );

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[translate.keywords['2-D shapes'](), translate.keywords['3-D shapes']()]}
        testCorrect={userAnswer =>
          userAnswer[0].every(item => shapes2d.includes(item.shape as (typeof shapes2d)[number])) &&
          userAnswer[1].every(item => shapes3d.includes(item.shape as (typeof shapes3d)[number]))
        }
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView key={index}>
                {dimens => (
                  <AssetSvg
                    name={shapeToSvg(shape.shape, shape.color)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            items.filter(item => shapes2d.includes(item.shape as (typeof shapes2d)[number])),
            items.filter(item => shapes3d.includes(item.shape as (typeof shapes3d)[number]))
          ]
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bhA',
  description: 'bhA',
  keywords: ['3-D shapes', '2-D shapes', 'Shapes'],
  schema: z
    .object({
      shape: z.enum(allShapes),
      color: z.enum(colors),
      items: z
        .array(z.enum(allShapes))
        .length(4)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => val.items.includes(val.shape), 'shape must be included as one of the items.'),
  simpleGenerator: () => {
    const color = getRandomFromArray(colors);

    // All triangles will use the string 'triangle', so only one can potentially be used in a generation:
    const selectedTriangle = getRandomFromArray(triangles);

    // All pyramids will use the string 'pyramid', so only one can potentially be used in a generation:
    const selectedPyramid = getRandomFromArray(pyramids);

    const possibleShapes = [selectedTriangle, selectedPyramid, ...nonTriangles, ...nonPyramids];

    const [shape, itemA, itemB, itemC] = getRandomSubArrayFromArray(possibleShapes, 4);

    const items = shuffle([shape, itemA, itemB, itemC]);

    return { shape, color, items };
  },
  Component: props => {
    const {
      question: { shape, color, items },
      translate
    } = props;

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToMatchTheNameToTheShape()}
        pdfTitle={translate.ks1PDFInstructions.tickTheNameOfTheShape()}
        items={items.map(item => ({ component: shapeToString(translate, item, 1), value: item }))}
        itemsMaxLines={2}
        pdfLayout="itemsBottom"
        statements={[
          {
            lhsComponent: <AssetSvg name={shapeToSvg(shape, color)} height={150} />,
            correctAnswer: shape
          }
        ]}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Recognise2DAnd3DShapes',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
