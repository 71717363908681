import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { az1, az2 } from '../../../Year 5/Summer/Shape/3EstimateAngles';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import { Dimens } from '../../../../theme/scaling';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isInRange } from '../../../../utils/matchers';
import { ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, DEGREES } from '../../../../constants';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { az9 } from 'common/src/SchemeOfLearning/Year 5/Summer/Shape/4MeasureAnglesUpTo180';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDY',
  description: 'aDY',
  keywords: ['Angles', 'Estimate', 'Reflex'],
  schema: z.object({
    angle: z.enum(['acute', 'obtuse', 'reflex']),
    acute: z.number().int().min(10).max(80),
    obtuse: z.number().int().min(100).max(170),
    reflex: z.number().int().min(190).max(350),
    wrong: numberEnum([90, 180, 360])
  }),
  simpleGenerator: () => {
    const angle = getRandomFromArray(['acute', 'obtuse', 'reflex'] as const);
    const acute = randomIntegerInclusive(10, 80);
    const obtuse = randomIntegerInclusive(100, 170);
    const reflex = randomIntegerInclusive(190, 350);
    const wrong = getRandomFromArray([90, 180, 360] as const);

    return {
      angle,
      acute,
      obtuse,
      reflex,
      wrong
    };
  },
  Component: ({ question, translate }) => {
    const { angle, acute, obtuse, reflex, wrong } = question;

    const selectables = (dimens: Dimens) =>
      shuffle(
        [
          {
            component: <AngleFromLines degrees={[0, acute]} dimens={dimens} />,
            value: 'acute'
          },
          {
            component: <AngleFromLines degrees={[0, obtuse]} dimens={dimens} />,
            value: 'obtuse'
          },
          {
            component: <AngleFromLines degrees={[0, reflex]} dimens={dimens} />,
            value: 'reflex'
          },
          {
            component: <AngleFromLines degrees={[0, wrong]} dimens={dimens} />,
            value: 'wrong'
          }
        ],
        {
          random: seededRandom(question)
        }
      );

    const title = {
      acute: 'selectAcuteAngle',
      obtuse: 'selectObtuseAngle',
      reflex: 'selectTheReflexAngle'
    } as const;

    const pdfTitle = {
      acute: 'circleAcuteAngle',
      obtuse: 'circleObtuseAngle',
      reflex: 'circleTheReflexAngle'
    } as const;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions[title[angle]]()}
        pdfTitle={translate.instructions[pdfTitle[angle]]()}
        numItems={4}
        renderItems={({ dimens }) =>
          selectables({ width: dimens.width, height: dimens.height - 40 })
        }
        testCorrect={[angle]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = { ...az1, uid: 'aDZ', description: 'aDZ' as const };

const Question3 = { ...az2, uid: 'aD0', description: 'aD0' as const };

const Question4 = newQuestionContent({
  uid: 'aD1',
  description: 'aD1',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(6)
      .max(179)
      .refine(val => val % 5 !== 0, 'Angle must not be a multiple of 5'),
    angleFromLeftOrRight: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(6, 179, {
      constraint: x => x % 5 !== 0
    });

    const angleFromLeftOrRight = getRandomFromArray(['left', 'right'] as const);

    return { angle, angleFromLeftOrRight };
  },
  Component: ({ question: { angle, angleFromLeftOrRight }, translate, displayMode }) => {
    // Must never accept any answer that ends in a 0 or 5:
    const [lowerBound, upperBound] =
      angle % 10 === 1 || angle % 10 === 6
        ? [angle, angle + 1]
        : angle % 10 === 4 || angle % 10 === 9
        ? [angle - 1, angle]
        : [angle - 1, angle + 1];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheSizeOfTheAngle()}
        sentence={translate.answerSentences.ansDeg()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        testCorrect={userAnswer => isInRange(lowerBound, upperBound)(parseFloat(userAnswer[0]))}
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{
          answersToDisplay: [angle.toLocaleString()],
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            lowerBound,
            upperBound
          )
        }}
        Content={
          <AngleFromLines
            lineLength={displayMode === 'digital' ? 250 : 400}
            degrees={angleFromLeftOrRight === 'left' ? [-90, angle - 90] : [90 - angle, 90]}
            protractor
          />
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = { ...az9, uid: 'aD2', description: 'aD2' as const };

const Question6 = newQuestionContent({
  uid: 'aD3',
  description: 'aD3',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z
    .object({
      angle1: z.number().int().min(10).max(50).multipleOf(10),
      angle2: z.number().int().min(95).max(160).multipleOf(5)
    })
    .refine(
      val => val.angle1 + val.angle2 <= 170,
      'angle1 + angle2 must be less than or equal to 170'
    ),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusiveStep(10, 50, 10);

    const angle2 = randomIntegerInclusiveStep(95, 170 - angle1, 5);

    return { angle1, angle2 };
  },
  Component: props => {
    const {
      question: { angle1, angle2 },
      translate
    } = props;

    const angle3 = 180 - (angle1 + angle2);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutSizesOfAngles()}
        sentences={[
          `${ALGEBRAIC_A} = <ans/>${DEGREES}`,
          `${ALGEBRAIC_B} = <ans/>${DEGREES}`,
          `${ALGEBRAIC_C} = <ans/>${DEGREES}`
        ]}
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfDirection="column"
        testCorrect={[[angle1.toString()], [angle2.toString()], [angle3.toString()]]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.8, dimens.width * 0.8)}
              angles={[
                { angle: angle1, innerLabel: ALGEBRAIC_A },
                { angle: angle2, innerLabel: ALGEBRAIC_B },
                { angle: angle3, innerLabel: ALGEBRAIC_C }
              ]}
              arcSizeGroups={[[0], [1], [2]]}
              showProtractor
              labelPositionTransformScale={1.6}
            />
          );
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureAndClassifyAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
