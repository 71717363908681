import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import { ActionPanelVariant } from './BaseLayout';
import { colors } from 'common/src/theme/colors';
import { QuestionNavigationContext } from '../../SchemeOfLearning/Question';

type Props = {
  children?: ElementOrRenderFunction;
  variant?: ActionPanelVariant;
  style?: StyleProp<ViewStyle>;
};

/**
 * Action panel for the right-edge of the screen, containing navigation buttons at the bottom. This one is wide.
 */
export default function ActionPanel({ children, variant = 'end', style }: Props) {
  const styles = useStyles(variant);
  const { QuizNavigation } = useContext(QuestionNavigationContext);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.contentContainer}>{resolveElementOrRenderFunction(children)}</View>
      <View style={styles.navigationContainer}>
        {resolveElementOrRenderFunction(QuizNavigation, {
          variant:
            variant === 'end' ||
            variant === 'bottom' ||
            variant === 'bottomTallCircleCont' ||
            variant === 'endMid'
              ? 'thin'
              : 'normal'
        })}
      </View>
    </View>
  );
}

function useStyles(actionPanelVariant: ActionPanelVariant) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 'column'
              : 'row',
          // Subtract the border width from the padding.
          // For some reason Figma places the border within the padding, which isn't how CSS works.
          padding: 35 - 3,
          backgroundColor: colors.greyMatter,
          borderWidth: 3,
          // Action Panel does not need a left or top border unless it is an 'end' or 'endWide' variant.
          borderLeftWidth:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 0
              : 3,
          borderTopWidth:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 3
              : 0,
          // Action Panel does not need a top-right border radius unless it is an 'end' or 'endWide' variant.
          borderTopRightRadius:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 24
              : 0,
          // Action Panel does need a bottom-left border radius unless it is an 'end' or 'endWide' variant.
          borderBottomLeftRadius:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 0
              : 24,
          borderBottomRightRadius: 24,
          borderColor: colors.prussianBlue
        },

        contentContainer: {
          flex: 1,
          flexDirection:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 'column'
              : 'row',
          justifyContent:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 'center'
              : 'flex-start',
          alignContent:
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endWide' ||
            actionPanelVariant === 'endMid'
              ? 'center'
              : 'flex-start'
        },

        navigationContainer: {
          alignSelf:
            actionPanelVariant === 'bottom' ||
            actionPanelVariant === 'bottomTallCircleCont' ||
            actionPanelVariant === 'end' ||
            actionPanelVariant === 'endMid'
              ? 'center'
              : 'flex-end'
        }
      }),
    [actionPanelVariant]
  );
}
