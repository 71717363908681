import { newBlockContent } from '../../../Block';

import RecogniseAndName3DShapes from './1RecogniseAndName3DShapes';
import Sort3DShapes from './2Sort3DShapes';
import RecogniseAndName2DShapes from './3RecogniseAndName2DShapes';
import Sort2DShapes from './4Sort2DShapes';
import PatternsWith2DAnd3DShapes from './5PatternsWith2DAnd3DShapes';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [11, 11],
  smallSteps: [
    RecogniseAndName3DShapes, // Step 1
    Sort3DShapes, // Step 2
    RecogniseAndName2DShapes, // Step 3
    Sort2DShapes, // Step 4
    PatternsWith2DAnd3DShapes // Step 5
  ]
});
export default Block;
