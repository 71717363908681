import { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Line } from 'react-native-svg';
import { StateTreeLeaf } from '../../../stateTree';
import { countRange } from '../../../utils/collections';
import { isEqual } from '../../../utils/matchers';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import { GridSvgChildren } from '../representations/Coordinates/Grid';
import SelectableSquareDots from '../representations/Coordinates/SelectableSquareDots';
import SquareDottedPaper from '../representations/Coordinates/SquareDottedPaper';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

const CELL_PIXELS = 80;
const PDF_DOT_RADIUS = 5;

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /** Array of points that the question concerns. */
  polygon: { x: number; y: number }[];
  /** Index with respect to the `polygon` array. */
  missingIndex: number;
  hideLines?: boolean;
  testCorrect?: (points: { x: number; y: number }[]) => boolean;
  markSchemeText?: string;
};

export default function QF45CompletePolygonOnSquareDottedPaper({
  title,
  pdfTitle,
  polygon,
  missingIndex,
  hideLines = false,
  testCorrect: testCorrectProp,
  markSchemeText
}: Props) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const numVertices = polygon.length;

  const fixedIndices = countRange(numVertices).filter(it => it !== missingIndex);

  // Draw the following lines always. All the lines of the polygon except the missing lines.
  const fixedLines: [number, number][] = countRange(numVertices).flatMap(n => {
    const m = (n + 1) % numVertices;
    if (n !== missingIndex && m !== missingIndex) {
      return [[n, m] as [number, number]];
    } else {
      return [];
    }
  });

  const adjacentIndices = [
    (missingIndex + 1) % numVertices,
    (missingIndex + numVertices - 1) % numVertices
  ];

  function renderLine(
    { x: x1, y: y1 }: { x: number; y: number },
    { x: x2, y: y2 }: { x: number; y: number },
    mathToSvgX: (x: number) => number,
    mathToSvgY: (y: number) => number,
    fixed: boolean
  ) {
    return (
      <Line
        key={`(${x1},${y1})->(${x2},${y2})`}
        pointerEvents="none"
        x1={mathToSvgX(x1)}
        y1={mathToSvgY(y1)}
        x2={mathToSvgX(x2)}
        y2={mathToSvgY(y2)}
        stroke={isPdf ? 'black' : colors.prussianBlue}
        strokeWidth={4}
        strokeLinecap="round"
        strokeDasharray={isPdf && !fixed ? '6 10' : undefined}
      />
    );
  }

  if (displayMode === 'markscheme' || displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={{ alignItems: 'center' }}
        mainPanelContents={
          <>
            <SquareDottedPaper
              // This is the biggest that fits within the mainPanel and looks nice
              mathDimens={{ xLength: 10, yLength: 5 }}
              cellSize={CELL_PIXELS}
              dotColor={colors.greys700}
              dotRadius={PDF_DOT_RADIUS}
            >
              {({ mathToSvgX, mathToSvgY }) => (
                <GridSvgChildren>
                  {fixedLines.map(([i, j]) =>
                    renderLine(polygon[i], polygon[j], mathToSvgX, mathToSvgY, true)
                  )}
                  {displayMode === 'markscheme' &&
                    adjacentIndices.map(i =>
                      renderLine(polygon[i], polygon[missingIndex], mathToSvgX, mathToSvgY, false)
                    )}
                </GridSvgChildren>
              )}
            </SquareDottedPaper>
            {displayMode === 'markscheme' && markSchemeText && renderMarkSchemeProp(markSchemeText)}
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <SquareDottedPaper
          // This is the biggest that fits within the mainPanel and looks nice
          mathDimens={{ xLength: 10, yLength: 5 }}
          cellSize={CELL_PIXELS}
          dotColor="transparent"
          style={styles.center}
        >
          {({ mathToSvgX, mathToSvgY }) => (
            <StateTreeLeaf<{ x: number; y: number }[]>
              id="selectabledots"
              defaultState={[]}
              testComplete={state => state.length !== 0}
              testCorrect={testCorrectProp ?? isEqual([polygon[missingIndex]])}
            >
              {({ state, setState }) => (
                <>
                  <SelectableSquareDots
                    state={state}
                    setState={setState}
                    fixedSelected={fixedIndices.map(index => polygon[index])}
                  />
                  {!hideLines ? (
                    <GridSvgChildren>
                      {fixedLines.map(([i, j]) =>
                        renderLine(polygon[i], polygon[j], mathToSvgX, mathToSvgY, true)
                      )}
                      {state.length !== 0 &&
                        adjacentIndices.map(i =>
                          renderLine(polygon[i], state[0], mathToSvgX, mathToSvgY, false)
                        )}
                    </GridSvgChildren>
                  ) : null}
                </>
              )}
            </StateTreeLeaf>
          )}
        </SquareDottedPaper>
      }
    />
  );
}

const styles = StyleSheet.create({
  center: { alignSelf: 'center' }
});
