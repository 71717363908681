import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { CustomizableTableWithState } from '../../../../components/question/representations/CustomizableTable';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import ContentBox from '../../../../components/molecules/ContentBox';
import { ADD, ALGEBRAIC_X, ALGEBRAIC_Y, SUB } from '../../../../constants';
import Text from '../../../../components/typography/Text';
import { colors } from '../../../../theme/colors';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { useMemo } from 'react';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { algebraicSymbolSchema, getAlgebraicSymbolPair } from '../../../../utils/algebraicSymbols';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUw',
  description: 'aUw',
  keywords: ['Table', 'Pairs of values', 'Equation'],
  schema: z.object({
    number: z.number().int().min(7).max(15)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(7, 15);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const [shapeA, shapeB] = getRandomSubArrayFromArray(
      displayMode === 'digital'
        ? [
            'Circles/circle_green',
            'Square/square_blue',
            'Parallelogram/parallelogram_pink',
            'Kite/kite_purple',
            'Trapezium/trapezium_isosceles_yellow'
          ]
        : [
            'Circles/circle_white',
            'Square/square_white',
            'Parallelogram/parallelogram_white',
            'Kite/kite_white',
            'Trapezium/trapezium_isosceles_white'
          ],
      2,
      {
        random: seededRandom(props.question)
      }
    );

    const tableData = [
      [
        <AssetSvg key="shapeATable" name={shapeA as SvgName} width={96} />,
        '<ans/>',
        '<ans/>',
        '<ans/>'
      ],
      [
        <AssetSvg key="shapeBTable" name={shapeB as SvgName} width={96} />,
        '<ans/>',
        '<ans/>',
        '<ans/>'
      ]
    ];

    return (
      <QF3Content
        title={translate.instructions.hereIsAnEquation()}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptThreeDifferentPairsOfValuesThatAddUpToNum(number)
        }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, width: dimens.width, justifyContent: 'space-between' }}
          >
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'center',
                backgroundColor: colors.white
              }}
            >
              <AssetSvg key="shapeA" name={shapeA as SvgName} width={96} />
              <Text style={{ fontSize: 40 }}>{` ${ADD} `}</Text>
              <AssetSvg key="shapeB" name={shapeB as SvgName} width={96} />
              <Text style={{ fontSize: 40 }}>{` = ${number.toLocaleString()} `}</Text>
            </ContentBox>
            <Text style={{ fontSize: 32 }}>
              {translate.instructions.findThreePossiblePairsOfValues()}
            </Text>
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              defaultState={['', '', '', '', '', '']}
              tableData={tableData}
              testCorrect={userAnswer =>
                parseInt(userAnswer[0]) + parseInt(userAnswer[3]) === number &&
                parseInt(userAnswer[1]) + parseInt(userAnswer[4]) === number &&
                parseInt(userAnswer[2]) + parseInt(userAnswer[5]) === number &&
                arrayHasNoDuplicates([userAnswer[0], userAnswer[1], userAnswer[2]]) &&
                arrayHasNoDuplicates([userAnswer[3], userAnswer[4], userAnswer[5]])
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aUx',
  description: 'aUx',
  keywords: ['Table', 'Pairs of values', 'Equation'],
  schema: z.object({
    number: z.number().int().min(7).max(20)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(7, 20);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const tableData = [
      [ALGEBRAIC_X, '<ans/>', '<ans/>', '<ans/>'],
      [ALGEBRAIC_Y, '<ans/>', '<ans/>', '<ans/>']
    ];

    return (
      <QF3Content
        title={translate.instructions.hereIsAnEquation()}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptThreeDifferentPairsOfValuesThatAddUpToNum(number)
        }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, width: dimens.width, justifyContent: 'space-between' }}
          >
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                backgroundColor: displayMode !== 'digital' ? colors.white : undefined
              }}
            >
              <Text
                style={{ fontSize: 40 }}
              >{`${ALGEBRAIC_X} ${ADD} ${ALGEBRAIC_Y} = ${number.toLocaleString()} `}</Text>
            </ContentBox>
            <Text style={{ fontSize: 32 }}>
              {translate.instructions.findThreePossiblePairsOfValues()}
            </Text>
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              defaultState={['', '', '', '', '', '']}
              tableData={tableData}
              testCorrect={userAnswer =>
                parseInt(userAnswer[0]) + parseInt(userAnswer[3]) === number &&
                parseInt(userAnswer[1]) + parseInt(userAnswer[4]) === number &&
                parseInt(userAnswer[2]) + parseInt(userAnswer[5]) === number &&
                arrayHasNoDuplicates([userAnswer[0], userAnswer[1], userAnswer[2]]) &&
                arrayHasNoDuplicates([userAnswer[3], userAnswer[4], userAnswer[5]])
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUy',
  description: 'aUy',
  keywords: ['Formula', 'Value', 'Pairs of values', 'Equation'],
  schema: z
    .object({
      total: z.number().int().min(9).max(20),
      numberA2: z.number().int().min(1).max(20),
      numberB2: z.number().int().min(0).max(20),
      numberB3Difference: z.number().int().min(-1).max(1),
      numberC2: z.number().int().min(1).max(19),
      numberC3Difference: z.number().int().min(0).max(2),
      numberD2: z.number().int().min(1).max(19),
      numberD3Difference: z.number().int().min(0).max(2),
      algebraicSymbol1: algebraicSymbolSchema,
      algebraicSymbol2: algebraicSymbolSchema
    })
    .refine(val => val.numberA2 <= val.total, 'numberA2 must be less than or equal to total.')
    .refine(val => val.numberC2 < val.total, 'numberC2 must be less than total.')
    .refine(val => val.numberD2 < val.total, 'numberD2 must be less than total.'),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(9, 20);

    const numberA2 = randomIntegerInclusive(1, total);

    const numberB2 = getRandomFromArray([0, total] as const);

    const numberB3Difference = randomIntegerInclusive(-1, 1);

    const [numberC2, numberD2] = randomUniqueIntegersInclusive(1, total - 1, 2, {
      constraint: x => x !== numberA2
    });

    const numberC3Difference = randomIntegerInclusive(0, 2);

    const numberD3Difference =
      // Enforce equation D to be incorrect if equations A, B and C are all correct:
      numberB3Difference === 0 && numberC3Difference === 0
        ? randomIntegerInclusive(1, 2)
        : randomIntegerInclusive(0, 2);

    const [algebraicSymbol1, algebraicSymbol2] = getAlgebraicSymbolPair();

    return {
      total,
      numberA2,
      numberB2,
      numberB3Difference,
      numberC2,
      numberC3Difference,
      numberD2,
      numberD3Difference,
      algebraicSymbol1,
      algebraicSymbol2
    };
  },
  Component: props => {
    const {
      question: {
        total,
        numberA2,
        numberB2,
        numberB3Difference,
        numberC2,
        numberC3Difference,
        numberD2,
        numberD3Difference,
        algebraicSymbol1,
        algebraicSymbol2
      },
      translate,
      displayMode
    } = props;

    const numberA3 = total - numberA2;

    const numberB3 = total - numberB2 + numberB3Difference;

    const numberC3 = total - numberC2 + numberC3Difference;

    const numberD3 = total - numberD2 - numberD3Difference;

    // Need useMemo to make items selectable:
    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number2: numberA2,
              number3: numberA3
            },
            {
              number2: numberB2,
              number3: numberB3
            },
            {
              number2: numberC2,
              number3: numberC3
            },
            {
              number2: numberD2,
              number3: numberD3
            }
          ],
          {
            random: seededRandom(props.question)
          }
        ),
      [
        numberA2,
        numberA3,
        numberB2,
        numberB3,
        numberC2,
        numberC3,
        numberD2,
        numberD3,
        props.question
      ]
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAnEquation()}
        testCorrect={items.filter(item => item.number2 + item.number3 === total)}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-evenly' }}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center'
              }}
            >
              <Text variant="WRN400">{`${algebraicSymbol1} ${ADD} ${algebraicSymbol2} = ${total.toLocaleString()}`}</Text>
            </ContentBox>
            <View style={{ width: dimens.width }}>
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {displayMode === 'digital'
                  ? translate.instructions.selectPairsOfCorrectValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )
                  : translate.instructions.circlePairsOfCorrectValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )}
              </Text>
            </View>
          </View>
        )}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  columnGap: displayMode === 'digital' ? 32 : 64,
                  width: dimens.width
                }}
              >
                <Text variant="WRN700">{`${algebraicSymbol1} = ${item.number2.toLocaleString()}`}</Text>
                <Text variant="WRN700">{`${algebraicSymbol2} = ${item.number3.toLocaleString()}`}</Text>
              </View>
            ),
            value: item
          }))
        }
        multiSelect
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUz',
  description: 'aUz',
  keywords: ['Formula', 'Table', 'Sequence', 'Pairs of values', 'Equation'],
  schema: z.object({
    number: z.number().int().min(3).max(10),
    answersMustBeLessThan: numberEnum([20, 25, 30]),
    algebraicSymbol1: algebraicSymbolSchema,
    algebraicSymbol2: algebraicSymbolSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(3, 10);

    const answersMustBeLessThan = getRandomFromArray([20, 25, 30] as const);

    const [algebraicSymbol1, algebraicSymbol2] = getAlgebraicSymbolPair();

    return { number, answersMustBeLessThan, algebraicSymbol1, algebraicSymbol2 };
  },
  Component: props => {
    const {
      question: { number, answersMustBeLessThan, algebraicSymbol1, algebraicSymbol2 },
      translate,
      displayMode
    } = props;

    const tableData = [
      [algebraicSymbol1, '<ans/>', '<ans/>', '<ans/>'],
      [algebraicSymbol2, '<ans/>', '<ans/>', '<ans/>'],
      [
        `${algebraicSymbol1} ${SUB} ${algebraicSymbol2}`,
        number.toLocaleString(),
        number.toLocaleString(),
        number.toLocaleString()
      ]
    ];

    return (
      <QF3Content
        title={translate.instructions.xAndYAreBothNumsLessThanNumCompleteTableToShowPossibleValues(
          algebraicSymbol1,
          algebraicSymbol2,
          answersMustBeLessThan
        )}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText:
            translate.markScheme.acceptThreeDifferentPairsOfValuesWhereXSubYEqualsNumEachValLessThanZ(
              algebraicSymbol1,
              algebraicSymbol2,
              number,
              answersMustBeLessThan
            )
        }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, width: dimens.width, justifyContent: 'space-between' }}
          >
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                padding: 8,
                backgroundColor: displayMode !== 'digital' ? colors.white : undefined
              }}
            >
              <Text
                style={{ fontSize: 32 }}
              >{`${algebraicSymbol1} ${SUB} ${algebraicSymbol2} = ${number.toLocaleString()} `}</Text>
            </ContentBox>
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              defaultState={['', '', '', '', '', '']}
              tableData={tableData}
              testCorrect={userAnswer =>
                parseInt(userAnswer[0]) - parseInt(userAnswer[3]) === number &&
                parseInt(userAnswer[1]) - parseInt(userAnswer[4]) === number &&
                parseInt(userAnswer[2]) - parseInt(userAnswer[5]) === number &&
                arrayHasNoDuplicates([userAnswer[0], userAnswer[1], userAnswer[2]]) &&
                arrayHasNoDuplicates([userAnswer[3], userAnswer[4], userAnswer[5]]) &&
                userAnswer.every(ans => parseInt(ans) < answersMustBeLessThan)
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUA',
  description: 'aUA',
  keywords: ['Formula', 'Value', 'Pairs of values', 'Equation'],
  schema: z
    .object({
      difference: z.number().int().min(9).max(20),
      numberA2: z.number().int().min(1).max(20),
      numberB2: z.number().int().min(0).max(1),
      numberC2: z.number().int().min(1).max(19),
      numberC3Difference: z.number().int().min(0).max(2),
      numberD2: z.number().int().min(1).max(19),
      numberD3Difference: z.number().int().min(0).max(2),
      algebraicSymbol1: algebraicSymbolSchema,
      algebraicSymbol2: algebraicSymbolSchema
    })
    .refine(
      val => val.numberA2 <= val.difference,
      'numberA2 must be less than or equal to difference.'
    )
    .refine(val => val.numberC2 < val.difference, 'numberA1 must be less than difference.')
    .refine(val => val.numberD2 < val.difference, 'numberA1 must be less than difference.'),
  simpleGenerator: () => {
    const difference = randomIntegerInclusive(9, 20);

    const numberA2 = randomIntegerInclusive(1, difference);

    const numberB2 = randomIntegerInclusive(0, 1);

    const [numberC2, numberD2] = randomUniqueIntegersInclusive(1, difference - 1, 2, {
      constraint: x => x !== numberA2
    });

    const numberC3Difference = randomIntegerInclusive(0, 2);

    const numberD3Difference =
      // Enforce equation D to be incorrect if equations A, B and C are all correct:
      numberB2 === 0 && numberC3Difference === 0
        ? randomIntegerInclusive(1, 2)
        : randomIntegerInclusive(0, 2);

    const [algebraicSymbol1, algebraicSymbol2] = getAlgebraicSymbolPair();

    return {
      difference,
      numberA2,
      numberB2,
      numberC2,
      numberC3Difference,
      numberD2,
      numberD3Difference,
      algebraicSymbol1,
      algebraicSymbol2
    };
  },
  Component: props => {
    const {
      question: {
        difference,
        numberA2,
        numberB2,
        numberC2,
        numberC3Difference,
        numberD2,
        numberD3Difference,
        algebraicSymbol1,
        algebraicSymbol2
      },
      translate,
      displayMode
    } = props;

    const numberA3 = difference + numberA2;

    const numberB3 = difference;

    const numberC3 = difference + numberC2 + numberC3Difference;

    const numberD3 = difference + numberD2 - numberD3Difference;

    // Need useMemo to make items selectable:
    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number2: numberA2,
              number3: numberA3
            },
            {
              number2: numberB2,
              number3: numberB3
            },
            {
              number2: numberC2,
              number3: numberC3
            },
            {
              number2: numberD2,
              number3: numberD3
            }
          ],
          {
            random: seededRandom(props.question)
          }
        ),
      [
        numberA2,
        numberA3,
        numberB2,
        numberB3,
        numberC2,
        numberC3,
        numberD2,
        numberD3,
        props.question
      ]
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAnEquation()}
        testCorrect={items.filter(item => item.number3 - item.number2 === difference)}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-evenly' }}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center'
              }}
            >
              <Text variant="WRN400">{`${algebraicSymbol1} ${SUB} ${algebraicSymbol2} = ${difference.toLocaleString()}`}</Text>
            </ContentBox>
            <View style={{ width: dimens.width }}>
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {displayMode === 'digital'
                  ? translate.instructions.selectPairsOfCorrectValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )
                  : translate.instructions.circlePairsOfCorrectValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )}
              </Text>
            </View>
          </View>
        )}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  columnGap: displayMode === 'digital' ? 32 : 64,
                  width: dimens.width
                }}
              >
                <Text variant="WRN700">{`${algebraicSymbol1} = ${item.number3.toLocaleString()}`}</Text>
                <Text variant="WRN700">{`${algebraicSymbol2} = ${item.number2.toLocaleString()}`}</Text>
              </View>
            ),
            value: item
          }))
        }
        multiSelect
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUB',
  description: 'aUB',
  keywords: ['Formula', 'Value', 'Pairs of values', 'Equation'],
  schema: z
    .object({
      total: z.number().int().min(14).max(40),
      symbol1Multiplier: z.number().int().min(2).max(8),
      numberA1: z.number().int().min(0).max(20),
      numberB1: z.number().int().min(0).max(20),
      numberC1: z.number().int().min(0).max(40),
      numberD1: z.number().int().min(0).max(40),
      algebraicSymbol1: algebraicSymbolSchema,
      algebraicSymbol2: algebraicSymbolSchema
    })
    .refine(val => val.numberA1 <= val.total, 'numberA1 must be less than or equal to total.')
    .refine(val => val.numberB1 <= val.total, 'numberB1 must be less than or equal to total.')
    .refine(val => val.numberC1 <= val.total, 'numberC1 must be less than or equal to total.')
    .refine(val => val.numberD1 <= val.total, 'numberD1 must be less than or equal to total.'),
  simpleGenerator: () => {
    const { total, symbol1Multiplier, numberA1, numberB1, numberC1, numberD1 } = rejectionSample(
      () => {
        const total = randomIntegerInclusive(14, 40);

        const symbol1Multiplier = randomIntegerInclusive(2, 8);

        const numberA1AndB1UpperBound = Math.floor(total / symbol1Multiplier);

        const [numberA1, numberB1] = randomUniqueIntegersInclusive(0, numberA1AndB1UpperBound, 2);

        const [numberC1, numberD1] = randomUniqueIntegersInclusive(0, total, 2);

        return { total, symbol1Multiplier, numberA1, numberB1, numberC1, numberD1 };
      },
      // Only permit them if their products are all different.
      ({ total, symbol1Multiplier, numberA1, numberB1, numberC1, numberD1 }) =>
        arrayHasNoDuplicates([
          numberA1,
          numberB1,
          numberC1,
          numberD1,
          total - symbol1Multiplier * numberA1,
          total - symbol1Multiplier * numberB1
        ])
    );

    const [algebraicSymbol1, algebraicSymbol2] = getAlgebraicSymbolPair();

    return {
      total,
      symbol1Multiplier,
      numberA1,
      numberB1,
      numberC1,
      numberD1,
      algebraicSymbol1,
      algebraicSymbol2
    };
  },
  Component: props => {
    const {
      question: {
        total,
        symbol1Multiplier,
        numberA1,
        numberB1,
        numberC1,
        numberD1,
        algebraicSymbol1,
        algebraicSymbol2
      },
      translate,
      displayMode
    } = props;

    const numberA2 = total - symbol1Multiplier * numberA1;

    const numberB2 = total - symbol1Multiplier * numberB1;

    const items = shuffle([numberA1, numberA2, numberB1, numberB2, numberC1, numberD1], {
      random: seededRandom(props.question)
    });

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.hereIsAnEquation()}
        items={items}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-evenly' }}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center'
              }}
            >
              <Text
                style={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              >{`${symbol1Multiplier.toLocaleString()}${algebraicSymbol1} ${ADD} ${algebraicSymbol2} = ${total.toLocaleString()} `}</Text>
            </ContentBox>
            <View style={{ width: dimens.width }}>
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {displayMode === 'digital'
                  ? translate.instructions.dragCardsToFindTwoPossiblePairsOfValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )
                  : translate.instructions.useCardsToFindTwoPossiblePairsOfValuesForXAndY(
                      algebraicSymbol1,
                      algebraicSymbol2
                    )}
              </Text>
            </View>
          </View>
        )}
        sentences={[
          `${algebraicSymbol1} = <ans/> ${algebraicSymbol2} = <ans/>`,
          `${algebraicSymbol1} = <ans/> ${algebraicSymbol2} = <ans/>`
        ]}
        testCorrect={userAnswer =>
          !!userAnswer[0][0] &&
          !!userAnswer[0][1] &&
          !!userAnswer[1][0] &&
          !!userAnswer[1][1] &&
          symbol1Multiplier * userAnswer[0][0] + userAnswer[0][1] === total &&
          symbol1Multiplier * userAnswer[1][0] + userAnswer[1][1] === total
        }
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptValidAnswersForContent(),
          answersToDisplay: [
            [numberA1, numberA2],
            [numberB1, numberB2]
          ]
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindPairsOfValues',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
