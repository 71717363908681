import { ReactNode, useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, ViewStyle, View } from 'react-native';
import { Theme, useTheme } from '../../theme';
import { ElementOrRenderFunction } from '../../utils/react';
import { DraggableEventData, Droppable } from './draganddrop';
import DragAndDropSource from './DragAndDropSource';
import { DisplayMode } from '../../contexts/displayMode';
import { DraggableVariant } from './utils';

type Props<Payload> = {
  variant?: DraggableVariant;
  channel?: string | number;
  draggables: {
    id: string | number;
    key?: string | number;
    payload: Payload;
    element: string | ElementOrRenderFunction;
  }[];
  onDropInto: (event: { draggable: DraggableEventData }) => boolean | void;
  onDraggedToNowhere: (event: { draggable: DraggableEventData }) => boolean | void;
  /** Style for the outer droppable container. Default styling doesn't have width/height specified. */
  style?: StyleProp<ViewStyle>;
  draggableStyle?: StyleProp<ViewStyle>;
  /** Style for the draggable container. */
  wrapperStyle?: StyleProp<ViewStyle>;

  // Props for customizing how AutoScaleText works when providing strings as the draggables' element.
  textStyle?: StyleProp<TextStyle>;
  textVariant?: keyof Theme['fonts'];
  /** If you want auto text scaling, provide this or textSizeAutoScaleGroup, but not both. */
  textScaleToLongestLength?: number;
  /** If you want auto text scaling, provide this or textScaleToLongestLength, but not both. */
  textSizeAutoScaleGroup?: number | string;
  textLetterEmWidth?: number;
  maxLines?: number;

  /** Other, non-interactive items to go before the interactive ones. */
  children?: ReactNode;
};

/**
 * A variant of the {@link Droppable} component, specialized to our UI spec, which contains several draggable items.
 *
 * This component makes it easier to work with drag and drop, than using {@link Draggable} and {@link Droppable}
 * directly. Should be used in conjunction with {@link DragAndDropSource}.
 *
 * This component shows a container, with several draggables as children within it. The default styling arranges the
 * draggables using flex-wrap.
 *
 * You still need to provide information about which draggables to display (which should be some state managed by
 * something else) and the draggable component itself to display (which may be simply a string), as well as a callback
 * for what to do when that draggable is dragged out of the drop zone (but not into another drop zone). You also need
 * to provide a callback for what to do when a new draggable is dragged into this drop zone.
 *
 * In the event that the draggable item's `element` is simply a string, you can either use the default font size,
 * or auto text scaling with the `textSizeAutoScaleGroup` prop. Also, all of the styles can be overridden.
 */
export default function DragAndDropZoneMultiple<Payload>({
  variant = 'square',
  channel = 0,
  draggables,
  onDropInto,
  onDraggedToNowhere,
  style,
  wrapperStyle,
  draggableStyle,
  textStyle,
  textVariant,
  textScaleToLongestLength,
  textSizeAutoScaleGroup,
  textLetterEmWidth,
  maxLines = 1,
  children
}: Props<Payload>) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode);

  return (
    <Droppable
      channel={channel}
      onDropInto={onDropInto}
      style={[styles.droppable, style]}
      enteredStyle={styles.hoveredOver}
    >
      {children}

      {draggables.map(draggable => (
        <View key={draggable.key ?? draggable.id} style={wrapperStyle}>
          <DragAndDropSource
            variant={variant}
            payload={draggable.payload}
            id={draggable.id}
            moveOrCopy="move"
            channel={channel}
            hideBackground
            onDraggedToNowhere={() =>
              onDraggedToNowhere({ draggable: { id: draggable.id, payload: draggable.payload } })
            }
            draggableStyle={draggableStyle}
            textStyle={textStyle}
            textVariant={textVariant}
            textScaleToLongestLength={textScaleToLongestLength}
            textSizeAutoScaleGroup={textSizeAutoScaleGroup}
            textLetterEmWidth={textLetterEmWidth}
            maxLines={maxLines}
          >
            {draggable.element}
          </DragAndDropSource>
        </View>
      ))}
    </Droppable>
  );
}

function useStyles(displayMode: 'digital' | 'pdf' | 'markscheme') {
  const theme = useTheme();

  return useMemo(() => {
    const gap = 8;
    const padding = 8;
    const borderWidth = displayMode === 'digital' ? 3 : 6;

    return StyleSheet.create({
      droppable: {
        borderRadius: 8,
        borderWidth,
        borderStyle: 'dashed',
        backgroundColor: displayMode === 'digital' ? theme.colors.surface : theme.colors.background,
        borderColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.pdfPrimary,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap,
        padding
      },

      hoveredOver: {
        backgroundColor: theme.colors.tertiaryContainerHovered
      }
    });
  }, [displayMode, theme.colors]);
}
