import { ComponentProps } from 'react';
import EasyDragAndDrop from './EasyDragAndDrop';
import { singleZonesTransformer } from './utils';

function ProviderWithState<DragValue>({
  testCorrect: testCorrectProp,
  testComplete: testCompleteProp,
  defaultState: defaultStateProp,
  ...props
}: Omit<
  ComponentProps<typeof EasyDragAndDrop.ProviderWithState>,
  'testCorrect' | 'testComplete' | 'defaultState'
> & {
  testCorrect?: (state: (DragValue | undefined)[]) => boolean;
  testComplete?: (state: (DragValue | undefined)[]) => boolean;
  defaultState?: (DragValue | undefined)[];
}) {
  const testCorrect =
    testCorrectProp === undefined
      ? undefined
      : (state: DragValue[][]) => testCorrectProp(singleZonesTransformer.transform(state));
  const testComplete =
    testCompleteProp === undefined
      ? undefined
      : (state: DragValue[][]) => testCompleteProp(singleZonesTransformer.transform(state));
  const defaultState =
    defaultStateProp === undefined
      ? undefined
      : singleZonesTransformer.untransform(defaultStateProp);

  return (
    <EasyDragAndDrop.ProviderWithState<DragValue>
      testCorrect={testCorrect}
      testComplete={testComplete}
      defaultState={defaultState}
      {...props}
    />
  );
}

/**
 * Like {@link EasyDragAndDrop} but even more specialized: it assumes all zones have at most one item in them, and
 * also provides the item's value in each case, for convenience.
 *
 * Therefore, this uses `(DragValue | undefined)` instead of `number[]` as each drop zone's contents in the
 * `ProviderWithState`'s `testCorrect`, `testComplete` and `defaultState`.
 *
 * When providing default state with `moveOrCopy` set to `'move'`, this make a best effort to create that default
 * state using item IDs without placing each item in more than one place.
 */
export default {
  Provider: EasyDragAndDrop.Provider,
  ProviderWithState,
  Source: EasyDragAndDrop.Source,
  ZoneSingle: EasyDragAndDrop.ZoneSingle
};
