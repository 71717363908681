import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { barColorNames, barColorsNamesArray, barColorsNamesSchema } from '../../../../theme/colors';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from '../../../../utils/random';
import QF62DrawBarCharts from '../../../../components/question/questionFormats/QF62DrawBarCharts';
import { getRandomUniquePets, petAsWord, petSchema } from '../../../../utils/pets';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'av2',
  description: 'av2',
  keywords: ['Bar chart', 'Draw'],
  schema: z
    .object({
      maxNumberOfPets: z.number().int().min(5).max(7),
      /** First bar is already completed. */
      pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      colors: barColorsNamesSchema
        .array()
        .length(4)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      data: z.number().int().min(1).max(7).array().length(4)
    })
    .refine(
      ({ maxNumberOfPets, data }) => data.every(it => it <= maxNumberOfPets),
      'all entris in the data must be <= maxNumberOfPets'
    ),
  simpleGenerator: () => {
    const maxNumberOfPets = randomIntegerInclusive(5, 7);
    const pets = getRandomUniquePets(4);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const data = countRange(4).map(() => randomIntegerInclusive(1, maxNumberOfPets));
    return { maxNumberOfPets, colors, data, pets };
  },
  Component: ({ question: { maxNumberOfPets, colors, data, pets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[data[0], 0, 0, 0]}
        interactiveIndices={[1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest="grid"
        yMax={maxNumberOfPets}
        yStepSize={1}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'av3',
  description: 'av3',
  keywords: ['Bar chart', 'Draw'],
  schema: z.object({
    colors: barColorsNamesSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    data: z.number().int().min(6).max(14).array().length(4)
  }),
  simpleGenerator: () => {
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const pets = getRandomUniquePets(4);
    const data = countRange(4).map(() => randomIntegerInclusiveStep(6, 14, 2));
    return { colors, data, pets };
  },
  Component: ({ question: { colors, data, pets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest={1}
        yMax={14}
        yStepSize={2}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'av4',
  description: 'av4',
  keywords: ['Bar chart', 'Draw'],
  schema: z.object({
    colors: barColorsNamesSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    data: z.number().int().min(5).max(35).array().length(4)
  }),
  simpleGenerator: () => {
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const pets = getRandomUniquePets(4);
    const data = countRange(4).map(() => randomIntegerInclusiveStep(5, 35, 5));
    return { colors, data, pets };
  },
  Component: ({ question: { colors, data, pets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest={5}
        yMax={35}
        yStepSize={5}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'av5',
  description: 'av5',
  keywords: ['Bar chart', 'Draw'],
  schema: z.object({
    maxNumberOfPets: z.number().int().min(50).max(70),
    colors: barColorsNamesSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    data: z.number().int().min(10).max(70).array().length(4)
  }),
  simpleGenerator: () => {
    const maxNumberOfPets = randomIntegerInclusiveStep(50, 70, 10);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const pets = getRandomUniquePets(4);
    const data = randomUniqueIntegersInclusiveStep(10, maxNumberOfPets, 10, 4);
    return { colors, data, pets, maxNumberOfPets };
  },
  Component: ({ question: { colors, data, pets, maxNumberOfPets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest={10}
        yMax={maxNumberOfPets}
        yStepSize={10}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'av6',
  description: 'av6',
  keywords: ['Bar chart', 'Draw'],
  schema: z.object({
    maxNumberOfPets: z.number().int().min(10).max(14).step(2),
    colors: barColorsNamesSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    data: z.number().int().min(3).max(13).array().length(4)
  }),
  simpleGenerator: () => {
    const maxNumberOfPets = randomIntegerInclusiveStep(10, 14, 2);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const pets = getRandomUniquePets(4);
    const data = randomUniqueIntegersInclusive(3, maxNumberOfPets - 1, 4, {
      constraint: x => x % 2 !== 0
    });
    return { colors, data, pets, maxNumberOfPets };
  },
  Component: ({ question: { colors, data, pets, maxNumberOfPets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest={1}
        yMax={maxNumberOfPets}
        yStepSize={2}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'av7',
  description: 'av7',
  keywords: ['Bar chart', 'Draw'],
  schema: z.object({
    maxNumberOfPets: z.number().int().min(50).max(70).step(10),
    colors: barColorsNamesSchema
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    data: z.number().int().min(10).max(70).array().length(4)
  }),
  simpleGenerator: () => {
    const maxNumberOfPets = randomIntegerInclusiveStep(50, 70, 10);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const pets = getRandomUniquePets(4);

    const multiplesOf5s = randomUniqueIntegersInclusiveStep(10, maxNumberOfPets, 5, 2, {
      constraint: x => x % 10 !== 0
    });
    const multiplesOf10s = randomUniqueIntegersInclusiveStep(10, maxNumberOfPets, 10, 2);

    const data = shuffle([...multiplesOf5s, ...multiplesOf10s]);

    return { colors, data, pets, maxNumberOfPets };
  },
  Component: ({ question: { colors, data, pets, maxNumberOfPets }, translate }) => {
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={pets.map(name => petAsWord(name, translate))}
        barColors={colors.map(name => barColorNames[name])}
        snapToNearest={5}
        yMax={maxNumberOfPets}
        yStepSize={10}
        xAxisLabel={translate.misc.pet()}
        yAxisLabel={translate.misc.numberOfPets()}
        yAxisArrowLabel={null}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawBarCharts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
