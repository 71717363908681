import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  capacityObjectAsWord,
  highVolumeImages,
  highVolumeItemsArray,
  lowVolumeImages,
  lowVolumeItemsArray,
  orangeJuiceSvg
} from '../../../../utils/capacityImages';
import { AssetSvg } from '../../../../assets/svg';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import {
  getNumberOfIntervals,
  JugWithLiquid
} from '../../../../components/question/representations/JugWithLiquid';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { decimalToFraction, formatFractionToMarkup } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJA',
  description: 'aJA',
  keywords: ['Capacity', 'Volume', 'Order'],
  schema: z.object({
    glassAmounts: z
      .enum(['empty', 'full', 'half', 'quarter', 'third', 'threeQuarters', 'twoThirds'])
      .array()
      .length(4),
    glassType: numberEnum([1, 2]),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const glassAmounts = getRandomSubArrayFromArray(
      ['empty', 'full', 'half', 'quarter', 'third', 'threeQuarters', 'twoThirds'] as const,
      4
    );
    const glassType = getRandomFromArray([1, 2] as const);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { glassAmounts, glassType, ordering };
  },
  Component: props => {
    const {
      question: { glassAmounts, glassType, ordering },
      translate
    } = props;

    const glassObjects = glassAmounts.map(amount => orangeJuiceSvg(amount, glassType));

    const correctOrder = sortNumberArray(
      glassObjects.map(val => val.value),
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.instructions.dragCardsToOrderOrangeJuiceFromLeast()
            : translate.instructions.dragCardsToOrderOrangeJuiceFromMost()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.instructions.useCardsToOrderOrangeJuiceFromLeast()
            : translate.instructions.useCardsToOrderOrangeJuiceFromMost()
        }
        testCorrect={correctOrder}
        items={glassObjects.map(object => {
          return {
            component: <AssetSvg name={object.svgName} height={80} width={80} />,
            value: object.value
          };
        })}
        itemVariant="square"
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Least() : translate.keywords.Most()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Most() : translate.keywords.Least()
        }
        arrowWidth={196}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aJB',
  description: 'aJB',
  keywords: ['Capacity', 'Volume', 'Compare'],
  schema: z.object({
    highItem: z.enum(highVolumeItemsArray),
    lowItem: z.enum(lowVolumeItemsArray),
    ordering: z.enum(['least', 'most'])
  }),
  simpleGenerator: () => {
    const highItem = getRandomFromArray(highVolumeItemsArray);
    const lowItem = getRandomFromArray(lowVolumeItemsArray);
    const ordering = getRandomFromArray(['least', 'most'] as const);

    return { highItem, lowItem, ordering };
  },

  Component: ({ question: { highItem, lowItem, ordering }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={
          ordering === 'least'
            ? translate.instructions.selectObjectThatHoldsLeastLiquid()
            : translate.instructions.selectObjectThatHoldsMostLiquid()
        }
        pdfTitle={
          ordering === 'least'
            ? translate.instructions.circleObjectThatHoldsLeastLiquid()
            : translate.instructions.circleObjectThatHoldsMostLiquid()
        }
        numItems={2}
        testCorrect={[ordering === 'least' ? lowItem : highItem]}
        renderItems={({ dimens }) =>
          shuffle(
            [
              {
                value: highItem,
                component: (
                  <>
                    <AssetSvg
                      name={
                        highVolumeImages
                          .filter(val => val.object === highItem)
                          .map(val => val.svgName)[0]
                      }
                      height={dimens.height * 0.6}
                      width={dimens.width * 0.9}
                    />
                    <Text variant="WRN400">{capacityObjectAsWord(highItem, translate)}</Text>
                  </>
                )
              },
              {
                value: lowItem,
                component: (
                  <>
                    <AssetSvg
                      name={
                        lowVolumeImages
                          .filter(val => val.object === lowItem)
                          .map(val => val.svgName)[0]
                      }
                      height={dimens.height * 0.4}
                      width={dimens.width * 0.6}
                    />
                    <Text variant="WRN400">{capacityObjectAsWord(lowItem, translate)}</Text>
                  </>
                )
              }
            ],
            { random: seededRandom({ highItem, lowItem, ordering }) }
          )
        }
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aJB2',
  description: 'aJB',
  keywords: ['Capacity', 'Volume', 'Compare'],
  schema: z.object({
    highItem: z.enum(highVolumeItemsArray),
    lowItem: z.enum(lowVolumeItemsArray),
    ordering: z.enum(['least', 'most'])
  }),
  simpleGenerator: () => {
    const highItem = getRandomFromArray(highVolumeItemsArray);

    const lowVolumeItemsArrayFiltered = lowVolumeItemsArray.filter(x => x !== 'FishTank');
    const lowItem = getRandomFromArray(lowVolumeItemsArrayFiltered)!;
    const ordering = getRandomFromArray(['least', 'most'] as const);

    return { highItem, lowItem, ordering };
  },

  Component: ({ question: { highItem, lowItem, ordering }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={
          ordering === 'least'
            ? translate.instructions.selectObjectThatHoldsLeastLiquid()
            : translate.instructions.selectObjectThatHoldsMostLiquid()
        }
        pdfTitle={
          ordering === 'least'
            ? translate.instructions.circleObjectThatHoldsLeastLiquid()
            : translate.instructions.circleObjectThatHoldsMostLiquid()
        }
        numItems={2}
        testCorrect={[ordering === 'least' ? lowItem : highItem]}
        renderItems={({ dimens }) =>
          shuffle(
            [
              {
                value: highItem,
                component: (
                  <>
                    <AssetSvg
                      name={
                        highVolumeImages
                          .filter(val => val.object === highItem)
                          .map(val => val.svgName)[0]
                      }
                      height={dimens.height * 0.6}
                      width={dimens.width * 0.9}
                    />
                    <Text variant="WRN400">{capacityObjectAsWord(highItem, translate)}</Text>
                  </>
                )
              },
              {
                value: lowItem,
                component: (
                  <>
                    <AssetSvg
                      name={
                        lowVolumeImages
                          .filter(val => val.object === lowItem)
                          .map(val => val.svgName)[0]
                      }
                      height={dimens.height * 0.4}
                      width={dimens.width * 0.6}
                    />
                    <Text variant="WRN400">{capacityObjectAsWord(lowItem, translate)}</Text>
                  </>
                )
              }
            ],
            { random: seededRandom({ highItem, lowItem, ordering }) }
          )
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJC',
  description: 'aJC',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  questionHeight: 900,
  schema: z
    .object({
      jugCapA: numberEnum([100, 200, 500, 1000]),
      jugCapB: numberEnum([100, 200, 500, 1000]),
      intervalA: numberEnum([20, 25, 50, 100, 200, 250, 500]),
      intervalB: numberEnum([20, 25, 50, 100, 200, 250, 500]),
      liquidA: z.number().int().min(10).max(950),
      liquidB: z.number().int().min(10).max(975),
      hasMost: z.boolean()
    })
    .refine(
      ({ jugCapA, jugCapB }) => arrayHasNoDuplicates([jugCapA, jugCapB]),
      'jugs should be different'
    )
    .refine(
      ({ liquidA, liquidB }) => arrayHasNoDuplicates([liquidA, liquidB]),
      'Liquid amounts should be different'
    ),
  simpleGenerator: () => {
    const [jugCapA, jugCapB] = getRandomSubArrayFromArray([100, 200, 500, 1000] as const, 2);

    const intervals = {
      100: [20, 25, 50],
      200: [20, 25, 50, 100],
      500: [50, 100],
      1000: [100, 200, 250, 500]
    } as const;
    const intervalA = getRandomFromArray(intervals[jugCapA]);
    const intervalB = getRandomFromArray(intervals[jugCapB]);
    const liquidA = randomIntegerInclusive(10, Math.min(950, jugCapA), {
      constraint: x => (intervalA !== 25 ? x % (intervalA / 2) === 0 : x % intervalA === 0)
    });
    const liquidB = randomIntegerInclusive(10, Math.min(975, jugCapB), {
      constraint: x =>
        x !== liquidA && (intervalB !== 25 ? x % (intervalB / 2) === 0 : x % intervalB === 0)
    });
    const hasMost = getRandomBoolean();

    return { jugCapA, jugCapB, intervalA, intervalB, liquidA, liquidB, hasMost };
  },
  Component: props => {
    const {
      question: { jugCapA, jugCapB, intervalA, intervalB, liquidA, liquidB, hasMost },
      translate
    } = props;

    const items = [
      {
        liquidAmount: liquidA,
        jugCap: jugCapA,
        tickValue: intervalA,
        most: liquidA > liquidB,
        bigger: jugCapA > jugCapB
      },
      {
        liquidAmount: liquidB,
        jugCap: jugCapB,
        tickValue: intervalB,
        most: liquidB > liquidA,
        bigger: jugCapB > jugCapA
      }
    ];
    const title = hasMost
      ? 'selectMeasuringContainerThatHasMostLiquid'
      : 'selectMeasuringContainerThatHasLeastLiquid';
    const pdfTitle = hasMost
      ? 'circleMeasuringContainerThatHasMostLiquid'
      : 'circleMeasuringContainerThatHasLeastLiquid';
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions[title]()}
        pdfTitle={translate.instructions[pdfTitle]()}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(({ liquidAmount, jugCap, tickValue, most, bigger }, i) => ({
            component: (
              <JugWithLiquid
                key={i}
                dimens={{
                  width: dimens.width * 0.9,
                  height: bigger ? dimens.height * 0.9 : dimens.height * 0.8
                }}
                jugCapacity={jugCap}
                tickValue={tickValue}
                liquidAmount={liquidAmount}
                labelUnits="ml"
                displayMajorLabels="top"
              />
            ),
            value: most
          }));
        }}
        testCorrect={[hasMost]}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aJD',
  description: 'aJD',
  keywords: ['Volume', 'Litres', 'Compare'],
  questionHeight: 1200,
  schema: z
    .object({
      intervalA: numberEnum([100, 200, 250, 500]),
      intervalB: numberEnum([100, 200, 250, 500]),
      liquidA: z.number().int().min(50).max(950),
      liquidB: z.number().int().min(100).max(975),
      isGreater: z.boolean()
    })
    .refine(
      ({ liquidA, liquidB }) => arrayHasNoDuplicates([liquidA, liquidB]),
      'Liquid amounts should be different'
    ),
  simpleGenerator: () => {
    const intervalA = getRandomFromArray([100, 200, 250, 500] as const);
    const factorA = intervalA === 250 || intervalA === 500 ? intervalA : intervalA / 2;
    const liquidA = randomIntegerInclusiveStep(factorA, 1000 - factorA, factorA);

    const intervalB = getRandomFromArray(
      liquidA === 500 ? ([100, 200, 250] as const) : ([100, 200, 250, 500] as const)
    );

    const liquidB = randomIntegerInclusiveStep(intervalB, 1000 - intervalB, intervalB, {
      constraint: x => x !== liquidA
    });

    const isGreater = getRandomBoolean();
    return { intervalA, intervalB, liquidA, liquidB, isGreater };
  },
  Component: props => {
    const {
      question: { intervalA, intervalB, liquidA, liquidB, isGreater },
      translate,
      displayMode
    } = props;

    const A = translate.letters.A();
    const B = translate.letters.B();

    const jugs = [
      {
        jug: A,
        liquidAmount: liquidA,
        tickValue: intervalA,
        liquidType: 'water',
        labelUnits: 'ml',
        label: translate.units.numberOfMl(liquidA)
      },
      {
        jug: B,
        liquidAmount: liquidB,
        tickValue: intervalB,
        liquidType: 'orange',
        labelUnits: 'fractionLitres',
        label: translate.units.stringLitres(
          formatFractionToMarkup(...decimalToFraction(liquidB / 1000), 'fraction'),
          2
        )
      }
    ];

    const [greaterLiquid, lesserLiquid] =
      liquidA > liquidB ? [liquidA, liquidB] : [liquidB, liquidA];
    const correctAnswer = isGreater ? greaterLiquid : lesserLiquid;

    const title = isGreater ? 'selectGreaterVolume' : 'selectSmallerVolume';
    const pdfTitle = isGreater ? 'circleGreaterVolume' : 'circleSmallerVolume';
    return (
      <QF11SelectImagesUpTo4WithContent
        questionHeight={1200}
        title={`${translate.instructions[title]()}`}
        pdfTitle={`${translate.instructions[pdfTitle]()}`}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            {jugs.map(({ liquidAmount, tickValue, liquidType, jug, labelUnits }, i) => (
              <View key={i}>
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {jug}
                </Text>
                <JugWithLiquid
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
                  jugCapacity={1000}
                  liquidAmount={liquidAmount}
                  // For the 100ml intervals, only show the 500ml label with unlabelled minor ticks
                  tickValue={i === 0 && tickValue === 100 ? 500 : tickValue}
                  unitsPerMajorTick={i === 0 && tickValue === 100 ? 4 : undefined}
                  liquidType={liquidType as 'water' | 'orange'}
                  labelUnits={labelUnits as 'ml' | 'fractionLitres'}
                  displayMajorLabels={i === 0 ? 'all' : 'first'}
                />
              </View>
            ))}
          </View>
        )}
        numItems={2}
        itemLayout="row"
        renderItems={jugs.map(({ liquidAmount, label }) => ({
          value: liquidAmount,
          component: (
            <TextStructure
              textVariant="WRN700"
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
              sentence={label}
            />
          )
        }))}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJE',
  description: 'aJE',
  keywords: ['Volume', 'Litres', 'Compare'],
  questionHeight: 1000,
  schema: z
    .object({
      jugCapA: numberEnum([1000, 2000, 3000, 4000, 5000]),
      jugCapB: numberEnum([1000, 2000, 3000, 4000, 5000]),
      intervalA: numberEnum([100, 200, 250, 500]),
      intervalB: numberEnum([100, 200, 250, 500]),
      liquidA: z.number().int().min(100).max(4900),
      liquidB: z.number().int().min(100).max(4900)
    })
    .refine(
      ({ jugCapA, jugCapB }) => arrayHasNoDuplicates([jugCapA, jugCapB]),
      'jugs should be different'
    ),
  simpleGenerator: () => {
    const [jugCapA, jugCapB] = getRandomSubArrayFromArray(
      [1000, 2000, 3000, 4000, 5000] as const,
      2
    );

    const intervals = {
      1000: [100, 200, 250, 500],
      2000: [200, 250, 500],
      3000: [200, 250, 500],
      4000: [500],
      5000: [500]
    } as const;
    const intervalA = getRandomFromArray(intervals[jugCapA]);
    const intervalB = getRandomFromArray(intervals[jugCapB]);
    const liquidAl = randomIntegerInclusiveStep(0, jugCapA - 1000, 1000);
    const liquidBl = randomIntegerInclusiveStep(0, jugCapB - 1000, 1000);
    const liquidA =
      liquidAl +
      randomIntegerInclusiveStep(100, 900, 100, {
        constraint: x => x % intervalA === 0
      });

    const liquidB =
      liquidBl +
      randomIntegerInclusiveStep(100, 900, 100, {
        constraint: x => x % intervalB === 0
      });

    return { jugCapA, jugCapB, intervalA, intervalB, liquidA, liquidB };
  },
  Component: props => {
    const {
      question: { jugCapA, jugCapB, intervalA, intervalB, liquidA, liquidB },
      translate,
      displayMode
    } = props;

    const dimens =
      displayMode === 'digital' ? { width: 400, height: 400 } : { width: 600, height: 600 };

    const jugAIsBigger = jugCapA > jugCapB;
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragInequalities0rEqualToCompareVolumes()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareVolumes()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: dimens.width,
                  gap: 5
                }}
              >
                <JugWithLiquid
                  dimens={
                    jugAIsBigger ? dimens : { width: dimens.width, height: dimens.height * 0.9 }
                  }
                  jugCapacity={jugCapA}
                  tickValue={1000}
                  unitsPerMajorTick={getNumberOfIntervals(intervalA)}
                  liquidAmount={liquidA}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: dimens.width,
                  gap: 5
                }}
              >
                <JugWithLiquid
                  dimens={
                    !jugAIsBigger ? dimens : { width: dimens.width, height: dimens.height * 0.9 }
                  }
                  jugCapacity={jugCapB}
                  tickValue={1000}
                  unitsPerMajorTick={getNumberOfIntervals(intervalB)}
                  liquidAmount={liquidB}
                />
              </View>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(liquidA, liquidB)
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJF',
  description: 'aJF',
  keywords: ['Capacity', 'Millilitres', 'Litres', 'Order'],
  schema: z
    .object({
      startWithSmallest: z.boolean(),
      volMlA: z.number().int().min(100).max(900).step(50),
      volMlB: z.number().int().min(1).max(500),
      volLC: z.number().int().min(1).max(7),
      volLD: z.number().int().min(1).max(7)
    })
    .refine(({ volMlA, volMlB }) => volMlA !== volMlB, 'volMlA cannot equal volMlB'),
  simpleGenerator: () => {
    const startWithSmallest = getRandomBoolean();

    const volMlA = randomIntegerInclusiveStep(100, 900, 50);
    const volMlB = randomIntegerInclusive(1, 500, { constraint: x => x !== volMlA });

    const [volLC, volLD] = countRange(2).map(_ => randomIntegerInclusive(1, 7));

    return { startWithSmallest, volMlA, volMlB, volLC, volLD };
  },
  Component: props => {
    const {
      question: { startWithSmallest, volMlA, volMlB, volLC, volLD },
      translate
    } = props;

    // Get all values in ml
    const volMlC = volLC * 1000 + volMlA;
    const volMlD = volLD * 1000;

    const items = shuffle(
      [
        {
          value: volMlA,
          string: translate.units.numberOfMillilitres(volMlA)
        },
        {
          value: volMlB,
          string: translate.units.numberOfMillilitres(volMlB)
        },
        {
          value: volMlC,
          string: translate.units.numberOfLitresAndMl(volLC, volMlA)
        },
        {
          value: volMlD,
          string: translate.units.numberOfLitres(volLD)
        }
      ],
      { random: seededRandom(props.question) }
    );

    const [title, pdfTitle, topLabel, bottomLabel] = startWithSmallest
      ? ([
          'dragCardsToOrderCapacitiesStartWithSmallest',
          'useCardsToOrderCapacitiesStartWithSmallest',
          'Smallest',
          'Greatest'
        ] as const)
      : ([
          'dragCardsToOrderCapacitiesStartWithGreatest',
          'useCardsToOrderCapacitiesStartWithGreatest',
          'Greatest',
          'Smallest'
        ] as const);

    return (
      <QF4DragOrderVertical
        title={translate.instructions[title]()}
        pdfTitle={translate.instructions[pdfTitle]()}
        testCorrect={sortNumberArray(
          [volMlA, volMlB, volMlC, volMlD],
          startWithSmallest ? 'ascending' : 'descending'
        )}
        items={items.map(({ value, string }) => ({
          value,
          component: string
        }))}
        topLabel={translate.keywords[topLabel]()}
        bottomLabel={translate.keywords[bottomLabel]()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareCapacityAndVolume',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
