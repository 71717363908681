import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { getRandomUniqueRealLifeObjectOf2DShapesSvgNames } from '../../../../utils/shapes';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  get3DShapeSvgName,
  getRandomUniqueRealLifeObjectOf3DShapesSvgNames,
  shapeProperties,
  shapes3DAsWord,
  stampsSchema,
  threeDShapesSchema
} from '../../../../utils/threeDShapes';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ShapeNames } from '../../../../utils/labelPositions';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avy',
  description: 'avy',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    answerSvg: z.string(),
    answerShapeName: threeDShapesSchema,
    incorrectShapeNames: z.array(threeDShapesSchema).length(3)
  }),
  simpleGenerator: () => {
    const array = [
      'cuboids',
      'hexagonalPrisms',
      'pentagonalPrisms',
      'squareBasedPyramids',
      'triangularPrisms',
      'cubes',
      'cones',
      'cylinders',
      'triangularBasedPyramids'
    ] as const;

    const answerShapeName = getRandomFromArray(array);

    const incorrectArray = array.filter(
      val => val !== answerShapeName && (answerShapeName === 'cubes' ? val !== 'cuboids' : true)
    );

    const incorrectShapeNames = getRandomSubArrayFromArray(incorrectArray, 3);

    const answerSvg = get3DShapeSvgName(answerShapeName);

    return { answerSvg, incorrectShapeNames, answerShapeName };
  },
  Component: props => {
    const {
      question: { answerSvg, incorrectShapeNames, answerShapeName },
      translate
    } = props;

    const items = shuffle([answerShapeName, ...incorrectShapeNames], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheMathematicalNameOfTheShape()}
        pdfTitle={translate.instructions.circleTheMathematicalNameOfTheShape()}
        testCorrect={[answerShapeName]}
        numItems={4}
        Content={
          <View style={{ top: 20 }}>
            <AssetSvg name={answerSvg as SvgName} height={200} width={200} />
          </View>
        }
        renderItems={items.map(shapeName => ({
          value: shapeName,
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {shapes3DAsWord(shapeName, translate, 1)}
            </Text>
          )
        }))}
      />
    );
  },
  questionHeight: 900
});

// Question2 exported to Q aAG
const Question2 = newQuestionContent({
  uid: 'avz',
  description: 'avz',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    shapeNames: z.array(threeDShapesSchema).length(4),
    shapeSvgNames: z.array(z.string())
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeNames = getRandomSubArrayFromArray(
      [
        'cuboids',
        'hexagonalPrisms',
        'pentagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms',
        'cubes',
        'cones',
        'cylinders',
        'triangularBasedPyramids'
      ] as const,
      4
    );
    const shapeSvgNames = shapeNames.map(name => get3DShapeSvgName(name));

    return { shapeSvgNames, shapeNames };
  },
  Component: props => {
    const {
      question: { shapeSvgNames, shapeNames },
      translate,
      displayMode
    } = props;

    const items = shapeNames.map(name => shapes3DAsWord(name, translate, 1));

    const statements = shapeNames.map((_, index) => {
      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: displayMode === 'digital' ? 150 : 250 }}>
            <AssetSvg
              name={shapeSvgNames[index] as SvgName}
              height={displayMode === 'digital' ? 100 : 160}
            />
          </View>
        ),
        correctAnswer: items[index]
      };
    });

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  }
});

export const avz = Question2;

const Question3 = newQuestionContent({
  uid: 'avA',
  description: 'avA',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    name: nameSchema,
    stamp: stampsSchema,
    shapesObjects: z
      .array(
        z.object({
          shape: z.string(),
          svg: z.string(),
          stampImages: z.array(z.string())
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const stamp = getRandomFromArray(['circles', 'squares', 'rectangles', 'triangles'] as const);

    const { shapesObjects } = rejectionSample(
      () => {
        const shapes = getRandomSubArrayFromArray(
          [
            'cuboids',
            'hexagonalPrisms',
            'pentagonalPrisms',
            'squareBasedPyramids',
            'triangularPrisms',
            'cubes',
            'cones',
            'cylinders',
            'triangularBasedPyramids'
          ] as const,
          4
        );

        const shapesObjects = shapeProperties.filter(({ shape }) => shapes.includes(shape));
        return { shapesObjects, shapes };
      },
      // only permit if there is atleast 1 correct answer
      ({ shapesObjects }) =>
        shapesObjects.filter(shapeObject => shapeObject.stampImages.includes(stamp)).length > 0
    );

    return { name, stamp, shapesObjects };
  },
  Component: props => {
    const {
      question: { name, stamp, shapesObjects },
      translate,
      displayMode
    } = props;

    const stampSvg = (() => {
      switch (stamp) {
        case 'circles':
          return <AssetSvg name="Other_shapes/stamp3dshape2" width={100} height={100} />;
        case 'squares':
          return <AssetSvg name="Other_shapes/stamp3dshape1" width={100} height={100} />;
        case 'rectangles':
          return <AssetSvg name="Other_shapes/stamp3dshape4" width={100} height={100} />;
        case 'triangles':
          return <AssetSvg name="Other_shapes/stamp3dshape3" width={100} height={100} />;
      }
    })();

    const answers = shapesObjects.filter(shapeObject => shapeObject.stampImages.includes(stamp));

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterStampsTheFaceOfA3DShapeOntoASheetOfPaperSelectTheShapeItCouldHaveBeen(
          name,
          answers.length === 1 ? translate.misc.shape() : translate.misc.shapes()
        )}
        pdfTitle={translate.instructions.characterStampsTheFaceOfA3DShapeOntoASheetOfPaperCircleTheShapeItCouldHaveBeen(
          name,
          answers.length === 1 ? translate.misc.shape() : translate.misc.shapes()
        )}
        testCorrect={answers.map(answer => answer.shape)}
        numItems={4}
        multiSelect
        itemStyle={{ height: displayMode === 'digital' ? 160 : 300 }}
        Content={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {stampSvg}
          </View>
        }
        renderItems={shapesObjects.map(({ svg, shape }) => ({
          value: shape,
          component: (
            <AssetSvg
              name={svg as SvgName}
              width={displayMode === 'digital' ? 140 : 250}
              height={displayMode === 'digital' ? 140 : 250}
            />
          )
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avB',
  description: 'avB',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    cubeDimens: z.number().int().min(1).max(20),
    cuboidADimen: z.number().int().min(2).max(20).multipleOf(2),
    finalShapeDimen: z.number().int().min(2).max(20).multipleOf(2),
    finalShape: z.enum(['Cube', 'Cuboid with arrows', 'Cuboid without arrows'])
  }),
  simpleGenerator: () => {
    const cubeDimens = randomIntegerInclusive(1, 20);

    const cuboidADimen = randomIntegerInclusiveStep(2, 20, 2);

    const finalShapeDimen = randomIntegerInclusiveStep(2, 20, 2);

    const finalShape = getRandomFromArray([
      'Cube',
      'Cuboid with arrows',
      'Cuboid without arrows'
    ] as const);

    return {
      cubeDimens,
      cuboidADimen,
      finalShapeDimen,
      finalShape
    };
  },
  Component: props => {
    const {
      question: { cubeDimens, cuboidADimen, finalShapeDimen, finalShape },
      translate
    } = props;

    const random = seededRandom(props.question);

    const cubesWithArrowsChoices = [
      'Cube_3_arrows_blue',
      'Cube_3_arrows_green',
      'Cube_3_arrows_red',
      'Cube_3_arrows_white',
      'Cube_3_arrows_yellow'
    ];

    const cuboidsWithArrowsChoices = [
      'cuboid1_3_arrows',
      'cuboid2_3_arrows',
      'cuboid3_3_arrows',
      'cuboid4_3_arrows'
    ] as const;

    const cuboidsWithoutArrowsChoices = ['Cuboids/Cuboid1', 'Cuboids/Cuboid2', 'Cuboids/Cuboid3'];

    const cubePath = getRandomFromArray(cubesWithArrowsChoices, { random });

    const cuboidWithArrowsPath = getRandomFromArray(cuboidsWithArrowsChoices, { random });

    const cuboidWithArrowsDimens = (
      cuboid: 'cuboid1_3_arrows' | 'cuboid2_3_arrows' | 'cuboid3_3_arrows' | 'cuboid4_3_arrows',
      width: number
    ) => {
      switch (cuboid) {
        case 'cuboid1_3_arrows':
          return [width, width / 2, width / 2];
        case 'cuboid2_3_arrows':
          return [width, width / 2, width / 2];
        case 'cuboid3_3_arrows':
          return [(width * 3) / 2, width, width];
        case 'cuboid4_3_arrows':
          return [width, width / 2, width / 2];
      }
    };

    const cuboidWithoutArrowsPath = getRandomFromArray(cuboidsWithoutArrowsChoices, { random });

    const finalShapePath = getRandomFromArray(
      finalShape === 'Cube'
        ? cubesWithArrowsChoices.filter(shape => shape !== cubePath)
        : finalShape === 'Cuboid with arrows'
        ? cuboidsWithArrowsChoices.filter(shape => shape !== cuboidWithArrowsPath)
        : cuboidsWithoutArrowsChoices.filter(shape => shape !== cuboidWithoutArrowsPath),
      { random }
    );

    const shapes = shuffle(
      [
        {
          shape: cubePath,
          isCorrect: true,
          hasArrows: true,
          width: cubeDimens,
          height: cubeDimens,
          depth: cubeDimens
        },
        {
          shape: cuboidWithArrowsPath,
          isCorrect: false,
          hasArrows: true,
          width: cuboidWithArrowsDimens(cuboidWithArrowsPath, cuboidADimen)[0],
          height: cuboidWithArrowsDimens(cuboidWithArrowsPath, cuboidADimen)[1],
          depth: cuboidWithArrowsDimens(cuboidWithArrowsPath, cuboidADimen)[2]
        },
        {
          shape: cuboidWithoutArrowsPath,
          isCorrect: false,
          hasArrows: false
        },
        {
          shape: finalShapePath,
          isCorrect: finalShape === 'Cube',
          hasArrows: finalShape !== 'Cuboid without arrows',
          width:
            finalShape === 'Cuboid with arrows'
              ? cuboidWithArrowsDimens(
                  finalShapePath as
                    | 'cuboid1_3_arrows'
                    | 'cuboid2_3_arrows'
                    | 'cuboid3_3_arrows'
                    | 'cuboid4_3_arrows',
                  finalShapeDimen
                )[0]
              : finalShapeDimen,
          height:
            finalShape === 'Cuboid with arrows'
              ? cuboidWithArrowsDimens(
                  finalShapePath as
                    | 'cuboid1_3_arrows'
                    | 'cuboid2_3_arrows'
                    | 'cuboid3_3_arrows'
                    | 'cuboid4_3_arrows',
                  finalShapeDimen
                )[1]
              : finalShapeDimen,
          depth:
            finalShape === 'Cuboid with arrows'
              ? cuboidWithArrowsDimens(
                  finalShapePath as
                    | 'cuboid1_3_arrows'
                    | 'cuboid2_3_arrows'
                    | 'cuboid3_3_arrows'
                    | 'cuboid4_3_arrows',
                  finalShapeDimen
                )[2]
              : finalShapeDimen
        }
      ],
      { random: seededRandom(props.question) }
    );

    const answer = shapes.filter(shape => shape.isCorrect).map(shape => shape.shape);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectWhichOfTheseAreCubes()}
        pdfTitle={translate.instructions.circleWhichOfTheseAreCubes()}
        testCorrect={answer}
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: shape.hasArrows ? (
              <LabelledShape
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                shapeName={shape.shape as ShapeNames}
                labels={[
                  shape.height ? translate.units.numberOfCm(shape.height) : '',
                  shape.depth ? translate.units.numberOfCm(shape.depth) : '',
                  shape.width ? translate.units.numberOfCm(shape.width) : ''
                ]}
              />
            ) : (
              <AssetSvg
                name={shape.shape as SvgName}
                height={dimens.height * 0.9}
                width={dimens.width * 0.9}
              />
            )
          }));
        }}
        multiSelect
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'avC',
  description: 'avC',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    objects3dShape: z.array(z.string()),
    objectss2dShapes: z.array(z.string())
  }),
  simpleGenerator: () => {
    const numOf3d = randomIntegerInclusive(2, 3);
    const objects3dShape = getRandomUniqueRealLifeObjectOf3DShapesSvgNames(numOf3d);
    const objectss2dShapes = getRandomUniqueRealLifeObjectOf2DShapesSvgNames(4 - numOf3d);

    return {
      objects3dShape,
      objectss2dShapes
    };
  },
  Component: props => {
    const {
      question: { objects3dShape, objectss2dShapes },
      translate
    } = props;

    const allShapes = [...objects3dShape, ...objectss2dShapes];

    const items = allShapes.map(name => {
      return {
        value: name,
        component: (
          <View>
            <AssetSvg name={name as SvgName} width={150} height={150} />
          </View>
        )
      };
    });

    const answer =
      objects3dShape.length === 3
        ? [items[0].value, items[1].value, items[2].value]
        : [items[0].value, items[1].value];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.hereAreSomeShapesSelectAll3DShapes()}
        pdfTitle={translate.instructions.hereAreSomeShapesCircleAll3DShapes()}
        testCorrect={answer}
        numItems={4}
        multiSelect
        renderItems={shuffle(
          items.map(({ component, value }) => ({
            value,
            component
          })),
          { random: seededRandom(props.question) }
        )}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'avC2',
  description: 'avC',
  keywords: ['Shape', 'Face', 'Edge', 'Vertex'],
  schema: z.object({
    shapeNames: z.array(threeDShapesSchema),
    missingIndexes: z.array(z.number().int().min(1).max(3))
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const cubeOrCuboid = getRandomFromArray(['cubes', 'cuboids'] as const);

    const shapeNames = getRandomSubArrayFromArray(
      [
        'hexagonalPrisms',
        'pentagonalPrisms',
        'squareBasedPyramids',
        'triangularPrisms',
        cubeOrCuboid,
        'triangularBasedPyramids'
      ] as const,
      3
    );

    const missingIndexes = countRange(2).map(() => randomIntegerInclusive(1, 3));

    missingIndexes.push(
      randomIntegerInclusive(1, 3, { constraint: x => !missingIndexes.includes(x) })
    );

    return { shapeNames, missingIndexes };
  },
  Component: ({ question: { shapeNames, missingIndexes }, translate, displayMode }) => {
    const shapesData = shapeNames.map((name, idx) => {
      const shape = shapeProperties
        .filter(el => el.shape === name)
        .map(el => [shapes3DAsWord(el.shape, translate, 1), el.faces, el.edges, el.vertices])[0];

      return {
        shape,
        missingIndex: missingIndexes[idx]
      };
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTableToShowCorrectNumberOfFacesEdgesVertices()}
        cellHeaders={[
          {
            label: translate.tableHeaders.shape(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.faces(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.edges(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          },
          {
            label: translate.tableHeaders.vertices(),
            textStyle: { fontSize: displayMode === 'digital' ? 22 : 50 }
          }
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
        rowStyle={displayMode === 'digital' && { maxHeight: 110 }}
        tableData={shapesData.map(shapeData =>
          shapeData.shape.map((data, idx) =>
            shapeData.missingIndex === idx ? '<ans/>' : data.toString()
          )
        )}
        questionHeight={900}
        testCorrect={shapesData.map(shapeData =>
          shapeData.shape[shapeData.missingIndex].toString()
        )}
      />
    );
  }
});

// Question6 exported to Q aAI
const Question6 = newQuestionContent({
  uid: 'avD',
  description: 'avD',
  keywords: ['3-D', 'Shape', 'Properties'],
  schema: z.object({
    shape: threeDShapesSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'cuboids',
      'hexagonalPrisms',
      'pentagonalPrisms',
      'squareBasedPyramids',
      'triangularPrisms',
      'cubes',
      'cones',
      'cylinders',
      'triangularBasedPyramids'
    ] as const);

    return { shape };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const [shapeObject] = shapeProperties.filter(val => val.shape === shape);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.howManyFacesEdgesVerticesDoesTheShapeHave()}
        style={{ flexDirection: 'row' }}
        pdfDirection="column"
        questionHeight={900}
        pdfSentenceStyle={{ flexDirection: 'row' }}
        sentences={[
          translate.answerSentences.ansFaces(),
          translate.answerSentences.ansEdges(),
          translate.answerSentences.ansVertices()
        ]}
        testCorrect={[
          [shapeObject.faces.toString()],
          [shapeObject.edges.toString()],
          [shapeObject.vertices.toString()]
        ]}
        Content={({ dimens: { width, height } }) => (
          <View>
            <AssetSvg
              name={shapeObject.svg as SvgName}
              height={height * 0.85}
              width={width * 0.85}
            />
          </View>
        )}
      />
    );
  }
});
export const avD = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndDescribe3DShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5],
  unpublishedQuestionTypes: []
});
export default SmallStep;
