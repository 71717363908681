import { TranslationFunctions } from '../i18n/i18n-types';
import { sortNumberArray } from './collections';

/**
 * Function that takes in a total amount and an array of denominations, and tries to make the total amount using the denominations
 * provided.
 * Will always try and use the highest possible values passed in the array before using any lower values.
 * If the requested total cannot be made with the denominations, an error is thrown.
 */
export function totalSplitIntoDenominations(total: number, denominations: number[]): number[] {
  const sortedDenominations = sortNumberArray(denominations, 'descending');
  const result: number[] = [];

  for (const denomination of sortedDenominations) {
    while (total >= denomination) {
      result.push(denomination);
      total -= denomination;
    }
  }
  // Error if the total cannot be made using the denominations passed in:
  if (total !== 0)
    throw new Error(
      `No combination exists from [${denominations.map(
        num => `${num}, `
      )}] cannot combine to sum up to ${total}.`
    );

  return result;
}

export const measurements = ['Capacity', 'Length', 'Mass', 'Volume'] as const;
export type Measurements = (typeof measurements)[number];

export const measurementsWithTheArticle = (
  measurement: Measurements,
  translate: TranslationFunctions
) => {
  switch (measurement) {
    case 'Capacity':
      return translate.misc.theCapacity();
    case 'Length':
      return translate.misc.theLength();
    case 'Mass':
      return translate.misc.theMass();
    case 'Volume':
      return translate.misc.theVolume();
  }
};
