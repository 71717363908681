import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { SetState } from 'common/src/utils/react';
import { filledArray, range } from 'common/src/utils/collections';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import Table from 'common/src/components/molecules/Table';
import { useCallback, useContext } from 'react';
import { colors } from 'common/src/theme/colors';
import { withStateHOC } from '../../../stateTree';
import Svg, { Path } from 'react-native-svg';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import { getPlayer } from '../../../utils/Audio';

type Props = {
  /**
   * Total number of rows required.
   */
  numberOfRows: number;
  /**
   * Number of columns per row required.
   */
  numberOfCols: number;
  /**
   * Size of each square in the grid. Optional prop, defaults to calculations determined by the givenShape and dimens.
   */
  squareSize?: number;
  borderWidthAmount?: number;
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  /**
   * The user answer, i.e. which cells are highlighted.
   */
  userAnswer: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer: SetState<boolean[][]>;
  /**
   * Dimensions
   */
  dimens: Dimens;
  /**
   * Optional array to show define fixed cells and selectable cells.
   * If not set, all cells will be selectable and non fixed
   */
  array?: (null | 'fixed' | undefined)[][];
  /**
   * Optional answer offset if we have some unselectable columns
   * WARNING: Should be used as last resort
   */
  ansColumnOffset?: number;
  /**
   * Optional answer offset if we have some unselectable rows
   * WARNING: Should be used as last resort
   */
  ansRowOffset?: number;
};

export const CreateShapeFromSquares = ({
  numberOfRows,
  numberOfCols,
  squareSize = undefined,
  borderWidthAmount = undefined,
  cellSizeLabel,
  userAnswer,
  setUserAnswer,
  dimens,
  array,
  ansColumnOffset = 0,
  ansRowOffset = 0
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const styles = useStyles();

  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      setUserAnswer(userAnswer =>
        userAnswer.map((row, rowIndex) =>
          rowIndex !== rowIdx
            ? row
            : row.map((item, columnIndex) => (columnIndex !== colIdx ? item : !item))
        )
      );
    },
    [setUserAnswer]
  );

  // Firstly, calculate various layout dimensions.
  const maxWidth = dimens.width * 0.9;
  const maxHeight = dimens.height * 0.9;
  const squareDimens = squareSize ?? Math.min(maxHeight / numberOfRows, maxWidth / numberOfCols);
  const tableWidth = squareDimens * numberOfCols;
  const tableHeight = squareDimens * numberOfRows;

  const scaleFactor = useContext(ScaleFactorContext);
  const borderWidth = Math.round(borderWidthAmount ?? Math.max(2, 2 / scaleFactor));

  const squareColor = colors.burntSienna;
  const player = getPlayer();

  const items =
    array ?? range(0, numberOfRows - 1).map(() => range(0, numberOfCols - 1).map(() => null));

  // Now return a table
  return (
    <View style={{ width: tableWidth }}>
      {cellSizeLabel && (
        <View style={{ alignSelf: 'flex-end', alignItems: 'flex-end' }}>
          <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
          <Svg width={squareDimens} height={20}>
            <Path d={'M0,10 L10,5 L10,15 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
            <Path
              d={`M5,10 L${squareDimens - 5},10`}
              stroke={isPdf ? colors.black : colors.prussianBlue}
              strokeWidth={isPdf ? 4 : 2}
            />
            <Path
              d={`M${squareDimens},10
                    L${squareDimens - 10},5
                    L${squareDimens - 10},15
                    Z`}
              fill={isPdf ? colors.black : colors.prussianBlue}
            />
          </Svg>
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <Table
          style={{ width: tableWidth, height: tableHeight }}
          rowStyle={{ flex: 1 }} // Rows share the table height
          cellStyle={{ flex: 1 }} // Cells share the row width
          borderWidthAmount={borderWidth}
          items={items.map((row, rowIndex) =>
            row.map((column, columnIndex) => {
              if (column === 'fixed') {
                return (
                  <View
                    key={`${rowIndex}-${columnIndex}`}
                    style={{
                      width: squareDimens,
                      height: squareDimens,
                      alignSelf: 'stretch',
                      backgroundColor: colors.pacificBlue
                    }}
                  />
                );
              } else if (column === null) {
                return (
                  <TouchableOpacity
                    key={`${rowIndex}-${columnIndex}`}
                    style={{
                      flex: 1,
                      alignSelf: 'stretch',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: squareDimens,
                      height: squareDimens,
                      backgroundColor: userAnswer[rowIndex - ansRowOffset][
                        columnIndex - ansColumnOffset
                      ]
                        ? squareColor
                        : undefined
                    }}
                    onPress={() => {
                      player.playSound('tablecellon', numberOfRows - rowIndex);
                      handleOnPress(rowIndex - ansRowOffset, columnIndex - ansColumnOffset);
                    }}
                  ></TouchableOpacity>
                );
              }
            })
          )}
        />
        {cellSizeLabel && (
          <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
            <Svg width={20} height={squareDimens}>
              <Path d={'M10,0 L5,10 L15,10 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
              <Path
                d={`M10,5 L10,${squareDimens - 5}`}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={isPdf ? 4 : 2}
              />
              <Path
                d={`M10,${squareDimens}
                  L5,${squareDimens - 10}
                  L15,${squareDimens - 10}
                  Z`}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            </Svg>
            <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const filterSelectedCols = (array: boolean[][]) => {
  return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
};

/** See {@link CreateShapeFromSquares}. */
export const CreateShapeFromSquaresWithState = withStateHOC(CreateShapeFromSquares, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: ({ numberOfRows, numberOfCols }) => ({
    defaultState: filledArray(null, numberOfRows).map(() => filledArray(false, numberOfCols)),
    testComplete: userAnswer => filterSelectedCols(userAnswer).length > 0
  })
});

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return StyleSheet.create({
    cellLabel: {
      fontSize: displayMode === 'digital' ? 20 : 40,
      alignSelf: 'center',
      lineHeight: displayMode === 'digital' ? 20 : 40
    }
  });
};
