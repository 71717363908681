import BaseLayout from 'common/src/components/molecules/BaseLayout';
import {
  BarModelInteractive,
  BarModelInteractiveWithState
} from 'common/src/components/question/representations/BarModelInteractive';
import { BarModelColorsKey } from 'common/src/theme/colors';
import { filledArray } from 'common/src/utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext, useMemo } from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { createShapeWithSquares } from '../../../utils/shapes';
import { MeasureView } from '../../atoms/MeasureView';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { useI18nContext } from '../../../i18n/i18n-react';
import { seededRandom } from '../../../utils/random';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  /**
   * Number of rows
   */
  numberOfRows: number;
  /**
   * Number of columns - Will be the same amount per row
   */
  numberOfCols: number;
  /**
   * Number or function to check correct answer
   */
  testCorrect: number;
  /**
   * Color
   */
  color?: BarModelColorsKey;
  tableHeight?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  braceTextStyle?: StyleProp<TextStyle>;
};

export default function QF20aBarModelInteractive({
  numberOfRows,
  numberOfCols,
  title,
  testCorrect,
  color,
  tableHeight,
  questionHeight,
  fullWidthTopBrace,
  braceTextStyle,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  const initialState = filledArray(null, numberOfRows).map(() => filledArray(false, numberOfCols));

  const filterSelectedCols = (array: boolean[][]) => {
    return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
  };

  const exampleMarkSchemeAnswer = useMemo(() => {
    const random = seededRandom({ numberOfCols, numberOfRows, testCorrect });
    return displayMode === 'markscheme'
      ? createShapeWithSquares(
          numberOfRows,
          numberOfCols,
          testCorrect,
          false,
          { random },
          undefined,
          false
        )
      : filledArray(null, numberOfRows).map(() => filledArray(false, numberOfCols));
  }, [displayMode, numberOfCols, numberOfRows, testCorrect]);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <BarModelInteractive
                  userAnswer={exampleMarkSchemeAnswer}
                  numberOfCols={numberOfCols}
                  numberOfRows={numberOfRows}
                  color={color}
                  tableHeight={
                    tableHeight ??
                    (numberOfRows * 150 < dimens.height ? numberOfRows * 150 : dimens.height)
                  }
                  tableWidth={dimens.width}
                  fullWidthTopBrace={fullWidthTopBrace}
                  braceTextStyle={braceTextStyle}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              renderMarkSchemeProp(translate.markScheme.segmentsCanBeInAnyOrder())}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <BarModelInteractiveWithState
              id="barmodel"
              defaultState={initialState}
              testComplete={answer => filterSelectedCols(answer).length > 0}
              testCorrect={userAnswer => {
                // Filter true items
                const selectedCols = filterSelectedCols(userAnswer);
                // Get length of selected columns
                let selectedColsLength = 0;
                selectedCols.forEach(col => (selectedColsLength += col.length));

                // Test whether they got it right.
                return selectedColsLength === testCorrect;
              }}
              numberOfRows={numberOfRows}
              numberOfCols={numberOfCols}
              color={color}
              tableHeight={
                tableHeight ??
                (numberOfRows * 100 < dimens.height ? numberOfRows * 100 : dimens.height)
              }
              tableWidth={dimens.width}
              fullWidthTopBrace={fullWidthTopBrace}
              braceTextStyle={braceTextStyle}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
