import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, DIV, MULT } from '../../../../constants';
import { compareFractions } from '../../../../utils/fractions';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { barModelColors, barModelColorsArray } from '../../../../theme/colors';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQa',
  description: 'aQa',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator',
    'Bar model'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(6),
      multiplier: z.number().int().min(2).max(24)
    })
    .refine(
      val => val.multiplier % val.denominator === 0,
      'multiplier must be a multiple of denominator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);

    const multiplier = randomIntegerInclusive(2, 24, {
      // Restrict factor to be less than 5 so content actually fits.
      constraint: x => x % denominator === 0 && x / denominator < 5
    });

    return { denominator, multiplier };
  },
  Component: props => {
    const {
      question: { denominator, multiplier },
      translate,
      displayMode
    } = props;

    const factor = multiplier / denominator;

    const [colorA, colorB] = getRandomSubArrayFromArray(barModelColorsArray, 2, {
      random: seededRandom(props.question)
    });

    const items = shuffle(
      [
        translate.answerSentences.numLotsOfX(
          multiplier,
          `<frac n='1' d='${denominator.toLocaleString()}' />`
        ),
        translate.answerSentences.xOfY(
          `<frac n='1' d='${denominator.toLocaleString()}' />`,
          multiplier.toLocaleString()
        )
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcsToReps()}
        pdfTitle={translate.instructions.useCardsToMatchCalcsToReps()}
        statementStyle={{ marginVertical: 16, justifyContent: 'center' }}
        statements={[
          {
            lhsComponent: (
              <View style={{ rowGap: 16 }}>
                {range(1, factor).map(num => (
                  <ShadedFractionBarModel
                    key={num}
                    totalSubSections={denominator}
                    width={400}
                    color={barModelColors[colorA]}
                  />
                ))}
              </View>
            ),
            correctAnswer: translate.answerSentences.numLotsOfX(
              multiplier,
              `<frac n='1' d='${denominator.toLocaleString()}' />`
            )
          },
          {
            lhsComponent: (
              <View>
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  coloredSections={[0]}
                  width={400}
                  color={barModelColors[colorB]}
                  fullWidthTopBrace={multiplier.toLocaleString()}
                />
              </View>
            ),
            correctAnswer: translate.answerSentences.xOfY(
              `<frac n='1' d='${denominator.toLocaleString()}' />`,
              multiplier.toLocaleString()
            )
          }
        ]}
        items={items.map(item => ({
          value: item,
          component: (
            <TextStructure
              sentence={item}
              textVariant="WRN700"
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          )
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQb',
  description: 'aQb',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator'
  ],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(10),
      multiplierA: z.number().int().min(3).max(20),
      denominatorB: z.number().int().min(2).max(10),
      totalB: z.number().int().min(2).max(20)
    })
    .refine(
      val => val.multiplierA > val.denominatorA,
      'multiplierA must be greater than denominatorA.'
    )
    .refine(
      val => val.multiplierA % val.denominatorA === 0,
      'multiplierA must be a multiple of denominatorA.'
    )
    .refine(
      val => val.totalB % val.denominatorB === 0,
      'totalB must be a multiple of denominatorB.'
    )
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    ),
  simpleGenerator: () => {
    const { denominatorA, multiplierA, denominatorB, totalB } = rejectionSample(
      () => {
        const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

        const multiplierA = randomIntegerInclusive(denominatorA + 1, 20, {
          constraint: x => x % denominatorA === 0
        });

        const totalB = randomIntegerInclusive(2, 20, {
          constraint: x => x % denominatorB === 0
        });
        return { denominatorA, multiplierA, denominatorB, totalB };
      },
      ({ denominatorA, multiplierA, denominatorB, totalB }) => {
        const answerA = multiplierA / denominatorA;
        // The following will be used as items - these should all be unique:
        return arrayHasNoDuplicates([multiplierA, answerA, totalB, denominatorB]);
      }
    );

    return { denominatorA, multiplierA, denominatorB, totalB };
  },
  Component: props => {
    const {
      question: { denominatorA, multiplierA, denominatorB, totalB },
      translate,
      displayMode
    } = props;

    const answerA = multiplierA / denominatorA;
    const answerB = totalB / denominatorB;

    const items = shuffle(
      [
        multiplierA.toLocaleString(),
        answerA.toLocaleString(),
        totalB.toLocaleString(),
        denominatorB.toLocaleString()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const sentences = [
      {
        sentence: `<frac n='1' d='${denominatorA.toLocaleString()}' /> ${MULT} <ans/> = <frac n='${multiplierA.toLocaleString()}' d='${denominatorA.toLocaleString()}' /> = <ans/>`,
        answer: [multiplierA.toLocaleString(), answerA.toLocaleString()]
      },
      {
        sentence: `${translate.answerSentences.xOfY(
          `<frac n='1' d='${denominatorB.toLocaleString()}' />`,
          totalB.toLocaleString()
        )} = <ans/> ${DIV} <ans/> = ${answerB.toLocaleString()}`,
        answer: [totalB.toLocaleString(), denominatorB.toLocaleString()]
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => sentence.answer)}
        moveOrCopy="move"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQc',
  description: 'aQc',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(10),
      numerator: z.number().int().min(1).max(9),
      total: z.number().int().min(2).max(12)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.')
    .refine(
      val => val.numerator * val.total > val.denominator,
      'numerator x total must be greater than denominator.'
    )
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.'),
  simpleGenerator: () => {
    const { denominator, numerator, total } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 10);

        const numerator = randomIntegerInclusive(1, denominator - 1);

        const total = randomIntegerInclusive(2, 12, {
          constraint: x => x % denominator === 0
        });
        return { denominator, numerator, total };
      },
      ({ denominator, numerator, total }) =>
        // Ensure the correct and incorrect answers generate different results - need to be here rather than in total constraints
        // to allow complete regeneration of numbers if any of these are invalid:
        (total * numerator) / denominator !== (denominator * numerator) / total &&
        total * numerator > denominator
    );

    return { denominator, numerator, total };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { denominator, numerator, total },
      translate,
      displayMode
    } = props;

    const correctSentence = `<frac n='${numerator}' d='${denominator}'/> ${MULT} ${total.toLocaleString()}`;

    const incorrectSentences = [
      `<frac n='${numerator}' d='${total}'/> ${MULT} ${denominator.toLocaleString()}`,
      `<frac n='${numerator}' d='${total}'/> ${ADD} ${denominator.toLocaleString()}`,
      `<frac n='${numerator}' d='${denominator}'/> ${ADD} ${total.toLocaleString()}`
    ];

    const items = shuffle([correctSentence, ...incorrectSentences], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.whichCalcGivesSameAnswerAsStatement()}
        pdfTitle={translate.instructions.whichCalcGivesSameAnswerAsStatementPdf()}
        testCorrect={[correctSentence]}
        numItems={4}
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, minWidth: dimens.width / 3, justifyContent: 'center' }}
          >
            <ContentBox>
              <TextStructure
                fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                textVariant="WRN400"
                sentence={translate.answerSentences.xOfY(
                  `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`,
                  total.toLocaleString()
                )}
              />
            </ContentBox>
          </View>
        )}
        renderItems={items.map(item => ({
          component: (
            <TextStructure
              sentence={item}
              textVariant="WRN700"
              fractionTextStyle={{
                fontWeight: '700',
                fontSize: displayMode === 'digital' ? 32 : 50
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: item
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQd',
  description: 'aQd',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator'
  ],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(10),
      numeratorA: z.number().int().min(1).max(9),
      totalA: z.number().int().min(2).max(200),
      denominatorB: z.number().int().min(2).max(10),
      numeratorB: z.number().int().min(1).max(9),
      totalB: z.number().int().min(2).max(200),
      denominatorC: z.number().int().min(2).max(10),
      numeratorC: z.number().int().min(1).max(9),
      totalC: z.number().int().min(2).max(200)
    })
    .refine(
      val =>
        val.numeratorA < val.denominatorA &&
        val.numeratorB < val.denominatorB &&
        val.numeratorC < val.denominatorC,
      'All numerators must be less than their matching denominators.'
    )
    .refine(
      val =>
        val.totalA % val.denominatorA === 0 &&
        val.totalB % val.denominatorB === 0 &&
        val.totalC % val.denominatorC === 0,
      'All totals must be multiples of their matching denominators.'
    )
    .refine(
      val =>
        val.totalA * val.numeratorA > val.denominatorA &&
        val.totalB * val.numeratorB > val.denominatorB &&
        val.totalC * val.numeratorC > val.denominatorC,
      'total x numerator must be greater than denominator for each equation.'
    ),
  simpleGenerator: () => {
    const {
      denominatorA,
      numeratorA,
      totalA,
      denominatorB,
      numeratorB,
      totalB,
      denominatorC,
      numeratorC,
      totalC
    } = rejectionSample(
      () => {
        const [denominatorA, denominatorB, denominatorC] = randomUniqueIntegersInclusive(2, 10, 3);

        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);

        const totalA = randomIntegerInclusive(2, 200, {
          constraint: x => x % denominatorA === 0 && x * numeratorA > denominatorA
        });

        const totalB = randomIntegerInclusive(2, 200, {
          constraint: x => x % denominatorB === 0 && x * numeratorB > denominatorB
        });

        const totalC = randomIntegerInclusive(2, 200, {
          constraint: x => x % denominatorC === 0 && x * numeratorC > denominatorC
        });

        return {
          denominatorA,
          numeratorA,
          totalA,
          denominatorB,
          numeratorB,
          totalB,
          denominatorC,
          numeratorC,
          totalC
        };
      },
      ({ totalA, totalB, totalC }) => {
        return arrayHasNoDuplicates([totalA, totalB, totalC]);
      }
    );

    return {
      denominatorA,
      numeratorA,
      totalA,
      denominatorB,
      numeratorB,
      totalB,
      denominatorC,
      numeratorC,
      totalC
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        totalA,
        denominatorB,
        numeratorB,
        totalB,
        denominatorC,
        numeratorC,
        totalC
      },
      translate,
      displayMode
    } = props;

    const items = [
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' /> ${MULT} ${totalA.toLocaleString()}`}
          />
        ),
        value: 'A'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' /> ${MULT} ${totalB.toLocaleString()}`}
          />
        ),
        value: 'B'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' /> ${MULT} ${totalC.toLocaleString()}`}
          />
        ),
        value: 'C'
      }
    ];

    const sentences = shuffle(
      [
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' />`,
            totalA.toLocaleString()
          )} <ans/>`,
          answer: 'A'
        },
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`,
            totalB.toLocaleString()
          )} <ans/>`,
          answer: 'B'
        },
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' />`,
            totalC.toLocaleString()
          )} <ans/>`,
          answer: 'C'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.useCardsToMatchCalcs()}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        items={items}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => [sentence.answer])}
        moveOrCopy="move"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aQe',
  description: 'aQe',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator'
  ],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(10),
      numeratorA: z.number().int().min(1).max(9),
      multiplierA: z.number().int().min(2).max(12),
      answerBoxRightA: z.boolean(),
      denominatorB: z.number().int().min(2).max(10),
      numeratorB: z.number().int().min(1).max(9),
      totalB: z.number().int().min(2).max(12),
      answerBoxRightB: z.boolean()
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(
      val => val.numeratorA * val.multiplierA > val.denominatorA,
      'multiplierA x numeratorA must be greater than denominatorA.'
    )
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.')
    .refine(
      val => val.totalB % val.denominatorB === 0,
      'totalB must be a multiple of denominatorB.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

    const multiplierA = randomIntegerInclusive(2, 12, {
      constraint: x => x * numeratorA > denominatorA
    });

    const answerBoxRightA = getRandomBoolean();

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    const totalB = randomIntegerInclusive(2, 12, {
      constraint: x => x % denominatorB === 0
    });

    const answerBoxRightB = getRandomBoolean();

    return {
      denominatorA,
      numeratorA,
      multiplierA,
      answerBoxRightA,
      denominatorB,
      numeratorB,
      totalB,
      answerBoxRightB
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        multiplierA,
        answerBoxRightA,
        denominatorB,
        numeratorB,
        totalB,
        answerBoxRightB
      },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0][0], userAnswer[0][1]],
            [numeratorA * multiplierA, denominatorA]
          ) && userAnswer[1][0] === ((totalB / denominatorB) * numeratorB).toString()
        }
        inputMaxCharacters={2}
        sentences={[
          answerBoxRightA
            ? `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${MULT} ${multiplierA.toLocaleString()} = <frac nAns='' dAns='' />`
            : `<frac nAns='' dAns='' /> = <frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${MULT} ${multiplierA.toLocaleString()}`,
          answerBoxRightB
            ? translate.answerSentences.fracOfNumEqualsAns(
                `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`,
                totalB
              )
            : translate.answerSentences.ansEqualsFracOfNum(
                `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`,
                totalB
              )
        ]}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(numeratorA * multiplierA).toLocaleString(), denominatorA.toLocaleString()],
            [((totalB / denominatorB) * numeratorB).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQf',
  description: 'aQf',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Integer',
    'Numerator',
    'Denominator',
    'Fraction as operator'
  ],
  schema: z
    .object({
      correctDenominator: z.number().int().min(2).max(12),
      correctNumerator: z.number().int().min(1).max(11),
      correctTotal: z.number().int().min(2).max(12),
      incorrectDenominatorA: z.number().int().min(2).max(10),
      incorrectNumeratorA: z.number().int().min(1).max(9),
      incorrectTotalA: z.number().int().min(2).max(12),
      incorrectDenominatorB: z.number().int().min(2).max(10),
      incorrectNumeratorB: z.number().int().min(1).max(9),
      incorrectTotalB: z.number().int().min(2).max(12),
      incorrectResultC: z.number().int().min(2).max(100)
    })
    .refine(
      val => val.correctNumerator < val.correctDenominator,
      'correctNumerator must be less than correctDenominator.'
    )
    .refine(
      val => val.correctNumerator * val.correctTotal > val.correctDenominator,
      'correctNumerator x correctTotal must be greater than correctDenominator.'
    )
    .refine(
      val => val.correctTotal % val.correctDenominator === 0,
      'correctTotal must be a multiple of correctDenominator.'
    ),
  simpleGenerator: () => {
    const {
      correctDenominator,
      correctNumerator,
      correctTotal,
      incorrectDenominatorA,
      incorrectNumeratorA,
      incorrectTotalA,
      incorrectDenominatorB,
      incorrectNumeratorB,
      incorrectTotalB,
      incorrectResultC
    } = rejectionSample(
      () => {
        const correctDenominator = randomIntegerInclusive(2, 12);

        const correctNumerator = randomIntegerInclusive(1, correctDenominator - 1);

        const correctTotal = randomIntegerInclusive(2, 12, {
          constraint: x => x % correctDenominator === 0
        });

        const [incorrectDenominatorA, incorrectDenominatorB] = randomUniqueIntegersInclusive(
          2,
          10,
          2,
          {
            constraint: x => x !== correctDenominator
          }
        );

        const incorrectNumeratorA = randomIntegerInclusive(1, incorrectDenominatorA - 1);

        const incorrectTotalA = randomIntegerInclusive(2, 12, {
          constraint: x => x % incorrectDenominatorA === 0
        });

        const incorrectNumeratorB = randomIntegerInclusive(1, incorrectDenominatorB - 1);

        const incorrectTotalB = randomIntegerInclusive(2, 12, {
          constraint: x => x % incorrectDenominatorB === 0
        });

        const incorrectResultC = randomIntegerInclusive(2, 100);
        return {
          correctDenominator,
          correctNumerator,
          correctTotal,
          incorrectDenominatorA,
          incorrectNumeratorA,
          incorrectTotalA,
          incorrectDenominatorB,
          incorrectNumeratorB,
          incorrectTotalB,
          incorrectResultC
        };
      },
      ({
        correctDenominator,
        correctNumerator,
        correctTotal,
        incorrectDenominatorA,
        incorrectNumeratorA,
        incorrectTotalA,
        incorrectDenominatorB,
        incorrectNumeratorB,
        incorrectTotalB,
        incorrectResultC
      }) => {
        const correctResult = (correctTotal * correctNumerator) / correctDenominator;

        const incorrectResultA = (incorrectTotalA * incorrectNumeratorA) / incorrectDenominatorA;

        const incorrectResultB = (incorrectTotalB * incorrectNumeratorB) / incorrectDenominatorB;
        // The following will be used as items - these should all be unique:
        return (
          arrayHasNoDuplicates([
            correctResult,
            incorrectResultA,
            incorrectResultB,
            incorrectResultC
          ]) &&
          correctTotal * correctNumerator > correctDenominator &&
          incorrectTotalA * incorrectNumeratorA > incorrectDenominatorA &&
          incorrectTotalB * incorrectNumeratorB > incorrectDenominatorB
        );
      }
    );

    return {
      correctDenominator,
      correctNumerator,
      correctTotal,
      incorrectDenominatorA,
      incorrectNumeratorA,
      incorrectTotalA,
      incorrectDenominatorB,
      incorrectNumeratorB,
      incorrectTotalB,
      incorrectResultC
    };
  },
  Component: props => {
    const {
      question: {
        correctDenominator,
        correctNumerator,
        correctTotal,
        incorrectDenominatorA,
        incorrectNumeratorA,
        incorrectTotalA,
        incorrectDenominatorB,
        incorrectNumeratorB,
        incorrectTotalB,
        incorrectResultC
      },
      translate
    } = props;

    const correctResult = (correctTotal * correctNumerator) / correctDenominator;

    const items = shuffle(
      [
        {
          sentence: translate.answerSentences.xOfY(
            `<frac n='${correctNumerator.toLocaleString()}' d='${correctDenominator.toLocaleString()}' />`,
            correctTotal.toLocaleString()
          ),
          isCorrect: true
        },
        {
          sentence: `<frac n='${correctNumerator.toLocaleString()}' d='${correctDenominator.toLocaleString()}' /> ${MULT} ${correctTotal.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: `${correctResult.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: translate.answerSentences.xOfY(
            `<frac n='${incorrectNumeratorA.toLocaleString()}' d='${incorrectDenominatorA.toLocaleString()}' />`,
            incorrectTotalA.toLocaleString()
          ),
          isCorrect: false
        },
        {
          sentence: `<frac n='${incorrectNumeratorB.toLocaleString()}' d='${incorrectDenominatorB.toLocaleString()}' /> ${MULT} ${incorrectTotalB.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${incorrectResultC.toLocaleString()}`,
          isCorrect: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectStatementCardsThatAreEquiv()}
        pdfTitle={translate.instructions.circleStatementCardsThatAreEquiv()}
        // use flat map to filter for correct answers and map to the index in one go
        testCorrect={items.flatMap((equation, index) => (equation.isCorrect ? [index] : []))}
        items={items.map((equation, index) => ({
          // value of each answer is just its index in the `items` array
          value: index,
          component: (
            <TextStructure
              sentence={equation.sentence}
              textStyle={{ fontSize: 30, fontWeight: '700' }}
              fractionTextStyle={{ fontSize: 30, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          )
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseFractionsAsOperators',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
