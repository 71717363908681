/**
 * This is a function to find all the factor pairs of a number.
 * It utilises the findFactors function.
 * Square numbers will have [squareRoot, squareRoot] as a factor pair.
 * Number must be positive.
 */
export function findFactorPairs(num: number): [number, number][] {
  const factorPairs: [number, number][] = [];
  const sqrtNum = Math.sqrt(num);
  // Loop through numbers from 1 to the square root of num
  for (let i = 1; i <= sqrtNum; i++) {
    // If num is divisible by i with no remainder, it must be a factor.
    if (num % i === 0) {
      factorPairs.push([i, num / i]);
    }
  }
  return factorPairs;
}

/**
 * This is a function to find all the factors of a number.
 * Number must be positive.
 */
export function findFactors(num: number): number[] {
  return findFactorPairs(num)
    .flatMap(pair => {
      // avoid duplicates
      return pair[0] === pair[1] ? [pair[0]] : pair;
    })
    .sort((a, b) => a - b);
}

/**
 * Find all factors of a number n, but ignore 1 and m.
 * Number must be positive.
 */
export function findFactorsExcludingSelfAnd1(num: number): number[] {
  return findFactors(num).slice(1, -1);
}

/**
 * Function to determine whether a given number is a square number.
 *
 * @param num the number to determine
 * @returns boolean of whether square or not
 */
export function isSquare(num: number): boolean {
  return Number.isInteger(Math.sqrt(num));
}

/**
 * Function to determine whether a given number is a cube number.
 *
 * @param num the number to determine
 * @returns boolean of whether cube or not
 */
export function isCube(num: number): boolean {
  return Number.isInteger(Math.cbrt(num));
}
