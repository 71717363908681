import { ReactNode, useContext } from 'react';
import { StyleProp, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import { useTheme } from '../../theme';
import { ScaleFactorContext, useWindowLayout } from '../../theme/scaling';
import Animated, { FadeOut, FadeIn, FadeInDown } from 'react-native-reanimated';

/**
 * A dismissable modal.
 */
export default function Modal({
  dismissable = true,
  onDismiss = () => {
    /* Do nothing */
  },
  children,
  style,
  containerStyle,
  /** Disable the animations for edge-cases observed in Android for the Logout Warning Modal */
  disableAnimations = false
}: {
  dismissable?: boolean;
  onDismiss?: () => void;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  disableAnimations?: boolean;
}) {
  const styles = useStyles();

  return (
    <Animated.View
      testID="MODAL"
      style={[StyleSheet.absoluteFill, styles.outerStyle, style]}
      entering={disableAnimations ? undefined : FadeIn}
      exiting={disableAnimations ? undefined : FadeOut}
    >
      <TouchableWithoutFeedback onPress={dismissable ? onDismiss : undefined}>
        <View style={[StyleSheet.absoluteFill, styles.backdrop]} />
      </TouchableWithoutFeedback>
      <Animated.View
        style={[styles.container, containerStyle]}
        entering={disableAnimations ? undefined : FadeInDown}
        exiting={disableAnimations ? undefined : FadeOut}
      >
        {children}
      </Animated.View>
    </Animated.View>
  );
}

const useStyles = function () {
  const theme = useTheme();
  const { insets } = useWindowLayout();
  const scaleFactor = useContext(ScaleFactorContext);

  return StyleSheet.create({
    outerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: insets.left / scaleFactor,
      paddingBottom: insets.bottom / scaleFactor,
      paddingRight: insets.right / scaleFactor,
      paddingTop: insets.top / scaleFactor
    },

    backdrop: {
      backgroundColor: '#322f3766'
    },

    container: {
      minWidth: 580,
      minHeight: '30%',
      maxHeight: '90%',
      maxWidth: '90%',
      backgroundColor: theme.colors.surface
    }
  });
};
