import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { Point2d } from '../../../../utils/vectors';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import { G, Text as SvgText, TSpan } from 'react-native-svg';
import { GridLine, GridPolygon, GridText } from 'common/src/utils/gridUtils';
import { isValidRightAngledTriangle } from '../../../../utils/shapes';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import {
  arraysHaveSameContents,
  countRange,
  nestedArrayHasNoDuplicates
} from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../../constants';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import { isInRange } from '../../../../utils/matchers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { parseToSUB } from '../../../../utils/parse';
import Text from 'common/src/components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFq',
  description: 'aFq',
  keywords: ['Coordinate', 'Reflection'],
  schema: z.object({
    triangle1: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle2: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle3: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle4: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    labels: z.array(z.enum(['A', 'B', 'C', 'D'])).length(4)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(1, 2);
    const width = randomIntegerInclusive(1, 3, { constraint: x => x !== height });
    const isHorizontal = getRandomBoolean();

    // position of right angle
    const raPosition = getRandomFromArray([
      'topLeft',
      'topRight',
      'bottomLeft',
      'bottomRight'
    ] as const);

    const mappingRotation = {
      topLeft: 'bottomRight',
      topRight: 'bottomLeft',
      bottomLeft: 'topRight',
      bottomRight: 'topLeft'
    };

    const triangles = [raPosition, mappingRotation[raPosition]];

    // do all triangles in q1 and then translate
    const points = triangles.map(type => {
      const trianglePoint1 = {
        x: randomIntegerInclusive(-4, -1 - width),
        y:
          type === 'topRight' || type === 'topLeft'
            ? randomIntegerInclusive(1 + height, 3)
            : randomIntegerInclusive(1, 3 - height)
      };
      const trianglePoint2 = {
        x: trianglePoint1.x + width,
        y: trianglePoint1.y
      };
      const trianglePoint3 = {
        x:
          type === 'topRight' || type === 'bottomRight'
            ? trianglePoint1.x + width
            : trianglePoint1.x,
        y:
          type === 'topRight' || type === 'topLeft'
            ? trianglePoint1.y - height
            : trianglePoint1.y + height
      };
      return [trianglePoint1, trianglePoint2, trianglePoint3];
    });

    const quads = [
      { dx: 0, dy: 0, label: 'A' as const, quad: 'Q1' },
      { dx: 4, dy: 0, label: 'B' as const, quad: 'Q2' },
      { dx: 0, dy: -4, label: 'C' as const, quad: 'Q3' },
      { dx: 4, dy: -4, label: 'D' as const, quad: 'Q4' }
    ];

    const quad2 = quads.filter(val => (isHorizontal ? val.quad === 'Q3' : val.quad === 'Q2'))[0];

    const finalQuads = [
      quads[0],
      quad2,
      ...shuffle(quads.filter(val => ![quads[0], quad2].includes(val)))
    ];

    const triangle1 = points[0].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(finalQuads[0].dx, finalQuads[0].dy))
    );
    // reflection
    const triangle2 = points[0].map(val =>
      isHorizontal ? new Point2d(val.x, -val.y) : new Point2d(-val.x, val.y)
    );
    // rotate
    const triangle3 = points[1].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(finalQuads[2].dx, finalQuads[2].dy))
    );
    // translate
    const triangle4 = points[0].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(finalQuads[3].dx, finalQuads[3].dy))
    );

    const labels = finalQuads.map(val => val.label);
    return { triangle1, triangle2, triangle3, triangle4, labels };
  },
  Component: ({ question, translate }) => {
    const { triangle1, triangle2, triangle3, triangle4, labels } = question;
    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTriangleThatIsAReflectionOfTriangleA()}
        pdfTitle={translate.instructions.circleTriangleThatIsAReflectionOfTriangleA()}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMin={-5}
                xMax={5}
                yMin={-4}
                yMax={4}
                squareGrid
                children={({ mathToSvgX, mathToSvgY }) => (
                  <GridSvgChildren>
                    {[triangle1, triangle2, triangle3, triangle4].map((triangle, i) => {
                      return (
                        <G key={i}>
                          <SvgText
                            y={mathToSvgY(triangle[0].y + 0.2)}
                            x={mathToSvgX(
                              Math.max(triangle[0].x, triangle[1].x, triangle[2].x) + 0.4
                            )}
                            textAnchor="middle"
                          >
                            <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                              {translate.letters[labels[i] as 'A' | 'B' | 'C' | 'D']()}
                            </TSpan>
                          </SvgText>
                          <GridPolygon
                            points={triangle.map(val => [val.x, val.y] as [number, number])}
                            showBorder
                          />
                        </G>
                      );
                    })}
                  </GridSvgChildren>
                )}
              ></Grid>
            )}
          </MeasureView>
        }
        correctAnswer={labels
          .filter((_val, i) => i === 1)
          .map(val => translate.letters[val as 'B' | 'C' | 'D']())}
        selectables={Object.fromEntries(
          ['B', 'C', 'D'].map(val => [val, translate.letters[val as 'B' | 'C' | 'D']()])
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aFr',
  description: 'aFr',
  keywords: ['Coordinate', 'Reflection'],
  schema: z.object({
    triangle1: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle2: z
      .array(z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) }))
      .length(3),
    isCorrect: z.boolean(),
    isYAxis: z.boolean()
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isYAxis = getRandomBoolean();
        const isCorrect = getRandomBoolean();

        const number1 = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const number2 = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const number3 = isYAxis
          ? number1 < 0
            ? randomIntegerInclusive(-3, -1, { constraint: x => x !== number1 })
            : randomIntegerInclusive(1, 3, { constraint: x => x !== number1 })
          : randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 && x !== number1 });
        const number4 = !isYAxis
          ? number2 < 0
            ? randomIntegerInclusive(-3, -1, { constraint: x => x !== number2 })
            : randomIntegerInclusive(1, 3, { constraint: x => x !== number2 })
          : randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 && x !== number2 });

        const point1 = {
          x: number1,
          y: number2
        };

        const point2 = {
          x: number1,
          y: number4
        };

        const point3 = {
          x: number3,
          y: number4
        };

        const triangle1 = [point1, point2, point3];

        let triangle2;
        if (isCorrect) {
          triangle2 = triangle1.map(val =>
            isYAxis ? new Point2d(-val.x, val.y) : new Point2d(val.x, -val.y)
          );
        } else {
          const triangle2Points = getRandomFromArray([
            [
              {
                x: number1 + 1,
                y: number2
              },
              {
                x: number1 + 1,
                y: number4
              },
              {
                x: number3 + 1,
                y: number4
              }
            ],
            [
              {
                x: number1 - 1,
                y: number2
              },
              {
                x: number1 - 1,
                y: number4
              },
              {
                x: number3 - 1,
                y: number4
              }
            ],
            [
              {
                x: number1,
                y: number2 + 1
              },
              {
                x: number1,
                y: number4 + 1
              },
              {
                x: number3,
                y: number4 + 1
              }
            ],
            [
              {
                x: number1,
                y: number2 - 1
              },
              {
                x: number1,
                y: number4 - 1
              },
              {
                x: number3,
                y: number4 - 1
              }
            ]
          ]);
          triangle2 = triangle2Points.map(val =>
            isYAxis ? new Point2d(-val.x, val.y) : new Point2d(val.x, -val.y)
          );
        }
        return { triangle1, triangle2, isCorrect, isYAxis };
      },
      val =>
        val.triangle2.every(obj => obj.x >= -4 && obj.x <= 4 && obj.y >= -4 && obj.y <= 4) &&
        isValidRightAngledTriangle(
          [val.triangle1[0].x, val.triangle1[0].y],
          [val.triangle1[1].x, val.triangle1[1].y],
          [val.triangle1[2].x, val.triangle1[2].y]
        ) &&
        isValidRightAngledTriangle(
          [val.triangle2[0].x, val.triangle2[0].y],
          [val.triangle2[1].x, val.triangle2[1].y],
          [val.triangle2[2].x, val.triangle2[2].y]
        ) &&
        Math.abs(val.triangle1[0].x - val.triangle1[2].x) >= 2 &&
        Math.abs(val.triangle1[0].y - val.triangle1[2].y) >= 2
    ),
  Component: ({ question, translate, displayMode }) => {
    const { triangle1, triangle2, isCorrect, isYAxis } = question;
    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.hasTheTriangleBeenReflectedInAxis(
          isYAxis ? ALGEBRAIC_Y : ALGEBRAIC_X
        )}
        pdfTitle={`${translate.instructions.hasTheTriangleBeenReflectedInAxis(
          isYAxis ? ALGEBRAIC_Y : ALGEBRAIC_X
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMin={-5}
                xMax={5}
                yMin={-4}
                yMax={4}
                squareGrid
                children={
                  <GridSvgChildren>
                    {[triangle1, triangle2].map((triangle, i) => {
                      return (
                        <GridPolygon
                          key={i}
                          points={triangle.map(val => [val.x, val.y] as [number, number])}
                          showBorder
                        />
                      );
                    })}
                    <GridLine
                      points={
                        isYAxis
                          ? [
                              [0, 4.5],
                              [0, -4.5]
                            ]
                          : [
                              [-5.5, 0],
                              [5.5, 0]
                            ]
                      }
                      color={colors.red}
                      strokeWidth={displayMode === 'digital' ? 2 : 4}
                    />
                  </GridSvgChildren>
                }
              ></Grid>
            )}
          </MeasureView>
        }
        correctAnswer={[isCorrect ? translate.misc.Yes() : translate.misc.No()]}
        selectables={Object.fromEntries([
          ['Yes', translate.misc.Yes()],
          ['No', translate.misc.No()]
        ])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aFs',
  description: 'aFs',
  keywords: ['Coordinate', 'Vertex', 'Reflection'],
  schema: z
    .object({
      coordinates1: z.number().int().min(-5).max(5).array().length(2),
      isHorizontal: z.boolean(),
      lineIntercept: z.number().int().min(-3).max(3),
      answer: z.number().int().min(-5).max(5).array().length(2)
    })
    .refine(
      val => val.answer[1] <= 4 && val.answer[1] >= -4 && val.answer[0] <= 5 && val.answer[0] >= -5,
      'answer is within the grid'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isHorizontal = getRandomBoolean();
        const lineIntercept = isHorizontal
          ? randomIntegerInclusive(-3, 3)
          : randomIntegerInclusive(-2, 2);

        const coordinates1 = [
          randomIntegerInclusive(-5, 5, { constraint: x => x !== lineIntercept }),
          randomIntegerInclusive(-4, 4, { constraint: x => x !== lineIntercept })
        ];
        const answerCoord = lineIntercept - coordinates1[isHorizontal ? 0 : 1] + lineIntercept;

        const answer = isHorizontal
          ? [answerCoord, coordinates1[1]]
          : [coordinates1[0], answerCoord];

        return { coordinates1, isHorizontal, lineIntercept, answer };
      },
      ({ answer }) => {
        return answer[1] <= 4 && answer[1] >= -4 && answer[0] <= 5 && answer[0] >= -5;
      }
    ),
  Component: ({
    question: { coordinates1, isHorizontal, lineIntercept, answer },
    translate,
    displayMode
  }) => {
    const start = new Point2d(coordinates1[0], coordinates1[1]);
    const end = new Point2d(answer[0], answer[1]);
    return (
      <QF48TranslateSvg
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointToReflectPointInLineL()
            : translate.instructions.drawPointToReflectPointInLineL()
        }
        start={[start]}
        end={[end]}
        gridChildren={
          <>
            <GridSvgChildren>
              <GridLine
                points={
                  isHorizontal
                    ? [
                        [lineIntercept, -4.5],
                        [lineIntercept, 4.5]
                      ]
                    : [
                        [-5.5, lineIntercept],
                        [5.5, lineIntercept]
                      ]
                }
                strokeWidth={displayMode === 'digital' ? 2 : 4}
                color={colors.red}
              />
              <GridText
                label={translate.letters.L()}
                coordinates={
                  isHorizontal
                    ? [lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept, 5]
                    : [6, lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept]
                }
              />
            </GridSvgChildren>
          </>
        }
        svg="dot"
        xMax={5}
        yMax={4}
        yMin={-4}
        xMin={-5}
        hideContinuationLines={false}
        hideAxis={false}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aFt',
  description: 'aFt',
  keywords: ['Coordinate', 'Reflection'],
  schema: z.object({
    coordinate1: z.number().int().min(-4).max(4).array().length(2),
    coordinate2: z.number().int().min(-4).max(4).array().length(2),
    coordinate3: z.number().int().min(-4).max(4).array().length(2),
    isYAxis: z.boolean()
  }),
  questionHeight: 750,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isYAxis = getRandomBoolean();
        const number1 = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
        const number2 = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
        const number3 = isYAxis
          ? number1 < 0
            ? randomIntegerInclusive(-4, -1, { constraint: x => x !== number1 })
            : randomIntegerInclusive(1, 4, { constraint: x => x !== number1 })
          : randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
        const number4 = !isYAxis
          ? number2 < 0
            ? randomIntegerInclusive(-4, -1, { constraint: x => x !== number2 })
            : randomIntegerInclusive(1, 4, { constraint: x => x !== number2 })
          : randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 && x !== number2 });

        const coordinate1 = [number1, number2];
        const coordinate2 = [number1, number4];
        const coordinate3 = [number3, number4];
        return { coordinate1, coordinate2, coordinate3, isYAxis };
      },
      val => {
        const point1 = val.coordinate1 as [number, number];
        const point2 = val.coordinate2 as [number, number];
        const point3 = val.coordinate3 as [number, number];

        return (
          isValidRightAngledTriangle(point1, point2, point3) &&
          Math.abs(val.coordinate1[0] - val.coordinate3[0]) >= 2 &&
          Math.abs(val.coordinate1[1] - val.coordinate3[1]) >= 2
        );
      }
    ),
  Component: ({
    question: { coordinate1, coordinate2, coordinate3, isYAxis },
    translate,
    theme,
    displayMode
  }) => {
    const answer = [coordinate1, coordinate2, coordinate3].map(val =>
      isYAxis ? [-val[0], val[1]] : [val[0], -val[1]]
    );

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragCirclesToReflectVerticesTriangleInAxis(
                isYAxis ? ALGEBRAIC_Y : ALGEBRAIC_X
              )
            : translate.instructions.drawPointsToReflectVerticesTriangleInAxis(
                isYAxis ? ALGEBRAIC_Y : ALGEBRAIC_X
              )
        }
        testCorrect={ans =>
          nestedArrayHasNoDuplicates(ans, true) &&
          ans.every(
            coord =>
              arraysHaveSameContents(coord, answer[0]) ||
              arraysHaveSameContents(coord, answer[1]) ||
              arraysHaveSameContents(coord, answer[2])
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: answer.map(val => val as [number, number])
        }}
        gridChildren={[
          <GridSvgChildren key={'polygon'}>
            <GridPolygon
              points={[
                coordinate1 as [number, number],
                coordinate2 as [number, number],
                coordinate3 as [number, number]
              ]}
              showBorder
            />
            <GridLine
              points={
                isYAxis
                  ? [
                      [0, 4.5],
                      [0, -4.5]
                    ]
                  : [
                      [-5.5, 0],
                      [5.5, 0]
                    ]
              }
              strokeWidth={displayMode === 'digital' ? 2 : 4}
              color={colors.red}
            />
          </GridSvgChildren>
        ]}
        gridProps={{
          xMax: 5,
          yMax: 4,
          yMin: -4,
          xMin: -5,
          squareGrid: true,
          height: displayMode === 'digital' ? 500 : 750
        }}
        snapToGrid
        items={countRange(3).map(() => ({
          // In PDF mode, we use a cross instead.
          component:
            displayMode === 'digital'
              ? 'Coordinates/CirclePointCustomizable'
              : 'Coordinates/CrossPointCustomizable',
          svgProps: { fill: theme.colors.tertiary }
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFu',
  description: 'aFu',
  keywords: ['Coordinate', 'Reflection'],
  schema: z
    .object({
      coordinates1: z.number().int().min(-10).max(10).array().length(2),
      isHorizontal: z.boolean(),
      lineIntercept: z.number().int().min(-9).max(9),
      answer: z.number().int().min(-10).max(10).array().length(2)
    })
    .refine(
      val =>
        val.answer[1] <= 8 && val.answer[1] >= -8 && val.answer[0] <= 10 && val.answer[0] >= -10,
      'answer is within the grid'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isHorizontal = getRandomBoolean();
        const lineIntercept = isHorizontal
          ? randomIntegerInclusive(-9, 9)
          : randomIntegerInclusive(-7, 7);

        const coordinates1 = [
          randomIntegerInclusive(-10, 10, {
            constraint: x => !isInRange(lineIntercept - 2, lineIntercept + 2)(x)
          }),
          randomIntegerInclusive(-8, 8, {
            constraint: y => !isInRange(lineIntercept - 2, lineIntercept + 2)(y)
          })
        ];
        const answerCoord = lineIntercept - coordinates1[isHorizontal ? 0 : 1] + lineIntercept;

        const answer = isHorizontal
          ? [answerCoord, coordinates1[1]]
          : [coordinates1[0], answerCoord];

        return { coordinates1, isHorizontal, lineIntercept, answer };
      },
      ({ answer }) => {
        return answer[1] <= 4 && answer[1] >= -4 && answer[0] <= 5 && answer[0] >= -5;
      }
    ),
  Component: props => {
    const {
      question: { coordinates1, isHorizontal, lineIntercept, answer },
      translate,
      displayMode
    } = props;

    const xLabels = countRange(20, -10).map(val =>
      isHorizontal && val === lineIntercept ? parseToSUB(lineIntercept.toLocaleString()) : ''
    );
    const yLabels = countRange(16, -8).map(val =>
      !isHorizontal && val === lineIntercept ? parseToSUB(lineIntercept.toLocaleString()) : ''
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thePointReflectedInLWhatAreCoordsOfReflectedPoint()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={10}
            yMax={8}
            xMin={-10}
            yMin={-8}
            xLabels={xLabels}
            yLabels={yLabels}
            squareGrid
          >
            <>
              <GridSvgChildren>
                <GridLine
                  points={
                    isHorizontal
                      ? [
                          [lineIntercept, -8.5],
                          [lineIntercept, 8.5]
                        ]
                      : [
                          [-10.5, lineIntercept],
                          [10.5, lineIntercept]
                        ]
                  }
                  strokeWidth={displayMode === 'digital' ? 2 : 4}
                  color={colors.red}
                />
                {/* L label */}
                <GridText
                  label={translate.letters.L()}
                  coordinates={
                    isHorizontal
                      ? [lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept, 9]
                      : [11, lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept]
                  }
                />
                {/* coord label */}
                <GridText
                  label={translate.answerSentences.xYCoordinate(coordinates1[0], coordinates1[1])}
                  coordinates={
                    // move the label depending on how close it is to the axes
                    coordinates1[1] > 0
                      ? [coordinates1[0], coordinates1[1] + 1]
                      : coordinates1[0] === 0 || coordinates1[0] === 1
                      ? [coordinates1[0] + 2, coordinates1[1]]
                      : coordinates1[0] === -1
                      ? [coordinates1[0] - 2, coordinates1[1]]
                      : [coordinates1[0], coordinates1[1] - 1.5]
                  }
                  fontSize={displayMode === 'digital' ? 21 : 32}
                />
              </GridSvgChildren>
              <GridImage
                mathCoord={coordinates1 as [number, number]}
                item={{
                  component: 'Coordinates/CirclePointCustomizable',
                  svgProps: { fill: colors.pacificBlue },
                  width: displayMode === 'digital' ? 25 : 50
                }}
              />
            </>
          </Grid>
        )}
        inputMaxCharacters={2}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentence={`(<ans/>, <ans/>)`}
        extraSymbol="minus"
        testCorrect={userAnswer =>
          userAnswer[0] === parseToSUB(answer[0].toString()) &&
          userAnswer[1] === parseToSUB(answer[1].toString())
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            parseToSUB(answer[0].toLocaleString()),
            parseToSUB(answer[1].toLocaleString())
          ]
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aFv',
  description: 'aFv',
  keywords: ['Coordinate', 'Axes'],
  schema: z.object({
    coordinates: z.number().int().min(-5).max(5).array().length(2),
    isX: z.boolean()
  }),
  simpleGenerator: () => {
    const isX = getRandomBoolean();

    const coordinates = [
      randomIntegerInclusive(-5, 5, { constraint: x => (!isX && x !== 0) || isX }),
      randomIntegerInclusive(-5, 5, { constraint: x => (isX && x !== 0) || !isX })
    ];

    return { isX, coordinates };
  },
  Component: ({ question: { isX, coordinates }, translate, displayMode }) => {
    const correctAnswer = isX
      ? [coordinates[0], -coordinates[1]]
      : [-coordinates[0], coordinates[1]];

    const minusYOffset = displayMode === 'digital' ? 14 : 0;
    const minusXOffset = displayMode === 'digital' ? 38 : 60;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.pointAIsReflextedInAxesToGiveBWhatAreCoordsOfB(
          isX ? ALGEBRAIC_X : ALGEBRAIC_Y
        )}
        sentence="( <ans/> , <ans/> )"
        testCorrect={correctAnswer.map(val => parseToSUB(val.toString()))}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        extraSymbol="minus"
        pdfDirection="column"
        questionHeight={1400}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidCoordinate() }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height * 1.05}
            xMin={-5}
            yMin={-5}
            xMax={5}
            yMax={5}
            squareGrid
          >
            {({ mathToSvgY }) => (
              <>
                <GridImage
                  mathCoord={coordinates as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue }
                  }}
                />
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(coordinates[1]) + (coordinates[1] < 0 ? minusYOffset : -32),
                    right: mathToSvgY(coordinates[0]) + (coordinates[0] < 0 ? minusXOffset : -10),
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.letters.A()}
                </Text>
              </>
            )}
          </Grid>
        )}
      />
    );
  },
  questionHeight: 1400
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Reflections',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
