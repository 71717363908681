import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomNumberPairs,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray
} from '../../../../utils/collections';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bbb',
  description: 'bbb',
  keywords: ['Number bonds', 'Add', 'Ten frame', 'Counters'],
  schema: z.object({
    colours: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2),
    isFlipped: z.array(z.boolean()).length(3),
    bonds: z
      .object({
        A: z.array(z.number().int().min(0).max(10)).length(2),
        B: z.array(z.number().int().min(0).max(10)).length(2),
        C: z.array(z.number().int().min(0).max(10)).length(2)
      })
      .refine(
        x => arrayHasNoDuplicates(Object.values(x), arraysHaveSameContentsUnordered),
        'number bond pairs have to be different'
      )
  }),
  simpleGenerator: () => {
    const colours = getRandomSubArrayFromArray(['red', 'yellow', 'blue', 'green'] as const, 2);
    const isFlipped = countRange(3).map(_ => getRandomBoolean());

    const keys = shuffle(['A', 'B', 'C'] as const);

    const pairs = rejectionSample(
      () => randomNumberPairs(10, 3),
      x => arrayHasNoDuplicates(x, arraysHaveSameContentsUnordered)
    );

    type BondsType = {
      [T in (typeof keys)[number]]: [number, number];
    };
    const bonds = Object.fromEntries(keys.map((key, i) => [key, pairs[i]])) as BondsType;

    return { colours, isFlipped, bonds };
  },
  Component: props => {
    const {
      question: { colours, isFlipped, bonds },
      translate
    } = props;

    const [counter1, counter2] = colours;
    const equation = (left: number, right: number, flipped: boolean) =>
      flipped
        ? `${right.toLocaleString()} ${ADD} ${left.toLocaleString()} = ${(10).toLocaleString()}`
        : `${left.toLocaleString()} ${ADD} ${right.toLocaleString()} = ${(10).toLocaleString()}`;

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragToMatchTheNumberBondsToTheTenFrames()}
        pdfTitle={translate.ks1PDFInstructions.matchTheTenFramesToTheNumberBonds()}
        items={Object.entries(bonds).map(([x, [num1, num2]], i) => ({
          value: x,
          component: <Text variant="WRN400">{equation(num1, num2, isFlipped[i])}</Text>
        }))}
        statements={(['A', 'B', 'C'] as const).map(x => ({
          lhsComponent: (
            <TenFrameLayout
              size="small"
              items={[...filledArray(counter1, bonds[x][0]), ...filledArray(counter2, bonds[x][1])]}
            />
          ),
          correctAnswer: x
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'bbc',
  description: 'bbc',
  keywords: ['Number bonds', 'Add', 'Bar model'],
  schema: z.object({
    flipBarModel: z.boolean(),
    number1: z.number().int().min(1).max(9),
    answerBox: z.enum(['10', 'number1', 'number2'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    const flipBarModel = getRandomBoolean();
    const answerBox = getRandomFromArray(['10', 'number1', 'number2'] as const);

    return {
      number1,
      flipBarModel,
      answerBox
    };
  },
  Component: ({ question, translate }) => {
    const { number1, flipBarModel, answerBox } = question;

    const [topRow, bottomRow] = flipBarModel
      ? [[number1, 10 - number1], [10]]
      : [[10], [number1, 10 - number1]];

    const answerIndices =
      answerBox === '10' ? [[0], []] : answerBox === 'number1' ? [[], [0]] : [[], [1]];

    return (
      <QF20CompleteTheBarModel
        title={translate.ks1Instructions.completeTheBarModel()}
        numbers={[topRow, bottomRow]}
        total={10}
        answerIndices={flipBarModel ? answerIndices.reverse() : answerIndices}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbd',
  description: 'bbd',
  keywords: ['Number bonds', 'Add'],
  schema: z
    .object({
      bonds: z
        .array(z.tuple([z.number().int().min(0).max(10), z.number().int().min(0).max(10)]))
        .length(9)
    })
    .refine(({ bonds }) => bonds.every(([num1, num2]) => num1 + num2 <= 10)),
  simpleGenerator: () => {
    const numCorrect = randomIntegerInclusive(3, 8);

    const correct1numbers = randomUniqueIntegersInclusive(0, 10, numCorrect);
    const correctBonds = correct1numbers.map(i => [10 - i, i] as [number, number]);

    const remainingNum1 = randomUniqueIntegersInclusive(0, 9, 9 - numCorrect);
    const remainingBonds = remainingNum1.map(num1 => {
      const num2 = randomIntegerInclusive(0, 9, { constraint: x => num1 + x < 10 });
      return [num1, num2] as [number, number];
    });

    return {
      bonds: shuffle([...correctBonds, ...remainingBonds])
    };
  },
  Component: ({ question, translate }) => {
    const { bonds } = question;

    const items = bonds.map(([num1, num2], i) => ({
      component: `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()}`,
      value: i,
      isCorrect: num1 + num2 === 10
    }));

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectTheNumberBondsToNum(10)}
        pdfTitle={translate.ks1PDFInstructions.tickTheNumberBondsToNum(10)}
        items={items}
        multiSelect
        testCorrect={items.filter(it => it.isCorrect).map(it => it.value)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumberBondsTo10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
