import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import {
  compareFractions,
  Fraction,
  fractionToDecimal,
  simplify
} from 'common/src/utils/fractions';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { FractionsWithArrowsWithState } from '../../../../components/question/representations/ValuesWithArrows/FractionsWithArrows';
import { isEqual } from '../../../../utils/matchers';
import { DIV, MULT } from '../../../../constants';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ao4',
  description: 'ao4',
  keywords: ['Equivalence', 'Equivalent fractions'],
  schema: z
    .object({
      answer1: z.number().int().min(2).max(10),
      denominatorA: z.number().int().min(2).max(6),
      answer2: z.number().int().min(2).max(10),
      denominatorB: numberEnum([3, 5, 7]),
      numeratorB: z.number().int().min(2).max(6)
    })
    .refine(
      val => val.numeratorB < val.denominatorB,
      'numeratorB must be less than denominatorB to prevent improper fraction'
    ),
  simpleGenerator: () => {
    const [answer1, answer2] = randomUniqueIntegersInclusive(2, 10, 2);

    const denominatorA = randomIntegerInclusive(2, 6);

    const denominatorB = getRandomFromArray([3, 5, 7] as const);
    const numeratorB = randomIntegerInclusive(2, denominatorB - 1);

    return { answer1, denominatorA, answer2, denominatorB, numeratorB };
  },
  Component: props => {
    const {
      question: { answer1, denominatorA, answer2, denominatorB, numeratorB },
      translate,
      displayMode
    } = props;

    const resultADenominator = denominatorA * answer1;
    const resultANumerator = answer1;

    const resultBDenominator = denominatorB * answer2;
    const resultBNumerator = numeratorB * answer2;

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <FractionsWithArrowsWithState
              id={'fracA'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={['1', resultANumerator.toString()]}
              denominators={[denominatorA.toString(), resultADenominator.toString()]}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbol={`${MULT}`}
              testCorrect={isEqual([answer1.toString(), answer1.toString()])}
              arrowDirection="right"
              defaultState={
                displayMode === 'markscheme'
                  ? [answer1.toLocaleString(), answer1.toLocaleString()]
                  : undefined
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
            />
            <FractionsWithArrowsWithState
              id={'fracB'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={[numeratorB.toString(), resultBNumerator.toString()]}
              denominators={[denominatorB.toString(), resultBDenominator.toString()]}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbol={`${MULT}`}
              testCorrect={isEqual([answer2.toString(), answer2.toString()])}
              arrowDirection="right"
              defaultState={
                displayMode === 'markscheme'
                  ? [answer2.toLocaleString(), answer2.toLocaleString()]
                  : undefined
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
            />
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ao5',
  description: 'ao5',
  keywords: ['Equivalence', 'Equivalent fractions'],
  schema: z
    .object({
      answer1: z.number().int().min(2).max(10),
      denominatorA: z.number().int().min(2).max(6),
      answer2: z.number().int().min(2).max(10),
      denominatorB: numberEnum([3, 5, 7]),
      numeratorB: z.number().int().min(2).max(6)
    })
    .refine(
      val => val.numeratorB < val.denominatorB,
      'numeratorB must be less than denominatorB to prevent improper fraction'
    ),
  simpleGenerator: () => {
    const [answer1, answer2] = randomUniqueIntegersInclusive(2, 10, 2);

    const denominatorA = randomIntegerInclusive(2, 6);

    const denominatorB = getRandomFromArray([3, 5, 7] as const);
    const numeratorB = randomIntegerInclusive(2, denominatorB - 1);

    return { answer1, denominatorA, answer2, denominatorB, numeratorB };
  },
  Component: props => {
    const {
      question: { answer1, denominatorA, answer2, denominatorB, numeratorB },
      translate,
      displayMode
    } = props;

    const resultADenominator = denominatorA * answer1;
    const resultANumerator = answer1;

    const resultBDenominator = denominatorB * answer2;
    const resultBNumerator = numeratorB * answer2;

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <FractionsWithArrowsWithState
              id={'fracA'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={['1', resultANumerator.toString()]}
              denominators={[denominatorA.toString(), resultADenominator.toString()]}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbol={`${DIV}`}
              testCorrect={isEqual([answer1.toString(), answer1.toString()])}
              arrowDirection="left"
              defaultState={
                displayMode === 'markscheme'
                  ? [answer1.toLocaleString(), answer1.toLocaleString()]
                  : undefined
              }
            />
            <FractionsWithArrowsWithState
              id={'fracB'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={[numeratorB.toString(), resultBNumerator.toString()]}
              denominators={[denominatorB.toString(), resultBDenominator.toString()]}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbol={`${DIV}`}
              testCorrect={isEqual([answer2.toString(), answer2.toString()])}
              arrowDirection="left"
              defaultState={
                displayMode === 'markscheme'
                  ? [answer2.toLocaleString(), answer2.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ao6',
  description: 'ao6',
  keywords: ['Equivalence', 'Equivalent fractions'],
  schema: z.object({
    answer1: z.number().int().min(2).max(10),
    numeratorB: z.number().int().min(3).max(10),
    answer2: z.number().int().min(1).max(11)
  }),
  simpleGenerator: () => {
    const answer1 = randomIntegerInclusive(2, 10);

    const numeratorB = randomIntegerInclusive(3, 10);

    const answer2 = getRandomFromArray([answer1, answer1 + 1, answer1 - 1] as const);

    return { answer1, numeratorB, answer2 };
  },
  Component: props => {
    const {
      question: { answer1, numeratorB, answer2 },
      translate
    } = props;

    const denominatorA = answer1;
    const denominatorB = numeratorB * answer2;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.areTheFractionsEquivalent()}
        pdfTitle={translate.instructions.areTheFractionsEquivalentCircleCorrectAnswer()}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        correctAnswer={answer1 === answer2}
        content={
          <View style={{ display: 'flex', flexDirection: 'row', columnGap: 96 }}>
            <TextStructure sentence={`<frac n='1' d='${denominatorA}'/>`} />
            <TextStructure sentence={`<frac n='${numeratorB}' d='${denominatorB}'/>`} />
          </View>
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ao7',
  description: 'ao7',
  keywords: ['Fractions', 'Equivalence', 'Match'],
  schema: z.object({
    number1: z.number().int().min(3).max(7),
    number2: z.number().int().min(1).max(6),
    number3: z.number().int().min(3).max(7),
    number4: z.number().int().min(2).max(6),
    number5: z.number().int().min(2).max(6),
    number6: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(3, 7);
        const number3 = randomIntegerInclusive(3, 7);

        const number2 = randomIntegerInclusive(1, number1 - 1);
        const number4 = randomIntegerInclusive(2, number3 - 1);

        const number5 = randomIntegerInclusive(2, 6);
        const number6 = randomIntegerInclusive(2, 6);

        return { number1, number2, number3, number4, number5, number6 };
      },
      val => {
        const fracA = [val.number2 * val.number5, val.number1 * val.number5];
        const fracB = [val.number2 * val.number5, (val.number1 + 1) * val.number5];
        const fracC = [val.number4 * val.number6, val.number3 * val.number6];

        return (
          // Ensure no equivalent fractions
          fractionToDecimal(fracA[0], fracA[1]) !== fractionToDecimal(fracB[0], fracB[1]) &&
          fractionToDecimal(fracA[0], fracA[1]) !== fractionToDecimal(fracC[0], fracC[1]) &&
          fractionToDecimal(fracB[0], fracB[1]) !== fractionToDecimal(fracC[0], fracC[1])
        );
      }
    ),
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate,
      displayMode
    } = props;

    const statementFracs: Fraction[] = [
      [number2, number1],
      [number2, number1 + 1],
      [number4, number3]
    ];

    const answerFracs: Fraction[] = [
      [number2 * number5, number1 * number5],
      [number2 * number5, (number1 + 1) * number5],
      [number4 * number6, number3 * number6]
    ];

    const shuffledAnswerFracs = shuffle(answerFracs, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMatchEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsMatchEquivalentFractions()}
        items={shuffledAnswerFracs.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0]}' d='${frac[1]}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: fractionToDecimal(...frac)
        }))}
        statements={statementFracs.map(frac => ({
          correctAnswer: fractionToDecimal(...frac),
          lhsComponent: <TextStructure sentence={`<frac n='${frac[0]}' d='${frac[1]}'/> =`} />
        }))}
        statementStyle={{ justifyContent: 'center' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ao8',
  description: 'ao8',
  keywords: ['Fraction', 'Equivalence'],
  schema: z.object({
    questionFraction: fractionSchema(),
    answerOptions: fractionSchema()
      .array()
      .length(6)
      .refine(
        val => arrayHasNoDuplicates(val),
        'answerOptions should not have any duplicate fractions'
      )
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(3, 7);
        const number2 = randomIntegerInclusive(1, number1 - 1);

        return { number1, number2 };
      },
      ({ number1, number2 }) => simplify(number2, number1)[1] >= 3
    );
    const [simplifiedNum2, simplifiedNum1] = simplify(number2, number1);

    const number3 = simplifiedNum1 * 2;
    const number4 = simplifiedNum2 * 2;

    const multiplierA = randomIntegerInclusive(3, 5);
    const number5 = simplifiedNum1 * multiplierA;
    const number6 = simplifiedNum2 * multiplierA;

    const multiplierB = randomIntegerInclusive(6, 10);
    const number7 = simplifiedNum1 * multiplierB;
    const number8 = simplifiedNum2 * multiplierB;

    const multiplierC = randomIntegerInclusive(1, 3);
    const number9 = simplifiedNum1 + multiplierC;
    const number10 = simplifiedNum2 + multiplierC;

    const number11 = simplifiedNum1 * 3;
    const number12 = simplifiedNum2 * 2;

    const multiplierD = randomIntegerInclusive(4, 10);
    const number13 = simplifiedNum1 * multiplierD;
    const number14 = simplifiedNum2 * (multiplierD - 1);

    const questionFraction: Fraction = [simplifiedNum2, simplifiedNum1];
    const answerOptions: Fraction[] = shuffle([
      [number4, number3],
      [number6, number5],
      [number8, number7],
      [number10, number9],
      [number12, number11],
      [number14, number13]
    ]);

    return { questionFraction, answerOptions };
  },
  Component: props => {
    const {
      question: { questionFraction, answerOptions },
      translate,
      displayMode
    } = props;

    return (
      <QF10SelectNumbers<Fraction>
        title={translate.instructions.selectFractionsEquivalentToX(
          `<frac n='${questionFraction[0]}' d='${questionFraction[1]}'/>`
        )}
        pdfTitle={translate.instructions.circleFractionsEquivalentToX(
          `<frac n='${questionFraction[0]}' d='${questionFraction[1]}'/>`
        )}
        testCorrect={answerOptions.filter(frac =>
          compareFractions(frac, [questionFraction[0], questionFraction[1]])
        )}
        items={answerOptions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0]}' d='${frac[1]}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: frac
        }))}
        multiSelect
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'ao9',
  description: 'ao9',
  keywords: ['Fraction', 'Equivalence'],
  schema: z.object({
    questionFraction: fractionSchema(),
    answerOptions: fractionSchema()
      .array()
      .length(6)
      .refine(
        val => arrayHasNoDuplicates(val),
        'answerOptions should not have any duplicate fractions'
      )
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 7);
    const number2 = randomIntegerInclusive(1, number1 - 1);
    const [simplifiedNum2, simplifiedNum1] = simplify(number2, number1);

    const multiplierA = randomIntegerInclusive(2, 3);
    const number3 = simplifiedNum1 * multiplierA;
    const number4 = simplifiedNum2 * multiplierA;

    const multiplierB = randomIntegerInclusive(4, 6);
    const number5 = simplifiedNum1 * multiplierB;
    const number6 = simplifiedNum2 * multiplierB;

    const multiplierC = randomIntegerInclusive(7, 10);
    const number7 = simplifiedNum1 * multiplierC;
    const number8 = simplifiedNum2 * multiplierC;

    const number9 = number5 - simplifiedNum1;
    const number10 = number6 + simplifiedNum2;

    const number11 = simplifiedNum1 * 3;
    const number12 = simplifiedNum2 * 2;

    const multiplierD = randomIntegerInclusive(2, 10, {
      constraint: x => x !== 3 // Prevent duplicates with number11 & number12
    });
    const number13 = simplifiedNum1 * multiplierD;
    const number14 = simplifiedNum2 * (multiplierD - 1);

    const questionFraction: Fraction = [number6, number5];
    const answerOptions: Fraction[] = shuffle([
      [number4, number3],
      [simplifiedNum2, simplifiedNum1],
      [number8, number7],
      [number10, number9],
      [number12, number11],
      [number14, number13]
    ]);

    return { questionFraction, answerOptions };
  },
  Component: props => {
    const {
      question: { questionFraction, answerOptions },
      translate,
      displayMode
    } = props;

    return (
      <QF10SelectNumbers<Fraction>
        title={translate.instructions.selectFractionsEquivalentToX(
          `<frac n='${questionFraction[0]}' d='${questionFraction[1]}'/>`
        )}
        pdfTitle={translate.instructions.circleFractionsEquivalentToX(
          `<frac n='${questionFraction[0]}' d='${questionFraction[1]}'/>`
        )}
        testCorrect={answerOptions.filter(frac =>
          compareFractions(frac, [questionFraction[0], questionFraction[1]])
        )}
        items={answerOptions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0]}' d='${frac[1]}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: frac
        }))}
        multiSelect
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseEquivalentFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
