import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { all, create, number } from 'mathjs';
import { LineGraphColors } from '../../../../theme/colors';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import { isEqualPerimeter, isRectangle } from '../../../../utils/shapes';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { compareFloats } from '../../../../utils/math';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRI',
  description: 'aRI',
  keywords: ['Length', 'Centimetres', 'cm', 'Millimetres', 'mm'],
  schema: z.object({
    lengthCm: z.number().int().min(0).max(4),
    lengthMm: z.number().int().min(1).max(9),
    vertical: z.boolean()
  }),
  simpleGenerator: () => ({
    lengthCm: randomIntegerInclusive(0, 4),
    lengthMm: randomIntegerInclusive(1, 9),
    vertical: getRandomBoolean()
  }),
  Component: props => {
    const {
      question: { lengthCm, lengthMm, vertical },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const length = number(math.evaluate(`(${lengthCm} + (${lengthMm})/10)`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        sentence={`${translate.answerSentences.ansCm()}`}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={3}
        testCorrect={userAnswer => compareFloats(userAnswer[0], length)}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            vertical={vertical}
            rulerKind="cm"
            rulerLength={lengthCm + 1}
            items={[
              {
                length,
                lineColor: color
              }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRJ',
  description: 'aRJ',
  keywords: ['Length', 'Centimetres', 'cm', 'Millilitres', 'mm'],
  schema: z.object({
    start: z.number().min(0.1).max(0.9),
    lineLengths: z.array(z.number().min(0.1).max(3.9)).length(4),
    vertical: z.boolean()
  }),
  simpleGenerator: () => {
    const start = randomIntegerInclusive(1, 9) / 10;

    const lineLengths = randomUniqueIntegersInclusive(1, 39, 4).map(mm => mm / 10);
    const vertical = getRandomBoolean();

    return { start, lineLengths, vertical };
  },
  Component: props => {
    const {
      translate,
      question: { start, lineLengths, vertical }
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const maxLength = Math.max(...lineLengths);

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheCorrectLengthOfTheLine()}
        pdfTitle={translate.instructions.circleTheCorrectLengthOfTheLine()}
        selectables={lineLengths.reduce(
          (acc, length) => ({
            ...acc,
            [length.toString()]: translate.units.numberOfCm(length)
          }),
          {}
        )}
        correctAnswer={[lineLengths[0].toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <ItemsAgainstRuler
                width={dimens.width}
                height={dimens.height}
                vertical={vertical}
                rulerKind="cm"
                rulerLength={maxLength + 1}
                items={[
                  {
                    start,
                    length: lineLengths[0],
                    lineColor: color
                  }
                ]}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aRK',
  description: 'aRK',
  keywords: [
    'Length',
    'Width',
    'Centimetres',
    'cm',
    'Millimetres',
    'mm',
    'Perimeter',
    'Addition',
    'Multiplication',
    'Double',
    'Rectangle'
  ],
  schema: z.object({
    length: z.number().int().min(2).max(100),
    width: z.number().int().min(1).max(99),
    unit: z.enum(['mm', 'cm'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(2, 100);
    const width = randomIntegerInclusive(1, length - 1);
    const unit = getRandomFromArray(['mm', 'cm'] as const);

    return { width, length, unit };
  },
  Component: props => {
    const {
      question: { width, length, unit },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfRectangle()}
        sentence={`<ans/> ${translate.units[unit]()}`}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width, height: dimens.height - 64 }}
            x={width}
            y={length}
            widthLabel={`${width.toLocaleString()} ${translate.units[unit]()}`}
            heightLabel={`${length.toLocaleString()} ${translate.units[unit]()}`}
          />
        )}
        testCorrect={[((width + length) * 2).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRL',
  description: 'aRL',
  keywords: [
    'Length',
    'Width',
    'Centimetres',
    'cm',
    'Millimetres',
    'mm',
    'Perimeter',
    'Repeated addition',
    'Multiplication',
    'Square'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(200),
    unit: z.enum(['mm', 'cm'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 200);
    const unit = getRandomFromArray(['mm', 'cm'] as const);

    return { length, unit };
  },
  Component: props => {
    const {
      question: { length, unit },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfSquare()}
        sentence={`<ans/> ${translate.units[unit]()}`}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width, height: dimens.height - 64 }}
            x={length}
            y={length}
            heightLabel={`${length.toLocaleString()} ${translate.units[unit]()}`}
          />
        )}
        testCorrect={[(length * 4).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRM',
  description: 'aRM',
  keywords: [
    'Length',
    'Width',
    'Centimetres',
    'cm',
    'Millimetres',
    'mm',
    'Perimeter',
    'Addition',
    'Repeated addition',
    'Multiplication',
    'Double',
    'Rectangle',
    'Square'
  ],
  schema: z.object({
    length1: z.number().int().min(2).max(200),
    width1: z.number().int().min(1).max(200),
    length2: z.number().int().min(2).max(200),
    width2: z.number().int().min(1).max(200),
    unit: z.enum(['mm', 'cm'])
  }),
  simpleGenerator: () => {
    const unit = getRandomFromArray(['mm', 'cm'] as const);

    const [length1, length2] = randomUniqueIntegersInclusive(2, 200, 2);
    const width1 = randomIntegerInclusive(1, length1);
    const width2 = randomIntegerInclusive(1, length2, {
      // Make sure the right and wrong perimeters are different
      constraint: x =>
        arrayHasNoDuplicates([
          2 * (length1 + width1),
          length1 + width1,
          2 * (length2 + x),
          length2 + x
        ])
    });

    return { length1, length2, width1, width2, unit };
  },
  Component: props => {
    const {
      question: { length1, length2, width1, width2, unit },
      translate
    } = props;

    const peri1 = `${((length1 + width1) * 2).toLocaleString()} ${translate.units[unit]()}`;
    const peri2 = `${((length2 + width2) * 2).toLocaleString()} ${translate.units[unit]()}`;

    const wrongPeris = [
      `${(length1 + width1).toLocaleString()} ${translate.units[unit]()}`,
      `${(length2 + width2).toLocaleString()} ${translate.units[unit]()}`
    ];

    const items = shuffle([peri1, peri2, ...wrongPeris], { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchPerimetersToTheShapes()}
        pdfTitle={translate.instructions.useTheCardsToMatchPerimetersToTheShapes()}
        itemVariant={'shortRectangle'}
        items={items}
        statements={[
          {
            correctAnswer: peri1,
            lhsComponent: (
              <QuadrilateralWithDimens
                containerStyle={{ marginTop: 0 }}
                dimens={{ width: 300, height: 145 }}
                x={length1}
                y={width1}
                widthLabel={`${length1.toLocaleString()} ${translate.units[unit]()}`}
                heightLabel={`${width1.toLocaleString()} ${translate.units[unit]()}`}
              />
            )
          },
          {
            correctAnswer: peri2,
            lhsComponent: (
              <QuadrilateralWithDimens
                containerStyle={{ marginTop: 0 }}
                dimens={{ width: 300, height: 145 }}
                x={length1}
                y={width2}
                widthLabel={`${length2.toLocaleString()} ${translate.units[unit]()}`}
                heightLabel={`${width2.toLocaleString()} ${translate.units[unit]()}`}
              />
            )
          }
        ]}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
        useArrows={false}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aRN',
  description: 'aRN',
  keywords: [
    'Length',
    'Width',
    'Centimetres',
    'cm',
    'Millimetres',
    'mm',
    'Perimeter',
    'Addition',
    'Repeated addition',
    'Multiplication',
    'Double',
    'Rectangle',
    'Square'
  ],
  schema: z.object({
    height: z.number().int().min(2).max(14),
    width: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(2, 14);
    const width = randomIntegerInclusive(1, Math.min(height - 1, 7));
    return { height, width };
  },
  Component: ({ question: { height, width }, translate }) => {
    const perimeter = 2 * (height + width);

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.selectSquaresToCreateRectangleWithPerimX(
          translate.units.numberOfCm(perimeter)
        )}
        numberOfRows={7}
        numberOfCols={14}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfRectangles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
