// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import KilogramsAndKilometres from './1KilogramsAndKilometres';
import MillimetresAndMillilitres from './2MillimetresAndMillilitres';
import ConvertUnitsOfLength from './3ConvertUnitsOfLength';
import ConvertBetweenMetricAndImperialUnits from './4ConvertBetweenMetricAndImperialUnits';
import ConvertUnitsOfTime from './5ConvertUnitsOfTime';
import CalculateWithTimetables from './6CalculateWithTimetables';

const Block = newBlockContent({
  block: 'ConvertingUnits',
  field: 'Measurement',
  weeks: [10, 11],
  smallSteps: [
    KilogramsAndKilometres, // Step 1
    MillimetresAndMillilitres, // Step 2
    ConvertUnitsOfLength, // Step 3
    ConvertBetweenMetricAndImperialUnits, // Step 4
    ConvertUnitsOfTime, // Step 5
    CalculateWithTimetables // Step 6
  ]
});
export default Block;
