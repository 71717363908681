import { useCallback } from 'react';
import debounce from '../utils/debounce';

/**
 * Like {@link useCallback}, but additionally applies debouncing to that callback, at the expense of losing the return
 * value of the callback. See also {@link debounce}.
 */
export default function useDebouncedCallback<F extends (...args: unknown[]) => unknown>(
  callback: F,
  dependencies: readonly unknown[],
  wait: number = 300
): (...args: Parameters<F>) => void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(callback, wait), [wait, ...dependencies]);
}
