import { newBlockContent } from '../../../Block';

import BondsTo10 from './1BondsTo10';
import FactFamiliesAdditionSubtractionBondsWithin20 from './2FactFamiliesAdditionSubtractionBondsWithin20';
import RelatedFacts from './3RelatedFacts';
import BondsTo100Tens from './4BondsTo100Tens';
import AddAndSubtract1s from './5AddAndSubtract1s';
import AddByMaking10 from './6AddByMaking10';
import AddThree1DigitNumbers from './7AddThree1DigitNumbers';
import AddToTheNext10 from './8AddToTheNext10';
import AddAcross10 from './9AddAcross10';
import SubtractAcross10 from './10SubtractAcross10';
import SubtractFromA10 from './11SubtractFromA10';
import SubtractA1DigitNumberFromA2DigitNumberAcrossA10 from './12SubtractA1DigitNumberFromA2DigitNumberAcrossA10';
import TenMoreTenLess from './13TenMoreTenLess';
import AddAndSubtract10s from './14AddAndSubtract10s';
import AddTwo2DigitNumbersNotAcrossA10 from './15AddTwo2DigitNumbersNotAcrossA10';
import AddTwo2DigitNumbersAcrossA10 from './16AddTwo2DigitNumbersAcrossA10';
import SubtractTwo2DigitNumbersNotAcrossA10 from './17SubtractTwo2DigitNumbersNotAcrossA10';
import SubtractTwo2DigitNumbersAcrossA10 from './18SubtractTwo2DigitNumbersAcrossA10';
import MixedAdditionAndSubtraction from './19MixedAdditionAndSubtraction';
import CompareNumberSentences from './20CompareNumberSentences';
import MissingNumberProblems from './21MissingNumberProblems';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [5, 9],
  smallSteps: [
    BondsTo10, // Step 1
    FactFamiliesAdditionSubtractionBondsWithin20, // Step 2
    RelatedFacts, // Step 3
    BondsTo100Tens, // Step 4
    AddAndSubtract1s, // Step 5
    AddByMaking10, // Step 6
    AddThree1DigitNumbers, // Step 7
    AddToTheNext10, // Step 8
    AddAcross10, // Step 9
    SubtractAcross10, // Step 10
    SubtractFromA10, // Step 11
    SubtractA1DigitNumberFromA2DigitNumberAcrossA10, // Step 12
    TenMoreTenLess, // Step 13
    AddAndSubtract10s, // Step 14
    AddTwo2DigitNumbersNotAcrossA10, // Step 15
    AddTwo2DigitNumbersAcrossA10, // Step 16
    SubtractTwo2DigitNumbersNotAcrossA10, // Step 17
    SubtractTwo2DigitNumbersAcrossA10, // Step 18
    MixedAdditionAndSubtraction, // Step 19
    CompareNumberSentences, // Step 20
    MissingNumberProblems // Ste 21
  ]
});
export default Block;
