import { z } from 'zod';

////
// Utility functions
////

/**
 * Converts a whole number between 1 and 3999 to a string of Roman numerals.
 * (1 is the smallest number in Roman numerals, and 3999 is the largest since the largest numeral is M=1000 and at
 * most three of the same numeral are allowed consecutively.)
 *
 * Throws error if the input was not a whole number between 1 and 3999.
 */
export function numberToRomanNumerals(input: number): string {
  if (input < 1 || input > 3999 || !Number.isInteger(input)) {
    throw new Error('Number is not a whole number between 1 and 3999');
  }

  // Array containing ones, tens, hundreds, thousands
  const inputDigitsReversed = Array.from(input.toString())
    .map(it => parseInt(it))
    .reverse();
  return inputDigitsReversed
    .map((digit, pow) => digitsToNumeralsTable[pow][digit])
    .reverse()
    .join('');
}

/**
 * Converts a string of Roman numerals to a whole number between 1 and 3999.
 *
 * Returns null if the input was not valid Roman numerals.
 *
 * We define valid numerals to be the unique way of expressing the number by replacing each digit in decimal notation
 * with its corresponding set of numerals, as given in the table below.
 */
export function romanNumeralsToNumber(input: string): number | null {
  if (input.length === 0) {
    return null;
  }

  let answer = 0;
  for (let pow = 0; pow <= 3; pow++) {
    if (input.length === 0) {
      break;
    }

    // Try to extract the 10^pow digit
    const numerals = digitsToNumeralsTable[pow];

    // Look for each set of numerals in order, in order of string length descending (except '')
    const numeralsLongestFirst = numerals
      .filter(it => it !== '')
      .sort((a, b) => a.length - b.length)
      .reverse();
    const matchingNumeral = numeralsLongestFirst.find(it => input.includes(it));

    if (matchingNumeral !== undefined) {
      // We should have just matched to the end of the string. Check by splitting.
      const splitInput = input.split(matchingNumeral);
      // Should give us two strings, where the last one is empty.
      if (splitInput.length !== 2 || splitInput[1].length !== 0) {
        // Invalid!
        return null;
      }
      input = splitInput[0];

      answer += numerals.indexOf(matchingNumeral) * Math.pow(10, pow);
    }
  }

  // If we have anything left of the input, the input was invalid.
  if (input.length > 0) {
    return null;
  }

  return answer;
}

/**
 * Table that acts as a map from power-of-ten and digit to roman numeral.
 * Standard Roman Numerals only use subtractive notation for 4, 9, 40, 90, 400, 900, so we don't need to worry about
 * things like IC=99, as this is non-standard.
 *
 * Note that the IIII=4, while fashionable, is also seen as non-standard, and therefore not present in this table.
 *
 * This is a 2d array, where the outer array tracks the place value of that digit as a power of ten (ones, tens,
 * hundreds thousands), and the inner arrays give the numerals for that digit (where the digit is the index).
 */
const digitsToNumeralsTable: string[][] = [
  ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'],
  ['', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'],
  ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'],
  ['', 'M', 'MM', 'MMM']
];

/** Single numeral values. */
const numeralToNumberTableConst = {
  I: 1,
  V: 5,
  X: 10,
  L: 50,
  C: 100,
  D: 500,
  M: 1000
} as const;

////
// Types and schemas
////

export type RomanNumeral = keyof typeof numeralToNumberTableConst;
export const RomanNumeralArray = Object.keys(numeralToNumberTableConst) as RomanNumeral[];
export const isRomanNumeral = (x: string): x is RomanNumeral =>
  RomanNumeralArray.some(it => it === x);
export const RomanNumeralSchema = z
  .string()
  .length(1)
  .refine(x => isRomanNumeral(x), 'Must be I, V, X, L, C, D or M');

export const RomanNumeralsSchema = z
  .string()
  .refine(x => romanNumeralsToNumber(x) !== null, 'Must be valid Roman numerals');

export type RomanNumeralValue = (typeof numeralToNumberTableConst)[RomanNumeral];
export const RomanNumeralValueArray = Object.values(
  numeralToNumberTableConst
) as RomanNumeralValue[];
export const isRomanNumeralValue = (x: number): x is RomanNumeralValue =>
  RomanNumeralValueArray.some(it => it === x);
export const RomanNumeralValueSchema = z
  .number()
  .int()
  .min(1)
  .max(1000)
  .refine(x => isRomanNumeralValue(x), 'Must be 1, 5, 10, 50, 100, 500 or 1000');
