import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { InternalNodeContext, projectSetState, SetState, StateTree } from './helpers';

/**
 * Wrap a question with this in order to manage its state using {@link StateTree}. This is essentailly a fancy context
 * provider, which passes the entire question's state, and also the overall question's correctness, up via callbacks,
 * to be managed by a Quiz.
 */
export function StateTreeRoot({
  children,
  state,
  setState,
  onCorrectChanged,
  onCompleteChanged
}: {
  children: ReactNode;
  state: StateTree;
  setState: SetState<StateTree>;
  onCorrectChanged?: (correct: boolean) => void;
  onCompleteChanged?: (complete: boolean) => void;
}) {
  // State for managing childNodes correct status
  const [childCorrectNodes, setChildCorrectNodes] = useState<Record<string, boolean>>({});

  // Work out whether this node is correct.
  const correct =
    // We have some correctness info
    Object.values(childCorrectNodes).length > 0 &&
    // All direct child nodes with state have reported their correctness
    Object.keys(state).every(id => id in childCorrectNodes) &&
    // All direct child nodes are correct
    Object.values(childCorrectNodes).every(correct => correct);

  // If this node's correctness has changed, inform the callback.
  const previousCorrect = useRef<boolean | null>(null);
  useEffect(() => {
    if (correct !== previousCorrect.current) {
      previousCorrect.current = correct;
      onCorrectChanged && onCorrectChanged(correct);
    }
  }, [correct, onCorrectChanged]);

  // State for managing childNodes complete status
  const [childCompleteNodes, setChildCompleteNodes] = useState<Record<string, boolean>>({});

  // Work out whether this node has been complete.
  const complete =
    // We have some completeness info
    Object.values(childCompleteNodes).length > 0 &&
    // All direct child nodes with state have reported their completeness
    Object.keys(state).every(id => id in childCompleteNodes) &&
    // All direct child nodes are complete
    Object.values(childCompleteNodes).every(complete => complete);

  // If this node's completeness has changed, inform the callback.
  const previousComplete = useRef<boolean | null>(null);
  useEffect(() => {
    if (complete !== previousComplete.current) {
      previousComplete.current = complete;
      onCompleteChanged && onCompleteChanged(complete);
    }
  }, [complete, onCompleteChanged]);

  // Callbacks for children to update their state
  const getChildState = useCallback((childId: string) => state[childId], [state]);
  const reportChildState = useCallback(
    (childId: string) => projectSetState(setState, childId),
    [setState]
  );
  const reportChildCorrect = useCallback(
    (childId: string) => projectSetState(setChildCorrectNodes, childId),
    [setChildCorrectNodes]
  );
  const reportChildComplete = useCallback(
    (childId: string) => projectSetState(setChildCompleteNodes, childId),
    [setChildCompleteNodes]
  );

  return (
    <InternalNodeContext.Provider
      value={{
        state,
        setState: () => {
          throw new Error('Tried to set value on root node - does nothing');
        },
        getChildState,
        reportChildState,
        reportChildCorrect,
        reportChildComplete: reportChildComplete
      }}
    >
      {children}
    </InternalNodeContext.Provider>
  );
}
