import { useContext } from 'react';
import { View } from 'react-native';
import { MeasureView } from '../../atoms/MeasureView';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import Grid, { type GridProps } from '../representations/Coordinates/Grid';
import LineGraph, { LineGraphWithState } from '../representations/Coordinates/LineGraph';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../constants';
import TableWithLeftHeaders from '../representations/TableWithLeftHeaders';
import { format } from 'mathjs';
import { countRange } from '../../../utils/collections';
import { colors } from '../../../theme/colors';
import GridImage from '../representations/Coordinates/GridImage';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle: string;

  correctAnswer: number[];

  /** Default: all points at gridProps.xAxis or, as a fallback, gridProps.yMin. */
  defaultState?: number[];

  /** Any additional props for the grid. */
  gridProps: GridProps;

  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';

  /** (PDF-only) how high is the overall question. Must agree with question type definition. */
  questionHeight?: number;

  /**
   * What to show in the PDF answer scheme. Default: 'plotAllPoints'.
   * - plotAllPoints: we're asking them to plot all the points, so show them a blank graph in the PDF question.
   * - fixIncorrectPoints: we're asking them to fix some incorrect points, so show them an incorrect graph in the
   *   PDF question.
   */
  pdfVariant?: 'plotAllPoints' | 'fixIncorrectPoints';
};

/**
 * Draw a line graph from a table of data.
 */
export default function QF59DrawLineGraph({
  title,
  pdfTitle,
  correctAnswer,
  gridProps,
  defaultState: defaultStateProp,
  snapToNearest,
  questionHeight,
  pdfVariant = 'plotAllPoints'
}: Props) {
  const defaultState =
    defaultStateProp ?? correctAnswer.map(() => gridProps.xAxis ?? gridProps.yMin ?? 0);

  const displayMode = useContext(DisplayMode);

  // TODO: this is currently copy-pasted from Grid. May be safer to obtain this from a common location, rather than
  // calculating again.
  const xLabels =
    gridProps.xLabels ??
    countRange(gridProps.xMax - (gridProps.xMin ?? 0) + 1)
      .map(i => (gridProps.xMin ?? 0) + i * (gridProps.xStepSize ?? 1))
      .map(x =>
        gridProps.xDecimalPlaces !== undefined
          ? format(x, { notation: 'fixed', precision: gridProps.xDecimalPlaces })
          : format(x, { precision: 14 })
      );

  const table = (
    <TableWithLeftHeaders
      headers={[gridProps.xAxisLabel ?? ALGEBRAIC_X, gridProps.yAxisLabel ?? ALGEBRAIC_Y]}
      items={[
        correctAnswer.map((_, i) => xLabels[i]),
        correctAnswer.map(it => it.toLocaleString())
      ]}
      style={{ width: '80%', alignSelf: 'center', marginBottom: 20 }}
      headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
      headerTextStyle={displayMode !== 'digital' && { color: 'black' }}
      textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
    />
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const gridContents = (() => {
      switch (pdfVariant) {
        case 'plotAllPoints': {
          // In this mode, we are asking them to plot all the points. So show nothing in the PDF case,
          // and the correct answer in the markscheme case.
          switch (displayMode) {
            case 'pdf':
              return null;
            case 'markscheme':
              return <LineGraph points={correctAnswer} color={colors.burntSienna} />;
          }
        }
        case 'fixIncorrectPoints': {
          // In this mode, we are asking them to fix some incorrect points. So show a graph in the PDF
          // case, and add some circles and crosses in the markscheme case.
          switch (displayMode) {
            case 'pdf':
              return <LineGraph points={defaultState} />;
            case 'markscheme':
              return (
                <>
                  <LineGraph points={defaultState} />
                  {correctAnswer.map((correctY, i) => {
                    if (defaultState[i] !== correctY) {
                      return (
                        <>
                          <CircledPoint
                            key={`incorrect${i}`}
                            x={(gridProps.xMin ?? 0) + i * (gridProps.xStepSize ?? 1)}
                            y={defaultState[i]}
                          />
                          <NewPoint
                            key={`correct${i}`}
                            x={(gridProps.xMin ?? 0) + i * (gridProps.xStepSize ?? 1)}
                            y={correctY}
                          />
                        </>
                      );
                    }
                  })}
                </>
              );
          }
        }
      }
    })();

    return (
      <BaseLayoutPDF
        title={pdfTitle}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            {table}
            <MeasureView style={{ marginTop: 20 }}>
              {({ width, height }) => (
                <Grid sizingMethod="dimens" width={width * 0.6} height={height} {...gridProps}>
                  {gridContents}
                </Grid>
              )}
            </MeasureView>
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <>
          {table}
          <MeasureView>
            {({ width, height }) => (
              <Grid sizingMethod="dimens" width={width} height={height} {...gridProps}>
                <LineGraphWithState
                  id="linegraph"
                  snapToNearest={snapToNearest}
                  defaultState={defaultState}
                  testCorrect={isEqual(correctAnswer)}
                />
              </Grid>
            )}
          </MeasureView>
        </>
      }
    />
  );
}

function CircledPoint({ x, y }: { x: number; y: number }) {
  return (
    <GridImage
      mathCoord={[x, y]}
      item={{
        component: (
          <View
            style={{
              width: 50,
              height: 50,
              borderRadius: 999,
              borderWidth: 5,
              borderColor: colors.burntSienna
            }}
          />
        ),
        width: 50,
        height: 50
      }}
    />
  );
}

function NewPoint({ x, y }: { x: number; y: number }) {
  return (
    <GridImage
      mathCoord={[x, y]}
      item={{
        component: 'Coordinates/CrossPointCustomizable',
        svgProps: { fill: colors.burntSienna }
      }}
    />
  );
}
