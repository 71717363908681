// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UseScales from './1UseScales';
import MeasureMassInGrams from './2MeasureMassInGrams';
import MeasureMassInKilogramsAndGrams from './3MeasureMassInKilogramsAndGrams';
import EquivalentMassesKilogramsAndGrams from './4EquivalentMassesKilogramsAndGrams';
import CompareMass from './5CompareMass';
import AddAndSubtractMass from './6AddAndSubtractMass';
import MeasureCapacityAndVolumeInMillilitres from './7MeasureCapacityAndVolumeInMillilitres';
import MeasureCapacityAndVolumeInLitresAndMillilitres from './8MeasureCapacityAndVolumeInLitresAndMillilitres';
import EquivalentCapacitiesAndVolumesLitresAndMillilitres from './9EquivalentCapacitiesAndVolumesLitresAndMillilitres';
import CompareCapacityAndVolume from './10CompareCapacityAndVolume';
import AddAndSubtractCapacityAndVolume from './11AddAndSubtractCapacityAndVolume';

const Block = newBlockContent({
  block: 'MassAndCapacity',
  field: 'Measurement',
  weeks: [10, 12],
  smallSteps: [
    UseScales, // Step 1
    MeasureMassInGrams, // Step 2
    MeasureMassInKilogramsAndGrams, // Step 3
    EquivalentMassesKilogramsAndGrams, // Step 4
    CompareMass, // Step 5
    AddAndSubtractMass, // Step 6
    MeasureCapacityAndVolumeInMillilitres, // Step 7
    MeasureCapacityAndVolumeInLitresAndMillilitres, // Step 8
    EquivalentCapacitiesAndVolumesLitresAndMillilitres, // Step 9
    CompareCapacityAndVolume, // Step 10
    AddAndSubtractCapacityAndVolume // Step 11
  ]
});
export default Block;
