import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  MultiLinkedShapeNames,
  filterMultilinkShapes,
  multilinkShapes,
  MultilinkShapesSchema,
  scaleMultilinkShapes
} from '../../../../utils/multiLinkCubesImages';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import {
  getRandomBoolean,
  getRandomFromArray,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  Shapes3DIsoSchema,
  filterShapes3DIso,
  shapes3DIso,
  shapes3DIsoNames
} from '../../../../utils/shapes3DIso';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDA',
  description: 'aDA',
  keywords: ['Volume', '3-D shape', 'Cubes', 'Cubic', 'Centimetres'],
  schema: z.object({
    cubeShape: MultilinkShapesSchema
  }),
  simpleGenerator: () => {
    const flatRegularShapes = filterMultilinkShapes({
      hasDepth: false,
      isIrregular: false,
      numberOfCubes: [2, 10]
    });

    const cubeShape = getRandomFromArray(flatRegularShapes) as MultiLinkedShapeNames;
    return { cubeShape };
  },
  Component: props => {
    const {
      question: { cubeShape },
      translate
    } = props;

    const { svg, numberOfCubes } = multilinkShapes[cubeShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheVolumeOf3DShape()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCubes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width * 0.75} height={dimens.height * 0.75} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDB',
  description: 'aDB',
  keywords: ['Volume', '3-D shape', 'Cubes', 'Cubic', 'Centimetres'],
  schema: z.object({
    cubeShape: MultilinkShapesSchema
  }),
  simpleGenerator: () => {
    const flatIrregularShapes = filterMultilinkShapes({ hasDepth: false, isIrregular: true });

    const cubeShape = getRandomFromArray(flatIrregularShapes) as MultiLinkedShapeNames;
    return { cubeShape };
  },
  Component: props => {
    const {
      question: { cubeShape },
      translate
    } = props;

    const { svg, numberOfCubes } = multilinkShapes[cubeShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheVolumeOf3DShape()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCubes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width * 0.75} height={dimens.height * 0.75} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aDC',
  description: 'aDC',
  keywords: ['Non-unit fraction', 'Equal parts', 'Numerator', 'Denominator'],
  schema: z
    .object({
      shapeA: MultilinkShapesSchema,
      shapeB: MultilinkShapesSchema
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB),
  simpleGenerator: () => {
    const cubes = getRandomFromArray([4, 6, 8, 10]);
    const shapesForA = filterMultilinkShapes({ hasDepth: false, numberOfCubes: [cubes, cubes] });
    const rangeForB: [number, number] = getRandomBoolean() ? [cubes + 1, 12] : [3, cubes - 1];
    const shapesForB = filterMultilinkShapes({ hasDepth: false, numberOfCubes: rangeForB });

    const shapeA = getRandomFromArray(shapesForA) as MultiLinkedShapeNames;
    const shapeB = getRandomFromArray(shapesForB) as MultiLinkedShapeNames;
    return { shapeA, shapeB };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB } = question;

    const { svg: svgA, numberOfCubes: numberOfCubesA } = multilinkShapes[shapeA];
    const { svg: svgB, numberOfCubes: numberOfCubesB } = multilinkShapes[shapeB];

    const scales = scaleMultilinkShapes([shapeA, shapeB]);

    const options = shuffle(
      [
        {
          value: numberOfCubesA,
          svgName: svgA,
          scale: scales[0]
        },
        {
          value: numberOfCubesB,
          svgName: svgB,
          scale: scales[1]
        }
      ],
      {
        random: seededRandom(question)
      }
    );
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectThe3DShapeThatHasXCubes(
          numberOfCubesA.toLocaleString()
        )}
        pdfTitle={translate.instructions.circleThe3DShapeThatHasXCubes(
          numberOfCubesA.toLocaleString()
        )}
        numItems={2}
        renderItems={({ dimens }) =>
          options.map(({ value, svgName, scale }) => ({
            component: (
              <AssetSvg
                name={svgName}
                width={Math.min(dimens.width, dimens.height) * 0.8 * scale}
                height={Math.min(dimens.width, dimens.height) * 0.8 * scale}
              />
            ),
            value
          }))
        }
        testCorrect={[numberOfCubesA]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aDD',
  description: 'aDD',
  keywords: ['Volume', '3-D shape', 'Cubes', 'Cubic', 'Centimetres'],
  schema: z.object({
    cubeShape: MultilinkShapesSchema
  }),
  simpleGenerator: () => {
    const flatShapes = filterMultilinkShapes({ hasDepth: false });

    const cubeShape = getRandomFromArray(flatShapes) as MultiLinkedShapeNames;
    return { cubeShape };
  },
  Component: props => {
    const {
      question: { cubeShape },
      translate
    } = props;

    const { svg, numberOfCubes } = multilinkShapes[cubeShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.eachCubeHasVolume1cm3WhatIsTheVolumeOfTheShape()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width * 0.8} height={dimens.height} />
        )}
      />
    );
  }
});

// Question6 exported to Q aW4
const Question5 = newQuestionContent({
  uid: 'aDE',
  description: 'aDE',
  keywords: ['Volume', '3-D shape', 'Cubes', 'Cubic', 'Centimetres'],
  schema: z.object({
    shape: Shapes3DIsoSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shape = getRandomFromArray(shapes3DIsoNames)!;
    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const { svg, numberOfCubes } = shapes3DIso[shape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.cuboidIsDrawnOnIsometricPaperHowManyCubesAreNeededToMakeTheCuboid()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCubes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width} height={dimens.height} />
        )}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});
export const aDE = Question5;

const Question6 = newQuestionContent({
  uid: 'aDF',
  description: 'aDF',
  keywords: ['Volume', '3-D shape', 'Cubes', 'Cubic', 'Centimetres'],
  schema: z.object({
    shape: Shapes3DIsoSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shapesWithDepth = filterShapes3DIso({ hasDepth: true })!;
    const shape = getRandomFromArray(shapesWithDepth)!;
    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const { svg, numberOfCubes } = shapes3DIso[shape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.cuboidIsDrawnOnIsometricPaperHowManyCubesAreNeededToMakeTheCuboid()}
        testCorrect={[numberOfCubes.toString()]}
        sentence={translate.answerSentences.ansCubes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svg} width={dimens.width} height={dimens.height} />
        )}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CubicCentimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
