// Inherit props from BarModelWithState and add new ones

import { useContext, useMemo } from 'react';
import { BarModel } from './BarModel';
import { StyleSheet, View } from 'react-native';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import { uniqueBarModelColors, uniqueBarModelColorsArray } from '../../../theme/colors';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { getRandomFromArray, getRandomSubArrayFromArray } from '../../../utils/random';
import { Dimens } from '../../../theme/scaling';
import { countRange, filledArray } from '../../../utils/collections';
import TextStructure from '../../molecules/TextStructure';
import { projectSetState, SetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import { parseMarkup } from '../../../markup';
import Brace from '../../molecules/Brace';

type Props = {
  /** The numbers to represent each bar model. Represented as a 2D array where each inner array is a singular bar. */
  numbers: number[][];
  /** Optional display strings rather than the numbers in the cell of each bar model. */
  strings?: string[][];
  /** Indices for each bar model that we wish to have an arrow in place of a cell. */
  arrowIndices?: number[][];
  /**
   * Total amount that the bar model is working with.
   * Used to determine the width proportions of each cell.
   */
  total: number;
  /** Usable dimens */
  dimens: Dimens;
  /** Text you wish you place before each bar model, each index of this will match against its respective bar model. */
  preBarText?: string[];
  /** Text to put on the vertical brace, uses our standard markdown parsing. */
  braceText: string;
  userAnswer?: string[];
  setUserAnswer?: SetState<string[]>;
  inputMaxCharacters?: number;
};

type BarProps = {
  numbers: number[];
  strings?: string[];
  arrowIndices?: number[];
  total: number;
  dimens: Dimens;
  cellColors: string[];
  preBarText?: string;
};

/**
 * Simple bar model with vertical brace encompassing the total height of all bar models plus gap between them.
 */
export const BarModelVerticalBrace = ({
  total,
  strings,
  numbers,
  arrowIndices,
  preBarText,
  braceText,
  dimens,
  inputMaxCharacters,
  userAnswer = [],
  setUserAnswer = () => {
    /* Do nothing */
  }
}: Props) => {
  const styles = useStyles();
  const numberOfBars = numbers.length;

  if (numberOfBars > 3) {
    throw new Error('This representation does not support more than 3 bar models.');
  }

  // Generate cell colors, ensuring that we choose `numberOfBars` unique colors
  const uniqueHexCodes = useMemo(() => {
    return getRandomSubArrayFromArray(uniqueBarModelColorsArray, numberOfBars).map(color =>
      getRandomFromArray(uniqueBarModelColors[color])
    );
  }, [numberOfBars]);

  // Calculate the height of the vertical brace.
  const barHeight = 100;
  const braceHeight = barHeight * numberOfBars + 32 * (numberOfBars - 1);

  // Narrow the available space
  const usableDimens = {
    height: dimens.height,
    width: dimens.width * 0.7
  };

  return (
    <View style={styles.container}>
      <View style={styles.barContainer}>
        {countRange(numberOfBars).map(i => (
          <Bar
            key={i}
            numbers={numbers[i]}
            strings={strings?.[i]}
            arrowIndices={arrowIndices?.[i]}
            total={total}
            dimens={usableDimens}
            cellColors={[uniqueHexCodes[i]]}
            preBarText={preBarText?.[i]}
          />
        ))}
      </View>

      <Brace height={braceHeight} width={50} />

      <View style={styles.answerContainer}>
        {braceText && (
          <TextStructure
            textVariant="WRN400"
            sentence={braceText}
            inputBox={({ index }: { index: number }) => (
              <NoKeyboardTextInput
                key={index}
                value={userAnswer[index]}
                onChangeText={projectSetState(setUserAnswer, index)}
                maxCharacters={inputMaxCharacters}
              />
            )}
          />
        )}
      </View>
    </View>
  );
};

function Bar({
  numbers,
  strings = [],
  arrowIndices = [],
  total,
  dimens,
  cellColors,
  preBarText
}: BarProps) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles();

  return (
    <View style={styles.preBarTextContainer}>
      {preBarText && (
        <Text variant="WRN400" style={styles.preBarText}>
          {preBarText}
        </Text>
      )}
      <BarModel
        numbers={[numbers]}
        strings={[strings]}
        arrowIndices={[arrowIndices]}
        total={total}
        dimens={dimens}
        cellColors={[cellColors]}
        maxFontSize={displayMode === 'digital' ? 40 : 50}
      />
    </View>
  );
}

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        container: { columnGap: 8, flexDirection: 'row' },
        barContainer: { rowGap: 32, flexDirection: 'column' },
        answerContainer: {
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          columnGap: 8
        },
        preBarTextContainer: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        preBarText: {
          fontSize: displayMode === 'digital' ? 24 : 40,
          width: displayMode === 'digital' ? 100 : 200,
          textAlign: 'center',
          alignContent: 'center'
        }
      }),
    [displayMode]
  );
};

export const BarModelVerticalBraceWithState = withStateHOC(BarModelVerticalBrace, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray('', parseMarkup(props.braceText).numberOfAns),
    testComplete: state => state !== undefined && state.every(it => it !== '')
  })
});
