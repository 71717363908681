import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  barModelColors,
  barModelColorsArray,
  BarModelColorsKey,
  barModelColorsSchema,
  hundredSquareColors
} from '../../../../theme/colors';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  createHundredSquareShape,
  numbersToHundredSquareColors,
  rotateShape270
} from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { aRo } from '../../../Year 5/Spring/DecimalsAndPercentages/12UnderstandPercentages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVM',
  description: 'aVM',
  keywords: ['Percentage', 'Hundred square', 'Parts', 'Whole'],
  schema: z.object({
    percentShaded: z.number().int().min(0).max(100)
  }),
  simpleGenerator: () => {
    const percentShaded = randomIntegerInclusive(0, 100);
    return { percentShaded };
  },
  Component: props => {
    const {
      question: { percentShaded },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(percentShaded));

    const hundredSquareColor = getRandomFromArray(hundredSquareColors, {
      random: seededRandom(props.question)
    });

    const coloredSquare = numbersToHundredSquareColors(
      [percentShaded],
      [hundredSquareColor as string],
      270
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} dimens={dimens} cellColorMap={coloredSquare} />
        )}
        sentence={translate.answerSentences.ansPercentOfHundredSquareIsShaded()}
        testCorrect={[percentShaded.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVN',
  description: 'aVN',
  keywords: ['Percentage', 'Hundred square', 'Parts', 'Whole'],
  schema: z
    .object({
      percentShaded: z
        .number()
        .int()
        .min(0)
        .max(100)
        .refine(val => val !== 50, 'percentShaded cannot be 50'),
      incorrectAnswerA: z.number().int().min(0).max(100),
      incorrectAnswerB: z.number().int().min(0).max(100)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.percentShaded,
          val.incorrectAnswerA,
          val.incorrectAnswerB,
          100 - val.percentShaded
        ]),
      'Incorrect answers must be different to each other and percentShaded, as well as different to 100 - percentShaded'
    ),
  simpleGenerator: () => {
    const percentShaded = randomIntegerInclusive(0, 100, {
      constraint: x => x !== 50
    });

    const incorrectAnswerC = 100 - percentShaded;

    const [incorrectAnswerA, incorrectAnswerB] = randomUniqueIntegersInclusive(0, 100, 2, {
      constraint: x => x !== percentShaded && x !== incorrectAnswerC
    });

    return { percentShaded, incorrectAnswerA, incorrectAnswerB };
  },
  Component: props => {
    const {
      question: { percentShaded, incorrectAnswerA, incorrectAnswerB },
      translate
    } = props;

    const incorrectAnswerC = 100 - percentShaded;

    const items = shuffle([percentShaded, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC], {
      random: seededRandom(props.question)
    });

    const shape = rotateShape270(createHundredSquareShape(percentShaded));

    const hundredSquareColor = getRandomFromArray(hundredSquareColors, {
      random: seededRandom(props.question)
    });

    const coloredSquare = numbersToHundredSquareColors(
      [percentShaded],
      [hundredSquareColor as string],
      270
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectPercentageOfHundredSquareShaded()}
        pdfTitle={translate.instructions.circlePercentageOfHundredSquareShaded()}
        testCorrect={[percentShaded]}
        itemLayout={'column'}
        itemsOuterContainerStyle={{ gap: 16 }}
        mainPanelFlexDirection={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} dimens={dimens} cellColorMap={coloredSquare} />
        )}
        renderItems={items.map(item => ({
          value: item,
          component: <Text variant="WRN700">{item}%</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aVO',
  description: 'aVO',
  keywords: ['Percentage', 'Hundred square', 'Parts', 'Whole'],
  schema: z
    .object({
      percentShadedA: z.number().int().min(0).max(100),
      percentShadedB: z.number().int().min(0).max(100)
    })
    .refine(
      val => val.percentShadedA + val.percentShadedB <= 100,
      'percentShadedA + percentShadedB must not be greater than 100'
    ),
  simpleGenerator: () => {
    const percentShadedA = randomIntegerInclusive(0, 100);

    const percentShadedB = randomIntegerInclusive(0, 100 - percentShadedA);

    return { percentShadedA, percentShadedB };
  },
  Component: props => {
    const {
      question: { percentShadedA, percentShadedB },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(percentShadedA + percentShadedB));

    const [hundredSquareColorA, hundredSquareColorB] = getRandomSubArrayFromArray(
      hundredSquareColors,
      2,
      {
        random: seededRandom(props.question)
      }
    );

    const percentsToInclude = [];
    const colorsToInclude = [];

    if (percentShadedA !== 0) {
      percentsToInclude.push(percentShadedA);
      colorsToInclude.push(hundredSquareColorA);
    }

    if (percentShadedB !== 0) {
      percentsToInclude.push(percentShadedB);
      colorsToInclude.push(hundredSquareColorB);
    }

    const coloredSquare = numbersToHundredSquareColors(percentsToInclude, colorsToInclude, 270);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatPercentageOfHundredSquareIsNotShaded()}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={shape} dimens={dimens} cellColorMap={coloredSquare} />
        )}
        sentence={translate.answerSentences.ansPercentOfHundredSquareIsNotShaded()}
        testCorrect={[(100 - (percentShadedA + percentShadedB)).toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aVP',
  description: 'aVP',
  keywords: ['Percentages', 'Bar model', 'Parts', 'Whole'],
  schema: z.object({
    percentShaded: z.number().int().min(5).max(100).multipleOf(5),
    barModelColor: barModelColorsSchema
  }),
  simpleGenerator: () => {
    const percentShaded = randomIntegerInclusiveStep(5, 100, 5);

    const barModelColor = getRandomFromArray(barModelColorsArray) as BarModelColorsKey;

    return { percentShaded, barModelColor };
  },
  Component: props => {
    const {
      question: { percentShaded, barModelColor },
      translate
    } = props;

    const shadedColorArray = filledArray(barModelColors[barModelColor], percentShaded / 5);

    const unshadedArray = filledArray('white', 20 - percentShaded / 5);

    const customColorMap =
      percentShaded % 10 === 0
        ? shuffle([...shadedColorArray, ...unshadedArray], {
            random: seededRandom(props.question)
          })
        : [...shadedColorArray, ...unshadedArray];

    // Answer

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>%'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatPercentageOfTheBarModelIsShaded()}
        testCorrect={[percentShaded.toString()]}
        Content={({ dimens }) => {
          return (
            <ShadedFractionBarModel
              totalSubSections={20}
              customColorMap={customColorMap}
              totalPerSection={2}
              width={dimens.width}
              hideDashedLines
            />
          );
        }}
      />
    );
  }
});

const Question5: typeof aRo = {
  ...aRo,
  uid: 'aVQ',
  description: 'aVQ',
  keywords: ['Percentage', 'Bar model', 'Parts', 'Whole']
};

const Question6 = newQuestionContent({
  uid: 'aVR',
  description: 'aVR',
  keywords: ['Percentage', 'Bar model', 'Parts', 'Whole'],
  schema: z
    .object({
      correctPercentShaded: z.number().int().min(5).max(95),
      incorrectPercentShaded: z.number().int().min(5).max(95),
      percentShadedA: z.number().int().min(5).max(95),
      percentShadedB: z.number().int().min(5).max(95)
    })
    .refine(
      val => val.correctPercentShaded !== val.incorrectPercentShaded,
      'correctPercentShaded and incorrectPercentShaded'
    ),
  simpleGenerator: () => {
    const isPercentShadedACorrect = getRandomBoolean();

    const isPercentShadedBCorrect = getRandomBoolean();

    const [correctPercentShaded, incorrectPercentShaded] = randomUniqueIntegersInclusiveStep(
      5,
      95,
      5,
      2
    );

    const percentShadedA = isPercentShadedACorrect
      ? correctPercentShaded
      : randomIntegerInclusiveStep(5, 95, 5, {
          // Can be same as incorrectPercentShaded and percentShadedA, as these will be coloured/shuffled differently.
          constraint: x => x !== correctPercentShaded
        });

    const percentShadedB = isPercentShadedBCorrect
      ? correctPercentShaded
      : randomIntegerInclusiveStep(5, 95, 5, {
          // Can be same as incorrectPercentShaded and percentShadedA, as these will be coloured/shuffled differently.
          constraint: x => x !== correctPercentShaded
        });

    return {
      correctPercentShaded,
      incorrectPercentShaded,
      percentShadedA,
      percentShadedB
    };
  },
  Component: props => {
    const {
      question: { correctPercentShaded, incorrectPercentShaded, percentShadedA, percentShadedB },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barModelColorsArray, 4, {
      random: seededRandom(props.question)
    });

    // correctPercentShaded:
    const correctShadedColorArray = filledArray(
      barModelColors[colors[0]],
      correctPercentShaded / 5
    );

    const correctUnshadedArray = filledArray('white', 20 - correctPercentShaded / 5);

    const correctCustomColorMap = [...correctShadedColorArray, ...correctUnshadedArray];

    // incorrectPercentShaded:
    const incorrectShadedColorArray = filledArray(
      barModelColors[colors[1]],
      incorrectPercentShaded / 5
    );

    const incorrectUnshadedArray = filledArray('white', 20 - incorrectPercentShaded / 5);

    const incorrectCustomColorMap = [...incorrectShadedColorArray, ...incorrectUnshadedArray];

    // percentShadedA:
    const shadedColorArrayA = filledArray(barModelColors[colors[2]], percentShadedA / 5);

    const unshadedArrayA = filledArray('white', 20 - percentShadedA / 5);

    const customColorMapA = shuffle([...shadedColorArrayA, ...unshadedArrayA], {
      random: seededRandom(colors[2]) // Shuffle the colorMap of this bar model, ensuring different shuffle to customColorMapB.
    });

    // percentShadedB:
    const shadedColorArrayB = filledArray(barModelColors[colors[3]], percentShadedB / 5);

    const unshadedArrayB = filledArray('white', 20 - percentShadedB / 5);

    const customColorMapB = shuffle([...shadedColorArrayB, ...unshadedArrayB], {
      random: seededRandom(colors[3]) // Shuffle the colorMap of this bar model, ensuring different shuffle to customColorMapA.
    });

    const items = shuffle(
      [
        {
          colorMap: correctCustomColorMap,
          isCorrect: true,
          value: 1
        },
        {
          colorMap: incorrectCustomColorMap,
          isCorrect: false,
          value: 2
        },
        {
          colorMap: customColorMapA,
          isCorrect: percentShadedA === correctPercentShaded,
          value: 3
        },
        {
          colorMap: customColorMapB,
          isCorrect: percentShadedB === correctPercentShaded,
          value: 4
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const itemsCorrect = items.filter(statement => statement.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectBarModelsWherePercentShadedIn(
          itemsCorrect.length,
          correctPercentShaded
        )}
        pdfTitle={translate.instructions.circleBarModelsWherePercentShadedIn(
          itemsCorrect.length,
          correctPercentShaded
        )}
        testCorrect={itemsCorrect.map(statement => statement.value)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(({ colorMap, value }) => ({
            value,
            component: (
              <ShadedFractionBarModel
                totalSubSections={20}
                customColorMap={colorMap}
                totalPerSection={2}
                width={dimens.width * 0.95}
              />
            )
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandPercentages',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
