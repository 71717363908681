import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { ADD, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFU',
  description: 'aFU',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    tens: z.number().int().min(10).max(80).multipleOf(10),
    ones: z.number().int().min(1).max(8),
    numOfRows: numberEnum([2, 3, 4, 5]),
    incorrectAnswer: z.enum(['+10', '-10', '+1', '-1', '+11', '-11'])
  }),
  simpleGenerator: () => {
    const numOfRows = getRandomFromArray([2, 3, 4, 5] as const);

    const tens = randomIntegerInclusiveStep(10, 70, 10);

    const ones = randomIntegerInclusive(1, 7, { constraint: x => x * numOfRows >= 10 });

    const incorrectAnswer = getRandomFromArray(
      tens === 10 && ones === 1
        ? (['+10', '-10', '+1', '-1', '+11'] as const)
        : (['+10', '-10', '+1', '-1', '+11', '-11'] as const)
    );

    return { tens, ones, numOfRows, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, incorrectAnswer },
      translate,
      displayMode
    } = props;

    const number4 = tens + ones;

    const amountOfTens = tens / 10;

    const [amountOfIncorrectTens, amountOfIncorrectOnes] = (() => {
      switch (incorrectAnswer) {
        case '+10':
          return [amountOfTens + 1, ones];
        case '-10':
          return [amountOfTens - 1, ones];
        case '+1':
          return [amountOfTens, ones + 1];
        case '-1':
          return [amountOfTens, ones - 1];
        case '+11':
          return [amountOfTens + 1, ones + 1];
        case '-11':
          return [amountOfTens - 1, ones - 1];
      }
    })();

    const tensImagesCorrect = getImagesByAmount('Place_value/10', amountOfTens);
    const tensImagesIncorrect = getImagesByAmount('Place_value/10', amountOfIncorrectTens);

    // Correct amount of ones
    const onesImagesCorrect = getImagesByAmount('Place_value/1', ones);
    // Incorrect amount of ones
    const onesImagesIncorrect = getImagesByAmount('Place_value/1', amountOfIncorrectOnes);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 180, height: 50 } : { width: 380, height: 100 };

    const scalesToCheck = [
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesCorrect
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesCorrect
      )
    ];

    // Only check these if the number of incorrect tens or ones is greater than zero - otherwise, this function breaks.
    if (amountOfIncorrectTens > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          tensImagesIncorrect
        )
      );
    }

    if (amountOfIncorrectOnes > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          onesImagesIncorrect
        )
      );
    }

    const scaleFactor = Math.min(...scalesToCheck);

    // Correct data
    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesCorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesCorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const dataB = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesIncorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesIncorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    const charts = shuffle(
      [
        {
          tensImages: tensImagesCorrect,
          onesImages: onesImagesCorrect,
          data: dataA,
          isCorrect: true,
          value: 'correct'
        },
        {
          tensImages: tensImagesIncorrect,
          onesImages: onesImagesIncorrect,
          data: dataB,
          isCorrect: false,
          value: 'incorrect'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXTimesY(number4, numOfRows)}
        pdfTitle={translate.instructions.selectPlaceValueChartRepresentsXTimesYPDF(
          number4,
          numOfRows
        )}
        testCorrect={['correct']}
        numItems={2}
        renderItems={({ dimens }) => {
          return charts.map(chart => ({
            value: chart.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor: placeValueColumnInfo[1].color
                    },
                    textStyle: {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor: placeValueColumnInfo[0].color
                    },
                    textStyle: {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={chart.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aFV',
  description: 'aFV',
  keywords: ['Multiplication', 'Partition', '2-digit', 'Place value chart'],
  schema: z.object({
    tens: z.number().int().min(10).max(90).multipleOf(10),
    ones: z.number().int().min(1).max(9),
    numOfRows: numberEnum([2, 3, 4, 5])
  }),
  simpleGenerator: () => {
    const numOfRows = getRandomFromArray([2, 3, 4, 5] as const);
    const tens = randomIntegerInclusiveStep(10, 90, 10);
    const ones = randomIntegerInclusive(1, 9, { constraint: x => x * numOfRows >= 10 });

    return { tens, ones, numOfRows };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows },
      translate,
      displayMode
    } = props;

    const number4 = tens + ones;

    const amountOfTens = tens / 10;

    const tensImagesCorrect = getImagesByAmount('Place_value/10', amountOfTens);

    const onesImagesCorrect = getImagesByAmount('Place_value/1', ones);

    const scalesToCheck = [
      calcRowOfImagesScaleFactor(200, 50, tensImagesCorrect),
      calcRowOfImagesScaleFactor(200, 50, onesImagesCorrect)
    ];

    const scaleFactor = Math.min(...scalesToCheck);

    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 2 }}
          images={tensImagesCorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 2 }}
          images={onesImagesCorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToWorkOutX(
          `${number4.toLocaleString()} ${MULT} ${numOfRows.toLocaleString()}`
        )}
        testCorrect={[Number(number4 * numOfRows).toString()]}
        sentence={`${number4.toLocaleString()} ${MULT} ${numOfRows.toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[1].color
                },
                textStyle: {
                  color: placeValueColumnInfo[1].textColor
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color
                },
                textStyle: {
                  color: placeValueColumnInfo[0].textColor
                }
              }
            ]}
            tableData={dataA}
            tableStyle={{ width: dimens.width - 24 }}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aFY',
  description: 'aFY',
  keywords: ['Multiplication', '2-digit', '1-digit'],
  schema: z.object({
    tens: z.number().int().min(20).max(90).multipleOf(10),
    ones: z.number().int().min(2).max(9),
    multiplicationRHS: numberEnum([2, 3, 4, 5, 8])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(20, 90, 10);
    const ones = randomIntegerInclusive(2, 9);
    const multiplicationRHS = getRandomFromArray([2, 3, 4, 5, 8] as const);

    return { tens, ones, multiplicationRHS };
  },
  Component: props => {
    const {
      question: { tens, ones, multiplicationRHS },
      translate
    } = props;

    const multiplicationLHS = tens + ones;
    const var5 = tens * multiplicationRHS;
    const var6 = ones * multiplicationRHS;
    const var7 = multiplicationLHS * multiplicationRHS;

    const answerOption = shuffle(
      [
        Number(tens / 10).toString(),
        ones.toString(),
        var5.toString(),
        var6.toString(),
        var7.toString()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const sentences = [
      {
        sentence: `${multiplicationLHS.toLocaleString()} ${MULT} ${multiplicationRHS.toLocaleString()} = <ans/> ${translate.misc.tens()} ${MULT} ${multiplicationRHS.toLocaleString()} ${ADD} <ans/> ${translate.misc.ones()} ${MULT} ${multiplicationRHS.toLocaleString()}`,
        answer: [Number(tens / 10).toString(), ones.toString()]
      },
      {
        sentence: `${multiplicationLHS.toLocaleString()} ${MULT} ${multiplicationRHS.toLocaleString()} = <ans/> ${ADD} <ans/>`,
        answer: [var5.toString(), var6.toString()]
      },
      {
        sentence: `${multiplicationLHS.toLocaleString()} ${MULT} ${multiplicationRHS.toLocaleString()} = <ans/>`,
        answer: [var7.toString()]
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToWorkOutXMultY(
          multiplicationLHS,
          multiplicationRHS
        )}
        pdfTitle={translate.instructions.useCardsToWorkOutXMultY(
          multiplicationLHS,
          multiplicationRHS
        )}
        items={answerOption}
        sentencesStyle={{ alignItems: 'flex-start' }}
        pdfSentencesStyle={{ alignItems: 'flex-start' }}
        sentences={sentences.map(sentence => sentence.sentence)}
        actionPanelVariant="endWide"
        pdfLayout="itemsTop"
        testCorrect={userAnswer => {
          const [topAnswer, middleAnswer, bottomAnswer] = userAnswer;
          return (
            arraysHaveSameContents(topAnswer, sentences[0].answer) &&
            arraysHaveSameContentsUnordered(middleAnswer, sentences[1].answer) &&
            arraysHaveSameContents(bottomAnswer, sentences[2].answer)
          );
        }}
        moveOrCopy="move"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [sentences[0].answer, sentences[1].answer, sentences[2].answer]
        }}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aFW',
  description: 'aFW',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    var1: numberEnum([2, 3, 4, 5, 8]),
    var2: z.number().int().min(10).max(90).multipleOf(10),
    var3: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const var1 = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const var2 = randomIntegerInclusiveStep(10, 90, 10);
    const var3 = randomIntegerInclusive(1, 9, { constraint: x => var1 * x >= 10 });

    return { var1, var2, var3 };
  },

  Component: props => {
    const {
      question: { var1, var2, var3 },
      translate
    } = props;

    const var4 = var2 + var3;

    const eqA = getBinOpEquation({
      left: var2,
      right: var1,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: var3,
      right: var1,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: var4,
      right: var1,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeEachCalculationToWorkOutX(
          `${var4.toLocaleString()} ${MULT} ${var1.toLocaleString()}`
        )}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFX',
  description: 'aFX',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    var1a: numberEnum([2, 3, 4, 5, 8]),
    var4a: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const var1a = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const var2a = randomIntegerInclusiveStep(10, 90, 10);
    const var3a = randomIntegerInclusive(1, 9, { constraint: x => var1a * x >= 10 });
    const var4a = var2a + var3a;

    return { var1a, var4a };
  },

  Component: props => {
    const {
      question: { var1a, var4a },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: var4a,
      right: var1a,
      sign: 'multiply',
      answer: 'result'
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={binOpEquationsToTestCorrect([eqA])[0]}
        sentence={binOpEquationToSentenceString(eqA)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aFZ',
  description: 'aFZ',
  keywords: ['Multiplication', '2-digit', '1-digit'],
  schema: z.object({
    var4a: z.number().int().min(11).max(99),
    correctAnswerA: z.number().int().min(1).max(792)
  }),
  simpleGenerator: () => {
    const var1a = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const var2a = randomIntegerInclusiveStep(10, 90, 10);
    const var3a = randomIntegerInclusive(1, 9, { constraint: x => var1a * x >= 10 });
    const var4a = var2a + var3a;
    const correctAnswerA = var4a * var1a;

    return { var4a, correctAnswerA };
  },
  Component: props => {
    const {
      question: { var4a, correctAnswerA },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: var4a,
      result: correctAnswerA,
      sign: 'multiply',
      answer: 'right'
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={binOpEquationsToTestCorrect([eqA])[0]}
        sentence={binOpEquationToSentenceString(eqA)}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aFZ2',
  description: 'aFZ',
  keywords: ['Multiplication', '2-digit', '1-digit'],
  schema: z.object({
    product: z.number().int().min(22).max(250),
    draggableItems: z.array(z.number().int().min(1).max(10)).length(6)
  }),
  simpleGenerator: () => {
    const [multiplier, fakeMultiplier] = getRandomSubArrayFromArray([2, 3, 4, 5, 8] as const, 2);

    const { number2, number3, product } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(1, 9);
        const number3 = randomIntegerInclusive(1, 9);

        const product = (number2 * 10 + number3) * multiplier;

        return { number2, number3, product };
      },
      ({ number2, number3, product }) => number2 * number3 >= 10 && product <= 250
    );

    // Distractors
    const [number6, number7] = randomUniqueIntegersInclusive(1, 9, 2, {
      constraint: x => ![number2, number3, multiplier, fakeMultiplier].includes(x)
    });

    const draggableItems = shuffle([
      multiplier,
      number2,
      number3,
      fakeMultiplier,
      number6,
      number7
    ]);

    return { product, draggableItems };
  },
  Component: props => {
    const {
      question: { product, draggableItems },
      translate
    } = props;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        sentence={`<ans/><ans/> ${MULT} <ans/> = ${product.toLocaleString()}`}
        items={draggableItems}
        testCorrect={userAnswer => {
          // Join first 2 digits together
          const firstNum = Number(`${userAnswer[0]}${userAnswer[1]}`);
          const secondNum = Number(userAnswer[2]);
          return firstNum * secondNum === product;
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidMultiplicationUsingAvailCards()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA2DigitNumberByA1DigitNumberWithExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
