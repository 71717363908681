import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { MULT } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjp',
  description: 'bjp',
  keywords: ['5 times-table', '10 times-table', 'Multiples'],
  schema: z
    .object({
      timesTable: numberEnum([5, 10]),
      values: z
        .array(z.number().int().min(5).max(100).step(5))
        .length(6)
        .refine(arrayHasNoDuplicates)
    })
    .refine(
      ({ values, timesTable }) =>
        values.some(num => num % [10, 5].filter(val => val !== timesTable)[0] === 0),
      'at least one correct answer'
    ),
  simpleGenerator: () => {
    const timesTable = getRandomFromArrayWithWeights([5, 10] as const, [4, 1]);

    // correct value must be divisible by both so step 10
    const correct = randomIntegerInclusiveStep(10, 100, 10);
    const values = randomUniqueIntegersInclusiveStep(timesTable, 100, timesTable, 5, {
      constraint: x => x !== correct
    });

    return {
      timesTable,
      values: shuffle([...values, correct])
    };
  },
  Component: ({ question, translate }) => {
    const { timesTable, values } = question;
    const other = [10, 5].filter(val => val !== timesTable)[0];

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.theNumbersAreAllInXTimesTableSelectNumbersAlsoInYTimesTable(
          timesTable,
          other
        )}
        pdfTitle={translate.ks1PDFInstructions.theNumbersAreAllInXTimesTableTickNumbersAlsoInYTimesTable(
          timesTable,
          other
        )}
        items={values}
        multiSelect
        testCorrect={values.filter(it => it % other === 0)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bjq',
  description: 'bjq',
  keywords: ['5 times-table', '10 times-table', 'Multiples'],
  schema: z.object({
    values: z
      .array(z.number().int().min(5).max(100).step(5))
      .length(6)
      .refine(arrayHasNoDuplicates)
      .refine(val => val.some(i => i % 10 !== 0))
  }),
  simpleGenerator: () => {
    const multOf5Only = randomIntegerInclusiveStep(5, 100, 5, { constraint: x => x % 10 !== 0 });
    const values = randomUniqueIntegersInclusiveStep(5, 100, 5, 5, {
      constraint: x => x !== multOf5Only
    });

    return {
      values: shuffle([...values, multOf5Only])
    };
  },
  Component: ({ question, translate }) => {
    const { values } = question;

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.sortTheNumbersIntoTheTable()}
        items={values}
        testCorrect={[
          values.filter(val => val % 10 !== 0),
          values.filter(val => val % 10 === 0),
          []
        ]}
        actionPanelVariant="bottom"
        headerHeight={100}
        questionHeight={900}
        headerTextStyle={{ textAlign: 'center', lineHeight: 40 }}
        zoneNames={[
          translate.ks1MiscStrings['Only in the 5 times-table'](),
          translate.ks1MiscStrings['In both the 5 and 10 times-tables'](),
          translate.ks1MiscStrings['Only in the 10 times-table']()
        ]}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bjr',
  description: 'bjr',
  keywords: ['5 times-table', '10 times-table', 'Multiply'],
  schema: z.object({
    number: z.number().min(1).max(6),
    isAnsFirst: z.boolean(),
    isTenFirst: z.boolean(),
    isFiveFirst: z.boolean(),
    isFiveTimesFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 6);
    const isAnsFirst = getRandomBoolean();
    const isTenFirst = getRandomBoolean();
    const isFiveFirst =
      number === 5 ? isTenFirst : getRandomFromArrayWithWeights([isTenFirst, !isTenFirst], [3, 1]);
    const isFiveTimesFirst = getRandomBoolean();

    return {
      number,
      isAnsFirst,
      isFiveFirst,
      isTenFirst,
      isFiveTimesFirst
    };
  },
  Component: ({
    question: { number, isAnsFirst, isFiveFirst, isTenFirst, isFiveTimesFirst },
    translate
  }) => {
    const fiveValues = isFiveFirst ? [5, number * 2] : [number * 2, 5];
    const tenValues = isTenFirst ? [10, number] : [number, 10];

    const sentences = isAnsFirst
      ? [
          `<ans/> = ${tenValues[0].toLocaleString()} ${MULT} ${tenValues[1].toLocaleString()}`,
          `<ans/> = ${fiveValues[0].toLocaleString()} ${MULT} ${fiveValues[1].toLocaleString()}`
        ]
      : [
          `${tenValues[0].toLocaleString()} ${MULT} ${tenValues[1].toLocaleString()} = <ans/>`,
          `${fiveValues[0].toLocaleString()} ${MULT} ${fiveValues[1].toLocaleString()} = <ans/>`
        ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheCalculations()}
        sentences={isFiveTimesFirst ? [...sentences].reverse() : sentences}
        containerStyle={{ alignItems: isAnsFirst ? 'flex-start' : 'flex-end' }}
        testCorrect={[[(number * 10).toString()], [(number * 10).toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The5And10TimesTables',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
