// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import MultiplicationAndDivisionB from './MultiplicationAndDivisionB';
import LengthAndPerimeter from './LengthAndPerimeter';
import FractionsA from './FractionsA';
import MassAndCapacity from './MassAndCapacity';

const Term = newTermContent({
  term: 'Spring',
  blocks: [MultiplicationAndDivisionB, LengthAndPerimeter, FractionsA, MassAndCapacity]
});
export default Term;
