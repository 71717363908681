import { StyleSheet } from 'react-native';
import { type SvgImageProps } from './svgImageTypes';
import { type CSSProperties } from 'react';

export default function SvgImage({ source, width, height, fill, style }: SvgImageProps) {
  // On web, we use <img> tags directly .svg files

  const styleFlattened = StyleSheet.flatten(style) as CSSProperties;

  // On web, objectFit="contain" seems to sometimes leave padding around SVGs, so we use "fill" as a workaround.
  // See https://bitbucket.org/whiterosemaths/question-store/pull-requests/1041
  // This doesn't stretch the SVG - SVGs have their own "contain" logic that applies within this <img>, so the
  // SVG won't actually get stretched. It just seems to help avoid spurious padding.
  styleFlattened.objectFit = 'fill';

  if (fill) {
    return <img src={source} width="100%" height="100%" style={styleFlattened} draggable="false" />;
  } else {
    return (
      <img src={source} width={width} height={height} style={styleFlattened} draggable="false" />
    );
  }
}
