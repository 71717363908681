import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { findExchanges, numbersDoNotExchange } from 'common/src/utils/exchanges';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD, SUB } from 'common/src/constants';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import { range } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import ContentBox from '../../../../components/molecules/ContentBox';
import { colors, drawPieChartColors } from '../../../../theme/colors';
import Text from '../../../../components/typography/Text';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aiY',
  description: 'aiY',
  keywords: ['Subtraction', 'Counters'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(20001)
        .max(99999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10001)
        .max(89999)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => val.var1 > val.var2, 'var1 should be more than var2'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(20001, 99999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(10001, var1 - 10000, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have no exchanges
      ({ var1, var2 }) => var1 > var2 && numbersDoNotExchange(var1, -var2)
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const answer = var1 - var2;
    const answerMissingDigits = range(0, answer.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={answer}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aiZ',
  description: 'aiZ',
  keywords: ['Subtraction'],
  schema: z
    .object({
      differenceA: z.number().int().min(101).max(898),
      subtrahendA: z.number().int().min(202).max(999),
      multiplierC: numberEnum([100, 1000]),
      answerBoxPositionA: z.enum(['left', 'right', 'result']),
      answerBoxPositionB: z.enum(['left', 'right', 'result']),
      answerBoxPositionC: z.enum(['left', 'right', 'result'])
    })
    .refine(
      val => val.subtrahendA > val.differenceA + 100,
      'subtrahendA must be more than 100 more than differenceA.'
    ),
  simpleGenerator: () => {
    const differenceA = randomIntegerInclusive(101, 898);

    const subtrahendA = randomIntegerInclusive(differenceA + 101, 999);

    const multiplierC = getRandomFromArray([100, 1000] as const);

    const answerBoxPositionA = getRandomFromArray(['left', 'right', 'result'] as const);

    const answerBoxPositionB = getRandomFromArray(['left', 'right', 'result'] as const);

    const answerBoxPositionC = getRandomFromArray(['left', 'right', 'result'] as const);

    return {
      differenceA,
      subtrahendA,
      multiplierC,
      answerBoxPositionA,
      answerBoxPositionB,
      answerBoxPositionC
    };
  },

  Component: props => {
    const {
      question: {
        differenceA,
        subtrahendA,
        multiplierC,
        answerBoxPositionA,
        answerBoxPositionB,
        answerBoxPositionC
      },
      translate,
      displayMode
    } = props;

    const minuendA = differenceA + subtrahendA;

    const subtrahendB = subtrahendA * 10;

    const differenceB = differenceA * 10;

    const subtrahendC = subtrahendA * multiplierC;

    const differenceC = differenceA * multiplierC;

    const contentBoxBackground =
      displayMode !== 'digital' ? colors.white : drawPieChartColors.Green;

    const eqs = [
      getBinOpEquation({
        right: subtrahendA,
        result: differenceA,
        sign: 'subtract',
        answer: answerBoxPositionA
      }),
      getBinOpEquation({
        right: subtrahendB,
        result: differenceB,
        sign: 'subtract',
        answer: answerBoxPositionB
      }),
      getBinOpEquation({
        right: subtrahendC,
        result: differenceC,
        sign: 'subtract',
        answer: answerBoxPositionC
      })
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useCalculationToCompleteNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        sentenceStyle={{ alignSelf: 'center' }}
        Content={
          <ContentBox containerStyle={{ backgroundColor: contentBoxBackground }}>
            <Text
              variant="WRN400"
              style={{
                color: colors.black,
                // Slightly smaller font size needed to ensure number does not cut off on mobiles:
                fontSize: displayMode !== 'digital' ? 50 : 32,
                lineHeight: displayMode !== 'digital' ? 75 : 48
              }}
            >
              {`${differenceA.toLocaleString()} ${ADD} ${subtrahendA.toLocaleString()} = ${minuendA.toLocaleString()}`}
            </Text>
          </ContentBox>
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ai0',
  description: 'ai0',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(20001)
        .max(99999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10001)
        .max(89999)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => val.var1 > val.var2, 'var1 should be more than var2'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(20001, 99999, { constraint: x => x % 10 !== 0 });

        const var2 = randomIntegerInclusive(10001, var1 - 10000, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have no exchanges and the difference between the two numbers is at least 10,000
      ({ var1, var2 }) =>
        var1 > var2 && findExchanges(var1, -var2).length === 1 && var1 - var2 >= 10000
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Question4 exported to Q ajz
const Question4 = newQuestionContent({
  uid: 'ai1',
  description: 'ai1',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z.object({
    minuend: z.number().int().min(20000).max(99999),
    subtrahend: z.number().int().min(10000).max(89999)
  }),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(20000, 99999);
        const subtrahend = randomIntegerInclusive(10000, minuend - 10000);
        return { minuend, subtrahend };
      },
      // Only permit them if they have more than one exchange and produce a difference of at least 10,000
      ({ minuend, subtrahend }) =>
        findExchanges(minuend, -subtrahend).length > 1 && minuend - subtrahend >= 10000
    );

    return { minuend, subtrahend };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const number3 = minuend - subtrahend;

    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXSubtractY(
          minuend.toLocaleString(),
          subtrahend.toLocaleString()
        )}
        topNumber={minuend}
        bottomNumber={subtrahend}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const ai1 = Question4;

const Question5 = newQuestionContent({
  uid: 'ai2',
  description: 'ai2',
  keywords: ['Subtraction', 'Efficient'],
  schema: z
    .object({
      number1: z.number().int().min(11000).max(99999),
      number2: z.number().int().min(10000).max(99998)
    })
    .refine(val => val.number1 > val.number2, 'number1 must be greater than number2.'),
  simpleGenerator: () => {
    const oneOrTwoEndsIn9s = randomIntegerInclusive(1, 2);

    const number2ThOrTTh = getRandomFromArray(['thousands', 'tenThousands'] as const);

    const number1 = (() => {
      if (oneOrTwoEndsIn9s === 1) {
        return randomIntegerInclusiveStep(20000, 100000, 10000) - 1;
      } else {
        switch (number2ThOrTTh) {
          case 'thousands':
            // If num2 is going to be 10999 or 11999 etc., the lowest num1 can be is 11000
            return randomIntegerInclusive(11000, 99999);
          case 'tenThousands':
            // If num2 is going to be 19999 or 29999 etc., the lowest num1 can be is 20000
            return randomIntegerInclusive(20000, 99999);
        }
      }
    })();

    const number2 = (() => {
      if (oneOrTwoEndsIn9s === 1) {
        return randomIntegerInclusive(10000, number1 - 1);
      } else {
        switch (number2ThOrTTh) {
          case 'thousands':
            // Range is from 11000 to the rounded-down thousand of num1.
            return randomIntegerInclusiveStep(11000, Math.floor(number1 / 1000) * 1000, 1000) - 1;
          case 'tenThousands':
            // Range is from 20000 to the rounded-down tenThousand of num1.
            return (
              randomIntegerInclusiveStep(20000, Math.floor(number1 / 10000) * 10000, 10000) - 1
            );
        }
      }
    })() as number;

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.useEfficientMethodToCompleteSubtraction()}
        testCorrect={[(number1 - number2).toString()]}
        sentence={`${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        {...props}
      />
    );
  }
});

// Question6 exported to Q ajC
const Question6 = newQuestionContent({
  uid: 'ai3',
  description: 'ai3',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      answerNumber: z.number().int().min(10000).max(89999),
      bottomNumber: z.number().int().min(10000).max(89999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer']),
      missingTenThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val =>
        findExchanges(val.answerNumber, val.bottomNumber).length > 0 &&
        findExchanges(val.answerNumber, val.bottomNumber).length < 4,
      'answerNumber and bottomNumber must have one, two or three exchanges.'
    )
    .refine(
      val => val.answerNumber + val.bottomNumber < 100000,
      'answerNumber + bottomNumber must be less than 100,000'
    ),
  simpleGenerator: () => {
    const { answerNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const answerNumber = randomIntegerInclusive(10000, 89999);
        const bottomNumber = randomIntegerInclusive(10000, 99999 - answerNumber);
        return { answerNumber, bottomNumber };
      },
      // Only permit them if they have 1, 2 or 3 exchanges.
      ({ answerNumber, bottomNumber }) =>
        findExchanges(answerNumber, bottomNumber).length > 0 &&
        findExchanges(answerNumber, bottomNumber).length < 4
    );

    const numberWithExtraMissingDigit1 = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    // Filter out numberWithExtraMissingDigit1 so we do not get three missing digits in the same number.
    const numberWithExtraMissingDigit2 = getRandomFromArray(
      ['top', 'bottom', 'answer'].filter(item => item !== numberWithExtraMissingDigit1)
    ) as 'top' | 'bottom' | 'answer';

    const [missingOnes, missingTens, missingHundreds, missingThousands, missingTenThousands] =
      shuffle([
        'top',
        'bottom',
        'answer',
        numberWithExtraMissingDigit1,
        numberWithExtraMissingDigit2
      ] as const);

    return {
      answerNumber,
      bottomNumber,
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands,
      missingTenThousands
    };
  },

  Component: props => {
    const {
      question: {
        answerNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands,
        missingTenThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands,
      missingTenThousands
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const ai3 = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractWholeNumbersWithMoreThanFourDigits',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
