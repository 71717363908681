import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  getDayNameForGivenDate,
  getNumberOfDaysInMonth,
  getRandomMonth,
  getSelectedDateFromFirstDateOfDayInMonth,
  monthNames,
  monthSchema
} from 'common/src/utils/months';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import Text from 'common/src/components/typography/Text';
import { unit } from 'mathjs';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getFemaleName, getRandomUniqueMaleNames, nameSchema } from 'common/src/utils/names';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { Calendar } from 'common/src/components/question/representations/Calendar';
import { daySchema, dayNames } from 'common/src/utils/days';
import { getNumeralOrdinal, getWordOrdinal } from 'common/src/utils/ordinals';
import { numberEnum } from 'common/src/utils/zod';
import QF7InteractiveTable from 'common/src/components/question/questionFormats/QF7InteractiveTable';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axG',
  description: 'axG',
  keywords: ['Months', 'Days'],
  schema: z.object({
    months: z.array(monthSchema).length(4)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const months = getRandomSubArrayFromArray(monthNames, 4);

    return { months };
  },
  Component: ({ question: { months }, translate }) => {
    // 30 days or less
    const thirtyDaysOrLessMonths = months.filter(month => getNumberOfDaysInMonth(month) <= 30);
    // 31 days exactly
    const thirtyOneDaysMonths = months.filter(month => getNumberOfDaysInMonth(month) === 31);

    const correctOrder = [thirtyDaysOrLessMonths, thirtyOneDaysMonths];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortTheMonthsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsSortTheMonthsIntoTheTable()}
        zoneNames={[
          translate.instructions.thirtyDaysOrLess(),
          translate.instructions.thirtyOneDays()
        ]}
        items={months}
        itemVariant="rectangle"
        testCorrect={correctOrder}
        questionHeight={800}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axH',
  description: 'axH',
  keywords: ['Years', 'Months', 'Weeks', 'Days'],
  schema: z.object({
    weeksA: z.number().int().min(2).max(5),
    weeksB: z.number().int().min(60).max(360),
    yearsA: z.number().int().min(1).max(3),
    yearsB: z.number().int().min(2).max(5),
    days: z.number().int().min(5).max(25),
    months: z.number().int().min(10).max(50)
  }),
  simpleGenerator: () => {
    const weeksA = randomIntegerInclusive(2, 5);
    const weeksB = Math.random() < 0.5 ? randomIntegerInclusiveStep(60, 100, 10) : 360;
    const yearsA = randomIntegerInclusive(1, 3);
    const yearsB = randomIntegerInclusive(2, 5);
    const days = randomIntegerInclusive(5, 25);
    const months = Math.random() < 0.5 ? randomIntegerInclusiveStep(10, 50, 10) : 12;

    return {
      weeksA,
      weeksB,
      yearsA,
      yearsB,
      days,
      months
    };
  },
  Component: props => {
    const {
      question: { weeksA, weeksB, yearsA, yearsB, days, months },
      translate,
      displayMode
    } = props;
    const weeksAToDays = weeksA * 7;
    const weeksBToDays = weeksB * 7;
    const yearsAToDays = unit(yearsA, 'years').to('days').toNumber();
    const yearsBToDays = unit(yearsB, 'years').to('days').toNumber();
    const oneMonthToDays = unit(1, 'month').to('days').toNumber();
    const monthsToDays = unit(months, 'month').to('days').toNumber();

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'right' }}
            >
              {translate.time.numWeeks(weeksA)}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'left' }}
            >
              {translate.time.numDays(weeksAToDays)}
            </Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(weeksAToDays, weeksAToDays)
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'right' }}
            >
              {translate.time.numDays(days)}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'left' }}
            >
              {translate.time.numMonths(1)}
            </Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(days, oneMonthToDays)
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'right' }}
            >
              {translate.time.numWeeks(weeksB)}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'left' }}
            >
              {translate.time.numYears(yearsA)}
            </Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(weeksBToDays, yearsAToDays)
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'right' }}
            >
              {translate.time.numMonths(months)}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 220 : 300, textAlign: 'left' }}
            >
              {translate.time.numYears(yearsB)}
            </Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(monthsToDays, yearsBToDays)
        }
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF6DragMatchStatements
        title={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'axI',
  description: 'axI',
  keywords: ['Weeks', 'Days'],
  schema: z.object({
    weeksA: z.number().int().min(2).max(4),
    weeksB: z.number().int().min(5).max(9),
    weeksC: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const weeksA = randomIntegerInclusive(2, 4);
    const weeksB = randomIntegerInclusive(5, 9);
    const weeksC = randomIntegerInclusiveStep(10, 90, 10);

    return { weeksA, weeksB, weeksC };
  },
  Component: ({ question: { weeksA, weeksB, weeksC }, translate }) => {
    // Answers
    const answer1 = weeksA * 7;
    const answer2 = weeksB * 7;
    const answer3 = weeksC * 7;

    // Table data
    const data = [
      [weeksA.toLocaleString(), '<ans/>'],
      [weeksB.toLocaleString(), '<ans/>'],
      [weeksC.toLocaleString(), '<ans/>']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        cellHeaders={[translate.timePeriod.weeks(), translate.timePeriod.days()]}
        tableData={data}
        testCorrect={[answer1.toString(), answer2.toString(), answer3.toString()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'axJ',
  description: 'axJ',
  keywords: ['Weeks', 'Days'],
  schema: z.object({
    daysA: z.number().int().min(7).max(28).multipleOf(7),
    daysB: z.number().int().min(35).max(77).multipleOf(7),
    daysC: z.number().int().min(140).max(770).multipleOf(7).multipleOf(10)
  }),
  simpleGenerator: () => {
    const daysA = randomIntegerInclusiveStep(7, 28, 7);
    const daysB = randomIntegerInclusiveStep(35, 77, 7);
    const daysC = randomIntegerInclusiveStep(140, 770, 10, {
      constraint: x => x % 7 === 0
    });

    return { daysA, daysB, daysC };
  },
  Component: ({ question: { daysA, daysB, daysC }, translate }) => {
    // Answers
    const answer1 = daysA / 7;
    const answer2 = daysB / 7;
    const answer3 = daysC / 7;

    // Table data
    const data = [
      ['<ans/>', daysA.toLocaleString()],
      ['<ans/>', daysB.toLocaleString()],
      ['<ans/>', daysC.toLocaleString()]
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        cellHeaders={[translate.timePeriod.weeks(), translate.timePeriod.days()]}
        tableData={data}
        testCorrect={[answer1.toString(), answer2.toString(), answer3.toString()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'axK',
  description: 'axK',
  keywords: ['Time', 'Months', 'Days', 'Calendar'],
  schema: z.object({
    month: monthSchema,
    selectedDay: daySchema,
    ordinalNumberIndex: numberEnum([1, 2, 3, 4]),
    incorrectOrdinalNumberIndex: numberEnum([1, 2, 3, 4]),
    year: z.number().int().min(2016).max(2023)
  }),
  simpleGenerator: () => {
    const month = getRandomMonth();
    const year = randomIntegerInclusive(2016, 2023);

    const firstDay = getDayNameForGivenDate(new Date(year, monthNames.indexOf(month), 1));

    const [selectedDay] = getRandomSubArrayFromArray(
      dayNames.filter(x => x !== firstDay),
      1
    );

    // First, second, third, fourth
    const [ordinalNumberIndex, incorrectOrdinalNumberIndex] = getRandomSubArrayFromArray(
      [1, 2, 3, 4] as const,
      2
    );

    return {
      month,
      selectedDay,
      ordinalNumberIndex,
      incorrectOrdinalNumberIndex,
      year
    };
  },
  Component: props => {
    const {
      question: { month, selectedDay, ordinalNumberIndex, incorrectOrdinalNumberIndex, year },
      locale,
      translate
    } = props;

    // Correct answer
    const correctAnswer = getSelectedDateFromFirstDateOfDayInMonth(
      year,
      month,
      selectedDay,
      ordinalNumberIndex
    );

    // Incorrect answer
    const incorrectAnswer1 = 1;

    // Incorrect answer
    const incorrectAnswer2 = getSelectedDateFromFirstDateOfDayInMonth(
      year,
      month,
      selectedDay,
      incorrectOrdinalNumberIndex
    );

    const ordinalNumber = getWordOrdinal(ordinalNumberIndex, translate);

    const statements = [
      {
        value: correctAnswer
      },
      {
        value: incorrectAnswer1
      },
      {
        value: incorrectAnswer2
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsACalendarFromMonthYearSelectDateOfOrdinalNumber({
          month,
          year,
          ordinalNumber,
          selectedDay
        })}
        pdfTitle={translate.instructions.hereIsACalendarFromMonthYearSelectDateOfOrdinalNumberPDF({
          month,
          year,
          ordinalNumber,
          selectedDay
        })}
        testCorrect={[correctAnswer]}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => <Calendar year={year} month={month} dimens={dimens} />}
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{getNumeralOrdinal(value, locale, translate)}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'axL',
  description: 'axL',
  keywords: ['Months', 'Years'],
  schema: z.object({
    timesOld: z.number().int().min(2).max(3),
    monthsOldA: z.number().int().min(4).max(7),
    monthsOldB: z.number().int().min(2).max(3),
    yearsOld: z.number().int().min(2).max(5),
    maleNameA: nameSchema,
    maleNameB: nameSchema,
    femaleName: nameSchema
  }),
  simpleGenerator: () => {
    const timesOld = randomIntegerInclusive(2, 3);
    const monthsOldA = randomIntegerInclusive(4, 7);
    const monthsOldB = randomIntegerInclusive(2, 3);
    const yearsOld = randomIntegerInclusive(2, 5);

    const [maleNameA, maleNameB] = getRandomUniqueMaleNames(2);
    const femaleName = getFemaleName();

    return { timesOld, monthsOldA, monthsOldB, yearsOld, maleNameA, maleNameB, femaleName };
  },

  Component: props => {
    const {
      question: { timesOld, monthsOldA, monthsOldB, yearsOld, maleNameA, maleNameB, femaleName },
      translate
    } = props;

    // Answers
    const answer1 = yearsOld * 2 + yearsOld * timesOld + 1;
    const answer2 = monthsOldB + monthsOldB * timesOld + (monthsOldB + monthsOldA) - 12;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.boyAIsXTimesAsOldAsGirlBoyBIsXMonthsOlderThanGirlTheGirlIsXYearsAndYMonthsOld(
          {
            maleNameA,
            maleNameB,
            femaleName,
            monthsOldA,
            monthsOldB,
            timesOld,
            years: yearsOld
          }
        )}
        Content={<Text variant="WRN400">{translate.answerSentences.whatIsTheirCombinedAge()}</Text>}
        testCorrect={[answer1.toString(), answer2.toString()]}
        sentence={translate.answerSentences.ansYearsAnsMonths()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'YearsMonthsWeeksAndDays',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
