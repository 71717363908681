import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { numberEnum } from 'common/src/utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import QF7InteractiveTable from 'common/src/components/question/questionFormats/QF7InteractiveTable';
import {
  compareFractions,
  formatFractionToMarkup,
  fractionToDecimal
} from 'common/src/utils/fractions';
import { all, create, number } from 'mathjs';
import { compareFloats } from 'common/src/utils/math';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { DisplayShapeOnGrid } from 'common/src/components/question/representations/DisplayShapeOnGrid';
import { createHundredSquareShape } from 'common/src/utils/shapes';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered
} from 'common/src/utils/collections';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVY',
  description: 'aVY',
  keywords: ['Hundred square', 'Parts', 'Whole', 'Equivalent', 'Fraction', 'Decimal', 'Percentage'],
  schema: z.object({
    shadedSquares: z.number().int().min(1).max(100)
  }),
  questionHeight: 850,
  simpleGenerator: () => {
    // Get weighted ratio of 7:1
    const ratio = getRandomFromArrayWithWeights([true, false] as const, [7, 1]);

    // Randomisation of 3 different values for shadedSquares
    const shadedSquaresSplit = getRandomFromArray([1, 2, 3] as const);

    let shadedSquares = 1;

    if (ratio) {
      switch (shadedSquaresSplit) {
        case 1:
          shadedSquares = randomIntegerInclusiveStep(10, 90, 5);
          break;
        case 2:
          shadedSquares = randomIntegerInclusive(1, 9);
          break;
        case 3:
          shadedSquares = randomIntegerInclusive(91, 100);
          break;
      }
    } else {
      randomIntegerInclusive(1, 100);
    }

    return { shadedSquares };
  },
  Component: props => {
    const {
      question: { shadedSquares },
      translate
    } = props;

    const shape = createHundredSquareShape(shadedSquares);

    // Answers
    const decimalAns = number(math.evaluate(`${shadedSquares} / 100`));
    const oneHundredAns = 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatFractionDecimalPercentageOfGridIsShaded()}
        questionHeight={850}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="row"
        textStyle={{ fontSize: 28 }}
        sentences={[
          translate.answerSentences.fractionEqualsAns(),
          translate.answerSentences.decimalEqualsAns(),
          `${translate.answerSentences.percentageEqualsAnsPercent()}`
        ]}
        testCorrect={userAnswer => {
          return (
            compareFractions([userAnswer[0][0], userAnswer[0][1]], [shadedSquares, 100]) &&
            compareFloats(userAnswer[1][0], decimalAns) &&
            compareFloats(userAnswer[2][0], shadedSquares)
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [shadedSquares.toLocaleString(), oneHundredAns.toLocaleString()],
            [decimalAns.toLocaleString()],
            [shadedSquares.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVZ',
  description: 'aVZ',
  keywords: ['Hundredths', 'Parts', 'Whole', 'Equivalent', 'Fraction', 'Decimal', 'Percentage'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    number2: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        // Get weighted ratio of 7:1
        const ratio = getRandomFromArrayWithWeights([true, false] as const, [7, 1]);

        const number1 = ratio
          ? getRandomBoolean()
            ? randomIntegerInclusive(10, 100, {
                constraint: x => x % 5 === 0
              })
            : randomIntegerInclusive(1, 9)
          : randomIntegerInclusive(1, 100);

        const number2 = ratio
          ? randomIntegerInclusive(5, 100, {
              constraint: x => x % 5 === 0
            })
          : randomIntegerInclusive(1, 100);

        return {
          number1,
          number2
        };
      },
      // Only permit them if number1 is not equal to number2
      ({ number1, number2 }) => number1 !== number2
    );
    return {
      number1,
      number2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const decimal1 = (number1 / 100).toLocaleString();
    const percentage1 = `${number1.toLocaleString()}%`;

    const decimal2 = (number2 / 100).toLocaleString();
    const percentage2 = `${number2.toLocaleString()}%`;

    const sentences = [
      {
        sentence: `<frac n='${number1.toLocaleString()}' d='${(100).toLocaleString()}' /> = <ans/> = <ans/>`,
        answer: [decimal1, percentage1]
      },
      {
        sentence: `<frac n='${number2.toLocaleString()}' d='${(100).toLocaleString()}' /> = <ans/> = <ans/>`,
        answer: [decimal2, percentage2]
      }
    ];

    const items = shuffle([percentage1, percentage2, decimal1, decimal2], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchEquivalentFractionsDecimalsPercentages()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFractionsDecimalsPercentages()}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(({ sentence }) => sentence)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [decimal1.toLocaleString(), percentage1.toLocaleString()],
            [decimal2.toLocaleString(), percentage2.toLocaleString()]
          ]
        }}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer[0], sentences[0].answer) &&
          arraysHaveSameContentsUnordered(userAnswer[1], sentences[1].answer)
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aV0',
  description: 'aV0',
  keywords: ['Hundredths', 'Parts', 'Whole', 'Equivalent', 'Fraction', 'Decimal', 'Percentage'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int(),
    variants: z.array(numberEnum([1, 2, 3]))
  }),
  simpleGenerator: () => {
    // Get weighted ratio of 7:1
    const ratio = getRandomFromArrayWithWeights([true, false] as const, [7, 1]);

    // If true use number[], else 100
    const number2 = ratio ? getRandomFromArray([2, 4, 5, 10] as const) : 100;
    // If true use (1, 9), else (1, 99)
    const number1 = ratio
      ? randomIntegerInclusive(1, 9, {
          constraint: x => x < number2
        })
      : randomIntegerInclusive(1, 99, {
          constraint: x => x < number2
        });

    // Random to decide between 1 or 2 answer boxes
    const random = getRandomBoolean();
    // 1 represents fraction, 2 decimal, 3 percentage
    const variants = getRandomSubArrayFromArray([1, 2, 3] as const, random ? 1 : 2).sort();

    return { number1, number2, variants };
  },
  Component: ({ question: { number1, number2, variants }, translate, displayMode }) => {
    // Answers
    const decimalAns = number(math.evaluate(`${number1} / ${number2}`));
    const percentageAns = number(math.evaluate(`(${number1} / ${number2}) * 100`));

    // Table structure
    // Check if 1,2,3 and render either answer box or display values
    const dataFraction = variants.includes(1)
      ? `<frac nAns="" dAns="" />`
      : `<frac n="${number1.toLocaleString()}" d="${number2.toLocaleString()}" />`;
    const dataDecimal = variants.includes(2) ? '<ans />' : `${decimalAns}`;
    const dataPercentage = variants.includes(3) ? '<ans />%' : `${percentageAns}%`;

    const data = [[dataFraction, dataDecimal, dataPercentage]];

    const answers: { type: string; values: number[] }[] = [];

    variants.forEach(variant => {
      switch (variant) {
        case 1:
          answers.push({
            type: 'fraction',
            values: [number1, number2]
          });
          break;
        case 2:
          answers.push({
            type: 'decimal',
            values: [decimalAns]
          });
          break;
        case 3:
          answers.push({
            type: 'percentage',
            values: [percentageAns]
          });
          break;
      }
    });

    // Mark scheme
    const markSchemeAnswers: string[] = [];

    answers.map(answer => {
      if (answer.values.length > 1) {
        markSchemeAnswers.push(
          answer.values[0].toLocaleString(),
          answer.values[1].toLocaleString()
        );
      } else {
        markSchemeAnswers.push(answer.values[0].toLocaleString());
      }
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        cellHeaders={[
          translate.tableHeaders.Fraction(),
          translate.tableHeaders.Decimal(),
          translate.tableHeaders.Percentage()
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswers,
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        tableData={data}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => {
          const checkAnswers: boolean[] = [];
          // Set user answer index to 0
          let userAnswerIdx = 0;
          answers.map(answer => {
            if (answer.type === 'fraction') {
              checkAnswers.push(
                compareFractions(
                  [userAnswer[userAnswerIdx], userAnswer[userAnswerIdx + 1]],
                  [answer.values[0], answer.values[1]]
                )
              );

              // If fraction increment by 2
              userAnswerIdx += 2;
            } else if (answer.type === 'decimal') {
              checkAnswers.push(compareFloats(userAnswer[userAnswerIdx], answer.values.toString()));
              // If decimal increment by 1
              userAnswerIdx++;
            } else {
              checkAnswers.push(compareFloats(userAnswer[userAnswerIdx], answer.values.toString()));
              // If percentage increment by 1
              userAnswerIdx++;
            }
          });

          // Filter only false values
          const isAnswersCorrect = checkAnswers.filter(answer => answer === false);

          // If length is >=1 means there is at least 1 false value i.e wrong answer
          // Else if less than 1, correct answer
          return isAnswersCorrect.length >= 1 ? false : true;
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aV1',
  description: 'aV1',
  keywords: ['Hundredths', 'Parts', 'Whole', 'Equivalent', 'Fraction', 'Decimal', 'Percentage'],
  schema: z.object({
    number1: z.number().int().min(1).max(49),
    number2: numberEnum([2, 4, 5, 10, 20, 25, 50]),
    number3: z.number().int().min(1).max(49),
    number4: numberEnum([2, 4, 5, 10, 20, 25, 50])
  }),
  simpleGenerator: () => {
    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const [number2, number4] = getRandomSubArrayFromArray(
          [2, 4, 5, 10, 20, 25, 50] as const,
          2
        );
        const number1 = randomIntegerInclusive(1, 49, {
          constraint: x => x < number2
        });

        const number3 = randomIntegerInclusive(1, 49, {
          constraint: x => x < number4
        });

        return {
          number1,
          number2,
          number3,
          number4
        };
      },
      // Decimals cannot be equal
      ({ number1, number2, number3, number4 }) =>
        arrayHasNoDuplicates([number3 / number4, number1 / number2])
    );
    return {
      number1,
      number2,
      number3,
      number4
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const decimal1 = (number1 / number2).toLocaleString();
    const percentage1 = `${((number1 / number2) * 100).toLocaleString()}%`;

    const decimal2 = (number3 / number4).toLocaleString();
    const percentage2 = `${((number3 / number4) * 100).toLocaleString()}%`;

    const sentences = [
      {
        sentence: `${formatFractionToMarkup(number1, number2, 'fraction')} = <ans/> = <ans/>`,
        answer: [decimal1, percentage1]
      },
      {
        sentence: `${formatFractionToMarkup(number3, number4, 'fraction')} = <ans/> = <ans/>`,
        answer: [decimal2, percentage2]
      }
    ];

    const items = shuffle([percentage1, percentage2, decimal1, decimal2], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchEquivalentFractionsDecimalsPercentages()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFractionsDecimalsPercentages()}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(({ sentence }) => sentence)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [decimal1.toString(), percentage1.toString()],
            [decimal2.toString(), percentage2.toString()]
          ]
        }}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer[0], sentences[0].answer) &&
          arraysHaveSameContentsUnordered(userAnswer[1], sentences[1].answer)
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aV2',
  description: 'aV2',
  keywords: ['Hundredths', 'Parts', 'Whole', 'Equivalent', 'Fraction', 'Decimal', 'Percentage'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(2).max(100),
    number3: z.number().int().min(1).max(99),
    number4: z.number().int().min(2).max(100),
    number5: z.number().int().min(1).max(99),
    number6: z.number().int().min(2).max(100),
    variants: z.array(numberEnum([1, 2, 3]))
  }),
  simpleGenerator: () => {
    const { number1, number2, number3, number4, number5, number6, variants } = rejectionSample(
      () => {
        // Get weighted ratio of 3:1
        const ratio = getRandomFromArrayWithWeights([true, false] as const, [3, 1]);

        // variants of fraction, decimal, percentage
        const variants = getRandomSubArrayFromArray([1, 2, 3] as const, 2);

        const number2 = ratio
          ? getRandomFromArray([5, 10, 20] as const)
          : getRandomFromArray([2, 4, 25, 50, 100] as const);

        const number4 = ratio
          ? getRandomFromArray([5, 10, 20] as const)
          : getRandomFromArray([2, 4, 25, 50, 100] as const);

        const number6 = ratio
          ? getRandomFromArray([5, 10, 20] as const)
          : getRandomFromArray([2, 4, 25, 50, 100] as const);

        const number1 = randomIntegerInclusive(1, number2 - 1);
        const number3 = randomIntegerInclusive(1, number4 - 1);
        const number5 = randomIntegerInclusive(1, number6 - 1);

        return {
          number1,
          number2,
          number3,
          number4,
          number5,
          number6,
          variants
        };
      },
      // Only permit them if fractions are not equal
      // And fraction denominators are not equal
      ({ number1, number2, number3, number4, number5, number6 }) =>
        arrayHasNoDuplicates([number1 / number2, number3 / number4, number5 / number6]) &&
        number2 !== number4 &&
        number2 !== number6 &&
        number4 !== number6
    );
    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      variants
    };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, variants },
      translate,
      displayMode
    } = props;

    const number7 = number(math.evaluate(`${number1} / ${number2}`));
    const number8 = number(math.evaluate(`${number3} / ${number4}`));
    const number9 = number(math.evaluate(`${number5} / ${number6}`));

    const number10 = number(math.evaluate(`${number7} * 100`));
    const number11 = number(math.evaluate(`${number8} * 100`));
    const number12 = number(math.evaluate(`${number9} * 100`));

    const variantData: { title: string; values: number[] }[] = [];

    variants.forEach(variant => {
      switch (variant) {
        case 1:
          variantData.push({
            title: 'Fraction',
            values: [number1, number2]
          });
          break;
        case 2:
          variantData.push({
            title: 'Decimal',
            values: [number7]
          });
          break;
        case 3:
          variantData.push({
            title: 'Percentage',
            values: [number10]
          });
          break;
      }
    });

    const variantA = [1, 2];
    const variantB = [1, 3];

    const firstVariant = arraysHaveSameContents(variants, variantA);
    const secondVariant = arraysHaveSameContents(variants, variantB);

    const items = shuffle(
      [
        {
          sentence: firstVariant
            ? formatFractionToMarkup(number1, number2, 'fraction')
            : secondVariant
            ? formatFractionToMarkup(number1, number2, 'fraction')
            : `${number7.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: firstVariant
            ? `${number7}`
            : secondVariant
            ? `${number10}%`
            : `${number10.toLocaleString()}%`,
          isCorrect: true
        },
        {
          sentence: firstVariant
            ? formatFractionToMarkup(number3, number4, 'fraction')
            : secondVariant
            ? formatFractionToMarkup(number3, number4, 'fraction')
            : `${number8.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: firstVariant
            ? `${number9}`
            : secondVariant
            ? `${number11.toLocaleString()}%`
            : `${number12.toLocaleString()}%`,
          isCorrect: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Titles
    const title = firstVariant
      ? `${translate.instructions.selectTheXAndYEquivalentToZ(
          translate.keywords.Fraction(),
          translate.keywords.Decimal(),
          number10.toLocaleString()
        )}%`
      : secondVariant
      ? translate.instructions.selectTheXAndYEquivalentToZ(
          translate.keywords.Fraction(),
          translate.keywords.Percentage(),
          number7.toLocaleString()
        )
      : translate.instructions.selectTheXAndYEquivalentToZFrac(
          translate.keywords.Decimal(),
          translate.keywords.Percentage(),
          number1.toLocaleString(),
          number2.toLocaleString()
        );

    const pdfTitle = firstVariant
      ? `${translate.instructions.circleTheXAndYEquivalentToZ(
          translate.keywords.Fraction(),
          translate.keywords.Decimal(),
          number10.toLocaleString()
        )}%`
      : secondVariant
      ? translate.instructions.circleTheXAndYEquivalentToZ(
          translate.keywords.Fraction(),
          translate.keywords.Percentage(),
          number7.toLocaleString()
        )
      : translate.instructions.circleTheXAndYEquivalentToZFrac(
          translate.keywords.Decimal(),
          translate.keywords.Percentage(),
          number1.toLocaleString(),
          number2.toLocaleString()
        );

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={items.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={items.map(equation => ({
          component: (
            <TextStructure
              sentence={equation.sentence}
              fractionTextStyle={{ fontWeight: '700' }}
              textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50, fontWeight: '700' }}
            />
          ),
          value: equation.sentence
        }))}
        multiSelect
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aV3',
  description: 'aV3',
  keywords: [
    'Fraction',
    'Percentage',
    'Decimal',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Multiplication',
    'Division',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    numerator: z.number().int().min(1).max(19),
    denominator: numberEnum([2, 4, 5, 10, 20]),
    draggableA: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 4, 5, 10, 20] as const);
    const numerator = randomIntegerInclusive(1, 19, {
      constraint: x => x < denominator
    });
    const draggableA = randomIntegerInclusive(1, 99, {
      // DraggableA cannot be the same as ans2
      constraint: x => x !== (numerator / denominator) * 100
    });

    return { numerator, denominator, draggableA };
  },
  Component: props => {
    const {
      question: { numerator, denominator, draggableA },
      translate
    } = props;

    const decimalAns = number(math.evaluate(`${numerator} / ${denominator}`));
    const percentageAns = number(math.evaluate(`(${numerator} / ${denominator}) * 100`));

    const draggableB = number(math.evaluate(`${numerator} / 100`));

    // Items
    const items = [numerator, denominator, decimalAns, percentageAns, draggableA, draggableB];

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsConvertFracToDecimalAndPercentage(
          numerator,
          denominator
        )}
        pdfTitle={translate.instructions.useCardsConvertFracToDecimalAndPercentage(
          numerator,
          denominator
        )}
        items={items}
        sentence={translate.answerSentences.fracEqualsAnsEqualsAnsPercent(numerator, denominator)}
        testCorrect={[decimalAns, percentageAns]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aV32',
  description: 'aV3',
  keywords: [
    'Fraction',
    'Percentage',
    'Decimal',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Multiplication',
    'Division',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: numberEnum([2, 4, 5, 10, 20]),
    numerator: z.number().int().min(1).max(19),
    valueToShow: z.enum(['percentage', 'decimal', 'fraction'])
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 4, 5, 10, 20] as const);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const valueToShow = getRandomFromArray(['percentage', 'decimal', 'fraction'] as const);

    return { denominator, numerator, valueToShow };
  },
  Component: props => {
    const {
      question: { denominator, numerator, valueToShow },
      translate,
      displayMode
    } = props;

    const percentAns = number(math.evaluate(`${numerator} / ${denominator} * 100`));
    const decimalAns = number(math.evaluate(`${numerator} / ${denominator}`));

    const { sentence, answer } = (() => {
      switch (valueToShow) {
        case 'decimal':
          return {
            sentence: `${decimalAns} = <frac nAns='' dAns=''/> = <ans/>%`,
            answer: [
              numerator.toLocaleString(),
              denominator.toLocaleString(),
              percentAns.toLocaleString()
            ]
          };
        case 'fraction':
          return {
            sentence: `<frac n='${numerator}' d='${denominator}' /> = <ans/> = <ans/>%`,
            answer: [decimalAns.toLocaleString(), percentAns.toLocaleString()]
          };
        default:
          return {
            sentence: `${percentAns}% = <frac nAns='' dAns=''/> = <ans/>`,
            answer: [
              numerator.toLocaleString(),
              denominator.toLocaleString(),
              decimalAns.toLocaleString()
            ]
          };
      }
    })();

    const title =
      valueToShow === 'decimal'
        ? translate.instructions.convertTheXToAYAndAZ(
            translate.keywords.Decimal(),
            decimalAns,
            translate.keywords.Fraction(),
            translate.keywords.Percentage()
          )
        : valueToShow === 'fraction'
        ? translate.instructions.convertTheXToAYAndAZ(
            translate.keywords.Fraction(),
            `<frac n='${numerator}' d='${denominator}' />`,
            translate.keywords.Decimal(),
            translate.keywords.Percentage()
          )
        : translate.instructions.convertTheXToAYAndAZ(
            translate.keywords.Percentage(),
            `${percentAns}%`,
            translate.keywords.Fraction(),
            translate.keywords.Decimal()
          );

    return (
      <QF2AnswerBoxOneSentence
        sentence={sentence}
        extraSymbol="decimalPoint"
        title={title}
        inputMaxCharacters={3}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={userAnswer => {
          if (valueToShow === 'decimal') {
            return (
              fractionToDecimal(Number(userAnswer[0]), Number(userAnswer[1])) === decimalAns &&
              userAnswer[2] === percentAns.toString()
            );
          } else if (valueToShow === 'fraction') {
            return (
              parseFloat(userAnswer[0]) === decimalAns && userAnswer[1] === percentAns.toString()
            );
          }
          return (
            fractionToDecimal(Number(userAnswer[0]), Number(userAnswer[1])) === decimalAns &&
            parseFloat(userAnswer[2]) === decimalAns
          );
        }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: answer,
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsDecimalsAndPercentages',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});

export default SmallStep;
