import { getRandomFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

const schoolSubjects = ['Art', 'English', 'Maths', 'PE', 'Phonics', 'Science'] as const;

export const schoolSubjectsSchema = z.enum(schoolSubjects);

export function getRandomSchoolSubject() {
  return getRandomFromArray(schoolSubjects);
}

export type SchoolSubject = (typeof schoolSubjects)[number];

export const isSchoolSubject = (x: string): x is SchoolSubject =>
  schoolSubjects.includes(x as SchoolSubject);

export const schoolSubjectAsWord = (subject: SchoolSubject, translate: TranslationFunctions) => {
  switch (subject) {
    case 'Art':
      return translate.schoolSubjects.Art();
    case 'English':
      return translate.schoolSubjects.English();
    case 'Maths':
      return translate.schoolSubjects.Maths();
    case 'PE':
      return translate.schoolSubjects.PE();
    case 'Phonics':
      return translate.schoolSubjects.Phonics();
    case 'Science':
      return translate.schoolSubjects.Science();
  }
};

const shortSchoolSubjects = ['Art', 'Music', 'PE', 'Spanish'] as const;

export const shortSchoolSubjectsSchema = z.enum(shortSchoolSubjects);

export function getRandomShortSchoolSubject() {
  return getRandomFromArray(shortSchoolSubjects);
}

export type ShortSchoolSubject = (typeof shortSchoolSubjects)[number];

export const isShortSchoolSubject = (x: string): x is ShortSchoolSubject =>
  shortSchoolSubjects.includes(x as ShortSchoolSubject);

export const shortSchoolSubjectAsWord = (
  subject: ShortSchoolSubject,
  translate: TranslationFunctions
) => {
  switch (subject) {
    case 'Art':
      return translate.schoolSubjects.Art();
    case 'Music':
      return translate.schoolSubjects.Music();
    case 'PE':
      return translate.schoolSubjects.PE();
    case 'Spanish':
      return translate.schoolSubjects.Spanish();
  }
};
