import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { parseSymbolsToString, parseToSUB } from '../../../../utils/parse';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import {
  arraysHaveSameContents,
  countRange,
  nestedArrayHasNoDuplicates,
  sortNumberArray
} from '../../../../utils/collections';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { all, create, isInteger, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import { colors } from '../../../../theme/colors';
import { isValidSquare, isValidTrapezium } from '../../../../utils/shapes';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { GridPolygon } from '../../../../utils/gridUtils';
import { type LocalizedString } from 'typesafe-i18n';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aE8',
  description: 'aE8',
  keywords: ['Coordinate', 'First quadrant', 'Axes'],
  schema: z.object({
    coordinates: z.number().int().min(-4).max(4).array().length(2)
  }),
  simpleGenerator: () => ({
    coordinates: [randomIntegerInclusive(-4, 4), randomIntegerInclusive(-4, 4)]
  }),
  Component: ({ question: { coordinates }, translate, displayMode }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.giveCoordinatesOfPoint()}
        sentence="( <ans/> , <ans/> )"
        inputMaxCharacters={4}
        testCorrect={coordinates.map(coordinate => parseToSUB(coordinate.toString()))}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        extraSymbol="minus"
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={displayMode === 'digital' ? width : width * 1.3}
            height={displayMode === 'digital' ? height : height * 1.3}
            xMin={-4}
            yMin={-4}
            xMax={4}
            yMax={4}
            squareGrid
          >
            <GridImage
              mathCoord={coordinates as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aE9',
  description: 'aE9',
  keywords: ['Axes', 'Coordinate', 'Plot'],
  schema: z.object({
    coordinates: z.tuple([z.number().int().min(-5).max(5), z.number().int().min(-4).max(4)])
  }),
  questionHeight: 800,
  simpleGenerator: () => ({
    coordinates: [randomIntegerInclusive(-5, 5), randomIntegerInclusive(-4, 4)] as [number, number]
  }),
  Component: ({ question: { coordinates }, translate, displayMode, theme }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragTheCircleToThePointXY(
                coordinates[0].toLocaleString(),
                coordinates[1].toLocaleString()
              )
            : translate.instructions.drawACrossToThePointXY(
                coordinates[0].toLocaleString(),
                coordinates[1].toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          return arraysHaveSameContents(
            ans[0].map(el => parseToSUB(el.toString())),
            coordinates.map(coordinate => parseToSUB(coordinate.toString()))
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinates]
        }}
        gridProps={{
          xMin: -5,
          yMin: -4,
          xMax: 5,
          yMax: 4,
          squareGrid: true,
          height: displayMode === 'digital' ? 500 : 800
        }}
        items={[
          {
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aFa',
  description: 'aFa',
  keywords: ['Coordinate', 'Rectangle'],
  schema: z.object({
    coordinates1: z.number().int().min(-4).max(4).array().length(2),
    coordinates2: z.number().int().min(-4).max(4).array().length(2),
    coordinates3: z.number().int().min(-4).max(4).array().length(2),
    coordinates4: z.number().int().min(-4).max(4).array().length(2),
    answerCoordinates: z.number().int().min(0).max(3).array().length(2)
  }),
  simpleGenerator: () => {
    const { coordinates1, coordinates2, coordinates3, coordinates4 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(-4, 4, 2);
        // remove 0 so labels dont interfere with axis
        const yCoords = randomUniqueIntegersInclusive(-4, 4, 2, { constraint: x => x !== 0 });

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        const coordinates1 = [x1, y1];
        const coordinates2 = [x1, y2];
        const coordinates3 = [x2, y2];
        const coordinates4 = [x2, y1];

        return {
          coordinates1,
          coordinates2,
          coordinates3,
          coordinates4
        };
      },
      ({ coordinates1, coordinates2, coordinates3, coordinates4 }) => {
        return (
          isValidSquare(
            coordinates1 as [number, number],
            coordinates2 as [number, number],
            coordinates3 as [number, number],
            coordinates4 as [number, number]
          ) &&
          Math.abs(coordinates1[0] - coordinates3[0]) > 1 &&
          Math.abs(coordinates1[0] - coordinates2[1]) > 1
        );
      }
    );

    const answerCoordinates = randomUniqueIntegersInclusive(0, 3, 2);

    return { coordinates1, coordinates2, coordinates3, coordinates4, answerCoordinates };
  },
  Component: props => {
    const {
      question: { coordinates1, coordinates2, coordinates3, coordinates4, answerCoordinates },
      translate,
      displayMode
    } = props;

    const coords = [coordinates1, coordinates2, coordinates3, coordinates4];
    const x = 0;
    const y = 1;

    const maxY = sortNumberArray(
      coords.map(point => point[y]),
      'descending'
    )[0];

    const letters = [
      translate.letters.A(),
      translate.letters.B(),
      translate.letters.C(),
      translate.letters.D()
    ];

    const yMaxOffset = displayMode === 'digital' ? 25 : 40;
    const yMinOffset = displayMode === 'digital' ? 0 : 20;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.writeTheCoordinatesOfThePoints()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={4} yMax={4} xMin={-4} yMin={-4} squareGrid>
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon points={coords as [number, number][]} showBorder />
                </GridSvgChildren>
                {letters.map((val, i) => (
                  <Text
                    key={i}
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      top:
                        coords[i][y] === maxY
                          ? mathToSvgY(coords[i][y]) - yMaxOffset
                          : mathToSvgY(coords[i][y]) + yMinOffset,
                      left: mathToSvgX(coords[i][x]),
                      fontSize: displayMode === 'digital' ? 21 : 50,
                      lineHeight: 24,
                      color: colors.prussianBlue
                    }}
                  >
                    {val}
                  </Text>
                ))}
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentences={[
          `${letters[answerCoordinates[0]]}  (<ans/>, <ans/>)`,
          `${letters[answerCoordinates[1]]}  (<ans/>, <ans/>)`
        ]}
        extraSymbol="minus"
        inputMaxCharacters={3}
        testCorrect={ans =>
          arraysHaveSameContents(
            ans[0],
            coords[answerCoordinates[0]].map(val => parseToSUB(val.toString()))
          ) &&
          arraysHaveSameContents(
            ans[1],
            coords[answerCoordinates[1]].map(val => parseToSUB(val.toString()))
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            coords[answerCoordinates[0]].map(val => val.toString()),
            coords[answerCoordinates[1]].map(val => val.toString())
          ]
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aFb',
  description: 'aFb',
  keywords: ['Axes', 'Coordinate', 'Plot'],
  schema: z.object({
    coordinates1: z.tuple([z.number().int().min(-5).max(5), z.number().int().min(-4).max(4)]),
    coordinates2: z.tuple([z.number().int().min(-5).max(5), z.number().int().min(-4).max(4)]),
    coordinates3: z.tuple([z.number().int().min(-5).max(5), z.number().int().min(-4).max(4)]),
    coordinates4: z.tuple([z.number().int().min(-5).max(5), z.number().int().min(-4).max(4)])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shape = getRandomFromArray(['trapezium', 'parallelogram', 'kite'] as const);

    const x1 = randomIntegerInclusive(-5, -1);
    const y1 = randomIntegerInclusive(-4, -1);
    const x2 = randomIntegerInclusive(1, 5, {
      constraint: x =>
        // midpoint should be integer if kite
        (shape === 'kite' && Math.abs(x1 + x) % 2 === 0) || shape !== 'kite'
    });
    const y2 = randomIntegerInclusive(1, 4);

    const constraint = (shape: 'trapezium' | 'parallelogram' | 'kite', x: number) => {
      switch (shape) {
        case 'trapezium': {
          return (
            x - x2 >= -5 && x - x2 <= 5 && x + x1 >= -5 && x + x1 <= 5 && Math.abs(x2 - x1) > 2 * x
          );
        }
        case 'parallelogram': {
          return x + x1 >= -5 && x + x1 <= 5 && x + x2 >= -5 && x + x2 <= 5;
        }
        case 'kite': {
          return true;
        }
      }
    };

    const offset = randomIntegerInclusive(-4, 4, {
      constraint: x => constraint(shape, x)
    });

    let coordinates1;
    let coordinates2;
    let coordinates3;
    let coordinates4;

    switch (shape) {
      case 'parallelogram':
        coordinates1 = [x1, y1] as [number, number];
        coordinates2 = [x2, y1] as [number, number];
        coordinates3 = [x1 + offset, y2] as [number, number];
        coordinates4 = [x2 + offset, y2] as [number, number];
        break;
      case 'trapezium':
        coordinates1 = [x1, y1] as [number, number];
        coordinates2 = [x2, y1] as [number, number];
        coordinates3 = [x1 + offset, y2] as [number, number];
        coordinates4 = [x2 - offset, y2] as [number, number];
        break;
      case 'kite': {
        const midpoint = (x1 + x2) / 2;
        const yMid = (y1 + y2) / 2;
        const yMidpoint = randomIntegerInclusive(Math.round(yMid), y2 - 1);
        coordinates1 = [midpoint, y1] as [number, number];
        coordinates2 = [x2, yMidpoint] as [number, number];
        coordinates3 = [midpoint, y2] as [number, number];
        coordinates4 = [x1, yMidpoint] as [number, number];
        break;
      }
    }

    return { coordinates1, coordinates2, coordinates3, coordinates4 };
  },
  Component: ({
    question: { coordinates1, coordinates2, coordinates3, coordinates4 },
    translate,
    displayMode,
    theme
  }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToPlotQuadrilateralVertices(
                coordinates1[0],
                coordinates1[1],
                coordinates2[0],
                coordinates2[1],
                coordinates3[0],
                coordinates3[1],
                coordinates4[0],
                coordinates4[1]
              )
            : translate.instructions.drawCrossesToPlotQuadrilateralVertices(
                coordinates1[0],
                coordinates1[1],
                coordinates2[0],
                coordinates2[1],
                coordinates3[0],
                coordinates3[1],
                coordinates4[0],
                coordinates4[1]
              )
        }
        snapToGrid
        testCorrect={ans =>
          nestedArrayHasNoDuplicates(ans, true) &&
          ans.every(
            coord =>
              arraysHaveSameContents(coord, coordinates1) ||
              arraysHaveSameContents(coord, coordinates2) ||
              arraysHaveSameContents(coord, coordinates3) ||
              arraysHaveSameContents(coord, coordinates4)
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            coordinates1 as [number, number],
            coordinates2 as [number, number],
            coordinates3 as [number, number],
            coordinates4 as [number, number]
          ]
        }}
        gridProps={{
          xMin: -5,
          yMin: -4,
          xMax: 5,
          yMax: 4,
          squareGrid: true,
          height: displayMode === 'digital' ? 500 : 800
        }}
        items={countRange(4).map(() => ({
          component:
            displayMode === 'digital'
              ? 'Coordinates/CirclePointCustomizable'
              : 'Coordinates/CrossPointCustomizable',
          svgProps: { fill: theme.colors.tertiary }
        }))}
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFc',
  description: 'aFc',
  keywords: ['Axes', 'Coordinate', 'Plot'],
  schema: z.object({
    answerShape: z.enum(['trapeziums', 'rectangles', 'parallelograms']),
    indexToHide: numberEnum([0, 1, 2, 3]),
    coordinates: z.array(z.array(z.number().min(-5).max(5)))
  }),
  questionHeight: 750,
  simpleGenerator: () => {
    const answerShape = getRandomFromArray(['trapeziums', 'rectangles', 'parallelograms'] as const);

    const indexToHide = getRandomFromArray([0, 1, 2, 3] as const);

    const { pointXA, pointXB, pointYA, pointYB, pointXC, pointXD, pointXE } = rejectionSample(
      () => {
        const pointXA = randomIntegerInclusive(-5, -1);
        const pointXB = randomIntegerInclusive(1, 5);
        const pointYA = randomIntegerInclusive(-4, -1);
        const pointYB = randomIntegerInclusive(1, 4);
        // helperNumber is used to find x axis for trapeziums and parallelograms.
        const helperNumber = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
        // pointXC generated x axis for 3rd point on both trapeziums and parallelograms
        const pointXC = pointXA + helperNumber;
        // pointXD generated x axis for 4th point on parallelograms only
        const pointXD = pointXB + helperNumber;
        // pointXE generated x axis for 4th point on trapeziums only
        const pointXE = pointXB - helperNumber;

        return { pointXA, pointXB, pointYA, pointYB, helperNumber, pointXC, pointXD, pointXE };
      },
      ({ pointXA, pointXB, pointXC, pointXD, pointXE, helperNumber }) =>
        answerShape === 'parallelograms'
          ? pointXC >= -5 && pointXD <= 5
          : answerShape === 'trapeziums'
          ? // helperNumber equation makes sure that the coordinates are correct for trapeziums
            pointXC >= -5 && pointXE <= 5 && helperNumber * 2 < pointXB - pointXA
          : // If the shape is rectangle we only need pointXA,pointXB,pointYA,pointYB so we don't need any checks
            true
    );

    const coordinates = (() => {
      switch (answerShape) {
        case 'rectangles':
          return [
            [pointXA, pointYA],
            [pointXB, pointYA],
            [pointXA, pointYB],
            [pointXB, pointYB]
          ];
        case 'trapeziums':
          return [
            [pointXA, pointYA],
            [pointXB, pointYA],
            [pointXE, pointYB],
            [pointXC, pointYB]
          ];
        default:
          return [
            [pointXA, pointYA],
            [pointXB, pointYA],
            [pointXC, pointYB],
            [pointXD, pointYB]
          ];
      }
    })();

    return { coordinates, answerShape, indexToHide };
  },
  Component: ({
    question: { coordinates, answerShape, indexToHide },
    translate,
    displayMode,
    theme
  }) => {
    const correctAnswer = coordinates[indexToHide] as [number, number];

    const translateString =
      answerShape === 'trapeziums'
        ? 'aTrapezium'
        : answerShape === 'parallelograms'
        ? 'aParallelogram'
        : 'aRectangle';

    const shapeString = translate.shapes[translateString]();

    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(1, shapeString)
            : translate.instructions.drawCrossToCompleteVerticesOfShape(shapeString)
        }
        testCorrect={ans =>
          answerShape === 'parallelograms' || answerShape === 'rectangles'
            ? arraysHaveSameContents(ans[0], correctAnswer)
            : isValidTrapezium(
                coordinates.map(val => val as [number, number]),
                indexToHide,
                ans[0],
                false
              )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer],
          answerText: translate.markScheme.acceptAnyCoordinateThatMakeAShape(
            translate.shapes[answerShape](1)
          )
        }}
        gridProps={{
          xMin: -5,
          yMin: -4,
          xMax: 5,
          yMax: 4,
          squareGrid: true,
          height: displayMode === 'digital' ? 500 : 750
        }}
        snapToGrid
        gridChildren={coordinates
          .map((val, i) => (
            <GridImage
              key={i}
              mathCoord={val as [number, number]}
              item={{
                component:
                  displayMode === 'digital'
                    ? 'Coordinates/CirclePointCustomizable'
                    : 'Coordinates/CrossPointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          ))
          .filter((_, index) => index !== indexToHide)}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aFd',
  description: 'aFd',
  keywords: ['Axes', 'Coordinate'],
  schema: z.object({
    answerShape: z.enum(['kite', 'trapezium', 'rectangle', 'parallelogram']),
    coordinates: z.array(z.array(z.string()))
  }),
  simpleGenerator: () => {
    const answerShape = getRandomFromArray([
      'kite',
      'trapezium',
      'rectangle',
      'parallelogram'
    ] as const);

    const { pointXA, pointXB, pointXC, pointXD, pointYA, pointYB, pointYC, pointXE, pointXF } =
      rejectionSample(
        () => {
          const pointXA = randomIntegerInclusive(-5, -1);
          const pointXB = randomIntegerInclusive(1, 5);
          const pointYA = randomIntegerInclusive(-4, -1);
          const pointYB = randomIntegerInclusive(1, 4);
          // helperNumber is used to find x axis for trapeziums and parallelograms.
          const helperNumber = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
          const pointXC = number(math.evaluate(`(${pointXB} + ${pointXA}) / 2`));
          // pointXC generated x axis for 3rd point on the kite
          const pointYC = number(math.evaluate(`(${pointYB} + ${pointYA}) / 2`));
          // pointYC generated y axis for 4rd point on the kite
          const pointXD = number(math.evaluate(`${pointXA} + ${helperNumber}`));
          // pointXD generated x axis for 3rd point on both trapeziums and parallelograms
          const pointXE = number(math.evaluate(`${pointXB} + ${pointYA}`));
          // pointXE generated x axis for 4th point on parallelograms
          const pointXF = number(math.evaluate(`${pointXB} - ${pointYA}`));
          // pointXF generated x axis for 4th point on trapeziums
          return {
            pointXA,
            pointXB,
            pointXC,
            pointXD,
            pointYA,
            pointYB,
            pointYC,
            pointXE,
            pointXF,
            helperNumber
          };
        },
        ({ pointXA, pointXB, pointXC, pointYA, pointYB, pointYC, helperNumber }) =>
          answerShape === 'kite'
            ? isInteger(pointXC) && pointYC > pointYA && pointYC < pointYB
            : answerShape === 'trapezium'
            ? // helperNumber equation makes sure that the coordinates are correct for trapeziums
              helperNumber * 2 < pointXB - pointXA
            : // If the shape is rectangle or parallelogram we dont need no checks
              true
      );

    const coordinates = (() => {
      switch (answerShape) {
        case 'kite': {
          return [
            [pointXC.toLocaleString(), pointYA.toLocaleString()],
            [pointXB.toLocaleString(), pointYC.toLocaleString()],
            [pointXC.toLocaleString(), pointYB.toLocaleString()],
            [pointXA.toLocaleString(), pointYC.toLocaleString()]
          ];
        }

        case 'parallelogram': {
          return [
            [pointXA.toLocaleString(), pointYA.toLocaleString()],
            [pointXB.toLocaleString(), pointYA.toLocaleString()],
            [pointXD.toLocaleString(), pointYB.toLocaleString()],
            [pointXE.toLocaleString(), pointYB.toLocaleString()]
          ];
        }

        case 'trapezium': {
          return [
            [pointXA.toLocaleString(), pointYA.toLocaleString()],
            [pointXB.toLocaleString(), pointYA.toLocaleString()],
            [pointXD.toLocaleString(), pointYB.toLocaleString()],
            [pointXF.toLocaleString(), pointYB.toLocaleString()]
          ];
        }

        default:
          return [
            [pointXA.toLocaleString(), pointYA.toLocaleString()],
            [pointXB.toLocaleString(), pointYA.toLocaleString()],
            [pointXA.toLocaleString(), pointYB.toLocaleString()],
            [pointXB.toLocaleString(), pointYB.toLocaleString()]
          ];
      }
    })();

    return { coordinates, answerShape };
  },
  Component: ({ question: { coordinates, answerShape }, translate }) => {
    const items: { value: typeof answerShape; localizedName: LocalizedString }[] = [
      { value: 'kite', localizedName: translate.shapes.kites(1) },
      { value: 'trapezium', localizedName: translate.shapes.trapeziums(1) },
      { value: 'rectangle', localizedName: translate.shapes.rectangles(1) },
      { value: 'parallelogram', localizedName: translate.shapes.parallelograms(1) }
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.AQuadrilateralHasVerticesAt()}
        numItems={4}
        testCorrect={[answerShape]}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-around' }}>
            <Text variant="WRN700" style={{ fontSize: 40, alignSelf: 'center' }}>
              {`(${coordinates[0][0]}, ${coordinates[0][1]}), (${coordinates[1][0]}, ${coordinates[1][1]}), (${coordinates[2][0]}, ${coordinates[2][1]}), (${coordinates[3][0]}, ${coordinates[3][1]})`}
            </Text>
            <Text style={{ fontSize: 32, alignSelf: 'flex-start' }}>
              {translate.answerSentences.whatIsTheMathematicaNameOfTheQuadrilateral()}
            </Text>
          </View>
        )}
        renderItems={items.map(item => ({
          value: item.value,
          component: (
            <Text variant="WRN700" style={{ fontSize: 32 }}>
              {item.localizedName}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question6V2 = newQuestionContent({
  uid: 'aFd2',
  description: 'aFd2',
  keywords: ['Coordinate'],
  schema: z.object({
    quadrant: z.enum(['P', 'Q', 'R', 'S'])
  }),
  simpleGenerator: () => {
    const quadrant = getRandomFromArray(['P', 'Q', 'R', 'S'] as const);

    return { quadrant };
  },
  Component: ({ question: { quadrant }, translate, displayMode }) => {
    const testCorrect = (userAnswer: number[]) => {
      const x = 0;
      const y = 1;
      switch (quadrant) {
        case 'P':
          return userAnswer[x] >= 1 && userAnswer[y] >= 1;
        case 'Q':
          return userAnswer[x] <= -1 && userAnswer[y] >= 1;
        case 'R':
          return userAnswer[x] <= -1 && userAnswer[y] <= -1;
        case 'S':
          return userAnswer[x] >= 1 && userAnswer[y] <= -1;
      }
    };

    const textPositions = [
      { x: 3, y: 4, label: translate.letters.P() },
      { x: -4, y: 4, label: translate.letters.Q() },
      { x: -4, y: -3, label: translate.letters.R() },
      { x: 3, y: -3, label: translate.letters.S() }
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreCoordinatesOfAnyPointInQuadX(
          translate.letters[quadrant]()
        )}
        sentence="( <ans/> , <ans/> )"
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          testCorrect(userAnswer.map(val => Number(parseSymbolsToString(val))))
        }
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        extraSymbol="minus"
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidCoordinate() }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMin={-5}
            yMin={-5}
            xMax={5}
            yMax={5}
            squareGrid
            hideGridLines
            xLabels={null}
            yLabels={null}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                {textPositions.map((val, i) => (
                  <Text
                    key={i}
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      top: mathToSvgY(val.y),
                      left: mathToSvgX(val.x),
                      fontSize: displayMode === 'digital' ? 40 : 50,
                      lineHeight: 24,
                      color: colors.prussianBlue
                    }}
                  >
                    {val.label}
                  </Text>
                ))}
              </>
            )}
          </Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndPlotPointsInFourQuadrants',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6V2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
