// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MeasureInMetresAndCentimetres from './1MeasureInMetresAndCentimetres';
import MeasureInMillimetres from './2MeasureInMillimetres';
import MeasureInCentimetresAndMillimetres from './3MeasureInCentimetresAndMillimetres';
import MetresCentimetresAndMillimetres from './4MetresCentimetresAndMillimetres';
import EquivalentLengthsMetresAndCentimetres from './5EquivalentLengthsMetresAndCentimetres';
import EquivalentLengthsCentimetresAndMillimetres from './6EquivalentLengthsCentimetresAndMillimetres';
import CompareLengths from './7CompareLengths';
import AddLengths from './8AddLengths';
import SubtractLengths from './9SubtractLengths';
import WhatIsPerimeter from './10WhatIsPerimeter';
import MeasurePerimeter from './11MeasurePerimeter';
import CalculatePerimeter from './12CalculatePerimeter';

const Block = newBlockContent({
  block: 'LengthAndPerimeter',
  field: 'Measurement',
  weeks: [4, 6],
  smallSteps: [
    MeasureInMetresAndCentimetres, // Step 1
    MeasureInMillimetres, // Step 2
    MeasureInCentimetresAndMillimetres, // Step 3
    MetresCentimetresAndMillimetres, // Step 4
    EquivalentLengthsMetresAndCentimetres, // Step 5
    EquivalentLengthsCentimetresAndMillimetres, // Step 6
    CompareLengths, // Step 7
    AddLengths, // Step 8
    SubtractLengths, // Step 9
    WhatIsPerimeter, // Step 10
    MeasurePerimeter, // Step 11
    CalculatePerimeter // Step 12
  ]
});
export default Block;
