import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom
} from 'common/src/utils/random';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { numberEnum } from '../../../../utils/zod';
import { arrayObjectAsWord } from '../../../../utils/arrayObjects';
import { AssetSvg } from '../../../../assets/svg';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors, barModelColorsArray, colors } from '../../../../theme/colors';
import { range } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { DIV, MULT } from '../../../../constants';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGo',
  description: 'aGo',
  keywords: ['Scaling', 'Multiplication', 'Division'],
  schema: z
    .object({
      fruitA: z.enum(['Green apple', 'Banana', 'Orange', 'Pear']),
      fruitAAmount: z.number().int().min(1).max(5),
      fruitB: z.enum(['Green apple', 'Banana', 'Orange', 'Pear']),
      fruitBScale: numberEnum([2, 3, 4, 5, 8, 10])
    })
    .refine(val => val.fruitA !== val.fruitB, 'fruitA and fruitB must be different.'),
  simpleGenerator: () => {
    const [fruitA, fruitB] = getRandomSubArrayFromArray(
      ['Green apple', 'Banana', 'Orange', 'Pear'] as const,
      2
    );

    const fruitBScale = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    const fruitAAmount = randomIntegerInclusive(1, 5, {
      constraint: x => x * fruitBScale <= 12
    });

    return { fruitA, fruitAAmount, fruitB, fruitBScale };
  },
  Component: props => {
    const {
      question: { fruitA, fruitAAmount, fruitB, fruitBScale },
      translate
    } = props;

    const fruitAStringSingular = arrayObjectAsWord(fruitA, translate, false);
    const fruitAStringPlural = arrayObjectAsWord(fruitA, translate, true);

    const fruitBString = arrayObjectAsWord(fruitB, translate, true);

    return (
      <QF1ContentAndSentences
        sentences={[
          fruitAAmount > 1
            ? translate.answerSentences.thereAreAnsXAndAnsY(fruitAStringPlural, fruitBString)
            : translate.answerSentences.thereIsAnsXAndAnsY(fruitAStringSingular, fruitBString),
          translate.answerSentences.thereAreAnsTimesAsManyXAsY(fruitBString, fruitAStringPlural)
        ]}
        title={translate.instructions.completeSentencesToDescribeFruit()}
        testCorrect={[
          [fruitAAmount.toString(), (fruitAAmount * fruitBScale).toString()],
          [fruitBScale.toString()]
        ]}
        pdfDirection="column"
        Content={({ dimens }) => {
          const counterSize = Math.min(
            dimens.width / (fruitAAmount * fruitBScale),
            dimens.height * 0.4
          );

          return (
            <>
              <ArrayOfObjects
                rows={1}
                columns={fruitAAmount}
                dimens={{ width: dimens.width, height: dimens.height / 2 }}
                image={fruitA}
                counterSize={counterSize}
                rowStyle={{ alignSelf: 'flex-start' }}
              />
              <ArrayOfObjects
                rows={1}
                columns={fruitAAmount * fruitBScale}
                dimens={{ width: dimens.width, height: dimens.height / 2 }}
                image={fruitB}
                counterSize={counterSize}
                rowStyle={{ alignSelf: 'flex-start' }}
              />
            </>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGp',
  description: 'aGp',
  keywords: ['Scaling', 'Multiplication', 'Division'],
  schema: z.object({
    redRibbonLength: numberEnum([2, 3, 4, 5, 8, 10]),
    greenRibbonScale: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const redRibbonLength = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    const greenRibbonScale = randomIntegerInclusive(1, 12, {
      constraint: x => x * redRibbonLength >= 12 && x * redRibbonLength <= 48
    });

    return { redRibbonLength, greenRibbonScale };
  },
  Component: props => {
    const {
      question: { redRibbonLength, greenRibbonScale },
      translate
    } = props;

    const greenRibbonLength = redRibbonLength * greenRibbonScale;

    // Constraint to prevent the red ribbon from displaying less than one eighth the width of the green ribbon:
    const redRibbonLengthScale = greenRibbonScale > 8 ? 8 : greenRibbonScale;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.theGreenRibbonIsAnsTimesAsLongAsTheRedRibbon()}
        title={translate.instructions.completeSentencesToDescribeRibbons()}
        testCorrect={[greenRibbonScale.toString()]}
        Content={({ dimens }) => (
          <>
            <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start' }}>
              <AssetSvg name={'RibbonRed'} width={(dimens.width - 100) / redRibbonLengthScale} />
              <ContentBox containerStyle={{ display: 'flex', alignSelf: 'center', marginLeft: 8 }}>
                <Text style={{ fontSize: 28, width: 80, textAlign: 'center' }}>
                  {translate.units.numberOfCm(redRibbonLength)}
                </Text>
              </ContentBox>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-start' }}>
              <AssetSvg name={'RibbonGreen'} width={dimens.width - 100} />
              <ContentBox containerStyle={{ display: 'flex', alignSelf: 'center', marginLeft: 8 }}>
                <Text style={{ fontSize: 28, width: 80, textAlign: 'center' }}>
                  {translate.units.numberOfCm(greenRibbonLength)}
                </Text>
              </ContentBox>
            </View>
          </>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGq',
  description: 'aGq',
  keywords: ['Scaling', 'Multiplication', 'Division'],
  schema: z.object({
    barA: numberEnum([2, 3, 4, 5, 8, 10]),
    barBScale: z.number().int().min(5).max(12)
  }),
  simpleGenerator: () => {
    const barA = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    const barBScale = randomIntegerInclusive(5, 12, {
      constraint: x => x * barA >= 24 && x * barA <= 96
    });

    return { barA, barBScale };
  },
  Component: props => {
    const {
      question: { barA, barBScale },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.barBIsAnsTimesTheSizeOfTheBarA()}
        title={translate.instructions.completeSentenceToDescribeBarModels()}
        testCorrect={[barBScale.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}>
              <Text variant="WRN400">{translate.letters.A()}</Text>
              <BarModel
                numbers={[[barA]]}
                total={barA * barBScale}
                dimens={{
                  width: dimens.width * 0.95,
                  height: dimens.height / 2
                }}
                maxFontSize={56}
                rowColors={[colors.acidGreen]}
              />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}>
              <Text variant="WRN400">{translate.letters.B()}</Text>
              <BarModel
                numbers={[[barA * barBScale]]}
                total={barA * barBScale}
                dimens={{
                  width: dimens.width * 0.95,
                  height: dimens.height / 2
                }}
                maxFontSize={56}
                rowColors={[colors.dangerLight]}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aGr',
  description: 'aGr',
  keywords: ['Scaling', 'Bar model', 'Multiplication'],
  schema: z.object({
    barA: z.number().int().min(2).max(5),
    barBScale: z.number().int().min(2).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const barA = randomIntegerInclusive(2, 5);

    const barBScale = randomIntegerInclusive(2, 5);

    return { barA, barBScale };
  },
  Component: props => {
    const {
      question: { barA, barBScale },
      translate,
      displayMode
    } = props;

    const barBNumbers = range(1, barBScale).map(() => barA);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreNumGreenBalloons(barA, barBScale)}
        testCorrect={[(barA * barBScale).toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ width: dimens.width / 8, alignItems: 'flex-end', marginRight: 16 }}>
                <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 44 }}>
                  {translate.colors.Green()}
                </Text>
              </View>
              <BarModel
                numbers={[[barA]]}
                total={barA * barBScale}
                dimens={{
                  width: (dimens.width * 7) / 8,
                  height: dimens.height / 2
                }}
                rowColors={[colors.seaGreenCrayola]}
              />
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ width: dimens.width / 8, alignItems: 'flex-end', marginRight: 16 }}>
                <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 44 }}>
                  {translate.colors.Yellow()}
                </Text>
              </View>
              <BarModel
                numbers={[barBNumbers]}
                total={barA * barBScale}
                dimens={{
                  width: (dimens.width * 7) / 8,
                  height: dimens.height / 2
                }}
                rowColors={[colors.yellow]}
              />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.ansYellowBalloons()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGs',
  description: 'aGs',
  keywords: ['Scaling', 'Multiplication', 'Division'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 5, 8, 10]),
    number2: z.number().int().min(5).max(12),
    lineAAnswerPosition: z.enum(['left', 'middle', 'right']),
    lineBAnswerPosition: z.enum(['left', 'middle', 'right']),
    lineCAnswerPosition: z.enum(['left', 'middle', 'right']),
    lineDAnswerPosition: z.enum(['left', 'middle', 'right'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    const number2 = randomIntegerInclusive(5, 12, {
      constraint: x => x * number1 >= 12 && x * number1 <= 50
    });

    const [lineAAnswerPosition, lineBAnswerPosition, lineCAnswerPosition, lineDAnswerPosition] =
      range(1, 4).map(() => getRandomFromArray(['left', 'middle', 'right'] as const));

    return {
      number1,
      number2,
      lineAAnswerPosition,
      lineBAnswerPosition,
      lineCAnswerPosition,
      lineDAnswerPosition
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        lineAAnswerPosition,
        lineBAnswerPosition,
        lineCAnswerPosition,
        lineDAnswerPosition
      },
      translate,
      displayMode
    } = props;

    const number3 = number1 * number2;

    const sentenceA = translate.answerSentences.xIsEqualToYLotsOfZ(
      lineAAnswerPosition === 'left' ? '<ans/>' : number3.toLocaleString(),
      lineAAnswerPosition === 'middle' ? '<ans/>' : number2.toLocaleString(),
      lineAAnswerPosition === 'right' ? '<ans/>' : number1.toLocaleString()
    );

    const sentenceB = `${
      lineBAnswerPosition === 'left' ? '<ans/>' : number2.toLocaleString()
    } ${MULT} ${lineBAnswerPosition === 'middle' ? '<ans/>' : number1.toLocaleString()} = ${
      lineBAnswerPosition === 'right' ? '<ans/>' : number3.toLocaleString()
    }`;

    const sentenceC = translate.answerSentences.thereAreXLotsOfYInZ(
      lineCAnswerPosition === 'left' ? '<ans/>' : number2.toLocaleString(),
      lineCAnswerPosition === 'middle' ? '<ans/>' : number1.toLocaleString(),
      lineCAnswerPosition === 'right' ? '<ans/>' : number3.toLocaleString()
    );

    const sentenceD = `${
      lineDAnswerPosition === 'left' ? '<ans/>' : number3.toLocaleString()
    } ${DIV} ${lineDAnswerPosition === 'middle' ? '<ans/>' : number1.toLocaleString()} = ${
      lineDAnswerPosition === 'right' ? '<ans/>' : number2.toLocaleString()
    }`;

    const answerA = (
      lineAAnswerPosition === 'left'
        ? number3
        : lineAAnswerPosition === 'middle'
        ? number2
        : number1
    ).toString();

    const answerB = (
      lineBAnswerPosition === 'left'
        ? number2
        : lineBAnswerPosition === 'middle'
        ? number1
        : number3
    ).toString();

    const answerC = (
      lineCAnswerPosition === 'left'
        ? number2
        : lineCAnswerPosition === 'middle'
        ? number1
        : number3
    ).toString();

    const answerD = (
      lineDAnswerPosition === 'left'
        ? number3
        : lineDAnswerPosition === 'middle'
        ? number1
        : number2
    ).toString();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[answerA], [answerB], [answerC], [answerD]]}
        sentences={[sentenceA, sentenceB, sentenceC, sentenceD]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        containerStyle={{ rowGap: 12 }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGt',
  description: 'aGt',
  keywords: ['Scaling', 'Multiplication', 'Division'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      trueOrFalse: z.boolean(),
      fruitA: z.enum(['apples', 'bananas', 'oranges', 'pears', 'pineapples', 'strawberries']),
      fruitB: z.enum(['apples', 'bananas', 'oranges', 'pears', 'pineapples', 'strawberries'])
    })
    .refine(val => val.fruitA !== val.fruitB, 'fruitA cannot be the same as fruitB.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);

    const trueOrFalse = getRandomBoolean();

    const [fruitA, fruitB] = getRandomSubArrayFromArray(
      ['apples', 'bananas', 'oranges', 'pears', 'pineapples', 'strawberries'] as const,
      2
    );

    return {
      number1,
      trueOrFalse,
      fruitA,
      fruitB
    };
  },
  Component: props => {
    const {
      question: { number1, trueOrFalse, fruitA, fruitB },
      translate,
      displayMode
    } = props;

    const fruitAString = (() => {
      switch (fruitA) {
        case 'apples':
          return translate.objects.Apples();
        case 'bananas':
          return translate.objects.Bananas();
        case 'oranges':
          return translate.objects.Oranges();
        case 'pears':
          return translate.objects.Pears();
        case 'pineapples':
          return translate.objects.Pineapples();
        case 'strawberries':
          return translate.objects.Strawberries();
      }
    })();

    const fruitBString = (() => {
      switch (fruitB) {
        case 'apples':
          return translate.objects.Apples();
        case 'bananas':
          return translate.objects.Bananas();
        case 'oranges':
          return translate.objects.Oranges();
        case 'pears':
          return translate.objects.Pears();
        case 'pineapples':
          return translate.objects.Pineapples();
        case 'strawberries':
          return translate.objects.Strawberries();
      }
    })();

    const number2 = trueOrFalse ? number1 : number1 - 1;

    const number1AsOnes = range(1, number1).map(() => 1);

    const number1AsOnesStrings = range(1, number1).map(() => '');

    const [colorA, colorB] = getRandomSubArrayFromArray(barModelColorsArray, 2, {
      random: seededRandom(props.question)
    });

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.isStatementTrueOrFalse()}<br/>${translate.instructions.selectYourAnswer()}`}
        pdfTitle={translate.instructions.isStatementTrueOrFalsePDF()}
        correctAnswer={trueOrFalse}
        content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 44 }}>
              {translate.answerSentences.thereAreXTimesAsManyYThanZ(number2, fruitB, fruitA)}
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ width: dimens.width / 4, alignItems: 'flex-end', marginRight: 16 }}>
                <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 44 }}>
                  {fruitAString}
                </Text>
              </View>
              <BarModel
                numbers={[[1]]}
                strings={[['']]}
                total={number1}
                dimens={{
                  width: (dimens.width * 3) / 4,
                  height: dimens.height / 2
                }}
                rowColors={[barModelColors[colorA]]}
              />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ width: dimens.width / 4, alignItems: 'flex-end', marginRight: 16 }}>
                <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 44 }}>
                  {fruitBString}
                </Text>
              </View>
              <BarModel
                numbers={[number1AsOnes]}
                strings={[number1AsOnesStrings]}
                total={number1}
                dimens={{
                  width: (dimens.width * 3) / 4,
                  height: dimens.height / 2
                }}
                rowColors={[barModelColors[colorB]]}
              />
            </View>
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Scaling',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
