import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  sortNumberArray
} from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { Line } from 'react-native-svg';
import { parseSymbolsToString, parseToSUB } from '../../../../utils/parse';
import { GridLine, GridPolygon, GridText } from 'common/src/utils/gridUtils';
import { isInRange } from '../../../../utils/matchers';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { isValidRectangle } from '../../../../utils/shapes';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFe',
  description: 'aFe',
  keywords: ['Coordinate', 'Straight line'],
  schema: z.object({
    points: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(3),
    isCorrect: z.boolean(),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const isCorrect = getRandomBoolean();

    const point1 = { x: randomIntegerInclusive(-5, 5), y: randomIntegerInclusive(-4, 4) };

    const point2 = {
      x: isHorizontal
        ? randomIntegerInclusive(-5, 5, { constraint: x => x !== point1.x })
        : point1.x,
      y: isHorizontal
        ? point1.y
        : randomIntegerInclusive(-4, 4, { constraint: y => y !== point1.y })
    };

    let point3;

    if (isCorrect) {
      if (isHorizontal) {
        point3 = {
          x: randomIntegerInclusive(-5, 5, { constraint: x => x !== point1.x && x !== point2.x }),
          y: point1.y
        };
      } else {
        point3 = {
          x: point1.x,
          y: randomIntegerInclusive(-4, 4, { constraint: y => y !== point1.y && y !== point2.y })
        };
      }
    } else {
      if (isHorizontal) {
        point3 = {
          x: randomIntegerInclusive(-5, 5, { constraint: x => x !== point1.x && x !== point2.x }),
          y: randomIntegerInclusive(Math.max(point1.y - 1, -4), Math.min(point1.y + 1, 4), {
            constraint: y => y !== point1.y
          })
        };
      } else {
        point3 = {
          x: randomIntegerInclusive(Math.max(point1.x - 1, -5), Math.min(point1.x + 1, 5), {
            constraint: x => x !== point1.x
          }),
          y: randomIntegerInclusive(-4, 4, { constraint: y => y !== point1.y && y !== point2.y })
        };
      }
    }

    const points = [point1, point2, point3];

    return { points, isCorrect, isHorizontal };
  },
  Component: ({ question, translate, displayMode }) => {
    const { points, isCorrect, isHorizontal } = question;
    const linePoints: [number, number][] = isHorizontal
      ? [
          [-5.5, points[0].y],
          [5.5, points[0].y]
        ]
      : [
          [points[0].x, 4.5],
          [points[0].x, -4.5]
        ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.hereIsStraightLineWithXAndYIsZOnTheLine(
          translate.answerSentences.xYCoordinate(points[0].x, points[0].y),
          translate.answerSentences.xYCoordinate(points[1].x, points[1].y),
          translate.answerSentences.xYCoordinate(points[2].x, points[2].y)
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMin={-5}
                xMax={5}
                yMin={-4}
                yMax={4}
                squareGrid
              >
                <GridSvgChildren>
                  <GridLine points={linePoints} color="red" />
                </GridSvgChildren>
                <GridImage
                  mathCoord={[points[0].x, points[0].y] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: {
                      fill: colors.pacificBlue
                    },
                    width: displayMode !== 'digital' ? 70 : undefined,
                    height: displayMode !== 'digital' ? 70 : undefined
                  }}
                />
                <GridImage
                  mathCoord={[points[1].x, points[1].y] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: {
                      fill: colors.pacificBlue
                    },
                    width: displayMode !== 'digital' ? 70 : undefined,
                    height: displayMode !== 'digital' ? 70 : undefined
                  }}
                />
              </Grid>
            )}
          </MeasureView>
        }
        correctAnswer={[isCorrect ? 'yes' : 'no']}
        selectables={{ yes: translate.misc.Yes(), no: translate.misc.No() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aFe2',
  description: 'aFe',
  keywords: ['Coordinate', 'Straight line'],
  schema: z.object({
    points: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(2),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();

    const point1 = { x: randomIntegerInclusive(-5, 5), y: randomIntegerInclusive(-4, 4) };

    const point2 = {
      x: isHorizontal
        ? randomIntegerInclusive(-5, 5, { constraint: x => x !== point1.x })
        : point1.x,
      y: isHorizontal
        ? point1.y
        : randomIntegerInclusive(-4, 4, { constraint: y => y !== point1.y })
    };

    const points = [point1, point2];

    return { points, isHorizontal };
  },
  Component: ({ question, translate, displayMode }) => {
    const { points, isHorizontal } = question;
    const linePoints: [number, number][] = isHorizontal
      ? [
          [-5.5, points[0].y],
          [5.5, points[0].y]
        ]
      : [
          [points[0].x, 4.5],
          [points[0].x, -4.5]
        ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.aStraightLineGoesThroughXAndWhatAreCoordsOfAnotherPoint(
          translate.answerSentences.xYCoordinate(points[0].x, points[0].y),
          translate.answerSentences.xYCoordinate(points[1].x, points[1].y)
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height}
            xMin={-5}
            xMax={5}
            yMin={-4}
            yMax={4}
            squareGrid
            hideGridLines
          >
            <GridSvgChildren>
              <GridLine points={linePoints} color="red" />
            </GridSvgChildren>
            <GridImage
              mathCoord={[points[0].x, points[0].y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
            />
            <GridImage
              mathCoord={[points[1].x, points[1].y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
            />
          </Grid>
        )}
        testCorrect={userAnswer => {
          const point1x = parseToSUB(points[0].x.toString());
          const point1y = parseToSUB(points[0].y.toString());

          const point2x = parseToSUB(points[1].x.toString());
          const point2y = parseToSUB(points[1].y.toString());

          return isHorizontal
            ? userAnswer[1] === point1y && userAnswer[0] !== point1x && userAnswer[0] !== point2x
            : userAnswer[0] === point1x && userAnswer[1] !== point1y && userAnswer[1] !== point2y;
        }}
        sentence={'(<ans/>, <ans/>)'}
        questionHeight={1200}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: isHorizontal
            ? [(6).toLocaleString(), parseToSUB(points[0].y.toLocaleString())]
            : [parseToSUB(points[0].x.toLocaleString()), (6).toLocaleString()],
          answerText: isHorizontal
            ? translate.markScheme.acceptAnyCoordinateThatSatisfiesYEqualsN(
                parseToSUB(points[0].y.toLocaleString())
              )
            : translate.markScheme.acceptAnyCoordinateThatSatisfiesXEqualsN(
                parseToSUB(points[0].x.toLocaleString())
              )
        }}
        extraSymbol="minus"
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aFf',
  description: 'aFf',
  keywords: ['Coordinate'],
  questionHeight: 1400,
  schema: z.object({
    gradient: z.number().int().min(-4).max(4),
    intercept: z.number().int().min(-4).max(4),
    points: z.array(
      z.object({ x: z.number().int().min(-4).max(4), y: z.number().int().min(-4).max(4) })
    )
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const gradient = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });
        const intercept = randomIntegerInclusive(-4, 4);

        const points: { x: number; y: number }[] = [];
        countRange(9, -4).forEach(x => {
          const y = gradient * x + intercept;
          if (y % 1 === 0 && y >= -4 && y <= 4) points.push({ x, y });
        });

        return { gradient, intercept, points };
      },
      val => val.points.length >= 3
    ),
  Component: ({ question, translate, displayMode }) => {
    const { gradient, intercept, points } = question;
    const { x: x1, y: y1 } = points[0];
    const { x: x2, y: y2 } = points[1];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereAreTwoPointsStraightLineWhatAreCoordsOfAnotherPoint()}
        extraSymbol="minus"
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height}
            xMin={-4}
            xMax={4}
            yMin={-4}
            yMax={4}
            squareGrid
            children={({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <Line
                    x1={mathToSvgX(-4.5)}
                    y1={mathToSvgY(gradient * -4.5 + intercept)}
                    x2={mathToSvgX(4.5)}
                    y2={mathToSvgY(gradient * 4.5 + intercept)}
                    stroke={colors.prussianBlue}
                    strokeWidth={2}
                  />
                </GridSvgChildren>
                <GridImage
                  mathCoord={[x1, y1] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: {
                      fill: colors.pacificBlue
                    },
                    width: displayMode !== 'digital' ? 70 : undefined,
                    height: displayMode !== 'digital' ? 70 : undefined
                  }}
                />
                <GridImage
                  mathCoord={[x2, y2] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: {
                      fill: colors.pacificBlue
                    },
                    width: displayMode !== 'digital' ? 70 : undefined,
                    height: displayMode !== 'digital' ? 70 : undefined
                  }}
                />
              </>
            )}
          />
        )}
        inputMaxCharacters={2}
        testCorrect={([userX, userY]) => {
          // Mark wrong if user enter the points already plotted
          if (
            [
              [parseToSUB(x1.toString()), parseToSUB(y1.toString())],
              [parseToSUB(x2.toString()), parseToSUB(y2.toString())]
            ].some(([x, y]) => arraysHaveSameContents([userX, userY], [x, y]))
          )
            return false;
          // Mark right if they enter any points that satisfy the given points
          const userC =
            Number(parseSymbolsToString(userY)) - Number(parseSymbolsToString(userX)) * gradient;
          return userC === intercept;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [points[2].x.toLocaleString(), points[2].y.toLocaleString()],
          answerText: translate.markScheme.acceptAnyCoordinateThatSatisfiesLine({
            m: parseToSUB(gradient.toString()),
            c: parseToSUB(intercept.toString())
          })
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={'(<ans/>, <ans/>)'}
        sentenceStyle={{ alignItems: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1400}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aFg',
  description: 'aFg',
  keywords: ['Coordinate'],
  schema: z.object({
    coordinates1: z.number().int().min(-9).max(9).array().length(2),
    coordinates2: z.number().int().min(-9).max(9).array().length(2)
  }),
  simpleGenerator: () => {
    // tweak constraints a bit so label isnt over axes
    const x1 = randomIntegerInclusive(-9, 9, { constraint: x => !isInRange(-5, 0)(x) });
    const y1 = randomIntegerInclusive(-9, 9, { constraint: x => x !== 0 });

    const x2 = randomIntegerInclusive(-9, 9, {
      constraint: x => !isInRange(x1 - 3, x1 + 3)(x) && !isInRange(-3, 1)(x)
    });
    const y2 = randomIntegerInclusive(-9, 9, {
      constraint: y => !isInRange(y1 - 3, y1 + 3)(y) && !isInRange(0, 3)(y)
    });

    return { coordinates1: [x1, y1], coordinates2: [x2, y2] };
  },
  Component: props => {
    const {
      question: { coordinates1, coordinates2 },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsAHorizontalAndVerticalLineWhatAreCoordsOfIntersection()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={9}
            yMax={9}
            xMin={-9}
            yMin={-9}
            xLabels={null}
            yLabels={null}
            hideGridLines
            squareGrid
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <Line
                    x1={mathToSvgX(-9.5)}
                    y1={mathToSvgY(coordinates2[1])}
                    x2={mathToSvgX(9.5)}
                    y2={mathToSvgY(coordinates2[1])}
                    stroke={colors.red}
                    strokeWidth={2}
                  />
                  <Line
                    x1={mathToSvgX(coordinates1[0])}
                    y1={mathToSvgY(-9.5)}
                    x2={mathToSvgX(coordinates1[0])}
                    y2={mathToSvgY(9.5)}
                    stroke={colors.red}
                    strokeWidth={2}
                  />
                </GridSvgChildren>
                <GridImage
                  mathCoord={coordinates1 as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue },
                    width: displayMode === 'digital' ? 25 : 50
                  }}
                />
                <GridImage
                  mathCoord={coordinates2 as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue },
                    width: displayMode === 'digital' ? 25 : 50
                  }}
                />
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(coordinates1[1]) - (displayMode === 'digital' ? 10 : 20),
                    right: mathToSvgY(coordinates1[0]) - (displayMode === 'digital' ? 60 : 140),
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.answerSentences.xYCoordinate(coordinates1[0], coordinates1[1])}
                </Text>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(coordinates2[1]) + (displayMode === 'digital' ? 20 : 40),
                    right: mathToSvgY(coordinates2[0]) - (displayMode === 'digital' ? 10 : 40),
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.answerSentences.xYCoordinate(coordinates2[0], coordinates2[1])}
                </Text>
              </>
            )}
          </Grid>
        )}
        inputMaxCharacters={2}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentence={`(<ans/>, <ans/>)`}
        extraSymbol="minus"
        testCorrect={userAnswer =>
          userAnswer[0] === parseToSUB(coordinates1[0].toString()) &&
          userAnswer[1] === parseToSUB(coordinates2[1].toString())
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinates1[0].toString(), coordinates2[1].toString()]
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aFh',
  description: 'aFh',
  keywords: ['Coordinate'],
  schema: z
    .object({
      x1: z.number().int().min(-4).max(9),
      y1: z.number().int().min(-9).max(4),
      width: z.number().int().min(5).max(10),
      answerCoordinates: z.number().int().min(0).max(3).array().length(2)
    })
    .refine(val => val.x1 - val.width >= -9 && val.y1 + val.width <= 9, 'square is within grid'),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(5, 10);

    // tweak constraints to ensure labels do not overlap axes
    const x1 = randomIntegerInclusive(-4, 9, {
      constraint: x => !isInRange(-1, 0)(x) && !isInRange(-1, 0)(x - width) && x - width >= -9
    });
    const y1 = randomIntegerInclusive(-9, 4, {
      constraint: y => !isInRange(0, 2)(y) && !isInRange(-2, 0)(y + width) && y + width <= 9
    });

    const answerCoordinates = randomUniqueIntegersInclusive(0, 3, 2);

    return { x1, y1, width, answerCoordinates };
  },
  Component: props => {
    const {
      question: { x1, y1, width, answerCoordinates },
      translate,
      displayMode
    } = props;

    const x = 0;
    const y = 1;

    const coords = [
      [x1, y1],
      [x1, y1 + width],
      [x1 - width, y1 + width],
      [x1 - width, y1]
    ];

    const maxY = sortNumberArray(
      coords.map(point => point[y]),
      'descending'
    )[0];

    const maxX = sortNumberArray(
      coords.map(point => point[x]),
      'descending'
    )[0];

    const letters = [
      translate.letters.A(),
      translate.letters.B(),
      translate.letters.C(),
      translate.letters.D()
    ];

    const yMaxOffset = displayMode === 'digital' ? 25 : 40;
    const yMinOffset = displayMode === 'digital' ? 0 : 20;

    const yMaxOffsetCoord = displayMode === 'digital' ? 60 : 100;
    const yMinOffsetCoord = displayMode === 'digital' ? 30 : 80;
    const xOffset = displayMode === 'digital' ? 20 : 60;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.hereIsASquareWriteCoordsOfOtherVertices()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={9}
            yMax={9}
            xMin={-9}
            yMin={-9}
            squareGrid
            hideGridLines
            xLabels={null}
            yLabels={null}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon points={coords as [number, number][]} showBorder />
                </GridSvgChildren>
                {letters.map((val, i) => (
                  <Text
                    key={i}
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      top:
                        coords[i][y] === maxY
                          ? mathToSvgY(coords[i][y]) - yMaxOffset
                          : mathToSvgY(coords[i][y]) + yMinOffset,
                      left:
                        coords[i][x] === maxX || coords[i][x] === 0
                          ? mathToSvgX(coords[i][x])
                          : mathToSvgX(coords[i][x] - 0.5),
                      fontSize: displayMode === 'digital' ? 21 : 50,
                      lineHeight: 24,
                      color: colors.prussianBlue
                    }}
                  >
                    {val}
                  </Text>
                ))}
                {[0, 1, 2, 3]
                  .filter(val => !answerCoordinates.includes(val))
                  .map(val => (
                    <Text
                      key={`${val}_coords`}
                      variant="WRN700"
                      style={{
                        position: 'absolute',
                        top:
                          coords[val][y] === maxY
                            ? mathToSvgY(coords[val][y]) - yMaxOffsetCoord
                            : mathToSvgY(coords[val][y]) + yMinOffsetCoord,
                        left:
                          coords[val][x] === maxX
                            ? mathToSvgX(coords[val][x]) - xOffset
                            : coords[val][x] === 0
                            ? mathToSvgX(coords[val][x])
                            : mathToSvgX(coords[val][x] - 0.5) - xOffset,
                        fontSize: displayMode === 'digital' ? 21 : 50,
                        lineHeight: 24,
                        color: colors.prussianBlue
                      }}
                    >
                      {translate.answerSentences.xYCoordinate(coords[val][x], coords[val][y])}
                    </Text>
                  ))}
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentences={[
          `${letters[answerCoordinates[0]]}  (<ans/>, <ans/>)`,
          `${letters[answerCoordinates[1]]}  (<ans/>, <ans/>)`
        ]}
        inputMaxCharacters={3}
        testCorrect={[
          coords[answerCoordinates[0]].map(val => val.toString()),
          coords[answerCoordinates[1]].map(val => val.toString())
        ]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4v2 = newQuestionContent({
  uid: 'aFh2',
  description: 'aFh',
  keywords: ['Coordinate'],
  schema: z
    .object({
      x1: z.number().int().min(0).max(10),
      y1: z.number().int().min(0).max(10),
      width: z.number().int().min(3).max(10),
      answerCoordinates: z.number().int().min(0).max(3).array().length(2)
    })
    .refine(val => val.x1 - val.width >= 0 && val.y1 + val.width <= 10, 'square is within grid'),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 8);

    // tweak constraints to ensure labels do not overlap axes
    const x1 = randomIntegerInclusive(0, 10, {
      constraint: x => x - width >= 0
    });
    const y1 = randomIntegerInclusive(2, 10, {
      constraint: y => y + width <= 10
    });

    const answerCoordinates = randomUniqueIntegersInclusive(0, 3, 2);

    return { x1, y1, width, answerCoordinates };
  },
  Component: props => {
    const {
      question: { x1, y1, width, answerCoordinates },
      translate,
      displayMode
    } = props;

    const x = 0;
    const y = 1;

    const coords = [
      [x1, y1],
      [x1, y1 + width],
      [x1 - width, y1 + width],
      [x1 - width, y1]
    ];

    const maxY = sortNumberArray(
      coords.map(point => point[y]),
      'descending'
    )[0];

    const maxX = sortNumberArray(
      coords.map(point => point[x]),
      'descending'
    )[0];

    const letters = [
      translate.letters.A(),
      translate.letters.B(),
      translate.letters.C(),
      translate.letters.D()
    ];

    const yMaxOffset = displayMode === 'digital' ? 25 : 40;
    const yMinOffset = displayMode === 'digital' ? 0 : 20;

    const yMaxOffsetCoord = displayMode === 'digital' ? 60 : 100;
    const yMinOffsetCoord = displayMode === 'digital' ? 30 : 80;
    const xOffset = displayMode === 'digital' ? 20 : 60;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.hereIsASquareWhatAreCoordsOfOtherVertices()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={10}
            yMax={10}
            squareGrid
            hideGridLines
            xLabels={null}
            yLabels={null}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon points={coords as [number, number][]} showBorder />
                </GridSvgChildren>
                {letters.map((val, i) => (
                  <Text
                    key={i}
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      top:
                        coords[i][y] === maxY
                          ? mathToSvgY(coords[i][y]) - yMaxOffset
                          : mathToSvgY(coords[i][y]) + yMinOffset,
                      left:
                        coords[i][x] === maxX || coords[i][x] === 0
                          ? mathToSvgX(coords[i][x])
                          : mathToSvgX(coords[i][x] - 0.5),
                      fontSize: displayMode === 'digital' ? 21 : 50,
                      lineHeight: 24,
                      color: colors.prussianBlue
                    }}
                  >
                    {val}
                  </Text>
                ))}
                {[0, 1, 2, 3]
                  .filter(val => !answerCoordinates.includes(val))
                  .map(val => (
                    <Text
                      key={`${val}_coords`}
                      variant="WRN700"
                      style={{
                        position: 'absolute',
                        top:
                          coords[val][y] === maxY
                            ? mathToSvgY(coords[val][y]) - yMaxOffsetCoord
                            : mathToSvgY(coords[val][y]) + yMinOffsetCoord,
                        left:
                          coords[val][x] === maxX
                            ? mathToSvgX(coords[val][x]) - xOffset
                            : coords[val][x] === 0
                            ? mathToSvgX(coords[val][x])
                            : mathToSvgX(coords[val][x] - 0.5) - xOffset,
                        fontSize: displayMode === 'digital' ? 21 : 50,
                        lineHeight: 24,
                        color: colors.prussianBlue
                      }}
                    >
                      {translate.answerSentences.xYCoordinate(coords[val][x], coords[val][y])}
                    </Text>
                  ))}
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentences={[
          `${letters[answerCoordinates[0]]}  (<ans/>, <ans/>)`,
          `${letters[answerCoordinates[1]]}  (<ans/>, <ans/>)`
        ]}
        inputMaxCharacters={3}
        testCorrect={[
          coords[answerCoordinates[0]].map(val => val.toString()),
          coords[answerCoordinates[1]].map(val => val.toString())
        ]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'aFi',
  description: 'aFi',
  keywords: ['Coordinate'],
  schema: z.object({
    coordinates1: z.number().int().min(-10).max(10).array().length(2),
    coordinates2: z.number().int().min(-10).max(10).array().length(2),
    coordinates3: z.number().int().min(-10).max(10).array().length(2),
    coordinates4: z.number().int().min(-10).max(10).array().length(2),
    isCorrect: z.boolean(),
    isPerimeter: z.boolean(),
    value: z.number().int()
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        // tweak constraints to make sure labels dont cross axes
        const xCoords = randomUniqueIntegersInclusive(-10, 10, 2, {
          constraint: x => !isInRange(-2, 1)(x)
        });
        const yCoords = randomUniqueIntegersInclusive(-8, 8, 2, {
          constraint: x => !isInRange(-1, 1)(x)
        });

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          isValidRectangle(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) && // width greater than 1
          x2 - x1 > 4 &&
          y2 - y1 > 2
        );
      }
    );

    const coordinates1 = [x1, y1];
    const coordinates2 = [x1, y2];
    const coordinates3 = [x2, y2];
    const coordinates4 = [x2, y1];
    let isCorrect = false;
    const isPerimeter = getRandomBoolean();
    const value = ((): number => {
      switch (isPerimeter) {
        case true: {
          const perim = (x2 - x1 + (y2 - y1)) * 2;
          const add = getRandomFromArray([-4, 2, 0, 2, 4] as const);
          isCorrect = add === 0;
          return perim + add;
        }
        case false: {
          const area = (x2 - x1) * (y2 - y1);
          const add = getRandomFromArray([x1 - x2, x2 - x1, 0, y1 - y2, y2 - y1] as const);
          isCorrect = add === 0;
          return area + add;
        }
      }
    })();
    return {
      coordinates1,
      coordinates2,
      coordinates3,
      coordinates4,
      isCorrect,
      isPerimeter,
      value
    };
  },
  Component: props => {
    const {
      question: {
        coordinates1,
        coordinates2,
        coordinates3,
        coordinates4,
        isCorrect,
        isPerimeter,
        value
      },
      translate,
      displayMode
    } = props;

    const x = 0;
    const y = 1;
    const coords = [coordinates1, coordinates2, coordinates3, coordinates4];

    const maxY = sortNumberArray(
      coords.map(point => point[y]),
      'descending'
    )[0];

    const yMaxOffsetCoord = displayMode === 'digital' ? 35 : 80;
    const yMinOffsetCoord = displayMode === 'digital' ? 10 : 60;
    const xOffset = displayMode === 'digital' ? 20 : 60;

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          isPerimeter
            ? translate.instructions.thePerimeterOfShapeIsNumSelectStatementTrueOrFalse(
                translate.shapes.rectangles(1),
                value
              )
            : translate.instructions.theAreaOfShapeIsNumSelectStatementTrueOrFalse(
                translate.shapes.rectangles(1),
                value
              )
        }
        pdfTitle={
          isPerimeter
            ? translate.instructions.thePerimeterOfShapeIsNumCircleStatementTrueOrFalse(
                translate.shapes.rectangles(1),
                value
              )
            : translate.instructions.theAreaOfShapeIsNumCircleStatementTrueOrFalse(
                translate.shapes.rectangles(1),
                value
              )
        }
        correctAnswer={[isCorrect ? 'true' : 'false']}
        selectables={{ true: translate.keywords.True(), false: translate.keywords.False() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                {...dimens}
                xMax={9}
                yMax={9}
                xMin={-9}
                yMin={-9}
                squareGrid
                hideGridLines
                xLabels={null}
                yLabels={null}
              >
                {({ mathToSvgX, mathToSvgY }) => (
                  <>
                    <GridSvgChildren>
                      <GridPolygon points={coords as [number, number][]} showBorder />
                    </GridSvgChildren>
                    {[1, 3].map(val => (
                      <Text
                        key={`${val}_coords`}
                        variant="WRN700"
                        style={{
                          position: 'absolute',
                          top:
                            coords[val][y] === maxY
                              ? mathToSvgY(coords[val][y]) - yMaxOffsetCoord
                              : mathToSvgY(coords[val][y]) + yMinOffsetCoord,
                          left: mathToSvgX(coords[val][x]) - xOffset,
                          fontSize: displayMode === 'digital' ? 21 : 50,
                          lineHeight: 24,
                          color: colors.prussianBlue
                        }}
                      >
                        {translate.answerSentences.xYCoordinate(coords[val][x], coords[val][y])}
                      </Text>
                    ))}
                  </>
                )}
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5v2 = newQuestionContent({
  uid: 'aFi2',
  description: 'aFi',
  keywords: ['Straight line', 'Four quadrants', 'Coordinates'],
  schema: z.object({
    incorrectOptions: z.array(z.number().int().min(-10).max(10).array().length(2)),
    correctAnswers: z.array(z.number().int().min(-10).max(10).array().length(2)),
    isHorizontal: z.boolean(),
    lineIntercept: z.number().int().min(-10).max(10)
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const lineIntercept = randomIntegerInclusive(-10, 10, { constraint: x => x !== 0 });

    const { correctAnswers, incorrectOptions } = rejectionSample(
      () => {
        const numberOfCorrectAnswers = randomIntegerInclusive(1, 4);

        const values = randomUniqueIntegersInclusive(-10, 10, numberOfCorrectAnswers);

        const correctAnswers = countRange(numberOfCorrectAnswers).map(i =>
          isHorizontal ? [lineIntercept, values[i]] : [values[i], lineIntercept]
        );

        const incorrect1 = isHorizontal
          ? [-lineIntercept, randomIntegerInclusive(-10, 10)]
          : [randomIntegerInclusive(-10, 10), -lineIntercept];

        const incorrect2 = isHorizontal
          ? [randomIntegerInclusive(-10, 10), lineIntercept]
          : [lineIntercept, randomIntegerInclusive(-10, 10)];

        const incorrect3 = isHorizontal
          ? [-lineIntercept, -correctAnswers[0][1]]
          : [-correctAnswers[0][0], -lineIntercept];

        const incorrectOptions = shuffle([incorrect1, incorrect2, incorrect3]).slice(
          0,
          4 - numberOfCorrectAnswers
        );

        return { correctAnswers, incorrectOptions };
      },
      val => arrayHasNoDuplicates([...val.correctAnswers, ...val.incorrectOptions])
    );

    return { correctAnswers, isHorizontal, lineIntercept, incorrectOptions };
  },
  Component: props => {
    const {
      question: { correctAnswers, isHorizontal, lineIntercept, incorrectOptions },
      translate,
      displayMode
    } = props;

    const options = shuffle(
      [
        ...correctAnswers.map((val, i) => [
          ['A', 'B', 'C', 'D'][i],
          translate.answerSentences.xYCoordinate(val[0], val[1])
        ]),
        ...incorrectOptions.map((val, i) => [
          ['X', 'Y', 'Z'][i],
          translate.answerSentences.xYCoordinate(val[0], val[1])
        ])
      ],
      { random: seededRandom(props.question) }
    );

    // go slightly to one side of intercept so the label can be seen apart from at 0
    const drawLineIntercept = lineIntercept + 0.6;

    const xLabels = countRange(21, -10).map(val =>
      isHorizontal && val === lineIntercept ? parseToSUB(lineIntercept.toLocaleString()) : ''
    );
    const yLabels = countRange(21, -10).map(val =>
      !isHorizontal && val === lineIntercept ? parseToSUB(lineIntercept.toLocaleString()) : ''
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectPairOfCoordsThatLieOnL(correctAnswers.length)}
        pdfTitle={translate.instructions.circlePairOfCoordsThatLieOnL(correctAnswers.length)}
        correctAnswer={correctAnswers.map((_val, i) => ['A', 'B', 'C', 'D'][i])}
        selectables={Object.fromEntries(options)}
        multiSelect={correctAnswers.length > 1}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                {...dimens}
                xMax={10}
                yMax={10}
                xMin={-10}
                yMin={-10}
                squareGrid
                hideGridLines
                xLabels={xLabels}
                yLabels={yLabels}
              >
                <GridSvgChildren>
                  <GridLine
                    points={
                      isHorizontal
                        ? [
                            [drawLineIntercept, -10.5],
                            [drawLineIntercept, 10.5]
                          ]
                        : [
                            [-10.5, drawLineIntercept],
                            [10.5, drawLineIntercept]
                          ]
                    }
                    strokeWidth={displayMode === 'digital' ? 2 : 4}
                    color={colors.red}
                  />
                  {/* L label */}
                  <GridText
                    label={translate.letters.L()}
                    coordinates={
                      isHorizontal
                        ? [lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept, 11]
                        : [11, lineIntercept === 0 ? lineIntercept + 0.4 : lineIntercept]
                    }
                  />
                </GridSvgChildren>
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aFj',
  description: 'aFj',
  keywords: ['Coordinate'],
  schema: z.object({
    x1: z.number().int().min(-10).max(10),
    x2: z.number().int().min(-10).max(10),
    y1: z.number().int().min(-8).max(8),
    y2: z.number().int().min(-8).max(8)
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(-10, 10, 2, {
          constraint: x => !isInRange(-1, 1)(x)
        });
        const yCoords = randomUniqueIntegersInclusive(-8, 8, 2, {
          constraint: y => !isInRange(-1, 1)(y)
        });
        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'descending');
        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          x2 - x1 > 3 &&
          y1 - y2 > 3 &&
          x2 + (y1 - y2) <= 10 &&
          y2 + (x2 - x1) <= 8 &&
          // to make sure labels are not too close to axes
          !isInRange(-1, 1)(x2 + (y1 - y2)) &&
          !isInRange(-1, 1)(y2 + (x2 - x1))
        );
      }
    );

    return { x1, x2, y1, y2 };
  },
  Component: props => {
    const {
      question: { x1, x2, y1, y2 },
      translate,
      displayMode
    } = props;

    const x3 = x2 + (y1 - y2);
    const y3 = y2 + (x2 - x1);
    const coords = [
      [x1, y1],
      [x1, y2],
      [x2, y2],
      [x3, y3],
      [x3, y2],
      [x2, y2]
    ];

    const yMaxOffset = displayMode === 'digital' ? 35 : 60;
    const yMaxOffsetCoord = displayMode === 'digital' ? 40 : 90;
    const yMinOffsetCoord = displayMode === 'digital' ? 15 : 70;
    const xOffset = displayMode === 'digital' ? 20 : 60;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereAreTwoIdenticalTrianglesWriteCoordsOfPointA()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={9}
            yMax={9}
            xMin={-9}
            yMin={-9}
            squareGrid
            hideGridLines
            xLabels={null}
            yLabels={null}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon points={coords as [number, number][]} showBorder color={null} />
                </GridSvgChildren>
                <GridImage
                  mathCoord={[x1, y1] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue },
                    width: displayMode === 'digital' ? 20 : 40
                  }}
                />
                <GridImage
                  mathCoord={[x3, y3] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue },
                    width: displayMode === 'digital' ? 20 : 40
                  }}
                />
                <GridImage
                  mathCoord={[x2, y2] as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue },
                    width: displayMode === 'digital' ? 20 : 40
                  }}
                />
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(y3) - yMaxOffset,
                    left: mathToSvgX(x3),
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.letters.A()}
                </Text>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(y1) - yMaxOffsetCoord,
                    left: mathToSvgX(x1) - xOffset,
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.answerSentences.xYCoordinate(x1, y1)}
                </Text>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    top: mathToSvgY(y2) + yMinOffsetCoord,
                    left: mathToSvgX(x2) - xOffset,
                    fontSize: displayMode === 'digital' ? 21 : 50,
                    lineHeight: 24,
                    color: colors.prussianBlue
                  }}
                >
                  {translate.answerSentences.xYCoordinate(x2, y2)}
                </Text>
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentence={`${translate.letters.A()}  (<ans/>, <ans/>)`}
        testCorrect={[x3, y3].map(val => val.toString())}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6v2 = newQuestionContent({
  uid: 'aFj2',
  description: 'aFj',
  keywords: ['Coordinate'],
  schema: z.object({
    isHorizontal: z.boolean(),
    points: z
      .array(z.object({ x: z.number().int().min(-10).max(10), y: z.number().int().min(-8).max(8) }))
      .length(3)
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const distance = randomIntegerInclusive(4, isHorizontal ? 8 : 6);
    let points;
    if (isHorizontal) {
      const x1 = randomIntegerInclusive(-10, 10 - distance * 2, {
        constraint: x => !isInRange(-1, 1)(x) && !isInRange(-1, 1)(x + distance)
      });
      const y = randomIntegerInclusive(-8, 8, { constraint: y => y !== 0 });
      points = [
        { x: x1, y },
        { x: x1 + distance, y },
        { x: x1 + 2 * distance, y }
      ];
    } else {
      const y1 = randomIntegerInclusive(-8 + distance * 2, 8, {
        constraint: y => !isInRange(-1, 1)(y) && !isInRange(-1, 1)(y - distance)
      });
      const x = randomIntegerInclusive(-10, 9, { constraint: x => x !== 0 });
      points = [
        { x, y: y1 },
        { x, y: y1 - distance },
        { x, y: y1 - 2 * distance }
      ];
    }

    return { points, isHorizontal };
  },
  Component: props => {
    const {
      question: { points, isHorizontal },
      translate,
      displayMode
    } = props;

    const xOffset = displayMode === 'digital' ? 10 : 30;
    const yTopOffset = displayMode === 'digital' ? 38 : 60;
    const yBottomOffset = displayMode === 'digital' ? 18 : 60;

    const xCoordOffset = displayMode === 'digital' ? 30 : 60;
    const xCoordVerticalOffset = displayMode === 'digital' ? 18 : 60;
    const yBottomCoordOffset = displayMode === 'digital' ? 42 : 110;
    const yTopCoordOffset = displayMode === 'digital' ? 60 : 110;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theDistanceBetweenABIsSameAsBAndCWhatAreCoordsOfC()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xMax={9}
            yMax={9}
            xMin={-9}
            yMin={-9}
            squareGrid
            hideGridLines
            xLabels={null}
            yLabels={null}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                {points.map((coord, i) => (
                  <View key={i}>
                    <GridImage
                      mathCoord={[coord.x, coord.y]}
                      item={{
                        component: 'Coordinates/CirclePointCustomizable',
                        svgProps: { fill: colors.pacificBlue },
                        width: displayMode === 'digital' ? 20 : 40
                      }}
                    />
                    {/* letter */}
                    <Text
                      variant="WRN700"
                      style={{
                        position: 'absolute',
                        top:
                          coord.y > 0 || !isHorizontal
                            ? mathToSvgY(coord.y) - yTopOffset
                            : mathToSvgY(coord.y) + yBottomOffset,
                        left: mathToSvgX(coord.x) - xOffset,
                        fontSize: displayMode === 'digital' ? 21 : 50,
                        lineHeight: 24,
                        color: colors.prussianBlue
                      }}
                    >
                      {translate.letters[['A', 'B', 'C'][i] as 'A' | 'B' | 'C']()}
                    </Text>
                    {/* coord */}
                    {i !== 2 && (
                      <Text
                        variant="WRN700"
                        style={{
                          position: 'absolute',
                          top: !isHorizontal
                            ? mathToSvgY(coord.y) - yTopOffset
                            : coord.y > 0
                            ? mathToSvgY(coord.y) - yTopCoordOffset
                            : mathToSvgY(coord.y) + yBottomCoordOffset,
                          left: !isHorizontal
                            ? mathToSvgX(coord.x) + xCoordVerticalOffset
                            : mathToSvgX(coord.x) - xCoordOffset,
                          fontSize: displayMode === 'digital' ? 21 : 50,
                          lineHeight: 24,
                          color: colors.prussianBlue
                        }}
                      >
                        {translate.answerSentences.xYCoordinate(coord.x, coord.y)}
                      </Text>
                    )}
                  </View>
                ))}
              </>
            )}
          </Grid>
        )}
        extraSymbol="minus"
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentence={`${translate.letters.C()}  (<ans/>, <ans/>)`}
        testCorrect={[parseToSUB(points[2].x.toString()), parseToSUB(points[2].y.toString())]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithCoordinates',
  questionTypes: [Question1v2, Question2, Question3, Question4v2, Question5v2, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question4, Question5, Question6]
});
export default SmallStep;
