import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { Direction, Point2d } from '../../../../utils/vectors';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { GridPolygon } from 'common/src/utils/gridUtils';
import { countRange } from '../../../../utils/collections';
import Svg, { G, TSpan, Text as SvgText } from 'react-native-svg';
import { isValidTriangle } from '../../../../utils/shapes';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

function pointWithinRange(
  point: { x: number; y: number },
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number
) {
  return xMin <= point.x && point.x <= xMax && yMin <= point.y && point.y <= yMax;
}

const Question1 = newQuestionContent({
  uid: 'aFk',
  description: 'aFk',
  keywords: ['Coordinate', 'Vertex', 'Translation'],
  questionHeight: 900,
  schema: z
    .object({
      startX: z.number().int().min(0).max(10),
      startY: z.number().int().min(0).max(8),
      translateX: z.number().int(),
      translateY: z.number().int()
    })
    .refine(({ startX, startY, translateX, translateY }) => {
      const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
      return 0 <= newPoint.x && newPoint.x <= 10 && 0 <= newPoint.y && newPoint.y <= 8;
    }, 'translation must not place the point out of the grid (10x8)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const startX = randomIntegerInclusive(0, 10);
        const startY = randomIntegerInclusive(0, 8);

        const translateX = randomIntegerInclusive(-9, 9, {
          constraint: x => x !== 0
        });
        const translateY = randomIntegerInclusive(-8, 8, {
          constraint: x => x !== 0
        });

        return { startX, startY, translateX, translateY };
      },
      ({ startX, startY, translateX, translateY }) => {
        const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
        return 0 <= newPoint.x && newPoint.x <= 10 && 0 <= newPoint.y && newPoint.y <= 8;
      }
    ),

  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.dragShapeToTranslateXDxYDy({
      shape: translate.shapes.points(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    const pdfTitle = translate.instructions.drawShapeToTranslateXDxYDy({
      shape: translate.shapes.crosses(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="dot"
        xMax={10}
        yMax={8}
        hideContinuationLines={false}
        hideAxis={false}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aFl',
  description: 'aFl',
  keywords: ['Coordinate', 'Vertex', 'Translation'],
  schema: z
    .object({
      squareAPoints: z
        .array(z.object({ x: z.number().int().min(0).max(10), y: z.number().int().min(0).max(8) }))
        .length(4),
      squareBPoints: z
        .array(z.object({ x: z.number().int().min(0).max(10), y: z.number().int().min(0).max(8) }))
        .length(4),
      translateX: z.number().int(),
      translateY: z.number().int(),
      isCorrect: z.boolean(),
      squareSize: z.number().int().min(2).max(4)
    })
    .refine(({ squareAPoints, translateX, translateY }) => {
      const newPoint1 = new Point2d(squareAPoints[0].x, squareAPoints[0].y).add(
        new Point2d(translateX, translateY)
      );
      const newPoint2 = new Point2d(squareAPoints[1].x, squareAPoints[1].y).add(
        new Point2d(translateX, translateY)
      );
      const newPoint3 = new Point2d(squareAPoints[2].x, squareAPoints[2].y).add(
        new Point2d(translateX, translateY)
      );
      const newPoint4 = new Point2d(squareAPoints[3].x, squareAPoints[3].y).add(
        new Point2d(translateX, translateY)
      );
      return (
        pointWithinRange(newPoint1, 0, 10, 0, 8) &&
        pointWithinRange(newPoint2, 0, 10, 0, 8) &&
        pointWithinRange(newPoint3, 0, 10, 0, 8) &&
        pointWithinRange(newPoint4, 0, 10, 0, 8)
      );
    }, 'translation must not place the point out of the grid (10x8)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const squareSize = randomIntegerInclusive(2, 4);

        const point1 = {
          x: randomIntegerInclusive(0, 10 - squareSize),
          y: randomIntegerInclusive(squareSize, 8)
        };
        const point2 = { x: point1.x + squareSize, y: point1.y };
        const point3 = { x: point1.x, y: point1.y + squareSize };
        const point4 = { x: point1.x + squareSize, y: point1.y + squareSize };

        const squareAPoints = [point1, point2, point3, point4];

        const translateX = getRandomBoolean()
          ? randomIntegerInclusive(-9, -(squareSize + 1))
          : randomIntegerInclusive(squareSize + 1, 9);
        const translateY = getRandomBoolean()
          ? randomIntegerInclusive(-8, -(squareSize + 1))
          : randomIntegerInclusive(squareSize + 1, 8);

        const newPoint1 = new Point2d(squareAPoints[0].x, squareAPoints[0].y).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(squareAPoints[1].x, squareAPoints[1].y).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(squareAPoints[2].x, squareAPoints[2].y).add(
          new Point2d(translateX, translateY)
        );
        const newPoint4 = new Point2d(squareAPoints[3].x, squareAPoints[3].y).add(
          new Point2d(translateX, translateY)
        );

        const squareBPoints = [newPoint1, newPoint2, newPoint3, newPoint4];
        const isCorrect = getRandomBoolean();

        return { squareAPoints, squareBPoints, translateX, translateY, isCorrect, squareSize };
      },
      ({ squareBPoints, squareAPoints }) => {
        return (
          pointWithinRange(squareBPoints[0], 0, 10, 0, 8) &&
          pointWithinRange(squareBPoints[1], 0, 10, 0, 8) &&
          pointWithinRange(squareBPoints[2], 0, 10, 0, 8) &&
          pointWithinRange(squareBPoints[3], 0, 10, 0, 8) &&
          pointWithinRange(squareAPoints[0], 0, 10, 0, 8) &&
          pointWithinRange(squareAPoints[1], 0, 10, 0, 8) &&
          pointWithinRange(squareAPoints[2], 0, 10, 0, 8) &&
          pointWithinRange(squareAPoints[3], 0, 10, 0, 8)
        );
      }
    ),
  Component: ({ question, translate, displayMode, theme }) => {
    const { squareAPoints, squareBPoints, translateX, translateY, isCorrect, squareSize } =
      question;

    const xTranslation = isCorrect ? translateX : squareBPoints[0].x - squareAPoints[1].x;
    const yTranslation = isCorrect ? translateY : squareBPoints[0].y - squareAPoints[2].y;

    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case xTranslation > 0:
          return ['right', xTranslation];
        case xTranslation <= 0:
          return ['left', -xTranslation];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case yTranslation > 0:
          return ['up', yTranslation];
        case yTranslation <= 0:
          return ['down', -yTranslation];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.theTranslationABIsXDxAndYDYDoYouAgree({
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    return (
      <QF39ContentWithSelectablesOnRight
        title={title}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMin={0}
                xMax={10}
                yMin={0}
                yMax={8}
                squareGrid
                children={({ mathToSvgX }) => (
                  <>
                    <GridImage
                      mathCoord={[squareAPoints[0].x, squareAPoints[0].y] as [number, number]}
                      item={{
                        component: 'SquareCustomizable',
                        svgProps: {
                          fill:
                            displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                        },
                        width: mathToSvgX(squareSize) - mathToSvgX(0),
                        height: mathToSvgX(squareSize) - mathToSvgX(0)
                      }}
                      anchorDX={0}
                      anchorDY={mathToSvgX(squareSize) - mathToSvgX(0)}
                      label={{
                        text: translate.letters.A(),
                        styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
                      }}
                    />
                    <GridImage
                      mathCoord={[squareBPoints[0].x, squareBPoints[0].y] as [number, number]}
                      item={{
                        component: 'SquareCustomizable',
                        svgProps: {
                          fill:
                            displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                        },
                        width: mathToSvgX(squareSize) - mathToSvgX(0),
                        height: mathToSvgX(squareSize) - mathToSvgX(0)
                      }}
                      anchorDX={0}
                      anchorDY={mathToSvgX(squareSize) - mathToSvgX(0)}
                      label={{
                        text: translate.letters.B(),
                        styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
                      }}
                    />
                  </>
                )}
              ></Grid>
            )}
          </MeasureView>
        }
        correctAnswer={[isCorrect ? 'yes' : 'no']}
        selectables={{ yes: translate.misc.Yes(), no: translate.misc.No() }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aFm',
  description: 'aFm',
  keywords: ['Coordinate', 'Vertex', 'Translation'],
  schema: z
    .object({
      coordinates1: z.number().int().min(-5).max(5).array().length(2),
      coordinates2: z.number().int().min(-5).max(5).array().length(2),
      coordinates3: z.number().int().min(-5).max(5).array().length(2),
      translateX: z.number().int().min(-7).max(7),
      translateY: z.number().int().min(-6).max(6),
      direction: z.enum(['left', 'right', 'up', 'down'])
    })
    .refine(({ coordinates1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates1[0], coordinates1[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid')
    .refine(({ coordinates2, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates2[0], coordinates2[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid')
    .refine(({ coordinates3, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates3[0], coordinates3[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const direction = getRandomFromArray(['left', 'right', 'up', 'down'] as const);

        let coordinates1;
        let coordinates2;
        let coordinates3;
        let translateX;
        let translateY;
        switch (direction) {
          case 'left': {
            const xCoords = randomUniqueIntegersInclusive(2, 5, 3);
            const yCoords = randomUniqueIntegersInclusive(-4, 4, 3);
            [coordinates1, coordinates2, coordinates3] = countRange(3).map(i => [
              xCoords[i],
              yCoords[i]
            ]);

            translateX = randomIntegerInclusive(-7, -4);
            translateY = 0;
            break;
          }
          case 'right': {
            const xCoords = randomUniqueIntegersInclusive(-5, 2, 3);
            const yCoords = randomUniqueIntegersInclusive(-4, 4, 3);
            [coordinates1, coordinates2, coordinates3] = countRange(3).map(i => [
              xCoords[i],
              yCoords[i]
            ]);
            translateX = randomIntegerInclusive(4, 7);
            translateY = 0;
            break;
          }
          case 'up': {
            const xCoords = randomUniqueIntegersInclusive(-5, 5, 3);
            const yCoords = randomUniqueIntegersInclusive(-4, -2, 3);
            [coordinates1, coordinates2, coordinates3] = countRange(3).map(i => [
              xCoords[i],
              yCoords[i]
            ]);
            translateX = 0;
            translateY = randomIntegerInclusive(3, 6);
            break;
          }
          case 'down': {
            const xCoords = randomUniqueIntegersInclusive(-5, 5, 3);
            const yCoords = randomUniqueIntegersInclusive(2, 4, 3);
            [coordinates1, coordinates2, coordinates3] = countRange(3).map(i => [
              xCoords[i],
              yCoords[i]
            ]);
            translateX = 0;
            translateY = randomIntegerInclusive(-6, -3);
            break;
          }
        }

        return { coordinates1, coordinates2, coordinates3, translateX, translateY, direction };
      },
      ({ coordinates1, coordinates2, coordinates3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinates1[0], coordinates1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinates2[0], coordinates2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinates3[0], coordinates3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          -5 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          -4 <= newPoint1.y &&
          newPoint1.y <= 4 &&
          -5 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          -4 <= newPoint2.y &&
          newPoint2.y <= 4 &&
          -5 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          -4 <= newPoint3.y &&
          newPoint3.y <= 4 &&
          isValidTriangle(
            coordinates1 as [number, number],
            coordinates2 as [number, number],
            coordinates3 as [number, number],
            3
          )
        );
      }
    ),
  Component: ({
    question: { coordinates1, coordinates2, coordinates3, translateX, translateY, direction },
    translate,
    displayMode
  }) => {
    const coords = [coordinates1, coordinates2, coordinates3];
    const start = coords.map(val => new Point2d(val[0], val[1]));
    const end = coords.map(val =>
      new Point2d(val[0], val[1]).add(new Point2d(translateX, translateY))
    );

    const title =
      translateX === 0
        ? translate.instructions.dragPointsToTranslateTriangleYSquaresDY({
            y: Math.abs(translateY),
            dy: translate.directions[direction]()
          })
        : translate.instructions.dragPointsToTranslateTriangleXSquaresDX({
            x: Math.abs(translateX),
            dx: translate.directions[direction]()
          });

    const pdfTitle =
      translateX === 0
        ? translate.instructions.drawPointsToTranslateTriangleYSquaresDY({
            y: Math.abs(translateY),
            dy: translate.directions[direction]()
          })
        : translate.instructions.drawPointsToTranslateTriangleXSquaresDX({
            x: Math.abs(translateX),
            dx: translate.directions[direction]()
          });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={start}
        end={end}
        svg="custom"
        gridChildren={
          <Svg height={1000}>
            <GridPolygon
              points={[
                coordinates1 as [number, number],
                coordinates2 as [number, number],
                coordinates3 as [number, number]
              ]}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
          </Svg>
        }
        customDraggable={(color: string, opacity?: number, index = 0) => ({
          component: (
            <Svg width={36} height={36}>
              <AssetSvg
                name="Coordinates/CirclePointCustomizable"
                width={36}
                svgProps={{ fill: opacity && opacity < 1 ? `${color}${opacity * 100}` : color }}
              />
              <SvgText y={26} x={18} textAnchor="middle" fontFamily="White_Rose_Noto-Regular">
                <TSpan
                  fontSize={displayMode === 'digital' ? 22 : 28}
                  fill={displayMode === 'digital' ? 'black' : 'white'}
                >
                  {['A', 'B', 'C'][index]}
                </TSpan>
              </SvgText>
            </Svg>
          ),
          width: 36,
          height: 36
        })}
        xMax={5}
        yMax={4}
        xMin={-5}
        yMin={-4}
        hideContinuationLines={false}
        hideAxis={false}
        anchorDX={18}
        anchorDY={18}
        questionHeight={900}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aFn',
  description: 'aFn',
  keywords: ['Coordinate', 'Vertex', 'Translation'],
  schema: z.object({
    triangle1: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle2: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle3: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(3),
    triangle4: z
      .array(z.object({ x: z.number().int().min(-5).max(5), y: z.number().int().min(-4).max(4) }))
      .length(3),
    labels: z.array(z.enum(['A', 'B', 'C', 'D'])).length(4)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(1, 2);
    const width = randomIntegerInclusive(1, 3);

    const quads = shuffle([
      { dx: 0, dy: 0, label: 'A' as const },
      { dx: 5, dy: 0, label: 'B' as const },
      { dx: 0, dy: -5, label: 'C' as const },
      { dx: 5, dy: -5, label: 'D' as const }
    ]);

    // position of right angles
    const raPosition = getRandomSubArrayFromArray(
      ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'] as const,
      3
    );

    // do all triangles in q1 and then translate
    const points = countRange(3).map(i => {
      const trianglePoint1 = {
        x: randomIntegerInclusive(-5, -1 - width - 1),
        y:
          raPosition[i] === 'topRight' || raPosition[i] === 'topLeft'
            ? randomIntegerInclusive(1 + height, 4)
            : randomIntegerInclusive(1, 4 - height)
      };
      const trianglePoint2 = {
        x: trianglePoint1.x + width,
        y: trianglePoint1.y
      };

      const trianglePoint3 = {
        x:
          raPosition[i] === 'topRight' || raPosition[i] === 'bottomRight'
            ? trianglePoint1.x + width
            : trianglePoint1.x,
        y:
          raPosition[i] === 'topRight' || raPosition[i] === 'topLeft'
            ? trianglePoint1.y - height
            : trianglePoint1.y + height
      };

      return [trianglePoint1, trianglePoint2, trianglePoint3];
    });

    const triangle1 = points[0].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(quads[0].dx, quads[0].dy))
    );
    const triangle2 = points[0].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(quads[1].dx, quads[1].dy))
    );
    const triangle3 = points[1].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(quads[2].dx, quads[2].dy))
    );
    const triangle4 = points[2].map(val =>
      new Point2d(val.x, val.y).add(new Point2d(quads[3].dx, quads[3].dy))
    );

    const labels = quads.map(val => val.label);

    return { triangle1, triangle2, triangle3, triangle4, labels };
  },
  Component: ({ question, translate }) => {
    const { triangle1, triangle2, triangle3, triangle4, labels } = question;

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectWhichTrianglesAreTranslationsOfEachOther()}
        pdfTitle={translate.instructions.circleWhichTrianglesAreTranslationsOfEachOther()}
        multiSelect
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMin={-5}
                xMax={5}
                yMin={-4}
                yMax={4}
                squareGrid
                children={({ mathToSvgX, mathToSvgY }) => (
                  <GridSvgChildren>
                    {[triangle1, triangle2, triangle3, triangle4].map((triangle, i) => {
                      return (
                        <G key={i}>
                          <SvgText
                            y={mathToSvgY(triangle[0].y + 0.2)}
                            x={mathToSvgX(
                              Math.max(triangle[0].x, triangle[1].x, triangle[2].x) + 0.4
                            )}
                            textAnchor="middle"
                          >
                            <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                              {translate.letters[labels[i] as 'A' | 'B' | 'C' | 'D']()}
                            </TSpan>
                          </SvgText>
                          <GridPolygon
                            points={triangle.map(val => [val.x, val.y] as [number, number])}
                            showBorder
                          />
                        </G>
                      );
                    })}
                  </GridSvgChildren>
                )}
              ></Grid>
            )}
          </MeasureView>
        }
        correctAnswer={labels
          .filter((_val, i) => i < 2)
          .map(val => translate.letters[val as 'A' | 'B' | 'C' | 'D']())}
        selectables={Object.fromEntries(
          ['A', 'B', 'C', 'D'].map(val => [val, translate.letters[val as 'A' | 'B' | 'C' | 'D']()])
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aFo',
  description: 'aFo',
  keywords: ['Coordinate', 'Vertex', 'Translation'],
  schema: z
    .object({
      coordinates1: z.number().int().min(-5).max(5).array().length(2),
      coordinates2: z.number().int().min(-5).max(5).array().length(2),
      coordinates3: z.number().int().min(-5).max(5).array().length(2),
      translateX: z.number().int().min(-7).max(7),
      translateY: z.number().int().min(-6).max(6),
      directionX: z.enum(['left', 'right']),
      directionY: z.enum(['up', 'down'])
    })
    .refine(({ coordinates1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates1[0], coordinates1[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinates2, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates2[0], coordinates2[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinates3, translateX, translateY }) => {
      const newPoint = new Point2d(coordinates3[0], coordinates3[1]).add(
        new Point2d(translateX, translateY)
      );
      return -5 <= newPoint.x && newPoint.x <= 5 && -4 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid (5x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const directionX = getRandomFromArray(['left', 'right'] as const);
        const directionY = getRandomFromArray(['up', 'down'] as const);
        const translateX =
          directionX === 'right' ? randomIntegerInclusive(3, 7) : randomIntegerInclusive(-7, -3);
        const translateY =
          directionY === 'up' ? randomIntegerInclusive(2, 6) : randomIntegerInclusive(-6, -2);

        const xCoords =
          directionX === 'right'
            ? randomUniqueIntegersInclusive(-5, 5 - translateX, 3)
            : randomUniqueIntegersInclusive(-5 - translateX, 5, 3);
        const yCoords =
          directionY === 'up'
            ? randomUniqueIntegersInclusive(-4, 4 - translateY, 3)
            : randomUniqueIntegersInclusive(-4 - translateY, 4, 3);

        const [coordinates1, coordinates2, coordinates3] = countRange(3).map(i => [
          xCoords[i],
          yCoords[i]
        ]);

        return {
          coordinates1,
          coordinates2,
          coordinates3,
          translateX,
          translateY,
          directionX,
          directionY
        };
      },
      ({ coordinates1, coordinates2, coordinates3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinates1[0], coordinates1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinates2[0], coordinates2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinates3[0], coordinates3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          -5 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          -4 <= newPoint1.y &&
          newPoint1.y <= 4 &&
          -5 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          -4 <= newPoint2.y &&
          newPoint2.y <= 4 &&
          -5 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          -4 <= newPoint3.y &&
          newPoint3.y <= 4 &&
          isValidTriangle(
            coordinates1 as [number, number],
            coordinates2 as [number, number],
            coordinates3 as [number, number],
            5
          )
        );
      }
    ),
  Component: ({
    question: {
      coordinates1,
      coordinates2,
      coordinates3,
      translateX,
      translateY,
      directionX,
      directionY
    },
    translate,
    displayMode
  }) => {
    const coords = [coordinates1, coordinates2, coordinates3];
    const start = coords.map(val => new Point2d(val[0], val[1]));
    const end = coords.map(val =>
      new Point2d(val[0], val[1]).add(new Point2d(translateX, translateY))
    );

    const title = translate.instructions.dragPointsToTranslateTriangleXSquaresDXAndYSquaresDY({
      x: Math.abs(translateX),
      dx: translate.directions[directionX](),
      y: Math.abs(translateY),
      dy: translate.directions[directionY]()
    });

    const pdfTitle = translate.instructions.drawPointsToTranslateTriangleXSquaresDXAndYSquaresDY({
      x: Math.abs(translateX),
      dx: translate.directions[directionX](),
      y: Math.abs(translateY),
      dy: translate.directions[directionY]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={start}
        end={end}
        svg="custom"
        gridChildren={
          <Svg height={1000}>
            <GridPolygon
              points={[
                coordinates1 as [number, number],
                coordinates2 as [number, number],
                coordinates3 as [number, number]
              ]}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
          </Svg>
        }
        customDraggable={(color: string, opacity?: number, index = 0) => ({
          component: (
            <Svg width={36} height={36}>
              <AssetSvg
                name="Coordinates/CirclePointCustomizable"
                width={36}
                svgProps={{ fill: opacity && opacity < 1 ? `${color}${opacity * 100}` : color }}
              />
              <SvgText y={26} x={18} textAnchor="middle" fontFamily="White_Rose_Noto-Regular">
                <TSpan
                  fontSize={displayMode === 'digital' ? 22 : 28}
                  fill={displayMode === 'digital' ? 'black' : 'white'}
                >
                  {['A', 'B', 'C'][index]}
                </TSpan>
              </SvgText>
            </Svg>
          ),
          width: 36,
          height: 36
        })}
        xMax={5}
        yMax={4}
        xMin={-5}
        yMin={-4}
        hideContinuationLines={false}
        hideAxis={false}
        anchorDX={18}
        anchorDY={18}
        questionHeight={900}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aFp',
  description: 'aFp',
  keywords: ['Coordinate', 'Rectangle'],
  schema: z.object({
    number1: z.number().int().min(-20).max(20).step(5),
    number2: z.number().int().min(-20).max(20).step(5),
    translateX: z.number().int().min(10).max(50).step(5),
    translateY: z.number().int().min(10).max(50).step(5),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(-20, 20, 5);
    const number2 = randomIntegerInclusiveStep(-20, 20, 5);

    const translateX = randomIntegerInclusiveStep(10, 50, 5);
    const translateY = randomIntegerInclusiveStep(10, 50, 5);

    const answerIndex = randomIntegerInclusive(0, 3);

    return { number1, number2, translateX, translateY, answerIndex };
  },
  Component: props => {
    const {
      question: { number1, number2, translateX, translateY, answerIndex },
      translate
    } = props;

    const number3 = number1 + translateX;
    const number4 = number2 + translateY;
    const number5 = number1 - translateX;
    const number6 = number2 - translateY;

    const statements = [
      {
        sentence: translate.answerSentences.xYCoordinate(
          number3.toLocaleString(),
          number4.toLocaleString()
        ),
        value: 'A'
      },
      {
        sentence: translate.answerSentences.xYCoordinate(
          number3.toLocaleString(),
          number6.toLocaleString()
        ),
        value: 'B'
      },
      {
        sentence: translate.answerSentences.xYCoordinate(
          number5.toLocaleString(),
          number4.toLocaleString()
        ),
        value: 'C'
      },
      {
        sentence: translate.answerSentences.xYCoordinate(
          number5.toLocaleString(),
          number6.toLocaleString()
        ),
        value: 'D'
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom({ number1, number2, translateX, translateY })
    });

    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (answerIndex) {
        case 0:
        case 1:
          return ['right', translateX];
        case 2:
        case 3:
          return ['left', translateX];
        default:
          throw new Error('Unreachable');
      }
    })();

    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (answerIndex) {
        case 1:
        case 3:
          return ['down', translateY];
        case 0:
        case 2:
          return ['up', translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.pointAHasCoordinatesHasBeenTranslatedXDXAndYDYSelectCoordAfter(
          {
            coord: translate.answerSentences.xYCoordinate(
              number1.toLocaleString(),
              number2.toLocaleString()
            ),
            x: xDistance.toLocaleString(),
            dx: translate.directions[xDirection](),
            y: yDistance.toLocaleString(),
            dy: translate.directions[yDirection]()
          }
        )}
        pdfTitle={translate.instructions.pointAHasCoordinatesHasBeenTranslatedXDXAndYDYCircleCoordAfter(
          {
            coord: translate.answerSentences.xYCoordinate(
              number1.toLocaleString(),
              number2.toLocaleString()
            ),
            x: xDistance.toLocaleString(),
            dx: translate.directions[xDirection](),
            y: yDistance.toLocaleString(),
            dy: translate.directions[yDirection]()
          }
        )}
        testCorrect={[['A', 'B', 'C', 'D'][answerIndex]]}
        numItems={4}
        renderItems={shuffledStatements.map(({ sentence, value }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {sentence}
            </Text>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Translations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
