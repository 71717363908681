import { useContext, useMemo } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import { colors } from 'common/src/theme/colors';
import { ScientificNotation } from 'common/src/utils/math';
import { arraysHaveSameContents, countRange, range } from 'common/src/utils/collections';
import Text from 'common/src/components/typography/Text';
import { withStateHOC } from '../../../../stateTree';
import { SetState, projectSetState } from '../../../../utils/react';
import { all, create, number } from 'mathjs';
import React from 'react';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { AssetSvg } from '../../../../assets/svg';
import { DisplayMode } from '../../../../contexts/displayMode';
import { InputCell, LabelCell, OverlayText, emptyRow } from './Cells';
import {
  findAllDivisionExchangeValues,
  numbersDoNotHaveDivisionExchange
} from '../../../../utils/exchanges';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export type DivisionProps = {
  /**
   * The number to be shown on the left-hand side of the division operation.
   */
  divisor: number;
  /**
   * Digits to be replaced with answer boxes for the divisor. Array of powers of ten.
   */
  divisorMissingDigits?: number[];
  /**
   * The number to be shown on the right-hand side of the division operation.
   */
  dividend: number;
  /**
   * Digits to be replaced with answer boxes for the dividend. Array of powers of ten.
   */
  dividendMissingDigits?: number[];
  /**
   * The number to be shown on the top of the division operation. Optional prop, defaults to a blank line.
   * quotient cannot have more digits than dividend.
   */
  quotient?: number;
  /**
   * Digits to be replaced with answer boxes for the quotient. Array of powers of ten.
   */
  quotientMissingDigits?: number[];
  /**
   * Number to be shown for the remainder. Optional prop, defaults to not showing at all.
   * If show, the remainder symbol will be shown to the right of the quotient, followed by the remainder given.
   */
  remainder?: number;
  /**
   * Digits to be replaced with answer boxes for the remainder. Array of powers of ten.
   */
  remainderMissingDigits?: number[];
  /**
   * Defaulted to false. When true the exchange values will be given and the row will be non-interactive.
   * When false they will be optional input boxes.
   * Does not make sense to use alongside the removeExchangeRow prop.
   */
  showNumberExchanges?: boolean;
  /**
   * Styles to be applied to the container of this component.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Removes extra cells from top/bottom rows and left/right columns around the operation.
   * Defaults to false.
   */
  removeExtraCells?: boolean;
  /**
   * Number of extra rows to be inserted beneath the equation compared to normal.
   * Expects a positive integer.
   * Optional prop, defaults to undefined, i.e. no extra rows.
   */
  extraRows?: number;
  /**
   * Prop to allow customised styling of font size and line height on the non-exchange label cells.
   */
  labelCellFontStyle?: {
    fontSize?: number;
    lineHeight?: number;
  };
  /**
   * Usable dimensions for the question.
   */
  dimens: Dimens;
  /**
   * When true hides the work out space for exchanges
   * Default: false
   * Note: If the calculation has no exchanges they will automatically not be shown.
   */
  noExchangeBoxes?: boolean;
  /**
   * The user answer.
   *
   * Each array corresponds to the user's answer for the missing digits of that number. The indices of the array
   * correspond to the digit at that position in the number. So the element at index 2 is the user's answer for the
   * hundreds, for example. Indices corresponding to digits that aren't missing are left as undefined, since the user
   * doesn't need to give an answer for these.
   *
   * The exchanges part of the userAnswer is to store values for the workout space.
   *
   * For quality of life, we support this array being initially empty or populated with undefined - it will be filled
   * in as the user starts giving their answers.
   */
  userAnswer?: {
    divisor: string[];
    dividend: string[];
    quotient: string[];
    remainder: string[];
    exchanges: string[];
  };
  setUserAnswer?: SetState<{
    divisor: string[];
    dividend: string[];
    quotient: string[];
    remainder: string[];
    exchanges: string[];
  }>;
};

/**
 * This component renders a division operation on a grid, made to represent grid paper.
 * This component is different to ColumnOperations, due to the nature of division being
 * markedly different to the other operations.
 * The number of columns of Cells is determined by the numbers.
 * There is a blank row and column along the top, bottom, left and right of the column operation.
 * A thick black line marking the 'bus stop' borders the left and top of the dividend.
 */
export default function Division({
  divisor,
  divisorMissingDigits = [],
  dividend,
  dividendMissingDigits = [],
  quotient,
  quotientMissingDigits = [],
  remainder,
  remainderMissingDigits = [],
  showNumberExchanges = false,
  containerStyle,
  removeExtraCells = false,
  extraRows = 0,
  labelCellFontStyle,
  dimens,
  noExchangeBoxes,
  userAnswer = { divisor: [], dividend: [], quotient: [], remainder: [], exchanges: [] },
  setUserAnswer = () => {
    /* do nothing */
  }
}: DivisionProps) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  // If the quotient prop was missing, don't show the quotient.
  const hideAnswerDigits = quotient === undefined;
  // ... but we still need to work it out in order to know how long it should be.
  quotient = quotient ?? number(math.evaluate(`${dividend} / ${divisor}`));

  // find exchanges
  const exchangeNumbers = findAllDivisionExchangeValues(dividend, divisor).map(val =>
    val === 0 ? '' : val
  );

  const onlyExchangeIsRemainder =
    exchangeNumbers
      .filter((_val, i) => i !== exchangeNumbers.length - 1)
      .every(val => val === '') && exchangeNumbers[exchangeNumbers.length - 1] !== '';

  const numbersExchange = numbersDoNotHaveDivisionExchange(dividend, divisor);
  const hasExchanges = !noExchangeBoxes && !numbersExchange && !onlyExchangeIsRemainder;
  // show this when we have interactive exchange boxes
  const showInformationString = hasExchanges && displayMode === 'digital' && !showNumberExchanges;

  // Get an array of digits to show for each number, starting with the lowest power and going up.
  const getDigits = (number: number, missingDigits: number[]): (number | '$ans')[] => {
    const minRange = Math.min(ScientificNotation.fromNumber(number).resolution, 0);
    const sci = ScientificNotation.fromNumber(number);
    // make sure we get all leading zeros in decimals i.e 0.0023
    const maxRange = sci.e < 0 ? 0 : sci.e;
    const digits = range(minRange, maxRange).map(pow =>
      missingDigits.includes(pow) ? '$ans' : sci.unsignedDigitAt(pow)
    );
    return digits;
  };

  const digits: Record<
    'divisor' | 'dividend' | 'quotient' | 'remainder',
    (number | '$ans' | '')[]
  > = {
    divisor: getDigits(divisor, divisorMissingDigits),
    dividend: getDigits(dividend, dividendMissingDigits),
    quotient: getDigits(quotient, quotientMissingDigits),
    remainder: remainder ? getDigits(remainder, remainderMissingDigits) : []
  };

  const totalDivisorDividendDigits = digits.divisor.length + digits.dividend.length;
  const remainderLength = remainder ? digits.remainder.length + 1 : 0;

  // The question will need one empty column to the left and one to the right, unless removeExtraCells prop passed.
  const gridColumns = removeExtraCells
    ? totalDivisorDividendDigits
    : totalDivisorDividendDigits + 2;
  const gridRows = removeExtraCells ? 2 + extraRows : 4 + extraRows;

  // Calculate the width/height of each Cell.
  const maxCellWidth = dimens.width / (gridColumns + remainderLength);
  const maxCellHeight = showInformationString
    ? dimens.height / gridRows - 40 // Remove 40px for the information string at the bottom.
    : dimens.height / gridRows;

  const answerBoxesShown =
    quotientMissingDigits.length > 0 ||
    divisorMissingDigits.length > 0 ||
    dividendMissingDigits.length > 0 ||
    remainderMissingDigits.length > 0;

  const cellDimens = answerBoxesShown
    ? // Enforce cellDimens to be at least 96 if answer boxes are shown:
      Math.max(Math.min(maxCellWidth, maxCellHeight), 96)
    : Math.min(maxCellWidth, maxCellHeight);

  const gridLineWidth = 1;
  const answerBoxBorderWidth = 3;

  const sharedStyles = useSharedStyles(gridLineWidth);

  // If we have at least one answer box missing in the divisor or dividend, all answer box Cells on PDFs should have a black border.
  const showBorderAroundBoxesOnPdf =
    (divisorMissingDigits && divisorMissingDigits.length > 0) ||
    (dividendMissingDigits && dividendMissingDigits.length > 0);

  // We should show exchanges on PDFs only if in mark-scheme, or if showNumberExchanges is passed explicitly for the question.
  const showPdfExchanges =
    displayMode === 'markscheme' || (displayMode === 'pdf' && showNumberExchanges);

  const digitCell = (
    digit: number | '' | '$ans',
    state: 'divisor' | 'dividend' | 'quotient' | 'remainder' | 'exchanges',
    digitIndex: number,
    colIndex: number,
    style?: StyleProp<ViewStyle>,
    mergeLeftBorder = false,
    mergeRightBorder = false,
    exchange?: string
  ) => {
    const numberUserAnswer = userAnswer[state];
    const numberSetUserAnswer = projectSetState(setUserAnswer, state);
    if (digit === '$ans' && colIndex) {
      return (
        <React.Fragment key={`${state}-digit-interactive-${digitIndex}`}>
          <LabelCell
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
            style={style}
          />
          <InputCell
            colIndex={colIndex}
            autoFocus={false}
            numberSetUserAnswer={numberSetUserAnswer}
            numberUserAnswer={numberUserAnswer}
            indexOfDigit={digitIndex}
            mergeLeftBorder={mergeLeftBorder}
            mergeRightBorder={mergeRightBorder}
            gridLineWidth={gridLineWidth}
            cellDimens={cellDimens}
            answerBoxBorderWidth={answerBoxBorderWidth}
            showBorderAroundBoxesOnPdf={showBorderAroundBoxesOnPdf}
          />
          {exchange && (
            <OverlayText
              cellDimens={cellDimens}
              text={exchange.toLocaleString()}
              colIndex={colIndex}
              gridLineWidth={gridLineWidth}
              position="topLeft"
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={`${state}-digit-${digitIndex}`}>
          <LabelCell
            cellDimens={cellDimens}
            gridLineWidth={gridLineWidth}
            answerBoxBorderWidth={answerBoxBorderWidth}
            character={digit.toLocaleString()}
            style={style}
            fontStyle={labelCellFontStyle}
          />
          {exchange && (
            <OverlayText
              cellDimens={cellDimens}
              text={exchange.toLocaleString()}
              colIndex={colIndex}
              gridLineWidth={gridLineWidth}
              position="topLeft"
            />
          )}
        </React.Fragment>
      );
    }
  };

  const interactiveExchangeCell = (
    dividend: number | '' | '$ans',
    digitIndex: number,
    colIndex: number,
    style?: StyleProp<ViewStyle>,
    mergeLeftBorder = false,
    mergeRightBorder = false
  ) => {
    const numberUserAnswer = userAnswer['exchanges'];
    const numberSetUserAnswer = projectSetState(setUserAnswer, 'exchanges');
    return (
      <React.Fragment key={`exchanges-interactive-${digitIndex}`}>
        <LabelCell
          cellDimens={cellDimens}
          gridLineWidth={gridLineWidth}
          answerBoxBorderWidth={answerBoxBorderWidth}
          style={style}
        />
        <InputCell
          colIndex={colIndex}
          autoFocus={false}
          numberSetUserAnswer={numberSetUserAnswer}
          numberUserAnswer={numberUserAnswer}
          indexOfDigit={digitIndex}
          mergeLeftBorder={mergeLeftBorder}
          mergeRightBorder={mergeRightBorder}
          gridLineWidth={gridLineWidth}
          cellDimens={cellDimens}
          isExchange
          answerBoxBorderWidth={answerBoxBorderWidth}
          showBorderAroundBoxesOnPdf={showBorderAroundBoxesOnPdf}
        />
        <OverlayText
          cellDimens={cellDimens}
          text={dividend.toLocaleString()}
          colIndex={colIndex}
          gridLineWidth={gridLineWidth}
        />
      </React.Fragment>
    );
  };

  const divisionRow = (
    columns: number,
    divisor: (number | '' | '$ans')[],
    dividend: (number | '' | '$ans')[],
    removeExtraCells: boolean
  ) => (
    <View style={sharedStyles.tableRow}>
      {countRange(columns).map(i => {
        const dividendStart = divisor.length + (removeExtraCells ? 0 : 1);
        const dividendEnd = dividendStart + dividend.length;
        // empty cell
        if (i === 0 && !removeExtraCells) {
          return (
            <LabelCell
              key={`empty-cell-division-${i}`}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
          // divisor
        } else if (i < dividendStart) {
          const currentIndex = divisor.length - i - (removeExtraCells ? 1 : 0);
          return digitCell(
            divisor[currentIndex],
            'divisor',
            currentIndex,
            i,
            currentIndex === 0 && sharedStyles.divisorEnd,
            divisor[currentIndex + 1] === '$ans',
            divisor[currentIndex - 1] === '$ans'
          );
          //dividend;
        } else if (i < dividendEnd) {
          const currentIndex = dividend.length - (i - dividendStart) - 1;
          const currentIndexOfExchange = i - dividendStart - 1;
          const exchangeValue =
            currentIndexOfExchange < 0 ? '' : exchangeNumbers[currentIndexOfExchange];

          if (hasExchanges && !showNumberExchanges) {
            return interactiveExchangeCell(
              dividend[currentIndex],
              currentIndex,
              i,
              currentIndex === dividend.length - 1
                ? { ...sharedStyles.dividendStart, ...sharedStyles.dividend }
                : sharedStyles.dividend,
              currentIndex !== dividend.length - 1,
              currentIndex !== 0
            );
          } else {
            return digitCell(
              dividend[currentIndex],
              'dividend',
              currentIndex,
              i,
              currentIndex === dividend.length - 1
                ? { ...sharedStyles.dividendStart, ...sharedStyles.dividend }
                : sharedStyles.dividend,
              dividend[currentIndex + 1] === '$ans',
              dividend[currentIndex - 1] === '$ans',
              showNumberExchanges || showPdfExchanges ? exchangeValue.toLocaleString() : undefined
            );
          }
        } else {
          return (
            <LabelCell
              key={`empty-cell-division-${i}`}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
        }
      })}
    </View>
  );

  const quotientRow = (
    columns: number,
    quotient: (number | '' | '$ans')[],
    divisorLength: number,
    dividendLength: number,
    removeExtraCells: boolean,
    remainder?: (number | '' | '$ans')[]
  ) => (
    <View style={sharedStyles.tableRow}>
      {countRange(columns).map(i => {
        const quotientStart = divisorLength + (removeExtraCells ? 0 : 1);
        const quotientEnd = quotientStart + dividendLength;
        // empty cell
        if (i < quotientStart) {
          return (
            <LabelCell
              key={`empty_cell_quotient_${i}`}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
        } // quotient
        else if (i < quotientEnd && !hideAnswerDigits) {
          const lengthDifference = dividendLength - quotient.length;
          if (i < quotientStart + lengthDifference) {
            return (
              <LabelCell
                key={`spareCell_quotient_${i}`}
                cellDimens={cellDimens}
                gridLineWidth={gridLineWidth}
                answerBoxBorderWidth={answerBoxBorderWidth}
                style={sharedStyles.quotient}
              />
            );
          }
          const currentIndex = quotient.length - (i - (quotientStart + lengthDifference)) - 1;
          return digitCell(
            quotient[currentIndex],
            'quotient',
            currentIndex,
            i,
            sharedStyles.quotient,
            quotient[currentIndex + 1] === '$ans',
            quotient[currentIndex - 1] === '$ans'
          );
        } //remainder
        else if (remainder && i >= quotientEnd) {
          const currentIndex = i - quotientEnd;
          if (currentIndex === 0) {
            return (
              <LabelCell
                key={`r_cell_${i}`}
                cellDimens={cellDimens}
                gridLineWidth={gridLineWidth}
                answerBoxBorderWidth={answerBoxBorderWidth}
                character="r"
              />
            );
          } else {
            return digitCell(
              remainder[currentIndex - 1],
              'remainder',
              currentIndex - 1,
              i,
              undefined,
              remainder[currentIndex - 1] === '$ans',
              remainder[currentIndex + 1] === '$ans'
            );
          }
        } else {
          return (
            <LabelCell
              key={`empty_cell_quotient_${i}`}
              cellDimens={cellDimens}
              gridLineWidth={gridLineWidth}
              answerBoxBorderWidth={answerBoxBorderWidth}
            />
          );
        }
      })}
    </View>
  );

  const length = gridColumns + remainderLength;

  return (
    <>
      <View style={[sharedStyles.tableContainer, containerStyle]}>
        {!removeExtraCells &&
          emptyRow(length, 'top', gridLineWidth, answerBoxBorderWidth, cellDimens)}
        {quotientRow(
          length,
          digits.quotient,
          digits.divisor.length,
          digits.dividend.length,
          removeExtraCells,
          arraysHaveSameContents(digits.remainder, []) ? undefined : digits.remainder
        )}
        {divisionRow(length, digits.divisor, digits.dividend, removeExtraCells)}
        {extraRows > 0 &&
          countRange(extraRows - 1).map(i =>
            emptyRow(length, `extra_${i}`, gridLineWidth, answerBoxBorderWidth, cellDimens)
          )}
        {!removeExtraCells &&
          emptyRow(length, `bottom`, gridLineWidth, answerBoxBorderWidth, cellDimens)}
      </View>
      {showInformationString && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <AssetSvg name={'Info'} width={25} height={26} />
          <Text variant="WRN400" style={{ fontSize: 22 }}>
            {translate.informationStrings.useWorkingSpaceToHelp()}
          </Text>
          <AssetSvg name={'CellTop'} width={34} height={34} />
        </View>
      )}
    </>
  );
}

const useSharedStyles = (gridLineWidth: number) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const minBorderWidth = 1 / scaleFactor;

  return useMemo(
    () =>
      StyleSheet.create({
        tableContainer: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderTopColor: colors.gridBlue,
          borderBottomColor: colors.gridBlue,
          borderStartColor: colors.gridBlue,
          borderEndColor: colors.gridBlue
        },
        tableRow: {
          flexDirection: 'row'
        },
        dividend: {
          borderTopColor: 'black',
          borderTopWidth: 1.5
        },
        quotient: {
          borderBottomColor: 'black',
          borderBottomWidth: 1.5
        },
        divisorEnd: {
          borderEndColor: 'black',
          borderEndWidth: 1.5
        },
        dividendStart: {
          borderStartColor: 'black',
          borderStartWidth: 1.5
        }
      }),
    [gridLineWidth, minBorderWidth]
  );
};

/** See {@link MissingDigitColumnOperations} */
export const DivisionWithState = withStateHOC(Division, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: {
    defaultState: {
      divisor: [],
      dividend: [],
      quotient: [],
      remainder: [],
      exchanges: []
    }
  }
});
