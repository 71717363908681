import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { SUB } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHm',
  description: 'aHm',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Millimetres', 'Subtract'],
  schema: z.object({
    leftNum: z.number().int().min(10).max(90).multipleOf(10),
    rightNum: z.number().int().min(1).max(9),
    answerPosition: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const leftNum = randomIntegerInclusiveStep(10, 90, 10);

    const rightNum = randomIntegerInclusive(1, 9);

    const answerPosition = getRandomFromArray(['left', 'right'] as const);

    return { leftNum, rightNum, answerPosition };
  },

  Component: ({ question: { leftNum, rightNum, answerPosition }, translate, displayMode }) => {
    const total = leftNum + rightNum;

    const strings = [
      [`${translate.units.numberOfMm(total)}`],
      answerPosition === 'left'
        ? ['', `${translate.units.numberOfMm(rightNum)}`]
        : [`${translate.units.numberOfMm(leftNum)}`, '']
    ];
    const numbers = [[total], [leftNum, rightNum]];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={`${translate.instructions.workOutTheMissingLengthOnTheBarModel()}<br/>${translate.instructions.giveYourAnswerInMillimetres()}`}
        testCorrect={[(answerPosition === 'left' ? leftNum : rightNum).toString()]}
        sentence={`<ans/> ${translate.units.mm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={total}
            dimens={dimens}
            oneFontSize
            maxFontSize={displayMode === 'digital' ? 32 : 40}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHn',
  description: 'aHn',
  keywords: [
    'Bar model',
    'Metric',
    'Converting units',
    'Length',
    'Centimetres',
    'Metres',
    'Subtract'
  ],
  schema: z.object({
    leftNum: z.number().int().min(10).max(90).multipleOf(10),
    answerPosition: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const leftNum = randomIntegerInclusiveStep(10, 90, 10);

    const answerPosition = getRandomFromArray(['left', 'right'] as const);

    return { leftNum, answerPosition };
  },

  Component: ({ question: { leftNum, answerPosition }, translate, displayMode }) => {
    const rightNum = 100 - leftNum;

    const strings = [
      [`${translate.units.numberOfM(1)}`],
      answerPosition === 'left'
        ? ['', `${translate.units.numberOfCm(rightNum)}`]
        : [`${translate.units.numberOfCm(leftNum)}`, '']
    ];
    const numbers = [[100], [leftNum, rightNum]];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={`${translate.instructions.workOutTheMissingLengthOnTheBarModel()}<br/>${translate.instructions.giveYourAnswerInCentimetres()}`}
        testCorrect={[(answerPosition === 'left' ? leftNum : rightNum).toString()]}
        sentence={`<ans/> ${translate.units.cm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={100}
            dimens={dimens}
            oneFontSize
            maxFontSize={displayMode === 'digital' ? 32 : 40}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHo',
  description: 'aHo',
  keywords: [
    'Metric',
    'Converting units',
    'Length',
    'Millimetres',
    'Centimetres',
    'Metres',
    'Subtraction'
  ],
  schema: z.object({
    numberA1: z.number().int().min(1).max(9),
    numberA2: z.number().int().min(0).max(8),
    numberA3: z.number().int().min(0).max(40).multipleOf(10),
    numberA4: z.number().int().min(10).max(50).multipleOf(10),
    numberB1: z.number().int().min(1).max(9),
    numberB2: z.number().int().min(0).max(8),
    numberB3: z.number().int().min(0).max(40).multipleOf(10),
    numberB4: z.number().int().min(10).max(50).multipleOf(10),
    numberC1: z.number().int().min(1).max(9),
    numberC2: z.number().int().min(0).max(8),
    numberC3: z.number().int().min(0).max(40).multipleOf(10),
    numberC4: z.number().int().min(10).max(50).multipleOf(10)
  }),
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1] = randomUniqueIntegersInclusive(1, 9, 3);

    const numberA2 = randomIntegerInclusive(0, 9 - numberA1);

    const numberB2 = randomIntegerInclusive(0, 9 - numberB1);

    const numberC2 = randomIntegerInclusive(0, 9 - numberC1);

    const [numberA3, numberB3, numberC3] = randomUniqueIntegersInclusiveStep(10, 40, 10, 3);

    const [numberA4, numberB4, numberC4] = randomUniqueIntegersInclusiveStep(10, 50, 10, 3);

    return {
      numberA1,
      numberA2,
      numberA3,
      numberA4,
      numberB1,
      numberB2,
      numberB3,
      numberB4,
      numberC1,
      numberC2,
      numberC3,
      numberC4
    };
  },

  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA3,
        numberA4,
        numberB1,
        numberB2,
        numberB3,
        numberB4,
        numberC1,
        numberC2,
        numberC3,
        numberC4
      },
      translate
    } = props;

    const subtrahendA = numberA1 + numberA3;
    const answerA = numberA2 + numberA4;
    const minuendA = subtrahendA + answerA;

    const subtrahendB = numberB2 + numberB4;
    const answerB = numberB1 + numberB3;
    const minuendB = subtrahendB + answerB;

    const subtrahendC = numberC2 + numberC4;
    const answerC = numberC1 + numberC3;
    const minuendC = subtrahendC + answerC;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={[[answerA.toString()], [answerB.toString()], [answerC.toString()]]}
        sentences={[
          `${translate.units.numberOfMm(minuendA)} ${SUB} ${translate.units.numberOfMm(
            subtrahendA
          )} = <ans/> ${translate.units.mm()}`,
          `${translate.units.numberOfCm(minuendB)} ${SUB} ${translate.units.numberOfCm(
            subtrahendB
          )} = <ans/> ${translate.units.cm()}`,
          `${translate.units.numberOfM(minuendC)} ${SUB} ${translate.units.numberOfM(
            subtrahendC
          )} = <ans/> ${translate.units.m()}`
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aHp',
  description: 'aHp',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres', 'Subtraction'],
  schema: z.object({
    answerA: z.number().int().min(1).max(9),
    numberOfMmA: z.number().int().min(10).max(90).multipleOf(10),
    numberOfMmB1: z.number().int().min(21).max(99),
    numberOfCmB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const answerA = randomIntegerInclusive(1, 9);
    const numberOfMmA = randomIntegerInclusiveStep(10, 90, 10);
    const numberOfMmB1 = randomIntegerInclusive(21, 99);
    const numberOfCmB = randomIntegerInclusive(1, 9, {
      constraint: x => x < numberOfMmB1 / 10
    });

    return { answerA, numberOfMmA, numberOfMmB1, numberOfCmB };
  },

  Component: props => {
    const {
      question: { answerA, numberOfMmA, numberOfMmB1, numberOfCmB },
      translate
    } = props;

    const numberOfCmA2 = numberOfMmA / 10;
    const numberOfCmA1 = answerA + numberOfCmA2;

    const numberOfMmB2 = numberOfCmB * 10;
    const answerB = numberOfMmB1 - numberOfMmB2;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={[
          [numberOfCmA2.toString(), answerA.toString()],
          [numberOfMmB2.toString(), answerB.toString()]
        ]}
        sentences={[
          // String A:
          `${translate.units.numberOfCm(numberOfCmA1)} ${SUB} ${translate.units.numberOfMm(
            numberOfMmA
          )} = ${translate.units.numberOfCm(
            numberOfCmA1
          )} ${SUB} <ans/> ${translate.units.cm()} = <ans/> ${translate.units.cm()}`,
          // String B:
          `${translate.units.numberOfMm(numberOfMmB1)} ${SUB} ${translate.units.numberOfCm(
            numberOfCmB
          )} = ${translate.units.numberOfMm(
            numberOfMmB1
          )} ${SUB} <ans/> ${translate.units.mm()} = <ans/> ${translate.units.mm()}`
        ]}
        actionPanelVariant="bottomTall"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHq',
  description: 'aHq',
  keywords: ['Metric', 'Converting units', 'Length', 'Centimetres', 'Metres', 'Subtraction'],
  schema: z
    .object({
      subtrahendA: z.number().int().min(10).max(90).multipleOf(10),
      minuendB: z.number().int().min(2).max(5),
      subtrahendB: z.number().int().min(10).max(490).multipleOf(10),
      subtrahendC: z.number().int().min(10).max(100).multipleOf(10),
      minuendD: z.number().int().min(2).max(5),
      subtrahendD: z.number().int().min(10).max(490).multipleOf(10)
    })
    .refine(
      val => val.minuendB * 100 > val.subtrahendB,
      'minuendB x 100 must be larger than subtrahendB.'
    )
    .refine(
      val => val.minuendD * 100 > val.subtrahendD,
      'minuendD x 100 must be larger than subtrahendD.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const subtrahendA = randomIntegerInclusiveStep(10, 90, 10);

    const minuendB = randomIntegerInclusive(2, 5);

    const subtrahendB = randomIntegerInclusiveStep(10, minuendB * 100 - 10, 10);

    const subtrahendC = randomIntegerInclusiveStep(10, 100, 10, {
      constraint: x => x !== subtrahendA // Prevent equations A and C from being the same.
    });

    const minuendD = randomIntegerInclusive(2, 5);

    const subtrahendD = randomIntegerInclusiveStep(10, minuendD * 100 - 10, 10, {
      constraint: x => x !== subtrahendB // Prevent equations B and D from being the same.
    });

    return { subtrahendA, minuendB, subtrahendB, subtrahendC, minuendD, subtrahendD };
  },

  Component: props => {
    const {
      question: { subtrahendA, minuendB, subtrahendB, subtrahendC, minuendD, subtrahendD },
      translate
    } = props;

    const differenceA = 100 - subtrahendA;

    const differenceB = minuendB * 100 - subtrahendB;

    const differenceC = 100 - subtrahendC;

    const differenceD = minuendD * 100 - subtrahendD;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={[
          [differenceA.toString()],
          [differenceB.toString()],
          [subtrahendC.toString()],
          [subtrahendD.toString()]
        ]}
        sentences={[
          translate.answerSentences.numMMinusNumCmEqualsAnsCm(1, subtrahendA),
          translate.answerSentences.numMMinusNumCmEqualsAnsCm(minuendB, subtrahendB),
          translate.answerSentences.numMMinusAnsCmEqualsNumCm(1, differenceC),
          translate.answerSentences.numMMinusAnsCmEqualsNumCm(minuendD, differenceD)
        ]}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aHr',
  description: 'aHr',
  keywords: ['Metric', 'Converting units', 'Length', 'Millimetres', 'Centimetres', 'Subtraction'],
  schema: z
    .object({
      subtrahendA: z.number().int().min(1).max(9),
      subtrahendB: z.number().int().min(1).max(9),
      minuendC: z.number().int().min(2).max(5),
      subtrahendC: z.number().int().min(10).max(40).multipleOf(10)
    })
    .refine(
      val => val.minuendC * 10 > val.subtrahendB,
      'minuendC x 10 must be larger than subtrahendC.'
    ),
  simpleGenerator: () => {
    const subtrahendA = randomIntegerInclusive(1, 9);

    const subtrahendB = randomIntegerInclusive(1, 9, {
      constraint: x => x !== subtrahendA // Prevent equations A and B from being the same.
    });

    const minuendC = randomIntegerInclusive(2, 5);

    const subtrahendC = randomIntegerInclusiveStep(10, minuendC * 10 - 10, 10);

    return { subtrahendA, subtrahendB, minuendC, subtrahendC };
  },

  Component: props => {
    const {
      question: { subtrahendA, subtrahendB, minuendC, subtrahendC },
      translate
    } = props;

    const differenceA = 100 - subtrahendA * 10;

    const differenceB = 100 - subtrahendB * 10;

    const differenceC = minuendC * 10 - subtrahendC;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={[[differenceA.toString()], [subtrahendB.toString()], [subtrahendC.toString()]]}
        sentences={[
          translate.answerSentences.numMmMinusNumCmEqualsAnsMm(100, subtrahendA),
          translate.answerSentences.numMmMinusAnsCmEqualsNumMm(100, differenceB),
          translate.answerSentences.numCmMinusAnsMmEqualsNumMm(minuendC, differenceC)
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractLengths',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
