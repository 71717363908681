import { ComponentProps, useCallback } from 'react';
import { GestureResponderEvent, Platform, StyleSheet } from 'react-native';
import Text from '../typography/Text';

/**
 * Underlined text, which when clicked on performs an action.
 *
 * Optimized slightly for web.
 */
export default function LinkText({
  style,
  action,
  ...props
}: Omit<ComponentProps<typeof Text>, 'accessibilityRole' | 'onPress' | 'onClick'> & {
  action: () => void;
}) {
  // Fiddle with the onPress callback to make web behaviour better. This is inspired by react-navigation's Link
  // component
  const onPress = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent> | GestureResponderEvent) => {
      let shouldHandle: boolean;

      if (Platform.OS !== 'web' || !e) {
        // Native or no event info - always handle
        shouldHandle = true;
      } else {
        // Web, only handle left clicks without modifier key

        // @ts-expect-error: these properties exist on web, but not in React Native
        const hasModifierKey = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey;
        // @ts-expect-error: these properties exist on web, but not in React Native
        const isLeftClick = e.button === null || e.button === 0;

        shouldHandle = !hasModifierKey && isLeftClick;
      }

      if (shouldHandle) {
        action();
      }
    },
    [action]
  );

  return (
    <Text style={[styles.linkText, style]} accessibilityRole="link" onPress={onPress} {...props} />
  );
}

const styles = StyleSheet.create({
  linkText: {
    textDecorationLine: 'underline'
  }
});
