import { type ComponentProps, useCallback, useState } from 'react';
import {
  type LayoutChangeEvent,
  StyleSheet,
  View,
  type StyleProp,
  type ViewStyle
} from 'react-native';
import { type Dimens } from 'common/src/theme/scaling';
import {
  type ElementOrRenderFunction,
  resolveElementOrRenderFunction
} from 'common/src/utils/react';

type MeasureViewProps = Omit<ComponentProps<typeof View>, 'children'> & {
  /**
   * Style for the outer container - use this for margins, borders, padding and positioning.
   * Use `style` for managing children, such as flexDirection.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Style for the inner contianer, direct parent of `children`.
   * This is what actually gets measured, with those dimensions passed to the children, so avoid adding margins,
   * paddings and borders to this as that would make the dimensions unrepresentative of the actual space available.
   */
  style?: StyleProp<ViewStyle>;
  children: ElementOrRenderFunction<Dimens>;
};

/**
 * Like {@link View}, but it measures its dimensions and passes that information to its children.
 *
 * Unlike View, if you pass in no style, it assumes: fill remaining space in parent, center children. If you pass in
 * any other style, it uses what you passed in. This is simply because we almost always want this style.
 *
 * Limitations:
 * - The first render will not render the children, as this is just used for measuring dimensions.
 *   - This is noticeable on mobile devices - the children pop in after the screen is initially rendered.
 * - Be careful of infinite loops. E.g. this view's style makes its dimensions depend on its children's dimensions,
 *   and the children's dimensions depend on this view's dimensions.
 */
export function MeasureView({
  children,
  onLayout: onLayoutProp,
  containerStyle,
  style,
  ...props
}: MeasureViewProps) {
  const [dimens, setDimens] = useState<Dimens | null>(null);

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      const { width, height } = e.nativeEvent.layout;
      // Only update if something has changed and both width and height are non-zero.
      if (width !== 0 && height !== 0) {
        setDimens(old => (width !== old?.width || height !== old.height ? { width, height } : old));
      }
      onLayoutProp && onLayoutProp(e);
    },
    [onLayoutProp]
  );

  return (
    <View style={[styles.container, containerStyle]} {...props}>
      <View onLayout={onLayout} style={[styles.children, style]}>
        {
          // Once the view's dimensions have been measured, render the children in it.
          dimens !== null && resolveElementOrRenderFunction(children, dimens)
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch'
  },
  children: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
