import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { arrayHasNoDuplicates, sortNumberArray } from 'common/src/utils/collections';
import {
  numberToRomanNumerals,
  RomanNumeral,
  RomanNumeralArray,
  romanNumeralsToNumber,
  RomanNumeralValueArray,
  RomanNumeralValueSchema
} from 'common/src/components/question/representations/RomanNumerals';
import {
  logUniformSample,
  randomUniqueIntegersInclusive,
  getRandomFromArray,
  shuffle,
  randomIntegerInclusive,
  seededRandom
} from 'common/src/utils/random';
import AutoScaleText from 'common/src/components/typography/AutoScaleText';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { numberEnum } from 'common/src/utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ada',
  description: 'ada',
  keywords: ['Place value', 'Roman', 'Match', 'Thousand', '1,000'],
  schema: z
    .object({
      numeralToExclude1: z.enum(['I', 'V', 'X', 'L', 'C', 'D', 'M']),
      numeralToExclude2: z.enum(['I', 'V', 'X', 'L', 'C', 'D', 'M']),
      numeralToExclude3: z.enum(['I', 'V', 'X', 'L', 'C', 'D', 'M'])
    })
    .refine(
      val =>
        arrayHasNoDuplicates([val.numeralToExclude1, val.numeralToExclude2, val.numeralToExclude3]),
      'Numerals to exclude must all be different.'
    ),
  simpleGenerator: () => {
    const numeralToExclude1 = getRandomFromArray(RomanNumeralArray) as RomanNumeral;
    const numeralToExclude2 = getRandomFromArray(
      RomanNumeralArray.filter(numeral => numeral !== numeralToExclude1)
    ) as RomanNumeral;
    const numeralToExclude3 = getRandomFromArray(
      RomanNumeralArray.filter(
        numeral => numeral !== numeralToExclude1 && numeral !== numeralToExclude2
      )
    ) as RomanNumeral;

    return { numeralToExclude1, numeralToExclude2, numeralToExclude3 };
  },
  Component: props => {
    const {
      question: { numeralToExclude1, numeralToExclude2, numeralToExclude3 },
      translate
    } = props;

    const items = RomanNumeralArray.filter(
      numeral =>
        numeral !== numeralToExclude1 &&
        numeral !== numeralToExclude2 &&
        numeral !== numeralToExclude3
    ).map(romanNumeralsToNumber) as number[];

    // Randomly order these statements
    const statements = shuffle(
      RomanNumeralArray.filter(
        numeral =>
          numeral !== numeralToExclude1 &&
          numeral !== numeralToExclude2 &&
          numeral !== numeralToExclude3
      ).map(numeral => {
        return {
          numeral: numeral,
          number: romanNumeralsToNumber(numeral)!
        };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchNumbersToRomanNumerals()}
        pdfTitle={translate.instructions.matchNumbersToRomanNumerals()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentencesTextVariant="roman"
        sentences={statements.map(statement => `${statement.numeral} <ans/>`)}
        testCorrect={statements.map(statement => [statement.number])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'adb',
  description: 'adb',
  keywords: ['Place value', 'Roman', 'Order', 'Thousand', '1,000'],
  schema: z.object({
    numbers: RomanNumeralValueSchema.array()
      .length(7)
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Bias smaller numbers more than larger ones.
    const numbers = shuffle(RomanNumeralValueArray);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { numbers, ordering };
  },
  Component: props => {
    const {
      question: { numbers, ordering },
      translate
    } = props;
    const correctOrder = sortNumberArray(numbers, ordering);

    const numeralsArray = numbers.map(numberToRomanNumerals);

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.instructions.dragRomanNumeralCardsPlaceInAscendingOrder()
            : translate.instructions.dragRomanNumeralCardsPlaceInDescendingOrder()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.instructions.placeCardsAscendingOrder()
            : translate.instructions.placeCardsDescendingOrder()
        }
        testCorrect={correctOrder}
        items={numbers.map((number, index) => ({
          value: number,
          component: numeralsArray[index]
        }))}
        itemsTextVariant="roman"
        pdfItemVariant="pdfSquare"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'adc',
  description: 'adc',
  keywords: ['Place value', 'Roman', 'Order', 'Thousand', '1,000'],
  schema: z.object({
    number: numberEnum([
      4, 6, 9, 11, 15, 20, 40, 51, 55, 60, 90, 101, 105, 110, 150, 200, 400, 501, 505, 510, 550,
      900, 1001, 1005, 1010, 1050, 1100, 1500, 2000
    ])
  }),
  simpleGenerator: () => {
    // All these numbers can be expressed with 2 roman numerals, from IV to MM:
    const number = getRandomFromArray([
      4, 6, 9, 11, 15, 20, 40, 51, 55, 60, 90, 101, 105, 110, 150, 200, 400, 501, 505, 510, 550,
      900, 1001, 1005, 1010, 1050, 1100, 1500, 2000
    ] as const);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const romanNumerals = numberToRomanNumerals(number).toString().split('');

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragRomanNumeralCardsToMakeNum(number)}
        pdfTitle={translate.instructions.useRomanNumeralsToMakeNumEachRomanNumeralCanBeUsedMoreThanOnce(
          number
        )}
        sentence={'<ans/> '.repeat(romanNumerals.length)}
        items={['I', 'V', 'X', 'L', 'C', 'D', 'M']}
        itemsTextVariant="roman"
        moveOrCopy="copy"
        testCorrect={romanNumerals}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'add',
  description: 'add',
  keywords: ['Place value', 'Roman', 'Match', 'Thousand', '1,000'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(1000),
      number2: z.number().int().min(2).max(1000),
      number3: z.number().int().min(2).max(1000),
      number4: z.number().int().min(2).max(1000)
    })
    .refine(
      val => arrayHasNoDuplicates([val.number1, val.number2, val.number3, val.number4]),
      'All numbers must be different.'
    ),
  simpleGenerator: () => {
    const [number1, number2, number3, number4] = randomUniqueIntegersInclusive(2, 1000, 4);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const items = [number1, number2, number3, number4];

    // Randomly order these statements
    const statements = shuffle(
      items.map(number => ({
        numeral: numberToRomanNumerals(number),
        number
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchNumbersToRomanNumerals()}
        pdfTitle={translate.instructions.matchNumbersToRomanNumerals()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentencesTextVariant="roman"
        sentences={statements.map(statement => `${statement.numeral} <ans/>`)}
        testCorrect={statements.map(statement => [statement.number])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ade',
  description: 'ade',
  keywords: ['Place value', 'Roman', 'Order', 'Thousand', '1,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(1000)
      .array()
      .length(5)
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Bias smaller numbers more than larger ones.
    const numbers = randomUniqueIntegersInclusive(1, 1000, 5, {
      sample: logUniformSample,
      // Restrict number of characters in Roman Numeral to less than 7 characters:
      constraint: x => numberToRomanNumerals(x).length < 7
    });
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { numbers, ordering };
  },
  Component: props => {
    const {
      question: { numbers, ordering },
      translate
    } = props;
    const correctOrder = sortNumberArray(numbers, ordering);

    const numeralsArray = numbers.map(numberToRomanNumerals);

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.instructions.dragRomanNumeralCardsPlaceInAscendingOrder()
            : translate.instructions.dragRomanNumeralCardsPlaceInDescendingOrder()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.instructions.placeCardsAscendingOrder()
            : translate.instructions.placeCardsDescendingOrder()
        }
        testCorrect={correctOrder}
        items={numbers.map((number, index) => ({
          value: number,
          component: numeralsArray[index]
        }))}
        itemsTextVariant="roman"
        itemsMaxLines={1}
        itemsLetterEmWidth={1}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'adf',
  description: 'adf',
  keywords: ['Place value', 'Roman', 'Thousand', '1,000'],
  schema: z.object({
    number1: z.number().int().min(2).max(1000)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 1000);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheNumberShownByTheRomanNumerals()}
        testCorrect={[number1.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        {...props}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }
            ]}
          >
            <View style={[{ borderColor: 'black', borderWidth: 2, borderRadius: 12 }]}>
              <AutoScaleText
                variant="roman"
                containerStyle={{ width: dimens.width / 2, height: dimens.height / 2.5 }}
                minFontSize={22}
                maxFontSize={40}
                group={0}
                maxLines={1}
              >
                {numberToRomanNumerals(number1)}
              </AutoScaleText>
            </View>
          </View>
        )}
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'RomanNumeralsTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
