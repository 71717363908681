import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { isInRange } from '../../../../utils/matchers';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { fruitAsWord, getFruitSvgs } from '../../../../utils/fruits';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import {
  getAnimalSvgs,
  getRandomUniqueKs1Animals,
  ks1AnimalAsWord,
  ks1AnimalNamesSchema
} from '../../../../utils/animals';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bbe',
  description: 'bbe',
  keywords: ['Add', 'Altogether', 'Ten frame'],
  questionHeight: 1100,
  schema: z
    .object({
      num1: z.number().int().min(1).max(9),
      num2: z.number().int().min(1).max(9),
      items: z.discriminatedUnion('group', [
        z.object({
          group: z.literal('animals'),
          variations: z.array(ks1AnimalNamesSchema).length(2)
        }),
        z.object({
          group: z.literal('fruits'),
          variations: z.array(z.enum(['Apple', 'Orange', 'Banana', 'Pear'])).length(2)
        })
      ])
    })
    .refine(
      ({ num1, num2 }) => isInRange(2, 10)(num1 + num2),
      'total of num1 and num2 should be between 2 and 10'
    ),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 9);
    const num2 = randomIntegerInclusive(1, 9, { constraint: x => isInRange(2, 10)(x + num1) });

    const group = getRandomFromArray(['animals', 'fruits'] as const);
    const items =
      group === 'animals'
        ? { group, variations: getRandomUniqueKs1Animals(2) }
        : {
            group,
            variations: getRandomSubArrayFromArray(
              ['Apple', 'Orange', 'Banana', 'Pear'] as const,
              2
            )
          };

    return {
      num1,
      num2,
      items
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { num1, num2, items } = question;
    let svg1, svg2, var1, var2, sentence3;

    if (items.group === 'animals') {
      const [variation1, variation2] = items.variations;
      svg1 = getAnimalSvgs(variation1);
      svg2 = getAnimalSvgs(variation2);
      var1 = ks1AnimalAsWord(variation1, translate, num1);
      var2 = ks1AnimalAsWord(variation2, translate, num2);
      sentence3 = 'thereAreAnsAnimalsAltogether' as const;
    } else {
      const [variation1, variation2] = items.variations;
      svg1 = getFruitSvgs(variation1);
      svg2 = getFruitSvgs(variation2);
      var1 = fruitAsWord(variation1, translate, num1 > 1);
      var2 = fruitAsWord(variation2, translate, num2 > 1);
      sentence3 = 'thereAreAnsPiecesOfFruitAltogether' as const;
    }

    const var1Sentence = num1 > 1 ? 'thereAreAnsX' : 'thereIsAnsX';
    const var2Sentence = num2 > 1 ? 'thereAreAnsX' : 'thereIsAnsX';

    const counterSize = displayMode === 'digital' ? 64 : 100;
    return (
      <QF1ContentAndSentences
        questionHeight={1100}
        title={translate.ks1Instructions.completeTheSentences()}
        Content={
          <TenFrameLayout
            size={displayMode === 'digital' ? 'medium' : 'xlarge'}
            items={[
              ...filledArray(
                <AssetSvg name={svg1} height={counterSize} width={counterSize} />,
                num1
              ),
              ...filledArray(
                <AssetSvg name={svg2} height={counterSize} width={counterSize} />,
                num2
              )
            ]}
          />
        }
        sentences={[
          translate.ks1AnswerSentences[var1Sentence](var1),
          translate.ks1AnswerSentences[var2Sentence](var2),
          translate.ks1AnswerSentences[sentence3]()
        ]}
        testCorrect={[[num1.toString()], [num2.toString()], [(num1 + num2).toString()]]}
        pdfDirection="column"
      />
    );
  }
});

const groups = ['sweets', 'apples', 'cubes', 'counters', 'bears', 'animals'] as const;
const groupSchema = z.enum(groups);
type Group = (typeof groups)[number];

const getGroup = () => getRandomFromArray(groups);

const getGroupAsString = (group: Group, translate: TranslationFunctions) => {
  switch (group) {
    case 'cubes':
      return translate.shapes.cubes(2);
    case 'animals':
    case 'bears':
      return translate.misc[group](2);
    case 'apples':
      return fruitAsWord('Apple', translate, true);
    default: {
      return translate.items[group](2);
    }
  }
};

const svgList: Record<Group, SvgName[]> = {
  sweets: ['Sweets/SweetGreen', 'Sweets/SweetRed'],
  apples: ['Array_objects/AppleGreen', 'Array_objects/AppleRed'],
  cubes: (['green', 'red', 'blue', 'yellow'] as const).map(
    color => `Cubes_blank/Coloured_cube_unlabelled_${color}` as const
  ),
  counters: (['red', 'yellow', 'blue', 'green'] as const).map(
    color => `Circles/circle_${color}` as const
  ),
  bears: (['orange', 'yellow', 'red', 'green', 'purple'] as const).map(
    color => `Gummy_bears/gummybear_${color}` as const
  ),
  animals: ['Cat', 'Frog', 'bird_blue', 'Sheep', 'Owl_bird', 'Horse', 'Lion']
};

const Question2 = newQuestionContent({
  uid: 'bbf',
  description: 'bbf',
  keywords: ['Add', 'Altogether', 'Ten frame'],
  schema: z
    .object({
      group: groupSchema,
      variationIndices: z.array(z.number().int().min(0).max(6)).length(2),
      num1: z.number().int().min(1).max(9),
      num2: z.number().int().min(1).max(9)
    })
    .refine(
      ({ num1, num2 }) => isInRange(2, 10)(num1 + num2),
      'total of num1 and num2 should be between 3 and 9'
    ),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 9);
    const num2 = randomIntegerInclusive(1, 9, { constraint: x => isInRange(2, 10)(x + num1) });
    const group = getGroup();

    const variationIndices = randomUniqueIntegersInclusive(0, svgList[group].length - 1, 2);

    return {
      num1,
      num2,
      group,
      variationIndices
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, group, variationIndices } = question;

    const groupAsString = getGroupAsString(group, translate);

    const [var1, var2] = variationIndices;
    const svgNameA = svgList[group][var1];
    const svgNameB = svgList[group][var2];

    const counterSize = 100;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyXAltogether(groupAsString)}
        Content={
          <TenFrameLayout
            size="xlarge"
            items={[
              ...filledArray(
                <AssetSvg name={svgNameA} height={counterSize} width={counterSize} />,
                num1
              ),
              ...filledArray(
                <AssetSvg name={svgNameB} height={counterSize} width={counterSize} />,
                num2
              )
            ]}
          />
        }
        sentence="<ans/>"
        testCorrect={[(num1 + num2).toString()]}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbg',
  description: 'bbg',
  keywords: ['Add', 'Altogether', 'Word problem'],
  schema: z
    .object({
      group: groupSchema,
      variationIndices: z.array(z.number().int().min(0).max(6)).length(2),
      numA: z.number().int().min(1).max(9),
      numB: z.number().int().min(1).max(9),
      options: z
        .array(z.number().int().min(1).max(10))
        .length(4)
        .refine(x => arrayHasNoDuplicates(x), 'Options should be different')
    })
    .refine(({ numA, numB }) => isInRange(3, 10)(numA + numB), 'Total should be between 3 and 10'),
  simpleGenerator: () => {
    const group = getGroup();
    const variationIndices = randomUniqueIntegersInclusive(0, svgList[group].length - 1, 2);
    const numA = randomIntegerInclusive(1, 9);
    const numB = randomIntegerInclusive(1, 9, { constraint: x => isInRange(3, 10)(numA + x) });
    const total = numA + numB;

    const possibleOptions = [
      ...new Set([
        total,
        total - 1,
        ...(total + 1 < 10 ? [total + 1, getRandomFromArray([numA, numB])] : [numA, numB])
      ])
    ];

    const extraOptions = randomUniqueIntegersInclusive(1, 10, 4 - possibleOptions.length, {
      constraint: x => arrayHasNoDuplicates([...possibleOptions, x])
    });

    return {
      numA,
      numB,
      group,
      variationIndices,
      options: shuffle([...possibleOptions, ...extraOptions])
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { numA, numB, group, variationIndices, options } = question;
    const total = numA + numB;
    const [var1, var2] = variationIndices;
    const svgNameA = svgList[group][var1];
    const svgNameB = svgList[group][var2];

    const items = options.map(n => ({
      value: n,
      component: <Text variant="WRN400">{n.toLocaleString()}</Text>
    }));

    const groupAsString = getGroupAsString(group, translate);

    const assetDimen = displayMode === 'digital' ? 80 : 100;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={`${translate.ks1Instructions.howManyXAltogether(
          groupAsString
        )}<br/>${translate.ks1Instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.ks1Instructions.howManyXAltogether(
          groupAsString
        )}<br/>${translate.ks1PDFInstructions.tickYourAnswer()}`}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              gap: 16,
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <View style={{ flexDirection: 'row', gap: 8, paddingVertical: 16 }}>
              {countRange(numA).map(i => (
                <AssetSvg key={`${i}_A`} name={svgNameA} height={assetDimen} width={assetDimen} />
              ))}
            </View>
            <View style={{ flexDirection: 'row', gap: 8, paddingVertical: 16 }}>
              {countRange(numB).map(i => (
                <AssetSvg key={`${i}_B`} name={svgNameB} height={assetDimen} width={assetDimen} />
              ))}
            </View>
          </View>
        )}
        renderItems={items}
        numItems={4}
        itemLayout={displayMode === 'digital' ? 'grid' : 'row'}
        testCorrect={[total]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AdditionAddTogether',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
