import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { DIV } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersDoNotHaveDivisionExchange } from '../../../../utils/exchanges';
import { create, all, number } from 'mathjs';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { getImagesByAmount } from '../../../../utils/images';
import { range } from '../../../../utils/collections';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

import { View } from 'react-native';
import RowOfImages from '../../../../components/molecules/RowOfImages';
import { colors } from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getGenderFromName, getRandomName, nameSchema } from '../../../../utils/names';

const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aF6',
  description: 'aF6',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange'],
  questionHeight: 1200,
  schema: z
    .object({
      divisor: numberEnum([2, 3, 4]),
      tens: z.number().int().min(4).max(9),
      ones: z.number().int().min(2).max(9)
    })
    .refine(
      ({ tens, ones }) => tens < 10 && ones < 10,
      'tens and ones multiplied by divisor should be less than 10'
    )
    .refine(
      ({ tens, ones }) => tens + ones <= 12,
      'tens + ones should be less than or equal to 12'
    ),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([2, 3, 4] as const);

    const { tens, ones } = rejectionSample(
      () => {
        const tensMultiplier = getRandomFromArray(
          divisor === 2 ? [2, 3, 4] : divisor === 3 ? [2, 3] : [2]
        );
        const tens = tensMultiplier * divisor;
        const onesMultiplier = getRandomFromArray(
          divisor === 2 ? [1, 2, 3, 4] : divisor === 3 ? [1, 2, 3] : [1, 2]
        );
        const ones = onesMultiplier * divisor;
        return { tens, ones };
      },
      val => val.ones + val.tens <= 12
    );

    return { divisor, tens, ones };
  },
  Component: ({ question: { divisor, tens, ones }, translate }) => {
    const dividend = tens * 10 + ones;
    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeNumberSentences()}
        pdfDirection="column"
        questionHeight={1200}
        sentences={[
          `${tens.toLocaleString()} ${translate.powersOfTen.tens(
            tens
          )} ${DIV} ${divisor.toLocaleString()} = <ans/> ${translate.powersOfTen.tens(
            tens / divisor
          )}`,
          `${ones.toLocaleString()} ${translate.powersOfTen.ones(
            ones
          )} ${DIV} ${divisor.toLocaleString()} = <ans/> ${translate.powersOfTen.ones(
            ones / divisor
          )}`,
          `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans />`
        ]}
        testCorrect={[
          [(tens / divisor).toString()],
          [(ones / divisor).toString()],
          [(dividend / divisor).toString()]
        ]}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <ArrayOfObjects
                dimens={{ height: dimens.height, width: (dimens.width * tens) / (tens + ones) }}
                rows={1}
                columns={tens}
                customImage={
                  <AssetSvg
                    name="Base_Ten/Crayons10"
                    height={dimens.height}
                    width={dimens.width / (tens + ones + 1)}
                  />
                }
                rowStyle={{ columnGap: 4 }}
              />
              <ArrayOfObjects
                dimens={{ height: dimens.height, width: (dimens.width * ones) / (tens + ones) }}
                rows={1}
                columns={ones}
                customImage={
                  <AssetSvg
                    name="Base_Ten/Crayons1_blue"
                    height={dimens.height}
                    width={dimens.width / (tens + ones + 1)}
                  />
                }
                rowStyle={{ columnGap: 4 }}
              />
            </View>
            // <BaseTenRepresentation
            //   b10Rep={{
            //     variant: 'Crayons',
            //     numbers: numberToBase10Object(dividend),
            //     arrangement: 'ltr'
            //   }}
            //   usableWidth={dimens.width}
            //   usableHeight={dimens.height}
            //   containerStyle={{ alignItems: 'center' }}
            // />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aF7',
  description: 'aF7',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange', 'Place value chart'],
  schema: z
    .object({
      divisor: numberEnum([2, 3, 4]),
      dividend: z.number().int().min(11).max(99),
      wrongDividend: z.number().int().min(11).max(99)
    })
    .refine(
      ({ divisor, dividend }) => numbersDoNotHaveDivisionExchange(dividend, divisor),
      'Numbers should not exchange'
    ),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([2, 3, 4] as const);

    const dividend = randomIntegerInclusive(11, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisor) && x % 10 !== 0
    });

    const { wrongDividend } = rejectionSample(
      () => {
        const diff = getRandomFromArray([
          divisor, // 1 counter
          divisor * 10, // 10 counter
          divisor * 10 + divisor // 1 and 10 counter
        ] as const);
        const sign = getRandomFromArray(['+', '-'] as const);

        const wrongDividend = number(math.evaluate(`${dividend} ${sign} ${diff}`));

        return { wrongDividend };
      },

      ({ wrongDividend }) => wrongDividend > 10 && wrongDividend < 100 && wrongDividend % 10 !== 0
    );

    return { divisor, dividend, wrongDividend };
  },
  Component: ({ question, translate, displayMode }) => {
    const { divisor, dividend, wrongDividend } = question;

    const options = shuffle([dividend, wrongDividend], { random: seededRandom(question) });

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectWhichPVCRepresentsXDivY(dividend, divisor)}
        testCorrect={[dividend]}
        numItems={2}
        renderItems={({ dimens }) => {
          return options.map((value, idx) => {
            const quotient = value / divisor;
            const tensPerRow = quotient / 10;
            const onesPerRow = quotient % 10;
            return {
              value,
              component: (
                <CustomizableTable
                  cellHeaders={[
                    {
                      label: translate.keywords.Tens(),
                      containerStyle: {
                        backgroundColor:
                          displayMode === 'digital'
                            ? placeValueColumnInfo[1].color
                            : colors.greys100
                      },
                      textStyle: displayMode === 'digital' && {
                        color: placeValueColumnInfo[1].textColor
                      }
                    },
                    {
                      label: translate.keywords.Ones(),
                      containerStyle: {
                        backgroundColor:
                          displayMode === 'digital'
                            ? placeValueColumnInfo[0].color
                            : colors.greys100
                      },
                      textStyle: displayMode === 'digital' && {
                        color: placeValueColumnInfo[0].textColor
                      }
                    }
                  ]}
                  tableData={range(1, divisor).map(_ => [
                    <RowOfImages
                      key={idx}
                      containerStyle={rowContainerDimens}
                      style={{ gap: 4 }}
                      images={getImagesByAmount('Place_value/10', tensPerRow)}
                    />,
                    <RowOfImages
                      key={idx}
                      containerStyle={rowContainerDimens}
                      style={{ gap: 4 }}
                      images={getImagesByAmount('Place_value/1', onesPerRow)}
                    />
                  ])}
                  tableStyle={{ width: dimens.width * 0.9 }}
                  tableFontSize={displayMode === 'digital' ? 24 : 50}
                />
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aF8',
  description: 'aF8',
  keywords: ['Partition', 'Two digit', 'Part-whole model'],
  schema: z.object({
    divisor: numberEnum([2, 3, 4, 5, 8]),
    quotientTens: z.number().int().min(10).max(40).step(10),
    quotientOnes: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([2, 3, 4, 5, 8] as const);

    const { quotientTens, quotientOnes } = rejectionSample(
      () => {
        const quotientOnes = randomIntegerInclusive(1, 4);
        const quotientTens = randomIntegerInclusiveStep(10, 40, 10);

        return { quotientTens, quotientOnes };
      },
      // Only permit them if the dividend is less than 100
      ({ quotientTens, quotientOnes }) => (quotientTens + quotientOnes) * divisor < 100
    );

    return {
      divisor,
      quotientTens,
      quotientOnes
    };
  },
  Component: props => {
    const {
      question: { divisor, quotientTens, quotientOnes },
      translate,
      displayMode
    } = props;

    const quotient = quotientTens + quotientOnes;
    const dividendOnes = quotientOnes * divisor;
    const dividendTens = quotientTens * divisor;
    const dividend = dividendTens + dividendOnes;

    const sentences = [
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheDivisions()}
        testCorrect={[[quotientOnes.toString()], [quotientTens.toString()], [quotient.toString()]]}
        sentences={sentences}
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        style={{ alignItems: 'flex-end', right: 80 }}
        pdfSentenceStyle={
          displayMode !== 'digital' && {
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            rowGap: 200,
            right: 400
          }
        }
        Content={({ dimens }) => (
          <View style={{ position: 'absolute', top: 10 }}>
            <PartWholeModel
              top={dividend}
              partition={[dividendOnes, dividendTens]}
              dimens={{
                width: displayMode === 'digital' ? dimens.width / 1.3 : dimens.width / 2,
                height: dimens.height / 1.5
              }}
              variation="leftRight"
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aF9',
  description: 'aF9',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange'],
  schema: z.object({
    divisor: numberEnum([2, 3, 4, 5, 8]),
    quotentTens: z.number().int().min(1).max(9),
    quotentOnes: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([2, 3, 4, 5, 8] as const);

    const quotentTens = randomIntegerInclusive(1, 9, {
      constraint: x => x * divisor < 10
    });

    const quotentOnes = randomIntegerInclusive(1, 9, {
      constraint: x => x * divisor < 10
    });
    return { divisor, quotentTens, quotentOnes };
  },
  Component: ({ question: { divisor, quotentTens, quotentOnes }, translate }) => {
    const dividendTens = quotentTens * divisor;
    const dividendOnes = quotentOnes * divisor;
    const dividend = dividendTens * 10 + dividendOnes;
    const ans = dividend / divisor;

    const sentences = [
      `${dividendTens} ${translate.powersOfTen.tens(
        dividendTens
      )} ${DIV} ${divisor} = <ans/> ${translate.powersOfTen.tens(quotentTens)}`,
      `${dividendOnes} ${translate.powersOfTen.ones(
        dividendOnes
      )} ${DIV} ${divisor} = <ans/> ${translate.powersOfTen.ones(quotentOnes)}`,
      `${dividend} ${DIV} ${divisor} = <ans/>`
    ];
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentencesToSolveXDivY(dividend, divisor)}
        sentences={sentences}
        testCorrect={[[quotentTens.toString()], [quotentOnes.toString()], [ans.toString()]]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGa',
  description: 'aGa',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange'],
  schema: z.object({
    divisorA: numberEnum([2, 3, 4, 5, 8]),
    divisorB: numberEnum([2, 3, 4, 5, 8]),
    divisorC: numberEnum([2, 3, 4, 5, 8]),
    divisorD: numberEnum([2, 3, 4, 5, 8]),
    dividendA: z.number().int().min(22).max(99),
    dividendB: z.number().int().min(22).max(99),
    dividendC: z.number().int().min(22).max(99),
    dividendD: z.number().int().min(22).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [divisorA, divisorC, divisorB, divisorD] = getRandomSubArrayFromArray(
      [2, 3, 4, 5, 8] as const,
      4
    );

    const dividendA = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorA) && x % 10 !== 0
    });
    const dividendB = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorB) && x % 10 !== 0
    });
    const dividendC = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorC) && x % 10 !== 0
    });
    const dividendD = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorD) && x % 10 !== 0
    });

    return {
      divisorA,
      divisorC,
      divisorB,
      divisorD,
      dividendA,
      dividendB,
      dividendC,
      dividendD
    };
  },
  Component: ({ question, translate }) => {
    const { divisorA, divisorC, divisorB, divisorD, dividendA, dividendB, dividendC, dividendD } =
      question;
    const equations = shuffle(
      [
        { sentence: `${dividendA} ${DIV} ${divisorA} = <ans/>`, answer: dividendA / divisorA },
        { sentence: `${dividendB} ${DIV} ${divisorB} = <ans/>`, answer: dividendB / divisorB },
        { sentence: `${dividendC} ${DIV} ${divisorC} = <ans/>`, answer: dividendC / divisorC },
        { sentence: `<ans/> = ${dividendD} ${DIV} ${divisorD}`, answer: dividendD / divisorD }
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={equations.map(({ sentence }) => sentence)}
        testCorrect={equations.map(({ answer }) => [answer.toString()])}
        questionHeight={900}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aGb2',
  description: 'aGb2',
  keywords: ['Divide', 'No exchange'],
  schema: z
    .object({
      dividend: z.number().int().min(20).max(100),
      divisor: z.number().int().min(2).max(8),
      name: nameSchema,
      object: z.enum(['sweets', 'marbles', 'mints'])
    })
    .refine(
      val => numbersDoNotHaveDivisionExchange(val.dividend, val.divisor),
      'Calculation must not contain an exchange'
    ),
  simpleGenerator: () => {
    const { dividend, divisor } = rejectionSample(
      () => {
        const dividend = randomIntegerInclusive(20, 100);
        const divisor = randomIntegerInclusive(2, 8);

        return { dividend, divisor };
      },
      ({ dividend, divisor }) => numbersDoNotHaveDivisionExchange(dividend, divisor)
    );

    const name = getRandomName();
    const object = getRandomFromArray(['sweets', 'marbles', 'mints'] as const);

    return {
      dividend,
      divisor,
      name,
      object
    };
  },
  Component: ({ question, translate }) => {
    const { dividend, divisor, name, object } = question;

    const answer = dividend / divisor;

    const pronoun =
      getGenderFromName(name) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasXObjectsAndSharesBetweenYJars({
          name,
          pronoun,
          dividend,
          divisor,
          object
        })}
        sentence={'<ans/>'}
        testCorrect={[answer.toString()]}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGb',
  description: 'aGb',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange'],
  schema: z.object({
    divisorA: numberEnum([2, 3, 4, 5, 8]),
    divisorB: numberEnum([2, 3, 4, 5, 8]),
    divisorC: numberEnum([2, 3, 4, 5, 8]),
    dividendA: z.number().int().min(22).max(99),
    dividendB: z.number().int().min(22).max(99),
    dividendC: z.number().int().min(22).max(99)
  }),
  simpleGenerator: () => {
    const [divisorA, divisorC, divisorB] = getRandomSubArrayFromArray([2, 3, 4, 5, 8] as const, 3);

    const dividendA = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorA) && x % 10 !== 0
    });
    const dividendB = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorB) && x % 10 !== 0
    });
    const dividendC = randomIntegerInclusive(22, 99, {
      constraint: x => numbersDoNotHaveDivisionExchange(x, divisorC) && x % 10 !== 0
    });

    return {
      divisorA,
      divisorC,
      divisorB,
      dividendA,
      dividendB,
      dividendC
    };
  },
  Component: ({ question, translate }) => {
    const { divisorA, divisorC, divisorB, dividendA, dividendB, dividendC } = question;
    const equations = shuffle(
      [
        { sentence: `${dividendA} ${DIV} ${divisorA} = <ans/>`, answer: dividendA / divisorA },
        { sentence: `${dividendB} ${DIV} ${divisorB} = <ans/>`, answer: dividendB / divisorB },
        { sentence: `<ans/> = ${dividendC} ${DIV} ${divisorC}`, answer: dividendC / divisorC }
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={equations.map(({ sentence }) => sentence)}
        testCorrect={equations.map(({ answer }) => [answer.toString()])}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberByA1DigitNumberNoExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
