import { StyleSheet, View } from 'react-native';
import { colors } from 'common/src/theme/colors';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { MeasureView } from '../../atoms/MeasureView';

type Props = {
  /** Determine whether the brace is to be used to the left or right of a component.
   * Optional prop, defaults to 'left'. */
  leftOrRightBrace?: 'left' | 'right';
};

/**
 * This is a component to return a curly brace, to be used to the left or right of a component.
 */
export const VerticalCurlyBrace = ({ leftOrRightBrace = 'left' }: Props) => {
  const styles = useStyles();

  return (
    <MeasureView style={leftOrRightBrace === 'right' && { transform: [{ scale: -1 }] }}>
      {dimens => (
        <View style={[styles.wholeVerticalLine, { height: dimens.height }]}>
          <View
            style={[
              styles.horizontalTick,
              {
                width: dimens.width / 4
              }
            ]}
          />
          <View
            style={[
              styles.horizontalTick,
              {
                width: dimens.width / 2,
                right: dimens.width / 2
              }
            ]}
          />
          <View
            style={[
              styles.horizontalTick,
              {
                width: dimens.width / 4
              }
            ]}
          />
        </View>
      )}
    </MeasureView>
  );
};

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return StyleSheet.create({
    wholeVerticalLine: {
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      width: displayMode === 'digital' ? 2 : 4,
      justifyContent: 'space-between'
    },
    horizontalTick: {
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      height: displayMode === 'digital' ? 2 : 4
    }
  });
};
