import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkh',
  description: 'bkh',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  questionHeight: 1200,
  schema: z.object({
    isCorrectAnswersTwo: z.boolean(),
    items: z.array(
      z.object({
        value: z.enum(['A', 'B', 'C', 'D']),
        numberOfCounters: z.number().int().min(1).max(12)
      })
    ),
    color: z.enum(['Red', 'Blue', 'Yellow', 'Green']),
    randomSeeds: z.array(z.number().int().min(1).max(2000)).length(4)
  }),
  simpleGenerator: () => {
    const isCorrectAnswersTwo = getRandomBoolean();
    const color = getRandomFromArray(['Red', 'Blue', 'Yellow', 'Green'] as const);

    const values = isCorrectAnswersTwo
      ? [['A', 'B'] as const, ['C', 'D'] as const]
      : [['A', 'B', 'C'] as const, ['D'] as const];

    const numberOfCorrectCounters = randomUniqueIntegersInclusiveStep(
      2,
      12,
      2,
      isCorrectAnswersTwo ? 2 : 3
    );
    const numberOfIncorrectCounters = randomUniqueIntegersInclusiveStep(
      1,
      11,
      2,
      isCorrectAnswersTwo ? 2 : 1
    );

    const correctItems = values[0].map((value, index) => {
      return { value, numberOfCounters: numberOfCorrectCounters[index] };
    });

    const incorrectItems = values[1].map((value, index) => {
      return { value, numberOfCounters: numberOfIncorrectCounters[index] };
    });

    const items = shuffle([...correctItems, ...incorrectItems]);

    const randomSeeds = randomUniqueIntegersInclusive(1, 2000, 4);

    return { isCorrectAnswersTwo, color, items, randomSeeds };
  },
  Component: props => {
    const {
      question: { isCorrectAnswersTwo, color, items, randomSeeds },
      translate,
      displayMode
    } = props;

    const arrangementA = getRandomBooleanArray(
      3,
      5,
      items[0].numberOfCounters,
      seededRandom({ randomA: randomSeeds[0] })
    );

    const arrangementB = getRandomBooleanArray(
      3,
      5,
      items[1].numberOfCounters,
      seededRandom({ randomB: randomSeeds[1] })
    );

    const arrangementC = getRandomBooleanArray(
      3,
      5,
      items[2].numberOfCounters,
      seededRandom({ randomC: randomSeeds[2] })
    );

    const arrangementD = getRandomBooleanArray(
      3,
      5,
      items[3].numberOfCounters,
      seededRandom({ randomD: randomSeeds[3] })
    );

    const arrangements = [arrangementA, arrangementB, arrangementC, arrangementD];

    const answer = isCorrectAnswersTwo ? ['A', 'B'] : ['A', 'B', 'C'];

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheSetsOfCountersThatCanBeSharedIntoTwoEqualGroups()}
        pdfTitle={translate.ks1PDFInstructions.tickTheSetsOfCountersThatCanBeSharedIntoTwoEqualGroups()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          items.map(({ value }, index) => ({
            value,
            component: (
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {arrangements[index].map((row, rowIndex) => (
                  <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                    {row.map((cell, colIndex) => (
                      <View
                        key={`cell-${rowIndex}-${colIndex}`}
                        style={{
                          width: dimens.width * 0.18,
                          height: dimens.height * 0.3,
                          padding: displayMode === 'digital' ? 8 : 16
                        }}
                      >
                        {cell && (
                          <AssetSvg
                            name={'CounterCustomizable'}
                            svgProps={{ color }}
                            height={displayMode === 'digital' ? 60 : 100}
                            width={displayMode === 'digital' ? 60 : 100}
                          />
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            )
          }))
        }
        testCorrect={answer}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bki',
  description: 'bki',
  keywords: ['Equal groups', 'Group'],
  schema: z.object({
    rows: z.number().int().min(2).max(10),
    columns: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 5);

    const columns = randomIntegerInclusive(2, 10, {
      constraint: x => x * rows <= 20 && (x * rows) % 2 === 0
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate,
      displayMode
    } = props;

    const total = rows * columns;

    const answer = total / 2;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.findFracOfX("<frac n='1' d='2' />", total)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='2'/>", total)}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkj',
  description: 'bkj',
  keywords: ['Equal groups', 'Group', 'Half'],
  schema: z.object({
    number: z.number().int().min(4).max(20).step(2),
    isAnswerFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(4, 20, 2);
    const isAnswerFirst = getRandomBoolean();

    return {
      number,
      isAnswerFirst
    };
  },
  Component: props => {
    const {
      question: { number, isAnswerFirst },
      translate,
      displayMode
    } = props;

    const answer = isAnswerFirst ? number.toString() : (number / 2).toString();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheMissingNumber()}
        testCorrect={[answer]}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={
          isAnswerFirst
            ? `<frac n='1' d='2'/> of <ans /> = ${number / 2}`
            : `<frac n='1' d='2'/> of ${number} = <ans/>`
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAHalf',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
