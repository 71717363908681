export default {
  after: 'after',
  and: 'and',
  before: 'before',
  counter: 'counter',
  counters: 'counters',
  dad: 'dad',
  equalGroups: 'equal groups',
  EvenNumbers: 'Even numbers',
  equalTo: 'equal to',
  fewer: 'fewer',
  friend: 'friend',
  greaterThan: 'greater than',
  groupX: 'Group {0}',
  Her: 'Her',
  Him: 'Him',
  His: 'His',
  'In both the 5 and 10 times-tables': 'In both the 5 and 10 times-tables',
  last: 'last',
  lessThan: 'less than',
  Marbles: 'Marbles',
  more: 'more',
  mum: 'mum',
  OddNumbers: 'Odd numbers',
  ones: 'Ones',
  'Only in the 5 times-table': 'Only in the 5 times-table',
  'Only in the 10 times-table': 'Only in the 10 times-table',
  shorterThan1Metre: 'Shorter than 1 metre',
  tallerThan1Metre: 'Taller than 1 metre',
  tens: 'Tens',
  unequalGroups: 'unequal groups',
  xToLowercase: '{0:string|lower}',
  xToCapitalize: '{0:string|capitalize}',
  directions: {
    above: 'above',
    antiClockwise: 'anti-clockwise',
    backwards: 'backwards',
    below: 'below',
    clockwise: 'clockwise',
    down: 'down',
    forwards: 'forwards',
    full: 'full',
    fullTurn: 'full turn',
    half: 'half',
    halfTurn: 'half turn',
    left: 'left',
    quarter: 'quarter',
    quarterTurn: 'quarter turn',
    quarterTurnAntiClockwise: 'quarter turn anti-clockwise',
    quarterTurnClockwise: 'quarter turn clockwise',
    right: 'right',
    threeQuarter: 'three-quarter',
    threeQuarterTurn: 'three-quarter turn',
    threeQuarterTurnAntiClockwise: 'three-quarter turn anti-clockwise',
    threeQuarterTurnClockwise: 'three-quarter turn clockwise',
    up: 'up'
  }
};
