const cos = Math.cos;
const sin = Math.sin;
const PI = Math.PI;

/* Multiply a 2x2 matrix by a 1x2 matrix */
const multiplyMatrix = ([[a, b], [c, d]]: number[][], [x, y]: number[]): number[] => [
  a * x + b * y,
  c * x + d * y
];

/* function to create a rotation matrix from an angle
 * x is an angle in radians
 */
const rotateMatrix = (x: number) => {
  const cosx = cos(x);
  const sinx = sin(x);
  return [
    [cosx, -sinx],
    [sinx, cosx]
  ];
};

/* function to add two vectors together */
const addVectors = ([a1, a2]: number[], [b1, b2]: number[]) => [a1 + b1, a2 + b2];

/**
  returns a SVG path element that represenat a ellipse.
*/

export function calcEllipseArcPath(
  /** Center of ellipse horizontally */
  cx: number,
  /** Center of ellipse vertically */
  cy: number,
  /** Radius of ellipse horizontally */
  rx: number,
  /** Radius of ellipse vertically */
  ry: number,
  /* Start angle, in radians */
  t1 = 0,
  /** Angle to sweep, in radians. Positive. */
  sweepAngle = 180,
  /** Rotation of the ellipse, in radians */
  rotationAngle = 180
) {
  let sweepAngleRad = (sweepAngle / 180) * PI;
  sweepAngleRad = sweepAngleRad % (2 * PI);
  const rotationAngleRad = (rotationAngle / 180) * PI;
  const rotMatrix = rotateMatrix(rotationAngleRad);
  const t1Rad = (t1 / 180) * PI;
  const [sX, sY] = addVectors(multiplyMatrix(rotMatrix, [rx * cos(t1Rad), ry * sin(t1Rad)]), [
    cx,
    cy
  ]);
  const [eX, eY] = addVectors(
    multiplyMatrix(rotMatrix, [rx * cos(t1Rad + sweepAngleRad), ry * sin(t1Rad + sweepAngleRad)]),
    [cx, cy]
  );
  const fA = sweepAngleRad > PI ? 1 : 0;
  const fS = sweepAngleRad > 0 ? 1 : 0;
  const path = `M ${sX} ${sY} A ${rx} ${ry} ${
    (rotationAngleRad / PI) * 180
  } ${fA} ${fS} ${eX} ${eY}`;
  return path;
}

/**
 * Draw a triangle arrow head pointing down
 * xOffset & yOffset optional parameters used to draw larger arrow heads
 */
export const drawArrowHead = (x: number, y: number, xOffset = 8, yOffset = 10) => {
  const path = `M ${x} ${y} L${x - xOffset} ${y - yOffset} L${x + xOffset} ${y - yOffset} Z`;
  return path;
};
