import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { LESS_THAN } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  arrayHasNoDuplicates,
  arrayIsSubset,
  sortNumberArray
} from '../../../../utils/collections';
import { getCharacterHeadImage, characterNameLabel } from '../../../../utils/characters';
import {
  ActivityDurations,
  AnswerSentenceActivitiesArray,
  getAnswerSentenceActivityInfo,
  translateActivity
} from '../../../../utils/activities';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1Activities = [
  'aSchoolDay',
  'brushYourTeeth',
  'eatAnApple',
  'eatASweet',
  'flyFromBritainToUSA',
  'haveAShower',
  'jump3Times',
  'sleepAtNight',
  'snapAPhoto',
  'takeABreath',
  'watchAFilm',
  'writeAStory',
  'writeTheDate'
] as const;

const Question1 = newQuestionContent({
  uid: 'auA',
  description: 'auA',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z
    .object({
      hoursMinutesOrSeconds: z.enum(['hours', 'minutes', 'seconds']),
      activities: z.enum(Question1Activities).array().length(9)
    })
    .refine(
      ({ hoursMinutesOrSeconds, activities }) =>
        activities.filter(activity => ActivityDurations[activity].includes(hoursMinutesOrSeconds))
          .length > 0,
      'There must be at least one correct activity'
    ),
  simpleGenerator: () => {
    const hoursMinutesOrSeconds = getRandomFromArray(['hours', 'minutes', 'seconds'] as const);

    const activities = rejectionSample(
      () => getRandomSubArrayFromArray(Question1Activities, 9),
      activities =>
        activities.filter(activity => ActivityDurations[activity].includes(hoursMinutesOrSeconds))
          .length > 0
    );

    return { hoursMinutesOrSeconds, activities };
  },

  Component: ({ question: { hoursMinutesOrSeconds, activities }, translate }) => {
    const title = (() => {
      switch (hoursMinutesOrSeconds) {
        case 'hours':
          return translate.instructions.selectActivitesMostAppropriatelyTimedInHours();
        case 'minutes':
          return translate.instructions.selectActivitesMostAppropriatelyTimedInMinutes();
        case 'seconds':
          return translate.instructions.selectActivitesMostAppropriatelyTimedInSeconds();
      }
    })();

    // Leniency: some activities could be measured with two units. In these cases we give the student the
    // benefit of the doubt. In particular, the student's answer needs to:
    // - (allowed) only include activities that can be measure with the given unit
    // - (required) include all activities that can _only_ be measured with the given unit (as these are unambiguous)
    const allowedActivities: string[] = [];
    const requiredActivities: string[] = [];
    activities.forEach(activity => {
      const durations = ActivityDurations[activity];
      if (durations.includes(hoursMinutesOrSeconds)) {
        allowedActivities.push(activity);
        if (durations.length === 1) {
          requiredActivities.push(activity);
        }
      }
    });

    return (
      <QF10SelectNumbers
        title={title}
        testCorrect={userAnswer =>
          // Check that all given answers are allowed, and all required answers are given.
          arrayIsSubset(userAnswer, allowedActivities) &&
          arrayIsSubset(requiredActivities, userAnswer)
        }
        multiSelect
        items={activities.map(activity => ({
          value: activity,
          component: translateActivity(activity, translate)
        }))}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerToDisplay: [...allowedActivities, ...requiredActivities] }}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'auB',
  description: 'auB',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    activity: z.enum(AnswerSentenceActivitiesArray),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const activity = getRandomFromArray(AnswerSentenceActivitiesArray);

    const name = getRandomName();

    return { activity, name };
  },
  Component: ({ question: { activity, name }, translate }) => {
    const [activityString, correctTime] = getAnswerSentenceActivityInfo(activity, name, translate);

    const statements = ['hours', 'minutes', 'seconds'] as const;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectWordToCompleteSentence()}
        testCorrect={[correctTime]}
        numItems={3}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center', alignItems: 'center' }]}>
            <Text variant="WRN400">{activityString}</Text>
          </View>
        )}
        renderItems={statements.map(duration => ({
          value: duration,
          component: <Text variant="WRN700">{translate.time[duration](0)}</Text>
        }))}
      />
    );
  }
});

const Question3ShortActivities = [
  'eatAGrape',
  'brushYourTeeth',
  'listenToASong',
  'writeASentence',
  'tieYourShoes',
  'putOnYourCoat',
  'makeASandwich',
  'boilAKettle',
  'kickABall'
] as const;

const Question3LongActivities = [
  'runABath',
  'watchAFilm',
  'buildAHouse',
  'paintARoom',
  'cookDinner',
  'runAMarathon',
  'goToASwimmingLesson'
] as const;

const Question3 = newQuestionContent({
  uid: 'auC',
  description: 'auC',
  keywords: ['Time', 'Compare'],
  schema: z.object({
    shortOrLong: z.enum(['short', 'long']),
    shortActivity: z.enum(Question3ShortActivities),
    longActivity: z.enum(Question3LongActivities)
  }),
  simpleGenerator: () => {
    const shortOrLong = getRandomFromArray(['short', 'long'] as const);

    const shortActivity = getRandomFromArray(Question3ShortActivities);

    const longActivity = getRandomFromArray(Question3LongActivities);

    return { shortOrLong, shortActivity, longActivity };
  },
  Component: props => {
    const {
      question: { shortOrLong, shortActivity, longActivity },
      translate
    } = props;

    const activities = shuffle([shortActivity, longActivity], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={
          shortOrLong === 'short'
            ? translate.instructions.selectActivityThatTakesLessTime()
            : translate.instructions.selectActivityThatTakesLonger()
        }
        pdfTitle={
          shortOrLong === 'short'
            ? translate.instructions.circleActivityThatTakesLessTime()
            : translate.instructions.circleActivityThatTakesLonger()
        }
        testCorrect={[shortOrLong === 'short' ? shortActivity : longActivity]}
        numItems={2}
        renderItems={activities.map(activity => ({
          value: activity,
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {translateActivity(activity, translate)}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'auD',
  description: 'auD',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    hours: z.number().int().min(1).max(2),
    minutes: z.number().int().min(30).max(150).multipleOf(10),
    seconds: z.number().int().min(60).max(300).multipleOf(60),
    times: z
      .array(
        z.object({
          string: z.enum(['hours', 'minutes', 'seconds']),
          value: z.number().int().min(1).max(300)
        })
      )
      .length(3)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 2);
    const minutes = randomIntegerInclusiveStep(30, 150, 10, {
      constraint: x => x !== hours
    });
    const seconds = randomIntegerInclusiveStep(60, 300, 60, {
      constraint: x => x !== hours && x !== minutes
    });

    const times = shuffle([
      { string: 'hours', value: hours },
      { string: 'minutes', value: minutes },
      { string: 'seconds', value: seconds }
    ] as const);

    return { hours, minutes, seconds, times };
  },
  Component: props => {
    const {
      question: { hours, minutes, seconds, times },
      translate
    } = props;

    // Convert hours/minutes to seconds
    const hoursToSeconds = hours * 3600;
    const minutesToSeconds = minutes * 60;

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderTheTimes()}
        pdfTitle={translate.instructions.drawLinesToOrderTheTimes()}
        testCorrect={sortNumberArray([seconds, minutesToSeconds, hoursToSeconds])}
        items={times.map(({ value, string }) => ({
          value:
            string === 'hours' ? hoursToSeconds : string === 'minutes' ? minutesToSeconds : value,
          component: (
            <Text variant="WRN700">
              {value} {translate.timePeriod[string](value)}
            </Text>
          )
        }))}
        topLabel={translate.keywords.Shortest()}
        bottomLabel={translate.keywords.Longest()}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'auD2',
  description: 'auD',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    hours: z.number().int().min(1).max(2),
    minutes: z.number().int().min(30).max(150).multipleOf(10),
    seconds: z.number().int().min(60).max(300).multipleOf(60),
    ascendingOrDescending: z.enum(['ascending', 'descending']),
    times: z
      .array(
        z.object({
          string: z.enum(['hours', 'minutes', 'seconds']),
          value: z.number().int().min(1).max(300)
        })
      )
      .length(3)
  }),
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);
    const hours = randomIntegerInclusive(1, 2);
    const minutes = randomIntegerInclusiveStep(30, 150, 10, {
      constraint: x => x !== hours
    });
    const seconds = randomIntegerInclusiveStep(60, 300, 60, {
      constraint: x => x !== hours && x !== minutes
    });

    const times = shuffle([
      { string: 'hours', value: hours },
      { string: 'minutes', value: minutes },
      { string: 'seconds', value: seconds }
    ] as const);

    return { hours, minutes, seconds, times, ascendingOrDescending };
  },
  Component: props => {
    const {
      question: { hours, minutes, seconds, times, ascendingOrDescending },
      translate
    } = props;

    // Convert hours/minutes to seconds
    const hoursToSeconds = hours * 3600;
    const minutesToSeconds = minutes * 60;

    const { topLabel, bottomLabel, answer } = (() => {
      switch (ascendingOrDescending) {
        case 'descending':
          return {
            topLabel: translate.keywords.Shortest(),
            bottomLabel: translate.keywords.Longest(),
            answer: sortNumberArray([seconds, minutesToSeconds, hoursToSeconds])
          };
        default:
          return {
            topLabel: translate.keywords.Longest(),
            bottomLabel: translate.keywords.Shortest(),
            answer: sortNumberArray([seconds, minutesToSeconds, hoursToSeconds]).reverse()
          };
      }
    })();

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragCardsOrderTheTimesFromXToY(topLabel, bottomLabel)}
        pdfTitle={translate.instructions.useCardsOrderTheTimesFromXToY(topLabel, bottomLabel)}
        testCorrect={answer}
        items={times.map(({ value, string }) => ({
          value:
            string === 'hours' ? hoursToSeconds : string === 'minutes' ? minutesToSeconds : value,
          component: (
            <Text variant="WRN700">
              {value} {translate.timePeriod[string](value)}
            </Text>
          )
        }))}
        topLabel={topLabel}
        bottomLabel={bottomLabel}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'auE',
  description: 'auE',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    seconds: z.number().int().min(120).max(600)
  }),
  simpleGenerator: () => {
    const seconds = randomIntegerInclusiveStep(120, 600, 60);

    return { seconds };
  },

  Component: props => {
    const {
      question: { seconds },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeStatement()}
        testCorrect={answer =>
          parseInt(answer[0]) * 60 > seconds && parseInt(answer[1]) * 60 > parseInt(answer[0])
        }
        sentence={`${seconds.toLocaleString()} ${translate.timePeriod.seconds(
          seconds
        )} ${LESS_THAN} <ans/> ${translate.timePeriod.minutes(
          0
        )} ${LESS_THAN} <ans/> ${translate.timePeriod.hours(0)}`}
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptValidAnswersForContent() }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'auF',
  description: 'auF',
  keywords: ['Time', 'Hours', 'Minutes', 'Seconds'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      nameC: nameSchema,
      nameD: nameSchema,
      nameATime: z.number().int().min(61).max(300),
      nameBTime: z.number().int().min(61).max(119),
      nameCMinutes: z.number().int().min(2).max(3),
      nameCSeconds: z.number().int().min(1).max(20),
      nameDTime: z.number().int().min(61).max(300)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.nameATime,
          val.nameBTime,
          val.nameCMinutes * 60 + val.nameCSeconds,
          val.nameDTime
        ]),
      'All calculated times must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.nameA, val.nameB, val.nameC, val.nameD]),
      'Names must all be different.'
    ),
  simpleGenerator: () => {
    const [nameA, nameB, nameC, nameD] = getRandomUniqueNames(4);

    const nameATime = randomIntegerInclusive(61, 300);

    const nameBTime = randomIntegerInclusive(61, 119, {
      constraint: x => x !== nameATime
    });

    const nameCMinutes = randomIntegerInclusive(2, 3);

    const nameCSeconds = randomIntegerInclusive(1, 20, {
      constraint: x => x + nameCMinutes * 60 !== nameATime && x + nameCMinutes * 60 !== nameBTime
    });

    const nameDTime = randomIntegerInclusive(61, 300, {
      constraint: x => x !== nameATime && x !== nameBTime && x !== nameCSeconds + nameCMinutes * 60
    });

    return {
      nameA,
      nameB,
      nameC,
      nameD,
      nameATime,
      nameBTime,
      nameCMinutes,
      nameCSeconds,
      nameDTime
    };
  },
  Component: props => {
    const {
      question: {
        nameA,
        nameB,
        nameC,
        nameD,
        nameATime,
        nameBTime,
        nameCMinutes,
        nameCSeconds,
        nameDTime
      },
      translate
    } = props;

    const nameCTime = nameCMinutes * 60 + nameCSeconds;

    // nameBMinutes is always 1, so nameBSeconds is just total nameBTime - 60.
    const nameBSeconds = nameBTime - 60;

    const statements = [
      {
        name: nameA,
        sentence: translate.answerSentences.itTookMeNumSeconds(nameATime),
        value: nameATime
      },
      {
        name: nameB,
        sentence: translate.answerSentences.itTookMeNumMinutesAndNumSeconds(1, nameBSeconds),
        value: nameBTime
      },
      {
        name: nameC,
        sentence: translate.answerSentences.itTookMeNumMinutesAndNumSeconds(
          nameCMinutes,
          nameCSeconds
        ),
        value: nameCTime
      },
      {
        name: nameD,
        sentence: translate.answerSentences.itTookMeNumSeconds(nameDTime),
        value: nameDTime
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.charactersRunARace(nameA, nameB, nameC, nameD)}
        pdfTitle={translate.instructions.charactersRunARacePDF(nameA, nameB, nameC, nameD)}
        testCorrect={[Math.min(nameATime, nameBTime, nameCTime, nameDTime)]}
        numItems={4}
        renderItems={({ dimens }) => {
          return shuffledStatements.map(({ name, sentence, value }) => ({
            value,
            component: (
              <View style={{ flexDirection: 'row', width: '100%' }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <SpeechBubble
                    flickLocation="top-right"
                    style={{
                      maxWidth: 0.5 * dimens.width,
                      maxHeight: 0.5 * dimens.height,
                      top: 20
                    }}
                  >
                    {sentence}
                  </SpeechBubble>
                </View>
                <View
                  style={{ rowGap: 10, justifyContent: 'center', marginLeft: 10, marginRight: 20 }}
                >
                  {getCharacterHeadImage(name, dimens.height / 2, dimens.width / 3 - 20)}
                  {characterNameLabel(name, dimens.width / 3 - 20)}
                </View>
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnitsOfTime',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4],
  unpublishedQuestionTypes: []
});
export default SmallStep;
