import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { View } from 'react-native';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfV',
  description: 'bfV',
  keywords: ['Tens', 'Ones', 'Base 10', 'Place value chart'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 9);

    return {
      numberOfOnes,
      numberOfTens
    };
  },
  Component: ({ question: { numberOfOnes, numberOfTens }, translate, displayMode }) => {
    const answer = numberOfTens * 10 + numberOfOnes;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatNumberIsShown()}
        Content={({ dimens }) => {
          const scale =
            numberOfTens > 5
              ? displayMode === 'digital'
                ? 0.15
                : 0.3
              : displayMode === 'digital'
              ? 0.3
              : 0.6;

          return (
            <CustomizableTable
              cellHeaders={[
                {
                  label: placeValueColumnInfo[1].name,
                  containerStyle: { backgroundColor: placeValueColumnInfo[1].color },
                  textStyle: { color: placeValueColumnInfo[1].textColor }
                },
                {
                  label: placeValueColumnInfo[0].name,
                  containerStyle: { backgroundColor: placeValueColumnInfo[0].color },
                  textStyle: { color: placeValueColumnInfo[0].textColor }
                }
              ]}
              tableData={[
                [
                  <View
                    key="tens"
                    style={{
                      flexDirection: 'row'
                    }}
                  >
                    <BaseTenRepresentation
                      b10Rep={{
                        variant: 'Cubes',
                        numbers: { tens: numberOfTens },
                        arrangement: 'ltr'
                      }}
                      usableWidth={dimens.width / 2}
                      usableHeight={dimens.height * 0.7}
                      scale={scale}
                      align="center"
                    />
                  </View>,
                  <BaseTenRepresentation
                    key="ones"
                    b10Rep={{
                      variant: 'Cubes',
                      numbers: { ones: numberOfOnes },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width / 2}
                    usableHeight={dimens.height * 0.7}
                    scale={scale}
                    align="center"
                  />
                ]
              ]}
              tableCellStyle={{ height: dimens.height * 0.8 }}
            />
          );
        }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentence={'<ans/>'}
        testCorrect={[answer.toString()]}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'bfX',
  description: 'bfX',
  keywords: ['Tens', 'Ones', 'Base 10', 'Place value chart'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 9);

    return {
      numberOfOnes,
      numberOfTens
    };
  },
  Component: ({ question: { numberOfOnes, numberOfTens }, translate }) => {
    const answer = numberOfTens * 10 + numberOfOnes;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        Content={({ dimens }) => {
          return (
            <CustomizableTable
              cellHeaders={[
                {
                  label: placeValueColumnInfo[1].name,
                  containerStyle: { backgroundColor: placeValueColumnInfo[1].color },
                  textStyle: { color: placeValueColumnInfo[1].textColor }
                },
                {
                  label: placeValueColumnInfo[0].name,
                  containerStyle: { backgroundColor: placeValueColumnInfo[0].color },
                  textStyle: { color: placeValueColumnInfo[0].textColor }
                }
              ]}
              tableData={[[numberOfTens.toLocaleString(), numberOfOnes.toLocaleString()]]}
              tableCellStyle={{ height: dimens.height * 0.6 }}
            />
          );
        }}
        sentence={translate.ks1AnswerSentences.thereAreAnsTensAndAnsOnesTheNumberIsAns()}
        testCorrect={[numberOfTens.toString(), numberOfOnes.toString(), answer.toString()]}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UsePlaceValueChart',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
