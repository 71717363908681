import { View, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { useTheme } from 'common/src/theme';
import { useContext, useMemo } from 'react';
import Text from 'common/src/components/typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';

interface Props {
  /**
   * Input can either be a string or a JSX Element to display a text input
   *
   * integer is optional if you want to display a mixed fraction
   */
  integer?: string | JSX.Element;
  numerator: string | JSX.Element;
  denominator: string | JSX.Element;
  containerStyle?: StyleProp<ViewStyle>;
  dividerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

/**
 * Component to render mixed fraction; all numbers can either be strings or JSX.Elements.
 * Integer is optional.
 */
const MixedFraction = ({
  integer,
  numerator,
  denominator,
  containerStyle,
  dividerStyle,
  textStyle
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode);

  return (
    <View style={styles.container}>
      {integer !== undefined && (
        // Add a deliberate space after the integer to get a font-relative horizontal space
        <Text style={[styles.text, textStyle]}>{integer} </Text>
      )}
      <View style={styles.fractionContainer}>
        <View style={[styles.numeratorContainer, containerStyle]}>
          {typeof numerator === 'string' ? (
            <Text style={[styles.text, textStyle]}>{numerator}</Text>
          ) : (
            numerator
          )}
        </View>
        <View
          style={
            typeof numerator === 'string' && typeof denominator === 'string'
              ? [styles.divider, dividerStyle]
              : [styles.divider, { width: '100%', minWidth: 128 }, dividerStyle]
          }
        />
        <View style={[styles.denominatorContainer, containerStyle]}>
          {typeof denominator === 'string' ? (
            <Text style={[styles.text, textStyle]}>{denominator}</Text>
          ) : (
            denominator
          )}
        </View>
      </View>
    </View>
  );
};

function useStyles(displayMode: 'digital' | 'pdf' | 'markscheme') {
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        text: {
          ...theme.fonts.default,
          fontSize: displayMode === 'digital' ? 40 : 50
        },
        container: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        fractionContainer: {
          flexDirection: 'column',
          alignItems: 'center'
        },
        numeratorContainer: {
          justifyContent: 'flex-end'
        },
        divider: {
          marginVertical: 8,
          minWidth: 44,
          width: '100%',
          borderWidth: displayMode === 'digital' ? 1 : 2,
          borderColor: displayMode === 'digital' ? theme.colors.primary : 'black'
        },
        denominatorContainer: {
          justifyContent: 'flex-start'
        }
      }),
    [displayMode, theme.colors.primary, theme.fonts.default]
  );
}

export default MixedFraction;
