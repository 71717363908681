import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { useMemo } from 'react';
import { z } from 'zod';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import { arrayHasNoDuplicates, filledArray, sortNumberArray } from 'common/src/utils/collections';
import {
  fractionToDecimal,
  simplify,
  mixedNumberToImproperFraction,
  compareFractions
} from 'common/src/utils/fractions';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from 'common/src/components/question/questionFormats/QF36ContentAndSentenceDrag';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { barModelColors } from 'common/src/theme/colors';
import { lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqr',
  description: 'aqr',
  keywords: ['Compare', 'Fraction', 'Numerator', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(9),
      number2: z.number().int().min(4).max(10),
      number3: z.number().int().min(4).max(10),
      numeratorColor: z.string()
    })
    .refine(
      val => val.number1 < val.number2 && val.number1 < val.number3,
      'number1 must be less than number2 and number3'
    )
    .refine(val => val.number3 !== val.number2, 'number3 must be different to number2'),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(4, 10);
    const number3 = randomIntegerInclusive(4, 10, {
      constraint: x => x !== number2
    });
    const number1 = randomIntegerInclusive(1, 9, {
      constraint: x => x < number2 && x < number3
    });

    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { number1, number2, number3, numeratorColor };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, numeratorColor },
      translate
    } = props;

    // Bar Model 1
    // Bar model 1 should be split into number2 amount of parts and have number1 shaded in
    const numeratorColorArray1 = filledArray(numeratorColor, number1);
    const remainder1 = filledArray('white', number2 - number1);

    // Bar Model 2
    // Bar model 2 should be split into number3 amount of parts and have number1 shaded in
    const numeratorColorArray2 = filledArray(numeratorColor, number1);
    const remainder2 = filledArray('white', number3 - number1);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = ['>', '<', '='];

    const fraction1 = fractionToDecimal(number1, number2);
    const fraction2 = fractionToDecimal(number1, number3);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.useBarModelsToCompareFractionsDragCardsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useBarModelsToCompareFractionsUseLessThanGreaterThanEqualsToMakeStatementCorrect()}
        items={answerOptions}
        pdfLayout="itemsHidden"
        itemVariant="square"
        mainPanelStyle={{ alignItems: 'center' }}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={number2}
              width={dimens.width}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
            />
            <ShadedFractionBarModel
              totalSubSections={number3}
              width={dimens.width}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
            />
          </View>
        )}
        sentence={`<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> <ans/> <frac n='${number1.toLocaleString()}' d='${number3.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fraction1, fraction2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aqs',
  description: 'aqs',
  keywords: ['Compare', 'Fraction', 'Numerator'],
  schema: z.object({
    number1: z.number().int().min(2).max(7),
    number2: z.number().int().min(4).max(9),
    number3: z.number().int().min(3).max(8),
    smallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(4, 9);
    // Must be less than number2
    const number3 = randomIntegerInclusive(3, number2 - 1);
    // Must be less than number2 and number3
    const number1 = randomIntegerInclusive(2, number3 - 1);

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { number1, number2, number3, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, smallerOrGreater },
      translate,
      displayMode
    } = props;

    const bars = [
      {
        rows: 1,
        cols: number2
      },
      {
        rows: 1,
        cols: number3
      }
    ];

    const prebarText = [
      `<frac n='${number1}' d='${number2}'/>`,
      `<frac n='${number1}' d='${number3}'/>`
    ];

    // Simplify fractions
    const fracA = simplify(number1, number2);
    const fracB = simplify(number1, number3);

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.completeSentence()} ${translate.instructions.shadeBarModelsToHelp()}`}
        testCorrect={{
          content: [number1, number1],
          sentence: answer => {
            return smallerOrGreater === 'smaller'
              ? compareFractions([answer[0], answer[1]], fracA)
              : compareFractions([answer[0], answer[1]], fracB);
          }
        }}
        inputMaxCharacters={2}
        sentence={translate.instructions.theXFractionIsY(
          smallerOrGreater,
          `<frac nAns='' dAns=''/>`
        )}
        bars={bars}
        preBarText={prebarText}
        questionHeight={1200}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        pdfTableHeight={115}
        customMarkSchemeAnswer={{
          answersToDisplay: {
            sentence:
              smallerOrGreater === 'smaller'
                ? fracA.map(num => num.toLocaleString())
                : fracB.map(num => num.toLocaleString())
          },
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aqt',
  description: 'aqt',
  keywords: ['Order', 'Fraction', 'Numerator'],
  schema: z.object({
    numeratorA: z.number().int().min(2).max(10),
    denominatorA: z.number().int().min(3).max(12),
    denominatorB: z.number().int().min(3).max(50),
    denominatorC: z.number().int().min(2).max(10),
    denominatorD: z.number().int().min(2).max(50).multipleOf(2),
    multiple: z.number().int().min(2).max(3),
    ascendingOrDescending: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const {
      numeratorA,
      denominatorA,
      denominatorB,
      denominatorC,
      denominatorD,
      multiple,
      ascendingOrDescending
    } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusive(2, 10);
        const denominatorA = randomIntegerInclusive(numeratorA + 1, 12);
        const denominatorB = randomIntegerInclusive(numeratorA + 1, 50);
        const denominatorC = randomIntegerInclusive(2, numeratorA);
        const denominatorD = randomIntegerInclusiveStep(2, 50, 2);
        const multiple = randomIntegerInclusive(2, 3);
        const ascendingOrDescending = getRandomBoolean();

        return {
          numeratorA,
          denominatorA,
          denominatorB,
          denominatorC,
          denominatorD,
          multiple,
          ascendingOrDescending
        };
      },
      ({ numeratorA, denominatorA, denominatorB, denominatorC, denominatorD, multiple }) => {
        const fracA = fractionToDecimal(numeratorA, denominatorA);
        const fracB = fractionToDecimal(numeratorA, denominatorB);
        const fracC = fractionToDecimal(numeratorA, denominatorC);
        const fracD = fractionToDecimal(numeratorA * multiple, denominatorD);

        return arrayHasNoDuplicates([fracA, fracB, fracC, fracD]);
      }
    );

    return {
      numeratorA,
      denominatorA,
      denominatorB,
      denominatorC,
      denominatorD,
      multiple,
      ascendingOrDescending
    };
  },
  Component: props => {
    const {
      question: {
        numeratorA,
        denominatorA,
        denominatorB,
        denominatorC,
        denominatorD,
        multiple,
        ascendingOrDescending
      },
      translate,
      displayMode
    } = props;

    const numeratorB = numeratorA * multiple;

    // Fractions
    const [fracANumerator, fracADenominator] = [numeratorA, denominatorA];
    const [fracBNumerator, fracBDenominator] = [numeratorA, denominatorB];
    const [fracCNumerator, fracCDenominator] = [numeratorA, denominatorC];
    const [fracDNumerator, fracDDenominator] = [numeratorB, denominatorD];

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        string: `<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/>`,
        value: fractionToDecimal(fracANumerator, fracADenominator)
      };
      const eqB = {
        string: `<frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`,
        value: fractionToDecimal(fracBNumerator, fracBDenominator)
      };
      const eqC = {
        string: `<frac n='${fracCNumerator.toLocaleString()}' d='${fracCDenominator.toLocaleString()}'/>`,
        value: fractionToDecimal(fracCNumerator, fracCDenominator)
      };
      const eqD = {
        string: `<frac n='${fracDNumerator.toLocaleString()}' d='${fracDDenominator.toLocaleString()}'/>`,
        value: fractionToDecimal(fracDNumerator, fracDDenominator)
      };
      return [eqA, eqB, eqC, eqD];
    }, [
      fracADenominator,
      fracANumerator,
      fracBDenominator,
      fracBNumerator,
      fracCDenominator,
      fracCNumerator,
      fracDDenominator,
      fracDNumerator
    ]);

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending
            ? translate.instructions.dragCardsToPlaceFractionsInAscendingOrder()
            : translate.instructions.dragCardsToPlaceFractionsInDescendingOrder()
        }
        pdfTitle={
          ascendingOrDescending
            ? translate.instructions.useCardsToPlaceFractionsInAscendingOrder()
            : translate.instructions.useCardsToPlaceFractionsInDescendingOrder()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqu',
  description: 'aqu',
  keywords: ['Compare', 'Fraction', 'Numerator'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(74),
      number2: z.number().int().min(5).max(75),
      number3: z.number().int().min(5).max(75),
      number4: z.number().int().min(1).max(85),
      number5: z.number().int().min(5).max(50),
      number6: z.number().int().min(5).max(50),
      number7: z.number().int().min(6).max(150),
      number8: z.number().int().min(5).max(20),
      number9: z.number().int().min(5).max(20)
    })
    .refine(
      val => val.number1 < val.number2 && val.number1 < val.number3,
      'number1 must be less than number2 and number3'
    )
    .refine(val => val.number3 !== val.number2, 'number3 must be different to number2')
    .refine(
      val =>
        val.number4 !== val.number1 && val.number4 !== val.number5 && val.number4 !== val.number6,
      'number4 must be different to number1, number5 and number6'
    )
    .refine(val => val.number6 !== val.number5, 'number6 must be different to number5')
    .refine(
      val =>
        val.number7 !== val.number1 &&
        val.number7 !== val.number4 &&
        val.number7 > val.number8 &&
        val.number7 > val.number9,
      'number7 must be different to number1 and number4 plus greater than number8 and number9'
    )
    .refine(val => val.number9 !== val.number8, 'number9 must be different to number8'),
  simpleGenerator: () => {
    const { number1, number2, number3, number4, number5, number6, number7, number8, number9 } =
      rejectionSample(
        () => {
          const number2 = randomIntegerInclusive(5, 75);
          const number3 = randomIntegerInclusive(5, 75, {
            constraint: x => x !== number2
          });
          const number1 = randomIntegerInclusive(1, 74, {
            constraint: x => x < number2 && x < number3
          });
          const number5 = randomIntegerInclusive(5, 50);
          const number6 = randomIntegerInclusive(5, 50, {
            constraint: x => x !== number5
          });
          const number4 = randomIntegerInclusive(1, 85, {
            constraint: x => x !== number1 && x !== number5 && x !== number6
          });
          const number8 = randomIntegerInclusive(5, 20);
          const number9 = randomIntegerInclusive(5, 20, {
            constraint: x => x !== number8
          });
          const number7 = randomIntegerInclusive(6, 150, {
            constraint: x => x !== number1 && x !== number4 && x > number8 && x > number9
          });

          return {
            number1,
            number2,
            number3,
            number4,
            number5,
            number6,
            number7,
            number8,
            number9
          };
        },
        ({ number1, number2, number3, number4, number5, number6, number7, number8, number9 }) => {
          const fractions = [
            [number1, number2],
            [number1, number3],
            [number4, number5],
            [number4, number6],
            [number7, number8],
            [number7, number9]
          ];

          // Prevent denominator from been 1
          return (
            fractions.filter(frac => {
              const [numerator, denominator] = frac;
              return simplify(numerator, denominator)[1] === 1;
            }).length === 0
          );
        }
      );

    return { number1, number2, number3, number4, number5, number6, number7, number8, number9 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, number7, number8, number9 },
      translate
    } = props;

    const checkAnswer = (obj: { fracA: number[]; fracB: number[] }): string => {
      const [numeratorA, denominatorA] = obj.fracA;
      const [numeratorB, denominatorB] = obj.fracB;

      const fracA = fractionToDecimal(numeratorA, denominatorA);
      const fracB = fractionToDecimal(numeratorB, denominatorB);

      // Compare fractions and return correct answer (>, < or =)
      const answer = lessThanGreaterThanOrEqualTo(fracA, fracB);

      return answer;
    };

    // Simplify fractions
    const [fraction1Numerator, fraction1Denominator] = simplify(number1, number2);
    const [fraction2Numerator, fraction2Denominator] = simplify(number1, number3);

    const [fraction3Numerator, fraction3Denominator] = simplify(number4, number5);
    const [fraction4Numerator, fraction4Denominator] = simplify(number4, number6);

    const [fraction5Numerator, fraction5Denominator] = simplify(number7, number8);
    const [fraction6Numerator, fraction6Denominator] = simplify(number7, number9);

    // Statements
    const sentences = [
      {
        sentence: `<frac n='${fraction1Numerator.toLocaleString()}' d='${fraction1Denominator.toLocaleString()}'/> <ans /> <frac n='${fraction2Numerator.toLocaleString()}' d='${fraction2Denominator.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: [fraction1Numerator, fraction1Denominator],
          fracB: [fraction2Numerator, fraction2Denominator]
        })
      },
      {
        sentence: `<frac n='${fraction3Numerator.toLocaleString()}' d='${fraction3Denominator.toLocaleString()}'/> <ans /> <frac n='${fraction4Numerator.toLocaleString()}' d='${fraction4Denominator.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: [fraction3Numerator, fraction3Denominator],
          fracB: [fraction4Numerator, fraction4Denominator]
        })
      },
      {
        sentence: `<frac n='${fraction5Numerator.toLocaleString()}' d='${fraction5Denominator.toLocaleString()}'/> <ans /> <frac n='${fraction6Numerator.toLocaleString()}' d='${fraction6Denominator.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: [fraction5Numerator, fraction5Denominator],
          fracB: [fraction6Numerator, fraction6Denominator]
        })
      }
    ];

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        actionPanelVariant="end"
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useInequalitySymbolsToMakeStatementsCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqv',
  description: 'aqv',
  keywords: ['Compare', 'Fraction', 'Mixed number', 'Numerator'],
  schema: z
    .object({
      wholeA: z.number().int().min(2).max(12),
      numeratorA: z.number().int().min(1).max(9),
      denominatorA: z.number().int().min(2).max(10),
      wholeAsNumeratorB: z.number().int().min(1).max(12),
      numeratorB: z.number().int().min(1).max(11),
      denominatorB: z.number().int().min(2).max(12),
      wholeCAndD: z.number().int().min(2).max(12),
      numeratorC: z.number().int().min(1).max(9),
      denominatorC: z.number().int().min(2).max(10),
      numeratorD: z.number().int().min(1).max(11),
      denominatorD: z.number().int().min(2).max(12)
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(val => val.numeratorC < val.denominatorC, 'numeratorC must be less than denominatorC.')
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    )
    .refine(
      val => val.denominatorC !== val.denominatorD,
      'denominatorC and denominatorD must be different.'
    )
    .refine(
      val =>
        !compareFractions(
          [val.wholeA, val.numeratorA, val.denominatorA],
          [val.wholeAsNumeratorB * val.denominatorB + val.numeratorB, val.denominatorB]
        ),
      'fractionA and fractionB cannot be equal.'
    )
    .refine(
      val =>
        !compareFractions(
          [val.wholeCAndD, val.numeratorC, val.denominatorC],
          [val.wholeCAndD * val.denominatorD + val.numeratorD, val.denominatorD]
        ),
      'fractionC and fractionD cannot be equal.'
    ),
  simpleGenerator: () => {
    const { wholeA, numeratorA, denominatorA, wholeAsNumeratorB, numeratorB, denominatorB } =
      rejectionSample(
        () => {
          const wholeA = randomIntegerInclusive(2, 12);
          const denominatorA = randomIntegerInclusive(2, 10);
          const numeratorA = randomIntegerInclusive(1, denominatorA - 1, {
            constraint: x => {
              const simplifiedA = simplify(x, denominatorA);
              // This fraction must be in its simplest form already:
              return x === simplifiedA[0];
            }
          });
          const wholeAsNumeratorB = randomIntegerInclusive(1, 12);
          const denominatorB = randomIntegerInclusive(2, 12, {
            constraint: x => x !== denominatorA
          });
          const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
          return { wholeA, numeratorA, denominatorA, wholeAsNumeratorB, numeratorB, denominatorB };
        },
        // The two fractions must have a difference between 2 and 5:
        ({ wholeA, numeratorA, denominatorA, wholeAsNumeratorB, numeratorB, denominatorB }) => {
          const fracA = fractionToDecimal(wholeA * denominatorA + numeratorA, denominatorA);
          const fracB = fractionToDecimal(
            wholeAsNumeratorB * denominatorB + numeratorB,
            denominatorB
          );
          // Need Math.abs as either fracA or fracB can be the bigger fraction.
          const difference = Math.abs(fracA - fracB);
          return difference >= 2 && difference <= 5;
        }
      );

    const { wholeCAndD, numeratorC, denominatorC, numeratorD, denominatorD } = rejectionSample(
      () => {
        const wholeCAndD = randomIntegerInclusive(2, 12);
        const denominatorC = randomIntegerInclusive(2, 10);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1, {
          constraint: x => {
            const simplifiedC = simplify(x, denominatorC);
            // This fraction must be in its simplest form already:
            return x === simplifiedC[0];
          }
        });
        const denominatorD = randomIntegerInclusive(2, 12, {
          constraint: x => x !== denominatorC
        });
        const numeratorD = randomIntegerInclusive(1, denominatorD - 1);
        return { wholeCAndD, numeratorC, denominatorC, numeratorD, denominatorD };
      },
      // The two fractions must have a difference between 0.25 and 1:
      ({ wholeCAndD, numeratorC, denominatorC, numeratorD, denominatorD }) => {
        const fracC = fractionToDecimal(wholeCAndD * denominatorC + numeratorC, denominatorC);
        const fracD = fractionToDecimal(wholeCAndD * denominatorD + numeratorD, denominatorD);
        // Need Math.abs as either fracC or fracD can be the bigger fraction.
        const difference = Math.abs(fracC - fracD);
        return difference >= 0.25 && difference <= 1;
      }
    );

    return {
      wholeA,
      numeratorA,
      denominatorA,
      wholeAsNumeratorB,
      numeratorB,
      denominatorB,
      wholeCAndD,
      numeratorC,
      denominatorC,
      numeratorD,
      denominatorD
    };
  },
  Component: props => {
    const {
      question: {
        wholeA,
        numeratorA,
        denominatorA,
        wholeAsNumeratorB,
        numeratorB,
        denominatorB,
        wholeCAndD,
        numeratorC,
        denominatorC,
        numeratorD,
        denominatorD
      },
      translate
    } = props;

    const checkAnswer = (obj: { fracA: number[]; fracB: number[] }): string => {
      const [numeratorA, denominatorA] = obj.fracA;
      const [numeratorB, denominatorB] = obj.fracB;

      const fracA = fractionToDecimal(numeratorA, denominatorA);
      const fracB = fractionToDecimal(numeratorB, denominatorB);

      // Compare fractions and return correct answer (>, < or =)
      const answer = lessThanGreaterThanOrEqualTo(fracA, fracB);

      return answer;
    };

    const sentences = shuffle(
      [
        {
          sentence: `<frac w='${wholeA.toLocaleString()}' n='${numeratorA}' d='${denominatorA}'/> <ans/> <frac n='${
            wholeAsNumeratorB * denominatorB + numeratorB
          }' d='${denominatorB}'/>`,
          answer: checkAnswer({
            fracA: mixedNumberToImproperFraction(wholeA, numeratorA, denominatorA),
            fracB: [wholeAsNumeratorB * denominatorB + numeratorB, denominatorB]
          })
        },
        {
          sentence: `<frac w='${wholeCAndD.toLocaleString()}' n='${numeratorC}' d='${denominatorC}'/> <ans/> <frac n='${
            wholeCAndD * denominatorD + numeratorD
          }' d='${denominatorD}'/>`,
          answer: checkAnswer({
            fracA: mixedNumberToImproperFraction(wholeCAndD, numeratorC, denominatorC),
            fracB: [wholeCAndD * denominatorD + numeratorD, denominatorD]
          })
        }
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsToCompareNumbers()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareNumbers()}
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqw',
  description: 'aqw',
  keywords: ['Compare', 'Fraction', 'Numerator'],
  schema: z
    .object({
      scoreA: z.number().int().min(2).max(11),
      outOfA: z.number().int().min(5).max(12),
      scoreB: z.number().int().min(2).max(60),
      outOfB: z.number().int().min(10).max(132),
      name1: nameSchema,
      name2: nameSchema
    })
    .refine(val => val.name1 !== val.name2, 'name1 cannot be the same as name2')
    .refine(
      val => val.scoreA / val.outOfA !== val.scoreB / val.outOfB,
      'scoreA cannot be equal to scoreB'
    ),
  simpleGenerator: () => {
    const { scoreA, outOfA, scoreB, outOfB, name1, name2 } = rejectionSample(
      () => {
        const outOf1 = randomIntegerInclusive(5, 12);
        const score1 = randomIntegerInclusive(2, outOf1 - 1);

        const scoreBMultiple = randomIntegerInclusive(2, 5);
        const scoreBAddition = randomIntegerInclusive(1, 5);

        const outOf2 = outOf1 * score1;
        const score2 = score1 * scoreBMultiple + scoreBAddition;

        const [[scoreA, outOfA], [scoreB, outOfB]] = [
          [score1, outOf1],
          [score2, outOf2]
        ];

        const [name1, name2] = getRandomUniqueNames(2);

        return { scoreA, outOfA, scoreB, outOfB, name1, name2 };
      },
      ({ scoreA, scoreB, outOfA, outOfB }) =>
        scoreB < outOfB &&
        arrayHasNoDuplicates([fractionToDecimal(scoreA, outOfA), fractionToDecimal(scoreB, outOfB)])
    );

    return { scoreA, outOfA, scoreB, outOfB, name1, name2 };
  },
  Component: props => {
    const {
      question: { scoreA, outOfA, scoreB, outOfB, name1, name2 },
      translate
    } = props;

    const statements = shuffle(
      [
        {
          name: name1,
          sentence: translate.answerSentences.characterScoresXOutOfY(name1, scoreA, outOfA),
          value: fractionToDecimal(scoreA, outOfA)
        },
        {
          name: name2,
          sentence: translate.answerSentences.characterScoresXOutOfY(name2, scoreB, outOfB),
          value: fractionToDecimal(scoreB, outOfB)
        }
      ],
      { random: seededRandom(props.question) }
    );

    const correctAnswer = statements.reduce(
      (bestScore, statement) => (statement.value > bestScore ? statement.value : bestScore),
      0
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectWhichChildDidTheBest()}
        pdfTitle={translate.instructions.circleWhichChildDidTheBest()}
        testCorrect={[correctAnswer]}
        numItems={2}
        renderItems={({ dimens }) =>
          statements.map(({ name, sentence, value }) => ({
            value,
            component: (
              <View style={{ alignItems: 'center' }}>
                <AssetSvg
                  name={getCharacterHeadSvgName(name)}
                  height={dimens.height * 0.5}
                  width={dimens.width * 0.8}
                />
                <View style={{ paddingTop: 20 }}>
                  <TextStructure textVariant="WRN700" sentence={sentence} />
                </View>
              </View>
            )
          }))
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderNumerator',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
