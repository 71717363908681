// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import MultiplicationAndDivisionB from './MultiplicationAndDivisionB';
import LengthAndPerimeter from './LengthAndPerimeter';
import Fractions from './Fractions';
import DecimalsA from './DecimalsA';

const Term = newTermContent({
  term: 'Spring',
  blocks: [MultiplicationAndDivisionB, LengthAndPerimeter, Fractions, DecimalsA]
});
export default Term;
