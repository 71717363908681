import { StyleProp, TextStyle, View } from 'react-native';
import { countRange } from 'common/src/utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { colors } from '../../../../theme/colors';
import { Dimens } from '../../../../theme/scaling';
import { getPictogramIcons, getPictogramRow } from './PictogramImages';
import { CustomizableTable } from '../CustomizableTable';
import Text from '../../../typography/Text';
import EasyDragAndDrop from '../../../draganddrop/EasyDragAndDrop';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

type Props = TitleStyleProps & {
  dimens: Dimens;
  /** The names of each column. */
  columnNames: string[];
  /** Flex value for each column respectively */
  columnFlexValues?: number[];
  /** The row values including names. This does not include any values for the pictogram column. Can be either string or element, element can be useful for when needing to rendering JSX like tallys */
  rowData: string[][] | (string | JSX.Element)[][];
  /** values to be displayed as images when not interactive */
  displayValues?: number[];
  interactive?: boolean;
  /** string value to be shown by the key */
  keyValue: string;
  color?: string;
  /**
   * Colors to be applied to the counters of the pictogram, row-by-row. Allows for different colours on different lines of the pictogram.
   * If passed, will override anything passed into color if a value exists in the index of this array.
   */
  rowColors?: (string | undefined)[];
  /**
   * Color to be applied to the counter in the key. Optional prop, will otherwise default to color prop.
   */
  keyColor?: string;
  headerColor?: string;
  headerTextStyle?: StyleProp<TextStyle>;
};

/**
 * Pictogram representation
 * Makes use of the CustomizableTable component and returns a table with
 * rows of circles in wholes, halves, quarters and three quarters.
 */
export default function Pictogram({
  displayValues,
  dimens,
  columnNames,
  columnFlexValues,
  rowData,
  interactive = false,
  keyValue,
  color,
  rowColors = [],
  keyColor,
  headerColor,
  headerTextStyle
}: Props) {
  const displayMode = useContext(DisplayMode);
  const interactiveRowHeight = 120;
  const data = countRange(rowData.length).map(i => {
    const lastColumn = interactive ? (
      <EasyDragAndDrop.ZoneMultiple
        style={[
          {
            width: '100%',
            minHeight: interactiveRowHeight,
            borderColor: 'transparent',
            justifyContent: 'flex-start'
          }
        ]}
        id={i}
        key={`${i}`}
        capacity={6}
      />
    ) : (
      <View key={`${rowData[i][0]}`} style={{ width: '100%', alignItems: 'flex-start' }}>
        {displayValues && getPictogramRow(displayValues[i], 50, 50, rowColors[i] ?? color)}
      </View>
    );

    return [...rowData[i], lastColumn];
  });

  return (
    <View style={{ gap: 10 }}>
      <View
        style={{
          width: dimens.width,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 5
        }}
      >
        {
          getPictogramIcons({
            fraction: 'whole',
            width: 40,
            height: 40,
            color: keyColor ?? color,
            key: `whole_key`
          }).component
        }
        <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{` = ${keyValue}`}</Text>
      </View>
      <CustomizableTable
        cellHeaders={columnNames.map(name => {
          return {
            label: name,
            containerStyle: {
              backgroundColor: headerColor ?? colors.pacificBlue,
              padding: interactive ? 0 : 6
            },
            textStyle: [displayMode === 'digital' && { color: 'white' }, headerTextStyle]
          };
        })}
        columnFlexValues={columnFlexValues ? columnFlexValues : interactive ? [1, 1, 4] : [1, 3]}
        tableData={data}
        tableStyle={{ width: dimens.width }}
        tableFontSize={displayMode !== 'digital' ? 50 : interactive ? 32 : 22}
        tableCellStyle={interactive ? { minHeight: interactiveRowHeight, padding: 0 } : undefined}
      />
    </View>
  );
}
