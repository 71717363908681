import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { CircleShapeNames, anglePositions } from '../../../utils/anglePositions';
import { colors } from '../../../theme/colors';

type Props = {
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Name of the shape, check in labelPositions.ts for corresponding svgName
   */
  shapeName: CircleShapeNames;
  /**
   * @param circleIndexes An array of angle indexes
   * See anglePositions.tsx for details on which angle is which index.
   */
  circleIndexes: number[];
  seed?: string | number | object;
  /**
   * How much to reduce the shape width by from the dimens width.
   * Optional prop, defaults to 64.
   */
  shapeWidthReduction?: number;
  /**
   * How much to reduce the shape height by from the dimens height.
   * Optional prop, defaults to 64.
   */
  shapeHeightReduction?: number;
  circleRadius?: number;
};
/**
 * This component renders a shape with circles around the specified angles.
 */
export const CircleShapeAngles = (props: Props) => {
  const {
    dimens: { width, height },
    shapeName,
    circleIndexes,
    shapeWidthReduction = 64,
    shapeHeightReduction = 64
  } = props;
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(width, height);
  const shapeWidth = width - shapeWidthReduction;
  const shapeHeight = height - shapeHeightReduction;
  const circleDiameter = shapeWidth * 0.1;
  const circleColor = displayMode === 'digital' ? colors.prussianBlue : colors.black;

  //get absolute positions of circles
  const renderCircles = () => {
    const { svgName, positions } = anglePositions(
      shapeName,
      { width, height },
      { shapeWidth, shapeHeight }
    );
    const circles = positions.map((_x, index) => {
      if (circleIndexes.includes(index)) {
        return (
          <View
            key={index}
            style={[
              positions[index],
              {
                position: 'absolute',
                width: circleDiameter,
                height: circleDiameter,
                zIndex: 2,
                borderRadius: 100,
                borderColor: circleColor,
                borderWidth: 2
              }
            ]}
          ></View>
        );
      }
    });

    return {
      svgName,
      circles
    };
  };
  const { svgName, circles } = renderCircles();

  return (
    <View style={[styles.imageWrapper]}>
      {circles}
      <AssetSvg name={svgName as SvgName} width={shapeWidth} height={shapeHeight} />
    </View>
  );
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          width,
          height
        }
      }),
    [width, height]
  );
};
