import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { z } from 'zod';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import {
  filledArray,
  nestedArrayHasNoDuplicates,
  sortNumberArray
} from 'common/src/utils/collections';
import {
  randomIntegerInclusive,
  seededRandom,
  shuffle,
  rejectionSample,
  getRandomFromArray,
  randomUniqueIntegersInclusive
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { simplify } from 'common/src/utils/fractions';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { numberEnum } from 'common/src/utils/zod';
import { fractionToDecimal } from 'common/src/utils/fractions';
import QF36ContentAndSentenceDrag from 'common/src/components/question/questionFormats/QF36ContentAndSentenceDrag';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { barModelColors } from 'common/src/theme/colors';
import { lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import QF34InteractiveBarModelWithSentenceDrag from 'common/src/components/question/questionFormats/QF34InteractiveBarModelWithSentenceDrag';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { findFactorsExcludingSelfAnd1 } from 'common/src/utils/factors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aql',
  description: 'aql',
  keywords: ['Compare', 'Fraction', 'Denominator', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(8),
      number2: z.number().int().min(4).max(9),
      number3: z.number().int().min(1).max(8),
      numeratorColor: z.string()
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2')
    .refine(
      val => val.number3 < val.number2 && val.number3 !== val.number1,
      'number3 is less than number2 and different to number1'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(4, 9);
    const number1 = randomIntegerInclusive(1, number2 - 1);
    const number3 = randomIntegerInclusive(1, number2 - 1, {
      constraint: x => x !== number1
    });

    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { number1, number2, number3, numeratorColor };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, numeratorColor },
      translate
    } = props;

    // Bar Model 1
    // Bar model 1 should be split into number2 amount of parts and have number1 shaded in
    const numeratorColorArray1 = filledArray(numeratorColor, number1);
    const remainder1 = filledArray('white', number2 - number1);

    // Bar Model 2
    // Bar model 2 should be split into number2 amount of parts and have number3 shaded in
    const numeratorColorArray2 = filledArray(numeratorColor, number3);
    const remainder2 = filledArray('white', number2 - number3);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = ['>', '<', '='];

    const fraction1 = fractionToDecimal(number1, number2);
    const fraction2 = fractionToDecimal(number3, number2);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsCompleteStatement()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatement()}
        pdfLayout="itemsHidden"
        items={answerOptions}
        itemVariant="square"
        mainPanelStyle={{ alignItems: 'center' }}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={number2}
              width={dimens.width}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
            />
            <ShadedFractionBarModel
              totalSubSections={number2}
              width={dimens.width}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
            />
          </View>
        )}
        sentence={`<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> <ans/> <frac n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fraction1, fraction2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aqm',
  description: 'aqm',
  keywords: ['Compare', 'Fraction', 'Denominator', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(11),
      number2: z.number().int().min(4).max(12),
      number3: z.number().int().min(3).max(11),
      number4: z.number().int().min(4).max(12)
    })
    .refine(val => val.number4 % val.number2 === 0, 'number4 must be a multiple of number2'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(4, 12);
        // Must be less than number2
        const number1 = randomIntegerInclusive(3, number2 - 1);
        const number4 = randomIntegerInclusive(4, 12, {
          constraint: x => x % number2 === 0
        });
        // Must be less than number4
        const number3 = randomIntegerInclusive(3, number4 - 1);

        return { number1, number2, number3, number4 };
      },
      val =>
        // Prevent identical fractions, but allow equivalent fractions
        nestedArrayHasNoDuplicates([
          [val.number1, val.number2],
          [val.number3, val.number4]
        ])
    ),
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate,
      displayMode
    } = props;

    const bars = [
      {
        rows: 1,
        cols: number2,
        label: (
          <TextStructure
            sentence={`<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/>`}
            fractionTextStyle={[{ fontSize: displayMode === 'digital' ? 30 : 50 }]}
          />
        )
      },
      {
        rows: 1,
        cols: number4,
        label: (
          <TextStructure
            sentence={`<frac n='${number3.toLocaleString()}' d='${number4.toLocaleString()}'/>`}
            fractionTextStyle={[{ fontSize: displayMode === 'digital' ? 30 : 50 }]}
          />
        )
      }
    ];

    const fracA = Number(fractionToDecimal(number1, number2));
    const fracB = Number(fractionToDecimal(number3, number4));

    // Compare fractions and return correct answer (>, < or =)
    const answer = lessThanGreaterThanOrEqualTo(fracA, fracB);

    return (
      <QF34InteractiveBarModelWithSentenceDrag
        title={translate.instructions.dragCardsCompleteStatementYouCanShadeTheBarModelsToHelp()}
        pdfTitle={translate.instructions.useInequalitySymbolsCompleteStatementYouCanShadeTheBarModelsToHelp()}
        bars={bars}
        sentence={`<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}' /> <ans /> <frac n='${number3.toLocaleString()}' d='${number4.toLocaleString()}' />`}
        items={[
          { component: <Text variant="WRN700">{'>'}</Text>, value: '>' },
          { component: <Text variant="WRN700">{'<'}</Text>, value: '<' },
          { component: <Text variant="WRN700">{'='}</Text>, value: '=' }
        ]}
        testCorrectSentence={[answer]}
        actionPanelVariant="end"
        questionHeight={1300}
        hideItemsPdf
      />
    );
  },
  questionHeight: 1300
});

const Question3 = newQuestionContent({
  uid: 'aqn',
  description: 'aqn',
  keywords: ['Order', 'Fraction', 'Denominator'],
  schema: z
    .object({
      ascendingOrDescending: z.enum(['ascending', 'descending']),
      denominatorA: z.number().int().min(2).max(6),
      denominatorB: z.number().int().min(2).max(6),
      denominatorC: z.number().int().min(6).max(30),
      denominatorD: z.number().int().min(2).max(15),
      numeratorA: z.number().int().min(1).max(5),
      numeratorB: z.number().int().min(1).max(5),
      numeratorC: z.number().int().min(1).max(29),
      numeratorD: z.number().int().min(1).max(29)
    })
    .refine(
      val => findFactorsExcludingSelfAnd1(val.denominatorC).length > 0,
      'There must be at least one factor'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const {
      ascendingOrDescending,
      denominatorA,
      denominatorB,
      denominatorC,
      denominatorD,
      numeratorA,
      numeratorB,
      numeratorC,
      numeratorD
    } = rejectionSample(
      () => {
        const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

        const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 6, 2);
        const denominatorC = denominatorA * denominatorB;
        const denominatorD = getRandomFromArray(
          findFactorsExcludingSelfAnd1(denominatorC)
        ) as number;

        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
        const numeratorD = randomIntegerInclusive(1, denominatorD - 1);

        return {
          ascendingOrDescending,
          denominatorA,
          denominatorB,
          denominatorC,
          denominatorD,
          numeratorA,
          numeratorB,
          numeratorC,
          numeratorD
        };
      },
      ({
        denominatorA,
        denominatorB,
        denominatorC,
        denominatorD,
        numeratorA,
        numeratorB,
        numeratorC,
        numeratorD
      }) => {
        const fracA = [numeratorA, denominatorA];
        const fracB = [numeratorB, denominatorB];
        const fracC = [numeratorC, denominatorC];
        const fracD = [numeratorD, denominatorD];

        return (
          denominatorD !== denominatorA &&
          denominatorD !== denominatorB &&
          // Ensure no equivalent fractions
          fractionToDecimal(fracA[0], fracA[1]) !== fractionToDecimal(fracB[0], fracB[1]) &&
          fractionToDecimal(fracA[0], fracA[1]) !== fractionToDecimal(fracC[0], fracC[1]) &&
          fractionToDecimal(fracA[0], fracA[1]) !== fractionToDecimal(fracD[0], fracD[1]) &&
          fractionToDecimal(fracB[0], fracB[1]) !== fractionToDecimal(fracC[0], fracC[1]) &&
          fractionToDecimal(fracB[0], fracB[1]) !== fractionToDecimal(fracD[0], fracD[1]) &&
          fractionToDecimal(fracC[0], fracC[1]) !== fractionToDecimal(fracD[0], fracD[1])
        );
      }
    );

    return {
      ascendingOrDescending,
      denominatorA,
      denominatorB,
      denominatorC,
      denominatorD,
      numeratorA,
      numeratorB,
      numeratorC,
      numeratorD
    };
  },
  Component: props => {
    const {
      question: {
        ascendingOrDescending,
        denominatorA,
        denominatorB,
        denominatorC,
        denominatorD,
        numeratorA,
        numeratorB,
        numeratorC,
        numeratorD
      },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const fractions = [
      {
        text: `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`,
        value: Number(fractionToDecimal(numeratorA, denominatorA))
      },
      {
        text: `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`,
        value: Number(fractionToDecimal(numeratorB, denominatorB))
      },
      {
        text: `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`,
        value: Number(fractionToDecimal(numeratorC, denominatorC))
      },
      {
        text: `<frac n='${numeratorD.toLocaleString()}' d='${denominatorD.toLocaleString()}'/>`,
        value: Number(fractionToDecimal(numeratorD, denominatorD))
      }
    ];
    const shuffledFractions = shuffle(fractions, { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderFractionsFromSmallestToGreatest()
            : translate.instructions.orderFractionsFromGreatestToSmallest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderFractionsFromSmallestToGreatest()
            : translate.instructions.useCardsToOrderFractionsFromGreatestToSmallest()
        }
        testCorrect={sortNumberArray(
          shuffledFractions.map(x => x.value),
          ascendingOrDescending
        )}
        draggableVariant="rectangle"
        items={shuffledFractions.map(({ text, value }) => ({
          component: (
            <TextStructure
              sentence={text}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          ),
          value
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqo',
  description: 'aqo',
  keywords: ['Compare', 'Fraction', 'Common denominator'],
  schema: z.object({
    denominatorA: z.number().int().min(3).max(10),
    denominatorB: z.number().int().min(3).max(10),
    numeratorA: z.number().int().min(1).max(9),
    numeratorB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(3, 10);
    const denominatorB = randomIntegerInclusive(3, 10, {
      constraint: x => x !== denominatorA && denominatorA % x !== 0
    });

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    return { denominatorA, denominatorB, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, numeratorA, numeratorB },
      translate
    } = props;

    const fraction1 = fractionToDecimal(numeratorA, denominatorA);
    const fraction2 = fractionToDecimal(numeratorB, denominatorB);

    const statements = [
      {
        lhsComponent: <TextStructure sentence={`<frac n='${numeratorA}' d='${denominatorA}'/>`} />,
        rhsComponent: <TextStructure sentence={`<frac n='${numeratorB}' d='${denominatorB}' />`} />,
        correctAnswer: lessThanGreaterThanOrEqualTo(fraction1, fraction2)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatement()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatement()}
        mainPanelStyle={{ alignItems: 'center' }}
        statements={statements}
        items={['>', '<', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question5 = newQuestionContent({
  uid: 'aqp',
  description: 'aqp',
  keywords: ['Compare', 'Fraction', 'Denominator', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(5),
      number2: z.number().int().min(3).max(6),
      number3: z.number().int().min(1).max(5),
      number4: z.number().int().min(3).max(6),
      number5: z.number().int().min(1).max(9),
      number6: z.number().int().min(5).max(10),
      number7: z.number().int().min(1).max(9),
      number8: z.number().int().min(5).max(10),
      number9: z.number().int().min(6).max(9),
      number10: z.number().int(),
      number11: z.number().int().min(11).max(49),
      number12: numberEnum([15, 20, 50])
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2')
    .refine(val => val.number3 < val.number4, 'number3 must be less than number4')
    .refine(val => val.number4 !== val.number2, 'number4 must be different to number2')
    .refine(val => val.number5 < val.number6, 'number5 must be less than number6')
    .refine(val => val.number7 < val.number8, 'number7 must be less than number8')
    .refine(val => val.number8 !== val.number6, 'number8 must be different to number6')
    .refine(val => val.number9 < val.number10, 'number9 must be less than number10')
    .refine(val => val.number11 < val.number12, 'number11 must be less than number12'),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 6);
    const number1 = randomIntegerInclusive(1, number2 - 1);
    const number4 = randomIntegerInclusive(3, 6, {
      constraint: x => x !== number2
    });
    const number3 = randomIntegerInclusive(1, number4 - 1);
    const number6 = randomIntegerInclusive(5, 10);
    const number5 = randomIntegerInclusive(1, number6 - 1);
    const number8 = randomIntegerInclusive(5, 10, {
      constraint: x => x !== number6
    });
    const number7 = randomIntegerInclusive(1, number8 - 1);
    const number10 = 10;
    const number9 = randomIntegerInclusive(6, number10 - 1);
    const number12 = getRandomFromArray([15, 20, 50] as const);
    const number11 = randomIntegerInclusive(11, number12 - 1);

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      number11,
      number12
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10,
        number11,
        number12
      },
      translate
    } = props;

    const checkAnswer = (obj: { fracA: number; fracB: number }): string => {
      const fracA = obj.fracA;
      const fracB = obj.fracB;

      // Compare fractions and return correct answer (>, < or =)
      const answer = lessThanGreaterThanOrEqualTo(fracA, fracB);

      return answer;
    };

    // Statements
    const sentences = [
      {
        sentence: `<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> <ans /> <frac n='${number3.toLocaleString()}' d='${number4.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: fractionToDecimal(number1, number2),
          fracB: fractionToDecimal(number3, number4)
        })
      },
      {
        sentence: `<frac n='${number5.toLocaleString()}' d='${number6.toLocaleString()}'/> <ans /> <frac n='${number7.toLocaleString()}' d='${number8.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: fractionToDecimal(number5, number6),
          fracB: fractionToDecimal(number7, number8)
        })
      },
      {
        sentence: `<frac n='${number9.toLocaleString()}' d='${number10.toLocaleString()}'/> <ans /> <frac n='${number11.toLocaleString()}' d='${number12.toLocaleString()}'/>`,
        answer: checkAnswer({
          fracA: fractionToDecimal(number9, number10),
          fracB: fractionToDecimal(number11, number12)
        })
      }
    ];

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useCardsCompleteStatements()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        actionPanelVariant="end"
        testCorrect={sentences.map(({ answer }) => [answer])}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqq',
  description: 'aqq',
  keywords: ['Order', 'Fractions', 'Improper', 'Mixed numbers', 'Common denominator'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(5),
      number2: z.number().int().min(1).max(7),
      number3: z.number().int().min(2).max(8),
      number4: z.number().int().min(1).max(7),
      number5: z.number().int().min(2).max(8),
      number6: z.number().int().min(9).max(20),
      number7: z.number().int().min(3).max(8),
      number8: z.number().int().min(3).max(7),
      number9: z.number().int().min(9).max(20),
      ascendingOrDescending: z.enum(['ascending', 'descending'])
    })
    .refine(val => val.number2 < val.number3, 'number2 must be less than number3')
    .refine(val => val.number4 < val.number5, 'number4 must be less than number5')
    .refine(val => val.number5 !== val.number3, 'number5 must be different to number3')
    .refine(val => val.number6 % val.number7 !== 0, 'number6 must not be a multiple of number7'),
  simpleGenerator: () => {
    const {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      ascendingOrDescending
    } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1, 5);
        const number3 = randomIntegerInclusive(2, 8);
        const number2 = randomIntegerInclusive(1, number3 - 1);
        const number5 = randomIntegerInclusive(2, 8, {
          constraint: x => x !== number3
        });
        const number4 = randomIntegerInclusive(1, number5 - 1);
        const number7 = randomIntegerInclusive(3, 8);
        const number6 = randomIntegerInclusive(9, 20, {
          constraint: x => x % number7 !== 0
        });
        const number8 = randomIntegerInclusive(3, 7);
        const number9 = randomIntegerInclusive(9, 20);
        const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

        return {
          number1,
          number2,
          number3,
          number4,
          number5,
          number6,
          number7,
          number8,
          number9,
          ascendingOrDescending
        };
      },
      ({ number1, number2, number3, number4, number5, number6, number7 }) => {
        return (
          number6 / number7 !== number1 + number2 / number3 &&
          number6 / number7 !== number1 + number4 / number5 &&
          number1 + number2 / number3 !== number1 + number4 / number5
        );
      }
    );

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      ascendingOrDescending
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        ascendingOrDescending
      },
      translate,
      displayMode
    } = props;

    // Question asks for specific fractions to be in its simplest form
    const [eq1SimplifiedA, eq1SimplifiedB] = simplify(number2, number3);
    const [eq2SimplifiedA, eq2SimplifiedB] = simplify(number4, number5);

    // Randomly order these equations
    const eqs = shuffle(
      [
        {
          string: `<frac w='${number1.toLocaleString()}' n='${eq1SimplifiedA}' d='${eq1SimplifiedB}'/>`,
          value: number1 + eq1SimplifiedA / eq1SimplifiedB
        },
        {
          string: `<frac w='${number1.toLocaleString()}' n='${eq2SimplifiedA}' d='${eq2SimplifiedB}'/>`,
          value: number1 + eq2SimplifiedA / eq2SimplifiedB
        },
        {
          string: `<frac n='${number6}' d='${number7}'/>`,
          value: number6 / number7
        },
        {
          string: `<frac n='${number8}' d='${number9}'/>`,
          value: number8 / number9
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderNumbersFromSmallestToGreatest()
            : translate.instructions.orderNumbersFromGreatestToSmallest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()
            : translate.instructions.useCardsToOrderNumbersFromGreatestToSmallest()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 26 : 40,
                fontWeight: '700'
              }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderDenominator',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
