import { View, StyleSheet } from 'react-native';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { parseMarkup } from '../../../markup';
import { SetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import ContentBox from '../../molecules/ContentBox';
import Text from '../../../components/typography/Text';
import { Dimens } from '../../../theme/scaling';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import TextStructure from '../../molecules/TextStructure';

type UserAnswer = string[];

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer: SetState<UserAnswer[]>;
  /**
   * @param boxes An array of arrays of strings for box labels
   * The number of arrays equates to the number of sequences
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> boxes should return an array of number strings
   */
  boxes: string[][];
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
};

/**
 * This component renders a sequence of content boxes
 * The <ans/> string is used to annotate where a user answer input field is require.
 */
export const SequenceBoxes = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    boxes,
    dimens: { width, height }
  } = props;

  const displayMode = useContext(DisplayMode);
  const styles = useStyles(width, height, displayMode);

  return (
    <View style={styles.container}>
      {boxes.map((row, rowIndex) => {
        let ansIndex = -1;
        const parsedRow = row.map(row => parseMarkup(row));
        return (
          <View key={rowIndex} style={styles.row}>
            {parsedRow.map((val, i) => {
              if (val.numberOfAns > 0) {
                ansIndex += 1;
              }
              // Copy of current answer box's index to retain which index in the state each input has.
              const currentBoxIndex = ansIndex;
              if (val.tokens.length === 0) {
                return (
                  <ContentBox
                    containerStyle={[
                      styles.contentBox,
                      {
                        backgroundColor: colors.white
                      }
                    ]}
                    key={i}
                  >
                    <Text variant="WRN400" style={{ width: 80, textAlign: 'center' }}>
                      {'?'}
                    </Text>
                  </ContentBox>
                );
              }
              if (val.tokens[0].type === 'text' || val.tokens[0].type === 'frac') {
                return (
                  <ContentBox containerStyle={styles.contentBox} key={i}>
                    <TextStructure
                      textVariant="WRN400"
                      fractionTextStyle={{ fontSize: 32 }}
                      sentence={boxes[rowIndex][i]}
                    />
                  </ContentBox>
                );
              } else {
                return (
                  <NoKeyboardTextInput
                    key={`${rowIndex}_${i}`}
                    style={{ minWidth: displayMode === 'digital' ? 120 : 200 }}
                    value={userAnswer[rowIndex][currentBoxIndex]}
                    onChangeText={text => {
                      const newState = [...userAnswer];
                      newState[rowIndex][currentBoxIndex] = text;
                      setUserAnswer(newState);
                    }}
                  />
                );
              }
            })}
          </View>
        );
      })}
    </View>
  );
};

export const SequenceBoxesWithState = withStateHOC(SequenceBoxes, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: props.boxes.map(row =>
      row
        .map(row => parseMarkup(row))
        .filter(val => val.numberOfAns > 0)
        .map(() => '')
    ),
    testComplete: userAnswers => userAnswers.every(userAnswer => userAnswer.every(it => it !== ''))
  })
});

const useStyles = (width: number, height: number, displayMode: string) => {
  const contentBoxWidth = displayMode === 'digital' ? 120 : 200;
  const contentBoxHeight = displayMode === 'digital' ? 96 : 150;
  return useMemo(
    () =>
      StyleSheet.create({
        container: { width: width, height: height, justifyContent: 'space-evenly' },
        row: { flexDirection: 'row', gap: 10, justifyContent: 'center' },
        contentBox: {
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: contentBoxHeight,
          minWidth: contentBoxWidth
        }
      }),
    [width, height, contentBoxHeight, contentBoxWidth]
  );
};
