import { newBlockContent } from '../../../Block';

import DescribeTurns from './1DescribeTurns';
import DescribePositionLeftAndRight from './2DescribePositionLeftAndRight';
import DescribePositionForwardsAndBackwards from './3DescribePositionForwardsAndBackwards';
import DescribePositionAboveAndBelow from './4DescribePositionAboveAndBelow';
import OrdinalNumbers from './5OrdinalNumbers';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [6, 6],
  smallSteps: [
    DescribeTurns, // Step 1
    DescribePositionLeftAndRight, // Step 2
    DescribePositionForwardsAndBackwards, // Step 3
    DescribePositionAboveAndBelow, // Step 4
    OrdinalNumbers // Step 5
  ]
});
export default Block;
