import { useCallback, useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import TextStructure from '../../../molecules/TextStructure';
import Svg, { G, Line, Polygon } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import Text from '../../../typography/Text';
import { parseMarkup } from '../../../../markup';
import { SUB } from '../../../../constants';
import { DisplayMode } from '../../../../contexts/displayMode';
import { all, create, number } from 'mathjs';
import { AssetSvg } from '../../../../assets/svg';
import { withStateHOC } from '../../../../stateTree';
import { SetState } from '../../../../utils/react';
import { parseToSUB } from '../../../../utils/parse';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export type CompleteNumberLine = string[];
export type FreeNumberLine = (number | null)[];

type Props = {
  /**
   * @param tickValues - Array representing the number line tick values/labels.
   * Can be one of 2 formats:
   * 1. Only the value to be shown (FreeNumberLine).
   * 2. Value to be shown along with the number of user answers at that specific tick (CompleteNumberLine).
   *
   * Option 1 {@link FreeNumberLine} supports the most basic of number lines along with very flexible answers boxes
   * allowing them to be positioned anywhere across the number line (using the freeNumberLineAnswer prop)
   *
   * Option 2 {@link CompleteNumberLine} supports a complete the number line style question and utilises the
   * TextStructure markup language (see {@link parseMarkup} for syntax) for all of the number line labels. Fractions
   * are supported using this type of number line, but there are some limitations.
   *
   */
  tickValues: CompleteNumberLine | FreeNumberLine;
  inputMaxCharacters?: number;
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  /**
   * @param freeNumberLineAnswer - Similar to `focusNumber`, but this accepts an array of number values of where
   * you want to place an answer box along the number line.
   */
  freeNumberLineAnswer?: number[];
  /**
   * @param focusNumber - Individual number to highlight with an arrow, but does not render an answer box. Used often
   * for questions regarding rounding.
   */
  focusNumber?: string | number;
  /**
   * @param extendFocusArrow - Makes the focus number arrow extend to touch the numberline rather than the tick. This
   * should only be used in questions that don't need to point to a tick value otherwise it will cause an overlap
   */
  extendFocusArrow?: boolean;
  /**
   * Boolean to decide whether to actually display the focus number on the component, above the arrow.
   * Optional prop, defaults to false.
   */
  showFocusNumber?: boolean;
  /**
   * @param userAnswer - State to hold the user input answer.
   */
  userAnswer?: string[];
  /**
   * @param onTextInput - What to do when a user inputs an answer.
   */
  onTextInput?: (answer: string, index: number) => void;
  /**
   * These 2 parameters are only necessary if the number line you're rendering won't have a label on the first or last
   * ticks. These are required for calculating the position to place arrows/answer boxes on the number line.
   * @param firstNumber - First number on the number line
   * @param lastNumber - Last number on the number line
   */
  firstNumber?: number;
  lastNumber?: number;
  /** Custom scale factor for text, defaults to 2.5 */
  scaleFactor?: number;
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
  /**
   * Custom vertical padding for text under ticks. Optional prop, defaults to 8.
   */
  textVerticalPad?: number;
  /**
   * Optional symbol shown at left of the input box. Takes either a string to be rendered
   * or a JSX.Element
   */
  inputLeadingSymbol?: string | JSX.Element;
  /**
   * Optional boolean to override the orange arrow with a prussianBlue one.
   */
  blueArrow?: boolean;
  /**
   * optional horizontal arrow shown above the center line with the given length.
   */
  horizontalArrowLength?: number;
  answerPositions?: 'top' | 'bottom';
  tickOffset?: number;
  /** Vertical bar model */
  vertical?: boolean;
};

const NumberLine = ({
  tickValues,
  inputMaxCharacters,
  dimens: { width, height },
  freeNumberLineAnswer,
  userAnswer = [''],
  firstNumber,
  lastNumber,
  focusNumber,
  extendFocusArrow = false,
  showFocusNumber = false,
  scaleFactor,
  customFontSize,
  inputLeadingSymbol,
  blueArrow,
  textVerticalPad = 8,
  horizontalArrowLength,
  onTextInput = () => {
    /* do nothing */
  },
  answerPositions,
  tickOffset,
  vertical
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  // Parse all the tick values. Each tick can have 0, 1 or more input boxes.
  const tickValuesParsed = tickValues.map(tick => parseMarkup(String(tick)));
  const showFractions = tickValuesParsed.some(tick => tick.tokens.some(el => el.type === 'frac'));

  const startNumber = firstNumber ?? Number(tickValues[0]);
  const finalNumber = lastNumber ?? Number(tickValues[tickValues.length - 1]);

  // Offset numberline in y direction to fit into available space
  const yOffset = freeNumberLineAnswer ? 64 : 36;

  // SVG Properties
  const lineColor = isPdf ? colors.black : colors.prussianBlue;
  const lineThickness = isPdf ? 4 : 2;

  const horizontalPad = 40;
  const pdfHorizontalPad = 140;
  const lineWidth = width - (isPdf ? horizontalPad + pdfHorizontalPad : 2 * horizontalPad);
  const tickHeight = isPdf ? 80 : 40;
  const ansTickHeight = showFractions || isPdf ? tickHeight : 64;
  const centerLineY = showFractions
    ? isPdf
      ? height / 2 - tickHeight
      : height / 4 - tickHeight
    : height / 2 - tickHeight + yOffset;
  const tickSpacing = lineWidth / (tickValues.length - 1);
  const startingTickX = horizontalPad;

  const styles = useStyles(width, height);
  const fontSize =
    customFontSize ??
    scaleNumberLineFontSize(
      tickValues.map(tick => {
        return tick?.toLocaleString() ?? '';
      }),
      lineWidth,
      displayMode,
      scaleFactor
    );

  // Center line
  const centerLine = (
    <Line
      x1={horizontalPad}
      y1={centerLineY}
      x2={width - (isPdf ? pdfHorizontalPad : horizontalPad)}
      y2={centerLineY}
      stroke={lineColor}
      strokeWidth={lineThickness}
      key={'CenterLine'}
    />
  );

  // renders a horizontal double ended arrow of length `horizontalArrowLength`
  const horizontalArrow = () => {
    if (horizontalArrowLength) {
      // work out the tick interval so that we can work out the number of ticks the arrow should cover
      const tickInterval = number(
        math.evaluate(`(${finalNumber} - ${startNumber}) / ${tickValues.length - 1}`)
      );

      // work out at which the length in terms of ticks
      const calculatedLength = number(
        math.evaluate(`(${horizontalArrowLength} - ${startNumber}) / ${tickInterval}`)
      );

      const arrowXEnd = startingTickX + calculatedLength * tickSpacing;

      return (
        <G key={'lengthArrow'}>
          <Polygon
            points={`${horizontalPad + 10},${centerLineY - 55} ${horizontalPad},${
              centerLineY - 50
            } ${horizontalPad + 10},${centerLineY - 45}`}
            fill={lineColor}
            stroke={lineColor}
          />
          <Line
            x1={horizontalPad}
            y1={centerLineY - 50}
            x2={arrowXEnd}
            y2={centerLineY - 50}
            stroke={lineColor}
            strokeWidth={displayMode === 'digital' ? 2 : 4}
          />
          <Polygon
            points={`${arrowXEnd - 10},${centerLineY - 55} ${arrowXEnd},${centerLineY - 50} ${
              arrowXEnd - 10
            },${centerLineY - 45}`}
            fill={lineColor}
            stroke={lineColor}
          />
        </G>
      );
    }
    return null;
  };

  // Ticks
  const ticks = tickValues.map((_tick, index) => {
    const tickX = startingTickX + index * tickSpacing;
    if (freeNumberLineAnswer) {
      return (
        <Line
          x1={tickX}
          y1={centerLineY - tickHeight / 2}
          x2={tickX}
          y2={centerLineY + tickHeight / 2}
          stroke={lineColor}
          strokeWidth={lineThickness}
          key={'tick' + index}
        />
      );
    }

    // Render different style of tick if answer box present
    if (tickValuesParsed[index].numberOfAns > 0) {
      return (
        <Line
          x1={tickX}
          y1={centerLineY - ansTickHeight / 2}
          x2={tickX}
          y2={centerLineY + ansTickHeight / 2}
          stroke={colors.burntSienna}
          strokeWidth={isPdf ? 8 : 4}
          key={'tick' + index}
        />
      );
    } else {
      return (
        <Line
          x1={tickX}
          y1={centerLineY - tickHeight / 2}
          x2={tickX}
          y2={centerLineY + tickHeight / 2}
          stroke={lineColor}
          strokeWidth={lineThickness}
          key={'tick' + index}
        />
      );
    }
  });

  let ansIndex = -1;
  let topOrBottom = 'top';
  // Tick Numbers
  const numberComponents = tickValues.map((tick, index) => {
    // CompleteNumberLine style (use TextStructure)
    if (typeof tick === 'string') {
      const ansCount = tickValuesParsed[index].numberOfAns;

      // Alternate between having answer box above/below centre line
      // Currently does not support fractions
      if (ansCount > 0) {
        topOrBottom = answerPositions ?? (topOrBottom === 'top' ? 'bottom' : 'top');
      }

      const tickX = startingTickX + index * tickSpacing;

      // Add number of answers for this tick to index
      ansIndex += ansCount;

      // Create scoped copy, otherwise all answer indices will equal final index
      const i = ansIndex;

      const negativeFrac = tick.includes(`${SUB}`) && tick.includes('<frac');
      const topOffset = isPdf ? (showFractions ? 280 : 150) : 96;
      const bottomFractionOffsetPdf = showFractions && isPdf ? 60 : 0;

      return (
        <View
          key={'tickNum' + index}
          style={[
            styles.completeNumberLineLabel,
            {
              left: tickX - 60,
              top:
                // Move answer box above/below line
                topOrBottom === 'top' && ansCount > 0
                  ? centerLineY - ansTickHeight / 2 - textVerticalPad - topOffset
                  : centerLineY +
                    ansTickHeight / 2 +
                    bottomFractionOffsetPdf -
                    textVerticalPad +
                    (ansCount === 0 ? tickOffset ?? 0 : 0)
            },
            vertical && { transform: 'rotate(-90deg)' }
          ]}
        >
          <TextStructure
            sentence={tick}
            inputBox={({ index }) => {
              // Adjust the answer index to be correct for the number of answers
              // This is done by the following calculation: i-count+(index+1)
              // i = the total number of answers to date including the answers at this tick
              // count = number of answers at this tick
              // index = the index of the answer at this tick (0,1,2)
              return (
                <NoKeyboardTextInput
                  key={i - ansCount + (index + 1)}
                  value={userAnswer[i - ansCount + (index + 1)]}
                  onChangeText={text => {
                    onTextInput(text, i - ansCount + (index + 1));
                  }}
                  maxCharacters={inputMaxCharacters}
                  style={{ fontSize }}
                />
              );
            }}
            textStyle={[styles.text, { fontSize }]}
            style={{ flexWrap: 'nowrap', width: negativeFrac ? 90 : undefined }}
            fractionTextStyle={{ fontSize }}
            fractionContainerStyle={{ height: ansCount > 0 ? 96 : 48 }}
          />
        </View>
      );
    }
    // FreeNumberLine Style (no TextStructure)
    else {
      const tickX = startingTickX + index * tickSpacing;

      return (
        <View
          key={'tickNum' + index}
          style={[
            styles.freeNumberLineLabel,
            { left: tickX - 60, top: centerLineY + tickHeight / 2 + textVerticalPad }
          ]}
        >
          <Text numberOfLines={1} style={[styles.text, { fontSize }]}>
            {tick !== null ? parseToSUB(tick.toLocaleString()) : undefined}
          </Text>
        </View>
      );
    }
  });

  // Render free hand answer boxes
  const freeHandAnswerComponents = freeNumberLineAnswer?.map((number, index) => {
    // Increment answer index
    ansIndex += 1;

    // Create scoped copy
    const i = ansIndex;

    // Calculate position by calculating percentage of the whole line width
    const tickX =
      ((number - startNumber) / (finalNumber - startNumber)) * lineWidth - horizontalPad / 2;
    return (
      <View key={'tickNum' + index}>
        {typeof inputLeadingSymbol === 'string' ? (
          <Text
            style={{
              fontSize: fontSize,
              left: isPdf ? tickX - 80 : tickX - 20,
              top: isPdf ? centerLineY - 220 : centerLineY - 125
            }}
          >
            {inputLeadingSymbol}
          </Text>
        ) : (
          inputLeadingSymbol
        )}
        <View
          style={[
            styles.freeHandAnswerContainer,
            { left: tickX, top: isPdf ? centerLineY - 260 : centerLineY - 145 }
          ]}
        >
          <NoKeyboardTextInput
            value={userAnswer[ansIndex]}
            onChangeText={text => {
              onTextInput(text, i);
            }}
            maxCharacters={inputMaxCharacters}
            style={{ fontSize }}
          />

          <View style={styles.freeHandArrowContainer}>
            <AssetSvg
              name="ArrowUpCustomizable"
              height={isPdf ? 115 : 40}
              width={isPdf ? 52 : 26}
              svgProps={{
                fill: blueArrow ? colors.prussianBlue : colors.burntSienna
              }}
            />
          </View>
        </View>
      </View>
    );
  });

  // Focus Number Case
  let focusArrow = null;
  if (focusNumber && typeof focusNumber === 'number') {
    const arrowAdjustment = displayMode === 'digital' ? 70 : 150;
    const tickX =
      startingTickX + ((focusNumber - startNumber) / (finalNumber - startNumber)) * lineWidth;

    focusArrow = (
      <View
        style={[
          styles.focusArrowContainer,
          { left: tickX - 40, top: centerLineY - arrowAdjustment }
        ]}
      >
        {showFocusNumber && (
          <Text style={{ fontSize: fontSize, transform: [{ rotate: '180deg' }], top: 88 }}>
            {focusNumber.toLocaleString()}
          </Text>
        )}
        <AssetSvg
          name="ArrowUpCustomizable"
          height={isPdf ? (extendFocusArrow ? 160 : 110) : extendFocusArrow ? 70 : 40}
          width={isPdf ? 52 : 26}
          svgProps={{
            fill: isPdf ? colors.black : blueArrow ? colors.prussianBlue : colors.burntSienna
          }}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={styles.svg}
        pointerEvents={'none'}
      >
        {centerLine}
        {horizontalArrow()}
        {ticks}
      </Svg>
      {numberComponents}
      {freeHandAnswerComponents}
      {focusArrow}
    </View>
  );
};

function useStyles(width: number, height: number) {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height
        },
        svg: {
          position: 'absolute',
          top: 0,
          left: 0
        },
        arrow: {
          width: 25,
          height: 39
        },
        text: {
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          overflow: 'visible'
        },
        completeNumberLineLabel: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120
        },
        freeNumberLineLabel: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120
        },
        freeHandAnswerContainer: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120
        },
        freeHandArrowContainer: {
          transform: [
            { rotate: '180deg' },
            { scaleY: 0.6 },
            { translateY: displayMode === 'digital' ? 14 : 42 }
          ]
        },
        focusArrowContainer: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 80,
          transform: [{ rotate: '180deg' }]
        }
      }),
    [width, height, displayMode]
  );
}

export default NumberLine;

/**
 * Simple wrapper around {@link NumberLine} which makes it easier to use with {@link withStateHOC}.
 * Replaces `onTextInput` prop (which works on one text input at a time) with `setUserAnswer` prop
 * (which works on all of the text inputs simultaneously).
 */
function NumberLineThatsEasierToUseWithWithState({
  setUserAnswer,
  ...props
}: Omit<Props, 'onTextInput'> & { setUserAnswer: SetState<string[]> }) {
  const onTextInput = useCallback(
    (answer: string, index: number) => {
      setUserAnswer(old => {
        const newArr = [...old];
        newArr[index] = answer;
        return newArr;
      });
    },
    [setUserAnswer]
  );
  return NumberLine({ onTextInput, ...props });
}

export const NumberLineWithState = withStateHOC(NumberLineThatsEasierToUseWithWithState, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer'
});
