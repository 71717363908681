import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { barModelColors } from '../../../../theme/colors';
import { filledArray, range } from '../../../../utils/collections';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Table from '../../../../components/molecules/Table';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import { compareFractions } from '../../../../utils/fractions';
import { all, create, number } from 'mathjs';
import { compareFloats } from '../../../../utils/math';
import {
  PartWholeModel,
  TextPartition
} from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from 'common/src/components/question/questionFormats/QF3InteractiveContent';
import { numbersDoNotExchange } from '../../../../utils/exchanges';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'awq',
  description: 'awq',
  keywords: ['100 square', 'Decimals', 'Hundredths'],
  schema: z.object({
    answer: z.number().int().min(1).max(99),
    shadedOrNot: z.enum(['shaded', 'not shaded'])
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(1, 99);
    const shadedOrNot = getRandomFromArray(['shaded', 'not shaded'] as const);

    return { answer, shadedOrNot };
  },
  Component: props => {
    const {
      question: { answer, shadedOrNot },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create table of colors
    const numOfRows = Math.floor(answer / 10);
    const fullRowsShaded = filledArray(
      filledArray(shadingColor, 10),
      shadedOrNot === 'shaded' ? numOfRows : 10 - numOfRows - 1
    );

    const leftover = answer - numOfRows * 10;
    const crossoverShaded = filledArray(
      shadingColor,
      shadedOrNot === 'shaded' ? leftover : 10 - leftover
    );
    const crossoverNotShaded = filledArray(
      'white',
      shadedOrNot === 'shaded' ? 10 - leftover : leftover
    );
    const crossover = [...crossoverShaded, ...crossoverNotShaded];

    const notShaded = filledArray(
      filledArray('white', 10),
      shadedOrNot === 'shaded' ? 10 - numOfRows - 1 : numOfRows
    );

    fullRowsShaded.push(crossover);
    const colors = fullRowsShaded.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 40,
            height: 40
          }}
        />
      ))
    );

    return (
      <QF1ContentAndSentence
        title={
          shadedOrNot === 'shaded'
            ? translate.instructions.hundredSquareRepresentsWholeHowManyXAreShaded({
                measure: translate.fractions.hundredths(answer)
              })
            : translate.instructions.hundredSquareRepresentsWholeHowManyXAreNotShaded({
                measure: translate.fractions.hundredths(answer)
              })
        }
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View style={dimens}>
            <Table items={table} />
          </View>
        )}
      />
    );
  }
});
const Question2 = newQuestionContent({
  uid: 'awr',
  description: 'awr',
  keywords: ['Part-whole model', 'Addition', 'Number bonds', 'Decimals', 'Hundredths'],
  schema: z.object({
    givenDecimal: z.number().min(0.01).max(0.99).step(0.01),
    givenPart: z.enum(['left', 'right'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const givenDecimal = randomIntegerInclusive(1, 99) / 100;

    const givenPart = getRandomFromArray(['left', 'right'] as const);

    return { givenDecimal, givenPart };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenDecimal, givenPart } = question;

    // Need mathjs' formatting to account for JavaScript round-off errors:
    const answerPart = number(math.evaluate(`1 - ${givenDecimal}`));

    let partition: TextPartition = [];

    if (givenPart === 'left') {
      partition = ['$ans', givenDecimal.toLocaleString()];
    } else {
      partition = [givenDecimal.toLocaleString(), '$ans'];
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={displayMode === 'markscheme' ? [answerPart.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], answerPart)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={(1).toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aws',
  description: 'aws',
  keywords: ['Part-whole model', 'Addition', 'Number bonds', 'Decimals', 'Hundredths'],
  schema: z
    .object({
      givenDecimalA: z.number().min(0.01).max(0.97).step(0.01),
      givenDecimalB: z.number().min(0.01).max(0.97).step(0.01),
      answerPosition: z.enum(['left', 'middle', 'right'])
    })
    .refine(
      val => val.givenDecimalA + val.givenDecimalB < 1,
      'givenDecimalA + givenDecimalB must be less than 1'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const decimalA = randomIntegerInclusive(1, 97);

    const decimalB = randomIntegerInclusive(1, Math.min(99 - decimalA, 97), {
      constraint: x => numbersDoNotExchange(x, decimalA)
    });

    const [givenDecimalA, givenDecimalB] = shuffle([decimalA / 100, decimalB / 100]);

    const answerPosition = getRandomFromArray(['left', 'middle', 'right'] as const);

    return { givenDecimalA, givenDecimalB, answerPosition };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenDecimalA, givenDecimalB, answerPosition } = question;

    // Need mathjs' formatting to account for JavaScript round-off errors:
    const answerPart = number(math.evaluate(`1 - ${givenDecimalA} - ${givenDecimalB}`));

    const partition = (() => {
      switch (answerPosition) {
        case 'left':
          return [givenDecimalA.toLocaleString(), givenDecimalB.toLocaleString(), '$ans'];
        case 'middle':
          return [givenDecimalA.toLocaleString(), '$ans', givenDecimalB.toLocaleString()];
        case 'right':
          return ['$ans', givenDecimalA.toLocaleString(), givenDecimalB.toLocaleString()];
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={displayMode === 'markscheme' ? [answerPart.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], answerPart)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={(1).toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awt',
  description: 'awt',
  keywords: ['Hundredths', 'Addition', 'One whole'],
  schema: z.object({
    numberArray: z.number().min(0.01).max(0.99).array().length(7),
    sumOrNot: z.enum(['do', 'do not'])
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray(range(0.1, 0.9, 0.1));
    const number2 = number(math.evaluate(`1 - ${number1}`));
    const number3 = number(math.evaluate(`${number2} / 10`));
    const number4 = number(math.evaluate(`${number1} / 10`));
    const number5 = getRandomFromArray(range(0.1, 0.9, 0.1));
    const number7 = number(math.evaluate(`${number5} / 10`));
    const number6 = number(math.evaluate(`1 - ${number7}`));

    const numberArray = [number1, number2, number3, number4, number5, number6, number7];

    const sumOrNot = getRandomFromArray(['do', 'do not'] as const);

    return { numberArray, sumOrNot };
  },
  Component: props => {
    const {
      question: { numberArray, sumOrNot },
      translate
    } = props;

    const [number1, number2, number3, number4, number5, number6, number7] = numberArray;
    const options = [
      {
        component: `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
        correct: number(math.evaluate(`${number1} + ${number2}`)) === 1
      },
      {
        component: `${number1.toLocaleString()} ${ADD} ${number3.toLocaleString()}`,
        correct: number(math.evaluate(`${number1} + ${number3}`)) === 1
      },
      {
        component: `${number4.toLocaleString()} ${ADD} ${number3.toLocaleString()}`,
        correct: number(math.evaluate(`${number4} + ${number3}`)) === 1
      },
      {
        component: `${number5.toLocaleString()} ${ADD} ${number6.toLocaleString()}`,
        correct: number(math.evaluate(`${number5} + ${number6}`)) === 1
      },
      {
        component: `${number7.toLocaleString()} ${ADD} ${number6.toLocaleString()}`,
        correct: number(math.evaluate(`${number7} + ${number6}`)) === 1
      },
      {
        component: `${number6.toLocaleString()} ${ADD} ${number7.toLocaleString()}`,
        correct: number(math.evaluate(`${number6} + ${number7}`)) === 1
      },
      {
        component: `${number6.toLocaleString()} ${ADD} ${number5.toLocaleString()}`,
        correct: number(math.evaluate(`${number6} + ${number5}`)) === 1
      }
    ];

    const shuffledOptions = getRandomSubArrayFromArray(options, 6, {
      random: seededRandom(props.question)
    });

    const correctAnswers = shuffledOptions
      .filter(opt => (sumOrNot === 'do' ? opt.correct : !opt.correct))
      .map(it => it.component);

    return (
      <QF10SelectNumbers
        title={
          sumOrNot === 'do'
            ? translate.instructions.selectCalcsThatSumToOne()
            : translate.instructions.selectCalcsThatDoNotSumToOne()
        }
        testCorrect={correctAnswers}
        items={shuffledOptions.map(({ component }) => ({
          component,
          value: component
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'awu',
  description: 'awu',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition'],
  schema: z.object({
    number1: z.number().min(0.1).max(0.9).step(0.1),
    number2: z.number().min(0.01).max(0.09).step(0.01)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray(range(0.1, 0.9, 0.1));
    const number2 = number(math.evaluate(`${number1} / 10`));

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // Calculate answers
    const answer1 = math.evaluate(`1 - ${number1}`);
    const answer2 = math.evaluate(`1 - ${number2}`);

    const random = seededRandom(props.question);
    const sentenceANums = shuffle([number1.toLocaleString(), '<ans/>'], { random });
    const sentenceBNums = shuffle([number2.toLocaleString(), '<ans/>'], { random });

    // Sentences to display
    const sentenceA = `${sentenceANums[0]} ${ADD} ${sentenceANums[1]} = 1`;
    const sentenceB = `${sentenceBNums[0]} ${ADD} ${sentenceBNums[1]} = 1`;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={3}
        sentences={[sentenceA, sentenceB]}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awv',
  description: 'awv',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(98)
      .array()
      .length(3)
      .refine(val => number(math.format(math.sum(val))) === 100, 'numerators must sum to 100'),
    numbersAnsPosition: numberEnum([0, 1, 2]),
    fractionOrDecimal: z.enum(['decimal', 'fraction'])
  }),
  simpleGenerator: () => {
    const fractionOrDecimal = getRandomFromArray(['decimal', 'fraction'] as const);
    const numberA = randomIntegerInclusive(1, 98);
    const numberB = randomIntegerInclusive(1, 99 - numberA, {
      constraint: x => numbersDoNotExchange(x, numberA)
    });
    const numberC = 100 - (numberA + numberB);
    const numbers = [numberA, numberB, numberC];
    const numbersAnsPosition = getRandomFromArray([0, 1, 2] as const);

    return {
      numbers,
      numbersAnsPosition,
      fractionOrDecimal
    };
  },
  Component: props => {
    const {
      question: { numbers, numbersAnsPosition, fractionOrDecimal },
      translate
    } = props;

    const fracOrDecimalNumbers =
      fractionOrDecimal === 'fraction'
        ? numbers.map(num => `<frac n='${num.toLocaleString()}' d='100' />`)
        : numbers.map(num => number(math.evaluate(`${num} / 100`)).toLocaleString());

    const fracOrDecimalAns =
      fractionOrDecimal === 'fraction'
        ? numbers[numbersAnsPosition]
        : numbers.map(num => number(math.evaluate(`${num} / 100`)))[numbersAnsPosition];

    fracOrDecimalNumbers[numbersAnsPosition] =
      fractionOrDecimal === 'fraction' ? `<frac nAns='' dAns='' />` : '<ans/>';

    const sentence = fracOrDecimalNumbers.join(` ${ADD} `) + ' = 1';

    return (
      <QF2AnswerBoxManySentences
        title={
          fractionOrDecimal === 'fraction'
            ? translate.instructions.fillInMissingNumbers()
            : translate.instructions.fillInMissingNumber()
        }
        testCorrect={userAnswer =>
          fractionOrDecimal === 'fraction'
            ? compareFractions([...userAnswer[0]], [fracOrDecimalAns, 100])
            : compareFloats(userAnswer[0][0], fracOrDecimalAns)
        }
        inputMaxCharacters={4}
        sentences={[sentence]}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[fracOrDecimalAns.toLocaleString(), (100).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeAWholeWithHundredths',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
