import { all, create, equal, number } from 'mathjs';
import { range } from './collections';
import { z } from 'zod';
import { DECIMAL_POINT } from '../constants';

export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export const DigitSchema = z.number().int().min(0).max(9) as z.Schema<Digit>;
export const NonNegativeIntegerSchema = z.number().int().min(0);

// Initialise mathjs to use maximum precision
const math = create(all, { precision: 14, number: 'BigNumber' });

/**
 * A number represented in scientific notation, e.g. -7.142e-3 for -0.007142.
 *
 * Always in simplest form, with no leading zeroes or trailing zeroes, unless the number is 0 (i.e. 0e0). Avoids
 * problems with the `number` class relating to floating point arithmetic, at the cost of slower algorithms and higher
 * memory requirements... but these are both negligable for our purposes.
 *
 * Negation, addition and subtraction have been implemented as non-static methods of this class, as well as getting
 * and setting individual digits. Direct mutation is not allowed - this class is treated as immutable, and changes are
 * made by making a copy.
 *
 * Multiplication and division have not been implemented, as we can't really do any better than converting to
 * `number`, performing the calculation inexactly, and converting back.
 */
export class ScientificNotation {
  /**
   * Mantissa, expressed as an array - the digits with the digit at index 0 being worth 10^e, the next 10^(e-1), etc.
   * Also, the length of this array represents the number of significant digits.
   */
  readonly digits: readonly Digit[];
  /** Exponent - the power of 10 that the first digit is worth. */
  readonly e: number;
  /** Whether the number is negative or not. */
  readonly negative: boolean;

  /** The power of 10 associated with the smallest stored digit. */
  get resolution(): number {
    return this.e - (this.digits.length - 1);
  }

  /** Private constructor, which doesn't simplify (so make sure to simplify after if required). */
  private constructor(digits: readonly Digit[], e: number, negative = false) {
    this.digits = digits;
    this.e = e;
    this.negative = negative;
  }

  /** Public constructor, which converts the inputs into simplest form. */
  static create(
    digits: readonly Digit[],
    e = digits.length - 1,
    negative = false
  ): ScientificNotation {
    return new ScientificNotation(digits, e, negative).simplify();
  }

  /** Private copy function for convenience, which doesn't simplify (so make sure to simplify after if required). */
  private copy({ digits = this.digits, e = this.e, negative = this.negative }): ScientificNotation {
    return new ScientificNotation(digits, e, negative);
  }

  /**
   * Remove leading and trailing zeroes, possibly changing the exponent.
   *
   * This function is private because we never expose any un-simplified instances of this class - we only use them
   * internally.
   */
  private simplify(): ScientificNotation {
    const digits = this.digits.slice();
    let newE = this.e;

    // Remove leading 0s
    while (digits[0] === 0) {
      digits.shift();
      newE -= 1;
    }

    // Remove trailing 0s
    while (digits[digits.length - 1] === 0) {
      digits.pop();
    }

    // If result is zero - return the special zero value, as otherwise it's ambiguous.
    if (digits.length === 0) {
      return ScientificNotation.ZERO;
    }

    return new ScientificNotation(digits, newE, this.negative);
  }

  static readonly ZERO = new ScientificNotation([0], 0);

  static powerOfTen(power: number | PowersOfTenWord): ScientificNotation {
    power = typeof power === 'string' ? powersOfTenWordToPow[power] : power;
    return new ScientificNotation([1], power);
  }

  /**
   * Create from a floating point number. Optionally provide significant figures, otherwise the maximum significant
   * figures that the float is known to will be used.
   *
   * @param {number} input the number to get a decimal expansion for
   * @param significantFigures positive integer - the number of digits after the first to write out
   */
  static fromNumber(input: number, significantFigures = 10): ScientificNotation {
    // The inbuilt toExponential does all the work.
    const inputString = input.toExponential(significantFigures);

    return ScientificNotation.fromExponentialString(inputString);
  }

  /**
   * Create Scientific Notation representation of a number from a fixed string.
   *
   * @param {string} input the number to get a decimal expansion for
   * @returns {ScientificNotation}
   */
  static fromFixedString(input: string): ScientificNotation {
    const [integer, decimal] = input.split('.');
    const sign = parseInt(integer) < 0;

    const integerPart = integer.replace(/-|\./g, '');

    const integerDigits = Array.from(integerPart);
    const decimalDigits = decimal ? Array.from(decimal) : [];

    const digits = [...integerDigits, ...decimalDigits];

    const expo = integerPart.length - 1;

    return new ScientificNotation(
      Array.from(digits).map(it => parseInt(it) as Digit),
      expo,
      sign
    ).simplify();
  }

  /**
   * Create Scientific Notation representation of a number from an exponential string.
   *
   * @param {string} input the number to get a decimal expansion for
   * @returns {ScientificNotation}
   */
  static fromExponentialString(input: string): ScientificNotation {
    const [mantissa, exponent] = input.split('e');
    const sign = parseInt(mantissa) < 0;
    const digitsString = mantissa.replace(/-|\./g, '');

    return ScientificNotation.create(
      Array.from(digitsString).map(it => parseInt(it) as Digit),
      parseInt(exponent),
      sign
    ).simplify();
  }

  /** Returns the floating point number represented. */
  toNumber(): number {
    const [firstDigit, ...digits] = this.digits;
    const numberString =
      (this.negative ? '-' : '') + firstDigit + '.' + digits.join('') + 'e' + this.e;

    // The inbuilt parseFloat does all the work.
    return parseFloat(numberString);
  }

  /** Create from Base10Oject. */
  static fromBase10Object(input: Base10Object): ScientificNotation {
    // Go via number, to reduce the code we need to write.
    return ScientificNotation.fromNumber(base10ObjectToNumber(input));
  }

  /**
   * Convert to a {@link Base10Object}. Note that any digits from place values outside of what Base10Object can
   * represent will just be lost.
   */
  toBase10Object(): Base10Object {
    const base10Object: Base10Object = {};
    Object.entries(powersOfTenWordToPow).forEach(([word, pow]) => {
      base10Object[word as PowersOfTenWord] = this.digitAt(pow);
    });
    return base10Object;
  }

  /**
   * The digit in this representation associated with the given power of ten.
   *
   * Note that this just looks at the digits and ignores whether the number itself was positive or negative. So for
   * example getting the tens digit of -210 will give 1.
   */
  unsignedDigitAt(power: number | PowersOfTenWord): Digit {
    power = typeof power === 'string' ? powersOfTenWordToPow[power] : power;
    return this.digits[this.e - power] ?? 0;
  }

  /** Like {@link unsignedDigitAt}, but negative if this object represents a negative number. */
  digitAt(power: number | PowersOfTenWord): number {
    power = typeof power === 'string' ? powersOfTenWordToPow[power] : power;
    return this.unsignedDigitAt(power) * (this.negative ? -1 : 1);
  }

  /**
   * Set the digit with the given power of ten to a new value.
   *
   * Note that this just updates the digits, and the overall number will keep the sign it had before. So for example
   * updating -210 with a 3 at the thousands digit will result in -3210.
   */
  setUnsignedDigitAt(
    power: number | PowersOfTenWord,
    newDigit: Digit | ((old: Digit) => Digit)
  ): ScientificNotation {
    power = typeof power === 'string' ? powersOfTenWordToPow[power] : power;
    const oldDigit = this.unsignedDigitAt(power);
    newDigit = typeof newDigit === 'function' ? newDigit(this.unsignedDigitAt(power)) : newDigit;
    return this.minus(new ScientificNotation([oldDigit], power))
      .add(new ScientificNotation([newDigit], power))
      .simplify();
  }

  /** Add one instance of this class to another (or directly add another number). */
  add(other: ScientificNotation | number): ScientificNotation {
    other = other instanceof ScientificNotation ? other : ScientificNotation.fromNumber(other);
    const e = Math.max(this.e, other.e) + 1; // Highest we can go is 1 more than the things we're adding
    const resolution = Math.min(this.resolution, other.resolution);
    const digits = Array(e - resolution + 1).fill(0) as [Digit | -1, ...Digit[]];

    // If both negative, add their negations
    if (this.negative && other.negative) {
      return this.negation.add(other.negation).negation;
    }

    // Add each position, starting with the smallest powers, remembering to carry ones.
    for (let pow = resolution; pow <= e; pow++) {
      const digit = (digits[e - pow] ?? 0) + this.digitAt(pow) + other.digitAt(pow);
      // Can assume at least one number is positive. This means when adding each position, the highest it can be is
      // 9+9 (with carried 1), i.e. 19, and the lowest it can be is 0-9 (with carried -1), i.e. -10.
      if (10 <= digit && digit < 20) {
        digits[e - pow] = (digit - 10) as Digit;
        digits[e - pow - 1] = 1;
      } else if (0 <= digit && digit < 10) {
        digits[e - pow] = digit as Digit;
      } else if (-10 <= digit && digit < 0) {
        if (pow === e) {
          digits[e - pow] = digit as Digit;
        } else {
          digits[e - pow] = (digit + 10) as Digit;
          digits[e - pow - 1] = -1;
        }
      } else {
        throw Error('Logic error - needed to carry a 2 in an addition');
      }
    }

    // This result is either positive with all positive digits, or negative with a leading -1 and the rest being positive
    // digits, e.g. [-1, 7, 9, 0].
    const [first, ...rest] = digits;
    if (first !== -1) {
      // Positive result - simplify and return;
      return new ScientificNotation([first as Digit, ...rest], e).simplify();
    }

    // Negative result. Our canonical notation uses the "negative" flag, which indicates that all digits are
    // considered negative. However, the result currently only has a leading -1, and the rest are positive.
    // E.g. we have [-1, 7, 9, 0] and we want to write this like -([2, 1, 0]). We do this using another addition:
    // -(1000 + -790) = -210.
    // This involves calling this function (add) from within itself. Note that this addition (e.g. 1000 + -790) always
    // has a positive result, meaning that this cannot cause a recursion, and it will be put in simplest form.
    const largeNumber = new ScientificNotation([1], e);
    const smallNumberNegative = new ScientificNotation(rest, e - 1, true);
    return largeNumber.add(smallNumberNegative).negation;
  }

  get negation(): ScientificNotation {
    return this.copy({ negative: !this.negative }); // No simplification required after such a change
  }

  /** Minus one instance of this class to another (or directly minus another number). */
  minus(other: ScientificNotation | number): ScientificNotation {
    other = other instanceof ScientificNotation ? other : ScientificNotation.fromNumber(other);
    return this.add(other.negation);
  }

  equals(other: ScientificNotation | number): boolean {
    // For some reason typescript wasn't happy with `other = other instanceof ...` as it was for the other methods.
    const otherScientific =
      other instanceof ScientificNotation ? other : ScientificNotation.fromNumber(other);

    // Relies on this and other being in simplest form, which should always be the case.
    return (
      this.e === otherScientific.e &&
      this.negative === otherScientific.negative &&
      this.digits.length === otherScientific.digits.length &&
      this.digits.every((x, i) => otherScientific.digits[i] === x)
    );
  }

  multiply(other: ScientificNotation | number): ScientificNotation {
    other = other instanceof ScientificNotation ? other : ScientificNotation.fromNumber(other);

    // Evaluate negative or positive
    const negative = this.negative !== other.negative;

    // Treat digits as number to avoid floating point error when handling decimals
    const a = parseInt(this.digits.join(''));
    const b = parseInt(other.digits.join(''));

    // Calculate offset for each
    const aOffset = this.digits.length - 1;
    const bOffset = other.digits.length - 1;

    let product = ScientificNotation.fromNumber(a * b);
    // Have to use copy as properties are readonly
    product = product.copy({ e: product.e - (aOffset + bOffset - (this.e + other.e)), negative });
    return product.simplify();
  }

  divide(other: ScientificNotation | number, significantFigures = 10): ScientificNotation {
    other = other instanceof ScientificNotation ? other : ScientificNotation.fromNumber(other);

    // Treat digits as number to avoid floating point error when handling decimals
    const number1 = this.digits.join('');
    const number2 = other.digits.join('');

    // Calculate exponent
    const productExponent = this.e - other.e;

    const division = (parseInt(number1) / parseInt(number2)) * 10 ** productExponent;

    return ScientificNotation.fromFixedString(division.toFixed(significantFigures));
  }
}

/**
 * Another way we represent a number in base 10 is with an object, with wordy keys representing the position.
 *
 * These keys are all _optional_, so you can distinguish the digit 0 with no digit in that position. However, this
 * means when reading digits off from this you might want to use defaulting notation: either
 * `const test = foo.tens ?? 0;` or `const {tens=0} = foo;`
 *
 * Also note that this is not neatly bundled up in a class like `ScientificNotation`, since we sometimes use it in
 * data that needs to be serialized, such as question data.
 */
export type Base10Object = { [place in PowersOfTenWord]?: number };

export function base10ObjectToNumber(base10: Base10Object): number {
  return Object.entries(base10)
    .map(([word, num]) => Math.pow(10, powersOfTenWordToPow[word as PowersOfTenWord]) * num)
    .reduce((sum, num) => number(math.evaluate(`${sum} + ${num}`)), 0);
}

export function numberToBase10Object(number: number): Base10Object {
  // Go via ScientificNotation to reduce the code we need to write
  return ScientificNotation.fromNumber(number).toBase10Object();
}

/** Keys supported by {@link Base10Object}. These are not translated. */
export type PowersOfTenWord = keyof typeof powersOfTenWordToPow;
export function isPowersOfTenWord(x: string): x is PowersOfTenWord {
  return Object.keys(powersOfTenWordToPow).includes(x);
}

/** Powers of 10 supported by {@link Base10Object}. */
export type Pow = (typeof powersOfTenWordToPow)[PowersOfTenWord];
export function isPow(x: number): x is Pow {
  return Object.values(powersOfTenWordToPow).includes(x as Pow);
}

/** Object to convert between keys of {@link Base10Object} and the powers they represent. */
export const powersOfTenWordToPow = {
  millions: 6,
  hundredThousands: 5,
  tenThousands: 4,
  thousands: 3,
  hundreds: 2,
  tens: 1,
  ones: 0,
  tenths: -1,
  hundredths: -2,
  thousandths: -3,
  tenThousandths: -4
} as const;

/** Object to convert between powers of 10 supported by {@link Base10Object} and the its keys. */
export const powersOfTenPowToWord = {
  6: 'millions',
  5: 'hundredThousands',
  4: 'tenThousands',
  3: 'thousands',
  2: 'hundreds',
  1: 'tens',
  0: 'ones',
  [-1]: 'tenths',
  [-2]: 'hundredths',
  [-3]: 'thousandths',
  [-4]: 'tenThousandths'
} as const;

/**
 * Function to round a number to the nearest integer
 * @param numberToRound The number to round
 * @param round The nearest number to round to.
 * @param direction The direction that we're allowed to round.
 * @returns rounded number
 */
export function roundToTheNearest(
  numberToRound: number,
  round: number,
  direction: 'up' | 'down' | 'either' = 'either'
): number {
  const roundingFunction =
    direction === 'up' ? Math.ceil : direction === 'down' ? Math.floor : Math.round;

  const numberToRoundFactored = number(math.evaluate(`${numberToRound} / ${round}`));

  return number(math.evaluate(`${roundingFunction(numberToRoundFactored)} * ${round}`));
}

/**
 * Function to round a number to an amount of significant figures.
 * @param number The number to round.
 * @param numOfSignificantFigures The amount of significant figures to round to. Must be a positive integer.
 * @returns Number rounded to the specified amount of significant figures.
 */
export function roundToSignificantFigures(number: number, numOfSignificantFigures: number): number {
  return ScientificNotation.fromNumber(number, numOfSignificantFigures - 1).toNumber();
}

/** Counts the number of digits in the passed number that are zero. */
export const numberOfZeroDigits = (number: number) => number.toString().split('0').length - 1;

/** Counts the number of digits in the passed number that aren't zero. */
export const numberOfNonZeroDigits = (number: number) =>
  number.toString().length - numberOfZeroDigits(number);

/** Check if a digit of a number at a certain power is any of the numbers from an array */
export const digitAtPowerIsNumber = (
  number: number,
  power: number | PowersOfTenWord,
  endsIn: number[]
) => {
  return endsIn.includes(ScientificNotation.fromNumber(number).unsignedDigitAt(power));
};

/**
 * Returns the passed-in number with an enforced digit in a particular power of ten.
 */
export const numberWithEnforcedDigit = (
  numberToChange: number,
  powerToChange: number,
  digit: Digit
) => {
  return ScientificNotation.fromNumber(numberToChange)
    .setUnsignedDigitAt(powerToChange, digit)
    .toNumber();
};

/**
 * Function to check each number's digit is 0. Returns an array of powers of ten where this is true.
 */
export function findZeroesInt(n: number): number[] {
  const sci = ScientificNotation.fromNumber(n);
  return range(0, sci.e).filter(pow => sci.digitAt(pow) === 0);
}

/** Convert an integer into words.
 * Limited to English only
 * Limited to trillions
 * Some of the rules we try to follow:
 * 1. tens and ones are joined together with a hyphen (-) ie twenty-one
 * 2. 'ands' only precede tens and ones.
 * 3. Only use 'ands' if another digit precedes the and.
 * 4. Commas are used to separate other digit nominations
 */
export const integerToWord = (number: number) => {
  if (!Number.isInteger(number)) {
    throw new Error('Number is not an integer');
  }
  if (number === 0) {
    return 'zero';
  }
  const sciNotation = ScientificNotation.fromNumber(number);
  const onesAndTeens = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ];
  const thousands = ['', 'thousand', 'million', 'billion', 'trillion'];
  let tripletCount = 0;
  const numberDigits = number.toString().length;
  let remainingDigits = numberDigits;
  let totalWord = '';

  /* Numerical words are pronounced in blocks of threes (triplets)
   * The 'and' in these words precedes the tens and ones. No other nomination has a preceding 'and'
   */
  while (remainingDigits > 0) {
    let word = '';

    // Put in the hundreds if required
    if (sciNotation.unsignedDigitAt(tripletCount * 3 + 2) > 0) {
      word = onesAndTeens[sciNotation.unsignedDigitAt(tripletCount * 3 + 2)] + 'hundred';
    }

    const firstTwoDigits =
      sciNotation.unsignedDigitAt(tripletCount * 3 + 1) * 10 +
      sciNotation.unsignedDigitAt(tripletCount * 3);

    // Add the 'and' if it is required
    // If there are tens and ones and the hundreds precede it
    if (firstTwoDigits !== 0 && word.length !== 0 && tripletCount !== 0) {
      if (remainingDigits - 3 > 0 || word.endsWith('hundred')) {
        word += ' and ';
      }
    }
    // Else if there are thousands etc before it
    else if (tripletCount === 0 && firstTwoDigits !== 0) {
      if (word.length !== 0 || remainingDigits - 3 > 0) {
        word += ' and ';
      }
    }

    // Put in the tens and ones
    if (firstTwoDigits < 20) {
      word += onesAndTeens[firstTwoDigits];
    } else {
      const spaceOrHyphen = sciNotation.unsignedDigitAt(tripletCount * 3) > 0 ? '-' : ' ';
      word +=
        tens[sciNotation.unsignedDigitAt(tripletCount * 3 + 1)] +
        spaceOrHyphen +
        onesAndTeens[sciNotation.unsignedDigitAt(tripletCount * 3)];
    }

    // Add the thousands if required
    if (
      sciNotation.unsignedDigitAt(tripletCount * 3) !== 0 ||
      sciNotation.unsignedDigitAt(tripletCount * 3 + 1) !== 0 ||
      sciNotation.unsignedDigitAt(tripletCount * 3 + 2) !== 0
    ) {
      word +=
        word[word.length - 1] === ' ' ? thousands[tripletCount] : ' ' + thousands[tripletCount];
    }

    // Add the comma if totalWord already contains numbers and the totalWord doesn't begin with ' a' from and.
    let comma = '';
    if (totalWord.length > 0 && totalWord[1] !== 'a') {
      // If the ones, tens and hundreds are not 0
      if (
        sciNotation.unsignedDigitAt(tripletCount * 3 - 1) !== 0 ||
        sciNotation.unsignedDigitAt(tripletCount * 3 - 2) !== 0 ||
        sciNotation.unsignedDigitAt(tripletCount * 3 - 3) !== 0
      ) {
        comma = ', ';
      }
    }
    // Build the word
    totalWord = word + comma + totalWord;
    // Move to the next triplet
    tripletCount += 1;
    remainingDigits -= 3;
  }
  // Remove trailing whitespace
  return totalWord.trim();
};

/**
 * A simple function to compare two numbers, that then returns a string of '<', '>' or '='.
 * If the first number is less than the second number, returns '<'.
 * If the first number is greater than the second number, returns '>'.
 * If the first number is equal to the second number, returns '='.
 */
export function lessThanGreaterThanOrEqualTo(firstNumber: number, secondNumber: number) {
  if (firstNumber < secondNumber) {
    return '<';
  } else if (firstNumber > secondNumber) {
    return '>';
  } else {
    return '=';
  }
}

/**
 * Easy way to check if number is within a certain accuracy range of a target number.
 * e.g. checking user answer when estimating number on numberline with +/- 10 accuracy.
 * @param num - Number that must be within accuracy range of target.
 * @param targetNum - Target Number.
 * @param accuracyRange - Value for permitted accuracy range (must always be positive).
 * @returns {boolean}
 */
export const isWithinRange = (num: number, targetNum: number, accuracyRange: number): boolean => {
  return Math.abs(num - targetNum) <= accuracyRange;
};

/**
 * Compare 2 floating point numbers. Similar to compareFractions {@link compareFractions}
 */
export const compareFloats = (
  userAnswer: string | number,
  expectedAnswer: string | number
): boolean => {
  // Decimals that immediately start with a decimal point must always be marked as wrong:
  if (typeof userAnswer === 'string' && userAnswer[0] === DECIMAL_POINT) {
    return false;
  }
  try {
    return equal(userAnswer, expectedAnswer) as boolean;
  } catch {
    return false;
  }
};

/**
 * Check if decimal number is valid, expects number to only
 */
export const isValidDecimalString = (decimalString: string): boolean => {
  // Decimals that immediately start with a decimal point must always be marked as wrong:
  if (decimalString[0] === DECIMAL_POINT) {
    return false;
  }
  try {
    number(decimalString);
    return true;
  } catch {
    return false;
  }
};

/**
 * Returns the smallest integer n such that the nth triangular number
 * is greater than or equal to the given number,
 * e.g. 7 would return 4, as the 4th triangular number (10)
 * is the smallest triangle number that is greater than 7.
 */
export const getMinTriangularNumber = (number: number) => {
  let n = 1;
  let triangleNumber = (n * (n + 1)) / 2;
  while (number > triangleNumber) {
    n++;
    triangleNumber = (n * (n + 1)) / 2;
  }
  return n;
};
