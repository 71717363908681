import { mod } from 'mathjs';
import { type ViewStyle } from 'react-native';

export const DEGREES_PER_RAD = 360 / (2 * Math.PI);
export const RADS_PER_DEGREE = 1 / DEGREES_PER_RAD;

/**
 * Convert polar coordinates (radius and angle in degrees) relative to a given center to cartesian coordinates (x and
 * y).
 *
 * Note that the angle rotates around _anti-clockwise_ starting pointing right.
 * If interpreted in screen coordinates (where y points down), the angle rotates around _clockwise_ starting pointing
 * right.
 */
export function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  'worklet';
  const angleInRadians = angleInDegrees * RADS_PER_DEGREE;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
}

/**
 * Calculate the SVG path for a circular arc. This should be used in the `d` prop of a `Path` component.
 *
 * Assuming screen coordinates (y axis points down), the angle is drawn clockwise from the start angle to the end
 * angle.
 */
export function describeArc(
  centerX: number,
  centerY: number,
  radius: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  startAngle: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  endAngle: number
) {
  'worklet';
  const start = polarToCartesian(centerX, centerY, radius, endAngle - 90);
  const end = polarToCartesian(centerX, centerY, radius, startAngle - 90);
  let largeArcFlag = '0';
  if (endAngle >= startAngle) {
    largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  } else {
    largeArcFlag = endAngle + 360.0 - startAngle <= 180 ? '0' : '1';
  }
  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' '
  );
  return d;
}

/**
 * Calculate the SVG path for a circular sector. This should be used in the `d` prop of a `Path` component.
 *
 * Assuming screen coordinates (y axis points down), the angle is drawn clockwise from the start angle to the end
 * angle.
 */
export function describeSector(
  centerX: number,
  centerY: number,
  radius: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  startAngle: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  endAngle: number
) {
  'worklet';
  const start = polarToCartesian(centerX, centerY, radius, endAngle - 90);
  const end = polarToCartesian(centerX, centerY, radius, startAngle - 90);
  let largeArcFlag = '0';
  if (endAngle >= startAngle) {
    largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  } else {
    largeArcFlag = endAngle + 360.0 - startAngle <= 180 ? '0' : '1';
  }
  return (
    `M${centerX},${centerY} ` +
    `L${start.x},${start.y} ` +
    `A${radius},${radius} 0 ${largeArcFlag},0 ${end.x},${end.y} ` +
    `Z`
  );
}

/**
 * Calculate the SVG path translation for a arrow head.
 */
export function describeArrowHeadTranslation(
  centerX: number,
  centerY: number,
  radius: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  startAngle: number,
  /** Angle in degrees, clockwise from straight up, (so 0 points in y- direction). */
  endAngle: number,
  arrowHeadEndingSize: number,
  clockwise = true
) {
  'worklet';
  const start = polarToCartesian(centerX, centerY, radius, endAngle - 90);
  const end = polarToCartesian(centerX, centerY, radius, startAngle - 90);

  const totalAngle = clockwise
    ? Math.abs(endAngle - startAngle)
    : 360 - Math.abs(endAngle - startAngle);
  const angleRad = (totalAngle * Math.PI) / 180;
  const x = clockwise ? start.x : end.x;
  const y = clockwise ? start.y : end.y;

  const requiresFlip = [0, 180].includes(totalAngle) && !clockwise;

  const xTranslation =
    x -
    Math.cos(requiresFlip ? angleRad - Math.PI : angleRad) * arrowHeadEndingSize +
    (Math.sin(angleRad) * arrowHeadEndingSize) / 2;
  const yTranslation =
    y - Math.sin(angleRad) * arrowHeadEndingSize - (Math.cos(angleRad) * arrowHeadEndingSize) / 2;

  const angle = startAngle < endAngle ? endAngle : -startAngle;

  return `translate(${xTranslation}, ${yTranslation}) ${
    requiresFlip ? 'scale(-1, 1)' : ''
  } rotate(${angle}) `;
}

export function describeSquareArc(
  centerX: number,
  centerY: number,
  radius: number,
  startAngle: number
) {
  'worklet';
  const start = polarToCartesian(centerX, centerY, radius, startAngle);
  const mid = polarToCartesian(centerX, centerY, radius * Math.SQRT2, startAngle - 45);
  const end = polarToCartesian(centerX, centerY, radius, startAngle - 90);
  const d = `M ${start.x} ${start.y} L ${mid.x} ${mid.y} L ${end.x} ${end.y}`;
  return d;
}

/**
 * Get angle (degrees) between two lines oriented at the given angles a and b (i.e. b-a).
 * Result is always between 0 (inclusive) and 360 (exclusive).
 *
 * If allowReflexAngles is true, this is the angle clockwise from a to b.
 * If allowReflexAngles is false, this is smallest angle from a to b.
 */
export function angleBetweenTwoLines(a: number, b: number, allowReflexAngles: boolean): number {
  // Modulo operator instead of remainder operator
  // Ensures result is between 0 and 360
  const angle = mod(b - a, 360);
  return !allowReflexAngles && angle > 180 ? 360 - angle : angle;
}

/**
 * Get the transform style property for rotating a view around an anchor point within that view.
 *
 * In react-native 0.73, we can utilize transformOrigin to simplify this, so we wouldn't require the view's width and
 * height.
 */
export function rotateAroundAPointTransformStyle(
  /** Rotation angle in radians, clockwise. */
  theta: number,
  /** The point that the rotation is centered on - x coordinate */
  anchorX: number,
  /** The point that the rotation is centered on - y coordinate */
  anchorY: number,
  /** The width of the view */
  width: number,
  /** The height of the view */
  height: number
): ViewStyle['transform'] {
  'worklet';
  // Marked as a worklet so that it can be used within `useAnimatedStyle` callbacks.
  return [
    { translateX: -(width / 2 - anchorX) },
    { translateY: -(height / 2 - anchorY) },
    { rotate: `${theta}rad` },
    { translateX: width / 2 - anchorX },
    { translateY: height / 2 - anchorY }
  ];
}
