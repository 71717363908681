import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { arrayHasNoDuplicates, range, sortNumberArray } from 'common/src/utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from 'common/src/utils/random';
import { names } from 'common/src/utils/names';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getNumeralOrdinal } from '../../../../utils/ordinals';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acA',
  description: 'acA',
  keywords: ['Place value', 'Order', '10,000'],
  schema: z.object({
    numbers: z.number().int().min(1).max(9999).array().length(4).refine(arrayHasNoDuplicates),
    lookingForLargest: z.boolean()
  }),
  simpleGenerator: () => ({
    numbers: randomUniqueIntegersInclusive(1, 9999, 4),
    lookingForLargest: getRandomFromArray([true, false] as const)
  }),
  Component: ({ question: { numbers, lookingForLargest }, translate }) => (
    <QF10SelectNumbers
      title={
        lookingForLargest
          ? translate.instructions.whichNumberIsGreatest()
          : translate.instructions.whichNumberIsSmallest()
      }
      pdfTitle={`${
        lookingForLargest
          ? translate.instructions.whichNumberIsGreatest()
          : translate.instructions.whichNumberIsSmallest()
      }<br/>${translate.instructions.circleYourAnswer()}`}
      testCorrect={[lookingForLargest ? Math.max(...numbers) : Math.min(...numbers)]}
      items={numbers.map(number => ({
        value: number,
        component: number.toLocaleString()
      }))}
    />
  )
});

const Question2 = newQuestionContent({
  uid: 'acB',
  description: 'acB',
  keywords: ['Place value', 'Order', '10,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1000)
      .max(9999)
      .array()
      .length(4)
      .refine(value => new Set(value).size === value.length, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Get 4 random numbers
    const numbers = randomUniqueIntegersInclusive(1000, 9999, 4);

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'acC',
  description: 'acC',
  keywords: ['Place value', 'Order', '10,000'],
  schema: z
    .object({
      lower: z.number().int().min(1000).max(8999),
      upper: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => val.upper - val.lower > 1,
      'There must be an integer strictly between lower and upper'
    ),
  example: {
    lower: 1421,
    upper: 1434
  },
  simpleGenerator: () => {
    const lower = randomIntegerInclusive(1000, 8800);
    const upper = randomIntegerInclusive(lower + 11, lower + 199);
    return { lower, upper };
  },
  Component: props => {
    const {
      question: { lower, upper },
      translate
    } = props;
    const validAnswers = range(lower + 1, upper - 1).map(it => it.toString());
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.writeNumGreaterThanNum1AndLessThanNum2(
          lower.toLocaleString(),
          upper.toLocaleString()
        )}
        testCorrect={ans => validAnswers.includes(ans[0])}
        sentence={'<ans/>'}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            (lower + 1).toLocaleString(),
            (upper - 1).toLocaleString()
          )
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'acD',
  description: 'acD',
  keywords: ['Place value', 'Order', '10,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(9999)
      .array()
      .length(4)
      .refine(value => new Set(value).size === value.length, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Get a random 1 digit number (>0), a 2-digit, 3-digit and 4-digit
    const numbers = shuffle([
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(10, 99),
      randomIntegerInclusive(100, 999),
      randomIntegerInclusive(1000, 9999)
    ]);

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acE',
  description: 'acE',
  keywords: ['Place value', 'Order', '10,000', 'Thousand', 'Table', 'Length'],
  schema: z.object({
    firstnames: z.string().array().length(4).refine(arrayHasNoDuplicates),
    distances: z.number().int().min(100).max(9999).array().length(4).refine(arrayHasNoDuplicates),
    ordinal: z.number().int().min(2).max(3) as z.Schema<2 | 3>
  }),
  simpleGenerator: () => {
    const firstnames = getRandomSubArrayFromArray([...names.male, ...names.female], 4);
    const distances = randomUniqueIntegersInclusive(100, 9999, 4);
    const ordinal = randomIntegerInclusive(2, 3) as 2 | 3;
    return { firstnames, distances, ordinal };
  },
  Component: ({ question: { firstnames, distances, ordinal }, translate, locale }) => {
    const correctHeight = sortNumberArray(distances, 'descending')[ordinal - 1];
    const correctIndex = distances.indexOf(correctHeight);
    const correctName = firstnames[correctIndex];

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whoTravelsXOrdinalFurthest(
          getNumeralOrdinal(ordinal, locale, translate)
        )} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.whoTravelsXOrdinalFurthest(
          getNumeralOrdinal(ordinal, locale, translate)
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        numItems={4}
        testCorrect={[correctName]}
        renderItems={firstnames.map((name, index) => ({
          value: name,
          component: (
            <>
              <Text variant="WRN700" style={{ fontSize: 32 }}>
                {name}
              </Text>
              <Text style={{ fontSize: 32 }}>{translate.units.numberOfM(distances[index])}</Text>
            </>
          )
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'acF',
  description: 'acF',
  keywords: ['Place value', 'Order', '10,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(123)
      .max(9999)
      .array()
      .length(4)
      .refine(value => new Set(value).size === value.length, 'Duplicate numbers are not allowed.'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Get 4 distinct numbers using the same 4 distinct digits. There are 24 permutations to choose from.
    // Each number is in the range 0123-9999.
    const digits = randomUniqueIntegersInclusive(0, 9, 4);
    const candidates = digits
      // Permutations of 1 distinct element
      .map(x => [x])
      // Permutations of 2 distinct elements
      .flatMap(p => digits.filter(it => !p.includes(it)).map(y => [...p, y]))
      // Permutations of 3 distinct elements
      .flatMap(p => digits.filter(it => !p.includes(it)).map(z => [...p, z]))
      // Permutations of 4 distinct elements
      .flatMap(p => digits.filter(it => !p.includes(it)).map(w => [...p, w]))
      // Convert to numbers
      .map(p => p.reduce((sum, digit, index) => sum + digit * Math.pow(10, index), 0));
    const numbers = getRandomSubArrayFromArray(candidates, 4);

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'OrderNumbersTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
