import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { getDayTemperaturesGenerator } from '../../../../utils/graphs';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomNumber,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { LineGraphColors } from '../../../../theme/colors';
import { countRange, range, sortNumberArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import LineGraph, {
  Key
} from '../../../../components/question/representations/Coordinates/LineGraph';
import { numberEnum } from '../../../../utils/zod';
import { dayAsWord, dayNames, daySchema } from '../../../../utils/days';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF59DrawLineGraph from '../../../../components/question/questionFormats/QF59DrawLineGraph';
import { getYValueOfPoint } from '../../../../utils/lines';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aXe',
  description: 'aXe',
  keywords: ['Line graph', 'X-axis', 'Y-axis', 'Axes', 'Temperature', 'Time', 'Interpret'],
  schema: z.object({
    tempMax: numberEnum([5, 6, 12]),
    timeMin: z.number().int().min(5).max(8),
    answerIdx: z.number().int().min(0).max(7),
    temperatures: z.number().int().array()
  }),
  simpleGenerator: () => {
    const tempMax = getRandomFromArray([5, 6, 12] as const);
    const timeMin = randomIntegerInclusive(5, 8);
    const answerIdx = randomIntegerInclusive(0, 7);

    const tempMin = 0;
    const timeStep = 1;

    // Temperature
    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      tempMax,
      timeMin,
      timeMin + timeStep * 7,
      // Peak time of day is between min +2 and min +4
      randomNumber(timeMin + 2, timeMin + 4)
    );

    const temperatures = range(timeMin, timeMin + 7 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(Math.round);

    return { tempMax, timeMin, answerIdx, temperatures };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { tempMax, timeMin, answerIdx, temperatures },
      translate
    } = props;

    const tempMin = 0;
    const timeStep = 1;

    // Times
    const times = range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(
      x => `${x}:00`
    );

    // Answer
    const ans = temperatures[answerIdx];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.keywords.Celsius()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatWasTheTemperatureAtX(times[answerIdx])}
        testCorrect={[ans.toString()]}
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <Grid
              sizingMethod="dimens"
              width={dimens.width}
              height={dimens.height}
              xAxis={tempMin}
              xMin={timeMin}
              xMax={timeMin + (temperatures.length - 1) * timeStep}
              xStepSize={timeStep}
              yAxis={timeMin}
              yMin={tempMin}
              yMax={tempMax}
              yStepSize={tempMax === 12 ? 2 : 1}
              yAxisLabel={`${translate.keywords.Temperature()} (${translate.keywords.Celsius()})`}
              xAxisLabel={translate.keywords.Time()}
              xLabels={[...times]}
            >
              <LineGraph points={temperatures} />
            </Grid>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aXf',
  description: 'aXf',
  keywords: ['Line graph', 'X-axis', 'Y-axis', 'Axes', 'Temperature', 'Time', 'Interpret'],
  schema: z.object({
    tempMax: numberEnum([10, 12, 25, 30]),
    timeMin: z.number().int().min(5).max(8),
    answerIndexes: z.number().int().min(0).max(7).array().length(2),
    temperatures: z.number().int().array()
  }),
  simpleGenerator: () => {
    const tempMax = getRandomFromArray([10, 12, 25, 30] as const);
    const timeMin = randomIntegerInclusive(5, 8);
    const tempMin = 0;
    const timeStep = 1;
    // must land on interval if going up in 5s can be in between interval when going up in 2s
    const tempStep = tempMax === 10 || tempMax === 12 ? 1 : 5;

    let adjustedTempMax: number;
    // if 10 or 12 then we are happy for any temperature in this interval
    if (tempMax === 10 || tempMax === 12) {
      adjustedTempMax = tempMax;
    }
    // for 25 and 30 we want only multiple of 5's so for 25 we want 5 to be max and for 30 we want 6
    // we will then multiply by 5 after to get final values
    else if (tempMax === 25) {
      adjustedTempMax = 5;
    } else {
      adjustedTempMax = 6;
    }

    // Temperature
    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      adjustedTempMax,
      timeMin,
      timeMin + timeStep * 7,
      // Peak time of day is between min +2 and min +4
      randomNumber(timeMin + 2, timeMin + 4)
    );

    const temperatures = range(timeMin, timeMin + 7 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(i => (adjustedTempMax === tempMax ? Math.round(i) : Math.round(i) * tempStep));

    const answerIndexes = rejectionSample(
      () => {
        // sort it so that the times are in order in the question
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, 2), 'ascending');
      },
      x => temperatures[x[0]] !== temperatures[x[1]]
    );

    return { tempMax, timeMin, answerIndexes, temperatures };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { tempMax, timeMin, answerIndexes, temperatures },
      translate
    } = props;

    const tempMin = 0;
    const timeStep = 1;
    const tempStep = tempMax === 10 || tempMax === 12 ? 2 : 5;

    // Times
    const times = range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(
      x => `${x}:00`
    );

    // Temps
    const val1 = temperatures[answerIndexes[0]];
    const val2 = temperatures[answerIndexes[1]];
    const ans = Math.abs(val1 - val2).toString();

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.keywords.Celsius()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatIsDifferenceInTempOfXAndY(
          times[answerIndexes[0]],
          times[answerIndexes[1]]
        )}
        testCorrect={[ans.toString()]}
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <Grid
              sizingMethod="dimens"
              width={dimens.width}
              height={dimens.height}
              xAxis={tempMin}
              xMin={timeMin}
              xMax={timeMin + (temperatures.length - 1) * timeStep}
              xStepSize={timeStep}
              yAxis={timeMin}
              yMin={tempMin}
              yMax={tempMax}
              yStepSize={tempStep}
              yAxisLabel={`${translate.keywords.Temperature()} (${translate.keywords.Celsius()})`}
              xAxisLabel={translate.keywords.Time()}
              xLabels={[...times]}
            >
              <LineGraph points={temperatures} />
            </Grid>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXg',
  description: 'aXg',
  keywords: [
    'Line graph',
    'X-axis',
    'Y-axis',
    'Axes',
    'Distance',
    'Miles',
    'Time',
    'Minutes',
    'Interpret'
  ],
  schema: z.object({
    distanceMax: numberEnum([25, 30]),
    answerTime: z.number().int().min(1).max(34),
    answerDistance: z.number().int().min(0).max(30),
    distances: z.number().int().array(),
    times: z.number().int().array(),
    incorrectAnswers: z.array(z.number().int().min(0).max(30)).length(3)
  }),
  simpleGenerator: () => {
    const distanceMax = getRandomFromArray([25, 30] as const);
    const distance7 = distanceMax;
    const otherDistances = countRange(6).map(() => randomIntegerInclusive(0, distanceMax));
    const ordered = sortNumberArray(otherDistances, 'ascending');
    const distance1 = 0;
    const distances = [distance1, ...ordered, distance7];

    const answerTime = randomIntegerInclusive(1, 34, { constraint: x => x % 5 !== 0 });

    const timeStep = 5;
    const timeMin = 0;

    // Times - may as well return these as part of generator rather than regenerate later
    const times = range(timeMin, timeMin + (distances.length - 1) * timeStep, timeStep);

    // work out the distance at answer time so we ensure we have unqiue answers
    const minValue = answerTime - (answerTime % 5);
    const indexOfMinVal = times.indexOf(minValue);
    const maxValue = answerTime + (5 - (answerTime % 5));
    const indexOfMaxVal = times.indexOf(maxValue);
    const answerDistance = getYValueOfPoint(
      [minValue, distances[indexOfMinVal]] as [number, number],
      [maxValue, distances[indexOfMaxVal]] as [number, number],
      answerTime
    );
    // exclude answer +/-2
    const incorrectAnswers = randomUniqueIntegersInclusive(0, distanceMax, 3, {
      constraint: x => !countRange(5, answerDistance - 2).includes(x)
    });

    return {
      distanceMax,
      answerTime,
      answerDistance,
      times,
      distances,
      incorrectAnswers
    };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      translate,
      question: { distanceMax, answerTime, answerDistance, times, distances, incorrectAnswers }
    } = props;

    const distanceMin = 0;
    const timeStep = 5;
    const distanceStep = 5;
    const timeMin = 0;

    // Times
    const stringTimes = times.map(x => x.toLocaleString());

    const selectables = shuffle([answerDistance, ...incorrectAnswers], {
      random: seededRandom(props.question)
    });

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectApproxDistanceTravelledAfterXMinutes(answerTime)}
        pdfTitle={translate.instructions.circleApproxDistanceTravelledAfterXMinutes(answerTime)}
        selectables={Object.fromEntries(
          selectables.map(key => [key, translate.units.numberOfMiles(key)])
        )}
        correctAnswer={[answerDistance.toString()]}
        questionHeight={1000}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                sizingMethod="dimens"
                width={dimens.width}
                height={dimens.height}
                xAxis={distanceMin}
                xMin={timeMin}
                xMax={timeMin + (distances.length - 1) * timeStep}
                xStepSize={timeStep}
                yAxis={timeMin}
                yMin={distanceMin}
                yMax={distanceMax}
                yStepSize={distanceStep}
                yAxisLabel={translate.graphLabels.distanceTravelledMiles()}
                xAxisLabel={`${translate.keywords.Time()} (${translate.graphLabels.minutes()})`}
                xLabels={[...stringTimes]}
              >
                <LineGraph points={distances} />
              </Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aXh',
  description: 'aXh',
  keywords: [
    'Line graph',
    'X-axis',
    'Y-axis',
    'Axes',
    'Distance',
    'Miles',
    'Time',
    'Minutes',
    'Interpret'
  ],
  schema: z.object({
    distanceMax: numberEnum([25, 30]),
    answerTime: z.number().int().min(1).max(34),
    distances: z.number().int().array()
  }),
  simpleGenerator: () => {
    const distanceMax = getRandomFromArray([25, 30] as const);

    const distance7 = distanceMax;
    const otherDistances = countRange(6).map(() => randomIntegerInclusive(0, distanceMax));
    const ordered = sortNumberArray(otherDistances, 'ascending');
    const distance1 = 0;

    const distances = [distance1, ...ordered, distance7];

    const answerTime = randomIntegerInclusive(1, 34, { constraint: x => x % 5 !== 0 });

    return { distanceMax, answerTime, distances };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { distanceMax, answerTime, distances },
      translate
    } = props;

    const distanceMin = 0;
    const timeStep = 5;
    const distanceStep = 5;
    const timeMin = 0;

    // Times
    const times = range(timeMin, timeMin + (distances.length - 1) * timeStep, timeStep);
    const stringTimes = times.map(x => x.toLocaleString());

    const minValue = answerTime - (answerTime % 5);
    const indexOfMinVal = times.indexOf(minValue);
    const maxValue = answerTime + (5 - (answerTime % 5));
    const indexOfMaxVal = times.indexOf(maxValue);
    const ans = getYValueOfPoint(
      [minValue, distances[indexOfMinVal]] as [number, number],
      [maxValue, distances[indexOfMaxVal]] as [number, number],
      answerTime
    );

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.keywords.Celsius()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatIsApproximateDistanceAfterXMinutes(answerTime)}
        testCorrect={userAnswer =>
          range(ans - 1, ans + 1)
            .map(i => i.toString())
            .includes(userAnswer[0])
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            Math.max(ans - 1, 0).toLocaleString(),
            Math.min(ans + 1, 35).toLocaleString()
          ),
          answersToDisplay: [ans.toLocaleString()]
        }}
        inputMaxCharacters={2}
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <Grid
              sizingMethod="dimens"
              width={dimens.width}
              height={dimens.height}
              xAxis={distanceMin}
              xMin={timeMin}
              xMax={timeMin + (distances.length - 1) * timeStep}
              xStepSize={timeStep}
              yAxis={timeMin}
              yMin={distanceMin}
              yMax={distanceMax}
              yStepSize={distanceStep}
              yAxisLabel={`${translate.graphLabels.distanceTravelledMiles()}`}
              xAxisLabel={`${translate.keywords.Time()} (${translate.graphLabels.minutes()})`}
              xLabels={[...stringTimes]}
            >
              <LineGraph points={distances} />
            </Grid>
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXi',
  description: 'aXi',
  keywords: ['Line graph', 'X-axis', 'Y-axis', 'Axes', 'Litres', 'Time', 'Interpret'],
  schema: z.object({
    waterMax: numberEnum([1000, 1200]),
    timeMin: z.number().int().min(5).max(10),
    values1: z.number().int().array(),
    values2: z.number().int().array(),
    days: z.array(daySchema).length(2),
    equalIndex: z.number().int().min(0).max(7),
    incorrectAnswerIndexes: z.array(z.number().int().min(0).max(7)).length(3)
  }),
  simpleGenerator: () => {
    const waterMax = getRandomFromArray([1000, 1200] as const);
    const timeMin = randomIntegerInclusive(5, 10);
    // use equalIndex to make values1[i] equal values2[i]
    const equalIndex = randomIntegerInclusive(0, 7);

    const values1 = countRange(8).map(() => randomIntegerInclusiveStep(0, waterMax, 100));
    const values2 = countRange(8).map(i =>
      i === equalIndex
        ? values1[i]
        : randomIntegerInclusiveStep(0, waterMax, 100, { constraint: x => x !== values1[i] })
    );

    const days = getRandomSubArrayFromArray(dayNames, 2);
    const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 7, 3, {
      constraint: x => x !== equalIndex
    });

    return { waterMax, timeMin, values1, values2, days, equalIndex, incorrectAnswerIndexes };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      translate,
      question: { waterMax, timeMin, values1, values2, days, equalIndex, incorrectAnswerIndexes }
    } = props;

    const waterMin = 0;
    const timeStep = 1;
    const waterStep = 200;

    // Times
    const times = range(timeMin, timeMin + (values1.length - 1) * timeStep, timeStep).map(
      x => `${x}:00`
    );

    const random = seededRandom(props.question);

    const [color1, color2] = shuffle(LineGraphColors.slice(0, 2), {
      random
    });

    const selectables = shuffle(
      times.filter((_x, i) => i === equalIndex || incorrectAnswerIndexes.includes(i)),
      {
        random
      }
    );

    const day1String = dayAsWord(days[0], translate);
    const day2String = dayAsWord(days[1], translate);

    const colors = { [day1String]: color1, [day2String]: color2 } as const;

    // Don't always show day1 first in the key.
    const keyDays = shuffle([day1String, day2String], { random });

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.whatTimeWasXAndYTheSame(day1String, day2String)}
        selectables={Object.fromEntries(selectables.map(key => [key, key]))}
        correctAnswer={[times[equalIndex]]}
        questionHeight={1000}
        leftContent={
          <MeasureView>
            {dimens => (
              <>
                <Key
                  colors={keyDays.map(day => colors[day])}
                  labels={keyDays}
                  style={{ alignSelf: 'flex-end', marginBottom: -15 }}
                />
                <Grid
                  sizingMethod="dimens"
                  width={dimens.width}
                  height={dimens.height}
                  xAxis={waterMin}
                  xMin={timeMin}
                  xMax={timeMin + (values1.length - 1) * timeStep}
                  xStepSize={timeStep}
                  yAxis={timeMin}
                  yMin={waterMin}
                  yMax={waterMax}
                  yLabels={range(waterMin, waterMax, 200).map(num => num.toLocaleString())}
                  yStepSize={waterStep}
                  yAxisLabel={`${translate.graphLabels.waterConsumptionLitres()}`}
                  xAxisLabel={translate.keywords.Time()}
                  xLabels={[...times]}
                >
                  <LineGraph points={values1} color={color1} />
                  <LineGraph points={values2} color={color2} />
                </Grid>
              </>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aXj',
  description: 'aXj',
  keywords: ['Line graph', 'X-axis', 'Y-axis', 'Axes', 'Plot', 'Points', 'Draw'],
  schema: z.object({
    answersArray: z.array(z.number().min(0).max(60).step(5)).length(7)
  }),
  simpleGenerator: () => {
    const answersArray = countRange(7).map(() => randomIntegerInclusiveStep(0, 60, 5));

    return {
      answersArray
    };
  },
  Component: props => {
    const {
      question: { answersArray },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.drawPointsToCompleteTheLineGraph()}
        correctAnswer={answersArray}
        gridProps={{
          xAxis: 0,
          xMin: 0,
          xMax: 60,
          xStepSize: 10,
          yAxis: 1,
          yMin: 0,
          yMax: 60,
          yStepSize: 10,
          yAxisLabel: translate.graphLabels.heightMetres(),
          xAxisLabel: `${translate.keywords.Time()} (${translate.timePeriod.seconds(2)})`,
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={5}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LineGraphs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
