import { View } from 'react-native';
import { useI18nContext } from 'common/src/i18n/i18n-react';
import { ComponentProps, useContext, useState } from 'react';
import Text from '../../typography/Text';
import Svg, { Path } from 'react-native-svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import TextStructure from '../../molecules/TextStructure';
import { SetState } from '../../../utils/react';
import { Dimens } from '../../../theme/scaling';
import { withStateHOC } from '../../../stateTree';
import { parseMarkup } from '../../../markup';
import { filledArray } from '../../../utils/collections';

type Props = {
  /**
   * Input box for answer box on the number line, must be specified to know what input
   * is available to the user and how to handle the answer
   */
  inputBox: (
    rowIndex: number,
    currentBoxIndex: number,
    boxWidth: number,
    boxHeight: number,
    index?: number
  ) => JSX.Element;
  /**
   * An array of arrays representing each function machine.
   * Every array within this rowsOfBoxes array represents a separate function machine.
   * Within each inner array, each string will correspond to the contents displayed in each text box in the same order.
   * If '<ans/>' is passed, the box will become an answer input box in this place.
   * If sharedColumns passes any indexes, any content in these indexes in non-final rows will be overridden
   * by the content in that index from the last row.
   */
  rowsOfBoxes: string[][];
  /** Height of the boxes in the function machine. Optional, defaults to 150. */
  boxHeight?: number;
  /** Width of the boxes in the function machine. Optional, defaults to 150. */
  boxWidth?: number;
  /**
   * Indexes of columns that are to be shared across the entire function machine.
   * Box will show the contents of this index from the last row - entries in this index in other rows will be ignored
   * e.g. rowsOfBoxes={[['a', 'b'], ['c', 'd']]} sharedColumns={[1]} will display a shared column in index 1 for both rows,
   * with only 'd' showing within it.
   */
  sharedColumns?: number[];
  /**
   * Prop to determine whether to use the widths needed for draggables or the standard widths for non-draggable function machines.
   * Optional prop, defaults to false.
   */
  draggableWidths?: boolean;
  /**
   * Prop to determine whether or not to hide the 'Input' and 'Output' labels.
   * Optional prop, defaults to false.
   */
  hideLabels?: boolean;
  dimens: Dimens;
};

/**
 * Function to display a function machine(s).
 * Takes in rows of boxes as arrays of strings, and creates rows of function machines from them.
 * Columns can be shared across all rows with sharedColumns; content from the final row's matching index will be used as the content
 * in a shared column.
 * Capable of being used with both QF28 and QF28a, with different width calculations for these determined by the draggableWidths prop.
 */
export const FunctionMachines = ({
  inputBox,
  rowsOfBoxes,
  boxHeight = 150,
  boxWidth = 150,
  sharedColumns = [],
  draggableWidths = false,
  hideLabels = false,
  dimens
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const translate = useI18nContext().LL;

  const boxBorderWidth = isPdf ? 4 : 2;
  const totalBoxHeight = isPdf ? boxHeight * 2 : boxHeight;
  const totalBoxWidth = isPdf ? boxWidth * 2 : boxWidth;

  const labelFontSize = isPdf ? 48 : 32;
  const boxFontSize = isPdf ? 60 : 40;

  const arrowWidth = isPdf ? 96 : 48;

  const rowMarginBottom = 16;

  const sharedBoxHeight =
    totalBoxHeight * rowsOfBoxes.length + rowMarginBottom * (rowsOfBoxes.length - 1);

  const sharedBoxOffset = sharedBoxHeight - totalBoxHeight;

  let draggableAnsIndex = -1;

  const [widthsOfColumns, setWidthsOfColumns] = useState<number[]>(
    rowsOfBoxes[0].map(() => totalBoxWidth)
  );

  return (
    <View style={[dimens, { justifyContent: 'center' }]}>
      {rowsOfBoxes.map((row, rowIndex) => {
        let ansIndex = -1;
        return (
          <View
            style={{
              flexDirection: 'row',
              alignSelf: 'center',
              alignItems: 'flex-end',
              marginBottom: rowMarginBottom
            }}
            key={rowIndex}
          >
            {row.map((box, boxIndex) => {
              const ansCount = parseMarkup(box).numberOfAns;

              draggableAnsIndex += ansCount;

              const i = draggableAnsIndex;

              if (parseMarkup(box).numberOfAns > 0) {
                ansIndex += 1;
              }

              // Update the width if necessary
              if (totalBoxWidth + 100 * ansCount > widthsOfColumns[boxIndex] && ansCount > 1) {
                // Create a new array with the old values and the updated width
                const newWidths = [...widthsOfColumns];
                newWidths[boxIndex] = totalBoxWidth + 100 * ansCount;
                setWidthsOfColumns(newWidths);
              }

              // Copy of current answer box's index to retain which index in the state each input has.
              const currentBoxIndex = ansIndex;

              return (
                <View key={boxIndex} style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                  <View style={{ alignItems: 'center' }}>
                    {boxIndex === 0 && rowIndex === 0 && !hideLabels && (
                      <Text style={{ fontSize: labelFontSize, marginBottom: 8 }}>
                        {translate.misc.Input()}
                      </Text>
                    )}
                    {boxIndex === row.length - 1 && rowIndex === 0 && !hideLabels && (
                      <Text style={{ fontSize: labelFontSize, marginBottom: 8 }}>
                        {translate.misc.Output()}
                      </Text>
                    )}
                    {sharedColumns.includes(boxIndex) ? (
                      rowIndex === rowsOfBoxes.length - 1 ? (
                        <View style={{ height: totalBoxHeight }}>
                          <View
                            style={{
                              height: sharedBoxHeight,
                              width: draggableWidths ? widthsOfColumns[boxIndex] : totalBoxWidth,
                              borderWidth: boxBorderWidth,
                              borderColor: isPdf ? colors.black : colors.functionMachineBorder,
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                              bottom: sharedBoxOffset,
                              backgroundColor:
                                boxIndex === 0 || boxIndex === row.length - 1
                                  ? colors.white
                                  : colors.functionMachineFill,
                              flexDirection: 'row'
                            }}
                          >
                            <TextStructure
                              textVariant="WRN700"
                              sentence={box}
                              textStyle={{
                                fontSize: boxFontSize,
                                // lineHeight and further styling below needed to consistently align content or answer boxes correctly.
                                lineHeight: totalBoxHeight
                              }}
                              style={{
                                height: totalBoxHeight,
                                alignContent: 'center',
                                justifyContent: 'center'
                              }}
                              inputBox={({ index }) =>
                                inputBox(
                                  rowIndex,
                                  currentBoxIndex,
                                  totalBoxWidth,
                                  sharedBoxHeight,
                                  i - ansCount + (index + 1)
                                )
                              }
                            />
                          </View>
                        </View>
                      ) : (
                        // Hide all other boxes that are to be overridden by the shared box, only allowing their width to remain in place.
                        <View
                          style={{
                            height: 0,
                            width: draggableWidths ? widthsOfColumns[boxIndex] : totalBoxWidth,
                            borderWidth: 0,
                            backgroundColor: 'transparent'
                          }}
                        ></View>
                      )
                    ) : (
                      <View
                        style={{
                          height: totalBoxHeight,
                          width:
                            draggableWidths && parseMarkup(box).numberOfAns > 0
                              ? widthsOfColumns[boxIndex]
                              : totalBoxWidth,
                          maxHeight: dimens.height,
                          borderWidth: boxBorderWidth,
                          borderColor:
                            parseMarkup(box).numberOfAns > 0 && !draggableWidths
                              ? 'transparent'
                              : isPdf
                              ? colors.black
                              : colors.functionMachineBorder,
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor:
                            boxIndex === 0 || boxIndex === row.length - 1
                              ? colors.white
                              : colors.functionMachineFill,
                          flexDirection: 'row'
                        }}
                      >
                        <TextStructure
                          textVariant="WRN700"
                          sentence={box}
                          textStyle={{
                            fontSize: boxFontSize,
                            // lineHeight and further styling below needed to consistently align content or answer boxes correctly.
                            lineHeight: totalBoxHeight
                          }}
                          style={{
                            height: totalBoxHeight,
                            alignContent: 'center',
                            justifyContent: 'center'
                          }}
                          inputBox={({ index }) =>
                            inputBox(
                              rowIndex,
                              currentBoxIndex,
                              totalBoxWidth,
                              totalBoxHeight,
                              i - ansCount + (index + 1)
                            )
                          }
                        />
                      </View>
                    )}
                  </View>
                  {boxIndex !== row.length - 1 && (
                    <View
                      style={{
                        height: totalBoxHeight,
                        width: arrowWidth,
                        justifyContent: 'center'
                      }}
                    >
                      <Svg width={arrowWidth} height={30}>
                        <Path
                          d={`M2,15 L${arrowWidth - 6},15`}
                          stroke={isPdf ? colors.black : colors.burntSienna}
                          strokeWidth={4}
                        />
                        <Path
                          d={`M${arrowWidth - 3},15
                              L${arrowWidth - 18},0
                              L${arrowWidth - 18},30
                              Z`}
                          fill={isPdf ? colors.black : colors.burntSienna}
                        />
                      </Svg>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export function FunctionMachinesInput({
  userAnswer,
  onTextInput,
  dimens,
  ...props
}: Omit<ComponentProps<typeof FunctionMachines>, 'inputBox'> & {
  userAnswer?: string[][];
  onTextInput?: SetState<string[][]>;
}) {
  const keyboardInputBox = (
    rowIndex: number,
    currentBoxIndex: number,
    boxWidth: number,
    boxHeight: number
  ) => {
    return (
      <NoKeyboardTextInput
        key={`${rowIndex}-${currentBoxIndex}`}
        value={userAnswer ? userAnswer[rowIndex][currentBoxIndex] : ''}
        onChangeText={
          userAnswer && onTextInput
            ? text => {
                const newState = [...userAnswer];
                newState[rowIndex][currentBoxIndex] = text;
                onTextInput(newState);
              }
            : undefined
        }
        style={{
          height: boxHeight,
          width: boxWidth,
          maxHeight: dimens.height
        }}
      />
    );
  };

  return <FunctionMachines inputBox={keyboardInputBox} dimens={dimens} {...props} />;
}

/** See {@link FunctionMachinesInput}. */
export const FunctionMachinesInputWithState = withStateHOC(FunctionMachinesInput, {
  stateProp: 'userAnswer',
  setStateProp: 'onTextInput',
  defaults: props => ({
    // Default state is an empty string array of the length of how many ans boxes there are.
    defaultState: props.rowsOfBoxes
      .map(row => row.map(str => parseMarkup(str).numberOfAns).reduce((a, b) => a + b))
      .map(i => filledArray('', i)),
    testComplete: state => (state ? state.every(row => row.every(it => it !== '')) : true)
  })
});
