import { View } from 'react-native';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import Text from 'common/src/components/typography/Text';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { numberEnum } from 'common/src/utils/zod';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'anv',
  description: 'anv',
  keywords: ['Divisibility'],
  schema: z.object({
    choices: z.number().array().length(4)
  }),
  simpleGenerator: () => {
    // Pick 4 numbers to act as indices for picking options
    const choices = getRandomSubArrayFromArray([0, 1, 2, 3, 4], 4);

    return { choices };
  },
  Component: props => {
    const {
      question: { choices },
      translate
    } = props;

    const allStatements = [
      { statement: translate.answerSentences.evenNumber(), answer: 'Divisible by 2' },
      { statement: translate.answerSentences.sumOfDigitsDivisibleByX(3), answer: 'Divisible by 3' },
      { statement: translate.answerSentences.sumOfDigitsDivisibleByX(9), answer: 'Divisible by 9' },
      { statement: translate.answerSentences.halveAndAnswerEven(), answer: 'Divisible by 4' },
      { statement: translate.answerSentences.endsIn5Or0(), answer: 'Divisible by 5' }
    ];

    // Pick first 4 to conform to QF6 maximum number of items
    const questionStatements = choices.map(idx => allStatements[idx]);
    const answerOptions = questionStatements.map(x => x.answer).sort();

    return (
      <QF37SentencesDrag
        title={translate.instructions.DragCardsMatchDivisibilityRules()}
        pdfTitle={translate.instructions.UseCardsMatchDivisibilityRules()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        sentences={questionStatements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={questionStatements.map(({ answer }) => [answer])}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'anw',
  description: 'anw',
  keywords: ['Divisibility'],
  schema: z.object({
    chosenFactor: numberEnum([2, 3, 5, 9, 10]),
    number: z.number().int().min(101).max(999)
  }),
  simpleGenerator: () => {
    const chosenFactor = getRandomFromArray([2, 3, 5, 9, 10] as const);

    const isDivisible = getRandomFromArray([true, false]);

    // Allow a 50-50 chance of number having chosenFactor as a factor.
    // Without these conditions, it would be rare to have chosenFactor as a factor.
    const number = randomIntegerInclusive(101, 999, {
      constraint: x => (isDivisible ? x % chosenFactor === 0 : x % chosenFactor !== 0)
    });

    return { chosenFactor, number };
  },
  Component: props => {
    const {
      question: { chosenFactor, number },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={`${translate.instructions.tinySaysXIsDivisibleByYIsTinyCorrect(
          number.toLocaleString(),
          chosenFactor.toLocaleString()
        )} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.tinySaysXIsDivisibleByYIsTinyCorrect(
          number.toLocaleString(),
          chosenFactor.toLocaleString()
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={number % chosenFactor === 0 ? ['Yes'] : ['No']}
        numItems={2}
        itemStyle={{ height: 165 }}
        renderItems={() => [
          {
            value: 'Yes',
            component: <Text variant="WRN700">{translate.misc.Yes()}</Text>
          },
          {
            value: 'No',
            component: <Text variant="WRN700">{translate.misc.No()}</Text>
          }
        ]}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center', alignItems: 'center' }}>
            <AssetSvg name="Tiny" />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'anx',
  description: 'anx',
  keywords: ['Divisibility'],
  schema: z
    .object({
      chosenFactor: numberEnum([2, 3, 5, 9, 10]),
      numberArray: z.number().int().min(2).max(99).array().length(6)
    })
    .refine(val => {
      const numsWithChosenFactor = val.numberArray.filter(
        number => number % val.chosenFactor === 0
      );
      return numsWithChosenFactor.length > 1 && numsWithChosenFactor.length < 6;
    }, 'numberArray must contain at least 2 numbers that have chosenFactor as one of their factors, and 1 number that does not have this factor.')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    const chosenFactor = getRandomFromArray([2, 3, 5, 9, 10] as const);

    // Create two numbers that definitely have chosenFactor as one of their factors.
    const [number1, number2] = randomUniqueIntegersInclusive(2, 99, 2, {
      constraint: x => x % chosenFactor === 0
    });

    // Create a number that definitely does not have chosenFactor as one of its factors.
    const number3 = randomIntegerInclusive(2, 99, {
      constraint: x => x % chosenFactor !== 0
    });

    // Create three other numbers.
    const [number4, number5, number6] = randomUniqueIntegersInclusive(2, 99, 3, {
      constraint: x => x !== number1 && x !== number2 && x !== number3
    });

    const numberArray = shuffle([number1, number2, number3, number4, number5, number6]);

    return { chosenFactor, numberArray };
  },
  Component: props => {
    const {
      question: { chosenFactor, numberArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatAreDivisibleByNum(chosenFactor)}
        pdfTitle={translate.instructions.circleNumbersThatAreDivisibleByNum(chosenFactor)}
        testCorrect={numberArray.filter(it => it % chosenFactor === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'any',
  description: 'any',
  keywords: ['Divisibility'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(2)
      .max(11)
      .refine(x => x !== 7, 'number1 should not be 7'),
    correctArrayNums: z.array(z.number().int().min(49).max(501)).length(3),
    incorrectArrayNums: z.array(z.number().int().min(19).max(501)).length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 11, { constraint: x => x !== 7 });

    // Correct answers
    const correctArrayNums = randomUniqueIntegersInclusive(49, 501, 3, {
      constraint: x => x % number1 === 0
    });

    // Incorrect answers
    const incorrectArrayNums = randomUniqueIntegersInclusive(19, 501, 4, {
      constraint: x => x % number1 !== 0
    });

    return {
      number1,
      correctArrayNums,
      incorrectArrayNums
    };
  },

  Component: props => {
    const {
      question: { number1, correctArrayNums, incorrectArrayNums },
      translate
    } = props;

    const renderNumbers = getRandomSubArrayFromArray(
      [...correctArrayNums, ...incorrectArrayNums],
      6,
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectAllNumbersDivisibleBy(number1)}
        testCorrect={renderNumbers.filter(num => correctArrayNums.includes(num))}
        multiSelect
        items={renderNumbers.map((number: number) => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'anz',
  description: 'anz',
  keywords: ['Divisibility'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(2)
        .max(10)
        .refine(x => x !== 7, 'var1 should not be 7'),
      var2: z
        .number()
        .int()
        .min(2)
        .max(10)
        .refine(x => x !== 7, 'var2 should not be 7'),
      numbers: z.array(z.number().int().min(101).max(10000)).length(6)
    })
    .refine(val => val.var1 !== val.var2, 'var1 and var2 must be different')
    .refine(val => arrayHasNoDuplicates(val.numbers), 'there must be no duplicate options'),
  questionHeight: 800,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 10, { constraint: x => x !== 7 });
    const var2 = randomIntegerInclusive(2, 10, {
      constraint: x => x !== 7 && x !== var1 && var1 % x !== 0 && x % var1 !== 0
    });

    const [var3, var4, var5, var6, var7, var8] = rejectionSample(
      () => {
        const var3 = randomIntegerInclusive(101, 10000, {
          constraint: x => x % var1 === 0 && x % var2 !== 0
        });
        const var4 = randomIntegerInclusive(101, 9999, {
          constraint: x => x % var2 === 0 && x % var1 !== 0
        });
        const var5 = randomIntegerInclusive(101, 9999, {
          constraint: x => x % var1 === 0 && x % var2 === 0
        });
        const [var6, var7, var8] = randomUniqueIntegersInclusive(101, 9999, 3, {
          constraint: x => x % var1 === 0 || x % var2 === 0
        });
        return [var3, var4, var5, var6, var7, var8];
      },
      numbers => arrayHasNoDuplicates(numbers)
    );

    const numbers = shuffle([var3, var4, var5, var6, var7, var8]);

    return { var1, var2, numbers };
  },
  Component: ({ question: { var1, var2, numbers }, translate }) => {
    const multiple1: number[] = [];
    const multiple2: number[] = [];
    const multipleBoth: number[] = [];

    // Limit number of options due to space
    const limitedNumbers = getRandomSubArrayFromArray(numbers, 6, {
      random: seededRandom({ var1, var2, numbers })
    });

    // Organize the numbers into their respective Sets
    limitedNumbers.forEach(number => {
      if (number % var1 === 0 && number % var2 === 0) {
        multipleBoth.push(number);
      } else if (number % var1 === 0) {
        multiple1.push(number);
      } else {
        multiple2.push(number);
      }
    });

    const correctOrder = [multiple1, multipleBoth, multiple2];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortNumbersIntoTheTable()}
        pdfTitle={translate.instructions.sortNumbersPDF()}
        zoneNames={[
          translate.instructions.divisibleByXOnly(var1),
          translate.instructions.divisibleByXAndY(var1, var2),
          translate.instructions.divisibleByXOnly(var2)
        ]}
        items={limitedNumbers}
        testCorrect={correctOrder}
        actionPanelVariant="bottom"
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        itemsMaxLines={1}
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'anA',
  description: 'anA',
  keywords: ['Divisibility'],
  schema: z
    .object({
      chosenFactor1: z.number().int().min(2).max(5),
      chosenFactor2: z
        .number()
        .int()
        .min(6)
        .max(9)
        .refine(x => x !== 7, 'chosenFactor2 should not be 7'),
      numberArray: z.number().int().min(100).max(1000).array().length(4)
    })
    .refine(val => {
      const numWithBothFactors = val.numberArray.filter(
        number => number % val.chosenFactor1 === 0 && number % val.chosenFactor2 === 0
      );
      const numWithFactor1 = val.numberArray.filter(
        number => number % val.chosenFactor1 === 0 && number % val.chosenFactor2 !== 0
      );
      const numWithFactor2 = val.numberArray.filter(
        number => number % val.chosenFactor1 !== 0 && number % val.chosenFactor2 === 0
      );

      return (
        numWithBothFactors.length === 1 &&
        numWithFactor1.length === 1 &&
        numWithFactor2.length === 1
      );
    }, 'numberArray must have only one number that has both factors, only one number that has only chosenFactor1 and only one number that only has chosenFactor2.')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    const chosenFactor1 = randomIntegerInclusive(2, 5);

    // chosenFactor1 must not be a factor of chosenFactor2, otherwise some numbers below will be impossible to create.
    const chosenFactor2 = randomIntegerInclusive(6, 9, {
      constraint: x => x % chosenFactor1 !== 0 && x !== 7
    });

    // Create a number that definitely has both chosenFactors among its factors.
    const number1 = randomIntegerInclusive(100, 1000, {
      constraint: x => x % chosenFactor1 === 0 && x % chosenFactor2 === 0
    });

    // Create a number that definitely has chosenFactor1 but not chosenFactor2 among its factors.
    const number2 = randomIntegerInclusive(100, 1000, {
      constraint: x => x % chosenFactor1 === 0 && x % chosenFactor2 !== 0
    });

    // Create a number that definitely has chosenFactor2 but not chosenFactor1 among its factors.
    const number3 = randomIntegerInclusive(100, 1000, {
      constraint: x => x % chosenFactor1 !== 0 && x % chosenFactor2 === 0
    });

    // Create a number that has neither chosenFactor1 nor chosenFactor2 among its factors.
    const number4 = randomIntegerInclusive(100, 1000, {
      constraint: x => x % chosenFactor1 !== 0 && x % chosenFactor2 !== 0
    });

    const numberArray = shuffle([number1, number2, number3, number4]);

    return { chosenFactor1, chosenFactor2, numberArray };
  },
  Component: props => {
    const {
      question: { chosenFactor1, chosenFactor2, numberArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.useDivisRulesForXAndYToFindNum(
          chosenFactor1.toLocaleString(),
          chosenFactor2.toLocaleString(),
          (chosenFactor1 * chosenFactor2).toLocaleString()
        )}
        testCorrect={numberArray.filter(it => it % chosenFactor1 === 0 && it % chosenFactor2 === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RulesOfDivisibility',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
