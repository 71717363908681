import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      d="M18.485 2.293a1 1 0 0 1 1.415 0c5.467 5.467 5.467 14.332 0 19.799a1 1 0 0 1-1.415-1.414c4.687-4.687 4.687-12.285 0-16.97a1 1 0 0 1 0-1.415ZM17.071 5.12a1 1 0 1 0-1.414 1.414 8 8 0 0 1 0 11.314 1 1 0 0 0 1.414 1.414c3.905-3.905 3.905-10.236 0-14.142ZM14.243 7.95a1 1 0 1 0-1.415 1.414 4 4 0 0 1 0 5.657 1 1 0 0 0 1.415 1.414 6 6 0 0 0 0-8.485ZM11 6.04c0-1.257-1.455-1.957-2.437-1.171L4.649 8H3a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1.65l3.913 3.131c.982.786 2.437.086 2.437-1.171V6.04ZM5.899 9.562 9 7.08v9.838l-3.101-2.48A2 2 0 0 0 4.649 14H3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1.65a2 2 0 0 0 1.249-.438Z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
