import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusiveStep } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import WoodenBalanceScale, {
  getScaledWeights,
  weightToDenominations
} from '../../../../components/question/representations/WoodenBalanceScale';
import QF65SetTheScales from '../../../../components/question/questionFormats/QF65SetTheScales';
import { isEqual } from '../../../../utils/matchers';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Scales from '../../../../components/question/representations/Scales';
import { View } from 'react-native';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bjK',
  description: 'bjK',
  keywords: ['Measure', 'Mass', 'Grams', 'Balance scales'],
  schema: z.object({
    weight: z.number().int().min(5).max(100).step(5),
    object: z.enum([
      'apple',
      'banana',
      'pear',
      'elephant',
      'giraffe',
      'bear',
      'carrot',
      'train',
      'pencil'
    ])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'apple',
      'banana',
      'pear',
      'elephant',
      'giraffe',
      'bear',
      'carrot',
      'train',
      'pencil'
    ] as const);

    const weight = randomIntegerInclusiveStep(5, 100, 5);

    return { weight, object };
  },
  Component: props => {
    const {
      question: { object, weight },
      translate,
      displayMode
    } = props;

    const objectSvg = {
      apple: ['Array_objects/AppleRed', 90],
      banana: ['Array_objects/Banana', 90],
      bear: ['Teddy_bear_2', 90],
      carrot: ['Carrot', 120],
      elephant: ['Elephant', 100],
      giraffe: ['Giraffe', 80],
      pear: ['Array_objects/Pear', 70],
      pencil: ['Pencils/Pencil_green', 200],
      train: ['Transport_toys/Train_carriage', 150]
    } as const;

    const weights = weightToDenominations(weight, [50, 20, 10, 5]);
    const scaledWeights = getScaledWeights({ weights, unit: 'g' });

    const weightComponent = weights.map((x, i) => (
      <AssetSvg
        width={scaledWeights[i] * (displayMode === 'digital' ? 100 : 180)}
        key={`weight_${x}_${i}`}
        name={`Weights/Weights_${x}g` as const}
      />
    ));
    const title = {
      apple: 'whatIsTheMassOfTheApple',
      banana: 'whatIsTheMassOfTheBanana',
      bear: 'whatIsTheMassOfTheBear',
      carrot: 'whatIsTheMassOfTheCarrot',
      elephant: 'whatIsTheMassOfTheElephant',
      giraffe: 'whatIsTheMassOfTheGiraffe',
      pear: 'whatIsTheMassOfThePear',
      pencil: 'whatIsTheMassOfThePencil',
      train: 'whatIsTheMassOfTheTrain'
    } as const;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions[title[object]]()}
        sentence={translate.ks1AnswerSentences.ansG()}
        testCorrect={[weight.toString()]}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[
              <AssetSvg
                key={'object'}
                name={objectSvg[object][0]}
                width={objectSvg[object][1] * (displayMode === 'digital' ? 1 : 1.8)}
                style={{ justifyContent: 'flex-end' }}
              />,
              <View key="weights" style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                {weightComponent}
              </View>
            ]}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
            stackInfront
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjL',
  description: 'bjL',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    weight: z.number().int().min(5).max(100).step(5),
    image: z.enum(['cube', 'cylinder', 'sphere', 'cone', 'cuboid'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(5, 100, 5);
    const image = getRandomFromArray(['cube', 'cylinder', 'sphere', 'cone', 'cuboid'] as const);
    return { weight, image };
  },
  Component: ({ question: { weight, image }, translate }) => {
    const svgName = 'Scales/100g_scale_10g';

    const imageSvg = {
      cube: 'Cubes_blank/Coloured_cube_unlabelled_red',
      cylinder: '3D_shapes_full_colors/Cylinders/Cylinder_pink',
      sphere: '3D_shapes_full_colors/Spheres/Sphere_green',
      cone: '3D_shapes_full_colors/Cones/Cone_yellow',
      cuboid: '3D_shapes_full_colors/Cuboids/Cuboid_purple'
    } as Record<string, SvgName>;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.ks1Instructions.whatIsTheMass()}
        testCorrect={[weight.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              weightG={weight}
              scaleWidth={dimens.height * 0.55}
              svgName={svgName}
              weightImage={imageSvg[image]}
              weightImageWidth={dimens.height * 0.3}
              weightStyle={{ bottom: -11, zIndex: 1 }}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjM',
  description: 'bjM',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    weight: z.number().int().min(5).max(100).step(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const weight = randomIntegerInclusiveStep(5, 100, 5);
    return { weight };
  },
  Component: ({ question: { weight }, translate }) => {
    return (
      <QF65SetTheScales
        title={translate.ks1Instructions.dragTheScaleToShowTheMassOfXG(weight)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowToShowTheMassOfXG(weight)}
        svgName="Scales/100g_scale_10g"
        answerGrams={weight}
        questionHeight={1000}
        testCorrect={isEqual(weight)}
      />
    );
  }
});
////
// Small Step
////
const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInGrams',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
