import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { ADD, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import { countRange } from '../../../../utils/collections';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKs',
  description: 'aKs',
  keywords: ['Partition', 'Part-whole model'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(6),
    multiplicandTens: z.number().int().min(10).max(90).step(10),
    multiplicandOnes: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 6);
    const multiplicandTens = randomIntegerInclusiveStep(10, 90, 10);
    const multiplicandOnes = randomIntegerInclusive(1, 9);

    return {
      multiplier,
      multiplicandTens,
      multiplicandOnes
    };
  },
  Component: props => {
    const {
      question: { multiplier, multiplicandTens, multiplicandOnes },
      translate
    } = props;
    const multiplicand = multiplicandTens + multiplicandOnes;
    const sentences = [
      `${MULT} ${multiplier} = <ans/>`,
      `${MULT} ${multiplier} = <ans/>`,
      `${multiplicand} ${MULT} ${multiplier} = <ans/>`
    ];
    return (
      <QF1ContentAndSentences
        title={translate.instructions.completePartWholeModel()}
        testCorrect={[
          [(multiplicandTens * multiplier).toString()],
          [(multiplicandOnes * multiplier).toString()],
          [(multiplicand * multiplier).toString()]
        ]}
        sentences={sentences}
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        style={{ alignItems: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ position: 'absolute', top: 25, left: 50 }}>
            <PartWholeModel
              top={multiplicand}
              partition={[multiplicandTens, multiplicandOnes]}
              dimens={{ width: dimens.width - 30, height: dimens.height / 1.64 }}
              variation="leftRight"
            />
          </View>
        )}
      />
    );
  }
});

const Question1V2 = newQuestionContent({
  uid: 'aKs2',
  description: 'aKs',
  keywords: ['Array'],
  schema: z
    .object({
      tens: z.number().int().min(1).max(9),
      ones: z.number().int().min(1).max(9),
      rows: z.number().int().min(2).max(6)
    })
    .refine(val => val.tens + val.ones <= 10, 'tens and ones must sum to less than 10'),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(1, 9, { constraint: x => x + tens <= 10 });
    const rows = randomIntegerInclusive(2, 6);

    return {
      tens,
      ones,
      rows
    };
  },
  Component: props => {
    const {
      question: { tens, ones, rows },
      translate
    } = props;

    const oneRowValue = tens * 10 + ones;
    const correctAnswer = oneRowValue * rows;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useDiagramToWorkOutXMultY(oneRowValue, rows)}
        testCorrect={[correctAnswer.toString()]}
        sentence={`<ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center' }}>
            {countRange(rows).map(i => (
              <View
                key={i}
                style={{
                  height: Math.min(dimens.height / rows, dimens.width / (tens + ones)),
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                {countRange(tens).map(i => (
                  <AssetSvg
                    key={`tens_${i}`}
                    name="Place_value/10"
                    height={Math.min(
                      (dimens.height / rows) * 0.9,
                      (dimens.width / (tens + ones)) * 0.9
                    )}
                  />
                ))}
                {countRange(ones).map(i => (
                  <AssetSvg
                    key={`ones_${i}`}
                    name="Place_value/1"
                    height={Math.min(
                      (dimens.height / rows) * 0.9,
                      (dimens.width / (tens + ones)) * 0.9
                    )}
                  />
                ))}
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aKt',
  description: 'aKt',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    tens: z.number().int().min(1).max(3),
    ones: z.number().int().min(1).max(4),
    rows: z.number().int().min(2).max(4),
    ansBoxOption: z.number().int().min(1).max(2)
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 3);
    const ones = randomIntegerInclusive(1, 4);
    const rows = randomIntegerInclusive(2, 4);
    const ansBoxOption = randomIntegerInclusive(1, 2);

    return { tens, ones, rows, ansBoxOption };
  },
  Component: ({ question: { tens, ones, rows, ansBoxOption }, translate, displayMode }) => {
    const var3 = tens * 10 + ones;

    const tensAns = tens * rows * 10;
    const onesAns = ones * rows;
    const answer = var3 * rows;

    const tensCounters = getImagesByAmount('Place_value/GreyCounter', tens);
    const onesCounters = getImagesByAmount('Place_value/GreyCounter', ones);

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(200, 50, tensCounters, 8),
      calcRowOfImagesScaleFactor(200, 50, onesCounters, 8)
    );

    const data = countRange(rows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 8 }}
          images={tensCounters}
          scaleFactor={scaleFactor}
        />,

        <RowOfImages
          key={i}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 8 }}
          images={onesCounters}
          scaleFactor={scaleFactor}
        />
      ];
    });

    const sentence = `${var3} ${MULT} ${rows} = ${
      ansBoxOption === 1 ? `<ans/> ${ADD} ${onesAns}` : `${tensAns} ${ADD} <ans/>`
    } = <ans/>`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartAndBase10ToSolve(
          `<g>${var3} ${MULT} ${rows}</g>`
        )}
        pdfDirection="column"
        Content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[1].color,
                  padding: 0
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[1].textColor
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color,
                  padding: 0
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[0].textColor
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'center' }}
        testCorrect={[
          ansBoxOption === 1 ? tensAns.toString() : onesAns.toString(),
          answer.toString()
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKu',
  description: 'aKu',
  keywords: ['Number line', 'Multiplication'],
  schema: z.object({
    tens: z.number().int().min(10).max(20).multipleOf(10),
    digits: z.number().int().min(1).max(9),
    multiplier: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(10, 20, 10);
    const digits = randomIntegerInclusive(1, 9);
    const multiplier = randomIntegerInclusive(2, 9);

    return { tens, digits, multiplier };
  },
  Component: props => {
    const {
      question: { tens, digits, multiplier },
      translate
    } = props;

    const number3 = tens + digits;
    const number5 = tens * multiplier;
    const number6 = number3 * multiplier;
    const number7 = tens * multiplier;
    const number8 = digits * multiplier;
    const firstJumpString = `${tens} ${MULT} ${multiplier} = ${number7}`;
    const secondJumpString = `${digits} ${MULT} ${multiplier} = ${number8}`;

    const startingNumber = 0;
    const endNumber = number6;

    const firstJumpScale =
      number6 - number5 > (number5 * 2) / 5 ? number5 : number6 - (number5 * 2) / 5;

    const tickArray = [
      {
        label: '0',
        position: 0
      },
      {
        label: '<ans/>',
        position: firstJumpScale
      },
      {
        label: '<ans/>',
        position: number6
      }
    ];

    const jumpArrowArray = [
      { start: 0, end: firstJumpScale, label: firstJumpString },
      { start: firstJumpScale, end: number6, label: secondJumpString }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToSolveX(
          `${number3.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        testCorrect={[number5.toString(), number6.toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aKv',
  description: 'aKv',
  keywords: ['Number line', 'Multiplication'],
  schema: z.object({
    tens: z.number().int().min(10).max(20).multipleOf(10),
    digits: z.number().int().min(1).max(9),
    multiplier: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(10, 20, 10);
    const digits = randomIntegerInclusive(1, 9);
    const multiplier = randomIntegerInclusive(2, 9);

    return { tens, digits, multiplier };
  },
  Component: props => {
    const {
      question: { tens, digits, multiplier },
      translate
    } = props;

    const number3 = tens + digits;
    const number5 = tens * multiplier;
    const number6 = number3 * multiplier;
    const firstJumpString = `${tens} ${MULT} ${multiplier}`;
    const secondJumpString = `${digits} ${MULT} ${multiplier}`;

    const startingNumber = 0;
    const endNumber = number6;

    const firstJumpScale =
      number6 - number5 > (number5 * 2) / 5 ? number5 : number6 - (number5 * 2) / 5;

    const tickArray = [
      {
        label: '0',
        position: 0
      },
      {
        label: '<ans/>',
        position: firstJumpScale
      },
      {
        label: '<ans/>',
        position: number6
      }
    ];

    const jumpArrowArray = [
      { start: 0, end: firstJumpScale, label: firstJumpString },
      { start: firstJumpScale, end: number6, label: secondJumpString }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToSolveX(
          `${number3.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        testCorrect={[number5.toString(), number6.toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aKw',
  description: 'aKw',
  keywords: ['Part-whole model', 'Multiplication'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(9),
    multiplicandTens: z.number().int().min(10).max(50).step(10),
    multiplicandOnes: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 9);
    const multiplicandTens = randomIntegerInclusiveStep(10, 50, 10);
    const multiplicandOnes = randomIntegerInclusive(1, 9);

    return {
      multiplier,
      multiplicandTens,
      multiplicandOnes
    };
  },
  Component: props => {
    const {
      question: { multiplier, multiplicandTens, multiplicandOnes },
      translate
    } = props;
    const multiplicand = multiplicandTens + multiplicandOnes;
    const sentences = [
      `${MULT} ${multiplier} = <ans/>`,
      `${MULT} ${multiplier} = <ans/>`,
      `${multiplicand} ${MULT} ${multiplier} = <ans/>`
    ];
    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePartWholeModelToSolve(
          `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        testCorrect={[
          [(multiplicandTens * multiplier).toString()],
          [(multiplicandOnes * multiplier).toString()],
          [(multiplicand * multiplier).toString()]
        ]}
        sentences={sentences}
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        style={{ alignItems: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ position: 'absolute', top: 25, left: 50 }}>
            <PartWholeModel
              top={multiplicand}
              partition={[multiplicandTens, multiplicandOnes]}
              dimens={{ width: dimens.width - 30, height: dimens.height / 1.64 }}
              variation="leftRight"
            />
          </View>
        )}
      />
    );
  }
});

const Question5V2 = newQuestionContent({
  uid: 'aKw2',
  description: 'aKw',
  keywords: ['Multiplication'],
  schema: z.object({
    number1: z.number().int().min(10).max(90).step(10),
    number2: z.number().int().min(1).max(9),
    number4: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 90, 10);
    const number2 = randomIntegerInclusive(1, 9);
    const number4 = randomIntegerInclusive(2, 9, { constraint: x => x !== number2 });

    return { number1, number2, number4 };
  },
  Component: ({ question: { number1, number2, number4 }, translate }) => {
    const number3 = number1 + number2;
    const number5 = number1 / 10;

    const items = shuffle(
      [
        {
          vslue: 'A',
          string: `${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        },
        {
          vslue: 'B',
          string: `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${ADD} ${number4.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
        },
        {
          vslue: 'C',
          string: `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        },
        {
          vslue: 'D',
          string: `${number5.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${ADD} ${number2.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        }
      ],
      { random: seededRandom({ number1, number2, number4 }) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheCalculationEquivalentToXMultY(number3, number4)}
        pdfTitle={translate.instructions.circleTheCalculationEquivalentToXMultY(number3, number4)}
        testCorrect={['A']}
        numItems={4}
        renderItems={items.map(val => ({
          value: val.vslue,
          component: <Text variant="WRN700">{val.string}</Text>
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKx',
  description: 'aKx',
  keywords: ['Partition', 'Multiplication'],
  schema: z.object({
    tens: z.number().int().min(30).max(90).multipleOf(10),
    digits: z.number().int().min(1).max(9),
    multiplier: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(30, 90, 10);
    const digits = randomIntegerInclusive(1, 9);
    const multiplier = randomIntegerInclusive(2, 9);

    return { tens, digits, multiplier };
  },
  Component: props => {
    const {
      question: { tens, digits, multiplier },
      translate
    } = props;

    const number3 = tens + digits;

    const answer1 = digits * multiplier;
    const answer2 = tens * multiplier;
    const answer3 = number3 * multiplier;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculationsToSolveX(
          `${number3} ${MULT} ${multiplier}`
        )}
        testCorrect={[[answer1.toString()], [answer2.toString()], [answer3.toString()]]}
        sentences={[
          `${digits.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
          `${tens.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InformalWrittenMethodsForMultiplication',
  questionTypes: [Question1V2, Question2, Question3, Question4, Question5V2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question5]
});
export default SmallStep;
