import { StyleSheet, View } from 'react-native';
import TextStructure from '../../../molecules/TextStructure';

/** Render custom mark scheme prop passed to QF */
export const renderMarkSchemeProp = (markSchemAnswer: string): JSX.Element => {
  const styles = getStyles();

  return (
    <View style={styles.textContainer}>
      <TextStructure sentence={markSchemAnswer} />
    </View>
  );
};

const getStyles = () => {
  return StyleSheet.create({
    textContainer: {
      paddingTop: 32,
      justifyContent: 'flex-end',
      alignSelf: 'flex-end'
    }
  });
};
