import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { DisplayShapeOnGrid } from 'common/src/components/question/representations/DisplayShapeOnGrid';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import { createHundredSquareShape } from 'common/src/utils/shapes';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel, {
  ShadedFractionBarModelWithState
} from 'common/src/components/question/representations/ShadedFractionBarModel';
import { filledArray, range } from 'common/src/utils/collections';
import { barModelColors, colors } from 'common/src/theme/colors';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { dayNames, daySchema } from 'common/src/utils/days';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRk',
  description: 'aRk',
  keywords: ['Percentages', '100 square'],
  schema: z.object({
    number: z.number().int().min(10).max(100).multipleOf(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10, 100, 10);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const shape = createHundredSquareShape(number);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsPartsOutOfAHundredShaded(),
          translate.answerSentences.thisIsAnsPercent()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[[number.toString()], [number.toString()]]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRl',
  description: 'aRl',
  keywords: ['Percentages', '100 square'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(1)
      .max(100)
      .refine(x => x % 10 !== 0, 'number should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 100, {
      constraint: x => x % 10 !== 0
    });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const shape = createHundredSquareShape(number);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsPartsOutOfAHundredShaded(),
          translate.answerSentences.thisIsAnsPercent()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[[number.toString()], [number.toString()]]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRm',
  description: 'aRm',
  keywords: ['Percentages', 'Bar model'],
  schema: z.object({
    shaded: z.number().int().min(1).max(10),
    shadedColor: z.string()
  }),
  simpleGenerator: () => {
    const shaded = randomIntegerInclusive(1, 10);
    const shadedColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { shaded, shadedColor };
  },
  Component: props => {
    const {
      question: { shaded, shadedColor },
      translate
    } = props;

    // Bar model
    const shadedColorArray = filledArray(shadedColor, shaded);
    const remainder = filledArray('white', 10 - shaded);

    const customColorMap = [...shadedColorArray, ...remainder];

    // Answer
    const ans = shaded * 10;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/> %'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatPercentageOfTheBarModelIsShaded()}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <ShadedFractionBarModel
              totalSubSections={10}
              customColorMap={customColorMap}
              totalPerSection={shaded}
              width={dimens.width}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRn',
  description: 'aRn',
  keywords: ['Percentages', 'Bar model', 'Parts', 'Whole'],
  schema: z.object({
    twentieths: z.number().int().min(1).max(20),
    numeratorColor: z.string()
  }),
  simpleGenerator: () => {
    const twentieths = randomIntegerInclusive(1, 20);
    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { twentieths, numeratorColor };
  },
  Component: props => {
    const {
      question: { twentieths, numeratorColor },
      translate
    } = props;

    // Bar model
    const numeratorColorArray = filledArray(numeratorColor, twentieths);
    const remainder = filledArray('white', 20 - twentieths);

    const customColorMap = [...numeratorColorArray, ...remainder];

    // Answer
    const ans = twentieths * 5;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/> %'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatPercentageOfTheBarModelIsShaded()}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <ShadedFractionBarModel
              totalSubSections={20}
              customColorMap={customColorMap}
              totalPerSection={2}
              width={dimens.width}
            />
          );
        }}
      />
    );
  }
});

// Question4 exported to Q aVQ
const Question5 = newQuestionContent({
  uid: 'aRo',
  description: 'aRo',
  keywords: ['Percentage', 'Bar model'],
  schema: z.object({
    percentage: z.number().int().min(5).max(100).multipleOf(5)
  }),
  simpleGenerator: () => {
    const percentage = randomIntegerInclusiveStep(5, 100, 5);

    return { percentage };
  },
  Component: props => {
    const {
      question: { percentage },
      translate,
      displayMode
    } = props;

    const partsToShade = percentage / 5;

    return (
      <QF3Content
        title={translate.instructions.shadePercentageOfBarModel(percentage)}
        actionPanelVariant="bottom"
        Content={({ dimens }) => (
          <ShadedFractionBarModelWithState
            id="barmodel"
            totalSubSections={20}
            totalPerSection={2}
            width={dimens.width}
            color={colors.burntSienna}
            defaultState={displayMode === 'markscheme' ? range(0, partsToShade - 1) : []}
            testCorrect={userAnswer => userAnswer.length === partsToShade}
          />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyNumPartsOfTheBarModelCanBeShadedToMakeNumPercent(
            percentage / 5,
            percentage
          )
        }}
      />
    );
  }
});
export const aRo = Question5;

const Question6 = newQuestionContent({
  uid: 'aRp',
  description: 'aRp',
  keywords: ['Percentages'],
  schema: z.object({
    ticketsA: z.number().int().min(2).max(59),
    ticketsB: z.number().int().min(2).max(59),
    name: nameSchema,
    days: z.array(daySchema)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const ticketsA = randomIntegerInclusive(2, 59);
    const ticketsB = randomIntegerInclusive(2, 59, {
      constraint: x => x + ticketsA <= 98
    });

    const name = getRandomName();
    const days = getRandomSubArrayFromArray(dayNames, 2);

    return { ticketsA, ticketsB, name, days };
  },
  Component: props => {
    const {
      question: { ticketsA, ticketsB, name, days },
      translate
    } = props;

    // Days
    const [dayA, dayB] = days;

    // Answer
    const ans = 100 - (ticketsA + ticketsB);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.characterXIsSellingTicketsForSchoolPlayThereAreYTickets(
          name,
          100
        )}<br/>${translate.instructions.onXDayYTicketsWereSold(
          dayA,
          ticketsA
        )}<br/>${translate.instructions.onXDayYTicketsWereSold(
          dayB,
          ticketsB
        )}<br/>${translate.instructions.whatPercentageOfTicketsAreStillForSale()}`}
        sentence={'<ans/>%'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        testCorrect={[ans.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandPercentages',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
