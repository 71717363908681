import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import {
  arrayHasNoDuplicates,
  countRange,
  rangeAsString,
  sortNumberArray
} from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { parseToSUB } from '../../../../utils/parse';
import Text from '../../../../components/typography/Text';
import { Thermometer } from '../../../../components/question/representations/Thermometer';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCO',
  description: 'aCO',
  keywords: ['Negative numbers', 'Compare', 'Thermometer', 'Temperature'],
  schema: z
    .object({
      temp1: z.number().int().min(-5).max(5),
      temp2: z.number().int().min(-5).max(5)
    })
    .refine(({ temp1, temp2 }) => temp1 !== temp2, 'temperatures should be different'),
  simpleGenerator: () => {
    const [temp1, temp2] = randomUniqueIntegersInclusive(-5, 5, 2);

    return {
      temp1,
      temp2
    };
  },
  Component: ({ question: { temp1, temp2 }, translate }) => {
    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragLessThanGreaterThanEqualsToCompareTemperatures()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareTemperatures()}
        itemVariant="square"
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <Thermometer
              dimens={{ height: dimens.height, width: dimens.width * 0.5 }}
              topScale={5}
              bottomScale={-5}
              temperature={temp1}
            />
            <Thermometer
              dimens={{ height: dimens.height, width: dimens.width * 0.5 }}
              topScale={5}
              bottomScale={-5}
              temperature={temp2}
            />
          </View>
        )}
        sentence={`${translate.units.numberOfDegreesC(
          parseToSUB(temp1.toString())
        )} <ans/> ${translate.units.numberOfDegreesC(parseToSUB(temp2.toString()))}`}
        testCorrect={[lessThanGreaterThanOrEqualTo(temp1, temp2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aCP',
  description: 'aCP',
  keywords: ['Negative numbers', 'Compare'],
  schema: z.object({
    startingNumber: z.number().int().min(-20).max(-10).multipleOf(5),
    number1: z.number().int().min(-20).max(0),
    number2: z.number().int().min(-20).max(0)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(-20, -10, 5);
    const [number1, number2] = randomUniqueIntegersInclusive(
      startingNumber,
      startingNumber + 10,
      2
    );

    return { startingNumber, number1, number2 };
  },
  Component: props => {
    const {
      question: { startingNumber, number1, number2 },
      translate
    } = props;

    const endNumber = startingNumber + 10;

    const tickArray = rangeAsString(startingNumber, endNumber, 1, true).map(num => parseToSUB(num));

    return (
      <QF36ContentAndSentenceDrag
        actionPanelVariant={'bottom'}
        title={translate.instructions.dragLessThanGreaterThanEqualsToCompareTemperatures()}
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheNumbers()}
        pdfLayout="itemsHidden"
        Content={({ dimens }) => <NumberLine dimens={dimens} tickValues={tickArray} />}
        sentence={`${parseToSUB(number1.toLocaleString())} <ans/> ${parseToSUB(
          number2.toLocaleString()
        )}`}
        items={['<', '>', '=']}
        itemVariant={'square'}
        testCorrect={[lessThanGreaterThanOrEqualTo(number1, number2)]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCQ',
  description: 'aCQ',
  keywords: ['Negative numbers', 'Compare'],
  schema: z.object({
    questionNumbers: z.array(z.number().int().min(-100).max(10)).length(2)
  }),
  simpleGenerator: () => {
    const numbersToUse = getRandomFromArray([1, 2, 3]);

    const questionNumbers =
      numbersToUse === 1
        ? [randomIntegerInclusive(-10, 5), randomIntegerInclusive(-10, 10)]
        : numbersToUse === 2
        ? countRange(2).map(_ => randomIntegerInclusive(-20, 0))
        : countRange(2).map(_ => randomIntegerInclusive(-100, -1));

    return {
      questionNumbers
    };
  },
  Component: ({ question: { questionNumbers }, translate, displayMode }) => {
    const statement = {
      lhsComponent: (
        <Text
          variant="WRN400"
          style={{ width: displayMode === 'digital' ? 100 : 200, textAlign: 'right' }}
        >
          {parseToSUB(questionNumbers[0].toLocaleString())}
        </Text>
      ),
      rhsComponent: (
        <Text
          variant="WRN400"
          style={{ width: displayMode === 'digital' ? 100 : 200, textAlign: 'left' }}
        >
          {parseToSUB(questionNumbers[1].toLocaleString())}
        </Text>
      ),
      correctAnswer: lessThanGreaterThanOrEqualTo(questionNumbers[0], questionNumbers[1])
    };
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareNumbers()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareNumbers()}
        itemVariant="square"
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statementStyle={{ justifyContent: 'center' }}
        statements={[statement]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3v2 = newQuestionContent({
  uid: 'aCQ2',
  description: 'aCQ',
  keywords: ['Negative numbers', 'Compare'],
  schema: z.object({
    questionNumbers: z
      .array(z.number().int().min(-100).max(10))
      .length(2)
      .refine(questionNumbers => arrayHasNoDuplicates(questionNumbers), 'numbers have to be unique')
  }),
  simpleGenerator: () => {
    const numbersToUse = getRandomFromArray([1, 2, 3]);

    const questionNumbers = (() => {
      switch (numbersToUse) {
        case 1: {
          const numA = randomIntegerInclusive(-10, 5);
          const numB = randomIntegerInclusive(-10, 10, { constraint: x => x !== numA });
          return [numA, numB];
        }
        case 2: {
          const numA = randomIntegerInclusive(-20, 0);
          const numB = randomIntegerInclusive(-20, 0, { constraint: x => x !== numA });
          return [numA, numB];
        }
        default: {
          const numA = randomIntegerInclusive(-100, -1);
          const numB = randomIntegerInclusive(-100, -1, { constraint: x => x !== numA });
          return [numA, numB];
        }
      }
    })();

    return {
      questionNumbers
    };
  },
  Component: ({ question: { questionNumbers }, translate, displayMode }) => {
    const statement = {
      lhsComponent: (
        <Text
          variant="WRN400"
          style={{ width: displayMode === 'digital' ? 100 : 200, textAlign: 'right' }}
        >
          {parseToSUB(questionNumbers[0].toLocaleString())}
        </Text>
      ),
      rhsComponent: (
        <Text
          variant="WRN400"
          style={{ width: displayMode === 'digital' ? 100 : 200, textAlign: 'left' }}
        >
          {parseToSUB(questionNumbers[1].toLocaleString())}
        </Text>
      ),
      correctAnswer: lessThanGreaterThanOrEqualTo(questionNumbers[0], questionNumbers[1])
    };
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragInequalitiesToCompareTemperatures()}
        pdfTitle={translate.instructions.useGreaterThanAndLessThanToCompareTemperatures()}
        itemVariant="square"
        items={['>', '<']}
        moveOrCopy="move"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statementStyle={{ justifyContent: 'center' }}
        statements={[statement]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aCR',
  description: 'aCR',
  keywords: ['Negative numbers', 'Compare', 'Order'],
  schema: z.object({
    startingNumber: z.number().int().min(-100).max(0).multipleOf(5),
    isLess: z.boolean(),
    number: z.number().int().min(-99).max(9)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(-100, 0, 5);
    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);
    const isLess = getRandomBoolean();

    return { startingNumber, isLess, number };
  },
  Component: props => {
    const {
      question: { startingNumber, isLess, number },
      translate
    } = props;

    const endNumber = startingNumber + 10;

    const tickArray = rangeAsString(startingNumber, endNumber, 1, true).map(num => parseToSUB(num));

    const title = isLess ? 'dragArrowToNumberLessThanX' : 'dragArrowToNumberGreaterThanX';
    const pdfTitle = isLess ? 'drawArrowToNumberLessThanX' : 'drawArrowToNumberGreaterThanX';

    const acceptedRange: [number, number] = isLess
      ? [startingNumber, number - 1]
      : [number + 1, startingNumber + 10];

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions[title](parseToSUB(number.toLocaleString()))}
        pdfTitle={translate.instructions[pdfTitle](parseToSUB(number.toLocaleString()))}
        testCorrect={acceptedRange}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickArray}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            parseToSUB(acceptedRange[0].toLocaleString()),
            parseToSUB(acceptedRange[1].toLocaleString())
          )
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCS',
  description: 'aCS',
  keywords: ['Order', 'Negative numbers'],
  schema: z.object({
    numbers: z
      .array(z.number().min(-30).max(20))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x)),
    ascending: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(-30, -1, 2);
    numbers.push(randomIntegerInclusive(0, 20));
    numbers.push(
      randomIntegerInclusive(-30, 20, { constraint: x => arrayHasNoDuplicates([...numbers, x]) })
    );

    const ascending = getRandomBoolean();

    return { numbers: shuffle(numbers), ascending };
  },
  Component: props => {
    const {
      question: { numbers, ascending },
      translate
    } = props;

    const startWithTemp = ascending ? 'startWithTheColdestTemp' : 'startWithTheWarmestTemp';

    return (
      <QF4DragOrderVertical
        title={`${translate.instructions.dragCardsToOrderTheTemp()}<br/>${translate.instructions[
          startWithTemp
        ]()}`}
        pdfTitle={`${translate.instructions.useCardsToOrderTheTemp()}<br/>${translate.instructions[
          startWithTemp
        ]()}`}
        testCorrect={sortNumberArray(numbers, ascending ? 'ascending' : 'descending')}
        items={numbers.map(number => ({
          value: number,
          component: `${translate.units.stringDegreesC(parseToSUB(number.toLocaleString()))}`
        }))}
        topLabel={ascending ? translate.misc.Coldest() : translate.misc.Warmest()}
        bottomLabel={ascending ? translate.misc.Warmest() : translate.misc.Coldest()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aCT',
  description: 'aCT',
  keywords: ['Order', 'Negative numbers'],
  schema: z.object({
    numbers: z.array(z.number().min(-100).max(0)).length(4),
    ascending: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(-100, 0, 4);

    const ascending = getRandomBoolean();

    return { numbers, ascending };
  },
  Component: props => {
    const {
      question: { numbers, ascending },
      translate
    } = props;

    const startWithTemp = ascending ? 'startWithTheColdestTemp' : 'startWithTheWarmestTemp';

    return (
      <QF4DragOrderVertical
        title={`${translate.instructions.dragCardsToOrderTheTemp()}<br/>${translate.instructions[
          startWithTemp
        ]()}`}
        pdfTitle={`${translate.instructions.useCardsToOrderTheTemp()}<br/>${translate.instructions[
          startWithTemp
        ]()}`}
        testCorrect={sortNumberArray(numbers, ascending ? 'ascending' : 'descending')}
        items={numbers.map(number => ({
          value: number,
          component: `${translate.units.stringDegreesC(parseToSUB(number.toLocaleString()))}`
        }))}
        topLabel={ascending ? translate.misc.Coldest() : translate.misc.Warmest()}
        bottomLabel={ascending ? translate.misc.Warmest() : translate.misc.Coldest()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderNegativeNumbers',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3],
  unpublishedQuestionTypes: []
});
export default SmallStep;
