import React from 'react';
import { useMemo, useContext } from 'react';
import { View } from 'react-native';
import { Dimens, containAspectRatio, ScaleFactorContext } from 'common/src/theme/scaling';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { Line, Svg, Rect, Text as SvgText } from 'react-native-svg';
import { range } from '../../../utils/collections';
import { DisplayMode } from '../../../contexts/displayMode';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';
import { withStateHOC } from 'common/src/stateTree';
import { isInRange } from '../../../utils/matchers';
import { useI18nContext } from '../../../i18n/i18n-react';
import { parseToSUB } from '../../../utils/parse';

const THERMOMETER_HEIGHT = 394.88147;
const THERMOMETER_WIDTH = 116.4252;
const VERTICAL_STROKE_WIDTH = 1;
const MAJOR_TICK_STROKE = 2;
const BOTTOM_OFFSET = 63;
const TOP_OFFSET = 28;

// 96 is the minimum answer box size, and feels about right for grabbing the arrow on mobile. Smaller can feel difficult to drag.
const ARROW_HEIGHT = 96;

const AnimatedRect = Animated.createAnimatedComponent(Rect);

type ThermometerScaleProps = {
  height: number;
  width: number;
  /**
   * Top scale number.
   */
  topScale: number;
  /**
   * Bottom scale number.
   */
  bottomScale: number;
  /**
   * Step interval of the scale.
   */
  step: number;
  /**
   * How high the ticks will start from the bottom of the svg.
   */
  startOffset: number;
  scaleFactor: number;
  pixelsPerUnit: number;
  showOnlyLabels?: number[];
  horizontal: boolean;
  labelFontSize?: number;
};

/**
 * A component that displays a thermometer with its scale.
 * This arranges the ticks and labels along the thermometer scale.
 */
const ThermometerScale = ({
  height,
  width,
  topScale,
  bottomScale,
  step,
  startOffset,
  scaleFactor,
  pixelsPerUnit,
  showOnlyLabels,
  horizontal,
  labelFontSize
}: ThermometerScaleProps) => {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;
  const LABEL_FONT_SIZE =
    labelFontSize ?? Math.max(12 * scaleFactor, displayMode === 'digital' ? 18 : 35);
  const MAJOR_TICK_LENGTH = 8 * scaleFactor;
  const xScalePosition = width - 48 * scaleFactor;
  const degUnitPosition = horizontal ? width - 90 * scaleFactor : width - 77 * scaleFactor;

  const [labelOffset, textRotation, thermUnitOffset] = horizontal
    ? [5 * scaleFactor, 90, height * 0.75]
    : [3, 180, 0];

  const ticks = (() => {
    return (
      <Svg viewBox={`0 0 ${width} ${height}`} height={height} width={width}>
        {/* Ticks */}
        {range(bottomScale, topScale, step).map((tick, i) => {
          const y = startOffset + pixelsPerUnit * i;
          return (
            <React.Fragment key={`ticks_${i}`}>
              {/* Major ticks */}
              <Line
                key={`tick-${i}`}
                strokeWidth={MAJOR_TICK_STROKE}
                stroke="black"
                x1={xScalePosition + VERTICAL_STROKE_WIDTH * 0.5}
                x2={xScalePosition - MAJOR_TICK_LENGTH}
                y1={y}
                y2={y}
              />
              {/* Label text */}
              {((showOnlyLabels !== undefined && showOnlyLabels.includes(tick)) ||
                showOnlyLabels === undefined) && (
                <SvgText
                  key={`label-${i}`}
                  y={y}
                  x={MAJOR_TICK_LENGTH + xScalePosition + labelOffset}
                  fontSize={LABEL_FONT_SIZE}
                  fontFamily="White_Rose_Noto-Regular"
                  textAnchor="end"
                  alignmentBaseline="baseline"
                  transform={`rotate(${textRotation}, ${MAJOR_TICK_LENGTH + xScalePosition}, ${
                    y - LABEL_FONT_SIZE * 0.15
                  })`}
                >
                  {parseToSUB(tick.toString())}
                </SvgText>
              )}
            </React.Fragment>
          );
        })}
        {/* Temperature units */}
        <SvgText
          x={degUnitPosition + thermUnitOffset}
          y={startOffset - 10}
          transform={`rotate(${textRotation}, ${degUnitPosition}, ${
            startOffset - 10 - LABEL_FONT_SIZE * 0.15
          })`}
          fontSize={LABEL_FONT_SIZE + 5}
          fontFamily="White_Rose_Noto-Bold"
        >
          {translate.units.celsius()}
        </SvgText>
      </Svg>
    );
  })();

  const thermometerSvgPath = 'Thermometers/Vertical_Thermometer_blank';

  return (
    <View
      style={{
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height
      }}
      pointerEvents="none"
    >
      <AssetSvg name={thermometerSvgPath as SvgName} height={height} />
      <View
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 2,
          // Flip it so it starts at the bottom
          transform: [{ rotate: `180deg` }]
        }}
      >
        <View>{ticks}</View>
      </View>
    </View>
  );
};

const getSizingInfo = (scaleLength: number, thermometerHeight: number, scaleFactor: number) => {
  const width = THERMOMETER_WIDTH;
  const usableHeightForTicks = thermometerHeight - (BOTTOM_OFFSET + TOP_OFFSET) * scaleFactor;
  const startOffset = BOTTOM_OFFSET * scaleFactor;
  const height = usableHeightForTicks + startOffset;
  const pixelsPerUnit = usableHeightForTicks / scaleLength;
  return {
    naturalWidth: width,
    naturalHeight: height,
    pixelsPerUnit,
    startOffset
  };
};

export type ThermometerProps = {
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Top scale number.
   */
  topScale: number;
  /**
   * Bottom scale number.
   */
  bottomScale: number;
  /**
   * Optional. Step interval of the scale. Defaults to 1.
   */
  step?: number;
  /** Current temperature in degrees */
  temperature: number;
  /** Callback for when temperature is changed by a gesture. */
  setTemperature?: (x: number) => void;
  /**
   * Optional. For interactive thermometer, what degree of temp to snap to. Defaults to 1.
   */
  snapToNearest?: number;
  /**
   * If true, shows arrow. If false, is a static thermometer. Defaults to false.
   */
  isInteractive?: boolean;
  /**
   * Optional. If passed, only shows the labels specified in the array. If undefined all labels are shown.
   */
  showOnlyLabels?: number[];
  /**
   *  Optional. Whether the orientation of the thermometer should be horizontal instead of vertical. Default: false.
   */
  horizontal?: boolean;
  /**
   *  Optional. Change default font sizes.
   */
  labelFontSize?: number;
};

/**
 * This component renders a draggable arrow along a thermometer to change the temperature.
 * Non-interactive version shows a static temperature and no arrow.
 */
export const Thermometer = ({
  dimens: { width, height },
  bottomScale,
  topScale,
  step = 1,
  temperature,
  setTemperature,
  snapToNearest = 1,
  isInteractive = false,
  showOnlyLabels = undefined,
  horizontal = false,
  labelFontSize
}: ThermometerProps) => {
  const fill = colors.red;
  const scaleFactor = useContext(ScaleFactorContext);

  if (topScale < bottomScale) throw `Bottom scale must be lower than topScale`;
  const scaleLength = (topScale - bottomScale) / step;

  const [heightToUse, widthToUse] = horizontal ? [width, height] : [height, width];

  const { width: thermometerWidth, height: thermometerHeight } = containAspectRatio(
    { width: widthToUse, height: heightToUse },
    THERMOMETER_WIDTH / THERMOMETER_HEIGHT
  );

  const thermRotation = horizontal ? '90deg' : '0deg';

  const scaleFactorThermometer = thermometerHeight / THERMOMETER_HEIGHT;
  const { pixelsPerUnit, startOffset } = getSizingInfo(
    scaleLength,
    thermometerHeight,
    scaleFactorThermometer
  );
  const unitsPerPixel = 1 / pixelsPerUnit;
  const snapToNearestPixels = pixelsPerUnit * (snapToNearest / step);

  const units = (temperature - bottomScale) / step;

  const tempPixels = Math.round(units * pixelsPerUnit);
  const animatedAmount = useSharedValue(tempPixels);
  const setTemperaturePixels: ((x: number) => void) | undefined = useMemo(() => {
    if (setTemperature === undefined) {
      return undefined;
    }
    return x => setTemperature(Math.round(bottomScale + x * unitsPerPixel * step));
  }, [setTemperature, unitsPerPixel, bottomScale, step]);

  const maxTemperature = scaleLength * pixelsPerUnit;

  const beginPagePosition = useSharedValue<[number, number] | null>(null);
  const beginAmount = useSharedValue<number | null>(null);
  const updateParent = setTemperaturePixels !== undefined;
  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPagePosition.value = [event.absoluteX, event.absoluteY];
          beginAmount.value = animatedAmount.value;
        })
        .onUpdate(event => {
          const translation = (event.absoluteY - beginPagePosition.value![1]) / scaleFactor;
          // Negative to 'fill' liquid upwards
          let currentLength = beginAmount.value! - translation;
          // Clamp to stay in bounds of the thermometer:
          currentLength = Math.min(maxTemperature, Math.max(0, currentLength));
          // Snapping behaviour - movement in pixels
          currentLength = Math.round(currentLength / snapToNearestPixels) * snapToNearestPixels;
          animatedAmount.value = currentLength;
        })
        .onFinalize(() => {
          updateParent && runOnJS(setTemperaturePixels)(animatedAmount.value);
        }),
    [
      animatedAmount,
      beginAmount,
      beginPagePosition,
      maxTemperature,
      scaleFactor,
      setTemperaturePixels,
      snapToNearestPixels,
      updateParent
    ]
  );

  const animatedArrowStyle = useAnimatedStyle(
    () => ({ bottom: startOffset + animatedAmount.value - ARROW_HEIGHT / 2 }),
    [startOffset, animatedAmount.value]
  );

  // Account for extra space between the container and svg
  const heightDifference = heightToUse - thermometerHeight;
  const animatedLiquidProps = useAnimatedProps(
    () => ({
      height: startOffset + heightDifference + animatedAmount.value,
      y: heightToUse - animatedAmount.value - startOffset - heightDifference
    }),
    [startOffset, animatedAmount.value, heightToUse, heightDifference]
  );

  return (
    <View
      style={{
        width: widthToUse,
        height: heightToUse,
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ rotate: thermRotation }]
      }}
    >
      {isInteractive && (
        <GestureDetector gesture={panGesture}>
          <Animated.View
            style={[
              {
                width: 110,
                height: ARROW_HEIGHT,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                right: width * 0.5 + thermometerWidth * 0.6
              },
              animatedArrowStyle
            ]}
          >
            <AssetSvg
              name="SliderArrowRightCustomizable"
              height={26}
              svgProps={{ fill: colors.burntSienna }}
            />
          </Animated.View>
        </GestureDetector>
      )}
      <View
        style={{
          width: thermometerWidth,
          height: thermometerHeight
        }}
      >
        <ThermometerScale
          height={thermometerHeight}
          width={thermometerWidth}
          topScale={topScale}
          bottomScale={bottomScale}
          step={step}
          startOffset={startOffset}
          scaleFactor={scaleFactorThermometer}
          pixelsPerUnit={pixelsPerUnit}
          showOnlyLabels={showOnlyLabels}
          horizontal={horizontal}
          labelFontSize={labelFontSize}
        />
        <Svg
          width={thermometerWidth * 0.63}
          height={thermometerHeight - 2}
          style={{
            position: 'absolute',
            zIndex: -1,
            alignSelf: 'center'
          }}
        >
          <AnimatedRect
            pointerEvents="none"
            width={thermometerWidth * 0.7}
            x={0}
            animatedProps={animatedLiquidProps}
            fill={fill}
          />
        </Svg>
      </View>
    </View>
  );
};

/** StateTree version of {@link Thermometer}. */
export const ThermometerWithState = withStateHOC(Thermometer, {
  stateProp: 'temperature',
  setStateProp: 'setTemperature',
  defaults: ({ topScale, bottomScale, step = 1 }) => ({
    defaultState: bottomScale - 2 * step, // so the temperature starts at the absolute bottom of the thermometer
    testComplete: isInRange(bottomScale, topScale)
  })
});
