import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import Text from 'common/src/components/typography/Text';
import { AssetSvg, SvgName, getSvgInfo } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { Path, Svg } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from '../../../utils/random';

type Props = {
  /**
   * @param labels An array of strings to hold the labels that go around the shape.
   * Should be given in form [upper-left, upper-right, lower-left, lower-right, bottom]
   */
  labels: string[];
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  centerLabel?: string;
  showEqualSidesLines?: boolean;
  /**
   * Prop to decide if one arrow along the top-left of the pentagon should be displayed.
   * Defaults to false.
   */
  showArrow?: boolean;
  /**
   * Prop to decide if a pentagon SVG with all arrows should be displayed.
   * If true, overrides showArrow.
   * Defaults to false.
   */
  allArrows?: boolean;
};
/**
 * This component renders a pentagon with labels on the sides.
 */
export const LabelledPentagon = (props: Props) => {
  const {
    labels,
    dimens: { width },
    centerLabel = undefined,
    showEqualSidesLines,
    showArrow,
    allArrows
  } = props;
  const displayMode = useContext(DisplayMode);
  const shapeWidth = width * 0.35;
  const longestItemTextLength = labels.reduce((max, item) => Math.max(max, item.length), 0);
  const labelWidth =
    displayMode === 'digital' ? longestItemTextLength * 18 : longestItemTextLength * 25;
  const styles = useStyles(shapeWidth, displayMode);
  const font = displayMode === 'digital' ? 32 : 40;
  const smallFont = displayMode === 'digital' ? 22 : 32;

  const pentagonSvgPath = getRandomFromArray(
    allArrows
      ? [
          'Shapes_with_dimension_arrows/pentagon_blue_arrows',
          'Shapes_with_dimension_arrows/pentagon_green_arrows',
          'Shapes_with_dimension_arrows/pentagon_orange_arrows',
          'Shapes_with_dimension_arrows/pentagon_purple_arrows',
          'Shapes_with_dimension_arrows/pentagon_red_arrows'
        ]
      : [
          'Pentagons/pentagon_blue',
          'Pentagons/pentagon_green',
          'Pentagons/pentagon_pink',
          'Pentagons/pentagon_purple',
          'Pentagons/pentagon_yellow'
        ],
    {
      random: seededRandom({ labels, centerLabel, showEqualSidesLines, showArrow, allArrows })
    }
  );

  const centerLabelColor =
    pentagonSvgPath === 'Pentagons/pentagon_pink' || pentagonSvgPath === 'Pentagons/pentagon_yellow'
      ? colors.prussianBlue
      : colors.white;

  const { aspectRatio } = getSvgInfo(pentagonSvgPath as SvgName);
  const shapeHeight = shapeWidth / aspectRatio;

  // get labels for shapes and absolute positions
  const shapeLabels = () => {
    const svgCenter = shapeWidth / 2.5;
    const lineHeight = 40;

    const style = [
      { left: 0, top: showArrow ? -20 : -10 }, //Top Left
      { left: shapeWidth * 0.8, top: -10 }, //Top Right
      { right: shapeWidth * 0.9, top: shapeHeight * 0.7 }, //Bottom Left
      { left: shapeWidth * 0.9, top: shapeHeight * 0.7 }, //Bottom Right
      { bottom: -lineHeight, left: svgCenter } //Bottom
    ];

    return labels.map((_label, index) => {
      return (
        <View key={index} style={[style[index], { position: 'absolute', minWidth: labelWidth }]}>
          <View>
            <Text
              variant="WRN700"
              style={{ fontSize: smallFont, textAlign: 'center', lineHeight: 40 }}
            >
              {labels[index]}
            </Text>
          </View>
        </View>
      );
    });
  };
  return (
    <View>
      {showArrow && !allArrows && (
        <View style={displayMode === 'digital' ? styles.arrowContainer : styles.arrowContainerPDF}>
          {displayMode === 'digital' ? (
            <Svg width={20} height={width / 5 + 10}>
              <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
              <Path d={`M10,5 L10,${width / 5 + 5}`} stroke={colors.prussianBlue} strokeWidth={2} />
              <Path
                d={`M10,${width / 5 + 10}
                    L5,${width / 5}
                    L15,${width / 5}
                    Z`}
                fill={colors.prussianBlue}
              />
            </Svg>
          ) : (
            <Svg width={40} height={width / 5}>
              <Path d={'M10,0 L0,20 L20,20 Z'} fill={colors.black} />
              <Path d={`M10,5 L10,${width / 5 - 5}`} stroke={colors.black} strokeWidth={4} />
              <Path
                d={`M10,${width / 5}
                    L0,${width / 5 - 20}
                    L20,${width / 5 - 20}
                    Z`}
                fill={colors.black}
              />
            </Svg>
          )}
        </View>
      )}
      <View style={[styles.imageWrapper]}>
        {shapeLabels()}
        <View style={{ position: 'absolute', zIndex: 1 }}>
          {centerLabel && (
            <Text variant="WRN700" style={{ fontSize: font, color: centerLabelColor }}>
              {centerLabel}
            </Text>
          )}
        </View>
        {showEqualSidesLines ? (
          <View>
            <AssetSvg name={pentagonSvgPath as SvgName} width={shapeWidth} height={shapeHeight} />
            <View
              style={[
                styles.line,
                styles.horizontalLine,
                {
                  left: displayMode === 'digital' ? 35 : 70,
                  top: displayMode === 'digital' ? 40 : 110,
                  transform: [{ rotate: '50deg' }]
                }
              ]}
            />
            <View
              style={[
                styles.line,
                styles.horizontalLine,
                {
                  right: displayMode === 'digital' ? 35 : 70,
                  top: displayMode === 'digital' ? 40 : 110,
                  transform: [{ rotate: '130deg' }]
                }
              ]}
            />
            <View
              style={[
                styles.line,
                styles.horizontalLine,
                {
                  right: 5,
                  top: displayMode === 'digital' ? 150 : 375,
                  transform: [{ rotate: '20deg' }]
                }
              ]}
            />
            <View
              style={[
                styles.line,
                styles.horizontalLine,
                {
                  left: 5,
                  top: displayMode === 'digital' ? 150 : 375,
                  transform: [{ rotate: '160deg' }]
                }
              ]}
            />
            <View
              style={[
                styles.line,
                styles.verticalLine,
                { bottom: displayMode === 'digital' ? -20 : -40 }
              ]}
            />
          </View>
        ) : (
          <AssetSvg name={pentagonSvgPath as SvgName} width={shapeWidth} height={shapeHeight} />
        )}
      </View>
    </View>
  );
};
const useStyles = (width: number, displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          alignItems: 'center',
          justifyContent: 'center',
          width: width
        },
        verticalLine: {
          height: '20%',
          width: displayMode === 'digital' ? 2 : 4,
          left: '50%'
        },
        horizontalLine: {
          width: '20%',
          height: displayMode === 'digital' ? 2 : 4,
          top: '50%'
        },
        line: {
          position: 'absolute',
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
        },
        arrowContainer: {
          height: width,
          minHeight: 50,
          alignItems: 'center',
          position: 'absolute',
          transform: [{ rotate: '54deg' }],
          bottom: 53,
          left: 0
        },
        arrowContainerPDF: {
          height: width,
          minHeight: 50,
          alignItems: 'center',
          position: 'absolute',
          transform: [{ rotate: '54deg' }],
          bottom: 110,
          left: -20
        }
      }),
    [width, displayMode]
  );
};
