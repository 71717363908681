import { newBlockContent } from '../../../Block';

import RecogniseAHalfOfAnObjectOrAShape from './1RecogniseAHalfOfAnObjectOrAShape';
import FindAHalfOfAnObjectOrAShape from './2FindAHalfOfAnObjectOrAShape';
import RecogniseAHalfOfAQuantity from './3RecogniseAHalfOfAQuantity';
import FindAHalfOfAQuantity from './4FindAHalfOfAQuantity';
import RecogniseAQuarterOfAnObjectOrAShape from './5RecogniseAQuarterOfAnObjectOrAShape';
import FindAQuarterOfAnObjectOrAShape from './6FindAQuarterOfAnObjectOrAShape';
import RecogniseAQuarterOfAQuantity from './7RecogniseAQuarterOfAQuantity';
import FindAQuarterOfAQuantity from './8FindAQuarterOfAQuantity';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    RecogniseAHalfOfAnObjectOrAShape, // Step 1
    FindAHalfOfAnObjectOrAShape, // Step 2
    RecogniseAHalfOfAQuantity, // Step 3
    FindAHalfOfAQuantity, // Step 4
    RecogniseAQuarterOfAnObjectOrAShape, // Step 5
    FindAQuarterOfAnObjectOrAShape, // Step 6
    RecogniseAQuarterOfAQuantity, // Step 7
    FindAQuarterOfAQuantity // Step 8
  ]
});
export default Block;
