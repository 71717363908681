import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from 'common/src/utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { ScientificNotation } from '../../../../utils/math';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQE',
  description: 'aQE',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Equivalent'],
  schema: z.object({
    givenHundredths: z.number().int().min(2).max(99),
    givenTenths: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const givenHundredths = randomIntegerInclusive(2, 99);

    const givenTenths = randomIntegerInclusive(2, 9);

    return { givenHundredths, givenTenths };
  },
  Component: props => {
    const {
      question: { givenHundredths, givenTenths },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeEquivalentFractions()}
        testCorrect={[[(givenHundredths * 10).toString()], [(givenTenths * 100).toString()]]}
        fractionContainerStyle={{ height: 96 }}
        pdfContainerStyle={{ rowGap: 64 }}
        sentences={[
          translate.answerSentences.wEqualsXSoYEqualsZ(
            `<frac n='${(1).toLocaleString()}' d='${(100).toLocaleString()}' />`,
            `<frac n='${(10).toLocaleString()}' d='${(1000).toLocaleString()}' />`,
            `<frac n='${givenHundredths.toLocaleString()}' d='${(100).toLocaleString()}' />`,
            `<frac nAns='' d='${(1000).toLocaleString()}' />`
          ),
          translate.answerSentences.wEqualsXSoYEqualsZ(
            `<frac n='${(1).toLocaleString()}' d='${(10).toLocaleString()}' />`,
            `<frac n='${(100).toLocaleString()}' d='${(1000).toLocaleString()}' />`,
            `<frac n='${givenTenths.toLocaleString()}' d='${(10).toLocaleString()}' />`,
            `<frac nAns='' d='${(1000).toLocaleString()}' />`
          )
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQF',
  description: 'aQF',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Base 10'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(6),
    hundreds: z.number().int().min(1).max(2)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 6);
    const hundreds = randomIntegerInclusive(1, 2);
    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;

    return (
      <MarkupAssets
        elements={{
          thousandsCube: <AssetSvg name={'Base_Ten/1000cube'} width={96} />
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.ifXEquals1WhatFracIsRepresented(
            `<asset name='thousandsCube'/>`
          )}
          testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
          sentence={`<frac nAns='' d='${(1000).toLocaleString()}'/>`}
          Content={({ dimens }) => (
            <BaseTenRepresentation
              b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          )}
          sentenceStyle={{ justifyContent: 'flex-end' }}
        />
      </MarkupAssets>
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQG',
  description: 'aQG',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Base 10'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(6),
    hundreds: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    // Weight this question to enforce one zero three-quarters of the time, two zeroes one-quarter of the time:
    const howManyZeroes = getRandomFromArray([1, 1, 1, 2]);

    const enforcedZeroes = getRandomSubArrayFromArray(['ones', 'tens', 'hundreds'], howManyZeroes);

    const ones = enforcedZeroes.includes('ones') ? 0 : randomIntegerInclusive(3, 9);
    const tens = enforcedZeroes.includes('tens') ? 0 : randomIntegerInclusive(1, 6);
    const hundreds = enforcedZeroes.includes('hundreds') ? 0 : randomIntegerInclusive(1, 2);

    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;

    return (
      <MarkupAssets
        elements={{
          thousandsCube: <AssetSvg name={'Base_Ten/1000cube'} width={96} />
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.ifXEquals1WhatFracIsRepresented(
            `<asset name='thousandsCube'/>`
          )}
          testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
          sentence={`<frac nAns='' d='${(1000).toLocaleString()}'/>`}
          Content={({ dimens }) => (
            <BaseTenRepresentation
              b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              scale={tens === 0 && hundreds === 0 ? 0.45 : undefined}
              align={tens === 0 && hundreds === 0 ? 'center' : undefined}
            />
          )}
          sentenceStyle={{ justifyContent: 'flex-end' }}
        />
      </MarkupAssets>
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQH',
  description: 'aQH',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Base 10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(1)
      .max(400)
      .refine(
        number => sumDigits(number) <= 22,
        'number must be representable with <=22 base ten counters'
      )
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 400, {
      constraint: number => sumDigits(number) <= 22
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <MarkupAssets
        elements={{
          thousandsCube: <AssetSvg name="Base_Ten/1000cube" width={96} />
        }}
      >
        <QF15CreateBaseTenNumber
          title={translate.instructions.ifXEquals1RepresentFracByDraggingBase10(
            `<asset name='thousandsCube'/>`,
            `<frac n='${number.toLocaleString()}' d='${(1000).toLocaleString()}' />`
          )}
          pdfTitle={translate.instructions.ifXEquals1RepresentFracUsingBase10(
            `<asset name='thousandsCube'/>`,
            `<frac n='${number.toLocaleString()}' d='${(1000).toLocaleString()}' />`
          )}
          number={ScientificNotation.fromNumber(number)}
          draggablesToShow={[2, 1, 0]}
          // Max capacity is 22
          counterSize="small"
          rows={2}
          questionHeight={1000}
        />
      </MarkupAssets>
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aQI',
  description: 'aQI',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Place value counters'],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(6),
      hundredths: z.number().int().min(0).max(6),
      thousandths: z.number().int().min(0).max(6)
    })
    .refine(
      val => val.tenths + val.hundredths + val.thousandths <= 12,
      'Only up to 12 counters can be chosen.'
    ),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(0, 6);

    const hundredths = randomIntegerInclusive(0, 6);

    const thousandths =
      tenths === 0 && hundredths === 0
        ? randomIntegerInclusive(1, 6)
        : randomIntegerInclusive(0, Math.min(12 - (tenths + hundredths), 6));

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: props => {
    const {
      question: { tenths, hundredths, thousandths },
      translate
    } = props;

    const totalCounters = tenths + hundredths + thousandths;

    const total = tenths * 100 + hundredths * 10 + thousandths;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueCountersToCompletePartition()}
        testCorrect={[tenths.toString(), hundredths.toString(), thousandths.toString()]}
        sentence={`<frac n='${total.toLocaleString()}' d='${(1000).toLocaleString()}'/> = <frac nAns='' d='${(10).toLocaleString()}'/> + <frac nAns='' d='${(100).toLocaleString()}'/> + <frac nAns='' d='${(1000).toLocaleString()}'/>`}
        fractionContainerStyle={{ height: 96 }}
        Content={({ dimens }) => {
          const dimensPerCounter = Math.min(dimens.height - 16, dimens.width / (totalCounters + 2));
          return (
            <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
              {tenths > 0 && (
                <View
                  style={{
                    width: (dimens.width / totalCounters) * tenths - 4,
                    borderWidth: 1,
                    borderColor: 'red',
                    borderRadius: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ArrayOfObjects
                    rows={1}
                    columns={tenths}
                    customImage={<AssetSvg name="Place_value/1-10" width={dimensPerCounter} />}
                    dimens={{
                      height: dimensPerCounter,
                      width: (dimens.width / totalCounters) * tenths - 4
                    }}
                    rowStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
              {hundredths > 0 && (
                <View
                  style={{
                    width: (dimens.width / totalCounters) * hundredths - 4,
                    borderWidth: 1,
                    borderColor: 'red',
                    borderRadius: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ArrayOfObjects
                    rows={1}
                    columns={hundredths}
                    customImage={<AssetSvg name="Place_value/1-100" width={dimensPerCounter} />}
                    dimens={{
                      height: dimensPerCounter,
                      width: (dimens.width / totalCounters) * hundredths - 4
                    }}
                    rowStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
              {thousandths > 0 && (
                <View
                  style={{
                    width: (dimens.width / totalCounters) * thousandths - 4,
                    borderWidth: 1,
                    borderColor: 'red',
                    borderRadius: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ArrayOfObjects
                    rows={1}
                    columns={thousandths}
                    customImage={<AssetSvg name="Place_value/1-1000" width={dimensPerCounter} />}
                    dimens={{
                      height: dimensPerCounter,
                      width: (dimens.width / totalCounters) * thousandths - 4
                    }}
                    rowStyle={{ justifyContent: 'space-around' }}
                  />
                </View>
              )}
            </View>
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQJ',
  description: 'aQJ',
  keywords: ['Fractions', 'Tenths', 'Hundredths', 'Thousandths', 'Place value counters'],
  schema: z
    .object({
      tenths: z.number().int().min(2).max(5),
      hundredths: z.number().int().min(2).max(5),
      thousandths: z.number().int().min(2).max(5),
      passTenthToHundredths: z.boolean(),
      passHundredthToThousandths: z.boolean()
    })
    .refine(
      val => val.tenths + val.hundredths + val.thousandths <= 12,
      'Only up to 12 counters can be chosen.'
    ),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(2, 5);

    const hundredths = randomIntegerInclusive(2, 5);

    const thousandths = randomIntegerInclusive(2, Math.min(12 - (tenths + hundredths), 5));

    // At least one of these must be true.
    const passTenthToHundredths = getRandomBoolean();

    const passHundredthToThousandths = passTenthToHundredths ? getRandomBoolean() : true;

    return {
      tenths,
      hundredths,
      thousandths,
      passTenthToHundredths,
      passHundredthToThousandths
    };
  },
  Component: props => {
    const {
      question: {
        tenths,
        hundredths,
        thousandths,
        passTenthToHundredths,
        passHundredthToThousandths
      },
      translate
    } = props;

    const totalCounters = tenths + hundredths + thousandths;

    const total = tenths * 100 + hundredths * 10 + thousandths;

    const tenthsValue = passTenthToHundredths ? tenths - 1 : tenths;

    const hundredthsValue = (() => {
      if (passTenthToHundredths && passHundredthToThousandths) {
        return hundredths + 9;
      } else if (passTenthToHundredths) {
        return hundredths + 10;
      } else if (passHundredthToThousandths) {
        return hundredths - 1;
      } else {
        return hundredths;
      }
    })();

    const thousandthsValue = passHundredthToThousandths ? thousandths + 10 : thousandths;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueCountersToCompletePartition()}
        testCorrect={[
          tenthsValue.toString(),
          hundredthsValue.toString(),
          thousandthsValue.toString()
        ]}
        sentence={`<frac n='${total.toLocaleString()}' d='${(1000).toLocaleString()}'/> = <frac nAns='' d='${(10).toLocaleString()}'/> + <frac nAns='' d='${(100).toLocaleString()}'/> + <frac nAns='' d='${(1000).toLocaleString()}'/>`}
        fractionContainerStyle={{ height: 96 }}
        Content={({ dimens }) => {
          const dimensPerCounter = Math.min(dimens.height - 16, dimens.width / (totalCounters + 2));

          const hudredthsViewWidth = (() => {
            if (passTenthToHundredths && passHundredthToThousandths) {
              return (dimens.width / totalCounters) * hundredths - 4;
            } else if (passTenthToHundredths || !passHundredthToThousandths) {
              (dimens.width / totalCounters) * (hundredths + 1) - 4;
            } else {
              (dimens.width / totalCounters) * (hundredths - 1) - 4;
            }
          })();

          return (
            <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
              <View
                style={{
                  width: passTenthToHundredths
                    ? (dimens.width / totalCounters) * (tenths - 1) - 4
                    : (dimens.width / totalCounters) * tenths - 4,
                  borderWidth: 1,
                  borderColor: 'red',
                  borderRadius: 12,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ArrayOfObjects
                  rows={1}
                  columns={passTenthToHundredths ? tenths - 1 : tenths}
                  customImage={<AssetSvg name="Place_value/1-10" width={dimensPerCounter} />}
                  dimens={{
                    height: dimensPerCounter,
                    width:
                      (dimens.width / totalCounters) * (passTenthToHundredths ? tenths : tenths) - 4
                  }}
                />
              </View>
              <View
                style={{
                  width: hudredthsViewWidth,
                  borderWidth: 1,
                  borderColor: 'red',
                  borderRadius: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}
              >
                {passTenthToHundredths && (
                  <AssetSvg name="Place_value/1-10" width={dimensPerCounter} />
                )}
                <ArrayOfObjects
                  rows={1}
                  columns={passHundredthToThousandths ? hundredths - 1 : hundredths}
                  customImage={<AssetSvg name="Place_value/1-100" width={dimensPerCounter} />}
                  dimens={{
                    height: dimensPerCounter,
                    width:
                      (dimens.width / totalCounters) *
                        (passHundredthToThousandths ? hundredths - 1 : hundredths) -
                      4
                  }}
                  rowStyle={{ justifyContent: passTenthToHundredths ? 'flex-end' : 'center' }}
                />
              </View>
              <View
                style={{
                  width: passHundredthToThousandths
                    ? (dimens.width / totalCounters) * (thousandths + 1) - 4
                    : (dimens.width / totalCounters) * thousandths - 4,
                  borderWidth: 1,
                  borderColor: 'red',
                  borderRadius: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}
              >
                {passHundredthToThousandths && (
                  <AssetSvg name="Place_value/1-100" width={dimensPerCounter} />
                )}
                <ArrayOfObjects
                  rows={1}
                  columns={thousandths}
                  customImage={<AssetSvg name="Place_value/1-1000" width={dimensPerCounter} />}
                  dimens={{
                    height: dimensPerCounter,
                    width: (dimens.width / totalCounters) * thousandths - 4
                  }}
                  rowStyle={{ justifyContent: passHundredthToThousandths ? 'flex-end' : 'center' }}
                />
              </View>
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ThousandthsAsFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
