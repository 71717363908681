import { useContext, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Dimens, ScaleFactorContext } from 'common/src/theme/scaling';
import { range } from 'common/src/utils/collections';
import { colors } from 'common/src/theme/colors';
import {
  Base10Object,
  ScientificNotation,
  base10ObjectToNumber,
  numberToBase10Object
} from 'common/src/utils/math';
import { SetState, transformSetState } from 'common/src/utils/react';
import { withStateHOC } from '../../../../stateTree';
import { DisplayMode } from '../../../../contexts/displayMode';
import AutoScaleText from '../../../typography/AutoScaleText';
import { Polygon, Svg } from 'react-native-svg';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

export const TouchableGattegnoChart = (props: {
  /** Elements of this array are powers of ten that the row at that index should represent */
  rowsToShow: number[];
  differentRowColors?: boolean;
  dimens: Dimens;
  correctAnswer?: number;
  userAnswer: number;
  setUserAnswer: SetState<number>;
  preshaded?: number[];
}) => {
  const {
    rowsToShow,
    differentRowColors = false,
    dimens,
    userAnswer,
    setUserAnswer,
    preshaded
  } = props;

  // Convert userAnswer/setUserAnswer to Base10Object type
  const userNumber = numberToBase10Object(userAnswer);
  const setUserNumber = useMemo(
    () => transformSetState(setUserAnswer, numberToBase10Object, base10ObjectToNumber),
    [setUserAnswer]
  );

  // Number of rows for Gattegno Chart
  const numberOfRows = rowsToShow.length;

  const sharedStyles = useSharedStyles(dimens, numberOfRows);

  const rowToArray = range(1, 9);

  const onPressHandler = (value: number, row: number) => {
    switch (row) {
      case -3:
        return value === userNumber.thousandths
          ? // As the value of the cell pressed is equal to the currently selected ones, this makes the selected ones now 0:
            setUserNumber(prevNumber => {
              return { ...prevNumber, thousandths: 0 };
            })
          : // Sets the currently selected ones to equal the value of the cell pressed:
            setUserNumber(prevNumber => {
              return { ...prevNumber, thousandths: value };
            });
      case -2:
        return value === userNumber.hundredths
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, hundredths: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, hundredths: value };
            });
      case -1:
        return value === userNumber.tenths
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, tenths: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, tenths: value };
            });
      case 0:
        return value === userNumber.ones
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, ones: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, ones: value };
            });
      case 1:
        return value === userNumber.tens
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, tens: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, tens: value };
            });
      case 2:
        return value === userNumber.hundreds
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, hundreds: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, hundreds: value };
            });
      case 3:
        return value === userNumber.thousands
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, thousands: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, thousands: value };
            });
      case 4:
        return value === userNumber.tenThousands
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, tenThousands: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, tenThousands: value };
            });
      case 5:
        return value === userNumber.hundredThousands
          ? setUserNumber(prevNumber => {
              return { ...prevNumber, hundredThousands: 0 };
            })
          : setUserNumber(prevNumber => {
              return { ...prevNumber, hundredThousands: value };
            });
      default:
        return;
    }
  };

  return (
    <View style={[dimens, sharedStyles.tableContainer]}>
      {/* Map each row we want to show: */}
      {rowsToShow.map((row, rowIndex) => (
        <View style={sharedStyles.tableRow} key={row}>
          {/* Grab and map each number we want to show in a row as individual cells: */}
          {rowToArray.map((cellNumber, cellIndex, rowArray) => {
            return (
              <TableCell
                key={cellNumber}
                value={cellNumber}
                row={row}
                lastRow={rowIndex === rowsToShow.length - 1}
                lastCell={cellIndex === rowArray.length - 1}
                onPressHandler={onPressHandler}
                userNumber={userNumber}
                differentRowColors={differentRowColors}
                dimens={dimens}
                numberOfRows={numberOfRows}
                preshaded={preshaded}
                userAnswer={userAnswer}
              />
            );
          })}
        </View>
      ))}
    </View>
  );
};

const TableCell = (props: {
  lastRow: boolean;
  lastCell: boolean;
  onPressHandler: (value: number, row: number) => void;
  row: number;
  value: number;
  userNumber: Base10Object;
  differentRowColors: boolean;
  dimens: Dimens;
  numberOfRows: number;
  preshaded?: number[];
  userAnswer: number;
}) => {
  const {
    lastRow,
    lastCell,
    onPressHandler,
    row,
    value,
    userNumber,
    differentRowColors,
    dimens,
    numberOfRows,
    preshaded,
    userAnswer
  } = props;
  const displayMode = useContext(DisplayMode);

  const sharedStyles = useSharedStyles(dimens, numberOfRows);

  const backgroundColorSwitch = (row: number, value: number) => {
    const defaultColor = displayMode === 'digital' ? colors.burntSienna : colors.pdfShading;
    const total = number(math.evaluate(`${value} * ${Math.pow(10, row)}`));
    if (preshaded?.includes(total)) return colors.pacificBlue;

    switch (row) {
      case -3:
        return value === userNumber.thousandths
          ? differentRowColors
            ? colors.thousands
            : defaultColor
          : 'transparent';
      case -2:
        return value === userNumber.hundredths
          ? differentRowColors
            ? colors.hundreds
            : defaultColor
          : 'transparent';
      case -1:
        return value === userNumber.tenths
          ? differentRowColors
            ? colors.tens
            : defaultColor
          : 'transparent';
      case 0:
        return value === userNumber.ones
          ? differentRowColors
            ? colors.ones
            : defaultColor
          : 'transparent';
      case 1:
        return value === userNumber.tens
          ? differentRowColors
            ? colors.tens
            : defaultColor
          : 'transparent';
      case 2:
        return value === userNumber.hundreds
          ? differentRowColors
            ? colors.hundreds
            : defaultColor
          : 'transparent';
      case 3:
        return value === userNumber.thousands
          ? differentRowColors
            ? colors.thousands
            : defaultColor
          : 'transparent';
      case 4:
        return value === userNumber.tenThousands
          ? differentRowColors
            ? colors.tenThousands
            : defaultColor
          : 'transparent';
      case 5:
        return value === userNumber.hundredThousands
          ? differentRowColors
            ? colors.hundredThousands
            : defaultColor
          : 'transparent';
      default:
        return 'lightgrey';
    }
  };

  const fontColorSwitch = (row: number, value: number) => {
    const defaultColor = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const total = number(math.evaluate(`${value} * ${Math.pow(10, row)}`));
    if (preshaded?.includes(total)) return colors.white;

    switch (row) {
      case -3:
        return value === userNumber.thousandths ? 'white' : defaultColor;
      case -2:
        return value === userNumber.hundredths ? 'white' : defaultColor;
      case -1:
        return value === userNumber.tenths ? 'white' : defaultColor;
      case 0:
        return value === userNumber.ones ? 'white' : defaultColor;
      case 1:
        return value === userNumber.tens ? 'white' : defaultColor;
      case 2:
        return value === userNumber.hundreds ? 'white' : defaultColor;
      case 3:
        return value === userNumber.thousands ? 'white' : defaultColor;
      case 4:
        return value === userNumber.tenThousands ? 'white' : defaultColor;
      case 5:
        return value === userNumber.hundredThousands ? 'white' : defaultColor;
      default:
        return 'lightgrey';
    }
  };

  return (
    <TouchableOpacity
      style={[
        { backgroundColor: backgroundColorSwitch(row, value) },
        sharedStyles.cell,
        lastCell && sharedStyles.lastCellOfRow,
        lastRow && sharedStyles.lastRow
      ]}
      onPress={() => onPressHandler(value, row)}
    >
      <AutoScaleText
        variant="WRN400"
        minFontSize={22}
        maxFontSize={displayMode === 'digital' ? 32 : 50}
        group="chart"
        containerStyle={{ width: dimens.width / 9, height: dimens.height / numberOfRows }}
        textStyle={[
          {
            color: differentRowColors ? colors.prussianBlue : fontColorSwitch(row, value),
            textAlign: 'center'
          },
          sharedStyles.selectedNumber
        ]}
        maxLines={1}
      >
        {(value * Math.pow(10, row)).toLocaleString()}
      </AutoScaleText>
      {preshaded &&
      preshaded?.includes(value * Math.pow(10, row)) &&
      ScientificNotation.fromNumber(userAnswer).digitAt(row) === value ? (
        <View style={{ position: 'absolute', zIndex: -1 }}>
          <Svg width={dimens.width / 9} height={dimens.height / numberOfRows}>
            <Polygon
              points={`0 0, ${dimens.width / 9} 0,${dimens.width / 9} ${
                dimens.height / numberOfRows
              }`}
              fill={colors.burntSienna}
            />
          </Svg>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

const useSharedStyles = (dimens: Dimens, numberOfRows: number) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const displayMode = useContext(DisplayMode);

  const borderWidth =
    displayMode === 'digital' ? Math.max(2, 2 / scaleFactor) : Math.max(4, 2 / scaleFactor);

  return useMemo(
    () =>
      StyleSheet.create({
        cell: {
          borderStartWidth: borderWidth,
          borderTopWidth: borderWidth,
          justifyContent: 'center',
          flex: 1,
          alignContent: 'center',
          alignItems: 'center',
          borderColor: displayMode === 'digital' ? colors.prussianBlue : 'black'
        },
        lastCellOfRow: {
          borderEndWidth: borderWidth
        },
        lastRow: {
          borderBottomWidth: borderWidth
        },
        selectedNumber: {
          justifyContent: 'center',
          alignContent: 'center',
          paddingVertical: 8
        },
        tableContainer: {
          flex: 1,
          // Ideal width to fill up the space available while just avoiding the header's shadow / numpad
          justifyContent: 'center',
          alignSelf: 'center'
        },
        tableRow: {
          flexDirection: 'row',
          height: dimens.height / numberOfRows
        }
      }),
    [borderWidth, dimens.height, displayMode, numberOfRows]
  );
};

/** See {@link TouchableGattegnoChart} */
export const TouchableGattegnoChartWithState = withStateHOC(TouchableGattegnoChart, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: 0,
    testComplete: answer => answer !== 0,
    testCorrect: answer => answer === props.correctAnswer
  })
});
