import { StyleSheet, View } from 'react-native';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { Dimens } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { useI18nContext } from '../../../i18n/i18n-react';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  character: 'Alex' | 'Annie' | 'Jack' | 'Mo';
  places: ('Trees' | 'Park' | 'School' | 'Pool')[];
  dimens: Dimens;
  startingPosition?: 0 | 90 | 180 | 270;
};

function getSvg(
  name: 'Trees' | 'Park' | 'School' | 'Pool' | 'Alex' | 'Annie' | 'Jack' | 'Mo'
): SvgName {
  switch (name) {
    case 'Trees': {
      return 'trees_birds_eye_view';
    }
    case 'Park': {
      return 'park_birds_eye_view';
    }
    case 'School': {
      return 'school_birds_eye_view';
    }
    case 'Pool': {
      return 'pool_birds_eye_view';
    }
    case 'Alex': {
      return 'Alex_birds_eye';
    }
    case 'Annie': {
      return 'Annie_birds_eye';
    }
    case 'Jack': {
      return 'Jack_birds_eye';
    }
    case 'Mo': {
      return 'Mo_birds_eye';
    }
  }
}

/**
 * This component renders a Birds Eye View manipulative.
 */
export function BirdsEyeView({ character, places, dimens, startingPosition = 0 }: Props) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  const { width, height } = dimens;

  const outerRowHeight = height * 0.35;
  const elementHeight = height * 0.2;
  const elementWidth = width * 0.2;

  const styles = getStyles(width, height, outerRowHeight, elementHeight, displayMode);

  const svgNames = places.map(val => getSvg(val));

  return (
    <View style={styles.container}>
      {/* Top row i.e. places[0] */}
      <View style={styles.outerRow}>
        <Text variant="WRN400" style={styles.labelText}>
          {translate.places[places[0]]()}
        </Text>
        <AssetSvg name={svgNames[0]} height={elementHeight} style={{ maxWidth: elementWidth }} />
      </View>
      {/* Middle row i.e. places[3], character and places[1] */}
      <View style={styles.middleRow}>
        <View style={[styles.textWrapper, { justifyContent: 'flex-end' }]}>
          <Text variant="WRN400" style={styles.labelText}>
            {translate.places[places[3]]()}
          </Text>
          <AssetSvg name={svgNames[3]} height={elementHeight} style={{ maxWidth: elementWidth }} />
        </View>
        <View
          style={{
            transform: `rotate(${startingPosition}deg)`
          }}
        >
          <AssetSvg
            name={getSvg(character)}
            height={elementHeight}
            style={{ maxWidth: elementWidth }}
          />
        </View>
        <View style={styles.textWrapper}>
          <AssetSvg name={svgNames[1]} height={elementHeight} style={{ maxWidth: elementWidth }} />
          <Text variant="WRN400" style={styles.labelText}>
            {translate.places[places[1]]()}
          </Text>
        </View>
      </View>
      <View style={styles.outerRow}>
        <AssetSvg name={svgNames[2]} height={elementHeight} style={{ maxWidth: elementWidth }} />
        <Text variant="WRN400" style={styles.labelText}>
          {translate.places[places[2]]()}
        </Text>
      </View>
    </View>
  );
}

const getStyles = (
  width: number,
  height: number,
  outerRowHeight: number,
  elementHeight: number,
  displayMode: 'pdf' | 'markscheme' | 'digital'
) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height,
      justifyContent: 'space-evenly'
    },
    outerRow: {
      width: width,
      height: outerRowHeight,
      alignItems: 'center',
      justifyContent: 'center'
    },
    middleRow: {
      width: width,
      height: elementHeight,
      flexDirection: 'row',
      justifyContent: 'center',
      columnGap: 40
    },
    textWrapper: { flexDirection: 'row', alignItems: 'center', gap: 20, width: width / 3 },
    labelText: { fontSize: displayMode === 'digital' ? 28 : 40, lineHeight: 40 }
  });
