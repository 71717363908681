import { z } from 'zod';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { all, create, number } from 'mathjs';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { findFactors } from '../../../../utils/factors';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { DIV } from '../../../../constants';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { numberEnum } from '../../../../utils/zod';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import { getImagesByAmount } from '../../../../utils/images';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF23aDraggableMultiRowPlaceValueChart from '../../../../components/question/questionFormats/QF23aDraggableMultiRowPlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKW',
  description: 'aKW',
  keywords: ['Division', 'Place value chart'],
  schema: z.object({
    hundreds: z.number().int().min(1).max(4),
    tens: z.number().int().min(0).max(4),
    ones: z.number().int().min(0).max(4),
    divisor: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);
    const hundreds = randomIntegerInclusive(1, 4, { constraint: x => x * divisor < 9 });
    const tens = randomIntegerInclusive(0, 4, { constraint: x => x * divisor < 10 });
    const ones = randomIntegerInclusive(0, 4, { constraint: x => x * divisor < 10 });

    return { hundreds, tens, ones, divisor };
  },

  Component: props => {
    const {
      question: { hundreds, tens, ones, divisor },
      translate
    } = props;

    const answer = number(math.evaluate(`${hundreds} * 100 + ${tens} * 10 + ${ones}`));
    const dividend = number(math.evaluate(`${divisor} * ${answer}`));

    return (
      <QF23aDraggableMultiRowPlaceValueChart
        title={translate.instructions.dragCountersToRepresentNum(
          `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToRepresentNum(
          `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        correctAnswerPerRow={ScientificNotation.fromNumber(answer)}
        columnsToShow={[2, 1, 0]}
        numOfRows={divisor}
        counterVariant="decimalCounter"
        headerVariant="name"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aKX',
  description: 'aKX',
  keywords: ['Division', 'Place value chart'],
  schema: z.object({
    hundreds: z.number().int().min(1).max(4),
    tens: z.number().int().min(0).max(4),
    ones: z.number().int().min(0).max(4),
    divisor: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);
    const hundreds = randomIntegerInclusive(1, 4, { constraint: x => x * divisor < 9 });
    const tens = randomIntegerInclusive(0, 4, { constraint: x => x * divisor < 10 });
    const ones = randomIntegerInclusive(0, 4, { constraint: x => x * divisor < 10 });

    return { hundreds, tens, ones, divisor };
  },

  Component: props => {
    const {
      question: { hundreds, tens, ones, divisor },
      translate,
      displayMode
    } = props;

    const answer = number(math.evaluate(`${hundreds} * 100 + ${tens} * 10 + ${ones}`));
    const dividend = number(math.evaluate(`${divisor} * ${answer}`));

    const hundredsImage = getImagesByAmount('Place_value/100', hundreds);
    const tensImage = getImagesByAmount('Place_value/10', tens);
    const onesImage = getImagesByAmount('Place_value/1', ones);

    // if tens or ones are zero the function breaks so set it to be large
    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(200, 50, hundredsImage, 8),
      tens > 0 ? calcRowOfImagesScaleFactor(200, 50, tensImage, 8) : 10000,
      ones > 0 ? calcRowOfImagesScaleFactor(200, 50, onesImage, 8) : 10000
    );

    // Create data array
    const data: (string | JSX.Element)[][] = [];
    for (let i = 0; i < divisor; i++) {
      data.push([
        hundreds > 0 ? (
          <RowOfImages
            containerStyle={{
              width: 200,
              height: 50,
              flexBasis: 'auto'
            }}
            style={{ gap: 8 }}
            images={hundredsImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        tens > 0 ? (
          <RowOfImages
            containerStyle={{
              width: 200,
              height: 50,
              flexBasis: 'auto'
            }}
            style={{ gap: 8 }}
            images={tensImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        ),
        ones > 0 ? (
          <RowOfImages
            containerStyle={{
              width: 200,
              height: 50,
              flexBasis: 'auto'
            }}
            style={{ gap: 8 }}
            images={onesImage}
            scaleFactor={scaleFactor}
          />
        ) : (
          ''
        )
      ]);
    }

    return (
      <QF1ContentAndSentence
        sentence={`${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans />`}
        sentenceStyle={{ justifyContent: 'center' }}
        title={translate.instructions.usePlaceValueChartToHelpCompleteDivision()}
        testCorrect={[answer.toString()]}
        Content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Hundreds(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[2].color
                },
                textStyle: {
                  color: placeValueColumnInfo[2].textColor
                }
              },
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[1].color
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[1].textColor
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[0].textColor
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKY',
  description: 'aKY',
  questionHeight: 900,
  keywords: ['Division', 'Part-whole model'],
  schema: z.object({
    number1: z.number().int().min(100).max(400).step(100),
    number2: z.number().int().min(0).max(90).step(10),
    number3: z.number().int().min(0).max(9),
    number4: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const number4 = randomIntegerInclusive(2, 5);
    const exchangeAt = getRandomFromArray(['ones', 'tens'] as const);
    const number2 = randomIntegerInclusiveStep(0, 90, 10, {
      constraint: x =>
        exchangeAt === 'tens' ? x * number4 >= 100 && x * number4 < 200 : x * number4 < 100
    });
    const number3 = randomIntegerInclusive(0, 9, {
      constraint: x =>
        exchangeAt === 'tens' ? x * number4 < 10 : x * number4 >= 10 && x * number4 < 20
    });
    const number1 = randomIntegerInclusiveStep(100, 400, 100, {
      constraint: x => x * number4 < 900
    });

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answer = number1 + number2 + number3;
    const number5 = number(math.evaluate(`${number4} * ${answer}`));
    const number6 = number(math.evaluate(`${number4} * ${number1}`));
    const number7 = number(math.evaluate(`${number4} * ${number2}`));
    const number8 = number(math.evaluate(`${number4} * ${number3}`));
    return (
      <QF1ContentAndSentence
        sentence={`${number5.toLocaleString()} ${DIV} ${number4.toLocaleString()} = <ans />`}
        sentenceStyle={{ justifyContent: 'center' }}
        title={translate.instructions.usePartWholeModelToWorkOut(
          `<g>${number5} ${DIV} ${number4}</g>`
        )}
        questionHeight={900}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <PartWholeModel
            top={number5}
            partition={[number6, number7, number8].filter(val => val !== 0)}
            dimens={dimens}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aKZ',
  description: 'aKZ',
  keywords: ['Division', 'Exchange'],
  schema: z.object({
    hundreds1: z.number().int().min(100).max(400).step(100),
    tens1: z.number().int().min(0).max(50).step(10),
    ones1: z.number().int().min(0).max(5),
    hundreds2: z.number().int().min(100).max(400).step(100),
    tens2: z.number().int().min(0).max(50).step(10),
    ones2: z.number().int().min(0).max(5),
    divisor1: z.number().int().min(2).max(5),
    divisor2: z.number().int().min(2).max(5)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const divisor1 = randomIntegerInclusive(2, 5);
    const tens1 = randomIntegerInclusiveStep(0, 50, 10, {
      constraint: x => x * divisor1 < 100
    });
    const ones1 = randomIntegerInclusive(0, 5, {
      constraint: x => x * divisor1 >= 10 && x * divisor1 < 20
    });
    const hundreds1 = randomIntegerInclusiveStep(100, 400, 100, {
      constraint: x => x * divisor1 < 900
    });

    const divisor2 = randomIntegerInclusive(2, 5);
    const hundreds2 = randomIntegerInclusiveStep(100, 400, 100, {
      constraint: x => x * divisor2 < 900
    });
    const tens2 = randomIntegerInclusiveStep(0, 50, 10, {
      constraint: x => x * divisor2 >= 100 && x * divisor2 < 200
    });
    const ones2 = randomIntegerInclusive(0, 5, {
      constraint: x => x * divisor2 < 10
    });

    return { divisor1, hundreds1, tens1, ones1, divisor2, hundreds2, tens2, ones2 };
  },
  Component: props => {
    const {
      question: { divisor1, hundreds1, tens1, ones1, divisor2, hundreds2, tens2, ones2 },
      translate
    } = props;

    const answerA = hundreds1 + tens1 + ones1;
    const dividendA = number(math.evaluate(`${divisor1} * ${answerA}`));
    const answerB = hundreds2 + tens2 + ones2;
    const dividendB = number(math.evaluate(`${divisor2} * ${answerB}`));

    const eqA = getBinOpEquation({
      left: dividendA,
      right: divisor1,
      sign: 'divide',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: dividendB,
      right: divisor2,
      sign: 'divide',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aK0',
  description: 'aK0',
  keywords: ['Division', 'Remainder'],
  schema: z.object({
    number1A: z.number().int().min(101).max(499),
    number1B: z.number().int().min(101).max(499),
    number1C: z.number().int().min(101).max(499),
    number1D: z.number().int().min(101).max(499),
    number2A: numberEnum([2, 3, 4, 5, 10]),
    number2B: numberEnum([2, 3, 4, 5, 10]),
    number2C: numberEnum([2, 3, 4, 5, 10]),
    number2D: numberEnum([2, 3, 4, 5, 10])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number2A = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const number2B = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const number2C = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const number2D = getRandomFromArray([2, 3, 4, 5, 10] as const);

    const number1A = randomIntegerInclusive(101, 499, { constraint: x => x % number2A === 0 });
    const number1B = randomIntegerInclusive(101, 499, { constraint: x => x % number2B === 0 });
    const number1C = randomIntegerInclusive(101, 499, { constraint: x => x % number2C !== 0 });
    const number1D = randomIntegerInclusive(101, 499, { constraint: x => x % number2D !== 0 });

    return { number1A, number1B, number1C, number1D, number2A, number2B, number2C, number2D };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D, number2A, number2B, number2C, number2D },
      translate
    } = props;

    const itemA = `${number1A.toLocaleString()} ${DIV} ${number2A.toLocaleString()}`;
    const itemB = `${number1B.toLocaleString()} ${DIV} ${number2B.toLocaleString()}`;
    const itemC = `${number1C.toLocaleString()} ${DIV} ${number2C.toLocaleString()}`;
    const itemD = `${number1D.toLocaleString()} ${DIV} ${number2D.toLocaleString()}`;

    const items = shuffle([itemA, itemB, itemC, itemD], { random: seededRandom(props.question) });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        zoneNames={[translate.tableHeaders.noRemainder(), translate.tableHeaders.remainder()]}
        testCorrect={[
          [itemA, itemB],
          [itemC, itemD]
        ]}
        items={items}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aK1',
  description: 'aK1',
  keywords: ['Division', 'Part-whole model', 'Remainder'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(4),
      hundreds: z.number().int().min(100).max(600).step(100),
      tens: z.number().int().min(10).max(90).step(10),
      ones: z.number().int().min(1).max(9)
    })
    .refine(
      val =>
        findFactors(val.hundreds).includes(val.divisor) &&
        findFactors(val.tens).includes(val.divisor) &&
        !findFactors(val.ones).includes(val.divisor),
      'divisor must be a factor of hundreds and tens but not of ones'
    ),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);
    const hundreds = randomIntegerInclusiveStep(100, 600, 100, {
      constraint: x => findFactors(x).includes(divisor)
    });
    const tens = randomIntegerInclusiveStep(10, 90, 10, {
      constraint: x => findFactors(x).includes(divisor)
    });
    const ones = randomIntegerInclusive(1, 9, {
      constraint: x => !findFactors(x).includes(divisor)
    });
    return {
      divisor,
      hundreds,
      tens,
      ones
    };
  },
  Component: props => {
    const {
      question: { divisor, hundreds, tens, ones },
      translate
    } = props;
    const number5 = hundreds + tens + ones;
    const answers = [
      Math.floor(number(math.evaluate(`${number5} / ${divisor}`))).toString(),
      (number5 % divisor).toString()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToWorkOut(
          `<g>${number5} ${DIV} ${divisor}</g>`
        )}
        testCorrect={answers}
        sentence={translate.answerSentences.calcEqualsAnsRemainderAns(
          `${number5.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        sentenceStyle={{ justifyContent: 'center' }}
        Content={({ dimens }) => (
          <PartWholeModel top={number5} partition={[hundreds, tens, ones]} dimens={dimens} />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA3DigitNumberByA1DigitNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
