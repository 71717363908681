import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates, countRange, NonEmptyArray } from '../../../../utils/collections';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const shapes = [
  'Heart',
  'Face',
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Trapezium',
  'Rectangle'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Heart: [
    'SymmetricalShapes/vertical1_pink',
    'SymmetricalShapes/vertical1_purple',
    'SymmetricalShapes/vertical1_green',
    'SymmetricalShapes/vertical1_yellow'
  ],
  Face: [
    'SymmetricalShapes/vertical2_pink',
    'SymmetricalShapes/vertical2_purple',
    'SymmetricalShapes/vertical2_green',
    'SymmetricalShapes/vertical2_yellow'
  ],
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Trapezium: [
    'Trapezium/trapezium_isosceles_green',
    'Trapezium/trapezium_isosceles_purple',
    'Trapezium/trapezium_isosceles_pink',
    'Trapezium/trapezium_isosceles_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_yellow'
  ]
};

const turnToWords: Record<
  -90 | -270 | 0 | 90 | 180 | 270,
  | 'fullTurn'
  | 'halfTurn'
  | 'quarterTurnClockwise'
  | 'threeQuarterTurnClockwise'
  | 'quarterTurnAntiClockwise'
  | 'threeQuarterTurnAntiClockwise'
> = {
  '-90': 'quarterTurnAntiClockwise',
  '-270': 'threeQuarterTurnAntiClockwise',
  0: 'fullTurn',
  90: 'quarterTurnClockwise',
  180: 'halfTurn',
  270: 'threeQuarterTurnClockwise'
};

const turnToDistance: Record<
  -90 | -270 | 0 | 90 | 180 | 270,
  'full' | 'half' | 'quarter' | 'threeQuarter'
> = {
  '-90': 'quarter',
  '-270': 'threeQuarter',
  0: 'full',
  90: 'quarter',
  180: 'half',
  270: 'threeQuarter'
};

const Question1 = newQuestionContent({
  uid: 'blD',
  description: 'blD',
  keywords: [
    'Half turn',
    'Quarter turn',
    'Full turn',
    'Three-quarter turn',
    'Clockwise',
    'Anti-clockwise'
  ],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium']),
    colorIndexes: z.array(z.number().int().min(0).max(3)).length(3),
    startingRotations: numberEnum([0, 90, 180, 270]).array().length(3),
    rotations: numberEnum([-270, -90, 0, 90, 180, 270]).array().length(3),
    options: z
      .enum([
        'fullTurn',
        'halfTurn',
        'quarterTurnClockwise',
        'threeQuarterTurnAntiClockwise',
        'quarterTurnAntiClockwise',
        'threeQuarterTurnClockwise'
      ])
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium'
    ] as const);
    const colorIndexes = randomUniqueIntegersInclusive(0, 3, 3);
    const startingRotations = countRange(3).map(() =>
      getRandomFromArray([0, 90, 180, 270] as const)
    );

    const rotations = rejectionSample(
      () => getRandomSubArrayFromArray([-90, -270, 0, 90, 180, 270] as const, 3),
      val => arrayHasNoDuplicates(val.map(val => (val < 0 ? 360 + val : val)))
    );

    const correctOptions = rotations.map(val => turnToWords[val]);
    const array = ([-90, -270, 0, 90, 180, 270] as const).filter(
      val =>
        !rotations.includes(val) &&
        !rotations.includes((val < 0 ? 360 + val : val - 360) as 0 | 90 | -90 | 270 | -270 | 180)
    ) as NonEmptyArray<-90 | -270 | 0 | 90 | 180 | 270>;

    const incorrectOption = getRandomFromArray(array);

    const options = [...correctOptions, turnToWords[incorrectOption!]];

    return {
      shape,
      colorIndexes,
      startingRotations,
      rotations,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndexes, startingRotations, rotations, options },
      translate,
      displayMode
    } = props;

    const dimens = {
      width: displayMode === 'digital' ? 300 : 400,
      height: displayMode === 'digital' ? 150 : 200
    };

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheShapesToTheTurns()}
        pdfTitle={translate.ks1PDFInstructions.matchTheShapesToTheTurn()}
        items={options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: 32, lineHeight: 38, textAlign: 'center' }}>
              {translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        statementStyle={{ justifyContent: 'center' }}
        statements={rotations.map((angle, i) => ({
          lhsComponent: (
            <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View
                style={{
                  transform: `rotate(${startingRotations[i]}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg
                  name={svgNames[shape][colorIndexes[i]]}
                  height={dimens.height * 0.9}
                  width={dimens.height * 0.9}
                />
              </View>
              <View
                style={{
                  transform: `rotate(${startingRotations[i] + angle}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg
                  name={svgNames[shape][colorIndexes[i]]}
                  height={dimens.height * 0.9}
                  width={dimens.height * 0.9}
                />
              </View>
            </View>
          ),
          correctAnswer: turnToWords[angle]
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
        useArrows={false}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'blE',
  description: 'blE',
  keywords: [
    'Half turn',
    'Quarter turn',
    'Full turn',
    'Three-quarter turn',
    'Clockwise',
    'Anti-clockwise'
  ],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium']),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    options: numberEnum([-270, -90, 0, 90, 180, 270]).array(),
    turn: numberEnum([-270, -90, 0, 90, 180, 270])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArray([0, 90, 180, 270] as const);

    const turn = getRandomFromArray([-270, -90, 0, 90, 180, 270] as const);

    const array = ([-90, -270, 0, 90, 180, 270] as const).filter(
      val =>
        turn !== val &&
        turn !== ((val < 0 ? 360 + val : val - 360) as 0 | 90 | -90 | 270 | -270 | 180)
    ) as NonEmptyArray<-90 | -270 | 0 | 90 | 180 | 270>;

    const options = [90, 270].includes(Math.abs(turn))
      ? ([0, 180, turn] as const)
      : ([turn, ...getRandomSubArrayFromArray(array, 2)] as const);

    return {
      shape,
      colorIndex,
      startingRotation,
      turn,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, turn, options },
      translate,
      displayMode
    } = props;

    const turnWords = translate.ks1MiscStrings.directions[turnToDistance[turn]]();

    const [title, pdfTitle] = (() => {
      switch (turn) {
        case -90:
        case -270:
          return [
            translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAXTurnAntiClockwise(
              turnWords
            ),
            translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAXTurnAntiClockwise(
              turnWords
            )
          ];
        case 0:
        case 180:
          return [
            translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAXTurn(turnWords),
            translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAXTurn(turnWords)
          ];
        case 90:
        case 270:
          return [
            translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAXTurnClockwise(turnWords),
            translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAXTurnClockwise(turnWords)
          ];
      }
    })();

    const dimens =
      displayMode === 'digital' ? { height: 200, width: 300 } : { height: 400, width: 600 };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={title}
        pdfTitle={pdfTitle}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height * 0.75, justifyContent: 'center' }}>
            <View style={{ transform: `rotate(${startingRotation}deg)` }}>
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.4}
                width={dimens.height * 0.4}
              />
            </View>
          </View>
        )}
        itemLayout="row"
        numItems={[90, 270].includes(turn) ? 2 : 3}
        itemStyle={dimens}
        renderItems={options.map((val, i) => ({
          value: val + startingRotation,
          component: (
            <View
              key={i}
              style={{
                transform: `rotate(${startingRotation + val}deg)`,
                height: dimens.height * 0.6,
                width: dimens.height * 0.6
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
          )
        }))}
        testCorrect={[turn + startingRotation]}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'blF',
  description: 'blF',
  keywords: [
    'Half turn',
    'Quarter turn',
    'Full turn',
    'Three-quarter turn',
    'Clockwise',
    'Anti-clockwise'
  ],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium', 'Rectangle']),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    rotation: numberEnum([-90, -270, 0, 90, 180, 270]),
    options: z
      .enum(['full', 'half', 'quarter', 'threeQuarter'])
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Rectangle'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArrayWithWeights(
      [shape === 'Arrow' ? 270 : 0, shape === 'Arrow' ? 0 : 90, 180, 270] as const,
      [3, 1, 1, 1]
    );
    const rotation = getRandomFromArray([-270, -90, 0, 90, 180, 270] as const);

    return {
      shape,
      colorIndex,
      startingRotation,
      rotation,
      options: shuffle(['full', 'half', 'quarter', 'threeQuarter'] as const)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, rotation, options },
      translate
    } = props;

    const sentence = (() => {
      switch (rotation) {
        case -90:
        case -270:
          return translate.ks1AnswerSentences.theShapeHasTurnedAAnsTurnAntiClockwise();
        case 0:
        case 180:
          return translate.ks1AnswerSentences.theShapeHasTurnedAAnsTurn();
        case 90:
        case 270:
          return translate.ks1AnswerSentences.theShapeHasTurnedAAnsTurnClockwise();
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: 40 }}>
              {translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <View
              style={{
                transform: `rotate(${startingRotation}deg)`,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
            <View
              style={{
                transform: `rotate(${startingRotation + rotation}deg)`,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        sentence={sentence}
        testCorrect={userAnswer =>
          userAnswer[0] !== undefined &&
          (shape === 'Rectangle'
            ? [180, 0].includes(rotation)
              ? ['full', 'half'].includes(userAnswer[0])
              : ['quarter', 'threeQuarter'].includes(userAnswer[0])
            : turnToDistance[rotation] === userAnswer[0])
        }
        pdfLayout="itemsTop"
        customMarkSchemeAnswer={{
          answersToDisplay: [[turnToDistance[rotation]]]
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribeTurns',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
