import { View, StyleSheet } from 'react-native';
import { countRange } from '../../../utils/collections';
import { AssetSvg } from '../../../assets/svg';
import { Dimens } from '../../../theme/scaling';

type CubeTowerProps = {
  /**
   * Number of cubes in the towers.
   */
  count: number;
  dimens: Dimens;
  color: 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'orange';
};

/**
 * Representation of cube tower.
 *
 * It accepts a color and a count as its props and returns unlabeled cubes stacked onto top of each other touching .
 */
export const CubeTower = ({ count, dimens, color }: CubeTowerProps) => {
  const { width: containerWidth, height: containerHeight } = dimens;

  // Calculate cube size based on the count
  const cubeSize = Math.min(containerWidth * 0.8, (containerHeight / count) * 1.2);

  return (
    <View
      style={[
        styles.container,
        {
          width: containerWidth,
          height: count === 1 ? containerHeight * 1.2 : containerHeight
        }
      ]}
    >
      {countRange(count).map((_, index) => (
        <View
          style={{
            position: 'absolute',
            bottom: index * cubeSize * 0.68,
            width: cubeSize,
            height: cubeSize
          }}
          key={index}
        >
          <AssetSvg
            name={`Cubes_blank/Coloured_cube_unlabelled_${color}`}
            width={cubeSize}
            height={cubeSize}
          />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center'
  }
});
