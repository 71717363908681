import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { numberEnum } from 'common/src/utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { View } from 'react-native';
import TextStructure from 'common/src/components/molecules/TextStructure';
import Text from 'common/src/components/typography/Text';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from 'common/src/components/molecules/ContentBox';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { arrayHasNoDuplicates, filledArray } from 'common/src/utils/collections';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { BarModelCurlyBrace } from 'common/src/components/question/representations/BarModelCurlyBrace';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from 'common/src/components/question/representations/Number Line/NumberLine';
import { useContext } from 'react';
import { DisplayMode } from 'common/src/contexts/displayMode';
import { compareFloats } from 'common/src/utils/math';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWa',
  description: 'aWa',
  keywords: ['Equivalent', 'Fraction', 'Percentage'],
  schema: z.object({
    numbers: z.array(numberEnum([2, 4, 5, 10, 20, 25, 50, 100])).length(3),
    percentagesAreDraggable: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = getRandomSubArrayFromArray([2, 4, 5, 10, 20, 25, 50, 100] as const, 3);
    const percentagesAreDraggable = getRandomBoolean();

    return { numbers, percentagesAreDraggable };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { numbers, percentagesAreDraggable },
      translate,
      displayMode
    } = props;

    const translatedOne = (1).toLocaleString();
    const [number1, number2, number3] = numbers;

    const percentageA = number(math.evaluate(`(1 / ${number1}) * 100`));
    const percentageB = number(math.evaluate(`(1 / ${number2}) * 100`));
    const percentageC = number(math.evaluate(`(1 / ${number3}) * 100`));

    const statements = shuffle(
      [
        {
          sentence: percentagesAreDraggable
            ? `<frac n='${translatedOne}' d='${number1.toLocaleString()}'/> = <ans/>`
            : `${translate.answerSentences.numPercent(percentageA)} = <ans/>`,
          answer: number1
        },
        {
          sentence: percentagesAreDraggable
            ? `<frac n='${translatedOne}' d='${number2.toLocaleString()}'/> = <ans/>`
            : `${translate.answerSentences.numPercent(percentageB)} = <ans/>`,
          answer: number2
        },
        {
          sentence: percentagesAreDraggable
            ? `<frac n='${translatedOne}' d='${number3.toLocaleString()}'/> = <ans/>`
            : `${translate.answerSentences.numPercent(percentageC)} = <ans/>`,
          answer: number3
        }
      ],
      { random: seededRandom(props.question) }
    );

    const items = shuffle(
      [
        {
          text: percentagesAreDraggable
            ? translate.answerSentences.numPercent(percentageA)
            : number1,
          value: number1
        },
        {
          text: percentagesAreDraggable
            ? translate.answerSentences.numPercent(percentageB)
            : number2,
          value: number2
        },
        {
          text: percentagesAreDraggable
            ? translate.answerSentences.numPercent(percentageC)
            : number3,
          value: number3
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={
          percentagesAreDraggable
            ? translate.instructions.dragCardsToMatchXToY(
                translate.keywords.Percentages(),
                translate.keywords['Equivalent fractions']()
              )
            : translate.instructions.dragCardsToMatchXToY(
                translate.keywords['Equivalent fractions'](),
                translate.keywords.Percentages()
              )
        }
        pdfTitle={
          percentagesAreDraggable
            ? translate.instructions.useCardsToMatchXToY(
                translate.keywords.Percentages(),
                translate.keywords['Equivalent fractions']()
              )
            : translate.instructions.useCardsToMatchXToY(
                translate.keywords['Equivalent fractions'](),
                translate.keywords.Percentages()
              )
        }
        items={items.map(({ text, value }) => {
          return {
            component: (
              <TextStructure
                sentence={
                  percentagesAreDraggable
                    ? text.toLocaleString()
                    : `<frac n='${translatedOne}' d='${text.toLocaleString()}'/>`
                }
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 28 : 50,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
                textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50, fontWeight: '700' }}
              />
            ),
            value
          };
        })}
        sentences={statements.map(({ sentence }) => sentence)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={statements.map(({ answer }) => [answer])}
        actionPanelVariant="end"
        questionHeight={800}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWb',
  description: 'aWb',
  keywords: ['Percentage', 'Amount', 'Bar model', 'Parts', 'Whole'],
  schema: z.object({
    numbersParts: z.array(numberEnum([2, 4, 5, 10])).length(2),
    total: z.number().int().min(20).max(400).multipleOf(20)
  }),
  simpleGenerator: () => {
    const numbersParts = getRandomSubArrayFromArray([2, 4, 5, 10] as const, 2);
    const total = randomIntegerInclusiveStep(20, 400, 20);

    return { numbersParts, total };
  },
  Component: props => {
    const {
      question: { numbersParts, total },
      translate
    } = props;

    const [number1Parts, number2Parts] = numbersParts;

    // Bar model 1
    const barModel1Numbers = filledArray(1, number1Parts);
    const barModel1Strings = filledArray('', number1Parts);
    const barModel1CellColors = filledArray('', number1Parts);

    // Bar model 2
    const barModel2Numbers = filledArray(1, number2Parts);
    const barModel2Strings = filledArray('', number2Parts);
    const barModel2CellColors = filledArray('', number2Parts);

    // Draggables
    const draggablePercentA = number(math.evaluate(`(1 / ${number1Parts}) * 100`));
    const draggablePercentB = number(math.evaluate(`(1 / ${number2Parts}) * 100`));

    const statements = [
      {
        lhsComponent: (
          <MeasureView>
            {dimens => (
              <View>
                <BarModel
                  total={number1Parts}
                  numbers={[barModel1Numbers]}
                  strings={[barModel1Strings]}
                  cellColors={[barModel1CellColors]}
                  dimens={{ width: dimens.width, height: dimens.height }}
                  rowHeight={50}
                  topBraceText={total.toLocaleString()}
                />

                <View
                  style={{
                    width: (dimens.width - 35) / number1Parts
                  }}
                >
                  <BarModelCurlyBrace topOrBottomBrace="bottom" braceText={'?'} />
                </View>
              </View>
            )}
          </MeasureView>
        ),
        correctAnswer: translate.answerSentences.xPercentOfY(draggablePercentA, total)
      },
      {
        lhsComponent: (
          <MeasureView>
            {dimens => (
              <View>
                <BarModel
                  total={number2Parts}
                  numbers={[barModel2Numbers]}
                  strings={[barModel2Strings]}
                  cellColors={[barModel2CellColors]}
                  dimens={{ width: dimens.width, height: dimens.height }}
                  rowHeight={50}
                  topBraceText={total.toLocaleString()}
                />

                <View
                  style={{
                    width: (dimens.width - 35) / number2Parts
                  }}
                >
                  <BarModelCurlyBrace topOrBottomBrace="bottom" braceText={'?'} />
                </View>
              </View>
            )}
          </MeasureView>
        ),
        correctAnswer: translate.answerSentences.xPercentOfY(draggablePercentB, total)
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = shuffle(
      [
        translate.answerSentences.xPercentOfY(draggablePercentA, total),
        translate.answerSentences.xPercentOfY(draggablePercentB, total)
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchStatementToBarModels()}
        pdfTitle={translate.instructions.useTheCardsToMatchStatementToBarModels()}
        items={items}
        statements={shuffledStatements}
        statementStyle={{ marginBottom: 80 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWc',
  description: 'aWc',
  keywords: ['Percentage', 'Amount', 'Bar model', 'Parts', 'Whole', 'Division'],
  schema: z.object({
    number1Parts: numberEnum([2, 4, 5, 10]),
    percentage: z.number().int().min(0).max(200)
  }),
  simpleGenerator: () => {
    const number1Parts = getRandomFromArray([2, 4, 5, 10] as const);
    const percentage = randomIntegerInclusive(0, 200);

    return { number1Parts, percentage };
  },
  Component: props => {
    const {
      question: { number1Parts, percentage },
      translate
    } = props;

    const displayMode = useContext(DisplayMode);

    const barModelNumbers = filledArray(1, number1Parts);
    const barModelStrings = filledArray('', number1Parts);
    const barModelCellColors = filledArray('', number1Parts);

    const tickValues =
      displayMode === 'digital'
        ? [
            translate.answerSentences.numPercent(0),
            translate.answerSentences.numPercent(10),
            translate.answerSentences.numPercent(20),
            translate.answerSentences.numPercent(30),
            translate.answerSentences.numPercent(40),
            translate.answerSentences.numPercent(50),
            translate.answerSentences.numPercent(60),
            translate.answerSentences.numPercent(70),
            translate.answerSentences.numPercent(80),
            translate.answerSentences.numPercent(90),
            translate.answerSentences.numPercent(100)
          ]
        : [
            (0).toLocaleString(),
            (10).toLocaleString(),
            (20).toLocaleString(),
            (30).toLocaleString(),
            (40).toLocaleString(),
            (50).toLocaleString(),
            (60).toLocaleString(),
            (70).toLocaleString(),
            (80).toLocaleString(),
            (90).toLocaleString(),
            (100).toLocaleString()
          ];

    const percent = number(math.evaluate(`(1 / ${number1Parts}) * 100`));
    const total = percentage * number1Parts;

    return (
      <QF1ContentAndSentence
        sentence={`${translate.answerSentences.xPercentOfY(percent, total)} = <ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ flex: 0.5, justifyContent: 'center' }}
        title={translate.instructions.useBarModelToCompleteCalc()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], percentage)}
        customMarkSchemeAnswer={{
          answersToDisplay: [percentage.toLocaleString()]
        }}
        Content={({ dimens }) => {
          return (
            <View>
              <View style={{ paddingLeft: 38 }}>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    width: (dimens.width - 103) / number1Parts
                  }}
                >
                  <BarModelCurlyBrace topOrBottomBrace="top" braceText={'?'} />
                </View>
                <BarModel
                  total={number1Parts}
                  numbers={[barModelNumbers]}
                  strings={[barModelStrings]}
                  cellColors={[barModelCellColors]}
                  dimens={{ width: dimens.width - 68, height: dimens.height }}
                  rowHeight={50}
                />
              </View>
              <View style={{ position: 'relative' }}>
                <NumberLine
                  tickValues={tickValues}
                  dimens={{
                    width: displayMode === 'digital' ? dimens.width - 33 : dimens.width + 66,
                    height: 120
                  }}
                  customFontSize={24}
                />
                {displayMode !== 'digital' && (
                  <View style={{ position: 'absolute', right: 60, top: 50 }}>
                    <Text variant="WRN400" style={{ fontSize: 32 }}>
                      ({translate.answerSentences.percent()})
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  alignSelf: displayMode === 'digital' ? 'center' : 'flex-start',
                  width: displayMode === 'digital' ? dimens.width - 110 : dimens.width - 75,
                  paddingLeft: displayMode === 'digital' ? 0 : 34,
                  paddingTop: displayMode === 'digital' ? 0 : 40
                }}
              >
                <BarModelCurlyBrace topOrBottomBrace="bottom" braceText={total.toLocaleString()} />
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aWd',
  description: 'aWd',
  keywords: ['Percentage', 'Amount', 'Division'],
  schema: z.object({
    number1: z.number().int().min(100).max(9900).multipleOf(100),
    moreNumbers: z.array(numberEnum([1, 10, 20, 25, 50, 100])).length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 9900, 100);
    const moreNumbers = getRandomSubArrayFromArray([1, 10, 20, 25, 50, 100] as const, 4);

    return {
      number1,
      moreNumbers
    };
  },

  Component: props => {
    const {
      question: { number1, moreNumbers },
      translate
    } = props;

    const displayMode = useContext(DisplayMode);

    const [number2, number3, number4, number5] = moreNumbers;

    // Items
    const item1 = number(math.evaluate(`(${number1} * ${number2}) / 100`));
    const item2 = number(math.evaluate(`(${number1} * ${number3}) / 100`));
    const item3 = number(math.evaluate(`(${number1} * ${number4}) / 100`));
    const item4 = number(math.evaluate(`(${number1} * ${number5}) / 100`));

    const statements = shuffle(
      [
        {
          component: item1,
          value: item1
        },
        {
          component: item2,
          value: item2
        },
        {
          component: item3,
          value: item3
        },
        {
          component: item4,
          value: item4
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutThePercentage()}
        testCorrect={[item1]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                width: dimens.width / 3,
                height: 100,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">
                {translate.answerSentences.xPercentOfY(number2, number1)}
              </Text>
            </ContentBox>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode === 'digital'
                ? translate.answerSentences.selectTheCorrectAnswer()
                : translate.answerSentences.circleTheCorrectAnswer()}
            </Text>
          </View>
        )}
        renderItems={statements.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWe',
  description: 'aWe',
  keywords: ['Percentage', 'Amount', 'Division'],
  schema: z.object({
    number1: z.number().int().min(100).max(9900).multipleOf(100),
    moreNumbers: z.array(numberEnum([1, 10, 20, 25, 50, 100])).length(3),
    random: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 9900, 100);
    const moreNumbers = getRandomSubArrayFromArray([1, 10, 20, 25, 50, 100] as const, 3);
    const random = getRandomBoolean();

    return { number1, moreNumbers, random };
  },

  Component: props => {
    const {
      question: { number1, moreNumbers, random },
      translate
    } = props;

    const [number2, number3, number4] = moreNumbers;

    // Answers
    const ans1 = number(math.evaluate(`(${number1} * ${number2}) / 100`));
    const ans2 = number(math.evaluate(`(${number1} * ${number3}) / 100`));
    const ans3 = number(math.evaluate(`(${number1} * ${number4}) / 100`));

    // Sentences
    const sentences = [
      random
        ? translate.answerSentences.xPercentOfYAns(number2, number1)
        : translate.answerSentences.ansEqualsXPercentOfY(number2, number1),
      random
        ? translate.answerSentences.xPercentOfYAns(number3, number1)
        : translate.answerSentences.ansEqualsXPercentOfY(number3, number1),
      random
        ? translate.answerSentences.xPercentOfYAns(number4, number1)
        : translate.answerSentences.ansEqualsXPercentOfY(number4, number1)
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ]
        }}
        sentences={sentences}
        sentenceStyle={{ alignSelf: random ? 'flex-end' : 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWf',
  description: 'aWf',
  keywords: ['Percentage', 'Amount', 'Division'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10).max(9900).multipleOf(10)).length(4),
    moreNumbers: z.array(numberEnum([1, 10, 20, 25, 50, 100])).length(4),
    incorrectNumbers: z.array(numberEnum([1, 10, 20, 25, 50, 100])).length(2),
    products: z.array(z.number().int())
  }),
  simpleGenerator: () => {
    const { numbers, moreNumbers, incorrectNumbers, products } = rejectionSample(
      () => {
        const numbers = randomUniqueIntegersInclusiveStep(10, 9900, 10, 4);
        const moreNumbers = getRandomSubArrayFromArray([1, 10, 20, 25, 50, 100] as const, 4);
        const incorrectNumbers = getRandomSubArrayFromArray([1, 10, 20, 25, 50, 100] as const, 2);

        const [number1A, number1B, number1C, number1D] = numbers;
        const [number2A, number2B, number2C, number2D] = moreNumbers;
        const [number3A, number3B] = incorrectNumbers;

        const productA = number1A * number2A;
        const productB = number1B * number2B;
        const productC = number1C * number2C;
        const productD = number1D * number2D;
        const productE = number1A * number3A;
        const productF = number2A * number3B;

        const products = [productA, productB, productC, productD, productE, productF];

        return {
          numbers,
          moreNumbers,
          incorrectNumbers,
          products,
          productA,
          productB,
          productC,
          productD,
          productE,
          productF
        };
      },
      ({ productA, productB, productC, productD, productE, productF }) =>
        arrayHasNoDuplicates([productA, productB, productC, productD, productE, productF])
    );

    return { numbers, moreNumbers, incorrectNumbers, products };
  },
  Component: props => {
    const {
      question: { numbers, moreNumbers, incorrectNumbers },
      translate
    } = props;

    const [number1A, number1B, number1C, number1D] = numbers;
    const [number2A, number2B, number2C, number2D] = moreNumbers;
    const [number3A, number3B] = incorrectNumbers;

    const productA = number(math.evaluate(`(${number1A} * ${number2A}) / 100`));
    const productB = number(math.evaluate(`(${number1B} * ${number2B}) / 100`));
    const productC = number(math.evaluate(`(${number1C} * ${number2C}) / 100`));
    const productD = number(math.evaluate(`(${number1D} * ${number2D}) / 100`));
    const productE = number(math.evaluate(`(${number1A} * ${number3A}) / 100`));
    const productF = number(math.evaluate(`(${number2A} * ${number3B}) / 100`));

    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.xPercentOfYAns(number2A, number1A),
          answer: productA
        },
        {
          sentence: translate.answerSentences.xPercentOfYAns(number2B, number1B),
          answer: productB
        },
        {
          sentence: translate.answerSentences.xPercentOfYAns(number2C, number1C),
          answer: productC
        },
        {
          sentence: translate.answerSentences.xPercentOfYAns(number2D, number1D),
          answer: productD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const answerOptions = shuffle([productA, productB, productC, productD, productE, productF], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        actionPanelVariant="endWide"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={1300}
        itemMaxLines={1}
      />
    );
  },
  questionHeight: 1300
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PercentageOfAnAmountOneStep',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
