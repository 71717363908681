import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { numberToBase10Object } from '../../../../utils/math';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { View } from 'react-native';
import { countRange, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'be0',
  description: 'be0',
  keywords: ['Tens'],
  schema: z.object({
    number: z.number().int().min(20).max(100),
    variation: z.enum(['tenFrame', 'Cubes', 'Straws', 'rekenrek'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['tenFrame', 'Cubes', 'Straws', 'rekenrek'] as const);
    // Can only fit 6 tenFrames on screen vertically
    const number = randomIntegerInclusiveStep(20, variation === 'tenFrame' ? 60 : 100, 10);

    return { number, variation };
  },
  Component: props => {
    const {
      question: { number, variation },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        actionPanelVariant="endWide"
        title={translate.ks1Instructions.whatNumberIsShown()}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
        Content={({ dimens }) => {
          return variation === 'Cubes' || variation === 'Straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: variation,
                numbers: number === 100 ? { tens: 10 } : numberToBase10Object(number),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={number} />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              <View style={{ flexDirection: 'row', gap: 20 }}>
                {countRange(Math.ceil(number / 10)).map(i => (
                  <TenFrameLayout
                    items={filledArray('red', 10)}
                    orientation="vertical"
                    size="xsmall"
                    key={i}
                  />
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'be1',
  description: 'be1',
  keywords: ['Tens'],
  schema: z.object({
    number: z.number().int().min(20).max(100),
    variation: z.enum(['tenFrame', 'Cubes', 'Straws', 'rekenrek'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const variation = getRandomFromArray(['tenFrame', 'Cubes', 'Straws', 'rekenrek'] as const);
    // Can only fit 6 tenFrames on screen vertically
    const number = randomIntegerInclusiveStep(20, variation === 'tenFrame' ? 60 : 100, 10);

    return { number, variation };
  },
  Component: props => {
    const {
      question: { number, variation },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsOnesInNum(number),
          translate.ks1AnswerSentences.thereAreAnsTensInNum(number)
        ]}
        questionHeight={1000}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[[(0).toString()], [(number / 10).toString()]]}
        Content={({ dimens }) => {
          return variation === 'Cubes' || variation === 'Straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: variation,
                numbers: number === 100 ? { tens: 10 } : numberToBase10Object(number),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={number} />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              <View style={{ flexDirection: 'row', gap: 20 }}>
                {countRange(Math.ceil(number / 10)).map(i => (
                  <TenFrameLayout
                    items={filledArray('red', 10)}
                    orientation="vertical"
                    size="xsmall"
                    key={i}
                  />
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'be2',
  description: 'be2',
  keywords: ['Tens'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(51)
      .max(99)
      .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10'),
    variation: z.enum(['tenFrame', 'Cubes', 'Straws', 'rekenrek'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['tenFrame', 'Cubes', 'Straws', 'rekenrek'] as const);
    // Can only fit 6 tenFrames on screen vertically
    const number = randomIntegerInclusive(51, variation === 'tenFrame' ? 59 : 99, {
      constraint: x => x % 10 !== 0
    });

    return { number, variation };
  },
  Component: props => {
    const {
      question: { number, variation },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        actionPanelVariant="endWide"
        title={translate.ks1Instructions.whatNumberIsShown()}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
        Content={({ dimens }) => {
          return variation === 'Cubes' || variation === 'Straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: variation,
                numbers: number === 100 ? { tens: 10 } : numberToBase10Object(number),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={number} />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              <View style={{ flexDirection: 'row', gap: 20 }}>
                {countRange(Math.ceil(number / 10)).map(i => (
                  <TenFrameLayout
                    items={filledArray('red', i === Math.floor(number / 10) ? number % 10 : 10)}
                    orientation="vertical"
                    size="xsmall"
                    key={i}
                  />
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TensTo100',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
