import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { View } from 'react-native';
import TextStructure from '../../../../components/molecules/TextStructure';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { NoKeyboardTextInputWithState } from '../../../../components/atoms/NoKeyboardTextInput';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfc',
  description: 'bfc',
  keywords: ['Equal to', 'Less than', 'Greater than'],
  schema: z.object({
    numberA: z.number().int().min(10).max(99),
    numberB: z.number().int().min(10).max(99),
    variant: z.enum(['Straws', 'Cubes'])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 9);

    const numberA = randomIntegerInclusive(10, 99, {
      constraint: x => Math.floor(x / 10) === tens
    });
    const numberB = randomIntegerInclusive(10, 99, {
      constraint: x => Math.floor(x / 10) === tens
    });

    const variant = getRandomFromArray(['Straws', 'Cubes'] as const);

    return {
      numberA,
      numberB,
      variant
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, variant },
      translate,
      displayMode
    } = props;

    const items = ['>', '<', '='];

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  ones: parseFloat(numberA.toString().split('')[1]),
                  tens: parseFloat(numberA.toString().split('')[0])
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'center' }}>
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: {
                  tens: parseFloat(numberB.toString().split('')[0]),
                  ones: parseFloat(numberB.toString().split('')[1])
                },
                // TODO: BaseTen arrangement, can be jumbled when work is done also if numberA === numberB arrangements need to different.
                arrangement: 'ltr'
              }}
              usableWidth={350}
              usableHeight={300}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompare()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bfd',
  description: 'bfd',
  keywords: ['Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    numberA: z.number().int().min(10).max(99),
    numberB: z.number().int().min(10).max(99)
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 9);

    const numberA = randomIntegerInclusive(10, 99, {
      constraint: x => Math.floor(x / 10) === tens
    });
    const numberB = randomIntegerInclusive(10, 99, {
      constraint: x => Math.floor(x / 10) === tens && x !== numberA
    });

    return {
      numberA,
      numberB
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB },
      translate,
      displayMode
    } = props;

    const items = ['>', '<', '='];

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}>
            <TextStructure sentence={numberA.toLocaleString()} />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}>
            <TextStructure sentence={numberB.toLocaleString()} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bfe',
  description: 'bfe',
  keywords: ['Less than', 'Greater than'],
  schema: z.object({
    number: z.number().int().min(11).max(99),
    symbol: z.enum([LESS_THAN, GREATER_THAN]),
    questionIndex: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const symbol = getRandomFromArray([LESS_THAN, GREATER_THAN] as const);

    const questionIndex = getRandomFromArray([0, 1] as const);

    const number = randomIntegerInclusive(11, 99, {
      constraint: x =>
        questionIndex === 1
          ? (symbol === GREATER_THAN && x % 10 !== 9) || (symbol === LESS_THAN && x % 10 !== 0)
          : (symbol === GREATER_THAN && x % 10 !== 0) || (symbol === LESS_THAN && x % 10 !== 9)
    });

    return { number, symbol, questionIndex };
  },

  Component: props => {
    const {
      question: { number, symbol, questionIndex },
      translate,
      displayMode
    } = props;

    const tens = Math.floor(number / 10);
    const ones = number % 10;

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheStatement()}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.numberSentenceMustBeCorrect()
        }}
        Content={({ dimens }) => {
          const numberBox = (number: number) => (
            <ContentBox
              containerStyle={{
                width: dimens.width / 8,
                height: displayMode === 'digital' ? 100 : 150,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">{number.toLocaleString()}</Text>
            </ContentBox>
          );

          const answerBox = (
            <NoKeyboardTextInputWithState
              id="textbox"
              testCorrect={answer => {
                const isGreaterThan = symbol === GREATER_THAN;
                const comparison = isGreaterThan === (questionIndex !== 0);
                return comparison ? parseInt(answer) > ones : parseInt(answer) < ones;
              }}
              singleCharacterMode
              style={{ width: dimens.width / 8 }}
            />
          );

          const symbolText = (
            <View style={{ justifyContent: 'center', marginHorizontal: 10 }}>
              <TextStructure sentence={symbol} />
            </View>
          );

          return (
            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
              {numberBox(tens)}
              {questionIndex === 0 ? numberBox(ones) : answerBox}
              {symbolText}
              {numberBox(tens)}
              {questionIndex === 0 ? answerBox : numberBox(ones)}
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareNumbersWithTheSameNumberOfTens',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
