export const splitByTwo = (number: number): { amount: number; isRemainder: boolean }[] => {
  if (number < 0) {
    throw Error('Cannot split negative numbers.');
  }

  let currentNumber = number;

  const arrayOfNums = [];

  while (currentNumber > 2) {
    arrayOfNums.push({ amount: 2, isRemainder: false });
    currentNumber -= 2;
  }

  arrayOfNums.push({ amount: currentNumber, isRemainder: false });

  return arrayOfNums;
};

export const splitByNine = (number: number): { amount: number; isRemainder: boolean }[] => {
  if (number < 0) {
    throw Error('Cannot split negative numbers.');
  }

  let currentNumber = number;

  const arrayOfNums = [];

  while (currentNumber >= 9) {
    arrayOfNums.push({ amount: 9, isRemainder: false });
    currentNumber -= 9;
  }

  if (currentNumber > 0 && number > 9) {
    arrayOfNums.push({ amount: currentNumber, isRemainder: true });
  } else if (currentNumber >= 0) {
    arrayOfNums.push({ amount: currentNumber, isRemainder: false });
  }

  return arrayOfNums;
};

export const splitByTen = (number: number): { amount: number; isRemainder: boolean }[] => {
  if (number < 0) {
    throw Error('Cannot split negative numbers.');
  }

  let currentNumber = number;

  const arrayOfNums = [];

  while (currentNumber > 10) {
    arrayOfNums.push({ amount: 10, isRemainder: false });
    currentNumber -= 10;
  }

  arrayOfNums.push({ amount: currentNumber, isRemainder: false });

  return arrayOfNums;
};
