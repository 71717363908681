// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UnderstandNegativeNumbers from './1UnderstandNegativeNumbers';
import CountThroughZeroIn1s from './2CountThroughZeroIn1s';
import CountThroughZeroInMultiples from './3CountThroughZeroInMultiples';
import CompareAndOrderNegativeNumbers from './4CompareAndOrderNegativeNumbers';
import FindTheDifference from './5FindTheDifference';

const Block = newBlockContent({
  block: 'NegativeNumbers',
  field: 'Number',
  weeks: [9, 9],
  smallSteps: [
    UnderstandNegativeNumbers, // Step 1
    CountThroughZeroIn1s, // Step 2
    CountThroughZeroInMultiples, // Step 3
    CompareAndOrderNegativeNumbers, // Step 4
    FindTheDifference // Step 5
  ]
});
export default Block;
