import { newBlockContent } from '../../../Block';

import Unitising from './1Unitising';
import RecogniseCoins from './2RecogniseCoins';
import RecogniseNotes from './3RecogniseNotes';
import CountInCoins from './4CountInCoins';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [9, 9],
  smallSteps: [
    Unitising, // Step 1
    RecogniseCoins, // Step 2
    RecogniseNotes, // Step 3
    CountInCoins // Step 4
  ]
});
export default Block;
