import { TouchableGattegnoChartWithState } from '../representations/Gattegno Chart/TouchableGattegnoChart';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import { DisplayMode } from '../../../contexts/displayMode';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  correctAnswer: number;
  rowsToShow: number[];
  differentRowColors?: boolean;
  preshaded?: number[];
  /** PDF Question Height */
  questionHeight?: number;
};

export default function QF12CreateGattegnoChart({
  title,
  pdfTitle,
  correctAnswer,
  rowsToShow,
  differentRowColors,
  preshaded,
  questionHeight,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <TouchableGattegnoChartWithState
                id="gattegnochart"
                rowsToShow={rowsToShow}
                differentRowColors={differentRowColors}
                dimens={dimens}
              />
            )}
          </MeasureView>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <TouchableGattegnoChartWithState
                id="gattegnochart"
                rowsToShow={rowsToShow}
                defaultState={correctAnswer}
                differentRowColors={differentRowColors}
                dimens={dimens}
              />
            )}
          </MeasureView>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <TouchableGattegnoChartWithState
              id="gattegnochart"
              correctAnswer={correctAnswer}
              rowsToShow={rowsToShow}
              differentRowColors={differentRowColors}
              dimens={dimens}
              preshaded={preshaded}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
