import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive, seededRandom } from '../../../../utils/random';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKm',
  description: 'aKm',
  keywords: ['Multiplication', 'Array'],
  schema: z.object({
    cols: z.number().int().min(2).max(12),
    rows: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const cols = randomIntegerInclusive(2, 12);
    const rows = randomIntegerInclusive(2, 5);

    return { cols, rows };
  },

  Component: props => {
    const {
      question: { cols, rows },
      translate
    } = props;

    const correctAnswer = cols * rows;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[correctAnswer.toString()]}
        sentence={`${cols.toLocaleString()} ${MULT} ${rows.toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={cols}
            customImage={
              <AssetSvg
                name="Place_value/1"
                width={Math.min(dimens.width / cols, dimens.height / rows)}
              />
            }
            dimens={dimens}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aKn',
  description: 'aKn',
  keywords: ['Multiplication', 'Array'],
  schema: z.object({
    cols: z.number().int().min(2).max(12),
    rows: z.number().int().min(2).max(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cols = randomIntegerInclusive(2, 12);
    const rows = randomIntegerInclusive(2, 4);

    return { cols, rows };
  },

  Component: props => {
    const {
      question: { cols, rows },
      translate
    } = props;

    const tens = cols * rows;
    const sum = tens * 10;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[tens.toString()], [sum.toString()]]}
        sentences={[
          `${cols.toLocaleString()} ${MULT} ${rows.toLocaleString()} = <ans/>`,
          `${cols.toLocaleString()} ${MULT} ${rows.toLocaleString()} ${MULT} 10 = <ans/>`
        ]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={cols}
            customImage={
              <AssetSvg
                name="Place_value/10"
                width={Math.min(dimens.width / cols, dimens.height / rows)}
              />
            }
            dimens={dimens}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKo',
  description: 'aKo',
  keywords: ['Multiplication', 'Array'],
  schema: z.object({
    cols: z.number().int().min(2).max(12),
    rows: z.number().int().min(2).max(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cols = randomIntegerInclusive(2, 12);
    const rows = randomIntegerInclusive(2, 4);

    return { cols, rows };
  },

  Component: props => {
    const {
      question: { cols, rows },
      translate
    } = props;

    const hundreds = cols * rows;
    const sum = hundreds * 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[hundreds.toString()], [sum.toString()]]}
        sentences={[
          `${cols.toLocaleString()} ${MULT} ${rows.toLocaleString()} = <ans/>`,
          `${cols.toLocaleString()} ${MULT} ${rows.toLocaleString()} ${MULT} 100 = <ans/>`
        ]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={cols}
            customImage={
              <AssetSvg
                name="Place_value/100"
                width={Math.min(dimens.width / cols, dimens.height / rows)}
              />
            }
            dimens={dimens}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aKp',
  description: 'aKp',
  keywords: ['Multiplication', 'Array'],
  schema: z.object({
    cols: z.number().int().min(2).max(12),
    rows: z.number().int().min(2).max(4),
    divisor: z.number().int().min(2).max(12)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cols = randomIntegerInclusive(2, 12);
    const rows = randomIntegerInclusive(2, 4);
    const divisor = getRandomFromArray([cols, rows]);

    return { cols, rows, divisor };
  },

  Component: props => {
    const {
      question: { cols, rows, divisor },
      translate
    } = props;

    const sum = cols * rows * 100;
    const sumHundreds = cols * rows;
    const hundreds = sum / 100 / divisor;
    const answer = hundreds * 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[hundreds.toString()], [answer.toString()]]}
        sentences={[
          translate.answerSentences.xHundredsDivYEqualsAnsHundreds(sumHundreds, divisor),
          `${sum.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`
        ]}
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={cols}
            customImage={
              <AssetSvg
                name="Place_value/100"
                width={Math.min(dimens.width / cols, dimens.height / rows)}
              />
            }
            dimens={dimens}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aKq',
  description: 'aKq',
  keywords: ['Multiplication', '10', '100'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * 10;
    const number4 = number1 * 100;

    const answers = [number1 * number2, number1 * number3, number1 * number4];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={answers.map(answer => [answer.toString()])}
        sentences={[
          `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKr',
  description: 'aKr',
  keywords: ['Division', '10', '100'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;
    const number4 = number1 * 10;
    const number5 = number1 * 100;
    const number6 = number2 * 10;
    const number7 = number2 * 100;
    const number8 = number3 * 10;
    const number9 = number3 * 100;
    const number10 = getRandomFromArray([number1, number2, number4, number6], {
      random: seededRandom(props.question)
    });
    const number11 = number8 / number10;
    const number12 = getRandomFromArray([number1, number2, number4, number5, number6, number7], {
      random: seededRandom(props.question)
    });
    const number13 = number9 / number12;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[number1.toString()], [number11.toString()], [number13.toString()]]}
        sentences={[
          `${number3.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
          `${number8.toLocaleString()} ${DIV} ${number10.toLocaleString()} = <ans/>`,
          `${number9.toLocaleString()} ${DIV} ${number12.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RelatedFactsMultiplicationAndDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
