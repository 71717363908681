import { Line, Svg } from 'react-native-svg';
import { View } from 'react-native';
import { colors } from '../../../theme/colors';
import { countRange } from '../../../utils/collections';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

const lineWidth = 5;

function TallyMark({
  height,
  x1Position,
  x2Position
}: {
  height: number;
  x1Position: number;
  x2Position?: number;
}) {
  const displayMode = useContext(DisplayMode);

  return (
    <Line
      x1={x1Position}
      y1={5}
      x2={x2Position ?? x1Position}
      y2={height - 5}
      stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  );
}

export function tallyImages({
  value,
  width,
  height,
  key
}: {
  value: 1 | 2 | 3 | 4 | 5;
  width?: number;
  height?: number;
  key?: string;
}) {
  const lineGap = 15;
  const svgWidth =
    width ?? value === 5 ? (lineWidth + lineGap) * 4 + 10 : (lineWidth + lineGap) * value;
  const svgHeight = height ?? 75;

  const offset = value === 5 ? 5 : 0;

  const component = (
    <Svg key={key} width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
      {countRange(value).map(i =>
        i < 4 ? (
          <TallyMark
            key={`${key}_${i}`}
            height={svgHeight}
            x1Position={lineGap / 2 + lineWidth / 2 + (lineGap + lineWidth) * i + offset}
          />
        ) : (
          <TallyMark
            key={`${key}_${i}`}
            height={svgHeight}
            x1Position={svgWidth - 5}
            x2Position={5}
          />
        )
      )}
    </Svg>
  );
  return { component: component, value: value as number };
}

export function numberToTally(number: number, width?: number, height?: number) {
  const numOfFullTallies = Math.floor(number / 5);
  const remainder = (number % 5) as 1 | 2 | 3 | 4 | 5;

  return (
    <View
      key={`tally_row`}
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 10,
        flexWrap: 'wrap'
      }}
    >
      {countRange(numOfFullTallies).map(
        i => tallyImages({ value: 5, width, height, key: `whole_${i}` }).component
      )}
      {tallyImages({ value: remainder, width, height, key: `remainder` }).component}
    </View>
  );
}
