import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import { isInRange } from '../../../../utils/matchers';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import { Point2d } from '../../../../utils/vectors';
import { GridWithArrowsBetweenChildren } from '../../../../components/question/representations/GridWithArrowsBetweenChildren';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Text from '../../../../components/typography/Text';
import { filledArray } from '../../../../utils/collections';

////
// Questions
////

const images = ['Cow', 'Horse', 'Sheep', 'Bee', 'Ant'] as const;
type Image = (typeof images)[number];

const svgNames: Record<Image, SvgName> = {
  Cow: 'Cow',
  Horse: 'Horse',
  Sheep: 'Sheep',
  Bee: 'Bee_from_above',
  Ant: 'Ant'
};

const Question1 = newQuestionContent({
  uid: 'beO',
  description: 'beO',
  keywords: ['Forwards', 'Backwards', 'Position'],
  schema: z
    .object({
      trackSize: z.number().int().min(5).max(6),
      startSpace: z.number().int().min(0).max(7),
      endSpace: z.number().int().min(0).max(7),
      name: z.enum(['Jack', 'Kim', 'Max']),
      flipCharacter: z.boolean(),
      subQuestion: z.discriminatedUnion('variant', [
        z.object({ variant: z.literal('direction') }),
        z.object({
          variant: z.literal('distance'),
          numberItems: z.number().int().array().length(4)
        })
      ])
    })
    .refine(val => isInRange(0, val.trackSize - 1)(val.startSpace), 'item must stay inside track')
    .refine(val => isInRange(0, val.trackSize - 1)(val.endSpace), 'item must stay inside track'),
  simpleGenerator: () => {
    const name = getRandomFromArray(['Jack', 'Kim', 'Max'] as const);
    const trackSize = randomIntegerInclusive(5, 6);
    const [startSpace, endSpace] = randomUniqueIntegersInclusive(0, trackSize - 1, 2);
    const flipCharacter = getRandomBoolean();
    const variant = getRandomFromArray(['distance', 'direction'] as const);
    const subQuestion = (() => {
      switch (variant) {
        case 'distance':
          return {
            variant,
            numberItems: shuffle([
              ...randomUniqueIntegersInclusive(1, trackSize - 1, 3, {
                constraint: x => x !== Math.abs(startSpace - endSpace)
              }),
              Math.abs(startSpace - endSpace)
            ])
          };
        case 'direction':
          return { variant };
      }
    })();

    return {
      name,
      trackSize,
      startSpace,
      endSpace,
      flipCharacter,
      subQuestion
    };
  },
  Component: props => {
    const {
      question: { name, trackSize, startSpace, endSpace, flipCharacter, subQuestion },
      translate
    } = props;

    const start = new Point2d(startSpace, 0);
    const end = new Point2d(endSpace, 0);

    const xDirection =
      (startSpace < endSpace && !flipCharacter) || (startSpace > endSpace && flipCharacter)
        ? 'forwards'
        : 'backwards';
    const xDistance = Math.abs(startSpace - endSpace);
    const items =
      subQuestion.variant === 'direction'
        ? (['forwards', 'backwards'] as const).map(value => ({
            value,
            component: <Text variant="WRN700">{translate.ks1MiscStrings.directions[value]()}</Text>
          }))
        : subQuestion.numberItems.map(value => ({
            value: value.toString(),
            component: <Text variant="WRN700">{value.toLocaleString()}</Text>
          }));
    const scale = flipCharacter ? -1 : 1;

    const svgName: SvgName = `Characters/Direction/${name}_body`;
    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        Content={({ dimens }) => (
          <GridWithArrowsBetweenChildren
            xMax={trackSize}
            dimens={{ width: dimens.width, height: dimens.height * 0.7 }}
            points={[start, end]}
            imageStyle={{ transform: `scaleX(${scale})` }}
            svgNames={filledArray(svgName, 2)}
            arrowXOffset={0.35}
            yMax={1}
          />
        )}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentence={
          subQuestion.variant === 'direction'
            ? translate.ks1AnswerSentences.nameMovesXSpacesAns(name, xDistance)
            : translate.ks1AnswerSentences.nameMovesAnsSpacesX(
                name,
                xDistance,
                translate.ks1MiscStrings.directions[xDirection]()
              )
        }
        testCorrect={subQuestion.variant === 'direction' ? [xDirection] : [xDistance.toString()]}
        pdfLayout="itemsTop"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'beP',
  description: 'beP',
  keywords: ['Forwards', 'Backwards', 'Move', 'Position'],
  schema: z
    .object({
      trackSize: z.number().int().min(5).max(8),
      startSpace: z.number().int().min(0).max(7),
      spaces: z
        .number()
        .int()
        .min(-7)
        .max(7)
        .refine(val => val !== 0),
      image: z.enum(['Cow', 'Horse', 'Sheep', 'Bee', 'Ant'])
    })
    .refine(val => {
      const after = val.startSpace + val.spaces;
      return isInRange(0, val.trackSize - 1)(after);
    }, 'item must stay inside track'),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Cow', 'Horse', 'Sheep', 'Bee', 'Ant'] as const);

    const trackSize = randomIntegerInclusive(5, 8);
    const startSpace = randomIntegerInclusive(0, trackSize - 1);

    const spaces = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => x !== 0 && isInRange(0, trackSize - 1)(startSpace + x)
    });

    return { image, trackSize, startSpace, spaces };
  },
  Component: props => {
    const {
      question: { image, trackSize, startSpace, spaces },
      translate
    } = props;

    const animal = (() => {
      switch (image) {
        case 'Ant':
          return translate.animals.ants(1);
        case 'Bee':
          return translate.animals.bees(1);
        case 'Cow':
          return translate.animals.Cow(1);
        case 'Horse':
          return translate.animals.Horse(1);
        case 'Sheep':
          return translate.animals.sheep();
      }
    })();

    const [yDirection, yDistance] = ((): ['backwards' | 'forwards', number] => {
      if (spaces > 0) return ['forwards', spaces];
      else return ['backwards', -spaces];
    })();

    const start = new Point2d(startSpace, 0);
    const end = start.add(new Point2d(spaces, 0));

    return (
      <QF48TranslateSvg
        title={translate.ks1Instructions.dragTheItemXSpacesDy(
          animal,
          yDistance,
          translate.ks1MiscStrings.directions[yDirection]()
        )}
        pdfTitle={translate.ks1PDFInstructions.theItemMovesXSpacesDyDrawACrossOnTheTrack(
          animal,
          yDistance,
          translate.ks1MiscStrings.directions[yDirection]()
        )}
        start={[start]}
        end={[end]}
        questionHeight={900}
        svg="custom"
        customDraggable={(_color: string, opacity?: number, _index = 0, width = 200) => ({
          component: (
            <View
              style={{
                opacity: opacity,
                width: width,
                height: width,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ transform: `rotate(${image === 'Bee' ? 90 : 0}deg)` }}>
                <AssetSvg name={svgNames[image]} width={width * 0.9} height={width * 0.9} />
              </View>
            </View>
          ),
          width: width,
          height: width
        })}
        anchorDX={0}
        anchorDY="height"
        yMax={1}
        xMax={trackSize}
        gridLineWidth={2}
        markSchemeIsGhost
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'beQ',
  description: 'beQ',
  keywords: ['Forwards', 'Backwards', 'Move', 'Position', 'Left', 'Right'],
  schema: z
    .object({
      startSpaceX: z.number().int().min(0).max(3),
      startSpaceY: z.number().int().min(0).max(3),
      spacesX: z
        .number()
        .int()
        .min(-3)
        .max(3)
        .refine(val => val !== 0),
      spacesY: z
        .number()
        .int()
        .min(-3)
        .max(3)
        .refine(val => val !== 0),
      image: z.enum(['Bee', 'Ant'])
    })
    .refine(val => {
      const afterY = val.startSpaceY + val.spacesY;
      const afterX = val.startSpaceX + val.spacesX;
      return isInRange(0, 3)(afterY) && isInRange(0, 3)(afterX);
    }, 'item must stay inside track'),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);

    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 1);

    const spacesX = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => isInRange(0, trackSize - 1)(startSpaceX + x) && x !== 0
    });

    const spacesY = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => isInRange(0, trackSize - 1)(startSpaceY + x) && x !== 0
    });

    return { image, startSpaceX, startSpaceY, spacesX, spacesY };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spacesX, spacesY },
      translate
    } = props;

    const animal = (() => {
      switch (image) {
        case 'Ant':
          return translate.animals.ants(1);
        case 'Bee':
          return translate.animals.bees(1);
      }
    })();

    const start = new Point2d(startSpaceX, startSpaceY);
    const end = start.add(new Point2d(spacesX, spacesY));

    const [yDirection, yDistance] = ((): ['backwards' | 'forwards', number] => {
      if (spacesY > 0) return ['forwards', spacesY];
      else return ['backwards', -spacesY];
    })();

    const [xDirection, xDistance] = ((): ['left' | 'right', number] => {
      if (spacesX > 0) return ['right', spacesX];
      else return ['left', -spacesX];
    })();

    return (
      <QF48TranslateSvg
        title={translate.ks1Instructions.dragTheItemYSpacesDyAndXSpacesDx({
          item: animal,
          dy: yDistance,
          yDirection,
          dx: xDistance,
          xDirection
        })}
        pdfTitle={translate.ks1PDFInstructions.theItemMovesYSpacesDyAndXSpacesDxDrawACrossOnTheTrack(
          {
            item: animal,
            dy: yDistance,
            yDirection,
            dx: xDistance,
            xDirection
          }
        )}
        start={[start]}
        end={[end]}
        questionHeight={900}
        svg="custom"
        customDraggable={(_color: string, opacity?: number, _index = 0, width = 200) => ({
          component: (
            <View
              style={{
                opacity: opacity,
                width: width,
                height: width,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ transform: `rotate(${image === 'Ant' ? 270 : 0}deg)` }}>
                <AssetSvg name={svgNames[image]} width={width * 0.9} height={width * 0.9} />
              </View>
            </View>
          ),
          width: width,
          height: width
        })}
        anchorDX={0}
        anchorDY="height"
        yMax={4}
        xMax={4}
        gridLineWidth={2}
        markSchemeIsGhost
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribePositionForwardsAndBackwards',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
