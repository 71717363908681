import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomUniqueUkCities, ukCitiesSchema } from '../../../../utils/cities';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { parseToSUB } from '../../../../utils/parse';
import { range, rangeAsString, sortNumberArray } from '../../../../utils/collections';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { Thermometer } from '../../../../components/question/representations/Thermometer';
import { numberEnum } from '../../../../utils/zod';
import QF66InteractiveThermometer from '../../../../components/question/questionFormats/QF66InteractiveThermometer';
import QF17eCompleteObjectAsNumberLine from '../../../../components/question/questionFormats/QF17eCompleteObjectAsNumberLine';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aCw',
  description: 'aCw',
  keywords: ['Negative numbers', 'Temperature', 'Thermometer'],
  schema: z
    .object({
      bottomScale: z.number().int().min(-9).max(-6),
      temp: z.number().int().min(-8).max(8)
    })
    .refine(
      ({ bottomScale, temp }) => temp < bottomScale + 15,
      'temp should be lower than the top scale'
    ),
  simpleGenerator: () => {
    const bottomScale = randomIntegerInclusive(-9, -6);
    const temp = randomIntegerInclusive(bottomScale + 1, bottomScale + 14);

    return { bottomScale, temp };
  },
  Component: ({ question: { bottomScale, temp }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTempShownOnThermometer()}
        extraSymbol={'minus'}
        Content={({ dimens }) => (
          <Thermometer
            bottomScale={bottomScale}
            topScale={bottomScale + 15}
            dimens={dimens}
            temperature={temp}
          />
        )}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.ansDegC()}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={userAnswer => userAnswer[0] === parseToSUB(temp.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [parseToSUB(temp.toString())]
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aCx',
  description: 'aCx',
  keywords: ['Negative numbers', 'Temperature', 'Thermometer'],
  schema: z
    .object({
      bottomScale: z.number().int().min(-20).max(-10).step(2),
      temp: z.number().int().min(-18).max(18).step(2)
    })
    .refine(
      ({ bottomScale, temp }) => temp < bottomScale + 30,
      'temp should be lower than the top scale'
    ),
  simpleGenerator: () => {
    const bottomScale = randomIntegerInclusiveStep(-20, -10, 2);
    const temp = randomIntegerInclusiveStep(bottomScale + 2, bottomScale + 28, 2);

    return { bottomScale, temp };
  },
  Component: ({ question: { bottomScale, temp }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTempShownOnThermometer()}
        extraSymbol={'minus'}
        Content={({ dimens }) => (
          <Thermometer
            bottomScale={bottomScale}
            topScale={bottomScale + 30}
            step={2}
            dimens={dimens}
            temperature={temp}
          />
        )}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.ansDegC()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={userAnswer => userAnswer[0] === parseToSUB(temp.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [parseToSUB(temp.toString())]
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'aCy',
  description: 'aCy',
  keywords: ['Negative numbers', 'Temperature', 'Thermometer'],
  schema: z
    .object({
      bottomScale: numberEnum([-30, -20, -10]),
      temp: z.number().int().min(-28).max(18).step(2)
    })
    .refine(
      ({ bottomScale, temp }) => temp < bottomScale + 30,
      'temp should be lower than the top scale'
    ),
  simpleGenerator: () => {
    const bottomScale = getRandomFromArray([-10, -20, -30] as const);
    const temp = randomIntegerInclusiveStep(bottomScale + 2, bottomScale + 28, 2);

    return { bottomScale, temp };
  },
  Component: ({ question: { bottomScale, temp }, translate }) => {
    return (
      <QF66InteractiveThermometer
        title={translate.instructions.dragArrowToShowXCOnTheThermometer(temp.toLocaleString())}
        pdfTitle={translate.instructions.shadeInThermometerToShowXC(temp.toLocaleString())}
        bottomScale={bottomScale}
        topScale={bottomScale + 30}
        step={2}
        testCorrect={temp}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aCz',
  description: 'aCz',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    interval: numberEnum([1, 2, 5]),
    missingIndices: z.array(z.number().int().min(0).max(9)).length(3)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([1, 2, 5] as const);

    // Get a mix of negative and positive missing numbers, excluding 0
    // Don't make the answer boxes consecutive or at the end of the line due to spacing on pdfs
    const negIdx = randomIntegerInclusive(0, 4);
    const posIdx = randomIntegerInclusive(6, 9);
    const randomInx = getRandomBoolean()
      ? randomIntegerInclusive(0, 4, { constraint: x => !isInRange(negIdx - 1, negIdx + 1)(x) })
      : randomIntegerInclusive(6, 9, { constraint: x => !isInRange(posIdx - 1, posIdx + 1)(x) });

    const missingIndices = sortNumberArray([negIdx, posIdx, randomInx]);

    return { interval, missingIndices };
  },
  Component: ({ question: { interval, missingIndices }, translate }) => {
    const endNumber = interval * 5;
    const startingNumber = -endNumber;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    const answerArray = missingIndices.map(idx => tickValues[idx]);

    return (
      <QF17eCompleteObjectAsNumberLine
        object="thermometer"
        extraSymbol={'minus'}
        title={translate.instructions.labelThermometerCorrectly()}
        inputMaxCharacters={3}
        tickValues={tickValues.map((n, i) => {
          return missingIndices.includes(i) ? '<ans/>' : n;
        })}
        testCorrect={answer =>
          answer.every((it, index) => it === parseToSUB(answerArray[index].toLocaleString()))
        }
        customMarkSchemeAnswer={{ answersToDisplay: answerArray }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCA',
  description: 'aCA',
  keywords: ['Negative numbers'],
  schema: z.object({
    cities: z.array(ukCitiesSchema).length(2),
    temp: z.number().int().min(-8).max(-2)
  }),
  simpleGenerator: () => {
    const cities = getRandomUniqueUkCities(2);
    const temp = randomIntegerInclusive(-8, -2);

    return { cities, temp };
  },

  Component: props => {
    const {
      question: { cities, temp },
      translate
    } = props;

    const acceptableRange = range(temp + 1, -1).map(temp => parseToSUB(temp.toString()));
    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.tempInXIsYTempInZIsWarmer(
          cities[0],
          parseToSUB(temp.toLocaleString()),
          cities[1]
        )}<br/>${translate.instructions.whatCouldTheTempInXBe(cities[1])}`}
        extraSymbol={'minus'}
        testCorrect={userAnswer => acceptableRange.includes(userAnswer[0])}
        sentence={translate.answerSentences.ansDegC()}
        inputMaxCharacters={2}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            parseToSUB((temp + 1).toLocaleString()),
            parseToSUB('-1')
          )
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aCA2',
  description: 'aCA2',
  keywords: ['Negative numbers'],
  schema: z.object({
    bottomScale: z.number().int().min(-15).max(-10),
    temp: z.number().int().min(-15).max(-3)
  }),
  simpleGenerator: () => {
    const bottomScale = randomIntegerInclusive(-15, -10);
    const temp = randomIntegerInclusive(bottomScale, -3);

    return { bottomScale, temp };
  },

  Component: ({ question: { bottomScale, temp }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTempShownOnThermometer()}
        extraSymbol={'minus'}
        Content={({ dimens }) => (
          <Thermometer
            bottomScale={bottomScale}
            topScale={bottomScale + 20}
            dimens={dimens}
            temperature={temp}
            showOnlyLabels={[0, 1]}
            horizontal={true}
          />
        )}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.ansDegC()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={userAnswer => userAnswer[0] === parseToSUB(temp.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [parseToSUB(temp.toString())]
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aCB',
  description: 'aCB',
  keywords: ['Negative numbers', 'Temperature'],
  schema: z
    .object({
      temp1: z.number().int().min(-20).max(-10),
      temp2: z.number().int().min(-10).max(-1)
    })
    .refine(
      ({ temp1, temp2 }) => temp2 - temp1 >= 2,
      'The temperatures should have a difference of atleaset 2'
    ),
  simpleGenerator: () => {
    const temp1 = randomIntegerInclusive(-20, -10);
    const temp2 = randomIntegerInclusive(Math.max(-10, temp1 + 2), -1);

    return { temp1, temp2 };
  },
  Component: ({ question: { temp1, temp2 }, translate }) => {
    // Table data
    const data = [
      [translate.time.numAm(5), translate.units.stringDegreesC(parseToSUB(temp1.toLocaleString()))],
      [translate.time.numNoon(12), translate.answerSentences.ansDegC()],
      [translate.time.numPm(6), translate.units.stringDegreesC(parseToSUB(temp2.toLocaleString()))]
    ];
    const acceptableRange = range(temp1 + 1, temp2 - 1).map(temp => parseToSUB(temp.toString()));
    return (
      <QF7InteractiveTable
        extraSymbol={'minus'}
        title={`${translate.instructions.useInfoToCompleteTheTable()}<br/>${translate.instructions.atNoonItWasWarmerThan5amButColderThan6pm()} `}
        cellHeaders={[translate.keywords.Time(), translate.keywords.Temperature()]}
        tableData={data}
        testCorrect={userAnswer => acceptableRange.includes(userAnswer[0])}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeInclusive(
            parseToSUB((temp1 + 1).toLocaleString()),
            parseToSUB((temp2 - 1).toLocaleString())
          )
        }}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandNegativeNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
