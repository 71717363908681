import { newBlockContent } from '../../../Block';

import OClockAndHalfPast from './1OClockAndHalfPast';
import QuarterPastAndQuarterTo from './2QuarterPastAndQuarterTo';
import TellTimePastTheHour from './3TellTimeTimePastTheHour';
import TellTimeToTheHour from './4TellTimeTimeToTheHour';
import TellTheTimeTo5Minutes from './5TellTheTimeTo5Minutes';
import MinutesInAnHour from './6MinutesInAnHour';
import HoursInADay from './7HoursInADay';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [4, 6],
  smallSteps: [
    OClockAndHalfPast, // Step 1
    QuarterPastAndQuarterTo, // Step 2
    TellTimePastTheHour, // Step 3
    TellTimeToTheHour, // Step 4
    TellTheTimeTo5Minutes, // Step 5
    MinutesInAnHour, // Step 6
    HoursInADay // Step 7
  ]
});
export default Block;
