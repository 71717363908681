import { z } from 'zod';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { findMultiplicationExchanges } from '../../../../utils/exchanges';
import { randomIntegerInclusive, rejectionSample } from '../../../../utils/random';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { MULT } from '../../../../constants';
import { all, create, number } from 'mathjs';
import { ScientificNotation, numberOfZeroDigits } from '../../../../utils/math';
import { range } from '../../../../utils/collections';
import QF23aDraggableMultiRowPlaceValueChart from '../../../../components/question/questionFormats/QF23aDraggableMultiRowPlaceValueChart';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKE',
  description: 'aKE',
  keywords: ['Multiplication', 'Place value chart'],
  schema: z.object({
    hundreds: z.number().int().min(1).max(4),
    tens: z.number().int().min(0).max(4),
    ones: z.number().int().min(1).max(4),
    multiplier: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 4);
    const hundreds = randomIntegerInclusive(1, 4, { constraint: x => x * multiplier < 9 });
    const tens = randomIntegerInclusive(0, 4);
    const ones = randomIntegerInclusive(1, 4);
    multiplier;
    return { hundreds, tens, ones, multiplier };
  },

  Component: props => {
    const {
      question: { hundreds, tens, ones, multiplier },
      translate
    } = props;

    const multiplicand = number(math.evaluate(`${hundreds} * 100 + ${tens} * 10 + ${ones}`));

    return (
      <QF23aDraggableMultiRowPlaceValueChart
        title={translate.instructions.dragCountersIntoPVCToShow(
          `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersInPVCToShow(
          `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        correctAnswerPerRow={ScientificNotation.fromNumber(multiplicand)}
        columnsToShow={[2, 1, 0]}
        numOfRows={multiplier}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aKF',
  description: 'aKF',
  keywords: ['Column multiplication', 'Three digit', 'One digit'],
  schema: z.object({
    var1: z
      .number()
      .int()
      .min(111)
      .max(399)
      .refine(x => numberOfZeroDigits(x) === 0),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 6);
    const var1 = randomIntegerInclusive(111, 399, {
      constraint: x => x * var2 < 1000 && numberOfZeroDigits(x) === 0
    });

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutX(
          `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()}`
        )}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aKG',
  description: 'aKG',
  keywords: ['Column multiplication', 'Three digit', 'One digit'],
  schema: z.object({
    var1: z
      .number()
      .int()
      .min(101)
      .max(399)
      .refine(x => numberOfZeroDigits(x) === 1),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 6);
    const var1 = randomIntegerInclusive(111, 399, {
      constraint: x => x * var2 < 1000 && numberOfZeroDigits(x) === 1
    });

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutX(
          `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()}`
        )}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aKH',
  description: 'aKH',
  keywords: ['Column multiplication', 'Three digit', 'One digit'],
  schema: z.object({
    var1: z
      .number()
      .int()
      .min(167)
      .max(799)
      .refine(x => numberOfZeroDigits(x) === 0),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 6);
    const var1 = randomIntegerInclusive(167, 799, {
      constraint: x => x * var2 < 2500 && x * var2 > 1000 && numberOfZeroDigits(x) === 0
    });

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutX(
          `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()}`
        )}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aKI',
  description: 'aKI',
  keywords: ['Column multiplication', 'Three digit', 'One digit'],
  schema: z.object({
    var1: z
      .number()
      .int()
      .min(170)
      .max(799)
      .refine(x => numberOfZeroDigits(x) === 1),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 6);
    const var1 = randomIntegerInclusive(170, 799, {
      constraint: x => x * var2 < 2500 && x * var2 > 1000 && numberOfZeroDigits(x) === 1
    });

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutX(
          `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()}`
        )}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aKJ',
  description: 'aKJ',
  keywords: ['Column multiplication', 'Three digit', 'One digit'],
  schema: z
    .object({
      var1: z.number().int().min(111).max(399),
      var2: z.number().int().min(2).max(6)
    })
    .refine(val => findMultiplicationExchanges(val.var1, val.var2).length > 1, 'numbers exchange'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(111, 399, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(2, 6);
        return { var1, var2 };
      },
      // Only permit them if they exchange
      ({ var1, var2 }) => findMultiplicationExchanges(var1, var2).length > 1
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const answer = number(math.evaluate(`${var1 * var2}`));
    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        topMissingDigits={[2]}
        bottomMissingDigits={[0]}
        answerMissingDigits={[1]}
        answerNumber={answer}
        showNumberExchanges
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(var1, var1.toString().length),
            bottom: getMarkSchemeAnswer(var2, var2.toString().length),
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA3DigitNumberByA1DigitNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
