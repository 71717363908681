import { StyleProp, TextStyle, View } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from 'common/src/utils/react';
import BaseLayout, { ActionPanelVariant } from 'common/src/components/molecules/BaseLayout';
import UserInput, { ExtraSymbols, UserInputEvent } from 'common/src/components/molecules/UserInput';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { Dimens } from 'common/src/theme/scaling';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  inputStyle?: StyleProp<TextStyle>;
  actionPanelVariant?: ActionPanelVariant;
  inputVariant?: 'tall' | 'wide';
  inputType?: UserInputEvent['inputType'];
  /** Content to fill the area under the title. */
  Content: ElementOrRenderFunction<{ dimens: Dimens }>;
  extraSymbol?: ExtraSymbols;
  /** Props for extra content modal */
  promptButton?: string | JSX.Element;
  promptButtonPosition?: 'left' | 'center' | 'right';
  modalTitle?: string;
  modalContent?: JSX.Element;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answerText?: string };
};

/**
 * Layout containing a title, numpad and content (within a MeasureView).
 * Using StateTree, the content may be interactive.
 * Use this instead of QF3InteractiveContent if you aren't using the state/setState that it provides.
 */
export default function QF3Content({
  title,
  pdfTitle,
  Content,
  inputStyle,
  inputType,
  actionPanelVariant,
  inputVariant,
  extraSymbol,
  promptButton,
  promptButtonPosition = 'center',
  modalTitle,
  modalContent,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const displayTitle =
    (displayMode === 'pdf' || displayMode === 'markscheme') && pdfTitle ? pdfTitle : title;

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={displayTitle}
        mainPanelContents={
          <View
            style={{
              flex: 1,
              alignSelf: 'stretch',
              justifyContent: 'space-evenly',
              alignItems: 'stretch'
            }}
          >
            <MeasureView>
              {dimens =>
                resolveElementOrRenderFunction(Content, {
                  dimens
                })
              }
            </MeasureView>
          </View>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={displayTitle}
        mainPanelContents={
          <>
            <View
              style={{
                flex: 1,
                alignSelf: 'stretch',
                justifyContent: 'space-evenly',
                alignItems: 'stretch'
              }}
            >
              <MeasureView>
                {dimens =>
                  resolveElementOrRenderFunction(Content, {
                    dimens
                  })
                }
              </MeasureView>
            </View>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        modalContent={modalContent}
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={displayTitle}
      actionPanelVariant={actionPanelVariant ?? (inputType ? 'endWide' : 'end')}
      actionPanelContents={
        inputType && (
          <UserInput inputType={inputType} variant={inputVariant} extraSymbol={extraSymbol} />
        )
      }
      mainPanelContents={
        <View
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'space-evenly',
            alignItems: 'stretch'
          }}
        >
          <MeasureView>
            {dimens =>
              resolveElementOrRenderFunction(Content, {
                dimens
              })
            }
          </MeasureView>
        </View>
      }
      promptButton={promptButton}
      promptButtonPosition={promptButtonPosition}
      modalTitle={modalTitle}
      modalContent={modalContent}
      {...props}
    />
  );
}
