import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { randomIntegerInclusive, rejectionSample, shuffle } from 'common/src/utils/random';
import { z } from 'zod';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import { digitAtPowerIsNumber } from 'common/src/utils/math';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from 'common/src/constants';
import { range } from '../../../../utils/collections';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agE',
  description: 'agE',
  keywords: ['Addition', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(11)
        .max(45)
        .refine(
          x => x % 10 !== 0 && x % 10 < 6,
          'cannot be a multiple of 10 and one digit less than 6'
        ),
      var2: z
        .number()
        .int()
        .min(11)
        .max(44)
        .refine(
          x => x % 10 !== 0 && x % 10 < 5,
          'cannot be a multiple of 10 and one digit less than 5'
        )
    })
    .refine(
      val => numbersDoNotExchange(val.var1, val.var2) && val.var1 + val.var2 < 100,
      'numbers do not exchange and total is less than 100'
    ),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(11, 45, { constraint: x => x % 10 !== 0 && x % 10 < 6 });

    const var2 = randomIntegerInclusive(11, 44, {
      constraint: x => x % 10 !== 0 && x % 10 < 5 && x + var1 < 100
    });
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={var1 + var2}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(var1 + var2, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'agF',
  description: 'agF',
  keywords: ['Addition', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(11)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(
      val => numbersDoNotExchange(val.var1, val.var2) && val.var1 + val.var2 < 1000,
      'numbers do not exchange and total is less than 1000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange
      ({ var1, var2 }) => numbersDoNotExchange(var1, var2)
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={var1 + var2}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(var1 + var2, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'agG',
  description: 'agG',
  keywords: ['Add', 'No exchange', 'Part-whole model'],
  schema: z.object({
    number1: z.number().int().min(101).max(898),
    number2: z.number().int().min(101).max(898)
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(101, 898, { constraint: x => x % 100 !== 0 });

        const number2 = randomIntegerInclusive(101, 898, {
          constraint: x => x % 10 !== 0
        });
        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotExchange(number1, number2) && number1 + number2 < 1000
    );

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={['']}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={'$ans'}
            partition={[number1.toLocaleString(), number2.toLocaleString()]}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            isInteractive
            dimens={dimens}
          />
        )}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => answer[0] === (number1 + number2).toString()}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [(number1 + number2).toLocaleString()]
        }}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'agH',
  description: 'agH',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(898),
      number2: z.number().int().min(101).max(898)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(101, 898, {
          constraint: x => x % 100 !== 0
        });

        const number2 = randomIntegerInclusive(101, 999 - number1, {
          constraint: x =>
            // number2 must have only one zero, in either the ones or the tens:
            (digitAtPowerIsNumber(x, 'ones', [0]) && !digitAtPowerIsNumber(x, 'tens', [0])) ||
            (!digitAtPowerIsNumber(x, 'ones', [0]) && digitAtPowerIsNumber(x, 'tens', [0]))
        });
        return { number1, number2 };
      },
      // Only permit them if their products are all different.
      ({ number1, number2 }) => numbersDoNotExchange(number1, number2)
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'agI',
  description: 'agI',
  keywords: ['Add', 'No exchange', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(101).max(898),
    number2: z.number().int().min(101).max(898)
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(101, 898, { constraint: x => x % 100 !== 0 });

        const number2 = randomIntegerInclusive(101, 898, {
          constraint: x => x % 10 !== 0
        });
        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotExchange(number1, number2) && number1 + number2 < 1000
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const numbers = [[number3], [number1, number2]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={[[0]]}
        total={number3}
        oneFontSize
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'agJ',
  description: 'agJ',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      topNumber: z.number().int().min(100).max(899),
      bottomNumber: z.number().int().min(100).max(899),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersDoNotExchange(val.topNumber, val.bottomNumber),
      'topNumber and bottomNumber must not exchange.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const topNumber = randomIntegerInclusive(100, 899);

    const bottomNumber = randomIntegerInclusive(100, 999 - topNumber, {
      constraint: x => numbersDoNotExchange(topNumber, x)
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );

    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwoNumbersNoExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
