import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, SUB } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getNumberOfIntervals,
  JugWithLiquid
} from '../../../../components/question/representations/JugWithLiquid';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import { LabelledJuiceBottle } from '../../../../components/question/representations/LabelledJuiceBottle';
import { getRandomName, nameSchema } from '../../../../utils/names';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJG',
  description: 'aJG',
  keywords: ['Volume', 'Millilitres', 'Add'],
  questionHeight: 1000,
  schema: z
    .object({
      interval: numberEnum([100, 200, 250]),
      liquidA: z.number().int().min(100).max(900),
      liquidB: z.number().int().min(100).max(900)
    })
    .refine(({ liquidA, liquidB }) => liquidA !== liquidB, 'Liquid amounts should be different')
    .refine(
      ({ liquidA, liquidB }) => liquidA + liquidB < 1000,
      'Total amount should be less than 1000'
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([100, 200, 250] as const);

    // Get maxLiquid of whole number steps from minLiquid
    const maxLiquid = interval * Math.floor((900 - interval) / interval);
    const liquidA = randomIntegerInclusiveStep(interval, maxLiquid, interval);

    const liquidB = randomIntegerInclusiveStep(interval, maxLiquid, interval, {
      constraint: x => x !== liquidA && x + liquidA < 1000
    });

    return { interval, liquidA, liquidB };
  },
  Component: props => {
    const {
      question: { interval, liquidA, liquidB },
      translate
    } = props;

    const jugs = [
      {
        liquidAmount: liquidA,
        tickValue: interval
      },
      {
        liquidAmount: liquidB,
        tickValue: interval
      }
    ];

    const totalWater = liquidA + liquidB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchWaterIsThereInTotal()}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            {jugs.map(({ liquidAmount, tickValue }, i) => (
              <JugWithLiquid
                key={i}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.9 }}
                jugCapacity={1000}
                tickValue={tickValue === 100 ? 500 : tickValue}
                unitsPerMajorTick={tickValue === 100 ? 4 : undefined}
                liquidAmount={liquidAmount}
                labelUnits={'ml'}
              />
            ))}
          </View>
        )}
        sentence={translate.answerSentences.ansMl()}
        testCorrect={[totalWater.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJH',
  description: 'aJH',
  keywords: ['Volume', 'Litres', 'Add'],
  questionHeight: 1000,
  schema: z.object({
    bottleLitres: z.array(z.number().int().min(1).max(3))
  }),
  simpleGenerator: () => {
    const numberOfBottles = randomIntegerInclusive(2, 4);

    const bottleLitres = countRange(numberOfBottles).map(_ => randomIntegerInclusive(1, 3));

    return { bottleLitres };
  },
  Component: props => {
    const {
      question: { bottleLitres },
      translate
    } = props;
    const totalLitres = sumNumberArray(bottleLitres);
    const numBottles = bottleLitres.length;

    const bottleInfo = [
      ['small', 0.8],
      ['medium', 0.9],
      ['large', 1]
    ] as const;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchJuiceIsThereAltogether()}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            {bottleLitres.map((litres, i) => {
              const [bottleSvg, scaleHeight] = bottleInfo[litres - 1];
              return (
                <View
                  key={i}
                  style={{
                    width: dimens.width / numBottles,
                    alignSelf: 'flex-end'
                  }}
                >
                  <LabelledJuiceBottle
                    dimens={{
                      width: dimens.width / numBottles,
                      height: dimens.height * scaleHeight
                    }}
                    label={translate.units.numberOfL(litres)}
                    bottleSize={bottleSvg}
                  />
                </View>
              );
            })}
          </View>
        )}
        sentence={translate.answerSentences.ansL()}
        testCorrect={[totalLitres.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJI',
  description: 'aJI',
  keywords: ['Volume', 'Millilitres', 'Add'],
  questionHeight: 1000,
  schema: z
    .object({
      jugCapacity: z.number().int().min(2).max(5),
      intervalA: numberEnum([200, 250, 500]),
      intervalB: numberEnum([200, 250]),
      lA: z.number().int().min(1).max(4),
      lB: z.number().int().min(0).max(4),
      mlA: z.number().int().min(100).max(800),
      mlB: z.number().int().min(100).max(800)
    })
    .refine(({ mlA, mlB }) => mlA + mlB < 1000, 'Total ml should be less than 1000'),
  simpleGenerator: () => {
    const jugCapacity = randomIntegerInclusive(2, 5);
    const intervalA = getRandomFromArray([200, 250, 500] as const);
    const intervalB = getRandomFromArray([200, 250] as const);

    const lA = randomIntegerInclusive(1, jugCapacity - 1);

    const lB = randomIntegerInclusive(0, jugCapacity - 1);

    const { mlA, mlB } = rejectionSample(
      () => {
        const mlA = randomIntegerInclusive(100, 800, {
          constraint: x => x % intervalA === 0
        });
        const mlB = randomIntegerInclusive(100, 800, {
          constraint: x => x % intervalB === 0
        });

        return { mlA, mlB };
      },
      ({ mlA, mlB }) => mlA + mlB < 1000
    );
    return { jugCapacity, intervalA, intervalB, lA, mlA, lB, mlB };
  },
  Component: props => {
    const {
      question: { jugCapacity, intervalA, intervalB, lA, mlA, lB, mlB },
      translate
    } = props;

    const jugs = [
      {
        liquidAmount: lA * 1000 + mlA,
        interval: intervalA
      },
      {
        liquidAmount: lB * 1000 + mlB,
        interval: intervalB
      }
    ];

    const totalLitres = lA + lB;
    const totalMl = mlA + mlB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchWaterIsThereInTotal()}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            {jugs.map(({ liquidAmount, interval }, i) => (
              <JugWithLiquid
                key={i}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.9 }}
                jugCapacity={jugCapacity * 1000}
                tickValue={1000}
                unitsPerMajorTick={getNumberOfIntervals(interval)}
                liquidAmount={liquidAmount}
              />
            ))}
          </View>
        )}
        sentence={translate.answerSentences.ansLAnsMl()}
        testCorrect={[totalLitres.toString(), totalMl.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aJJ',
  description: 'aJJ',
  keywords: ['Volume', 'Capacity', 'Litres', 'Millilitres', 'Add'],
  questionHeight: 1200,
  schema: z
    .object({
      name: nameSchema,
      bottleL: z.number().int().min(1).max(3),
      bottleMl: z.number().int().min(300).max(900).step(100),
      jugCapacity: numberEnum([200, 300, 400, 500]),
      interval: numberEnum([100, 200, 250]),
      jugMl: z.number().int().min(100).max(500)
    })
    .refine(
      ({ jugCapacity, interval }) => jugCapacity % interval === 0,
      'jug capacity should be a multiple of the intervals'
    ),
  simpleGenerator: () => {
    const name = getRandomName();
    const bottleL = randomIntegerInclusive(1, 3);
    const bottleMl = randomIntegerInclusiveStep(300, 900, 100);
    const jugCapacity = getRandomFromArray([200, 300, 400, 500] as const);
    const intervalsOptions =
      jugCapacity === 500
        ? ([100, 250] as const)
        : jugCapacity === 400
        ? ([100, 200] as const)
        : ([100] as const);
    const interval = getRandomFromArray(intervalsOptions);
    const jugMl = randomIntegerInclusive(100, Math.min(bottleMl - 1, jugCapacity), {
      constraint: x => x % interval === 0
    });
    return { name, bottleL, bottleMl, jugCapacity, interval, jugMl };
  },
  Component: props => {
    const {
      question: { name, bottleL, bottleMl, jugCapacity, interval, jugMl },
      translate,
      displayMode
    } = props;
    const remainingMl = bottleMl - jugMl;

    const isDigital = displayMode === 'digital';
    return (
      <QF1ContentAndSentence
        title={translate.instructions.charHasXLYMlOfJuiceHowMuchIsInBottleNow(
          name,
          bottleL,
          bottleMl
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: isDigital ? 'space-between' : 'flex-start',
              alignItems: 'flex-end'
            }}
          >
            <LabelledJuiceBottle
              bottleSize="extraLarge"
              dimens={{ height: dimens.height, width: dimens.width * 0.4 }}
              label={translate.units.numberOfLMl(bottleL, bottleMl)}
            />
            <View style={{ paddingBottom: dimens.height * 0.06 }}>
              <JugWithLiquid
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.5 }}
                jugCapacity={jugCapacity}
                tickValue={interval}
                liquidAmount={jugMl}
                liquidType="orange"
                labelUnits="ml"
                displayMajorLabels="top"
              />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.ansLAnsMl()}
        testCorrect={[bottleL.toString(), remainingMl.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aJJ2',
  description: 'aJJ2',
  keywords: ['Volume', 'Capacity', 'Litres', 'Millilitres', 'Add', 'Subtract'],
  schema: z.object({
    waterMl: z.number().int().min(100).max(900).step(50)
  }),
  simpleGenerator: () => {
    const waterMl = randomIntegerInclusiveStep(100, 900, 50);

    return {
      waterMl
    };
  },

  Component: props => {
    const {
      question: { waterMl },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        sentence={`${translate.answerSentences.xL(1)} ${SUB} ${translate.answerSentences.xMl(
          waterMl
        )} = ${translate.answerSentences.ansMl()}`}
        testCorrect={[(1000 - waterMl).toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJK',
  description: 'aJK',
  keywords: ['Volume', 'Capacity', 'Litres', 'Millilitres', 'Subtract'],
  schema: z
    .object({
      waterL: z.number().int().min(2).max(9),
      waterMl: z.number().int().min(100).max(600).step(100),
      capacityL: z.number().int().min(3).max(10),
      capacityMl: z.number().int().min(200).max(900).step(100)
    })
    .refine(val => val.waterL < val.capacityL, 'waterL must be less than capacityL.')
    .refine(val => val.waterMl < val.capacityMl, 'waterMl must be less than capacityMl.'),
  simpleGenerator: () => {
    const waterL = randomIntegerInclusive(2, 9);
    const waterMl = randomIntegerInclusiveStep(100, 600, 100);

    const capacityL = randomIntegerInclusive(waterL + 1, 10);
    const capacityMl = randomIntegerInclusiveStep(waterMl + 100, 900, 100);

    return { waterL, waterMl, capacityL, capacityMl };
  },

  Component: props => {
    const {
      question: { waterL, waterMl, capacityL, capacityMl },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fishTankHasABWaterCapacityIsCDHowMuchMoreCanFit(
          waterL,
          waterMl,
          capacityL,
          capacityMl
        )}
        sentence={translate.answerSentences.ansLAnsMl()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(capacityL - waterL).toString(), (capacityMl - waterMl).toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJL',
  description: 'aJL',
  keywords: ['Volume', 'Capacity', 'Litres', 'Millilitres', 'Add', 'Subtract'],
  schema: z
    .object({
      waterL1A: z.number().int().min(1).max(8),
      waterMl1A: z.number().int().min(100).max(600).step(100),
      waterL1B: z.number().int().min(1).max(8),
      waterMl1B: z.number().int().min(50).max(350).step(50),
      waterL2A: z.number().int().min(3).max(8),
      waterMl2A: z.number().int().min(300).max(950).step(50),
      waterL2B: z.number().int().min(1).max(7),
      waterMl2B: z.number().int().min(100).max(900).step(100),
      waterMl3: z.number().int().min(100).max(900).step(50)
    })
    .refine(val => val.waterL2B < val.waterL2A, 'waterL2B must be less than waterL2A.')
    .refine(val => val.waterMl2B < val.waterMl2A, 'waterMl2B must be less than waterMl2A.'),
  simpleGenerator: () => {
    const waterL1A = randomIntegerInclusive(1, 8);
    const waterMl1A = randomIntegerInclusiveStep(100, 600, 100);
    const waterL1B = randomIntegerInclusive(1, 8);
    const waterMl1B = randomIntegerInclusiveStep(50, 350, 50);

    const waterL2A = randomIntegerInclusive(3, 8);
    const waterMl2A = randomIntegerInclusiveStep(300, 950, 50);
    const waterL2B = randomIntegerInclusive(1, waterL2A - 1);
    const waterMl2B = randomIntegerInclusiveStep(100, waterMl2A - 50, 100);

    const waterMl3 = randomIntegerInclusiveStep(100, 900, 50);

    return {
      waterL1A,
      waterMl1A,
      waterL1B,
      waterMl1B,
      waterL2A,
      waterMl2A,
      waterL2B,
      waterMl2B,
      waterMl3
    };
  },

  Component: props => {
    const {
      question: {
        waterL1A,
        waterMl1A,
        waterL1B,
        waterMl1B,
        waterL2A,
        waterMl2A,
        waterL2B,
        waterMl2B,
        waterMl3
      },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={[
          `${translate.answerSentences.xLYMl(
            waterL1A,
            waterMl1A
          )} ${ADD} ${translate.answerSentences.xLYMl(
            waterL1B,
            waterMl1B
          )} = ${translate.answerSentences.ansLAnsMl()}`,
          `${translate.answerSentences.xLYMl(
            waterL2A,
            waterMl2A
          )} ${SUB} ${translate.answerSentences.xLYMl(
            waterL2B,
            waterMl2B
          )} = ${translate.answerSentences.ansLAnsMl()}`,
          `${translate.answerSentences.xL(1)} ${SUB} ${translate.answerSentences.xMl(
            waterMl3
          )} = ${translate.answerSentences.ansMl()}`
        ]}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        testCorrect={[
          [(waterL1A + waterL1B).toString(), (waterMl1A + waterMl1B).toString()],
          [(waterL2A - waterL2B).toString(), (waterMl2A - waterMl2B).toString()],
          [(1000 - waterMl3).toString()]
        ]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aJL2',
  description: 'aJL2',
  keywords: ['Volume', 'Capacity', 'Litres', 'Millilitres', 'Add', 'Subtract'],
  schema: z
    .object({
      waterLA: z.number().int().min(3).max(8),
      waterMlA: z.number().int().min(300).max(950).step(50),
      waterLB: z.number().int().min(1).max(7),
      waterMlB: z.number().int().min(100).max(900).step(100)
    })
    .refine(val => val.waterLB < val.waterLA, 'waterLB must be less than waterLA.')
    .refine(val => val.waterMlB < val.waterMlA, 'waterMlB must be less than waterMlA.'),
  simpleGenerator: () => {
    const waterLA = randomIntegerInclusive(3, 8);
    const waterMlA = randomIntegerInclusiveStep(300, 950, 50);
    const waterLB = randomIntegerInclusive(1, waterLA - 1);
    const waterMlB = randomIntegerInclusiveStep(100, waterMlA - 50, 100);

    return {
      waterLA,
      waterMlA,
      waterLB,
      waterMlB
    };
  },

  Component: props => {
    const {
      question: { waterLA, waterMlA, waterLB, waterMlB },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        sentence={`${translate.answerSentences.xLYMl(
          waterLA,
          waterMlA
        )} ${SUB} ${translate.answerSentences.xLYMl(
          waterLB,
          waterMlB
        )} = ${translate.answerSentences.ansLAnsMl()}`}
        testCorrect={[(waterLA - waterLB).toString(), (waterMlA - waterMlB).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractCapacityAndVolume',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4, Question6]
});
export default SmallStep;
