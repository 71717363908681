// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import RepresentNumbersTo1000 from './1RepresentNumbersTo1000';
import PartitionNumbersTo1000 from './2PartitionNumbersTo1000';
import NumberLineTo1000 from './3NumberLineTo1000';
import Thousands from './4Thousands';
import RepresentNumbersTo10000 from './5RepresentNumbersTo10000';
import PartitionNumbersTo10000 from './6PartitionNumbersTo10000';
import FlexiblePartitioningOfNumbersTo10000 from './7FlexiblePartitioningOfNumbersTo10000';
import Find1To1000MoreOrLess from './8Find1To1000MoreOrLess';
import NumberLineTo10000 from './9NumberLineTo10000';
import EstimateOnANumberLineTo10000 from './10EstimateOnANumberLineTo10000';
import CompareNumbersTo10000 from './11CompareNumbersTo10000';
import OrderNumbersTo10000 from './12OrderNumbersTo10000';
import RomanNumerals from './13RomanNumerals';
import RoundToTheNearest10 from './14RoundToTheNearest10';
import RoundToTheNearest100 from './15RoundToTheNearest100';
import RoundToTheNearest1000 from './16RoundToTheNearest1000';
import RoundToTheNearest10100Or1000 from './17RoundToTheNearest10100Or1000';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 4],
  smallSteps: [
    RepresentNumbersTo1000, // Step 1
    PartitionNumbersTo1000, // Step 2
    NumberLineTo1000, // Step 3
    Thousands, // Step 4
    RepresentNumbersTo10000, // Step 5
    PartitionNumbersTo10000, // Step 6
    FlexiblePartitioningOfNumbersTo10000, // Step 7
    Find1To1000MoreOrLess, // Step 8
    NumberLineTo10000, // Step 9
    EstimateOnANumberLineTo10000, // Step 10
    CompareNumbersTo10000, // Step 11
    OrderNumbersTo10000, // Step 12
    RomanNumerals, // Step 13
    RoundToTheNearest10, // Step 14
    RoundToTheNearest100, // Step 15
    RoundToTheNearest1000, // Step 16
    RoundToTheNearest10100Or1000 // Step 17
  ]
});
export default Block;
