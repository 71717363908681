import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import TitleRow, { TitleStyleProps } from './TitleRow';
import { ActionPanelVariant } from './BaseLayout';
import { useContext, useMemo } from 'react';
import { colors } from 'common/src/theme/colors';
import { QuestionNavigationContext } from '../../SchemeOfLearning/Question';

type Props = TitleStyleProps & {
  /** Usually the question intruction, e.g. "Complete the sentences." */
  title?: string | JSX.Element;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * The attached action panel's variant, used to style accordingly.
   */
  actionPanelVariant: ActionPanelVariant;
  /**
   * Boolean to determine whether the content is shown in PDF or not, used to style accordingly.
   */
  displayMode?: 'digital' | 'pdf' | 'markscheme';
  children: ElementOrRenderFunction;
};

/**
 * Main zone where the question is asked.
 * Called "Question container" in figma.
 */
export default function MainPanel({
  title = '',
  children,
  style,
  containerStyle,
  titleStyle,
  titleTextStyle,
  titleFractionContainerStyle,
  titleFractionTextStyle,
  titleFractionDividerStyle,
  actionPanelVariant,
  displayMode = 'digital'
}: Props) {
  const styles = useStyles(actionPanelVariant, displayMode);

  const { QuizInformation } = useContext(QuestionNavigationContext);

  return (
    <View style={[styles.container, style]}>
      {QuizInformation}
      <TitleRow
        title={title}
        titleStyle={titleStyle}
        titleTextStyle={titleTextStyle}
        titleFractionContainerStyle={titleFractionContainerStyle}
        titleFractionTextStyle={titleFractionTextStyle}
        titleFractionDividerStyle={titleFractionDividerStyle}
      />
      <View style={[styles.contentContainer, containerStyle]}>
        {resolveElementOrRenderFunction(children)}
      </View>
    </View>
  );
}

function useStyles(
  actionPanelVariant: ActionPanelVariant,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: 'white',
          borderWidth: displayMode === 'digital' ? 3 : 0,
          borderRadius: 24,
          // Main Panel does not need a bottom-left border radius unless the Action Panel is an 'end' or 'endWide' variant,
          // or if in PDF mode.
          borderBottomLeftRadius:
            (actionPanelVariant === 'end' ||
              actionPanelVariant === 'endWide' ||
              actionPanelVariant === 'endMid') &&
            displayMode === 'digital'
              ? 24
              : 0,
          // If in PDF mode, the bottom-right border radius is needed; otherwise, the Action Panel will be touching this.
          borderBottomRightRadius: displayMode === 'digital' ? 0 : 24,
          // Main Panel does not need a top-right border radius if the Action Panel is an 'end' or 'endWide' variant
          // while not in PDF mode.
          borderTopRightRadius:
            (actionPanelVariant === 'end' ||
              actionPanelVariant === 'endWide' ||
              actionPanelVariant === 'endMid') &&
            displayMode === 'digital'
              ? 0
              : 24,
          borderColor: colors.prussianBlue,
          flexDirection: 'column'
        },

        contentContainer: {
          flex: 1,
          justifyContent: 'center',
          paddingTop: 24,
          paddingHorizontal: 32,
          paddingBottom: 32
        }
      }),
    [actionPanelVariant, displayMode]
  );
}
