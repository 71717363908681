// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import ShapesSameArea from './1ShapesSameArea';
import AreaAndPerimeter from './2AreaAndPerimeter';
import AreaOfATriangleCountingSquares from './3AreaOfATriangleCountingSquares';
import AreaOfARightAngledTriangle from './4AreaOfARightAngledTriangle';
import AreaOfAnyTriangle from './5AreaOfAnyTriangle';
import AreaOfAParallelogram from './6AreaOfAParallelogram';
import VolumeCountingCubes from './7VolumeCountingCubes';
import VolumeOfACuboid from './8VolumeOfACuboid';

const Block = newBlockContent({
  block: 'AreaPerimeterAndVolume',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    ShapesSameArea, // Step 1
    AreaAndPerimeter, // Step 2
    AreaOfATriangleCountingSquares, // Step 3
    AreaOfARightAngledTriangle, // Step 4
    AreaOfAnyTriangle, // Step 5
    AreaOfAParallelogram, // Step 6
    VolumeCountingCubes, // Step 7
    VolumeOfACuboid // Step 8
  ]
});
export default Block;
