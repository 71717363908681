import Svg, { Path } from 'react-native-svg';
import { Platform, View } from 'react-native';
import { PieChartColors, colors } from 'common/src/theme/colors';
import Animated, { useSharedValue, runOnJS, withTiming, Easing } from 'react-native-reanimated';
import {
  Gesture,
  GestureDetector,
  GestureHandlerRootView,
  TouchData
} from 'react-native-gesture-handler';
import { useCallback, useContext, useMemo } from 'react';
import { countRange, filledArray } from '../../../utils/collections';
import { withStateHOC } from '../../../stateTree';
import { noop } from 'common/src/utils/flowControl';
import { SetState } from '../../../utils/react';
import { ScaleFactorContext } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import { getRandomFromArray, seededRandom } from '../../../utils/random';

// need these to omit the collapsable prop in react-native-web
class PathOmitter extends Path {
  public override render() {
    const newProps = {
      ...this.props,
      collapsable: undefined
    };
    return <Path {...newProps} />;
  }
}

class SvgOmitter extends Svg {
  public override render() {
    const newProps = {
      ...this.props,
      collapsable: undefined
    };
    return <Svg {...newProps} />;
  }
}

const AnimatedSvg = Animated.createAnimatedComponent(SvgOmitter);
const AnimatedPath = Animated.createAnimatedComponent(PathOmitter);

type UserAnswer = number;

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer?: SetState<UserAnswer[]>;
  /** Number of slices of the pie chart */
  numberOfSlices: number;
  /** Radius of pie chart
   * Viewbox is relative to the radius of the pie chart
   */
  radius: number;
  /** Color can be set manually */
  options?: { color: string; label: string }[];
  outerLabels?: string[];
};

const PieChartInteractive = ({
  userAnswer = [],
  setUserAnswer = noop,
  numberOfSlices,
  radius,
  options,
  outerLabels
}: Props) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const displayMode = useContext(DisplayMode);
  const labelOffset = displayMode === 'digital' ? 60 : 120;
  radius = outerLabels ? radius - labelOffset : radius;

  const defaultColor = getRandomFromArray(PieChartColors, {
    random: seededRandom({ numberOfSlices, options })
  }) as string;

  const colorArray = options
    ? [{ color: 'white', label: '' }, ...options]
    : [
        { color: 'white', label: '' },
        { color: defaultColor, label: '' }
      ];
  /** The callback we want to call when a rectangle is clicked. In our case, we just keep a tally of clicks. */
  const updateColor = useCallback(
    (index: number) =>
      setUserAnswer(old => old.map((v, i) => (i === index ? (v + 1) % colorArray.length : v))),
    [colorArray.length, setUserAnswer]
  );
  /** The opacities of each rectangle. Just used to make the gesture feel a bit more responsive. */
  const animatedOpacity = useSharedValue(filledArray(1, numberOfSlices));
  const currentSegementGestured = useSharedValue<number | null>(null);

  // Calculate viewbox dimensions
  const viewboxWidth = 2 * radius + 10;
  const viewboxHeight = 2 * radius + 10;

  // Angle of each individual slice
  const angle = 360 / numberOfSlices;
  const centerX = viewboxWidth / 2;
  const centerY = viewboxHeight / 2;

  function getPoints(radius: number, angleInDeg: number, radialFactor: number = 1) {
    const radians = ((angleInDeg - 90) * Math.PI) / 180;
    return {
      x: centerX + radialFactor * radius * Math.cos(radians),
      y: centerY + radialFactor * radius * Math.sin(radians)
    };
  }

  const renderPieSlice = (): JSX.Element[] => {
    let startAngle = 0;
    const pieChart = [];
    // Loop to calculate and append segment paths to pieChart array
    for (let idx = 0; idx < numberOfSlices; idx++) {
      const endAngle = startAngle + angle;

      // Calculate start and end coordinates of the arc
      const { x: startX, y: startY } = getPoints(radius, startAngle);
      const { x: endX, y: endY } = getPoints(radius, endAngle);

      // Create SVG path for the pie slice
      const pieChartSection = `M${centerX},${centerY} L${startX},${startY} A${radius},${radius} 0 ${
        angle > 180 ? 1 : 0
      },1 ${endX},${endY} Z`;

      // Update startAngle for the next slice
      startAngle = endAngle;

      pieChart.push(
        <AnimatedPath
          key={`slice-${idx}`}
          d={pieChartSection}
          fill={displayMode === 'digital' ? colorArray[userAnswer[idx]].color : 'white'}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={displayMode === 'digital' ? 2 : 4}
          animatedProps={{
            opacity: animatedOpacity.value[idx]
          }}
        />
      );
    }

    return pieChart;
  };

  const renderInSegmentLabels = () => {
    return countRange(numberOfSlices).map((_val, idx) => {
      if (userAnswer[idx] === 0) {
        return;
      }
      const startAngle = angle * idx;
      const midAngle = startAngle + angle / 2;
      // get rough mid points minus 20 for letter
      const { x: xStart, y: yStart } = getPoints(radius, midAngle, 0.75);
      const labelIndex = userAnswer[idx];

      return (
        <View
          key={`label-${idx}`}
          pointerEvents="none"
          style={{
            position: 'absolute',
            width: viewboxWidth,
            height: viewboxHeight,
            left: xStart - viewboxWidth / 2,
            top: yStart - viewboxHeight / 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              fontSize: 32,
              textAlign: 'center'
            }}
            variant="WRN400"
          >
            {colorArray[labelIndex].label}
          </Text>
        </View>
      );
    });
  };

  const renderOuterLabels = () => {
    if (outerLabels === undefined) return;
    return outerLabels.map((val, idx) => {
      const startAngle = angle * idx;
      // if we have two labels at 0 position, one needs to be higher
      const yOffset = idx === outerLabels.length - 1 ? (displayMode === 'digital' ? 30 : 70) : 0;
      const radialFactor = displayMode === 'digital' ? 1.15 : 1.2;
      const { x: xStart, y: yStart } = getPoints(radius, startAngle, radialFactor);

      return (
        <View
          key={`outerlabel-${idx}`}
          pointerEvents="none"
          style={{
            position: 'absolute',
            width: viewboxWidth,
            height: viewboxHeight,
            left: xStart - viewboxWidth / 2,
            top: yStart - yOffset - viewboxHeight / 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              fontSize: displayMode === 'digital' ? 22 : 40,
              textAlign: 'center'
            }}
            variant="WRN400"
          >
            {val}
          </Text>
        </View>
      );
    });
  };

  const gesture = useMemo(() => {
    function getSegmentIndex(x: number, y: number) {
      'worklet';
      const PI2 = Math.PI * 2;
      const dx = x - centerX;
      const dy = y - centerY;
      const rr = radius * radius;
      // check that it is within the circle
      if (dx * dx + dy * dy > rr) {
        return null;
      }
      // Work out radians. The (...+ PI2) % PI2 ensures we are working in positives
      const pointAngleRad = (Math.atan2(dy, dx) + Math.PI / 2 + PI2) % PI2;
      const pointAngleDeg = (pointAngleRad * 180) / Math.PI;
      return Math.floor(pointAngleDeg / angle);
    }

    return (
      Gesture.Tap()
        // By default the gesture cancels if they hold down for too long.
        // We don't care about that so give them ages.
        .maxDuration(10000)
        .onBegin(e => {
          const x = Platform.OS === 'web' ? e.x / scaleFactor : e.x;
          const y = Platform.OS === 'web' ? e.y / scaleFactor : e.y;
          currentSegementGestured.value = getSegmentIndex(x, y);

          animatedOpacity.value = withTiming(
            animatedOpacity.value.map((o, i) => (i === currentSegementGestured.value ? 0.4 : o)),
            {
              duration: 5000,
              easing: Easing.linear
            }
          );
        })
        .onTouchesMove(e => {
          const touch = e.changedTouches.find(t => t.id === 0) as TouchData;
          const x = Platform.OS === 'web' ? touch.x / scaleFactor : touch.x;
          const y = Platform.OS === 'web' ? touch.y / scaleFactor : touch.y;
          if (
            currentSegementGestured.value !== null &&
            getSegmentIndex(x, y) !== currentSegementGestured.value
          ) {
            // Moved out of rectangle. Cancel early:
            animatedOpacity.value = withTiming(
              animatedOpacity.value.map((o, i) => (i === currentSegementGestured.value ? 1 : o)),
              {
                duration: 100,
                easing: Easing.linear
              }
            );
            currentSegementGestured.value = null;
          }
        })
        .onEnd((_, success) => {
          if (success && currentSegementGestured.value !== null) {
            runOnJS(updateColor)(currentSegementGestured.value!);
          }
        })
        .onFinalize(() => {
          animatedOpacity.value = withTiming(
            animatedOpacity.value.map((o, i) => (i === currentSegementGestured.value ? 1 : o)),
            {
              duration: 5000,
              easing: Easing.linear
            }
          );
          currentSegementGestured.value = null;
        })
    );
  }, [
    angle,
    animatedOpacity,
    centerX,
    centerY,
    currentSegementGestured,
    radius,
    scaleFactor,
    updateColor
  ]);

  return (
    <GestureHandlerRootView style={{ flex: 1, alignItems: 'center' }}>
      <GestureDetector gesture={gesture}>
        <View>
          <AnimatedSvg width={viewboxWidth} height={viewboxHeight}>
            {renderPieSlice()}
          </AnimatedSvg>
          {renderInSegmentLabels()}
          {outerLabels && renderOuterLabels()}
        </View>
      </GestureDetector>
    </GestureHandlerRootView>
  );
};

/** See {@link PieChartInteractive}. */
export const PieChartInteractiveWithState = withStateHOC(PieChartInteractive, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(0, props.numberOfSlices),
    testComplete: state => state.every(it => it !== 0)
  })
});

export default PieChartInteractive;
