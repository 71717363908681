// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import DecimalAndFractionEquivalents from './1DecimalAndFractionEquivalents';
import FractionsAsDivision from './2FractionsAsDivision';
import UnderstandPercentages from './3UnderstandPercentages';
import FractionsToPercentages from './4FractionsToPercentages';
import EquivalentFractionsDecimalsAndPercentages from './5EquivalentFractionsDecimalsAndPercentages';
import OrderFractionsDecimalsAndPercentages from './6OrderFractionsDecimalsAndPercentages';
import PercentageOfAnAmountOneStep from './7PercentageOfAnAmountOneStep';
import PercentageOfAnAmountMultiStep from './8PercentageOfAnAmountMultiStep';
import PercentagesMissingValues from './9PercentagesMissingValues';

const Block = newBlockContent({
  block: 'FractionsDecimalsAndPercentages',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    DecimalAndFractionEquivalents, // Step 1
    FractionsAsDivision, // Step 2
    UnderstandPercentages, // Step 3
    FractionsToPercentages, // Step 4
    EquivalentFractionsDecimalsAndPercentages, // Step 5
    OrderFractionsDecimalsAndPercentages, // Step 6
    PercentageOfAnAmountOneStep, // Step 7
    PercentageOfAnAmountMultiStep, // Step 8
    PercentagesMissingValues // Step 9
  ]
});
export default Block;
