import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from 'common/src/utils/random';
import { z } from 'zod';
import { range } from 'common/src/utils/collections';
import QF27bShortDivision from '../../../../components/question/questionFormats/QF27bShortDivision';
import {
  isDivisionExchangeAt,
  isDivisionExchangesOnlyAt,
  numbersDoNotHaveDivisionExchange
} from '../../../../utils/exchanges';
import { powersOfTenWordToPow } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aO6',
  description: 'aO6',
  keywords: ['2-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(11).max(99)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const dividend = randomIntegerInclusive(11, 99, {
      constraint: x => x % divisor === 0 && numbersDoNotHaveDivisionExchange(x, divisor)
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aO7',
  description: 'aO7',
  keywords: ['2-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(21).max(89)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const dividend = randomIntegerInclusive(21, 89, {
      constraint: x => x % divisor === 0 && isDivisionExchangeAt(x, divisor, 'tens') && x % 10 !== 0
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aO8',
  description: 'aO8',
  keywords: ['3-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(4),
      dividend: z.number().int().min(201).max(999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const dividend = randomIntegerInclusive(201, 999, {
      constraint: x =>
        x % divisor === 0 && x % 10 !== 0 && numbersDoNotHaveDivisionExchange(x, divisor)
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aO9',
  description: 'aO9',
  keywords: ['3-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(201).max(999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const exchangePosition = getRandomFromArray(['hundreds', 'tens'] as const);

    const divisor = randomIntegerInclusive(2, 4);

    const dividend = randomIntegerInclusive(201, 999, {
      constraint: x =>
        x % divisor === 0 &&
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, [exchangePosition])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aPa',
  description: 'aPa',
  keywords: ['3-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(201).max(999),
      quotientAnswerBoxPosition: z.enum(['tens', 'ones'])
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const dividend = randomIntegerInclusive(201, 999, {
      constraint: x => x % divisor === 0 && x % 10 !== 0 && isDivisionExchangeAt(x, divisor, 'tens')
    });

    const quotientAnswerBoxPosition = getRandomFromArray(['tens', 'ones'] as const);

    return { divisor, dividend, quotientAnswerBoxPosition };
  },
  Component: props => {
    const {
      question: { divisor, dividend, quotientAnswerBoxPosition },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        dividend={dividend}
        dividendMissingDigits={[2]}
        quotient={quotient}
        quotientMissingDigits={quotientAnswerBoxPosition === 'tens' ? [1] : [0]}
        showNumberExchanges
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aPb',
  description: 'aPb',
  keywords: ['3-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(201).max(999),
      quotientAnswerBoxPosition: z.enum(['hundreds', 'tens', 'ones']),
      dividendAnswerBoxPosition: z.enum(['hundreds', 'tens', 'ones'])
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.')
    .refine(val => val.dividend / val.divisor >= 100, 'dividend / divisor must be at least 100')
    .refine(
      val => val.quotientAnswerBoxPosition !== val.dividendAnswerBoxPosition,
      'quotientAnswerBoxPosition and dividendAnswerBoxPosition must be different.'
    ),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const dividend = randomIntegerInclusive(201, 999, {
      constraint: x =>
        x % divisor === 0 &&
        x % 10 !== 0 &&
        isDivisionExchangeAt(x, divisor, 'tens') &&
        x / divisor >= 100
    });

    const [quotientAnswerBoxPosition, dividendAnswerBoxPosition] = getRandomSubArrayFromArray(
      ['hundreds', 'tens', 'ones'] as const,
      2
    );

    return { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition };
  },
  Component: props => {
    const {
      question: { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition },
      translate
    } = props;

    const quotient = dividend / divisor;
    const quotientMissingDigit = powersOfTenWordToPow[quotientAnswerBoxPosition];

    const dividendMissingDigit = powersOfTenWordToPow[dividendAnswerBoxPosition];

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        divisorMissingDigits={[0]}
        dividend={dividend}
        dividendMissingDigits={[dividendMissingDigit]}
        quotient={quotient}
        quotientMissingDigits={[quotientMissingDigit]}
        showNumberExchanges
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ShortDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
