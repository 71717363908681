// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import YearsMonthsWeeksAndDays from './1YearsMonthsWeeksAndDays';
import HoursMinutesAndSeconds from './2HoursMinutesAndSeconds';
import ConvertBetweenAnalogueAndDigitalTimes from './3ConvertBetweenAnalogueAndDigitalTimes';
import ConvertToThe24HourClock from './4ConvertToThe24HourClock';
import ConvertFromThe24HourClock from './5ConvertFromThe24HourClock';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [5, 6],
  smallSteps: [
    YearsMonthsWeeksAndDays, // Step 1
    HoursMinutesAndSeconds, // Step 2
    ConvertBetweenAnalogueAndDigitalTimes, // Step 3
    ConvertToThe24HourClock, // Step 4
    ConvertFromThe24HourClock // Step 5
  ]
});
export default Block;
