import { TextStyle } from 'react-native';

type FontStyle = Pick<TextStyle, 'fontFamily' | 'fontWeight' | 'fontStyle'>;

/**
 * I want to be able to say:
 *
 * ```
 * {
 *   fontFamily: 'foo',
 *   fontWeight: '400',
 *   fontStyle: 'italic'
 * }
 * ```
 *
 * to declare my font. Unfortunately, some of the fonts we download have the weight and style built into the font
 * family. So we actually need something like:
 *
 * ```
 * {
 *   fontFamily: 'foo-RegularItalic'
 * }
 * ```
 *
 * This function turns the former into the latter, so you can write the style using the meta-family (e.g. 'foo')
 * instead of specific font families (e.g. 'foo-Regular'). (Meta-family is a term made up for this function.)
 */
export function resolveFont<S extends FontStyle>(style: S): S {
  const fontFamily = style.fontFamily;

  // Can't do anything if they don't provide a font family.
  if (fontFamily === undefined) {
    return style;
  }

  let metaFamily = metaFamilies.find(family => family.name === fontFamily);
  if (!metaFamily) {
    // Didn't recognize the font family. Maybe it was already bundled? Try again, unbundling them
    for (const testFamily of metaFamilies) {
      const unbundled = testFamily.unbundle(fontFamily);
      if (unbundled !== null) {
        // Found one. Replace the bundled fontFamily property with the unbundled style we just found.
        metaFamily = testFamily;
        delete style.fontFamily;
        style = { ...unbundled, ...style };
        break;
      }
    }
  }

  // If we still don't recognize the font family, we can't do anything.
  if (!metaFamily) {
    return style;
  }

  const family = metaFamily.bundle(
    resolveNormalAndBold(style.fontWeight ?? 'normal'),
    style.fontStyle ?? 'normal'
  );

  // Tweak the given style to use the real font.
  const newStyle = { ...style };
  newStyle.fontFamily = family;
  delete newStyle.fontWeight;
  delete newStyle.fontStyle;
  return newStyle;
}

// Weight names as given in files
const weightNumberToName = {
  '100': 'Thin',
  '200': 'ExtraLight',
  '300': 'Light',
  '400': 'Regular',
  '500': 'Medium',
  '600': 'SemiBold',
  '700': 'Bold',
  '800': 'ExtraBold',
  '900': 'Black'
} as const;
type WeightNumber = keyof typeof weightNumberToName;
type WeightName = (typeof weightNumberToName)[WeightNumber];
const weightNameToNumber = Object.fromEntries(
  Object.entries(weightNumberToName).map(([k, v]) => [v, k])
) as Record<WeightName, WeightNumber>;

// Style names
type Style = 'normal' | 'italic';

type MetaFamily = {
  name: string;
  bundle: (fontWeight: WeightNumber, fontStyle: Style) => string;
  /** Null indicates it wasn't this family. */
  unbundle: (bundledFamily: string) => { fontWeight: WeightNumber; fontStyle: Style } | null;
};

/** 'normal' is interpreted as 400 weight, 'bold' as 700 weight. */
function resolveNormalAndBold(weight: WeightNumber | 'normal' | 'bold'): WeightNumber {
  return weight === 'normal' ? '400' : weight === 'bold' ? '700' : weight;
}

/** Mapping from family to the actual font asset files we have. */
const metaFamilies: MetaFamily[] = [
  {
    name: 'Biotif',
    bundle: (weight, style) =>
      `Biotif-${weightNumberToName[weight]}${style === 'italic' ? 'Italic' : ''}`,
    unbundle: bundledFamily => {
      const match = bundledFamily.match(/^Biotif-(.+?)(Italic)?$/);
      if (match === null) {
        return null;
      }
      const [_, weightName, style] = match;
      return {
        fontWeight: weightNameToNumber[weightName as WeightName],
        fontStyle: style ? 'italic' : 'normal'
      };
    }
  },
  {
    name: 'Roboto',
    bundle: (weight, style) =>
      `Roboto_${weight}${weightNumberToName[weight]}${style === 'italic' ? '_Italic' : ''}`,
    unbundle: bundledFamily => {
      const match = bundledFamily.match(/^Roboto_\d*(.+?)(_Italic)?$/);
      if (match === null) {
        return null;
      }
      const [_, weightName, style] = match;
      return {
        fontWeight: weightNameToNumber[weightName as WeightName],
        fontStyle: style ? 'italic' : 'normal'
      };
    }
  },
  {
    name: 'Libre Baskerville',
    bundle: (weight, style) =>
      `LibreBaskerville_${weight}${weightNumberToName[weight]}${
        style === 'italic' ? '_Italic' : ''
      }`,
    unbundle: bundledFamily => {
      const match = bundledFamily.match(/^LibreBaskerville_\d*(.+?)(_Italic)?$/);
      if (match === null) {
        return null;
      }
      const [_, weightName, style] = match;
      return {
        fontWeight: weightNameToNumber[weightName as WeightName],
        fontStyle: style ? 'italic' : 'normal'
      };
    }
  },
  {
    name: 'White_Rose_Noto',
    bundle: (weight, style) =>
      `White_Rose_Noto-${weightNumberToName[weight]}${style === 'italic' ? 'Italic' : ''}`,
    unbundle: bundledFamily => {
      const match = bundledFamily.match(/^White_Rose_Noto-(.+?)(Italic)?$/);
      if (match === null) {
        return null;
      }
      const [_, weightName, style] = match;
      return {
        fontWeight: weightNameToNumber[weightName as WeightName],
        fontStyle: style ? 'italic' : 'normal'
      };
    }
  }
];
