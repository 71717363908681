import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { numberEnum } from '../../../../utils/zod';
import { convertUnitsSuffix } from '../../../../utils/unitConversion';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from 'common/src/components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aGS',
  description: 'aGS',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Conversion', 'Length'],
  schema: z.object({
    arrayOfRandomBooleans: z.array(z.boolean()).length(4)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const arrayOfRandomBooleans = range(0, 3).map(() => getRandomBoolean());
    return {
      arrayOfRandomBooleans
    };
  },
  Component: props => {
    const {
      translate,
      question: { arrayOfRandomBooleans }
    } = props;

    const answerOptions = shuffle(['1', '10', '100'], { random: seededRandom(props.question) });

    const sentences = [
      arrayOfRandomBooleans[0]
        ? {
            sentence: translate.answerSentences.thereAreAnsMillimetresIn1Cm(),
            answer: '10'
          }
        : {
            sentence: translate.answerSentences.thereAre10MillimetresInAnsCentimetre(),
            answer: '1'
          },
      arrayOfRandomBooleans[1]
        ? {
            sentence: translate.answerSentences.thereAreAnsCmInNumM(1),
            answer: '100'
          }
        : {
            sentence: translate.answerSentences.thereAre100CentimetresInAnsMetre(),
            answer: '1'
          },
      arrayOfRandomBooleans[2]
        ? {
            sentence: translate.answerSentences.ansMillimetresIsEqualTo1Centimetres(),
            answer: '10'
          }
        : {
            sentence: translate.answerSentences.tenMillimetresIsEqualToAnsCentimetre(),
            answer: '1'
          },
      arrayOfRandomBooleans[3]
        ? {
            sentence: translate.answerSentences.ansCentimetresIsEqualTo1Metre(),
            answer: '100'
          }
        : {
            sentence: translate.answerSentences.hundredCentimetresIsEqualToAnsMetre(),
            answer: '1'
          }
    ];

    shuffle(sentences, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        title={translate.instructions.completeSentences()}
        actionPanelVariant="end"
        questionHeight={1200}
        items={answerOptions}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        moveOrCopy="copy"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGT',
  description: 'aGT',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Order', 'Length'],
  schema: z
    .object({
      lengthsArray: z.array(z.number().int().min(1).max(99)).length(6),
      measurement: z.enum(['mm', 'cm', 'm']),
      orderBy: z.enum(['ascending', 'descending'])
    })
    .refine(val => arrayHasNoDuplicates(val.lengthsArray), 'there are no duplicate lengths'),
  simpleGenerator: () => {
    const lengthsArray = randomUniqueIntegersInclusive(1, 99, 6);
    const measurement = getRandomFromArray(['mm', 'cm', 'm'] as const);
    const orderBy = getRandomFromArray(['ascending', 'descending'] as const);

    return { lengthsArray, measurement, orderBy };
  },
  Component: props => {
    const {
      question: { lengthsArray, measurement, orderBy },
      translate
    } = props;

    const translatedMeasurement = (() => {
      switch (measurement) {
        case 'mm':
          return translate.units.mm();
        case 'cm':
          return translate.units.cm();
        default:
          return translate.units.m();
      }
    })();

    const items = shuffle(
      countRange(6).map(idx => {
        return {
          sentence: `${lengthsArray[idx].toLocaleString()} ${translatedMeasurement}`,
          value: lengthsArray[idx]
        };
      }),
      { random: seededRandom(props.question) }
    );

    const correctOrder = sortNumberArray(lengthsArray, orderBy);

    return (
      <QF5DragOrderHorizontal
        title={
          orderBy === 'ascending'
            ? translate.instructions.dragCardsToOrderLengthsStartWithShortest()
            : translate.instructions.dragCardsToOrderLengthsStartWithLongest()
        }
        pdfTitle={
          orderBy === 'ascending'
            ? translate.instructions.useCardsToOrderLengthsStartWithShortest()
            : translate.instructions.useCardsToOrderLengthsStartWithLongest()
        }
        testCorrect={correctOrder}
        items={items.map(({ sentence, value }) => {
          return {
            component: sentence,
            value
          };
        })}
        pdfItemVariant="pdfSquare"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGU',
  description: 'aGU',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Compare', 'Length', 'Inequalities'],
  schema: z.object({
    number1: numberEnum([1, 10, 100]),
    number2: numberEnum([1, 10, 100]),
    number3: numberEnum([1, 10, 100]),
    number4: numberEnum([1, 10, 100]),
    number5: numberEnum([1, 10, 100]),
    number6: numberEnum([1, 10, 100]),
    number1Measurement: z.enum(['mm', 'm', 'cm']),
    number2Measurement: z.enum(['mm', 'm', 'cm']),
    number3Measurement: z.enum(['mm', 'm', 'cm']),
    number4Measurement: z.enum(['mm', 'm', 'cm']),
    number5Measurement: z.enum(['mm', 'm', 'cm']),
    number6Measurement: z.enum(['mm', 'm', 'cm'])
  }),
  simpleGenerator: () => {
    const [number1, number2, number3, number4, number5, number6] = countRange(6).map(() =>
      getRandomFromArray([1, 10, 100] as const)
    );

    const [
      number1Measurement,
      number2Measurement,
      number3Measurement,
      number4Measurement,
      number5Measurement,
      number6Measurement
    ] = countRange(6).map(() => getRandomFromArray(['mm', 'm', 'cm'] as const));
    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number1Measurement,
      number2Measurement,
      number3Measurement,
      number4Measurement,
      number5Measurement,
      number6Measurement
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number1Measurement,
        number2Measurement,
        number3Measurement,
        number4Measurement,
        number5Measurement,
        number6Measurement
      },
      translate,
      displayMode
    } = props;

    const answers = [
      convertUnitsSuffix(number1, number1Measurement, 'mm').value,
      convertUnitsSuffix(number2, number2Measurement, 'mm').value,
      convertUnitsSuffix(number3, number3Measurement, 'mm').value,
      convertUnitsSuffix(number4, number4Measurement, 'mm').value,
      convertUnitsSuffix(number5, number5Measurement, 'mm').value,
      convertUnitsSuffix(number6, number6Measurement, 'mm').value
    ];

    const translateMeasurements = (measurement: 'mm' | 'm' | 'cm') => {
      switch (measurement) {
        case 'mm':
          return translate.units.mm();
        case 'cm':
          return translate.units.cm();
        default:
          return translate.units.m();
      }
    };

    const sentences = [
      {
        lhs: `${number1.toLocaleString()} ${number1Measurement}`,
        rhs: `${number2.toLocaleString()} ${translateMeasurements(number2Measurement)}`,
        correctAnswer: answers[0] > answers[1] ? '>' : answers[0] === answers[1] ? '=' : '<'
      },
      {
        lhs: `${number3.toLocaleString()} ${number3Measurement}`,
        rhs: ` ${number4.toLocaleString()} ${translateMeasurements(number4Measurement)}`,
        correctAnswer: answers[2] > answers[3] ? '>' : answers[2] === answers[3] ? '=' : '<'
      },
      {
        lhs: `${number5.toLocaleString()} ${number5Measurement}`,
        rhs: `${number6.toLocaleString()} ${translateMeasurements(number6Measurement)}`,
        correctAnswer: answers[4] > answers[5] ? '>' : answers[4] === answers[5] ? '=' : '<'
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareLengths()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteSentences()}
        actionPanelVariant="end"
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
        itemVariant="square"
        statementStyle={{ justifyContent: 'center' }}
        statements={sentences.map(({ lhs, rhs, correctAnswer }) => ({
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 200, alignItems: 'flex-end' }}>
              <TextStructure sentence={lhs} />
            </View>
          ),
          rhsComponent: (
            <View
              style={{ width: displayMode === 'digital' ? 150 : 200, alignItems: 'flex-start' }}
            >
              <TextStructure sentence={rhs} />
            </View>
          ),
          correctAnswer
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aGV',
  description: 'aGV',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Compare', 'Length'],
  schema: z
    .object({
      arrayOfMeasurementsObjects: z
        .array(z.object({ numberInMm: z.number(), string: z.string(), measurement: z.string() }))
        .length(8),
      correctAnswersIndexes: z.array(z.number()).min(1).max(3),
      name: nameSchema
    })
    .refine(
      val => arrayHasNoDuplicates(val.arrayOfMeasurementsObjects),
      'there are no duplicate lengths'
    )
    .refine(
      val => val.correctAnswersIndexes.length > 1 && val.correctAnswersIndexes.length < 4,
      'there should be between 1 and 3 correct answers to choose from'
    ),
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const indexToHide = getRandomFromArray([0, 1, 2, 3] as const);

    const { sortedOptions, correctAnswersIndexes } = rejectionSample(
      () => {
        const arrayOfOptionObjects = range(0, 7).map(() => {
          const number = randomIntegerInclusive(1, 9);
          const measurement = getRandomFromArray(['mm', 'cm', 'm']);
          return {
            numberInMm: convertUnitsSuffix(number, measurement, 'mm').value,
            measurement,
            string: `${number.toLocaleString()}`
          };
        });
        const contentOptions = [
          arrayOfOptionObjects[0],
          arrayOfOptionObjects[1],
          arrayOfOptionObjects[2],
          arrayOfOptionObjects[3]
        ].sort((a, b) =>
          ascendingOrDescending === 'ascending'
            ? a.numberInMm - b.numberInMm
            : b.numberInMm - a.numberInMm
        );

        contentOptions[indexToHide].string = '?';

        const sortedOptions = [
          ...contentOptions,
          arrayOfOptionObjects[4],
          arrayOfOptionObjects[5],
          arrayOfOptionObjects[6],
          arrayOfOptionObjects[7]
        ];

        const remainingOptions = [
          arrayOfOptionObjects[4],
          arrayOfOptionObjects[5],
          arrayOfOptionObjects[6],
          arrayOfOptionObjects[7]
        ];

        const correctAnswersIndexes: number[] = [];

        remainingOptions.forEach(({ numberInMm }, index) => {
          const sorted = sortNumberArray(
            [
              arrayOfOptionObjects[0].numberInMm,
              arrayOfOptionObjects[1].numberInMm,
              arrayOfOptionObjects[2].numberInMm,
              arrayOfOptionObjects[3].numberInMm
            ],
            ascendingOrDescending
          );

          sorted[indexToHide] = numberInMm;

          const newSorted = sortNumberArray(sorted, ascendingOrDescending);

          if (arraysHaveSameContents(sorted, newSorted)) {
            correctAnswersIndexes.push(4 + index);
          }
        });

        return { sortedOptions, correctAnswersIndexes };
      },

      ({ sortedOptions, correctAnswersIndexes }) =>
        arrayHasNoDuplicates(sortedOptions.map(el => el.numberInMm)) &&
        correctAnswersIndexes.length > 1 &&
        correctAnswersIndexes.length < 4
    );

    const name = getRandomName();

    return { arrayOfMeasurementsObjects: sortedOptions, name, indexToHide, correctAnswersIndexes };
  },
  Component: props => {
    const {
      question: { arrayOfMeasurementsObjects, name, correctAnswersIndexes },
      translate
    } = props;

    const translatedMeasureObjects = arrayOfMeasurementsObjects.map(object => {
      if (object.string !== '?') {
        const translatedUnits =
          object.measurement === 'mm'
            ? translate.units.mm()
            : object.measurement === 'cm'
            ? translate.units.cm()
            : translate.units.m();
        return { ...object, string: `${object.string} ${translatedUnits}` };
      }
      return object;
    });

    const [A, B, C, D] = countRange(4).map(idx => {
      return {
        component: <Text variant="WRN700">{translatedMeasureObjects[idx + 4].string}</Text>,
        value: translatedMeasureObjects[idx + 4].string,
        correct: correctAnswersIndexes.includes(idx + 4)
      };
    });

    const statements = shuffle([A, B, C, D], { random: seededRandom(props.question) });

    const itemOptions = countRange(4).map(idx => {
      return {
        value: translatedMeasureObjects[idx].string
      };
    });

    const correctAnswers = statements.filter(it => it.correct).map(it => it.value);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterHasPutSomeLengthsInOrderWhatCouldTheMissingMeasurementBe(
          { character: name }
        )}
        pdfTitle={`${translate.instructions.characterHasPutSomeLengthsInOrderWhatCouldTheMissingMeasurementBe(
          { character: name }
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={userAnswer => correctAnswers.includes(userAnswer[0])}
        numItems={4}
        customMarkSchemeAnswer={{
          answerToDisplay: [correctAnswers[0]],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        Content={({ dimens }) => (
          <ContentBox
            containerStyle={{
              width: dimens.width / 2,
              height: dimens.height - 50,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around'
            }}
          >
            {itemOptions.map((item, idx) => (
              <View key={idx}>
                <Text style={{ fontSize: 32 }}>{item.value}</Text>
              </View>
            ))}
          </ContentBox>
        )}
        renderItems={statements}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGW',
  description: 'aGW',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Order', 'Length'],
  schema: z.object({
    length1: z.number().int().min(1).max(9),
    length2: z.number().int().min(1).max(9),
    shortestOrLongestFirst: z.enum(['shortest', 'longest']),
    number2Measurement: z.enum(['mm', 'm', 'cm'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [length1, length2] = randomUniqueIntegersInclusive(1, 9, 2);

    const shortestOrLongestFirst = getRandomFromArray(['shortest', 'longest'] as const);
    const number2Measurement = getRandomFromArray(['mm', 'm', 'cm'] as const);

    return {
      length1,
      length2,
      shortestOrLongestFirst,
      number2Measurement
    };
  },
  Component: props => {
    const {
      question: { length1, length2, shortestOrLongestFirst, number2Measurement },
      translate
    } = props;

    const length2StringAndValue = (() => {
      switch (number2Measurement) {
        case 'mm':
          return { string: translate.units.numberOfMm(length2), valueInMm: length2 };
        case 'cm':
          return { string: translate.units.numberOfCm(length2), valueInMm: length2 * 10 };
        default:
          return { string: translate.units.numberOfM(length2), valueInMm: length2 * 100 };
      }
    })();

    const items = shuffle(
      [
        {
          string: translate.units.numberOfMm(length1),
          valueInMm: length1
        },
        {
          string: translate.units.numberOfCm(length1),
          valueInMm: length1 * 10
        },
        {
          string: translate.units.numberOfM(length1),
          valueInMm: length1 * 100
        },
        length2StringAndValue
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.dragCardsToOrderLengthsStartWithShortest()
            : translate.instructions.dragCardsToOrderLengthsStartWithLongest()
        }
        pdfTitle={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.useCardsToOrderLengthsStartWithShortest()
            : translate.instructions.useCardsToOrderLengthsStartWithLongest()
        }
        testCorrect={sortNumberArray(
          items.map(measurement => measurement.valueInMm),
          shortestOrLongestFirst === 'shortest' ? 'ascending' : 'descending'
        )}
        items={items.map(measurement => ({
          value: measurement.valueInMm,
          component: measurement.string
        }))}
        topLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Shortest()
            : translate.misc.Longest()
        }
        bottomLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Longest()
            : translate.misc.Shortest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGX',
  description: 'aGX',
  keywords: ['Millimetres', 'Centimetres', 'Metres', 'Metric', 'Order', 'Length'],
  schema: z.object({
    length1: z.number().int().min(1).max(9),
    length2: z.number().int().min(1).max(9),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(1).max(9),
    number6: z.number().int().min(110).max(990),
    shortestOrLongestFirst: z.enum(['shortest', 'longest']),
    measurement: z.enum(['mm', 'cm'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [length1, length2, number3] = randomUniqueIntegersInclusive(1, 9, 3);
    const number4 = randomIntegerInclusive(1, 9);
    const number6 = randomIntegerInclusive(110, 990, {
      constraint: x => x !== length1 * 100 && x !== length2 * 100
    });

    const shortestOrLongestFirst = getRandomFromArray(['shortest', 'longest'] as const);
    const measurement = getRandomFromArray(['mm', 'cm'] as const);

    return {
      length1,
      length2,
      number3,
      number4,
      number6,
      shortestOrLongestFirst,
      measurement
    };
  },
  Component: props => {
    const {
      question: {
        length1,
        length2,
        number3,
        number4,
        number6,
        shortestOrLongestFirst,
        measurement
      },
      translate
    } = props;

    const number5 = number3 * 10 + number4;

    const length2StringAndValue = (() => {
      switch (measurement) {
        case 'mm':
          return { string: translate.units.numberOfMm(number6), valueInMm: number6 };
        default:
          return {
            string: translate.units.numberOfCm(number6),
            valueInMm: convertUnitsSuffix(number6, 'cm', 'mm').value
          };
      }
    })();

    const items = shuffle(
      [
        {
          string: translate.units.numberOfM(length1),
          valueInMm: convertUnitsSuffix(length1, 'm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(length2),
          valueInMm: convertUnitsSuffix(length2, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfMm(number5),
          valueInMm: number5
        },
        length2StringAndValue
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.dragCardsToOrderLengthsStartWithShortest()
            : translate.instructions.dragCardsToOrderLengthsStartWithLongest()
        }
        pdfTitle={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.useCardsToOrderLengthsStartWithShortest()
            : translate.instructions.useCardsToOrderLengthsStartWithLongest()
        }
        testCorrect={sortNumberArray(
          items.map(measurement => measurement.valueInMm),
          shortestOrLongestFirst === 'shortest' ? 'ascending' : 'descending'
        )}
        items={items.map(measurement => ({
          value: measurement.valueInMm,
          component: measurement.string
        }))}
        topLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Shortest()
            : translate.misc.Longest()
        }
        bottomLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Longest()
            : translate.misc.Shortest()
        }
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MetresCentimetresAndMillimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
