import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { range } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import {
  algebraicSymbolSchema,
  algebraicSymbols,
  getAlgebraicSymbol
} from '../../../../utils/algebraicSymbols';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { ADD, ALGEBRAIC_A, SUB } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { MarkupAssets } from '../../../../markup';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUq',
  description: 'aUq',
  keywords: ['Variable', 'Constant', 'Algebra'],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    cubes: z.number().int().min(2).max(5),
    cubeValue: z.number().int().min(2).max(10),
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 5);
    const cubes = randomIntegerInclusive(2, 5);
    const cubeValue = randomIntegerInclusive(2, 10);
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);
    return { cubes, ones, cubeValue, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { cubes, ones, cubeValue, cubeColour, onesCubeColur },
      translate
    } = props;
    const total = cubes * cubeValue + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`one_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));

    const arrayOfObjects = [...arrayOfCubes, ...arrayOfOnes];

    return (
      <MarkupAssets
        elements={{
          cube: <AssetSvg key="cube" name={cubesSvgName} height={70} />,
          ones: <AssetSvg key="ones" name={onesSvgName} height={70} />
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.workOutValueOfLinkingCube()}
          Content={({ dimens }) => (
            <View
              style={{
                ...dimens
              }}
            >
              <ContentBox
                containerStyle={{
                  flexDirection: 'row',
                  alignSelf: 'flex-end',
                  marginBottom: 70
                }}
              >
                <TextStructure sentence={`<asset name='ones'/> = ${(1).toLocaleString()}`} />
              </ContentBox>
              <View style={{ alignItems: 'center' }}>
                <View style={{ width: arrayOfObjects.length * 80 }}>
                  <BarModelCurlyBrace braceText={total.toLocaleString()} />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10
                  }}
                >
                  {arrayOfObjects}
                </View>
              </View>
            </View>
          )}
          sentenceStyle={{ justifyContent: 'center' }}
          sentence={`<asset name='cube'/>  = <ans/>`}
          testCorrect={[cubeValue.toLocaleString()]}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aUr',
  description: 'aUr',
  keywords: ['Solve', 'Value'],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    cubes: z.number().int().min(2).max(5),
    cubeValue: z.number().int().min(2).max(6),
    symbol: algebraicSymbolSchema,
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 5);
    const cubes = randomIntegerInclusive(2, 5);
    const cubeValue = randomIntegerInclusive(2, 6);

    const symbol = getAlgebraicSymbol();

    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return { symbol, cubes, ones, cubeValue, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { symbol, cubes, ones, cubeValue, cubeColour, onesCubeColur },
      translate
    } = props;
    const total = cubes * cubeValue + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`one_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));

    const arrayOfObjects = [...arrayOfCubes, ...arrayOfOnes];
    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutValueOfLinkingCube()}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-around' }}>
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'flex-end'
              }}
            >
              <AssetSvg name={cubesSvgName} width={70} />
              <Text style={{ fontSize: 32, paddingRight: 30 }}>{` = ${symbol}`}</Text>
              <AssetSvg name={onesSvgName} width={70} />
              <Text style={{ fontSize: 32 }}>{` = ${(1).toLocaleString()}`}</Text>
            </ContentBox>
            <View style={{ alignItems: 'center' }}>
              <View style={{ width: arrayOfObjects.length * 80 }}>
                <BarModelCurlyBrace braceText={total.toLocaleString()} />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 10
                }}
              >
                {arrayOfObjects}
              </View>
            </View>
          </View>
        )}
        sentence={`${symbol} = <ans/>`}
        testCorrect={[cubeValue.toString()]}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUs',
  description: 'aUs',
  keywords: ['Solve', 'Equation', 'Bar model'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(5),
    var1: z.number().int().min(1).max(10),
    number: z.number().int().min(1).max(10),
    combinedBar: z.boolean(),
    symbol: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 5);
    const var1 = randomIntegerInclusive(1, 10);
    const number = randomIntegerInclusive(1, 10);
    const combinedBar = getRandomBoolean();
    const symbol = getAlgebraicSymbol();
    return { symbol, multiplier, var1, number, combinedBar };
  },
  Component: props => {
    const {
      question: { symbol, multiplier, var1, number, combinedBar },
      translate
    } = props;
    const total = multiplier * var1 + number;

    const variableBar = combinedBar ? [multiplier * var1] : range(1, multiplier).map(_ => var1);
    const variableLabel = combinedBar
      ? [`${multiplier.toLocaleString()}${symbol}`]
      : range(1, multiplier).map(_ => symbol);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheValueOf(symbol)}
        Content={({ dimens }) => (
          <BarModel
            dimens={dimens}
            total={total}
            numbers={[[total], [...variableBar, number]]}
            strings={[[total.toLocaleString()], [...variableLabel, number.toLocaleString()]]}
            sameRowColor
          />
        )}
        sentence={`${symbol} = <ans/>`}
        testCorrect={[var1.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUt',
  description: 'aUt',
  keywords: ['Equation', 'Solve'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    var1: z.number().int().min(1).max(6),
    var2: z.number().int().min(1).max(6),
    var3: z.number().int().min(1).max(6),
    var4: z.number().int().min(1).max(6),
    multiplier1: z.number().int().min(2).max(10),
    multiplier2: z.number().int().min(2).max(10),
    multiplier3: z.number().int().min(2).max(10),
    multiplier4: z.number().int().min(2).max(10),
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(1).max(10),
    number3: z.number().int().min(1).max(10),
    number4: z.number().int().min(1).max(60)
  }),
  simpleGenerator: () => {
    const symbol = getAlgebraicSymbol();

    const [var1, var2, var3, var4] = randomUniqueIntegersInclusive(1, 6, 4);

    const multiplier1 = randomIntegerInclusive(2, 10);
    const multiplier2 = randomIntegerInclusive(2, 10);
    const multiplier3 = randomIntegerInclusive(2, 10);
    const multiplier4 = randomIntegerInclusive(2, 10);

    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(1, 10);
    const number3 = randomIntegerInclusive(1, 10);
    const number4 = randomIntegerInclusive(1, multiplier4 * var4);

    return {
      symbol,
      var1,
      var2,
      var3,
      var4,
      multiplier1,
      multiplier2,
      multiplier3,
      multiplier4,
      number1,
      number2,
      number3,
      number4
    };
  },
  Component: props => {
    const {
      question: {
        symbol,
        var1,
        var2,
        var3,
        var4,
        multiplier1,
        multiplier2,
        multiplier3,
        multiplier4,
        number1,
        number2,
        number3,
        number4
      },
      translate
    } = props;

    const total1 = multiplier1 * var1 + number1;
    const total2 = multiplier2 * var2 + number2;
    const total3 = multiplier3 * var3 + number3;
    const total4 = multiplier4 * var4 - number4;

    const statements = shuffle(
      [
        {
          sentence: `${total1} = ${multiplier1}${symbol} ${ADD} ${number1}        ${symbol} = <ans/>`,
          correctAnswer: var1
        },
        {
          sentence: `${number2} ${ADD} ${multiplier2}${symbol} = ${total2}        ${symbol} = <ans/>`,
          correctAnswer: var2
        },
        {
          sentence: `${number3} = ${total3} ${SUB} ${multiplier3}${symbol}        ${symbol} = <ans/>`,
          correctAnswer: var3
        },
        {
          sentence: `${multiplier4}${symbol} ${SUB} ${number4} = ${total4}        ${symbol} = <ans/>`,
          correctAnswer: var4
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsSolveEquations()}
        pdfTitle={translate.instructions.useCardsSolveEquations()}
        items={[`${var1}`, `${var2}`, `${var3}`, `${var4}`]}
        sentences={statements.map(({ sentence }) => sentence)}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        actionPanelVariant="end"
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
        pdfSentencesStyle={{ alignItems: 'flex-end' }}
        pdfLayout={'itemsRight'}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aUu',
  description: 'aUu',
  keywords: ['Equation', 'Solve'],
  schema: z.object({
    symbol1: algebraicSymbolSchema,
    symbol2: algebraicSymbolSchema,
    symbol3: algebraicSymbolSchema,
    var1: z.number().int().min(1).max(6),
    var2: z.number().int().min(1).max(6),
    var3: z.number().int().min(1).max(6),
    multiplier1: z.number().int().min(2).max(10),
    multiplier2: z.number().int().min(2).max(10),
    multiplier3: z.number().int().min(2).max(10),
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(1).max(10),
    number3: z.number().int().min(1).max(60)
  }),
  simpleGenerator: () => {
    const [symbol1, symbol2, symbol3] = getRandomSubArrayFromArray(algebraicSymbols, 3);

    const [var1, var2, var3] = randomUniqueIntegersInclusive(1, 6, 3);

    const multiplier1 = randomIntegerInclusive(2, 10);
    const multiplier2 = randomIntegerInclusive(2, 10);
    const multiplier3 = randomIntegerInclusive(2, 10);

    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(1, 10);
    const number3 = randomIntegerInclusive(1, multiplier3 * var3);

    return {
      symbol1,
      symbol2,
      symbol3,
      var1,
      var2,
      var3,
      multiplier1,
      multiplier2,
      multiplier3,
      number1,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: {
        symbol1,
        symbol2,
        symbol3,
        var1,
        var2,
        var3,
        multiplier1,
        multiplier2,
        multiplier3,
        number1,
        number2,
        number3
      },
      translate
    } = props;

    const total1 = multiplier1 * var1 + number1;
    const total2 = multiplier2 * var2 + number2;
    const total3 = multiplier3 * var3 - number3;

    const statements = shuffle(
      [
        {
          sentence: `${multiplier1}${symbol1} ${ADD} ${number1} = ${total1}        ${symbol1} = <ans/>`,
          correctAnswer: var1
        },
        {
          sentence: `${total2} = ${number2} ${ADD} ${multiplier2}${symbol2}        ${symbol2} = <ans/>`,
          correctAnswer: var2
        },
        {
          sentence: `${multiplier3}${symbol3} ${SUB} ${number3} = ${total3}         ${symbol3} = <ans/>`,
          correctAnswer: var3
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.solveEquations()}
        sentences={statements.map(({ sentence }) => sentence)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUv',
  description: 'aUv',
  keywords: ['Perimeter', 'Equation', 'Solve'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    shape: z.enum(['Rectangle', 'Trapezium', 'Triangle']),
    varValue: z.number().int().min(1).max(6),
    varMultiplier: z.number().int().min(1).max(4),
    width: z.number().int().min(3).max(7),
    width2: z.number().int().min(4).max(8)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Rectangle', 'Trapezium', 'Triangle'] as const);
    const symbol = getAlgebraicSymbol();

    const varValue = randomIntegerInclusive(1, 6);
    const varMultiplier = randomIntegerInclusive(1, 4, {
      constraint: x => (symbol === ALGEBRAIC_A ? x !== 1 : true)
    });

    const width = randomIntegerInclusive(3, 7);
    const width2 = randomIntegerInclusive(width + 1, 8);

    return { shape, symbol, varValue, varMultiplier, width, width2 };
  },
  Component: props => {
    const {
      question: { shape, symbol, varValue, varMultiplier, width, width2 },
      translate
    } = props;
    let perimeter = 0;
    let labels: string[];

    switch (shape) {
      case 'Rectangle': {
        perimeter = (width + varMultiplier * varValue) * 2;
        labels = [`${varMultiplier.toLocaleString()}${symbol}`, `${width.toString()}`];
        break;
      }
      case 'Trapezium': {
        perimeter = varMultiplier * varValue * 2 + (width + width2);
        labels = [
          `${width.toString()}`,
          `${varMultiplier.toLocaleString()}${symbol}`,
          `${width2.toString()}`
        ];
        break;
      }
      case 'Triangle': {
        perimeter = 2 * varMultiplier * varValue + width;
        labels = [
          `${varMultiplier.toLocaleString()}${symbol}`,
          `${width.toString()}`,
          `${varMultiplier.toLocaleString()}${symbol}`
        ];
        break;
      }
    }
    return (
      <QF1ContentAndSentence
        title={translate.instructions.perimeterOfShapeIsX(perimeter.toLocaleString())}
        Content={({ dimens }) => (
          <>
            {shape === 'Triangle' ? (
              <LabelledTriangle labels={labels} dimens={dimens} />
            ) : (
              <LabelledQuadrilateral
                dimens={dimens}
                shape={shape}
                labels={labels}
                shapeSize="large"
              />
            )}
          </>
        )}
        sentence={`${translate.answerSentences.findValueOfX(symbol)}<br/>${symbol} = <ans/>`}
        testCorrect={[varValue.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Solve2StepEquations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
