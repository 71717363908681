import { fluent } from '@codibre/fluent-iterable';
import * as math from 'mathjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RecursiveArray<T> extends Array<T | ReadonlyArray<T> | RecursiveArray<T>> {}
export type NonEmptyArray<T> = [T, ...T[]];
export const isNonEmptyArray = <T>(x: T[]): x is NonEmptyArray<T> => x.length > 0;

/**
 * Produces an array of indices counting upwards from the starting number given, for the length given.
 *
 * Examples:
 * - `countRange(5)` is `[0, 1, 2, 3, 4]`, same as `range(0, 4)`
 * - `countRange(5, 3)` is `[3, 4, 5, 6, 7]`, same as `range(3, 7)`
 * - `countRange(0)` is `[]`, unlike `range(0, -1)` which is `[0, -1]`
 *   - for this reason, `countRange(length)` is often more appropriate than `range(0, length-1)`.
 *
 * @param length non-negative integer
 * @param start optional starting number, defaults to 0
 */
export function countRange(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

/**
 * Produces a range from `from` to `to` inclusive.
 *
 * ### Note:
 * - `to - from` should be an integer multiple of the step size. E.g. `range(1, 21, 4)`, `to-from` equals 20 which is an integer multiple of 4.
 * - If this is not the case, the last value in the range will be the last step without going past `to`. E.g. `range(0, 5, 2)` returns `[0, 2, 4]`.
 *
 * Handles non-integer values,
 *
 * @param from - first number in the array.
 * @param to - last number in the array. Allowed to be greater, equal, or less than `from`.
 * @param step - step size (absolute value).
 * @returns Array of numbers from `from` to `to` inclusive, with specified step size.
 */
export function range(from: number, to: number, step = 1): NonEmptyArray<number> {
  if (step < 0) {
    throw new Error(`step size ${step} was not positive`);
  }
  if (to === from) {
    return [from];
  }

  // Determine whether range is integer-only or not
  const usingIntegers = Number.isInteger(from) && Number.isInteger(step);

  const signedStep = to > from ? step : -step;
  const finishedCondition: (x: number) => boolean = to > from ? x => x > to : x => x < to;

  const array = [];
  let x = from;

  while (!finishedCondition(x)) {
    array.push(x);

    x = usingIntegers
      ? x + signedStep
      : // When handling non-integers, we want to use a floating point numbers which correspond to "nice" numbers in decimal notation.
        // So, print to a decimal string and remove floating point imprecision, then convert to a floating point number. (This assumes
        // the number doesn't require more than 12 significant figures.)
        parseFloat(math.format(x + signedStep, { precision: 12 }));
  }

  return array as NonEmptyArray<number>;
}

/**
 * Produces a range from `from` to `to` inclusive excluding anything in the excludes array.
 *
 * Note that the step size should divide the difference between `to` and `from`. If it doesn't, the last value in the
 * range will be the last step without going past `to`. E.g. range(0, 5, 2) gives [0, 2, 4].
 *
 * Accepts non-integers, but be wary.
 *
 * @param from The first number in the array.
 * @param to The last number in the array. Allowed to be greater, equal, or less than `to`.
 * @param excluding An array of numbers to exclude from the range.
 * @param step The step size (absolute value). Should divide abs(to-from).
 * @returns Array of numbers from `from` to `to` inclusive.
 */
export function rangeExcluding(
  from: number,
  to: number,
  excluding: readonly number[],
  step = 1
): NonEmptyArray<number> {
  if (step < 0) {
    throw new Error(`step size ${step} was not positive`);
  }
  if (to === from) {
    return [from];
  }

  const signedStep = to > from ? step : -step;
  const finishedCondition: (x: number) => boolean = to > from ? x => x > to : x => x < to;

  const array = [];
  let x = from;

  while (!finishedCondition(x)) {
    if (excluding.find(element => element === x) === undefined) {
      array.push(x);
    }
    x += signedStep;
  }

  return array as NonEmptyArray<number>;
}

/**
 * Produces a range from `from` to `to` inclusive as strings.
 *
 * Note that the step size should divide the difference between `to` and `from`. If it doesn't, the last value in the
 * range will be the last step without going past `to`. E.g. range(0, 5, 2) gives ['0', '2', '4'].
 *
 * Accepts non-integers, but be wary.
 *
 * @param from The first number in the array.
 * @param to The last number in the array. Allowed to be greater, equal, or less than `to`.
 * @param step The step size (absolute value). Should divide abs(to-from).
 * @param toLocale Flag for returning Locale String
 * @returns Array of numbers as strings from `from` to `to` inclusive.
 */
export function rangeAsString(
  from: number,
  to: number,
  step = 1,
  toLocale?: boolean
): NonEmptyArray<string> {
  return range(from, to, step).map(x =>
    toLocale ? x.toLocaleString() : x.toString()
  ) as NonEmptyArray<string>;
}

/** Produce a filled array of the given length, initialized by the given */
export function filledArray<T>(x: T, length: number): T[] {
  return Array<T>(length).fill(x);
}

/**
 * Convert an array to a 2d array, with the given rows and columns.
 *
 * Fills from the top-left:
 * - if there aren't enough elements in the input, pad it out with undefined.
 * - if there are too many elements in the input, the rest are not used.
 */
export function to2dArray<T>(
  input: readonly T[],
  columns: number,
  rows: number
): (T | undefined)[][] {
  const array2d: (T | undefined)[][] = [];

  let currentIndex = 0;
  range(0, rows - 1).forEach(row => {
    array2d[row] = [];
    range(0, columns - 1).forEach(column => {
      array2d[row][column] = input[currentIndex];
      currentIndex++;
    });
  });

  return array2d;
}

/**
 * Inserts an item into an array at the given index, shifting all existing items after that index by 1.
 * Returns a copy; the original array is unchanged.
 */
export function arrayInsert<T>(array: readonly T[], item: T, index: number): NonEmptyArray<T> {
  return [...array.slice(0, index), item, ...array.slice(index)] as NonEmptyArray<T>;
}

type ReadonlyMapTypes<M extends ReadonlyMap<unknown, unknown>> = M extends ReadonlyMap<
  infer K,
  infer V
>
  ? { key: K; value: V }
  : never;

/**
 * Convenience API combining set and delete. Produces a copy of the original array map if anything changes.
 * @param callback function to act on the current value. Returning undefined performs a delete. Returning the same
 * value does nothing.
 */
export function readonlyMapEntry<M extends ReadonlyMap<unknown, unknown>>(
  map: M,
  key: ReadonlyMapTypes<M>['key'],
  callback: (
    value: ReadonlyMapTypes<M>['value'] | undefined
  ) => ReadonlyMapTypes<M>['value'] | undefined
): M {
  const existingValue = map.get(key);
  const newValue = callback(existingValue);
  if (newValue === existingValue) {
    // No changes
    return map;
  } else {
    const newMap = new Map(map);
    if (newValue === undefined) {
      newMap.delete(key);
    } else {
      newMap.set(key, newValue);
    }
    return newMap as unknown as M;
  }
}

/**
 * Convenience API combining set and delete. Produces a copy of the original array if anything changes.
 * @param callback function to act on the current value. Returning undefined performs a delete, shifting other elements
 * in the array. Returning the same value does nothing.
 */
export function readonlyArrayEntry<A extends readonly unknown[]>(
  array: A,
  index: number,
  callback: (value: A[number]) => A[number] | undefined
): A {
  const existingValue = array[index];
  const newValue = callback(existingValue);
  if (newValue === existingValue) {
    // No changes
    return array;
  } else {
    const newArray = array.slice(0);
    if (newValue === undefined) {
      newArray.splice(index, 1);
    } else {
      newArray[index] = newValue;
    }
    return newArray as unknown as A;
  }
}

/**
 * Convenience API combining set and delete. Produces a copy of the original object if anything changes.
 * @param callback function to act on the current value. Returning undefined performs a delete. Returning the same
 * value does nothing.
 */
export function readonlyObjectEntry<
  O extends Readonly<Record<string | number | symbol, unknown>>,
  Key extends keyof O
>(object: O, key: Key, callback: (value: O[Key]) => O[Key] | undefined): O {
  const existingValue = object[key];
  const newValue = callback(existingValue);
  if (newValue === existingValue) {
    // No changes
    return object;
  } else {
    const newObject: O = { ...object };
    if (newValue === undefined) {
      delete newObject[key];
    } else {
      newObject[key] = newValue;
    }
    return newObject;
  }
}

/**
 * Return the "largest" member of an array, using the given metric to measure size.
 * Throws an exception if the input array is empty.
 */
export function greatest<T>(arr: readonly T[], metric: (t: T) => number): T {
  return arr.reduce((a, b) => (metric(a) > metric(b) ? a : b));
}

/**
 * Return the "smallest" member of an array, using the given metric to measure size.
 * Throws an exception if the input array is empty.
 */
export function smallest<T>(arr: readonly T[], metric: (t: T) => number): T {
  return arr.reduce((a, b) => (metric(a) < metric(b) ? a : b));
}

/** Checks sets for same contents. */
export function setsHaveSameContents<T>(a: ReadonlySet<T>, b: ReadonlySet<T>): boolean {
  return a.size === b.size && fluent(a).every(it => b.has(it));
}

/**
 * Returns an array of values that appear in both array a and array b.
 * Multiplicity is handled correctly, e.g. for a=[1, 2, 2, 3, 3, 3] and b=[2, 3, 3, 4],
 * the intersection is [2, 3, 3]
 *
 * Can optionally pass in a function to evaluate whether two items are equal - defaults to ===.
 * One place where this is useful when combined with deep equality, e.g.:
 *
 * ```typescript
 * import deepEqual from 'react-fast-compare';
 * const a = [{x: 1, y: 1}, {x: 1, y: 2}];
 * const b = [{x: 1, y: 1}, {x: 2, y: 1}];
 * const common = arrayIntersection(a, b, deepEqual);  // [{x: 1, y: 1}]
 * ```
 */
export function arrayIntersection<T>(
  a: readonly T[],
  b: readonly T[],
  equals: (x: T, y: T) => boolean = (x, y) => x === y
): T[] {
  const intersection: T[] = [];
  const bClone = [...b];
  for (const aElement of a) {
    const bElementIndex = bClone.findIndex(it => equals(aElement, it));
    if (bElementIndex !== -1) {
      intersection.push(bClone.splice(bElementIndex, 1)[0]);
    }
  }
  return intersection;
}

/**
 * Returns whether an array is a sub-set of another array.
 * Multiplicity is handle correctly, e.g. [1, 2, 2] is a subset of [1, 2, 2, 3] but not a subset of [1, 2, 3].
 *
 * Can optionally pass in a function to evaluate whether two items are equal - defaults to ===.
 * One place where this is useful when combined with deep equality, e.g.:
 *
 * ```typescript
 * import deepEqual from 'react-fast-compare';
 * const a = [{x: 1, y: 1}];
 * const b = [{x: 1, y: 1}, {x: 2, y: 1}];
 * const isSubset = arrayIsSubset(a, b, deepEqual);  // true
 * ```
 */
export function arrayIsSubset<T>(
  a: readonly T[],
  b: readonly T[],
  equals: (x: T, y: T) => boolean = (x, y) => x === y
): boolean {
  // For each element of a, find matching element of b

  const bClone = [...b];

  for (const aElement of a) {
    const bElement = bClone.find(bCloneElem => equals(aElement, bCloneElem));
    if (bElement === undefined) {
      // Failed! Couldn't find matching b element
      return false;
    }

    // Remove element that was found
    bClone.splice(bClone.indexOf(bElement), 1);
  }

  return true;
}

/**
 * Checks arrays for same contents in the same order.
 *
 * Can optionally pass in a function to evaluate whether two items are equal - defaults to ===.
 * One place where this is useful when combined with deep equality, e.g.:
 *
 * ```typescript
 * import deepEqual from 'react-fast-compare';
 * const a = [{x: 1, y: 1}, {x: 2, y: 1}];
 * const b = [{x: 1, y: 1}, {x: 2, y: 1}];
 * const isEqual = arraysHaveSameContents(a, b, deepEqual);  // true
 * ```
 */
export function arraysHaveSameContents<T>(
  a: readonly T[],
  b: readonly T[],
  equals: (x: T, y: T) => boolean = (x, y) => x === y
): boolean {
  return a.length === b.length && a.every((it, index) => equals(it, b[index]));
}

/**
 * Checks arrays for same contents, disregarding order.
 * For elements that appear more than once, checks they appear the same number of times in both arrays. This wouldn't
 * be checked if you just converted to sets and used {@link setsHaveSameContents}.
 *
 * Can optionally pass in a function to evaluate whether two items are equal - defaults to ===.
 * One place where this is useful when combined with deep equality, e.g.:
 *
 * ```typescript
 * import deepEqual from 'react-fast-compare';
 * const a = [{x: 1, y: 1}, {x: 2, y: 1}];
 * const b = [{x: 2, y: 1}, {x: 1, y: 1}];
 * const isEqual = arraysHaveSameContentsUnordered(a, b, deepEqual);  // true
 * ```
 */
export function arraysHaveSameContentsUnordered<T>(
  a: readonly T[],
  b: readonly T[],
  equals: (x: T, y: T) => boolean = (x, y) => x === y
): boolean {
  // Early return if arrays don't have same length
  if (a.length !== b.length) {
    return false;
  }

  return arrayIsSubset(a, b, equals);
}

/**
 * Like {@link arraysHaveSameContentsUnordered}, but for two arrays of arrays.
 * For each array of arrays we disregard order of the inner arrays and the outer array.
 */
export function nestedArraysHaveSameContentsUnordered<T>(
  a: RecursiveArray<T>,
  b: RecursiveArray<T>
): boolean {
  return arraysHaveSameContentsUnordered(a, b, (x, y) =>
    Array.isArray(x) && Array.isArray(y) ? nestedArraysHaveSameContentsUnordered(x, y) : x === y
  );
}
/**
 * Checks array for duplicates.
 *
 * Can optionally pass in a function to evaluate whether two items are equal - defaults to ===.
 */
export function arrayHasNoDuplicates<T>(
  a: readonly T[],
  equals: (x: T, y: T) => boolean = (x, y) => x === y
): boolean {
  // For every element, it's not the case that there's some element _with a different index_ equal to it
  return a.every(
    (it, itIndex) => !a.some((other, otherIndex) => itIndex !== otherIndex && equals(it, other))
  );
}

/**
 * Like {@link arrayHasNoDuplicates}, but for an array of arrays.
 * By default we disregard order of the inner arrays, when checking whether one counts as a duplicate.
 */
export function nestedArrayHasNoDuplicates<T>(
  a: ReadonlyArray<ReadonlyArray<T>>,
  ordered = false
): boolean {
  return arrayHasNoDuplicates(
    a,
    ordered ? arraysHaveSameContents : arraysHaveSameContentsUnordered
  );
}

/**
 * Deduplicating an array, excluding subsequent values wherever there's duplicates. Returns a copy of the array, with
 * duplicates removed. Keeps the first entry in each conflict.
 *
 * Optionally pass in a `seen` set of keys which should also be excluded. This will be mutated, to add the keys from
 * the input array. This is helpful when applying deduplication across multiple arrays.
 */
export function deduplicate<T, K>(array: T[], key: (value: T) => K, seen: Set<K> = new Set()) {
  return array.filter(item => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

/** Sorts an array of numbers. Returns a copy of the array, leaving the original unchanged. */
export function sortNumberArray<T extends number>(
  a: readonly T[],
  order: 'ascending' | 'descending' = 'ascending'
): T[] {
  return [...a].sort((a, b) => (order === 'ascending' ? a - b : b - a));
}

export function sumNumberArray(a: readonly number[]): number {
  return a.reduce((sum, x) => sum + x, 0);
}

/**
 * Zip together several arrays, producing an array of tuples.
 *
 * The arrays should be the same length. The first array determines the output length. If another array is shorter,
 * `undefine`s will be added (unknown to typescript). If another array is longer, the extra values will be missed.
 *
 * For example:
 * - `zipArrays([1, 2], ['a, 'b'])` produces `[[1, 'a'], [2, 'b']]`
 * - `zipArrays([1, 2], ['a, 'b'], [true, false])` produces `[[1, 'a', true], [2, 'b', false]]`
 *
 * The typescript types look complicated, but really they're just magic to ensure that passing in two arrays returns
 * an array of pairs, passing in three arrays gives an array of triples, etc.
 */
export function zipArrays<T, Arrs extends ReadonlyArray<ReadonlyArray<unknown>>>(
  first: ReadonlyArray<T>,
  ...arrs: Arrs
): [T, ...TupleFromArrays<Arrs>][] {
  // Allow casting to any - we know what we're doing.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return first.map((it, i) => [it, ...arrs.map(arr => arr[i])]) as any;
}

/** Type representing a tuple made from one element from each array. Uses the "mapped types" TypeScript feature. */
type TupleFromArrays<Arrs extends ReadonlyArray<ReadonlyArray<unknown>>> = {
  [Key in keyof Arrs]: Arrs[Key][number];
};

export function flatten2dArray<T>(array2d: readonly T[][]): {
  flatArray: T[];
  index2dTo1d: (row: number, column: number) => number;
  unflatten: (array: T[]) => T[][];
} {
  const flatArray = array2d.flatMap(it => it);

  function index2dTo1d(row: number, column: number): number {
    const startingIndex = sumNumberArray(
      array2d.filter((_, index) => index < row).map(sentence => sentence.length)
    );
    return startingIndex + column;
  }

  function unflatten(array: T[]): T[][] {
    let index = 0;
    return array2d.map(sentence => sentence.map(() => array[index++]));
  }

  return { flatArray, index2dTo1d, unflatten };
}

/**
 * Sum an array using the given metric.
 * E.g. `sumArrayBy(['a', 'ab', 'abc'], x => x.length)` produces 6
 */
export function sumArrayBy<T>(array: readonly T[], by: (x: T) => number): number {
  return array.reduce((sum, it) => sum + by(it), 0);
}

/**
 * Swap elements indicated by the indices. Defaults to swapping first two elements
 * E.g. `swapElements(['a', 'ab', 'abc'], 1, 2)` produces ['a', 'abc', 'ab']
 */
export function swapElements<T>(array: readonly T[], indexA = 0, indexB = 1): T[] {
  if (indexA === indexB) {
    throw Error('Cannot swap the same indices');
  }

  if (indexA >= array.length || indexB >= array.length) {
    throw Error('Index exceeds array length');
  }

  const arrayCopy = [...array];

  [arrayCopy[indexA], arrayCopy[indexB]] = [arrayCopy[indexB], arrayCopy[indexA]];

  return arrayCopy;
}

/**
 * Creates a deep clone of the given array.
 *
 * @param {unknown[]} array - The array to be deep cloned.
 * @returns {unknown[]} - A deep clone of the input array.
 */
export const deepClone = <T>(array: T[]): T[] => {
  return array.map(item => (Array.isArray(item) ? (deepClone(item) as T) : item));
};

/**
 * Creates a map that counts the occurrences of each number in given array.
 *
 * @param {readonly number[]} arr - The array of numbers to count.
 * @returns {Map<number, number>} A map where the keys are numbers from the array and the values are the count of each number.
 */
export function multisetCount(arr: readonly number[]): Map<number, number> {
  const countMap = new Map<number, number>();

  arr.forEach(num => {
    countMap.set(num, (countMap.get(num) || 0) + 1);
  });

  return countMap;
}
