// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplyFractionsByIntegers from './1MultiplyFractionsByIntegers';
import MultiplyFractionsByFractions from './2MultiplyFractionsByFractions';
import DivideAFractionByAnInteger from './3DivideAFractionByAnInteger';
import DivideAnyFractionByAnInteger from './4DivideAnyFractionByAnInteger';
import MixedQuestionsWithFractions from './5MixedQuestionsWithFractions';
import FractionOfAnAmount from './6FractionOfAnAmount';
import FractionOfAnAmountFindTheWhole from './7FractionOfAnAmountFindTheWhole';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [10, 11],
  smallSteps: [
    MultiplyFractionsByIntegers, // Step 1
    MultiplyFractionsByFractions, // Step 2
    DivideAFractionByAnInteger, // Step 3
    DivideAnyFractionByAnInteger, // Step 4
    MixedQuestionsWithFractions, // Step 5
    FractionOfAnAmount, // Step 6
    FractionOfAnAmountFindTheWhole // Step 7
  ]
});
export default Block;
