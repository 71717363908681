import LollyStickShape from './Coordinates/LollyStickShape';
import Grid from './Coordinates/Grid';
import { range } from '../../../utils/collections';
import { z } from 'zod';
import { getRandomFromArray } from '../../../utils/random';

export const lollyShapes = [
  'house',
  'diamond',
  'square',
  'longRect',
  'sideHouse',
  'triangle'
] as const;

export const lollyShapesSchema = z.enum(lollyShapes);

export function getRandomLollyShape() {
  return getRandomFromArray(lollyShapes);
}

export type LollyShape = (typeof lollyShapes)[number];

const getShape = (shape: LollyShape): [number, number][] => {
  switch (shape) {
    case 'house':
      return [
        [1, 2],
        [1.5, 2 + Math.sqrt(3 / 4)],
        [2, 2],
        [2, 1],
        [1, 1]
      ];
    case 'square':
      return [
        [1, 2],
        [2, 2],
        [2, 1],
        [1, 1]
      ];
    case 'longRect':
      return [
        [1, 3],
        [2, 3],
        [2, 2],
        [2, 1],
        [1, 1]
      ];
    case 'diamond':
      return [
        [1.5, 2],
        [2, 2 + Math.sqrt(3 / 4)],
        [2.5, 2],
        [2, 2 - Math.sqrt(3 / 4)]
      ];
    case 'sideHouse':
      return [
        [1 - Math.sqrt(3 / 4), 1.5],
        [1, 2],
        [2, 2],
        [2, 1],
        [1, 1]
      ];
    case 'triangle':
      return [
        [0.5, 1],
        [1, 1 + Math.sqrt(3 / 4)],
        [1.5, 1]
      ];
  }
};

/**
 *
 * Returns a pattern of shapes
 * Limitation: The triangle shape works with a max of 2 repetitions as of now
 */
export const LollyPatterns = (shape: LollyShape, repetitions: number = 0) => {
  const shapePattern = getShape(shape);
  const repeatedPattern = ['diamond', 'sideHouse'].includes(shape)
    ? shapePattern.slice(1)
    : shapePattern;

  const maxY = Math.floor(Math.max(...shapePattern.map(([_, y]) => y))) + 1;
  const maxX = Math.floor(Math.max(...shapePattern.map(([x]) => x))) + 1 + repetitions;

  return (
    <Grid
      sizingMethod="gridScale"
      xScale={70}
      yScale={70}
      xMax={maxX}
      yMax={maxY}
      hideGridLines
      hideContinuationLines
      xAxis={null}
      yAxis={null}
    >
      {range(0, repetitions).map(i => {
        if (i === 0) return <LollyStickShape key={`${i}`} vertices={shapePattern} closeLoop />;
        if (shape === 'triangle') {
          if (i === 1) {
            return (
              <LollyStickShape
                key={`${i}`}
                vertices={[
                  [1, 1 + Math.sqrt(3 / 4)],
                  [2, 1 + Math.sqrt(3 / 4)],
                  [1.5, 1]
                ]}
              />
            );
          }
          if (i === 2) {
            return (
              <LollyStickShape
                key={`${i}`}
                vertices={[
                  [2, 1 + Math.sqrt(3 / 4)],
                  [2.5, 1],
                  [1.5, 1]
                ]}
              />
            );
          }
        }
        return (
          <LollyStickShape key={`${i}`} vertices={repeatedPattern.map(([x, y]) => [x + i, y])} />
        );
      })}
    </Grid>
  );
};

export const getStickNumbers = (shape: LollyShape) => {
  switch (shape) {
    case 'house':
      return {
        shapeSticks: 5,
        patternSticks: 4
      };
    case 'square':
      return {
        shapeSticks: 4,
        patternSticks: 3
      };
    case 'longRect':
      return {
        shapeSticks: 6,
        patternSticks: 4
      };
    case 'diamond':
      return {
        shapeSticks: 4,
        patternSticks: 2
      };
    case 'sideHouse':
      return {
        shapeSticks: 5,
        patternSticks: 3
      };
    case 'triangle':
      return {
        shapeSticks: 3,
        patternSticks: 2
      };
  }
};
