import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  ArrayOfObjectsColors,
  barModelColors,
  barModelColorsArray,
  BarModelColorsKey,
  colors
} from '../../../../theme/colors';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Table from '../../../../components/molecules/Table';
import Text from '../../../../components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aP4',
  description: 'aP4',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(48),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const wholeNumber = randomIntegerInclusive(2, 48, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(1, denominator);

    return { denominator, wholeNumber, numerator };
  },
  Component: props => {
    const {
      question: { denominator, wholeNumber, numerator },
      translate
    } = props;
    const fractionOfWhole = number(math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`));
    const arrayOfSquares = filledArray(filledArray('white', denominator), 1);
    const countersPerSquare = number(math.evaluate(`${wholeNumber}/${denominator}`));

    const counterColor = getRandomFromArray(Object.values(ArrayOfObjectsColors), {
      random: seededRandom(props.question)
    }) as string;

    const generateArrayData = (countersPerSquare: number): number[][] => {
      if (countersPerSquare === 2) {
        return [[1, 1]];
      }

      const halfCount = countersPerSquare / 2;
      const topRowCount = Math.ceil(halfCount);
      const bottomRowCount = Math.floor(halfCount);

      const topRow: number[] = [];
      const bottomRow: number[] = [];

      countRange(topRowCount).forEach(() => topRow.push(1));
      countRange(bottomRowCount).forEach(() => bottomRow.push(1));

      return [topRow, bottomRow];
    };

    const arrayData = generateArrayData(countersPerSquare);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theBarModelShowsFracOfAnAmountIsNumber({
          frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
          number: fractionOfWhole
        })}
        testCorrect={[wholeNumber.toString()]}
        sentence={translate.answerSentences.theWholeIs()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View>
            <Table
              style={{ alignItems: 'center' }}
              items={arrayOfSquares.map(rowColors =>
                rowColors.map((number, i) => (
                  <View
                    key={number}
                    style={{
                      width: dimens.width / denominator,
                      height: dimens.height - 150,
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}
                  >
                    {i < numerator &&
                      arrayData.map((arr, i) => {
                        return arr.length ? (
                          <ArrayOfObjects
                            key={i}
                            color={counterColor}
                            dimens={{
                              height: (dimens.height - 150) / arrayData.length,
                              width: dimens.width / denominator
                            }}
                            rowStyle={{
                              justifyContent: 'space-evenly'
                            }}
                            rows={1}
                            counterSize={
                              Math.min(
                                dimens.width / denominator / arrayData[0].length,
                                (dimens.height - 150) / arrayData.length
                              ) - 10
                            }
                            columns={arr.length}
                          />
                        ) : null;
                      })}
                  </View>
                ))
              )}
            />
            <View style={{ top: 40 }}>
              <TextStructure sentence={translate.instructions.whatIsTheWhole()} />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aP5',
  description: 'aP5',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(96),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const wholeNumber = randomIntegerInclusive(2, 96, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(1, denominator - 1);
    return { denominator, wholeNumber, numerator };
  },
  Component: props => {
    const {
      question: { denominator, wholeNumber, numerator },
      translate
    } = props;

    const fractionOfWholeNumber = number(
      math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`)
    );

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const numeratorColorArray = filledArray(
      barModelColors[numeratorColor as BarModelColorsKey],
      numerator
    );

    const remainder = filledArray('white', denominator - 1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    const barModelString = number(math.evaluate(`${wholeNumber}/${denominator}`)).toString();

    const numbers = [filledArray(1, denominator)];

    const strings = filledArray(barModelString, denominator).map((num, i) =>
      i < numerator ? num : ''
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theBarModelShowsFracOfAnAmountIsNumber({
          frac: `<frac n='${numerator}' d='${denominator}' />`,
          number: fractionOfWholeNumber
        })}
        testCorrect={[wholeNumber.toString()]}
        sentence={translate.answerSentences.theWholeIs()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View>
            <BarModel
              total={denominator}
              dimens={{ width: dimens.width, height: dimens.height }}
              numbers={numbers}
              strings={[strings]}
              sameRowColor
              cellColors={[customColorMap]}
              rowHeight={50}
              topBraceText={'?'}
            />
            <View style={{ top: 40 }}>
              <TextStructure sentence={translate.instructions.whatIsTheWhole()} />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aP6',
  description: 'aP6',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(96),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(7),
    fractionOfWholeNumber: z.number().int().min(1).max(84)
  }),
  simpleGenerator: () => {
    const { denominator, numerator, wholeNumber, fractionOfWholeNumber } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 8);

        const numerator = randomIntegerInclusive(1, denominator - 1);

        const wholeNumber = randomIntegerInclusive(2, 96, {
          constraint: x => x % denominator === 0
        });

        const fractionOfWholeNumber = number(
          math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`)
        );

        return { denominator, numerator, wholeNumber, fractionOfWholeNumber };
      },
      // Only permit if answers array has no duplicate values
      ({ denominator, numerator, wholeNumber, fractionOfWholeNumber }) =>
        arrayHasNoDuplicates([
          fractionOfWholeNumber * numerator,
          number(math.floor((wholeNumber / numerator) * denominator)),
          wholeNumber - fractionOfWholeNumber,
          wholeNumber
        ])
    );

    return { denominator, numerator, wholeNumber, fractionOfWholeNumber };
  },
  Component: props => {
    const {
      question: { denominator, numerator, wholeNumber, fractionOfWholeNumber },
      translate,
      displayMode
    } = props;

    const incorrectAnswerA = fractionOfWholeNumber * numerator;
    const incorrectAnswerB = number(math.floor((wholeNumber / numerator) * denominator));
    const incorrectAnswerC = wholeNumber - fractionOfWholeNumber;

    const numeratorColor =
      displayMode === 'digital'
        ? getRandomFromArray(barModelColorsArray, {
            random: seededRandom(props.question)
          })
        : colors.pdfShading;

    const numeratorColorArray = filledArray(
      barModelColors[numeratorColor as BarModelColorsKey],
      numerator
    );

    const remainder = filledArray('white', denominator - 1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    const barModelString = number(math.evaluate(`${wholeNumber}/${denominator}`)).toString();

    const numbers = [filledArray(1, denominator)];

    const strings = filledArray(barModelString, denominator).map((num, i) =>
      i < numerator ? num : ''
    );

    const statements = shuffle(
      [
        {
          value: wholeNumber,
          sentence: wholeNumber.toString()
        },
        {
          value: incorrectAnswerA,
          sentence: incorrectAnswerA.toString()
        },
        {
          value: incorrectAnswerB,
          sentence: incorrectAnswerB.toString()
        },
        {
          value: incorrectAnswerC,
          sentence: incorrectAnswerC.toString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.whatIsTheWhole()}
        testCorrect={[wholeNumber]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ top: 50 }}>
            <BarModel
              total={denominator}
              dimens={{ width: dimens.width, height: dimens.height }}
              numbers={numbers}
              strings={[strings]}
              sameRowColor
              cellColors={[customColorMap]}
              rowHeight={displayMode === 'digital' ? 50 : 100}
              topBraceText={'?'}
            />
            <Text style={{ top: 50 }} variant="WRN400">
              {displayMode === 'digital'
                ? translate.instructions.selectCorrectAnswer()
                : translate.instructions.circleCorrectAnswer()}
            </Text>
          </View>
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          component: <Text variant="WRN700">{sentence}</Text>,
          value
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aP7',
  description: 'aP7',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(96),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(7),
    fractionOfWholeNumber: z.number().int().min(1).max(84),
    startWithFraction: z.boolean()
  }),
  simpleGenerator: () => {
    const { denominator, numerator, wholeNumber, fractionOfWholeNumber } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 8);

        const numerator = randomIntegerInclusive(1, denominator - 1);

        const wholeNumber = randomIntegerInclusive(2, 96, {
          constraint: x => x % denominator === 0
        });

        const fractionOfWholeNumber = number(
          math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`)
        );

        return { denominator, numerator, wholeNumber, fractionOfWholeNumber };
      },
      // Only permit if answers array has no duplicate values
      ({ denominator, numerator, wholeNumber, fractionOfWholeNumber }) =>
        arrayHasNoDuplicates([
          fractionOfWholeNumber * numerator,
          number(math.floor((wholeNumber / numerator) * denominator)),
          wholeNumber - fractionOfWholeNumber,
          wholeNumber
        ])
    );

    const startWithFraction = getRandomBoolean();

    return { denominator, numerator, wholeNumber, fractionOfWholeNumber, startWithFraction };
  },
  Component: props => {
    const {
      question: { denominator, numerator, wholeNumber, fractionOfWholeNumber, startWithFraction },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const numeratorColorArray = filledArray(
      barModelColors[numeratorColor as BarModelColorsKey],
      numerator
    );

    const remainder = filledArray('white', denominator - 1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    const barModelString = number(math.evaluate(`${wholeNumber}/${denominator}`)).toString();

    const numbers = [filledArray(1, denominator)];

    const strings = filledArray(barModelString, denominator).map(_ => '');

    const items = shuffle(
      [
        fractionOfWholeNumber * numerator,
        number(math.floor((wholeNumber / numerator) * denominator)),
        wholeNumber - fractionOfWholeNumber,
        wholeNumber
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        pdfLayout="itemsBottom"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        Content={({ dimens }) => (
          <View>
            <BarModel
              total={denominator}
              dimens={dimens}
              numbers={numbers}
              strings={[strings]}
              sameRowColor
              cellColors={[customColorMap]}
              topBraceText={'?'}
            />

            <View
              style={{
                width: ((dimens.width - 35) / denominator) * numerator
              }}
            >
              <BarModelCurlyBrace
                topOrBottomBrace="bottom"
                braceText={fractionOfWholeNumber.toLocaleString()}
              />
            </View>
          </View>
        )}
        sentence={
          startWithFraction
            ? `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${translate.misc.of()} <ans/> = ${fractionOfWholeNumber.toLocaleString()}`
            : `${fractionOfWholeNumber.toLocaleString()} = <frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${translate.misc.of()} <ans/>`
        }
        testCorrect={[wholeNumber]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aP8',
  description: 'aP8',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model',
    'Metres'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(1000),
    denominator: z.number().int().min(2).max(10),
    numerator: z.number().int().min(1).max(9),
    fractionOfWholeNumber: z.number().int().min(1).max(900),
    characterName: nameSchema
  }),
  simpleGenerator: () => {
    const { denominator, numerator, wholeNumber, fractionOfWholeNumber } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(2, 10);

        const numerator = randomIntegerInclusive(1, denominator - 1);

        const wholeNumber = randomIntegerInclusive(2, 1000, {
          constraint: x => x % denominator === 0
        });

        const fractionOfWholeNumber = number(
          math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`)
        );

        return { denominator, numerator, wholeNumber, fractionOfWholeNumber };
      },
      // Only permit if answers array has no duplicate values
      ({ denominator, numerator, wholeNumber, fractionOfWholeNumber }) =>
        arrayHasNoDuplicates([
          fractionOfWholeNumber * numerator,
          number(math.floor((wholeNumber / numerator) * denominator)),
          wholeNumber - fractionOfWholeNumber,
          wholeNumber
        ])
    );

    const characterName = getRandomName();

    return { denominator, numerator, wholeNumber, characterName, fractionOfWholeNumber };
  },
  Component: props => {
    const {
      question: { denominator, numerator, wholeNumber, characterName, fractionOfWholeNumber },
      translate
    } = props;

    const incorrectAnswerA = fractionOfWholeNumber * numerator;
    const incorrectAnswerB = number(math.floor((wholeNumber / numerator) * denominator));
    const incorrectAnswerC = wholeNumber - fractionOfWholeNumber;

    const statements = shuffle(
      [
        {
          value: wholeNumber,
          sentence: wholeNumber.toString()
        },
        {
          value: incorrectAnswerA,
          sentence: incorrectAnswerA.toString()
        },
        {
          value: incorrectAnswerB,
          sentence: incorrectAnswerB.toString()
        },
        {
          value: incorrectAnswerC,
          sentence: incorrectAnswerC.toString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterIsFracWayThroughRaceCharHasRanXHowLongIsTheRace({
          distance: fractionOfWholeNumber,
          frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
          name: characterName
        })}
        pdfTitle={`${translate.instructions.characterIsFracWayThroughRaceCharHasRanXHowLongIsTheRace(
          {
            distance: fractionOfWholeNumber,
            frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            name: characterName
          }
        )}<br/>${`${translate.instructions.circleCorrectAnswer()}`}`}
        testCorrect={[wholeNumber]}
        numItems={4}
        renderItems={() => {
          return statements.map(({ value, sentence }) => ({
            value,
            component: (
              <TextStructure textVariant="WRN700" sentence={`${sentence} ${translate.units.m()}`} />
            )
          }));
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aP9',
  description: 'aP9',
  keywords: [
    'Fraction',
    'Quantity',
    'Amount',
    'Whole',
    'Unit fraction',
    'Non-unit fraction',
    'Bar model'
  ],
  schema: z.object({
    wholeNumber: z.number().int().min(2).max(400),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    const wholeNumber = randomIntegerInclusive(2, 400, { constraint: x => x % denominator === 0 });

    return { denominator, numerator, wholeNumber };
  },
  Component: props => {
    const {
      question: { denominator, numerator, wholeNumber },
      translate
    } = props;

    const fractionOfWholeNumber = number(
      math.evaluate(`${wholeNumber}/${denominator} * ${numerator}`)
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fractionOfANumberIsXWhatIsTheNumber({
          frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
          number: fractionOfWholeNumber
        })}
        testCorrect={[wholeNumber.toString()]}
        sentence={translate.answerSentences.theNumberIsAns()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindTheWhole',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
