import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomNumberWithSpecificDigit,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  rejectionSample
} from 'common/src/utils/random';
import { CounterVariantNoBlockSchema } from 'common/src/components/question/representations/types';
import ReadPlaceValueChart from 'common/src/components/question/questionFormats/ReadPlaceValueChart';

import CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { PartWholeModel } from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import {
  Digit,
  numberToBase10Object,
  powersOfTenPowToWord,
  ScientificNotation
} from 'common/src/utils/math';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'aeC',
  description: 'aeC',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million', 'Chart'],
  schema: z.object({
    number: z.number().int().min(1000001).max(9999999),
    counterVariant: CounterVariantNoBlockSchema
  }),
  simpleGenerator: () => {
    // Generate random number between 1000001 and 9999999
    const number = randomIntegerInclusive(1000001, 9999999);

    const counterVariant = 'greyCounter' as const;

    return { number, counterVariant };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[6, 5, 4, 3, 2, 1, 0]}
      counterVariant={question.counterVariant}
      headerVariant="shortName"
      questionHeight={1050}
    />
  ),
  questionHeight: 1050
});

export const Question2 = newQuestionContent({
  uid: 'aeD',
  description: 'aeD',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million', 'Chart'],
  schema: z.object({
    number: z.number().min(1000001).max(9999999)
  }),
  questionHeight: 850,
  example: {
    number: 1232465
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1000001, 9999999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[6, 5, 4, 3, 2, 1, 0]}
      counterVariant="greyCounter"
      headerVariant="shortName"
      questionHeight={850}
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number.toLocaleString())}
      {...props}
    />
  )
});

export const Question3 = newQuestionContent({
  uid: 'aeE',
  description: 'aeE',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million', 'Bar model'],
  schema: z.object({
    total: z.number().min(1000001).max(9999999),
    powerOfAnswer: z.number().int().min(2).max(6) as z.Schema<2 | 3 | 4 | 5 | 6>,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const powerOfAnswer = randomIntegerInclusive(2, 6) as 2 | 3 | 4 | 5 | 6;

    // Prevents answer being 0 and rejects multiples of 1,000,000
    const total = rejectionSample(
      () =>
        randomNumberWithSpecificDigit(
          powerOfAnswer,
          { lower: 1000001, upper: 9999999 },
          randomIntegerInclusive(1, 9) as Digit
        ),
      x => x % 1000000 !== 0
    );

    const answerIndex = randomIntegerInclusive(0, 1);

    return { total, powerOfAnswer, answerIndex };
  },
  Component: ({ question: { total, powerOfAnswer, answerIndex }, translate, ...props }) => {
    const hiddenBarFactor = ScientificNotation.fromNumber(total).unsignedDigitAt(powerOfAnswer);
    const hiddenBarAmount = hiddenBarFactor ? Math.pow(10, powerOfAnswer) * hiddenBarFactor : 0;
    const givenNumber = total - hiddenBarAmount;

    const numbers = [
      [total],
      answerIndex === 0 ? [hiddenBarAmount, givenNumber] : [givenNumber, hiddenBarAmount]
    ];
    const answerIndices = [[], [answerIndex]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'aeF',
  description: 'aeF',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million', 'Select'],
  schema: z.object({
    numbers: z.number().int().min(1000000).max(9999999).array().length(8),
    digit: z.number().int().min(1).max(9),
    power: z.enum(['thousands', 'tenThousands', 'hundredThousands', 'millions'])
  }),
  example: {
    numbers: [1214040, 3359865, 4211234, 1751009, 8315249, 2462048, 5586000, 6844616],
    digit: 4,
    power: 'thousands'
  },
  simpleGenerator: () => {
    const power = getRandomFromArray([
      'thousands',
      'tenThousands',
      'hundredThousands',
      'millions'
    ] as const);
    const digit = randomIntegerInclusive(1, 9) as Digit;
    const range = { lower: 1000000, upper: 9999999 };

    const num1 = randomNumberWithSpecificDigit(6, range, digit);
    const num2 = randomNumberWithSpecificDigit(6, range, digit);
    const num3 = randomNumberWithSpecificDigit(5, range, digit);
    const num4 = randomNumberWithSpecificDigit(5, range, digit);
    const num5 = randomNumberWithSpecificDigit(4, range, digit);
    const num6 = randomNumberWithSpecificDigit(4, range, digit);
    const num7 = randomNumberWithSpecificDigit(3, range, digit);
    const num8 = randomNumberWithSpecificDigit(3, range, digit);

    return { numbers: shuffle([num1, num2, num3, num4, num5, num6, num7, num8]), digit, power };
  },
  Component: props => {
    const {
      question: { numbers, digit, power },
      translate
    } = props;

    const powerAsString = translate.powersOfTen[power](0);

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumbersHaveVarInPlace(digit, powerAsString)}
        testCorrect={numbers.filter(it => numberToBase10Object(it)[power] === digit)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aeG',
  description: 'aeG',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million'],
  schema: z
    .object({
      digits: z.array(z.number().int().min(0).max(9)).min(7).max(7),
      powerOfTen: z.number().int().min(2).max(6) as z.Schema<2 | 3 | 4 | 5 | 6>,
      value: z.number().int().min(0).max(9)
    })
    .refine(val => val.digits.includes(val.value), 'The value must be one of the choices'),
  example: {
    value: 5,
    digits: [1, 5, 2, 7, 6, 8, 4],
    powerOfTen: 3
  },
  simpleGenerator: () => {
    const numOfValues = 7;
    const value = randomIntegerInclusive(0, 9);

    // Ensure question doesn't ask for 0 in the first column - also, only allow the first five unit types to be selected
    const powerOfTen = (
      value === 0 ? randomIntegerInclusive(2, 5) : randomIntegerInclusive(2, 6)
    ) as 2 | 3 | 4 | 5 | 6;

    // Ensure each digit will be unique
    const digits = new Set<number>();

    digits.add(value);
    while (digits.size < numOfValues) {
      digits.add(randomIntegerInclusive(0, 9));
    }

    const shuffled = shuffle(Array.from(digits));

    return { digits: shuffled, value, powerOfTen };
  },
  Component: props => {
    const {
      question: { digits, value, powerOfTen },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      if (ans[0] === (0).toLocaleString()) {
        return false;
      }
      return ScientificNotation.fromFixedString(ans.join('')).digitAt(powerOfTen) === value;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.create7DigitNumberWith(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        pdfTitle={translate.instructions.create7DigitNumberWithDigitsOnlyUsedOnce(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        items={digits.map(num => num.toLocaleString())}
        sentence={'<ans/> '.repeat(7)}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyNumberWithXInTheYPlace(
            value,
            translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

export const Question6 = newQuestionContent({
  uid: 'aeH',
  description: 'aeH',
  keywords: ['Place value', 'Represent', '1,000,000', '10,000,000', 'Million', 'Part-whole'],
  schema: z
    .object({
      number: z.number().int().min(1010000).max(9990000).step(10000),
      tenThousands: z.number().int().min(0).max(90000),
      hundredThousands: z.number().int().min(0).max(900000),
      millions: z.number().int().min(1000000).max(9000000),
      unitOfAnswer: z.enum(['ten thousands', 'hundred thousands', 'millions'])
    })
    .refine(
      val => val.tenThousands + val.hundredThousands + val.millions === val.number,
      'Ten thousands, hundred thousands and millions must be equal to the whole'
    ),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray([
      'ten thousands',
      'hundred thousands',
      'millions'
    ] as const);
    // Conditions to ensure the answer needed is never 0
    const tenThousands =
      unitOfAnswer === 'ten thousands'
        ? randomIntegerInclusive(1, 9) * 10000
        : randomIntegerInclusive(0, 9) * 10000;
    // Added condition of ensuring that ten-thousands and hundred-thousands are never both 0
    const hundredThousands =
      tenThousands === 0 || unitOfAnswer === 'hundred thousands'
        ? randomIntegerInclusive(1, 9) * 100000
        : randomIntegerInclusive(0, 9) * 100000;
    const millions = randomIntegerInclusive(1, 9) * 1000000;
    const number = tenThousands + hundredThousands + millions;

    return { number, tenThousands, hundredThousands, millions, unitOfAnswer };
  },
  Component: ({ question, ...props }) => {
    const { number, tenThousands, hundredThousands, millions, unitOfAnswer } = question;

    const { translate } = props;

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'ten thousands') {
      partition = ['$ans', millions + hundredThousands];
      correctAnswer = tenThousands;
    } else if (unitOfAnswer === 'hundred thousands') {
      partition = ['$ans', millions + tenThousands];
      correctAnswer = hundredThousands;
    } else {
      partition = [hundredThousands + tenThousands, '$ans'];
      correctAnswer = millions;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo10000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
