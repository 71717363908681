import { newBlockContent } from '../../../Block';

import IntroducePartsAndWholes from './1IntroducePartsAndWholes';
import PartWholeModel from './2PartWholeModel';
import WriteNumberSentences from './3WriteNumberSentences';
import FactFamilesAdditionFacts from './4FactFamilesAdditionFacts';
import NumberBondsWithin10 from './5NumberBondsWithin10';
import SystematicNumberBondsWithin10 from './6SystematicNumberBondsWithin10';
import NumberBondsTo10 from './7NumberBondsTo10';
import AdditionAddTogether from './8AdditionAddTogether';
import AdditionAddMore from './9AdditionAddMore';
import AdditionProblems from './10AdditionProblems';
import FindAPart from './11FindAPart';
import SubtractionFindAPart from './12SubtractionFindAPart';
import FactFamiliesTheEightFacts from './13FactFamiliesTheEightFacts';
import SubtractionTakeAwayCrossOut from './14SubtractionTakeAwayCrossOut';
import SubtractionTakeAway from './15SubtractionTakeAway';
import SubtractionOnANumberLine from './16SubtractionOnANumberLine';
import AddOrSubtract1Or2 from './17AddOrSubtract1Or2';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [6, 10],
  smallSteps: [
    IntroducePartsAndWholes, // Step 1
    PartWholeModel, // Step 2
    WriteNumberSentences, // Step 3
    FactFamilesAdditionFacts, // Step 4
    NumberBondsWithin10, // Step 5
    SystematicNumberBondsWithin10, // Step 6
    NumberBondsTo10, // Step 7
    AdditionAddTogether, // Step 8
    AdditionAddMore, // Step 9
    AdditionProblems, // Step 10
    FindAPart, // Step 11
    SubtractionFindAPart, // Step 12
    FactFamiliesTheEightFacts, // Step 13
    SubtractionTakeAwayCrossOut, // Step 14
    SubtractionTakeAway, // Step 15
    SubtractionOnANumberLine, // Step 16
    AddOrSubtract1Or2 // Step 17
  ]
});
export default Block;
