import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { MULT, DIV } from 'common/src/constants';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import { range } from '../../../../utils/collections';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { colors } from '../../../../theme/colors';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aF0',
  description: 'aF0',
  keywords: ['Array', 'Multiplication', 'Division', 'Fact family', 'Ones', 'Counters'],
  schema: z.object({
    columnsOrRowsA: z.number().int().min(2).max(12),
    columnsOrRowsB: z.number().int().min(2).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columnsOrRowsB = randomIntegerInclusive(2, 5);

    const columnsOrRowsA = randomIntegerInclusive(2, 12, {
      constraint: x => x !== columnsOrRowsB
    });

    return { columnsOrRowsA, columnsOrRowsB };
  },
  Component: props => {
    const {
      question: { columnsOrRowsA, columnsOrRowsB },
      translate
    } = props;

    // Ensure the array is never wider than its height
    const [columns, rows] =
      columnsOrRowsA <= columnsOrRowsB
        ? [columnsOrRowsA, columnsOrRowsB]
        : [columnsOrRowsB, columnsOrRowsA];

    const product = columnsOrRowsA * columnsOrRowsB;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.whatMultAndDivFactsDoesTheArrayShow()}
        testCorrect={answer => {
          const colRowsAString = columnsOrRowsA.toString();
          const colsRowsBString = columnsOrRowsB.toString();
          const productStr = product.toString();

          const checkPairMultiplication = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val2 &&
            answer[index2][1] === val1 &&
            answer[index2][2] === val3;

          const checkPairDivision = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val1 &&
            answer[index2][1] === val3 &&
            answer[index2][2] === val2;

          const isFirstConditionMet =
            checkPairMultiplication(0, 1, colRowsAString, colsRowsBString, productStr) ||
            checkPairMultiplication(0, 1, colsRowsBString, colRowsAString, productStr);
          const isSecondConditionMet =
            checkPairDivision(2, 3, productStr, colsRowsBString, colRowsAString) ||
            checkPairDivision(2, 3, productStr, colRowsAString, colsRowsBString);

          return isFirstConditionMet && isSecondConditionMet;
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={<AssetSvg name="Place_value/1" width={dimens.height / rows} />}
          />
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aF1',
  description: 'aF1',
  keywords: ['Array', 'Multiplication', 'Division', 'Fact family', 'Tens', 'Counters'],
  schema: z.object({
    columnsOrRowsA: z.number().int().min(2).max(12),
    columnsOrRowsB: z.number().int().min(2).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columnsOrRowsB = randomIntegerInclusive(2, 5);

    const columnsOrRowsA = randomIntegerInclusive(2, 12, {
      constraint: x => x !== columnsOrRowsB
    });

    return { columnsOrRowsA, columnsOrRowsB };
  },
  Component: props => {
    const {
      question: { columnsOrRowsA, columnsOrRowsB },
      translate
    } = props;

    // Ensure the array is never wider than its height
    const [columns, rows] =
      columnsOrRowsA <= columnsOrRowsB
        ? [columnsOrRowsA, columnsOrRowsB]
        : [columnsOrRowsB, columnsOrRowsA];

    const product = columnsOrRowsA * columnsOrRowsB * 10;

    const answerCheck = (ans1a: string, ans1b: string, ans2a: string, ans2b: string) => {
      return (
        ((ans1a === columnsOrRowsA.toString() && ans1b === (columnsOrRowsB * 10).toString()) ||
          (ans1a === (columnsOrRowsA * 10).toString() && ans1b === columnsOrRowsB.toString()) ||
          (ans1a === columnsOrRowsB.toString() && ans1b === (columnsOrRowsA * 10).toString()) ||
          (ans1a === (columnsOrRowsB * 10).toString() && ans1b === columnsOrRowsA.toString())) &&
        ((ans2a === columnsOrRowsA.toString() && ans2b === (columnsOrRowsB * 10).toString()) ||
          (ans2a === (columnsOrRowsA * 10).toString() && ans2b === columnsOrRowsB.toString()) ||
          (ans2a === columnsOrRowsB.toString() && ans2b === (columnsOrRowsA * 10).toString()) ||
          (ans2a === (columnsOrRowsB * 10).toString() && ans2b === columnsOrRowsA.toString())) &&
        ans1a !== ans2a &&
        ans1b !== ans2b
      );
    };

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.whatMultAndDivFactsDoesTheArrayShow()}
        testCorrect={answer =>
          // Multiplication checks:
          answerCheck(answer[0][0], answer[0][1], answer[1][0], answer[1][1]) &&
          answer[0][2] === product.toString() &&
          answer[1][2] === product.toString() &&
          // Division checks:
          answerCheck(answer[2][1], answer[2][2], answer[3][1], answer[3][2]) &&
          answer[2][0] === product.toString() &&
          answer[3][0] === product.toString()
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={<AssetSvg name="Place_value/10" width={dimens.height / rows} />}
          />
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aF2',
  description: 'aF2',
  keywords: ['Base 10', 'Multiplication', 'Division', 'Fact family', 'Ones'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    onesPerGroup: z.number().int().min(1).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 5);

    const onesPerGroup = randomIntegerInclusive(1, 5, {
      constraint: x => x !== groups
    });

    return { groups, onesPerGroup };
  },
  Component: props => {
    const {
      question: { groups, onesPerGroup },
      translate
    } = props;

    const product = groups * onesPerGroup;

    const groupsArrangement = (() => {
      switch (groups) {
        case 1:
          return [[onesPerGroup]];
        case 2:
          return [[onesPerGroup], [onesPerGroup]];
        case 3:
          return [[onesPerGroup, onesPerGroup], [onesPerGroup]];
        case 4:
          return [
            [onesPerGroup, onesPerGroup],
            [onesPerGroup, onesPerGroup]
          ];
        default:
        case 5:
          return [[onesPerGroup, onesPerGroup], [onesPerGroup, onesPerGroup], [onesPerGroup]];
      }
    })();

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeMultAndDivFacts()}
        testCorrect={answer => {
          const groupsStr = groups.toString();
          const onesPerGroupStr = onesPerGroup.toString();
          const productStr = product.toString();

          const checkPairMultiplication = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val2 &&
            answer[index2][1] === val1 &&
            answer[index2][2] === val3;

          const checkPairDivision = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val1 &&
            answer[index2][1] === val3 &&
            answer[index2][2] === val2;

          const isFirstConditionMet =
            checkPairMultiplication(0, 1, groupsStr, onesPerGroupStr, productStr) ||
            checkPairMultiplication(0, 1, onesPerGroupStr, groupsStr, productStr);
          const isSecondConditionMet =
            checkPairDivision(2, 3, productStr, onesPerGroupStr, groupsStr) ||
            checkPairDivision(2, 3, productStr, groupsStr, onesPerGroupStr);

          return isFirstConditionMet && isSecondConditionMet;
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <View style={dimens}>
            {
              // Map over all rows:
              range(1, groupsArrangement.length).map((num, index) => (
                <View
                  key={num}
                  style={{
                    width: dimens.width,
                    height: dimens.height / groupsArrangement.length,
                    flexDirection: 'row'
                  }}
                >
                  {
                    // Map over each row:
                    range(1, groupsArrangement[index].length).map(num => (
                      <MeasureView
                        containerStyle={{
                          justifyContent: 'center'
                        }}
                        key={num}
                        style={{
                          borderColor: colors.pacificBlue,
                          borderRadius: 12,
                          padding: 36,
                          borderWidth: 2,
                          maxWidth: dimens.width * 0.45,
                          maxHeight: dimens.height * 0.3,
                          alignSelf: 'center'
                        }}
                      >
                        {cellDimens => (
                          <BaseTenRepresentation
                            b10Rep={{
                              variant: 'Cubes',
                              numbers: { ones: onesPerGroup },
                              arrangement: 'ltr'
                            }}
                            usableWidth={cellDimens.width}
                            usableHeight={cellDimens.height}
                          />
                        )}
                      </MeasureView>
                    ))
                  }
                </View>
              ))
            }
          </View>
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aF3',
  description: 'aF3',
  keywords: ['Base 10', 'Multiplication', 'Division', 'Fact family', 'Tens'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    tensPerGroup: z.number().int().min(1).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 5);

    const tensPerGroup = randomIntegerInclusive(1, 5, {
      constraint: x => x !== groups
    });

    return { groups, tensPerGroup };
  },
  Component: props => {
    const {
      question: { groups, tensPerGroup },
      translate
    } = props;

    const product = groups * tensPerGroup * 10;

    const groupsArrangement = (() => {
      switch (groups) {
        case 1:
          return [[tensPerGroup]];
        case 2:
          return [[tensPerGroup], [tensPerGroup]];
        case 3:
          return [[tensPerGroup, tensPerGroup], [tensPerGroup]];
        case 4:
          return [
            [tensPerGroup, tensPerGroup],
            [tensPerGroup, tensPerGroup]
          ];
        default:
        case 5:
          return [[tensPerGroup, tensPerGroup], [tensPerGroup, tensPerGroup], [tensPerGroup]];
      }
    })();

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeMultAndDivFacts()}
        testCorrect={answer => {
          const groupsStr = groups.toString();
          const tensPerGroupStr = (tensPerGroup * 10).toString();
          const productStr = product.toString();

          const checkPairMultiplication = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val2 &&
            answer[index2][1] === val1 &&
            answer[index2][2] === val3;

          const checkPairDivision = (
            index1: number,
            index2: number,
            val1: string,
            val2: string,
            val3: string
          ) =>
            answer[index1][0] === val1 &&
            answer[index1][1] === val2 &&
            answer[index1][2] === val3 &&
            answer[index2][0] === val1 &&
            answer[index2][1] === val3 &&
            answer[index2][2] === val2;

          const isFirstConditionMet =
            checkPairMultiplication(0, 1, groupsStr, tensPerGroupStr, productStr) ||
            checkPairMultiplication(0, 1, tensPerGroupStr, groupsStr, productStr);
          const isSecondConditionMet =
            checkPairDivision(2, 3, productStr, tensPerGroupStr, groupsStr) ||
            checkPairDivision(2, 3, productStr, groupsStr, tensPerGroupStr);

          return isFirstConditionMet && isSecondConditionMet;
        }}
        inputMaxCharacters={3}
        {...props}
        Content={({ dimens }) => (
          <View style={dimens}>
            {
              // Map over all rows:
              range(1, groupsArrangement.length).map((num, index) => (
                <View
                  key={num}
                  style={{
                    width: dimens.width,
                    height: dimens.height / groupsArrangement.length,
                    flexDirection: 'row'
                  }}
                >
                  {
                    // Map over each row:
                    range(1, groupsArrangement[index].length).map(num => (
                      <MeasureView
                        containerStyle={{
                          justifyContent: 'center'
                        }}
                        key={num}
                        style={{
                          borderColor: colors.pacificBlue,
                          borderRadius: 12,
                          padding: 36,
                          borderWidth: 2,
                          maxWidth: dimens.width * 0.45,
                          maxHeight: dimens.height * 0.3,
                          alignSelf: 'center'
                        }}
                      >
                        {cellDimens => (
                          <BaseTenRepresentation
                            b10Rep={{
                              variant: 'Cubes',
                              numbers: { tens: tensPerGroup },
                              arrangement: 'ltr'
                            }}
                            usableWidth={cellDimens.width}
                            usableHeight={cellDimens.height}
                          />
                        )}
                      </MeasureView>
                    ))
                  }
                </View>
              ))
            }
          </View>
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aF4',
  description: 'aF4',
  keywords: ['Multiplication', 'Division', 'Tens'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: numberEnum([2, 3, 4, 5, 8, 10])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [(number1 * number2).toString()],
          [(number1 * number2).toString()],
          [(number1 * number2 * 10).toString()]
        ]}
        sentences={[
          translate.answerSentences.numOnesTimesNumEqualsAnsOnes(number1, number2),
          translate.answerSentences.numTensTimesNumEqualsAnsTens(number1, number2),
          `${(number1 * 10).toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`
        ]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aF5',
  description: 'aF5',
  keywords: ['Multiplication', 'Division', 'Tens'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 5, 8]),
    number2: z.number().int().min(2).max(9),
    number3: z.number().int().min(20).max(90).multipleOf(10),
    number4: z.number().int().min(20).max(60).multipleOf(10),
    number5: z.number().int().min(70).max(120).multipleOf(10)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 8] as const);

    const number2 = randomIntegerInclusive(2, 9);

    const number3 = randomIntegerInclusiveStep(20, 90, 10);

    const number4 = randomIntegerInclusiveStep(20, 60, 10);

    const number5 = randomIntegerInclusiveStep(70, 120, 10);

    return { number1, number2, number3, number4, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5 },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[
          [number2.toString()],
          [number3.toString()],
          [number4.toString()],
          [number5.toString()]
        ]}
        sentences={[
          `${number1.toLocaleString()} ${MULT} <ans/> = ${(number1 * number2).toLocaleString()}`,
          `${number1.toLocaleString()} ${MULT} <ans/> = ${(number1 * number3).toLocaleString()}`,
          `<ans/> ${MULT} ${number1.toLocaleString()} = ${(number1 * number4).toLocaleString()}`,
          `${(number1 * number5).toLocaleString()} = ${number1.toLocaleString()} ${MULT} <ans/>`
        ]}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LinkMultiplicationAndDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
