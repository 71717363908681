import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { Text as SvgText, TSpan } from 'react-native-svg';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  nestedArrayHasNoDuplicates
} from '../../../../utils/collections';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import { GridPolygon } from 'common/src/utils/gridUtils';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAS',
  description: 'aAS',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    isSquare: z.boolean(),
    coordinates: z.array(z.number().int().min(0).max(6).array().length(2)).length(4),
    labelledPoint: z.number().int().min(0).max(3)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();
    const width = randomIntegerInclusive(isSquare ? 2 : 1, 4);
    const height = isSquare
      ? width
      : randomIntegerInclusive(1, 4, { constraint: x => x !== width });

    const coordinate1 = [
      randomIntegerInclusive(1, 6 - width),
      randomIntegerInclusive(1 + height, 6)
    ];
    const coordinate2 = [coordinate1[0] + width, coordinate1[1]];
    const coordinate3 = [coordinate1[0] + width, coordinate1[1] - height];
    const coordinate4 = [coordinate1[0], coordinate1[1] - height];

    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];

    const labelledPoint = randomIntegerInclusive(0, 3);

    return {
      isSquare,
      coordinates,
      labelledPoint
    };
  },
  Component: ({ question: { isSquare, coordinates, labelledPoint }, translate, displayMode }) => {
    const answers = coordinates
      .filter((_val, i) => i !== labelledPoint)
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.aShapeIsDrawnOnGridWriteCoordinatesOfOthers(
          isSquare ? translate.shapes.squares(1) : translate.shapes.rectangles(1),
          translate.numbersAsWords[3]()
        )}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        testCorrect={userAnswer =>
          nestedArrayHasNoDuplicates(userAnswer, true) &&
          userAnswer.every(
            ans =>
              arraysHaveSameContents(ans, answers[0]) ||
              arraysHaveSameContents(ans, answers[1]) ||
              arraysHaveSameContents(ans, answers[2])
          )
        }
        questionHeight={1000}
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: coordinates
            .filter((_val, i) => i !== labelledPoint)
            .map(coord => coord.map(i => i.toLocaleString())),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={6}
            yMax={6}
            xLabels={null}
            yLabels={null}
            squareGrid
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <SvgText
                  y={mathToSvgY(coordinates[labelledPoint][1] + (labelledPoint > 1 ? -0.5 : 0.25))}
                  x={mathToSvgX(coordinates[labelledPoint][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[labelledPoint][0].toLocaleString(),
                      coordinates[labelledPoint][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <GridPolygon
                  points={[
                    coordinates[0] as [number, number],
                    coordinates[1] as [number, number],
                    coordinates[2] as [number, number],
                    coordinates[3] as [number, number]
                  ]}
                  showBorder
                />
              </GridSvgChildren>
            )}
          ></Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAT',
  description: 'aAT',
  keywords: ['Coordinate', 'Coordinate grid'],
  questionHeight: 1000,
  schema: z.object({
    coordinates: z.array(z.number().int().min(0).max(6).array().length(2)).length(3),
    labelledPoint: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(1, 4);
    const height = randomIntegerInclusive(1, 4);

    const coordinate1 = [
      randomIntegerInclusive(1, 6 - width),
      randomIntegerInclusive(1 + height, 6)
    ];
    const coordinate2 = [coordinate1[0], coordinate1[1] - height];
    const coordinate3 = [coordinate1[0] + width, coordinate1[1] - height];

    const coordinates = [coordinate1, coordinate2, coordinate3];

    const labelledPoint = randomIntegerInclusive(0, 2);

    return {
      coordinates,
      labelledPoint
    };
  },
  Component: ({ question: { coordinates, labelledPoint }, translate, displayMode }) => {
    const answers = coordinates
      .filter((_val, i) => i !== labelledPoint)
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.aShapeIsDrawnOnGridWriteCoordinatesOfOthers(
          translate.shapes.rightAngledTriangles(1),
          translate.numbersAsWords[2]()
        )}
        questionHeight={1000}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        testCorrect={userAnswer =>
          nestedArrayHasNoDuplicates(userAnswer, true) &&
          userAnswer.every(
            ans =>
              arraysHaveSameContents(ans, answers[0]) || arraysHaveSameContents(ans, answers[1])
          )
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: coordinates
            .filter((_val, i) => i !== labelledPoint)
            .map(coord => coord.map(i => i.toLocaleString())),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={6}
            yMax={6}
            xLabels={null}
            yLabels={null}
            squareGrid
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <SvgText
                  y={mathToSvgY(coordinates[labelledPoint][1] + (labelledPoint > 0 ? -0.5 : 0.25))}
                  x={mathToSvgX(coordinates[labelledPoint][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[labelledPoint][0].toLocaleString(),
                      coordinates[labelledPoint][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <GridPolygon
                  points={[
                    coordinates[0] as [number, number],
                    coordinates[1] as [number, number],
                    coordinates[2] as [number, number]
                  ]}
                  showBorder
                />
              </GridSvgChildren>
            )}
          ></Grid>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAU',
  description: 'aAU',
  questionHeight: 1000,
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    isSquare: z.boolean(),
    coordinates: z.array(z.number().int().min(0).max(12).step(2).array().length(2)).length(4),
    labelledPoint: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();
    const width = randomIntegerInclusiveStep(isSquare ? 4 : 2, 8, 2);
    const height = isSquare
      ? width
      : randomIntegerInclusiveStep(2, 8, 2, { constraint: x => x !== width });

    const coordinate1 = [
      randomIntegerInclusiveStep(2, 12 - width, 2),
      randomIntegerInclusiveStep(2 + height, 12, 2)
    ];
    const coordinate2 = [coordinate1[0] + width, coordinate1[1]];
    const coordinate3 = [coordinate1[0] + width, coordinate1[1] - height];
    const coordinate4 = [coordinate1[0], coordinate1[1] - height];

    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];

    const labelledPoint = randomIntegerInclusive(0, 3);

    return {
      isSquare,
      coordinates,
      labelledPoint
    };
  },
  Component: ({ question: { isSquare, coordinates, labelledPoint }, translate, displayMode }) => {
    const answers = coordinates
      .filter((_val, i) => i !== labelledPoint)
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.aShapeIsDrawnOnGridWriteCoordinatesOfOthers(
          isSquare ? translate.shapes.squares(1) : translate.shapes.rectangles(1),
          translate.numbersAsWords[3]()
        )}
        questionHeight={1000}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        testCorrect={userAnswer =>
          nestedArrayHasNoDuplicates(userAnswer, true) &&
          userAnswer.every(
            ans =>
              arraysHaveSameContents(ans, answers[0]) ||
              arraysHaveSameContents(ans, answers[1]) ||
              arraysHaveSameContents(ans, answers[2])
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: coordinates
            .filter((_val, i) => i !== labelledPoint)
            .map(coord => coord.map(i => i.toLocaleString())),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={12}
            yMax={12}
            xLabels={null}
            yLabels={null}
            xStepSize={2}
            yStepSize={2}
            squareGrid
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <SvgText
                  y={mathToSvgY(coordinates[labelledPoint][1] + (labelledPoint > 1 ? -1 : 0.5))}
                  x={mathToSvgX(coordinates[labelledPoint][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[labelledPoint][0].toLocaleString(),
                      coordinates[labelledPoint][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <GridPolygon
                  points={[
                    coordinates[0] as [number, number],
                    coordinates[1] as [number, number],
                    coordinates[2] as [number, number],
                    coordinates[3] as [number, number]
                  ]}
                  showBorder
                />
              </GridSvgChildren>
            )}
          ></Grid>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAV',
  description: 'aAV',
  questionHeight: 1000,
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinates: z.array(z.number().int().min(0).max(12).step(2).array().length(2)).length(3),
    labelledPoint: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusiveStep(2, 8, 2);
    const height = randomIntegerInclusiveStep(2, 8, 2);

    const coordinate1 = [
      randomIntegerInclusiveStep(2, 12 - width, 2),
      randomIntegerInclusiveStep(2 + height, 12, 2)
    ];
    const coordinate2 = [coordinate1[0], coordinate1[1] - height];
    const coordinate3 = [coordinate1[0] + width, coordinate1[1] - height];

    const coordinates = [coordinate1, coordinate2, coordinate3];

    const labelledPoint = randomIntegerInclusive(0, 2);

    return {
      coordinates,
      labelledPoint
    };
  },
  Component: ({ question: { coordinates, labelledPoint }, translate, displayMode }) => {
    const answers = coordinates
      .filter((_val, i) => i !== labelledPoint)
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.aShapeIsDrawnOnGridWriteCoordinatesOfOthers(
          translate.shapes.rightAngledTriangles(1),
          translate.numbersAsWords[2]()
        )}
        questionHeight={1000}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        testCorrect={userAnswer =>
          nestedArrayHasNoDuplicates(userAnswer, true) &&
          userAnswer.every(
            ans =>
              arraysHaveSameContents(ans, answers[0]) || arraysHaveSameContents(ans, answers[1])
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: coordinates
            .filter((_val, i) => i !== labelledPoint)
            .map(coord => coord.map(i => i.toLocaleString())),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={12}
            yMax={12}
            xLabels={null}
            yLabels={null}
            xStepSize={2}
            yStepSize={2}
            squareGrid
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <SvgText
                  y={mathToSvgY(coordinates[labelledPoint][1] + (labelledPoint > 0 ? -1 : 0.5))}
                  x={mathToSvgX(coordinates[labelledPoint][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[labelledPoint][0].toLocaleString(),
                      coordinates[labelledPoint][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <GridPolygon
                  points={[
                    coordinates[0] as [number, number],
                    coordinates[1] as [number, number],
                    coordinates[2] as [number, number]
                  ]}
                  showBorder
                />
              </GridSvgChildren>
            )}
          ></Grid>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAW',
  description: 'aAW',
  keywords: ['Coordinate', 'Coordinate grid'],
  questionHeight: 1000,
  schema: z.object({
    isSquare: z.boolean(),
    x1: z.number().int().min(0).max(8),
    y1: z.number().int().min(0).max(8),
    x2: z.number().int().min(0).max(8),
    y2: z.number().int().min(0).max(8),
    labelledPoint: z.number().int().min(0).max(3),
    pointA: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isSquare = getRandomBoolean();
        const width = randomIntegerInclusive(isSquare ? 3 : 2, 5);
        const height = isSquare
          ? width
          : randomIntegerInclusive(2, 4, { constraint: x => x !== width });

        const x1 = randomIntegerInclusive(2, 8 - width);
        const y1 = randomIntegerInclusive(2 + height, 8, {
          constraint: y => y !== x1 && y - height !== x1
        });
        const x2 = x1 + width;
        const y2 = y1 - height;

        const labelledPoint = randomIntegerInclusive(0, 3);
        const isLeft = getRandomBoolean();
        const point = isLeft ? labelledPoint + 1 : labelledPoint - 1;
        const pointA = point < 0 ? 3 : point > 3 ? 0 : point;

        return {
          isSquare,
          x1,
          y1,
          x2,
          y2,
          labelledPoint,
          pointA
        };
      },
      val => arrayHasNoDuplicates([val.x1, val.x2, val.y1, val.y2])
    ),
  Component: ({
    question: { isSquare, x1, y1, x2, y2, labelledPoint, pointA },
    translate,
    displayMode
  }) => {
    const coordinates = [
      [x1, y1],
      [x2, y1],
      [x2, y2],
      [x1, y2]
    ];

    const number1 = coordinates[labelledPoint][0];
    const number2 = coordinates[labelledPoint][1];
    const number3 = coordinates[labelledPoint][1] === y1 ? y2 : y1;
    const number4 = coordinates[labelledPoint][0] === x1 ? x2 : x1;

    const selectables = [
      [
        [number1, number3],
        translate.answerSentences.xYCoordinate(number1.toLocaleString(), number3.toLocaleString())
      ],
      [
        [number4, number2],
        translate.answerSentences.xYCoordinate(number4.toLocaleString(), number2.toLocaleString())
      ],
      [
        [number2, number4],
        translate.answerSentences.xYCoordinate(number2.toLocaleString(), number4.toLocaleString())
      ],
      [
        [number3, number1],
        translate.answerSentences.xYCoordinate(number3.toLocaleString(), number1.toLocaleString())
      ]
    ];

    const answerIndex = selectables
      .map(val => val[0] as number[])
      .findIndex(x => arraysHaveSameContents(x, coordinates[pointA]));

    const answer = selectables[answerIndex][0] as number[];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.aShapeIsDrawnOnGridSelectPointA(
          isSquare ? translate.shapes.squares(1) : translate.shapes.rectangles(1)
        )}
        pdfTitle={translate.instructions.aShapeIsDrawnOnGridCirclePointA(
          isSquare ? translate.shapes.squares(1) : translate.shapes.rectangles(1)
        )}
        questionHeight={1000}
        selectables={Object.fromEntries(
          shuffle(selectables, {
            random: seededRandom({ isSquare, coordinates, labelledPoint, pointA })
          })
        )}
        correctAnswer={[answer.toString()]}
        selectableTextStyle={{ fontWeight: '700' }}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xMax={10}
                yMax={10}
                xLabels={null}
                yLabels={null}
                hideGridLines
                squareGrid
                children={({ mathToSvgX, mathToSvgY }) => (
                  <GridSvgChildren>
                    <SvgText
                      y={mathToSvgY(coordinates[labelledPoint][1] + (labelledPoint > 1 ? -1 : 0.4))}
                      x={mathToSvgX(coordinates[labelledPoint][0])}
                      textAnchor="middle"
                    >
                      <TSpan
                        fontSize={displayMode === 'digital' ? '28' : 40}
                        fill="black"
                        fontFamily="White_Rose_Noto-Regular"
                      >
                        {translate.answerSentences.xYCoordinate(
                          coordinates[labelledPoint][0].toLocaleString(),
                          coordinates[labelledPoint][1].toLocaleString()
                        )}
                      </TSpan>
                    </SvgText>
                    <SvgText
                      y={mathToSvgY(coordinates[pointA][1] + (pointA > 1 ? -1 : 0.4))}
                      x={mathToSvgX(coordinates[pointA][0])}
                      textAnchor="middle"
                    >
                      <TSpan
                        fontSize={displayMode === 'digital' ? '28' : 40}
                        fill="black"
                        fontFamily="White_Rose_Noto-Regular"
                      >
                        {translate.letters.A()}
                      </TSpan>
                    </SvgText>
                    <GridPolygon
                      points={[
                        coordinates[0] as [number, number],
                        coordinates[1] as [number, number],
                        coordinates[2] as [number, number],
                        coordinates[3] as [number, number]
                      ]}
                      showBorder
                    />
                  </GridSvgChildren>
                )}
              ></Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
] as const;

export const alphabetSchema = z.enum(alphabet);

const Question6 = newQuestionContent({
  uid: 'aAX',
  description: 'aAX',
  questionHeight: 1300,
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinates: z.array(z.number().int().min(2).max(12).array().length(2)).length(4),
    letters: alphabetSchema.array().length(2)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 6);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x !== width });

    const coordinate1 = [
      randomIntegerInclusive(2, 12 - width),
      randomIntegerInclusive(2 + height, 12)
    ];
    const coordinate2 = [coordinate1[0] + width, coordinate1[1]];
    const coordinate3 = [coordinate1[0] + width, coordinate1[1] - height];
    const coordinate4 = [coordinate1[0], coordinate1[1] - height];

    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];

    const letterIndex = randomIntegerInclusive(0, 24);
    const letters = [alphabet[letterIndex], alphabet[letterIndex + 1]];
    return {
      coordinates,
      letters
    };
  },
  Component: ({ question: { coordinates, letters }, translate, displayMode }) => {
    const answers = coordinates
      .filter((_val, i) => i !== 1 && i !== 3)
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.aShapeIsDrawnOnGridWriteCoordinatesOfXY(
          translate.shapes.rectangles(1),
          translate.letters[letters[0]](),
          translate.letters[letters[1]]()
        )}
        questionHeight={1300}
        sentences={[`${letters[0]} = ( <ans/> , <ans/> )`, `${letters[1]} = ( <ans/> , <ans/> )`]}
        testCorrect={answers}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignItems: 'flex-end' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={12}
            yMax={12}
            xLabels={null}
            yLabels={null}
            squareGrid
            hideGridLines
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <SvgText
                  y={mathToSvgY(coordinates[0][1] + 0.35)}
                  x={mathToSvgX(coordinates[0][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.letters[letters[0]]()}
                  </TSpan>
                </SvgText>
                <SvgText
                  y={mathToSvgY(coordinates[1][1] + 0.35)}
                  x={mathToSvgX(coordinates[1][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[1][0].toLocaleString(),
                      coordinates[1][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <SvgText
                  y={mathToSvgY(coordinates[2][1] - 1)}
                  x={mathToSvgX(coordinates[2][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.letters[letters[1]]()}
                  </TSpan>
                </SvgText>
                <SvgText
                  y={mathToSvgY(coordinates[3][1] - 1)}
                  x={mathToSvgX(coordinates[3][0])}
                  textAnchor="middle"
                >
                  <TSpan
                    fontSize={displayMode === 'digital' ? '28' : 40}
                    fill="black"
                    fontFamily="White_Rose_Noto-Regular"
                  >
                    {translate.answerSentences.xYCoordinate(
                      coordinates[3][0].toLocaleString(),
                      coordinates[3][1].toLocaleString()
                    )}
                  </TSpan>
                </SvgText>
                <GridPolygon
                  points={[
                    coordinates[0] as [number, number],
                    coordinates[1] as [number, number],
                    coordinates[2] as [number, number],
                    coordinates[3] as [number, number]
                  ]}
                  showBorder
                />
              </GridSvgChildren>
            )}
          ></Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ProblemSolvingWithCoordinates',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
