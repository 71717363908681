import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import {
  displayMoney,
  moneyFromXDenominations,
  penceToPoundsAndPence,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import TextStructure from '../../../../components/molecules/TextStructure';
import { countRange, sortNumberArray, sumNumberArray } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bir',
  description: 'bir',
  keywords: ['Add', 'Coins', 'Notes', 'Pounds', 'Pence', 'Money'],
  schema: z.object({
    moneyObjects: z.object({ value: z.number(), denominations: z.string().array() }).array(),
    characterNameA: ks1NameSchema,
    characterNameB: ks1NameSchema,
    variation: z.enum(['bothHavePence', 'bothHavePounds', 'bothPoundsAndPence'])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray([
      'bothHavePence',
      'bothHavePounds',
      'bothPoundsAndPence'
    ] as const);

    const { moneyObjects } = rejectionSample(
      () => {
        const [penceA, penceB] = (() => {
          switch (variation) {
            case 'bothHavePence':
              return countRange(2).map(() => randomIntegerInclusive(1, 99));
            case 'bothHavePounds':
              return countRange(2).map(() => randomIntegerInclusiveStep(100, 9900, 100));
            case 'bothPoundsAndPence':
              return countRange(2).map(() =>
                randomIntegerInclusive(101, 9999, { constraint: x => x % 100 !== 0 })
              );
          }
        })();

        const denominationsA = totalPenceToPoundsAndPence(penceA);
        const denominationsB = totalPenceToPoundsAndPence(penceB);

        const moneyObjects = [
          { value: penceA, denominations: denominationsA },
          { value: penceB, denominations: denominationsB }
        ];

        return { moneyObjects };
      },
      ({ moneyObjects }) =>
        moneyObjects.every(
          ({ denominations }) =>
            // Denominations length cannot be more than 4
            denominations.length <= 4 &&
            // Dont include £50
            !denominations.includes('£50') &&
            // Total of both numbers must be less then 10000 (£100)
            sumNumberArray(moneyObjects.map(({ value }) => value)) < 10000 &&
            // Total of both numbers must include pence
            sumNumberArray(moneyObjects.map(({ value }) => value % 100)) < 100
        )
    );

    const [characterNameA, characterNameB] = getRandomUniqueKs1Names(2);

    return { moneyObjects, characterNameA, characterNameB, variation };
  },
  Component: ({ question, translate, displayMode }) => {
    const { moneyObjects, characterNameA, characterNameB, variation } = question;

    const scaledCoins = displayMoney(
      moneyObjects.map(({ denominations }) => denominations).flat(),
      displayMode === 'digital' ? 80 : 130,
      displayMode === 'digital' ? 80 : 130,
      true
    );

    const [denominationsA, denominationsB] = moneyObjects.map((_, index) =>
      index === 0
        ? scaledCoins.filter((_, index) => index <= moneyObjects[0].denominations.length - 1)
        : scaledCoins.filter((_, index) => index > moneyObjects[0].denominations.length - 1)
    );

    const [moneyA, moneyB] = moneyObjects.map(({ value }) => ({
      pounds: Math.floor(value / 100),
      pence: value % 100
    }));

    const total = sumNumberArray(moneyObjects.map(({ value }) => value));

    const answer =
      variation === 'bothHavePence'
        ? [total.toString()]
        : variation === 'bothHavePounds'
        ? [(total / 100).toString()]
        : [Math.floor(total / 100).toString(), (total % 100).toString()];

    const buildSentence = (charName: string, money: { pence: number; pounds: number }) => {
      return variation === 'bothHavePence'
        ? translate.ks1Instructions.characterHasXPence(charName, money.pence)
        : variation === 'bothHavePounds'
        ? translate.ks1Instructions.characterHasXPounds(charName, money.pounds)
        : translate.ks1Instructions.characterHasXPoundsAndYPence(
            charName,
            money.pounds,
            money.pence
          );
    };

    const sentence =
      variation === 'bothHavePence'
        ? translate.ks1AnswerSentences.ansP()
        : variation === 'bothHavePounds'
        ? translate.ks1AnswerSentences.poundAns()
        : translate.ks1AnswerSentences.poundAnsAndAnsPence();

    return (
      <QF1ContentAndSentence
        title={buildSentence(characterNameA, { pence: moneyA.pence, pounds: moneyA.pounds })}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={answer}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center'
              }}
            >
              {denominationsA}
            </View>
            <TextStructure
              sentence={buildSentence(characterNameB, {
                pence: moneyB.pence,
                pounds: moneyB.pounds
              })}
            />
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center'
              }}
            >
              {denominationsB}
            </View>
            <TextStructure
              sentence={translate.answerSentences.howMuchMoneyDoTheyHaveAltogether()}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bis',
  description: 'bis',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money', 'Subtract'],
  schema: z.object({
    moneyObjects: z
      .object({
        value: z.number().min(1).max(1999),
        denominations: z.string().array()
      })
      .array(),
    characterNameA: ks1NameSchema,
    characterNameB: ks1NameSchema,
    doesCharAHaveMore: z.boolean()
  }),
  simpleGenerator: () => {
    const showNonDirectComparisons = getRandomBoolean();

    const { moneyObjects } = rejectionSample(
      () => {
        const numberOfFDenominations = randomIntegerInclusive(5, 8);

        const denominationsA = moneyFromXDenominations(
          numberOfFDenominations,
          'pence',
          [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000]
        );

        const amountOfMoneyToRemove = showNonDirectComparisons
          ? denominationsA.valuesInP.length > 6
            ? getRandomFromArray([1, 2, 3] as const)
            : getRandomFromArray([1, 2] as const)
          : denominationsA.valuesInP.length > 6
          ? getRandomFromArray([2, 3, 4] as const)
          : getRandomFromArray([2, 3] as const);

        const denominationsB = showNonDirectComparisons
          ? getRandomSubArrayFromArray(
              denominationsA.valuesInP,
              denominationsA.valuesInP.length - (amountOfMoneyToRemove - 1)
            )
          : getRandomSubArrayFromArray(
              denominationsA.valuesInP,
              denominationsA.valuesInP.length - amountOfMoneyToRemove
            );

        let editedDenominationsB: number[] = [];

        // If showNonDirectComparisons === true replace last number (it’s random so could be anything from denominationsB) and replace with non direct comparison
        if (showNonDirectComparisons) {
          const filteredDenominationsB = denominationsB.filter(
            (_, index) => index !== denominationsB.length - 1
          );

          const lastNumberOnDenominationsB = denominationsB[denominationsB.length - 1] as
            | 1
            | 2
            | 5
            | 10
            | 20
            | 50
            | 100
            | 200
            | 500;

          // Lookup to exchange number for non direct comparison (only options we could fit on the question)
          const nonDirectComparisonsMap = (
            number: 1 | 2 | 5 | 10 | 20 | 50 | 100 | 200 | 500 | 1000
          ) => {
            const lookup = {
              1: [1],
              2: [1, 1],
              5: [2, 2, 1],
              10: [5, 5],
              20: [10, 10],
              50: [20, 20, 10],
              100: [50, 50],
              200: [100, 100],
              500: [200, 200, 100],
              1000: [500, 500]
            };

            return lookup[number];
          };

          editedDenominationsB = [
            ...nonDirectComparisonsMap(lastNumberOnDenominationsB),
            ...filteredDenominationsB
          ];
        }

        const moneyObjects = [
          {
            value: denominationsA.sum,
            denominations: denominationsA.denominations.map(denomination => {
              const valueInPence = parseInt(denomination.split('p')[0]);
              return valueInPence > 99 ? `£${valueInPence / 100}` : denomination;
            }),
            valuesInPence: denominationsA.valuesInP
          },
          {
            value: sumNumberArray(denominationsB),
            denominations: showNonDirectComparisons
              ? sortNumberArray(editedDenominationsB, 'descending').map(num =>
                  num > 99 ? `£${num / 100}` : `${num}p`
                )
              : sortNumberArray(denominationsB, 'descending').map(num =>
                  num > 99 ? `£${num / 100}` : `${num}p`
                ),
            valuesInPence: showNonDirectComparisons
              ? sortNumberArray(editedDenominationsB, 'descending')
              : sortNumberArray(denominationsB, 'descending')
          }
        ];

        // Step 1: Calculate the total number of subsets possible for `valuesInPence`.
        // - `Math.pow(2, n)` generates `2^n`, where `n` is the length of `valuesInPence`.
        // - Each subset corresponds to a unique combination of elements in the array.
        // - Example: If `valuesInPence.length === 2`, there are `2^2 = 4` subsets.
        const totalCombinations = Math.pow(2, moneyObjects[1].valuesInPence.length);

        // Step 2: Generate an array of combination numbers from 1 to `totalCombinations - 1`.
        // - We start from `1` to exclude the empty subset (`0`).
        // - Each number represents a subset based on its binary representation,
        //   where each bit indicates whether an element is included (`1`) or excluded (`0`).
        const allSumTotalsFromDenominationsB = countRange(totalCombinations - 1)
          .map(combinationNumber => {
            // Step 3: Create the subset corresponding to `combinationNumber`.
            // - For each element in `valuesInPence`, check if its corresponding bit in
            //   `combinationNumber` is set (`1`).
            // - `(combinationNumber & (1 << valuesInPenceIndex)) !== 0`:
            //   - `(1 << valuesInPenceIndex)` shifts `1` left by `valuesInPenceIndex` places,
            //     creating a mask with only the `valuesInPenceIndex`th bit set.
            //   - The bitwise AND `&` checks if this bit in `combinationNumber` is `1`.
            //   - If true, include the element at `valuesInPenceIndex` in the subset.
            const subset = moneyObjects[1].valuesInPence.filter(
              (_, valuesInPenceIndex) => (combinationNumber & (1 << valuesInPenceIndex)) !== 0
            );
            // Step 4: Include the sum of the subset only if it has 2 or more elements.
            // - This ensures meaningful combinations (e.g., avoids single-element subsets).
            // - `sumNumberArray(subset)` computes the sum of all elements in the subset.
            // - Return `null` for subsets with fewer than 2 elements (they will be filtered out later).
            return subset.length >= 2 ? sumNumberArray(subset) : null;
          })
          // Step 5: Filter out `null` values to exclude subsets with fewer than 2 elements.
          .filter(sum => sum !== null) as number[];

        // Step 6: Remove duplicate sums by using a `Set`.
        // - The `Set` data structure automatically eliminates duplicates.
        // - Convert the `Set` back into an array for further processing.
        const uniqueSumTotalsFromallSumsFromDenominationsB = Array.from(
          new Set(allSumTotalsFromDenominationsB)
        );

        // Check if exactly one sum appears in the first array
        // This is only done if `showNonDirectComparisons` is true
        const multipleNonDirectComparisons = showNonDirectComparisons
          ? uniqueSumTotalsFromallSumsFromDenominationsB.filter(num =>
              moneyObjects[0].valuesInPence.includes(num)
            ).length !== 1
          : false;

        return { moneyObjects, multipleNonDirectComparisons };
      },
      ({ moneyObjects, multipleNonDirectComparisons }) =>
        // First array of money has to have a length between 5-8
        isInRange(5, 8)(moneyObjects[0].denominations.length) &&
        // Second array can have a length between 2-8
        isInRange(2, 8)(moneyObjects[1].denominations.length) &&
        // All money should have pounds and pence and values and be between £1 and £19.99
        moneyObjects.every(({ value }) => value % 100 !== 0 && isInRange(101, 1999)(value)) &&
        // Difference between the money should be atleast £1 and 1p
        Math.max(...moneyObjects.map(({ value }) => value)) -
          Math.min(...moneyObjects.map(({ value }) => value)) >=
          101 &&
        // Arrays should not have multiple non direct comparisons
        !multipleNonDirectComparisons &&
        // Money values should have different pence
        moneyObjects[0].value % 100 !== moneyObjects[1].value % 100 &&
        // Money values should only have a maximum of 2 notes
        moneyObjects.every(
          ({ valuesInPence }) => valuesInPence.filter(num => num >= 500).length <= 2
        )
    );

    const doesCharAHaveMore = getRandomBoolean();

    const [characterNameA, characterNameB] = getRandomUniqueKs1Names(2);

    return { moneyObjects, characterNameA, characterNameB, doesCharAHaveMore };
  },
  Component: ({ question, translate, displayMode }) => {
    const { moneyObjects, characterNameA, characterNameB, doesCharAHaveMore } = question;

    const scaledCoins = displayMoney(
      moneyObjects.map(({ denominations }) => denominations).flat(),
      displayMode === 'digital' ? 75 : 130,
      displayMode === 'digital' ? 75 : 130,
      true
    );

    const [denominationsA, denominationsB] = moneyObjects.map((_, index) =>
      index === 0
        ? scaledCoins.filter((_, index) => index <= moneyObjects[0].denominations.length - 1)
        : scaledCoins.filter((_, index) => index > moneyObjects[0].denominations.length - 1)
    );

    const highestTotal = Math.max(...moneyObjects.map(({ value }) => value));

    const [moneyA, moneyB] = moneyObjects.map(({ value }, index) => ({
      pounds: Math.floor(value / 100),
      pence: value % 100,
      total: value,
      character: [characterNameA, characterNameB][index],
      hasMoreMoney: value === highestTotal
    }));

    const sortedAmounts = sortNumberArray([moneyA.total, moneyB.total], 'descending');
    const answerPoundsAndPence = penceToPoundsAndPence(sortedAmounts[0] - sortedAmounts[1]);

    return (
      <QF1ContentAndSentence
        title={
          doesCharAHaveMore
            ? translate.ks1Instructions.characterHasXPoundsAndYPence(
                moneyA.character,
                moneyA.pounds,
                moneyA.pence
              )
            : translate.ks1Instructions.characterHasXPoundsAndYPence(
                moneyB.character,
                moneyB.pounds,
                moneyB.pence
              )
        }
        sentence={translate.ks1AnswerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          answerPoundsAndPence.pounds.toString(),
          answerPoundsAndPence.pence.toString()
        ]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 8,
                alignItems: 'center'
              }}
            >
              {doesCharAHaveMore ? denominationsA : denominationsB}
            </View>
            <TextStructure
              sentence={
                doesCharAHaveMore
                  ? translate.ks1Instructions.characterHasXPoundsAndYPence(
                      moneyB.character,
                      moneyB.pounds,
                      moneyB.pence
                    )
                  : translate.ks1Instructions.characterHasXPoundsAndYPence(
                      moneyA.character,
                      moneyA.pounds,
                      moneyA.pence
                    )
              }
            />
            <View
              style={{
                flexDirection: 'row',
                columnGap: 8,
                alignItems: 'center'
              }}
            >
              {doesCharAHaveMore ? denominationsB : denominationsA}
            </View>
            <TextStructure
              sentence={translate.ks1AnswerSentences.howMuchMoreMoneyDoesCharAHaveThanCharB(
                [moneyA, moneyB].find(({ hasMoreMoney }) => hasMoreMoney)?.character,
                [moneyA, moneyB].find(({ hasMoreMoney }) => !hasMoreMoney)?.character
              )}
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'bit',
  description: 'bit',
  keywords: ['Bar model', 'Money', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z.object({
    isAdd: z.boolean(),
    variation: z.enum(['pounds', 'pence', 'poundsAndPence']),
    moneyA: z.number().int().min(1).max(9898),
    moneyB: z.number().int().min(1).max(9898)
  }),
  simpleGenerator: () => {
    const isAdd = getRandomBoolean();

    const variation = getRandomFromArray(['pounds', 'pence', 'poundsAndPence'] as const);

    const { moneyA, moneyB } = rejectionSample(
      () => {
        const moneyA =
          variation === 'pence'
            ? randomIntegerInclusive(1, 98)
            : variation === 'pounds'
            ? randomIntegerInclusiveStep(100, 9800, 100)
            : randomIntegerInclusive(101, 9898, { constraint: x => x % 100 !== 0 });

        const moneyB =
          variation === 'pence'
            ? randomIntegerInclusive(1, 98, { constraint: x => x + moneyA < 100 })
            : variation === 'pounds'
            ? randomIntegerInclusiveStep(100, 9800, 100, { constraint: x => x + moneyA < 10000 })
            : randomIntegerInclusive(101, 9898, {
                constraint: x =>
                  ((x + moneyA < 10000 && x - moneyA >= 101) || moneyA - x >= 101) && x % 100 !== 0
              });

        return { moneyA, moneyB };
      },
      ({ moneyA, moneyB }) => numbersDoNotExchange(moneyA, moneyB)
    );

    return { moneyA, moneyB, isAdd, variation };
  },

  Component: ({ question: { moneyA, moneyB, isAdd, variation }, translate, displayMode }) => {
    const sentence =
      variation === 'pence'
        ? translate.ks1AnswerSentences.ansPence()
        : variation === 'pounds'
        ? translate.ks1AnswerSentences.poundAns()
        : translate.ks1AnswerSentences.poundAnsAndAnsP();

    const moneyAPoundsAndPence = penceToPoundsAndPence(moneyA);
    const moneyBPoundsAndPence = penceToPoundsAndPence(moneyB);
    const totalMoneyPoundsAndPence = penceToPoundsAndPence(moneyA + moneyB);

    const getAnswer = (money: { pounds: number; pence: number }, variation: string) => {
      if (variation === 'pence') {
        return [money.pence.toString()];
      } else if (variation === 'pounds') {
        return [money.pounds.toString()];
      } else {
        return [money.pounds.toString(), money.pence.toString()];
      }
    };
    const answer = getAnswer(isAdd ? totalMoneyPoundsAndPence : moneyBPoundsAndPence, variation);

    const formatMoneyString = (
      pounds: number,
      pence: number,
      variation: 'pounds' | 'pence' | 'poundsAndPence'
    ) => {
      if (variation === 'pence') {
        return `${pence.toLocaleString()}p`;
      } else if (variation === 'pounds') {
        return `£${pounds.toLocaleString()}`;
      } else {
        return `£${pounds.toLocaleString()} ${translate.misc.and()} ${pence.toLocaleString()}p`;
      }
    };

    const moneyAString = formatMoneyString(
      moneyAPoundsAndPence.pounds,
      moneyAPoundsAndPence.pence,
      variation
    );

    const moneyBString = isAdd
      ? formatMoneyString(moneyBPoundsAndPence.pounds, moneyBPoundsAndPence.pence, variation)
      : '';

    const moneyCString = isAdd
      ? '?'
      : formatMoneyString(
          totalMoneyPoundsAndPence.pounds,
          totalMoneyPoundsAndPence.pence,
          variation
        );

    // Used this from existing question ato
    // Ensures bar model width is wide enough to display text large enough
    const poundsACellWidth = moneyA <= 300 && variation === 'poundsAndPence' ? 300 : moneyA;
    const poundsBCellWidth = moneyB <= 300 && variation === 'poundsAndPence' ? 300 : moneyB;
    const totalWidth = poundsACellWidth + poundsBCellWidth;

    const strings = [[moneyCString], [moneyAString, moneyBString]];
    const numbers = [[totalWidth], [poundsACellWidth, poundsBCellWidth]];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToWorkOutMissingValue()}
        testCorrect={answer}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={totalWidth}
            dimens={dimens}
            sameRowColor
            maxFontSize={displayMode === 'digital' ? 32 : 50}
            oneFontSize
            arrowIndices={[[], isAdd ? [] : [1]]}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateWithMoney',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
