import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import { withStateHOC } from '../../../stateTree';

const PAN_HANDLE_TOUCHABLE_AREA = 96;
const PROTRACTOR_WIDTH = 380;

type Props = {
  state: { x: number; y: number };
  setState?: (newState: { x: number; y: number }) => void;
  shapeImage: JSX.Element;
  dimens: {
    width: number;
    height: number;
  };
};

export function DraggableProtractor({ state, setState, shapeImage: shapeImage, dimens }: Props) {
  const protractorInfo = getSvgInfo('Protractor180').aspectRatio;
  const protractorHeight = PROTRACTOR_WIDTH / protractorInfo;

  const displayMode = useContext(DisplayMode);

  const x = useSharedValue(state.x);
  const y = useSharedValue(state.y);

  const translateStyle = useAnimatedStyle(
    () => ({
      left: x.value,
      top: y.value
    }),
    [x, y]
  );

  const panStartX = useSharedValue(0);
  const panStartY = useSharedValue(0);

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(() => {
          panStartX.value = x.value;
          panStartY.value = y.value;
        })
        .minDistance(1)
        .onUpdate(event => {
          x.value = panStartX.value + event.translationX;
          y.value = panStartY.value + event.translationY;

          // X clamp
          x.value =
            x.value < -32
              ? -32
              : x.value + PROTRACTOR_WIDTH > dimens.width + 32
              ? dimens.width + 32 - PROTRACTOR_WIDTH
              : x.value;

          // Y clamp
          y.value =
            y.value > dimens.height - protractorHeight * 1.35
              ? dimens.height - protractorHeight * 1.35
              : y.value < -dimens.height + protractorHeight * 1.35
              ? -dimens.height + protractorHeight * 1.35
              : y.value;
        })
        .onFinalize(() => {
          setState &&
            runOnJS(setState)({
              x: x.value,
              y: y.value
            });
        }),
    [dimens.height, dimens.width, panStartX, panStartY, protractorHeight, setState, x, y]
  );

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: displayMode === 'digital' ? 'flex-start' : undefined,
        zIndex: 10
      }}
    >
      {displayMode === 'digital' && (
        <Animated.View
          style={[
            {
              backgroundColor: 'transparent',
              width: PROTRACTOR_WIDTH,
              height: protractorHeight,
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: displayMode === 'digital' ? 40 : undefined,
              zIndex: 10
            },
            translateStyle
          ]}
        >
          {<AssetSvg name={'Protractor180'} width={380} height={380} />}
          <GestureDetector gesture={panGesture}>
            <Animated.View
              style={[
                {
                  position: 'absolute',
                  width: PAN_HANDLE_TOUCHABLE_AREA,
                  height: PAN_HANDLE_TOUCHABLE_AREA,
                  borderRadius: 999,
                  backgroundColor: 'transparent',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              ]}
            >
              <AssetSvg name="Circles/circle_orange" width={50} />
            </Animated.View>
          </GestureDetector>
        </Animated.View>
      )}
      <View>{shapeImage}</View>
    </View>
  );
}

export const DraggableProtractorWithState = withStateHOC(DraggableProtractor, {
  stateProp: 'state',
  setStateProp: 'setState',
  defaults: {
    defaultState: {
      x: 0,
      y: 0
    }
  }
});
