import { useCallback, useContext } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput from '../../molecules/UserInput';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { DigitalClock, DigitalClockWithState } from '../representations/DigitalClock';
import { MeasureView } from '../../atoms/MeasureView';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type UserAnswer = string;

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /** By default, this is an array of array of empty strings '' matching the number of <ans/> in the rows of function machines. */
  initialState?: UserAnswer[];
  /** Defaults to checking that all user answer strings are non-empty. */
  testComplete?: (userAnswer: UserAnswer[]) => boolean;
  /** An array that contains [correctHours, correctMinutes] or a function that returns a boolean. */
  testCorrect: [string, string] | ((state: string[]) => boolean);
  hours: number | string;
  minutes: number | string;
  /**
   * Whether to show 'AM', 'PM', or nothing after hours:minutes in the digital clock.
   */
  amOrPm?: 'am' | 'pm';
  /**
   * Color of the clock. Optional prop, defaults to randomly picking a color from the digitalClockColors object.
   */
  color?: string;
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: [string, string]; answerText?: string };
};

export const QF51CompleteTheDigitalClock = ({
  title,
  pdfTitle,
  initialState,
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  hours,
  minutes,
  amOrPm,
  color,
  customMarkSchemeAnswer,
  questionHeight,
  ...props
}: Props) => {
  const displayMode = useContext(DisplayMode);

  // Default initialState
  initialState = (() => {
    if (initialState) {
      return initialState;
    } else {
      const initialStateArray: string[] = [];
      [hours, minutes].forEach(
        str => typeof str === 'string' && str.includes('<ans/>') && initialStateArray.push('')
      );
      return initialStateArray;
    }
  })();

  // Default testComplete
  const testComplete = useCallback(
    (userAnswer: UserAnswer[]) => {
      if (testCompleteProp !== undefined) {
        return testCompleteProp(userAnswer);
      } else {
        return userAnswer.every(it => it.length > 0);
      }
    },
    [testCompleteProp]
  );

  // Handle testCorrect
  const testCorrect = useCallback(
    (userAnswer: UserAnswer[]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        // Accept user answer if "hour" has a leading 0 or not and enforce minutes to always have
        // leading 0 if minutes < 10
        return (
          (userAnswer[0] === testCorrectProp[0] ||
            userAnswer[0] === testCorrectProp[0].padStart(2, '0')) &&
          userAnswer[1] === testCorrectProp[1].padStart(2, '0')
        );
      }
    },
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const ansHours =
      hours === '<ans/>'
        ? customMarkSchemeAnswer?.answerToDisplay?.[0] ??
          (Array.isArray(testCorrectProp) ? testCorrectProp[0] : hours)
        : hours;

    const ansMinutes =
      minutes === '<ans/>'
        ? customMarkSchemeAnswer?.answerToDisplay?.[1] ??
          (Array.isArray(testCorrectProp) ? testCorrectProp[1] : minutes)
        : minutes;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <DigitalClock
                  hours={displayMode === 'pdf' ? (hours === '<ans/>' ? '' : hours) : ansHours}
                  minutes={
                    displayMode === 'pdf' ? (minutes === '<ans/>' ? '' : minutes) : ansMinutes
                  }
                  dimens={dimens}
                  amOrPm={amOrPm}
                  color={color}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" />}
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <DigitalClockWithState
              id="digitalclock"
              defaultState={initialState}
              testComplete={testComplete}
              testCorrect={testCorrect}
              hours={hours}
              minutes={minutes}
              dimens={dimens}
              amOrPm={amOrPm}
              color={color}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
};
