// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import DescribePositionUsingCoordinates from './1DescribePositionUsingCoordinates';
import PlotCoordinates from './2PlotCoordinates';
import Draw2DShapesOnAGrid from './3Draw2DShapesOnAGrid';
import TranslateOnAGrid from './4TranslateOnAGrid';
import DescribeTranslationOnAGrid from './5DescribeTranslationOnAGrid';

const Block = newBlockContent({
  block: 'PositionAndDirection',
  field: 'Geometry',
  weeks: [11, 12],
  smallSteps: [
    DescribePositionUsingCoordinates, // Step 1
    PlotCoordinates, // Step 2
    Draw2DShapesOnAGrid, // Step 3
    TranslateOnAGrid, // Step 4
    DescribeTranslationOnAGrid // Step 5
  ]
});
export default Block;
