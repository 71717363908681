import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomUniqueIntegersInclusive, rejectionSample } from '../../../../utils/random';
import { sortNumberArray } from '../../../../utils/collections';
import {
  areCollinear,
  isIntersecting,
  isValidRectangle,
  isValidSquare
} from '../../../../utils/shapes';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { GridPolygon } from '../../../../utils/gridUtils';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'beA',
  description: 'beA',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter', 'Rectangle'],
  schema: z.object({
    x1: z.number(),
    x2: z.number(),
    y1: z.number(),
    y2: z.number()
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(1, 9, 2);
        const yCoords = randomUniqueIntegersInclusive(1, 5, 2);

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          isValidRectangle(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          !isValidSquare(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          (y2 - y1) % 2 === 0 &&
          (x2 - x1) % 2 === 0
        );
      }
    );

    return { x1, x2, y1, y2 };
  },
  Component: ({ translate, question: { x1, x2, y1, y2 }, displayMode }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapFourDotsToSplitTheShapeInQuarters()}
        pdfTitle={translate.ks1PDFInstructions.drawFourCrossesToSplitTheShapeIntoQuarters()}
        numPoints={4}
        drawMultipleLines
        gridChildren={
          <GridPolygon
            points={[
              [x1, y1],
              [x1, y2],
              [x2, y2],
              [x2, y1]
            ]}
            color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
            showBorder
          />
        }
        testCorrect={userAnswer => {
          const [point1, point2, point3, point4] = userAnswer;

          if (!point1 || !point2 || !point3 || !point4) return false;

          const rectangleLength = x2 - x1 + 1;
          const rectangleHeight = y2 - y1 + 1;

          const xHalfWay = x1 + Math.floor(rectangleLength / 2);
          const yHalfWay = y1 + Math.floor(rectangleHeight / 2);

          const lines = [
            [point1, point2],
            [point3, point4]
          ];

          // check its split vertically
          const isVerticallySplit = lines.some(
            ([p1, p2]) =>
              p1.x === xHalfWay &&
              p2.x === xHalfWay &&
              Math.min(p1.y, p2.y) <= y1 &&
              Math.max(p1.y, p2.y) >= y2
          );

          // check its split horizontally
          const isHorizontallySplit = lines.some(
            ([p1, p2]) =>
              p1.y === yHalfWay &&
              p2.y === yHalfWay &&
              Math.min(p1.x, p2.x) <= x1 &&
              Math.max(p1.x, p2.x) >= x2
          );

          // shape points
          const points = [
            { x: x1, y: y1 },
            { x: x1, y: y2 },
            { x: x2, y: y2 },
            { x: x2, y: y1 }
          ];

          const isLineADiagonal =
            point1.x > point2.x
              ? areCollinear(
                  [point1.x, point1.y],
                  [point2.x, point2.y],
                  [points[0].x, points[0].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear([point1.x, point1.y], [point2.x, point2.y], [points[1].x, points[1].y])
              : areCollinear(
                  [point2.x, point2.y],
                  [point1.x, point1.y],
                  [points[0].x, points[0].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear(
                  [point2.x, point2.y],
                  [point1.x, point1.y],
                  [points[1].x, points[1].y]
                );

          const isLineBDiagonal =
            point3.x > point4.x
              ? areCollinear(
                  [point3.x, point3.y],
                  [point4.x, point4.y],
                  [points[1].x, points[1].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear([point3.x, point3.y], [point4.x, point4.y], [points[0].x, points[0].y])
              : areCollinear(
                  [point4.x, point4.y],
                  [point3.x, point3.y],
                  [points[1].x, points[1].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear(
                  [point4.x, point4.y],
                  [point3.x, point3.y],
                  [points[0].x, points[0].y]
                );

          const isOutsideRectangle = ({ x, y }: { x: number; y: number }) =>
            x <= x1 || x >= x2 || y <= y1 || y >= y2;

          const isDiagonal = isLineADiagonal && isLineBDiagonal;

          const arePointsOutsideRectangle = points.every(point => isOutsideRectangle(point));

          const doLinesIntersect = isIntersecting(point1, point2, point3, point4);

          // Check if lines isIntersect and all lines are outside the rectangle
          const doLinesPassOtherChecks = arePointsOutsideRectangle && doLinesIntersect;

          if (!doLinesPassOtherChecks) {
            return false;
          }

          return isDiagonal || (isVerticallySplit && isHorizontallySplit);
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidAnswer() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAQuarterOfAnObjectOrAShape',
  questionTypes: [Question2],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
