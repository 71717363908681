import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 38 43" {...props}>
    <Path
      fill="#002E63"
      fillRule="evenodd"
      d="M5.554.667A2.333 2.333 0 0 1 7.8 3.087L7.57 9.242A18.666 18.666 0 1 1 .335 24a2.334 2.334 0 0 1 4.669 0 14 14 0 1 0 4.459-10.245l7.945.928a2.334 2.334 0 0 1-.542 4.634L4.733 17.901a2.334 2.334 0 0 1-2.065-2.403l.467-12.584A2.333 2.333 0 0 1 5.552.669l.002-.002Z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
