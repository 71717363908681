import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD, MULT } from 'common/src/constants';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { isCube, isSquare } from 'common/src/utils/factors';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { cubedCubesSVG, getRandomUniqueCuboidsObjects } from '../../../../utils/cubes';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'amV',
  description: 'amV',
  keywords: ['Cube'],
  schema: z.object({
    number1: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[(number1 * number1).toString(), (number1 * number1 * number1).toString()]}
        sentence={`${number1} ${MULT} ${number1} ${MULT} ${number1} = <ans/> ${MULT} ${number1} = <ans/>`}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'amW',
  description: 'amW',
  keywords: ['Cube'],
  schema: z.object({
    cubeNumberDimens: z.number().int().min(1).max(4),
    cuboidObjects: z.array(z.object({ svgName: z.string(), size: z.number() }))
  }),
  simpleGenerator: () => {
    const cubeNumberDimens = randomIntegerInclusive(1, 4);

    const cuboidObjects = rejectionSample(
      () => {
        const cuboidObjects = getRandomUniqueCuboidsObjects(3);

        return cuboidObjects;
      },
      cuboidObjects => {
        const sizeArray = cuboidObjects.map(el => el.size);
        // dont want duplicates or any square numbers in this arrangement
        return arrayHasNoDuplicates(sizeArray) && sizeArray.every(val => !isCube(val));
      }
    );

    return {
      cubeNumberDimens,
      cuboidObjects
    };
  },

  Component: props => {
    const {
      question: { cubeNumberDimens, cuboidObjects },
      translate
    } = props;
    const cubeSize = Math.pow(cubeNumberDimens, 3);

    const shapes = shuffle(
      [
        {
          value: 'cube',
          size: cubeSize,
          svg: cubedCubesSVG(cubeNumberDimens as 1 | 2 | 3, 150, 150),
          isCorrect: Math.cbrt(cubeSize) % 1 === 0
        },
        {
          value: 'cuboidA',
          size: cuboidObjects[0].size,
          svg: <AssetSvg width={150} height={150} name={cuboidObjects[0].svgName as SvgName} />,
          isCorrect: Math.cbrt(cuboidObjects[0].size) % 1 === 0
        },
        {
          value: 'cuboidB',
          size: cuboidObjects[1].size,
          svg: <AssetSvg width={150} height={150} name={cuboidObjects[1].svgName as SvgName} />,
          isCorrect: Math.cbrt(cuboidObjects[1].size) % 1 === 0
        },
        {
          value: 'cuboidC',
          size: cuboidObjects[2].size,
          svg: <AssetSvg width={150} height={150} name={cuboidObjects[2].svgName as SvgName} />,
          isCorrect: Math.cbrt(cuboidObjects[2].size) % 1 === 0
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.selectCubeNumber()}`}
        pdfTitle={`${translate.instructions.circleCubeNumber()}`}
        numItems={4}
        testCorrect={shapes.filter(shape => shape.isCorrect).map(shape => shape.value)}
        renderItems={() => {
          return shapes.map(({ value, size, svg }) => ({
            value: value,
            component: (
              <>
                <Text variant="WRN700">{size}</Text>
                {svg}
              </>
            )
          }));
        }}
        // multiSelect left in case a teacher customises the question to use more than one cube number.
        multiSelect
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'amX',
  description: 'amX',
  keywords: ['Cube'],
  schema: z.object({
    cubeRoot: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const cubeRoot = randomIntegerInclusive(2, 5);

    return { cubeRoot };
  },
  Component: props => {
    const {
      question: { cubeRoot },
      translate
    } = props;

    const cubes = (() => {
      switch (cubeRoot) {
        case 2:
          return cubedCubesSVG(2, 200, 200);
        case 3:
          return cubedCubesSVG(3, 200, 200);
        case 4:
          return cubedCubesSVG(4, 200, 200);
        case 5:
          return cubedCubesSVG(5, 200, 200);
      }
    })();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`<ans/> ${MULT} <ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.howManySmallCubesAreInThisCube()}
        testCorrect={[
          cubeRoot.toString(),
          cubeRoot.toString(),
          cubeRoot.toString(),
          (cubeRoot ** 3).toString()
        ]}
        Content={<View>{cubes}</View>}
      />
    );
  }
});

// Question4 exported to Q anJ
const Question4v2 = newQuestionContent({
  uid: 'amY2',
  description: 'amY',
  keywords: ['Cube', 'Notation'],
  schema: z.object({
    cubeVar: z.number().min(2).max(9)
  }),
  simpleGenerator: () => {
    const cubeVar = randomIntegerInclusive(2, 9);

    return { cubeVar };
  },
  Component: props => {
    const {
      question: { cubeVar },
      translate
    } = props;

    const itemA = {
      string: `${cubeVar.toLocaleString()} ${MULT} ${cubeVar.toLocaleString()} ${MULT} ${cubeVar.toLocaleString()}`,
      value: 'A'
    };
    const itemB = {
      string: `${cubeVar.toLocaleString()} ${MULT} ${(3).toLocaleString()}`,
      value: 'B'
    };
    const itemC = {
      string: `${cubeVar.toLocaleString()} ${ADD} ${cubeVar.toLocaleString()} ${ADD} ${cubeVar.toLocaleString()}`,
      value: 'C'
    };
    const itemD = {
      string: `${cubeVar.toLocaleString()} ${MULT} ${(10).toLocaleString()} ${ADD} ${(3).toLocaleString()}`,
      value: 'D'
    };

    const answers = shuffle([itemA, itemB, itemC, itemD], { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.interpretCubeNumberNotation(cubeVar)}
        pdfTitle={translate.instructions.interpretCubeNumberNotationPDF(cubeVar)}
        testCorrect={[itemA.value]}
        items={answers.map(({ value, string }) => ({
          value,
          component: string
        }))}
      />
    );
  }
});
export const amY2 = Question4v2;

// Question4 exported to Q anJ
const Question4 = newQuestionContent({
  uid: 'amY',
  description: 'amY',
  keywords: ['Cube', 'Notation'],
  schema: z.object({
    cubeVar: z.number().min(2).max(9)
  }),
  simpleGenerator: () => {
    const cubeVar = randomIntegerInclusive(2, 9);

    return { cubeVar };
  },
  Component: props => {
    const {
      question: { cubeVar },
      translate
    } = props;

    const answers = shuffle(
      [
        `${cubeVar} ${MULT} ${cubeVar} ${MULT} ${cubeVar}`,
        `${cubeVar} ${MULT} ${(3).toLocaleString()}`,
        `${cubeVar} ${ADD} ${cubeVar} ${ADD} ${cubeVar}`,
        `${cubeVar} ${MULT} ${(10).toLocaleString()} ${ADD} ${(3).toLocaleString()}`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.interpretCubeNumberNotation(cubeVar)}
        pdfTitle={translate.instructions.interpretCubeNumberNotationPDF(cubeVar)}
        testCorrect={[`${cubeVar} ${MULT} ${cubeVar} ${MULT} ${cubeVar}`]}
        multiSelect
        items={answers.map(answer => ({
          value: answer,
          component: answer
        }))}
      />
    );
  }
});
export const amY = Question4;

const Question5 = newQuestionContent({
  uid: 'amZ',
  description: 'amZ',
  keywords: ['Cube'],
  schema: z.object({
    number1: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 5);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[
          number1.toString(),
          number1.toString(),
          number1.toString(),
          Math.pow(number1, 3).toString()
        ]}
        sentence={`${number1}³ = <ans/> ${MULT} <ans/> ${MULT} <ans/> = <ans/>`}
      />
    );
  }
});

// Question6 exported to Q anM
const Question6 = newQuestionContent({
  uid: 'am0',
  description: 'am0',
  keywords: ['Cube'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(1000))
      .length(6)
      .refine(
        numbers => numbers.filter(x => isCube(x)).length >= 3,
        'There are at least 3 cube numbers'
      )
  }),
  simpleGenerator: () => {
    const cubes = randomUniqueIntegersInclusive(1, 125, 3, {
      constraint: x => isCube(x)
    });
    const hasTenCubed = getRandomBoolean();
    if (hasTenCubed) cubes[0] = 10 * 10 * 10;
    const square = randomIntegerInclusive(1, 100, {
      constraint: x => isSquare(x) && !cubes.includes(x)
    });

    const extras = randomUniqueIntegersInclusive(1, 100, 2, {
      constraint: x => ![...cubes, square].includes(x)
    });

    const numbers = shuffle([...cubes, ...extras, square]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const correct = numbers.filter(x => isCube(x));

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumsAreCube()}
        testCorrect={correct}
        multiSelect
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const am0 = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CubeNumbers',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
