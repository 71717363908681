import { Circle, Path, Svg } from 'react-native-svg';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';

export function getPictogramIcons({
  fraction,
  width,
  height,
  color,
  key
}: {
  fraction: string;
  width?: number;
  height?: number;
  color?: string;
  key?: string;
}) {
  width = width ?? 100;
  height = height ?? 100;
  color = color ?? 'blue';
  let component;
  let value;
  switch (fraction) {
    case 'half':
      component = (
        <Svg key={key} width={width / 2} height={height} viewBox={`0 0 ${width / 2} ${height}`}>
          <Path
            d={`M ${width / 2} 0 A ${width / 2} ${width / 2} 0 0 0 ${width / 2} ${width}`}
            fill={color}
          />
        </Svg>
      );
      value = 0.5;
      break;
    case 'quarter':
      component = (
        <Svg key={key} width={width / 2} height={height} viewBox={`0 0 ${width / 2} ${height}`}>
          <Path
            d={`M ${width / 2} 0 A ${width / 2} ${width / 2} 0 0 0 0 ${width / 2} L ${width / 2} ${
              width / 2
            }`}
            fill={color}
          />
        </Svg>
      );
      value = 0.25;
      break;
    case 'threeQuarter':
      component = (
        <Svg key={key} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <Path
            d={`M ${width / 2} 0 A ${width / 2} ${width / 2} 0 1 0 ${width} ${width / 2} L ${
              width / 2
            } ${width / 2}`}
            fill={color}
          />
        </Svg>
      );
      value = 0.75;
      break;
    default:
      component = (
        <Svg key={key} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <Circle cx={width / 2} cy={height / 2} r={width / 2} fill={color} />
        </Svg>
      );
      value = 1;
      break;
  }
  return { component: component, value: value };
}

export function getPictogramRow(number: number, width?: number, height?: number, color?: string) {
  width = width ?? 50;
  height = height ?? 50;
  color = color ?? colors.pacificBlue;
  const numOfWholes = Math.floor(number);
  const numOfHalves = number % 1 === 0.5 ? 1 : 0;
  const numOfThreeQuarters = number % 1 === 0.75 ? 1 : 0;
  const numOfQuarters = number % 1 === 0.25 ? 1 : 0;

  return (
    <View
      key={`row_${number}`}
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 5,
        flexWrap: 'wrap'
      }}
    >
      {countRange(numOfWholes).map(
        i =>
          getPictogramIcons({ fraction: 'whole', width, height, color, key: `whole_${i}` })
            .component
      )}
      {countRange(numOfHalves).map(
        i =>
          getPictogramIcons({ fraction: 'half', width, height, color, key: `half_${i}` }).component
      )}
      {countRange(numOfThreeQuarters).map(
        i =>
          getPictogramIcons({
            fraction: 'threeQuarter',
            width,
            height,
            color,
            key: `threeQuarter_${i}`
          }).component
      )}
      {countRange(numOfQuarters).map(
        i =>
          getPictogramIcons({ fraction: 'quarter', width, height, color, key: `quarter_${i}` })
            .component
      )}
    </View>
  );
}
