import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from '../../../../utils/collections';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';

const getAmountValue = (
  amount: 'Empty' | 'Full' | 'Quarter Full' | 'Half Full' | 'Three Quarters Full'
) => {
  return amount === 'Empty'
    ? 0
    : amount === 'Quarter Full'
    ? 250
    : amount === 'Half Full'
    ? 500
    : amount === 'Three Quarters Full'
    ? 750
    : 1000;
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdK',
  description: 'bdK',
  keywords: ['More', 'Less', 'Volume', 'Capacity', 'Compare'],
  schema: z.object({
    amounts: z.array(z.enum(['Empty', 'Quarter Full', 'Half Full', 'Three Quarters Full', 'Full'])),
    containerOrder: z.boolean(),
    containerTypeVariation: z.enum(['jug', 'glass'])
  }),
  simpleGenerator: () => {
    const amounts = getRandomSubArrayFromArray(
      ['Empty', 'Quarter Full', 'Half Full', 'Three Quarters Full', 'Full'] as const,
      2
    );
    const containerOrder = getRandomBoolean();

    const containerTypeVariation =
      amounts[0] === 'Full' || amounts[1] === 'Full'
        ? 'glass'
        : getRandomFromArray(['jug', 'glass'] as const);

    return {
      amounts,
      containerOrder,
      containerTypeVariation
    };
  },
  questionHeight: 1400,
  Component: props => {
    const {
      question: { amounts, containerOrder, containerTypeVariation },
      translate,
      displayMode
    } = props;

    const [amountA, amountB] = amounts;

    const more = translate.misc.more();
    const less = translate.misc.less();

    const amountAValue = getAmountValue(amountA);
    const amountBValue = getAmountValue(amountB);

    const correctAnswer =
      (containerOrder && amountAValue > amountBValue) ||
      (!containerOrder && amountBValue > amountAValue)
        ? more
        : less;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentences()}
        sentence={translate.ks1AnswerSentences.xContainerHasAnsJuiceThanContainerY(
          containerTypeVariation === 'glass' ? translate.objects.Glass() : translate.objects.Jug(),
          containerOrder ? translate.letters.A() : translate.letters.B(),
          containerOrder ? translate.letters.B() : translate.letters.A()
        )}
        sentencesStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 48 }}
        pdfLayout="itemsTop"
        Content={({ dimens }) => {
          return (
            <View style={{ display: 'flex', flexDirection: 'row', columnGap: 24 }}>
              <View>
                <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                  {translate.letters.A()}
                </Text>
                <JugWithLiquid
                  dimens={{
                    width: dimens.width * 0.3,
                    height: dimens.width * 0.3
                  }}
                  jugCapacity={1000}
                  liquidAmount={amountAValue}
                  hideAllLabels
                  tickValue={500}
                  liquidType="orange"
                  unitsPerMajorTick={10}
                  isInteractive={false}
                  containerType={containerTypeVariation}
                />
              </View>
              <View>
                <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                  {translate.letters.B()}
                </Text>
                <JugWithLiquid
                  dimens={{
                    width: dimens.width * 0.3,
                    height: dimens.width * 0.3
                  }}
                  jugCapacity={1000}
                  liquidAmount={amountBValue}
                  hideAllLabels
                  tickValue={500}
                  liquidType="orange"
                  unitsPerMajorTick={10}
                  isInteractive={false}
                  containerType={containerTypeVariation}
                />
              </View>
            </View>
          );
        }}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        actionPanelVariant="endWide"
        questionHeight={1400}
        items={[more, less]}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdL',
  description: 'bdL',
  keywords: ['More', 'Less', 'Volume', 'Capacity', 'Compare'],
  schema: z.object({
    amountA: z.enum(['Empty', 'Quarter Full', 'Half Full', 'Three Quarters Full', 'Full']),
    moreOrLess: z.enum(['More', 'Less']),
    containerTypeVariation: z.enum(['jug', 'glass'])
  }),
  simpleGenerator: () => {
    const { moreOrLess, amountA, containerTypeVariation } = rejectionSample(
      () => {
        const moreOrLess = getRandomFromArray(['More', 'Less'] as const);
        const containerTypeVariation = getRandomFromArray(['jug', 'glass'] as const);

        const amountA = getRandomFromArray([
          'Empty',
          'Quarter Full',
          'Half Full',
          'Three Quarters Full',
          'Full'
        ] as const);
        return { moreOrLess, amountA, containerTypeVariation };
      },

      ({ moreOrLess, amountA }) =>
        // if Glass A has less volume than Glass B, then Glass A cannot be full
        (moreOrLess === 'Less' && amountA !== 'Full') ||
        // if Glass A has more volume than Glass B, then Glass A cannot be empty
        (moreOrLess === 'More' && amountA !== 'Empty')
    );

    return { amountA, moreOrLess, containerTypeVariation };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { amountA, moreOrLess, containerTypeVariation },
      translate
    } = props;

    const amountAValue = getAmountValue(amountA);

    const moreOrLessTitle = moreOrLess === 'More' ? translate.misc.more() : translate.misc.less();

    return (
      <QF13DragLiquidInJug
        title={translate.ks1Instructions.xContainerHasYMoreOrLessJuiceThanZContainerDragTheArrowToShowHowMuchJuice(
          containerTypeVariation === 'glass' ? translate.objects.Glass() : translate.objects.Jug(),
          translate.letters.A(),
          moreOrLessTitle,
          translate.letters.B()
        )}
        pdfTitle={translate.ks1PDFInstructions.xContainerHasYMoreOrLessJuiceThanZContainerDrawHowMuchJuiceCouldBeInXContainer(
          containerTypeVariation === 'glass' ? translate.objects.Glass() : translate.objects.Jug(),
          translate.letters.A(),
          moreOrLessTitle,
          translate.letters.B()
        )}
        testCorrect={userAnswer =>
          moreOrLess === 'More' ? userAnswer < amountAValue : userAnswer > amountAValue
        }
        jugCapacity={1000}
        containerType={containerTypeVariation}
        hideAllLabels
        tickValue={500}
        lhsContentLabel={translate.letters.A()}
        label={translate.letters.B()}
        lhsContent={({ dimens }) => (
          <>
            <JugWithLiquid
              dimens={{
                width: dimens.width,
                height: dimens.height
              }}
              jugCapacity={1000}
              liquidAmount={amountAValue}
              hideAllLabels
              tickValue={500}
              liquidType="orange"
              unitsPerMajorTick={10}
              isInteractive={false}
              containerType={containerTypeVariation}
            />
          </>
        )}
        liquidType="orange"
        unitsPerMajorTick={10}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyAmountXMoreOrLessThanYContainer(
            moreOrLess === 'More' ? translate.misc.less() : translate.misc.more(),
            `${translate.objects.Glass()} ${translate.letters.A()}`
          )
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdM',
  description: 'bdM',
  keywords: ['Volume', 'Capacity', 'Compare', 'Order'],
  schema: z.object({
    amounts: z.array(z.enum(['Empty', 'Quarter Full', 'Half Full', 'Three Quarters Full', 'Full'])),
    order: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const amounts = getRandomSubArrayFromArray(
      ['Empty', 'Quarter Full', 'Half Full', 'Three Quarters Full', 'Full'] as const,
      3
    );
    const order = getRandomFromArray(['ascending', 'descending'] as const);

    return { amounts, order };
  },
  Component: props => {
    const {
      question: { amounts, order },
      translate
    } = props;

    const [amountA, amountB, amountC] = amounts;
    const amountAValue = getAmountValue(amountA);
    const amountBValue = getAmountValue(amountB);
    const amountCValue = getAmountValue(amountC);

    const items = shuffle([amountAValue, amountBValue, amountCValue], {
      random: seededRandom(props.question)
    });

    return (
      <QF4DragOrderVertical
        title={
          order === 'ascending'
            ? translate.ks1Instructions.dragCardsOrderGlassesStartWithTheSmallestAmountVolume()
            : translate.ks1Instructions.dragCardsOrderGlassesStartWithTheGreatestAmountVolume()
        }
        pdfTitle={
          order === 'ascending'
            ? translate.ks1PDFInstructions.orderGlassesStartWithTheSmallestAmountVolume()
            : translate.ks1PDFInstructions.orderGlassesStartWithTheGreatestAmountVolume()
        }
        testCorrect={sortNumberArray([amountAValue, amountBValue, amountCValue], order)}
        items={items.map(value => ({
          value,
          component: (
            <JugWithLiquid
              dimens={{
                width: 100,
                height: 100
              }}
              jugCapacity={1000}
              liquidAmount={value}
              hideAllLabels
              tickValue={500}
              liquidType="orange"
              unitsPerMajorTick={10}
              isInteractive={false}
              containerType={'glass'}
            />
          )
        }))}
        topLabel={
          order === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          order === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        draggableVariant="tallRectangle"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareVolume',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
