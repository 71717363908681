import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import { numberEnum } from '../../../../utils/zod';
import { AssetSvg } from '../../../../assets/svg';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import { countRange, filledArray, sumNumberArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { Dimens } from '../../../../theme/scaling';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJo',
  description: 'aJo',
  keywords: ['Measure', 'Volume', 'Millilitres', 'Litres'],
  questionHeight: 1200,
  schema: z
    .object({
      litresA: z.number().int().min(1).max(3),
      litresB: z.number().int().min(1).max(3),
      mlA: z.number().int().min(100).max(900).step(100),
      mlB: z.number().int().min(100).max(900).step(100),
      correctAnswer: z.enum(['A', 'B'])
    })
    .refine(
      ({ litresA, litresB, mlA, mlB }) => litresA * 1000 + mlA !== litresB * 1000 + mlB,
      'amounts must be different'
    ),
  simpleGenerator: () => {
    // can only fit 4 beakers in the selectables
    const litresA = randomIntegerInclusive(1, 3);
    const litresB = randomIntegerInclusive(1, 3);

    const mlA = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x / 100 + litresA <= 4
    });
    const mlB = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => (litresA === litresB && x !== mlA) || litresA !== litresB
    });

    const correctAnswer = getRandomFromArray(['A', 'B'] as const);

    return { litresA, litresB, mlA, mlB, correctAnswer };
  },
  Component: props => {
    const {
      question: { litresA, litresB, mlA, mlB, correctAnswer },
      translate,
      displayMode
    } = props;

    const jugDimens =
      displayMode === 'digital' ? { width: 200, height: 200 } : { width: 300, height: 300 };

    const items = (dimens: Dimens) =>
      shuffle(
        [
          {
            value: 'A',
            component: (
              <View
                style={[
                  { flexDirection: 'row' },
                  displayMode !== 'digital'
                    ? {
                        borderColor: 'black',
                        borderWidth: 2,
                        width: dimens.width,
                        height: dimens.height,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }
                    : undefined
                ]}
              >
                {countRange(litresA).map(val => (
                  <View style={jugDimens} key={`${val}A`}>
                    <JugWithLiquid
                      dimens={jugDimens}
                      jugCapacity={1000}
                      tickValue={100}
                      displayMajorLabels="top"
                      liquidAmount={1000}
                      fontSize={displayMode === 'digital' ? 21 : 30}
                    />
                  </View>
                ))}
                {countRange(mlA / 100).map(val => (
                  <View style={jugDimens} key={`${val}Aml`}>
                    <JugWithLiquid
                      dimens={jugDimens}
                      jugCapacity={100}
                      displayMajorLabels="top"
                      tickValue={10}
                      liquidAmount={100}
                      fontSize={displayMode === 'digital' ? 21 : 30}
                      labelUnits="ml"
                    />
                  </View>
                ))}
              </View>
            )
          },
          {
            value: 'B',
            component: (
              <View
                style={[
                  { flexDirection: 'row' },
                  displayMode !== 'digital'
                    ? {
                        borderColor: 'black',
                        borderWidth: 2,
                        width: dimens.width,
                        height: dimens.height,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }
                    : undefined
                ]}
              >
                {countRange(litresB).map(val => (
                  <View style={jugDimens} key={`${val}B`}>
                    <JugWithLiquid
                      dimens={jugDimens}
                      jugCapacity={1000}
                      displayMajorLabels="top"
                      tickValue={100}
                      liquidAmount={1000}
                      fontSize={displayMode === 'digital' ? 21 : 30}
                    />
                  </View>
                ))}
                <View style={jugDimens} key={`ml`}>
                  <JugWithLiquid
                    dimens={jugDimens}
                    jugCapacity={1000}
                    displayMajorLabels="top"
                    tickValue={100}
                    liquidAmount={mlB}
                    fontSize={displayMode === 'digital' ? 21 : 30}
                  />
                </View>
              </View>
            )
          }
        ],
        { random: seededRandom(props.question) }
      );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectBeakersThatHaveTotalOfXLitresAndYMl(
          correctAnswer === 'A' ? litresA : litresB,
          correctAnswer === 'A' ? mlA : mlB
        )}
        pdfTitle={translate.instructions.circleBeakersThatHaveTotalOfXLitresAndYMl(
          correctAnswer === 'A' ? litresA : litresB,
          correctAnswer === 'A' ? mlA : mlB
        )}
        itemLayout="column"
        numItems={2}
        renderItems={({ dimens }) => items(dimens)}
        testCorrect={[correctAnswer]}
        questionHeight={1200}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aJo2',
  description: 'aJo2',
  keywords: ['Measure', 'Volume', 'Millilitres', 'Litres'],
  questionHeight: 1200,
  schema: z
    .object({
      jugsNeeded: z.array(z.number().int().min(100).max(1000)),
      remaining: z.array(z.number().int().min(100).max(1000))
    })
    .refine(
      ({ jugsNeeded, remaining }) => jugsNeeded.length + remaining.length === 8,
      '8 total options'
    ),
  simpleGenerator: () => {
    let jugsRemaining = 8;
    const litres = randomIntegerInclusive(1, 4);
    const ml = randomIntegerInclusiveStep(100, 900, 100);

    const jugsToMakeTotalL = filledArray(1000, litres);
    jugsRemaining -= jugsToMakeTotalL.length;

    const jugsToMakeTotalMl = rejectionSample(
      () => {
        const numJugsForMl = randomIntegerInclusive(1, Math.min(ml / 100, jugsRemaining - 1));
        return countRange(numJugsForMl).map(_ => randomIntegerInclusiveStep(100, 900, 100));
      },
      x => sumNumberArray(x) === ml
    );
    jugsRemaining -= jugsToMakeTotalMl.length;
    const remaining = [
      1000,
      ...countRange(jugsRemaining - 1).map(_ => randomIntegerInclusiveStep(100, 1000, 100))
    ];
    return { jugsNeeded: [...jugsToMakeTotalL, ...jugsToMakeTotalMl], remaining };
  },
  Component: props => {
    const {
      question: { jugsNeeded, remaining },
      translate,
      displayMode
    } = props;

    const totalVolume = sumNumberArray(jugsNeeded);

    const unshuffled = [...jugsNeeded, ...remaining].map((amount, i) => ({
      key: i,
      value: amount,
      correct: i < jugsNeeded.length ? true : false
    }));

    const items = shuffle([...unshuffled], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectJugsThatWouldHaveTotalCapacitityOfXMl(totalVolume)}
        pdfTitle={translate.instructions.circleJugsThatWouldHaveTotalCapactityOfXMl(totalVolume)}
        numItems={8}
        multiSelect
        renderItems={({ dimens }) =>
          items.map(({ key, value }) => ({
            value: key,
            component: (
              <JugWithLiquid
                key={key}
                dimens={{ width: dimens.width, height: dimens.height * 0.8 }}
                jugCapacity={1000}
                displayMajorLabels="top"
                tickValue={100}
                liquidAmount={value}
                fontSize={displayMode === 'digital' ? 21 : 30}
              />
            )
          }))
        }
        testCorrect={userAnswer =>
          items
            .filter(({ key }) => userAnswer.includes(key))
            .reduce((acc, { value }) => acc + value, 0) === totalVolume
        }
        customMarkSchemeAnswer={{
          answerToDisplay: unshuffled.filter(({ correct }) => correct).map(({ key }) => key),
          answerText: translate.markScheme.acceptAnyCombinationThatAddUpToX(
            translate.units.stringMl(totalVolume.toLocaleString())
          )
        }}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJp',
  description: 'aJp',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  schema: z
    .object({
      jugCapacityL: z.number().int().min(2).max(4),
      waterL: z.number().int().min(1).max(3),
      waterMl: z.number().int().min(100).max(900).multipleOf(100)
    })
    .refine(val => val.waterL < val.jugCapacityL, 'waterL must be less than jugCapacityL.'),
  simpleGenerator: () => {
    const jugCapacityL = randomIntegerInclusive(2, 4);

    const waterL = randomIntegerInclusive(1, jugCapacityL - 1);

    const waterMl = randomIntegerInclusiveStep(100, 900, 100);

    return { jugCapacityL, waterL, waterMl };
  },
  Component: props => {
    const {
      question: { jugCapacityL, waterL, waterMl },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansLAndAnsMl()}
        title={translate.instructions.howMuchWaterIsInTheJug()}
        testCorrect={[waterL.toString(), waterMl.toString()]}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <JugWithLiquid
              dimens={dimens}
              jugCapacity={(jugCapacityL * 1000) as 2000 | 3000 | 4000}
              tickValue={1000}
              liquidAmount={waterL * 1000 + waterMl}
              unitsPerMajorTick={9}
            />
          );
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aJq',
  description: 'aJq',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  schema: z
    .object({
      jugCapacityL: z.number().int().min(2).max(4),
      bottleWaterL: z.number().int().min(1).max(3),
      bottleWaterMl: z.number().int().min(100).max(900).multipleOf(100)
    })
    .refine(
      val => val.bottleWaterL < val.jugCapacityL,
      'bottleWaterL must be less than jugCapacityL.'
    ),
  simpleGenerator: () => {
    const jugCapacityL = randomIntegerInclusive(2, 4);

    const bottleWaterL = randomIntegerInclusive(1, jugCapacityL - 1);

    const bottleWaterMl = randomIntegerInclusiveStep(100, 900, 100);

    return { jugCapacityL, bottleWaterL, bottleWaterMl };
  },
  Component: props => {
    const {
      question: { jugCapacityL, bottleWaterL, bottleWaterMl },
      translate,
      displayMode
    } = props;

    const unitsPerMajorTick = 9;

    const totalWater = bottleWaterL * 1000 + bottleWaterMl;

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.theFullBottleOfLiquidIsEmptiedIntoJug()}
        pdfTitle={translate.instructions.theFullBottleOfLiquidIsEmptiedIntoJugPDF()}
        testCorrect={totalWater}
        jugCapacity={jugCapacityL * 1000}
        tickValue={1000}
        unitsPerMajorTick={unitsPerMajorTick}
        lhsContent={({ dimens }) => (
          <>
            <AssetSvg name="Water_bottle_glass" height={dimens.height * 0.9} />
            <ContentBox
              containerStyle={{
                maxWidth: dimens.width * 0.3,
                position: 'absolute',
                bottom: displayMode === 'digital' ? dimens.height * 0.2 : dimens.height * 0.3
              }}
            >
              <Text
                variant="WRN400"
                style={{ fontSize: displayMode === 'digital' ? 32 : 50, textAlign: 'center' }}
              >
                {translate.answerSentences.xLAndYMl(bottleWaterL, bottleWaterMl)}
              </Text>
            </ContentBox>
          </>
        )}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aJr',
  description: 'aJr',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  schema: z
    .object({
      jugCapacityL: z.number().int().min(2).max(4),
      tickValueMl: numberEnum([200, 250, 500]),
      waterL: z.number().int().min(1).max(3),
      waterMl: z.number().int().min(200).max(800)
    })
    .refine(val => val.waterL < val.jugCapacityL, 'waterL must be less than jugCapacityL.')
    .refine(
      val => val.waterMl % val.tickValueMl === 0,
      'waterMl must be a multiple of tickValueMl'
    ),
  simpleGenerator: () => {
    const jugCapacityL = randomIntegerInclusive(2, 4);

    const tickValueMl = getRandomFromArray([200, 250, 500] as const);

    const waterL = randomIntegerInclusive(1, jugCapacityL - 1);

    const waterMl = (() => {
      switch (tickValueMl) {
        case 200:
          return randomIntegerInclusiveStep(200, 800, 200);
        case 250:
          return randomIntegerInclusiveStep(250, 750, 250);
        case 500:
          return 500;
      }
    })();

    return { jugCapacityL, tickValueMl, waterL, waterMl };
  },
  Component: props => {
    const {
      question: { jugCapacityL, tickValueMl, waterL, waterMl },
      translate
    } = props;
    const unitsPerMajorTick = {
      200: 4,
      250: 3,
      500: 1
    } as const;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansLAndAnsMl()}
        title={translate.instructions.howMuchWaterIsInTheJug()}
        testCorrect={[waterL.toString(), waterMl.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <JugWithLiquid
              dimens={dimens}
              jugCapacity={(jugCapacityL * 1000) as 2000 | 3000 | 4000}
              liquidAmount={waterL * 1000 + waterMl}
              tickValue={1000}
              unitsPerMajorTick={unitsPerMajorTick[tickValueMl]}
            />
          );
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'aJs',
  description: 'aJs',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  schema: z
    .object({
      jugCapacityL: z.number().int().min(2).max(5),
      jugMinorTickSizeMl: numberEnum([200, 250, 500]),
      bottleWaterL: z.number().int().min(1).max(4),
      bottleWaterMl: z.number().int().min(200).max(800)
    })
    .refine(
      val => val.bottleWaterL < val.jugCapacityL,
      'bottleWaterL must be less than jugCapacityL.'
    )
    .refine(
      val => val.bottleWaterMl % val.jugMinorTickSizeMl === 0,
      'bottleWaterMl must be a multiple of jugMinorTickSizeMl.'
    ),
  simpleGenerator: () => {
    const jugCapacityL = randomIntegerInclusive(2, 5);

    const jugMinorTickSizeMl = getRandomFromArray([200, 250, 500] as const);

    const bottleWaterL = randomIntegerInclusive(1, jugCapacityL - 1);

    const [waterMlLowerBound, waterMlUpperBound, waterMlStep] = (() => {
      switch (jugMinorTickSizeMl) {
        case 200:
          return [200, 800, 200];
        case 250:
          return [250, 750, 250];
        case 500:
          return [500, 500, 500];
      }
    })();

    const bottleWaterMl = randomIntegerInclusiveStep(
      waterMlLowerBound,
      waterMlUpperBound,
      waterMlStep
    );

    return { jugCapacityL, jugMinorTickSizeMl, bottleWaterL, bottleWaterMl };
  },
  Component: props => {
    const {
      question: { jugCapacityL, jugMinorTickSizeMl, bottleWaterL, bottleWaterMl },
      translate,
      displayMode
    } = props;

    const unitsPerMajorTick = jugMinorTickSizeMl === 200 ? 4 : jugMinorTickSizeMl === 250 ? 3 : 1;

    const totalWater = bottleWaterL * 1000 + bottleWaterMl;

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.theFullBottleOfLiquidIsEmptiedIntoJug()}
        pdfTitle={translate.instructions.theFullBottleOfLiquidIsEmptiedIntoJugPDF()}
        testCorrect={totalWater}
        jugCapacity={jugCapacityL * 1000}
        tickValue={1000}
        unitsPerMajorTick={unitsPerMajorTick}
        lhsContent={({ dimens }) => (
          <>
            <AssetSvg name="Water_bottle_glass" height={dimens.height * 0.9} />
            <ContentBox
              containerStyle={{
                maxWidth: dimens.width * 0.3,
                position: 'absolute',
                bottom: displayMode === 'digital' ? dimens.height * 0.2 : dimens.height * 0.3
              }}
            >
              <Text
                variant="WRN400"
                style={{ fontSize: displayMode === 'digital' ? 32 : 50, textAlign: 'center' }}
              >
                {translate.answerSentences.xLAndYMl(bottleWaterL, bottleWaterMl)}
              </Text>
            </ContentBox>
          </>
        )}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aJt',
  description: 'aJt',
  keywords: ['Measure', 'Volume', 'Litres', 'Millilitres'],
  schema: z
    .object({
      jugACapacityL: z.number().int().min(2).max(4),
      jugBCapacityL: z.number().int().min(3).max(5),
      jugBMinorTickSize: numberEnum([200, 250, 500]),
      juiceL: z.number().int().min(1).max(3),
      juiceMl: z.number().int().min(100).max(900).multipleOf(100)
    })
    .refine(
      val => val.jugACapacityL < val.jugBCapacityL,
      'jugACapacityL must be less than jugBCapacityL.'
    )
    .refine(val => val.juiceL < val.jugACapacityL, 'juiceL must be less than jugACapacityL.')
    .refine(
      val => val.juiceMl % val.jugBMinorTickSize === 0,
      'juiceMl must be a multiple of jugBMinorTickSize.'
    ),
  simpleGenerator: () => {
    const jugACapacityL = randomIntegerInclusive(2, 4);

    const jugBCapacityL = randomIntegerInclusive(jugACapacityL + 1, 5);

    const jugBMinorTickSize = getRandomFromArray([200, 250, 500] as const);

    const juiceL = randomIntegerInclusive(1, jugACapacityL - 1);

    const juiceMl = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x % jugBMinorTickSize === 0
    });

    return { jugACapacityL, jugBCapacityL, jugBMinorTickSize, juiceL, juiceMl };
  },
  Component: props => {
    const {
      question: { jugACapacityL, jugBCapacityL, jugBMinorTickSize, juiceL, juiceMl },
      translate
    } = props;

    const unitsPerMajorTickA = 9;

    const unitsPerMajorTickB = 1000 / jugBMinorTickSize - 1;

    const totalJuice = juiceL * 1000 + juiceMl;

    return (
      <QF13DragLiquidInJug
        title={translate.instructions.theOrangeJuiceInJugAIsPouredIntoJugB()}
        pdfTitle={translate.instructions.theOrangeJuiceInJugAIsPouredIntoJugBPDF()}
        testCorrect={totalJuice}
        jugCapacity={jugBCapacityL * 1000}
        tickValue={1000}
        liquidType="orange"
        unitsPerMajorTick={unitsPerMajorTickB}
        lhsContent={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'flex-end' }}>
            <JugWithLiquid
              dimens={{ width: dimens.width, height: dimens.height * 0.85 }}
              jugCapacity={jugACapacityL * 1000}
              tickValue={1000}
              liquidAmount={totalJuice}
              liquidType="orange"
              unitsPerMajorTick={unitsPerMajorTickA}
            />
          </View>
        )}
        label={translate.letters.B()}
        lhsContentLabel={translate.letters.A()}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureCapacityAndVolumeInLitresAndMillilitres',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
