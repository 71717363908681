/** Corresponds to tags <b> and <i> in the markup. */
export type SpanToken = {
  type: 'span';
  children: AllowedInSpanContext[];
  styleModifiers?: 'bold' | 'italic';
};

/** Corresponds to text (no tag) in the markup. */
export type TextToken = { type: 'text'; value: string };

/** Corresponds to tag <br> in the markup. */
export type BrToken = { type: 'br' };

/** Corresponds to tag <ans> in the markup. */
export type AnsToken = { type: 'ans'; index: number };

/** Corresponds to tag <frac> in the markup. */
export type FracToken = {
  type: 'frac';
  whole?: [AST] | AllowedInSpanContext[];
  numerator: [AST] | AllowedInSpanContext[];
  denominator: [AST] | AllowedInSpanContext[];
};

/** Corresponds to tag <asset> in the markup. */
export type AssetToken = {
  type: 'asset';
  name: string;
};

/** Corresponds to tag <g> in the markup. */
export type GroupToken = {
  type: 'group';
  children: AST[];
};

export type AllowedInSpanContext = SpanToken | TextToken | BrToken;
export const isAllowedInSpanContext = (token: AST): token is AllowedInSpanContext =>
  token.type === 'span' || token.type === 'text' || token.type === 'br';

/** Abstract Syntax Tree for our markup language. */
type AST = SpanToken | TextToken | BrToken | AnsToken | FracToken | AssetToken | GroupToken;
export default AST;
