import { View, StyleSheet } from 'react-native';
import { AssetSvg } from '../../assets/svg';
import Text from '../typography/Text';
import Button from '../atoms/Button';
import { colors } from '../../theme/colors';
import { useI18nContext } from '../../i18n/i18n-react';
import LinkText from '../atoms/LinkText';
import { MINIMUM_QUESTION_HEIGHT, QUESTION_WIDTH } from '../../theme/scaling';

type Props = {
  /** Callback for when the try again button is clicked. If absent, the try again button isn't there. */
  onTryAgainClicked?: () => void;
  /** Callback for when the close button is clicked. If absent, the close button isn't there. */
  onExitClicked?: () => void;
  /** Callback for when the back-to-home button is clocked. If absent, the try again button isn't there. */
  onHomeClicked?: () => void;
};

/**
 * 1280x720 view showing:
 * - the background card, which is common to all questions
 * - the Infinity logo
 * - a notice saying that something went wrong
 * - buttons for escaping, similar to `Results`.
 */
export default function QuizErrorCard({ onTryAgainClicked, onExitClicked, onHomeClicked }: Props) {
  const translate = useI18nContext().LL;

  return (
    <View style={styles.container}>
      {/* Close icon */}
      {onExitClicked && (
        <Button variant="circle" onPress={onExitClicked} style={styles.iconClose}>
          <AssetSvg name="Close" width={24} height={24} />
        </Button>
      )}

      {/* Logo */}
      <AssetSvg name="WhiteRoseInfinityDark" width={450} />

      {/* Message */}
      <Text variant="WRN700" style={{ textAlign: 'center' }}>
        {translate.quiz.errors.errorBoundaryTitle()}
      </Text>
      <Text
        variant="WRN400"
        style={{ fontSize: 32, lineHeight: 48, textAlign: 'center', width: '80%' }}
      >
        {translate.quiz.errors.errorBoundaryBody()}
      </Text>

      {/* Actions */}
      <View style={{ rowGap: 24 }}>
        {onTryAgainClicked && (
          <Button
            onPress={onTryAgainClicked}
            style={{ height: 96 }}
            baseColor={colors.seaGreenCrayola}
            testId="TRY_AGAIN_BUTTON"
          >
            <Text variant="ButtonLarge" style={{ paddingRight: 34 }}>
              {translate.quiz.modals.tryAgain()}
            </Text>
            <AssetSvg name="ArrowRight" style={styles.iconArrow} width={48} height={49} />
          </Button>
        )}
        {onHomeClicked && (
          <View
            testID="BACK_HOME_BUTTON"
            style={{ paddingHorizontal: 32, paddingTop: 22, paddingBottom: 16 }}
          >
            <LinkText action={onHomeClicked} style={[styles.linkText]}>
              {translate.quiz.backToHome()}
            </LinkText>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderColor: colors.prussianBlue,
    borderRadius: 24,
    borderWidth: 3,
    display: 'flex',
    height: MINIMUM_QUESTION_HEIGHT - 2 * 16,
    width: QUESTION_WIDTH - 2 * 12,
    marginVertical: 16,
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 40
  },

  linkText: {
    fontFamily: 'Biotif-Medium',
    fontSize: 29,
    lineHeight: 43.5,
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  iconArrow: {
    position: 'absolute',
    right: 24
  },
  iconClose: {
    borderWidth: 0,
    left: 12,
    top: 12,
    position: 'absolute',
    width: 64,
    height: 64,
    zIndex: 100,
    // Override the defaults in button
    paddingTop: 0,
    paddingBottom: 0
  }
});
