import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ScaleFactorContext } from 'common/src/theme/scaling';
import { colors } from '../../theme/colors';
import { DisplayMode } from '../../contexts/displayMode';

type Props = {
  items: (JSX.Element | undefined)[][];
  style?: StyleProp<ViewStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  cellStyle?: StyleProp<ViewStyle>;
  borderWidthAmount?: number;
  noGrid?: boolean;
};

/**
 * A simple grid with borders on each cell.
 *
 * By default, each cell simply wraps its contents. noGrid removes the border around the empty cells if set to true;
 * cellStyle affects what's inside that cell's borders. Each cell's borders overlap on two sides, to avoid double-bordering.
 *
 * If you want the table to fill available space and each cell to have some fraction of that available space, consider
 * ```
 * style={{flex: 1, alignSelf: 'stretch'}}
 * rowStyle={{flex: 1}}
 * cellStyle={{flex: 1}}
 * ```
 * or something like that.
 */
export default function Table({
  items,
  style,
  rowStyle,
  cellStyle,
  borderWidthAmount,
  noGrid
}: Props) {
  const styles = useStyles(borderWidthAmount);

  return (
    <View style={[styles.tableContainer, style]}>
      {items.map((row, rowIndex) => (
        <View key={rowIndex} style={[styles.tableRow, rowStyle]}>
          {row.map((item, columnIndex) => (
            <View key={columnIndex} style={[styles.tableCell, cellStyle]}>
              {item}
              <View
                pointerEvents="none"
                style={[styles.tableCellBorder, noGrid && !item && styles.noBorder]}
              />
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

const useStyles = (borderWidthAmount?: number) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const displayMode = useContext(DisplayMode);
  const borderWidth =
    borderWidthAmount ??
    (displayMode === 'digital' ? Math.max(2, 2 / scaleFactor) : Math.max(4, 2 / scaleFactor));

  return useMemo(
    () =>
      StyleSheet.create({
        tableContainer: {
          justifyContent: 'center',
          alignSelf: 'center',

          // All cells's borders spill over into their bottom-side and right-side neighbors. The right-most and
          // bottom-most cells have no neighbors on that side to spill into, so add some padding here.
          paddingRight: borderWidth,
          paddingBottom: borderWidth
        },
        tableRow: {
          flexDirection: 'row'
        },
        tableCell: {
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',

          // Space for the border's top and left. The border is an overlay view, so we need to manually make space for it.
          paddingTop: borderWidth,
          paddingLeft: borderWidth
        },
        tableCellBorder: {
          // Border. Essentially we want the bottom right part of this cell to overlap the cell to the bottom-right, so
          // that borders between cells overlap. This prevents double-bordering, but each cell still has a whole border
          // to itself.
          position: 'absolute',
          top: 0,
          left: 0,
          right: -borderWidth,
          bottom: -borderWidth,
          borderWidth: borderWidth,
          borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
        },
        noBorder: {
          borderWidth: 0
        }
      }),
    [borderWidth, displayMode]
  );
};
