import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'beX',
  description: 'beX',
  keywords: ['Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numbers: z.array(z.number().int().min(46).max(100)).length(5),
    answerIndices: z.array(z.number().int().min(0).max(4)),
    incorrectAnswers: z.array(z.number().int())
  }),
  simpleGenerator: () => {
    const isCountingForwards = getRandomBoolean();

    const startingNumber = randomIntegerInclusive(
      isCountingForwards ? 46 : 50,
      isCountingForwards ? 96 : 100
    );

    const numberOfMissingNumbers = getRandomFromArray([1, 2] as const);

    const numbers = countRange(5).map((_, index) =>
      isCountingForwards ? startingNumber + index : startingNumber - index
    );

    const answerIndices = randomUniqueIntegersInclusive(0, 4, numberOfMissingNumbers);

    const { incorrectAnswers } = rejectionSample(
      () => {
        const waysToVary = getRandomSubArrayFromArray(
          [
            'ReverseDigit',
            'TenLessThanCorrect',
            'TenMoreThanCorrect',
            'OneLessThanSmallest',
            'OneMoreThanGreatest'
          ],
          numberOfMissingNumbers === 2 ? 2 : 3
        );

        const incorrectAnswers = waysToVary.map(wayToVary => {
          switch (wayToVary) {
            case 'ReverseDigit':
              return parseFloat(
                numbers[getRandomFromArray(answerIndices) as number]
                  .toString()
                  .split('')
                  .reverse()
                  .join('')
              );
            case 'TenLessThanCorrect':
              return numbers[getRandomFromArray(answerIndices) as number] - 10;
            case 'TenMoreThanCorrect':
              return numbers[getRandomFromArray(answerIndices) as number] + 10;
            case 'OneLessThanSmallest':
              return sortNumberArray(numbers)[0] - 1;
            default:
              return sortNumberArray(numbers, 'descending')[0] + 1;
          }
        });
        return { incorrectAnswers };
      },
      // Only permit them if all answers are different
      ({ incorrectAnswers }) =>
        arrayHasNoDuplicates([
          ...incorrectAnswers,
          ...numbers.filter((_, idx) => answerIndices.includes(idx))
        ])
    );

    return {
      numbers,
      answerIndices,
      incorrectAnswers
    };
  },
  Component: props => {
    const {
      question: { numbers, answerIndices, incorrectAnswers },
      translate
    } = props;

    const answers = numbers.filter((_, idx) => answerIndices.includes(idx));

    const shuffledAnswers = shuffle([...answers, ...incorrectAnswers], {
      random: seededRandom(props.question)
    });

    const sentence = numbers
      .map((x, index) => (answerIndices.includes(index) ? `<ans/>` : x))
      .join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToFillInTheMissingNumbers(answers.length)}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheCount()}
        sentence={sentence}
        items={shuffledAnswers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={answers}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'beY',
  description: 'beY',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    answerIndices: z.array(z.number().int().min(0).max(7)),
    numbers: z.array(z.number().int().min(44).max(100)).length(8)
  }),
  simpleGenerator: () => {
    const isCountingForwards = getRandomBoolean();

    const startingNumber = randomIntegerInclusive(
      isCountingForwards ? 44 : 51,
      isCountingForwards ? 93 : 100
    );

    const numbers = countRange(8).map((_, index) =>
      isCountingForwards ? startingNumber + index : startingNumber - index
    );

    const { answerIndices } = rejectionSample(
      () => {
        const numberOfMissingNumbers = randomIntegerInclusive(1, 5);
        const answerIndices = randomUniqueIntegersInclusive(0, 7, numberOfMissingNumbers);

        return { answerIndices };
      },
      // check that always give at least 2 consecutive values
      ({ answerIndices }) =>
        numbers
          .map((number, index) => (answerIndices.includes(index) ? `<ans/>` : number))
          .filter(item => item !== '<ans/>')
          .some((num, _, arr) => typeof num === 'number' && arr.includes(num + 1))
    );

    return { numbers, answerIndices };
  },
  Component: ({ question: { numbers, answerIndices }, translate }) => {
    const numberArray = numbers.map((number, index) =>
      answerIndices.includes(index) ? `<ans/>` : number.toLocaleString()
    );

    const answerArray = numbers.flatMap((number, idx) =>
      answerIndices.includes(idx) ? [number.toString()] : []
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'beZ',
  description: 'beZ',
  keywords: ['Count', 'Forwards', 'Backwards', 'Numerals'],
  schema: z.object({
    isCountingForwards: z.boolean(),
    name: nameSchema,
    numberA: z.number().int().min(50).max(93),
    numberB: z.number().int().min(56).max(100),
    correctNumbers: z.array(z.number()),
    incorrectNumbers: z.array(z.number())
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomName();
    const isCountingForwards = getRandomBoolean();

    const { incorrectNumbers, correctNumbers, numberA, numberB } = rejectionSample(
      () => {
        const numberA = randomIntegerInclusive(50, 93);
        const numberB = randomIntegerInclusive(numberA + 6, 100);

        const numberOfCorrectAnswers = randomIntegerInclusive(2, 6);

        const correctNumbers = randomUniqueIntegersInclusive(
          numberA,
          numberB,
          numberOfCorrectAnswers
        );

        const waysToVary = getRandomSubArrayFromArray(
          [
            'MultipleOfTenBeforeFirstNumber',
            'MultipleOfTenAfterLastNumber',
            'ReverseNumber',
            'RandomNumber'
          ],
          6 - numberOfCorrectAnswers
        );

        const incorrectNumbers = waysToVary.map(wayToVary => {
          switch (wayToVary) {
            case 'MultipleOfTenBeforeFirstNumber':
              return Math.floor((numberA - 1) / 10) * 10;
            case 'MultipleOfTenAfterLastNumber':
              return Math.ceil(numberB / 10 + 1) * 10;
            case 'ReverseNumber': {
              const number = getRandomFromArray(correctNumbers) as number;
              return parseFloat(number.toString().split('').reverse().join(''));
            }
            default:
              return randomIntegerInclusive(1, 100);
          }
        });
        return { incorrectNumbers, correctNumbers, numberA, numberB };
      },
      // Only permit them if all answers are different
      ({ incorrectNumbers, correctNumbers }) =>
        arrayHasNoDuplicates([...incorrectNumbers, ...correctNumbers])
    );

    return {
      isCountingForwards,
      name,
      numberA,
      numberB,
      correctNumbers,
      incorrectNumbers
    };
  },

  Component: props => {
    const {
      question: { isCountingForwards, name, numberA, numberB, correctNumbers, incorrectNumbers },
      translate
    } = props;

    const [orderedNumberA, orderedNumberB] = isCountingForwards
      ? [Math.min(numberA, numberB), Math.max(numberA, numberB)]
      : [Math.max(numberA, numberB), Math.min(numberA, numberB)];

    const [title, pdfTile] = isCountingForwards
      ? [
          translate.ks1Instructions.characterIsCountingForwardsFromXToYSelectTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          ),
          translate.ks1PDFInstructions.characterIsCountingForwardsFromXToYTickTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          )
        ]
      : [
          translate.ks1Instructions.characterIsCountingBackwardsFromXToYSelectTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          ),
          translate.ks1PDFInstructions.characterIsCountingBackwardsFromXToYTickTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          )
        ];

    const items = shuffle([...correctNumbers, ...incorrectNumbers], {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={title}
        pdfTitle={pdfTile}
        testCorrect={correctNumbers}
        multiSelect
        items={items.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountFrom50To100',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
