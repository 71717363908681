import {
  arrayHasNoDuplicates,
  range,
  rangeAsString,
  filledArray,
  NonEmptyArray
} from 'common/src/utils/collections';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  getTwoConsecutiveAndOneNot
} from 'common/src/utils/random';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aci',
  description: 'aci',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1000).max(9000)).length(3).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 10000;
    const interval = 1000;

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and a third that is not */
    const numbers = getTwoConsecutiveAndOneNot(
      startingNumber + interval,
      endNumber - interval,
      interval
    );

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 10000;
    const interval = 1000;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'acj',
  description: 'acj',
  keywords: ['Number line', 'Place value', '100', 'Hundred'],
  schema: z.object({
    number: z.number().int().min(500).max(9500)
  }),
  simpleGenerator: () => {
    const answerInterval = 500;
    const startingNumber = 0;
    const endNumber = 10000;
    const labelInterval = 5000;

    // Make this miss the first, middle and last number
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    ).filter(x => x !== labelInterval) as NonEmptyArray<number>;
    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 1000;
    const labelInterval = 5000;
    const startingNumber = 0;
    const endNumber = 10000;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ack',
  description: 'ack',
  keywords: ['Number line', 'Place value', '10,000'],
  schema: z.object({
    number: z.number().int().min(0).max(10000)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 10000;
    const tickInterval = 1000;
    const answerInterval = 500;

    // Make this miss the first and last number
    const choices = range(startingNumber + tickInterval, endNumber - tickInterval, answerInterval);
    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 10000;
    const tickInterval = 1000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 100, number + 100]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'acl',
  description: 'acl',
  keywords: ['Number line', 'Place value', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(500).max(9500),
      interval: z.union([z.literal(500), z.literal(2000), z.literal(2500)])
    })
    .refine(
      val => val.number >= val.interval,
      val => ({ message: `All answers must be greater than or equal to ${val.interval}` })
    )
    .refine(
      val => val.number <= 10000000 - val.interval,
      val => ({ message: `All answers must less than or equal to ${10000000 - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([500, 2000, 2500] as const);
    const startingNumber = 0;
    const endNumber = interval === 500 ? 2500 : 10000;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, interval };
  },
  Component: props => {
    const {
      question: { number, interval },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = interval === 500 ? 2500 : 10000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acm',
  description: 'acm',
  keywords: ['Number line', 'Place value', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(1000).max(9000),
      startingNumber: z.number().int().min(1000).max(4000),
      endNumber: z.number().int().min(6000).max(9000)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 4) * 1000;
    const endNumber = startingNumber + 5000;
    const answerInterval = 500;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 50, number + 50]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'acn',
  description: 'acn',
  keywords: ['Number line', 'Place value', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(1020).max(9990),
      startingNumber: z.number().int().min(1010).max(9900),
      endNumber: z.number().int().min(1110).max(10000),
      interval: z.union([
        z.literal(10),
        z.literal(20),
        z.literal(50),
        z.literal(100),
        z.literal(200),
        z.literal(500)
      ])
    })
    .refine(
      val => val.number >= val.startingNumber + val.interval,
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.number <= val.endNumber - val.interval,
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([10, 20, 50, 100, 200, 500] as const);
    const startingNumber = getRandomFromArray(
      range(Math.max(1000 + interval, interval * 10), 10000 - 10 * interval, interval * 10)
    );
    const endNumber = startingNumber + 10 * interval;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLineTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});

export default SmallStep;
