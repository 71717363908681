import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { DIV, MULT } from 'common/src/constants';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { useMemo } from 'react';
import { View } from 'react-native';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import {
  ScientificNotation,
  compareFloats,
  lessThanGreaterThanOrEqualTo
} from 'common/src/utils/math';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from 'common/src/components/question/representations/Place Value Chart/PlaceValueChart';
import QF23CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { AssetSvg } from 'common/src/assets/svg';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { countRange } from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNW',
  description: 'aNW',
  keywords: ['Place value chart', 'Decimals', 'Divide', '1-digit'],
  schema: z.object({
    number1: z.number().int().min(10).max(30).multipleOf(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 30, 10);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    // More numbers
    const number2 = number1 / 10;
    const number3 = number2 / 10;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useCountersToHelpCompleteSentences()}
        sentences={[
          `<ans /> ${DIV} 10 = <ans/>`,
          `<ans/> ${translate.answerSentences.onesDivideByTenIsEqualTo(number2)} <ans/>`
        ]}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], number2) &&
          compareFloats(userAnswer[0][1], number3) &&
          compareFloats(userAnswer[1][0], number2) &&
          compareFloats(userAnswer[1][1], number3)
        }
        extraSymbol="decimalPoint"
        pdfDirection="column"
        Content={({ dimens }) => (
          <View
            style={{
              height: (dimens.height / 3) * number2,
              flexDirection: 'row'
            }}
          >
            {countRange(10).map(i => (
              <View
                key={i}
                style={
                  i !== 0
                    ? {
                        width: dimens.width / 10,
                        borderLeftWidth: 2,
                        borderColor: colors.prussianBlue,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderStyle: 'dashed'
                      }
                    : { width: dimens.width / 10, alignItems: 'center', justifyContent: 'center' }
                }
              >
                {countRange(number2).map((_image, imageIndex) => (
                  <AssetSvg key={imageIndex} name="Place_value/0.1" width={64} height={64} />
                ))}
              </View>
            ))}
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNX',
  description: 'aNX',
  keywords: ['Place value chart', 'Decimals', 'Divide', '1-digit'],
  schema: z.object({
    number: z.number().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 9);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = number / 10;

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragTheCountersToShowTheAnswer(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[0, -1]}
        counterVariant="greyCounter"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aNY',
  description: 'aNY',
  keywords: ['Place value chart', 'Decimals', 'Divide', '1-digit'],
  schema: z.object({
    number: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    // Answer
    const answer = number / 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsAOneDigitNumberOnAPlaceValueChart()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={`${translate.answerSentences.completeDivision()}<br/>${number} ${DIV} 10 = <ans/>`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[0, -1]}
            counterVariant={'number'}
            dimens={dimens}
          />
        )}
        inputMaxCharacters={3}
        extraSymbol="decimalPoint"
        pdfDirection="column"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNZ',
  description: 'aNZ',
  keywords: ['Decimals', 'Divide', '1-digit'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(9),
      number3: z.number().int().min(1).max(9),
      number5: z.number().int().min(1).max(9)
    })
    .refine(
      val =>
        val.number1 !== val.number3 && val.number1 !== val.number5 && val.number3 !== val.number5,
      'number1, number3 and number5 must all be different numbers'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number3, number5] = randomUniqueIntegersInclusive(1, 9, 3);

    return { number1, number3, number5 };
  },
  Component: props => {
    const {
      question: { number1, number3, number5 },
      translate
    } = props;

    // More numbers
    const number2 = number1 / 10;
    const number4 = number3 / 10;
    const number6 = number5 / 10;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheDivisions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], number2) &&
          compareFloats(userAnswer[1][0], number4) &&
          compareFloats(userAnswer[2][0], number5)
        }
        inputMaxCharacters={3}
        sentences={[
          `${number1} ${DIV} 10 = <ans/>`,
          `${number3} ${DIV} 10 = <ans/>`,
          `<ans/> ${DIV} 10 = ${number6}`
        ]}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number2.toLocaleString()],
            [number4.toLocaleString()],
            [number5.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={900}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aNZ2',
  description: 'aNZ',
  keywords: ['Decimals', 'Divide', '1-digit'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    // More numbers
    const number2 = number1 / 10;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheDivision()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], number2)}
        inputMaxCharacters={3}
        sentence={`${number1} ${DIV} 10 = <ans/>`}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [number2.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aN0',
  description: 'aN0',
  keywords: ['Decimals', 'Divide', '1-digit'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(9),
      number2: z.number().int().min(1).max(9),
      number3: z.number().int().min(1).max(9),
      number4: z.number().int().min(1).max(9)
    })
    .refine(val => val.number2 !== val.number3, 'number2 must not be equal to number3'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusive(1, 9);
    const number3 = randomIntegerInclusive(1, 9, {
      constraint: x => x !== number2
    });
    const number4 = randomIntegerInclusive(1, 9);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // More numbers
    const number5 = number4 / 10;

    const statements = useMemo(() => {
      const eqs = [
        { statement: `${number1} ${DIV} 10 <ans/> ${number1} ${MULT} 10`, answer: '<' },
        {
          statement: `${number2} ${DIV} 10 <ans/> ${number3} ${DIV} 10`,
          answer: lessThanGreaterThanOrEqualTo(number2 / 10, number3 / 10)
        },
        { statement: `${number4} ${DIV} 10 <ans/> ${number5} ${MULT} 10`, answer: '<' }
      ];
      return shuffle(eqs, { random: seededRandom(props.question) });
    }, [number1, number2, number3, number4, number5, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragInequalitiesOrEqualToMakeStatementsCorrect()}
        pdfTitle={translate.instructions.dragInequalitiesOrEqualToMakeStatementsCorrectPDF()}
        items={['<', '>', '=']}
        actionPanelVariant="end"
        itemVariant="square"
        sentences={statements.map(({ statement }) => statement)}
        testCorrect={statements.map(({ answer }) => [answer])}
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aN1',
  description: 'aN1',
  keywords: ['Place value counters', 'Decimals', 'Divide', '1-digit'],
  schema: z
    .object({
      number1: z.number().int().min(4).max(20),
      number2: z.number().int().min(2).max(9),
      number4: z.number().int().min(21).max(55),
      number5: z.number().int().min(2).max(9)
    })
    .refine(val => val.number1 % val.number2 === 0, 'number2 must be a factor of number1')
    .refine(val => val.number4 % val.number5 === 0, 'number5 must be a factor of number4'),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);
    const number1 = randomIntegerInclusive(4, 20, {
      constraint: x => x % number2 === 0
    });
    const number5 = randomIntegerInclusive(2, 9);
    const number4 = randomIntegerInclusive(21, 55, {
      constraint: x => x % number5 === 0
    });

    return { number1, number2, number4, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5 },
      translate
    } = props;

    // More numbers
    const number3 = number1 / number2;

    // Answers
    const answer1 = number2;
    const answer2 = number4 / number5;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={3}
        sentences={[
          `${number1} ${DIV} <ans/> ${DIV} 10 = ${number3} ${DIV} 10`,
          `${number4} ${DIV} ${number5} ${DIV} 10 = <ans/> ${DIV} 10`
        ]}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA1DigitNumberBy10',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
