import { z } from 'zod';
import { all, create, number } from 'mathjs';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';

import { findFactors, findFactorsExcludingSelfAnd1 } from 'common/src/utils/factors';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  nestedArrayHasNoDuplicates,
  range
} from 'common/src/utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from 'common/src/components/typography/Text';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJS',
  description: 'aJS',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z.object({
    numbers1: z.array(z.number().int().min(2).max(12)).length(3),
    numbers2: z.array(z.number().int().min(6).max(30)).length(3),
    numbers3: z.array(z.number().int().min(2).max(30)).length(3)
  }),
  simpleGenerator: () => {
    const numbers1 = randomUniqueIntegersInclusive(2, 12, 3);

    const numbers2 = randomUniqueIntegersInclusive(6, 30, 3, {
      constraint: x => findFactors(x).length > 4
    });

    const number3A = getRandomFromArray(
      findFactors(numbers2[0]).filter(number => number !== 1 && number !== numbers2[0])
    ) as number;

    const number3B = getRandomFromArray(
      findFactors(numbers2[1]).filter(number => number !== 1 && number !== numbers2[1])
    ) as number;

    const number3C = getRandomFromArray(
      findFactors(numbers2[2]).filter(number => number !== 1 && number !== numbers2[2])
    ) as number;

    const numbers3 = [number3A, number3B, number3C];

    return { numbers1, numbers2, numbers3 };
  },

  Component: props => {
    const {
      question: { numbers1, numbers2, numbers3 },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={range(0, 2).map(i => [
          number(math.evaluate(`${numbers2[i]} /${numbers3[i]}`)).toString()
        ])}
        sentences={range(0, 2).map(
          i =>
            `${numbers1[i]} ${MULT} ${numbers2[i]} = ${numbers1[i]} ${MULT} ${numbers3[i]} ${MULT} <ans/>`
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJT',
  description: 'aJT',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(24),
      number6: z.number().int().min(2).max(12)
    })
    .refine(val => val.number2 % val.number3 === 0, 'number2 is a factor of number3')
    .refine(val => findFactors(val.number2).length >= 3, 'number2 must have at least 3 factors.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x => findFactors(x).filter(number => number !== number1).length >= 3
    });
    const number3 = getRandomFromArray(
      findFactors(number2).filter(number => number !== 1 && number !== number2)
    ) as number;
    const number6 = randomIntegerInclusive(2, 12, {
      constraint: x => number2 % x !== 0 && x !== number1
    });

    return { number1, number2, number3, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number6 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} / ${number3}`));
    const number5 = number(math.evaluate(`${number1} * ${number2}`));

    const draggables = shuffle(
      [
        number1.toLocaleString(),
        number2.toLocaleString(),
        number3.toLocaleString(),
        number4.toLocaleString(),
        number5.toLocaleString(),
        number6.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        items={draggables}
        sentence={`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = ${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`}
        testCorrect={[number5.toString()]}
      />
    );
  }
});

const Question2V2 = newQuestionContent({
  uid: 'aJT2',
  description: 'aJT',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(10),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(12),
      number4: z.number().int().min(2).max(12),
      number5: z.number().int().min(12).max(240),
      number6a: z.number().int().min(2).max(250),
      number6b: z.number().int().min(2).max(250),
      number7: z.number().int().min(2).max(250),
      number8: z.number().int().min(2).max(250),
      number9: z.number().int().min(2).max(250)
    })
    .refine(
      val => findFactorsExcludingSelfAnd1(val.number2).length >= 3,
      'number2 must have at least 3 factors.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x =>
        findFactorsExcludingSelfAnd1(x).filter(number => number !== number1).length >= 3
    });
    const number3 = getRandomFromArray(findFactorsExcludingSelfAnd1(number2)) as number;
    const number4 = number2 / number3;
    const number5 = number1 * number2;

    const { number6a, number6b, number7, number8, number9 } = rejectionSample(
      () => {
        const [number6a, number6b] = randomUniqueIntegersInclusive(number5 - 10, number5 + 10, 2, {
          constraint: x => x !== number5
        });
        const number7 = getRandomBoolean()
          ? number1 * number3 * (number4 + 1)
          : number1 * number3 * (number4 - 1);
        const number8 = getRandomBoolean()
          ? number1 * (number3 + 1) * number4
          : number1 * (number3 - 1) * number4;
        const number9 = getRandomBoolean()
          ? (number1 + 1) * number3 * number4
          : (number1 + 1) * number3 * number4;

        return { number6a, number6b, number7, number8, number9 };
      },
      ({ number6a, number6b, number7, number8, number9 }) =>
        arrayHasNoDuplicates([number6a, number6b, number7, number8, number9])
    );

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6a,
      number6b,
      number7,
      number8,
      number9
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6a,
        number6b,
        number7,
        number8,
        number9
      },
      translate
    } = props;

    const options = [number5, number6a, number6b, number7, number8, number9];

    const draggables = shuffle(
      options.map(value => ({ value, component: value.toLocaleString() })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        items={draggables}
        testCorrect={[
          {},
          {
            right: [number5]
          }
        ]}
        sentences={[
          {
            left: `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`,
            right: `${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
          },
          { right: '<ans/>' }
        ]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aJU',
  description: 'aJU',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(7).max(12),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(24),
      number6: z.number().int().min(2).max(12)
    })
    .refine(val => val.number2 % val.number3 === 0, 'number2 is a factor of number3')
    .refine(val => findFactors(val.number2).length >= 3, 'number2 must have at least 3 factors.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(7, 12);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x => findFactors(x).filter(number => number !== number1).length >= 3
    });
    const number3 = getRandomFromArray(
      findFactors(number2).filter(number => number !== 1 && number !== number2)
    ) as number;
    const number6 = randomIntegerInclusive(2, 12, {
      constraint: x => number2 % x !== 0 && x !== number1
    });

    return { number1, number2, number3, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number6 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} / ${number3}`));
    const number5 = number(math.evaluate(`${number1} * ${number2}`));

    const draggables = shuffle(
      [
        number1.toLocaleString(),
        number2.toLocaleString(),
        number3.toLocaleString(),
        number4.toLocaleString(),
        number5.toLocaleString(),
        number6.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        items={draggables}
        sentence={`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = ${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`}
        testCorrect={[number5.toString()]}
      />
    );
  }
});

const Question3V2 = newQuestionContent({
  uid: 'aJU2',
  description: 'aJU',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z.object({
    number1: z.number().int().min(12).max(245),
    number2: z.number().int().min(2).max(10),
    options: z.array(z.object({ string: z.string(), isCorrect: z.boolean() })).length(4)
  }),
  simpleGenerator: () => {
    const numberOfPrimes = randomIntegerInclusive(3, 4);
    const { primes, number1 } = rejectionSample(
      () => {
        let primes;
        if (numberOfPrimes === 3) {
          const [prime1, prime2] = getRandomSubArrayFromArray([2, 3, 5, 7], 2);
          const prime3 = getRandomFromArray([2, 3, 5, 7]);
          primes = [prime1, prime2, prime3];
        } else {
          primes = countRange(4).map(() => getRandomFromArray([2, 3, 5, 7]));
        }

        const number1 = primes.reduce((a, b) => a * b);
        return { primes, number1 };
      },
      val => val.number1 <= 40
    );

    const number2 = randomIntegerInclusive(2, 10);
    const number3 = primes[0] * primes[1];
    const number4 = primes[1] * primes[2];
    const number5 = primes[0] * primes[2];

    const number6 = number1 / number3;
    const number7 = number1 / number4;
    const number8 = number1 / number5;

    const number3a = number3 + (getRandomBoolean() ? 1 : -1);
    const number4a = number4 + (getRandomBoolean() ? 1 : -1);
    const number5a = number5 + (getRandomBoolean() ? 1 : -1);
    const [number9a, number9b, number9c] = randomUniqueIntegersInclusive(1, number1 - 1, 3);

    const { correctOptions, incorrectOptions, correctAnswersCount } = rejectionSample(
      () => {
        const correctAnswersCount = randomIntegerInclusive(1, 3);
        const correctOptions = getRandomSubArrayFromArray(
          [
            [number3, number6],
            [number4, number7],
            [number5, number8]
          ],
          correctAnswersCount
        );

        const incorrectOptions = getRandomSubArrayFromArray(
          [
            [number3a, number6],
            [number4a, number7],
            [number5a, number8],
            [number9a, number1 - number9a],
            [number9b, number1 - number9b],
            [number9c, number1 - number9c]
          ],
          4 - correctAnswersCount
        );
        return { correctOptions, incorrectOptions, correctAnswersCount };
      },
      val => nestedArrayHasNoDuplicates([...val.correctOptions, ...val.incorrectOptions])
    );

    const isMultilpierFirst = countRange(4).map(getRandomBoolean);

    const params = shuffle([
      ...correctOptions.map((array, i) => ({
        values: isMultilpierFirst[i] ? [number2, ...array] : [...array, number2],
        isCorrect: true
      })),
      ...incorrectOptions.map((array, i) => ({
        values: isMultilpierFirst[i + correctAnswersCount]
          ? [number2, ...array]
          : [...array, number2],
        isCorrect: false
      }))
    ]);

    const options = params.map(obj => ({
      string: `${obj.values[0].toLocaleString()} ${MULT} ${obj.values[1].toLocaleString()} ${MULT} ${obj.values[2].toLocaleString()}`,
      isCorrect: obj.isCorrect
    }));

    return { number1, number2, number3, options };
  },

  Component: props => {
    const {
      question: { number1, number2, options },
      translate
    } = props;

    const multiSelect = options.filter(val => val.isCorrect).length > 1;

    return (
      <QF11SelectImagesUpTo4
        title={
          multiSelect
            ? translate.instructions.selectCalsEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
              )
            : translate.instructions.selectCalEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
              )
        }
        pdfTitle={
          multiSelect
            ? translate.instructions.circleCalsEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
              )
            : translate.instructions.circleCalEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
              )
        }
        multiSelect={multiSelect}
        testCorrect={options.filter(val => val.isCorrect).map(val => val.string)}
        numItems={4}
        renderItems={options.map(val => ({
          value: val.string,
          component: <Text variant="WRN700">{val.string}</Text>
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aJV',
  description: 'aJV',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(24)
    })
    .refine(val => val.number2 % val.number3 === 0, 'number2 is a factor of number3')
    .refine(val => findFactors(val.number2).length >= 3, 'number2 must have at least 3 factors.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x => findFactors(x).length >= 3
    });
    const number3 = getRandomFromArray(
      findFactors(number2).filter(x => x !== 1 && x !== number2)
    ) as number;
    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} / ${number3}`));
    const number5 = number(math.evaluate(`${number1} * ${number2}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[number4.toString(), number5.toString()]}
        sentence={`${number1} ${MULT} ${number2} = ${number1} ${MULT} ${number3} ${MULT} <ans/> = <ans/>`}
      />
    );
  }
});

const Question4V2 = newQuestionContent({
  uid: 'aJV2',
  description: 'aJV',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(12),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(12)
    })
    .refine(val => val.number2 % val.number3 === 0, 'number3 is a factor of number2')
    .refine(val => findFactors(val.number2).length >= 3, 'number2 must have at least 3 factors.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x => findFactors(x).length >= 3
    });
    const number3 = getRandomFromArray(findFactorsExcludingSelfAnd1(number2)) as number;
    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} / ${number3}`));
    const number5 = number(math.evaluate(`${number1} * ${number2}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[number4.toString(), number5.toString()]}
        sentence={`${number1} ${MULT} ${number2} = ${number1} ${MULT} ${number3} ${MULT} <ans/> = <ans/>`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJW',
  description: 'aJW',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(7).max(12),
      number2: z.number().int().min(6).max(24),
      number3: z.number().int().min(2).max(24)
    })
    .refine(val => val.number2 % val.number3 === 0, 'number2 is a factor of number3')
    .refine(val => findFactors(val.number2).length >= 3, 'number2 must have at least 3 factors.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(7, 12);
    const number2 = randomIntegerInclusive(6, 24, {
      constraint: x => findFactors(x).length >= 3
    });
    const number3 = getRandomFromArray(
      findFactors(number2).filter(x => x !== 1 && x !== number2)
    ) as number;
    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number(math.evaluate(`${number2} / ${number3}`));
    const number5 = number(math.evaluate(`${number1} * ${number2}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[number4.toString(), number5.toString()]}
        sentence={`${number1} ${MULT} ${number2} = ${number1} ${MULT} ${number3} ${MULT} <ans/> = <ans/>`}
      />
    );
  }
});

const Question5V2 = newQuestionContent({
  uid: 'aJW2',
  description: 'aJW',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(10),
      number2: z.number().int().min(2).max(10),
      number3: z.number().int().min(2).max(10),
      options: z.array(z.object({ string: z.string(), isCorrect: z.boolean() })).length(4)
    })
    .refine(
      val => val.number1 + val.number2 + val.number3 < 20,
      'sum of number1, number2 and number3 should be less than 20'
    ),
  simpleGenerator: () => {
    // sum must be less than 20
    const { number1, number2, number3, correctOptions, incorrectOptions } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 10);
        const number2 = randomIntegerInclusive(2, Math.min(17 - number1, 10));
        const number3 = randomIntegerInclusive(2, Math.min(19 - number1 - number2, 10));

        // other numbers
        const number4 = number1 * number2;
        const number5 = number1 * number3;
        const number6 = number2 * number3;

        const number4a = number4 + (getRandomBoolean() ? number1 : -number1);
        const number4b = number4 + (getRandomBoolean() ? number2 : -number2);
        const number5a = number5 + (getRandomBoolean() ? number1 : -number1);
        const number5b = number5 + (getRandomBoolean() ? number3 : -number3);
        const number6a = number6 + (getRandomBoolean() ? number2 : -number2);
        const number6b = number6 + (getRandomBoolean() ? number3 : -number3);

        const correctAnswersCount = randomIntegerInclusive(1, 3);
        const correctOptions = getRandomSubArrayFromArray(
          [
            [number4, number3],
            [number5, number2],
            [number6, number1]
          ],
          correctAnswersCount
        );

        const incorrectOptions = getRandomSubArrayFromArray(
          [
            [number4a, number3],
            [number4b, number3],
            [number5a, number2],
            [number5b, number2],
            [number6a, number1],
            [number6b, number1]
          ],
          4 - correctAnswersCount
        );
        return { number1, number2, number3, correctOptions, incorrectOptions };
      },
      val => nestedArrayHasNoDuplicates([...val.correctOptions, ...val.incorrectOptions])
    );

    const isReverseOrder = countRange(4).map(getRandomBoolean);

    const options = shuffle([
      ...correctOptions.map((array, i) => ({
        string: `${(isReverseOrder[i]
          ? array[1]
          : array[0]
        ).toLocaleString()} ${MULT} ${(isReverseOrder[i] ? array[0] : array[1]).toLocaleString()}`,
        isCorrect: true
      })),
      ...incorrectOptions.map((array, i) => ({
        string: `${(isReverseOrder[i]
          ? array[1]
          : array[0]
        ).toLocaleString()} ${MULT} ${(isReverseOrder[i] ? array[0] : array[1]).toLocaleString()}`,
        isCorrect: false
      }))
    ]);

    return { number1, number2, number3, options };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, options },
      translate
    } = props;

    const multiSelect = options.filter(val => val.isCorrect).length > 1;

    return (
      <QF11SelectImagesUpTo4
        title={
          multiSelect
            ? translate.instructions.selectCalsEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
              )
            : translate.instructions.selectCalEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
              )
        }
        pdfTitle={
          multiSelect
            ? translate.instructions.circleCalsEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
              )
            : translate.instructions.circleCalEquivalentToX(
                `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
              )
        }
        multiSelect={multiSelect}
        testCorrect={options.filter(val => val.isCorrect).map(val => val.string)}
        numItems={4}
        renderItems={options.map(val => ({
          value: val.string,
          component: <Text variant="WRN700">{val.string}</Text>
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJX',
  description: 'aJX',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z.object({
    number1: z.number().int().min(4).max(11),
    number2: z.number().int().min(4).max(11),
    number3: z.number().int().min(4).max(11),
    character: nameSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(4, 11);
    const number2 = randomIntegerInclusive(4, 11);
    const number3 = randomIntegerInclusive(4, 11);
    const character = getRandomName();

    return { number1, number2, number3, character };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, character },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2} * ${number3}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesFactorPairToWorkOutMultiplication(character)}
        testCorrect={answer =>
          number(math.evaluate(`${answer[0]} * ${answer[1]}`)).toString() === answer[2] &&
          answer[2] === total.toString()
        }
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <ContentBox containerStyle={{ width: dimens.width * 0.7 }}>
            <Text variant="WRN400">{`${number1} ${MULT} ${number2} ${MULT} ${number3}`}</Text>
          </ContentBox>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (number1 * number2).toLocaleString(),
            number3.toLocaleString(),
            total.toLocaleString()
          ],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question6V2 = newQuestionContent({
  uid: 'aJX2',
  description: 'aJX',
  keywords: ['Multiplication', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(9),
      number2: z.number().int().min(3).max(9),
      number3: z.number().int().min(3).max(9),
      character: nameSchema
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must be unique'),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(3, 9, 2);
    const number3 = randomIntegerInclusive(3, 9);
    const character = getRandomName();

    return { number1, number2, number3, character };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, character },
      translate,
      displayMode
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2} * ${number3}`));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterUsesFactorPairToWorkOutMultiplicationWriteTwoMultiplications(
          character
        )}
        testCorrect={answer =>
          // first sentence
          number(math.evaluate(`${Number(answer[0][0])} * ${Number(answer[0][1])}`)) === total &&
          // second sentence
          number(math.evaluate(`${Number(answer[1][0])} * ${Number(answer[1][1])}`)) === total &&
          // not used 1
          answer[0].every(val => val !== '1') &&
          answer[1].every(val => val !== '1') &&
          // not the same contents
          !arraysHaveSameContentsUnordered(answer[0], answer[1])
        }
        sentences={[`<ans/> ${MULT} <ans/>`, `<ans/> ${MULT} <ans/>`]}
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        pdfDirection="column"
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <ContentBox
            containerStyle={{
              width: displayMode === 'digital' ? dimens.width * 0.7 : dimens.width * 0.4
            }}
          >
            <Text variant="WRN400">{`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`}</Text>
          </ContentBox>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(number1 * number3).toLocaleString(), number2.toLocaleString()],
            [(number3 * number2).toLocaleString(), number1.toLocaleString()]
          ],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseFactorPairs',
  questionTypes: [Question1, Question2V2, Question3V2, Question4V2, Question5V2, Question6V2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
