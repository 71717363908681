import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, randomUniqueIntegersInclusive } from '../../../../utils/random';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ADD } from '../../../../constants';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { colors } from '../../../../theme/colors';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const getPlaceValueCounterImages = (
  tenths: number,
  hundredths: number,
  thousandths: number,
  height: number
) => {
  const arrayOfCounters = [
    ...countRange(tenths).map(i => (
      <AssetSvg key={`tenths_${i}`} name="Place_value/0.1" width={60} />
    )),
    ...countRange(hundredths).map(i => (
      <AssetSvg key={`hundredths_${i}`} name="Place_value/0.01" width={60} />
    )),
    ...countRange(thousandths).map(i => (
      <AssetSvg key={`thousands_${i}`} name="Place_value/0.001" width={60} />
    ))
  ];
  return (
    <View
      style={{
        height,
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
      }}
    >
      {arrayOfCounters}
    </View>
  );
};

const Question1 = newQuestionContent({
  uid: 'aQK',
  description: 'aQK',
  keywords: ['Tenths', 'Hundredths', 'Thousandths', 'Place value counters', 'Decimal'],
  schema: z.object({
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9),
    thousandths: z.number().int().min(2).max(9)
  }),

  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(0, 9, { constraint: x => x !== 1 });
    const hundredths = randomIntegerInclusive(tenths === 0 ? 2 : 0, 9, {
      constraint: x => x !== 1
    });
    const thousandths = randomIntegerInclusive(2, 9, {
      // can't fit more than 26 counters in the space
      constraint: x => tenths + hundredths + x < 27
    });

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths }, translate }) => {
    const number = Number(`0.${tenths}${hundredths}${thousandths}`);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        Content={({ dimens }) =>
          getPlaceValueCounterImages(tenths, hundredths, thousandths, dimens.height)
        }
        sentences={[
          translate.answerSentences.thereAreAnsTenthsAnsHundredthsAnsThousandths(),
          translate.answerSentences.theNumberIsAns()
        ]}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={5}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], [
            tenths.toString(),
            hundredths.toString(),
            thousandths.toString()
          ]) && compareFloats(userAnswer[1][0], number)
        }
        pdfDirection={'column'}
        questionHeight={750}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [tenths.toLocaleString(), hundredths.toLocaleString(), thousandths.toLocaleString()],
            [number.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQL',
  description: 'aQL',
  keywords: ['Thousandths', 'Hundredths', 'Tenths', 'Decimal', 'Place value counters'],
  schema: z.object({
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9),
    thousandths: z.number().int().min(1).max(9)
  }),

  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(0, 9);
    const hundredths = randomIntegerInclusive(tenths === 0 ? 1 : 0, 9);
    const thousandths = randomIntegerInclusive(1, 9);

    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: ({ question: { tenths, hundredths, thousandths }, translate }) => {
    const number = Number(`0.${tenths}${hundredths}${thousandths}`);

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToRepresentNum(number.toLocaleString())}
        pdfTitle={translate.instructions.drawCountersToRepresentNum(number.toLocaleString())}
        columnsToShow={[0, -1, -2, -3]}
        number={ScientificNotation.fromNumber(number)}
        counterVariant={'decimalCounter'}
        headerVariant={'numberTitle'}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aQM',
  description: 'aQM',
  keywords: ['Decimal', 'Thousandths', 'Hundredths', 'Tenths', 'Base 10'],
  schema: z.object({
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9),
    thousandths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(0, 9);
    const hundredths = randomIntegerInclusive(tenths === 0 ? 1 : 0, 9);
    const thousandths = randomIntegerInclusive(1, 9);
    return {
      tenths,
      hundredths,
      thousandths
    };
  },
  Component: props => {
    const {
      question: { tenths, hundredths, thousandths },
      translate
    } = props;
    const number = Number(`0.${tenths}${hundredths}${thousandths}`);

    return (
      <MarkupAssets
        elements={{
          thousandsCube: <AssetSvg name={'Base_Ten/1000cube'} width={96} />
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.ifXEquals1WhatDecimalIsRep(`<asset name='thousandsCube'/>`)}
          extraSymbol={'decimalPoint'}
          inputMaxCharacters={5}
          Content={({ dimens }) => (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: { hundreds: tenths, tens: hundredths, ones: thousandths },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          )}
          sentenceStyle={{ justifyContent: 'flex-end' }}
          sentence={`<ans/>`}
          testCorrect={userAnswer => compareFloats(userAnswer[0], number)}
          customMarkSchemeAnswer={{
            answersToDisplay: [number.toLocaleString()],
            answerText: translate.markScheme.acceptEquivalentDecimals()
          }}
        />
      </MarkupAssets>
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQN',
  description: 'aQN',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Tenths', 'Number line'],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(0).max(9),
    thousandths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    return {
      tenths: randomIntegerInclusive(1, 9),
      hundredths: randomIntegerInclusive(0, 9),
      thousandths: randomIntegerInclusive(1, 9)
    };
  },
  Component: props => {
    const {
      question: { tenths, hundredths, thousandths },
      translate
    } = props;
    const start = Number(`0.${tenths}${hundredths}`);
    const end = start + 0.01;

    const number = Number(`0.${tenths}${hundredths}${thousandths}`);
    const numberArray = [
      start.toLocaleString(undefined, { minimumFractionDigits: 2 }),
      ...countRange(9).map(_ => ''),
      end.toLocaleString(undefined, { minimumFractionDigits: 2 })
    ];

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawAnArrowToShowPositionOfNumOnNumberLine(
          number.toLocaleString()
        )}
        min={start}
        max={end}
        sliderStep={0.001}
        tickValues={numberArray}
        testCorrect={[number, number]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aQO',
  description: 'aQO',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Place value counters'],
  schema: z
    .object({
      tenths: z.number().int().min(1).max(9),
      hundredths: z.number().int().min(1).max(9),
      thousandths: z.number().int().min(1).max(9),
      moveTenths: z.number().int().min(0).max(8),
      moveHundredths: z.number().int().min(0).max(8)
    })
    .refine(
      ({ tenths, hundredths, thousandths }) =>
        ![tenths, hundredths, thousandths].every(x => x === 1),
      'No more than two variables should be 2'
    )
    .refine(
      ({ moveTenths, moveHundredths }) => moveTenths + moveHundredths > 0,
      'there should be atleast 1 overlap'
    ),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 9);
    const hundredths = randomIntegerInclusive(tenths === 1 ? 2 : 1, 9);
    const thousandths = randomIntegerInclusive(1, 9);

    const moveTenths = randomIntegerInclusive(hundredths < 2 ? 1 : 0, tenths - 1);
    const moveHundredths = randomIntegerInclusive(moveTenths === 0 ? 1 : 0, hundredths - 1);
    return {
      tenths,
      hundredths,
      thousandths,
      moveTenths,
      moveHundredths
    };
  },
  Component: props => {
    const {
      question: { tenths, hundredths, thousandths, moveTenths, moveHundredths },
      translate
    } = props;

    const number = Number(`0.${tenths}${hundredths}${thousandths}`);

    const groups = [
      [tenths - moveTenths, 0, 0],
      [moveTenths, hundredths - moveHundredths, 0],
      [0, moveHundredths, thousandths]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueCountersToCompletePartition()}
        extraSymbol={'decimalPoint'}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', width: dimens.width, justifyContent: 'space-evenly' }}
          >
            {groups.map(([tths, hths, thths], i) => (
              <View
                style={{
                  borderWidth: 3,
                  borderColor: colors.redBorder,
                  borderRadius: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 8
                }}
                key={i}
              >
                {getPlaceValueCounterImages(tths, hths, thths, 180)}
              </View>
            ))}
          </View>
        )}
        inputMaxCharacters={5}
        sentence={`${number.toLocaleString()} = <ans/> ${ADD} <ans/> ${ADD} <ans/>`}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            userAnswer,
            groups.map(([tths, hths, thths]) => `0.${tths}${hths}${thths}`),
            compareFloats
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: groups.map(([tths, hths, thths]) =>
            Number(`0.${tths}${hths}${thths}`).toLocaleString()
          ),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQP',
  description: 'aQP',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent'],
  schema: z.object({
    tenths1: z.number().int().min(1).max(9),
    hundredths1: z.number().int().min(1).max(9),
    thousandths1: z.number().int().min(1).max(9),
    tenths2: z.number().int().min(1).max(9),
    hundredths2: z.number().int().min(1).max(9),
    thousandths2: z.number().int().min(1).max(9),
    ones: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const [tenths1, tenths2] = randomUniqueIntegersInclusive(1, 9, 2);
    const [hundredths1, hundredths2] = randomUniqueIntegersInclusive(1, 9, 2);
    const [thousandths1, thousandths2] = randomUniqueIntegersInclusive(1, 9, 2);

    const ones = randomIntegerInclusive(1, 100);

    return { tenths1, tenths2, hundredths1, hundredths2, thousandths1, thousandths2, ones };
  },

  Component: ({
    question: { tenths1, tenths2, hundredths1, hundredths2, thousandths1, thousandths2, ones },
    translate
  }) => {
    const tenString = (10).toLocaleString();
    const hundString = (100).toLocaleString();
    const thouString = (1000).toLocaleString();
    const lhs = [
      `<frac n="${tenths1.toLocaleString()}" d="${tenString}"/> ${ADD} <frac n="${hundredths1.toLocaleString()}" d="${hundString}"/> ${ADD} <frac n="${thousandths1.toLocaleString()}" d="${thouString}"/>`,
      `${ones.toLocaleString()} ${ADD} <frac n="${tenths2.toLocaleString()}" d="${tenString}"/> ${ADD} <frac n="${hundredths2.toLocaleString()}" d="${hundString}"/> ${ADD} <frac n="${thousandths2.toLocaleString()}" d="${thouString}"/>`
    ];
    const rhs = [`<ans/>`, `<ans/>`];

    return (
      <QF2AlignedEquations
        extraSymbol={'decimalPoint'}
        title={translate.instructions.workOutTheAdditionsGiveAnsAsDecimal()}
        inputMaxCharacters={7}
        textStyle={{ fontSize: 40 }}
        leftSide={lhs}
        rightSide={rhs}
        testCorrect={({ right }) =>
          compareFloats(right[0][0], Number(`0.${tenths1}${hundredths1}${thousandths1}`)) &&
          compareFloats(right[1][0], Number(`${ones}.${tenths2}${hundredths2}${thousandths2}`))
        }
        customMarkSchemeAnswer={{
          answersToDisplay: {
            right: [
              [Number(`0.${tenths1}${hundredths1}${thousandths1}`).toLocaleString()],
              [Number(`${ones}.${tenths2}${hundredths2}${thousandths2}`).toLocaleString()]
            ]
          }
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ThousandthsAsDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
