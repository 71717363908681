import { type StyleProp, type ViewStyle, View } from 'react-native';
import { type SizingProps } from './UnitGrid';
import { projectSetState, type SetState } from '../../../../utils/react';
import Grid, { GridContext, GridSvgChildren } from './Grid';
import { range } from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';
import { noop } from '../../../../utils/flowControl';
import { useContext, useMemo } from 'react';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { ScaleFactorContext, useMinimumSafeWidth } from '../../../../theme/scaling';
import { useTheme } from '../../../../theme';
import { withStateHOC } from '../../../../stateTree';
import { Rect } from 'react-native-svg';

const HANDLE_WIDTH = 91;
const HANDLE_VISIBLE_WIDTH = 54;
const BORDER_WIDTH = 0.79;
const BAR_CELLS_WIDTH = 2;
const DUAL_BAR_CELLS_WIDTH = 1;
const BAR_CELLS_GAP = 1;

type Props = {
  /**
   * The number represented by each bar. Must be between 0 and yMax, inclusive.
   *
   * Must be the same length as `barLabels`.
   */
  barValues: number[];
  /**
   * The number represented by each bar for dual bar charts. Must be between 0 and yMax, inclusive.
   *
   * Must be the same length as `barLabels`.
   */
  secondaryBarValues?: number[];
  /** Callback for when a bar is changed. This should be set if at least one of the bars is interactive. */
  setBarValues?: SetState<number[]>;

  /**
   * The label for each bar. Must be the same length as `bars`.
   *
   * Also defines how many bars there are.
   */
  barLabels: string[];
  /**
   * The color that each bar should be.
   *
   * Must be the same length as `barLabels` for singular bar chart and length 2 for dual bar charts
   */
  barColors: string[];

  /**
   * Whether the bar chart should be interactive. Default: false.
   * Can optionally provide an array of indices, with each index corresponding to an entry of bar and barLabels.
   */
  interactive?: boolean | number[];

  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';

  /** Distance between two grid lines, in math coordinates. Default: 1. */
  yStepSize?: number;
  /** Value of last point on x axis. */
  yMax: number;
  /** Null for no label. Default: '𝑦'. */
  yAxisArrowLabel?: string | null;
  /** Null for no label. Default: null. */
  xAxisLabel?: string | null;
  /** Null for no label. Default: null. */
  yAxisLabel?: string | null;
  /** Null for no labels. Default: numbers yMin to yMax. */
  yLabels?: string[] | null;
  /** If provided, y-axis labels are given with this fixed number of decimal places. */
  yDecimalPlaces?: number;

  /** Additional style. Note that we default flexShrink to 0, so you need to manually set that to 1 if you want it. */
  style?: StyleProp<ViewStyle>;
  darkGridLinesForYLabels?: boolean;
} & SizingProps;

/**
 * @deprecated
 * Bar graph, with each bar separated by a single cell.
 *
 * This includes the {@link Grid} itself, though props relating to the x axis are fixed an not available for edit.
 */
export default function BarChart({
  barValues,
  secondaryBarValues,
  setBarValues = noop,
  barLabels,
  barColors,
  interactive: interactiveProp = false,
  snapToNearest,
  darkGridLinesForYLabels,
  ...gridProps
}: Props) {
  //TODO: make this work for an interactive dual bar chart
  if (interactiveProp && secondaryBarValues) {
    throw Error(`Interactive dual bar charts are not currently supported`);
  }

  // Set the interactive bars.
  let interactive: number[];
  switch (interactiveProp) {
    case false:
      interactive = [];
      break;
    case true:
      interactive = [...barLabels.keys()];
      break;
    default:
      interactive = interactiveProp;
      break;
  }

  // We position each bar on the x axis as follows:
  // - Each bar is 2 wide
  // - There is a space between each bar, and one extra padding (i.e. one space left of the first bar and after the last)
  const cellsPerBar = BAR_CELLS_WIDTH + BAR_CELLS_GAP;
  const xMax = barLabels.length * cellsPerBar + BAR_CELLS_GAP;
  const barIndexToMidpointInX = (barIndex: number) =>
    barIndex * cellsPerBar + BAR_CELLS_GAP + BAR_CELLS_WIDTH / 2;
  const dualBarIndexToMidpointInX = (barIndex: number) =>
    barIndex * cellsPerBar + BAR_CELLS_GAP + DUAL_BAR_CELLS_WIDTH / 2;
  const midpointInXToBarIndex = (x: number) =>
    (x - BAR_CELLS_WIDTH / 2 - BAR_CELLS_GAP) / cellsPerBar;

  return (
    <Grid
      {...gridProps}
      xMax={xMax}
      xLabels={range(0, xMax).map(x => barLabels[midpointInXToBarIndex(x)] ?? '')}
      xAxisArrowLabel={null}
      darkGridLinesForYLabels={darkGridLinesForYLabels}
    >
      {barValues.map((value, i) => (
        <Bar
          key={i}
          y={value}
          xMidpoint={secondaryBarValues ? dualBarIndexToMidpointInX(i) : barIndexToMidpointInX(i)}
          width={secondaryBarValues ? DUAL_BAR_CELLS_WIDTH : BAR_CELLS_WIDTH}
          color={secondaryBarValues ? barColors[0] : barColors[i]}
          setY={projectSetState(setBarValues, i)}
          interactive={interactive.includes(i)}
          snapToNearest={snapToNearest}
        />
      ))}
      {secondaryBarValues &&
        secondaryBarValues.map((value, i) => (
          <Bar
            key={i}
            y={value}
            xMidpoint={dualBarIndexToMidpointInX(i) + 1}
            width={DUAL_BAR_CELLS_WIDTH}
            color={barColors[1]}
            setY={projectSetState(setBarValues, i)}
            interactive={interactive.includes(i)}
            snapToNearest={snapToNearest}
          />
        ))}
    </Grid>
  );
}

type BarProps = {
  y: number;
  setY: SetState<number>;
  xMidpoint: number;
  width: number;
  color: string;
  interactive: boolean;
  snapToNearest?: number | 'grid';
};

const AnimatedRect = Animated.createAnimatedComponent(Rect);

/** Interactive bar with draggable handle at the top. */
function Bar({
  y,
  setY,
  xMidpoint,
  width,
  color,
  interactive,
  snapToNearest: snapToNearestProp
}: BarProps) {
  const scaleFactor = useContext(ScaleFactorContext);
  const theme = useTheme();
  const { mathToSvgX, mathToSvgY, svgToMathY, yMin, yMax, yStepSize } = useContext(GridContext);
  const snapToNearest = snapToNearestProp === 'grid' ? yStepSize : snapToNearestProp;

  const animatedY = useSharedValue(y);

  const midpoint = mathToSvgX(xMidpoint);
  const left = mathToSvgX(xMidpoint - width / 2);
  const right = mathToSvgX(xMidpoint + width / 2);
  const bottom = mathToSvgY(0);
  const derivedTop = useDerivedValue(() => mathToSvgY(animatedY.value), [animatedY, mathToSvgY]);

  ////
  // Gesture
  ////

  const beginPageYPosition = useSharedValue<number | null>(null);
  const beginSvgYCoord = useSharedValue<number | null>(null);

  const gesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPageYPosition.value = event.absoluteY;
          beginSvgYCoord.value = derivedTop.value;
        })
        .onUpdate(event => {
          const translationY = (event.absoluteY - beginPageYPosition.value!) / scaleFactor;
          const currentSvgYCoord = beginSvgYCoord.value! + translationY;

          // Clamp to stay in the grid
          animatedY.value = Math.min(Math.max(svgToMathY(currentSvgYCoord), 0), yMax);
        })
        .onFinalize(() => {
          // Snap to the grid
          let newY = animatedY.value;
          if (snapToNearest !== undefined) {
            newY = Math.round((animatedY.value - yMin) / snapToNearest) * snapToNearest + yMin;
            animatedY.value = withTiming(newY);
          }

          // Update outside of the animation
          runOnJS(setY)(newY);
        }),
    [
      animatedY,
      beginPageYPosition,
      beginSvgYCoord,
      derivedTop,
      scaleFactor,
      setY,
      snapToNearest,
      svgToMathY,
      yMax,
      yMin
    ]
  );

  ////
  // JSX
  ////

  const borderWidth = useMinimumSafeWidth(BORDER_WIDTH);
  const animatedStyle = useAnimatedStyle(() => ({ top: derivedTop.value }), [derivedTop]);
  const animatedRectProps = useAnimatedProps(
    () => ({ height: bottom - derivedTop.value, y: derivedTop.value }),
    [bottom, derivedTop]
  );

  return (
    <>
      {/* Orange handle, with larger touchable region */}
      {interactive && (
        <GestureDetector gesture={gesture}>
          <Animated.View
            pointerEvents="box-only"
            style={[
              {
                position: 'absolute',
                left: midpoint,
                marginLeft: -HANDLE_WIDTH / 2,
                marginTop: -HANDLE_WIDTH / 2,
                width: HANDLE_WIDTH,
                height: HANDLE_WIDTH,
                alignItems: 'center',
                justifyContent: 'center'
              },
              animatedStyle
            ]}
          >
            <View
              style={{
                marginTop: -HANDLE_VISIBLE_WIDTH / 2 + 1 * borderWidth,
                width: HANDLE_VISIBLE_WIDTH,
                height: HANDLE_VISIBLE_WIDTH / 2,
                backgroundColor: theme.colors.tertiary,
                borderColor: theme.colors.primary,
                borderTopLeftRadius: 999,
                borderTopRightRadius: 999,
                borderWidth: borderWidth
              }}
            />
          </Animated.View>
        </GestureDetector>
      )}

      {/* The bar itself */}
      <GridSvgChildren>
        <AnimatedRect
          pointerEvents="none"
          width={right - left}
          x={left}
          animatedProps={animatedRectProps}
          fill={color}
          stroke={colors.prussianBlue}
          strokeWidth={borderWidth}
        />
      </GridSvgChildren>
    </>
  );
}

/** StateTree version of {@link BarChart} */
export const BarChartWithState = withStateHOC(BarChart, {
  stateProp: 'barValues',
  setStateProp: 'setBarValues',
  defaults: props => ({
    defaultState: props.barLabels.map(() => 0),
    testComplete: state => state.some(it => it !== 0)
  })
});
