import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from 'common/src/utils/random';
import { SUB } from 'common/src/constants';
import { compareFractions, simplify } from 'common/src/utils/fractions';
import { numberEnum } from 'common/src/utils/zod';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { BarModelInteractiveWithCrossesWithState } from '../../../../components/question/representations/BarModelInteractiveWithCrosses';
import { filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import { trueCount } from '../../../../utils/shapes';
import TextStructure from '../../../../components/molecules/TextStructure';
import { greatestCommonDivisor } from '../../../../utils/multiples';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asg',
  description: 'asg',
  keywords: ['Mixed number', 'Subtract', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: numberEnum([3, 5, 7]),
    number3: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = getRandomFromArray([3, 5, 7] as const);

    const number3 = randomIntegerInclusive(2, number2 - 1);

    return {
      number1,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate,
      displayMode
    } = props;

    const [simplifiedNumerator, simplifiedDenominator] = simplify(number3, number2);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        inputMaxCharacters={2}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={answer =>
          answer[0] === (number1 - 1).toString() &&
          compareFractions([answer[1], answer[2]], [simplifiedNumerator, simplifiedDenominator])
        }
        sentence={`<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${SUB} 1 = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (number1 - 1).toLocaleString(),
            simplifiedNumerator.toLocaleString(),
            simplifiedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ash',
  description: 'ash',
  keywords: ['Mixed number', 'Subtract', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: numberEnum([3, 5, 7]),
    number3: z.number().int().min(2).max(6),
    number4: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = getRandomFromArray([3, 5, 7] as const);

    const number3 = randomIntegerInclusive(2, number2 - 1);

    const number4 = randomIntegerInclusive(1, number1 - 1);

    return {
      number1,
      number2,
      number3,
      number4
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheMissingNumber()}
        inputMaxCharacters={2}
        testCorrect={[(number1 - number4).toString()]}
        sentence={`<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${SUB} <ans /> = <frac w='${number4.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/>`}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asi',
  description: 'asi',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z.object({
    fracBDenominator: z.number().int().min(2).max(6),
    fracBNumerator: z.number().int().min(1),
    number3: z.number().int().min(2).max(6),
    denominator: z.number().int().min(4).max(12),
    fracANumerator: z.number().int().min(2).max(11)
  }),
  simpleGenerator: () => {
    const fracBDenominator = randomIntegerInclusive(2, 6);
    const fracBNumerator = randomIntegerInclusive(1, fracBDenominator / 2, {
      constraint: x => {
        const [simplifiedfracBNumerator] = simplify(x, fracBDenominator);

        // Fraction displayed must be in its simplest form.
        return x === simplifiedfracBNumerator;
      }
    });
    const number3 = randomIntegerInclusive(2, 6, {
      constraint: x => x * fracBDenominator <= 12
    });
    const denominator = fracBDenominator * number3;
    const fracANumerator = randomIntegerInclusive(denominator / 2 + 1, denominator - 1, {
      constraint: x => {
        const [simplifiedfracANumerator] = simplify(x, denominator);

        // Fraction displayed must be in its simplest form.
        return x === simplifiedfracANumerator;
      }
    });

    return { fracBDenominator, fracBNumerator, number3, denominator, fracANumerator };
  },
  Component: props => {
    const {
      question: { fracANumerator, fracBNumerator, fracBDenominator, number3, denominator },
      displayMode,
      translate
    } = props;

    const number6 = fracANumerator - fracBNumerator * number3;

    const bar1 = filledArray(true, denominator);
    const bar2 = [
      ...filledArray(true, fracANumerator),
      ...filledArray(false, denominator - fracANumerator)
    ];
    const crossedCells1 = filledArray(false, denominator);
    const crossedCells2 = filledArray(false, denominator);

    const shadedCells = [bar1, bar2];
    const crossedCells = [crossedCells1, crossedCells2];

    const answer = (denominator / fracBDenominator) * fracBNumerator;

    const sentence = `<frac w='1' n='${fracANumerator}' d='${denominator}'/> ${SUB} <frac n='${fracBNumerator}' d='${fracBDenominator}'/> = <frac w='1' n='${number6}' d='${denominator}'/>`;

    const correctAnswer = [
      [...filledArray(true, answer), ...filledArray(false, denominator - answer)],
      [...filledArray(false, denominator)]
    ];

    return (
      <QF3Content
        title={translate.instructions.useBarModelToRepresentCalculation()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <TextStructure sentence={sentence} textStyle={{ fontSize: 40 }} />
            <BarModelInteractiveWithCrossesWithState
              id={`barmodel`}
              defaultState={displayMode === 'markscheme' ? correctAnswer : undefined}
              shadedCells={shadedCells}
              numberOfRows={2}
              numberOfCols={denominator}
              tableHeight={displayMode === 'digital' ? 100 : 150}
              tableWidth={dimens.width}
              interactive
              crossedCells={crossedCells}
              testCorrect={userAnswer => trueCount(userAnswer) === answer}
              separatedRows
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptBarModelWithAnyXShadedCellsCrossedOut(answer)
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asj',
  description: 'asj',
  keywords: ['Difference', 'Mixed fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: numberEnum([3, 5, 7]),
    number3: z.number().int().min(2).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = getRandomFromArray([3, 5, 7] as const);

    const number3 = randomIntegerInclusive(2, number2 - 1);

    return {
      number1,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate,
      displayMode
    } = props;

    const [simplifiedNumerator, simplifiedDenominator] = simplify(number3 - 1, number2);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        inputMaxCharacters={2}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={answer =>
          answer[0] === number1.toString() &&
          compareFractions([answer[1], answer[2]], [simplifiedNumerator, simplifiedDenominator])
        }
        sentence={`<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${SUB} <frac n='1' d='${number2.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number1.toLocaleString(),
            simplifiedNumerator.toLocaleString(),
            simplifiedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ask',
  description: 'ask',
  keywords: ['Mixed number', 'Subtract', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: numberEnum([3, 5, 7]),
    number3: z.number().int().min(2).max(6),
    number4: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = getRandomFromArray([3, 5, 7] as const);

    const number3 = randomIntegerInclusive(2, number2 - 1);

    const number4 = randomIntegerInclusive(1, number3 - 1);

    return {
      number1,
      number2,
      number3,
      number4
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheMissingNumber()}
        inputMaxCharacters={2}
        testCorrect={[(number3 - number4).toString(), number2.toString()]}
        sentence={`<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${SUB} <frac nAns='' dAns=''/> = <frac w='${number1.toLocaleString()}' n='${number4.toLocaleString()}' d='${number2.toLocaleString()}'/>`}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asl',
  description: 'asl',
  keywords: ['Mixed number', 'Subtract', 'Fractions'],
  schema: z
    .object({
      whole: z.number().int().min(1).max(12),
      denominatorA: z.number().int().min(2).max(5),
      numeratorA: z.number().int().min(1).max(4),
      numeratorB: z.number().int().min(1).max(24),
      multiple: z.number().int().min(2).max(5),
      variation: z.boolean()
    })
    .refine(
      val => greatestCommonDivisor([val.numeratorA, val.denominatorA]) === 1,
      'numeratorA/denominatorA must not simplify'
    ),
  simpleGenerator: () => {
    const multiple = randomIntegerInclusive(2, 5);
    const whole = randomIntegerInclusive(1, 12);
    const denominatorA = randomIntegerInclusive(2, 5);
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1, {
      constraint: x => greatestCommonDivisor([x, denominatorA]) === 1
    });
    const variation = getRandomBoolean();
    const numeratorB = variation
      ? randomIntegerInclusive(1, numeratorA * multiple - 1)
      : randomIntegerInclusive(numeratorA * multiple - 1, denominatorA * multiple - 1, {
          constraint: x => x > numeratorA * multiple
        });

    return { multiple, whole, denominatorA, numeratorA, numeratorB, variation };
  },
  Component: props => {
    const {
      question: { multiple, whole, denominatorA, numeratorA, numeratorB, variation },
      translate
    } = props;

    const denominatorB = denominatorA * multiple;
    const numeratorC = variation
      ? numeratorA * multiple - numeratorB
      : numeratorB - numeratorA * multiple;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          answer[0] === whole.toString() &&
          compareFractions([answer[1], answer[2]], [numeratorC, denominatorB])
        }
        sentence={
          variation
            ? `<frac w='${whole.toLocaleString()}' n='${numeratorA}' d='${denominatorA}'/> ${SUB} <frac n='${numeratorB}' d='${denominatorB}'/> = <frac wAns='' nAns='' dAns=''/>`
            : `<frac w='${whole.toLocaleString()}' n='${numeratorB}' d='${denominatorB}'/> ${SUB} <frac n='${numeratorA}' d='${denominatorA}'/> = <frac wAns='' nAns='' dAns=''/>`
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            numeratorC.toString(),
            denominatorB.toString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFromAMixedNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
