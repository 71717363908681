import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray, arrayHasNoDuplicates } from 'common/src/utils/collections';
import {
  randomUniqueIntegersInclusive,
  getRandomFromArray,
  seededRandom,
  shuffle,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import { ADD, SUB } from 'common/src/constants';
import { greatestCommonDivisor } from 'common/src/utils/multiples';
import { findFactors } from 'common/src/utils/factors';
import { Fraction, fractionToDecimal } from 'common/src/utils/fractions';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import { roundToSignificantFigures } from 'common/src/utils/math';
import QF34InteractiveBarModelWithSentenceDrag from 'common/src/components/question/questionFormats/QF34InteractiveBarModelWithSentenceDrag';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aps',
  description: 'aps',
  keywords: ['Place value', 'Represent', '10,000'],
  schema: z
    .object({
      denominator: z.number().int().min(6).max(12),
      numerators: z.number().int().min(1).max(11).array().length(5),
      orderBy: z.enum(['smallest', 'greatest'])
    })
    .refine(val => arrayHasNoDuplicates(val.numerators), 'there are no duplicate numerator values'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(6, 12);
    const orderBy = getRandomFromArray(['smallest', 'greatest'] as const);

    const numerators = randomUniqueIntegersInclusive(1, denominator - 1, 5);

    return { denominator, numerators, orderBy };
  },
  Component: props => {
    const {
      question: { denominator, numerators, orderBy },
      translate,
      displayMode
    } = props;

    const sortedNumerators = [...numerators].sort((a, b) => a - b);

    const [numA, numB, numC, numD, numE] = sortedNumerators;

    const items = shuffle(
      [
        {
          numerator: numA,
          denominator,
          value: 1
        },
        {
          numerator: numB,
          denominator,
          value: 2
        },
        {
          numerator: numC,
          denominator,
          value: 3
        },
        {
          numerator: numD,
          denominator,
          value: 4
        },
        {
          numerator: numE,
          denominator,
          value: 5
        }
      ],
      { random: seededRandom(props.question) }
    );

    const correctOrder = orderBy === 'smallest' ? [1, 2, 3, 4, 5] : [5, 4, 3, 2, 1];

    return (
      <QF5DragOrderHorizontal
        title={
          orderBy === 'smallest'
            ? translate.instructions.orderFractionsStartingWithSmallest()
            : translate.instructions.orderFractionsStartingWithGreatest()
        }
        pdfTitle={
          orderBy === 'smallest'
            ? translate.instructions.useCardsToOrderFractionsStartingWithSmallest()
            : translate.instructions.useCardsToOrderFractionsStartingWithGreatest()
        }
        testCorrect={correctOrder}
        items={items.map(({ numerator, denominator, value }) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${numerator}' d='${denominator}' />`}
                fractionDividerStyle={{ marginVertical: 2 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value
          };
        })}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'apt',
  description: 'apt',
  keywords: ['Ordering', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(20),
    number2: z.number().int().min(2).max(20),
    number3: z.number().int().min(2).max(20),
    number4: z.number().int().min(2).max(20),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const [number1, number2, number3, number4] = randomUniqueIntegersInclusive(2, 20, 4);

    return { number1, number2, number3, number4, ascendingOrDescending };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, ascendingOrDescending },
      translate,
      displayMode
    } = props;

    // Draggables
    const eqs = [
      { string: `<frac n='1' d='${number1.toLocaleString()}'/>`, value: 1 / number1 },
      { string: `<frac n='1' d='${number2.toLocaleString()}'/>`, value: 1 / number2 },
      { string: `<frac n='1' d='${number3.toLocaleString()}'/>`, value: 1 / number3 },
      { string: `<frac n='1' d='${number4.toLocaleString()}'/>`, value: 1 / number4 }
    ];

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderFractionsStartingWithSmallest()
            : translate.instructions.orderFractionsStartingWithGreatest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderFractionsStartingWithSmallest()
            : translate.instructions.useCardsToOrderFractionsStartingWithGreatest()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apu',
  description: 'apu',
  keywords: ['Ordering', 'Fractions'],
  schema: z.object({
    number1: numberEnum([10, 20, 25, 40, 50]),
    number2: numberEnum([100, 200, 250, 400, 500]),
    number3: numberEnum([1000, 2000, 2500, 4000, 5000]),
    number4: z.number().int().min(9).max(5001),
    number5: numberEnum([1, 3, 7, 9]),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1 = getRandomFromArray([10, 20, 25, 40, 50] as const);
    const number2 = getRandomFromArray([100, 200, 250, 400, 500] as const);
    const number3 = getRandomFromArray([1000, 2000, 2500, 4000, 5000] as const);

    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);
    const random = getRandomFromArray([number1, number2, number3] as const);
    const addOrSub = getRandomFromArray([ADD, SUB]);

    // Number4 = number1 +/- 1, number2 +/- 1, number3 +/- 1
    const number4 = addOrSub === ADD ? random + 1 : random - 1;

    const number5 = getRandomFromArray([1, 3, 7, 9] as const);

    return { number1, number2, number3, number4, number5, ascendingOrDescending };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, ascendingOrDescending },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const eqA = {
      string: `<frac n='${number5}' d='${number1}'/>`,
      value: number5 / number1
    };
    const eqB = {
      string: `<frac n='${number5}' d='${number2}'/>`,
      value: number5 / number2
    };
    const eqC = {
      string: `<frac n='${number5}' d='${number3}'/>`,
      value: number5 / number3
    };
    const eqD = {
      string: `<frac n='${number5}' d='${number4}'/>`,
      value: number5 / number4
    };
    const eqs = shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderFractionsStartingWithSmallest()
            : translate.instructions.orderFractionsStartingWithGreatest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderFractionsStartingWithSmallest()
            : translate.instructions.useCardsToOrderFractionsStartingWithGreatest()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'apv',
  description: 'apv',
  keywords: ['Ordering', 'Fractions'],
  schema: z.object({
    numerator: z.number().int().min(1).max(4),
    barModel1Denominator: z.number().int().min(2).max(10),
    barModel2Denominator: z.number().int().min(2).max(10),
    barModel3Denominator: z.number().int().min(2).max(10),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(1, 4);
    const [barModel1Denominator, barModel2Denominator, barModel3Denominator] =
      randomUniqueIntegersInclusive(numerator + 1, 10, 3);

    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      numerator,
      barModel1Denominator,
      barModel2Denominator,
      barModel3Denominator,
      ascendingOrDescending
    };
  },
  Component: props => {
    const {
      question: {
        numerator,
        barModel1Denominator,
        barModel2Denominator,
        barModel3Denominator,
        ascendingOrDescending
      },
      translate,
      displayMode
    } = props;

    const bars = [
      {
        rows: 1,
        cols: barModel1Denominator
      },
      {
        rows: 1,
        cols: barModel2Denominator
      },
      {
        rows: 1,
        cols: barModel3Denominator
      }
    ];

    const fractions = [
      {
        component: (
          <TextStructure
            sentence={`<frac n='${numerator.toLocaleString()}' d='${barModel1Denominator.toLocaleString()}'/>`}
            fractionTextStyle={[
              { fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }
            ]}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: fractionToDecimal(numerator, barModel1Denominator)
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='${numerator.toLocaleString()}' d='${barModel2Denominator.toLocaleString()}'/>`}
            fractionTextStyle={[
              { fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }
            ]}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: fractionToDecimal(numerator, barModel2Denominator)
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='${numerator.toLocaleString()}' d='${barModel3Denominator.toLocaleString()}'/>`}
            fractionTextStyle={[
              { fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }
            ]}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: fractionToDecimal(numerator, barModel3Denominator)
      }
    ];

    const fractionsSorted = sortNumberArray(
      fractions.map(x => x.value),
      ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
    );

    return (
      <QF34InteractiveBarModelWithSentenceDrag
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.dragCardsToOrderFractionsStartingWithSmallestCanShadeBarModel()
            : translate.instructions.dragCardsToOrderFractionsStartingWithGreatestCanShadeBarModel()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderFractionsStartingWithSmallestCanShadeBarModel()
            : translate.instructions.useCardsToOrderFractionsStartingWithGreatestCanShadeBarModel()
        }
        bars={bars}
        sentence={`<ans />, <ans />, <ans />`}
        preBarText={[
          `<frac n='${numerator.toLocaleString()}' d='${barModel1Denominator.toLocaleString()}'/>`,
          `<frac n='${numerator.toLocaleString()}' d='${barModel2Denominator.toLocaleString()}'/>`,
          `<frac n='${numerator.toLocaleString()}' d='${barModel3Denominator.toLocaleString()}'/>`
        ]}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
        items={fractions}
        actionPanelVariant="end"
        testCorrectSentence={fractionsSorted}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'apw',
  description: 'apw',
  keywords: ['Fractions', 'Ordering'],
  schema: z
    .object({
      ascendingOrDescending: z.enum(['ascending', 'descending']),
      number1: numberEnum([8, 10, 12, 15, 20]),
      number2: z.number().int().min(1).max(19),
      number3: z.number().int().min(1).max(19),
      number4: z.number().int().min(1).max(20),
      number5: z.number().int().min(1).max(19),
      number6: z.number().int().min(1).max(20),
      number7: z.number().int().min(1).max(19)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          fractionToDecimal(val.number2, val.number1),
          fractionToDecimal(val.number3, val.number1),
          fractionToDecimal(val.number5, val.number4),
          fractionToDecimal(val.number7, val.number6)
        ]),
      'number2/number1, number3/number1, number5/number4 and number7/number6 must all be different - no equivalent fractions allowed.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const { number1, number2, number3, number4, number5, number6, number7 } = rejectionSample(
      () => {
        const number1 = getRandomFromArray([8, 10, 12, 15, 20] as const);
        const [number2, number3] = randomUniqueIntegersInclusive(1, number1, 2, {
          constraint: x => greatestCommonDivisor([x, number1]) === 1
        });

        const factorsOfNum1 = findFactors(number1);
        // Ignore "1" by using .slice()
        const [number4, number6] = getRandomSubArrayFromArray(factorsOfNum1.slice(1), 2);

        const number5 = randomIntegerInclusive(1, number4, {
          constraint: x => greatestCommonDivisor([x, number4]) === 1
        });
        const number7 = randomIntegerInclusive(1, number6, {
          constraint: x => greatestCommonDivisor([x, number6]) === 1
        });
        return { number1, number2, number3, number4, number5, number6, number7 };
      },

      // Fractions made must all be different:
      ({ number1, number2, number3, number4, number5, number6, number7 }) =>
        arrayHasNoDuplicates([
          fractionToDecimal(number2, number1),
          fractionToDecimal(number3, number1),
          fractionToDecimal(number5, number4),
          fractionToDecimal(number7, number6)
        ])
    );

    return { ascendingOrDescending, number1, number2, number3, number4, number5, number6, number7 };
  },
  Component: props => {
    const {
      question: {
        ascendingOrDescending,
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7
      },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const fractions = [
      {
        text: `<frac n='${number2}' d='${number1}'/>`,
        value: Number(fractionToDecimal(number2, number1))
      },
      {
        text: `<frac n='${number3}' d='${number1}'/>`,
        value: Number(fractionToDecimal(number3, number1))
      },
      {
        text: `<frac n='${number5}' d='${number4}'/>`,
        value: Number(fractionToDecimal(number5, number4))
      },
      {
        text: `<frac n='${number7}' d='${number6}'/>`,
        value: Number(fractionToDecimal(number7, number6))
      }
    ];
    const shuffledFractions = shuffle(fractions, { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.dragCardsToOrderFractionsStartingWithSmallest()
            : translate.instructions.dragCardsToOrderFractionsStartingWithGreatest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderFractionsStartingWithSmallest()
            : translate.instructions.useCardsToOrderFractionsStartingWithGreatest()
        }
        testCorrect={sortNumberArray(
          shuffledFractions.map(x => x.value),
          ascendingOrDescending
        )}
        draggableVariant="rectangle"
        items={shuffledFractions.map(({ text, value }) => ({
          component: (
            <TextStructure
              sentence={text}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          ),
          value
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'apx',
  description: 'apx',
  keywords: ['Ordering', 'Fractions'],
  schema: z.object({
    fractionWithGaps: z
      .union([
        fractionSchema(z.literal(null), z.number().int().min(1)),
        fractionSchema(z.number().int(), z.literal(null))
      ])
      .array()
      .length(4),
    shuffledAnswerOptions: z.number().int().min(1).array().length(4),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const number1 = randomIntegerInclusiveStep(10, 100, 10);
    const number2 = randomIntegerInclusive(Math.ceil(number1 * 0.75), number1 - 1);

    const number3 = randomIntegerInclusive(4, 100, {
      constraint: x => x === roundToSignificantFigures(x, 1)
    });
    const number4 = randomIntegerInclusive(Math.ceil(number3 * 0.5), Math.ceil(number3 * 0.75 - 1));

    const number5 = randomIntegerInclusive(4, 100, {
      constraint: x => x === roundToSignificantFigures(x, 1)
    });
    const number6 = randomIntegerInclusive(Math.ceil(number5 * 0.25), Math.ceil(number5 * 0.5 - 1));

    const number7 = randomIntegerInclusive(5, 100, {
      constraint: x => x === roundToSignificantFigures(x, 1)
    });
    const number8 = randomIntegerInclusive(1, Math.ceil(number7 * 0.25 - 1));

    const allFractions: Fraction[] = [
      [number2, number1],
      [number4, number3],
      [number6, number5],
      [number8, number7]
    ];

    // Fraction-specialised sorting
    const compareFractions = (a: Fraction, b: Fraction) => {
      const frac1 = Number(fractionToDecimal(...a));
      const frac2 = Number(fractionToDecimal(...b));

      return ascendingOrDescending === 'ascending' ? frac1 - frac2 : frac2 - frac1;
    };
    const sortedFractions = allFractions.sort(compareFractions);

    // New way
    const answerOptions: number[] = [];
    const fractionWithGaps: [number | null, number | null][] = sortedFractions.map(frac => {
      const chosenIdx = randomIntegerInclusive(0, 1);

      if (chosenIdx === 0) {
        answerOptions.push(frac[1]);
        return [frac[0], null];
      } else {
        answerOptions.push(frac[0]);
        return [null, frac[1]];
      }
    });
    const shuffledAnswerOptions = shuffle(answerOptions);

    return { ascendingOrDescending, fractionWithGaps, shuffledAnswerOptions };
  },
  Component: props => {
    const {
      question: { ascendingOrDescending, fractionWithGaps, shuffledAnswerOptions },
      translate
    } = props;

    const statements = fractionWithGaps.reduce(
      (acc: string, [numerator, denominator]: readonly (number | null)[], index): string => {
        const numeratorString = numerator === null ? "nAns=''" : `n='${numerator}'`;
        const denominatorString = denominator === null ? "dAns=''" : `d='${denominator}'`;
        const string =
          index < fractionWithGaps.length - 1
            ? `   <frac ${numeratorString} ${denominatorString}/>   ,`
            : `   <frac ${numeratorString} ${denominatorString}/>   `;

        return acc + string;
      },
      ''
    );

    const testCorrect = (userAnswer: readonly (number | undefined)[]): boolean => {
      const userAnswerFractions = fractionWithGaps.map((frac, index) => {
        return frac.map(value => {
          if (value === null) {
            return userAnswer[index];
          } else {
            return value;
          }
        });
      }) as [number | undefined, number | undefined][];

      if (userAnswerFractions.some(frac => frac.some(value => value === undefined))) {
        return false;
      }

      const userAnswerDecimals = userAnswerFractions.map(frac =>
        Number(fractionToDecimal(...(frac as Fraction)))
      );

      return ascendingOrDescending === 'ascending'
        ? userAnswerDecimals.every(
            (value, index, array) => index === 0 || value >= array[index - 1]
          )
        : userAnswerDecimals.every(
            (value, index, array) => index === 0 || value <= array[index - 1]
          );
    };

    return (
      <QF37SentenceDrag
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.fracsInAscendingOrderDragNumbersToCompleteFractions()
            : translate.instructions.fracsInDescendingOrderDragNumbersToCompleteFractions()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.fracsInAscendingOrderDragNumbersToCompleteFractionsUseEachNumberOnce()
            : translate.instructions.fracsInDescendingOrderDragNumbersToCompleteFractionsUseEachNumberOnce()
        }
        items={shuffledAnswerOptions}
        sentence={statements}
        testCorrect={testCorrect}
        fractionContainerStyle={{ height: 96 }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText:
            ascendingOrDescending === 'ascending'
              ? translate.markScheme.answersMustMakeFractionsInAscendingOrder()
              : translate.markScheme.answersMustMakeFractionsInDescendingOrder()
        }}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderFractionsLessThan1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
