import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive, seededRandom } from '../../../../utils/random';
import QF43DraggableLineOnTopOfStaticRuler from '../../../../components/question/questionFormats/QF43DraggableLineOnTopOfStaticRuler';
import { LineGraphColors } from '../../../../theme/colors';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import QF42bDraggableShapeOnRuler from '../../../../components/question/questionFormats/QF42bDraggableShapeOnRuler';
import { ADD } from '../../../../constants';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'au4',
  description: 'au4',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(14)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 14)
  }),
  Component: ({ question, translate }) => {
    const { length } = question;
    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howLongIsTheLine()}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[length.toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={length >= 9 ? 15 : 10}
            items={[{ length, lineColor: color }]}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'au5',
  description: 'au5',
  keywords: ['Measure', 'Length', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(2).max(15)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(2, 15)
  }),
  Component: ({ question: { length }, translate }) => {
    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragLineSoItIsLengthLong({
          length: length.toLocaleString(),
          unit: translate.units.cm()
        })}
        pdfTitle={translate.instructions.dragLineSoItIsLengthLongPdf({
          length: length.toLocaleString(),
          unit: translate.units.cm()
        })}
        rulerKind="cm"
        rulerLength={15}
        testCorrect={length}
        snapToNearest={0.1}
        wiggleRoom={0.1}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'au6',
  description: 'au6',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(149)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 149)
  }),
  Component: ({ question, translate }) => {
    const { length } = question;
    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    const lengthInCm = length / 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howLongIsTheLine()}
        sentence={translate.answerSentences.ansMm()}
        testCorrect={([userAnswer]) => {
          if (length % 5 === 0) {
            return userAnswer === length.toString();
          } else {
            return [length.toString(), (length + 1).toString(), (length - 1).toString()].includes(
              userAnswer
            );
          }
        }}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={lengthInCm >= 9 ? 15 : 10}
            items={[{ length: lengthInCm, lineColor: color }]}
          />
        )}
        inputMaxCharacters={3}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'au7',
  description: 'au7',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z.object({
    length: z.number().int().min(1).max(149)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(1, 149)
  }),
  Component: ({ question: { length }, translate }) => {
    const lengthInCm = length / 10;

    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragLineSoItIsLengthLong({
          length: length.toLocaleString(),
          unit: translate.units.mm()
        })}
        pdfTitle={translate.instructions.dragLineSoItIsLengthLongPdf({
          length: length.toLocaleString(),
          unit: translate.units.mm()
        })}
        rulerKind="cm"
        rulerLength={15}
        testCorrect={lengthInCm}
        snapToNearest={0.1}
        wiggleRoom={length % 5 === 0 ? 0 : 0.1}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'au8',
  description: 'au8',
  keywords: ['Measure', 'Length', 'Width', 'Centimetres'],
  schema: z
    .object({
      shapeName: z.enum([
        'triangles',
        'squares',
        'rectangles',
        'equilateralTriangles',
        'isoscelesTriangles'
      ]),
      lengthOfCm: z.number().int().min(3).max(6),
      offsetX: z.number().int().min(0).max(12),
      offsetY: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.lengthOfCm + val.offsetX <= 15,
      `offsetX ${ADD} lengthOfCm must be less than or equal to 15`
    ),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray([
      'triangles',
      'squares',
      'rectangles',
      'equilateralTriangles',
      'isoscelesTriangles'
    ] as const);
    const lengthOfCm = randomIntegerInclusive(3, 6);
    const offsetX = randomIntegerInclusive(0, 15 - lengthOfCm);
    const offsetY = randomIntegerInclusive(1, 3);

    return {
      shapeName,
      lengthOfCm,
      offsetX,
      offsetY
    };
  },
  Component: props => {
    const {
      question: { shapeName, offsetX, offsetY, lengthOfCm },
      translate
    } = props;

    const random = props.question;
    const regularShape = getShapeSvgName(shapeName, random);

    return (
      <QF42bDraggableShapeOnRuler
        title={translate.instructions.whatIsTheLengthOfTheXShapeYouCanDragXShapeToHelpYou(
          translate.shapes[shapeName](1)
        )}
        pdfTitle={translate.instructions.whatIsTheLengthOfTheXShape(translate.shapes[shapeName](1))}
        rulerKind="cm"
        rulerLength={15}
        sentence={translate.answerSentences.theXShapeIsAnsCmLong(translate.shapes[shapeName](1))}
        length={lengthOfCm}
        offsetX={offsetX}
        offsetY={-offsetY}
        testCorrect={[lengthOfCm.toString()]}
        markSchemeAnswer={[lengthOfCm.toLocaleString()]}
        shapeIconName={regularShape}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'au9',
  description: 'au9',
  keywords: ['Measure', 'Length', 'Width', 'Centimetres', 'Millimetres'],
  schema: z
    .object({
      shapeName: z.enum(['triangles']),
      lengthOfCm: z.number().int().min(3).max(6),
      offsetX: z.number().int().min(0).max(12),
      offsetY: z.number().int().min(1).max(3),
      unit: z.enum(['mm', 'cm'])
    })
    .refine(
      val => val.lengthOfCm + val.offsetX <= 15,
      `offsetX ${ADD} lengthOfCm must be less than or equal to 15`
    ),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray(['triangles'] as const);
    const lengthOfCm = randomIntegerInclusive(3, 6);
    const offsetX = randomIntegerInclusive(0, 15 - lengthOfCm);
    const offsetY = randomIntegerInclusive(1, 3);
    const unit = getRandomFromArray(['mm', 'cm'] as const);

    return {
      shapeName,
      lengthOfCm,
      offsetX,
      offsetY,
      unit
    };
  },
  Component: props => {
    const {
      question: { shapeName, offsetX, offsetY, lengthOfCm, unit },
      translate
    } = props;

    const random = props.question;
    const regularShape = getShapeSvgName(shapeName, random);
    const answer = unit === 'mm' ? lengthOfCm * 10 : lengthOfCm;

    return (
      <QF42bDraggableShapeOnRuler
        title={translate.instructions.whatIsTheLengthOfTheXShapeYouCanDragXShapeToHelpYou(
          translate.shapes[shapeName](1)
        )}
        pdfTitle={translate.instructions.whatIsTheLengthOfTheXShape(translate.shapes[shapeName](1))}
        rulerKind={'cm'}
        rulerLength={15}
        sentence={
          unit === 'mm' ? translate.answerSentences.ansMm() : translate.answerSentences.ansCm()
        }
        sentenceStyle={{ justifyContent: 'flex-end' }}
        length={lengthOfCm}
        offsetX={offsetX}
        offsetY={-offsetY}
        testCorrect={[answer.toString()]}
        markSchemeAnswer={[answer.toLocaleString()]}
        shapeIconName={regularShape}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureAndDrawAccurately',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
