import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, range, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { integerToWord } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'baw',
  description: 'baw',
  keywords: ['Number track', 'Backwards'],
  schema: z.object({
    endNumber: z.number().min(0).max(5),
    answerIndexes: z.array(z.number().min(2).max(7)).min(1).refine(arrayHasNoDuplicates),
    numCells: z.number().min(6).max(8)
  }),
  simpleGenerator: () => {
    const numCells = randomIntegerInclusive(6, 8);
    const endNumber = randomIntegerInclusive(numCells, 11) - numCells;
    const numberOfAns = numCells - 3;
    const answerIndexes = randomUniqueIntegersInclusive(2, numCells - 1, numberOfAns);
    return { endNumber, answerIndexes, numCells };
  },
  Component: ({ question: { endNumber, answerIndexes, numCells }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = range(endNumber + numCells - 1, endNumber);

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bax',
  description: 'bax',
  keywords: ['Words', 'Numeral'],
  schema: z
    .object({
      startingNumber: z.number().min(4).max(10),
      answerIndexes: z.array(z.number().min(0).max(4)).length(2),
      options: z
        .array(z.number().min(0).max(10))
        .length(4)
        .refine(arrayHasNoDuplicates, 'options must not contain duplicates')
    })
    .refine(
      val => val.answerIndexes.every(i => val.options.includes(val.startingNumber - i)),
      'options must include all correct answers'
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(4, 10);
    const answerIndexes = randomUniqueIntegersInclusive(0, 4, 2);
    const answerA = startingNumber - answerIndexes[0];
    const answerB = startingNumber - answerIndexes[1];
    const [incorrectA, incorrectB] = randomUniqueIntegersInclusive(0, 10, 2, {
      constraint: x => x !== answerA && x !== answerB
    });
    const options = shuffle([answerA, answerB, incorrectA, incorrectB]);
    return { startingNumber, answerIndexes, options };
  },
  Component: ({ question: { startingNumber, answerIndexes, options }, translate, displayMode }) => {
    const numberArray = range(startingNumber, startingNumber - 4);
    const stringArray = numberArray.map(integerToWord);
    const sentence = stringArray
      .map((string, index) => (answerIndexes.includes(index) ? '<ans/>' : string))
      .join(',   ');
    const items = options.map(x => ({
      value: x,
      component: <TextStructure sentence={integerToWord(x)} />
    }));
    const correctAnswer = sortNumberArray(answerIndexes).map(index => startingNumber - index);
    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToPutThemInOrder()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToPutThemInOrder()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={items}
        moveOrCopy="move"
        itemVariant="shortRectangle"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="shortRectangle"
        testCorrect={correctAnswer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountBackwardsWithin10',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
