import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  CustomizableTable,
  CustomizableTableWithState
} from '../../../../components/question/representations/CustomizableTable';
import { newQuestionContent } from '../../../Question';
import {
  getItemArrangement,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { countRange, sortNumberArray, sumNumberArray } from '../../../../utils/collections';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { View } from 'react-native';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'blf',
  description: 'blf',
  keywords: ['Table', '2-D shapes'],
  schema: z.object({
    triangles: z.number().int().min(1).max(5),
    squares: z.number().int().min(1).max(5),
    circles: z.number().int().min(1).max(5),
    ansIndexes: z.array(numberEnum([0, 1, 2])),
    colourVariant: z.enum(['purple', 'blue', 'yellow', 'green', 'red', 'pink'])
  }),
  simpleGenerator: () => {
    const triangles = randomIntegerInclusive(1, 5);
    const squares = randomIntegerInclusive(1, 5);
    const circles = randomIntegerInclusive(1, 5);
    const ansIndexesTotal = randomIntegerInclusive(1, 3);
    const ansIndexes = getRandomSubArrayFromArray([0, 1, 2] as const, ansIndexesTotal);
    const colourVariant = getRandomFromArray([
      'purple',
      'blue',
      'yellow',
      'green',
      'red',
      'pink'
    ] as const);

    return { triangles, squares, circles, ansIndexes, colourVariant };
  },
  Component: props => {
    const {
      question: { triangles, squares, circles, ansIndexes, colourVariant },
      translate,
      displayMode
    } = props;
    const triangleIcons: SvgName[] = countRange(triangles).map(
      () => `Equilateral_triangles/triangle_equal_${colourVariant}` as const
    );
    const squareIcons: SvgName[] = countRange(squares).map(
      () => `Square/square_${colourVariant}` as const
    );
    const circleIcons: SvgName[] = countRange(circles).map(
      () => `Circles/circle_${colourVariant}` as const
    );
    const shapeIcons = [...triangleIcons, ...squareIcons, ...circleIcons];

    const arrangement = getItemArrangement(
      shapeIcons,
      seededRandom(shapeIcons.map(shapeIcon => shapeIcon)),
      4,
      4
    );

    const random = seededRandom(props.question);

    const items = shuffle(
      [
        { shape: translate.shapes.triangles(1), total: triangles },
        { shape: translate.shapes.squares(1), total: squares },
        { shape: translate.shapes.circles(1), total: circles }
      ],
      { random }
    );

    const data = items.map(({ shape, total }, index) => [
      shape,
      (ansIndexes as number[]).includes(index) ? '<ans />' : total.toLocaleString()
    ]);

    // Answer
    const answer = items
      .filter((_, idx) => (ansIndexes as number[]).includes(idx))
      .map(item => item.total.toString());

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheTable()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center', width: dimens.width }}>
            <View style={{ flex: 1 }}>
              <CustomizableTableWithState
                id="table"
                inputMaxCharacters={4}
                cellHeaders={[
                  {
                    label: translate.tableHeaders.Shape(),
                    containerStyle: {
                      backgroundColor: colors.pacificBlue,
                      justifyContent: 'center'
                    }
                  },
                  {
                    label: translate.tableHeaders.Total(),
                    containerStyle: {
                      backgroundColor: colors.pacificBlue,
                      justifyContent: 'center'
                    }
                  }
                ]}
                tableData={data}
                tableCellStyle={{ height: dimens.height / 3 - 12 }}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                defaultState={
                  displayMode === 'markscheme'
                    ? answer.map(answer => answer.toString())
                    : ansIndexes.map(() => '')
                }
                testCorrect={userAnswer =>
                  userAnswer.filter((userAns, idx) => userAns === answer[idx]).length > 0
                }
              />
            </View>
            <View
              style={{
                flex: 1,
                borderColor: colors.prussianBlue,
                borderRadius: 8,
                borderWidth: 2,
                marginLeft: 16,
                padding: 12,
                height: dimens.height + 26
              }}
            >
              {arrangement.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: 'row',
                    width: dimens.width / 4,
                    height: dimens.height / 4,
                    alignItems: 'center'
                  }}
                >
                  {row.map(({ marginLeft, item }, itemIndex) => (
                    <View key={itemIndex} style={{ marginLeft: marginLeft / 2 }}>
                      <AssetSvg name={item} width={60} />
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'blg',
  description: 'blg',
  keywords: ['Most', 'Fewest', 'Table'],
  schema: z.object({
    fewestOrMore: z.enum(['Fewest', 'More']),
    contentType: z.enum(['Text', 'Icon']),
    rectangleOrSquare: z.enum(['Rectangle', 'Square']),
    objects: z.object({
      shape: z.array(z.enum(['Circle', 'Square', 'Triangle', 'Rectangle'])).length(4),
      animal: z.array(z.enum(['Sheep', 'Cow', 'Horse'])).length(3),
      pet: z.array(z.enum(['Dog', 'Cat', 'Bird'])).length(3)
    }),
    objectType: z.enum(['shape', 'animal', 'pet']),
    totals: z.array(z.number().int().min(1).max(20)).length(3),
    colourVariant: z.enum(['purple', 'blue', 'yellow', 'green', 'red', 'pink'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const fewestOrMore = getRandomFromArray(['Fewest', 'More'] as const);
    const contentType = getRandomFromArray(['Text', 'Icon'] as const);

    const objects = {
      shape: getRandomSubArrayFromArray(['Circle', 'Square', 'Triangle', 'Rectangle'] as const, 4),
      animal: getRandomSubArrayFromArray(['Sheep', 'Cow', 'Horse'] as const, 3),
      pet: getRandomSubArrayFromArray(['Dog', 'Cat', 'Bird'] as const, 3)
    };

    const objectType = getRandomFromArray(['shape', 'animal', 'pet'] as const);
    const rectangleOrSquare = getRandomFromArray(['Rectangle', 'Square'] as const);
    const totals = randomUniqueIntegersInclusive(1, 20, 3);

    const colourVariant = getRandomFromArray([
      'purple',
      'blue',
      'yellow',
      'green',
      'red',
      'pink'
    ] as const);

    return {
      fewestOrMore,
      contentType,
      objects,
      objectType,
      rectangleOrSquare,
      totals,
      colourVariant
    };
  },
  Component: props => {
    const {
      translate,
      question: {
        fewestOrMore,
        contentType,
        objects,
        objectType,
        rectangleOrSquare,
        totals,
        colourVariant
      },
      displayMode
    } = props;

    const getRandomObjectIcon = (objectType: (typeof object)[number]): SvgName => {
      switch (objectType) {
        case 'Cat':
          return 'Cat';
        case 'Circle':
          return `Circles/circle_${colourVariant}`;
        case 'Cow':
          return 'Cow';
        case 'Dog':
          return 'Dogs/dog1';
        case 'Bird':
          return 'bird_blue';
        case 'Horse':
          return 'Horse';
        case 'Sheep':
          return 'Sheep';
        case 'Rectangle':
          return `Rectangle/rectangle_${colourVariant}`;
        case 'Square':
          return `Square/square_${colourVariant}`;
        case 'Triangle':
          return `Equilateral_triangles/triangle_equal_${colourVariant}`;
      }
    };

    const translateObjects = (objectType: (typeof object)[number]) => {
      switch (objectType) {
        case 'Bird':
          return translate.pets.birds(1);
        case 'Cat':
          return translate.pets.cats(1);
        case 'Dog':
          return translate.pets.dogs(1);
        case 'Circle':
          return translate.shapes.circles(1);
        case 'Square':
          return translate.shapes.squares(1);
        case 'Rectangle':
          return translate.shapes.rectangles(1);
        case 'Triangle':
          return translate.shapes.triangles(1);
        case 'Cow':
          return translate.animals.cows(1);
        case 'Horse':
          return translate.animals.horses(1);
        case 'Sheep':
          return translate.animals.sheep();
      }
    };

    const object = objects[objectType].filter(obj => obj !== rectangleOrSquare);

    const [randomObjectA, randomObjectB, randomObjectC] = object;

    const randomObjectIconA = getRandomObjectIcon(randomObjectA);
    const randomObjectIconB = getRandomObjectIcon(randomObjectB);
    const randomObjectIconC = getRandomObjectIcon(randomObjectC);

    const [total1, total2, total3] = totals;

    // Table data
    const items = [
      { object: randomObjectA, icon: randomObjectIconA, total: total1 },
      { object: randomObjectB, icon: randomObjectIconB, total: total2 },
      { object: randomObjectC, icon: randomObjectIconC, total: total3 }
    ];

    const data = items.map(({ object, icon, total }, index) => [
      contentType === 'Text' ? object : <AssetSvg key={index} name={icon} width={120} />,
      total.toLocaleString()
    ]);

    const tableHeader =
      objectType === 'animal'
        ? translate.tableHeaders.Animal()
        : objectType === 'pet'
        ? translate.tableHeaders.Pet()
        : translate.tableHeaders.Shape();

    const selectables = shuffle(
      [
        translateObjects(randomObjectA),
        translateObjects(randomObjectB),
        translateObjects(randomObjectC)
      ],
      { random: seededRandom(props.question) }
    );

    const totalsAscending = sortNumberArray(totals, 'ascending');
    const totalsDescending = sortNumberArray(totals, 'descending');

    // Answer
    const answer =
      fewestOrMore === 'Fewest'
        ? items.filter(item => item.total === totalsAscending[0])[0].object.toLowerCase()
        : items.filter(item => item.total === totalsDescending[0])[0].object.toLowerCase();

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          fewestOrMore === 'More'
            ? translate.ks1Instructions.selectTheXObjectThereIsMostOf(objectType)
            : translate.ks1Instructions.selectTheXObjectThereIsFewestOf(objectType)
        }
        pdfTitle={
          fewestOrMore === 'More'
            ? translate.ks1PDFInstructions.tickTheXObjectThereIsMostOf(objectType)
            : translate.ks1PDFInstructions.tickTheXObjectThereIsFewestOf(objectType)
        }
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        correctAnswer={[answer.toString()]}
        questionHeight={900}
        selectableTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        leftContent={
          <MeasureView>
            {dimens => (
              <CustomizableTable
                inputMaxCharacters={4}
                cellHeaders={[
                  {
                    label: tableHeader,
                    containerStyle: {
                      backgroundColor: colors.pacificBlue,
                      justifyContent: 'center'
                    }
                  },
                  {
                    label: translate.tableHeaders.Total(),
                    containerStyle: {
                      backgroundColor: colors.pacificBlue,
                      justifyContent: 'center'
                    }
                  }
                ]}
                tableData={data}
                tableCellStyle={{ height: dimens.height / 3 - 12 }}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'blh',
  description: 'blh',
  keywords: ['Total', 'Altogether', 'Difference', 'Fewer', 'More', 'Table'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('Shape'),
        shape: z.array(z.enum(['Circle', 'Square', 'Triangle', 'Rectangle'])).length(4)
      }),
      z.object({
        variant: z.literal('Animal'),
        animal: z.array(z.enum(['Sheep', 'Cow', 'Horse'])).length(3)
      }),
      z.object({
        variant: z.literal('Pet'),
        pet: z.array(z.enum(['Dog', 'Cat', 'Bird'])).length(3)
      })
    ]),
    randomObjectIndexes: z.array(z.number().int().min(0).max(2)).length(2),
    totals: z.array(z.number().int().min(1).max(20)).length(3),
    moreOrFewer: z.enum(['More', 'Fewer']),
    contentType: z.enum(['Text', 'Icon']),
    rectangleOrSquare: z.enum(['Rectangle', 'Square']),
    colourVariant: z.enum(['purple', 'blue', 'yellow', 'green', 'red', 'pink']),
    questionVariant: numberEnum([1, 2, 3])
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['Shape', 'Animal', 'Pet'] as const);
    const questionVariant = getRandomFromArray([1, 2, 3] as const);

    const randomObjectIndexes = randomUniqueIntegersInclusive(0, 2, 2);
    const totals = randomUniqueIntegersInclusive(1, 20, 3);

    const moreOrFewer = getRandomFromArray(['More', 'Fewer'] as const);
    const contentType = getRandomFromArray(['Text', 'Icon'] as const);
    const rectangleOrSquare = getRandomFromArray(['Rectangle', 'Square'] as const);
    const colourVariant = getRandomFromArray([
      'purple',
      'blue',
      'yellow',
      'green',
      'red',
      'pink'
    ] as const);

    const subQuestion =
      variation === 'Shape'
        ? {
            variant: variation,
            shape: getRandomSubArrayFromArray(
              ['Circle', 'Square', 'Triangle', 'Rectangle'] as const,
              4
            )
          }
        : variation === 'Animal'
        ? {
            variant: variation,
            animal: getRandomSubArrayFromArray(['Sheep', 'Cow', 'Horse'] as const, 3)
          }
        : {
            variant: variation,
            pet: getRandomSubArrayFromArray(['Dog', 'Cat', 'Bird'] as const, 3)
          };

    return {
      randomObjectIndexes,
      totals,
      moreOrFewer,
      contentType,
      rectangleOrSquare,
      colourVariant,
      subQuestion,
      questionVariant
    };
  },
  Component: props => {
    const {
      question: {
        randomObjectIndexes,
        totals,
        moreOrFewer,
        contentType,
        rectangleOrSquare,
        colourVariant,
        subQuestion,
        questionVariant
      },
      translate,
      displayMode
    } = props;

    const object =
      subQuestion.variant === 'Shape'
        ? subQuestion.shape.filter(shape => shape !== rectangleOrSquare)
        : subQuestion.variant === 'Animal'
        ? subQuestion.animal
        : subQuestion.pet;

    const [randomObjectA, randomObjectB, randomObjectC] = object;

    const getRandomObjectIcon = (objectType: (typeof object)[number]): SvgName => {
      switch (objectType) {
        case 'Cat':
          return 'Cat';
        case 'Circle':
          return `Circles/circle_${colourVariant}` as const;
        case 'Cow':
          return 'Cow';
        case 'Dog':
          return 'Dogs/dog1';
        case 'Bird':
          return 'bird_blue';
        case 'Horse':
          return 'Horse';
        case 'Sheep':
          return 'Sheep';
        case 'Rectangle':
          return `Rectangle/rectangle_${colourVariant}` as const;
        case 'Square':
          return `Square/square_${colourVariant}` as const;
        case 'Triangle':
          return `Equilateral_triangles/triangle_equal_${colourVariant}` as const;
      }
    };

    const randomObjectIconA = getRandomObjectIcon(randomObjectA);
    const randomObjectIconB = getRandomObjectIcon(randomObjectB);
    const randomObjectIconC = getRandomObjectIcon(randomObjectC);

    const [total1, total2, total3] = totals;

    const tableHeader =
      subQuestion.variant === 'Animal'
        ? translate.tableHeaders.Animal()
        : subQuestion.variant === 'Pet'
        ? translate.tableHeaders.Pet()
        : translate.tableHeaders.Shape();

    const [randomIdx1, randomIdx2] = randomObjectIndexes;

    const [xObject, yObject] = [object[randomIdx1], object[randomIdx2]];

    const translateObjects = (objectType: (typeof object)[number]) => {
      switch (objectType) {
        case 'Bird':
          return translate.pets.birds(2);
        case 'Cat':
          return translate.pets.cats(2);
        case 'Dog':
          return translate.pets.dogs(2);
        case 'Circle':
          return translate.shapes.Circles(2);
        case 'Square':
          return translate.shapes.Squares(2);
        case 'Rectangle':
          return translate.shapes.Rectangles(2);
        case 'Triangle':
          return translate.shapes.Triangles(2);
        case 'Cow':
          return translate.animals.cows(2);
        case 'Horse':
          return translate.animals.horses(2);
        case 'Sheep':
          return translate.animals.sheep();
      }
    };

    // Translated titles
    const objectXTranslated = translateObjects(xObject);
    const objectYTranslated = translateObjects(yObject);

    // Table data
    const items = [
      { object: randomObjectA, icon: randomObjectIconA, total: total1 },
      { object: randomObjectB, icon: randomObjectIconB, total: total2 },
      { object: randomObjectC, icon: randomObjectIconC, total: total3 }
    ];

    const data = items.map(({ object, icon, total }, index) => [
      contentType === 'Text' ? object : <AssetSvg key={index} name={icon} width={90} />,
      total.toLocaleString()
    ]);

    // Object total values
    const totalX = totals[object.findIndex(item => item === xObject)];
    const totalY = totals[object.findIndex(item => item === yObject)];

    // Answer
    const answer =
      questionVariant === 1
        ? Math.abs(totalX - totalY)
        : questionVariant === 2
        ? sumNumberArray([totalX, totalY])
        : sumNumberArray([total1, total2, total3]);

    return (
      <QF1ContentAndSentence
        title={
          questionVariant === 1
            ? moreOrFewer === 'Fewer'
              ? translate.ks1Instructions.howManyFewerXObjectThanYObjectAreThere(
                  totalX < totalY ? objectXTranslated : objectYTranslated,
                  totalX < totalY ? objectYTranslated : objectXTranslated
                )
              : translate.ks1Instructions.howManyMoreXObjectThanYObjectAreThere(
                  totalX < totalY ? objectYTranslated : objectXTranslated,
                  totalX < totalY ? objectXTranslated : objectYTranslated
                )
            : questionVariant === 2
            ? translate.ks1Instructions.howManyXObjectAndYObjectAltogether(
                objectXTranslated,
                objectYTranslated
              )
            : translate.ks1Instructions.howManyXObjectInTotal(
                subQuestion.variant === 'Animal'
                  ? translate.misc.animals(2)
                  : subQuestion.variant === 'Pet'
                  ? translate.misc.pets()
                  : translate.misc.shapes()
              )
        }
        sentence={'<ans />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[answer.toString()]}
        Content={
          <CustomizableTable
            inputMaxCharacters={4}
            cellHeaders={[
              {
                label: tableHeader,
                containerStyle: {
                  backgroundColor: colors.pacificBlue,
                  justifyContent: 'center'
                }
              },
              {
                label: translate.tableHeaders.Total(),
                containerStyle: {
                  backgroundColor: colors.pacificBlue,
                  justifyContent: 'center'
                }
              }
            ]}
            tableData={data}
            textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
          />
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Tables',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
