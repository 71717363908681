import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  MultiLinkedShapeNames,
  filterMultilinkShapes,
  multilinkShapes,
  MultilinkShapesSchema,
  scaleMultilinkShapes
} from '../../../../utils/multiLinkCubesImages';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { filterShapes3DIso, shapes3DIso, Shapes3DIsoSchema } from '../../../../utils/shapes3DIso';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDG',
  description: 'aDG',
  keywords: ['Volume', 'Compare', '3-D shape'],
  schema: z
    .object({
      shapeA: MultilinkShapesSchema,
      shapeB: MultilinkShapesSchema,
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    const [sizeA, sizeB] = getRandomSubArrayFromArray([3, 4, 6, 8, 10, 12], 2);

    const shapesForA = filterMultilinkShapes({ hasDepth: false, numberOfCubes: [sizeA, sizeA] });

    const shapesForB = filterMultilinkShapes({ hasDepth: false, numberOfCubes: [sizeB, sizeB] });

    const shapeA = getRandomFromArray(shapesForA) as MultiLinkedShapeNames;

    const shapeB = getRandomFromArray(shapesForB) as MultiLinkedShapeNames;

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { shapeA, shapeB, smallerOrGreater };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB, smallerOrGreater } = question;

    const { svg: svgA, numberOfCubes: numberOfCubesA } = multilinkShapes[shapeA];
    const { svg: svgB, numberOfCubes: numberOfCubesB } = multilinkShapes[shapeB];

    const scales = scaleMultilinkShapes([shapeA, shapeB]);

    const items = shuffle(
      [
        {
          value: numberOfCubesA,
          svgName: svgA,
          isCorrect:
            smallerOrGreater === 'smaller'
              ? numberOfCubesA < numberOfCubesB
              : numberOfCubesA > numberOfCubesB,
          scale: scales[0]
        },
        {
          value: numberOfCubesB,
          svgName: svgB,
          isCorrect:
            smallerOrGreater === 'smaller'
              ? numberOfCubesB < numberOfCubesA
              : numberOfCubesB > numberOfCubesA,
          scale: scales[1]
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.select3DShapeWithSmallerVolume()
            : translate.instructions.select3DShapeWithGreaterVolume()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circle3DShapeWithSmallerVolume()
            : translate.instructions.circle3DShapeWithGreaterVolume()
        }
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName, scale }) => ({
            component: (
              <AssetSvg
                name={svgName}
                width={Math.min(dimens.width, dimens.height) * 0.8 * scale}
                height={Math.min(dimens.width, dimens.height) * 0.8 * scale}
              />
            ),
            value
          }))
        }
        testCorrect={items.filter(item => item.isCorrect).map(item => item.value)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDH',
  description: 'aDH',
  keywords: ['Volume', '3-D shape', 'Compare'],
  schema: z
    .object({
      shapeA: MultilinkShapesSchema,
      shapeB: MultilinkShapesSchema
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    const possibleShapes = filterMultilinkShapes({ hasDepth: false, numberOfCubes: [3, 12] });

    const [shapeA, shapeB] = getRandomSubArrayFromArray(
      possibleShapes,
      2
    ) as MultiLinkedShapeNames[];

    return { shapeA, shapeB };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB } = question;

    const { svg: svgA, numberOfCubes: numberOfCubesA } = multilinkShapes[shapeA];
    const { svg: svgB, numberOfCubes: numberOfCubesB } = multilinkShapes[shapeB];

    const scales = scaleMultilinkShapes([shapeA, shapeB]);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareVolumesOfShapes()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareVolumesOfShapes()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(numberOfCubesA, numberOfCubesB),
            lhsComponent: (
              <MeasureView>
                {dimens => (
                  <AssetSvg
                    name={svgA}
                    width={Math.min(dimens.width, dimens.height) * 0.8 * scales[0]}
                    height={Math.min(dimens.width, dimens.height) * 0.8 * scales[0]}
                  />
                )}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => (
                  <AssetSvg
                    name={svgB}
                    width={Math.min(dimens.width, dimens.height) * 0.8 * scales[1]}
                    height={Math.min(dimens.width, dimens.height) * 0.8 * scales[1]}
                  />
                )}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aDI',
  description: 'aDI',
  keywords: ['Volume', 'Order', '3-D shape'],
  schema: z
    .object({
      imageSizeA: numberEnum([1, 3, 4, 6, 7, 8, 10, 12]),
      imageSizeB: numberEnum([1, 3, 4, 6, 7, 8, 10, 12]),
      imageSizeC: numberEnum([1, 3, 4, 6, 7, 8, 10, 12]),
      imageSizeD: numberEnum([1, 3, 4, 6, 7, 8, 10, 12]),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.imageSizeA, val.imageSizeB, val.imageSizeC, val.imageSizeD]),
      'All image sizes must be different.'
    ),
  simpleGenerator: () => {
    const [imageSizeA, imageSizeB, imageSizeC, imageSizeD] = getRandomSubArrayFromArray(
      [1, 3, 4, 6, 7, 8, 10, 12] as const,
      4
    );

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { imageSizeA, imageSizeB, imageSizeC, imageSizeD, ordering };
  },
  Component: props => {
    const {
      question: { imageSizeA, imageSizeB, imageSizeC, imageSizeD, ordering },
      translate
    } = props;

    const imageAImageChoices = filterMultilinkShapes({
      hasDepth: false,
      numberOfCubes: [imageSizeA, imageSizeA]
    });

    const imageBImageChoices = filterMultilinkShapes({
      hasDepth: false,
      numberOfCubes: [imageSizeB, imageSizeB]
    });

    const imageCImageChoices = filterMultilinkShapes({
      hasDepth: false,
      numberOfCubes: [imageSizeC, imageSizeC]
    });

    const imageDImageChoices = filterMultilinkShapes({
      hasDepth: false,
      numberOfCubes: [imageSizeD, imageSizeD]
    });

    const random = seededRandom(props.question);

    const imageA = getRandomFromArray(imageAImageChoices, { random }) as string;
    const imageB = getRandomFromArray(imageBImageChoices, { random }) as string;
    const imageC = getRandomFromArray(imageCImageChoices, { random }) as string;
    const imageD = getRandomFromArray(imageDImageChoices, { random }) as string;

    const scales = scaleMultilinkShapes([imageA, imageB, imageC, imageD]);

    const images = [
      {
        image: imageA,
        volume: imageSizeA,
        scale: scales[0]
      },
      {
        image: imageB,
        volume: imageSizeB,
        scale: scales[1]
      },
      {
        image: imageC,
        volume: imageSizeC,
        scale: scales[2]
      },
      {
        image: imageD,
        volume: imageSizeD,
        scale: scales[3]
      }
    ];

    const correctOrder = sortNumberArray(
      [imageSizeA, imageSizeB, imageSizeC, imageSizeD],
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.instructions.dragCardsToPutShapesInAscendingOrderOfVolume()
            : translate.instructions.dragCardsToPutShapesInDescendingOrderOfVolume()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.instructions.useCardsToPutShapesInAscendingOrderOfVolume()
            : translate.instructions.useCardsToPutShapesInDescendingOrderOfVolume()
        }
        testCorrect={correctOrder}
        items={images.map(({ image, volume, scale }) => ({
          value: volume,
          component: (
            <MeasureView>
              {dimens => (
                <AssetSvg
                  name={multilinkShapes[image as string].svg as SvgName}
                  height={dimens.height * scale * 0.95}
                  width={dimens.width * scale * 0.95}
                />
              )}
            </MeasureView>
          )
        }))}
        itemVariant="largeSquare"
        pdfItemVariant="largeSquare"
        actionPanelVariant="bottomTallCircleCont"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aDJ',
  description: 'aDJ',
  keywords: ['Volume', '3-D shape', 'Compare'],
  schema: z
    .object({
      shapeA: MultilinkShapesSchema,
      shapeB: MultilinkShapesSchema
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    // One shape must have no depth and be irregular:
    const possibleShape1 = filterMultilinkShapes({ hasDepth: false, isIrregular: true });

    // The other shape must have depth:
    const possibleShape2 = filterMultilinkShapes({ hasDepth: true });

    const shapes = [
      getRandomFromArray(possibleShape1) as MultiLinkedShapeNames,
      getRandomFromArray(possibleShape2) as MultiLinkedShapeNames
    ];

    const [shapeA, shapeB] = shuffle(shapes);

    return { shapeA, shapeB };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB } = question;

    const { svg: svgA, numberOfCubes: numberOfCubesA } = multilinkShapes[shapeA];
    const { svg: svgB, numberOfCubes: numberOfCubesB } = multilinkShapes[shapeB];

    const scales = scaleMultilinkShapes([shapeA, shapeB]);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareVolumesOfShapes()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareVolumesOfShapes()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(numberOfCubesA, numberOfCubesB),
            lhsComponent: (
              <MeasureView>
                {dimens => (
                  <AssetSvg
                    name={svgA}
                    width={Math.min(dimens.width, dimens.height) * 0.8 * scales[0]}
                    height={Math.min(dimens.width, dimens.height) * 0.8 * scales[0]}
                  />
                )}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => (
                  <AssetSvg
                    name={svgB}
                    width={Math.min(dimens.width, dimens.height) * 0.8 * scales[1]}
                    height={Math.min(dimens.width, dimens.height) * 0.8 * scales[1]}
                  />
                )}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aDK',
  description: 'aDK',
  keywords: ['Volume', 'Compare', '3-D shape'],
  schema: z
    .union([
      z.object({
        shapeA: MultilinkShapesSchema,
        shapeB: MultilinkShapesSchema,
        smallerOrGreater: z.enum(['smaller', 'greater'])
      }),
      z.object({
        shapeA: Shapes3DIsoSchema,
        shapeB: Shapes3DIsoSchema,
        smallerOrGreater: z.enum(['smaller', 'greater'])
      })
    ])
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    // TODO Revert after launch and once we fix scaling problem
    // const [sizeA, sizeB] = getRandomSubArrayFromArray([2, 8, 12, 24, 27, 64], 2);

    // const shapesForA = filterMultilinkShapes({ hasDepth: true, numberOfCubes: [sizeA, sizeA] });

    // const shapesForB = filterMultilinkShapes({ hasDepth: true, numberOfCubes: [sizeB, sizeB] });

    // const shapeA = getRandomFromArray(shapesForA) as MultiLinkeShapeNames;

    // const shapeB = getRandomFromArray(shapesForB) as MultiLinkeShapeNames;

    // return { shapeA, shapeB, smallerOrGreater };

    const shapesWithDepth = filterShapes3DIso({ hasDepth: true })!;
    const { shapeA, shapeB } = rejectionSample(
      () => {
        const shapeA = getRandomFromArray(shapesWithDepth)!;
        const shapeB = getRandomFromArray(shapesWithDepth)!;
        return { shapeA, shapeB };
      },
      ({ shapeA, shapeB }) =>
        shapes3DIso[shapeA].numberOfCubes !== shapes3DIso[shapeB].numberOfCubes
    );

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { shapeA, shapeB, smallerOrGreater };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB, smallerOrGreater } = question;

    const random = seededRandom(question);

    let items: { volume: number; shapeName: SvgName }[];

    if (shapeA in multilinkShapes) {
      // Using multilink shapes
      const { svg: svgNameA, numberOfCubes: volumeA } =
        multilinkShapes[shapeA as MultiLinkedShapeNames];
      const { svg: svgNameB, numberOfCubes: volumeB } =
        multilinkShapes[shapeB as MultiLinkedShapeNames];

      items = [
        { volume: volumeA, shapeName: svgNameA },
        { volume: volumeB, shapeName: svgNameB }
      ];
    } else {
      // Using 3DIso
      const { svg: svgNameA, numberOfCubes: volumeA } =
        shapes3DIso[shapeA as MultiLinkedShapeNames];
      const { svg: svgNameB, numberOfCubes: volumeB } =
        shapes3DIso[shapeB as MultiLinkedShapeNames];

      items = [
        { volume: volumeA, shapeName: svgNameA },
        { volume: volumeB, shapeName: svgNameB }
      ];
    }

    items = shuffle(items, { random });
    const correctAnswer = (smallerOrGreater === 'smaller' ? Math.min : Math.max)(
      ...items.map(it => it.volume)
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectCuboidWithSmallerVolume()
            : translate.instructions.selectCuboidWithGreaterVolume()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleCuboidWithSmallerVolume()
            : translate.instructions.circleCuboidWithGreaterVolume()
        }
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ shapeName, volume }) => ({
            component: (
              <AssetSvg name={shapeName} width={dimens.width * 0.8} height={dimens.height * 0.8} />
            ),
            value: volume
          }))
        }
        testCorrect={[correctAnswer]}
      />
    );
  }
});

// Question6 exported to aW6
const Question6 = newQuestionContent({
  uid: 'aDL',
  description: 'aDL',
  keywords: ['Volume', 'Order', '3-D shape'],
  schema: z
    .object({
      imageSizeA: numberEnum([2, 8, 12, 24]),
      imageSizeB: numberEnum([2, 8, 12, 24]),
      imageSizeC: numberEnum([1, 2, 3, 4, 6, 7, 8, 10, 12, 24]),
      imageSizeD: numberEnum([1, 2, 3, 4, 6, 7, 8, 10, 12, 24]),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.imageSizeA, val.imageSizeB, val.imageSizeC, val.imageSizeD]),
      'All image sizes must be different.'
    ),
  simpleGenerator: () => {
    // Two images must have depth - these dimens are the only ones with depth.
    const [imageSizeA, imageSizeB] = getRandomSubArrayFromArray([2, 8, 12, 24] as const, 2);

    const [imageSizeC, imageSizeD] = getRandomSubArrayFromArray(
      ([1, 2, 3, 4, 6, 7, 8, 10, 12, 24] as const).filter(
        num => num !== imageSizeA && num !== imageSizeB
      ),
      2
    );

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { imageSizeA, imageSizeB, imageSizeC, imageSizeD, ordering };
  },
  Component: props => {
    const {
      question: { imageSizeA, imageSizeB, imageSizeC, imageSizeD, ordering },
      translate
    } = props;

    const imageAImageChoices = filterMultilinkShapes({
      hasDepth: true,
      numberOfCubes: [imageSizeA, imageSizeA],
      isCube: false
    });

    const imageBImageChoices = filterMultilinkShapes({
      hasDepth: true,
      numberOfCubes: [imageSizeB, imageSizeB],
      isCube: false
    });

    const imageCImageChoices = filterMultilinkShapes({
      numberOfCubes: [imageSizeC, imageSizeC]
    });

    const imageDImageChoices = filterMultilinkShapes({
      numberOfCubes: [imageSizeD, imageSizeD]
    });

    const random = seededRandom(props.question);

    const imageA = getRandomFromArray(imageAImageChoices, { random }) as string;
    const imageB = getRandomFromArray(imageBImageChoices, { random }) as string;
    const imageC = getRandomFromArray(imageCImageChoices, { random }) as string;
    const imageD = getRandomFromArray(imageDImageChoices, { random }) as string;

    const scales = scaleMultilinkShapes([imageA, imageB, imageC, imageD]);

    const images = shuffle(
      [
        {
          image: imageA,
          volume: imageSizeA,
          scale: scales[0]
        },
        {
          image: imageB,
          volume: imageSizeB,
          scale: scales[1]
        },
        {
          image: imageC,
          volume: imageSizeC,
          scale: scales[2]
        },
        {
          image: imageD,
          volume: imageSizeD,
          scale: scales[3]
        }
      ],
      { random }
    );

    const correctOrder = sortNumberArray(
      [imageSizeA, imageSizeB, imageSizeC, imageSizeD],
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.instructions.dragCardsToPutShapesInAscendingOrderOfVolume()
            : translate.instructions.dragCardsToPutShapesInDescendingOrderOfVolume()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.instructions.useCardsToPutShapesInAscendingOrderOfVolume()
            : translate.instructions.useCardsToPutShapesInDescendingOrderOfVolume()
        }
        testCorrect={correctOrder}
        items={images.map(({ image, volume, scale }) => ({
          value: volume,
          component: (
            <MeasureView>
              {dimens => (
                <AssetSvg
                  name={multilinkShapes[image as string].svg as SvgName}
                  height={dimens.height * scale * 0.95}
                  width={dimens.width * scale * 0.95}
                />
              )}
            </MeasureView>
          )
        }))}
        itemVariant="largeSquare"
        pdfItemVariant="largeSquare"
        actionPanelVariant="bottomTallCircleCont"
      />
    );
  }
});
export const aDL = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareVolume',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
