import { type ErrorMessages } from './types';

export const errorMessages: ErrorMessages = {
  validateSchoolCode: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    UNKNOWN_ERROR: 'Unknown Error',
    SCHOOL_CODE_NOT_FOUND: (schoolCode: string) => `School Code was not found: ${schoolCode})`,
    NO_INFINITY_SUBSCRIPTION: (schoolCode: string) =>
      `No Infinity subscription for school code: ${schoolCode}`
  },

  createNewQuizSession: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    UNKNOWN_ERROR: 'Unknown Error',
    QUIZ_NOT_FOUND: (quizPin: string) => `Quiz not found: ${quizPin}`,
    QUIZ_LOCKED: (quizPin: string) => `Quiz locked: ${quizPin}`
  },

  createAssignedQuizSession: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    LOGGED_OUT: 'Logged Out',
    UNKNOWN_ERROR: 'Unknown Error',
    QUIZ_NOT_FOUND: (assignemntId: string) => `Quiz assignment not found: ${assignemntId}`
  },

  getExistingQuizSession: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    PARSE_ERROR: 'Parse Error',
    LOGGED_OUT: 'Logged Out',
    UNKNOWN_ERROR: 'Unknown Error',
    QUIZ_SESSION_NOT_FOUND: (quizSessionId: string) => `Quiz session not found: ${quizSessionId}`,
    QUIZ_SESSION_EXPIRED: (quizSessionId: string) => `Quiz session expired: ${quizSessionId})`
  },

  updateQuizSession: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error'
  },

  validatePupilAccessCode: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error',
    PUPIL_NOT_FOUND: (pupilCode: string) => `Pupil was not found: ${pupilCode}`
  },

  getStudentData: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error',
    PARSE_ERROR: 'Parse Error'
  },

  getStudentQuizzes: {
    NETWORK_ERROR: 'Network Error',
    HTTP_ERROR: 'HTTP Error',
    UNKNOWN_ERROR: 'Unknown Error',
    PARSE_ERROR: 'Parse Error'
  },

  quizScreen: {
    RENDER_ERROR: (uid: string) => `Failed to render question: ${uid}`
  }
};
