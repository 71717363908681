import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { PictogramColors, barColorNames, barColorsNamesArray } from 'common/src/theme/colors';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomMorePlacesItem,
  morePlacesAsWord,
  morePlacesSchema
} from 'common/src/utils/places';
import Pictogram from 'common/src/components/question/representations/Pictogram/Pictogram';
import { compareFloats } from 'common/src/utils/math';
import { countRange, sumNumberArray } from 'common/src/utils/collections';
import { colorsAsWord } from 'common/src/utils/colors';
import { all, create, number } from 'mathjs';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from 'common/src/components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { numberEnum } from '../../../../utils/zod';
import MultiBarChart from '../../../../components/question/representations/Coordinates/MultiBarChart';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ay2',
  description: 'ay2',
  keywords: ['Bar chart', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    maxVisitors: z.number().int().min(5).max(14),
    visitors: z.number().int().min(0).max(14).array().length(5),
    scale: z.boolean(),
    answerIdx1: z.number().int().min(0).max(4),
    answerIdx2: z.number().int().min(0).max(4),
    option: morePlacesSchema
  }),
  simpleGenerator: () => {
    const { maxVisitors, visitors, scale, answerIdx1, answerIdx2, option } = rejectionSample(
      () => {
        const scale = getRandomBoolean();
        const maxVisitors = scale ? randomIntegerInclusive(5, 7) : randomIntegerInclusive(10, 14);
        const visitors = countRange(5).map(idx => {
          return randomIntegerInclusive(idx === 2 ? 0 : scale ? 1 : 2, maxVisitors);
        });

        const [answerIdx1, answerIdx2] = randomUniqueIntegersInclusive(0, 4, 2);

        const option = getRandomMorePlacesItem();

        return {
          maxVisitors,
          visitors,
          scale,
          answerIdx1,
          answerIdx2,
          option
        };
      },
      ({ visitors, answerIdx1, answerIdx2 }) => visitors[answerIdx1] > visitors[answerIdx2]
    );
    return {
      maxVisitors,
      visitors,
      scale,
      answerIdx1,
      answerIdx2,
      option
    };
  },
  Component: props => {
    const {
      question: { maxVisitors, visitors, scale, answerIdx1, answerIdx2, option },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const visitorsData = [
      {
        day: translate.time.Monday(),
        value: visitors[0]
      },
      {
        day: translate.time.Tuesday(),
        value: visitors[1]
      },
      {
        day: translate.time.Wednesday(),
        value: visitors[2]
      },
      {
        day: translate.time.Thursday(),
        value: visitors[3]
      },
      {
        day: translate.time.Friday(),
        value: visitors[4]
      }
    ];

    const place = morePlacesAsWord(option, translate);

    const day1 = visitorsData[answerIdx1].day;
    const day2 = visitorsData[answerIdx2].day;

    // Answer
    const ans = visitorsData[answerIdx1].value - visitorsData[answerIdx2].value;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howManyMoreVisitorsWentToTheXOptionOnDayXThanOnDayY(
          place,
          day1,
          day2
        )}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <MultiBarChart
              width={dimens.width}
              height={dimens.height}
              barValues={visitorsData
                .map(data => data.value)
                .map((val, i) => ({ option: i, values: [val] }))}
              barLabels={[
                translate.time.Mon(),
                translate.time.Tue(),
                translate.time.Wed(),
                translate.time.Thu(),
                translate.time.Fri()
              ]}
              barColors={colors.map(key => barColorNames[key])}
              snapToNearest="grid"
              yMax={maxVisitors}
              yStepSize={scale ? 1 : 2}
              xAxisLabel={translate.keywords.Day()}
              yAxisLabel={translate.misc.numberOfVisitors()}
              yAxisArrowLabel={null}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ay3',
  description: 'ay3',
  keywords: ['Bar chart', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    maxVisitors: z.number().int().min(5).max(14),
    visitors: z.number().int().min(0).max(14).array().length(5),
    scale: z.boolean(),
    answerIdx1: z.number().int().min(0).max(4),
    answerIdx2: z.number().int().min(0).max(4)
  }),
  simpleGenerator: () => {
    const { maxVisitors, visitors, scale, answerIdx1, answerIdx2 } = rejectionSample(
      () => {
        const scale = getRandomBoolean();
        const maxVisitors = scale ? randomIntegerInclusive(5, 7) : randomIntegerInclusive(10, 14);
        const visitors = countRange(5).map(idx => {
          return randomIntegerInclusive(idx === 2 ? 0 : scale ? 1 : 2, maxVisitors);
        });

        const [answerIdx1, answerIdx2] = randomUniqueIntegersInclusive(0, 4, 2);

        return {
          maxVisitors,
          visitors,
          scale,
          answerIdx1,
          answerIdx2
        };
      },
      ({ visitors, answerIdx1, answerIdx2 }) => visitors[answerIdx1] > visitors[answerIdx2]
    );
    return {
      maxVisitors,
      visitors,
      scale,
      answerIdx1,
      answerIdx2
    };
  },
  Component: props => {
    const {
      question: { maxVisitors, visitors, scale, answerIdx1, answerIdx2 },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const visitorsData = [
      {
        day: translate.time.Monday(),
        value: visitors[0]
      },
      {
        day: translate.time.Tuesday(),
        value: visitors[1]
      },
      {
        day: translate.time.Wednesday(),
        value: visitors[2]
      },
      {
        day: translate.time.Thursday(),
        value: visitors[3]
      },
      {
        day: translate.time.Friday(),
        value: visitors[4]
      }
    ];

    const day1 = visitorsData[answerIdx1].day;
    const day2 = visitorsData[answerIdx2].day;

    const sortedDays = [visitorsData[answerIdx1].value, visitorsData[answerIdx2].value].sort(
      (a, b) => b - a
    );

    // Answer
    const ans = sortedDays[0] - sortedDays[1];

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatIsTheDifferenceBetweenNumberOfVisitorsOnDayXandDayY(
          day1,
          day2
        )}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <MultiBarChart
              width={dimens.width}
              height={dimens.height + 40}
              barValues={visitorsData
                .map(data => data.value)
                .map((val, i) => ({ option: i, values: [val] }))}
              barLabels={[
                translate.time.Mon(),
                translate.time.Tue(),
                translate.time.Wed(),
                translate.time.Thu(),
                translate.time.Fri()
              ]}
              barColors={colors.map(key => barColorNames[key])}
              snapToNearest="grid"
              yMax={maxVisitors}
              yStepSize={scale ? 1 : 2}
              xAxisLabel={translate.keywords.Day()}
              yAxisLabel={translate.misc.numberOfVisitors()}
              yAxisArrowLabel={null}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ay4',
  description: 'ay4',
  keywords: ['Bar chart', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    maxVisitors: z.number().int().min(5).max(14),
    visitors: z.number().int().min(0).max(14).array().length(5),
    scale: z.boolean()
  }),
  simpleGenerator: () => {
    const scale = getRandomBoolean();
    const maxVisitors = scale ? randomIntegerInclusive(5, 7) : randomIntegerInclusive(10, 14);
    const visitors = countRange(5).map(idx => {
      return randomIntegerInclusive(idx === 2 ? 0 : scale ? 1 : 2, maxVisitors);
    });

    return {
      maxVisitors,
      visitors,
      scale
    };
  },
  Component: props => {
    const {
      question: { maxVisitors, visitors, scale },
      translate
    } = props;

    const visitorsData = [
      {
        day: 'Monday',
        value: visitors[0]
      },
      {
        day: 'Tuesday',
        value: visitors[1]
      },
      {
        day: 'Wednesday',
        value: visitors[2]
      },
      {
        day: 'Thursday',
        value: visitors[3]
      },
      {
        day: 'Friday',
        value: visitors[4]
      }
    ];

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    // Answer
    const ans = visitorsData.map(data => data.value).reduce((total, item) => total + item);

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatIsTheTotalNumberOfVisitorsFromMonFri()}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <MultiBarChart
              width={dimens.width}
              height={dimens.height}
              barValues={visitorsData
                .map(data => data.value)
                .map((val, i) => ({ option: i, values: [val] }))}
              barLabels={[
                translate.time.Mon(),
                translate.time.Tue(),
                translate.time.Wed(),
                translate.time.Thu(),
                translate.time.Fri()
              ]}
              barColors={colors.map(key => barColorNames[key])}
              snapToNearest="grid"
              yMax={maxVisitors}
              yStepSize={scale ? 1 : 2}
              xAxisLabel={translate.misc.day()}
              yAxisLabel={translate.misc.numberOfVisitorsLower()}
              yAxisArrowLabel={null}
            />
          );
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ay5',
  description: 'ay5',
  keywords: ['Bar chart', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    maxVisitors: z.number().int().min(5).max(14),
    visitors: z.number().int().min(0).max(14).array().length(5),
    scale: z.boolean(),
    answerIdx1: z.number().int().min(0).max(4),
    answerIdx2: z.number().int().min(0).max(4),
    answerIdx3: z.number().int().min(0).max(4),
    timesMultiple: z.number().int().min(2).max(7)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { maxVisitors, visitors, scale, answerIdx1, answerIdx2, answerIdx3, timesMultiple } =
      rejectionSample(
        () => {
          const scale = getRandomBoolean();
          const maxVisitors = scale ? randomIntegerInclusive(5, 7) : randomIntegerInclusive(10, 14);
          const visitors = countRange(5).map(idx => {
            return randomIntegerInclusive(idx === 2 ? 0 : scale ? 1 : 2, maxVisitors);
          });

          const [answerIdx1, answerIdx2, answerIdx3] = randomUniqueIntegersInclusive(0, 4, 3);

          const timesMultiple = randomIntegerInclusive(2, 7);

          return {
            maxVisitors,
            visitors,
            scale,
            answerIdx1,
            answerIdx2,
            answerIdx3,
            timesMultiple
          };
        },
        ({ visitors, answerIdx1, answerIdx2, answerIdx3 }) =>
          visitors[answerIdx1] !== visitors[answerIdx2] &&
          visitors[answerIdx1] !== visitors[answerIdx3] &&
          visitors[answerIdx2] !== visitors[answerIdx3]
      );
    return {
      maxVisitors,
      visitors,
      scale,
      answerIdx1,
      answerIdx2,
      answerIdx3,
      timesMultiple
    };
  },
  Component: props => {
    const {
      question: { maxVisitors, visitors, scale, answerIdx1, answerIdx2, answerIdx3, timesMultiple },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const visitorsData = [
      {
        day: 'Monday',
        value: visitors[0]
      },
      {
        day: 'Tuesday',
        value: visitors[1]
      },
      {
        day: 'Wednesday',
        value: visitors[2]
      },
      {
        day: 'Thursday',
        value: visitors[3]
      },
      {
        day: 'Friday',
        value: visitors[4]
      }
    ];

    const day = visitorsData[answerIdx1].day;

    // Answer
    const ans = visitorsData[answerIdx1].value * timesMultiple;
    const incorrect1 = visitorsData[answerIdx2].value * timesMultiple;
    const incorrect2 = visitorsData[answerIdx3].value * timesMultiple + 1;

    const shuffledDays = shuffle([ans, incorrect1, incorrect2], {
      random: seededRandom(props.question)
    });

    return (
      <QF39ContentWithSelectablesOnRight<string>
        title={
          timesMultiple === 2
            ? translate.instructions.thereWereXAsManyVisitorsOnSaturdayAsYDaySelectHowManyPeopleVisitedSaturday(
                translate.misc.twice(),
                day
              )
            : translate.instructions.thereWereXTimesAsManyVisitorsOnSaturdayAsYDaySelectHowManyPeopleVisitedSaturday(
                timesMultiple,
                day
              )
        }
        pdfTitle={
          timesMultiple === 2
            ? translate.instructions.thereWereXAsManyVisitorsOnSaturdayAsYDayCircleHowManyPeopleVisitedSaturday(
                translate.misc.twice(),
                day
              )
            : translate.instructions.thereWereXTimesAsManyVisitorsOnSaturdayAsYDayCircleHowManyPeopleVisitedSaturday(
                timesMultiple,
                day
              )
        }
        selectables={{
          day1: shuffledDays[0].toLocaleString(),
          day2: shuffledDays[1].toLocaleString(),
          day3: shuffledDays[2].toLocaleString()
        }}
        correctAnswer={[
          shuffledDays[0] === ans ? 'day1' : shuffledDays[1] === ans ? 'day2' : 'day3'
        ]}
        leftContent={
          <MeasureView>
            {dimens => (
              <MultiBarChart
                width={dimens.width}
                height={dimens.height}
                barValues={visitorsData
                  .map(data => data.value)
                  .map((val, i) => ({ option: i, values: [val] }))}
                barLabels={[
                  translate.time.Mon(),
                  translate.time.Tue(),
                  translate.time.Wed(),
                  translate.time.Thu(),
                  translate.time.Fri()
                ]}
                barColors={colors.map(key => barColorNames[key])}
                snapToNearest="grid"
                yMax={maxVisitors}
                yStepSize={scale ? 1 : 2}
                xAxisLabel={translate.keywords.Day()}
                yAxisLabel={translate.misc.numberOfVisitors()}
                yAxisArrowLabel={null}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ay6',
  description: 'ay6',
  keywords: ['Pictogram', 'Key', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    numbers: z.array(z.number().min(0).max(10).step(0.25)).length(3),
    colours: z
      .array(z.enum(['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White']))
      .length(3),
    answerColourIndexes: z.array(z.number().int().min(0).max(2)),
    multiple: numberEnum([4, 8, 12])
  }),
  simpleGenerator: () => {
    const [number1, number2, number3] = countRange(3).map(() => randomIntegerInclusive(1, 40) / 4);
    const colours = getRandomSubArrayFromArray(
      ['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White'] as const,
      3
    );
    const answerColourIndexes = randomUniqueIntegersInclusive(0, 2, 2);
    const multiple = getRandomFromArray([4, 8, 12] as const);
    const numbers = [number1, number2, number3];

    return { numbers, colours, answerColourIndexes, multiple };
  },
  Component: props => {
    const {
      question: { numbers, colours, answerColourIndexes, multiple },
      translate
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    const [index1, index2] = answerColourIndexes;

    const nameColour1 = colorsAsWord(colours[index1], translate);
    const nameColour2 = colorsAsWord(colours[index2], translate);

    // Answer
    const sortedNumbers = [numbers[index1], numbers[index2]].sort((a, b) => b - a);
    const answer = number(
      math.evaluate(`(${sortedNumbers[0]} * ${multiple}) - (${sortedNumbers[1] * multiple})`)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheDifferenceBetweenXPointsScoredByColourYTeamAndColourZTeam(
          nameColour1,
          nameColour2
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentence={`<ans/>`}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <Pictogram
            displayValues={numbers}
            columnNames={[translate.tableHeaders.Team(), translate.tableHeaders.Points()]}
            rowData={[[colours[0]], [colours[1]], [colours[2]]]}
            dimens={dimens}
            color={color}
            keyValue={translate.misc.XPoints(multiple.toLocaleString())}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ay7',
  description: 'ay7',
  keywords: ['Pictogram', 'Key', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z.object({
    numbers: z.array(z.number().min(0).max(10).step(0.25)).length(3),
    colours: z
      .array(z.enum(['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White']))
      .length(3),
    multiple: numberEnum([4, 8, 12])
  }),
  simpleGenerator: () => {
    const [number1, number2, number3] = countRange(3).map(() => randomIntegerInclusive(1, 40) / 4);
    const numbers = [number1, number2, number3];
    const colours = getRandomSubArrayFromArray(
      ['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White'] as const,
      3
    );
    const multiple = getRandomFromArray([4, 8, 12] as const);

    return { numbers, colours, multiple };
  },
  Component: props => {
    const {
      question: { numbers, colours, multiple },
      translate
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    // Answer
    const answer = numbers.reduce((total, item) => total + item) * multiple;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsWereScoredInTotal()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentence={`<ans/>`}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <Pictogram
            displayValues={numbers}
            columnNames={[translate.tableHeaders.Team(), translate.tableHeaders.Points()]}
            rowData={[[colours[0]], [colours[1]], [colours[2]]]}
            dimens={dimens}
            color={color}
            keyValue={translate.misc.XPoints(multiple.toLocaleString())}
          />
        )}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'ay72',
  description: 'ay7',
  keywords: ['Pictogram', 'Key', 'Interpret', 'Comparison', 'Sum', 'Difference'],
  schema: z
    .object({
      numbers: z.array(z.number().min(0).max(10).step(0.25)).length(3),
      colours: z
        .array(z.enum(['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White']))
        .length(3),
      multiple: numberEnum([4, 8, 12])
    })
    .refine(val => {
      const maxSum = val.multiple === 4 ? 20 : val.multiple === 8 ? 12 : 10;
      return sumNumberArray(val.numbers.map(x => Math.ceil(x))) <= maxSum;
    }, 'must be less than 20,12,10 circles when multiple is 4,8,12 respectively'),
  simpleGenerator: () => {
    const multiple = getRandomFromArray([4, 8, 12] as const);
    const maxSum = multiple === 4 ? 20 : multiple === 8 ? 12 : 10;
    const [number1, number2, number3] = rejectionSample(
      () => countRange(3).map(() => randomIntegerInclusive(1, 40) / 4),
      val => sumNumberArray(val.map(x => Math.ceil(x))) <= maxSum
    );
    const numbers = [number1, number2, number3];
    const colours = getRandomSubArrayFromArray(
      ['Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Yellow', 'White'] as const,
      3
    );

    return { numbers, colours, multiple };
  },
  Component: props => {
    const {
      question: { numbers, colours, multiple },
      translate,
      displayMode
    } = props;

    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(props.question)
    }) as string;

    // Answer
    const answer = numbers.reduce((total, item) => total + item) * multiple;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsWereScoredInTotal()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentence={`<ans/>`}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <Pictogram
            displayValues={numbers}
            columnNames={[translate.tableHeaders.Team(), translate.tableHeaders.Points()]}
            rowData={[[colours[0]], [colours[1]], [colours[2]]]}
            dimens={
              displayMode === 'digital'
                ? dimens
                : { height: dimens.height, width: dimens.width * 0.5 }
            }
            color={color}
            keyValue={translate.misc.XPoints(multiple.toLocaleString())}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ComparisonSumAndDifference',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
