import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { z } from 'zod';
import { nestedArraysHaveSameContentsUnordered } from '../../../../utils/collections';
import {
  beforeAfterSchoolActivities,
  beforeAfterSchoolActivitiesNameSchema,
  getRandomUniqueBeforeAfterSchoolActivities
} from '../../../../utils/activities';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bfv',
  description: 'bfv',
  keywords: ['Before', 'After', 'Time'],
  schema: z.object({
    beforeAfterSchoolActivitiesNames: z.array(beforeAfterSchoolActivitiesNameSchema).length(3)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const beforeAfterSchoolActivitiesNames = getRandomUniqueBeforeAfterSchoolActivities(3);

    return {
      beforeAfterSchoolActivitiesNames
    };
  },
  Component: ({ question: { beforeAfterSchoolActivitiesNames }, translate }) => {
    const schoolActivities = beforeAfterSchoolActivitiesNames.map(
      activity => beforeAfterSchoolActivities(translate)[activity]
    );
    const beforeSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'Before')
      .map(activity => activity.activity);
    const afterSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'After')
      .map(activity => activity.activity);
    const eitherSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'Either')
      .map(activity => activity.activity);

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragCardsToSortTheActivities()}
        pdfTitle={translate.ks1PDFInstructions.sortTheActivitiesIntoTheTable()}
        zoneNames={[
          translate.ks1Instructions.beforeSchool(),
          translate.ks1Instructions.afterSchool()
        ]}
        items={schoolActivities.map(activity => activity.label)}
        itemVariant="rectangle"
        testCorrect={userAnswer => {
          const [beforeSchoolUserAns, afterSchoolUserAns] = userAnswer;

          const beforeOrEitherAns =
            nestedArraysHaveSameContentsUnordered(beforeSchoolUserAns, beforeSchoolActivities) ||
            nestedArraysHaveSameContentsUnordered(userAnswer, [
              ...beforeSchoolUserAns,
              ...eitherSchoolActivities
            ]);

          const afterOrEitherAns =
            nestedArraysHaveSameContentsUnordered(afterSchoolUserAns, afterSchoolActivities) ||
            nestedArraysHaveSameContentsUnordered(userAnswer, [
              ...afterSchoolActivities,
              ...eitherSchoolActivities
            ]);

          return beforeOrEitherAns || afterOrEitherAns;
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            [...beforeSchoolActivities, ...eitherSchoolActivities],
            afterSchoolActivities
          ],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'BeforeAndAfter',
  questionTypes: [Question2],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
