import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm12.25 4.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm-3.115-7.786C10.4 8.46 11.083 8 12 8c1.283 0 2 .835 2 1.5s-.717 1.5-2 1.5a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.113c1.632-.373 3-1.64 3-3.387C16 7.404 14.03 6 12 6c-1.543 0-2.975.775-3.636 2.036a1 1 0 1 0 1.771.928z"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
