import { TranslationFunctions } from 'common/src/i18n/i18n-types';
import { LocalizedString } from 'typesafe-i18n';
import { z } from 'zod';
import { getRandomSubArrayFromArray } from './random';

/** All activities. These all correspond to their key in the strings file. */
const ActivitiesArray = [
  'aSchoolDay',
  'boilAKettle',
  'blink',
  'brushYourTeeth',
  'buildAHouse',
  'clap',
  'cookDinner',
  'countBack',
  'decorateAHouse',
  'eatAGrape',
  'eatAnApple',
  'eatASweet',
  'flyFromBritainToUSA',
  'getDressed',
  'goToASwimmingLesson',
  'haveAShower',
  'jump3Times',
  'kickABall',
  'listenToASong',
  'makeASandwich',
  'nameCountries',
  'paintARoom',
  'putOnYourCoat',
  'readABook',
  'run5km',
  'runABath',
  'runAMarathon',
  'sleepAtNight',
  'snapAPhoto',
  'sneeze',
  'takeABreath',
  'tenStarJumps',
  'tieYourShoes',
  'travelToMoon',
  'travelToSchool',
  'watchAFilm',
  'watchATVShow',
  'washDryLaundry',
  'washLaundry',
  'writeAddress',
  'writeASentence',
  'writeAStory',
  'writeFirstName',
  'writeTheDate'
] as const;

export type ActivityKey = (typeof ActivitiesArray)[number];

/** Get the string translation of an activity. */
export function translateActivity(activity: ActivityKey, translate: TranslationFunctions) {
  return translate.activities[activity]();
}

type Duration = 'seconds' | 'minutes' | 'hours';

/** A subset of activities, with what _units_ you would normally measure the duration in. */
export const ActivityDurations = {
  aSchoolDay: ['hours'] as Duration[],
  brushYourTeeth: ['minutes'] as Duration[],
  eatAnApple: ['seconds', 'minutes'] as Duration[],
  eatASweet: ['seconds', 'minutes'] as Duration[],
  flyFromBritainToUSA: ['hours'] as Duration[],
  haveAShower: ['minutes'] as Duration[],
  jump3Times: ['seconds'] as Duration[],
  sleepAtNight: ['hours'] as Duration[],
  snapAPhoto: ['seconds'] as Duration[],
  takeABreath: ['seconds'] as Duration[],
  watchAFilm: ['minutes', 'hours'] as Duration[],
  writeAStory: ['minutes', 'hours'] as Duration[],
  writeTheDate: ['seconds'] as Duration[]
};

type ApproximateTime =
  | '1 second'
  | '10 seconds'
  | '30 seconds'
  | '1 minute'
  | '5 minutes'
  | '1 hour'
  | 'years';

export const ActivityApproximateTimeNew = {
  blink: '1 second',
  clap: '1 second',
  countBack: '1 minute',
  eatASweet: '1 minute',
  listenToASong: '5 minutes',
  sneeze: '1 second',
  tenStarJumps: '30 seconds',
  travelToMoon: 'years',
  washLaundry: '1 hour',
  watchATVShow: '1 hour',
  writeAddress: '1 minute',
  writeFirstName: '10 seconds'
};

export const ActivityApproximateTime = {
  blink: ['10 seconds'] as ApproximateTime[],
  clap: ['10 seconds'] as ApproximateTime[],
  countBack: ['1 minute'] as ApproximateTime[],
  tenStarJumps: ['1 minute'] as ApproximateTime[],
  eatASweet: ['1 minute'] as ApproximateTime[],
  getDressed: ['1 minute', '5 minutes'] as ApproximateTime[],
  listenToASong: ['5 minutes'] as ApproximateTime[],
  nameCountries: ['1 minute'] as ApproximateTime[],
  run5km: ['1 hour'] as ApproximateTime[],
  sneeze: ['10 seconds'] as ApproximateTime[],
  tieYourShoes: ['1 minute'] as ApproximateTime[],
  travelToSchool: ['1 hour'] as ApproximateTime[],
  travelToMoon: ['years'] as ApproximateTime[],
  washDryLaundry: ['1 hour'] as ApproximateTime[],
  watchATVShow: ['1 hour'] as ApproximateTime[],
  writeAddress: ['1 minute'] as ApproximateTime[],
  writeFirstName: ['10 seconds'] as ApproximateTime[]
};

/** Activities which have an answer sentences associated with them. */
export const AnswerSentenceActivitiesArray = [
  'shower',
  'sleep',
  'do star jumps',
  'watch TV',
  'do homework',
  'bake cookies',
  'write name',
  'zip coat',
  'time at school',
  'paint a bedroom'
] as const;

export type AnswerSentenceActivityKey = (typeof AnswerSentenceActivitiesArray)[number];

export function getAnswerSentenceActivityInfo(
  activity: AnswerSentenceActivityKey,
  name: string,
  translate: TranslationFunctions
): [answerSentence: LocalizedString, duration: Duration] {
  switch (activity) {
    case 'sleep':
      return [translate.answerSentences.characterSleepsFor8XEachNight(name), 'hours'];
    case 'time at school':
      return [translate.answerSentences.characterIsAtSchoolFor6XADay(name), 'hours'];
    case 'paint a bedroom':
      return [translate.answerSentences.characterTakes5XToPaintTheirBedroom(name), 'hours'];
    case 'shower':
      return [translate.answerSentences.itTakesChar5XToHaveAShower(name), 'minutes'];
    case 'watch TV':
      return [translate.answerSentences.characterWatchesTVFor30XADay(name), 'minutes'];
    case 'do homework':
      return [translate.answerSentences.characterSpends20XOnTheirHomework(name), 'minutes'];
    case 'bake cookies':
      return [translate.answerSentences.itTakesChar20XToBakeSomeCookies(name), 'minutes'];
    case 'do star jumps':
      return [translate.answerSentences.itTakesChar10XToDo5StarJumps(name), 'seconds'];
    case 'write name':
      return [translate.answerSentences.characterTakes10XToWriteTheirName(name), 'seconds'];
    case 'zip coat':
      return [translate.answerSentences.itTakesChar8XToZipUpTheirCoat(name), 'seconds'];
  }
}

/** Activities which have an instruction sentence associated with them. */
export const InstructionActivitiesArray = [
  'starts school',
  'eats breakfast',
  'walks to school',
  'cycles to school',
  'reads a bedtime story',
  'gets ready for bed',
  'goes to bed'
] as const;

export type InstructionActivityKey = (typeof InstructionActivitiesArray)[number];

/** Get instruction and time for an activity */
export function getInstructionActivityInfo(
  activity: InstructionActivityKey,
  name: string,
  translate: TranslationFunctions
): [instruction: LocalizedString, instructionPDF: LocalizedString, amOrPm: 'am' | 'pm'] {
  switch (activity) {
    case 'starts school':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharStartsSchool(name),
        translate.instructions.oneOfTheTimesIsWhenCharStartsSchoolPDF(name),
        'am'
      ];
    case 'eats breakfast':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharEatsBreakfast(name),
        translate.instructions.oneOfTheTimesIsWhenCharEatsBreakfastPDF(name),
        'am'
      ];
    case 'walks to school':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharWalksToSchool(name),
        translate.instructions.oneOfTheTimesIsWhenCharWalksToSchoolPDF(name),
        'am'
      ];
    case 'cycles to school':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharCyclesToSchool(name),
        translate.instructions.oneOfTheTimesIsWhenCharCyclesToSchoolPDF(name),
        'am'
      ];
    case 'reads a bedtime story':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharReadsABedtimeStory(name),
        translate.instructions.oneOfTheTimesIsWhenCharReadsABedtimeStoryPDF(name),
        'pm'
      ];
    case 'gets ready for bed':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharGetsReadyForBed(name),
        translate.instructions.oneOfTheTimesIsWhenCharGetsReadyForBedPDF(name),
        'pm'
      ];
    case 'goes to bed':
      return [
        translate.instructions.oneOfTheTimesIsWhenCharGoesToBed(name),
        translate.instructions.oneOfTheTimesIsWhenCharGoesToBedPDF(name),
        'pm'
      ];
  }
}

// beforeAfterSchoolActivities
export const beforeAfterSchoolActivitiesNames = [
  'wake up',
  'breakfast',
  'get dressed',
  'bedtime story',
  'go to a party',
  'go to sleep',
  'dinner',
  'brush teeth',
  'read a book',
  'play'
] as const;

export type BeforeAfterSchoolActivitiesName = (typeof beforeAfterSchoolActivitiesNames)[number];
export const beforeAfterSchoolActivitiesNameSchema = z.enum(beforeAfterSchoolActivitiesNames);

export const beforeAfterSchoolActivities = (translate: TranslationFunctions) => {
  return {
    ['wake up']: {
      activity: 'wake up',
      label: translate.beforeAfterschoolActivities.wakeUp(),
      beforeAfterEitherSchool: 'Before'
    },
    breakfast: {
      activity: 'breakfast',
      label: translate.beforeAfterschoolActivities.breakfast(),
      beforeAfterEitherSchool: 'Before'
    },
    ['get dressed']: {
      activity: 'get dressed',
      label: translate.beforeAfterschoolActivities.getDressed(),
      beforeAfterEitherSchool: 'Before'
    },
    ['bedtime story']: {
      activity: 'bedtime story',
      label: translate.beforeAfterschoolActivities.bedtimeStory(),
      beforeAfterEitherSchool: 'After'
    },
    ['go to a party']: {
      activity: 'go to a party',
      label: translate.beforeAfterschoolActivities.goToAParty(),
      beforeAfterEitherSchool: 'After'
    },
    ['go to sleep']: {
      activity: 'go to sleep',
      label: translate.beforeAfterschoolActivities.goToSleep(),
      beforeAfterEitherSchool: 'After'
    },
    dinner: {
      activity: 'dinner',
      label: translate.beforeAfterschoolActivities.dinner(),
      beforeAfterEitherSchool: 'After'
    },
    ['brush teeth']: {
      activity: 'brush teeth',
      label: translate.beforeAfterschoolActivities.brushTeeth(),
      beforeAfterEitherSchool: 'Either'
    },
    ['read a book']: {
      activity: 'read a book',
      label: translate.beforeAfterschoolActivities.readABook(),
      beforeAfterEitherSchool: 'Either'
    },
    play: {
      activity: 'play',
      label: translate.beforeAfterschoolActivities.play(),
      beforeAfterEitherSchool: 'Either'
    }
  };
};

export function getRandomUniqueBeforeAfterSchoolActivities(
  numberOfBeforeAfterSchoolActivitiesNames: number
) {
  return getRandomSubArrayFromArray(
    beforeAfterSchoolActivitiesNames,
    numberOfBeforeAfterSchoolActivitiesNames
  );
}
