import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContents, countRange } from '../../../../utils/collections';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF41MovableLinesForCreatingAngles from '../../../../components/question/questionFormats/QF41MovableLinesForCreatingAngles';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayg',
  description: 'ayg',
  keywords: ['Turns', 'Angles'],
  schema: z.object({
    sentenceIndex: z.number().int().min(0).max(2)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const sentenceIndex = randomIntegerInclusive(0, 2);

    return { sentenceIndex };
  },
  Component: props => {
    const {
      question: { sentenceIndex },
      translate
    } = props;

    const quarter = translate.fractions.quarters(1);
    const half = translate.fractions.halves(1);
    const greater = translate.misc.greater();
    const less = translate.misc.less();

    const answerOptions = shuffle([quarter, half, greater, less], {
      random: seededRandom(props.question)
    });

    const sentence = [
      { sentence: translate.answerSentences.aRightAngleIsTheSameAsAnsTurn(), answer: [[quarter]] },
      {
        sentence: translate.answerSentences.anAcuteAngleIsAnsThanAnsTurn(),
        answer: [[less, quarter]]
      },
      {
        sentence: translate.answerSentences.anObtuseAngleIsAnsThanAnsButAnsThanAnsTurn(),
        answer: [
          [less, half, greater, quarter],
          [greater, quarter, less, half]
        ]
      }
    ][sentenceIndex];

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={answerOptions}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        pdfItemVariant="tallRectangle"
        sentence={sentence.sentence}
        testCorrect={userAnswer =>
          sentence.answer.some(val => arraysHaveSameContents(userAnswer, val))
        }
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: [sentence.answer[0]],
          answerText: translate.markScheme.orAnyOtherValidAnswerFromAvailCards()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayh',
  description: 'ayh',
  keywords: ['Angles'],
  schema: z.object({
    startingAngles: z.number().int().min(0).max(359).array().length(3),
    angles: z.number().int().min(10).max(170).array().length(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const startingAngles = countRange(3).map(() => randomIntegerInclusive(0, 359));
    const angles = shuffle([90, randomIntegerInclusive(10, 80), randomIntegerInclusive(100, 170)]);

    return { startingAngles, angles };
  },
  Component: ({ question: { startingAngles, angles }, translate, displayMode }) => {
    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 150, width: 180 } : { height: 200, width: 200 };

    const rightAngle = translate.angles.rightAngle();
    const acuteAngle = translate.angles.acuteAngle();
    const obtuseAngle = translate.angles.obtuseAngle();

    const statements = angles.map((val, i) => ({
      lhsComponent: (
        <View
          style={{
            height: dimens.height,
            width: dimens.width,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <AngleFromLines
            degrees={[startingAngles[i], startingAngles[i] + angles[i]]}
            dimens={dimens}
            strokeWidth={3}
          />
        </View>
      ),
      correctAnswer: val < 90 ? acuteAngle : val === 90 ? rightAngle : obtuseAngle
    }));

    return (
      <QF6DragMatchStatements
        pdfTitle={translate.instructions.useCardsToMatchLabelsToAngles()}
        title={translate.instructions.dragCardsToMatchLabelsToAngles()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={[acuteAngle, rightAngle, obtuseAngle]}
        actionPanelVariant="endWide"
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayi',
  description: 'ayi',
  keywords: ['Angles', 'Acute'],
  schema: z.object({
    angles: z.number().int().min(10).max(180).array().length(4),
    startingPosition: z.number().int().min(0).max(359).array().length(4)
  }),
  simpleGenerator: () => {
    const acuteAngles = randomUniqueIntegersInclusive(10, 80, 2);
    const obtuseAngles = randomUniqueIntegersInclusive(90, 180, 2);

    const angles = shuffle([...acuteAngles, ...obtuseAngles]);

    const startingPosition = countRange(4).map(() => randomIntegerInclusive(0, 359));

    return { angles, startingPosition };
  },
  Component: props => {
    const {
      question: { angles, startingPosition },
      translate,
      displayMode
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllAcuteAngles()}
        pdfTitle={translate.instructions.circleAllAcuteAngles()}
        testCorrect={angles.filter(i => i < 90)}
        multiSelect
        numItems={4}
        renderItems={({ dimens }) =>
          angles.map((val, i) => ({
            value: val,
            component: (
              <AngleFromLines
                degrees={[startingPosition[i], startingPosition[i] + val]}
                dimens={dimens}
                strokeWidth={displayMode === 'digital' ? 4 : 6}
              />
            )
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ayj',
  description: 'ayj',
  keywords: ['Angles', 'Obtuse'],
  schema: z.object({
    angles: z.number().int().min(10).max(170).array().length(4),
    startingPosition: z.number().int().min(0).max(359).array().length(4)
  }),
  simpleGenerator: () => {
    const acuteAngles = randomUniqueIntegersInclusive(10, 90, 2);
    const obtuseAngles = randomUniqueIntegersInclusive(100, 170, 2);

    const angles = shuffle([...acuteAngles, ...obtuseAngles]);

    const startingPosition = countRange(4).map(() => randomIntegerInclusive(0, 359));

    return { angles, startingPosition };
  },
  Component: props => {
    const {
      question: { angles, startingPosition },
      translate,
      displayMode
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllObtuseAngles()}
        pdfTitle={translate.instructions.circleAllObtuseAngles()}
        testCorrect={angles.filter(i => i > 90)}
        multiSelect
        numItems={4}
        renderItems={({ dimens }) =>
          angles.map((val, i) => ({
            value: val,
            component: (
              <AngleFromLines
                degrees={[startingPosition[i], startingPosition[i] + val]}
                dimens={dimens}
                strokeWidth={displayMode === 'digital' ? 4 : 6}
              />
            )
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ayk',
  description: 'ayk',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    angle: z.number().int().min(10).max(170),
    startingAngle: z.number().int().min(0).max(359)
  }),
  simpleGenerator: () => {
    const angleSize = getRandomFromArray(['obtuseAngle', 'acuteAngle', 'rightAngle'] as const);
    let angle;
    switch (angleSize) {
      case 'obtuseAngle':
        angle = randomIntegerInclusive(100, 170);
        break;
      case 'acuteAngle':
        angle = randomIntegerInclusive(10, 80);
        break;
      case 'rightAngle':
        angle = 90;
        break;
    }

    const startingAngle = randomIntegerInclusive(0, 359);

    return { angle, startingAngle };
  },
  Component: props => {
    const {
      question: { angle, startingAngle },
      translate
    } = props;

    const answer = angle < 90 ? 'acuteAngle' : angle === 90 ? 'rightAngle' : 'obtuseAngle';

    const options = ['acuteAngle', 'rightAngle', 'obtuseAngle'];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectWhetherAngleIsAcuteObtuseRightAngle()}
        pdfTitle={translate.instructions.circleWhetherAngleIsAcuteObtuseRightAngle()}
        testCorrect={[answer]}
        numItems={3}
        Content={({ dimens }) => (
          <AngleFromLines
            degrees={[startingAngle, startingAngle + angle]}
            dimens={{ height: dimens.height * 0.9, width: dimens.width }}
          />
        )}
        renderItems={options.map(value => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {translate.angles[value as 'acuteAngle' | 'rightAngle' | 'obtuseAngle']()}
            </Text>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ayl',
  description: 'ayl',
  keywords: ['Angles', 'Acute', 'Obtuse'],
  schema: z.object({
    isObtuse: z.boolean()
  }),
  simpleGenerator: () => {
    const isObtuse = getRandomBoolean();

    return { isObtuse };
  },
  Component: ({ question: { isObtuse }, translate }) => {
    const exampleAnswer = (isObtuse ? [5, 130] : [15, 70]) as [number, number];
    const markSchemeText = isObtuse
      ? translate.markScheme.anyObtuseAngle()
      : translate.markScheme.anyAcuteAngle();

    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeXAngle(
          isObtuse ? translate.keywords.Obtuse() : translate.keywords.Acute()
        )}
        pdfTitle={translate.instructions.drawAnXAngle(
          isObtuse ? translate.keywords.Obtuse() : translate.keywords.Acute()
        )}
        testCorrect={angle => (isObtuse ? angle > 90 && angle < 180 : angle >= 1 && angle < 90)}
        startAngles={[0, 0]}
        markScheme={{ exampleCorrectAnswer: exampleAnswer, notes: markSchemeText }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IdentifyAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
