import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { View } from 'react-native';
import { z } from 'zod';
import Text from 'common/src/components/typography/Text';
import { compareFloats } from 'common/src/utils/math';
import { isValidMoneyAnswer, numToCurrency } from 'common/src/utils/money';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from 'common/src/utils/zod';
import { fruitsSentenceSchema, getRandomUniqueFruitsSentence } from 'common/src/utils/fruits';
import { getRandomName, getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getShopItemImage, shopItemSchema } from 'common/src/utils/items';
import { arrayHasNoDuplicates, sumNumberArray } from 'common/src/utils/collections';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axA',
  description: 'axA',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z
    .object({
      fruits: z
        .array(fruitsSentenceSchema)
        .length(2)
        .refine(arrayHasNoDuplicates, 'fruits must be different'),
      penceValues: z.array(z.number().int().min(1).max(99)).length(2),
      pounds: numberEnum([1, 2]),
      name: nameSchema
    })
    .refine(
      ({ penceValues: [a, b], pounds }) => a + b < pounds * 100,
      'there must be change left over, i.e. pounds must be more than the total of penceValues'
    ),
  simpleGenerator: () => {
    const fruits = getRandomUniqueFruitsSentence(2);
    const penceValues = randomUniqueIntegersInclusive(21, 49, 2, { constraint: x => x % 5 !== 0 });
    const pounds = getRandomFromArray([1, 2] as const);
    const name = getRandomName();

    return { fruits, penceValues, pounds, name };
  },
  Component: props => {
    const {
      question: {
        fruits: [fruitA, fruitB],
        penceValues: [penceA, penceB],
        pounds,
        name
      },
      translate
    } = props;

    const ans = math.evaluate(`(${pounds} * 100 - (${penceA} + ${penceB})) / 100`);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterBuysFruitXandFruitYForXAndYPenceCharacterPaysWithXPoundsHowMuchChangeDoesCharacterGet(
          {
            name,
            fruit1: fruitA,
            fruit2: fruitB,
            penceA,
            penceB,
            pounds
          }
        )}
        inputMaxCharacters={4}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        extraSymbol="decimalPoint"
        sentence={'£<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{ answersToDisplay: [ans.toFixed(2)] }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axB',
  description: 'axB',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z
    .object({
      childTicketPounds: z.number().min(0.1).multipleOf(0.01),
      adultTicketPounds: z.number().min(0.1).multipleOf(0.01)
    })
    .refine(
      ({ childTicketPounds, adultTicketPounds }) => childTicketPounds < adultTicketPounds,
      'child ticket must be cheaper'
    ),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(2, 4);
    const penceA = randomIntegerInclusive(5, 50, {
      constraint: x => x % 5 === 0
    });
    const poundsB = randomIntegerInclusive(6, 9);
    const penceB = randomIntegerInclusive(55, 95, {
      constraint: x => x % 5 === 0
    });

    return {
      childTicketPounds: poundsA + penceA * 0.01,
      adultTicketPounds: poundsB + penceB * 0.01
    };
  },
  Component: props => {
    const {
      question: { childTicketPounds, adultTicketPounds },
      translate
    } = props;

    // Pounds and pence sentences display
    const poundsAToDisplay = numToCurrency({ amount: childTicketPounds });
    const poundsBToDisplay = numToCurrency({ amount: adultTicketPounds });

    // Answer
    const ans = math.evaluate(`${adultTicketPounds} - ${childTicketPounds}`);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aChildsCinemaTicketCostsXPoundsAndPenceAndAnAdultsTicketCostsAPoundsAndPenceHowMuchMoreDoesTheAdultsTicketCost(
          {
            poundsA: poundsAToDisplay,
            poundsB: poundsBToDisplay
          }
        )}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        extraSymbol="decimalPoint"
        sentence={'£<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{ answersToDisplay: [ans.toFixed(2)] }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axC',
  description: 'axC',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z
    .object({
      poundsA: z.number().min(0.1).multipleOf(0.01),
      poundsB: z.number().min(0.1).multipleOf(0.01)
    })
    .refine(({ poundsA, poundsB }) => poundsA < poundsB, 'poundsA must be less than poundsB'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(15, 24);
    const penceA = randomIntegerInclusive(51, 99, {
      constraint: x => x % 5 !== 0
    });
    const poundsB = randomIntegerInclusive(21, 49, {
      constraint: x => x > poundsA
    });
    const penceB = randomIntegerInclusive(1, 49, {
      constraint: x => x % 5 !== 0
    });

    return {
      poundsA: poundsA + penceA * 0.01,
      poundsB: poundsB + penceB * 0.01
    };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB },
      translate
    } = props;

    // Pounds and pence sentences display
    const poundsAToDisplay = numToCurrency({ amount: poundsA });
    const poundsBToDisplay = numToCurrency({ amount: poundsB });

    // Answers
    const ans = math.evaluate(`${poundsB} - ${poundsA}`);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        inputMaxCharacters={5}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        extraSymbol="decimalPoint"
        actionPanelVariant="bottomTall"
        sentence={translate.answerSentences.theDifferenceBetweenXPoundsAndYPoundsIs({
          poundsA: poundsAToDisplay,
          poundsB: poundsBToDisplay
        })}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        customMarkSchemeAnswer={{ answersToDisplay: [ans.toFixed(2)] }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'axD',
  description: 'axD',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Subtract'],
  schema: z.object({
    shopItems: z
      .array(z.object({ name: shopItemSchema, pounds: z.number().multipleOf(0.01).min(0.01) }))
      .length(4)
      .refine(
        shopItems => arrayHasNoDuplicates(shopItems.map(item => item.name)),
        'No duplicates allowed'
      ),
    /** Indices showing which items of the shopItems array were purchased. */
    purchasedItems: z
      .array(z.number().int().min(0).max(3))
      .length(3)
      .refine(arrayHasNoDuplicates, 'no duplicates allowed'),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const allShopItems = [
      {
        name: 'a book' as const,
        pounds: randomIntegerInclusive(3, 6) + randomIntegerInclusiveStep(10, 90, 10) * 0.01
      },
      {
        name: 'a cap' as const,
        pounds: randomIntegerInclusive(5, 9) + randomIntegerInclusiveStep(10, 90, 10) * 0.01
      },
      {
        name: 'headphones' as const,
        pounds: randomIntegerInclusive(15, 21) + 0.99
      },
      {
        name: 'a box of marbles' as const,
        pounds: randomIntegerInclusive(2, 3) + randomIntegerInclusiveStep(10, 90, 10) * 0.01
      },
      {
        name: 'a pack of crayons' as const,
        pounds: randomIntegerInclusive(2, 3) + randomIntegerInclusiveStep(10, 90, 10) * 0.01
      },
      {
        name: 'a magazine' as const,
        pounds: randomIntegerInclusive(2, 3) + randomIntegerInclusiveStep(10, 90, 10) * 0.01
      }
    ];

    // Get 4 random shop items and 3 of those which are purchased
    const shopItems = getRandomSubArrayFromArray(allShopItems, 4);
    const purchasedItems = randomUniqueIntegersInclusive(0, 3, 3);
    const name = getRandomName();

    return {
      shopItems,
      purchasedItems,
      name
    };
  },
  Component: props => {
    const {
      question: { shopItems, purchasedItems, name },
      translate,
      displayMode
    } = props;

    // Answer
    const ans = 50 - sumNumberArray(purchasedItems.map(index => shopItems[index].pounds));

    return (
      <QF1ContentAndSentence
        sentence={'£<ans/>'}
        title={translate.instructions.aShopSellsTheseItems()}
        testCorrect={answer => compareFloats(answer[0], ans) && isValidMoneyAnswer(answer[0])}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={{
                flexDirection: 'row',
                width: dimens.width,
                justifyContent: 'space-evenly'
              }}
            >
              {shopItems.map(item => (
                <View key={item.name} style={{ alignItems: 'center' }}>
                  {getShopItemImage(
                    item.name,
                    displayMode === 'digital' ? 100 : 150,
                    displayMode === 'digital' ? 100 : 150
                  )}
                  <Text
                    variant="WRN400"
                    style={{ fontSize: displayMode === 'digital' ? 32 : 50, textAlign: 'center' }}
                  >
                    {numToCurrency({ amount: item.pounds })}
                  </Text>
                </View>
              ))}
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.characterBuysXYZItems({
                name,
                itemA: translate.items[shopItems[purchasedItems[0]].name](),
                itemB: translate.items[shopItems[purchasedItems[1]].name](),
                itemC: translate.items[shopItems[purchasedItems[2]].name]()
              })}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.characterPaysWithAFiftyPoundNote({ name })}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.HowMuchChangeDoesCharacterGet({ name })}
            </Text>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString(undefined, { minimumFractionDigits: 2 })]
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'axE',
  description: 'axE',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Add', 'Divide'],
  schema: z
    .object({
      totalPounds: z.number().int().min(3).max(36).multipleOf(3),
      totalPence: numberEnum([
        3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 45, 60, 63, 66, 69, 75, 90, 93, 96, 99
      ]),
      amountAPence: z.number().int().min(50).max(3599),
      amountBPence: z.number().int().min(50).max(3599),
      names: z
        .array(nameSchema)
        .length(3)
        .refine(arrayHasNoDuplicates, 'All names must be different.')
    })
    .refine(
      val => val.amountAPence + val.amountBPence <= val.totalPounds * 100 + val.totalPence - 50,
      'amountAPence + amountBPence must be less than or equal to ((totalPounds x 100) + totalPence) - 50'
    ),
  simpleGenerator: () => {
    const totalPounds = randomIntegerInclusiveStep(3, 36, 3);

    const totalPence = getRandomFromArray([
      3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 45, 60, 63, 66, 69, 75, 90, 93, 96, 99
    ] as const);

    const totalMoneyInPence = totalPounds * 100 + totalPence;

    const amountAPence = randomIntegerInclusive(50, totalMoneyInPence - 100);

    const amountBPence = randomIntegerInclusive(50, totalMoneyInPence - amountAPence - 50);

    const names = getRandomUniqueNames(3);

    return { totalPounds, totalPence, amountAPence, amountBPence, names };
  },
  Component: props => {
    const {
      question: { totalPounds, totalPence, amountAPence, amountBPence, names },
      translate
    } = props;
    const amountCPence = totalPounds * 100 + totalPence - amountAPence - amountBPence;

    const amountAString = numToCurrency({
      amount: number(math.evaluate(`${amountAPence} / 100`))
    });

    const amountBString = numToCurrency({
      amount: number(math.evaluate(`${amountBPence} / 100`))
    });

    const amountCString = numToCurrency({
      amount: number(math.evaluate(`${amountCPence} / 100`))
    });

    const amountPerPerson = number(math.evaluate(`(${totalPounds} + (${totalPence} / 100)) / 3`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterAHasXCharacterBHasYCharacterCHasZPTheyPutTheirMoneyTogetherThenShare(
          {
            nameA: names[0],
            poundsA: amountAString,
            nameB: names[1],
            poundsB: amountBString,
            nameC: names[2],
            poundsC: amountCString
          }
        )}
        inputMaxCharacters={5}
        testCorrect={answer =>
          compareFloats(answer[0], amountPerPerson) && isValidMoneyAnswer(answer[0])
        }
        extraSymbol="decimalPoint"
        sentence={'£<ans />'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            amountPerPerson.toLocaleString(undefined, { minimumFractionDigits: 2 })
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axF',
  description: 'axF',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Divide', 'Remainder'],
  schema: z.object({
    sweetPricePence: z.number().int().min(3).max(9),
    characterMoneyPence: z.number().int().min(81).max(99),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const sweetPricePence = randomIntegerInclusive(3, 9);
    const characterMoneyPence = randomIntegerInclusive(81, 99);

    const name = getRandomName();

    return { sweetPricePence, characterMoneyPence, name };
  },
  Component: props => {
    const {
      question: { sweetPricePence, characterMoneyPence, name },
      translate
    } = props;

    // Answers
    const answer1 = Math.floor(characterMoneyPence / sweetPricePence);
    const answer2 = characterMoneyPence - answer1 * sweetPricePence;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterIsBuyingSweetsThatCostXPenceEach({
          name,
          pence: sweetPricePence
        })}
        inputMaxCharacters={3}
        testCorrect={[answer1.toString(), answer2.toString()]}
        sentence={`${translate.answerSentences.characterHasXPenceHowManySweetsCanCharacterBuy({
          name,
          pence: characterMoneyPence
        })}<br/>${translate.answerSentences.howMuchMoneyDoesCharacterHaveLeftAns({
          name
        })}`}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'axF2',
  description: 'axF',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Divide', 'Remainder'],
  schema: z.object({
    sweetPricePence: z.number().int().min(3).max(9),
    characterMoneyPence: z.number().int().min(81).max(99),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const sweetPricePence = randomIntegerInclusive(3, 9);
    const characterMoneyPence = randomIntegerInclusive(81, 99);

    const name = getRandomName();

    return { sweetPricePence, characterMoneyPence, name };
  },
  Component: props => {
    const {
      question: { sweetPricePence, characterMoneyPence, name },
      translate,
      displayMode
    } = props;

    // Answers
    const answer1 = Math.floor(characterMoneyPence / sweetPricePence);
    const answer2 = characterMoneyPence - answer1 * sweetPricePence;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterIsBuyingSweetsThatCostXPenceEachCharHasYPence({
          name,
          pence: sweetPricePence,
          totalPence: characterMoneyPence
        })}
        mainPanelStyle={{ alignItems: 'flex-start', flexDirection: 'row' }}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                alignSelf: 'flex-start',
                width: dimens.width,
                height: dimens.height * 0.9,
                justifyContent: 'space-around'
              }}
            >
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.answerSentences.howManySweetsCanCharacterBuy({
                  name
                })}
              </Text>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.answerSentences.howMuchMoneyDoesCharacterHaveLeftAns({
                  name
                })}
              </Text>
            </View>
          );
        }}
        inputMaxCharacters={2}
        sentences={['<ans/>', '<ans/>p']}
        pdfDirection="row"
        pdfSentenceStyle={{ flexDirection: 'column', justifyContent: 'space-around' }}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithMoney',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
