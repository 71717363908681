// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import AddFractions from './1AddFractions';
import SubtractFractions from './2SubtractFractions';
import PartitionTheWhole from './3PartitionTheWhole';
import UnitFractionsOfASetOfObjects from './4UnitFractionsOfASetOfObjects';
import NonUnitFractionsOfASetOfObjects from './5NonUnitFractionsOfASetOfObjects';
import ReasoningWithFractionsOfAnAmount from './6ReasoningWithFractionsOfAnAmount';

const Block = newBlockContent({
  block: 'FractionsB',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    AddFractions, // Step 1
    SubtractFractions, // Step 2
    PartitionTheWhole, // Step 3
    UnitFractionsOfASetOfObjects, // Step 4
    NonUnitFractionsOfASetOfObjects, // Step 5
    ReasoningWithFractionsOfAnAmount // Step 6
  ]
});
export default Block;
