import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { G, Line, Path } from 'react-native-svg';
import { Dimens } from 'common/src/theme/scaling';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { AssetSvg } from 'common/src/assets/svg';
import { calcEllipseArcPath, drawArrowHead } from './curve';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import { parseMarkup } from '../../../../markup';
import TextStructure from '../../../molecules/TextStructure';
import { DisplayMode } from '../../../../contexts/displayMode';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { SetState } from '../../../../utils/react';

type Props = {
  start: number;
  end: number;
  /**
   * @param tickValues An array of objects to hold the labels that go
   * on the number line and the position relative to the start.
   * Tick value labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> tickValues should return an array of number strings
   */
  tickValues: { label: string; position: number }[];
  /**
   * @param dimens Usable dimensions for the question
   */
  dimens: Dimens;
  /**
   * @param userAnswer State to hold the users input answers
   */
  userAnswer?: string[];
  /**
   * @param focusNumber The number to highlight with the arrow
   */
  focusNumber?: string;
  /**
   * @param jumpArrowArray An array of start an end points for a jump arrow, and the label string to display above the arrow
   * <ans/> is used to indicate where an answer box should be placed. '<ans/>' must be at the end of the string in this case.
   */
  jumpArrowArray?: { start: number; end: number; label: string }[];
  /** Override for font size, no font scaling will be used */
  customFontSize?: number;
  /**
   * Flag for whether number line is subtraction or not i.e. jump arrows reversed
   * Defaults to false.
   */
  subtraction?: boolean;
  /**
   * Flag to determine whether minimum spacing should be ignored or not. Needed for some Qs with awkwardly-placed answer boxes.
   * Defaults to undefined.
   */
  ignoreMinSpacing?: boolean;
  setUserAnswer?: SetState<string[]>;
};

/**
 * This component renders a number line manipulative that does not have uniform gaps between the ticks
 * The <ans/> string is used to annotate where a user answer input field is require.
 * This number line can also render arrows above that show the numerical gap between the ticks
 */
export const NumberLineVariableTick = ({
  start,
  end,
  tickValues,
  dimens: { width, height },
  userAnswer = [''],
  focusNumber = undefined,
  jumpArrowArray = [],
  subtraction = false,
  ignoreMinSpacing,
  customFontSize,
  setUserAnswer = () => {
    /* do nothing */
  }
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const strokeColor = isPdf ? colors.black : colors.prussianBlue;
  const strokeWidth = isPdf ? 4 : 2;

  // Parse all tick values. Each can have 0, 1 or more input boxes.
  const tickValuesParsed = tickValues.map(tick => parseMarkup(tick.label));
  const jumpArrowArrayParsed = jumpArrowArray.map(arrow => parseMarkup(arrow.label));

  // Flags for changing styling
  const tickShowFrac = tickValuesParsed.some(tick => tick.tokens.some(el => el.type === 'frac'));
  // Reduce the available width if mixed fraction with answer boxes at end of number line
  const tickShowMixedFracAns = tickValuesParsed[tickValues.length - 1].numberOfAns === 3;

  const pdfOffset = displayMode === 'digital' ? 0 : 100;
  const textVerticalPad = 8;
  const horizontalPad = 40;
  const lineWidth = tickShowMixedFracAns
    ? width - 50 - 2 * horizontalPad
    : width - 2 * horizontalPad;
  const tickHeight = 40;
  const centerLineY = tickShowFrac ? height / 1.8 - tickHeight : height / 1.6 - tickHeight;
  const tickSpacing = Math.abs(lineWidth / (end - start));
  const startingTickX = horizontalPad;
  const minimumSpacing = displayMode === 'digital' ? 146 : 256;

  // Arrow Head Params
  const arrowXOffset = displayMode === 'digital' ? 8 : 16;
  const arrowYOffset = displayMode === 'digital' ? 10 : 20;

  // Determine if minimum spacing can be ignored i.e. there are no answer boxes on ticks or on jump arrows
  const ignoreMinimumSpacing =
    ignoreMinSpacing ??
    tickValuesParsed.reduce((ansCount, tick) => tick.numberOfAns + ansCount, 0) +
      (jumpArrowArrayParsed?.reduce((ansCount, jumpArrow) => jumpArrow.numberOfAns + ansCount, 0) ??
        0) ===
      0;

  const styles = useMemo(() => getStyles(width, height), [width, height]);
  const fontSize =
    customFontSize ??
    scaleNumberLineFontSize(
      tickValues.map(val => val.label),
      lineWidth,
      displayMode
    );

  // Center line
  const centerLine = (
    <Line
      x1={horizontalPad}
      y1={centerLineY}
      x2={width - horizontalPad}
      y2={centerLineY}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      key={'CenterLine'}
    />
  );

  // Make the ticks and arrows a minimum of 128px apart to fit an answer box
  // Unless ignoreMinimumSpacing is set to true
  tickValues = ignoreMinimumSpacing
    ? tickValues
    : tickValues.map((tick, index) => {
        if (index !== 0 && index !== tickValues.length - 1) {
          const separation = Math.abs(tick.position - tickValues[index - 1].position) * tickSpacing;
          if (separation < minimumSpacing) {
            if (subtraction) {
              return {
                ...tick,
                position: tick.position - (minimumSpacing - separation) / tickSpacing
              };
            } else {
              return {
                ...tick,
                position: tick.position + (minimumSpacing - separation) / tickSpacing
              };
            }
          }
        }
        return tick;
      });

  let separatedArrowArray: { start: number; end: number; label: string }[] = [];
  // Splat current jumpArrowArray
  if (jumpArrowArray) {
    separatedArrowArray = [...jumpArrowArray];
  }

  // Ensure jump arrows account for minimum spacing, with respect to ignoreMinimumSpacing flag
  if (!ignoreMinimumSpacing) {
    jumpArrowArray?.forEach((arrow, index) => {
      if (index !== jumpArrowArray.length - 1) {
        const separation = Math.abs(arrow.end - arrow.start) * tickSpacing;
        if (separation < minimumSpacing) {
          // Move the end of this arrow and the start of the next arrow
          if (subtraction) {
            separatedArrowArray[index].end -= (minimumSpacing - separation) / tickSpacing;
            separatedArrowArray[index + 1].start -= (minimumSpacing - separation) / tickSpacing;
            separatedArrowArray[index].label = arrow.label;
          } else {
            separatedArrowArray[index].end += (minimumSpacing - separation) / tickSpacing;
            separatedArrowArray[index + 1].start += (minimumSpacing - separation) / tickSpacing;
            separatedArrowArray[index].label = arrow.label;
          }
        }
      }
    });
  }

  // Ticks
  const ticks = tickValues.map((tick, index) => {
    const tickX = startingTickX + (tick.position - start) * tickSpacing;
    return (
      <Line
        x1={tickX}
        y1={centerLineY - tickHeight / 2}
        x2={tickX}
        y2={centerLineY + tickHeight / 2}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        key={'tick' + index}
      />
    );
  });

  let ansIndex = -1;
  const jumpArrowLines = separatedArrowArray.map((jumpArrow, index) => {
    const jumpAmount = jumpArrow.end - jumpArrow.start;
    const curveRadiusX = (jumpAmount * tickSpacing) / 2;
    const startCurve = startingTickX + (jumpArrow.start - start) * tickSpacing + curveRadiusX;

    const curveRadiusY = ((end - start) * tickSpacing) / 10;

    return (
      <G key={'arrow' + index}>
        <Path
          d={calcEllipseArcPath(
            startCurve,
            centerLineY - 4 - tickHeight / 2,
            curveRadiusX * (subtraction ? -1 : 1),
            curveRadiusY
          )}
          fill="none"
          stroke={colors.burntSienna}
          strokeWidth={displayMode === 'digital' ? 3 : 6}
        />
        <Path
          d={drawArrowHead(
            startCurve + curveRadiusX,
            centerLineY - 4 - tickHeight / 2,
            arrowXOffset,
            arrowYOffset
          )}
          fill={colors.burntSienna}
          stroke={colors.burntSienna}
        />
      </G>
    );
  });

  // Add the labels to the jump arrows.
  // Some of these may contain answer input boxes
  const jumpArrowNumbers = jumpArrowArray.map((jumpArrow, index) => {
    const jumpAmount = jumpArrow.end - jumpArrow.start;
    const curveRadiusX = ((jumpAmount * tickSpacing) / 2) * (subtraction ? -1 : 1);
    const startCurve = startingTickX + (jumpArrow.start - start) * tickSpacing;

    const curveRadiusY = ((end - start) * tickSpacing) / 10;

    const top = centerLineY - tickHeight - textVerticalPad - curveRadiusY;

    const ansCount = jumpArrowArrayParsed[index].numberOfAns;

    // Check if jump arrow wants to show a fraction
    const jumpArrowShowFrac = jumpArrowArrayParsed[index].tokens.some(
      token => token.type === 'frac'
    );

    ansIndex += ansCount;

    const i = ansIndex;

    const topPositioning =
      ansCount > 0 ? top - 70 - textVerticalPad * 2 - pdfOffset : top - textVerticalPad * 8;

    const fracTopPosition = jumpArrowShowFrac
      ? ansCount > 0
        ? top - 186 - textVerticalPad * 2 - pdfOffset
        : top - 100 - textVerticalPad * 2 - pdfOffset
      : top - 70 - textVerticalPad * 2 - pdfOffset;

    return (
      <View
        style={{
          position: 'absolute',
          top: jumpArrowShowFrac ? fracTopPosition : topPositioning,
          left: subtraction ? startCurve - curveRadiusX * 2 : startCurve - 10,
          width: isPdf ? curveRadiusX * 2 + 50 : curveRadiusX * 2,
          minWidth: ignoreMinimumSpacing ? undefined : 96 + 20 + 27,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        key={'jumpLabel' + index}
      >
        <TextStructure
          sentence={jumpArrow.label}
          inputBox={({ index }) => {
            return (
              <NoKeyboardTextInput
                key={i - ansCount + (index + 1)}
                value={userAnswer[i - ansCount + (index + 1)]}
                onChangeText={answer => {
                  const newArr = [...userAnswer!];
                  newArr[i - ansCount + (index + 1)] = answer;
                  setUserAnswer(newArr);
                }}
                style={{ width: 96, fontSize }}
              />
            );
          }}
          textStyle={{ fontSize }}
          style={{ columnGap: ansCount > 0 ? 16 : 0, flexWrap: 'nowrap' }}
        />
      </View>
    );
  });

  // Numbers
  const numberComponents = tickValues.map((tick, index) => {
    const tickX = startingTickX + (tick.position - start) * tickSpacing;
    const ansCount = tickValuesParsed[index].numberOfAns;

    // Add number of answers for this tick to index
    ansIndex += ansCount;

    // Create scoped copy, otherwise all answer indices will equal final index
    const i = ansIndex;

    return (
      <View
        key={'tickNum' + index}
        style={{
          left: tickX - (isPdf ? 90 : 60),
          top: centerLineY + tickHeight / 2 + textVerticalPad,
          position: 'absolute',
          justifyContent: 'center',
          width: isPdf ? 180 : 120
        }}
      >
        <TextStructure
          sentence={tick.label}
          inputBox={({ index }) => {
            return (
              <NoKeyboardTextInput
                key={i - ansCount + (index + 1)}
                value={userAnswer[i - ansCount + (index + 1)]}
                onChangeText={answer => {
                  const newArr = [...userAnswer!];
                  newArr[i - ansCount + (index + 1)] = answer;
                  setUserAnswer(newArr);
                }}
                style={{ fontSize }}
              />
            );
          }}
          textStyle={{ textAlign: 'center', fontSize }}
          style={{ justifyContent: 'center' }}
        />
        {focusNumber === tick.label && <AssetSvg name="ArrowUp" width={60} height={100} />}
      </View>
    );
  }, ansIndex);

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ position: 'absolute', top: 0, left: 0 }}
        pointerEvents={'none'}
      >
        {centerLine}
        {jumpArrowLines}
        {ticks}
      </Svg>
      {numberComponents}
      {jumpArrowNumbers}
    </View>
  );
};

/** See {@link NumberLineVariableTick}. */
export const NumberLineVariableTickWithState = withStateHOC(NumberLineVariableTick, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    // default state is an empty string array of the length of how many ans boxes there are.
    defaultState: filledArray('', getAnswerCount(props)),
    testComplete: state => (state ? state.every(it => it !== '') : true)
  })
});
// Create initialState

const getAnswerCount = (props: Omit<Props, 'inputBox'>) => {
  const { tickValues, jumpArrowArray } = props;
  return (
    tickValues
      .map(tick => parseMarkup(tick.label).numberOfAns)
      .reduce((sum, numberOfAns) => sum + numberOfAns) +
    (jumpArrowArray
      ?.map(jumpArrow => parseMarkup(jumpArrow.label).numberOfAns)
      .reduce((sum, numberOfAns) => sum + numberOfAns) ?? 0)
  );
};

const getStyles = (width: number, height: number) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height
    }
  });
