import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getDimenShapeSvgName,
  getRegularShapeWithAllSidesMarkedColouredSvgName,
  getShapeSvgName,
  getShapeSvgNameByColor,
  regularShapes
} from '../../../../utils/shapeImages/shapes';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { get3DShapeSvgName } from '../../../../utils/threeDShapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  sortNumberArray
} from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ADD } from '../../../../constants';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import { LabelledPentagon } from '../../../../components/question/representations/LabelledPentagon';
import { LabelledHexagon } from '../../../../components/question/representations/LabelledHexagon';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const irregularShapes = [
  'isoscelesTriangles',
  'scaleneTriangles',
  'rectangles',
  'cubes',
  'cuboids'
] as const;

const shapeSides = {
  equilateralTriangles: 3,
  triangles: 3,
  squares: 4,
  pentagons: 5,
  hexagons: 6,
  heptagons: 7,
  octagons: 8,
  nonagons: 9,
  decagons: 10
};

const Question1 = newQuestionContent({
  uid: 'aLU',
  description: 'aLU',
  keywords: ['Regular', 'Polygons'],
  schema: z.object({
    regularShapeNames: z.array(z.enum(regularShapes)),
    irregularShapeNames: z.array(z.enum(irregularShapes)),
    colors: z.array(z.enum(['blue', 'green', 'purple', 'yellow'])).length(4)
  }),
  simpleGenerator: () => {
    const correctAnswers = randomIntegerInclusive(2, 3);
    const regularShapeNames = getRandomSubArrayFromArray(regularShapes, correctAnswers);
    const irregularShapeNames = getRandomSubArrayFromArray(irregularShapes, 4 - correctAnswers);

    return {
      regularShapeNames,
      irregularShapeNames,
      colors: shuffle(['blue', 'green', 'purple', 'yellow'] as const)
    };
  },
  Component: props => {
    const {
      question: { regularShapeNames, irregularShapeNames, colors },
      translate
    } = props;

    const seed = props.question;

    const items = shuffle(
      [
        ...regularShapeNames.map((val, i) => ({
          svgName: getRegularShapeWithAllSidesMarkedColouredSvgName(val, colors[i]),
          isCorrect: true
        })),
        ...irregularShapeNames.map((val, i) => ({
          svgName:
            val === 'cubes' || val === 'cuboids'
              ? get3DShapeSvgName(val, seed)
              : getShapeSvgNameByColor(val, colors[regularShapeNames.length + i]),
          isCorrect: false
        }))
      ],
      { random: seededRandom(seed) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesThatAreRegularPolygons()}
        pdfTitle={translate.instructions.circleShapesThatAreRegularPolygons()}
        testCorrect={items.filter(val => val.isCorrect).map(val => val.svgName)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item.svgName,
            component: (
              <AssetSvg
                name={item.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLV',
  description: 'aLV',
  keywords: ['Perimeter', 'Regular', 'Polygons'],
  schema: z.object({
    shape: z.enum(['equilateralTriangles', 'squares', 'pentagons', 'hexagons']),
    length: z.number().int().min(2).max(6),
    isM: z.boolean()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'equilateralTriangles',
      'squares',
      'pentagons',
      'hexagons'
    ] as const);

    const length = randomIntegerInclusive(2, 6);
    const isM = getRandomBoolean();

    return { shape, length, isM };
  },
  Component: props => {
    const {
      question: { shape, length, isM },
      translate,
      displayMode
    } = props;

    const sides = shapeSides[shape];

    const perimeter = length * sides;
    const labels = filledArray(
      isM ? translate.units.numberOfM(length) : translate.units.numberOfCm(length),
      sides
    );

    const seed = props.question;
    const svgName = getDimenShapeSvgName(shape, seed);

    let sentence1 = '';
    countRange(sides).forEach(val => {
      const value = isM ? translate.units.numberOfM(length) : translate.units.numberOfCm(length);
      sentence1 =
        val < sides - 1
          ? sentence1 + value + ` ${ADD} `
          : sentence1 +
            value +
            ` = ${isM ? translate.answerSentences.ansM() : translate.answerSentences.ansCm()}`;
    });

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutPerimeterOfShape()}
        Content={({ dimens }) => (
          <>
            {shape === 'equilateralTriangles' ? (
              <LabelledTriangle labels={labels} dimens={dimens} assetSvgName={svgName} />
            ) : shape === 'squares' ? (
              <LabelledQuadrilateral
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                shape={'Square4Arrows'}
                labels={labels}
                shapeSize="large"
              />
            ) : shape === 'pentagons' ? (
              <LabelledPentagon
                labels={labels}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                allArrows
              />
            ) : (
              <LabelledHexagon
                labels={labels}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                allArrows
              />
            )}
          </>
        )}
        sentences={[
          sentence1,
          isM
            ? translate.answerSentences.thePerimeterIsM()
            : translate.answerSentences.thePerimeterIsCm()
        ]}
        textStyle={displayMode === 'digital' ? { fontSize: 31 } : undefined}
        pdfDirection="column"
        questionHeight={1200}
        testCorrect={[[perimeter.toString()], [perimeter.toString()]]}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aLW',
  description: 'aLW',
  keywords: ['Perimeter', 'Regular', 'Polygons'],
  schema: z.object({
    shape: z.enum(['equilateralTriangles', 'squares', 'pentagons', 'hexagons']),
    length: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'equilateralTriangles',
      'squares',
      'pentagons',
      'hexagons'
    ] as const);

    const length = randomIntegerInclusive(2, 6);

    return { shape, length };
  },
  Component: props => {
    const {
      question: { shape, length },
      translate
    } = props;

    const sides = shapeSides[shape];

    const perimeter = length * sides;
    const labels = filledArray(translate.units.numberOfCm(length), sides);

    const seed = props.question;
    const svgName = getDimenShapeSvgName(shape, seed);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfRegularPolygon()}
        questionHeight={1100}
        Content={({ dimens }) => (
          <>
            {shape === 'equilateralTriangles' ? (
              <LabelledTriangle labels={labels} dimens={dimens} assetSvgName={svgName} />
            ) : shape === 'squares' ? (
              <LabelledQuadrilateral
                dimens={dimens}
                shape={'Square4Arrows'}
                labels={labels}
                shapeSize="large"
              />
            ) : shape === 'pentagons' ? (
              <LabelledPentagon labels={labels} dimens={dimens} allArrows />
            ) : (
              <LabelledHexagon labels={labels} dimens={dimens} allArrows />
            )}
          </>
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[perimeter.toString()]}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aLX',
  description: 'aLX',
  keywords: ['Perimeter', 'Regular', 'Polygons'],
  schema: z.object({
    shape: z.enum([
      'triangle_equal_1_arrow',
      'Square_1_arrow',
      'Pentagon_1_arrow',
      'Hexagon_1_arrow',
      'Heptagon_1_arrow',
      'Octagon_1_arrow'
    ]),
    length: z.number().int().min(2).max(12),
    color: z.enum(['pink', 'blue', 'green', 'purple', 'yellow']),
    cmOrM: z.enum(['cm', 'm'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'triangle_equal_1_arrow',
      'Square_1_arrow',
      'Pentagon_1_arrow',
      'Hexagon_1_arrow',
      'Heptagon_1_arrow',
      'Octagon_1_arrow'
    ] as const);

    const length = randomIntegerInclusive(2, 6);
    const color = getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const);
    const cmOrM = getRandomFromArray(['cm', 'm'] as const);

    return { shape, length, color, cmOrM };
  },
  Component: props => {
    const {
      question: { shape, length, color, cmOrM },
      translate
    } = props;

    const sides = {
      triangle_equal_1_arrow: 3,
      Square_1_arrow: 4,
      Pentagon_1_arrow: 5,
      Hexagon_1_arrow: 6,
      Heptagon_1_arrow: 7,
      Octagon_1_arrow: 8
    }[shape];

    const perimeter = length * sides;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfRegularPolygon()}
        questionHeight={1100}
        Content={({ dimens }) => (
          <LabelledShape
            labels={[
              cmOrM === 'cm'
                ? translate.units.numberOfCm(length)
                : translate.units.numberOfM(length)
            ]}
            dimens={dimens}
            shapeName={shape}
            color={color}
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={
          cmOrM === 'cm' ? translate.answerSentences.ansCm() : translate.answerSentences.ansM()
        }
        testCorrect={[perimeter.toString()]}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aLY',
  description: 'aLY',
  keywords: ['Regular', 'Polygons', 'Perimeter'],
  schema: z.object({
    shapes: z
      .object({
        shape: z.enum([
          'triangles',
          'squares',
          'pentagons',
          'hexagons',
          'heptagons',
          'octagons',
          'nonagons',
          'decagons'
        ]),
        length: z.number().int().min(2).max(12)
      })
      .array()
      .length(2),
    smallestOrGreatest: z.enum(['smallest', 'greatest'])
  }),
  simpleGenerator: () => {
    const shapeNames = getRandomSubArrayFromArray(
      [
        'triangles',
        'squares',
        'pentagons',
        'hexagons',
        'heptagons',
        'octagons',
        'nonagons',
        'decagons'
      ] as const,
      2
    );

    const orderedSides = [...shapeNames].sort((a, b) => shapeSides[a] - shapeSides[b]);

    const { lengths } = rejectionSample(
      () => {
        const lengths = sortNumberArray(randomUniqueIntegersInclusive(2, 12, 2), 'descending');
        return { lengths };
      },
      val => arrayHasNoDuplicates(val.lengths.map((len, i) => len * shapeSides[orderedSides[i]]))
    );

    const shapes = orderedSides.map((shape, i) => ({ shape, length: lengths[i] }));
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { shapes, smallestOrGreatest };
  },
  Component: props => {
    const {
      question: { shapes, smallestOrGreatest },
      translate,
      displayMode
    } = props;

    const perims = shapes.map(val => val.length * shapeSides[val.shape]);

    const answer = smallestOrGreatest === 'smallest' ? Math.min(...perims) : Math.max(...perims);

    return (
      <QF11SelectImagesUpTo4
        title={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.selectShapeWithSmallestPerimeter()
            : translate.instructions.selectShapeWithGreatestPerimeter()
        }
        pdfTitle={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.circleShapeWithSmallestPerimeter()
            : translate.instructions.circleShapeWithGreatestPerimeter()
        }
        numItems={2}
        testCorrect={[answer]}
        renderItems={({ dimens }) =>
          shapes.map((shapeProps, i) => {
            return {
              value: perims[i],
              component: (
                <TextStructure
                  textVariant="WRN700"
                  style={{ width: dimens.width * 0.9, justifyContent: 'center' }}
                  textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                  sentence={
                    shapeProps.shape === 'squares'
                      ? translate.answerSentences.aSquareWithASideLengthOfX(shapeProps.length)
                      : translate.answerSentences.aRegularShapeWithASideLengthOfX(
                          translate.shapes[shapeProps.shape](1),
                          shapeProps.length
                        )
                  }
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLZ',
  description: 'aLZ',
  keywords: ['Perimeter', 'Regular', 'Polygons'],
  schema: z.object({
    shape: z.enum(regularShapes),
    length: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(regularShapes);

    const length = randomIntegerInclusive(2, 10);

    return { shape, length };
  },
  Component: props => {
    const {
      question: { shape, length },
      translate
    } = props;

    const perimeter = length * shapeSides[shape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thePerimeterOfXItemIsYCmWhatIsTheLengthOfEachSide(
          translate.shapes[shape](1),
          perimeter
        )}
        questionHeight={1100}
        Content={({ dimens }) => (
          <AssetSvg
            name={getShapeSvgName(shape)}
            width={dimens.width * 0.8}
            height={dimens.height * 0.8}
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[length.toString()]}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfRegularPolygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
