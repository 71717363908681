import { ComponentProps } from 'react';
import { NumberTrack } from './NumberTrack';
import { SetState, projectSetState } from '../../../../utils/react';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { parseMarkup } from '../../../../markup';

export default function NumberTrackKeyboard({
  userAnswer,
  onTextInput,
  ...props
}: Omit<ComponentProps<typeof NumberTrack>, 'inputBox'> & {
  userAnswer?: string[];
  onTextInput?: SetState<string[]>;
}) {
  const keyboardInputBox = (
    ansIndex: number,
    boxWidth: number,
    boxHeight: number,
    style: StyleProp<ViewStyle>
  ) => (
    <NoKeyboardTextInput
      key={'answerBox' + ansIndex}
      value={userAnswer ? userAnswer[ansIndex] : ''}
      onChangeText={onTextInput ? projectSetState(onTextInput, ansIndex) : undefined}
      style={[
        {
          width: boxWidth,
          minWidth: boxWidth,
          minHeight: boxHeight,
          height: boxHeight
        },
        styles.answerBox,
        style
      ]}
      selectedStyle={{ zIndex: 2, borderWidth: 3 }}
      autoFocus={ansIndex === 0}
    />
  );

  return <NumberTrack inputBox={keyboardInputBox} {...props} />;
}

/** See {@link NumberTrackKeyboard}. */
export const NumberTrackKeyboardWithState = withStateHOC(NumberTrackKeyboard, {
  stateProp: 'userAnswer',
  setStateProp: 'onTextInput',
  defaults: props => ({
    // default state is an empty string array of the length of how many ans boxes there are.
    defaultState: filledArray(
      '',
      props.boxValues.map(row => parseMarkup(row).numberOfAns).reduce((a, b) => a + b)
    ),
    testComplete: state => (state ? state.every(it => it !== '') : true)
  })
});

const styles = StyleSheet.create({
  answerBox: {
    minWidth: 0 // to ignore the minWidth set on the NoKeyboardTextInput
  },
  hideBorder: {
    // Dashed / dotted borders on ios are not supported and so we have to use negative margins
    // to deliberately overlap a border with a border.
    marginLeft: -3
  }
});
