import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo, numberToBase10Object } from '../../../../utils/math';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arraysHaveSameContents, countRange } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import { chunk } from '../../../../utils/chunk';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgj',
  description: 'bgj',
  keywords: ['Equal to', 'Less than', 'Greater than', '<', '>', '='],
  schema: z
    .object({
      numberA: z.number().int().min(11).max(99),
      numberB: z.number().int().min(11).max(99),
      variation: z.enum(['Cubes', 'Straws', 'Rekenrek']),
      isVariationJumbled: z.boolean(),
      arrangementSeedA: z.number().int().min(1).max(1000),
      arrangementSeedB: z.number().int().min(1).max(1000)
    })
    .refine(
      val => Math.floor(val.numberA / 10) === Math.floor(val.numberB / 10),
      'numberA and numberB should be in the same tens'
    )
    .refine(
      val => val.arrangementSeedA !== val.arrangementSeedB,
      'arrangementSeedA cannot equal arrangementSeedB'
    ),
  simpleGenerator: () => {
    const numberOfTens = randomIntegerInclusive(1, 9);

    const numberA = randomIntegerInclusive(numberOfTens * 10 + 1, numberOfTens * 10 + 9);
    const numberB = randomIntegerInclusive(numberOfTens * 10 + 1, numberOfTens * 10 + 9);

    const variation = getRandomFromArray(['Straws'] as const);

    const isVariationJumbled = numberA === numberB ? true : getRandomBoolean();

    const [arrangementSeedA, arrangementSeedB] = randomUniqueIntegersInclusive(1, 1000, 2);

    return { numberA, numberB, variation, isVariationJumbled, arrangementSeedA, arrangementSeedB };
  },
  Component: props => {
    const {
      translate,
      question: {
        numberA,
        numberB,
        variation,
        isVariationJumbled,
        arrangementSeedA,
        arrangementSeedB
      }
    } = props;

    const baseTenA = numberA === 100 ? { tens: 10 } : numberToBase10Object(numberA);
    const baseTenB = numberB === 100 ? { tens: 10 } : numberToBase10Object(numberB);

    const scaleOnes = Math.max(numberA % 10, numberB % 10);
    const scaleTens = Math.max(Math.floor(numberA / 10), Math.floor(numberB / 10));

    const dimens = { width: 400, height: 450 };

    const simpleBaseTenScale = Scale(dimens.width, dimens.height, {
      ones: scaleOnes,
      tens: scaleTens
    });

    const scale = BaseTenRepCalcGridsAndScale(
      dimens.width,
      dimens.height,
      {
        ones: scaleOnes,
        tens: scaleTens
      },
      'Straws'
    ).scale;

    function generateSvgs(baseTen: { ones?: number; tens?: number }) {
      const onesSvgs = baseTen.ones
        ? countRange(baseTen.ones).map(() => 'Base_Ten/Straws1' as const)
        : [];
      const tensSvgs = baseTen.tens
        ? countRange(baseTen.tens).map(() => 'Bundle_of_10_straws' as const)
        : [];

      return shuffle(chunk([...onesSvgs, ...tensSvgs], 4), {
        random: seededRandom([arrangementSeedA, arrangementSeedB])
      });
    }

    const svgsAChunks = shuffle(generateSvgs(baseTenA), {
      random: seededRandom([arrangementSeedA, arrangementSeedB])
    });

    const chunksB = shuffle(generateSvgs(baseTenB), {
      random: seededRandom([arrangementSeedB, arrangementSeedA])
    });

    let svgsBChunks: ('Base_Ten/Straws1' | 'Bundle_of_10_straws')[][] = chunksB;

    if (numberA === numberB) {
      const areShuffledTheSame = arraysHaveSameContents(svgsAChunks.flat(), svgsBChunks.flat());
      if (areShuffledTheSame) {
        svgsBChunks = svgsAChunks.map(chunk => [...chunk].reverse());

        while (arraysHaveSameContents(svgsAChunks.flat(), svgsBChunks.flat())) {
          svgsBChunks = shuffle(svgsBChunks, { random: seededRandom([arrangementSeedB]) });
        }
      } else {
        svgsBChunks = chunksB;
      }
    } else {
      svgsBChunks = shuffle(generateSvgs(baseTenB), {
        random: seededRandom([arrangementSeedB])
      });
    }

    const numberArray = [
      { value: numberA, baseTen: baseTenA, chunks: svgsAChunks },
      { value: numberB, baseTen: baseTenB, chunks: svgsBChunks }
    ];

    const heightDivisor = Math.max(svgsAChunks.length, svgsBChunks.length);

    const [lhsComponent, rhsComponent] = numberArray.map(({ value, baseTen, chunks }, i) => {
      return (
        <View
          key={i}
          style={{
            flexDirection: 'row',
            width: dimens.width,
            height: dimens.height,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {variation === 'Cubes' ? (
            <SimpleBaseTenWithCrossOut
              ones={baseTen.ones}
              tens={baseTen.tens}
              dimens={dimens}
              scale={simpleBaseTenScale}
            />
          ) : variation === 'Straws' ? (
            isVariationJumbled ? (
              chunks.map(chunk => {
                return chunk.map((svgName, index) => {
                  return (
                    <AssetSvg
                      name={svgName}
                      key={index}
                      width={dimens.width / 5}
                      height={dimens.height / heightDivisor}
                    />
                  );
                });
              })
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  width: dimens.width,
                  height: dimens.height
                }}
              >
                <BaseTenRepresentation
                  usableHeight={dimens.height}
                  usableWidth={dimens.width}
                  b10Rep={{
                    arrangement: 'ltr',
                    numbers: { ones: baseTen.ones, tens: baseTen.tens },
                    variant: 'Straws'
                  }}
                  align="center"
                  scale={scale}
                />
              </View>
            )
          ) : (
            <Rekenrek dimens={dimens} rows={10} numberShown={value} />
          )}
        </View>
      );
    });

    const statements = [
      {
        lhsComponent,
        rhsComponent,
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheObjects()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheObjects()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statementStyle={{ justifyContent: 'center' }}
        statements={statements}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgk',
  description: 'bgk',
  keywords: ['Equal to', 'Less than', 'Greater than', '<', '>', '='],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(100),
      numberB: z.number().int().min(1).max(100),
      variation: z.enum(['Cubes', 'Straws', 'Rekenrek']),
      isVariationJumbled: z.boolean(),
      arrangementSeedA: z.number().int().min(1).max(1000),
      arrangementSeedB: z.number().int().min(1).max(1000)
    })
    .refine(
      val => val.arrangementSeedA !== val.arrangementSeedB,
      'arrangementSeedA cannot equal arrangementSeedB'
    ),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 100);
    const numberB = randomIntegerInclusive(1, 100);

    const variation = getRandomFromArray(['Cubes', 'Straws', 'Rekenrek'] as const);

    const isVariationJumbled = numberA === numberB ? true : getRandomBoolean();

    const [arrangementSeedA, arrangementSeedB] = randomUniqueIntegersInclusive(1, 1000, 2);

    return { numberA, numberB, variation, isVariationJumbled, arrangementSeedA, arrangementSeedB };
  },
  Component: props => {
    const {
      translate,
      question: {
        numberA,
        numberB,
        variation,
        isVariationJumbled,
        arrangementSeedA,
        arrangementSeedB
      }
    } = props;

    const baseTenA = numberA === 100 ? { tens: 10 } : numberToBase10Object(numberA);
    const baseTenB = numberB === 100 ? { tens: 10 } : numberToBase10Object(numberB);

    const scaleOnes = Math.max(numberA % 10, numberB % 10);
    const scaleTens = Math.max(Math.floor(numberA / 10), Math.floor(numberB / 10));

    const dimens = { width: 400, height: 450 };

    const simpleBaseTenScale = Scale(dimens.width, dimens.height, {
      ones: scaleOnes,
      tens: scaleTens
    });

    const scale = BaseTenRepCalcGridsAndScale(
      dimens.width,
      dimens.height,
      {
        ones: scaleOnes,
        tens: scaleTens
      },
      'Straws'
    ).scale;

    function generateSvgs(baseTen: { ones?: number; tens?: number }) {
      const onesSvgs = baseTen.ones
        ? countRange(baseTen.ones).map(() => 'Base_Ten/Straws1' as const)
        : [];
      const tensSvgs = baseTen.tens
        ? countRange(baseTen.tens).map(() => 'Bundle_of_10_straws' as const)
        : [];

      return chunk([...onesSvgs, ...tensSvgs], 4);
    }

    const svgsAChunks = shuffle(generateSvgs(baseTenA), {
      random: seededRandom([arrangementSeedA, arrangementSeedB])
    });
    const svgsBChunks = shuffle(generateSvgs(baseTenB), {
      random: seededRandom([arrangementSeedB, arrangementSeedA])
    });

    const numberArray = [
      { value: numberA, baseTen: baseTenA, chunks: svgsAChunks },
      { value: numberB, baseTen: baseTenB, chunks: svgsBChunks }
    ];

    const heightDivisor = Math.max(svgsAChunks.length, svgsBChunks.length);

    const [lhsComponent, rhsComponent] = numberArray.map(({ value, baseTen, chunks }, i) => {
      return (
        <View
          key={i}
          style={{
            flexDirection: 'row',
            width: dimens.width,
            height: dimens.height,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {variation === 'Cubes' ? (
            <SimpleBaseTenWithCrossOut
              ones={baseTen.ones}
              tens={baseTen.tens}
              dimens={dimens}
              scale={simpleBaseTenScale}
            />
          ) : variation === 'Straws' ? (
            isVariationJumbled ? (
              chunks.map(chunk => {
                return chunk.map((svgName, index) => {
                  return (
                    <AssetSvg
                      name={svgName}
                      key={index}
                      width={dimens.width / 5}
                      height={dimens.height / heightDivisor}
                    />
                  );
                });
              })
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  width: dimens.width,
                  height: dimens.height
                }}
              >
                <BaseTenRepresentation
                  usableHeight={dimens.height}
                  usableWidth={dimens.width}
                  b10Rep={{
                    arrangement: 'ltr',
                    numbers: { ones: baseTen.ones, tens: baseTen.tens },
                    variant: 'Straws'
                  }}
                  align="center"
                  scale={scale}
                />
              </View>
            )
          ) : (
            <Rekenrek scale={'small'} rows={10} numberShown={value} />
          )}
        </View>
      );
    });

    const statements = [
      {
        lhsComponent,
        rhsComponent,
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheObjects()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheObjects()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statementStyle={{ justifyContent: 'center' }}
        statements={statements}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgl',
  description: 'bgl',
  keywords: ['Fewer', 'More'],
  schema: z
    .object({
      fewerOrMore: z.enum(['fewer', 'more']),
      numberA: z.number().int().min(1).max(100),
      numberB: z.number().int().min(1).max(100),
      isJumbled: z.boolean(),
      item: z.enum(['Marbles', 'Balloons', 'Sweets', 'Crayons']),
      arrangementSeed: z.number().int().min(1).max(1000),
      crayonColor: z.enum(['red', 'blue', 'pink', 'green'])
    })
    .refine(
      ({ numberA, numberB }) => numberA !== numberB,
      'numberA and numberB cannot be the same.'
    ),
  simpleGenerator: () => {
    const item = getRandomFromArray(['Marbles', 'Balloons', 'Sweets', 'Crayons'] as const);

    const fewerOrMore = getRandomFromArray(['fewer', 'more'] as const);

    const isJumbled = getRandomBoolean();

    const numberOfTens = randomIntegerInclusive(1, 9);

    const [numberA, numberB] = getRandomBoolean()
      ? randomUniqueIntegersInclusive(numberOfTens * 10, numberOfTens * 10 + 9, 2)
      : [
          randomIntegerInclusive(numberOfTens * 10, numberOfTens * 10 + 9),
          randomIntegerInclusive(1, 100, { constraint: x => Math.floor(x / 10) !== numberOfTens })
        ];

    const arrangementSeed = randomIntegerInclusive(1, 1000);

    const crayonColor = getRandomFromArray(['red', 'blue', 'pink', 'green'] as const);

    return { item, fewerOrMore, isJumbled, numberA, numberB, arrangementSeed, crayonColor };
  },
  Component: ({ question, translate }) => {
    const { item, fewerOrMore, isJumbled, numberA, numberB, arrangementSeed, crayonColor } =
      question;

    const itemTranslations = {
      Marbles: translate.enums.Base10RepresentationVariant.Marbles(),
      Balloons: translate.enums.Base10RepresentationVariant.Balloons(),
      Sweets: translate.enums.Base10RepresentationVariant.Sweets(),
      Crayons: translate.enums.Base10RepresentationVariant.Crayons()
    };

    const svgNames = {
      Marbles: { ones: 'Base_Ten/Marbles1' as const, tens: 'Base_Ten/Marbles10' as const },
      Balloons: { ones: 'Base_Ten/Balloons1' as const, tens: 'Base_Ten/Balloons10' as const },
      Sweets: { ones: 'Base_Ten/Sweets1' as const, tens: 'Base_Ten/Sweets10' as const },
      Crayons: {
        ones: `Base_Ten/Crayons1_${crayonColor}` as const,
        tens: 'Base_Ten/Crayons10' as const
      }
    };

    const numberOfOnesA = numberA % 10;
    const numberOfOnesB = numberB % 10;

    const numberOfTensA = Math.floor(numberA / 10);
    const numberOfTensB = Math.floor(numberB / 10);

    const random = seededRandom({ arrangementSeed });
    const arrangementA = getRandomBooleanArray(5, 4, numberOfOnesA + numberOfTensA, random);
    const arrangementB = getRandomBooleanArray(5, 4, numberOfOnesB + numberOfTensB, random);

    const title =
      fewerOrMore === 'fewer'
        ? translate.ks1Instructions.selectTheGroupWithFewerObject(itemTranslations[item])
        : translate.ks1Instructions.selectTheGroupWithMoreObject(itemTranslations[item]);

    const pdfTitle =
      fewerOrMore === 'fewer'
        ? translate.ks1PDFInstructions.tickTheGroupWithFewerObject(itemTranslations[item])
        : translate.ks1PDFInstructions.tickTheGroupWithMoreObject(itemTranslations[item]);

    const correctAnswer =
      fewerOrMore === 'fewer' ? Math.min(numberA, numberB) : Math.max(numberA, numberB);

    const getSvgs = (arrangement: 'A' | 'B') => {
      let svgCount = 0;

      const svgSources = [
        ...countRange(arrangement === 'A' ? numberOfTensA : numberOfTensB).map(
          () => svgNames[item].tens
        ),
        ...countRange(arrangement === 'A' ? numberOfOnesA : numberOfOnesB).map(
          () => svgNames[item].ones
        )
      ];

      const svgs = shuffle(svgSources, { random });

      const mapCellsToSvgs = (arr: boolean[][]) => {
        return arr.map(row =>
          row.map(cell => {
            if (cell && svgCount < svgs.length) {
              return svgs[svgCount++];
            }
          })
        );
      };

      return arrangement === 'A' ? mapCellsToSvgs(arrangementA) : mapCellsToSvgs(arrangementB);
    };

    const items = [
      {
        value: numberA,
        svgs: getSvgs('A')
      },
      {
        value: numberB,
        svgs: getSvgs('B')
      }
    ];

    const scaleOnes = Math.max(numberA % 10, numberB % 10);
    const scaleTens = Math.max(Math.floor(numberA / 10), Math.floor(numberB / 10));

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        numItems={2}
        renderItems={({ dimens }) => {
          const scale = BaseTenRepCalcGridsAndScale(
            dimens.width,
            dimens.height,
            {
              ones: scaleOnes,
              tens: scaleTens
            },
            item
          ).scale;
          return [
            {
              value: items[0].value,
              component: isJumbled ? (
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                  {arrangementA.map((row, rowIndex) => (
                    <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                      {row.map((cell, colIndex) => (
                        <View
                          key={`cell-${rowIndex}-${colIndex}`}
                          style={{
                            width: dimens.width * 0.2,
                            height: dimens.height * 0.18,
                            padding: 4
                          }}
                        >
                          {cell && items[0].svgs[rowIndex][colIndex] && (
                            <AssetSvg
                              name={items[0].svgs[rowIndex][colIndex] as SvgName}
                              height={
                                items[0].svgs[rowIndex][colIndex]?.endsWith('10')
                                  ? dimens.height * 0.18
                                  : dimens.height * 0.1
                              }
                              width={
                                items[0].svgs[rowIndex][colIndex]?.endsWith('10')
                                  ? dimens.width * 0.18
                                  : dimens.width * 0.1
                              }
                            />
                          )}
                        </View>
                      ))}
                    </View>
                  ))}
                </View>
              ) : (
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <BaseTenRepresentation
                    b10Rep={{
                      arrangement: 'ltr',
                      numbers: { tens: numberOfTensA, ones: numberOfOnesA },
                      variant: item
                    }}
                    usableWidth={dimens.width}
                    usableHeight={dimens.height}
                    scale={scale}
                    align="center"
                  />
                </View>
              )
            },
            {
              value: items[1].value,
              component: isJumbled ? (
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                  {arrangementB.map((row, rowIndex) => (
                    <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                      {row.map((cell, colIndex) => (
                        <View
                          key={`cell-${rowIndex}-${colIndex}`}
                          style={{
                            width: dimens.width / 4,
                            height: dimens.height / 5,
                            padding: 4
                          }}
                        >
                          {cell && items[1].svgs[rowIndex][colIndex] && (
                            <AssetSvg
                              name={items[1].svgs[rowIndex][colIndex] as SvgName}
                              height={
                                items[1].svgs[rowIndex][colIndex]?.endsWith('10')
                                  ? dimens.height * 0.18
                                  : dimens.height * 0.1
                              }
                              width={
                                items[1].svgs[rowIndex][colIndex]?.endsWith('10')
                                  ? dimens.width * 0.18
                                  : dimens.height * 0.1
                              }
                            />
                          )}
                        </View>
                      ))}
                    </View>
                  ))}
                </View>
              ) : (
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <BaseTenRepresentation
                    b10Rep={{
                      arrangement: 'ltr',
                      numbers: { tens: numberOfTensB, ones: numberOfOnesB },
                      variant: item
                    }}
                    usableWidth={dimens.width}
                    usableHeight={dimens.height}
                    scale={scale}
                    align="center"
                  />
                </View>
              )
            }
          ];
        }}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareObjects',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
