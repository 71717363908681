import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFloats } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { createHundredSquareShape, rotateShape270 } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { countRange, range } from '../../../../utils/collections';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import { decimalToFraction } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQy',
  description: 'aQy',
  keywords: ['Hundred square', 'Decimals', 'Fraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(10).max(90).step(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(10, 90, 10);
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(numberOfShadedSquares));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeEquivalentFractionsAndDecimal()}
        sentence={`<frac nAns='' d='${(10).toLocaleString()}' /> = <frac nAns='' d='${(100).toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], number(math.evaluate(`${numberOfShadedSquares} / 10`))) &&
          compareFloats(userAnswer[1], numberOfShadedSquares) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number(math.evaluate(`${numberOfShadedSquares} / 10`)).toLocaleString(),
            numberOfShadedSquares.toLocaleString(),
            number(math.evaluate(`${numberOfShadedSquares} / 100`)).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQz',
  description: 'aQz',
  keywords: ['Hundred square', 'Decimals', 'Fraction', 'Fifths', 'Hundredths'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(20).max(80).step(20)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(20, 80, 20);
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(numberOfShadedSquares));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeEquivalentFractionsAndDecimal()}
        sentence={`<frac nAns='' d='${(5).toLocaleString()}' /> = <frac nAns='' d='${(100).toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], number(math.evaluate(`${numberOfShadedSquares} / 20`))) &&
          compareFloats(userAnswer[1], numberOfShadedSquares) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number(math.evaluate(`${numberOfShadedSquares} / 20`)).toLocaleString(),
            numberOfShadedSquares.toLocaleString(),
            number(math.evaluate(`${numberOfShadedSquares} / 100`)).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQA',
  description: 'aQA',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(10),
    numeratorB: z.number().int().min(1).max(5),
    numeratorC: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(1, 10);
    const numeratorB = randomIntegerInclusive(1, 5);
    const numeratorC = randomIntegerInclusive(1, 4);

    return { numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorA} / 10`))
        },
        {
          sentence: `<frac n='${numeratorB.toLocaleString()}' d='${(5).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorB} / 5`))
        },
        {
          sentence: `<frac n='${numeratorC.toLocaleString()}' d='${(4).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorC} / 4`))
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        title={translate.instructions.workOutTheEquivalentDecimals()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer) &&
          compareFloats(userAnswer[2][0], sentences[2].answer)
        }
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQB',
  description: 'aQB',
  keywords: ['Fifths', 'Decimals', 'Number line'],
  schema: z.object({
    steps: numberEnum([1, 2]),
    missingFractions: z.boolean(),
    variablesShown: z.array(z.number().int().min(2).max(8)).length(3),
    missingVariables: z.array(z.number().int().min(2).max(8)).length(2)
  }),
  simpleGenerator: () => {
    const steps = getRandomFromArray([1, 2] as const);
    const missingFractions = getRandomBoolean();
    const variablesShown = randomUniqueIntegersInclusiveStep(2, 8, 2, 3);
    const missingVariables = getRandomSubArrayFromArray(variablesShown, 2);

    return { steps, missingFractions, variablesShown, missingVariables };
  },

  Component: props => {
    const {
      question: { steps, missingFractions, variablesShown, missingVariables },
      translate,
      displayMode
    } = props;

    const topTickValues = range(0, 10, steps).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (variablesShown.includes(i))
        return missingFractions && missingVariables.includes(i)
          ? '<ans/>'
          : `<frac n='${decimalToFraction(i / 10)[0]}' d='${decimalToFraction(i / 10)[1]}' />`;
      return ' ';
    });

    const bottomTickValues = range(0, 10, steps).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (variablesShown.includes(i)) {
        return !missingFractions && missingVariables.includes(i)
          ? '<ans/>'
          : `${(i / 10).toLocaleString()}`;
      }
      return ' ';
    });

    let draggables;

    const [num1, num2] = missingVariables;
    const num3 = [2, 4, 6, 8].find(x => !variablesShown.includes(x))!;

    const dec1 = num1 / 10;
    const dec2 = num2 / 10;
    const dec3 = num3 / 10;

    // 1step !missingFractions
    if (!missingFractions && steps === 1) {
      const wrongDec1 = dec1 + 0.1 === dec2 ? dec1 - 0.1 : dec1 + 0.1;
      const wrongDec2 = dec2 + 0.1 === dec1 ? dec2 - 0.1 : dec2 + 0.1;

      draggables = [
        dec1,
        dec2,
        wrongDec1,
        wrongDec2,
        dec1 / 10,
        dec2 / 10,
        wrongDec1 * 10,
        wrongDec2 + 0.01
      ].map(i => i.toLocaleString());
    } else if (!missingFractions && steps === 2) {
      const wrongDec1 = dec1 * 5 + 0.5;
      const wrongDec2 = dec2 * 5 + 0.5;
      const wrongDec3 = dec3 * 5 + 0.5;

      draggables = [
        ...new Set([
          dec1,
          dec2,
          dec3,
          wrongDec1,
          wrongDec2,
          wrongDec3,
          dec1 / 2,
          dec2 / 2,
          dec3 / 2
        ])
      ].map(i => i.toLocaleString());
    } else {
      const dec4 = dec1 * 5;
      const dec5 = dec2 * 5;
      const fracOptionsAsDec = [
        ...new Set([dec1, dec2, dec4, dec5, dec4 + 4, dec5 + 4, dec3, dec3 * 10])
      ];

      draggables = fracOptionsAsDec.map(dec => {
        const [num, denom] = decimalToFraction(dec);
        return {
          component: (
            <TextStructure
              key={dec}
              sentence={`<frac n='${num.toLocaleString()}' d='${denom.toLocaleString()}' />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value: `${dec}`
        };
      });
    }

    const answers = [...missingVariables].sort().map(x => `${x / 10}`);

    const items = shuffle([...draggables], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={answers}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aQC',
  description: 'aQC',
  keywords: ['Hundredths', 'Fractions', 'Decimals', 'Mixed number'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(110).max(490).step(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(110, 490, 10, {
      constraint: x => x % 100 !== 0
    });
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const hundreds = Math.floor(number(math.evaluate(`${numberOfShadedSquares} / 100`)));
    const remaining = number(math.evaluate(`${numberOfShadedSquares} - (${hundreds} * 100)`));

    const remainingSquare = createHundredSquareShape(remaining);

    const denominator = remaining % 20 === 0 ? 5 : 10;
    const numerarator = denominator === 5 ? remaining / 20 : remaining / 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionAndDecimalIsRepresent()}
        sentence={`<frac wAns='' nAns='' d='${denominator.toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], hundreds) &&
          compareFloats(userAnswer[1], numerarator) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            {countRange(hundreds).map(number => {
              return (
                <View key={number} style={{ marginRight: 12 }}>
                  <DisplayShapeOnGrid
                    givenShape={createHundredSquareShape(100)}
                    dimens={{ width: dimens.width / (hundreds + 1), height: dimens.height }}
                  />
                </View>
              );
            })}
            <DisplayShapeOnGrid
              givenShape={remainingSquare}
              dimens={{ width: dimens.width / (hundreds + 1), height: dimens.height }}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            hundreds.toLocaleString(),
            numerarator.toLocaleString(),
            (numberOfShadedSquares / 100).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQD',
  description: 'aQD',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent', 'Fractions', 'Improper'],
  schema: z.object({
    numeratorA: z.number().int().min(11).max(19),
    numeratorB: z.number().int().min(21).max(49),
    numeratorC: z.number().int().min(1).max(50)
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(11, 19);
    const numeratorB = randomIntegerInclusive(21, 49);
    const numeratorC = randomIntegerInclusive(1, 50, { constraint: x => x % 2 !== 0 });

    return { numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorA} / 10`))
        },
        {
          sentence: `<frac n='${numeratorB.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorB} / 10`))
        },
        {
          sentence: `<frac n='${numeratorC.toLocaleString()}' d='${(2).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorC} / 2`))
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        title={translate.instructions.convertImproperFractionsToDecimals()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer) &&
          compareFloats(userAnswer[2][0], sentences[2].answer)
        }
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAndDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
