import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import Pictogram from '../../../../components/question/representations/Pictogram/Pictogram';
import { colors, PictogramColors } from '../../../../theme/colors';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { daySchema, getRandomUniqueDays, dayNames } from '../../../../utils/days';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'blo',
  description: 'blo',
  keywords: ['Pictogram'],
  schema: z
    .object({
      zeroPets: z.number().min(1).max(6),
      onePet: z.number().min(1).max(6),
      twoPets: z.number().min(1).max(6),
      threePets: z.number().min(0).max(6),
      numberOfPets: z.number().int().min(0).max(3),
      color: z.string()
    })
    .refine(
      val => arrayHasNoDuplicates([val.zeroPets, val.onePet, val.twoPets, val.threePets]),
      'zeroPets, onePet, twoPets and threePets all need to be different.'
    ),
  simpleGenerator: () => {
    const threePets = randomIntegerInclusive(0, 6);

    const [zeroPets, onePet, twoPets] = randomUniqueIntegersInclusive(1, 6, 4, {
      constraint: x => x !== threePets
    });

    const numberOfPets = randomIntegerInclusive(0, 3);

    const color = getRandomFromArray(PictogramColors) as string;

    return { zeroPets, onePet, twoPets, threePets, numberOfPets, color };
  },
  Component: props => {
    const {
      question: { zeroPets, onePet, twoPets, threePets, numberOfPets, color },
      translate
    } = props;

    const selectables = [zeroPets, onePet, twoPets, threePets];

    const correctAnswer = (() => {
      switch (numberOfPets) {
        case 0:
          return zeroPets;
        case 1:
          return onePet;
        case 2:
          return twoPets;
        case 3:
          return threePets;
      }
    })() as number;

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions.selectHowManyChildrenHaveNumPets(numberOfPets)}
        pdfTitle={translate.ks1PDFInstructions.tickHowManyChildrenHaveNumPets(numberOfPets)}
        correctAnswer={[correctAnswer.toString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[zeroPets, onePet, twoPets, threePets]}
                columnNames={[
                  translate.tableHeaders.numberOfPets(),
                  translate.tableHeaders.numberOfChildren()
                ]}
                rowData={[
                  [(0).toLocaleString()],
                  [(1).toLocaleString()],
                  [(2).toLocaleString()],
                  [(3).toLocaleString()]
                ]}
                dimens={dimens}
                color={color}
                keyValue={translate.keys.numChildren(1)}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'blp',
  description: 'blp',
  keywords: ['Pictogram', 'Most', 'Fewest'],
  schema: z
    .object({
      dayA: daySchema,
      dayAAmount: z.number().min(0).max(6),
      dayB: daySchema,
      dayBAmount: z.number().min(0).max(6),
      dayC: daySchema,
      dayCAmount: z.number().min(0).max(6),
      dayD: daySchema,
      dayDAmount: z.number().min(0).max(6),
      mostOrFewest: z.enum(['most', 'fewest']),
      item: z.enum(['cakes', 'cookies', 'drinks', 'ice creams', 'muffins']),
      color: z.string()
    })
    .refine(
      val => arrayHasNoDuplicates([val.dayAAmount, val.dayBAmount, val.dayCAmount, val.dayDAmount]),
      'Amounts for all days must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.dayA, val.dayB, val.dayC, val.dayD]),
      'All days must be different.'
    ),
  simpleGenerator: () => {
    const [dayA, dayB, dayC, dayD] = getRandomUniqueDays(4);

    const [dayAAmount, dayBAmount, dayCAmount, dayDAmount] = randomUniqueIntegersInclusive(0, 6, 4);

    const mostOrFewest = getRandomFromArray(['most', 'fewest'] as const);

    const color = getRandomFromArray(PictogramColors) as string;

    const item = getRandomFromArray([
      'cakes',
      'cookies',
      'drinks',
      'ice creams',
      'muffins'
    ] as const);

    return {
      dayA,
      dayAAmount,
      dayB,
      dayBAmount,
      dayC,
      dayCAmount,
      dayD,
      dayDAmount,
      mostOrFewest,
      item,
      color
    };
  },
  Component: props => {
    const {
      question: {
        dayA,
        dayAAmount,
        dayB,
        dayBAmount,
        dayC,
        dayCAmount,
        dayD,
        dayDAmount,
        mostOrFewest,
        item,
        color
      },
      translate
    } = props;

    const dayAString = translate.time[dayA]();

    const dayBString = translate.time[dayB]();

    const dayCString = translate.time[dayC]();

    const dayDString = translate.time[dayD]();

    const [itemString, tableHeader, keyString] = (() => {
      switch (item) {
        case 'cakes':
          return [
            translate.food.Cakes(0),
            translate.tableHeaders.numberOfCakesSold(),
            translate.keys.numCakes(1)
          ];
        case 'cookies':
          return [
            translate.food.Cookies(0),
            translate.tableHeaders.numberOfCookiesSold(),
            translate.keys.numCookies(1)
          ];
        case 'drinks':
          return [
            translate.drink.Drinks(0),
            translate.tableHeaders.numberOfDrinksSold(),
            translate.keys.numDrinks(1)
          ];
        case 'ice creams':
          return [
            translate.food.IceCreams(0),
            translate.tableHeaders.IceCreamsSold(),
            translate.keys.numIceCreams(1)
          ];
        case 'muffins':
          return [
            translate.food.Muffins(0),
            translate.tableHeaders.numberOfMuffinsSold(),
            translate.keys.numMuffins(1)
          ];
      }
    })();

    const data = [
      {
        day: dayA,
        string: dayAString,
        value: dayAAmount
      },
      {
        day: dayB,
        string: dayBString,
        value: dayBAmount
      },
      {
        day: dayC,
        string: dayCString,
        value: dayCAmount
      },
      {
        day: dayD,
        string: dayDString,
        value: dayDAmount
      }
    ].sort((a, b) => {
      const indexA = dayNames.indexOf(a.day);
      const indexB = dayNames.indexOf(b.day);
      return indexA - indexB;
    });

    const selectables = data.map(({ string }) => string);

    const answer =
      mostOrFewest === 'most'
        ? data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0])
        : data.reduce((min, obj) => (obj.value < min.value ? obj : min), data[0]);

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          mostOrFewest === 'most'
            ? translate.ks1Instructions.selectDayOnWhichMostXSold(itemString)
            : translate.ks1Instructions.selectDayOnWhichFewestXSold(itemString)
        }
        pdfTitle={
          mostOrFewest === 'most'
            ? translate.ks1PDFInstructions.tickDayOnWhichMostXSold(itemString)
            : translate.ks1PDFInstructions.tickDayOnWhichFewestXSold(itemString)
        }
        correctAnswer={[answer.string]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={data.map(({ value }) => value)}
                columnNames={[translate.keywords.Day(), tableHeader]}
                rowData={[...data.map(({ string }) => [string])]}
                dimens={dimens}
                color={color}
                keyValue={keyString}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'blq',
  description: 'blq',
  keywords: ['Pictogram', 'More', 'Fewer'],
  schema: z.object({
    colours: z
      .array(
        z.enum(['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'])
      )
      .length(4),
    mostOrFewest: z.enum(['most', 'fewest']),
    data: z.array(
      z.object({
        string: z.enum([
          'Black',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Purple',
          'Red',
          'Silver',
          'Yellow'
        ]),
        counters: z.number().int().min(1).max(6)
      })
    ),
    selectables: z.array(z.array(z.string()))
  }),
  simpleGenerator: () => {
    const countersA = randomIntegerInclusive(2, 6);

    // Must be less than countersA:
    const countersB = randomIntegerInclusive(1, 5, {
      constraint: x => x < countersA
    });

    const [countersC, countersD] = randomUniqueIntegersInclusive(1, 6, 2, {
      constraint: x => x !== countersA && x !== countersB && x !== Math.abs(countersA - countersB)
    });

    const colours = getRandomSubArrayFromArray(
      ['Black', 'Blue', 'Green', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Yellow'] as const,
      4
    );

    const [colourA, colourB, colourC, colourD] = colours;

    const data = shuffle([
      {
        string: colourA,
        counters: countersA
      },
      {
        string: colourB,
        counters: countersB
      },
      {
        string: colourC,
        counters: countersC
      },
      {
        string: colourD,
        counters: countersD
      }
    ]);

    const answer = Math.abs(countersA - countersB);

    const selectables = shuffle([
      ['A', answer.toLocaleString()],
      ['B', countersA.toLocaleString()],
      ['C', countersC.toLocaleString()],
      ['D', countersD.toLocaleString()]
    ]);

    const mostOrFewest = getRandomFromArray(['most', 'fewest'] as const);

    return {
      colours,
      selectables,
      data,
      mostOrFewest
    };
  },
  Component: props => {
    const {
      question: { colours, selectables, data, mostOrFewest },
      translate
    } = props;

    const [colourA, colourB] = colours;

    const colourAString = translate.colors[colourA]();

    const colourBString = translate.colors[colourB]();

    const translatedData = data.map(obj => {
      return {
        colour: colors.pacificBlue,
        string: translate.colors[obj.string](),
        counters: obj.counters
      };
    });

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          mostOrFewest === 'most'
            ? translate.ks1Instructions.selectHowManyMoreCarsAreXThanY(colourAString, colourBString)
            : translate.ks1Instructions.selectHowManyFewerCarsAreXThanY(
                colourBString,
                colourAString
              )
        }
        pdfTitle={
          mostOrFewest === 'most'
            ? translate.ks1PDFInstructions.tickHowManyMoreCarsAreXThanY(
                colourAString,
                colourBString
              )
            : translate.ks1PDFInstructions.tickHowManyFewerCarsAreXThanY(
                colourBString,
                colourAString
              )
        }
        correctAnswer={['A']}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={data.map(({ counters }) => counters)}
                columnNames={[
                  translate.tableHeaders.Colour(),
                  translate.tableHeaders.numberOfCarsInCarPark()
                ]}
                rowData={[...translatedData.map(({ string }) => [string])]}
                dimens={dimens}
                rowColors={filledArray(colors.pacificBlue, 4)}
                keyValue={translate.keys.numCars(1)}
                keyColor={colors.pacificBlue}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretPictograms1To1',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
