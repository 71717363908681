import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { useMemo } from 'react';
import { all, create, number } from 'mathjs';
import { z } from 'zod';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import PlaceValueChart from 'common/src/components/question/representations/Place Value Chart/PlaceValueChart';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { ScientificNotation } from 'common/src/utils/math';
import { arrayHasNoDuplicates, sortNumberArray } from 'common/src/utils/collections';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'awO',
  description: 'awO',
  keywords: ['Place value chart', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z
    .object({
      var1: z.number().int().min(1).max(9),
      var2: z.number().int().min(1).max(9),
      var3: z.number().int().min(1).max(9)
    })
    .refine(
      val => arrayHasNoDuplicates([val.var1, val.var2, val.var3]),
      'Numbers must all be different'
    )
    .refine(val => val.var1 !== val.var3)
    .refine(val => val.var2 !== val.var3),
  simpleGenerator: () => {
    const [var1, var2, var3] = randomUniqueIntegersInclusive(1, 9, 3);

    return { var1, var2, var3 };
  },
  Component: ({ question, translate }) => {
    const { var1, var2, var3 } = question;
    const numbers = useMemo(() => {
      const numberArray = [
        `${var1}.${var2}${var3}`,
        `${var1}.${var3}${var2}`,
        `${var2}.${var3}${var1}`
      ].map(item => number(math.evaluate(item)));

      return shuffle(numberArray, { random: seededRandom(question) });
    }, [var1, var2, var3, question]);

    const pvcItems = numbers.map((number, i) => ({
      value: number,
      component: (
        <PlaceValueChart
          key={i}
          number={ScientificNotation.fromNumber(number)}
          columnsToShow={[0, -1, -2]}
          counterVariant="number"
          headerVariant="shortName"
          headerHeight={56}
          dimens={{ height: 120, width: 300 }}
        />
      )
    }));
    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderNumbersFromSmallestToGreatest()}
        pdfTitle={translate.instructions.drawLinesToOrderNumbersFromSmallestToGreatest()}
        items={pvcItems}
        topLabel={translate.keywords.Smallest()}
        draggableVariant="tallRectangle"
        bottomLabel={translate.keywords.Greatest()}
        testCorrect={sortNumberArray(numbers)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awP',
  description: 'awP',
  keywords: ['Place value chart', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z.object({
    var1: z.number().min(1.1).max(8.9).step(0.1),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(11, 89, { constraint: x => x % 10 !== 0 }) / 10;

    return {
      var1,
      lookingForLarger: getRandomFromArray([true, false] as const)
    };
  },
  Component: ({ question: { lookingForLarger, var1 }, translate }) => {
    const var2 = number(math.evaluate(`${var1} * 0.1 + 0.1`));
    const var3 = number(math.evaluate(`${var1} * 0.1 - 0.1`));
    const var4 = var1;
    const var5 = number(math.evaluate(`10 - ${var1}`));
    const var6 = Math.floor(var1);
    const var7 = Math.ceil(var1);
    const var8 = Number(var1.toString().substring(0, 2) + '0' + var1.toString().substring(2));

    const numbers = getRandomSubArrayFromArray([var2, var3, var4, var5, var6, var7, var8], 6, {
      random: seededRandom({ lookingForLarger, var1 })
    });

    const format = (number: number) => {
      // Find var4 and add a 0 to the hundredths column
      if (number === var1) return number.toFixed(2);
      else return number.toString();
    };

    return (
      <QF10SelectNumbers
        title={
          lookingForLarger
            ? translate.instructions.whichNumsGreaterThanNum(var1.toLocaleString())
            : translate.instructions.whichNumsLessThanNum(var1.toLocaleString())
        }
        testCorrect={numbers.filter(it => (lookingForLarger ? it > var1 : it < var1))}
        items={numbers.map(number => ({
          value: number,
          component: format(number)
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'awQ',
  description: 'awQ',
  keywords: ['Place value', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z
    .object({
      var1: z.number().min(10.01).max(14.99).step(0.01),
      var2: z.number().min(5.01).max(9.99).step(0.01),
      var3: z.number().min(5.1).max(9.9).step(0.1),
      var4: z.number().min(0.51).max(0.99).step(0.01)
    })
    .refine(val => val.var2 !== val.var3, 'var2 and var3 should be different'),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1001, 1499) / 100;
    const var2 = randomIntegerInclusive(501, 999) / 100;
    const var3 = randomIntegerInclusive(51, 99, { constraint: x => x / 10 !== var2 }) / 10;
    const var4 = randomIntegerInclusive(51, 99) / 100;

    return { var1, var2, var3, var4 };
  },
  Component: ({ question, translate }) => {
    const items = useMemo(() => {
      return shuffle(Object.values(question), { random: seededRandom(question) });
    }, [question]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderNumbersFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()}
        items={items}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        testCorrect={sortNumberArray(items)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awR',
  description: 'awR',
  keywords: ['Place value', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z
    .object({
      var1: z.number().min(2.01).max(2.99).step(0.01),
      var2: z.number().min(1.01).max(1.99).step(0.01),
      var3: z.number().min(1.1).max(1.9).step(0.1),
      var4: z.number().min(0.51).max(0.99).step(0.01)
    })
    .refine(val => val.var2 !== val.var3, 'var2 and var3 should be different'),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(201, 299) / 100;
    const var2 = randomIntegerInclusive(101, 199) / 100;
    const var3 = randomIntegerInclusive(11, 19, { constraint: x => x / 10 !== var2 }) / 10;
    const var4 = randomIntegerInclusive(51, 99) / 100;

    return { var1, var2, var3, var4 };
  },
  Component: ({ question, translate }) => {
    const items = useMemo(() => {
      return shuffle(Object.values(question), { random: seededRandom(question) });
    }, [question]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderNumbersFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()}
        items={items}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        testCorrect={sortNumberArray(items)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'awS',
  description: 'awS',
  keywords: ['Place value', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z
    .object({
      var1: z.number().min(0.01).max(0.99).step(0.01),
      var2: z.number().min(0.01).max(0.99).step(0.01),
      var3: z.number().min(0.1).max(0.9).step(0.1),
      var4: z.number().min(0.02).max(0.09).step(0.01)
    })
    .refine(
      val => arrayHasNoDuplicates([val.var1, val.var2, val.var3]),
      'var1, var2 and var3 should be different'
    ),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 99) / 100;
    const var2 = randomIntegerInclusive(1, 99, { constraint: x => x / 100 !== var1 }) / 100;
    const var3 =
      randomIntegerInclusive(1, 9, { constraint: x => x / 10 !== var1 && x / 10 !== var2 }) / 10;
    const var4 = randomIntegerInclusive(2, 9) / 100;

    return { var1, var2, var3, var4 };
  },
  Component: ({ question, translate }) => {
    const items = useMemo(() => {
      return shuffle(Object.values(question), { random: seededRandom(question) });
    }, [question]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderNumbersFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()}
        items={items}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        testCorrect={sortNumberArray(items)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awT',
  description: 'awT',
  keywords: ['Place value', 'Tenths', 'Hundredths', 'Ordering'],
  schema: z.object({
    var1: z.number().min(1.11).max(8.88).step(0.01)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(111, 888) / 100;

    return { var1 };
  },
  Component: ({ question: { var1 }, translate }) => {
    const var2 = number(math.evaluate(`${var1} + 0.1`));
    const var3 = number(math.evaluate(`${var1} + 0.01`));
    const var4 = number(math.evaluate(`${var1} + 0.11`));

    const items = useMemo(() => {
      return shuffle([var1, var2, var3, var4], { random: seededRandom(var1) });
    }, [var1, var2, var3, var4]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderNumbersFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()}
        items={items}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        testCorrect={sortNumberArray(items)}
      />
    );
  }
});

////
// Small Step
////
const SmallStep = newSmallStepContent({
  smallStep: 'OrderDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
