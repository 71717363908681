import * as React from 'react';
import PortalConsumer from './PortalConsumer';
import PortalHost, { PortalContext, PortalMethods } from './PortalHost';

export type Props = {
  /**
   * Content of the `Portal`.
   */
  children: React.ReactNode;
};

/**
 * Portal allows rendering a component at a different place in the parent tree.
 * You can use it to render content which should appear above other elements, similar to `Modal`.
 * It requires a [`Portal.Host`](PortalHost) component to be rendered somewhere in the parent tree.
 *
 * ## Usage
 * ```tsx
 * import Text from 'common/src/components/typography/Text';
 * import { Portal } from 'common/src/components/portal';
 *
 * export default function MyComponent() {
 *   return (
 *     <Portal>
 *       <Text>This is rendered at a different place</Text>
 *     </Portal>
 *   )
 * }
 * ```
 */
export default class Portal extends React.Component<Props> {
  /** @see {@link PortalHost} */
  static Host = PortalHost;

  render() {
    const { children } = this.props;

    return (
      <PortalContext.Consumer>
        {manager => <PortalConsumer manager={manager as PortalMethods}>{children}</PortalConsumer>}
      </PortalContext.Consumer>
    );
  }
}
