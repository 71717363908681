import { ArrayObjectName } from './arrayObjects';
import { type SvgName } from '../assets/svg';

/** Object contain all the names of SVGs related to array objects. */
const arrayObjectImages: Record<ArrayObjectName, SvgName> = {
  'Green apple': 'Array_objects/AppleGreen',
  'Red apple': 'Array_objects/AppleRed',
  Banana: 'Array_objects/Banana',
  Pear: 'Array_objects/Pear',
  Orange: 'Array_objects/Orange',
  Pineapple: 'Array_objects/Pineapple',
  Strawberry: 'Array_objects/Strawberry'
};

/**
 * Get array object SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getArrayObjectSvgName = (object: ArrayObjectName): SvgName => {
  return arrayObjectImages[object];
};
