// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import Shape from './Shape';
import PositionAndDirection from './PositionAndDirection';
import Decimals from './Decimals';
import NegativeNumbers from './NegativeNumbers';
import ConvertingUnits from './ConvertingUnits';
import Volume from './Volume';

const Term = newTermContent({
  term: 'Summer',
  blocks: [Shape, PositionAndDirection, Decimals, NegativeNumbers, ConvertingUnits, Volume]
});
export default Term;
