import { CommonActions, type NavigationState } from '@react-navigation/native';
import { RootStackParamList } from './types';

/** Pop to the top screen in the navigation stack. This should be either the Home or PupilHome screens. */
export function popToTop() {
  return (state: NavigationState<RootStackParamList>) =>
    CommonActions.reset({
      ...state,
      routes: [state.routes[0]],
      index: 0
    });
}

type Route<RouteName extends keyof RootStackParamList> =
  // If it has optional params, make the params prop option, otherwise don't
  undefined extends RootStackParamList[RouteName]
    ? { name: RouteName; params?: RootStackParamList[RouteName] }
    : { name: RouteName; params: RootStackParamList[RouteName] };

/** Clear the navigation stack, replacing it with the given routes. */
export function resetTo<
  RouteNames extends [keyof RootStackParamList, ...(keyof RootStackParamList)[]]
>(
  ...routes: {
    // Typescript mapped type: map our non-empty array of route names to routes
    [K in keyof RouteNames]: Route<RouteNames[K]>;
  }
) {
  return (state: NavigationState<RootStackParamList>) =>
    CommonActions.reset({
      ...state,
      routes: routes,
      index: routes.length - 1
    });
}
