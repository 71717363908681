import { z } from 'zod';
import {
  getRequestQueryWithAuth,
  isHttpSuccess,
  postRequestQuery,
  postRequestQueryWithAuth,
  putRequestQuery
} from './requests';
import { type QuestionAttempt } from '../storage/useQuestionQueueStore';
import { type QuizSessionInfo } from '../storage/useQuizSessionStore';
import Logger from '../utils/logger';

/** Request data for creating a new quiz session from a Quiz PIN. */
type CreateNewQuizSessionPayload = {
  learningGroupShareCode: string;
  quizVersionShareShareCode: string;
};

/** Request data for creating a new quiz session from an assignment ID. */
type CreateAssignedQuizSessionPayload = {
  quizInstanceAssignmentId: number;
};

/** Schema for a Question, according to the API. */
const questionApiSchema = z.object({
  '@id': z.string(),
  displayOrder: z.number().int(),
  parameters: z.record(z.string(), z.unknown()).optional(),
  questionType: z.object({
    uid: z.string()
  })
});

const questionResultApiSchema = z.object({
  /**
   * Corresponds to @id from questionApiSchema. Can be in one of two formats:
   * - A string (seen in a POST response)
   * - An object with an @id key (seen in a GET or PUT response)
   *
   * Use `transform` to map both cases to the former type, which is also what the PUT request expects.
   */
  question: z
    .union([
      z.string(),
      z.object({
        '@id': z.string()
      })
    ])
    .transform(x => (typeof x === 'string' ? x : x['@id'])),
  answer: z.string(),
  isCorrect: z.boolean(),
  timeTaken: z.number(),
  attemptNumber: z.number().int().min(0),
  parameters: z.record(z.string(), z.unknown()).optional()
});

/** Schema for a Quiz Session, according to the API. */
const quizSessionApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  year: z.string(),
  randomiseQuestionParameters: z.boolean(),
  isComplete: z.boolean().optional().default(false),
  questions: questionApiSchema.array(),
  questionResults: questionResultApiSchema.array().optional().default([]),
  quizSounds: z.boolean().optional().default(false)
});

/** A Quiz Session, according to the API. */
type QuizSessionApiEntity = z.infer<typeof quizSessionApiSchema>;

/**
 * Create a Quiz Session from Quiz PIN and School Code.
 */
export const createNewQuizSession = async (
  payload: CreateNewQuizSessionPayload
): Promise<
  | QuizSessionInfo
  | 'network error'
  | 'http error'
  | 'not found'
  | 'quiz locked'
  | 'invalid response'
  | 'unknown error'
> => {
  const logTag = 'createNewQuizSession' as const;
  const endpoint = '/web/infinity/quiz-sessions';

  const result = await postRequestQuery(endpoint, payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        switch (result.response.status) {
          case 404:
            Logger.captureEvent('error', logTag, 'QUIZ_NOT_FOUND', {
              additionalMsg: payload.quizVersionShareShareCode,
              eventData: payload
            });

            return 'not found';
          case 403:
            Logger.captureEvent('warning', logTag, 'QUIZ_LOCKED', {
              additionalMsg: payload.quizVersionShareShareCode,
              eventData: payload
            });

            // Quiz locked due to expiry date (of the quiz share, not quiz session) or max uses exceeded
            return 'quiz locked';
          default:
            Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });
            return 'http error';
        }
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        Logger.captureEvent('fatal', logTag, 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  const parseResults = quizSessionApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', logTag, 'PARSE_ERROR', { eventData: parseResults });

    return 'invalid response';
  }

  // Validation success
  const parsedData: QuizSessionApiEntity = parseResults.data;

  return {
    id: parsedData.id,
    name: parsedData.name,
    randomiseQuestionParameters: parsedData.randomiseQuestionParameters,
    questions: parsedData.questions.map(question => ({
      id: question['@id'],
      uid: question.questionType.uid,
      displayOrder: question.displayOrder,
      parameters:
        question.parameters !== undefined ? JSON.stringify(question.parameters) : undefined
    })),
    quizSounds: parsedData.quizSounds,
    retryInfo: {
      type: 'quiz-pin',
      learningGroupShareCode: payload.learningGroupShareCode,
      quizVersionShareShareCode: payload.quizVersionShareShareCode
    }
  };
};

/**
 * Create a Quiz Session from quiz instance assignment ID. This is used in Infinity Plus, when starting a quiz from
 * the pupil home screen.
 */
export const createAssignedQuizSession = async (
  payload: CreateAssignedQuizSessionPayload
): Promise<
  | QuizSessionInfo
  | 'network error'
  | 'http error'
  | 'not found'
  | 'quiz locked'
  | 'invalid response'
  | 'logged out'
  | 'unknown error'
> => {
  const logTag = 'createAssignedQuizSession' as const;
  const endpoint = '/web/infinity/quiz-sessions/quiz-instance-assignment';

  const result = await postRequestQueryWithAuth(endpoint, payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });
        return 'network error';

      case 'http':
        switch (result.response.status) {
          case 404:
            Logger.captureEvent('error', logTag, 'QUIZ_NOT_FOUND', {
              additionalMsg: payload.quizInstanceAssignmentId.toString(),
              eventData: payload
            });

            return 'not found';
          default:
            Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });
            return 'http error';
        }
      case 'loggedOut':
        Logger.captureEvent('error', logTag, 'LOGGED_OUT', { eventData: result });
        return 'logged out';
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        Logger.captureEvent('fatal', logTag, 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  const parseResults = quizSessionApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', logTag, 'PARSE_ERROR', { eventData: parseResults });

    return 'invalid response';
  }

  // Validation success
  const parsedData: QuizSessionApiEntity = parseResults.data;

  return {
    id: parsedData.id,
    name: parsedData.name,
    randomiseQuestionParameters: parsedData.randomiseQuestionParameters,
    questions: parsedData.questions.map(question => ({
      id: question['@id'],
      uid: question.questionType.uid,
      displayOrder: question.displayOrder,
      parameters:
        question.parameters !== undefined ? JSON.stringify(question.parameters) : undefined
    })),
    quizSounds: parsedData.quizSounds,
    retryInfo: {
      type: 'assigned',
      quizInstanceAssignmentId: payload.quizInstanceAssignmentId
    }
  };
};

/**
 * Get an existing Quiz Session.
 *
 * This is useful for finding out our progress within the quiz, by looking at the questionResults.
 */
export const getExistingQuizSession = async (
  quizSessionId: string
): Promise<
  | Omit<QuizSessionInfo, 'retryInfo'>
  | 'network error'
  | 'http error'
  | 'not found'
  | 'invalid response'
  | 'logged out'
  | 'unknown error'
> => {
  const logTag = 'getExistingQuizSession' as const;
  const endpoint = `/web/infinity/quiz-sessions/${quizSessionId}`;
  const result = await getRequestQueryWithAuth(endpoint);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });
        return 'network error';
      case 'http':
        switch (result.response.status) {
          case 404:
            Logger.captureEvent('error', logTag, 'QUIZ_SESSION_NOT_FOUND', {
              additionalMsg: quizSessionId
            });
            return 'not found';
          default:
            Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });
            return 'http error';
        }
      case 'loggedOut':
        Logger.captureEvent('error', logTag, 'LOGGED_OUT', { eventData: result });
        return 'logged out';
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });
        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        Logger.captureEvent('fatal', logTag, 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const response = result.response;

  // Success - Validate the response
  const { data } = response;
  const parseResults = quizSessionApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', logTag, 'PARSE_ERROR', { eventData: parseResults });
    return 'invalid response';
  }

  // Validation success
  const parsedData: QuizSessionApiEntity = parseResults.data;

  return {
    id: parsedData.id,
    name: parsedData.name,
    randomiseQuestionParameters: parsedData.randomiseQuestionParameters,
    questions: parsedData.questions.map(question => ({
      id: question['@id'],
      uid: question.questionType.uid,
      displayOrder: question.displayOrder,
      parameters:
        question.parameters !== undefined ? JSON.stringify(question.parameters) : undefined
    })),
    quizSounds: parsedData.quizSounds,
    questionResults: parsedData.questionResults
  };
};

/**
 * Submit some question results to a Quiz Session.
 */
export const updateQuizSession = async (
  quizSessionId: string,
  payload: { isComplete?: boolean; questionResults?: QuestionAttempt[] }
): Promise<void | 'network error' | 'http error' | 'unknown error'> => {
  const logTag = 'updateQuizSession' as const;
  const endpoint = `/web/infinity/quiz-sessions/${quizSessionId}`;
  const result = await putRequestQuery(endpoint, payload);

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });

        return 'network error';
      case 'http':
        Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });

        return 'http error';
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });

        return 'unknown error';
      default:
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }

  // Success
  return;
};
