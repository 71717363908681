import { useEffect, useState } from 'react';
import useDebouncedCallback from './useDebouncedCallback';

/**
 * Return whether the current device is a mobile browser and in portrait orientation
 */
export default function useIsMobileBrowserAndPortrait() {
  const [dimens, setDimens] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleWindowSizeChange = useDebouncedCallback(
    () => setDimens({ width: window.innerWidth, height: window.innerHeight }),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, [handleWindowSizeChange]);

  const isMobile = Math.min(dimens.width, dimens.height) <= 768;
  const isPortrait = dimens.width < dimens.height;

  return isMobile && isPortrait;
}
