import { z } from 'zod';
import { SvgName } from '../../assets/svg';
import { getRandomFromArray, getRandomSubArrayFromArray } from '../random';

export const hexagons = [
  'Hexagons/hexagon_blue',
  'Hexagons/hexagon_green',
  'Hexagons/hexagon_pink',
  'Hexagons/hexagon_purple',
  'Hexagons/hexagon_white',
  'Hexagons/hexagon_yellow'
] as const;

export const pentagonHouses = [
  'Pentagon_houses/pentagon_house_blue',
  'Pentagon_houses/pentagon_house_green',
  'Pentagon_houses/pentagon_house_pink',
  'Pentagon_houses/pentagon_house_purple',
  'Pentagon_houses/pentagon_house_white',
  'Pentagon_houses/pentagon_house_yellow'
] as const;

export const pentagons = [
  'Pentagons/pentagon_blue',
  'Pentagons/pentagon_green',
  'Pentagons/pentagon_pink',
  'Pentagons/pentagon_purple',
  'Pentagons/pentagon_white',
  'Pentagons/pentagon_yellow'
] as const;

export const raTrapeziums = [
  'Right_angled_trapeziums/trapezium_RA_blue',
  'Right_angled_trapeziums/trapezium_RA_green',
  'Right_angled_trapeziums/trapezium_RA_pink',
  'Right_angled_trapeziums/trapezium_RA_purple',
  'Right_angled_trapeziums/trapezium_RA_white',
  'Right_angled_trapeziums/trapezium_RA_yellow'
] as const;

export const kites = [
  'Kite/kite_blue',
  'Kite/kite_green',
  'Kite/kite_pink',
  'Kite/kite_purple',
  'Kite/kite_white',
  'Kite/kite_yellow'
] as const;

export const parallelograms = [
  'Parallelogram/parallelogram_blue',
  'Parallelogram/parallelogram_green',
  'Parallelogram/parallelogram_pink',
  'Parallelogram/parallelogram_purple',
  'Parallelogram/parallelogram_white',
  'Parallelogram/parallelogram_yellow'
] as const;

export const rectangles = [
  'Rectangle/rectangle_blue',
  'Rectangle/rectangle_green',
  'Rectangle/rectangle_pink',
  'Rectangle/rectangle_purple',
  'Rectangle/rectangle_white',
  'Rectangle/rectangle_yellow'
] as const;

export const squares = [
  'Square/square_blue',
  'Square/square_green',
  'Square/square_pink',
  'Square/square_purple',
  'Square/square_white',
  'Square/square_yellow'
] as const;

export const trapeziums = [
  'Trapezium/trapezium_isosceles_blue',
  'Trapezium/trapezium_isosceles_green',
  'Trapezium/trapezium_isosceles_pink',
  'Trapezium/trapezium_isosceles_purple',
  'Trapezium/trapezium_isosceles_white',
  'Trapezium/trapezium_isosceles_yellow'
] as const;

export const irregularDodecagons = [
  'Dodecagons/Irregular_dodecagon1_blue',
  'Dodecagons/Irregular_dodecagon1_green',
  'Dodecagons/Irregular_dodecagon1_pink',
  'Dodecagons/Irregular_dodecagon1_purple',
  'Dodecagons/Irregular_dodecagon1_white',
  'Dodecagons/Irregular_dodecagon1_yellow'
] as const;

export const irregularHexagons = [
  'Hexagons/Irregular_hexagon1_blue',
  'Hexagons/Irregular_hexagon1_green',
  'Hexagons/Irregular_hexagon1_pink',
  'Hexagons/Irregular_hexagon1_purple',
  'Hexagons/Irregular_hexagon1_white',
  'Hexagons/Irregular_hexagon1_yellow'
] as const;

export const irregularNonagons = [
  'Nonagons/Irregular_nonagon_blue',
  'Nonagons/Irregular_nonagon_green',
  'Nonagons/Irregular_nonagon_pink',
  'Nonagons/Irregular_nonagon_purple',
  'Nonagons/Irregular_nonagon_white',
  'Nonagons/Irregular_nonagon_yellow'
] as const;

export const irregularOctagons = [
  'Octagons/Irregular_octagon1_blue',
  'Octagons/Irregular_octagon1_green',
  'Octagons/Irregular_octagon1_pink',
  'Octagons/Irregular_octagon1_purple',
  'Octagons/Irregular_octagon1_white',
  'Octagons/Irregular_octagon1_yellow'
] as const;

export const rhombuses = [
  'Rhombus/rhombus_blue',
  'Rhombus/rhombus_green',
  'Rhombus/rhombus_pink',
  'Rhombus/rhombus_purple',
  'Rhombus/rhombus_white',
  'Rhombus/rhombus_yellow'
] as const;

export const getRandomPolygonShapeNames = (quantity: number) => {
  return getRandomSubArrayFromArray(
    [
      'kites',
      'triangles',
      'squares',
      'rectangles',
      'hexagons',
      'pentagons',
      'heptagons',
      'nonagons',
      'octagons',
      'decagons'
    ] as const,
    quantity
  );
};

const nonExampleShapesSvgs: SvgName[] = [
  'Other_shapes/Square_non_example1',
  'Other_shapes/Square_non_example2',
  'Other_shapes/Square_non_example3',
  'Other_shapes/Square_non_example4',
  'Other_shapes/Nonagon_non_example1',
  'Other_shapes/Nonagon_non_example2',
  'Other_shapes/Nonagon_non_example3',
  'Other_shapes/Decagon_non_example1',
  'Other_shapes/Decagon_non_example2',
  'Other_shapes/Decagon_non_example3',
  'Other_shapes/Hexagon_non_example1',
  'Other_shapes/Hexagon_non_example2',
  'Other_shapes/Hexagon_non_example4',
  'Other_shapes/Octagon_non_example1',
  'Other_shapes/Octagon_non_example2',
  'Other_shapes/Octagon_non_example3',
  'Other_shapes/Heptagon_non_example1',
  'Other_shapes/Heptagon_non_example2',
  'Other_shapes/Pentagon_non_example1',
  'Other_shapes/Pentagon_non_example2',
  'Other_shapes/Pentagon_non_example3',
  'Other_shapes/Pentagon_non_example4',
  'Other_shapes/Pentagon_non_example5',
  'Other_shapes/Triangle_non_example1',
  'Other_shapes/Triangle_non_example2'
];

export function getRandomUniqueNonExampleShapeSvg(quantity: number) {
  return getRandomSubArrayFromArray([...nonExampleShapesSvgs] as const, quantity);
}
const twoDShapeArray = [
  'rhombuses',
  'parallelograms',
  'trapeziums',
  'rectangles',
  'squares'
] as const;
export const twoDShapeSchema = z.enum(twoDShapeArray);
export type TwoDShape = z.infer<typeof twoDShapeSchema>;

export const shapeProperties: Record<
  TwoDShape,
  { svgName: SvgName; parallelSidePairs: number; rightAngles: number; equalSidesPairs: number }
> = {
  squares: {
    svgName: getRandomFromArray([
      'Square/square_blue',
      'Square/square_green',
      'Square/square_pink',
      'Square/square_purple',
      'Square/square_yellow'
    ]) as SvgName,
    parallelSidePairs: 2,
    rightAngles: 4,
    equalSidesPairs: 2
  },
  rectangles: {
    svgName: getRandomFromArray([
      'Rectangle/rectangle_blue',
      'Rectangle/rectangle_green',
      'Rectangle/rectangle_pink',
      'Rectangle/rectangle_purple',
      'Rectangle/rectangle_yellow'
    ]) as SvgName,
    parallelSidePairs: 2,
    rightAngles: 4,
    equalSidesPairs: 2
  },
  trapeziums: {
    svgName: getRandomFromArray([
      'Trapezium/trapezium_isosceles_blue',
      'Trapezium/trapezium_isosceles_green',
      'Trapezium/trapezium_isosceles_pink',
      'Trapezium/trapezium_isosceles_purple',
      'Trapezium/trapezium_isosceles_yellow'
    ]) as SvgName,
    parallelSidePairs: 1,
    rightAngles: 0,
    equalSidesPairs: 1
  },
  parallelograms: {
    svgName: getRandomFromArray([
      'Parallelogram/parallelogram_blue',
      'Parallelogram/parallelogram_green',
      'Parallelogram/parallelogram_pink',
      'Parallelogram/parallelogram_purple',
      'Parallelogram/parallelogram_yellow'
    ]) as SvgName,
    parallelSidePairs: 2,
    rightAngles: 0,
    equalSidesPairs: 2
  },
  rhombuses: {
    svgName: getRandomFromArray([
      'Rhombus/rhombus_blue',
      'Rhombus/rhombus_green',
      'Rhombus/rhombus_pink',
      'Rhombus/rhombus_purple',
      'Rhombus/rhombus_yellow'
    ]) as SvgName,
    parallelSidePairs: 2,
    rightAngles: 0,
    equalSidesPairs: 2
  }
};

export function getRandom2DShape() {
  return getRandomFromArray([...twoDShapeArray] as const);
}

const markedLineShapes: {
  svgName: SvgName;
  shape: TwoDShape;
}[] = [
  {
    svgName: 'Other_shapes/Rhombus1_marked_lines_and_angles',
    shape: 'rhombuses'
  },
  {
    svgName: 'Other_shapes/Parallelogram1_marked_lines_and_angles',
    shape: 'parallelograms'
  },
  {
    svgName: 'Other_shapes/Trapezium1_marked_lines_and_angles',
    shape: 'trapeziums'
  },
  {
    svgName: 'Other_shapes/Rectangle1_marked_lines_and_angles',
    shape: 'rectangles'
  },
  {
    svgName: 'Other_shapes/Square_marked_lines_and_angles',
    shape: 'squares'
  }
];

export function getRandomUnqiueMarkedLineShape(quantity: number) {
  return getRandomSubArrayFromArray([...markedLineShapes] as const, quantity);
}

/**
 * @deprecated use rectilinearShapes
 */
export const rectilinearWithSideRatios = {
  Rectilinear_shape1_all_arrows: [5, 2, 4, 1, 1, 3],
  Compound_shape4_arrows: [10, 7, 4, 3, 3]
};
