import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import Text from 'common/src/components/typography/Text';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { parseMarkup } from '../../../markup';
import {
  QuadrilateralColors,
  QuadrilateralShapes,
  getQuadrilateralSvgName,
  quadrilateralColors,
  quadrilateralLabelPositionOffsets
} from '../../../utils/quadrilateralImages';
import { getRandomFromArray, seededRandom } from '../../../utils/random';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';

type UserAnswer = string;

type Props = {
  userAnswer?: UserAnswer[];
  setUserAnswer?: (answer: UserAnswer[]) => void;
  /**
   * @param labels An array of strings to hold the labels that go around the shape
   * Should be given in form [top, right, bottom, left]
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> labels should return an array of strings
   */
  labels: string[];
  /**
   * which shape to render
   */
  shape: QuadrilateralShapes;
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  color?: QuadrilateralColors;
  /**
   * Text to go after answer box
   */
  symbol?: string;
  centerLabel?: string;
  shapeSize?: 'large' | 'small';
  /**
   * This style effects the view and the text inside the view
   * Mainly used to transform the shape
   */
  rotate?: number;
  /** Optional param to override the image width */
  imageWidthScale?: number;
};

/**
 * This component renders a quadrilateral with labels on the sides.
 * The <ans/> string is used to annotate where a user answer input field is require.
 * At the moment the spacing works well for 3 labelled sides and below.
 * If we need 4 labels we will need to decrease the size of svgs via smallHeight and largeHeight
 */
export const LabelledQuadrilateral = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    labels,
    shape,
    color,
    dimens: { width, height },
    symbol,
    centerLabel = undefined,
    shapeSize = 'large',
    rotate = 0,
    imageWidthScale
  } = props;

  const displayMode = useContext(DisplayMode);

  // Parse all values to identify answer boxes
  const labelsParsed = labels.map(label => parseMarkup(label));

  const random = seededRandom({ shape, labels, shapeSize });
  const svgColor = color ?? getRandomFromArray(quadrilateralColors, { random });

  const isLargeShape = ['Square', 'Rhombus', 'Kite'].includes(shape);
  const smallWidth = isLargeShape ? width * 0.15 : width * 0.2;
  const largeWidth = isLargeShape
    ? width * (imageWidthScale ?? 0.25)
    : width * (imageWidthScale ?? 0.3);

  const svgName = getQuadrilateralSvgName(shape, svgColor);
  const svgInfo = getSvgInfo(svgName);
  const answerBoxWidth = displayMode === 'digital' ? 96 : 200;
  const answerBoxWidthWithText = answerBoxWidth + 46;

  const longestItemTextLength = labels.reduce((max, item) => Math.max(max, item.length), 0);
  const labelWidth =
    displayMode === 'digital' ? longestItemTextLength * 18 : longestItemTextLength * 25;

  // Find where answers are in order to find the required container size for both shapes and their
  // absolutely positioned labels.
  // This is to enable centering them.
  const isRightAnswer = labelsParsed[1] && labelsParsed[1].numberOfAns > 0;
  const isLeftAnswer =
    shape === 'Kite'
      ? (labelsParsed[2] && labelsParsed[2].numberOfAns > 0) ||
        (labelsParsed[3] && labelsParsed[3].numberOfAns > 0)
      : labelsParsed[3] && labelsParsed[3].numberOfAns > 0;
  const isRightLabel = labelsParsed[1] && labelsParsed[1].numberOfAns === 0;
  const isLeftLabel = labelsParsed[3] && labelsParsed[3].numberOfAns === 0;
  const isHorizontalAnswer = isRightAnswer || isLeftAnswer;
  const numOfHorizontalAnswers = isRightAnswer && isLeftAnswer ? 2 : isHorizontalAnswer ? 1 : 0;

  const largeContainerWidth = isHorizontalAnswer
    ? largeWidth + numOfHorizontalAnswers * (symbol ? answerBoxWidthWithText : answerBoxWidth)
    : largeWidth + 2 * labelWidth;
  const largeOffset =
    isRightAnswer && !isLeftAnswer
      ? -(symbol ? answerBoxWidthWithText : answerBoxWidth) / 2
      : isRightLabel && !isLeftLabel
      ? -labelWidth / 2
      : 0;

  const numOfHorizontalLabels =
    shape === 'Kite' ? (labelsParsed.length > 2 ? 2 : 1) : labelsParsed.length > 3 ? 2 : 1;
  const smallContainerWidth = smallWidth + numOfHorizontalLabels * labelWidth;
  const smallOffset = labelsParsed.length > 3 ? 0 : -labelWidth / 2;

  // get svg height
  const largeAdjustedHeight = largeWidth / svgInfo.aspectRatio;
  const smallAdjustedHeight = smallWidth / svgInfo.aspectRatio;

  // get positions for labels
  const largePositions = quadrilateralLabelPositionOffsets({
    adjustedWidth: largeWidth,
    adjustedHeight: largeAdjustedHeight,
    containerWidth: largeContainerWidth,
    shape,
    isTrailingText: symbol ? true : false,
    displayMode,
    rotate
  });
  const smallPositions = quadrilateralLabelPositionOffsets({
    adjustedWidth: smallWidth,
    adjustedHeight: smallAdjustedHeight,
    containerWidth: smallContainerWidth,
    shape,
    isTrailingText: symbol ? true : false,
    displayMode,
    rotate
  });

  const styles = useStyles(
    width,
    height,
    shapeSize === 'large' ? largeContainerWidth : smallContainerWidth
  );
  const textColor = ['Green', 'Purple'].includes(svgColor)
    ? colors.white
    : displayMode === 'digital'
    ? colors.prussianBlue
    : colors.black;
  const largeFont = displayMode === 'digital' ? 32 : 40;
  const smallFont = displayMode === 'digital' ? 22 : 32;

  // get labels for shapes and absolute positions
  const shapeLabels = (size: 'small' | 'large') => {
    const style = size === 'small' ? smallPositions.labelStyle : largePositions.labelStyle;
    let ansIndex = -1;
    return labels.map((_label, index) => {
      if (size === 'large' && labelsParsed[index].numberOfAns > 0) {
        ansIndex++;
        const currentBoxIndex = ansIndex;
        return (
          <View
            key={index}
            style={[
              largePositions.ansStyle[index],
              {
                position: 'absolute',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 5
              }
            ]}
          >
            <NoKeyboardTextInput
              value={userAnswer[currentBoxIndex]}
              onChangeText={text => {
                const newState = [...userAnswer];
                newState[currentBoxIndex] = text;
                setUserAnswer(newState);
              }}
            />
            {displayMode !== 'markscheme' && symbol && (
              <Text
                variant="WRN700"
                style={{
                  position: 'absolute',
                  left: answerBoxWidth + 5,
                  fontSize: largeFont,
                  alignSelf: displayMode !== 'digital' ? 'center' : undefined
                }}
              >
                {symbol}
              </Text>
            )}
          </View>
        );
      } else {
        return (
          <View
            key={index}
            style={[
              style[index],
              {
                position: 'absolute',
                minWidth: labelWidth,
                transform: [{ rotate: `${-rotate}deg` }]
              }
            ]}
          >
            <Text variant="WRN700" style={{ fontSize: smallFont, textAlign: 'center' }}>
              {labels[index]}
            </Text>
          </View>
        );
      }
    });
  };

  return (
    <View>
      <View
        style={[
          styles.imageWrapper,
          {
            left: shapeSize === 'large' ? largeOffset : smallOffset,
            transform: [{ rotate: `${rotate}deg` }]
          }
        ]}
      >
        {shapeLabels(shapeSize)}
        <View style={{ position: 'absolute', zIndex: 1 }}>
          {centerLabel && (
            <Text variant="WRN700" style={{ fontSize: largeFont, color: textColor }}>
              {centerLabel}
            </Text>
          )}
        </View>
        <AssetSvg name={svgName} width={shapeSize === 'large' ? largeWidth : smallWidth} />
      </View>
    </View>
  );
};

const useStyles = (width: number, height: number, containerWidth: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        containerHorizontal: {
          flexDirection: 'row',
          width: width,
          height: height,
          alignItems: 'center',
          justifyContent: 'space-evenly'
        },
        imageWrapper: {
          alignItems: 'center',
          justifyContent: 'center',
          width: containerWidth
        }
      }),
    [width, height, containerWidth]
  );
};
