import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
  NavigationState,
  PartialState
} from '@react-navigation/native';
import type { RootStackParamList } from './types';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],

  config: {
    screens:
      Platform.OS === 'web'
        ? {
            // This is a SPA, and we don't want the screen we're on to be reflected in the URL, so most pages should
            // just use an empty path. (Not providing the path property is equivalent to `path: ''`)
            // TODO: ...however, I don't want to make this change without more testing, and it breaks the cypress
            // tests, so leaving it commented out for now.
            // Home: {},
            // EnterSchoolCode: {},
            // EnterQuizPIN: {},
            // EnterPupilAccessCode: {},
            // PupilHome: {},
            // ScanQR: {},
            // Quiz: {},
            // QuizLoading: {},

            // Load an "offline" quiz directly from its tokens. This is a legacy way of doing things, before we had online
            // quiz shares.
            // e.g. https://<domain>/quiz?quizName=Test&tokens=aaa.aab
            // TODO: do we even want to keep this? do we ever use this feature?
            ImportQuiz: {
              path: '/quiz'
            },

            // Social media game has a separate path, keeping it distinct from the others
            SocialMediaGame: {
              path: '/have-a-go'
            }
          }
        : {
            // On native, we only care about routes where deep linking is concerned. All other screens don't matter.

            // Deep link for importing a quiz.
            // e.g. wreinfinity://quiz/AAAAAA-111111
            // Note that this is usually accessed by scanning a QR code with that URL _using the device's camera app_.
            // (When using the ScanQR screen, this route is obviously not used.)
            // These deep links work by specifying the scheme in app.json as 'wreinfinity'
            // TODO: this doesn't appear to be working, since the scheme is set to 'questionstore', not 'wreinfinity'
            ImportQuiz: {
              // TODO: ImportQuiz screen isn't expecting a code parameter, so this doesn't actually work. It probably
              // needs some refactoring if this is to work.
              path: '/quiz/:code' // 'code' should look like `AAAAAA-11111`
            }
          }
  },

  getStateFromPath: (path, options) => {
    const state = getStateFromPath(path, options) as
      | PartialState<NavigationState<RootStackParamList>>
      | undefined;
    const routes = state?.routes ?? [];

    // Customize getStateFromPath to add a backstack if there isn't one and we got here via deeplink
    if (routes.length === 1 && routes[0].name === 'ImportQuiz') {
      routes.unshift({ name: 'Home' });
    }

    return state;
  },

  getPathFromState(state, options) {
    return getPathFromState(state, options);
  }
};
