import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numbersDoNotExchange, numbersExchange } from '../../../../utils/exchanges';
import {
  randomIntegerInclusive,
  randomNumberInclusive,
  rejectionSample
} from '../../../../utils/random';
import { all, create, number } from 'mathjs';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD, SUB } from '../../../../constants';
import { countRange } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import { getImagesByAmount } from '../../../../utils/images';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';

const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const createHeader = (label: string, columnInfo: { color: string; textColor: string }) => {
  return {
    label,
    containerStyle: {
      backgroundColor: columnInfo.color
    },
    textStyle: {
      color: columnInfo.textColor
    }
  };
};

const Question1 = newQuestionContent({
  uid: 'aU0',
  description: 'aU0',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Integer',
    'Place value chart',
    'Addition',
    'Total'
  ],
  schema: z.object({
    tensA: z.number().int().min(0).max(6),
    onesA: z.number().int().min(0).max(6),
    tenthsA: z.number().int().min(0).max(6),
    hundredthsA: z.number().int().min(0).max(6),
    onesB: z.number().int().min(0).max(6),
    tenthsB: z.number().int().min(0).max(6),
    hundredthsB: z.number().int().min(0).max(6),
    rowASum: z.number().min(0).max(66.66),
    rowBSum: z.number().min(0).max(6.66)
  }),
  simpleGenerator: () => {
    const { tensA, onesA, tenthsA, hundredthsA, onesB, tenthsB, hundredthsB, rowASum, rowBSum } =
      rejectionSample(
        () => {
          const [tensA, onesA, tenthsA, hundredthsA, onesB, tenthsB, hundredthsB] = countRange(
            7
          ).map(() => randomIntegerInclusive(0, 6));

          const rowASum = number(
            math.evaluate(`(${tensA} * 10) + ${onesA} + (${tenthsA} / 10) + (${hundredthsA} / 100)`)
          );
          const rowBSum = number(
            math.evaluate(`${onesB} + ${tenthsB} / 10 + ${hundredthsB} / 100`)
          );

          return {
            tensA,
            onesA,
            tenthsA,
            hundredthsA,
            onesB,
            tenthsB,
            hundredthsB,
            rowASum,
            rowBSum
          };
        },
        ({ rowASum, rowBSum }) => numbersDoNotExchange(rowASum, rowBSum)
      );

    return { tensA, onesA, tenthsA, hundredthsA, onesB, tenthsB, hundredthsB, rowASum, rowBSum };
  },
  Component: props => {
    const {
      question: {
        tensA,
        onesA,
        tenthsA,
        hundredthsA,
        onesB,
        tenthsB,
        hundredthsB,
        rowASum,
        rowBSum
      },
      translate,
      displayMode
    } = props;

    const answer = number(math.evaluate(`${rowASum} + ${rowBSum}`));

    const tensAImages = getImagesByAmount('Place_value/GreyCounter', tensA);
    const onesAImages = getImagesByAmount('Place_value/GreyCounter', onesA);
    const tenthsAImages = getImagesByAmount('Place_value/GreyCounter', tenthsA);
    const hundredthsAImages = getImagesByAmount('Place_value/GreyCounter', hundredthsA);

    const onesBImages = getImagesByAmount('Place_value/GreyCounter', onesB);
    const tenthsBImages = getImagesByAmount('Place_value/GreyCounter', tenthsB);
    const hundredthsBImages = getImagesByAmount('Place_value/GreyCounter', hundredthsB);

    const scalesToCheck = [
      tensA > 0 ? calcRowOfImagesScaleFactor(172, 50, tensAImages, 8) : 10000,
      onesA > 0 ? calcRowOfImagesScaleFactor(172, 50, onesAImages, 8) : 10000,
      tenthsA > 0 ? calcRowOfImagesScaleFactor(172, 50, tenthsAImages, 8) : 10000,
      hundredthsA > 0 ? calcRowOfImagesScaleFactor(172, 50, hundredthsAImages, 8) : 10000,
      onesB > 0 ? calcRowOfImagesScaleFactor(172, 50, onesBImages, 8) : 10000,
      tenthsB > 0 ? calcRowOfImagesScaleFactor(172, 50, tenthsBImages, 8) : 10000,
      hundredthsB > 0 ? calcRowOfImagesScaleFactor(172, 50, hundredthsBImages, 8) : 10000
    ];

    const scaleFactor = Math.min(...scalesToCheck);

    // Correct data
    const data = [
      [tensAImages, onesAImages, tenthsAImages, hundredthsAImages],
      [[], onesBImages, tenthsBImages, hundredthsBImages]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteAddition()}
        sentence={`${rowASum.toLocaleString()} ${ADD} ${rowBSum.toLocaleString()} = <ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'center', flex: 0.5 }}
        testCorrect={[answer.toString()]}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              createHeader(translate.keywords.Tens(), placeValueColumnInfo[1]),
              createHeader(translate.keywords.Ones(), placeValueColumnInfo[0]),
              createHeader(translate.keywords.Tenths(), placeValueColumnInfo[-1]),
              createHeader(translate.keywords.Hundredths(), placeValueColumnInfo[-2])
            ]}
            tableData={data.map(row =>
              row.map((images, i) => (
                <RowOfImages
                  key={i}
                  containerStyle={{
                    height: 50
                  }}
                  style={{ gap: 8 }}
                  images={images}
                  scaleFactor={scaleFactor}
                />
              ))
            )}
            tableStyle={{ width: dimens.width - 24 }}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
            tenthsColumnIndex={2}
            rowSeperatorExtraSymbolLeft={ADD}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aU1',
  description: 'aU1',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundreds',
    'Integer',
    'Column addition',
    'Addition',
    'Total'
  ],
  schema: z.object({
    var1: z
      .number()
      .min(10.01)
      .max(89.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1'),
    var2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1')
  }),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomNumberInclusive(10.01, 89.99, 2);
        const var2 = randomNumberInclusive(1.01, 9.99, 2);
        return { var1, var2 };
      },
      // Only permit them if they do not exchange
      ({ var1, var2 }) =>
        numbersDoNotExchange(var1, var2) &&
        number(math.evaluate(`${var1} % 0.1`)) !== 0 &&
        number(math.evaluate(`${var2} % 0.1`)) !== 0
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = number(math.evaluate(`${var1} + ${var2}`));
    const answerMissingDigits = getDecimalMissingDigits(number3, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aU2',
  description: 'aU2',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Integer',
    'Place value chart',
    'Subtraction',
    'Total'
  ],
  schema: z.object({
    tensA: z.number().int().min(0).max(8),
    onesA: z.number().int().min(0).max(8),
    tenthsA: z.number().int().min(0).max(8),
    hundredthsA: z.number().int().min(0).max(6),
    rowASum: z.number().min(0).max(88.86),
    rowBSum: z.number().min(0).max(8.86)
  }),
  simpleGenerator: () => {
    const { tensA, onesA, tenthsA, hundredthsA, rowASum, rowBSum } = rejectionSample(
      () => {
        const [tensA, onesA, tenthsA] = countRange(3).map(() => randomIntegerInclusive(0, 8));
        const hundredthsA = randomIntegerInclusive(0, 6);

        //second number needs to be smaller or equal to the first number to avoid exchanges
        const onesB = randomIntegerInclusive(0, onesA);
        const tenthsB = randomIntegerInclusive(0, tenthsA);
        const hundredthsB = randomIntegerInclusive(0, hundredthsA);

        const rowASum = number(
          math.evaluate(`(${tensA} * 10) + ${onesA} + (${tenthsA} / 10) + (${hundredthsA} / 100)`)
        );
        const rowBSum = number(math.evaluate(`${onesB} + ${tenthsB} / 10 + ${hundredthsB} / 100`));

        return {
          tensA,
          onesA,
          tenthsA,
          hundredthsA,
          rowASum,
          rowBSum
        };
      },
      ({ rowASum, rowBSum }) => numbersDoNotExchange(rowASum, -rowBSum)
    );

    return { tensA, onesA, tenthsA, hundredthsA, rowASum, rowBSum };
  },
  Component: props => {
    const {
      question: { tensA, onesA, tenthsA, hundredthsA, rowASum, rowBSum },
      translate,
      displayMode
    } = props;

    const answer = number(math.evaluate(`${rowASum} - ${rowBSum}`));

    const tensAImages = getImagesByAmount('Place_value/GreyCounter', tensA);
    const onesAImages = getImagesByAmount('Place_value/GreyCounter', onesA);
    const tenthsAImages = getImagesByAmount('Place_value/GreyCounter', tenthsA);
    const hundredthsAImages = getImagesByAmount('Place_value/GreyCounter', hundredthsA);

    const scalesToCheck = [
      tensA > 0 ? calcRowOfImagesScaleFactor(172, 50, tensAImages, 8) : 10000,
      onesA > 0 ? calcRowOfImagesScaleFactor(172, 50, onesAImages, 8) : 10000,
      tenthsA > 0 ? calcRowOfImagesScaleFactor(172, 50, tenthsAImages, 8) : 10000,
      hundredthsA > 0 ? calcRowOfImagesScaleFactor(172, 50, hundredthsAImages, 8) : 10000
    ];

    const scaleFactor = Math.min(...scalesToCheck);

    // Correct data
    const data = [[tensAImages, onesAImages, tenthsAImages, hundredthsAImages]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteSubtraction()}
        sentence={`${rowASum.toLocaleString()} ${SUB} ${rowBSum.toLocaleString()} = <ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'center', flex: 0.5 }}
        testCorrect={[answer.toString()]}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              createHeader(translate.keywords.Tens(), placeValueColumnInfo[1]),
              createHeader(translate.keywords.Ones(), placeValueColumnInfo[0]),
              createHeader(translate.keywords.Tenths(), placeValueColumnInfo[-1]),
              createHeader(translate.keywords.Hundredths(), placeValueColumnInfo[-2])
            ]}
            tableData={data.map(row =>
              row.map((images, i) => (
                <RowOfImages
                  key={i}
                  containerStyle={{
                    height: 50
                  }}
                  style={{ gap: 8 }}
                  images={images}
                  scaleFactor={scaleFactor}
                />
              ))
            )}
            tableStyle={{ width: dimens.width - 24 }}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
            tenthsColumnIndex={2}
            rowSeperatorExtraSymbolLeft={ADD}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aU3',
  description: 'aU3',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundreds',
    'Integer',
    'Column subtraction',
    'Subtract',
    'Total'
  ],
  schema: z.object({
    var1: z
      .number()
      .min(10.01)
      .max(99.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1'),
    var2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1')
  }),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomNumberInclusive(10.01, 99.99, 2);
        const var2 = randomNumberInclusive(1.01, 9.99, 2);
        return { var1, var2 };
      },
      // Only permit them if they do not exchange
      ({ var1, var2 }) =>
        numbersDoNotExchange(var1, -var2) &&
        number(math.evaluate(`${var1} % 0.1`)) !== 0 &&
        number(math.evaluate(`${var2} % 0.1`)) !== 0
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = number(math.evaluate(`${var1} - ${var2}`));
    const answerMissingDigits = getDecimalMissingDigits(number3, 2);
    const markSchemeAnswer = getMarkSchemeAnswer(number3, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer }
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aU4',
  description: 'aU4',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundreds',
    'Integer',
    'Column addition',
    'Addition',
    'Total'
  ],
  schema: z.object({
    var1: z
      .number()
      .min(10.01)
      .max(89.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1'),
    var2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1')
  }),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomNumberInclusive(10.01, 89.99, 2);
        const var2 = randomNumberInclusive(1.01, 9.99, 2);
        return { var1, var2 };
      },
      // Only permit them if they do not exchange
      ({ var1, var2 }) =>
        numbersExchange(var1, var2) &&
        number(math.evaluate(`${var1} % 0.1`)) !== 0 &&
        number(math.evaluate(`${var2} % 0.1`)) !== 0
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = number(math.evaluate(`${var1} + ${var2}`));
    const answerMissingDigits = getDecimalMissingDigits(number3, 2);
    const markSchemeAnswer = getMarkSchemeAnswer(number3, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aU5',
  description: 'aU5',
  keywords: [
    'Decimal',
    'Tens',
    'Ones',
    'Tenths',
    'Hundreds',
    'Integer',
    'Column subtraction',
    'Subtract',
    'Total'
  ],
  schema: z.object({
    var1: z
      .number()
      .min(10.01)
      .max(99.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1'),
    var2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(x => x % 0.1 !== 0, 'cannot be a multiple of 0.1')
  }),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomNumberInclusive(10.01, 99.99, 2);
        const var2 = randomNumberInclusive(1.01, 9.99, 2);
        return { var1, var2 };
      },
      // Only permit them if they do not exchange
      ({ var1, var2 }) =>
        numbersExchange(var1, -var2) &&
        number(math.evaluate(`${var1} % 0.1`)) !== 0 &&
        number(math.evaluate(`${var2} % 0.1`)) !== 0
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = number(math.evaluate(`${var1} - ${var2}`));
    const answerMissingDigits = getDecimalMissingDigits(number3, 2);
    const markSchemeAnswer = getMarkSchemeAnswer(number3, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
