import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  amD,
  amE,
  amF,
  amG,
  amH,
  amI
} from 'common/src/SchemeOfLearning/Year 5/Autumn/MultiplicationAndDivisionA/4CommonFactors';

////
// Questions
////

const Question1 = { ...amD, uid: 'anj', description: 'anj' as const };

const Question2 = { ...amE, uid: 'ank', description: 'ank' as const };

const Question3 = { ...amF, uid: 'anl', description: 'anl' as const };

const Question4 = { ...amG, uid: 'anm', description: 'anm' as const };

const Question5 = { ...amH, uid: 'ann', description: 'ann' as const };

const Question6 = { ...amI, uid: 'ano', description: 'ano' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CommonFactors',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
