import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import {
  Fraction,
  improperFractionToMixedNumber,
  mixedNumberToImproperFraction,
  simplify,
  fractionToDecimal,
  compareFractions,
  fractionArithmetic
} from 'common/src/utils/fractions';
import { ADD } from 'common/src/constants';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import { fractionSchema } from 'common/src/utils/zod';
import QF36ContentAndSentenceDrag from 'common/src/components/question/questionFormats/QF36ContentAndSentenceDrag';
import MultiPieChart, {
  calculateRadius
} from 'common/src/components/question/representations/MultiPieChart';
import PieChart from 'common/src/components/question/representations/PieChart';
import Text from 'common/src/components/typography/Text';
import { PieChartColors } from 'common/src/theme/colors';
import { View } from 'react-native';
import ContentBox from 'common/src/components/molecules/ContentBox';
import { compareFloats } from '../../../../utils/math';
import { filledArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqJ',
  description: 'aqJ',
  keywords: ['Addition', 'Mixed numbers', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(1).max(8),
    number3: z.number().int().min(4).max(9),
    number4: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number3 = randomIntegerInclusive(4, 9);

    // number2 + number4 < number3
    const number2 = randomIntegerInclusive(1, number3 - 2);
    const number4 = randomIntegerInclusive(1, number3 - number2 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // Simplify fractions in sentence
    const [simplifiedNumber2, simplifiedNumber3a] = simplify(number2, number3);
    const [simplifiedNumber4, simplifiedNumber3b] = simplify(number4, number3);

    // answer1 === number1
    const answer2 = number2 + number4;
    // Simplify the answer
    const [simplfiedAnswer2, simplifiedAnswer3] = simplify(answer2, number3);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[
          number1.toString(),
          simplfiedAnswer2.toString(),
          simplifiedAnswer3.toString()
        ]}
        sentence={`<frac w='${number1}' n='${simplifiedNumber2}' d='${simplifiedNumber3a}'/> ${ADD} <frac n='${simplifiedNumber4}' d='${simplifiedNumber3b}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqK',
  description: 'aqK',
  keywords: ['Addition', 'Fractions', 'Mixed numbers'],
  schema: z.object({
    answerOptionFractions: fractionSchema().array().length(3),
    fractionIntegers: z.array(z.number().int().min(1).max(5)),
    missingSlicesA: z.array(z.number().int().min(0).max(6)),
    missingSlicesB: z.array(z.number().int().min(0).max(5))
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number7 = number1 - 1;
    const number3 = randomIntegerInclusive(2, 7);
    const number2 = randomIntegerInclusive(1, number3 - 1);

    const number4 = randomIntegerInclusive(1, 4);
    const number6 = randomIntegerInclusive(2, 6, {
      constraint: x => x !== number3
    });
    const number5 = randomIntegerInclusive(1, number6 - 1);

    const number8 = number6 - number5;
    const number9 = number6;

    const answerOptionFractions: Fraction[] = [
      [number2, number3],
      [number5, number6],
      [number8, number9]
    ];

    const fractionIntegers = [number1, number4, number7];

    const missingSlicesA = randomUniqueIntegersInclusive(0, number3 - 1, number3 - number2);
    const missingSlicesB = randomUniqueIntegersInclusive(0, number6 - 1, number6 - number5);

    return { answerOptionFractions, fractionIntegers, missingSlicesA, missingSlicesB };
  },
  Component: props => {
    const {
      question: { answerOptionFractions, fractionIntegers, missingSlicesA, missingSlicesB },
      translate,
      displayMode
    } = props;

    const [[number2, number3], [number5, number6], [number8, number9]] = answerOptionFractions;
    const [number1, number4, number7] = fractionIntegers;

    const answerOptions = [
      {
        component: (
          <TextStructure
            sentence={`<frac w='${number1}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number4}' n='${number5}' d='${number6}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ),
        value:
          number1 +
          number4 +
          fractionToDecimal(number2, number3) +
          fractionToDecimal(number5, number6)
      },
      {
        component: (
          <TextStructure
            sentence={`<frac w='${number7}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number4}' n='${number8}' d='${number9}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ),
        value:
          number7 +
          number4 +
          fractionToDecimal(number2, number3) +
          fractionToDecimal(number8, number9)
      },
      {
        component: (
          <TextStructure
            sentence={`<frac w='${number4}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number4}' n='${number2}' d='${number3}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ),
        value: 2 * (number4 + fractionToDecimal(number2, number3))
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToMatchCalcToRep()}
        pdfTitle={translate.instructions.useCardsToMatchCalcToRep()}
        items={shuffledAnswerOptions}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
        Content={({ dimens }) => {
          // Get 2 colors
          const [colorA, colorB] = getRandomSubArrayFromArray(PieChartColors, 2, {
            random: seededRandom({ number3, number6, missingSlicesA, missingSlicesB })
          });

          // Calculate how many rows there are going to be to be used to calculate maximal pie chart radius
          const numberOfRows = number1 > number4 ? Math.ceil(number1 / 2) : Math.ceil(number4 / 2);
          const pieChartRadius = calculateRadius(dimens, 6, numberOfRows);

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }
              ]}
            >
              <MultiPieChart
                numberOfPieCharts={number1}
                slicesPerChart={number3}
                color={colorA}
                radius={pieChartRadius}
                dimens={dimens}
                chartsPerRow={2}
              />
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, number3)}
                missingSlices={missingSlicesA}
                color={colorA}
                radius={pieChartRadius}
              />
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: numberOfRows * 2 * (pieChartRadius + 10),
                  paddingHorizontal: 10
                }}
              >
                <Text variant="WRN400">{`${ADD}`}</Text>
              </View>
              <MultiPieChart
                numberOfPieCharts={number4}
                slicesPerChart={number6}
                color={colorB}
                radius={pieChartRadius}
                dimens={dimens}
                chartsPerRow={2}
              />
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, number6)}
                missingSlices={missingSlicesB}
                color={colorB}
                radius={pieChartRadius}
              />
            </View>
          );
        }}
        sentenceStyle={{ justifyContent: 'center' }}
        sentence={'<ans/>'}
        testCorrect={[
          number1 +
            number4 +
            fractionToDecimal(number2, number3) +
            fractionToDecimal(number5, number6)
        ]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aqL',
  description: 'aqL',
  keywords: ['Addition', 'Fractions', 'Mixed numbers'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(8),
      number2: z.number().int().min(1).max(14),
      number3: z.number().int().min(3).max(15),
      number4: z.number().int().min(1).max(8),
      number5: z.number().int().min(1).max(9),
      number6: z.number().int().min(3).max(10)
    })
    .refine(val => val.number2 < val.number3, 'number2 must be less than number3')
    .refine(val => val.number5 < val.number6, 'number5 must be less than number6')
    .refine(
      val => val.number2 * val.number6 + val.number5 * val.number3 < val.number3 * val.number6,
      'fractions must not sum to improper fraction'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 8);
    const number4 = randomIntegerInclusive(1, 8);

    // Constraints to fulfil:
    // - number3, number6 must be unique
    // - number2 < number3
    // - number5 < number6
    // - Fractions must not sum to improper fraction
    const { number2, number3, number5, number6 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(3, 15);
        const number6 = randomIntegerInclusive(3, 10, {
          constraint: x => x !== number3
        });

        const number2 = randomIntegerInclusive(1, number3 - 1);
        const number5 = randomIntegerInclusive(1, number3 - 1);

        return { number2, number3, number5, number6 };
      },
      ({ number2, number3, number5, number6 }) =>
        number2 * number6 + number5 * number3 < number3 * number6
    );

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const answer1 = number1 + number4;
    const answer2 = number2 * number6 + number5 * number3;
    const answer3 = number3 * number6;

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);

    const [simplifiedAns2, simplifiedAns3] = simplify(answer2, answer3);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[answer1.toString(), simplifiedAns2.toString(), simplifiedAns3.toString()]}
        sentence={`<frac w='${number1}' n='${simplifiedNum2}' d='${simplifiedNum3}'/> ${ADD} <frac w='${number4}' n='${simplifiedNum5}' d='${simplifiedNum6}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqM',
  description: 'aqM',
  keywords: ['Addition', 'Fractions', 'Mixed numbers'],
  schema: z.object({
    name: nameSchema,
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(8),
    number3: z.number().int().min(3).max(10),
    number4: z.number().int().min(1).max(5),
    number5: z.number().int().min(1).max(5),
    number6: z.number().int().min(3).max(6)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(1, 5);
    const number3 = randomIntegerInclusive(3, 10);
    const number2 = randomIntegerInclusive(1, 8, {
      constraint: x => x < number3
    });

    const number4 = randomIntegerInclusive(1, 5);
    const number6 = randomIntegerInclusive(3, 6, {
      constraint: x => x !== number2
    });
    const number5 = randomIntegerInclusive(1, 5, {
      constraint: x => x < number6
    });

    return { name, number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { name, number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    // Calculate answer
    const [numerator1, denominator1] = mixedNumberToImproperFraction(number1, number2, number3);
    const [numerator2, denominator2] = mixedNumberToImproperFraction(number4, number5, number6);

    // Convert back to mixed number (includes simplification)
    const numeratorSum = numerator1 * denominator2 + numerator2 * denominator1;
    const [ansInteger, ansNumerator, ansDenominator] = improperFractionToMixedNumber(
      numeratorSum,
      denominator1 * denominator2
    );

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howFarCycledFraction(
          name,
          `<frac w='${number1}' n='${simplifiedNum2}' d='${simplifiedNum3}'/>`,
          `<frac w='${number4}' n='${simplifiedNum5}' d='${simplifiedNum6}'/>`
        )}
        inputMaxCharacters={2}
        testCorrect={userAnswer => {
          return ansNumerator === 0
            ? userAnswer[0] === ansInteger.toString()
            : userAnswer[0] === ansInteger.toString() &&
                compareFractions([userAnswer[1], userAnswer[2]], [ansNumerator, ansDenominator]);
        }}
        sentence={
          ansNumerator === 0
            ? `<ans/> ${translate.units.km()}`
            : `<frac wAns='' nAns='' dAns=''/> ${translate.units.km()}`
        }
        customMarkSchemeAnswer={{
          answersToDisplay:
            ansNumerator === 0
              ? [ansInteger.toLocaleString()]
              : [
                  ansInteger.toLocaleString(),
                  ansNumerator.toLocaleString(),
                  ansDenominator.toLocaleString()
                ],
          answerText:
            ansNumerator === 0 ? undefined : translate.markScheme.fractionMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqN',
  description: 'aqN',
  keywords: ['Addition', 'Fractions', 'Mixed numbers'],
  schema: z
    .object({
      wholeA: z.number().int().min(51).max(249),
      numeratorA: z.number().int().min(1).max(11),
      denominatorA: z.number().int().min(2).max(12),
      wholeB: z.number().int().min(51).max(249),
      numeratorB: z.number().int().min(1).max(11),
      denominatorB: z.number().int().min(2).max(12)
    })
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    )
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(51, 249, {
      constraint: x => x % 10 !== 0
    });

    const wholeB = randomIntegerInclusive(51, 249, {
      constraint: x => x % 10 !== 0
    });

    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 12, 2);

    const numeratorA = randomIntegerInclusive(1, Math.floor(denominatorA / 2), {
      constraint: x => {
        const simplifiedFraction = simplify(x, denominatorA);

        // numeratorA / denominatorA must produce a fraction already in its simplest form.
        return simplifiedFraction[0] === x;
      }
    });

    const numeratorB = randomIntegerInclusive(1, Math.floor(denominatorB / 2), {
      constraint: x => {
        const simplifiedFraction = simplify(x, denominatorB);

        // numeratorB / denominatorB must produce a fraction already in its simplest form.
        return simplifiedFraction[0] === x;
      }
    });

    return { wholeA, numeratorA, denominatorA, wholeB, numeratorB, denominatorB };
  },
  Component: props => {
    const {
      question: { wholeA, numeratorA, denominatorA, wholeB, numeratorB, denominatorB },
      translate,
      displayMode
    } = props;

    const [expectedNumerator, expectedDenominator] = fractionArithmetic(
      [numeratorA, denominatorA],
      [numeratorB, denominatorB],
      ADD
    );

    const givenFact = (
      <TextStructure
        sentence={`<frac n='${numeratorA}' d='${denominatorA}'/> ${ADD} <frac n='${numeratorB}' d='${denominatorB}'/> = <frac n='${expectedNumerator}' d='${expectedDenominator}'/>`}
        fractionContainerStyle={{ height: 48 }}
      />
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1100}
        sentence={`<frac w='${wholeA.toLocaleString()}' n='${numeratorA}' d='${denominatorA}'/> ${ADD} <frac w='${wholeB.toLocaleString()}' n='${numeratorB}' d='${denominatorB}'/> = <frac wAns='' nAns='' dAns=''/>`}
        title={translate.instructions.useGivenFactToCompleteCalc()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer, [wholeA + wholeB, expectedNumerator, expectedDenominator])
        }
        inputMaxCharacters={3}
        Content={
          <ContentBox
            containerStyle={{
              height: displayMode === 'digital' ? undefined : 300,
              width: displayMode === 'digital' ? undefined : 500,
              justifyContent: 'center'
            }}
          >
            {givenFact}
          </ContentBox>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (wholeA + wholeB).toLocaleString(),
            expectedNumerator.toLocaleString(),
            expectedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqO',
  description: 'aqO',
  keywords: ['Addition', 'Fractions', 'Mixed numbers', 'Missing value'],
  schema: z.object({
    questionInteger: z.number().int().min(2).max(9),
    questionFraction: fractionSchema(),
    answerIntegers: z.array(z.number().int().min(1).max(9)).length(4),
    answerOptionsFractions: fractionSchema().array().length(4)
  }),
  simpleGenerator: () => {
    // Generate integer parts
    const number1 = randomIntegerInclusive(2, 9);

    const number4 = randomIntegerInclusive(1, number1 - 1);
    const number7 = number1 - number4;

    const number10 = randomIntegerInclusive(1, 9, {
      constraint: x => x !== number4 && x !== number7
    });
    const number13 = number7;

    // Generate correct answer fractions
    const [number6, number9] = randomUniqueIntegersInclusive(3, 7, 2);
    const { number5, number8 } = rejectionSample(
      () => {
        const number5 = randomIntegerInclusive(1, number6 - 1);
        const number8 = randomIntegerInclusive(1, number9 - 1);

        return { number5, number8 };
      },
      ({ number5, number8 }) => number5 * number9 + number8 * number6 < number6 * number9
    );

    // Calculate sum for base fraction (given)
    const number3 = number6 * number9;
    const number2 = number5 * number9 + number8 * number6;

    const questionInteger = number1;
    const questionFraction: Fraction = [number2, number3];

    const answerIntegers = [number4, number7, number10, number13];
    const answerOptionsFractions: Fraction[] = [
      [number5, number6],
      [number8, number9],
      [number8, number9],
      [number5, number6]
    ];

    return { questionInteger, questionFraction, answerIntegers, answerOptionsFractions };
  },
  Component: props => {
    const {
      question: { questionInteger, questionFraction, answerIntegers, answerOptionsFractions },
      translate,
      displayMode
    } = props;

    const displayedQuestionFraction = simplify(questionFraction[0], questionFraction[1]);

    const answerOptions = answerOptionsFractions.map((frac, index) => {
      const simplifiedFraction = simplify(frac[0], frac[1]);

      return {
        component: (
          <TextStructure
            sentence={`<frac w='${answerIntegers[
              index
            ].toLocaleString()}' n='${simplifiedFraction[0].toLocaleString()}' d='${simplifiedFraction[1].toLocaleString()}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: answerIntegers[index] + fractionToDecimal(...frac)
      };
    });

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.instructions.dragFractionsCompleteNumberSentencePDF()}
        items={shuffledAnswerOptions}
        sentence={`<frac w='${questionInteger.toLocaleString()}' n='${displayedQuestionFraction[0].toLocaleString()}' d='${displayedQuestionFraction[1].toLocaleString()}'/> = <ans/> ${ADD} <ans/>`}
        testCorrect={([answer1, answer2]) => {
          const userCalculatedAnswer = ((answer1 ?? 0) + (answer2 ?? 0)).toFixed(10);
          const expectedAnswer = (
            questionInteger + fractionToDecimal(questionFraction[0], questionFraction[1])
          ).toString();
          return compareFloats(userCalculatedAnswer, expectedAnswer);
        }}
        moveOrCopy="move"
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidAdditionUsingAvailCards()
        }}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
