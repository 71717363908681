import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { findFactorPairs, findFactors } from 'common/src/utils/factors';
import { isPrime } from 'common/src/utils/primes';
import {
  arraysHaveSameContentsUnordered,
  arrayHasNoDuplicates,
  filledArray
} from 'common/src/utils/collections';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { MULT } from 'common/src/constants';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import { IncompleteArrayOfObjects } from '../../../../components/question/representations/IncompleteArrayOfObjects';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'amx',
  description: 'amx',
  keywords: ['Factors', 'Array'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(10),
      columns: z.number().int().min(2).max(6)
    })
    .refine(val => val.rows !== val.columns, 'rows and columns must be different amounts.'),
  questionHeight: 1440,
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 10);

    const columns = randomIntegerInclusive(2, 6, {
      constraint: x => x !== rows
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1440}
        sentence={translate.answerSentences.ansAndAnsAreFactorsOfNum(
          (rows * columns).toLocaleString()
        )}
        title={translate.instructions.useArrayToHelpCompleteSentence()}
        testCorrect={answer =>
          (answer[0] === rows.toString() && answer[1] === columns.toString()) ||
          (answer[0] === columns.toString() && answer[1] === rows.toString())
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
        customMarkSchemeAnswer={{
          answersToDisplay: [rows.toLocaleString(), columns.toLocaleString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'amy',
  description: 'amy',
  keywords: ['Factors', 'Array'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(2, 6);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.tapCountersToCreateArrayToShowXIsFactorOfY(number2, number3)}
        pdfTitle={translate.instructions.createArrayToShowXIsFactorOfY(number2, number3)}
        testCorrect={[number1, number2]}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'amz',
  description: 'amz',
  keywords: ['Square'],
  schema: z.object({
    divisor: z.number().int().min(3).max(6),
    dividend: z.number().int().min(4).max(60)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const divisor = randomIntegerInclusive(3, 6, {
      constraint: x => x !== number1
    });
    const number3 = number1 * divisor;

    const isFactor = getRandomBoolean();

    const dividend = number3 - (isFactor ? 0 : randomIntegerInclusive(1, divisor - 1));

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const arrayDimen1 = divisor;
    const arrayDimen2 = Math.floor(dividend / divisor);
    const remainder = dividend % divisor;

    const numOfRows = remainder === 0 ? arrayDimen2 : arrayDimen2 + 1;
    const numOfCols = arrayDimen1;

    // Create array to be displayed
    let arrayToDisplay: boolean[][];
    if (remainder === 0) {
      arrayToDisplay = filledArray(filledArray(true, numOfCols), numOfRows);
    } else {
      arrayToDisplay = filledArray(filledArray(true, numOfCols), numOfRows - 1);
      const finalRow = filledArray(true, remainder).concat(
        filledArray(false, numOfCols - remainder)
      );
      arrayToDisplay.push(finalRow);
    }

    // Rotate the array to ensure the number of cols is always larger than the number of rows
    if (numOfRows > numOfCols) {
      arrayToDisplay = arrayToDisplay[0].map((_, colIndex) =>
        arrayToDisplay.map(row => row[colIndex])
      );
    }

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isXAFactorOfYSelectYourAnswer(divisor, dividend)}
        pdfTitle={translate.instructions.isXAFactorOfYCircleYourAnswer(divisor, dividend)}
        correctAnswer={remainder === 0}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={({ dimens }) => (
          <IncompleteArrayOfObjects dimens={dimens} arrayToDisplay={arrayToDisplay} />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'amA',
  description: 'amA',
  keywords: ['Factor'],
  schema: z
    .object({
      number1: z.number().int().min(6).max(72)
    })
    .refine(val => !isPrime(val.number1), 'number1 cannot be a prime number.')
    .refine(val => findFactors(val.number1).length < 7, 'number1 must have less than 7 factors.'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(6, 72, {
      constraint: x => !isPrime(x) && findFactors(x).length < 7
    });
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const number1Factors = findFactors(number1);

    const number1FactorPairs = findFactorPairs(number1);

    const sentenceArray = [
      {
        string: `${(1).toLocaleString()} ${MULT} <ans/> = ${number1.toLocaleString()}`,
        answer: [number1.toString()]
      }
    ];

    // Loop for each factor pair besides 1 x number1:
    for (let i = 1; i < number1FactorPairs.length; i++) {
      sentenceArray.push({
        string: `${number1FactorPairs[
          i
        ][0].toLocaleString()} ${MULT} <ans/> = ${number1.toLocaleString()}`,
        answer: [(number1 / number1FactorPairs[i][0]).toString()]
      });
    }

    const finalSentence = (() => {
      switch (number1Factors.length as 2 | 3 | 4 | 5 | 6) {
        case 2:
          return translate.answerSentences.theFactorsOfNumAre2Ans(number1);
        case 3:
          return translate.answerSentences.theFactorsOfNumAre3Ans(number1);
        case 4:
          return translate.answerSentences.theFactorsOfNumAre4Ans(number1);
        case 5:
          return translate.answerSentences.theFactorsOfNumAre5Ans(number1);
        case 6:
          return translate.answerSentences.theFactorsOfNumAre6Ans(number1);
      }
    })();

    const finalSentenceAnswerArray: string[] = [];
    for (let i = 0; i < number1Factors.length; i++) {
      finalSentenceAnswerArray.push(number1Factors[i].toString());
    }

    const answerCheck = (userAnswer: string[][]) => {
      // Check 1: Ensure matching factor pairs are answered:
      const arrayOfChecks = [];
      for (let i = 0; i < sentenceArray.length; i++) {
        arrayOfChecks.push(userAnswer[i][0] === sentenceArray[i].answer[0]);
      }

      // Check 2: Ensure all factors are listed in the final sentence in any order:
      const userAnswerFinalSentenceArray: string[] = [];

      for (let i = 0; i < number1Factors.length; i++) {
        userAnswerFinalSentenceArray.push(userAnswer[number1FactorPairs.length][i]);
      }
      return (
        arrayOfChecks.every(val => val) &&
        arraysHaveSameContentsUnordered(userAnswerFinalSentenceArray, finalSentenceAnswerArray)
      );
    };

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.findTheFactorsOfNum(number1)}
        testCorrect={userAnswer => answerCheck(userAnswer)}
        inputMaxCharacters={2}
        sentences={[...sentenceArray.map(val => val.string), finalSentence]}
        containerStyle={{
          alignItems: 'center',
          rowGap: number1Factors.length > 4 ? 0 : 32
        }}
        pdfContainerStyle={{ alignItems: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ...sentenceArray.map(val => val.answer).map(val => [Number(val).toLocaleString()]),
            finalSentenceAnswerArray.map(val => Number(val).toLocaleString())
          ],
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'amB',
  description: 'amB',
  keywords: ['Factor'],
  schema: z
    .object({
      number: z.number().int().min(2).max(60),
      factorArray: z.number().int().min(1).max(60).array().length(8)
    })
    .refine(val => {
      const factors = findFactors(val.number);
      return factors.every(factor => val.factorArray.includes(factor));
    }, 'All the factors of number must be included in factorArray.'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 60, {
      constraint: x => findFactors(x).length <= 6
    });

    const factorArray = findFactors(number);

    while (factorArray.length < 8) {
      factorArray.push(
        randomIntegerInclusive(1, 60, {
          constraint: x => arrayHasNoDuplicates([x, ...factorArray])
        })
      );
    }

    return { number, factorArray: shuffle(factorArray) };
  },
  Component: props => {
    const {
      question: { number, factorArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheFactorsOfX(number.toLocaleString())}
        pdfTitle={translate.instructions.circleTheFactorsOfX(number.toLocaleString())}
        testCorrect={factorArray.filter(it => number % it === 0)}
        items={shuffle(
          factorArray.map(factor => ({
            value: factor,
            component: factor.toLocaleString()
          })),
          { random: seededRandom(props.question) }
        )}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'amC',
  description: 'amC',
  keywords: ['Factor'],
  schema: z
    .object({
      number1: z.number().int().min(6).max(72)
    })
    .refine(val => !isPrime(val.number1), 'number1 cannot be a prime number.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(6, 72, {
      constraint: x => !isPrime(x)
    });
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const number1Factors = findFactors(number1);
    const number1FactorsAsStrings = number1Factors.map(number => number.toString());

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.listTheFactorsOfNum(number1)}
        testCorrect={answer => arraysHaveSameContentsUnordered(answer, number1FactorsAsStrings)}
        inputMaxCharacters={2}
        sentence={'<ans/>'.repeat(number1Factors.length)}
        sentenceStyle={{ columnGap: 16 }}
        customMarkSchemeAnswer={{
          answersToDisplay: number1Factors.map(num => num.toLocaleString()),
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Factors',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
