import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, randomIntegerInclusiveStep } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPu',
  description: 'aPu',
  keywords: ['3-digit', '2-digit', 'Division'],
  schema: z.object({
    pencilsPerBox: z.number().int().min(3).max(9),
    totalAmountOfFullBoxesPencils: z
      .number()
      .int()
      .min(51)
      .max(499)
      .refine(x => x % 10 !== 0, 'totalAmountOfFullBoxesPencils should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const pencilsPerBox = randomIntegerInclusive(3, 9);
    const totalAmountOfFullBoxesPencils = randomIntegerInclusive(51, 499, {
      constraint: x => x % 10 !== 0
    });
    return { pencilsPerBox, totalAmountOfFullBoxesPencils };
  },
  Component: props => {
    const {
      question: { pencilsPerBox, totalAmountOfFullBoxesPencils },
      translate
    } = props;

    const totalAmountOfPencils = number(
      math.evaluate(`${totalAmountOfFullBoxesPencils} * ${pencilsPerBox}`)
    );

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.pencilsArePackedInBoxesOfXThereAreYPencils(
          pencilsPerBox,
          totalAmountOfPencils
        )}<br/>${translate.instructions.howManyBoxesCanBeMade()}`}
        testCorrect={[totalAmountOfFullBoxesPencils.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aPv',
  description: 'aPv',
  keywords: ['3-digit', '2-digit', 'Multiplication'],
  schema: z.object({
    length: z
      .number()
      .int()
      .min(81)
      .max(99)
      .refine(x => x !== 90, 'length should not be 90'),
    width: z.number().int().min(51).max(61)
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(81, 99, { constraint: x => x !== 90 });
    const width = randomIntegerInclusive(51, 59);
    return { length, width };
  },
  Component: props => {
    const {
      question: { length, width },
      translate
    } = props;
    const total = number(math.evaluate(`${length} * ${width}`));
    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.aHockeyPitchIsXMlongAndYMWide(
          length,
          width
        )}<br/>${translate.instructions.whatIsAreaOfPitch()}`}
        testCorrect={[total.toString()]}
        sentence={'<ans/> m²'}
        sentenceStyle={{ alignItems: 'flex-end' }}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPw',
  description: 'aPw',
  keywords: ['3-digit', '2-digit', 'Division'],
  schema: z.object({
    totalTables: z.number().int().min(26).max(99),
    seatsPerTable: z.number().int().min(4).max(9),
    emptySeats: z.number().int().min(1).max(8)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const totalTables = randomIntegerInclusive(26, 99);
    const seatsPerTable = randomIntegerInclusive(4, 9, { constraint: x => totalTables % x !== 0 });
    const emptySeats = randomIntegerInclusive(1, seatsPerTable - 1);
    return { totalTables, seatsPerTable, emptySeats };
  },
  Component: props => {
    const {
      question: { totalTables, seatsPerTable, emptySeats },
      translate
    } = props;

    const totalPeople = math.evaluate(`${totalTables} * ${seatsPerTable} - ${emptySeats}`);

    const sentences = [
      {
        sentence: translate.answerSentences.howManyTablesAreNeededWithAnsBox(),
        answer: totalTables
      },
      {
        sentence: translate.answerSentences.howManyEmptySeatsOnFinalTable(),
        answer: emptySeats
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.xPeopleAreAttendingAnEventEachTableCanSeatYPeople(
          totalPeople,
          seatsPerTable
        )}
        testCorrect={sentences.map(el => [el.answer.toString()])}
        sentences={sentences.map(el => el.sentence)}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: 32 }}
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPx',
  description: 'aPx',
  keywords: ['3-digit', '2-digit', 'Division', 'Multiplication'],
  schema: z.object({
    bottlesPerMultiPack: z.number().int().min(4).max(12).step(2),
    multiPacksPerBox: z.number().int().min(4).max(9),
    boxes: z
      .number()
      .int()
      .min(21)
      .max(59)
      .refine(x => x % 10 !== 0, 'boxes should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const bottlesPerMultiPack = randomIntegerInclusiveStep(4, 12, 2);
    const multiPacksPerBox = randomIntegerInclusive(4, 9, {
      constraint: x => x !== bottlesPerMultiPack
    });
    const boxes = randomIntegerInclusive(21, 59, { constraint: x => x % 10 !== 0 });
    return { bottlesPerMultiPack, multiPacksPerBox, boxes };
  },
  Component: props => {
    const {
      question: { bottlesPerMultiPack, multiPacksPerBox, boxes },
      translate
    } = props;
    const total = number(math.evaluate(`${bottlesPerMultiPack} * ${multiPacksPerBox} * ${boxes}`));
    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.aMultipackOfWaterContainsXBottles(
          bottlesPerMultiPack
        )}<br/>${translate.instructions.aBoxHoldsXMultipacksOfWater(
          multiPacksPerBox
        )}<br/>${translate.instructions.aShopOrdersXBoxesOfWaterWhatsTheTotalAmountOrdered(boxes)}`}
        testCorrect={[total.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPy',
  description: 'aPy',
  keywords: ['3-digit', '2-digit', 'Multiplication'],
  schema: z.object({
    carriages: z.number().int().min(6).max(16),
    peoplePerCarriage: z
      .number()
      .int()
      .min(32)
      .max(58)
      .step(2)
      .refine(x => x % 10 !== 0, 'peoplePerCarriage should not be a multiple of 10'),
    unreservedSeats: z.number().int().min(50).max(464)
  }),
  simpleGenerator: () => {
    const carriages = randomIntegerInclusive(6, 16);
    const peoplePerCarriage = randomIntegerInclusiveStep(32, 58, 2, {
      constraint: x => x % 10 !== 0
    });
    const unreservedSeats = randomIntegerInclusive(
      50,
      Math.floor(carriages * peoplePerCarriage * 0.5)
    );
    return { carriages, peoplePerCarriage, unreservedSeats };
  },
  Component: props => {
    const {
      question: { carriages, peoplePerCarriage, unreservedSeats },
      translate
    } = props;

    const totalSeats = number(math.evaluate(`${carriages} * ${peoplePerCarriage}`));
    const reservedSeats = totalSeats - unreservedSeats;

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.aTrainHasXCarriagesCarryingYPeopleZReservedSeats(
          carriages,
          peoplePerCarriage,
          reservedSeats
        )}<br/>${translate.instructions.howManyUnreservedSeats()}`}
        testCorrect={[unreservedSeats.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPz',
  description: 'aPz',
  keywords: ['3-digit', '2-digit', 'Multiplication'],
  schema: z.object({
    rows: z
      .number()
      .int()
      .min(101)
      .max(199)
      .refine(x => x % 10 !== 0, 'rows should not be a multiple of 10'),
    spacesPerRow: z.number().int().min(12).max(24).step(2),
    fullRows: z.number().int().min(12).max(99)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(101, 199, { constraint: x => x % 10 !== 0 });
    const spacesPerRow = randomIntegerInclusiveStep(12, 24, 2);
    const fullRows = randomIntegerInclusive(25, Math.floor(rows / 2));
    return { rows, spacesPerRow, fullRows };
  },
  Component: props => {
    const {
      question: { rows, spacesPerRow, fullRows },
      translate
    } = props;
    const totalEmptySpaces = number(math.evaluate(`(${rows} - ${fullRows}) * ${spacesPerRow}`));

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.aCarParkHasXRowsOfYSpacesZRowsAreFull(
          rows,
          spacesPerRow,
          fullRows
        )}<br/>${translate.instructions.howManySpacesAreEmpty()}`}
        testCorrect={[totalEmptySpaces.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithMultiplicationAndDivision',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
