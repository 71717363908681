import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { numbersDoNotExchange, numbersOnlyExchangeAt } from 'common/src/utils/exchanges';
import ColumnOperations from 'common/src/components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from 'common/src/constants';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { range } from '../../../../utils/collections';
import { digitAtPowerIsNumber } from '../../../../utils/math';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agQ',
  description: 'agQ',
  keywords: ['Addition', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(11)
        .max(88)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(
      val => numbersOnlyExchangeAt(val.var1, val.var2, 'ones') && val.var1 + val.var2 < 100,
      'numbers exchange at ones and total is less than 100'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(11, 88, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99 - var1, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, var2, 'ones') && var1 + var2 < 100
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'agR',
  description: 'agR',
  keywords: ['Addition', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(111).max(888),
      var2: z.number().int().min(111).max(888)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.var1, val.var2, 'ones') && val.var1 + val.var2 < 1000,
      'Numbers must exchange only at ones and total must be less than 1,000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(111, 888, {
          constraint: x =>
            // Must not contain any zeroes:
            !digitAtPowerIsNumber(x, 'ones', [0]) && !digitAtPowerIsNumber(x, 'tens', [0])
        });

        const var2 = randomIntegerInclusive(111, 999 - var1, {
          constraint: x =>
            // Must not contain any zeroes:
            !digitAtPowerIsNumber(x, 'ones', [0]) && !digitAtPowerIsNumber(x, 'tens', [0])
        });

        return { var1, var2 };
      },
      // Only permit them if they exchange at the ones and the total contains no zeroes:
      ({ var1, var2 }) =>
        numbersOnlyExchangeAt(var1, var2, 'ones') &&
        !digitAtPowerIsNumber(var1 + var2, 'ones', [0]) &&
        !digitAtPowerIsNumber(var1 + var2, 'tens', [0])
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'agS',
  description: 'agS',
  keywords: ['Add', 'Column method'],
  schema: z.object({
    number1: z.number().int().min(151).max(499),
    number2: z.number().int().min(101).max(499)
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(151, 499, {
          constraint: x => x % 10 !== 0
        });

        const number2 = randomIntegerInclusive(101, 499, {
          constraint: x => x % 10 !== 0
        });
        return { number1, number2 };
      },
      ({ number1, number2 }) =>
        numbersOnlyExchangeAt(number1, number2, 'ones') &&
        // Must be a zero in the tens of either addend, or in the ones of the total.
        (digitAtPowerIsNumber(number1, 'tens', [0]) ||
          digitAtPowerIsNumber(number2, 'tens', [0]) ||
          digitAtPowerIsNumber(number1 + number2, 'ones', [0]))
    );

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'agT',
  description: 'agT',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(899),
      numberA2: z.number().int().min(100).max(899),
      numberB1: z.number().int().min(100).max(899),
      numberB2: z.number().int().min(100).max(899),
      numberC1: z.number().int().min(100).max(899),
      numberC2: z.number().int().min(100).max(899),
      numberD1: z.number().int().min(100).max(899),
      numberD2: z.number().int().min(100).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 and numberB2 must have exchanges at the ones.'
    ),

  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return numbersOnlyExchangeAt(firstValue, secondNumber, 'ones');
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(100, 899);
        const numberA2 = randomIntegerInclusive(100, 999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        const numberB1 = randomIntegerInclusive(100, 899);
        const numberB2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they exchange only at the ones.
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(100, 899);
        const numberC2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(100, 899);
        const numberD2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1, bottomNumber: numberB2, isCorrect: true };
      const eqC = { topNumber: numberC1, bottomNumber: numberC2, isCorrect: cHasExchange };
      const eqD = { topNumber: numberD1, bottomNumber: numberD2, isCorrect: dHasExchange };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      cHasExchange,
      dHasExchange,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichAdditionsNeedAnExchange()} ${translate.instructions.selectYourAnswers()}`}
        pdfTitle={`${translate.instructions.whichAdditionsWillNeedAnExchange()}<br/>${translate.instructions.circleYourAnswers()}`}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4v2 = newQuestionContent({
  uid: 'agT2',
  description: 'agT',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(101).max(899),
      numberA2: z.number().int().min(101).max(899),
      numberB1: z.number().int().min(101).max(899),
      numberB2: z.number().int().min(101).max(899),
      numberC1: z.number().int().min(101).max(899),
      numberC2: z.number().int().min(101).max(899),
      numberD1: z.number().int().min(101).max(899),
      numberD2: z.number().int().min(101).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 and numberB2 must have exchanges at the ones.'
    )
    .refine(
      val =>
        [
          val.numberA1,
          val.numberA2,
          val.numberB1,
          val.numberB2,
          val.numberC1,
          val.numberC2,
          val.numberD1,
          val.numberD2
        ].every(num => num % 100 !== 0),
      'No number should not be a multiple of 100.'
    ),

  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return numbersOnlyExchangeAt(firstValue, secondNumber, 'ones');
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberA2 = randomIntegerInclusive(101, 1000 - numberA1, {
          constraint: x => x % 100 !== 0
        });
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        const numberB1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberB2 = randomIntegerInclusive(101, 1000 - numberB1, {
          constraint: x => x % 100 !== 0
        });
        return { numberB1, numberB2 };
      },
      // Only permit them if they exchange only at the ones.
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();
    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberC2 = randomIntegerInclusive(101, 1000 - numberC1, {
          constraint: x => x % 100 !== 0
        });
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberD2 = randomIntegerInclusive(101, 1000 - numberD1, {
          constraint: x => x % 100 !== 0
        });
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1, bottomNumber: numberB2, isCorrect: true };
      const eqC = { topNumber: numberC1, bottomNumber: numberC2, isCorrect: cHasExchange };
      const eqD = { topNumber: numberD1, bottomNumber: numberD2, isCorrect: dHasExchange };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      cHasExchange,
      dHasExchange,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAdditionsThatNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheAdditionsThatNeedAnExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'agU',
  description: 'agU',
  keywords: ['Add', 'Exchange'],
  schema: z
    .object({
      object1: z.enum(['Computer', 'Laptop', 'Phone', 'Tablet', 'Television']),
      object2: z.enum(['Computer', 'Laptop', 'Phone', 'Tablet', 'Television']),
      object1Price: z.number().int().min(301).max(559),
      object2MoreThanObject1: z.number().int().min(149).max(359)
    })
    .refine(val => val.object1 !== val.object2, 'object1 and object2 must be different.'),
  simpleGenerator: () => {
    const [object1, object2] = getRandomSubArrayFromArray(
      ['Computer', 'Laptop', 'Phone', 'Tablet', 'Television'] as const,
      2
    );

    const { object1Price, object2MoreThanObject1 } = rejectionSample(
      () => {
        const object1Price = randomIntegerInclusive(301, 559);

        const object2MoreThanObject1 = randomIntegerInclusive(149, 359);
        return { object1Price, object2MoreThanObject1 };
      },
      ({ object1Price, object2MoreThanObject1 }) =>
        numbersOnlyExchangeAt(object1Price, object2MoreThanObject1, 'ones')
    );

    return { object1, object2, object1Price, object2MoreThanObject1 };
  },

  Component: props => {
    const {
      question: { object1, object2, object1Price, object2MoreThanObject1 },
      translate
    } = props;

    const object1String = translate.objects[object1]();

    const object2String = translate.objects[object2]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aXCostsNumAYCostsNumMoreThanX(
          object1String,
          object1Price,
          object2String,
          object2MoreThanObject1
        )}
        sentence={translate.answerSentences.poundAns()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(object1Price + object2MoreThanObject1).toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'agV',
  description: 'agV',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      topNumber: z.number().int().min(101).max(889),
      bottomNumber: z.number().int().min(101).max(889),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersOnlyExchangeAt(val.topNumber, val.bottomNumber, 'ones'),
      'topNumber and bottomNumber must exchange at the ones only.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const topNumber = randomIntegerInclusive(101, 889, {
      constraint: x =>
        x % 10 !== 0 && // Ensure topNumber does not end in 0 so topNumber and bottomNumber can exchange at the ones
        x % 100 < 90 // Ensure topNumber has less than 9 tens to ensure topNumber and bottomNumber do not exchange at the tens
    });

    const bottomNumber = randomIntegerInclusive(101, 889, {
      constraint: x => numbersOnlyExchangeAt(topNumber, x, 'ones')
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwoNumbersAcrossA10',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4],
  unpublishedQuestionTypes: []
});
export default SmallStep;
