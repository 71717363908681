import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  arraysHaveSameContentsUnordered,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import { totalSplitIntoDenominations } from '../../../../utils/measure';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { getImageByWeight } from '../../../../utils/weightImages';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aI0',
  description: 'aI0',
  keywords: ['Mass', 'Measure', 'Weight', 'Grams', 'Kilograms', 'Equivalent'],
  schema: z.object({
    selectables: z
      .array(numberEnum([10, 20, 50, 100, 200, 500]).array())
      .length(4)
      .refine(
        val => val.filter(array => array.reduce((a, b) => a + b, 0) === 1000).length > 0,
        'At least one array must total 1,000'
      )
  }),
  simpleGenerator: () => {
    const weightValues = [10, 20, 50, 100, 200, 500] as const;
    type WeightValue = (typeof weightValues)[number];

    const correctWeightChoices: WeightValue[][] = [
      [500, 500],
      [200, 200, 200, 200, 200],
      [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      [500, 200, 200, 50, 50],
      [500, 200, 200, 100],
      [500, 200, 100, 100, 100],
      [500, 200, 200, 50, 20, 20, 10],
      [500, 100, 100, 100, 100, 100],
      [500, 100, 100, 100, 100, 50, 50],
      [200, 200, 200, 200, 100, 100],
      [200, 200, 200, 200, 100, 50, 50],
      [200, 200, 200, 200, 100, 50, 20, 20, 10],
      [200, 200, 200, 100, 100, 100, 100],
      [200, 200, 100, 100, 100, 100, 100, 100],
      [200, 100, 100, 100, 100, 100, 100, 100, 100]
    ];

    const incorrectWeightChoices: WeightValue[][] = [
      [500, 200, 200, 200],
      [500, 200],
      [500, 100, 100, 100, 100, 100],
      [500, 100, 100, 100, 100, 10],
      [200, 200, 200, 200, 100],
      [200, 200, 200, 200, 200, 200],
      [200, 200, 200, 100, 100, 100],
      [200, 200, 20, 100, 100, 100, 10],
      [200, 20, 100, 100, 100, 100, 100, 10],
      [200, 100, 100, 100, 100, 100, 100, 100, 100, 100],
      [50, 20, 10, 10, 10],
      [20, 100, 100, 100, 100, 100, 100, 100, 100]
    ];

    const correctSelectableA = getRandomFromArray(correctWeightChoices) as WeightValue[];

    const incorrectSelectableA = getRandomFromArray(incorrectWeightChoices) as WeightValue[];

    // Filter out the previously-selected correct choice:
    const filteredCorrectWeightChoices = correctWeightChoices.filter(
      array => !arraysHaveSameContentsUnordered(array, correctSelectableA)
    );

    // Filter out the previously-selected incorrect choice:
    const filteredIncorrectWeightChoices = incorrectWeightChoices.filter(
      array => !arraysHaveSameContentsUnordered(array, incorrectSelectableA)
    );

    const remainingChoices = [...filteredCorrectWeightChoices, ...filteredIncorrectWeightChoices];

    const [correctOrIncorrectSelectableA, correctOrIncorrectSelectableB] =
      getRandomSubArrayFromArray(remainingChoices, 2);

    // Shuffle the order of the selectables, and the order within each selectable array:
    const selectables = shuffle([
      shuffle(correctSelectableA),
      shuffle(incorrectSelectableA),
      shuffle(correctOrIncorrectSelectableA),
      shuffle(correctOrIncorrectSelectableB)
    ]);

    return { selectables };
  },
  Component: props => {
    const {
      question: { selectables },
      translate
    } = props;

    const minWeight = 10;

    // Number of items, or number of max weights in items, may need to be adjusted if items are too full with images.
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectSetsOfWeightsThatHaveTotalMassOf1Kg()}
        testCorrect={selectables
          .filter(array => array.reduce((a, b) => a + b, 0) === 1000)
          .map(array => array)}
        numItems={4}
        multiSelect
        renderItems={selectables.map(array => ({
          value: array,
          component: (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 10
              }}
            >
              {array.map((amount, i) => (
                <AssetSvg
                  key={i}
                  name={getImageByWeight(amount)}
                  height={amount === minWeight ? 70 : 70 + 70 * (amount / (minWeight * 100))}
                />
              ))}
            </View>
          )
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aI1',
  description: 'aI1',
  keywords: ['Mass', 'Measure', 'Weight', 'Grams', 'Kilograms', 'Equivalent'],
  schema: z.object({
    weightA1: z.number().int().min(100).max(900).multipleOf(100),
    weightB1: z.number().int().min(100).max(900).multipleOf(100),
    weightC2: z.number().int().min(100).max(900).multipleOf(100)
  }),
  simpleGenerator: () => {
    const [weightA1, weightB1, weightC2] = randomUniqueIntegersInclusiveStep(100, 900, 100, 3);

    return { weightA1, weightB1, weightC2 };
  },

  Component: props => {
    const {
      question: { weightA1, weightB1, weightC2 },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.numGPlusAnsEqualsNumKg(weightA1.toLocaleString(), 1),
          answer: 1000 - weightA1
        },
        {
          sentence: translate.answerSentences.numGPlusAnsEqualsNumKg(weightB1.toLocaleString(), 1),
          answer: 1000 - weightB1
        },
        {
          sentence: translate.answerSentences.ansGPlusNumGEqualsNumKg(weightC2.toLocaleString(), 1),
          answer: 1000 - weightC2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={sentences.map(sentence => [sentence.answer.toString()])}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aI2',
  description: 'aI2',
  keywords: ['Mass', 'Measure', 'Weight', 'Grams', 'Kilograms', 'Equivalent'],
  schema: z.object({
    weightA1: z
      .number()
      .int()
      .min(100)
      .max(900)
      .multipleOf(10)
      .refine(val => val % 100 !== 0, 'weightA1 must not be a multiple of 100'),
    weightB1: z
      .number()
      .int()
      .min(100)
      .max(900)
      .multipleOf(10)
      .refine(val => val % 100 !== 0, 'weightB1 must not be a multiple of 100'),
    weightC2: z
      .number()
      .int()
      .min(100)
      .max(900)
      .multipleOf(10)
      .refine(val => val % 100 !== 0, 'weightC2 must not be a multiple of 100')
  }),
  simpleGenerator: () => {
    const [weightA1, weightB1, weightC2] = randomUniqueIntegersInclusiveStep(100, 900, 10, 3, {
      constraint: x => x % 100 !== 0
    });

    return { weightA1, weightB1, weightC2 };
  },

  Component: props => {
    const {
      question: { weightA1, weightB1, weightC2 },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.numGPlusAnsEqualsNumKg(weightA1.toLocaleString(), 1),
          answer: 1000 - weightA1
        },
        {
          sentence: translate.answerSentences.numGPlusAnsEqualsNumKg(weightB1.toLocaleString(), 1),
          answer: 1000 - weightB1
        },
        {
          sentence: translate.answerSentences.ansGPlusNumGEqualsNumKg(weightC2.toLocaleString(), 1),
          answer: 1000 - weightC2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={sentences.map(sentence => [sentence.answer.toString()])}
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aI3',
  description: 'aI3',
  keywords: ['Mass', 'Measure', 'Weight', 'Grams', 'Kilograms', 'Equivalent'],
  schema: z.object({
    name: nameSchema,
    givenWeight: z.number().int().min(100).max(990).multipleOf(10)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const givenWeight = randomIntegerInclusiveStep(100, 990, 10, {
      constraint: x => {
        const weights = totalSplitIntoDenominations(
          x,
          [500, 200, 100, 50, 20, 10].filter(i => i !== x)
        );

        // Between 2 and 8 weights must be used:
        return weights.length >= 2 && weights.length <= 8;
      }
    });

    return { name, givenWeight };
  },
  Component: props => {
    const {
      question: { name, givenWeight },
      translate
    } = props;

    const weightsToUse = totalSplitIntoDenominations(givenWeight, [500, 200, 100, 50, 20, 10]);
    const minWeight = sortNumberArray(weightsToUse, 'ascending')[0];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentence={translate.answerSentences.ansG()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.characterIsUsingWeightsToMakeMassEquivalentTo1Kg(name)}
        testCorrect={[(1000 - givenWeight).toString()]}
        Content={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 10
            }}
          >
            {weightsToUse.map((amount, i) => (
              <AssetSvg
                key={i}
                name={getImageByWeight(amount as 500 | 200 | 100 | 50 | 20 | 10)}
                height={amount === minWeight ? 100 : 100 + 100 * (amount / (minWeight * 50))}
              />
            ))}
          </View>
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aI4',
  description: 'aI4',
  keywords: [
    'Mass',
    'Measure',
    'Weight',
    'Grams',
    'Kilograms',
    'Equivalent',
    'Fraction',
    'Bar model'
  ],
  schema: z.object({
    totalParts: numberEnum([2, 4, 5, 10]),
    partsShaded: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const totalParts = getRandomFromArray([2, 4, 5, 10] as const);

    const partsShaded = randomIntegerInclusive(1, totalParts - 1);

    return { totalParts, partsShaded };
  },

  Component: props => {
    const {
      question: { totalParts, partsShaded },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToFindFractionOfAKilogram()}
        testCorrect={[((1000 / totalParts) * partsShaded).toString()]}
        textStyle={displayMode ? { fontSize: 40 } : undefined}
        sentence={translate.answerSentences.numKgEqualsAnsG(
          `<frac n='${partsShaded}' d='${totalParts}'/>`
        )}
        pdfDirection="column"
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={totalParts}
            width={dimens.width}
            coloredSections={range(0, partsShaded - 1)}
            fullWidthTopBrace={translate.units.numberOfKg(1)}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aI5',
  description: 'aI5',
  keywords: ['Mass', 'Measure', 'Weight', 'Grams', 'Kilograms', 'Equivalent', 'Fraction'],
  schema: z.object({
    weightB1: z
      .number()
      .int()
      .min(105)
      .max(945)
      .multipleOf(5)
      .refine(val => val % 10 !== 0, 'weightA1 must not be a multiple of 10'),
    numeratorA: numberEnum([1, 3]),
    denominatorA: numberEnum([2, 4, 5, 10])
  }),
  questionHeight: 600,
  simpleGenerator: () => {
    const denominatorA = getRandomFromArray([2, 4, 5, 10] as const);

    const numeratorA = denominatorA === 4 ? getRandomFromArray([1, 3] as const) : 1;

    const weightB1 = randomIntegerInclusiveStep(105, 945, 5, {
      constraint: x => x % 10 !== 0
    });

    return { numeratorA, denominatorA, weightB1 };
  },

  Component: props => {
    const {
      question: { numeratorA, denominatorA },
      translate
    } = props;

    const fractionAGrams = (1000 * numeratorA) / denominatorA;

    const sentence = translate.answerSentences.ansGPlusNumKgEqualsNumKg(
      `<frac n='${numeratorA}' d='${denominatorA}'/>`,
      1
    );
    const answer = 1000 - fractionAGrams;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[answer.toString()]}
        sentence={sentence}
        questionHeight={600}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentMassesKilogramsAndGrams',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
