import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from 'common/src/utils/random';
import { arrayHasNoDuplicates, range, rangeExcluding } from 'common/src/utils/collections';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { roundToTheNearest } from 'common/src/utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acS',
  description: 'acS',
  keywords: ['Place value', 'Round', 'Number line', '100', 'Hundred'],
  schema: z.object({
    number: z.number().int().min(110).max(990).multipleOf(10)
  }),
  example: {
    number: 550
  },
  simpleGenerator: () => {
    // Limit tens to 1-9, ones to 1-9
    const hundreds = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const number = hundreds * 100 + tens * 10;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 100) * 100;
    const endNumber = Math.ceil(number / 100) * 100;
    const tickInterval = 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest100(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 100).toString()]}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'acT',
  description: 'acT',
  keywords: ['Place value', 'Round', 'Number line', '100', 'Hundred'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(110)
      .max(999)
      .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10 ')
  }),
  example: {
    number: 557
  },
  simpleGenerator: () => {
    // Limit tens to 1-9, ones to 1-9
    const hundreds = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(1, 9);
    const number = hundreds * 100 + tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 100) * 100;
    const endNumber = Math.ceil(number / 100) * 100;
    const tickInterval = 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest100(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 100).toString()]}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'acU',
  description: 'acU',
  keywords: ['Place value', 'Round', 'Hundred', '100', 'Sort'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(101).max(999)).length(6)
    })
    .refine(
      val => val.numbers.every(number => number % 100 !== 0),
      'Number cannot be a multiple of 100'
    ),
  simpleGenerator: () => {
    const hundreds = randomIntegerInclusive(1, 9);

    // Find a random multiple of 10, excluding the 50
    const randomMultipleOf10 =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 10 + hundreds * 100;

    // Find random numbers excluding the 50 and the randomMultipleOf10
    const tensAndOnes = getRandomSubArrayFromArray(
      rangeExcluding(1, 99, [50, randomMultipleOf10]),
      4
    );

    const numbers = tensAndOnes.map(value => {
      return hundreds * 100 + value;
    });

    // Add the 50
    numbers.push(hundreds * 100 + 50);
    // Add the random multiple of 10
    numbers.push(randomMultipleOf10);
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / 100) * 100;
    const upper = Math.ceil(numbers[0] / 100) * 100;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, 100) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(100)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(100)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'acV',
  description: 'acV',
  keywords: ['Place value', 'Round', '100', 'Hundred'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10).max(999)).length(3).refine(arrayHasNoDuplicates)
  }),
  example: {
    numbers: [16, 225, 704]
  },
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(10, 999, 3, { constraint: x => x % 100 !== 0 });
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 100);
      const sentence = `${number}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(100)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acW',
  description: 'acW',
  keywords: ['Place value', 'Round', '100'],
  schema: z.object({
    comparitor: z.number().int().step(100).min(200).max(900),
    numbers: z.number().int().min(0).max(1000).array().length(6)
  }),
  example: {
    comparitor: 700,
    numbers: [633, 650, 689, 725, 750, 788]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = randomIntegerInclusive(2, 9) * 100;

    // $var-101<num1<$var-50
    const num1 = randomIntegerInclusive(comparitor - 100, comparitor - 51);

    //num2=$var-50,
    const num2 = comparitor - 50;

    //$var-50<num3<$var,
    const num3 = randomIntegerInclusive(comparitor - 49, comparitor - 1);

    //num4<$var+50,
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 49);

    //num5=$var+50,
    const num5 = comparitor + 50;

    //$var+50<num6<$var+101
    const num6 = randomIntegerInclusive(comparitor + 51, comparitor + 100);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumsRoundToNumToNearestNum(comparitor, 100)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 100) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'acX',
  description: 'acX',
  keywords: ['Place value', 'Round', '100', 'Hundred'],
  schema: z.object({
    number: z.number().int().min(10).max(999)
  }),
  example: {
    number: 724
  },
  simpleGenerator: () => {
    const hundreds = randomIntegerInclusive(0, 9);
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(0, 9);
    const number = hundreds * 100 + tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const nearest10 = roundToTheNearest(number, 10);
    const nearest100 = roundToTheNearest(number, 100);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[nearest10.toString()], [nearest100.toString()]]}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        sentences={[
          translate.answerSentences.roundToNearest10(number),
          translate.answerSentences.roundToNearest100(number)
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearest100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
