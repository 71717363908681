import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { DIV, MULT } from 'common/src/constants';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { useMemo } from 'react';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'al9',
  description: 'al9',
  keywords: ['Divide', 'Divide by 1', 'Divide by itself'],
  schema: z
    .object({
      dividendA: z.number().int().min(8).max(50),
      divisorA: z.number().int().min(2).max(12),
      dividendB: z.number().int().min(8).max(50),
      divisorB: z.number().int().min(2).max(12),
      multiplicandC: z.number().int().min(2).max(12),
      multiplierC: z.number().int().min(2).max(6)
    })
    .refine(val => val.dividendA % val.divisorA === 0, 'dividendA must be a multiple of divisorA.')
    .refine(val => val.dividendB % val.divisorB === 0, 'dividendB must be a multiple of divisorB.'),
  simpleGenerator: () => {
    const divisorA = randomIntegerInclusive(2, 12);

    const dividendA = randomIntegerInclusive(8, 50, {
      constraint: x => x % divisorA === 0
    });

    const divisorB = randomIntegerInclusive(2, 12);

    const dividendB = randomIntegerInclusive(8, 50, {
      constraint: x => x % divisorB === 0 && x !== dividendA
    });

    const multiplicandC = randomIntegerInclusive(2, 12);

    const multiplierC = randomIntegerInclusive(2, 6);

    return { divisorA, dividendA, divisorB, dividendB, multiplicandC, multiplierC };
  },
  Component: ({
    question: { divisorA, dividendA, divisorB, dividendB, multiplicandC, multiplierC },
    translate
  }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheCalculations()}
        sentences={[
          `${dividendA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`,
          `${dividendB.toLocaleString()} ${DIV} ${(1).toLocaleString()} ${DIV} ${divisorB.toLocaleString()} = <ans/>`,
          `${multiplicandC.toLocaleString()} ${MULT} ${multiplierC.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`
        ]}
        testCorrect={[
          [(dividendA / divisorA).toString()],
          [(dividendB / divisorB).toString()],
          [(multiplicandC * multiplierC).toString()]
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ama',
  description: 'ama',
  keywords: ['Divide', 'Divide by 1', 'Divide by itself'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(30),
    numberA2: z.number().int().min(2).max(15),
    numberB1: z.number().int().min(10).max(50),
    numberC1: z.number().int().min(5).max(25),
    numberC2: z.number().int().min(5).max(25)
  }),
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(2, 30);

    const numberA2 = randomIntegerInclusive(2, 15, {
      constraint: x => x !== numberA1
    });

    const numberB1 = randomIntegerInclusive(10, 50);

    const [numberC1, numberC2] = randomUniqueIntegersInclusive(5, 25, 2);

    return { numberA1, numberA2, numberB1, numberC1, numberC2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberC1, numberC2 },
      translate,
      displayMode
    } = props;

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberA1.toLocaleString()} ${MULT} ${(0).toLocaleString()}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberA2.toLocaleString()} ${DIV} ${(1).toLocaleString()}`}</Text>
          ),
          correctAnswer: '<'
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberB1.toLocaleString()} ${DIV} ${(1).toLocaleString()}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberB1.toLocaleString()} ${MULT} ${(1).toLocaleString()}`}</Text>
          ),
          correctAnswer: '='
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberC1.toLocaleString()} ${DIV} ${numberC1.toLocaleString()}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 160 : 180, textAlign: 'center' }}
            >{`${numberC2.toLocaleString()} ${DIV} ${numberC2.toLocaleString()}`}</Text>
          ),
          correctAnswer: '='
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['<', '>', '=']}
        itemVariant="square"
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'amb',
  description: 'amb',
  keywords: ['Divide', 'Divide by 1', 'Divide by itself'],
  schema: z.object({
    number1: z.number().int().min(2).max(30),
    number2: z.number().int().min(2).max(30),
    number3: z.number().int().min(2).max(30)
  }),
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(2, 30, 3);

    return { number1, number2, number3 };
  },

  Component: ({ question: { number1, number2, number3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheCalculations()}
        testCorrect={[['1'], ['1'], ['1']]}
        sentences={[
          `${number1.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
          `${number2.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${DIV} ${number3.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'amc',
  description: 'amc',
  keywords: ['Divide', 'Divide by 1', 'Divide by itself'],
  schema: z.object({
    number1: z.number().int().min(2).max(30),
    number2: z.number().int().min(2).max(30),
    number3: z.number().int().min(2).max(30)
  }),
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(2, 30, 3);

    return { number1, number2, number3 };
  },

  Component: ({ question: { number1, number2, number3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheCalculations()}
        testCorrect={[[number1.toString()], [number2.toString()], [number3.toString()]]}
        sentences={[
          `${number1.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`,
          `${number2.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'amd',
  description: 'amd',
  keywords: ['Divide', '1', 'Itself'],
  schema: z.object({
    number1: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 12);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    // Randomly order these statements
    const sentences = useMemo(() => {
      const lineA = {
        sentence: `<ans /> ${DIV} ${number1.toLocaleString()} = <ans />`,
        answer: [number1, 1]
      };

      const lineB = {
        sentence: `<ans /> ${DIV} <ans /> = ${number1.toLocaleString()}`,
        answer: [number1, 1]
      };

      return shuffle([lineA, lineB], { random: seededRandom(props.question) });
    }, [number1, props.question]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentences()}
        items={[number1, 1]}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => answer)}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'ame',
  description: 'ame',
  keywords: ['Divide', '1', 'Itself'],
  schema: z.object({
    numbers: z.number().int().min(2).max(9).array().length(3)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(2, 9, 3);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [A1, A2, A3] = numbers;

    const equations = shuffle(
      [
        { component: `${(0).toLocaleString()} ${DIV} ${(1).toLocaleString()}`, value: '0a' },
        { component: `${A1.toLocaleString()} ${DIV} ${A1.toLocaleString()}`, value: '1a' },
        { component: `${(1).toLocaleString()} ${DIV} ${(1).toLocaleString()}`, value: '1b' },
        {
          component: `${A2.toLocaleString()} ${DIV} ${(1).toLocaleString()}`,
          value: 'not equal a'
        },
        { component: `${(1).toLocaleString()} ${MULT} ${(1).toLocaleString()}`, value: '1c' },
        {
          component: `${(1).toLocaleString()} ${DIV} ${A3.toLocaleString()}`,
          value: 'not equal b'
        },
        { component: `${(0).toLocaleString()} ${MULT} ${(1).toLocaleString()}`, value: '0b' },
        { component: `${(10).toLocaleString()} ${DIV} ${(0).toLocaleString()}`, value: 'error' }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalcsThatHaveAnAnswerOfNum(1)}
        pdfTitle={translate.instructions.circleCalcsThatHaveAnAnswerOfNum(1)}
        testCorrect={equations.filter(({ value }) => value.includes('1')).map(eq => eq.value)}
        multiSelect
        items={equations}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideANumberBy1AndItself',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
