import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import { numberEnum } from 'common/src/utils/zod';
import { multiplesNumberTrackArray } from 'common/src/utils/multiples';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF31NumberGridInteractive from 'common/src/components/question/questionFormats/QF31NumberGridInteractive';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alt',
  description: 'alt',
  keywords: ['Times-table', '9', '100 square'],
  schema: z.object({
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    return { startNumber };
  },
  Component: props => {
    const {
      question: { startNumber },
      translate
    } = props;

    const correctNumbers = range(startNumber, startNumber + 49).filter(number => number % 9 === 0);

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + 49}
        title={translate.instructions.selectAllMultiplesOfNum(9)}
        pdfTitle={translate.instructions.shadeMultiplesOfX(9)}
        testCorrect={correctNumbers}
        initialState={[correctNumbers[0]]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'alu',
  description: 'alu',
  keywords: ['Times-table', '9', 'Track'],
  schema: z.object({
    interval: numberEnum([-9, 9]),
    startingNumber: z.number().int().min(9).max(108).multipleOf(9),
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-9, 9] as const);
    const startingNumber =
      interval === 9
        ? randomIntegerInclusiveStep(9, 45, 9)
        : randomIntegerInclusiveStep(72, 108, 9);
    const tileToShow = randomIntegerInclusive(2, 6);
    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      5,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alv',
  description: 'alv',
  keywords: ['Times-table', '9', 'Multiply'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 9, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 9, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ left: 9, right: numberC1, sign: 'multiply', answer: 'result' });

    const eqD = getBinOpEquation({ left: 9, right: numberD1, sign: 'multiply', answer: 'right' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alw',
  description: 'alw',
  keywords: ['Times-table', '9', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ right: 9, result: numberA1, sign: 'divide', answer: 'result' });

    const eqB = getBinOpEquation({ right: 9, result: numberB1, sign: 'divide', answer: 'left' });

    const eqC = getBinOpEquation({ right: 9, result: numberC1, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ right: 9, result: numberD1, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'alx',
  description: 'alx',
  keywords: ['Multiple', '9'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(999)
      .array()
      .length(6)
      .refine(numbers => numbers.filter(x => x % 9 === 0).length >= 2, 'At least 2 multiples of 9')
  }),
  simpleGenerator: () => {
    const numMultiples = randomIntegerInclusive(2, 5);

    const multiples = randomUniqueIntegersInclusive(1, 999, numMultiples, {
      constraint: x => x % 9 === 0
    });

    const notMultiples = randomUniqueIntegersInclusive(1, 999, 6 - numMultiples, {
      constraint: x => x % 9 !== 0
    });

    const numbers = [...multiples, ...notMultiples];

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const shuffledNumbers = shuffle(numbers, { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(9)}
        pdfTitle={translate.instructions.tickTheMultipleOfX(9)}
        testCorrect={numbers.filter(it => it % 9 === 0)}
        multiSelect
        items={() =>
          shuffledNumbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aly',
  description: 'aly',
  keywords: ['9', 'Multiple', 'Divisibility'],
  schema: z.object({
    digits: z.array(z.number().int().min(1).max(9)).length(5)
  }),
  simpleGenerator: () => {
    const threes = getRandomSubArrayFromArray([3, 6, 9], 2);
    const others = getRandomSubArrayFromArray([1, 2, 4, 5, 7, 8], 3);

    const digits = shuffle([...threes, ...others]);

    return { digits };
  },
  Component: props => {
    const {
      question: { digits },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      const userNumber = parseInt(ans.join(''));
      return userNumber % 9 === 0;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.createMultipleOfX(9)}
        pdfTitle={translate.instructions.createMultipleOfXUsingSomeDigitsBelowNumbersCanBeUsedMoreThanOnce(
          9
        )}
        sentence="<ans/> <ans/> <ans/>"
        items={digits.map(num => num.toLocaleString())}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyMultipleOfXUsingAvailCards(9)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num9TimesTableAndDivisionFacts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
