import { newBlockContent } from '../../../Block';

import CountWithin20 from './1CountWithin20';
import Understand10 from './2Understand10';
import Understand1112And13 from './3Understand1112And13';
import Understand1415And16 from './4Understand1415And16';
import Understand1718And19 from './5Understand1718And19';
import Understand20 from './6Understand20';
import OneMoreAndOneLess from './7OneMoreAndOneLess';
import TheNumberLineTo20 from './8TheNumberLineTo20';
import UseANumberLineTo20 from './9UseANumberLineTo20';
import EstimateOnANumberLineTo20 from './10EstimateOnANumberLineTo20';
import CompareNumbersTo20 from './11CompareNumbersTo20';
import OrderNumbersTo20 from './12OrderNumbersTo20';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    CountWithin20, // Step 1
    Understand10, // Step 2
    Understand1112And13, // Step 3
    Understand1415And16, // Step 4
    Understand1718And19, // Step 5
    Understand20, // Step 6
    OneMoreAndOneLess, // Step 7
    TheNumberLineTo20, // Step 8
    UseANumberLineTo20, // Step 9
    EstimateOnANumberLineTo20, // Step 10
    CompareNumbersTo20, // Step 11
    OrderNumbersTo20 // Step 12
  ]
});
export default Block;
