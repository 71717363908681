import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { getRandomFromArray, randomIntegerInclusive } from 'common/src/utils/random';
import CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { CounterVariantNoBlockSchema } from 'common/src/components/question/representations/types';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ScientificNotation } from 'common/src/utils/math';
import QF12CreateGattegnoChart from 'common/src/components/question/questionFormats/QF12CreateGattegnoChart';
import ReadPlaceValueChart from 'common/src/components/question/questionFormats/ReadPlaceValueChart';
import { GattegnoChart } from 'common/src/components/question/representations/Gattegno Chart/GattegnoChart';
import { View } from 'react-native';
import React from 'react';
import { filledArray } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'abU',
  description: 'abU',
  keywords: ['Place value', 'Represent', '10,000', 'Object'],
  schema: z.object({
    ones: z.number().int().min(0).max(6),
    tens: z.number().int().min(0).max(6),
    hundreds: z.number().int().min(0).max(6),
    thousands: z.number().int().min(1).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    // Either ones, tens or hundreds forced to be zero.
    const whichAreZero = getRandomFromArray(['ones', 'tens', 'hundreds']);
    const tens = whichAreZero === 'tens' ? 0 : randomIntegerInclusive(0, 6);
    const hundreds = whichAreZero === 'hundreds' ? 0 : randomIntegerInclusive(0, 6);
    // Allow the lowest number to be 1001; enforce at least 1 if tens and hundreds are both 0.
    const ones = (() => {
      if (whichAreZero === 'ones') {
        return 0;
      } else if (tens === 0 && hundreds === 0) {
        return randomIntegerInclusive(1, 6);
      } else {
        return randomIntegerInclusive(0, 6);
      }
    })();
    const thousands = randomIntegerInclusive(1, 6);
    return {
      ones,
      tens,
      hundreds,
      thousands
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands },
      translate,
      displayMode
    } = props;

    const createImageArray = (
      digitValue: number,
      powerOfTen: 'thousands' | 'hundreds' | 'tens' | 'ones'
    ) => {
      let imageName;
      let baseSize = 70;

      switch (powerOfTen) {
        case 'thousands':
          imageName = 'Base_Ten/Nails1000';
          break;
        case 'hundreds':
          imageName = 'Base_Ten/Nails100';
          baseSize -= 10;
          break;
        case 'tens':
          imageName = 'Base_Ten/Nails10';
          baseSize -= 10;
          break;
        case 'ones':
          imageName = 'Base_Ten/Nails1';
          baseSize -= 20;
          break;
      }

      const resize = displayMode === 'digital' ? 1 : 2;

      const imageArray = [
        filledArray(
          <AssetSvg name={imageName as SvgName} height={baseSize * resize} />,
          digitValue > 3 ? 3 : digitValue
        ),
        filledArray(
          <AssetSvg
            name={imageName as SvgName}
            height={baseSize * resize}
            style={{ opacity: digitValue <= 3 ? 0 : 1 }}
          />,
          digitValue <= 3 ? 1 : digitValue - 3
        )
      ];

      return (
        <View style={{ rowGap: 4 }}>
          {imageArray.map((elems, idx) => (
            <View
              key={idx}
              style={{
                flexDirection: 'row',
                columnGap: 4,
                alignItems: 'center'
              }}
            >
              {elems.map((elem, idx) => (
                <React.Fragment key={`${idx}-img`}>{elem}</React.Fragment>
              ))}
            </View>
          ))}
        </View>
      );
    };

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.thousandsHundredsTensOnesAns(
          thousands,
          hundreds,
          tens,
          ones
        )}
        title={translate.instructions.completeSentences()}
        testCorrect={[
          thousands.toString(),
          hundreds.toString(),
          tens.toString(),
          ones.toString(),
          (thousands * 1000 + hundreds * 100 + tens * 10 + ones).toString()
        ]}
        Content={
          <View style={{ flexDirection: 'row', columnGap: 4 }}>
            {thousands > 0 && createImageArray(thousands, 'thousands')}
            {hundreds > 0 && createImageArray(hundreds, 'hundreds')}
            {tens > 0 && createImageArray(tens, 'tens')}
            {ones > 0 && createImageArray(ones, 'ones')}
          </View>
        }
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'abV',
  description: 'abV',
  keywords: ['Place value', 'Represent', '10,000', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[3, 2, 1, 0]}
      counterVariant="base10Block"
      questionHeight={900}
    />
  ),
  questionHeight: 900
});

export const Question3 = newQuestionContent({
  uid: 'abW',
  description: 'abW',
  keywords: ['Place value', 'Represent', '10,000', 'Chart'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999),
    counterVariant: CounterVariantNoBlockSchema
  }),
  simpleGenerator: () => {
    // Generate random number between 1001 and 9999
    const number = randomIntegerInclusive(1001, 9999);

    const counterVariant = 'greyCounter' as const;

    return { number, counterVariant };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[3, 2, 1, 0]}
      counterVariant={question.counterVariant}
      questionHeight={1050}
    />
  ),
  questionHeight: 1050
});

export const Question4 = newQuestionContent({
  uid: 'abX',
  description: 'abX',
  keywords: ['Place value', 'Represent', '10,000', 'Chart'],
  schema: z.object({
    number: z.number().min(1001).max(9999)
  }),
  questionHeight: 850,
  example: {
    number: 1245
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[3, 2, 1, 0]}
      counterVariant="greyCounter"
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number.toLocaleString())}
      questionHeight={850}
      {...props}
    />
  )
});

export const Question5 = newQuestionContent({
  uid: 'abY',
  description: 'abY',
  keywords: ['Place value', 'Represent', '10,000', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999)
  }),
  questionHeight: 1440,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: ({ question: { number }, translate, ...props }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[number.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentence="<ans/>"
        Content={({ dimens }) => (
          <GattegnoChart number={number} rowsToShow={[3, 2, 1, 0]} dimens={dimens} />
        )}
        {...props}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'abZ',
  description: 'abZ',
  keywords: ['Place value', 'Represent', '10,000', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999)
  }),
  example: {
    number: 2124
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number}
        rowsToShow={[3, 2, 1, 0]}
        title={translate.instructions.useGattegnotoShowNum(number.toLocaleString())}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentNumbersTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
