import { filledArray, range } from 'common/src/utils/collections';
import { randomIntegerInclusiveStep } from './random';

/**
 * Get a function from time to temperature, where time is given in hours past midnight.
 * We model this with a cosine function that happens to hit the min and max temperatures given within the time slice.
 */
export function getDayTemperaturesGenerator(
  tempMin: number,
  tempMax: number,
  timeMin: number,
  timeMax: number,
  tempMaxTime: number
): (time: number) => number {
  const shape = (time: number) => Math.cos(((time - tempMaxTime) * Math.PI) / 12);
  const times = range(timeMin, timeMax);
  const sampledShape = times.map(shape);
  const sampledMin = Math.min(...sampledShape);
  const sampledMax = Math.max(...sampledShape);

  const amplitude = (tempMax - tempMin) / (sampledMax - sampledMin);
  const offset = tempMin - amplitude * sampledMin;

  return (time: number) => shape(time) * amplitude + offset;
}

/**
 * Get random but evenly distributed partitions
 * Useful when generating pie charts.
 * The only constrainst handled are step and min/max. For more constrainst this can be wrapped in a rejection sample
 */
export function generateRandomPartitions({
  total,
  parts,
  min,
  max,
  step = 1
}: {
  total: number;
  parts: number;
  min: number;
  max?: number;
  step?: number;
}): number[] {
  if (min * parts > total) {
    throw new Error('the minimum multiplied by the parts must be less than the total');
  }
  if (max !== undefined && max * parts < total) {
    throw new Error(
      'the maximum multiplied by the parts must be greater than or equal to the total'
    );
  }
  // to start with lets give each part the minimum value
  const partitions = filledArray(min, parts);
  // now lets work out remainder
  let remainder = total - min * parts;
  let partIndex = 0;
  max = max ?? total;
  // split the remainder randomly across the parts
  while (remainder > 0) {
    const index = partIndex;
    if (partitions[index] === max) {
      partIndex = (index + 1) % parts;
    } else {
      // generate random number between 0 and the min of the remainder and the value to part to max value
      const addition = randomIntegerInclusiveStep(
        0,
        Math.min(remainder, max - partitions[index]),
        step
      );
      const updatedValue = partitions[index] + addition;
      partitions[index] = updatedValue;
      remainder = remainder - addition;
      partIndex = (index + 1) % parts;
    }
  }
  return partitions;
}
