import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { SetState } from '../../../../utils/react';
import { ScaleFactorContext } from '../../../../theme/scaling';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { Line, Svg } from 'react-native-svg';
import { AssetSvg } from '../../../../assets/svg';
import { colors } from '../../../../theme/colors';
import { withStateHOC } from '../../../../stateTree';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

const AnimatedLine = Animated.createAnimatedComponent(Line);
/** Nice and wide to make the pencil easier to click on. */
const CLICKABLE_WIDTH = 100;

type DrawLineProps = {
  /** Number of pixels to one of the ruler's units */
  pixelsPerUnit: number;
  /** Current line length, in ruler's units */
  lineLength: number;
  /** Callback for when line length is change by a gesture. */
  setLineLength?: SetState<number>;
  /** Max length, in ruler's units. */
  maxLength: number;
  /** Interval to snap to while dragging, in ruler's units. Default: don't snap. */
  snapToNearest?: number;
  /** Default: grey */
  lineColor?: string;
};

/**
 * A pencil that can be dragged to produce a line. This uses some unit of distance (e.g. cm) as the basis for its
 * props.
 */
export default function DrawLine({
  pixelsPerUnit,
  lineLength,
  setLineLength,
  maxLength,
  snapToNearest,
  ...props
}: DrawLineProps) {
  // Convert each of the props to pixels
  const unitsPerPixel = 1 / pixelsPerUnit;
  const lineLengthPixels: number = lineLength * pixelsPerUnit;
  const setLineLengthPixels: SetState<number> | undefined = useMemo(() => {
    if (setLineLength === undefined) {
      return undefined;
    }
    return newStateOrAction => {
      if (typeof newStateOrAction !== 'function') {
        setLineLength(number(math.evaluate(`${newStateOrAction} * ${unitsPerPixel}`)));
      } else {
        setLineLength(old => {
          const prevState = number(math.evaluate(`${old} * ${pixelsPerUnit}`));
          return number(math.evaluate(`${newStateOrAction(prevState)} * ${unitsPerPixel}`));
        });
      }
    };
  }, [pixelsPerUnit, setLineLength, unitsPerPixel]);
  const maxLengthPixels = maxLength * pixelsPerUnit;
  const snapToNearestPixels =
    snapToNearest === undefined ? undefined : snapToNearest * pixelsPerUnit;

  // Then just call into the component we made earlier for pixels!
  return (
    <DrawLinePixels
      lineLength={lineLengthPixels}
      setLineLength={setLineLengthPixels}
      maxLength={maxLengthPixels}
      snapToNearest={snapToNearestPixels}
      {...props}
    />
  );
}

/** StateTree version of {@link DrawLine}. */
export const DrawLineWithState = withStateHOC(DrawLine, {
  stateProp: 'lineLength',
  setStateProp: 'setLineLength'
});

type DrawLinePixelsProps = {
  /** Current line length, in pixels */
  lineLength: number;
  /** Callback for when line length is change by a gesture. */
  setLineLength?: SetState<number>;
  /** Max length, in pixels. */
  maxLength: number;
  /** Interval to snap to while dragging, in pixels. Default: don't snap. */
  snapToNearest?: number;
  /** Default: grey */
  lineColor?: string;
};

/**
 * A pencil that can be dragged to produce a line. This uses pixels as the basis for its props.
 */
function DrawLinePixels({
  lineLength,
  setLineLength,
  maxLength,
  snapToNearest,
  lineColor = colors.greys500
}: DrawLinePixelsProps) {
  const scaleFactor = useContext(ScaleFactorContext);

  const animatedLength = useSharedValue(lineLength);

  const beginPagePosition = useSharedValue<[number, number] | null>(null);
  const beginLength = useSharedValue<number | null>(null);
  const updateParent = setLineLength !== undefined;

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPagePosition.value = [event.absoluteX, event.absoluteY];
          beginLength.value = animatedLength.value;
        })
        .onUpdate(event => {
          const translation = (event.absoluteX - beginPagePosition.value![0]) / scaleFactor;
          let currentLength = beginLength.value! + translation;

          // Clamp to stay in bounds
          currentLength = Math.min(maxLength, Math.max(0, currentLength));

          // Snapping behaviour
          if (snapToNearest) {
            currentLength = Math.round(currentLength / snapToNearest) * snapToNearest;
          }

          animatedLength.value = currentLength;
        })
        .onFinalize(() => {
          updateParent && runOnJS(setLineLength)(animatedLength.value);
        }),
    [
      animatedLength,
      beginLength,
      beginPagePosition,
      maxLength,
      scaleFactor,
      setLineLength,
      snapToNearest,
      updateParent
    ]
  );

  const animatedPencilStyle = useAnimatedStyle(
    () => ({ left: animatedLength.value - CLICKABLE_WIDTH / 2 }),
    [animatedLength]
  );

  const animatedLineProps = useAnimatedProps(
    () => ({
      x2: animatedLength.value
    }),
    [animatedLength]
  );

  return (
    <View style={{ width: maxLength, height: 122 }}>
      <GestureDetector gesture={panGesture}>
        <Animated.View
          style={[
            { width: CLICKABLE_WIDTH, height: 110, alignItems: 'center' },
            animatedPencilStyle
          ]}
        >
          <AssetSvg name="Measure/pencil" height={110} />
        </Animated.View>
      </GestureDetector>
      <Svg width={maxLength} height={12}>
        <AnimatedLine
          x1={0}
          animatedProps={animatedLineProps}
          y1={6}
          y2={6}
          strokeWidth={12}
          stroke={lineColor}
        />
      </Svg>
    </View>
  );
}
