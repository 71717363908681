import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD } from 'common/src/constants';
import ColumnOperations from 'common/src/components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { range } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahU',
  description: 'ahU',
  keywords: ['Addition', 'Counters'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(100)
        .max(999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(1000)
        .max(10_000)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => numbersDoNotExchange(val.var1, val.var2), 'must not exchange.')
    .refine(val => val.var1 + val.var2 < 10_000, 'total should be less than 10,000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(100, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(1000, 10_000, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange and total is less than 10,000.
      ({ var1, var2 }) => var1 + var2 < 10_000 && numbersDoNotExchange(var1, var2)
    );

    return {
      var1,
      var2
    };
  },
  Component: props => {
    const {
      question: { var1, var2 },
      translate
    } = props;

    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    const [topNumber, bottomNumber] = shuffle([var1, var2], {
      random: seededRandom(props.question)
    });

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={topNumber}
        bottomNumber={bottomNumber}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ahV',
  description: 'ahV',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      number1: z.number().int().min(1000).max(9999),
      number2: z.number().int().min(100).max(999),
      isFourDigitFirst: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1000, 9999);

        const number2 = randomIntegerInclusive(100, 999);

        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotExchange(number1, number2)
    );

    const isFourDigitFirst = getRandomBoolean();

    return { number1, number2, isFourDigitFirst };
  },

  Component: props => {
    const {
      question: { number1, number2, isFourDigitFirst },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={[(number1 + number2).toString()]}
        sentence={
          isFourDigitFirst
            ? `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`
            : `${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()} = <ans/>`
        }
        questionHeight={400}
        {...props}
      />
    );
  },
  questionHeight: 400
});

const Question3 = newQuestionContent({
  uid: 'ahW',
  description: 'ahW',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      number1: z.number().int().min(1000).max(8999),
      number2: z.number().int().min(1000).max(9999)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1000, 8999);

    const number2 = randomIntegerInclusive(1000, 9999 - number1, {
      constraint: x => numbersDoNotExchange(number1, x)
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    const [topNumber, bottomNumber] = shuffle([number1, number2], {
      random: seededRandom(props.question)
    });

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={topNumber}
        bottomNumber={bottomNumber}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ahX',
  description: 'ahX',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(9899),
      numberA2: z.number().int().min(100).max(999),
      numberB1: z.number().int().min(1000).max(9899),
      numberB2: z.number().int().min(100).max(999),
      numberC1: z.number().int().min(1000).max(9899),
      numberC2: z.number().int().min(100).max(999),
      numberD1: z.number().int().min(1000).max(9899),
      numberD2: z.number().int().min(100).max(999)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2 * 10),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 and numberB2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, val.numberC2),
      'numberC1 and numberC2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberD1, val.numberD2),
      'numberD1 and numberD2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberA1 = randomIntegerInclusive(1000, 9899);
        const numberA2 = randomIntegerInclusive(100, 999);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2 * 10)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberB1 = randomIntegerInclusive(1000, 9899);
        const numberB2 = randomIntegerInclusive(100, 999);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberB1, numberB2 }) => numbersDoNotExchange(numberB1, numberB2)
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberC1 = randomIntegerInclusive(1000, 9899);
        const numberC2 = randomIntegerInclusive(100, 999);
        return { numberC1, numberC2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberC1, numberC2 }) => numbersDoNotExchange(numberC1, numberC2)
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberD1 = randomIntegerInclusive(1000, 9899);
        const numberD2 = randomIntegerInclusive(100, 999);
        return { numberD1, numberD2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberD1, numberD2 }) => numbersDoNotExchange(numberD1, numberD2)
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        topNumber: numberA2 * 10,
        bottomNumber: numberA1,
        isCorrect: false,
        topBlankDigits: [0],
        bottomBlankDigits: []
      };
      const eqB = {
        topNumber: numberB1,
        bottomNumber: numberB2,
        isCorrect: false,
        topBlankDigits: [1],
        bottomBlankDigits: []
      };
      const eqC = {
        topNumber: numberC1,
        bottomNumber: numberC2,
        isCorrect: false,
        topBlankDigits: [],
        bottomBlankDigits: [1]
      };
      const eqD = {
        topNumber: numberD1,
        bottomNumber: numberD2,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheColumnAdditionThatIsLaidOutCorrectly()}
        pdfTitle={translate.instructions.circleTheColumnAdditionThatIsLaidOutCorrectly()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ width: dimens.width, height: dimens.height - 30 }}
                removeExtraCells
                topBlankDigits={equation.topBlankDigits}
                bottomBlankDigits={equation.bottomBlankDigits}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ahY',
  description: 'ahY',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      number1: z.number().int().min(1000).max(8999),
      number2: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1000, 8999);

    const number2 = randomIntegerInclusive(1000, 9999 - number1, {
      constraint: x => numbersDoNotExchange(number1, x)
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={[(number1 + number2).toString()]}
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        questionHeight={400}
        {...props}
      />
    );
  },
  questionHeight: 400
});

const Question6 = newQuestionContent({
  uid: 'ahZ',
  description: 'ahZ',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      topNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersDoNotExchange(val.topNumber, val.bottomNumber),
      'topNumber and bottomNumber must not exchange.'
    ),
  simpleGenerator: () => {
    const topNumber = randomIntegerInclusive(1000, 8999);

    const bottomNumber = randomIntegerInclusive(1000, 9999 - topNumber, {
      constraint: x => numbersDoNotExchange(topNumber, x)
    });

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds, missingThousands };
  },

  Component: props => {
    const {
      question: {
        topNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddUpToTwo4DigitNumbersNoExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
