import { newBlockContent } from '../../../Block';

import CountIn2s from './1CountIn2s';
import CountIn10s from './2CountIn10s';
import CountIn5s from './3CountIn5s';
import RecogniseEqualGroups from './4RecogniseEqualGroups';
import AddEqualGroups from './5AddEqualGroups';
import MakeArrays from './6MakeArrays';
import MakeDoubles from './7MakeDoubles';
import MakeEqualGroupsGrouping from './8MakeEqualGroupsGrouping';
import MakeEqualGroupsSharing from './9MakeEqualGroupsSharing';

const Block = newBlockContent({
  block: 'MultiplicationAndDivision',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    CountIn2s, // Step 1
    CountIn10s, // Step 2
    CountIn5s, // Step 3
    RecogniseEqualGroups, // Step 4
    AddEqualGroups, // Step 5
    MakeArrays, // Step 6
    MakeDoubles, // Step 7
    MakeEqualGroupsGrouping, // Step 8
    MakeEqualGroupsSharing // Step 9
  ]
});
export default Block;
