import { useContext } from 'react';
import { G, Path } from 'react-native-svg';
import { GridContext, GridSvgChildren } from './Grid';
import { countRange } from '../../../../utils/collections';

type Props = {
  /** Vertices of the shape. The distance between each point should be a whole number multiple of the stick length. */
  vertices: [number, number][];
  /** Length of a single lolly stick in math units. Default: 1. */
  stickLength?: number;
  /** Whether to close the loop, linking the last point to the first. Default: false. */
  closeLoop?: boolean;
};

/**
 * Polygon made out of lolly sticks. Must be placed within a Grid component.
 *
 * Limitation: Grid's x axis and y axis must have the same scale factor.
 */
export default function LollyStickShape({ vertices, stickLength = 1, closeLoop = false }: Props) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  // Assume y axis has same scale factor
  const gridWidth = mathToSvgX(stickLength) - mathToSvgX(0);

  return (
    <GridSvgChildren>
      {vertices.map(([x, y], i) => {
        if (!closeLoop && i === vertices.length - 1) {
          // Not closing the loop, so don't draw sticks starting at the final vertex.
          return null;
        }
        const [nextX, nextY] = vertices[i + 1] ?? vertices[0];
        const dy = nextY - y;
        const dx = nextX - x;
        const direction = Math.PI / 2 - Math.atan2(dy, dx);
        const stickLengthsToNextPoint = Math.sqrt(dx * dx + dy * dy) / stickLength;
        // Draw as many whole sticks as will fit, with a little wiggle room to allow for floating point rounding error
        const sticksToDraw = Math.floor(stickLengthsToNextPoint + 0.1);

        return countRange(sticksToDraw).map(n => (
          <LollyStick
            key={`${i}-${n}`}
            startX={mathToSvgX(x + n * stickLength * Math.sin(direction))}
            startY={mathToSvgY(y + n * stickLength * Math.cos(direction))}
            length={gridWidth}
            direction={direction}
          />
        ));
      })}
    </GridSvgChildren>
  );
}

const NATURAL_LENGTH = 220;
const DEGRESS_PER_RAD = 180 / Math.PI;

export function LollyStick({
  startX,
  startY,
  length,
  direction
}: {
  startX: number;
  startY: number;
  length: number;
  /** Given in radians from x axis, anticlockwise. */
  direction: number;
}) {
  // Stick of length 220, pointing from (0,0) vertically upwards
  // The path information below is taken from the .svg of lolly sticks we already have.
  const stick = (
    <Path
      d="M23.8587 189.442V12.9294C23.8587 6.89324 18.9654 2 12.9293 2C6.89324 2 2 6.89324 2 12.9294V189.442C2 195.478 6.89324 200.372 12.9293 200.372C18.9654 200.372 23.8587 195.478 23.8587 189.442Z"
      fill="#FBCB83"
      stroke="#564B41"
      strokeWidth="2.4896"
      strokeMiterlimit="10"
      transform={[{ translateY: 8 }, { translateX: -13.5 }]}
      rotation={180}
    />
  );

  return (
    // Position, scale and rotate the stick into position
    <G x={startX} y={startY} rotation={direction * DEGRESS_PER_RAD} scale={length / NATURAL_LENGTH}>
      {stick}
    </G>
  );
}
