import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import Text from 'common/src/components/typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { getActualDimens } from '../../../utils/getActualDimens';

type Props = {
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Name of the shape svg
   */
  shapeSvg:
    | 'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle'
    | 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle'
    | 'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle'
    | 'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'
    | 'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
    | 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled'
    | 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled'
    | 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled'
    | 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled'
    | 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled';
  /**
   * @param labels An array of strings to hold the labels that go around the shape
   * Starts with height, width, slope
   */
  labels?: string[];
};

/**
 * This component renders a parallelogram with labels on the height, width and slope.
 */
export const LabelSplitParallelogram = (props: Props) => {
  const {
    labels,
    dimens: { width, height },
    shapeSvg
  } = props;
  const displayMode = useContext(DisplayMode);

  const styles = useStyles(width, height);
  const shapeWidth = width - 64;
  const shapeHeight = height - 64;
  const labelWidth = displayMode === 'digital' ? 52 : 75;

  const font = displayMode === 'digital' ? 22 : 32;

  //get labels for shapes and absolute positions
  const shapeLabels = () => {
    const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
      shapeSvg,
      { width, height },
      { shapeWidth, shapeHeight }
    );
    const sideLabelPositions = (() => {
      switch (shapeSvg) {
        case 'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle':
        case 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled':
          return [
            // height
            {
              right: widthDiff + actualWidth * 0.35,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { bottom: -20, left: widthDiff + actualWidth * 0.5 },
            // slope
            { top: heightDiff + actualHeight * 0.35, left: widthDiff + actualWidth }
          ];
        case 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle':
        case 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled':
          return [
            // height
            {
              left: widthDiff + actualWidth * 0.3,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { bottom: -20, left: widthDiff + actualWidth * 0.25 },
            // slope
            { top: heightDiff + actualHeight * 0.35, left: widthDiff + actualWidth }
          ];
        case 'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle':
        case 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled':
          return [
            // height
            {
              left: widthDiff + actualWidth * 0.2,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { bottom: -20, left: widthDiff + actualWidth * 0.5 },
            // slope
            { top: heightDiff + actualHeight * 0.35, left: widthDiff + actualWidth }
          ];
        case 'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle':
        case 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled':
          return [
            // height
            {
              left: widthDiff + actualWidth * 1.05,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { top: heightDiff + actualHeight * 0.1, left: widthDiff + actualWidth * 0.4 },
            // slope
            { top: heightDiff + actualHeight * 0.9, left: widthDiff + actualWidth * 0.4 }
          ];
        case 'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle':
          return [
            // height
            {
              left: widthDiff + actualWidth * 0.48,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { top: actualHeight + 20, left: widthDiff + actualWidth * 0.25 },
            // slope
            { top: heightDiff + actualHeight * 0.35, left: widthDiff + actualWidth * 0.85 }
          ];
        case 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled':
          return [
            // height
            {
              left: widthDiff + actualWidth * 0.48,
              top: heightDiff + actualHeight * 0.35
            },
            // width
            { top: heightDiff / 2 + actualHeight + 30, left: widthDiff + actualWidth * 0.25 },
            // slope
            { top: heightDiff + actualHeight * 0.35, left: widthDiff + actualWidth * 0.85 }
          ];
      }
    })();

    const labelSides =
      labels &&
      labels.map((label, index) => {
        return (
          <View
            key={index}
            style={[
              sideLabelPositions[index],
              { position: 'absolute', minWidth: labelWidth, zIndex: 2 }
            ]}
          >
            <Text variant="WRN700" style={{ fontSize: font, textAlign: 'center' }}>
              {label}
            </Text>
          </View>
        );
      });

    return {
      labelSides
    };
  };
  const { labelSides } = shapeLabels();

  return (
    <View style={[styles.imageWrapper]}>
      {labelSides}
      <AssetSvg name={shapeSvg as SvgName} width={shapeWidth} height={shapeHeight} />
    </View>
  );
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          width,
          height
        }
      }),
    [width, height]
  );
};

const getDimens = (
  svgName: SvgName,
  dimens: Dimens,
  shapeDimens: { shapeWidth: number; shapeHeight: number }
) => {
  const { width, height } = dimens;
  const { shapeWidth, shapeHeight } = shapeDimens;

  const { actualHeight, actualWidth } = getActualDimens(svgName, {
    width: shapeWidth,
    height: shapeHeight
  });

  const widthDiff = (width - actualWidth) * 0.5;
  const heightDiff = (height - actualHeight) * 0.5;

  return {
    actualWidth,
    actualHeight,
    widthDiff,
    heightDiff
  };
};
