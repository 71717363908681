import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import BaseTenRepresentation from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  getBinOpEquation,
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString
} from 'common/src/utils/fourOperations';
import QF23CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation } from 'common/src/utils/math';
import { all, create, number } from 'mathjs';
import { DIV } from 'common/src/constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKa',
  description: 'aKa',
  keywords: ['Division', '10'],
  schema: z.object({
    number1: z.number().int().min(1).max(4),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 4);
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const number3 = number1 * 100 + number2 * 10;
    const ans = number3 / 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        sentence={`${number3} = <ans/> ${translate.powersOfTen.tens(number3)}`}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: dimens.width,
              justifyContent: 'space-evenly'
            }}
          >
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: { hundreds: number1, tens: number2 },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              containerStyle={{ alignItems: 'center' }}
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aKb',
  description: 'aKb',
  keywords: ['Division', '10'],
  schema: z.object({
    number1: z.number().int().min(10).max(990).multipleOf(10),
    flipped: z.boolean()
  }),
  simpleGenerator: () => {
    const [option1, option2, option3] = randomUniqueIntegersInclusiveStep(10, 500, 10, 3);
    const option4 = randomIntegerInclusiveStep(510, 990, 10);
    const flipped = getRandomBoolean();

    return { number1: getRandomFromArray([option1, option2, option3, option4] as const), flipped };
  },

  Component: props => {
    const {
      question: { number1, flipped },
      translate
    } = props;

    // Answers
    const eqA = getBinOpEquation({
      left: number1,
      right: 10,
      sign: 'divide',
      answer: 'result',
      flipped: flipped
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={binOpEquationsToTestCorrect([eqA])[0]}
        sentence={binOpEquationToSentenceString(eqA)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKc',
  description: 'aKc',
  keywords: ['Division', '10'],
  schema: z.object({
    number1: z.number().min(110).max(990).step(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(110, 990, 10);
    return { number1 };
  },
  Component: ({ question: { number1 }, translate, displayMode }) => {
    const power = 10;
    const answer = number(math.evaluate(`${number1} / ${power}`));
    return (
      <QF23CreatePlaceValueChart
        initialState={displayMode === 'digital' ? number1 : undefined}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        title={translate.instructions.dragCountersToShowNumDivNum(number1, power)}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number1.toLocaleString()} ${DIV} ${power.toLocaleString()}`
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aKd',
  description: 'aKd',
  keywords: ['Division', '10'],
  schema: z.object({
    number2: z.number().int().min(100).max(900).multipleOf(100),
    number3: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(100, 900, 100);
    const number3 = randomIntegerInclusiveStep(10, 90, 10);

    return { number2, number3 };
  },

  Component: props => {
    const {
      question: { number2, number3 },
      translate
    } = props;

    // More numbers
    const number4 = number2 + number3;

    // Answers
    const eqB = getBinOpEquation({
      left: number2,
      right: 10,
      sign: 'divide',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: number3,
      right: 10,
      sign: 'divide',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      left: number4,
      right: 10,
      sign: 'divide',
      answer: 'result'
    });

    const eqs = [eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aKe',
  description: 'aKe',
  keywords: ['Division', '10'],
  schema: z.object({
    number1: z.number().int().min(100).max(900).multipleOf(100)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 900, 100);

    return { number1 };
  },

  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    // More numbers
    const number2 = number1 / 10;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[number1.toString()], [(number1 * 10).toString()]]}
        sentences={[`<ans/> ${DIV} 10 = ${number2}`, `<ans/> ${DIV} 10 = ${number1}`]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKf',
  description: 'aKf',
  keywords: ['Division', '10'],
  schema: z.object({
    number1: z.number().int().min(100).max(900).multipleOf(100),
    number2: z.number().int().min(10).max(90).multipleOf(10),
    number6: z.number().int().min(10).max(100),
    sentenceIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 900, 100);
    const number2 = randomIntegerInclusiveStep(10, 90, 10);
    const number6 = randomIntegerInclusive(10, 100);

    const sentenceIndex = randomIntegerInclusive(0, 2);

    return { number1, number2, number6, sentenceIndex };
  },

  Component: props => {
    const {
      question: { number1, number2, number6, sentenceIndex },
      translate
    } = props;

    // More numbers
    const number3 = number2 + number1;
    const number4 = number3 / 10;
    const number5 = number1 / 10;
    const number7 = number6 * 10;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={
          [[number4.toString()], [number5.toString()], [number7.toString()]][sentenceIndex]
        }
        sentence={
          [
            translate.answerSentences.ansOneTenthSizeOfX(number3),
            translate.answerSentences.ansOneTenthSizeOfX(number1),
            translate.answerSentences.xOneTenthSizeOfAns(number6)
          ][sentenceIndex]
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
