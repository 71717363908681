import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { ADD, MULT, SUB } from 'common/src/constants';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import ContentBox from 'common/src/components/molecules/ContentBox';
import Text from 'common/src/components/typography/Text';
import { filledArray } from 'common/src/utils/collections';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { useMemo } from 'react';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ar4',
  description: 'ar4',
  keywords: ['Multiply', 'Known facts'],
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    number2: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);

    const number2 = randomIntegerInclusive(2, 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number4 = number2 * 100;

    const number6 = number2 * 10;

    const answerA = number1 * number2;

    const answerB = number1 * number4;

    const answerC = number6 * number1;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[answerA.toString()], [answerB.toString()], [answerC.toString()]]}
        sentences={[
          `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`,
          `${number6.toLocaleString()} ${MULT} ${number1.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ar5',
  description: 'ar5',
  keywords: ['Add', 'Subtract', 'Known facts'],
  schema: z.object({
    number1: z.number().int().min(1001).max(9999),
    number2: z.number().int().min(1001).max(9999)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999);

    const number2 = randomIntegerInclusive(1001, 9999);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const number5 = number1 * 10;

    const number6 = number2 * 10;

    const number8 = number1 + 5;

    const answerB = number5 + number6;

    const answerC = number2 - 5;

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.useThisFactToCompleteCalcs()}
        testCorrect={[[number1.toString()], [answerB.toString()], [answerC.toString()]]}
        style={{ alignItems: 'center' }}
        sentences={[
          `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
          `${number5.toLocaleString()} ${ADD} ${number6.toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${number8.toLocaleString()} ${ADD} <ans/>`
        ]}
        Content={
          <ContentBox>
            <Text variant="WRN400">
              {number1.toLocaleString()} {ADD} {number2.toLocaleString()} ={' '}
              {number3.toLocaleString()}
            </Text>
          </ContentBox>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ar6',
  description: 'ar6',
  keywords: ['Multiply', 'Known facts', 'Bar model'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(val => val % 10 !== 0, 'number1 must not be a multiple of 10')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const number3 = number1 * 5;

    const numbers = [[number3], filledArray(number1, 5)];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.theBarModelShowsX(
          `${number1.toLocaleString()} ${MULT} 5 = ${number3.toLocaleString()}`
        )}
        testCorrect={[[(number1 * 6).toLocaleString()], [((number1 + 1) * 5).toLocaleString()]]}
        sentences={[
          `${number1.toLocaleString()} ${MULT} 6 = <ans/>`,
          `${(number1 + 1).toLocaleString()} ${MULT} 5 = <ans/>`
        ]}
        style={{ alignItems: 'center' }}
        Content={({ dimens }) => (
          <BarModel numbers={numbers} total={number3} dimens={dimens} sameRowColor />
        )}
        questionHeight={800}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ar7',
  description: 'ar7',
  keywords: ['Addition', 'Subtraction', 'Known facts', 'Equal'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1011)
        .max(9999)
        .refine(val => val % 10 !== 0, 'number1 must not be a multiple of 10'),
      number2: z.number().int().min(1010).max(9990).multipleOf(10)
    })
    .refine(val => val.number1 > val.number2, 'number1 must be greater than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1011, 9999, {
      constraint: x => x % 10 !== 0
    });

    const number2 = randomIntegerInclusiveStep(1010, 9990, 10, {
      constraint: x => x < number1
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + 1000;
    const number4 = number1 - number2;
    const number5 = number2 - 1;
    const number6 = number1 - 1;
    const number8 = number3 - 1;
    const number10 = number4 + 1;
    const number12 = number4 - 1;
    const number13 = number5 + 1;

    const statements = [
      {
        statement: `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} =`,
        value: number1 - number2
      },
      {
        statement: `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} =`,
        value: number3 - number2
      },
      {
        statement: `${number4.toLocaleString()} ${ADD} ${number2.toLocaleString()} =`,
        value: number4 + number2
      },
      {
        statement: `${number4.toLocaleString()} ${ADD} ${number5.toLocaleString()} =`,
        value: number4 + number5
      }
    ];

    const items = useMemo(() => {
      const items = [
        {
          component: `${number6.toLocaleString()} ${SUB} ${number5.toLocaleString()}`,
          value: number6 - number5
        },
        {
          component: `${number8.toLocaleString()} ${SUB} ${number5.toLocaleString()}`,
          value: number8 - number5
        },
        {
          component: `${number10.toLocaleString()} ${ADD} ${number5.toLocaleString()}`,
          value: number10 + number5
        },
        {
          component: `${number12.toLocaleString()} ${ADD} ${number13.toLocaleString()}`,
          value: number12 + number13
        }
      ];

      return shuffle(items, { random: seededRandom(props.question) });
    }, [number10, number12, number13, number5, number6, number8, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        itemsLetterEmWidth={0.5}
        itemMaxLines={1}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'ar8',
  description: 'ar8',
  keywords: ['Bar model', 'Part-whole model', 'Number line', 'Table'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(30010)
      .max(99990)
      .step(10)
      .refine(val => val % 100 !== 0, 'must not be multiple of 100'),
    number2: z.number().int().min(-9).max(9),
    number4: z.number().int().min(10010).max(89980)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(30010, 99990, 10, {
      constraint: x => x % 100 !== 0
    });
    const number2 = randomIntegerInclusive(-9, 9, {
      constraint: x => x !== 0
    });
    const number4 = randomIntegerInclusive(10010, number1 - 10010);

    return { number1, number2, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4 },
      translate
    } = props;

    const number3 = number1 + number2;
    const number5 = number3 - number4;
    const number6 = number1 - number4;
    const number7 = number3 - number6;
    const number8 = Math.round(number4 / 10000) * 10000;
    const number9 = number3 - number8;

    const barModelNumbers = [[number3], [number4, number5], [number7, number6], [number8, number9]];

    const answerIndices = [[], [], [0], [1]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={barModelNumbers}
        answerIndices={answerIndices}
        oneFontSize
        total={Math.max(...barModelNumbers.flat())}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'ar9',
  description: 'ar9',
  keywords: ['Difference', 'Known facts'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(8),
      number2: z.number().int().min(3).max(8),
      number3: z.number().int().min(2).max(9),
      number4: z.number().int().min(2).max(9),
      number5: z.number().int().min(13).max(18)
    })
    .refine(
      val => Math.abs(val.number1 - val.number2) >= 2,
      'difference between number1 & number2 must be at least 2'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(3, 8);
        const number2 = randomIntegerInclusive(3, 8);

        return { number1, number2 };
      },
      ({ number1, number2 }) => Math.abs(number1 - number2) >= 2
    );

    const number3Choice = getRandomBoolean();
    const number3 = number3Choice ? number1 + 1 : number1 - 1;
    const number4 = number3Choice ? number2 - 1 : number2 + 1;

    const number5 = number1 + 10;

    return { number1, number2, number3, number4, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5 },
      translate
    } = props;

    const number6 = number3 + 10;

    const number2String = number2.toLocaleString();
    const number4String = number4.toLocaleString();

    const statements = [
      {
        statement: `${number1.toLocaleString()} ${MULT} ${number2String}  <ans/>  ${number3.toLocaleString()} ${MULT} ${number4String}`,
        value: lessThanGreaterThanOrEqualTo(number1 * number2, number3 * number4)
      },
      {
        statement: `${number5.toLocaleString()} ${MULT} ${number2String}  <ans/>  ${number6.toLocaleString()} ${MULT} ${number4String}`,
        value: lessThanGreaterThanOrEqualTo(number5 * number2, number6 * number4)
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompareCalculations()}
        pdfTitle={translate.instructions.useCardsToCompareCalculationsEachCardCanBeUsedMoreThanOnce()}
        items={['<', '>', '=']}
        sentenceStyle={{ alignSelf: 'center' }}
        sentencesStyle={{ alignSelf: 'center' }}
        moveOrCopy="copy"
        sentences={statements.map(({ statement }) => statement)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsHidden"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReasonFromKnownFacts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
