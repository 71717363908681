import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { all, create, number } from 'mathjs';
import { MULT } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { range } from '../../../../utils/collections';
import { ArrowAnswerBoxesKeyboardWithState } from '../../../../components/question/representations/ArrowAnswerBoxes/ArrowAnswerBoxesInput';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aU6',
  description: 'aU6',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths'
  ],
  schema: z
    .object({
      ones: z.number().min(0).max(9),
      tenths: z.number().min(0).max(9),
      multiplier: numberEnum([10, 100, 1000])
    })
    .refine(val => val.ones + val.tenths >= 1),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const min = ones === 0 ? 1 : 0;
    const tenths = randomIntegerInclusive(min, 9);
    const multiplier = getRandomFromArray([10, 100, 1000] as const);

    return { ones, tenths, multiplier };
  },
  Component: ({ question: { ones, tenths, multiplier }, translate }) => {
    const total = number(math.evaluate(`${ones} + ${tenths}/10`));
    const answer = number(math.evaluate(`${total} * ${multiplier}`));
    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeCalculation()}<br/>${translate.instructions.useCountersToHelp()}`}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={`${total.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[3, 2, 1, 0, -1, -2]}
            dimens={dimens}
            counterVariant={'greyCounter'}
            headerVariant="shortName"
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aU7',
  description: 'aU7',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths'
  ],
  schema: z
    .object({
      ones: z.number().min(0).max(9),
      tenths: z.number().min(0).max(9),
      multiplier: numberEnum([10, 100, 1000])
    })
    .refine(val => val.ones + val.tenths >= 1),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const min = ones === 0 ? 1 : 0;
    const tenths = randomIntegerInclusive(min, 9);
    const multiplier = getRandomFromArray([10, 100, 1000] as const);

    return { ones, tenths, multiplier };
  },
  Component: ({ question: { ones, tenths, multiplier }, translate }) => {
    const total = number(math.evaluate(`${ones} + ${tenths}/10`));
    const answer = number(math.evaluate(`${total} * ${multiplier}`));
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragCountersIntoPVCToRepresentAnswerTo(
          `${total.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersIntoPVCToRepresentAnswerTo(
          `${total.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
        )}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[3, 2, 1, 0, -1, -2]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aU8',
  description: 'aU8',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths'
  ],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(1).max(9),
    dragOnRight: z.array(z.boolean()).length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);
    const number2 = randomIntegerInclusive(1, 9);
    const dragOnRight = range(0, 3).map(() => getRandomBoolean());

    return { number1, number2, dragOnRight };
  },
  Component: props => {
    const {
      question: { number1, number2, dragOnRight },
      translate
    } = props;

    const number3 = number(math.evaluate(`${number1} + ${number2}/10`));
    const number4 = number(math.evaluate(`${number3} *10`));
    const number5 = number(math.evaluate(`${number3} *100`));
    const number6 = number(math.evaluate(`${number3} *1000`));

    const sentences = shuffle(
      [
        {
          sentence: dragOnRight[0]
            ? `${number3.toLocaleString()} ${MULT} ${(1).toLocaleString()} = <ans/>`
            : `<ans/> = ${number3.toLocaleString()} ${MULT} ${(1).toLocaleString()}`,
          answer: number3
        },
        {
          sentence: dragOnRight[1]
            ? `${number3.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`
            : `<ans/> = ${number3.toLocaleString()} ${MULT} ${(10).toLocaleString()}`,
          answer: number4
        },
        {
          sentence: dragOnRight[2]
            ? `${number3.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`
            : `<ans/> = ${number3.toLocaleString()} ${MULT} ${(100).toLocaleString()}`,
          answer: number5
        },
        {
          sentence: dragOnRight[3]
            ? `${number3.toLocaleString()} ${MULT} ${(1000).toLocaleString()} = <ans/>`
            : `<ans/> = ${number3.toLocaleString()} ${MULT} ${(1000).toLocaleString()}`,
          answer: number6
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const answerOptions = shuffle(
      sentences.map(i => i.answer),
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        pdfSentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aU9',
  description: 'aU9',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Gattegno chart'
  ],
  schema: z.object({
    tens: z.number().int().min(10).max(90).step(10),
    ones: z.number().int().min(0).max(9),
    tenths: z.number().min(0.1).max(0.9),
    hundredths: z.number().min(0.01).max(0.09),
    multiplier: numberEnum([10, 100])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(10, 90, 10);
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(1, 9) / 10;
    const hundredths = randomIntegerInclusive(1, 9) / 100;
    const multiplier = getRandomFromArray([10, 100] as const);
    return { tens, ones, tenths, hundredths, multiplier };
  },
  Component: props => {
    const {
      question: { tens, ones, tenths, hundredths, multiplier },
      translate
    } = props;

    const total = tens + ones + tenths + hundredths;
    const answer = number(math.evaluate(`${total} * ${multiplier}`));

    return (
      <QF12CreateGattegnoChart
        correctAnswer={answer}
        rowsToShow={[3, 2, 1, 0, -1, -2]}
        title={translate.instructions.selectValuesToMakeANumberThatIsXTimeY(
          multiplier.toLocaleString(),
          total.toLocaleString()
        )}
        pdfTitle={translate.instructions.selectValuesToMakeANumberThatIsXTimeYPDF(
          multiplier.toLocaleString(),
          total.toLocaleString()
        )}
        preshaded={[tens, ones, tenths, hundredths]}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVa',
  description: 'aVa',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    answer1: z.number().int().min(1).max(9999),
    answer2: z.number().int().min(1).max(9999),
    answer3: z.number().int().min(1).max(9999)
  }),
  simpleGenerator: () => {
    const [answer1, answer2, answer3] = randomUniqueIntegersInclusive(1, 9999, 3);

    return { answer1, answer2, answer3 };
  },
  Component: props => {
    const {
      question: { answer1, answer2, answer3 },
      translate,
      displayMode
    } = props;

    const number1 = answer1 / 1000;
    const number2 = answer2 / 1000;
    const number3 = answer3 / 1000;

    return (
      <QF3Content
        title={translate.instructions.completeDiagram()}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <ArrowAnswerBoxesKeyboardWithState
            id={'trackA'}
            dimens={{ height: dimens.height, width: dimens.width }}
            boxValues={[
              [number1.toLocaleString(), `<ans/>`],
              [number2.toLocaleString(), `<ans/>`],
              [number3.toLocaleString(), `<ans/>`]
            ]}
            arrowValues={[
              [`${MULT} ${(1000).toLocaleString()}`],
              [`${MULT} ${(1000).toLocaleString()}`],
              [`${MULT} ${(1000).toLocaleString()}`]
            ]}
            testCorrect={userAnswer =>
              compareFloats(userAnswer[0][0], answer1.toString()) &&
              compareFloats(userAnswer[1][0], answer2.toString()) &&
              compareFloats(userAnswer[2][0], answer3.toString())
            }
            inputMaxCharacters={5}
            defaultState={
              displayMode === 'markscheme'
                ? [
                    [answer1.toLocaleString()],
                    [answer2.toLocaleString()],
                    [answer3.toLocaleString()]
                  ]
                : undefined
            }
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aVb',
  description: 'aVb',
  keywords: [
    'Multiply by 10',
    'Multiply by 100',
    'Multiply by 1,000',
    'Multiplication',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().min(1.1).max(99.9).step(0.1),
    number3: z.number().min(1.01).max(99.99).step(0.01),
    number4: z.number().min(1.01).max(99.99).step(0.01),
    multiplier: numberEnum([10, 100, 1000])
  }),
  questionHeight: 1050,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);
    const number2 = randomIntegerInclusive(11, 999, { constraint: x => x % 10 !== 0 }) / 10;
    const [number3, number4] = randomUniqueIntegersInclusive(101, 9999, 2, {
      constraint: x => x % 10 !== 0
    }).map(i => i / 100);
    const multiplier = getRandomFromArray([10, 100, 1000] as const);

    return { number1, number2, number3, number4, multiplier };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4, multiplier },
      translate
    } = props;

    const vars = shuffle([number1, number2, number3, number4], {
      random: seededRandom(props.question)
    });

    const answers = [
      number(math.evaluate(`${vars[0]} * 10`)),
      number(math.evaluate(`${vars[1]} * 100`)),
      number(math.evaluate(`${vars[2]} * 1000`)),
      number(math.evaluate(`${vars[3]} * ${multiplier}`))
    ];

    const sentences = [
      `${vars[0].toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
      `${vars[1].toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`,
      `${vars[2].toLocaleString()} ${MULT} ${(1000).toLocaleString()} = <ans/>`,
      `${vars[3].toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          range(0, 3).every(i => compareFloats(userAnswer[i][0], answers[i]))
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answers[0].toLocaleString()],
            [answers[1].toLocaleString()],
            [answers[2].toLocaleString()],
            [answers[3].toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={1050}
        sentences={sentences}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
