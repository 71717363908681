import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { integerToWord } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import { colors } from '../../../../theme/colors';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bam',
  description: 'bam',
  keywords: ['Match', 'Words', 'Numeral'],
  schema: z
    .object({
      nums: z.number().int().min(0).max(10).array().length(4).refine(arrayHasNoDuplicates),
      options: z.number().int().min(0).max(10).array().length(4).refine(arrayHasNoDuplicates),
      wordOrNum: z.enum(['word', 'numeral'])
    })
    .refine(
      val => arraysHaveSameContentsUnordered(val.nums, val.options),
      'options must contain the same elements as nums'
    ),
  simpleGenerator: () => {
    const nums = randomUniqueIntegersInclusive(0, 10, 4);
    const options = shuffle(nums);
    const wordOrNum = getRandomFromArray(['word', 'numeral'] as const);
    return { nums, options, wordOrNum };
  },
  Component: props => {
    const {
      question: { nums, options, wordOrNum },
      translate
    } = props;
    const statements = nums.map(number => ({
      lhsComponent: (
        <Text variant="WRN700" style={{ textAlign: 'center', width: 200 }}>
          {wordOrNum === 'numeral' ? number.toLocaleString() : integerToWord(number)}
        </Text>
      ),
      correctAnswer: number
    }));
    const items = options.map(number => ({
      component: wordOrNum === 'numeral' ? integerToWord(number) : number.toLocaleString(),
      value: number
    }));
    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheNumeralsToTheWords()}
        pdfTitle={translate.ks1PDFInstructions.matchTheNumeralsToTheWords()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ban',
  description: 'ban',
  keywords: ['Words', 'Numeral'],
  schema: z
    .object({
      options: z
        .number()
        .int()
        .min(0)
        .max(10)
        .array()
        .length(3)
        .refine(arrayHasNoDuplicates, 'options must have no duplicates'),
      numOrWord: z.enum(['number', 'word']),
      correctAnswer: z.number()
    })
    .refine(
      val => val.options.includes(val.correctAnswer),
      'options must include the correct answer'
    )
    .refine(val => arrayHasNoDuplicates(val.options), 'All values must be unique'),

  questionHeight: 1200,
  simpleGenerator: () => {
    const unshuffledOptions = randomUniqueIntegersInclusive(0, 10, 3);
    const numOrWord = getRandomFromArray(['number', 'word'] as const);
    const correctAnswer = unshuffledOptions[0];
    const options = shuffle(unshuffledOptions);
    return { options, correctAnswer, numOrWord };
  },
  Component: props => {
    const {
      question: { options, correctAnswer, numOrWord },
      translate
    } = props;

    const statements = options.map(value => {
      return {
        value: value,
        text: numOrWord === 'number' ? value.toLocaleString() : integerToWord(value)
      };
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          numOrWord === 'number'
            ? translate.ks1Instructions.whatIsTheNumberAsANumeralSelectYourAnswer()
            : translate.ks1Instructions.whatIsTheNumeralAsANumberSelectYourAnswer()
        }
        pdfTitle={
          numOrWord === 'number'
            ? translate.ks1PDFInstructions.whatIsTheNumberAsANumeralTickYourAnswer()
            : translate.ks1PDFInstructions.whatIsTheNumeralAsANumberTickYourAnswer()
        }
        testCorrect={[correctAnswer]}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              width: dimens.width,
              height: dimens.height,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <View
              style={{
                width: dimens.width / 4,
                height: dimens.height / 3,
                borderWidth: 2,
                borderColor: colors.prussianBlue,
                backgroundColor: colors.yellowContentBox
              }}
            >
              <Text
                variant="WRN700"
                style={{
                  textAlign: 'center',
                  width: dimens.width / 4,
                  height: dimens.height / 3,
                  textAlignVertical: 'center',
                  lineHeight: dimens.height / 3
                }}
              >
                {numOrWord === 'word'
                  ? correctAnswer.toLocaleString()
                  : integerToWord(correctAnswer)}
              </Text>
            </View>
          </View>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center', width: 200 }}>
              {statement.text}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bao',
  description: 'bao',
  keywords: ['Words', 'Numeral'],
  schema: z.object({
    num: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(1, 10);
    return { num };
  },
  Component: props => {
    const {
      question: { num },
      translate
    } = props;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragNumCountersOnToTheTenFrame(integerToWord(num), num)}
        pdfTitle={translate.ks1PDFInstructions.drawNumCounters(integerToWord(num), num)}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={['red']}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < num) return 'red';
        }}
        testCorrect={totalCountersIs(num)}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseNumbersAsWords',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
