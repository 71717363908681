import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  compareFractions,
  fractionArithmetic,
  fractionToDecimal,
  improperFractionToMixedNumber,
  mixedNumberToImproperFraction,
  simplify
} from '../../../../utils/fractions';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import { ADD, MULT, SUB } from '../../../../constants';
import { View } from 'react-native';
import { barModelColors } from '../../../../theme/colors';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import TextStructure from '../../../../components/molecules/TextStructure';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import { AssetSvg } from '../../../../assets/svg';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arp',
  description: 'arp',
  keywords: ['Fraction', 'Addition', 'Multiply', 'Bar model', 'Mixed number'],
  schema: z.object({
    number1: z.number().int().min(1).max(4),
    number2: z.number().int().min(2).max(5),
    number3: z.number().int().min(1).max(4)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 5);
    const number1 = number2 - 1;

    const number3 = randomIntegerInclusive(1, 4);

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate, displayMode }) => {
    const frac1 = fractionToDecimal(number1, number2);
    const frac2 = number3 + fractionToDecimal(1, number2);
    const rowTotal = 4 * frac1 + frac2;

    const numbers = [[frac1, frac1, frac1, frac1, frac2], [rowTotal]];
    const strings = [
      [
        `<frac n='${number1}' d='${number2}'/>`,
        `<frac n='${number1}' d='${number2}'/>`,
        `<frac n='${number1}' d='${number2}'/>`,
        `<frac n='${number1}' d='${number2}'/>`,
        `<frac w='${number3}' n='1' d='${number2}'/>`
      ],
      ['?']
    ];

    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic([number1 * 4, number2], [number3, 1, number2], ADD)
    );

    const ansIsInteger = correctAnswer[1] === 0;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1200}
        title={translate.instructions.workOutTheMissingTotal()}
        testCorrect={
          ansIsInteger
            ? [correctAnswer[0].toString()]
            : userAnswer => compareFractions(userAnswer, correctAnswer)
        }
        inputMaxCharacters={2}
        sentence={ansIsInteger ? '<ans/>' : `<frac wAns='' nAns='' dAns=''/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            total={rowTotal}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            sameRowColor
            rowHeight={displayMode === 'digital' ? undefined : 150}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arq',
  description: 'arq',
  keywords: ['Fraction', 'Multiply', 'Mixed number'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(1).max(4),
    number3: z.number().int().min(2).max(5),
    number4: z.number().int().min(1).max(9),
    number5: z.number().int().min(4).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { number1, number2, number3, number4, number5 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 5);

        const number3 = randomIntegerInclusive(2, 5);
        const number2 = randomIntegerInclusive(1, number3 - 1);

        const number5 = number3 * 2;
        const number4 = randomIntegerInclusive(1, number5 - 1);
        return { number1, number2, number3, number4, number5 };
      },
      ({ number1, number2, number3, number4, number5 }) => {
        // Simplify all fractions displayed
        const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
        const [simplifiedNum4, simplifiedNum5] = simplify(number4, number5);

        // Convert dimensions to improper fractions for easier calculation
        const [numeratorA, denominatorA] = mixedNumberToImproperFraction(
          number1,
          simplifiedNum2,
          simplifiedNum3
        );
        const [numeratorB, denominatorB] = mixedNumberToImproperFraction(
          number3,
          simplifiedNum4,
          simplifiedNum5
        );

        const correctAnswer = improperFractionToMixedNumber(
          ...fractionArithmetic([numeratorA * 2, denominatorA], [numeratorB * 2, denominatorB], ADD)
        );

        // Only permit if the numerator of the final answer is not zero - we must avoid integer-only answers:
        return correctAnswer[1] !== 0;
      }
    );

    return { number1, number2, number3, number4, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5 },
      translate
    } = props;

    // Simplify all fractions displayed
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum4, simplifiedNum5] = simplify(number4, number5);

    // Convert dimensions to improper fractions for easier calculation
    const [numeratorA, denominatorA] = mixedNumberToImproperFraction(
      number1,
      simplifiedNum2,
      simplifiedNum3
    );
    const [numeratorB, denominatorB] = mixedNumberToImproperFraction(
      number3,
      simplifiedNum4,
      simplifiedNum5
    );

    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic([numeratorA * 2, denominatorA], [numeratorB * 2, denominatorB], ADD)
    );

    const dimensX = fractionToDecimal(numeratorA, denominatorA);
    const dimensY = fractionToDecimal(numeratorB, denominatorB);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcPerimeterOfRectangle()}
        sentence={translate.units.stringM(`<frac wAns='' nAns='' dAns=''/>`)}
        pdfDirection="column"
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width - 400, height: dimens.height }}
            x={dimensX}
            y={dimensY}
            widthLabel={translate.units.stringM(
              `<frac w='${number1}' n='${simplifiedNum2}' d='${simplifiedNum3}'/>`
            )}
            heightLabel={translate.units.stringM(
              `<frac w= '${number3}' n='${simplifiedNum4}' d='${simplifiedNum5}'/>`
            )}
          />
        )}
        testCorrect={correctAnswer.map(it => it.toString())}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arr',
  description: 'arr',
  keywords: ['Fraction', 'Add', 'Multiply', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(3).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);

    return { denominator };
  },
  Component: props => {
    const {
      question: { denominator },
      translate,
      displayMode
    } = props;

    const numerator = denominator - 1;

    return (
      <QF3Content
        title={translate.instructions.completeCalculations()}
        inputType="numpad"
        Content={({ dimens }) => {
          return (
            <View style={[dimens, { justifyContent: 'space-around', alignItems: 'center' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <AssetSvg
                  name={displayMode === 'digital' ? 'ParenthesesLeftBlue' : 'ParenthesesLeftBlack'}
                  height={128}
                />
                <TextStructure
                  sentence={`<frac n='${numerator}' d='${denominator}'/> ${ADD} <frac n='${numerator}' d='${denominator}'/>`}
                />
                <AssetSvg
                  name={
                    displayMode === 'digital' ? 'ParenthesesRightBlue' : 'ParenthesesRightBlack'
                  }
                  height={128}
                />
                <CompleteTheSentenceWithState
                  id="sentence_1"
                  sentence={`${MULT} ${denominator.toLocaleString()} = <ans />`}
                  testCorrect={userAnswer => userAnswer[0] === (numerator * 2).toString()}
                  textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                  defaultState={
                    displayMode === 'markscheme' ? [(numerator * 2).toLocaleString()] : undefined
                  }
                />
              </View>
              <CompleteTheSentenceWithState
                id="sentence_2"
                sentence={`<frac n='${numerator}' d='${denominator}'/> ${ADD} <frac n='${numerator}' d='${denominator}'/> ${MULT} ${denominator.toLocaleString()} = <frac wAns='' nAns='' dAns=''/>`}
                testCorrect={userAnswer =>
                  compareFractions(
                    [userAnswer[0], userAnswer[1], userAnswer[2]],
                    [numerator, numerator, denominator]
                  )
                }
                textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                defaultState={
                  displayMode === 'markscheme'
                    ? [
                        numerator.toLocaleString(),
                        numerator.toLocaleString(),
                        denominator.toLocaleString()
                      ]
                    : undefined
                }
              />
            </View>
          );
        }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ars',
  description: 'ars',
  keywords: ['Fraction', 'Add', 'Divide'],
  schema: z
    .object({
      name: nameSchema,
      denominatorA: z.number().int().min(3).max(5),
      denominatorB: z.number().int().min(3).max(6),
      glasses: z.number().int().min(3).max(6)
    })
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomName();

    const denominatorA = randomIntegerInclusive(3, 5);

    const denominatorB = randomIntegerInclusive(3, 5, {
      constraint: x => x !== denominatorA
    });

    const glasses = randomIntegerInclusive(3, 5);

    return { name, denominatorA, denominatorB, glasses };
  },
  Component: props => {
    const {
      question: { name, denominatorA, denominatorB, glasses },
      translate
    } = props;

    const numeratorA = denominatorA - 1;

    const numeratorB = denominatorB - 1;

    // Required to add the fractions and calculate the answer:
    const commonDenominator = denominatorA * denominatorB;

    const factoredNumeratorA = numeratorA * denominatorB;
    const factoredNumeratorB = numeratorB * denominatorA;

    const [simplifiedAnswerNumerator, simplifiedAnswerDenominator] = simplify(
      factoredNumeratorA + factoredNumeratorB,
      commonDenominator * glasses
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.charMixesFracOfALitreOfOrangeJuice(
          name,
          `<frac n='${numeratorA}' d='${denominatorA}'/>`,
          `<frac n='${numeratorB}' d='${denominatorB}'/>`,
          glasses
        )}
        testCorrect={[simplifiedAnswerNumerator.toString(), simplifiedAnswerDenominator.toString()]}
        sentence={`<frac nAns='' dAns=''/> l`}
        textStyle={{ fontSize: 40 }}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'art',
  description: 'art',
  keywords: ['Fraction', 'Multiply', 'Area'],
  schema: z.object({
    number1: z.number().int().min(2).max(4),
    number2: z.number().int().min(1).max(4),
    number3: z.number().int().min(2).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 4);

    const number3 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusive(1, number3 - 1);

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    // Calculate area of square and divide by width of rectangle
    const [squareAreaNum, squareAreaDenom] = fractionArithmetic(
      [number2, number3],
      [number2, number3],
      MULT
    );

    const correctAnswer = [squareAreaNum, squareAreaDenom * number1];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.rectHasSameAreaAsSquareCalculateHeight()}
        sentence={`<frac nAns='' dAns=''/>m`}
        Content={({ dimens }) => {
          // Ensure shape colors are unique
          const [shapeColor1, shapeColor2] = getRandomSubArrayFromArray(
            Object.values(barModelColors),
            2,
            { random: seededRandom({ number1, number2, number3 }) }
          );

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  columnGap: 64,
                  justifyContent: 'center'
                }
              ]}
            >
              <QuadrilateralWithDimens
                dimens={{ width: dimens.width / 2 - 100, height: dimens.height }}
                x={number2 / number3}
                y={number2 / number3}
                widthLabel={`<frac n='${number2}' d='${number3}'/>m`}
                containerStyle={{ alignSelf: 'center', marginLeft: 0 }}
                shapeColor={shapeColor1}
              />
              <QuadrilateralWithDimens
                dimens={{ width: dimens.width / 2 - 100, height: dimens.height }}
                x={number1}
                y={(squareAreaNum / squareAreaDenom) * 2}
                widthLabel={`${number1}m`}
                heightLabel={'?'}
                containerStyle={{ alignSelf: 'center', marginLeft: 0, marginTop: 40 }}
                shapeColor={shapeColor2}
              />
            </View>
          );
        }}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aru',
  description: 'aru',
  keywords: ['Fraction', 'Subtract', 'Multiply', 'Mixed numbers'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(9),
    denominatorA: z.number().int().min(2).max(5),
    multiplier: z.number().int().min(2).max(5),
    numeratorB: z.number().int().min(1).max(11),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const { numeratorA, denominatorA, multiplier, numeratorB } = rejectionSample(
      () => {
        const denominatorA = randomIntegerInclusive(2, 5);

        const numeratorA = randomIntegerInclusive(1, 2 * denominatorA - 1, {
          // If denominatorA is 4, numeratorA cannot be 2 or 6; otherwise, any numbers in this range (except denominatorA) are fine.
          constraint: x => x !== denominatorA && (denominatorA === 4 ? x !== 2 && x !== 6 : true)
        });

        const multiplier = randomIntegerInclusive(2, 5, {
          constraint: x => x * denominatorA <= 12
        });

        const denominatorB = multiplier * denominatorA;

        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        return { numeratorA, denominatorA, multiplier, numeratorB };
      },
      ({ numeratorA, denominatorA, multiplier, numeratorB }) => {
        const denominatorB = multiplier * denominatorA;
        if (addOrSubtract === ADD) {
          const totalFractionAAndB = [
            numeratorA * denominatorB + numeratorB * denominatorA,
            denominatorA * denominatorB
          ];

          const totalFractionAAndBAsDecimal = fractionToDecimal(
            totalFractionAAndB[0],
            totalFractionAAndB[1]
          );

          return (
            numeratorA * multiplier + numeratorB <= 12 && totalFractionAAndBAsDecimal % 1 !== 0
          );
        } else {
          const differenceFractionAAndB = [
            numeratorA * denominatorB - numeratorB * denominatorA,
            denominatorA * denominatorB
          ];

          const differenceFractionAAndBAsDecimal = fractionToDecimal(
            differenceFractionAAndB[0],
            differenceFractionAAndB[1]
          );

          return numeratorA * multiplier > numeratorB && differenceFractionAAndBAsDecimal % 1 !== 0;
        }
      }
    );

    return { numeratorA, denominatorA, multiplier, numeratorB, addOrSubtract };
  },
  Component: props => {
    const {
      question: { numeratorA, denominatorA, multiplier, numeratorB, addOrSubtract },
      translate,
      displayMode
    } = props;

    const denominatorB = multiplier * denominatorA;

    const fractionA =
      numeratorA > denominatorA
        ? improperFractionToMixedNumber(numeratorA, denominatorA)
        : [numeratorA, denominatorA];

    const fractionB = [numeratorB, denominatorB];

    const fractionAString =
      fractionA.length === 3
        ? `<frac w='${fractionA[0]}' n='${fractionA[1]}' d='${fractionA[2]}'/>`
        : `<frac n='${fractionA[0]}' d='${fractionA[1]}'/>`;

    const fractionBString = `<frac n='${fractionB[0]}' d='${fractionB[1]}' />`;

    const [firstFractionString, secondFractionString] =
      addOrSubtract === ADD
        ? shuffle([fractionAString, fractionBString], {
            random: seededRandom(props.question)
          })
        : [fractionAString, fractionBString];

    const innerResult = fractionArithmetic(fractionA, fractionB, addOrSubtract);

    const outerResult = fractionArithmetic(innerResult, innerResult, MULT);

    const answerToUse =
      outerResult[0] > outerResult[1]
        ? improperFractionToMixedNumber(outerResult[0], outerResult[1])
        : outerResult;

    const answerSentence =
      answerToUse.length === 3 ? `<frac wAns='' nAns='' dAns=''/>` : `<frac nAns='' dAns=''/>`;

    return (
      <QF3Content
        title={translate.instructions.completeCalculation()}
        inputType="numpad"
        Content={({ dimens }) => {
          return (
            <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <AssetSvg
                  name={displayMode === 'digital' ? 'ParenthesesLeftBlue' : 'ParenthesesLeftBlack'}
                  height={128}
                />
                <TextStructure
                  sentence={`${firstFractionString} ${addOrSubtract} ${secondFractionString}`}
                />
                <AssetSvg
                  name={
                    displayMode === 'digital' ? 'ParenthesesRightBlue' : 'ParenthesesRightBlack'
                  }
                  height={128}
                />
                <TextStructure
                  textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                  sentence={'²'}
                  style={{ bottom: 48, right: 24 }}
                />
                <CompleteTheSentenceWithState
                  id="sentence"
                  sentence={`= ${answerSentence}`}
                  testCorrect={userAnswer => compareFractions(userAnswer, answerToUse)}
                  textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                  defaultState={
                    displayMode === 'markscheme'
                      ? answerToUse.map(num => num.toLocaleString())
                      : undefined
                  }
                />
              </View>
            </View>
          );
        }}
        questionHeight={900}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptEquivalentFractions() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MixedQuestionsWithFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
