import { arraysHaveSameContents } from './collections';
import { greatestCommonDivisor } from './multiples';

export function simplifyRatio(ratio: number[]): number[] {
  if (ratio.length < 2) {
    throw new Error('At least two numbers are required to simplify a ratio.');
  }

  const gcd = greatestCommonDivisor(ratio);
  return ratio.map(num => num / gcd);
}

export function compareRatios(
  userAnswer: readonly (number | string)[],
  correctAnswer: number[]
): boolean {
  const userAnswersNumbers = userAnswer.map(ans => (typeof ans === 'string' ? parseInt(ans) : ans));

  const simplifiedUser = simplifyRatio(userAnswersNumbers);
  const simplifiedCorrect = simplifyRatio(correctAnswer);

  // Compare numerator and denominator
  return arraysHaveSameContents(simplifiedCorrect, simplifiedUser);
}
