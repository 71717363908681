import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import TenFrameLayout, {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { getRandomBoolean, randomIntegerInclusive } from '../../../../utils/random';
import { filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { ADD } from '../../../../constants';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgN',
  description: 'bgN',
  keywords: ['Add', 'Ten frames', 'Counters', 'Make 10'],
  schema: z
    .object({
      numberA: z.number().int().min(2).max(9),
      numberB: z.number().int().min(2).max(9),
      colors: z.array(counterVariantSchema).length(2)
    })
    .refine(val => val.numberA + val.numberB > 10, 'Sum must be greater than 10'),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(2, 9);
    const numberB = randomIntegerInclusive(2, 9, { constraint: x => x + numberA > 10 });
    const colors = getRandomUniqueCounters(2);

    return { numberA, numberB, colors };
  },
  Component: props => {
    const {
      question: { numberA, numberB, colors },
      translate,
      displayMode
    } = props;

    const [colorA, colorB] = colors;

    const diff = 10 - numberA;

    const tenFrameA = filledArray(colorA, numberA).concat(filledArray(colorB, diff));
    const tenFrameB = filledArray(colorB, numberB - diff);

    const sentence = `${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()} = ${(10).toLocaleString()} ${ADD} <ans/> = <ans/>`;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        Content={
          <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 16 : 32 }}>
            <TenFrameLayout
              items={tenFrameA}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
            <TenFrameLayout
              items={tenFrameB}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          </View>
        }
        pdfDirection="column"
        sentence={sentence}
        testCorrect={[(numberB - diff).toString(), (numberA + numberB).toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgO',
  description: 'bgO',
  keywords: ['Add', 'Number line', 'Make 10'],
  schema: z
    .object({
      numberA: z.number().int().min(3).max(9),
      numberB: z.number().int().min(2).max(9)
    })
    .refine(val => val.numberA + val.numberB > 10, 'Sum must be greater than 10'),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(3, 9);
    const numberB = randomIntegerInclusive(2, 9, {
      // Ensure that there is enough space for an answer box on the second jump
      constraint: x => x + numberA >= 11
    });

    return { numberA, numberB };
  },
  Component: props => {
    const {
      question: { numberA, numberB },
      translate
    } = props;

    const diff = 10 - numberA;

    const answerA = numberB - diff;
    const answerB = numberA + numberB;

    const startNumber = numberA;
    const middleTick = 10;
    const endNumber = answerB;

    const tickArray = [
      {
        label: startNumber.toLocaleString(),
        position: startNumber
      },
      { label: middleTick.toLocaleString(), position: middleTick },
      { label: '<ans/>', position: endNumber }
    ];

    const jumpArrowArray = [
      { start: startNumber, end: middleTick, label: `${ADD} ${diff.toLocaleString()}` },
      { start: middleTick, end: endNumber, label: `${ADD}<ans/>` }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startNumber}
        end={endNumber}
        title={translate.ks1Instructions.completeTheNumberLineToWorkOutEqX(
          `${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()}`
        )}
        testCorrect={[answerA.toString(), answerB.toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bgP',
  description: 'bgP',
  keywords: ['Add', 'Ones'],
  schema: z
    .object({
      numberA: z.number().int().min(2).max(9),
      numberB: z.number().int().min(2).max(9),
      reversed: z.boolean()
    })
    .refine(val => val.numberA + val.numberB > 10, 'Sum must be greater than 10'),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(2, 9);
    const numberB = randomIntegerInclusive(2, 9, { constraint: x => x + numberA > 10 });
    const reversed = getRandomBoolean();

    return {
      numberA,
      numberB,
      reversed
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, reversed },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      [numberA, numberB, numberA + numberB],
      ADD,
      2,
      { reversed }
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheCalculation()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddByMaking10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
