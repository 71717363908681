import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { ADD, DIV, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { isInRange } from '../../../../utils/matchers';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bi8',
  description: 'bi8',
  keywords: ['Double', 'Halve'],
  schema: z
    .object({
      number: z.number().int().min(0).max(20),
      isDouble: z.boolean(),
      answerIndex: z.number().int().min(0).max(1)
    })
    .refine(
      val =>
        (val.isDouble && isInRange(0, 10)(val.number)) ||
        (!val.isDouble && isInRange(2, 20)(val.number) && val.number % 2 === 0)
    ),
  simpleGenerator: () => {
    const isDouble = getRandomBoolean();
    const number = isDouble ? randomIntegerInclusive(0, 10) : randomIntegerInclusiveStep(2, 20, 2);
    const answerIndex = randomIntegerInclusive(0, 1);

    return {
      isDouble,
      number,
      answerIndex
    };
  },
  Component: ({ question, translate }) => {
    const { isDouble, number, answerIndex } = question;

    const [sentences, answers] = (() => {
      if (isDouble) {
        return [
          [
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/> ${ADD} <ans/>`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${MULT} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/>`
          ],
          [
            [number.toString(), number.toString()],
            [(answerIndex === 0 ? number : 2).toString()],
            [(number * 2).toString()]
          ]
        ];
      } else {
        return [
          [
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${DIV} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} <ans/>`
          ],
          [[(answerIndex === 0 ? number : 2).toString()], [(number / 2).toString()]]
        ];
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={sentences}
        testCorrect={answers}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bi9',
  description: 'bi9',
  keywords: ['Double', 'Halve'],
  schema: z.object({
    number: z.number().int().min(1).max(12),
    variation: z.enum(['doubleEqualAns', 'doubleAns', 'halfEqualAns', 'halfAns']),
    options: z.number().int().max(100).array().length(4)
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArrayWithWeights(
      ['doubleEqualAns', 'doubleAns', 'halfEqualAns', 'halfAns'] as const,
      [4, 1, 4, 1]
    );

    const [number, options] = (() => {
      switch (variation) {
        case 'doubleEqualAns': {
          const num = randomIntegerInclusive(1, 5);
          const other = num === 2 ? 3 : num + 2;
          return [num, [num * 2, num * 20, other, other * 10]];
        }
        case 'doubleAns': {
          const num = randomIntegerInclusiveStep(2, 4, 2);
          return [num, [num / 2, num * 5, num * 2, num * 20]];
        }
        case 'halfEqualAns': {
          const num = randomIntegerInclusiveStep(2, 10, 2);
          const other = num === 4 ? 3 : num - 2;
          return [num, [num / 2, num * 5, other, other * 10]];
        }
        case 'halfAns': {
          const num = randomIntegerInclusive(1, 5);
          const other = num <= 2 ? num - 1 : num - 2;
          return [num, [num * 2, num * 20, other, other * 10]];
        }
      }
    })();

    return {
      number,
      variation,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { number, variation, options },
      translate
    } = props;

    const [sentences, answers] = (() => {
      switch (variation) {
        case 'doubleEqualAns':
          return [
            [
              translate.ks1AnswerSentences.doubleXEqualsY(number, '<ans/>'),
              translate.ks1AnswerSentences.soDoubleXEqualsY(
                (number * 10).toLocaleString(),
                '<ans/>'
              )
            ],
            [[number * 2], [number * 20]]
          ];
        case 'doubleAns':
          return [
            [
              translate.ks1AnswerSentences.doubleXEqualsY('<ans/>', number),
              translate.ks1AnswerSentences.soDoubleXEqualsY(
                '<ans/>',
                (number * 10).toLocaleString()
              )
            ],
            [[number / 2], [number * 5]]
          ];
        case 'halfEqualAns':
          return [
            [
              translate.ks1AnswerSentences.halfXEqualsY(number, '<ans/>'),
              translate.ks1AnswerSentences.soHalfXEqualsY((number * 10).toLocaleString(), '<ans/>')
            ],
            [[number / 2], [number * 5]]
          ];
        case 'halfAns':
          return [
            [
              translate.ks1AnswerSentences.halfXEqualsY('<ans/>', number),
              translate.ks1AnswerSentences.soHalfXEqualsY('<ans/>', (number * 10).toLocaleString())
            ],
            [[number * 2], [number * 20]]
          ];
      }
    })();

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentences()}
        sentences={sentences}
        testCorrect={answers}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        items={options.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const SmallStep = newSmallStepContent({
  smallStep: 'DoublingAndHalving',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
