import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { DIRECTIONS, Direction, Point2d } from '../../../../utils/vectors';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAY',
  description: 'aAY',
  keywords: ['Translation', 'Square', 'Grid'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(9),
      startY: z.number().int().min(0).max(4),
      translateX: z.number().int(),
      translateY: z.number().int()
    })
    .refine(
      ({ translateX, translateY }) => (translateX === 0) !== (translateY === 0),
      'Exactly one of translateX and translateY must be nonZero '
    )
    .refine(({ startX, startY, translateX, translateY }) => {
      const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
      return 0 <= newPoint.x && newPoint.x <= 9 && 0 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid (9x4)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const startX = randomIntegerInclusive(0, 9);
        const startY = randomIntegerInclusive(0, 4);

        const direction = getRandomFromArray(DIRECTIONS);
        const [translateX, translateY] = (() => {
          switch (direction) {
            case 'up':
              return [0, randomIntegerInclusive(1, 4)];
            case 'down':
              return [0, randomIntegerInclusive(-4, -1)];
            case 'left':
              return [randomIntegerInclusive(-9, -1), 0];
            case 'right':
              return [randomIntegerInclusive(1, 9), 0];
          }
        })();
        return { startX, startY, translateX, translateY };
      },
      ({ startX, startY, translateX, translateY }) => {
        const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
        return 0 <= newPoint.x && newPoint.x <= 9 && 0 <= newPoint.y && newPoint.y <= 4;
      }
    ),

  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title =
      direction === 'left' || direction === 'right'
        ? translate.instructions.dragShapeToTranslateXToTheDx({
            x: distance,
            dx: translate.directions[direction]()
          })
        : translate.instructions.dragShapeToTranslateYDy({
            y: distance,
            dy: translate.directions[direction]()
          });

    const pdfTitle =
      direction === 'left' || direction === 'right'
        ? translate.instructions.dragShapeToTranslateXToTheDxPDF({
            x: distance,
            dx: translate.directions[direction]()
          })
        : translate.instructions.dragShapeToTranslateYDyPDF({
            y: distance,
            dy: translate.directions[direction]()
          });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="square"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAZ',
  description: 'aAZ',
  keywords: ['Translation', 'Square', 'Grid'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(9),
      startY: z.number().int().min(0).max(4),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ startX, startY, translateX, translateY }) => {
      const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
      return 0 <= newPoint.x && newPoint.x <= 9 && 0 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of the grid (9x4)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const startX = randomIntegerInclusive(0, 9);
        const startY = randomIntegerInclusive(0, 4);

        const translateX = randomIntegerInclusive(-9, 9, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-4, 4, { constraint: x => x !== 0 });

        return { startX, startY, translateX, translateY };
      },
      ({ startX, startY, translateX, translateY }) => {
        const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
        return 0 <= newPoint.x && newPoint.x <= 9 && 0 <= newPoint.y && newPoint.y <= 4;
      }
    ),

  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.dragShapeXSquaresDXAndYSquaresDY({
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });
    const pdfTitle = translate.instructions.dragShapeXSquaresDXAndYSquaresDYPDF({
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="square"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aA0',
  description: 'aA0',
  keywords: ['Translation', 'Grid'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(9),
      startY: z.number().int().min(1).max(4),
      translateX: z.number().int(),
      translateY: z.number().int()
    })
    .refine(
      ({ translateX, translateY }) => (translateX === 0) !== (translateY === 0),
      'Exactly one of translateX and translateY must be nonZero '
    )
    .refine(({ startX, startY, translateX, translateY }) => {
      const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
      return 1 <= newPoint.x && newPoint.x <= 9 && 1 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of or on the edges of the grid (10x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const startX = randomIntegerInclusive(1, 9);
        const startY = randomIntegerInclusive(1, 4);

        const direction = getRandomFromArray(DIRECTIONS);
        const [translateX, translateY] = (() => {
          switch (direction) {
            case 'up':
              return [0, randomIntegerInclusive(1, 3)];
            case 'down':
              return [0, randomIntegerInclusive(-3, -1)];
            case 'left':
              return [randomIntegerInclusive(-8, -1), 0];
            case 'right':
              return [randomIntegerInclusive(1, 8), 0];
          }
        })();
        return { startX, startY, translateX, translateY };
      },
      ({ startX, startY, translateX, translateY }) => {
        const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
        return 1 <= newPoint.x && newPoint.x <= 9 && 1 <= newPoint.y && newPoint.y <= 4;
      }
    ),

  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();
    const title =
      direction === 'left' || direction === 'right'
        ? translate.instructions.translateShapeXSquaresDX({
            shape: translate.shapes.points(1),
            x: distance,
            dx: translate.directions[direction]()
          })
        : translate.instructions.translateShapeXSquaresDY({
            shape: translate.shapes.points(1),
            x: distance,
            dy: translate.directions[direction]()
          });

    const pdfTitle =
      direction === 'left' || direction === 'right'
        ? translate.instructions.translateShapeXSquaresDXPDF({
            shape: translate.shapes.points(1),
            x: distance,
            dx: translate.directions[direction]()
          })
        : translate.instructions.translateShapeXSquaresDYPDF({
            shape: translate.shapes.points(1),
            x: distance,
            dy: translate.directions[direction]()
          });

    return (
      <QF48TranslateSvg title={title} pdfTitle={pdfTitle} start={[start]} end={[end]} svg="dot" />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aA1',
  description: 'aA1',
  keywords: ['Translation', 'Grid'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(9),
      startY: z.number().int().min(1).max(4),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ startX, startY, translateX, translateY }) => {
      const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
      return 1 <= newPoint.x && newPoint.x <= 9 && 1 <= newPoint.y && newPoint.y <= 4;
    }, 'translation must not place the point out of or on the edges of the grid (10x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const startX = randomIntegerInclusive(1, 9);
        const startY = randomIntegerInclusive(1, 4);

        const translateX = randomIntegerInclusive(-8, 8, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });

        return { startX, startY, translateX, translateY };
      },
      ({ startX, startY, translateX, translateY }) => {
        const newPoint = new Point2d(startX, startY).add(new Point2d(translateX, translateY));
        return 1 <= newPoint.x && newPoint.x <= 9 && 1 <= newPoint.y && newPoint.y <= 4;
      }
    ),

  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.translateShapeXSquaresDXAndYSquaresDY({
      shape: translate.shapes.points(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });
    const pdfTitle = translate.instructions.translateShapeXSquaresDXAndYSquaresDYPDF({
      shape: translate.shapes.points(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });
    return (
      <QF48TranslateSvg title={title} pdfTitle={pdfTitle} start={[start]} end={[end]} svg="dot" />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aA2',
  description: 'aA2',
  keywords: ['Translation', 'Square', 'Grid'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-4).max(4),
      translateY: z.number().int().min(-4).max(4)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 5,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 5,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 4);
    const startY = randomIntegerInclusive(0, 4);
    const translateX = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 5
    });
    const translateY = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 5
    });
    return { startX, startY, translateX, translateY };
  },
  questionHeight: 1200,
  Component: ({
    question: { startX, startY, translateX, translateY },
    translate,
    displayMode,
    theme
  }) => {
    // PointB Coordinates
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.shapeAHasBeenTranslatedAnsDxAndAnsDy(
          translate.keywords.Square(),
          distanceX,
          translate.directions[directionX](),
          distanceY,
          translate.directions[directionY]()
        )}
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        pdfDirection="column"
        questionHeight={1200}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={5}
            yMax={5}
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
            squareGrid
            children={({ mathToSvgX }) =>
              [[startX, startY] as [number, number], [end.x, end.y] as [number, number]].map(
                (coord, i) => {
                  return (
                    <GridImage
                      key={i}
                      mathCoord={coord}
                      item={{
                        component: 'SquareCustomizable',
                        svgProps: {
                          fill:
                            displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                        },
                        width: mathToSvgX(1) - mathToSvgX(0),
                        height: mathToSvgX(1) - mathToSvgX(0)
                      }}
                      anchorDX={0}
                      anchorDY={mathToSvgX(1) - mathToSvgX(0)}
                      label={{
                        text: [translate.letters.A(), translate.letters.B()][i],
                        styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
                      }}
                    />
                  );
                }
              )
            }
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aA3',
  description: 'aA3',
  keywords: ['Translation', 'Square', 'Grid'],
  questionHeight: 1200,
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-4).max(4),
      translateY: z.number().int().min(-4).max(4)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);
    const translateX = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-4, 4, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    return { startX, startY, translateX, translateY };
  },
  Component: ({ question: { startX, startY, translateX, translateY }, translate, displayMode }) => {
    // PointB Coordinates
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    // Direction/Distance Y
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.shapeAHasBeenTranslatedAnsDxAndAnsDy(
          translate.keywords.Point(),
          distanceX,
          translate.directions[directionX](),
          distanceY,
          translate.directions[directionY]()
        )}
        questionHeight={1200}
        pdfDirection="column"
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        Content={({ dimens: { width, height } }) => (
          <Grid
            width={width}
            height={height}
            xMax={5}
            yMax={5}
            squareGrid
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
          >
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
              label={{
                text: translate.letters.A(),
                styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
              }}
            />
            <GridImage
              mathCoord={[end.x, end.y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: colors.pacificBlue
                },
                width: displayMode !== 'digital' ? 70 : undefined,
                height: displayMode !== 'digital' ? 70 : undefined
              }}
              label={{
                text: translate.letters.B(),
                styles: { fontSize: displayMode === 'digital' ? 24 : 50 }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Translation',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
