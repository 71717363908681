import { z } from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { all, create, number } from 'mathjs';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUO',
  description: 'aUO',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Place value chart'
  ],
  schema: z
    .object({
      ones: z.number().int().min(1).max(9),
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val => val.ones + val.tenths + val.hundredths + val.thousandths > 1,
      'number should be greater than or equal to 1.001'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const ones = randomIntegerInclusive(1, 9);
        const tenths = randomIntegerInclusive(0, 9);
        const hundredths = randomIntegerInclusive(0, 9);
        const thousandths = randomIntegerInclusive(0, 9);
        return { ones, tenths, hundredths, thousandths };
      },
      val => val.ones + val.tenths + val.hundredths + val.thousandths > 1
    ),
  Component: ({ question: { ones, tenths, hundredths, thousandths }, translate }) => {
    const answer = number(
      math.evaluate(`${ones} + ${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`)
    );
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(answer)}
            columnsToShow={[0, -1, -2, -3]}
            counterVariant={'greyCounter'}
            dimens={dimens}
          />
        )}
        sentence={translate.answerSentences.theNumberIsAns()}
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        questionHeight={1050}
      />
    );
  },
  questionHeight: 1050
});

const Question2 = newQuestionContent({
  uid: 'aUP',
  description: 'aUP',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Place value chart'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(99),
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val => val.whole + val.tenths + val.hundredths + val.thousandths > 1,
      'number should be greater than 1.001'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const whole = randomIntegerInclusive(1, 99);
        const tenths = randomIntegerInclusive(0, 9);
        const hundredths = randomIntegerInclusive(0, 9);
        const thousandths = randomIntegerInclusive(0, 9);
        return { whole, tenths, hundredths, thousandths };
      },
      val => val.whole + val.tenths + val.hundredths + val.thousandths > 1
    ),
  Component: props => {
    const {
      question: { whole, tenths, hundredths, thousandths },
      translate
    } = props;

    const answer = number(
      math.evaluate(`${whole} + ${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`)
    );

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragCountersToMakeNumOnPVC(answer.toLocaleString())}
        pdfTitle={translate.instructions.drawCountersToMakeNumOnPVC(answer.toLocaleString())}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[1, 0, -1, -2, -3]}
        counterVariant="greyCounter"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aUQ',
  description: 'aUQ',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Hundredths', 'Thousandths', 'Place value', 'Digit'],
  schema: z.object({
    tens: z.number().int().min(1).max(9),
    ones: z.number().int().min(1).max(9),
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9),
    thousandths: z.number().int().min(1).max(9),
    answerPower: z.number().int().min(-3).max(1)
  }),
  simpleGenerator: () => {
    const [tens, ones, tenths, hundredths, thousandths] = randomUniqueIntegersInclusive(1, 9, 5);
    const answerPower = randomIntegerInclusive(-3, 1);

    return { tens, ones, tenths, hundredths, thousandths, answerPower };
  },

  Component: props => {
    const {
      question: { tens, ones, tenths, hundredths, thousandths, answerPower },
      translate
    } = props;
    const random = seededRandom(props.question);

    const total = number(
      math.evaluate(
        `${tens}* 10 + ${ones} + ${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`
      )
    );
    const n = [thousandths, hundredths, tenths, ones, tens][answerPower + 3];

    const allOptions = [
      `${n.toLocaleString()} ${translate.fractions.thousandths(n)}`,
      `${n.toLocaleString()} ${translate.fractions.hundredths(n)}`,
      `${n.toLocaleString()} ${translate.fractions.tenths(n)}`,
      `${n.toLocaleString()} ${translate.powersOfTen.ones(n)}`,
      `${n.toLocaleString()} ${translate.powersOfTen.tens(n)}`
    ];

    const correctAnswer = allOptions[answerPower + 3];
    allOptions.splice(answerPower + 3, 2);

    const items = shuffle(
      [correctAnswer, ...getRandomSubArrayFromArray(allOptions, 3, { random })],
      {
        random
      }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectValueOfXinY(n.toLocaleString(), total.toLocaleString())}
        pdfTitle={translate.instructions.circleValueOfXinY(
          n.toLocaleString(),
          total.toLocaleString()
        )}
        testCorrect={[items.indexOf(correctAnswer)]}
        items={items.map((item, index) => ({ value: index, component: item }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUR',
  description: 'aUR',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Parts',
    'Whole',
    'Part-whole model'
  ],
  questionHeight: 1200,
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9),
    thousandths: z.number().int().min(1).max(9),
    part: z.enum(['partA', 'partB', 'partC', 'partD', 'whole']),
    variation: z.enum(['topDown', 'bottomUp'])
  }),
  simpleGenerator: () => {
    const part = getRandomFromArray(['partA', 'partB', 'partC', 'partD', 'whole'] as const);
    const ones = randomIntegerInclusive(1, 9);
    const tenths = randomIntegerInclusive(1, 9);
    const hundredths = randomIntegerInclusive(1, 9);
    const thousandths = randomIntegerInclusive(1, 9);
    const variation = getRandomFromArray(['topDown', 'bottomUp'] as const);

    return { ones, tenths, hundredths, thousandths, part, variation };
  },
  Component: ({ question, translate, displayMode }) => {
    const { ones, tenths, hundredths, thousandths, part, variation } = question;

    const total = number(
      math.evaluate(`${ones} + ${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`)
    );

    let partition: TextPartition = [];

    if (part === 'partA') {
      partition = ['$ans', tenths / 10, hundredths / 100, thousandths / 1000];
    } else if (part === 'partB') {
      partition = [ones, '$ans', hundredths / 100, thousandths / 1000];
    } else if (part === 'partC') {
      partition = [ones, tenths / 10, '$ans', thousandths / 1000];
    } else if (part === 'partD') {
      partition = [ones, tenths / 10, hundredths / 100, '$ans'];
    } else partition = [ones, tenths / 10, hundredths / 100, thousandths / 1000];

    const answer =
      part === 'partA'
        ? ones
        : part === 'partB'
        ? tenths / 10
        : part === 'partC'
        ? hundredths / 100
        : part === 'partD'
        ? thousandths / 1000
        : total;

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [answer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        inputType="numpad"
        extraSymbol="decimalPoint"
        questionHeight={1200}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={part === 'whole' ? '$ans' : total}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUS',
  description: 'aUS',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Addition'
  ],
  questionHeight: 900,
  schema: z.object({
    numbers1: z.array(z.number().int().min(10).max(90).step(10)).length(3),
    numbers2: z.array(z.number().int().min(1).max(9)).length(3),
    numbers3: z.array(z.number().int().min(1).max(9)).length(3),
    numbers4: z.array(z.number().int().min(1).max(9)).length(3),
    numbers5: z.array(z.number().int().min(1).max(9)).length(3),
    answerBoxIndex: z.number().int().min(0).max(4)
  }),
  simpleGenerator: () => {
    // make unqiue to ensure we always have different sums. Still have a lot of variation
    const numbers1 = randomUniqueIntegersInclusiveStep(10, 90, 10, 3);
    const numbers2 = randomUniqueIntegersInclusive(1, 9, 3);
    const numbers3 = randomUniqueIntegersInclusive(1, 9, 3);
    const numbers4 = randomUniqueIntegersInclusive(1, 9, 3);
    const numbers5 = randomUniqueIntegersInclusive(1, 9, 3);

    const answerBoxIndex = randomIntegerInclusive(0, 4);

    return { numbers1, numbers2, numbers3, numbers4, numbers5, answerBoxIndex };
  },
  Component: props => {
    const {
      question: { numbers1, numbers2, numbers3, numbers4, numbers5, answerBoxIndex },
      translate
    } = props;

    const sentenceValues: (string | number)[][] = range(0, 2).map(i => [
      numbers1[i],
      numbers2[i],
      numbers3[i] / 10,
      numbers4[i] / 100,
      numbers5[i] / 1000
    ]);

    //correct answers
    const correctAnswer1 = number(
      math.evaluate(
        `${sentenceValues[0][0]} + ${sentenceValues[0][1]} + ${sentenceValues[0][2]} + ${sentenceValues[0][3]} + ${sentenceValues[0][4]}`
      )
    );
    const correctAnswer2 = sentenceValues[1][answerBoxIndex];
    const correctAnswer3 = number(
      math.evaluate(
        `${sentenceValues[2][0]} + ${sentenceValues[2][1]} + ${sentenceValues[2][2]} + ${sentenceValues[2][3]} + ${sentenceValues[2][4]}`
      )
    );
    const number6 = number(
      math.evaluate(
        `${sentenceValues[1][0]} + ${sentenceValues[1][1]} + ${sentenceValues[1][2]} + ${sentenceValues[1][3]} + ${sentenceValues[1][4]}`
      )
    );

    // set answer boxes
    sentenceValues[0][5] = `<ans/>`;
    sentenceValues[1][answerBoxIndex] = `<ans/>`;
    sentenceValues[1][5] = number6.toLocaleString();
    sentenceValues[2] = shuffle(sentenceValues[2], { random: seededRandom(props.question) });
    sentenceValues[2][5] = `<ans/>`;

    const sentences = sentenceValues.map(
      value =>
        `${value[0].toLocaleString()} ${ADD} ${value[1].toLocaleString()} ${ADD} ${value[2].toLocaleString()} ${ADD} ${value[3].toLocaleString()} ${ADD} ${value[4].toLocaleString()} = ${value[5].toLocaleString()}`
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], correctAnswer1.toString()) &&
          compareFloats(userAnswer[1][0], correctAnswer2.toString()) &&
          compareFloats(userAnswer[2][0], correctAnswer3.toString())
        }
        extraSymbol="decimalPoint"
        inputMaxCharacters={6}
        sentences={sentences}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: 32 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [correctAnswer1.toLocaleString()],
            [correctAnswer2.toLocaleString()],
            [correctAnswer3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUT',
  description: 'aUT',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Flexible partition',
    'Addition'
  ],
  schema: z
    .object({
      number1: z.number().int().min(1).max(3),
      number2: z.number().min(0.2).max(0.9),
      number3: z.number().min(0.02).max(0.09),
      number4: z.number().min(0.002).max(0.009),
      answer1: z.number().int().min(1).max(3),
      answer2: z.number().min(0.1).max(2.9),
      answer3: z.number().min(0.01).max(0.89),
      answer4: z.number().min(0.001).max(0.089),
      otherOptions: z.array(z.number().min(0.01).max(3.9)).length(2)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.answer1,
          val.answer2,
          val.answer3,
          val.answer4,
          val.otherOptions[0],
          val.otherOptions[1]
        ]),
      'no duplicate answers'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 3);
    const number2Whole = randomIntegerInclusive(2, 9);
    const number2 = number2Whole / 10;
    const number3Whole = randomIntegerInclusive(2, 9);
    const number3 = number3Whole / 100;
    const number4 = randomIntegerInclusive(2, 9) / 1000;

    const answer1 = randomIntegerInclusive(1, number1);
    const answer2Tenths = randomIntegerInclusive(1, number2Whole);
    const answer2 = number(math.evaluate(`${answer2Tenths / 10} + ${number1} - ${answer1}`));
    const answer3Hundredths = randomIntegerInclusive(1, number3Whole) / 100;
    const answer3 = number(
      math.evaluate(`${answer3Hundredths} + (${number2Whole} - ${answer2Tenths}) / 10`)
    );
    const answer4 = number(
      math.evaluate(
        `${number1} + ${number2} + ${number3} + ${number4} - ${answer1} - ${answer2} - ${answer3}`
      )
    );

    const [option1, option2] = randomUniqueIntegersInclusive(1, number1 + number2Whole, 2, {
      constraint: x => ![answer1, answer2, answer3, answer4].includes(x / 10)
    });
    const [option3, option4] = randomUniqueIntegersInclusive(1, number2Whole + number3Whole, 2, {
      constraint: x =>
        ![answer1, answer2, answer3, answer4].includes(x / 100) &&
        ![option1, option2].includes(x / 10)
    });

    const otherOptions = getRandomSubArrayFromArray(
      [option1 / 10, option2 / 10, option3 / 100, option4 / 100],
      2
    );

    return { number1, number2, number3, number4, answer1, answer2, answer3, answer4, otherOptions };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        answer1,
        answer2,
        answer3,
        answer4,
        otherOptions
      },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} + ${number2} + ${number3} + ${number4}`));
    const answerOptions = shuffle(
      [
        answer1.toLocaleString(),
        answer2.toLocaleString(),
        answer3.toLocaleString(),
        answer4.toLocaleString(),
        otherOptions[0].toLocaleString(),
        otherOptions[1].toLocaleString()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsFlexiblyPartitionX(total.toLocaleString())}
        pdfTitle={translate.instructions.useCardsFlexiblyPartitionX(total.toLocaleString())}
        items={answerOptions}
        sentence={`${total.toLocaleString()} = <ans/> ${ADD} <ans/> ${ADD} <ans/> ${ADD} <ans/>`}
        testCorrect={userAnswer => {
          const sum = userAnswer.map(i => Number(i)).reduce((a, b) => a + b);
          return compareFloats(sum, total);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidPartitionWithAvailCards()
        }}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PlaceValueIntegersAndDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
