import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';
import { findFactorPairs, isSquare } from '../../../../utils/factors';
import { numberEnum } from '../../../../utils/zod';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  trueCount,
  isRectilinear,
  isRectangle,
  isSquareShape,
  createShapeWithSquares,
  createRectangleFromSquares,
  insertShapeIntoArray
} from '../../../../utils/shapes';
import { isPrime } from '../../../../utils/primes';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { filledArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afu',
  description: 'afu',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    cells: numberEnum([7, 11, 13, 17, 19, 23])
  }),
  simpleGenerator: () => {
    const cells = getRandomFromArray([7, 11, 13, 17, 19, 23] as const);

    return { cells };
  },
  Component: ({ question: { cells }, translate }) => {
    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.createShapeAreaNumSquares(cells)}
        numberOfRows={5}
        numberOfCols={8}
        testCorrect={userAnswer => trueCount(userAnswer) === cells && isRectilinear(userAnswer)}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithAreaX(cells)
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'afv',
  description: 'afv',
  keywords: ['Area', 'Integer'],
  schema: z
    .object({
      correctShapeArea: z.number().int().min(5).max(11),
      incorrectShapeArea: z.number().int().min(5).max(11)
    })
    .refine(
      val => val.correctShapeArea !== val.incorrectShapeArea,
      'correctShapeArea cannot be the same as incorrectShapeArea.'
    ),
  simpleGenerator: () => {
    const [correctShapeArea, incorrectShapeArea] = randomUniqueIntegersInclusive(5, 11, 2);

    return { correctShapeArea, incorrectShapeArea };
  },
  Component: props => {
    const {
      question: { correctShapeArea, incorrectShapeArea },
      translate
    } = props;

    // Randomly order these shapes
    const shapes = useMemo(() => {
      const random = seededRandom(props.question);

      const shapeA = {
        shape: createShapeWithSquares(4, 4, correctShapeArea, true, { random }),
        isCorrect: true
      };

      const shapeB = {
        shape: createShapeWithSquares(4, 4, incorrectShapeArea, true, { random }),
        isCorrect: false
      };

      return shuffle([shapeA, shapeB], { random });
    }, [correctShapeArea, incorrectShapeArea, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectRectilinearShapeWithAreaOfNumSquares(correctShapeArea)}
        pdfTitle={translate.instructions.circleRectilinearShapeWithAreaOfNumSquares(
          correctShapeArea
        )}
        testCorrect={shapes.filter(shape => shape.isCorrect).map(shape => shape.shape)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: <DisplayShapeOnGrid givenShape={shape.shape} dimens={dimens} />
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afw',
  description: 'afw',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    cells: z
      .number()
      .int()
      .min(8)
      .max(24)
      .refine(val => !isPrime(val), 'The number of cells must not be a prime number.')
      .refine(val => !isSquare(val), 'The number of cells must not be a square number.')
      .refine(val => val !== 22, 'The number of cells cannot be 22')
  }),
  simpleGenerator: () => {
    const cells = randomIntegerInclusive(8, 24, {
      constraint: x => !isPrime(x) && !isSquare(x) && x !== 22
    });

    return { cells };
  },
  Component: props => {
    const {
      question: { cells },
      translate
    } = props;

    const height = randomIntegerInclusive(2, 5, {
      constraint: x => cells % x === 0 && Math.floor(cells / x) <= 8,
      random: seededRandom(props.question)
    });

    const exampleRectangle = useMemo(
      () => createRectangleFromSquares(cells / height, height),
      [cells, height]
    );

    const exampleAnswer = insertShapeIntoArray(
      filledArray(filledArray(false, 8), 5),
      exampleRectangle
    );

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.createRectangleAreaNumSquares(cells)}
        numberOfRows={5}
        numberOfCols={8}
        testCorrect={userAnswer => trueCount(userAnswer) === cells && isRectangle(userAnswer)}
        customMarkSchemeAnswer={{
          answerToDisplay: exampleAnswer,
          answerText: translate.markScheme.anyRectangleWithAreaOfNumSquares(cells)
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'afx',
  description: 'afx',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    cells: numberEnum([4, 9, 16, 25])
  }),
  simpleGenerator: () => {
    const cells = getRandomFromArray([4, 9, 16, 25] as const);

    return { cells };
  },
  Component: ({ question: { cells }, translate }) => {
    const squareDimens = Math.sqrt(cells);

    const exampleSquare = useMemo(
      () => createRectangleFromSquares(squareDimens, squareDimens),
      [squareDimens]
    );
    const exampleAnswer = insertShapeIntoArray(
      filledArray(filledArray(false, 8), 5),
      exampleSquare
    );

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.createSquareAreaNumSquares(cells)}
        numberOfRows={5}
        numberOfCols={8}
        testCorrect={userAnswer => trueCount(userAnswer) === cells && isSquareShape(userAnswer)}
        customMarkSchemeAnswer={{
          answerToDisplay: exampleAnswer,
          answerText: translate.markScheme.anySquareWithAreaOfX(cells.toLocaleString())
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'afy',
  description: 'afy',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    cells: z.number().int().min(4).max(16)
  }),
  simpleGenerator: () => {
    const chooseSquare = getRandomBoolean();
    const cells = randomIntegerInclusive(4, 16, {
      constraint: x => chooseSquare === isSquare(x)
    });

    return { cells };
  },
  Component: ({ question: { cells }, translate }) => {
    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalseASquareCanBeMadeUsingNumSquares(cells)}
        pdfTitle={translate.instructions.isStatementTrueOrFalseASquareCanBeMadeUsingNumSquaresPDF(
          cells
        )}
        correctAnswer={isSquare(cells)}
        content={({ dimens }) => (
          <CreateShapeFromSquaresWithState
            id="createshape"
            dimens={dimens}
            numberOfCols={8}
            numberOfRows={4}
            // Do not want the grid to need to be interacted with to complete the question:
            testComplete={() => true}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'afz',
  description: 'afz',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    squares: z.number().int().min(8).max(24)
  }),
  simpleGenerator: () => {
    const squares = randomIntegerInclusive(8, 24, {
      constraint: x => !isSquare(x) && x !== 22
    });
    return { squares };
  },
  Component: ({ question: { squares }, translate }) => {
    const gridWidth = 8;
    const gridHeight = 4;
    const canBeRectangle = findFactorPairs(squares).some(
      ([a, b]) => (a <= gridWidth && b <= gridHeight) || (a <= gridHeight && b <= gridWidth)
    );

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalseARectangleCanBeMadeUsingNumSquares(
          squares
        )}
        pdfTitle={translate.instructions.isStatementTrueOrFalseARectangleCanBeMadeUsingNumSquaresPDF(
          squares
        )}
        correctAnswer={canBeRectangle}
        content={({ dimens }) => (
          <CreateShapeFromSquaresWithState
            id="createshape"
            dimens={dimens}
            numberOfCols={8}
            numberOfRows={4}
            // Do not want the grid to need to be interacted with to complete the question:
            testComplete={() => true}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
