import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom
} from 'common/src/utils/random';
import { ADD } from 'common/src/constants';
import { arraysHaveSameContents, filledArray } from 'common/src/utils/collections';
import { barModelColors } from 'common/src/theme/colors';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  compareFractions,
  fractionArithmetic,
  improperFractionToMixedNumber,
  simplify
} from 'common/src/utils/fractions';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from 'common/src/utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aM4',
  description: 'aM4',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(8),
      numeratorPartA: z.number().int().min(1).max(5),
      numeratorPartB: z.number().int().min(1).max(7)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB < val.denominator,
      `numeratorPartA ${ADD} numeratorPartB must be less than denominator`
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 8);
    const numeratorPartA = randomIntegerInclusive(1, denominator - 3);
    const numeratorPartB = randomIntegerInclusive(1, denominator - 1, {
      constraint: x => numeratorPartA + x < denominator
    });

    return { denominator, numeratorPartA, numeratorPartB };
  },
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB },
      translate
    } = props;

    const [color1, color2] = getRandomSubArrayFromArray(Object.values(barModelColors), 2, {
      random: seededRandom({ numeratorPartA, numeratorPartB })
    });

    const colors1 = filledArray(color1, numeratorPartA);
    const colors2 = filledArray(color2, numeratorPartB);
    const remainder = filledArray('white', denominator - (numeratorPartA + numeratorPartB));

    const customColorMap = [...colors1, ...colors2, ...remainder];

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorPartA, denominator],
      [numeratorPartB, denominator],
      ADD
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator])
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={dimens.height / 2}
            customColorMap={customColorMap}
          />
        )}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aM5',
  description: 'aM5',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(12),
      numeratorPartA: z.number().int().min(1).max(10),
      numeratorPartB: z.number().int().min(1).max(10)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB < val.denominator,
      `numeratorPartA ${ADD} numeratorPartB must be less than denominator`
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 12);
    const numeratorPartA = randomIntegerInclusive(1, denominator - 2);
    const numeratorPartB = randomIntegerInclusive(1, denominator - 2, {
      constraint: x => numeratorPartA + x < denominator
    });

    return { denominator, numeratorPartA, numeratorPartB };
  },
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB },
      translate
    } = props;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorPartA, denominator],
      [numeratorPartB, denominator],
      ADD
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator])
        }
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aM6',
  description: 'aM6',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(5).max(8),
      numeratorPartA: z.number().int().min(1).max(5),
      numeratorPartB: z.number().int().min(1).max(7),
      numeratorPartC: z.number().int().min(1).max(7)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB + val.numeratorPartC < val.denominator,
      `numeratorPartA ${ADD} numeratorPartB ${ADD} numeratorPartC must be less than denominator`
    ),
  simpleGenerator: () => {
    const { denominator, numeratorPartA, numeratorPartB, numeratorPartC } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(5, 8);
        const numeratorPartA = randomIntegerInclusive(1, denominator - 3);
        const numeratorPartB = randomIntegerInclusive(1, denominator - 1);
        const numeratorPartC = randomIntegerInclusive(1, denominator - 1);

        return {
          denominator,
          numeratorPartA,
          numeratorPartB,
          numeratorPartC
        };
      },
      ({ denominator, numeratorPartA, numeratorPartB, numeratorPartC }) =>
        numeratorPartA + numeratorPartB + numeratorPartC < denominator
    );

    return { denominator, numeratorPartA, numeratorPartB, numeratorPartC };
  },
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB, numeratorPartC },
      translate
    } = props;

    const [color1, color2, color3] = getRandomSubArrayFromArray(Object.values(barModelColors), 3, {
      random: seededRandom({ numeratorPartA, numeratorPartB, denominator })
    });

    const colors1 = filledArray(color1, numeratorPartA);
    const colors2 = filledArray(color2, numeratorPartB);
    const colors3 = filledArray(color3, numeratorPartC);
    const remainder = filledArray(
      'white',
      denominator - (numeratorPartA + numeratorPartB + numeratorPartC)
    );

    const customColorMap = [...colors1, ...colors2, ...colors3, ...remainder];

    const numeratorPartBAndC = numeratorPartB + numeratorPartC;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorPartA, denominator],
      [numeratorPartBAndC, denominator],
      ADD
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator])
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={dimens.height / 2}
            customColorMap={customColorMap}
          />
        )}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartC}' d='${denominator}' /> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aM7',
  description: 'aM7',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(12),
      numeratorPartA: z.number().int().min(2).max(11),
      numeratorPartB: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB > val.denominator,
      `numeratorPartA ${ADD} numeratorPartB must be greater than denominator`
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 12);
    const numeratorPartA = randomIntegerInclusive(2, denominator - 1);
    const numeratorPartB = randomIntegerInclusive(1, denominator - 1, {
      constraint: x => numeratorPartA + x > denominator
    });

    return { denominator, numeratorPartA, numeratorPartB };
  },
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB },
      translate
    } = props;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorPartA, denominator],
      [numeratorPartB, denominator],
      ADD
    );

    const [whole, ansNumeratorB, ansDenominatorB] = improperFractionToMixedNumber(
      ansNumerator,
      ansDenominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator]) &&
          compareFractions(
            [userAnswer[2], userAnswer[3], userAnswer[4]],
            [whole, ansNumeratorB, ansDenominatorB]
          )
        }
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> = <frac nAns='' dAns='' /> = <ans/> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ansNumerator.toLocaleString(),
            ansDenominator.toLocaleString(),
            whole.toLocaleString(),
            ansNumeratorB.toLocaleString(),
            ansDenominatorB.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aM72',
  description: 'aM7',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(12),
      numeratorPartA: z.number().int().min(2).max(11),
      numeratorPartB: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB > val.denominator,
      `numeratorPartA ${ADD} numeratorPartB must be greater than denominator`
    )
    .refine(
      val =>
        arraysHaveSameContents(
          simplify(val.numeratorPartA + val.numeratorPartB - val.denominator, val.denominator),
          [val.numeratorPartA + val.numeratorPartB - val.denominator, val.denominator]
        ),
      `the fractional part of the mixed number cannot be simplified`
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(4, 12);
        const numeratorPartA = randomIntegerInclusive(2, denominator - 1);
        const numeratorPartB = randomIntegerInclusive(1, denominator - 1, {
          constraint: x => numeratorPartA + x > denominator
        });

        return { denominator, numeratorPartA, numeratorPartB };
      },
      val =>
        arraysHaveSameContents(
          simplify(val.numeratorPartA + val.numeratorPartB - val.denominator, val.denominator),
          [val.numeratorPartA + val.numeratorPartB - val.denominator, val.denominator]
        )
    ),
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB },
      translate
    } = props;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorPartA, denominator],
      [numeratorPartB, denominator],
      ADD
    );

    const [whole, ansNumeratorB, ansDenominatorB] = improperFractionToMixedNumber(
      ansNumerator,
      ansDenominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAdditionGiveAnswerAsImproperAndMixedFractions()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator]) &&
          compareFractions(
            [userAnswer[2], userAnswer[3], userAnswer[4]],
            [whole, ansNumeratorB, ansDenominatorB]
          )
        }
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> = <frac nAns='' dAns='' /> = <frac wAns='' nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ansNumerator.toLocaleString(),
            ansDenominator.toLocaleString(),
            whole.toLocaleString(),
            ansNumeratorB.toLocaleString(),
            ansDenominatorB.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aM8',
  description: 'aM8',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(12),
      numeratorPartA: z.number().int().min(1).max(11),
      numeratorPartB: z.number().int().min(1).max(11),
      numeratorPartC: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB + val.numeratorPartC > val.denominator,
      `numeratorPartA ${ADD} numeratorPartB ${ADD} numeratorPartC must be greater than denominator`
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 12);
    const numeratorPartA = randomIntegerInclusive(1, denominator - 1);
    const numeratorPartB = randomIntegerInclusive(1, denominator - 1);
    const numeratorPartC = randomIntegerInclusive(1, denominator - 1, {
      constraint: x => numeratorPartA + numeratorPartB + x > denominator
    });

    return { denominator, numeratorPartA, numeratorPartB, numeratorPartC };
  },
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB, numeratorPartC },
      translate
    } = props;

    const numeratorANumeratorBSum = numeratorPartA + numeratorPartB;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorANumeratorBSum, denominator],
      [numeratorPartC, denominator],
      ADD
    );

    const [whole, ansNumeratorB, ansDenominatorB] = improperFractionToMixedNumber(
      ansNumerator,
      ansDenominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator]) &&
          compareFractions(
            [userAnswer[2], userAnswer[3], userAnswer[4]],
            [whole, ansNumeratorB, ansDenominatorB]
          )
        }
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartC}' d='${denominator}' /> = <frac nAns='' dAns='' /> = <ans/> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ansNumerator.toLocaleString(),
            ansDenominator.toLocaleString(),
            whole.toLocaleString(),
            ansNumeratorB.toLocaleString(),
            ansDenominatorB.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        actionPanelVariant="bottomTall"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aM82',
  description: 'aM8',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(12),
      numeratorPartA: z.number().int().min(1).max(11),
      numeratorPartB: z.number().int().min(1).max(11),
      numeratorPartC: z.number().int().min(1).max(11)
    })
    .refine(
      val => val.numeratorPartA + val.numeratorPartB + val.numeratorPartC > val.denominator,
      `numeratorPartA ${ADD} numeratorPartB ${ADD} numeratorPartC must be greater than denominator`
    )
    .refine(
      val => (val.numeratorPartA + val.numeratorPartB + val.numeratorPartC) % val.denominator !== 0,
      `numeratorPartA ${ADD} numeratorPartB ${ADD} numeratorPartC must not be multiple of denominator`
    )
    .refine(val => {
      const whole =
        (val.numeratorPartA + val.numeratorPartB + val.numeratorPartC) % val.denominator;
      return arraysHaveSameContents(
        simplify(
          val.numeratorPartA + val.numeratorPartB + val.numeratorPartC - val.denominator,
          val.denominator
        ),
        [
          val.numeratorPartA + val.numeratorPartB + val.numeratorPartC - whole * val.denominator,
          val.denominator
        ]
      );
    }, `the fractional part of the mixed number cannot be simplified`),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(4, 12);
        const numeratorPartA = randomIntegerInclusive(1, denominator - 1);
        const numeratorPartB = randomIntegerInclusive(1, denominator - 1);
        const numeratorPartC = randomIntegerInclusive(1, denominator - 1, {
          constraint: x =>
            numeratorPartA + numeratorPartB + x > denominator &&
            (numeratorPartA + numeratorPartB + x) % denominator !== 0
        });

        return { denominator, numeratorPartA, numeratorPartB, numeratorPartC };
      },
      val => {
        const whole =
          (val.numeratorPartA + val.numeratorPartB + val.numeratorPartC) % val.denominator;
        return arraysHaveSameContents(
          simplify(
            val.numeratorPartA + val.numeratorPartB + val.numeratorPartC - val.denominator,
            val.denominator
          ),
          [
            val.numeratorPartA + val.numeratorPartB + val.numeratorPartC - whole * val.denominator,
            val.denominator
          ]
        );
      }
    ),
  Component: props => {
    const {
      question: { denominator, numeratorPartA, numeratorPartB, numeratorPartC },
      translate
    } = props;

    const numeratorANumeratorBSum = numeratorPartA + numeratorPartB;

    const [ansNumerator, ansDenominator] = fractionArithmetic(
      [numeratorANumeratorBSum, denominator],
      [numeratorPartC, denominator],
      ADD
    );

    const [whole, ansNumeratorB, ansDenominatorB] = improperFractionToMixedNumber(
      ansNumerator,
      ansDenominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAdditionGiveAnswerAsImproperAndMixedFractions()}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [ansNumerator, ansDenominator]) &&
          compareFractions(
            [userAnswer[2], userAnswer[3], userAnswer[4]],
            [whole, ansNumeratorB, ansDenominatorB]
          )
        }
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorPartA}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartB}' d='${denominator}' /> ${ADD} <frac n='${numeratorPartC}' d='${denominator}' /> = <frac nAns='' dAns='' /> = <frac wAns='' nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ansNumerator.toLocaleString(),
            ansDenominator.toLocaleString(),
            whole.toLocaleString(),
            ansNumeratorB.toLocaleString(),
            ansDenominatorB.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aM9',
  description: 'aM9',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  questionHeight: 800,
  schema: z
    .object({
      denominator1A: z.number().int().min(4).max(12),
      numeratorPart1A: z.number().int().min(1).max(10),
      numeratorPart1B: z.number().int().min(1).max(10),
      denominator2A: z.number().int().min(4).max(12),
      numeratorPart2A: z.number().int().min(1).max(11),
      numeratorPart2B: z.number().int().min(1).max(11),
      numeratorPart2C: z.number().int().min(1).max(11),
      randomNumeratorA: z.boolean(),
      randomNumeratorB: numberEnum([1, 2, 3])
    })
    .refine(
      val => val.numeratorPart1A + val.numeratorPart1B < val.denominator1A,
      `numeratorPart1A ${ADD} numeratorPart1B must be less than denominator1A`
    )
    .refine(
      val => val.denominator1A !== val.denominator2A,
      `denominator1A must not equal denominator2A`
    )
    .refine(
      val => val.numeratorPart2A + val.numeratorPart2B + val.numeratorPart2C > val.denominator2A,
      `numeratorPart2A ${ADD} numeratorPart2B ${ADD} numeratorPart2C must be greater than denominator2A`
    ),
  simpleGenerator: () => {
    const [denominator1A, denominator2A] = randomUniqueIntegersInclusive(4, 12, 2);
    const numeratorPart1A = randomIntegerInclusive(1, denominator1A - 2);
    const numeratorPart1B = randomIntegerInclusive(1, denominator1A - 2, {
      constraint: x => numeratorPart1A + x < denominator1A
    });
    const numeratorPart2A = randomIntegerInclusive(1, denominator2A - 1);
    const numeratorPart2B = randomIntegerInclusive(1, denominator2A - 1);
    const numeratorPart2C = randomIntegerInclusive(1, denominator2A - 1, {
      constraint: x => numeratorPart2A + numeratorPart2B + x > denominator2A
    });

    const randomNumeratorA = getRandomBoolean();
    const randomNumeratorB = getRandomFromArray([1, 2, 3] as const);

    return {
      denominator1A,
      numeratorPart1A,
      numeratorPart1B,
      denominator2A,
      numeratorPart2A,
      numeratorPart2B,
      numeratorPart2C,
      randomNumeratorA,
      randomNumeratorB
    };
  },
  Component: props => {
    const {
      question: {
        denominator1A,
        numeratorPart1A,
        numeratorPart1B,
        denominator2A,
        numeratorPart2A,
        numeratorPart2B,
        numeratorPart2C,
        randomNumeratorA,
        randomNumeratorB
      },
      translate
    } = props;

    // Calculation 1
    const [ansNumerator1A, ansDenominator1A] = fractionArithmetic(
      [numeratorPart1A, denominator1A],
      [numeratorPart1B, denominator1A],
      ADD
    );

    // Calculation 2
    const numerator2ANumerator2BSum = numeratorPart2A + numeratorPart2B;

    const [ansNumerator2A, ansDenominator2A] = fractionArithmetic(
      [numerator2ANumerator2BSum, denominator2A],
      [numeratorPart2C, denominator2A],
      ADD
    );

    const [whole, ansNumerator2B, ansDenominator2B] = improperFractionToMixedNumber(
      ansNumerator2A,
      ansDenominator2A
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInTheBlanksToCompleteNumberSentences()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0][0], ansDenominator1A],
            randomNumeratorA
              ? [numeratorPart1A, ansDenominator1A]
              : [numeratorPart1B, ansDenominator1A]
          ) &&
          compareFractions(
            [userAnswer[1][0], ansDenominator2A],
            randomNumeratorB === 1
              ? [numeratorPart2A, ansDenominator2A]
              : randomNumeratorB === 2
              ? [numeratorPart2B, ansDenominator2A]
              : [numeratorPart2C, ansDenominator2A]
          )
        }
        inputMaxCharacters={1}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        fractionContainerStyle={{ height: 96 }}
        pdfContainerStyle={{ rowGap: 128 }}
        sentences={[
          randomNumeratorA
            ? `<frac nAns='' d='${denominator1A}' /> ${ADD} <frac n='${numeratorPart1B}' d='${denominator1A}' /> = <frac n='${ansNumerator1A}' d='${ansDenominator1A}' />`
            : `<frac n='${numeratorPart1A}' d='${denominator1A}' /> ${ADD} <frac nAns='' d='${denominator1A}' /> = <frac n='${ansNumerator1A}' d='${ansDenominator1A}' />`,
          randomNumeratorB === 1
            ? `<frac nAns='' d='${denominator2A}' /> ${ADD} <frac n='${numeratorPart2B}' d='${denominator2A}' /> ${ADD} <frac n='${numeratorPart2C}' d='${denominator2A}' /> = ${whole} <frac n='${ansNumerator2B}' d='${ansDenominator2B}' />`
            : randomNumeratorB === 2
            ? `<frac n='${numeratorPart2A}' d='${denominator2A}' /> ${ADD} <frac nAns='' d='${denominator2A}' /> ${ADD} <frac n='${numeratorPart2C}' d='${denominator2A}' /> = ${whole} <frac n='${ansNumerator2B}' d='${ansDenominator2B}' />`
            : `<frac n='${numeratorPart2A}' d='${denominator2A}' /> ${ADD} <frac n='${numeratorPart2B}' d='${denominator2A}' /> ${ADD} <frac nAns='' d='${denominator2A}' /> = ${whole} <frac n='${ansNumerator2B}' d='${ansDenominator2B}' />`
        ]}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            randomNumeratorA
              ? [numeratorPart1A.toLocaleString(), ansDenominator1A.toLocaleString()]
              : [numeratorPart1B.toLocaleString(), ansDenominator1A.toLocaleString()],
            randomNumeratorB === 1
              ? [numeratorPart2A.toLocaleString(), ansDenominator2A.toLocaleString()]
              : randomNumeratorB === 2
              ? [numeratorPart2B.toLocaleString(), ansDenominator2A.toLocaleString()]
              : [numeratorPart2C.toLocaleString(), ansDenominator2A.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwoOrMoreFractions',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
