import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  getRandomFromArray,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { MULT } from 'common/src/constants';
import { nameSchema, names } from 'common/src/utils/names';
import { countRange } from 'common/src/utils/collections';
import {
  monthSchema,
  getRandomMonth,
  getNumberOfDaysInMonth,
  monthAsWord
} from 'common/src/utils/months';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  aOV,
  aOVv2
} from 'common/src/SchemeOfLearning/Year 5/Spring/MultiplicationAndDivisionB/5MultiplyA4DigitNumberByA2DigitNumber';
import { compareFloats } from 'common/src/utils/math';
import Text from 'common/src/components/typography/Text';
import { Path, Svg } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { AssetSvg } from '../../../../assets/svg';
import { Dimens } from '../../../../theme/scaling';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { numberEnum } from '../../../../utils/zod';
import { isValidMoneyAnswer } from '../../../../utils/money';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aou',
  description: 'aou',
  keywords: ['Multiply', 'Pattern'],
  schema: z
    .object({
      number1: z.number().int().min(21).max(99),
      numberA2: z.number().int().min(3).max(9)
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });

    const numberA2 = randomIntegerInclusive(3, 9);

    return { number1, numberA2 };
  },

  Component: props => {
    const {
      question: { number1, numberA2 },
      translate
    } = props;

    const numberB2 = numberA2 * 10;

    const eqs = [
      getBinOpEquation({ left: number1, right: numberA2, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number1, right: numberB2, sign: 'multiply', answer: 'result' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question2 = { ...aOV, uid: 'aov', description: 'aov' as const };

const Question2v2 = { ...aOVv2, uid: 'aov2', description: 'aov' as const };

const Question3 = newQuestionContent({
  uid: 'aow',
  description: 'aow',
  keywords: ['Multiply', 'Written method'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(999),
      number2: z.number().int().min(11).max(99)
    })
    .refine(val => val.number1 % 50 !== 0, 'number1 must not be a multiple of 50'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => x % 50 !== 0
    });

    const number2 = randomIntegerInclusive(11, 99);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        testCorrect={[(number1 * number2).toString()]}
        sentence={`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aox',
  description: 'aox',
  keywords: ['Multiply', 'Problem'],
  schema: z.object({
    rowsAndCols: z
      .number()
      .int()
      .min(17)
      .max(29)
      .refine(val => val !== 20, 'rowsAndCols cannot be 20'),
    pricePerTile: z
      .number()
      .int()
      .min(13)
      .max(29)
      .refine(val => val !== 20, 'pricePerTile cannot be 20')
  }),
  simpleGenerator: () => {
    const rowsAndCols = randomIntegerInclusive(17, 29, {
      constraint: x => x !== 20
    });

    const pricePerTile = randomIntegerInclusive(13, 29, {
      constraint: x => x !== 20
    });

    return { rowsAndCols, pricePerTile };
  },
  Component: props => {
    const {
      question: { rowsAndCols, pricePerTile },
      translate
    } = props;

    const coloredTile = getRandomFromArray(
      [
        'Square/square_blue',
        'Square/square_green',
        'Square/square_pink',
        'Square/square_purple',
        'Square/square_yellow'
      ] as const,
      { random: seededRandom(props.question) }
    );

    const getTiles = (dimens: Dimens) =>
      countRange(rowsAndCols).map(i =>
        countRange(rowsAndCols).map(j =>
          (j % 2 && i % 2) || (!(j % 2) && !(i % 2)) ? (
            <AssetSvg
              key={`row-${i}-col-${j}`}
              name={coloredTile}
              height={dimens.height / rowsAndCols}
            />
          ) : (
            <AssetSvg
              key={`row-${i}-col-${j}`}
              name="Square/square_white"
              height={dimens.height / rowsAndCols}
            />
          )
        )
      );

    const totalInPounds = (rowsAndCols * rowsAndCols * pricePerTile) / 100;

    return (
      <QF1ContentAndSentence
        extraSymbol={'decimalPoint'}
        title={translate.instructions.mrSmithIsTilingHisKitchen(pricePerTile)}
        sentence={translate.answerSentences.poundAns()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], totalInPounds) &&
          // If the user uses a decimal point, the user must use exactly 2 decimal places to correctly represent a price:
          isValidMoneyAnswer(userAnswer[0])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [totalInPounds.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        inputMaxCharacters={6}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          const widthArrowLength = dimens.height;
          const heightArrowLength = dimens.height;
          return (
            <View
              style={{
                width: dimens.height + 40,
                height: dimens.height,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ paddingLeft: 90, justifyContent: 'center', alignItems: 'center' }}>
                <Text variant="WRN400">{rowsAndCols.toLocaleString()}</Text>
                <View style={{ flexDirection: 'row' }}>
                  <Svg width={widthArrowLength} height={20}>
                    <Path d={'M0,10 L10,5 L10,15 Z'} fill={colors.prussianBlue} />
                    <Path
                      d={`M5,10 L${widthArrowLength - 5},10`}
                      stroke={colors.prussianBlue}
                      strokeWidth={2}
                    />
                    <Path
                      d={`M${widthArrowLength},10
                    L${widthArrowLength - 10},5
                    L${widthArrowLength - 10},15
                    Z`}
                      fill={colors.prussianBlue}
                    />
                  </Svg>
                </View>
              </View>

              <View
                style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
              >
                <Text variant="WRN400">{rowsAndCols.toLocaleString()}</Text>
                <View>
                  <Svg width={20} height={heightArrowLength}>
                    <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
                    <Path
                      d={`M10,5 L10,${heightArrowLength - 5}`}
                      stroke={colors.prussianBlue}
                      strokeWidth={2}
                    />
                    <Path
                      d={`M10,${heightArrowLength}
                    L5,${heightArrowLength - 10}
                    L15,${heightArrowLength - 10}
                    Z`}
                      fill={colors.prussianBlue}
                    />
                  </Svg>
                </View>
                <View style={{ height: dimens.height, flexWrap: 'wrap' }}>{getTiles(dimens)}</View>
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoy',
  description: 'aoy',
  keywords: ['Multiplication', 'Multiples', 'Multiply', 'Ones-digit'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(4999)
      .refine(val => val % 10 !== 0, 'number1 must not be a multiple of 10'),
    number2: z.number().int().min(2).max(9),
    incorrectAnswerCDifference: numberEnum([-5, -4, -3, -2, 2, 3, 4, 5])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 4999, {
      constraint: x => x % 10 !== 0
    });

    const number2 = randomIntegerInclusive(2, 9);

    // PowerPoint specifies that incorrectAnswerC cannot be a multiple of number2
    // therefore we need to remove potential differences that are also multiples of number2:
    const incorrectAnswerCDifferenceArray = [-5, -4, -3, -2, 2, 3, 4, 5].filter(
      val => val % number2 !== 0
    );

    const incorrectAnswerCDifference = getRandomFromArray(incorrectAnswerCDifferenceArray) as
      | -5
      | -4
      | -3
      | -2
      | 2
      | 3
      | 4
      | 5;

    return { number1, number2, incorrectAnswerCDifference };
  },
  Component: props => {
    const {
      question: { number1, number2, incorrectAnswerCDifference },
      translate
    } = props;

    const correctAnswer = number1 * number2;
    const incorrectAnswerA = number1 * number2 + 1;
    const incorrectAnswerB = number1 * number2 - 1;
    const incorrectAnswerC = number1 * number2 + incorrectAnswerCDifference;

    const items = shuffle(
      [
        {
          value: correctAnswer,
          isCorrect: true
        },
        {
          value: incorrectAnswerA,
          isCorrect: false
        },
        {
          value: incorrectAnswerB,
          isCorrect: false
        },
        {
          value: incorrectAnswerC,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectCorrectAnswerToXLookAtOnesDigitToHelpYou(
          `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.circleCorrectAnswerToXLookAtOnesDigitToHelpYou(
          `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
        )}
        numItems={4}
        testCorrect={[correctAnswer]}
        renderItems={items.map(({ value }) => ({
          value: value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aoz',
  description: 'aoz',
  keywords: ['Multiply', 'Problem'],
  schema: z
    .object({
      number1: z.number().int().min(17).max(49),
      name1: nameSchema,
      name2: nameSchema,
      month: monthSchema
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10')
    .refine(val => val.name1 !== val.name2, 'Chosen names must be different.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(17, 49, {
      constraint: x => x % 10 !== 0
    });

    const [name1, name2] = getRandomSubArrayFromArray([...names.male, ...names.female], 2);

    const month = getRandomMonth();

    return { number1, name1, name2, month };
  },

  Component: props => {
    const {
      question: { number1, name1, name2, month },
      translate,
      displayMode
    } = props;

    const number2 = number1 * 10;

    const numOfDaysInMonth = getNumberOfDaysInMonth(month);
    const monthWord = monthAsWord(month, translate);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.characterDoesNumSitupsEveryDay(
          name1,
          number1,
          name2,
          number2,
          monthWord
        )}
        testCorrect={[[(number1 * 365).toString()], [(number2 * numOfDaysInMonth).toString()]]}
        sentences={[
          translate.answerSentences.characterDoesNumSitups(name1),
          translate.answerSentences.characterDoesNumSitups(name2)
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        mainPanelContainerStyle={{ width: '100%', justifyContent: 'flex-end' }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyUpToA4DigitNumberByA2DigitNumber',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [Question2v2],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
