import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF15CreateBaseTenNumber from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { ScientificNotation } from '../../../../utils/math';
import { numberEnum } from '../../../../utils/zod';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { CustomizableTableWithState } from '../../../../components/question/representations/CustomizableTable';
import { arraysHaveSameContents } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bg8',
  description: 'bg8',
  keywords: ['More', 'Less', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(11).max(89),
    moreOrLess: z.enum(['more', 'less'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 89);
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);

    return { number, moreOrLess };
  },
  Component: props => {
    const {
      question: { number, moreOrLess },
      translate
    } = props;

    const answer = moreOrLess === 'more' ? number + 10 : number - 10;
    const instruction =
      moreOrLess === 'more'
        ? translate.ks1Instructions.dragBase10ToShowXMore(10, number)
        : translate.ks1Instructions.dragBase10ToShowXLess(10, number);
    const pdfInstruction =
      moreOrLess === 'more'
        ? translate.ks1PDFInstructions.drawBase10ToShowXMore(10, number)
        : translate.ks1PDFInstructions.drawBase10ToShowXLess(10, number);

    return (
      <QF15CreateBaseTenNumber
        title={instruction}
        pdfTitle={pdfInstruction}
        number={ScientificNotation.fromNumber(answer)}
        draggablesToShow={[1, 0]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bg9',
  description: 'bg9',
  keywords: ['More', 'Less'],
  schema: z.object({
    number: z.number().int().min(10).max(90),
    numShownIndex: numberEnum([0, 1, 2])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10, 90);
    const numShownIndex = getRandomFromArray([0, 1, 2] as const);

    return { number, numShownIndex };
  },
  Component: props => {
    const {
      question: { number, numShownIndex },
      translate,
      displayMode
    } = props;

    const numbers = [number - 10, number, number + 10];
    const strings = numbers.map((n, i) => (i === numShownIndex ? n.toLocaleString() : '<ans/>'));
    const answers = numbers.filter((_, i) => i !== numShownIndex).map(String);

    const cellHeaders = [
      translate.tableHeaders.xLess(10),
      translate.tableHeaders.Number(),
      translate.tableHeaders.xMore(10)
    ];

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheTable()}
        inputType="numpad"
        Content={
          <CustomizableTableWithState
            id="table"
            inputMaxCharacters={3}
            defaultState={displayMode === 'markscheme' ? answers : ['', '']}
            tableData={[strings]}
            cellHeaders={cellHeaders}
            textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            // This looks better without having answer boxes visible inside the table cells on PDF:
            answerBoxStyling={
              displayMode !== 'digital' && {
                borderColor: 'transparent'
              }
            }
            testCorrect={userAnswer => arraysHaveSameContents(userAnswer, answers)}
          />
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bha',
  description: 'bha',
  keywords: ['More', 'Less'],
  schema: z.object({
    number: z.number().int().min(10).max(90),
    lessOrMore: z.enum(['less', 'more']),
    ansShown: z.boolean()
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10, 90);
    const lessOrMore = getRandomFromArray(['less', 'more'] as const);
    const ansShown = getRandomBoolean();

    return { number, lessOrMore, ansShown };
  },
  Component: props => {
    const {
      question: { number, lessOrMore, ansShown },
      translate
    } = props;

    const [sentence, answer] = ansShown
      ? lessOrMore === 'more'
        ? [translate.ks1AnswerSentences.xMoreThanAnsIsY(10, number + 10), number.toString()]
        : [translate.ks1AnswerSentences.xLessThanAnsIsY(10, number - 10), number.toString()]
      : lessOrMore === 'more'
      ? [translate.ks1AnswerSentences.xMoreThanYisAns(10, number), (number + 10).toString()]
      : [translate.ks1AnswerSentences.xLessThanYisAns(10, number), (number - 10).toString()];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={sentence}
        testCorrect={[answer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TenMoreTenLess',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
