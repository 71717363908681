import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Line } from 'react-native-svg';
import { Dimens } from 'common/src/theme/scaling';
import { filledArray, range } from 'common/src/utils/collections';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { colors } from '../../../theme/colors';
import { parseMarkup } from '../../../markup';
import { SetState } from '../../../utils/react';
import Text from 'common/src/components/typography/Text';
import { withStateHOC } from '../../../stateTree';
import { AssetSvg } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';

type UserAnswer = string;

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer: SetState<UserAnswer[]>;
  /**
   * @param centerNumber A string to hold the center number of the bug.
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> centerNumber should be a number string
   */
  centerNumber: string;
  /**
   * @param factors A string array to hold the factors for the bug legs.
   * Should have 6 factors either as number strings or answer boxes
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> factors should return an array of number strings
   */
  factors: string[];
  dimens: Dimens;
};

export const FactorBug = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    centerNumber,
    factors,
    dimens: { width, height }
  } = props;
  const displayMode = useContext(DisplayMode);

  const centerParsed = parseMarkup(centerNumber);
  const factorsParsed = factors.map(factors => parseMarkup(factors));

  const dimensCenterY = height / 2 - 15;
  const dimensStartX = width / 4;
  const circleDiameter = Math.min(150, dimensCenterY);

  const wholeCircleY = dimensCenterY - circleDiameter / 2;
  const wholeCircleX = width / 2 - circleDiameter / 2;

  const answerBoxWidth = displayMode === 'digital' ? 96 : 200;
  const answerBoxHeight = displayMode === 'digital' ? 96 : 150;

  const styles = useMemo(
    () => getStyles(width, height, circleDiameter, answerBoxWidth, answerBoxHeight, displayMode),
    [width, height, circleDiameter, answerBoxWidth, answerBoxHeight, displayMode]
  );

  // Create the lines. Need more space if we are in pdf due to bigger answer boxes
  const bugLegPositions = range(-1, 1).map(index => {
    const multiplier = displayMode === 'digital' ? index : 2 * index;
    return [
      dimensCenterY - multiplier * circleDiameter,
      dimensCenterY + multiplier * circleDiameter
    ];
  });
  const lines = bugLegPositions.map((y, index) => {
    return (
      <Line
        x1={dimensStartX}
        y1={y[0]}
        x2={dimensStartX * 3}
        y2={y[1]}
        stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
        strokeWidth="3"
        key={'Line' + index}
      />
    );
  });

  const answerCount = getAnswerCount(props);

  // Create the center circle
  const circle = () => {
    if (centerParsed.numberOfAns > 0) {
      return (
        <View
          style={[
            styles.centerCircle,
            {
              top: wholeCircleY,
              left: wholeCircleX,
              backgroundColor: colors.white,
              borderColor: 'transparent'
            }
          ]}
          key={'centerNumberinput'}
        >
          <NoKeyboardTextInput
            value={userAnswer[answerCount - 1]}
            onChangeText={text => {
              const newState = [...userAnswer];
              newState[answerCount - 1] = text;
              setUserAnswer(newState);
            }}
            style={styles.answerCircle}
          />
        </View>
      );
    }
    return (
      <View
        style={[
          styles.centerCircle,
          {
            top: wholeCircleY,
            left: wholeCircleX
          }
        ]}
      >
        <Text variant="WRN400">{centerNumber}</Text>
      </View>
    );
  };

  const leftFactorPositions = range(-1, 1).map(index => {
    const multiplier = displayMode === 'digital' ? index : 2 * index;
    return [
      dimensStartX - answerBoxWidth - 10,
      dimensCenterY - answerBoxHeight / 2 + circleDiameter * multiplier
    ];
  });

  const rightFactorPositions = range(-1, 1).map(index => {
    const multiplier = displayMode === 'digital' ? index : 2 * index;
    return [
      dimensStartX * 3 + 10,
      dimensCenterY - answerBoxHeight / 2 + circleDiameter * multiplier
    ];
  });

  const allFactors = [...leftFactorPositions, ...rightFactorPositions];

  // Create factors
  let ansIndex = -1;
  const displayFactors = allFactors.map((position, index) => {
    const factor = factorsParsed[index];
    if (factor.numberOfAns > 0) ansIndex++;
    const i = ansIndex;
    return (
      <View
        style={{
          top: position[1],
          left: position[0],
          position: 'absolute'
        }}
        key={`factor_${index}`}
      >
        {factor.numberOfAns > 0 && (
          <NoKeyboardTextInput
            value={userAnswer[i]}
            onChangeText={text => {
              const newState = [...userAnswer];
              newState[i] = text;
              setUserAnswer(newState);
            }}
          />
        )}
        {factor.tokens[0].type === 'text' && (
          <View style={styles.populatedAnswer}>
            <Text variant="WRN400">{factor.tokens[0].value}</Text>
          </View>
        )}
      </View>
    );
  });

  return (
    <View style={styles.container}>
      <View style={{ zIndex: 1, alignItems: 'center', pointerEvents: 'none' }}>
        <AssetSvg name="Factor_Bug" height={height * 0.75} />
      </View>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ position: 'absolute', top: 0, left: 0 }}
        pointerEvents={'none'}
      >
        {lines}
      </Svg>
      {circle()}
      {displayFactors}
    </View>
  );
};

export const FactorBugWithState = withStateHOC(FactorBug, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray('', getAnswerCount(props)),
    testComplete: userAnswer => userAnswer.every(i => i !== '')
  })
});

const getAnswerCount = (props: Omit<Props, 'userAnswer' | 'setUserAnswer'>) => {
  const factorsParsed = props.factors
    .map(factors => parseMarkup(factors))
    .filter(i => i.numberOfAns > 0);
  const centerParsed = parseMarkup(props.centerNumber);
  return factorsParsed.length + centerParsed.numberOfAns;
};

const getStyles = (
  width: number,
  height: number,
  circleDiameter: number,
  answerBoxWidth: number,
  answerBoxHeight: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height
    },

    centerCircle: {
      borderRadius: 999,
      padding: 10,
      width: circleDiameter,
      height: circleDiameter,
      textAlign: 'center',
      justifyContent: 'center',
      textAlignVertical: 'center',
      alignItems: 'center',
      alignContent: 'center',
      lineHeight: 80,
      backgroundColor: colors.white,
      position: 'absolute',
      zIndex: 3
    },

    populatedAnswer: {
      borderWidth: 2,
      borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: answerBoxWidth,
      height: answerBoxHeight
    },

    answerCircle: {
      width: circleDiameter,
      height: circleDiameter,
      borderRadius: 100,
      fontWeight: '700'
    }
  });
