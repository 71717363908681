import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { Line, Path, Rect, Svg } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from 'common/src/utils/random';
import { barModelColors } from 'common/src/theme/colors';
import Text from '../../typography/Text';
import { colors } from 'common/src/theme/colors';
import TextStructure from '../../molecules/TextStructure';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { SetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import { TouchableOpacity } from 'react-native';
import React from 'react';
import { countRange } from '../../../utils/collections';

export type ShadedFractionBarModelProps = {
  /** Total number of subsections for entire bar */
  totalSubSections: number;
  /** Divide totalSubSections into groups of this variable with any remainder rendered at the end */
  totalPerSection?: number;
  containerStyle?: StyleProp<ViewStyle>;
  /** Available width for bar model to use */
  width: number;
  /** Optional height of bar model; defaults to 60 */
  height?: number;
  /** Color for entire bar model */
  color?: string;
  /** Each subsection's color can be manually set using this color map
   * Each index represents a section and hence its color
   */
  customColorMap?: string[];
  /**
   * Boolean to determine whether to hide the dashed lines of each subsection.
   * Optional prop, defaults to false.
   */
  hideDashedLines?: boolean;
  /** Color only certain sections (sections represented as indices)
   * Similiar in functionality to customColorMap, but doesn't allow for multiple colors to be used in the same bar model
   */
  coloredSections?: number[];
  setColoredSections?: SetState<number[]>;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  topBraceTextStyle?: StyleProp<TextStyle>;
  /**
   * Text to display before the bar model. Optional prop.
   */
  preBarText?: string;
  preBarTextStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  fractionDividerStyle?: StyleProp<TextStyle>;
  /** Object containing size of parts and text for specific sections of bar model */
  partWidthBottomBrace?: {
    size: number;
    text: string | number;
  };
  bottomBraceTextStyle?: StyleProp<TextStyle>;
  isFullStrikeThrough?: boolean;
  strikeThroughIndexes?: number[];
  horizontalSplit?: number;
};

/**
 * Bar model to represent a fractions by shading in multiple sections.
 * Can be used to represent a mixed/improper fraction by setting the totalPerSection to the denominator.
 */
const ShadedFractionBarModel = ({
  totalSubSections,
  totalPerSection,
  width,
  height = 60,
  color,
  containerStyle,
  customColorMap,
  hideDashedLines = false,
  coloredSections,
  setColoredSections,
  fullWidthTopBrace,
  topBraceTextStyle,
  preBarText,
  preBarTextStyle,
  fractionContainerStyle,
  fractionTextStyle,
  partWidthBottomBrace,
  bottomBraceTextStyle,
  fractionDividerStyle,
  isFullStrikeThrough = false,
  strikeThroughIndexes,
  horizontalSplit
}: ShadedFractionBarModelProps) => {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode);

  // Calculate individual section width
  const sectionWidth = width / totalSubSections;

  const defaultColor = getRandomFromArray(Object.values(barModelColors), {
    random: seededRandom({ totalSubSections, width })
  }) as string;

  const renderSections = () => {
    // Initialize arrays for SVG paths
    const sections = [];
    const lines = [];
    const strikes = [];

    // Loop to calculate paths for each section & borders
    for (let idx = 0; idx < totalSubSections; idx++) {
      // Default to all colored
      const shadedFlag = coloredSections?.includes(idx) ?? true;

      // Determine color for section
      const sectionColor = customColorMap?.[idx] || (shadedFlag ? color || defaultColor : 'white');

      const xCoord = sectionWidth * idx;
      sections.push(
        <Section
          key={idx}
          x={xCoord}
          width={sectionWidth}
          height={height}
          color={sectionColor}
          onClick={
            setColoredSections
              ? () =>
                  setColoredSections(old => {
                    let newState = [...old];
                    if (newState.includes(idx)) {
                      newState = newState.filter(it => it !== idx);
                    } else {
                      newState.push(idx);
                    }
                    return newState;
                  })
              : undefined
          }
        />
      );

      if (strikeThroughIndexes && strikeThroughIndexes.includes(idx)) {
        strikes.push(
          <React.Fragment key={`lines_${idx}`}>
            <Line
              x1={idx * sectionWidth}
              x2={(idx + 1) * sectionWidth}
              y2={height}
              stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
              strokeWidth={displayMode === 'digital' ? 2 : 3}
            />
            <Line
              x1={idx * sectionWidth}
              x2={(idx + 1) * sectionWidth}
              y1={height}
              stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
              strokeWidth={displayMode === 'digital' ? 2 : 3}
            />
          </React.Fragment>
        );
      }

      // Add dashed lines between sections (apart from last section)
      if (idx !== totalSubSections - 1) {
        const lineX = xCoord + sectionWidth;
        const linePath = `M ${lineX},${height} L ${lineX},0`;
        lines.push(
          <Path
            key={`border-${idx}`}
            d={linePath}
            // Do not have any dashed pattern with hideDashedLines:
            strokeDasharray={hideDashedLines ? '0' : displayMode === 'digital' ? '3 3' : undefined}
            strokeWidth={displayMode === 'digital' ? 1 : 2}
            // If hideDashedLines, assign the current colour of the dashed line to match the current sectionColor:
            stroke={
              hideDashedLines
                ? sectionColor
                : displayMode === 'digital'
                ? colors.prussianBlue
                : colors.black
            }
          />
        );
      }

      // Add a solid line every X sections
      if (totalPerSection && (idx + 1) % totalPerSection === 0 && idx !== totalSubSections - 1) {
        const solidLineX = xCoord + sectionWidth;
        const solidLinePath = `M ${solidLineX},${height} L ${solidLineX},0`;
        lines.push(
          <Path
            key={`solid-border-${idx}`}
            d={solidLinePath}
            strokeWidth={displayMode === 'digital' ? 2 : 4}
            stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          />
        );
      }

      // Add horizontal dividers when horizontalSplit specified
      if (horizontalSplit) {
        countRange(horizontalSplit - 1).map(i =>
          lines.push(
            <Path
              key={`horizontalSplit-${idx}_${i}`}
              d={`M ${idx * sectionWidth} ${(height * (i + 1)) / horizontalSplit} L ${
                (idx + 1) * sectionWidth
              }, ${(height * (i + 1)) / horizontalSplit}`}
              strokeDasharray={displayMode === 'digital' ? '3 3' : undefined}
              strokeWidth={displayMode === 'digital' ? 1 : 2}
              stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
            />
          )
        );
      }
    }

    const renderpartWidthBottomBrace = () => {
      if (!partWidthBottomBrace) return;
      // Size of parts to determine width of horizontal line
      const size = partWidthBottomBrace['size'];

      // Size should be greater than 1
      if (size < 1) return;

      return (
        <View
          style={[
            {
              width: sectionWidth * size
            },
            styles.partWidthBottomBraceHorizontalLine
          ]}
        >
          <View>
            <View
              style={[
                styles.partWidthBottomBraceVerticalLine,
                styles.partWidthBottomBraceVerticalLineLeft
              ]}
            ></View>
            <View
              style={[
                styles.partWidthBottomBraceVerticalLine,
                styles.partWidthBottomBraceVerticalLineRight
              ]}
            ></View>
            <View
              style={[
                styles.partWidthBottomBraceVerticalLine,
                styles.partWidthBottomBraceVerticalLineMiddle
              ]}
            ></View>
            <Text style={[styles.partWidthBottomBraceText, bottomBraceTextStyle]}>
              {partWidthBottomBrace.text}
            </Text>
          </View>
        </View>
      );
    };

    return (
      <View style={{ flexDirection: 'row', columnGap: 16, alignItems: 'center' }}>
        {preBarText && (
          <TextStructure
            sentence={preBarText}
            textStyle={preBarTextStyle}
            fractionTextStyle={fractionTextStyle}
            fractionContainerStyle={fractionContainerStyle}
            fractionDividerStyle={fractionDividerStyle}
          />
        )}
        <View style={{ flexDirection: 'column' }}>
          {/* View to contain the SVG elements as well as the clickable elements */}
          <View style={{ width, height }}>
            {sections}
            {/* Wrap the SVG in a view to provide pointerEvents="none" as this is bugged on Svg on iOS */}
            <View style={StyleSheet.absoluteFill} pointerEvents="none">
              <Svg width={width} height={height}>
                {lines}
                {strikes}
                <Rect
                  x={0}
                  y={0}
                  width={width}
                  height={height}
                  strokeWidth={displayMode === 'digital' ? 4 : 8}
                  stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
                  fill="transparent"
                />
              </Svg>
            </View>
          </View>

          {/* If partWidthBottomBrace prop passed render label text underneath specific section */}
          {partWidthBottomBrace && (
            <View style={styles.partWidthBottomBraceContainer}>{renderpartWidthBottomBrace()}</View>
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {/* If fullWidthTopBrace prop passed render text with horizontal line above bar model */}
      {fullWidthTopBrace && (
        <View
          style={[
            styles.wholeContainer,
            {
              width
            }
          ]}
        >
          <View style={styles.wholeHorizontalLine}>
            <Text style={[styles.wholeHorizontalLineText, topBraceTextStyle]}>
              {fullWidthTopBrace}
            </Text>
            <View
              style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineMiddle]}
            ></View>
            <View
              style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineLeft]}
            ></View>
            <View
              style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineRight]}
            ></View>
          </View>
        </View>
      )}

      {renderSections()}
      {isFullStrikeThrough && (
        <View style={{ position: 'absolute' }}>
          <Svg width={width} height={height}>
            <Line
              x2={width}
              y2={height}
              stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
              strokeWidth={displayMode === 'digital' ? 2 : 3}
            />
            <Line
              x2={width}
              y1={height}
              stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
              strokeWidth={displayMode === 'digital' ? 2 : 3}
            />
          </Svg>
        </View>
      )}
    </View>
  );
};

/** Simple rectangle showing solid color. If `onClick` is provided, this is clickable. */
function Section({
  x,
  width,
  height,
  color,
  onClick
}: {
  x: number;
  width: number;
  height: number;
  color: string;
  onClick?: () => void;
}) {
  const rect = <View style={{ backgroundColor: color, width, height }} />;
  const positionStyle = {
    position: 'absolute',
    left: x,
    top: 0
  } as ViewStyle;

  return onClick ? (
    <TouchableOpacity onPress={onClick} style={positionStyle}>
      {rect}
    </TouchableOpacity>
  ) : (
    <View style={positionStyle}>{rect}</View>
  );
}

const useStyles = (displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center'
        },
        wholeContainer: {
          paddingBottom: 20,
          width: '100%'
        },
        wholeHorizontalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: displayMode === 'digital' ? 2 : 4,
          position: 'relative'
        },
        wholeHorizontalLineText: {
          alignSelf: 'center',
          fontSize: displayMode === 'digital' ? 24 : 50,
          position: 'absolute',
          top: displayMode === 'digital' ? -56 : -84
        },
        wholeHorizontalVerticalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: 10,
          position: 'absolute',
          top: 0,
          width: displayMode === 'digital' ? 2 : 4
        },
        wholeHorizontalVerticalLineLeft: {
          left: 0
        },
        wholeHorizontalVerticalLineRight: {
          right: 0
        },
        wholeHorizontalVerticalLineMiddle: {
          alignSelf: 'center',
          height: 20,
          top: -20
        },
        partWidthBottomBraceContainer: {
          alignSelf: 'flex-start'
        },
        partWidthBottomBraceHorizontalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: displayMode === 'digital' ? 2 : 4,
          marginTop: 10,
          position: 'relative',
          top: 10
        },
        partWidthBottomBraceVerticalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: 10,
          position: 'absolute',
          top: -10,
          width: displayMode === 'digital' ? 2 : 4
        },
        partWidthBottomBraceVerticalLineLeft: {
          left: 0
        },
        partWidthBottomBraceVerticalLineRight: {
          right: 0
        },
        partWidthBottomBraceVerticalLineMiddle: {
          alignSelf: 'center',
          height: 20,
          top: 0
        },
        partWidthBottomBraceText: {
          alignSelf: 'center',
          fontSize: displayMode === 'digital' ? 24 : 50,
          position: 'absolute',
          top: 18
        }
      }),
    [displayMode]
  );
};

export default ShadedFractionBarModel;

export const ShadedFractionBarModelWithState = withStateHOC(ShadedFractionBarModel, {
  stateProp: 'coloredSections',
  setStateProp: 'setColoredSections',
  defaults: {
    defaultState: [],
    testComplete: state => state !== undefined && state.length > 0
  }
});
