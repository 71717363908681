import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import Svg, { Line, Text as SvgText } from 'react-native-svg';
import { polarToCartesian } from '../../../utils/angles';
import { range } from '../../../utils/collections';
import { isInRange } from '../../../utils/matchers';

type DynamicScaleProps = {
  /** Height of whole scale */
  height: number;
  scaleWidth: number;
  /** Default g. (Are not translated)*/
  labels?: 'kg' | 'g';
  /** Scale capacity in "g" */
  maxScale: number;
  /**  Major labelled intervals in "g" */
  majorTicks: number;
  /** Minor intervals in "g". */
  minorTicks?: number;
  /**
   * Amount in g for the arrow to incrementally snap to.
   * If left undefined, the arrow will simply snap to the minor or major tick values.
   */
  snapToNearest?: number;
  /** Blank scale svg*/
  svgName: SvgName;
  scaleFactor: number;
  radsPerG: number;
};

export default function DynamicScale({
  scaleWidth,
  maxScale,
  minorTicks,
  majorTicks,
  labels = 'g',
  height,
  svgName,
  scaleFactor,
  radsPerG
}: DynamicScaleProps) {
  const DEG_PER_G = radsPerG * (180 / Math.PI);

  const labelFontSize = 20 * scaleFactor;
  const majorTickWidth = 5 * scaleFactor;
  const minorTickWidth = 2 * scaleFactor;

  const centerScale = scaleWidth * 0.5;

  const ticks = range(0, maxScale, minorTicks ?? majorTicks);

  return (
    <View
      style={{
        position: 'relative',
        zIndex: 5
      }}
    >
      <View
        style={{
          width: scaleWidth,
          height: height,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 5
        }}
      >
        <Svg width={scaleWidth} height={scaleWidth} viewBox={`0 0 ${scaleWidth} ${scaleWidth}`}>
          {ticks.map(x => {
            const degree = x * DEG_PER_G - 90;
            const startTick = polarToCartesian(
              centerScale,
              centerScale,
              centerScale - 30 * scaleFactor,
              degree
            );
            const endTick = polarToCartesian(
              centerScale,
              centerScale,
              centerScale - 10 * scaleFactor,
              degree
            );
            return (
              <Line
                key={x}
                x1={startTick.x}
                y1={startTick.y}
                x2={endTick.x}
                y2={endTick.y}
                stroke={colors.black}
                strokeWidth={x % majorTicks === 0 ? majorTickWidth : minorTickWidth}
              />
            );
          })}
        </Svg>
      </View>
      <View
        style={{
          width: scaleWidth,
          height: height,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 5
        }}
      >
        <Svg width={scaleWidth} height={scaleWidth} viewBox={`0 0 ${scaleWidth} ${scaleWidth}`}>
          {ticks.map((x, i) => {
            if (x % majorTicks === 0) {
              const degree = x * DEG_PER_G - 90;
              const label = polarToCartesian(
                centerScale,
                centerScale,
                centerScale - 55 * scaleFactor,
                degree
              );
              return (
                <SvgText
                  key={`label-${i}`}
                  y={label.y + 6}
                  x={label.x - 22 * scaleFactor}
                  fontSize={labelFontSize}
                  fontFamily="White_Rose_Noto-regular"
                  letterSpacing={0}
                  alignmentBaseline={
                    // move the labels closer to the scale
                    // 70-113° are the labels on the bottom portion of the scale
                    isInRange(70, 113)(degree)
                      ? 'middle'
                      : // -90° is the label at the top
                      degree === -90
                      ? 'after-edge'
                      : undefined
                  }
                >
                  {labels === 'g' ? `${x.toLocaleString()}g` : `${(x / 1000).toLocaleString()}kg`}
                </SvgText>
              );
            }
          })}
        </Svg>
      </View>

      <AssetSvg name={svgName} width={scaleWidth} />
    </View>
  );
}
