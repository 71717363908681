/**
 * A function to determine whether a passed-in number is a prime number or not.
 * Number must be non-negative.
 */
export function isPrime(n: number): boolean {
  // Negative numbers, 0 and 1 are not prime numbers.
  if (n <= 1) {
    return false;
  }

  // 2 is a prime number.
  if (n === 2) {
    return true;
  }

  // If the number is even and not 2, it cannot be a prime number.
  if (n % 2 === 0) {
    return false;
  }

  // Check if the number is divisible by any odd number between 3 and the square root of itself.
  for (let i = 3; i <= Math.sqrt(n); i += 2) {
    if (n % i === 0) {
      return false;
    }
  }

  return true;
}

/**
 * Use the Sieve of Eratosthenes. n must be a positive integer.
 */
export function getPrimeNumbersToN(n: number): number[] {
  // The sieve marks non-primes.
  const sieve = new Array<true | undefined>(n + 1); // Don't bother filling array with false - just treat undefined as false.
  // For efficiency reasons, we also collect the primes as we go.
  const primes: number[] = [];

  // Starting from p=2, the first prime, mark all multiples of p as non-prime.
  for (let p = 2; p <= n; p++) {
    // Only look at unmarked values of p. For the others, skip to the next value of p.
    if (sieve[p] === undefined) {
      // Sieve had not marked p yet. Since we've marked all multiples less than p already earlier
      // in the loop, this must mean p is prime.
      primes.push(p);

      // Mark all multiples of p
      for (let multiple = p * p; multiple <= n; multiple += p) {
        sieve[multiple] = true;
      }
    }
  }

  return primes;
}

/** Get prime numbers between two positive integer bounds. */
export function getPrimeNumbers(lower: number, upper: number): number[] {
  // Note - you could make this more efficient.
  return getPrimeNumbersToN(upper).filter(i => i >= lower);
}
