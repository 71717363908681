import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bcH',
  description: 'bcH',
  keywords: ['Double'],
  schema: z
    .object({
      numberA: z.number().int().min(0).max(10),
      numberB: z.number().int().min(0).max(10)
    })
    .refine(val => val.numberA !== val.numberB, 'numberA and numberB cannot be the same.'),
  simpleGenerator: () => {
    const [numberA, numberB] = randomUniqueIntegersInclusive(2, 10, 2);
    return {
      numberA,
      numberB
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctA = numberA * 2;
    const correctB = numberB * 2;

    const items = [correctA, correctB];

    const possibleIncorrectAnswers1 = [numberA + 2, numberB + 2];

    const incorrectAnswer1 = getRandomFromArray(
      // Need to filter to prevent possible duplicate answers.
      possibleIncorrectAnswers1.filter(num => !items.includes(num)),
      { random }
    ) as number;

    items.push(incorrectAnswer1);

    const possibleIncorrectAnswers2 = [correctA + 1, correctA - 1, correctB + 1, correctB - 1];

    const incorrectAnswer2 = getRandomFromArray(
      // Need to filter to prevent possible duplicate answers.
      possibleIncorrectAnswers2.filter(num => !items.includes(num)),
      { random }
    ) as number;

    items.push(incorrectAnswer2);

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentences()}
        sentences={[numberA, numberB].map(num => translate.ks1AnswerSentences.doubleNumIsAns(num))}
        items={shuffle(items, { random }).map(num => num.toLocaleString())}
        testCorrect={[[correctA.toLocaleString()], [correctB.toLocaleString()]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcI',
  description: 'bcI',
  keywords: ['Double', 'Halve'],
  schema: z.object({
    answer: z.number().int().min(0).max(10)
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(0, 10);
    return { answer };
  },

  Component: props => {
    const {
      question: { answer },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.doubleAnsIsNum(answer * 2)}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Doubles',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
