import { SvgName } from '../assets/svg';
import { NonEmptyArray } from './collections';
import { getRandomFromArray } from './random';
import { RectilinearShapeNames } from './rectilinearShapes';
import { z } from 'zod';

type CompoundShapeOnGridNoLabelsName =
  | 'Translucent_rectiliear_shape1_grid'
  | 'Translucent_rectiliear_shape2_grid'
  | 'Translucent_rectiliear_shape3_grid'
  | 'Translucent_rectiliear_shape4_grid'
  | 'Translucent_rectiliear_shape5_grid'
  | 'Translucent_rectiliear_shape6_grid'
  | 'Translucent_rectiliear_shape7_grid'
  | 'Translucent_rectiliear_shape8_grid'
  | 'Translucent_rectiliear_shape9_grid'
  | 'Translucent_rectiliear_shape10_grid'
  | 'Translucent_rectiliear_shape11_grid'
  | 'Translucent_rectiliear_shape12_grid';

type CompoundShapesOnGridNoLabelsInfo = {
  svgName: SvgName;
  totalArea: number;
};

/**
 * Compound shapes on a grid of 1cm x 1cm squares, with no labels
 *
 * Table of information of the images in this category, keyed by string identifier, as used by a Question Type's
 * schema, or equivalently a Specific Question's parameters.
 *
 * Note that these string identifies are shared with the backend, so they must remain stable. They're also distinct
 * from display strings or SVG names.
 *
 * Note: Due to historic reasons, the keys are different to the SVG names that end up being used, since the SVGs they
 * use were added later.
 */
export const compoundShapeOnGridNoLabels: Record<
  CompoundShapeOnGridNoLabelsName,
  CompoundShapesOnGridNoLabelsInfo
> = {
  Translucent_rectiliear_shape1_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_nolabels',
    totalArea: 24
  },
  Translucent_rectiliear_shape2_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_nolabels',
    totalArea: 24
  },
  Translucent_rectiliear_shape3_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_nolabels',
    totalArea: 34
  },
  Translucent_rectiliear_shape4_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_nolabels',
    totalArea: 25
  },
  Translucent_rectiliear_shape5_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_nolabels',
    totalArea: 13
  },
  Translucent_rectiliear_shape6_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_nolabels',
    totalArea: 43
  },
  Translucent_rectiliear_shape7_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_nolabels',
    totalArea: 20
  },
  Translucent_rectiliear_shape8_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_nolabels',
    totalArea: 34
  },
  Translucent_rectiliear_shape9_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_nolabels',
    totalArea: 36
  },
  Translucent_rectiliear_shape10_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_nolabels',
    totalArea: 34
  },
  Translucent_rectiliear_shape11_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_nolabels',
    totalArea: 34
  },
  Translucent_rectiliear_shape12_grid: {
    svgName: 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_nolabels',
    totalArea: 12
  }
};

/** String identifiers of {@link compoundShapeOnGridNoLabels}. */
const compoundShapeOnGridNoLabelsNames = Object.keys(
  compoundShapeOnGridNoLabels
) as NonEmptyArray<CompoundShapeOnGridNoLabelsName>;

/** Zod schema for {@link compoundShapeOnGridNoLabelsNames}. */
export const CompoundShapeOnGridNoLabelsSchema = z.enum(compoundShapeOnGridNoLabelsNames);

/** Utility function for getting a random {@link compoundShapeOnGridNoLabelsNames}. */
export const getRandomCompoundShapeOnGridNoLabelsName = (): CompoundShapeOnGridNoLabelsName =>
  getRandomFromArray(compoundShapeOnGridNoLabelsNames);

type TwoShapesOnGridName =
  | 'Compound_shape1_grid'
  | 'Compound_shape2_grid'
  | 'Compound_shape3_grid'
  | 'Compound_shape4_grid'
  | 'Compound_shape5_grid'
  | 'Compound_shape6_grid'
  | 'Compound_shape7_grid'
  | 'Compound_shape8_grid'
  | 'Compound_shape9_grid'
  | 'Compound_shape10_grid'
  | 'Compound_shape11_grid'
  | 'Compound_shape12_grid'
  | 'Compound_shape13_grid'
  | 'Compound_shape14_grid'
  | 'Compound_shape15_grid'
  | 'Compound_shape16_grid';

type TwoShapesOnGridInfo = {
  svgName: SvgName;
  totalArea: number;
};

/**
 * Two shapes on a grid of 1cm x 1cm squares, labeled A and B.
 *
 * Table of information of the images in this category, keyed by string identifier, as used by a Question Type's
 * schema, or equivalently a Specific Question's parameters.
 *
 * Note that these string identifies are shared with the backend, so they must remain stable. They're also distinct
 * from display strings or SVG names.
 */
export const twoShapesOnGrid: Record<TwoShapesOnGridName, TwoShapesOnGridInfo> = {
  Compound_shape1_grid: {
    svgName: 'Shapes_on_grids/Compound_shape1_grid',
    totalArea: 13
  },
  Compound_shape2_grid: {
    svgName: 'Shapes_on_grids/Compound_shape2_grid',
    totalArea: 10
  },
  Compound_shape3_grid: {
    svgName: 'Shapes_on_grids/Compound_shape3_grid',
    totalArea: 22
  },
  Compound_shape4_grid: {
    svgName: 'Shapes_on_grids/Compound_shape4_grid',
    totalArea: 18
  },
  Compound_shape5_grid: {
    svgName: 'Shapes_on_grids/Compound_shape5_grid',
    totalArea: 20
  },
  Compound_shape6_grid: {
    svgName: 'Shapes_on_grids/Compound_shape6_grid',
    totalArea: 15
  },
  Compound_shape7_grid: {
    svgName: 'Shapes_on_grids/Compound_shape7_grid',
    totalArea: 24
  },
  Compound_shape8_grid: {
    svgName: 'Shapes_on_grids/Compound_shape8_grid',
    totalArea: 33
  },
  Compound_shape9_grid: {
    svgName: 'Shapes_on_grids/Compound_shape9_grid',
    totalArea: 27
  },
  Compound_shape10_grid: {
    svgName: 'Shapes_on_grids/Compound_shape10_grid',
    totalArea: 32
  },
  Compound_shape11_grid: {
    svgName: 'Shapes_on_grids/Compound_shape11_grid',
    totalArea: 17
  },
  Compound_shape12_grid: {
    svgName: 'Shapes_on_grids/Compound_shape12_grid',
    totalArea: 18
  },
  Compound_shape13_grid: {
    svgName: 'Shapes_on_grids/Compound_shape13_grid',
    totalArea: 18
  },
  Compound_shape14_grid: {
    svgName: 'Shapes_on_grids/Compound_shape14_grid',
    totalArea: 22
  },
  Compound_shape15_grid: {
    svgName: 'Shapes_on_grids/Compound_shape15_grid',
    totalArea: 24
  },
  Compound_shape16_grid: {
    svgName: 'Shapes_on_grids/Compound_shape16_grid',
    totalArea: 34
  }
};

/** String identifiers of {@link twoShapesOnGrid}. */
export const twoShapesOnGridNames = Object.keys(
  twoShapesOnGrid
) as NonEmptyArray<TwoShapesOnGridName>;

/** Zod schema for {@link twoShapesOnGridNames}. */
export const TwoShapesOnGridSchema = z.enum(twoShapesOnGridNames);

/** Utility function for getting a random {@link twoShapesOnGridNames}. */
export const getRandomTwoShapesOnGridName = () => {
  return getRandomFromArray(twoShapesOnGridNames) as TwoShapesOnGridName;
};

type TwoShapesName =
  | 'Labelled_compound_shape1'
  | 'Labelled_compound_shape2'
  | 'Labelled_compound_shape3'
  | 'Labelled_compound_shape4'
  | 'Labelled_compound_shape5'
  | 'Labelled_compound_shape6';

type TwoShapesInfo = {
  svgName: SvgName;
  sideRatios: readonly number[];
  areaA: readonly [number, number];
  areaB: readonly [number, number];
};

/**
 * Two shapes, not on a grid, labeled A and B.
 *
 * Table of information of the images in this category, keyed by string identifier, as used by a Question Type's
 * schema, or equivalently a Specific Question's parameters.
 *
 * Note that these string identifies are shared with the backend, so they must remain stable. They're also distinct
 * from display strings or SVG names.
 */
export const twoShapes: Record<TwoShapesName, TwoShapesInfo> = {
  Labelled_compound_shape1: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape1',
    sideRatios: [3, 1, 3, 1],
    areaA: [3, 1],
    areaB: [1, 3]
  },
  Labelled_compound_shape2: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape2',
    sideRatios: [5, 3, 3, 2],
    areaA: [5, 3],
    areaB: [3, 2]
  },
  Labelled_compound_shape3: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape3',
    sideRatios: [8, 7, 2, 3],
    areaA: [8, 7],
    areaB: [2, 3]
  },
  Labelled_compound_shape4: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape4',
    sideRatios: [6, 3, 6, 2],
    areaA: [6, 2],
    areaB: [3, 6]
  },
  Labelled_compound_shape5: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape5',
    sideRatios: [6, 5, 6, 2],
    areaA: [6, 2],
    areaB: [5, 6]
  },
  Labelled_compound_shape6: {
    svgName: 'Shapes_with_arrows/Labelled_compound_shape6',
    sideRatios: [6, 1, 6, 2],
    areaA: [6, 2],
    areaB: [6, 1]
  }
};

/** String identifiers of {@link twoShapes}. */
export const twoShapesNames = Object.keys(twoShapes) as NonEmptyArray<TwoShapesName>;

/** Zod schema for {@link twoShapesNames}. */
export const TwoShapesSchema = z.enum(twoShapesNames);

/** Utility function for getting a random {@link twoShapesNames}. */
export const getRandomTwoShapesName = () => {
  return getRandomFromArray(twoShapesNames) as TwoShapesName;
};

type CompoundShapesName =
  | 'Compound_shape1_arrows'
  | 'Compound_shape2_arrows'
  | 'Compound_shape3_arrows'
  | 'Compound_shape4_arrows'
  | 'Compound_shape5_arrows'
  | 'Compound_shape6_arrows'
  | 'Compound_shape7_arrows';

type CompoundShapesInfo = {
  svgName: SvgName;
  sideRatios: readonly number[];
  compoundSides: readonly (readonly [number, number])[];
};

/**
 * Compound shapes, not on a grid, with arrows (but no numbers) on each side.
 *
 * Table of information of the images in this category, keyed by string identifier, as used by a Question Type's
 * schema, or equivalently a Specific Question's parameters.
 *
 * Note that these string identifies are shared with the backend, so they must remain stable. They're also distinct
 * from display strings or SVG names.
 */
export const compoundShapes: Record<CompoundShapesName, CompoundShapesInfo> = {
  Compound_shape1_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape1_arrows',
    sideRatios: [3, 8, 6, 2],
    compoundSides: [
      [3, 8],
      [6, 2]
    ]
  },
  Compound_shape2_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape2_arrows',
    sideRatios: [7, 6, 3, 2],
    compoundSides: [
      [7, 2],
      [4, 3]
    ]
  },
  Compound_shape3_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape3_arrows',
    sideRatios: [7, 8, 3, 5, 3, 5, 1, 8],
    compoundSides: [
      [7, 3],
      [5, 3],
      [5, 1]
    ]
  },
  Compound_shape4_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape4_arrows',
    sideRatios: [9, 6, 3, 2, 2],
    compoundSides: [
      [9, 2],
      [9, 2],
      [3, 2]
    ]
  },
  Compound_shape5_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape5_arrows',
    sideRatios: [3, 2, 2, 1, 6],
    compoundSides: [
      [3, 2],
      [3, 2],
      [2, 1]
    ]
  },
  Compound_shape6_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape6_arrows',
    sideRatios: [7, 5, 2, 3],
    compoundSides: [
      [7, 5],
      [2, 3]
    ]
  },
  Compound_shape7_arrows: {
    svgName: 'Shapes_with_arrows/Compound_shape7_arrows',
    sideRatios: [3, 9, 5, 5],
    compoundSides: [
      [3, 5],
      [4, 5]
    ]
  }
};

/** String identifiers of {@link twoShapes}. */
export const compoundShapeNames = Object.keys(compoundShapes) as NonEmptyArray<CompoundShapesName>;

/** Zod schema for {@link twoShapesNames}. */
export const CompoundShapeSchema = z.enum(compoundShapeNames);

/** Utility function for getting a random {@link twoShapesNames}. */
export const getRandomCompoundShapeName = () => getRandomFromArray(compoundShapeNames);

type ArchivedRectilinearShapesInfo = {
  sideRatios: readonly number[];
  compoundSides: [number, number][];
};

/**
 * @deprecated. These do not have the right proportions - see rectilinearShapes.ts
 */
export const archivedRectilinearShapes: Record<
  RectilinearShapeNames,
  ArchivedRectilinearShapesInfo
> = {
  Rectilinear_shape1_all_arrows: {
    sideRatios: [10, 6, 8, 3, 2, 9],
    compoundSides: [
      [10, 6],
      [3, 2]
    ]
  },
  Rectilinear_shape2_all_arrows: {
    sideRatios: [9, 7, 2, 1, 2, 7, 9, 7, 2, 1, 2, 7],
    compoundSides: [
      [9, 15],
      [2, 1],
      [2, 1]
    ]
  },
  Rectilinear_shape3_all_arrows: {
    sideRatios: [6, 8, 2, 6, 2, 7, 10, 9],
    compoundSides: [
      [9, 6],
      [2, 1],
      [2, 7]
    ]
  },
  Rectilinear_shape4_all_arrows: {
    sideRatios: [10, 3, 2, 2, 2, 8, 6, 9],
    compoundSides: [
      [9, 6],
      [2, 1],
      [3, 2]
    ]
  },
  Rectilinear_shape5_all_arrows: {
    sideRatios: [9, 2, 3, 7, 12, 5],
    compoundSides: [
      [5, 9],
      [3, 7]
    ]
  },
  Rectilinear_shape6_all_arrows: {
    sideRatios: [9, 2, 8, 5, 1, 7],
    compoundSides: [
      [9, 2],
      [5, 1]
    ]
  },
  Rectilinear_shape7_all_arrows: {
    sideRatios: [8, 1, 2, 12, 10, 11],
    compoundSides: [
      [2, 1],
      [11, 10]
    ]
  },
  Rectilinear_shape8_all_arrows: {
    sideRatios: [10, 8, 2, 9, 12, 1],
    compoundSides: [
      [2, 9],
      [10, 1]
    ]
  },
  Rectilinear_shape9_all_arrows: {
    sideRatios: [2, 8, 3, 9, 5, 1],
    compoundSides: [
      [1, 5],
      [8, 3]
    ]
  },
  Rectilinear_shape10_all_arrows: {
    sideRatios: [9, 3, 8, 3, 1, 6],
    compoundSides: [
      [9, 3],
      [3, 1]
    ]
  },
  Rectilinear_shape11_all_arrows: {
    sideRatios: [3, 9, 6, 2, 9, 11],
    compoundSides: [
      [11, 3],
      [6, 2]
    ]
  },
  Rectilinear_shape12_all_arrows: {
    sideRatios: [11, 9, 7, 8, 3, 8, 1, 9],
    compoundSides: [
      [7, 9],
      [3, 1],
      [1, 9]
    ]
  },
  Rectilinear_shape13_all_arrows: {
    sideRatios: [10, 3, 8, 9, 2, 12],
    compoundSides: [
      [2, 12],
      [8, 3]
    ]
  },
  Rectilinear_shape14_all_arrows: {
    sideRatios: [3, 7, 6, 4, 9, 11],
    compoundSides: [
      [3, 11],
      [6, 4]
    ]
  },
  Rectilinear_shape15_all_arrows: {
    sideRatios: [8, 7, 1, 3, 6, 3, 1, 7],
    compoundSides: [
      [7, 1],
      [6, 4],
      [7, 1]
    ]
  },
  Rectilinear_shape16_all_arrows: {
    sideRatios: [11, 1, 7, 2, 4, 3],
    compoundSides: [
      [3, 4],
      [7, 1]
    ]
  },
  Rectilinear_shape17_all_arrows: {
    sideRatios: [8, 10, 3, 9, 5, 1],
    compoundSides: [
      [3, 9],
      [8, 1]
    ]
  },
  Rectilinear_shape18_all_arrows: {
    sideRatios: [8, 1, 5, 6, 3, 7],
    compoundSides: [
      [8, 1],
      [6, 3]
    ]
  },
  Rectilinear_shape19_all_arrows: {
    sideRatios: [2, 5, 8, 3, 10, 8],
    compoundSides: [
      [3, 10],
      [2, 5]
    ]
  },
  Rectilinear_shape20_all_arrows: {
    sideRatios: [6, 3, 5, 7, 1, 10],
    compoundSides: [
      [3, 6],
      [7, 1]
    ]
  },
  Rectilinear_shape21_all_arrows: {
    sideRatios: [3, 11, 10, 5, 7, 6],
    compoundSides: [
      [3, 11],
      [7, 5]
    ]
  },
  Rectilinear_shape22_all_arrows: {
    sideRatios: [10, 1, 7, 8, 3, 9],
    compoundSides: [
      [10, 1],
      [8, 3]
    ]
  },
  Rectilinear_shape23_all_arrows: {
    sideRatios: [4, 7, 8, 4, 12, 11],
    compoundSides: [
      [4, 7],
      [4, 12]
    ]
  },
  Rectilinear_shape24_all_arrows: {
    sideRatios: [5, 12, 9, 5, 4, 7],
    compoundSides: [
      [12, 5],
      [4, 5]
    ]
  },
  Rectilinear_shape25_all_arrows: {
    sideRatios: [10, 9, 5, 3, 5, 12],
    compoundSides: [
      [9, 10],
      [3, 5]
    ]
  },
  Rectilinear_shape26_all_arrows: {
    sideRatios: [9, 11, 7, 3, 2, 8],
    compoundSides: [
      [2, 8],
      [7, 11]
    ]
  },
  Rectilinear_shape27_all_arrows: {
    sideRatios: [4, 3, 6, 9, 10, 12],
    compoundSides: [
      [3, 4],
      [9, 10]
    ]
  },
  Rectilinear_shape28_all_arrows: {
    sideRatios: [4, 3, 6, 8, 10, 5],
    compoundSides: [
      [3, 6],
      [5, 10]
    ]
  },
  Rectilinear_shape29_all_arrows: {
    sideRatios: [3, 4, 8, 6, 11, 10],
    compoundSides: [
      [3, 4],
      [6, 11]
    ]
  },
  Rectilinear_shape30_all_arrows: {
    sideRatios: [4, 7, 3, 2, 1, 5],
    compoundSides: [
      [4, 5],
      [2, 3]
    ]
  },
  Rectilinear_shape31_all_arrows: {
    sideRatios: [9, 8, 4, 2, 5, 6],
    compoundSides: [
      [2, 4],
      [6, 9]
    ]
  },
  Rectilinear_shape32_all_arrows: {
    sideRatios: [10, 7, 2, 4, 6, 4, 2, 7],
    compoundSides: [
      [2, 7],
      [2, 7],
      [6, 3]
    ]
  },
  Rectilinear_shape33_all_arrows: {
    sideRatios: [9, 1, 2, 11, 7, 12],
    compoundSides: [
      [11, 7],
      [9, 1]
    ]
  },
  Rectilinear_shape34_all_arrows: {
    sideRatios: [6, 8, 2, 3, 4, 11],
    compoundSides: [
      [3, 4],
      [6, 8]
    ]
  },
  Rectilinear_shape35_all_arrows: {
    sideRatios: [10, 8, 6, 3, 4, 11],
    compoundSides: [
      [10, 8],
      [4, 3]
    ]
  },
  Rectilinear_shape36_all_arrows: {
    sideRatios: [7, 2, 3, 9, 4, 11],
    compoundSides: [
      [7, 2],
      [4, 9]
    ]
  },
  Rectilinear_shape37_all_arrows: {
    sideRatios: [4, 11, 6, 2, 9, 2],
    compoundSides: [
      [4, 11],
      [2, 2]
    ]
  },
  Rectilinear_shape38_all_arrows: {
    sideRatios: [10, 12, 2, 3, 8, 9],
    compoundSides: [
      [8, 9],
      [2, 12]
    ]
  }
};
