export const getPWMCounterArrangement = (number: number) => {
  switch (number) {
    case 0:
      return [[]];
    case 1:
      return [[true]];
    case 2:
      return [[true, true]];
    case 3:
      return [
        [true, true],
        [true, false]
      ];
    case 4:
      return [
        [true, true],
        [true, true]
      ];
    case 5:
      return [
        [true, true],
        [true, true],
        [true, false]
      ];
    case 6:
      return [
        [true, true],
        [true, true],
        [true, true]
      ];

    default:
      throw new Error(`There are no counter arrangements for ${number}.`);
  }
};
