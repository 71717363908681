import { newBlockContent } from '../../../Block';

import CountFrom50To100 from './1CountFrom50To100';
import TensTo100 from './2TensTo100';
import PartitionIntoTensAndOnes from './3PartitionIntoTensAndOnes';
import TheNumberLineTo100 from './4TheNumberLineTo100';
import OneMoreOneLess from './5OneMoreOneLess';
import CompareNumbersWithTheSameNumberOfTens from './6CompareNumbersWithTheSameNumberOfTens';
import CompareAnyTwoNumbers from './7CompareAnyTwoNumbers';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    CountFrom50To100, // Step 1
    TensTo100, // Step 2
    PartitionIntoTensAndOnes, // Step 3
    TheNumberLineTo100, // Step 4
    OneMoreOneLess, // Step 5
    CompareNumbersWithTheSameNumberOfTens, // Step 6
    CompareAnyTwoNumbers // Step 7
  ]
});
export default Block;
