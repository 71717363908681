import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { SUB } from 'common/src/constants';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { randomIntegerInclusive, rejectionSample } from 'common/src/utils/random';
import {
  compareFractions,
  fractionArithmetic,
  improperFractionToMixedNumber
} from 'common/src/utils/fractions';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ass',
  description: 'ass',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z.object({
    integerA: z.number().int().min(4).max(9),
    denominator: z.number().int().min(3).max(12),
    numeratorA: z.number().int().min(2).max(11),
    integerB: z.number().int().min(1).max(18),
    numeratorB: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const integerA = randomIntegerInclusive(4, 9);
    const denominator = randomIntegerInclusive(3, 12);
    const numeratorA = randomIntegerInclusive(2, denominator - 1);

    const integerB = randomIntegerInclusive(1, integerA - 1);
    const numeratorB = randomIntegerInclusive(1, numeratorA - 1);

    return { integerA, denominator, numeratorA, integerB, numeratorB };
  },
  Component: props => {
    const {
      question: { integerA, denominator, numeratorA, integerB, numeratorB },
      translate
    } = props;

    // Calculate answer and convert back to mixed number
    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic(
        [integerA, numeratorA, denominator],
        [integerB, numeratorB, denominator],
        SUB
      )
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentence={`<frac w='${integerA}' n='${numeratorA}' d='${denominator}'/> ${SUB} <frac w='${integerB}' n='${numeratorB}' d='${denominator}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ast',
  description: 'ast',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z
    .object({
      integerA: z.number().int().min(4).max(9),
      denominatorA: z.number().int().min(3).max(8),
      numeratorA: z.number().int().min(1).max(7),
      integerB: z.number().int().min(1).max(8),
      denominatorB: z.number().int().min(6).max(16),
      numeratorB: z.number().int().min(1).max(14)
    })
    .refine(
      val => (val.numeratorA * 2 - val.numeratorB) % val.denominatorA !== 0,
      'numerators most not sum to multiple of denominator'
    ),
  simpleGenerator: () => {
    const integerA = randomIntegerInclusive(4, 9);
    const denominatorA = randomIntegerInclusive(3, 8);

    const integerB = randomIntegerInclusive(1, integerA - 1);
    const denominatorB = denominatorA * 2;

    const { numeratorA, numeratorB } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, numeratorA * 2 - 1);

        return { numeratorA, numeratorB };
      },
      ({ numeratorA, numeratorB }) => (numeratorA * 2 - numeratorB) % denominatorA !== 0
    );

    return { integerA, denominatorA, numeratorA, integerB, denominatorB, numeratorB };
  },
  Component: props => {
    const {
      question: { integerA, denominatorA, numeratorA, integerB, denominatorB, numeratorB },
      translate
    } = props;

    // Calculate answer and convert back to mixed number
    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic(
        [integerA, numeratorA, denominatorA],
        [integerB, numeratorB, denominatorB],
        SUB
      )
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentence={`<frac w='${integerA}' n='${numeratorA}' d='${denominatorA}'/> ${SUB} <frac w='${integerB}' n='${numeratorB}' d='${denominatorB}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asu',
  description: 'asu',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z
    .object({
      integerA: z.number().int().min(4).max(9),
      denominator: z.number().int().min(3).max(8),
      numerator: z.number().int().min(2).max(7),
      answer1: z.number().int().min(1).max(8),
      answer2: z.number().int().min(6).max(16),
      answer3: z.number().int().min(1).max(13)
    })
    .refine(
      val => (val.numerator * 2 - val.answer3) % val.denominator !== 0,
      'numerators most not sum to multiple of denominator'
    ),
  simpleGenerator: () => {
    const integerA = randomIntegerInclusive(4, 9);
    const denominator = randomIntegerInclusive(3, 8);

    const answer1 = randomIntegerInclusive(1, integerA - 1);
    const answer2 = denominator * 2;

    const { numerator, answer3 } = rejectionSample(
      () => {
        const numerator = randomIntegerInclusive(2, denominator - 1);
        const answer3 = randomIntegerInclusive(1, numerator * 2 - 1);

        return { numerator, answer3 };
      },
      ({ numerator, answer3 }) => (numerator * 2 - answer3) % denominator !== 0
    );

    return { integerA, denominator, numerator, answer1, answer2, answer3 };
  },
  Component: props => {
    const {
      question: { integerA, denominator, numerator, answer1, answer2, answer3 },
      translate
    } = props;

    // Calculate other fraction to display
    const [numeratorSum, denominatorSum] = fractionArithmetic(
      [integerA, numerator, denominator],
      [answer1, answer3, answer2],
      SUB
    );

    // Can't use improperFractionToMixedNumber because it automatically simplifies fraction part
    const number4 = Math.floor(numeratorSum / denominatorSum);
    const number5 = numeratorSum - number4 * denominatorSum;
    const number6 = denominatorSum;

    const correctAnswer = [answer1, answer3, answer2];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentence={`<frac w='${integerA}' n='${numerator}' d='${denominator}'/> ${SUB} <frac wAns='' nAns='' dAns=''/> = <frac w='${number4}' n='${number5}' d='${number6}'/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asv',
  description: 'asv',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z
    .object({
      integerA: z.number().int().min(4).max(9),
      denominator: z.number().int().min(3).max(12),
      numeratorA: z.number().int().min(1).max(10),
      integerB: z.number().int().min(1).max(7),
      numeratorB: z.number().int().min(2).max(11)
    })
    .refine(val => val.integerA - (val.integerB + 1) !== 0, 'integers must not sum to 0'),
  simpleGenerator: () => {
    const integerA = randomIntegerInclusive(4, 9);
    const denominator = randomIntegerInclusive(3, 12);
    const numeratorA = randomIntegerInclusive(1, denominator - 2);

    const integerB = randomIntegerInclusive(1, integerA - 2);
    const numeratorB = randomIntegerInclusive(numeratorA + 1, denominator - 1);

    return { integerA, denominator, numeratorA, integerB, numeratorB };
  },
  Component: props => {
    const {
      question: { integerA, denominator, numeratorA, integerB, numeratorB },
      translate
    } = props;

    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic(
        [integerA, numeratorA, denominator],
        [integerB, numeratorB, denominator],
        SUB
      )
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentence={`<frac w='${integerA}' n='${numeratorA}' d='${denominator}'/> ${SUB} <frac w='${integerB}' n='${numeratorB}' d='${denominator}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asw',
  description: 'asw',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z
    .object({
      integerA: z.number().int().min(4).max(9),
      denominatorA: z.number().int().min(3).max(8),
      numeratorA: z.number().int().min(1).max(6),
      integerB: z.number().int().min(1).max(7),
      denominatorB: z.number().int().min(6).max(16),
      numeratorB: z.number().int().min(2).max(15)
    })
    .refine(val => val.integerA - (val.integerB + 1) !== 0, 'integers must not sum to 0')
    .refine(
      val => (val.numeratorA * 2 - val.numeratorB) % val.denominatorA !== 0,
      'numerators most not sum to multiple of denominator'
    ),
  simpleGenerator: () => {
    const integerA = randomIntegerInclusive(4, 9);
    const denominatorA = randomIntegerInclusive(3, 8);

    const integerB = randomIntegerInclusive(1, integerA - 2);
    const denominatorB = denominatorA * 2;

    const { numeratorA, numeratorB } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusive(1, denominatorA - 2);
        const numeratorB = randomIntegerInclusive(numeratorA + 1, denominatorB - 1);

        return { numeratorA, numeratorB };
      },
      ({ numeratorA, numeratorB }) => (numeratorA * 2 - numeratorB) % denominatorA !== 0
    );

    return { integerA, denominatorA, numeratorA, integerB, denominatorB, numeratorB };
  },
  Component: props => {
    const {
      question: { integerA, denominatorA, numeratorA, integerB, denominatorB, numeratorB },
      translate
    } = props;

    const correctAnswer = improperFractionToMixedNumber(
      ...fractionArithmetic(
        [integerA, numeratorA, denominatorA],
        [integerB, numeratorB, denominatorB],
        SUB
      )
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        sentence={`<frac w='${integerA}' n='${numeratorA}' d='${denominatorA}'/> ${SUB} <frac w='${integerB}' n='${numeratorB}' d='${denominatorB}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswer.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asx',
  description: 'asx',
  keywords: ['Mixed number', 'Subtraction'],
  schema: z
    .object({
      wholeA: z.number().int().min(4).max(12),
      numeratorA: z.number().int().min(3).max(10),
      wholeB: z.number().int().min(1).max(9),
      numeratorB: z.number().int().min(4).max(11),
      denominator: z.number().int().min(5).max(12)
    })
    .refine(val => val.numeratorA < val.numeratorB, 'numeratorA must be less than numeratorB.')
    .refine(val => val.numeratorB < val.denominator, 'numeratorB must be less than denominator.')
    .refine(val => val.wholeB < val.wholeA - 2, 'wholeB must be less than wholeA - 2'),
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(4, 12);

    const wholeB = randomIntegerInclusive(1, wholeA - 3);

    const denominator = randomIntegerInclusive(5, 12);

    const numeratorB = randomIntegerInclusive(4, denominator - 1);

    const numeratorA = randomIntegerInclusive(3, numeratorB - 1);

    return { wholeA, numeratorA, wholeB, numeratorB, denominator };
  },
  Component: props => {
    const {
      question: { wholeA, numeratorA, wholeB, numeratorB, denominator },
      translate
    } = props;

    // Calculate other fraction to display
    const finalImproperFraction = fractionArithmetic(
      [wholeA, numeratorA, denominator],
      [wholeB, numeratorB, denominator],
      SUB
    );

    const finalMixedNumber = improperFractionToMixedNumber(
      finalImproperFraction[0],
      finalImproperFraction[1]
    );

    // Needed in case the final fraction has been multiplied - we must not simplify any fractions displayed.
    // Should be obvious as both denominators shown to the user should be identical.
    const multiplier = denominator / finalMixedNumber[2];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={userAnswer =>
          userAnswer[0] === wholeB.toString() &&
          compareFractions([userAnswer[1], userAnswer[2]], [numeratorB, denominator])
        }
        inputMaxCharacters={2}
        sentence={`<frac w='${wholeA.toLocaleString()}' n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} <frac wAns='' nAns='' dAns=''/> = <frac w='${finalMixedNumber[0].toLocaleString()}' n='${(
          finalMixedNumber[1] * multiplier
        ).toLocaleString()}' d='${(finalMixedNumber[2] * multiplier).toLocaleString()}'/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            wholeB.toLocaleString(),
            numeratorB.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwoMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
