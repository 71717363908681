import { useMemo } from 'react';
import { InheritProps } from '../../../utils/types';
import QF36ContentAndSentencesDrag from './QF36ContentAndSentencesDrag';

/** Base props off of {@link QF36ContentAndSentencesDrag} */
type ChangedAndNewProps<T> = {
  /** Sentence to complete. This uses the markup language defined at {@link parseMarkup}. */
  sentence: string;
  /** Generally speaking you don't need to provide this. Defaults all drop zones empty. */
  sentenceInitialState?: (T | undefined)[];

  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: readonly (T | undefined)[]) => boolean;

  /** Either an array of correct answers, or a function for more advanced cases. */
  testCorrect: (T | undefined)[] | ((userAnswer: readonly (T | undefined)[]) => boolean);
};
type RemovedProps = 'sentences' | 'sentencesInitialState';
type Props<T> = InheritProps<
  typeof QF36ContentAndSentencesDrag<T>,
  ChangedAndNewProps<T>,
  RemovedProps
>;

/**
 * Layout containing a title above, drag and drop zone in the action panel, and arbitrary content with a single
 * sentence below in the main panel.
 *
 * This variant has one sentence. If you need many sentences choose {@link QF36ContentAndSentencesDrag}.
 *
 * To use interactive content, wrap that content in a `StateTreeLeaf` (either manually or using `withStateHoC`).
 *
 * ### Testing functions (testCorrect/testComplete)
 *
 * The easiest way to use these is to omit testComplete, and provide an array for testCorrect, e.g.
 *
 * ```
 *   testCorrect={[1, 2]}
 * ```
 *
 * See {@link QF36ContentAndSentencesDrag}, which this is based on, for more information.
 */
export default function QF36ContentAndSentenceDrag<T>({
  sentence,
  sentenceInitialState,
  testComplete,
  testCorrect,
  ...props
}: Props<T>) {
  return (
    <QF36ContentAndSentencesDrag<T>
      sentences={useMemo(() => [sentence], [sentence])}
      sentencesInitialState={useMemo(
        () => (sentenceInitialState === undefined ? undefined : [sentenceInitialState]),
        [sentenceInitialState]
      )}
      testComplete={useMemo(
        () => (testComplete !== undefined ? userAnswer => testComplete(userAnswer[0]) : undefined),
        [testComplete]
      )}
      testCorrect={useMemo(
        () =>
          typeof testCorrect === 'function'
            ? userAnswer => testCorrect(userAnswer[0])
            : [testCorrect],
        [testCorrect]
      )}
      {...props}
    />
  );
}
