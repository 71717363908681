import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF17CompleteTheNumberLine from 'common/src/components/question/questionFormats/QF17CompleteTheNumberLine';
import QF38ContentWithSentenceTrueOrFalse from 'common/src/components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { NumberLineWithSliderWithState } from 'common/src/components/question/representations/Number Line/NumberLineWithSlider';
import NumberLine from 'common/src/components/question/representations/Number Line/NumberLine';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  range
} from 'common/src/utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'awU',
  description: 'awU',
  keywords: ['Number line', 'Tenths', 'Rounding', 'True', 'False'],
  schema: z
    .object({
      number: z
        .number()
        .min(0)
        .max(9.9)
        .step(0.1)
        .refine(number => number % 0.5 !== 0, 'number cannot be a multiple of 0.5'),
      integerToAsk: z.number().int()
    })
    .refine(
      ({ number, integerToAsk }) => [Math.floor(number), Math.ceil(number)].includes(integerToAsk),
      'integerToAsk must be one of the two nearest integers to number'
    ),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(0, 100, { constraint: it => it % 5 !== 0 }) / 10;
    const integerToAsk = getRandomFromArray([Math.floor(number), Math.ceil(number)]);
    return { number, integerToAsk };
  },
  Component: ({ question: { number, integerToAsk }, translate }) => {
    const floor = Math.floor(number);
    const ceil = Math.ceil(number);
    const tickValues = range(floor, ceil, 0.1).map(x => x.toString());
    const intToCompareTo = integerToAsk === floor ? ceil : floor;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.isStatementTrueOrFalse()}<br/>${translate.answerSentences.xIsCloserToYThanZ(
          number,
          integerToAsk,
          intToCompareTo
        )}`}
        pdfTitle={`${translate.instructions.isStatementTrueOrFalsePDF()}<br/>${translate.answerSentences.xIsCloserToYThanZ(
          number,
          integerToAsk,
          intToCompareTo
        )}`}
        content={({ dimens }) => (
          <NumberLine
            firstNumber={floor}
            lastNumber={ceil}
            tickValues={tickValues}
            focusNumber={number}
            dimens={dimens}
          />
        )}
        correctAnswer={Math.round(number) === integerToAsk}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'awV',
  description: 'awV',
  keywords: ['Number line', 'Tenths', 'Rounding'],
  schema: z.object({
    number1: z.number().int().min(10).max(99).step(1),
    decimal: z
      .number()
      .min(0.1)
      .max(0.9)
      .step(0.1)
      .refine(decimal => decimal % 0.5 !== 0, 'number cannot be a multiple of 0.5')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 99);
    const decimal = randomIntegerInclusive(1, 9, { constraint: x => x !== 5 }) / 10;

    return { number1, decimal };
  },
  Component: ({ question: { number1, decimal }, translate }) => {
    const number2 = number1 + 1;
    const number3 = number1 + decimal;

    const tickValues = range(number1, number2, 0.1).map(x => x.toString());

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number3.toLocaleString())}
        pdfTitle={translate.instructions.drawAnArrowToShowPositionOfNumPDF(
          number3.toLocaleString()
        )}
        content={({ dimens }) => (
          <NumberLineWithSliderWithState
            id={'numberline'}
            min={number1}
            max={number2}
            tickValues={tickValues}
            sliderStep={0.1}
            width={dimens.width}
          />
        )}
        sentence={translate.answerSentences.isXCloserToYorZ(number3, number1, number2)}
        trueButtonLabel={number1.toString()}
        falseButtonLabel={number2.toString()}
        correctAnswer={Math.round(number3) === number1}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'awW',
  description: 'awW',
  keywords: ['Number line', 'Tenths', 'Rounding'],
  schema: z.object({
    number1: z.number().int().min(1).max(9).step(1)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    return { number1 };
  },
  Component: ({ question: { number1 }, translate }) => {
    const tickValues = range(number1 + 0.1, number1 + 0.9, 0.1);
    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        tickValues={[null, ...tickValues, null]}
        freeNumberLineAnswer={[number1, number1 + 1]}
        firstNumber={number1}
        lastNumber={number1 + 1}
        testCorrect={[number1.toString(), (number1 + 1).toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awX',
  description: 'awX',
  keywords: ['Integer', 'Tenths', 'Rounding'],
  schema: z
    .object({
      number1: z.number().min(0.1).max(98.9).step(0.1)
    })
    .refine(val => val.number1 % 1 !== 0, 'number1 not end in a zero'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 989, { constraint: x => x % 10 !== 0 }) / 10;

    return { number1 };
  },
  Component: ({ question: { number1 }, translate }) => {
    const answer = [Math.ceil(number1).toString(), Math.floor(number1).toString()];

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.whatIntegersDoesXLieBetween(
          number1
        )}<br/>${translate.instructions.completeSentence()}`}
        sentence={translate.answerSentences.xLiesBetweenAnsAndAns(number1)}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        inputMaxCharacters={3}
        testCorrect={userAnswer => arraysHaveSameContentsUnordered(userAnswer, answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            Math.floor(number1).toLocaleString(),
            Math.ceil(number1).toLocaleString()
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'awY',
  description: 'awY',
  keywords: ['Tenths', 'Rounding'],
  schema: z.object({
    numbers: z
      .number()
      .refine(it => !Number.isInteger(it), 'must not be an integer')
      .array()
      .length(3)
      .refine(arrayHasNoDuplicates, 'numbers must not contain duplicates')
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(16, 94, { constraint: x => x % 5 !== 0 }) / 10;
    const var2 = randomIntegerInclusive(1, 9, { constraint: x => x !== 5 }) / 10;
    const var3 =
      randomIntegerInclusive(5, 85, { constraint: x => x % 5 === 0 && x % 10 !== 0 }) / 10;

    return { numbers: shuffle([var1, var2, var3]) };
  },
  Component: ({ question: { numbers }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundEachDecimalToTheNearestWholeNumber()}
        sentences={numbers.map(x => `${x.toString()} <ans/>`)}
        testCorrect={numbers.map(x => [Math.round(x).toString()])}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awZ',
  description: 'awZ',
  keywords: ['Tenths', 'Rounding'],
  schema: z
    .object({
      targetNumber: z.number().int(),
      options: z
        .number()
        .step(0.1)
        .refine(it => !Number.isInteger(it), 'must not be an integer')
        .array()
        .length(7)
        .refine(arrayHasNoDuplicates, 'options must not contain duplicates')
    })
    .refine(
      ({ targetNumber, options }) => options.some(it => Math.round(it) === targetNumber),
      'at least one option must be correct'
    ),
  simpleGenerator: () => {
    const addOrSubValue = (a: number, b: number) => getRandomFromArray([a + b, a - b]);

    const targetNumber = randomIntegerInclusive(10, 50); // var1
    const options = rejectionSample(
      () => [
        addOrSubValue(targetNumber, getRandomFromArray([0.1, 0.2, 0.3, 0.4])), // var2
        addOrSubValue(targetNumber, 0.5), // var3
        targetNumber + getRandomFromArray([0.6, 0.7, 0.8, 0.9]), // var4
        targetNumber - getRandomFromArray([0.1, 0.2, 0.3, 0.4]), // var5
        addOrSubValue(targetNumber, getRandomFromArray([1.1, 1.2, 1.3, 1.4])), // var6
        addOrSubValue(targetNumber, getRandomFromArray([0.1, 0.2, 0.3, 0.4])), // var7
        targetNumber - getRandomFromArray([0.6, 0.7, 0.8, 0.9]) // var8
      ],
      arrayHasNoDuplicates
    );

    return { targetNumber, options };
  },
  Component: ({ question: { targetNumber, options }, translate }) => {
    const items = getRandomSubArrayFromArray(options, 6, {
      random: seededRandom({ targetNumber, options })
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheNumbersThatRoundToXWhenRoundedToTheNearestWholeNumber(
          targetNumber
        )}
        items={items}
        testCorrect={items.filter(it => Math.round(it) === targetNumber)}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearestWholeNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
