import { View, StyleSheet } from 'react-native';
import { arraysHaveSameContents, countRange, filledArray } from 'common/src/utils/collections';
import { useContext } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import { isInRange, isNotEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { CounterVariant, renderTenFrameCounter } from '../representations/TenFrame/TenFrameLayout';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * The correct grouping of values. The 2D array goes in order.
   *
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect: CounterVariant[][] | ((userAnswer: CounterVariant[][]) => boolean);
  /**
   * Optional custom mark scheme answer (with items). If you provide testCorrect as a function, you _must_ provide
   * this. If you provide testCorrect as an array, you don't need to provide this.
   *
   * Either a text string to display, or an array of rows, each with an array of columns, each with an array of
   * correct answers to go in that zone.
   */
  markSchemeAnswer?: CounterVariant[][];
  items: CounterVariant[];
  questionHeight?: number;
  /**
   * Number of drop zones to show.
   * If `variation` is horizontal, the maximum number is 6.
   * If `variation` is vertical, the maximum number is 10.
   */
  numberOfGroups: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Array of counters representing the amount of counters each zone has to start with.
   */
  countersPerGroup?: CounterVariant[][];
  /**
   * Determines the drop zone orientation. Optional, defaults to 'horizontal'.
   */
  variation?: 'vertical' | 'horizontal';
};

/**
 * Question Format 60: Drag counters into groups of up to 6 Groups
 *
 * Title at the top, draggables on the right, and dropzone positions to drag them into on the left.
 *
 * This is a many-6 drag and drop.
 */
export default function QF60DragCountersIntoGroups({
  title,
  pdfTitle,
  testCorrect,
  markSchemeAnswer,
  items: itemsProp,
  questionHeight,
  numberOfGroups,
  countersPerGroup,
  variation = 'horizontal',
  ...props
}: Props) {
  if (variation === 'horizontal' && !isInRange(2, 6)(numberOfGroups)) {
    throw new Error('For horizontal variation, numberOfGroups must be between 2 and 6.');
  }

  if (typeof testCorrect === 'function' && markSchemeAnswer === undefined) {
    throw new Error('testCorrect is a function, so you must provide the exampleCorrectAnswer prop');
  }

  const styles = getStyles(numberOfGroups, variation);

  const displayMode = useContext(DisplayMode);

  const draggables = itemsProp.map(item => {
    return {
      component: renderTenFrameCounter({
        size: 'large',
        variant: item,
        showShadow: false
      }),
      value: item
    };
  });

  const initialState = countersPerGroup ? countersPerGroup : filledArray([], numberOfGroups);

  const dropSource = (
    <DragAndDropSection>
      {itemsProp.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const dragZones = (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        columnGap: variation === 'vertical' ? 20 : 16,
        rowGap: variation === 'vertical' ? 0 : 16,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {countRange(numberOfGroups).map((_, index) => {
        return variation === 'vertical' ? (
          <EasyDragAndDrop.ZoneMultiple
            key={index}
            id={index}
            capacity={numberOfGroups <= 5 ? 8 : 4}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              borderStyle: 'solid',
              width: '18%',
              height: numberOfGroups <= 5 ? '75%' : '44%',
              borderRadius: 24
            }}
            droppedStyle={styles.counter}
          />
        ) : (
          <EasyDragAndDrop.ZoneMultiple
            key={index}
            id={index}
            capacity={6}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              borderStyle: 'solid',
              width: numberOfGroups > 4 ? '32%' : '49%',
              height: numberOfGroups > 4 ? '50%' : '50%',
              borderRadius: 24
            }}
            droppedStyle={styles.counter}
          />
        );
      })}
    </View>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const defaultState =
      displayMode === 'markscheme' && typeof testCorrect !== 'function'
        ? testCorrect
        : markSchemeAnswer;

    return (
      <EasyDragAndDrop.ProviderWithState
        id="QF60"
        moveOrCopy="copy"
        items={draggables}
        defaultState={defaultState}
        draggableStyle={{
          borderWidth: 0,
          shadowColor: 'transparent',
          backgroundColor: 'transparent'
        }}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={dragZones}
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="QF60"
      items={draggables}
      moveOrCopy="copy"
      defaultState={initialState}
      // Complete if at least one zone has had a change.
      testComplete={isNotEqual(initialState)}
      hideBackground
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : state => state.every((zone, index) => arraysHaveSameContents(zone, testCorrect[index]))
      }
      draggableStyle={{
        borderWidth: 0,
        shadowColor: 'transparent',
        backgroundColor: 'transparent'
      }}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        actionPanelContents={dropSource}
        mainPanelContents={dragZones}
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const getStyles = (numberOfGroups: number, variation: 'horizontal' | 'vertical') => {
  return StyleSheet.create({
    // A single counter.
    counter: {
      width:
        variation === 'horizontal' ? (numberOfGroups > 4 ? 80 : 70) : numberOfGroups > 5 ? 65 : 70,
      height:
        variation === 'horizontal' ? (numberOfGroups > 4 ? 80 : 70) : numberOfGroups > 5 ? 65 : 70
    }
  });
};
