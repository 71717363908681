import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import QF30GroupCountersAndSentence from 'common/src/components/question/questionFormats/QF30GroupCountersAndSentence';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { getObjectImage } from 'common/src/utils/objectsImages';
import { filledArray } from 'common/src/utils/collections';
import { getGenderFromName, getRandomName, nameSchema } from 'common/src/utils/names';
import { DIV } from 'common/src/constants';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectPrepositionWord,
  objectNames,
  objectSchema
} from 'common/src/utils/objects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aj1',
  description: 'aj1',
  keywords: ['Share', 'Divide'],
  schema: z
    .object({
      countersPerGroup: z.number().int().min(2).max(6),
      groups: z.number().int().min(2).max(5),
      sharingOrGrouping: z.enum(['sharing', 'grouping'])
    })
    .refine(
      val => val.groups !== val.countersPerGroup,
      'groups and countersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const countersPerGroup = randomIntegerInclusive(2, 6);

    const groups = randomIntegerInclusive(2, 5, {
      constraint: x => x !== countersPerGroup && x * countersPerGroup < 21
    });

    const sharingOrGrouping = getRandomFromArray(['sharing', 'grouping'] as const);

    return { groups, countersPerGroup, sharingOrGrouping };
  },
  Component: ({ question: { groups, countersPerGroup, sharingOrGrouping }, translate }) => {
    const counters = groups * countersPerGroup;
    return (
      <QF30GroupCountersAndSentence
        title={
          sharingOrGrouping === 'sharing'
            ? translate.instructions.xCountersAreSharedEquallyIntoYGroupsCompleteSentence(
                counters,
                groups
              )
            : translate.instructions.xCountersArePutIntoGroupsOfYCompleteSentence(
                counters,
                countersPerGroup
              )
        }
        sentence={
          sharingOrGrouping === 'sharing'
            ? translate.answerSentences.thereAreAnsCountersInEachGroup()
            : translate.answerSentences.thereAreAnsGroupsOf(countersPerGroup.toLocaleString())
        }
        testCorrect={
          sharingOrGrouping === 'sharing' ? [countersPerGroup.toString()] : [groups.toString()]
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aj2',
  description: 'aj2',
  keywords: ['Group', 'Divide'],
  schema: z
    .object({
      countersPerGroup: z.number().int().min(2).max(6),
      groups: z.number().int().min(2).max(5),
      sharingOrGrouping: z.enum(['sharing', 'grouping'])
    })
    .refine(
      val => val.groups !== val.countersPerGroup,
      'groups and countersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const countersPerGroup = randomIntegerInclusive(2, 6);

    const groups = randomIntegerInclusive(2, 5, {
      constraint: x => x !== countersPerGroup && x * countersPerGroup < 21
    });

    const sharingOrGrouping = getRandomFromArray(['sharing', 'grouping'] as const);

    return { groups, countersPerGroup, sharingOrGrouping };
  },
  Component: ({ question: { groups, countersPerGroup, sharingOrGrouping }, translate }) => {
    const counters = groups * countersPerGroup;
    return (
      <QF30GroupCountersAndSentence
        title={
          sharingOrGrouping === 'sharing'
            ? translate.instructions.xCountersAreSharedEquallyIntoYGroupsCompleteDivision(
                counters,
                groups
              )
            : translate.instructions.xCountersArePutIntoGroupsOfYCompleteDivision(
                counters,
                countersPerGroup
              )
        }
        sentence={
          sharingOrGrouping === 'sharing'
            ? `${counters.toLocaleString()} ${DIV} ${groups.toLocaleString()} = <ans/>`
            : `${counters.toLocaleString()} ${DIV} ${countersPerGroup.toLocaleString()} = <ans/>`
        }
        testCorrect={
          sharingOrGrouping === 'sharing' ? [countersPerGroup.toString()] : [groups.toString()]
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aj3',
  description: 'aj3',
  keywords: ['Divide', 'Share', 'Group'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(2).max(5),
    shareOrGroup: z.enum(['share', 'group']),
    object: objectSchema,
    array: z.string().array().length(2)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 5);
    const number1 = randomIntegerInclusive(2, 5, {
      constraint: x => x !== number2
    });
    const shareOrGroup = getRandomFromArray(['share', 'group'] as const);

    const object = getRandomFromArray(objectNames, {
      random: seededRandom({ number1, number2, shareOrGroup })
    });

    const array = shuffle(['share', 'group']);

    return { number1, number2, shareOrGroup, array, object };
  },

  Component: ({ question: { number1, number2, shareOrGroup, array, object }, translate }) => {
    const number3 = number1 * number2;

    return (
      <QF11SelectImagesUpTo4
        title={
          shareOrGroup === 'share'
            ? translate.instructions.selectPictureThatShowsXDIVYAsASharingProblem(number3, number2)
            : translate.instructions.selectPictureThatShowsXDIVYAsAGroupingProblem(number3, number2)
        }
        pdfTitle={
          shareOrGroup === 'share'
            ? translate.instructions.circlePictureThatShowsXDIVYAsASharingProblem(number3, number2)
            : translate.instructions.circlePictureThatShowsXDIVYAsAGroupingProblem(number3, number2)
        }
        numItems={2}
        testCorrect={[shareOrGroup]}
        renderItems={({ dimens }) =>
          array.map((type, i) => {
            return {
              value: type,
              component: (
                <ArrayOfObjects
                  key={i}
                  dimens={dimens}
                  columns={type === 'share' ? number2 : number1}
                  rows={1}
                  rowStyle={{ columnGap: 4, paddingHorizontal: 10 }}
                  customImage={getObjectImage(
                    object,
                    type === 'share' ? number1 : number2,
                    dimens.height / (type === 'share' ? number2 : number1),
                    dimens.width / (type === 'share' ? number2 : number1) - 10
                  )}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aj4',
  description: 'aj4',
  keywords: ['Divide'],
  schema: z.object({
    number1: z.number().int().min(2).max(8),
    number2: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 5);
    const number1 = randomIntegerInclusive(2, 8, {
      constraint: x => x !== number2
    });

    return { number1, number2 };
  },

  Component: ({ question: { number1, number2 }, translate }) => {
    const number3 = number1 * number2;

    const barArray = filledArray(number2, number1);

    const numbers = [[number3], barArray];

    const strings = [[], filledArray('?', number1)];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.workOutTheDivision()}
        testCorrect={[number2.toString()]}
        sentence={`${number3.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number3}
            dimens={dimens}
            sameRowColor
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aj5',
  description: 'aj5',
  keywords: ['Share', 'Divide', 'Problem'],
  schema: z
    .object({
      numbersPerGroup: z.number().int().min(2).max(6),
      groups: z.number().int().min(2).max(5),
      object: objectSchema,
      name: nameSchema
    })
    .refine(
      val => val.groups !== val.numbersPerGroup,
      'groups and numbersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const numbersPerGroup = randomIntegerInclusive(2, 6);

    const groups = randomIntegerInclusive(2, 5, {
      constraint: x => x !== numbersPerGroup && x * numbersPerGroup < 21
    });

    const name = getRandomName();
    const object = getRandomObject();
    return { groups, numbersPerGroup, name, object };
  },
  Component: ({ question: { groups, numbersPerGroup, name, object }, translate }) => {
    const total = groups * numbersPerGroup;

    const containerPlural = containerOfObject(object, translate, true);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);
    const movementPreposition = objectPrepositionWord(object, translate, true);
    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.characterHasNumObjectsCharPutsThemIntoContainersWithNumEach({
          name,
          total,
          objectPlural,
          movementPreposition,
          containerPlural,
          amountPerGroup: numbersPerGroup,
          preposition
        })}
        sentence={translate.answerSentences.ansObject(containerPlural)}
        testCorrect={[groups.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aj6',
  description: 'aj6',
  keywords: ['Group', 'Divide', 'Problem'],
  schema: z
    .object({
      numbersPerGroup: z.number().int().min(2).max(6),
      groups: z.number().int().min(2).max(5),
      object: objectSchema,
      name: nameSchema
    })
    .refine(
      val => val.groups !== val.numbersPerGroup,
      'groups and numbersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const numbersPerGroup = randomIntegerInclusive(2, 6);

    const groups = randomIntegerInclusive(2, 5, {
      constraint: x => x !== numbersPerGroup && x * numbersPerGroup < 21
    });

    const name = getRandomName();
    const object = getRandomObject();
    return { groups, numbersPerGroup, name, object };
  },
  Component: ({ question: { groups, numbersPerGroup, name, object }, translate }) => {
    const numbers = groups * numbersPerGroup;

    const pronoun =
      getGenderFromName(name) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();
    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);
    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.characterHasNumObjects({
          name,
          pronoun,
          num1: numbers,
          objectPlural,
          containerPlural,
          num2: numbersPerGroup,
          preposition,
          containerSingular
        })}
        sentence={translate.answerSentences.ansObject(objectPlural)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[groups.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SharingAndGrouping',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
