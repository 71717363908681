import { newBlockContent } from '../../../Block';

import Recognise2DAnd3DShapes from './1Recognise2DAnd3DShapes';
import CountSidesOn2DShapes from './2CountSidesOn2DShapes';
import CountVerticesOn2DShapes from './3CountVerticesOn2DShapes';
import Draw2DShapes from './4Draw2DShapes';
import LinesOfSymmetryOnShapes from './5LinesOfSymmetryOnShapes';
import UseLinesOfSymmetryToCompleteShapes from './6UseLinesOfSymmetryToCompleteShapes';
import Sort2DShapes from './7Sort2DShapes';
import CountFacesOn3DShapes from './8CountFacesOn3DShapes';
import CountEdgesOn3DShapes from './9CountEdgesOn3DShapes';
import CountVerticesOn3DShapes from './10CountVerticesOn3DShapes';
import Sort3DShapes from './11Sort3DShapes';
import MakePatternsWith2DAnd3DShapes from './12MakePatternsWith2DAnd3DShapes';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [10, 12],
  smallSteps: [
    Recognise2DAnd3DShapes, // Step 1
    CountSidesOn2DShapes, // Step 2
    CountVerticesOn2DShapes, // Step 3
    Draw2DShapes, // Step 4
    LinesOfSymmetryOnShapes, // Step 5
    UseLinesOfSymmetryToCompleteShapes, // Step 6
    Sort2DShapes, // Step 7
    CountFacesOn3DShapes, // Step 8
    CountEdgesOn3DShapes, // Step 9
    CountVerticesOn3DShapes, // Step 10
    Sort3DShapes, // Step 11
    MakePatternsWith2DAnd3DShapes // Step 12
  ]
});
export default Block;
