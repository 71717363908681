import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  MonthName,
  getConsecutiveMonths,
  getDayNameForGivenDate,
  getFirstDateOfDayInMonthByYear,
  getLastDateOfDayInMonthByYear,
  getNumberOfDaysInMonth,
  getNumberOfDaysInMonthByYear,
  getRandomMonth,
  monthAsWord,
  monthNames,
  monthSchema
} from '../../../../utils/months';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { getCharacterHeadImage } from '../../../../utils/characters';
import { View } from 'react-native';
import { Calendar } from '../../../../components/question/representations/Calendar';
import { dayNames, daySchema } from '../../../../utils/days';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { unit } from 'mathjs';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { getNumeralOrdinal, getWordOrdinal } from '../../../../utils/ordinals';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'at6',
  description: 'at6',
  keywords: ['Time', 'Years', 'Months', 'Days'],
  schema: z.object({
    questionIndex: z.number().min(0).max(4)
  }),
  simpleGenerator: () => {
    const questionIndex = randomIntegerInclusive(0, 4);

    return { questionIndex };
  },

  Component: props => {
    const {
      question: { questionIndex },
      translate
    } = props;

    const questions = [
      { sentence: translate.answerSentences.thereAreAnsDaysInAWeek(), answer: '7' },
      { sentence: translate.answerSentences.thereAreAnsMonthsInAYear(), answer: '12' },
      { sentence: translate.answerSentences.thereAreAnsDaysInANonLeapYear(), answer: '365' },
      { sentence: translate.answerSentences.thereAreAnsDaysInALeapYear(), answer: '366' },
      { sentence: translate.answerSentences.leapYearsHappenEveryAnsYears(), answer: '4' }
    ];

    const { sentence, answer } = questions[questionIndex];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[answer]}
        sentence={sentence}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'at7',
  description: 'at7',
  keywords: ['Time', 'Months', 'Days'],
  schema: z.object({
    months: z.array(monthSchema).length(3)
  }),
  simpleGenerator: () => {
    const months = getConsecutiveMonths(3);

    return {
      months
    };
  },
  Component: props => {
    const {
      question: { months },
      translate
    } = props;

    // Statements
    const statements = [
      {
        statement: monthAsWord(months[0], translate),
        value: getNumberOfDaysInMonth(months[0] as MonthName)
      },
      {
        statement: monthAsWord(months[1], translate),
        value: getNumberOfDaysInMonth(months[1] as MonthName)
      },
      {
        statement: monthAsWord(months[2], translate),
        value: getNumberOfDaysInMonth(months[2] as MonthName)
      }
    ];

    const answerOptions = [
      {
        component: (30).toLocaleString(),
        value: 30
      },
      {
        component: (31).toLocaleString(),
        value: 31
      },
      {
        component: (28).toLocaleString(),
        value: 28
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchHowManyDaysThereAreInEachMonth()}
        pdfTitle={translate.instructions.matchHowManyDaysThereAreInEachMonthPDF()}
        items={shuffledAnswerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        moveOrCopy="copy"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'at8',
  description: 'at8',
  keywords: ['Time', 'Months', 'Days', 'Calendar'],
  schema: z
    .object({
      month: monthSchema,
      dateInMonth: z.number().int().min(1).max(31),
      year: z.number().int().min(2016).max(2023),
      correctDay: daySchema,
      incorrectDayA: daySchema,
      incorrectDayB: daySchema
    })
    .refine(
      val => getNumberOfDaysInMonthByYear(val.month, val.year) >= val.dateInMonth,
      'dateInMonth must not be larger than number of days in selected month.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.incorrectDayA, val.incorrectDayB]),
      'days for answers must all be different.'
    ),
  simpleGenerator: () => {
    const month = getRandomMonth();
    const year = randomIntegerInclusive(2016, 2023);

    const dateInMonth = randomIntegerInclusive(1, getNumberOfDaysInMonthByYear(month, year));

    const correctDay = getDayNameForGivenDate(
      new Date(year, monthNames.indexOf(month), dateInMonth)
    );

    const [incorrectDayA, incorrectDayB] = getRandomSubArrayFromArray(
      dayNames.filter(day => day !== correctDay),
      2
    );

    return { month, dateInMonth, year, correctDay, incorrectDayA, incorrectDayB };
  },
  Component: props => {
    const {
      question: { month, dateInMonth, year, correctDay, incorrectDayA, incorrectDayB },
      locale,
      translate
    } = props;

    const ordinal = getNumeralOrdinal(dateInMonth, locale, translate);

    const statements = [
      {
        value: correctDay
      },
      {
        value: incorrectDayA
      },
      {
        value: incorrectDayB
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsACalendarFromMonthYearSelectDayOfWeek(
          month,
          year,
          ordinal
        )}
        pdfTitle={translate.instructions.hereIsACalendarFromMonthYearSelectDayOfWeekPDF(
          month,
          year,
          ordinal
        )}
        testCorrect={[correctDay]}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => (
          <Calendar
            month={month}
            year={year}
            dimens={{ width: dimens.width * 0.9, height: dimens.height }}
          />
        )}
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{translate.time[value]()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'at9',
  description: 'at9',
  keywords: ['Time', 'Months', 'Days', 'Calendar'],
  schema: z.object({
    month: monthSchema,
    selectedDay: daySchema,
    firstOrLast: z.enum(['first', 'last']),
    year: z.number().int().min(2016).max(2023)
  }),
  simpleGenerator: () => {
    const month = getRandomMonth();

    const [selectedDay] = getRandomSubArrayFromArray(dayNames, 1);

    const year = randomIntegerInclusive(2016, 2023);

    const firstOrLast = getRandomFromArray(['first', 'last'] as const);

    return { month, selectedDay, firstOrLast, year };
  },
  Component: props => {
    const {
      question: { month, selectedDay, firstOrLast, year },
      locale,
      translate
    } = props;

    const numberOfDaysInMonth = getNumberOfDaysInMonth(month);

    const correctDate =
      firstOrLast === 'first'
        ? getFirstDateOfDayInMonthByYear(year, month, selectedDay)
        : getLastDateOfDayInMonthByYear(year, month, selectedDay);

    const incorrectDateA =
      firstOrLast === 'first'
        ? getLastDateOfDayInMonthByYear(year, month, selectedDay)
        : getFirstDateOfDayInMonthByYear(year, month, selectedDay);

    const incorrectDateB =
      firstOrLast === 'first'
        ? correctDate === 1
          ? 2
          : 1
        : correctDate === numberOfDaysInMonth
        ? numberOfDaysInMonth - 1
        : numberOfDaysInMonth;

    const statements = [
      {
        value: correctDate
      },
      {
        value: incorrectDateA
      },
      {
        value: incorrectDateB
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          firstOrLast === 'first'
            ? translate.instructions.hereIsACalendarFromMonthYearSelectDateOfFirst(
                month,
                year,
                selectedDay
              )
            : translate.instructions.hereIsACalendarFromMonthYearSelectDateOfLast(
                month,
                year,
                selectedDay
              )
        }
        pdfTitle={
          firstOrLast === 'first'
            ? translate.instructions.hereIsACalendarFromMonthYearSelectDateOfFirstPDF(
                month,
                year,
                selectedDay
              )
            : translate.instructions.hereIsACalendarFromMonthYearSelectDateOfLastPDF(
                month,
                year,
                selectedDay
              )
        }
        testCorrect={[correctDate]}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => (
          <Calendar
            month={month}
            year={year}
            dimens={{ width: dimens.width * 0.9, height: dimens.height }}
          />
        )}
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{getNumeralOrdinal(value, locale, translate)}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aua',
  description: 'aua',
  keywords: ['Time', 'Years', 'Months', 'Weeks', 'Days', 'Calendar'],
  schema: z.object({
    daysA: z.number().int().min(1).max(14),
    daysB: z.number().int().min(7).max(20),
    weeks: z.number().int().min(5).max(9),
    years: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const daysA = randomIntegerInclusive(1, 14);
    const daysB = randomIntegerInclusive(7, 20);
    const weeks = randomIntegerInclusive(5, 9);
    const years = randomIntegerInclusive(1, 3);

    return {
      daysA,
      daysB,
      weeks,
      years
    };
  },
  Component: props => {
    const {
      question: { daysA, daysB, weeks, years },
      translate
    } = props;

    // Conversion to days
    const oneWeekInDays = 7;
    const twoWeeksInDays = 14;
    const oneMonthInDays = unit(1, 'months').to('days').toNumber();

    // Products
    const weeksProduct = unit(weeks, 'weeks').to('days').toNumber();
    const yearsToMonthsProduct = years * 12;
    const monthsProduct = unit(yearsToMonthsProduct, 'months').to('days').toNumber();
    const yearsProduct = unit(years, 'years').to('days').toNumber();

    // Statements
    const statements = shuffle(
      [
        {
          sentence: translate.answerSentences.xWeeksAnsYDays(1, daysA),
          answer: lessThanGreaterThanOrEqualTo(oneWeekInDays, daysA)
        },
        {
          sentence: translate.answerSentences.xDaysAnsYWeeks(daysB, 2),
          answer: lessThanGreaterThanOrEqualTo(daysB, twoWeeksInDays)
        },
        {
          sentence: translate.answerSentences.xWeeksAnsYMonths(weeks, 1),
          answer: lessThanGreaterThanOrEqualTo(weeksProduct, oneMonthInDays)
        },
        {
          sentence: translate.answerSentences.xMonthsAnsYYears(yearsToMonthsProduct, years),
          answer: lessThanGreaterThanOrEqualTo(monthsProduct, yearsProduct)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteStatements()}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatementsEachSymbolCanBeUsedMoreThanOnce()}
        items={['<', '>', '=']}
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={statements.map(statement => [statement.answer])}
        moveOrCopy="copy"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aub',
  description: 'aub',
  keywords: ['Time', 'Months', 'Days'],
  schema: z.object({
    name: nameSchema,
    correctMonth: monthSchema,
    incorrectMonthPreviousOrNext: z.enum(['previous', 'next'])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const correctMonth = getRandomMonth();

    const incorrectMonthPreviousOrNext = getRandomFromArray(['previous', 'next'] as const);

    return { name, correctMonth, incorrectMonthPreviousOrNext };
  },
  Component: props => {
    const {
      question: { name, correctMonth, incorrectMonthPreviousOrNext },
      locale,
      translate
    } = props;

    const daysInCorrectMonth = getNumberOfDaysInMonth(correctMonth);

    const incorrectDaysInCorrectMonth = daysInCorrectMonth === 30 ? 31 : 30;

    const indexOfCorrectMonth = monthNames.indexOf(correctMonth);

    const incorrectMonth = (() => {
      const indexOfIncorrectMonth =
        incorrectMonthPreviousOrNext === 'previous'
          ? indexOfCorrectMonth === 0
            ? 11
            : indexOfCorrectMonth - 1
          : indexOfCorrectMonth === 11
          ? 0
          : indexOfCorrectMonth + 1;
      return incorrectMonthPreviousOrNext === 'previous'
        ? monthNames[indexOfIncorrectMonth]
        : monthNames[indexOfIncorrectMonth];
    })();

    const daysInIncorrectMonth = getNumberOfDaysInMonth(incorrectMonth);

    const correctMonthOrdinalCorrectDaysString = getNumeralOrdinal(
      daysInCorrectMonth,
      locale,
      translate
    );
    const correctMonthOrdinalIncorrectDaysString = getNumeralOrdinal(
      incorrectDaysInCorrectMonth,
      locale,
      translate
    );
    const incorrectMonthOrdinalDaysString = getNumeralOrdinal(
      daysInIncorrectMonth,
      locale,
      translate
    );

    const statements = [
      {
        sentence: `${translate.time[correctMonth]()} ${correctMonthOrdinalCorrectDaysString}`,
        isCorrect: true
      },
      {
        sentence: `${translate.time[correctMonth]()} ${correctMonthOrdinalIncorrectDaysString}`,
        isCorrect: false
      },
      {
        sentence: `${translate.time[incorrectMonth]()} ${incorrectMonthOrdinalDaysString}`,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterDescribesTheirBirthday(name)}
        pdfTitle={translate.instructions.characterDescribesTheirBirthdayPDF(name)}
        testCorrect={statements.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={3}
        contentContainerStyle={{
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
            ]}
          >
            <SpeechBubble
              flickLocation="bottom-right"
              style={{ maxWidth: 0.45 * dimens.width, top: -20, zIndex: 1 }}
            >
              {translate.answerSentences.myBirthdayIsTheLastDayOfTheNthMonth(
                getWordOrdinal(indexOfCorrectMonth + 1, translate)
              )}
            </SpeechBubble>

            <View style={{ alignSelf: 'flex-end', marginRight: 20, marginVertical: 20, zIndex: 0 }}>
              {getCharacterHeadImage(name, dimens.height * 0.7, dimens.width / 3)}
            </View>
          </View>
        )}
        renderItems={shuffledStatements.map(({ sentence }) => ({
          value: sentence,
          component: <Text variant="WRN700">{sentence}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'YearsMonthsAndDays',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
