import { View, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { containAspectRatio } from '../../../theme/scaling';
import TextStructure from '../../molecules/TextStructure';
import { Path, Svg } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from 'common/src/utils/random';
import { colors, barModelColors } from '../../../theme/colors';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /** Available space */
  dimens: Dimens;
  /** Actual dimensions of shape (numbers used in question) */
  x: number;
  y: number;
  /** Labels for height/width arrows, if undefined, no arrow/label will be rendered */
  widthLabel?: string;
  heightLabel?: string;
  /** Fill color for shape */
  shapeColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  showEqualSidesLines?: boolean;
  /**
   * Optional styling for label text.
   */
  labelTextStyle?: StyleProp<TextStyle>;
  /**
   * Optional styling for the container of the top label.
   */
  labelTopContainerStyle?: StyleProp<ViewStyle>;
  /**
   * Optional prop to label all the sides, only works if the width and height labels are given.
   */
  labelAllSides?: boolean;
};

/**
 * Renders a rectangle (or square) based on the x (width) and y (height) values given.
 */
export const QuadrilateralWithDimens = ({
  dimens,
  x,
  y,
  widthLabel,
  heightLabel,
  labelAllSides = false,
  shapeColor,
  containerStyle,
  showEqualSidesLines,
  labelTextStyle,
  labelTopContainerStyle
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isDigital = displayMode === 'digital';
  // Scale up rectangle to fill available space with given aspect ratio
  const actualDimens = containAspectRatio(dimens, x / y);

  const fillColor =
    shapeColor ??
    (getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ x, y })
    }) as string);

  const styles = useStyles(fillColor, actualDimens, labelAllSides, isDigital);

  // Min width for arrows
  const widthArrowLength = actualDimens.width < 100 ? 100 : actualDimens.width;
  const heightArrowLength = actualDimens.height < 100 ? 100 : actualDimens.height;

  return (
    <View style={[styles.outerContainer, containerStyle]}>
      {widthLabel && (
        <View style={styles.widthContainer}>
          <TextStructure
            sentence={widthLabel}
            textStyle={[styles.labelText, labelTextStyle]}
            fractionTextStyle={[styles.labelText, labelTextStyle]}
            fractionContainerStyle={styles.labelFracContainer}
            fractionDividerStyle={styles.labelFracDivider}
            style={[{ top: 8 }, labelTopContainerStyle]}
          />
          {sideArrow(widthArrowLength, true, isDigital)}
        </View>
      )}

      <View style={{ flexDirection: 'row' }}>
        {heightLabel && (
          <View style={styles.heightContainer}>
            <TextStructure
              sentence={heightLabel}
              textStyle={[styles.labelText, labelTextStyle]}
              fractionTextStyle={[styles.labelText, labelTextStyle]}
              fractionContainerStyle={styles.labelFracContainer}
              fractionDividerStyle={styles.labelFracDivider}
            />
            {sideArrow(heightArrowLength, false, isDigital)}
          </View>
        )}

        {showEqualSidesLines ? (
          <View style={[styles.rectangle, { marginLeft: 25, position: 'relative' }]}>
            <View style={[styles.line, styles.verticalLine, { top: -20 }]} />
            <View style={[styles.line, styles.horizontalLine, { left: -20 }]} />
            <View style={[styles.line, styles.verticalLine, { bottom: -20 }]} />
            <View style={[styles.line, styles.horizontalLine, { right: -20 }]} />
          </View>
        ) : (
          <View style={[styles.rectangle]} />
        )}
        {heightLabel && labelAllSides && (
          <View
            style={[
              styles.heightContainer,
              { flexDirection: 'row-reverse', paddingLeft: isDigital ? 0 : 16 }
            ]}
          >
            <TextStructure
              sentence={heightLabel}
              textStyle={[styles.labelText, labelTextStyle]}
              fractionTextStyle={[styles.labelText, labelTextStyle]}
              fractionContainerStyle={styles.labelFracContainer}
              fractionDividerStyle={styles.labelFracDivider}
            />
            {sideArrow(heightArrowLength, false, isDigital)}
          </View>
        )}
      </View>

      {widthLabel && labelAllSides && (
        <View
          style={[
            styles.widthContainer,
            { flexDirection: 'column-reverse', paddingTop: isDigital ? 0 : 16 }
          ]}
        >
          <TextStructure
            sentence={widthLabel}
            textStyle={[styles.labelText, labelTextStyle]}
            fractionTextStyle={[styles.labelText, labelTextStyle]}
            fractionContainerStyle={styles.labelFracContainer}
            fractionDividerStyle={styles.labelFracDivider}
            style={[labelTopContainerStyle]}
          />
          {sideArrow(widthArrowLength, true, isDigital)}
        </View>
      )}
    </View>
  );
};

function useStyles(
  fillColor: string,
  actualDimens: Dimens,
  labelAllSides: boolean,
  isDigital: boolean
) {
  return useMemo(
    () =>
      StyleSheet.create({
        outerContainer: {
          flexDirection: 'column',
          alignSelf: 'flex-start',
          marginLeft: actualDimens.width < 320 ? 100 : 0,
          marginTop: 60
        },
        widthContainer: {
          width: actualDimens.width,
          minWidth: 100,
          alignItems: 'center',
          alignSelf: labelAllSides ? 'center' : 'flex-end'
        },
        heightContainer: {
          height: actualDimens.height,
          minHeight: 100,
          alignItems: 'center',
          flexDirection: 'row'
        },
        rectangle: {
          width: actualDimens.width,
          height: actualDimens.height,
          minWidth: 100,
          minHeight: 100,
          backgroundColor: fillColor,
          borderWidth: 2,
          borderColor: isDigital ? colors.prussianBlue : colors.black
        },
        line: {
          position: 'absolute',
          backgroundColor: isDigital ? colors.prussianBlue : colors.black
        },
        horizontalLine: {
          width: '20%',
          height: isDigital ? 2 : 4,
          top: '50%'
        },
        verticalLine: {
          height: '20%',
          width: isDigital ? 2 : 4,
          left: '50%'
        },
        labelText: {
          fontSize: isDigital ? 26 : 32
        },
        labelFracDivider: {
          minWidth: 30,
          marginVertical: 0
        },
        labelFracContainer: {
          height: 32
        }
      }),
    [fillColor, actualDimens, labelAllSides, isDigital]
  );
}

const sideArrow = (arrowLength: number, isHorizontal: boolean, isDigital: boolean) => {
  const [color, strokeWidth] = isDigital ? [colors.prussianBlue, 2] : [colors.black, 4];

  const [height, width, arrowHead1, arrowHead2] = isHorizontal
    ? isDigital
      ? [
          20,
          arrowLength,
          'M0,10 L10,5 L10,15 Z',
          `M${arrowLength},10 L${arrowLength - 10},5 L${arrowLength - 10},15 Z`
        ]
      : [
          40,
          arrowLength,
          'M0,10 L20,0 L20,20 Z',
          `M${arrowLength},10 L${arrowLength - 20},0 L${arrowLength - 20},20 Z`
        ]
    : isDigital
    ? [
        arrowLength,
        20,
        'M10,0 L5,10 L15,10 Z',
        `M10,${arrowLength} L5,${arrowLength - 10} L15,${arrowLength - 10} Z`
      ]
    : [
        arrowLength,
        40,
        'M10,0 L0,20 L20,20 Z',
        `M10,${arrowLength} L0,${arrowLength - 20} L20,${arrowLength - 20} Z`
      ];

  const arrowLine = isHorizontal ? `M5,10 L${arrowLength - 5},10` : `M10,5 L10,${arrowLength - 5}0`;

  return (
    <Svg width={width} height={height}>
      <Path d={arrowHead1} fill={color} />
      <Path d={arrowLine} stroke={color} strokeWidth={strokeWidth} />
      <Path d={arrowHead2} fill={color} />
    </Svg>
  );
};
