import { deduplicate, greatest } from 'common/src/utils/collections';
import { QuizSessionCacheEntry, type QuizSessionInfo } from '../storage/useQuizSessionStore';

export type ResumeStatus = {
  currentQuestionIndex: number;
  currentQuestionIncorrectAttempts: number;
  results: {
    stars: number | undefined;
  }[];
  /** The question parameters used on the current question, from either the questions or the latest result. */
  questionParams?: string;
};

/**
 * Get resume status (for resuming a Quiz) from quiz session information, specifically the list of questions in the
 * quiz session, and the question results submitted so far.
 *
 * The tricky thing here is figuring out the stars and current attempts based purely on the submitted results. There is
 * also an edge case where a question has multiple "attempt 1"s submitted against it (or "attempt 2"s etc.) which can
 * occur when the same quiz session is resumed on two devices. In that case we want to pick one attempt 1 from each
 * question (and same for attempt 2, etc.)
 *
 * Returns undefined if the quiz needs to be restarted.
 */
export function getResumeStatusFromQuizSession({
  id: quizSessionId,
  randomiseQuestionParameters,
  questions,
  questionResults = []
}: QuizSessionInfo): ResumeStatus | undefined {
  // The input data has come from the backend, so it could be dodgy in all sorts of ways.
  // First, distil it into a sensible data structure.
  const questionDataArray: {
    uid: string;
    parameters?: string;
    /**
     * Attempts indexed by their attempt number. The final entry is the final attempt.
     * Null entries indicate missing attempts.
     */
    attempts: ({ answer: string; isCorrect: boolean; parameters?: string } | null)[];
  }[] = [...questions]
    .sort((x, y) => x.displayOrder - y.displayOrder)
    .map(q => {
      let attemptsRaw = questionResults.filter(r => r.question === q.id);

      // Edge case: there could be multiple attempts for this question with the same attempt number.
      // If that happens, deduplicate by picking the attempt that comes latest in the array.
      // (`deduplicate` picks the earliest entry, so we reverse to pick the latest entry.)
      attemptsRaw.reverse();
      attemptsRaw = deduplicate(attemptsRaw, x => x.attemptNumber);

      if (attemptsRaw.length === 0) {
        return { uid: q.uid, parameters: q.parameters, attempts: [] };
      }

      const finalAttemptNumber = greatest(
        attemptsRaw,
        attempt => attempt.attemptNumber
      ).attemptNumber;
      const attempts: ({ answer: string; isCorrect: boolean; parameters?: string } | null)[] = [];

      for (let attemptIndex = 0; attemptIndex <= finalAttemptNumber - 1; attemptIndex++) {
        const attemptRaw = attemptsRaw.find(attempt => attempt.attemptNumber === attemptIndex + 1);

        attempts.push(
          attemptRaw
            ? {
                answer: attemptRaw.answer,
                isCorrect: attemptRaw.isCorrect,
                parameters: attemptRaw.parameters
                  ? JSON.stringify(attemptRaw.parameters satisfies Record<string, unknown>)
                  : undefined
              }
            : null
        );

        if (attemptRaw?.isCorrect) {
          // Edge case: There could be attempts after a correct answer. Ignore them.
          break;
        }
      }

      return { uid: q.uid, parameters: q.parameters, attempts };
    });

  // Stars of completed questions
  // Edge case: there might be answers for questions after the first uncompleted question. Ignore these.
  const starsArray: number[] = [];

  for (const q of questionDataArray) {
    const finalAttempt = q.attempts[q.attempts.length - 1]; // Might be undefined
    const isComplete = finalAttempt?.isCorrect || q.attempts.length === 3;

    if (isComplete) {
      if (finalAttempt?.isCorrect) {
        starsArray.push(4 - q.attempts.length); // 3 stars for attempt 1, 2 for attempt 2, 1 for attempt 3.
      } else {
        starsArray.push(0);
      }
    } else {
      // Found a non-completed question. Ignore subsequent answers.
      break;
    }
  }

  // There is a results object for each question, including the ones not attempted yet.
  const results = questionDataArray.map((_, index) => ({
    stars: index in starsArray ? starsArray[index] : undefined
  }));

  // Current question is the the first one with no stars
  const currentQuestionIndex = starsArray.length;
  if (currentQuestionIndex > questionDataArray.length - 1) {
    // Couldn't find a question with no stars. Quiz must be complete.
    console.warn(`Trying to resume a quiz which is already complete: ${quizSessionId}`);

    // Recover by just pretending they're starting the quiz again
    return undefined;
  }
  const currentQuestion = questionDataArray[currentQuestionIndex];
  const currentQuestionIncorrectAttempts = currentQuestion.attempts.length;

  // The params of the current question come from the question in "identical mode", or answers in "infinite mode".
  const currentQuestionParams = randomiseQuestionParameters
    ? currentQuestion.attempts[currentQuestion.attempts.length - 1]?.parameters
    : currentQuestion.parameters;

  return {
    currentQuestionIndex,
    currentQuestionIncorrectAttempts,
    results,
    questionParams: currentQuestionParams
  };
}

/**
 * Check the compatibility of the local session cache, and if so augment the resume status with:
 * - current question user answer
 * - current attempt time elapsed
 */
export function combineResumeStatusWithLocalSessionCache(
  resumeStatus: ResumeStatus,
  sessionCache: QuizSessionCacheEntry
): ResumeStatus & {
  currentAttemptTimeElapsed?: number | undefined;
  currentUserAnswer?: string | undefined;
} {
  // May need to restore state from the locally-cached session state.
  // However, we need to be extra careful here. If other user answers have come in since we made that cache,
  // then it's no longer valid and should be ignored.
  if (
    // Current question index and attempts agree
    sessionCache.currentQuestionIndex === resumeStatus.currentQuestionIndex &&
    sessionCache.currentQuestionIncorrectAttempts ===
      resumeStatus.currentQuestionIncorrectAttempts &&
    // Current question params agree
    (resumeStatus.questionParams === undefined ||
      resumeStatus.questionParams === sessionCache.questionParams)
  ) {
    // Session cache is compatible: Also use information from the session cache
    return {
      ...resumeStatus,
      questionParams: sessionCache.questionParams,
      currentAttemptTimeElapsed: sessionCache.currentAttemptTimeElapsed,
      currentUserAnswer: sessionCache.currentUserAnswer
    };
  } else {
    return resumeStatus;
  }
}
