import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import TextStructure from '../../../molecules/TextStructure';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import { DisplayMode } from '../../../../contexts/displayMode';
import { withStateHOC } from '../../../../stateTree';
import { SetState } from '../../../../utils/react';
import { countRange, filledArray } from '../../../../utils/collections';
import { Selectable } from '../../../atoms/Selectable';
import { noop } from '../../../../utils/flowControl';

export type CompleteNumberLine = string[];
export type FreeNumberLine = (number | null)[];

type Props = {
  /**
   * @param tickValues - Array of arrays representing number line tick values/labels.
   * The length of tickValues determines number of number lines
   */
  tickValues: string[][];
  /**
   * @param selectableIndexes - The indexes of the selectable tick labels. Defaults to all of them
   */
  selectableIndexes?: number[][];
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  /**
   * @param userAnswer - State to hold the user input answer.
   */
  userAnswer: number[][];
  setUserAnswer?: SetState<number[][] | null>;
  /** Custom scale factor for text, defaults to 2.5 */
  scaleFactor?: number;
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
  /**
   * Custom vertical padding for text under ticks. Optional prop, defaults to 8.
   */
  textVerticalPad?: number;
  multiSelect?: boolean;
};

const SelectableNumberLine = ({
  tickValues,
  selectableIndexes: selectableIndexesProp,
  dimens: { width, height },
  userAnswer = [],
  setUserAnswer = noop,
  scaleFactor,
  customFontSize,
  textVerticalPad = 8,
  multiSelect = false
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  // default to all tick values being selectables
  const selectableIndexes =
    selectableIndexesProp ?? tickValues.map(line => countRange(line.length));

  // SVG Properties
  const lineColor = isPdf ? colors.black : colors.prussianBlue;
  const lineThickness = isPdf ? 4 : 2;

  const horizontalPad = 60;
  const pdfHorizontalPad = 140;
  const lineWidth = width - (isPdf ? horizontalPad + pdfHorizontalPad : 2 * horizontalPad);
  const tickHeight = isPdf ? 80 : 40;

  const SELECTABLE_HEIGHT = displayMode === 'digital' ? 120 : 170;
  const SELECTABLE_WIDTH = displayMode === 'digital' ? 120 : 170;

  const centerLineYBase = tickHeight / 2;

  const startingTickX = horizontalPad;

  const styles = useStyles(width, height, SELECTABLE_WIDTH);
  const fontSize =
    customFontSize ??
    scaleNumberLineFontSize(
      tickValues.map(tick => {
        return tick?.toLocaleString() ?? '';
      }),
      lineWidth,
      displayMode,
      scaleFactor
    );

  // Center line
  const centerLines = (
    <Line
      x1={horizontalPad}
      y1={centerLineYBase}
      x2={width - (isPdf ? pdfHorizontalPad : horizontalPad)}
      y2={centerLineYBase}
      stroke={lineColor}
      strokeWidth={lineThickness}
    />
  );

  // Ticks
  const ticks = (tickValue: string[], lineId: number) => {
    const tickSpacing = lineWidth / (tickValue.length - 1);
    return tickValue.map((_tick, index) => {
      const tickX = startingTickX + index * tickSpacing;
      return (
        <Line
          key={`line-${lineId}-tick${index}`}
          x1={tickX}
          y1={centerLineYBase - tickHeight / 2}
          x2={tickX}
          y2={centerLineYBase + tickHeight / 2}
          stroke={lineColor}
          strokeWidth={lineThickness}
        />
      );
    });
  };

  const emptyState: number[][] = filledArray([], tickValues.length);

  // Tick Numbers
  const numberComponents = (tickValue: string[], lineIndex: number) => {
    return tickValue.map((tick, index) => {
      const tickSpacing = lineWidth / (tickValue.length - 1);
      const tickX = startingTickX + index * tickSpacing;
      // selectable label
      if (selectableIndexes[lineIndex].includes(index)) {
        return (
          <View
            key={'line' + lineIndex + 'label' + index}
            style={[
              styles.completeNumberLineLabel,
              {
                left: tickX - SELECTABLE_WIDTH / 2,
                top: centerLineYBase + tickHeight / 2 + textVerticalPad
              }
            ]}
          >
            <Selectable
              style={{
                height: SELECTABLE_HEIGHT,
                width: SELECTABLE_WIDTH,
                borderRadius: 24
              }}
              textVariant="WRN700"
              textSizeAutoScaleGroup={'numbers'}
              selected={userAnswer[lineIndex].includes(index)}
              markschemeTickPosition={{ top: 0, right: 0 }}
              toggleSelected={() => {
                let userAnswerCopy = userAnswer;
                if (userAnswer[lineIndex].includes(index)) {
                  userAnswerCopy[lineIndex] = userAnswerCopy[lineIndex].filter(
                    answerValue => answerValue !== index
                  );
                } else {
                  if (multiSelect) {
                    userAnswerCopy[lineIndex] = [...userAnswerCopy[lineIndex], index];
                  } else {
                    const newState = emptyState;
                    newState[lineIndex] = [...newState[lineIndex], index];
                    userAnswerCopy = newState;
                  }
                }
                setUserAnswer(userAnswerCopy);
              }}
            >
              <TextStructure
                sentence={tick}
                textStyle={[styles.text, { fontSize }]}
                fractionTextStyle={{ fontSize }}
                fractionContainerStyle={{ height: 48 }}
              />
            </Selectable>
          </View>
        );
      } else {
        return (
          <View
            key={'tickNum' + index}
            style={[
              styles.completeNumberLineLabel,
              {
                left: tickX - SELECTABLE_WIDTH / 2,
                top: centerLineYBase + tickHeight / 2 + textVerticalPad
              }
            ]}
          >
            <TextStructure
              sentence={tick}
              textStyle={[styles.text, { fontSize }]}
              style={{ flexWrap: 'nowrap' }}
              fractionTextStyle={{ fontSize }}
              fractionContainerStyle={{ height: 48 }}
            />
          </View>
        );
      }
    });
  };

  return (
    <View style={styles.container}>
      {tickValues.map((tickValue, idx) => {
        return (
          <View key={idx} style={{ width: width, height: height / tickValues.length }}>
            <Svg
              width={width}
              height={height / tickValues.length}
              viewBox={`0 0 ${width} ${height / tickValues.length}`}
              pointerEvents={'none'}
            >
              {centerLines}
              {ticks(tickValue, idx)}
            </Svg>
            {numberComponents(tickValue, idx)}
          </View>
        );
      })}
    </View>
  );
};

function useStyles(width: number, height: number, SELECTABLE_WIDTH: number) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height
        },
        text: {
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          overflow: 'visible'
        },
        completeNumberLineLabel: {
          position: 'absolute',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: SELECTABLE_WIDTH
        }
      }),
    [width, height, SELECTABLE_WIDTH]
  );
}

export default SelectableNumberLine;

export const SelectableNumberLineWithState = withStateHOC(SelectableNumberLine, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray([], props.tickValues.length),
    testComplete: userAnswer => userAnswer.some(val => val.length > 0)
  })
});
