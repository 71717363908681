import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TwoWayTable from '../../../../components/question/representations/TwoWayTable';
import {
  arrayHasNoDuplicates,
  range,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSA',
  description: 'aSA',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    showings: z.array(z.number().int().min(1).max(10)).length(3),
    numberOfAdultsArray: z.array(z.number().int().min(10).max(50)).length(3),
    numberOfChildrenArray: z.array(z.number().int().min(30).max(70)).length(3),
    isAdults: z.boolean(),
    showingTitleIndex: numberEnum([0, 1, 2])
  }),
  simpleGenerator: () => {
    const showingTimeOne = randomIntegerInclusive(1, 3);
    const showingTimeTwo = randomIntegerInclusive(showingTimeOne + 1, 6);
    const showingTimeThree = randomIntegerInclusive(showingTimeTwo + 1, 10);
    const [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree] =
      randomUniqueIntegersInclusive(10, 50, 3);
    const [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree] =
      randomUniqueIntegersInclusive(30, 70, 3);

    const isAdults = getRandomBoolean();
    const showingTitleIndex = getRandomFromArray([0, 1, 2] as const);

    const showings = [showingTimeOne, showingTimeTwo, showingTimeThree];
    const numberOfAdultsArray = [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree];
    const numberOfChildrenArray = [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree];

    return {
      showings,
      numberOfAdultsArray,
      numberOfChildrenArray,
      isAdults,
      showingTitleIndex
    };
  },
  Component: ({
    question: { showings, numberOfAdultsArray, numberOfChildrenArray, isAdults, showingTitleIndex },
    translate
  }) => {
    const answers = isAdults ? numberOfAdultsArray : numberOfChildrenArray;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXWatchedTheYPmShowing(
          isAdults ? translate.keywords.Adults() : translate.keywords.Children(),
          showings[showingTitleIndex]
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answers[showingTitleIndex].toString()]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={[translate.keywords.Adults(), translate.keywords.Children()]}
            leftHeaders={showings.map(
              showing => `${translate.time.numPm(showing)} ${translate.misc.showings()}`
            )}
            items={[
              [numberOfAdultsArray[0].toLocaleString(), numberOfChildrenArray[0].toLocaleString()],
              [numberOfAdultsArray[1].toLocaleString(), numberOfChildrenArray[1].toLocaleString()],
              [numberOfAdultsArray[2].toLocaleString(), numberOfChildrenArray[2].toLocaleString()]
            ]}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSB',
  description: 'aSB',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    showings: z.array(z.number().int().min(1).max(10)).length(3),
    numberOfAdultsArray: z.array(z.number().int().min(10).max(50)).length(3),
    numberOfChildrenArray: z.array(z.number().int().min(30).max(70)).length(3),
    isAdults: z.boolean()
  }),
  simpleGenerator: () => {
    const showingTimeOne = randomIntegerInclusive(1, 3);
    const showingTimeTwo = randomIntegerInclusive(showingTimeOne + 1, 6);
    const showingTimeThree = randomIntegerInclusive(showingTimeTwo + 1, 10);
    const [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree] =
      randomUniqueIntegersInclusive(10, 50, 3);
    const [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree] =
      randomUniqueIntegersInclusive(30, 70, 3);

    const isAdults = getRandomBoolean();

    const showings = [showingTimeOne, showingTimeTwo, showingTimeThree];
    const numberOfAdultsArray = [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree];
    const numberOfChildrenArray = [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree];

    return {
      showings,
      numberOfAdultsArray,
      numberOfChildrenArray,
      isAdults
    };
  },
  Component: ({
    question: { showings, numberOfAdultsArray, numberOfChildrenArray, isAdults },
    translate
  }) => {
    const answers = isAdults ? numberOfAdultsArray : numberOfChildrenArray;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXWatchedTheFilmThatDay(
          isAdults ? translate.keywords.Adults() : translate.keywords.Children()
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[sumNumberArray(answers).toString()]}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={[translate.keywords.Adults(), translate.keywords.Children()]}
            leftHeaders={showings.map(
              showing => `${translate.time.numPm(showing)} ${translate.misc.showings()}`
            )}
            items={[
              [numberOfAdultsArray[0].toLocaleString(), numberOfChildrenArray[0].toLocaleString()],
              [numberOfAdultsArray[1].toLocaleString(), numberOfChildrenArray[1].toLocaleString()],
              [numberOfAdultsArray[2].toLocaleString(), numberOfChildrenArray[2].toLocaleString()]
            ]}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aSC',
  description: 'aSC',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    showings: z.array(z.number().int().min(1).max(10)).length(3),
    numberOfAdultsArray: z.array(z.number().int().min(10).max(50)).length(3),
    numberOfChildrenArray: z.array(z.number().int().min(30).max(70)).length(3),
    showingTitleIndex: z.number().int().min(0).max(2),
    seatsInACinema: z.number().int().min(100).max(180).step(10)
  }),
  simpleGenerator: () => {
    const showingTimeOne = randomIntegerInclusive(1, 3);
    const showingTimeTwo = randomIntegerInclusive(showingTimeOne + 1, 6);
    const showingTimeThree = randomIntegerInclusive(showingTimeTwo + 1, 10);
    const [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree] =
      randomUniqueIntegersInclusive(10, 50, 3);
    const [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree] =
      randomUniqueIntegersInclusive(30, 70, 3);

    const showingTitleIndex = getRandomFromArray([0, 1, 2]);

    const seatsInACinema = randomIntegerInclusiveStep(100, 180, 10);

    const showings = [showingTimeOne, showingTimeTwo, showingTimeThree];
    const numberOfAdultsArray = [numberOfAdultsOne, numberOfAdultsTwo, numberOfAdultsThree];
    const numberOfChildrenArray = [numberOfChildrenOne, numberOfChildrenTwo, numberOfChildrenThree];

    return {
      showings,
      numberOfAdultsArray,
      numberOfChildrenArray,
      showingTitleIndex,
      seatsInACinema
    };
  },
  Component: ({
    question: {
      showings,
      numberOfAdultsArray,
      numberOfChildrenArray,
      showingTitleIndex,
      seatsInACinema
    },
    translate
  }) => {
    const answer =
      seatsInACinema -
      (numberOfAdultsArray[showingTitleIndex] + numberOfChildrenArray[showingTitleIndex]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreXSeatsInACinemaHowManyEmptySeatsAtYShowing(
          seatsInACinema,
          showings[showingTitleIndex]
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={[translate.keywords.Adults(), translate.keywords.Children()]}
            leftHeaders={showings.map(
              showing => `${translate.time.numPm(showing)} ${translate.misc.showings()}`
            )}
            items={[
              [numberOfAdultsArray[0].toLocaleString(), numberOfChildrenArray[0].toLocaleString()],
              [numberOfAdultsArray[1].toLocaleString(), numberOfChildrenArray[1].toLocaleString()],
              [numberOfAdultsArray[2].toLocaleString(), numberOfChildrenArray[2].toLocaleString()]
            ]}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aSD',
  description: 'aSD',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    tableValues: z
      .array(z.number().min(1).max(20))
      .length(8)
      .refine(arrayHasNoDuplicates, 'all values in table are unique'),
    isLeastPopular: z.boolean(),
    isKS1: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const tableValues = randomUniqueIntegersInclusive(1, 20, 8);

    const isLeastPopular = getRandomBoolean();
    const isKS1 = getRandomBoolean();

    return {
      tableValues,
      isKS1,
      isLeastPopular
    };
  },
  Component: props => {
    const {
      question: { tableValues, isKS1, isLeastPopular },
      translate
    } = props;

    const numberOfKS1Array = tableValues.slice(0, 4);
    const numberOfKS2Array = tableValues.slice(4);

    const selectables = shuffle(
      [
        ['A', translate.transport.walk()],
        ['B', translate.transport.car()],
        ['C', translate.transport.bus()],
        ['D', translate.transport.cycle()]
      ],
      { random: seededRandom(props.question) }
    );

    const options = [
      translate.transport.walk(),
      translate.transport.car(),
      translate.transport.bus(),
      translate.transport.cycle()
    ];

    const findByPopularity = (popularity: 'least' | 'most') => {
      const walking = isKS1 ? numberOfKS1Array[0] : numberOfKS2Array[0];
      const car = isKS1 ? numberOfKS1Array[1] : numberOfKS2Array[1];
      const bus = isKS1 ? numberOfKS1Array[2] : numberOfKS2Array[2];
      const cycle = isKS1 ? numberOfKS1Array[3] : numberOfKS2Array[3];

      const leastPopular = sortNumberArray(
        [walking, car, bus, cycle],
        popularity === 'least' ? 'ascending' : 'descending'
      )[0];

      const answer = (() => {
        switch (leastPopular) {
          case isKS1 ? numberOfKS1Array[0] : numberOfKS2Array[0]:
            return 'A';
          case isKS1 ? numberOfKS1Array[1] : numberOfKS2Array[1]:
            return 'B';
          case isKS1 ? numberOfKS1Array[2] : numberOfKS2Array[2]:
            return 'C';
          case isKS1 ? numberOfKS1Array[3] : numberOfKS2Array[3]:
            return 'D';
          default:
            return '';
        }
      })();

      return answer;
    };

    const answer = isLeastPopular ? findByPopularity('least') : findByPopularity('most');

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheXPopularMethodOfGettingToSchoolForKeyStageY(
          isLeastPopular ? translate.keywords.Least() : translate.keywords.Most(),
          isKS1 ? 1 : 2
        )}
        pdfTitle={translate.instructions.circleTheXPopularMethodOfGettingToSchoolForKeyStageY(
          isLeastPopular ? translate.keywords.Least() : translate.keywords.Most(),
          isKS1 ? 1 : 2
        )}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[answer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TwoWayTable
                style={dimens}
                topHeaders={['KS1', 'KS2']}
                leftHeaders={options}
                items={[
                  [numberOfKS1Array[0].toLocaleString(), numberOfKS2Array[0].toLocaleString()],
                  [numberOfKS1Array[1].toLocaleString(), numberOfKS2Array[1].toLocaleString()],
                  [numberOfKS1Array[2].toLocaleString(), numberOfKS2Array[2].toLocaleString()],
                  [numberOfKS1Array[3].toLocaleString(), numberOfKS2Array[3].toLocaleString()]
                ]}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aSD2',
  description: 'aSD',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    tableValues: z
      .array(z.number().min(1).max(20))
      .length(8)
      .refine(arrayHasNoDuplicates, 'all values in table are unique')
      .refine(
        val =>
          sumNumberArray(val.filter((_val, i) => i < 4)) <= 30 &&
          sumNumberArray(val.filter((_val, i) => i > 3)) <= 30,
        'each column show sum to less than 31'
      ),
    isLeastPopular: z.boolean(),
    isYear1: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const tableValues = rejectionSample(
      () => randomUniqueIntegersInclusive(1, 20, 8),
      val =>
        sumNumberArray(val.filter((_val, i) => i < 4)) <= 30 &&
        sumNumberArray(val.filter((_val, i) => i > 3)) <= 30
    );

    const isLeastPopular = getRandomBoolean();
    const isYear1 = getRandomBoolean();

    return {
      tableValues,
      isYear1,
      isLeastPopular
    };
  },
  Component: props => {
    const {
      question: { tableValues, isYear1, isLeastPopular },
      translate
    } = props;

    const numberOfYear1Array = tableValues.slice(0, 4);
    const numberOfYear2Array = tableValues.slice(4);

    const selectables = shuffle(
      [
        ['A', translate.transport.walk()],
        ['B', translate.transport.car()],
        ['C', translate.transport.bus()],
        ['D', translate.transport.cycle()]
      ],
      { random: seededRandom(props.question) }
    );

    const options = [
      translate.transport.walk(),
      translate.transport.car(),
      translate.transport.bus(),
      translate.transport.cycle()
    ];

    const findByPopularity = (popularity: 'least' | 'most') => {
      const walking = isYear1 ? numberOfYear1Array[0] : numberOfYear2Array[0];
      const car = isYear1 ? numberOfYear1Array[1] : numberOfYear2Array[1];
      const bus = isYear1 ? numberOfYear1Array[2] : numberOfYear2Array[2];
      const cycle = isYear1 ? numberOfYear1Array[3] : numberOfYear2Array[3];

      const leastPopular = sortNumberArray(
        [walking, car, bus, cycle],
        popularity === 'least' ? 'ascending' : 'descending'
      )[0];

      const answer = (() => {
        switch (leastPopular) {
          case isYear1 ? numberOfYear1Array[0] : numberOfYear2Array[0]:
            return 'A';
          case isYear1 ? numberOfYear1Array[1] : numberOfYear2Array[1]:
            return 'B';
          case isYear1 ? numberOfYear1Array[2] : numberOfYear2Array[2]:
            return 'C';
          case isYear1 ? numberOfYear1Array[3] : numberOfYear2Array[3]:
            return 'D';
          default:
            return '';
        }
      })();

      return answer;
    };

    const answer = isLeastPopular ? findByPopularity('least') : findByPopularity('most');

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheXPopularMethodOfGettingToSchoolForYearY(
          isLeastPopular ? translate.keywords.Least() : translate.keywords.Most(),
          isYear1 ? 1 : 2
        )}
        pdfTitle={translate.instructions.circleTheXPopularMethodOfGettingToSchoolForYearY(
          isLeastPopular ? translate.keywords.Least() : translate.keywords.Most(),
          isYear1 ? 1 : 2
        )}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[answer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TwoWayTable
                style={dimens}
                topHeaders={[
                  translate.misc.yearN((1).toLocaleString()),
                  translate.misc.yearN((2).toLocaleString())
                ]}
                leftHeaders={options}
                items={[
                  [numberOfYear1Array[0].toLocaleString(), numberOfYear2Array[0].toLocaleString()],
                  [numberOfYear1Array[1].toLocaleString(), numberOfYear2Array[1].toLocaleString()],
                  [numberOfYear1Array[2].toLocaleString(), numberOfYear2Array[2].toLocaleString()],
                  [numberOfYear1Array[3].toLocaleString(), numberOfYear2Array[3].toLocaleString()]
                ]}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSE',
  description: 'aSE',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    tableValues: z.array(z.number().min(1).max(20)).length(8),
    isKS1: z.boolean()
  }),
  simpleGenerator: () => {
    const tableValues = range(1, 8).map(() => randomIntegerInclusive(1, 20));

    const isKS1 = getRandomBoolean();

    return {
      tableValues,
      isKS1
    };
  },
  Component: ({ question: { tableValues, isKS1 }, translate }) => {
    const numberOfKS1Array = tableValues.slice(0, 4);
    const numberOfKS2Array = tableValues.slice(4);

    const answer = isKS1
      ? sumNumberArray([
          numberOfKS1Array[0],
          numberOfKS1Array[1],
          numberOfKS1Array[2],
          numberOfKS1Array[3]
        ])
      : sumNumberArray([
          numberOfKS2Array[0],
          numberOfKS2Array[1],
          numberOfKS2Array[2],
          numberOfKS2Array[3]
        ]);

    const options = [
      translate.transport.walk(),
      translate.transport.car(),
      translate.transport.bus(),
      translate.transport.cycle()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyChildrenAreThereInTotalInYearX(isKS1 ? 1 : 2)}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={[
              translate.misc.yearN((1).toLocaleString()),
              translate.misc.yearN((2).toLocaleString())
            ]}
            leftHeaders={options}
            items={[
              [numberOfKS1Array[0].toLocaleString(), numberOfKS2Array[0].toLocaleString()],
              [numberOfKS1Array[1].toLocaleString(), numberOfKS2Array[1].toLocaleString()],
              [numberOfKS1Array[2].toLocaleString(), numberOfKS2Array[2].toLocaleString()],
              [numberOfKS1Array[3].toLocaleString(), numberOfKS2Array[3].toLocaleString()]
            ]}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSF',
  description: 'aSF',
  keywords: ['Two-way tables', 'Interpret'],
  schema: z.object({
    tableValues: z.array(z.number().min(1).max(20)).length(8),
    optionIndexOne: numberEnum([0, 1, 2, 3]),
    optionIndexTwo: numberEnum([0, 1, 2, 3])
  }),
  simpleGenerator: () => {
    const tableValues = range(1, 8).map(() => randomIntegerInclusive(1, 20));

    const [optionIndexOne, optionIndexTwo] = getRandomSubArrayFromArray([0, 1, 2, 3] as const, 2);

    return {
      tableValues,
      optionIndexOne,
      optionIndexTwo
    };
  },
  Component: ({ question: { tableValues, optionIndexOne, optionIndexTwo }, translate }) => {
    const numberOfKS1Array = tableValues.slice(0, 4);
    const numberOfKS2Array = tableValues.slice(4);

    const options = [
      translate.transport.walk(),
      translate.transport.car(),
      translate.transport.bus(),
      translate.transport.cycle()
    ];

    const answer =
      numberOfKS1Array[optionIndexOne] +
      numberOfKS2Array[optionIndexOne] +
      numberOfKS1Array[optionIndexTwo] +
      numberOfKS2Array[optionIndexTwo];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyChildrenInTotalTravelledToSchoolByXandByY(
          options[optionIndexOne] === 'Walk'
            ? translate.transport.walking()
            : options[optionIndexOne],
          options[optionIndexTwo] === 'Walk'
            ? translate.transport.walking()
            : options[optionIndexTwo]
        )}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <TwoWayTable
            style={dimens}
            topHeaders={['KS1', 'KS2']}
            leftHeaders={options}
            items={[
              [numberOfKS1Array[0].toLocaleString(), numberOfKS2Array[0].toLocaleString()],
              [numberOfKS1Array[1].toLocaleString(), numberOfKS2Array[1].toLocaleString()],
              [numberOfKS1Array[2].toLocaleString(), numberOfKS2Array[2].toLocaleString()],
              [numberOfKS1Array[3].toLocaleString(), numberOfKS2Array[3].toLocaleString()]
            ]}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwoWayTables',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
