import Autumn from './Autumn';
import Spring from './Spring';
import Summer from './Summer';
import { newYearContent } from '../Year';

const Year = newYearContent({
  year: 'Year3',
  terms: [Autumn, Spring, Summer]
});
export default Year;
