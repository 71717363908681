import { z, ZodLiteral } from 'zod';

/**
 * Convenience function for generating a schema for a single number matching a list of valid numbers.
 */
export function numberEnum<U extends number, T extends readonly U[]>(
  numbers: T
): z.ZodEffects<z.ZodNumber, T[number], number> {
  const checkNumberInEnum = (x: number): x is T[number] => numbers.includes(x as T[number]);
  return z.number().refine(checkNumberInEnum, `Number must be one of ${numbers}`);
}

/**
 * Convenience function to generate a Zod Enum from an object's keys.
 */
export function zodEnumFromObjKeys<T extends string>(
  obj: Record<T, unknown>
): z.ZodEnum<[T, ...T[]]> {
  const [firstKey, ...otherKeys] = Object.keys(obj) as T[];
  return z.enum([firstKey, ...otherKeys]);
}

/**
 * Convenience schemas for handling fractions in a question schema.
 * Example Usage (2 cases):
 * 1: z.object({
 *      fractionOptions: fractionSchema().array().length(6),
 *      exampleFraction: fractionSchema(z.number().int().min(1).max(30), z.number().int().min(1).max(40))
 *    })
 */
export function fractionSchema<T = number>(
  numeratorSchema: z.ZodNumber | ZodLiteral<T> = z.number().int(),
  denominatorSchema: z.ZodNumber | ZodLiteral<T> = z.number().int().min(1)
) {
  return z.tuple([numeratorSchema, denominatorSchema]);
}

export function mixedFractionSchema<T = number>(
  wholeSchema: z.ZodNumber | ZodLiteral<T> = z.number().int(),
  numeratorSchema: z.ZodNumber | ZodLiteral<T> = z.number().int(),
  denominatorSchema: z.ZodNumber | ZodLiteral<T> = z.number().int().min(1)
) {
  return z.tuple([wholeSchema, numeratorSchema, denominatorSchema]);
}

/** Usage: `z.number().nullable().transform(nullToUndefined)` */
export const nullToUndefined = <T>(x: T | null): T | undefined => {
  return x ?? undefined;
};
