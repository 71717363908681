import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  range,
  rangeAsString
} from '../../../../utils/collections';
import { parseToSUB } from '../../../../utils/parse';
import { SequenceBoxesWithState } from '../../../../components/question/representations/SequenceBoxes';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCI',
  description: 'aCI',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(-10).max(-2).multipleOf(2),
    missingIdxes: z.array(z.number().int().min(0).max(5)).length(2)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(-10, -2, 2);
    const missingIdxes = randomUniqueIntegersInclusive(0, 5, 2);

    return { startingNumber, missingIdxes };
  },
  Component: props => {
    const {
      question: { startingNumber, missingIdxes },
      translate
    } = props;

    const interval = 2;
    const endNumber = startingNumber + 10;

    const tickValues = rangeAsString(startingNumber, endNumber, interval, true).map(value =>
      parseToSUB(value)
    );

    const answers = [...missingIdxes].sort().map(idx => parseToSUB(tickValues[idx].toString()));

    missingIdxes.forEach(idx => (tickValues[idx] = '<ans/>'));

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        extraSymbol={'minus'}
        testCorrect={answers}
        tickValues={tickValues}
        answerPositions={'bottom'}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aCJ',
  description: 'aCJ',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(-50).max(-20).multipleOf(5),
    missingIdxes: z.array(z.number().int().min(0).max(10)).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(-50, -20, 5);

    const zeroIndex = Math.abs(startingNumber / 5);

    const missingIdxes: number[] = [];

    countRange(3).forEach(_ =>
      missingIdxes.push(
        randomIntegerInclusive(0, 10, {
          constraint: x =>
            arrayHasNoDuplicates([...missingIdxes, x]) &&
            // Missing numbers should have no more than 1 positive number
            [...missingIdxes, x].filter(i => i >= zeroIndex).length <= 1
        })
      )
    );

    return { startingNumber, missingIdxes };
  },
  Component: props => {
    const {
      question: { startingNumber, missingIdxes },
      translate
    } = props;

    const interval = 5;
    const endNumber = startingNumber + 50;

    const tickValues = rangeAsString(startingNumber, endNumber, interval, true).map(value =>
      parseToSUB(value)
    );

    const answers = [...missingIdxes]
      .sort((a, b) => a - b)
      .map(idx => parseToSUB(tickValues[idx].toString()));

    missingIdxes.forEach(idx => (tickValues[idx] = '<ans/>'));

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        extraSymbol={'minus'}
        testCorrect={answers}
        tickValues={tickValues}
        answerPositions={'bottom'}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCK',
  description: 'aCK',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(-40).max(-16).multipleOf(4),
    missingIdxes: z.array(z.number().int().min(0).max(10)).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(-40, -16, 4);

    const zeroIndex = Math.abs(startingNumber / 4);

    const missingIdxes: number[] = [];

    countRange(3).forEach(_ =>
      missingIdxes.push(
        randomIntegerInclusive(0, 10, {
          constraint: x =>
            arrayHasNoDuplicates([...missingIdxes, x]) &&
            // Missing numbers should have no more than 1 positive number
            [...missingIdxes, x].filter(i => i >= zeroIndex).length <= 1
        })
      )
    );

    return { startingNumber, missingIdxes };
  },
  Component: props => {
    const {
      question: { startingNumber, missingIdxes },
      translate
    } = props;

    const interval = 4;
    const endNumber = startingNumber + 40;

    const tickValues = rangeAsString(startingNumber, endNumber, interval, true).map(value =>
      parseToSUB(value)
    );

    const answers = [...missingIdxes]
      .sort((a, b) => a - b)
      .map(idx => parseToSUB(tickValues[idx].toString()));

    missingIdxes.forEach(idx => (tickValues[idx] = '<ans/>'));

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        extraSymbol={'minus'}
        testCorrect={answers}
        tickValues={tickValues}
        answerPositions={'bottom'}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCL',
  description: 'aCL',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    endNumber: z.number().int().min(0).max(9).multipleOf(3)
  }),
  simpleGenerator: () => {
    const endNumber = randomIntegerInclusiveStep(0, 9, 3);

    return { endNumber };
  },
  Component: props => {
    const {
      question: { endNumber },
      translate
    } = props;

    const interval = 3;
    const startingNumber = endNumber - 30;

    const tickValues = range(startingNumber, endNumber, interval).map((x, i) =>
      [0, 1, 10].includes(i) ? parseToSUB(x.toLocaleString()) : i === 6 ? '<ans/>' : ''
    );

    const answer = (endNumber - 12).toString();

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        extraSymbol={'minus'}
        testCorrect={[parseToSUB(answer)]}
        tickValues={tickValues}
        answerPositions="bottom"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCM',
  description: 'aCM',
  keywords: ['Negative numbers', 'Number track'],
  schema: z.object({
    startingNumber: z.number().int().min(-40).max(10)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(-40, 10);

    return { startingNumber };
  },
  Component: ({ question, translate, displayMode }) => {
    const { startingNumber } = question;

    const interval = 5;

    const numberArray = [
      ...rangeAsString(startingNumber, startingNumber - interval * 2, interval, true).map(value =>
        parseToSUB(value)
      ),
      ...countRange(4).map(_ => '<ans/>')
    ];
    const answers = rangeAsString(
      startingNumber - interval * 3,
      startingNumber - interval * 6,
      interval
    );

    return (
      <QF3Content
        title={translate.instructions.workOutTheNextFourNumbersInTheSequence()}
        actionPanelVariant={'bottomTall'}
        inputVariant={'wide'}
        inputType="numpad"
        extraSymbol={'minus'}
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <SequenceBoxesWithState
              id="seq1"
              boxes={[numberArray]}
              dimens={dimens}
              testCorrect={userAnswer =>
                arraysHaveSameContents(
                  answers.map(el => parseToSUB(el)),
                  userAnswer[0]
                )
              }
              defaultState={
                displayMode === 'markscheme' ? [answers.map(el => el.toLocaleString())] : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aCN',
  description: 'aCN',
  keywords: ['Negative numbers', 'Number track'],
  schema: z.object({
    startingNumber: z
      .number()
      .int()
      .min(1)
      .max(7)
      .refine(x => x % 2 !== 0, 'starting number must be odd')
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(1, 7, 2);

    return { startingNumber };
  },
  Component: ({ question, translate, displayMode }) => {
    const { startingNumber } = question;

    const interval = 2;

    const numberArray = [
      ...rangeAsString(startingNumber, startingNumber - interval * 2, interval, true).map(value =>
        parseToSUB(value)
      ),
      ...countRange(4).map(_ => '<ans/>')
    ];

    const answers = rangeAsString(
      startingNumber - interval * 3,
      startingNumber - interval * 6,
      interval
    );

    return (
      <QF3Content
        title={translate.instructions.workOutTheNextFourNumbersInTheSequence()}
        actionPanelVariant={'bottomTall'}
        inputVariant={'wide'}
        inputType="numpad"
        extraSymbol={'minus'}
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <SequenceBoxesWithState
              id="seq1"
              boxes={[numberArray]}
              dimens={dimens}
              testCorrect={userAnswer =>
                arraysHaveSameContents(
                  answers.map(el => parseToSUB(el)),
                  userAnswer[0]
                )
              }
              defaultState={
                displayMode === 'markscheme' ? [answers.map(el => el.toLocaleString())] : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountThroughZeroInMultiples',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
