import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { aDE } from '../../../Year 5/Summer/Volume/1CubicCentimetres';
import { aDL } from '../../../Year 5/Summer/Volume/2CompareVolume';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import {
  ShapesCubes3DNames,
  ShapesCubes3DSchema,
  filterShapesCubes3D,
  getRandomUniqueCuboidsObjects,
  shapeCubes3D,
  cuboidSVGNameAndSizeObjects
} from '../../../../utils/cubes';
import { arrayIntersection } from '../../../../utils/collections';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aW2',
  description: 'aW2',
  keywords: ['Volume', '3-D shape'],
  schema: z.object({
    cubeShape: ShapesCubes3DSchema
  }),
  simpleGenerator: () => {
    const simpleShapes = filterShapesCubes3D({
      stacked: false
    });

    const cubeShape = getRandomFromArray(simpleShapes) as ShapesCubes3DNames;
    return { cubeShape };
  },
  Component: props => {
    const {
      question: { cubeShape },
      translate
    } = props;

    const { svgName, size } = shapeCubes3D[cubeShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheVolumeOf3DShape()}
        testCorrect={[size.toString()]}
        sentence={translate.answerSentences.ansCubes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg name={svgName} width={dimens.width} height={dimens.height} />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aW3',
  description: 'aW3',
  keywords: ['Volume', '3-D shape'],
  schema: z
    .object({
      cubeShapeA: ShapesCubes3DSchema,
      cubeShapeB: ShapesCubes3DSchema
    })
    .refine(
      ({ cubeShapeA, cubeShapeB }) => cubeShapeA !== cubeShapeB,
      'Image  should be different'
    ),
  simpleGenerator: () => {
    const simpleShapes = filterShapesCubes3D({
      stacked: false
    });

    const [cubeShapeA, cubeShapeB] = getRandomSubArrayFromArray(
      simpleShapes,
      2
    ) as ShapesCubes3DNames[];
    return { cubeShapeA, cubeShapeB };
  },
  Component: ({ question, translate }) => {
    const { cubeShapeA, cubeShapeB } = question;

    const { svgName: svgA, size: sizeA } = shapeCubes3D[cubeShapeA];
    const { svgName: svgB, size: sizeB } = shapeCubes3D[cubeShapeB];

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.the3DShapesHaveTheSameVolume()}<br/>${translate.instructions.selectIfStatementIsTrueOrFalse()}`}
        pdfTitle={`${translate.instructions.the3DShapesHaveTheSameVolume()}<br/>${translate.instructions.circleIfStatementIsTrueOrFalse()}`}
        content={({ dimens }) => (
          <View style={{ flexDirection: 'row', columnGap: 50 }}>
            <AssetSvg name={svgA} width={dimens.width * 0.45} height={dimens.height * 0.8} />
            <AssetSvg name={svgB} width={dimens.width * 0.45} height={dimens.height * 0.8} />
          </View>
        )}
        correctAnswer={sizeA === sizeB}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aW32',
  description: 'aW32',
  keywords: ['Volume', '3-D shape'],
  questionHeight: 900,
  schema: z
    .object({
      cubeShapeA: ShapesCubes3DSchema,
      cubeShapeB: ShapesCubes3DSchema,
      isGreater: z.boolean()
    })
    .refine(
      ({ cubeShapeA, cubeShapeB }) =>
        shapeCubes3D[cubeShapeA].size !== shapeCubes3D[cubeShapeB].size,
      'Sizes should be different'
    ),
  simpleGenerator: () => {
    const simpleShapes = filterShapesCubes3D({
      stacked: false
    });

    const { cubeShapeA, cubeShapeB } = rejectionSample(
      () => {
        const [cubeShapeA, cubeShapeB] = getRandomSubArrayFromArray(
          simpleShapes,
          2
        ) as ShapesCubes3DNames[];
        return { cubeShapeA, cubeShapeB };
      },
      // Only permit them if they have different sizes
      ({ cubeShapeA, cubeShapeB }) =>
        shapeCubes3D[cubeShapeA].size !== shapeCubes3D[cubeShapeB].size
    );

    const isGreater = getRandomBoolean();
    return { cubeShapeA, cubeShapeB, isGreater };
  },
  Component: ({ question, translate }) => {
    const { cubeShapeA, cubeShapeB, isGreater } = question;

    const shapes = [shapeCubes3D[cubeShapeA], shapeCubes3D[cubeShapeB]];
    const [{ size: sizeA }, { size: sizeB }] = shapes;
    const [greaterShape, smallerShape] = sizeA > sizeB ? [sizeA, sizeB] : [sizeB, sizeA];

    const volTitle = isGreater ? 'selectShapeWithGreaterVolume' : 'selectShapeWithSmallerVolume';
    const volTitlePDF = isGreater ? 'circleShapeWithGreaterVolume' : 'circleShapeWithSmallerVolume';

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.eachCubeHasVolume1cm3()}<br/>${translate.instructions[
          volTitle
        ]()}`}
        pdfTitle={`${translate.instructions.eachCubeHasVolume1cm3()}<br/>${translate.instructions[
          volTitlePDF
        ]()}`}
        testCorrect={[isGreater ? greaterShape : smallerShape]}
        numItems={2}
        renderItems={({ dimens }) =>
          shapes.map(({ svgName, size }) => ({
            value: size,
            component: <AssetSvg name={svgName} width={dimens.width} height={dimens.height * 0.8} />
          }))
        }
        questionHeight={900}
      />
    );
  }
});

const Question3 = { ...aDE, uid: 'aW4', description: 'aW4' as const };

const Question4 = newQuestionContent({
  uid: 'aW5',
  description: 'aW5',
  keywords: ['Volume', '3-D shape'],
  schema: z.object({
    cuboidShape: z.object({ svgName: z.string(), size: z.number() }),
    givenCubes: z.number().int().min(3).max(66)
  }),
  simpleGenerator: () => {
    const cuboidShape = rejectionSample(
      () => getRandomUniqueCuboidsObjects(1)[0],
      val => val.dimens.every(i => i > 1)
    );
    const [h, w, d] = cuboidShape.dimens;
    const areaSeen = h * w + h * d + w * d;
    const givenCubes = getRandomFromArray([cuboidShape.size, areaSeen - (h + w + d) + 1, areaSeen]);

    return { cuboidShape, givenCubes };
  },
  Component: ({ question, translate }) => {
    const { cuboidShape, givenCubes } = question;

    const { svgName, size } = cuboidShape;

    return (
      <QF39ContentWithSelectablesOnRight
        title={`${translate.instructions.thereAreXCubesInTheCuboid(
          givenCubes
        )}<br/>${translate.instructions.selectIfStatementIsTrueOrFalse()}`}
        pdfTitle={`${translate.instructions.thereAreXCubesInTheCuboid(
          givenCubes
        )}<br/>${translate.instructions.circleIfStatementIsTrueOrFalse()}`}
        leftContent={
          <MeasureView>
            {dimens => (
              <AssetSvg
                name={svgName as SvgName}
                width={dimens.width * 0.45}
                height={dimens.height}
              />
            )}
          </MeasureView>
        }
        selectables={{ true: translate.misc.True(), false: translate.misc.False() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        correctAnswer={[givenCubes === size ? 'true' : 'false']}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aW52',
  description: 'aW52',
  keywords: ['Volume', '3-D shape'],
  questionHeight: 900,
  schema: z.object({
    cuboidShape: z.object({ svgName: z.string(), size: z.number() })
  }),
  simpleGenerator: () => {
    const cuboidShape = rejectionSample(
      () => getRandomUniqueCuboidsObjects(1)[0],
      val => val.dimens.every(i => i > 1)
    );

    return { cuboidShape };
  },
  Component: ({ question, translate }) => {
    const { cuboidShape } = question;

    const { svgName, size } = cuboidShape;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.whatIsVolumeOfCuboidEachCubeIs1cm3()}`}
        Content={({ dimens }) => (
          <AssetSvg
            name={svgName as SvgName}
            width={dimens.width * 0.45}
            height={dimens.height * 0.9}
          />
        )}
        sentence={translate.answerSentences.ansCmCubed()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[size.toString()]}
        questionHeight={900}
      />
    );
  }
});

const Question5 = { ...aDL, uid: 'aW6', description: 'aW6' as const };

const Question6 = newQuestionContent({
  uid: 'aW7',
  description: 'aW7',
  keywords: ['Volume', 'Cuboid'],
  schema: z
    .object({
      cuboidShapeL: z.object({ svgName: z.string(), size: z.number() }),
      cuboidShapeS: z.object({ svgName: z.string(), size: z.number() })
    })
    .refine(({ cuboidShapeL, cuboidShapeS }) => cuboidShapeL.size !== cuboidShapeS.size),
  simpleGenerator: () => {
    const cuboidShapeA = getRandomUniqueCuboidsObjects(1)[0];
    const cuboidShapeB = cuboidSVGNameAndSizeObjects.filter(info => {
      return arrayIntersection(cuboidShapeA.dimens, info.dimens).length === 2;
    })[0];

    const [cuboidShapeL, cuboidShapeS] =
      cuboidShapeA.size > cuboidShapeB.size
        ? [cuboidShapeA, cuboidShapeB]
        : [cuboidShapeB, cuboidShapeA];

    return { cuboidShapeL, cuboidShapeS };
  },
  Component: props => {
    const {
      question: { cuboidShapeL, cuboidShapeS },
      translate
    } = props;

    const { svgName: svgNameL, size: sizeL } = cuboidShapeL;
    const { svgName: svgNameS, size: sizeS } = cuboidShapeS;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMoreCubesAreThereInCuboidAThanCuboidB()}
        testCorrect={[(sizeL - sizeS).toString()]}
        sentence={translate.answerSentences.ansCubes()}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', columnGap: 50 }}>
            <View style={{ alignItems: 'center' }}>
              <TextStructure sentence={'A'} />
              <AssetSvg
                name={svgNameL as SvgName}
                width={dimens.width * 0.45}
                height={dimens.height * 0.8}
              />
            </View>
            <View style={{ alignItems: 'center' }}>
              <TextStructure sentence={'B'} />
              <AssetSvg
                name={svgNameS as SvgName}
                width={dimens.width * 0.45}
                height={dimens.height * 0.8}
              />
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'VolumeCountingCubes',
  questionTypes: [Question1, Question2v2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question4]
});
export default SmallStep;
