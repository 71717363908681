import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { arraysHaveSameContents, filledArray } from 'common/src/utils/collections';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { barModelColors, tableColors } from 'common/src/theme/colors';
import {
  Fraction,
  fractionArithmetic,
  fractionToDecimal,
  simplify
} from 'common/src/utils/fractions';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import { View } from 'react-native';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  getRandomFromArray,
  seededRandom,
  getRandomSubArrayFromArray
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFractions } from 'common/src/utils/fractions';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { fractionSchema } from '../../../../utils/zod';
import { ADD, SUB } from '../../../../constants';
import { threeNumberLCM } from '../../../../utils/multiples';
import Table from '../../../../components/molecules/Table';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arB',
  description: 'arB',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z.object({
    number1: z.number().int().min(3).max(6),
    number3: z.number().int().min(2).max(12)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 6);
    const number3 = randomIntegerInclusive(2, 12);

    return { number1, number3 };
  },

  Component: props => {
    const {
      question: { number1, number3 },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    // Answer
    const number2 = number3 * number1;

    // Bar model
    const remainder = filledArray('white', number1 - 1);

    const customColorMap = [numeratorColor, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={900}
        sentence={`<frac n='1' d='${number1.toLocaleString()}'/> of <ans/> = ${number3.toLocaleString()}`}
        title={translate.instructions.completeCalculation()}
        testCorrect={[number2.toString()]}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={number1}
            width={dimens.width}
            height={dimens.height / 3}
            customColorMap={customColorMap}
            fullWidthTopBrace={'?'}
            topBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 40 : 50,
              top: displayMode === 'digital' ? -72 : -84
            }}
            partWidthBottomBrace={{
              size: 1,
              text: number3.toLocaleString()
            }}
            bottomBraceTextStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arC',
  description: 'arC',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(5),
      number2: z.number().int().min(3).max(6),
      number3: z.number().int().min(6).max(20),
      name: nameSchema
    })
    .refine(val => val.number1 < val.number2, 'number1 is less than number2')
    .refine(val => val.number3 % val.number1 === 0, 'number3 is a multiple of number1'),
  questionHeight: 900,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 6);
    const number1 = randomIntegerInclusive(2, number2 - 1);
    const number3 = randomIntegerInclusive(6, 20, {
      constraint: x => x % number1 === 0
    });

    const name = getRandomName();

    return { number1, number2, number3, name };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, name },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    // Simplify fractions
    const [numerator, denominator] = simplify(number1, number2);

    // Answer
    const number4 = (number3 / numerator) * denominator;

    // Bar model
    const numeratorColorArray = filledArray(numeratorColor, numerator);
    const remainder = filledArray('white', denominator - numerator);

    const customColorMap = [...numeratorColorArray, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={900}
        sentence={'<ans/> miles'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.characterTravelsFracFromHouseToSchoolHowFarInTotal({
          character: name,
          frac: `<frac n='${numerator}' d='${denominator}' />`,
          numberOfMiles: number3
        })}
        testCorrect={[number4.toString()]}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={dimens.height / 3}
            customColorMap={customColorMap}
            fullWidthTopBrace={'?'}
            topBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 32 : 50,
              top: displayMode === 'digital' ? -64 : -84
            }}
            partWidthBottomBrace={{
              size: numeratorColorArray.length,
              text: `${number3} miles`
            }}
            bottomBraceTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arD',
  description: 'arD',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(500),
      number3: z.number().int().min(4).max(12),
      number4: z.number().int().min(2).max(11)
    })
    .refine(val => val.number1 % val.number4 === 0, 'number1 must be a multiple of number4')
    .refine(val => val.number4 < val.number3, 'number4 must be less than number3'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number3 = randomIntegerInclusive(4, 12);
    const number4 = randomIntegerInclusive(2, number3 - 1);
    const number1 = randomIntegerInclusive(101, 500, {
      constraint: x => x % number4 === 0
    });

    return { number1, number3, number4 };
  },

  Component: props => {
    const {
      question: { number1, number3, number4 },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    // Answer
    const number2 = (number1 / number4) * number3;

    // Bar model
    const numeratorColorArray = filledArray(numeratorColor, number4);
    const remainder = filledArray('white', number3 - 1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutTheMissingWhole()}
        testCorrect={[number2.toString()]}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={number3}
            width={dimens.width}
            height={dimens.height / 3}
            customColorMap={customColorMap}
            fullWidthTopBrace={'?'}
            topBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 32 : 50,
              top: displayMode === 'digital' ? -64 : -84
            }}
            partWidthBottomBrace={{
              size: numeratorColorArray.length,
              text: number1
            }}
            bottomBraceTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arE',
  description: 'arE',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(10).max(500).multipleOf(10)
  }),
  questionHeight: 600,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    const number2 = randomIntegerInclusiveStep(10, 500, 10);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = (number2 / 10) * number1;

    const number4 = number2 * 10;

    const number5 = (number4 / 10) * number1;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingInfo()}
        testCorrect={[[number2.toString()], [number4.toString()]]}
        sentences={[
          translate.answerSentences.fracOfAnsEqualsNum(`<frac n='${number1}' d='10'/>`, number3),
          translate.answerSentences.fracOfAnsEqualsNum(`<frac n='${number1}' d='10'/>`, number5)
        ]}
        questionHeight={600}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arF',
  description: 'arF',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z.object({
    fractionA: fractionSchema(),
    fractionB: fractionSchema(),
    fractionC: fractionSchema(),
    number6: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const { fractionA, fractionB, fractionC, number6 } = rejectionSample(
      () => {
        const [fractionA, fractionB] = getRandomSubArrayFromArray(
          [
            [1, 2],
            [1, 3],
            [1, 4],
            [1, 5],
            [1, 6],
            [1, 8],
            [1, 10]
          ] as Fraction[],
          2
        );

        const fractionCChoices = (
          [
            [1, 2],
            [1, 3],
            [2, 3],
            [1, 4],
            [1, 5],
            [2, 5],
            [3, 5],
            [1, 6],
            [1, 8],
            [3, 8],
            [1, 10],
            [3, 10]
          ] as Fraction[]
        ).filter(
          frac =>
            !arraysHaveSameContents(frac, fractionA) && !arraysHaveSameContents(frac, fractionB)
        );

        const fractionC = getRandomFromArray(fractionCChoices)!;

        const number6 = randomIntegerInclusive(1, 5);

        return { fractionA, fractionB, fractionC, number6 };
      },
      ({ fractionA, fractionB, fractionC, number6 }) => {
        const totalFracAAndB = [
          fractionA[0] * fractionB[1] + fractionB[0] * fractionA[1],
          fractionB[1] * fractionA[1]
        ];

        const totalAllFracs = [
          totalFracAAndB[0] * fractionC[1] + fractionC[0] * totalFracAAndB[1],
          totalFracAAndB[1] * fractionC[1]
        ];

        const lcm = threeNumberLCM(fractionA[1], fractionB[1], fractionC[1]);

        return fractionToDecimal(totalAllFracs[0], totalAllFracs[1]) < 1 && lcm * number6 <= 240;
      }
    );

    return { fractionA, fractionB, fractionC, number6 };
  },
  Component: props => {
    const {
      question: { fractionA, fractionB, fractionC, number6 },
      translate,
      displayMode
    } = props;
    const trees = getRandomSubArrayFromArray(
      [
        translate.objects.Apple(),
        translate.objects.Birch(),
        translate.objects.Cedar(),
        translate.objects.Elm(),
        translate.objects.Fir(),
        translate.objects.Maple(),
        translate.objects.Oak(),
        translate.objects.Willow()
      ],
      4,
      {
        random: seededRandom(props.question)
      }
    );

    const totalFracAAndB = fractionArithmetic(fractionA, fractionB, ADD);

    const totalAllFracs = fractionArithmetic(totalFracAAndB, fractionC, ADD);

    const fractionD = fractionArithmetic([1, 1], totalAllFracs, SUB);

    const totalToFind = getRandomFromArray(['B', 'C', 'D'], {
      random: seededRandom(props.question)
    });

    const strings = [
      trees,
      [
        `<frac n='${fractionA[0]}' d='${fractionA[1]}' />`,
        totalToFind === 'B' ? '?' : `<frac n='${fractionB[0]}' d='${fractionB[1]}' />`,
        totalToFind === 'C' ? '?' : `<frac n='${fractionC[0]}' d='${fractionC[1]}' />`,
        totalToFind === 'D' ? '?' : `<frac n='${fractionD[0]}' d='${fractionD[1]}' />`
      ]
    ];

    // Don't need to throw in fractionD into a fourNumberLCM, it will never push this LCM any higher:
    const lcm = threeNumberLCM(fractionA[1], fractionB[1], fractionC[1]);

    const totalTrees = lcm * number6;

    const totalTreesA = fractionToDecimal(totalTrees * fractionA[0], fractionA[1]);
    const totalTreesB = fractionToDecimal(totalTrees * fractionB[0], fractionB[1]);
    const totalTreesC = fractionToDecimal(totalTrees * fractionC[0], fractionC[1]);
    const totalTreesD = fractionToDecimal(totalTrees * fractionD[0], fractionD[1]);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.theTableShowsTheFractionsOfTreesInAPark()}
        testCorrect={[
          totalToFind === 'B'
            ? totalTreesB.toString()
            : totalToFind === 'C'
            ? totalTreesC.toString()
            : totalTreesD.toString()
        ]}
        sentence={translate.answerSentences.thereAreNumXTreesHowManyYTreesAreThere(
          totalTreesA,
          trees[0],
          totalToFind === 'B' ? trees[1] : totalToFind === 'C' ? trees[2] : trees[3]
        )}
        Content={({ dimens }) => (
          <Table
            items={strings.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <View
                  key={`row-${rowIndex}-cell-${cellIndex}`}
                  style={{
                    backgroundColor: rowIndex === 0 ? tableColors.headerGreen : undefined,
                    alignSelf: 'stretch',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TextStructure
                    textVariant={'WRN400'}
                    sentence={cell}
                    textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
                  />
                </View>
              ))
            )}
            style={[dimens, { flex: 1, alignSelf: 'stretch' }]}
            rowStyle={{ flex: 1 }}
            cellStyle={{ flex: 1 }}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'arG',
  description: 'arG',
  keywords: ['Fraction', 'Multiply', 'Whole'],
  schema: z
    .object({
      number1: z.number().int().min(5).max(99),
      number2: z.number().int().min(11).max(100),
      number3: z.number().int().min(6).max(21)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2')
    .refine(
      val =>
        compareFractions([val.number1, val.number2], [2, 3]) ||
        compareFractions([val.number1, val.number2], [3, 4]),
      'number1/number2 must be equivalent to either 2/3 or 3/4'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(11, 100);

        const number1 = randomIntegerInclusive(5, number2 - 1);

        return { number1, number2 };
      },
      // Only permit them if number1/number2 produces an equivalent fraction to either 2/3 or 3/4
      ({ number1, number2 }) =>
        compareFractions([number1, number2], [2, 3]) || compareFractions([number1, number2], [3, 4])
    );

    const number3 = (() => {
      if (compareFractions([number1, number2], [2, 3])) {
        return randomIntegerInclusiveStep(6, 21, 3);
      } else {
        return randomIntegerInclusiveStep(8, 20, 4);
      }
    })();
    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = (number3 * number1) / number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[number3.toString()]}
        sentence={translate.answerSentences.numEqualsFracOfAns(
          number4,
          `<frac n='${number1}' d='${number2}'/>`
        )}
        textStyle={{ fontSize: 40 }}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionOfAnAmountFindTheWhole',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
