import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { View } from 'react-native';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { getRandomUniqueKs1Names, Name } from '../../../../utils/names';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { filledArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import QF18DragIntoABox from '../../../../components/question/questionFormats/QF18DragIntoABox';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baB',
  description: 'baB',
  keywords: ['Group', 'Match'],
  schema: z.object({
    player: z.enum(['Child', 'Horse', 'Sheep']),
    numPlayers: z.number().int().min(3).max(7),
    item: z.enum(['apple', 'cake', 'cookie', 'carrot', 'hay', 'bone', 'ball']),
    numObjects: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const player = getRandomFromArray(['Child', 'Horse', 'Sheep'] as const);
    const numPlayers = randomIntegerInclusive(3, 7);
    let item;
    switch (player) {
      case 'Child':
        item = getRandomFromArray(['apple', 'cake', 'cookie'] as const);
        break;
      case 'Horse':
        item = getRandomFromArray(['carrot', 'hay'] as const);
        break;
      case 'Sheep':
        item = 'hay' as const;
        break;
    }

    const numObjects = getRandomFromArrayWithWeights(
      [numPlayers - 2, numPlayers - 1, numPlayers, numPlayers + 1, numPlayers + 2],
      [3, 3, 8, 3, 3]
    );

    return { player, numPlayers, item, numObjects };
  },
  Component: ({ question: { player, numPlayers, item, numObjects }, translate }) => {
    const players =
      player === 'Child'
        ? getRandomUniqueKs1Names(
            numPlayers,
            seededRandom({ player, numPlayers, item, numObjects })
          )
        : filledArray(player, numPlayers);

    const itemSVGPath = {
      apple: 'Array_objects/AppleGreen',
      cake: 'Food_fractions/Cake/Cake_Whole/Cake_1_Whole',
      cookie: 'Cookie_biscuit/Cookie_biscuit_5',
      carrot: 'Carrot',
      hay: 'Hay_bale',
      bone: 'Array_objects/AppleGreen',
      ball: 'TennisBall'
    };

    const objects = filledArray(item, numObjects);

    let title = translate.ks1Instructions.canEachChildHave1AppleSelectYourAnswer();
    let pdfTitle = translate.ks1PDFInstructions.canEachChildHave1AppleTickYourAnswer();
    switch (player) {
      case 'Child': {
        switch (item) {
          case 'apple':
            title = translate.ks1Instructions.canEachChildHave1AppleSelectYourAnswer();
            pdfTitle = translate.ks1PDFInstructions.canEachChildHave1AppleTickYourAnswer();
            break;
          case 'cookie':
            title = translate.ks1Instructions.canEachChildHave1CookieSelectYourAnswer();
            pdfTitle = translate.ks1PDFInstructions.canEachChildHave1CookieTickYourAnswer();
            break;
          case 'cake':
            title = translate.ks1Instructions.canEachChildHave1CakeSelectYourAnswer();
            pdfTitle = translate.ks1PDFInstructions.canEachChildHave1CakeTickYourAnswer();
            break;
        }
        break;
      }
      case 'Horse': {
        switch (item) {
          case 'carrot':
            title = translate.ks1Instructions.canEachHorseHave1CarrotSelectYourAnswer();
            pdfTitle = translate.ks1PDFInstructions.canEachHorseHave1CarrotTickYourAnswer();
            break;
          case 'hay':
            title = translate.ks1Instructions.canEachHorseHave1BaleOfHaySelectYourAnswer();
            pdfTitle = translate.ks1PDFInstructions.canEachHorseHave1BaleOfHayTickYourAnswer();
            break;
        }
        break;
      }

      case 'Sheep': {
        title = translate.ks1Instructions.canEachSheepHave1BaleOfHaySelectYourAnswer();
        pdfTitle = translate.ks1PDFInstructions.canEachSheepHave1BaleOfHayTickYourAnswer();
        break;
      }
    }

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={title}
        pdfTitle={pdfTitle}
        correctAnswer={numPlayers <= numObjects}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        questionHeight={900}
        content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }
            ]}
          >
            <View
              style={[
                {
                  width: dimens.width,
                  height: dimens.height / 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'row'
                }
              ]}
            >
              {players.map((name, index) => {
                const svgName =
                  player === 'Child' ? getCharacterHeadSvgName(name as Name) : (name as SvgName);
                return (
                  <View
                    key={index}
                    style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <AssetSvg
                      name={svgName}
                      height={dimens.height / 2 - 4}
                      width={dimens.width / numPlayers - 4}
                    />
                  </View>
                );
              })}
            </View>
            <View
              style={[
                {
                  width: dimens.width,
                  height: dimens.height / 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }
              ]}
            >
              {objects.map((name: typeof item, index) => {
                return (
                  <View
                    key={index}
                    style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <AssetSvg
                      name={itemSVGPath[name] as SvgName}
                      height={dimens.height / 2 - 4}
                      width={dimens.width / 10}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'baC',
  description: 'baC',
  keywords: ['Match'],
  schema: z.object({
    number: z.number().int().min(2).max(7),
    player: z.enum([
      'Children with apples',
      'Children with cakes',
      'Children with sweets',
      'Horses with carrots',
      'Dogs with bones',
      'Dogs with balls',
      'Birds with worms'
    ])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 7);

    const player = getRandomFromArray([
      'Children with apples',
      'Children with cakes',
      'Children with sweets',
      'Horses with carrots',
      'Dogs with bones',
      'Dogs with balls',
      'Birds with worms'
    ] as const);

    return { number, player };
  },
  Component: props => {
    const {
      question: { number, player },
      translate
    } = props;
    const random = seededRandom(props.question);

    const [playerSvgPaths, draggablePath, playerString, draggableString] = (() => {
      switch (player) {
        case 'Children with apples':
          return [
            getRandomUniqueKs1Names(number, random).map(name => getCharacterHeadSvgName(name)),
            'Array_objects/AppleRed' as const,
            translate.objects.Child(),
            translate.objects.Apple()
          ];
        case 'Children with cakes':
          return [
            getRandomUniqueKs1Names(number, random).map(name => getCharacterHeadSvgName(name)),
            'Cake' as const,
            translate.objects.Child(),
            translate.objects.Cake()
          ];
        case 'Children with sweets':
          return [
            getRandomUniqueKs1Names(number, random).map(name => getCharacterHeadSvgName(name)),
            'Base_Ten/Sweets1' as const,
            translate.objects.Child(),
            translate.objects.Sweet()
          ];
        case 'Horses with carrots':
          return [
            filledArray('Horse' as const, number),
            'Carrot' as const,
            translate.animals.Horse(1),
            translate.objects.Carrot()
          ];
        case 'Dogs with bones':
          return [
            filledArray('Dogs/dog1' as const, number),
            'Bone' as const,
            translate.animals.dogs(1),
            translate.objects.Bone()
          ];
        case 'Dogs with balls':
          return [
            filledArray('Dogs/dog1' as const, number),
            'tennis_ball' as const,
            translate.animals.dogs(1),
            translate.objects.Ball()
          ];
        case 'Birds with worms':
          return [
            filledArray('bird_blue' as const, number),
            'Worm' as const,
            translate.animals.birds(1),
            translate.objects.Worm()
          ];
      }
    })();

    return (
      <QF18DragIntoABox
        title={translate.ks1Instructions.dragNumXForEachYIntoTheBox(
          1,
          draggableString,
          playerString
        )}
        pdfTitle={translate.ks1PDFInstructions.drawNumXForEachY(1, draggableString, playerString)}
        testCorrect={number}
        draggablePath={draggablePath}
        topContent={({ dimens }) => (
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {playerSvgPaths.map((name, index) => (
              <AssetSvg
                name={name}
                key={index}
                height={dimens.height * 0.8}
                width={dimens.width / (number + 1)}
              />
            ))}
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baD',
  description: 'baD',
  keywords: ['Group'],
  schema: z.object({
    numChildren: z.number().int().min(2).max(5),
    item: z.enum(['Apple', 'Cookie', 'Egg', 'Flower', 'Pencil']),
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    const numChildren = randomIntegerInclusive(2, 5);
    const item = getRandomFromArray(['Apple', 'Cookie', 'Egg', 'Flower', 'Pencil'] as const);
    const seed = randomIntegerInclusive(1, 999);
    return { numChildren, item, seed };
  },
  Component: ({ question: { numChildren, item, seed }, translate }) => {
    const children = getRandomUniqueKs1Names(numChildren, seededRandom({ seed }));

    const statements = [
      {
        numItems: numChildren,
        isCorrect: true
      },
      {
        numItems: numChildren - 1,
        isCorrect: false
      },
      {
        numItems: numChildren + 1,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom({ seed })
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheGroupThatMatchesTheNumberOfChildren()}
        pdfTitle={translate.ks1PDFInstructions.tickTheGroupThatMatchesTheNumberOfChildren()}
        testCorrect={shuffledStatements
          .filter(statement => statement.isCorrect)
          .map(statement => statement)}
        numItems={3}
        itemLayout="row"
        itemStyle={{ height: 250 }}
        contentContainerStyle={{ height: 250 }}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {children.map((name, index) => {
              const svgName = getCharacterHeadSvgName(name as Name);
              return (
                <View
                  key={index}
                  style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                >
                  <AssetSvg
                    name={svgName}
                    height={dimens.height / 2 - 4}
                    width={dimens.width / numChildren - 4}
                  />
                </View>
              );
            })}
          </View>
        )}
        renderItems={shuffledStatements.map(value => ({
          value,
          component: (
            <AssetSvg name={getObjectSvgName(item, value.numItems)} width={200} height={200} />
          )
        }))}
        questionHeight={800}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareGroupsByMatching',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
