import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusive, shuffle } from '../../../../utils/random';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { ADD } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { rangeAsString } from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { View } from 'react-native';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgW',
  description: 'bgW',
  keywords: ['Add', 'Number line', 'Across a ten'],
  schema: z.object({
    numA: z
      .number()
      .int()
      .min(12)
      .max(89)
      .refine(
        val => val % 10 !== 0 && val % 10 !== 1,
        'numA should not be a multiple of 10 or ones digit be 1'
      ),
    numB: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(12, 89, { constraint: x => x % 10 !== 0 && x % 10 !== 1 });
    const numB = randomIntegerInclusive(1, 9, {
      // Ensure numbers exchange and goes past next multiple of 10
      constraint: x => x + (numA % 10) > 10
    });

    return { numA, numB };
  },
  Component: props => {
    const {
      question: { numA, numB },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA + numB], ADD, 2);

    const tickValues = rangeAsString(numA - 1, numA + 10, 1, true);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        pdfDirection="column"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgX',
  description: 'bgX',
  keywords: ['Add', 'Base 10'],
  schema: z.object({
    numA: z
      .number()
      .int()
      .min(12)
      .max(89)
      .refine(
        val => val % 10 !== 0 && val % 10 !== 1,
        'numA should not be a multiple of 10 or ones digit be 1'
      ),
    numB: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(12, 89, { constraint: x => x % 10 !== 0 && x % 10 !== 1 });
    const numB = randomIntegerInclusive(1, 9, {
      // Ensure numbers exchange and goes past next multiple of 10
      constraint: x => x + (numA % 10) > 10
    });

    return { numA, numB };
  },
  Component: props => {
    const {
      question: { numA, numB },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA + numB], ADD, 2);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => {
          const scale = Scale(dimens.width * 0.5, dimens.height * 0.8, {
            tens: Math.floor(numA / 10),
            ones: Math.max(numA % 10, numB)
          });

          return (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'flex-end',
                flexDirection: 'row',
                columnGap: 16
              }}
            >
              <SimpleBaseTenWithCrossOut
                ones={numA % 10}
                tens={Math.floor(numA / 10)}
                scale={scale}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
              />
              <Text
                style={{ height: dimens.height * 0.7, alignContent: 'center' }}
                variant="WRN400"
              >
                {ADD}
              </Text>
              <SimpleBaseTenWithCrossOut
                ones={numB}
                scale={scale}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
              />
            </View>
          );
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bgY',
  description: 'bgY',
  keywords: ['Add', 'Across a ten'],
  schema: z.object({
    numbers: z.array(z.union([z.number().int().min(12).max(89), z.number().int().min(2).max(9)])),
    reversed: z.boolean()
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(12, 89, { constraint: x => x % 10 !== 0 && x % 10 !== 1 });
    const numB = randomIntegerInclusive(2, 9, {
      // Ensure numbers exchange and goes past next multiple of 10
      constraint: x => x + (numA % 10) > 10
    });

    const numbers = shuffle([numA, numB]);

    const reversed = getRandomBoolean();

    return { numbers, reversed };
  },
  Component: props => {
    const {
      question: { numbers, reversed },
      translate
    } = props;

    const [numA, numB] = numbers;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA + numB], ADD, 2, {
      reversed
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAcrossA10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
