import { z } from 'zod';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { CustomizableTable } from 'common/src/components/question/representations/CustomizableTable';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { range } from 'common/src/utils/collections';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from 'common/src/components/molecules/RowOfImages';
import { getImagesByAmount } from 'common/src/utils/images';
import QF27aLongMultiplicationDeprecated from '../../../../components/question/questionFormats/QF27aLongMultiplicationDeprecated';
import {
  numbersDoNotHaveMultiplicationExchange,
  numbersMultiplicationExchangeAt
} from '../../../../utils/exchanges';
import { MULT } from '../../../../constants';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ScientificNotation, digitAtPowerIsNumber } from '../../../../utils/math';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { colors } from '../../../../theme/colors';
import QF27aLongMultiplication from '../../../../components/question/questionFormats/QF27aLongMultiplication';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKy',
  description: 'aKy',
  keywords: ['Multiplication', 'Place value chart'],
  schema: z.object({
    tens: z.number().int().min(30).max(40).multipleOf(10),
    ones: z.number().int().min(1).max(4),
    numOfRows: z.number().int().min(1).max(4),
    random: z.boolean()
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusiveStep(30, 40, 10);
    const ones = randomIntegerInclusive(1, 4);
    const numOfRows = randomIntegerInclusive(1, 4);

    const random = Math.random() < 0.5;

    return { tens, ones, numOfRows, random };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, random },
      translate,
      displayMode
    } = props;

    // Part of question title
    const number3 = tens + ones;

    // Divide amount of tens by 10 to know how many rows deep counters should be
    const amountOfTens = tens / 10;

    // Correct amount of tens
    const tensImagesA = getImagesByAmount('Place_value/10', amountOfTens);
    // Incorrect amount of tens
    const tensImagesB = getImagesByAmount('Place_value/10', amountOfTens + 1);

    // Correct amount of ones
    const onesImagesA = getImagesByAmount('Place_value/1', ones);
    // Incorrect amount of ones
    const onesImagesB = getImagesByAmount('Place_value/1', ones + 1);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesB,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesB,
        4
      )
    );

    const dataA: (string | JSX.Element)[][] = [];
    const dataB: (string | JSX.Element)[][] = [];
    for (let i = 0; i < numOfRows; i++) {
      // Correct data
      dataA.push([
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesA}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesA}
          scaleFactor={scaleFactor}
        />
      ]);
      // Incorrect data
      dataB.push([
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          // Either show correct or incorrect tens
          images={random ? tensImagesB : tensImagesA}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          // Either show correct or incorrect ones
          images={random ? onesImagesA : onesImagesB}
          scaleFactor={scaleFactor}
        />
      ]);
    }

    const eqA = {
      tensImages: tensImagesA,
      onesImages: onesImagesA,
      data: dataA,
      isCorrect: true,
      value: 'A'
    };
    const eqB = {
      tensImages: tensImagesB,
      onesImages: onesImagesB,
      data: dataB,
      isCorrect: false,
      value: 'B'
    };

    // Randomly order these equations
    const eqs = shuffle([eqA, eqB], { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whichPlaceValueChartRepresentsXTimesY(number3, numOfRows)}
        pdfTitle={translate.instructions.whichPlaceValueChartRepresentsXTimesYPDF(
          number3,
          numOfRows
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={equation.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aKz',
  description: 'aKz',
  keywords: ['Column multiplication', 'Two digit', 'One digit'],
  schema: z
    .object({
      var1: z.number().int().min(13).max(49),
      var2: z.number().int().min(2).max(4)
    })
    .refine(
      val => numbersDoNotHaveMultiplicationExchange(val.var1, val.var2),
      'numbers should not exchange'
    ),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 4);
    const var1 = randomIntegerInclusive(13, 49, {
      constraint: x =>
        x % 10 !== 0 && numbersDoNotHaveMultiplicationExchange(x, var2) && x * var2 < 100
    });

    return { var1, var2 };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const partialProduct1 = var2 * Number(var1.toString()[1]);
    const partialProduct2 = var2 * Number(var1.toString()[0]) * 10;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutX(`${var1} ${MULT} ${var2}`)}
        topNumber={var1}
        multiplier={var2}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            partialProduct1: getMarkSchemeAnswer(
              partialProduct1,
              partialProduct1.toString().length
            ),
            partialProduct2: getMarkSchemeAnswer(
              partialProduct2,
              partialProduct2.toString().length
            ),
            answer: getMarkSchemeAnswer(var1 * var2, (var1 * var2).toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aKz2',
  description: 'aKz',
  keywords: ['Column multiplication', 'Two digit', 'One digit'],
  schema: z
    .object({
      var1: z.number().int().min(13).max(49),
      var2: z.number().int().min(2).max(4)
    })
    .refine(
      val => numbersDoNotHaveMultiplicationExchange(val.var1, val.var2),
      'numbers should not exchange'
    ),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 4);
    const var1 = randomIntegerInclusive(13, 49, {
      constraint: x =>
        x % 10 !== 0 && numbersDoNotHaveMultiplicationExchange(x, var2) && x * var2 < 100
    });

    return { var1, var2 };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const partialProduct1 = var2 * Number(var1.toString()[1]);
    const partialProduct2 = var2 * Number(var1.toString()[0]) * 10;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutX(`${var1} ${MULT} ${var2}`)}
        topNumber={var1}
        multiplier={var2}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            partialProduct1: getMarkSchemeAnswer(
              partialProduct1,
              partialProduct1.toString().length
            ),
            partialProduct2: getMarkSchemeAnswer(
              partialProduct2,
              partialProduct2.toString().length
            ),
            answer: getMarkSchemeAnswer(var1 * var2, (var1 * var2).toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aKA',
  description: 'aKA',
  keywords: ['Addition', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z.number().int().min(21).max(99),
      var2: z.number().int().min(2).max(5)
    })
    .refine(
      val => numbersMultiplicationExchangeAt(val.var1, val.var2, 'tens'),
      'numbers should have one exchange at tens'
    ),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 5);
    const tens1 = randomIntegerInclusive(20, 90, {
      constraint: x => x * var2 < 250 && x * var2 > 100
    });
    const ones1 = randomIntegerInclusive(1, 9, {
      constraint: x => numbersMultiplicationExchangeAt(tens1 + x, var2, 'tens')
    });

    const var1 = tens1 + ones1;
    return { var1, var2 };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const partialProduct1 = var2 * Number(var1.toString()[1]);
    const partialProduct2 = var2 * Number(var1.toString()[0]) * 10;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutX(`${var1} ${MULT} ${var2}`)}
        topNumber={var1}
        multiplier={var2}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            partialProduct1: getMarkSchemeAnswer(
              partialProduct1,
              partialProduct1.toString().length
            ),
            partialProduct2: getMarkSchemeAnswer(
              partialProduct2,
              partialProduct2.toString().length
            ),
            answer: getMarkSchemeAnswer(var1 * var2, (var1 * var2).toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aKA2',
  description: 'aKA',
  keywords: ['Addition', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z.number().int().min(21).max(99),
      var2: z.number().int().min(2).max(5)
    })
    .refine(
      val => numbersMultiplicationExchangeAt(val.var1, val.var2, 'tens'),
      'numbers should have one exchange at tens'
    ),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 5);
    const tens1 = randomIntegerInclusive(20, 90, {
      constraint: x => x * var2 < 250 && x * var2 > 100
    });
    const ones1 = randomIntegerInclusive(1, 9, {
      constraint: x => numbersMultiplicationExchangeAt(tens1 + x, var2, 'tens')
    });

    const var1 = tens1 + ones1;
    return { var1, var2 };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const partialProduct1 = var2 * Number(var1.toString()[1]);
    const partialProduct2 = var2 * Number(var1.toString()[0]) * 10;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutX(`${var1} ${MULT} ${var2}`)}
        topNumber={var1}
        multiplier={var2}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            partialProduct1: getMarkSchemeAnswer(
              partialProduct1,
              partialProduct1.toString().length
            ),
            partialProduct2: getMarkSchemeAnswer(
              partialProduct2,
              partialProduct2.toString().length
            ),
            answer: getMarkSchemeAnswer(var1 * var2, (var1 * var2).toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aKB',
  description: 'aKB',
  keywords: ['Column multiplication', 'Two digit', 'One digit'],
  schema: z.object({
    var1: z.number().int().min(16).max(75),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(16, 75, { constraint: x => x % 10 !== 0 });
    const var2 = randomIntegerInclusive(2, 6);

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutX(
          `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()}`
        )}
        topNumber={var1}
        bottomNumber={var2}
        operation={MULT}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aKC',
  description: 'aKC',
  keywords: ['Column multiplication', 'Two digit', 'One digit'],
  schema: z.object({
    var1: z.number().int().min(13).max(49),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const var2 = randomIntegerInclusive(2, 6);
        const var1 = randomIntegerInclusive(13, 49, {
          constraint: x => x % 10 !== 0 && x * var2 < 100
        });

        return { var1, var2 };
      },
      // Only permit them if theres only one correct answer
      ({ var1, var2 }) => {
        const number3 = var1 * var2;
        const answerOnes = ScientificNotation.fromNumber(number3).digitAt('ones');
        return (
          range(1, 9).filter(
            i => digitAtPowerIsNumber(var1 * i, 'ones', [answerOnes]) && var1 * i < 100
          ).length === 1
        );
      }
    ),
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={var1}
        bottomNumber={var2}
        answerNumber={number3}
        operation={MULT}
        bottomMissingDigits={[0]}
        answerMissingDigits={[1]}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            bottom: getMarkSchemeAnswer(var2, var2.toString().length),
            answer: getMarkSchemeAnswer(number3, number3.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aKD',
  description: 'aKD',
  keywords: ['Column multiplication', 'Two digit', 'One digit'],
  schema: z.object({
    var1: z.number().int().min(21).max(99),
    var2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(2, 6);
    const var1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0 && x * var2 < 500 && x * var2 > 100
    });

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 * var2;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigit()}
        topNumber={var1}
        bottomNumber={var2}
        answerNumber={number3}
        operation={MULT}
        bottomMissingDigits={[0]}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            bottom: getMarkSchemeAnswer(var2, var2.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA2DigitNumberByA1DigitNumber',
  questionTypes: [Question1, Question2v2, Question3v2, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
