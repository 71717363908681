import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { MULT } from '../../../../constants';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import {
  CustomizableTable,
  CustomizableTableWithState
} from '../../../../components/question/representations/CustomizableTable';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import AreaModel, {
  AreaModelWithLabelsWithState
} from '../../../../components/question/representations/AreaModel';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isEqual } from '../../../../utils/matchers';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { colors } from '../../../../theme/colors';

////
// Questions
////

// Questions were rearranged following a rewrite for v2.
// So the UID will not be in alphabetical order.

const Question1 = newQuestionContent({
  uid: 'aZ0',
  description: 'aZ0',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z
    .object({
      numberAOnes: z.number().int().min(1).max(9),
      numberATens: z.number().int().min(1).max(2),
      numberBTens: z.number().int().min(1).max(2),
      answerBox: z.enum(['numberAOnes', 'numberATens', 'numberBTens'])
    })
    .refine(
      val => val.numberAOnes + val.numberATens * 10 <= 22,
      'numberA must be less than or equal to 22'
    ),
  simpleGenerator: () => {
    const numberATens = randomIntegerInclusive(1, 2);

    const numberAOnes = randomIntegerInclusive(1, numberATens === 1 ? 9 : 2);

    const numberBTens = randomIntegerInclusive(1, 2);

    const answerBox = getRandomFromArray(['numberAOnes', 'numberATens', 'numberBTens'] as const);

    return { numberAOnes, numberATens, numberBTens, answerBox };
  },
  Component: props => {
    const {
      question: { numberAOnes, numberATens, numberBTens, answerBox },
      translate,
      displayMode
    } = props;

    const numberA = numberATens * 10 + numberAOnes;

    const numberB = numberBTens * 10;

    const ansToCheck = (() => {
      switch (answerBox) {
        case 'numberAOnes':
          return numberAOnes;
        case 'numberATens':
          return numberATens * 10;
        case 'numberBTens':
          return numberBTens * 10;
      }
    })();

    return (
      <QF3Content
        title={translate.instructions.fillInBlankToCompleteAreaXMultY(numberA, numberB)}
        inputType="numpad"
        Content={({ dimens }) => (
          <AreaModelWithLabelsWithState
            dimens={dimens}
            topNumber={numberA}
            sideNumber={numberB}
            topNumberAnswerPositions={
              answerBox === 'numberATens' ? [0] : answerBox === 'numberAOnes' ? [1] : undefined
            }
            sideNumberAnswerPositions={answerBox === 'numberBTens' ? [0] : undefined}
            testCorrect={isEqual([ansToCheck.toString()])}
            id={'areaModel'}
            defaultState={displayMode === 'markscheme' ? [ansToCheck.toLocaleString()] : undefined}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aZ02',
  description: 'aZ0',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z
    .object({
      numberAOnes: z.number().int().min(1).max(7),
      numberATens: z.number().int().min(1).max(7),
      numberBOnes: z.number().int().min(1).max(4),
      numberBTens: z.number().int().min(1).max(4)
    })
    .refine(
      val => val.numberAOnes + val.numberATens <= 8,
      "numberA's digits must total to 8 or less."
    )
    .refine(
      val => val.numberBOnes + val.numberBTens <= 5,
      "numberB's digits must total to 5 or less."
    ),
  simpleGenerator: () => {
    const numberAOnes = randomIntegerInclusive(1, 7);
    const numberATens = randomIntegerInclusive(1, 8 - numberAOnes);

    const numberBOnes = randomIntegerInclusive(1, 4);
    const numberBTens = randomIntegerInclusive(1, 5 - numberBOnes);

    return { numberAOnes, numberATens, numberBOnes, numberBTens };
  },
  Component: props => {
    const {
      question: { numberAOnes, numberATens, numberBTens, numberBOnes },
      translate,
      displayMode
    } = props;

    const numberA = numberATens * 10 + numberAOnes;

    const numberB = numberBTens * 10 + numberBOnes;
    const biggerDimen = Math.max(numberBOnes + numberBTens, numberAOnes + numberATens, 7);

    const dimensPerSvgContainer =
      displayMode === 'digital' ? 56 + (10 - biggerDimen) * 4 : 130 + (10 - biggerDimen) * 4;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsCalculationShownByAreaModel()}
        Content={
          <AreaModel
            dimensPerSvgContainer={dimensPerSvgContainer}
            topNumber={numberA}
            sideNumber={numberB}
            headerVariant="none"
            productVariant="counter"
          />
        }
        sentence={`<ans/> ${MULT} <ans/> = ${(numberA * numberB).toLocaleString()}`}
        pdfDirection="column"
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [numberA.toString(), numberB.toString()])
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aZ3',
  description: 'aZ3',
  keywords: ['4-digit', 'Area model', 'Place value counters', 'Multiplication'],
  schema: z.object({
    numberA: z.number().int().min(20).max(60).multipleOf(10),
    numberB: z.number().int().min(1).max(9),
    numberC: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusiveStep(20, 60, 10);
    const numberB = randomIntegerInclusive(1, 9);
    const numberC = randomIntegerInclusive(1, 10);

    return { numberA, numberB, numberC };
  },
  Component: props => {
    const {
      question: { numberA, numberB, numberC },
      translate,
      displayMode
    } = props;

    const numberD = numberA + numberB;

    const tableData = [
      [MULT, numberA.toLocaleString(), numberB.toLocaleString()],
      [numberC.toLocaleString(), '<ans/>', '<ans/>']
    ];

    return (
      <QF3Content
        title={translate.instructions.useTheAreaModelToCompleteTheMultiplication()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <MeasureView style={{ width: dimens.width / 1.5, alignSelf: 'center' }}>
              {
                <CustomizableTableWithState
                  id="table"
                  inputMaxCharacters={2}
                  defaultState={
                    displayMode === 'markscheme'
                      ? [(numberA * numberC).toString(), (numberB * numberC).toString()]
                      : ['', '']
                  }
                  columnFlexValues={[1, 4, 4]}
                  tableData={tableData}
                  textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                  // This looks better without having answer boxes visibile inside the table cells on PDF:
                  answerBoxStyling={
                    displayMode !== 'digital' && {
                      borderColor: 'transparent'
                    }
                  }
                  testCorrect={userAnswer =>
                    arraysHaveSameContents(userAnswer, [
                      (numberA * numberC).toString(),
                      (numberB * numberC).toString()
                    ])
                  }
                />
              }
            </MeasureView>
            <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <CompleteTheSentenceWithState
                defaultState={
                  displayMode === 'markscheme' ? [(numberD * numberC).toLocaleString()] : ['']
                }
                id="sentence"
                sentence={`${numberD.toLocaleString()} ${MULT} ${numberC.toLocaleString()} = <ans/>`}
                testCorrect={userAnswer =>
                  arraysHaveSameContents(userAnswer, [(numberD * numberC).toString()])
                }
              />
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2v2 = newQuestionContent({
  uid: 'aZ32',
  description: 'aZ3',
  keywords: ['4-digit', 'Area model', 'Place value counters', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0),
    number2: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const eq = getBinOpEquation({ left: number1, right: number2, sign: 'multiply' });

    const number1Tens = Math.floor(number1 / 10) * 10;
    const number1Ones = number1 % 10;

    const number2Tens = Math.floor(number2 / 10) * 10;
    const number2Ones = number2 % 10;

    const tableData = [
      [
        number2Tens.toLocaleString(),
        (number1Tens * number2Tens).toLocaleString(),
        (number1Ones * number2Tens).toLocaleString()
      ],
      [
        number2Ones.toLocaleString(),
        (number1Tens * number2Ones).toLocaleString(),
        (number1Ones * number2Ones).toLocaleString()
      ]
    ];

    const containerStyle = { height: 64, backgroundColor: colors.white };
    const textStyle = { color: colors.black, fontSize: displayMode === 'digital' ? 32 : 50 };
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeCalculation()}
        sentence={binOpEquationToSentenceString(eq)}
        pdfDirection="column"
        testCorrect={binOpEquationsToTestCorrect([eq])[0]}
        Content={({ dimens }) => (
          <View
            style={{ height: dimens.height, width: dimens.width * 0.75, justifyContent: 'center' }}
          >
            <CustomizableTable
              cellHeaders={[
                { label: MULT, containerStyle, textStyle },
                { label: number1Tens.toLocaleString(), containerStyle, textStyle },
                { label: number1Ones.toLocaleString(), containerStyle, textStyle }
              ]}
              columnFlexValues={[1, 4, 4]}
              tableData={tableData}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, color: colors.black }}
              tableCellStyle={{ height: 96 }}
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aZ4',
  description: 'aZ4',
  keywords: ['4-digit', 'Area model', 'Place value counters', 'Multiplication'],
  schema: z
    .object({
      numberAOnes: z.number().int().min(1).max(7),
      numberATens: z.number().int().min(1).max(7),
      numberBOnes: z.number().int().min(1).max(4),
      numberBTens: z.number().int().min(1).max(4)
    })
    .refine(
      val => val.numberAOnes + val.numberATens <= 8,
      "numberA's digits must total to 8 or less."
    )
    .refine(
      val => val.numberBOnes + val.numberBTens <= 5,
      "numberB's digits must total to 5 or less."
    ),
  simpleGenerator: () => {
    const numberAOnes = randomIntegerInclusive(1, 7);

    const numberATens = randomIntegerInclusive(1, 8 - numberAOnes);

    const numberBOnes = randomIntegerInclusive(1, 4);

    const numberBTens = randomIntegerInclusive(1, 5 - numberBOnes);

    return { numberAOnes, numberATens, numberBOnes, numberBTens };
  },
  Component: props => {
    const {
      question: { numberAOnes, numberATens, numberBOnes, numberBTens },
      translate,
      displayMode
    } = props;

    const numberA = 10 * numberATens + numberAOnes;

    const numberB = 10 * numberBTens + numberBOnes;

    const biggerDimen = Math.max(numberBOnes + numberBTens, numberAOnes + numberATens, 7);

    const dimensPerSvgContainer =
      displayMode === 'digital' ? 56 + (10 - biggerDimen) * 4 : 130 + (10 - biggerDimen) * 4;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheAreaModelToCompleteTheMultiplication()}
        sentence={`${numberA.toLocaleString()} ${MULT} ${numberB.toLocaleString()} = <ans/>`}
        pdfDirection="column"
        testCorrect={[(numberA * numberB).toString()]}
        Content={
          <AreaModel
            dimensPerSvgContainer={dimensPerSvgContainer}
            topNumber={numberA}
            sideNumber={numberB}
            headerVariant="counter"
            productVariant="counter"
          />
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aZ5',
  description: 'aZ5',
  keywords: ['4-digit', 'Area model', 'Place value counters', 'Multiplication'],
  schema: z.object({
    numberA: z.number().int().min(20).max(60).multipleOf(10),
    numberB: z.number().int().min(1).max(9),
    numberC: z.number().int().min(10).max(60).multipleOf(10),
    numberD: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusiveStep(20, 60, 10);
    const numberB = randomIntegerInclusive(1, 9);
    const numberC = randomIntegerInclusiveStep(10, 60, 10);
    const numberD = randomIntegerInclusive(1, 9);

    return { numberA, numberB, numberC, numberD };
  },
  Component: props => {
    const {
      question: { numberA, numberB, numberC, numberD },
      translate,
      displayMode
    } = props;

    const numberE = numberA + numberB;
    const numberF = numberC + numberD;

    const tableData = [
      [MULT, numberA.toLocaleString(), numberB.toLocaleString()],
      [numberC.toLocaleString(), '<ans/>', '<ans/>'],
      [numberD.toLocaleString(), '<ans/>', '<ans/>']
    ];

    return (
      <QF3Content
        title={translate.instructions.useTheAreaModelToCompleteTheMultiplication()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <MeasureView style={{ width: dimens.width / 1.5, alignSelf: 'center' }}>
              {
                <CustomizableTableWithState
                  id="table"
                  inputMaxCharacters={4}
                  defaultState={
                    displayMode === 'markscheme'
                      ? [
                          (numberA * numberC).toString(),
                          (numberB * numberC).toString(),
                          (numberA * numberD).toString(),
                          (numberB * numberD).toString()
                        ]
                      : ['', '', '', '']
                  }
                  columnFlexValues={[1, 4, 4]}
                  tableData={tableData}
                  textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                  // This looks better without having answer boxes visibile inside the table cells on PDF:
                  answerBoxStyling={
                    displayMode !== 'digital' && {
                      borderColor: 'transparent'
                    }
                  }
                  testCorrect={userAnswer =>
                    arraysHaveSameContents(userAnswer, [
                      (numberA * numberC).toString(),
                      (numberB * numberC).toString(),
                      (numberA * numberD).toString(),
                      (numberB * numberD).toString()
                    ])
                  }
                />
              }
            </MeasureView>
            <View style={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
              <CompleteTheSentenceWithState
                inputMaxCharacters={4}
                defaultState={
                  displayMode === 'markscheme' ? [(numberE * numberF).toLocaleString()] : ['']
                }
                id="sentence"
                sentence={`${numberE.toLocaleString()} ${MULT} ${numberF.toLocaleString()} = <ans/>`}
                testCorrect={userAnswer =>
                  arraysHaveSameContents(userAnswer, [(numberE * numberF).toString()])
                }
              />
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aZ2',
  description: 'aZ2',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z.object({
    numberAOnes: z.number().int().min(1).max(9),
    numberB: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const numberAOnes = randomIntegerInclusive(1, 9);

    const numberB = randomIntegerInclusive(1, 5);

    return { numberAOnes, numberB };
  },
  Component: props => {
    const {
      question: { numberAOnes, numberB },
      translate,
      displayMode
    } = props;

    const biggerDimen = Math.max(numberB, numberAOnes + 1, 7);

    const dimensPerSvgContainer =
      displayMode === 'digital' ? 56 + (10 - biggerDimen) * 4 : 130 + (10 - biggerDimen) * 4;

    const numberA = 10 + numberAOnes;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheAreaModelToCompleteTheMultiplication()}
        sentence={`${numberA.toLocaleString()} ${MULT} ${numberB.toLocaleString()} = <ans/>`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[(numberA * numberB).toString()]}
        Content={
          <AreaModel
            dimensPerSvgContainer={dimensPerSvgContainer}
            topNumber={numberA}
            sideNumber={numberB}
            headerVariant="base10"
            productVariant="number"
          />
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5v2 = newQuestionContent({
  uid: 'aZ22',
  description: 'aZ2',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0),
    number2: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const eq = getBinOpEquation({ left: number1, right: number2, sign: 'multiply' });

    const number1Tens = Math.floor(number1 / 10) * 10;
    const number1Ones = number1 % 10;

    const number2Tens = Math.floor(number2 / 10) * 10;
    const number2Ones = number2 % 10;

    const tableData = [
      [MULT, number1Tens.toLocaleString(), number1Ones.toLocaleString()],
      [number2Tens.toLocaleString(), '<ans/>', '<ans/>'],
      [number2Ones.toLocaleString(), '<ans/>', '<ans/>']
    ];

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeCalculation()}<br/>${translate.instructions.useAreaModelToHelp()}`}
        sentence={binOpEquationToSentenceString(eq)}
        pdfDirection="column"
        testCorrect={binOpEquationsToTestCorrect([eq])[0]}
        Content={
          <CustomizableTableWithState
            id="table"
            inputMaxCharacters={4}
            columnFlexValues={[1, 4, 4]}
            tableData={tableData}
            textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            defaultState={['', '', '', '']}
            // The table is not marked
            testComplete={() => true}
            // This looks better without having answer boxes visibile inside the table cells on PDF:
            answerBoxStyling={
              displayMode !== 'digital' && {
                borderColor: 'transparent'
              }
            }
          />
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aZ1',
  description: 'aZ1',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z
    .object({
      numberAOnes: z.number().int().min(1).max(9),
      numberATens: z.number().int().min(1).max(2),
      numberBTens: z.number().int().min(1).max(2)
    })
    .refine(
      val => val.numberAOnes + val.numberATens * 10 <= 22,
      'numberA must be less than or equal to 22'
    ),
  simpleGenerator: () => {
    const numberATens = randomIntegerInclusive(1, 2);

    const numberAOnes = randomIntegerInclusive(1, numberATens === 1 ? 9 : 2);

    const numberBTens = randomIntegerInclusive(1, 2);

    return { numberAOnes, numberATens, numberBTens };
  },
  Component: props => {
    const {
      question: { numberAOnes, numberATens, numberBTens },
      translate,
      displayMode
    } = props;

    const numberA = numberATens * 10 + numberAOnes;

    const numberB = numberBTens * 10;

    return (
      <QF3Content
        title={translate.instructions.fillInBlanksToCompleteAreaXMultY(numberA, numberB)}
        inputType="numpad"
        Content={({ dimens }) => (
          <AreaModelWithLabelsWithState
            topNumberOnesMinWidth={180}
            topNumber={numberA}
            sideNumber={numberB}
            topNumberAnswerPositions={[0, 1]}
            sideNumberAnswerPositions={[0]}
            testCorrect={isEqual([
              numberB.toString(),
              (numberATens * 10).toString(),
              numberAOnes.toString()
            ])}
            dimens={dimens}
            id={'areaModel'}
            defaultState={
              displayMode === 'markscheme'
                ? [
                    numberB.toLocaleString(),
                    (numberATens * 10).toLocaleString(),
                    numberAOnes.toLocaleString()
                  ]
                : undefined
            }
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6v2 = newQuestionContent({
  uid: 'aZ12',
  description: 'aZ12',
  keywords: ['4-digit', 'Area model', 'Base 10', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0),
    number2: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const eq = getBinOpEquation({ left: number1, right: number2, sign: 'multiply' });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        sentence={binOpEquationToSentenceString(eq)}
        testCorrect={binOpEquationsToTestCorrect([eq])[0]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA2DigitNumberByA2DigitNumberAreaModel',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5v2, Question6v2],
  unpublishedQuestionTypes: [Question1v2],
  archivedQuestionTypes: [Question1, Question2, Question5, Question6]
});
export default SmallStep;
