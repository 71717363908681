import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { countRange, filledArray } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  ArrayOfObjectsWithCrosses,
  ArrayOfObjectsWithCrossesWithState
} from '../../../../components/question/representations/ArrayOfObjectsWithCrosses';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbw',
  description: 'bbw',
  keywords: ['Subtract', 'First', 'Then', 'Now', 'Cross out', 'Take away'],
  schema: z.object({
    object: z.enum(['Apple', 'Bird', 'Car', 'Cookie']),
    amountOfObjects: z.number().int().min(3).max(9),
    amountSubtracted: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Apple', 'Bird', 'Car', 'Cookie'] as const);
    const amountOfObjects = randomIntegerInclusive(3, 9);
    const amountSubtracted = randomIntegerInclusive(1, amountOfObjects);

    return {
      object,
      amountOfObjects,
      amountSubtracted
    };
  },

  Component: props => {
    const {
      question: { object, amountOfObjects, amountSubtracted },
      displayMode,
      translate
    } = props;

    let icon: SvgName;

    switch (object) {
      case 'Apple':
        icon = 'Array_objects/AppleRed';
        break;
      case 'Bird':
        icon = 'bird_blue';
        break;
      case 'Car':
        icon = 'Car';
        break;
      case 'Cookie':
        icon = 'Cookie';
        break;
    }

    const translatedObject =
      object === 'Apple' ? translate.objects.Apples() : translate.objects.Cookies();

    const title =
      object === 'Bird'
        ? `${translate.ks1Instructions.firstThereWereXBirdsYBirdsFlewAway(
            amountOfObjects,
            amountSubtracted
          )}<br/><br/>${
            displayMode === 'digital'
              ? translate.ks1Instructions.tapToShowTheBirdsThatFlewAway()
              : translate.ks1PDFInstructions.crossOutToShowTheBirdsThatFlewAway()
          }`
        : object === 'Car'
        ? `${translate.ks1Instructions.firstThereWereXCarsInCarParkYCarsDriveAway(
            amountOfObjects,
            amountSubtracted
          )}<br/><br/>${
            displayMode === 'digital'
              ? translate.ks1Instructions.tapToShowCarsThatDriveAway()
              : translate.ks1PDFInstructions.crossOutToShowCarsThatDriveAway()
          }`
        : `${translate.ks1Instructions.firstThereWereXFoodObjectsOnAPlateYFoodObjectsWereEaten(
            amountOfObjects,
            translatedObject,
            amountSubtracted
          )}<br/><br/>${
            displayMode === 'digital'
              ? translate.ks1Instructions.tapToShowTheXFoodObjectsThatWereEaten(translatedObject)
              : translate.ks1PDFInstructions.crossOutToShowTheXFoodObjectThatWereEaten(
                  translatedObject
                )
          }`;

    return (
      <QF3Content
        title={title}
        Content={({ dimens }) => (
          <ArrayOfObjectsWithCrossesWithState
            dimens={dimens}
            icon={{
              name: icon,
              width:
                displayMode === 'digital'
                  ? amountOfObjects < 4
                    ? dimens.width / amountOfObjects / 3
                    : 75
                  : 110
            }}
            amountOfColumns={amountOfObjects}
            id="objects"
            defaultState={
              displayMode === 'markscheme' ? filledArray(true, amountSubtracted) : undefined
            }
            testCorrect={userAnswer =>
              userAnswer.filter(col => col === true).length === amountSubtracted
            }
          />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 600
});

const Question2 = newQuestionContent({
  uid: 'bbx',
  description: 'bbx',
  keywords: ['Subtract', 'First', 'Then', 'Now', 'Cross out', 'Take away'],
  schema: z.object({
    object: z.enum(['Bird', 'Car', 'Cookie']),
    amountOfObjects: z.number().int().min(3).max(9),
    amountSubtracted: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Bird', 'Car', 'Cookie'] as const);
    const amountOfObjects = randomIntegerInclusive(3, 9);
    const amountSubtracted = randomIntegerInclusive(1, amountOfObjects);

    return { object, amountOfObjects, amountSubtracted };
  },
  Component: props => {
    const {
      question: { object, amountOfObjects, amountSubtracted },
      translate,
      displayMode
    } = props;

    let icon: SvgName;

    switch (object) {
      case 'Bird':
        icon = 'bird_blue';
        break;
      case 'Car':
        icon = 'Car';
        break;
      case 'Cookie':
        icon = 'Cookie';
        break;
    }

    const amountLeft = amountOfObjects - amountSubtracted;
    const items = shuffle([amountOfObjects, amountSubtracted, amountLeft], {
      random: seededRandom(props.question)
    });

    const sentences =
      object === 'Bird'
        ? [
            translate.ks1AnswerSentences.firstThereWereAnsBirdsInTree(),
            translate.ks1AnswerSentences.thenAnsOfTheBirdsFlewAway(),
            amountLeft === 1
              ? translate.ks1AnswerSentences.nowThereIsAnsBirdLeft()
              : translate.ks1AnswerSentences.nowThereAreAnsBirdsLeft()
          ]
        : object === 'Car'
        ? [
            translate.ks1AnswerSentences.firstThereWereAnsCarsInCarPark(),
            amountSubtracted === 1
              ? translate.ks1AnswerSentences.thenAnsOfTheCarsDriveAway()
              : translate.ks1AnswerSentences.thenAnsOfTheCarsDrivesAway(),
            amountLeft === 1
              ? translate.ks1AnswerSentences.nowThereIsAnsCarLeft()
              : translate.ks1AnswerSentences.nowThereAreAnsCarsLeft()
          ]
        : [
            translate.ks1AnswerSentences.firstThereWereAnsCookies(),
            amountSubtracted === 1
              ? translate.ks1AnswerSentences.thenAnsOfTheCookiesWasEaten()
              : translate.ks1AnswerSentences.thenAnsOfTheCookiesWereEaten(),
            amountLeft === 1
              ? translate.ks1AnswerSentences.nowThereIsAnsCookieLeft()
              : translate.ks1AnswerSentences.nowThereAreAnsCookiesLeft()
          ];

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragCardsCompleteSentencesToMatchThePicture()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentences()}
        items={items}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <ArrayOfObjectsWithCrosses
            dimens={dimens}
            icon={{
              name: icon,
              width:
                displayMode === 'digital'
                  ? amountOfObjects < 4
                    ? dimens.width / amountOfObjects / 3
                    : 75
                  : 110
            }}
            amountOfColumns={amountOfObjects}
            crossedOutIndices={filledArray(true, amountSubtracted)}
          />
        )}
        sentences={sentences}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        testCorrect={[[amountOfObjects], [amountSubtracted], [amountLeft]]}
        pdfLayout="itemsAboveContent"
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question3 = newQuestionContent({
  uid: 'bby',
  description: 'bby',
  keywords: ['Subtract', 'First', 'Then', 'Now', 'Cross out', 'Take away'],
  questionHeight: 900,
  schema: z
    .object({
      initial: z.number().int().min(3).max(10),
      subtract: z.number().int().min(1).max(10),
      item: z.enum(['cookie', 'bird', 'car'])
    })
    .refine(
      ({ initial, subtract }) => initial >= subtract,
      'initial shouldb be greater or equal to subtract'
    ),
  simpleGenerator: () => {
    const initial = randomIntegerInclusive(3, 10);
    const subtract = randomIntegerInclusive(1, initial);

    const item = getRandomFromArray(['cookie', 'bird', 'car'] as const);

    return { initial, subtract, item };
  },
  Component: props => {
    const {
      question: { initial, subtract, item },
      translate
    } = props;

    const remaining = initial - subtract;
    const svgName = {
      cookie: 'Cookie_biscuit/Cookie_biscuit_6',
      car: 'Car',
      bird: 'bird_blue'
    } as const;

    const firstStatement = {
      cookie: 'firstThereWereXCookies',
      car: 'firstThereWereXCarsInCarPark',
      bird: 'firstThereWereXBirdsInTree'
    } as const;

    const secondStatement = {
      cookie: subtract === 1 ? 'thenXCookieWasEaten' : 'thenXCookiesWereEaten',
      car: 'thenXOfTheCarsDriveAway',
      bird: 'thenXOfTheBirdsFlewAway'
    } as const;

    const thirdStatement = {
      cookie: remaining === 1 ? 'nowThereIsAnsCookie' : 'nowThereAreAnsCookies',
      car: remaining === 1 ? 'nowThereIsAnsCarLeft' : 'nowThereAreAnsCarsLeft',
      bird: remaining === 1 ? 'nowThereIsAnsBirdLeft' : 'nowThereAreAnsBirdsLeft'
    } as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', gap: 8 }}>
              {countRange(initial).map(i => (
                <AssetSvg
                  key={`${i}_A`}
                  name={svgName[item]}
                  height={dimens.height}
                  width={dimens.width / initial - 8}
                />
              ))}
            </View>
          );
        }}
        sentence={`${translate.ks1AnswerSentences[firstStatement[item]](
          initial
        )}<br/>${translate.ks1AnswerSentences[secondStatement[item]](
          subtract
        )}<br/>${translate.ks1AnswerSentences[thirdStatement[item]]()}`}
        testCorrect={[remaining.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionTakeAwayCrossOut',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
