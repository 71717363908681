import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { MULT } from '../../../../constants';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import {
  multiplicationHasExchanges,
  numbersDoNotHaveMultiplicationExchange
} from '../../../../utils/exchanges';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { CustomizableTable } from 'common/src/components/question/representations/CustomizableTable';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { colors } from '../../../../theme/colors';
import { ScientificNotation } from '../../../../utils/math';

////
// Questions
////

const Question1v2 = newQuestionContent({
  uid: 'aFO2',
  description: 'aFO',
  keywords: ['Place value chart', 'Multiplication', '2-digit'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(11)
      .max(44)
      .refine(x => {
        const sci = ScientificNotation.fromNumber(x);
        const ones = sci.digitAt(0);
        const tens = sci.digitAt(1);
        return 1 <= ones && ones <= 4 && 1 <= tens && tens <= 4;
      }, 'both digits must be between 1 and 4 inclusive'),
    multiplier: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 4);

    const multiplicand = rejectionSample(
      () => {
        const ones = randomIntegerInclusive(1, 4);
        const tens = randomIntegerInclusive(1, 4);
        return tens * 10 + ones;
      },
      // Prevent numbers that lead to exchanges
      multiplicand => !multiplicationHasExchanges(multiplicand, multiplier)
    );

    return { multiplicand, multiplier };
  },
  Component: props => {
    const {
      question: { multiplicand, multiplier },
      translate,
      displayMode
    } = props;
    const random = seededRandom(props.question);

    const sci = ScientificNotation.fromNumber(multiplicand);
    const ones = sci.digitAt(0);
    const tens = sci.digitAt(1);

    // Get the options
    type Option = { tens: number; ones: number }[];
    // Correct option (multiplicand * multiplier) is to show the multiplicand on each row, with multiplier rows
    const correctRows: Option = filledArray({ tens, ones }, multiplier);
    // Incorrect option (multiiplicand + multiplier) is to show the multiplicant on row 1, and the multiplier on row 2
    const incorrectRows: Option = [
      { tens, ones },
      { tens: 0, ones: multiplier }
    ];
    // Each selectable's "value" is just its stringified rows. This ensures that the user answer vaguely
    // corresponds to the real answer.
    const correctValue = JSON.stringify(correctRows);
    const options = shuffle(
      [correctRows, incorrectRows].map(rows => ({ value: JSON.stringify(rows), rows })),
      { random }
    );

    // Calculate common scale factor for the images
    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };
    const scaleFactors = options.flatMap(option =>
      option.rows.flatMap(row => {
        const rowScaleFactors = [];
        if (row.tens !== 0) {
          rowScaleFactors.push(
            calcRowOfImagesScaleFactor(
              rowContainerDimens.width,
              rowContainerDimens.height,
              getImagesByAmount('Place_value/10', row.tens),
              4
            )
          );
        }
        if (row.ones !== 0) {
          rowScaleFactors.push(
            calcRowOfImagesScaleFactor(
              rowContainerDimens.width,
              rowContainerDimens.height,
              getImagesByAmount('Place_value/1', row.ones),
              4
            )
          );
        }
        return rowScaleFactors;
      })
    );
    const scaleFactor = Math.min(...scaleFactors);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXTimesY(
          multiplicand,
          multiplier
        )}
        pdfTitle={translate.instructions.circlePlaceValueChartRepresentsXTimesY(
          multiplicand,
          multiplier
        )}
        testCorrect={[correctValue]}
        numItems={2}
        renderItems={({ dimens }) =>
          options.map(option => ({
            value: option.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: {
                      color:
                        displayMode === 'digital' ? placeValueColumnInfo[1].textColor : colors.black
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: {
                      color:
                        displayMode === 'digital' ? placeValueColumnInfo[0].textColor : colors.black
                    }
                  }
                ]}
                tableData={option.rows.map(row => [
                  <RowOfImages
                    key="tens"
                    containerStyle={rowContainerDimens}
                    style={{ gap: 4 }}
                    images={getImagesByAmount('Place_value/10', row.tens)}
                    scaleFactor={scaleFactor}
                  />,
                  <RowOfImages
                    key="ones"
                    containerStyle={rowContainerDimens}
                    style={{ gap: 4 }}
                    images={getImagesByAmount('Place_value/1', row.ones)}
                    scaleFactor={scaleFactor}
                  />
                ])}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }))
        }
        questionHeight={900}
      />
    );
  }
});

/** @deprecated archived */
const Question1 = newQuestionContent({
  uid: 'aFO',
  description: 'aFO',
  keywords: ['Place value chart', 'Multiplication', '2-digit'],
  schema: z.object({
    tens: z.number().int().min(10).max(40).multipleOf(10),
    ones: z.number().int().min(1).max(4),
    numOfRows: z.number().int().min(2).max(4),
    incorrectAnswer: z.enum(['+10', '-10', '+1', '-1']),
    amountOfIncorrectTens: z.number().int().min(1).max(5),
    amountOfIncorrectOnes: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const { tens, ones, numOfRows, incorrectAnswer, amountOfIncorrectTens, amountOfIncorrectOnes } =
      rejectionSample(
        () => {
          const numOfRows = randomIntegerInclusive(2, 4);

          const tens =
            numOfRows === 2
              ? randomIntegerInclusiveStep(10, 40, 10)
              : numOfRows === 3
              ? randomIntegerInclusiveStep(10, 30, 10)
              : randomIntegerInclusiveStep(10, 20, 10);

          const ones =
            numOfRows === 2
              ? randomIntegerInclusive(1, 4)
              : numOfRows === 3
              ? randomIntegerInclusive(1, 3)
              : randomIntegerInclusive(1, 2);

          const incorrectAnswer = getRandomFromArray(['+10', '-10', '+1', '-1'] as const);

          const amountOfTens = tens / 10;

          const [amountOfIncorrectTens, amountOfIncorrectOnes] = (() => {
            switch (incorrectAnswer) {
              case '+10':
                return [amountOfTens + 1, ones];
              case '-10':
                return [amountOfTens - 1, ones];
              case '+1':
                return [amountOfTens, ones + 1];
              case '-1':
                return [amountOfTens, ones - 1];
            }
          })();

          return {
            tens,
            ones,
            numOfRows,
            incorrectAnswer,
            amountOfIncorrectTens,
            amountOfIncorrectOnes
          };
        },
        ({ amountOfIncorrectTens, amountOfIncorrectOnes, numOfRows }) => {
          // Ensure no exchanges
          // Ensure tens/ones are always greater than 0
          return (
            amountOfIncorrectTens * 10 * numOfRows < 100 &&
            amountOfIncorrectOnes * numOfRows < 10 &&
            amountOfIncorrectTens !== 0 &&
            amountOfIncorrectOnes !== 0
          );
        }
      );

    return { tens, ones, numOfRows, incorrectAnswer, amountOfIncorrectTens, amountOfIncorrectOnes };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, amountOfIncorrectTens, amountOfIncorrectOnes },
      translate,
      displayMode
    } = props;

    const number4 = tens + ones;

    const amountOfTens = tens / 10;

    const tensImagesCorrect = getImagesByAmount('Place_value/10', amountOfTens);
    const tensImagesIncorrect = getImagesByAmount('Place_value/10', amountOfIncorrectTens);

    // Correct amount of ones
    const onesImagesCorrect = getImagesByAmount('Place_value/1', ones);
    // Incorrect amount of ones
    const onesImagesIncorrect = getImagesByAmount('Place_value/1', amountOfIncorrectOnes);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scalesToCheck = [
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesCorrect,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesCorrect,
        4
      )
    ];

    // Only check these if the number of incorrect tens or ones is greater than zero - otherwise, this function breaks.
    if (amountOfIncorrectTens > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          tensImagesIncorrect,
          4
        )
      );
    }

    if (amountOfIncorrectOnes > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          onesImagesIncorrect,
          4
        )
      );
    }

    const scaleFactor = Math.min(...scalesToCheck);

    // Correct data
    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesCorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesCorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const dataB = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesIncorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesIncorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    const charts = shuffle(
      [
        {
          tensImages: tensImagesCorrect,
          onesImages: onesImagesCorrect,
          data: dataA,
          isCorrect: true,
          value: 'correct'
        },
        {
          tensImages: tensImagesIncorrect,
          onesImages: onesImagesIncorrect,
          data: dataB,
          isCorrect: false,
          value: 'incorrect'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXTimesY(number4, numOfRows)}
        pdfTitle={translate.instructions.circlePlaceValueChartRepresentsXTimesY(number4, numOfRows)}
        testCorrect={['correct']}
        numItems={2}
        renderItems={({ dimens }) => {
          return charts.map(chart => ({
            value: chart.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={chart.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aFP',
  description: 'aFP',
  keywords: ['Place value chart', '2-digit', 'Multiplication'],
  schema: z.object({
    rows: z.number().int().min(2).max(4),
    tens: z.number().int().min(10).max(40).multipleOf(10),
    ones: z.number().int().min(1).max(4),
    incorrectAnswerA: numberEnum([10, -10]),
    incorrectAnswerB: z
      .number()
      .int()
      .min(-5)
      .max(5)
      .refine(val => val !== 0, 'incorrectAnswerB cannot equal 0'),
    incorrectAnswerCForm: numberEnum([10, -10])
  }),
  simpleGenerator: () => {
    const { rows, tens, ones, incorrectAnswerA, incorrectAnswerB, incorrectAnswerCForm } =
      rejectionSample(
        () => {
          const rows = randomIntegerInclusive(2, 4);

          const tens =
            rows === 2
              ? randomIntegerInclusiveStep(10, 40, 10)
              : rows === 3
              ? randomIntegerInclusiveStep(10, 30, 10)
              : randomIntegerInclusiveStep(10, 20, 10);

          const ones =
            rows === 2
              ? randomIntegerInclusive(1, 4)
              : rows === 3
              ? randomIntegerInclusive(1, 3)
              : randomIntegerInclusive(1, 2);

          const incorrectAnswerA = getRandomFromArray([10, -10] as const);

          const incorrectAnswerB = randomIntegerInclusive(-5, 5, {
            constraint: x => x !== 0
          });

          const incorrectAnswerCForm = getRandomFromArray([10, -10] as const);
          return { rows, tens, ones, incorrectAnswerA, incorrectAnswerB, incorrectAnswerCForm };
        },
        // Ensure no exchanges
        ({ rows, tens, ones }) => tens * rows < 100 && ones * rows < 10
      );

    return {
      rows,
      tens,
      ones,
      incorrectAnswerA,
      incorrectAnswerB,
      incorrectAnswerCForm
    };
  },
  Component: props => {
    const {
      question: { rows, tens, ones, incorrectAnswerA, incorrectAnswerB, incorrectAnswerCForm },
      translate,
      displayMode
    } = props;

    const incorrectAnswerC = incorrectAnswerB + incorrectAnswerCForm;

    const multiplicand = tens + ones;

    const correctAnswer = multiplicand * rows;

    const statements = [
      {
        value: `${correctAnswer.toLocaleString()}`,
        isCorrect: true
      },
      {
        value: `${(correctAnswer + incorrectAnswerA).toLocaleString()}`,
        isCorrect: false
      },
      {
        value: `${(correctAnswer + incorrectAnswerB).toLocaleString()}`,
        isCorrect: false
      },
      {
        value: `${(correctAnswer + incorrectAnswerC).toLocaleString()}`,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    const tensImages = getImagesByAmount('Place_value/10', tens / 10);
    const onesImages = getImagesByAmount('Place_value/1', ones);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImages,
        4
      ),
      calcRowOfImagesScaleFactor(rowContainerDimens.width, rowContainerDimens.height, onesImages, 4)
    );

    const tableData: (string | JSX.Element)[][] = [];
    for (let i = 0; i < rows; i++) {
      // Correct data
      tableData.push([
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImages}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImages}
          scaleFactor={scaleFactor}
        />
      ]);
    }
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectCorrectAnswerToMultuseThePVCToHelp(multiplicand, rows)}
        pdfTitle={translate.instructions.circleCorrectAnswerToMultuseThePVCToHelp(
          multiplicand,
          rows
        )}
        testCorrect={shuffledStatements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        itemLayout={'row'}
        itemStyle={{ marginVertical: 16 }}
        numItems={4}
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Tens(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[1].textColor
                }
              },
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor:
                    displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                },
                textStyle: displayMode === 'digital' && {
                  color: placeValueColumnInfo[0].textColor
                }
              }
            ]}
            tableData={tableData}
            tableStyle={{ width: dimens.width * 0.8 }}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{value}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aFQ',
  description: 'aFQ',
  keywords: ['Part-whole model', '2-digit', 'Multiplication'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 5, 8]),
    number2: z.number().int().min(10).max(40).multipleOf(10),
    number3: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const { number1, number2, number3 } = rejectionSample(
      () => {
        const number1 = getRandomFromArray([2, 3, 4, 5, 8] as const);

        const number2 = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * number1 < 100
        });

        const number3 = randomIntegerInclusive(1, 4);

        return { number1, number2, number3 };
      },
      ({ number1, number2, number3 }) => number3 * number1 < 10 && number3 + number2 >= 13
    );

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;
    const number4 = number2 + number3;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToMultiplyXByY(number4, number1)}
        testCorrect={[(number4 * number1).toString()]}
        sentence={`${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <PartWholeModel
            top={`${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()}`}
            partition={[
              `${number3.toLocaleString()} ${MULT} ${number1.toLocaleString()}`,
              `${number2.toLocaleString()} ${MULT} ${number1.toLocaleString()}`
            ]}
            dimens={dimens}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aFR',
  description: 'aFR',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    number1A: numberEnum([2, 3, 4, 5, 8]),
    number2A: z.number().int().min(11).max(99),
    number1B: numberEnum([2, 3, 4, 5, 8]),
    number2B: z.number().int().min(11).max(99),
    number1C: numberEnum([2, 3, 4, 5, 8]),
    number2C: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const multiplier4 = [11, 12, 21, 22] as const;

    // Equation A:
    const { number1A, number2A } = rejectionSample(
      () => {
        const number1A = getRandomFromArray([2, 3, 4, 5, 8] as const);

        const number2A =
          number1A === 5 || number1A === 8 // If number1A is 5 or 8, only 11 is valid for number2A - ternary added to speed up generator.
            ? 11
            : number1A === 4
            ? getRandomFromArray(multiplier4)
            : randomIntegerInclusive(11, 99, {
                constraint: x => x % 10 !== 0
              });

        return { number1A, number2A };
      },
      ({ number1A, number2A }) => numbersDoNotHaveMultiplicationExchange(number2A, number1A)
    );

    // Equation B:
    const { number1B, number2B } = rejectionSample(
      () => {
        const number1B = getRandomFromArray([2, 3, 4, 5, 8] as const);

        const number2B =
          number1B === 5 || number1B === 8 // If number1B is 5 or 8, only 11 is valid for number2B - ternary added to speed up generator.
            ? 11
            : number1B === 4
            ? getRandomFromArray(multiplier4)
            : randomIntegerInclusive(11, 99, {
                constraint: x => x % 10 !== 0
              });

        return { number1B, number2B };
      },
      ({ number1B, number2B }) =>
        numbersDoNotHaveMultiplicationExchange(number2B, number1B) &&
        arrayHasNoDuplicates([number1A, number1B]) &&
        arrayHasNoDuplicates([number2A, number2B])
    );

    // Equation C:
    const { number1C, number2C } = rejectionSample(
      () => {
        const number1C = getRandomFromArray([2, 3, 4, 5, 8] as const);

        const number2C =
          number1C === 5 || number1C === 8 // If number1C is 5 or 8, only 11 is valid for number2C - ternary added to speed up generator.
            ? 11
            : number1C === 4
            ? getRandomFromArray(multiplier4)
            : randomIntegerInclusive(11, 99, {
                constraint: x => x % 10 !== 0
              });

        return { number1C, number2C };
      },
      ({ number1C, number2C }) =>
        numbersDoNotHaveMultiplicationExchange(number2C, number1C) &&
        arrayHasNoDuplicates([number1A, number1B, number1C]) &&
        arrayHasNoDuplicates([number2A, number2B, number2C])
    );

    return {
      number1A,
      number2A,
      number1B,
      number2B,
      number1C,
      number2C
    };
  },
  Component: props => {
    const {
      question: { number1A, number2A, number1B, number2B, number1C, number2C },
      translate
    } = props;

    const equations = shuffle(
      [
        {
          equation: `${number2A} ${MULT} ${number1A} = <ans/>`,
          answer: number2A * number1A
        },
        {
          equation: `${number2B} ${MULT} ${number1B} = <ans/>`,
          answer: number2B * number1B
        },
        {
          equation: `<ans/> = ${number2C} ${MULT} ${number1C}`,
          answer: number2C * number1C
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={equations.map(eq => [eq.answer.toString()])}
        inputMaxCharacters={2}
        sentences={equations.map(eq => eq.equation)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFS',
  description: 'aFS',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 5, 8]),
    number2: z.number().int().min(10).max(40).multipleOf(10),
    number3: z.number().int().min(1).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const { number1, number2, number3 } = rejectionSample(
      () => {
        const number1 = getRandomFromArray([2, 3, 4, 5, 8] as const);

        const number2 = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * number1 < 100
        });

        const number3 = randomIntegerInclusive(1, 5);

        return { number1, number2, number3 };
      },
      ({ number1, number2, number3 }) => number3 * number1 <= 10 && number3 + number2 >= 13
    );

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number2 + number3;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentencesToSolveX(
          `${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()}`
        )}
        testCorrect={userAnswer =>
          userAnswer[0][0] === (number2 / 10).toString() &&
          userAnswer[0][1] === number3.toString() &&
          parseInt(userAnswer[1][0]) + parseInt(userAnswer[1][1]) === number4 * number1 &&
          userAnswer[2][0] === (number4 * number1).toString()
        }
        inputMaxCharacters={2}
        sentences={[
          `${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()} = <ans/> ${translate.powersOfTen.tens(
            number2 / 10
          )} ${MULT} ${number1.toLocaleString()} + <ans/> ${translate.powersOfTen.ones(
            number3
          )} ${MULT} ${number1.toLocaleString()}`,
          `${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()} = <ans/> + <ans/>`,
          `${number4.toLocaleString()} ${MULT} ${number1.toLocaleString()} = <ans/>`
        ]}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        textStyle={{ fontSize: 32 }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(number2 / 10).toLocaleString(), number3.toLocaleString()],
            [(number2 * number1).toLocaleString(), (number3 * number1).toLocaleString()],
            [(number4 * number1).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aFT',
  description: 'aFT',
  keywords: ['Multiplication', 'Partition', '2-digit'],
  schema: z.object({
    number1A: numberEnum([2, 3, 4, 5, 8]),
    number2A: z.number().int().min(10).max(40).multipleOf(10),
    number3A: z.number().int().min(1).max(5),
    number1B: numberEnum([2, 3, 4, 5, 8]),
    number2B: z.number().int().min(10).max(40).multipleOf(10),
    number3B: z.number().int().min(1).max(5),
    number1C: numberEnum([2, 3, 4, 5, 8]),
    number2C: z.number().int().min(10).max(40).multipleOf(10),
    number3C: z.number().int().min(1).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const {
      number1A,
      number2A,
      number3A,
      number1B,
      number2B,
      number3B,
      number1C,
      number2C,
      number3C
    } = rejectionSample(
      () => {
        const [number1A, number1B, number1C] = getRandomSubArrayFromArray(
          [2, 3, 4, 5, 8] as const,
          3
        );

        // Equation A
        const number2A = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * number1A < 100
        });

        const number3A = randomIntegerInclusive(1, 5, {
          constraint: x => x * number1A <= 10
        });

        // Equation B
        const number2B = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * number1B < 100
        });

        const number3B = randomIntegerInclusive(1, 5, {
          constraint: x => x * number1B <= 10
        });

        // Equation C
        const number2C = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * number1C < 100
        });

        const number3C = randomIntegerInclusive(1, 5, {
          constraint: x => x * number1C <= 10
        });

        return {
          number1A,
          number2A,
          number3A,
          number1B,
          number2B,
          number3B,
          number1C,
          number2C,
          number3C
        };
      },
      ({ number2A, number3A, number2B, number3B, number2C, number3C }) =>
        number2A + number3A >= 13 && number2B + number3B >= 13 && number2C + number3C >= 13
    );

    return {
      number1A,
      number2A,
      number3A,
      number1B,
      number2B,
      number3B,
      number1C,
      number2C,
      number3C
    };
  },
  Component: props => {
    const {
      question: {
        number1A,
        number2A,
        number3A,
        number1B,
        number2B,
        number3B,
        number1C,
        number2C,
        number3C
      },
      translate
    } = props;

    const number4A = number2A + number3A;

    const number4B = number2B + number3B;

    const number4C = number2C + number3C;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === number1A.toString() &&
          userAnswer[0][1] === number1A.toString() &&
          ((userAnswer[1][0] === number2B.toString() && userAnswer[1][1] === number1B.toString()) ||
            (userAnswer[1][0] === number1B.toString() &&
              userAnswer[1][1] === number2B.toString())) &&
          userAnswer[2][0] === number4C.toString()
        }
        inputMaxCharacters={2}
        sentences={[
          `${number4A.toLocaleString()} ${MULT} ${number1A.toLocaleString()} = ${number2A.toLocaleString()} ${MULT} <ans/> + ${number3A.toLocaleString()} ${MULT} <ans/>`,
          `${number4B.toLocaleString()} ${MULT} ${number1B.toLocaleString()} = <ans/> ${MULT} ${number1B.toLocaleString()} + ${number3B.toLocaleString()} ${MULT} <ans/>`,
          `<ans/> ${MULT} ${number1C.toLocaleString()} = ${number2C.toLocaleString()} ${MULT} ${number1C.toLocaleString()} + ${number3C.toLocaleString()} ${MULT} ${number1C.toLocaleString()}`
        ]}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number1A.toLocaleString(), number1A.toLocaleString()],
            [number2B.toLocaleString(), number1B.toLocaleString()],
            [number4C.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA2DigitNumberByA1DigitNumberNoExchange',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
