import { Svg, Path, SvgProps, Pattern, Rect, Defs } from 'react-native-svg';
import { colors } from '../../../theme/colors';

const HEIGHT = 100;

type Props = {
  ribbonLength: number;
  color: 'red' | 'green';
  tail: 'left' | 'right';
};

/**
 * Flexible ribbon that scales across the width but the height remains constant
 */
const FlexibleRibbon = ({ ribbonLength, color, tail, ...svgProps }: Props & SvgProps) => {
  const length = ribbonLength;

  const [color1, color2] =
    color !== 'red'
      ? [colors.fieryRose, colors.fieryRose600]
      : [colors.seaGreenCrayolaPressIn, colors.seaGreenCrayolaMid];

  return (
    <Svg viewBox={`0 0 ${length} ${HEIGHT}`} width={length} height={HEIGHT} {...svgProps}>
      <Defs>
        <Pattern
          id="Pattern"
          x="0"
          y="0"
          width="160"
          height={`${HEIGHT}`}
          patternUnits="userSpaceOnUse"
        >
          <Rect x="0" y="0" width="80" height={HEIGHT} fill={color1} />
          <Rect x="80" y="0" width="80" height={HEIGHT} fill={color2} />
        </Pattern>
      </Defs>
      <Path
        d={`M${length} ${HEIGHT}
        ${tail === 'right' ? ` L ${length - 40} ${HEIGHT / 2}` : ''}
        L${length} 0
        L 0 0
        ${tail === 'left' ? ` L ${40} ${HEIGHT / 2}` : ''}
        L0 ${HEIGHT}
        Z`}
        fill="url(#Pattern)"
        stroke="black"
        strokeWidth="3"
      />
    </Svg>
  );
};

export default FlexibleRibbon;
