import { newTermContent } from '../../Term';
import PlaceValue from './PlaceValue';
import AdditionAndSubtraction from './AdditionAndSubtraction';
import Shape from './Shape';

const Term = newTermContent({
  term: 'Autumn',
  blocks: [PlaceValue, AdditionAndSubtraction, Shape]
});
export default Term;
