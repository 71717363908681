/**
 * Chunk items in array
 * E.g [1, 2, 3, 4, 5, 6, 7] with a chunk of 2 -> [[1, 2], [3, 4], [5, 6], [7]]
 */
export const chunk = <T>(arr: T[], num: number) => {
  const chunked = [];
  for (let i = 0; i < arr.length; i += num) {
    chunked.push(arr.slice(i, i + num));
  }
  return chunked;
};
