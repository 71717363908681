import axios from 'axios';
import ENV from '../ENV';

const BASE_URL = ENV.API_URL;

const api = axios.create({
  baseURL: BASE_URL
});

export default api;
