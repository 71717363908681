import QF1ContentAndSentence from './QF1ContentAndSentence';
import { type StyleProp, TextStyle, ViewStyle } from 'react-native';
import { HeaderVariant } from '../representations/Place Value Chart/TableHeaderRow';
import { CounterVariant } from 'common/src/components/question/representations/types';
import { ScientificNotation } from 'common/src/utils/math';
import { PlaceValueColumn } from 'common/src/components/question/representations/Place Value Chart/PlaceValueCounters';
import PlaceValueChart from 'common/src/components/question/representations/Place Value Chart/PlaceValueChart';
import { useI18nContext } from '../../../i18n/i18n-react';
import { ExtraSymbols } from '../../molecules/UserInput';

type Props = {
  number: ScientificNotation;
  columnsToShow: PlaceValueColumn[];
  counterVariant: CounterVariant;
  sentence?: string;
  testCorrect?: string[] | ((userAnswer: string[]) => boolean);
  inputMaxCharacters?: number;
  title?: string;
  sentenceStyle?: StyleProp<ViewStyle>;
  pdfSentenceStyle?: StyleProp<TextStyle>;
  headerVariant?: HeaderVariant;
  extraSymbol?: ExtraSymbols;
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
  questionHeight?: number;
};

export default function ReadPlaceValueChart({
  number,
  columnsToShow,
  counterVariant,
  sentence = '<ans/>',
  testCorrect = answer => ScientificNotation.fromNumber(parseFloat(answer[0])).equals(number),
  inputMaxCharacters = number.toNumber().toString().length,
  title,
  headerVariant,
  extraSymbol,
  sentenceStyle,
  pdfSentenceStyle,
  customMarkSchemeAnswer = { answersToDisplay: [number.toNumber().toLocaleString()] },
  questionHeight
}: Props) {
  const translate = useI18nContext().LL;
  title = title ?? translate.instructions.whatNumberIsShown();

  return (
    <QF1ContentAndSentence
      title={title}
      testCorrect={testCorrect}
      inputMaxCharacters={inputMaxCharacters}
      sentence={sentence}
      extraSymbol={extraSymbol}
      customMarkSchemeAnswer={customMarkSchemeAnswer}
      sentenceStyle={sentenceStyle ?? { justifyContent: 'flex-end' }}
      pdfSentenceStyle={pdfSentenceStyle ?? { justifyContent: 'flex-end' }}
      pdfDirection="column"
      Content={({ dimens }) => (
        <PlaceValueChart
          number={number}
          columnsToShow={columnsToShow}
          counterVariant={counterVariant}
          headerVariant={headerVariant}
          dimens={dimens}
        />
      )}
      questionHeight={questionHeight}
    />
  );
}
