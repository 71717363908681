import { newYearContent } from '../Year';
import Autumn from './Autumn';
import Spring from './Spring';
import Summer from './Summer';

const Year = newYearContent({
  year: 'Year4',
  terms: [Autumn, Spring, Summer]
});
export default Year;
