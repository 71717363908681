import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import { SUB } from '../../../../constants';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { isEqual, isInRange } from '../../../../utils/matchers';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import { View } from 'react-native';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhk',
  description: 'bhk',
  keywords: ['Subtract', 'Base 10'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(val => val % 10 !== 0),
      number2: z.number().int().min(10).max(98),
      reversed: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.number1, -val.number2),
      'number1 must have greater than or equal to the number of number2 tens and ones'
    )
    .refine(val => isInRange(1, 88)(val.number1 - val.number2)),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(10, number1 - 1, {
      constraint: x =>
        Math.floor(number1 / 10) >= Math.floor(x / 10) &&
        number1 % 10 >= x % 10 &&
        isInRange(1, 88)(number1 - x)
    });

    const reversed = getRandomBoolean();

    return { number1, number2, reversed };
  },
  Component: props => {
    const {
      question: { number1, number2, reversed },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      [number1, number2, number1 - number2],
      SUB,
      2,
      { reversed }
    );

    const ones = number1 % 10;
    const tens = Math.floor(number1 / 10);

    const crossedOutOnes = number2 % 10;
    const crossedOutTens = Math.floor(number2 / 10);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={ones}
            tens={tens}
            dimens={{ width: dimens.width, height: dimens.height }}
            crossedOutIndices={{
              ones: crossedOutOnes === 0 ? [] : range(ones - 1, ones - crossedOutOnes),
              tens: range(tens - 1, tens - crossedOutTens)
            }}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bhl',
  description: 'bhl',
  keywords: ['Subtract'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(12)
        .max(99)
        .refine(val => val % 10 !== 0),
      number2: z.number().int().min(10).max(98),
      options: z.number().int().min(1).max(99).array().length(4)
    })
    .refine(
      val =>
        Math.floor(val.number1 / 10) >= Math.floor(val.number2 / 10) &&
        val.number1 % 10 >= val.number2 % 10,
      'number1 must have greater than or equal to the number of number2 tens and ones'
    )
    .refine(val => isInRange(1, 88)(val.number1 - val.number2)),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 99, { constraint: x => x % 10 !== 0 });
    // go to two smaller so that we have enough incorrect answers
    const number2 = randomIntegerInclusive(10, number1 - 2, {
      constraint: x =>
        Math.floor(number1 / 10) >= Math.floor(x / 10) &&
        number1 % 10 >= x % 10 &&
        isInRange(1, 88)(number1 - x)
    });

    const answer = number1 - number2;

    const options = [
      answer,
      ...getRandomSubArrayFromArray(
        [answer + 10, answer - 10, answer + 1, answer - 1].filter(val => val > 0),
        3
      )
    ];
    return {
      number1,
      number2,
      options: shuffle(options)
    };
  },
  Component: ({ question, translate }) => {
    const { number1, number2, options } = question;

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.whatIsXOperationY(number1, SUB, number2)}
        pdfTitle={`${translate.ks1Instructions.whatIsXOperationY(
          number1,
          SUB,
          number2
        )}<br/>${translate.ks1PDFInstructions.tickTheCorrectAnswer()}`}
        items={options}
        testCorrect={[number1 - number2]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhm',
  description: 'bhm',
  keywords: ['Subtract', 'Bar model'],
  schema: z
    .object({
      isAnsLeft: z.boolean(),
      number1: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(val => val % 10 !== 0),
      number2: z.number().int().min(10).max(98),
      isArrow: z.boolean()
    })
    .refine(
      val =>
        Math.floor(val.number1 / 10) >= Math.floor(val.number2 / 10) &&
        val.number1 % 10 >= val.number2 % 10,
      'number1 must have greater than or equal to the number of number2 tens and ones'
    )
    .refine(val => isInRange(1, 88)(val.number1 - val.number2)),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(10, number1 - 1, {
      constraint: x =>
        Math.floor(number1 / 10) >= Math.floor(x / 10) &&
        number1 % 10 >= x % 10 &&
        isInRange(1, 88)(number1 - x)
    });

    const isAnsLeft = getRandomBoolean();
    const isArrow = isAnsLeft ? getRandomBoolean() : false;

    return {
      number1,
      number2,
      isAnsLeft,
      isArrow
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, isAnsLeft, isArrow } = question;

    const [topRow, bottomRow] = [[number1], [number2, number1 - number2]];

    const answerIndices = isAnsLeft ? [[], [0]] : [[], [1]];
    const arrowIndices = isAnsLeft ? [[1]] : [[0]];

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheBarModel()}
        actionPanelVariant="endWide"
        inputType="numpad"
        Content={({ dimens }) => {
          return isArrow ? (
            <View style={{ gap: 50 }}>
              <BarModelWithState
                numbers={[topRow]}
                total={number1}
                dimens={dimens}
                answerIndices={[answerIndices[0]]}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
                id={'barModel-B'}
              />
              <BarModelWithState
                id="bar-model-Arrow"
                numbers={[bottomRow]}
                answerIndices={[answerIndices[1]]}
                total={number1}
                dimens={dimens}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
                oneFontSize
                testCorrect={userAnswer =>
                  isEqual(bottomRow[isAnsLeft ? 0 : 1].toString())(userAnswer[0][isAnsLeft ? 0 : 1])
                }
                arrowIndices={arrowIndices}
                testComplete={userAnswer =>
                  answerIndices[1].every((_, rowIndex) => userAnswer[0][rowIndex] !== '')
                }
                defaultState={
                  displayMode === 'markscheme'
                    ? [bottomRow.map(val => val.toLocaleString())]
                    : [['', '']]
                }
              />
            </View>
          ) : (
            <BarModelWithState
              id="bar-model-A"
              numbers={[topRow, bottomRow]}
              answerIndices={answerIndices}
              total={number1}
              dimens={dimens}
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              oneFontSize
              testCorrect={userAnswer =>
                isEqual(bottomRow[isAnsLeft ? 0 : 1].toString())(userAnswer[1][isAnsLeft ? 0 : 1])
              }
              testComplete={userAnswer =>
                answerIndices.every((row, rowIndex) =>
                  row.every(pos => {
                    const userString = (userAnswer[rowIndex] ?? [])[pos] ?? '';
                    return userString !== '';
                  })
                )
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [[''], bottomRow.map(val => val.toLocaleString())]
                  : [[''], ['', '']]
              }
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo2DigitNumbersNotAcrossA10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
