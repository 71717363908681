import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from 'common/src/utils/random';
import { range, rangeExcluding } from 'common/src/utils/collections';
import QF8DragIntoUpTo3Groups from 'common/src/components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { fluent } from '@codibre/fluent-iterable';
import { roundToTheNearest } from 'common/src/utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acM',
  description: 'acM',
  keywords: ['Place value', 'Round', 'Number line', '10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(11)
      .max(99)
      .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10')
  }),
  example: {
    number: 43
  },
  simpleGenerator: () => {
    // Limit tens to 1-9, ones to 1-9
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const number = tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 10) * 10;
    const endNumber = Math.ceil(number / 10) * 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest10(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'acN',
  description: 'acN',
  keywords: ['Place value', 'Round', 'Sort', '10'],
  schema: z.object({
    numbers: z
      .array(
        z
          .number()
          .int()
          .min(11)
          .max(99)
          .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10')
      )
      .length(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 9);
    // Find random numbers excluding the 5
    const ones = getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 5);
    const numbers = ones.map(value => {
      return tens * 10 + value;
    });
    // Include the 5
    numbers.push(tens * 10 + 5);
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / 10) * 10;
    const upper = Math.ceil(numbers[0] / 10) * 10;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, 10) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(10)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(10)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'acO',
  description: 'acO',
  keywords: ['Place value', 'Round', '10'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(99)).length(3)
  }),
  example: {
    numbers: [6, 72, 38]
  },
  simpleGenerator: () => {
    const numAnswers = 3;
    const numberSet = new Set<number>();
    while (numberSet.size < numAnswers) {
      const ones = randomIntegerInclusive(1, 9);
      const tens = randomIntegerInclusive(0, 9);
      numberSet.add(tens * 10 + ones);
    }
    const numbers = fluent(numberSet).toArray();
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 10);
      const sentence = `${number}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(10)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'acP',
  description: 'acP',
  keywords: ['Place value', 'Round', 'Number line', '10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(110)
      .max(989)
      .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10')
  }),
  example: {
    number: 777
  },
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 8);
    const hundreds = randomIntegerInclusive(1, 9);
    const number = hundreds * 100 + tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 10) * 10;
    const endNumber = Math.ceil(number / 10) * 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest10(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10).toString()]}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'acQ',
  description: 'acQ',
  keywords: ['Place value', 'Round', '10'],
  schema: z.object({
    comparitor: z.number().int().step(10).min(20).max(90),
    numbers: z.number().int().min(0).max(100).array().length(6)
  }),
  example: {
    comparitor: 40,
    numbers: [33, 35, 38, 41, 45, 48]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = randomIntegerInclusive(2, 9) * 10;

    //$var-11<num1<$var-5,
    const num1 = randomIntegerInclusive(comparitor - 10, comparitor - 6);

    //num2=$var-5,
    const num2 = comparitor - 5;

    //$var-5<num3<$var,
    const num3 = randomIntegerInclusive(comparitor - 4, comparitor - 1);

    //num4<$var+5,
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 4);

    //num5=$var+5,
    const num5 = comparitor + 5;

    //$var+5<num6<$var+11
    const num6 = randomIntegerInclusive(comparitor + 6, comparitor + 10);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumsRoundToNumToNearestNum(comparitor, 10)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 10) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'acR',
  description: 'acR',
  keywords: ['Place value', 'Round', '10'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(999)).length(4)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    // $var1 is not a multiple of 5,
    const var1Ones = getRandomFromArray(rangeExcluding(1, 9, [5]));
    const var1Tens = randomIntegerInclusive(1, 9);
    const var1 = var1Tens * 10 + var1Ones;

    // $var2 is an odd multiple of 5,
    const var2Multiple = getRandomFromArray(range(1, 199, 2));
    const var2 = var2Multiple * 5;

    // $var3 is a multiple of 100+-Rnd(1 to 4),
    const var3Rand = randomIntegerInclusive(-4, 4);
    const var3Hundreds = randomIntegerInclusive(1, 9) * 100;
    const var3 = var3Hundreds + var3Rand;

    // $var4 is less than 10.
    const var4 = randomIntegerInclusive(1, 9);

    const numbers = shuffle([var1, var2, var3, var4]);
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 10);
      const sentence = `${number}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(10)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearest10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
