import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbk',
  description: 'bbk',
  keywords: ['Number sentence', 'Add'],
  schema: z
    .object({
      num1: z.number().int().min(0).max(10),
      num2: z.number().int().min(0).max(10),
      flipped: z.boolean(),
      answer: z.enum(['left', 'right', 'result'])
    })
    .refine(({ num1, num2 }) => isInRange(2, 10)(num1 + num2)),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(0, 10);
    const num2 = randomIntegerInclusive(0, 10, { constraint: x => isInRange(2, 10)(x + num1) });
    const answer = getRandomFromArray(['left', 'right', 'result'] as const);
    const flipped = getRandomBoolean();

    return {
      num1,
      num2,
      answer,
      flipped
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, answer, flipped } = question;

    const sentence = getBinOpEquation({
      left: num1,
      right: num2,
      result: num1 + num2,
      sign: 'add',
      flipped,
      answer
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbm',
  description: 'bbm',
  keywords: ['Add', 'Word problem', 'Multi-step'],
  schema: z
    .object({
      names: z.array(ks1NameSchema).length(2),
      sweetsA: z.number().int().min(1).max(4),
      diffSweets: z.number().int().min(1).max(7),
      options: z
        .array(z.number().int().min(1).max(10))
        .length(4)
        .refine(x => arrayHasNoDuplicates(x), 'Options should be different')
    })
    .refine(
      ({ sweetsA, diffSweets }) => isInRange(4, 10)(2 * sweetsA + diffSweets),
      'Total should be between 4 and 10'
    ),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);

    const sweetsA = randomIntegerInclusive(1, 4);
    const diffSweets = randomIntegerInclusive(1, 7, {
      constraint: x => isInRange(4, 10)(2 * sweetsA + x)
    });

    const total = 2 * sweetsA + diffSweets;

    const possibleOptions = [
      ...new Set([total, total - 1, sweetsA + diffSweets, total + 1 < 10 ? total + 1 : total])
    ];

    const extraOptions = randomUniqueIntegersInclusive(1, 10, 4 - possibleOptions.length, {
      constraint: x => arrayHasNoDuplicates([...possibleOptions, x])
    });

    return {
      names,
      sweetsA,
      diffSweets,
      options: shuffle([...possibleOptions, ...extraOptions])
    };
  },
  Component: ({ question, translate }) => {
    const { names, sweetsA, diffSweets, options } = question;
    const total = 2 * sweetsA + diffSweets;

    const [nameA, nameB] = names;

    const items = options.map(n => ({
      value: n,
      component: <Text variant="WRN400">{n.toLocaleString()}</Text>
    }));

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.ks1Instructions.charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether(
          { nameA, nameB, sweetsA, diffSweets }
        )}<br/>${translate.ks1Instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.ks1Instructions.charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether(
          { nameA, nameB, sweetsA, diffSweets }
        )}<br/>${translate.ks1PDFInstructions.tickYourAnswer()}`}
        renderItems={items}
        numItems={4}
        itemLayout="row"
        innerContainerStyle={{ alignItems: 'flex-end' }}
        itemStyle={{ height: 100 }}
        testCorrect={[total]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AdditionProblems',
  questionTypes: [Question1, Question3],
  unpublishedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
