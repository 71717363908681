import { getRandomFromArray } from './random';
import { z } from 'zod';

export const brokenAndUnbrokenShapes = {
  broken: [
    'Arrow with 1 break',
    'Arrow with 2 breaks',
    'Circle with 1 break',
    'Circle with 2 breaks',
    'Cross with 1 break',
    'Cross with 2 breaks',
    'Hexagon with 1 break',
    'Hexagon with 2 breaks',
    'Isoscles trapezium with 1 break',
    'Isoscles trapezium with 2 breaks',
    'Kite with 1 break',
    'Kite with 2 breaks',
    'Narrow isosceles triangle with 1 break',
    'Narrow isosceles triangle with 2 breaks',
    'Parallelogram with 1 break',
    'Parallelogram with 2 breaks',
    'Pentagon with 1 break',
    'Pentagon with 2 breaks',
    'Pentagon house with 1 break',
    'Pentagon house with 2 breaks',
    'Rectangle with 1 break',
    'Rectangle with 2 breaks',
    'Rhombus with 1 break',
    'Rhombus with 2 breaks',
    'Right-angle trapezium with 1 break',
    'Right-angle trapezium with 2 breaks',
    'Right-angle triangle with 1 break',
    'Right-angle triangle with 2 breaks',
    'Scalene triangle with 1 break',
    'Scalene triangle with 2 breaks',
    'Semi-circle with 1 break',
    'Semi-circle with 2 breaks',
    'Square with 1 break',
    'Square with 2 breaks',
    'Triangle with 1 break',
    'Triangle with 2 breaks',
    'Wide isosceles triangle with 1 break',
    'Wide isosceles triangle with 2 breaks'
  ],
  unbroken: [
    'Arrow',
    'Circle',
    'Semi-circle',
    'Cross',
    'Hexagon',
    'Isoscles trapezium',
    'Kite',
    'Narrow isosceles triangle',
    'Parallelogram',
    'Pentagon',
    'Pentagon house',
    'Rectangle',
    'Rhombus',
    'Right-angle trapezium',
    'Right-angle triangle',
    'Scalene triangle',
    'Square',
    'Triangle',
    'Wide isosceles triangle'
  ]
} as const;

export const brokenAndUnbrokenShapeSchema = z.enum([
  ...brokenAndUnbrokenShapes.broken,
  ...brokenAndUnbrokenShapes.unbroken
]);

export const brokenShapeSchema = z.enum([...brokenAndUnbrokenShapes.broken]);

export const unbrokenShapeSchema = z.enum([...brokenAndUnbrokenShapes.unbroken]);

export type BrokenAndUnbroken = keyof typeof brokenAndUnbrokenShapes;

export type BrokenAndUnbrokenShapeName =
  (typeof brokenAndUnbrokenShapes)[BrokenAndUnbroken][number];

export function getRandomBrokenAndUnbrokenShape() {
  return getRandomFromArray([
    ...brokenAndUnbrokenShapes.broken,
    ...brokenAndUnbrokenShapes.unbroken
  ] as const);
}

export function getRandomBrokenShape() {
  return getRandomFromArray([...brokenAndUnbrokenShapes.broken] as const);
}

export function getRandomUnbrokenShape() {
  return getRandomFromArray([...brokenAndUnbrokenShapes.unbroken] as const);
}

export function isBrokenOrUnbroken(shape: BrokenAndUnbrokenShapeName): 'broken' | 'unbroken' {
  return brokenAndUnbrokenShapes.broken.some(it => it === shape) ? 'broken' : 'unbroken';
}

export const brokenAndUnbrokenShapeToAssetPath = {
  Arrow: 'Unbroken_shapes/arrow_transparent',
  'Arrow with 1 break': 'Broken_shapes/arrow_break_1',
  'Arrow with 2 breaks': 'Broken_shapes/arrow_break_2',
  Circle: 'Unbroken_shapes/circle_transparent',
  'Circle with 1 break': 'Broken_shapes/circle_break_1',
  'Circle with 2 breaks': 'Broken_shapes/circle_break_2',
  Cross: 'Unbroken_shapes/cross_transparent',
  'Cross with 1 break': 'Broken_shapes/cross_break_1',
  'Cross with 2 breaks': 'Broken_shapes/cross_break_2',
  Hexagon: 'Unbroken_shapes/hexagon_transparent',
  'Hexagon with 1 break': 'Broken_shapes/hexagon_break_1',
  'Hexagon with 2 breaks': 'Broken_shapes/hexagon_break_2',
  'Isoscles trapezium': 'Unbroken_shapes/trapezium_isosceles_transparent',
  'Isoscles trapezium with 1 break': 'Broken_shapes/trapezium_isosceles_break_1',
  'Isoscles trapezium with 2 breaks': 'Broken_shapes/trapezium_isosceles_break_2',
  Kite: 'Unbroken_shapes/kite_transparent',
  'Kite with 1 break': 'Broken_shapes/kite_break_1',
  'Kite with 2 breaks': 'Broken_shapes/kite_break_2',
  'Narrow isosceles triangle': 'Unbroken_shapes/triangle_isos_narrow_transparent',
  'Narrow isosceles triangle with 1 break': 'Broken_shapes/triangle_isos_narrow_break_1',
  'Narrow isosceles triangle with 2 breaks': 'Broken_shapes/triangle_isos_narrow_break_2',
  Parallelogram: 'Unbroken_shapes/parallelogram_transparent',
  'Parallelogram with 1 break': 'Broken_shapes/parallelogram_break_1',
  'Parallelogram with 2 breaks': 'Broken_shapes/parallelogram_break_2',
  Pentagon: 'Unbroken_shapes/pentagon_transparent',
  'Pentagon with 1 break': 'Broken_shapes/pentagon_break_1',
  'Pentagon with 2 breaks': 'Broken_shapes/pentagon_break_2',
  'Pentagon house': 'Unbroken_shapes/pentagon_house_transparent',
  'Pentagon house with 1 break': 'Broken_shapes/pentagon_house_break_1',
  'Pentagon house with 2 breaks': 'Broken_shapes/pentagon_house_break_2',
  Rectangle: 'Unbroken_shapes/rectangle_transparent',
  'Rectangle with 1 break': 'Broken_shapes/rectangle_break_1',
  'Rectangle with 2 breaks': 'Broken_shapes/rectangle_break_2',
  Rhombus: 'Unbroken_shapes/rhombus_transparent',
  'Rhombus with 1 break': 'Broken_shapes/rhombus_break_1',
  'Rhombus with 2 breaks': 'Broken_shapes/rhombus_break_2',
  'Right-angle trapezium': 'Unbroken_shapes/trapezium_RA_transparent',
  'Right-angle trapezium with 1 break': 'Broken_shapes/trapezium_RA_break_1',
  'Right-angle trapezium with 2 breaks': 'Broken_shapes/trapezium_RA_break_2',
  'Right-angle triangle': 'Unbroken_shapes/triangle_RA_transparent',
  'Right-angle triangle with 1 break': 'Broken_shapes/triangle_RA_break_1',
  'Right-angle triangle with 2 breaks': 'Broken_shapes/triangle_RA_break_2',
  'Scalene triangle': 'Unbroken_shapes/triangle_scalene_transparent',
  'Scalene triangle with 1 break': 'Broken_shapes/triangle_scalene_break_1',
  'Scalene triangle with 2 breaks': 'Broken_shapes/triangle_scalene_break_2',
  'Semi-circle': 'Unbroken_shapes/circle_semi_transparent',
  'Semi-circle with 1 break': 'Broken_shapes/circle_semi_break_1',
  'Semi-circle with 2 breaks': 'Broken_shapes/circle_semi_break_2',
  Square: 'Unbroken_shapes/square_transparent',
  'Square with 1 break': 'Broken_shapes/square_break_1',
  'Square with 2 breaks': 'Broken_shapes/square_break_2',
  Triangle: 'Unbroken_shapes/triangle_equal_transparent',
  'Triangle with 1 break': 'Broken_shapes/triangle_break_1',
  'Triangle with 2 breaks': 'Broken_shapes/triangle_break_2',
  'Wide isosceles triangle': 'Unbroken_shapes/triangle_isos_wide_transparent',
  'Wide isosceles triangle with 1 break': 'Broken_shapes/triangle_isos_wide_break_1',
  'Wide isosceles triangle with 2 breaks': 'Broken_shapes/triangle_isos_wide_break_2'
};
