import { useContext } from 'react';
import { colors } from 'common/src/theme/colors';
import { Line, Polygon } from 'react-native-svg';
import { GridContext } from 'common/src/components/question/representations/Coordinates/Grid';
import { TSpan, Text as SvgText } from 'react-native-svg';

export function GridPolygon({
  points,
  color,
  showBorder = false,
  opacity,
  borderWidth
}: {
  points: [number, number][];
  color?: string | null;
  showBorder?: boolean;
  opacity?: number;
  borderWidth?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <Polygon
      points={points.map(([x, y]) => `${mathToSvgX(x)},${mathToSvgY(y)}`).join(' ')}
      fill={color === null ? 'none' : color ?? `${colors.pacificBlue}30`}
      opacity={opacity}
      stroke={showBorder ? colors.prussianBlue : undefined}
      strokeWidth={showBorder ? borderWidth ?? 2 : 0}
    />
  );
}

export function GridText({
  label,
  coordinates,
  fontSize = 36
}: {
  label: string;
  coordinates: [number, number];
  fontSize?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <SvgText y={mathToSvgY(coordinates[1])} x={mathToSvgX(coordinates[0])} textAnchor="middle">
      <TSpan fontSize={fontSize} fill="black" fontFamily="White_Rose_Noto-Regular">
        {label}
      </TSpan>
    </SvgText>
  );
}

export function GridLine({
  points,
  color,
  strokeWidth,
  strokeDasharray
}: {
  points: [number, number][];
  color?: string;
  strokeWidth?: number;
  strokeDasharray?: number[];
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <Line
      x1={mathToSvgX(points[0][0])}
      y1={mathToSvgY(points[0][1])}
      x2={mathToSvgX(points[1][0])}
      y2={mathToSvgY(points[1][1])}
      stroke={color ?? colors.prussianBlue}
      strokeWidth={strokeWidth ?? 2}
      strokeDasharray={strokeDasharray}
    />
  );
}
