import MissingDigitColumnOperations, {
  MissingDigitColumnOperationsProps
} from './MissingDigitColumnOperations';

type Props = Omit<
  MissingDigitColumnOperationsProps,
  | 'userAnswer'
  | 'onUserAnswerChanged'
  | 'topMissingDigits'
  | 'bottomMissingDigits'
  | 'answerMissingDigits'
>;

/**
 * This component renders a column operation on a grid, made to represent grid paper.
 * The number of columns of squares is determined by the numbers and answer.
 * There is a blank row and column along the top, bottom, left and right of the column operation.
 * The operator will always show on the third row, second column of the grid.
 * The answer bar is calculated to be the correct answer's length, if no answer prop is passed.
 */
export default function ColumnOperations(props: Props) {
  return (
    <MissingDigitColumnOperations
      {...props}
      topMissingDigits={[]}
      bottomMissingDigits={[]}
      answerMissingDigits={[]}
    />
  );
}
