import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { findExchanges, numbersDoNotExchange } from 'common/src/utils/exchanges';
import ColumnOperations from 'common/src/components/question/representations/ColumnOperations/ColumnOperations';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from 'common/src/constants';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import { useMemo } from 'react';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aiS',
  description: 'aiS',
  keywords: ['Addition', 'Counters'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(10_000)
        .max(100_000)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10_000)
        .max(100_000)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => numbersDoNotExchange(val.var1, val.var2), 'should not exchange')
    .refine(val => val.var1 + val.var2 < 100_000, 'total should be less than 100,000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(10_000, 100_000, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(10_000, 100_000, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have no exchanges
      ({ var1, var2 }) => var1 + var2 < 100_000 && numbersDoNotExchange(var1, var2)
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aiT',
  description: 'aiT',
  keywords: ['Addition', 'Inverse', 'Number bonds'],
  schema: z.object({
    numberA1: z.number().int().min(101).max(899),
    answerBoxPositionA: z.enum(['left', 'right']),
    numberB1: z.number().int().min(1001).max(8999),
    answerBoxPositionB: z.enum(['left', 'right']),
    numberC1: z.number().int().min(101).max(999),
    numberC1Position: z.enum(['left', 'right']),
    answerBoxPositionC: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const [numberA1, numberC1] = randomUniqueIntegersInclusive(101, 899, 2, {
      constraint: x => x % 10 !== 0
    });

    const answerBoxPositionA = getRandomFromArray(['left', 'right'] as const);

    const numberB1 = randomIntegerInclusive(1001, 8999, {
      constraint: x => x % 10 !== 0
    });

    const answerBoxPositionB = getRandomFromArray(['left', 'right'] as const);

    const numberC1Position = getRandomFromArray(['left', 'right'] as const);

    const answerBoxPositionC = getRandomFromArray(['left', 'right'] as const);

    return {
      numberA1,
      answerBoxPositionA,
      numberB1,
      answerBoxPositionB,
      numberC1,
      numberC1Position,
      answerBoxPositionC
    };
  },

  Component: props => {
    const {
      question: {
        numberA1,
        answerBoxPositionA,
        numberB1,
        answerBoxPositionB,
        numberC1,
        numberC1Position,
        answerBoxPositionC
      },
      translate
    } = props;

    const numberA2 = 1000 - numberA1;

    const numberB2 = 10000 - numberB1;

    const numberC2 = 10000 - numberC1;

    const sentenceA =
      answerBoxPositionA === 'left'
        ? `<ans/> ${ADD} ${numberA2.toLocaleString()} = ${(1000).toLocaleString()}`
        : `${numberA1.toLocaleString()} ${ADD} <ans/> = ${(1000).toLocaleString()}`;

    const sentenceB =
      answerBoxPositionB === 'left'
        ? `<ans/> ${ADD} ${numberB2.toLocaleString()} = ${(10000).toLocaleString()}`
        : `${numberB1.toLocaleString()} ${ADD} <ans/> = ${(10000).toLocaleString()}`;

    const sentenceC = (() => {
      if (numberC1Position === 'left') {
        return `${answerBoxPositionC === 'left' ? '<ans/>' : numberC1.toLocaleString()} ${ADD} ${
          answerBoxPositionC === 'left' ? numberC2.toLocaleString() : '<ans/>'
        } = ${(10000).toLocaleString()}`;
      } else {
        return `${answerBoxPositionC === 'left' ? '<ans/>' : numberC2.toLocaleString()} ${ADD} ${
          answerBoxPositionC === 'left' ? numberC1.toLocaleString() : '<ans/>'
        } = ${(10000).toLocaleString()}`;
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        sentences={[sentenceA, sentenceB, sentenceC]}
        testCorrect={[
          [answerBoxPositionA === 'left' ? numberA1.toString() : numberA2.toString()],
          [answerBoxPositionB === 'left' ? numberB1.toString() : numberB2.toString()],
          [
            (answerBoxPositionC === 'left' && numberC1Position === 'left') ||
            (answerBoxPositionC === 'right' && numberC1Position === 'right')
              ? numberC1.toString()
              : numberC2.toString()
          ]
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aiU',
  description: 'aiU',
  keywords: ['Addition', 'Column'],
  schema: z
    .object({
      numberA1: z.number().int().min(10001).max(99_999),
      numberA2: z.number().int().min(101).max(999),
      numberB1: z.number().int().min(10001).max(99_999),
      numberB2: z.number().int().min(101).max(999),
      numberC1: z.number().int().min(10001).max(99_999),
      numberC2: z.number().int().min(101).max(999),
      numberD1: z.number().int().min(10001).max(99_999),
      numberD2: z.number().int().min(101).max(999)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1]),
      'numberA1, numberB1 and numberC1 must all be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.numberA2, val.numberB2, val.numberC2]),
      'numberA2, numberB2 and numberC2 must all be different.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(10001, 99_000);
        const numberA2 = randomIntegerInclusive(101, 999);
        return { numberA1, numberA2 };
      },
      ({ numberA1, numberA2 }) => numberA1 + numberA2 * 100 < 100_000
    );
    const [numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(10001, 99_000, 3, {
      constraint: x => x !== numberA1
    });

    const [numberB2, numberC2, numberD2] = randomUniqueIntegersInclusive(101, 999, 3, {
      constraint: x => x !== numberA2
    });

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        topNumber: numberA1,
        bottomNumber: numberA2 * 100,
        isCorrect: false,
        topBlankDigits: [],
        bottomBlankDigits: [0, 1]
      };
      const eqB = {
        topNumber: numberB1,
        bottomNumber: numberB2,
        isCorrect: false,
        topBlankDigits: [],
        bottomBlankDigits: [0]
      };
      const eqC = {
        topNumber: numberC2,
        bottomNumber: numberC1,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      const eqD = {
        topNumber: numberD1,
        bottomNumber: numberD2,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectColAddsWhichAreLaidOutCorrectly()}
        pdfTitle={translate.instructions.circleColAddsWhichAreLaidOutCorrectly()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
                removeExtraCells
                topBlankDigits={equation.topBlankDigits}
                bottomBlankDigits={equation.bottomBlankDigits}
              />
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aiV',
  description: 'aiV',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(10_000)
        .max(100_000)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10_000)
        .max(100_000)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => findExchanges(val.var1, val.var2).length === 1, 'should be 1 exchange')
    .refine(val => val.var1 + val.var2 < 100_000, 'total should be less than 100,000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(10_000, 100_000, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(10_000, 100_000, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have one exchange
      ({ var1, var2 }) => var1 + var2 < 100_000 && findExchanges(var1, var2).length === 1
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Question5 exported to Q ajy
const Question5 = newQuestionContent({
  uid: 'aiW',
  description: 'aiW',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(10000).max(89999),
      number2: z.number().int().min(10000).max(89999)
    })
    .refine(
      val => val.number1 + val.number2 < 100000,
      'number1 + number2 must be less than 100,000'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const number1 = randomIntegerInclusive(10000, 89999);
        const number2 = randomIntegerInclusive(10000, 99999 - number1);
        return { number1, number2 };
      },
      // Only permit them if they have 2-3 exchanges.
      ({ number1, number2 }) => findExchanges(number1, number2).length > 1
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXAddY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const aiW = Question5;

// Question6 exported to Q ajB
const Question6 = newQuestionContent({
  uid: 'aiX',
  description: 'aiX',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      topNumber: z.number().int().min(10000).max(89999),
      bottomNumber: z.number().int().min(10000).max(89999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer']),
      missingTenThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val =>
        findExchanges(val.topNumber, val.bottomNumber).length > 0 &&
        findExchanges(val.topNumber, val.bottomNumber).length < 3,
      'topNumber and bottomNumber must have one or two exchanges.'
    )
    .refine(
      val => val.topNumber + val.bottomNumber < 100000,
      'topNumber + bottomNumber must be less than 100,000'
    ),
  simpleGenerator: () => {
    const { topNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping topNumber uniformly distributed
        const topNumber = randomIntegerInclusive(10000, 89999);
        const bottomNumber = randomIntegerInclusive(10000, 99999 - topNumber);
        return { topNumber, bottomNumber };
      },
      // Only permit them if they have 1 or 2 exchanges.
      ({ topNumber, bottomNumber }) =>
        findExchanges(topNumber, bottomNumber).length > 0 &&
        findExchanges(topNumber, bottomNumber).length < 3
    );

    const numberWithExtraMissingDigit1 = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    // Filter out numberWithExtraMissingDigit1 so we do not get three missing digits in the same number.
    const numberWithExtraMissingDigit2 = getRandomFromArray(
      ['top', 'bottom', 'answer'].filter(item => item !== numberWithExtraMissingDigit1)
    ) as 'top' | 'bottom' | 'answer';

    const [missingOnes, missingTens, missingHundreds, missingThousands, missingTenThousands] =
      shuffle([
        'top',
        'bottom',
        'answer',
        numberWithExtraMissingDigit1,
        numberWithExtraMissingDigit2
      ] as const);

    return {
      topNumber,
      bottomNumber,
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands,
      missingTenThousands
    };
  },

  Component: props => {
    const {
      question: {
        topNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands,
        missingTenThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands,
      missingTenThousands
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const aiX = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddWholeNumbersWithMoreThanFourDigits',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
