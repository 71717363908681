import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { DayName, dayAsWord, dayNames } from '../../../utils/days';
import Table from '../../molecules/Table';
import { useI18nContext as commonUseI18nContext } from 'common/src/i18n/i18n-react';
import { useContext, useMemo } from 'react';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import { MonthName, getDayNameForGivenDate, monthNames } from '../../../utils/months';

type Props = {
  month: MonthName;
  year: number;
  dimens?: Dimens;
};

/**
 * Function to display a calendar table.
 * Takes in a number of days, the day of the week that the 1st of the calendar should be (defaults to Monday),
 * and available dimens (defaults to {width: 960, height: 510}).
 */
export const Calendar = ({
  month,
  year,
  dimens: { width, height } = { width: 960, height: 510 }
}: Props) => {
  const translate = commonUseI18nContext().LL;

  const firstDayOfMonth = getDayNameForGivenDate(new Date(year, monthNames.indexOf(month), 1));
  const numberOfDays = new Date(year, monthNames.indexOf(month) + 1, 0).getDate();
  const numberOfBlankDaysBeforeStart = dayNames.indexOf(firstDayOfMonth as DayName);

  const totalRows = Math.ceil((numberOfDays + numberOfBlankDaysBeforeStart) / 7);

  const tableHeight = height * 0.9;

  const styles = useStyles(width, tableHeight, totalRows);

  const rows = [];

  for (let i = 0; i < totalRows; i++) {
    const currentRow = [];
    for (let j = 0; j < 7; j++) {
      const currentDate = i * 7 + j - numberOfBlankDaysBeforeStart + 1;
      if (currentDate <= 0 || currentDate > numberOfDays) {
        currentRow.push(<View style={[styles.cell, styles.blankCell]} />);
      } else {
        currentRow.push(
          <View style={[styles.cell]} key={currentDate}>
            <Text variant={'WRN700'} style={[styles.text]}>
              {currentDate.toLocaleString()}
            </Text>
          </View>
        );
      }
    }
    rows.push(currentRow);
  }

  const daysRow = dayNames.map(day => (
    <View style={[styles.cell, styles.headerCell]} key={day}>
      <Text variant={'WRN700'} style={[styles.headerText, styles.text]}>
        {dayAsWord(day, translate)}
      </Text>
    </View>
  ));

  return (
    <View style={styles.container}>
      <Table items={[daysRow, ...rows]} />
    </View>
  );
};

const useStyles = (width: number, height: number, totalRows: number) => {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        blankCell: {
          backgroundColor: displayMode === 'digital' ? '#C8CFE6' : colors.pdfShading
        },
        cell: {
          // totalRows + 1 to account for the header row of days of the week.
          height: height / (totalRows + 1) - 2,
          width: width / 7
        },
        container: {
          height: height
        },
        headerCell: {
          backgroundColor: displayMode === 'digital' ? '#225CA5' : colors.greys100
        },
        headerText: {
          color: displayMode === 'digital' ? 'white' : 'black'
        },
        text: {
          alignItems: 'center',
          display: 'flex',
          fontSize: displayMode === 'digital' ? 22 : 40,
          justifyContent: 'center',
          // totalRows + 1 to account for the header row of days of the week.
          lineHeight: height / (totalRows + 1) - 2,
          textAlign: 'center'
        }
      }),
    [height, totalRows, width, displayMode]
  );
};
