import {
  gridSwitchRowsOf1,
  gridSwitchRowsOf2,
  gridSwitchRowsOf3,
  gridSwitchRowsOf5
} from './gridSwitch';
import { splitByTwo, splitByNine, splitByTen } from './splitNumberUtil';

export type gridDef = {
  grids: { grid: number[][]; dimens: { width: number; height: number } }[];
  totalHeight: number;
  totalWidth: number;
};

export const gridCalcRowsOf1 = (number: number): gridDef => {
  if (number === 0) {
    return {
      grids: [{ grid: [], dimens: { width: 0, height: 0 } }],
      totalHeight: 0,
      totalWidth: 0
    };
  }

  const setOfOnes = new Array(number).fill({ amount: 1, isRemainder: false });

  const grids: { grid: number[][]; dimens: { width: number; height: number } }[] = [];

  setOfOnes.forEach(set => grids.push(gridSwitchRowsOf1(set.amount)));

  const widths = grids.map(object => object.dimens.width);

  const totalHeight = grids.reduce((accumulated, object) => accumulated + object.dimens.height, 0);

  const totalWidth = Math.max(...widths);

  return { grids: grids, totalHeight: totalHeight, totalWidth: totalWidth };
};

export const gridCalcRowsOf2 = (number: number): gridDef => {
  // Number is split into an array of 2s, and any remainder.
  const setOfTwos = splitByTwo(number);

  const grids: { grid: number[][]; dimens: { width: number; height: number } }[] = [];

  // Each set of 2 and remainder goes through the switch statement, and is pushed into the grids, giving an array of grids and dimensions for each set
  setOfTwos.forEach(set => grids.push(gridSwitchRowsOf2(set.amount)));

  const widths = grids.map(object => object.dimens.width);

  const totalHeight = grids.reduce((accumulated, object) => accumulated + object.dimens.height, 0);

  const totalWidth = Math.max(...widths);

  return { grids: grids, totalHeight: totalHeight, totalWidth: totalWidth };
};

export const gridCalcRowsOf3 = (number: number): gridDef => {
  // Number is split into an array of 9s, and any remainder.
  const setOfNines = splitByNine(number);

  const grids: { grid: number[][]; dimens: { width: number; height: number } }[] = [];

  // Each set of 9 and remainder goes through the switch statement, and is pushed into the grids, giving an array of grids and dimensions for each set
  // isRemainder also passed to get back a different styling/grid layout for remainders 2, 3, 4, 5, 6, 7
  setOfNines.forEach(set => grids.push(gridSwitchRowsOf3(set.amount, set.isRemainder)));

  const widths = grids.map(object => object.dimens.width);

  const totalHeight = grids.reduce((accumulated, object) => accumulated + object.dimens.height, 0);

  const totalWidth = Math.max(...widths);

  return { grids: grids, totalHeight: totalHeight, totalWidth: totalWidth };
};

export const gridCalcRowsOf5 = (number: number): gridDef => {
  // Number is split into an array of 10s, and any remainder.
  const setOfTens = splitByTen(number);

  const grids: { grid: number[][]; dimens: { width: number; height: number } }[] = [];

  // Each set of 10 and remainder goes through the switch statement, and is pushed into the grids, giving an array of grids and dimensions for each set
  setOfTens.forEach(set => grids.push(gridSwitchRowsOf5(set.amount)));

  const totalHeight = grids.reduce((accumulated, object) => accumulated + object.dimens.height, 0);

  const widths = grids.map(object => object.dimens.width);

  const totalWidth = Math.max(...widths);

  return { grids: grids, totalHeight: totalHeight, totalWidth: totalWidth };
};
