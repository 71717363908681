export function throwError(message?: string, options?: ErrorOptions): never {
  throw new Error(message, options);
}

/** Do nothing */
export function noop(): void {
  /* Do nothing */
}

/** Run the same function several times */
export function repeat(iterations: number, func: () => void) {
  for (let i = 0; i < iterations; i++) {
    func();
  }
}
