import { newBlockContent } from '../../../Block';

import AddByCountingOnWithin20 from './1AddByCountingOnWithin20';
import AddOnesUsingNumberBonds from './2AddOnesUsingNumberBonds';
import FindAndMakeNumberBondsTo20 from './3FindAndMakeNumberBondsTo20';
import Doubles from './4Doubles';
import NearDoubles from './5NearDoubles';
import SubtractOnesUsingNumberBonds from './6SubtractOnesUsingNumberBonds';
import SubtractionCountingBack from './7SubtractionCountingBack';
import SubtractionFindingTheDifference from './8SubtractionFindingTheDifference';
import RelatedFacts from './9RelatedFacts';
import MissingNumberProblems from './10MissingNumberProblems';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 6],
  smallSteps: [
    AddByCountingOnWithin20, // Step 1
    AddOnesUsingNumberBonds, // Step 2
    FindAndMakeNumberBondsTo20, // Step 3
    Doubles, // Step 4
    NearDoubles, // Step 5
    SubtractOnesUsingNumberBonds, // Step 6
    SubtractionCountingBack, // Step 7
    SubtractionFindingTheDifference, // Step 8
    RelatedFacts, // Step 9
    MissingNumberProblems // Step 10
  ]
});
export default Block;
