import { useContext, useMemo } from 'react';
import { View, type StyleProp, type ViewStyle } from 'react-native';
import { SetState, projectSetState } from '../../../../utils/react';
import { GridContext, GridSvgChildren } from './Grid';
import Animated, {
  SharedValue,
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { Line } from 'react-native-svg';
import { LineGraphColors, colors } from '../../../../theme/colors';
import { withStateHOC } from '../../../../stateTree';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { ScaleFactorContext } from '../../../../theme/scaling';
import { countRange } from '../../../../utils/collections';
import Text from '../../../typography/Text';
import { DisplayMode } from '../../../../contexts/displayMode';
import { AssetSvg, type SvgNameCustomizable } from '../../../../assets/svg';
import { useSharedValues } from '../../../../utils/animation';

const AnimatedLine = Animated.createAnimatedComponent(Line);

type LineGraphColor = (typeof LineGraphColors)[number];
const colorToPointSvg: Record<LineGraphColor, SvgNameCustomizable> = {
  [colors.pacificBlue]: 'Coordinates/CirclePointCustomizable',
  [colors.red]: 'Coordinates/CrossPointCustomizable',
  [colors.orange2]: 'Coordinates/SquarePointCustomizable'
};

type Props = {
  /** y coordinates for each vertical gridline, starting from xMin and counting up in xStepSize. */
  points: number[];
  /** Updater for `points`. Leave this undefined for non-interactive line graph. */
  setPoints?: SetState<number[]>;
  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';
  /**
   * Default: 'Coordinates/CirclePointCustomizable', or depending on color in PDF mode (to make them different when printed
   * greyscale) - only supports the colors defined in {@link LineGraphColors}. */
  pointSvg?: SvgNameCustomizable;
  /** Defaults to burntSienna if interactive, pacificBlue otherwise. */
  color?: LineGraphColor | string;
  /** Point radius. Defaults to 13 if interactive, 7 otherwise, enlarged slightly in PDF mode. */
  r?: number;
  /** Line width. Defaults to 3, enlarged slightly in PDF mode. */
  lineWidth?: number;
};

/**
 * Line graph. Must be placed within a `Grid`. Just consists of the points and connecting lines.
 *
 * Interactive if `setPoints` is provided.
 *
 * The length of the `points` array must not change!
 *
 * Limitation: the points provided always lie on the vertical grid lines.
 */
export default function LineGraph({
  points,
  setPoints,
  snapToNearest,
  pointSvg: pointSvgProp,
  color = setPoints !== undefined ? colors.burntSienna : colors.pacificBlue,
  r: rProp,
  lineWidth: lineWidthProp
}: Props) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';
  const pointSvg: SvgNameCustomizable =
    pointSvgProp ?? (isPdf && LineGraphColors.includes(color as LineGraphColor))
      ? colorToPointSvg[color as LineGraphColor]
      : 'Coordinates/CirclePointCustomizable';

  const pdfModeEnlargement = isPdf ? 40 / 21.667 : 1;
  const r = rProp ?? (setPoints !== undefined ? 13 : 7) * pdfModeEnlargement;
  const lineWidth = lineWidthProp ?? 3 * pdfModeEnlargement;

  // In this component, be aware of the differences between "svgY" and "mathY" - svgY is a pixel coordinate
  const { mathToSvgY } = useContext(GridContext);
  const animatedSvgYArray = useSharedValues(points.length, points.map(mathToSvgY));

  const setMathYArray = useMemo(
    () =>
      points.map((_, index) => (y: number) => setPoints && projectSetState(setPoints, index)(y)),
    [points, setPoints]
  );

  return (
    <>
      <GridSvgChildren>
        {countRange(points.length - 1).map(i => (
          <ConnectingLine
            key={i}
            index={i}
            animatedSvgYPair={{ left: animatedSvgYArray[i], right: animatedSvgYArray[i + 1] }}
            color={color}
            lineWidth={lineWidth}
          />
        ))}
      </GridSvgChildren>
      {countRange(points.length).map(i => (
        <Point
          key={`point-${i}`}
          index={i}
          animatedSvgY={animatedSvgYArray[i]}
          setMathY={setMathYArray[i]}
          snapToNearest={snapToNearest}
          r={r}
          color={color}
          pointSvg={pointSvg}
          interactive={setPoints !== undefined}
        />
      ))}
    </>
  );
}

/** Like {@link LineGraph}, but this uses StateTree. */
export const LineGraphWithState = withStateHOC(LineGraph, {
  stateProp: 'points',
  setStateProp: 'setPoints'
});

type ConnectingLineProps = {
  index: number;
  animatedSvgYPair: { left: SharedValue<number>; right: SharedValue<number> };
  color: string;
  lineWidth: number;
};

/** A dashed line connecting two points. The given index indicates the left point. */
function ConnectingLine({ index, animatedSvgYPair, color, lineWidth }: ConnectingLineProps) {
  const { mathToSvgX, xMin, xStepSize } = useContext(GridContext);

  const x = xMin + index * xStepSize;

  const animatedProps = useAnimatedProps(() => {
    return {
      y1: animatedSvgYPair.left.value,
      y2: animatedSvgYPair.right.value
    };
  }, [animatedSvgYPair]);

  return (
    <AnimatedLine
      x1={mathToSvgX(x)}
      x2={mathToSvgX(x + xStepSize)}
      animatedProps={animatedProps}
      stroke={color}
      strokeWidth={lineWidth}
      strokeDasharray={`${lineWidth * 1.4} ${lineWidth * 1.4}`}
    />
  );
}

type PointProps = {
  index: number;
  animatedSvgY: SharedValue<number>;
  setMathY?: (mathY: number) => void;
  r: number;
  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';
  pointSvg: SvgNameCustomizable;
  color: string;
  interactive?: boolean;
};

/**
 * A point on a line graph, which may be interactive (dragged up and down).
 */
function Point({
  index,
  animatedSvgY,
  setMathY,
  r,
  snapToNearest: snapToNearestProp,
  pointSvg,
  color,
  interactive = false
}: PointProps) {
  const { mathToSvgX, mathToSvgY, svgToMathY, xMin, xStepSize, yMin, yMax, yStepSize } =
    useContext(GridContext);
  const scaleFactor = useContext(ScaleFactorContext);

  const snapToNearest = snapToNearestProp === 'grid' ? yStepSize : snapToNearestProp;

  const x = xMin + index * xStepSize;

  ////
  // Gesture
  ////

  const beginGestureY = useSharedValue<number | null>(null);
  const beginSvgY = useSharedValue<number | null>(null);

  const gesture = useMemo(() => {
    return Gesture.Pan()
      .onBegin(event => {
        beginGestureY.value = event.absoluteY;
        beginSvgY.value = animatedSvgY.value;
      })

      .onUpdate(event => {
        const translationY = (event.absoluteY - beginGestureY.value!) / scaleFactor;
        const currentSvgYCoord = beginSvgY.value! + translationY;

        // Clamp to stay in the grid
        animatedSvgY.value = mathToSvgY(
          Math.min(Math.max(svgToMathY(currentSvgYCoord), yMin), yMax)
        );
      })

      .onFinalize(() => {
        let newMathY = svgToMathY(animatedSvgY.value);

        // Snap to the grid
        if (snapToNearest !== undefined) {
          newMathY = Math.round((newMathY - yMin) / snapToNearest) * snapToNearest + yMin;
          animatedSvgY.value = withTiming(mathToSvgY(newMathY));
        }

        // Update outside of the animation
        setMathY && runOnJS(setMathY)(newMathY);
      });
  }, [
    animatedSvgY,
    beginGestureY,
    beginSvgY,
    mathToSvgY,
    scaleFactor,
    setMathY,
    snapToNearest,
    svgToMathY,
    yMax,
    yMin
  ]);

  ////
  // JSX
  ////

  const animatedStyle = useAnimatedStyle(
    () => ({
      top: animatedSvgY.value - r
    }),
    [animatedSvgY, r]
  );

  const view = (
    <Animated.View
      style={[
        {
          position: 'absolute',
          left: mathToSvgX(x) - r,
          width: 96,
          height: 96
        },
        animatedStyle
      ]}
    >
      <AssetSvg name={pointSvg} width={r * 2} height={r * 2} svgProps={{ fill: color }} />
    </Animated.View>
  );

  return interactive ? <GestureDetector gesture={gesture}>{view}</GestureDetector> : view;
}

/** Key (AKA legend) for a graph with multiple LineGraphs. Simply shows the dots next to their labels. */
export function Key({
  colors,
  labels,
  pointSvgs: pointSvgsProp,
  style
}: {
  colors: (string | LineGraphColor)[];
  labels: string[];
  /**
   * Default: Coordinates/CirclePointCustomizable, or depending on color in PDF mode (to make them different when printed
   * greyscale) - only supports the colors defined in {@link LineGraphColors}.
   */
  pointSvgs?: SvgNameCustomizable[];
  style?: StyleProp<ViewStyle>;
}) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';
  const pointSvgs: SvgNameCustomizable[] =
    pointSvgsProp ??
    colors.map(color =>
      isPdf && LineGraphColors.includes(color as LineGraphColor)
        ? colorToPointSvg[color as LineGraphColor]
        : 'Coordinates/CirclePointCustomizable'
    );

  const fontSize = isPdf ? 40 : 21.667;

  return (
    <View style={[{ flexDirection: 'row', gap: fontSize * 1.5 }, style]}>
      {labels.map((label, i) => (
        <View
          key={label}
          style={{ flexDirection: 'row', gap: fontSize * 0.5, alignItems: 'center' }}
        >
          <AssetSvg
            name={pointSvgs[i]}
            width={fontSize}
            height={fontSize}
            svgProps={{ fill: colors[i] }}
          />
          <Text variant="WRN400" style={{ fontSize, lineHeight: fontSize * 1.5 }}>
            {label}
          </Text>
        </View>
      ))}
    </View>
  );
}
