import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { DisplayShapeOnGrid } from 'common/src/components/question/representations/DisplayShapeOnGrid';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { createHundredSquareShape } from 'common/src/utils/shapes';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from 'common/src/utils/math';
import QF4DragOrderVertical from 'common/src/components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from 'common/src/utils/collections';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { fractionSchema } from 'common/src/utils/zod';
import { Fraction, fractionToDecimal } from 'common/src/utils/fractions';
import { all, create, number } from 'mathjs';

const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRq',
  description: 'aRq',
  keywords: ['Percentages', 'Fractions', 'Hundred square'],
  schema: z.object({
    percentage: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const percentage = randomIntegerInclusive(1, 100);

    return { percentage };
  },
  Component: props => {
    const {
      question: { percentage },
      translate
    } = props;

    const shape = createHundredSquareShape(percentage);

    return (
      <QF1ContentAndSentence
        sentence={'<ans/> % = <frac nAns="" d="100" />'}
        title={translate.instructions.whatPercentageAndFractionOfHundredSquareShaded()}
        testCorrect={[percentage.toString(), percentage.toString()]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRr',
  description: 'aRr',
  keywords: ['Percentages', 'Fractions', 'Equivalent'],
  schema: z.object({
    numerator: z.number().int().min(1).max(99),
    percentageB: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(1, 99);
    const percentageB = randomIntegerInclusive(1, 99);

    return { numerator, percentageB };
  },

  Component: props => {
    const {
      question: { numerator, percentageB },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutEquivalentFractionsAndPercentages()}
        testCorrect={[[numerator.toString()], [percentageB.toString()]]}
        sentences={[
          `<frac n="${numerator.toLocaleString()}" d="100" /> = <ans/> %`,
          `<frac nAns="" d="100" /> = ${percentageB.toLocaleString()} %`
        ]}
        fractionContainerStyle={{ height: 90 }}
        pdfContainerStyle={{ rowGap: 64 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRs',
  description: 'aRs',
  keywords: ['Percentages', 'Fractions', 'Hundred square'],
  schema: z.object({
    percentage: z.number().int().min(10).max(100).multipleOf(10)
  }),
  simpleGenerator: () => {
    const percentage = randomIntegerInclusiveStep(10, 100, 10);

    return { percentage };
  },
  Component: props => {
    const {
      question: { percentage },
      translate
    } = props;

    const shape = createHundredSquareShape(percentage);

    const numerator = percentage / 10;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/> % = <frac nAns="" d="10" />'}
        title={translate.instructions.useHundredSquareToHelpWorkOutEquivalentFractionAndPercentage()}
        testCorrect={[percentage.toString(), numerator.toString()]}
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRt',
  description: 'aRt',
  keywords: ['Percentages', 'Fractions', 'Compare'],
  schema: z.object({
    percentageA: z.number().int().min(1).max(100),
    percentageB: z.number().int().min(1).max(100),
    percentageC: z.number().int().min(1).max(100),
    numeratorA: z.number().int().min(1).max(100),
    numeratorB: z.number().int().min(1).max(100),
    numeratorC: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [percentageA, percentageB, percentageC] = randomUniqueIntegersInclusive(1, 100, 3);

    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(1, 100, 2);
    const numeratorC = randomIntegerInclusive(1, 9);

    return { percentageA, percentageB, percentageC, numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { percentageA, percentageB, percentageC, numeratorA, numeratorB, numeratorC },
      translate,
      displayMode
    } = props;

    // Statements
    const statements = shuffle(
      [
        {
          sentence: `${percentageA.toLocaleString()}% <ans/> <frac n="${numeratorA.toLocaleString()}" d="100" />`,
          answer: lessThanGreaterThanOrEqualTo(
            number(math.evaluate(`${percentageA} / 100`)),
            fractionToDecimal(numeratorA, 100)
          )
        },
        {
          sentence: `<frac n="${numeratorB.toLocaleString()}" d="100" /> <ans/> ${percentageB.toLocaleString()}% `,
          answer: lessThanGreaterThanOrEqualTo(
            fractionToDecimal(numeratorB, 100),
            number(math.evaluate(`${percentageB} / 100`))
          )
        },
        {
          sentence: `<frac n="${numeratorC.toLocaleString()}" d="10" /> <ans/> ${percentageC.toLocaleString()}%`,
          answer: lessThanGreaterThanOrEqualTo(
            fractionToDecimal(numeratorC, 10),
            number(math.evaluate(`${percentageC} / 100`))
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useInequalitiesToCompleteSentences()}
        items={['<', '>', '=']}
        pdfLayout="itemsHidden"
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={statements.map(statement => [statement.answer])}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        moveOrCopy="copy"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRu',
  description: 'aRu',
  keywords: ['Order', 'Fractions', 'Percentages'],
  schema: z.object({
    percentageA: z.number().int().min(1).max(99),
    percentageB: z.number().int().min(1).max(99),
    fractionA: z.number().int().min(1).max(99),
    fractionB: z.number().int().min(1).max(99),
    smallestOrGreatest: z.enum(['Smallest', 'Greatest'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [percentageA, percentageB, fractionA, fractionB] = randomUniqueIntegersInclusive(
      1,
      99,
      4
    );

    const smallestOrGreatest = getRandomFromArray(['Smallest', 'Greatest'] as const);

    return { percentageA, percentageB, fractionA, fractionB, smallestOrGreatest };
  },
  Component: props => {
    const {
      question: { percentageA, percentageB, fractionA, fractionB, smallestOrGreatest },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [
        {
          value: percentageA,
          string: `${percentageA.toLocaleString()}%`
        },
        {
          value: percentageB,
          string: `${percentageB.toLocaleString()}%`
        },
        {
          value: fractionA,
          string: `<frac n="${fractionA.toLocaleString()}" d="100" />`
        },
        {
          value: fractionB,
          string: `<frac n="${fractionB.toLocaleString()}" d="100" />`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={
          smallestOrGreatest === 'Smallest'
            ? translate.instructions.dragCardsOrderValuesSmallestToGreatest()
            : translate.instructions.dragCardsOrderValuesGreatestToSmallest()
        }
        pdfTitle={
          smallestOrGreatest === 'Smallest'
            ? translate.instructions.useCardsOrderValuesSmallestToGreatest()
            : translate.instructions.useCardsOrderValuesGreatestToSmallest()
        }
        testCorrect={sortNumberArray(
          items.map(x => x.value),
          smallestOrGreatest === 'Smallest' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={items.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              textVariant="WRN700"
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          )
        }))}
        topLabel={
          smallestOrGreatest === 'Smallest'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          smallestOrGreatest === 'Smallest'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aRv',
  description: 'aRv',
  keywords: ['Percentages', 'Fractions'],
  schema: z.object({
    answerOptions: fractionSchema().array().length(6)
  }),
  simpleGenerator: () => {
    const { answerOptions } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusiveStep(5, 45, 5);
        const numeratorB = randomIntegerInclusive(1, 4);
        const numeratorC = randomIntegerInclusiveStep(10, 90, 10);
        const numeratorD = randomIntegerInclusive(1, 9);
        const numeratorE = randomIntegerInclusive(1, 10);
        const denominatorG = randomIntegerInclusiveStep(110, 200, 10);
        const numeratorF = randomIntegerInclusiveStep(10, denominatorG - 10, 10);

        const answerOptions: Fraction[] = shuffle([
          [numeratorA, 50],
          [numeratorB, 5],
          [numeratorC, 100],
          [numeratorD, 10],
          [numeratorE, 50],
          [numeratorF, denominatorG]
        ]);

        return {
          answerOptions
        };
      },
      // Ensure there are always between 1-5 correct answers
      ({ answerOptions }) =>
        answerOptions.filter(frac => fractionToDecimal(...frac) >= 0.5).length >= 1 &&
        answerOptions.filter(frac => fractionToDecimal(...frac) >= 0.5).length < 6
    );

    return {
      answerOptions
    };
  },
  Component: props => {
    const {
      question: { answerOptions },
      translate,
      displayMode
    } = props;

    return (
      <QF10SelectNumbers<Fraction>
        title={translate.instructions.selectTheFractionsThatAreGreaterThanOrEqualToPercentage(50)}
        pdfTitle={translate.instructions.circleTheFractionsThatAreGreaterThanOrEqualToPercentage(
          50
        )}
        testCorrect={answerOptions.filter(frac => {
          return fractionToDecimal(...frac) >= 0.5;
        })}
        items={answerOptions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: frac
        }))}
        multiSelect
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PercentagesAsFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
