import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bas',
  description: 'bas',
  keywords: ['Count', 'Ten frame', 'More'],
  schema: z.object({
    num: z.number().int().min(1).max(9),
    ordering: z.enum(['rowFirst', 'columnFirst'])
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(1, 9);
    const ordering = getRandomFromArray(['rowFirst', 'columnFirst'] as const);
    return { num, ordering };
  },
  Component: props => {
    const {
      question: { num, ordering },
      translate
    } = props;

    const correctAnswer = num + 1;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.drag1MoreCounterOnToTheTenFrame()}
        pdfTitle={translate.ks1PDFInstructions.draw1MoreThanNum(num)}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={['yellow']}
        itemOrdering={ordering}
        interactiveCells="notInitial"
        initialState={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < num) return 'red';
        }}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < correctAnswer) return 'red';
        }}
        testCorrect={totalCountersIs(correctAnswer)}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bau',
  description: 'bau',
  keywords: ['Addition'],
  schema: z.object({
    number: z.number().int().min(0).max(10),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const variant = getRandomBoolean();
    const number = randomIntegerInclusive(0, variant ? 10 : 9);
    return { number, variant };
  },

  Component: props => {
    const {
      question: { number, variant },
      translate
    } = props;

    const answer = variant ? number - 1 : number + 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          variant
            ? translate.ks1AnswerSentences.oneMoreThanAnsIsX(number)
            : translate.ks1AnswerSentences.oneMoreThanXIsAns(number)
        }
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneMore',
  questionTypes: [Question1, Question3],
  unpublishedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
