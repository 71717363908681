import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { CustomizableTable } from 'common/src/components/question/representations/CustomizableTable';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import {
  breadSchema,
  breadFillingsSchema,
  getRandomUniqueBreadNames,
  getRandomUniqueBreadFillingsNames,
  getRandomUniqueIceCreamFlavourNames,
  getRandomUniqueIceCreamToppingNames,
  iceCreamFlavoursSchema,
  iceCreamToppingsItemsSchema
} from 'common/src/utils/food';
import { colors } from 'common/src/theme/colors';
import { MULT } from 'common/src/constants';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from 'common/src/components/typography/Text';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { AssetSvg } from 'common/src/assets/svg';
import { getCharacterHeadSvgName } from 'common/src/utils/characters';
import { getRandomName, getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { displayMoney } from 'common/src/utils/money';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aK2',
  description: 'aK2',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    breadTotal: z.number().int().min(2).max(4),
    fillingTotal: z.number().int().min(2).max(4),
    breadItems: z.array(breadSchema),
    breadFillingItems: z.array(breadFillingsSchema)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const breadTotal = randomIntegerInclusive(2, 4);
    const fillingTotal = randomIntegerInclusive(2, 4, {
      constraint: x => (breadTotal === 2 ? x !== 2 : true)
    });
    const breadItems = getRandomUniqueBreadNames(breadTotal);
    const breadFillingItems = getRandomUniqueBreadFillingsNames(fillingTotal);

    return { breadTotal, fillingTotal, breadItems, breadFillingItems };
  },
  Component: ({
    question: { breadTotal, fillingTotal, breadItems, breadFillingItems },
    translate,
    displayMode
  }) => {
    const answer = breadTotal * fillingTotal;

    // Get largest total between items/fillings
    // Works out how many rows needed in table
    const indexLength = Math.max(breadTotal, fillingTotal);

    // Create data array
    const data: (string | JSX.Element)[][] = [];

    // Loop over indexLength and push items/fillings to each column
    // If an empty column return empty string
    for (let i = 0; i < indexLength; i++) {
      data.push([breadItems[i] ?? '', breadFillingItems[i] ?? '']);
    }

    return (
      <QF1ContentAndSentence
        title={translate.instructions.HowManyDifferentCombinationsOfBreadAndFillingsCompleteTheSentence()}
        pdfDirection="column"
        Content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.food.bread.Name(),
                containerStyle: {
                  backgroundColor: colors.yellow,
                  padding: 0
                },
                textStyle: {
                  color: colors.prussianBlue
                }
              },
              {
                label: translate.food.ingredients.Fillings(),
                containerStyle: {
                  backgroundColor: colors.yellow,
                  padding: 0
                },
                textStyle: {
                  color: colors.prussianBlue
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 40}
            tableCellStyle={{
              maxHeight: 60,
              padding: 0
            }}
          />
        )}
        sentence={translate.answerSentences.thereAreAnsCombinations()}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[answer.toString()]}
        questionHeight={800}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aK3',
  description: 'aK3',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    coinsATotal: z.number().int().min(2).max(4),
    coinsBTotal: z.number().int().min(2).max(4),
    pounds: z.array(z.enum(['£1', '£2', '50p', '20p'])),
    pence: z.array(z.enum(['1p', '2p', '5p', '10p'])),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const coinsATotal = randomIntegerInclusive(2, 4);
    const coinsBTotal = randomIntegerInclusive(2, 4, {
      constraint: x => (coinsATotal === 2 ? x !== 2 : true)
    });

    const pounds = getRandomSubArrayFromArray(['£1', '£2', '50p', '20p'] as const, coinsATotal);
    const pence = getRandomSubArrayFromArray(['1p', '2p', '5p', '10p'] as const, coinsBTotal);

    const name = getRandomName();

    return { coinsATotal, coinsBTotal, pounds, pence, name };
  },
  Component: props => {
    const {
      question: { coinsATotal, coinsBTotal, pounds, pence, name },
      translate,
      displayMode
    } = props;

    // Options
    const optionA = coinsATotal * coinsBTotal;
    const optionB = coinsATotal * coinsBTotal * 2;
    const optionC = Math.ceil((coinsATotal * coinsBTotal) / 2);
    const optionD = Math.ceil(coinsATotal / coinsBTotal);

    const options = shuffle([optionA, optionB, optionC, optionD], {
      random: seededRandom(props.question)
    });

    // Display
    const poundsDisplay = displayMoney(pounds);
    const penceDisplay = displayMoney(pence);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterHasTwoPilesOfCoinsHowManyDifferentCombinations(name)}
        pdfTitle={translate.instructions.characterHasTwoPilesOfCoinsHowManyDifferentCombinationsPDF(
          name
        )}
        testCorrect={[optionA]}
        numItems={4}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: dimens.width
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
                borderRadius: 4,
                borderWidth: 3,
                padding: 16
              }}
            >
              {poundsDisplay.map((pound, idx) => (
                <Text key={idx} style={{ marginRight: 16 }}>
                  {pound}
                </Text>
              ))}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
                borderRadius: 4,
                borderWidth: 3,
                padding: 16
              }}
            >
              {penceDisplay.map((pence, idx) => (
                <Text key={idx} style={{ marginRight: 8 }}>
                  {pence}
                </Text>
              ))}
            </View>
          </View>
        )}
        renderItems={options.map(option => ({
          value: option,
          component: <Text variant="WRN700">{option}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aK4',
  description: 'aK4',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    iceCreamFlavoursTotal: z.number().int().min(2).max(4),
    iceCreamToppingsTotal: z.number().int().min(1).max(4),
    iceCreamFlavoursItems: z.array(iceCreamFlavoursSchema),
    iceCreamToppingsItems: z.array(iceCreamToppingsItemsSchema)
  }),
  simpleGenerator: () => {
    const iceCreamFlavoursTotal = randomIntegerInclusive(2, 4);
    const iceCreamToppingsTotal = randomIntegerInclusive(1, 4, {
      constraint: x => (iceCreamFlavoursTotal === 2 ? x !== 2 : true)
    });

    const iceCreamFlavoursItems = getRandomUniqueIceCreamFlavourNames(iceCreamFlavoursTotal);
    const iceCreamToppingsItems = getRandomUniqueIceCreamToppingNames(iceCreamToppingsTotal);

    return {
      iceCreamFlavoursTotal,
      iceCreamToppingsTotal,
      iceCreamFlavoursItems,
      iceCreamToppingsItems
    };
  },
  Component: props => {
    const {
      question: {
        iceCreamFlavoursTotal,
        iceCreamToppingsTotal,
        iceCreamFlavoursItems,
        iceCreamToppingsItems
      },
      translate,
      displayMode
    } = props;

    const optionA = iceCreamFlavoursTotal + iceCreamToppingsTotal;
    const optionB = iceCreamFlavoursTotal * iceCreamToppingsTotal;

    const statements = shuffle([optionA, optionB], {
      random: seededRandom(props.question)
    });

    // Get largest total between flavours/toppings
    // Works out how many rows needed in table
    const indexLength = Math.max(iceCreamFlavoursTotal, iceCreamToppingsTotal);

    // Create data array
    const data: (string | JSX.Element)[][] = [];

    // Loop over indexLength and push flavours/toppings to each column
    // If an empty column return empty string
    for (let i = 0; i < indexLength; i++) {
      data.push([iceCreamFlavoursItems[i] ?? '', iceCreamToppingsItems[i] ?? '']);
    }

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.cafeHasXFlavoursYToppingsWorkOutTotalNumberOfCombinations(
          iceCreamFlavoursTotal,
          iceCreamToppingsTotal
        )}
        pdfTitle={translate.instructions.cafeHasXFlavoursYToppingsWorkOutTotalNumberOfCombinationsPDF(
          iceCreamFlavoursTotal,
          iceCreamToppingsTotal
        )}
        testCorrect={[optionB]}
        itemLayout={'row'}
        numItems={2}
        Content={
          <CustomizableTable
            cellHeaders={[
              {
                label: 'Ice cream flavours',
                containerStyle: {
                  backgroundColor: '#f3cdcc',
                  padding: 0
                },
                textStyle: {
                  color: '#002e63'
                }
              },
              {
                label: 'Toppings',
                containerStyle: {
                  backgroundColor: '#f3cdcc',
                  padding: 0
                },
                textStyle: {
                  color: '#002e63'
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        }
        renderItems={statements.map(option => ({
          value: option,
          component: <Text variant="WRN700">{option}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aK5',
  description: 'aK5',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    basesTotal: z.number().int().min(3).max(8),
    toppingsTotal: z.number().int().min(3).max(5)
  }),
  simpleGenerator: () => {
    const basesTotal = randomIntegerInclusive(3, 8);
    const toppingsTotal = randomIntegerInclusive(3, 5);

    return { basesTotal, toppingsTotal };
  },

  Component: props => {
    const {
      question: { basesTotal, toppingsTotal },
      translate
    } = props;

    // Combination total
    const ans = basesTotal * toppingsTotal;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.pizzeriaOffersChoiceOfXBasesAndYToppingsHowManyCombinations(
          basesTotal,
          toppingsTotal
        )}
        testCorrect={userAnswer => {
          return (
            ((userAnswer[0][0].toString() === basesTotal.toString() &&
              userAnswer[0][1].toString() === toppingsTotal.toString()) ||
              (userAnswer[0][0].toString() === toppingsTotal.toString() &&
                userAnswer[0][1].toString() === basesTotal.toString())) &&
            userAnswer[0][2].toString() === ans.toString() &&
            userAnswer[1][0].toString() === ans.toString()
          );
        }}
        inputMaxCharacters={2}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `${translate.answerSentences.thereAreAnsCombinations()}`
        ]}
        sentenceStyle={{ alignSelf: 'center' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [basesTotal.toLocaleString(), toppingsTotal.toLocaleString(), ans.toLocaleString()],
            [ans.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aK6',
  description: 'aK6',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z
    .object({
      characterNameA: nameSchema,
      characterATShirts: z.number().min(2).max(9),
      characterAJackets: z.number().min(3).max(9),
      characterNameB: nameSchema,
      characterBTShirts: z.number().min(2).max(9),
      characterBJackets: z.number().min(3).max(9)
    })
    .refine(
      val => val.characterBTShirts !== val.characterATShirts,
      'characterBTShirts is not equal to characterATShirts'
    )
    .refine(
      val => val.characterBJackets !== val.characterAJackets,
      'characterBJackets is not equal to characterAJackets'
    ),
  simpleGenerator: () => {
    const {
      characterNameA,
      characterATShirts,
      characterAJackets,
      characterNameB,
      characterBTShirts,
      characterBJackets
    } = rejectionSample(
      () => {
        // Character names
        const [characterNameA, characterNameB] = getRandomUniqueNames(2);

        // Character A
        const characterATShirts = randomIntegerInclusive(2, 9);
        const characterAJackets = randomIntegerInclusive(3, 9);

        // Character B
        const characterBTShirts = randomIntegerInclusive(2, 9, {
          constraint: x => x !== characterATShirts
        });
        const characterBJackets = randomIntegerInclusive(3, 9, {
          constraint: x => x !== characterAJackets
        });

        return {
          characterNameA,
          characterATShirts,
          characterAJackets,
          characterNameB,
          characterBTShirts,
          characterBJackets
        };
      },
      ({ characterATShirts, characterAJackets, characterBTShirts, characterBJackets }) =>
        // Make sure you cannot get identical answers
        characterATShirts * characterAJackets !== characterBTShirts * characterBJackets
    );

    return {
      characterNameA,
      characterATShirts,
      characterAJackets,
      characterNameB,
      characterBTShirts,
      characterBJackets
    };
  },
  Component: ({
    question: {
      characterNameA,
      characterATShirts,
      characterAJackets,
      characterNameB,
      characterBTShirts,
      characterBJackets
    },
    translate
  }) => {
    // Selectables
    const selectables = [
      {
        tShirts: characterATShirts,
        jackets: characterAJackets,
        name: characterNameA,
        image: <AssetSvg name={getCharacterHeadSvgName(characterNameA)} width={200} height={200} />
      },
      {
        tShirts: characterBTShirts,
        jackets: characterBJackets,
        name: characterNameB,
        image: <AssetSvg name={getCharacterHeadSvgName(characterNameB)} width={200} height={200} />
      }
    ];

    // Character A/B total combinations
    const characterATotalCombinations = characterATShirts * characterAJackets;
    const characterBTotalCombinations = characterBTShirts * characterBJackets;

    // Get the largest combination
    const ans = Math.max(characterATotalCombinations, characterBTotalCombinations);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.charHasXTShirtsAndYJacketsWhoHasMoreChoicesOfCombinations({
          name: characterNameA,
          tShirts: characterATShirts,
          jackets: characterAJackets
        })}<br/>${translate.instructions.charHasXTShirtsAndYJacketsWhoHasMoreChoicesOfCombinations({
          name: characterNameB,
          tShirts: characterBTShirts,
          jackets: characterBJackets
        })}<br/>${translate.instructions.whoHasMoreChoicesOfCombinationsForWearingOneTShirtAndOneJacket()}`}
        pdfTitle={`${translate.instructions.charHasXTShirtsAndYJacketsWhoHasMoreChoicesOfCombinations(
          {
            name: characterNameA,
            tShirts: characterATShirts,
            jackets: characterAJackets
          }
        )}<br/>${translate.instructions.charHasXTShirtsAndYJacketsWhoHasMoreChoicesOfCombinations({
          name: characterNameB,
          tShirts: characterBTShirts,
          jackets: characterBJackets
        })}<br/>${translate.instructions.whoHasMoreChoicesOfCombinationsForWearingOneTShirtAndOneJacket()} ${translate.instructions.circleCorrectAnswer()}`}
        testCorrect={[ans]}
        numItems={2}
        renderItems={selectables.map(selectable => ({
          value: selectable.tShirts * selectable.jackets,
          component: (
            <View
              style={{
                alignItems: 'center'
              }}
            >
              <Text variant="WRN400">{selectable.name}</Text>
              {selectable.image}
            </View>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aK7',
  description: 'aK7',
  keywords: ['Multiplication', 'Correspondence'],
  schema: z.object({
    sportTotal: z.number().int().min(2).max(6),
    artAndCraftTotal: z.number().int().min(2).max(6),
    outwardBoundTotal: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const sportTotal = randomIntegerInclusive(2, 6);
    const artAndCraftTotal = randomIntegerInclusive(2, 6);
    const outwardBoundTotal = randomIntegerInclusive(2, 6);

    return { sportTotal, artAndCraftTotal, outwardBoundTotal };
  },
  Component: props => {
    const {
      question: { sportTotal, artAndCraftTotal, outwardBoundTotal },
      translate
    } = props;

    // Combination total
    const ans = sportTotal * artAndCraftTotal * outwardBoundTotal;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.atSummerCampThereAreASportBArtAndCraftCOutwardBoundActivitiesHowManyActivityCombinationsAreThere(
          sportTotal,
          artAndCraftTotal,
          outwardBoundTotal
        )}
        testCorrect={[ans.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CorrespondenceProblems',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
