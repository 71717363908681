// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import DecimalsB from './DecimalsB';
import Money from './Money';
import Time from './Time';
import Shape from './Shape';
import Statistics from './Statistics';
import PositionAndDirection from './PositionAndDirection';

const Term = newTermContent({
  term: 'Summer',
  blocks: [DecimalsB, Money, Time, Shape, Statistics, PositionAndDirection]
});
export default Term;
