import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const shapes = [
  'Heart',
  'Face',
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Trapezium',
  'Rectangle'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Heart: [
    'SymmetricalShapes/vertical1_pink',
    'SymmetricalShapes/vertical1_purple',
    'SymmetricalShapes/vertical1_green',
    'SymmetricalShapes/vertical1_yellow'
  ],
  Face: [
    'SymmetricalShapes/vertical2_pink',
    'SymmetricalShapes/vertical2_purple',
    'SymmetricalShapes/vertical2_green',
    'SymmetricalShapes/vertical2_yellow'
  ],
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Trapezium: [
    'Trapezium/trapezium_isosceles_green',
    'Trapezium/trapezium_isosceles_purple',
    'Trapezium/trapezium_isosceles_pink',
    'Trapezium/trapezium_isosceles_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_yellow'
  ]
};

const turnToWords: Record<
  0 | 90 | 180 | 270,
  'fullTurn' | 'halfTurn' | 'quarterTurn' | 'threeQuarterTurn'
> = {
  0: 'fullTurn',
  90: 'quarterTurn',
  180: 'halfTurn',
  270: 'threeQuarterTurn'
};

const Question1 = newQuestionContent({
  uid: 'beI',
  description: 'beI',
  keywords: ['Half turn', 'Quarter turn', 'Full turn', 'Three-quarter turn'],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium']),
    colorIndexes: z.array(z.number().int().min(0).max(3)).length(2),
    startingRotation: numberEnum([0, 90, 180, 270]),
    rotations: z.array(numberEnum([0, 90, 180, 270])).length(2),
    options: z.enum(['fullTurn', 'halfTurn', 'quarterTurn', 'threeQuarterTurn']).array().length(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium'
    ] as const);
    const colorIndexes = randomUniqueIntegersInclusive(0, 3, 2);
    const startingRotation = getRandomFromArrayWithWeights(
      [shape === 'Arrow' ? 270 : 0, shape === 'Arrow' ? 0 : 90, 180, 270] as const,
      [3, 1, 1, 1]
    );
    const rotation1 = getRandomFromArray([0, 90, 180, 270] as const);
    const rotation2 = [90, 270].includes(rotation1)
      ? getRandomFromArray([0, 180] as const)
      : getRandomFromArray([90, 270, (180 - rotation1) as 0 | 180] as const);

    const options = [
      'fullTurn' as const,
      'halfTurn' as const,
      [90, 270].includes(rotation1)
        ? turnToWords[rotation1]
        : [90, 270].includes(rotation2)
        ? turnToWords[rotation2]
        : getRandomFromArray(['quarterTurn', 'threeQuarterTurn'] as const)
    ];

    return {
      shape,
      colorIndexes,
      startingRotation,
      rotations: [rotation1, rotation2],
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndexes, startingRotation, rotations, options },
      translate,
      displayMode
    } = props;

    const dimens = {
      width: displayMode === 'digital' ? 300 : 400,
      height: displayMode === 'digital' ? 150 : 200
    };

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToMatchTheShapesToTheTurn()}
        pdfTitle={translate.ks1PDFInstructions.matchTheShapesToTheTurn()}
        items={options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: 32 }}>
              {translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        statementStyle={{ justifyContent: 'center' }}
        statements={rotations.map((angle, i) => ({
          lhsComponent: (
            <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View
                style={{
                  transform: `rotate(${startingRotation}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg
                  name={svgNames[shape][colorIndexes[i]]}
                  height={dimens.height * 0.9}
                  width={dimens.height * 0.9}
                />
              </View>
              <View
                style={{
                  transform: `rotate(${startingRotation + angle}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg
                  name={svgNames[shape][colorIndexes[i]]}
                  height={dimens.height * 0.9}
                  width={dimens.height * 0.9}
                />
              </View>
            </View>
          ),
          correctAnswer: turnToWords[angle]
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
        useArrows={false}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'beJ',
  description: 'beJ',
  keywords: ['Half turn', 'Quarter turn', 'Full turn', 'Three-quarter turn'],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium']),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    options: numberEnum([0, 90, 180, 270]).array(),
    turn: numberEnum([0, 90, 180, 270])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArray([0, 90, 180, 270] as const);

    const turn = getRandomFromArray([0, 90, 180, 270] as const);

    const options = [90, 270].includes(turn)
      ? ([0, 180, turn] as const)
      : ([
          turn,
          ...getRandomSubArrayFromArray([90, 270, (180 - turn) as 180 | 0] as const, 2)
        ] as const);

    return {
      shape,
      colorIndex,
      startingRotation,
      turn,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, turn, options },
      translate,
      displayMode
    } = props;

    const title = {
      0: translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAFullTurn(),
      90: translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAQuarterTurn(),
      180: translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAHalfTurn(),
      270: translate.ks1Instructions.selectWhatTheShapeWillLookLikeAfterAThreeQuarterTurn()
    };

    const pdfTitle = {
      0: translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAFullTurn(),
      90: translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAQuarterTurn(),
      180: translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAHalfTurn(),
      270: translate.ks1PDFInstructions.tickWhatTheShapeWillLookLikeAfterAThreeQuarterTurn()
    };

    const dimens =
      displayMode === 'digital' ? { height: 200, width: 300 } : { height: 400, width: 600 };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={title[turn]}
        pdfTitle={pdfTitle[turn]}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height * 0.8, justifyContent: 'center' }}>
            <View style={{ transform: `rotate(${startingRotation}deg)` }}>
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.4}
                width={dimens.height * 0.4}
              />
            </View>
          </View>
        )}
        itemLayout="row"
        numItems={[90, 270].includes(turn) ? 2 : 3}
        itemStyle={dimens}
        renderItems={options.map((val, i) => ({
          value: val + startingRotation,
          component: (
            <View
              key={i}
              style={{
                transform: `rotate(${startingRotation + val}deg)`,
                height: dimens.height * 0.6,
                width: dimens.height * 0.6
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
          )
        }))}
        testCorrect={[turn + startingRotation]}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'beK',
  description: 'beK',
  keywords: ['Half turn', 'Quarter turn', 'Full turn', 'Three-quarter turn'],
  schema: z.object({
    shape: z.enum(['Heart', 'Face', 'Arrow', 'Equilateral', 'Isosceles', 'Trapezium', 'Rectangle']),
    colorIndex: z.number().int().min(0).max(3),
    startingRotation: numberEnum([0, 90, 180, 270]),
    rotation: numberEnum([0, 90, 180, 270]),
    options: z.enum(['fullTurn', 'halfTurn', 'quarterTurn', 'threeQuarterTurn']).array().length(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'Heart',
      'Face',
      'Arrow',
      'Equilateral',
      'Isosceles',
      'Trapezium',
      'Rectangle'
    ] as const);
    const colorIndex = randomIntegerInclusive(0, 3);
    const startingRotation = getRandomFromArrayWithWeights(
      [shape === 'Arrow' ? 270 : 0, shape === 'Arrow' ? 0 : 90, 180, 270] as const,
      [3, 1, 1, 1]
    );
    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    const options = [
      'fullTurn' as const,
      'halfTurn' as const,
      [90, 270].includes(rotation)
        ? turnToWords[rotation]
        : getRandomFromArray(['quarterTurn', 'threeQuarterTurn'] as const)
    ];

    return {
      shape,
      colorIndex,
      startingRotation,
      rotation,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { shape, colorIndex, startingRotation, rotation, options },
      translate
    } = props;

    const string = {
      fullTurn: translate.ks1MiscStrings.directions.full(),
      quarterTurn: translate.ks1MiscStrings.directions.quarter(),
      halfTurn: translate.ks1MiscStrings.directions.half(),
      threeQuarterTurn: translate.ks1MiscStrings.directions.threeQuarter()
    };

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: 40 }}>
              {string[val]}
            </Text>
          )
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <View
              style={{
                transform: `rotate(${startingRotation}deg)`,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
            <View
              style={{
                transform: `rotate(${startingRotation + rotation}deg)`,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AssetSvg
                name={svgNames[shape][colorIndex]}
                height={dimens.height * 0.6}
                width={dimens.height * 0.6}
              />
            </View>
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        sentence={translate.ks1AnswerSentences.theShapeHasTurnedAAnsTurn()}
        testCorrect={userAnswer =>
          shape === 'Rectangle' && [0, 180].includes(rotation) && userAnswer[0]
            ? [turnToWords[0], turnToWords[180]].includes(userAnswer[0])
            : turnToWords[rotation] === userAnswer[0]
        }
        pdfLayout="itemsTop"
        customMarkSchemeAnswer={{
          answersToDisplay: [[turnToWords[rotation]]]
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribeTurns',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
