import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getRandomFromArray, randomIntegerInclusive } from 'common/src/utils/random';
import { arrayHasNoDuplicates, filledArray } from 'common/src/utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { all, create, number } from 'mathjs';
import { ScientificNotation, compareFloats, roundToTheNearest } from '../../../../utils/math';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { View } from 'react-native';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRe',
  description: 'aRe',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Integer',
    'Number line',
    'Round',
    'Nearest tenth'
  ],
  schema: z.object({
    startingInteger: z.number().int().min(0).max(9),
    tenths: z.number().int().min(1).max(8),
    hundredths: z
      .number()
      .int()
      .min(1)
      .max(9)
      .refine(val => val !== 5)
  }),
  simpleGenerator: () => {
    const startingInteger = randomIntegerInclusive(0, 9);

    const tenths = randomIntegerInclusive(1, 8);

    const hundredths = randomIntegerInclusive(1, 9, {
      constraint: x => x !== 5
    });

    return { startingInteger, tenths, hundredths };
  },
  Component: props => {
    const {
      question: { startingInteger, tenths, hundredths },
      translate
    } = props;

    const focusNumber = number(
      math.evaluate(`${startingInteger} + ${tenths / 10} + ${hundredths / 100}`)
    );

    const startNumber = number(math.evaluate(`${startingInteger} + ${tenths / 10}`));

    const midNumber = number(math.evaluate(`${startingInteger} + ${tenths / 10} + 0.05`));

    const endNumber = number(math.evaluate(`${startingInteger} + ${(tenths + 1) / 10}`));

    const expectedAnswer = roundToTheNearest(focusNumber, 0.1);

    // Array of tick values for number line
    const tickValues = [
      startNumber.toLocaleString(),
      ...filledArray('', 4),
      midNumber.toLocaleString(),
      ...filledArray('', 4),
      endNumber.toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.nearestTenthIs()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], expectedAnswer)}
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={focusNumber}
            showFocusNumber
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [expectedAnswer.toLocaleString()]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRf',
  description: 'aRf',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Integer',
    'Number line',
    'Round',
    'Nearest tenth'
  ],
  schema: z.object({
    startingInteger: z.number().int().min(10).max(100),
    tenths: z.number().int().min(1).max(8),
    hundredths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const startingInteger = randomIntegerInclusive(10, 100);

    const tenths = randomIntegerInclusive(1, 8);

    const hundredths = randomIntegerInclusive(1, 9);

    return { startingInteger, tenths, hundredths };
  },
  Component: props => {
    const {
      question: { startingInteger, tenths, hundredths },
      translate
    } = props;

    const focusNumber = number(
      math.evaluate(`${startingInteger} + ${tenths / 10} + ${hundredths / 100}`)
    );

    const startNumber = number(math.evaluate(`${startingInteger} + ${tenths / 10}`));

    const midNumber = number(math.evaluate(`${startingInteger} + ${tenths / 10} + 0.05`));

    const endNumber = number(math.evaluate(`${startingInteger} + ${(tenths + 1) / 10}`));

    const expectedAnswer = roundToTheNearest(focusNumber, 0.1);

    // Array of tick values for number line
    const tickValues = [
      startNumber.toLocaleString(),
      ...filledArray('', 4),
      midNumber.toLocaleString(),
      ...filledArray('', 4),
      endNumber.toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.numRoundedToTheNearestTenthIs(focusNumber)}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], expectedAnswer)}
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={focusNumber}
            showFocusNumber
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [expectedAnswer.toLocaleString()]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRg',
  description: 'aRg',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths', 'Round'],
  schema: z.object({
    ones: z.number().int().min(0).max(99),
    tenths: z.number().int().min(1).max(8),
    hundredthsA: z.number().int().min(3).max(4)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 99);

    const tenths = randomIntegerInclusive(1, 8);

    const hundredthsA = randomIntegerInclusive(3, 4);

    return { ones, tenths, hundredthsA };
  },
  Component: props => {
    const {
      question: { ones, tenths, hundredthsA },
      translate
    } = props;

    const numberA = number(math.evaluate(`${ones} + ${tenths / 10} + ${hundredthsA / 100}`));
    const numberB = number(math.evaluate(`${ones} + ${tenths / 10} + ${(hundredthsA + 1) / 100}`));
    const numberC = number(math.evaluate(`${ones} + ${tenths / 10} + ${(hundredthsA + 2) / 100}`));

    const expectedAnswerA = roundToTheNearest(numberA, 0.1);
    const expectedAnswerB = roundToTheNearest(numberB, 0.1);
    const expectedAnswerC = roundToTheNearest(numberC, 0.1);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundEachNumToNearestTenth()}
        sentences={[
          `${numberA.toLocaleString()} <ans/>`,
          `${numberB.toLocaleString()} <ans/>`,
          `${numberC.toLocaleString()} <ans/>`
        ]}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], expectedAnswerA) &&
          compareFloats(userAnswer[1][0], expectedAnswerB) &&
          compareFloats(userAnswer[2][0], expectedAnswerC)
        }
        inputMaxCharacters={4}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRh',
  description: 'aRh',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths', 'Round'],
  schema: z.object({
    onesA: z.number().int().min(0).max(10),
    tenthsA: z.number().int().min(1).max(8),
    hundredthsA: z.number().int().min(1).max(9),
    onesB: z.number().int().min(0).max(20),
    tenthsB: z.number().int().min(1).max(8),
    hundredthsB: z.number().int().min(1).max(9),
    onesC: z.number().int().min(20).max(100),
    tenthsC: z.number().int().min(1).max(8),
    hundredthsC: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    // Number A:
    const onesA = randomIntegerInclusive(0, 10);

    const tenthsA = randomIntegerInclusive(1, 8);

    const hundredthsA = randomIntegerInclusive(1, 9);

    // Number B:
    const onesB = randomIntegerInclusive(0, 20, {
      constraint: x => x !== onesA
    });

    const tenthsB = randomIntegerInclusive(1, 8);

    const hundredthsB = randomIntegerInclusive(1, 9);

    // Number C:

    const onesC = randomIntegerInclusive(20, 100, {
      constraint: x => x !== onesB
    });

    const tenthsC = randomIntegerInclusive(1, 8);

    const hundredthsC = randomIntegerInclusive(1, 9);

    return {
      onesA,
      tenthsA,
      hundredthsA,
      onesB,
      tenthsB,
      hundredthsB,
      onesC,
      tenthsC,
      hundredthsC
    };
  },
  Component: props => {
    const {
      question: {
        onesA,
        tenthsA,
        hundredthsA,
        onesB,
        tenthsB,
        hundredthsB,
        onesC,
        tenthsC,
        hundredthsC
      },
      translate
    } = props;

    const numberA = number(math.evaluate(`${onesA} + ${tenthsA / 10} + ${hundredthsA / 100}`));
    const numberB = number(math.evaluate(`${onesB} + ${tenthsB / 10} + ${hundredthsB / 100}`));
    const numberC = number(math.evaluate(`${onesC} + ${tenthsC / 10} + ${hundredthsC / 100}`));

    const expectedAnswerA = roundToTheNearest(numberA, 0.1);
    const expectedAnswerB = roundToTheNearest(numberB, 0.1);
    const expectedAnswerC = roundToTheNearest(numberC, 0.1);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundEachNumToNearestTenth()}
        sentences={[
          `${numberA.toLocaleString()} <ans/>`,
          `${numberB.toLocaleString()} <ans/>`,
          `${numberC.toLocaleString()} <ans/>`
        ]}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], expectedAnswerA) &&
          compareFloats(userAnswer[1][0], expectedAnswerB) &&
          compareFloats(userAnswer[2][0], expectedAnswerC)
        }
        inputMaxCharacters={5}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRi',
  description: 'aRi',
  keywords: ['Tenths', 'Hundredths', 'Decimals', 'Round'],
  schema: z.object({
    ones: z.number().int().min(0).max(20),
    tenths: z.number().int().min(1).max(8),
    hundredths: z.number().int().min(1).max(9),
    svgName: z.enum(['CardboardBox', 'CardboardBox1', 'CardboardBox2', 'CardboardBox3'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 20);

    const tenths = randomIntegerInclusive(1, 8);

    const hundredths = randomIntegerInclusive(1, 9);

    const svgName = getRandomFromArray([
      'CardboardBox',
      'CardboardBox1',
      'CardboardBox2',
      'CardboardBox3'
    ] as const);

    return { ones, tenths, hundredths, svgName };
  },
  Component: props => {
    const {
      question: { ones, tenths, hundredths, svgName },
      translate
    } = props;

    const weight = number(math.evaluate(`${ones} + ${tenths / 10} + ${hundredths / 100}`));

    const expectedAnswer = roundToTheNearest(weight, 0.1);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.hereIsMassOfAParcel()}
        sentences={[
          translate.answerSentences.roundMassOfParcelTo1Dp(),
          translate.answerSentences.ansKg()
        ]}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[1][0], expectedAnswer)}
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <>
            <AssetSvg name={svgName as SvgName} height={dimens.height * 0.8} />
            <Text variant="WRN400">{translate.units.numberOfKg(weight)}</Text>
          </>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [[], [expectedAnswer.toLocaleString()]]
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aRi2',
  description: 'aRi',
  keywords: ['Tenths', 'Hundredths', 'Decimals', 'Round'],
  schema: z.object({
    ones: z.number().int().min(0).max(20),
    tenths: z.number().int().min(1).max(8),
    hundredths: z.number().int().min(1).max(9),
    svgName: z.enum(['CardboardBox', 'CardboardBox1', 'CardboardBox2', 'CardboardBox3'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 20);

    const tenths = randomIntegerInclusive(1, 8);

    const hundredths = randomIntegerInclusive(1, 9);

    const svgName = getRandomFromArray(['CardboardBox', 'CardboardBox2', 'CardboardBox3'] as const);

    return { ones, tenths, hundredths, svgName };
  },
  Component: props => {
    const {
      question: { ones, tenths, hundredths, svgName },
      translate,
      displayMode
    } = props;

    const weight = number(math.evaluate(`${ones} + ${tenths / 10} + ${hundredths / 100}`));

    const expectedAnswer = roundToTheNearest(weight, 0.1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsMassOfAParcel()}
        sentence={translate.answerSentences.ansKg()}
        extraSymbol="decimalPoint"
        testCorrect={[expectedAnswer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={4}
        questionHeight={900}
        Content={({ dimens }) => (
          <>
            <AssetSvg name={svgName as SvgName} height={dimens.height * 0.6} />
            <Text variant="WRN400">{translate.units.numberOfKg(weight)}</Text>
            <View style={{ width: dimens.width, alignItems: 'flex-start' }}>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.answerSentences.roundMassOfParcelTo1Dp()}
              </Text>
            </View>
          </>
        )}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aRj',
  description: 'aRj',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths', 'Round'],
  schema: z.object({
    roundedNumber: z.number().min(0.1).max(19.9).multipleOf(0.1)
  }),
  simpleGenerator: () => {
    const roundedNumber = randomIntegerInclusive(1, 199) / 10;
    return { roundedNumber };
  },
  Component: props => {
    const {
      question: { roundedNumber },
      translate
    } = props;

    const answerCheck = (answer: string): boolean => {
      const answerAsNum = number(math.evaluate(`${answer}`));

      const answerToSciNot = ScientificNotation.fromNumber(answerAsNum);

      return (
        // Check that the lowest power of the answer is the hundredths
        answerToSciNot.resolution === -2 &&
        // Check that the answer rounds to roundedNumber:
        roundToTheNearest(answerAsNum, 0.1) === roundedNumber &&
        // Check that the last character is not 0, i.e. no trailing zeroes:
        answer.charAt(answer.length - 1) !== '0'
      );
    };

    return (
      <QF2AnswerBoxOneSentence
        sentence={'<ans/> <ans/> <ans/>'}
        title={translate.instructions.aNumberRoundedToTheNearestTenthIsNum(roundedNumber)}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          userAnswer.every(ans => answerCheck(ans)) && arrayHasNoDuplicates(userAnswer)
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundTo1DecimalPlace',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
