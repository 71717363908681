import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF23CreatePlaceValueChart from 'common/src/components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation } from 'common/src/utils/math';
import ReadPlaceValueChart from 'common/src/components/question/questionFormats/ReadPlaceValueChart';

import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import { numbersDoNotExchange } from 'common/src/utils/exchanges';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Q1And3Generator = () => {
  const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
  const powerOfTen = getRandomFromArray([1, 10, 100, 1000, 10000, 100000] as const);

  const number =
    lessOrMore === 'less'
      ? randomIntegerInclusive(1, 999999, {
          constraint: x => x - powerOfTen > 0 && numbersDoNotExchange(x, -powerOfTen)
        })
      : randomIntegerInclusive(1, 999999, {
          constraint: x => x + powerOfTen <= 999999 && numbersDoNotExchange(x, powerOfTen)
        });

  return { number, lessOrMore, powerOfTen };
};

const Question1 = newQuestionContent({
  uid: 'adK',
  description: 'adK',
  keywords: ['Place value', 'Counters', 'More', 'Less', '100,000'],
  schema: z.object({
    number: z.number().min(1).max(999999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z
      .number()
      .int()
      .refine(x => [1, 10, 100, 1000, 10000, 100000].includes(x))
  }),
  example: {
    number: 132465,
    lessOrMore: 'less',
    powerOfTen: 10000
  },
  simpleGenerator: Q1And3Generator,
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <QF23CreatePlaceValueChart
        title={
          lessOrMore === 'less'
            ? translate.instructions.dragCountersToRepresentNumLess(
                powerOfTen.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.dragCountersToRepresentNumMore(
                powerOfTen.toLocaleString(),
                number.toLocaleString()
              )
        }
        pdfTitle={
          lessOrMore === 'less'
            ? translate.instructions.drawCountersToRepresentNumLess(
                powerOfTen.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.drawCountersToRepresentNumMore(
                powerOfTen.toLocaleString(),
                number.toLocaleString()
              )
        }
        number={ScientificNotation.fromNumber(result)}
        columnsToShow={[5, 4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'adL',
  description: 'adL',
  keywords: ['Place value', 'Counters', 'More', 'Less', '100,000'],
  schema: z.object({
    number: z.number().min(1).max(888888),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z
      .number()
      .int()
      .refine(x => [1, 10, 100, 1000, 10000, 100000].includes(x))
  }),
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfTen = getRandomFromArray([1, 10, 100, 1000, 10000, 100000] as const);

    const number =
      lessOrMore === 'less'
        ? randomIntegerInclusive(1, 888888, {
            constraint: x =>
              ScientificNotation.fromNumber(x).digits.every(i => i < 9) &&
              x - powerOfTen > 0 &&
              numbersDoNotExchange(x, -powerOfTen)
          })
        : randomIntegerInclusive(1, 888888, {
            constraint: x =>
              ScientificNotation.fromNumber(x).digits.every(i => i < 9) &&
              x + powerOfTen <= 888888 &&
              numbersDoNotExchange(x, powerOfTen)
          });

    return { number, lessOrMore, powerOfTen };
  },
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <ReadPlaceValueChart
        title={translate.instructions.herePlaceValueChart()}
        number={ScientificNotation.fromNumber(number)}
        columnsToShow={[5, 4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        sentenceStyle={{ justifyContent: 'flex-start' }}
        pdfSentenceStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number.toString(), result.toString()]}
        sentence={
          lessOrMore === 'less'
            ? translate.answerSentences.whatNumberIsShownWhatIsLess(powerOfTen.toLocaleString())
            : translate.answerSentences.whatNumberIsShownWhatIsMore(powerOfTen.toLocaleString())
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'adM',
  description: 'adM',
  keywords: ['Place value', 'More', 'Less', '100,000'],
  schema: z.object({
    number: z.number().min(1).max(999999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfTen: z
      .number()
      .int()
      .refine(x => [1, 10, 100, 1000, 10000, 100000].includes(x))
  }),
  example: {
    number: 132465,
    lessOrMore: 'less',
    powerOfTen: 10000
  },
  simpleGenerator: Q1And3Generator,
  Component: ({ question: { number, lessOrMore, powerOfTen }, translate, ...props }) => {
    const result = lessOrMore === 'less' ? number - powerOfTen : number + powerOfTen;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={[result.toString()]}
        sentence={translate.instructions.oneTen100LessOrMoreThanNumberIsAns(
          powerOfTen.toLocaleString(),
          lessOrMore,
          number.toLocaleString()
        )}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'adN',
  description: 'adN',
  keywords: ['Place value', 'More', 'Less', '100,000'],
  schema: z.object({
    number: z.number().min(10001).max(899999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfUnit: z.number().int().min(2).max(4)
  }),
  example: {
    number: 132465,
    lessOrMore: 'less',
    powerOfUnit: 4
  },
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfUnit: number = randomIntegerInclusive(2, 4);
    const number = randomIntegerInclusive(10001, 899999);
    return { number, lessOrMore, powerOfUnit };
  },
  Component: props => {
    const {
      question: { number, lessOrMore, powerOfUnit },
      translate
    } = props;
    const power = Math.pow(10, powerOfUnit);

    const numbers = shuffle(
      [number + 10000, number - 10000, number + 1000, number - 1000, number + 100, number - 100],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={
          lessOrMore === 'less'
            ? translate.instructions.whatIsDiffLessThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.whatIsDiffMoreThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
        }
        pdfTitle={`${
          lessOrMore === 'less'
            ? translate.instructions.whatIsDiffLessThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
            : translate.instructions.whatIsDiffMoreThanNum(
                power.toLocaleString(),
                number.toLocaleString()
              )
        }<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={numbers.filter(it =>
          lessOrMore === 'less' ? it === number - power : it === number + power
        )}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'adO',
  description: 'adO',
  keywords: ['Place value', 'More', 'Less', 'Gattegno', '100,000'],
  schema: z.object({
    number: z.number().int().min(11111).max(899999),
    lessOrMore: z.enum(['less', 'more']),
    powerOfUnit: z.number().int().min(0).max(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const lessOrMore: 'less' | 'more' = getRandomFromArray(['less', 'more'] as const);
    const powerOfUnit: number = randomIntegerInclusive(0, 5);

    const power = Math.pow(10, powerOfUnit);

    const number = rejectionSample(
      () => randomIntegerInclusive(11111, 899999),
      x => {
        const sci = ScientificNotation.fromNumber(x);
        const digits = [0, 1, 2, 3, 4, 5].map(pow => sci.unsignedDigitAt(pow));
        const zeroDigits = digits.filter(digit => digit === 0).length;
        const diff = Math.pow(10, powerOfUnit);
        const correctAnswer = lessOrMore === 'less' ? x - diff : x + diff;
        const numberToShow = lessOrMore === 'less' ? x + diff : x - diff;
        return (
          zeroDigits < 2 &&
          1 <= correctAnswer &&
          correctAnswer <= 999999 &&
          numberToShow >= 11111 &&
          numberToShow <= 999999 &&
          numbersDoNotExchange(x, lessOrMore === 'less' ? -power : power)
        );
      }
    );
    return { number, lessOrMore, powerOfUnit };
  },
  Component: ({ question: { number, lessOrMore, powerOfUnit }, translate }) => {
    const power = Math.pow(10, powerOfUnit);

    const numberToShow = lessOrMore === 'less' ? number + power : number - power;

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number}
        rowsToShow={[5, 4, 3, 2, 1, 0]}
        title={
          lessOrMore === 'less'
            ? translate.instructions.useGattegnotoShowNumLessThan(
                power.toLocaleString(),
                numberToShow.toLocaleString()
              )
            : translate.instructions.useGattegnotoShowNumMoreThan(
                power.toLocaleString(),
                numberToShow.toLocaleString()
              )
        }
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'adP',
  description: 'adP',
  keywords: ['Place value', 'More', 'Less', '100,000'],
  schema: z
    .object({
      number1: z.number().int().min(100001).max(999999),
      powerOfNum1Unit: z.number().int().min(1).max(5),
      number2: z.number().int().min(1).max(899999),
      powerOfNum2Unit: z.number().int().min(1).max(5)
    })
    .refine(
      val => val.powerOfNum1Unit !== val.powerOfNum2Unit,
      'powerOfNum1Unit and powerOfNum2Unit should not be the same.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(100001, 999999);

    const powerOfNum1Unit = randomIntegerInclusive(1, 5);

    const number2 = randomIntegerInclusive(1, 899999);

    // Ensure powerOfNum2Unit is not the same as powerOfNum1Unit
    const powerOfNum2Unit = rejectionSample(
      () => randomIntegerInclusive(1, 5),
      x => x !== powerOfNum1Unit
    );

    return { number1, powerOfNum1Unit, number2, powerOfNum2Unit };
  },
  Component: props => {
    const {
      question: { number1, powerOfNum1Unit, number2, powerOfNum2Unit },
      translate
    } = props;
    const power1 = Math.pow(10, powerOfNum1Unit);
    const power2 = Math.pow(10, powerOfNum2Unit);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentencesEachCardCanBeUsedMoreThanOnce()}
        items={[10, 100, 1000, 10000, 100000]}
        sentences={[
          `${translate.answerSentences.numIs(
            number1.toLocaleString()
          )} <ans/> ${translate.answerSentences.moreThan((number1 - power1).toLocaleString())}`,
          `${translate.answerSentences.numIs(
            number2.toLocaleString()
          )} <ans/> ${translate.answerSentences.lessThan((number2 + power2).toLocaleString())}`
        ]}
        testCorrect={[[power1], [power2]]}
        moveOrCopy="copy"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'To100000MoreOrLess',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
