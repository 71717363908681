import { StyleSheet } from 'react-native';
import { filledArray, range } from '../../../utils/collections';
import { useMemoStable } from '../../../utils/react';
import { BarModel } from './BarModel';
import { Dimens, PDF_QUESTION_WIDTH } from '../../../theme/scaling';
import { fractionWallColors } from '../../../theme/colors';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  startNumber?: number;
  finalNumber?: number;
  dimens?: Dimens;
};

const FractionWall = ({ startNumber = 1, finalNumber = 10, dimens }: Props) => {
  const displayMode = useContext(DisplayMode);

  const width = dimens?.width ?? displayMode === 'digital' ? 960 : PDF_QUESTION_WIDTH;
  const height = dimens?.height ?? displayMode === 'digital' ? 510 : 780;
  const styles = useStyles(width, height, displayMode);
  const fractionDenominators = range(startNumber, finalNumber);

  if (finalNumber - startNumber > 9) {
    console.warn('You will need to more colors to the fractionWallColors list.');
  }

  return (
    <BarModel
      total={1}
      numbers={fractionDenominators.map(it => filledArray(1 / it, it))}
      strings={fractionDenominators.map(it =>
        it > 1 ? filledArray(`<frac n='1' d='${it}' />`, it) : filledArray('1', 1)
      )}
      dimens={styles.dimensions}
      rowHeight={displayMode === 'digital' ? 51 : undefined}
      maxFontSize={displayMode === 'digital' ? 18 : 28}
      rowColors={fractionWallColors}
      fractionDividerStyle={styles.divider}
      fractionTextStyle={displayMode === 'digital' ? styles.fractionText : styles.pdfFractionText}
    />
  );
};

const useStyles = (
  width: number,
  height: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) => {
  return useMemoStable(
    () =>
      StyleSheet.create({
        dimensions: {
          width,
          height
        },
        divider: {
          marginVertical: 0,
          minWidth: displayMode === 'digital' ? 25 : 48
        },
        fractionText: {
          fontSize: 18
        },
        pdfFractionText: {
          fontSize: 28
        }
      }),
    [width, height, displayMode]
  );
};

export default FractionWall;
