import { createContext } from 'react';

export function noop() {
  /* Do nothing */
}

export type SetState<T> = (newStateOrAction: T | ((old: T) => T)) => void;

/**
 * Utility function to project a set state function of a javascript object, down to a set state function for just one
 * key in that object.
 */
export function projectSetState<A extends Record<string, unknown>, L extends keyof A>(
  setState: SetState<A>,
  propertyLabel: L
): SetState<A[L]> {
  return (newStateOrAction: A[L] | ((old: A[L]) => A[L])) => {
    return setState(record => {
      // Resolve the new state
      const newState =
        typeof newStateOrAction === 'function'
          ? (newStateOrAction as (old: A[L]) => A[L])(record[propertyLabel])
          : newStateOrAction;

      if (propertyLabel in record && record[propertyLabel] === newState) {
        // No change needed, so don't make a change and cause unnecessary renders
        return record;
      } else {
        return { ...record, [propertyLabel]: newState };
      }
    });
  };
}

/** A question's state. */
export type StateTree = Record<string, unknown>;
export type StateInternalNode = StateTree;
export type StateLeafNode<T = unknown> = T;
export type StateNode<T = unknown> = StateLeafNode<T> | StateTree;

/**
 * Context to hold the state and state management callbacks for a particular internal node of the state tree, which
 * may or may not be the tree's root node.
 */
export const InternalNodeContext = createContext<{
  state: StateInternalNode;
  setState: SetState<StateInternalNode>;
  getChildState: (childId: string) => StateNode | undefined;
  reportChildState: (childId: string) => SetState<StateNode>;
  reportChildCorrect: (childId: string) => SetState<boolean>;
  reportChildComplete: (childId: string) => SetState<boolean>;
}>({
  // The default context state doesn't matter as we don't support getting the context outside of a provider.
  state: {},
  setState: () => {
    throw new Error('State tree node used outside of StateTreeRoot');
  },
  getChildState: () => {
    throw new Error('State tree node used outside of StateTreeRoot');
  },
  reportChildState: () => {
    throw new Error('State tree node used outside of StateTreeRoot');
  },
  reportChildCorrect: () => {
    throw new Error('State tree node used outside of StateTreeRoot');
  },
  reportChildComplete: () => {
    throw new Error('State tree node used outside of StateTreeRoot');
  }
});
