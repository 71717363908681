import Text from 'common/src/components/typography/Text';
import { View, StyleSheet, Pressable } from 'react-native';
import { Image } from 'expo-image';
import FilledButton from '../components/FilledButton';
import AppConfig from '../../app.json';
import { colors } from 'common/src/theme/colors';
import { checkCameraPermissions } from '../utils/permissions';
import useLoginStore from '../storage/useLoginStore';
import { useI18nContext } from '../i18n/i18n-react';
import { RootStackProps } from '../navigation/types';
import { StatusBar } from 'expo-status-bar';
import useBreakpoints from '../hooks/useBreakpoints';
import { useMemo } from 'react';

export default function PupilMenuScreen({ navigation }: RootStackProps<'PupilMenu'>) {
  const clearLoggedInUser = useLoginStore(state => state.clearLoggedInUser);
  const { school } = useLoginStore();
  const translate = useI18nContext().LL;
  const { resize } = useBreakpoints();
  const styles = useStyles(resize);

  // Permissions for QR code inside menu
  const handlePermissions = async () => {
    // On success, *replace* this screen with the ScanQR screen, so the back button goes to PupilHome
    await checkCameraPermissions(() => navigation.replace('ScanQR'));
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.prussianBlue }}>
      <StatusBar style="light" />

      <View style={styles.smallCollapsibleSpacing} />

      {/* Menu actions */}
      <View style={styles.menuActionsContainer}>
        <FilledButton
          icon={() => (
            <Image
              source={require('pupil-app/assets/svg/QRCodeIcon.svg')}
              style={{ width: 48 * resize, height: 48 * resize }}
            />
          )}
          onPress={handlePermissions}
        >
          {translate.home.scanQrCode()}
        </FilledButton>

        <View style={styles.largeCollapsibleSpacing} />

        <FilledButton
          icon={() => (
            <Image
              source={require('pupil-app/assets/svg/Plus.svg')}
              style={{ width: 48 * resize, height: 48 * resize }}
            />
          )}
          onPress={
            school !== undefined
              ? () => navigation.replace('EnterQuizPIN')
              : () => navigation.replace('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' })
          }
        >
          {translate.home.enterCode()}
        </FilledButton>

        <View style={styles.largeCollapsibleSpacing} />

        <Pressable onPress={clearLoggedInUser}>
          <Text style={styles.logoutBtn}>Log Out</Text>
        </Pressable>
      </View>

      <View style={styles.smallCollapsibleSpacing} />

      {/* Menu footer text */}
      <View style={{ paddingBottom: 40 * resize }}>
        <Text
          style={{
            fontSize: 22 * resize,
            lineHeight: 33 * resize,
            color: '#ffffff75', // Opacity: 75%
            textAlign: 'center'
          }}
        >
          {translate.home.appVersion(AppConfig.expo.version)}
        </Text>
      </View>
    </View>
  );
}

function useStyles(resize: number) {
  return useMemo(
    () =>
      StyleSheet.create({
        menuActionsContainer: {
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        },
        logoutBtn: {
          textTransform: 'uppercase',
          textDecorationLine: 'underline',
          fontSize: 29 * resize,
          lineHeight: 43.5 * resize,
          color: colors.white
        },
        // Instead of using a simple `gap` style, we use a view which can shrink if absolutely necessary.
        largeCollapsibleSpacing: {
          flex: 1,
          maxHeight: 32 * resize,
          minHeight: 16 * resize
        },
        smallCollapsibleSpacing: {
          flex: 1,
          maxHeight: 18 * resize,
          minHeight: 9 * resize
        }
      }),
    [resize]
  );
}
