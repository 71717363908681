import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  findExchanges,
  numbersDoNotExchange,
  numbersOnlyExchangeAt
} from 'common/src/utils/exchanges';
import ColumnOperations from 'common/src/components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import { ADD } from 'common/src/constants';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { range } from '../../../../utils/collections';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahe',
  description: 'ahe',
  keywords: ['Addition', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'Cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(x => x % 10 !== 0, 'Cannot be a multiple of 10')
    })
    .refine(
      val => numbersDoNotExchange(val.var1, val.var2) && val.var1 + val.var2 < 1000,
      'Numbers must not exchange, and total must be less than 1,000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones/tens
      ({ var1, var2 }) => numbersDoNotExchange(var1, var2) && var1 + var2 < 1000
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ahf',
  description: 'ahf',
  keywords: ['Addition', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10)
        .max(100)
        .refine(x => x % 10 !== 0)
    })
    .refine(
      val =>
        numbersOnlyExchangeAt(val.var1, val.var2, 'tens') ||
        numbersOnlyExchangeAt(val.var1, val.var2, 'ones'),
      'must only an exchange at the ones or tens.'
    )
    .refine(val => val.var1 + val.var2 < 1000, 'total should be less than 1000'),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['ones', 'tens'] as const);
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have exchanges at the ones/tens and total is less than 1000.
      ({ var1, var2 }) => var1 + var2 < 1000 && numbersOnlyExchangeAt(var1, var2, exchange)
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ahg',
  description: 'ahg',
  keywords: ['Addition', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10)
        .max(100)
        .refine(x => x % 10 !== 0)
    })
    .refine(
      val => findExchanges(val.var1, val.var2).length === 2,
      'must exchange at the ones and tens.'
    )
    .refine(val => val.var1 + val.var2 < 1000, 'total should be less than 1000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have exchanges at the ones and tens and total is less than 1000.
      ({ var1, var2 }) => var1 + var2 < 1000 && findExchanges(var1, var2).length === 2
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ahh',
  description: 'ahh',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(10).max(99),
      number2: z.number().int().min(101).max(879)
    })
    .refine(
      val =>
        numbersOnlyExchangeAt(val.number1, val.number2, 'ones') ||
        numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 and number2 must exchange at either the ones or tens only.'
    ),
  simpleGenerator: () => {
    const onesOrTensExchange = randomIntegerInclusive(0, 1);

    const number2 =
      onesOrTensExchange === 0
        ? randomIntegerInclusive(101, 879, {
            constraint: x =>
              x % 10 !== 0 && // Ensure number1 does not end in 0 so number1 and number2 can exchange at the ones
              x % 100 < 80 // Ensure number1 has less than 8 tens to ensure number1 and number2 do not exchange at the tens
          })
        : randomIntegerInclusive(110, 799, {
            constraint: x => x % 100 > 9 // Ensure number1 ends in at least 10 so number1 and number2 can exchange at the tens
          });

    const number1 = randomIntegerInclusive(10, 99, {
      constraint: x => numbersOnlyExchangeAt(number2, x, [onesOrTensExchange])
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;
    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXAddY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ahi',
  description: 'ahi',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(989),
      numberA2: z.number().int().min(10).max(99),
      numberB1: z.number().int().min(100).max(989),
      numberB2: z.number().int().min(10).max(99),
      numberC1: z.number().int().min(100).max(989),
      numberC2: z.number().int().min(10).max(99),
      numberD1: z.number().int().min(100).max(989),
      numberD2: z.number().int().min(10).max(99)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2 * 10),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2 * 10),
      'numberB1 and numberB2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, val.numberC2),
      'numberC1 and numberC2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberD1, val.numberD2),
      'numberD1 and numberD2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberA1 = randomIntegerInclusive(100, 989);
        const numberA2 = randomIntegerInclusive(10, 99);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2 * 10)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberB1 = randomIntegerInclusive(100, 989);
        const numberB2 = randomIntegerInclusive(10, 99);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberB1, numberB2 }) => numbersDoNotExchange(numberB1, numberB2 * 10)
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberC1 = randomIntegerInclusive(100, 989);
        const numberC2 = randomIntegerInclusive(10, 99);
        return { numberC1, numberC2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberC1, numberC2 }) => numbersDoNotExchange(numberC1, numberC2)
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberD1 = randomIntegerInclusive(100, 989);
        const numberD2 = randomIntegerInclusive(10, 99);
        return { numberD1, numberD2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberD1, numberD2 }) => numbersDoNotExchange(numberD1, numberD2)
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        topNumber: numberA2 * 10,
        bottomNumber: numberA1,
        isCorrect: false,
        topBlankDigits: [0],
        bottomBlankDigits: []
      };
      const eqB = {
        topNumber: numberB1,
        bottomNumber: numberB2 * 10,
        isCorrect: false,
        topBlankDigits: [],
        bottomBlankDigits: [0]
      };
      const eqC = {
        topNumber: numberC1,
        bottomNumber: numberC2,
        isCorrect: false,
        topBlankDigits: [1],
        bottomBlankDigits: []
      };
      const eqD = {
        topNumber: numberD1,
        bottomNumber: numberD2,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichColAddIsLaidOutCorrectly()} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.whichColAddIsLaidOutCorrectly()}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                topBlankDigits={equation.topBlankDigits}
                bottomBlankDigits={equation.bottomBlankDigits}
                removeExtraCells
              />
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'ahj',
  description: 'ahj',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(101).max(879)
    })
    .refine(
      val =>
        numbersOnlyExchangeAt(val.number1, val.number2, 'ones') ||
        numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 and number2 must exchange at either the ones or tens only.'
    ),
  simpleGenerator: () => {
    const onesOrTensExchange = randomIntegerInclusive(0, 1);

    const { number1, number2 } = rejectionSample(
      () => {
        const number2 =
          onesOrTensExchange === 0
            ? randomIntegerInclusive(101, 879, {
                constraint: x =>
                  x % 10 !== 0 && // Ensure number1 does not end in 0 so number1 and number2 can exchange at the ones
                  x % 100 < 80 // Ensure number1 has less than 8 tens to ensure number1 and number2 do not exchange at the tens
              })
            : randomIntegerInclusive(110, 799, {
                constraint: x => x % 100 > 9 // Ensure number1 ends in at least 10 so number1 and number2 can exchange at the tens
              });

        const number1 = randomIntegerInclusive(11, 99, {
          constraint: x => numbersOnlyExchangeAt(number2, x, [onesOrTensExchange])
        });
        return { number1, number2 };
      },
      ({ number1 }) => number1 % 10 !== 0
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutXAddY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={[(number1 + number2).toString()]}
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Add2DigitAnd3DigitNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
