import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { arrayHasNoDuplicates } from 'common/src/utils/collections';
import { countRange } from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  getRandomUniqueShapesWithRightAngles,
  twoDShapeProperties
} from '../../../../utils/shapeImages/angles';
import { numberEnum } from '../../../../utils/zod';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { rightAngleSVG } from '../../../../components/question/representations/LineSvgs';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auS',
  description: 'auS',
  keywords: ['Right angles'],
  schema: z.object({
    options: z.array(
      z.object({
        value: z.number().int().min(0).max(4),
        isLeft: z.boolean()
      })
    )
  }),
  simpleGenerator: () => {
    const numOfCorrect = randomIntegerInclusive(1, 2);
    const isLeft = countRange(4).map(getRandomBoolean);

    const options =
      numOfCorrect === 2
        ? [
            {
              value: 1,
              isLeft: true
            },
            {
              value: 1,
              isLeft: false
            }
          ]
        : [
            {
              value: 1,
              isLeft: isLeft[0]
            }
          ];

    const values = getRandomSubArrayFromArray([0, 2, 4] as const, 4 - numOfCorrect);

    countRange(4 - numOfCorrect).forEach(i =>
      options.push({
        value: values[i],
        isLeft: isLeft[numOfCorrect === 1 ? i + 1 : i]
      })
    );

    return { options: shuffle(options) };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPicturesThatShowOnlyOneRightAngle()}
        pdfTitle={translate.instructions.circlePicturesThatShowOnlyOneRightAngle()}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          options.map((option, i) => ({
            value: ['A', 'B', 'C', 'D'][i],
            component: rightAngleSVG(
              option.value as 0 | 1 | 2 | 4,
              { height: dimens.height * 0.6, width: dimens.width * 0.6 },
              option.isLeft
            )
          }))
        }
        testCorrect={options
          .map((val, i) => ({ value: val.value, index: ['A', 'B', 'C', 'D'][i] }))
          .filter(val => val.value === 1)
          .map(val => val.index)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'auT',
  description: 'auT',
  keywords: ['Right angles', 'Shape', 'Regular'],
  schema: z.object({
    options: z.array(
      z.object({
        shape: z.string(),
        svg: z.string(),
        rightAngles: z.number().int().min(0).max(4)
      })
    ),
    rightAnswer: numberEnum([1, 2, 4])
  }),
  simpleGenerator: () => {
    const rightAnswer = getRandomFromArray([1, 2, 4] as const);
    const color = getRandomFromArray([
      'blue',
      'green',
      'pink',
      'purple',
      'white',
      'yellow'
    ] as const);
    // at least one right answer
    const answer = getRandomUniqueShapesWithRightAngles(1, rightAnswer, undefined, color);
    const options = rejectionSample(
      () => [...answer, ...getRandomUniqueShapesWithRightAngles(3, undefined, undefined, color)],
      val => arrayHasNoDuplicates(val.map(x => x.shape)) && val.every(x => x.rightAngles <= 4)
    );

    return { options: shuffle(options), rightAnswer };
  },
  Component: props => {
    const {
      question: { options, rightAnswer },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithXRightAngles(rightAnswer)}
        pdfTitle={translate.instructions.circleShapesWithXRightAngles(rightAnswer)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          options.map(option => ({
            value: option.svg,
            component: (
              <AssetSvg
                name={option.svg as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            )
          }))
        }
        testCorrect={options.filter(val => val.rightAngles === rightAnswer).map(val => val.svg)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'auU',
  description: 'auU',
  keywords: ['Right angles', 'Shape', 'Regular'],
  schema: z.object({
    shape: z.object({
      shape: z.string(),
      svg: z.string(),
      rightAngles: z.number().int().min(1)
    })
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray(['squares', 'rectangles', 'rightAngledTriangles']);
    const shapeProps = twoDShapeProperties.filter(val => val.shape === shapeName);
    const shape = shapeProps.map(val => ({
      ...val,
      svg: getRandomFromArray([...val.svg] as const) as SvgName
    }));

    return { shape: shape[0] };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyRightAngles()}
        testCorrect={[shape.rightAngles.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <AssetSvg
              name={shape.svg as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'auV',
  description: 'auV',
  keywords: ['Right angles', 'Shape', 'Irregular'],
  schema: z.object({
    shape: z.object({
      shape: z.string(),
      svg: z.string(),
      rightAngles: z.number().int().min(1)
    })
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray([
      'pentagons',
      'rightAngledTrapeziums',
      'irregularDodecagons',
      'irregularHexagon',
      'pentagons',
      'rightAngledTrapeziums'
    ]);
    const shapeProps = twoDShapeProperties.filter(val => val.shape === shapeName);
    const shape = shapeProps.map(val => ({
      ...val,
      svg: getRandomFromArray([...val.svg] as const) as SvgName
    }));

    return { shape: shape[0] };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyRightAngles()}
        testCorrect={[shape.rightAngles.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <AssetSvg
              name={shape.svg as SvgName}
              width={dimens.width * 0.8}
              height={dimens.height * 0.8}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'auW',
  description: 'auW',
  keywords: ['Right angles', 'Turns'],
  schema: z.object({
    turns: z.array(z.enum(['quarter', 'half', 'threeQuarter', 'full'])).length(2)
  }),
  simpleGenerator: () => {
    const turns = getRandomSubArrayFromArray(
      ['quarter', 'half', 'threeQuarter', 'full'] as const,
      2
    );

    return { turns };
  },
  Component: props => {
    const {
      question: { turns },
      translate
    } = props;

    const sentences: string[] = [];
    const answers: string[][] = [];

    turns.map(turn => {
      switch (turn) {
        case 'quarter':
          sentences.push(translate.answerSentences.aQuarterTurnIsAnsRightAngles());
          answers.push(['1']);
          break;
        case 'half':
          sentences.push(translate.answerSentences.aHalfTurnIsAnsRightAngles());
          answers.push(['2']);
          break;
        case 'threeQuarter':
          sentences.push(translate.answerSentences.aThreeQuarterTurnIsAnsRightAngles());
          answers.push(['3']);
          break;
        case 'full':
          sentences.push(translate.answerSentences.aFullTurnIsAnsRightAngles());
          answers.push(['4']);
          break;
      }
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={answers}
        sentences={sentences}
        containerStyle={{ alignItems: 'flex-start' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'auX',
  description: 'auX',
  keywords: ['Right angles', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    options: z
      .array(
        z.object({
          shape: z.string(),
          svg: z.string(),
          rightAngles: z.number().int().min(0)
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const options = rejectionSample(
      () => getRandomUniqueShapesWithRightAngles(4),
      val => arrayHasNoDuplicates(val.map(x => x.shape))
    );

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    const correctAnswer = [[], []] as [lessThan2: string[], moreThan2: string[]];
    options.forEach(val => {
      const zoneIndex = val.rightAngles < 2 ? 0 : 1;
      correctAnswer[zoneIndex].push(val.shape);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortShapesInTable()}
        pdfTitle={translate.instructions.useCardsToSortShapesInTable()}
        zoneNames={[
          translate.tableHeaders.lessThan2RightAngles(),
          translate.tableHeaders.twoOrMoreRightAngles()
        ]}
        testCorrect={correctAnswer}
        items={options.map(val => ({
          value: val.shape,
          component: <AssetSvg name={val.svg as SvgName} height={80} width={100} />
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RightAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
