import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { range } from '../../../../utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusiveStep,
  randomNumber,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { roundToTheNearest } from '../../../../utils/math';
import { getRandomMonth, monthAsWord, monthNames, monthSchema } from '../../../../utils/months';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import LineGraph, {
  Key
} from '../../../../components/question/representations/Coordinates/LineGraph';
import { LineGraphColors } from '../../../../theme/colors';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getRandomUniqueUkCities, ukCitiesSchema } from '../../../../utils/cities';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';

function getRandomRainfall(mean: number, amplitude: number, noiseAmplitude: number): number[] {
  return (
    range(1, 12)
      // Model on a cosine curve
      .map(x => Math.cos((x * Math.PI) / 6) * amplitude + mean)
      // ... with some noise
      .map(x => x + randomNumber(-noiseAmplitude, noiseAmplitude))
      // ... rounded to the nearest 5
      .map(x => roundToTheNearest(x, 5))
  );
}

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSo',
  description: 'aSo',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      populationMax: z.number().int().min(10000).max(12000).multipleOf(2000),
      population: z.number().int().min(0).max(12000).multipleOf(1000).array().length(7),
      startingYear: z.number().int().min(1870).max(1950).multipleOf(10),
      selectedYear: z.number().int().min(1870).max(2010).multipleOf(10)
    })
    .refine(
      val => val.population.every(it => it <= val.populationMax),
      'Each population value must be less than or equal to populationMax.'
    )
    .refine(
      val => val.selectedYear >= val.startingYear && val.selectedYear <= val.startingYear + 60,
      'selectedYear must be between startingYear and startingYear + 60'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const populationMax = randomIntegerInclusiveStep(10000, 12000, 2000);

    const startingPopulation = randomIntegerInclusiveStep(1000, 3000, 1000);

    const population = (() => {
      const currentPop = [startingPopulation];

      for (let i = 0; i < 6; i++) {
        currentPop.push(
          currentPop[i] +
            randomIntegerInclusiveStep(0, 2000, 1000, {
              constraint: x =>
                (i > 0 ? x !== currentPop[i] - currentPop[i - 1] : true) &&
                currentPop[i] + x <= populationMax
            })
        );
      }

      return currentPop;
    })();

    const startingYear = randomIntegerInclusiveStep(1870, 1950, 10);

    const selectedYearIsNumYearsLater = randomIntegerInclusiveStep(0, 60, 10);

    const selectedYear = startingYear + selectedYearIsNumYearsLater;

    return { populationMax, population, startingYear, selectedYear };
  },
  Component: ({ question, translate }) => {
    const { populationMax, population, startingYear, selectedYear } = question;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    const years = range(0, 7).map(x => (x * 10 + startingYear).toString());

    const correctAnswer = population[(selectedYear - startingYear) / 10];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatWasPopulationAtEndOfYear(selectedYear)}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[correctAnswer.toString()]}
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={0}
                xMin={1}
                xMax={8}
                xStepSize={1}
                yAxis={1}
                yMin={0}
                yMax={populationMax}
                yStepSize={2000}
                yAxisLabel={translate.graphLabels.population()}
                yAxisLabelOffset={50}
                xAxisLabel={translate.graphLabels.year()}
                xLabels={years}
                yLabels={range(0, populationMax, 2000).map(x => x.toLocaleString())}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={population} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSp',
  description: 'aSp',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      populationMax: z.number().int().min(10000).max(12000).multipleOf(2000),
      population: z.number().int().min(0).max(12000).multipleOf(1000).array().length(7),
      startingYear: z.number().int().min(1870).max(1950).multipleOf(10),
      selectedDecade: z.number().int().min(1870).max(2000).multipleOf(10),
      smallestOrGreatest: z.enum(['smallest', 'greatest'])
    })
    .refine(
      val => val.population.every(it => it <= val.populationMax),
      'Each population value must be less than or equal to populationMax.'
    )
    .refine(
      val => val.selectedDecade >= val.startingYear && val.selectedDecade <= val.startingYear + 50,
      'selectedYear must be between startingYear and startingYear + 50'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    const populationMax = randomIntegerInclusiveStep(10000, 12000, 2000);

    const startingPopulation = randomIntegerInclusiveStep(1000, 2000, 1000);

    const startingYear = randomIntegerInclusiveStep(1870, 1950, 10);

    const selectedDecadeIsNumYearsLater = randomIntegerInclusiveStep(0, 50, 10);

    const selectedDecade = startingYear + selectedDecadeIsNumYearsLater;

    const selectedIndex = (selectedDecade - startingYear) / 10;

    const population = (() => {
      const currentPop = [startingPopulation];

      for (let i = 0; i < 6; i++) {
        if (i === selectedIndex) {
          const populationToAdd = smallestOrGreatest === 'smallest' ? 0 : 2000;
          currentPop.push(currentPop[i] + populationToAdd);
        } else {
          const [lowerBound, upperBound] =
            smallestOrGreatest === 'smallest' ? [1000, 2000] : [0, 1000];

          currentPop.push(
            currentPop[i] +
              randomIntegerInclusiveStep(lowerBound, upperBound, 1000, {
                constraint: x =>
                  (i > 0 ? x !== currentPop[i] - currentPop[i - 1] : true) &&
                  currentPop[i] + x <= populationMax
              })
          );
        }
      }

      return currentPop;
    })();

    return { populationMax, population, startingYear, selectedDecade, smallestOrGreatest };
  },
  Component: props => {
    const {
      question: { populationMax, population, startingYear, selectedDecade, smallestOrGreatest },
      translate
    } = props;

    const years = range(0, 7).map(x => x * 10 + startingYear);

    const yearsStrings = years.map(year => year.toString());

    const selectableAnswers = [
      ...getRandomSubArrayFromArray(years.slice(0, 6), 3, {
        constraint: x => x !== selectedDecade,
        random: seededRandom(props.question)
      }),
      selectedDecade
    ]
      .sort((a, b) => a - b) // Sort years in ascending order.
      .map(year => year.toString()); // Turn these years to string - years should NOT be localised.

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.selectDecadeThatSmallestPopulationIncrease()
            : translate.instructions.selectDecadeThatGreatestPopulationIncrease()
        }
        pdfTitle={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.circleDecadeThatSmallestPopulationIncrease()
            : translate.instructions.circleDecadeThatGreatestPopulationIncrease()
        }
        selectables={Object.fromEntries(
          selectableAnswers.map(year => [
            translate.time.decadePlural(year),
            translate.time.decadePlural(year)
          ])
        )}
        correctAnswer={[translate.time.decadePlural(selectedDecade)]}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                sizingMethod="dimens"
                width={dimens.width}
                height={dimens.height}
                xAxis={0}
                xMin={1}
                xMax={8}
                xStepSize={1}
                yAxis={1}
                yMin={0}
                yMax={populationMax}
                yStepSize={2000}
                yAxisLabel={translate.graphLabels.population()}
                yAxisLabelOffset={50}
                xAxisLabel={translate.graphLabels.year()}
                xLabels={yearsStrings}
                yLabels={range(0, populationMax, 2000).map(x => x.toLocaleString())}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={population} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aSq',
  description: 'aSq',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      populationMax: z.number().int().min(10000).max(12000).multipleOf(2000),
      population: z.number().int().min(0).max(12000).multipleOf(1000).array().length(7),
      startingYear: z.number().int().min(1870).max(1950).multipleOf(10),
      selectedYear: z
        .number()
        .int()
        .min(1875)
        .max(2005)
        .multipleOf(5)
        .refine(val => val % 10 === 5, 'selectedYear must end in 5')
    })
    .refine(
      val => val.population.every(it => it <= val.populationMax),
      'Each population value must be less than or equal to populationMax.'
    )
    .refine(
      val => val.selectedYear >= val.startingYear && val.selectedYear <= val.startingYear + 55,
      'selectedYear must be between startingYear + 5 and startingYear + 55'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const populationMax = randomIntegerInclusiveStep(10000, 12000, 2000);

    const startingPopulation = randomIntegerInclusiveStep(1000, 3000, 1000);

    const population = (() => {
      const currentPop = [startingPopulation];

      for (let i = 0; i < 6; i++) {
        currentPop.push(
          currentPop[i] +
            randomIntegerInclusiveStep(0, 2000, 1000, {
              constraint: x =>
                (i > 0 ? x !== currentPop[i] - currentPop[i - 1] : true) &&
                currentPop[i] + x <= populationMax
            })
        );
      }

      return currentPop;
    })();

    const startingYear = randomIntegerInclusiveStep(1870, 1950, 10);

    const selectedYearIsNumYearsLater = randomIntegerInclusiveStep(0, 50, 10) + 5;

    const selectedYear = startingYear + selectedYearIsNumYearsLater;

    return { populationMax, population, startingYear, selectedYear };
  },
  Component: ({ question, translate }) => {
    const { populationMax, population, startingYear, selectedYear } = question;

    const color1 = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    const years = range(0, 7).map(x => x * 10 + startingYear);

    const yearsStrings = years.map(year => year.toString());

    const lowerIndex = years.indexOf(roundToTheNearest(selectedYear, 10, 'down'));

    const upperIndex = years.indexOf(roundToTheNearest(selectedYear, 10, 'up'));

    const correctAnswer = (population[lowerIndex] + population[upperIndex]) / 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.estimatePopulationInX(selectedYear)}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        inputMaxCharacters={5}
        testCorrect={userAnswer =>
          // Allow a ±200 acceptable range:
          parseInt(userAnswer[0]) <= correctAnswer + 200 &&
          parseInt(userAnswer[0]) >= correctAnswer - 200
        }
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={0}
                xMin={1}
                xMax={8}
                xStepSize={1}
                yAxis={1}
                yMin={0}
                yMax={populationMax}
                yStepSize={2000}
                yAxisLabel={translate.graphLabels.population()}
                yAxisLabelOffset={50}
                xAxisLabel={translate.graphLabels.year()}
                xLabels={yearsStrings}
                yLabels={range(0, populationMax, 2000).map(x => x.toLocaleString())}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={population} color={color1} />
              </Grid>
            )}
          </MeasureView>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.allowMarginOfErrorOfX(200)
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aSr',
  description: 'aSr',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      rainfallMax: z.number().int().min(40).max(60).multipleOf(10),
      rainfall1: z.number().int().min(0).array().length(12),
      rainfall2: z.number().int().min(0).array().length(12),
      city1: ukCitiesSchema,
      city2: ukCitiesSchema,
      month: monthSchema
    })
    .refine(
      ({ rainfallMax, rainfall1, rainfall2 }) =>
        [...rainfall1, ...rainfall2].every(it => it <= rainfallMax),
      'Each rainfall value must be less than or equal to rainfallMax.'
    )
    .refine(val => val.city1 !== val.city2, 'city1 and city2 must be different.'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const rainfallMax = randomIntegerInclusiveStep(40, 60, 10);

    const [rainfall1, rainfall2] = shuffle([
      getRandomRainfall(15, 10, 5),
      getRandomRainfall(rainfallMax - 15, 10, 5)
    ]);

    const [city1, city2] = getRandomUniqueUkCities(2);

    const month = getRandomMonth();

    return { rainfallMax, rainfall1, rainfall2, city1, city2, month };
  },
  Component: ({ question, translate }) => {
    const { rainfallMax, rainfall1, rainfall2, city1, city2, month } = question;

    const [color1, color2] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(question)
    });

    const city1String = translate.cities[city1]();

    const city2String = translate.cities[city2]();

    const colors = { [city1String]: color1, [city2String]: color2 } as const;

    // Don't always show city1 first in the key.
    const keyCities = shuffle([city1String, city2String], { random: seededRandom(question) });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMillimetresOfRainfallWereThereInCityInMonth(
          city1String,
          monthAsWord(month, translate)
        )}
        sentence={translate.answerSentences.ansMm()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[rainfall1[monthNames.indexOf(month)].toString()]}
        Content={
          <>
            <Key
              colors={keyCities.map(city => colors[city])}
              labels={keyCities}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <MeasureView style={{ marginBottom: -30 }}>
              {({ width, height }) => (
                <Grid
                  sizingMethod="dimens"
                  width={width}
                  height={height}
                  xAxis={0}
                  xMin={1}
                  xMax={12}
                  xStepSize={1}
                  yAxis={1}
                  yMin={0}
                  yMax={rainfallMax}
                  yStepSize={10}
                  yAxisLabel={translate.graphLabels.rainfallMm()}
                  xAxisLabel={translate.graphLabels.monthsOfTheYear()}
                  xLabels={monthNames.map(month => translate.time.monthAbbreviations[month]())}
                  xAxisArrowLabel={null}
                  yAxisArrowLabel={null}
                >
                  <LineGraph points={rainfall1} color={color1} />
                  <LineGraph points={rainfall2} color={color2} />
                </Grid>
              )}
            </MeasureView>
          </>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSs',
  description: 'aSs',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      rainfallMax: z.number().int().min(40).max(60).multipleOf(10),
      selectedRainfall: z.number().int().min(0).array().length(12),
      otherRainfall: z.number().int().min(0).array().length(12),
      selectedCity: ukCitiesSchema,
      otherCity: ukCitiesSchema,
      driestOrWettest: z.enum(['driest', 'wettest'])
    })
    .refine(
      ({ rainfallMax, selectedRainfall, otherRainfall }) =>
        [...selectedRainfall, ...otherRainfall].every(it => it <= rainfallMax),
      'Each rainfall value must be less than or equal to rainfallMax.'
    )
    .refine(
      val => val.selectedCity !== val.otherCity,
      'selectedCity and otherCity must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const driestOrWettest = getRandomFromArray(['driest', 'wettest'] as const);

    const rainfallMax = randomIntegerInclusiveStep(40, 60, 10);

    const [selectedRainfall, otherRainfall] = shuffle([
      getRandomRainfall(15, 10, 5),
      getRandomRainfall(rainfallMax - 15, 10, 5)
    ]);

    const [selectedCity, otherCity] = getRandomUniqueUkCities(2);

    const valueToFind =
      driestOrWettest === 'driest' ? Math.min(...selectedRainfall) : Math.max(...selectedRainfall);

    const equivalentIndexes = selectedRainfall
      .map((element, index) => (element === valueToFind ? index : ''))
      .filter(element => element !== '') as number[];

    const selectedIndex = getRandomFromArray(equivalentIndexes);

    for (let i = 0; i < equivalentIndexes.length; i++) {
      if (equivalentIndexes[i] !== selectedIndex) {
        driestOrWettest === 'driest'
          ? // If looking for driest, +5 to all the driest months that we want to be incorrect so only one correct answer remains:
            (selectedRainfall[equivalentIndexes[i]] += 5)
          : // If looking for wettest, -5 to all the wettest months that we want to be incorrect so only one correct answer remains:
            (selectedRainfall[equivalentIndexes[i]] -= 5);
      }
    }

    return {
      rainfallMax,
      selectedRainfall,
      otherRainfall,
      selectedCity,
      otherCity,
      driestOrWettest
    };
  },
  Component: props => {
    const {
      question: {
        rainfallMax,
        selectedRainfall,
        otherRainfall,
        selectedCity,
        otherCity,
        driestOrWettest
      },
      translate
    } = props;

    const correctIndex =
      driestOrWettest === 'driest'
        ? selectedRainfall.indexOf(Math.min(...selectedRainfall))
        : selectedRainfall.indexOf(Math.max(...selectedRainfall));

    const selectableIndexes = [
      ...randomUniqueIntegersInclusive(0, 11, 3, {
        constraint: x => x !== correctIndex,
        random: seededRandom(props.question)
      }),
      correctIndex
    ].sort((a, b) => a - b); // Sort indexes in ascending order.

    const [color1, color2] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    const selectedCityString = translate.cities[selectedCity]();

    const otherCityString = translate.cities[otherCity]();

    const colors = { [selectedCityString]: color1, [otherCityString]: color2 } as const;

    // Don't always show selectedCity first in the key.
    const keyCities = shuffle([selectedCityString, otherCityString], {
      random: seededRandom(props.question)
    });

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          driestOrWettest === 'driest'
            ? translate.instructions.selectDriestMonthInX(selectedCityString)
            : translate.instructions.selectWettestMonthInX(selectedCityString)
        }
        pdfTitle={
          driestOrWettest === 'driest'
            ? translate.instructions.circleDriestMonthInX(selectedCityString)
            : translate.instructions.circleWettestMonthInX(selectedCityString)
        }
        selectables={Object.fromEntries(
          selectableIndexes.map(selectableIndex => [
            selectableIndex.toString(),
            monthNames[selectableIndex]
          ])
        )}
        correctAnswer={[correctIndex.toString()]}
        topContent={
          <Key
            colors={keyCities.map(city => colors[city])}
            labels={keyCities}
            style={{ marginBottom: 16, marginLeft: 80 }}
          />
        }
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                sizingMethod="dimens"
                width={dimens.width}
                height={dimens.height}
                xAxis={0}
                xMin={1}
                xMax={12}
                xStepSize={1}
                yAxis={1}
                yMin={0}
                yMax={rainfallMax}
                yStepSize={10}
                yAxisLabel={translate.graphLabels.rainfallMm()}
                xAxisLabel={translate.graphLabels.monthsOfTheYear()}
                xLabels={monthNames.map(month => translate.time.monthAbbreviations[month]())}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={selectedRainfall} color={color1} />
                <LineGraph points={otherRainfall} color={color2} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSt',
  description: 'aSt',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z
    .object({
      rainfallMax: z.number().int().min(40).max(60).multipleOf(10),
      rainfall1: z.number().int().min(0).array().length(12),
      rainfall2: z.number().int().min(0).array().length(12),
      city1: ukCitiesSchema,
      city2: ukCitiesSchema,
      month: monthSchema
    })
    .refine(
      ({ rainfallMax, rainfall1, rainfall2 }) =>
        [...rainfall1, ...rainfall2].every(it => it <= rainfallMax),
      'Each rainfall value must be less than or equal to rainfallMax.'
    )
    .refine(val => val.city1 !== val.city2, 'city1 and city2 must be different.'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const rainfallMax = randomIntegerInclusiveStep(40, 60, 10);

    const [rainfall1, rainfall2] = shuffle([
      getRandomRainfall(15, 10, 5),
      getRandomRainfall(rainfallMax - 15, 10, 5)
    ]);

    const [city1, city2] = getRandomUniqueUkCities(2);

    const month = getRandomMonth();

    return { rainfallMax, rainfall1, rainfall2, city1, city2, month };
  },
  Component: ({ question, translate }) => {
    const { rainfallMax, rainfall1, rainfall2, city1, city2, month } = question;

    const [color1, color2] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(question)
    });

    const city1String = translate.cities[city1]();

    const city2String = translate.cities[city2]();

    const colors = { [city1String]: color1, [city2String]: color2 } as const;

    const [firstCity, secondCity] =
      rainfall1[monthNames.indexOf(month)] >= rainfall2[monthNames.indexOf(month)]
        ? [city1String, city2String]
        : [city2String, city1String];

    // Don't always show city1 first in the key.
    const keyCities = shuffle([city1String, city2String], { random: seededRandom(question) });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchMoreRainfallWasThereInCity1ThanCity2InMonth(
          firstCity,
          secondCity,
          monthAsWord(month, translate)
        )}
        sentence={translate.answerSentences.ansMm()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          Math.abs(
            rainfall1[monthNames.indexOf(month)] - rainfall2[monthNames.indexOf(month)]
          ).toString()
        ]}
        Content={
          <>
            <Key
              colors={keyCities.map(city => colors[city])}
              labels={keyCities}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <MeasureView style={{ marginBottom: -30 }}>
              {({ width, height }) => (
                <Grid
                  sizingMethod="dimens"
                  width={width}
                  height={height}
                  xAxis={0}
                  xMin={1}
                  xMax={12}
                  xStepSize={1}
                  yAxis={1}
                  yMin={0}
                  yMax={rainfallMax}
                  yStepSize={10}
                  yAxisLabel={translate.graphLabels.rainfallMm()}
                  xAxisLabel={translate.graphLabels.monthsOfTheYear()}
                  xLabels={monthNames.map(month => translate.time.monthAbbreviations[month]())}
                  xAxisArrowLabel={null}
                  yAxisArrowLabel={null}
                >
                  <LineGraph points={rainfall1} color={color1} />
                  <LineGraph points={rainfall2} color={color2} />
                </Grid>
              )}
            </MeasureView>
          </>
        }
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndInterpretLineGraphs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
