// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MakeAWholeWithTenths from './1MakeAWholeWithTenths';
import MakeAWholeWithHundredths from './2MakeAWholeWithHundredths';
import PartitionDecimals from './3PartitionDecimals';
import FlexiblyPartitionDecimals from './4FlexiblyPartitionDecimals';
import CompareDecimals from './5CompareDecimals';
import OrderDecimals from './6OrderDecimals';
import RoundToTheNearestWholeNumber from './7RoundToTheNearestWholeNumber';
import HalvesAndQuartersAsDecimals from './8HalvesAndQuartersAsDecimals';

const Block = newBlockContent({
  block: 'DecimalsB',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    MakeAWholeWithTenths, // Step 1
    MakeAWholeWithHundredths, // Step 2
    PartitionDecimals, // Step 3
    FlexiblyPartitionDecimals, // Step 4
    CompareDecimals, // Step 5
    OrderDecimals, // Step 6
    RoundToTheNearestWholeNumber, // Step 7
    HalvesAndQuartersAsDecimals // Step 8
  ]
});
export default Block;
