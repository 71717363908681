export const SHARE_URL = 'https://infinity.whiteroseeducation.com/have-a-go';

export function getShareText({
  starsTotalScore,
  starsTotalLength,
  timeTakenSeconds
}: {
  starsTotalScore: string | number;
  starsTotalLength: string | number;
  timeTakenSeconds: string | number;
}) {
  return (
    `I got ⭐${starsTotalScore}/${starsTotalLength} in ⏱️${timeTakenSeconds}s on today's quiz.%0A` +
    `%23whiteroseeducation %23infinity ${SHARE_URL}`
  );
}

export function getClipboardText({
  starsTotalScore,
  starsTotalLength,
  timeTakenSeconds
}: {
  starsTotalScore: string | number;
  starsTotalLength: string | number;
  timeTakenSeconds: string | number;
}) {
  return `I got ⭐${starsTotalScore}/${starsTotalLength} in ⏱️${timeTakenSeconds}s on today's quiz.\n#whiteroseeducation #infinity ${SHARE_URL}`;
}
