import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { ADD, SUB } from 'common/src/constants';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  fractionArithmetic,
  fractionToDecimal,
  improperFractionToMixedNumber,
  mixedNumberToImproperFraction,
  simplify
} from 'common/src/utils/fractions';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { arraysHaveSameContents } from '../../../../utils/collections';
import { all, create, number } from 'mathjs';
import { threeNumberLCM } from '../../../../utils/multiples';
import { View } from 'react-native';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqV',
  description: 'aqV',
  keywords: ['Subtraction', 'Fractions', 'Mixed numbers', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number3: z.number().int().min(3).max(12)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number3 = randomIntegerInclusive(3, 12);

    return { number1, number3 };
  },
  Component: ({ question: { number1, number3 }, translate, displayMode }) => {
    const number2 = 1;

    const partA = fractionToDecimal(number2, number3) + number1;

    const partB = number(math.evaluate(`10 - ${partA}`));

    const numbers = [[10], [partA, partB]];
    const strings = [
      [],
      [
        `<frac w='${number1.toLocaleString()}' n='${number2.toLocaleString()}' d='${number3.toLocaleString()}'/>`,
        '?'
      ]
    ];

    const answer1 = 10 - number1 - 1;
    const answer2 = number3 - number2;
    const answer3 = number3;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1200}
        title={translate.instructions.completeBarModelGiveAnswerInSimplestForm()}
        testCorrect={[answer1.toString(), answer2.toString(), answer3.toString()]}
        sentence={`<frac wAns='' nAns='' dAns=''/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            total={10}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            rowHeight={displayMode === 'digital' ? undefined : 200}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
          />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqW',
  description: 'aqW',
  keywords: ['Addition', 'Fractions', 'Common denominator', 'Multi-step'],
  schema: z.object({
    number1: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const number2 = number1 * 2;
    const number3 = number1 * 4;

    // Add all fractions together
    const [ansNumerator, ansDenominator] = fractionArithmetic(
      fractionArithmetic([1, number1], [1, number2], ADD),
      [1, number3],
      ADD
    );

    const [simplifiedAns1, simplifiedAns2] = simplify(ansNumerator, ansDenominator);

    const startingNumber = 1 / number1;
    const endNumber = simplifiedAns1 / simplifiedAns2;

    const tickArray = [
      { label: `<frac n='1' d='${number1}'/>`, position: startingNumber },
      { label: '', position: 1 / number1 + 1 / number2 },
      { label: `<frac nAns='' dAns=''/>`, position: endNumber }
    ];

    const jumpArrowArray = [
      {
        start: startingNumber,
        end: 1 / number1 + 1 / number2,
        label: `  <frac n='1' d='${number2}'/>`
      },
      { start: 1 / number1 + 1 / number2, end: endNumber, label: `  <frac n='1' d='${number3}'/>` }
    ];

    return (
      <QF25JumpOnANumberLine
        title={translate.instructions.completeMissingValueOnNumberLine()}
        start={startingNumber - 0.05}
        end={endNumber}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionMustBeInSimplestForm()
        }}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aqX',
  description: 'aqX',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Mixed numbers', 'Multi-step'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(3).max(10),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(1).max(9),
    number5: z.number().int().min(6).max(10),
    number6: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const number3 = randomIntegerInclusive(1, 5);

    const { number1, number2, number4, number5, number6 } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusive(3, 10);

        const number1 = randomIntegerInclusive(1, number2 - 1);

        const number5 = randomIntegerInclusive(6, 10, {
          constraint: x => x !== number2
        });

        const number4 = randomIntegerInclusive(1, number5 - 1);

        const number6 = randomIntegerInclusive(2, 4, {
          constraint: x => x !== number2 && x !== number5
        });

        return { number1, number2, number4, number5, number6 };
      },

      ({ number1, number2, number4, number5, number6 }) => {
        const [_simplifiedNum1, simplifiedNum2] = simplify(number1, number2);

        const [_simplifiedNum4, simplifiedNum5] = simplify(number4, number5);

        // LCM of all three denominators presented to the user must be less than 100:
        return threeNumberLCM(simplifiedNum2, simplifiedNum5, number6) < 100;
      }
    );

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const [frac2Numerator, frac2Denominator] = mixedNumberToImproperFraction(
      number3,
      number4,
      number5
    );

    const additionNumerator = number1 * frac2Denominator + frac2Numerator * number2;
    const additionDenominator = number2 * frac2Denominator;

    const answerNumerator = additionNumerator * number6 - additionDenominator;
    const answerDenominator = additionDenominator * number6;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum4, simplifiedNum5] = simplify(number4, number5);

    const [answer1, answer2, answer3] = improperFractionToMixedNumber(
      answerNumerator,
      answerDenominator
    );

    const [simplifiedAns2, simplifiedAns3] = simplify(answer2, answer3);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[answer1.toString(), simplifiedAns2.toString(), simplifiedAns3.toString()]}
        sentence={`<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/> ${ADD} <frac w='${number3}' n='${simplifiedNum4}' d='${simplifiedNum5}'/> ${SUB} <frac n='1' d='${number6}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqY',
  description: 'aqY',
  keywords: ['Addition', 'Fractions', 'Improper fractions', 'Multi-step', 'Missing value'],
  schema: z.object({
    number1: z.number().int().min(5).max(9),
    number2: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 9);
    const number2 = randomIntegerInclusive(1, number1 - 1);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const answer1 = 4 * number1 - (1 * 3 + number2 * 3);
    const answer2 = 3 * number1;

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum1] = simplify(number2, number1);

    const [simplifiedAns1, simplifiedAns2] = simplify(answer1, answer2);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={`<frac n='1' d='${number1}'/> ${ADD} <frac n='${simplifiedNum2}' d='${simplifiedNum1}'/> ${ADD} <frac nAns='' dAns=''/> = <frac n='4' d='3'/>`}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqZ',
  description: 'aqZ',
  keywords: ['Subtraction', 'Fractions'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(8),
      number2: z.number().int().min(3).max(9),
      number3: z.number().int().min(5).max(8)
    })
    .refine(
      val => val.number1 / val.number2 > 1 / val.number3,
      'number1/number2 must be greater than 1/number3'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 9);
    const number1 = number2 - 1;

    const number3 = randomIntegerInclusive(5, 8, {
      constraint: x => x !== number2
    });

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      displayMode,
      translate
    } = props;

    // Calculate answer
    const ansNumerator = number1 * number3 - number2;
    const ansDenominator = number2 * number3;

    // Simplify all fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);

    const [simplifiedAns1, simplifiedAns2] = simplify(ansNumerator, ansDenominator);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howMuchPetrolLeftFraction(
          `<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/>`,
          `<frac n='1' d='${number3}'/>`
        )}
        titleFractionDividerStyle={{ marginVertical: 4 }}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 34 : 64 }}
        testCorrect={[simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={`<frac nAns='' dAns=''/>`}
        questionHeight={900}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aq0',
  description: 'aq0',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Mixed numbers'],
  schema: z.object({
    number1: z.number().int().min(1).max(3),
    number3: z.number().int().min(2).max(3),
    number4: z.number().int().min(4).max(6),
    number6: z.number().int().min(4).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 3);
    const number3 = randomIntegerInclusive(2, 3);
    const number4 = randomIntegerInclusive(4, 6);
    const number6 = randomIntegerInclusive(4, 8);

    return { number1, number3, number4, number6 };
  },
  Component: props => {
    const {
      question: { number1, number3, number4, number6 },
      translate,
      displayMode
    } = props;

    const number2 = 1;
    const number5 = number6 - 1;
    const number7 = number4 + 1;
    const number8 = 1;

    const statements = [
      {
        lhsComponent: (
          <View
            style={{
              width: displayMode === 'digital' ? 450 : 600,
              alignItems: 'flex-end'
            }}
          >
            <TextStructure
              sentence={`<frac w='${number1}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number4}' n='${number5}' d='${number6}'/> = <frac w='${number4}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number1}' n='${number5}' d='${number6}'/>`}
            />
          </View>
        ),
        correctAnswer: arraysHaveSameContents(
          fractionArithmetic([number1, number2, number3], [number4, number5, number6], ADD),
          fractionArithmetic([number4, number2, number3], [number1, number5, number6], ADD)
        )
      },
      {
        lhsComponent: (
          <View
            style={{
              width: displayMode === 'digital' ? 450 : 600,
              alignItems: 'flex-end'
            }}
          >
            <TextStructure
              sentence={`<frac w='${number4}' n='${number5}' d='${number6}'/> ${SUB} <frac n='${number2}' d='${number3}'/> = <frac w='${number7}' n='${number5}' d='${number6}'/> ${SUB} <frac w='${number8}' n='${number2}' d='${number3}'/>`}
            />
          </View>
        ),
        correctAnswer: arraysHaveSameContents(
          fractionArithmetic([number4, number5, number6], [number2, number3], SUB),
          fractionArithmetic([number7, number5, number6], [number8, number2, number3], SUB)
        )
      },
      {
        lhsComponent: (
          <View
            style={{
              width: displayMode === 'digital' ? 450 : 600,
              alignItems: 'flex-end'
            }}
          >
            <TextStructure
              sentence={`<frac w='${number4}' n='${number5}' d='${number6}'/> ${SUB} <frac w='${number1}' n='${number2}' d='${number3}'/> = <frac w='${number4}' n='${number2}' d='${number3}'/> ${SUB} <frac w='${number1}' n='${number5}' d='${number6}'/>`}
            />
          </View>
        ),
        correctAnswer: arraysHaveSameContents(
          fractionArithmetic([number4, number5, number6], [number1, number2, number3], SUB),
          fractionArithmetic([number4, number2, number3], [number1, number5, number6], SUB)
        )
      },
      {
        lhsComponent: (
          <View
            style={{
              width: displayMode === 'digital' ? 450 : 600,
              alignItems: 'flex-end'
            }}
          >
            <TextStructure
              sentence={`<frac w='${number4}' n='${number2}' d='${number3}'/> ${ADD} <frac w='${number1}' n='${number5}' d='${number6}'/> = <frac w='${number4}' n='${number2}' d='${number3}'/> ${SUB} <frac w='${number1}' n='${number5}' d='${number6}'/>`}
            />
          </View>
        ),
        correctAnswer: arraysHaveSameContents(
          fractionArithmetic([number4, number2, number3], [number1, number5, number6], ADD),
          fractionArithmetic([number4, number2, number3], [number1, number5, number6], SUB)
        )
      }
    ];

    const random = seededRandom(props.question);

    const chosenStatements = shuffle(getRandomSubArrayFromArray(statements, 3, { random }), {
      random
    });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.areTheStatementsTrueOrFalse()}
        items={[
          { component: translate.instructions.true(), value: true },
          { component: translate.instructions.false(), value: false }
        ]}
        statements={chosenStatements}
        statementStyle={{ justifyContent: 'center' }}
        actionPanelVariant="end"
        moveOrCopy="copy"
        itemVariant="square"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiStepProblems',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
