import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';
const SvgComponent = (props: SvgProps) => (
  <Svg fill="#002E63" viewBox="0 0 48 49" {...props}>
    <Path
      fillRule="evenodd"
      d="M24.586 9.067a2 2 0 0 1 2.828 0l14 14a2 2 0 0 1 0 2.828l-14 14a2 2 0 0 1-2.828-2.828l10.586-10.586H8a2 2 0 0 1 0-4h27.172L24.586 11.895a2 2 0 0 1 0-2.828Z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
