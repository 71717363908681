import {
  arrayHasNoDuplicates,
  range,
  rangeAsString,
  filledArray,
  NonEmptyArray
} from 'common/src/utils/collections';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  getTwoConsecutiveAndOneNot
} from 'common/src/utils/random';
import { MULT } from 'common/src/constants';
import { roundToTheNearest } from 'common/src/utils/math';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aeU',
  description: 'aeU',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(100000).max(900000))
      .length(3)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 1000000;
    const interval = 100000;

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and a third that is not */
    const numbers = getTwoConsecutiveAndOneNot(
      startingNumber + interval,
      endNumber - interval,
      interval
    );

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1000000;
    const interval = 100000;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'aeV',
  description: 'aeV',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(500000).max(9500000)
  }),
  simpleGenerator: () => {
    const interval = 500000;
    const startingNumber = 0;
    const endNumber = 10000000;
    const labelInterval = 5000000;

    // Make this miss the first, middle and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval).filter(
      x => x !== labelInterval
    ) as NonEmptyArray<number>;
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 1000000;
    const labelInterval = 5000000;
    const startingNumber = 0;
    const endNumber = 10000000;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aeW',
  description: 'aeW',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(0).max(10000000)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 10000000;
    const answerInterval = 500000;

    // Make this miss the first and last number
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 10000000;
    const tickInterval = 1000000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 200000, number + 200000]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'aeX',
  description: 'aeX',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z
    .object({
      number: z.number().int().min(500000).max(9500000),
      interval: z.union([z.literal(500000), z.literal(2000000), z.literal(2500000)])
    })
    .refine(
      val => val.number >= val.interval,
      val => ({ message: `All answers must be greater than or equal to ${val.interval}` })
    )
    .refine(
      val => val.number <= 10000000 - val.interval,
      val => ({ message: `All answers must less than or equal to ${10000000 - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([500000, 2000000, 2500000] as const);
    const startingNumber = 0;
    const endNumber = interval === 500000 ? 2500000 : 10000000;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, interval };
  },
  Component: props => {
    const {
      question: { number, interval },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = interval === 500000 ? 2500000 : 10000000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aeY',
  description: 'aeY',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z
    .object({
      number: z.number().int().min(1000000).max(9000000),
      startingNumber: z.number().int().min(1000000).max(4000000),
      endNumber: z.number().int().min(6000000).max(9000000)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 4) * 1000000;
    const endNumber = startingNumber + 5000000;
    const answerInterval = 500000;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1000000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 100000, number + 100000]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'aeZ',
  description: 'aeZ',
  keywords: ['Number line', 'Place value', '10,000,000', 'Million'],
  schema: z
    .object({
      number: z.number().int().gt(1000000).lte(10000000),
      // Note: we need equal here, because for interval=500,000 the only valid number line is 5,000,000-10,000,000
      startingNumber: z.number().int().gt(1000000).lte(10000000),
      endNumber: z.number().int().gt(1000000).lte(10000000),
      interval: z.union([
        z.literal(10000),
        z.literal(20000),
        z.literal(50000),
        z.literal(100000),
        z.literal(200000),
        z.literal(500000)
      ])
    })
    .refine(
      val => val.number >= val.startingNumber + val.interval,
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.number <= val.endNumber - val.interval,
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    )
    .refine(
      ({ startingNumber, interval }) => startingNumber % (10 * interval) === 0,
      `startingNumber must be a multiple of 10 ${MULT} interval`
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([10000, 20000, 50000, 100000, 200000, 500000] as const);
    const startingNumberLowerBound = roundToTheNearest(1000000 + 1, interval * 10, 'up');
    const startingNumberUpperBound = roundToTheNearest(
      10000000 - interval * 10,
      interval * 10,
      'down'
    );
    const startingNumber = randomIntegerInclusiveStep(
      startingNumberLowerBound,
      startingNumberUpperBound,
      interval * 10
    );
    const endNumber = startingNumber + interval * 10;
    const number = randomIntegerInclusiveStep(
      startingNumber + interval,
      endNumber - interval,
      interval
    );

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLineTo10000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});

export default SmallStep;
