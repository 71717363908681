// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newTermContent } from '../../Term';
import Shape from './Shape';
import PositionAndDirection from './PositionAndDirection';

const Term = newTermContent({
  term: 'Summer',
  blocks: [Shape, PositionAndDirection]
});
export default Term;
