import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { numberEnum } from 'common/src/utils/zod';
import {
  getItemArrangement,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  coinScaledSizes,
  displayMoney,
  moneyFromXDenominations,
  moneyToHighestDenominations,
  penceToPoundsAndPence,
  totalPenceToPoundsAndPence
} from 'common/src/utils/money';
import { View } from 'react-native';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import Text from 'common/src/components/typography/Text';
import { useMemo } from 'react';
import { getRandomItem, getSingleItemImagePath, singleItemSchema } from 'common/src/utils/items';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ate',
  description: 'ate',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z
    .object({
      amountA: numberEnum([10, 20, 50]),
      amountB: numberEnum([1, 2, 5]),
      amountCAmountInPence: numberEnum([120, 150, 200]),
      amountD: numberEnum([10, 20, 50])
    })
    .refine(val => val.amountD !== val.amountA, 'amountD must not be equal to amountA'),
  simpleGenerator: () => {
    const [amountA, amountD] = getRandomSubArrayFromArray([10, 20, 50] as const, 2);
    const amountB = getRandomFromArray([1, 2, 5] as const);
    // amountC can be multiple amounts (£1.20, £1.50, £2)
    const amountCAmountInPence = getRandomFromArray([120, 150, 200] as const);

    return { amountA, amountB, amountCAmountInPence, amountD };
  },
  Component: props => {
    const {
      question: { amountA, amountB, amountCAmountInPence, amountD },
      translate
    } = props;

    // Amounts
    const amountAInPence = moneyToHighestDenominations(100, 'pence', [amountA]);
    const amountBInPence = moneyToHighestDenominations(10, 'pence', [amountB]);
    const amountCInPence = moneyToHighestDenominations(amountCAmountInPence, 'pence', [
      amountCAmountInPence === 120 ? 20 : 50
    ]);
    const amountDInPence = moneyToHighestDenominations(100, 'pence', [amountD]);

    // Selectables
    const selectables = useMemo(() => {
      return shuffle(
        [
          {
            value: 'A',
            amount: amountAInPence
          },
          {
            value: 'B',
            amount: amountBInPence
          },
          {
            value: 'C',
            amount: amountCInPence
          },
          {
            value: 'D',
            amount: amountDInPence
          }
        ],
        { random: seededRandom(props.question) }
      );
    }, [amountAInPence, amountBInPence, amountCInPence, amountDInPence, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAmountsThatShowOnePound()}
        pdfTitle={translate.instructions.circleTheAmountsThatShowOnePound()}
        testCorrect={['A', 'D']}
        numItems={4}
        multiSelect
        renderItems={() =>
          selectables.map(selectable => {
            return {
              value: selectable.value,
              component: (
                <View
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    columnGap: 12,
                    rowGap: 12
                  }}
                >
                  {displayMoney(selectable.amount, 80, 80)}
                </View>
              )
            };
          })
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'atf',
  description: 'atf',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    selectables: numberEnum([1, 2, 5, 10, 20, 50]).array().length(9)
  }),
  simpleGenerator: () => {
    const coinValues = [1, 2, 5, 10, 20, 50] as const;
    type CoinValue = (typeof coinValues)[number];

    const curatedSelectablesChoices: CoinValue[][] = [
      [1, 2, 2, 5, 5, 10, 10, 20, 50], // e.g. 50+20+10+10+5+5
      [1, 1, 2, 2, 2, 5, 20, 20, 50], // e.g. 50+20+20+5+2+2+1
      [20, 20, 20, 20, 10, 5, 5, 2, 2], // e.g. 20+20+20+20+10+5+5
      [5, 5, 10, 10, 10, 10, 20, 20, 10], // e.g. 20+20+10+10+10+10+10+5+5
      [20, 20, 20, 20, 10, 5, 2, 2, 1], // e.g 20+20+20+20+10+5+2+2+1
      [50, 10, 10, 10, 10, 10, 5, 5, 5], // e.g 50+10+10+10+10+5+5
      [50, 5, 5, 5, 5, 20, 20, 20, 20], // e.g 50+20+20+5+5
      [20, 20, 10, 10, 10, 10, 10, 10, 5], // e.g 20+20+10+10+10+10+10+10
      [50, 20, 20, 10, 2, 2, 2, 2, 2], // e.g 50+20+20+2+2+2+2+2
      [1, 1, 10, 10, 10, 20, 20, 20, 20] // e.g 20+20+20+20+10+10
    ];

    const selectables = getRandomFromArray(curatedSelectablesChoices)!;

    return { selectables };
  },
  Component: props => {
    const {
      question: { selectables },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.select100p()}
        pdfTitle={translate.instructions.circle100p()}
        testCorrect={answer => {
          // Sum of selectables
          const sum = answer.reduce((sum, index) => sum + selectables[index], 0);

          return sum === 100;
        }}
        multiSelect
        items={selectables.map((amount, idx) => ({
          value: idx,
          component: <View>{displayMoney([`${amount}p`], 80, 80)}</View>
        }))}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationOfCoinsThatSumsToX(100)
        }}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'atg',
  description: 'atg',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pounds: z.number().int().min(1).max(3),
    pence: z.number().int().min(11).max(90),
    penceAmount: z.string().array()
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(1, 3);
    const numCoinsForPounds = pounds * 2; // Each pound needs to be represented by 2 50p coins
    const maxNumCoins = 9; // If pounds is max then there will be 6 50p coins. Pence needs to be a minimum of 3 coins so this is how we decided on max number
    const pence = rejectionSample(
      () => {
        const amount = moneyFromXDenominations(
          randomIntegerInclusive(3, maxNumCoins - numCoinsForPounds),
          'pence'
        );

        return { ...amount, doesNotExchange: amount.sum >= 11 && amount.sum <= 90 };
      },
      x => x.doesNotExchange
    );

    return { pounds, pence: pence.sum, penceAmount: pence.denominations };
  },
  Component: props => {
    const {
      question: { pounds, pence, penceAmount },
      translate,
      displayMode
    } = props;

    const poundsToPence = pounds * 100;

    const poundsAmount = moneyToHighestDenominations(poundsToPence, 'pence', [50]);

    const coinsToDisplay = [...poundsAmount, ...penceAmount];

    const arrangement = getItemArrangement(
      coinsToDisplay,
      seededRandom(coinsToDisplay.map(coin => coin)),
      2,
      6
    );

    const coinScales = coinScaledSizes(coinsToDisplay);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howMuchMoney()}
        testCorrect={[pounds.toString(), pence.toString()]}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          return arrangement.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                height: dimens.height / 3,
                width: dimens.width
              }}
            >
              {row.map(({ marginLeft, item }, itemIndex) => (
                <View key={itemIndex} style={{ marginLeft, gap: 16, justifyContent: 'center' }}>
                  {displayMoney(
                    [item],
                    (displayMode === 'digital' ? 100 : 150) * coinScales[item],
                    (displayMode === 'digital' ? 100 : 150) * coinScales[item]
                  )}
                </View>
              ))}
            </View>
          ));
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ath',
  description: 'ath',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pounds: z.number().int().min(1).max(9),
    pence: z.number().int().min(1).max(99),
    item: singleItemSchema
  }),
  simpleGenerator: () => {
    const item = getRandomItem();

    const pounds = (() => {
      switch (item) {
        case 'book':
          return randomIntegerInclusive(2, 9);
        case 'crayonbox':
          return randomIntegerInclusive(1, 3);
        case 'football':
          return randomIntegerInclusive(4, 9);
        case 'magazine':
          return randomIntegerInclusive(1, 4);
      }
    })();

    const pence = randomIntegerInclusive(1, 99);

    return { pounds, pence, item };
  },
  Component: props => {
    const {
      question: { pounds, pence, item },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = pounds * 100 + pence;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansP()}
        title={translate.instructions.whatIsThePriceOfTheItemInPence()}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AssetSvg
              name={getSingleItemImagePath(item)}
              height={displayMode === 'digital' ? (dimens.height / 3) * 2 : dimens.height}
            />
            <ContentBox
              containerStyle={{
                paddingHorizontal: 25,
                paddingVertical: 0,
                justifyContent: 'flex-end',
                right: 50,
                transform: [{ rotate: '-8deg' }]
              }}
            >
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }} variant="WRN400">
                {translate.answerSentences.xPoundsAndYPence(pounds, pence)}
              </Text>
            </ContentBox>
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ati',
  description: 'ati',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z
    .object({
      pence: z.number().int().min(101).max(999)
    })
    .refine(val => val.pence % 100 !== 0),
  simpleGenerator: () => {
    const pence = randomIntegerInclusive(101, 999, {
      constraint: x => x % 100 !== 0
    });

    return { pence };
  },
  Component: props => {
    const {
      question: { pence },
      translate
    } = props;

    const { pounds: answer1, pence: answer2 } = penceToPoundsAndPence(pence);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.whatIsTheAmountInPoundsAndPence()}
        testCorrect={userAnswer => {
          return (
            (userAnswer[0] === answer1.toString() ||
              userAnswer[0] === answer1.toString().padStart(2, '0')) &&
            (userAnswer[1] === answer2.toString() ||
              userAnswer[1] === answer2.toString().padStart(2, '0'))
          );
        }}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.xPenceEqualsPoundsandPence(pence)}
        customMarkSchemeAnswer={{ answersToDisplay: [answer1.toString(), answer2.toString()] }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atj',
  description: 'atj',
  keywords: ['Money', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pence: z.number().int().min(101).max(499)
  }),
  simpleGenerator: () => {
    const pence = randomIntegerInclusive(101, 499);

    return { pence };
  },
  Component: props => {
    const {
      question: { pence },
      translate
    } = props;

    // Get breakdown of pounds and pence from pence value
    // Then take the length of the returned array to get fewest amount of coins needed
    const fewestAmountOfCoinsToMakePence = totalPenceToPoundsAndPence(pence).length;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.whatIsTheFewestNumberOfCoinsYouCanUseToMakeXPence(pence)}
        testCorrect={[fewestAmountOfCoinsToMakePence.toString()]}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        sentence={'<ans/>'}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertPoundsAndPence',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
