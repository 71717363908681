import { ADD, MULT, SUB } from 'common/src/constants';
import { ScientificNotation } from 'common/src/utils/math';
import MissingDigitColumnOperations, {
  MissingDigitColumnOperationsWithState
} from '../representations/ColumnOperations/MissingDigitColumnOperations';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { countRange, range } from '../../../utils/collections';

type UserAnswer = {
  top: string[];
  bottom: string[];
  answer: string[];
  exchanges: string[];
  strikeout: (number | undefined)[];
};

type Props = TitleStyleProps & {
  title: string;
  topNumber: number;
  topMissingDigits?: number[];
  bottomNumber: number;
  bottomMissingDigits?: number[];
  middleNumber?: number;
  middleMissingDigits?: number[];
  answerNumber: number;
  answerMissingDigits?: number[];
  operation: ADD | SUB | MULT;
  extraSymbol?: ExtraSymbols;
  showNumberExchanges?: boolean;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerToDisplay?: { [K in keyof UserAnswer]: { [P in K]: UserAnswer[K] } }[keyof UserAnswer];
    answerText?: string;
  };
  /** Optional show an extra answer as it can be leading */
  showExtraLeadingAnswerBox?: boolean;
};

export default function QF27MissingDigitColumnOperations({
  title,
  topNumber,
  topMissingDigits = [],
  bottomNumber,
  bottomMissingDigits = [],
  middleNumber,
  middleMissingDigits = [],
  answerNumber,
  answerMissingDigits = [],
  operation,
  extraSymbol,
  showNumberExchanges = false,
  questionHeight,
  customMarkSchemeAnswer,
  showExtraLeadingAnswerBox = false,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const topNumberSci = ScientificNotation.fromNumber(topNumber);
  const bottomNumberSci = ScientificNotation.fromNumber(bottomNumber);
  const middleNumberSci = middleNumber && ScientificNotation.fromNumber(middleNumber);
  const answerNumberSci = ScientificNotation.fromNumber(answerNumber);

  const minTopPower = Math.min(topNumberSci.resolution, 0);
  const minBottomPower = Math.min(bottomNumberSci.resolution, 0);
  const minMiddlePower = middleNumberSci && Math.min(middleNumberSci.resolution, 0);
  const minAnswerPower = Math.min(answerNumberSci.resolution, 0);

  const lowestPower = Math.min(minTopPower, minBottomPower, minAnswerPower, minMiddlePower ?? 0);
  const adjustByTop = Math.min(minTopPower, lowestPower);
  const adjustByBottom =
    operation === MULT ? minBottomPower : Math.min(minBottomPower, lowestPower);
  const adjustByMiddle = Math.min(minMiddlePower ?? 0, lowestPower);
  const adjustByAnswer = Math.min(minAnswerPower, lowestPower);

  // Need to use adjusted missing digits to account for when we have decimals.
  // The test complete checks the array per pow but decimal powers include negatives so we need to adjust
  const adjustMissingDigits = (missingDigits: number[], minPower: number): number[] => {
    return missingDigits.map(i => i - minPower);
  };

  const adjustedTopMissingDigits = adjustMissingDigits(topMissingDigits, adjustByTop);
  const adjustedBottomMissingDigits = adjustMissingDigits(bottomMissingDigits, adjustByBottom);
  const adjustedMiddleMissingDigits = adjustMissingDigits(middleMissingDigits, adjustByMiddle);
  const adjustedAnswerMissingDigits = adjustMissingDigits(answerMissingDigits, adjustByAnswer);

  /** Check that every digit given by adjustedXMissingDigits has a non-empty user string set. */
  const testComplete = (answer: UserAnswer) =>
    adjustedTopMissingDigits.every(
      pow => answer.top[pow] !== undefined && answer.top[pow].length > 0
    ) &&
    adjustedBottomMissingDigits.every(
      pow => answer.bottom[pow] !== undefined && answer.bottom[pow].length > 0
    ) &&
    adjustedAnswerMissingDigits.every(
      pow => answer.answer[pow] !== undefined && answer.answer[pow].length > 0
    ) &&
    adjustedMiddleMissingDigits.every(
      pow => answer.answer[pow] !== undefined && answer.answer[pow].length > 0
    );

  const checkAnswerIfExtraAnswerBox = (answer: UserAnswer) => {
    let extraBoxOk = false;
    if (showExtraLeadingAnswerBox) {
      if (answer.answer.length === adjustedAnswerMissingDigits.length + 1) {
        return (
          answer.answer[answer.answer.length - 1] === '0' ||
          answer.answer[answer.answer.length - 1] === ''
        );
      }
      extraBoxOk = adjustedAnswerMissingDigits.length === answer.answer.length;
    } else {
      extraBoxOk = true;
    }
    return extraBoxOk;
  };

  /** Check that every digit given by adjustedXMissingDigits agrees with the digit from the number. */
  /** Also need to adjust unsignedDigitAt by the minTopPower i.e if we are looking at 2.3 tenths then the adjusted pow will be 1 but we want (1 + -1) */
  const testCorrect = (answer: UserAnswer) =>
    checkAnswerIfExtraAnswerBox(answer) &&
    adjustedTopMissingDigits.every(
      pow => answer.top[pow] === topNumberSci.unsignedDigitAt(pow + adjustByTop).toString()
    ) &&
    adjustedBottomMissingDigits.every(
      pow => answer.bottom[pow] === bottomNumberSci.unsignedDigitAt(pow + adjustByBottom).toString()
    ) &&
    adjustedAnswerMissingDigits.every(
      pow => answer.answer[pow] === answerNumberSci.unsignedDigitAt(pow + adjustByAnswer).toString()
    ) &&
    adjustedMiddleMissingDigits.every(
      pow => answer.answer[pow] === answerNumberSci.unsignedDigitAt(pow + adjustByAnswer).toString()
    );

  if (displayMode === 'markscheme' || displayMode === 'pdf') {
    const correctAnswerDefault = {
      top: [],
      bottom: [],
      middle: [],
      answer: [],
      exchanges: [],
      strikeout: []
    };

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <MissingDigitColumnOperations
                  topNumber={topNumber}
                  topMissingDigits={topMissingDigits}
                  bottomNumber={bottomNumber}
                  bottomMissingDigits={bottomMissingDigits}
                  middleNumber={middleNumber}
                  middleMissingDigits={middleMissingDigits}
                  answerNumber={answerNumber}
                  answerMissingDigits={answerMissingDigits}
                  operation={operation}
                  dimens={dimens}
                  showNumberExchanges={showNumberExchanges}
                  userAnswer={
                    displayMode === 'markscheme'
                      ? {
                          ...correctAnswerDefault,
                          ...customMarkSchemeAnswer?.answerToDisplay
                        }
                      : undefined
                  }
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <MissingDigitColumnOperationsWithState
              id="column-operation"
              topNumber={topNumber}
              topMissingDigits={topMissingDigits}
              bottomNumber={bottomNumber}
              bottomMissingDigits={bottomMissingDigits}
              middleNumber={middleNumber}
              middleMissingDigits={middleMissingDigits}
              answerNumber={answerNumber}
              answerMissingDigits={answerMissingDigits}
              operation={operation}
              dimens={dimens}
              showExtraLeadingAnswerBox={showExtraLeadingAnswerBox}
              showNumberExchanges={showNumberExchanges}
              testComplete={testComplete}
              testCorrect={testCorrect}
              defaultState={{
                top: [],
                bottom: [],
                middle: [],
                answer: [],
                exchanges: [],
                strikeout: []
              }}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}

type Row = 'top' | 'bottom' | 'answer';

/**
 * We often have our missing digits information going from position to row, rather than row to position which is what
 * this QF needs. This is just a utility function to make it easier to map between these ways of storing the same
 * information.
 */
export function getMissingDigits(
  missingOnes?: Row | Row[],
  missingTens?: Row | Row[],
  missingHundreds?: Row | Row[],
  missingThousands?: Row | Row[],
  missingTenThousands?: Row | Row[]
): { topMissingDigits: number[]; bottomMissingDigits: number[]; answerMissingDigits: number[] } {
  const allMissingDigits = [
    [0, missingOnes],
    [1, missingTens],
    [2, missingHundreds],
    [3, missingThousands],
    [4, missingTenThousands]
  ] as const;

  const findMissingDigits = (inputRow: Row): number[] =>
    allMissingDigits
      .filter(([_pow, rowOrRows]) =>
        typeof rowOrRows === 'string' ? rowOrRows === inputRow : rowOrRows?.includes(inputRow)
      )
      .map(([pow, _rowOrRows]) => pow);
  return {
    topMissingDigits: findMissingDigits('top'),
    bottomMissingDigits: findMissingDigits('bottom'),
    answerMissingDigits: findMissingDigits('answer')
  };
}

/**
 * getDecimalAnswerMissingDigits
 */
export function getDecimalMissingDigits(answer: number, decimalPlaces: number): number[] {
  return range(-decimalPlaces, answer.toFixed(decimalPlaces).length - 2 - decimalPlaces);
}

/**
 * getMarkSchemeAnswer
 * We often have the answer line as missing digits and we need the answer broken down into separate answer boxes for the markscheme
 * This is util function to return a number array for the answer
 */
export function getMarkSchemeAnswer(answer: number, answerLength: number): string[] {
  const scientificNotation = ScientificNotation.fromNumber(answer);
  const answerArray = scientificNotation.digits.map(i => i.toLocaleString());
  countRange(answerLength - answerArray.length).forEach(() =>
    answerArray.push((0).toLocaleString())
  );
  return answer < 1 ? answerArray : answerArray.reverse();
}
