import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { randomIntegerInclusive } from 'common/src/utils/random';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { filledArray, range } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors, barModelColorsArray, BarModelColorsKey } from '../../../../theme/colors';
import { ADD } from '../../../../constants';

////
// Questions
////

const Question2 = newQuestionContent({
  uid: 'bcy',
  description: 'bcy',
  keywords: ['Add', 'Number line'],
  schema: z.object({
    total: z.number().int().min(10).max(20),
    numberA: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(10, 20);

    const numberA = randomIntegerInclusive(1, 9);

    return { total, numberA };
  },
  Component: props => {
    const {
      question: { total, numberA },
      translate,
      displayMode
    } = props;

    const numberB = total - numberA;

    const numberLineMinMax = total > 15 ? [5, 20] : [0, 15];

    const random = seededRandom(props.question);

    const sentenceNumberOrder =
      total > 15
        ? [Math.max(numberB, numberA), Math.min(numberB, numberA)]
        : shuffle([numberA, numberB], { random });

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        actionPanelVariant="bottomTall"
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={`${sentenceNumberOrder[0].toLocaleString()} ${ADD} ${sentenceNumberOrder[1].toLocaleString()} = <ans/>`}
        testCorrect={[total.toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={range(numberLineMinMax[0], numberLineMinMax[1])}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 32 : 50}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcz',
  description: 'bcz',
  keywords: ['Add', 'Bar model'],
  schema: z
    .object({
      numberA: z.number().int().min(2).max(17),
      numberB: z.number().int().min(3).max(18),
      wholeNumberLeftOrRight: z.enum(['left', 'right'])
    })
    .refine(
      val => val.numberA + val.numberB >= 10 && val.numberA + val.numberB <= 20,
      'total must be at least 10, and no greater than 20'
    ),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(2, 17);

    const numberB = randomIntegerInclusive(3, 17, {
      constraint: x =>
        // For the sake of readability, just using the following constraints rather than complicating the min/max of numberB:
        x + numberA <= 20 &&
        x + numberA >= 10 &&
        // Both numbers cannot be 10:
        (numberA === 10 ? x !== 10 : true)
    });

    const wholeNumberLeftOrRight = getRandomFromArray(['left', 'right'] as const);

    return { numberA, numberB, wholeNumberLeftOrRight };
  },
  Component: props => {
    const {
      question: { numberA, numberB, wholeNumberLeftOrRight },
      translate,
      displayMode
    } = props;

    const total = numberA + numberB;

    const [splitNumber, wholeNumber] = [numberA, numberB].sort((a, b) => a - b);

    const random = seededRandom(props.question);

    const [splitColor, wholeColor] = getRandomSubArrayFromArray(barModelColorsArray, 2, {
      random
    });

    const splitColorArray = filledArray(
      barModelColors[splitColor as BarModelColorsKey],
      splitNumber
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={
          wholeNumberLeftOrRight === 'left'
            ? `${wholeNumber.toLocaleString()} ${ADD} ${splitNumber.toLocaleString()} = <ans/>`
            : `${splitNumber.toLocaleString()} ${ADD} ${wholeNumber.toLocaleString()} = <ans/>`
        }
        testCorrect={[total.toString()]}
        Content={({ dimens }) => (
          <BarModel
            dimens={dimens}
            total={total}
            numbers={
              wholeNumberLeftOrRight === 'left'
                ? [[wholeNumber, ...filledArray(1, splitNumber)]]
                : [[...filledArray(1, splitNumber), wholeNumber]]
            }
            strings={
              wholeNumberLeftOrRight === 'left'
                ? [[wholeNumber.toLocaleString(), ...filledArray('', splitNumber)]]
                : [[...filledArray('', splitNumber), wholeNumber.toLocaleString()]]
            }
            cellColors={
              wholeNumberLeftOrRight === 'left'
                ? [[barModelColors[wholeColor as BarModelColorsKey], ...splitColorArray]]
                : [[...splitColorArray, barModelColors[wholeColor as BarModelColorsKey]]]
            }
            maxFontSize={displayMode === 'digital' ? 32 : 50}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddByCountingOnWithin20',
  questionTypes: [Question2, Question3],
  unpublishedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
