import { filledArray } from 'common/src/utils/collections';
import {
  BarModelInteractiveWithState,
  shadedAtLeastOneCell
} from 'common/src/components/question/representations/BarModelInteractive';
import { trueCount } from 'common/src/utils/shapes';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import DragAndDropSection from 'common/src/components/molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from 'common/src/components/draganddrop/EasyDragAndDropWithSingleZones';
import Text from 'common/src/components/typography/Text';
import { parseMarkup } from 'common/src/markup';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { DraggableVariant } from '../../draganddrop/utils';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { MeasureView } from '../../atoms/MeasureView';
import { isEqual } from '../../../utils/matchers';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { useI18nContext } from '../../../i18n/i18n-react';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: JSX.Element | string; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  bars: { rows: number; cols: number; label?: string | JSX.Element }[];
  /**
   * If absent, bar model is always marked as complete and correct.
   * If present, at least one cell must be shaded to be complete, and this is used to test correctness.
   */
  testCorrectBarModel?: number[];
  sentence: string;
  items: ItemInfo<T>[];
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  actionPanelVariant?: 'end' | 'endWide' | 'bottom';
  /**
   * Text to display before each bar model. Optional prop.
   */
  preBarText?: string[];
  testCorrectSentence: T[];
  pdfItemVariant?: DraggableVariant;
  /** PDF Question Height */
  questionHeight?: number;
  /** Whether to hide draggables in the pdf and markscheme.
   * Default: false
   * */
  hideItemsPdf?: boolean;
};

export default function QF34InteractiveBarModelWithSentenceDrag<T>({
  title,
  pdfTitle,
  bars,
  testCorrectBarModel,
  sentence,
  items,
  pdfItemVariant = 'pdfSquare',
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  actionPanelVariant = 'endWide',
  preBarText = [],
  testCorrectSentence,
  questionHeight,
  hideItemsPdf = false,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctItemOrder = testCorrectSentence.map(ans => {
      const [{ value }] = items.filter(it => it.value === ans);
      return value;
    });

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant}
        moveOrCopy="copy"
        defaultState={displayMode === 'markscheme' ? correctItemOrder : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              <DragAndDropSectionPDF
                style={{ flex: 0.2 }}
                itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap' }}
              >
                {!hideItemsPdf &&
                  items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
                  ))}
              </DragAndDropSectionPDF>
              <MeasureView>
                {dimens => (
                  <View style={{ alignItems: 'center', rowGap: 64 }}>
                    {bars.map((bar, idx) => {
                      return (
                        <View key={idx} style={{ flexDirection: 'row', alignItems: 'center' }}>
                          {bar.label && (
                            <Text
                              style={{
                                alignSelf: 'center',
                                fontSize: 18,
                                paddingRight: 20,
                                position: 'relative',
                                top: -7.5
                              }}
                            >
                              {bar.label}
                            </Text>
                          )}
                          <BarModelInteractiveWithState
                            id={`barmodel-${idx}`}
                            numberOfRows={bar.rows}
                            numberOfCols={bar.cols}
                            fractionContainerStyle={fractionContainerStyle}
                            fractionDividerStyle={[{ marginVertical: 0 }, fractionDividerStyle]}
                            fractionTextStyle={fractionTextStyle}
                            preBarText={preBarText[idx]}
                            tableHeight={Math.min(dimens.height / bars.length, 100)}
                            tableWidth={
                              // Account for label to the left of the bar model
                              bar.label !== undefined || preBarText !== undefined
                                ? dimens.width - 128
                                : dimens.width
                            }
                          />
                        </View>
                      );
                    })}

                    <TextStructure
                      sentence={sentence}
                      inputBox={({ index }) => (
                        <>
                          {displayMode === 'markscheme' && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                            />
                          )}
                          <EasyDragAndDropWithSingleZones.ZoneSingle key={index} id={index} />
                        </>
                      )}
                    />
                  </View>
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                renderMarkSchemeProp(translate.markScheme.barModelsDoNotNeedShading())}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="move"
      variant="square"
      defaultState={filledArray(undefined as T | undefined, parseMarkup(sentence).numberOfAns)}
      testComplete={state => state.every(it => it !== undefined)}
      // Since testCorrect only runs if testComplete succeeds, the state will never actually have any undefined values
      // at this point, making this cast safe.
      testCorrect={isEqual(testCorrectSentence)}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection>
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View style={{ alignItems: 'center', rowGap: 16 }}>
                {bars.map((bar, idx) => {
                  return (
                    <View key={idx} style={{ flexDirection: 'row' }}>
                      {bar.label && (
                        <Text
                          style={{
                            alignSelf: 'center',
                            fontSize: 18,
                            paddingRight: 20,
                            position: 'relative',
                            top: -7.5
                          }}
                        >
                          {bar.label}
                        </Text>
                      )}
                      <BarModelInteractiveWithState
                        id={`barmodel-${idx}`}
                        testComplete={
                          testCorrectBarModel === undefined ? undefined : shadedAtLeastOneCell
                        }
                        testCorrect={
                          testCorrectBarModel === undefined
                            ? undefined
                            : answer => {
                                // Check that this bar model has the right number of cells shaded.
                                const shadedCells = trueCount(answer);
                                const correctShadedCells = testCorrectBarModel[idx];
                                return shadedCells === correctShadedCells;
                              }
                        }
                        numberOfRows={bar.rows}
                        numberOfCols={bar.cols}
                        fractionContainerStyle={fractionContainerStyle}
                        fractionDividerStyle={[{ marginVertical: 0 }, fractionDividerStyle]}
                        fractionTextStyle={fractionTextStyle}
                        preBarText={preBarText[idx]}
                        tableHeight={Math.min(dimens.height / bars.length, 100)}
                        tableWidth={
                          // Account for label to the left of the bar model
                          bar.label !== undefined || preBarText !== undefined
                            ? dimens.width - 64
                            : dimens.width
                        }
                      />
                    </View>
                  );
                })}

                <TextStructure
                  sentence={sentence}
                  inputBox={({ index }) => (
                    <EasyDragAndDropWithSingleZones.ZoneSingle key={index} id={index} />
                  )}
                />
              </View>
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}
