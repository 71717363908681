// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MeasureInKilometresAndMetres from './1MeasureInKilometresAndMetres';
import EquivalentLengthsKilometresAndMetres from './2EquivalentLengthsKilometresAndMetres';
import PerimeterOnAGrid from './3PerimeterOnAGrid';
import PerimeterOfARectangle from './4PerimeterOfARectangle';
import PerimeterOfRectilinearShapes from './5PerimeterOfRectilinearShapes';
import FindMissingLengthsInRectilinearShapes from './6FindMissingLengthsInRectilinearShapes';
import CalculateThePerimeterOfRectilinearShapes from './7CalculateThePerimeterOfRectilinearShapes';
import PerimeterOfRegularPolygons from './8PerimeterOfRegularPolygons';
import PerimeterOfPolygons from './9PerimeterOfPolygons';

const Block = newBlockContent({
  block: 'LengthAndPerimeter',
  field: 'Measurement',
  weeks: [4, 5],
  smallSteps: [
    MeasureInKilometresAndMetres, // Step 1
    EquivalentLengthsKilometresAndMetres, // Step 2
    PerimeterOnAGrid, // Step 3
    PerimeterOfARectangle, // Step 4
    PerimeterOfRectilinearShapes, // Step 5
    FindMissingLengthsInRectilinearShapes, // Step 6
    CalculateThePerimeterOfRectilinearShapes, // Step 7
    PerimeterOfRegularPolygons, // Step 8
    PerimeterOfPolygons // Step 9
  ]
});
export default Block;
