import { Pressable, View } from 'react-native';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { Dimens } from '../../../theme/scaling';
import { countRange, filledArray } from '../../../utils/collections';
import {
  arrayOfObjectsWithCrossesColors,
  ArrayOfObjectsWithCrossesColors,
  colors
} from '../../../theme/colors';
import { SetState } from '../../../utils/react';
import { useCallback, useContext } from 'react';
import { withStateHOC } from '../../../stateTree';
import Svg, { Line } from 'react-native-svg';
import { DisplayMode } from '../../../contexts/displayMode';
type Props = {
  icon: {
    name: SvgName;
    width: number;
    height?: number;
  };
  /**
   * Amount of columns/boxes to be displayed
   * Should be at least 1 and no greater than 10
   */
  amountOfColumns: number;
  /**
   * Amount of icons to be displayed
   * Useful if you want some columns/boxes to be empty
   */
  amountOfIcons?: number;
  dimens: Dimens;
  /**
   * To be used if you want static columns/boxes crossed out by default
   */
  crossedOutIndices: boolean[];
  setCrossedOutIndices?: SetState<boolean[]>;
  /**
   * Change the colour of the columns/boxes
   */
  crossedOutColor?: ArrayOfObjectsWithCrossesColors;
};

/**
 * Representation to either display a static group of icons with crosses
 * Or to display an interactive group of icons which can be clicked/crossed out
 */
export const ArrayOfObjectsWithCrosses = ({
  icon,
  amountOfColumns,
  amountOfIcons = amountOfColumns,
  crossedOutIndices,
  setCrossedOutIndices,
  crossedOutColor,
  dimens
}: Props) => {
  const isInteractive = setCrossedOutIndices !== undefined;
  const displayMode = useContext(DisplayMode);
  const crossedOutColorStyle = crossedOutColor
    ? arrayOfObjectsWithCrossesColors[crossedOutColor]
    : colors.prussianBlue;

  if (amountOfColumns < 1 || amountOfColumns > 10)
    throw Error(
      `Amount of columns should be greater than 1 and less or equal to 10. Amount is currently ${amountOfColumns}.`
    );

  if (crossedOutIndices && crossedOutIndices.length > amountOfColumns)
    throw Error(
      `Amount of crossed cells should be equal to or less than amount of columns. Amount is currently ${crossedOutIndices.length}.`
    );

  if (amountOfIcons > amountOfColumns)
    throw Error(
      `Amount of icons should be less than or equal to ${amountOfColumns}. Amount is currently ${amountOfIcons}.`
    );

  const handleOnPress = useCallback(
    (colIdx: number) => {
      if (setCrossedOutIndices) {
        setCrossedOutIndices(prevIndices => {
          const newIndices = [...prevIndices];
          newIndices[colIdx] = !newIndices[colIdx];
          return newIndices;
        });
      }
    },
    [setCrossedOutIndices]
  );

  return (
    <View
      style={{
        borderBottomWidth: 3,
        borderRightWidth: 3,
        borderTopWidth: 3,
        borderColor: crossedOutColor
          ? arrayOfObjectsWithCrossesColors[crossedOutColor]
          : colors.prussianBlue,
        flexDirection: 'row',
        width: dimens.width
      }}
    >
      {countRange(amountOfColumns).map((_col, colIdx) => (
        <Pressable
          key={colIdx}
          onPress={() => {
            handleOnPress(colIdx);
          }}
          disabled={!isInteractive}
        >
          {crossedOutIndices && crossedOutIndices.length > 0 && crossedOutIndices[colIdx] && (
            <Svg
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                zIndex: 10
              }}
            >
              <Line x1="0" y1="100" x2="100" y2="0" strokeWidth="3" stroke={crossedOutColorStyle} />
              <Line x1="0" y1="0" x2="100" y2="100" strokeWidth="3" stroke={crossedOutColorStyle} />
            </Svg>
          )}
          <View
            style={{
              alignItems: 'center',
              borderLeftWidth: 3,
              borderColor: crossedOutColorStyle,
              display: 'flex',
              justifyContent: 'center',
              padding: 8,
              width: dimens.width / amountOfColumns,
              minHeight: displayMode === 'digital' ? 96 : 120
            }}
          >
            {colIdx < amountOfIcons && (
              <AssetSvg name={icon.name} width={icon.width} height={icon.height} />
            )}
          </View>
        </Pressable>
      ))}
    </View>
  );
};

export const ArrayOfObjectsWithCrossesWithState = withStateHOC(ArrayOfObjectsWithCrosses, {
  stateProp: 'crossedOutIndices',
  setStateProp: 'setCrossedOutIndices',
  defaults: props => ({
    defaultState: filledArray(false, props.amountOfColumns),
    testComplete: state => (state ? state.filter(col => col === true).length > 0 : true)
  })
});
