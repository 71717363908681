import { useContext } from 'react';
import { View } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import Text from 'common/src/components/typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { getActualDimens } from '../../../utils/getActualDimens';

type Props = {
  /**
   * @param labels label for the missing angle
   */
  label: string;
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  svgName: SvgName;
};
/**
 * This component renders a polygon with one labelled angle.
 */
export const LabelledOneAngle = (props: Props) => {
  const {
    label,
    dimens: { width, height },
    svgName
  } = props;

  const displayMode = useContext(DisplayMode);

  const { actualHeight, actualWidth } = getActualDimens(svgName, {
    width: width,
    height: height
  });

  const [topFactor, leftFactor] = ((): [number, number] => {
    switch (svgName) {
      case 'Other_shapes/Regular_decagon_equal_sides_one_angle':
        return [0.15, 0.68];
      case 'Other_shapes/Regular_heptagon_equal_sides_one_angle':
      case 'Other_shapes/Regular_nonagon_equal_sides_one_angle':
        return [0.65, 0.35];
      case 'Other_shapes/Regular_hexagon_equal_sides_one_angle':
        return [0.1, 0.33];
      case 'Other_shapes/Regular_octagon_equal_sides_one_angle':
        return [0.25, 0.18];
      case 'Other_shapes/Regular_pentagon_equal_sides_one_angle':
        return [0.6, 0.7];
      case 'Layered_shapes/Layered_compound_shapes1':
        return [0.02, 0.33];
      case 'Layered_shapes/Layered_compound_shapes2':
        return [0.6, 0.88];
      case 'Layered_shapes/Layered_compound_shapes3':
        return [0, 0.75];
      case 'Layered_shapes/Layered_compound_shapes4':
        return [0.6, 0.72];
      case 'Layered_shapes/Layered_compound_shapes5':
        return [0, 0.36];
      default:
        throw new Error('Unreachable');
    }
  })();

  return (
    <View style={{ flexDirection: 'row' }}>
      <AssetSvg name={svgName} width={actualWidth} height={actualHeight} />
      <Text
        variant="WRN400"
        style={{
          position: 'absolute',
          left: actualWidth * leftFactor,
          top: actualHeight * topFactor,
          fontSize: displayMode === 'digital' ? 28 : 40
        }}
      >
        {label}
      </Text>
    </View>
  );
};
