import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import TenFrames from 'common/src/components/question/representations/TenFrame/TenFrameLegacy';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { countRange, range, sortNumberArray } from 'common/src/utils/collections';
import { View } from 'react-native';
import { AssetSvg } from 'common/src/assets/svg';
import QF14aCompleteNumberTrackDraggable from 'common/src/components/question/questionFormats/QF14aCompleteNumberTrackDraggable';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomUniqueNames, nameSchema } from 'common/src/utils/names';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNy',
  description: 'aNy',
  keywords: ['Fraction', 'Tenths', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(1).max(9),
    randomIndexes: z.array(z.number().int().min(1).max(9))
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const randomIndexes = randomUniqueIntegersInclusive(1, 9, number);

    return { number, randomIndexes };
  },
  Component: props => {
    const {
      question: { number, randomIndexes },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionOfTheBarModelIsShaded()}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={10}
            coloredSections={randomIndexes}
            width={dimens.width}
          />
        )}
        pdfDirection="column"
        sentence={'<frac nAns="" d="10" />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNz',
  description: 'aNz',
  keywords: ['Fraction', 'Tenths', 'Ten frame'],
  schema: z.object({
    number: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.aFullTenFrameRepresentsOneWholeWhatFractionIsShown()}
        Content={<TenFrames numberOfCounters={number} placeValue={'counter'} />}
        sentence={'<frac nAns="" d="10" />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNA',
  description: 'aNA',
  keywords: ['Fraction', 'Tenths', 'Place value counters'],
  schema: z.object({
    number: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionIsShown()}
        Content={
          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: 8 }}>
            {countRange(number).map((_number, i) => (
              <AssetSvg key={i} name={'Place_value/1-10'} width={70} height={70} />
            ))}
          </View>
        }
        sentence={'<frac nAns="" d="10" />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNB',
  description: 'aNB',
  keywords: ['Fractions', 'Tenths'],
  schema: z.object({
    number: z.number().int().min(4).max(10),
    items: z.array(z.number().int()).min(1).max(11),
    tenthsMultiple: z.boolean()
  }),
  simpleGenerator: () => {
    const tenthsMultiple = getRandomBoolean();
    const number = randomIntegerInclusive(4, tenthsMultiple ? 10 : 6);

    const items = tenthsMultiple ? range(0, number) : range(1, number);

    return { number, items, tenthsMultiple };
  },
  Component: props => {
    const {
      question: { items, tenthsMultiple, number },
      translate,
      displayMode
    } = props;

    const updatedItems = items.map((value, index) => {
      if (index === 0) {
        return '0';
      } else if (index === 1) {
        return `<frac n="${value}" d="10" />`;
      }
      return '<ans/>';
    });

    // All potential answers for both single/double digits
    const tenthsByOne = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const tenthsByTwo = [4, 6, 8, 10];

    const fractionItems = tenthsMultiple ? tenthsByOne : tenthsByTwo;
    const shuffledFractionItems = shuffle(fractionItems, { random: seededRandom(props.question) });

    const correctOrder = fractionItems.filter(item =>
      tenthsMultiple ? item <= number : item < number * 2
    );

    return (
      <QF14aCompleteNumberTrackDraggable
        title={translate.instructions.completeNumberTrack()}
        testCorrect={correctOrder}
        boxValues={updatedItems}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        fractionDividerStyle={{ marginVertical: 2 }}
        items={shuffledFractionItems.map(number => ({
          value: number,
          component: (
            <TextStructure
              sentence={`<frac n='${number}' d='10'/>`}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        pdfItemVariant="pdfSquare"
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aNB2',
  description: 'aNB',
  keywords: ['Fractions', 'Tenths'],
  schema: z.object({
    number: z.number().int().min(4).max(9),
    items: z.array(z.number().int()).min(1).max(11),
    tenthsMultiple: z.boolean()
  }),
  simpleGenerator: () => {
    const tenthsMultiple = getRandomBoolean();
    const number = randomIntegerInclusive(4, tenthsMultiple ? 8 : 6);

    const items = tenthsMultiple ? range(0, number) : range(1, number);

    return { number, items, tenthsMultiple };
  },
  Component: props => {
    const {
      question: { items, tenthsMultiple, number },
      translate,
      displayMode
    } = props;

    const updatedItems = items.map((value, index) => {
      if (index === 0) {
        return '0';
      } else if (index === 1) {
        return `<frac n="${value}" d="10" />`;
      }
      return '<ans/>';
    });

    // All potential answers for both single/double digits
    const tenthsByOne = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const tenthsByTwo = [4, 6, 8, 10];

    const fractionItems = tenthsMultiple ? tenthsByOne : tenthsByTwo;
    const shuffledFractionItems = shuffle(fractionItems, { random: seededRandom(props.question) });

    const correctOrder = fractionItems.filter(item =>
      tenthsMultiple ? item <= number : item < number * 2
    );

    const sentence = updatedItems.join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragTheCardsToContinueTheSequence()}
        pdfTitle={translate.instructions.useTheCardsToContinueTheSequence()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={shuffledFractionItems.map(number => ({
          value: number,
          component: (
            <TextStructure
              sentence={`<frac n='${number}' d='10'/>`}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50
              }}
            />
          )
        }))}
        moveOrCopy="move"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="pdfSquare"
        testCorrect={correctOrder}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aNC',
  description: 'aNC',
  keywords: ['Fractions', 'Tenths'],
  schema: z.object({
    number: z.number().int().min(4).max(10),
    items: z.array(z.number().int()).min(1).max(11),
    tenthsMultiple: z.boolean()
  }),
  simpleGenerator: () => {
    const tenthsMultiple = getRandomBoolean();
    const number = randomIntegerInclusive(4, tenthsMultiple ? 10 : 6);

    const items = tenthsMultiple ? range(0, number) : range(1, number);

    return { number, items, tenthsMultiple };
  },
  Component: props => {
    const {
      question: { items, tenthsMultiple, number },
      translate,
      displayMode
    } = props;

    // Reversed items
    const updatedItems = items
      .map((value, index) => {
        if (index === 0) {
          return '0';
        } else if (index === 1) {
          return `<frac n="${value}" d="10" />`;
        }
        return '<ans/>';
      })
      .reverse();

    // All potential answers for both single/double digits
    const tenthsByOne = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const tenthsByTwo = [4, 6, 8, 10];

    const fractionItems = tenthsMultiple ? tenthsByOne : tenthsByTwo;
    const shuffledFractionItems = shuffle(fractionItems, { random: seededRandom(props.question) });

    // Descending order
    const correctOrder = sortNumberArray(
      fractionItems.filter(item => (tenthsMultiple ? item <= number : item < number * 2)),
      'descending'
    );

    return (
      <QF14aCompleteNumberTrackDraggable
        title={translate.instructions.completeNumberTrack()}
        testCorrect={correctOrder}
        boxValues={updatedItems}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        fractionDividerStyle={{ marginVertical: 2 }}
        pdfItemVariant="pdfSquare"
        items={shuffledFractionItems.map(number => ({
          value: number,
          component: (
            <TextStructure
              sentence={`<frac n='${number}' d='10'/>`}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aNC2',
  description: 'aNC',
  keywords: ['Fractions', 'Tenths'],
  schema: z.object({
    number: z.number().int().min(4).max(9),
    items: z.array(z.number().int()).min(1).max(11),
    tenthsMultiple: z.boolean()
  }),
  simpleGenerator: () => {
    const tenthsMultiple = getRandomBoolean();
    const number = randomIntegerInclusive(4, tenthsMultiple ? 8 : 6);

    const items = tenthsMultiple ? range(0, number) : range(1, number);

    return { number, items, tenthsMultiple };
  },
  Component: props => {
    const {
      question: { items, tenthsMultiple, number },
      translate,
      displayMode
    } = props;

    // Reversed items
    const updatedItems = items
      .map((value, index) => {
        if (index === 0) {
          return '0';
        } else if (index === 1) {
          return `<frac n="${value}" d="10" />`;
        }
        return '<ans/>';
      })
      .reverse();

    // All potential answers for both single/double digits
    const tenthsByOne = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const tenthsByTwo = [4, 6, 8, 10];

    const fractionItems = tenthsMultiple ? tenthsByOne : tenthsByTwo;
    const shuffledFractionItems = shuffle(fractionItems, { random: seededRandom(props.question) });

    // Descending order
    const correctOrder = sortNumberArray(
      fractionItems.filter(item => (tenthsMultiple ? item <= number : item < number * 2)),
      'descending'
    );

    const sentence = updatedItems.join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragTheCardsToContinueTheSequence()}
        pdfTitle={translate.instructions.useTheCardsToContinueTheSequence()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={shuffledFractionItems.map(number => ({
          value: number,
          component: (
            <TextStructure
              sentence={`<frac n='${number}' d='10'/>`}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50
              }}
            />
          )
        }))}
        moveOrCopy="move"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="pdfSquare"
        testCorrect={correctOrder}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aND',
  description: 'aND',
  keywords: ['Fractions', 'Tenths'],
  schema: z
    .object({
      strawsX: z.number().int().min(1).max(8),
      strawsY: z.number().int().min(1).max(8),
      nameA: nameSchema,
      nameB: nameSchema,
      nameC: nameSchema
    })
    .refine(val => val.strawsX + val.strawsY < 10, 'strawsX add strawsY must be less than 10'),
  simpleGenerator: () => {
    const strawsX = randomIntegerInclusive(1, 8);
    // Ensure combined straws is less than 10
    const strawsY = randomIntegerInclusive(1, 8, {
      constraint: x => x + strawsX < 10
    });

    const [nameA, nameB, nameC] = getRandomUniqueNames(3);

    return { strawsX, strawsY, nameA, nameB, nameC };
  },
  Component: ({ question: { strawsX, strawsY, nameA, nameB, nameC }, translate }) => {
    const ans = 10 - (strawsX + strawsY);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.characterHasABundleOfTenStrawsCharacterSaysThatThisBundleRepresentsOneWhole(
          {
            character: nameA
          }
        )}<br/>${translate.instructions.characterAGivesXStrawsToCharacterBAndYStrawsToCharacterCWhatFractionOfStrawsDoesCharacterAHaveLeft(
          {
            characterA: nameA,
            characterB: nameB,
            characterC: nameC,
            strawsX,
            strawsY
          }
        )}`}
        testCorrect={[ans.toString()]}
        sentence={'<frac nAns="" d="10" />'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TenthsAsFractions',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5],
  unpublishedQuestionTypes: []
});
export default SmallStep;
