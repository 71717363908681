import { z } from 'zod';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { range } from '../../../../utils/collections';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { compareFloats } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNQ',
  description: 'aNQ',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    startingNumber: z.number().min(1).max(8.9).step(0.1)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(10, 89) / 10;

    return { startingNumber };
  },
  Component: props => {
    const {
      question: { startingNumber },
      translate
    } = props;
    const tickInterval = 0.1;
    const endNumber = startingNumber + 0.5;

    const numbers = [
      number(math.evaluate(`${startingNumber} + 0.3`)),
      number(math.evaluate(`${startingNumber} + 0.4`)),
      number(math.evaluate(`${startingNumber} + 0.5`))
    ];

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number.toLocaleString();
    });

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], numbers[0]) &&
          compareFloats(userAnswer[1], numbers[1]) &&
          compareFloats(userAnswer[2], numbers[2])
        }
        inputMaxCharacters={3}
        tickValues={tickValues}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: numbers.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNR',
  description: 'aNR',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(14),
    answerBoxPositions: z.array(z.number().int().min(1).max(8))
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 14);
    const numberOfAnswers = randomIntegerInclusive(1, 3);
    const answerBoxPositions = randomUniqueIntegersInclusive(1, 8, numberOfAnswers).sort();

    return { startingNumber, answerBoxPositions };
  },
  Component: props => {
    const {
      question: { startingNumber, answerBoxPositions },
      translate
    } = props;
    const tickInterval = 0.1;
    const endNumber = startingNumber + 1;
    const values = range(startingNumber, endNumber, tickInterval);
    const answers = answerBoxPositions.map(answerBoxPosition => values[answerBoxPosition]);

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(() => {
      return '';
    });

    // add start and end numbers
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[tickValues.length - 1] = endNumber.toLocaleString();

    // Set where the answers should go
    answerBoxPositions.forEach(answerBoxPosition => {
      tickValues[answerBoxPosition] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.fillInDecimalsOnNumberLine()}
        testCorrect={userAnswer =>
          answers.every((answers, i) => compareFloats(userAnswer[i], answers))
        }
        inputMaxCharacters={4}
        tickValues={tickValues}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNS',
  description: 'aNS',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    startingNumber: z.number().min(2).max(8.9).step(0.1),
    tickInterval: numberEnum([0.2, 0.3, 0.4, 0.5]),
    tickLabel: numberEnum([1, 2])
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(20, 89) / 10;
    const tickInterval = getRandomFromArray([0.2, 0.3, 0.4, 0.5] as const);
    const tickLabel = getRandomFromArray([1, 2] as const);

    return { startingNumber, tickInterval, tickLabel };
  },
  Component: props => {
    const {
      question: { startingNumber, tickInterval, tickLabel },
      translate
    } = props;
    const endNumber = startingNumber + tickInterval * 5;

    const answers = range(startingNumber, endNumber, tickInterval);
    // remove ticklabels
    answers.splice(tickLabel, 1);
    answers.splice(0, 1);

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number.toLocaleString();
    });

    // Set where the answers should go
    answers.forEach(answer => {
      tickValues[tickValues.indexOf(answer.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={userAnswer =>
          answers.every((answers, i) => compareFloats(userAnswer[i], answers))
        }
        inputMaxCharacters={3}
        tickValues={tickValues}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNT',
  description: 'aNT',
  keywords: ['Tenths', 'Decimals', 'Centimetres', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(1).max(6),
    length: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 6);
    const length = randomIntegerInclusive(1, 9);

    return { startingNumber, length };
  },
  Component: props => {
    const {
      question: { startingNumber, length },
      translate
    } = props;
    const tickInterval = 0.1;
    const endNumber = startingNumber + 1;
    const answer = number(math.evaluate(`${length} * ${tickInterval}`));

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number === startingNumber || number === endNumber
        ? `${number.toLocaleString()} ${translate.units.cm()}`
        : '';
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howLongIsTheArrow()}
        testCorrect={userAnswer => compareFloats(answer.toString(), userAnswer[0])}
        sentence={translate.answerSentences.theArrowIsAnsCmLong()}
        Content={({ dimens }) => (
          <NumberLine
            dimens={dimens}
            tickValues={tickValues}
            horizontalArrowLength={startingNumber + answer}
            firstNumber={startingNumber}
            lastNumber={endNumber}
          />
        )}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aNU',
  description: 'aNU',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(14),
    tickInterval: numberEnum([0.2, 0.4, 0.5]),
    answerBox: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 14);
    const tickInterval = getRandomFromArray([0.2, 0.4, 0.5] as const);
    const answerBox = randomIntegerInclusive(1, 2 / tickInterval - 2);

    return { startingNumber, tickInterval, answerBox };
  },
  Component: props => {
    const {
      question: { startingNumber, tickInterval, answerBox },
      translate
    } = props;
    const endNumber = startingNumber + 2;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(() => {
      return '';
    });

    // add start and end numbers
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[tickValues.length - 1] = endNumber.toLocaleString();

    // Set where the answers should go
    tickValues[answerBox] = '<ans/>';

    const answer = range(startingNumber, endNumber, tickInterval)[answerBox];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.fillInDecimalOnNumberLine()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        inputMaxCharacters={4}
        tickValues={tickValues}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aNV',
  description: 'aNV',
  keywords: ['Tenths', 'Decimals', 'Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(14),
    endNumber: z.number().int().min(2).max(19),
    numOfTicks: z.number().int().min(5).max(10),
    answerBox: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 14);
    const difference = randomIntegerInclusive(2, 5);
    const endNumber = startingNumber + difference;
    const numOfTicks = randomIntegerInclusive(5, 10, {
      constraint: x => (difference * 10) % x === 0
    });
    const answerBox = randomIntegerInclusive(1, numOfTicks - 1);

    return { startingNumber, endNumber, numOfTicks, answerBox };
  },
  Component: props => {
    const {
      question: { startingNumber, endNumber, numOfTicks, answerBox },
      translate
    } = props;

    const tickInterval = (endNumber - startingNumber) / numOfTicks;

    // Create array of empty strings
    const numberArray = range(startingNumber, endNumber, tickInterval).map(() => '');
    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    const answer = startingNumber + tickInterval * answerBox;

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowWhereXIsOnTheNumberLine(answer)}
        pdfTitle={translate.instructions.whereIsNumPdf(answer)}
        testCorrect={[answer - 0.2, answer + 0.2]}
        min={startingNumber}
        max={endNumber}
        sliderStep={0.1}
        tickValues={numberArray}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TenthsOnANumberLine',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
