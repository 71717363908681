import { getRandomFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';

export const arrayObjectNames = [
  'Green apple',
  'Red apple',
  'Banana',
  'Orange',
  'Pear',
  'Pineapple',
  'Strawberry'
] as const;

export const arrayObjectSchema = z.enum(arrayObjectNames);

export function getRandomArrayObject() {
  return getRandomFromArray(arrayObjectNames);
}

export type ArrayObjectName = (typeof arrayObjectNames)[number];

export const isArrayObject = (x: string): x is ArrayObjectName =>
  arrayObjectNames.includes(x as ArrayObjectName);

export const arrayObjectAsWord = (
  object: ArrayObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Green apple':
    case 'Red apple':
      return isPlural ? translate.objects.Apples() : translate.objects.Apple();
    case 'Banana':
      return isPlural ? translate.objects.Bananas() : translate.objects.Banana();
    case 'Orange':
      return isPlural ? translate.objects.Oranges() : translate.objects.Orange();
    case 'Pear':
      return isPlural ? translate.objects.Pears() : translate.objects.Pear();
    case 'Pineapple':
      return isPlural ? translate.objects.Pineapples() : translate.objects.Pineapple();
    case 'Strawberry':
      return isPlural ? translate.objects.Strawberries() : translate.objects.Strawberry();
  }
};
