import { ComponentType, useMemo } from 'react';
import { StateTreeLeafProps, StateTreeLeaf } from './StateTreeLeaf';

type AdjustedProps<
  Props extends Record<string, unknown>,
  StateProp extends string,
  SetStateProp extends string
> = Omit<Props, StateProp | SetStateProp> &
  Omit<StateTreeLeafProps<Required<Props>[StateProp]>, 'children'>;

type ThingOrFunction<T, Args> = T | ((args: Args) => T);

/**
 * Higher order component. Wrap a component whose props extend:
 *
 * ```ts
 * { state: T, setState: (newStateOrAction: T | ((old: T) => T)) => void }
 * ```
 *
 * with
 *
 * ```ts
 * export const MyComponentWithState = withStateHOC(MyComponent);
 * ```
 *
 * and it will change from expecting these props to expecting `id`, `defaultValue`, `testComplete` and  `testCorrect`
 * instead. The other props of the component will remain untouched.
 *
 * If your component's props instead extend:
 *
 * ```ts
 * { foo: T, setFoo: (newStateOrAction: T | ((old: T) => T)) => void }
 * ```
 *
 * then use
 *
 * ```ts
 * export const MyComponentWithState = withStateHOC(MyComponent, {stateProp: 'foo', setStateProp: 'setFoo'});
 * ```
 *
 * You can also provide values for `defaultState`, `testCorrect` and `testComplete` which will be used if these are
 * not specified when using your component, for example:
 *
 * ```tsx
 * export const MyComponentWithState = withStateHOC(MyComponent, {
 *   defaults: { defaultState: null, testComplete: x => x !== null }
 * });
 * ```
 */
export function withStateHOC<
  Props extends Record<string, unknown>,
  StateProp extends string = 'state',
  SetStateProp extends string = 'setState'
>(
  Component: ComponentType<Props>,
  options: {
    /** Optionally override the name of the state prop, for when your component's state prop isn't 'state'. */
    stateProp?: StateProp;
    /** Optionally override the name of the set state prop, for when your component's set state prop isn't 'setState'. */
    setStateProp?: SetStateProp;
    /** Optionally provide some defaults, so that you don't need to specify them every time. */
    defaults?: ThingOrFunction<
      {
        defaultState?: Props[StateProp];
        testCorrect?: (value: Props[StateProp]) => boolean;
        testComplete?: (value: Props[StateProp]) => boolean;
      },
      Omit<Props, StateProp | SetStateProp>
    >;
  } = {}
): (props: AdjustedProps<Props, StateProp, SetStateProp>) => JSX.Element | null {
  // Warning: the types get a bit absurd here, because we're doing a delicate prop manipulation.
  // What's important is that it is typed correctly when using it!
  type NewProps = AdjustedProps<Props, StateProp, SetStateProp>;
  type T = Required<Props>[StateProp];

  return ({ defaultState, testCorrect, testComplete, ...props }: NewProps) => {
    const stateProp = options.stateProp ?? 'state';
    const setStateProp = options.setStateProp ?? 'setState';
    const defaults = useMemo(
      () =>
        options.defaults !== undefined
          ? typeof options.defaults === 'function'
            ? options.defaults(props as unknown as Omit<Props, StateProp | SetStateProp>)
            : options.defaults
          : {},
      [props]
    );
    defaultState = defaultState ?? defaults.defaultState;
    testCorrect = testCorrect ?? defaults.testCorrect;
    testComplete = testComplete ?? defaults.testComplete;

    return (
      <StateTreeLeaf<T>
        defaultState={defaultState}
        testCorrect={testCorrect}
        testComplete={testComplete}
        {...props}
      >
        {({ state, setState }) => {
          const componentProps = {
            ...props,
            [stateProp]: state,
            [setStateProp]: setState
          } as unknown as Props;

          return <Component {...componentProps} />;
        }}
      </StateTreeLeaf>
    );
  };
}
