import { View, type ViewStyle, type StyleProp, Pressable, StyleSheet } from 'react-native';
import { type Dimens } from 'common/src/theme/scaling';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from 'common/src/utils/collections';
import {
  ElementOrRenderFunction,
  resolveElementOrRenderFunction,
  resolveThingOrFunction,
  SetState,
  ThingOrFunction
} from '../../../utils/react';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import { type ComponentProps, useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { useTheme } from '../../../theme';
import { AssetSvg } from '../../../assets/svg';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { withStateHOC } from '../../../stateTree';
import deepEqual from 'react-fast-compare';

type ItemInfo<T> = {
  component: JSX.Element;
  /** Must be unique amongst the items. */
  value: T;
};

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /** Content to fill area above selectables */
  Content: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;

  /**
   * If false, selecting one item deselects all others, so the user answer is a set of at most 1 value.
   * Default: false.
   */
  multiSelect?: boolean;

  /**
   * If true, selecting 0 items counts as a complete answer.
   * Default: false.
   */
  emptyIsValid?: boolean;

  /**
   * Initial selection state.
   * Note: orderis irrelevant. This must not contain duplicates (using `isEqual` to compare).
   * Default: none selected.
   */
  initialState?: T[];

  /** Choose which way to flex content & selectables, defaults to column */
  mainPanelFlexDirection?: 'row' | 'column';
  mainPanelContainer?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  /**
   * Styling to be applied to the outer container of the items.
   */
  itemsOuterContainerStyle?: StyleProp<ViewStyle>;
  /** Layout for the selectable items. Optional prop, defaults to 'grid'. */
  itemLayout?: 'grid' | 'column' | 'row' | 'rowTall';

  /** The number of items to show. */
  numItems: 2 | 3 | 4;

  /**
   * The items to show. Each item gets the same dimensions. The items will be rendered in order top-left, top-right,
   * bottom-left, bottom-right - with the bottom row reduced to one item in the case of 3 total items, or
   * nonexistent in the case of 2 total items.
   *
   * Length of this array must match `numItems`.
   */
  renderItems: ThingOrFunction<ItemInfo<T>[], [{ dimens: Dimens }]>;

  /** How to compare two items for equality. Defaults to deep equality AKA structural equality. */
  isEqual?: (x: T, y: T) => boolean;

  /** Either an array showing the correct answer, or a function if there are multiple correct answers. */
  testCorrect: T[] | ((ans: T[]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: T[]; answerText?: string };
};

/**
 * Question Format 11: Select Images (2, 3 or 4).
 *
 * Title at the top with content below or to the left side (depending on what `mainPanelFlexDirection` is set to).
 */
export default function QF11SelectImagesUpTo4WithContent<T>({
  title,
  pdfTitle,
  Content,
  testCorrect,
  numItems,
  renderItems,
  isEqual = deepEqual,
  multiSelect = false,
  emptyIsValid = false,
  initialState = [],
  mainPanelFlexDirection = 'column',
  mainPanelContainer,
  innerContainerStyle,
  contentContainerStyle,
  itemStyle,
  itemsOuterContainerStyle,
  itemLayout = 'grid',
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const styles = useQfStyles(mainPanelFlexDirection);

  // Throw error if initial state has duplicates
  if (!arrayHasNoDuplicates(initialState, isEqual)) {
    throw Error(`Initial state has duplicates: ${JSON.stringify(initialState)}`);
  }

  const SelectableContainerWithState = getSelectableContainerWithState<T>();

  const contentContainerProps = {
    numItems,
    renderItems,
    multiSelect,
    mainPanelFlexDirection,
    mainPanelContainer,
    innerContainerStyle,
    contentContainerStyle,
    itemStyle,
    itemsOuterContainerStyle,
    itemLayout,
    Content
  };

  const selectableContainerProps = {
    numItems,
    renderItems,
    multiSelect,
    mainPanelFlexDirection,
    innerContainerStyle,
    itemStyle,
    itemsOuterContainerStyle,
    itemLayout,
    isEqual
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrect !== 'function' && displayMode === 'markscheme'
        ? testCorrect
        : customMarkSchemeAnswer?.answerToDisplay;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        containerStyle={{ paddingLeft: 10 }}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => {
                const { rowWidth, selectableWidth, selectableHeight } = getDimensions(
                  displayMode,
                  dimens,
                  numItems,
                  mainPanelFlexDirection,
                  itemLayout
                );
                return (
                  <View style={[styles.mainPanel, mainPanelContainer]}>
                    <ContentContainer
                      dimens={dimens}
                      rowWidth={rowWidth}
                      selectableHeight={selectableHeight}
                      {...contentContainerProps}
                    />
                    <SelectableContainer
                      userAnswer={markSchemeAnswer ?? []}
                      rowWidth={rowWidth}
                      selectableWidth={selectableWidth}
                      selectableHeight={selectableHeight}
                      {...selectableContainerProps}
                    />
                  </View>
                );
              }}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => {
            const { rowWidth, selectableWidth, selectableHeight } = getDimensions(
              displayMode,
              dimens,
              numItems,
              mainPanelFlexDirection,
              itemLayout
            );
            return (
              <View style={[styles.mainPanel, mainPanelContainer]}>
                <ContentContainer
                  dimens={dimens}
                  rowWidth={rowWidth}
                  selectableHeight={selectableHeight}
                  {...contentContainerProps}
                />
                <SelectableContainerWithState
                  id="selection"
                  defaultState={initialState}
                  testCorrect={answer =>
                    typeof testCorrect === 'function'
                      ? testCorrect(answer)
                      : arraysHaveSameContentsUnordered(answer, testCorrect, isEqual)
                  }
                  testComplete={answer => emptyIsValid || answer.length > 0}
                  rowWidth={rowWidth}
                  selectableWidth={selectableWidth}
                  selectableHeight={selectableHeight}
                  {...selectableContainerProps}
                />
              </View>
            );
          }}
        </MeasureView>
      }
      {...props}
    />
  );
}

function ContentContainer({
  dimens,
  rowWidth,
  selectableHeight,
  Content,
  mainPanelFlexDirection,
  contentContainerStyle,
  itemLayout
}: {
  /** Dimensions of the content and the selectables combined */
  dimens: Dimens;
  rowWidth: number;
  selectableHeight: number;
  Content?: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;
  numItems: 2 | 3 | 4;
  mainPanelFlexDirection: 'row' | 'column';
  contentContainerStyle?: StyleProp<ViewStyle>;
  itemLayout: 'grid' | 'column' | 'row' | 'rowTall';
}) {
  const styles = useContentStyles(rowWidth, mainPanelFlexDirection);

  const content = resolveElementOrRenderFunction(Content, {
    dimens: {
      width: mainPanelFlexDirection === 'row' ? dimens.width / 2 : dimens.width,
      height:
        mainPanelFlexDirection === 'row'
          ? dimens.height
          : itemLayout === 'row'
          ? dimens.height - selectableHeight / 2
          : dimens.height / 2
    }
  });

  return <View style={[styles.contentContainer, contentContainerStyle]}>{content}</View>;
}

function SelectableContainer<T>({
  rowWidth,
  selectableWidth,
  selectableHeight,
  numItems,
  renderItems,
  isEqual,
  multiSelect,
  userAnswer,
  setUserAnswer,
  mainPanelFlexDirection,
  innerContainerStyle,
  itemStyle,
  itemsOuterContainerStyle,
  itemLayout
}: {
  rowWidth: number;
  selectableWidth: number;
  selectableHeight: number;
  numItems: 2 | 3 | 4;
  renderItems: ThingOrFunction<ItemInfo<T>[], [{ dimens: Dimens }]>;
  isEqual: (x: T, y: T) => boolean;
  multiSelect: boolean;
  userAnswer: T[];
  setUserAnswer?: SetState<T[]>;
  mainPanelFlexDirection: 'row' | 'column';
  innerContainerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  itemsOuterContainerStyle?: StyleProp<ViewStyle>;
  itemLayout: 'grid' | 'column' | 'row' | 'rowTall';
}) {
  const displayMode = useContext(DisplayMode);

  const styles = {
    ...useSelectableStyles(selectableWidth, selectableHeight),
    ...useContentStyles(rowWidth, mainPanelFlexDirection)
  };

  // Set shared props for selectables
  const selectableProps = {
    multiSelect,
    mainPanelFlexDirection,
    itemStyle,
    userAnswer,
    setUserAnswer,
    isEqual,
    rowWidth,
    selectableWidth,
    selectableHeight
  };

  const items = resolveThingOrFunction(renderItems, {
    dimens: { width: selectableWidth, height: selectableHeight }
  });

  return itemLayout === 'grid' ? (
    <View
      style={[
        { justifyContent: 'center', rowGap: displayMode === 'digital' ? 24 : 64 },
        itemsOuterContainerStyle
      ]}
    >
      <View style={[styles.row, innerContainerStyle]}>
        {items[0] !== undefined && <SelectableItem item={items[0]} {...selectableProps} />}
        {items[1] !== undefined && <SelectableItem item={items[1]} {...selectableProps} />}
      </View>

      {/* only show second row if there are more than 2 selectables */}
      {numItems === 3 && (
        <View style={[styles.row, styles.singleItemRow, innerContainerStyle]}>
          {items[2] !== undefined && <SelectableItem item={items[2]} {...selectableProps} />}
        </View>
      )}
      {numItems === 4 && (
        <View style={[styles.row, innerContainerStyle]}>
          {items[2] !== undefined && <SelectableItem item={items[2]} {...selectableProps} />}
          {items[3] !== undefined && <SelectableItem item={items[3]} {...selectableProps} />}
        </View>
      )}
    </View>
  ) : itemLayout === 'column' ? (
    <View style={[styles.column, { justifyContent: 'center' }, itemsOuterContainerStyle]}>
      {items[0] !== undefined && (
        <View style={[styles.columnLayoutRow, styles.singleItemRow, innerContainerStyle]}>
          <SelectableItem item={items[0]} {...selectableProps} />
        </View>
      )}
      {items[1] !== undefined && (
        <View style={[styles.columnLayoutRow, styles.singleItemRow, innerContainerStyle]}>
          <SelectableItem item={items[1]} {...selectableProps} />
        </View>
      )}
      {items[2] !== undefined && (
        <View style={[styles.columnLayoutRow, styles.singleItemRow, innerContainerStyle]}>
          <SelectableItem item={items[2]} {...selectableProps} />
        </View>
      )}
      {items[3] !== undefined && (
        <View style={[styles.columnLayoutRow, styles.singleItemRow, innerContainerStyle]}>
          <SelectableItem item={items[3]} {...selectableProps} />
        </View>
      )}
    </View>
  ) : (
    <View style={[{ justifyContent: 'center' }, itemsOuterContainerStyle]}>
      <View style={[styles.row, innerContainerStyle]}>
        {items[0] !== undefined && <SelectableItem item={items[0]} {...selectableProps} />}
        {items[1] !== undefined && <SelectableItem item={items[1]} {...selectableProps} />}
        {items[2] !== undefined && <SelectableItem item={items[2]} {...selectableProps} />}
        {items[3] !== undefined && <SelectableItem item={items[3]} {...selectableProps} />}
      </View>
    </View>
  );
}

type SelectableContainerWithState<T> = ReturnType<
  typeof withStateHOC<ComponentProps<typeof SelectableContainer<T>>, 'userAnswer', 'setUserAnswer'>
>;

const SelectableContainerWithStateNonGeneric = withStateHOC(SelectableContainer, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer'
});

/** Get the StateTree version of SelectableContainer. There's no need to use useMemo with this. */
function getSelectableContainerWithState<T>() {
  // This cast simply changes the generic parameter from unknown to T, which is safe.
  return SelectableContainerWithStateNonGeneric as SelectableContainerWithState<T>;
}

function SelectableItem<T>({
  item,
  multiSelect,
  itemStyle,
  userAnswer,
  setUserAnswer,
  isEqual,
  selectableWidth,
  selectableHeight
}: {
  item: ItemInfo<T>;
  multiSelect: boolean;
  itemStyle?: StyleProp<ViewStyle>;
  userAnswer: T[];
  setUserAnswer?: SetState<T[]>;
  isEqual: (x: T, y: T) => boolean;
  selectableWidth: number;
  selectableHeight: number;
}) {
  const displayMode = useContext(DisplayMode);
  const styles = useSelectableStyles(selectableWidth, selectableHeight / 2);

  const selected = userAnswer.some(answerValue => isEqual(answerValue, item.value));

  return (
    <Pressable
      style={[styles.selectable, selected && styles.selected, itemStyle]}
      onPress={() => {
        if (userAnswer && setUserAnswer) {
          if (userAnswer.some(answerValue => isEqual(answerValue, item.value))) {
            // Unselect this item
            setUserAnswer(userAnswer.filter(value => !isEqual(value, item.value)));
          } else {
            // Select this item
            multiSelect ? setUserAnswer([...userAnswer, item.value]) : setUserAnswer([item.value]);
          }
        }
      }}
    >
      {displayMode === 'markscheme' && userAnswer?.includes(item.value) && (
        <AssetSvg
          name={'True'}
          width={50}
          style={{ zIndex: 999, position: 'absolute', top: 15, right: 30 }}
        />
      )}
      {item.component}
    </Pressable>
  );
}

function getDimensions(
  displayMode: 'pdf' | 'markscheme' | 'digital',
  dimens: Dimens,
  numItems: 2 | 3 | 4,
  mainPanelFlexDirection: 'row' | 'column',
  itemLayout: 'grid' | 'column' | 'row' | 'rowTall'
) {
  const rowWidth = mainPanelFlexDirection === 'row' ? dimens.width / 2 : dimens.width;
  const selectableSpacing = displayMode === 'digital' ? 24 : 64;

  const itemContainerDimens = (numItems: 2 | 3 | 4, dimens: Dimens) => {
    // Account for gap of 24 between selectables
    const width =
      itemLayout === 'row' || itemLayout === 'rowTall'
        ? (dimens.width - 4 * selectableSpacing) / numItems
        : mainPanelFlexDirection === 'row' && itemLayout !== 'column'
        ? (dimens.width / 2 - 4 * selectableSpacing) / 2
        : (dimens.width - 4 * selectableSpacing) / 2;
    const height =
      itemLayout === 'rowTall'
        ? dimens.height
        : numItems === 2 && itemLayout !== 'row'
        ? dimens.height - 2 * selectableSpacing
        : (dimens.height - selectableSpacing) / 2;

    return { width, height } as const;
  };

  const { width: selectableWidth, height: selectableHeight } = itemContainerDimens(
    numItems,
    dimens
  );

  return { displayMode, rowWidth, selectableWidth, selectableHeight };
}

const useQfStyles = (mainPanelFlexDirection: 'row' | 'column') => {
  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: mainPanelFlexDirection,
          columnGap: mainPanelFlexDirection === 'row' ? 32 : 0
        }
      }),
    [mainPanelFlexDirection]
  );
};

const useContentStyles = (rowWidth: number, mainPanelFlexDirection: 'row' | 'column') => {
  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: mainPanelFlexDirection,
          columnGap: mainPanelFlexDirection === 'row' ? 32 : 0
        },
        column: {
          gap: 24
        },
        columnLayoutRow: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: rowWidth
        },
        row: {
          width: rowWidth,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 24
        },
        contentContainer: {
          alignItems: 'center',
          justifyContent: 'center'
        },
        singleItemRow: {
          justifyContent: 'center'
        }
      }),
    [mainPanelFlexDirection, rowWidth]
  );
};

const useSelectableStyles = (width: number, height: number) => {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        selectable: {
          width,
          height,
          borderColor: theme.colors.tertiary,
          borderWidth: displayMode === 'digital' ? theme.buttonBorderWidth : 0,
          borderRadius: 24,
          backgroundColor: theme.colors.background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        selected: {
          backgroundColor:
            displayMode === 'digital' ? theme.colors.tertiary : theme.colors.background,
          borderColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.pdfPrimary,
          borderWidth: displayMode === 'markscheme' ? theme.buttonBorderWidth : 0
        }
      }),
    [
      displayMode,
      height,
      theme.buttonBorderWidth,
      theme.colors.background,
      theme.colors.pdfPrimary,
      theme.colors.tertiary,
      width
    ]
  );
};
