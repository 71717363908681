import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { View } from 'react-native';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { foodMassAsWord, foodMassSchema, getRandomFoodMass } from 'common/src/utils/food';
import Text from 'common/src/components/typography/Text';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { create, all, number } from 'mathjs';
import { compareFloats } from 'common/src/utils/math';
import {
  childItemAsWord,
  childItemSchema,
  getRandomChildItem,
  getRandomLengthItem,
  getRandomUniquePackagedItemNames,
  lengthItemAsWord,
  lengthItemSchema,
  packagedItemSchema
} from 'common/src/utils/items';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { numToCurrency } from 'common/src/utils/money';
import { getRandomShape, shapeData, shapesAsWord, shapesSchema } from 'common/src/utils/shapes';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { fruitAsWord, fruitsSchema, getRandomFruit } from 'common/src/utils/fruits';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { colors } from 'common/src/theme/colors';
import { filledArray } from 'common/src/utils/collections';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { useContext } from 'react';
import { DisplayMode } from 'common/src/contexts/displayMode';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lowercase } from 'typesafe-i18n/formatters';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVu',
  description: 'aVu',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Division',
    'Divide',
    'Mass',
    'Kilograms',
    'kg'
  ],
  schema: z.object({
    foodMassItem: foodMassSchema,
    massA: z.number().min(1).max(9),
    massB: z.number().min(2).max(12)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const foodMassItem = getRandomFoodMass();
    const massA = randomIntegerInclusive(1, 9);
    const massB = randomIntegerInclusive(2, 12);

    return { foodMassItem, massA, massB };
  },

  Component: props => {
    const {
      question: { foodMassItem, massA, massB },
      translate
    } = props;

    // Food mass
    const foodMassAItemTranslated = foodMassAsWord(foodMassItem, translate, 1);
    const foodMassBItemTranslated = foodMassAsWord(foodMassItem, translate, massB);

    const massADecimal = number(math.evaluate(`${massA} / 10`));

    // Answers
    const answer = number(math.evaluate(`${massADecimal} * ${massB}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aXItemHasAMassOfYKgWhatIsMassOfZOfTheXItemPlural(
          foodMassAItemTranslated,
          massADecimal.toLocaleString(),
          massB.toLocaleString(),
          foodMassBItemTranslated
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        inputMaxCharacters={4}
        sentence={`<ans/> ${translate.keywords.kg()}`}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={500}
        extraSymbol="decimalPoint"
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVv',
  description: 'aVv',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Division',
    'Divide',
    'Length',
    'Metres',
    'm'
  ],
  schema: z.object({
    lengthItem: lengthItemSchema,
    length: z.number().min(4).max(14),
    lengthMultiple: z.number().min(2).max(12)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const lengthItem = getRandomLengthItem();
    const length = randomIntegerInclusive(4, 14, {
      constraint: x => x % 10 !== 0
    });
    const lengthMultiple = randomIntegerInclusive(2, 12);

    return { lengthItem, length, lengthMultiple };
  },

  Component: props => {
    const {
      question: { lengthItem, length, lengthMultiple },
      translate
    } = props;

    // Lengths
    const lengthItemATranslated = lengthItemAsWord(lengthItem, translate, 1);
    const lengthItemBTranslated = lengthItemAsWord(lengthItem, translate, lengthMultiple);
    const lengthDecimal = number(math.evaluate(`${length} / 10`));

    // Answer
    const answer = number(math.evaluate(`${lengthDecimal} * ${lengthMultiple}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.theLengthOfAXItemIsYLengthWhatIsTheLengthOfZOfTheseXItemPlural(
          lengthItemATranslated,
          lengthDecimal.toLocaleString(),
          lengthMultiple.toLocaleString(),
          lengthItemBTranslated
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        inputMaxCharacters={4}
        sentence={`<ans/> ${translate.keywords.m()}`}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={500}
        extraSymbol="decimalPoint"
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aVw',
  description: 'aVw',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Division',
    'Divide',
    'Pounds',
    'Pence',
    'Cost',
    'Money'
  ],
  schema: z.object({
    childItem: childItemSchema,
    pounds: z.number().min(101).max(990),
    poundsMultiple: z.number().min(2).max(12),
    variations: z.array(z.number().int().min(1).max(9)).length(5)
  }),
  simpleGenerator: () => {
    const childItem = getRandomChildItem();
    const pounds = randomIntegerInclusive(101, 990, {
      constraint: x => x % 10 !== 0
    });
    const poundsMultiple = randomIntegerInclusive(2, 12);
    const variations = randomUniqueIntegersInclusive(1, 9, 5);

    return { childItem, pounds, poundsMultiple, variations };
  },

  Component: props => {
    const {
      question: { childItem, pounds, poundsMultiple, variations },
      translate
    } = props;

    // Convert to decimal
    const poundsDecimal = number(math.evaluate(`${pounds} / 100`));

    // Variations for incorrect answers
    const [variationA, variationB, variationC, variationD, variationE] = variations.map(variation =>
      number(math.evaluate(`${variation} / 10`))
    );

    const childItemATranslated = childItemAsWord(childItem, translate, 1);
    const childItemBTranslated = childItemAsWord(childItem, translate, poundsMultiple);

    const answer = poundsDecimal * poundsMultiple;

    // Numbers
    const number4 = answer + variationA;
    const number5 = answer + variationB;
    const number6 = answer - variationC;
    const number7 = answer - variationD;
    const number8 = poundsDecimal + poundsMultiple;
    const number9 = poundsDecimal + poundsMultiple - variationE;

    // Incorrect answers
    const incorrectAnswers = shuffle([number4, number5, number6, number7, number8, number9], {
      random: seededRandom(props.question)
    });

    // Correct answer
    const correct = [`${numToCurrency({ amount: answer })}`];

    // Options
    const options = shuffle(
      [
        `${numToCurrency({ amount: answer })}`,
        `${numToCurrency({ amount: incorrectAnswers[0] })}`,
        `${numToCurrency({ amount: incorrectAnswers[1] })}`,
        `${numToCurrency({ amount: incorrectAnswers[2] })}`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.aXItemCostsYPoundsHowMuchDoZOfTheseXItemPluralCost(
          childItemATranslated,
          numToCurrency({ amount: poundsDecimal }),
          poundsMultiple.toLocaleString(),
          childItemBTranslated
        )}
        testCorrect={correct}
        items={options.map(opt => ({
          value: opt,
          component: opt
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aVx',
  description: 'aVx',
  keywords: [
    'Decimal',
    'Integer',
    'Division',
    'Divide',
    'Metres',
    'm',
    'Regular 2-D shape',
    'Triangle',
    'Square',
    'Pentagon',
    'Hexagon',
    'Heptagon',
    'Octagon',
    'Nonagon',
    'Decagon'
  ],
  schema: z.object({
    shapeItem: shapesSchema,
    correctAnswer: z.number().int().min(1).max(99),
    incorrectAnswers: z.array(z.number().int().min(2).max(9)).length(3)
  }),
  simpleGenerator: () => {
    const shapeItem = getRandomShape();
    const correctAnswer = randomIntegerInclusive(1, 99, {
      constraint: x => x % 10 !== 0
    });
    const incorrectAnswers = randomUniqueIntegersInclusive(2, 9, 3, {
      constraint: x => x !== correctAnswer
    });

    return { shapeItem, correctAnswer, incorrectAnswers };
  },

  Component: props => {
    const {
      question: { shapeItem, correctAnswer, incorrectAnswers },
      translate
    } = props;

    // Get translated shape word from shapeItem
    const shapeItemTranslated = shapesAsWord(shapeItem, translate, 1);

    // Get shapeItem data including shape sides length
    const shape = shapeData(translate, 1).filter(food => food.name === shapeItemTranslated)[0];

    const perimeterInMetres = number(math.evaluate(`${shape.sides} * (${correctAnswer} / 100)`));

    // Incorrect answers
    const incorrect = shuffle(
      [...incorrectAnswers.map(incorrect => number(math.evaluate(`${incorrect} / 10`)))],
      {
        random: seededRandom(props.question)
      }
    );

    // Correct answer
    const correct = [
      `${number(math.evaluate(`${perimeterInMetres} / ${shape.sides}`))} ${translate.keywords.m()}`
    ];

    const options = shuffle(
      [
        `${correct.toLocaleString()}`,
        `${incorrect[0].toLocaleString()} ${translate.keywords.m()}`,
        `${incorrect[1].toLocaleString()} ${translate.keywords.m()}`,
        `${incorrect[2].toLocaleString()} ${translate.keywords.m()}`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.thePerimeterOfXItemIsYMetresWhatIsTheLengthOfEachSide(
          shape.name,
          perimeterInMetres.toLocaleString()
        )}
        testCorrect={correct}
        items={options.map(opt => ({
          value: opt,
          component: opt
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVy',
  description: 'aVy',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Mass',
    'Kilograms',
    'kg'
  ],
  schema: z.object({
    kilograms: z.number().int().min(11).max(59),
    mass: z.number().int().min(2).max(9),
    incorrectVariations: z.array(z.number().int().min(2).max(8)).length(6),
    fruitItem: fruitsSchema
  }),
  simpleGenerator: () => {
    const kilograms = randomIntegerInclusive(11, 59, {
      constraint: x => x % 10 !== 0
    });
    const mass = randomIntegerInclusive(2, 9);
    const incorrectVariations = randomUniqueIntegersInclusive(2, 8, 6);
    const fruitItem = getRandomFruit();

    return {
      kilograms,
      mass,
      incorrectVariations,
      fruitItem
    };
  },
  Component: props => {
    const {
      question: { kilograms, mass, incorrectVariations, fruitItem },
      translate,
      displayMode
    } = props;

    const fruitPlural = fruitAsWord(fruitItem, translate, true);

    // Convert to decimals
    const kilogramsDecimal = number(math.evaluate(`${kilograms} / 100`));

    const number3 = mass + 1;
    const number4 = number(math.evaluate(`${number3} + 1`));

    // Correct answers
    const number5 = kilogramsDecimal * mass;
    const number6 = kilogramsDecimal * number3;
    const number7 = kilogramsDecimal * number4;

    // Incorrect answers
    const [
      incorrectVariationA,
      incorrectVariationB,
      incorrectVariationC,
      incorrectVariationD,
      incorrectVariationE,
      incorrectVariationF
    ] = incorrectVariations.map(incorrectVariation =>
      number(math.evaluate(`${incorrectVariation} / 100`))
    );

    const incorrectA = number5 + incorrectVariationA;
    const incorrectB = number5 - incorrectVariationB;

    const incorrectC = number6 + incorrectVariationC;
    const incorrectD = number6 - incorrectVariationD;

    const incorrectE = number7 + incorrectVariationE;
    const incorrectF = number7 - incorrectVariationF;

    const shuffledIncorrectAnswers = shuffle(
      [incorrectA, incorrectB, incorrectC, incorrectD, incorrectE, incorrectF],
      {
        random: seededRandom(props.question)
      }
    );

    const statements = [
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              width: displayMode === 'digital' ? 350 : 500,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {translate.answerSentences.massOfXMassYItem(mass, fruitPlural)}
          </Text>
        ),
        correctAnswer: number5
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              width: displayMode === 'digital' ? 350 : 500,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {translate.answerSentences.massOfXMassYItem(number3, fruitPlural)}
          </Text>
        ),
        correctAnswer: number6
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              width: displayMode === 'digital' ? 350 : 500,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {translate.answerSentences.massOfXMassYItem(number4, fruitPlural)}
          </Text>
        ),
        correctAnswer: number7
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMatchMassesOneXItemHasMassOfYKg(
          fruitItem,
          kilogramsDecimal.toLocaleString()
        )}
        pdfTitle={translate.instructions.useCardsMatchMassesOneXItemHasMassOfYKg(
          fruitItem,
          kilogramsDecimal.toLocaleString()
        )}
        items={[
          ...items,
          shuffledIncorrectAnswers[0],
          shuffledIncorrectAnswers[1],
          shuffledIncorrectAnswers[2]
        ]}
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        pdfLayout="itemsBottom"
        useArrows={false}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5v2 = newQuestionContent({
  uid: 'aVy2',
  description: 'aVy',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Mass',
    'Kilograms',
    'kg'
  ],
  schema: z.object({
    kilograms: z.number().int().min(11).max(59),
    mass: z.number().int().min(2).max(9),
    incorrectVariations: z.array(z.number().int().min(2).max(8)).length(6),
    fruitItem: fruitsSchema
  }),
  simpleGenerator: () => {
    const kilograms = randomIntegerInclusive(11, 59, {
      constraint: x => x % 10 !== 0
    });
    const mass = randomIntegerInclusive(2, 9);
    const incorrectVariations = randomUniqueIntegersInclusive(2, 8, 6);
    const fruitItem = getRandomFruit();

    return {
      kilograms,
      mass,
      incorrectVariations,
      fruitItem
    };
  },
  Component: props => {
    const {
      question: { kilograms, mass, incorrectVariations, fruitItem },
      translate
    } = props;

    const fruitPlural = fruitAsWord(fruitItem, translate, true);

    // Convert to decimals
    const kilogramsDecimal = number(math.evaluate(`${kilograms} / 100`));

    const number3 = mass + 1;
    const number4 = number(math.evaluate(`${number3} + 1`));

    // Correct answers
    const number5 = kilogramsDecimal * mass;
    const number6 = kilogramsDecimal * number3;
    const number7 = kilogramsDecimal * number4;

    // Incorrect answers
    const [
      incorrectVariationA,
      incorrectVariationB,
      incorrectVariationC,
      incorrectVariationD,
      incorrectVariationE,
      incorrectVariationF
    ] = incorrectVariations.map(incorrectVariation =>
      number(math.evaluate(`${incorrectVariation} / 100`))
    );

    const incorrectA = number5 + incorrectVariationA;
    const incorrectB = number5 - incorrectVariationB;

    const incorrectC = number6 + incorrectVariationC;
    const incorrectD = number6 - incorrectVariationD;

    const incorrectE = number7 + incorrectVariationE;
    const incorrectF = number7 - incorrectVariationF;

    const shuffledIncorrectAnswers = shuffle(
      [incorrectA, incorrectB, incorrectC, incorrectD, incorrectE, incorrectF],
      {
        random: seededRandom(props.question)
      }
    );

    const statements = [
      {
        string: translate.answerSentences.massOfXMassYItemEqualsAnsKg(mass, fruitPlural),
        correctAnswer: number5
      },
      {
        string: translate.answerSentences.massOfXMassYItemEqualsAnsKg(number3, fruitPlural),
        correctAnswer: number6
      },
      {
        string: translate.answerSentences.massOfXMassYItemEqualsAnsKg(number4, fruitPlural),
        correctAnswer: number7
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchMassesOneXItemHasMassOfYKg(
          fruitItem,
          kilogramsDecimal.toLocaleString()
        )}
        pdfTitle={translate.instructions.useCardsMatchMassesOneXItemHasMassOfYKg(
          fruitItem,
          kilogramsDecimal.toLocaleString()
        )}
        items={[
          ...items,
          shuffledIncorrectAnswers[0],
          shuffledIncorrectAnswers[1],
          shuffledIncorrectAnswers[2]
        ]}
        itemVariant="square"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        // sentenceStyle={{ alignSelf: 'flex-end' }}
        // sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={shuffledStatements.map(val => val.string)}
        testCorrect={shuffledStatements.map(val => [val.correctAnswer])}
        pdfLayout="itemsRight"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aVz',
  description: 'aVz',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Division',
    'Divide',
    'Bar model',
    'Pounds',
    'Pence',
    'Cost',
    'Money'
  ],
  schema: z.object({
    number1: z.number().int().min(101).max(599),
    packagedItems: z.array(packagedItemSchema),
    multiple: z.number().int().min(2).max(6),
    random: z.boolean()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 599, {
      constraint: x => x % 10 !== 0
    });
    const packagedItems = getRandomUniquePackagedItemNames(2);
    const multiple = randomIntegerInclusive(2, 6);
    const random = getRandomBoolean();

    return { number1, packagedItems, multiple, random };
  },
  Component: props => {
    const {
      question: { number1, packagedItems, multiple, random },
      translate
    } = props;

    const [packagedItemA, packagedItemB] = packagedItems;

    // Convert to decimals
    const number1Decimal = number(math.evaluate(`${number1} / 100`));

    const number3 = number(math.evaluate(`${number1Decimal} * ${multiple}`));
    const pounds = numToCurrency({ amount: number3 + number1Decimal });

    // Answer
    const answer = number(math.evaluate(`${number3} - ${number1Decimal}`));

    // Bar model
    const barModelTotals = 100 * multiple;
    const item2BarModalLength = barModelTotals / multiple;
    const item2ArrowLength = (barModelTotals * (multiple - 1)) / multiple;

    const displayMode = useContext(DisplayMode);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.aXOfItemACostsYTimesAsMuchAsBoxOrBagOfItemBTogetherTheyCostZ(
          random ? translate.objects.Box() : translate.objects.Bag(),
          packagedItemA,
          multiple,
          packagedItemB,
          pounds
        )}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        sentence={'£<ans/>'}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        Content={({ dimens }) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}
            >
              <View style={{ marginRight: 20 }}>
                <View
                  style={{
                    height:
                      (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2,
                    justifyContent: 'center'
                  }}
                >
                  <Text
                    style={{ alignSelf: 'flex-end', fontSize: displayMode === 'digital' ? 30 : 50 }}
                  >
                    {lowercase(packagedItemA)}
                  </Text>
                </View>

                <View
                  style={{
                    height:
                      (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2,
                    justifyContent: 'center'
                  }}
                >
                  <Text
                    style={{ alignSelf: 'flex-end', fontSize: displayMode === 'digital' ? 30 : 50 }}
                  >
                    {lowercase(packagedItemB)}
                  </Text>
                </View>
              </View>
              <View>
                <View
                  style={{
                    height:
                      (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2,
                    justifyContent: 'center'
                  }}
                >
                  <BarModel
                    dimens={{
                      width: displayMode === 'digital' ? dimens.width - 300 : dimens.width - 500,
                      height:
                        (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2
                    }}
                    total={barModelTotals}
                    numbers={[[...filledArray(100, multiple)]]}
                    strings={[[...filledArray('', multiple)]]}
                    sameRowColor
                  />
                </View>

                <View
                  style={{
                    height:
                      (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2,
                    justifyContent: 'center'
                  }}
                >
                  <BarModel
                    dimens={{
                      width: displayMode === 'digital' ? dimens.width - 300 : dimens.width - 500,
                      height:
                        (displayMode === 'digital' ? dimens.height - 20 : dimens.height - 90) / 2
                    }}
                    total={barModelTotals}
                    numbers={[[item2BarModalLength, item2ArrowLength]]}
                    strings={[['', '?']]}
                    arrowIndices={[[1]]}
                    rowColors={[colors.acidGreen]}
                  />
                </View>
              </View>
              <View
                style={{
                  width: 30,
                  height: displayMode === 'digital' ? dimens.height - 50 : dimens.height - 150,
                  alignSelf: 'center',
                  marginLeft: 10,
                  borderWidth: 2,
                  borderColor: colors.prussianBlue,
                  borderLeftWidth: 0
                }}
              />
              <View
                style={{
                  width: 15,
                  alignSelf: 'center',
                  marginRight: 10,
                  borderTopWidth: 2,
                  borderColor: colors.prussianBlue
                }}
              />
              <Text
                style={{
                  width: displayMode === 'digital' ? 100 : 180,
                  alignSelf: 'center',
                  fontSize: displayMode === 'digital' ? 30 : 50
                }}
              >
                {pounds}
              </Text>
            </View>
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                width: dimens.width,
                justifyContent: 'flex-start'
              }}
            >
              {translate.answerSentences.howMuchMoreDoesXItemOfYItemCostThanZItem(
                random ? translate.objects.Box() : translate.objects.Bag(),
                packagedItemA,
                packagedItemB
              )}
            </Text>
          </>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAndDivideDecimalsInContext',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
