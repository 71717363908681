import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 5a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm1.25 10.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
