import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { readonlyObjectEntry } from 'common/src/utils/collections';
import { useCallback } from 'react';

/** Number of days until a queue item becomes expired. */
const EXPIRY_DAYS = 7;

/** A single item in the queue. This holds all the unsent question attempts for a quiz session. */
type QueueItem = {
  /**
   * Last time this queue item was updated. Queue items older than 7 days will be deleted.
   * This is stored in milliseconds since the epoch.
   */
  updatedAt: number;
  /** The question results that are currently queued up for this quiz session. */
  questionAttempts: QuestionAttempt[];
  /**
   * If provided, we need to update the backend on the quiz completion status to whatever value it is.
   * (Currently only true is allowed as we have no reason to send "isComplete": false.)
   * Note: this is only sent if `questionAttempts` is non-empty (i.e. we have unsent question attempts). This is why
   * `isComplete` is only ever set to true when accompanied with a questionAttempt.
   */
  isComplete?: true;
};

export type QuestionAttempt = {
  question: string;
  answer: string;
  isCorrect: boolean;
  timeTaken: number;
  attemptNumber: number;
  parameters?: Record<string, unknown>;
};

type State = {
  sessionQueue: Record<string, QueueItem | undefined>;
};

type Actions = {
  getSessionQueue: () => Record<string, QueueItem | undefined>;
  /**
   * Add a question attempt to the queue item for the given quizSessionId, creating it if it didn't exist.
   * Returns the new queue item.
   */
  updateQueueItem: (
    quizSessionId: string,
    questionAttempt: QuestionAttempt,
    isComplete?: true
  ) => QueueItem;
  /** Clear quiz results for specified quizSessionId. */
  deleteQueueItem: (quizSessionId: string) => void;
  /** Clear any expired entries in the queue. Also removes undefiend entries or entries with no question attempts. */
  deleteExpiredQueueItems: () => void;
};

const defaultState: State = {
  sessionQueue: {}
};

const useQuestionQueueStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get) => ({
        ...defaultState,

        getSessionQueue: () => get().sessionQueue,

        updateQueueItem: (quizSessionId, questionAttempt, isComplete) => {
          const sessionQueue = get().sessionQueue;
          let newQueueItem: QueueItem;
          const newQuestionQueue = readonlyObjectEntry(sessionQueue, quizSessionId, queueItem => {
            newQueueItem =
              queueItem === undefined
                ? {
                    updatedAt: Date.now(),
                    questionAttempts: [questionAttempt],
                    isComplete: isComplete
                  }
                : {
                    ...queueItem,
                    updatedAt: Date.now(),
                    questionAttempts: [...queueItem.questionAttempts, questionAttempt],
                    isComplete
                  };
            return newQueueItem;
          });

          set({ sessionQueue: newQuestionQueue });
          return newQueueItem!;
        },

        deleteQueueItem: quizSessionId => {
          const sessionQueue = get().sessionQueue;
          const newQuestionQueue = readonlyObjectEntry(sessionQueue, quizSessionId, _ => undefined);

          set({ sessionQueue: newQuestionQueue });
        },

        deleteExpiredQueueItems: () => {
          const now = new Date().getTime();
          const millisecondsDays = 1000 * 60 * 60 * 24 * EXPIRY_DAYS;

          const sessionQueue = get().sessionQueue;
          const newQuestionQueue = Object.fromEntries(
            Object.entries(sessionQueue).filter(
              ([_, queueItem]) =>
                queueItem !== undefined &&
                queueItem.questionAttempts.length > 0 &&
                now - queueItem.updatedAt < millisecondsDays
            )
          );

          set({ sessionQueue: newQuestionQueue });
        }
      }),
      {
        name: 'questionsQueue',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useQuestionQueueStore;

/**
 * Submit all queued questions. Clears out expired queue items first.
 */
export function useSubmitAllQueuedQuestions<Error extends string>(
  networkRequest: (
    quizSessionId: string,
    payload: { questionResults?: QuestionAttempt[]; isComplete?: boolean }
  ) => Promise<void | Error>
): () => Promise<void> {
  const getSessionQueue = useQuestionQueueStore(state => state.getSessionQueue);
  const deleteQueueItem = useQuestionQueueStore(state => state.deleteQueueItem);
  const deleteExpiredQueueItems = useQuestionQueueStore(state => state.deleteExpiredQueueItems);

  return useCallback(async () => {
    deleteExpiredQueueItems();

    const entries = Object.entries(getSessionQueue()).filter(
      (x: [string, QueueItem | undefined]): x is [string, QueueItem] => x[1] !== undefined
    );

    // Make all request simultaneously and keep hold of an array of the promises
    const promises = entries.map(async ([quizSessionId, queueEntry]) => {
      const result = await networkRequest(quizSessionId, {
        questionResults: queueEntry.questionAttempts,
        isComplete: queueEntry.isComplete
      });
      if (typeof result !== 'string') {
        // Success
        deleteQueueItem(quizSessionId);
      }
    });

    // Wait for all promises to finish
    await Promise.all(promises);
  }, [deleteExpiredQueueItems, deleteQueueItem, getSessionQueue, networkRequest]);
}
