/**
 * These utility functions deal with _exchanges_. An exchange is when some of one column is exchanged into 10× or 0.1×
 * its value, and written in an adjacent column. This is also often known as carrying or borrowing the 1 (though it
 * isn't always 1).
 *
 * For example, 136 + 482 is worked out by exchanging 10 of the tens for 1 of the hundreds, as the tens column sums to
 * 11 and we "carry" that first 1 into the hundreds.
 *
 * For another example, 192 - 99 is worked out by exchanging 1 of the tens for 10 of the ones, as we cannot take 9
 * from 2, but we can take 9 from 12. We "borrow" a 1 from the tens.
 *
 * Exchanges can also cascade. For example 8888 + 1112 looks at face value to have a single exchange: carry the one
 * from the ones to the tens, since the ones column sums to 10. However, carrying that 1 makes the other columns also
 * sum to 10, so it actually exchanges in 4 columns.
 *
 * Important notes:
 *
 * 1. Multiplication can also have exchanges, but it is usually only talked about when multiplying by a single digit
 * number, e.g. 12×6 involves exchanging 10 ones for 1 ten. You can carry more than 1 at a time with multiplications.
 * These functions currently don't support multiplication.
 * 2. These functions currently only support adding or subtracting two numbers.
 * 3. We have the concept of exchanging _at_ a column. For the sake of these utility functions, we always
 * denote the lower column involved, so 17+15 and 32-15 both exchange at the ones column. This is _not_
 * universal:
 *   - you can also use the word "across", which denotes the higher column, e.g. 17+15 exchanges across a 10.
 *   - the White Rose Maths maths specialists would say 32-15 exchanges at the _tens_ column.
 */
import { arraysHaveSameContentsUnordered, countRange, filledArray, range } from './collections';
import { PowersOfTenWord, powersOfTenWordToPow, ScientificNotation } from './math';
import { randomIntegerInclusive, shuffle } from './random';

/**
 * Checks that no exchange happened at all.
 *
 * Works by checking that a direct sum with no borrowing gives the same digits as the true sum.
 *
 * Result must be non-negative, but one or both of the given numbers may be negative to check subtractions.
 */
export function numbersDoNotExchange(numA: number, numB: number): boolean {
  const a = ScientificNotation.fromNumber(numA);
  const b = ScientificNotation.fromNumber(numB);
  const sum = a.add(b);

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  // Compare the digits of the real sum with those of the direct-sum, with no exchanges. These may be out of bounds.
  return range(lowestPower, highestPower).every(
    pow => sum.digitAt(pow) === a.digitAt(pow) + b.digitAt(pow)
  );
}

/**
 * Function to check any exchange takes place between two numbers when added together.
 */
export function numbersExchange(numA: number, numB: number) {
  return !numbersDoNotExchange(numA, numB);
}

/**
 * Function to check an exchange does not take place at a specific power of ten when two numbers are added together.
 *
 * Result must be non-negative, but one or both of the given numbers may be negative to check subtractions.
 */
export function numbersDoNotExchangeAt(
  numA: number,
  numB: number,
  exchange: number | PowersOfTenWord
) {
  exchange = typeof exchange === 'number' ? exchange : powersOfTenWordToPow[exchange];

  const a = ScientificNotation.fromNumber(numA);
  const b = ScientificNotation.fromNumber(numB);
  const sum = a.add(b);

  // These are signed digits, so they're negative if the number is negative.
  const digitA = a.digitAt(exchange);
  const digitB = b.digitAt(exchange);
  const digitResult = sum.digitAt(exchange);

  if (numB >= 0 && numA >= 0) {
    // When summing two positive numbers, an exchange in a column means that the sum of the digits in that column
    // (plus maybe a carried 1 from the column below) was >= 10, so 10 is subtracted and carried as a 1 to the column
    // above. So digitResult = digitA + digitB + maybe 1 - 10
    return !(digitResult === digitA + digitB - 10 || digitResult === digitA + digitB + 1 - 10);
  } else {
    // When one of them is negative, an exchange in a column means that the sum of the digits in that column
    // (minus maybe a borrowed 1 by the column below) was < 0, so 10 is added and borrowed as a 1 from the column
    // above. So digitResult = digitA + digitB - maybe 1 + 10
    return !(digitResult === digitA + digitB + 10 || digitResult === digitA + digitB - 1 + 10);
  }
}

/**
 * Function to check an exchange takes place at a specific power of ten when two numbers are added together.
 *
 * Result must be non-negative, but one or both of the given numbers may be negative to check subtractions.
 */
export function numbersExchangeAt(numA: number, numB: number, exchange: number | PowersOfTenWord) {
  return !numbersDoNotExchangeAt(numA, numB, exchange);
}

/**
 * Function to find all the exchanges (as powers of ten) which take place when two numbers are added together.
 *
 * Result must be non-negative, but one or both of the given numbers may be negative to check subtractions.
 */
export function findExchanges(numA: number, numB: number): number[] {
  const a = ScientificNotation.fromNumber(numA);
  const b = ScientificNotation.fromNumber(numB);
  const sum = a.add(b);

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  return range(lowestPower, highestPower).filter(pow => numbersExchangeAt(numA, numB, pow));
}

/**
 * Function to find all exchange numerical values for add and subtraction
 * For subtraction send one number in as negative
 * @param numA
 * @param numB
 * @returns string array with empty strings if there is no exchange
 */

export function findAddSubExchangeValues(numA: number, numB: number): string[] {
  const exchanges = findExchanges(numA, numB);
  const a = ScientificNotation.fromNumber(numA);
  const b = ScientificNotation.fromNumber(numB);
  const isSubtraction = numA < 0 || numB < 0;
  const sum = a.add(b);

  const aDigits = a.digits.slice().reverse();
  const bDigits = b.digits.slice().reverse();

  const positiveNumber = numA > 0 ? aDigits : bDigits;

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  return range(highestPower, lowestPower).map(i => {
    const displayNumber = isSubtraction
      ? positiveNumber[i + 1] - Math.floor((aDigits[i] + bDigits[i]) / 10)
      : Math.floor((aDigits[i] + bDigits[i]) / 10);
    return exchanges.includes(i) ? displayNumber.toString() : '';
  });
}

/**
 * Function to check an exchange takes places only at specified powers, and no other exchanges take place.
 *
 * `exchanges` can be given as a single number/word, or an array of numbers/words.
 *
 * Result must be non-negative, but one or both of the given numbers may be negative to check subtractions.
 */
export function numbersOnlyExchangeAt(
  numA: number,
  numB: number,
  exchanges: (number | PowersOfTenWord) | (number | PowersOfTenWord)[]
): boolean {
  exchanges = Array.isArray(exchanges) ? exchanges : [exchanges];
  const numberExchanges = exchanges.map(exchange =>
    typeof exchange === 'number' ? exchange : powersOfTenWordToPow[exchange]
  );
  return arraysHaveSameContentsUnordered(findExchanges(numA, numB), numberExchanges);
}

/**
 * Find a random pair of numbers which, when added, has only the given exchange(s).
 * This is constructive, and is roughly 80x more efficient than a rejection sample approach, i.e. generating random
 * numbers over and over until they happen to have the required exchanges.
 */
export function getSumWithOnlyGivenExchanges(
  exchanges: (number | PowersOfTenWord) | (number | PowersOfTenWord)[],
  numDigits: number,
  options: {
    /** Whether to allow numbers with fewer digits, e.g. 20 is allowed as a 3 digit number. Defaults to true. */
    allowFewerDigits?: boolean;
    /** Where we get our randomness from. Defaults to {@link Math.random}, but seeded randomness could be used. */
    random?: () => number;
  } = {}
): [number, number] {
  exchanges = Array.isArray(exchanges) ? exchanges : [exchanges];
  const numberExchanges = exchanges.map(exchange =>
    typeof exchange === 'number' ? exchange : powersOfTenWordToPow[exchange]
  );

  const { allowFewerDigits, random } = options;
  const randomOptions = { random };

  const aDigits: number[] = [];
  const bDigits: number[] = [];
  // Tracks which columns carried a 1 into the next column.
  const carries: boolean[] = [];

  for (const pow of countRange(numDigits)) {
    // If we're not allowed numbers with fewer digits and this is the leading digit, this digit cannot be 0.
    const lowestDigit = !allowFewerDigits && pow === numDigits - 1 ? 1 : 0;
    const carried1 = carries[pow - 1] ? 1 : 0;
    if (numberExchanges.includes(pow)) {
      // It should exchange here
      const x = randomIntegerInclusive(
        Math.max(lowestDigit, carried1 === 1 ? 0 : 1),
        9,
        randomOptions
      );
      const y = randomIntegerInclusive(Math.max(lowestDigit, 10 - x - carried1), 9, randomOptions);
      const [aDigit, bDigit] = shuffle([x, y], randomOptions);
      aDigits.push(aDigit);
      bDigits.push(bDigit);
      carries.push(true);
    } else {
      // It should not exchange here
      const x = randomIntegerInclusive(lowestDigit, 9 - carried1 - lowestDigit, randomOptions);
      const y = randomIntegerInclusive(lowestDigit, 9 - x - carried1, randomOptions);
      const [aDigit, bDigit] = shuffle([x, y], randomOptions);
      aDigits.push(aDigit);
      bDigits.push(bDigit);
      carries.push(false);
    }
  }

  return [Number(aDigits.reverse().join('')), Number(bDigits.reverse().join(''))];
}

/**
 * @deprecated Use {@link multiplicationHasExchanges}
 * Function to check that there are no exchanges when multiplicand × multiplier.
 * Returns a boolean - true if no exchanges take place, false if exchanges do take place.
 * This assumes the multiplier is a positive single-digit number.
 */
export function numbersDoNotHaveMultiplicationExchange(
  multiplicand: number,
  multiplier: number
): boolean {
  if (multiplier >= 10 || multiplier < 1) {
    throw new Error('This function is not designed to work with multipliers that are not 1-digit.');
  }

  const allPowers = ScientificNotation.fromNumber(multiplicand).toBase10Object();
  return Object.values(allPowers).every(num => num * multiplier < 10 && num * multiplier >= 0);
}

/**
 * Function to find if exchange happens for given power of ten for multiplicand × multiplier.
 * Returns a boolean - true if exchange take place, false if no exchange takes place.
 * This assumes the multiplier is a positive single-digit number.
 */
export function numbersMultiplicationExchangeAt(
  multiplicand: number,
  multiplier: number,
  exchange: number | PowersOfTenWord
) {
  if (multiplier >= 10 || multiplier < 1) {
    throw new Error('This function is not designed to work with multipliers that are not 1-digit.');
  }
  exchange = typeof exchange === 'number' ? exchange : powersOfTenWordToPow[exchange];
  const a = ScientificNotation.fromNumber(multiplicand);
  // These are signed digits, so they're negative if the number is negative.
  const digitA = a.digitAt(exchange);
  return digitA * multiplier >= 10;
}

/**
 * Function to find all the exchanges (as powers of ten) which take place when two numbers are multiplied together.
 * Returns number array with power of tens where exchanges happen
 * This assumes the multiplier is a positive single-digit number.
 * */
export function findMultiplicationExchanges(multiplicand: number, multiplier: number): number[] {
  if (multiplier >= 10 || multiplier < 1) {
    throw new Error('This function is not designed to work with multipliers that are not 1-digit.');
  }

  const a = ScientificNotation.fromNumber(multiplicand);
  const b = ScientificNotation.fromNumber(multiplier);
  const sum = a.multiply(b);

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  return range(lowestPower, highestPower).filter(pow =>
    numbersMultiplicationExchangeAt(multiplicand, multiplier, pow)
  );
}

/**
 * Function to find all exchange numerical values for multiplication
 * For subtraction send one number in as negative
 * @param numA
 * @param numB
 * @returns string array with empty strings if there is no exchange
 * This assumes the multiplier is a positive single-digit number.
 * */

export function findMultiplicationExchangeValuesOld(
  multiplicand: number,
  multiplier: number
): string[] {
  if (multiplier >= 10 || multiplier < 1) {
    throw new Error('This function is not designed to work with multipliers that are not 1-digit.');
  }

  const exchanges = findMultiplicationExchanges(multiplicand, multiplier);
  const a = ScientificNotation.fromNumber(multiplicand);
  const b = ScientificNotation.fromNumber(multiplier);
  const sum = a.multiply(b);

  const aDigits = a.digits.slice().reverse();

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  return range(highestPower, lowestPower).map(i => {
    const isLastExchange = i === highestPower - 1 && aDigits[i - 1] !== undefined;
    const returnValue = isLastExchange
      ? a.digitAt(i) * b.digitAt(b.e) + Math.floor((a.digitAt(i - 1) * b.digitAt(b.e)) / 10)
      : a.digitAt(i) * b.digitAt(b.e);
    return exchanges.includes(i) ? Math.floor(returnValue / 10).toString() : '';
  });
}

export function findMultiplicationExchangeValues(
  multiplicand: number,
  multiplier: number
): string[] {
  if (multiplier >= 10 || multiplier < 1) {
    throw new Error('This function is not designed to work with multipliers that are not 1-digit.');
  }

  const a = ScientificNotation.fromNumber(multiplicand);
  const b = ScientificNotation.fromNumber(multiplier);
  const sum = a.multiply(b);
  const results: number[] = [];

  // Check all possibly relevant digits.
  const lowestPower = Math.min(a.resolution, b.resolution, sum.resolution);
  const highestPower = Math.max(a.e, b.e, sum.e);

  range(lowestPower, highestPower).forEach((i, idx) => {
    const returnValue = Math.floor(
      (a.digitAt(i) * b.digitAt(b.e) + (i > 0 ? results[idx - 1] : 0)) / 10
    );
    results.push(returnValue);
  });

  return results.map(val => (val === 0 ? '' : val.toLocaleString())).reverse();
}

/**
 * Function to check that there are no exchanges when dividend ÷ divisor.
 * Returns a boolean - true if no exchanges take place, false if exchanges do take place.
 * This assumes the divisor is a positive single-digit number.
 */
export function numbersDoNotHaveDivisionExchange(dividend: number, divisor: number): boolean {
  if (divisor >= 10) {
    console.warn('This function is not designed to work with divisors that are not 1-digit.');
  }

  if (divisor < 1) {
    console.warn('This function is not designed to work with divisors that are less than 1.');
  }

  const allPowers = ScientificNotation.fromNumber(dividend).toBase10Object();
  return Object.values(allPowers).every(num => num % divisor === 0);
}

/**
 * Function to find the exchange that happens for a given power of ten for dividend / divisor.
 * Returns a number - if no exchange takes place, 0 is returned.
 * This function takes into account exchanges that take place before the digit that may impact whether an exchange takes place
 * e.g. in 155 / 3, no exchange takes place in the tens, as this would already have 1 from the hundreds carried over - and so
 * is actually 15 tens divided by 3.
 * This assumes the divisor is a positive single-digit number. Larger divisors are unlikely to work as intended.
 */
export function findDivisionExchangeValueAt(
  dividend: number,
  divisor: number,
  exchange: number | PowersOfTenWord
): number {
  exchange = typeof exchange === 'number' ? exchange : powersOfTenWordToPow[exchange];

  const dividendSci = ScientificNotation.fromNumber(dividend);
  const highestPower = dividendSci.e;

  let currentExchange = 0;

  // Check every division and potential exchange that can take place from the dividend's highest digit to the required power:
  for (let i = highestPower; i >= exchange; i--) {
    const currentDigit = dividendSci.unsignedDigitAt(i);

    if (currentDigit !== undefined) {
      if (currentDigit + ((10 * currentExchange) % divisor) !== 0) {
        // If the current division does not divide exactly, an exchange is needed - assign it here:
        currentExchange = (currentDigit + 10 * currentExchange) % divisor;
      } else {
        // If the current division divides exactly, no exchange is needed:
        currentExchange = 0;
      }
    }
  }

  // If we end with a currentExchange that isn't 0, there must be an exchange at this power:
  return currentExchange;
}

/**
 * Function to find all exchange numerical values for division.
 * @returns number array with 0 if there is no exchange.
 * Exchanges are returned from highest digit to lowest digit of dividend.
 * This assumes the divisor is a positive single-digit number,
 * and that the quotient will be a whole number - otherwise this function will not behave as expected.
 * */
export function findAllDivisionExchangeValues(dividend: number, divisor: number): number[] {
  const dividendToSciNot = ScientificNotation.fromNumber(dividend);

  const lowestPower = dividendToSciNot.resolution;
  const highestPower = dividendToSciNot.e;

  return range(highestPower, lowestPower).map(i =>
    findDivisionExchangeValueAt(dividend, divisor, i)
  );
}

/**
 * Function to find if exchange happens for given power of ten for dividend / divisor.
 * Returns a boolean - true if exchange take place, false if no exchange takes place.
 * This assumes the divisor is a positive single-digit number.
 */
export function isDivisionExchangeAt(
  dividend: number,
  divisor: number,
  exchange: number | PowersOfTenWord
): boolean {
  return findDivisionExchangeValueAt(dividend, divisor, exchange) !== 0;
}

/**
 * Function to find all the exchanges (as powers of ten) which take place when dividend / divisor.
 * Returns number array with power of tens where exchanges happen.
 * This assumes the divisor is a positive single-digit number.
 * */
export function findAllDivisionExchangesPowersOfTen(dividend: number, divisor: number): number[] {
  const dividendToSciNot = ScientificNotation.fromNumber(dividend);

  const lowestPowerOfDividend = dividendToSciNot.resolution;
  const highestPowerOfDividend = dividendToSciNot.e;

  // Return every power in the dividend that has an exchange at this particular power:
  return range(lowestPowerOfDividend, highestPowerOfDividend).filter(pow =>
    isDivisionExchangeAt(dividend, divisor, pow)
  );
}

/**
 * Function to find if exchange(s) happen for given power(s) of ten for dividend / divisor.
 * Returns a boolean - true if exchanges only take place at the required place values, false otherwise.
 */
export function isDivisionExchangesOnlyAt(
  dividend: number,
  divisor: number,
  exchanges: (number | PowersOfTenWord)[]
): boolean {
  const exchangesToUse = exchanges.map(exc =>
    typeof exc === 'number' ? exc : powersOfTenWordToPow[exc]
  );

  const allPowers = findAllDivisionExchangesPowersOfTen(dividend, divisor);

  return arraysHaveSameContentsUnordered(exchangesToUse, allPowers);
}

export function multiplicationHasExchanges(multiplicand: number, multiplier: number): boolean {
  const multiplicandString = String(multiplicand).split('').reverse().map(Number);
  const multiplierString = String(multiplier).split('').reverse().map(Number);

  const resultsArray = filledArray(0, multiplicandString.length + multiplierString.length);

  // Perform long multiplication digit by digit
  for (let i = 0; i < multiplicandString.length; i++) {
    for (let j = 0; j < multiplierString.length; j++) {
      const product = multiplicandString[i] * multiplierString[j];
      const sum = resultsArray[i + j] + product;
      if (product >= 10) {
        return true; // Carry-over in multiplication phase
      }
      // Add to the resultsArray and manage carry-over
      resultsArray[i + j] = sum % 10; // Store the current digit
      resultsArray[i + j + 1] += Math.floor(sum / 10); // Carry over to the next position
      // Check if carry-over occurred during the addition phase
      if (sum >= 10) {
        return true; // Carry-over in addition phase
      }
    }
  }
  return false; // No carry-over found in either phase
}
