// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import PerimeterOfRectangles from './1PerimeterOfRectangles';
import PerimeterOfRectilinearShapes from './2PerimeterOfRectilinearShapes';
import PerimeterOfPolygons from './3PerimeterOfPolygons';
import AreaOfRectangles from './4AreaOfRectangles';
import AreaOfCompoundShapes from './5AreaOfCompoundShapes';
import EstimateArea from './6EstimateArea';

const Block = newBlockContent({
  block: 'PerimeterAndArea',
  field: 'Measurement',
  weeks: [9, 10],
  smallSteps: [
    PerimeterOfRectangles, // Step 1
    PerimeterOfRectilinearShapes, // Step 2
    PerimeterOfPolygons, // Step 3
    AreaOfRectangles, // Step 4
    AreaOfCompoundShapes, // Step 5
    EstimateArea // Step 6
  ]
});
export default Block;
