import { Name } from './names';
import { colors } from '../theme/colors';
import { View } from 'react-native';
import Text from '../components/typography/Text';
import { AssetSvg, type SvgName } from '../assets/svg';

const characterHeadObjects: Record<Name, SvgName> = {
  Alex: 'Characters/AlexHead',
  Amir: 'Characters/AmirHead',
  Annie: 'Characters/AnnieHead',
  Dexter: 'Characters/DexterHead',
  Eva: 'Characters/EvaHead',
  Jack: 'Characters/JackHead',
  Jo: 'Characters/JoHead',
  Kim: 'Characters/KimHead',
  Max: 'Characters/MaxHead',
  Mo: 'Characters/MoHead',
  Ron: 'Characters/RonHead',
  Rosie: 'Characters/RosieHead',
  Sam: 'Characters/SamHead',
  Teddy: 'Characters/TeddyHead',
  Tommy: 'Characters/TommyHead',
  Whitney: 'Characters/WhitneyHead'
};

/**
 * Get character head SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getCharacterHeadImage} instead.
 */
export const getCharacterHeadSvgName = (name: Name): SvgName => {
  return characterHeadObjects[name];
};

/**
 * Get character head image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getCharacterHeadSvgName} directly, for better customization.
 */
export const getCharacterHeadImage = (name: Name, height?: number, width?: number): JSX.Element => {
  return <AssetSvg name={getCharacterHeadSvgName(name)} height={height} width={width} />;
};

type characterStanding = {
  [key: string]: {
    name: SvgName;
    bottomOffset: number;
    topOffset: number;
  };
};

const characterStandingObjects: characterStanding = {
  Alex: {
    name: 'Characters/AlexStanding',
    bottomOffset: 8,
    topOffset: 18
  },
  Amir: {
    name: 'Characters/AmirStanding',
    bottomOffset: 8,
    topOffset: 10
  },
  Annie: {
    name: 'Characters/AnnieStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Eva: {
    name: 'Characters/EvaStanding',
    bottomOffset: 8,
    topOffset: 50
  },
  Jack: {
    name: 'Characters/JackStanding',
    bottomOffset: 8,
    topOffset: 18
  },
  Jo: {
    name: 'Characters/JoStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Kim: {
    name: 'Characters/KimStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Max: {
    name: 'Characters/MaxStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Mo: {
    name: 'Characters/MoStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Ron: {
    name: 'Characters/RonStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Rosie: {
    name: 'Characters/RosieStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Sam: {
    name: 'Characters/SamStanding',
    bottomOffset: 8,
    topOffset: 55
  },
  Teddy: {
    name: 'Characters/TeddyStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Tommy: {
    name: 'Characters/TommyStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Whitney: {
    name: 'Characters/WhitneyStanding',
    bottomOffset: 8,
    topOffset: 0
  }
};

export const getCharacterStandingOffsets = (name: Name) => {
  if (name === 'Dexter') {
    throw Error('There is no standing image for Dexter.');
  }
  const CHARACTER_STANDING_BOTTOM_OFFSET = characterStandingObjects[name].bottomOffset;
  const CHARACTER_STANDING_TOP_OFFSET = characterStandingObjects[name].topOffset;

  return {
    characterBottomOffset: CHARACTER_STANDING_BOTTOM_OFFSET,
    characterTopOffset: CHARACTER_STANDING_TOP_OFFSET
  };
};

// These two offsets define where the character's height should be measured from and to, when placed against a ruler.
// They were found empirically for all CharacterStanding SVGs, and are luckily the same for all the SVGs.
/**
 * Get character standing SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getCharacterStandingSvgName = (name: Name): SvgName => {
  return characterStandingObjects[name].name;
};

/**
 * Helper function for consistent name label styling for different characters
 *
 * Colors have been set according to Figma designs (all genders are the same color)
 */
export const characterNameLabel = (name: Name, width: number) => {
  return (
    <View
      style={{
        borderWidth: 3,
        borderRadius: 19,
        width,
        borderColor: colors.prussianBlue,
        backgroundColor: colors.white
      }}
    >
      <Text variant="WRN400" style={{ fontSize: 32, textAlign: 'center' }}>
        {name}
      </Text>
    </View>
  );
};
