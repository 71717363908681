import { ComponentProps, createContext, useContext, useMemo } from 'react';
import EasyDragAndDropWithSingleZones from './EasyDragAndDropWithSingleZones';
import { getGridTransformer } from './utils';

const noContextError = () => {
  throw new Error('ZoneSingle must have Provider or ProviderWithState as an ancestor');
};

/** Context to get the knowledge of how to transform to/from the grid down to the individual components. */
const GridTransformerContext = createContext<ReturnType<typeof getGridTransformer>>({
  transform: noContextError,
  untransform: noContextError,
  untransformIndex: noContextError
});
function Provider<DragValue>({
  columnsPerRow,
  ...props
}: ComponentProps<typeof EasyDragAndDropWithSingleZones.Provider<DragValue>> & {
  /** How many drop zones each row of the grid contains. */ columnsPerRow: number[];
}) {
  const gridTransformer = useMemo(() => getGridTransformer(columnsPerRow), [columnsPerRow]);
  return (
    <GridTransformerContext.Provider value={gridTransformer}>
      <EasyDragAndDropWithSingleZones.Provider<DragValue> {...props} />
    </GridTransformerContext.Provider>
  );
}

function ProviderWithState<DragValue>({
  columnsPerRow,
  testCorrect: testCorrectProp,
  testComplete: testCompleteProp,
  defaultState: defaultStateProp,
  ...props
}: Omit<
  ComponentProps<typeof EasyDragAndDropWithSingleZones.ProviderWithState<DragValue>>,
  'testCorrect' | 'testComplete' | 'defaultState'
> & {
  /** How many drop zones each row of the grid contains. */
  columnsPerRow: number[];
  testCorrect?: (state: (DragValue | undefined)[][]) => boolean;
  testComplete?: (state: (DragValue | undefined)[][]) => boolean;
  defaultState?: (DragValue | undefined)[][];
}) {
  const gridTransformer = getGridTransformer(columnsPerRow);

  const testCorrect =
    testCorrectProp === undefined
      ? undefined
      : (state: (DragValue | undefined)[]) => testCorrectProp(gridTransformer.transform(state));
  const testComplete =
    testCompleteProp === undefined
      ? undefined
      : (state: (DragValue | undefined)[]) => testCompleteProp(gridTransformer.transform(state));
  const defaultState =
    defaultStateProp === undefined ? undefined : gridTransformer.untransform(defaultStateProp);

  return (
    <GridTransformerContext.Provider value={gridTransformer}>
      <EasyDragAndDropWithSingleZones.ProviderWithState<DragValue>
        testCorrect={testCorrect}
        testComplete={testComplete}
        defaultState={defaultState}
        {...props}
      />
    </GridTransformerContext.Provider>
  );
}

function ZoneSingle({
  row,
  column,
  ...props
}: Omit<ComponentProps<typeof EasyDragAndDropWithSingleZones.ZoneSingle>, 'id'> & {
  row: number;
  column: number;
}) {
  const gridTransformer = useContext(GridTransformerContext);
  const id = gridTransformer.untransformIndex(row, column);

  return <EasyDragAndDropWithSingleZones.ZoneSingle id={id} {...props} />;
}

/**
 * Like {@link EasyDragAndDropWithSingleZones} but even more specialized: it allows you to set out the drop zones in
 * a grid arrangment.
 *
 * Therefore, this uses a double array instead of a single array for `ProviderWithState`'s `testCorrect`, `testComplete`
 * and `defaultState`.
 */
export default {
  Provider,
  ProviderWithState,
  Source: EasyDragAndDropWithSingleZones.Source,
  ZoneSingle: ZoneSingle
};
