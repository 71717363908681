type System = 'Imperial' | 'Metric';
type MeasurementType = 'Capacity' | 'Length' | 'Mass' | 'Volume';

export const LengthSuffix = ['mm', 'cm', 'm', 'km', 'in', 'ft', 'yd', 'mi'] as const;
export const MassSuffix = ['mg', 'g', 'kg', 't', 'oz', 'lb', 'st'] as const;
export const CapacitySuffix = ['ml', 'cl', 'l', 'pt', 'gal'] as const;
export const VolumeSuffix = ['mm³', 'cm³', 'm³', 'km³'] as const;

/** Base abstract Measurement class
 * This class defines the abstract properties and methods
 * that all Measurement type objects should contain.
 */
export abstract class Measurement {
  /* _system: string: Imperial or Metric */
  private _system: System;
  /* _type: MeasurementType: 'Capacity' | 'Length' | 'Mass'; */
  private _type: MeasurementType;
  /* _measurementName: string: Name of the measurement */
  private _measurementName: string;
  /* _suffix: string: The unit string to use for the measurement */
  private _suffix: string;
  /* _value: number: The quantity of the measurement */
  protected _value: number;

  constructor(
    system: System,
    type: MeasurementType,
    measurementName: string,
    suffix: string,
    value: number
  ) {
    this._system = system;
    this._type = type;
    this._measurementName = measurementName;
    this._suffix = suffix;
    this._value = value;
  }

  /* Convert this measurement to the smallest metric measurement type */
  abstract toBaseMetric(): Measurement;
  /* Convert this measurement to the smallest imperial measurement type */
  abstract toBaseImperial(): Measurement;

  get system(): System {
    return this._system;
  }

  get type(): MeasurementType {
    return this._type;
  }

  get measurementName(): string {
    return this._measurementName;
  }

  get suffix(): string {
    return this._suffix;
  }

  get value(): number {
    return this._value;
  }

  set value(val: number) {
    this._value = val;
  }
}

/** Abstract class to classify all length measurements */
abstract class Length extends Measurement {
  static type: MeasurementType = 'Length';

  constructor(system: System, measurementName: string, suffix: string, value: number) {
    super(system, Length.type, measurementName, suffix, value);
  }

  abstract toBaseMetric(): Measurement;
  abstract toBaseImperial(): Measurement;
}

/**
 * Metric Lengths
 */

export class Millimetres extends Length {
  static system: System = 'Metric';
  static measurementName = 'Millimetres';
  static suffix = 'mm';

  constructor(value: number) {
    super(Millimetres.system, Millimetres.measurementName, Millimetres.suffix, value);
  }

  toBaseMetric() {
    // No Conversion required as mm is the base value
    return this;
  }

  toBaseImperial() {
    // There are 25mm in 1 inch
    return new Inches(this.value / 25);
  }

  static fromOther(from: Length): Millimetres {
    if (from.system === 'Metric') {
      return from.toBaseMetric();
    } else {
      return new Millimetres(from.toBaseImperial().value * 25);
    }
  }
}

export class Centimetres extends Length {
  static system: System = 'Metric';
  static measurementName = 'Centimetres';
  static suffix = 'cm';

  constructor(value: number) {
    super(Centimetres.system, Centimetres.measurementName, Centimetres.suffix, value);
  }
  toBaseMetric() {
    // 10 mm in 1 cm
    return new Millimetres(this.value * 10);
  }

  toBaseImperial() {
    return Inches.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Length): Centimetres {
    if (from.system === 'Metric') {
      return new Centimetres(from.toBaseMetric().value / 10);
    } else {
      return new Centimetres(from.toBaseImperial().toBaseMetric().value / 10);
    }
  }
}

export class Metres extends Length {
  static system: System = 'Metric';
  static measurementName = 'Metres';
  static suffix = 'm';

  constructor(value: number) {
    super(Metres.system, Metres.measurementName, Metres.suffix, value);
  }

  toBaseMetric() {
    // 1000 mm in 1 m
    return new Millimetres(this.value * 1000);
  }

  toBaseImperial() {
    return Inches.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Length): Metres {
    if (from.system === 'Metric') {
      return new Metres(from.toBaseMetric().value / 1000);
    } else {
      return new Metres(from.toBaseImperial().toBaseMetric().value / 1000);
    }
  }
}

export class Kilometres extends Length {
  static system: System = 'Metric';
  static measurementName = 'Kilometres';
  static suffix = 'km';

  constructor(value: number) {
    super(Kilometres.system, Kilometres.measurementName, Kilometres.suffix, value);
  }

  toBaseMetric() {
    // 1000000 mm in 1 km
    return new Millimetres(this.value * 1000000);
  }

  toBaseImperial() {
    return Inches.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Length): Kilometres {
    if (from.system === 'Metric') {
      return new Kilometres(from.toBaseMetric().value / 1000000);
    } else {
      // Students would be expected to convert to miles first then to KM
      // They should use 1 mile = 1.6 km
      return new Kilometres(Miles.fromOther(from).value * 1.6);
    }
  }
}

/**
 * Imperial lengths
 */

export class Inches extends Length {
  static system: System = 'Imperial';
  static measurementName = 'Inches';
  static suffix = 'in';

  constructor(value: number) {
    super(Inches.system, Inches.measurementName, Inches.suffix, value);
  }

  toBaseMetric() {
    return new Millimetres(this.value * 25);
  }

  toBaseImperial() {
    // No Conversion required as inches is
    // the base imperial value for length
    return this;
  }

  static fromOther(from: Length): Inches {
    if (from.system === 'Imperial') {
      return from.toBaseImperial();
    } else {
      return new Inches(from.toBaseMetric().value / 25);
    }
  }
}

export class Feet extends Length {
  static system: System = 'Imperial';
  static measurementName = 'Feet';
  static suffix = 'ft';

  constructor(value: number, measurementName?: string) {
    super(Feet.system, measurementName ?? Feet.measurementName, Feet.suffix, value);
  }

  toBaseImperial() {
    // 12 inches in 1 foot
    return new Inches(this.value * 12);
  }

  toBaseMetric() {
    return Millimetres.fromOther(this.toBaseImperial());
  }

  static fromOther(from: Length): Feet {
    if (from.system === 'Metric') {
      return new Feet(from.toBaseMetric().toBaseImperial().value / 12);
    } else {
      return new Feet(from.toBaseImperial().value / 12);
    }
  }
}

export class Yards extends Length {
  static system: System = 'Imperial';
  static measurementName = 'Yards';
  static suffix = 'yd';

  constructor(value: number) {
    super(Yards.system, Yards.measurementName, Yards.suffix, value);
  }

  toBaseImperial() {
    // 36 inches in a yard
    return new Inches(this.value * 36);
  }

  toBaseMetric() {
    return Millimetres.fromOther(this.toBaseImperial());
  }

  static fromOther(from: Length): Yards {
    if (from.system === 'Metric') {
      return new Yards(from.toBaseMetric().toBaseImperial().value / 36);
    } else {
      return new Yards(from.toBaseImperial().value / 36);
    }
  }
}

export class Miles extends Length {
  static system: System = 'Imperial';
  static measurementName = 'Miles';
  static suffix = 'mi';

  constructor(value: number, measurementName?: string) {
    super(Miles.system, measurementName ?? Miles.measurementName, Miles.suffix, value);
  }

  toBaseImperial() {
    return new Inches(this.value * 63360);
  }

  toBaseMetric() {
    return Millimetres.fromOther(this.toBaseImperial());
  }

  static fromOther(from: Length): Miles {
    if (from.system === 'Metric') {
      // Students need to use 1.6 kilometres to 1 mile
      // Convert metric measure to mm, then to km, then to miles
      return new Miles(from.toBaseMetric().value / (1000000 * 1.6));
    } else {
      return new Miles(from.toBaseImperial().value / 63360);
    }
  }
}

/** Abstract class to classify all weight measurements */
abstract class Mass extends Measurement {
  static type: MeasurementType = 'Mass';

  constructor(system: System, measurementName: string, suffix: string, value: number) {
    super(system, Mass.type, measurementName, suffix, value);
  }

  abstract toBaseMetric(): Measurement;
  abstract toBaseImperial(): Measurement;
}

/**
 * Metric Weights
 */

export class Milligrams extends Mass {
  static system: System = 'Metric';
  static measurementName = 'Milligrams';
  static suffix = 'mg';

  constructor(value: number) {
    super(Milligrams.system, Milligrams.measurementName, Milligrams.suffix, value);
  }

  toBaseMetric() {
    // No Conversion required as mg is the base value
    return this;
  }

  toBaseImperial() {
    // Approx 1oz is 28350 mg
    // Students would not be required to make this conversion
    return new Ounces(this.value / 28350);
  }

  static fromOther(from: Mass): Milligrams {
    if (from.system === 'Metric') {
      return from.toBaseMetric();
    } else {
      return new Milligrams(from.toBaseImperial().value * 28350);
    }
  }
}

export class Grams extends Mass {
  static system: System = 'Metric';
  static measurementName = 'Grams';
  static suffix = 'g';

  constructor(value: number) {
    super(Grams.system, Grams.measurementName, Grams.suffix, value);
  }

  toBaseMetric() {
    // 1 g = 1000 mg
    return new Milligrams(this.value * 1000);
  }

  toBaseImperial() {
    return Ounces.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Mass): Grams {
    if (from.system === 'Metric') {
      return new Grams(from.toBaseMetric().value / 1000);
    } else {
      return new Grams(from.toBaseImperial().toBaseMetric().value / 1000);
    }
  }
}

export class Kilograms extends Mass {
  static system: System = 'Metric';
  static measurementName = 'Kilograms';
  static suffix = 'kg';

  constructor(value: number) {
    super(Kilograms.system, Kilograms.measurementName, Kilograms.suffix, value);
  }

  toBaseMetric() {
    // 1 kg = 1000000 mg
    return new Milligrams(this.value * 1000000);
  }

  toBaseImperial() {
    return Ounces.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Mass): Kilograms {
    if (from.system === 'Metric') {
      return new Kilograms(from.toBaseMetric().value / 1000000);
    } else {
      return new Kilograms(from.toBaseImperial().toBaseMetric().value / 1000000);
    }
  }
}

export class Tonnes extends Mass {
  static system: System = 'Metric';
  static measurementName = 'Tonnes';
  static suffix = 'tonnes';

  constructor(value: number, measurementName?: string) {
    super(Tonnes.system, measurementName ?? Tonnes.measurementName, Tonnes.suffix, value);
  }

  toBaseMetric() {
    // 1 t = 1000000000 mg
    return new Milligrams(this.value * 1000000000);
  }

  toBaseImperial() {
    return Ounces.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Mass): Tonnes {
    if (from.system === 'Metric') {
      return new Tonnes(from.toBaseMetric().value / 1000000000);
    } else {
      return new Tonnes(from.toBaseImperial().toBaseMetric().value / 1000000000);
    }
  }
}

/**
 * Imperial Weights
 */

export class Ounces extends Mass {
  static system: System = 'Imperial';
  static measurementName = 'Ounces';
  static suffix = 'oz';

  constructor(value: number) {
    super(Ounces.system, Ounces.measurementName, Ounces.suffix, value);
  }

  toBaseMetric() {
    // Approx 1oz is 28350 mg
    return new Milligrams(this.value * 28350);
  }

  toBaseImperial() {
    // No Conversion required as oz is the base value
    return this;
  }

  static fromOther(from: Mass): Ounces {
    if (from.system === 'Imperial') {
      return from.toBaseImperial();
    } else {
      return new Ounces(from.toBaseMetric().value / 28350);
    }
  }
}

export class Pounds extends Mass {
  static system: System = 'Imperial';
  static measurementName = 'Pounds';
  static suffix = 'lb';

  constructor(value: number) {
    super(Pounds.system, Pounds.measurementName, Pounds.suffix, value);
  }

  toBaseMetric() {
    return Milligrams.fromOther(this.toBaseImperial());
  }

  toBaseImperial() {
    // 1 lb = 16 oz
    return new Ounces(this.value * 16);
  }

  static fromOther(from: Mass): Pounds {
    if (from.system === 'Metric') {
      return new Pounds(from.toBaseMetric().toBaseImperial().value / 16);
    } else {
      return new Pounds(from.toBaseImperial().value / 16);
    }
  }
}

export class Stone extends Mass {
  static system: System = 'Imperial';
  static measurementName = 'Stone';
  static suffix = 'st';

  constructor(value: number) {
    super(Stone.system, Stone.measurementName, Stone.suffix, value);
  }

  toBaseMetric() {
    return Milligrams.fromOther(this.toBaseImperial());
  }

  toBaseImperial() {
    // 1 st = 224 oz
    return new Ounces(this.value * 224);
  }

  static fromOther(from: Mass): Stone {
    if (from.system === 'Metric') {
      return new Stone(from.toBaseMetric().toBaseImperial().value / 224);
    } else {
      return new Stone(from.toBaseImperial().value / 224);
    }
  }
}

/** Abstract class to classify all capacity measurements */
abstract class Capacity extends Measurement {
  static type: MeasurementType = 'Capacity';

  constructor(system: System, measurementName: string, suffix: string, value: number) {
    super(system, Capacity.type, measurementName, suffix, value);
  }
  abstract toBaseMetric(): Measurement;
  abstract toBaseImperial(): Measurement;
}

/**
 * Metric Capacities
 */

export class Millilitres extends Capacity {
  static system: System = 'Metric';
  static measurementName = 'Millilitres';
  static suffix = 'ml';

  constructor(value: number) {
    super(Millilitres.system, Millilitres.measurementName, Millilitres.suffix, value);
  }

  toBaseMetric() {
    // No Conversion required as mg is the base value
    return this;
  }

  toBaseImperial() {
    // Approx 1 pint is 568.3 millilitres
    // Students would not be required to make this conversion
    return new Pints(this.value / 568.3);
  }

  static fromOther(from: Capacity): Millilitres {
    if (from.system === 'Metric') {
      return from.toBaseMetric();
    } else {
      return new Millilitres(from.toBaseImperial().value * 568.3);
    }
  }
}

export class Centilitres extends Capacity {
  static system: System = 'Metric';
  static measurementName = 'Centilitres';
  static suffix = 'cl';

  constructor(value: number) {
    super(Centilitres.system, Centilitres.measurementName, Centilitres.suffix, value);
  }
  toBaseMetric() {
    // 10 ml in 1 cl
    return new Millilitres(this.value * 10);
  }

  toBaseImperial() {
    return Pints.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Capacity): Centilitres {
    if (from.system === 'Metric') {
      return new Centilitres(from.toBaseMetric().value / 10);
    } else {
      return new Centilitres(from.toBaseImperial().toBaseMetric().value / 10);
    }
  }
}

export class Litres extends Capacity {
  static system: System = 'Metric';
  static measurementName = 'Litres';
  static suffix = 'l';

  constructor(value: number) {
    super(Litres.system, Litres.measurementName, Litres.suffix, value);
  }
  toBaseMetric() {
    // 1000 ml in 1 l
    return new Millilitres(this.value * 1000);
  }

  toBaseImperial() {
    return Pints.fromOther(this.toBaseMetric());
  }

  static fromOther(from: Capacity): Litres {
    if (from.system === 'Metric') {
      return new Litres(from.toBaseMetric().value / 1000);
    } else {
      return new Litres(from.toBaseImperial().toBaseMetric().value / 1000);
    }
  }
}

/**
 * Imperial Capacities
 */

export class Pints extends Capacity {
  static system: System = 'Imperial';
  static measurementName = 'Pints';
  static suffix = 'pt';

  constructor(value: number, measurementName?: string) {
    super(Pints.system, measurementName ?? Pints.measurementName, Pints.suffix, value);
  }

  toBaseMetric() {
    // Approx 1oz is 28350 mg
    return new Milligrams(this.value * 568.3);
  }

  toBaseImperial() {
    // No Conversion required as oz is the base value
    return this;
  }

  static fromOther(from: Capacity): Pints {
    if (from.system === 'Imperial') {
      return from.toBaseImperial();
    } else {
      return new Pints(from.toBaseMetric().value / 568.3);
    }
  }
}

export class Gallons extends Capacity {
  static system: System = 'Imperial';
  static measurementName = 'Gallons';
  static suffix = 'gal';

  constructor(value: number, measurementName?: string) {
    super(Gallons.system, measurementName ?? Gallons.measurementName, Gallons.suffix, value);
  }

  toBaseMetric() {
    return Millilitres.fromOther(this.toBaseImperial());
  }

  toBaseImperial() {
    // 1 gal = 8 pts
    return new Pints(this.value * 8);
  }

  static fromOther(from: Capacity): Gallons {
    if (from.system === 'Metric') {
      return new Gallons(from.toBaseMetric().toBaseImperial().value / 8);
    } else {
      return new Gallons(from.toBaseImperial().value / 8);
    }
  }
}

/** Abstract class to classify all volume measurements */
abstract class Volume extends Measurement {
  static type: MeasurementType = 'Volume';

  constructor(system: System, measurementName: string, suffix: string, value: number) {
    super(system, Volume.type, measurementName, suffix, value);
  }

  abstract toBaseMetric(): Measurement;
  toBaseImperial(): Measurement {
    throw new Error('Volumes do not have imperial measures: ');
  }
}

export class CubicMillimetres extends Volume {
  static system: System = 'Metric';
  static measurementName = 'Cubic Millimetres';
  static suffix = 'mm³';

  constructor(value: number) {
    super(
      CubicMillimetres.system,
      CubicMillimetres.measurementName,
      CubicMillimetres.suffix,
      value
    );
  }
  toBaseMetric() {
    return this;
  }

  static fromOther(from: Capacity): CubicMillimetres {
    return from.toBaseMetric();
  }
}

export class CubicCentimetres extends Volume {
  static system: System = 'Metric';
  static measurementName = 'Cubic Centimetres';
  static suffix = 'cm³';

  constructor(value: number) {
    super(
      CubicCentimetres.system,
      CubicCentimetres.measurementName,
      CubicCentimetres.suffix,
      value
    );
  }
  toBaseMetric() {
    return new CubicMillimetres(this.value * 1000);
  }

  static fromOther(from: Capacity): CubicCentimetres {
    return new CubicCentimetres(from.toBaseMetric().value / 1000);
  }
}

export class CubicMetres extends Volume {
  static system: System = 'Metric';
  static measurementName = 'Cubic Metres';
  static suffix = 'm³';

  constructor(value: number) {
    super(CubicMetres.system, CubicMetres.measurementName, CubicMetres.suffix, value);
  }
  toBaseMetric() {
    return new CubicMillimetres(this.value * 1000000000);
  }

  static fromOther(from: Capacity): CubicMetres {
    return new CubicMetres(from.toBaseMetric().value / 1000000000);
  }
}

export class CubicKilometres extends Volume {
  static system: System = 'Metric';
  static measurementName = 'Cubic Kilometres';
  static suffix = 'km³';

  constructor(value: number) {
    super(CubicKilometres.system, CubicKilometres.measurementName, CubicKilometres.suffix, value);
  }
  toBaseMetric() {
    return new CubicMillimetres(this.value * 1000000000000000000);
  }

  static fromOther(from: Capacity): CubicKilometres {
    return new CubicKilometres(from.toBaseMetric().value / 1000000000000000000);
  }
}

export const lengths = [Millimetres, Centimetres, Metres, Kilometres, Inches, Feet, Yards, Miles];

export const weights = [Milligrams, Grams, Kilograms, Tonnes, Ounces, Pounds, Stone];

export const capacities = [Millilitres, Centilitres, Litres, Pints, Gallons];

export const volumes = [CubicMillimetres, CubicCentimetres, CubicMetres, CubicKilometres];

/** An array of measurements that allows us to filter on the static properties */
export const measurements = [...lengths, ...weights, ...capacities, ...volumes];

/** Function to convert from an instantiated Measurement to another Measurement */
export function convert(from: Measurement, to: string): Measurement {
  const convertTo = measurements.find(
    measurement => measurement.suffix === to || measurement.measurementName === to
  );

  if (!convertTo) {
    throw new Error('Invalid measurement unit: ' + to);
  }

  if (from.type !== convertTo.type) {
    throw new Error('Invalid conversion attempt: ' + from.type + ' to ' + convertTo.type);
  }

  return convertTo.fromOther(from);
}

/** Function to convert units using numbers and unit strings only */
export function convertUnitsSuffix(from: number, fromUnits: string, toUnits: string): Measurement {
  const convertFrom = measurements.find(measurement => measurement.suffix === fromUnits);
  const convertTo = measurements.find(measurement => measurement.suffix === toUnits);

  if (!convertFrom) {
    throw new Error('Invalid measurement unit: ' + fromUnits);
  }

  if (!convertTo) {
    throw new Error('Invalid measurement unit: ' + toUnits);
  }

  if (convertFrom.type !== convertTo.type) {
    throw new Error('Invalid conversion attempt: ' + convertFrom.type + ' to ' + convertTo.type);
  }

  return convertTo.fromOther(new convertFrom(from));
}

/** Create a measurement instance from given value and measurement name or suffix */
export function createMeasurement(value: number, units: string): Measurement {
  const found = measurements.find(
    measurement => measurement.suffix === units || measurement.measurementName === units
  );

  if (!found) {
    throw new Error('Invalid measurement unit: ' + units);
  }

  return new found(value);
}

/** Example usage
const miles = new Miles(1);
console.log(convert(miles, 'km').value);
console.log(convertUnitsSuffix(1, 'mi', 'km').value);


const kilometres = new Kilometres(1);
console.log(convert(kilometres, 'mi').value);
*/
