import { equal, smallerEq } from 'mathjs';
import { MeasureView } from '../../atoms/MeasureView';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from '../../molecules/TitleRow';
import DrawLine, { DrawLineWithState } from '../representations/Measurement/DrawLine';
import ItemsAgainstRuler from '../representations/Measurement/ItemsAgainstRuler';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle: string;
  rulerKind: 'cm' | 'mm';
  rulerLength: number;
  testCorrect: number | ((userAnswer: number) => boolean);
  /** Smallest interval that the pencil can be dragged to. Default: no snapping. */
  snapToNearest?: number;
  /** How far you can be from the correct answer. Default: 0 */
  wiggleRoom?: number;
  /**
   * Optional custom mark scheme answer.
   * *Required* if testCorrect is a function
   */
  customMarkSchemeAnswer?: { answerToDisplay: number; answerText?: string };
};

export default function QF43DraggableLineOnTopOfStaticRuler({
  title,
  pdfTitle,
  rulerKind,
  rulerLength,
  testCorrect: testCorrectProp,
  snapToNearest,
  wiggleRoom = 0,
  customMarkSchemeAnswer
}: Props) {
  if (typeof testCorrectProp === 'function' && customMarkSchemeAnswer === undefined) {
    throw new Error(
      'testCorrect is a function, so you must provide the customMarkSchemeAnswer prop'
    );
  }

  const displayMode = useContext(DisplayMode);

  const testCorrect = useMemo(
    () =>
      typeof testCorrectProp === 'function'
        ? testCorrectProp
        : (ans: number) =>
            wiggleRoom === 0
              ? (equal(ans, testCorrectProp) as boolean)
              : (smallerEq(Math.abs(ans - testCorrectProp), wiggleRoom) as boolean),
    [testCorrectProp, wiggleRoom]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <ItemsAgainstRuler
                  width={dimens.width}
                  height={dimens.height}
                  rulerKind={rulerKind}
                  rulerLength={rulerLength}
                  items={[
                    {
                      length: rulerLength,
                      imageInfo: (_pixelLength, pixelsPerUnit) => ({
                        crossAxisLength: 122,
                        image:
                          displayMode === 'pdf' ? (
                            <></>
                          ) : (
                            <DrawLine
                              lineLength={
                                typeof testCorrectProp === 'number'
                                  ? testCorrectProp
                                  : // Default to 0 to stop TypeScript complaining
                                    // *REMINDER* to pass this in if you are using a testCorrect function
                                    customMarkSchemeAnswer?.answerToDisplay ?? 0
                              }
                              pixelsPerUnit={pixelsPerUnit}
                              maxLength={rulerLength}
                              snapToNearest={snapToNearest}
                            />
                          )
                      })
                    }
                  ]}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="bottom"
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <ItemsAgainstRuler
              width={dimens.width}
              height={dimens.height}
              rulerKind={rulerKind}
              rulerLength={rulerLength}
              items={[
                {
                  length: rulerLength,
                  imageInfo: (_pixelLength, pixelsPerUnit) => ({
                    crossAxisLength: 122,
                    image: (
                      <DrawLineWithState
                        id="drawLine"
                        defaultState={0}
                        testCorrect={testCorrect}
                        pixelsPerUnit={pixelsPerUnit}
                        maxLength={rulerLength}
                        snapToNearest={snapToNearest}
                      />
                    )
                  })
                }
              ]}
            />
          )}
        </MeasureView>
      }
    />
  );
}
