import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getItemArrangement,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { filledArray, range } from '../../../../utils/collections';
import { compareFractions } from '../../../../utils/fractions';
import { compareRatios } from '../../../../utils/ratios';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { arrayObjectAsWord } from '../../../../utils/arrayObjects';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { colorsAsWord } from '../../../../utils/colors';
import { barColorNames, barColorsNamesArray, barColorsNamesSchema } from '../../../../theme/colors';
import { getMultiLinkCubeSvgName } from '../../../../utils/multiLinkCubesImages';
import { BarModel } from '../../../../components/question/representations/BarModel';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aS4',
  description: 'aS4',
  keywords: ['Fraction', 'Ratio', 'Bar model'],
  schema: z.object({
    shaded: z.number().int().min(1).max(9),
    denominator: z.number().int().min(5).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 10);
    const shaded = randomIntegerInclusive(1, denominator - 1);

    return { denominator, shaded };
  },
  Component: props => {
    const {
      question: { denominator, shaded },
      translate
    } = props;

    const unshaded = denominator - shaded;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.ratioOfShadedToUnshadedIsAns(),
          translate.answerSentences.fractionShadedIsAns()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          compareRatios([userAnswer[0][0], userAnswer[0][1]], [shaded, unshaded]) &&
          (compareFractions([userAnswer[1][0], userAnswer[1][1]], [unshaded, denominator]) ||
            compareFractions([userAnswer[1][0], userAnswer[1][1]], [shaded, denominator]))
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width - 100}
            coloredSections={range(0, shaded - 1)}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [shaded.toLocaleString(), unshaded.toLocaleString()],
            [shaded.toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractionsRatios()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aS5',
  description: 'aS5',
  keywords: ['Fraction', 'Ratio', 'Counters'],
  questionHeight: 1250,
  schema: z
    .object({
      number1: z.number().int().min(1).max(9),
      number2: z.number().int().min(1).max(9),
      counterColours: z.array(z.enum(['Blue', 'Green', 'Pink', 'Purple', 'Yellow'])).length(2),
      number3: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.number1 + val.number2 <= 10 && val.number1 + val.number2 >= 5,
      'sum of number1 and number2 should be greater than 4 and less than 11'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const min = 5 - number1 <= 0 ? 1 : 5 - number1;
    const max = Math.min(10 - number1, 9);
    const number2 = randomIntegerInclusive(min, max);
    const number3 = getRandomFromArray([number1, number2]);

    const counterColours = getRandomSubArrayFromArray(
      ['Blue', 'Green', 'Pink', 'Purple', 'Yellow'] as const,
      2
    );

    return { number1, number2, counterColours, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, counterColours, number3 },
      translate
    } = props;

    const generateCounters = (counterIndex: number, number: number) => {
      return range(0, number - 1).map(i => {
        const name = `Circles/circle_${counterColours[counterIndex].toLowerCase()}` as SvgName;
        return <AssetSvg key={`${counterColours[counterIndex]}-${i}`} name={name} width={60} />;
      });
    };

    const counters1 = generateCounters(0, number1);
    const counters2 = generateCounters(1, number2);

    const nameColour1 = colorsAsWord(counterColours[0], translate);
    const nameColour2 = colorsAsWord(counterColours[1], translate);

    const counters = [...counters1, ...counters2];
    const arrangement = getItemArrangement(counters, seededRandom(props.question), 2, 8);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.ratioOfXtoYIsAns(nameColour1, nameColour2),
          translate.answerSentences.fractionfXtoYCountersIs(
            number3 === number1 ? nameColour1 : nameColour2
          )
        ]}
        questionHeight={1250}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          compareRatios([userAnswer[0][0], userAnswer[0][1]], [number1, number2]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [number3, number1 + number2])
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => {
          return arrangement.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                height: dimens.height / 2,
                width: dimens.width
              }}
            >
              {row.map(({ marginLeft, item }, itemIndex) => (
                <View key={itemIndex} style={{ marginLeft, gap: 16 }}>
                  {item}
                </View>
              ))}
            </View>
          ));
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number1.toLocaleString(), number2.toLocaleString()],
            [number3.toLocaleString(), (number1 + number2).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractionsRatios()
        }}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aS6',
  description: 'aS6',
  keywords: ['Fraction', 'Ratio', 'Bar model'],
  questionHeight: 900,
  schema: z
    .object({
      shaded1: z.number().int().min(1).max(8),
      shaded2: z.number().int().min(1).max(8),
      shaded3: z.number().int().min(1).max(8),
      colors: z.array(barColorsNamesSchema).length(2)
    })
    .refine(
      val =>
        val.shaded1 + val.shaded2 + val.shaded3 >= 5 &&
        val.shaded1 + val.shaded2 + val.shaded3 <= 10,
      'sum of shaded1, shaded2, shaded3 should be greater than 4 and less than 11'
    ),
  simpleGenerator: () => {
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2);
    const { shaded1, shaded2, shaded3 } = rejectionSample(
      () => {
        const shaded1 = randomIntegerInclusive(1, 8);
        const shaded2 = randomIntegerInclusive(1, 8);
        const shaded3 = randomIntegerInclusive(1, 8);

        return { shaded1, shaded2, shaded3 };
      },
      val =>
        val.shaded1 + val.shaded2 + val.shaded3 >= 5 &&
        val.shaded1 + val.shaded2 + val.shaded3 <= 10
    );
    return { colors, shaded1, shaded2, shaded3 };
  },
  Component: props => {
    const {
      question: { colors, shaded1, shaded2, shaded3 },
      translate,
      displayMode
    } = props;

    const denominator = shaded1 + shaded2 + shaded3;

    const [color1, color2] = colors;

    const colourArray1 = filledArray(barColorNames[color1], shaded1);
    const colourArray2 = filledArray(barColorNames[color2], shaded2);
    const colourArray3 = filledArray('white', shaded3);
    const colourArray = [...colourArray1, ...colourArray2, ...colourArray3];

    const nameColour1 = colorsAsWord(colors[0], translate);
    const nameColour2 = colorsAsWord(colors[1], translate);
    const nameColour3 = colorsAsWord('White', translate);

    const labels = [
      ...filledArray(translate.acronyms[colors[0]](), shaded1),
      ...filledArray(translate.acronyms[colors[1]](), shaded2),
      ...filledArray(translate.acronyms.White(), shaded3)
    ];

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.colourFractionAns(nameColour1),
          translate.answerSentences.colourFractionAns(nameColour2),
          translate.answerSentences.colourFractionAns(nameColour3)
        ]}
        questionHeight={900}
        style={{ flexDirection: 'row' }}
        title={translate.instructions.barModelShowsRatioCompleteFractions(
          `${shaded1} : ${shaded2} : ${shaded3}`
        )}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], userAnswer[0][1]], [shaded1, denominator]) &&
          compareFractions([userAnswer[1][0], userAnswer[1][1]], [shaded2, denominator]) &&
          compareFractions([userAnswer[2][0], userAnswer[2][1]], [shaded3, denominator])
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [shaded1.toLocaleString(), denominator.toLocaleString()],
            [shaded2.toLocaleString(), denominator.toLocaleString()],
            [shaded3.toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={[filledArray(1, denominator)]}
            dimens={dimens}
            total={denominator}
            strings={[labels]}
            cellColors={[colourArray]}
            maxFontSize={displayMode === 'digital' ? 32 : 50}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aS7',
  description: 'aS7',
  keywords: ['Ratio', 'Fraction'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(7),
      number2: z.number().int().min(3).max(7),
      name: nameSchema,
      multiLinkColors: z.array(z.enum(['Orange', 'Blue', 'Purple', 'Green', 'Red'])).length(2),
      statementOption: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.number1 + val.number2 >= 5 && val.number1 + val.number2 <= 10,
      'sum of number1 and number2 should be greater than 4 and less than 11'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 7);
    const min = 5 - number1 <= 2 ? 3 : 5 - number1;
    const max = Math.min(10 - number1, 7);
    const number2 = randomIntegerInclusive(min, max);

    const name = getRandomName();
    const multiLinkColors = getRandomSubArrayFromArray(
      ['Orange', 'Blue', 'Purple', 'Green', 'Red'] as const,
      2
    );

    const statementOption = randomIntegerInclusive(1, 9);

    return { number1, number2, name, multiLinkColors, statementOption };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, name, multiLinkColors, statementOption } = question;

    const number3 = number1 + number2;

    const color1Word = colorsAsWord(multiLinkColors[0], translate);
    const color2Word = colorsAsWord(multiLinkColors[1], translate);

    const image1 = getMultiLinkCubeSvgName(multiLinkColors[0], number1);
    const image2 = getMultiLinkCubeSvgName(multiLinkColors[1], number2);

    let statement: string;
    switch (statementOption) {
      case 1:
        statement = translate.answerSentences.ratioOfXtoYIsZ(
          color1Word,
          color2Word,
          `${number1.toLocaleString()} : ${number2.toLocaleString()}`
        );
        break;
      case 2:
        statement = translate.answerSentences.ratioOfXtoYIsZ(
          color2Word,
          color1Word,
          `${number2.toLocaleString()} : ${number1.toLocaleString()}`
        );
        break;
      case 3:
        statement = translate.answerSentences.ratioOfXtoYIsZ(
          color1Word,
          color2Word,
          `${number2.toLocaleString()} : ${number1.toLocaleString()}`
        );
        break;
      case 4:
        statement = translate.answerSentences.ratioOfXtoYIsZ(
          color1Word,
          color2Word,
          `${number1.toLocaleString()} : ${number3.toLocaleString()}`
        );
        break;
      case 5:
        statement = translate.answerSentences.fractionOfColour(
          color1Word,
          `<frac n='${number1.toLocaleString()}' d='${number3.toLocaleString()}' />`
        );
        break;
      case 6:
        statement = translate.answerSentences.fractionOfColour(
          color2Word,
          `<frac n='${number2.toLocaleString()}' d='${number3.toLocaleString()}' />`
        );
        break;
      case 7:
        statement = translate.answerSentences.fractionOfColour(
          color1Word,
          `<frac n='${number2.toLocaleString()}' d='${number3.toLocaleString()}' />`
        );
        break;
      case 8:
        statement = translate.answerSentences.fractionOfColour(
          color2Word,
          `<frac n='${number1.toLocaleString()}' d='${number3.toLocaleString()}' />`
        );
        break;
      case 9:
        statement = translate.answerSentences.fractionOfColour(
          color1Word,
          `<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}' />`
        );
        break;
      default:
        break;
    }

    const answer = [1, 2, 5, 6].includes(statementOption) ? true : false;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isCharacterCorrect(name)}
        pdfTitle={translate.instructions.isCharacterCorrectPDF(name)}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={({ dimens }) => (
          <View style={{ rowGap: 16, alignItems: 'center' }}>
            <View
              style={{
                transform: 'rotate(90deg)',
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                width: dimens.width
              }}
            >
              <View style={{ width: dimens.width * 0.1, paddingBottom: 48 }}>
                <View style={{ top: displayMode === 'digital' ? 48 : 90, zIndex: 2 }}>
                  <AssetSvg name={image1} width={dimens.width * 0.1} />
                </View>
                <AssetSvg name={image2} width={dimens.width * 0.1} style={{ zIndex: 1 }} />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'stretch'
              }}
            >
              <SpeechBubble
                flickLocation="bottom-right"
                style={{
                  maxWidth: 0.45 * dimens.width,
                  marginHorizontal: 0.05 * dimens.width,
                  maxHeight: 0.3 * dimens.height,
                  marginVertical: 0.175 * dimens.height,
                  top: -20,
                  zIndex: 1
                }}
              >
                {statement}
              </SpeechBubble>

              <View
                style={{ alignSelf: 'flex-end', marginRight: 20, marginVertical: 20, zIndex: 0 }}
              >
                <AssetSvg name={getCharacterHeadSvgName(name)} height={dimens.height * 0.4} />
              </View>
            </View>
          </View>
        )}
        correctAnswer={answer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aS8',
  description: 'aS8',
  keywords: ['Fraction', 'Ratio'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(9),
      number2: z.number().int().min(1).max(9),
      answerObject: z.enum(['FeltTip', 'Pencil'])
    })
    .refine(
      val => val.number1 + val.number2 >= 5 && val.number1 + val.number2 <= 12,
      'sum of number1 and number2 should be greater than 4 and less than 13'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const min = 5 - number1 <= 0 ? 1 : 5 - number1;
    const max = Math.min(12 - number1, 9);
    const number2 = randomIntegerInclusive(min, max);

    const answerObject = getRandomFromArray(['FeltTip', 'Pencil'] as const);

    return { number1, number2, answerObject };
  },

  Component: props => {
    const {
      question: { number1, number2, answerObject },
      translate
    } = props;

    const objectPlural1 = translate.objects.Pencils();
    const objectPlural2 = translate.objects.FeltTips();
    const answerString = answerObject === 'Pencil' ? objectPlural1 : objectPlural2;
    const numerator = answerObject === 'Pencil' ? number1 : number2;
    const denominator = number1 + number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.pencilCaseHasFractionXWhatIsRatio(
          `<frac n='${numerator}' d='${denominator}' />`,
          answerString
        )}
        testCorrect={userAnswer =>
          compareRatios([userAnswer[0], userAnswer[1]], [numerator, denominator - numerator])
        }
        inputMaxCharacters={2}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            numerator.toLocaleString(),
            (denominator - numerator).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        sentence={`<ans/> : <ans/>`}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aS9',
  description: 'aS9',
  keywords: ['Fraction', 'Ratio'],
  schema: z
    .object({
      fruits: z.array(z.enum(['Green apple', 'Banana', 'Orange', 'Pear'])).length(2),
      number1: z.number().int().min(1).max(9),
      number2: z.number().int().min(1).max(9),
      answerFruit: z.enum(['A', 'B'])
    })
    .refine(
      val => val.number1 + val.number2 >= 5 && val.number1 + val.number2 <= 12,
      'sum of number1 and number2 should be greater than 4 and less than 13'
    ),
  simpleGenerator: () => {
    const fruits = getRandomSubArrayFromArray(
      ['Green apple', 'Banana', 'Orange', 'Pear'] as const,
      2
    );
    const number1 = randomIntegerInclusive(1, 9);
    const min = 5 - number1 <= 0 ? 1 : 5 - number1;
    const max = Math.min(12 - number1, 9);
    const number2 = randomIntegerInclusive(min, max);

    const answerFruit = getRandomFromArray(['A', 'B'] as const);

    return { fruits, number1, number2, answerFruit };
  },

  Component: props => {
    const {
      question: { fruits, number1, number2, answerFruit },
      translate
    } = props;

    const fruitAString = arrayObjectAsWord(fruits[0], translate, true);
    const fruitBString = arrayObjectAsWord(fruits[1], translate, true);
    const answerFruitString = answerFruit === 'A' ? fruitAString : fruitBString;
    const numerator = answerFruit === 'A' ? number1 : number2;
    const denominator = number1 + number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.ratioOfFruitsIsxWhatIsFractionOfY(
          fruitAString,
          fruitBString,
          `${number1.toLocaleString()} : ${number2.toLocaleString()}`,
          answerFruitString
        )}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [numerator, denominator])
        }
        inputMaxCharacters={2}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentence={`<frac nAns='' dAns='' />`}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RatioAndFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
