import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { ADD, SUB } from 'common/src/constants';
import { countRange, filledArray, rangeAsString } from 'common/src/utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { isEqual, isInRange } from '../../../../utils/matchers';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { View } from 'react-native';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhq',
  description: 'bhq',
  keywords: ['Add', 'Subtract', 'Number line'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number2: z.number().int().min(1).max(9),
    isAddition: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99);
    const number2 = randomIntegerInclusive(1, 9);

    const isAddition = getRandomBoolean();

    return { number1, number2, isAddition };
  },
  Component: props => {
    const {
      question: { number1, number2, isAddition },
      translate
    } = props;

    const startingNumber = isAddition ? number1 - 1 : number1 - 9;

    // Array of tick values for number line
    const tickValues = rangeAsString(startingNumber, startingNumber + 10, 1, true);

    const numAnswer = isAddition ? number1 + number2 : number1 - number2;
    const { sentence, answer } = buildSimpleNumberSentence(
      [number1, number2, numAnswer],
      isAddition ? ADD : SUB,
      2
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={sentence}
        title={translate.ks1Instructions.completeTheNumberSentence()}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhr',
  description: 'bhr',
  keywords: ['Add', 'Subtract', 'Base 10'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(10).max(99),
      isAddition: z.boolean()
    })
    .refine(
      val =>
        isInRange(1, 99)(val.isAddition ? val.number1 + val.number2 : val.number1 - val.number2),
      'answer must be between 1 and 99'
    ),
  simpleGenerator: () => {
    const isAddition = getRandomBoolean();
    const number1 = randomIntegerInclusive(11, isAddition ? 89 : 99);
    const number2 = randomIntegerInclusive(10, 99, {
      constraint: x => (isAddition ? x + number1 <= 99 : number1 - x >= 1)
    });

    return { number1, number2, isAddition };
  },
  Component: props => {
    const {
      question: { number1, number2, isAddition },
      translate
    } = props;

    const numAnswer = isAddition ? number1 + number2 : number1 - number2;
    const { sentence, answer } = buildSimpleNumberSentence(
      [number1, number2, numAnswer],
      isAddition ? ADD : SUB,
      2
    );

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={number1 % 10}
            tens={Math.floor(number1 / 10)}
            dimens={{ width: dimens.width, height: dimens.height }}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bhs',
  description: 'bhs',
  keywords: ['Add', 'Subtract', 'Bar model'],
  schema: z.object({
    ansPosition: z.enum(['left', 'right', 'leftTop', 'rightTop', 'top', 'bottom']),
    number1: z.number().int().min(11).max(100),
    number2: z.number().int().min(1).max(99),
    isArrow: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 100);
    const number2 = randomIntegerInclusive(1, number1 - 1);

    const ansPosition = getRandomFromArray([
      'left',
      'right',
      'leftTop',
      'rightTop',
      'top',
      'bottom'
    ] as const);
    const isArrow = ['left', 'top'].includes(ansPosition) ? getRandomBoolean() : false;

    return {
      number1,
      number2,
      ansPosition,
      isArrow
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, ansPosition, isArrow } = question;

    const [topRow, bottomRow] = [[number1], [number2, number1 - number2]];

    const [rowIndex, colIndex, answerIndices, arrowIndices, numbers] = (() => {
      switch (ansPosition) {
        case 'bottom':
          return [1, 0, [[], [0]], [], [bottomRow, topRow]];
        case 'left':
          return [1, 0, [[], [0]], [[1]], [topRow, bottomRow]];
        case 'leftTop':
          return [0, 0, [[0], []], [], [bottomRow, topRow]];
        case 'right':
          return [1, 1, [[], [1]], [], [topRow, bottomRow]];
        case 'rightTop':
          return [0, 1, [[1], []], [], [bottomRow, topRow]];
        case 'top':
          return [0, 0, [[0], []], [[1]], [topRow, bottomRow]];
      }
    })();

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheBarModel()}
        actionPanelVariant="endWide"
        inputType="numpad"
        questionHeight={700}
        Content={({ dimens }) => {
          return isArrow ? (
            <View style={{ gap: 50 }}>
              {countRange(2).map(val => (
                <BarModelWithState
                  key={val}
                  id={`bar-model-${val}`}
                  numbers={[numbers[val]]}
                  answerIndices={rowIndex === val ? [[colIndex]] : undefined}
                  total={number1}
                  dimens={dimens}
                  maxFontSize={displayMode === 'digital' ? 32 : 50}
                  oneFontSize
                  testCorrect={
                    rowIndex === val
                      ? userAnswer =>
                          isEqual(numbers[rowIndex][colIndex].toString())(userAnswer[0][colIndex])
                      : undefined
                  }
                  arrowIndices={arrowIndices}
                  testComplete={
                    rowIndex === val ? userAnswer => userAnswer[0][colIndex] !== '' : undefined
                  }
                  defaultState={
                    displayMode === 'markscheme'
                      ? numbers[rowIndex].map((val, id) =>
                          id === colIndex ? [val.toLocaleString()] : ['']
                        )
                      : [filledArray('', numbers[rowIndex].length)]
                  }
                />
              ))}
            </View>
          ) : (
            <BarModelWithState
              id="bar-model-A"
              numbers={numbers}
              answerIndices={answerIndices}
              total={number1}
              dimens={dimens}
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              oneFontSize
              testCorrect={userAnswer =>
                isEqual(numbers[rowIndex][colIndex].toString())(userAnswer[rowIndex][colIndex])
              }
              testComplete={userAnswer =>
                answerIndices.every((row, rowIndex) =>
                  row.every(pos => {
                    const userString = (userAnswer[rowIndex] ?? [])[pos] ?? '';
                    return userString !== '';
                  })
                )
              }
              defaultState={
                displayMode === 'markscheme'
                  ? numbers.map(row =>
                      row.map((val, colId) => (colId === colIndex ? val.toLocaleString() : ''))
                    )
                  : [filledArray('', numbers[0].length), filledArray('', numbers[1].length)]
              }
            />
          );
        }}
      />
    );
  },
  questionHeight: 700
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MixedAdditionAndSubtraction',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
