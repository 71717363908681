import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { Theme, useTheme } from '../../theme';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import { DraggableEventData, Droppable } from '../draganddrop/draganddrop';
import Text from '../typography/Text';
import DragAndDropSource from './DragAndDropSource';
import { DRAGGABLE_DIMENS, DraggableVariant } from './utils';
import { DisplayMode } from '../../contexts/displayMode';

type Props<Payload> = {
  variant?: DraggableVariant;
  channel?: string | number;
  draggable?: {
    id: string | number;
    payload: Payload;
    element: string | ElementOrRenderFunction;
  };
  onDropInto: (event: { draggable: DraggableEventData }) => boolean | void;
  onDraggedToNowhere: () => boolean | void;
  placeholder?: string | ElementOrRenderFunction;
  style?: StyleProp<ViewStyle>;
  draggableStyle?: StyleProp<ViewStyle>;

  // Props for customizing how AutoScaleText works when providing strings as the draggables' element.
  textStyle?: StyleProp<TextStyle>;
  textVariant?: keyof Theme['fonts'];
  /** If you want auto text scaling, provide this or textSizeAutoScaleGroup, but not both. */
  textScaleToLongestLength?: number;
  /** If you want auto text scaling, provide this or textScaleToLongestLength, but not both. */
  textSizeAutoScaleGroup?: number | string;
  textLetterEmWidth?: number;
  maxLines?: number;
};

/**
 * A variant of the {@link Droppable} component, specialized to our UI spec, which contains 1 or 0 draggable items.
 *
 * This component makes it easier to work with drag and drop, than using {@link Draggable} and {@link Droppable}
 * directly. Should be used in conjunction with {@link DragAndDropSource}.
 *
 * This component shows a square or rectangular, fixed-size draggable piece. The default styling matches the UI design
 * for drop zones.
 *
 * You still need to provide information about what draggable to display (which should be some state managed by
 * something else) and the draggable component itself to display (which may be simply a string), as well as a callback
 * for what to do when that draggable is dragged out of the drop zone (but not into another drop zone). You also need
 * to provide a callback for what to do when a different draggable is dragged into this drop zone.
 *
 * In the event that the draggable item's element is simply a string, you can either use the default font size,
 * or auto text scaling with the `textSizeAutoScaleGroup` prop. Also, all of the styles can be overridden.
 */
export default function DragAndDropZoneSingle<Payload>({
  variant = 'square',
  channel = 0,
  draggable,
  onDropInto,
  onDraggedToNowhere,
  placeholder,
  style,
  draggableStyle,
  textStyle,
  textVariant,
  textScaleToLongestLength,
  textSizeAutoScaleGroup,
  textLetterEmWidth,
  maxLines = 1
}: Props<Payload>) {
  const styles = useStyles(variant);

  return (
    <Droppable
      channel={channel}
      onDropInto={onDropInto}
      style={[styles.droppable, style]}
      enteredStyle={styles.hoveredOver}
    >
      {placeholder !== undefined && (
        <View style={[StyleSheet.absoluteFill, styles.placeholderContainer]}>
          {typeof placeholder === 'string' ? (
            <Text style={styles.placeholder}>{placeholder}</Text>
          ) : (
            resolveElementOrRenderFunction(placeholder)
          )}
        </View>
      )}
      {draggable !== undefined && (
        // Show draggable in this dropzone
        <DragAndDropSource
          variant={variant}
          payload={draggable.payload}
          id={draggable.id}
          moveOrCopy="move"
          channel={channel}
          hideBackground
          onDraggedToNowhere={() => onDraggedToNowhere()}
          draggableStyle={draggableStyle}
          textStyle={textStyle}
          textVariant={textVariant}
          textScaleToLongestLength={textScaleToLongestLength}
          textSizeAutoScaleGroup={textSizeAutoScaleGroup}
          textLetterEmWidth={textLetterEmWidth}
          maxLines={maxLines}
        >
          {draggable.element}
        </DragAndDropSource>
      )}
    </Droppable>
  );
}

DragAndDropZoneSingle.RECTANGLE_WIDTH = 320 as const;
DragAndDropZoneSingle.SHORT_RECTANGLE_WIDTH = 160 as const;
DragAndDropZoneSingle.SQUARE_WIDTH = 96 as const;
DragAndDropZoneSingle.HEIGHT = 96 as const;

function useStyles(variant: DraggableVariant) {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();

  return useMemo(() => {
    const { width: draggableWidth, height: draggableHeight } = DRAGGABLE_DIMENS[variant];

    return StyleSheet.create({
      droppable: {
        width: draggableWidth,
        height: draggableHeight,
        borderRadius: 8,
        borderWidth: theme.buttonBorderWidth,
        borderStyle: displayMode === 'digital' ? 'dashed' : 'solid',
        backgroundColor:
          displayMode === 'digital' ? theme.colors.tertiaryContainer : theme.colors.background,
        borderColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.pdfPrimary,
        alignItems: 'center',
        justifyContent: 'center'
      },

      hoveredOver: {
        backgroundColor: theme.colors.tertiaryContainerHovered
      },

      placeholderContainer: {
        justifyContent: 'center',
        alignItems: 'center'
      },

      placeholder: {
        fontSize: 32,
        lineHeight: 48,
        fontWeight: '700',
        color: theme.colors.placeholder
      }
    });
  }, [
    displayMode,
    theme.buttonBorderWidth,
    theme.colors.background,
    theme.colors.pdfPrimary,
    theme.colors.placeholder,
    theme.colors.tertiary,
    theme.colors.tertiaryContainer,
    theme.colors.tertiaryContainerHovered,
    variant
  ]);
}
