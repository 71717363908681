import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  counterVariantSchema,
  counterVariants
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomNumberPairs,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, filledArray, rangeAsString } from '../../../../utils/collections';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { SUB } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { TenFrameTapToCrossOutWithState } from '../../../../components/question/representations/TenFrame/TenFrameTapToCrossOut';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { isEqualUnordered } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgZ',
  description: 'bgZ',
  keywords: ['Subtract', 'Cross out', 'Take away', 'Counters', 'Ten frame'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(18),
      numB: z.number().int().min(2).max(9),
      color: counterVariantSchema
    })
    .refine(val => val.numA - val.numB < 10, 'Subtraction answer must be less than 10'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 18);
    const numB = randomIntegerInclusive(2, 9, { constraint: x => numA - x < 10 });
    const color = getRandomFromArray(counterVariants);

    return { numA, numB, color };
  },
  Component: props => {
    const {
      question: { numA, numB, color },
      translate,
      displayMode
    } = props;

    const tenFrameA = filledArray(color, 10);
    const tenFrameB = filledArray(color, numA - 10);

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA - numB], SUB, 2);

    const instruction = `${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.youCanTapTheCountersToCrossThemOut()}`;
    const pdfInstruction = `${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.useTheTenFramesToHelpYou()}`;

    return (
      <QF1ContentAndSentence
        title={instruction}
        pdfTitle={pdfInstruction}
        Content={
          <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 16 : 32 }}>
            <TenFrameTapToCrossOutWithState
              id="ten-frame-A"
              items={tenFrameA}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
            <TenFrameTapToCrossOutWithState
              id="ten-frame-B"
              items={tenFrameB}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          </View>
        }
        pdfDirection="column"
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bg0',
  description: 'bg0',
  keywords: ['Subtract', 'Number line'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(18),
      numB: z.number().int().min(2).max(9)
    })
    .refine(val => val.numA - val.numB < 10, 'Subtraction answer must be less than 10'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 18);
    const numB = randomIntegerInclusive(2, 9, { constraint: x => numA - x < 10 });

    return { numA, numB };
  },
  Component: props => {
    const {
      question: { numA, numB },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA - numB], SUB, 2);

    const tickValues = rangeAsString(numA - 10, numA + 1, 1, true);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        pdfDirection="column"
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bg1',
  description: 'bg1',
  keywords: ['Subtract', 'Missing numbers'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(18),
      numB: z.number().int().min(2).max(9),
      correctAns: z.array(z.number().int().min(0).max(9)).length(2),
      options: z.array(z.number().int().min(0).max(9)).length(5)
    })
    .refine(val => val.numA - val.numB < 10, 'Subtraction answer must be less than 10'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 18);
    const numB = randomIntegerInclusive(2, 9, { constraint: x => numA - x < 10 });

    // Options
    // 1 + 2. Correct number pair (2 nums) that sums to subtrahend
    // 3. Answer to subtraction e.g. 13 - 4
    // 4. Random number from random number pair that sums to subtrahend
    // 5. Random number between 0 and 9, but can't sum to subtrahend
    const [optionA, optionB, optionC, optionD, optionE] = rejectionSample(
      () => {
        const [randomPairA, randomPairB] = randomNumberPairs(numB, 2);
        const randIndex = randomIntegerInclusive(0, 1);

        const [optionA, optionB] = randomPairA;
        const optionC = numA - numB;
        const optionD = randomPairB[randIndex];
        const optionE = randomIntegerInclusive(0, 9, {
          constraint: x => optionC + x !== numB && optionD + x !== numB
        });

        return [optionA, optionB, optionC, optionD, optionE];
      },
      ([optionA, optionB, optionC, optionD, optionE]) =>
        optionA === optionB
          ? arrayHasNoDuplicates([optionB, optionC, optionD, optionE])
          : arrayHasNoDuplicates([optionA, optionB, optionC, optionD, optionE])
    );

    return {
      numA,
      numB,
      correctAns: [optionA, optionB],
      options: shuffle([optionA, optionB, optionC, optionD, optionE])
    };
  },
  Component: props => {
    const {
      question: { numA, numB, correctAns, options },
      translate
    } = props;

    const sentence = `${numA} ${SUB} ${numB} = ${numA} ${SUB} <ans/> ${SUB} <ans/>`;

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsToCompleteNumberSentence()}
        sentence={sentence}
        items={options}
        testCorrect={isEqualUnordered(correctAns)}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAns],
          answerText: translate.markScheme.answersInAnyOrder()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractAcross10',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
