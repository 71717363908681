import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  filledArray,
  multisetCount,
  nestedArrayHasNoDuplicates,
  sortNumberArray
} from '../../../../utils/collections';
import { isInRange } from '../../../../utils/matchers';
import { TranslationFunctions } from '../../../../i18n/i18n-types';
import { numberEnum } from '../../../../utils/zod';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import { Point2d } from '../../../../utils/vectors';

////
// Questions
////

const shapes = [
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Square',
  'Rectangle',
  'Star',
  'Circle'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Square: [
    'Square/square_pink',
    'Square/square_purple',
    'Square/square_green',
    'Square/square_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_yellow'
  ],
  Circle: [
    'Circles/circle_pink',
    'Circles/circle_purple',
    'Circles/circle_green',
    'Circles/circle_yellow'
  ],
  Star: ['Star_pink', 'Star_purple', 'Star_green', 'Star_yellow']
};

const shapeTranslation = (shape: Shape, translate: TranslationFunctions) => {
  switch (shape) {
    case 'Square':
      return translate.shapes.squares(1);
    case 'Arrow':
      return translate.shapes.arrow();
    case 'Circle':
      return translate.shapes.circles(1);
    case 'Equilateral':
    case 'Isosceles':
      return translate.shapes.triangles(1);
    case 'Rectangle':
      return translate.shapes.rectangles(1);
    case 'Star':
      return translate.shapes.stars(1);
  }
};

const Question1 = newQuestionContent({
  uid: 'blx',
  description: 'blx',
  keywords: ['Left', 'Right', 'Between', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Circle', 'Star'])
      .array()
      .refine(arrayHasNoDuplicates),
    shapeComparisonIndexes: z.number().int().min(0).max(3).array().length(2),
    colorIndexes: z.number().int().min(0).max(3).array().length(4),
    variation: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const shapeOptions = [
      'Arrow',
      'Circle',
      'Star',
      getRandomFromArray(['Equilateral', 'Isosceles'] as const),
      getRandomFromArray(['Square', 'Rectangle'] as const)
    ] as const;
    const numberOfShapes = randomIntegerInclusive(3, 4);
    const shapes = getRandomSubArrayFromArray(shapeOptions, numberOfShapes);

    const variation = randomIntegerInclusive(0, 3);
    const shapeComparisonIndexes =
      variation < 2
        ? randomUniqueIntegersInclusive(0, numberOfShapes - 1, 2)
        : variation === 2
        ? // only need one shape for variation 2 but there must be a shape either side
          filledArray(randomIntegerInclusive(1, numberOfShapes - 2), 2)
        : // get two shapes that have at least one shape between them
          rejectionSample(
            () => randomUniqueIntegersInclusive(0, numberOfShapes - 1, 2),
            val => Math.abs(val[0] - val[1]) > 1
          );

    return {
      shapes: shuffle(shapes),
      shapeComparisonIndexes,
      colorIndexes: shuffle(countRange(4)),
      variation
    };
  },
  Component: props => {
    const {
      question: { shapes, shapeComparisonIndexes, colorIndexes, variation },
      translate
    } = props;

    const isLeft = shapeComparisonIndexes[0] < shapeComparisonIndexes[1];

    const items =
      variation === 0
        ? (['left', 'right'] as const).map(val => ({
            value: val,
            component: <Text variant="WRN700">{translate.ks1MiscStrings.directions[val]()}</Text>
          }))
        : shapes.map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val, translate)}</Text>
          }));

    const testCorrect = (userAnswer: readonly (string | undefined)[]): boolean => {
      const answerIndex0 = shapes.indexOf(
        userAnswer[0] as 'Arrow' | 'Equilateral' | 'Isosceles' | 'Square' | 'Rectangle' | 'Circle'
      );
      switch (variation as 0 | 1 | 2 | 3) {
        case 0:
          return arraysHaveSameContents(userAnswer, [
            shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'left' : 'right'
          ]);
        case 1:
          return (
            (isLeft && answerIndex0 > shapeComparisonIndexes[0]) ||
            (!isLeft && answerIndex0 < shapeComparisonIndexes[0])
          );
        case 2: {
          const answerIndex1 = shapes.indexOf(
            userAnswer[1] as
              | 'Arrow'
              | 'Equilateral'
              | 'Isosceles'
              | 'Square'
              | 'Rectangle'
              | 'Circle'
          );
          // get answers in ascending order to then check shape is between them
          const orderedAnswers = sortNumberArray([answerIndex0, answerIndex1], 'ascending');
          return isInRange(orderedAnswers[0] + 1, orderedAnswers[1] - 1)(shapeComparisonIndexes[0]);
        }
        case 3: {
          // get indexes in ascending order to then check answer is between them
          const orderedIndex = sortNumberArray(shapeComparisonIndexes, 'ascending');
          return isInRange(orderedIndex[0] + 1, orderedIndex[1] - 1)(answerIndex0);
        }
      }
    };

    const shape1 = shapeTranslation(shapes[shapeComparisonIndexes[0]], translate);
    const shape2 = shapeTranslation(shapes[shapeComparisonIndexes[1]], translate);

    const [sentence, markSchemeAnswer] = (() => {
      switch (variation) {
        case 0:
          return [
            translate.ks1AnswerSentences.theShapeIsToTheAnsOfTheShape(shape1, shape2),
            [[shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'left' : 'right']]
          ];
        case 1:
          return [
            isLeft
              ? translate.ks1AnswerSentences.theShapeIsToTheLeftOfAns(shape1)
              : translate.ks1AnswerSentences.theShapeIsToTheRightOfAns(shape1),
            [[shapes[shapeComparisonIndexes[1]]]]
          ];
        case 2:
          return [
            translate.ks1AnswerSentences.theShapeIsBetweenTheAnsAndTheAns(shape1),
            [[shapes[shapeComparisonIndexes[0] - 1], shapes[shapeComparisonIndexes[0] + 1]]]
          ];
        default: {
          const orderedIndex = sortNumberArray(shapeComparisonIndexes, 'ascending');
          return [
            translate.ks1AnswerSentences.theAnsIsBetweenTheShapeAndTheShape(shape1, shape2),
            [[shapes[orderedIndex[0] + 1]]]
          ];
        }
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={items}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {shapes.map((shape, i) => (
              <View
                key={i}
                style={{
                  transform: `rotate(${shape === 'Arrow' ? 270 : 0}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg name={svgNames[shape][colorIndexes[i]]} height={dimens.height * 0.4} />
              </View>
            ))}
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        sentence={sentence}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bly',
  description: 'bly',
  keywords: ['Above', 'Below', 'Between', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Star'])
      .array()
      .refine(arrayHasNoDuplicates),
    shapeComparisonIndexes: z.number().int().min(0).max(2).array().length(2),
    colorIndexes: z.number().int().min(0).max(3).array().length(3),
    variation: numberEnum([0, 1, 2, 3, 4]),
    items: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Star'])
      .array()
      .length(3)
  }),
  simpleGenerator: () => {
    // generate 4 so we have an extra one for the options
    const shapeOptions = [
      'Arrow',
      'Star',
      getRandomFromArray(['Equilateral', 'Isosceles'] as const),
      getRandomFromArray(['Square', 'Rectangle'] as const)
    ] as const;
    const numberOfShapes = 3;
    const shapes = getRandomSubArrayFromArray(shapeOptions, numberOfShapes);

    const variation = getRandomFromArray([0, 1, 2, 3, 4] as const);
    const shapeComparisonIndexes =
      variation === 3
        ? filledArray(1, 2)
        : variation === 4
        ? // must be a shape between them so can only be 0 and 2 because we have 3 shapes
          shuffle([0, 2])
        : randomUniqueIntegersInclusive(0, numberOfShapes - 1, 2);
    const items =
      variation === 2
        ? shapes
        : shapeOptions.filter(val => val !== shapes[shapeComparisonIndexes[0]]);

    return {
      shapes: shapes,
      shapeComparisonIndexes,
      colorIndexes: getRandomSubArrayFromArray(countRange(4), 3),
      variation,
      items: shuffle(items)
    };
  },
  Component: props => {
    const {
      question: { shapes, shapeComparisonIndexes, colorIndexes, variation, items },
      translate
    } = props;

    const isAbove = shapeComparisonIndexes[0] < shapeComparisonIndexes[1];

    const draggables =
      variation === 0
        ? (['above', 'below'] as const).map(val => ({
            value: val,
            component: <Text variant="WRN700">{translate.ks1MiscStrings.directions[val]()}</Text>
          }))
        : items.map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val, translate)}</Text>
          }));

    const testCorrect = (userAnswer: readonly (string | undefined)[]): boolean => {
      const answerIndex0 = shapes.indexOf(
        userAnswer[0] as 'Arrow' | 'Equilateral' | 'Isosceles' | 'Square' | 'Rectangle' | 'Star'
      );
      switch (variation) {
        case 0:
          return (
            userAnswer[0] ===
            (shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'above' : 'below')
          );
        case 1:
          return (
            (isAbove && answerIndex0 > shapeComparisonIndexes[0]) ||
            (!isAbove && answerIndex0 < shapeComparisonIndexes[0])
          );
        case 2:
          return answerIndex0 === 0;
        case 3: {
          const answerIndex1 = shapes.indexOf(
            userAnswer[1] as 'Arrow' | 'Equilateral' | 'Isosceles' | 'Square' | 'Rectangle' | 'Star'
          );
          // get answers in ascending order to then check shape is between them
          const orderedAnswers = sortNumberArray([answerIndex0, answerIndex1], 'ascending');
          return isInRange(orderedAnswers[0] + 1, orderedAnswers[1] - 1)(shapeComparisonIndexes[0]);
        }
        case 4: {
          // get indexes in ascending order to then check answer is between them
          const orderedIndex = sortNumberArray(shapeComparisonIndexes, 'ascending');
          return isInRange(orderedIndex[0] + 1, orderedIndex[1] - 1)(answerIndex0);
        }
      }
    };

    const shape1 = shapeTranslation(shapes[shapeComparisonIndexes[0]], translate);
    const shape2 = shapeTranslation(shapes[shapeComparisonIndexes[1]], translate);

    const [sentence, markSchemeAnswer] = (() => {
      switch (variation) {
        case 0:
          return [
            translate.ks1AnswerSentences.theShapeIsAnsTheShape(shape1, shape2),
            [[shapeComparisonIndexes[0] < shapeComparisonIndexes[1] ? 'above' : 'below']]
          ];
        case 1:
          return [
            isAbove
              ? translate.ks1AnswerSentences.theShapeIsAboveTheAns(shape1)
              : translate.ks1AnswerSentences.theShapeIsBelowTheAns(shape1),
            [[shapes[shapeComparisonIndexes[1]]]]
          ];
        case 2:
          return [
            shapes[0] === 'Arrow'
              ? translate.ks1AnswerSentences.theTopShapeIsAnAns()
              : translate.ks1AnswerSentences.theTopShapeIsAAns(),
            [[shapes[0]]]
          ];
        case 3: {
          return [
            translate.ks1AnswerSentences.theShapeIsBetweenTheAnsAndTheAns(shape1),
            [[shapes[shapeComparisonIndexes[0] - 1], shapes[shapeComparisonIndexes[0] + 1]]]
          ];
        }
        default: {
          const orderedIndex = sortNumberArray(shapeComparisonIndexes, 'ascending');
          return [
            translate.ks1AnswerSentences.theAnsIsBetweenTheShapeAndTheShape(shape1, shape2),
            [[shapes[orderedIndex[0] + 1]]]
          ];
        }
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={draggables}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-evenly' }}>
            {shapes.map((shape, i) => (
              <View
                key={i}
                style={{
                  transform: `rotate(${shape === 'Arrow' ? 270 : 0}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg name={svgNames[shape][colorIndexes[i]]} height={dimens.height * 0.25} />
              </View>
            ))}
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        sentence={sentence}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'blz',
  description: 'blz',
  keywords: ['Above', 'Below', 'Left', 'Right', 'Between', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Star'])
      .array()
      .refine(arrayHasNoDuplicates),
    shapeIndexes: z.number().int().min(0).max(3).array(),
    instruction: z.enum(['below', 'above', 'left', 'right', 'between']),
    positions: z
      .tuple([z.number().int().min(0).max(3), z.number().int().min(0).max(3)])
      .array()
      .refine(arrayHasNoDuplicates),
    colorIndexes: z.number().int().min(0).max(3).array(),
    shapeRotations: numberEnum([90, 180, 270, 0]).array()
  }),
  simpleGenerator: () => {
    const numberOfShapes = randomIntegerInclusive(3, 4);
    const shapes = getRandomSubArrayFromArray(
      [
        'Star' as const,
        'Arrow' as const,
        getRandomFromArray(['Equilateral', 'Isosceles'] as const),
        getRandomFromArray(['Square', 'Rectangle'] as const)
      ],
      numberOfShapes
    );
    const { positions, shapeIndexes, instruction } = rejectionSample(
      () => {
        const instruction = getRandomFromArray([
          'below',
          'above',
          'left',
          'right',
          'between'
        ] as const);

        // find the required positions
        // for example if the instruction is left then there must be a shape that isnt in the last row
        const requiredPositions: [number, number][] = [];
        if (instruction === 'above')
          requiredPositions.push([randomIntegerInclusive(0, 3), randomIntegerInclusive(0, 2)]);
        if (instruction === 'below')
          requiredPositions.push([randomIntegerInclusive(0, 3), randomIntegerInclusive(1, 3)]);
        if (instruction === 'left')
          requiredPositions.push([randomIntegerInclusive(1, 3), randomIntegerInclusive(0, 3)]);
        if (instruction === 'right')
          requiredPositions.push([randomIntegerInclusive(0, 2), randomIntegerInclusive(0, 3)]);
        if (instruction === 'between') {
          const x = randomIntegerInclusive(0, 3);
          const y = randomIntegerInclusive(0, 3);
          const isY = getRandomBoolean();
          requiredPositions.push(
            [x, y],
            [
              isY
                ? randomIntegerInclusive(0, 3, { constraint: val => !isInRange(x - 1, x + 1)(val) })
                : x,
              isY
                ? y
                : randomIntegerInclusive(0, 3, { constraint: val => !isInRange(y - 1, y + 1)(val) })
            ]
          );
        }

        const otherPositions = countRange(numberOfShapes - requiredPositions.length).map(() => [
          randomIntegerInclusive(0, 3),
          randomIntegerInclusive(0, 3)
        ]) as [number, number][];

        const shapeIndexes = countRange(requiredPositions.length);

        return { positions: [...requiredPositions, ...otherPositions], shapeIndexes, instruction };
      },
      val => {
        const countMapX = Array.from(multisetCount(val.positions.map(val => val[0])).values());
        const countMapY = Array.from(multisetCount(val.positions.map(val => val[1])).values());
        // Ensure there are at most 2 in each column and row
        return (
          nestedArrayHasNoDuplicates(val.positions, true) &&
          countMapY.every(count => count <= 2) &&
          countMapX.every(count => count <= 2)
        );
      }
    );
    const colorIndexes = randomUniqueIntegersInclusive(0, 3, 4);
    const shapeRotations = getRandomSubArrayFromArray([0, 90, 180, 270] as const, numberOfShapes);
    return {
      shapes,
      positions,
      shapeIndexes,
      instruction,
      colorIndexes,
      shapeRotations
    };
  },
  Component: ({
    question: { shapes, positions, shapeIndexes, instruction, colorIndexes, shapeRotations },
    translate,
    displayMode
  }) => {
    const shapeNames = shapeIndexes.map(val => shapeTranslation(shapes[val], translate));
    const startPoint = new Point2d(positions[shapeIndexes[0]][0], positions[shapeIndexes[0]][1]);

    const [title, pdfTitle, markSchemeAnswer]: [string, string, Point2d] = (() => {
      switch (instruction) {
        case 'above':
        case 'below':
          return [
            translate.ks1Instructions.dragTheCircleDyTheShape(
              translate.ks1MiscStrings.directions[instruction](),
              shapeNames[0]
            ),
            translate.ks1PDFInstructions.drawACircleDyTheShape(
              translate.ks1MiscStrings.directions[instruction](),
              shapeNames[0]
            ),
            instruction === 'above'
              ? startPoint.add(new Point2d(0, 1))
              : startPoint.add(new Point2d(0, -1))
          ];
        case 'left':
        case 'right':
          return [
            translate.ks1Instructions.dragTheCircleToTheDxTheShape(
              translate.ks1MiscStrings.directions[instruction](),
              shapeNames[0]
            ),
            translate.ks1PDFInstructions.drawACircleToTheDxTheShape(
              translate.ks1MiscStrings.directions[instruction](),
              shapeNames[0]
            ),
            instruction === 'left'
              ? startPoint.add(new Point2d(-1, 0))
              : startPoint.add(new Point2d(1, 0))
          ];
        case 'between': {
          const point1 = positions[shapeIndexes[0]];
          const point2 = positions[shapeIndexes[1]];
          const isX = point1[0] === point2[0];

          const pointsOrdered = sortNumberArray(
            [point1, point2].map(val => val[isX ? 1 : 0]),
            'ascending'
          );
          const xAns = isX ? point1[0] : pointsOrdered[0] + 1;
          const yAns = isX ? pointsOrdered[0] + 1 : point1[1];

          return [
            translate.ks1Instructions.dragTheCircleBetweenTheShapeAAndTheShapeB(
              shapeNames[0],
              shapeNames[1]
            ),
            translate.ks1PDFInstructions.drawACircleBetweenTheShapeAAndTheShapeB(
              shapeNames[0],
              shapeNames[1]
            ),
            new Point2d(xAns, yAns)
          ];
        }
      }
    })();

    const testCorrect = (userAnswer: [number, number][]): boolean => {
      const positionOfShape = positions[shapeIndexes[0]];
      switch (instruction) {
        case 'above':
          return userAnswer[0][1] > positionOfShape[1];
        case 'below':
          return userAnswer[0][1] < positionOfShape[1];
        case 'left':
          return userAnswer[0][0] < positionOfShape[0];
        case 'right':
          return userAnswer[0][0] > positionOfShape[0];
        case 'between': {
          const positionOfShape2 = positions[shapeIndexes[1]];
          const isX = positionOfShape[0] === positionOfShape2[0];

          const pointsOrdered = sortNumberArray(
            [positionOfShape, positionOfShape2].map(val => val[isX ? 1 : 0]),
            'ascending'
          );
          return isInRange(
            pointsOrdered[0] + 1,
            pointsOrdered[1] - 1
          )(isX ? userAnswer[0][1] : userAnswer[0][0]);
        }
      }
    };

    // We always have 4x4 grid so lets hard code this so we know the size required for the draggables
    const gridSize = 125;
    return (
      <QF46PlotCoordinate
        title={displayMode === 'digital' ? title : pdfTitle}
        snapToGrid
        testCorrect={testCorrect}
        gridProps={{
          xMax: 4,
          yMax: 4,
          squareGrid: true,
          hideContinuationLines: true,
          yAxis: null,
          xAxis: null,
          sizingMethod: 'gridScale',
          xScale: gridSize,
          yScale: gridSize,
          gridLineWidth: 2
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [[markSchemeAnswer.x, markSchemeAnswer.y]]
        }}
        gridChildren={shapes.map((shape, i) => (
          <GridImage
            key={i}
            mathCoord={positions[i]}
            item={{
              component: (
                <View
                  style={{
                    width: gridSize,
                    height: gridSize,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <View style={{ transform: `rotate(${shapeRotations[i]}deg)` }}>
                    <AssetSvg
                      key={shape}
                      name={svgNames[shape][colorIndexes[i]]}
                      width={gridSize * 0.9}
                      height={gridSize * 0.9}
                    />
                  </View>
                </View>
              ),
              width: gridSize,
              height: gridSize
            }}
            anchorDX={0}
            anchorDY={gridSize}
          />
        ))}
        items={[
          {
            component: (
              <View
                style={{
                  width: gridSize,
                  height: gridSize,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <AssetSvg
                  name="Coordinates/CirclePointCustomizable"
                  width={gridSize * 0.9}
                  height={gridSize * 0.9}
                  svgProps={{ fill: colors.burntSienna }}
                />
              </View>
            ),
            width: gridSize,
            height: gridSize,
            anchor: [0, gridSize]
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LanguageOfPosition',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
