import { SvgName } from '../../assets/svg';
import { getRandomFromArray, getRandomSubArrayFromArray } from '../random';
import { circles } from './shapes';

import { hexagons, rectangles, squares } from './polygons';

const correctMarkSymmetry: { svgName: SvgName }[] = [
  { svgName: 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_4_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_9_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_10_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_11_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_12_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_13_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_15_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_19_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_20_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_21_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_22_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_23_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_d' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_c' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_26_line_of_symmetry_a' }
];

const incorrectMarkSymmetry: { svgName: SvgName }[] = [
  { svgName: 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_b' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_a' },
  { svgName: 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_b' }
];

export function getRandomShapeWithCorrectSymmetryLines(quantity: number) {
  return getRandomSubArrayFromArray(correctMarkSymmetry, quantity);
}

export function getRandomShapeWithIncorrectSymmetryLines(quantity: number) {
  return getRandomSubArrayFromArray(incorrectMarkSymmetry, quantity);
}

export const regularSymmetryLines = (
  shape:
    | 'scaleneTriangles'
    | 'isoscelesTriangles'
    | 'equilateralTriangles'
    | 'squares'
    | 'rectangles'
    | 'pentagons'
    | 'rhombuses'
    | 'parallelograms'
    | 'hexagons'
): number => {
  switch (shape) {
    case 'scaleneTriangles':
      return 0;
    case 'isoscelesTriangles':
      return 1;
    case 'equilateralTriangles':
      return 3;
    case 'squares':
      return 4;
    case 'rectangles':
      return 2;
    case 'pentagons':
      return 5;
    case 'rhombuses':
      return 2;
    case 'parallelograms':
      return 0;
    case 'hexagons':
      return 6;
  }
};

export const irregularSymmetryLines = (svgName: SvgName): number => {
  switch (svgName) {
    case 'Pentagons/Irregular_pentagon2_white':
    case 'Pentagons/Irregular_pentagon2_blue':
    case 'Pentagons/Irregular_pentagon2_green':
    case 'Pentagons/Irregular_pentagon2_pink':
    case 'Pentagons/Irregular_pentagon2_purple':
    case 'Pentagons/Irregular_pentagon2_yellow':
    case 'Pentagons/Irregular_pentagon4_white':
    case 'Pentagons/Irregular_pentagon4_blue':
    case 'Pentagons/Irregular_pentagon4_green':
    case 'Pentagons/Irregular_pentagon4_pink':
    case 'Pentagons/Irregular_pentagon4_purple':
    case 'Pentagons/Irregular_pentagon4_yellow':
      return 1;
    default:
      return 0;
  }
};

export const irregularSymmetricalShapesWithArrowsSvgs: {
  svgName: SvgName;
  sideRatios: number[];
  labelComponent: 'shape' | 'triangle' | 'quadrilateral';
  missingSides: number[];
  linesOfSymmetry: number;
}[] = [
  {
    svgName: getRandomFromArray([
      'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows',
      'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows',
      'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows',
      'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows',
      'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows'
    ] as const),
    sideRatios: [2, 1, 2],
    labelComponent: 'triangle',
    missingSides: [0],
    linesOfSymmetry: 1
  },
  {
    svgName: getRandomFromArray([
      'Shapes_with_dimension_arrows/rectangle_blue_arrows_4',
      'Shapes_with_dimension_arrows/rectangle_green_arrows_4',
      'Shapes_with_dimension_arrows/rectangle_orange_arrows_4',
      'Shapes_with_dimension_arrows/rectangle_purple_arrows_4',
      'Shapes_with_dimension_arrows/rectangle_red_arrows_4'
    ] as const),
    sideRatios: [2, 1, 2, 1],
    labelComponent: 'quadrilateral',
    missingSides: [1, 2],
    linesOfSymmetry: 2
  },
  {
    svgName: getRandomFromArray([
      'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows',
      'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows',
      'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows'
    ] as const),
    sideRatios: [1, 2, 3, 2],
    labelComponent: 'shape',
    missingSides: [1],
    linesOfSymmetry: 1
  },

  {
    svgName: getRandomFromArray([
      'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue',
      'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green',
      'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink',
      'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple',
      'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow'
    ] as const),
    sideRatios: [1, 1, 2, 1, 1],
    labelComponent: 'shape',
    missingSides: [0, 1],
    linesOfSymmetry: 1
  },
  {
    svgName: getRandomFromArray([
      'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue',
      'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green',
      'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink',
      'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple',
      'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow'
    ] as const),
    sideRatios: [1, 2, 4, 2, 1],
    labelComponent: 'shape',
    missingSides: [0, 1],
    linesOfSymmetry: 1
  },
  {
    svgName: getRandomFromArray([
      'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue',
      'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green',
      'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink',
      'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple',
      'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow'
    ] as const),
    sideRatios: [4, 1, 3, 3, 1, 4],
    labelComponent: 'shape',
    missingSides: [0, 1, 2],
    linesOfSymmetry: 1
  },
  {
    svgName: getRandomFromArray([
      'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue',
      'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green',
      'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink',
      'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple',
      'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow'
    ] as const),
    sideRatios: [4, 3, 3, 4, 3, 3],
    labelComponent: 'shape',
    missingSides: [1, 2, 3, 4],
    linesOfSymmetry: 2
  }
];
const symmetricalImages: { svgNames: SvgName[]; symmetry: 'horizontal' | 'vertical' | 'both' }[] = [
  {
    svgNames: [
      'SymmetricalShapes/both1_white',
      'SymmetricalShapes/both1_pink',
      'SymmetricalShapes/both1_purple',
      'SymmetricalShapes/both1_green',
      'SymmetricalShapes/both1_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [...squares],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both3_white',
      'SymmetricalShapes/both3_pink',
      'SymmetricalShapes/both3_purple',
      'SymmetricalShapes/both3_green',
      'SymmetricalShapes/both3_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both4_white',
      'SymmetricalShapes/both4_pink',
      'SymmetricalShapes/both4_purple',
      'SymmetricalShapes/both4_green',
      'SymmetricalShapes/both4_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [...hexagons],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both6_white',
      'SymmetricalShapes/both6_pink',
      'SymmetricalShapes/both6_purple',
      'SymmetricalShapes/both6_green',
      'SymmetricalShapes/both6_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [...rectangles],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both8_white',
      'SymmetricalShapes/both8_pink',
      'SymmetricalShapes/both8_purple',
      'SymmetricalShapes/both8_green',
      'SymmetricalShapes/both8_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both9_white',
      'SymmetricalShapes/both9_pink',
      'SymmetricalShapes/both9_purple',
      'SymmetricalShapes/both9_green',
      'SymmetricalShapes/both9_yellow'
    ],
    symmetry: 'both'
  },
  { svgNames: [...circles], symmetry: 'both' },
  {
    svgNames: [
      'SymmetricalShapes/both11_white',
      'SymmetricalShapes/both11_pink',
      'SymmetricalShapes/both11_purple',
      'SymmetricalShapes/both11_green',
      'SymmetricalShapes/both11_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/both12_white',
      'SymmetricalShapes/both12_pink',
      'SymmetricalShapes/both12_purple',
      'SymmetricalShapes/both12_green',
      'SymmetricalShapes/both12_yellow'
    ],
    symmetry: 'both'
  },
  {
    svgNames: [
      'SymmetricalShapes/horizontal1_white',
      'SymmetricalShapes/horizontal1_pink',
      'SymmetricalShapes/horizontal1_purple',
      'SymmetricalShapes/horizontal1_green',
      'SymmetricalShapes/horizontal1_yellow'
    ],
    symmetry: 'horizontal'
  },
  {
    svgNames: [
      'SymmetricalShapes/horizontal2_white',
      'SymmetricalShapes/horizontal2_pink',
      'SymmetricalShapes/horizontal2_purple',
      'SymmetricalShapes/horizontal2_green',
      'SymmetricalShapes/horizontal2_yellow'
    ],
    symmetry: 'horizontal'
  },
  {
    svgNames: [
      'SymmetricalShapes/horizontal3_white',
      'SymmetricalShapes/horizontal3_pink',
      'SymmetricalShapes/horizontal3_purple',
      'SymmetricalShapes/horizontal3_green',
      'SymmetricalShapes/horizontal3_yellow'
    ],
    symmetry: 'horizontal'
  },
  {
    svgNames: [
      'SymmetricalShapes/horizontal4_white',
      'SymmetricalShapes/horizontal4_pink',
      'SymmetricalShapes/horizontal4_purple',
      'SymmetricalShapes/horizontal4_green',
      'SymmetricalShapes/horizontal4_yellow'
    ],
    symmetry: 'horizontal'
  },
  {
    svgNames: [
      'SymmetricalShapes/vertical1_white',
      'SymmetricalShapes/vertical1_pink',
      'SymmetricalShapes/vertical1_purple',
      'SymmetricalShapes/vertical1_green',
      'SymmetricalShapes/vertical1_yellow'
    ],
    symmetry: 'vertical'
  },
  {
    svgNames: [
      'SymmetricalShapes/vertical2_white',
      'SymmetricalShapes/vertical2_pink',
      'SymmetricalShapes/vertical2_purple',
      'SymmetricalShapes/vertical2_green',
      'SymmetricalShapes/vertical2_yellow'
    ],
    symmetry: 'vertical'
  },
  {
    svgNames: [
      'SymmetricalShapes/vertical3_white',
      'SymmetricalShapes/vertical3_pink',
      'SymmetricalShapes/vertical3_purple',
      'SymmetricalShapes/vertical3_green',
      'SymmetricalShapes/vertical3_yellow'
    ],
    symmetry: 'vertical'
  }
];

export function getRandomUniqueSymmetryImages(
  quantity: number,
  symmetry?: 'horizontal' | 'vertical' | 'both',
  isSymmetry = true
) {
  const array = symmetry
    ? symmetricalImages.filter(val =>
        isSymmetry ? val.symmetry === symmetry : val.symmetry !== symmetry
      )
    : symmetricalImages;
  const value = getRandomSubArrayFromArray([...array] as const, quantity);
  return value.map(val => ({
    symmetry: val.symmetry,
    svgName: getRandomFromArray([...val.svgNames] as const) as SvgName
  }));
}

export const symmeticalShapes = [
  'rectangleThin',
  'square',
  'hexagon',
  'rectangleThick',
  'diamond',
  'circle',
  'plus',
  'cross'
] as const;

export type SymmetricalShapeName = (typeof symmeticalShapes)[number];

const symmetricalShapesWithLines: Record<
  SymmetricalShapeName,
  { horizontal: SvgName; vertical: SvgName; incorrect1: SvgName; incorrect2: SvgName }
> = {
  rectangleThin: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_d',
    vertical: 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_b',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_b'
  },
  square: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_c',
    vertical: 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_a',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_b'
  },
  hexagon: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_c',
    vertical: 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_b',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_b'
  },
  rectangleThick: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_a',
    vertical: 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_c',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_b'
  },
  diamond: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_c',
    vertical: 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_b',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_b'
  },
  circle: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_c',
    vertical: 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_b',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_b'
  },
  plus: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_d',
    vertical: 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_a',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_b'
  },
  cross: {
    horizontal: 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_d',
    vertical: 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_b',
    incorrect1: 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_a',
    incorrect2: 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_b'
  }
};
export function getRandomShapeWithSymmetryLines(shape: SymmetricalShapeName) {
  return symmetricalShapesWithLines[shape];
}
