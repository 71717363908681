import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { fractionToDecimal } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { barModelColors } from '../../../../theme/colors';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { ADD, SUB } from '../../../../constants';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMY',
  description: 'aMY',
  keywords: ['Bar model', 'Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    number1: z.number().int().min(4).max(8).step(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(4, 8, 2);

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, displayMode }) => {
    const number2 = number1 / 2;

    const barArray = filledArray(1 / number1, number1);

    const numbers = [[0.5, 0.5], barArray];

    const strings = [
      [
        `<frac n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' />`,
        `<frac n="${(1).toLocaleString()}" d="${(2).toLocaleString()}" />`
      ],
      countRange(number1).map(
        () => `<frac n='${(1).toLocaleString()}' d='${number1.toLocaleString()}' />`
      )
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToHelpRepresentEquivalentFraction()}
        testCorrect={[number2.toString(), number1.toString()]}
        sentence={`<frac n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' /> = <frac nAns='' dAns='' />`}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={1}
            dimens={dimens}
            sameRowColor
            rowHeight={displayMode !== 'digital' ? 200 : undefined}
          />
        )}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aMY2',
  description: 'aMY',
  keywords: ['Bar model', 'Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    number1: z.number().int().min(4).max(8).step(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(4, 8, 2);

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, displayMode }) => {
    const number2 = number1 / 2;
    const barArray = filledArray(1 / number1, number1);
    const numbers = [[0.5, 0.5], barArray];
    const strings = [
      [`<frac n='${1}' d='${2}' />`, `<frac n="${1}" d="${2}" />`],
      countRange(number1).map(() => `<frac n='${1}' d='${number1}' />`)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpFindEquivalentFraction()}
        testCorrect={[number2.toString()]}
        fractionContainerStyle={{ height: 96 }}
        sentence={`<frac n='${1}' d='${2}' /> = <frac nAns='' d='${number1}' />`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={1}
            dimens={dimens}
            sameRowColor
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
            rowHeight={displayMode !== 'digital' ? 200 : undefined}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aMZ',
  description: 'aMZ',
  keywords: [
    'Bar model',
    'Equivalent fractions',
    'Fraction',
    'Numerator',
    'Denominator',
    'Parts',
    'Whole'
  ],
  schema: z.object({
    denominator1: z.number().int().min(3).max(5),
    numerator1: z.number().int().min(1).max(4),
    multiplier: z.number().int().min(3).max(5),
    isVertical: z.array(z.boolean()).length(2),
    addOrSub: z.array(z.enum([ADD, SUB])).length(4),
    incorrectAnswerIndex: z.array(z.number().min(0).max(5)).length(4)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 5);
    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const isVertical = countRange(2).map(getRandomBoolean);
    const multiplier = randomIntegerInclusive(3, 5, { constraint: x => x * numerator1 <= 15 });
    const addOrSub = countRange(4).map(() => getRandomFromArray([ADD, SUB] as const));
    const incorrectAnswerIndex = randomUniqueIntegersInclusive(0, 5, 4);

    return { denominator1, numerator1, isVertical, multiplier, addOrSub, incorrectAnswerIndex };
  },

  Component: ({
    question: { denominator1, numerator1, isVertical, multiplier, addOrSub, incorrectAnswerIndex },
    translate,
    displayMode
  }) => {
    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ denominator1, multiplier })
    }) as string;

    const colourMap1 = isVertical[0]
      ? [
          ...filledArray(numeratorColor, numerator1 * 2),
          ...filledArray('white', (denominator1 - numerator1) * 2)
        ]
      : [
          ...filledArray(numeratorColor, numerator1),
          ...filledArray('white', denominator1 - numerator1)
        ];

    const colourMap2 = isVertical[1]
      ? [
          ...filledArray(numeratorColor, numerator1 * multiplier),
          ...filledArray('white', (denominator1 - numerator1) * multiplier)
        ]
      : [
          ...filledArray(numeratorColor, numerator1),
          ...filledArray('white', denominator1 - numerator1)
        ];

    const incorrectItems = [
      [
        addOrSub[0] === ADD ? 2 * numerator1 + 1 : 2 * numerator1 - 1,
        addOrSub[1] === ADD ? 2 * denominator1 + 1 : 2 * denominator1 - 1
      ],
      [numerator1 + multiplier, denominator1 + multiplier],
      [
        addOrSub[2] === ADD ? numerator1 * multiplier + 1 : numerator1 * multiplier - 1,
        addOrSub[3] === ADD ? denominator1 * multiplier + 1 : denominator1 * multiplier - 1
      ],
      [2 * numerator1, denominator1],
      [multiplier * numerator1, denominator1],
      [numerator1, multiplier * denominator1]
    ].filter((_x, idx) => incorrectAnswerIndex.includes(idx));

    const items = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac n='${(2 * numerator1).toLocaleString()}' d='${(
                2 * denominator1
              ).toLocaleString()}' />`}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'A'
        },
        {
          component: (
            <TextStructure
              sentence={`<frac n='${(multiplier * numerator1).toLocaleString()}' d='${(
                multiplier * denominator1
              ).toLocaleString()}' />`}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'B'
        },
        ...incorrectItems.map((x, i) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${x[0].toLocaleString()}' d='${x[1].toLocaleString()}' />`}
                textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: `${i}`
          };
        })
      ],
      {
        random: seededRandom({
          denominator1,
          numerator1,
          isVertical,
          multiplier,
          addOrSub,
          incorrectAnswerIndex
        })
      }
    );

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.dragCardsAndUseBarModelsToMatchEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsAndBarModelsToMatchEquivalentFractions()}
        items={items}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfLayout="itemsBottom"
        actionPanelVariant="bottom"
        sentenceStyle={{ minWidth: displayMode === 'digital' ? 0 : 300 }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', height: dimens.height }}>
            <ShadedFractionBarModel
              totalSubSections={isVertical[0] ? denominator1 * 2 : denominator1}
              customColorMap={colourMap1}
              width={dimens.width * 0.6}
              height={dimens.height * 0.4}
              horizontalSplit={isVertical[0] ? undefined : 2}
            />
            <ShadedFractionBarModel
              totalSubSections={isVertical[1] ? denominator1 * multiplier : denominator1}
              customColorMap={colourMap2}
              width={dimens.width * 0.6}
              height={dimens.height * 0.4}
              horizontalSplit={isVertical[1] ? undefined : multiplier}
            />
          </View>
        )}
        sentences={[
          `<frac n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> = <ans/>`,
          `<frac n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> = <ans/>`
        ]}
        testCorrect={[['A'], ['B']]}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aMZ2',
  description: 'aMZ',
  keywords: [
    'Bar model',
    'Equivalent fractions',
    'Fraction',
    'Numerator',
    'Denominator',
    'Parts',
    'Whole'
  ],
  schema: z.object({
    denominator1: z.number().int().min(3).max(5),
    numerator1: z.number().int().min(1).max(4),
    multiplier: z.number().int().min(2).max(5),
    isVertical: z.boolean(),
    addOrSub: z.array(z.enum([ADD, SUB])).length(2)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 5);
    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const isVertical = getRandomBoolean();
    const multiplier = randomIntegerInclusive(2, 5, { constraint: x => x * numerator1 <= 15 });
    const addOrSub = countRange(2).map(() => getRandomFromArray([ADD, SUB] as const));

    return { denominator1, numerator1, isVertical, multiplier, addOrSub };
  },

  Component: ({
    question: { denominator1, numerator1, isVertical, multiplier, addOrSub },
    translate,
    displayMode
  }) => {
    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ denominator1, multiplier })
    }) as string;

    const colourMap1 = isVertical
      ? [
          ...filledArray(numeratorColor, numerator1 * multiplier),
          ...filledArray('white', (denominator1 - numerator1) * multiplier)
        ]
      : [
          ...filledArray(numeratorColor, numerator1),
          ...filledArray('white', denominator1 - numerator1)
        ];

    const incorrectItems = [
      [
        addOrSub[0] === ADD ? multiplier * numerator1 + 1 : multiplier * numerator1 - 1,
        addOrSub[1] === ADD ? multiplier * denominator1 + 1 : multiplier * denominator1 - 1
      ],
      [numerator1 + multiplier, denominator1 + multiplier],
      [multiplier * numerator1, denominator1],
      [numerator1, multiplier * denominator1]
    ];

    const items = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac n='${multiplier * numerator1}' d='${multiplier * denominator1}' />`}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'A'
        },
        ...incorrectItems.map((x, i) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${x[0]}' d='${x[1]}' />`}
                textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: `${i}`
          };
        })
      ],
      {
        random: seededRandom({
          denominator1,
          numerator1,
          isVertical,
          multiplier,
          addOrSub
        })
      }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragTheCardToMatchEquivalentFractionShownInDiagram()}
        pdfTitle={translate.instructions.useTheCardToMatchEquivalentFractionShownInDiagram()}
        items={items}
        mainPanelStyle={{ flexDirection: displayMode === 'digital' ? 'row' : 'column' }}
        actionPanelVariant="bottom"
        sentenceStyle={{ minWidth: displayMode === 'digital' ? 0 : 300 }}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={isVertical ? denominator1 * multiplier : denominator1}
            customColorMap={colourMap1}
            width={dimens.width * 0.6}
            height={dimens.height * 0.4}
            horizontalSplit={isVertical ? undefined : multiplier}
          />
        )}
        sentence={`<frac n='${numerator1}' d='${denominator1}' /> = <ans/>`}
        testCorrect={['A']}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aM0',
  description: 'aM0',
  keywords: ['Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator1: z.number().int().min(3).max(5),
    multiplier: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 5);
    const multiplier = randomIntegerInclusive(2, 5);

    return { denominator1, multiplier };
  },

  Component: ({ question: { denominator1, multiplier }, translate }) => {
    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ denominator1, multiplier })
    }) as string;

    const colourMap1 = shuffle(
      [...filledArray(numeratorColor, 1), ...filledArray('white', denominator1 - 1)],
      { random: seededRandom({ denominator1, multiplier }) }
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useDiagramsToCompleteEquivalentFractions()}
        testCorrect={[multiplier.toString(), (denominator1 * multiplier).toString()]}
        sentence={`<frac n='${(1).toLocaleString()}' d='${denominator1.toLocaleString()}' /> = <frac nAns='' dAns='' />`}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: dimens.width }}
          >
            <ShadedFractionBarModel
              totalSubSections={denominator1}
              customColorMap={colourMap1}
              width={dimens.width * 0.4}
              height={dimens.height}
            />
            <ShadedFractionBarModel
              totalSubSections={denominator1}
              customColorMap={colourMap1}
              width={dimens.width * 0.4}
              height={dimens.height}
              horizontalSplit={multiplier}
            />
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aM1',
  description: 'aM1',
  keywords: ['Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator1: z.number().int().min(3).max(5),
    numerator1: z.number().int().min(2).max(4),
    multiplier: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 5);
    const numerator1 = randomIntegerInclusive(2, denominator1 - 1);
    const multiplier = randomIntegerInclusive(2, 5);

    return { denominator1, numerator1, multiplier };
  },

  Component: ({ question: { denominator1, numerator1, multiplier }, translate }) => {
    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ denominator1, numerator1, multiplier })
    }) as string;

    const colourMap1 = shuffle(
      [
        ...filledArray(numeratorColor, numerator1),
        ...filledArray('white', denominator1 - numerator1)
      ],
      { random: seededRandom({ denominator1, numerator1, multiplier }) }
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useDiagramsToCompleteEquivalentFractions()}
        testCorrect={[(numerator1 * multiplier).toString(), (denominator1 * multiplier).toString()]}
        sentence={`<frac n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> = <frac nAns='' dAns='' />`}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: dimens.width }}
          >
            <ShadedFractionBarModel
              totalSubSections={denominator1}
              customColorMap={colourMap1}
              width={dimens.width * 0.4}
              height={dimens.height}
            />
            <ShadedFractionBarModel
              totalSubSections={denominator1}
              customColorMap={colourMap1}
              width={dimens.width * 0.4}
              height={dimens.height}
              horizontalSplit={multiplier}
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aM2',
  description: 'aM2',
  keywords: ['Fraction wall', 'Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  questionHeight: 1200,
  schema: z.object({
    denominator1: z.number().int().min(2).max(3),
    numerator1: z.number().int().min(1).max(2),
    numerator2: z.number().int().min(1).max(5),
    answerBoxIndex: z.array(z.number().min(0).max(3)).length(2)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 3);
    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const numerator2 = randomIntegerInclusive(1, denominator1 * 2 - 1);
    const answerBoxIndex = countRange(2).map(() => randomIntegerInclusive(0, 3));

    return { denominator1, numerator1, numerator2, answerBoxIndex };
  },

  Component: ({
    question: { denominator1, numerator1, numerator2, answerBoxIndex },
    translate,
    displayMode
  }) => {
    const denominator2 = denominator1 * 2;
    const denominator3 = denominator2 * 2;
    const numbers = [
      filledArray(1 / denominator1, denominator1),
      filledArray(1 / denominator2, denominator2),
      filledArray(1 / denominator3, denominator3)
    ];

    const strings = [
      countRange(denominator1).map(
        () => `<frac n='${(1).toLocaleString()}' d='${denominator1.toLocaleString()}' />`
      ),
      countRange(denominator2).map(
        () => `<frac n='${(1).toLocaleString()}' d='${denominator2.toLocaleString()}' />`
      ),
      countRange(denominator3).map(
        () => `<frac n='${(1).toLocaleString()}' d='${denominator3.toLocaleString()}' />`
      )
    ];

    const sentenceValues = [
      [
        { sentence: `n='${numerator1.toLocaleString()}'`, value: numerator1 },
        { sentence: `d='${denominator1.toLocaleString()}'`, value: denominator1 },
        { sentence: `n='${(numerator1 * 2).toLocaleString()}'`, value: numerator1 * 2 },
        { sentence: `d='${denominator2.toLocaleString()}'`, value: denominator2 }
      ],
      [
        { sentence: `n='${numerator2.toLocaleString()}'`, value: numerator2 },
        { sentence: `d='${denominator2.toLocaleString()}'`, value: denominator2 },
        { sentence: `n='${(numerator2 * 2).toLocaleString()}'`, value: numerator2 * 2 },
        { sentence: `d='${denominator3.toLocaleString()}'`, value: denominator3 }
      ]
    ];

    answerBoxIndex[0] % 2 === 0
      ? (sentenceValues[0][answerBoxIndex[0]].sentence = `nAns=''`)
      : (sentenceValues[0][answerBoxIndex[0]].sentence = `dAns=''`);
    answerBoxIndex[1] % 2 === 0
      ? (sentenceValues[1][answerBoxIndex[1]].sentence = `nAns=''`)
      : (sentenceValues[1][answerBoxIndex[1]].sentence = `dAns=''`);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useTheFractionWallToCompleteEquivalentFractions()}
        pdfDirection="column"
        questionHeight={1200}
        testCorrect={[
          [sentenceValues[0][answerBoxIndex[0]].value.toString()],
          [sentenceValues[1][answerBoxIndex[1]].value.toString()]
        ]}
        sentences={sentenceValues.map(
          val =>
            `<frac ${val[0].sentence} ${val[1].sentence} /> = <frac ${val[2].sentence} ${val[3].sentence} />`
        )}
        style={{ flexDirection: 'row' }}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-around' }}
        fractionContainerStyle={{ height: displayMode !== 'digital' ? 150 : 96 }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={1}
            dimens={dimens}
            sameRowColor
            fractionDividerStyle={{ marginVertical: 1 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 22 : undefined }}
            rowHeight={displayMode !== 'digital' ? 200 : undefined}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aM3',
  description: 'aM3',
  keywords: ['Equivalent fractions', 'Fraction', 'Numerator', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(3).max(5),
    multipliers: z.array(z.number().int().min(2).max(12)).length(6),
    numOfDenom: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);
    const multipliers = randomUniqueIntegersInclusive(2, 12, 6, {
      constraint: x => x % denominator !== 0 && (x % denominator) + 1 !== 0
    });
    const numOfDenom = randomIntegerInclusive(1, 5);
    return { denominator, multipliers, numOfDenom };
  },
  Component: ({ question: { denominator, multipliers, numOfDenom }, translate, displayMode }) => {
    const fractions = shuffle(
      [
        ...countRange(numOfDenom).map(i => {
          return {
            sentence: `<frac n='${multipliers[i].toLocaleString()}' d='${(
              denominator * multipliers[i]
            ).toLocaleString()}' />`,
            value: fractionToDecimal(multipliers[i], denominator * multipliers[i])
          };
        }),
        ...countRange(6 - numOfDenom, numOfDenom).map(i => {
          return {
            sentence: `<frac n='${multipliers[i].toLocaleString()}' d='${(
              (denominator + 1) *
              multipliers[i]
            ).toLocaleString()}' />`,
            value: fractionToDecimal(multipliers[i], (denominator + 1) * multipliers[i])
          };
        })
      ],
      { random: seededRandom({ denominator, multipliers, numOfDenom }) }
    );

    const correctAnswers = [
      fractions.filter(i => i.value === fractionToDecimal(1, denominator)).map(i => i.value),
      fractions.filter(i => i.value === fractionToDecimal(1, denominator + 1)).map(i => i.value)
    ];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortEquivalentFractions()}
        pdfTitle={translate.instructions.sortEquivalentFractionsPDF()}
        zoneNames={[
          translate.tableHeaders.EquivalentToX(
            `<frac n='${(1).toLocaleString()}' d='${denominator.toLocaleString()}' />`
          ),
          translate.tableHeaders.EquivalentToX(
            `<frac n='${(1).toLocaleString()}' d='${(denominator + 1).toLocaleString()}' />`
          )
        ]}
        items={fractions.map(({ value, sentence }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value
          };
        })}
        testCorrect={correctAnswers}
        questionHeight={900}
        pdfItemVariant="pdfSquare"
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionFamilies',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
