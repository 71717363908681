import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  findExchanges,
  numbersDoNotExchange,
  numbersExchange,
  numbersExchangeAt,
  numbersOnlyExchangeAt
} from 'common/src/utils/exchanges';
import ColumnOperations from 'common/src/components/question/representations/ColumnOperations/ColumnOperations';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from 'common/src/components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from 'common/src/constants';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import { PowersOfTenWord } from 'common/src/utils/math';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import deepEqual from 'react-fast-compare';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ah0',
  description: 'ah0',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(1001)
        .max(9999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      topAddendVar1OrVar2: z.enum(['var1', 'var2'])
    })
    .refine(
      val => findExchanges(val.var1, val.var2).length === 1 && val.var1 + val.var2 < 10_000,
      'There must be one exchange and the total is less than 10,000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have one exchange
      ({ var1, var2 }) => findExchanges(var1, var2).length === 1 && var1 + var2 < 10_000
    );

    const topAddendVar1OrVar2 = getRandomFromArray(['var1', 'var2'] as const);

    return {
      var1,
      var2,
      topAddendVar1OrVar2
    };
  },
  Component: ({ question: { var1, var2, topAddendVar1OrVar2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={topAddendVar1OrVar2 === 'var1' ? var1 : var2}
        bottomNumber={topAddendVar1OrVar2 === 'var1' ? var2 : var1}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ah1',
  description: 'ah1',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(1001)
        .max(8998)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(1001)
        .max(8998)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => findExchanges(val.var1, val.var2).length === 1, 'must be only 1 exchange')
    .refine(val => val.var1 + val.var2 < 10_000, 'total should be less than 10,000'),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['ones', 'tens', 'hundreds'] as const);
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(1001, 8998, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(1001, 9999 - var1, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange and total is less than 10,000.
      ({ var1, var2 }) => var1 + var2 < 10_000 && numbersOnlyExchangeAt(var1, var2, exchange)
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ah2',
  description: 'ah2',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      eqs: z
        .array(
          z.object({
            topNumber: z.number().int().min(1000).max(8999),
            bottomNumber: z.number().int().min(1000).max(8999),
            isCorrect: z.boolean()
          })
        )
        .length(4)
    })
    .refine(
      val => val.eqs.filter(eq => numbersExchange(eq.bottomNumber, eq.topNumber)).length > 0,
      'must have atleast one equation that exchanges.'
    ),
  simpleGenerator: () => {
    const {
      incorrectNumberA1,
      incorrectNumberA2,
      incorrectNumberB1,
      incorrectNumberB2,
      incorrectNumberC1,
      incorrectNumberC2
    } = rejectionSample(
      () => {
        // Generate 3 pairs of random integers that sum to less than 10,000.
        const incorrectNumberA1 = randomIntegerInclusive(1000, 8999);
        const incorrectNumberA2 = randomIntegerInclusive(1000, 9999 - incorrectNumberA1);

        const incorrectNumberB1 = randomIntegerInclusive(1000, 8999);
        const incorrectNumberB2 = randomIntegerInclusive(1000, 9999 - incorrectNumberB1);

        const incorrectNumberC1 = randomIntegerInclusive(1000, 8999);
        const incorrectNumberC2 = randomIntegerInclusive(1000, 9999 - incorrectNumberC1);

        return {
          incorrectNumberA1,
          incorrectNumberA2,
          incorrectNumberB1,
          incorrectNumberB2,
          incorrectNumberC1,
          incorrectNumberC2
        };
      },
      // Only permit them if they have no exchanges and no duplicate additions.
      ({
        incorrectNumberA1,
        incorrectNumberA2,
        incorrectNumberB1,
        incorrectNumberB2,
        incorrectNumberC1,
        incorrectNumberC2
      }) =>
        numbersDoNotExchange(incorrectNumberA1, incorrectNumberA2) &&
        numbersDoNotExchange(incorrectNumberB1, incorrectNumberB2) &&
        numbersDoNotExchange(incorrectNumberC1, incorrectNumberC2) &&
        arrayHasNoDuplicates([incorrectNumberA1, incorrectNumberB1, incorrectNumberC1]) &&
        arrayHasNoDuplicates([incorrectNumberA2, incorrectNumberB2, incorrectNumberC2])
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberB1 = randomIntegerInclusive(1000, 8999);
        const numberB2 = randomIntegerInclusive(1000, 9999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they exchange only at the ones.
      ({ numberB1, numberB2 }) => numbersOnlyExchangeAt(numberB1, numberB2, 'ones')
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberC1 = randomIntegerInclusive(1000, 8999);
        const numberC2 = randomIntegerInclusive(1000, 9999 - numberC1);
        return { numberC1, numberC2 };
      },
      // Only permit them if they exchange only at the tens.
      ({ numberC1, numberC2 }) => numbersOnlyExchangeAt(numberC1, numberC2, 'tens')
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberD1 = randomIntegerInclusive(1000, 8999);
        const numberD2 = randomIntegerInclusive(1000, 9999 - numberD1);
        return { numberD1, numberD2 };
      },
      // Only permit them if they exchange only at the hundreds.
      ({ numberD1, numberD2 }) => numbersOnlyExchangeAt(numberD1, numberD2, 'hundreds')
    );

    const eqs = getRandomSubArrayFromArray(
      [
        { topNumber: incorrectNumberA1, bottomNumber: incorrectNumberA2, isCorrect: false },
        { topNumber: incorrectNumberB1, bottomNumber: incorrectNumberB2, isCorrect: false },
        { topNumber: incorrectNumberC1, bottomNumber: incorrectNumberC2, isCorrect: false },
        { topNumber: numberB1, bottomNumber: numberB2, isCorrect: true },
        { topNumber: numberC1, bottomNumber: numberC2, isCorrect: true },
        { topNumber: numberD1, bottomNumber: numberD2, isCorrect: true }
      ],
      4
    );

    return { eqs };
  },
  Component: props => {
    const {
      question: { eqs },
      translate
    } = props;

    const shuffledEqs = shuffle(eqs, { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAdditionsThatWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheAdditionsThatWillNeedAnExchange()}
        testCorrect={shuffledEqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return shuffledEqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ width: dimens.width, height: dimens.height - 30 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ah3',
  description: 'ah3',
  keywords: ['Addition', 'Exchange'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1001)
        .max(8998)
        .refine(x => x % 10 !== 0),
      number2: z
        .number()
        .int()
        .min(1001)
        .max(8998)
        .refine(x => x % 10 !== 0)
    })
    .refine(val => findExchanges(val.number1, val.number2).length === 1, 'must be only 1 exchange')
    .refine(val => val.number1 + val.number2 < 10000, 'Total should be less than 10,000'),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['ones', 'tens', 'hundreds'] as const);

    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1001, 8998, { constraint: x => x % 10 !== 0 });

        const number2 = randomIntegerInclusive(1001, 9999 - number1, {
          constraint: x => x % 10 !== 0
        });

        return { number1, number2 };
      },
      // Only permit them if they only exchange at the selected power, and the total is less than 10,000:
      ({ number1, number2 }) =>
        number1 + number2 < 10000 && numbersOnlyExchangeAt(number1, number2, exchange)
    );

    return {
      number1,
      number2
    };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        testCorrect={[(number1 + number2).toString()]}
      />
    );
  }
});

/** Helper function for generating a random sum which exchanges at (at least) the given power of ten. */
const exchangesAtX = (x: PowersOfTenWord | 'nowhere' | 'no constraint') =>
  rejectionSample(
    () => ({
      left: randomIntegerInclusive(1000, 9999),
      right: randomIntegerInclusive(1000, 9999)
    }),
    ({ left: l, right: r }) =>
      l + r <= 9999 &&
      (x === 'no constraint'
        ? true
        : x === 'nowhere'
        ? numbersDoNotExchange(l, r)
        : numbersExchangeAt(l, r, x))
  );

const Question5 = newQuestionContent({
  uid: 'ah4',
  description: 'ah4',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z.object({
    sums: z
      .array(
        z
          .object({
            left: z.number().int().min(1).max(9999),
            right: z.number().int().min(1).max(9999)
          })
          .refine(({ left, right }) => left + right <= 9999, 'Result must be <= 9,999')
      )
      .refine(
        sums => sums.some(({ left, right }) => numbersExchange(left, right)),
        'There must be at least one calculation which exchanges.'
      )
      .refine(calcs => arrayHasNoDuplicates(calcs, deepEqual), 'No duplicate calculations allowed')
  }),
  simpleGenerator: () => {
    const sums = shuffle(
      rejectionSample(
        () => [
          exchangesAtX('nowhere'),
          exchangesAtX('nowhere'),
          exchangesAtX('nowhere'),
          exchangesAtX('nowhere'),
          exchangesAtX('ones'),
          exchangesAtX('tens'),
          exchangesAtX('hundreds'),
          exchangesAtX('no constraint')
        ],
        arr => arrayHasNoDuplicates(arr, deepEqual)
      )
    );

    return { sums: getRandomSubArrayFromArray(sums, 6) };
  },
  Component: ({ question: { sums }, translate }) => {
    const calculations = sums.map(({ left, right }, i) => ({
      value: i,
      text: `${left.toLocaleString()} ${ADD} ${right.toLocaleString()}`,
      exchange: numbersExchange(left, right)
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheAdditionsThatWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheAdditionsThatWillNeedAnExchange()}
        testCorrect={calculations.filter(it => it.exchange).map(it => it.value)}
        items={calculations.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ah5',
  description: 'ah5',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      topNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => findExchanges(val.topNumber, val.bottomNumber).length === 1,
      'topNumber and bottomNumber must have only one exchange.'
    )
    .refine(
      val => val.topNumber + val.bottomNumber < 10000,
      'topNumber + bottomNumber must be less than 10,000'
    ),
  simpleGenerator: () => {
    const { topNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping topNumber uniformly distributed
        const topNumber = randomIntegerInclusive(1000, 8999);
        const bottomNumber = randomIntegerInclusive(1000, 9999 - topNumber);
        return { topNumber, bottomNumber };
      },
      // Only permit them if they have 1 exchange.
      ({ topNumber, bottomNumber }) => findExchanges(topNumber, bottomNumber).length === 1
    );

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds, missingThousands };
  },

  Component: props => {
    const {
      question: {
        topNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwo4DigitNumbersOneExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
