import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { useTheme } from '../../theme';
import Modal from './Modal';

/**
 * Like {@link Modal}, but with default styling that makes it look similar to the question card.
 */
export default function ModalCard({ containerStyle, ...props }: ComponentProps<typeof Modal>) {
  const styles = useStyles();
  return <Modal containerStyle={[styles.container, containerStyle]} {...props} />;
}

const useStyles = function () {
  const theme = useTheme();

  return StyleSheet.create({
    container: {
      padding: 60,
      backgroundColor: theme.colors.surface,
      borderRadius: 24,
      borderWidth: 3,
      borderColor: theme.colors.onSurface
    }
  });
};
