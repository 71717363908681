import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';

/** Generated with https://react-svgr.com/playground/?jsxRuntime=automatic&memo=true&native=true&typescript=true */
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path
      fillRule="evenodd"
      d="M8.563 4.869C9.545 4.083 11 4.783 11 6.04v11.92c0 1.257-1.455 1.957-2.437 1.171L4.649 16H3a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h1.65l3.913-3.131ZM9 7.081 5.899 9.56a2 2 0 0 1-1.25.439H3a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h1.65a2 2 0 0 1 1.249.438L9 16.92V7.081Z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
