import { SvgName } from '../assets/svg';
import { Dimens } from '../theme/scaling';
import { getActualDimens } from './getActualDimens';

/**
 * List of SvgNames that can be used with CircleShapeAngles.tsx
 */
export type CircleShapeNames =
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_yellow'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_yellow'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_yellow';

const getDimens = (
  svgName: SvgName,
  dimens: Dimens,
  shapeDimens: { shapeWidth: number; shapeHeight: number }
) => {
  const { width, height } = dimens;
  const { shapeWidth, shapeHeight } = shapeDimens;

  const { actualHeight, actualWidth } = getActualDimens(svgName, {
    width: shapeWidth,
    height: shapeHeight
  });

  const widthDiff = (width - actualWidth) * 0.5;
  const heightDiff = (height - actualHeight) * 0.5;

  return {
    actualWidth,
    actualHeight,
    widthDiff,
    heightDiff
  };
};

/**
 *  positions for circles to be drawn over angles in shape
 */
export const anglePositions = (
  shapeName: CircleShapeNames,
  dimens: Dimens,
  shapeDimens: { shapeWidth: number; shapeHeight: number }
) => {
  switch (shapeName) {
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_blue':
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_green':
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_pink':
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_purple':
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        // here angle 0 and 1 are the equal ones
        positions: [
          // angle 0
          {
            bottom: heightDiff + actualHeight * 0.8,
            right: widthDiff + actualWidth * 0.78
          },
          // angle 1
          {
            top: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.78
          },
          // angle 2
          {
            top: heightDiff + actualHeight * 0.8,
            right: widthDiff + actualWidth * 0.78
          }
        ]
      };
    }
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_blue':
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_green':
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_pink':
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_purple':
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        // here angle 0 and 1 are the equal ones
        positions: [
          // angle 0
          {
            bottom: heightDiff + actualHeight * 0.8,
            right: widthDiff + actualWidth * 0.88
          },
          // angle 1
          {
            right: widthDiff + actualWidth * 0.88,
            top: heightDiff + actualHeight * 0.8
          },
          // angle 2
          {
            top: heightDiff + actualHeight * 0.35,
            left: widthDiff + actualWidth * 0.88
          }
        ]
      };
    }
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_blue':
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_green':
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_pink':
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_purple':
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        // here angle 0 and 1 are the equal ones
        positions: [
          // angle 0
          {
            bottom: heightDiff + actualHeight * 0.8,
            right: widthDiff + actualWidth * 0.88
          },
          // angle 1
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.88
          },
          // angle 2
          {
            top: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.42
          }
        ]
      };
    }
  }
};
