import { useCallback, useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import TextStructure from '../../../molecules/TextStructure';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import Text from '../../../typography/Text';
import { parseMarkup } from '../../../../markup';
import { SUB } from '../../../../constants';
import { DisplayMode } from '../../../../contexts/displayMode';
import { AssetSvg } from '../../../../assets/svg';
import { withStateHOC } from '../../../../stateTree';
import { SetState } from '../../../../utils/react';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { parseToSUB } from '../../../../utils/parse';

export type CompleteNumberLine = string[];
export type ObjectsAsNumberLine = 'thermometer';

type Props = {
  /**
   * @param tickValues - Array representing the number line tick values/labels.
   * Value to be shown along with the number of user answers at that specific tick (CompleteNumberLine).
   * Option 2 {@link CompleteNumberLine} supports a complete the number line style question and utilises the
   * TextStructure markup language (see {@link parseMarkup} for syntax) for all of the number line labels. Fractions
   * are supported using this type of number line, but there are some limitations.
   *
   */
  tickValues: CompleteNumberLine;
  inputMaxCharacters?: number;
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  /**
   * @param userAnswer - State to hold the user input answer.
   */
  userAnswer?: string[];
  /**
   * @param onTextInput - What to do when a user inputs an answer.
   */
  onTextInput?: (answer: string, index: number) => void;
  /** Custom scale factor for text, defaults to 2.5 */
  scaleFactor?: number;
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
  object: ObjectsAsNumberLine;
};

export const ObjectAsNumberLine = ({
  object,
  tickValues,
  inputMaxCharacters,
  dimens: { width, height },
  userAnswer = [''],
  scaleFactor = 2.5,
  customFontSize,
  onTextInput = () => {
    /* do nothing */
  }
}: Props) => {
  const translate = useI18nContext().LL;
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const { component, objectLeftOffSet, objectCenterLineYOffset } = (() => {
    switch (object) {
      case 'thermometer':
        return {
          objectLeftOffSet: isPdf ? 60 : 35,
          objectCenterLineYOffset: isPdf ? 57 : 39,
          component: (
            <>
              <AssetSvg
                name="Thermometers/Horizontal_Thermometer_filled"
                height={height}
                width={width}
              />
              <Text
                variant="WRN700"
                style={{
                  fontSize: isPdf ? 40 : 20,
                  position: 'absolute',
                  bottom: height * 0.5 - (isPdf ? 180 : 120),
                  right: isPdf ? 50 : 35
                }}
              >
                {translate.units.celsius()}
              </Text>
            </>
          )
        };
      default:
        throw new Error('Object not defined to be used as number line.');
    }
  })();

  const centerLineY = height * 0.5 + objectCenterLineYOffset;
  // SVG Properties
  const lineColor = isPdf ? colors.black : colors.prussianBlue;
  const lineThickness = isPdf ? 4 : 2;

  const horizontalPad = 60 + objectLeftOffSet;
  const pdfHorizontalPad = 140;
  const lineWidth = width - (isPdf ? horizontalPad + pdfHorizontalPad : 2 * horizontalPad);
  const tickSpacing = lineWidth / (tickValues.length - 1);
  const startingTickX = horizontalPad;
  const tickHeight = isPdf ? 70 : 40;

  const styles = useStyles(width, height, objectLeftOffSet);
  const fontSize =
    customFontSize ??
    scaleNumberLineFontSize(
      tickValues.map(tick => {
        return tick?.toLocaleString() ?? '';
      }),
      lineWidth,
      displayMode,
      scaleFactor
    );

  // Parse all the tick values. Each tick can have 0, 1 or more input boxes.
  const tickValuesParsed = tickValues.map(tick => parseMarkup(String(tick)));

  // Ticks
  const ticks = tickValues.map((_tick, index) => {
    const tickX = startingTickX + index * tickSpacing;

    // Render different style of tick if answer box present
    if (tickValuesParsed[index].numberOfAns > 0) {
      return (
        <Line
          x1={tickX}
          y1={centerLineY - tickHeight / 2}
          x2={tickX}
          y2={centerLineY + tickHeight / 2}
          stroke={colors.burntSienna}
          strokeWidth={isPdf ? 8 : 4}
          key={'tick' + index}
        />
      );
    } else {
      return (
        <Line
          x1={tickX}
          y1={centerLineY - tickHeight / 2}
          x2={tickX}
          y2={centerLineY + tickHeight / 2}
          stroke={lineColor}
          strokeWidth={lineThickness}
          key={'tick' + index}
        />
      );
    }
  });

  let ansIndex = -1;

  // Tick Numbers
  const numberComponents = tickValues.map((tick, index) => {
    // CompleteNumberLine style (use TextStructure)
    if (typeof tick === 'string') {
      const ansCount = tickValuesParsed[index].numberOfAns;
      const tickX = startingTickX + index * tickSpacing;
      // Add number of answers for this tick to index
      ansIndex += ansCount;
      // Create scoped copy, otherwise all answer indices will equal final index
      const i = ansIndex;
      const negativeFrac = tick.includes(`${SUB}`) && tick.includes('<frac');

      return (
        <View
          key={'tickNum' + index}
          style={[
            styles.completeNumberLineLabel,
            {
              left: tickX - 60 + objectLeftOffSet,
              bottom: height - centerLineY + tickHeight / 2
            }
          ]}
        >
          <TextStructure
            sentence={parseToSUB(tick)}
            inputBox={({ index }) => {
              // Adjust the answer index to be correct for the number of answers
              // This is done by the following calculation: i-count+(index+1)
              // i = the total number of answers to date including the answers at this tick
              // count = number of answers at this tick
              // index = the index of the answer at this tick (0,1,2)
              return (
                <NoKeyboardTextInput
                  key={i - ansCount + (index + 1)}
                  value={userAnswer[i - ansCount + (index + 1)]}
                  onChangeText={text => {
                    onTextInput(text, i - ansCount + (index + 1));
                  }}
                  maxCharacters={inputMaxCharacters}
                  style={{ fontSize }}
                />
              );
            }}
            textStyle={[styles.text, { fontSize }]}
            style={{ flexWrap: 'nowrap', width: negativeFrac ? 90 : undefined }}
            fractionTextStyle={{ fontSize }}
            fractionContainerStyle={{ height: ansCount > 0 ? 96 : 48 }}
          />
        </View>
      );
    }
  });

  return (
    <View style={styles.container}>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={styles.svg}
        pointerEvents={'none'}
      >
        {ticks}
      </Svg>
      <View style={{ zIndex: 0 }}>{component}</View>
      {numberComponents}
    </View>
  );
};

function useStyles(width: number, height: number, leftOffSet: number) {
  const displayMode = useContext(DisplayMode);
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height,
          position: 'relative'
        },
        svg: {
          position: 'absolute',
          bottom: 0,
          left: leftOffSet,
          zIndex: 2
        },
        text: {
          textAlign: 'center',
          overflow: 'visible'
        },
        completeNumberLineLabel: {
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          width: 120,
          height: displayMode === 'digital' ? 96 : 150
        }
      }),
    [width, height, leftOffSet, displayMode]
  );
}

/**
 * Simple wrapper around {@link NumberLine} which makes it easier to use with {@link withStateHOC}.
 * Replaces `onTextInput` prop (which works on one text input at a time) with `setUserAnswer` prop
 * (which works on all of the text inputs simultaneously).
 */
function ObjectNumberLineThatsEasierToUseWithWithState({
  setUserAnswer,
  ...props
}: Omit<Props, 'onTextInput'> & { setUserAnswer: SetState<string[]> }) {
  const onTextInput = useCallback(
    (answer: string, index: number) => {
      setUserAnswer(old => {
        const newArr = [...old];
        newArr[index] = answer;
        return newArr;
      });
    },
    [setUserAnswer]
  );
  return ObjectAsNumberLine({ onTextInput, ...props });
}

export const ObjectAsNumberLineWithState = withStateHOC(
  ObjectNumberLineThatsEasierToUseWithWithState,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer'
  }
);
