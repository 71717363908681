import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';
import {
  createGridAroundShape,
  createRectangleFromSquares,
  getRectangleDimensions,
  isRectangle,
  isSquareShape
} from '../../../../utils/shapes';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTa',
  description: 'aTa',
  keywords: ['Scale'],
  schema: z.object({
    object: z.enum([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ]),
    incorrectAScale: z.enum(['thin', 'wide', 'short', 'tall']),
    incorrectBScale: z.enum(['thin', 'wide', 'short', 'tall'])
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ] as const);

    const [incorrectAScale, incorrectBScale] = getRandomSubArrayFromArray(
      ['thin', 'wide', 'short', 'tall'] as const,
      2
    );

    return { object, incorrectAScale, incorrectBScale };
  },
  Component: props => {
    const {
      question: { object, incorrectAScale, incorrectBScale },
      translate,
      displayMode
    } = props;

    const objectString = (() => {
      switch (object) {
        case 'Bike':
          return translate.objects.Bike();
        case 'Bus':
          return translate.objects.Bus();
        case 'Car':
          return translate.objects.Car();
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return translate.objects.Flower();
      }
    })();

    const objectSvgPath = (() => {
      switch (object) {
        case 'Bike':
        case 'Bus':
        case 'Car':
          return object;
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return `Flowers/${object}`;
      }
    })() as SvgName;

    const [incorrectAWidth, incorrectAHeight] = (() => {
      switch (incorrectAScale) {
        case 'thin':
          return [0.25, 0.85];
        case 'wide':
          return [1, 0.6];
        case 'short':
          return [0.85, 0.25];
        case 'tall':
          return [0.6, 1];
      }
    })();

    const [incorrectBWidth, incorrectBHeight] = (() => {
      switch (incorrectBScale) {
        case 'thin':
          return [0.25, 0.85];
        case 'wide':
          return [1, 0.6];
        case 'short':
          return [0.85, 0.25];
        case 'tall':
          return [0.6, 1];
      }
    })();

    const statements = shuffle(
      [
        {
          value: 'correct',
          width: 0.75,
          height: 0.75,
          isCorrect: true
        },
        {
          value: 'incorrectA',
          width: incorrectAWidth,
          height: incorrectAHeight,
          isCorrect: false
        },
        {
          value: 'incorrectB',
          width: incorrectBWidth,
          height: incorrectBHeight,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAPictureOfObject(objectString)}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        itemLayout={'rowTall'}
        numItems={3}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <AssetSvg
              name={objectSvgPath}
              height={dimens.height * 0.3}
              style={{ alignSelf: 'center' }}
            />
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode !== 'digital'
                ? translate.instructions.circleScaleDrawingOfObject(objectString)
                : translate.instructions.selectScaleDrawingOfObject(objectString)}
            </Text>
          </View>
        )}
        renderItems={({ dimens }) =>
          statements.map(({ value, width, height }) => ({
            value,
            component: (
              <MeasureView
                style={[
                  {
                    transform: [{ scaleX: width }, { scaleY: height }],
                    width: dimens.width * width,
                    height: dimens.height * height,
                    maxWidth: dimens.width,
                    maxHeight: dimens.height,
                    margin: 8,
                    alignSelf: 'center'
                  }
                ]}
              >
                {measureDimens => (
                  <AssetSvg
                    name={objectSvgPath}
                    width={measureDimens.width}
                    height={measureDimens.height}
                  />
                )}
              </MeasureView>
            )
          }))
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aTb',
  description: 'aTb',
  keywords: ['Scale', 'Translation'],
  schema: z.object({
    object: z.enum([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ]),
    incorrectAScale: z.enum(['thin', 'wide', 'short', 'tall']),
    incorrectBScale: z.enum(['thin', 'wide', 'short', 'tall']),
    correctRotation: z.number().int().min(0).max(359),
    incorrectARotation: z.number().int().min(0).max(359),
    incorrectBRotation: z.number().int().min(0).max(359)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ] as const);

    const [incorrectAScale, incorrectBScale] = getRandomSubArrayFromArray(
      ['thin', 'wide', 'short', 'tall'] as const,
      2
    );

    const [correctRotation, incorrectARotation, incorrectBRotation] = randomUniqueIntegersInclusive(
      0,
      359,
      3
    );

    return {
      object,
      incorrectAScale,
      incorrectBScale,
      correctRotation,
      incorrectARotation,
      incorrectBRotation
    };
  },
  Component: props => {
    const {
      question: {
        object,
        incorrectAScale,
        incorrectBScale,
        correctRotation,
        incorrectARotation,
        incorrectBRotation
      },
      translate,
      displayMode
    } = props;

    const objectString = (() => {
      switch (object) {
        case 'Bike':
          return translate.objects.Bike();
        case 'Bus':
          return translate.objects.Bus();
        case 'Car':
          return translate.objects.Car();
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return translate.objects.Flower();
      }
    })();

    const objectSvgPath = (() => {
      switch (object) {
        case 'Bike':
        case 'Bus':
        case 'Car':
          return object;
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return `Flowers/${object}`;
      }
    })() as SvgName;

    const [incorrectAWidth, incorrectAHeight] = (() => {
      switch (incorrectAScale) {
        case 'thin':
          return [0.25, 0.85];
        case 'wide':
          return [1, 0.6];
        case 'short':
          return [0.85, 0.25];
        case 'tall':
          return [0.6, 1];
      }
    })();

    const [incorrectBWidth, incorrectBHeight] = (() => {
      switch (incorrectBScale) {
        case 'thin':
          return [0.25, 0.85];
        case 'wide':
          return [1, 0.6];
        case 'short':
          return [0.85, 0.25];
        case 'tall':
          return [0.6, 1];
      }
    })();

    const statements = shuffle(
      [
        {
          value: 'correct',
          width: 0.75,
          height: 0.75,
          rotation: correctRotation,
          isCorrect: true
        },
        {
          value: 'incorrectA',
          width: incorrectAWidth,
          height: incorrectAHeight,
          rotation: incorrectARotation,
          isCorrect: false
        },
        {
          value: 'incorrectB',
          width: incorrectBWidth,
          height: incorrectBHeight,
          rotation: incorrectBRotation,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAPictureOfObject(objectString)}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        itemLayout={'rowTall'}
        numItems={3}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <AssetSvg
              name={objectSvgPath}
              height={dimens.height * 0.3}
              style={{ alignSelf: 'center' }}
            />
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode !== 'digital'
                ? translate.instructions.circleScaleDrawingOfObject(objectString)
                : translate.instructions.selectScaleDrawingOfObject(objectString)}
            </Text>
          </View>
        )}
        renderItems={({ dimens }) =>
          statements.map(({ value, width, height, rotation }) => ({
            value,
            component: (
              <MeasureView
                style={[
                  {
                    transform: [
                      { scaleX: width },
                      { scaleY: height },
                      { rotate: `${rotation}deg` }
                    ],
                    width: dimens.width * width,
                    height: dimens.height * height,
                    maxWidth: dimens.width - 16,
                    maxHeight: dimens.height - 16,
                    margin: 16,
                    alignSelf: 'center'
                  }
                ]}
              >
                {measureDimens => (
                  <AssetSvg
                    name={objectSvgPath}
                    width={measureDimens.width}
                    height={measureDimens.height}
                  />
                )}
              </MeasureView>
            )
          }))
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTc',
  description: 'aTc',
  keywords: ['Scale'],
  schema: z.object({
    object: z.enum([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ]),
    itemARotation: z.number().int().min(0).max(359),
    itemBRotation: z.number().int().min(0).max(359),
    itemBScale: z.enum(['correct', 'thin', 'wide', 'short', 'tall'])
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'Bike',
      'Bus',
      'Car',
      'Flower_Blue',
      'Flower_Orange',
      'Flower_Purple',
      'Flower_Red',
      'Flower_White',
      'Flower_Yellow'
    ] as const);

    const itemBScale = getRandomFromArrayWithWeights(
      ['correct', 'thin', 'wide', 'short', 'tall'] as const,
      [4, 1, 1, 1, 1]
    );

    const [itemARotation, itemBRotation] = randomUniqueIntegersInclusive(0, 359, 2);

    return {
      object,
      itemARotation,
      itemBRotation,
      itemBScale
    };
  },
  Component: props => {
    const {
      question: { object, itemARotation, itemBRotation, itemBScale },
      translate,
      displayMode
    } = props;

    const objectString = (() => {
      switch (object) {
        case 'Bike':
          return translate.objects.Bike();
        case 'Bus':
          return translate.objects.Bus();
        case 'Car':
          return translate.objects.Car();
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return translate.objects.Flower();
      }
    })();

    const objectSvgPath = (() => {
      switch (object) {
        case 'Bike':
        case 'Bus':
        case 'Car':
          return object;
        case 'Flower_Blue':
        case 'Flower_Orange':
        case 'Flower_Purple':
        case 'Flower_Red':
        case 'Flower_White':
        case 'Flower_Yellow':
          return `Flowers/${object}`;
      }
    })() as SvgName;

    const [itemBWidth, itemBHeight] = (() => {
      switch (itemBScale) {
        case 'correct':
          return [0.7, 0.7];
        case 'thin':
          return [0.25, 0.75];
        case 'wide':
          return [1, 0.6];
        case 'short':
          return [0.75, 0.25];
        case 'tall':
          return [0.6, 1];
      }
    })();

    const statements = shuffle(
      [
        {
          value: 'itemA',
          width: 0.75,
          height: 0.75,
          rotation: itemARotation
        },
        {
          value: 'itemB',
          width: itemBWidth,
          height: itemBHeight,
          rotation: itemBRotation
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.hereIsAPictureOfObject(objectString)}
        correctAnswer={itemBScale === 'correct'}
        content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <AssetSvg
              name={objectSvgPath}
              height={dimens.height * 0.15}
              style={{ alignSelf: 'center' }}
            />
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode !== 'digital'
                ? translate.instructions.theseAreBothScaleDrawingsOfObjectPDF(objectString)
                : translate.instructions.theseAreBothScaleDrawingsOfObject(objectString)}
            </Text>
            <View
              style={{ height: dimens.height * 0.6, width: dimens.width, flexDirection: 'row' }}
            >
              {statements.map(({ width, height, rotation }, index) => (
                <MeasureView
                  key={index}
                  containerStyle={{ justifyContent: 'center' }}
                  style={[
                    {
                      transform: [
                        { scaleX: width },
                        { scaleY: height },
                        { rotate: `${rotation}deg` }
                      ],
                      width: dimens.width * width * 0.4,
                      height: dimens.height * height * 0.4,
                      maxWidth: dimens.width * 0.4 - 16,
                      maxHeight: dimens.height * 0.4 - 16,
                      margin: 16,
                      alignSelf: 'center'
                    }
                  ]}
                >
                  {measureDimens => (
                    <AssetSvg
                      name={objectSvgPath}
                      width={measureDimens.width}
                      height={measureDimens.height}
                    />
                  )}
                </MeasureView>
              ))}
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aTd',
  description: 'aTd',
  keywords: ['Scale', 'Draw'],
  schema: z.object({
    squareWidth: z.number().int().min(2).max(10).multipleOf(2)
  }),
  simpleGenerator: () => {
    const squareWidth = randomIntegerInclusiveStep(2, 10, 2);

    return { squareWidth };
  },
  Component: ({ question: { squareWidth }, translate, displayMode }) => {
    const square = createRectangleFromSquares(squareWidth / 2, squareWidth / 2);

    const isCorrectSize = (
      userAnswer: boolean[][],
      expectedWidth: number,
      expectedHeight: number
    ): boolean => {
      const answerDimens = getRectangleDimensions(userAnswer);

      return answerDimens.width === expectedWidth && answerDimens.height === expectedHeight;
    };

    return (
      <QF3Content
        title={translate.instructions.aSquareHasASideLengthOfNumCm(squareWidth)}
        pdfTitle={translate.instructions.aSquareHasASideLengthOfNumCmPDF(squareWidth)}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <QuadrilateralWithDimens
              dimens={{
                width: dimens.width * 0.3,
                height: dimens.height - 128
              }}
              x={squareWidth}
              y={squareWidth}
              widthLabel={translate.units.numberOfCm(squareWidth)}
              containerStyle={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}
            />
            <View
              style={{ width: dimens.width * 0.5, height: dimens.height, justifyContent: 'center' }}
            >
              <CreateShapeFromSquaresWithState
                id="createshape"
                dimens={{ width: dimens.width * 0.5, height: dimens.height }}
                numberOfCols={5}
                numberOfRows={5}
                defaultState={
                  displayMode === 'markscheme'
                    ? createGridAroundShape([5, 5], square, 'center')
                    : undefined
                }
                testCorrect={userAnswer =>
                  isSquareShape(userAnswer) &&
                  isCorrectSize(userAnswer, squareWidth / 2, squareWidth / 2)
                }
                cellSizeLabel={translate.units.numberOfCm(2)}
              />
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.shadingCanTakePlaceAnywhereOnGrid()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aTe',
  description: 'aTe',
  keywords: ['Scale', 'Draw'],
  schema: z.object({
    givenSquareScale: z.number().int().min(1).max(5),
    selectableCellWidth: z.number().int().min(3).max(8)
  }),
  simpleGenerator: () => {
    const givenSquareScale = randomIntegerInclusive(1, 5);

    const selectableCellWidth = randomIntegerInclusive(3, 8);

    return { givenSquareScale, selectableCellWidth };
  },
  Component: ({ question: { givenSquareScale, selectableCellWidth }, translate, displayMode }) => {
    const square = createRectangleFromSquares(givenSquareScale, givenSquareScale);

    const givenSquareWidth = selectableCellWidth * givenSquareScale;

    const isCorrectSize = (
      userAnswer: boolean[][],
      expectedWidth: number,
      expectedHeight: number
    ): boolean => {
      const answerDimens = getRectangleDimensions(userAnswer);

      return answerDimens.width === expectedWidth && answerDimens.height === expectedHeight;
    };

    return (
      <QF3Content
        title={translate.instructions.aSquareHasASideLengthOfNumCm(givenSquareWidth)}
        pdfTitle={translate.instructions.aSquareHasASideLengthOfNumCmPDF(givenSquareWidth)}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <QuadrilateralWithDimens
              dimens={{
                width: dimens.width * 0.3,
                height: dimens.height - 128
              }}
              x={givenSquareWidth}
              y={givenSquareWidth}
              widthLabel={translate.units.numberOfCm(givenSquareWidth)}
              containerStyle={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}
            />
            <View
              style={{ width: dimens.width * 0.5, height: dimens.height, justifyContent: 'center' }}
            >
              <CreateShapeFromSquaresWithState
                id="createshape"
                dimens={{ width: dimens.width * 0.5, height: dimens.height }}
                numberOfCols={5}
                numberOfRows={5}
                defaultState={
                  displayMode === 'markscheme'
                    ? createGridAroundShape([5, 5], square, 'center')
                    : undefined
                }
                testCorrect={userAnswer =>
                  isSquareShape(userAnswer) &&
                  isCorrectSize(userAnswer, givenSquareScale, givenSquareScale)
                }
                cellSizeLabel={translate.units.numberOfCm(selectableCellWidth)}
              />
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.shadingCanTakePlaceAnywhereOnGrid()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aTf',
  description: 'aTf',
  keywords: ['Scale', 'Draw'],
  schema: z.object({
    givenRectangleWidthScale: z.number().int().min(1).max(5),
    givenRectangleHeightScale: z.number().int().min(1).max(5),
    selectableCellWidth: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const [givenRectangleWidthScale, givenRectangleHeightScale] = randomUniqueIntegersInclusive(
      1,
      5,
      2
    );

    const selectableCellWidth = randomIntegerInclusive(3, 8);

    return { givenRectangleWidthScale, givenRectangleHeightScale, selectableCellWidth };
  },
  Component: ({
    question: { givenRectangleWidthScale, givenRectangleHeightScale, selectableCellWidth },
    translate,
    displayMode
  }) => {
    const rectangle = createRectangleFromSquares(
      givenRectangleWidthScale,
      givenRectangleHeightScale
    );

    const givenRectangleWidth = selectableCellWidth * givenRectangleWidthScale;

    const givenRectangleHeight = selectableCellWidth * givenRectangleHeightScale;

    const isCorrectSize = (
      userAnswer: boolean[][],
      expectedWidth: number,
      expectedHeight: number
    ): boolean => {
      const answerDimens = getRectangleDimensions(userAnswer);

      return answerDimens.width === expectedWidth && answerDimens.height === expectedHeight;
    };

    return (
      <QF3Content
        title={translate.instructions.aRectangleHasASideLengthOfNumCmAndWidthOfNumCm(
          givenRectangleHeight,
          givenRectangleWidth
        )}
        pdfTitle={translate.instructions.aRectangleHasASideLengthOfNumCmAndWidthOfNumCmPDF(
          givenRectangleHeight,
          givenRectangleWidth
        )}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-between' }]}>
            <QuadrilateralWithDimens
              dimens={{
                width: dimens.width * 0.3,
                height: dimens.height - 128
              }}
              x={givenRectangleWidth}
              y={givenRectangleHeight}
              widthLabel={translate.units.numberOfCm(givenRectangleWidth)}
              heightLabel={translate.units.numberOfCm(givenRectangleHeight)}
              containerStyle={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}
            />
            <View
              style={{ width: dimens.width * 0.5, height: dimens.height, justifyContent: 'center' }}
            >
              <CreateShapeFromSquaresWithState
                id="createshape"
                dimens={{ width: dimens.width * 0.5, height: dimens.height }}
                numberOfCols={5}
                numberOfRows={5}
                defaultState={
                  displayMode === 'markscheme'
                    ? createGridAroundShape([5, 5], rectangle, 'center')
                    : undefined
                }
                testCorrect={userAnswer =>
                  isRectangle(userAnswer) &&
                  isCorrectSize(userAnswer, givenRectangleWidthScale, givenRectangleHeightScale)
                }
                cellSizeLabel={translate.units.numberOfCm(selectableCellWidth)}
              />
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.shadingCanTakePlaceAnywhereOnGrid()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ScaleDrawing',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
