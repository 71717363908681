import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { getRandomFromArray, randomIntegerInclusive, seededRandom } from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { useMemo } from 'react';
import QF24aCreateShapeAndGivenShape from '../../../../components/question/questionFormats/QF24aCreateShapeAndGivenShape';
import {
  createRectangleFromSquares,
  isEqualPerimeter,
  isRectangle
} from '../../../../utils/shapes';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHy',
  description: 'aHy',
  keywords: ['Ruler', 'Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    squareWidth: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const squareWidth = randomIntegerInclusive(2, 8);

    return { squareWidth };
  },
  Component: props => {
    const {
      question: { squareWidth },
      translate
    } = props;

    const squareToUse = getRandomFromArray(
      [
        'Square/square_blue',
        'Square/square_green',
        'Square/square_pink',
        'Square/square_purple',
        'Square/square_yellow'
      ] as const,
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfSquare()}
        sentence={translate.answerSentences.ansCm()}
        testCorrect={[(squareWidth * 4).toString()]}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={10}
            items={[{ length: squareWidth, svgInfo: { name: squareToUse }, guidelines: false }]}
          />
        )}
        sentenceStyle={{ alignSelf: 'flex-end' }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aHz2',
  description: 'aHz',
  keywords: ['Ruler', 'Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    widthCm: z.number().int().min(2).max(7),
    heightCm: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const widthCm = randomIntegerInclusive(2, 7);
    const heightCm = randomIntegerInclusive(1, 3);

    return { widthCm, heightCm };
  },
  Component: props => {
    const {
      question: { widthCm, heightCm },
      translate,
      displayMode
    } = props;

    const perimeter = widthCm * 2 + heightCm * 2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfRectangle()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        testCorrect={[perimeter.toString()]}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={8}
            items={[
              {
                length: widthCm,
                imageInfo: (_pixelLength, pixelsPerUnit, _scaleFactor) => ({
                  crossAxisLength: dimens.height / 2,
                  startOffset: displayMode === 'digital' ? -80 : -100,
                  image: (
                    <QuadrilateralWithDimens
                      dimens={{
                        width: widthCm * pixelsPerUnit,
                        height: heightCm * pixelsPerUnit
                      }}
                      containerStyle={{ marginLeft: 0 }}
                      x={widthCm}
                      y={heightCm}
                      heightLabel={translate.units.numberOfCm(heightCm)}
                    />
                  )
                }),
                guidelines: false
              }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [perimeter.toLocaleString()]
        }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aHz',
  description: 'aHz',
  keywords: ['Ruler', 'Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    squareWidthCm: z.number().int().min(2).max(7),
    squareWidthMm: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const squareWidthCm = randomIntegerInclusive(2, 7);

    const squareWidthMm = randomIntegerInclusive(2, 8);

    return { squareWidthCm, squareWidthMm };
  },
  Component: props => {
    const {
      question: { squareWidthCm, squareWidthMm },
      translate
    } = props;

    const squareToUse = getRandomFromArray(
      [
        'Square/square_blue',
        'Square/square_green',
        'Square/square_pink',
        'Square/square_purple',
        'Square/square_yellow'
      ] as const,
      {
        random: seededRandom(props.question)
      }
    );

    const squareWidth = number(math.evaluate(`${squareWidthCm} + ${squareWidthMm / 10}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheSide()}
        sentence={translate.answerSentences.ansCmAnsMm()}
        testCorrect={userAnswer =>
          userAnswer[0] === squareWidthCm.toString() &&
          // ±1 margin of error for mm answer:
          (userAnswer[1] === squareWidthMm.toString() ||
            userAnswer[1] === (squareWidthMm - 1).toString() ||
            userAnswer[1] === (squareWidthMm + 1).toString())
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={8}
            items={[{ length: squareWidth, svgInfo: { name: squareToUse }, guidelines: false }]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [squareWidthCm.toLocaleString(), squareWidthMm.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHA',
  description: 'aHA',
  keywords: ['Ruler', 'Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    squareWidth: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const squareWidth = randomIntegerInclusive(2, 10);

    return { squareWidth };
  },
  Component: props => {
    const {
      question: { squareWidth },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsThePerimeterOfTheSquare()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width, height: dimens.height - 64 }}
            x={squareWidth}
            y={squareWidth}
            widthLabel={translate.units.numberOfCm(squareWidth)}
            heightLabel={translate.units.numberOfCm(squareWidth)}
          />
        )}
        testCorrect={[(squareWidth * 4).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aHB',
  description: 'aHB',
  keywords: ['Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    shapeAWidth: z.number().int().min(3).max(10),
    shapeAHeight: z.number().int().min(3).max(10),
    shapeBWidth: z.number().int().min(1).max(12),
    shapeBHeight: z.number().int().min(1).max(12),
    shapeToSelect: z.enum(['A', 'B'])
  }),
  simpleGenerator: () => {
    const shapeAWidth = randomIntegerInclusive(3, 10);

    const shapeAHeight = randomIntegerInclusive(3, 10);

    const shapeBWidth =
      shapeAWidth +
      randomIntegerInclusive(-2, 2, {
        constraint: x => x !== 0
      });

    const shapeBHeight =
      shapeAHeight +
      randomIntegerInclusive(-2, 2, {
        // Prevent the two shapes having the same perimeter:
        constraint: x => x !== 0 && x + shapeAHeight + shapeBWidth !== shapeAHeight + shapeAWidth
      });

    const shapeToSelect = getRandomFromArray(['A', 'B'] as const);

    return { shapeAWidth, shapeAHeight, shapeBWidth, shapeBHeight, shapeToSelect };
  },
  Component: props => {
    const {
      question: { shapeAWidth, shapeAHeight, shapeBWidth, shapeBHeight, shapeToSelect },
      translate
    } = props;

    const perimeter =
      shapeToSelect === 'A'
        ? 2 * shapeAWidth + 2 * shapeAHeight
        : 2 * shapeBWidth + 2 * shapeBHeight;

    // Shapes must be wrapped in useMemo to memoize isCorrect, and therefore allow the shapes to be selected:
    const shapes = useMemo(() => {
      return [
        {
          width: shapeAWidth,
          height: shapeAHeight,
          isCorrect: shapeToSelect === 'A'
        },
        {
          width: shapeBWidth,
          height: shapeBHeight,
          isCorrect: shapeToSelect === 'B'
        }
      ];
    }, [shapeAHeight, shapeAWidth, shapeBHeight, shapeBWidth, shapeToSelect]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whichShapeHasPerimeterOfXCm(perimeter)}
        testCorrect={shapes.filter(shape => shape.isCorrect).map(shape => shape)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape,
            component: (
              <QuadrilateralWithDimens
                dimens={{ width: dimens.width - 128, height: dimens.height - 128 }}
                x={shape.width}
                y={shape.height}
                widthLabel={translate.units.numberOfCm(shape.width)}
                heightLabel={translate.units.numberOfCm(shape.height)}
                containerStyle={{ marginTop: 0, marginLeft: 0, alignSelf: 'center' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHC',
  description: 'aHC',
  keywords: ['Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    squareSize: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const squareSize = randomIntegerInclusive(2, 5);

    return { squareSize };
  },
  Component: props => {
    const {
      question: { squareSize },
      translate
    } = props;

    const expectedPerimeter = squareSize * 4 + 2;

    return (
      <QF24aCreateShapeAndGivenShape
        title={translate.instructions.tapSquaresToCreateRectWithPerimeterXGreaterThanShadedSquare(
          translate.units.numberOfCm(2)
        )}
        pdfTitle={translate.instructions.shadeSquaresToCreateRectWithPerimeterXGreaterThanShadedSquare(
          translate.units.numberOfCm(2)
        )}
        givenShape={createRectangleFromSquares(squareSize, squareSize)}
        numberOfRows={5}
        numberOfCols={6}
        createCellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, expectedPerimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(expectedPerimeter)
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aHD',
  description: 'aHD',
  keywords: ['Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    squareSize: z.number().int().min(3).max(5)
  }),
  simpleGenerator: () => {
    const squareSize = randomIntegerInclusive(3, 5);

    return { squareSize };
  },
  Component: props => {
    const {
      question: { squareSize },
      translate
    } = props;

    const expectedPerimeter = squareSize * 4 - 2;

    return (
      <QF24aCreateShapeAndGivenShape
        title={translate.instructions.tapSquaresToCreateRectWithPerimeterXLessThanShadedSquare(
          translate.units.numberOfCm(2)
        )}
        pdfTitle={translate.instructions.shadeSquaresToCreateRectWithPerimeterXLessThanShadedSquare(
          translate.units.numberOfCm(2)
        )}
        givenShape={createRectangleFromSquares(squareSize, squareSize)}
        numberOfRows={5}
        numberOfCols={6}
        createCellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, expectedPerimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(expectedPerimeter)
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasurePerimeter',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
