import { NumberLineVariableTickWithState } from '../representations/Number Line/NumberLineVariableTick';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { useContext, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DisplayMode } from '../../../contexts/displayMode';
import { isEqual } from '../../../utils/matchers';
import { parseSymbolsToString } from '../../../utils/parse';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { TitleStyleProps } from '../../molecules/TitleRow';

type Props = TitleStyleProps & {
  title: string;
  start: number;
  end: number;
  tickValues: { label: string; position: number }[];
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  jumpArrowArray?: { start: number; end: number; label: string }[];
  /** Flag for whether number line is subtraction or not i.e. jump arrows reversed */
  subtraction?: boolean;
  extraSymbol?: ExtraSymbols;
  /** PDF Question Height */
  questionHeight?: number;
  /**
   * Flag to determine whether minimum spacing should be ignored or not. Needed for some Qs with awkwardly-placed answer boxes.
   */
  ignoreMinSpacing?: boolean;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

export default function QF25JumpOnANumberLine({
  title,
  start,
  end,
  tickValues,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  jumpArrowArray,
  subtraction,
  extraSymbol,
  ignoreMinSpacing,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles();

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView containerStyle={styles.pdfMainPanelContainer}>
              {dimens => (
                <NumberLineVariableTickWithState
                  id="numberline"
                  dimens={{ height: dimens.height, width: dimens.width * 0.9 }}
                  tickValues={tickValues}
                  start={start}
                  end={end}
                  jumpArrowArray={jumpArrowArray}
                  subtraction={subtraction}
                  defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                  ignoreMinSpacing={ignoreMinSpacing}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={() => (
        <MeasureView>
          {dimens => (
            <NumberLineVariableTickWithState
              id="numberline"
              dimens={dimens}
              tickValues={tickValues}
              start={start}
              end={end}
              jumpArrowArray={jumpArrowArray}
              subtraction={subtraction}
              testCorrect={testCorrect}
              ignoreMinSpacing={ignoreMinSpacing}
            />
          )}
        </MeasureView>
      )}
      {...props}
    />
  );
}

const useStyles = () => {
  return StyleSheet.create({
    pdfMainPanelContainer: {
      marginRight: 100
    }
  });
};
