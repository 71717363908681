import { all, create, format, fraction, number } from 'mathjs';
import { arraysHaveSameContents } from './collections';
import { greatestCommonDivisor } from './multiples';
import { TranslationFunctions } from 'common/src/i18n/i18n-types';
import { ADD, DIV, MULT, SUB } from '../constants';
import { AssetSvg } from 'common/src/assets/svg';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

type FractionOperation = ADD | DIV | MULT | SUB;

export type Fraction = [numerator: number, denominator: number];

export type MixedFraction = [number, ...Fraction];

/**
 * Simplify given fraction
 * - Does not handle negative fractions
 * - If it is an improper fraction that simplifies to a fraction with a denominator of 1 then
 * it will return just an integer (the numerator)
 * - Use improperFractionToMixedNumber to simplify an improper fraction to a mixed fraction
 */
export function simplify(numerator: number, denominator: number): Fraction {
  const divisor = greatestCommonDivisor([Math.abs(numerator), Math.abs(denominator)]);

  return [numerator / divisor, denominator / divisor];
}

/**
 * Determines is 2 fractions are are equivalent (simplify down to the same fraction).
 */
export function isEquivalentFraction(
  numeratorA: number,
  denominatorA: number,
  numeratorB: number,
  denominatorB: number
): boolean {
  return arraysHaveSameContents(
    simplify(numeratorA, denominatorA),
    simplify(numeratorB, denominatorB)
  );
}

/**
 * Convert and simplify improper fraction to a mixed fraction (does not handle negatives)
 * Returns array of [integer, numerator, denominator] or just an integer if fraction
 */
export function improperFractionToMixedNumber(
  numerator: number,
  denominator: number,
  isSimplified = true
): MixedFraction {
  const integer = Math.floor(numerator / denominator);
  numerator -= integer * denominator;

  const simplified = isSimplified
    ? simplify(numerator, denominator)
    : ([numerator, denominator] as Fraction);

  return [integer, ...simplified];
}

/**
 * Convert mixed fraction to improper fraction (does not handle negatives)
 *
 * Note - Fraction will not be simplified.
 */
export function mixedNumberToImproperFraction(
  integer: number,
  numerator: number,
  denominator: number
): Fraction {
  return [numerator + integer * denominator, denominator];
}

/**
 * Convert fraction to decimal
 *
 * @param {number} numerator - (handles negatives)
 * @param {number} denominator
 * @param {number} numOfDecimals - number of decimal places to round to (default = 10)
 *
 * Note - Does not handle mixed fractions, use mixedNumberToImproperFraction() first
 * WARNING - If used with a user input denominator, an error could be thrown if zero is input (division by zero)
 */
export function fractionToDecimal(
  numerator: number,
  denominator: number,
  numOfDecimals = 10
): number {
  return Number(format(numerator / denominator, { notation: 'fixed', precision: numOfDecimals }));
}

/**
 * Convert decimal to fraction
 *
 * @param decimal decimal number to convert to fraction (handles negatives)
 * @returns fraction; improper fraction if decimal is > 1
 */
export function decimalToFraction(decimal: number): [number, number] {
  const { s: sign, n: numerator, d: denominator } = fraction(decimal);

  return [sign * numerator, denominator];
}

/**
 * Returns decimal/fraction/percentage markup for a given numerator and denominator
 *
 * @param numerator value to convert
 * @param denominator value to convert
 * @param conversion what to convert it to
 * @returns markup string, using our markup language as defined in `parseMarkup`
 */
export function formatFractionToMarkup(
  numerator: number,
  denominator: number,
  conversion: 'percentage' | 'fraction' | 'decimal'
): string {
  if (conversion === 'decimal')
    return number(math.evaluate(`${numerator}/${denominator}`)).toLocaleString();
  if (conversion === 'percentage')
    return `${number(math.evaluate(`(${numerator}/${denominator})*100`)).toLocaleString()}%`;
  else return `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`;
}

/**
 * Comapre fraction answers by simplifying both and comparing
 */
export function compareFractions(
  userAnswer: readonly (number | string)[],
  correctAnswer: readonly number[]
): boolean {
  const userAnswersNumbers = userAnswer.map(ans => (typeof ans === 'string' ? parseInt(ans) : ans));

  // Handle if fraction is mixed
  const simplifiedCorrect =
    correctAnswer.length === 2
      ? simplify(correctAnswer[0], correctAnswer[1])
      : simplify(correctAnswer[1], correctAnswer[2]);
  const simplifiedUser =
    userAnswersNumbers.length === 2
      ? simplify(userAnswersNumbers[0], userAnswersNumbers[1])
      : simplify(userAnswersNumbers[1], userAnswersNumbers[2]);

  const integerCorrect =
    correctAnswer.length === 3 ? correctAnswer[0] === userAnswersNumbers[0] : true;

  // Compare numerator and denominator
  return arraysHaveSameContents(simplifiedCorrect, simplifiedUser) && integerCorrect;
}

/**
 * Convert portion to string e.g. 6 => sixth, use numOfPortions to utilise the correct pluralisation translation
 */
export function portionToText(portion: number, translate: TranslationFunctions, numOfPortions = 2) {
  switch (portion) {
    case 2:
      return translate.fractions.halves(numOfPortions);
    case 3:
      return translate.fractions.thirds(numOfPortions);
    case 4:
      return translate.fractions.quarters(numOfPortions);
    case 5:
      return translate.fractions.fifths(numOfPortions);
    case 6:
      return translate.fractions.sixths(numOfPortions);
    case 7:
      return translate.fractions.sevenths(numOfPortions);
    case 8:
      return translate.fractions.eighths(numOfPortions);
    case 9:
      return translate.fractions.ninths(numOfPortions);
    case 10:
      return translate.fractions.tenths(numOfPortions);
  }
}

/**
 * Arithmetic operations between 2 fractions; handles mixed or improper fractions.
 * Will always return either an improper/proper fraction.
 */
export function fractionArithmetic(
  fractionA: readonly number[],
  fractionB: readonly number[],
  operation: FractionOperation
): Fraction {
  let [numeratorA, denominatorA] = fractionA;
  let [numeratorB, denominatorB] = fractionB;

  if (fractionA.length === 3) {
    [numeratorA, denominatorA] = mixedNumberToImproperFraction(...(fractionA as MixedFraction));
  }

  if (fractionB.length === 3) {
    [numeratorB, denominatorB] = mixedNumberToImproperFraction(...(fractionB as MixedFraction));
  }

  const math = create(all, { number: 'Fraction' });

  let result: math.Fraction;

  switch (operation) {
    case ADD:
      result = math.add(
        math.fraction(numeratorA, denominatorA),
        math.fraction(numeratorB, denominatorB)
      );
      break;
    case SUB:
      result = math.subtract(
        math.fraction(numeratorA, denominatorA),
        math.fraction(numeratorB, denominatorB)
      );
      break;
    case MULT:
      result = math.multiply(
        math.fraction(numeratorA, denominatorA),
        math.fraction(numeratorB, denominatorB)
      ) as math.Fraction;
      break;
    case DIV:
      result = math.divide(
        math.fraction(numeratorA, denominatorA),
        math.fraction(numeratorB, denominatorB)
      ) as math.Fraction;
      break;
  }

  return [result.s * result.n, result.d];
}

export const renderFractionCounter = (amount: number, width = 70) => {
  switch (amount) {
    case 2:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_2`} width={width} />;
    case 3:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_3`} width={width} />;
    case 4:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_4`} width={width} />;
    case 5:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_5`} width={width} />;
    case 6:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_6`} width={width} />;
    case 7:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_7`} width={width} />;
    case 8:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_8`} width={width} />;
    case 9:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_9`} width={width} />;
    case 10:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_10`} width={width} />;
    case 11:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_11`} width={width} />;
    case 12:
      return <AssetSvg name={`Counters_fractions/Counters_unit_fractions1_12`} width={width} />;
    default:
      throw new Error(`${amount} not valid.`);
  }
};

/**
 * Takes in a mixed number and returns a whole number
 * If mixed number doesn't equal an integer it simply returns back the original fraction
 */
export const convertMixedNumberToWhole = (
  whole: number,
  numerator: number,
  denominator: number
): number[] => {
  if (numerator === denominator) {
    return [whole + 1];
  } else if (numerator % denominator === 0) {
    return [whole + Math.floor(numerator / denominator)];
  } else {
    return [whole, numerator, denominator];
  }
};
