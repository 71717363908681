// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplyUpToA4DigitNumberByA1DigitNumber from './1MultiplyUpToA4DigitNumberByA1DigitNumber';
import MultiplyA2DigitNumberByA2DigitNumberAreaModel from './2MultiplyA2DigitNumberByA2DigitNumberAreaModel';
import MultiplyA2DigitNumberByA2DigitNumber from './3MultiplyA2DigitNumberByA2DigitNumber';
import MultiplyA3DigitNumberByA2DigitNumber from './4MultiplyA3DigitNumberByA2DigitNumber';
import MultiplyA4DigitNumberByA2DigitNumber from './5MultiplyA4DigitNumberByA2DigitNumber';
import SolveProblemsWithMultiplication from './6SolveProblemsWithMultiplication';
import ShortDivision from './7ShortDivision';
import DivideA4DigitNumberByA1DigitNumber from './8DivideA4DigitNumberByA1DigitNumber';
import DivideWithRemainders from './9DivideWithRemainders';
import EfficientDivision from './10EfficientDivision';
import SolveProblemsWithMultiplicationAndDivision from './11SolveProblemsWithMultiplicationAndDivision';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    MultiplyUpToA4DigitNumberByA1DigitNumber, // Step 1
    MultiplyA2DigitNumberByA2DigitNumberAreaModel, // Step 2
    MultiplyA2DigitNumberByA2DigitNumber, // Step 3
    MultiplyA3DigitNumberByA2DigitNumber, // Step 4
    MultiplyA4DigitNumberByA2DigitNumber, // Step 5
    SolveProblemsWithMultiplication, // Step 6
    ShortDivision, // Step 7
    DivideA4DigitNumberByA1DigitNumber, // Step 8
    DivideWithRemainders, // Step 9
    EfficientDivision, // Step 10
    SolveProblemsWithMultiplicationAndDivision // Step 11
  ]
});
export default Block;
