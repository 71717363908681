import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectNames,
  objectSchema
} from 'common/src/utils/objects';
import { useMemo } from 'react';
import { View } from 'react-native';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import QF30GroupCountersAndSentence from 'common/src/components/question/questionFormats/QF30GroupCountersAndSentence';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4 from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from 'common/src/components/typography/Text';
import { getObjectImage, getObjectSvgName } from 'common/src/utils/objectsImages';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import {
  countRange,
  filledArray,
  nestedArrayHasNoDuplicates,
  range
} from 'common/src/utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Table from '../../../../components/molecules/Table';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ajE',
  description: 'ajE',
  keywords: ['Group'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(10),
      numberPerGroup: z.number().int().min(2).max(6),
      object: objectSchema
    })
    .refine(
      val => val.groups !== val.numberPerGroup,
      'groups and numberPerGroup must be different.'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 10);

    const numberPerGroup = randomIntegerInclusive(2, 6, {
      constraint: x => x !== groups
    });

    const object = getRandomObject();

    return { groups, numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { groups, numberPerGroup, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsX(containerPlural),
          translate.answerSentences.eachGroupHasAnsObjects(containerSingular, objectPlural),
          translate.answerSentences.thereAreAnsEqualGroupsOfAns()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [groups.toString()],
          [numberPerGroup.toString()],
          [groups.toString(), numberPerGroup.toString()]
        ]}
        {...props}
        Content={({ dimens }) => {
          const topLine = groups <= 5 ? groups : Math.ceil(groups / 2);
          const botLine = groups - topLine;
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, topLine).map(index => (
                  <View key={index}>
                    {getObjectImage(object, numberPerGroup, dimens.height / 2)}
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {botLine !== 0 &&
                  range(1, botLine).map(index => (
                    <View key={index}>
                      {getObjectImage(object, numberPerGroup, dimens.height / 2)}
                    </View>
                  ))}
              </View>
            </View>
          );
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ajF',
  description: 'ajF',
  keywords: ['Group'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(12),
      numberPerGroup: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.groups !== val.numberPerGroup,
      'groups and numberPerGroup must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 12);

    const numberPerGroup = randomIntegerInclusive(1, 6, {
      constraint: x => x !== groups
    });

    return { groups, numberPerGroup };
  },
  Component: props => {
    const {
      question: { groups, numberPerGroup },
      translate,
      displayMode
    } = props;

    const counterColor = getRandomFromArray(Object.values(ArrayOfObjectsColors), {
      random: seededRandom(props.question)
    }) as string;

    const generateArrayData = (countersPerSquare: number): number[][] => {
      if (countersPerSquare === 2) {
        return [[1, 1]];
      }
      const halfCount = countersPerSquare / 2;
      const topRowCount = Math.ceil(halfCount);
      const bottomRowCount = Math.floor(halfCount);
      const topRow: number[] = [];
      const bottomRow: number[] = [];
      countRange(topRowCount).forEach(() => topRow.push(1));
      countRange(bottomRowCount).forEach(() => bottomRow.push(1));
      return [topRow, bottomRow];
    };
    const arrayData = generateArrayData(numberPerGroup);

    const arrayOfSquares = filledArray(filledArray('white', 1), 1);

    const groupsArray = countRange(groups);

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.thereAreAnsEqualGroupsOfAns()}
        title={translate.instructions.completeSentence()}
        testCorrect={[groups.toString(), numberPerGroup.toString()]}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', gap: 30 }}
          >
            {groupsArray.map(num => {
              return (
                <Table
                  key={num}
                  items={arrayOfSquares.map(rowColors =>
                    rowColors.map(number => (
                      <View
                        key={number}
                        style={{
                          width: dimens.width * 0.2,
                          height: displayMode === 'digital' ? 100 : 150,
                          alignItems: 'center',
                          justifyContent: 'space-around'
                        }}
                      >
                        {arrayData.map((arr, i) => {
                          return arr.length ? (
                            <ArrayOfObjects
                              key={i}
                              color={counterColor}
                              dimens={{
                                height: dimens.height * 0.15,
                                width: dimens.width * 0.15
                              }}
                              rowStyle={{
                                justifyContent: 'space-evenly'
                              }}
                              rows={1}
                              counterSize={Math.ceil(
                                (dimens.height * 0.2) /
                                  (arrayData.length > 1 ? arrayData.length : 2)
                              )}
                              columns={arr.length}
                            />
                          ) : null;
                        })}
                      </View>
                    ))
                  )}
                />
              );
            })}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ajG',
  description: 'ajG',
  keywords: ['Group'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(4),
    numberA2: z.number().int().min(2).max(6),
    objectA: objectSchema,
    numberB1: z.number().int().min(2).max(4),
    numberB2: z.number().int().min(2).max(6),
    objectB: objectSchema,
    numberC1: z.number().int().min(2).max(4),
    numberC2: z.number().int().min(2).max(6),
    objectC: objectSchema,
    numberD1: z.number().int().min(2).max(4),
    numberD2: z.number().int().min(2).max(6),
    objectD: objectSchema
  }),
  simpleGenerator: () => {
    const { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 } =
      rejectionSample(
        () => {
          // Generate 4 random integers independently
          const [numberA1, numberB1, numberC1, numberD1] = range(1, 4).map(() =>
            randomIntegerInclusive(2, 4)
          );
          const [numberA2, numberB2, numberC2, numberD2] = range(1, 4).map(() =>
            randomIntegerInclusive(2, 6)
          );
          return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
        },
        // Only permit them if their products are all different.
        ({ numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 }) =>
          nestedArrayHasNoDuplicates([
            [numberA1, numberA2],
            [numberB1, numberB2],
            [numberC1, numberC2],
            [numberD1, numberD2]
          ])
      );

    const [objectA, objectB, objectC, objectD] = getRandomSubArrayFromArray(objectNames, 4);

    return {
      numberA1,
      numberA2,
      objectA,
      numberB1,
      numberB2,
      objectB,
      numberC1,
      numberC2,
      objectC,
      numberD1,
      numberD2,
      objectD
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        objectA,
        numberB1,
        numberB2,
        objectB,
        numberC1,
        numberC2,
        objectC,
        numberD1,
        numberD2,
        objectD
      },
      translate,
      displayMode
    } = props;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 80, width: 450 } : { height: 150, width: 600 };

    // Randomly order these statements
    const images = shuffle(
      [
        {
          lhsComponent: (
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={numberA1}
              customImage={
                <AssetSvg name={getObjectSvgName(objectA, numberA2)} height={dimens.height} />
              }
              rowStyle={{ columnGap: 8, flex: 1, justifyContent: 'flex-end' }}
            />
          ),
          correctAnswer: 'A'
        },
        {
          lhsComponent: (
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={numberB1}
              customImage={
                <AssetSvg name={getObjectSvgName(objectB, numberB2)} height={dimens.height} />
              }
              rowStyle={{ columnGap: 8, flex: 1, justifyContent: 'flex-end' }}
            />
          ),
          correctAnswer: 'B'
        },
        {
          lhsComponent: (
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={numberC1}
              customImage={
                <AssetSvg name={getObjectSvgName(objectC, numberC2)} height={dimens.height} />
              }
              rowStyle={{ columnGap: 8, flex: 1, justifyContent: 'flex-end' }}
            />
          ),
          correctAnswer: 'C'
        },
        {
          lhsComponent: (
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={numberD1}
              customImage={
                <AssetSvg name={getObjectSvgName(objectD, numberD2)} height={dimens.height} />
              }
              rowStyle={{ columnGap: 8, flex: 1, justifyContent: 'flex-end' }}
            />
          ),
          correctAnswer: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    const items = [
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 30 : 32 }}>
            {translate.answerSentences.xEqualGroupsOfY(numberA1, numberA2)}
          </Text>
        ),
        value: 'A'
      },
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 30 : 32 }}>
            {translate.answerSentences.xEqualGroupsOfY(numberB1, numberB2)}
          </Text>
        ),
        value: 'B'
      },
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 30 : 32 }}>
            {translate.answerSentences.xEqualGroupsOfY(numberC1, numberC2)}
          </Text>
        ),
        value: 'C'
      },
      {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 30 : 32 }}>
            {translate.answerSentences.xEqualGroupsOfY(numberD1, numberD2)}
          </Text>
        ),
        value: 'D'
      }
    ];

    return (
      <QF6DragMatchStatements
        moveOrCopy="move"
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        title={translate.instructions.dragTheCardsToMatchTheStatementsWithPictures()}
        pdfTitle={translate.instructions.useTheCardsToMatchTheStatementsWithPictures()}
        items={items}
        statements={images}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ajH',
  description: 'ajH',
  keywords: ['Group'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(4),
    numberA2: z.number().int().min(2).max(6),
    objectA: objectSchema,
    numberB1: z.number().int().min(2).max(4),
    numberB2: z.number().int().min(2).max(6),
    objectB: objectSchema,
    numberC1: z.number().int().min(2).max(4),
    numberC2: z.number().int().min(2).max(6),
    objectC: objectSchema,
    numberD1: z.number().int().min(2).max(4),
    numberD2: z.number().int().min(2).max(6),
    objectD: objectSchema
  }),
  simpleGenerator: () => {
    const { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 } =
      rejectionSample(
        () => {
          // Generate 4 random integers independently
          const [numberA1, numberB1, numberC1, numberD1] = range(1, 4).map(() =>
            randomIntegerInclusive(2, 4)
          );
          const [numberA2, numberB2, numberC2, numberD2] = range(1, 4).map(() =>
            randomIntegerInclusive(2, 6)
          );
          return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
        },
        // Only permit them if their products are all different.
        ({ numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 }) =>
          nestedArrayHasNoDuplicates([
            [numberA1, numberA2],
            [numberB1, numberB2],
            [numberC1, numberC2],
            [numberD1, numberD2]
          ])
      );

    const [objectA, objectB, objectC, objectD] = getRandomSubArrayFromArray(objectNames, 4);

    return {
      numberA1,
      numberA2,
      objectA,
      numberB1,
      numberB2,
      objectB,
      numberC1,
      numberC2,
      objectC,
      numberD1,
      numberD2,
      objectD
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        objectA,
        numberB1,
        numberB2,
        objectB,
        numberC1,
        numberC2,
        objectC,
        numberD1,
        numberD2,
        objectD
      },
      translate
    } = props;

    // Randomly order these statements
    const images = useMemo(() => {
      const lineA = {
        number1: numberA1,
        number2: numberA2,
        object: objectA,
        isCorrect: true
      };

      const lineB = {
        number1: numberB1,
        number2: numberB2,
        object: objectB,
        isCorrect: false
      };

      const lineC = {
        number1: numberC1,
        number2: numberC2,
        object: objectC,
        isCorrect: false
      };

      const lineD = {
        number1: numberD1,
        number2: numberD2,
        object: objectD,
        isCorrect: false
      };

      return shuffle([lineA, lineB, lineC, lineD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      objectA,
      objectB,
      objectC,
      objectD,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichPictureShowsXEqualGroupsOfY(numberA1, numberA2)}`}
        pdfTitle={`${translate.instructions.whichPictureShowsXEqualGroupsOfYPDF(
          numberA1,
          numberA2
        )}`}
        testCorrect={images.filter(line => line.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return images.map(line => ({
            value: line,
            component: (
              <ArrayOfObjects
                dimens={dimens}
                rows={1}
                columns={line.number1}
                customImage={getObjectImage(
                  line.object,
                  line.number2,
                  dimens.height - 10,
                  dimens.width / (line.number1 + 1)
                )}
                rowStyle={{ columnGap: 8, flex: 1 }}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ajI',
  description: 'ajI',
  keywords: ['Group'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      countersPerGroup: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.groups !== val.countersPerGroup,
      'groups and countersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);

    const countersPerGroup = randomIntegerInclusive(2, 5, {
      constraint: x => x !== groups && x * groups < 21
    });

    return { groups, countersPerGroup };
  },
  Component: ({ question: { groups, countersPerGroup }, translate }) => {
    const counters = groups * countersPerGroup;
    return (
      <QF30GroupCountersAndSentence
        title={`${translate.instructions.dragCountersToCreateEqualGroups(
          counters.toLocaleString(),
          groups.toLocaleString()
        )}<br/>${translate.instructions.completeSentence()}`}
        sentence={translate.answerSentences.thereAreAnsCountersInEachGroup()}
        testCorrect={[countersPerGroup.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'ajJ',
  description: 'ajJ',
  keywords: ['Group'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      countersPerGroup: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.groups !== val.countersPerGroup,
      'groups and countersPerGroup must be different.'
    ),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);

    const countersPerGroup = randomIntegerInclusive(2, 5, {
      constraint: x => x !== groups && x * groups < 21
    });

    return { groups, countersPerGroup };
  },
  Component: ({ question: { groups, countersPerGroup }, translate }) => {
    const counters = groups * countersPerGroup;
    return (
      <QF30GroupCountersAndSentence
        title={`${translate.instructions.dragCountersToCreateEqualGroupsOfX(
          counters.toLocaleString(),
          countersPerGroup.toLocaleString()
        )}<br/>${translate.instructions.completeSentence()}`}
        sentence={translate.answerSentences.thereAreAnsGroupsOfXCounters(
          countersPerGroup.toLocaleString()
        )}
        testCorrect={[groups.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplicationEqualGroups',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
