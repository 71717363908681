import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { randomIntegerInclusive, shuffle } from 'common/src/utils/random';
import { z } from 'zod';
import { DIV } from 'common/src/constants';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { randomUniqueIntegersInclusive } from 'common/src/utils/random';
import { View } from 'react-native';
import {
  objectSchema,
  getRandomObject,
  containerOfObject,
  objectAsWord,
  objectPrepositionWord
} from 'common/src/utils/objects';
import { nameSchema, getRandomName } from 'common/src/utils/names';
import { filledArray, range } from 'common/src/utils/collections';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akv',
  description: 'akv',
  keywords: ['Divide', '4'],
  schema: z.object({
    countersPerGroup: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const countersPerGroup = randomIntegerInclusive(2, 8);

    return { countersPerGroup };
  },
  Component: ({ question: { countersPerGroup }, translate, displayMode }) => {
    const counters = 4 * countersPerGroup;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[
          `${translate.answerSentences.XDividedIntoYEqualGroupsIsAns(
            counters.toLocaleString(),
            (4).toLocaleString()
          )}`,
          `${counters.toLocaleString()} ${DIV} ${(4).toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[countersPerGroup.toString()], [countersPerGroup.toString()]]}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akw',
  description: 'akw',
  keywords: ['Divide', '4', 'Array'],
  schema: z.object({
    columnsOrRows: z.number().int().min(2).max(10)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const columnsOrRows = randomIntegerInclusive(2, 10);

    return { columnsOrRows };
  },
  Component: props => {
    const {
      question: { columnsOrRows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.howManyGroupsOfNumAreInArray((4).toLocaleString()),
          `${(columnsOrRows * 4).toLocaleString()} ${DIV} ${(4).toLocaleString()} = <ans/>`
        ]}
        title={translate.instructions.hereIsAnArrayOfNumCounters(columnsOrRows * 4)}
        testCorrect={[[columnsOrRows.toString()], [columnsOrRows.toString()]]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            // Ensure there's no more than 5 rows, to save on space.
            rows={columnsOrRows < 6 ? columnsOrRows : 4}
            columns={columnsOrRows < 6 ? 4 : columnsOrRows}
            dimens={dimens}
          />
        )}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akx',
  description: 'akx',
  keywords: ['Divide', '4', 'Number line'],
  schema: z.object({
    answer: z.number().int().min(3).max(8)
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(3, 8);

    return { answer };
  },
  Component: props => {
    const {
      question: { answer },
      translate
    } = props;

    const tickValues = range(0, 40, 4);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToCompleteDiv()}
        testCorrect={[answer.toString()]}
        sentence={`${(answer * 4).toLocaleString()} ${DIV} ${(4).toLocaleString()} = <ans/>`}
        Content={({ dimens }) => <NumberLine dimens={dimens} tickValues={tickValues} />}
        pdfDirection="column"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aky',
  description: 'aky',
  keywords: ['Divide', '4', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 10);

    return { number };
  },

  Component: ({ question: { number }, translate, ...props }) => {
    const barArray = filledArray(4, number);

    const product = number * 4;

    const numbers = [[product], barArray];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.workOutX(`${product.toLocaleString()} ${DIV} 4`)}
        testCorrect={[number.toString()]}
        sentence={`${product.toLocaleString()} ${DIV} 4 = <ans/>`}
        {...props}
        Content={({ dimens }) => (
          <BarModel numbers={numbers} total={product} dimens={dimens} sameRowColor />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'akz',
  description: 'akz',
  keywords: ['Divide', '4', 'Problem'],
  schema: z.object({
    groups: z.number().int().min(2).max(10),
    object: objectSchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 10);

    const object = getRandomObject();

    const name = getRandomName();

    return { groups, object, name };
  },
  Component: props => {
    const {
      question: { groups, object, name },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansObject(containerPlural)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.characterHasNumObjectsAndSomeGroups({
          name,
          num1: groups * 4,
          objectPlural,
          containerPlural,
          num2: 4,
          preposition,
          containerSingular
        })}
        testCorrect={[groups.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center', alignItems: 'center' }]}>
            <AssetSvg name={getObjectSvgName(object, 4)} height={dimens.height * 0.8} />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'akA',
  description: 'akA',
  keywords: ['Divide', '4'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(40)
      .array()
      .length(6)
      .refine(numbers => numbers.filter(x => x % 4 === 0).length === 2, 'Only 2 multiples of 4')
  }),
  simpleGenerator: () => {
    const multiples = randomUniqueIntegersInclusive(1, 40, 2, {
      constraint: x => x % 4 === 0
    });
    const notMultiples = randomUniqueIntegersInclusive(1, 40, 4, {
      constraint: x => x % 4 !== 0
    });

    const numbers = shuffle([...multiples, ...notMultiples]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatCanBeDividedEquallyByNum(4)}
        pdfTitle={translate.instructions.circleNumbersThatCanBeDividedEquallyByNum(4)}
        testCorrect={numbers.filter(it => it % 4 === 0)}
        multiSelect
        items={() =>
          numbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy4',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
