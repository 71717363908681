// Autogenerated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UnderstandAnglesAsTurns from './1UnderstandAnglesAsTurns';
import IdentifyAngles from './2IdentifyAngles';
import CompareAndOrderAngles from './3CompareAndOrderAngles';
import Triangles from './4Triangles';
import Quadrilaterals from './5Quadrilaterals';
import Polygons from './6Polygons';
import LinesOfSymmetry from './7LinesOfSymmetry';
import CompleteASymmetricFigure from './8CompleteASymmetricFigure';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [8, 9],
  smallSteps: [
    UnderstandAnglesAsTurns, // Step 1
    IdentifyAngles, // Step 2
    CompareAndOrderAngles, // Step 3
    Triangles, // Step 4
    Quadrilaterals, // Step 5
    Polygons, // Step 6
    LinesOfSymmetry, // Step 7
    CompleteASymmetricFigure // Step 8
  ]
});
export default Block;
