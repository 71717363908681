import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import TextStructure from './TextStructure';

export type TitleStyleProps = {
  titleStyle?: StyleProp<ViewStyle>;
  titleTextStyle?: StyleProp<TextStyle>;
  titleFractionContainerStyle?: StyleProp<ViewStyle>;
  titleFractionTextStyle?: StyleProp<TextStyle>;
  titleFractionDividerStyle?: StyleProp<ViewStyle>;
};

type Props = TitleStyleProps & {
  title: string | JSX.Element;
};

export default function TitleRow({
  title,
  titleStyle,
  titleTextStyle,
  titleFractionContainerStyle,
  titleFractionTextStyle,
  titleFractionDividerStyle
}: Props) {
  return (
    <View style={styles.container}>
      {typeof title === 'string' ? (
        <TextStructure
          sentence={title}
          style={[styles.title, titleStyle]}
          textStyle={titleTextStyle}
          fractionContainerStyle={titleFractionContainerStyle}
          fractionTextStyle={[{ fontSize: 32 }, titleFractionTextStyle]}
          fractionDividerStyle={titleFractionDividerStyle}
        />
      ) : (
        title
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    paddingHorizontal: 32
  },

  title: {
    marginTop: 0
  }
});
