import { View } from 'react-native';
import { colors, tenFrameCounterColors } from '../theme/colors';
import Text from '../components/typography/Text';

export function renderCounter(color: 'yellow' | 'red' | 'blue' | 'green') {
  return (
    <View
      style={{
        width: 96,
        height: 96,
        borderRadius: 999,
        borderWidth: 2.33,
        backgroundColor: tenFrameCounterColors[color],
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text
        variant="WRN700"
        style={{
          fontSize: 30,
          color: color === 'yellow' ? colors.prussianBlue : 'white'
        }}
      >
        {color.charAt(0)}
      </Text>
    </View>
  );
}
