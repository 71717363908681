import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { MULT, ADD } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFractions } from '../../../../utils/fractions';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { arrayHasNoDuplicates, filledArray, range } from '../../../../utils/collections';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  domesticAnimalsObjectSchema,
  getRandomdomesticAnimalsObject
} from '../../../../utils/objects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aPG',
  description: 'aPG',
  keywords: [
    'Multiplication',
    'Multiply',
    'Lots of',
    'Repeated addition',
    'Non-unit fraction',
    'Integer'
  ],
  schema: z
    .object({
      multiplierA: z.number().int().min(2).max(6),
      multiplierB: z.number().int().min(2).max(6),
      multiplierC: z.number().int().min(2).max(6),
      numeratorA: z.number().int().min(2).max(9),
      numeratorB: z.number().int().min(2).max(9),
      numeratorC: z.number().int().min(2).max(9),
      denominatorA: z.number().int().min(5).max(20),
      denominatorB: z.number().int().min(5).max(20),
      denominatorC: z.number().int().min(5).max(20)
    })
    .refine(
      val => arrayHasNoDuplicates([val.denominatorA, val.denominatorB, val.denominatorC]),
      'All denominators must be different.'
    )
    .refine(
      ({ multiplierA, numeratorA, denominatorA }) => multiplierA * numeratorA < denominatorA,
      'TotalA should be less than a whole'
    )
    .refine(
      ({ multiplierB, numeratorB, denominatorB }) => multiplierB * numeratorB < denominatorB,
      'TotalB should be less than a whole'
    )
    .refine(
      ({ multiplierC, numeratorC, denominatorC }) => multiplierC * numeratorC < denominatorC,
      'TotalC should be less than a whole'
    ),
  simpleGenerator: () => {
    const multiplierA = randomIntegerInclusive(2, 6);
    const multiplierB = randomIntegerInclusive(2, 6);
    const multiplierC = randomIntegerInclusive(2, 6);

    const { numeratorA, numeratorB, numeratorC, denominatorA, denominatorB, denominatorC } =
      rejectionSample(
        () => {
          const numeratorA = randomIntegerInclusive(2, 9, {
            constraint: x => x * multiplierA < 20
          });
          const numeratorB = randomIntegerInclusive(2, 9, {
            constraint: x => x * multiplierB < 20
          });
          const numeratorC = randomIntegerInclusive(2, 9, {
            constraint: x => x * multiplierC < 20
          });

          const denominatorA = randomIntegerInclusive(5, 20, {
            constraint: x => numeratorA * multiplierA < x
          });
          const denominatorB = randomIntegerInclusive(5, 20, {
            constraint: x => numeratorB * multiplierB < x
          });
          const denominatorC = randomIntegerInclusive(5, 20, {
            constraint: x => numeratorC * multiplierC < x
          });
          return { numeratorA, numeratorB, numeratorC, denominatorA, denominatorB, denominatorC };
        },
        ({ denominatorA, denominatorB, denominatorC }) =>
          arrayHasNoDuplicates([denominatorA, denominatorB, denominatorC])
      );

    return {
      denominatorA,
      multiplierA,
      denominatorB,
      multiplierB,
      denominatorC,
      multiplierC,
      numeratorA,
      numeratorB,
      numeratorC
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        multiplierA,
        denominatorB,
        multiplierB,
        denominatorC,
        multiplierC,
        numeratorA,
        numeratorB,
        numeratorC
      },
      translate,
      displayMode
    } = props;

    const items = [
      {
        sentence: `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' /> ${MULT} ${multiplierA.toLocaleString()}`,
        value: 'A'
      },
      {
        sentence: `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' /> ${MULT} ${multiplierB.toLocaleString()}`,
        value: 'B'
      },
      {
        sentence: `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' /> ${MULT} ${multiplierC.toLocaleString()}`,
        value: 'C'
      }
    ];

    const statements = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}' />`,
            multiplierA - 1
          ).join('')} = <ans/>`,
          value: 'A'
        },
        {
          sentence: `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`,
            multiplierB - 1
          ).join('')} = <ans/>`,
          value: 'B'
        },
        {
          sentence: `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' />${filledArray(
            ` ${ADD} <frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}' />`,
            multiplierC - 1
          ).join('')} = <ans/>`,
          value: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchEachAdditionToEquivMult()}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endMid"
        pdfTitle={translate.instructions.useCardsToMatchEachAdditionToEquivMult()}
        items={items.map(({ sentence, value }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value
          };
        })}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(it => it.sentence)}
        testCorrect={statements.map(it => [it.value])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aPH',
  description: 'aPH',
  keywords: [
    'Multiplication',
    'Multiply',
    'Lots of',
    'Repeated addition',
    'Non-unit fraction',
    'Integer',
    'Bar model'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(5).max(9),
      numerator: z.number().int().min(2).max(4),
      multiplier: z.number().int().min(2).max(4)
    })
    .refine(
      ({ multiplier, numerator, denominator }) => multiplier * numerator < denominator,
      'Total should be less than a whole'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 4);

    const numerator = randomIntegerInclusive(2, 4, { constraint: x => x * multiplier < 9 });

    const denominator = randomIntegerInclusive(5, 9, {
      constraint: x => numerator * multiplier < x
    });

    return { denominator, numerator, multiplier };
  },
  Component: ({ question: { denominator, numerator, multiplier }, displayMode, translate }) => {
    const improperNumerator = multiplier * numerator;
    return (
      <QF1ContentAndSentences
        title={translate.instructions.shadeInTheBarModelToHelpCompleteTheCalculation()}
        Content={({ dimens }) => (
          <BarModelInteractiveWithState
            id="barModel"
            numberOfRows={1}
            numberOfCols={denominator}
            tableWidth={dimens.width}
            tableHeight={displayMode === 'digital' ? 100 : 160}
          />
        )}
        sentences={[
          `<frac n='${numerator}' d='${denominator}' />${filledArray(
            ` ${ADD} <frac n='${numerator}' d='${denominator}' />`,
            multiplier - 1
          ).join('')} = <frac nAns='' dAns='' />`,
          `${multiplier.toLocaleString()} ${MULT} <frac n='${numerator}' d='${denominator}' /> = <frac nAns='' dAns='' />`
        ]}
        inputMaxCharacters={1}
        textStyle={{ fontSize: 32 }}
        fractionTextStyle={{ fontSize: 32 }}
        // Styling needed to try and keep the two sentences/equations as separate as possible on the same line:
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
        testCorrect={userAnswer =>
          userAnswer.every(answer => compareFractions(answer, [improperNumerator, denominator]))
        }
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [improperNumerator.toLocaleString(), denominator.toLocaleString()],
            [improperNumerator.toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPI',
  description: 'aPI',
  keywords: ['Multiplication', 'Multiply', 'Unit fraction', 'Integer'],
  schema: z
    .object({
      denominatorA: z.number().int().min(5).max(15),
      denominatorB: z.number().int().min(5).max(15),
      numeratorA: z.number().int().min(2).max(7),
      numeratorB: z.number().int().min(2).max(7),
      multiplierA: z.number().int().min(2).max(7),
      multiplierB: z.number().int().min(2).max(7),
      startWithMultiplierA: z.boolean(),
      startWithMultiplierB: z.boolean()
    })
    .refine(val => val.denominatorA !== val.denominatorB, 'All denominators must be different.')
    .refine(
      ({ multiplierA, numeratorA, denominatorA }) => multiplierA * numeratorA < denominatorA,
      'TotalA should be less than a whole'
    )
    .refine(
      ({ multiplierB, numeratorB, denominatorB }) => multiplierB * numeratorB < denominatorB,
      'TotalB should be less than a whole'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(5, 15, 2);

    const { multiplierA, multiplierB, numeratorA, numeratorB } = rejectionSample(
      () => {
        const multiplierA = randomIntegerInclusive(2, 7);
        const multiplierB = randomIntegerInclusive(2, 7);
        const numeratorA = randomIntegerInclusive(2, 7);
        const numeratorB = randomIntegerInclusive(2, 7);

        return { multiplierA, multiplierB, numeratorA, numeratorB };
      },
      ({ multiplierA, multiplierB, numeratorA, numeratorB }) =>
        numeratorA * multiplierA < denominatorA && numeratorB * multiplierB < denominatorB
    );
    const startWithMultiplierA = getRandomBoolean();
    const startWithMultiplierB = getRandomBoolean();
    return {
      multiplierA,
      numeratorA,
      denominatorA,
      multiplierB,
      numeratorB,
      denominatorB,
      startWithMultiplierA,
      startWithMultiplierB
    };
  },
  Component: ({
    question: {
      multiplierA,
      numeratorA,
      denominatorA,
      multiplierB,
      numeratorB,
      denominatorB,
      startWithMultiplierA,
      startWithMultiplierB
    },
    translate
  }) => {
    const sentences = [
      {
        sentence: startWithMultiplierA
          ? `${multiplierA.toLocaleString()} ${MULT} <frac n='${numeratorA}' d='${denominatorA}'/> = <frac nAns='' dAns=''/>`
          : ` <frac nAns='' dAns=''/> = <frac n='${numeratorA}' d='${denominatorA}'/> ${MULT} ${multiplierA.toLocaleString()}`,
        answer: [numeratorA * multiplierA, denominatorA]
      },
      {
        sentence: startWithMultiplierB
          ? `${multiplierB.toLocaleString()} ${MULT} <frac n='${numeratorB}' d='${denominatorB}'/> = <frac nAns='' dAns=''/>`
          : `<frac nAns='' dAns=''/> = <frac n='${numeratorB}' d='${denominatorB}'/> ${MULT} ${multiplierB.toLocaleString()} `,
        answer: [numeratorB * multiplierB, denominatorB]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={userAnswer =>
          userAnswer.every((answer, idx) => compareFractions(answer, sentences[idx].answer))
        }
        inputMaxCharacters={2}
        sentences={sentences.map(({ sentence }) => sentence)}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: sentences.map(({ answer }) => [
            answer[0].toLocaleString(),
            answer[1].toLocaleString()
          ]),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPJ',
  description: 'aPJ',
  keywords: ['Multiplication', 'Multiply', 'Non-unit fraction', 'Integer'],
  schema: z
    .object({
      denominator: z.number().int().min(5).max(15),
      days: z.number().int().min(2).max(7),
      numerator: z.number().int().min(2).max(7),
      incorrectNumeratorA: z.number().int().min(2).max(14),
      incorrectNumeratorB: z.number().int().min(2).max(14),
      incorrectNumeratorC: z.number().int().min(2).max(14),
      pet: domesticAnimalsObjectSchema
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.incorrectNumeratorA,
          val.incorrectNumeratorB,
          val.incorrectNumeratorC
        ]),
      'All numerators must be different.'
    )
    .refine(({ denominator, days, numerator }) => days * numerator < denominator),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(2, 7);

    const days = randomIntegerInclusive(2, 7, {
      constraint: x => x * numerator < 15
    });

    const denominator = randomIntegerInclusive(5, 15, { constraint: x => x > numerator * days });

    const [incorrectNumeratorA, incorrectNumeratorB, incorrectNumeratorC] =
      randomUniqueIntegersInclusive(2, 14, 3, {
        constraint: x => x !== numerator * days
      });

    const pet = getRandomdomesticAnimalsObject();

    return {
      denominator,
      days,
      numerator,
      incorrectNumeratorA,
      incorrectNumeratorB,
      incorrectNumeratorC,
      pet
    };
  },
  Component: props => {
    const {
      question: {
        denominator,
        numerator,
        incorrectNumeratorA,
        incorrectNumeratorB,
        incorrectNumeratorC,
        pet,
        days
      },
      translate
    } = props;

    const correctNumerator = numerator * days;
    const eqs = shuffle(
      [
        {
          sentence: `<frac n='${correctNumerator}' d='${denominator}'/>`,
          isCorrect: true
        },
        {
          sentence: `<frac n='${incorrectNumeratorA}' d='${denominator}'/>`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${incorrectNumeratorB}' d='${denominator}'/>`,
          isCorrect: false
        },
        {
          sentence: `<frac n='${incorrectNumeratorC}' d='${denominator}'/>`,
          isCorrect: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.xEatsYBagOfFoodADayWhatFractionOfBagDoesEatInZdays(
          pet,
          `<frac n='${numerator}' d='${denominator}'/>`,
          days
        )}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: (
              <TextStructure
                sentence={equation.sentence}
                style={{ justifyContent: 'center' }}
                fractionTextStyle={{ fontWeight: '700' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPK',
  description: 'aPK',
  keywords: ['Multiplication', 'Multiply', 'Integer', 'Non-unit fraction', 'Number line'],
  schema: z
    .object({
      jumps: z.number().int().min(2).max(4),
      denominator: z.number().int().min(3).max(5),
      numerator: z.number().int().min(2).max(4)
    })
    .refine(val => val.denominator > val.numerator, 'Denominator must be greater than numerator.'),

  simpleGenerator: () => {
    const jumps = randomIntegerInclusive(2, 4);

    const denominator = randomIntegerInclusive(3, 5);

    const numerator = randomIntegerInclusive(2, 4, {
      constraint: x => x < denominator && jumps * x > denominator
    });

    return { denominator, jumps, numerator };
  },
  Component: props => {
    const {
      question: { denominator, jumps, numerator },
      translate
    } = props;

    const improperNumerator = jumps * numerator;

    const mixedNumberInteger = Math.floor(improperNumerator / denominator);
    const mixedNumberNumerator = improperNumerator % denominator;

    const endNumber = mixedNumberInteger + 1;

    const tickValues = range(0, endNumber * denominator).map(i => ({
      label: i % denominator === 0 ? `${(i / denominator).toLocaleString()}` : ``,
      position: i
    }));

    const jumpArrowArray = range(0, jumps - 1).map(i => ({
      start: i * numerator,
      end: i * numerator + numerator,
      label: ''
    }));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToCompleteMult()}
        sentence={`${jumps.toLocaleString()} ${MULT} <frac n='${numerator}' d='${denominator}' /> = <frac nAns='' dAns='' /> = <frac wAns='' nAns='' dAns='' />`}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [improperNumerator, denominator]) &&
          userAnswer[2] === mixedNumberInteger.toString() &&
          compareFractions([userAnswer[3], userAnswer[4]], [mixedNumberNumerator, denominator])
        }
        inputMaxCharacters={2}
        textStyle={{ fontSize: 40 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            jumps.toLocaleString(),
            denominator.toLocaleString(),
            mixedNumberInteger.toLocaleString(),
            mixedNumberNumerator.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            dimens={dimens}
            tickValues={tickValues}
            start={0}
            end={endNumber * denominator}
            jumpArrowArray={jumpArrowArray}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPL',
  description: 'aPL',
  keywords: ['Multiplication', 'Multiply', 'Integer', 'Non-unit fraction'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(12),
      numerator: z.number().int().min(2).max(11),
      multiplier: z.number().int().min(2).max(20),
      intergerFirst: z.boolean()
    })
    .refine(({ denominator, numerator }) => denominator > numerator, 'Must be a proper fraction')
    .refine(
      ({ denominator, numerator, multiplier }) => denominator < numerator * multiplier,
      'Total should be less than a whole'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);
    const numerator = randomIntegerInclusive(2, denominator - 1);
    const multiplier = randomIntegerInclusive(2, 20, {
      constraint: x => x * numerator > denominator
    });

    const intergerFirst = getRandomBoolean();

    return { denominator, numerator, multiplier, intergerFirst };
  },
  Component: ({ question: { denominator, numerator, multiplier, intergerFirst }, translate }) => {
    const improperNumerator = numerator * multiplier;
    const mixedNumberInteger = Math.floor(improperNumerator / denominator);
    const mixedNumberNumerator = improperNumerator % denominator;
    const items = shuffle(
      [
        {
          sentence: improperNumerator.toLocaleString(),
          value: improperNumerator
        },
        {
          sentence: mixedNumberInteger.toLocaleString(),
          value: mixedNumberInteger
        },
        {
          sentence: mixedNumberNumerator.toLocaleString(),
          value: mixedNumberNumerator
        },
        {
          sentence: numerator.toLocaleString(),
          value: numerator
        }
      ],
      { random: seededRandom({ denominator, numerator, multiplier }) }
    );
    const sentence = intergerFirst
      ? `${multiplier.toLocaleString()} ${MULT} <frac nAns='' d='${denominator.toLocaleString()}' /> = <frac nAns='' d='${denominator.toLocaleString()}' /> = <frac wAns='' nAns='' d='${denominator.toLocaleString()}' />`
      : `<frac nAns='' d='${denominator.toLocaleString()}' /> ${MULT} ${multiplier.toLocaleString()} = <frac nAns='' d='${denominator.toLocaleString()}' /> = <frac wAns='' nAns='' d='${denominator.toLocaleString()}' />`;

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragNumberCardsToCompleteMutliplication()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useNumberCardsToCompleteMultiplication()}
        pdfLayout="itemsRight"
        items={items.map(({ sentence, value }) => {
          return {
            component: sentence,
            value
          };
        })}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={[sentence]}
        fractionContainerStyle={{ height: 96 }}
        testCorrect={[[numerator, improperNumerator, mixedNumberInteger, mixedNumberNumerator]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyANonUnitFractionByAnInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
