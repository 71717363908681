import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgv',
  description: 'bgv',
  keywords: ['Times-tables', 'Count', 'Multiplication'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(12),
    item: z.enum(['Apple', 'Cookie', 'Flower', 'Muffin', 'Pear', 'Banana']),
    answers: z
      .array(z.number().min(2).max(50))
      .length(3)
      .refine(arrayHasNoDuplicates, 'Answers should have no duplicates')
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 12);
    const item = getRandomFromArray([
      'Apple',
      'Cookie',
      'Flower',
      'Muffin',
      'Pear',
      'Banana'
    ] as const);

    const answers = rejectionSample(
      () => [
        multiplier,
        multiplier * 3,
        getRandomBoolean() ? (multiplier - 1) * 3 : (multiplier + 1) * 3
      ],
      val => arrayHasNoDuplicates(val)
    );

    return { multiplier, item, answers: shuffle(answers) };
  },
  Component: props => {
    const {
      question: { multiplier, item, answers },
      translate
    } = props;

    const svgName =
      item === 'Pear'
        ? 'Pears_in_a_transparent_bag/Pears_in_a_bag_3'
        : item === 'Muffin'
        ? 'Food_on_a_plate/Muffins_plate_3'
        : item === 'Banana'
        ? 'Bunches_of_bananas/Bunch_of_bananas_3'
        : getObjectSvgName(item, 3);

    const sentenceLookup = {
      Apple: 'thereAreAnsApplesAltogether',
      Cookie: 'thereAreAnsCookiesAltogether',
      Flower: 'thereAreAnsFlowersAltogether',
      Muffin: 'thereAreAnsMuffinsAltogether',
      Pear: 'thereAreAnsPearsAltogether',
      Banana: 'thereAreAnsBananasAltogether'
    } as const;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        sentence={translate.ks1AnswerSentences[sentenceLookup[item]]()}
        sentencesStyle={{ alignItems: 'flex-start' }}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        testCorrect={[multiplier * 3]}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View style={{ flexDirection: 'row', gap: 25 }}>
              {countRange(Math.min(multiplier, 6)).map(i => (
                <AssetSvg
                  key={i}
                  name={svgName}
                  width={dimens.width / 7}
                  height={dimens.height / 3}
                />
              ))}
            </View>
            <View style={{ flexDirection: 'row', gap: 25, alignItems: 'flex-start' }}>
              {multiplier > 6 &&
                countRange(Math.min(multiplier - 6)).map(i => (
                  <AssetSvg
                    key={i + multiplier}
                    name={svgName}
                    width={dimens.width / 7}
                    height={dimens.height / 3}
                  />
                ))}
            </View>
          </View>
        )}
        items={answers.map(value => ({ value, component: <Text variant="WRN700">{value}</Text> }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bgw',
  description: 'bgw',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', 'Times-tables'],
  schema: z.object({
    numberArray: z.number().int().min(0).max(36).step(3).array().length(8),
    missingIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const startingNumber =
      ordering === 'ascending'
        ? randomIntegerInclusiveStep(0, 15, 3)
        : randomIntegerInclusiveStep(21, 36, 3);

    const numberArray = countRange(8).map(num => {
      return ordering === 'ascending' ? startingNumber + num * 3 : startingNumber - num * 3;
    });

    const missingIndices = rejectionSample(
      () => {
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(3, 5)));
      },
      // Check at least two consecutive numbers
      x => x.some((num, i, arr) => arr[i + 1] - num >= 3)
    );

    return { numberArray, missingIndices };
  },
  Component: ({ question: { numberArray, missingIndices }, translate }) => {
    const answerArray = missingIndices.map(x => numberArray[x].toString());

    const boxValues = numberArray.map((x, i) =>
      missingIndices.includes(i) ? '<ans/>' : x.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeTheNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bgx',
  description: 'bgx',
  keywords: ['Count', 'Forwards', 'Backwards', 'Numerals'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(36),
    order: z.enum(['forwards', 'backwards']),
    name: ks1NameSchema,
    selectables: z
      .array(z.number().int().min(1).max(36))
      .length(6)
      .refine(arrayHasNoDuplicates, 'Selectables should have no duplicates'),
    correctAnswers: z.array(z.number().int().min(1).max(36))
  }),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const mulitplier = 3;

    const order = getRandomFromArray(['forwards', 'backwards'] as const);

    const numberOfCorrectAnswers = randomIntegerInclusive(2, 6);

    const startingNumber =
      order === 'forwards'
        ? randomIntegerInclusiveStep(0, 36 - mulitplier * (numberOfCorrectAnswers + 1), 3)
        : randomIntegerInclusiveStep(mulitplier * (numberOfCorrectAnswers + 1), 36, 3);

    const correctAnswers =
      order === 'forwards'
        ? randomUniqueIntegersInclusiveStep(startingNumber + 3, 36, 3, numberOfCorrectAnswers)
        : randomUniqueIntegersInclusiveStep(3, startingNumber - 3, 3, numberOfCorrectAnswers);

    const incorrectOptionsAPlus = correctAnswers.map(num => (num !== 36 ? num + 1 : undefined));
    const incorrectOptionsBPlus = correctAnswers.map(num => num - 1);

    const incorrectOptionC =
      order === 'forwards' ? correctAnswers.map(num => (num !== 3 ? num - 3 : undefined)) : [];
    const incorrectOptionD =
      order === 'backwards' ? correctAnswers.map(num => (num !== 36 ? num + 3 : undefined)) : [];

    const incorrectOptions = getRandomSubArrayFromArray(
      [
        ...incorrectOptionsAPlus,
        ...incorrectOptionsBPlus,
        ...incorrectOptionC,
        ...incorrectOptionD
      ].filter(el => el !== undefined) as number[],
      6 - numberOfCorrectAnswers,
      {
        constraint: x => arrayHasNoDuplicates([x, ...correctAnswers])
      }
    );

    const selectables = shuffle([...correctAnswers, ...incorrectOptions]);

    return {
      startingNumber,
      order,
      name,
      numberOfCorrectAnswers,
      selectables,
      correctAnswers
    };
  },
  Component: props => {
    const {
      question: { startingNumber, order, name, selectables, correctAnswers },
      translate
    } = props;

    const title =
      order === 'forwards'
        ? translate.ks1Instructions.characterIsCountingForwardsInXFromYSelectNumbers(
            name,
            3,
            startingNumber
          )
        : translate.ks1Instructions.characterIsCountingBackwardsInXFromYSelectNumbers(
            name,
            3,
            startingNumber
          );
    const pdfTitle =
      order === 'forwards'
        ? translate.ks1PDFInstructions.characterIsCountingForwardsInXFromYTickNumbers(
            name,
            3,
            startingNumber
          )
        : translate.ks1PDFInstructions.characterIsCountingBackwardsInXFromYTickNumbers(
            name,
            3,
            startingNumber
          );

    return (
      <QF10SelectNumbers
        title={title}
        pdfTitle={pdfTitle}
        multiSelect
        testCorrect={correctAnswers}
        items={selectables.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountIn3s',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
