import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ImageWithLabel } from '../../../../components/question/representations/ImageWithLabel';
import {
  FoodIngredient,
  foodIngredientsArray,
  getFoodIngredientsSvg
} from '../../../../utils/food';
import { AssetSvg } from '../../../../assets/svg';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, SUB } from '../../../../constants';
import Scales from '../../../../components/question/representations/Scales';
import {
  arrayObjectAsWord,
  arrayObjectSchema,
  getRandomArrayObject
} from '../../../../utils/arrayObjects';
import { getArrayObjectSvgName } from '../../../../utils/arrayObjectsImages';
import {
  getRandomUniqueHeavyObject,
  heavyObjectAsWord,
  heavyObjectSchema
} from '../../../../utils/objects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJc',
  description: 'aJc',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Add'],
  schema: z
    .object({
      weight1: z.number().int().min(100).max(800).step(100),
      weight2: z.number().int().min(100).max(800).step(100),
      image1: arrayObjectSchema,
      image2: arrayObjectSchema
    })
    .refine(val => val.weight1 + val.weight2 < 1000, 'sum of weights must be less than 1000'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const { weight1, weight2 } = rejectionSample(
      () => {
        const weight1 = randomIntegerInclusiveStep(100, 800, 100);
        const weight2 = randomIntegerInclusiveStep(100, 800, 100);
        return { weight1, weight2 };
      },
      val => val.weight1 + val.weight2 < 1000
    );

    const [image1, image2] = rejectionSample(
      () => countRange(2).map(getRandomArrayObject),
      val => arrayHasNoDuplicates(val)
    );

    return { weight1, weight2, image1, image2 };
  },
  Component: ({ question: { weight1, weight2, image1, image2 }, translate }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.whatIsTotalMassOfTheXAndTheY(
          arrayObjectAsWord(image1, translate),
          arrayObjectAsWord(image2, translate)
        )}
        testCorrect={[(weight1 + weight2).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <View
              style={[
                dimens,
                { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end' }
              ]}
            >
              <Scales
                weightG={weight1}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/1000g_scale_50g_100g"
                weightImage={getArrayObjectSvgName(image1)}
              />
              <Scales
                weightG={weight2}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/1000g_scale_50g_100g"
                weightImage={getArrayObjectSvgName(image2)}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJd',
  description: 'aJd',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms', 'Add'],
  schema: z
    .object({
      mass1: z.number().int().min(1).max(20),
      mass2: z.number().int().min(1).max(10),
      mass3: z.number().int().min(1).max(10),
      ingredients: z.enum(foodIngredientsArray).array().length(3)
    })
    .refine(
      val => arrayHasNoDuplicates([val.mass1, val.mass2, val.mass3]),
      'all masses must be different.'
    ),
  simpleGenerator: () => {
    const mass1 = randomIntegerInclusive(1, 20);
    const [mass2, mass3] = randomUniqueIntegersInclusive(1, 10, 2, {
      constraint: x => x !== mass1
    });

    const isSavory = getRandomBoolean();
    const ingredients = isSavory
      ? ([
          getRandomFromArray(['pasta', 'rice'] as const),
          'carrots',
          'potatoes'
        ] as FoodIngredient[])
      : ([
          getRandomFromArray(['chocolateChips', 'chocolate'] as const),
          'flour',
          'sugar'
        ] as FoodIngredient[]);

    return { mass1, mass2, mass3, ingredients: shuffle(ingredients) };
  },
  Component: props => {
    const {
      question: { mass1, mass2, mass3, ingredients },
      translate
    } = props;
    const masses = [mass1, mass2, mass3];
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKg()}
        title={translate.instructions.whatIsTotalMassOfIngredients()}
        testCorrect={[(mass1 + mass2 + mass3).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', gap: 40 }}>
              {ingredients.map((val, i) => (
                <ImageWithLabel
                  key={val}
                  value={translate.answerSentences.xKg(masses[i])}
                  image={
                    <AssetSvg
                      name={getFoodIngredientsSvg(val)}
                      height={dimens.height * 0.8}
                      width={dimens.width * 0.15}
                    />
                  }
                  imageHeight={dimens.height * 0.9}
                  position="top"
                  labelWidth={dimens.width * 0.15}
                />
              ))}
            </View>
          );
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aJd2',
  description: 'aJd',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms', 'Add'],
  schema: z
    .object({
      mass1: z.number().int().min(1).max(20),
      mass2: z.number().int().min(1).max(10),
      mass3: z.number().int().min(1).max(10),
      ingredients: z.enum(foodIngredientsArray).array().length(3)
    })
    .refine(
      val => arrayHasNoDuplicates([val.mass1, val.mass2, val.mass3]),
      'all masses must be different.'
    ),
  simpleGenerator: () => {
    const mass1 = randomIntegerInclusive(1, 20);
    const [mass2, mass3] = randomUniqueIntegersInclusive(1, 10, 2, {
      constraint: x => x !== mass1
    });

    const ingredients = [
      getRandomFromArray(['pasta', 'rice'] as const),
      'carrots',
      'potatoes'
    ] as FoodIngredient[];

    return { mass1, mass2, mass3, ingredients: shuffle(ingredients) };
  },
  Component: props => {
    const {
      question: { mass1, mass2, mass3, ingredients },
      translate
    } = props;
    const masses = [mass1, mass2, mass3];
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKg()}
        title={translate.instructions.whatIsTotalMassOfIngredients()}
        testCorrect={[(mass1 + mass2 + mass3).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', gap: 40 }}>
              {ingredients.map((val, i) => (
                <ImageWithLabel
                  key={val}
                  value={translate.answerSentences.xKg(masses[i])}
                  image={
                    <AssetSvg
                      name={getFoodIngredientsSvg(val)}
                      height={dimens.height * 0.8}
                      width={dimens.width * 0.15}
                    />
                  }
                  imageHeight={dimens.height * 0.9}
                  position="top"
                  labelWidth={dimens.width * 0.15}
                />
              ))}
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJe',
  description: 'aJe',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Kilograms', 'Add'],
  schema: z
    .object({
      kg1: z.number().int().min(1).max(4),
      kg2: z.number().int().min(1).max(4),
      g1: z.number().int().min(100).max(800).step(100),
      g2: z.number().int().min(100).max(800).step(100),
      items: heavyObjectSchema.array().length(2)
    })
    .refine(val => val.g1 + val.g2 < 1000, 'sum of the gram weights must be less than 1000'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const [kg1, kg2] = randomUniqueIntegersInclusive(1, 4, 2);
    const g1 = randomIntegerInclusiveStep(100, 800, 100);
    const g2 = randomIntegerInclusiveStep(100, 900 - g1, 100);
    const items = getRandomUniqueHeavyObject(2);

    return { kg1, kg2, g1, g2, items };
  },
  Component: ({ question: { kg1, kg2, g1, g2, items }, translate, displayMode }) => {
    const weight1 = kg1 * 1000 + g1;
    const weight2 = kg2 * 1000 + g2;

    const laptopWidth = displayMode === 'digital' ? 150 : 250;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKgAndG()}
        title={translate.instructions.whatIsTotalMassOfTheXAndTheY(
          heavyObjectAsWord(items[0], translate),
          heavyObjectAsWord(items[1], translate)
        )}
        testCorrect={[(kg1 + kg2).toString(), (g1 + g2).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <View
              style={[
                dimens,
                { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end' }
              ]}
            >
              <Scales
                weightG={weight1}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/5_kg_scale_100_g"
                weightImage={items[0]}
                weightImageWidth={items[0] === 'Laptop' ? laptopWidth : undefined}
              />
              <Scales
                weightG={weight2}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/5_kg_scale_100_g"
                weightImage={items[1]}
                weightImageWidth={items[1] === 'Laptop' ? laptopWidth : undefined}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aJf',
  description: 'aJf',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams', 'Subtract'],
  schema: z
    .object({
      weight1: z.number().int().min(100).max(800).step(100),
      weight2: z.number().int().min(200).max(900).step(100),
      foodItem: z.enum(['Beans', 'Cereal', 'Rice'])
    })
    .refine(val => val.weight2 - val.weight1 > 0, 'difference of weights must be greater than 0'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const { weight1, weight2 } = rejectionSample(
      () => {
        const weight1 = randomIntegerInclusiveStep(100, 800, 100);
        const weight2 = randomIntegerInclusiveStep(200, 900, 100);
        return { weight1, weight2 };
      },
      val => val.weight2 - val.weight1 > 0
    );

    const foodItem = getRandomFromArray(['Beans', 'Cereal', 'Rice'] as const);

    return { weight1, weight2, foodItem };
  },
  Component: ({ question: { weight1, weight2, foodItem }, translate, displayMode }) => {
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansG()}
        title={translate.instructions.theEmptyBowlWeighsXgBeforeFoodWhatIsWeightOfFood(weight1)}
        testCorrect={[(weight2 - weight1).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <View
              style={[
                dimens,
                { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-end' }
              ]}
            >
              <Scales
                weightG={weight1}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/1000g_scale_50g_100g"
                weightImage="bowls/Bowl_Empty"
                weightImageWidth={displayMode === 'digital' ? 100 : 200}
              />
              <Scales
                weightG={weight2}
                scaleWidth={Math.min(dimens.width * 0.35, dimens.height * 0.6)}
                svgName="Scales/1000g_scale_50g_100g"
                weightImage={`bowls/Bowl_${foodItem}`}
                weightImageWidth={displayMode === 'digital' ? 100 : 200}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJg',
  description: 'aJg',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms', 'Subtract'],
  schema: z
    .object({
      kg: z.number().int().min(2).max(9),
      g: z.number().int().min(200).max(900).step(100),
      kgSplit: z.number().int().min(1).max(8),
      gSplit: z.number().int().min(100).max(800).step(100),
      showKg: z.boolean()
    })
    .refine(
      val => val.kg - val.kgSplit > 0 && val.g - val.gSplit > 0,
      'difference must be greater than 0.'
    ),
  simpleGenerator: () => {
    const kg = randomIntegerInclusive(2, 9);
    const g = randomIntegerInclusiveStep(200, 900, 100);
    const kgSplit = randomIntegerInclusive(1, kg - 1);
    const gSplit = randomIntegerInclusiveStep(100, g - 100, 100);
    const showKg = getRandomBoolean();

    return { kg, g, kgSplit, gSplit, showKg };
  },
  Component: props => {
    const {
      question: { kg, g, kgSplit, gSplit, showKg },
      translate,
      displayMode
    } = props;

    const answerKg = showKg ? kg - kgSplit : kg;
    const answerG = showKg ? g : g - gSplit;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.questionMarkAnsKgAndG()}
        title={translate.instructions.whatIsMissingPart()}
        testCorrect={[answerKg.toString(), answerG.toString()]}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <BarModel
              numbers={[
                showKg
                  ? [kgSplit * 1000, kg * 1000 + g - kgSplit * 1000]
                  : [kg * 1000 + g - gSplit, gSplit]
              ]}
              strings={[
                showKg
                  ? [translate.answerSentences.xKg(kgSplit), '?']
                  : ['?', translate.answerSentences.xG(gSplit)]
              ]}
              total={kg * 1000 + g}
              oneFontSize
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
              bottomBraceText={translate.answerSentences.xKgAndG(kg, g)}
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJh',
  description: 'aJh',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms', 'Subtract', 'Add'],
  schema: z
    .object({
      kg1A: z.number().int().min(1).max(50),
      g1A: z.number().int().min(100).max(850).step(50),
      kg1B: z.number().int().min(1).max(50),
      g1B: z.number().int().min(100).max(900).step(50),
      kg2A: z.number().int().min(10).max(50),
      g2A: z
        .number()
        .int()
        .min(200)
        .max(999)
        .refine(x => x % 5 !== 0),
      kg2B: z.number().int().min(9).max(49),
      g2B: z.number().int().min(100).max(900).step(100)
    })
    .refine(val => val.g1A + val.g1B < 1000, 'g1A + g1B must be less than 1000.')
    .refine(val => val.kg2B < val.kg2A, 'kg2B must be less than kg2A.')
    .refine(val => val.g2B < val.g2A, 'g2B must be less than g2A.'),
  simpleGenerator: () => {
    const [kg1A, kg1B] = randomUniqueIntegersInclusive(1, 50, 2);
    const g1A = randomIntegerInclusiveStep(100, 850, 50);
    const g1B = randomIntegerInclusiveStep(100, 1000 - g1A - 50, 50);
    const kg2A = randomIntegerInclusive(10, 50);
    const g2A = randomIntegerInclusive(200, 999, { constraint: x => x % 5 !== 0 });
    const kg2B = randomIntegerInclusive(9, kg2A - 1);
    const g2B = randomIntegerInclusiveStep(100, g2A - 100, 100);

    return {
      kg1A,
      g1A,
      kg1B,
      g1B,
      kg2A,
      g2A,
      kg2B,
      g2B
    };
  },
  Component: props => {
    const {
      question: { kg1A, g1A, kg1B, g1B, kg2A, g2A, kg2B, g2B },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={[
          `${translate.answerSentences.xKgG(kg1A, g1A)} ${ADD} ${translate.answerSentences.xKgG(
            kg1B,
            g1B
          )} = ${translate.answerSentences.ansKgG()}`,
          `${translate.answerSentences.xKgG(kg2A, g2A)} ${SUB} ${translate.answerSentences.xKgG(
            kg2B,
            g2B
          )} = ${translate.answerSentences.ansKgG()}`
        ]}
        actionPanelVariant="bottomTall"
        testCorrect={[
          [(kg1A + kg1B).toString(), (g1A + g1B).toString()],
          [(kg2A - kg2B).toString(), (g2A - g2B).toString()]
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractMass',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
