import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from 'common/src/utils/collections';
import BaseLayout from '../../molecules/BaseLayout';
import { Selectable } from 'common/src/components/atoms/Selectable';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { resolveThingOrFunction, SetState, ThingOrFunction } from '../../../utils/react';
import { Theme } from '../../../theme';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { StyleSheet } from 'react-native';
import { MeasureView } from '../../atoms/MeasureView';
import { Dimens } from '../../../theme/scaling';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { ComponentProps, useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { chunk } from '../../../utils/chunk';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { withStateHOC } from '../../../stateTree';
import deepEqual from 'react-fast-compare';

type ItemInfo<T> = {
  /** Will be passed to {@link Selectable}. Use strings if possible. */
  component: string | JSX.Element;
  /** Must be unique amongst the items. */
  value: T;
};

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /**
   * If false, selecting one item deselects all others, so the user answer is a set of at most 1 value.
   * Default: false.
   */
  multiSelect?: boolean;

  /**
   * If true, selecting 0 items counts as a complete answer.
   * Default: false.
   */
  emptyIsValid?: boolean;

  /**
   * Initial selection state.
   * Note: order is irrelevant. This array must have no duplicates (where equality is given by `isEqual`).
   * Default: none selected (empty array).
   */
  initialState?: T[];

  /**
   * The items to show. Each item gets the same dimensions, and the component for each item is put in an oval
   * {@link Selectable}. Length of this array must be an integer between 2 and 12 inclusive.
   * Note: item values are compared with `isEqual`.
   */
  items: ThingOrFunction<((T & (string | number)) | ItemInfo<T>)[]>;

  /** How to compare two items for equality. Defaults to deep equality AKA structural equality. */
  isEqual?: (x: T, y: T) => boolean;

  selectableContainerStyle?: StyleProp<ViewStyle>;
  // used if items contains strings
  itemsTextVariant?: keyof Theme['fonts'];
  /** Additional text style to apply */
  itemsTextStyle?: StyleProp<TextStyle>;
  /** Whether to auto scale all text (fontSize and lineHeight) to fit its containers. (Default: true) */
  itemsTextAutoScale?: boolean;
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;

  /** Either an array showing the correct answer, or a function for more advanced usage. */
  testCorrect: T[] | ((ans: T[]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: T[]; answerText?: string };
};

/**
 * Question Format 10: Select Numbers.
 *
 * Title at the top. Accepts 4, 6 or 8 numbers. (If you need a different quantity, consider enhancing this
 * component).
 * Items must be shuffled prior to passing them in, otherwise they will appear in the same order every time.
 */
export default function QF10SelectNumbers<T>({
  title,
  pdfTitle,
  testCorrect,
  items: itemsProp,
  itemsTextVariant,
  itemsTextStyle,
  itemsTextAutoScale = true,
  itemsLetterEmWidth,
  itemsMaxLines = 2,
  multiSelect = false,
  emptyIsValid = false,
  initialState = [],
  questionHeight,
  customMarkSchemeAnswer,
  selectableContainerStyle,
  isEqual = deepEqual,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const items: ItemInfo<T>[] = resolveThingOrFunction(itemsProp).map(item =>
    typeof item === 'number' || typeof item === 'string'
      ? { component: item.toLocaleString(), value: item }
      : item
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const numItems = items.length;
  if (!Number.isInteger(numItems) || numItems < 2 || numItems > 12) {
    throw Error(`QF10SelectNumbers numItems ${numItems} must be between 2 and 12`);
  }

  // Throw error if duplicate values
  const values = items.map(it => it.value);
  if (!arrayHasNoDuplicates(values, isEqual)) {
    throw Error(`Multiple items with the same value: ${JSON.stringify(values)}`);
  }

  // Throw error if initial state has duplicates
  if (!arrayHasNoDuplicates(initialState, isEqual)) {
    throw Error(`Initial state has duplicates: ${JSON.stringify(initialState)}`);
  }

  const SelectableContainerWithState = getSelectableContainerWithState<T>();

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrect !== 'function' && displayMode === 'markscheme'
        ? testCorrect
        : customMarkSchemeAnswer?.answerToDisplay;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <SelectableContainer
                  userAnswer={markSchemeAnswer ?? []}
                  dimens={dimens}
                  items={items}
                  isEqual={isEqual}
                  longestItemTextLength={longestItemTextLength}
                  itemsMaxLines={itemsMaxLines}
                  itemsTextStyle={itemsTextStyle}
                  itemsTextAutoScale={itemsTextAutoScale}
                  itemsLetterEmWidth={itemsLetterEmWidth}
                  multiSelect={multiSelect}
                  selectableContainerStyle={selectableContainerStyle}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="end"
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <SelectableContainerWithState
              dimens={dimens}
              id="selection"
              defaultState={initialState}
              testCorrect={answer =>
                typeof testCorrect === 'function'
                  ? testCorrect(answer as T[])
                  : arraysHaveSameContentsUnordered(answer, testCorrect, isEqual)
              }
              testComplete={answer => emptyIsValid || answer.length > 0}
              items={items}
              isEqual={isEqual}
              longestItemTextLength={longestItemTextLength}
              itemsTextStyle={itemsTextStyle}
              itemsTextAutoScale={itemsTextAutoScale}
              itemsLetterEmWidth={itemsLetterEmWidth}
              itemsMaxLines={itemsMaxLines}
              multiSelect={multiSelect}
              selectableContainerStyle={selectableContainerStyle}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}

function SelectableContainer<T>({
  userAnswer,
  setUserAnswer,
  items,
  isEqual,
  longestItemTextLength,
  itemsTextStyle,
  itemsTextAutoScale,
  itemsLetterEmWidth,
  itemsMaxLines,
  multiSelect,
  selectableContainerStyle
}: {
  dimens: Dimens;
  userAnswer: T[];
  setUserAnswer?: SetState<T[]>;
  items: ItemInfo<T>[];
  isEqual: (x: T, y: T) => boolean;
  longestItemTextLength: number;
  itemsTextStyle?: StyleProp<TextStyle>;
  itemsTextAutoScale: boolean;
  itemsLetterEmWidth?: number;
  itemsMaxLines: number;
  multiSelect: boolean;
  selectableContainerStyle?: StyleProp<ViewStyle>;
}) {
  const styles = useStyles();
  // If 8 or less selectables display 2 columns, else display 3
  const itemsArrangement = items.length <= 8 ? chunk(items, 2) : chunk(items, 3);

  return (
    <View style={styles.selectableContainer}>
      {itemsArrangement.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {row.map((item, itemIndex) => (
            <Selectable
              key={itemIndex}
              selected={userAnswer.some(answerValue => isEqual(answerValue, item.value))}
              textVariant="WRN700"
              textStyle={itemsTextStyle}
              textScaleToLongestLength={itemsTextAutoScale ? longestItemTextLength : undefined}
              textLetterEmWidth={itemsLetterEmWidth}
              // Some of our text would be too small if put on one line. Perhaps this should be configurable?
              maxLines={itemsMaxLines}
              selectableContainerStyle={selectableContainerStyle}
              toggleSelected={() => {
                if (setUserAnswer && userAnswer) {
                  if (userAnswer.some(answerValue => isEqual(answerValue, item.value))) {
                    // Unselect this item
                    setUserAnswer(
                      userAnswer.filter(answerValue => !isEqual(answerValue, item.value))
                    );
                  } else {
                    // Select this item
                    multiSelect
                      ? setUserAnswer([...userAnswer, item.value])
                      : setUserAnswer([item.value]);
                  }
                }
              }}
            >
              {item.component}
            </Selectable>
          ))}
        </View>
      ))}
    </View>
  );
}

type SelectableContainerWithState<T> = ReturnType<
  typeof withStateHOC<ComponentProps<typeof SelectableContainer<T>>, 'userAnswer', 'setUserAnswer'>
>;

const SelectableContainerWithStateNonGeneric = withStateHOC(SelectableContainer, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer'
});

/** Get the StateTree version of SelectableContainer. There's no need to use useMemo with this. */
function getSelectableContainerWithState<T>() {
  // This cast simply changes the generic parameter from unknown to T, which is safe.
  return SelectableContainerWithStateNonGeneric as SelectableContainerWithState<T>;
}

function useStyles() {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        selectableContainer: {
          gap: displayMode === 'digital' ? 16 : 64
        },
        row: {
          columnGap: displayMode === 'digital' ? 15 : 30,
          flexDirection: 'row',
          justifyContent: 'flex-start'
        }
      }),
    [displayMode]
  );
}
