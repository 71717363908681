import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import { ADD } from 'common/src/constants';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  decimalToFraction,
  Fraction,
  fractionToDecimal,
  improperFractionToMixedNumber,
  simplify
} from 'common/src/utils/fractions';
import { findFactors } from 'common/src/utils/factors';
import { greatestCommonDivisor } from 'common/src/utils/multiples';
import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import TextStructure from 'common/src/components/molecules/TextStructure';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { fractionSchema, numberEnum } from 'common/src/utils/zod';
import QF20bInteractiveBarModelWithSentence from 'common/src/components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apR',
  description: 'apR',
  keywords: ['Add', 'Fractions', 'Denominator'],
  schema: z.object({
    barModel1Denominator: z.number().int().min(2).max(5),
    barModel1Numerator: z.number().int().min(1).max(4),
    barModel2Denominator: z.number().int(),
    barModel2Numerator: z.number().int().min(5).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { barModel1Denominator, barModel1Numerator, barModel2Denominator, barModel2Numerator } =
      rejectionSample(
        () => {
          const barModel1Denominator = randomIntegerInclusive(2, 5);
          const barModel1Numerator = randomIntegerInclusive(1, barModel1Denominator - 1);

          let barModel2Denominator = 1;

          switch (barModel1Denominator) {
            case 2:
              barModel2Denominator = getRandomFromArray([6, 8, 10]);
              break;
            case 3:
              barModel2Denominator = 6;
              break;
            case 4:
              barModel2Denominator = 8;
              break;
            case 5:
              barModel2Denominator = 10;
              break;
          }

          const barModel2Numerator = randomIntegerInclusive(5, barModel2Denominator - 1);

          return {
            barModel1Denominator,
            barModel1Numerator,
            barModel2Denominator,
            barModel2Numerator
          };
        },
        ({ barModel1Denominator, barModel1Numerator, barModel2Denominator, barModel2Numerator }) =>
          // Must equal odd number to avoid simplifiable fractions
          ((barModel2Denominator / barModel1Denominator) * barModel1Numerator +
            barModel2Numerator) %
            2 !==
            0 &&
          // Must be greater than barModel2Denominator
          (barModel2Denominator / barModel1Denominator) * barModel1Numerator + barModel2Numerator >
            barModel2Denominator
      );

    return {
      barModel1Denominator,
      barModel1Numerator,
      barModel2Denominator,
      barModel2Numerator
    };
  },

  Component: props => {
    const {
      question: {
        barModel1Denominator,
        barModel1Numerator,
        barModel2Denominator,
        barModel2Numerator
      },
      translate
    } = props;

    // Answers
    const answer1 = barModel2Denominator;
    const answer2 =
      (barModel2Denominator / barModel1Denominator) * barModel1Numerator + barModel2Numerator;
    const answer3 = 1;
    const answer4 = answer2 - answer1;

    // Simplify fractions
    const [fracANumerator, fracADenominator] = simplify(answer2, answer1);
    const [fracBNumerator, fracBDenominator] = simplify(answer4, answer1);

    const bars = [
      { rows: 1, cols: barModel2Denominator },
      { rows: 1, cols: barModel2Denominator }
    ];

    const sentence = `<frac n='${barModel1Numerator}' d='${barModel1Denominator}' /> ${ADD} <frac n='${barModel2Numerator}' d='${barModel2Denominator}' /> = <frac nAns='' dAns='' /> = <frac wAns='' nAns='' dAns='' />`;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.completeAddition()}<br/>${translate.instructions.youCanShadeTheBarModelsToHelp()}`}
        testCorrect={{
          sentence: answer =>
            compareFractions([answer[0], answer[1]], [fracANumerator, fracADenominator]) &&
            compareFractions(
              [answer[2], answer[3], answer[4]],
              [answer3, fracBNumerator, fracBDenominator]
            )
        }}
        inputMaxCharacters={2}
        sentence={sentence}
        bars={bars}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.barModelsDoNotNeedShading() }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'apS',
  description: 'apS',
  keywords: ['Fractions', 'Addition', 'Denominator'],
  schema: z.object({
    statementFractions: fractionSchema(
      z.number().int().min(1).max(5),
      z.number().int().min(2).max(6)
    )
      .array()
      .length(3),
    answerOptionNumbers: z.array(z.number().int().min(2).max(25)).length(3),
    number3: z.number().int().min(6).max(30),
    number10: z.number().int().min(5).max(29)
  }),
  simpleGenerator: () => {
    // Needs to be in a rejection sample to prevent the fraction items produced from numbers 5, 7 and 9 being duplicates.
    const { number3, number5, number7, number9, number10, statementFractions } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 5);
        const number2 = randomIntegerInclusive(3, 6, {
          constraint: x => x !== number1
        });
        const number3 = number1 * number2;

        const number4 = randomIntegerInclusive(1, number1 - 1);
        const number5 = number4 * number2;

        const [number6, number8] = randomUniqueIntegersInclusive(1, number2 - 1, 2);
        const number7 = number6 * number1;
        const number9 = number8 * number1;

        const number10 = number3 - 1;

        const statementFractions: Fraction[] = [
          [number4, number1],
          [number6, number2],
          [number8, number2]
        ];

        return {
          number3,
          number5,
          number7,
          number9,
          number10,
          statementFractions
        };
      },
      // Only permit them if their products are all different.
      ({ number5, number7, number9 }) => arrayHasNoDuplicates([number5, number7, number9])
    );

    const answerOptionNumbers = [number5, number7, number9];

    return { statementFractions, answerOptionNumbers, number3, number10 };
  },
  Component: props => {
    const {
      question: { statementFractions, answerOptionNumbers, number3, number10 },
      translate,
      displayMode
    } = props;

    const answerOptions = answerOptionNumbers.map(num => ({
      component: (
        <TextStructure
          sentence={`<frac n='${num}' d='${number3}'/> ${ADD} <frac n='${number10}' d='${number3}'/>`}
          textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
          textVariant="WRN700"
          fractionTextStyle={{
            fontSize: displayMode === 'digital' ? 30 : 50,
            fontWeight: '700'
          }}
          fractionDividerStyle={{ marginVertical: 2 }}
        />
      ),
      value: fractionToDecimal(num, number3) + fractionToDecimal(number10, number3)
    }));

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={shuffledAnswerOptions}
        statements={statementFractions.map(frac => ({
          lhsComponent: (
            <TextStructure
              sentence={`<frac n='${frac[0]}' d='${frac[1]}'/> ${ADD} <frac n='${number10}' d='${number3}'/> =`}
            />
          ),
          correctAnswer: fractionToDecimal(...frac) + fractionToDecimal(number10, number3)
        }))}
        statementStyle={{ justifyContent: 'center' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apT',
  description: 'apT',
  keywords: ['Addition', 'Fractions', 'Common denominator'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(10)
        .max(50)
        .refine(val => findFactors(val).length > 4, 'number1 must have at least 4 factors'),
      number2: z.number().int().min(2).max(49),
      number3: z.number().int().min(2).max(49),
      number4: z.number().int().min(1).max(48),
      number5: z.number().int().min(1).max(48),
      number6: z.number().int().min(1).max(49),
      multiple: numberEnum([2, 3])
    })
    .refine(
      val => val.number1 % val.number2 === 0 && val.number1 % val.number3 === 0,
      'number2 & number3 are factors of number1'
    )
    .refine(
      val => greatestCommonDivisor([val.number6, val.number1]) === 1,
      'number6/number1 must not simplify'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 50, {
      constraint: x => findFactors(x).length > 4
    });

    const [number2, number3] = randomUniqueIntegersInclusive(2, number1 - 1, 2, {
      constraint: x => number1 % x === 0
    });

    const number4 = randomIntegerInclusive(1, number2 - 1, {
      constraint: x => x !== number3
    });
    const number5 = randomIntegerInclusive(1, number3 - 1);
    const number6 = randomIntegerInclusive(1, number1 - 1, {
      constraint: x => greatestCommonDivisor([x, number1]) === 1
    });

    const multiple = getRandomFromArray([2, 3] as const);

    return { number1, number2, number3, number4, number5, number6, multiple };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, multiple },
      translate
    } = props;

    const random = seededRandom(props.question);

    const fractions = shuffle(
      [
        `<frac n='${number4}' d='${number2}'/>`,
        `<frac n='${number5}' d='${number3}'/>`,
        `<frac n='${number6}' d='${number1}'/>`
      ],
      { random }
    );

    const correctAnswer1 = number1.toLocaleString();

    const correctAnswer2 = (number1 * multiple).toLocaleString();

    const denominatorsSum = [number2, number3, number1].reduce((a, b) => a + b, 0);

    const options = shuffle(
      [
        correctAnswer1,
        correctAnswer2,
        ...getRandomSubArrayFromArray(
          [
            number2.toLocaleString(),
            number3.toLocaleString(),
            number4.toLocaleString(),
            denominatorsSum.toLocaleString()
          ],
          4,
          { random }
        )
      ],
      { random }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheNumbersYouCouldUseAsCommonDenominatorToWorkOutXYZ(
          fractions[0],
          fractions[1],
          fractions[2]
        )}
        testCorrect={[correctAnswer1, correctAnswer2]}
        titleFractionContainerStyle={{ height: 36 }}
        titleStyle={{ rowGap: 2 }}
        items={options}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'apU',
  description: 'apU',
  keywords: ['Addition', 'Fractions', 'Common denominators'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(3).max(6),
    number3: z.number().int().min(6).max(30),
    number4: z.number().int().min(4).max(29)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = number1 + 1;
    const number3 = number1 * number2;
    const number4 = randomIntegerInclusive(number2 + 1, number3 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answer1 = number1 * number1 + number4;
    const answer2 = number3;
    const answer3 = answer1 - answer2;
    const answer4 = 1;

    const correctFraction1 = [answer1, answer2];
    const correctFraction2 = [answer4, answer3, answer2];

    const testCorrect = (answer: string[]): boolean => {
      const frac1 = [answer[0], answer[1]];
      const frac2 = [answer[2], answer[3], answer[4]];

      return compareFractions(frac1, correctFraction1) && compareFractions(frac2, correctFraction2);
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={testCorrect}
        inputMaxCharacters={2}
        sentence={`<frac n='${number1}' d='${number2}'/> ${ADD} <frac n='${number4}' d='${number3}'/> = <frac nAns='' dAns=''/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            correctFraction1[0].toLocaleString(),
            correctFraction1[1].toLocaleString(),
            correctFraction2[0].toLocaleString(),
            correctFraction2[1].toLocaleString(),
            correctFraction2[2].toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'apV',
  description: 'apV',
  keywords: ['Addition', 'Inverse', 'Fractions', 'Numerators'],
  schema: z.object({
    number1: z.number().int().min(3).max(10),
    number2: z.number().int().min(6).max(20),
    number3: z.number().int().min(4).max(19),
    answer1: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 10);
    const halfNumber1 = Math.round(number1 * 0.5);
    const number2 = number1 * 2;
    const number3 = randomIntegerInclusive(number1 + 1, number2 - 1);

    const answer1 = randomIntegerInclusive(halfNumber1, number1 - 1);

    return { number1, number2, number3, answer1 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, answer1 },
      translate
    } = props;

    const number4 = number3 + 2 * answer1 - number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheMissingNumerator()}
        testCorrect={[answer1.toString()]}
        sentence={`<frac n='${number3}' d='${number2}'/> ${ADD} <frac nAns='' d='${number1}'/> = <frac w='1' n='${number4}' d='${number2}'/>`}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'apW',
  description: 'apW',
  keywords: ['Addition', 'Fractions'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(8),
    denominatorB: z.number().int().min(2).max(8),
    denominatorC: z.number().int().min(1).max(25),
    numeratorA: z.number().int().min(1).max(7),
    numeratorB: z.number().int().min(1).max(7),
    numeratorC: z.number().int().min(1).max(24),
    ansIdx: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const { denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC, ansIdx } =
      rejectionSample(
        () => {
          const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 8, 2);
          const denominatorC = denominatorA * denominatorB;

          const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
          const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
          const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
          const ansIdx = randomIntegerInclusive(1, 3);

          return {
            denominatorA,
            denominatorB,
            denominatorC,
            numeratorA,
            numeratorB,
            numeratorC,
            ansIdx
          };
        },
        ({ denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC }) => {
          return (
            denominatorA * denominatorB <= 25 &&
            // Ensure sum of fractions is >= 1
            fractionToDecimal(numeratorA, denominatorA) +
              fractionToDecimal(numeratorB, denominatorB) +
              fractionToDecimal(numeratorC, denominatorC) >=
              1
          );
        }
      );

    return { denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC, ansIdx };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        denominatorB,
        denominatorC,
        numeratorA,
        numeratorB,
        numeratorC,
        ansIdx
      },
      translate
    } = props;

    // Simplify fractions
    const [simplifiedNumerator1A, simplifiedDenominator1A] = simplify(numeratorA, denominatorA);
    const [simplifiedNumerator2A, simplifiedDenominator2A] = simplify(numeratorB, denominatorB);
    const [simplifiedNumerator3A, simplifiedDenominator3A] = simplify(numeratorC, denominatorC);

    // Get sum as decimal
    const sum =
      fractionToDecimal(simplifiedNumerator1A, simplifiedDenominator1A) +
      fractionToDecimal(simplifiedNumerator2A, simplifiedDenominator2A) +
      fractionToDecimal(simplifiedNumerator3A, simplifiedDenominator3A);

    // Convert sum back to fraction
    const [ansImproperFracNumerator, ansImproperFracDenominator] = decimalToFraction(sum);

    // Convert improper fraction to mixed number or integer
    const ans = improperFractionToMixedNumber(ansImproperFracNumerator, ansImproperFracDenominator);

    // Check if integer or mixed number
    const ansIsInteger = Number.isInteger(sum);

    const [fracA, fracB, fracC] = shuffle(
      [
        [simplifiedNumerator1A, simplifiedDenominator1A],
        [simplifiedNumerator2A, simplifiedDenominator2A],
        [simplifiedNumerator3A, simplifiedDenominator3A]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutMissingNumerator()}
        testCorrect={
          // Numerator ans either first, second, third fraction
          ansIdx === 1
            ? [fracA[0].toString()]
            : ansIdx === 2
            ? [fracB[0].toString()]
            : [fracC[0].toString()]
        }
        inputMaxCharacters={2}
        textStyle={{ fontSize: 40 }}
        sentence={
          ansIdx === 1
            ? `<frac nAns='' d='${fracA[1]}' /> ${ADD} <frac n='${fracB[0]}' d='${
                fracB[1]
              }'/> ${ADD} <frac n='${fracC[0]}' d='${fracC[1]}'/> = ${
                ansIsInteger ? ans[0] : `<frac w='${ans[0]}' n='${ans[1]}' d='${ans[2]}' />`
              }`
            : ansIdx === 2
            ? `<frac n='${fracA[0]}' d='${fracA[1]}' /> ${ADD} <frac nAns='' d='${
                fracB[1]
              }'/> ${ADD} <frac n='${fracC[0]}' d='${fracC[1]}'/> = ${
                ansIsInteger ? ans[0] : `<frac w='${ans[0]}' n='${ans[1]}' d='${ans[2]}' />`
              }`
            : `<frac n='${fracA[0]}' d='${fracA[1]}' /> ${ADD} <frac n='${fracB[0]}' d='${
                fracB[1]
              }'/> ${ADD} <frac nAns='' d='${fracC[1]}'/> = ${
                ansIsInteger ? ans[0] : `<frac w='${ans[0]}' n='${ans[1]}' d='${ans[2]}' />`
              }`
        }
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddFractionsWithTotalGreaterThan1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
