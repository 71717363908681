import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  displayMoney,
  moneyToHighestDenominations,
  numToCurrency,
  isValidMoneyAnswer
} from '../../../../utils/money';
import { View } from 'react-native';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from 'common/src/components/typography/Text';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { compareFloats } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axc',
  description: 'axc',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    poundsA: z.number().int().min(1).max(3),
    poundsB: z.number().int().min(4).max(9),
    poundsC: z.number().int().min(10).max(13)
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 3);
    const poundsB = randomIntegerInclusive(4, 9);
    const poundsC = randomIntegerInclusive(10, 13);

    return { poundsA, poundsB, poundsC };
  },

  Component: props => {
    const {
      question: { poundsA, poundsB, poundsC },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbers()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === (poundsA * 100).toString() &&
          userAnswer[1][0] === (poundsB * 100).toString() &&
          compareFloats(userAnswer[2][0], poundsC) &&
          isValidMoneyAnswer(userAnswer[2][0])
        }
        inputMaxCharacters={5}
        sentences={[
          `£${poundsA.toLocaleString()} = <ans/>p`,
          `£${poundsB.toLocaleString()} = <ans/>p`,
          `£<ans/> = ${(poundsC * 100).toLocaleString()}p`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(poundsA * 100).toLocaleString()],
            [(poundsB * 100).toLocaleString()],
            [poundsC.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axd',
  description: 'axd',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z
    .object({
      poundsA: z.number().int().min(1).max(4),
      penceA: z.number().int().min(1).max(99),
      poundsB: z.number().int().min(5).max(10),
      penceB: z.number().int().min(1).max(99),
      poundsC: z.number().int().min(11).max(24),
      penceC: z.number().int().min(1).max(99)
    })
    .refine(
      ({ penceA, penceB, penceC }) => arrayHasNoDuplicates([penceA, penceB, penceC]),
      'pence values should all be unique'
    ),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 4);
    const poundsB = randomIntegerInclusive(5, 10);
    const poundsC = randomIntegerInclusive(11, 24);

    const [penceA, penceB, penceC] = randomUniqueIntegersInclusive(1, 99, 3);

    return { poundsA, penceA, poundsB, penceB, poundsC, penceC };
  },

  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB, poundsC, penceC },
      translate
    } = props;

    const totalPenceA = poundsA * 100 + penceA;
    const totalPenceB = poundsB * 100 + penceB;
    const totalPenceC = poundsC * 100 + penceC;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbers()}
        testCorrect={[[totalPenceA.toString()], [totalPenceB.toString()], [totalPenceC.toString()]]}
        sentences={[
          `${numToCurrency({ amount: totalPenceA / 100 })} = <ans/>p`,
          `${numToCurrency({ amount: totalPenceB / 100 })} = <ans/>p`,
          `${numToCurrency({ amount: totalPenceC / 100 })} = <ans/>p`
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axe',
  description: 'axe',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    penceA: z.number().int().min(101).max(299),
    penceB: z.number().int().min(301).max(999),
    penceC: z.number().int().min(1001).max(1299)
  }),
  simpleGenerator: () => {
    const penceA = randomIntegerInclusive(101, 299, { constraint: x => x % 100 !== 0 });
    const penceB = randomIntegerInclusive(301, 999, { constraint: x => x % 100 !== 0 });
    const penceC = randomIntegerInclusive(1001, 1299, { constraint: x => x % 100 !== 0 });
    return { penceA, penceB, penceC };
  },

  Component: props => {
    const {
      question: { penceA, penceB, penceC },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertAmountsToPoundsPence()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], penceA / 100) &&
          compareFloats(userAnswer[1][0], penceB / 100) &&
          compareFloats(userAnswer[2][0], penceC / 100) &&
          userAnswer.every(answer => isValidMoneyAnswer(answer[0]))
        }
        sentences={[
          `${penceA.toLocaleString()}p = £<ans/>`,
          `${penceB.toLocaleString()}p = £<ans/>`,
          `${penceC.toLocaleString()}p = £<ans/>`
        ]}
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(penceA / 100).toFixed(2)],
            [(penceB / 100).toFixed(2)],
            [(penceC / 100).toFixed(2)]
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'axf',
  description: 'axf',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z
    .object({
      penceA: z.number().int().min(201).max(798),
      poundsBC: z.number().int().min(2).max(7),
      penceB: z.number().int().min(1).max(99),
      penceC: z.number().int().min(1).max(99),
      penceD: z.number().int().min(201).max(798)
    })
    .refine(val => val.penceC !== val.penceB, "penceC and penceB's value cannot be the same"),
  simpleGenerator: () => {
    const penceA = randomIntegerInclusive(201, 798, {
      constraint: x => {
        const digits = [...x.toString()];
        return x % 100 !== 0 && digits[1] !== digits[2];
      }
    });
    const poundsBC = Math.trunc(penceA / 100);
    const [penceB, penceC] = randomUniqueIntegersInclusive(1, 99, 2);

    const penceADigits = [...penceA.toString()];
    const penceDDigits = [penceADigits[0], penceADigits[2], penceADigits[1]];
    const penceD = Number.parseInt(penceDDigits.join(''));

    return { penceA, poundsBC, penceB, penceC, penceD };
  },

  Component: props => {
    const {
      question: { penceA, poundsBC, penceB, penceC, penceD },
      translate
    } = props;

    const totalPenceB = poundsBC * 100 + penceB;
    const totalPenceC = poundsBC * 100 + penceC;

    const statements = [
      {
        statement: `${penceA.toLocaleString()}p`,
        correctAnswer: numToCurrency({ amount: penceA / 100 })
      },
      {
        statement: numToCurrency({ amount: totalPenceB / 100 }),
        correctAnswer: `${totalPenceB}p`
      },
      {
        statement: numToCurrency({ amount: totalPenceC / 100 }),
        correctAnswer: `${totalPenceC}p`
      },
      {
        statement: `${penceD.toLocaleString()}p`,
        correctAnswer: numToCurrency({ amount: penceD / 100 })
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });
    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchAmountsThatAreEqual()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={shuffledStatements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={shuffledStatements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'axg',
  description: 'axg',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pounds: z.number().int().min(4).max(7),
    pence: z.number().int().min(81).max(99),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(4, 7);
    const pence = randomIntegerInclusive(81, 99, {
      constraint: x => x % 5 !== 0
    });
    const name = getRandomName();

    return { pounds, pence, name };
  },

  Component: props => {
    const {
      question: { pounds, pence, name: character },
      translate
    } = props;

    const acceptableDemonintions = [1, 2, 5, 10, 20, 50, 100, 200];
    const totalPence = pounds * 100 + pence;
    const acceptableAnswers = acceptableDemonintions.map(coin => (totalPence + coin).toString());

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasSavedXinYPenceGivenOneMoreCoinHowManyYPenceCoinsDoesCharacterHaveNow(
          {
            character,
            pounds,
            pence
          }
        )}
        testCorrect={x => acceptableAnswers.includes(x[0])}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyOfTheFollowingXYZ({ acceptableAnswers })
        }}
        inputMaxCharacters={3}
        sentence={translate.answerSentences.ansP()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axh',
  description: 'axh',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Convert'],
  schema: z.object({
    pounds: z.number().int().min(10).max(15),
    pence: z.number().int().min(1).max(9),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(10, 15);
    const pence = randomIntegerInclusive(1, 9, {
      constraint: x => x % 5 !== 0
    });
    const name = getRandomName();

    return { pounds, pence, name };
  },
  Component: props => {
    const {
      question: { pounds, pence, name: character },
      translate,
      displayMode
    } = props;

    const poundsAmount = moneyToHighestDenominations(pounds, 'pounds', [10, 5, 2, 1]);
    const penceAmount = moneyToHighestDenominations(pence, 'pence', [10, 5, 2, 1]);

    // Selectables
    const reversePence = pence * 10; // the pence value is always < 10, so this effectively reverses the 0X to X0
    const selectables = shuffle(
      [
        {
          value: 'A',
          amount: `${(pounds * 100 + pence).toLocaleString()}p`
        },
        {
          value: 'B',
          amount: `${(pounds * 10 + pence).toLocaleString()}p`
        },
        {
          value: 'C',
          amount: `£${pounds.toLocaleString()}.${pence.toLocaleString(undefined, {
            minimumIntegerDigits: 2
          })}`
        },
        {
          value: 'D',
          amount: `${(pounds * 100 + reversePence).toLocaleString()}p`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.characterHasThisMoneySelectTheAmountsThatShowHowMuchMoneyCharacterHasGot(
          { character }
        )}
        pdfTitle={translate.instructions.characterHasThisMoneySelectTheAmountsThatShowHowMuchMoneyCharacterHasGotPdf(
          { character }
        )}
        testCorrect={['A', 'C']}
        numItems={4}
        multiSelect
        contentContainerStyle={{ flex: 1 }}
        Content={({ dimens }) => (
          <>
            <View
              style={{
                ...dimens,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 12
              }}
            >
              {displayMoney(
                [...poundsAmount, ...penceAmount],
                displayMode === 'digital' ? 100 : 150,
                displayMode === 'digital' ? 100 : 150,
                true
              )}
            </View>
          </>
        )}
        renderItems={() =>
          selectables.map(selectable => {
            return {
              value: selectable.value,
              component: <Text variant="WRN700">{selectable.amount}</Text>
            };
          })
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertBetweenPoundsAndPence',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
