import { StyleSheet, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { barModelColors, BarModelColorsKey, colors } from 'common/src/theme/colors';
import { SetState } from 'common/src/utils/react';
import { range } from 'common/src/utils/collections';
import Text from '../../typography/Text';
import Table from 'common/src/components/molecules/Table';
import TextStructure from 'common/src/components/molecules/TextStructure';
import { useCallback } from 'react';
import { withStateHOC } from 'common/src/stateTree';
import { noop } from 'common/src/utils/flowControl';

type Props = {
  /**
   * Cells which should be always shaded and not interactive.
   */
  fixedCells: boolean[][];
  /**
   * Total number of rows required
   */
  numberOfRows: number;
  /**
   * Number of columns per row
   */
  numberOfCols: number;
  /**
   * The user answer, i.e. which cells are highlighted.
   */
  userAnswer: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<boolean[][]>;
  /**
   * Interactive cells color
   */
  interactiveCellsColor?: BarModelColorsKey;
  /**
   * Fixed cells color
   */
  fixedCellsColor?: BarModelColorsKey;
  /**
   * Table width
   */
  tableWidth?: number;
  /**
   * Table height
   */
  tableHeight?: number;
  /**
   * Table styles
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Text to display before the bar model. Optional prop.
   */
  preBarText?: string;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
};

/**
 * Similar to BarModelInteractive representation, but with a few differences.
 * Takes in fixedCells prop which is passed to TouchableOpacity to disable clicking on specific cells (fixed cells).
 * fixedCellsColor and interactiveCellsColor props allow different shading on cells dependent on whether cell is fixed or interactive.
 */

export const BarModelInteractiveWithFixedCells = ({
  numberOfRows,
  numberOfCols,
  userAnswer,
  setUserAnswer = noop,
  interactiveCellsColor,
  fixedCellsColor,
  tableWidth = 800 * 0.9,
  tableHeight = 400 * 0.9,
  style,
  preBarText,
  fractionContainerStyle,
  fractionTextStyle,
  fullWidthTopBrace,
  fixedCells
}: Props) => {
  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      setUserAnswer(userAnswer =>
        userAnswer.map((row, rowIndex) =>
          rowIndex !== rowIdx
            ? row
            : row.map((item, columnIndex) => (columnIndex !== colIdx ? item : !item))
        )
      );
    },
    [setUserAnswer]
  );

  // Set color for interactive color, fixed cells color and default color
  const setColor = (fixedCells: boolean[][], rowIndex: number, columnIndex: number) => {
    // If default state bar model has fixed cells
    if (fixedCells[rowIndex][columnIndex]) {
      // Use fixedCellsColor passed in or default to burntSienna
      return barModelColors[fixedCellsColor!] ?? colors.burntSienna;
    } else if (userAnswer[rowIndex][columnIndex]) {
      // Else if interactive bar model cell
      // Use interactiveCellsColor passed in or default to burntSienna
      return barModelColors[interactiveCellsColor!] ?? colors.burntSienna;
    } else {
      // Else return undefined
      return undefined;
    }
  };

  // Now return a table
  return (
    <View style={{ flexDirection: 'row' }}>
      {preBarText && (
        <TextStructure
          sentence={preBarText}
          fractionTextStyle={fractionTextStyle}
          fractionContainerStyle={fractionContainerStyle}
        />
      )}
      <View style={{ flexDirection: 'column' }}>
        {/* If fullWidthTopBrace prop passed render text with horizontal line above bar model */}
        {fullWidthTopBrace && (
          <View style={[styles.wholeContainer]}>
            <View style={styles.wholeHorizontalLine}>
              <Text style={styles.questionMark}>{fullWidthTopBrace}</Text>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineMiddle
                ]}
              ></View>
              <View
                style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineLeft]}
              ></View>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineRight
                ]}
              ></View>
            </View>
          </View>
        )}
        <Table
          style={[{ width: tableWidth, height: tableHeight }, style]}
          rowStyle={{ flex: 1 }} // Rows share the table height
          cellStyle={{ flex: 1 }} // Cells share the row width
          items={range(0, numberOfRows - 1).map(rowIndex =>
            range(0, numberOfCols - 1).map(columnIndex => {
              return (
                <TouchableOpacity
                  key={`${rowIndex}-${columnIndex}`}
                  disabled={fixedCells[rowIndex][columnIndex]}
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    backgroundColor: setColor(fixedCells, rowIndex, columnIndex),
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onPress={() => handleOnPress(rowIndex, columnIndex)}
                />
              );
            })
          )}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wholeContainer: {
    paddingBottom: 20
  },
  wholeHorizontalLine: {
    backgroundColor: colors.prussianBlue,
    height: 2,
    position: 'relative'
  },
  wholeHorizontalVerticalLine: {
    backgroundColor: colors.prussianBlue,
    height: 10,
    position: 'absolute',
    top: 0,
    width: 2
  },
  wholeHorizontalVerticalLineLeft: {
    left: 0
  },
  wholeHorizontalVerticalLineRight: {
    right: 0
  },
  wholeHorizontalVerticalLineMiddle: {
    alignSelf: 'center',
    height: 20,
    top: -20
  },
  questionMark: {
    alignSelf: 'center',
    fontSize: 24,
    position: 'absolute',
    top: -56
  }
});

/** See {@link BarModelInteractiveWithFixedCells}. */
export const BarModelInteractiveWithFixedCellsWithState = withStateHOC(
  BarModelInteractiveWithFixedCells,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer',
    defaults: props => ({
      defaultState: props.fixedCells
    })
  }
);
