import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, deduplicate } from '../../../../utils/collections';
import { fractionToDecimal } from '../../../../utils/fractions';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, nameSchema } from '../../../../utils/names';
import Clock, { Time12hSchema } from '../../../../components/question/representations/Clock';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { Dimens } from 'common/src/theme/scaling';
import { useMemo } from 'react';
import {
  InstructionActivitiesArray,
  getInstructionActivityInfo
} from '../../../../utils/activities';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import {
  EventNames,
  events,
  eventSchema,
  eventsSchema,
  getRandomUniqueEvents,
  getRandomUniqueEventsOld
} from '../../../../utils/events';
import { displayDigitalTime, sortNumbersByAmOrPm } from '../../../../utils/time';
import deepEqual from 'react-fast-compare';

////
// Questions
////

const Question1v2 = newQuestionContent({
  uid: 'at02',
  description: 'at0',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm'],
  schema: z.object({
    eventNames: z
      .array(eventSchema)
      .length(4)
      .refine(arrayHasNoDuplicates, 'events must be different')
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const eventNames = getRandomUniqueEvents(4);

    return { eventNames };
  },
  Component: ({ question: { eventNames }, translate }) => {
    const testCorrectGroup = (
      userAnswerGroup: EventNames[],
      timeOfDay: 'morning' | 'afternoon'
    ): boolean => userAnswerGroup.every(activity => events[activity].timeOfDay.includes(timeOfDay));

    const morningEvents = eventNames.filter(it => events[it].timeOfDay.includes('morning'));
    const afternoonEvents = eventNames.filter(
      it => events[it].timeOfDay.includes('afternoon') && !morningEvents.includes(it)
    );

    const markSchemeAnswer = [morningEvents, afternoonEvents];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen()}
        pdfTitle={translate.instructions.useCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen()}
        zoneNames={[translate.timePeriod.Morning(), translate.timePeriod.Afternoon()]}
        items={eventNames.map(eventName => ({
          value: eventName,
          component: translate.activities[eventName]()
        }))}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={([morningGroup, afternoonGroup]) =>
          testCorrectGroup(morningGroup, 'morning') && testCorrectGroup(afternoonGroup, 'afternoon')
        }
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question1 = newQuestionContent({
  uid: 'at0',
  description: 'at0',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm'],
  schema: z.object({
    events: eventsSchema.length(4).refine(arrayHasNoDuplicates, 'events must be different')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const events = getRandomUniqueEventsOld(4);

    return { events };
  },
  Component: ({ question: { events }, translate }) => {
    const morningEvents = events
      .filter(event => event.timeOfDay === 'morning')
      .map(event => event.name);
    const afternoonEvents = events
      .filter(event => event.timeOfDay === 'afternoon')
      .map(event => event.name);

    const correctOrder = [morningEvents, afternoonEvents];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen()}
        pdfTitle={translate.instructions.useCardsToSortTheEventsIntoTheTimeOfDayThatTheyUsuallyHappen()}
        zoneNames={[translate.timePeriod.Morning(), translate.timePeriod.Afternoon()]}
        items={events.map(event => event.name)}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={correctOrder}
        questionHeight={800}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'at1',
  description: 'at1',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Digital'],
  schema: z.object({
    name: nameSchema,
    hour: z.number().int().min(7).max(9),
    minute: z.number().int().min(0).max(55).multipleOf(5),
    activity: z.enum(InstructionActivitiesArray)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const hour = randomIntegerInclusive(7, 9);

    const [minMinute, maxMinute] = (() => {
      switch (hour) {
        case 7:
          return [45, 55];
        case 8:
          return [0, 55];
        default:
        case 9:
          return [0, 30];
      }
    })();

    const minute = randomIntegerInclusiveStep(minMinute, maxMinute, 5);

    const activity = getRandomFromArray(InstructionActivitiesArray);

    return { name, hour, minute, activity };
  },
  Component: props => {
    const {
      question: { name, hour, minute, activity },
      translate
    } = props;

    const [title, pdfTitle, amOrPm] = getInstructionActivityInfo(activity, name, translate);

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[amOrPm]}
        numItems={2}
        renderItems={({ dimens }) => {
          return [
            {
              value: 'am',
              component: (
                <DigitalClock
                  dimens={{ height: dimens.height, width: dimens.width - 16 }}
                  hours={hour}
                  minutes={minute}
                  amOrPm="am"
                />
              )
            },
            {
              value: 'pm',
              component: (
                <DigitalClock
                  dimens={{ height: dimens.height, width: dimens.width - 16 }}
                  hours={hour}
                  minutes={minute}
                  amOrPm="pm"
                />
              )
            }
          ];
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'at2',
  description: 'at2',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Digital'],
  schema: z
    .object({
      hoursA: z.number().int().min(0).max(23),
      hoursB: z.number().int().min(0).max(23),
      hoursC: z.number().int().min(0).max(23),
      hoursD: z.number().int().min(0).max(23),
      earliestOrLatest: z.enum(['earliest', 'latest'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.hoursA, val.hoursB, val.hoursC, val.hoursD]),
      'All hours must be different.'
    ),
  simpleGenerator: () => {
    const [hoursA, hoursB, hoursC, hoursD] = randomUniqueIntegersInclusive(0, 23, 4);

    const earliestOrLatest = getRandomFromArray(['earliest', 'latest'] as const);

    return { hoursA, hoursB, hoursC, hoursD, earliestOrLatest };
  },
  Component: props => {
    const {
      question: { hoursA, hoursB, hoursC, hoursD, earliestOrLatest },
      translate
    } = props;

    const hoursToOClockAmount = (hours: number) => {
      // If hours are 0, must display as '12 am'.
      if (hours === 0) {
        return 12;
      }
      if (hours < 13) {
        // Other am hours are already correct for an 'am' string. 12 is also already correct for '12 pm'.
        return hours;
      }
      // Other hours must be pm, so need their 12 subtracting from their amount to show correctly in a 'pm' string.
      return hours - 12;
    };

    const hoursAOClockAmount = hoursToOClockAmount(hoursA);
    const hoursBOClockAmount = hoursToOClockAmount(hoursB);
    const hoursCOClockAmount = hoursToOClockAmount(hoursC);
    const hoursDOClockAmount = hoursToOClockAmount(hoursD);

    const allHours = [hoursA, hoursB, hoursC, hoursD];

    const correctHour =
      earliestOrLatest === 'earliest'
        ? allHours.reduce((hour, current) => {
            return current < hour ? current : hour;
          }, allHours[0])
        : allHours.reduce((hour, current) => {
            return current > hour ? current : hour;
          }, allHours[0]);

    return (
      <QF11SelectImagesUpTo4
        title={
          earliestOrLatest === 'earliest'
            ? translate.instructions.selectTheEarliestTime()
            : translate.instructions.selectTheLatestTime()
        }
        pdfTitle={
          earliestOrLatest === 'earliest'
            ? translate.instructions.circleTheEarliestTime()
            : translate.instructions.circleTheLatestTime()
        }
        testCorrect={[correctHour]}
        numItems={4}
        renderItems={() => {
          return [
            {
              value: hoursA,
              component: (
                <Text variant="WRN700">
                  {hoursA < 12
                    ? translate.time.numAm(hoursAOClockAmount)
                    : translate.time.numPm(hoursAOClockAmount)}
                </Text>
              )
            },
            {
              value: hoursB,
              component: (
                <Text variant="WRN700">
                  {hoursB < 12
                    ? translate.time.numAm(hoursBOClockAmount)
                    : translate.time.numPm(hoursBOClockAmount)}
                </Text>
              )
            },
            {
              value: hoursC,
              component: (
                <Text variant="WRN700">
                  {hoursC < 12
                    ? translate.time.numAm(hoursCOClockAmount)
                    : translate.time.numPm(hoursCOClockAmount)}
                </Text>
              )
            },
            {
              value: hoursD,
              component: (
                <Text variant="WRN700">
                  {hoursD < 12
                    ? translate.time.numAm(hoursDOClockAmount)
                    : translate.time.numPm(hoursDOClockAmount)}
                </Text>
              )
            }
          ];
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'at3',
  description: 'at3',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Digital'],
  schema: z.object({
    times: z
      .array(
        z.object({
          timeType: z.enum(['am', 'pm']),
          hours: z.number().int().min(0).max(11),
          minutes: z.number().int().min(0).max(59)
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    // Ensure minutes are unique to prevent duplicate times
    const [minutesA, minutesB, minutesC, minutesD] = randomUniqueIntegersInclusive(0, 59, 4);

    const times = shuffle([
      {
        timeType: getRandomFromArray(['am', 'pm'] as const),
        hours: randomIntegerInclusive(0, 11),
        minutes: minutesA
      },
      {
        timeType: getRandomFromArray(['am', 'pm'] as const),
        hours: randomIntegerInclusive(0, 11),
        minutes: minutesB
      },
      {
        timeType: getRandomFromArray(['am', 'pm'] as const),
        hours: randomIntegerInclusive(0, 11),
        minutes: minutesC
      },
      {
        timeType: getRandomFromArray(['am', 'pm'] as const),
        hours: randomIntegerInclusive(0, 11),
        minutes: minutesD
      }
    ] as const);

    return { times };
  },
  Component: props => {
    const {
      question: { times },
      translate
    } = props;

    const amTimes = sortNumbersByAmOrPm('am', times);
    const pmTimes = sortNumbersByAmOrPm('pm', times);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderTheseTimesFromEarliestToLatest()}
        pdfTitle={translate.instructions.useCardsToOrderTheseTimesFromEarliestToLatest()}
        testCorrect={[...amTimes, ...pmTimes]}
        items={times.map(({ hours, minutes, timeType }) => {
          {
            const time = displayDigitalTime(
              timeType === 'am' ? hours : hours + 12,
              minutes,
              false,
              '12'
            );
            return {
              value: hours * 3600 + minutes * 60,
              component: <Text variant="WRN700">{time}</Text>
            };
          }
        })}
        topLabel={translate.keywords.Earliest()}
        bottomLabel={translate.keywords.Latest()}
      />
    );
  }
});

/**
 * Schema to use for a particular time. Note that the hours run from 0 to 11, whereas when spoken or displayed on a
 * digital clock in 12 hour time, we must use 12 instead of 0.
 * Use {@link hours12hToDisplayHour} just before displaying.
 */
const timeSchema = Time12hSchema.extend({
  amOrPm: z.enum(['am', 'pm'])
});

/** Display e.g. "past 12" instead of "past 0". */
const hours12hToDisplayHour = (hours: number) => (hours === 0 ? 12 : hours);

const Question5v2 = newQuestionContent({
  uid: 'at42',
  description: 'at4',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Analogue', 'Digital'],
  schema: z
    .object({
      options: z
        .array(z.object({ time: timeSchema, clock: z.enum(['digital', 'analogue']) }))
        .length(4)
        .refine(val => arrayHasNoDuplicates(val, deepEqual), 'options must not have duplicates'),
      correctAnswer: timeSchema
    })
    .refine(
      ({ options, correctAnswer }) => options.some(option => deepEqual(option.time, correctAnswer)),
      'options must contain at least one correct answer'
    ),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 11);
    const minutes = randomIntegerInclusiveStep(5, 55, 5, {
      constraint: x => x % 15 !== 0
    });
    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    const correctAnswer = { hours, minutes, amOrPm };
    const options = shuffle([
      { time: { ...correctAnswer, amOrPm: 'am' }, clock: 'digital' },
      { time: { ...correctAnswer, amOrPm: 'pm' }, clock: 'digital' },
      { time: correctAnswer, clock: 'analogue' },
      { time: { ...correctAnswer, hours: (hours + 1) % 12 }, clock: 'analogue' }
    ] as const);

    return { options, correctAnswer };
  },
  Component: props => {
    const {
      question: { options, correctAnswer },
      translate
    } = props;

    const { hours, minutes, amOrPm } = correctAnswer;

    const getTitle = (isPdf: boolean) => {
      switch (amOrPm) {
        case 'am': {
          // Morning strings
          if (minutes > 30) {
            return (
              isPdf
                ? translate.instructions.circleClocksThatShowXMinutesToYInTheMorning
                : translate.instructions.selectClocksThatShowXMinutesToYInTheMorning
            )(60 - minutes, hours12hToDisplayHour((hours + 1) % 12));
          } else {
            return (
              isPdf
                ? translate.instructions.circleClocksThatShowXMinutesPastYInTheMorning
                : translate.instructions.selectClocksThatShowXMinutesPastYInTheMorning
            )(minutes, hours12hToDisplayHour(hours));
          }
        }
        case 'pm': {
          // Afternoon or evening strings
          if (hours > 5) {
            // Evening strings
            if (minutes > 30) {
              return (
                isPdf
                  ? translate.instructions.circleClocksThatShowXMinutesToYInTheEvening
                  : translate.instructions.selectClocksThatShowXMinutesToYInTheEvening
              )(60 - minutes, hours12hToDisplayHour((hours + 1) % 12));
            } else {
              return (
                isPdf
                  ? translate.instructions.circleClocksThatShowXMinutesPastYInTheEvening
                  : translate.instructions.selectClocksThatShowXMinutesPastYInTheEvening
              )(minutes, hours12hToDisplayHour(hours));
            }
          } else {
            // Afternoon strings
            if (minutes > 30) {
              return (
                isPdf
                  ? translate.instructions.circleClocksThatShowXMinutesToYInTheAfternoon
                  : translate.instructions.selectClocksThatShowXMinutesToYInTheAfternoon
              )(60 - minutes, hours12hToDisplayHour((hours + 1) % 12));
            } else {
              return (
                isPdf
                  ? translate.instructions.circleClocksThatShowXMinutesPastYInTheAfternoon
                  : translate.instructions.selectClocksThatShowXMinutesPastYInTheAfternoon
              )(minutes, hours12hToDisplayHour(hours));
            }
          }
        }
      }
    };

    return (
      <QF11SelectImagesUpTo4
        title={getTitle(false)}
        pdfTitle={getTitle(true)}
        testCorrect={options.filter(option => deepEqual(option.time, correctAnswer))}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          options.map(option => ({
            value: option,
            component:
              option.clock === 'digital' ? (
                <DigitalClock
                  hours={hours12hToDisplayHour(option.time.hours)}
                  minutes={option.time.minutes}
                  dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                  amOrPm={option.time.amOrPm}
                />
              ) : (
                <Clock
                  width={dimens.height * 0.9}
                  time={{ hours: option.time.hours, minutes: option.time.minutes }}
                  interactive={false}
                />
              )
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

/** @deprecated archived */
const Question5 = newQuestionContent({
  uid: 'at4',
  description: 'at4',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Analogue', 'Digital'],
  schema: z.object({
    hour: z.number().int().min(1).max(11),
    minute: z
      .number()
      .int()
      .min(5)
      .max(55)
      .multipleOf(5)
      .refine(val => val % 15 !== 0, 'minute must not be 15, 30 or 45'),
    amOrPm: z.enum(['am', 'pm'])
  }),
  simpleGenerator: () => {
    const hour = randomIntegerInclusive(1, 11);

    const minute = randomIntegerInclusiveStep(5, 55, 5, {
      constraint: x => x % 15 !== 0
    });

    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    return { hour, minute, amOrPm };
  },
  Component: props => {
    const {
      question: { hour, minute, amOrPm },
      translate
    } = props;

    const statements = useMemo(() => {
      return [
        {
          component: (dimens: Dimens) => (
            <DigitalClock
              hours={hour}
              minutes={minute}
              dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
              amOrPm="am"
            />
          ),
          isCorrect: amOrPm === 'am'
        },
        {
          component: (dimens: Dimens) => (
            <DigitalClock
              hours={hour}
              minutes={minute}
              dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
              amOrPm="pm"
            />
          ),
          isCorrect: amOrPm === 'pm'
        },
        {
          component: (dimens: Dimens) => (
            <Clock
              width={dimens.height * 0.9}
              time={{ hours: hour, minutes: minute }}
              interactive={false}
            />
          ),
          isCorrect: true
        },
        {
          component: (dimens: Dimens) => (
            <Clock
              width={dimens.height * 0.9}
              time={{ hours: hour + 1, minutes: minute }}
              interactive={false}
            />
          ),
          isCorrect: false
        }
      ];
    }, [amOrPm, hour, minute]);

    const correctHourString = minute > 30 ? (hour === 12 ? 1 : hour + 1) : hour;

    const correctMinuteString = minute > 30 ? 60 - minute : minute;

    const title = (() => {
      if (amOrPm === 'am') {
        // Morning strings
        if (minute > 30) {
          return translate.instructions.selectClocksThatShowXMinutesToYInTheMorning(
            correctMinuteString,
            correctHourString
          );
        } else {
          return translate.instructions.selectClocksThatShowXMinutesPastYInTheMorning(
            correctMinuteString,
            correctHourString
          );
        }
      } else {
        // Evening strings
        if (hour > 5) {
          if (minute > 30) {
            return translate.instructions.selectClocksThatShowXMinutesToYInTheEvening(
              correctMinuteString,
              correctHourString
            );
          } else {
            return translate.instructions.selectClocksThatShowXMinutesPastYInTheEvening(
              correctMinuteString,
              correctHourString
            );
          }
          // Afternoon strings
        } else {
          if (minute > 30) {
            return translate.instructions.selectClocksThatShowXMinutesToYInTheAfternoon(
              correctMinuteString,
              correctHourString
            );
          } else {
            return translate.instructions.selectClocksThatShowXMinutesPastYInTheAfternoon(
              correctMinuteString,
              correctHourString
            );
          }
        }
      }
    })();

    const pdfTitle = (() => {
      if (amOrPm === 'am') {
        // Morning strings
        if (minute > 30) {
          return translate.instructions.circleClocksThatShowXMinutesToYInTheMorning(
            correctMinuteString,
            correctHourString
          );
        } else {
          return translate.instructions.circleClocksThatShowXMinutesPastYInTheMorning(
            correctMinuteString,
            correctHourString
          );
        }
      } else {
        // Evening strings
        if (hour > 5) {
          if (minute > 30) {
            return translate.instructions.circleClocksThatShowXMinutesToYInTheEvening(
              correctMinuteString,
              correctHourString
            );
          } else {
            return translate.instructions.circleClocksThatShowXMinutesPastYInTheEvening(
              correctMinuteString,
              correctHourString
            );
          }
          // Afternoon strings
        } else {
          if (minute > 30) {
            return translate.instructions.circleClocksThatShowXMinutesToYInTheAfternoon(
              correctMinuteString,
              correctHourString
            );
          } else {
            return translate.instructions.circleClocksThatShowXMinutesPastYInTheAfternoon(
              correctMinuteString,
              correctHourString
            );
          }
        }
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.component)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shuffle(statements, {
            random: seededRandom(props.question)
          }).map(({ component }) => ({
            value: component,
            component: component(dimens)
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'at5',
  description: 'at5',
  keywords: ['Time', 'Morning', 'Afternoon', 'am', 'pm', 'Digital'],
  schema: z.object({
    name: nameSchema,
    quarterPastOrQuarterTo: z.enum(['quarter past', 'quarter to']),
    hoursA: z.number().int().min(6).max(9),
    hoursB: z.number().int().min(1).max(12),
    minutesB: z.number().int().min(5).max(55).multipleOf(5),
    hoursD: z.number().int().min(1).max(12),
    minutesD: z.number().int().min(5).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const quarterPastOrQuarterTo = getRandomFromArray(['quarter past', 'quarter to'] as const);

    const hoursA = randomIntegerInclusive(6, 9);

    const [hoursB, hoursD] = randomUniqueIntegersInclusive(1, 12, 2);

    // Guarantee that hoursB and minutesB/hoursD and minutesD will be different from all other times.
    const [minutesB, minutesD] = randomUniqueIntegersInclusiveStep(5, 55, 5, 2, {
      constraint: x => (x !== 30 && quarterPastOrQuarterTo === 'quarter past' ? x !== 45 : x !== 15)
    });

    return { name, quarterPastOrQuarterTo, hoursA, hoursB, minutesB, hoursD, minutesD };
  },

  Component: props => {
    const {
      question: { name, quarterPastOrQuarterTo, hoursA, hoursB, minutesB, hoursD, minutesD },
      translate
    } = props;
    const random = seededRandom(props.question);

    let answers =
      quarterPastOrQuarterTo === 'quarter past'
        ? [
            {
              string: translate.time.digitalTimeAm({
                hours: hoursA,
                minutes: (0).toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursA
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursB,
                minutes: minutesB.toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursB + 12 + fractionToDecimal(minutesB, 60)
            },
            {
              string: translate.time.digitalTimePm({ hours: hoursA - 1, minutes: 45 }),
              // value is hoursA, plus 11 due to time being pm, plus 0.75 to account for 'quarter to'
              value: hoursA + 11.75
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursD,
                minutes: minutesD.toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursD + 12 + fractionToDecimal(minutesD, 60)
            },
            {
              string: translate.time.digitalTimePm({ hours: hoursA, minutes: 30 }),
              value: hoursA + 12.5
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursA,
                minutes: (0).toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursA + 12
            }
          ]
        : [
            {
              string: translate.time.digitalTimeAm({
                hours: hoursA + 1,
                minutes: (0).toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursA + 1
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursB,
                minutes: minutesB.toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursB + 12 + fractionToDecimal(minutesB, 60)
            },
            {
              string: translate.time.digitalTimePm({ hours: hoursA, minutes: 15 }),
              value: hoursA + 12.25
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursD,
                minutes: minutesD.toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursD + 12 + fractionToDecimal(minutesD, 60)
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursA,
                minutes: (0).toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursA + 12
            },
            {
              string: translate.time.digitalTimePm({
                hours: hoursA + 1,
                minutes: (0).toLocaleString(undefined, { minimumIntegerDigits: 2 })
              }),
              value: hoursA + 13
            }
          ];

    // We have sometimes spotted that `answers` contains duplicate values. Defensively deduplicate.
    // This means that sometimes the question has <6 answers, but this is better than crashing.
    answers = deduplicate(answers, x => x.value);

    const arrivalTimeAsValue =
      quarterPastOrQuarterTo === 'quarter past' ? hoursA + 12.25 : hoursA + 11.75;

    const closestAnswer = (() => {
      let closestValueChecked = null;

      for (const obj of answers) {
        const currentCheckedValue = obj.value;

        if (currentCheckedValue >= arrivalTimeAsValue) {
          if (closestValueChecked === null || currentCheckedValue < closestValueChecked) {
            closestValueChecked = currentCheckedValue;
          }
        }
      }

      // There is at least one correct answer, so this can never be null by this point
      return closestValueChecked!;
    })();

    const shuffledAnswers = shuffle(answers, { random });

    return (
      <QF10SelectNumbers
        title={
          quarterPastOrQuarterTo === 'quarter past'
            ? translate.instructions.characterGetsToTheCinemaAtQuarterPast(name, hoursA)
            : translate.instructions.characterGetsToTheCinemaAtQuarterTo(name, hoursA)
        }
        testCorrect={[closestAnswer]}
        items={shuffledAnswers.map(time => ({
          value: time.value,
          component: time.string
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseAMAndPM',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5v2, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1, Question5]
});
export default SmallStep;
