import { View } from 'react-native';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD } from '../../../../constants';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import TextStructure from '../../../../components/molecules/TextStructure';
import {
  coinScaledSizes,
  displayMoney,
  moneyToHighestDenominations
} from '../../../../utils/money';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from 'common/src/components/typography/Text';
import {
  aSmallItemAsWords,
  getRandomUniqueSmallItemNames,
  smallItemSVG,
  smallItemsSchema
} from '../../../../utils/items';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { MarkupAssets } from '../../../../markup';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atk',
  description: 'atk',
  keywords: ['Money', 'Pounds', 'Pence', 'Add'],
  schema: z.object({
    poundsA: z.number().int().min(1).max(10),
    poundsB: z.number().int().min(1).max(6),
    penceA: z.number().int().min(5).max(45).step(5),
    penceB: z.number().int().min(10).max(50).step(10),
    characterNameA: nameSchema,
    characterNameB: nameSchema
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 10);
    const poundsB = randomIntegerInclusive(1, 6);
    const penceA = randomIntegerInclusiveStep(5, 45, 5);
    const penceB = randomIntegerInclusiveStep(10, 50, 10);
    const [characterNameA, characterNameB] = getRandomUniqueNames(2);

    return { poundsA, poundsB, penceA, penceB, characterNameA, characterNameB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { poundsA, poundsB, penceA, penceB, characterNameA, characterNameB } = question;

    const moneyA = [
      ...moneyToHighestDenominations(poundsA, 'pounds'),
      ...moneyToHighestDenominations(penceA, 'pence')
    ];

    const moneyB = [
      ...moneyToHighestDenominations(poundsB, 'pounds'),
      ...moneyToHighestDenominations(penceB, 'pence')
    ];

    const totalPounds = poundsA + poundsB;
    const totalPence = penceA + penceB;

    const coinScales = coinScaledSizes([...moneyA, ...moneyB]);

    return (
      <QF1ContentAndSentence
        title={translate.answerSentences.characterHasXPoundsAndYPence(
          characterNameA,
          poundsA,
          penceA
        )}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[totalPounds.toString(), totalPence.toString()]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center',
                alignSelf: 'center'
              }}
            >
              {moneyA.map(money =>
                displayMoney(
                  [money],
                  (displayMode === 'digital' ? 100 : 150) *
                    // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                    (money in coinScales ? coinScales[money] : 1),
                  (displayMode === 'digital' ? 100 : 150) *
                    // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                    (money in coinScales ? coinScales[money] : 1)
                )
              )}
            </View>
            <TextStructure
              sentence={translate.answerSentences.characterHasXPoundsAndYPence(
                characterNameB,
                poundsB,
                penceB
              )}
            />
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center',
                alignSelf: 'center'
              }}
            >
              {moneyB.map(money =>
                displayMoney(
                  [money],
                  (displayMode === 'digital' ? 100 : 150) *
                    // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                    (money in coinScales ? coinScales[money] : 1),
                  (displayMode === 'digital' ? 100 : 150) *
                    // Check to see if money is in coinScales - pound notes should not be in this object, so should just have a scale of 1:
                    (money in coinScales ? coinScales[money] : 1)
                )
              )}
            </View>
            <TextStructure
              sentence={translate.answerSentences.howMuchMoneyDoTheyHaveAltogether()}
            />
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'atl',
  description: 'atl',
  keywords: ['Money', 'Pounds', 'Pence', 'Add'],
  schema: z
    .object({
      poundsA: z.number().int().min(1).max(4),
      penceA: z.number().int().min(5).max(45).step(5),
      poundsB: z.number().int().min(1).max(4),
      penceB: z.number().int().min(5).max(45).step(5),
      itemA: smallItemsSchema,
      itemB: smallItemsSchema,
      characterName: nameSchema
    })
    .refine(
      val => val.poundsA !== val.poundsB && val.penceA !== val.penceB,
      'poundsA cannot equal poundsB and penceA cannot equal penceB'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const [poundsA, poundsB] = randomUniqueIntegersInclusive(1, 4, 2);
    const [penceA, penceB] = randomUniqueIntegersInclusiveStep(5, 45, 5, 2);

    const [itemA, itemB] = rejectionSample(
      () => getRandomUniqueSmallItemNames(3),
      // Only one pair of socks can be choosen
      items => items.filter(item => item.includes('socks')).length <= 1
    );

    const characterName = getRandomName();

    return { poundsA, poundsB, penceA, penceB, characterName, itemA, itemB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { poundsA, poundsB, penceA, penceB, characterName, itemA, itemB } = question;

    const totalPounds = poundsA + poundsB;
    const totalPence = penceA + penceB;

    const items = [
      {
        svg: smallItemSVG(itemA),
        text: translate.answerSentences.xPoundsAndYPence(poundsA, penceA)
      },
      {
        svg: smallItemSVG(itemB),
        text: translate.answerSentences.xPoundsAndYPence(poundsB, penceB)
      }
    ];

    return (
      <QF3Content
        title={translate.instructions.characterBuysTwoItemsCompleteSentencesToShowHowMuchMoneyCharacterSpends(
          characterName
        )}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              {items.map(({ svg, text }, i) => (
                <View key={i} style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
                  {svg}
                  <ContentBox
                    containerStyle={{
                      width: dimens.width * 0.25,
                      padding: 0,
                      justifyContent: 'center'
                    }}
                  >
                    <Text
                      style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                      variant="WRN400"
                    >
                      {text}
                    </Text>
                  </ContentBox>
                </View>
              ))}
            </View>
            <View
              style={{ flexDirection: 'row', width: dimens.width, justifyContent: 'space-between' }}
            >
              <CompleteTheSentenceWithState
                id="sentenceA"
                sentence={translate.answerSentences.xPoundsAddYPoundsEqualsAnsPounds(
                  poundsA,
                  poundsB
                )}
                testCorrect={userAnswer => userAnswer[0] === totalPounds.toString()}
                defaultState={
                  displayMode === 'markscheme' ? [totalPounds.toLocaleString()] : undefined
                }
                style={{ alignSelf: 'flex-start' }}
                inputMaxCharacters={4}
              />
              <CompleteTheSentenceWithState
                id="sentenceB"
                sentence={translate.answerSentences.xPAddYPEqualsAnsP(penceA, penceB)}
                testCorrect={userAnswer => userAnswer[0] === totalPence.toString()}
                defaultState={
                  displayMode === 'markscheme' ? [totalPence.toLocaleString()] : undefined
                }
                style={{ alignSelf: 'flex-end' }}
                inputMaxCharacters={4}
              />
            </View>
            <CompleteTheSentenceWithState
              id="sentenceC"
              sentence={translate.answerSentences.characterSpendsPoundAnsAndPenceAns(characterName)}
              testCorrect={userAnswer =>
                userAnswer[0] === totalPounds.toString() && userAnswer[1] === totalPence.toString()
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [totalPounds.toLocaleString(), totalPence.toLocaleString()]
                  : undefined
              }
              style={{ alignSelf: 'flex-start' }}
              inputMaxCharacters={4}
            />
          </View>
        )}
        questionHeight={800}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'atl2',
  description: 'atl',
  keywords: ['Money', 'Pounds', 'Pence', 'Add'],
  schema: z
    .object({
      poundsA: z.number().int().min(1).max(4),
      penceA: z.number().int().min(5).max(45).step(5),
      poundsB: z.number().int().min(1).max(4),
      penceB: z.number().int().min(5).max(45).step(5),
      itemA: smallItemsSchema,
      itemB: smallItemsSchema,
      characterName: nameSchema
    })
    .refine(
      val => val.poundsA !== val.poundsB && val.penceA !== val.penceB,
      'poundsA cannot equal poundsB and penceA cannot equal penceB'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const [poundsA, poundsB] = randomUniqueIntegersInclusive(1, 4, 2);
    const [penceA, penceB] = randomUniqueIntegersInclusiveStep(5, 45, 5, 2);

    const [itemA, itemB] = rejectionSample(
      () => getRandomUniqueSmallItemNames(3),
      // Only one pair of socks can be choosen
      items => items.filter(item => item.includes('socks')).length <= 1
    );

    const characterName = getRandomName();

    return { poundsA, poundsB, penceA, penceB, characterName, itemA, itemB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { poundsA, poundsB, penceA, penceB, characterName, itemA, itemB } = question;

    const totalPounds = poundsA + poundsB;
    const totalPence = penceA + penceB;

    const titleItemA = aSmallItemAsWords(itemA, translate);
    const titleItemB = aSmallItemAsWords(itemB, translate);

    const items = [
      {
        svg: smallItemSVG(itemA),
        text: translate.answerSentences.xPoundsAndYPence(poundsA, penceA)
      },
      {
        svg: smallItemSVG(itemB),
        text: translate.answerSentences.xPoundsAndYPence(poundsB, penceB)
      }
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterBuysXAndYWhatIsTheTotalCost(
          characterName,
          titleItemA,
          titleItemB
        )}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[totalPounds.toString(), totalPence.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center' }]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              {items.map(({ svg, text }, i) => (
                <View key={i} style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
                  {svg}
                  <ContentBox
                    containerStyle={{
                      width: dimens.width * 0.25,
                      padding: 0,
                      justifyContent: 'center'
                    }}
                  >
                    <Text
                      style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                      variant="WRN400"
                    >
                      {text}
                    </Text>
                  </ContentBox>
                </View>
              ))}
            </View>
          </View>
        )}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'atm',
  description: 'atm',
  keywords: ['Money', 'Pounds', 'Pence', 'Addition'],
  schema: z.object({
    poundsA: z.number().int().min(1).max(9),
    penceA: z.number().int().min(1).max(44),
    poundsB: z.number().int().min(1).max(9),
    penceB: z.number().int().min(1).max(44)
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 9);
    const poundsB = randomIntegerInclusive(1, 9);
    const penceATens = randomIntegerInclusiveStep(10, 40, 10);
    const penceAOnes = randomIntegerInclusive(1, 4);
    const penceBTens = randomIntegerInclusiveStep(10, 40, 10);
    const penceBOnes = randomIntegerInclusive(1, 4);

    const penceA = penceATens + penceAOnes;
    const penceB = penceBTens + penceBOnes;

    return { poundsA, penceA, poundsB, penceB };
  },

  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={[(poundsA + poundsB).toString(), (penceA + penceB).toString()]}
        sentence={`£${poundsA.toLocaleString()} ${translate.misc.and()} ${penceA.toLocaleString()}p ${ADD} £${poundsB.toLocaleString()} ${translate.misc.and()} ${penceB.toLocaleString()}p = £<ans/> and <ans/>p`}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'atn',
  description: 'atn',
  keywords: ['Money', 'Pounds', 'Pence', 'Addition', 'Convert'],
  schema: z
    .object({
      poundsA: z.number().int().min(1).max(9),
      penceA: z.number().int().min(50).max(90).multipleOf(10),
      poundsB: z.number().int().min(1).max(9),
      penceB: z.number().int().min(51).max(99)
    })
    .refine(val => val.penceA + val.penceB > 100, 'pence values exchange into pounds'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 9);
    const poundsB = randomIntegerInclusive(1, 9);
    const penceA = randomIntegerInclusiveStep(50, 90, 10);
    const penceB = randomIntegerInclusive(51, 99, {
      constraint: x => penceA + x > 100 && x % 10 !== 0
    });

    return { poundsA, penceA, poundsB, penceB };
  },

  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB },
      translate
    } = props;

    const poundsAns = poundsA + poundsB + 1;
    const penceAns = penceA + penceB - 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={[poundsAns.toString(), penceAns.toString()]}
        sentence={`£${poundsA.toLocaleString()} ${translate.misc.and()} ${penceA.toLocaleString()}p ${ADD} £${poundsB.toLocaleString()} ${translate.misc.and()} ${penceB.toLocaleString()}p = £<ans/> and <ans/>p`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ato',
  description: 'ato',
  keywords: ['Money', 'Pounds', 'Pence', 'Addition'],
  schema: z.object({
    poundsA: z.number().int().min(1).max(15),
    poundsB: z.number().int().min(1).max(9),
    penceB: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(1, 15);
    const poundsB = randomIntegerInclusive(1, 9);
    const penceB = randomIntegerInclusiveStep(10, 90, 10);

    return { poundsA, poundsB, penceB };
  },

  Component: ({ question: { poundsA, poundsB, penceB }, translate, displayMode }) => {
    const poundsAInPence = poundsA * 100;
    const poundsAPenceATotal = poundsAInPence + 99;

    const poundsBInPence = poundsB * 100;
    const poundsBPenceBTotal = poundsBInPence + penceB;

    // Ensures bar model width is wide enough to display text large enough
    const poundsACellWidth = poundsAPenceATotal <= 300 ? 300 : poundsAPenceATotal;
    const poundsBCellWidth = poundsBPenceBTotal <= 300 ? 300 : poundsBPenceBTotal;

    const totalWidth = poundsACellWidth + poundsBCellWidth;

    const strings = [
      ['?'],
      [
        translate.answerSentences.poundsXAndYP(poundsA.toLocaleString(), (99).toLocaleString()),
        translate.answerSentences.poundsXAndYP(poundsB.toLocaleString(), penceB.toLocaleString())
      ]
    ];
    const numbers = [[totalWidth], [poundsACellWidth, poundsBCellWidth]];

    const answerPounds = poundsA + poundsB + 1;
    const answerPence = 99 + penceB - 100;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToWorkOutMissingValue()}
        testCorrect={[answerPounds.toString(), answerPence.toString()]}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={totalWidth}
            dimens={dimens}
            sameRowColor
            maxFontSize={displayMode === 'digital' ? 32 : 50}
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atp',
  description: 'atp',
  keywords: ['Money', 'Pounds', 'Pence', 'Add'],
  schema: z.object({
    poundsA: z.number().int().min(3).max(4),
    penceA: z.number().int().min(1).max(49),
    poundsB: z.number().int().min(1).max(2),
    itemNames: z.array(smallItemsSchema)
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(3, 4);
    const penceA = randomIntegerInclusive(1, 49);
    const poundsB = randomIntegerInclusive(1, 2);

    const itemNames = rejectionSample(
      () => getRandomUniqueSmallItemNames(3),
      // Only one pair of socks can be choosen
      items => items.filter(item => item.includes('socks')).length <= 1
    );

    return { poundsA, poundsB, penceA, itemNames };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB, penceA, itemNames },
      translate
    } = props;

    const [itemA, itemB, itemC] = itemNames.map(name => smallItemSVG(name, 80, 80));

    const items = shuffle(
      [
        {
          svg: 'itemA',
          text: translate.answerSentences.poundsXAndYP(
            poundsA.toLocaleString(),
            penceA.toLocaleString()
          ),
          amount: poundsA * 100 + penceA
        },
        {
          svg: 'itemB',
          text: translate.answerSentences.xP((99).toLocaleString()),
          amount: 99
        },
        {
          svg: 'itemC',
          text: translate.answerSentences.poundsXAndYP(
            poundsB.toLocaleString(),
            (50).toLocaleString()
          ),
          amount: poundsB * 100 + 50
        }
      ],
      { random: seededRandom(props.question) }
    );

    const topLhsValue = items[0].amount + items[1].amount;
    const topRhsValue = items[0].amount + items[2].amount;
    const bottomLhsValue = items[0].amount;
    const bottomRhsValue = items[1].amount + items[2].amount;

    const answerA = lessThanGreaterThanOrEqualTo(topLhsValue, topRhsValue);
    const answerB = lessThanGreaterThanOrEqualTo(bottomLhsValue, bottomRhsValue);

    return (
      <MarkupAssets
        elements={{
          itemA,
          itemB,
          itemC
        }}
      >
        <QF36ContentAndSentencesDrag
          title={translate.instructions.dragCardsCompleteStatements()}
          pdfTitle={translate.instructions.useInequalitySymbolsToMakeStatementCorrect()}
          items={['>', '<', '=']}
          pdfLayout="itemsHidden"
          actionPanelVariant="end"
          Content={
            <View
              style={{
                flexDirection: 'row',
                columnGap: 50
              }}
            >
              {items.map(({ svg, text }, i) => (
                <View key={i} style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <ContentBox>
                    <TextStructure sentence={`<asset name='${svg}'/> ${text}`} />
                  </ContentBox>
                </View>
              ))}
            </View>
          }
          moveOrCopy="copy"
          sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
          sentences={[
            `<asset name="${items[0].svg}"/>  ${ADD}  <asset name="${items[1].svg}"/>   <ans/>   <asset name="${items[0].svg}"/>  ${ADD}  <asset name="${items[2].svg}"/>`,
            `<asset name="${items[0].svg}"/>   <ans/>   <asset name="${items[1].svg}"/>  ${ADD}  <asset name="${items[2].svg}"/>`
          ]}
          testCorrect={[[answerA], [answerB]]}
          questionHeight={900}
        />
      </MarkupAssets>
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddMoney',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2],
  unpublishedQuestionTypes: []
});
export default SmallStep;
