import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle,
  getRandomSubArrayFromArray
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { MULT } from 'common/src/constants';
import { numberEnum } from 'common/src/utils/zod';
import { arrayHasNoDuplicates, range, sortNumberArray } from 'common/src/utils/collections';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { AssetSvg } from 'common/src/assets/svg';
import QF37SentencesDrag from 'common/src/components/question/questionFormats/QF37SentencesDrag';
import { useMemo } from 'react';
import QF37SentenceDrag from 'common/src/components/question/questionFormats/QF37SentenceDrag';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'amf',
  description: 'amf',
  keywords: ['Multiply', 'Equivalent'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(10),
    numberA2: z.number().int().min(2).max(10),
    numberA3: z.number().int().min(2).max(10),
    numberB1: z.number().int().min(2).max(10),
    numberB2: z.number().int().min(2).max(10),
    numberB3: z.number().int().min(2).max(10),
    numberC1: z.number().int().min(2).max(10),
    numberC2: z.number().int().min(2).max(10),
    numberC3: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const {
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3
    } = rejectionSample(
      () => {
        const [numberA1, numberA2, numberA3] = randomUniqueIntegersInclusive(2, 10, 3);

        const [numberB1, numberB2, numberB3] = randomUniqueIntegersInclusive(2, 10, 3);

        const [numberC1, numberC2, numberC3] = randomUniqueIntegersInclusive(2, 10, 3);
        return {
          numberA1,
          numberA2,
          numberA3,
          numberB1,
          numberB2,
          numberB3,
          numberC1,
          numberC2,
          numberC3
        };
      },
      // Only permit them if their products are all different.
      ({
        numberA1,
        numberA2,
        numberA3,
        numberB1,
        numberB2,
        numberB3,
        numberC1,
        numberC2,
        numberC3
      }) =>
        arrayHasNoDuplicates([
          numberA1 * numberA2 * numberA3,
          numberB1 * numberB2 * numberB3,
          numberC1 * numberC2 * numberC3
        ])
    );
    return {
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA3,
        numberB1,
        numberB2,
        numberB3,
        numberC1,
        numberC2,
        numberC3
      },
      translate
    } = props;

    const answerOptions = [
      `${numberA1.toLocaleString()} ${MULT} ${(numberA2 * numberA3).toLocaleString()}`,
      `${(numberB1 * numberB2).toLocaleString()} ${MULT} ${numberB3.toLocaleString()}`,
      `${numberC1.toLocaleString()} ${MULT} ${(numberC2 * numberC3).toLocaleString()}`
    ];

    const statements = shuffle(
      [
        {
          statement: `${numberA1.toLocaleString()} ${MULT} ${numberA2.toLocaleString()} ${MULT} ${numberA3.toLocaleString()}`,
          answer: `${numberA1.toLocaleString()} ${MULT} ${(numberA2 * numberA3).toLocaleString()}`
        },
        {
          statement: `${numberB1.toLocaleString()} ${MULT} ${numberB2.toLocaleString()} ${MULT} ${numberB3.toLocaleString()}`,
          answer: `${(numberB1 * numberB2).toLocaleString()} ${MULT} ${numberB3.toLocaleString()}`
        },
        {
          statement: `${numberC1.toLocaleString()} ${MULT} ${numberC2.toLocaleString()} ${MULT} ${numberC3.toLocaleString()}`,
          answer: `${numberC1.toLocaleString()} ${MULT} ${(numberC2 * numberC3).toLocaleString()}`
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchEquivalentCalcs()}
        pdfTitle={translate.instructions.useTheCardsToMatchEquivalentCalcs()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'amg',
  description: 'amg',
  keywords: ['Multiply', 'Triple'],
  schema: z.object({
    numberB1: z.number().int().min(2).max(9),
    numberC1: z.number().int().min(2).max(9),
    numberC2: z.number().int().min(2).max(9),
    numberD1: z.number().int().min(2).max(9),
    numberD2: z.number().int().min(2).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const { b1, c1, c2, d1, d2 } = rejectionSample(
      () => {
        // Generate 5 random integers independently
        const [b1, c1, c2, d1, d2] = range(1, 6).map(() => randomIntegerInclusive(2, 9));
        return { b1, c1, c2, d1, d2 };
      },
      // Only permit them if their products are all different.
      ({ b1, c1, c2, d1, d2 }) => arrayHasNoDuplicates([b1 * 20, c1 * c2, d1 * d2])
    );

    return { numberB1: b1, numberC1: c1, numberC2: c2, numberD1: d1, numberD2: d2 };
  },
  Component: props => {
    const {
      question: { numberB1, numberC1, numberD1, numberC2, numberD2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[
          [numberC1 * numberC2, numberC1 * numberC2 * 2],
          [numberD2, numberD1 * numberD2],
          [numberB1, numberB1 * 20]
        ].map(sentenceAns => sentenceAns.map(ans => ans.toString()))}
        sentences={[
          `${(2).toLocaleString()} ${MULT} ${numberC1} ${MULT} ${numberC2} = ${(2).toLocaleString()} ${MULT} <ans/> = <ans/>`,
          `${numberD1} ${MULT} ${(1).toLocaleString()} ${MULT} ${numberD2} = ${numberD1} ${MULT} <ans/> = <ans/>`,
          `${(5).toLocaleString()} ${MULT} ${(4).toLocaleString()} ${MULT} ${numberB1} = ${(20).toLocaleString()} ${MULT} <ans/> = <ans/>`
        ]}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'amh',
  description: 'amh',
  keywords: ['Multiply', 'Triple'],
  schema: z.object({
    numberA1: z.number().int().min(1).max(5),
    numberA2: z.union([z.literal(2), z.literal(4), z.literal(6), z.literal(8)]),
    numberA3: z.union([z.literal(2), z.literal(3), z.literal(5), z.literal(10)]),
    numberB1: z.number().int().min(1).max(5),
    numberB2: z.union([z.literal(2), z.literal(4), z.literal(6), z.literal(8)]),
    numberB3: z.union([z.literal(2), z.literal(3), z.literal(5), z.literal(10)]),
    numberC1: z.number().int().min(1).max(5),
    numberC2: z.union([z.literal(2), z.literal(4), z.literal(6), z.literal(8)]),
    numberC3: z.union([z.literal(2), z.literal(3), z.literal(5), z.literal(10)])
  }),
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 5);
    const numberA2 = getRandomFromArray([2, 4, 6, 8] as const);
    const numberA3 = getRandomFromArray([2, 3, 5, 10] as const);

    const numberB1 = randomIntegerInclusive(1, 5);
    const numberB2 = getRandomFromArray([2, 4, 6, 8] as const);
    const numberB3 = getRandomFromArray([2, 3, 5, 10] as const);

    const numberC1 = randomIntegerInclusive(1, 5);
    const numberC2 = getRandomFromArray([2, 4, 6, 8] as const);
    const numberC3 = getRandomFromArray([2, 3, 5, 10] as const);

    return {
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA3,
        numberB1,
        numberB2,
        numberB3,
        numberC1,
        numberC2,
        numberC3
      },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={[
          [(numberA1 * numberA2 * numberA3).toString()],
          [(numberB1 * numberB2 * numberB3).toString()],
          [(numberC1 * numberC2 * numberC3).toString()]
        ]}
        sentences={[
          `${numberA1} ${MULT} ${numberA2} ${MULT} ${numberA3} = <ans/>`,
          `${numberB1} ${MULT} ${numberB2} ${MULT} ${numberB3} = <ans/>`,
          `${numberC1} ${MULT} ${numberC2} ${MULT} ${numberC3} = <ans/>`
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ami',
  description: 'ami',
  keywords: ['Multiply', 'Triple', 'Inequality'],
  schema: z.object({
    number1s: z.number().int().array().length(4),
    number2s: z.number().int().array().length(4),
    number4s: z.number().int().array().length(4)
  }),
  simpleGenerator: () => {
    const number1s = [
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9)
    ];

    const number2s = [
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9)
    ];

    const number4s = [
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9),
      randomIntegerInclusive(1, 9)
    ];

    return { number1s, number2s, number4s };
  },
  Component: props => {
    const {
      question: { number1s, number2s, number4s },
      translate
    } = props;

    const [number1A, number1B, number1C, number1D] = number1s;
    const [number2A, number2B, number2C, number2D] = number2s;
    const [number4A, number4B, number4C, number4D] = number4s;

    const number3B = number1B * number2B;
    const number5C = number2C + 1;
    const number5D = number2D + 1;
    const number6C = number4C + 1;
    const number6D = number4D + 1;
    const number7C = number5C * number6C;

    // Shuffle the sentences:
    const statements = useMemo(() => {
      const statement1 = {
        lhsComponent: `${number1A.toLocaleString()} ${MULT} ${number2A.toLocaleString()} ${MULT} ${number4A.toLocaleString()}`,
        rhsComponent: `${number4A.toLocaleString()} ${MULT} ${number1A.toLocaleString()} ${MULT} ${number2A.toLocaleString()}`,
        correctAnswer: '='
      };

      const statement2 = {
        lhsComponent: `${number1B.toLocaleString()} ${MULT} ${number2B.toLocaleString()} ${MULT} ${number4B.toLocaleString()}`,
        rhsComponent: `${number3B.toLocaleString()} ${MULT} ${number4B.toLocaleString()}`,
        correctAnswer: '='
      };

      const statement3 = {
        lhsComponent: `${number1C.toLocaleString()} ${MULT} ${number2C.toLocaleString()} ${MULT} ${number4C.toLocaleString()}`,
        rhsComponent: `${number7C.toLocaleString()} ${MULT} ${number1C.toLocaleString()}`,
        correctAnswer: '<'
      };

      const statement4 = {
        lhsComponent: `${number5D.toLocaleString()} ${MULT} ${number1D.toLocaleString()} ${MULT} ${number6D.toLocaleString()}`,
        rhsComponent: `${number1D.toLocaleString()} ${MULT} ${number2D.toLocaleString()} ${MULT} ${number4D.toLocaleString()}`,
        correctAnswer: '>'
      };

      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      number1A,
      number2A,
      number4A,
      number1B,
      number2B,
      number4B,
      number3B,
      number1C,
      number2C,
      number4C,
      number7C,
      number5D,
      number1D,
      number6D,
      number2D,
      number4D,
      props.question
    ]);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        pdfLayout="itemsHidden"
        actionPanelVariant="endWide"
        items={['<', '>', '=']}
        itemVariant="square"
        statements={statements}
        moveOrCopy="copy"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'amj',
  description: 'amj',
  keywords: ['Multiply', 'Triple'],
  schema: z.object({
    randomNumber: numberEnum([4, 8, 9]),
    digits: z.array(z.number().int()).length(3)
  }),
  simpleGenerator: () => {
    const randomNumber = getRandomFromArray([4, 8, 9] as const);

    const digits = getRandomSubArrayFromArray([1, 2, 3, 5, 7, randomNumber], 3);

    return { randomNumber, digits };
  },
  Component: props => {
    const {
      question: { randomNumber, digits },
      translate
    } = props;

    const [d1, d2, d3] = digits;

    const number3 = d1 * d2 * d3;

    const cards = sortNumberArray([1, 2, 3, 5, 7, randomNumber]);

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteMultiplication()}
        pdfTitle={translate.instructions.useCardsToCompleteMultiplication()}
        items={cards}
        sentence={`<ans/> ${MULT} <ans/> ${MULT} <ans/> = ${number3}`}
        testCorrect={ans => {
          const product = (ans as number[]).reduce((acc, val) => acc * val, 1);
          return product === number3;
        }}
        moveOrCopy="copy"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidMultiplicationUsingAvailCards()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'amk',
  description: 'amk',
  keywords: ['Multiply', 'Triple', 'Problem'],
  schema: z.object({
    tables: z.number().int().min(2).max(6),
    chairsPerTable: z.number().int().min(2).max(6),
    chairColor: z.enum(['blue', 'pink'])
  }),
  simpleGenerator: () => {
    const tables = randomIntegerInclusive(2, 6);

    const chairsPerTable = randomIntegerInclusive(2, 6);

    const chairColor = getRandomFromArray(['blue', 'pink'] as const);

    return { tables, chairsPerTable, chairColor };
  },
  Component: props => {
    const {
      question: { tables, chairsPerTable, chairColor },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.everyChairHasFourLegs(),
          translate.answerSentences.everyTableHasNumChairs(chairsPerTable.toLocaleString()),
          translate.answerSentences.thereAreNumberTables(tables.toLocaleString()),
          translate.answerSentences.thereAreAnsChairLegsInTotal()
        ]}
        title={translate.instructions.completeSentence()}
        testCorrect={[[], [], [], [(tables * chairsPerTable * 4).toString()]]}
        Content={
          chairColor === 'blue' ? <AssetSvg name="ChairBlue" /> : <AssetSvg name="ChairPink" />
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyThreeNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
