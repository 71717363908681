import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { getRandomName, nameSchema } from 'common/src/utils/names';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import AutoScaleText from 'common/src/components/typography/AutoScaleText';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from 'common/src/utils/collections';
import { expensiveObjectNames, expensiveObjectSchema } from 'common/src/utils/objects';
import { getExpensiveObjectImage } from 'common/src/utils/objectsImages';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ajg',
  description: 'ajg',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z
    .object({
      name: nameSchema,
      number1: z.number().int().min(10).max(188),
      number2: z.number().int().min(10).max(188),
      number3: z.number().int().min(21).max(199)
    })
    .refine(
      val => val.number1 + val.number2 < val.number3,
      'number1 + number2 must be less than number3.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(10, 188);

    const number2 = randomIntegerInclusive(10, 198 - number1);

    const number3 = randomIntegerInclusive(number1 + number2 + 1, 199);

    return { name, number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { name, number1, number2, number3 },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxManySentences
        pdfDirection="column"
        questionHeight={900}
        title={translate.instructions.characterIsReadingABook(name, number1, number2, number3)}
        testCorrect={[
          [(number1 + number2).toString()],
          [(number3 - (number1 + number2)).toString()]
        ]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        sentences={[
          translate.answerSentences.howManyPagesHasCharReadAns(name),
          translate.answerSentences.howManyPagesDoesCharHaveLeftAns(name)
        ]}
        textStyle={{ fontSize: displayMode !== 'digital' ? 50 : 28 }}
        titleTextStyle={{ fontSize: displayMode !== 'digital' ? 50 : 28 }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ajh',
  description: 'ajh',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(500),
      number2: z.number().int().min(10).max(499),
      number3: z.number().int().min(10).max(500)
    })
    .refine(val => val.number2 < val.number1, 'number2 must be less than number1.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 500);

    const number2 = randomIntegerInclusive(10, number1 - 1);

    const number3 = randomIntegerInclusive(10, 500);

    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.thereAreNumberPeopleOnATrain(number1, number2, number3)}
        testCorrect={[(number3 + (number1 - number2)).toString()]}
        sentence={translate.answerSentences.ansPeople()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aji',
  description: 'aji',
  keywords: ['Addition'],
  schema: z
    .object({
      number1: z.number().int().min(100).max(9800).multipleOf(100),
      number2: z.number().int().min(100).max(9800).multipleOf(100)
    })
    .refine(
      val => val.number1 + val.number2 <= 10000,
      'number1 + number2 must not be greater than 10,000'
    ),
  simpleGenerator: () => {
    // If number1 is a prime, it must be less than 30, otherwise number2 will never have two common factors.
    const number1 = randomIntegerInclusiveStep(100, 9800, 100);
    const number2 = randomIntegerInclusiveStep(100, 9800, 100, {
      constraint: x => x + number1 <= 10000
    });
    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;
    const number3 = number1 + number2;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.hereAreTwoNumberCards(number3.toLocaleString())}
        testCorrect={[Math.abs(number1 - number2).toString()]}
        sentence={translate.answerSentences.whatIsTheDifferenceBetweenTheTwoCards()}
        textStyle={{ fontSize: 28 }}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }
            ]}
          >
            <View style={[{ borderColor: 'black', borderWidth: 1, borderRadius: 12 }]}>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ width: dimens.width / 3, height: dimens.height / 2 }}
                group={0}
                maxLines={1}
              >
                {number2.toLocaleString()}
              </AutoScaleText>
            </View>
            <View style={[{ borderColor: 'black', borderWidth: 1, borderRadius: 12 }]}>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ width: dimens.width / 3, height: dimens.height / 2 }}
                group={0}
                maxLines={1}
              >
                ?
              </AutoScaleText>
            </View>
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ajj',
  description: 'ajj',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z
    .object({
      price1: z.number().int().min(50).max(450),
      price2: z.number().int().min(50).max(450),
      price3: z.number().int().min(50).max(450),
      object1: expensiveObjectSchema,
      object2: expensiveObjectSchema,
      object3: expensiveObjectSchema,
      name: nameSchema
    })
    .refine(
      val => val.price1 + val.price2 + val.price3 < 1000,
      'All prices should total to less than 1,000'
    )
    .refine(
      val => arrayHasNoDuplicates([val.object1, val.object2, val.object3]),
      'All objects must be different.'
    ),
  simpleGenerator: () => {
    const price1 = randomIntegerInclusive(50, 450);
    const price2 = randomIntegerInclusive(50, 450);
    const price3 = randomIntegerInclusive(50, 450, {
      constraint: x => x + price1 + price2 < 1000
    });

    const shuffledObjects = shuffle(expensiveObjectNames);

    const object1 = shuffledObjects[0];
    const object2 = shuffledObjects[1];
    const object3 = shuffledObjects[2];

    const name = getRandomName();

    return { price1, price2, price3, object1, object2, object3, name };
  },
  Component: props => {
    const {
      question: { price1, price2, price3, object1, object2, object3, name },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.howMuchMoneyDoesNameHaveLeft(name)}
        title={translate.instructions.characterHas1000PoundsToSpend(
          name,
          object1,
          object2,
          object3
        )}
        testCorrect={[(1000 - (price1 + price2 + price3)).toString()]}
        Content={({ dimens }) => {
          return (
            <View style={[dimens, { flexDirection: 'row' }]}>
              <View style={[{ alignItems: 'center', justifyContent: 'space-evenly' }]}>
                {getExpensiveObjectImage(object1, dimens.height / 1.75, dimens.width / 3.25)}
                <Text variant="WRN400">£{price1}</Text>
              </View>
              <View style={[{ alignItems: 'center', justifyContent: 'space-evenly' }]}>
                {getExpensiveObjectImage(object2, dimens.height / 1.75, dimens.width / 3.25)}
                <Text variant="WRN400">£{price2}</Text>
              </View>
              <View style={[{ alignItems: 'center', justifyContent: 'space-evenly' }]}>
                {getExpensiveObjectImage(object3, dimens.height / 1.75, dimens.width / 3.25)}
                <Text variant="WRN400">£{price3}</Text>
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ajk',
  description: 'ajk',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(10000),
      number2: z.number().int().min(1000).max(9999),
      number3: z.number().int().min(1000).max(10000)
    })
    .refine(val => val.number2 < val.number1, 'number2 must be less than number1.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 10000);

    const number2 = randomIntegerInclusive(1000, number1 - 1);

    const number3 = randomIntegerInclusive(1000, 10000);

    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.atTheStartOfTheDayNumParcels(number1, number2, number3)}
        testCorrect={[(number3 + (number1 - number2)).toString()]}
        sentence={translate.answerSentences.ansParcels()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ajl',
  description: 'ajl',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z
    .object({
      number1: z.number().int().min(1100).max(9900).multipleOf(100),
      number2: z.number().int().min(1002).max(9998)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2.')
    .refine(val => val.number1 % 1000 !== 0, 'number1 must not be a multiple of 1,000'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(1100, 9900, 100, {
      constraint: x => x % 1000 !== 0
    });

    const number2 = randomIntegerInclusiveStep(number1 + 2, 9998, 2);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = (number2 - number1) / 2;

    const number4 = number3 + number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.twoNumbersHaveDiffOfXAndTotalOfY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={answer =>
          arraysHaveSameContentsUnordered(
            [answer[0], answer[1]],
            [number3.toString(), number4.toString()]
          )
        }
        inputMaxCharacters={4}
        sentence={'<ans/> and <ans/>'}
        customMarkSchemeAnswer={{
          answersToDisplay: [number3.toLocaleString(), number4.toLocaleString()],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        questionHeight={400}
      />
    );
  },
  questionHeight: 400
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiStepAdditionAndSubtractionProblems',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
