import QF10SelectNumbers from 'common/src/components/question/questionFormats/QF10SelectNumbers';
import { ADD } from 'common/src/constants';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  sumNumberArray
} from 'common/src/utils/collections';
import {
  digitAtPowerIsNumber,
  numberOfZeroDigits,
  numberToBase10Object
} from 'common/src/utils/math';
import {
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import { PartWholeModel } from 'common/src/components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import { View } from 'react-native';
import { colors } from 'common/src/theme/colors';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { useMemo } from 'react';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const partitionMaker = (number: number, partitionLength: number) => {
  const { hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
  const standardPartition = [hundreds * 100, tens * 10, ones].filter(part => part !== 0);

  return rejectionSample(
    () => {
      let { hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);

      const borrowedTens = randomIntegerInclusive(0, tens);
      tens -= borrowedTens;
      ones += borrowedTens * 10;

      const borrowedHundreds = randomIntegerInclusive(0, hundreds);
      hundreds -= borrowedHundreds;
      tens += borrowedHundreds * 10;

      return [hundreds * 100, tens * 10, ones].filter(it => it !== 0);
    },
    partition =>
      partition.length === partitionLength && !arraysHaveSameContents(partition, standardPartition)
  );
};

const Question1 = newQuestionContent({
  uid: 'aaK',
  description: 'aaK',
  keywords: ['Place value', 'Flexible partition', 'Base 10', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(201).max(599),
      partition: z.number().int().array().min(3).max(3)
    })
    .refine(val => val.number % 10 !== 0, 'number must not be a multiple of 10')
    .refine(
      val => digitAtPowerIsNumber(val.number, 2, [2, 3, 4, 5]),
      'number must have 2-5 hundreds.'
    )
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  questionHeight: 1440,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(201, 599, {
      constraint: x => x % 10 !== 0 && digitAtPowerIsNumber(x, 2, [2, 3, 4, 5])
    });

    const partition = partitionMaker(number, 3);

    return { number, partition };
  },
  Component: props => {
    const {
      question: { number, partition },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1440}
        title={translate.instructions.useBase10RepToCompleteNumSentence()}
        testCorrect={answer =>
          arraysHaveSameContentsUnordered(
            partition.map(part => part.toString()),
            answer
          )
        }
        inputMaxCharacters={3}
        sentence={`${number.toLocaleString()} = <ans/> ${ADD} <ans/> ${ADD} <ans/>`}
        {...props}
        Content={({ dimens }) => {
          const height = dimens.height - 24; // 24 is for the padding
          const width = dimens.width / 4 - 24;
          const scales = partition.map(
            part =>
              BaseTenRepCalcGridsAndScale(width, height, numberToBase10Object(part), 'Cubes', 1)
                .scale
          );
          const scale = Math.min(...scales);

          return (
            <View
              style={{
                flex: 1,
                alignSelf: 'stretch',
                flexDirection: 'row',
                justifyContent: 'space-evenly'
              }}
            >
              {partition.map((part, index) => (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: colors.pacificBlue,
                    borderRadius: 12,
                    padding: 12,
                    height: dimens.height,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  key={index}
                >
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: 'Cubes',
                      numbers: numberToBase10Object(part),
                      arrangement: 'ltr'
                    }}
                    usableWidth={width}
                    usableHeight={height}
                    align={'center'}
                    numCols={1}
                    scale={scale}
                  />
                </View>
              ))}
            </View>
          );
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.validSentenceMatchingContent() }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aaL',
  description: 'aaL',
  keywords: ['Place value', 'Flexible partition', 'Base 10', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(211).max(999),
      partition: z.number().int().array().min(3).max(3),
      answerPosition: z.number().int().min(0).max(2)
    })
    .refine(val => numberOfZeroDigits(val.number) === 0, 'number must not have any zeroes.')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(211, 999, {
      constraint: x => numberOfZeroDigits(x) === 0
    });

    const partition = partitionMaker(number, 3);

    const answerPosition = randomIntegerInclusive(0, 2);

    return { number, partition, answerPosition };
  },
  Component: props => {
    const {
      question: { number, partition, answerPosition },
      translate
    } = props;

    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '<ans/>'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '<ans/>' : value.toLocaleString()
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[correctAnswer.toString()]}
        sentence={`${number.toLocaleString()} = ${modifiedPartition[0]} ${ADD} ${
          modifiedPartition[1]
        } ${ADD} ${modifiedPartition[2]}`}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Cubes', numbers: numberToBase10Object(number), arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aaM',
  description: 'aaM',
  keywords: ['Place value', 'Flexible partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(221).max(999),
      partition: z.number().int().array().min(3).max(3),
      answerPosition: z.number().int().min(0).max(2)
    })
    .refine(val => val.number % 10 !== 0, 'number must not be a multiple of 10')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(221, 999, {
      constraint: x => x % 10 !== 0
    });

    const partition = partitionMaker(number, 3);

    const answerPosition = randomIntegerInclusive(0, 2);

    return { number, partition, answerPosition };
  },
  Component: ({ question: { number, partition, answerPosition }, translate }) => {
    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '$ans'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '$ans' : value.toLocaleString()
    );

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={modifiedPartition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aaN',
  description: 'aaN',
  keywords: ['Place value', 'Flexible partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(221).max(999),
      partition: z.number().int().array().min(2).max(2),
      answerPosition: z.number().int().min(0).max(1)
    })
    .refine(val => !digitAtPowerIsNumber(val.number, 1, [0]), 'number must not have zero tens.')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  simpleGenerator: () => {
    // Some numbers do not permit a flexible partition of size 2 that's different to their standard partition.
    //We've found these all have 0 in the tens column. Since there are very few numbers with 0 in the tens column
    // which _do_ permit such a partition (such as 300), we simply rule them all out.
    const number = randomIntegerInclusive(221, 999, {
      constraint: x => !digitAtPowerIsNumber(x, 1, [0])
    });

    const partition = partitionMaker(number, 2);

    const answerPosition = randomIntegerInclusive(0, 1);

    return { number, partition, answerPosition };
  },
  Component: ({ question: { number, partition, answerPosition }, translate }) => {
    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '$ans'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '$ans' : value.toLocaleString()
    );

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={modifiedPartition}
            isInteractive
            dimens={dimens}
          />
        )}
        testComplete={userAnswer => userAnswer[0].length !== 0}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aaO',
  description: 'aaO',
  keywords: ['Place value', 'Flexible partition', '1,000', 'Thousand'],
  schema: z.object({
    digit1: z.number().int().min(2).max(9),
    digit2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const digit1 = randomIntegerInclusive(2, 9);
    const digit2 = randomIntegerInclusive(2, 9);

    return { digit1, digit2 };
  },
  Component: props => {
    const {
      question: { digit1, digit2 },
      translate,
      displayMode
    } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: translate.misc.numberOfOnes(digit1 * 10 + digit2),
        value: digit1 * 10 + digit2
      };

      const statement2 = {
        statement: `${translate.misc.numberOfTens(
          digit1 * 10
        )} ${ADD} ${translate.misc.numberOfOnes(digit2)}`,
        value: digit1 * 100 + digit2
      };

      const statement3 = {
        statement: translate.misc.numberOfTens(digit1 * 10 + digit2),
        value: (digit1 * 10 + digit2) * 10
      };

      return shuffle([statement1, statement2, statement3], {
        random: seededRandom(props.question)
      });
    }, [translate.misc, digit1, digit2, props.question]);

    const items = statements.map(({ value }) => value).sort((a, b) => a - b);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchStatementsToValues()}
        items={items}
        statementStyle={{ justifyContent: 'center' }}
        statements={statements.map(({ statement, value }) => ({
          correctAnswer: value,
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 320 : 400, textAlign: 'right' }}
            >
              {statement}
            </Text>
          )
        }))}
        questionHeight={750}
      />
    );
  },
  questionHeight: 750
});

const Question6 = newQuestionContent({
  uid: 'aaP',
  description: 'aaP',
  keywords: ['Place value', 'Flexible partition', 'Base 10', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z
        .number()
        .int()
        .min(211)
        .max(999)
        .refine(x => x % 10 !== 0, 'Must not be a multiple of 10'),
      partitions: z.number().int().array().min(2).array().length(4)
    })
    .refine(
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition))),
      'Duplicate partitions are not allowed'
    )
    .refine(
      ({ number, partitions }) => partitions.map(sumNumberArray).includes(number),
      'At least one partition must be correct'
    ),
  // Wrap everything in a rejection sample to avoid stuff like 303 which has only 2 new partitions:
  // - 200 + 100 + 3
  // - 100 + 3
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number = randomIntegerInclusive(211, 999, { constraint: x => x % 10 !== 0 });
        const { hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
        const standardPartition = [hundreds * 100, tens * 10, ones].filter(part => part !== 0);

        // The spreadsheet doesn't specify how the partitions should be constructed. So we have some creative freedom here.
        //
        // From analysing the worksheets, it appears that this works by:
        // - Start with the usual partition (hundreds, tens, ones)
        // - Borrow as many tens into the ones, or hundreds into the tens, as you like.
        // - If a column ends up emptied, drop it.
        // - There must be at least 2 parts in the end.
        // - Can't be unchanged
        const makePartition = (x: number): number[] => {
          return rejectionSample(
            () => {
              let { hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(x);

              const borrowedTens = randomIntegerInclusive(0, tens);
              tens -= borrowedTens;
              ones += borrowedTens * 10;

              const borrowedHundreds = randomIntegerInclusive(0, hundreds);
              hundreds -= borrowedHundreds;
              tens += borrowedHundreds * 10;

              return [hundreds * 100, tens * 10, ones].filter(it => it !== 0);
            },
            partition =>
              partition.length >= 2 && !arraysHaveSameContents(partition, standardPartition)
          );
        };

        const partitions = shuffle([
          makePartition(number - 110),
          makePartition(number),
          makePartition(number),
          makePartition(number)
        ]);

        return { number, partitions };
      },
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition)))
    ),
  Component: ({ question: { number, partitions }, translate }) => {
    const { hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
    const standardPartition = [hundreds * 100, tens * 10, ones].filter(part => part !== 0);

    const partitionToNumberSentence = (partition: number[]): string =>
      partition.map(part => part.toLocaleString()).join(` ${ADD} `);

    const partitionsInfo = partitions.map((partition, index) => ({
      value: index,
      text: partitionToNumberSentence(partition),
      correct: sumNumberArray(partition) === number
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumberSentencesAreEqualTo(
          partitionToNumberSentence(standardPartition)
        )}
        testCorrect={partitionsInfo
          .filter(partition => partition.correct)
          .map(partition => partition.value)}
        items={partitionsInfo.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FlexiblePartitioningOfNumbersTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
