import { ReactNode, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { colors } from '../../theme/colors';

type Props = {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  itemsStyle?: StyleProp<ViewStyle>;
};

export default function DragAndDropSectionPDF({ children, style, itemsStyle }: Props) {
  const styles = useStyles();

  return (
    <View style={[styles.container, style]}>
      <View style={(styles.itemsContainer, itemsStyle)}>{children}</View>
    </View>
  );
}

function useStyles() {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          padding: 16 - 2,
          backgroundColor: colors.white,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center'
        },

        itemsContainer: {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          gap: 32
        },

        text: {
          fontSize: 21.67,
          lineHeight: 32.5,
          alignSelf: 'center',
          marginBottom: 16
        }
      }),
    []
  );
}
