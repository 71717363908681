/**
 * Delete last character. Note that we cannot just use methods like slice naively, as they are not unicode-aware.
 *
 * More detail:
 *
 * Consider the string "-𝑦". This is made up of two characters, known as "code points" in Unicode. Unicode is
 * an encoding standard that specifies how each code point is encoded.
 *
 * Javascript uses the UTF-16 flavor of Unicode. This means that each code point is represented as a sequence
 * of 16-bit numbers, known as code units. Most code points are represented with a single code unit, e.g. "-"
 * is 0x002d. (Code units are often given in hexadecimal.) However, some code points need two code units, e.g.
 * "𝑦" is 0xd835 0xdc66. This is known as a surrogate pair.
 *
 * Javascript methods like .split, .slice and .substring work on code units, which means they can erroneously
 * split up surrogate pairs, resulting in "lone surrogates", which is invalid. However, the string's iterator
 * (used with `...myString`) is unicode-aware and splits the string into code points.
 *
 * Limitation: some emojis like 👨‍👦 are represented by a sequence of _code points_ (👨 0x200d 👦), known as a
 * grapheme cluster. These are not handled properly by this function, as only the last code point is deleted.
 *
 * For more information, see here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String#utf-16_characters_unicode_code_points_and_grapheme_clusters
 */
export function deleteLastCodePoint(text: string): string {
  return [...text].slice(0, -1).join('');
}

/**
 * Creates a simple number sentence with the given numbers, operator and ansIndex.
 * If you are passing in `type number` as the `parts`, the function will localise all numbers in the returned
 * sentence.
 *
 * Note: Does not do any validation on whether the number sentence is valid.
 *
 * Limitation: Only supports single operator sentences.
 *
 * @param parts - Number parts that make up the sentence
 * @param operator - Operator to be used
 * @param ansIndex - Index of the number part where the answer box should be
 * @param reversed - Flips the sentence to be in the form of: c = a + b
 * @returns {object} `{sentence: string, answer: number}`
 */
export function buildSimpleNumberSentence<T extends number | string>(
  parts: T[],
  operator: string,
  ansIndex: number,
  options: { reversed?: boolean } = {}
): { sentence: string; answer: T } {
  const { reversed } = options;

  const sentenceParts: (string | number)[] = [...parts];

  const answer = parts[ansIndex];
  sentenceParts[ansIndex] = '<ans/>';

  let sentence: string;

  if (reversed) {
    sentence = `${sentenceParts[sentenceParts.length - 1].toLocaleString()} = `;
    for (let i = 0; i < sentenceParts.length - 1; i++) {
      sentence += `${sentenceParts[i].toLocaleString()} ${operator} `;
    }
    sentence = sentence.slice(0, -` ${operator} `.length); // Remove the last operator
  } else {
    sentence = '';
    for (let i = 0; i < sentenceParts.length - 1; i++) {
      sentence += `${sentenceParts[i].toLocaleString()} ${operator} `;
    }
    sentence = sentence.slice(0, -` ${operator}`.length); // Remove the last operator
    sentence += `= ${sentenceParts[sentenceParts.length - 1].toLocaleString()}`;
  }

  return { sentence, answer };
}
