import Svg, { SvgProps, Path } from 'react-native-svg';
import { memo } from 'react';
const SvgComponent = (props: SvgProps) => (
  <Svg fill="#002E63" viewBox="0 0 32 32" {...props}>
    <Path
      fillRule="evenodd"
      d="M31.08.92c.507.507.787 1.173.787 1.88 0 .707-.28 1.387-.787 1.88L19.76 16l11.32 11.32c.507.507.787 1.173.787 1.88 0 .707-.28 1.387-.787 1.88a2.644 2.644 0 0 1-3.76 0L16 19.76 4.68 31.08a2.644 2.644 0 0 1-1.88.787c-.707 0-1.387-.28-1.88-.787a2.644 2.644 0 0 1-.787-1.88c0-.707.28-1.387.787-1.88L12.24 16 .92 4.68A2.621 2.621 0 0 1 .133 2.8c0-.707.28-1.387.787-1.88A2.644 2.644 0 0 1 2.8.133c.707 0 1.387.28 1.88.787L16 12.24 27.307.92a2.644 2.644 0 0 1 1.88-.787c.706 0 1.386.28 1.88.787h.013Z"
      clipRule="evenodd"
    />
  </Svg>
);
const Memo = memo(SvgComponent);
export default Memo;
