import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF64CreatePictogram from '../../../../components/question/questionFormats/QF64CreatePictogram';
import { fruitsSchema, getRandomUniqueFruits, FruitNameLC } from '../../../../utils/fruits';
import { randomIntegerInclusive, randomUniqueIntegersInclusive } from '../../../../utils/random';
import { numberToTally } from '../../../../components/question/representations/Tallies';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bll',
  description: 'bll',
  keywords: ['Pictogram'],
  questionHeight: 850,
  schema: z.object({
    fruits: z.array(fruitsSchema).length(3),
    numbers: z.array(z.number().int().min(1).max(6)),
    missingRow: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const missingRow = randomIntegerInclusive(0, 2);

    const numbers = countRange(3).map(() => randomIntegerInclusive(1, 6));

    const fruits = getRandomUniqueFruits(3);

    return { numbers, fruits, missingRow };
  },
  Component: ({ question, translate }) => {
    const { numbers, fruits, missingRow } = question;

    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      numbers[idx].toLocaleString()
    ]);

    const givenCols = [...numbers];
    givenCols[missingRow] = 0;

    return (
      <QF64CreatePictogram
        title={translate.ks1Instructions.dragCirclesToCompletePictogram()}
        pdfTitle={translate.ks1PDFInstructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(1)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'blm',
  description: 'blm',
  keywords: ['Pictogram'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(5),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(1).max(6),
    fruit2: z.number().int().min(0).max(6),
    fruit3: z.number().int().min(0).max(6),
    missingRows: z.array(z.number().int().min(0).max(2)).length(2)
  }),
  simpleGenerator: () => {
    const key = randomIntegerInclusive(2, 5);

    const fruit1 = randomIntegerInclusive(1, 6);
    const fruit2 = randomIntegerInclusive(0, 6);
    const fruit3 = randomIntegerInclusive(0, 6, {
      constraint: x => !(fruit2 === 0 && x === 0)
    });

    const fruits = getRandomUniqueFruits(3);

    const missingRows = randomUniqueIntegersInclusive(0, 2, 2, {
      constraint: x =>
        !(fruit1 === 0 && x === 0) && !(fruit2 === 0 && x === 1) && !(fruit3 === 0 && x === 2)
    });

    return { key, fruit1, fruit2, fruit3, fruits, missingRows };
  },

  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits, missingRows } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [...numbers];

    missingRows.forEach(row => {
      givenCols[row] = 0;
    });

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bln',
  description: 'bln',
  keywords: ['Pictogram', 'Tally'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(5),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(1).max(5),
    fruit2: z.number().int().min(1).max(5),
    fruit3: z.number().int().min(1).max(5),
    missingRows: z.array(z.number().int().min(0).max(2))
  }),
  simpleGenerator: () => {
    const key = randomIntegerInclusive(2, 5);

    const fruit1 = randomIntegerInclusive(1, 5);
    const fruit2 = randomIntegerInclusive(1, 5);
    const fruit3 = randomIntegerInclusive(1, 5, {
      constraint: x => !(fruit2 === 0 && x === 0)
    });

    const fruits = getRandomUniqueFruits(3);

    const missingRowsCount = randomIntegerInclusive(1, 3);

    let missingRows;

    if (missingRowsCount === 3) {
      missingRows = [0, 1, 2]; // All rows are missing
    } else {
      missingRows = randomUniqueIntegersInclusive(0, 2, missingRowsCount);
    }

    return { key, fruit1, fruit2, fruit3, fruits, missingRows };
  },

  Component: ({ question, translate }) => {
    const { fruit1, fruit2, fruit3, fruits, missingRows } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [translate.fruits[x](), numberToTally(numbers[idx])]);

    const givenCols = [...numbers];

    missingRows.forEach(row => {
      givenCols[row] = 0;
    });

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Tally(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        columnFlexValues={[1, 1, 3]}
        keyValue={translate.misc.numberOfFruits(1)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawPictograms1To1',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
