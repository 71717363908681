import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Clock, { ClockWithState } from '../../../../components/question/representations/Clock';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { Dimens } from 'common/src/theme/scaling';
import { useMemo } from 'react';
import { numberEnum } from '../../../../utils/zod';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { QF51CompleteTheDigitalClock } from '../../../../components/question/questionFormats/QF51CompleteTheDigitalClock';
import { addDurationTo24hTime } from '../../../../utils/time';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axS',
  description: 'axS',
  keywords: ['Time', 'Analogue', 'Digital', 'Morning', 'Afternoon'],
  schema: z.object({
    morningOrAfternoon: z.enum(['morning', 'afternoon']),
    analogueHoursA: z.number().int().min(6).max(16),
    analogueMinutesA: numberEnum([0, 30]),
    analogueHoursB: z.number().int().min(6).max(16),
    analogueMinutesB: numberEnum([0, 30]),
    digitalHoursA: z.number().int().min(6).max(16),
    digitalMinutesA: numberEnum([0, 30]),
    digitalHoursB: z.number().int().min(6).max(16),
    digitalMinutesB: numberEnum([0, 30])
  }),
  simpleGenerator: () => {
    const morningOrAfternoon = getRandomFromArray(['morning', 'afternoon'] as const);

    const { analogueHoursA, analogueHoursB, digitalHoursA, digitalHoursB } = rejectionSample(
      () => {
        // Ensure both analogue clocks aren't identical:
        const [analogueHoursA, analogueHoursB] = randomUniqueIntegersInclusive(6, 16, 2);

        // Ensure both digital clocks aren't identical:
        const [digitalHoursA, digitalHoursB] = randomUniqueIntegersInclusive(6, 16, 2);
        return { analogueHoursA, analogueHoursB, digitalHoursA, digitalHoursB };
      },
      // Only permit them if at least one of the hours generated is a correct answer.
      ({ analogueHoursA, analogueHoursB, digitalHoursA, digitalHoursB }) =>
        [analogueHoursA, analogueHoursB, digitalHoursA, digitalHoursB].filter(hour =>
          morningOrAfternoon === 'morning' ? hour < 12 : hour >= 12
        ).length > 0
    );

    const analogueMinutesA = getRandomFromArray([0, 30] as const);

    const analogueMinutesB = getRandomFromArray([0, 30] as const);

    const digitalMinutesA = getRandomFromArray([0, 30] as const);

    const digitalMinutesB = getRandomFromArray([0, 30] as const);

    return {
      analogueHoursA,
      analogueMinutesA,
      analogueHoursB,
      analogueMinutesB,
      digitalHoursA,
      digitalMinutesA,
      digitalHoursB,
      digitalMinutesB,
      morningOrAfternoon
    };
  },
  Component: props => {
    const {
      question: {
        analogueHoursA,
        analogueMinutesA,
        analogueHoursB,
        analogueMinutesB,
        digitalHoursA,
        digitalMinutesA,
        digitalHoursB,
        digitalMinutesB,
        morningOrAfternoon
      },
      translate
    } = props;

    const statements = useMemo(() => {
      return [
        {
          component: (dimens: Dimens) => (
            <DigitalClock
              hours={digitalHoursA <= 12 ? digitalHoursA : digitalHoursA - 12}
              minutes={digitalMinutesA}
              dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.9 }}
              amOrPm={digitalHoursA < 12 ? 'am' : 'pm'}
            />
          ),
          isCorrect: morningOrAfternoon === 'morning' ? digitalHoursA < 12 : digitalHoursA >= 12,
          value: 1
        },
        {
          component: (dimens: Dimens) => (
            <DigitalClock
              hours={digitalHoursB <= 12 ? digitalHoursB : digitalHoursB - 12}
              minutes={digitalMinutesB}
              dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.9 }}
              amOrPm={digitalHoursB < 12 ? 'am' : 'pm'}
            />
          ),
          isCorrect: morningOrAfternoon === 'morning' ? digitalHoursB < 12 : digitalHoursB >= 12,
          value: 2
        },
        {
          component: (dimens: Dimens) => (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Clock
                width={dimens.height * 0.8}
                time={{ hours: analogueHoursA, minutes: analogueMinutesA }}
                interactive={false}
              />
              <Text variant="WRN700">
                {analogueHoursA < 12 ? translate.time.am() : translate.time.pm()}
              </Text>
            </View>
          ),
          isCorrect: morningOrAfternoon === 'morning' ? analogueHoursA < 12 : analogueHoursA >= 12,
          value: 3
        },
        {
          component: (dimens: Dimens) => (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Clock
                width={dimens.height * 0.8}
                time={{ hours: analogueHoursB, minutes: analogueMinutesB }}
                interactive={false}
              />
              <Text variant="WRN700">
                {analogueHoursB < 12 ? translate.time.am() : translate.time.pm()}
              </Text>
            </View>
          ),
          isCorrect: morningOrAfternoon === 'morning' ? analogueHoursB < 12 : analogueHoursB >= 12,
          value: 4
        }
      ];
    }, [
      analogueHoursA,
      analogueHoursB,
      analogueMinutesA,
      analogueMinutesB,
      digitalHoursA,
      digitalHoursB,
      digitalMinutesA,
      digitalMinutesB,
      morningOrAfternoon,
      translate.time
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={
          morningOrAfternoon === 'morning'
            ? translate.instructions.selectTheTimesOnTheClockThatAreInTheMorning()
            : translate.instructions.selectTheTimesOnTheClockThatAreInTheAfternoon()
        }
        pdfTitle={
          morningOrAfternoon === 'morning'
            ? translate.instructions.circleTheTimesOnTheClockThatAreInTheMorning()
            : translate.instructions.circleTheTimesOnTheClockThatAreInTheAfternoon()
        }
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shuffle(statements, {
            random: seededRandom(props.question)
          }).map(({ component, value }) => ({
            value,
            component: component(dimens)
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'axT',
  description: 'axT',
  keywords: ['Time', 'Analogue', 'Digital', 'Convert'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: z.number().int().min(0).max(55).multipleOf(5),
    incorrectHoursA: z.number().int().min(1).max(12),
    incorrectMinutesA: z.number().int().min(0).max(55).multipleOf(5),
    incorrectHoursB: z.number().int().min(1).max(12),
    incorrectMinutesB: z.number().int().min(0).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);

    const minutes = randomIntegerInclusiveStep(0, 55, 5);

    const { incorrectHoursA, incorrectMinutesA } = rejectionSample(
      () => {
        const incorrectHoursA = randomIntegerInclusive(1, 12);

        const incorrectMinutesA = randomIntegerInclusiveStep(0, 55, 5);

        return { incorrectHoursA, incorrectMinutesA };
      },
      // Only permit them iif the time generated is not identical to the correct time.
      ({ incorrectHoursA, incorrectMinutesA }) =>
        `${hours}:${minutes}` !== `${incorrectHoursA}:${incorrectMinutesA}`
    );

    const { incorrectHoursB, incorrectMinutesB } = rejectionSample(
      () => {
        const incorrectHoursB = randomIntegerInclusive(1, 12);

        const incorrectMinutesB = randomIntegerInclusiveStep(0, 55, 5);

        return { incorrectHoursB, incorrectMinutesB };
      },
      // Only permit them iif the time generated is not identical to the correct time.
      ({ incorrectHoursB, incorrectMinutesB }) =>
        `${hours}:${minutes}` !== `${incorrectHoursB}:${incorrectMinutesB}` &&
        `${incorrectHoursA}:${incorrectMinutesA}` !== `${incorrectHoursB}:${incorrectMinutesB}`
    );

    return {
      hours,
      minutes,
      incorrectHoursA,
      incorrectMinutesA,
      incorrectHoursB,
      incorrectMinutesB
    };
  },
  Component: props => {
    const {
      question: {
        hours,
        minutes,
        incorrectHoursA,
        incorrectMinutesA,
        incorrectHoursB,
        incorrectMinutesB
      },
      translate
    } = props;

    const statements = [
      {
        hours: hours,
        minutes: minutes,
        value: `${hours}:${minutes}`,
        isCorrect: true
      },
      {
        hours: incorrectHoursA,
        minutes: incorrectMinutesA,
        value: `${incorrectHoursA}:${incorrectMinutesA}`,
        isCorrect: false
      },
      {
        hours: incorrectHoursB,
        minutes: incorrectMinutesB,
        value: `${incorrectHoursB}:${incorrectMinutesB}`,
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectDigitalTimeShownOnAnalogueClock()}
        testCorrect={shuffledStatements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            // Clock should only accept hours 0 to 11, so adjust it here if hours is 12:
            time={{ hours: hours === 12 ? 0 : hours, minutes: minutes }}
            width={dimens.height - 16}
            interactive={false}
          />
        )}
        renderItems={shuffledStatements.map(({ hours, minutes }) => ({
          value: `${hours}:${minutes}`,
          component: (
            <MeasureView>
              {dimens => (
                <DigitalClock
                  hours={hours}
                  minutes={minutes}
                  dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                />
              )}
            </MeasureView>
          )
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'axU',
  description: 'axU',
  keywords: ['Time', 'Analogue', 'Digital', 'Convert'],
  schema: z.object({
    amOrPm: z.enum(['am', 'pm']),
    hours: z.number().int().min(1).max(12),
    minutes: z.number().int().min(5).max(55).multipleOf(5)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    const hours = randomIntegerInclusive(1, 12);

    const minutes = randomIntegerInclusiveStep(5, 55, 5);

    return { amOrPm, hours, minutes };
  },
  Component: ({ question: { amOrPm, hours, minutes }, translate, displayMode }) => {
    return (
      <QF3InteractiveContent
        title={translate.instructions.convertAnalogueIntoDigitalTime()}
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? [hours.toString(), minutes.toString().padStart(2, '0')]
            : ['', '']
        }
        testComplete={answer => answer.every(it => it.length !== 0)}
        testCorrect={answer =>
          answer[0] === hours.toString() && answer[1] === minutes.toString().padStart(2, '0')
        }
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'space-between' }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
              <Clock
                width={dimens.height * 0.45}
                time={{ hours: hours === 12 ? 0 : hours, minutes }}
                interactive={false}
              />
              <Text variant="WRN700" style={{ marginLeft: 16 }}>
                {amOrPm === 'am' ? translate.time.am() : translate.time.pm()}
              </Text>
            </View>
            <DigitalClock
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              amOrPm={amOrPm}
              dimens={{ height: dimens.height * 0.5, width: dimens.width }}
              hours={'<ans/>'}
              minutes={'<ans/>'}
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'axV',
  description: 'axV',
  keywords: ['Time', 'Analogue', 'Digital', 'Convert'],
  schema: z.object({
    amOrPm: z.enum(['am', 'pm']),
    hours: z.number().int().min(1).max(12),
    minutes: z.number().int().min(5).max(55)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    const hours = randomIntegerInclusive(1, 12);

    const minutes = randomIntegerInclusiveStep(5, 55, 5);

    return { amOrPm, hours, minutes };
  },
  Component: ({ question: { amOrPm, hours, minutes }, translate, displayMode }) => {
    const interactive = displayMode === 'digital' ? true : false;
    const showHands = displayMode === 'pdf' ? false : true;
    const initialState = displayMode === 'markscheme' ? { hours, minutes } : undefined;

    const hoursAnswer = hours === 12 ? 0 : hours;

    return (
      <QF3Content
        title={translate.instructions.moveTheHandsOfClockToShowDigitalTime()}
        pdfTitle={translate.instructions.drawTheHandsOnClockToShowDigitalTime()}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <DigitalClock
              hours={hours}
              minutes={minutes}
              dimens={{
                height: dimens.height,
                width: Math.min(dimens.width * 0.4, dimens.height)
              }}
              amOrPm={amOrPm}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <ClockWithState
                id="clock"
                width={Math.min(dimens.width * 0.45, dimens.height)}
                testCorrect={answer => answer.hours === hoursAnswer && answer.minutes === minutes}
                interactive={interactive}
                showHands={showHands}
                defaultState={initialState}
              />
              <Text variant="WRN700" style={{ marginLeft: 16 }}>
                {amOrPm === 'am' ? translate.time.am() : translate.time.pm()}
              </Text>
            </View>
          </View>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axW',
  description: 'axW',
  keywords: ['Time', 'Analogue', 'Digital', 'Convert'],
  schema: z.object({
    name: nameSchema,
    hours: z.number().int().min(1).max(7),
    minutes: z.number().int().min(16).max(29),
    pastOrTo: z.enum(['past', 'to']),
    durationHours: z.number().int().min(2).max(3),
    durationMinutes: z.number().int().min(45).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const hours = randomIntegerInclusive(1, 7);

    const minutes = randomIntegerInclusive(16, 29, {
      constraint: x => x % 5 !== 0
    });

    const pastOrTo = getRandomFromArray(['past', 'to'] as const);

    const durationHours = randomIntegerInclusive(2, 3);

    const durationMinutes = randomIntegerInclusiveStep(45, 55, 5);

    return { name, hours, minutes, pastOrTo, durationHours, durationMinutes };
  },

  Component: props => {
    const {
      question: { name, hours, minutes, pastOrTo, durationHours, durationMinutes },
      translate
    } = props;

    const currentHours = pastOrTo === 'past' ? hours : hours - 1;
    const currentMinutes = pastOrTo === 'past' ? minutes : 60 - minutes;

    const [correctHours, correctMinutes] = addDurationTo24hTime(
      currentHours,
      currentMinutes,
      durationHours * 60 + durationMinutes
    );

    return (
      <QF51CompleteTheDigitalClock
        title={
          pastOrTo === 'past'
            ? translate.instructions.characterLeavesHomeAtNumMinutesPastNum(
                name,
                minutes,
                hours,
                durationHours,
                durationMinutes
              )
            : translate.instructions.characterLeavesHomeAtNumMinutesToNum(
                name,
                minutes,
                hours,
                durationHours,
                durationMinutes
              )
        }
        testCorrect={[correctHours.toString(), correctMinutes.toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm="pm"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'axX',
  description: 'axX',
  keywords: ['Time', 'Analogue', 'Digital', 'Convert'],
  schema: z.object({
    name: nameSchema,
    startHour: z.number().int().min(5).max(7),
    startMinute: z.number().int().min(15).max(45).multipleOf(15),
    frequency: z.number().int().min(16).max(24),
    arrivalHour: z.number().int().min(7).max(9),
    arrivalMinute: numberEnum([5, 10, 20])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const startHour = randomIntegerInclusive(5, 7);

    const startMinute = randomIntegerInclusiveStep(15, 45, 15);

    const frequency = randomIntegerInclusive(16, 24, {
      constraint: x => x !== 20
    });

    const arrivalHour = randomIntegerInclusive(Math.max(startHour + 1, 7), 9);

    const arrivalMinute = getRandomFromArray([5, 10, 20] as const);

    return { name, startHour, startMinute, frequency, arrivalHour, arrivalMinute };
  },
  Component: ({
    question: { name, startHour, startMinute, frequency, arrivalHour, arrivalMinute },
    translate
  }) => {
    // Following consts all used to formulate the correct answer:
    const number6 = arrivalHour + arrivalMinute / 60 - (startHour + startMinute / 60);

    const number7 = Math.ceil(number6 / (frequency / 60));

    const number8 = (frequency / 60) * number7;

    const number9 = number8 + (startHour + startMinute / 60);

    const number10 = number9 - (arrivalHour + arrivalMinute / 60);

    const answer = Math.round(number10 * 60);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterIsGettingTheBusIntoTown(
          name,
          startHour,
          startMinute,
          frequency,
          arrivalMinute,
          arrivalHour
        )}
        sentence={`${translate.answerSentences.ansMinutes()}`}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertBetweenAnalogueAndDigitalTimes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
