/**
 * Debounce a function.
 * Ensures that the function is only run with the most recent args, if the args haven't changed for a minimum wait
 * time.
 * Note that the return value of the input function is not retained.
 */
export default function debounce<F extends (...args: unknown[]) => unknown>(
  callback: F,
  wait: number = 300
): (...args: Parameters<F>) => void {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), wait);
  };
}
