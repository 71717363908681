import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TextStructure from '../../../../components/molecules/TextStructure';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import QF28aFunctionMachinesDraggable from '../../../../components/question/questionFormats/QF28aFunctionMachinesDraggable';
import { parseToSUB } from '../../../../utils/parse';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { arraysHaveSameContentsUnordered, filledArray, range } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import { numberEnum } from '../../../../utils/zod';
import { getRegularShapeWithAllSidesMarkedSvgName } from '../../../../utils/shapeImages/shapes';
import { LabelledShapesWithMarkedSides } from '../../../../components/question/representations/LabelledShapesWithMarkedSides';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTW',
  description: 'aTW',
  keywords: ['Expression', 'Algebra'],
  schema: z.object({
    cubes: z.number().int().min(2).max(7),
    ones: z.number().int().min(1).max(6),
    algebraicLetter: algebraicSymbolSchema,
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  simpleGenerator: () => {
    const cubes = randomIntegerInclusive(2, 7);
    const ones = randomIntegerInclusive(1, 6, { constraint: x => x + cubes <= 10 });
    const algebraicLetter = getAlgebraicSymbol();
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return { cubes, ones, algebraicLetter, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { cubes, ones, algebraicLetter, cubeColour, onesCubeColur },
      translate
    } = props;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`one_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));

    const arrayOfObjects = [...arrayOfCubes, ...arrayOfOnes];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${ADD} <ans/>`}
        title={translate.instructions.workOutAnAlgebraicExpressionToDescribeTheSetOfCubes()}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            `${cubes.toLocaleString()}${algebraicLetter}`,
            `${ones.toLocaleString()}`
          ]
        }}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [
            `${cubes.toString()}${algebraicLetter}`,
            `${ones.toString()}`
          ])
        }
        sentenceStyle={{ alignSelf: 'center' }}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens
            }}
          >
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'center'
              }}
            >
              <AssetSvg name={cubesSvgName} width={70} />
              <Text style={{ fontSize: 32, paddingRight: 30 }}>{` = ${algebraicLetter}`}</Text>
              <AssetSvg name={onesSvgName} width={70} />
              <Text style={{ fontSize: 32 }}>{` = ${(1).toLocaleString()}`}</Text>
            </ContentBox>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
                top: 100
              }}
            >
              {arrayOfObjects}
            </View>
          </View>
        )}
        extraSymbol={algebraicLetter}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aTX',
  description: 'aTX',
  keywords: ['Expression', 'Algebra', 'Simplify'],
  schema: z.object({
    cubesA: z.number().int().min(1).max(3),
    cubesB: z.number().int().min(1).max(3),
    onesA: z.number().int().min(1).max(4),
    cubesAll: z.number().int().min(4).max(6),
    cubesD: z.number().int().min(1).max(4),
    onesB: z.number().int().min(1).max(4),
    algebraicLetter: algebraicSymbolSchema,
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cubesA = randomIntegerInclusive(1, 3);
    const cubesB = randomIntegerInclusive(1, 3);
    const onesA = randomIntegerInclusive(1, 4, { constraint: x => x + cubesA + cubesB <= 10 });

    const cubesAll = randomIntegerInclusive(4, 6);
    const cubesD = randomIntegerInclusive(1, cubesAll - 2);

    const onesB = randomIntegerInclusive(1, 4, { constraint: x => x + cubesAll <= 10 });

    const algebraicLetter = getAlgebraicSymbol();

    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return {
      cubesA,
      cubesB,
      cubesAll,
      cubesD,
      onesA,
      onesB,
      algebraicLetter,
      cubeColour,
      onesCubeColur
    };
  },
  Component: props => {
    const {
      question: {
        cubesA,
        cubesB,
        cubesAll,
        cubesD,
        onesA,
        onesB,
        algebraicLetter,
        cubeColour,
        onesCubeColur
      },
      translate,
      displayMode
    } = props;

    const cubesC = cubesAll - cubesD;

    const answers = [
      [`${cubesA + cubesB}${algebraicLetter}`, onesA.toString()],
      [`${cubesC}${algebraicLetter}`, onesB.toString()]
    ];

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnesA = range(1, onesA).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`oneA_${i}`} />
    ));
    const arrayOfCubesA = range(1, cubesA).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cubeA_${i}`} />
    ));
    const arrayOfCubesB = range(1, cubesB).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cubeB_${i}`} />
    ));
    const arrayOfOnesB = range(1, onesB).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`oneB_${i}`} />
    ));
    const arrayOfCubesC = range(1, cubesC).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cubeC_${i}`} />
    ));
    const arrayOfCubesD = range(1, cubesD).map(i => (
      <View key={`cubeD_${i}`} style={{ position: 'relative', width: 70, height: 70 }}>
        <AssetSvg name={cubesSvgName} width={70} />
        <Text
          style={{
            position: 'absolute',
            right: 13,
            color: 'black',
            fontSize: 75,
            top: -12
          }}
        >
          X
        </Text>
      </View>
    ));

    const arrayOfObjectsA = [...arrayOfCubesA, ...arrayOfOnesA, ...arrayOfCubesB];
    const arrayOfObjectsB = [...arrayOfCubesC, ...arrayOfOnesB, ...arrayOfCubesD];

    return (
      <QF3Content
        title={translate.instructions.useCubesSimplifyExpressions()}
        inputType="numpad"
        questionHeight={1000}
        extraSymbol={algebraicLetter}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <View style={{ top: 50 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                {arrayOfObjectsA}
              </View>
              <CompleteTheSentenceWithState
                id="sentenceA"
                style={{ alignSelf: 'center', top: 20 }}
                testCorrect={userAnswer => arraysHaveSameContentsUnordered(userAnswer, answers[0])}
                defaultState={
                  displayMode === 'markscheme' ? [answers[0][0], answers[0][1]] : undefined
                }
                sentence={`<ans/> ${ADD} <ans/>`}
              />
            </View>
            <View>
              <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 10
                  }}
                >
                  {arrayOfObjectsB}
                </View>
              </View>
              <CompleteTheSentenceWithState
                id="sentenceB"
                style={{ alignSelf: 'center', top: 20 }}
                testCorrect={userAnswer => arraysHaveSameContentsUnordered(userAnswer, answers[1])}
                defaultState={
                  displayMode === 'markscheme' ? [answers[1][0], answers[1][1]] : undefined
                }
                sentence={`<ans/> ${ADD} <ans/>`}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aTX2',
  description: 'aTX',
  keywords: ['Expression', 'Algebra'],
  schema: z
    .object({
      cubes: z.number().int().min(2).max(7),
      ones: z.number().int().min(1).max(6),
      algebraicLetter: algebraicSymbolSchema,
      cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
      onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
    })
    .refine(val => val.cubes + val.ones <= 8),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cubes = randomIntegerInclusive(2, 7);
    const ones = randomIntegerInclusive(1, Math.min(6, 8 - cubes));

    const algebraicLetter = getAlgebraicSymbol();

    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return {
      cubes,
      ones,
      algebraicLetter,
      cubeColour,
      onesCubeColur
    };
  },
  Component: props => {
    const {
      question: { cubes, ones, algebraicLetter, cubeColour, onesCubeColur },
      translate
    } = props;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`oneA_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cubeA_${i}`} />
    ));

    const arrayOfObjects = shuffle([...arrayOfCubes, ...arrayOfOnes], {
      random: seededRandom(props.question)
    });

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${ADD} <ans/>`}
        title={translate.instructions.whatAlgebraicExpressionDescribesSetOfCubes()}
        inputMaxCharacters={2}
        extraSymbol={algebraicLetter}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            `${cubes.toLocaleString()}${algebraicLetter}`,
            `${ones.toLocaleString()}`
          ]
        }}
        pdfDirection="column"
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [
            `${cubes.toString()}${algebraicLetter}`,
            `${ones.toString()}`
          ])
        }
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens
            }}
          >
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'center'
              }}
            >
              <AssetSvg name={cubesSvgName} width={70} />
              <Text style={{ fontSize: 32, paddingRight: 30 }}>{` = ${algebraicLetter}`}</Text>
              <AssetSvg name={onesSvgName} width={70} />
              <Text style={{ fontSize: 32 }}>{` = ${(1).toLocaleString()}`}</Text>
            </ContentBox>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
                top: 100
              }}
            >
              {arrayOfObjects}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTY',
  description: 'aTY',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    operation: z.enum([ADD, SUB, MULT]),
    number1: z.number().int().min(2).max(5),
    number2A: z.number().int().min(1).max(10),
    number2B: z.number().int().min(1).max(10),
    algebraicLetter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const operation = getRandomFromArray([ADD, SUB, MULT] as const);
    const number1 = randomIntegerInclusive(2, 5);
    const [number2A, number2B] =
      operation === '−'
        ? randomUniqueIntegersInclusive(6, 10, 2)
        : randomUniqueIntegersInclusive(1, 5, 2);

    const algebraicLetter = getAlgebraicSymbol();

    return { operation, number1, number2A, number2B, algebraicLetter };
  },
  Component: props => {
    const {
      question: { operation, number1, number2A, number2B, algebraicLetter },
      translate,
      displayMode
    } = props;

    const [number3A, number3B] = (() => {
      switch (operation) {
        case ADD:
          return [number1 + number2A, number1 + number2B];
        case SUB:
          return [number2A - number1, number2B - number1];
        case MULT:
          return [number2A * number1, number2B * number1];
      }
    })();

    const items = [
      `${algebraicLetter} ${ADD} ${number1.toLocaleString()}`,
      `${algebraicLetter} ${SUB} ${number1.toLocaleString()}`,
      `${number1.toLocaleString()}${algebraicLetter}`,
      `${number1}${algebraicLetter} ${ADD} ${number1.toLocaleString()}`
    ];

    const correctAnswer =
      operation === MULT
        ? `${number1}${algebraicLetter}`
        : `${algebraicLetter} ${operation} ${number1}`;

    return (
      <QF28aFunctionMachinesDraggable
        title={translate.instructions.dragCardsCompleteFunctionMachine()}
        pdfTitle={translate.instructions.useCardsToCompleteFunctionMachine()}
        sharedColumns={[1]}
        itemsMaxLines={1}
        rowsOfBoxes={[
          [number2A.toLocaleString(), '', number3A.toLocaleString()],
          [number2B.toLocaleString(), '', number3B.toLocaleString()],
          [algebraicLetter, `${operation}${parseToSUB(number1.toLocaleString())}`, `<ans/>`]
        ]}
        testCorrect={[correctAnswer]}
        items={items.map(item => ({
          component: (
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 24 : 40 }}>
              {item}
            </Text>
          ),
          value: item
        }))}
        questionHeight={1400}
      />
    );
  },
  questionHeight: 1400
});

const Question4 = newQuestionContent({
  uid: 'aTZ',
  description: 'aTZ',
  keywords: ['Expression'],
  schema: z.object({
    algebraicLetter: algebraicSymbolSchema,
    number: z.number().int().min(2).max(9),
    answerIndexes: z.array(numberEnum([0, 1, 2, 3, 4, 5, 6])).length(4)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 9);
    const algebraicLetter = getAlgebraicSymbol();
    const answerIndexes = getRandomSubArrayFromArray([0, 1, 2, 3, 4, 5, 6] as const, 4);

    return { number, algebraicLetter, answerIndexes };
  },
  Component: props => {
    const {
      question: { number, algebraicLetter, answerIndexes },
      translate,
      displayMode
    } = props;

    const options = [
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xLessThanY(
                  number.toLocaleString(),
                  algebraicLetter
                )}
              />
            </View>
          ),
          correctAnswer: 'a'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${number.toLocaleString()} ${SUB} ${algebraicLetter}`}</Text>
          ),

          value: 'a'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xMultipliedByY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'b'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`${number.toLocaleString()}${algebraicLetter}`}</Text>,
          value: 'b'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xDividedByY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'c'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${DIV} ${number.toLocaleString()}`}</Text>
          ),
          value: 'c'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xMoreThanY(
                  number.toLocaleString(),
                  algebraicLetter
                )}
              />
            </View>
          ),
          correctAnswer: 'd'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${ADD} ${number.toLocaleString()}`}</Text>
          ),
          value: 'd'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure sentence={translate.answerSentences.xSquared(algebraicLetter)} />
            </View>
          ),
          correctAnswer: 'e'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`${algebraicLetter}²`}</Text>,
          value: 'e'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure sentence={translate.answerSentences.doubleX(algebraicLetter)} />
            </View>
          ),
          correctAnswer: 'f'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`2${algebraicLetter}`}</Text>,
          value: 'f'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xLessThanY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'g'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${SUB} ${number.toLocaleString()}`}</Text>
          ),
          value: 'g'
        }
      }
    ];

    const filteredOptions = answerIndexes.map(index => options[index]);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchStatementsToEquivalantAlgebraicExpression()}
        statements={filteredOptions.map(el => el.statement)}
        statementStyle={{ justifyContent: 'center' }}
        items={shuffle(
          filteredOptions.map(el => el.answerOptions),
          { random: seededRandom(props.question) }
        )}
        moveOrCopy="move"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4v2 = newQuestionContent({
  uid: 'aTZ2',
  description: 'aTZ',
  keywords: ['Expression'],
  schema: z.object({
    algebraicLetter: algebraicSymbolSchema,
    number: z.number().int().min(2).max(9),
    answerIndexes: z.array(numberEnum([0, 1, 2, 3, 4, 5, 6])).length(4)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 9);
    const algebraicLetter = getAlgebraicSymbol();
    const answerIndexes =
      number === 2
        ? // make sure we do not get double and multiply
          getRandomSubArrayFromArray(
            getRandomBoolean() ? ([0, 2, 3, 4, 5, 6] as const) : ([0, 1, 2, 3, 4, 6] as const),
            4
          )
        : getRandomSubArrayFromArray([0, 1, 2, 3, 4, 5, 6] as const, 4);

    return { number, algebraicLetter, answerIndexes };
  },
  Component: props => {
    const {
      question: { number, algebraicLetter, answerIndexes },
      translate,
      displayMode
    } = props;

    const options = [
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xLessThanY(
                  number.toLocaleString(),
                  algebraicLetter
                )}
              />
            </View>
          ),
          correctAnswer: 'a'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${SUB} ${number.toLocaleString()}`}</Text>
          ),

          value: 'a'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xMultipliedByY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'b'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`${number.toLocaleString()}${algebraicLetter}`}</Text>,
          value: 'b'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xDividedByY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'c'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${DIV} ${number.toLocaleString()}`}</Text>
          ),
          value: 'c'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xMoreThanY(
                  number.toLocaleString(),
                  algebraicLetter
                )}
              />
            </View>
          ),
          correctAnswer: 'd'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${algebraicLetter} ${ADD} ${number.toLocaleString()}`}</Text>
          ),
          value: 'd'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure sentence={translate.answerSentences.xSquared(algebraicLetter)} />
            </View>
          ),
          correctAnswer: 'e'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`${algebraicLetter}²`}</Text>,
          value: 'e'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure sentence={translate.answerSentences.doubleX(algebraicLetter)} />
            </View>
          ),
          correctAnswer: 'f'
        },
        answerOptions: {
          component: <Text variant="WRN700">{`${(2).toLocaleString()}${algebraicLetter}`}</Text>,
          value: 'f'
        }
      },
      {
        statement: {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 300 : 450, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={translate.answerSentences.xLessThanY(
                  algebraicLetter,
                  number.toLocaleString()
                )}
              />
            </View>
          ),
          correctAnswer: 'g'
        },
        answerOptions: {
          component: (
            <Text variant="WRN700">{`${number.toLocaleString()} ${SUB} ${algebraicLetter}`}</Text>
          ),
          value: 'g'
        }
      }
    ];

    const filteredOptions = answerIndexes.map(index => options[index]);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchStatementsToAlgebraicExpression()}
        statements={filteredOptions.map(el => el.statement)}
        statementStyle={{ justifyContent: 'center' }}
        items={shuffle(
          filteredOptions.map(el => el.answerOptions),
          { random: seededRandom(props.question) }
        )}
        moveOrCopy="move"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aT0',
  description: 'aT0',
  keywords: ['Expression'],

  schema: z.object({
    shape: z.enum([
      'equilateralTriangles',
      'squares',
      'hexagons',
      'pentagons',
      'octagons',
      'heptagons',
      'nonagons',
      'decagons'
    ]),
    algebraicLetter: algebraicSymbolSchema,
    shapeSvgName: z.string()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'equilateralTriangles',
      'squares',
      'hexagons',
      'pentagons',
      'octagons',
      'heptagons',
      'nonagons',
      'decagons'
    ] as const);
    const algebraicLetter = getAlgebraicSymbol();

    const shapeSvgName = getRegularShapeWithAllSidesMarkedSvgName(shape);

    return { shape, algebraicLetter, shapeSvgName };
  },
  Component: props => {
    const {
      question: { shape, algebraicLetter, shapeSvgName },
      translate,
      displayMode
    } = props;

    const number = (() => {
      switch (shape) {
        case 'equilateralTriangles':
          return 3;
        case 'squares':
          return 4;
        case 'pentagons':
          return 5;
        case 'hexagons':
          return 6;
        case 'heptagons':
          return 7;
        case 'octagons':
          return 8;
        case 'nonagons':
          return 9;
        case 'decagons':
          return 10;
      }
    })();

    const statements = shuffle(
      [
        { value: 'a', text: `${number.toLocaleString()}${algebraicLetter}` },
        { value: 'b', text: `${algebraicLetter} ${ADD} ${number.toLocaleString()}` },
        { value: 'c', text: `${number}${algebraicLetter} ${ADD} ${number.toLocaleString()}` },
        { value: 'd', text: `${number.toLocaleString()}` }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheAlgebraicExpressionThatRepresentsThePerimeterOfTheShape()}
        pdfTitle={translate.instructions.circleTheAlgebraicExpressionThatRepresentsThePerimeterOfTheShape()}
        testCorrect={['a']}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ bottom: 20 }}>
            {LabelledShapesWithMarkedSides({
              dimens,
              displayMode,
              labelText: algebraicLetter,
              shapeName: shape,
              shapeSvgName,
              shapeSize: 'small'
            })}
          </View>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: <Text variant="WRN700">{statement.text}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aT1',
  description: 'aT1',
  keywords: ['Bar model', 'Expression'],
  schema: z.object({
    algebraicLetter: algebraicSymbolSchema,
    number: z.number().int().min(1).max(9),
    algebraicSections: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const algebraicLetter = getAlgebraicSymbol();

    const algebraicSections = randomIntegerInclusive(2, 8, { constraint: x => x !== number });

    return { number, algebraicLetter, algebraicSections };
  },

  Component: props => {
    const {
      question: { number, algebraicLetter, algebraicSections },
      translate
    } = props;

    const total = algebraicSections + number;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeBarModel()}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [
            `${algebraicSections}${algebraicLetter}`,
            number.toString()
          ])
        }
        inputMaxCharacters={2}
        sentence={`? = <ans/> ${ADD} <ans/>`}
        extraSymbol={algebraicLetter}
        customMarkSchemeAnswer={{
          answersToDisplay: [`${algebraicSections}${algebraicLetter}`, number.toLocaleString()]
        }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <BarModel
              total={total}
              strings={[
                filledArray('?', total),
                filledArray(`${algebraicLetter}`, algebraicSections)
              ]}
              numbers={[filledArray(total, 1), [...filledArray(1, algebraicSections), number]]}
              dimens={dimens}
              sameRowColor
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FormExpressions',
  questionTypes: [Question1, Question2v2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question2, Question4]
});
export default SmallStep;
